import {
  IntegrationType,
  Maybe,
  ThirdPartyProvider,
} from "api/generated/graphql";
import { IntegrationInfo } from "components/integrations/OrgIntegrationApp";
import { FormGroup, Input, Modal, RadioGroup } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import React, { ReactElement, useContext, useState } from "react";
import { useLocation } from "react-router-dom";
import AppContext from "views/app/AppContext";
import { subtitleFormat } from "views/groups/creation/common";
import { IntegrationButton } from "views/settings/third_party_authorizer/IntegrationButton";
import { OrgIntegrationRedirectConfirmModal } from "views/settings/third_party_authorizer/OrgIntegrationRedirectConfirmModal";
import { UserIntegrationRedirectConfirmModal } from "views/settings/third_party_authorizer/UserIntegrationRedirectConfirmModal";

const slackOrgKindOptions: Array<{ value: "workspace" | "organization" }> = [
  { value: "workspace" },
  { value: "organization" },
];

export type ClientInfo = {
  clientId: string;
  clientSecret: string;
  signingSecret: string;
  clientSecondaryToken: string;
};

export type IntegrationCreationInfo = {
  orgName: string;
  thirdPartyProvider: ThirdPartyProvider;
  clientInfo: ClientInfo;
};

enum IntegrationCreationSteps {
  SlackOrgNameOrEnterpriseRedirect,
  AppKey,
  AppSecret,
  SigningSecret,
  AppLevelToken,
  Redirect,
  Code,
}

type ThirdPartyCodeFlowAuthorizerProps = {
  integrationType: IntegrationType;
  integrationInfo: IntegrationInfo;
  isConnected: boolean;
  openThirdPartyProvider: Maybe<ThirdPartyProvider>;
  setOpenThirdPartyProvider: (integration: Maybe<ThirdPartyProvider>) => void;
  connectionButtonDisabled: boolean;
};

export const getOrgTooltip = (thirdPartyProvider: ThirdPartyProvider) => {
  switch (thirdPartyProvider) {
    case ThirdPartyProvider.Slack:
      return 'e.g. "my-organization" where your Slack URL is "my-organization.slack.com"';
  }
};

export const ThirdPartyCodeFlowAuthorizer = (
  props: ThirdPartyCodeFlowAuthorizerProps
) => {
  const { appState } = useContext(AppContext);
  const urlParams = new URLSearchParams(useLocation().search);

  const [stepNumber, setStepNumber] = useState(1);

  const thirdPartyProvider = props.integrationInfo.thirdPartyProvider;

  const [
    integrationCreationInfo,
    setIntegrationCreationInfo,
  ] = useState<IntegrationCreationInfo>({
    orgName: urlParams.get("orgName") || "",
    thirdPartyProvider: thirdPartyProvider,
    clientInfo: {
      clientId: "",
      clientSecret: "",
      signingSecret: "",
      clientSecondaryToken: "",
    },
  });

  let steps: IntegrationCreationSteps[];
  switch (thirdPartyProvider) {
    case ThirdPartyProvider.GitHub:
      steps = [IntegrationCreationSteps.Redirect];
      break;
    case ThirdPartyProvider.GitLab:
      steps = [IntegrationCreationSteps.Redirect];
      break;
    case ThirdPartyProvider.Slack:
      if (appState.isOnPrem) {
        steps = [
          IntegrationCreationSteps.SlackOrgNameOrEnterpriseRedirect,
          IntegrationCreationSteps.AppKey,
          IntegrationCreationSteps.AppSecret,
          IntegrationCreationSteps.SigningSecret,
          IntegrationCreationSteps.AppLevelToken,
          IntegrationCreationSteps.Redirect,
        ];
      } else {
        steps = [
          IntegrationCreationSteps.SlackOrgNameOrEnterpriseRedirect,
          IntegrationCreationSteps.Redirect,
        ];
      }
      break;
    // The only third party providers that use this component are GitHub, GitLab, and Slack.
    // The code should never reach this default case.
    default:
      steps = [];
  }

  const [slackOrgKind, setSlackOrgKind] = useState({
    value: "workspace",
  } as typeof slackOrgKindOptions[0]);

  const step = steps[stepNumber - 1];
  const totalSteps = steps.length;

  const integrationName = _.upperFirst(_.camelCase(thirdPartyProvider));

  const onClose = () => {
    props.setOpenThirdPartyProvider(null);
  };

  let modal: Maybe<ReactElement> = null;
  switch (step) {
    case IntegrationCreationSteps.SlackOrgNameOrEnterpriseRedirect:
      modal = (
        <Modal
          isOpen
          onClose={onClose}
          title="Link Slack"
          subtitle={subtitleFormat(stepNumber, totalSteps)}
        >
          <Modal.Body>
            <div className={sprinkles({ marginBottom: "md" })}>
              <RadioGroup
                options={slackOrgKindOptions}
                onSelectValue={(v) => setSlackOrgKind(v)}
                value={slackOrgKind}
                getOptionKey={(v) => v.value}
                getOptionLabel={(v) =>
                  v.value === "workspace"
                    ? "Install to a single Slack workspace"
                    : "Install to Slack Enterprise Grid"
                }
              />
            </div>
            {slackOrgKind.value === "workspace" && (
              <FormGroup label="Workspace domain">
                <Input
                  value={integrationCreationInfo.orgName}
                  placeholder="my-slack-team"
                  rightText=".slack.com"
                  onChange={(orgName) =>
                    setIntegrationCreationInfo({
                      ...integrationCreationInfo,
                      orgName,
                    })
                  }
                />
              </FormGroup>
            )}
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Next"
            onPrimaryButtonClick={() => setStepNumber((s) => s + 1)}
            primaryButtonDisabled={
              slackOrgKind.value === "workspace" &&
              !integrationCreationInfo.orgName
            }
          />
        </Modal>
      );
      break;
    case IntegrationCreationSteps.AppKey:
      modal = (
        <Modal
          title={`Link ${integrationName}`}
          isOpen={true}
          onClose={onClose}
          subtitle={subtitleFormat(stepNumber, totalSteps)}
        >
          <Modal.Body>
            <FormGroup label="Client ID">
              <Input
                value={integrationCreationInfo.clientInfo.clientId}
                onChange={(val) => {
                  setIntegrationCreationInfo({
                    ...integrationCreationInfo,
                    clientInfo: {
                      ...integrationCreationInfo.clientInfo,
                      clientId: val,
                    },
                  });
                }}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Next"
            onPrimaryButtonClick={() => setStepNumber(stepNumber + 1)}
            secondaryButtonLabel={stepNumber > 1 ? "Back" : undefined}
            onSecondaryButtonClick={
              stepNumber > 1 ? () => setStepNumber(stepNumber - 1) : undefined
            }
          />
        </Modal>
      );
      break;
    case IntegrationCreationSteps.AppSecret:
      modal = (
        <Modal
          title={`Link ${integrationName}`}
          isOpen={true}
          onClose={onClose}
          subtitle={subtitleFormat(stepNumber, totalSteps)}
        >
          <Modal.Body>
            <FormGroup label="Client secret">
              <Input
                value={integrationCreationInfo.clientInfo.clientSecret}
                onChange={(val) => {
                  setIntegrationCreationInfo({
                    ...integrationCreationInfo,
                    clientInfo: {
                      ...integrationCreationInfo.clientInfo,
                      clientSecret: val,
                    },
                  });
                }}
                type="password"
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Next"
            onPrimaryButtonClick={() => setStepNumber(stepNumber + 1)}
            secondaryButtonLabel={stepNumber > 1 ? "Back" : undefined}
            onSecondaryButtonClick={
              stepNumber > 1 ? () => setStepNumber(stepNumber - 1) : undefined
            }
          />
        </Modal>
      );
      break;
    case IntegrationCreationSteps.SigningSecret:
      modal = (
        <Modal
          title={`Link ${integrationName}`}
          isOpen={true}
          onClose={onClose}
          subtitle={subtitleFormat(stepNumber, totalSteps)}
        >
          <Modal.Body>
            <FormGroup label="Signing secret">
              <Input
                value={integrationCreationInfo.clientInfo.signingSecret}
                onChange={(val) => {
                  setIntegrationCreationInfo({
                    ...integrationCreationInfo,
                    clientInfo: {
                      ...integrationCreationInfo.clientInfo,
                      signingSecret: val,
                    },
                  });
                }}
                type="password"
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Next"
            onPrimaryButtonClick={() => setStepNumber(stepNumber + 1)}
            secondaryButtonLabel={stepNumber > 1 ? "Back" : undefined}
            onSecondaryButtonClick={
              stepNumber > 1 ? () => setStepNumber(stepNumber - 1) : undefined
            }
          />
        </Modal>
      );
      break;
    case IntegrationCreationSteps.AppLevelToken:
      modal = (
        <Modal
          title={`Link ${integrationName}`}
          isOpen={true}
          onClose={onClose}
          subtitle={subtitleFormat(stepNumber, totalSteps)}
        >
          <Modal.Body>
            <FormGroup label="App level token">
              <Input
                value={integrationCreationInfo.clientInfo.clientSecondaryToken}
                onChange={(val) => {
                  setIntegrationCreationInfo({
                    ...integrationCreationInfo,
                    clientInfo: {
                      ...integrationCreationInfo.clientInfo,
                      clientSecondaryToken: val,
                    },
                  });
                }}
                type="password"
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Next"
            onPrimaryButtonClick={() => setStepNumber(stepNumber + 1)}
            secondaryButtonLabel={stepNumber > 1 ? "Back" : undefined}
            onSecondaryButtonClick={
              stepNumber > 1 ? () => setStepNumber(stepNumber - 1) : undefined
            }
          />
        </Modal>
      );
      break;
    case IntegrationCreationSteps.Redirect:
      switch (thirdPartyProvider) {
        case ThirdPartyProvider.GitHub:
        case ThirdPartyProvider.GitLab:
          modal = (
            <UserIntegrationRedirectConfirmModal
              title={`Initiate ${integrationName} auth flow`}
              message={`You will now be redirected ${integrationName}'s website to begin their auth flow.`}
              isModalOpen={true}
              stepNumber={stepNumber}
              setStepNumber={setStepNumber}
              totalSteps={totalSteps}
              value={integrationCreationInfo.clientInfo.clientSecret}
              onClose={onClose}
              integrationCreationInfo={integrationCreationInfo}
            />
          );
          break;
        default:
          modal = (
            <OrgIntegrationRedirectConfirmModal
              title={`Initiate ${integrationName} auth flow`}
              message={
                <>
                  You will now be redirected {integrationName}'s website to
                  begin their auth flow.{" "}
                  {slackOrgKind.value === "organization" ? (
                    <>
                      Select the enterprise grid to install to in top-right
                      selector.
                      <br />
                      <br />
                      By default, Opal will not be installed in any workspaces.
                      You will be able to add Opal to individual workspaces
                      after this step.
                    </>
                  ) : (
                    ""
                  )}
                </>
              }
              isModalOpen={true}
              stepNumber={stepNumber}
              setStepNumber={setStepNumber}
              totalSteps={totalSteps}
              value={integrationCreationInfo.clientInfo.clientSecret}
              onClose={onClose}
              integrationCreationInfo={integrationCreationInfo}
            />
          );
      }
      break;
  }

  const isModalOpen =
    props.openThirdPartyProvider === props.integrationInfo.thirdPartyProvider;

  return (
    <div>
      <IntegrationButton
        integrationType={props.integrationType}
        integrationInfo={props.integrationInfo}
        isConnected={props.isConnected}
        setOpenThirdPartyProvider={props.setOpenThirdPartyProvider}
        buttonDisabled={props.connectionButtonDisabled}
      />
      {isModalOpen && modal}
    </div>
  );
};
