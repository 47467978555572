import { AccessType } from "api/generated/graphql";
import { Checkbox } from "components/ui";
import { useState } from "react";
import useLogEvent from "utils/analytics";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import { useFilterDispatch, useFilterState } from "../contexts/FilterContext";
import * as styles from "./AccessTypeFilter.css";
import FilterHeader from "./FilterHeader";

const AccessTypeFilter = () => {
  const showBirthrightText = useFeatureFlag(
    FeatureFlag.VisualizationGroupBasedVsBirthrightText
  );
  const [expanded, setExpanded] = useState(false);

  const { accessTypes } = useFilterState();
  const filterDispatch = useFilterDispatch();
  const logEvent = useLogEvent();

  const handleFilterChange = (accessType: AccessType) => (
    selected: boolean
  ) => {
    filterDispatch({
      type: "UPDATE_ACCESS_TYPE_FILTER",
      payload: {
        accessType,
        selected,
      },
    });
    logEvent({
      name: "viz_access_type_highlight_toggle",
      properties: {
        accessType,
        checked: selected,
      },
    });
  };

  const filters = (
    <>
      <div className={styles.checkboxRow}>
        <Checkbox
          label="Expiring"
          size="sm"
          checked={accessTypes.includes(AccessType.Expiring)}
          onChange={handleFilterChange(AccessType.Expiring)}
        />
        <span className={styles.textBadge({ color: "green" })}>BEST</span>
      </div>
      <div className={styles.checkboxRow}>
        <Checkbox
          label={showBirthrightText ? "Birthright" : "Group-based"}
          size="sm"
          checked={accessTypes.includes(AccessType.Birthright)}
          onChange={handleFilterChange(AccessType.Birthright)}
        />
        <span className={styles.textBadge({ color: "yellow" })}>OK</span>
      </div>
      <div className={styles.checkboxRow}>
        <Checkbox
          label="Longstanding"
          size="sm"
          checked={accessTypes.includes(AccessType.Longstanding)}
          onChange={handleFilterChange(AccessType.Longstanding)}
        />
        <span className={styles.textBadge({ color: "red" })}>BAD</span>
      </div>
    </>
  );

  return (
    <>
      <FilterHeader
        title="Access type"
        description="How users access resources"
        active={accessTypes.length > 0}
        expanded={expanded}
        onClick={() => setExpanded((prev) => !prev)}
      />
      {expanded ? filters : null}
    </>
  );
};

export default AccessTypeFilter;
