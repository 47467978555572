import { useToast } from "components/toast/Toast";
import { useHistory, useLocation } from "react-router";
import { useMountEffect } from "utils/hooks";
import { clearMfaData, getMfaParams, MfaCustomParams } from "utils/mfa/mfa";
import { clearOidcData, getOidcData } from "utils/oidc/oidc";

export type RequestModalMfaFillerProps = {
  callback: (requestModalParams: MfaCustomParams) => void;
};

export const RequestModalMfaFiller = (props: RequestModalMfaFillerProps) => {
  const location = useLocation();
  const history = useHistory();

  const { displayErrorToast } = useToast();

  useMountEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    if (queryParams.has("mfa")) {
      const mfaStatus = queryParams.get("mfa");
      if (mfaStatus === "success") {
        const mfaParams = getMfaParams();
        if (mfaParams && (mfaParams.requestReason || mfaParams.requestState)) {
          props.callback(mfaParams);
        }
      } else if (mfaStatus === "access_denied") {
        displayErrorToast(
          "Error: Please contact your Opal Admin to reset your MFA."
        );
      } else {
        displayErrorToast(
          "MFA challenge failed. Please try again or contact support if the issue remains."
        );
      }

      // Hopefully this is the last place where the MFA info is being used, so
      // if you ever need this again, after you handle the callback from this
      // useMountEffect, remove the next line: OPAL-11571
      clearMfaData();
      queryParams.delete("mfa");
      history.replace({
        search: queryParams.toString(),
      });
    } else if (queryParams.has("oidc_auth")) {
      // Restore state from before redirect.
      const oidcAuthStatus = queryParams.get("oidc_auth");
      if (oidcAuthStatus === "success") {
        const oidcData = getOidcData();
        if (
          oidcData &&
          (oidcData.mfaParams?.requestReason ||
            oidcData.mfaParams?.requestState)
        ) {
          props.callback(oidcData.mfaParams);
        }
      }

      // Hopefully this is the last place where the MFA info is being used, so
      // if you ever need this again, after you handle the callback from this
      // useMountEffect, remove the next line: OPAL-11571
      clearOidcData();
      queryParams.delete("oidc_auth");
      history.replace({
        search: queryParams.toString(),
      });
    }
    return;
  });

  return null;
};

export default RequestModalMfaFiller;
