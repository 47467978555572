import { ThirdPartyProvider } from "api/generated/graphql";
import slackLogo from "assets/logos/slack-logo.svg";
import Label from "components/label/Label";
import styles from "components/label/MessageChannelLabel.module.scss";
import React from "react";
import * as Icons from "react-feather";

type MessageChannelLabelProps = {
  messageChannelName: string;
  thirdPartyProvider: ThirdPartyProvider;
};

export const MessageChannelLabel = (props: MessageChannelLabelProps) => {
  let icon = null;
  if (props.thirdPartyProvider === ThirdPartyProvider.Slack) {
    icon = slackLogo;
  }
  return <Label text={props.messageChannelName} icon={icon} />;
};

type RemoteMessageChannelLabelProps = {
  messageChannelName: string;
  thirdPartyProvider: ThirdPartyProvider;
  isPrivate: boolean;
};

export const RemoteMessageChannelLabel = (
  props: RemoteMessageChannelLabelProps
) => {
  let icon = null;
  if (props.thirdPartyProvider === ThirdPartyProvider.Slack) {
    if (props.isPrivate) {
      icon = <Icons.Lock strokeWidth={2} size={13} />;
    } else {
      icon = <Icons.Hash strokeWidth={2} size={13} />;
    }
  }
  return <Label text={props.messageChannelName} icon={icon} />;
};

type RemoteMessageChannelButtonLabelProps = {
  messageChannelName: string;
  thirdPartyProvider: ThirdPartyProvider;
  isPrivate: boolean;
  action: string;
};

export const RemoteMessageChannelButtonLabel = (
  props: RemoteMessageChannelButtonLabelProps
) => {
  return (
    <div className={styles.createMessageChannel}>
      <span className={styles.create}>{props.action}</span>
      <RemoteMessageChannelLabel
        messageChannelName={props.messageChannelName}
        thirdPartyProvider={ThirdPartyProvider.Slack}
        isPrivate={props.isPrivate}
      />
    </div>
  );
};
