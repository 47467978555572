import {
  AccessReviewConnectionFragment,
  AccessReviewGroupForDetailFragment,
  AccessReviewResourceFragment,
  EntityType,
  useAccessReviewConnectionQuery,
  useAccessReviewGroupDetailsQuery,
  useAccessReviewResourceQuery,
} from "api/generated/graphql";
import GroupBindingDetailPopover from "components/group_bindings/GroupBindingDetailPopover";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import { useReducer } from "react";
import { useParams } from "react-router";
import { useTransitionBack } from "utils/router/hooks";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";

import AccessReviewConnectionV3 from "./AccessReviewConnectionV3";
import {
  accessReviewContextReducer,
  initialAccessReviewContext,
} from "./AccessReviewContext";
import AccessReviewEntityCard from "./AccessReviewEntityCard";
import AccessReviewGroupV3 from "./AccessReviewGroupV3";
import AccessReviewResourceV3 from "./AccessReviewResourceV3";
import SubmitAccessReviewButtonV3 from "./common/SubmitAccessReviewButtonV3";

const AccessReviewPerformView = () => {
  const {
    accessReviewId,
    accessReviewResourceId,
    accessReviewGroupId,
    accessReviewConnectionId,
  } = useParams<Record<string, string>>();

  let notFoundEntity = "";

  const {
    data: resourceData,
    error: resourceError,
    loading: resourceLoading,
  } = useAccessReviewResourceQuery({
    variables: {
      input: {
        id: accessReviewResourceId,
      },
    },
    skip: !accessReviewResourceId,
  });
  let accessReviewResource: AccessReviewResourceFragment | undefined;
  switch (resourceData?.accessReviewResource?.__typename) {
    case "AccessReviewResourceResult":
      accessReviewResource =
        resourceData.accessReviewResource.accessReviewResource;
      break;
    case "AccessReviewResourceNotFoundError":
      notFoundEntity = "Access Review Resource";
      break;
  }

  const {
    data: groupData,
    error: groupError,
    loading: groupLoading,
  } = useAccessReviewGroupDetailsQuery({
    variables: {
      input: {
        id: accessReviewGroupId,
      },
    },
    skip: !accessReviewGroupId,
  });
  let accessReviewGroup: AccessReviewGroupForDetailFragment | undefined;
  switch (groupData?.accessReviewGroup?.__typename) {
    case "AccessReviewGroupResult":
      accessReviewGroup = groupData.accessReviewGroup.accessReviewGroup;
      break;
    case "AccessReviewGroupNotFoundError":
      notFoundEntity = "Access Review Group";
      break;
  }

  const {
    data: connectionData,
    error: connectionError,
    loading: connectionLoading,
  } = useAccessReviewConnectionQuery({
    variables: {
      input: {
        id: accessReviewConnectionId,
      },
    },
    skip: !accessReviewConnectionId,
  });
  let accessReviewConnection: AccessReviewConnectionFragment | undefined;
  switch (connectionData?.accessReviewConnection?.__typename) {
    case "AccessReviewConnectionResult":
      accessReviewConnection =
        connectionData.accessReviewConnection.accessReviewConnection;
      break;
    case "AccessReviewConnectionNotFoundError":
      notFoundEntity = "Access Review Connection";
      break;
  }

  const error = resourceError || groupError || connectionError;
  if (error) {
    return <UnexpectedErrorPage error={error} />;
  }

  const loading = resourceLoading || groupLoading || connectionLoading;
  if (loading) {
    return <FullscreenSkeleton />;
  }

  if (notFoundEntity) {
    return <NotFoundPage entity={notFoundEntity} />;
  }

  return (
    <AccessReviewPerform
      accessReviewId={accessReviewId}
      accessReviewResource={accessReviewResource}
      accessReviewGroup={accessReviewGroup}
      accessReviewConnection={accessReviewConnection}
    />
  );
};

const AccessReviewPerform = ({
  accessReviewId,
  accessReviewResource,
  accessReviewGroup,
  accessReviewConnection,
}: {
  accessReviewId: string;
  accessReviewResource?: AccessReviewResourceFragment;
  accessReviewGroup?: AccessReviewGroupForDetailFragment;
  accessReviewConnection?: AccessReviewConnectionFragment;
}) => {
  const transitionBack = useTransitionBack();

  const [accessReviewState, accessReviewDispatch] = useReducer(
    accessReviewContextReducer,
    initialAccessReviewContext
  );

  const handleClose = () => {
    transitionBack(`/access-reviews/${accessReviewId}#my-reviews`);
  };

  const handleSave = async () => {};

  const renderEntityCard = () => {
    if (accessReviewResource) {
      const resource = accessReviewResource.resource;
      return (
        <AccessReviewEntityCard
          entityId={resource?.id || ""}
          entityType={EntityType.Resource}
          name={resource?.name || ""}
          description={resource?.description || ""}
          itemType={resource?.resourceType}
        />
      );
    } else if (accessReviewGroup) {
      const group = accessReviewGroup.group;
      return (
        <AccessReviewEntityCard
          entityId={group?.id || ""}
          entityType={EntityType.Group}
          name={group?.name || ""}
          description={group?.description || ""}
          itemType={group?.groupType}
          nameAccessory={
            group?.groupBinding ? (
              <GroupBindingDetailPopover
                groupId={group.id}
                groupBinding={group.groupBinding}
              />
            ) : undefined
          }
        />
      );
    } else if (accessReviewConnection) {
      const connection = accessReviewConnection.connection;
      return (
        <AccessReviewEntityCard
          entityId={connection?.id || ""}
          entityType={EntityType.Connection}
          name={connection?.name || ""}
          description={connection?.description || ""}
          itemType={connection?.connectionType}
        />
      );
    }
  };

  const renderButtons = (): React.ReactNode[] => {
    let performReviewState =
      (accessReviewResource?.id &&
        accessReviewState.performReviewStateByUARResourceId[
          accessReviewResource.id
        ]) ||
      undefined;

    if (accessReviewGroup) {
      performReviewState =
        accessReviewState.performReviewStateByUARGroupId[accessReviewGroup.id];
    } else if (accessReviewConnection) {
      performReviewState =
        accessReviewState.performReviewStateByUARConnectionId[
          accessReviewConnection.id
        ];
    }

    return [
      ...(performReviewState
        ? [
            <SubmitAccessReviewButtonV3
              accessReviewId={accessReviewId}
              onCancel={handleClose}
              onSubmit={handleClose}
              entityName={accessReviewResource?.resource?.name || ""}
              performReviewState={performReviewState}
            />,
          ]
        : []),
    ];
  };

  const renderMainContent = () => {
    if (accessReviewResource) {
      return (
        <AccessReviewResourceV3
          accessReviewResource={accessReviewResource}
          accessReviewState={accessReviewState}
          accessReviewDispatch={accessReviewDispatch}
        />
      );
    } else if (accessReviewGroup) {
      return (
        <AccessReviewGroupV3
          accessReviewGroup={accessReviewGroup}
          accessReviewState={accessReviewState}
          accessReviewDispatch={accessReviewDispatch}
        />
      );
    } else if (accessReviewConnection) {
      return (
        <AccessReviewConnectionV3
          accessReviewConnection={accessReviewConnection}
          accessReviewState={accessReviewState}
          accessReviewDispatch={accessReviewDispatch}
        />
      );
    }
  };

  return (
    <FullscreenView
      title="UAR Review"
      onCancel={handleClose}
      onPrimaryButtonClick={handleSave}
      primaryButtonDisabled={false}
      buttons={renderButtons()}
    >
      <FullscreenView.Sidebar variants={{ size: "sm" }}>
        {renderEntityCard()}
      </FullscreenView.Sidebar>
      <FullscreenView.Content fullWidth>
        {renderMainContent()}
      </FullscreenView.Content>
    </FullscreenView>
  );
};

export default AccessReviewPerformView;
