import {
  IntegrationType,
  Maybe,
  ThirdPartyProvider,
} from "api/generated/graphql";
import { IntegrationInfo } from "components/integrations/OrgIntegrationApp";
import React from "react";
import { OrgIntegrationButton } from "views/settings/third_party_authorizer/OrgIntegrationButton";
import { UserIntegrationButton } from "views/settings/third_party_authorizer/UserIntegrationButton";

type IntegrationButtonProps = {
  integrationType: IntegrationType;
  integrationInfo: IntegrationInfo;
  isConnected: boolean;
  setOpenThirdPartyProvider: (integration: Maybe<ThirdPartyProvider>) => void;
  onConnectClick?: () => void;
  buttonDisabled: boolean;
  simpleButton?: boolean;
};

export const IntegrationButton = (props: IntegrationButtonProps) => {
  switch (props.integrationType) {
    case IntegrationType.Org:
      return (
        <OrgIntegrationButton
          integrationInfo={props.integrationInfo}
          isConnected={props.isConnected}
          setOpenThirdPartyProvider={props.setOpenThirdPartyProvider}
          onConnectClick={props.onConnectClick}
          buttonDisabled={props.buttonDisabled}
          simpleButton={props.simpleButton}
        />
      );
    case IntegrationType.User:
      return (
        <UserIntegrationButton
          integrationInfo={props.integrationInfo}
          isConnected={props.isConnected}
          setOpenThirdPartyProvider={props.setOpenThirdPartyProvider}
          onConnectClick={props.onConnectClick}
          buttonDisabled={props.buttonDisabled}
        />
      );
    default:
      return null;
  }
};
