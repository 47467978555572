import * as React from "react";

import * as styles from "../button/MultiButton.css";
import ContextMenu from "../context_menu/ContextMenu";
import Button from "./ButtonV3";

export interface MultiButtonOption {
  label: string;
  handler: () => void;
}

interface MultiButtonProps extends PropsFor<typeof Button> {
  buttons: MultiButtonOption[];
}

const MultiButtonV3 = ({
  buttons,
  loading,
  disabled,
  leftIconName,
  type,
  size,
}: MultiButtonProps) => {
  const [currentButton, setCurrentButton] = React.useState(buttons[0]);
  const menuOptions = buttons.map((button) => {
    return {
      label: button.label,
      onClick: () => {
        setCurrentButton(button);
      },
    };
  });
  if (buttons.length == 0) {
    return <></>;
  }

  if (buttons.length == 1) {
    return (
      <Button
        onClick={buttons[0].handler}
        type={type}
        label={buttons[0].label}
        loading={loading}
        leftIconName={leftIconName}
        size={size}
      />
    );
  }

  return (
    <div className={styles.container}>
      <Button
        onClick={currentButton.handler}
        label={currentButton.label}
        type={type}
        loading={loading}
        disabled={disabled}
        leftIconName={leftIconName}
        size={size}
        flatEdge="right"
      />
      <ContextMenu
        options={menuOptions}
        renderButton={(onClick) => (
          <Button
            onClick={onClick}
            type={type}
            leftIconName="chevron-down"
            loading={loading}
            flatEdge="left"
            size={size}
          />
        )}
        rightAligned={true}
      />
    </div>
  );
};

export default MultiButtonV3;
