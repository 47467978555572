import { TagFragment, useTagQuery } from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import { Divider, Tabs } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import TagDeleteModal from "./TagMoreInfoMenu";
import TagGroupsRow from "./viewer/rows/TagGroupsRow";
import TagOverviewRow from "./viewer/rows/TagOverviewRow";
import TagResourcesRow from "./viewer/rows/TagResourcesRow";
import TagUsersRow from "./viewer/rows/TagUsersRow";

interface TagView {
  key: string;
  title: string;
  content: JSX.Element;
  count?: number;
}

const TagDetailColumn = () => {
  const { tagId } = useParams<Record<string, string>>();
  const location = useLocation();
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const selectedView = location.hash.slice(1) || "overview";

  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);

  const { data, error: tagError, loading: tagLoading } = useTagQuery({
    variables: {
      input: {
        id: tagId ?? "",
      },
    },
    skip: tagId == null,
  });
  let tag: TagFragment | null = null;
  let tagNotFound = false;

  if (data) {
    switch (data.tag.__typename) {
      case "TagResult":
        tag = data.tag.tag;
        break;
      case "TagNotFoundError":
        tagNotFound = true;
        break;
      default:
        logError(new Error(`failed to list tag`));
    }
  } else if (tagError) {
    logError(tagError, `failed to list tags`);
  }

  if (tagNotFound) {
    return (
      <Column isContent>
        <NotFoundPage />
      </Column>
    );
  }

  if (tagLoading) {
    <Column isContent maxWidth="lg">
      <ColumnContentSkeleton />
    </Column>;
  }

  if (tagError) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={tagError} />
      </Column>
    );
  }

  if (tag == null) {
    return null;
  }

  const tagViews: TagView[] = [
    {
      key: "overview",
      content: <TagOverviewRow tag={tag} />,
      title: "Overview",
    },
    {
      key: "resources",
      content: <TagResourcesRow tag={tag} />,
      title: "Resources",
      count: tag.tagResources.length,
    },
    {
      key: "groups",
      content: <TagGroupsRow tag={tag} />,
      title: "Groups",
      count: tag.tagGroups.length,
    },
    {
      key: "users",
      content: <TagUsersRow tag={tag} />,
      title: "Users",
      count: new Set(tag.tagUsers.map((user) => user.userId)).size, // count unique users,
    },
  ];
  const tabInfos = tagViews.map((view) => ({
    title: view.title,
    onClick: () => history.push({ hash: view.key }),
    isSelected: selectedView === view.key,
    badgeCount: view.count,
  }));

  const selectedViewInfo = tagViews.find((view) => view.key === selectedView);
  const content = selectedViewInfo?.content;

  return (
    <>
      <Column isContent maxWidth="lg">
        <ColumnHeader
          title={`${tag.key}: ${tag.value ?? ""}`}
          subtitle={selectedViewInfo?.title}
          breadcrumbs={
            hasV3Nav
              ? [
                  {
                    name: "Tags",
                    to: "/tags",
                  },
                ]
              : undefined
          }
          icon={{ type: "name", icon: "tag" }}
          menuOptions={[
            {
              label: "Delete tag",
              onClick: () => setShowDeleteModal(true),
              type: "danger",
              icon: { type: "name", icon: "trash" },
            },
          ]}
        />
        <Divider margin="md" />
        <div
          className={sprinkles({
            display: "flex",
            justifyContent: "center",
          })}
        >
          <Tabs tabInfos={tabInfos} round />
        </div>
        <ColumnContent>{content}</ColumnContent>
      </Column>
      {showDeleteModal ? (
        <TagDeleteModal
          tag={tag}
          showDeleteTagModal={showDeleteModal}
          setShowDeleteTagModal={setShowDeleteModal}
        />
      ) : null}
    </>
  );
};

export default TagDetailColumn;
