import { getModifiedErrorMessage } from "api/ApiContext";
import {
  DetailGroupUserFragment,
  EntityType,
  GroupPreviewSmallFragment,
  GroupUserFiltersInput,
  GroupUserSortByField,
  GroupUserSource,
  HrIdpStatus,
  Maybe,
  PaginatedGroupUsersFragment,
  PaginatedGroupUsersSortBy,
  PropagationStatusCode,
  RemoveGroupUserInput,
  SortDirection,
  UpdateGroupUserInput,
  useGroupDetailPaginatedGroupUsersQuery,
  useRemoveGroupUsersMutation,
  useUpdateGroupUsersMutation,
} from "api/generated/graphql";
import { ResourceLabel, TimeLabel } from "components/label/Label";
import { BulkUpdateExpirationModal } from "components/modals/BulkUpdateExpirationModal";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import PropagationStatusLabelWithModal from "components/propagation/PropagationStatusLabelWithModal";
import { Checkbox, Icon, Input, Modal, Select, Tooltip } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import { useState } from "react";
import { useImmer } from "use-immer";
import useLogEvent from "utils/analytics";
import {
  AuthorizedActionManage,
  AuthorizedActionManageUser,
} from "utils/auth/auth";
import { getResourceUrlNew } from "utils/common";
import { groupTypeHasRoles } from "utils/directory/groups";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { PropagationType } from "utils/useRemediations";
import { AVAILABLE_PROPAGATION_STATUSES } from "views/Common";
import { expirationValueToDurationInMinutes } from "views/requests/utils";
import { formatHrIdpStatus } from "views/users/utils";
import { dropNothings } from "views/utils";

import {
  getGroupUserAccessPathsInfo,
  GroupUserAccessPathsInfo,
  GroupUserAccessPointsLabel,
} from "../resources/GroupUserAccessPointsLabel";
import GroupUserSourceLabel from "./GroupUserSourceLabel";
import * as styles from "./GroupUsersTableV3.css";

type SortValue = PaginatedGroupUsersSortBy;

function isSortableField(str: string): str is GroupUserSortByField {
  return Object.values<string>(GroupUserSortByField).includes(str);
}

interface GroupUserRow {
  id: string;
  userId: string;
  groupUser: DetailGroupUserFragment;
  source: GroupUserSource;
  [GroupUserSortByField.SourceOfAccess]: GroupUserAccessPathsInfo;
  [GroupUserSortByField.UserFullName]?: string;
  userAvatarUrl?: string;
  [GroupUserSortByField.UserEmail]: string;
  [GroupUserSortByField.AccessLevelName]: string;
  [GroupUserSortByField.ExpiresAt]?: string | null;
  [GroupUserSortByField.PropagationStatus]?: PropagationStatusCode | null;
  [GroupUserSortByField.LastUsedAt]: string;
  idpStatus?: HrIdpStatus;
}

type GroupUsersTableV3Props = {
  group: GroupPreviewSmallFragment & {
    authorizedActions?: string[] | null;
  };
};

const DEFAULT_FILTERS: GroupUserFiltersInput = {};

export const GroupUsersTableV3 = (props: GroupUsersTableV3Props) => {
  const transitionTo = useTransitionTo();
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [removeUsersErrorMessage, setRemoveUsersErrorMessage] = useState<
    Maybe<string>
  >(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const [
    updateExpirationErrorMessage,
    setUpdateExpirationErrorMessage,
  ] = useState<string>("");
  const [showUpdateExpirationModal, setShowUpdateExpirationModal] = useState(
    false
  );

  const [sortBy, setSortBy] = useState<SortValue | undefined>({
    field: GroupUserSortByField.UserFullName,
    direction: SortDirection.Asc,
  });
  const [filters, setFilters] = useImmer<GroupUserFiltersInput>(
    DEFAULT_FILTERS
  );
  const debouncedFilters = useDebouncedValue(filters, 250);

  const canManage =
    props.group.authorizedActions?.includes(AuthorizedActionManage) ||
    props.group.authorizedActions?.includes(AuthorizedActionManageUser);
  const startPushTaskPoll = usePushTaskLoader();
  const logEvent = useLogEvent();

  const {
    data,
    error,
    loading,
    fetchMore,
    refetch,
  } = useGroupDetailPaginatedGroupUsersQuery({
    variables: {
      id: props.group.id,
      groupUsers: {
        sortBy,
        filters: debouncedFilters,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  let paginatedGroupUsers: PaginatedGroupUsersFragment | null = null;
  if (data) {
    switch (data.group.__typename) {
      case "GroupResult": {
        paginatedGroupUsers = data.group.group.paginatedGroupUsers;
        break;
      }
      case "GroupNotFoundError":
        break;
      default:
        logError(new Error(`failed to load group users`));
    }
  } else if (error) {
    logError(error, `failed to get resource`);
  }

  const hasMoreUsersToFetch = Boolean(
    paginatedGroupUsers?.cursor &&
      paginatedGroupUsers?.groupUsers.length <
        paginatedGroupUsers?.totalNumGroupUsers
  );
  const availableAccessLevels = _.sortBy(
    paginatedGroupUsers?.uniqueAccessLevels ?? [],
    "accessLevelName"
  );

  const cursor = paginatedGroupUsers?.cursor;
  const loadMoreRows =
    hasMoreUsersToFetch && cursor && !loading
      ? async () => {
          await fetchMore({
            variables: {
              // NOTE: since this is a nested field, we need to pass _all_ the fields
              groupUsers: {
                sortBy,
                filters: debouncedFilters,
                cursor,
              },
            },
          });
        }
      : undefined;

  const GROUP_USER_COLUMNS: Header<GroupUserRow>[] = dropNothings([
    {
      id: GroupUserSortByField.UserFullName,
      label: "User",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <ResourceLabel
            text={row[GroupUserSortByField.UserFullName]}
            avatar={row.userAvatarUrl}
            pointerCursor={true}
            entityId={row.userId}
            entityTypeNew={EntityType.User}
          />
        );
      },
      width: 130,
    },
    {
      id: GroupUserSortByField.UserEmail,
      label: "Email",
      customCellRenderer: (row) => {
        return (
          <div className={styles.emailCell}>
            {row[GroupUserSortByField.UserEmail]}
          </div>
        );
      },
      width: 130,
    },
    {
      id: GroupUserSortByField.SourceOfAccess,
      label: "Access Paths",
      customCellRenderer: (row) => {
        if (row.source === GroupUserSource.RegularIndirect) {
          return (
            <GroupUserAccessPointsLabel
              accessPathsInfo={row[GroupUserSortByField.SourceOfAccess]}
              user={row.groupUser.user}
              group={props.group}
            />
          );
        }
        return <GroupUserSourceLabel source={row.source} />;
      },
      width: 110,
    },
    {
      id: GroupUserSortByField.ExpiresAt,
      label: "Expires",
      customCellRenderer: (row) => {
        const expirationTime = row.groupUser.access?.latestExpiringAccessPoint
          ?.expiration
          ? moment(
              new Date(
                row.groupUser.access.latestExpiringAccessPoint.expiration
              )
            )
          : null;
        return (
          <TimeLabel
            time={expirationTime}
            supportTicket={
              row.groupUser.access?.latestExpiringAccessPoint.supportTicket
            }
            useExpiringLabel
          />
        );
      },
      width: 125,
    },
    {
      id: GroupUserSortByField.PropagationStatus,
      label: "Status",
      customCellRenderer: (row) => {
        return (
          <PropagationStatusLabelWithModal
            propagationType={PropagationType.GroupUser}
            propagationStatus={row.groupUser.propagationStatus}
            isAccessReview={false}
            entityInfo={{
              roleAssignmentKey: row.groupUser.userId + props.group.id,
              user: row.groupUser.user,
              group: props.group,
              lastExpiringAccessPointExpiration:
                row.groupUser.access?.latestExpiringAccessPoint.expiration,
            }}
          />
        );
      },
      width: 55,
    },
    {
      id: "idpStatus",
      label: "",
      width: 32,
      customCellRenderer: (row) => {
        let iconName: PropsFor<typeof Icon>["name"] = "user";
        let color: PropsFor<typeof Icon>["color"] = "gray400";
        switch (row.idpStatus) {
          case HrIdpStatus.Active:
            iconName = "user-check";
            color = "gray400";
            break;
          default:
            iconName = "user-x";
            color = "red600";
            break;
        }
        return row.idpStatus ? (
          <Tooltip tooltipText={formatHrIdpStatus(row.idpStatus)}>
            <Icon name={iconName} size="xs" color={color} />
          </Tooltip>
        ) : (
          <></>
        );
      },
    },
  ]);

  if (groupTypeHasRoles(props.group.groupType)) {
    GROUP_USER_COLUMNS.splice(2, 0, {
      id: GroupUserSortByField.AccessLevelName,
      label: "Role",
    });
  }

  const [
    removeGroupUsers,
    { loading: removeUsersLoading },
  ] = useRemoveGroupUsersMutation();

  const [
    updateGroupUsers,
    { loading: updateUsersLoading },
  ] = useUpdateGroupUsersMutation();

  const submitRemoval = async (groupUsersToRemove: RemoveGroupUserInput[]) => {
    logEvent({
      name: "apps_remove_user",
      properties: {
        type: "group",
        numUsers: groupUsersToRemove.length,
      },
    });

    try {
      const { data } = await removeGroupUsers({
        variables: {
          input: {
            groupUsers: groupUsersToRemove,
          },
        },
      });
      switch (data?.removeGroupUsers.__typename) {
        case "RemoveGroupUsersResult":
          startPushTaskPoll(data.removeGroupUsers.taskId, {
            refetchOnComplete: [{ groupId: props.group.id }],
            onComplete: () => {
              refetch();
            },
          });
          setShowRemoveModal(false);
          setRemoveUsersErrorMessage(null);
          setSelectedItemIds([]);
          break;
        case "CallToWebhookFailedError":
          setRemoveUsersErrorMessage(data.removeGroupUsers.message);
          break;
        default:
          logError(new Error(`failed to remove group users`));
          setRemoveUsersErrorMessage("Error: failed to remove group users");
      }
    } catch (error) {
      logError(error, "failed to remove group users");
      setRemoveUsersErrorMessage(
        getModifiedErrorMessage("Error: failed to remove group users", error)
      );
    }
  };

  const submitUpdateExpiration = async (
    groupUsersToUpdate: UpdateGroupUserInput[]
  ) => {
    logEvent({
      name: "apps_update_user",
      properties: {
        type: "group",
        numUsers: groupUsersToUpdate.length,
      },
    });

    try {
      const { data } = await updateGroupUsers({
        variables: {
          input: {
            groupUsers: groupUsersToUpdate,
          },
        },
      });
      switch (data?.updateGroupUsers.__typename) {
        case "UpdateGroupUsersResult":
          for (const taskId of data.updateGroupUsers.taskIds) {
            startPushTaskPoll(taskId, {
              refetchOnComplete: [{ groupId: props.group.id }],
              onComplete: () => {
                refetch();
              },
            });
          }
          if (data.updateGroupUsers.taskIds.length === 0) {
            refetch();
          }
          setShowUpdateExpirationModal(false);
          setUpdateExpirationErrorMessage("");
          setSelectedItemIds([]);
          break;
        default:
          logError(new Error(`failed to update expiration`));
          setUpdateExpirationErrorMessage("Error: failed to update expiration");
      }
    } catch (error) {
      logError(error, "failed to update expiration");
      setUpdateExpirationErrorMessage(
        getModifiedErrorMessage("Error: failed to update expiration", error)
      );
    }
  };

  let groupUsers: DetailGroupUserFragment[] =
    paginatedGroupUsers?.groupUsers ?? [];
  const rowsById: Record<string, GroupUserRow> = {};
  const rows: GroupUserRow[] = groupUsers.map((groupUser) => {
    const row: GroupUserRow = {
      id:
        groupUser.userId +
        groupUser.access?.directAccessPoint?.accessLevel?.accessLevelRemoteId,
      userId: groupUser.userId,
      groupUser: groupUser,
      [GroupUserSortByField.UserFullName]: groupUser.user?.fullName,
      userAvatarUrl: groupUser.user?.avatarUrl,
      [GroupUserSortByField.UserEmail]: groupUser.user?.email ?? "\u2014",
      idpStatus: groupUser.user?.hrIdpStatus ?? undefined,
      [GroupUserSortByField.AccessLevelName]:
        groupUser.access?.directAccessPoint?.accessLevel?.accessLevelName ||
        "\u2014",
      [GroupUserSortByField.SourceOfAccess]: getGroupUserAccessPathsInfo({
        user: groupUser.user,
        access: groupUser.access,
        group: props.group,
      }),
      source: groupUser.source,
      [GroupUserSortByField.ExpiresAt]:
        groupUser.access?.latestExpiringAccessPoint.expiration,
      [GroupUserSortByField.PropagationStatus]:
        groupUser.propagationStatus?.statusCode,
      [GroupUserSortByField.LastUsedAt]: groupUser.lastUsedAt
        ? moment(groupUser.lastUsedAt).fromNow()
        : "Never",
    };
    rowsById[row.id] = row;
    return row;
  });

  const bulkRightActions: PropsFor<
    typeof TableHeader
  >["bulkRightActions"] = canManage
    ? [
        {
          label: "Update Expiration",
          type: "mainSecondary",
          onClick: () => {
            setShowUpdateExpirationModal(true);
          },
          iconName: "clock",
        },
        {
          label: "Remove",
          type: "danger",
          onClick: () => setShowRemoveModal(true),
          iconName: "trash",
        },
      ]
    : [];

  const getCheckboxDisabledReason = (row: GroupUserRow) => {
    if (!row.groupUser.access?.directAccessPoint) {
      return "The user has no direct access to remove";
    } else if (row.source === GroupUserSource.OnCall) {
      return "This user was added via an on-call binding. To remove them, please remove the on-call binding.";
    } else if (row.source === GroupUserSource.RegularNested) {
      return "This user was added due to membership in a nested group that grants access to this group. To remove them, please remove them from the nested group.";
    }
    return undefined;
  };

  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        height: "100%",
      })}
    >
      <div
        className={sprinkles({
          display: "flex",
          gap: "md",
          alignItems: "center",
          marginBottom: "md",
        })}
      >
        <div className={styles.searchInput}>
          <Input
            leftIconName="search"
            type="search"
            style="search"
            placeholder="Filter by name"
            value={filters.userFullName ?? ""}
            onChange={(value) => {
              logEvent({
                name: "apps_filter_users",
                properties: {
                  type: "group",
                  filter: "userFullName",
                },
              });
              setFilters((draft) => {
                draft.userFullName = value;
              });
            }}
          />
        </div>
        <div className={styles.dropdown}>
          <Select
            multiple
            size="sm"
            value={AVAILABLE_PROPAGATION_STATUSES.filter((status) => {
              if (!filters.propagationStatuses) return false;
              return status.values.some((value) => {
                return filters?.propagationStatuses?.includes(value);
              });
            })}
            options={AVAILABLE_PROPAGATION_STATUSES}
            getOptionLabel={(option) => option.label}
            renderInputValue={(value) => {
              if (!value || value.length === 0) {
                return "Filter by propagation status";
              }
              return value.map((option) => option.label).join(", ");
            }}
            onSelectValue={(value, reason) => {
              logEvent({
                name: "apps_filter_users",
                properties: {
                  type: "group",
                  filter: "propagationStatuses",
                },
              });
              setFilters((draft) => {
                if (!value) return;
                switch (reason) {
                  case "select-option":
                    draft.propagationStatuses = _.uniq([
                      ...(draft.propagationStatuses ?? []),
                      ...value.values,
                    ]);
                    break;
                  case "remove-option":
                    draft.propagationStatuses = draft.propagationStatuses?.filter(
                      (status) =>
                        !value.values.some((value) => status === value)
                    );
                    break;
                }
              });
            }}
          />
        </div>
        {availableAccessLevels && availableAccessLevels.length > 1 && (
          <div className={styles.dropdown}>
            <Select
              multiple
              size="sm"
              value={availableAccessLevels.filter(
                (accessLevel) =>
                  filters.accessLevelRemoteIds?.includes(
                    accessLevel.accessLevelRemoteId
                  ) ?? false
              )}
              options={availableAccessLevels}
              getOptionLabel={(option) => option.accessLevelName}
              renderInputValue={(value) => {
                if (!value || value.length === 0) {
                  return "Filter by role";
                }
                return value.map((option) => option.accessLevelName).join(", ");
              }}
              onSelectValue={(value, reason) => {
                logEvent({
                  name: "apps_filter_users",
                  properties: {
                    type: "group",
                    filter: "roles",
                  },
                });
                setFilters((draft) => {
                  if (!value) return;
                  switch (reason) {
                    case "select-option":
                      draft.accessLevelRemoteIds = _.uniq([
                        ...(draft.accessLevelRemoteIds ?? []),
                        value.accessLevelRemoteId,
                      ]);
                      break;
                    case "remove-option":
                      draft.accessLevelRemoteIds = draft.accessLevelRemoteIds?.filter(
                        (accessLevel) =>
                          accessLevel !== value.accessLevelRemoteId
                      );
                      break;
                  }
                });
              }}
            />
          </div>
        )}
        {
          <Checkbox
            checked={filters.directAccessOnly ?? false}
            onChange={(checked) => {
              logEvent({
                name: "apps_filter_users",
                properties: {
                  type: "group",
                  filter: "directAccessOnly",
                },
              });
              setFilters((draft) => {
                draft.directAccessOnly = checked;
              });
            }}
            label="Direct Access Only"
          />
        }
      </div>
      <TableHeader
        entityType={EntityType.User}
        totalNumRows={paginatedGroupUsers?.totalNumGroupUsers ?? 0}
        selectedNumRows={selectedItemIds.length}
        defaultRightActions={
          canManage
            ? [
                {
                  label: "Add Users",
                  type: "mainSecondary",
                  onClick: () => {
                    transitionTo({
                      pathname: `/groups/${props.group.id}/add-users`,
                    });
                  },
                  iconName: "plus",
                },
              ]
            : []
        }
        bulkRightActions={bulkRightActions}
      />
      <Table
        rows={rows}
        loadingRows={loading}
        totalNumRows={paginatedGroupUsers?.totalNumGroupUsers ?? 0}
        getRowId={(row) => row.id}
        columns={GROUP_USER_COLUMNS}
        onRowClickTransitionTo={(row) =>
          getResourceUrlNew({
            entityId: row.userId,
            entityType: EntityType.User,
          })
        }
        defaultSortBy={GroupUserSortByField.UserFullName}
        onLoadMoreRows={loadMoreRows}
        checkedRowIds={new Set(selectedItemIds)}
        manualSortDirection={
          sortBy && {
            sortBy: sortBy.field,
            sortDirection: sortBy.direction,
          }
        }
        handleManualSort={(field, sortDirection) => {
          if (!sortDirection) {
            setSortBy(undefined);
            return;
          }
          if (!isSortableField(field)) {
            return;
          }
          const direction: SortDirection =
            sortDirection === "DESC" ? SortDirection.Desc : SortDirection.Asc;

          setSortBy({ field, direction });
        }}
        onCheckedRowsChange={
          canManage
            ? (checkedRowIds, checked) => {
                if (checked) {
                  setSelectedItemIds((prev) => [...prev, ...checkedRowIds]);
                  return;
                } else {
                  setSelectedItemIds((prev) =>
                    prev.filter((id) => !checkedRowIds.includes(id))
                  );
                }
              }
            : undefined
        }
        selectAllChecked={
          selectedItemIds.length > 0 &&
          selectedItemIds.length ===
            rows.filter((row) => !getCheckboxDisabledReason(row)).length
        }
        onSelectAll={(checked) =>
          checked
            ? setSelectedItemIds(
                rows
                  .filter((row) => !getCheckboxDisabledReason(row))
                  .map((row) => row.id)
              )
            : setSelectedItemIds([])
        }
        getCheckboxDisabledReason={getCheckboxDisabledReason}
      />
      {showUpdateExpirationModal && (
        <BulkUpdateExpirationModal
          isOpen={showUpdateExpirationModal}
          onSubmit={(expiration) => {
            const groupUsersToUpdate: UpdateGroupUserInput[] = [];
            const accessDurationInMinutes =
              expirationValueToDurationInMinutes(expiration)?.asMinutes() ??
              null;
            for (const rowId of selectedItemIds) {
              const row = rowsById[rowId];
              groupUsersToUpdate.push({
                groupId: props.group.id,
                userId: row.userId,
                durationInMinutes: { int: accessDurationInMinutes },
              });
            }
            submitUpdateExpiration(groupUsersToUpdate);
          }}
          selectedItemIds={selectedItemIds}
          entityName={props.group.name}
          errorMessage={updateExpirationErrorMessage}
          onClose={() => setShowUpdateExpirationModal(false)}
          loading={updateUsersLoading}
        />
      )}
      {showRemoveModal && (
        <Modal
          isOpen
          title="Remove Group Users"
          onClose={() => setShowRemoveModal(false)}
        >
          <Modal.Body>
            {removeUsersErrorMessage && (
              <ModalErrorMessage errorMessage={removeUsersErrorMessage} />
            )}
            Are you sure you want to remove{" "}
            {pluralize("direct assignment", selectedItemIds.length, true)} from
            this group?
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Remove"
            primaryButtonDisabled={selectedItemIds.length === 0}
            primaryButtonLoading={removeUsersLoading}
            onPrimaryButtonClick={() => {
              const groupUsersToRemove: RemoveGroupUserInput[] = [];
              for (const rowId of selectedItemIds) {
                const row = rowsById[rowId];
                groupUsersToRemove.push({
                  groupId: props.group.id,
                  userId: row.userId,
                });
              }
              submitRemoval(groupUsersToRemove);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default GroupUsersTableV3;
