import { Maybe } from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { CodeEditor, FormGroup, Modal } from "components/ui";
import React from "react";
import { RoleDescriptionInfo } from "views/resources/viewer/rows/ResourceCustomRolesRow";

export type ToggleInfo = {
  toggleState: boolean;
  setToggleState: (newToggleState: boolean) => void;
  toggleTextTrue: string; // text displayed when toggleState === true
  toggleTextFalse: string; // text displayed when toggleState === false
};

type UpdateResourceCustomRoleModalProps = {
  isModalOpen: boolean;
  onChange: (description: string) => void;
  onClose: () => void;
  onSubmit: () => void;
  value: string;
  inputTypePassword?: boolean;
  errorMessage?: Maybe<string>;
  disabled?: boolean;
  loading?: boolean;
  submitDisabled?: boolean;
  tooltipText?: string;
  isDataPrivate?: boolean;
  toggleInfo?: ToggleInfo;
  setCurrentPolicy: (policy: Maybe<string>) => void;
  currentPolicy: Maybe<string>;
  savedPolicy: Maybe<string>;
  roleDescriptionInfo: RoleDescriptionInfo;
  resourceCustomRolesCount: number;
};

export const UpdateResourceCustomRoleModal = (
  props: UpdateResourceCustomRoleModalProps
) => {
  return (
    <Modal isOpen={props.isModalOpen} onClose={props.onClose} title="Edit Role">
      <Modal.Body>
        <FormGroup
          label="Policy"
          infoTooltip={props.roleDescriptionInfo.policyDescription || undefined}
        >
          <CodeEditor
            onChangeCode={props.setCurrentPolicy}
            code={props.currentPolicy ?? ""}
          />
        </FormGroup>
        {props.errorMessage && (
          <ModalErrorMessage errorMessage={props.errorMessage} />
        )}
      </Modal.Body>
      <Modal.Footer
        onPrimaryButtonClick={props.onSubmit}
        primaryButtonLoading={props.loading}
        primaryButtonDisabled={
          props.loading ||
          props.disabled ||
          props.value === "" ||
          props.currentPolicy === "" ||
          props.submitDisabled
        }
        primaryButtonLabel="Save"
        secondaryButtonLabel="Revert to Saved"
        onSecondaryButtonClick={() => props.setCurrentPolicy(props.savedPolicy)}
        secondaryButtonDisabled={
          props.disabled || props.currentPolicy === props.savedPolicy
        }
      />
    </Modal>
  );
};

export default UpdateResourceCustomRoleModal;
