import { ResourceType } from "api/generated/graphql";
import { EntityType } from "api/generated/graphql";
import { EntityIcon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { getResourceUrlNew } from "utils/common";

export type AppForAppCell = {
  appID: string;
  appName: string;
  appType: string;
};

interface Props {
  app: AppForAppCell;
  openInNewTab?: boolean;
}

const AppCell = ({ app, openInNewTab }: Props) => {
  return (
    <div
      className={sprinkles({
        display: "flex",
        alignItems: "center",
        gap: "sm",
      })}
    >
      <EntityIcon
        type={app.appType as ResourceType}
        iconStyle="rounded"
        size="sm"
      />
      <div>
        <a
          href={getResourceUrlNew({
            entityId: app.appID,
            entityType: EntityType.Connection,
          })}
          target={openInNewTab ? "_blank" : ""}
          rel="noopener noreferrer"
        >
          {" "}
          {app.appName}
        </a>
      </div>
    </div>
  );
};

export default AppCell;
