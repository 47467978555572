import { EntityIcon, Icon, Skeleton } from "components/ui";
import { generateUniqueId, IconData } from "components/ui/utils";
import React from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";
import { Link } from "react-router-dom";

import * as styles from "./List.css";

const EMPTY_STRING_LABEL = "Unknown Name";

interface ListProps<Item> {
  items: Item[];
  /** Used to determine a unique identifier for a given item. */
  getItemKey: (option: Item) => string;
  /** Used to determine the string value for a given item. If null is returned, a skeleton will be rendered. If "" is returned, placeholder text will be used. */
  getItemLabel: (option: Item) => string | null;
  getIcon?: (option: Item) => IconData;
  getLink?: (option: Item) => string;
  noItemsMessage?: string | React.ReactNode;
  onDeleteItem?: (item: Item) => void;
  onReorder?: (reorderedItems: Item[]) => void;
}

const List = <Item extends {}>(props: ListProps<Item>) => {
  const listId = React.useMemo(() => generateUniqueId("list"), []);

  const { onDeleteItem } = props;

  if (props.items.length === 0) {
    return <div className={styles.noItemsMessage}>{props.noItemsMessage}</div>;
  }

  const renderIcon = (option: Item) => {
    if (!props.getIcon) return null;
    const iconData = props.getIcon(option);

    if (iconData.type === "name") {
      return (
        <div className={styles.icon({ iconStyle: iconData.style })}>
          <Icon name={iconData.icon} size="xs" />
        </div>
      );
    }

    if (iconData.type === "entity") {
      return (
        <div className={styles.icon({ iconStyle: "default" })}>
          <EntityIcon type={iconData.entityType} />
        </div>
      );
    }

    if (!iconData.icon) return null;
    return (
      <div className={styles.icon({ iconStyle: iconData.style })}>
        <Icon externalIconUrl={iconData.icon} size="xs" />
      </div>
    );
  };

  const renderDeleteIcon = (item: Item) => {
    if (!onDeleteItem) {
      return null;
    }
    return (
      <div className={styles.icon({ iconStyle: "default" })}>
        <Icon name="x" onClick={() => onDeleteItem(item)} size="sm" />
      </div>
    );
  };

  const renderItemLabel = (item: Item) => {
    const label = props.getItemLabel(item);
    if (label === null) {
      return <Skeleton width="90%" />;
    }
    if (props.getLink !== undefined) {
      const link = props.getLink(item);
      return <Link to={link}>{label}</Link>;
    }
    return label == "" ? EMPTY_STRING_LABEL : label;
  };

  const handleDragEnd = (result: DropResult) => {
    if (!props.onReorder) return;
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination?.index;
    if (destinationIndex === undefined || sourceIndex === destinationIndex)
      return;

    const newItems = [...props.items];
    const [removed] = newItems.splice(sourceIndex, 1);
    newItems.splice(destinationIndex, 0, removed);

    props.onReorder(newItems);
  };

  if (!props.onReorder) {
    return (
      <div>
        {props.items.map((item) => (
          <div className={styles.item} key={props.getItemKey(item)}>
            <div className={styles.leftContent}>
              {renderIcon(item)}
              {renderItemLabel(item)}
            </div>
            {renderDeleteIcon(item)}
          </div>
        ))}
      </div>
    );
  }

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId={listId}>
        {(provided) => (
          <div {...provided.droppableProps} ref={provided.innerRef}>
            {props.items.map((item, index) => (
              <Draggable
                key={props.getItemKey(item)}
                draggableId={props.getItemKey(item)}
                index={index}
              >
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={styles.item}
                  >
                    <div className={styles.leftContent}>
                      <div className={styles.icon({ iconStyle: "default" })}>
                        <Icon name="menu" size="sm" />
                      </div>
                      {renderIcon(item)}
                      {renderItemLabel(item)}
                    </div>
                    {renderDeleteIcon(item)}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default List;
