import {
  ConfigurationTemplateFragment,
  useGetConfigurationsTemplateQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import { useParams } from "react-router";
import { logError } from "utils/logging";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";
import ConfigurationTemplateColumn from "views/templates/configurations/ConfigurationTemplateColumn";

const ConfigurationTemplatesContent = () => {
  const { configurationTemplateId } = useParams<{
    configurationTemplateId: string;
  }>();
  const { data, error, loading } = useGetConfigurationsTemplateQuery({
    variables: {
      ID: configurationTemplateId,
    },
    skip: !configurationTemplateId,
  });

  if (!data && !error) {
    return null;
  }

  if (error) {
    logError(
      error,
      "failed to get configuration template: " + configurationTemplateId
    );
    return <UnexpectedErrorPage error={error} />;
  }

  if (configurationTemplateId && loading) {
    return (
      <Column isContent maxWidth="lg">
        <ColumnContentSkeleton />
      </Column>
    );
  }

  let configurationTemplate: ConfigurationTemplateFragment | undefined;
  switch (data?.configurationTemplate.__typename) {
    case "ConfigurationTemplateNotFoundError":
      return <NotFoundPage />;
    case "ConfigurationTemplateResult":
      configurationTemplate =
        data.configurationTemplate.configurationTemplate ?? undefined;
      break;
    default:
      logError(new Error("failed to retrieve configuration template"));
      return <UnexpectedErrorPage error={error} />;
  }

  if (!configurationTemplate) {
    return null;
  }

  return (
    <Column isContent maxWidth="lg">
      <ConfigurationTemplateColumn
        configurationTemplate={configurationTemplate}
      />
    </Column>
  );
};

export default ConfigurationTemplatesContent;
