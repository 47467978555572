import {
  GroupFragment,
  Maybe,
  useDeleteGroupMutation,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Banner, Modal } from "components/ui";
import React, { useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { logError, logWarning } from "utils/logging";
import { useURLSearchParam } from "utils/router/hooks";
import { OKTA_APP_ID_URL_KEY } from "views/apps/AppsContext";

export type GroupDeleteModalProps = {
  group: Pick<GroupFragment, "id" | "name" | "connectionId" | "groupType">;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

export const GroupDeleteModal = (props: GroupDeleteModalProps) => {
  const history = useHistory();
  const logEvent = useLogEvent();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [oktaAppId] = useURLSearchParam(OKTA_APP_ID_URL_KEY);

  const [deleteGroup, { loading }] = useDeleteGroupMutation({
    refetchQueries: [
      "ConnectionOverview",
      "Group",
      "GroupsHome",
      "ItemsListSection",
      "AppDetailColumn",
      "AppsListColumn",
    ],
  });

  const deleteModalReset = () => {
    props.setShowModal(false);
    setErrorMessage(null);
  };

  const getRedirectUrl = () => {
    if (oktaAppId) {
      return {
        pathname: `/apps/${oktaAppId}`,
      };
    }
    return {
      pathname: `/apps/${props.group.connectionId}`,
    };
  };

  const handleSubmit = async () => {
    logEvent({
      name: "apps_item_remove_click",
      properties: {
        itemType: props.group.groupType,
      },
    });

    try {
      const { data } = await deleteGroup({
        variables: {
          input: {
            id: props.group.id,
          },
        },
      });
      switch (data?.deleteGroup.__typename) {
        case "DeleteGroupResult":
          deleteModalReset();
          displaySuccessToast("Success: group successfully deleted");
          history.replace(getRedirectUrl());
          break;
        case "GroupNotFoundError":
        case "GroupHasChildrenError":
          logWarning(new Error(data.deleteGroup.message));
          setErrorMessage(data.deleteGroup.message);
          break;
        default:
          logError(new Error(`failed to delete group`));
          setErrorMessage(`Error: failed to delete group"`);
      }
    } catch (e) {
      logError(e, `failed to delete group`);
      setErrorMessage(`Error: failed to delete group`);
    }
  };

  return (
    <Modal
      title={`Remove ${props.group.name} from Opal`}
      isOpen={props.showModal}
      onClose={deleteModalReset}
    >
      <Modal.Body>
        <p>
          <Banner
            message={`If your group has any expiring or on-call access provisioned through Opal,
            this access will become permanent on the end system once you remove the group.`}
            type="warning"
          />
        </p>
        <p>
          Are you sure you want to remove <b>{props.group.name}</b> from Opal?
          This cannot be undone.
        </p>
        <p>
          Removing an group from Opal only deletes its configuration in Opal and
          does not cause access to be removed from the end system. This includes
          access provisioned through Opal.
        </p>
        {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
      </Modal.Body>
      <Modal.Footer
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLabel="Remove"
        primaryButtonLoading={loading}
        primaryButtonType="error"
      />
    </Modal>
  );
};

export default GroupDeleteModal;
