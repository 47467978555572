import {
  GroupType,
  ResourceType,
  useGroupTypesWithCountsQuery,
  useResourceTypesWithCountsQuery,
} from "api/generated/graphql";
import oktaLogo from "assets/icons/okta-app.svg";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { Label, Select } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { logError } from "utils/logging";

interface EntityTypeDropdownProps {
  entityTypes?: (ResourceType | GroupType)[];
  setEntityTypeFilter: (
    entityTypes?: (ResourceType | GroupType)[] | undefined
  ) => void;
  connectionIds?: string[] | undefined;
  additionalValues?: (ResourceType | GroupType)[] | undefined;
  overrideEntityTypes?: (ResourceType | GroupType)[] | undefined;
}

export const getEntityIcon = (entity: ResourceType | GroupType) => {
  if (entity === ResourceType.OktaApp) {
    return oktaLogo;
  } else if (Object.values(ResourceType).includes(entity as ResourceType)) {
    return getResourceTypeInfo(entity as ResourceType)?.icon;
  } else {
    return getGroupTypeInfo(entity as GroupType)?.icon;
  }
};

const getEntityOptionLabel = (entity: ResourceType | GroupType): string => {
  if (entity === ResourceType.OktaApp) {
    return "Okta App";
  } else if (Object.values(ResourceType).includes(entity as ResourceType)) {
    return getResourceTypeInfo(entity as ResourceType)?.fullName ?? "";
  } else {
    return getGroupTypeInfo(entity as GroupType)?.name ?? "";
  }
};

const renderEntityTypeInputValue = (value?: (ResourceType | GroupType)[]) => {
  if (!value || value?.length === 0) {
    return "Filter by Entity Type";
  } else if (value?.length === 1) {
    return getEntityOptionLabel(value[0]);
  } else {
    return (
      getEntityOptionLabel(value[0]) +
      (value?.length > 1 ? ` + ${String(value.length - 1)} more` : "")
    );
  }
};

const EntityTypeDropdown = (props: EntityTypeDropdownProps) => {
  // Fetch all entityTypes
  const resourceTypes = new Set<ResourceType>();

  // Fetching entity types
  const entityTypeToConnectionIDMap: Map<
    ResourceType | GroupType,
    string | null | undefined
  > = new Map();

  const {
    data: groupTypesData,
    error: groupTypesError,
  } = useGroupTypesWithCountsQuery({
    variables: {
      input: { connectionIds: props.connectionIds },
    },
  });

  if (groupTypesError) {
    logError(groupTypesError, "failed to list group types");
  }
  switch (groupTypesData?.groupTypesWithCounts?.__typename) {
    case "GroupTypesWithCountsResult":
      groupTypesData?.groupTypesWithCounts.groupTypesWithCounts.forEach(
        (groupType) => {
          entityTypeToConnectionIDMap.set(
            groupType.groupType,
            groupType.connectionId
          );
        }
      );
      break;
    default:
      break;
  }

  const {
    data: resourceTypesData,
    error: resourceTypesDataError,
  } = useResourceTypesWithCountsQuery({
    variables: {
      input: { connectionIds: props.connectionIds },
    },
  });

  if (resourceTypesDataError) {
    logError(resourceTypesDataError, "failed to list resource types");
  }

  switch (resourceTypesData?.resourceTypesWithCounts?.__typename) {
    case "ResourceTypesWithCountsResult":
      resourceTypesData?.resourceTypesWithCounts.resourceTypesWithCounts.forEach(
        (resource) => {
          resourceTypes.add(resource.resourceType);
          entityTypeToConnectionIDMap.set(
            resource.resourceType,
            resource.connectionId
          );
        }
      );
      break;
    default:
      break;
  }

  const getOptionLabel = (entity: ResourceType | GroupType): string => {
    if (resourceTypes.has(entity as ResourceType)) {
      return getResourceTypeInfo(entity as ResourceType)?.fullName ?? "";
    } else {
      return getGroupTypeInfo(entity as GroupType)?.name ?? "";
    }
  };
  const sortedEntityTypes = Array.from(entityTypeToConnectionIDMap.keys()).sort(
    (a, b) => {
      const labelA = getOptionLabel(a);
      const labelB = getOptionLabel(b);
      return labelA.localeCompare(labelB);
    }
  );

  if (props.additionalValues) {
    sortedEntityTypes.push(...props.additionalValues);
  }
  const handleSetEntityTypeFilter = (
    newEntityTypeFilter?: (ResourceType | GroupType)[] | undefined
  ): void => {
    if (newEntityTypeFilter?.length === 0) {
      props.setEntityTypeFilter(undefined);
    } else {
      props.setEntityTypeFilter(newEntityTypeFilter);
    }
  };

  return (
    <Select<ResourceType | GroupType>
      size="sm"
      multiple
      value={props.entityTypes}
      options={props.overrideEntityTypes ?? sortedEntityTypes}
      getOptionLabel={(entityType) => {
        return getEntityOptionLabel(entityType);
      }}
      getOptionSelected={(option, value) => {
        return getEntityOptionLabel(option) === getEntityOptionLabel(value);
      }}
      placeholderIcon={{ type: "name", icon: "cube" }}
      alwaysShowPlaceholderIcon={true}
      onChange={handleSetEntityTypeFilter}
      getIcon={(entityType) => ({
        type: "src",
        icon: getEntityIcon(entityType),
      })}
      renderInputValue={renderEntityTypeInputValue}
      placeholder={renderEntityTypeInputValue(props.entityTypes)}
      highlightWhenSelected={true}
      listboxFooter={{
        footer: (
          <div
            className={sprinkles({
              fontFamily: "body",
              padding: "md",
            })}
          >
            {props.entityTypes && props.entityTypes.length > 0 ? (
              <Label
                label={`Clear selection (${props.entityTypes.length})`}
                color="blue600V3"
                onClick={() => handleSetEntityTypeFilter([])}
              />
            ) : (
              <Label label="No items selected" color="gray700" />
            )}
          </div>
        ),
        sticky: true,
      }}
    />
  );
};

export default EntityTypeDropdown;
