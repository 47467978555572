import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import { Divider } from "components/ui";
import EventFiltersRow from "views/events/viewer/rows/EventFiltersRow";

const EventFiltersColumn = () => {
  return (
    <>
      <Column>
        <ColumnHeader
          title="Saved filters"
          icon={{ type: "name", icon: "filter" }}
          breadcrumbs={[
            {
              name: "Events",
              to: `/events`,
            },
          ]}
        />
        <Divider margin="md" />
        <EventFiltersRow />
      </Column>
    </>
  );
};

export default EventFiltersColumn;
