import {
  EventFragment,
  EventType,
  PropagationStatusCode,
  PropagationTaskType,
  RequestFragment,
  RequestStatus,
} from "api/generated/graphql";
import {
  AlertBridge,
  NeutralBridge,
  OkBridge,
  PropagatedPill,
  RequestedPill,
  ReviewedPill,
  RevokedPill,
} from "components/requests/RequestPills";
import sprinkles from "css/sprinkles.css";
import React, { ReactFragment } from "react";
import styles from "views/requests/RequestProgressBar.module.scss";

export type RequestProgressBarProps = {
  request: RequestFragment;
  events: EventFragment[];
  propagationStatusCode?: PropagationStatusCode;
  propagationTaskType?: PropagationTaskType;
  setShowPropagationStatusModal: (show: boolean) => void;
};

export const RequestProgressBar = (props: RequestProgressBarProps) => {
  const elements: ReactFragment[] = [<RequestedPill />];

  const accessRevoked = Boolean(
    props.events.find((event) =>
      event.subEvents.find(
        (subEvent) =>
          subEvent.subEventType === EventType.UsersRemovedFromGroups ||
          subEvent.subEventType === EventType.UsersRemovedFromResources ||
          subEvent.subEventType === EventType.ResourcesRemovedFromGroups
        // TODO: add GroupRemovedFromGroups
      )
    )
  );

  let includeRevocationPill = false;
  if (props.request.durationInMinutes) {
    includeRevocationPill = true;
  }

  let propagatedPill = (
    <PropagatedPill
      status={
        props.request.resourceProposal
          ? PropagationStatusCode.Success
          : props.propagationStatusCode
      }
      taskType={props.propagationTaskType}
    />
  );

  if (!props.request.resourceProposal) {
    propagatedPill = (
      <div
        className={sprinkles({ cursor: "pointer" })}
        onClick={() => {
          props.setShowPropagationStatusModal(true);
        }}
      >
        {propagatedPill}
      </div>
    );
  }

  switch (props.request.status) {
    case RequestStatus.Pending:
      elements.push(
        <NeutralBridge />,
        <ReviewedPill status={props.request.status} />,
        <NeutralBridge />,
        <PropagatedPill />
      );
      break;
    case RequestStatus.Approved:
      elements.push(
        <OkBridge />,
        <ReviewedPill status={props.request.status} />,
        <OkBridge />,
        propagatedPill
      );
      break;
    case RequestStatus.Denied:
      elements.push(
        <AlertBridge />,
        <ReviewedPill status={props.request.status} />,
        <NeutralBridge />,
        <PropagatedPill />
      );
      break;
    case RequestStatus.Canceled:
      elements.push(
        <NeutralBridge />,
        <ReviewedPill status={props.request.status} />,
        <NeutralBridge />,
        <PropagatedPill />
      );
      break;
  }

  if (includeRevocationPill) {
    elements.push(<NeutralBridge />);
    elements.push(<RevokedPill complete={accessRevoked} />);
  }

  return <div className={styles.wrapper}>{elements}</div>;
};
