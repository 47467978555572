import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import _ from "lodash";
import React from "react";
import styles from "views/access_reviews/revocations/AccessReviewRevocations.module.scss";

export enum RevocationAction {
  MarkRevoked = "MARK_REVOKED",
  LinkTicket = "LINK_TICKET",
}

type AccessReviewRevocationsToggleButtonProps = {
  state: RevocationAction | undefined;
  onStateChange: (
    state: RevocationAction | undefined,
    bulkAction: boolean
  ) => void;
  markText?: string;
  linkText?: string;
};

const AccessReviewRevocationToggleButton = (
  props: AccessReviewRevocationsToggleButtonProps
) => {
  let markText = props.markText || "Mark Revoked";
  let linkText = props.linkText || "Link Ticket";

  return (
    <div>
      <ToggleButtonGroup
        value={props.state}
        className={styles.buttonGroup}
        exclusive
        onChange={(event, newState) =>
          props.onStateChange(newState || undefined, event.shiftKey)
        }
      >
        <ToggleButton
          className={styles.markRevoked}
          value={RevocationAction.MarkRevoked}
        >
          {markText}
        </ToggleButton>
        <ToggleButton
          className={styles.linkTicket}
          value={RevocationAction.LinkTicket}
        >
          {linkText}
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

export const calculateSelectAllState = (
  actions: RevocationAction[],
  numReviewableItems: number
): RevocationAction | undefined => {
  if (actions.length !== numReviewableItems) {
    return undefined;
  }

  if (_.every(actions, (action) => action === RevocationAction.MarkRevoked)) {
    return RevocationAction.MarkRevoked;
  } else if (
    _.every(actions, (action) => action === RevocationAction.LinkTicket)
  ) {
    return RevocationAction.LinkTicket;
  }

  return undefined;
};

export default AccessReviewRevocationToggleButton;
