import { ErrorEntity } from "api/generated/graphql";
import { ResourceLabel } from "components/label/Label";
import modalErrorMessageStyles from "components/modals/ModalErrorMessage.module.scss";
import React from "react";
import * as Icons from "react-feather";

export type ModalErrorProps = {
  errorMessage: string | React.ReactElement;
  errorEntities?: ErrorEntity[];
};

export const ModalErrorMessage = (props: ModalErrorProps) => {
  let errorMessage = props.errorMessage;
  if (typeof errorMessage === "string") {
    // Display multiple newlines as a single newline
    errorMessage = errorMessage.replace(/\n[ \t]+\n/, "\n");
  }

  return (
    <div className={modalErrorMessageStyles.errorWrapper}>
      <div className={modalErrorMessageStyles.errorMessage}>
        <Icons.AlertTriangle strokeWidth={3} />
        <div className={modalErrorMessageStyles.errorText}>{errorMessage}</div>
      </div>
      <div className={modalErrorMessageStyles.errorEntities}>
        {props.errorEntities?.map((errorEntity) => {
          return (
            <div className={modalErrorMessageStyles.errorEntity}>
              <div className={modalErrorMessageStyles.bullet} />
              <ResourceLabel
                text={errorEntity.name}
                entityId={errorEntity.id.entityId}
                entityTypeNew={errorEntity.id.entityType}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ModalErrorMessage;
