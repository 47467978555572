import AuthContext from "components/auth/AuthContext";
import {
  Badge,
  Breadcrumbs,
  Button,
  ContextMenu,
  EntityIcon,
  Icon,
  Skeleton,
  Tabs,
  Tooltip,
} from "components/ui";
import { IconData } from "components/ui/utils";
import { useContext } from "react";
import { useTransitionTo } from "utils/router/hooks";
import { ACCESS_OPTION_URL_KEY } from "views/apps/AppsContext";

import * as styles from "./ColumnHeader.css";

export interface Props {
  title?: string;
  subtitle?: string;
  icon?: IconData;
  count?: number;
  titleAccessory?: JSX.Element;
  menuError?: boolean;
  menuOptions?: PropsFor<typeof ContextMenu>["options"];
  addMenuOptions?: PropsFor<typeof ContextMenu>["options"];
  rightActions?: JSX.Element;
  breadcrumbs?: PropsFor<typeof Breadcrumbs>["breadcrumbInfos"];
  onClose?: () => void;
  demoBadge?: boolean;
  tabs?: PropsFor<typeof Tabs>["tabInfos"];
  renderTitle?: () => React.ReactNode;
  preserveQuery?: boolean;
  onClickInsights?: () => void;
}

const ColumnHeader: React.FC<Props> = (props) => {
  const { preserveQuery = true } = props;
  const transitionTo = useTransitionTo({
    preserveQueries: preserveQuery ? [ACCESS_OPTION_URL_KEY] : [],
  });
  const { authState } = useContext(AuthContext);

  const renderIcon = () => {
    if (!props.icon) return null;

    if (props.icon.type === "name") {
      return (
        <div className={styles.icon}>
          <Icon name={props.icon.icon} size="lg" iconStyle={props.icon.style} />
        </div>
      );
    }

    if (props.icon.type === "src") {
      return (
        <div className={styles.icon}>
          <Icon data={props.icon} size="lg" iconStyle={props.icon.style} />
        </div>
      );
    }

    if (props.icon.type === "entity") {
      return (
        <div className={styles.icon}>
          <EntityIcon type={props.icon.entityType} size="lg" />
        </div>
      );
    }

    return null;
  };

  const renderBackButton = () => {
    if (!props.breadcrumbs || props.breadcrumbs.length === 0) return null;

    const lastBreadcrumb = props.breadcrumbs[props.breadcrumbs.length - 1];

    // Note: this currently doesn't support entity type breadcrumbs.
    // See: BreadcrumbInfo in breadcrumbs.tsx
    const backTo = "to" in lastBreadcrumb ? lastBreadcrumb.to : undefined;
    if (!backTo) return null;

    return (
      <Button
        leftIconName="chevron-left"
        borderless
        size="sm"
        onClick={() => transitionTo({ pathname: backTo })}
        round
      />
    );
  };

  const renderCloseButton = () => {
    if (!props.onClose) return null;

    return (
      <Button
        leftIconName="x"
        borderless
        size="sm"
        onClick={props.onClose}
        round
      />
    );
  };

  const renderIconRow = () => {
    return (
      <div className={styles.iconRow}>
        <div>
          {renderBackButton()}
          {renderCloseButton()}
          {props.demoBadge && <div className={styles.demoBadge}>Demo only</div>}
        </div>
        <div className={styles.actions}>
          {props.onClickInsights && authState.user?.isAdmin ? (
            <Tooltip tooltipText="View Insights">
              <Button
                leftIconName="department"
                borderless
                size="sm"
                onClick={props.onClickInsights}
                round
              />
            </Tooltip>
          ) : null}
          {props.addMenuOptions && props.addMenuOptions.length ? (
            <ContextMenu
              rightAligned
              options={props.addMenuOptions}
              renderButton={(onClick) => (
                <div className={styles.overflowMenuContainer} onClick={onClick}>
                  <Button leftIconName="plus" borderless size="sm" round />
                </div>
              )}
            />
          ) : null}
          {props.menuOptions && props.menuOptions.length ? (
            <ContextMenu
              rightAligned
              options={props.menuOptions}
              renderButton={(onClick) => (
                <div className={styles.overflowMenuContainer} onClick={onClick}>
                  <Button
                    leftIconName="dots-horizontal"
                    borderless
                    size="sm"
                    round
                  />
                  {props.menuError ? (
                    <div className={styles.overflowMenuAlertIcon}>
                      <Button
                        leftIconName="alert-triangle"
                        borderless
                        size="sm"
                        type="warning"
                        round
                      />
                    </div>
                  ) : null}
                </div>
              )}
            />
          ) : null}
        </div>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {renderIconRow()}
      <div>
        {renderIcon()}
        {props.renderTitle ? (
          props.renderTitle()
        ) : (
          <>
            <div className={styles.titleRow}>
              <div className={styles.title} title={props.title}>
                {props.title}
              </div>
              {props.count ? <Badge count={props.count} size="xs" /> : null}
              {props.titleAccessory ? props.titleAccessory : null}
            </div>
            <div className={styles.footer}>
              {props.breadcrumbs ? (
                <Breadcrumbs breadcrumbInfos={props.breadcrumbs} />
              ) : (
                <div className={styles.subtitle} title={props.subtitle}>
                  {props.subtitle}
                </div>
              )}
              {props.rightActions ? (
                <div className={styles.rightActionsContainer}>
                  {props.rightActions}
                </div>
              ) : null}
            </div>
          </>
        )}
      </div>
      {props.tabs && (
        <div className={styles.tabsContainer}>
          <Tabs tabInfos={props.tabs} round />
        </div>
      )}
    </div>
  );
};

export const ColumnHeaderSkeleton = () => {
  return (
    <div className={styles.container}>
      <div className={styles.iconRow}></div>
      <Skeleton variant="rect" height="42px" width="42px" />
      <Skeleton />
      <Skeleton />
    </div>
  );
};

export default ColumnHeader;
