import {
  EntityType,
  Maybe,
  ResourceUserAccessFragment,
} from "api/generated/graphql";
import AccessPathLabel from "components/label/AccessPathLabel";
import {
  AccessPointsLabel,
  ResourceForAccessLabel,
  UserForAccessLabel,
} from "components/label/AccessPointsLabel";
import EventLabel from "components/label/item_labels/EventLabel";
import {
  ResourceLabel,
  TimeLabel,
  timeValueIndefinite,
} from "components/label/Label";
import MaterialTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MaterialTable";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import React from "react";

type ResourceUserAccessPointsLabelProps = ResourceUserAccessInfo & {
  isNotClickable?: boolean;
};

export const ResourceUserAccessPointsLabel = (
  props: ResourceUserAccessPointsLabelProps
) => {
  const headers: Header<ResourceUserAccessPointRow>[] = [
    { id: "expirationDate", label: "Expires" },
    { id: "event", label: "Event" },
    { id: "accessPath", label: "Access Path" },
  ];

  const { user, resource, access, isNotClickable } = props;

  const accessPathsInfo = getResourceUserAccessPathsInfo(props);

  const numAccessPoints = accessPathsInfo.accessPointRows.length;
  if (numAccessPoints === 0) {
    return <div>{"--"}</div>;
  }

  return (
    <AccessPointsLabel
      subject={user}
      object={resource}
      roleName={access?.accessLevel?.accessLevelRemoteId}
      hasDirectAccess={accessPathsInfo.hasDirectAccess}
      numAccessPoints={numAccessPoints}
      numInheritedAccessPoints={accessPathsInfo.numInheritedAccessPoints}
      numGroupAccessPoints={accessPathsInfo.numGroupAccessPoints}
      accessPathContent={
        <div className={sprinkles({ padding: "sm" })}>
          <MaterialTable<ResourceUserAccessPointRow>
            headers={headers}
            rows={accessPathsInfo.accessPointRows}
            denseFormatting={true}
            tableContainerStyles={{ maxHeight: 300 }}
          />
        </div>
      }
      isNotClickable={isNotClickable}
    />
  );
};

export type ResourceUserAccessPathsInfo = {
  accessPointRows: CellRow<ResourceUserAccessPointRow>[];
  numInheritedAccessPoints: number;
  numGroupAccessPoints: number;
  hasDirectAccess: boolean;
};

interface ResourceUserAccessPointRow {
  expirationDate: string;
  event: string;
  accessPath: string;
}

const expirationColMinWidth = "60px";
const accessPathColMinWidth = "400px";
const accessPathColMaxWidth = "800px";

type ResourceUserAccessInfo = {
  user: Maybe<UserForAccessLabel> | undefined;
  access: Maybe<ResourceUserAccessFragment> | undefined;
  resource: Maybe<ResourceForAccessLabel> | undefined;
};

export const getResourceUserAccessPathsInfo = (
  props: ResourceUserAccessInfo
) => {
  const accessPointRows: CellRow<ResourceUserAccessPointRow>[] = [];
  const directUserAccess = props.access?.directAccessPoint;

  if (directUserAccess) {
    const expirationTime = directUserAccess.expiration
      ? moment(new Date(directUserAccess.expiration))
      : null;
    accessPointRows.push({
      id: directUserAccess.resourceId,
      data: {
        expirationDate: {
          value:
            expirationTime?.unix() ||
            `${directUserAccess.resourceId}-expiration`,
          sortValue: expirationTime?.unix() || timeValueIndefinite,
          element: (
            <TimeLabel
              time={expirationTime}
              supportTicket={directUserAccess.supportTicket}
            />
          ),
          style: { minWidth: expirationColMinWidth },
        },
        event: {
          value: directUserAccess.eventId,
          element: <EventLabel eventId={directUserAccess.eventId} />,
        },
        accessPath: {
          value: `${directUserAccess.resourceId}-access-path`,
          element: (
            <AccessPathLabel
              accessLabels={[
                <ResourceLabel
                  entityTypeNew={EntityType.User}
                  entityId={props.user?.id}
                  avatar={props.user?.avatarUrl}
                  text={props.user?.fullName}
                  maxChars={32}
                />,
                <ResourceLabel
                  entityTypeNew={EntityType.Resource}
                  entityId={props.resource?.id}
                  text={props.resource?.name}
                  resourceType={props.resource?.resourceType}
                  maxChars={32}
                />,
              ]}
              maxWidth={accessPathColMaxWidth}
            />
          ),
          style: { minWidth: accessPathColMinWidth },
        },
      },
    });
  }

  props.access?.inheritedAccessPoints.forEach((inheritedUserAccess) => {
    const expirationTime = inheritedUserAccess.expiration
      ? moment(new Date(inheritedUserAccess.expiration))
      : null;
    const id = inheritedUserAccess.resourceId || "unknown-inherited";

    accessPointRows.push({
      id: id,
      data: {
        expirationDate: {
          value: expirationTime?.unix() || `${id}-expiration`,
          sortValue: expirationTime?.unix() || timeValueIndefinite,
          element: (
            <TimeLabel
              time={expirationTime}
              supportTicket={inheritedUserAccess.supportTicket}
              useExpiringLabel
            />
          ),
          style: { minWidth: expirationColMinWidth },
        },
        event: {
          value: inheritedUserAccess.eventId,
          element: <EventLabel eventId={inheritedUserAccess.eventId} />,
        },
        accessPath: {
          value: `${id}-access-path`,
          element:
            // Special-case legacy inherited access
            inheritedUserAccess.resourceId == props.access?.resourceId ? (
              <AccessPathLabel
                accessLabels={[
                  <ResourceLabel
                    entityTypeNew={EntityType.User}
                    entityId={props.user?.id}
                    avatar={props.user?.avatarUrl}
                    text={props.user?.fullName}
                    maxChars={32}
                  />,
                  <ResourceLabel
                    entityTypeNew={EntityType.Resource}
                    text={"Unknown resource"}
                  />,
                  <ResourceLabel
                    entityTypeNew={EntityType.Resource}
                    entityId={props.resource?.id}
                    text={props.resource?.name}
                    resourceType={props.resource?.resourceType}
                    maxChars={32}
                  />,
                ]}
                maxWidth={accessPathColMaxWidth}
              />
            ) : (
              <AccessPathLabel
                accessLabels={[
                  <ResourceLabel
                    entityTypeNew={EntityType.User}
                    entityId={props.user?.id}
                    avatar={props.user?.avatarUrl}
                    text={props.user?.fullName}
                    maxChars={32}
                  />,
                  <ResourceLabel
                    entityTypeNew={EntityType.Resource}
                    entityId={id}
                    text={inheritedUserAccess.resourceName}
                    resourceType={inheritedUserAccess.resourceType}
                    maxChars={32}
                  />,
                  <ResourceLabel
                    entityTypeNew={EntityType.Resource}
                    entityId={props.resource?.id}
                    text={props.resource?.name}
                    resourceType={props.resource?.resourceType}
                    maxChars={32}
                  />,
                ]}
                maxWidth={accessPathColMaxWidth}
              />
            ),
          style: { minWidth: accessPathColMinWidth },
        },
      },
    });
  });

  props.access?.groupUserAccesses.forEach((groupUserAccess) => {
    const directAccessPoint = groupUserAccess.directAccessPoint;

    if (directAccessPoint) {
      const expirationTime = directAccessPoint.expiration
        ? moment(new Date(directAccessPoint.expiration))
        : null;

      accessPointRows.push({
        id: groupUserAccess.groupId,
        data: {
          expirationDate: {
            value:
              expirationTime?.unix() ||
              `${directAccessPoint.groupId}-expiration`,
            sortValue: expirationTime?.unix() || timeValueIndefinite,
            element: (
              <TimeLabel
                time={expirationTime}
                supportTicket={directAccessPoint.supportTicket}
                useExpiringLabel
              />
            ),
            style: { minWidth: expirationColMinWidth },
          },
          event: {
            value: directAccessPoint.eventId,
            element: <EventLabel eventId={directAccessPoint.eventId} />,
          },
          accessPath: {
            value: `${groupUserAccess.groupId}-access-path`,
            element: (
              <AccessPathLabel
                accessLabels={[
                  <ResourceLabel
                    entityTypeNew={EntityType.User}
                    entityId={props.user?.id}
                    avatar={props.user?.avatarUrl}
                    text={props.user?.fullName}
                    maxChars={32}
                  />,
                  <ResourceLabel
                    entityTypeNew={EntityType.Group}
                    entityId={directAccessPoint.groupId}
                    text={directAccessPoint.groupName}
                    groupType={directAccessPoint.groupType}
                    maxChars={32}
                    inactive={!groupUserAccess.isGroupManaged}
                    tooltipText={
                      !groupUserAccess.isGroupManaged
                        ? "This group is currently not managed in Opal"
                        : null
                    }
                  />,
                  <ResourceLabel
                    entityTypeNew={EntityType.Resource}
                    entityId={props.resource?.id}
                    text={props.resource?.name}
                    resourceType={props.resource?.resourceType}
                    maxChars={32}
                  />,
                ]}
                maxWidth={accessPathColMaxWidth}
              />
            ),
            style: { minWidth: accessPathColMinWidth },
          },
        },
      });
    }

    groupUserAccess.indirectAccessPointPaths.forEach(
      (indirectAccessPointPath) => {
        const resolvedAccessPoint = indirectAccessPointPath.resolvedAccessPoint;

        if (resolvedAccessPoint) {
          const expirationTime = resolvedAccessPoint?.expiration
            ? moment(new Date(resolvedAccessPoint.expiration))
            : null;

          let accessPathLabels = [
            <ResourceLabel
              entityTypeNew={EntityType.User}
              entityId={props.user?.id}
              avatar={props.user?.avatarUrl}
              text={props.user?.fullName}
              maxChars={32}
            />,
          ];

          accessPathLabels = [
            ...accessPathLabels,
            ...indirectAccessPointPath.indirectAccessPointPath.map(
              (indirectAccessPoint) => {
                return (
                  <ResourceLabel
                    entityTypeNew={EntityType.Group}
                    entityId={indirectAccessPoint.groupId}
                    text={indirectAccessPoint.groupName}
                    groupType={indirectAccessPoint.groupType}
                    maxChars={32}
                  />
                );
              }
            ),
          ];

          accessPathLabels.push(
            <ResourceLabel
              entityTypeNew={EntityType.Resource}
              entityId={props.resource?.id}
              resourceType={props.resource?.resourceType}
              text={props.resource?.name}
              maxChars={32}
            />
          );

          accessPointRows.push({
            id: resolvedAccessPoint.groupId,
            data: {
              expirationDate: {
                value:
                  expirationTime?.unix() ||
                  `${groupUserAccess.groupId}-expiration`,
                sortValue: expirationTime?.unix() || timeValueIndefinite,
                element: (
                  <TimeLabel
                    time={expirationTime}
                    supportTicket={resolvedAccessPoint.supportTicket}
                  />
                ),
                style: { minWidth: expirationColMinWidth },
              },
              event: {
                value: resolvedAccessPoint.eventId,
                element: <EventLabel eventId={resolvedAccessPoint.eventId} />,
              },
              accessPath: {
                value: `${groupUserAccess.groupId}-access-path`,
                element: (
                  <AccessPathLabel
                    accessLabels={accessPathLabels}
                    maxWidth={accessPathColMaxWidth}
                  />
                ),
                style: { minWidth: accessPathColMinWidth },
              },
            },
          });
        }
      }
    );
  });

  return {
    accessPointRows,
    hasDirectAccess: !!directUserAccess,
    numInheritedAccessPoints: props.access?.inheritedAccessPoints.length || 0,
    numGroupAccessPoints: props.access?.groupUserAccesses.length || 0,
  };
};
