import {
  AccessReviewTab,
  AccessReviewUserFragment,
  useOngoingAccessReviewSubtabStatsQuery,
} from "api/generated/graphql";
import EntityViewer from "components/entity_viewer/EntityViewer";
import { EntityViewerTabType } from "components/entity_viewer/EntityViewerTabs";
import { useContext, useState } from "react";
import { logError } from "utils/logging";
import { useReadUINotification } from "utils/notifications";
import AccessReviewContext from "views/access_reviews/AccessReviewContext";
import AccessReviewUserActionButtons from "views/access_reviews/users/AccessReviewUserActionButtons";
import AccessReviewUserGroupsRow from "views/access_reviews/users/AccessReviewUserGroupsRow";
import AccessReviewUserResourcesRow from "views/access_reviews/users/AccessReviewUserResourcesRow";
import AccessReviewUserTitleRow from "views/access_reviews/users/AccessReviewUserTitleRow";

import { getDeadlineProgressBarInfo } from "../common/utils";
import AccessReviewUserOverview from "./AccessReviewUserOverview";

type AccessReviewUserViewerProps = {
  accessReviewUser: AccessReviewUserFragment;
};

export const AccessReviewUserViewer = (props: AccessReviewUserViewerProps) => {
  const { accessReviewState } = useContext(AccessReviewContext);

  const [selectedTab, setSelectedTab] = useState<EntityViewerTabType>(
    EntityViewerTabType.Resources
  );
  useReadUINotification(props.accessReviewUser.accessReviewId);

  const { data, error } = useOngoingAccessReviewSubtabStatsQuery({
    variables: {
      input: {
        tab: AccessReviewTab.UsersTab,
        itemId: props.accessReviewUser.userId,
        accessReviewId: props.accessReviewUser.accessReview?.id ?? "",
      },
    },
  });
  let tabStats = null;
  if (data) {
    switch (data.ongoingAccessReviewSubtabStats.__typename) {
      case "OngoingAccessReviewSubtabStatsResult":
        tabStats =
          data.ongoingAccessReviewSubtabStats.ongoingAccessReviewSubtabStats ||
          null;
        break;
      case "AccessReviewNotFoundError":
      case "AccessReviewAlreadyStoppedError":
        break;
      default:
        logError(new Error(`failed to retrieve access review stats`));
    }
  } else if (error) {
    logError(error, `failed to retrieve access review stats`);
  }

  const deadlineInfo = getDeadlineProgressBarInfo(
    props.accessReviewUser.accessReview
  );
  const badgeColor = Number(deadlineInfo.daysToShow) < 3 ? "error" : "warning";

  return (
    <EntityViewer
      title={
        <AccessReviewUserTitleRow accessReviewUser={props.accessReviewUser} />
      }
      tabInfos={[
        {
          tabType: EntityViewerTabType.Overview,
          tabContent: (
            <AccessReviewUserOverview userId={props.accessReviewUser.userId} />
          ),
        },
        {
          tabType: EntityViewerTabType.Resources,
          tabContent: (
            <AccessReviewUserResourcesRow
              accessReviewUser={props.accessReviewUser}
              setSelectedTab={setSelectedTab}
            />
          ),
          tabCount:
            tabStats && tabStats.numResourcesToReview
              ? tabStats.numResourcesToReview
              : undefined,
          badgeColor,
        },
        {
          tabType: EntityViewerTabType.Groups,
          tabContent: (
            <AccessReviewUserGroupsRow
              accessReviewUser={props.accessReviewUser}
              setSelectedTab={setSelectedTab}
            />
          ),
          tabCount:
            tabStats && tabStats.numGroupsToReview
              ? tabStats.numGroupsToReview
              : undefined,
          badgeColor,
        },
      ]}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      button={
        accessReviewState.ongoingAccessReviewIdSet.has(
          props.accessReviewUser.accessReviewId
        ) ? (
          <AccessReviewUserActionButtons
            accessReviewUser={props.accessReviewUser}
          />
        ) : (
          <></>
        )
      }
    />
  );
};

export default AccessReviewUserViewer;
