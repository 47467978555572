import { getModifiedErrorMessage } from "api/ApiContext";
import { useUpdateOwnerUsersForOwnerMutation } from "api/generated/graphql";
import { PaginatedUserDropdown } from "components/dropdown/PaginatedUserDropdown";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { FormGroup, List, Modal } from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import React, { useState } from "react";
import { logError } from "utils/logging";

interface User {
  id: string;
  fullName: string;
  avatarUrl: string;
}

interface OwnerEditUsersModalProps {
  ownerId: string;
  users: User[];
  hasEscalation: boolean;
  hasSourceGroup: boolean;
  isOpen: boolean;
  onClose: () => void;
}

export const OwnerEditUsersModal = (props: OwnerEditUsersModalProps) => {
  const { displaySuccessToast } = useToast();
  const [orderedUsers, setOrderedUsers] = useState<User[]>(props.users);
  const [errorMessage, setErrorMessage] = useState("");

  const [
    updateOwnerUsersForOwnerMutation,
    { loading },
  ] = useUpdateOwnerUsersForOwnerMutation();

  React.useEffect(() => {
    setOrderedUsers(props.users);
  }, [props.users]);

  const handleSubmit = async () => {
    try {
      const { data } = await updateOwnerUsersForOwnerMutation({
        variables: {
          input: {
            ownerId: props.ownerId,
            userIds: orderedUsers.map((user) => user.id),
          },
        },
        refetchQueries: ["Owner"],
      });
      switch (data?.setOwnerUsers.__typename) {
        case "UpdateOwnerUsersForOwnerResult":
          displaySuccessToast("Success: owner edited");
          props.onClose();
          break;
        case "CannotAddUserToSyncedOwnerError":
          setErrorMessage(
            `Error: this owner's user list is link to a group: ${data.setOwnerUsers.group?.name}`
          );
          break;
        default:
          logError(new Error(`failed to update owner`));
          setErrorMessage(`Error: failed to update owner`);
      }
    } catch (error) {
      logError(error, "failed to update owner");
      setErrorMessage(
        getModifiedErrorMessage("Error: failed to update owner", error)
      );
    }
  };

  const modalUserListMessage = props.hasSourceGroup
    ? "Edit user escalation order"
    : "Edit existing users";

  return (
    <Modal isOpen={props.isOpen} onClose={props.onClose} title="Edit Owner">
      <Modal.Body>
        {errorMessage ? (
          <ModalErrorMessage errorMessage={errorMessage} />
        ) : null}
        <FormGroup label={modalUserListMessage}>
          <List
            items={orderedUsers}
            getItemLabel={(user) => user.fullName}
            getItemKey={(user) => user.id}
            getIcon={(option) => ({
              type: "src",
              style: "rounded",
              icon: option.avatarUrl || defaultAvatarURL,
              fallbackIcon: defaultAvatarURL,
            })}
            onDeleteItem={
              !props.hasSourceGroup
                ? (user) =>
                    setOrderedUsers((oldUsers) =>
                      oldUsers.filter((u) => u.id !== user.id)
                    )
                : undefined
            }
            noItemsMessage="An Owner must contain at least 1 user."
            onReorder={
              props.hasEscalation
                ? (newUsers) => setOrderedUsers(newUsers)
                : undefined
            }
          />
        </FormGroup>
        {!props.hasSourceGroup && (
          <FormGroup label="Add a user">
            <PaginatedUserDropdown
              placeholder="Add a user"
              onChange={(newUser) => {
                if (!newUser) return;
                if (orderedUsers.some((user) => user.id === newUser.id)) {
                  return;
                }
                setOrderedUsers((oldUsers) => [...oldUsers, newUser]);
              }}
              selectOnly
              hiddenUserIds={orderedUsers.map((u) => u.id)}
            />
          </FormGroup>
        )}
      </Modal.Body>
      <Modal.Footer
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLabel="Submit"
        primaryButtonLoading={loading}
        primaryButtonDisabled={orderedUsers.length === 0}
      />
    </Modal>
  );
};

export default OwnerEditUsersModal;
