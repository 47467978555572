import { EntityType, OwnedResourceFragment } from "api/generated/graphql";
import ConnectionLabel from "components/label/item_labels/ConnectionLabel";
import { ResourceLabel } from "components/label/Label";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import MaterialTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MaterialTable";
import { Icon } from "components/ui";
import React from "react";
import { useHistory } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { getResourceSublabel } from "utils/resources";

interface OwnerResourcesTableRow {
  name: string;
  connection: string;
  admin: string;
  reviewer: string;
}

type OwnerResourcesTableProps = {
  ownedResources: OwnedResourceFragment[];
};

export const OwnerResourcesTable = (props: OwnerResourcesTableProps) => {
  const history = useHistory();

  const headers: Header<OwnerResourcesTableRow>[] = [
    { id: "name", label: "Name" },
    { id: "connection", label: "App" },
    { id: "admin", label: "Admin" },
    { id: "reviewer", label: "Reviewer" },
  ];

  const rows: CellRow<OwnerResourcesTableRow>[] = props.ownedResources.map(
    ({ resource, isAdmin, isReviewer }) => {
      const subText = resource ? getResourceSublabel(resource) : "--";
      return {
        id: resource.id,
        data: {
          name: {
            value: resource.name,
            element: (
              <ResourceLabel
                text={resource.name}
                subText={subText}
                entityType={EntityTypeDeprecated.Resource}
                resourceType={resource?.resourceType}
                bold={true}
                icon={resource?.iconUrl}
                iconLarge={true}
                pointerCursor={true}
              />
            ),
            clickHandler: () => {
              history.push(
                getResourceUrlNew({
                  entityId: resource.id,
                  entityType: EntityType.Resource,
                })
              );
            },
          },
          connection: {
            sortValue: resource.connection?.name || "",
            value: resource.connection?.id || "",
            element: (
              <ConnectionLabel
                text={resource.connection?.name}
                connectionId={resource.connection?.id}
                connectionType={resource.connection?.connectionType}
              />
            ),
          },
          admin: {
            value: String(isAdmin),
            element: isAdmin ? <Icon name="check" size="sm" /> : <></>,
          },
          reviewer: {
            value: String(isReviewer),
            element: isReviewer ? <Icon name="check" size="sm" /> : <></>,
          },
        },
      };
    }
  );

  return (
    <EmptyStateContentWrapper
      content={
        <MaterialTable<OwnerResourcesTableRow> headers={headers} rows={rows} />
      }
      entityType={EntityType.Resource}
      title="No resources owned"
      isEmpty={props.ownedResources.length === 0}
    />
  );
};

export default OwnerResourcesTable;
