import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import {
  EventFilterFields,
  EventType,
  FirstPartyTokenFragment,
  UserDropdownPreviewFragment,
  UserFragment,
  useUserQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import FirstPartyTokenDropdown from "components/dropdown/FirstPartyTokenDropdown";
import { PaginatedUserDropdown } from "components/dropdown/PaginatedUserDropdown";
import RowBar from "components/layout/RowBar";
import {
  Button,
  ContextMenu,
  DataElement,
  Icon,
  Input,
  Select,
} from "components/ui";
import _ from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { eventTypeToString } from "utils/events";

import * as styles from "./EventFilterBar.css";
import { SaveFilterModal } from "./Events";
import DateFilterInput from "./filters/DateFilterInput";
import { buildFilterURLParams, useEventFilter, useEventTypes } from "./utils";

const EventFilterBar = () => {
  const history = useHistory();
  const location = useLocation();
  const { authState } = useContext(AuthContext);
  const eventFilter = useEventFilter();
  const [filtersInfosState, setFiltersInfosState] = useState(eventFilter);
  const [filterErrors, setFilterErrors] = useState<
    Record<keyof typeof eventFilter, string | undefined>
  >({
    startDate: undefined,
    endDate: undefined,
    actors: undefined,
    objects: undefined,
    eventTypes: undefined,
    systemEvents: undefined,
    apiTokens: undefined,
  });
  const [showSaveModal, setShowSaveModal] = useState(false);
  useEffect(() => {
    setFiltersInfosState(eventFilter);
    // Re-render whenever the route changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);
  const allEventTypes = useEventTypes();

  const startDateInfo = filtersInfosState.startDate;
  const endDateInfo = filtersInfosState.endDate;
  const actorInfo = filtersInfosState.actors;
  const eventTypeInfo = filtersInfosState.eventTypes;
  const objectInfo = filtersInfosState.objects;
  const systemEventsInfo = filtersInfosState.systemEvents;
  const apiTokensInfo = filtersInfosState.apiTokens;

  const propagationErrorEventTypes = [
    EventType.PropagationFailureAddUserToGroup,
    EventType.PropagationFailureAddUserToResource,
    EventType.PropagationFailureAddResourceToGroup,
    EventType.PropagationFailureRemoveUserFromGroup,
    EventType.PropagationFailureRemoveUserFromResource,
    EventType.PropagationFailureRemoveUserFromConnection,
    EventType.PropagationFailureRemoveResourceFromGroup,
  ];
  const isPropagationErrorFilter = _.isEqual(
    eventTypeInfo?.eventTypes,
    propagationErrorEventTypes
  );

  let actorUser: UserFragment | undefined;
  const { data: actorUserData } = useUserQuery({
    variables: {
      input: {
        id: actorInfo?.userId ?? "",
      },
    },
    skip: !actorInfo?.userId,
  });

  switch (actorUserData?.user.__typename) {
    case "UserResult":
      actorUser = actorUserData.user.user;
  }

  const renderAddFilterButton = (
    onClick: (event: React.MouseEvent) => void
  ) => (
    <Button
      label="Add filter"
      type="primary"
      onClick={onClick}
      size="sm"
      outline
      leftIconName="plus"
      rightIconName="chevron-down"
    />
  );

  const filterMenuOptions: PropsFor<typeof ContextMenu>["options"] = [];
  if (!startDateInfo && !endDateInfo) {
    filterMenuOptions.push({
      label: "Date range",
      onClick: () => {
        setFiltersInfosState((prev) => {
          return {
            ...prev,
            startDate: {
              date: "",
            },
            endDate: {
              date: "",
            },
          };
        });
      },
      icon: { type: "name", icon: "calendar" },
    });
  }

  if (!actorInfo) {
    filterMenuOptions.push({
      label: "User",
      onClick: () => {
        setFiltersInfosState((prev) => {
          return {
            ...prev,
            actors: {
              userId: "",
            },
          };
        });
      },
      icon: { type: "name", icon: "user" },
    });
  }

  if (!eventTypeInfo) {
    filterMenuOptions.push({
      label: "Event type",
      onClick: () => {
        setFiltersInfosState((prev) => {
          return {
            ...prev,
            eventTypes: {
              eventTypes: [],
            },
          };
        });
      },
      icon: { type: "name", icon: "events" },
    });
  }

  if (!objectInfo) {
    filterMenuOptions.push({
      label: "Object",
      onClick: () => {
        setFiltersInfosState((prev) => {
          return {
            ...prev,
            objects: {
              objectId: "",
            },
          };
        });
      },
      icon: { type: "name", icon: "cube" },
    });
  }

  if (!systemEventsInfo) {
    filterMenuOptions.push({
      label: "System Events",
      onClick: () => {
        setFiltersInfosState((prev) => {
          return {
            ...prev,
            systemEvents: true,
          };
        });
      },
      icon: { type: "name", icon: "refresh" },
    });
  }

  if (!eventTypeInfo) {
    filterMenuOptions.push({
      label: "Propagation Errors",
      onClick: () => {
        setFiltersInfosState((prev) => {
          return {
            ...prev,
            eventTypes: {
              eventTypes: propagationErrorEventTypes,
            },
          };
        });
      },
      icon: { type: "name", icon: "alert-circle" },
    });
  }

  if (!apiTokensInfo) {
    filterMenuOptions.push({
      label: "API Tokens",
      onClick: () => {
        setFiltersInfosState((prev) => {
          return {
            ...prev,
            apiTokens: {
              apiTokenLabel: "",
              apiTokenPreview: "",
            },
          };
        });
      },
      icon: { type: "name", icon: "key" },
    });
  }

  const handleDateChange = (key: "endDate" | "startDate") => (
    date: MaterialUiPickersDate
  ) => {
    if (!date) {
      return;
    }
    setFiltersInfosState((prev) => ({
      ...prev,
      [key]: {
        date: date.format("YYYY/MM/DD"),
      },
    }));
    setFilterErrors((prev) => ({
      ...prev,
      [key]: undefined,
    }));
  };

  const handleActorChange = (user?: UserDropdownPreviewFragment) => {
    if (!user) {
      return;
    }

    setFiltersInfosState((prev) => {
      return {
        ...prev,
        actors: {
          userId: user.id,
        },
      };
    });
  };

  const handleEventTypeChange = (eventType?: EventType) => {
    if (!eventType) {
      return;
    }

    setFiltersInfosState((prev) => {
      return {
        ...prev,
        eventTypes: {
          eventTypes: [eventType],
        },
      };
    });
  };

  const handleObjectChange = (objectId: string) => {
    setFiltersInfosState((prev) => {
      return {
        ...prev,
        objects: {
          objectId,
        },
      };
    });
  };

  const handleApiTokenChange = (apiToken?: FirstPartyTokenFragment) => {
    if (!apiToken) {
      return;
    }

    setFiltersInfosState((prev) => {
      return {
        ...prev,
        apiTokens: {
          apiTokenLabel: apiToken.tokenLabel,
          apiTokenPreview: apiToken.tokenPreview,
        },
      };
    });
  };

  const handleRemoveFilter = (key: keyof EventFilterFields) => {
    setFiltersInfosState((prev) => {
      delete prev[key];

      if (Object.keys(prev).length === 0) {
        history.push("?");
      }

      return {
        ...prev,
      };
    });
  };

  const dateFilter =
    startDateInfo || endDateInfo ? (
      <div className={styles.filterContainer}>
        <DateFilterInput
          type="start"
          onDateChange={handleDateChange("startDate")}
          date={startDateInfo?.date}
          error={Boolean(filterErrors.startDate)}
        />
        <Icon name="arrow-right" size="sm" />
        <DateFilterInput
          type="end"
          onDateChange={handleDateChange("endDate")}
          date={endDateInfo?.date}
          error={Boolean(filterErrors.endDate)}
        />
        <Icon
          name="x"
          size="xs"
          onClick={() => {
            handleRemoveFilter("startDate");
            handleRemoveFilter("endDate");
          }}
        />
      </div>
    ) : null;

  const actorFilter = actorInfo ? (
    <div className={styles.filterContainer}>
      <Icon name="user" size="sm" />
      <div className={styles.input}>
        <PaginatedUserDropdown
          value={actorUser}
          onChange={handleActorChange}
          clearable={false}
          size="xs"
          placeholder="Select user"
          includeSystemUser
        />
      </div>
      <Icon
        name="x"
        size="xs"
        onClick={() => {
          handleRemoveFilter("actors");
        }}
      />
    </div>
  ) : null;

  const eventTypeFilter =
    eventTypeInfo && !isPropagationErrorFilter ? (
      <div className={styles.filterContainer}>
        <Icon name="events" size="sm" />
        <div className={styles.input}>
          <Select
            size="xs"
            options={allEventTypes}
            onChange={handleEventTypeChange}
            getOptionLabel={(option) => eventTypeToString(option, true)}
            placeholder="Select event type"
            value={
              eventTypeInfo.eventTypes ? eventTypeInfo.eventTypes[0] : undefined
            }
          />
        </div>
        <Icon
          name="x"
          size="xs"
          onClick={() => {
            handleRemoveFilter("eventTypes");
          }}
        />
      </div>
    ) : null;

  const objectFilter = objectInfo ? (
    <div className={styles.filterContainer}>
      <Icon name="cube" size="sm" />
      <div className={styles.input}>
        <Input
          value={objectInfo.objectId}
          type="text"
          onChange={handleObjectChange}
          size="sm"
          placeholder="Object ID"
        />
      </div>
      <Icon
        name="x"
        size="xs"
        onClick={() => {
          handleRemoveFilter("objects");
        }}
      />
    </div>
  ) : null;

  const systemEventFilter = systemEventsInfo ? (
    <div className={styles.filterContainer}>
      <DataElement
        label="System Events"
        color="gray"
        leftIcon={{
          name: "refresh",
        }}
        rightIcon={{
          name: "x",
          onClick: () => {
            handleRemoveFilter("systemEvents");
          },
        }}
      />
    </div>
  ) : null;

  const propagationErrorsFilter = isPropagationErrorFilter ? (
    <div className={styles.filterContainer}>
      <DataElement
        label="Propagation Errors"
        color="gray"
        leftIcon={{
          name: "alert-circle",
        }}
        rightIcon={{
          name: "x",
          onClick: () => {
            handleRemoveFilter("eventTypes");
          },
        }}
      />
    </div>
  ) : null;

  const apiTokenFilter = apiTokensInfo ? (
    <div className={styles.filterContainer}>
      <Icon name="key" size="sm" />
      <div className={styles.input}>
        <FirstPartyTokenDropdown
          onChange={handleApiTokenChange}
          value={{
            tokenLabel: apiTokensInfo.apiTokenLabel,
            tokenPreview: apiTokensInfo.apiTokenPreview,
          }}
          size="xs"
        />
      </div>
      <Icon
        name="x"
        size="xs"
        onClick={() => {
          handleRemoveFilter("apiTokens");
        }}
      />
    </div>
  ) : null;

  const hasFilters = Object.keys(filtersInfosState).length > 0;
  const validateFilters = () => {
    let errors = {
      ...filterErrors,
    };
    let hasError = false;

    if (startDateInfo?.date === "Invalid date") {
      errors.startDate = "Invalid date";
      hasError = true;
    }
    if (endDateInfo?.date === "Invalid date") {
      errors.endDate = "Invalid date";
      hasError = true;
    }

    if (hasError) {
      setFilterErrors(errors);
    }

    return hasError;
  };

  const newParams = buildFilterURLParams(filtersInfosState);

  const handleClickApply = () => {
    const hasError = validateFilters();
    if (hasError) {
      return;
    }

    history.push("/events?" + newParams.toString());
  };

  const filtersAreEqual = () => {
    if (eventFilter.startDate?.date) {
      if (
        !startDateInfo?.date ||
        !moment(startDateInfo.date).isSame(moment(eventFilter.startDate.date))
      ) {
        return false;
      }
    } else if (startDateInfo?.date) {
      return false;
    }

    if (eventFilter.endDate?.date) {
      if (
        !endDateInfo?.date ||
        !moment(endDateInfo.date).isSame(moment(eventFilter.endDate.date))
      ) {
        return false;
      }
    } else if (endDateInfo?.date) {
      return false;
    }

    if (eventFilter.actors?.userId) {
      if (
        !actorInfo?.userId ||
        eventFilter.actors.userId !== actorInfo.userId
      ) {
        return false;
      }
    } else if (actorInfo?.userId) {
      return false;
    }

    if (eventFilter.eventTypes?.eventTypes?.length) {
      for (let eventType of eventFilter.eventTypes.eventTypes) {
        if (
          !eventTypeInfo?.eventTypes?.length ||
          !eventTypeInfo.eventTypes.includes(eventType)
        ) {
          return false;
        }
      }
    } else if (eventTypeInfo?.eventTypes?.length) {
      return false;
    }

    if (eventFilter.objects?.objectId) {
      if (
        !objectInfo?.objectId ||
        objectInfo.objectId !== eventFilter.objects.objectId
      ) {
        return false;
      }
    } else if (objectInfo?.objectId) {
      return false;
    }

    if (
      (!eventFilter?.systemEvents && systemEventsInfo) ||
      (eventFilter?.systemEvents && !systemEventsInfo)
    ) {
      return false;
    }

    if (
      eventFilter.apiTokens?.apiTokenLabel &&
      eventFilter.apiTokens?.apiTokenPreview
    ) {
      if (
        !apiTokensInfo?.apiTokenLabel ||
        !apiTokensInfo.apiTokenPreview ||
        eventFilter.apiTokens.apiTokenLabel !== apiTokensInfo.apiTokenLabel ||
        eventFilter.apiTokens.apiTokenPreview !== apiTokensInfo.apiTokenPreview
      ) {
        return false;
      }
    } else if (apiTokensInfo?.apiTokenLabel && apiTokensInfo?.apiTokenPreview) {
      return false;
    }

    return true;
  };

  return (
    <>
      <RowBar>
        <Icon name="filter" size="sm" />
        <div className={styles.title}>Filter events</div>
        <ContextMenu
          renderButton={renderAddFilterButton}
          options={filterMenuOptions}
        />
        <div className={styles.filtersContainer}>
          {dateFilter}
          {actorFilter}
          {eventTypeFilter}
          {objectFilter}
          {systemEventFilter}
          {propagationErrorsFilter}
          {apiTokenFilter}
        </div>
        {hasFilters ? (
          <div className={styles.buttonsContainer}>
            <Button
              label="Apply filter"
              type="primary"
              size="sm"
              onClick={handleClickApply}
              disabled={filtersAreEqual()}
            />
            {authState.user?.isAdmin ? (
              <Button
                label="Save filter"
                type="primary"
                size="sm"
                outline
                onClick={() => {
                  handleClickApply();
                  setShowSaveModal(true);
                }}
              />
            ) : null}
          </div>
        ) : null}
      </RowBar>
      {showSaveModal ? (
        <SaveFilterModal onClose={() => setShowSaveModal(false)} />
      ) : null}
    </>
  );
};

export default EventFilterBar;
