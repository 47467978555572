import { EntityIdTuple, Maybe } from "api/generated/graphql";
import UserLabel from "components/label/item_labels/UserLabel";
import Label from "components/label/Label";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import React from "react";
import resourcesAccessReviewTableStyles from "views/resources/ResourcesAccessReviewTable.module.scss";

type ReviewerUserCellEntry = {
  name: string;
  avatarUrl: string;
  entityIds?: Maybe<EntityIdTuple[]>;
};

type AccessReviewReviewersCellProps = {
  itemType: "group" | "resource" | "app";
  reviewerUsers: Maybe<ReviewerUserCellEntry[]> | undefined;
  canEditReviewers: boolean;
  disabledEditText?: string;
  disabledEditTooltipText?: string;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
};

export const AccessReviewReviewersCell = (
  props: AccessReviewReviewersCellProps
) => {
  if (props.reviewerUsers?.length) {
    const primaryReviewer =
      _.maxBy(props.reviewerUsers, (r) => r.entityIds?.length || 0) ||
      props.reviewerUsers[0];

    return (
      <div
        className={resourcesAccessReviewTableStyles.userLabelContainer}
        onClick={props.onClick}
      >
        <div>
          <UserLabel
            name={
              primaryReviewer.name +
              (props.reviewerUsers.length > 1
                ? ` (+${props.reviewerUsers.length - 1})`
                : "")
            }
            avatar={primaryReviewer.avatarUrl}
          />
        </div>
      </div>
    );
  }

  if (props.canEditReviewers) {
    return (
      <span
        className={sprinkles({
          textDecoration: "underline",
          cursor: "pointer",
        })}
        onClick={props.onClick}
      >
        Assign Reviewers
      </span>
    );
  } else {
    if (props.disabledEditText && props.disabledEditTooltipText) {
      return (
        <Label
          text={props.disabledEditText}
          tooltipText={props.disabledEditTooltipText}
          additionalStyleClass={resourcesAccessReviewTableStyles.inactive}
        />
      );
    }
    return (
      <Label
        text={"Needs Assignment"}
        tooltipText={`Only this ${props.itemType}'s admins or an Opal auditor or admin can assign reviewers.`}
        additionalStyleClass={resourcesAccessReviewTableStyles.inactive}
      />
    );
  }
};

export default AccessReviewReviewersCell;
