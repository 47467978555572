import {
  AccessOption,
  AppItemsSortByField,
  ConnectionType,
  RemoteAppItemFragment,
  ResourceType,
  SortDirection,
  useAwsAccountsColumnQuery,
  useRemoteItemsListQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeader";
import ColumnListItem, {
  ColumnListItemsSkeleton,
} from "components/column/ColumnListItem";
import ColumnListScroller from "components/column/ColumnListScroller";
import { ColumnSearchAndSort } from "components/column/ColumnSearchAndSort";
import { useToast } from "components/toast/Toast";
import { Divider } from "components/ui";
import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import AppDetailColumn from "./AppDetailColumn";
import AppDetailContent from "./AppDetailContent";
import { ACCESS_OPTION_URL_KEY, AppsContext } from "./AppsContext";
import BulkImportColumn from "./BulkImportColumn";
import { appsBreadcrumb, useAccessOptionKey } from "./utils";

const AWSRemoteAccounts = () => {
  const { selectedRemoteItems, toggleRemoteItem } = useContext(AppsContext);
  const [accessOptionKey] = useAccessOptionKey();
  const { appId } = useParams<Record<string, string>>();

  const { data, loading, error } = useRemoteItemsListQuery({
    variables: {
      id: appId,
      resourceType: ResourceType.AwsAccount,
    },
    skip: !appId || accessOptionKey !== AccessOption.Unmanaged,
  });

  if (error) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  if (!appId) {
    return <div>Select an app instance to import items.</div>;
  }

  let remoteItems: RemoteAppItemFragment[] = [];
  switch (data?.app.__typename) {
    case "App": {
      switch (data.app.remoteItems.__typename) {
        case "RemoteAppItemsResult":
          remoteItems = data.app.remoteItems.items ?? [];
      }
    }
  }

  const renderItem = (index: number) => {
    const item = remoteItems[index];
    if (!item) return <></>;

    return (
      <ColumnListItem
        key={item.remoteId}
        label={item.remoteName}
        checkbox={{
          checked: selectedRemoteItems.some(
            (i) => i.remoteId === item.remoteId
          ),
          onChange: () => toggleRemoteItem(item),
        }}
        icon={
          item.resourceType
            ? {
                type: "entity",
                entityType: item.resourceType,
              }
            : item.groupType
            ? {
                type: "entity",
                entityType: item.groupType,
              }
            : undefined
        }
        type="terminal"
        onClick={() => toggleRemoteItem(item)}
      />
    );
  };

  return (
    <ColumnListScroller
      renderRow={renderItem}
      numRows={remoteItems.length}
      loading={loading}
      emptyState={{
        title: "No accounts to import",
      }}
    />
  );
};

const SORT_OPTIONS = [
  {
    label: "Name (A-Z)",
    value: {
      field: AppItemsSortByField.Name,
      direction: SortDirection.Asc,
    },
  },
  {
    label: "Name (Z-A)",
    value: {
      field: AppItemsSortByField.Name,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Newest first",
    value: {
      field: AppItemsSortByField.CreatedAt,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Oldest first",
    value: {
      field: AppItemsSortByField.CreatedAt,
      direction: SortDirection.Asc,
    },
  },
];

const AWSAccountsColumn = () => {
  const transitionTo = useTransitionTo({
    preserveQueries: [ACCESS_OPTION_URL_KEY],
  });
  const history = useHistory();
  const {
    selectedRemoteItems,
    isSelectMode,
    setIsSelectMode,
    clearSelectedItems,
  } = useContext(AppsContext);
  const { displayErrorToast } = useToast();
  const { selectedItems, toggleItem, selectItem, clearItem } = useContext(
    AppsContext
  );
  const { authState } = useContext(AuthContext);
  const [accessOptionKey] = useAccessOptionKey();
  const { appId, appView } = useParams<Record<string, string>>();

  const logEvent = useLogEvent();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sortOption, setSortOption] = useState(SORT_OPTIONS[0]);

  const {
    data,
    loading,
    error,
    fetchMore,
    previousData,
  } = useAwsAccountsColumnQuery({
    variables: {
      access: accessOptionKey,
      appId,
      searchQuery,
      sortBy: sortOption.value,
    },
    notifyOnNetworkStatusChange: true,
  });

  if (loading && !data && !searchQuery) {
    return (
      <Column>
        <ColumnHeaderSkeleton />
        <Divider margin="md" />
        <ColumnListItemsSkeleton />
      </Column>
    );
  }

  if (error) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  // Use previousData to avoid flashing in the header during search
  const appFromData = data?.app.__typename === "App" ? data.app : null;
  const previousApp =
    previousData?.app.__typename === "App" ? previousData.app : null;
  const app = appFromData ?? previousApp;
  if (!app && !searchQuery) {
    return null;
  }

  const accounts = app?.items.items || [];
  if (
    accounts.length === 0 &&
    accessOptionKey !== AccessOption.Unmanaged &&
    !appId &&
    !searchQuery
  ) {
    return <AppDetailColumn appId={appId} />;
  }

  const cursor = app?.items.cursor;
  const loadMore = cursor
    ? async () => {
        await fetchMore({
          variables: {
            cursor,
          },
        });
      }
    : undefined;

  const selectAll = async () => {
    let thisCursor = cursor;
    accounts.forEach((item) => {
      item.resource && selectItem(item.resource);
      item.group && selectItem(item.group);
    });

    while (thisCursor) {
      const { data, error } = await fetchMore({
        variables: {
          cursor: thisCursor,
          access: accessOptionKey,
          appId,
        },
      });
      if (error) {
        logError(error, "failed to select all items.");
        displayErrorToast("failed to select all items.");
        break;
      }
      accounts.forEach((item) => {
        item.resource && selectItem(item.resource);
      });
      thisCursor =
        data?.app.__typename === "App" ? data.app.items.cursor : undefined;
    }
  };

  const clearAll = async () => {
    accounts?.forEach((item) => {
      item.resource && clearItem(item.resource);
      item.group && clearItem(item.group);
    });
  };

  const renderAccountListItem = (index: number) => {
    if (!accounts) return <></>;

    const account = accounts[index]?.resource;
    if (!account) return <></>;

    return (
      <ColumnListItem
        key={account.id}
        label={account.name}
        onClick={() => {
          transitionTo({
            pathname: `/apps/${appId}/account/${account.id}`,
          });
          logEvent({ name: "apps_aws_account_click" });
        }}
        checkbox={
          isSelectMode
            ? {
                checked: selectedItems.some((item) => item.id === account.id),
                onChange: () => {
                  if (account) toggleItem(account);
                },
              }
            : undefined
        }
        largeIcon
        icon={{
          type: "entity",
          entityType: account.resourceType,
        }}
      />
    );
  };

  const allSelected =
    isSelectMode &&
    accounts.every((item) => {
      const id = item.group?.id || item.resource?.id;
      return selectedItems.some((i) => i.id === id);
    });

  const items = (
    <>
      <ColumnListItem
        key="all-accounts"
        label="All Resources"
        onClick={() => {
          const pathname = `/apps/${appId}/all-accounts`;
          transitionTo({ pathname });
          logEvent({ name: "apps_aws_all_accounts_click" });
        }}
      />
      <Divider margin="md" label="Accounts" labelPosition="left" />
      <ColumnSearchAndSort
        placeholder="Filter accounts"
        sortOptions={SORT_OPTIONS}
        sortBy={sortOption}
        setSortBy={setSortOption}
        setSearchQuery={setSearchQuery}
        trackName="accounts"
        checkbox={{
          checked: allSelected,
          onChange: allSelected ? clearAll : selectAll,
          loading: loading,
        }}
      />
      <ColumnListScroller
        renderRow={renderAccountListItem}
        numRows={app?.items.items?.length ?? 0}
        onLoadMore={loadMore}
        hasNextPage={cursor != null}
        loading={loading}
      />
    </>
  );

  const menuOptions: PropsFor<typeof ColumnHeader>["menuOptions"] = [];
  if (accessOptionKey !== AccessOption.Unmanaged) {
    menuOptions.push({
      label: isSelectMode ? "Cancel bulk select" : "Bulk select",
      icon: { type: "name", icon: "check-square" },
      onClick: () => {
        if (isSelectMode) {
          clearSelectedItems();
        } else {
          logEvent({
            name: "apps_bulk_select_start",
            properties: {
              entryPoint: "accounts",
            },
          });
          setIsSelectMode(true);
        }
      },
    });
    if (authState.user?.isAdmin) {
      menuOptions.push({
        label: "Import items",
        icon: { type: "name", icon: "plus" },
        onClick: () => {
          history.push(
            `/apps/${appId}?${ACCESS_OPTION_URL_KEY}=${AccessOption.Unmanaged}`
          );
        },
      });
    }
  }

  return (
    <>
      <Column>
        <ColumnHeader
          title={app?.name ?? ""}
          icon={{
            type: "entity",
            entityType: ConnectionType.AwsSso,
          }}
          breadcrumbs={[appsBreadcrumb]}
          menuOptions={menuOptions}
        />
        <Divider />
        <ColumnListItem
          label={app?.name ?? ""}
          icon={{
            type: "entity",
            entityType: ConnectionType.AwsSso,
          }}
          selected={Boolean(appView)}
          onClick={() => {
            transitionTo({
              pathname: `/apps/${appId}/overview`,
            });
          }}
        />
        <Divider
          label={
            accessOptionKey === AccessOption.Unmanaged
              ? "Accounts to Add"
              : undefined
          }
          labelPosition="left"
        />
        {accessOptionKey === AccessOption.Unmanaged ? (
          <AWSRemoteAccounts />
        ) : (
          items
        )}
      </Column>
      <AppDetailContent />
      {selectedRemoteItems.length > 0 && <BulkImportColumn />}
    </>
  );
};

export default AWSAccountsColumn;
