import {
  GroupBindingSuggestionFragment,
  GroupPreviewTinyFragment,
} from "api/generated/graphql";
import { Checkbox, Icon, Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";

type Props = {
  suggestions: GroupBindingSuggestionFragment[];
  sourceGroupBySuggestionId: Record<string, GroupPreviewTinyFragment>;
  onSubmit: (suggestionIds: string[]) => void;
  onModalClose: () => void;
  isOpen: boolean;
};

const GroupBindingSuggestionDisambiguateModal = (props: Props) => {
  const [selectedIds, setSelectedIds] = useState<string[]>([]);

  return (
    <Modal
      title={"Conflicting group links found"}
      onClose={() => props.onModalClose()}
      isOpen={props.isOpen}
      subtitle={
        "Some of the suggestions you've selected are conflicting and couldn't be submitted. " +
        "Select the correct group links for each suggestion. If you're not sure, you can skip this step and come back later."
      }
    >
      <Modal.Body>
        <div
          className={sprinkles({
            display: "flex",
            gap: "md",
            flexDirection: "column",
          })}
        >
          {props.suggestions.map((suggestion) => {
            const sourceGroup =
              props.sourceGroupBySuggestionId[suggestion.id] ??
              suggestion.primaryGroup;
            const otherGroup =
              suggestion.primaryGroupId === sourceGroup.id
                ? suggestion.secondaryGroup
                : suggestion.primaryGroup;
            const isSelected = selectedIds.includes(suggestion.id);
            return (
              <Checkbox
                checked={isSelected}
                key={suggestion.id}
                onChange={(checked) => {
                  if (checked) {
                    setSelectedIds([...selectedIds, suggestion.id]);
                    return;
                  }

                  setSelectedIds(
                    selectedIds.filter((id) => id !== suggestion.id)
                  );
                }}
                label={
                  <span
                    className={sprinkles({
                      display: "flex",
                      gap: "xs",
                      alignItems: "center",
                    })}
                  >
                    <b>{sourceGroup.name ?? "Hidden group"}</b>
                    <Icon name="link" size="xxs" color="gray500" />
                    {otherGroup?.name ?? "Hidden group"}
                  </span>
                }
              />
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel={
          selectedIds.length === 0 ? "Skip and link" : "Submit and link"
        }
        onPrimaryButtonClick={() => {
          props.onSubmit(selectedIds);
          setSelectedIds([]);
        }}
      />
    </Modal>
  );
};

export default GroupBindingSuggestionDisambiguateModal;
