import {
  Maybe,
  ResourceAccessLevel,
  ResourceAccessLevelFragment,
  ResourcePreviewLargeFragment,
  ResourcePreviewSmallFragment,
  ResourceType,
  ServiceType,
  useResourceAccessLevelsLazyQuery,
  useResourcePreviewQuery,
} from "api/generated/graphql";
import {
  ResourceIndividualRoleCheckedData,
  Role,
} from "components/modals/ResourceIndividualRoleModal";
import { SelectType } from "components/modals/update/SelectItemsModal";
import { Checkbox, Loader, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import moment from "moment/moment";
import React, { ReactElement, useEffect, useState } from "react";
import {
  isResourceRoleVisible,
  resourceRequiresAtLeastOneRole,
} from "utils/directory/resources";
import { useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";

type ResourceRoleCheckboxProps = {
  resource?: ResourcePreviewLargeFragment;
  entityId: string;
  loading?: boolean;
  errorMessage?: Maybe<string>;
  submitDisabled?: boolean;
  selectType: SelectType;
  disabled?: boolean;
  checked?: boolean;
  tooltipText?: string;
  addRoleData?: (roleData: ResourceIndividualRoleCheckedData[]) => void;
  removeRoleData?: (entityIdToRemove: string) => void;

  existingDirectRolesByEntityId: Record<string, Role[]>;
  updatedRolelsByEntityId: Record<string, Role[]>;
  setUpdatedRolesByEntityId: (
    updatedRolesByEntityId: Record<string, Role[]>
  ) => void;
  serviceType?: ServiceType;
  uploadCsvUserIds?: Record<string, boolean>;
};

const ResourceRoleCheckbox = (props: ResourceRoleCheckboxProps) => {
  const [checked, setChecked] = useState(Boolean(props.checked));
  const [hidden, setHidden] = useState(false);
  const [
    serviceTypeRequiresAtLeastOneRoleVerified,
    setServiceTypeRequiresAtLeastOneRoleVerified,
  ] = useState(false);

  // used to trigger useEffect reexecution when query is cached
  const [lastCheckedClickTimestamp, setLastCheckedClickTimestamp] = useState(0);

  // Query for resource data if not provided already
  const {
    data: resourceData,
    loading: resourceLoading,
    error: resourceError,
  } = useResourcePreviewQuery({
    variables: {
      input: {
        id: props.entityId,
      },
    },
    skip: Boolean(props.resource),
  });

  const [
    resourceRoles,
    { data, error, loading },
  ] = useResourceAccessLevelsLazyQuery({
    variables: {
      input: {
        // fallback to entity id if resource isn't supplied
        resourceId: props.resource?.id || props.entityId,
      },
    },
  });

  let resource: ResourcePreviewSmallFragment | undefined = props.resource;
  if (resourceData?.resource.__typename === "ResourceResult") {
    resource = resourceData.resource.resource;
  }

  useEffect(() => {
    if (data && resource) {
      switch (data?.accessLevels.__typename) {
        case "ResourceAccessLevelsResult": {
          const rolesForResource: ResourceAccessLevelFragment[] = [
            ...data.accessLevels.accessLevels,
          ];

          const isRemovingModal =
            props.selectType === SelectType.Remove ||
            props.selectType === SelectType.Delete;
          if (
            props.resource?.resourceType === ResourceType.OktaApp &&
            isRemovingModal
          ) {
            // This is an unfortunate special case. Basically, some Okta apps have "non-default" roles. However,
            // those roles are basically a really large JSON blob corresponding to the access profile. Rather than auto-importing
            // these, we let users define some subset of the blob in Opal as a custom role. Opal will try to match
            // this role to existing users that are imported. However, the imported users whose roles don't match
            // will continue to have default roles in Opal. We want the user to be able to remove these users in Opal, so
            // we inject this default role here. However, we don't want the user to be able to add users with default role
            // because for these apps in Okta, there is no default role.
            rolesForResource.push({
              __typename: "ResourceAccessLevel",
              accessLevelName: "",
              accessLevelRemoteId: "",
            });
          }

          if (rolesForResource.length < 2) {
            let roleToAdd: ResourceAccessLevel = {
              accessLevelName: "",
              accessLevelRemoteId: "",
            };
            if (rolesForResource.length === 1) {
              roleToAdd = rolesForResource[0];
            }

            const entityUpdatedRoles =
              props.updatedRolelsByEntityId[props.entityId] || [];

            if (
              !resourceRequiresAtLeastOneRole(resource) ||
              serviceTypeRequiresAtLeastOneRoleVerified
            ) {
              props.setUpdatedRolesByEntityId({
                ...props.updatedRolelsByEntityId,
                [props.entityId]: _.uniqBy(
                  [...entityUpdatedRoles, roleToAdd],
                  "accessLevelRemoteId"
                ),
              });

              if (props.addRoleData) {
                props.addRoleData([
                  {
                    entityId: props.entityId,
                    loading: props.loading,
                    errorMessage: props.errorMessage,
                    submitDisabled: props.submitDisabled,
                    selectType: props.selectType,
                    rolesForResource: rolesForResource,
                  },
                ]);
              }
            }

            if (!isResourceRoleVisible(resource, rolesForResource.length)) {
              setHidden(true);
            }
            if (rolesForResource.length === 1) {
              setServiceTypeRequiresAtLeastOneRoleVerified(true);
            }
          } else if (props.addRoleData) {
            if (
              !resourceRequiresAtLeastOneRole(resource) ||
              serviceTypeRequiresAtLeastOneRoleVerified
            ) {
              props.addRoleData([
                {
                  entityId: props.entityId,
                  loading: props.loading,
                  errorMessage: props.errorMessage,
                  submitDisabled: props.submitDisabled,
                  selectType: props.selectType,
                  rolesForResource: rolesForResource,
                },
              ]);
            }
            setServiceTypeRequiresAtLeastOneRoleVerified(true);
          }

          break;
        }
        case "ResourceNotFoundError":
          logError(
            new Error(
              `Error: failed to list resource roles: ${data.accessLevels.message}`
            )
          );
          break;
        default:
          logError(new Error(`Error: failed to list resource roles`));
          break;
      }
    } else if (error) {
      logError(error, `Error: failed to list resource roles: ${error.message}`);
    }
  }, [data, resource, error, lastCheckedClickTimestamp]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (props.uploadCsvUserIds) {
      for (const [userId] of Object.entries(props.uploadCsvUserIds)) {
        if (userId === props.entityId) {
          setChecked(true);
        }
      }
    }
  }, [props.uploadCsvUserIds, props.entityId]);

  useMountEffect(() => {
    if (resource && resourceRequiresAtLeastOneRole(resource)) {
      if (props.resource?.id || props.entityId) {
        resourceRoles();
      }
    }
  });

  const isDelete =
    props.selectType === SelectType.Remove ||
    props.selectType === SelectType.Delete;

  const numExistingDirectRoles =
    props.existingDirectRolesByEntityId[props.entityId]?.length || 0;
  let rolesForResource = [];
  if (data?.accessLevels.__typename === "ResourceAccessLevelsResult") {
    rolesForResource = data.accessLevels.accessLevels;
  }
  let userHasFullDirectAccess = false;
  if (!isDelete && rolesForResource.length !== 0) {
    userHasFullDirectAccess =
      numExistingDirectRoles === rolesForResource.length;
  }

  let checkbox: ReactElement | undefined;
  if (!loading && !hidden && !resourceLoading && !resourceError) {
    checkbox = (
      <Checkbox
        size="sm"
        checked={props.checked || checked || userHasFullDirectAccess}
        onChange={() => {
          // NOTE: when the onclick event is fired off, the "checked" value is the value
          // from before the user clicked the checkbox
          // ie: if user toggles the checkbox from checked to unchecked, then checked=true
          if (!checked) {
            // if checked, then fetch the roles for the resource
            // to decide what to do
            resourceRoles();
            setLastCheckedClickTimestamp(moment.now());
            setChecked(true);
          } else {
            props.removeRoleData && props.removeRoleData(props.entityId);
            setChecked(false);

            delete props.updatedRolelsByEntityId[props.entityId];
            props.setUpdatedRolesByEntityId({
              ...props.updatedRolelsByEntityId,
            });
          }
        }}
        disabled={props.disabled || userHasFullDirectAccess}
      />
    );
  }

  return (
    <div className={sprinkles({ display: "flex", alignItems: "center" })}>
      {loading || resourceLoading ? <Loader size="sm" /> : null}
      {checkbox !== undefined &&
        (props.tooltipText ? (
          <Tooltip tooltipText={props.tooltipText}>
            <div>{checkbox}</div>
          </Tooltip>
        ) : (
          checkbox
        ))}
    </div>
  );
};

export default ResourceRoleCheckbox;
