import { IdpConnectionFragment, Maybe } from "api/generated/graphql";
import { AppTile } from "components/integrations/AppTile";
import { getLogoByIdpConnectionType } from "components/label/Label";
import IdpAttributesModal from "components/modals/update/IdpAttributesModal";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import React, { useState } from "react";
import { IdpAuthorizer } from "views/settings/third_party_authorizer/IdpAuthorizer";

import * as styles from "./OrgIntegrationAppV3.css";

export type IdpHrIntegrationAppProps = {
  idpConnection: Maybe<IdpConnectionFragment>;
};

export const IdpHrIntegrationAppV3 = (props: IdpHrIntegrationAppProps) => {
  const { idpConnection } = props;

  const [showModal, setShowModal] = useState(false);

  const idpConnected = !!idpConnection;

  let connectionStatusText = idpConnected ? "Connected" : "No connection";
  if (idpConnected) {
    connectionStatusText = `${connectionStatusText}`;
  }

  return (
    <div>
      <div className={styles.appInfo}>
        <div className={styles.leftContent}>
          <AppTile
            logo={getLogoByIdpConnectionType(idpConnection?.idpConnectionType)}
          />
          <div className={styles.text}>
            <div className={styles.appName}>
              {idpConnection?.connection?.name ||
                "Identity Provider / HR Information System"}
            </div>
            <div className={sprinkles({ display: "flex" })}>
              <div className={styles.subtitle}>
                <div
                  className={styles.subtitleStatus({ connected: idpConnected })}
                />
                <div className={styles.subtitleText}>
                  {connectionStatusText}
                </div>
              </div>
              {idpConnected && (
                <div className={styles.attributesLink}>
                  <div
                    className={styles.subtitleText}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    {`Importing ${pluralize(
                      "custom user attribute",
                      idpConnection?.userAttributeImportMappings.length || 0,
                      true
                    )}`}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.rightContent}>
          <IdpAuthorizer
            idpConnection={idpConnection}
            isConnected={idpConnected}
          />
        </div>
      </div>
      {idpConnection && (
        <IdpAttributesModal
          idpConnection={idpConnection}
          isModalOpen={showModal}
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}
    </div>
  );
};
