import {
  ConnectionType,
  EntityType,
  SortDirection,
} from "api/generated/graphql";
import ConnectionLabel from "components/label/item_labels/ConnectionLabel";
import MuiVirtualTable, {
  Header,
} from "components/tables/material_table/MuiVirtualTable";
import { Banner, Modal } from "components/ui";
import { Skeleton } from "components/ui";
import Table, { Header as V3Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { plural } from "pluralize";
import React from "react";
import { Link } from "react-router-dom";
import { SortDirection as TableSortDirection } from "react-virtualized";
import { getResourceUrlNew } from "utils/common";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import * as styles from "views/access_reviews/settings/scope/AccessReviewItemPreviewModal.css";

interface AccessReviewItem {
  id: string;
  value: string;
  rendered: React.ReactElement;
  connection?: {
    name: string;
    id: string;
    connectionType: ConnectionType;
  };
  admin?: {
    id: string;
    name: string;
  };
}

interface AccessReviewItemPreviewModalProps {
  itemType: "resource" | "group" | "app";
  onClose: () => void;
  items: AccessReviewItem[];
  loadMoreRows: (cursor: string) => Promise<void>;
  onSortDirectionChange?: (direction: SortDirection) => void;
  sortDirection: SortDirection;
  cursor?: string;
  loading: boolean;
  totalNumRows: number;
}

interface TableRow {
  name: string;
  app: string;
  admin: string;
}

interface TableRowV3 {
  id: string;
  name?: {
    value: string;
    element: React.ReactElement;
  };
  connection?: {
    name: string;
    id: string;
    connectionType: ConnectionType;
  };
  admin?: {
    id: string;
    name: string;
  };
}

export const AccessReviewItemPreviewModal: React.FC<AccessReviewItemPreviewModalProps> = (
  props
) => {
  const pluralItemType = plural(props.itemType);
  const title = `Access Review ${_.capitalize(pluralItemType)}`;
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const headers: Header<TableRow>[] = [
    {
      id: "name",
      label: `${_.capitalize(props.itemType)}`,
      width: 300,
      minWidth: 250,
    },
  ];
  const V3headers: V3Header<TableRowV3>[] = [
    {
      id: "name",
      label: `${_.capitalize(props.itemType)}`,
      width: 300,
      minWidth: 250,
      customCellRenderer: (x) => x.name?.element ?? <></>,
      sortable: true,
    },
  ];
  if (props.itemType !== "app") {
    headers.push({
      id: "app",
      label: `App`,
      width: 300,
      minWidth: 250,
      sortable: false,
    });
    V3headers.push({
      id: "connection",
      label: `App`,
      width: 300,
      minWidth: 250,
      sortable: false,
      customCellRenderer: (x) => {
        return x.connection ? (
          <ConnectionLabel
            text={x.connection.name}
            connectionId={x.connection.id}
            connectionType={x.connection.connectionType}
            target="_blank"
          />
        ) : (
          <></>
        );
      },
    });
  }
  headers.push({
    id: "admin",
    label: `Admin`,
    width: 300,
    minWidth: 250,
    sortable: false,
  });
  V3headers.push({
    id: "admin",
    label: `Admin`,
    width: 300,
    minWidth: 250,
    sortable: false,
    customCellRenderer: (x) => {
      return x.admin ? (
        <Link
          to={getResourceUrlNew({
            entityId: x.admin.id,
            entityType: EntityType.Owner,
          })}
          target="_blank"
        >
          {x.admin.name}
        </Link>
      ) : (
        <></>
      );
    },
  });

  const onSortChange = (dir: keyof typeof TableSortDirection | string) => {
    props.onSortDirectionChange &&
      props.onSortDirectionChange(
        dir === TableSortDirection.DESC || dir === "DESC"
          ? SortDirection.Desc
          : SortDirection.Asc
      );
  };

  const rows = props.items.map((x) => {
    return {
      id: x.id,
      data: {
        name: {
          value: x.value,
          element: x.rendered,
        },
        app: {
          value: x.connection?.name ?? "",
          element: x.connection ? (
            <ConnectionLabel
              text={x.connection.name}
              connectionId={x.connection.id}
              connectionType={x.connection.connectionType}
              target="_blank"
            />
          ) : (
            <></>
          ),
        },
        admin: {
          value: x.admin?.name ?? "",
          element: x.admin ? (
            <Link
              to={getResourceUrlNew({
                entityId: x.admin.id,
                entityType: EntityType.Owner,
              })}
              target="_blank"
            >
              {x.admin.name}
            </Link>
          ) : (
            <></>
          ),
        },
      },
    };
  });

  const rowsV3: TableRowV3[] = props.items.map((x) => {
    return {
      id: x.id,
      name: {
        value: x.value,
        element: x.rendered,
      },
      connection: x.connection,
      admin: x.admin,
    };
  });

  const loadMoreRows = () => {
    if (!props.cursor) return Promise.resolve();
    return props.loadMoreRows(props.cursor);
  };

  return (
    <Modal title={title} isOpen={true} onClose={props.onClose} maxWidth={false}>
      <Modal.Body>
        {!props.loading && rows.length === 0 ? (
          <Banner
            type="warning"
            message={`The access review will not include any ${pluralItemType}.`}
          />
        ) : (
          <div className={styles.table}>
            {hasV3 ? (
              <div
                className={sprinkles({
                  paddingX: "sm",
                  paddingY: "xs",
                })}
              >
                <Table
                  columns={V3headers}
                  rows={rowsV3}
                  totalNumRows={props.totalNumRows}
                  getRowId={(ru) => ru.id}
                  onLoadMoreRows={loadMoreRows}
                  loadingRows={props.loading}
                  manualSortDirection={{
                    sortBy: "name",
                    sortDirection: props.sortDirection,
                  }}
                  handleManualSort={(_, dir) => {
                    onSortChange(dir ?? "");
                  }}
                />
              </div>
            ) : (
              <MuiVirtualTable
                columns={headers}
                rows={rows}
                defaultSortBy={"name"}
                defaultSortDirection={TableSortDirection.ASC}
                onSortOptionsChanged={(_, dir) => onSortChange(dir)}
                loadMoreRows={loadMoreRows}
                allRowsLoaded={props.cursor === undefined}
              />
            )}
            {props.loading && <SelectorLoading />}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Close"
        onPrimaryButtonClick={props.onClose}
        primaryButtonLoading={props.loading}
      />
    </Modal>
  );
};

const SelectorLoading = () => {
  return (
    <div className={styles.loading}>
      {_.range(12).map(() => (
        <div className={sprinkles({ paddingY: "sm", paddingX: "lg" })}>
          <Skeleton />
        </div>
      ))}
    </div>
  );
};
