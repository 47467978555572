import { GeneralSettingType } from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { IdpMfaModal } from "components/modals/IdpMfaModal";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import FullPageLoading from "views/loading/FullPageLoading";
import OrgContext from "views/settings/OrgContext";

// SlackMfa is a dedicated component for handling MFA validation from the Slack modal.
export const SlackMfa = () => {
  const history = useHistory();

  const authState = useContext(AuthContext).authState;
  const { orgState } = useContext(OrgContext);

  const [isIdpMfaModalOpen, setIsIdpMfaModalOpen] = useState(false);

  const useOktaMfa =
    orgState.orgSettings?.generalSettings.some(
      (setting) => setting === GeneralSettingType.UseOktaMfaForGatingOpalActions
    ) || false;

  useEffect(() => {
    if (!orgState.orgSettings) {
      return;
    }
    if (useOktaMfa) {
      setIsIdpMfaModalOpen(true);
    } else {
      authState.authClient?.mfaFlow("/callback/slackmfa");
    }
  }, [orgState.orgSettings, useOktaMfa, authState.authClient]);

  return (
    <>
      {isIdpMfaModalOpen ? (
        <IdpMfaModal
          onClose={() => {
            setIsIdpMfaModalOpen(false);
            history.replace("/");
          }}
          onMfaSuccess={() => {
            setIsIdpMfaModalOpen(false);
            window.location.replace("slack://open");
            history.replace("/");
          }}
        />
      ) : (
        <FullPageLoading />
      )}
    </>
  );
};

export default SlackMfa;
