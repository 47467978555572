import { AccessReviewReviewerAssignmentPolicy } from "api/generated/graphql";

export const ACCESS_REVIEW_ASSIGNMENT_LABELS: Record<
  AccessReviewReviewerAssignmentPolicy,
  string
> = {
  [AccessReviewReviewerAssignmentPolicy.Manually]: "Manually assign reviewers",
  [AccessReviewReviewerAssignmentPolicy.ByManager]:
    "Auto-assign to user's manager",
  [AccessReviewReviewerAssignmentPolicy.ByOwningTeamAdmin]:
    "Auto-assign to resource admins via round robin",
  [AccessReviewReviewerAssignmentPolicy.ByOwningTeamAdminAll]:
    "Auto-assign to all resource admins",
  [AccessReviewReviewerAssignmentPolicy.ByApprovers]:
    "Auto-assign to resource approvers via round robin",
  [AccessReviewReviewerAssignmentPolicy.ByApproversAll]:
    "Auto-assign to all resource approvers",
};
