import OpalIcon from "assets/logos/opal-logo-standalone.svg";
import OpalLogo from "assets/logos/opal-security-logo.svg";
import { TooltipPlacement } from "components/label/Label";
import { Badge, ContextMenu, Icon, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useKBar, VisualState } from "kbar";
import { useState } from "react";
import { useLocation } from "react-router";
import { Link, useHistory } from "react-router-dom";
import useLogEvent from "utils/analytics";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useHasInventory, useLocalStorage, useMountEffect } from "utils/hooks";
import { useBooleanURLSearchParam } from "utils/router/hooks";
import { ConditionalWrapper } from "views/Common";

import LeftSidebarUserMenu from "./LeftSidebarUserMenu";
import * as styles from "./LeftSidebarV3.css";
import useLeftSidebarSectionInfos, {
  LeftSidebarOptionInfo,
  LeftSidebarSectionInfo,
} from "./useLeftSidebarRoutesV3";

const LeftSidebar = () => {
  const history = useHistory();
  const leftSidebarSectionInfos = useLeftSidebarSectionInfos();
  const [collapsed, setCollapsed] = useLocalStorage("collapsedSidebar", false);
  const hasEndUserXP = useFeatureFlag(FeatureFlag.EndUserExperience);

  // not super happy with this location for launching search from URL
  // but it needs to be a child of SpotlightSearchProvider in order to
  // have access to it's state. Long term we should have a singular
  // container component that handles all the "global" things but this works.
  const { query } = useKBar();
  const [searchOverlayOn] = useBooleanURLSearchParam("searchOverlay");
  useMountEffect(() => {
    if (searchOverlayOn && query) {
      query.setVisualState(VisualState.animatingIn);
    }
  });

  return (
    <div className={styles.container({ collapsed: collapsed })}>
      <nav className={styles.menu}>
        <button
          className={styles.logo({ collapsed })}
          onClick={() => {
            history.push("/");
          }}
          type="button"
        >
          <img
            className={sprinkles({ overflow: "auto" })}
            src={collapsed ? OpalIcon : OpalLogo}
            alt="Opal logo"
            onClick={() => {
              history.push("/");
            }}
          />
        </button>
        <div
          className={styles.collapseButtonContainer}
          onClick={() => setCollapsed(!collapsed)}
        >
          <Tooltip
            tooltipText={collapsed ? "Expand Sidebar" : "Collapse Sidebar"}
          >
            <div className={styles.collapseButton}>
              <Icon name="layout-left" size="xs" />
            </div>
          </Tooltip>
        </div>
        {leftSidebarSectionInfos
          .filter((itemInfo) => {
            if ("options" in itemInfo) {
              return itemInfo.options.some(
                (opt) => !opt.hidden && opt.canAccess
              );
            } else {
              return !itemInfo.hidden && itemInfo.canAccess;
            }
          })
          .map((itemInfo, i) =>
            "options" in itemInfo ? (
              <LeftSidebarNavSection
                key={`${itemInfo.title}-${i}`}
                sectionInfo={itemInfo}
                collapsed={collapsed}
              />
            ) : (
              <LeftSidebarNavLink
                key={`${itemInfo.title}-${i}`}
                optionInfo={itemInfo}
                collapsed={collapsed}
              />
            )
          )}
      </nav>
      {hasEndUserXP && (
        // TODO: Remove feature flag and only use v3 feature flag
        <ConditionalWrapper
          wrapper={(children) => (
            <Tooltip
              tooltipText={"Make a request"}
              placement={TooltipPlacement.BottomStart}
            >
              {children}
            </Tooltip>
          )}
          useWrapper={collapsed}
        >
          <Link
            key={"request-button"}
            className={styles.menuItem({
              fullWidth: true,
              main: true,
              center: true,
            })}
            to={"/request-access"}
            title={"Make a request"}
          >
            <div className={styles.menuItemLeft}>
              <Icon name="raised-hand" size="xs" />
              {!collapsed && (
                <div className={styles.menuItemTitle}>Make a request</div>
              )}
            </div>
          </Link>
        </ConditionalWrapper>
      )}
      <Footer collapsed={collapsed} />
    </div>
  );
};

const LeftSidebarNavLink = ({
  optionInfo,
  collapsed = false,
}: {
  optionInfo: LeftSidebarOptionInfo;
  collapsed: boolean;
}) => {
  const logEvent = useLogEvent();
  const location = useLocation();
  const hasInventory = useHasInventory();

  if (optionInfo.hidden) {
    return null;
  }

  let active = [
    optionInfo.route,
    ...(optionInfo.extraRoutes ?? []),
  ].some((route) => location.pathname.startsWith(route));

  // special cased active info for inventory (this is bad - do not copy)
  if (hasInventory) {
    if (
      active === true &&
      optionInfo.route === "/apps" &&
      !location.pathname.startsWith("/apps") &&
      !location.pathname.startsWith("/bundles")
    ) {
      // rendering the Catalog menu item AND the route doesn't start with /apps
      active = false;
    }

    if (
      active === false &&
      optionInfo.route === "/inventory" &&
      (location.pathname.startsWith("/resources") ||
        location.pathname.startsWith("/groups") ||
        location.pathname.startsWith("/owners") ||
        location.pathname.startsWith("/users") ||
        location.pathname.startsWith("/tags"))
    ) {
      // rendering the Inventory menu item AND the route doesn't start with /apps
      active = true;
    }
  }
  const badgeCount = optionInfo?.badgeCount;

  return (
    <ConditionalWrapper
      wrapper={(children) => (
        <Tooltip
          tooltipText={optionInfo.title}
          placement={TooltipPlacement.BottomStart}
        >
          {Boolean(badgeCount && badgeCount > 0) && collapsed ? (
            <div className={styles.notification}>
              <div className={styles.notificationIcon({ active })}>•</div>
              <div className={styles.notificationSubItems}>{children}</div>
            </div>
          ) : (
            children
          )}
        </Tooltip>
      )}
      useWrapper={collapsed}
    >
      <Link
        key={optionInfo.route}
        className={styles.menuItem({ active })}
        to={optionInfo.route}
        onClick={() =>
          logEvent({
            name: "left_sidebar_tab_click",
            properties: { tabName: optionInfo.title },
          })
        }
        title={optionInfo.title}
      >
        <div className={styles.menuItemLeft}>
          <Icon name={optionInfo.iconName} size="xs" />
          {!collapsed && (
            <div className={styles.menuItemTitle}>
              {optionInfo.title}
              {Boolean(badgeCount && badgeCount > 0) && (
                <Badge
                  count={optionInfo.badgeCount ?? 0}
                  color="white"
                  size="sm"
                />
              )}
            </div>
          )}
        </div>
      </Link>
    </ConditionalWrapper>
  );
};

const LeftSidebarNavSection = ({
  sectionInfo,
  collapsed = false,
}: {
  sectionInfo: LeftSidebarSectionInfo;
  collapsed: boolean;
}) => {
  const logEvent = useLogEvent();
  const location = useLocation();
  const history = useHistory();
  const options = sectionInfo.options.filter(
    (opt) => opt.canAccess === true && !opt.hidden === true
  );
  const hasActiveOption = options.some((optionInfo) => {
    let active = location.pathname.startsWith(optionInfo.route);
    optionInfo.extraRoutes?.forEach((route) => {
      if (location.pathname.startsWith(route)) {
        active = true;
      }
    });
    return active;
  });
  const [open, setOpen] = useState(hasActiveOption);
  const configActive = options
    .map((optionInfo) => optionInfo.route)
    .some((route) => location.pathname.startsWith(route));

  const renderMenuItem = (optionInfo: LeftSidebarOptionInfo) => {
    if (optionInfo.hidden) {
      return null;
    }
    let active = location.pathname.startsWith(optionInfo.route);
    optionInfo.extraRoutes?.forEach((route) => {
      if (location.pathname.startsWith(route)) {
        active = true;
      }
    });
    return (
      <Link
        key={optionInfo.route}
        className={styles.menuItem({ active, nested: !collapsed })}
        to={optionInfo.route}
        onClick={() =>
          logEvent({
            name: "left_sidebar_tab_click",
            properties: { tabName: optionInfo.title },
          })
        }
        title={optionInfo.title}
      >
        {!collapsed && (
          <div className={styles.menuItemTitle}>{optionInfo.title}</div>
        )}
      </Link>
    );
  };

  const renderPopoverMenuItem = (optionInfo: LeftSidebarOptionInfo) => {
    return {
      label: optionInfo.title,
      onClick: () => {
        history.push(optionInfo.route);
      },
      active: location.pathname.startsWith(optionInfo.route),
    };
  };

  if (collapsed) {
    return (
      <ContextMenu
        block
        options={options.map((optionInfo) => renderPopoverMenuItem(optionInfo))}
        renderButton={(onClick) => (
          <Tooltip
            tooltipText={sectionInfo.title}
            placement={TooltipPlacement.BottomStart}
          >
            <button
              className={styles.menuItem({ active: hasActiveOption })}
              onClick={onClick}
            >
              <Icon name={sectionInfo.iconName} size="xs" />
            </button>
          </Tooltip>
        )}
        header={
          <span className={styles.contextMenuHeader}>{sectionInfo.title}</span>
        }
        collapsed={collapsed}
        rightAligned
        startAligned
      />
    );
  }

  return (
    <>
      <Link
        key={sectionInfo.title}
        className={styles.section}
        to={open || configActive ? location.pathname : options[0].route}
        title={sectionInfo.title}
        onClick={() => {
          setOpen((prev) => !prev);
          logEvent({
            name: "left_sidebar_tab_click",
            properties: { tabName: sectionInfo.title },
          });
        }}
      >
        <div className={styles.menuItemLeft}>
          <Icon name={sectionInfo.iconName} size="xs" />
          <div className={styles.sectionTitle}>{sectionInfo.title}</div>
          <Icon name={open ? "chevron-down" : "chevron-right"} size="xs" />
        </div>
      </Link>
      {open && options.map((optionInfo) => renderMenuItem(optionInfo))}
    </>
  );
};

const Footer = ({ collapsed = false }: { collapsed: boolean }) => {
  const menuItems = [
    {
      label: "Opal Docs",
      onClick: () => {
        window.open("https://docs.opal.dev/docs");
      },
    },
    {
      label: "Customer Support",
      onClick: () => {
        window.open("https://opaldev.zendesk.com/hc/en-us");
      },
    },
  ];

  return (
    <div>
      <ConditionalWrapper
        wrapper={(children) => (
          <Tooltip
            tooltipText={"Get Support"}
            placement={TooltipPlacement.BottomStart}
          >
            {children}
          </Tooltip>
        )}
        useWrapper={collapsed}
      >
        <ContextMenu
          block
          options={menuItems}
          renderButton={(onClick) => (
            <button
              className={styles.menuItem({ active: false, fullWidth: true })}
              onClick={onClick}
            >
              {!collapsed ? (
                <div className={styles.menuItemTitle}>Get Support</div>
              ) : (
                <Icon name="help-circle" size="xs" />
              )}
            </button>
          )}
          collapsed={collapsed}
        />
      </ConditionalWrapper>
      <ConditionalWrapper
        wrapper={(children) => (
          <Tooltip
            tooltipText={"What's New"}
            placement={TooltipPlacement.BottomStart}
          >
            {children}
          </Tooltip>
        )}
        useWrapper={collapsed}
      >
        <button
          className={styles.menuItem({ active: false, fullWidth: true })}
          onClick={() => {
            window.open("https://docs.opal.dev/changelog");
          }}
        >
          <div className={styles.menuItemLeft}>
            {!collapsed ? (
              <div className={styles.menuItemTitle}>What's New</div>
            ) : (
              <Icon name="stars" size="xs" />
            )}
          </div>
        </button>
      </ConditionalWrapper>
      <div className={styles.divider} />
      <LeftSidebarUserMenu collapsed={collapsed} />
    </div>
  );
};

export default LeftSidebar;
