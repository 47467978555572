import { getModifiedErrorMessage } from "api/ApiContext";
import {
  Maybe,
  UserDropdownPreviewFragment,
  UserFragment,
  UserPreviewLargeFragment,
  useUpdateUserManagerMutation,
} from "api/generated/graphql";
import { PaginatedUserDropdown } from "components/dropdown/PaginatedUserDropdown";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Modal } from "components/ui";
import { useState } from "react";
import { logError } from "utils/logging";

type ManagerModalProps = {
  isModalOpen: boolean;
  setIsModalOpen: (isOpen: boolean) => void;
  user?: Maybe<UserPreviewLargeFragment>;
  manager?: UserDropdownPreviewFragment;
  onUserUpdate?: (user: UserFragment) => void;
};

export const ManagerModal = (props: ManagerModalProps) => {
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [manager, setManager] = useState<Maybe<UserDropdownPreviewFragment>>(
    props.manager ?? null
  );
  const [updateUserManager, { loading }] = useUpdateUserManagerMutation();

  const modalReset = () => {
    props.setIsModalOpen(false);
    setErrorMessage(null);
    setManager(null);
  };

  const handleSubmit = async () => {
    if (!props.user) {
      return;
    }
    try {
      const { data } = await updateUserManager({
        variables: {
          input: {
            id: props.user.id,
            managerId: {
              userId: manager?.id ?? null,
            },
          },
        },
      });
      if (data) {
        let user = null;
        switch (data.updateUserManager.__typename) {
          case "UpdateUserManagerResult":
            modalReset();
            displaySuccessToast(`Success: user manager updated`);
            user = data.updateUserManager.user;
            props.onUserUpdate && props.onUserUpdate(user);
            break;
          case "UserNotFoundError":
          case "SystemUserIsImmutableError":
          case "ManagedHRDataIsImmutableError":
            setErrorMessage(data.updateUserManager.message);
            break;
          default:
            logError(new Error(`failed to update user manager`));
            setErrorMessage(`Error: failed to update user manager`);
        }
      }
    } catch (error) {
      logError(error, "failed to update user manager");
      setErrorMessage(
        getModifiedErrorMessage(`Error: failed to update user manager`, error)
      );
    }
  };

  return (
    <Modal
      title={"Set manager"}
      isOpen={props.isModalOpen}
      onClose={modalReset}
    >
      <Modal.Body>
        <PaginatedUserDropdown
          value={manager ?? undefined}
          onChange={(user) => setManager(user ?? null)}
          hiddenUserIds={manager ? [manager.id] : []}
          clearable
          autoFocus
        />
        {errorMessage ? (
          <ModalErrorMessage errorMessage={errorMessage} />
        ) : undefined}
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Submit"
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLoading={loading}
        primaryButtonDisabled={
          (manager && manager.id === props.manager?.id) || false
        }
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={modalReset}
      />
    </Modal>
  );
};

export default ManagerModal;
