import {
  ConfigurationTemplateFragment,
  useGetConfigurationsTemplateQuery,
  useUpdateConfigurationTemplateMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { ResourceConfig } from "components/forms/common";
import {
  getResourceConfigChangedFields,
  makeConfigForTemplate,
  makeUpdateInputForTemplate,
  validateResourceConfig,
} from "components/forms/utils";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import { useToast } from "components/toast/Toast";
import { Icon } from "components/ui";
import { isEqual } from "lodash";
import { useContext, useState } from "react";
import { useParams } from "react-router";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import {
  ForbiddenPage,
  NotFoundPage,
  UnexpectedErrorPage,
} from "views/error/ErrorCodePage";
import OrgContext from "views/settings/OrgContext";

import ConfigTemplateForm from "./ConfigTemplateForm";

const EditConfigTemplateView = () => {
  const { authState } = useContext(AuthContext);
  const { configurationTemplateId } = useParams<{
    configurationTemplateId: string;
  }>();

  const { data, error, loading } = useGetConfigurationsTemplateQuery({
    variables: {
      ID: configurationTemplateId,
    },
    skip: !configurationTemplateId,
  });
  let configurationTemplate: ConfigurationTemplateFragment | undefined;
  switch (data?.configurationTemplate.__typename) {
    case "ConfigurationTemplateNotFoundError":
      return <NotFoundPage />;
    case "ConfigurationTemplateResult":
      configurationTemplate =
        data.configurationTemplate.configurationTemplate ?? undefined;
      break;
  }

  if (!authState.user?.isAdmin) {
    return <ForbiddenPage />;
  }
  if (loading) {
    return <FullscreenSkeleton />;
  }
  if (!configurationTemplate || error) {
    return <UnexpectedErrorPage error={error} />;
  }

  return <EditConfigTemplate configurationTemplate={configurationTemplate} />;
};

interface Props {
  configurationTemplate: ConfigurationTemplateFragment;
}

const EditConfigTemplate = (props: Props) => {
  const { configurationTemplate } = props;
  const transitionBack = useTransitionBack();
  const { orgState } = useContext(OrgContext);
  const { displaySuccessToast } = useToast();

  const [
    updateConfigurationTemplate,
    { loading: saveLoading },
  ] = useUpdateConfigurationTemplateMutation();

  const initialConfig = {
    ...makeConfigForTemplate(props.configurationTemplate),
    name: props.configurationTemplate.name,
  };
  const [config, setConfig] = useState<Partial<ResourceConfig>>(initialConfig);
  const [errors, setErrors] = useState<string[]>([]);

  const handleClose = () => {
    transitionBack(
      `/templates/configurations/${props.configurationTemplate.id}`
    );
  };

  const handleSave = async () => {
    if (!configurationTemplate || !initialConfig) return;

    const errors = validateResourceConfig(config, orgState);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }
    if (!config.name) {
      setErrors(["A unique name is required."]);
      return;
    }
    if (!config.adminOwner) {
      setErrors(["Admin owner is required."]);
      return;
    }

    try {
      setErrors([]);
      const changedConfig = getResourceConfigChangedFields(
        initialConfig,
        config
      );

      const { data } = await updateConfigurationTemplate({
        variables: {
          input: makeUpdateInputForTemplate(
            configurationTemplate.id,
            changedConfig
          ),
        },
      });

      switch (data?.updateConfigurationTemplate.__typename) {
        case "UpdateConfigurationTemplateResult":
          setErrors([]);
          displaySuccessToast("Successfully updated configuration template");
          handleClose();
          break;
        case "ConfigurationTemplateNameExistsError":
          setErrors([data.updateConfigurationTemplate.message]);
          break;
        default:
          logError("Failed to update configuration template");
          setErrors(["Failed to update configuration template."]);
      }
    } catch (err) {
      logError(err, "Failed to update configuration template");
      setErrors(["Failed to update configuration template."]);
    }
  };

  const hasRequiredFields =
    config.name && config.name.trim().length > 0 && config.adminOwner;
  const title = (
    <>
      Edit: <Icon name="template" /> {props.configurationTemplate.name}
    </>
  );
  return (
    <FullscreenView
      title={title}
      onCancel={handleClose}
      onPrimaryButtonClick={handleSave}
      primaryButtonDisabled={
        isEqual(config, initialConfig) || !hasRequiredFields
      }
      primaryButtonLoading={saveLoading}
    >
      <ConfigTemplateForm
        config={config}
        onChange={setConfig}
        errors={errors}
      />
    </FullscreenView>
  );
};

export default EditConfigTemplateView;
