import { Column } from "components/column/Column";
import { useContext } from "react";

import { AppsContext } from "./AppsContext";

const AppsContentColumn: React.FC<
  Omit<PropsFor<typeof Column>, "isContent">
> = (props) => {
  const { bulkMode } = useContext(AppsContext);
  if (bulkMode) {
    return null;
  }

  return (
    <Column maxWidth="lg" {...props} isContent>
      {props.children}
    </Column>
  );
};

export default AppsContentColumn;
