import {
  EntityType,
  TagPreviewLargeFragment,
  UserTagFragment,
} from "api/generated/graphql";
import { ResourceLabel } from "components/label/Label";
import ServiceTypeLabel from "components/label/ServiceTypeLabel";
import MaterialTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MaterialTable";
import moment from "moment";
import { useHistory } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";

type TagUserTableRow = {
  user: string;
  tagSource: string;
  createdAt: string;
};

type TagUsersTableProps = {
  tag: TagPreviewLargeFragment;
  tagUsers: UserTagFragment[];
};

const TagUsersTable = (props: TagUsersTableProps) => {
  const history = useHistory();

  const headers: Header<TagUserTableRow>[] = [
    { id: "user", label: "User" },
    { id: "tagSource", label: "Tag source" },
    { id: "createdAt", label: "Created at" },
  ];

  const rows: CellRow<TagUserTableRow>[] = props.tagUsers.map((tagUser) => {
    const createdAtTime = moment(new Date(tagUser.createdAt));
    const user = tagUser.user;

    return {
      id: tagUser.id || "",
      data: {
        user: {
          value: user?.id ?? "",
          element: (
            <ResourceLabel
              text={user?.fullName}
              subText={user?.teamAttr}
              iconLarge={true}
              bold={true}
              entityType={EntityTypeDeprecated.User}
              avatar={user?.avatarUrl}
              pointerCursor={true}
            />
          ),
          clickHandler: () => {
            history.push(
              getResourceUrlNew({
                entityId: user?.id ?? null,
                entityType: EntityType.User,
              })
            );
          },
        },
        tagSource: {
          value: `tagSource_${tagUser.source}`,
          sortValue: tagUser.source,
          element: <ServiceTypeLabel serviceType={tagUser.source} />,
        },
        createdAt: {
          value: createdAtTime.unix(),
          element: <>{createdAtTime.fromNow()}</>,
        },
      },
    };
  });

  return (
    <MaterialTable<TagUserTableRow>
      headers={headers}
      rows={rows}
      denseFormatting={true}
    />
  );
};

export default TagUsersTable;
