import { gql, useMutation, useQuery } from "@apollo/client";
import {
  DeleteIdpGroupMappingsMutation,
  ResourceNameQuery,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Modal } from "components/ui";
import pluralize from "pluralize";
import { useState } from "react";
import { logError } from "utils/logging";

type Props = {
  isOpen: boolean;
  onComplete: () => void;
  onCancel: () => void;
  groupIds: string[];
  resourceId: string;
};

const QUERY = gql`
  query resourceName($id: ResourceId!) {
    resource(input: { id: $id }) {
      ... on ResourceResult {
        resource {
          id
          name
        }
      }
      ... on ResourceNotFoundError {
        message
      }
    }
  }
`;

const MUTATION = gql`
  mutation deleteIdpGroupMappings($input: DeleteIdpGroupMappingsInput!) {
    deleteIdpGroupMappings(input: $input) {
      success
    }
  }
`;

export default ({
  isOpen,
  onComplete,
  onCancel,
  groupIds,
  resourceId,
}: Props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const { displaySuccessToast } = useToast();
  const [
    deleteIdpGroupMappings,
    { loading: deleteIdpGroupMappingsLoading },
  ] = useMutation<DeleteIdpGroupMappingsMutation>(MUTATION, {
    refetchQueries: ["AppResources"],
  });

  const { data } = useQuery<ResourceNameQuery>(QUERY, {
    variables: {
      id: resourceId,
    },
    fetchPolicy: "cache-and-network",
  });

  const resourceName =
    data?.resource.__typename === "ResourceResult"
      ? data?.resource.resource.name
      : "";

  const numChecked = groupIds.length;

  const handleSubmit = async () => {
    const input = {
      appResourceId: resourceId,
      groupRoleMappingIds: groupIds,
    };
    try {
      const { data } = await deleteIdpGroupMappings({
        variables: {
          input: input,
        },
      });
      if (data?.deleteIdpGroupMappings.success) {
        displaySuccessToast("Success: removed mappings");
        onComplete();
      }
    } catch (e) {
      logError(e, `failed to remove mappings`);
      setErrorMessage(`Error: failed to remove mappings`);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onCancel}
      title={`Remove ${numChecked} ${pluralize(
        "mapping",
        numChecked
      )} from ${resourceName}`}
    >
      <Modal.Body>
        {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
        <p>
          Are you sure you want to remove {numChecked}{" "}
          {pluralize("mapping", numChecked)} from {resourceName}?
        </p>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Remove"
        primaryButtonLoading={deleteIdpGroupMappingsLoading}
        primaryButtonDisabled={numChecked === 0}
        onPrimaryButtonClick={handleSubmit}
        secondaryButtonLabel="Close"
        onSecondaryButtonClick={onCancel}
      />
    </Modal>
  );
};
