import {
  EntityType,
  FilteredGroupsInput,
  FiltersInput,
  GroupPreviewLargeFragment,
  SortDirection,
  useFilteredGroupsLargeQuery,
} from "api/generated/graphql";
import GroupBindingDetailPopover from "components/group_bindings/GroupBindingDetailPopover";
import { ResourceLabel } from "components/label/Label";
import { useToast } from "components/toast/Toast";
import sprinkles from "css/sprinkles.css";
import React, { useEffect, useState } from "react";
import { AccessReviewItemPreviewModal } from "views/access_reviews/settings/scope/AccessReviewItemPreviewModal";

interface AccessReviewGroupsPreviewModalProps {
  onClose: () => void;
  filters: FiltersInput;
  includeGroupBindings?: boolean;
}

export const AccessReviewGroupsPreviewModal: React.FC<AccessReviewGroupsPreviewModalProps> = (
  props
) => {
  const { onClose } = props;
  const [sort, setSort] = useState(SortDirection.Asc);
  const queryInput: FilteredGroupsInput = {
    filters: props.filters,
    sort,
    includeSourceGroups: props.includeGroupBindings,
  };
  const { data, error, loading, fetchMore } = useFilteredGroupsLargeQuery({
    variables: {
      input: queryInput,
    },
  });
  const { displayErrorToast } = useToast();
  useEffect(() => {
    if (error) {
      displayErrorToast("An error occurred while loading the groups list.");
      onClose();
    }
  }, [onClose, error, displayErrorToast]);

  const loadMoreRows = async (cursor: string) => {
    await fetchMore({
      variables: {
        input: { ...queryInput, cursor },
      },
    });
  };

  const groups: GroupPreviewLargeFragment[] =
    data?.filteredGroups?.groups ?? [];

  const items = groups.map((r) => {
    return {
      id: r.id,
      value: r.name,
      rendered: (
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "row",
            gap: "sm",
          })}
        >
          <ResourceLabel
            text={r.name}
            entityId={r.id}
            entityTypeNew={EntityType.Group}
            bold={true}
            iconLarge={true}
            target="_blank"
          />
          {r.groupBinding && (
            <GroupBindingDetailPopover
              groupId={r.id}
              groupBinding={r.groupBinding}
            />
          )}
        </div>
      ),
      connection: r.connection ?? undefined,
      admin: r.adminOwner ?? undefined,
    };
  });

  return (
    <AccessReviewItemPreviewModal
      itemType="group"
      onClose={onClose}
      items={items}
      loading={loading}
      cursor={data?.filteredGroups?.cursor ?? undefined}
      onSortDirectionChange={setSort}
      sortDirection={sort}
      loadMoreRows={loadMoreRows}
      totalNumRows={data?.filteredGroups?.totalNumGroups ?? 0}
    />
  );
};
