import {
  AccessReviewAction,
  AccessReviewConnectionFragment,
  AccessReviewConnectionUserFragment,
  AccessReviewItemStatus,
  AccessReviewType,
  ReviewConnectionUserAction,
  ReviewerUserStatus,
  useAccessReviewConnectionQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import { connectionTypeInfoByType } from "components/label/ConnectionTypeLabel";
import { Divider, Input } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { filterSearchResults } from "utils/search/filter";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import { calculateBatchAcceptRevokeState } from "./AccessReviewAcceptRevokeToggleButton";
import AccessReviewContext, {
  AccessReviewContextActionType,
  emptyPerformReviewState,
} from "./AccessReviewContext";
import * as styles from "./AccessReviewResource.css";
import ConnectionUserSubRow from "./common/ConnectionUserSubRow";
import DecisionButtons from "./common/DecisionButtons";
import NoteCell from "./common/NoteCell";
import StatusCell from "./common/StatusCell";
import SubmitAccessReviewButton from "./common/SubmitAccessReviewButtonV2";
import UserCell from "./common/UserCell";
import { UARSelectContext } from "./UARSelectContext";

interface ConnectionUserRow {
  id: string;
  user: string;
  outcome?: string;
  note?: string;
  data: AccessReviewConnectionUserFragment;
}

const AccessReviewConnection = () => {
  const { accessReviewConnectionId } = useParams<Record<string, string>>();

  const { data, error, loading } = useAccessReviewConnectionQuery({
    variables: {
      input: {
        id: accessReviewConnectionId,
      },
    },
    skip: !accessReviewConnectionId,
  });

  if (loading) {
    return (
      <Column isContent maxWidth="lg">
        <ColumnContentSkeleton />
      </Column>
    );
  }

  let accessReviewConnection: AccessReviewConnectionFragment | undefined;
  if (
    data?.accessReviewConnection.__typename === "AccessReviewConnectionResult"
  ) {
    accessReviewConnection = data.accessReviewConnection.accessReviewConnection;
  }

  if (error || !accessReviewConnection) {
    return (
      <Column isContent maxWidth="lg">
        <UnexpectedErrorPage />
      </Column>
    );
  }

  return (
    <Column isContent maxWidth="lg">
      {accessReviewConnection.currentUserReviewerStatus?.itemStatus ===
      AccessReviewItemStatus.Completed ? (
        <AccessReviewConnectionCompleted
          accessReviewConnection={accessReviewConnection}
        />
      ) : (
        <AccessReviewConnectionTodo
          accessReviewConnection={accessReviewConnection}
        />
      )}
    </Column>
  );
};

interface Props {
  accessReviewConnection: AccessReviewConnectionFragment;
}

const AccessReviewConnectionTodo = ({ accessReviewConnection }: Props) => {
  const { accessReviewId, accessReviewConnectionId } = useParams<
    Record<string, string>
  >();
  const history = useHistory();
  const { authState } = useContext(AuthContext);
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );
  const {
    selectedConnectionUserIds,
    setSelectedConnectionUserIds,
  } = useContext(UARSelectContext);
  const [searchQuery, setSearchQuery] = useState("");

  const performReviewState =
    accessReviewState.performReviewStateByUARConnectionId[
      accessReviewConnectionId
    ];

  // Initialize review state if not already initialized
  useEffect(() => {
    if (!performReviewState || !performReviewState.connectionUserActions) {
      accessReviewDispatch({
        type: AccessReviewContextActionType.AccessReviewItemUpdate,
        payload: {
          performReviewStateByUARConnectionId: {
            ...accessReviewState.performReviewStateByUARConnectionId,
            [accessReviewConnectionId]: emptyPerformReviewState(),
          },
        },
      });
    }
  }, [
    accessReviewState,
    accessReviewConnectionId,
    performReviewState,
    accessReviewDispatch,
  ]);

  const selfReviewAllowed =
    accessReviewConnection?.accessReview?.selfReviewAllowed;
  const connection = accessReviewConnection?.connection;
  // Filter for search results and
  // only show incomplete connection users current user needs to review
  const connectionUsers = (
    accessReviewConnection?.connectionUsers ?? []
  ).filter((user) => {
    return user.reviewerUsers?.find((reviewer) => {
      return (
        reviewer.userId === authState.user?.user.id &&
        (selfReviewAllowed || authState.user?.user.id !== user.userId) &&
        reviewer.status === ReviewerUserStatus.NotStarted
      );
    });
  });
  const filteredSearchConnectionUsers = filterSearchResults(
    connectionUsers,
    searchQuery,
    (connectionUser) => [
      connectionUser.user?.fullName,
      connectionUser.user?.email,
    ]
  );

  if (!performReviewState) {
    return null;
  }

  if (!connection) {
    return (
      <Column isContent maxWidth="lg">
        <UnexpectedErrorPage />
      </Column>
    );
  }

  const getConnectionUserReviewState = (connectionUserId: string) => {
    return performReviewState.connectionUserActions.find(
      (ru) => ru.accessReviewConnectionUserId === connectionUserId
    )?.action;
  };

  const handleReviewAction = (
    state: AccessReviewAction,
    accessReviewConnectionUserId: string
  ) => {
    const existingInfo = performReviewState.connectionUserActions.find(
      (connectionUserAction) =>
        connectionUserAction.accessReviewConnectionUserId ===
        accessReviewConnectionUserId
    );

    if (existingInfo) {
      existingInfo.action = state;
    } else {
      performReviewState.connectionUserActions.push({
        accessReviewConnectionUserId,
        action: state,
      });
    }
    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARConnectionId: {
          ...accessReviewState.performReviewStateByUARConnectionId,
          [accessReviewConnectionId]: performReviewState,
        },
      },
    });
  };

  const handleBulkReviewAction = (state: AccessReviewAction) => {
    performReviewState.connectionUserActions.forEach((connectionUserAction) => {
      connectionUserAction.action = state;
    });

    connectionUsers.forEach((connectionUser) => {
      const existingInfo = performReviewState.connectionUserActions.find(
        (connectionUserAction) =>
          connectionUserAction.accessReviewConnectionUserId ===
          connectionUser.id
      );

      if (!existingInfo) {
        performReviewState.connectionUserActions.push({
          accessReviewConnectionUserId: connectionUser.id,
          action: state,
        });
      }
    });

    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARConnectionId: {
          ...accessReviewState.performReviewStateByUARConnectionId,
          [accessReviewConnectionId]: performReviewState,
        },
      },
    });
  };

  const goBack = () => {
    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARConnectionId: {},
        performReviewStateByUARGroupId: {},
        performReviewStateByUARUserId: {},
        performReviewStateByUARResourceId: {},
      },
    });
    history.push(`/access-reviews/${accessReviewId}/my-reviews`);
  };

  const connectionUserActionByUarConnectionUserId: Record<
    string,
    ReviewConnectionUserAction
  > = {};
  performReviewState?.connectionUserActions?.forEach((connectionUserAction) => {
    const id = connectionUserAction.accessReviewConnectionUserId;
    connectionUserActionByUarConnectionUserId[id] = connectionUserAction;
  });

  const rows: ConnectionUserRow[] = filteredSearchConnectionUsers.map(
    (connectionUser) => {
      const connectionUserAction =
        connectionUserActionByUarConnectionUserId[connectionUser.id];
      return {
        id: connectionUser.id,
        user: connectionUser.user?.fullName || connectionUser.userId,
        outcome: connectionUser.statusAndOutcome.outcome,
        note: connectionUserAction?.note ?? undefined,
        data: connectionUser,
      };
    }
  );

  const bulkState = calculateBatchAcceptRevokeState(
    performReviewState.connectionUserActions,
    connectionUsers.length
  );
  const bulkDecisionHeader = (
    <DecisionButtons
      state={bulkState}
      onApproveClick={() =>
        handleBulkReviewAction(
          bulkState === AccessReviewAction.Accept
            ? AccessReviewAction.NoAction
            : AccessReviewAction.Accept
        )
      }
      onRevokeClick={() =>
        handleBulkReviewAction(
          bulkState === AccessReviewAction.Revoke
            ? AccessReviewAction.NoAction
            : AccessReviewAction.Revoke
        )
      }
      clearBackground
    />
  );

  const columns: Header<ConnectionUserRow>[] = [
    {
      id: "user",
      label: "User",
      width: 400,
      customCellRenderer: (row) => {
        const user = row.data.user;
        if (!user) {
          return <></>;
        }
        return <UserCell user={user} />;
      },
    },
    {
      id: "outcome",
      label: "",
      sortable: false,
      customHeader: rows.length > 0 ? bulkDecisionHeader : <></>,
      width: 100,
      customCellRenderer: (row) => {
        const state = getConnectionUserReviewState(row.id);
        return (
          <DecisionButtons
            state={state}
            onApproveClick={() =>
              handleReviewAction(
                state === AccessReviewAction.Accept
                  ? AccessReviewAction.NoAction
                  : AccessReviewAction.Accept,
                row.id
              )
            }
            onRevokeClick={() =>
              handleReviewAction(
                state === AccessReviewAction.Revoke
                  ? AccessReviewAction.NoAction
                  : AccessReviewAction.Revoke,
                row.id
              )
            }
          />
        );
      },
    },
    {
      id: "note",
      label: "",
      sortable: false,
      width: 40,
      customCellRenderer: (row) => (
        <NoteCell
          note={row.note}
          accessReviewID={accessReviewId}
          targetIDs={[row.id]}
          targetType={AccessReviewType.ConnectionUser}
          onNoteChange={(updatedNoteContent) => {
            const connectionUser = row.data;
            if (!performReviewState) {
              return;
            }

            const existingInfo = performReviewState.connectionUserActions.find(
              (connectionUserAction) =>
                connectionUserAction.accessReviewConnectionUserId ===
                connectionUser.id
            );

            if (existingInfo) {
              existingInfo.note = updatedNoteContent;
            } else {
              performReviewState.connectionUserActions.push({
                accessReviewConnectionUserId: connectionUser.id,
                action: AccessReviewAction.NoAction,
                note: updatedNoteContent,
              });
            }

            accessReviewDispatch({
              type: AccessReviewContextActionType.AccessReviewItemUpdate,
              payload: {
                performReviewStateByUARConnectionId: {
                  ...accessReviewState.performReviewStateByUARConnectionId,
                  [accessReviewConnectionId]: performReviewState,
                },
              },
            });
          }}
        />
      ),
    },
  ];

  const renderSubRow = (row: ConnectionUserRow) => {
    return <ConnectionUserSubRow connectionUser={row.data} />;
  };

  const selectedIds = selectedConnectionUserIds[accessReviewConnectionId] ?? [];

  return (
    <>
      <ColumnHeader
        title={connection.name}
        icon={{ type: "entity", entityType: connection.connectionType }}
        subtitle={connectionTypeInfoByType[connection.connectionType].name}
        onClose={goBack}
        rightActions={
          <SubmitAccessReviewButton
            accessReviewId={accessReviewId}
            onCancel={goBack}
            entityName={connection.name}
            performReviewState={performReviewState}
          />
        }
      />
      <Divider />
      <div className={sprinkles({ margin: "sm", marginTop: "lg" })}>
        Please review the following access:
      </div>
      <ColumnContent>
        <div className={styles.tableContainer}>
          <div className={styles.searchContainer}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Search this table"
            />
          </div>
          <Table
            rows={rows}
            totalNumRows={rows.length}
            getRowId={(ru) => ru.id}
            columns={columns}
            renderSubRow={renderSubRow}
            emptyState={{
              title: "No users to review",
            }}
            onCheckedRowsChange={(ids, checked) => {
              if (checked) {
                setSelectedConnectionUserIds(
                  accessReviewConnectionId,
                  _.uniq([...ids, ...selectedIds])
                );
              } else {
                setSelectedConnectionUserIds(
                  accessReviewConnectionId,
                  selectedIds.filter((id) => !ids.includes(id))
                );
              }
            }}
            checkedRowIds={new Set(selectedIds)}
            selectAllChecked={selectedIds.length === rows.length}
            onSelectAll={(checked) => {
              if (checked) {
                setSelectedConnectionUserIds(
                  accessReviewConnectionId,
                  rows.map((row) => row.id)
                );
              } else {
                setSelectedConnectionUserIds(accessReviewConnectionId, []);
              }
            }}
          />
        </div>
      </ColumnContent>
    </>
  );
};

const AccessReviewConnectionCompleted = ({ accessReviewConnection }: Props) => {
  const { accessReviewId } = useParams<Record<string, string>>();
  const history = useHistory();

  const [searchQuery, setSearchQuery] = useState("");

  const connection = accessReviewConnection.connection;
  // Filter for search results
  const connectionUsers = filterSearchResults(
    accessReviewConnection.connectionUsers ?? [],
    searchQuery,
    (connectionUser) => [
      connectionUser.user?.fullName,
      connectionUser.user?.email,
    ]
  );

  if (!connection) {
    return (
      <Column isContent maxWidth="lg">
        <UnexpectedErrorPage />
      </Column>
    );
  }

  const rows: ConnectionUserRow[] = connectionUsers.map((connectionUser) => {
    return {
      id: connectionUser.id,
      user: connectionUser.user?.fullName || connectionUser.userId,
      outcome: connectionUser.statusAndOutcome.outcome,
      data: connectionUser,
    };
  });

  const columns: Header<ConnectionUserRow>[] = [
    {
      id: "user",
      label: "User",
      customCellRenderer: (row) => {
        const user = row.data.user;
        if (!user) {
          return <></>;
        }
        return <UserCell user={user} />;
      },
    },
    {
      id: "outcome",
      label: "Status",
      sortable: false,
      width: 80,
      customCellRenderer: (row) => {
        const outcome = row.data.statusAndOutcome.outcome;
        const status = row.data.statusAndOutcome.status;
        if (!outcome || !status) {
          return <></>;
        }

        return <StatusCell outcome={outcome} status={status} />;
      },
    },
  ];

  const renderSubRow = (row: ConnectionUserRow) => {
    return <ConnectionUserSubRow connectionUser={row.data} />;
  };

  return (
    <>
      <ColumnHeader
        title={connection.name}
        icon={{ type: "entity", entityType: connection.connectionType }}
        subtitle={connectionTypeInfoByType[connection.connectionType].name}
        onClose={() =>
          history.push(`/access-reviews/${accessReviewId}/my-reviews`)
        }
      />
      <Divider />
      <ColumnContent>
        <div className={styles.tableContainer}>
          <div className={sprinkles({ marginBottom: "md" })}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Search this table"
            />
          </div>
          <Table
            rows={rows}
            totalNumRows={rows.length}
            getRowId={(ru) => ru.id}
            columns={columns}
            renderSubRow={renderSubRow}
          />
        </div>
      </ColumnContent>
    </>
  );
};

export default AccessReviewConnection;
