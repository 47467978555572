import { formatDuration } from "components/label/Label";
import { EditDurationForm } from "components/modals/EditDurationModal";
import { FormRow, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import { useContext } from "react";
import { AppsContext } from "views/apps/AppsContext";

import { FormMode } from "../common";

interface Props {
  mode: FormMode;
  // undefined = leave unchanged in bulk edit mode
  // null = indefinite duration
  maxDurationMin?: number | null;
  onChange: (durationMin: number | undefined | null) => void;
  orgMaxDuration?: number;
}

const MaxDurationRow = (props: Props) => {
  const { bulkMode } = useContext(AppsContext);

  const viewContent =
    props.maxDurationMin != null
      ? formatDuration(moment.duration(props.maxDurationMin, "m"))
      : "--";

  const editContent = (
    <>
      {bulkMode === "edit" && (
        <div className={sprinkles({ marginBottom: "md" })}>
          <Switch
            label="Leave unchanged"
            checked={props.maxDurationMin === undefined}
            onChange={(val) => props.onChange(val ? undefined : null)}
            rightAlign
          />
        </div>
      )}
      <div className={sprinkles({ marginBottom: "sm" })}>
        This is the maximum amount of time that direct access to this resource
        can request. If unset, users can request for indefinite access or the
        org-wide maximum access duration if set.
      </div>
      <EditDurationForm
        title="Duration"
        initValue={props.maxDurationMin}
        maxValue={props.orgMaxDuration}
        maxValueReason={`Exceeds organization maximum of ${formatDuration(
          moment.duration(props.orgMaxDuration, "m")
        )}`}
        // Pass null specifically to set indefinite duration
        // We reserve undefined in configs to denote unchanged.
        onChange={(val) => props.onChange(val ?? null)}
        nullable
        includeIndefinite={false}
      />
    </>
  );
  return (
    <FormRow title="Maximum duration">
      {props.mode === "view" ? viewContent : editContent}
    </FormRow>
  );
};

export default MaxDurationRow;
