import {
  EntityType,
  GroupBindingFragment,
  GroupBindingsSortByField,
  GroupType,
  SortDirection,
  useGroupBindingsTableQuery,
} from "api/generated/graphql";
import { Button, Label } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import { logError } from "utils/logging";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { useGroupBindingsFilterState } from "views/group_bindings/GroupBindingsContext";
import GroupBindingDeleteModal from "views/group_bindings/modals/GroupBindingDeleteModal";
import GroupBindingEditModal from "views/group_bindings/modals/GroupBindingEditModal";
import GroupBindingGroupsRow from "views/group_bindings/rows/GroupBindingGroupsRow";

import GroupBindingGroupLabel from "./rows/GroupBindingGroupLabel";

const BINDINGS_PER_PAGE = 30;
const CREATED_AT_COL_ID = GroupBindingsSortByField.CreatedAt;
const NAME_COL_ID = GroupBindingsSortByField.SourceGroupName;
const SOURCE_OF_TRUTH_COL_ID = GroupBindingsSortByField.SourceGroupType;
const CREATED_BY_COL_ID = GroupBindingsSortByField.CreatedBy;

function isSortableField(str: string): str is GroupBindingsSortByField {
  return Object.values<string>(GroupBindingsSortByField).includes(str);
}

type SortValue = {
  field: GroupBindingsSortByField;
  direction: SortDirection;
};

type BindingRow = {
  id: string;
  [NAME_COL_ID]: string;
  [SOURCE_OF_TRUTH_COL_ID]: string;
  [CREATED_AT_COL_ID]: string;
  [CREATED_BY_COL_ID]: string;
  actions?: unknown;
  data: GroupBindingFragment;
};

const GroupBindingsTable = () => {
  const {
    connectionIds,
    groupIds,
    groupTypes,
    searchQuery,
  } = useGroupBindingsFilterState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedGroupBindingIds, setSelectedGroupBindingIds] = useState<
    string[]
  >([]);
  const [
    passToModalGroupBindingId,
    setPassToModalGroupBindingId,
  ] = useState<string>("");
  const [sortBy, setSortBy] = useState<SortValue | undefined>({
    field: GroupBindingsSortByField.SourceGroupName,
    direction: SortDirection.Asc,
  });

  const { data, error, loading, fetchMore } = useGroupBindingsTableQuery({
    variables: {
      input: {
        filters: {
          connectionIds: connectionIds.length > 0 ? connectionIds : undefined,
          groupIds: groupIds.length > 0 ? groupIds : undefined,
          groupTypes:
            groupTypes.length > 0
              ? groupTypes.map((t) => t as GroupType)
              : undefined,
          searchQuery: searchQuery.length > 0 ? searchQuery : undefined,
        },
        pageSize: BINDINGS_PER_PAGE,
        sortBy,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    logError(error, `failed to list group bindings`);
    return <UnexpectedErrorPage error={error} />;
  }

  const { groupBindings, totalNumItems, cursor } = data?.groupBindings || {};

  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            input: {
              cursor,
              pageSize: BINDINGS_PER_PAGE,
              sortBy,
              filters: {
                connectionIds:
                  connectionIds.length > 0 ? connectionIds : undefined,
                groupIds: groupIds.length > 0 ? groupIds : undefined,
                searchQuery: searchQuery.length > 0 ? searchQuery : undefined,
                groupTypes: groupTypes.length > 0 ? groupTypes : undefined,
              },
            },
          },
        });
      }
    : undefined;

  const rows =
    groupBindings
      ?.filter((binding) => Boolean(binding))
      .map((binding) => {
        return {
          id: binding.id || "",
          [NAME_COL_ID]: binding.sourceGroup?.name || "",
          [SOURCE_OF_TRUTH_COL_ID]:
            binding.sourceGroup?.name || binding.sourceGroup?.groupType || "",
          [CREATED_AT_COL_ID]: binding.createdAt || "",
          [CREATED_BY_COL_ID]: binding.createdByUser?.fullName || "",
          data: binding,
        };
      }) ?? [];

  const columns: Header<BindingRow>[] = [
    {
      id: NAME_COL_ID,
      label: "Groups",
      width: 400,
      customCellRenderer: (row: BindingRow) => {
        return (
          <GroupBindingGroupsRow
            sourceGroup={row.data.sourceGroup ?? undefined}
            groups={row.data.groups}
          />
        );
      },
    },
    {
      id: SOURCE_OF_TRUTH_COL_ID,
      label: "Source of truth",
      width: 200,
      customCellRenderer: (row: BindingRow) => {
        const { sourceGroup: group } = row.data;
        return <GroupBindingGroupLabel group={group ?? undefined} />;
      },
    },
    {
      id: CREATED_AT_COL_ID,
      label: "Linked on",
      width: 120,
      customCellRenderer: (row: BindingRow) => {
        const time = moment(new Date(row.data.createdAt));
        return <Label label={time.fromNow()} />;
      },
    },
    {
      id: CREATED_BY_COL_ID,
      label: "Linked by",
      width: 120,
      customCellRenderer: (row: BindingRow) => {
        const user = row.data.createdByUser;
        return (
          <Label
            linkTo={`/users/${user?.id}`}
            label={user?.fullName ?? "Deleted user"}
          />
        );
      },
    },
    {
      id: "actions",
      label: "",
      width: 100,
      customCellRenderer: (row: BindingRow) => {
        return (
          <div
            className={sprinkles({
              display: "flex",
              flexDirection: "row",
              gap: "md",
              justifyContent: "flex-end",
            })}
          >
            <Button
              leftIconName="edit"
              tooltip="Edit"
              borderless
              onClick={() => {
                setPassToModalGroupBindingId(row.id);
                setShowEditModal(true);
              }}
            />
            <Button
              leftIconName="link-broken"
              tooltip="Unlink"
              type="error"
              borderless
              onClick={() => {
                setPassToModalGroupBindingId(row.id);
                setShowDeleteModal(true);
              }}
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <TableHeader
        entityType={EntityType.GroupBinding}
        selectedNumRows={selectedGroupBindingIds.length}
        totalNumRows={totalNumItems ?? 0}
        bulkRightActions={[
          {
            label: "Unlink",
            type: "danger",
            onClick: () => setShowDeleteModal(true),
            iconName: "link-broken",
          },
          {
            label: "Edit",
            type: "main",
            onClick: () => setShowEditModal(true),
            iconName: "edit",
          },
        ]}
      />
      <Table
        checkedRowIds={new Set(selectedGroupBindingIds)}
        onCheckedRowsChange={(ids, checked) => {
          if (checked) {
            setSelectedGroupBindingIds(
              _.uniq([...selectedGroupBindingIds, ...ids])
            );
          } else {
            setSelectedGroupBindingIds(
              selectedGroupBindingIds.filter((id) => !ids.includes(id))
            );
          }
        }}
        selectAllChecked={rows.length === selectedGroupBindingIds.length}
        onSelectAll={(checked) => {
          if (checked) {
            setSelectedGroupBindingIds(rows.map((row) => row.id));
          } else {
            setSelectedGroupBindingIds([]);
          }
        }}
        rows={rows}
        columns={columns}
        loadingRows={loading}
        totalNumRows={totalNumItems ?? 0}
        getRowId={(row) => row.id}
        emptyState={{
          title: "No linked groups",
          subtitle: "There are no group bindings to display.",
        }}
        onLoadMoreRows={loadMoreRows}
        manualSortDirection={
          sortBy && {
            sortBy: sortBy.field,
            sortDirection: sortBy.direction,
          }
        }
        handleManualSort={(sortBy, sortDirection) => {
          if (!sortDirection) {
            setSortBy(undefined);
            return;
          }
          if (!isSortableField(sortBy)) {
            return;
          }
          const direction: SortDirection =
            sortDirection === "DESC" ? SortDirection.Desc : SortDirection.Asc;

          setSortBy({
            field: sortBy,
            direction,
          });
        }}
      />
      {showDeleteModal && (
        <GroupBindingDeleteModal
          isOpen={showDeleteModal}
          onModalClose={() => {
            setShowDeleteModal(false);
            setSelectedGroupBindingIds([]);
            setPassToModalGroupBindingId("");
          }}
          groupBindingIds={
            passToModalGroupBindingId.length > 0
              ? [passToModalGroupBindingId]
              : selectedGroupBindingIds
          }
        />
      )}
      {showEditModal && (
        <GroupBindingEditModal
          isOpen={showEditModal}
          onModalClose={() => {
            setShowEditModal(false);
            setSelectedGroupBindingIds([]);
            setPassToModalGroupBindingId("");
          }}
          groupBindingIds={
            passToModalGroupBindingId.length > 0
              ? [passToModalGroupBindingId]
              : selectedGroupBindingIds
          }
        />
      )}
    </>
  );
};

export default GroupBindingsTable;
