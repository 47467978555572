import {
  EventStreamFragment,
  WebhookPubsubPublishConnectionAuthType,
} from "api/generated/graphql";
import { PillV3 } from "components/pills/PillsV3";
import { ButtonV3, Label } from "components/ui";
import _ from "lodash";
import pluralize from "pluralize";
import React, { useState } from "react";

import * as styles from "./EventStreamConnection.css";
import { EditEventStreamConnectionModal } from "./EventStreamConnectionModals";

type EventStreamConnectionProps = {
  eventStream: EventStreamFragment;
};

export const EventStreamConnection = (props: EventStreamConnectionProps) => {
  const [
    connectionToEdit,
    setConnectionToEdit,
  ] = useState<EventStreamFragment>();

  const eventStream = props.eventStream;

  const getAuthType = (authType: WebhookPubsubPublishConnectionAuthType) => {
    switch (authType) {
      case WebhookPubsubPublishConnectionAuthType.None:
        return "None";
      case WebhookPubsubPublishConnectionAuthType.ApiKey:
        return "API Key";
      case WebhookPubsubPublishConnectionAuthType.Hmac:
        return "HMAC";
      default:
        return "Unknown";
    }
  };

  return (
    <>
      <div className={styles.container} key={eventStream.id}>
        <div className={styles.cardHeader}>
          <div className={styles.cardTitle}>
            {eventStream.connection.name}
            <div className={styles.cardPill}>
              <PillV3
                key={eventStream.id}
                keyText={_.capitalize(eventStream.connection.connectionType)}
                pillColor="Gray"
              />
            </div>
          </div>
          <div className={styles.editButton}>
            <ButtonV3
              leftIconName="edit"
              onClick={() => setConnectionToEdit(eventStream)}
              size="sm"
              type="defaultSecondary"
            />
          </div>
        </div>
        <div className={styles.lowerContent}>
          <div className={styles.lowerContentLeft}>
            <ConnectionMetadata
              header="Status"
              content={
                <>
                  <span
                    className={styles.status({
                      notActive: !eventStream.connection.enabled,
                    })}
                  ></span>
                  <Label
                    label={
                      eventStream.connection.enabled ? "Enabled" : "Disabled"
                    }
                    iconSize="xxs"
                  />
                </>
              }
              width="sm"
            />
            <ConnectionMetadata
              header="URL"
              content={
                <Label
                  label={eventStream.connection.metadata.url}
                  oneLine
                  truncateLength={30}
                />
              }
              width="md"
            />
            <ConnectionMetadata
              header="Authorization Scheme"
              content={
                <Label
                  label={getAuthType(
                    eventStream.connection.credentials.authType
                  )}
                />
              }
              width="sm"
            />
          </div>
          {eventStream.connection.failedDeliveryCount &&
          eventStream.connection.failedDeliveryCount > 0 ? (
            <div className={styles.lowerContentRight}>
              <ConnectionMetadata
                content={
                  <Label
                    label={`${pluralize(
                      "failed delivery",
                      eventStream.connection.failedDeliveryCount,
                      true
                    )} (last 30 days)`}
                    color="red600V3"
                    icon={{ type: "name", icon: "alert-circle" }}
                  />
                }
              />
            </div>
          ) : null}
        </div>
      </div>
      {connectionToEdit && (
        <EditEventStreamConnectionModal
          eventStream={connectionToEdit}
          showModal={Boolean(connectionToEdit)}
          onSubmit={() => setConnectionToEdit(undefined)}
          onCancel={() => setConnectionToEdit(undefined)}
        ></EditEventStreamConnectionModal>
      )}
    </>
  );
};

type ConnectionMetadataProps = {
  header?: string;
  content: JSX.Element;
  width?: "sm" | "md";
};

const ConnectionMetadata = (props: ConnectionMetadataProps) => {
  return (
    <div className={styles.lowerContentCell({ width: props.width })}>
      <div className={styles.lowerContentCellHeader}>{props.header}</div>
      <div className={styles.lowerContentCellContent}>{props.content}</div>
    </div>
  );
};
