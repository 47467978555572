import ConfigurationTemplateDropdown from "components/configuration_templates/ConfigurationTemplateDropdown";
import { FormGroup } from "components/ui";
import { useContext } from "react";
import { AppsContext } from "views/apps/AppsContext";

import { ResourceConfig } from "../common";

interface Props {
  configurationTemplate?: ResourceConfig["configurationTemplate"];
  onChange: (configurationTemplate?: { name: string; id: string }) => void;
  clearable?: boolean;
  placeholder?: string;
}

const ChildrenDefaultConfigTemplateRow = (props: Props) => {
  const { bulkMode } = useContext(AppsContext);

  const content = (
    <ConfigurationTemplateDropdown
      selectedConfigurationTemplateId={props.configurationTemplate?.id}
      onSelectConfigurationTemplate={props.onChange}
      clearable={true}
      placeholder={
        props.placeholder ?? (bulkMode === "edit" ? "Leave unchanged" : "None")
      }
    />
  );

  return (
    <FormGroup label="Auto-Import Configuration Template">
      <p>
        Children imported through sync auto-import will be assigned this
        configuration by default.
      </p>
      {content}
    </FormGroup>
  );
};

export default ChildrenDefaultConfigTemplateRow;
