import { useAppRowQuery } from "api/generated/graphql";
import { FormRow, Label, Skeleton } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { getAppIcon } from "views/apps/utils";

interface Props {
  connectionId: string;
  mode: "view" | "edit";
}

const AppRow = ({ connectionId, mode }: Props) => {
  const disabled = mode === "edit";
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  const { data, loading, error } = useAppRowQuery({
    variables: { connectionId },
  });

  let connection = null;
  let connectionNotFound = false;
  switch (data?.connection.__typename) {
    case "ConnectionResult":
      connection = data.connection.connection;
      break;
    case "ConnectionNotFoundError":
      connectionNotFound = true;
      break;
  }

  if (error) {
    return (
      <FormRow title="App" disabled={disabled}>
        Failed to get app info
      </FormRow>
    );
  }

  if (connectionNotFound) {
    return (
      <FormRow title="App" disabled={disabled}>
        <span className={sprinkles({ color: "gray500", fontStyle: "italic" })}>
          You do not have access to see this data
        </span>
      </FormRow>
    );
  }

  return (
    <FormRow title="App" disabled={disabled}>
      {loading || !connection ? (
        <Skeleton width="120px" />
      ) : (
        <Label
          label={connection.name}
          icon={getAppIcon({
            __typename: "ConnectionApp",
            connectionType: connection.connectionType,
            connectionIconUrl: connection.iconUrl,
          })}
          linkTo={
            hasV3 ? `/apps/${connectionId}/` : `/apps/${connectionId}/overview`
          }
        />
      )}
    </FormRow>
  );
};

export default AppRow;
