import { getModifiedErrorMessage } from "api/ApiContext";
import {
  EntityType,
  GroupType,
  PropagationStatusCode,
  RemoveGroupUserInput,
  useRemoveGroupUsersMutation,
  UserGroupFragment,
  UserOverviewFragment,
  useUserGroupsQuery,
} from "api/generated/graphql";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import { ResourceLabel, TimeLabel } from "components/label/Label";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import PropagationStatusLabelWithModal from "components/propagation/PropagationStatusLabelWithModal";
import { Checkbox, EntityIcon, Input, Modal } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableFilters from "components/ui/table/TableFilters";
import TableHeader from "components/ui/table/TableHeader";
import { compareAccessPaths } from "components/ui/table/utils";
import sprinkles from "css/sprinkles.css";
import { Maybe } from "graphql/jsutils/Maybe";
import moment from "moment";
import pluralize from "pluralize";
import { useState } from "react";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { PropagationType } from "utils/useRemediations";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ViewSkeleton from "views/loading/ViewSkeleton";
import {
  getGroupUserAccessPathsInfo,
  GroupUserAccessPathsInfo,
  GroupUserAccessPointsLabel,
} from "views/resources/GroupUserAccessPointsLabel";
import { dropNothings } from "views/utils";

import * as styles from "./UserGroupsTableV3.css";

interface UserGroupRow {
  id: string;
  userGroup: UserGroupFragment;
  groupName?: string;
  groupType: GroupType;
  role: string;
  accessPaths: GroupUserAccessPathsInfo;
  expiry?: string | null;
  syncStatus?: PropagationStatusCode | null;
  isUnmanaged?: boolean;
}

type UseGroupsTableV3Props = {
  user: UserOverviewFragment;
  canEdit: boolean;
};

export const UserGroupsTableV3 = (props: UseGroupsTableV3Props) => {
  const startPushTaskPoll = usePushTaskLoader();
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [removeGroupsErrorMessage, setRemoveGroupsErrorMessage] = useState<
    Maybe<string>
  >(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showUnmanagedResources, setShowUnmanagedResources] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 200);

  const transitionTo = useTransitionTo();

  const [
    removeGroupUsers,
    { loading: removeUsersLoading },
  ] = useRemoveGroupUsersMutation();

  const { data, previousData, loading, error } = useUserGroupsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      id: props.user.id,
    },
  });

  if (loading && !data && !previousData) {
    return <ViewSkeleton />;
  }

  if (error) {
    return <UnexpectedErrorPage error={error} />;
  }

  let userGroups: UserGroupFragment[] = [];
  if (previousData?.user.__typename === "UserResult") {
    userGroups = previousData.user.user.userGroups;
  }
  if (data?.user.__typename === "UserResult") {
    userGroups = data.user.user.userGroups;
  }

  const submitRemoval = async (groupUsersToRemove: RemoveGroupUserInput[]) => {
    try {
      const { data } = await removeGroupUsers({
        variables: {
          input: {
            groupUsers: groupUsersToRemove,
          },
        },
      });
      switch (data?.removeGroupUsers.__typename) {
        case "RemoveGroupUsersResult":
          startPushTaskPoll(data.removeGroupUsers.taskId, {
            refetchOnComplete: [{ userId: props.user.id }],
          });
          setShowRemoveModal(false);
          setSelectedItemIds([]);
          break;
        default:
          logError(
            new Error(`failed to remove user access to the selected groups`)
          );
          setRemoveGroupsErrorMessage(
            "Error: failed to remove user access to the selected groups"
          );
      }
    } catch (error) {
      logError(error, "failed to remove user access to the selected groups");
      setRemoveGroupsErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to remove user access to the selected groups",
          error
        )
      );
    }
  };

  const GROUP_USER_COLUMNS: Header<UserGroupRow>[] = [
    {
      id: "groupName",
      label: "Name",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <ResourceLabel
            text={row.groupName}
            pointerCursor={true}
            groupType={row.groupType}
            entityId={row.userGroup.groupId}
            entityTypeNew={EntityType.Group}
            hideIcon
          />
        );
      },
      width: 130,
    },
    {
      id: "groupType",
      label: "Type",
      customCellRenderer: (row) => {
        return (
          <span
            className={sprinkles({
              display: "flex",
              gap: "sm",
              alignItems: "center",
            })}
          >
            <EntityIcon type={row.groupType} />
            {groupTypeInfoByType[row.groupType].name}
          </span>
        );
      },
      width: 130,
    },
    {
      id: "role",
      label: "Role",
    },
    {
      id: "accessPaths",
      label: "Source Of Access",
      customCellRenderer: (row) => {
        return (
          <GroupUserAccessPointsLabel
            user={props.user}
            group={row.userGroup.group}
            accessPathsInfo={getGroupUserAccessPathsInfo({
              user: props.user,
              access: row.userGroup.access,
              group: row.userGroup.group,
            })}
          />
        );
      },
      sortingFn: (rowA, rowB): number => {
        return compareAccessPaths(
          rowA.getValue("accessPaths"),
          rowB.getValue("accessPaths")
        );
      },
      width: 110,
    },
    {
      id: "expiry",
      label: "Expires",
      customCellRenderer: (row) => {
        const expirationTime = row.userGroup.access?.latestExpiringAccessPoint
          ?.expiration
          ? moment(
              new Date(
                row.userGroup.access.latestExpiringAccessPoint.expiration
              )
            )
          : null;
        return (
          <TimeLabel
            time={expirationTime}
            supportTicket={
              row.userGroup.access?.latestExpiringAccessPoint.supportTicket
            }
            useExpiringLabel
          />
        );
      },
      width: 125,
    },
    {
      id: "syncStatus",
      label: "Status",
      customCellRenderer: (row) => {
        return (
          <PropagationStatusLabelWithModal
            propagationType={PropagationType.GroupUser}
            propagationStatus={row.userGroup.propagationStatus}
            isAccessReview={false}
            entityInfo={{
              roleAssignmentKey: props.user.id + row.userGroup.groupId,
              user: props.user,
              group: row.userGroup.group,
              lastExpiringAccessPointExpiration:
                row.userGroup.access?.latestExpiringAccessPoint.expiration,
            }}
          />
        );
      },
      width: 50,
    },
  ];

  if (!showUnmanagedResources) {
    userGroups = userGroups.filter((userGroups) => userGroups.group?.isManaged);
  }

  const filteredUserGroups = userGroups.filter((userGroup) => {
    if (debouncedSearchQuery === "") return true;
    if (!userGroup.group) return false;
    return userGroup.group.name
      .toLowerCase()
      .includes(debouncedSearchQuery.toLowerCase());
  });
  const rowsById: Record<string, UserGroupRow> = {};
  const rows: UserGroupRow[] = filteredUserGroups.flatMap((userGroup) => {
    if (!userGroup.group) return [];
    const row = {
      id: userGroup.groupId,
      userGroup: userGroup,
      groupName: userGroup.group.name,
      groupType: userGroup.group.groupType,
      role:
        userGroup.access?.directAccessPoint?.accessLevel?.accessLevelName ||
        "\u2014",
      accessPaths: getGroupUserAccessPathsInfo({
        user: props.user,
        group: userGroup.group,
        access: userGroup.access,
      }),
      expiry: userGroup.access?.latestExpiringAccessPoint.expiration,
      syncStatus: userGroup.propagationStatus?.statusCode,
      isUnmanaged: !userGroup.group.isManaged,
    };
    rowsById[row.id] = row;
    return [row];
  });

  const getCheckboxDisabledReason = (row: UserGroupRow) => {
    if (!row.userGroup.group?.isManaged) {
      return "Unmanaged groups cannot be removed";
    } else if (!row.userGroup.access?.directAccessPoint) {
      return "The user has no direct access to remove";
    }
    return undefined;
  };

  const bulkRightActions: PropsFor<
    typeof TableHeader
  >["bulkRightActions"] = dropNothings([
    props.canEdit
      ? {
          label: "Remove",
          type: "danger",
          onClick: () => setShowRemoveModal(true),
          iconName: "trash",
        }
      : null,
  ]);

  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        height: "100%",
      })}
    >
      <TableFilters>
        <TableFilters.Left>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Filter resources by name"
            />
          </div>
          <Checkbox
            size="md"
            label="Show unmanaged resources"
            checked={showUnmanagedResources}
            onChange={setShowUnmanagedResources}
          />
        </TableFilters.Left>
      </TableFilters>
      <TableHeader
        entityName={"Group Access Points"}
        selectedNumRows={selectedItemIds.length}
        totalNumRows={rows.length}
        defaultRightActions={
          props.canEdit
            ? [
                {
                  label: "Add Groups",
                  type: "mainSecondary",
                  onClick: () => {
                    transitionTo({
                      pathname: `/users/${props.user.id}/add-groups`,
                    });
                  },
                  iconName: "plus",
                },
              ]
            : []
        }
        bulkRightActions={bulkRightActions}
      />
      <Table
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        columns={GROUP_USER_COLUMNS}
        defaultSortBy="groupName"
        checkedRowIds={new Set(selectedItemIds)}
        onCheckedRowsChange={
          props.canEdit
            ? (checkedRowIds, checked) => {
                if (checked) {
                  setSelectedItemIds((prev) => [...prev, ...checkedRowIds]);
                  return;
                } else {
                  setSelectedItemIds((prev) =>
                    prev.filter((id) => !checkedRowIds.includes(id))
                  );
                }
              }
            : undefined
        }
        selectAllChecked={
          selectedItemIds.length > 0 &&
          selectedItemIds.length ===
            rows.filter((row) => !getCheckboxDisabledReason(row)).length
        }
        onSelectAll={(checked) =>
          checked
            ? setSelectedItemIds(
                rows
                  .filter((row) => !getCheckboxDisabledReason(row))
                  .map((row) => row.id)
              )
            : setSelectedItemIds([])
        }
        getCheckboxDisabledReason={getCheckboxDisabledReason}
      />
      {showRemoveModal && (
        <Modal
          isOpen
          title="Remove User Resources"
          onClose={() => setShowRemoveModal(false)}
        >
          <Modal.Body>
            {removeGroupsErrorMessage && (
              <ModalErrorMessage errorMessage={removeGroupsErrorMessage} />
            )}
            Are you sure you want to remove{" "}
            {pluralize("direct role assignments", selectedItemIds.length, true)}{" "}
            from this user?
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Remove"
            primaryButtonDisabled={selectedItemIds.length === 0}
            primaryButtonLoading={removeUsersLoading}
            onPrimaryButtonClick={() => {
              const userGroupsToRemove: RemoveGroupUserInput[] = [];
              for (const rowId of selectedItemIds) {
                const row = rowsById[rowId];
                userGroupsToRemove.push({
                  groupId: row.userGroup.groupId,
                  userId: props.user.id,
                });
              }
              submitRemoval(userGroupsToRemove);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default UserGroupsTableV3;
