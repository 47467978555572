import { BundleItemFragment } from "api/generated/graphql";

type BundleResource = BundleItemFragment & {
  resource: {
    id: string;
  };
};

type BundleGroup = BundleItemFragment & {
  group: {
    id: string;
  };
};

export const bundleItemIsResource = (
  item: BundleItemFragment
): item is BundleResource => {
  return item.resource !== null;
};

export const bundleItemIsGroup = (
  item: BundleItemFragment
): item is BundleGroup => {
  return item.group !== null;
};
