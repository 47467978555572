import { EntityOptionData } from "components/dropdown/PaginatedEntityDropdown";
import { Label } from "components/ui";
import sprinkles from "css/sprinkles.css";

import * as styles from "./EntityOptionLabel.css";

const EntityOptionLabel: React.FC<EntityOptionData> = (item) => {
  return (
    <div className={styles.container}>
      <div className={sprinkles({ display: "flex", flexDirection: "row" })}>
        <span className={styles.name}>{item.label}</span>
        <div className={styles.itemType}>
          <Label
            label={item.typeLabel ?? ""}
            entityType={item.type}
            inline
            icon={item.icon}
            iconSize="xs"
            color="gray700"
          />
        </div>
      </div>
      <div>
        {item.breadcrumb && (
          <div className={styles.breadcrumb}>{item.breadcrumb}</div>
        )}
        {item.description && (
          <div className={styles.description}>{item.description}</div>
        )}
      </div>
    </div>
  );
};

export default EntityOptionLabel;
