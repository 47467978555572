import {
  AddResourceUserInput,
  ResourceAccessLevel,
  ResourcePreviewSmallFragment,
  useAddResourceUsersMutation,
  useMultipleResourceAccessLevelsQuery,
  useResourcePreviewLazyQuery,
  UserPreviewSmallFragment,
  UserResourceFragment,
} from "api/generated/graphql";
import ResourceSearchDropdown, {
  mergeResourceSelectData,
  removeResourceSelectData,
} from "components/dropdown/ResourceSearchDropdown";
import * as styles from "components/modals/AddItemModal.css";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import ResourceRoleRow from "components/modals/ResourceRoleRow";
import { Modal, Skeleton } from "components/ui";
import _ from "lodash";
import { useState } from "react";
import useLogEvent from "utils/analytics";
import { resourceRequiresAtLeastOneRole } from "utils/directory/resources";
import { logError } from "utils/logging";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
} from "views/requests/utils";

interface Props {
  user: UserPreviewSmallFragment;
  userResources: UserResourceFragment[];
  onClose: () => void;
}

const UserAddResourcesModal = (props: Props) => {
  const [roleByResourceIdToAdd, setRoleByResourceIdToAdd] = useState<
    Record<string, ResourceAccessLevel[]>
  >({});
  const [
    expirationValueByResourceIdToAdd,
    setExpirationValueByResourceIdToAdd,
  ] = useState<Record<string, ExpirationValue>>({});
  const [errorMessage, setErrorMessage] = useState("");
  const [rowErrors, setRowErrors] = useState<Record<string, string>>({}); // key is resource id

  const logEvent = useLogEvent();
  const startPushTaskPoll = usePushTaskLoader();

  const [addResourceUsers, { loading }] = useAddResourceUsersMutation();

  const existingRolesByResourceId: Record<string, ResourceAccessLevel[]> = {};
  props.userResources.forEach((resourceUser) => {
    if (!existingRolesByResourceId[resourceUser.resourceId]) {
      existingRolesByResourceId[resourceUser.resourceId] = [];
    }
    if (resourceUser.access?.directAccessPoint?.accessLevel) {
      existingRolesByResourceId[resourceUser.resourceId] = _.uniqBy(
        [
          ...existingRolesByResourceId[resourceUser.resourceId],
          resourceUser.access?.directAccessPoint?.accessLevel,
        ],
        "accessLevelRemoteId"
      );
    }
  });

  const [getResourceInfo] = useResourcePreviewLazyQuery();
  const {
    data,
    previousData,
    error: rolesError,
    loading: loadingResourceRoles,
  } = useMultipleResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceIds: Object.keys(existingRolesByResourceId),
      },
    },
  });

  if (loadingResourceRoles && !data && !previousData) {
    return (
      <Modal title="Add User to Resources" isOpen onClose={props.onClose}>
        <Modal.Body>
          <Skeleton height="48px" />
        </Modal.Body>
      </Modal>
    );
  }

  if (rolesError) {
    return (
      <Modal title="Add User to Resources" isOpen onClose={props.onClose}>
        <Modal.Body>
          <ModalErrorMessage errorMessage={rolesError.message} />
        </Modal.Body>
      </Modal>
    );
  }

  let allRoles;
  if (
    previousData?.multipleAccessLevels.__typename ===
    "MultipleResourceAccessLevelsResult"
  ) {
    allRoles = previousData.multipleAccessLevels.results;
  }
  switch (data?.multipleAccessLevels.__typename) {
    case "MultipleResourceAccessLevelsResult":
      allRoles = data.multipleAccessLevels.results;
  }

  const allRolesByResourceId: Record<string, ResourceAccessLevel[]> = {};
  allRoles?.forEach((role) => {
    allRolesByResourceId[role.resourceId] = role.accessLevels;
  });

  const handleSubmit = async () => {
    logEvent({
      name: "apps_add_user_to_resources",
      properties: {
        numResourcesAddedTo: Object.entries(roleByResourceIdToAdd).length,
      },
    });
    try {
      const resourceInputs: AddResourceUserInput[] = [];
      const newRowErrors: Record<string, string> = {};
      for (const [resourceId, roles] of Object.entries(roleByResourceIdToAdd)) {
        const { data } = await getResourceInfo({
          variables: {
            input: {
              id: resourceId,
            },
          },
        });
        let resource: ResourcePreviewSmallFragment | undefined;
        switch (data?.resource.__typename) {
          case "ResourceResult":
            resource = data.resource.resource;
            break;
        }
        if (!resource) {
          setErrorMessage("Failed to add user to resources");
          return;
        }

        if (roles.length === 0) {
          // If resource requires a role, but none are selected,
          // show an error.
          if (resourceRequiresAtLeastOneRole(resource)) {
            newRowErrors[resourceId] = "Please select at least one role";
          } else {
            // If resource does not require roles,
            // add an empty role to add the resource directly.
            resourceInputs.push({
              userId: props.user.id,
              resourceId,
              accessLevel: {
                accessLevelName: "",
                accessLevelRemoteId: "",
              },
              durationInMinutes: expirationValueToDurationInMinutes(
                expirationValueByResourceIdToAdd[resourceId] ??
                  ExpirationValue.Indefinite
              )?.asMinutes(),
            });
          }
        }

        roles.forEach((role) => {
          resourceInputs.push({
            userId: props.user.id,
            resourceId,
            accessLevel: {
              accessLevelName: role.accessLevelName,
              accessLevelRemoteId: role.accessLevelRemoteId,
            },
            durationInMinutes: expirationValueToDurationInMinutes(
              expirationValueByResourceIdToAdd[resourceId] ??
                ExpirationValue.Indefinite
            )?.asMinutes(),
          });
        });
      }

      if (Object.keys(newRowErrors).length > 0) {
        setRowErrors(newRowErrors);
        return;
      }

      const { data } = await addResourceUsers({
        variables: {
          input: {
            resourceUsers: resourceInputs,
          },
        },
        refetchQueries: ["UserResources"],
        awaitRefetchQueries: true,
      });
      switch (data?.addResourceUsers.__typename) {
        case "AddResourceUsersResult":
          startPushTaskPoll(data.addResourceUsers.taskId);
          props.onClose();
          break;
        case "OpalGlobalImpersonationResourceDirectAddNotAllowedError":
          logError(new Error(data.addResourceUsers.message));
          setErrorMessage(data.addResourceUsers.message);
          break;
        case "ResourceUserAlreadyExists":
          setErrorMessage(data.addResourceUsers.message);
          break;
        default:
          logError(new Error(`failed to add user to resources`));
          setErrorMessage("Error: failed to add user to resources");
      }
    } catch (err) {
      logError(err, "Failed to add user to resources");
      setErrorMessage("Failed to add user to resources");
    }
  };

  const disabledResourceIds: string[] = [];
  Object.keys(existingRolesByResourceId).forEach((resourceId) => {
    const allRolesAdded =
      allRolesByResourceId[resourceId]?.length ===
      existingRolesByResourceId[resourceId]?.length;
    if (allRolesAdded) {
      disabledResourceIds.push(resourceId);
    }
  });

  return (
    <Modal isOpen title="Add User to Resources" onClose={props.onClose}>
      <Modal.Body>
        {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
        <ResourceSearchDropdown
          style="search"
          selectedResourceIds={Object.keys(roleByResourceIdToAdd)}
          onSelect={({ actionType, resources }) => {
            setRoleByResourceIdToAdd((prev) => {
              if (actionType === "select-option") {
                return mergeResourceSelectData(prev, resources);
              } else {
                return removeResourceSelectData(prev, resources);
              }
            });
          }}
          disabledResourceIds={disabledResourceIds}
        />
        <div className={styles.itemsContainer}>
          {Object.keys(roleByResourceIdToAdd).map((resourceId) => {
            return (
              <ResourceRoleRow
                key={resourceId}
                resourceId={resourceId}
                existingRoles={existingRolesByResourceId[resourceId] ?? []}
                roles={roleByResourceIdToAdd[resourceId] ?? []}
                error={rowErrors[resourceId]}
                onResourceRemove={() => {
                  setRoleByResourceIdToAdd((prev) => {
                    delete prev[resourceId];
                    return { ...prev };
                  });
                }}
                onRoleSelect={(role) => {
                  setRoleByResourceIdToAdd((prev) => {
                    if (resourceId in prev) {
                      return {
                        ...prev,
                        [resourceId]: [...prev[resourceId], role],
                      };
                    } else {
                      return {
                        ...prev,
                        [resourceId]: [role],
                      };
                    }
                  });
                }}
                onRoleRemove={(role) => {
                  setRoleByResourceIdToAdd((prev) => {
                    return {
                      ...prev,
                      [resourceId]: prev[resourceId].filter(
                        (a) =>
                          a.accessLevelRemoteId !== role.accessLevelRemoteId
                      ),
                    };
                  });
                }}
                expirationValue={
                  expirationValueByResourceIdToAdd[resourceId] ??
                  ExpirationValue.Indefinite
                }
                onExpirationValueChange={(value) => {
                  setExpirationValueByResourceIdToAdd((prev) => {
                    return {
                      ...prev,
                      [resourceId]: value,
                    };
                  });
                }}
              />
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Add Resources"
        primaryButtonDisabled={Object.keys(roleByResourceIdToAdd).length === 0}
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLoading={loading}
      />
    </Modal>
  );
};

export default UserAddResourcesModal;
