import { Maybe } from "api/generated/graphql";
import { ErrorMessageLightLabel } from "components/label/MessageLabel";
import { Input, Modal } from "components/ui";
import React, { useState } from "react";

export type AccessReviewNoteModalProps = {
  initNote: Maybe<string>;
  isOpen: boolean;
  primaryButtonLoading?: boolean;
  errMsg: string;
  onClose: () => void;
  onSubmit: (note: Maybe<string>) => void;
};

const AccessReviewNoteModal = (props: AccessReviewNoteModalProps) => {
  const [note, setNote] = useState<Maybe<string>>(props.initNote || null);

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={`${props.initNote ? "Edit" : "Add"} Note`}
    >
      <Modal.Body>
        <p>
          To include a note with your review of this access item, enter a
          message below:
        </p>
        <Input value={note || ""} onChange={setNote} type="textarea">
          {note}
        </Input>
        {props.errMsg && <ErrorMessageLightLabel errorMessage={props.errMsg} />}
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Save"
        onPrimaryButtonClick={() => props.onSubmit(note)}
        primaryButtonLoading={props.primaryButtonLoading}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={props.onClose}
      />
    </Modal>
  );
};

export default AccessReviewNoteModal;
