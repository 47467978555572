import { IdpConnectionFragment, Maybe } from "api/generated/graphql";
import clsx from "clsx";
import { AppTile } from "components/integrations/AppTile";
import {
  getLogoByIdpConnectionType,
  getNameByIdpConnectionType,
} from "components/label/Label";
import IdpAttributesModal from "components/modals/update/IdpAttributesModal";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import React, { useState } from "react";
import integrationsStyles from "views/settings/OrgIntegrations.module.scss";
import { IdpAuthorizer } from "views/settings/third_party_authorizer/IdpAuthorizer";

export type IdpHrIntegrationAppProps = {
  idpConnection: Maybe<IdpConnectionFragment>;
};

export const IdpHrIntegrationApp = (props: IdpHrIntegrationAppProps) => {
  const { idpConnection } = props;

  const [showModal, setShowModal] = useState(false);
  const isConnected = !!idpConnection;

  let connectionStatusText = isConnected ? "Connected" : "No connection";
  if (isConnected) {
    connectionStatusText = `${connectionStatusText}`;
  }

  return (
    <div>
      <div className={integrationsStyles.appInfo}>
        <div className={integrationsStyles.leftContent}>
          <AppTile
            logo={getLogoByIdpConnectionType(idpConnection?.idpConnectionType)}
          />
          <div className={integrationsStyles.text}>
            <div className={integrationsStyles.appName}>
              {getNameByIdpConnectionType(idpConnection?.idpConnectionType) ||
                "Identity Provider / HR Information System"}
            </div>
            <div className={sprinkles({ display: "flex" })}>
              <div className={integrationsStyles.subtitle}>
                <div
                  className={clsx({
                    [integrationsStyles.subtitleStatus]: true,
                    [integrationsStyles.statusDisconnected]: !isConnected,
                    [integrationsStyles.statusConnected]: isConnected,
                  })}
                />
                <div className={integrationsStyles.subtitleText}>
                  {connectionStatusText}
                </div>
              </div>
              {isConnected && (
                <div className={integrationsStyles.attributesLink}>
                  <div
                    className={integrationsStyles.subtitleText}
                    onClick={() => {
                      setShowModal(true);
                    }}
                  >
                    {`Importing ${pluralize(
                      "custom user attribute",
                      idpConnection?.userAttributeImportMappings.length || 0,
                      true
                    )}`}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={integrationsStyles.rightContent}>
          <IdpAuthorizer
            idpConnection={idpConnection}
            isConnected={isConnected}
          />
        </div>
      </div>
      {idpConnection && (
        <IdpAttributesModal
          idpConnection={idpConnection}
          isModalOpen={showModal}
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}
    </div>
  );
};
