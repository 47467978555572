import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AuthType,
  ConnectionType,
  Maybe,
  useCreateConnectionMutation,
  Visibility,
} from "api/generated/graphql";
import mariadbLogo from "assets/logos/mariadb-logo.png";
import mongoLogo from "assets/logos/mongodb-logo.png";
import mysqlLogo from "assets/logos/mysql-logo.png";
import postgresLogo from "assets/logos/postgres-logo.svg";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { Button, Checkbox, FormGroup, Input } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import { validateHostname } from "views/connections/create/common";
import {
  CreateConnectionComponents as CreateConnectionComponentsV2,
  CreateConnectionView as CreateConnectionViewV2,
} from "views/connections/create/CreateConnectionComponents";
import {
  CreateConnectionComponentsV3,
  CreateConnectionViewV3,
} from "views/connections/create/CreateConnectionComponentsV3";
import { UploadCertButton } from "views/connections/create/UploadCertButton";
import VisibilitySelector from "views/visibility/VisibilitySelector";

export const CreateMongo = () => {
  return <CreateDatabaseForm variant={ConnectionType.Mongo} />;
};

export const CreateMySql = () => {
  return <CreateDatabaseForm variant={ConnectionType.Mysql} />;
};

export const CreateMariaDB = () => {
  return <CreateDatabaseForm variant={ConnectionType.Mariadb} />;
};

export const CreatePostgres = () => {
  return <CreateDatabaseForm variant={ConnectionType.Postgres} />;
};

type CreateDatabaseFormProps = {
  variant:
    | ConnectionType.Mongo
    | ConnectionType.Mysql
    | ConnectionType.Mariadb
    | ConnectionType.Postgres;
};

const variantToLabels = {
  [ConnectionType.Mongo]: {
    fullName: "MongoDB",
    name: "mongodb",
    hostname: "mongodb.opal.dev",
    port: 27017,
    logo: mongoLogo,
  },
  [ConnectionType.Mysql]: {
    fullName: "MySQL",
    name: "mysql",
    hostname: "mysql.opal.dev",
    port: 3306,
    logo: mysqlLogo,
  },
  [ConnectionType.Mariadb]: {
    fullName: "MariaDB",
    name: "mariadb",
    hostname: "mariadb.opal.dev",
    port: 3306,
    logo: mariadbLogo,
  },
  [ConnectionType.Postgres]: {
    fullName: "PostgreSQL",
    name: "postgres",
    hostname: "postgres.opal.dev",
    port: 5432,
    logo: postgresLogo,
  },
};

const CreateDatabaseForm = (props: CreateDatabaseFormProps) => {
  const history = useHistory();
  const hasAppLevelVisibility = useFeatureFlag(FeatureFlag.AppLevelVisibility);
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const logEvent = useLogEvent();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [visibility, setVisibility] = useState<Visibility>(Visibility.Global);
  const [visibilityGroupIds, setVisibilityGroupIds] = useState<
    string[] | undefined
  >([]);
  const [hostname, setHostname] = useState("");
  const [portNumber, setPortNumber] = useState(
    variantToLabels[props.variant].port
  );
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [tlsMode, setTlsMode] = useState(true);
  const [tlsCertContent, setTLSCertContent] = useState<Maybe<string>>(null);
  const [tlsCaCertContent, setTLSCaCertContent] = useState<Maybe<string>>(null);

  const [adminOwnerId, setAdminOwnerId] = useState<string | undefined>(
    undefined
  );

  const [createConnectionMutation, { loading }] = useCreateConnectionMutation({
    refetchQueries: ["AppsListColumn", "Connections"],
  });

  const fieldUnset =
    name === "" ||
    description === "" ||
    hostname === "" ||
    !adminOwnerId ||
    username === "" ||
    password === "";

  const variantLabels = variantToLabels[props.variant];

  const onSubmit = async () => {
    const hostnameErrorMessage = validateHostname(hostname, "Hostname");
    if (hostnameErrorMessage) {
      setErrorMessage(`Error: ${hostnameErrorMessage}`);
      return;
    }
    let inputVariant;
    switch (props.variant) {
      case ConnectionType.Mongo:
        inputVariant = {
          connectionType: ConnectionType.Mongo,
          metadata: {
            connectionType: ConnectionType.Mongo,
            mongo: {
              serverHostname: hostname,
              serverPort: portNumber,
              tlsMode: tlsMode,
              tlsCertContent: tlsCertContent,
              tlsCaCertContent: tlsCaCertContent,
            },
          },
          credentials: {
            authType: AuthType.Mongo,
            mongo: {
              rootUserName: username,
              rootPassword: password,
            },
          },
        };
        break;
      case ConnectionType.Mariadb:
      case ConnectionType.Mysql:
        inputVariant = {
          connectionType: props.variant,
          metadata: {
            connectionType: props.variant,
            mySqlMariadb: {
              serverHostname: hostname,
              serverPort: portNumber,
              tlsMode: tlsMode,
              tlsCertContent: tlsCertContent,
              tlsCaCertContent: tlsCaCertContent,
            },
          },
          credentials: {
            authType: AuthType.Mysqlmariadb,
            mySqlMariadb: {
              rootUserName: username,
              rootPassword: password,
            },
          },
        };
        break;
      case ConnectionType.Postgres:
        inputVariant = {
          connectionType: ConnectionType.Postgres,
          metadata: {
            connectionType: ConnectionType.Postgres,
            postgres: {
              serverHostname: hostname,
              serverPort: portNumber,
              tlsMode: tlsMode,
              tlsCertContent: tlsCertContent,
              tlsCaCertContent: tlsCaCertContent,
            },
          },
          credentials: {
            authType: AuthType.Postgres,
            postgres: {
              rootUserName: username,
              rootPassword: password,
            },
          },
        };
        break;
      default:
        logError(new Error(`unrecognized connection type: ${props.variant}`));
        setErrorMessage(
          `Error: unrecognized connection type: ${props.variant}`
        );
        return;
    }
    try {
      const { data } = await createConnectionMutation({
        variables: {
          input: {
            name: name,
            description: description,
            adminOwnerId: adminOwnerId ?? "",
            visibility: visibility,
            visibilityGroupIds: visibilityGroupIds ?? [],
            importVisibility: Visibility.Global,
            ...inputVariant,
          },
        },
      });
      switch (data?.createConnection.__typename) {
        case "CreateConnectionResult":
          history.replace(
            getResourceUrl(
              EntityTypeDeprecated.Connection,
              data.createConnection.connection.id
            )
          );
          displaySuccessToast(`Success: ${variantLabels.fullName} app created`);
          logEvent({
            name: "apps_create_click",
            properties: {
              connectionType: props.variant,
            },
          });

          break;
        case "ConnectionExistsError":
        case "ConnectionBadMetadataError":
        case "UserFacingError":
          logWarning(new Error(data.createConnection.message));
          setErrorMessage(data.createConnection.message);
          break;
        default:
          logError(new Error(`app creation failed`));
          setErrorMessage(`Error: app creation failed`);
      }
    } catch (error) {
      logError(error, "app creation failed");
      setErrorMessage(
        getModifiedErrorMessage("Error: app creation failed", error)
      );
    }
  };

  const CreateConnectionComponents = hasV3
    ? CreateConnectionComponentsV3
    : CreateConnectionComponentsV2;
  const CreateConnectionView = hasV3
    ? CreateConnectionViewV3
    : CreateConnectionViewV2;

  var disableForm = useFeatureFlag(FeatureFlag.DisableDatabaseAppSetup);
  if (disableForm) {
    return (
      <CreateConnectionView
        title={`Add your ${variantLabels.fullName} database`}
        logo={variantLabels.logo}
        submitDisabled={true}
        submitLoading={false}
      >
        <p>
          Please contact support@opal.dev for assistance with{" "}
          {variantLabels.fullName} app setup.
        </p>
      </CreateConnectionView>
    );
  }

  return (
    <CreateConnectionView
      title={`Add your ${variantLabels.fullName} database`}
      logo={variantLabels.logo}
      onSubmit={onSubmit}
      submitDisabled={fieldUnset}
      submitLoading={loading}
    >
      <>
        <CreateConnectionComponents
          title={"Step 1"}
          subtitle={`Create ${variantLabels.fullName} database app`}
          isLast
        >
          <>
            <FormGroup label="App name:">
              <Input
                onChange={setName}
                placeholder={`Identifiable name of the ${variantLabels.fullName} database.`}
                value={name}
              />
            </FormGroup>
            <FormGroup label="App admin:">
              <OwnerDropdown
                selectedOwnerId={adminOwnerId}
                onSelectOwner={(owner) => setAdminOwnerId(owner?.id)}
                placeholder="Select an owner to own this app."
              />
            </FormGroup>
            <FormGroup label="Description:">
              <Input
                onChange={setDescription}
                placeholder="A brief description of the account to further inform people requesting access to it."
                value={description}
              />
            </FormGroup>
            {hasAppLevelVisibility && (
              <FormGroup label="Visibility:">
                <VisibilitySelector
                  visibility={visibility}
                  onChangeVisibility={(vis) => {
                    if (vis == Visibility.Global) {
                      setVisibility(Visibility.Global);
                      setVisibilityGroupIds([]);
                    } else {
                      setVisibility(Visibility.Team);
                    }
                  }}
                  visibilityGroups={visibilityGroupIds ?? []}
                  onChangeVisibilityGroups={setVisibilityGroupIds}
                />
              </FormGroup>
            )}
            <FormGroup label="Hostname:">
              <Input
                onChange={setHostname}
                placeholder={`The hostname associated with the database (e.g. ${variantLabels.hostname}).`}
                value={hostname}
              />
            </FormGroup>
            <FormGroup label="Port:">
              <Input
                onChange={(value) => {
                  setPortNumber(Number(value));
                }}
                placeholder="The port number associated with the database."
                value={String(portNumber)}
              />
            </FormGroup>
            <FormGroup
              label="TLS Mode"
              infoTooltip={`If enabled, Opal will use TLS to connect to your
                          ${variantLabels.fullName} database. Optionally, if you
                          use self-signed certificates, you can manually upload
                          a client certificate for Opal to use to connect to
                          your database (TLS cert), or you may manually upload
                          your certificate authority's certificate (TLS CA
                          cert).`}
            >
              <div className={sprinkles({ display: "flex" })}>
                <Checkbox
                  size="sm"
                  checked={tlsMode}
                  onChange={() => setTlsMode(!tlsMode)}
                  label="Enabled"
                />
                {tlsMode && (
                  <div className={sprinkles({ display: "flex" })}>
                    <div className={sprinkles({ marginLeft: "md" })}>
                      <UploadCertButton
                        buttonTitle={"Upload TLS cert"}
                        uploadLabelId={"upload-tls-cert-input"}
                        setCertContent={setTLSCertContent}
                        setErrorMessage={setErrorMessage}
                      />
                    </div>
                    <div className={sprinkles({ marginLeft: "md" })}>
                      <UploadCertButton
                        buttonTitle={"Upload TLS CA cert"}
                        uploadLabelId={"upload-tls-cert-ca-input"}
                        setCertContent={setTLSCaCertContent}
                        setErrorMessage={setErrorMessage}
                      />
                    </div>
                  </div>
                )}
              </div>
            </FormGroup>
            <FormGroup label="Username:">
              <Input
                onChange={setUsername}
                placeholder="The username associated with the administrator account of the database."
                value={username}
              />
            </FormGroup>
            <FormGroup label="Password:">
              <Input
                onChange={setPassword}
                placeholder="The password associated with the administrator account of the database."
                value={password}
                type="password"
              />
            </FormGroup>
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
            {!hasV3 && (
              <div
                className={sprinkles({
                  display: "flex",
                  justifyContent: "flex-end",
                })}
              >
                <Button
                  type="primary"
                  disabled={fieldUnset}
                  label={"Create"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </div>
            )}
          </>
        </CreateConnectionComponents>
      </>
    </CreateConnectionView>
  );
};
