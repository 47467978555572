import {
  AccessReviewAction,
  GroupAccessLevel,
  ResourceAccessLevel,
  useGroupAccessLevelsQuery,
  useResourceAccessLevelsQuery,
} from "api/generated/graphql";
import { ContextMenu, Icon, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { ConditionalWrapper } from "views/Common";

import * as styles from "./DecisionButtons.css";

interface Props {
  state?: AccessReviewAction;
  resourceId?: string;
  groupId?: string;
  onApproveClick: () => void;
  onRevokeClick: () => void;
  onRoleSelect?: (role: ResourceAccessLevel | GroupAccessLevel) => void;
  showRole?: boolean;
  clearBackground?: boolean;
  bulkAction?: boolean;
}

const DecisionButtons = (props: Props) => {
  const {
    state,
    resourceId,
    groupId,
    showRole: showRole,
    onRoleSelect: onRoleSelect,
    clearBackground,
    onApproveClick,
    onRevokeClick,
    bulkAction,
  } = props;

  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  const {
    data: resourceRolesData,
    loading: resourceRolesLoading,
    error: resourceRolesError,
  } = useResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceId: resourceId ?? "",
      },
    },
    skip: !resourceId,
  });

  const {
    data: groupRolesData,
    loading: groupRolesLoading,
    error: groupRolesError,
  } = useGroupAccessLevelsQuery({
    variables: {
      input: {
        groupId: groupId ?? "",
      },
    },
    skip: !groupId,
  });

  const roleOptions: PropsFor<typeof ContextMenu>["options"] = [];
  if (resourceRolesLoading || groupRolesLoading) {
    roleOptions.push({
      label: "Loading...",
      onClick: () => {},
      disabled: true,
    });
  } else if (resourceRolesError || groupRolesError) {
    roleOptions.push({
      label: "Failed to fetch roles",
      type: "danger",
      onClick: () => {},
      disabled: true,
    });
  } else if (
    resourceRolesData?.accessLevels.__typename === "ResourceAccessLevelsResult"
  ) {
    resourceRolesData.accessLevels.accessLevels?.forEach((role) => {
      roleOptions.push({
        label: role.accessLevelName,
        onClick: () => {
          if (onRoleSelect) {
            onRoleSelect(role);
          }
        },
      });
    });
  } else if (
    groupRolesData?.groupAccessLevels.__typename === "GroupAccessLevelsResult"
  ) {
    groupRolesData.groupAccessLevels.accessLevels?.forEach((role) => {
      roleOptions.push({
        label: role.accessLevelName,
        onClick: () => {
          if (onRoleSelect) {
            onRoleSelect(role);
          }
        },
      });
    });
  }

  return (
    <div
      className={sprinkles({
        display: "flex",
        alignItems: "center",
        gap: "sm",
      })}
    >
      <ConditionalWrapper
        wrapper={(children) => (
          <Tooltip tooltipText={`accept${bulkAction ? " all" : ""}`}>
            {children}
          </Tooltip>
        )}
        useWrapper={hasV3}
      >
        <div
          className={styles.decisionButton({
            color: "green",
            clear: clearBackground,
            selected: state === AccessReviewAction.Accept,
          })}
          onClick={onApproveClick}
        >
          {state === AccessReviewAction.Accept ? (
            <Icon name="check-circle" color="green600" size="xs" />
          ) : (
            <Icon name="check" size="xs" />
          )}
        </div>
      </ConditionalWrapper>
      <ConditionalWrapper
        wrapper={(children) => (
          <Tooltip tooltipText={`revoke${bulkAction ? " all" : ""}`}>
            {children}
          </Tooltip>
        )}
        useWrapper={hasV3}
      >
        <div
          className={styles.decisionButton({
            color: "red",
            clear: clearBackground,
            selected: state === AccessReviewAction.Revoke,
          })}
          onClick={onRevokeClick}
        >
          {state === AccessReviewAction.Revoke ? (
            <Icon name="x-circle" color="red600" size="xs" />
          ) : (
            <Icon name="x" size="xs" />
          )}
        </div>
      </ConditionalWrapper>
      {showRole && (
        <ContextMenu
          renderButton={(onClick) => (
            <div
              className={styles.decisionButton({
                color: "blue",
                clear: clearBackground,
                selected: state === AccessReviewAction.Update,
              })}
              onClick={onClick}
            >
              <Icon name="arrow-switch-3" size="xs" />
              <Icon name="chevron-down" size="xs" />
            </div>
          )}
          options={roleOptions}
        />
      )}
    </div>
  );
};

export default DecisionButtons;
