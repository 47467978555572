import AuthContext from "components/auth/AuthContext";
import { useContext } from "react";
import { hasBasicPermissions } from "utils/auth/auth";
import { ForbiddenPage } from "views/error/ErrorCodePage";
import EventsTableV3Component from "views/events/EventsTableV3Component";

interface Props {
  accessReviewId: string;
}

const AccessReviewEventsTable = ({ accessReviewId }: Props) => {
  const { authState } = useContext(AuthContext);
  const isMember = hasBasicPermissions(authState.user);

  if (isMember) {
    return <ForbiddenPage />;
  }

  return (
    <EventsTableV3Component
      eventFilter={{
        objects: {
          objectId: accessReviewId,
        },
      }}
      route={{
        pathname: `/access-reviews/${accessReviewId}`,
        hash: "#events",
      }}
    />
  );
};

export default AccessReviewEventsTable;
