import { getModifiedErrorMessage } from "api/ApiContext";
import {
  RequestFragment,
  RequestPreviewLargeFragment,
  RequestsDocument,
  RequestsQuery,
  RequestStatus,
  RequestType,
  useCancelRequestMutation,
} from "api/generated/graphql";
import { updateNumRequestsToReview } from "components/layout/left_sidebar/useLeftSidebarRoutes";
import { useToast } from "components/toast/Toast";
import { Button, ButtonV3 } from "components/ui";
import { useHistory } from "react-router";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { useRequestPermissions } from "views/requests/RequestOverview";

type RequestCancelButtonProps = {
  request: RequestFragment;
};

export const RequestCancelButton = (props: RequestCancelButtonProps) => {
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);
  const history = useHistory();
  const {
    belongsToCurrentUser,
    canAdminReview,
    canNormalReview,
  } = useRequestPermissions(props.request);

  const { displaySuccessToast, displayErrorToast } = useToast();

  const [cancelRequest, { loading }] = useCancelRequestMutation({
    refetchQueries: ["EventsFull", "RequestsPendingStat", "RequestStats"],
  });

  if (props.request.status !== RequestStatus.Pending || !belongsToCurrentUser) {
    return null;
  }

  const handleCancel = async () => {
    try {
      const { data } = await cancelRequest({
        variables: {
          input: {
            id: props.request.id,
          },
        },
        refetchQueries: hasV3Nav ? ["RequestsTable"] : [],
        update: (cache, { data }) => {
          switch (data?.cancelRequest.__typename) {
            case "CancelRequestResult": {
              // Remove when V2 is deprecated. This behavior is only
              // valid for V2 nav, where we have a sidebar of requests.
              const canceledRequest = data.cancelRequest.request;
              !hasV3Nav &&
                [RequestType.Incoming, RequestType.Outgoing].forEach(
                  (requestType) => {
                    let cachedQuery = cache.readQuery<RequestsQuery>({
                      query: RequestsDocument,
                      variables: {
                        input: {
                          requestType: requestType,
                        },
                      },
                    });
                    if (cachedQuery) {
                      let canceledRequests: RequestPreviewLargeFragment[] = cachedQuery.requests.requests.filter(
                        (request) => request.id !== canceledRequest.id
                      );
                      cache.writeQuery<RequestsQuery>({
                        query: RequestsDocument,
                        variables: {
                          input: {
                            requestType: requestType,
                          },
                        },
                        data: {
                          ...cachedQuery,
                          requests: {
                            ...cachedQuery.requests,
                            requests: canceledRequests,
                          },
                        },
                      });
                    }
                  }
                );
              if (canAdminReview || canNormalReview) {
                updateNumRequestsToReview(cache);
              }
            }
          }
        },
      });
      switch (data?.cancelRequest.__typename) {
        case "CancelRequestResult": {
          const request = data.cancelRequest.request;
          displaySuccessToast(`Success: request canceled`);
          history.replace(
            getResourceUrl(EntityTypeDeprecated.Request, request.id)
          );
          break;
        }
        case "RequestAlreadyActionedError":
        case "RequestNotFoundError":
          displayErrorToast(data.cancelRequest.message);
          break;
        default:
          logError(new Error(`request cancel failed`));
          displayErrorToast(`Error: request cancel failed`);
      }
    } catch (error) {
      logError(error, "request cancel failed");
      displayErrorToast(
        getModifiedErrorMessage(`Error: request cancel failed`, error)
      );
    }
  };

  return hasV3Nav ? (
    <ButtonV3
      label="Cancel Request"
      onClick={handleCancel}
      loading={loading}
      size="md"
    />
  ) : (
    <Button
      label="Cancel"
      onClick={handleCancel}
      loading={loading}
      outline
      size="md"
      borderless
    />
  );
};

export default RequestCancelButton;
