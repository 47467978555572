import { AccessReviewUserWarningType } from "api/generated/graphql";
import { Header as MaterialTableHeader } from "components/tables/material_table/MaterialTable";

export interface AccessReviewReviewerWarningRow {
  reason: string;
  reviewer?: string;
  event?: string;
}

export const ReviewerWarningHeaders: MaterialTableHeader<AccessReviewReviewerWarningRow>[] = [
  { id: "reason", label: "Reason" },
  { id: "reviewer", label: "Reviewer" },
  { id: "event", label: "Event" },
];

export const FormatReason = (
  accessReviewUserWarningType: AccessReviewUserWarningType
): string => {
  switch (accessReviewUserWarningType) {
    case AccessReviewUserWarningType.ReviewerDeleted:
      return "Reviewer's user account was deleted in Opal";
    case AccessReviewUserWarningType.ReviewerNoVisibility:
      return "Reviewer has no visibility to the item";
    case AccessReviewUserWarningType.SelfReviewNotAllowed:
      return "Self reviews are not allowed";
  }
};
