import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AddGroupUserInput,
  GroupAccessLevel,
  GroupAccessLevelInput,
  GroupPreviewWithUsersFragment,
  SortDirection,
  useAddGroupUsersMutation,
  useGroupAddUsersQuery,
  UserPreviewSmallFragment,
  UsersSortByField,
} from "api/generated/graphql";
import UploadCSVButton, {
  CSVColumns,
  UserInfo,
} from "components/buttons/UploadCSVButton";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import FullscreenViewTitle from "components/fullscreen_modals/FullscreenViewTitle";
import { ResourceLabel } from "components/label/Label";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import { Banner, Divider, Icon, Input, Select } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableFilters from "components/ui/table/TableFilters";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import { useState } from "react";
import { useParams } from "react-router";
import { useImmer } from "use-immer";
import useLogEvent from "utils/analytics";
import {
  AuthorizedActionManage,
  AuthorizedActionManageUser,
} from "utils/auth/auth";
import { groupTypeHasRoles } from "utils/directory/groups";
import { serviceTypeHasMaxOneRole } from "utils/directory/resources";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { ForbiddenPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
} from "views/requests/utils";
import { getUserAvatarIcon } from "views/users/utils";
import { dropNothings } from "views/utils";

import * as styles from "./ResourceAddUsersView.css";

type GroupUserToAdd = {
  user: UserPreviewSmallFragment;
  expiration: ExpirationValue;
  expirationInMinutes?: number;
  roles?: GroupAccessLevel[];
};

interface UserItemRow {
  id: string;
  [UsersSortByField.FirstName]: string;
  [UsersSortByField.Email]: string;
  item: UserPreviewSmallFragment;
}
const COLUMNS: Header<UserItemRow>[] = [
  {
    id: UsersSortByField.FirstName,
    label: "Name",
    sortable: true,
    customCellRenderer: (row) => {
      let userFullname = row.item?.fullName;
      let icon = row.item ? getUserAvatarIcon(row.item) : null;
      if (!userFullname) {
        return <></>;
      }
      return (
        <div
          className={sprinkles({
            display: "flex",
            alignItems: "center",
            gap: "sm",
          })}
        >
          {icon && <Icon data={icon} />}
          <ResourceLabel
            text={userFullname}
            bold={true}
            pointerCursor={true}
            maxChars="auto"
          />
        </div>
      );
    },
    width: 200,
  },
  {
    id: UsersSortByField.Email,
    label: "Email",
    sortable: true,
    customCellRenderer: (row) => {
      let userEmail = row.item.email;
      if (!userEmail) {
        return <></>;
      }
      return (
        <ResourceLabel
          text={userEmail}
          bold={false}
          pointerCursor={true}
          maxChars="auto"
        />
      );
    },
    width: 200,
  },
];

function isSortableField(str: string): str is UsersSortByField {
  return Object.values<string>(UsersSortByField).includes(str);
}

type SortValue = {
  field: UsersSortByField;
  direction: SortDirection;
};

const GroupAddUsersView = () => {
  const transitionBack = useTransitionBack();
  const logEvent = useLogEvent();
  const { groupId } = useParams<{ groupId: string }>();
  const startPushTaskPoll = usePushTaskLoader();

  const [loadingCSV, setLoadingCSV] = useState<boolean>(false);
  const [usersToAddByUserId, setUsersToAddByUserId] = useImmer<
    Partial<Record<string, GroupUserToAdd>>
  >({});
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  const [errorMessages, setErrorMessages] = useImmer<string[]>([]);

  const [sortBy, setSortBy] = useState<SortValue | undefined>({
    field: UsersSortByField.FirstName,
    direction: SortDirection.Asc,
  });
  const [
    addGroupUsers,
    { loading: addUsersLoading },
  ] = useAddGroupUsersMutation();
  const {
    data,
    previousData,
    loading,
    error,
    fetchMore,
  } = useGroupAddUsersQuery({
    variables: {
      id: groupId,
      searchQuery: debouncedSearchQuery,
      sortBy: sortBy,
      maxNumEntries: 100,
    },
  });
  const loadMoreRows = async () => {
    if (!cursor) {
      return;
    }
    await fetchMore({
      variables: {
        searchQuery: debouncedSearchQuery,
        cursor: cursor,
        sortBy: sortBy,
      },
    });
  };
  let group: GroupPreviewWithUsersFragment | undefined;
  if (previousData?.group.__typename === "GroupResult") {
    group = previousData.group.group;
  }
  if (data?.group.__typename === "GroupResult") {
    group = data.group.group;
  }
  const allUsers = data?.users.users ?? previousData?.users.users ?? [];
  const roles: GroupAccessLevel[] = group?.accessLevels ?? [];
  const cursor = data?.users?.cursor || undefined;
  const totalNumUsers = data?.users?.totalNumUsers || 0;

  if (loading && !(data || previousData)) {
    return <FullscreenSkeleton />;
  }
  if (
    !(
      group?.authorizedActions?.includes(AuthorizedActionManage) ||
      group?.authorizedActions?.includes(AuthorizedActionManageUser)
    )
  ) {
    return <ForbiddenPage />;
  }
  if (!group || error) {
    return <UnexpectedErrorPage error={error} />;
  }

  const handleClose = () => {
    transitionBack(`/groups/${groupId}#users`);
  };

  const directRolesByUserId: Record<string, GroupAccessLevel[]> = {};
  const numDirectAccessPointsByUserId: Record<string, number> = {};
  group.groupUsers.forEach((groupUser) => {
    if (groupUser.access?.directAccessPoint) {
      if (!directRolesByUserId[groupUser.userId]) {
        directRolesByUserId[groupUser.userId] = [];
      }
      if (groupUser.accessLevel) {
        directRolesByUserId[groupUser.userId] = _.uniqBy(
          [...directRolesByUserId[groupUser.userId], groupUser.accessLevel],
          "accessLevelRemoteId"
        );
      }

      if (!numDirectAccessPointsByUserId[groupUser.userId]) {
        numDirectAccessPointsByUserId[groupUser.userId] = 0;
      }
      numDirectAccessPointsByUserId[groupUser.userId] += 1;
    }
  });

  // Filter out users who have direct access already
  const users = allUsers.filter((user) => {
    const directRoleCount = numDirectAccessPointsByUserId[user.id] || 0;
    // Group does not have access levels
    if (directRoleCount > 0) {
      return false;
    }
    return true;
  });

  const rows: UserItemRow[] = users.map((user) => {
    return {
      id: user.id,
      [UsersSortByField.FirstName]: user.firstName || "--",
      [UsersSortByField.Email]: user.email || "--",
      item: user,
    };
  });

  const groupHasRoles = groupTypeHasRoles(group.groupType);
  const numUsersToAdd = Object.keys(usersToAddByUserId).length;

  const handleAddUsers = async () => {
    if (groupHasRoles) {
      const usersRequireRoles = Object.values(usersToAddByUserId).filter(
        (user) => {
          if (!user?.roles || user.roles.length === 0) {
            return true;
          }
          return false;
        }
      );
      if (usersRequireRoles.length > 0) {
        setErrorMessages(["You must select at least one role for each user."]);
        return;
      }
    }

    logEvent({
      name: "apps_add_user",
      properties: {
        type: "group",
        numUsers: Object.entries(expirationValueToDurationInMinutes).length,
      },
    });
    try {
      const groupUsersToAdd: AddGroupUserInput[] = dropNothings(
        Object.values(usersToAddByUserId).reduce((acc, userMetadata) => {
          if (!userMetadata) {
            return acc;
          }
          const expirationVal =
            userMetadata.expiration || ExpirationValue.Indefinite;
          const accessDurationInMinutes =
            userMetadata.expirationInMinutes ??
            expirationValueToDurationInMinutes(expirationVal)?.asMinutes();

          let role: GroupAccessLevelInput | null = null;
          const roles = userMetadata.roles;
          if (roles) {
            role = {
              accessLevelName: roles[0].accessLevelName,
              accessLevelRemoteId: roles[0].accessLevelRemoteId,
            };
          }

          acc.push({
            groupId,
            userId: userMetadata.user.id,
            durationInMinutes: accessDurationInMinutes,
            accessLevel: role,
          });

          return acc;
        }, [] as AddGroupUserInput[])
      );

      const { data } = await addGroupUsers({
        variables: {
          input: {
            groupUsers: groupUsersToAdd,
          },
        },
        refetchQueries: [
          "GroupDetailView",
          "GroupDetailPaginatedGroupUsers",
          "AppsListColumn",
        ],
      });
      switch (data?.addGroupUsers.__typename) {
        case "AddGroupUsersResult":
          startPushTaskPoll(data.addGroupUsers.taskId);
          handleClose();
          break;
        case "CannotAddSystemUserToGroupError":
          logError(new Error(data.addGroupUsers.message));
          setErrorMessages([data.addGroupUsers.message]);
          break;
        case "CallToWebhookFailedError":
          setErrorMessages([data.addGroupUsers.message]);
          break;
        case "GroupUserAlreadyExists":
          setErrorMessages([data.addGroupUsers.message]);
          break;
        default:
          logError(new Error(`failed to add group users`));
          setErrorMessages(["Error: failed to add group users"]);
      }
    } catch (error) {
      logError(error, "failed to add group users");
      setErrorMessages([
        getModifiedErrorMessage("Error: failed to add group users", error),
      ]);
    }
  };

  const handleUserClick = (user: UserPreviewSmallFragment) => {
    if (user.id in usersToAddByUserId) {
      setUsersToAddByUserId((draft) => {
        delete draft[user.id];
      });
    } else {
      setUsersToAddByUserId((draft) => {
        draft[user.id] = {
          user: user,
          expiration: ExpirationValue.Indefinite,
        };
      });
    }
  };

  const handleUserCSVUpload = async (userInfos: UserInfo[]) => {
    setLoadingCSV(true);
    const userEmails = userInfos.map((userInfo) => userInfo.email);
    const userInfoByEmail = _.keyBy(userInfos, "email");
    // Get the users from our backend, and add them to the table if needed
    const { data, error } = await fetchMore({
      variables: { userEmails, maxNumEntries: userEmails.length },
    });
    if (error) {
      logError(error, `failed to fetch users`);
      setErrorMessages([`Error: failed to fetch users`]);
      setLoadingCSV(false);
      return;
    }
    setErrorMessages([]);
    // Create a map of users by email and their secondaryEmails
    const userByEmail = data.users.users.reduce((acc, user) => {
      for (const email of user.secondaryEmails) {
        acc[email] = user;
      }
      acc[user.email] = user;
      return acc;
    }, {} as Record<string, UserPreviewSmallFragment>);
    // Tell the user that some users were not found
    const usersNotFound: string[] = [];
    for (const email of userEmails) {
      if (!userByEmail[email]) {
        usersNotFound.push(email);
      }
    }
    if (usersNotFound.length > 0) {
      setErrorMessages((draft) => {
        draft.push(
          `${pluralize(
            "user",
            usersNotFound.length,
            true
          )} not found: ${usersNotFound.join(", ")}`
        );
      });
    }

    // Add users that don't already have access to the staging sidebar
    // Notify the user that some users in the CSV already have access
    const users = data.users.users.reduce(
      (acc, user) => {
        const directRoleCount = numDirectAccessPointsByUserId[user.id] || 0;
        // Group does not have access levels
        if (directRoleCount > 0) {
          acc.skippedUsers.push(user.email);
        } else {
          acc.usersToAdd.push(user);
        }
        return acc;
      },
      {
        skippedUsers: [] as string[],
        usersToAdd: [] as UserPreviewSmallFragment[],
      }
    );
    if (users.usersToAdd.length > 0) {
      setUsersToAddByUserId((draft) => {
        for (const user of users.usersToAdd) {
          let role: GroupAccessLevel | undefined;

          let userInfo: UserInfo | undefined = userInfoByEmail[user.email];
          if (!userInfo) {
            userInfo = user.secondaryEmails
              .map((email) => userInfoByEmail[email])
              .find((u) => !!u);
          }
          role = roles.find(
            (role) => role.accessLevelRemoteId == userInfo?.roleRemoteId
          );

          draft[user.id] = {
            user,
            expiration: ExpirationValue.Indefinite,
            expirationInMinutes: userInfo?.durationInMinutes,
            roles: groupHasRoles && role ? [role] : undefined,
          };
        }
      });
    }
    if (users.skippedUsers.length > 0) {
      setErrorMessages((draft) => {
        draft.push(
          `${pluralize(
            "user",
            users.skippedUsers.length,
            true
          )} already have access: ${users.skippedUsers.join(", ")}`
        );
      });
    }
    setLoadingCSV(false);
  };

  return (
    <FullscreenView
      title={
        <FullscreenViewTitle
          entityType={group.groupType}
          entityName={group.name}
          targetEntityName="users"
          action="add"
        />
      }
      onCancel={handleClose}
      onPrimaryButtonClick={handleAddUsers}
      primaryButtonLabel={`Add ${
        numUsersToAdd > 0 ? numUsersToAdd : ""
      } ${pluralize("user", numUsersToAdd)}`}
      primaryButtonDisabled={numUsersToAdd === 0 || loadingCSV}
      primaryButtonLoading={addUsersLoading || loadingCSV}
    >
      <FullscreenView.Content fullWidth>
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textMd",
              fontWeight: "medium",
              marginBottom: "md",
            })}
          >
            Select users to add to the group:
          </div>
          <TableFilters>
            <TableFilters.Left>
              <div className={styles.searchInput}>
                <Input
                  leftIconName="search"
                  type="search"
                  style="search"
                  value={searchQuery}
                  onChange={(value) => {
                    setSearchQuery(value);
                  }}
                  placeholder="Filter by name or email"
                  autoFocus
                />
              </div>
            </TableFilters.Left>
            <TableFilters.Right>
              <UploadCSVButton
                onChangeUserInfos={handleUserCSVUpload}
                onChangeErrorMessage={(errorMessage) =>
                  setErrorMessages([errorMessage])
                }
                requiredColumns={
                  groupHasRoles
                    ? [CSVColumns.Email, CSVColumns.RoleRemoteId]
                    : [CSVColumns.Email]
                }
                optionalColumns={[CSVColumns.DurationInMinutes]}
                trackName="apps_add_user"
              />
            </TableFilters.Right>
          </TableFilters>
          <Divider />
          {loading ? (
            <ColumnListItemsSkeleton />
          ) : (
            <Table
              columns={COLUMNS}
              rows={rows}
              totalNumRows={totalNumUsers ?? 0}
              getRowId={(user) => user.id}
              onLoadMoreRows={loadMoreRows}
              checkedRowIds={new Set(Object.keys(usersToAddByUserId))}
              onCheckedRowsChange={(checkedRowIds) => {
                for (const userId of checkedRowIds) {
                  const user = users.find((user) => user.id === userId);
                  if (user) {
                    handleUserClick(user);
                  }
                }
              }}
              onRowClick={(user) => {
                handleUserClick(user.item);
              }}
              manualSortDirection={
                sortBy && {
                  sortBy: sortBy.field,
                  sortDirection: sortBy.direction,
                }
              }
              handleManualSort={(sortBy, sortDirection) => {
                // Investigate if there is more elegant solution
                if (!sortDirection) {
                  setSortBy(undefined);
                  return;
                }
                if (!isSortableField(sortBy)) {
                  return;
                }
                const direction: SortDirection =
                  sortDirection === "DESC"
                    ? SortDirection.Desc
                    : SortDirection.Asc;
                setSortBy({
                  field: sortBy,
                  direction,
                });
              }}
            />
          )}
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {errorMessages && errorMessages.length > 0 && (
          <>
            {errorMessages.map((errorMessage, index) => (
              <Banner
                key={errorMessage + index}
                message={errorMessage}
                type="error"
                marginBottom="lg"
              />
            ))}
          </>
        )}
        <div
          className={sprinkles({
            fontSize: "textLg",
            fontWeight: "medium",
            marginBottom: "lg",
          })}
        >
          Adding {numUsersToAdd} {pluralize("User", numUsersToAdd)}
        </div>
        {Object.keys(usersToAddByUserId).map((userId) => {
          const user = usersToAddByUserId[userId]?.user;
          if (!user) {
            return null;
          }
          const expiration = usersToAddByUserId[userId]?.expiration;
          const expirationInMinutes =
            usersToAddByUserId[userId]?.expirationInMinutes;

          return (
            <div key={userId} className={styles.userCard}>
              <div
                className={sprinkles({
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "sm",
                  marginBottom: "lg",
                })}
              >
                <div className={sprinkles({ flexShrink: 0 })}>
                  <Icon data={getUserAvatarIcon(user)} />
                </div>
                <div className={styles.userInfoSection}>
                  <div className={styles.userCardHeader}>{user.fullName}</div>
                  <div className={styles.userCardSubtitle}>{user.email}</div>
                </div>
                <div className={sprinkles({ flexShrink: 0 })}>
                  <Icon
                    name="trash"
                    color="red600V3"
                    onClick={() => {
                      setUsersToAddByUserId((draft) => {
                        delete draft[user.id];
                      });
                    }}
                  />
                </div>
              </div>
              {expirationInMinutes ? (
                <div
                  className={sprinkles({
                    paddingX: "sm",
                    marginTop: "sm",
                    fontSize: "textSm",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  })}
                >
                  Access for{" "}
                  {moment.duration(expirationInMinutes, "minutes").humanize()}
                  <Icon
                    name="x"
                    size="xs"
                    onClick={() => {
                      setUsersToAddByUserId((draft) => {
                        const userToUpdate = draft[user.id];
                        if (userToUpdate) {
                          delete userToUpdate.expirationInMinutes;
                        }
                      });
                    }}
                  />
                </div>
              ) : (
                <Select
                  key={userId}
                  options={Object.values(ExpirationValue)}
                  value={expiration}
                  onChange={(val) => {
                    if (val) {
                      setUsersToAddByUserId((draft) => {
                        const userToAdd = draft[user.id];
                        if (userToAdd) {
                          userToAdd.expiration = val;
                        } else {
                          draft[user.id] = { user: user, expiration: val };
                        }
                      });
                    }
                  }}
                  disableBuiltInFiltering
                  getOptionLabel={(expirationVal) =>
                    expirationVal === ExpirationValue.Indefinite
                      ? "Indefinite access"
                      : `Access for ${expirationVal}`
                  }
                />
              )}
              {groupHasRoles && (
                <div className={sprinkles({ marginTop: "md" })}>
                  <Select
                    key={userId}
                    options={roles.filter((role) => {
                      // Only include roles that the user does not already have
                      // or are currently selected to be added
                      if (
                        directRolesByUserId[user.id]
                          ?.map((r) => r.accessLevelRemoteId)
                          .includes(role.accessLevelRemoteId)
                      ) {
                        return false;
                      }
                      if (
                        usersToAddByUserId[user.id]?.roles
                          ?.map((role) => role.accessLevelRemoteId)
                          .includes(role.accessLevelRemoteId)
                      ) {
                        return false;
                      }
                      return true;
                    })}
                    selectOnly
                    placeholder="Select role"
                    onChange={(val) => {
                      if (!val) {
                        return;
                      }
                      const selectedAccessLevel = {
                        accessLevelName: val.accessLevelName,
                        accessLevelRemoteId: val.accessLevelRemoteId,
                      };
                      setUsersToAddByUserId((draft) => {
                        const userToAdd = draft[user.id];
                        if (userToAdd) {
                          const currentRoles = userToAdd.roles;
                          if (
                            serviceTypeHasMaxOneRole(group?.serviceType) ||
                            (currentRoles?.length === 1 &&
                              currentRoles?.[0].accessLevelName === "")
                          ) {
                            userToAdd.roles = [selectedAccessLevel];
                          } else {
                            userToAdd.roles = [
                              ...(currentRoles ?? []),
                              selectedAccessLevel,
                            ];
                          }
                          userToAdd.roles = [selectedAccessLevel];
                        } else {
                          draft[user.id] = {
                            user: user,
                            roles: [selectedAccessLevel],
                            expiration:
                              expiration ?? ExpirationValue.Indefinite,
                          };
                        }
                      });
                    }}
                    getOptionLabel={(role) => role.accessLevelName}
                  />
                  {usersToAddByUserId[user.id]?.roles?.map((role) => {
                    if (role.accessLevelName === "") {
                      return null;
                    }
                    return (
                      <div
                        key={role.accessLevelRemoteId}
                        className={sprinkles({
                          paddingX: "sm",
                          marginTop: "sm",
                          fontSize: "textSm",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        })}
                      >
                        {role.accessLevelName}
                        <Icon
                          name="x"
                          size="xs"
                          onClick={() => {
                            setUsersToAddByUserId((draft) => {
                              const userToAdd = draft[user.id];
                              if (userToAdd) {
                                userToAdd.roles = userToAdd.roles?.filter(
                                  (r) =>
                                    r.accessLevelRemoteId !==
                                    role.accessLevelRemoteId
                                );
                              }
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};

export default GroupAddUsersView;
