import {
  ConnectionPreviewFragment,
  ConnectionPreviewLargeFragment,
  ConnectionType,
  ConnectionVisibilityGroupFragment,
  EntityType,
  ImportSetting,
  Maybe,
  useConnectionPreviewQuery,
  useUpdateConnectionMutation,
  Visibility,
} from "api/generated/graphql";
import { ClickToCopyButton } from "components/buttons/ClickToCopyButton";
import { Column } from "components/column/Column";
import { ConnectionConfig, FormMode } from "components/forms/common";
import AdminRow from "components/forms/rows/AdminRow";
import ChildrenDefaultConfigTemplateRow from "components/forms/rows/ChildrenDefaultConfigTemplateRow";
import ConnectionAutoImportGroupResourcesRow from "components/forms/rows/ConnectionAutoImportGroupResourcesRow";
import ConnectionImportNotificationRow from "components/forms/rows/ConnectionImportNotificationRow";
import ConnectionImportSettingRow from "components/forms/rows/ConnectionImportSettingRow";
import ConnectionTicketProviderPropagationRow, {
  TicketProviderFields,
} from "components/forms/rows/ConnectionTicketProviderPropagationRow";
import ConnectionWebhookToggleRow from "components/forms/rows/ConnectionWebhookToggleRow";
import DescriptionRow from "components/forms/rows/DescriptionRow";
import VisibilityRow from "components/forms/rows/VisibilityRow";
import {
  getConnectionConfigChangedFields,
  makeConfigForConnection,
  makeUpdateInputForConnection,
  validateConnectionConfig,
} from "components/forms/utils";
import { connectionTypeInfoByType } from "components/label/ConnectionTypeLabel";
import { useToast } from "components/toast/Toast";
import { Banner, Button, FormRow, FormSection, Label } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { hasRemoteGroupResources } from "utils/directory/connections";
import { isNativeConnectionType } from "utils/directory/resources";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import AppHeader from "./AppHeader";
import AppsContentColumn from "./AppsContentColumn";

const AppOverviewColumn = () => {
  const { appId } = useParams<Record<string, string>>();
  const logEvent = useLogEvent();

  const { displaySuccessToast } = useToast();

  const [mode, setMode] = useState<FormMode>("view");
  const [errors, setErrors] = useState<string[]>([]);

  const [
    updateConnection,
    { loading: updateLoading },
  ] = useUpdateConnectionMutation();

  const { data, error, loading } = useConnectionPreviewQuery({
    variables: {
      connectionId: appId,
    },
  });

  let connection: Maybe<
    ConnectionPreviewLargeFragment & {
      importVisibilityGroups: ConnectionVisibilityGroupFragment[];
    } & {
      visibilityGroups: ConnectionVisibilityGroupFragment[];
    }
  > = null;
  let connectionNotFound = false;
  if (data) {
    switch (data.connection.__typename) {
      case "ConnectionResult":
        connection = data.connection.connection;
        break;
      case "ConnectionNotFoundError":
        connectionNotFound = true;
        break;
      default:
        logError(new Error(`failed to get app`));
    }
  } else if (error) {
    logError(error, `failed to get app`);
  }

  const [initialConfig, setInitialConfig] = useState<
    Partial<ConnectionConfig>
  >();
  const [config, setConfig] = useState<Partial<ConnectionConfig>>({});

  useEffect(() => {
    if (!connection) return;
    const connectionConfig = makeConfigForConnection(connection);
    setConfig(connectionConfig);
    setInitialConfig(connectionConfig);
  }, [connection]);

  if (connectionNotFound) {
    return (
      <Column isContent>
        <NotFoundPage />
      </Column>
    );
  }

  if (loading) {
    return (
      <Column isContent>
        <ColumnContentSkeleton />
      </Column>
    );
  }

  if (error) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  if (!connection || !config) {
    return null;
  }

  const handleSave = async () => {
    if (connection === null || !initialConfig) {
      return null;
    }

    const errors = validateConnectionConfig(config);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    logEvent({
      name: "apps_app_edit_click",
      properties: {
        connectionType: connection.connectionType,
        visibilityChange:
          config.visibility !== connection.visibility ||
          !_.isEqual(
            config.visibilityGroupIds,
            connection.visibilityGroups.map((g) => g.visibilityGroupId)
          ),
      },
    });

    try {
      setErrors([]);

      const changedConfig = getConnectionConfigChangedFields(
        initialConfig,
        config
      );
      const inputFields = makeUpdateInputForConnection(
        changedConfig,
        connection
      );

      const { data } = await updateConnection({
        variables: {
          input: inputFields,
        },
        refetchQueries: [
          "ConnectionOverview",
          "AppDetailColumn",
          "ConnectionPreview",
        ],
      });

      switch (data?.updateConnection.__typename) {
        case "UpdateConnectionResult":
          setMode("view");
          setErrors([]);
          displaySuccessToast("Success: app settings updated");
          break;
        case "UserFacingError":
        case "ConnectionNotFoundError":
        case "ConnectionBadMetadataError":
        case "ConnectionVisibilityGroupNotFoundError":
          setErrors([data.updateConnection.message]);
          logError(new Error(data.updateConnection.message));
          break;
        default:
          logError(new Error("Failed to update connection"));
          setErrors(["Failed to update connection."]);
      }
    } catch (err) {
      logError(err, "Failed to update connection");
      setErrors(["Failed to update connection."]);
    }
  };

  let headerButtons =
    mode === "view" ? (
      <Button
        label="Edit"
        leftIconName="edit"
        borderless
        onClick={() => setMode("edit")}
        size="md"
      />
    ) : (
      <div className={sprinkles({ display: "flex", gap: "sm" })}>
        <Button
          label="Cancel"
          onClick={() => {
            setMode("view");
            if (initialConfig) {
              setConfig(initialConfig);
            }
            setErrors([]);
          }}
          disabled={updateLoading}
          borderless
          size="md"
        />
        <Button
          label={updateLoading ? "Saving..." : "Save"}
          leftIconName="check"
          type="primary"
          onClick={handleSave}
          disabled={updateLoading || _.isEqual(config, initialConfig)}
          size="md"
        />
      </div>
    );

  return (
    <AppsContentColumn>
      <AppHeader editButtons={headerButtons} mode={mode} />
      {errors.map((error) => (
        <Banner message={error} type="error" />
      ))}
      <AppDetails
        connection={connection}
        mode={mode}
        config={config}
        setConfig={setConfig}
      />
    </AppsContentColumn>
  );
};

interface DetailsProps {
  connection: ConnectionPreviewFragment;
  mode: FormMode;
  config: Partial<ConnectionConfig>;
  setConfig: (config: Partial<ConnectionConfig>) => void;
}

export const AppDetails = (props: DetailsProps) => {
  const { connection, mode, config, setConfig } = props;

  const hasWebhookToggleFF = useFeatureFlag(
    FeatureFlag.ConnectionWebhookToggle
  );
  const hasConfigTemplates = useFeatureFlag(FeatureFlag.ConfigurationTemplates);
  const hasAutoImportConfigTemplate = useFeatureFlag(
    FeatureFlag.ConnectionAutoImportConfigTemplate
  );
  const hasAppLevelVisibility = useFeatureFlag(FeatureFlag.AppLevelVisibility);

  const handleChange = (key: keyof ConnectionConfig) => (
    val: ConnectionConfig[keyof ConnectionConfig]
  ) => {
    setConfig({
      ...config,
      [key]: val,
    });
  };

  const handleChangeShouldNotify = (val: boolean) => {
    let importNotification = {
      shouldNotify: val,
      recipientOwnerId: config.importNotification?.recipientOwnerId,
      recipientOwnerName: config.importNotification?.recipientOwnerName,
    };

    setConfig({
      ...config,
      importNotification: importNotification,
    });
  };

  const handleChangeImportRecipientOwner = (recipient?: {
    name: string;
    id: string;
  }) => {
    let importNotification = {
      shouldNotify: config.importNotification
        ? config.importNotification.shouldNotify
        : false,
      recipientOwnerId: recipient?.id,
      recipientOwnerName: recipient?.name,
    };

    setConfig({
      ...config,
      importNotification: importNotification,
    });
  };

  const handleChangeTicketProvider = ({
    ticketProviderEnabled,
    ticketProjectId,
    ticketProjectName,
    ticketProvider,
  }: TicketProviderFields) => {
    setConfig({
      ...config,
      ticketProviderEnabled,
      ticketProjectId,
      ticketProjectName,
      ticketProvider,
    });
  };

  const handleChangeOwner = (owner?: { name: string; id: string }) => {
    setConfig({
      ...config,
      adminOwnerId: owner?.id,
      adminOwnerName: owner?.name,
    });
  };

  const importFormRows = [];
  if (!isNativeConnectionType(connection.connectionType)) {
    const awsMetadata =
      (connection.metadata?.__typename === "AWSSSOConnectionMetadata" &&
        connection.metadata) ||
      null;

    importFormRows.push(
      <ConnectionImportSettingRow
        key="importSetting"
        connectionType={connection.connectionType}
        mode={mode}
        importSetting={config.importSetting ?? ImportSetting.None}
        onChange={handleChange("importSetting")}
        awsIdentityCenterImportSetting={config.awsIdentityCenterImportSetting}
        onChangeAwsIdentityCenterImportSetting={handleChange(
          "awsIdentityCenterImportSetting"
        )}
        awsOrganizationSetImportSetting={config.awsOrganizationImportSetting}
        onChangeAwsOrganizationImportSetting={handleChange(
          "awsOrganizationImportSetting"
        )}
        hideAwsOrganizationImportSetting={
          awsMetadata !== null && !awsMetadata.awsOrganizationEnabled
        }
        hideAwsIdentityCenterImportSetting={
          awsMetadata !== null && !awsMetadata.awsSsoEnabled
        }
      />
    );
    if (
      config.importSetting === ImportSetting.All ||
      config.importSetting === ImportSetting.Tagged
    ) {
      importFormRows.push(
        <ConnectionImportNotificationRow
          key="importNotification"
          mode={mode}
          shouldNotify={
            config.importNotification
              ? config.importNotification!.shouldNotify
              : false
          }
          recipientOwner={
            config.importNotification?.recipientOwnerId &&
            config.importNotification?.recipientOwnerName
              ? {
                  id: config.importNotification!.recipientOwnerId,
                  name: config.importNotification!.recipientOwnerName,
                }
              : config.adminOwnerId && config.adminOwnerName
              ? {
                  id: config.adminOwnerId,
                  name: config.adminOwnerName,
                }
              : undefined
          }
          onShouldNotifyChange={handleChangeShouldNotify}
          onRecipientOwnerChange={handleChangeImportRecipientOwner}
        />
      );
    }

    if (
      hasRemoteGroupResources(connection.connectionType) &&
      connection.importSetting !== ImportSetting.All
    ) {
      importFormRows.push(
        <ConnectionAutoImportGroupResourcesRow
          key="autoImportGroupResources"
          mode={mode}
          autoImportGroupResources={Boolean(config.autoImportGroupResources)}
          onChange={handleChange("autoImportGroupResources")}
        />
      );
    }
  }
  importFormRows.push(
    <VisibilityRow
      entityType={EntityType.Connection}
      key="importVisibility"
      title="Import visibility"
      mode={mode}
      visibility={config.importVisibility}
      visibilityGroups={config.importVisibilityGroups ?? []}
      onChangeVisibilityAndGroups={(val: {
        visibility?: Visibility;
        groupIds?: string[];
      }) => {
        const { visibility, groupIds } = val;
        const visDict = visibility ? { importVisibility: visibility } : {};
        const visGroupsDict = groupIds
          ? { importVisibilityGroups: groupIds }
          : {};
        setConfig({
          ...config,
          ...visDict,
          ...visGroupsDict,
        });
      }}
    />
  );
  if (hasConfigTemplates && hasAutoImportConfigTemplate) {
    importFormRows.push(
      <ChildrenDefaultConfigTemplateRow
        key="importedChildrenConfigurationTemplate"
        mode={mode}
        configurationTemplate={config.childrenDefaultConfigTemplate}
        onChange={handleChange("childrenDefaultConfigTemplate")}
      />
    );
  }

  // Hide webhook toggle for non-native connections, unless already enabled.
  const showConnectionWebhookToggle =
    isNativeConnectionType(connection.connectionType) ||
    hasWebhookToggleFF ||
    config.webhookEnabled;

  return (
    <div
      className={sprinkles({
        padding: "md",
        display: "flex",
        flexDirection: "column",
        gap: "xl",
      })}
    >
      <FormSection title="General">
        <FormRow title="Type" disabled={mode === "edit"}>
          <Label
            label={connectionTypeInfoByType[connection.connectionType].name}
            icon={{ type: "entity", entityType: connection.connectionType }}
          />
        </FormRow>
        <DescriptionRow
          mode={mode}
          value={config.description}
          onChange={handleChange("description")}
        />
        <FormRow title="ID" disabled={mode === "edit"}>
          {
            <ClickToCopyButton
              text={connection.id}
              disableCopy={mode === "edit"}
            />
          }
        </FormRow>
        <AdminRow
          owner={
            config.adminOwnerId && config.adminOwnerName
              ? {
                  id: config.adminOwnerId,
                  name: config.adminOwnerName,
                }
              : undefined
          }
          mode={mode}
          onChange={handleChangeOwner}
        />
        {hasAppLevelVisibility && (
          <VisibilityRow
            key="visibility"
            title="App visibility"
            mode={mode}
            entityType={EntityType.Connection}
            visibility={config.visibility}
            visibilityGroups={config.visibilityGroupIds ?? []}
            onChangeVisibilityAndGroups={(val: {
              visibility?: Visibility;
              groupIds?: string[];
            }) => {
              const { visibility, groupIds } = val;
              const visDict = visibility ? { visibility } : {};
              const visGroupsDict = groupIds
                ? { visibilityGroupIds: groupIds }
                : {};
              setConfig({
                ...config,
                ...visDict,
                ...visGroupsDict,
              });
            }}
            additionalWarning={
              connection.connectionType === ConnectionType.OktaDirectory &&
              config.visibility === Visibility.Team
                ? `The visibility settings of this app do not impact the
            visibility of Okta Apps or the groups associated with them. To
            manage their visibility, please utilize the individual item
            visibility settings. This specific Okta Directory App will remain
            undiscoverable to members with access unless they possess an
            Admin role or are part of a designated visibility group.`
                : undefined
            }
          />
        )}
      </FormSection>
      {showConnectionWebhookToggle && (
        <FormSection title="Sync Details">
          <ConnectionWebhookToggleRow
            mode={mode}
            connectionType={connection.connectionType}
            webhookEnabled={Boolean(config.webhookEnabled)}
            onChange={handleChange("webhookEnabled")}
          />
          {connection.connectionType == ConnectionType.Custom ? (
            <ConnectionTicketProviderPropagationRow
              mode={mode}
              ticketProviderEnabled={Boolean(config.ticketProviderEnabled)}
              ticketProjectId={config.ticketProjectId}
              ticketProjectName={config.ticketProjectName}
              ticketProvider={config.ticketProvider}
              onChange={handleChangeTicketProvider}
            />
          ) : null}
        </FormSection>
      )}
      <FormSection title="Import Settings">{importFormRows}</FormSection>
    </div>
  );
};

export default AppOverviewColumn;
