import { getModifiedErrorMessage } from "api/ApiContext";
import {
  Maybe,
  RequestDecisionLevel,
  RequestFragment,
  RequestPreviewLargeFragment,
  RequestsDocument,
  RequestsQuery,
  RequestStatus,
  RequestType,
  useDenyRequestMutation,
} from "api/generated/graphql";
import { updateNumRequestsToReview } from "components/layout/left_sidebar/useLeftSidebarRoutes";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Button, ButtonV3, Input, Modal } from "components/ui";
import { useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { useRequestPermissions } from "views/requests/RequestOverview";

type RequestDenyButtonProps = {
  request: RequestFragment;
};

export const RequestDenyButton = (props: RequestDenyButtonProps) => {
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);
  const {
    canNormalReview,
    canAdminReview,
    targetIsCurrentUser,
  } = useRequestPermissions(props.request);

  const { displaySuccessToast, displayErrorToast } = useToast();

  const [showModal, setShowModal] = useState(false);
  const [denyComment, setDenyComment] = useState<string>("");
  const level = canNormalReview
    ? RequestDecisionLevel.Regular
    : RequestDecisionLevel.Admin;

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const [denyRequest, { loading }] = useDenyRequestMutation({
    refetchQueries: ["EventsFull", "RequestsPendingStat", "RequestStats"],
  });

  if (props.request.status !== RequestStatus.Pending) {
    return <></>;
  }

  if ((!canNormalReview && !canAdminReview) || targetIsCurrentUser) {
    return <></>;
  }
  const modalReset = () => {
    setShowModal(false);
    setErrorMessage(null);
    setDenyComment("");
  };

  const handleSubmit = async () => {
    try {
      const { data } = await denyRequest({
        variables: {
          input: {
            id: props.request.id,
            comment: denyComment.trim(),
            level: level,
          },
        },
        refetchQueries: hasV3Nav ? ["RequestsTable"] : [],
        update: (cache, { data }) => {
          switch (data?.denyRequest.__typename) {
            case "DenyRequestResult": {
              const deniedRequest = data.denyRequest.request;
              // Remove when v2 is deprecated. This behavior is only
              // valid for v2 nav, where we have a sidebar of requests.
              !hasV3Nav &&
                [RequestType.Incoming, RequestType.Outgoing].forEach(
                  (requestType) => {
                    let cachedQuery = cache.readQuery<RequestsQuery>({
                      query: RequestsDocument,
                      variables: {
                        input: {
                          requestType: requestType,
                        },
                      },
                    });
                    if (cachedQuery) {
                      let deniedRequests: RequestPreviewLargeFragment[] = cachedQuery.requests.requests.filter(
                        (request) => request.id !== deniedRequest.id
                      );
                      cache.writeQuery<RequestsQuery>({
                        query: RequestsDocument,
                        variables: {
                          input: {
                            requestType: requestType,
                          },
                        },
                        data: {
                          ...cachedQuery,
                          requests: {
                            ...cachedQuery.requests,
                            requests: deniedRequests,
                          },
                        },
                      });
                    }
                  }
                );
              updateNumRequestsToReview(cache);
            }
          }
        },
      });
      switch (data?.denyRequest.__typename) {
        case "DenyRequestResult": {
          displaySuccessToast(`Success: request denied`);
          modalReset();
          break;
        }
        case "RequestAlreadyActionedError":
        case "RequestNotFoundError":
          displayErrorToast(data.denyRequest.message);
          setErrorMessage(data.denyRequest.message);
          break;
        default:
          logError(new Error(`request denial failed`));
          displayErrorToast(`Error: request denial failed`);
          setErrorMessage("Error: request denial failed");
      }
    } catch (error) {
      logError(error, "request denial failed");
      displayErrorToast(
        getModifiedErrorMessage(`Error: request denial failed`, error)
      );
      setErrorMessage(
        getModifiedErrorMessage(`Error: request denial failed`, error)
      );
    }
  };

  const denyRequestModal = (
    <Modal isOpen={showModal} onClose={modalReset} title="Deny request">
      <Modal.Body>
        <Input
          type="textarea"
          placeholder="I am denying this request because..."
          value={denyComment}
          onChange={setDenyComment}
        />
        {errorMessage ? (
          <ModalErrorMessage errorMessage={errorMessage} />
        ) : null}
      </Modal.Body>
      <Modal.Footer
        onSecondaryButtonClick={modalReset}
        secondaryButtonLabel="Cancel"
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLoading={loading}
        primaryButtonDisabled={denyComment.trim().length === 0}
        primaryButtonLabel="Save"
      />
    </Modal>
  );

  return hasV3Nav ? (
    <>
      <ButtonV3
        label="Deny"
        type="danger"
        onClick={() => setShowModal(true)}
        leftIconName="thumbs-down"
        loading={loading}
        size="md"
      />
      {denyRequestModal}
    </>
  ) : (
    <>
      <Button
        label="Deny"
        type="error"
        onClick={() => setShowModal(true)}
        leftIconName="thumbs-down"
        loading={loading}
        size="md"
      />
      {denyRequestModal}
    </>
  );
};

export default RequestDenyButton;
