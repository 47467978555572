import {
  GroupAccessLevel,
  GroupAccessLevelFragment,
  Maybe,
  useGroupAccessLevelsQuery,
} from "api/generated/graphql";
import RequestModalRoleDropdown from "components/modals/RequestModalRoleDropdown";
import { Modal } from "components/ui";
import { useState } from "react";
import { logError } from "utils/logging";

type AccessReviewEditRoleModalProps = {
  onSubmit: (role: GroupAccessLevel) => void;
  onClose: () => void;
  currentRole: Maybe<GroupAccessLevel>;
  groupId: string;
};

const AccessReviewEditGroupRoleModal = (
  props: AccessReviewEditRoleModalProps
) => {
  const [selectedRole, setSelectedRole] = useState<
    GroupAccessLevel | undefined
  >(props.currentRole ?? undefined);

  const { data, loading, error } = useGroupAccessLevelsQuery({
    variables: {
      input: {
        groupId: props.groupId,
      },
    },
  });

  let roles: GroupAccessLevelFragment[] = [];
  if (data) {
    switch (data.groupAccessLevels.__typename) {
      case "GroupAccessLevelsResult": {
        if (data.groupAccessLevels.accessLevels) {
          roles.push(...data.groupAccessLevels.accessLevels);
        }
        break;
      }
      case "GroupNotFoundError":
        logError(new Error(`Error: failed to list group roles`));
        break;
    }
  } else if (error) {
    logError(error, `failed to list group roles`);
  }

  return (
    <Modal isOpen={true} onClose={props.onClose} title="Edit role">
      <Modal.Body>
        <RequestModalRoleDropdown
          availableRoles={roles}
          selectedRole={selectedRole}
          setSelectedRole={(role) => setSelectedRole(role as GroupAccessLevel)}
          isImpersonationResource={false}
          loading={loading}
        />
      </Modal.Body>
      <Modal.Footer
        onSecondaryButtonClick={props.onClose}
        onPrimaryButtonClick={() => {
          if (selectedRole) {
            props.onSubmit(selectedRole);
          }
        }}
        primaryButtonLabel={"Select"}
        primaryButtonDisabled={
          !selectedRole ||
          selectedRole.accessLevelRemoteId ===
            props.currentRole?.accessLevelRemoteId
        }
      />
    </Modal>
  );
};

export default AccessReviewEditGroupRoleModal;
