import {
  AccessReviewBaseFragment,
  useAccessReviewsNoStatsQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import ColumnListItem, {
  ColumnListItemsSkeleton,
} from "components/column/ColumnListItem";
import ColumnListScroller from "components/column/ColumnListScroller";
import { useToast } from "components/toast/Toast";
import { ButtonV3, Divider, Icon } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import { useHistory, useLocation } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import {
  handleRequestExport,
  UARExportType,
} from "views/access_reviews/common/Export";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { getUserAvatarIcon } from "views/users/utils";

import RegenerateAccessReviewReportButton from "./common/RegenerateAccessReviewReportButton";

interface AccessReviewData {
  id: string;
  name: string;
  startedOn: string;
  endedOn: string;
  download: string;
  reportID: string;
  startedByFullName: string;
  endedByFullName: string;
  data: {
    startedBy: {
      avatarUrl: string;
    };
    endedBy: {
      avatarUrl: string;
    };
  };
}

const ACCESS_REVIEWS_ENDED_COLUMNS: Header<AccessReviewData>[] = [
  {
    id: "name",
    label: "Name",
  },
  {
    id: "startedByFullName",
    label: "Started By",
    customCellRenderer: (row) => {
      return (
        <div className={sprinkles({ display: "flex", gap: "sm" })}>
          <div className={sprinkles({ flexShrink: 0 })}>
            <Icon
              size="sm"
              data={getUserAvatarIcon({
                avatarUrl: row.data.startedBy.avatarUrl,
              })}
            />
          </div>
          <div>{row.startedByFullName}</div>
        </div>
      );
    },
    sortable: true,
    sortingFn: (a, b) =>
      a.original.startedByFullName.localeCompare(b.original.startedByFullName),
  },
  {
    id: "startedOn",
    label: "Started On",
    sortable: true,
    width: 100,
    customCellRenderer: (row) => {
      return <div>{moment(row["startedOn"]).format("YYYY-MM-DD")}</div>;
    },
  },
  {
    id: "endedByFullName",
    label: "Ended By",
    sortable: true,
    customCellRenderer: (row) => {
      return (
        <div className={sprinkles({ display: "flex", gap: "sm" })}>
          <div className={sprinkles({ flexShrink: 0 })}>
            <Icon
              size="sm"
              data={getUserAvatarIcon({
                avatarUrl: row.data.endedBy.avatarUrl,
              })}
            />
          </div>
          <div>{row.endedByFullName}</div>
        </div>
      );
    },
  },
  {
    id: "endedOn",
    label: "Ended On",
    sortable: true,
    width: 100,
    customCellRenderer: (row) => {
      return <div>{moment(row["endedOn"]).format("YYYY-MM-DD")}</div>;
    },
  },
  {
    id: "download",
    label: "",
    sortable: false,
    customCellRenderer: (row) => {
      return (
        <DownloadButton
          id={row.id}
          name={row.name}
          stoppedDate={row.endedOn}
          reportID={row.reportID}
        />
      );
    },
  },
];

const DownloadButton = ({
  id,
  name,
  stoppedDate,
  reportID,
}: {
  id: string;
  name: string;
  stoppedDate?: string | null;
  reportID: string;
}) => {
  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();
  if (reportID == "") {
    return (
      <RegenerateAccessReviewReportButton
        label="Regenerate Report"
        isV3={true}
        accessReview={{ id, name, stoppedDate }}
        displayErrorToast={displayErrorToast}
        displayLoadingToast={displayLoadingToast}
        displaySuccessToast={displaySuccessToast}
      />
    );
  }
  return (
    <ButtonV3
      label="Download Report"
      leftIconName="download"
      onClick={(e) => {
        e.stopPropagation();
        handleRequestExport(
          { id, name, stoppedDate },
          UARExportType.Report,
          displayLoadingToast,
          displaySuccessToast,
          displayErrorToast
        );
      }}
      outline
    />
  );
};

const AccessReviewsEndedColumn = () => {
  const history = useHistory();
  const location = useLocation();

  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);
  const transitionTo = useTransitionTo();

  const { data, error, loading } = useAccessReviewsNoStatsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {},
    },
  });
  let accessReviews: AccessReviewBaseFragment[] | null = null;
  if (data) {
    switch (data.accessReviews.__typename) {
      case "AccessReviewsResult":
        accessReviews = data.accessReviews.accessReviews.filter((uar) =>
          Boolean(uar.stoppedByUser)
        );
        break;
      default:
        logError(new Error("failed to retrieve ongoing access reviews"));
    }
  } else if (error) {
    logError(error, "failed to retrieve ongoing access reviews");
  }

  if (error || (!loading && !accessReviews)) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  if (hasV3Nav) {
    return (
      <>
        <TableHeader
          entityName={"Ended Access Review"}
          totalNumRows={accessReviews?.length ?? 0}
          loading={loading && !accessReviews}
        />

        {loading && !accessReviews ? (
          <ColumnListItemsSkeleton />
        ) : (
          <Table
            rows={
              accessReviews?.map((ar) => {
                return {
                  id: ar.id,
                  name: ar.name,
                  startedByFullName: ar.startedByUser?.fullName ?? "",
                  endedByFullName: ar.stoppedByUser?.fullName ?? "",
                  startedOn: ar.createdAt ?? "",
                  endedOn: ar.stoppedDate ?? "",
                  download: "",
                  reportID: ar.reportId ?? "",
                  data: {
                    startedBy: {
                      avatarUrl: ar.startedByUser?.avatarUrl ?? "",
                    },
                    endedBy: {
                      avatarUrl: ar.stoppedByUser?.avatarUrl ?? "",
                    },
                  },
                };
              }) ?? []
            }
            totalNumRows={accessReviews?.length ?? 0}
            getRowId={(ru) => ru.name}
            columns={ACCESS_REVIEWS_ENDED_COLUMNS}
            defaultSortBy="endedOn"
            defaultSortDirection="DESC"
            onRowClick={(row, event) => {
              transitionTo(
                {
                  pathname: "/access-reviews/" + row.id,
                  search: "category=ended",
                },
                event
              );
            }}
          />
        )}
      </>
    );
  }

  const renderRow = (index: number) => {
    if (!accessReviews) return <></>;

    const accessReview = accessReviews[index];
    if (!accessReview) return <></>;

    return (
      <ColumnListItem
        key={accessReview.id}
        label={accessReview.name}
        selected={location.pathname.includes(accessReview.id)}
        onClick={() => {
          history.push({
            pathname: "/access-reviews/" + accessReview.id,
            search: "?category=ended",
          });
        }}
      />
    );
  };

  return (
    <Column>
      <ColumnHeader
        title="Ended"
        icon={{ type: "name", icon: "calendar-check" }}
        breadcrumbs={[{ name: "Access Reviews", to: "/access-reviews" }]}
        preserveQuery={false}
      />
      <Divider />
      <ColumnListScroller
        renderRow={renderRow}
        numRows={accessReviews?.length ?? 0}
        loading={loading && !accessReviews}
      />
    </Column>
  );
};

export default AccessReviewsEndedColumn;
