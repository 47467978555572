import {
  MessageChannelFragment,
  MessageChannelType,
  ThirdPartyProvider,
  useCreateRemoteMessageChannelMutation,
  useLinkExistingRemoteMessageChannelMutation,
  useSlackIntegrationWorkspacesQuery,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { Button, FormGroup, Icon, Input, Select, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useEffect, useState } from "react";
import { logError } from "utils/logging";

type CreateMessageChannelProps = {
  channelNameToCreate: string;
  adminTeamName: string;
  channelType: MessageChannelType;
  onCreateChannel: (messageChannel: MessageChannelFragment) => void;

  // For authorization
  resourceId?: string;
  groupId?: string;
};

const CreateMessageChannel = (props: CreateMessageChannelProps) => {
  const [newChannelName, setNewChannelName] = useState(
    props.channelNameToCreate
  );
  const [isPrivate, setIsPrivate] = useState(false);
  const [createdChannelName, setCreatedChannelName] = useState("");
  const [errorMessage, setErrorMessage] = useState<string>();
  const [existingRemoteChannelId, setExistingRemoteChannelId] = useState("");

  const [
    createRemoteMessageChannelMutation,
    { loading: createRemoteMessageChannelMutationLoading },
  ] = useCreateRemoteMessageChannelMutation();

  const [
    linkExistingRemoteMessageChannelMutation,
    { loading: linkExistingRemoteMessageChannelMutationLoading },
  ] = useLinkExistingRemoteMessageChannelMutation();

  const { data: slackWorkspaces } = useSlackIntegrationWorkspacesQuery();

  useEffect(() => {
    setNewChannelName(props.channelNameToCreate);
  }, [props.channelNameToCreate]);

  const [workspaceId, setWorkspaceId] = useState(
    undefined as string | undefined
  );

  const slackIntegration = slackWorkspaces?.thirdPartyIntegrations.thirdPartyIntegrations.find(
    (i) => i.thirdPartyProvider === ThirdPartyProvider.Slack
  );

  let workspaceSelector: React.ReactChild | undefined = undefined;
  if (slackIntegration?.slackMetadata?.enterpriseId) {
    workspaceSelector = (
      <div className={sprinkles({ marginBottom: "md" })}>
        <Select<{ id: string; name: string }>
          placeholder="Select workspace..."
          value={slackIntegration?.slackMetadata?.workspaces.find(
            (w) => w.id === workspaceId
          )}
          options={slackIntegration?.slackMetadata?.workspaces ?? []}
          getOptionLabel={(o) => o.name}
          onChange={(o) => {
            setWorkspaceId(o?.id);
          }}
        />
      </div>
    );
  }

  const iconName = isPrivate ? "lock" : "hash";

  if (createdChannelName) {
    return (
      <>
        <div className={sprinkles({ marginBottom: "sm" })}>
          Success!{" "}
          <span className={sprinkles({ fontWeight: "semibold" })}>
            <Icon name={iconName} size="xs" /> {createdChannelName}
          </span>{" "}
          added.
        </div>
        <Button
          label="Create or link another channel"
          onClick={() => {
            setCreatedChannelName("");
            setNewChannelName("");
            setExistingRemoteChannelId("");
          }}
          outline
        />
      </>
    );
  }

  const handleCreate = async () => {
    try {
      const { data } = await createRemoteMessageChannelMutation({
        variables: {
          input: {
            itemName: props.adminTeamName,
            customName: newChannelName,
            isPrivate: isPrivate,
            resourceId: props.resourceId,
            groupId: props.groupId,
            workspaceId: workspaceId,
          },
        },
        refetchQueries: ["MessageChannels"],
      });
      switch (data?.createRemoteMessageChannel.__typename) {
        case "CreateRemoteMessageChannelResult":
          props.onCreateChannel(data.createRemoteMessageChannel.messageChannel);
          setErrorMessage(undefined);
          setCreatedChannelName(
            data.createRemoteMessageChannel.messageChannel.name
          );
          break;
        case "MessageChannelUserNotInWorkspaceError":
        case "MessageChannelNameTakenError":
        case "MessageChannelMissingOAuthScopeError":
        case "MessageChannelInvalidNameError":
          setErrorMessage(data.createRemoteMessageChannel.message);
          break;
        default:
          logError(new Error(`failed to create Slack channel`));
          setErrorMessage("Error: failed to create Slack channel");
      }
    } catch (error) {
      logError(error, `failed to create Slack channel`);
      setErrorMessage("Error: failed to create Slack channel");
    }
  };

  const handleLinkExisting = async () => {
    try {
      const { data } = await linkExistingRemoteMessageChannelMutation({
        variables: {
          input: {
            remoteChannelId: existingRemoteChannelId,
            resourceId: props.resourceId,
            groupId: props.groupId,
          },
        },
        refetchQueries: ["MessageChannels"],
      });
      switch (data?.linkExistingRemoteMessageChannel.__typename) {
        case "LinkExistingRemoteMessageChannelResult":
          props.onCreateChannel(
            data.linkExistingRemoteMessageChannel.messageChannel
          );
          setErrorMessage(undefined);
          setCreatedChannelName(
            data.linkExistingRemoteMessageChannel.messageChannel.name
          );
          break;
        case "RemoteMessageChannelNotFound":
          setErrorMessage(data.linkExistingRemoteMessageChannel.message);
          break;
        case "MessageChannelMissingOAuthScopeError":
          setErrorMessage(data.linkExistingRemoteMessageChannel.message);
          break;
        case "RemoteMessageChannelAlreadyLinked":
          setErrorMessage(
            "Error: this channel is already linked. Select this channel from the list above."
          );
          break;
        default:
          logError(new Error(`failed to link Slack channel`));
          setErrorMessage("Error: failed to link Slack channel");
      }
    } catch (error) {
      logError(error, `failed to link Slack channel`);
      setErrorMessage("Error: failed to link Slack channel");
    }
  };

  return (
    <>
      {errorMessage ? <ModalErrorMessage errorMessage={errorMessage} /> : null}
      <div className={sprinkles({ marginBottom: "md" })}>
        <FormGroup label="Create a channel">
          {workspaceSelector}
          <Input
            value={newChannelName}
            onChange={setNewChannelName}
            leftIconName={iconName}
            placeholder="channel-name"
          />
        </FormGroup>
      </div>
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          marginBottom: "md",
        })}
      >
        <Switch
          checked={isPrivate}
          onChange={setIsPrivate}
          label="Make channel private"
        />
        <Button
          label="Create"
          onClick={handleCreate}
          loading={createRemoteMessageChannelMutationLoading}
          outline
          disabled={Boolean(
            !newChannelName ||
              (slackIntegration?.slackMetadata?.enterpriseId && !workspaceId)
          )}
        />
      </div>
      <FormGroup
        label="Link an existing channel by ID"
        infoTooltip={`This is not the channel name. Channel ID can be found on the bottom of the channel's "About" page.`}
      >
        <Input
          value={existingRemoteChannelId}
          onChange={setExistingRemoteChannelId}
          placeholder="Channel ID"
        />
      </FormGroup>
      <div
        className={sprinkles({
          display: "flex",
          justifyContent: "flex-end",
        })}
      >
        <Button
          label="Link"
          onClick={handleLinkExisting}
          loading={linkExistingRemoteMessageChannelMutationLoading}
          disabled={!existingRemoteChannelId}
          outline
        />
      </div>
    </>
  );
};

export default CreateMessageChannel;
