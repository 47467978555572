import { TagPreviewLargeFragment, useTagsQuery } from "api/generated/graphql";
import {
  DataElement,
  DataElementList,
  FormGroup,
  Modal,
  Select,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import React, { useState } from "react";
import { logError } from "utils/logging";

const PAGE_SIZE = 200;

interface Tag {
  key: string;
  value?: string | null;
}

interface Props {
  tags: Tag[];
  onChangeTags?: (newTags: Tag[]) => void;
}

const TagFilter: React.FC<Props> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalTags, setModalTags] = useState<Tag[]>([]);

  // This query feeds the list of available tags in the dropdown
  const [searchQuery, setSearchQuery] = useState("");
  let tagSearchCursor: string | null | undefined;
  const { data, error, fetchMore } = useTagsQuery({
    variables: {
      input: {
        searchQuery,
        limit: PAGE_SIZE,
        cursor: tagSearchCursor,
      },
    },
  });
  const tagSearchList = data?.tags.tags ?? [];
  tagSearchCursor = data?.tags.cursor;
  const unselectedTags = tagSearchList.filter(
    (tag) => !modalTags.find((t) => t.key === tag.key && t.value === tag.value)
  );

  if (error) {
    logError(error, `failed to list tags`);
  }

  const handleOpenModal = () => {
    setShowModal(true);
    setModalTags(props.tags);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    if (!props.onChangeTags) return;
    props.onChangeTags([...modalTags]);
    setShowModal(false);
  };

  const handleSearchInputChange = _.debounce((s: string) => {
    setSearchQuery(s);
  }, 250);

  const handleInputChange = (input: string) => {
    handleSearchInputChange(input);
  };

  if (!props.onChangeTags) {
    if (props.tags.length === 0) {
      return null;
    }
    return (
      <FormGroup
        label="Entities with tags"
        fontWeight="normal"
        fontSize="textMd"
      >
        <DataElementList>
          {props.tags.map(({ key, value }) => (
            <DataElement
              label={value ? `${key}:${value}` : key}
              color="pink"
              leftIcon={{ name: "tag" }}
            />
          ))}
        </DataElementList>
      </FormGroup>
    );
  }

  return (
    <>
      <FormGroup
        label="Entities with tags"
        fontWeight="normal"
        fontSize="textMd"
      >
        <div className={sprinkles({ marginBottom: "sm" })}>
          Add items to the access review using tags
        </div>
        <DataElementList>
          {props.tags.map(({ key, value }) => (
            <DataElement
              label={value ? `${key}:${value}` : key}
              color="pink"
              leftIcon={{ name: "tag" }}
              rightIcon={{
                name: "x",
                onClick: () => {
                  props.onChangeTags &&
                    props.onChangeTags(
                      props.tags.filter(
                        (tag) => !(tag.key === key && tag.value === value)
                      )
                    );
                },
              }}
            />
          ))}
          <DataElement
            label="Add tag"
            color="blue"
            leftIcon={{
              name: "plus",
            }}
            onClick={handleOpenModal}
          />
        </DataElementList>
      </FormGroup>
      <Modal
        isOpen={showModal}
        onClose={handleCancel}
        title="Include entities with tags"
      >
        <Modal.Body>
          <p>
            This access review will include resources and groups that are tagged
            with one of the following tags. If no tags are selected, the access
            review will only include items matching the other provided scopes.
          </p>
          <div className={sprinkles({ marginBottom: "md" })}>
            <Select<TagPreviewLargeFragment>
              options={unselectedTags}
              selectOnly
              getOptionLabel={(tag) =>
                tag.value ? `${tag.key}:${tag.value}` : tag.key
              }
              onChange={(newTag) => {
                if (!newTag) return;
                setModalTags([...modalTags, newTag]);
                // Selecting an option will briefly populate the search input with the option's label.
                // But we want to blank out the search input, so we call the debounced handler here
                handleSearchInputChange("");
              }}
              onInputChange={handleInputChange}
              placeholder="Select a tag"
              autoFocus
              disableBuiltInFiltering // Search is already handled server-side
              onScrollToBottom={async () => {
                // If user scrolls to bottom of dropdown, fetch the next batch of tags
                if (tagSearchCursor) {
                  await fetchMore({
                    variables: {
                      input: {
                        cursor: tagSearchCursor,
                        searchQuery: searchQuery,
                        limit: PAGE_SIZE,
                      },
                    },
                  });
                }
              }}
            />
          </div>
          <DataElementList>
            {modalTags.map(({ key, value }) => (
              <DataElement
                label={value ? `${key}:${value}` : key}
                color="pink"
                leftIcon={{ name: "tag" }}
                rightIcon={{
                  name: "x",
                  onClick: () => {
                    setModalTags(
                      modalTags.filter(
                        (tag) => !(tag.key === key && tag.value === value)
                      )
                    );
                  },
                }}
              />
            ))}
          </DataElementList>
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Save"
          onPrimaryButtonClick={handleSave}
          secondaryButtonLabel="Cancel"
          onSecondaryButtonClick={handleCancel}
        />
      </Modal>
    </>
  );
};

export default TagFilter;
