import { gql, useQuery } from "@apollo/client";
import {
  EntityType,
  NonHumanIdentityResourcesQuery,
  ResourcePreviewSmallFragmentDoc,
  useConnectionsQuery,
} from "api/generated/graphql";
import { ResourceLabel } from "components/label/Label";
import { useOpalSearchInput } from "components/opal/common/input/OpalSearchInput";
import OpalTable, { Columns } from "components/opal/table/OpalTable";
import { Select } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import pluralize from "pluralize";
import { getResourceUrlNew } from "utils/common";
import { nhiConnectionTypes } from "utils/directory/connections";
import { logError } from "utils/logging";
import { useURLSearchParam } from "utils/router/hooks";
import { CONNECTION_ID_URL_KEY } from "views/apps/AppsContext";
import * as styles from "views/non-human-identities/NonHumanIdentitiesColumn.css";

type Row = NonHumanIdentityResourcesQuery["resources"]["resources"][0];

const NON_HUMAN_IDENTITY_COLUMNS: Columns<Row> = [
  {
    id: "name",
    label: "Name",
    customCellRenderer: (row) => {
      return (
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
            fontWeight: "medium",
            whiteSpace: "nowrap",
          })}
        >
          <ResourceLabel
            text={row.name}
            pointerCursor={true}
            entityId={row.id}
            entityTypeNew={EntityType.Resource}
            resourceType={row.resourceType}
          />
        </div>
      );
    },
  },
  {
    id: "remoteName",
    label: "Remote Name",
    customCellRenderer: (row) => {
      return <div className={styles.nameField}>{row.remoteName}</div>;
    },
    width: 200,
  },
  {
    id: "connection",
    label: "App",
    width: 100,
    customCellRenderer: (row) => {
      if (!row.connection) {
        return "--";
      }
      return (
        <div
          onClick={(e) => e.stopPropagation()}
          className={sprinkles({
            display: "flex",
            gap: "sm",
            fontWeight: "medium",
            whiteSpace: "nowrap",
          })}
        >
          <ResourceLabel
            text={row.connection.name}
            pointerCursor={true}
            entityId={row.connection.id}
            entityTypeNew={EntityType.Connection}
            connectionType={row.connection.connectionType}
          />
        </div>
      );
    },
  },
  {
    id: "resourceCount",
    label: "Resources",
    width: 75,
    customCellRenderer: (row) => {
      return (
        <div
          className={sprinkles({
            color:
              row.numEntityAssignmentsForPrincipal > 0 ? undefined : "gray600",
          })}
        >
          {`${row.numEntityAssignmentsForPrincipal || "No"} ${pluralize(
            "Resource",
            row.numEntityAssignmentsForPrincipal
          )}`}
        </div>
      );
    },
  },
  {
    id: "createdAt",
    label: "Created",
    width: 75,
    customCellRenderer: (row) => {
      return <div>{moment(row.createdAt).fromNow()}</div>;
    },
  },
];

const NonHumanIdentitiesColumn = () => {
  const [searchQuery, searchInput] = useOpalSearchInput({
    placeholder: "Filter by name",
  });

  const [connectionIdParam, setConnectionIdParam] = useURLSearchParam(
    CONNECTION_ID_URL_KEY
  );

  const {
    data: resourcesData,
    error: resourcesError,
    networkStatus: resourcesNetworkStatus,
    fetchMore,
  } = useQuery(
    gql`
      query InventoryNHIs(
        $searchQuery: String
        $connectionIds: [ConnectionId!]
        $cursor: String
      ) {
        resources(
          input: {
            cursor: $cursor
            searchQuery: $searchQuery
            connectionIds: $connectionIds
            nonHumanIdentitiesOnly: true
          }
        ) {
          __typename
          ... on ResourcesResult {
            resources {
              ...ResourcePreviewSmall
              numEntityAssignmentsForPrincipal
            }
            cursor
            totalNumResources
          }
        }
      }
      ${ResourcePreviewSmallFragmentDoc}
    `,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        searchQuery: searchQuery,
        nonHumanIdentitiesOnly: true,
        connectionId: connectionIdParam ? [connectionIdParam] : undefined,
      },
    }
  );

  const {
    data: connectionsData,
    error: connectionsError,
  } = useConnectionsQuery({
    variables: {
      input: {
        connectionTypes: Array.from(nhiConnectionTypes),
      },
    },
  });

  if (resourcesError) {
    logError(
      resourcesError,
      `failed to list resources home: non-human identities due to failure loading resources`
    );
  }

  if (connectionsError) {
    logError(
      connectionsError,
      `failed to list resources home: non-human identities due to failure loading connections`
    );
  }

  const connections = connectionsData?.connections?.connections ?? [];
  const selectedConnection = connections?.find(
    (connection) => connection.id === connectionIdParam
  );

  const resources = resourcesData?.resources.resources ?? [];
  const cursor = resourcesData?.resources.cursor;
  const totalNumResources = resourcesData?.resources.totalNumResources ?? 0;

  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            cursor,
          },
        });
      }
    : undefined;

  return (
    <OpalTable
      rows={resources}
      filters={
        <>
          {searchInput}
          <Select
            options={connections}
            onChange={(connection) =>
              setConnectionIdParam(connection?.id ?? null)
            }
            getOptionLabel={(connection) => connection.name}
            getIcon={(connection) => ({
              type: "entity",
              entityType: connection.connectionType,
            })}
            placeholder="App"
            value={selectedConnection}
            clearable
            popperForceDownward
            size="sm"
          />
        </>
      }
      totalNumRows={totalNumResources}
      getRowId={(ru) => ru.id}
      columns={NON_HUMAN_IDENTITY_COLUMNS}
      onLoadMoreRows={loadMoreRows}
      onRowClickTransitionTo={(row) =>
        getResourceUrlNew({
          entityId: row.id,
          entityType: EntityType.Resource,
        })
      }
      entityName="Non-human Identity"
      networkStatus={resourcesNetworkStatus}
    />
  );
};

export default NonHumanIdentitiesColumn;
