import { openInNewTab } from "api/common/common";
import GroupBindingDetailPopover from "components/group_bindings/GroupBindingDetailPopover";
import { EntityIcon, Label, Loader } from "components/ui";
import {
  BASE_NODE_HEIGHT,
  BASE_NODE_WIDTH,
  GroupNodeData,
  ICON_HEIGHT,
  ICON_WIDTH,
  NODE_PADDING,
  SPACING,
  TEXT_SPACING,
} from "components/viz/common";
import {
  useFilterDispatch,
  useFilterState,
} from "components/viz/contexts/FilterContext";
import NodeMenu from "components/viz/menus/NodeMenu";
import Node from "components/viz/nodes/Node";
import React, { useContext } from "react";
import useLogEvent from "utils/analytics";

import { GraphContext } from "../contexts/GraphContext";
import * as styles from "./GroupNode.css";

interface GroupNodeProps {
  data: GroupNodeData;
  onClick: (nodeId: string, groupId?: string) => void;
  onCmdClick?: () => void;
}

const GroupNode = ({ data, onClick, onCmdClick }: GroupNodeProps) => {
  const filterDispatch = useFilterDispatch();
  const filterState = useFilterState();
  const { graphDispatch } = useContext(GraphContext);
  const logEvent = useLogEvent();

  const setLoading = React.useCallback(
    (loading: boolean) => {
      graphDispatch({
        type: "SET_LOADING",
        payload: {
          loading,
        },
      });
    },
    [graphDispatch]
  );

  const nodeMenuOptions: PropsFor<typeof NodeMenu>["options"] = [];
  if (data.isManaged) {
    nodeMenuOptions.push({
      label: "View details",
      onClick: () => {
        data.url && openInNewTab(data.url);
        logEvent({
          name: "viz_view_details_click",
          properties: {
            entityName: data.name ?? "",
            nodeID: data.nodeId,
            entityType: data.kind,
          },
        });
      },
      icon: { type: "name", icon: "link-external" },
    });
  }

  const selectedGroupIds = filterState.selection.groupIds ?? [];
  const numGroupTypes = filterState.selection.multiGroupSelections?.length ?? 0;
  if (
    numGroupTypes > 0 ||
    selectedGroupIds.length !== 1 ||
    !selectedGroupIds.includes(data.nodeId)
  ) {
    nodeMenuOptions.push({
      label: "View relations",
      onClick: () => {
        setLoading(true);
        filterDispatch({
          type: "ADD_GROUP",
          payload: {
            id: data.nodeId,
            clearFilter: true,
          },
        });
        logEvent({
          name: "viz_view_relations_click",
          properties: {
            entityName: data.name ?? "",
            nodeID: data.nodeId,
            entityType: data.kind,
          },
        });
      },
      icon: { type: "name", icon: "eye" },
    });
  }

  const groupBindingIconOffset = data.groupBinding ? ICON_WIDTH : 0;

  return (
    <Node
      data={data}
      onClick={() => {
        onClick(data.nodeId, data.groupId);

        graphDispatch({
          type: "TOGGLE_SELECT_GROUP",
          payload: {
            groupId: data.nodeId,
          },
        });
      }}
      onCmdClick={onCmdClick}
    >
      <foreignObject
        width={ICON_WIDTH + 10}
        height={ICON_HEIGHT}
        y={BASE_NODE_HEIGHT / 2 - ICON_HEIGHT / 2}
        x={NODE_PADDING + groupBindingIconOffset}
        className={styles.iconGroup}
      >
        <EntityIcon type={data.groupType} />
      </foreignObject>
      {data.loading ? (
        <foreignObject
          width={ICON_WIDTH}
          height={ICON_HEIGHT}
          x={
            BASE_NODE_WIDTH - SPACING.xl - NODE_PADDING + groupBindingIconOffset
          }
          y={BASE_NODE_HEIGHT / 2 - ICON_HEIGHT / 2}
        >
          <Loader size="md" />
        </foreignObject>
      ) : null}
      {data.groupBinding ? (
        <foreignObject
          width={ICON_WIDTH}
          height={ICON_HEIGHT}
          x={BASE_NODE_HEIGHT / 2 - ICON_WIDTH / 2}
          y={BASE_NODE_HEIGHT / 2 - ICON_HEIGHT / 3}
        >
          <GroupBindingDetailPopover
            groupId={data.nodeId}
            groupBinding={data.groupBinding}
          />
        </foreignObject>
      ) : null}
      <foreignObject
        className={styles.label}
        x={groupBindingIconOffset + NODE_PADDING + ICON_WIDTH + SPACING.xs}
        y={BASE_NODE_HEIGHT / 2 - 10}
        height={TEXT_SPACING}
        width={
          BASE_NODE_WIDTH -
          (ICON_WIDTH + NODE_PADDING * 3) -
          groupBindingIconOffset
        }
      >
        <Label label={data.name} oneLine />
      </foreignObject>
      <foreignObject
        width={ICON_WIDTH}
        height={ICON_HEIGHT}
        x={BASE_NODE_WIDTH - ICON_WIDTH - NODE_PADDING}
        y={BASE_NODE_HEIGHT / 2 - ICON_HEIGHT / 2}
      >
        <NodeMenu options={nodeMenuOptions} />
      </foreignObject>
      {data.showLinkAbove && (
        <foreignObject
          height={NODE_PADDING}
          width={2}
          x={NODE_PADDING + ICON_WIDTH / 4}
          y={-NODE_PADDING}
        >
          <div className={styles.groupBindingLink} />
        </foreignObject>
      )}
    </Node>
  );
};

export default GroupNode;
