import {
  EntityType,
  useListConfigurationsTemplatesQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import ColumnListItem from "components/column/ColumnListItem";
import ColumnListScroller from "components/column/ColumnListScroller";
import { Divider } from "components/ui";
import { useContext } from "react";
import { useHistory, useParams } from "react-router";
import { getResourceUrlNew } from "utils/common";

const ConfigurationTemplatesColumn = () => {
  const { data } = useListConfigurationsTemplatesQuery();
  const { configurationTemplateId } = useParams<{
    configurationTemplateId: string;
  }>();
  const history = useHistory();
  const { authState } = useContext(AuthContext);

  const templates = data?.configurationTemplates?.configurationTemplates ?? [];

  const addMenuOptions: PropsFor<typeof ColumnHeader>["addMenuOptions"] = [];
  if (authState.user?.isAdmin) {
    addMenuOptions.push({
      label: "Create template",
      onClick: () => history.push("/templates/configurations/create"),
      icon: { type: "name", icon: "plus" },
    });
  }

  return (
    <>
      <Column>
        <ColumnHeader
          title="Configuration Templates"
          icon={{ type: "name", icon: "template" }}
          breadcrumbs={[{ name: "Templates", to: "/templates" }]}
          addMenuOptions={addMenuOptions}
        />
        <Divider />
        <ColumnListItem
          key="template-mapping"
          label="Template Mapping"
          onClick={() => history.push("/templates/configurations/mapping")}
        />
        <Divider />
        <ColumnListScroller
          emptyState={{
            title: "No templates",
            subtitle: authState.user?.isAdmin
              ? "Click the + button in the top right to add."
              : undefined,
          }}
          numRows={templates.length}
          renderRow={(i) => {
            const template = templates[i];
            if (!template) {
              return <></>;
            }
            return (
              <ColumnListItem
                key={template.id}
                label={template.name}
                selected={template.id === configurationTemplateId}
                onClick={() => {
                  history.push(
                    getResourceUrlNew({
                      entityId: template.id,
                      entityType: EntityType.ConfigurationTemplate,
                    })
                  );
                }}
              />
            );
          }}
        />
      </Column>
    </>
  );
};

export default ConfigurationTemplatesColumn;
