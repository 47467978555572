import errorMessageLabelFixedStyles from "components/label/ErrorMessageLabelFixed.module.scss";
import Label from "components/label/Label";
import messageLabelStyles from "components/label/MessageLabel.module.scss";
import React from "react";
import * as Icons from "react-feather";

type ErrorMessageLabelFixedProps = {
  errorMessage: string;
};

export const ErrorMessageLabelFixed = (props: ErrorMessageLabelFixedProps) => {
  return (
    <span className={errorMessageLabelFixedStyles.visibilityLabel}>
      <Label
        text={props.errorMessage}
        icon={<Icons.AlertTriangle size={16} strokeWidth={3} />}
        additionalStyleClass={messageLabelStyles.errorMessage}
      />
    </span>
  );
};

export default ErrorMessageLabelFixed;
