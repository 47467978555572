import {
  AccessReviewConnectionUserFragment,
  AccessReviewFragment,
  AccessReviewGroupUserFragment,
  AccessReviewItemOutcome,
  AccessReviewResourceUserFragment,
  useAccessReviewRevocationsQuery,
} from "api/generated/graphql";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import React from "react";
import * as Icons from "react-feather";
import { logError } from "utils/logging";
import AccessReviewRevocationsTable from "views/access_reviews/revocations/AccessReviewRevocationsTable";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ViewSkeleton from "views/loading/ViewSkeleton";

export type AccessReviewRevocationsProps = {
  accessReview: AccessReviewFragment;
};

const AccessReviewRevocations = (props: AccessReviewRevocationsProps) => {
  const { data, error, loading } = useAccessReviewRevocationsQuery({
    variables: {
      input: {
        accessReviewId: props.accessReview.id,
      },
    },
  });

  let accessReviewResourceUsers: AccessReviewResourceUserFragment[] = [];
  if (data?.accessReviewResourceUsers) {
    switch (data.accessReviewResourceUsers.__typename) {
      case "AccessReviewResourceUsersResult":
        accessReviewResourceUsers =
          data.accessReviewResourceUsers.accessReviewResourceUsers || [];
        break;
      default:
        logError(new Error(`failed to retrieve access_review_resource_users`));
    }
  }

  let accessReviewGroupUsers: AccessReviewGroupUserFragment[] = [];
  if (data?.accessReviewGroupUsers) {
    switch (data.accessReviewGroupUsers.__typename) {
      case "AccessReviewGroupUsersResult":
        accessReviewGroupUsers =
          data.accessReviewGroupUsers.accessReviewGroupUsers || [];
        break;
      default:
        logError(new Error(`failed to retrieve access_review_group_users`));
    }
  }

  let accessReviewConnectionUsers: AccessReviewConnectionUserFragment[] = [];
  if (data?.accessReviewConnectionUsers) {
    switch (data.accessReviewConnectionUsers.__typename) {
      case "AccessReviewConnectionUsersResult":
        accessReviewConnectionUsers =
          data.accessReviewConnectionUsers.accessReviewConnectionUsers || [];
        break;
      default:
        logError(
          new Error(`failed to retrieve access_review_connection_users`)
        );
    }
  }

  if (error) {
    logError(
      error,
      `failed to retrieve access_review_resource_users and access_review_group_users`
    );
    return <UnexpectedErrorPage error={error} />;
  }

  if (loading) {
    return <ViewSkeleton />;
  }

  const filteredResourceUsers = accessReviewResourceUsers.filter(
    (accessReviewResourceUser) => {
      return (
        accessReviewResourceUser.statusAndOutcome.outcome ===
          AccessReviewItemOutcome.Revoked ||
        accessReviewResourceUser.statusAndOutcome.outcome ===
          AccessReviewItemOutcome.Updated
      );
    }
  );

  const filteredGroupUsers = accessReviewGroupUsers.filter(
    (accessReviewGroupUser) => {
      return (
        accessReviewGroupUser.statusAndOutcome.outcome ===
        AccessReviewItemOutcome.Revoked
      );
    }
  );

  const filteredConnectionUsers = accessReviewConnectionUsers.filter(
    (accessReviewConnectionUser) => {
      return (
        accessReviewConnectionUser.statusAndOutcome.outcome ===
        AccessReviewItemOutcome.Revoked
      );
    }
  );

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <EmptyStateContentWrapper
        content={
          <AccessReviewRevocationsTable
            accessReviewId={props.accessReview.id}
            accessReviewResourceUsers={filteredResourceUsers}
            accessReviewGroupUsers={filteredGroupUsers}
            accessReviewConnectionUsers={filteredConnectionUsers}
          />
        }
        icon={<Icons.Repeat />}
        title={`No access changes to display`}
        subtitle={`There have not been any access changes in this access review.`}
        isEmpty={
          filteredResourceUsers.length === 0 &&
          filteredGroupUsers.length === 0 &&
          filteredConnectionUsers.length === 0
        }
      />
    </div>
  );
};

export default AccessReviewRevocations;
