import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AuthType,
  ConnectionType,
  Maybe,
  useCreateConnectionMutation,
  Visibility,
} from "api/generated/graphql";
import snowflakeLogo from "assets/logos/snowflake-logo.svg";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { Banner, Button, FormGroup, Input, Link } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import {
  CreateConnectionComponents as CreateConnectionComponentsV2,
  CreateConnectionView as CreateConnectionViewV2,
} from "views/connections/create/CreateConnectionComponents";
import {
  CreateConnectionComponentsV3,
  CreateConnectionViewV3,
} from "views/connections/create/CreateConnectionComponentsV3";
import { NotFoundPage } from "views/error/ErrorCodePage";

const CreateSnowflake = () => {
  const isSnowflakeEnabled = useFeatureFlag(
    FeatureFlag.EnableSnowflakeConnectionType
  );
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  if (!isSnowflakeEnabled) {
    return <NotFoundPage />;
  }

  return hasV3 ? (
    <CreateSnowflakeForm />
  ) : (
    <div className={sprinkles({ display: "flex", width: "100%" })}>
      <CreateSnowflakeForm />
    </div>
  );
};

const CreateSnowflakeForm = () => {
  const history = useHistory();
  const logEvent = useLogEvent();
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [organization, setOrganization] = useState("");
  const [account, setAccount] = useState("");
  const [locator, setLocator] = useState("");
  const [accountIdentifier, setAccountIdentifier] = useState("");
  const [accountUrl, setAccountUrl] = useState("");
  const [serviceAccountName, setServiceAccountName] = useState("OPALADMIN");
  const [serviceAccountRole, setServiceAccountRole] = useState("OPALSERVICE");
  const [privateKey, setPrivateKey] = useState("");
  const [publicKey, setPublicKey] = useState("");
  const [privateKeyPassword, setPrivateKeyPassword] = useState("");

  const [adminOwnerId, setAdminOwnerId] = useState<string | undefined>(
    undefined
  );

  const [createConnectionMutation, { loading }] = useCreateConnectionMutation({
    refetchQueries: ["AppsListColumn", "Connections"],
  });

  const fieldUnset =
    name?.trim() === "" ||
    description?.trim() === "" ||
    !adminOwnerId ||
    organization?.trim() === "" ||
    account?.trim() === "" ||
    locator?.trim() === "" ||
    accountIdentifier?.trim() === "" ||
    accountUrl?.trim() === "" ||
    publicKey?.trim() === "" ||
    privateKey?.trim() === "" ||
    privateKeyPassword?.trim() === "";

  const onSubmit = async () => {
    try {
      const { data } = await createConnectionMutation({
        variables: {
          input: {
            name: name?.trim(),
            description: description?.trim(),
            connectionType: ConnectionType.Snowflake,
            adminOwnerId: adminOwnerId ?? "",
            visibility: Visibility.Global,
            visibilityGroupIds: [],
            importVisibility: Visibility.Global,
            metadata: {
              connectionType: ConnectionType.Snowflake,
              snowflake: {
                organization: organization?.trim(),
                account: account?.trim(),
                locator: locator?.trim(),
                accountIdentifier: accountIdentifier?.trim(),
                accountUrl: accountUrl?.trim(),
                serviceAccountName: serviceAccountName?.trim(),
                serviceAccountRole: serviceAccountRole?.trim(),
              },
            },
            credentials: {
              authType: AuthType.Snowflake,
              snowflake: {
                publicKey: publicKey,
                privateKey: privateKey,
                privateKeyPassword: privateKeyPassword,
              },
            },
          },
        },
      });
      switch (data?.createConnection.__typename) {
        case "CreateConnectionResult":
          history.replace(
            getResourceUrl(
              EntityTypeDeprecated.Connection,
              data.createConnection.connection.id
            )
          );
          displaySuccessToast(`Success: Snowflake app created`);
          logEvent({
            name: "apps_create_click",
            properties: {
              connectionType: ConnectionType.Snowflake,
            },
          });

          break;
        case "ConnectionExistsError":
        case "ConnectionBadMetadataError":
        case "UserFacingError":
          logWarning(new Error(data.createConnection.message));
          setErrorMessage(data.createConnection.message);
          break;
        default:
          logError(new Error(`app creation failed`));
          setErrorMessage(`Error: app creation failed`);
      }
    } catch (error) {
      logError(error, "app creation failed");
      setErrorMessage(
        getModifiedErrorMessage("Error: app creation failed", error)
      );
    }
  };

  const CreateConnectionComponents = hasV3
    ? CreateConnectionComponentsV3
    : CreateConnectionComponentsV2;
  const CreateConnectionView = hasV3
    ? CreateConnectionViewV3
    : CreateConnectionViewV2;

  return (
    <CreateConnectionView
      title={"Add your Snowflake account"}
      logo={snowflakeLogo}
      onSubmit={onSubmit}
      submitDisabled={fieldUnset}
      submitLoading={loading}
    >
      <>
        <Banner
          message="There is a limit to 10,000 users, roles, databases, schemas, and tables each. If you would like to increase this, please reach out to support@opal.dev"
          type="info"
        />
        <CreateConnectionComponents
          title="Step 1"
          subtitle="Create Opal service account user"
        >
          <>
            <p>
              Opal requires a service account user to administer Snowflake on
              your behalf.
            </p>
            <p>
              To learn more about setting up a service account user,{" "}
              <Link
                url={"https://docs.opal.dev/docs/snowflake"}
                target="_blank"
                external
              >
                check out our official documentation here
              </Link>
              .
            </p>
          </>
        </CreateConnectionComponents>
        <CreateConnectionComponents
          title={"Step 2"}
          subtitle={"Create Snowflake application"}
        >
          <>
            <FormGroup label="App name:">
              <Input
                onChange={setName}
                placeholder="Identifiable name of the Snowflake account."
                value={name}
              />
            </FormGroup>
            <FormGroup label="App admin:">
              <OwnerDropdown
                selectedOwnerId={adminOwnerId}
                onSelectOwner={(owner) => setAdminOwnerId(owner?.id)}
                placeholder="Select an owner to own this app."
              />
            </FormGroup>
            <FormGroup label="Description:">
              <Input
                onChange={setDescription}
                placeholder="A brief description of the account to further inform people requesting access to it."
                value={description}
              />
            </FormGroup>
            <FormGroup label="Organization:">
              <p>
                For help finding your organization name, please see{" "}
                <Link
                  url="https://docs.snowflake.com/en/user-guide/organizations-gs#viewing-the-name-of-your-organization-and-its-accounts"
                  target="_blank"
                  external
                >
                  Snowflake's documentation
                </Link>
                .
              </p>
              <Input
                onChange={setOrganization}
                placeholder="The organization name."
                value={organization}
              />
            </FormGroup>
            <FormGroup label="Account:">
              <p>
                For help finding your account name, please see{" "}
                <Link
                  url="https://docs.snowflake.com/en/user-guide/organizations-gs#viewing-the-name-of-your-organization-and-its-accounts"
                  target="_blank"
                  external
                >
                  Snowflake's documentation
                </Link>
                .
              </p>
              <Input
                onChange={setAccount}
                placeholder="The name of the account."
                value={account}
              />
            </FormGroup>
            <FormGroup label="Locator:">
              <p>
                For help finding your locator, please see{" "}
                <Link
                  url="https://docs.snowflake.com/en/user-guide/admin-account-identifier#using-an-account-locator-as-an-identifier"
                  target="_blank"
                  external
                >
                  Snowflake's documentation
                </Link>
                .
              </p>
              <Input
                onChange={setLocator}
                placeholder="The account locator (e.g., xy12345)."
                value={locator}
              />
            </FormGroup>
            <FormGroup label="Account identifier:">
              <p>
                For help finding your account identifier, please see{" "}
                <Link
                  url="https://docs.snowflake.com/en/user-guide/admin-account-identifier"
                  target="_blank"
                  external
                >
                  Snowflake's documentation
                </Link>
                .
              </p>
              <Input
                onChange={setAccountIdentifier}
                placeholder="The identifier for the account (e.g. <orgname>-<account name>)."
                value={accountIdentifier}
              />
            </FormGroup>
            <FormGroup label="Account URL:">
              <Input
                onChange={setAccountUrl}
                placeholder="The unique URL for the account (e.g. <orgname>-<account name>.snowflakecomputing.com)."
                value={accountUrl}
              />
            </FormGroup>
            <FormGroup label="Service account name:">
              <Input
                onChange={setServiceAccountName}
                placeholder="The service account Opal Security will use to manage Snowflake (e.g., OPAL_SECURITY_ADMIN)."
                value={serviceAccountName}
              />
            </FormGroup>
            <FormGroup label="Service account role:">
              <Input
                onChange={setServiceAccountRole}
                placeholder="The service account role Opal Security will use to manage Snowflake (e.g., OPAL_SECURITY_SERVICE)."
                value={serviceAccountRole}
              />
            </FormGroup>
            <FormGroup label="Public key:">
              <Input
                onChange={setPublicKey}
                placeholder="-----BEGIN PUBLIC KEY-----"
                value={publicKey}
                type="textarea"
              />
            </FormGroup>
            <FormGroup label="Private key:">
              <Input
                onChange={setPrivateKey}
                placeholder="-----BEGIN ENCRYPTED PRIVATE KEY-----"
                value={privateKey}
                type="textarea"
              />
            </FormGroup>
            <FormGroup label="Private key password:">
              <Input
                onChange={setPrivateKeyPassword}
                placeholder="The password for decrypting the private key."
                type="password"
                value={privateKeyPassword}
              />
            </FormGroup>
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
            <div
              className={sprinkles({
                display: "flex",
                justifyContent: "flex-end",
              })}
            >
              {!hasV3 && (
                <Button
                  type="primary"
                  disabled={fieldUnset}
                  label={"Create"}
                  loading={loading}
                  onClick={onSubmit}
                />
              )}
            </div>
          </>
        </CreateConnectionComponents>
      </>
    </CreateConnectionView>
  );
};

export default CreateSnowflake;
