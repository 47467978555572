import {
  AccessReviewItemOutcome,
  AccessReviewItemStatus,
  AccessReviewSummaryStatus,
  ConnectionPreviewSmallFragment,
  ConnectionUserPropagationStatusFragment,
  EntityType,
  GroupPreviewSmallFragment,
  GroupPropagationStatusFragment,
  GroupResourcePropagationStatusFragment,
  Maybe,
  PropagationStatusCode,
  ResourcePreviewSmallFragment,
  ResourcePropagationStatusFragment,
  UserPreviewSmallFragment,
} from "api/generated/graphql";
import Label from "components/label/Label";
import labelStyles from "components/label/Label.module.scss";
import AccessReviewWarningsModal, {
  AccessReviewUserWarningFragment,
} from "components/modals/AccessReviewWarningsModal";
import PropagationStatusLabelWithModal from "components/propagation/PropagationStatusLabelWithModal";
import { Icon, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import React, { ReactElement, useState } from "react";
import { entityTypeToString } from "utils/entity";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { PropagatedEntityInfo, PropagationType } from "utils/useRemediations";

export const accessReviewItemStatusSortValue = (
  status: AccessReviewItemStatus
): number => {
  switch (status) {
    case AccessReviewItemStatus.NeedsAttention:
      return 1;
    case AccessReviewItemStatus.NeedsEndSystemRevocation:
      return 2;
    case AccessReviewItemStatus.NeedsUpdateRequestApproval:
      return 3;
    case AccessReviewItemStatus.PartiallyCompleted:
      return 4;
    case AccessReviewItemStatus.NotStarted:
      return 5;
    case AccessReviewItemStatus.Completed:
      return 6;
    case AccessReviewItemStatus.NoReviewNeeded:
      return 7;
  }
};

export const accessReviewSummaryStatusSortValue = (
  status: AccessReviewSummaryStatus
): number => {
  switch (status) {
    case AccessReviewSummaryStatus.NeedsAttention:
      return 1;
    case AccessReviewSummaryStatus.NeedsEndSystemRevocation:
      return 2;
    case AccessReviewSummaryStatus.NeedsUpdateRequestApproval:
      return 3;
    case AccessReviewSummaryStatus.PartiallyCompleted:
      return 4;
    case AccessReviewSummaryStatus.NotStarted:
      return 5;
    case AccessReviewSummaryStatus.Completed:
      return 6;
    case AccessReviewSummaryStatus.NoReviewNeeded:
      return 7;
  }
};

type AccessReviewOutcomeLabelProps = {
  outcome: AccessReviewItemOutcome;
  entityType: EntityType;
};

export const AccessReviewOutcomeLabel = (
  props: AccessReviewOutcomeLabelProps
) => {
  let text = "--";
  let icon: Maybe<ReactElement> | undefined = undefined;
  let toolTipPhrase = "";
  const entity = _.lowerCase(entityTypeToString(props.entityType));
  let color: Parameters<typeof sprinkles>[0]["color"] = "black";

  switch (props.outcome) {
    case AccessReviewItemOutcome.Accepted:
      text = "Approved";
      color = "green1000";
      icon = <Icon name="check-circle" color="green600" size="xs" />;
      toolTipPhrase = `Access for this ${entity} has been accepted by all reviewers.`;
      break;
    case AccessReviewItemOutcome.Updated:
      text = "Updated";
      color = "blue1000";
      icon = <Icon name="arrow-switch-3" color="blue600" size="xs" />;
      toolTipPhrase = `Access for this ${entity} has been updated.`;
      break;
    case AccessReviewItemOutcome.Revoked:
      text = "Revoked";
      color = "red1000";
      icon = <Icon name="x-circle" color="red600" size="xs" />;
      toolTipPhrase = `Access for this ${entity} has been revoked.`;
      break;
  }

  const label = (
    <span
      className={sprinkles({
        display: "flex",
        gap: "xs",
        alignItems: "center",
        color,
      })}
    >
      {icon}
      {text}
    </span>
  );

  return toolTipPhrase ? (
    <Tooltip tooltipText={toolTipPhrase}>{label}</Tooltip>
  ) : (
    label
  );
};

type AccessReviewStatusLabelProps = {
  status: AccessReviewItemStatus | AccessReviewSummaryStatus;
  entityType: EntityType;
  warnings?: Maybe<AccessReviewUserWarningFragment[]>;
  resource?: Maybe<ResourcePreviewSmallFragment>;
  group?: Maybe<GroupPreviewSmallFragment>;
  connection?: Maybe<ConnectionPreviewSmallFragment>;
  user?: Maybe<UserPreviewSmallFragment>;
  accessReviewId: string;
  canEditReviewers?: boolean;
};

export const AccessReviewStatusLabel = (
  props: AccessReviewStatusLabelProps
) => {
  const [showModal, setShowModal] = useState(false);
  const hasNHIs = useFeatureFlag(FeatureFlag.NonHumanIdentities);

  let text = "--";
  let icon: Maybe<ReactElement> | undefined;
  let toolTipPhrase = "";
  let onClick: (() => void) | undefined;
  const entity = _.lowerCase(entityTypeToString(props.entityType));
  let color: Parameters<typeof sprinkles>[0]["color"] = "black";

  switch (props.status) {
    case AccessReviewSummaryStatus.NoReviewNeeded:
      text = "No Review Needed";
      icon = <Icon name="check-circle" color="gray600" size="xs" />;
      if (!hasNHIs) {
        toolTipPhrase = `Access to this ${entity} does not need to be reviewed since there are no users with direct access to the ${entity}.`;
        if (props.entityType === EntityType.Group) {
          toolTipPhrase = `Access to this ${entity} does not need to be reviewed since the ${entity} has no members and does not grant access to any resources.`;
        }
      } else {
        toolTipPhrase = `Access to this ${entity} does not need to be reviewed since it was deleted or there are no principals with direct access.`;
        if (props.entityType === EntityType.Group) {
          toolTipPhrase = `Access to this ${entity} does not need to be reviewed since it was deleted or the ${entity} has no members and does not grant access to any resources.`;
        }
      }
      break;
    case AccessReviewSummaryStatus.Completed:
    case AccessReviewItemStatus.Completed:
      text = "Complete";
      color = "green1000";
      icon = <Icon name="check-circle" color="green600" size="xs" />;
      toolTipPhrase = `Access to this ${entity} has been reviewed by all reviewers.`;
      break;
    case AccessReviewSummaryStatus.NotStarted:
    case AccessReviewItemStatus.NotStarted:
      text = "Not Started";
      icon = <Icon name="minus-circle" color="gray600" size="xs" />;
      toolTipPhrase = `Access to this ${entity} has not been reviewed yet.`;
      break;
    case AccessReviewSummaryStatus.PartiallyCompleted:
    case AccessReviewItemStatus.PartiallyCompleted:
      text = "Partially Complete";
      color = "yellow1000";
      icon = <Icon name="incomplete" color="yellow600" size="xs" />;
      toolTipPhrase = `Access to this ${entity} has been reviewed by some reviewers.`;
      break;
    case AccessReviewSummaryStatus.NeedsUpdateRequestApproval:
    case AccessReviewItemStatus.NeedsUpdateRequestApproval:
      text = "Awaiting Update";
      color = "yellow1000";
      icon = <Icon name="alert-circle" color="yellow700" size="xs" />;
      toolTipPhrase = `Access to this ${entity} was updated in this access review, but is awaiting approval of the request that facilitates the update.`;
      break;
    case AccessReviewSummaryStatus.NeedsEndSystemRevocation:
    case AccessReviewItemStatus.NeedsEndSystemRevocation:
      text = "Awaiting Revocation";
      color = "yellow1000";
      icon = <Icon name="alert-circle" color="yellow700" size="xs" />;
      toolTipPhrase = `Access to this ${entity} was revoked in this access review, but is awaiting manual revocation on the end system.`;
      break;
    case AccessReviewSummaryStatus.NeedsAttention:
    case AccessReviewItemStatus.NeedsAttention:
      text = "Has Warnings";
      color = "yellow1000";
      icon = <Icon name="alert-circle" color="yellow700" size="xs" />;
      toolTipPhrase = `Access to this ${entity} has warnings.`;
      onClick = () => setShowModal(!showModal);
      break;
  }

  const label = (
    <span
      className={sprinkles({
        display: "flex",
        gap: "xs",
        alignItems: "center",
        color,
      })}
      onClick={onClick}
    >
      {icon}
      {text}
    </span>
  );

  return (
    <div>
      {showModal ? (
        label
      ) : (
        <Tooltip tooltipText={toolTipPhrase}>{label}</Tooltip>
      )}
      {showModal && (
        <AccessReviewWarningsModal
          accessReviewId={props.accessReviewId}
          showModal={showModal}
          onClose={() => setShowModal(false)}
          warnings={props.warnings}
          resource={props.resource}
          group={props.group}
          connection={props.connection}
          user={props.user}
          canEditReviewers={props.canEditReviewers}
        />
      )}
    </div>
  );
};

type AccessReviewRevocationStatusLabelProps = {
  status: AccessReviewItemStatus;
  outcome: AccessReviewItemOutcome;
  entityType: EntityType;
  entityInfo: PropagatedEntityInfo;
  propagationType: PropagationType;
  propagationStatus?: Maybe<
    | ResourcePropagationStatusFragment
    | GroupPropagationStatusFragment
    | GroupResourcePropagationStatusFragment
    | ConnectionUserPropagationStatusFragment
  >;
};

export const AccessReviewRevocationStatusLabel = (
  props: AccessReviewRevocationStatusLabelProps
) => {
  if (
    (props.outcome !== AccessReviewItemOutcome.Revoked &&
      props.outcome !== AccessReviewItemOutcome.Updated) ||
    !props.entityInfo.accessReviewId
  ) {
    return <>--</>;
  }

  if (
    props.status === AccessReviewItemStatus.NeedsUpdateRequestApproval ||
    props.status === AccessReviewItemStatus.NeedsEndSystemRevocation
  ) {
    return (
      <AccessReviewStatusLabel
        status={props.status}
        entityType={props.entityType}
        accessReviewId={props.entityInfo.accessReviewId}
      />
    );
  }

  if (
    props.propagationStatus &&
    props.propagationStatus.statusCode !== null &&
    props.propagationStatus.statusCode !== PropagationStatusCode.Success
  ) {
    const text =
      props.outcome === AccessReviewItemOutcome.Updated
        ? "Updating"
        : "Revoking";

    return (
      <div className={sprinkles({ display: "flex" })}>
        <PropagationStatusLabelWithModal
          propagationType={props.propagationType}
          isAccessReview={true}
          entityInfo={props.entityInfo}
          propagationStatus={props.propagationStatus}
        />
        <span className={sprinkles({ marginLeft: "xs" })}>
          {props.propagationStatus.statusCode ===
          PropagationStatusCode.Pending ? (
            <Label
              text={text}
              additionalStyleClass={labelStyles.statusWarning}
            />
          ) : (
            <Label
              text={"Error"}
              additionalStyleClass={labelStyles.statusError}
            />
          )}
        </span>
      </div>
    );
  }

  return (
    <AccessReviewOutcomeLabel
      outcome={props.outcome}
      entityType={props.entityType}
    />
  );
};
