import {
  ConnectionPreviewLargeFragment,
  ConnectionType,
  ResourceType,
} from "api/generated/graphql";
import { isConnectionTypeResourcesProvider } from "utils/directory/connections";
import { isNativeConnectionType } from "utils/directory/resources";
import { resourceTypesByConnectionType } from "utils/directory/services";

export type ResourceCreationInfo = {
  connection?: ConnectionPreviewLargeFragment;
  isCsvImport?: boolean;
  resourceType?: ResourceType;
  name?: string;
  adminOwner?: {
    id: string;
    name: string;
  };
  description?: string;
  remoteId?: string;
};

export const hasResourceCreation = (connectionType: ConnectionType) =>
  (isNativeConnectionType(connectionType) &&
    connectionType !== ConnectionType.Opal) ||
  (!isNativeConnectionType(connectionType) &&
    isConnectionTypeResourcesProvider(connectionType));

export const hasCreationMethodCards = (connectionType?: ConnectionType) =>
  connectionType === ConnectionType.Custom;

export const hasServiceTypes = (connectionType?: ConnectionType) =>
  Boolean(
    connectionType &&
      !isNativeConnectionType(connectionType) &&
      isConnectionTypeResourcesProvider(connectionType)
  );

// Returns which resource types for a given connection type should be explicitly
// importable.
export const getImportableResourceTypes = (
  connectionType: ConnectionType
): ResourceType[] => {
  let resourceTypes = resourceTypesByConnectionType[connectionType];

  switch (connectionType) {
    case ConnectionType.AwsSso:
      // Disable importing non-account resources for now, as they are contained within accounts.
      resourceTypes = resourceTypes.filter(
        (resourceType) => resourceType == ResourceType.AwsAccount
      );
      break;
    default:
      break;
  }

  return resourceTypes;
};

// If the connection type only has one importable type, return it.
// Otherwise, return undefined.
export const getSingleImportableResourceType = (
  connectionType: ConnectionType
): ResourceType | undefined => {
  const resourceTypes = getImportableResourceTypes(connectionType);
  return resourceTypes.length === 1 ? resourceTypes[0] : undefined;
};

export const hasRequiredRemoteId = (
  connectionType: ConnectionType
): boolean => {
  switch (connectionType) {
    case ConnectionType.Postgres:
      return true;
    default:
      return false;
  }
};

export const remoteIdLabelByConnectionType = (
  connectionType?: ConnectionType
): string => {
  switch (connectionType) {
    case ConnectionType.Postgres:
      return "Database name";
    default:
      return "Remote ID";
  }
};
