import { PillV3 } from "components/pills/PillsV3";
import { DataElementList, Icon, RadioGroup } from "components/ui";
import { useState } from "react";

import * as styles from "./ActionSidebarSection.css";

interface Props {
  removeGroup: boolean;
  groups: string[];
  onChange: (shouldRemove: boolean) => void;
}

const optionLabels: Record<"true" | "false", string> = {
  true: "Yes, remove them",
  false: "No, leave unchanged",
};

const RemoveGroupSection = (props: Props) => {
  const [expanded, setExpanded] = useState(false);
  if (props.groups.length === 0) {
    return null;
  }

  return (
    <div className={styles.actionContainer}>
      <div
        className={styles.actionHeader({ expanded })}
        onClick={() => setExpanded((prev) => !prev)}
      >
        <Icon name="users-minus" size="xs" />
        <span className={styles.actionTitle}>Remove from group</span>
        <Icon name={expanded ? "chevron-up" : "chevron-down"} size="xs" />
      </div>
      {expanded ? (
        <div className={styles.actionContent}>
          <DataElementList>
            {props.groups.map((group) => (
              <PillV3
                key={group}
                keyText={group}
                pillColor="Teal"
                icon={{
                  type: "name",
                  icon: "users",
                }}
              />
            ))}
          </DataElementList>
          <div className={styles.description}>
            Revoke users from these groups?
          </div>
          <RadioGroup
            value={props.removeGroup}
            options={[true, false]}
            getOptionKey={(opt) => opt}
            getOptionLabel={(opt) => optionLabels[opt ? "true" : "false"]}
            onSelectValue={(opt) => props.onChange(opt)}
          />
        </div>
      ) : null}
    </div>
  );
};

export default RemoveGroupSection;
