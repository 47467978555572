import type {
  GroupAccessLevel,
  ResourceAccessLevel,
} from "api/generated/graphql";
import sprinkles from "css/sprinkles.css";

import * as styles from "./RequestedItemCard.css";

type Props = {
  children?: JSX.Element;
  accessLevel: ResourceAccessLevel | GroupAccessLevel | undefined;
};

const RequestedItemCard = (props: Props) => {
  const accessLevel =
    props.accessLevel && props.accessLevel.accessLevelName != ""
      ? props.accessLevel.accessLevelName
      : null;
  return (
    <div className={styles.requestedItemCard}>
      {props.children}

      {accessLevel ? (
        <div className={sprinkles({ marginTop: "lg" })}>
          Requested role: "{accessLevel}"
        </div>
      ) : null}
    </div>
  );
};

export default RequestedItemCard;
