import React from "react";
import * as Icons from "react-feather";
import { useHistory } from "react-router-dom";
import styles from "views/connections/create/CreateConnectionComponents.module.scss";

type CreateConnectionViewProps = {
  logo: string;
  title: string;
  children: React.ReactElement;
  backUrl?: string;
};
export const CreateConnectionView = (props: CreateConnectionViewProps) => {
  const history = useHistory();
  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <div className={styles.backButtonRow}>
          <div
            className={styles.backButton}
            onClick={() => {
              const backUrl = props.backUrl || "/apps/browse";
              history.push(backUrl);
            }}
          >
            <Icons.ChevronLeft strokeWidth={3} size={18} />
            <span className={styles.cancelText}>Cancel</span>
          </div>
        </div>
        <div className={styles.viewHeader}>
          <img className={styles.logo} alt="logo" src={props.logo} />
          <span>{props.title}</span>
        </div>
      </div>
      <div className={styles.viewContent}>{props.children}</div>
    </div>
  );
};

type CreateConnectionPanelProps = {
  title: string;
  subtitle: string;
  children: React.ReactElement;
};

export const CreateConnectionComponents = (
  props: CreateConnectionPanelProps
) => {
  return (
    <div className={styles.connectionPanel}>
      <div className={styles.title}>{props.title}</div>
      <div className={styles.subtitle}>{props.subtitle}</div>
      {props.children}
    </div>
  );
};
