import {
  ConnectionType,
  EntityType,
  RequestMessageCode,
  RequestMessageFragment,
  RequestMessageLevel,
  ResourceType,
  useAccessRequestGroupCardQuery,
  useAccessRequestOktaAppCardQuery,
  useAccessRequestResourceCardQuery,
  useResourceAccessLevelsQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import {
  getGroupTypeInfo,
  GroupTypeInfo,
} from "components/label/GroupTypeLabel";
import {
  getResourceTypeInfo,
  ResourceTypeInfo,
} from "components/label/ResourceTypeLabel";
import { GroupDetailsModal } from "components/modals/enduser_exp/GroupDetailsModal";
import { ResourceDetailsModal } from "components/modals/enduser_exp/ResourceDetailsModal";
import RequestModalRoleDropdown from "components/modals/RequestModalRoleDropdown";
import {
  ContextMenu,
  FormGroup,
  Icon,
  Input,
  Label,
  Select,
  Skeleton,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import pluralize from "pluralize";
import {
  MouseEventHandler,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { getItemRequiresRoles } from "utils/access_requests";
import {
  OPAL_GLOBAL_IMPERSONATION_REMOTE_ID,
  OPAL_IMPERSONATION_REMOTE_ID,
} from "utils/constants";
import { resourceTypeCanBeAccessed } from "utils/directory/resources";
import { formatResourceBreadcrumb } from "utils/resources";
import {
  AccessRequestEntity,
  Role,
} from "views/access_request/AccessRequestContext";
import { isGroupBindingRedirectRequired } from "views/group_bindings/common";
import {
  getExtraParamsByConnectionMetadata,
  getRequestMessageFromCode,
} from "views/requests/utils";

import * as styles from "./AccessRequestEntityCard.css";

type BaseProps = {
  id: string;
  targetUserId?: string;
  onRemoveEntity: (entity: AccessRequestEntity) => void;
  messages?: RequestMessageFragment[];
};

// This is used for regular groups or resources that may or may not have requestable roles
type RoleEntityProps = {
  selectedRoles?: Role[];
  setSelectedRoles: (roles: Role[], isUserAction?: boolean) => void;

  setIsRoleRequired: (isRoleRequired: boolean) => void;
};

type GroupProps = BaseProps &
  RoleEntityProps & {
    onAddEntity: (entity: AccessRequestEntity) => void;
  };

export const AccessRequestGroupCard: React.FC<GroupProps> = (props) => {
  const { authState } = useContext(AuthContext);
  const { id, selectedRoles, setSelectedRoles, setIsRoleRequired } = props;
  const [showGroupInfoModal, setShowGroupInfoModal] = useState(false);

  const { data, error, loading } = useAccessRequestGroupCardQuery({
    variables: {
      id,
      targetUserId: props.targetUserId ?? "",
    },
  });

  const group =
    data?.group.__typename === "GroupResult" ? data.group.group : undefined;

  const expirationByRoleRemoteId: PropsFor<
    typeof RequestModalRoleDropdown
  >["expirationByRoleRemoteId"] = {};

  const remoteId =
    group?.currentUserAccess.groupUser?.accessLevel?.accessLevelRemoteId;
  const expiration =
    group?.currentUserAccess.groupUser?.access?.directAccessPoint?.expiration;

  if (remoteId && expiration) {
    expirationByRoleRemoteId[remoteId] = moment(expiration);
  }

  const numAccessResources = group?.groupResources.length ?? 0;
  const numAccessGroups = group?.containingGroups.length ?? 0;
  let accessMessage = "";
  if (numAccessResources > 0) {
    accessMessage += `${numAccessResources} ${pluralize(
      "item",
      numAccessResources
    )}`;
  }
  if (numAccessGroups > 0) {
    accessMessage += `${
      numAccessResources > 0 ? " and " : ""
    } ${numAccessGroups} ${pluralize("group", numAccessGroups)}`;
  }

  const isSelfRequest = props.targetUserId === authState?.user?.user.id;
  const hasAccess = Boolean(
    group?.currentUserAccess.groupUser?.access?.directAccessPoint
  );
  const willResetAccess = isSelfRequest && hasAccess;

  const availableRoles =
    data?.groupAccessLevels.__typename === "GroupAccessLevelsResult"
      ? data?.groupAccessLevels.accessLevels ?? []
      : undefined;

  const includeRoles = group
    ? getItemRequiresRoles(group, availableRoles)
    : false;

  // Every group is by default a source group, unless they're not
  const isRequestable = group
    ? resourceTypeCanBeAccessed(group.groupType) &&
      !isGroupBindingRedirectRequired(group)
    : false;

  useEffect(() => {
    if (
      includeRoles &&
      (selectedRoles ?? []).length === 0 &&
      availableRoles?.length === 1
    ) {
      setSelectedRoles(availableRoles);
    }

    if (includeRoles) {
      setIsRoleRequired(includeRoles);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableRoles, includeRoles, selectedRoles]);

  const sourceGroupRedirect = useCallback(() => {
    if (group && isGroupBindingRedirectRequired(group)) {
      props.onRemoveEntity({ id, type: EntityType.Group });
      props.onAddEntity({
        id: group.groupBinding!.sourceGroupId,
        type: EntityType.Group,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group, id]);

  if (error || data?.group.__typename === "GroupNotFoundError") {
    return (
      <AccessRequestBaseEntityCard
        name="Group not found"
        error
        onRemoveClick={() =>
          props.onRemoveEntity({ id, type: EntityType.Group })
        }
        entityType={EntityType.Group}
      />
    );
  }

  const messages: PropsFor<typeof AccessRequestBaseEntityCard>["messages"] =
    props.messages?.map((message) => {
      return {
        code: message.code,
        level: message.level,
        message: getRequestMessageFromCode(message.code, isSelfRequest, {
          connectionName: group?.connection?.name,
          connectionType: group?.connection?.connectionType,
          extraParams: getExtraParamsByConnectionMetadata(
            group?.connection?.metadata ?? undefined
          ),
          sourceGroup: group?.groupBinding?.sourceGroup
            ? group?.groupBinding?.sourceGroup
            : undefined,
          sourceGroupRedirect,
        }),
      };
    }) ?? [];

  if (willResetAccess) {
    // workaround until we get all the messages in requests defaults
    // TODO: Move this check and message to the backend
    messages.push({
      code: RequestMessageCode.RequestResetsAccessDuration,
      level: RequestMessageLevel.Info,
      message: "This request will reset existing access duration.",
    });
  }

  return (
    <>
      <AccessRequestBaseEntityCard
        name={group?.name ?? ""}
        typeInfo={getGroupTypeInfo(group?.groupType)}
        onRemoveClick={() =>
          props.onRemoveEntity({ id, type: EntityType.Group })
        }
        breadcrumb={
          group?.connection ? `${group?.connection?.name}/` : undefined
        }
        entityType={EntityType.Group}
        loading={loading || !data}
        isRequestable={isRequestable}
        messages={messages}
        onMoreInfoClick={
          group
            ? (event) => {
                event.stopPropagation();
                setShowGroupInfoModal(true);
              }
            : undefined
        }
      >
        {includeRoles && (
          <FormGroup label="Select a role" required compact fontSize="textXs">
            <RequestModalRoleDropdown
              id={"group-role-select"}
              selectedRole={props.selectedRoles?.[0]}
              isImpersonationResource={false}
              setSelectedRole={(role) =>
                role && props.setSelectedRoles([role], true)
              }
              loading={loading}
              availableRoles={availableRoles ?? []}
              expirationByRoleRemoteId={
                isSelfRequest ? expirationByRoleRemoteId : undefined
              }
            />
          </FormGroup>
        )}
        {accessMessage.length > 0 && (
          <div
            className={sprinkles({ fontSize: "textXs", fontWeight: "bold" })}
          >
            This group gives you access to {accessMessage}
          </div>
        )}
      </AccessRequestBaseEntityCard>
      {showGroupInfoModal && group && (
        <GroupDetailsModal
          groupId={group.id}
          showModal={showGroupInfoModal}
          closeModal={() => setShowGroupInfoModal(false)}
          showButtons={false}
        />
      )}
    </>
  );
};

type ResourceProps = BaseProps & RoleEntityProps;

export const AccessRequestResourceCard: React.FC<ResourceProps> = (props) => {
  const { authState } = useContext(AuthContext);
  const { id, selectedRoles, setSelectedRoles, setIsRoleRequired } = props;
  const [showResourceInfoModal, setShowResourceInfoModal] = useState(false);
  const [availableRoleDropdowns, setAvailableRoleDropdowns] = useState<number>(
    0
  );

  const { data, error, loading } = useAccessRequestResourceCardQuery({
    variables: {
      id,
    },
  });

  const resource =
    data?.resource.__typename === "ResourceResult"
      ? data?.resource.resource
      : undefined;
  const isOktaApp = resource?.resourceType === ResourceType.OktaApp;

  // Unfortunately, due to some access levels are obtained (especially in the
  // case of GCP), we can't bundle this query with the one above, otherwise it
  // might take too long to show anything to the user while the roles queries
  // completes.
  const {
    data: rolesData,
    error: rolesError,
    loading: rolesLoading,
  } = useResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceId: id,
        onlyRequestableTargetUser: props.targetUserId,
      },
    },
  });

  const isImpersonationResource =
    resource?.remoteId === OPAL_IMPERSONATION_REMOTE_ID;
  const isGlobalImpersonationResource =
    resource?.remoteId === OPAL_GLOBAL_IMPERSONATION_REMOTE_ID;

  const availableRoles =
    rolesData?.accessLevels.__typename === "ResourceAccessLevelsResult"
      ? rolesData.accessLevels.accessLevels
      : undefined;

  const includeRoles = resource
    ? getItemRequiresRoles(resource, availableRoles)
    : false;

  const remainingRoles = availableRoles?.filter(
    (role) =>
      !selectedRoles?.some(
        (selectedRole) =>
          selectedRole.accessLevelRemoteId === role.accessLevelRemoteId
      )
  );

  const isSelfRequest = props.targetUserId === authState?.user?.user.id;

  const isRequestable = resource
    ? resourceTypeCanBeAccessed(resource.resourceType)
    : false;

  const expirationByRoleRemoteId: PropsFor<
    typeof RequestModalRoleDropdown
  >["expirationByRoleRemoteId"] = resource?.currentUserAccess.resourceUsers.reduce(
    (result, resourceUser) => {
      if (!result) result = {};
      if (resourceUser.access?.directAccessPoint) {
        result[resourceUser.accessLevel.accessLevelRemoteId] = resourceUser
          .access.directAccessPoint.expiration
          ? moment(resourceUser.access.directAccessPoint.expiration)
          : null;
      }
      return result;
    },
    {} as PropsFor<typeof RequestModalRoleDropdown>["expirationByRoleRemoteId"]
  );

  const hasAccess =
    !includeRoles && !(resource?.currentUserAccess.resourceUsers.length === 0)
      ? Boolean(
          resource?.currentUserAccess.resourceUsers[0].access?.directAccessPoint
        )
      : false;
  const willResetAccess = isSelfRequest && hasAccess;
  const willResetAccessFor =
    selectedRoles?.filter(
      (role) =>
        isSelfRequest &&
        expirationByRoleRemoteId?.[role.accessLevelRemoteId] !== undefined
    ) ?? [];

  useEffect(() => {
    if (
      includeRoles &&
      (selectedRoles ?? []).length === 0 &&
      availableRoles?.length === 1
    ) {
      setSelectedRoles(availableRoles);
    }

    if (includeRoles) {
      setIsRoleRequired(includeRoles);
      if (availableRoleDropdowns < (selectedRoles?.length ?? 1))
        setAvailableRoleDropdowns(selectedRoles?.length ?? 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableRoles, includeRoles, selectedRoles]);

  if (error || data?.resource.__typename === "ResourceNotFoundError") {
    return (
      <AccessRequestBaseEntityCard
        name="Resource not found"
        error
        onRemoveClick={() =>
          props.onRemoveEntity({ id, type: EntityType.Resource })
        }
        entityType={EntityType.Resource}
      />
    );
  }

  const canMultipleRolesBeRequested =
    includeRoles &&
    (remainingRoles ?? []).length > 0 &&
    !isGlobalImpersonationResource &&
    !isImpersonationResource;

  const messages: PropsFor<typeof AccessRequestBaseEntityCard>["messages"] =
    props.messages?.map((message) => {
      return {
        code: message.code,
        level: message.level,
        message: getRequestMessageFromCode(message.code, isSelfRequest, {
          connectionName: resource?.connection?.name,
          connectionType: resource?.connection?.connectionType,
          extraParams: getExtraParamsByConnectionMetadata(
            resource?.connection?.metadata ?? undefined
          ),
        }),
      };
    }) ?? [];

  if (!includeRoles && willResetAccess) {
    // workaround until we get all the messages in requests defaults
    // TODO: Move this check and message to the backend
    messages.push({
      code: RequestMessageCode.RequestResetsAccessDuration,
      level: RequestMessageLevel.Info,
      message: "This request will reset existing access duration.",
    });
  } else if (willResetAccessFor.length > 0) {
    // workaround until we get all the messages in requests defaults
    // TODO: Move this check and message to the backend
    messages.push({
      code: RequestMessageCode.RequestResetsAccessDuration,
      level: RequestMessageLevel.Info,
      message: `This request will reset existing access duration for ${willResetAccessFor
        .map((role) => role.accessLevelName)
        .join(", ")}.`,
    });
  }

  return (
    <AccessRequestBaseEntityCard
      name={resource?.name ?? ""}
      typeInfo={getResourceTypeInfo(resource?.resourceType)}
      onRemoveClick={() =>
        props.onRemoveEntity({ id, type: EntityType.Resource })
      }
      breadcrumb={
        !isOktaApp
          ? formatResourceBreadcrumb(resource?.ancestorPathToResource, 80)
          : undefined
      }
      entityType={EntityType.Resource}
      loading={loading || !data}
      messages={messages}
      isRequestable={isRequestable}
      onMoreInfoClick={
        resource
          ? (event) => {
              event.stopPropagation();
              setShowResourceInfoModal(true);
            }
          : undefined
      }
      menuOptions={
        canMultipleRolesBeRequested
          ? [
              {
                label: "Add another role",
                onClick: () => setAvailableRoleDropdowns((prev) => prev + 1),
                disabled: (selectedRoles?.length ?? 0) < availableRoleDropdowns,
              },
            ]
          : undefined
      }
    >
      {showResourceInfoModal && resource && (
        <ResourceDetailsModal
          resourceId={resource.id}
          showModal={showResourceInfoModal}
          closeModal={() => setShowResourceInfoModal(false)}
          showButtons={false}
        />
      )}
      {includeRoles && !isGlobalImpersonationResource && (
        <>
          {rolesError && (
            <Label label="Failed to load roles" color="red500V3" />
          )}
          {Array.from({ length: availableRoleDropdowns }).map((_, index) => {
            const currentRole = selectedRoles?.[index];
            const availableRolesForThisDropdown =
              availableRoles?.filter(
                (role) =>
                  role.accessLevelRemoteId ==
                    currentRole?.accessLevelRemoteId ||
                  !selectedRoles?.some(
                    (selectedRole) =>
                      selectedRole.accessLevelRemoteId ===
                      role.accessLevelRemoteId
                  )
              ) ?? [];
            return (
              <FormGroup
                key={`role-dropdown-${currentRole?.accessLevelRemoteId}-${index}`}
                marginSize="sm"
                label="Select a role"
                required
                compact
                fontSize="textXs"
              >
                <div
                  className={sprinkles({
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    gap: "sm",
                  })}
                >
                  <div className={sprinkles({ flexGrow: 1 })}>
                    <RequestModalRoleDropdown
                      id={"resource-role-select"}
                      isImpersonationResource={isImpersonationResource}
                      selectedRole={currentRole}
                      setSelectedRole={(role) => {
                        if (role) {
                          const updatedRoles = selectedRoles?.slice() ?? [];
                          if (
                            updatedRoles[index]?.accessLevelRemoteId !==
                            role.accessLevelRemoteId
                          ) {
                            updatedRoles[index] = role;
                          }
                          setSelectedRoles(updatedRoles, true);
                        }
                      }}
                      loading={rolesLoading || !rolesData}
                      availableRoles={availableRolesForThisDropdown}
                      expirationByRoleRemoteId={
                        isSelfRequest ? expirationByRoleRemoteId : undefined
                      }
                    />
                  </div>
                  {availableRoleDropdowns > 1 && (
                    <Icon
                      onClick={() => {
                        if (selectedRoles)
                          setSelectedRoles(
                            selectedRoles.filter((_, i) => i !== index)
                          );
                        setAvailableRoleDropdowns((count) =>
                          Math.max(count - 1, 1)
                        );
                      }}
                      name="x"
                    />
                  )}
                </div>
              </FormGroup>
            );
          })}
        </>
      )}
      {includeRoles && isGlobalImpersonationResource && (
        <>
          <FormGroup
            label="User Name"
            required
            compact
            fontSize="textXs"
            marginSize="sm"
          >
            <Input
              placeholder="Enter the Name of the user to impersonate"
              value={props.selectedRoles?.[0].accessLevelName}
              onChange={(value) => {
                const selectedRole = props.selectedRoles?.[0] ?? {
                  accessLevelRemoteId: "",
                  accessLevelName: "",
                };
                setSelectedRoles(
                  [{ ...selectedRole, accessLevelName: value }],
                  true
                );
              }}
            />
          </FormGroup>
          <FormGroup
            label="User ID"
            required
            compact
            fontSize="textXs"
            marginSize="sm"
          >
            <Input
              placeholder="Enter ID of user to impersonate"
              value={props.selectedRoles?.[0].accessLevelRemoteId}
              onChange={(value) => {
                const selectedRole = props.selectedRoles?.[0] ?? {
                  accessLevelRemoteId: "",
                  accessLevelName: "",
                };
                setSelectedRoles(
                  [{ ...selectedRole, accessLevelRemoteId: value }],
                  true
                );
              }}
            />
          </FormGroup>
        </>
      )}
    </AccessRequestBaseEntityCard>
  );
};

// This is used for Okta apps that can embed requesting another group or even the app itself, if requestable
type AppEntityProps = {
  selectedRoleEntity: AccessRequestEntity | undefined;
  onSelectRoleEntity: (
    entity: AccessRequestEntity | undefined,
    isUserAction?: boolean
  ) => void;
  onError: (error: "app-not-found" | "roles-not-found") => void;
};

type OktaAppProps = BaseProps & AppEntityProps;

type RequestableOktaAppItem = {
  key: string;
  resource?: {
    id: string;
    name: string;
  } | null;
  group?: {
    id: string;
    name: string;
  } | null;
};

export const AccessRequestOktaAppCard: React.FC<OktaAppProps> = (props) => {
  const { id, selectedRoleEntity, onSelectRoleEntity } = props;
  const { authState } = useContext(AuthContext);
  const [showResourceInfoModal, setShowResourceInfoModal] = useState(false);

  // TODO: pass the target user ID to the query to load the correct roles
  const { data, error, loading } = useAccessRequestOktaAppCardQuery({
    variables: {
      id: id,
    },
  });

  const app = data?.app.__typename === "App" ? data.app : undefined;
  const resource =
    app?.app.__typename === "OktaResourceApp" ? app.app.resource : undefined;
  const includeDirectApp = resource ? resource?.isRequestable : false;
  const items: RequestableOktaAppItem[] = app?.items.items
    ? [...app?.items.items]
    : [];

  if (includeDirectApp && resource) {
    // If the app itself is requestable, we're going to add it at the end of the dropdown
    // This is to have backward compatibility with Okta App being requestable resources.
    items.push({
      key: resource.id,
      resource: {
        id: resource.id,
        name: "No role",
      },
    });
  }

  useEffect(() => {
    if (loading) return;
    // If we haven't selected a role (either a group or the direct access) for
    // this Okta app, we're going to automatically default the user to the
    // first available role, if only one is available. Otherwise the user will
    // have to make their own choice.
    if (selectedRoleEntity == null && items.length === 1) {
      if (items[0].resource) {
        onSelectRoleEntity({
          id: items[0].resource.id,
          type: EntityType.Resource,
        });
      } else if (items[0].group) {
        onSelectRoleEntity({
          id: items[0].group.id,
          type: EntityType.Group,
        });
      }
    } else if (items.length === 0) {
      props.onError("roles-not-found");
    } else if (data?.app.__typename === "AppNotFoundError") {
      props.onError("app-not-found");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, items, selectedRoleEntity, loading]);

  if (error || data?.app.__typename === "AppNotFoundError") {
    return (
      <AccessRequestBaseEntityCard
        name="Okta app not found"
        error
        onRemoveClick={() =>
          props.onRemoveEntity({ id, type: ResourceType.OktaApp })
        }
        entityType={EntityType.Resource}
      />
    );
  }

  if (loading) {
    return (
      <Skeleton width="100%" height="40px" variant="rect" marginBottom="mdlg" />
    );
  }

  const isSelfRequest = props.targetUserId === authState?.user?.user.id;
  const expirationByItemId: PropsFor<
    typeof RequestModalRoleDropdown
  >["expirationByRoleRemoteId"] = resource?.currentUserAccess.resourceUsers.reduce(
    // Collect for the various roles, either direct resource user membership or
    // Okta group membership (we don't check if it's an actual Okta group,
    // since worst case it just won't show up in this dropdown), if the user
    // has already access to any of them, so that we can show if access is
    // getting reset.
    (result, resourceUser) => {
      if (!result) result = {};
      if (!isSelfRequest) return result;
      if (resourceUser.access?.directAccessPoint) {
        const accessPoint = resourceUser.access.directAccessPoint;
        result[accessPoint.resourceId] = accessPoint.expiration
          ? moment(accessPoint.expiration)
          : null;
      }
      if (resourceUser.access?.groupUserAccesses) {
        for (const groupUserAccess of resourceUser.access.groupUserAccesses) {
          if (groupUserAccess.directAccessPoint) {
            const accessPoint = groupUserAccess.directAccessPoint;
            result[accessPoint.groupId] = accessPoint.expiration
              ? moment(accessPoint.expiration)
              : null;
          }
        }
      }
      return result;
    },
    {} as PropsFor<typeof RequestModalRoleDropdown>["expirationByRoleRemoteId"]
  );
  const willResetAccess =
    isSelfRequest &&
    selectedRoleEntity &&
    expirationByItemId?.[selectedRoleEntity.id] !== undefined;

  const selectedRole =
    items.find((item) => item.key === selectedRoleEntity?.id) ?? undefined;
  const noRequestableRoles = items.length === 0 && !loading;
  const messages: PropsFor<typeof AccessRequestBaseEntityCard>["messages"] =
    props.messages?.map((message) => {
      return {
        code: message.code,
        level: message.level,
        message: getRequestMessageFromCode(message.code, isSelfRequest, {
          connectionName: resource?.connection?.name,
          connectionType: ConnectionType.OktaDirectory,
        }),
      };
    }) ?? [];
  if (willResetAccess) {
    // workaround until we get all the messages in requests defaults
    // TODO: Move this check and message to the backend
    messages.push({
      code: RequestMessageCode.RequestResetsAccessDuration,
      level: RequestMessageLevel.Info,
      message: "This request will reset existing access duration.",
    });
  }

  return (
    <AccessRequestBaseEntityCard
      name={app?.name ?? ""}
      typeInfo={getResourceTypeInfo(ResourceType.OktaApp)}
      loading={loading}
      error={Boolean(error) || noRequestableRoles}
      entityType={EntityType.Resource}
      onRemoveClick={() =>
        props.onRemoveEntity({ id, type: ResourceType.OktaApp })
      }
      onMoreInfoClick={
        app
          ? (event) => {
              event.stopPropagation();
              setShowResourceInfoModal(true);
            }
          : undefined
      }
      messages={messages}
    >
      {showResourceInfoModal && app && (
        <ResourceDetailsModal
          resourceId={app.id}
          showModal={showResourceInfoModal}
          closeModal={() => setShowResourceInfoModal(false)}
          showButtons={false}
        />
      )}
      <FormGroup label="Select a role" required compact fontSize="textXs">
        <Select
          loading={loading}
          disabled={noRequestableRoles}
          id={"app-role-select"}
          value={selectedRole}
          getOptionLabel={(option) =>
            option.group?.name ?? option.resource?.name ?? ""
          }
          getOptionSelected={(option, value) => option.key === value.key}
          getOptionSublabel={(option) => {
            const expiration = expirationByItemId?.[option.key];
            if (expiration) return `Expires ${expiration.fromNow()}`;
            else if (expiration === null) return "Unlimited access";
            return "";
          }}
          placeholder="Select a role"
          options={items}
          onChange={(option) => {
            if (option?.group?.id) {
              props.onSelectRoleEntity(
                {
                  id: option.group.id,
                  type: EntityType.Group,
                },
                true
              );
            } else if (option?.resource?.id) {
              props.onSelectRoleEntity(
                {
                  id: option.resource.id,
                  type: EntityType.Resource,
                },
                true
              );
            }
          }}
        />
      </FormGroup>
      {noRequestableRoles && (
        <Label
          color="red700V3"
          icon={{ type: "name", icon: "alert-circle" }}
          iconSize="xs"
          label="No available roles to request for this app"
        />
      )}
    </AccessRequestBaseEntityCard>
  );
};

type BaseCardProps = {
  name: string;
  typeInfo?: GroupTypeInfo | ResourceTypeInfo | null;
  entityType: EntityType.Group | EntityType.Resource;
  onRemoveClick: MouseEventHandler | undefined;
  error?: boolean;
  loading?: boolean;
  breadcrumb?: string;
  onMoreInfoClick?: MouseEventHandler | undefined;
  isRequestable?: boolean;
  menuOptions?: PropsFor<typeof ContextMenu>["options"];
  messages?: {
    code: RequestMessageCode;
    level: RequestMessageLevel;
    message: React.ReactNode;
  }[];
  children?: React.ReactNode;
};

const AccessRequestBaseEntityCard: React.FC<BaseCardProps> = (props) => {
  let { error = false, isRequestable = true } = props;
  let content: React.ReactNode | undefined = undefined;

  if (props.loading) {
    content = <Skeleton width="100%" height="40px" variant="rect" />;
  } else if (!isRequestable) {
    error = true;
    content = (
      <>
        <div className={sprinkles({ fontWeight: "bold", fontSize: "bodyMd" })}>
          {props.name} is not requestable
        </div>
      </>
    );
  } else {
    content = props.children;
  }

  error =
    props.messages?.some(
      (message) => message.level === RequestMessageLevel.Error
    ) || error;

  return (
    <div className={styles.container({ error })}>
      <div className={styles.header}>
        <div className={sprinkles({ display: "flex", width: "100%" })}>
          <div className={styles.title}>
            {props.loading ? (
              <Skeleton width="120px" height="24px" variant="rect" />
            ) : (
              props.name
            )}
          </div>
          <div className={styles.rightAligned}>
            <Label
              label={props.typeInfo?.name ?? ""}
              icon={
                props.typeInfo?.iconName
                  ? {
                      type: "name",
                      icon: props.typeInfo.iconName,
                    }
                  : undefined
              }
              iconSize="xs"
              entityType={props.entityType}
              inline
              color="gray800"
            />
            {props.menuOptions && (
              <ContextMenu
                renderButton={(onClick) => (
                  <Icon name="dots-horizontal" onClick={onClick} size="md" />
                )}
                rightAligned
                horizontalDots
                options={props.menuOptions}
              />
            )}
            <Icon onClick={props.onRemoveClick} size="md" name="x" />
          </div>
        </div>
        {props.breadcrumb && (
          <div className={styles.breadcrumb}>{props.breadcrumb}</div>
        )}
      </div>
      <div>{content}</div>
      {props.onMoreInfoClick && (
        <div className={styles.moreInfoLink} onClick={props.onMoreInfoClick}>
          More Info
        </div>
      )}
      {props.messages?.map((message) => (
        <div key={message.code} className={styles.message}>
          <span>
            <Icon
              name={"alert-circle"}
              size="md"
              color={
                message.level === RequestMessageLevel.Error
                  ? "red600V3"
                  : "blue600V3"
              }
            />
          </span>
          <span>{message.message}</span>
        </div>
      ))}
    </div>
  );
};
