import {
  EntityType,
  OwnerFragment,
  useUpdateOwnerMutation,
} from "api/generated/graphql";
import GroupSearchDropdown from "components/dropdown/GroupSearchDropdown";
import { OwnerConfig } from "components/forms/common";
import SlackChannelsRow from "components/forms/rows/SlackChannelRow";
import {
  makeConfigForOwner,
  validateOwnerConfig,
} from "components/forms/utils";
import { useToast } from "components/toast/Toast";
import {
  Banner,
  DataElement,
  Divider,
  FormGroup,
  Icon,
  Input,
  Modal,
  Switch,
  Tooltip,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import { isEqual } from "lodash";
import { useState } from "react";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";

import { EscalationPolicyForm } from "./owner_viewer/OwnerEscalationPolicy";

interface Props {
  owner: OwnerFragment;
  onClose: () => void;
}

const escalationPolicyHelpText =
  "By default, all reviewers are notified when an access request is created. To set a reviewer escalation policy, enable the option below. An escalation policy notifies one reviewer at a time, and notifies the next reviewer only when the request hasn't been actioned within a set amount of time.";

const OwnerEditModal = (props: Props) => {
  const { displaySuccessToast } = useToast();
  const [update, { loading: updateLoading }] = useUpdateOwnerMutation();

  const initialConfig = makeConfigForOwner(props.owner);
  const [config, setConfig] = useState<Partial<OwnerConfig>>(initialConfig);
  const [errors, setErrors] = useState<string[]>([]);

  const handleChange = (key: keyof OwnerConfig) => (
    val: OwnerConfig[keyof OwnerConfig]
  ) => {
    setConfig((prevConfig) => ({ ...prevConfig, [key]: val }));
  };

  const handleSave = async () => {
    const errors = validateOwnerConfig(config);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    try {
      setErrors([]);
      const { data } = await update({
        variables: {
          input: {
            ownerId: props.owner.id,
            name: config.name,
            accessRequestEscalationPeriodInMinutes: {
              int: config.escalationDurationMin,
            },
            description: config.description,
            reviewerMessageChannelId: {
              channelId: config.reviewerMessageChannel?.id,
            },
            sourceGroupId: {
              groupId: config.enabledSourceGroup
                ? config.sourceGroup?.id
                : null,
            },
          },
        },
        refetchQueries: ["Owner"],
      });

      switch (data?.updateOwner.__typename) {
        case "UpdateOwnerResult":
          props.onClose();
          displaySuccessToast(`Successfully updated owner`);
          break;
        case "GroupNotFoundError":
          setErrors(["Source group not found"]);
          break;
        case "MessageChannelNotFoundError":
          setErrors(["Reviewer message channel not found"]);
          break;
        case "OwnerAccessRequestFrequencyReminderOutOfBoundsError":
          setErrors(["Escalation duration must be between 1 and 1440 minutes"]);
          break;
        default:
          logError("Failed to update owner");
      }
    } catch (e) {
      logError(e, "failed to update owner");
    }
  };

  return (
    <Modal title="Edit Owner" onClose={props.onClose} isOpen maxWidth="md">
      <Modal.Body>
        <div className={sprinkles({ fontFamily: "body" })}>
          {errors.map((error) => (
            <Banner type="error" message={error} />
          ))}
          <div>
            <FormGroup label="Name">
              <Input
                value={config.name}
                onChange={handleChange("name")}
                placeholder="Enter name"
              />
            </FormGroup>
            <FormGroup label="Description">
              <Input
                type="textarea"
                value={config.description}
                onChange={handleChange("description")}
                placeholder="Enter description"
              />
            </FormGroup>
          </div>
          <Divider />
          <div
            className={sprinkles({ fontSize: "textMd", fontWeight: "medium" })}
          >
            Users
          </div>
          <div
            className={sprinkles({
              display: "flex",
              flexDirection: "column",
              gap: "sm",
            })}
          >
            An Owner must contain at least 1 user.
            <Switch
              checked={Boolean(config.enabledSourceGroup)}
              onChange={(checked: boolean) => {
                if (checked) {
                  handleChange("sourceGroup")(config.sourceGroup);
                } else {
                  handleChange("sourceGroup")(undefined);
                }
                handleChange("enabledSourceGroup")(checked);
              }}
              label="Enable syncing user list from selected group"
            />
            {config.enabledSourceGroup && (
              <FormGroup label="Source group:">
                {config.sourceGroup ? (
                  <DataElement
                    label={config.sourceGroup?.name}
                    color="teal"
                    leftIcon={
                      config.sourceGroup
                        ? {
                            data: {
                              entityType: config.sourceGroup?.groupType,
                              type: "entity",
                            },
                          }
                        : undefined
                    }
                    link={
                      config.sourceGroup?.id
                        ? getResourceUrlNew({
                            entityId: config.sourceGroup.id,
                            entityType: EntityType.Group,
                          })
                        : undefined
                    }
                    rightIcon={{
                      name: "x",
                      onClick: () => {
                        handleChange("sourceGroup")(undefined);
                      },
                    }}
                  />
                ) : (
                  <GroupSearchDropdown
                    placeholder="Select source group..."
                    limitSelectedGroups={1}
                    selectedGroupIds={[]}
                    onSelect={(data) => {
                      switch (data.actionType) {
                        case "select-option":
                          handleChange("sourceGroup")(data.groups[0]);
                          break;
                        case "remove-option":
                          handleChange("sourceGroup")(undefined);
                          break;
                      }
                    }}
                    popperForceDownward
                  />
                )}
              </FormGroup>
            )}
          </div>
          <Divider />
          <div
            className={sprinkles({
              fontSize: "textMd",
              fontWeight: "medium",
              display: "flex",
              alignItems: "center",
              gap: "xs",
              marginBottom: "sm",
            })}
          >
            Escalation Policy
            <Tooltip tooltipText={escalationPolicyHelpText}>
              <Icon name="info-circle" size="xs" color="gray400" />
            </Tooltip>
          </div>
          <EscalationPolicyForm
            accessRequestEscalationPeriodInMinutes={
              config.escalationDurationMin
            }
            onChange={handleChange("escalationDurationMin")}
            includeDescription={false}
          />
          <Divider />
          <div
            className={sprinkles({
              fontSize: "textMd",
              fontWeight: "medium",
              marginBottom: "sm",
            })}
          >
            Reviewer Slack channel
          </div>
          <SlackChannelsRow
            mode="edit"
            adminOwnerName={config.name ?? ""}
            messageChannel={config.reviewerMessageChannel ?? undefined}
            onChange={handleChange("reviewerMessageChannel")}
            isV3
          />
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Save"
        onPrimaryButtonClick={handleSave}
        primaryButtonLoading={updateLoading}
        primaryButtonDisabled={isEqual(config, initialConfig)}
      />
    </Modal>
  );
};

export default OwnerEditModal;
