import {
  usePaginatedUserDropdownQuery,
  UserDropdownPreviewFragment,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Select } from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useContext, useState } from "react";
import { logError } from "utils/logging";
import { SearchParamValue } from "utils/router/hooks";

// Limits number of options rendered for performance.
const MAX_USERS_TO_DISPLAY = 200;

type UserDropdownProps = {
  value?: UserDropdownPreviewFragment;
  valueId?: SearchParamValue;
  onChange: (newUser?: UserDropdownPreviewFragment) => void;
  /**
   * Already selected users -- will be hidden within this dropdown.
   * Currently this component doesn't display a UI for selecting/deselecting
   * multiple users.
   */
  hiddenUserIds?: string[];
  /** Additional static options, like "Unset Manager". These will be prepended. */
  additionalUsers?: UserDropdownPreviewFragment[];
  includeSystemUser?: boolean;
  includeOnlyManagers?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  /** Focus the input component on mount. */
  autoFocus?: boolean;
  placeholder?: string;
  selectOnly?: boolean;
  size?: "xs" | "sm" | "md";
  popperForceDownward?: PropsFor<typeof Select>["popperForceDownward"];
  popperHeight?: PropsFor<typeof Select>["popperHeight"];
};

/**
 * A searchable dropdown containing all users in the organization. This dropdown
 * statically limits the number of rendered options to preserve performance --
 * the user is expected to leverage search instead of scrolling to select the
 * desired user.
 */
export const PaginatedUserDropdown = ({
  value,
  valueId,
  onChange,
  hiddenUserIds,
  additionalUsers,
  disabled,
  clearable = true,
  autoFocus,
  placeholder,
  selectOnly,
  size,
  popperForceDownward,
  popperHeight,
  includeSystemUser,
  includeOnlyManagers,
}: UserDropdownProps) => {
  const { authState } = useContext(AuthContext);
  const [searchQuery, setSearchQuery] = useState("");

  const {
    data: usersData,
    error: usersError,
    loading,
    fetchMore,
  } = usePaginatedUserDropdownQuery({
    variables: {
      input: {
        searchQuery: searchQuery,
        maxNumEntries: MAX_USERS_TO_DISPLAY,
        includeSystemUser,
        includeOnlyManagers,
      },
    },
    fetchPolicy: "cache-first",
  });
  if (usersError) {
    logError(usersError, "failed to list users");
  }

  const handleSearchInputChange = _.debounce((s: string) => {
    setSearchQuery(s);
  }, 250);

  const handleInputChange = (input: string) => {
    handleSearchInputChange(input);
  };

  let users: UserDropdownPreviewFragment[] = [];
  switch (usersData?.users.__typename) {
    case "UsersResult":
      users = usersData.users.users;
      break;
    default:
      break;
  }

  let allUsers = _.uniqBy(
    [...(additionalUsers ?? []), ...users],
    (user) => user.id
  );
  if (hiddenUserIds) {
    allUsers = allUsers.filter((user) => !hiddenUserIds.includes(user.id));
  }

  const handleSelectValue = (value?: UserDropdownPreviewFragment) => {
    onChange(value);
    if (selectOnly) handleSearchInputChange("");
  };

  const getOptionLabel = (option: UserDropdownPreviewFragment) => {
    return option.id === authState.user?.user.id
      ? `${option.fullName} (Me)`
      : option.fullName;
  };

  const renderOptionLabel = (option: UserDropdownPreviewFragment) => {
    return (
      <span>
        {option.fullName}
        <span className={sprinkles({ color: "gray500", fontSize: "bodySm" })}>
          {" "}
          ({option.email})
        </span>
      </span>
    );
  };

  const onFetchMore = async () => {
    if (!usersData?.users.cursor) return;
    await fetchMore({
      variables: {
        input: {
          cursor: usersData?.users.cursor,
        },
      },
    });
  };

  return (
    <Select
      options={allUsers}
      value={
        !value && valueId ? allUsers.find((user) => user.id === valueId) : value
      }
      onChange={handleSelectValue}
      onInputChange={handleInputChange}
      clearable={clearable}
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      renderOptionLabel={renderOptionLabel}
      getIcon={(option) => ({
        type: "src",
        style: "rounded",
        icon: option.avatarUrl || defaultAvatarURL,
        fallbackIcon: defaultAvatarURL,
      })}
      getOptionSelected={(option, value) => option.id === value.id}
      disableBuiltInFiltering // Search is already handled server-side
      autoFocus={autoFocus}
      placeholder={placeholder}
      selectOnly={selectOnly}
      size={size}
      popperForceDownward={popperForceDownward}
      popperHeight={popperHeight}
      loading={loading}
      onScrollToBottom={() => onFetchMore()}
    />
  );
};
