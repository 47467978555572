import { EntityIdTupleNullable } from "api/common/common";
import { ResourceLabel } from "components/label/Label";
import React from "react";
import { Link } from "react-router-dom";

import * as styles from "./Breadcrumbs.css";

export type BreadcrumbInfo =
  | {
      name: string;
      entityId: EntityIdTupleNullable;
    }
  | {
      name: string;
      to: string;
    };

type BreadcrumbsProps = {
  showIcons?: boolean;
  breadcrumbInfos: BreadcrumbInfo[];
  openInNewTab?: boolean;
};

const Breadcrumbs = ({
  showIcons = true,
  breadcrumbInfos,
  openInNewTab = false,
}: BreadcrumbsProps) => {
  const renderBreadcrumb = (breadcrumbInfo: BreadcrumbInfo) => {
    if ("entityId" in breadcrumbInfo) {
      return (
        <ResourceLabel
          key={`group-breadcrumb-${breadcrumbInfo.entityId.entityId}`}
          text={breadcrumbInfo.name}
          entityTypeNew={breadcrumbInfo.entityId.entityType}
          entityId={breadcrumbInfo.entityId.entityId}
          hideIcon={!showIcons}
        />
      );
    }
    return (
      <Link to={breadcrumbInfo.to} target={openInNewTab ? "_blank" : undefined}>
        {breadcrumbInfo.name}
      </Link>
    );
  };

  return (
    <div className={styles.breadcrumbs}>
      {breadcrumbInfos.map((breadcrumbInfo, i) => {
        return (
          <React.Fragment key={i}>
            {i !== 0 ? <span>/</span> : null}
            <span className={styles.breadcrumb}>
              {renderBreadcrumb(breadcrumbInfo)}
            </span>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
