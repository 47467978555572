import {
  BundleFragment,
  BundlesDocument,
  useDeleteBundleMutation,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Modal } from "components/ui";
import { useState } from "react";
import { useHistory } from "react-router";
import { logError } from "utils/logging";

import { SORT_OPTIONS } from "./BundlesColumn";

interface Props {
  bundle: BundleFragment;
  onClose: () => void;
}

const BundleDeleteModal = (props: Props) => {
  const history = useHistory();
  const [error, setError] = useState("");

  const { displaySuccessToast } = useToast();
  const [deleteBundle, { loading }] = useDeleteBundleMutation();

  const handleDelete = async () => {
    try {
      await deleteBundle({
        variables: {
          input: {
            id: props.bundle.id,
          },
        },
        refetchQueries: [
          {
            query: BundlesDocument,
            variables: {
              input: {
                sortBy: SORT_OPTIONS[0].value,
              },
            },
          },
        ],
      });

      displaySuccessToast("Success: bundle deleted");
      props.onClose();
      history.push("/bundles");
    } catch (err) {
      logError(err, "Failed to delete bundle");
      setError("Failed to delete bundle");
    }
  };

  return (
    <Modal isOpen title="Delete Bundle" onClose={props.onClose}>
      <Modal.Body>
        {error && <ModalErrorMessage errorMessage={error} />}
        Are you sure you want to delete "{props.bundle.name}"? This cannot be
        undone.
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Delete"
        primaryButtonType="error"
        primaryButtonLoading={loading}
        onPrimaryButtonClick={handleDelete}
      />
    </Modal>
  );
};

export default BundleDeleteModal;
