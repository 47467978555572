import {
  ConnectionSummaryFragment,
  ConnectionType,
  ParentResourceInput,
  ResourceDropdownPreviewFragment,
  ResourceType,
  ResourceTypeWithCountFragment,
  SearchResourcePreviewFragment,
  useConnectionsSummaryQuery,
  usePaginatedResourceDropdownQuery,
  useResourceTypesWithCountsQuery,
  useSearchResourcesQuery,
} from "api/generated/graphql";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { Select } from "components/ui";
import { IconData } from "components/ui/utils";
import React from "react";
import { AuthorizedActionManage } from "utils/auth/auth";
import { logError } from "utils/logging";
import { ExpirationValue } from "views/requests/utils";

import EntityCountOption from "./EntityCountOption";

type EntitySelectorPage =
  | "connectionsResources"
  | "resourceTypes"
  | "resources";

const PAGE_SIZE = 100;
const SEARCH_INITIAL_SECTION_SIZE = 3;

type SearchGroup = "resources";

type SearchID = { resourceId: string };

type SearchOption = {
  id: SearchID;
  name: string;
  group: SearchGroup;
  icon: IconData;
  resourceType: ResourceType;
  parentResourceName?: string;
  relatedEntityCount?: number;
  relatedEntityLabel?: string;
  canManage: boolean;
};

interface NavigationStackItem {
  page: EntitySelectorPage;
  pageTitle: string;

  connectionFilter?: string[];
  resourceTypeFilter?: ResourceType[];
  parentResourceFilter?: ParentResourceInput;
}

export interface ResourceSelectData {
  id: string;
  name: string;
  resourceType: ResourceType;
}

interface SelectData {
  actionType?: "select-option" | "remove-option";
  resources: ResourceSelectData[];
}

export const optionToResourceSelectData = (
  option: SearchOption
): ResourceSelectData => ({
  id: option.id.resourceId,
  name: option.name,
  resourceType: option.resourceType,
});

const fragmentToSelectData = (
  fragment: ResourceDropdownPreviewFragment
): ResourceSelectData => ({
  id: fragment.id,
  name: fragment.name,
  resourceType: fragment.resourceType,
});

export const mergeResourceSelectData = <T extends object>(
  prev: Record<string, T[]>,
  next: ResourceSelectData[]
): Record<string, T[]> => {
  const newResources = next.reduce((acc, resource) => {
    return { ...acc, [resource.id]: [] };
  }, {});
  return { ...prev, ...newResources };
};

export const removeResourceSelectData = <T extends object>(
  prev: Record<string, T[]>,
  next: ResourceSelectData[]
): Record<string, T[]> => {
  next.forEach((resource) => {
    delete prev[resource.id];
  });
  return { ...prev };
};

export const mergeExpiration = (
  prev: Record<string, ExpirationValue>,
  next: ResourceSelectData[]
): Record<string, ExpirationValue> => {
  const newResources = next.reduce((acc, resource) => {
    return { ...acc, [resource.id]: ExpirationValue.Indefinite };
  }, {});
  return { ...prev, ...newResources };
};

export const removeExpiration = (
  prev: Record<string, ExpirationValue>,
  next: ResourceSelectData[]
): Record<string, ExpirationValue> => {
  next.forEach((resource) => {
    delete prev[resource.id];
  });
  return { ...prev };
};

interface Props {
  selectedResourceIds: string[];
  onSelect: (data: SelectData) => void;
  disabledResourceIds?: string[];
  disabledResourceTypes?: ResourceType[];
  disableForNonAdmin?: boolean;
  style?: PropsFor<typeof Select>["style"];
  placeholder?: string;
  placeholderIcon?: IconData;
  alwaysShowPlaceholder?: boolean;
  size?: PropsFor<typeof Select>["size"];
}

const ResourceSearchDropdown = (props: Props) => {
  const [navigationStack, setNavigationStack] = React.useState<
    NavigationStackItem[]
  >([{ page: "connectionsResources", pageTitle: "Resources" }]);

  const popNavigationStack = () => {
    if (navigationStack.length === 1) {
      return;
    }
    const newStack = navigationStack.slice();
    newStack.pop();
    setNavigationStack(newStack);
  };

  const pushNavigationStack = (newPage: NavigationStackItem) => {
    setNavigationStack([...navigationStack, newPage]);
  };

  const currentNavigationItem = navigationStack[navigationStack.length - 1];
  const page = currentNavigationItem.page;
  const pageTitle = currentNavigationItem.pageTitle;

  const [searchQuery, setSearchQuery] = React.useState("");

  const {
    data: connectionsSummaryData,
    error: connectionsSummaryError,
    loading: connectionsSummaryLoading,
  } = useConnectionsSummaryQuery({
    variables: { input: {} },
  });
  if (connectionsSummaryError) {
    logError(connectionsSummaryError, "failed to list connections");
  }

  let connections: ConnectionSummaryFragment[] = [];
  switch (connectionsSummaryData?.connections.__typename) {
    case "ConnectionsResult":
      connections = connectionsSummaryData.connections.connections;
      break;
    default:
      break;
  }

  const {
    data: resourceTypesData,
    error: resourceTypesError,
    loading: resourceTypesLoading,
  } = useResourceTypesWithCountsQuery({
    variables: {
      input: {
        connectionIds: currentNavigationItem.connectionFilter,
        parentResourceId: currentNavigationItem.parentResourceFilter,
      },
    },
    skip: page !== "resourceTypes",
  });

  if (resourceTypesError) {
    logError(resourceTypesError, "failed to list resource types");
  }

  let resourceTypes: ResourceTypeWithCountFragment[] = [];
  switch (resourceTypesData?.resourceTypesWithCounts.__typename) {
    case "ResourceTypesWithCountsResult":
      resourceTypes =
        resourceTypesData.resourceTypesWithCounts.resourceTypesWithCounts;
      break;
    default:
      break;
  }

  const connectionSummaryById = new Map<string, ConnectionSummaryFragment>();
  connections.forEach((connection) => {
    connectionSummaryById.set(connection.id, connection);
  });
  const isViewingSnowflake =
    currentNavigationItem.connectionFilter?.some(
      (connectionId) =>
        connectionSummaryById.get(connectionId)?.connectionType ===
        ConnectionType.Snowflake
    ) ?? false;

  const {
    data: resourcesData,
    error: resourcesError,
    fetchMore: resourcesFetchMore,
    loading: resourcesLoading,
  } = usePaginatedResourceDropdownQuery({
    variables: {
      input: {
        connectionIds: currentNavigationItem.connectionFilter,
        resourceTypes: currentNavigationItem.resourceTypeFilter,
        maxNumEntries: PAGE_SIZE,
        parentResourceId: currentNavigationItem.parentResourceFilter,
      },
    },
    // HACK: There is a weird interaction between the graphql cache and Snowflake resources
    //       when trying to add/remove resources to/from a Snowflake Role, which causes the
    //       parent components to re-render and the dropdown to close.
    //       I couldn't find the root cause / a better way to fix this, so I'm disabling
    //       the cache when the current navigation item is a Snowflake resource.
    //       Otherwise, it uses graphql's default caching policy.
    //       Demo: https://www.loom.com/share/aa1dafd7af2a4affb5ccaeb7fd79e0e2
    fetchPolicy: isViewingSnowflake ? "no-cache" : "cache-first",
    skip: page !== "resources",
  });
  if (resourcesError) {
    logError(resourcesError, "failed to list resources");
  }

  let resourcesCursor: string | null | undefined;
  let resources: ResourceDropdownPreviewFragment[] = [];
  switch (resourcesData?.resources.__typename) {
    case "ResourcesResult":
      resources = resourcesData.resources.resources;
      resourcesCursor = resourcesData.resources.cursor;
      break;
    default:
      break;
  }

  const {
    data: searchResourcesData,
    error: searchResourcesError,
    fetchMore: searchResourcesFetchMore,
  } = useSearchResourcesQuery({
    variables: {
      query: searchQuery,
      maxNumEntries: SEARCH_INITIAL_SECTION_SIZE,
    },

    skip: searchQuery === "",
  });
  if (searchResourcesError) {
    logError(searchResourcesError, "failed to execute resources search");
  }

  let searchResourcesCursor: string | null | undefined;
  let searchResources: SearchResourcePreviewFragment[] = [];
  switch (searchResourcesData?.resources.__typename) {
    case "ResourcesResult":
      searchResources = searchResourcesData.resources.resources;
      searchResourcesCursor = searchResourcesData.resources.cursor;
      break;
    default:
      break;
  }

  const sharedProps = {
    style: props.style,
    placeholder: props.placeholder ?? "Search",
    placeholderIcon: props.placeholderIcon,
    alwaysShowPlaceholder: props.alwaysShowPlaceholder,
    onInputChange: setSearchQuery,
    disableBuiltInFiltering: true,
    size: props.size,
  };

  if (searchQuery !== "") {
    const searchOptions: SearchOption[] = searchResources.map(
      (searchResource) => ({
        id: { resourceId: searchResource.id },
        resourceType: searchResource.resourceType,
        name: searchResource.name,
        group: "resources",
        icon: {
          type: "entity",
          entityType: searchResource.resourceType,
        },
        relatedEntityCount: searchResource.numChildResources
          ? searchResource.numChildResources
          : undefined,
        parentResourceName: searchResource.parentResource?.name,
        relatedEntityLabel: "child resources",
        canManage:
          searchResource.authorizedActions?.includes(AuthorizedActionManage) ??
          false,
      })
    );

    return (
      <Select<typeof searchOptions[0]>
        {...sharedProps}
        value={searchOptions.filter((searchOption) => {
          return props.selectedResourceIds.includes(searchOption.id.resourceId);
        })}
        multiple
        options={searchOptions}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option) => {
          return (
            props.disabledResourceIds?.includes(option.id.resourceId) ||
            props.disabledResourceTypes?.includes(option.resourceType) ||
            (props.disableForNonAdmin && !option.canManage) ||
            option.resourceType === ResourceType.AwsAccount
          );
        }}
        getIcon={(option) => option.icon}
        groupBy={(option) => option.group}
        groupHasLoadMore={(groupName) => {
          if (groupName === "resources") {
            return Boolean(searchResourcesCursor);
          }
          return false;
        }}
        renderOptionLabel={(option) => (
          <EntityCountOption
            name={option.name}
            count={option.relatedEntityCount}
            parentResourceName={option.parentResourceName}
            entityType={option.relatedEntityLabel}
          />
        )}
        onGroupLoadMore={(groupName) => {
          if (groupName === "resources") {
            searchResourcesFetchMore({
              variables: {
                resourcesCursor: searchResourcesCursor,
                maxNumEntries: PAGE_SIZE,
              },
            });
          }
        }}
        onSelectValue={(option, reason) => {
          if (
            option &&
            (reason === "select-option" || reason === "remove-option")
          ) {
            props.onSelect({
              actionType: reason,
              resources: [optionToResourceSelectData(option)],
            });
          }
        }}
        onBulkSelectValue={(option, reason) => {
          if (
            option &&
            (reason === "select-option" || reason === "remove-option")
          ) {
            props.onSelect({
              actionType: reason,
              resources: option.map(optionToResourceSelectData),
            });
          }
        }}
      />
    );
  }

  const anyLoading =
    connectionsSummaryLoading || resourceTypesLoading || resourcesLoading;

  if (page === "resourceTypes") {
    return (
      <Select<ResourceTypeWithCountFragment>
        {...sharedProps}
        options={resourceTypes}
        getOptionLabel={(option) =>
          getResourceTypeInfo(option.resourceType)?.name ?? ""
        }
        renderOptionLabel={(option) => (
          <EntityCountOption
            name={getResourceTypeInfo(option.resourceType)?.name ?? ""}
            count={option.numResources}
            entityType="resource"
          />
        )}
        getIcon={(option) => ({
          type: "entity",
          entityType: option.resourceType,
        })}
        onChange={() => {}}
        listboxHeader={{
          title: pageTitle,
          onGoBack: popNavigationStack,
        }}
        optionHasDrilldown={() => true}
        onDrilldown={(option) => {
          pushNavigationStack({
            page: "resources",
            pageTitle: getResourceTypeInfo(option.resourceType)?.name ?? "",
            connectionFilter: currentNavigationItem.connectionFilter,
            parentResourceFilter: currentNavigationItem.parentResourceFilter,
            resourceTypeFilter: [option.resourceType],
          });
        }}
        loading={anyLoading}
        getOptionDisabled={(option) =>
          props.disabledResourceTypes?.includes(option.resourceType) || false
        }
      />
    );
  }

  if (page === "resources") {
    const fetchMore = async () => {
      if (resourcesCursor) {
        await resourcesFetchMore({
          variables: {
            input: {
              cursor: resourcesCursor,
              maxNumEntries: PAGE_SIZE,
            },
          },
        });
      }
    };

    const sharedResourceProps = {
      listboxHeader: {
        title: pageTitle,
        onGoBack: popNavigationStack,
      },
      options: resources,
      getOptionLabel: (option: ResourceDropdownPreviewFragment) => option.name,
      renderOptionLabel: (option: ResourceDropdownPreviewFragment) => (
        <EntityCountOption
          name={option.name}
          count={
            option.numChildResources ? option.numChildResources : undefined
          }
          entityType="child resource"
        />
      ),
      onScrollToBottom: fetchMore,
      loading: anyLoading,
    };

    if (
      currentNavigationItem.resourceTypeFilter?.includes(
        ResourceType.AwsAccount
      )
    ) {
      return (
        <Select<ResourceDropdownPreviewFragment>
          {...sharedProps}
          {...sharedResourceProps}
          onChange={() => {}}
          optionHasDrilldown={(option) => option.numChildResources > 0}
          getOptionDisabled={(option) => option.numChildResources === 0}
          onDrilldown={(option) => {
            pushNavigationStack({
              page: "resourceTypes",
              pageTitle: option.name,
              connectionFilter: currentNavigationItem.connectionFilter,
              resourceTypeFilter: currentNavigationItem.resourceTypeFilter,
              parentResourceFilter: { parentResourceId: option.id },
            });
          }}
          getIcon={(option) => ({
            type: "entity",
            entityType: option.resourceType,
          })}
        />
      );
    }
    return (
      <Select<ResourceDropdownPreviewFragment>
        {...sharedProps}
        {...sharedResourceProps}
        multiple
        value={props.selectedResourceIds
          ?.map(
            (filterResourceId) =>
              resources.find((resource) => resource.id === filterResourceId)!
          )
          .filter((resource) => resource)}
        onSelectValue={(option, reason) => {
          if (!option) {
            return;
          }

          if (reason === "select-option" || reason === "remove-option") {
            props.onSelect({
              actionType: reason,
              resources: [fragmentToSelectData(option)],
            });
          }
        }}
        onBulkSelectValue={(option, reason) => {
          if (!option) {
            return;
          }

          if (reason === "select-option" || reason === "remove-option") {
            props.onSelect({
              actionType: reason,
              resources: option.map(fragmentToSelectData),
            });
          }
        }}
        getOptionDisabled={(option) => {
          return (
            props.disabledResourceIds?.includes(option.id) ||
            (props.disableForNonAdmin &&
              !option.authorizedActions?.includes(AuthorizedActionManage)) ||
            false
          );
        }}
        getIcon={(option) => ({
          type: "entity",
          entityType: option.resourceType,
        })}
      />
    );
  }

  return (
    <Select
      {...sharedProps}
      options={connections.filter((connection) => connection.numResources)}
      getOptionLabel={(option) => option.name}
      renderOptionLabel={(option) => (
        <EntityCountOption
          name={option.name}
          count={option.numResources}
          entityType="resource"
        />
      )}
      getIcon={(option) => ({
        type: "src",
        icon: getConnectionTypeInfo(option.connectionType)?.icon,
      })}
      onChange={() => {}}
      listboxHeader={{
        title: "Resources",
      }}
      optionHasDrilldown={() => true}
      onDrilldown={(option) => {
        if (option.connectionType === ConnectionType.AwsSso) {
          pushNavigationStack({
            page: "resources",
            pageTitle: getResourceTypeInfo(ResourceType.AwsAccount)?.name ?? "",
            connectionFilter: [option.id],
            parentResourceFilter: { parentResourceId: null },
            resourceTypeFilter: [ResourceType.AwsAccount],
          });
        } else {
          pushNavigationStack({
            page: "resourceTypes",
            pageTitle: option.name,
            connectionFilter: [option.id],
          });
        }
      }}
      loading={anyLoading}
    />
  );
};

export default ResourceSearchDropdown;
