import {
  ResourceType,
  useAwsAllAccountsColumnQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeader";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import { Divider } from "components/ui";
import { useContext } from "react";
import { useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { logError } from "utils/logging";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";

import { AppsContext } from "./AppsContext";
import ItemsListSection from "./ItemsListSection";
import { appsBreadcrumb } from "./utils";

const AWSAllAccountsColumn = ({ appId: appIdFromQuery }: { appId: string }) => {
  const { appId: appIdFromParams } = useParams<Record<string, string>>();
  const appId = appIdFromQuery || appIdFromParams;

  const logEvent = useLogEvent();

  const { isSelectMode, setIsSelectMode, clearSelectedItems } = useContext(
    AppsContext
  );

  const { data, error, loading } = useAwsAllAccountsColumnQuery({
    variables: {
      id: appId,
    },
  });

  let connection;
  switch (data?.connection.__typename) {
    case "ConnectionResult":
      connection = data.connection.connection;
      break;
    case "ConnectionNotFoundError":
      return (
        <Column>
          <NotFoundPage />
        </Column>
      );
  }

  if (error) {
    logError(error);
    return (
      <Column>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  if (loading) {
    return (
      <Column>
        <ColumnHeaderSkeleton />
        <Divider margin="md" />
        <ColumnListItemsSkeleton />
      </Column>
    );
  }

  return (
    <Column>
      <ColumnHeader
        title="All Resources"
        icon={{ type: "entity", entityType: ResourceType.AwsAccount }}
        breadcrumbs={[
          appsBreadcrumb,
          {
            name: connection?.name || "AWS",
            to: `/apps/${appId}`,
          },
        ]}
        menuOptions={[
          {
            label: isSelectMode ? "Cancel bulk select" : "Bulk select",
            icon: { type: "name", icon: "check-square" },
            onClick: () => {
              if (isSelectMode) {
                clearSelectedItems();
              } else {
                setIsSelectMode(true);
                logEvent({
                  name: "apps_bulk_select_start",
                  properties: {
                    entryPoint: "all_accounts",
                  },
                });
              }
            },
          },
        ]}
      />
      <Divider margin="md" />
      <ItemsListSection appId={appId} />
    </Column>
  );
};

export default AWSAllAccountsColumn;
