import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewFragment,
  AccessReviewResourcePreviewFragment,
  EntityType,
  ReviewerUserStatus,
  useUpdateAccessReviewResourceReviewersMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import ConnectionLabel from "components/label/item_labels/ConnectionLabel";
import { ResourceLabel } from "components/label/Label";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import AccessReviewerModal from "components/modals/AccessReviewerModal";
import {
  CellRow,
  Header,
  ScrollableMuiVirtualTable,
} from "components/tables/material_table/MuiVirtualTable";
import { useToast } from "components/toast/Toast";
import { Button } from "components/ui";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { SortDirection } from "react-virtualized";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { logError } from "utils/logging";
import AccessReviewContext, {
  AccessReviewContextActionType,
  emptyPerformReviewState,
} from "views/access_reviews/AccessReviewContext";
import AccessReviewReviewersCell from "views/access_reviews/AccessReviewReviewersCell";
import {
  AccessReviewStatusLabel,
  accessReviewSummaryStatusSortValue,
} from "views/access_reviews/AccessReviewStatus";
import {
  getReviewerModalEntries,
  getReviewersSortValue,
} from "views/access_reviews/common/Common";
import { getAccessReviewEntityUrl } from "views/access_reviews/common/Routes";
import {
  CONNECTION_HEADER,
  RESOURCE_NAME_HEADER,
  REVIEWERS_HEADER,
  SUMMARY_ACTION_HEADER,
  SUMMARY_STATUS_HEADER,
} from "views/access_reviews/common/TableHeaders";

interface ResourcesAccessReviewTableRow {
  name: string;
  connection: string;
  reviewers: string;
  status: string;
  action: string;
}

type AccessReviewResourcesTableProps = {
  accessReview: AccessReviewFragment;
  accessReviewResources: AccessReviewResourcePreviewFragment[];
};

const AccessReviewResourcesTable = (props: AccessReviewResourcesTableProps) => {
  const history = useHistory();
  const { authState } = useContext(AuthContext);
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );

  const headers: Header<ResourcesAccessReviewTableRow>[] = [
    RESOURCE_NAME_HEADER,
    CONNECTION_HEADER,
    REVIEWERS_HEADER,
    SUMMARY_STATUS_HEADER,
  ];

  if (accessReviewState.ongoingAccessReviewIdSet.has(props.accessReview.id)) {
    headers.push(SUMMARY_ACTION_HEADER);
  }

  let rows: CellRow<ResourcesAccessReviewTableRow>[] = [];

  props.accessReviewResources.forEach((accessReviewResource) => {
    if (accessReviewResource.resource) {
      const name = accessReviewResource.resource.name;
      const status = accessReviewResource.status;
      const userCanReviewResource = getUserCanReviewResource(
        authState.user?.user.id,
        accessReviewResource
      );

      const canEditReviewers =
        accessReviewState.ongoingAccessReviewIdSet.has(
          accessReviewResource.accessReviewId
        ) && accessReviewResource.canEditReviewers;

      const connection = accessReviewResource.resource?.connection;
      const connectionId = accessReviewResource.resource?.connectionId;

      rows.push({
        id: accessReviewResource.id,
        data: {
          name: {
            value: name,
            element: (
              <ResourceLabel
                text={name}
                subText={
                  getResourceTypeInfo(
                    accessReviewResource?.resource.resourceType
                  )?.name || "--"
                }
                entityType={EntityTypeDeprecated.Resource}
                resourceType={accessReviewResource.resource.resourceType}
                icon={accessReviewResource?.resource.iconUrl}
                iconLarge={true}
                bold={true}
                pointerCursor={true}
              />
            ),
            clickHandler: () => {
              history.push(getAccessReviewEntityUrl(accessReviewResource));
            },
          },
          connection: {
            sortValue: connection?.name || "",
            value: connectionId || "",
            element: (
              <ConnectionLabel
                text={connection?.name}
                connectionType={connection?.connectionType}
              />
            ),
          },
          reviewers: {
            value: getReviewersSortValue(accessReviewResource.reviewerUsers),
            element:
              accessReviewResource.numResourceUsers === 0 ? (
                <>--</>
              ) : (
                <ResourceAccessReviewersCell
                  accessReviewResource={accessReviewResource}
                />
              ),
          },
          status: {
            value: status,
            sortValue:
              10 * accessReviewSummaryStatusSortValue(status) +
              (getUserCanReviewResource(
                authState.user?.user.id,
                accessReviewResource
              )
                ? 0
                : 1),
            element: (
              <AccessReviewStatusLabel
                entityType={EntityType.Resource}
                status={status}
                warnings={accessReviewResource.warnings}
                resource={accessReviewResource.resource}
                accessReviewId={accessReviewResource.accessReviewId}
                canEditReviewers={canEditReviewers}
              />
            ),
          },
          action: {
            value: userCanReviewResource.toString(),
            element: userCanReviewResource ? (
              <Button
                type="warning"
                label="Review Access"
                leftIconName="check-square"
                onClick={() => {
                  accessReviewDispatch({
                    type: AccessReviewContextActionType.AccessReviewItemUpdate,
                    payload: {
                      performReviewStateByUARResourceId: {
                        ...accessReviewState.performReviewStateByUARResourceId,
                        [accessReviewResource.id]: emptyPerformReviewState(),
                      },
                    },
                  });
                  history.push(getAccessReviewEntityUrl(accessReviewResource));
                }}
              />
            ) : (
              <>{"--"}</>
            ),
          },
        },
      });
    }
  });

  return (
    <ScrollableMuiVirtualTable
      columns={headers}
      rows={rows}
      defaultSortBy={"status"}
      defaultSortDirection={SortDirection.ASC}
      allRowsLoaded
    />
  );
};

export const getUserCanReviewResource = (
  userId: string | undefined,
  resource: AccessReviewResourcePreviewFragment
) => {
  for (const reviewerUser of resource.reviewerUsers) {
    if (
      reviewerUser.userId === userId &&
      reviewerUser.status === ReviewerUserStatus.NotStarted
    ) {
      return true;
    }
  }
  return false;
};

type ResourceAccessReviewersCellProps = {
  accessReviewResource: AccessReviewResourcePreviewFragment;
};

export const ResourceAccessReviewersCell = (
  props: ResourceAccessReviewersCellProps
) => {
  const { accessReviewState } = useContext(AccessReviewContext);
  const [showReviewersModal, setShowReviewersModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { displaySuccessToast } = useToast();

  const [
    updateResourceReviewers,
    { loading },
  ] = useUpdateAccessReviewResourceReviewersMutation();

  const canEditReviewers =
    accessReviewState.ongoingAccessReviewIdSet.has(
      props.accessReviewResource.accessReviewId
    ) && props.accessReviewResource.canEditReviewers;

  return (
    <>
      <AccessReviewReviewersCell
        itemType="resource"
        reviewerUsers={getReviewerModalEntries(
          props.accessReviewResource.reviewerUsers,
          null
        )}
        canEditReviewers={canEditReviewers}
        onClick={() => {
          setShowReviewersModal(true);
        }}
      />
      {showReviewersModal && (
        <AccessReviewerModal
          title={"Reviewers"}
          isModalOpen={showReviewersModal}
          numUserReviews={props.accessReviewResource.numResourceUsers}
          onClose={() => {
            setShowReviewersModal(false);
            setErrorMessage("");
          }}
          loading={loading}
          onSubmit={async (reviewers) => {
            try {
              const { data } = await updateResourceReviewers({
                variables: {
                  input: {
                    accessReviewResourceId: props.accessReviewResource.id,
                    reviewers: reviewers,
                    updateOnlyForEntities: false,
                  },
                },
                refetchQueries: [
                  "OngoingAccessReviewStats",
                  "OngoingAccessReviewTabStats",
                  "OngoingAccessReviewSubtabStats",
                ],
              });
              switch (data?.updateAccessReviewResourceReviewers.__typename) {
                case "UpdateAccessReviewResourceReviewersResult": {
                  displaySuccessToast("Success: reviewers updated");
                  setShowReviewersModal(false);
                  break;
                }
                case "AccessReviewAlreadyStoppedError": {
                  setErrorMessage(
                    data?.updateAccessReviewResourceReviewers.message
                  );
                  break;
                }
              }
            } catch (error) {
              logError(error, "failed to update access reviewers");
              setErrorMessage(
                getModifiedErrorMessage(
                  "Error: failed to update access reviewers",
                  error
                )
              );
            }
          }}
          errorMessage={errorMessage}
          entryInfos={getReviewerModalEntries(
            props.accessReviewResource.reviewerUsers,
            null
          )}
          canEditReviewers={canEditReviewers}
        />
      )}
    </>
  );
};

export default AccessReviewResourcesTable;
