import * as React from "react";

import * as styles from "./ButtonGroup.css";

interface Props {}

const ButtonGroup: React.FC<Props> = (props) => {
  return <div className={styles.buttonGroup}>{props.children}</div>;
};

export default ButtonGroup;
