import { AppInfo, Maybe } from "api/generated/graphql";
import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  ReactElement,
  useReducer,
} from "react";

export enum AppContextActionType {
  OnPremChange,
  BannerContentChange,
}

export type AppContextState = {
  isInitialized: boolean;
  isOnPrem: boolean;
  version: string;
  environment: string;
  isRemoteLoggingEnabled: boolean;
  bannerContent: Maybe<ReactElement> | undefined;
};

export type AppContextEvent = {
  type: AppContextActionType;
  payload: {
    appInfo?: AppInfo | undefined | null;
    bannerContent?: Maybe<ReactElement> | undefined;
  };
};

const initialAppContext: AppContextState = {
  isInitialized: false,
  isOnPrem: false,
  version: "0.0.0",
  environment: "",
  isRemoteLoggingEnabled: false,
  bannerContent: null,
};

const AppContext = createContext<{
  appState: AppContextState;
  appDispatch: Dispatch<AppContextEvent>;
}>({
  appState: initialAppContext,
  appDispatch: () => null,
});

const reducer = (
  state: AppContextState,
  action: AppContextEvent
): AppContextState => {
  switch (action.type) {
    case AppContextActionType.OnPremChange:
      return {
        ...state,
        isInitialized: true,
        isOnPrem: !!action.payload.appInfo?.isOnPrem,
        version: action.payload.appInfo?.version || "0.0.0",
        environment: action.payload.appInfo?.environment || "",
        isRemoteLoggingEnabled:
          action.payload.appInfo?.isRemoteLoggingEnabled || false,
      };
    case AppContextActionType.BannerContentChange:
      return {
        ...state,
        bannerContent: action.payload.bannerContent!,
      };
    default:
      return state;
  }
};

export const AppContextProvider: FunctionComponent = (
  props: PropsWithChildren<{}>
) => {
  const [appState, appDispatch] = useReducer(reducer, initialAppContext);

  return (
    <AppContext.Provider
      value={{ appState: appState, appDispatch: appDispatch }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContext;
