import {
  AuthFlowType,
  IntegrationType,
  Maybe,
  ThirdPartyIntegrationFragment,
  ThirdPartyProvider,
} from "api/generated/graphql";
import clsx from "clsx";
import { AppTile } from "components/integrations/AppTile";
import { IntegrationInfo } from "components/integrations/OrgIntegrationApp";
import React from "react";
import { ThirdPartyCodeFlowAuthorizer } from "views/settings/third_party_authorizer/ThirdPartyCodeFlowAuthorizer";
import { ThirdPartyLinkFlowAuthorizer } from "views/settings/third_party_authorizer/ThirdPartyLinkFlowAuthorizer";
import { ThirdPartyManualFlowAuthorizer } from "views/settings/third_party_authorizer/ThirdPartyManualFlowAuthorizer";
import integrationsStyles from "views/user_settings/UserIntegrations.module.scss";

export type UserIntegrationAppProps = {
  integrationInfo: IntegrationInfo;
  integrations: ThirdPartyIntegrationFragment[];
  openThirdPartyProvider: Maybe<ThirdPartyProvider>;
  setOpenThirdPartyProvider: (integration: Maybe<ThirdPartyProvider>) => void;
  disabled: boolean;
};

export const UserIntegrationApp = (props: UserIntegrationAppProps) => {
  const isConnected = !!props.integrations.find(
    (integration) =>
      integration.thirdPartyProvider ===
      props.integrationInfo.thirdPartyProvider
  );

  let authorizer = null;
  switch (props.integrationInfo.authFlowType) {
    case AuthFlowType.Link:
      authorizer = (
        <ThirdPartyLinkFlowAuthorizer
          integrationType={IntegrationType.User}
          integrationInfo={props.integrationInfo}
          isConnected={isConnected}
          connectionButtonDisabled={props.disabled}
          openThirdPartyProvider={props.openThirdPartyProvider}
          setOpenThirdPartyProvider={props.setOpenThirdPartyProvider}
        />
      );
      break;
    case AuthFlowType.Code:
      authorizer = (
        <ThirdPartyCodeFlowAuthorizer
          integrationType={IntegrationType.User}
          integrationInfo={props.integrationInfo}
          isConnected={isConnected}
          connectionButtonDisabled={props.disabled}
          openThirdPartyProvider={props.openThirdPartyProvider}
          setOpenThirdPartyProvider={props.setOpenThirdPartyProvider}
        />
      );
      break;
    case AuthFlowType.Manual:
      authorizer = (
        <ThirdPartyManualFlowAuthorizer
          integrationType={IntegrationType.User}
          integrationInfo={props.integrationInfo}
          isConnected={isConnected}
          connectionButtonDisabled={props.disabled}
          openThirdPartyProvider={props.openThirdPartyProvider}
          setOpenThirdPartyProvider={props.setOpenThirdPartyProvider}
        />
      );
      break;
  }

  return (
    <div>
      <div className={integrationsStyles.appInfo}>
        <div className={integrationsStyles.leftContent}>
          <AppTile logo={props.integrationInfo.logo} />
          <div className={integrationsStyles.text}>
            <div className={integrationsStyles.appName}>
              {props.integrationInfo.name}
            </div>
            <div className={integrationsStyles.subtitle}>
              <div
                className={clsx({
                  [integrationsStyles.subtitleStatus]: true,
                  [integrationsStyles.statusDisconnected]: !isConnected,
                  [integrationsStyles.statusConnected]: isConnected,
                })}
              />
              <div className={integrationsStyles.subtitleText}>
                {isConnected ? "Connected" : "No connection"}
              </div>
            </div>
          </div>
        </div>
        <div className={integrationsStyles.rightContent}>{authorizer}</div>
      </div>
    </div>
  );
};
