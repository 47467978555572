import { useResourceMoreInfoQuery } from "api/generated/graphql";
import { DataElement, DataElementList, Skeleton } from "components/ui";
import sprinkles from "css/sprinkles.css";

const ResourceMoreInfo = ({ resourceId }: { resourceId: string }) => {
  const { data, error, loading } = useResourceMoreInfoQuery({
    variables: {
      input: {
        id: resourceId,
      },
    },
  });

  if (loading) {
    return (
      <div className={sprinkles({ marginBottom: "md" })}>
        <Skeleton height="48px" />
      </div>
    );
  }

  if (error) {
    return (
      <div className={sprinkles({ marginBottom: "md", color: "red600" })}>
        Failed to fetch resource info
      </div>
    );
  }

  const resource =
    data?.resource.__typename === "ResourceResult"
      ? data.resource.resource
      : null;
  if (!resource) {
    return null;
  }

  return (
    <div className={sprinkles({ marginBottom: "md" })}>
      <div
        className={sprinkles({
          fontSize: "labelMd",
          fontWeight: "medium",
          marginBottom: "sm",
        })}
      >
        More information about {resource.name}
      </div>
      <DataElementList>
        {resource.adminOwner ? (
          <DataElement
            color="blue"
            label={`Admin: ${resource.adminOwner.name}`}
            size="sm"
            link={`/owners/${resource.adminOwner.id}`}
          />
        ) : null}
        {resource.connection ? (
          <DataElement
            color="blue"
            label={`App: ${resource.connection.name}`}
            size="sm"
            link={`/apps/${resource.connection.id}/overview`}
          />
        ) : null}
        {resource.configTemplate ? (
          <DataElement
            color="blue"
            label={`Template: ${resource.configTemplate.name}`}
            size="sm"
            link={`/templates/configurations/${resource.configTemplate.id}`}
          />
        ) : null}
      </DataElementList>
    </div>
  );
};

export default ResourceMoreInfo;
