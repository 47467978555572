import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewResourceFragment,
  AccessReviewResourceUserFragment,
  EntityIdTuple,
  EntityType,
  ReviewerUserInput,
  useAccessReviewResourceQuery,
  useUpdateAccessReviewResourceReviewersMutation,
  useUpdateResourceUserReviewersMutation,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import AccessReviewerModal from "components/modals/AccessReviewerModal";
import { useToast } from "components/toast/Toast";
import { Banner, Button, Divider, Input, Tabs } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { filterSearchResults } from "utils/search/filter";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import * as styles from "./AccessReviewResource.css";
import AccessReviewReviewersCell from "./AccessReviewReviewersCell";
import {
  AccessReviewOutcomeLabel,
  AccessReviewStatusLabel,
} from "./AccessReviewStatus";
import {
  getReviewerModalEntries,
  getReviewersSortValue,
} from "./common/Common";
import EventsTable from "./common/EventsTable";
import ResourceUserSubRow from "./common/ResourceUserSubRow";
import UserCell from "./common/UserCell";
import AccessReviewResourceOverview from "./resources/AccessReviewResourceOverview";

const AccessReviewResourceDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const { accessReviewId, accessReviewResourceId } = useParams<
    Record<string, string>
  >();
  const { displaySuccessToast } = useToast();

  const [selectedResourceUserIds, setSelectedResourceUserIds] = useState<
    string[]
  >([]);
  const [showBulkReviewersModal, setShowBulkReviewersModal] = useState(false);
  const [reviewersError, setReviewersError] = useState("");

  const [
    updateResourceReviewers,
    { loading: updateLoading },
  ] = useUpdateAccessReviewResourceReviewersMutation();
  const { data, error, loading } = useAccessReviewResourceQuery({
    variables: {
      input: {
        id: accessReviewResourceId,
      },
    },
    skip: !accessReviewResourceId,
  });

  if (loading) {
    return (
      <Column isContent>
        <ColumnContentSkeleton />
      </Column>
    );
  }

  let accessReviewResource: AccessReviewResourceFragment | undefined;
  switch (data?.accessReviewResource.__typename) {
    case "AccessReviewResourceResult":
      accessReviewResource = data.accessReviewResource.accessReviewResource;
      break;
    case "AccessReviewResourceNotFoundError":
      return (
        <Column isContent>
          <NotFoundPage entity="Resource" />
        </Column>
      );
  }

  const resource = accessReviewResource?.resource;

  if (error || !accessReviewResource || !resource) {
    return (
      <Column isContent>
        <UnexpectedErrorPage />
      </Column>
    );
  }

  const currentAllResourceUserIds = new Set(
    accessReviewResource.resourceUsers?.map((ru) => ru.id)
  );
  const filteredResourceUserIds = selectedResourceUserIds.filter((id) =>
    currentAllResourceUserIds.has(id)
  );

  const handleSubmitReviewers = async (reviewers: ReviewerUserInput[]) => {
    const entityIds: EntityIdTuple[] = filteredResourceUserIds.map((id) => ({
      entityId: id,
      entityType: EntityType.AccessReviewResourceUser,
    }));
    reviewers = reviewers.map((reviewer) => {
      reviewer.entityIds = entityIds;
      return reviewer;
    });

    // Only update reviewers for checked rows
    accessReviewResource?.reviewerUsers.forEach((reviewerUser) => {
      if (!filteredResourceUserIds.includes(reviewerUser.entityId)) {
        reviewers.push({
          userId: reviewerUser.userId,
          entityIds: [
            {
              entityId: reviewerUser.entityId,
              entityType: reviewerUser.entityType,
            },
          ],
        });
      }
    });

    try {
      const { data } = await updateResourceReviewers({
        variables: {
          input: {
            accessReviewResourceId,
            reviewers,
            updateOnlyForEntities: false,
          },
        },
        refetchQueries: [
          "MyAccessReviewItems",
          "AccessReviewItems",
          "AccessReviewResource",
        ],
      });
      switch (data?.updateAccessReviewResourceReviewers.__typename) {
        case "UpdateAccessReviewResourceReviewersResult": {
          displaySuccessToast("Success: reviewers updated");
          setSelectedResourceUserIds([]);
          setShowBulkReviewersModal(false);
          break;
        }
        case "AccessReviewAlreadyStoppedError": {
          setReviewersError(data?.updateAccessReviewResourceReviewers.message);
          break;
        }
      }
    } catch (error) {
      setReviewersError(
        getModifiedErrorMessage(
          "Error: failed to update access reviewers",
          error
        )
      );
    }
  };

  const isStoppedReview =
    accessReviewResource.accessReview?.stoppedDate != null;

  return (
    <>
      <Column isContent>
        <ColumnHeader
          title={resource.name}
          icon={{ type: "entity", entityType: resource.resourceType }}
          subtitle={resourceTypeInfoByType[resource.resourceType].fullName}
          onClose={() =>
            history.push(`/access-reviews/${accessReviewId}/entities`)
          }
          rightActions={
            !isStoppedReview &&
            (!location.hash || location.hash === "#users") ? (
              <Button
                label="Bulk assign reviewers"
                type="primary"
                borderless
                leftIconName="edit"
                disabled={filteredResourceUserIds.length === 0}
                onClick={() => setShowBulkReviewersModal(true)}
              />
            ) : undefined
          }
        />
        <Divider />
        <div
          className={sprinkles({
            display: "flex",
            justifyContent: "center",
            marginY: "md",
          })}
        >
          <div className={sprinkles({ width: "fit-content" })}>
            <Tabs
              tabInfos={[
                {
                  title: "Users",
                  isSelected: !location.hash || location.hash === "#users",
                  onClick: () => history.push({ hash: "#users" }),
                  icon: "users",
                  badgeCount: accessReviewResource.numResourceUsers,
                },
                {
                  title: "Overview",
                  isSelected: location.hash === "#overview",
                  onClick: () => history.push({ hash: "#overview" }),
                  icon: "list",
                },
                {
                  title: "Events",
                  isSelected: location.hash === "#events",
                  onClick: () => history.push({ hash: "#events" }),
                  icon: "events",
                },
              ]}
              round
            />
          </div>
        </div>
        <ColumnContent>
          {!location.hash || location.hash === "#users" ? (
            <Users
              accessReviewResource={accessReviewResource}
              selectedResourceUserIds={filteredResourceUserIds}
              onSelectResourceUserIds={(ids) => {
                setSelectedResourceUserIds((prev) => _.uniq([...prev, ...ids]));
              }}
              onRemoveResourceUserIds={(ids) => {
                setSelectedResourceUserIds((prev) =>
                  prev.filter((id) => !ids.includes(id))
                );
              }}
            />
          ) : null}
          {location.hash === "#overview" && (
            <AccessReviewResourceOverview
              accessReviewResource={accessReviewResource}
            />
          )}
          {location.hash === "#events" && (
            <div className={styles.tableContainer}>
              <EventsTable
                eventFilter={{
                  objects: {
                    objectId: accessReviewResource.id,
                  },
                }}
              />
            </div>
          )}
        </ColumnContent>
      </Column>
      {showBulkReviewersModal && (
        <AccessReviewerModal
          title="Reviewers"
          isModalOpen={showBulkReviewersModal}
          numUserReviews={filteredResourceUserIds.length}
          onClose={() => {
            setShowBulkReviewersModal(false);
            setReviewersError("");
          }}
          loading={updateLoading}
          onSubmit={handleSubmitReviewers}
          errorMessage={reviewersError}
          entryInfos={getReviewerModalEntries(
            accessReviewResource.reviewerUsers,
            new Set(filteredResourceUserIds)
          )}
          canEditReviewers
        />
      )}
    </>
  );
};

interface ViewProps {
  accessReviewResource: AccessReviewResourceFragment;
  selectedResourceUserIds: string[];
  onSelectResourceUserIds: (ids: string[]) => void;
  onRemoveResourceUserIds: (ids: string[]) => void;
}

interface ResourceUserRow {
  id: string;
  name: string;
  role: string;
  reviewers: string;
  status: string;
  outcome: string;
  data: AccessReviewResourceUserFragment;
}

const Users = (props: ViewProps) => {
  const {
    accessReviewResource,
    selectedResourceUserIds,
    onSelectResourceUserIds,
    onRemoveResourceUserIds,
  } = props;

  const { displaySuccessToast } = useToast();

  const [selectedResourceUser, setSelectedResourceUser] = useState<
    AccessReviewResourceUserFragment | undefined
  >();
  const [showReviewerModal, setShowReviewerModal] = useState(false);
  const [reviewerError, setReviewerError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [
    updateResourceUserReviewers,
    { loading },
  ] = useUpdateResourceUserReviewersMutation();

  const resourceUsers = accessReviewResource.resourceUsers ?? [];

  const resourceHasRoles = resourceUsers.some(
    (user) => !!user.accessLevel.accessLevelRemoteId
  );
  const isStoppedReview =
    accessReviewResource.accessReview?.stoppedDate != null;

  const rows: ResourceUserRow[] = filterSearchResults(
    resourceUsers.slice(),
    searchQuery,
    (resourceUser) => [resourceUser.user?.fullName, resourceUser.user?.email]
  ).map((resourceUser) => {
    return {
      id: resourceUser.id,
      name: resourceUser.user?.fullName ?? "",
      role: resourceUser.accessLevel.accessLevelName,
      reviewers: getReviewersSortValue(resourceUser.reviewerUsers ?? []),
      status: resourceUser.statusAndOutcome.status,
      outcome: resourceUser.statusAndOutcome.outcome,
      data: resourceUser,
    };
  });

  const columns: Header<ResourceUserRow>[] = [
    {
      id: "name",
      label: "Name",
      width: resourceHasRoles ? 200 : 400,
      customCellRenderer: (row) => {
        const user = row.data.user;
        if (!user) {
          return <></>;
        }
        return <UserCell user={user} />;
      },
    },
  ];
  if (resourceHasRoles) {
    columns.push({
      id: "role",
      label: "Role",
      width: 200,
    });
  }
  columns.push(
    {
      id: "reviewers",
      label: "Reviewers",
      width: 150,
      customCellRenderer: (row) => {
        return (
          <AccessReviewReviewersCell
            itemType="resource"
            canEditReviewers={!isStoppedReview}
            reviewerUsers={row.data.reviewerUsers ?? []}
            onClick={() => {
              setSelectedResourceUser(row.data);
              setShowReviewerModal(true);
            }}
          />
        );
      },
    },
    {
      id: "status",
      label: "Status",
      width: 150,
      customCellRenderer: (row) => {
        return (
          <AccessReviewStatusLabel
            entityType={EntityType.AccessReviewResourceUser}
            status={row.data.statusAndOutcome.status}
            warnings={row.data.warnings}
            resource={props.accessReviewResource.resource}
            user={row.data.user}
            accessReviewId={props.accessReviewResource.accessReviewId}
          />
        );
      },
    },
    {
      id: "outcome",
      label: "Outcome",
      width: 120,
      customCellRenderer: (row) => {
        return (
          <AccessReviewOutcomeLabel
            entityType={EntityType.AccessReviewResourceUser}
            outcome={row.data.statusAndOutcome.outcome}
          />
        );
      },
    }
  );

  const renderSubRow = (row: ResourceUserRow) => {
    return <ResourceUserSubRow resourceUser={row.data} />;
  };

  const handleSubmitReviewers = async (reviewers: ReviewerUserInput[]) => {
    if (!selectedResourceUser) {
      return;
    }
    try {
      const { data } = await updateResourceUserReviewers({
        variables: {
          input: {
            accessReviewResourceUserId: selectedResourceUser.id,
            reviewers,
          },
        },
        refetchQueries: [
          "MyAccessReviewItems",
          "AccessReviewItems",
          "AccessReviewResource",
        ],
      });
      switch (data?.updateResourceUserReviewers.__typename) {
        case "UpdateResourceUserReviewersResult": {
          displaySuccessToast("Success: reviewers updated");
          setShowReviewerModal(false);
          break;
        }
        case "AccessReviewAlreadyStoppedError": {
          setReviewerError(data?.updateResourceUserReviewers.message);
          break;
        }
        case "UpdateAccessReviewReviewersValidationError": {
          setReviewerError(data?.updateResourceUserReviewers.message);
          break;
        }
      }
    } catch (error) {
      setReviewerError(
        getModifiedErrorMessage(
          "Error: failed to update access reviewers",
          error
        )
      );
    }
  };

  const multiSelectProps = isStoppedReview
    ? {}
    : {
        checkedRowIds: new Set(selectedResourceUserIds),
        onCheckedRowsChange: (ids: string[], checked: boolean) => {
          if (checked) {
            onSelectResourceUserIds(ids);
          } else {
            onRemoveResourceUserIds(ids);
          }
        },
        selectAllChecked: selectedResourceUserIds.length === rows.length,
        onSelectAll: (checked: boolean) => {
          if (checked) {
            onSelectResourceUserIds(rows.map((row) => row.id));
          } else {
            onRemoveResourceUserIds(rows.map((row) => row.id));
          }
        },
      };

  return (
    <>
      <div className={styles.tableContainer}>
        {isStoppedReview && (
          <div className={sprinkles({ marginBottom: "md" })}>
            <Banner
              type="warning"
              message="This access review has been stopped. No further actions can be taken on reviewer assignments and reviews."
            />
          </div>
        )}
        <div className={styles.searchContainer}>
          <Input
            leftIconName="search"
            type="search"
            style="search"
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Search this table"
          />
        </div>
        <Table
          rows={rows}
          totalNumRows={rows.length}
          getRowId={(row) => row.id}
          columns={columns}
          renderSubRow={renderSubRow}
          emptyState={{
            title: "No users to review",
            subtitle:
              "No users had direct access to this resource at the time of the access review.",
          }}
          {...multiSelectProps}
        />
      </div>
      {selectedResourceUser && showReviewerModal && (
        <AccessReviewerModal
          title="Reviewers"
          isModalOpen={showReviewerModal}
          onClose={() => {
            setShowReviewerModal(false);
            setReviewerError("");
          }}
          loading={loading}
          onSubmit={handleSubmitReviewers}
          errorMessage={reviewerError}
          entryInfos={getReviewerModalEntries(
            selectedResourceUser.reviewerUsers,
            null
          )}
          canEditReviewers={!isStoppedReview}
        />
      )}
    </>
  );
};

export default AccessReviewResourceDetails;
