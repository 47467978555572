import { TruncatedLabel } from "components/label/Label";
import React, { ReactElement } from "react";
import * as Icons from "react-feather";

const strokeWidthDefault = 2;

type GroupLabelProps = {
  text?: string | null;
  subText?: string;
  groupId?: string;
  maxChars?: number;
  icon?: string | ReactElement | null;
};

export const GroupLabel = (props: GroupLabelProps) => {
  let url = null;
  if (props.groupId) {
    url = `/groups/${props.groupId}`;
  }

  let text = props.text;
  if (!props.text || props.text === "") {
    text = props.groupId?.substr(0, 7) || "--";
  }

  let icon = props.icon || <Icons.Users strokeWidth={strokeWidthDefault} />;
  let iconTooltipText =
    "A group is a sensitive entity whose access is managed by Opal.";

  return (
    <TruncatedLabel
      text={text}
      subText={props.subText}
      icon={icon}
      iconTooltipText={iconTooltipText}
      url={url}
      maxChars={props.maxChars}
    />
  );
};

export default GroupLabel;
