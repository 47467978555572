import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewGroupFragment,
  EntityIdTuple,
  EntityType,
  Maybe,
  ReviewerUserStatus,
  useUpdateAccessReviewGroupReviewersMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import AccessReviewerModal from "components/modals/AccessReviewerModal";
import { useToast } from "components/toast/Toast";
import { Button } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext, useState } from "react";
import { useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";
import { getOidcData, OidcPostAuthAction } from "utils/oidc/oidc";
import { useQuery } from "utils/router/hooks";
import AccessReviewContext, {
  AccessReviewContextActionType,
  emptyPerformReviewState,
} from "views/access_reviews/AccessReviewContext";
import { BulkAssignReviewersButtons } from "views/access_reviews/common/BulkAssignReviewersButtons";
import { getReviewerModalEntries } from "views/access_reviews/common/Common";
import { SubmitAccessReviewButtons } from "views/access_reviews/common/SubmitAccessReviewButtons";

type GroupAccessReviewButtonsProps = {
  accessReviewGroup: AccessReviewGroupFragment;
  selectedRows: Record<string, EntityType>;
  setSelectedRows: (value: Record<string, EntityType>) => void;
  showBulkUpdateColumn: boolean;
  setShowBulkUpdateColumn: (value: boolean) => void;
};

const AccessReviewGroupActionButtons = (
  props: GroupAccessReviewButtonsProps
) => {
  const query = useQuery();
  const { authState } = useContext(AuthContext);
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );

  const isReviewer = props.accessReviewGroup.reviewerUsers.some(
    (reviewer) =>
      reviewer.userId === authState.user?.user.id &&
      reviewer.status === ReviewerUserStatus.NotStarted
  );

  const enableReviewMode = () =>
    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARGroupId: {
          ...accessReviewState.performReviewStateByUARGroupId,
          [props.accessReviewGroup.id]: emptyPerformReviewState(),
        },
      },
    });

  // If this page was opened via a Review notification action button,
  // start the page in Review Access mode.
  useMountEffect(() => {
    if (isReviewer && query.get("ref") === "notif") {
      enableReviewMode();
    }
  });

  const [showReviewersModal, setShowReviewersModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [
    updateGroupReviewers,
    { loading: updateReviewersLoading },
  ] = useUpdateAccessReviewGroupReviewersMutation();
  const { displaySuccessToast } = useToast();

  const group = props.accessReviewGroup.group;
  if (!group) {
    return <></>;
  }

  // If this access review is not ongoing, don't allow any actions to be performed.
  if (
    !accessReviewState.ongoingAccessReviewIdSet.has(
      props.accessReviewGroup.accessReviewId
    )
  ) {
    return <></>;
  }

  // If the user is currently in the UI for performing a review, show a different set of buttons.
  let performReviewState =
    accessReviewState.performReviewStateByUARGroupId[
      props.accessReviewGroup.id
    ];
  const queryParams = new URLSearchParams(location.search);
  if (queryParams.has("oidc_auth")) {
    const oidcAuthStatus = queryParams.get("oidc_auth");
    if (oidcAuthStatus === "success") {
      const oidcData = getOidcData();
      if (
        oidcData &&
        oidcData.params?.action === OidcPostAuthAction.PerformAccessReview
      ) {
        if (oidcData.params.performReviewState) {
          performReviewState = oidcData.params.performReviewState;
        }
      }
    }
  }
  const isGroupBeingReviewed = !!performReviewState;
  if (isGroupBeingReviewed) {
    return (
      <SubmitAccessReviewButtons
        entityName={group.name}
        accessReviewId={props.accessReviewGroup.accessReviewId}
        performReviewState={performReviewState}
        accessReviewItem={props.accessReviewGroup}
      />
    );
  }

  // Otherwise, show the normal set of buttons.

  const resetBulkAssign = () => {
    props.setSelectedRows({});
    props.setShowBulkUpdateColumn(false);
  };

  const selectedRowIds = Object.keys(props.selectedRows);
  const numSelectedUserRows = Object.values(props.selectedRows).reduce(
    (count, entityType) => {
      return entityType === EntityType.AccessReviewGroupUser
        ? count + 1
        : count;
    },
    0
  );
  const numSelectedResourceRows = Object.values(props.selectedRows).reduce(
    (count, entityType) => {
      return entityType === EntityType.AccessReviewGroupResource
        ? count + 1
        : count;
    },
    0
  );

  return (
    <div
      className={sprinkles({
        display: "flex",
        alignItems: "center",
        gap: "md",
      })}
    >
      {props.showBulkUpdateColumn ? (
        <BulkAssignReviewersButtons
          disabled={
            !props.accessReviewGroup.canEditReviewers ||
            selectedRowIds.length <= 0
          }
          setShowReviewersModal={setShowReviewersModal}
          onCancel={resetBulkAssign}
        />
      ) : (
        <>
          {props.accessReviewGroup.canEditReviewers && (
            <Button
              label="Bulk Assign Reviewers"
              type="primary"
              onClick={() => {
                props.setShowBulkUpdateColumn(true);
              }}
            />
          )}
          {isReviewer && (
            <Button
              onClick={enableReviewMode}
              type="warning"
              label="Review Access"
              leftIconName="check-square"
            />
          )}
        </>
      )}
      {showReviewersModal && (
        <AccessReviewerModal
          title={"Reviewers"}
          isModalOpen={showReviewersModal}
          numUserReviews={
            props.showBulkUpdateColumn
              ? numSelectedUserRows
              : props.accessReviewGroup.numGroupUsers
          }
          numResourceReviews={
            props.showBulkUpdateColumn
              ? numSelectedResourceRows
              : props.accessReviewGroup.numGroupResources
          }
          onClose={() => {
            setShowReviewersModal(false);
            setErrorMessage("");
          }}
          loading={updateReviewersLoading}
          onSubmit={async (reviewers) => {
            // Build entityIdTuple array from selected rows.
            const entityIds: EntityIdTuple[] = [];
            Object.entries(props.selectedRows ?? {}).forEach(
              ([id, entityType]) => {
                entityIds.push({
                  entityId: id,
                  entityType: entityType,
                });
              }
            );

            // reviewers are initialized with null entityIds so let's specify the entity id tuple
            reviewers = reviewers.map((reviewer) => {
              reviewer.entityIds = entityIds;
              return reviewer;
            });

            // Preserve the state of reviewers of unchecked rows
            props.accessReviewGroup.reviewerUsers.forEach((reviewerUser) => {
              if (!selectedRowIds.includes(reviewerUser.entityId)) {
                reviewers.push({
                  userId: reviewerUser.userId,
                  entityIds: [
                    {
                      entityId: reviewerUser.entityId,
                      entityType: reviewerUser.entityType,
                    },
                  ],
                });
              }
            });

            try {
              const { data } = await updateGroupReviewers({
                variables: {
                  input: {
                    accessReviewGroupId: props.accessReviewGroup.id,
                    reviewers: reviewers,
                    updateOnlyForEntities: false,
                  },
                },
                refetchQueries: [
                  "OngoingAccessReviewStats",
                  "OngoingAccessReviewTabStats",
                  "OngoingAccessReviewSubtabStats",
                ],
              });
              switch (data?.updateAccessReviewGroupReviewers.__typename) {
                case "UpdateAccessReviewGroupReviewersResult": {
                  displaySuccessToast("Success: Reviewers Updated");
                  setShowReviewersModal(false);
                  resetBulkAssign();
                  break;
                }
                case "AccessReviewAlreadyStoppedError": {
                  setErrorMessage(
                    data?.updateAccessReviewGroupReviewers.message
                  );
                  break;
                }
              }
            } catch (error) {
              logError(error, "failed to update access reviewers");
              setErrorMessage(
                getModifiedErrorMessage(
                  "Error: failed to update access reviewers",
                  error
                )
              );
            }
          }}
          errorMessage={errorMessage || ""}
          entryInfos={getReviewerModalEntries(
            props.accessReviewGroup.reviewerUsers,
            new Set(selectedRowIds)
          )}
          canEditReviewers={props.accessReviewGroup.canEditReviewers}
        />
      )}
    </div>
  );
};

export default AccessReviewGroupActionButtons;
