import { gql, useFragment } from "@apollo/client";
import { OpalAppResourceCountCellFragment } from "api/generated/graphql";

import OpalCountCell from "../OpalCountCell";

type Props = { appID: string };

const OpalAppResourceCountCell = (props: Props) => {
  const { complete, data } = useFragment<OpalAppResourceCountCellFragment>({
    fragment: gql`
      fragment OpalAppResourceCountCell on App {
        resourceCount
      }
    `,
    fragmentName: "OpalAppResourceCountCell",
    from: {
      __typename: "App",
      id: props.appID,
    },
  });

  return (
    <OpalCountCell
      complete={complete}
      subject="Resource"
      count={data?.resourceCount}
    />
  );
};
export default OpalAppResourceCountCell;
