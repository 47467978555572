import {
  EntityType,
  EventForTableFragment,
  EventType,
} from "api/generated/graphql";
import { Label } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import pluralize from "pluralize";
import { useHistory, useParams } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { eventTypeToString } from "utils/events";
import { useTransitionTo } from "utils/router/hooks";

import EventDetailColumnV3 from "./EventDetailColumnV3";
import * as styles from "./EventsColumnV3.css";

interface EventRow {
  id: string;
  event: EventType;
  actor: {
    fullName: string;
    avatarUrl: string;
  };
  ip: string;
  numLines: number;
  timestamp: string;
  numErrors: number;
  linkTo?: string;
}

const EVENT_COLUMNS_SMALL: Header<EventRow>[] = [
  {
    id: "event",
    label: "Event",
    sortable: false,
    minWidth: 200,
    customCellRenderer: (row) => {
      const time = moment(row.timestamp);
      return (
        <div className={sprinkles({ paddingY: "sm" })}>
          <div
            className={sprinkles({ flexDirection: "row", paddingBottom: "sm" })}
          >
            <div
              className={sprinkles({
                fontWeight: "bold",
              })}
            >
              {eventTypeToString(row.event, true)}
            </div>
            {time.fromNow()}
          </div>
          <Label
            label={row.actor.fullName}
            entityType={EntityType.User}
            iconSize="sm"
            icon={{
              type: "src",
              icon: row.actor.avatarUrl,
            }}
          />
        </div>
      );
    },
  },
];

const EVENT_COLUMNS: Header<EventRow>[] = [
  {
    id: "event",
    label: "Event",
    sortable: false,
    minWidth: 200,
    customCellRenderer: (row) => {
      return (
        <div className={sprinkles({ paddingY: "sm" })}>
          {eventTypeToString(row.event, true)}
        </div>
      );
    },
  },
  {
    id: "actor",
    label: "Actor",
    sortable: false,
    customCellRenderer: (row) => {
      return (
        <Label
          label={row.actor.fullName}
          entityType={EntityType.User}
          iconSize="sm"
          icon={{
            type: "src",
            icon: row.actor.avatarUrl,
          }}
        />
      );
    },
  },
  {
    id: "ip",
    label: "IP Address",
    sortable: false,
    width: 80,
  },
  {
    id: "id",
    sortable: false,
    label: "Event ID",
  },
  {
    id: "numLines",
    label: "Lines",
    sortable: false,
    width: 80,
    customCellRenderer: (row) => {
      return <div>{`${row.numLines} Lines`}</div>;
    },
  },
  {
    id: "timestamp",
    label: "Date",
    sortable: false,
    customCellRenderer: (row) => {
      return <div>{row.timestamp}</div>;
    },
  },
  {
    id: "numErrors",
    label: "Errors",
    sortable: false,
    width: 70,
    customCellRenderer: (row) => {
      return (
        <div
          className={sprinkles({
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          })}
        >
          <div
            className={sprinkles({
              color: row.numErrors > 0 ? undefined : "gray600",
            })}
          >
            {`${row.numErrors || "No"} ${pluralize("Error", row.numErrors)}`}
          </div>
          {row.numErrors > 0 ? <div className={styles.errorDot} /> : null}
        </div>
      );
    },
  },
];

type BaseTableProps = Pick<
  PropsFor<typeof Table>,
  "emptyState" | "loadingRows" | "totalNumRows" | "onLoadMoreRows"
>;

type Props = BaseTableProps & {
  events: EventForTableFragment[];
};

const EventsTableV3 = ({
  events,
  emptyState,
  loadingRows,
  totalNumRows,
  onLoadMoreRows,
}: Props) => {
  const history = useHistory();
  const transitionTo = useTransitionTo({ preserveAllQueries: true });
  const { eventId } = useParams<{ eventId: string }>();
  const isViewingEventDetail = Boolean(eventId);

  const rows = events.map<EventRow>((event) => {
    return {
      id: event.id,
      event: event.eventType,
      actor: event.actorUser ?? {
        fullName: "",
        avatarUrl: "",
      },
      ip: event.actorIP ?? "",
      numLines: event.numSubEvents,
      timestamp: event.createdAt,
      numErrors: event.numErrorSubEvents,
    };
  });

  const table = (
    <Table
      rows={rows}
      getRowId={(ru) => ru.id}
      columns={isViewingEventDetail ? EVENT_COLUMNS_SMALL : EVENT_COLUMNS}
      emptyState={emptyState}
      loadingRows={loadingRows}
      onRowClick={(row, event) => {
        transitionTo(
          {
            pathname: getResourceUrlNew({
              entityId: row.id,
              entityType: EntityType.Event,
            }),
          },
          event
        );
      }}
      totalNumRows={rows.length == 0 ? 0 : totalNumRows}
      onLoadMoreRows={onLoadMoreRows}
      getRowHighlighted={(row) => row.id === eventId}
      defaultSortBy="timestamp"
    />
  );

  return isViewingEventDetail ? (
    <div className={styles.eventDetailContainer}>
      <div className={styles.smallTable}>{table}</div>
      <div className={styles.eventDetail}>
        <EventDetailColumnV3 />
      </div>
    </div>
  ) : history.location.pathname === "/events" ? (
    table
  ) : (
    <div className={sprinkles({ height: "100%" })}>{table}</div>
  );
};

export default EventsTableV3;
