import { EntityType, ReviewerUserFragment } from "api/generated/graphql";
import { ResourceLabel } from "components/label/Label";
import { Icon, PopoverV3 } from "components/ui";
import { vars } from "css/vars.css";

import { DecisionLabel } from "./AccessReviewReviewerDetails";

export const ReviewerUsers = ({
  reviewerUsers,
}: {
  reviewerUsers: ReviewerUserFragment[];
}) => {
  const ReviewerUsersPopover = () => {
    return (
      <div
        style={{
          width: "384px",
          padding: "8px",
        }}
      >
        <div style={{ marginBottom: "5px" }}>
          {reviewerUsers.length} Other Reviewers
        </div>

        {reviewerUsers.map((reviewer) => {
          return (
            <div style={{ marginBottom: "10px" }}>
              <div key={reviewer.userId} style={{ display: "flex" }}>
                <div style={{ width: "200px" }}>
                  <ResourceLabel
                    text={reviewer.name}
                    avatar={reviewer.avatarUrl}
                    pointerCursor={true}
                    entityId={reviewer.userId}
                    entityTypeNew={EntityType.User}
                  />
                </div>
                <div style={{ width: "150px" }}>
                  <DecisionLabel status={reviewer.status} />
                </div>
              </div>
              {reviewer.note && (
                <div
                  style={{
                    display: "flex",
                    width: "300px",
                  }}
                >
                  <div>
                    <Icon name="message" color="gray700" size="xs" />
                  </div>
                  <div
                    style={{
                      color: vars.color.gray500,
                      display: "block",
                      paddingLeft: "5px",
                      fontSize: vars.fontSize.textSm,
                      lineHeight: "18px",
                    }}
                  >
                    "{reviewer.note}"
                  </div>
                </div>
              )}
            </div>
          );
        })}
      </div>
    );
  };

  return (
    <PopoverV3 content={<ReviewerUsersPopover />}>
      <Icon name="users-edit" color="blue700V3" />
    </PopoverV3>
  );
};
