import { useApolloClient } from "@apollo/client";
import { refetchQueries } from "api/ApiContext";
import {
  ConnectionType,
  GroupType,
  ResourceType,
  SyncErrorFragment,
  SyncTaskDocument,
  SyncTaskFragment,
  SyncTaskQuery,
  SyncTaskStatus,
  SyncType,
  useStartSyncMutation,
  useSyncStatusQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import SyncStatusModal from "components/label/SyncStatusModal";
import { useToast } from "components/toast/Toast";
import { ButtonV3 } from "components/ui";
import { useContext, useEffect, useState } from "react";
import useLogEvent from "utils/analytics";
import { logError } from "utils/logging";

import {
  getLabelForSyncStatus,
  getLabelForToasts,
} from "./SyncButtonsLabelsUtil";

type QueriesToRefetchType = Parameters<typeof refetchQueries>[0]["include"];

export type Props = {
  syncType: SyncType;
  queriesToRefetch?: QueriesToRefetchType;
  connection?: {
    id: string;
    name: string;
    connectionType: ConnectionType;
  };
  resource?: {
    id: string;
    name: string;
    resourceType: ResourceType;
  };
  group?: {
    id: string;
    name: string;
    groupType: GroupType;
    isOnCallSynced: boolean;
  };
  accessReview?: {
    id: string;
  };
  loadingEntity?: boolean;
  label?: string;
  adminOnly?: boolean;
};

const SyncButtons = (props: Props) => {
  const [syncTaskId, setSyncTaskId] = useState<string>();
  const isSyncing = syncTaskId != null;
  const [startSyncMutation] = useStartSyncMutation();
  const { authState } = useContext(AuthContext);
  const canManage = authState.user?.isAdmin ?? false;

  const { syncType, queriesToRefetch } = props;
  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();

  const logEvent = useLogEvent();
  const [showSyncModal, setShowSyncModal] = useState(false);

  const apolloClient = useApolloClient();

  // check if there's an ongoing sync
  const {
    data: syncStatusData,
    error: syncStatusError,
    loading: syncStatusLoading,
  } = useSyncStatusQuery({
    variables: {
      input: {
        syncType: syncType,
        connectionId: props.connection?.id ?? undefined,
      },
    },
  });

  // if there's an ongoing sync, set syncTaskId to the task ID to start polling
  useEffect(() => {
    if (syncStatusData?.syncStatus.__typename === "SyncStatusResult") {
      const taskId = syncStatusData.syncStatus.ongoingSyncTask?.id;
      if (taskId != null) {
        setSyncTaskId(taskId);
      }
    }
  }, [syncStatusData]);

  // if there's an ongoing sync - either just initiated by pressing the button
  // or initiated previously, start polling the server for updates.
  //
  // this query is intentionally done in a useEffect with apolloClient.watchQuery
  // so that it can continue polling even after the button is unmounted
  useEffect(() => {
    if (isSyncing) {
      // check if we're already polling already for this syncTaskId
      const observableQueries = apolloClient.getObservableQueries([
        SyncTaskDocument,
      ]);
      let currentlyPolling = false;
      observableQueries.forEach((value) => {
        if (syncTaskId === value.variables?.input.id) {
          currentlyPolling = true;
        }
      });
      if (!currentlyPolling) {
        // async callback that stars a watchQuery on our SyncTaskQuery
        // which returns an ObservableQuery which we can the pass a
        // callback to .subscribe() that get called when the query result
        // changes
        const fetchSyncTaskData = async () => {
          const observableQueryPromise = await apolloClient.watchQuery<SyncTaskQuery>(
            {
              query: SyncTaskDocument,
              variables: { input: { id: syncTaskId } },
              pollInterval: 2000,
            }
          );
          return observableQueryPromise;
        };

        fetchSyncTaskData().then((observableQuery) => {
          observableQuery.subscribe(async (queryResult) => {
            if (queryResult.data.syncTask.__typename === "SyncTaskResult") {
              const status = queryResult.data.syncTask.syncTask.status;
              if (status !== SyncTaskStatus.Started) {
                // cleanup
                observableQuery.stopPolling();
                setSyncTaskId(undefined);
                let allQueriesToRefetch: QueriesToRefetchType = ["SyncStatus"];
                if (queriesToRefetch) {
                  allQueriesToRefetch = allQueriesToRefetch.concat(
                    queriesToRefetch
                  );
                }

                try {
                  await refetchQueries({ include: allQueriesToRefetch });
                } catch (error) {
                  logError(error, `failed to refresh data after sync`);
                }

                const label = getLabelForToasts(props);

                if (status === SyncTaskStatus.Completed) {
                  displaySuccessToast(`Success: Finished syncing ${label}`);
                } else {
                  displayErrorToast(`Finished syncing ${label} with errors`);
                }
              }
            }
          });
        });
      }
    }
  }, [
    apolloClient,
    displayErrorToast,
    displaySuccessToast,
    isSyncing,
    props,
    queriesToRefetch,
    syncTaskId,
  ]);

  const startSync = async () => {
    const { data } = await startSyncMutation({
      variables: {
        input: {
          syncType: syncType,
          connectionId: props.connection?.id ?? undefined,
          resourceId: props.resource?.id ?? undefined,
          groupId: props.group?.id ?? undefined,
          accessReviewId: props.accessReview?.id ?? undefined,
        },
      },
    });
    switch (data?.startSync.__typename) {
      case "StartSyncResult":
        setSyncTaskId(data.startSync.syncTask.id);
        displayLoadingToast(`Syncing ${getLabelForToasts(props)}...`, false);
        break;
      case "InvalidSyncTypeError":
      case "ConnectionNotFoundError":
      case "ResourceNotFoundError":
      case "GroupNotFoundError":
      case "AccessReviewNotFoundError":
      default:
        break;
    }
  };

  // Sync Button labels
  let label = "Start sync";

  if (isSyncing) {
    label = "Syncing...";
  } else if (props.label) {
    label = props.label;
  } else if (
    syncType === SyncType.PullConnectionsSingleGroup ||
    syncType === SyncType.PullConnectionsSingleResource
  ) {
    label = "Sync item";
  }

  // Status Button labels
  let lastSuccessfulSyncTask: SyncTaskFragment | null = null;
  let syncErrors: SyncErrorFragment[] = [];
  if (syncStatusData != null) {
    switch (syncStatusData.syncStatus.__typename) {
      case "SyncStatusResult":
        lastSuccessfulSyncTask =
          syncStatusData.syncStatus.lastSuccessfulSyncTask ?? null;
        syncErrors = syncStatusData.syncStatus.syncErrors;
        break;
      case "InvalidSyncTypeError":
        logError(syncStatusData.syncStatus.message);
        break;
    }
  }

  let syncStatus: string;
  if (syncStatusError) {
    syncStatus = "Unable to get status";
  } else if (syncStatusLoading) {
    syncStatus = "Loading sync status";
  } else {
    syncStatus = getLabelForSyncStatus(lastSuccessfulSyncTask, syncErrors);
  }

  return (
    <>
      {canManage ? (
        <ButtonV3
          leftIconName="refresh"
          label={label}
          onClick={startSync}
          disabled={syncTaskId != null}
        />
      ) : null}
      <ButtonV3
        label="View Sync Details"
        sublabel={syncStatus}
        onClick={() => {
          logEvent({
            name: "apps_view_sync_details",
            properties: {
              syncType: syncType,
            },
          });
          setShowSyncModal(true);
        }}
      />
      <SyncStatusModal
        syncType={syncType}
        entity={null}
        lastSuccessfulSyncTask={lastSuccessfulSyncTask}
        syncErrors={syncErrors}
        isModalOpen={showSyncModal}
        onClose={() => {
          setShowSyncModal(false);
        }}
      />
    </>
  );
};

export default SyncButtons;
