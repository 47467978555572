import {
  ConnectionType,
  GroupAccessLevel,
  GroupType,
  ResourceAccessLevel,
  ResourceType,
  ServiceType,
} from "api/generated/graphql";
import { OPAL_GLOBAL_IMPERSONATION_REMOTE_ID } from "utils/constants";
import { groupTypeHasRoles } from "utils/directory/groups";
import {
  entityHasOnlyOneRole,
  resourceRequiresAtLeastOneRole,
} from "utils/directory/resources";

export const getIsGlobalImpersonationResource = (resource: {
  __typename: "Resource";
  remoteId: string;
}) => {
  return resource.remoteId === OPAL_GLOBAL_IMPERSONATION_REMOTE_ID;
};

type ItemIncludeRoles = {
  id: string;
  serviceType: ServiceType;
  connection?: { connectionType: ConnectionType } | null;
};

type ResourceIncludeRoles = ItemIncludeRoles & {
  __typename: "Resource";
  resourceType: ResourceType;
  remoteId: string;
};

type GroupIncludeRoles = ItemIncludeRoles & {
  __typename: "Group";
  groupType: GroupType;
  remoteId?: string | null;
};

export const getItemRequiresRoles = (
  item: ResourceIncludeRoles | GroupIncludeRoles,
  availableRoles?: (ResourceAccessLevel | GroupAccessLevel)[]
) => {
  switch (item.__typename) {
    case "Resource": {
      if (!availableRoles) {
        return resourceRequiresAtLeastOneRole(item);
      }
      if (
        !getIsGlobalImpersonationResource(item) &&
        availableRoles.length === 1 &&
        availableRoles[0].accessLevelRemoteId === ""
      ) {
        // in the event there is only the default role, do not show the dropdown
        return false;
      }
      if (availableRoles.length === 0) {
        return false;
      }
      return !entityHasOnlyOneRole(
        item.serviceType,
        item.connection?.connectionType,
        item.remoteId
      );
    }
    case "Group": {
      if (groupTypeHasRoles(item.groupType)) {
        return true;
      }
      if (!availableRoles) {
        return false;
      }
      if (availableRoles.length === 0) {
        return false;
      }
      return true;
    }
  }
};
