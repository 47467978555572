import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import ColumnListItem from "components/column/ColumnListItem";
import { Divider } from "components/ui";
import { useContext } from "react";
import { useHistory } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

const TemplatesColumn = () => {
  const { authState } = useContext(AuthContext);
  const organizationName = authState.user?.user.organization.name;
  const history = useHistory();

  const hasConfigurationTemplates = useFeatureFlag(
    FeatureFlag.ConfigurationTemplates
  );

  return (
    <>
      <Column>
        <ColumnHeader
          title="Templates"
          subtitle={organizationName}
          icon={{ type: "name", icon: "template" }}
        />
        <Divider />
        <ColumnListItem
          label="Custom Access Requests"
          onClick={() => history.push("/templates/requests")}
        />
        {hasConfigurationTemplates ? (
          <ColumnListItem
            label="Configuration Templates"
            onClick={() => history.push("/templates/configurations")}
          />
        ) : null}
      </Column>
    </>
  );
};

export default TemplatesColumn;
