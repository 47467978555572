import { GroupPreviewLargeFragment, Maybe } from "api/generated/graphql";
import { SectionEntry } from "components/viewer/Viewer";

export const GROUP_DESCRIPTION_TITLE = "Description";

type GroupDescriptionRowProps = {
  group?: Maybe<GroupPreviewLargeFragment>;
};

export const GroupDescriptionRow = (props: GroupDescriptionRowProps) => {
  return (
    <SectionEntry title={GROUP_DESCRIPTION_TITLE}>
      <>{props.group?.description || "--"}</>
    </SectionEntry>
  );
};

export default GroupDescriptionRow;
