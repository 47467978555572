import {
  EntityType,
  useCreateBundleMutation,
  Visibility,
} from "api/generated/graphql";
import { BundleConfig } from "components/forms/common";
import FullscreenView from "components/layout/FullscreenView";
import { useToast } from "components/toast/Toast";
import { useState } from "react";
import { useHistory } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";

import BundleEditForm from "./BundleEditForm";

const BundleCreateView = () => {
  const [createBundle, { loading: saveLoading }] = useCreateBundleMutation();
  const history = useHistory();
  const transitionBack = useTransitionBack();
  const { displaySuccessToast } = useToast();

  const [errors, setErrors] = useState<string[]>([]);
  const [config, setConfig] = useState<Partial<BundleConfig>>({
    visibility: Visibility.Global,
  });

  const handleClose = () => {
    transitionBack("/bundles");
  };

  const handleSave = async () => {
    if (!config.name || !config.name.trim().length) {
      setErrors(["A unique name is required."]);
      return;
    }
    if (!config.adminOwner) {
      setErrors(["Admin owner is required."]);
      return;
    }

    try {
      setErrors([]);
      const { data } = await createBundle({
        variables: {
          input: {
            name: config.name,
            description: config.description || "",
            adminOwnerId: config.adminOwner.id,
            visibility: config.visibility,
            visibilityGroupsIds: config.visibilityGroupsIds,
          },
        },
        refetchQueries: ["Bundles"],
      });

      switch (data?.createBundle.__typename) {
        case "CreateBundleResult":
          setErrors([]);
          displaySuccessToast("Successfully created bundle");
          history.push(
            getResourceUrlNew({
              entityId: data.createBundle.bundle.id,
              entityType: EntityType.Bundle,
            })
          );
          break;
        case "BundleNameExistsError":
          setErrors([data.createBundle.message]);
          break;
        default:
          logError("Failed to create bundle");
          setErrors(["Failed to create bundle"]);
      }
    } catch (err) {
      logError(err, "Failed to create bundle");
      setErrors(["Failed to create bundle"]);
    }
  };

  const hasRequiredFields =
    config.name && config.name.trim().length > 0 && config.adminOwner;

  return (
    <FullscreenView
      title="Create Bundle"
      onCancel={handleClose}
      onPrimaryButtonClick={handleSave}
      primaryButtonDisabled={saveLoading || !hasRequiredFields}
    >
      <BundleEditForm config={config} onChange={setConfig} errors={errors} />
    </FullscreenView>
  );
};

export default BundleCreateView;
