import { getModifiedErrorMessage } from "api/ApiContext";
import {
  ConnectionPreviewLargeFragment,
  Maybe,
  useUpdateConnectionMutation,
} from "api/generated/graphql";
import { Editor } from "components/entity_viewer/EntityViewer";
import EditDescriptionModal from "components/modals/update/EditDescriptionModal";
import { useToast } from "components/toast/Toast";
import { SectionEntry } from "components/viewer/Viewer";
import React, { useState } from "react";
import { logError } from "utils/logging";

type ConnectionDescriptionRowProps = {
  connection?: Maybe<ConnectionPreviewLargeFragment>;
};

export const ConnectionDescriptionRow = (
  props: ConnectionDescriptionRowProps
) => {
  const [showModal, setShowModal] = useState(false);

  const initDescription = props.connection?.description;
  const [description, setDescription] = useState<string>(initDescription || "");

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [updateConnection, { loading }] = useUpdateConnectionMutation();

  const editor = (
    <Editor
      menuOptions={[
        {
          label: "Edit",
          handler: () => {
            setShowModal(true);
          },
        },
      ]}
    />
  );

  const modalReset = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  const descriptionModal = (
    <EditDescriptionModal
      key={"description"}
      title={"Edit description"}
      isModalOpen={showModal}
      onChange={(updatedDescription) => {
        setDescription(updatedDescription);
      }}
      onClose={() => {
        modalReset();
      }}
      onSubmit={async () => {
        if (!props.connection?.id) {
          return;
        }
        try {
          const { data } = await updateConnection({
            variables: {
              input: {
                id: props.connection.id,
                description: description,
              },
            },
          });
          switch (data?.updateConnection.__typename) {
            case "UpdateConnectionResult":
              modalReset();
              displaySuccessToast("Success: app description updated");
              break;
            case "ConnectionNotFoundError":
            case "UserFacingError":
              setErrorMessage(data.updateConnection.message);
              break;
            default:
              logError(new Error(`failed to update app description`));
              setErrorMessage("Error: failed to update app description");
          }
        } catch (error) {
          logError(error, "failed to update app description");
          setErrorMessage(
            getModifiedErrorMessage(
              "Error: failed to update app description",
              error
            )
          );
        }
      }}
      value={description}
      loading={loading}
      errorMessage={errorMessage}
      submitDisabled={description === props.connection?.description}
    />
  );

  return (
    <SectionEntry title={"Description"} adminEditor={editor}>
      <>{initDescription || "--"}</>
      {showModal ? descriptionModal : null}
    </SectionEntry>
  );
};

export default ConnectionDescriptionRow;
