import {
  EntityType,
  OwnersSortByField,
  SortDirection,
  useOwnersListQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import ColumnListItem from "components/column/ColumnListItem";
import ColumnListScroller from "components/column/ColumnListScroller";
import { ColumnSearchAndSort } from "components/column/ColumnSearchAndSort";
import { Divider } from "components/ui";
import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import OwnerCreateModal from "./OwnerCreateModal";
import OwnerDetailColumn from "./OwnerDetailColumn";

const SORT_OPTIONS = [
  {
    label: "Name (A-Z)",
    value: {
      field: OwnersSortByField.Name,
      direction: SortDirection.Asc,
    },
  },
  {
    label: "Name (Z-A)",
    value: {
      field: OwnersSortByField.Name,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Newest first",
    value: {
      field: OwnersSortByField.CreatedAt,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Oldest first",
    value: {
      field: OwnersSortByField.CreatedAt,
      direction: SortDirection.Asc,
    },
  },
];

const OwnersColumn = () => {
  const history = useHistory();
  const { ownerId } = useParams<Record<string, string>>();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);

  const { data, error, loading, fetchMore } = useOwnersListQuery({
    variables: {
      input: {
        searchQuery: searchQuery ? searchQuery : undefined,
        sortBy: sortBy.value,
      },
    },
  });

  const { authState } = useContext(AuthContext);
  const organizationName = authState.user?.user.organization.name;
  const isAdmin = authState.user?.isAdmin ?? false;

  const cursor = data?.owners.cursor;
  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            input: {
              cursor,
              searchQuery: searchQuery.length > 0 ? searchQuery : undefined,
              sortBy: sortBy.value,
            },
          },
        });
      }
    : undefined;

  const owners = data?.owners.owners ?? [];
  if (error) {
    logError(error, "failed to list owners");
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  const renderRow = (index: number) => {
    const owner = owners[index];
    if (!owner) {
      return <></>;
    }

    return (
      <ColumnListItem
        key={owner.id}
        label={owner.name}
        selected={ownerId === owner.id}
        onClick={() => {
          history.push(
            getResourceUrlNew({
              entityId: owner.id,
              entityType: EntityType.Owner,
            })
          );
        }}
      />
    );
  };

  const addMenuOptions: PropsFor<typeof ColumnHeader>["addMenuOptions"] = [];
  if (isAdmin) {
    addMenuOptions.push({
      label: "Create owner",
      onClick: () => setShowCreateModal(true),
      icon: { type: "name", icon: "plus" },
    });
  }

  return (
    <>
      <Column>
        <ColumnHeader
          title="Owners"
          subtitle={organizationName}
          icon={{ type: "name", icon: "user-square" }}
          count={data?.owners.totalNumOwners}
          addMenuOptions={addMenuOptions}
        />
        <Divider margin="md" />
        <ColumnSearchAndSort
          key="search"
          setSearchQuery={setSearchQuery}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortOptions={SORT_OPTIONS}
          placeholder="Search owners"
          trackName="owners"
        />
        <ColumnListScroller
          renderRow={renderRow}
          numRows={owners.length}
          emptyState={{ title: "No owners to display." }}
          onLoadMore={loadMoreRows}
          hasNextPage={Boolean(cursor)}
          loading={loading}
        />
      </Column>
      {ownerId ? <OwnerDetailColumn /> : null}
      {showCreateModal ? (
        <OwnerCreateModal onClose={() => setShowCreateModal(false)} />
      ) : null}
    </>
  );
};

export default OwnersColumn;
