import { EntityType, Maybe, ServiceType } from "api/generated/graphql";
import clsx from "clsx";
import Label from "components/label/Label";
import { getServiceTypeInfo } from "components/label/ServiceTypeLabel";
import styles from "components/pills/TagPills.module.scss";
import { TagKeyValue } from "components/tags/Tags";
import { Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { ReactElement } from "react";
import * as Icons from "react-feather";
import { getResourceUrlNew } from "utils/common";

export type TagPillProps = {
  tagId?: string;
  tag: TagKeyValue;
  sourceServiceType?: Maybe<ServiceType>;
  clearable?: boolean;
  onRemove?: () => void;
};

export const TagPill = (props: TagPillProps) => {
  const { tag, tagId } = props;
  const url = tagId
    ? getResourceUrlNew({
        entityId: tagId,
        entityType: EntityType.Tag,
      })
    : undefined;
  return (
    <BasePill
      icon={<Icons.Tag strokeWidth={3} />}
      text={tag.value ? `${tag.key}:${tag.value}` : tag?.key}
      url={url}
      ok={true}
      sourceServiceType={props.sourceServiceType}
      clearable={props.clearable}
      onRemove={props.onRemove}
    />
  );
};

type BasePillProps = {
  icon: Maybe<ReactElement>;
  text: string;
  url?: string;
  alert?: boolean;
  ok?: boolean;
  neutral?: boolean;
  sourceServiceType?: Maybe<ServiceType>;
  clearable?: boolean;
  onRemove?: () => void;
};

const BasePill = (props: BasePillProps) => {
  let serviceIcon: Maybe<string> = null;
  if (props.sourceServiceType) {
    const serviceTypeInfo = getServiceTypeInfo(props.sourceServiceType);
    if (serviceTypeInfo) {
      serviceIcon = serviceTypeInfo.icon;
    }
  }

  return (
    <div
      className={clsx({
        [styles.pillWrapper]: true,
        [styles.alert]: props.alert,
        [styles.ok]: props.ok,
        [styles.neutral]: props.neutral,
      })}
    >
      <div className={styles.pillInnerWrapper}>
        {props.icon}
        <div className={styles.pillText}>
          <Label
            text={props.text}
            maxChars={35}
            tooltipText={props.text.length > 40 ? props.text : null}
            url={props.url}
          />
        </div>
        {serviceIcon && (
          <div className={styles.serviceIcon}>
            <img alt={""} src={serviceIcon} width={16} height={16} />
          </div>
        )}
        {props.clearable && (
          <div
            className={sprinkles({ marginLeft: "xs", cursor: "pointer" })}
            onClick={props.onRemove}
          >
            <Icon name="x" size="sm" />
          </div>
        )}
      </div>
    </div>
  );
};
