import { LineGraphData } from "api/generated/graphql";
import { Line } from "react-chartjs-2";

interface Props {
  data: LineGraphData[];
  label: string;
}

const options: PropsFor<typeof Line>["options"] = {
  responsive: true,
  aspectRatio: 3,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      displayColors: false,
      callbacks: {
        title: () => "",
      },
    },
  },
  // Show tooltip on hover over any part of the graph to the nearest point.
  interaction: {
    mode: "nearest",
    intersect: false,
    axis: "xy",
  },
  // Hide axes and lines
  scales: {
    y: {
      grid: {
        drawOnChartArea: false,
        tickLength: 0,
      },
      display: false,
    },
    x: {
      grid: {
        drawOnChartArea: false,
        tickLength: 0,
      },
      display: false,
    },
  },
};

const Sparkline = (props: Props) => {
  const data: PropsFor<typeof Line>["data"] = {
    datasets: [
      {
        data: props.data.map((point) => point.value),
        borderColor: "#359FF4",
        backgroundColor: "#359FF4",
        pointRadius: 0,
        pointHoverRadius: 0,
        borderWidth: 2,
        label: props.label,
      },
    ],
    labels: props.data.map((data) => data.timestamp),
  };

  return <Line data={data} options={options} />;
};

export default Sparkline;
