import { Checkbox, ContextMenu, Icon, Select } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useHistory, useLocation } from "react-router";

import { useFilterDispatch, useFilterState } from "./contexts/FilterContext";
import * as styles from "./TopControlBar.css";

interface Props {
  zoom: number;
  onZoomIn: () => void;
  onZoomOut: () => void;
  onZoomReset: () => void;
}

const GroupByOptions = [
  {
    label: "Group by user",
    value: undefined,
  },
  {
    label: "Group by team",
    value: "userTeam",
  },
  {
    label: "Group by role",
    value: "userRole",
  },
];

const TopControlBar = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const filterState = useFilterState();
  const filterDispatch = useFilterDispatch();

  const hasSelected = Boolean(location.search);

  const displayOptions: PropsFor<typeof ContextMenu>["options"] = [
    {
      label: "User Email",
      onClick: () =>
        filterDispatch({
          type: "SET_SHOW_EMAIL",
          payload: { showEmail: !filterState.attributesShown.email },
        }),
      renderOption: () => (
        <div className={styles.displayOption}>
          <Checkbox
            label="User Email"
            checked={Boolean(filterState.attributesShown.email)}
            onChange={(checked) =>
              filterDispatch({
                type: "SET_SHOW_EMAIL",
                payload: { showEmail: checked },
              })
            }
          />
        </div>
      ),
    },
    {
      label: "User Team",
      onClick: () =>
        filterDispatch({
          type: "SET_SHOW_TEAM",
          payload: { showTeam: !filterState.attributesShown.team },
        }),
      renderOption: () => (
        <div className={styles.displayOption}>
          <Checkbox
            label="User Team"
            checked={Boolean(filterState.attributesShown.team)}
            onChange={(checked) =>
              filterDispatch({
                type: "SET_SHOW_TEAM",
                payload: { showTeam: checked },
              })
            }
          />
        </div>
      ),
    },
    {
      label: "User Role",
      onClick: () =>
        filterDispatch({
          type: "SET_SHOW_POSITION",
          payload: { showPosition: !filterState.attributesShown.position },
        }),
      renderOption: () => (
        <div className={styles.displayOption}>
          <Checkbox
            label="User Role"
            checked={Boolean(filterState.attributesShown.position)}
            onChange={(checked) =>
              filterDispatch({
                type: "SET_SHOW_POSITION",
                payload: { showPosition: checked },
              })
            }
          />
        </div>
      ),
    },
  ];

  return (
    <div className={styles.container}>
      <div className={sprinkles({ display: "flex", gap: "sm" })}>
        <Select
          getIcon={() => ({ type: "name", icon: "stars" })}
          disableBuiltInFiltering
          options={GroupByOptions}
          value={GroupByOptions.find(
            (opt) => filterState.groupBy === opt.value
          )}
          getOptionLabel={(opt) => opt.label}
          onChange={(opt) => {
            filterDispatch({
              type: "UPDATE_GROUP_BY",
              payload: {
                value: opt?.value as "userTeam" | "userRole" | undefined,
              },
            });
          }}
        />
        <ContextMenu
          options={displayOptions}
          renderButton={(onClick) => (
            <div className={styles.dropdownButton} onClick={onClick}>
              <Icon size="xs" name="settings" />
              Display
              <Icon size="xs" name="chevron-down" />
            </div>
          )}
        />
        <Checkbox
          label="Hide Groups"
          checked={Boolean(filterState.hideGroups)}
          onChange={(checked) => {
            filterDispatch({
              type: "UPDATE_HIDE_GROUPS",
              payload: {
                value: checked,
              },
            });
          }}
        />
      </div>
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "center",
          gap: "sm",
        })}
      >
        {hasSelected && (
          <div
            className={styles.clearItems}
            onClick={() => history.push("/insights")}
          >
            <Icon name="x" size="sm" />
            Clear all
          </div>
        )}
        <div className={styles.zoomContainer}>
          <div onClick={props.onZoomIn}>
            <Icon name="plus" size="xs" />
          </div>
          <div onClick={props.onZoomReset}>
            <div className={styles.zoomValue}>{`${Math.round(
              props.zoom * 100
            )} %`}</div>
            <div className={styles.zoomReset}>Reset</div>
          </div>
          <div onClick={props.onZoomOut}>
            <Icon name="minus" size="xs" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopControlBar;
