import { EventType, TaskTrigger } from "api/generated/graphql";

/**
 * Used for mapping EventTypes to strings that are displayed in the app.
 *
 * NOTE: PLEASE ALSO UPDATE THE FUNCTION BELOW IF YOU UPDATE THIS ONE.
 */
export function eventTypeToString(eventType: EventType, pluralize: boolean) {
  let eventName = eventType.toString();
  switch (eventType) {
    case EventType.UsersCreated:
      eventName = "User(s) created";
      break;
    case EventType.UsersDeleted:
      eventName = "User(s) deleted";
      break;
    case EventType.UserTeamUpdated:
      eventName = "User team updated";
      break;
    case EventType.GroupFoldersCreated:
      eventName = "Group folder(s) created";
      break;
    case EventType.GroupFoldersDeleted:
      eventName = "Group folder(s) deleted";
      break;
    case EventType.GroupsCreated:
      eventName = "Group(s) created";
      break;
    case EventType.GroupsDeleted:
      eventName = "Group(s) deleted";
      break;
    case EventType.GroupsUpdated:
      eventName = "Group(s) updated";
      break;
    case EventType.GroupsAddedToFolders:
      eventName = "Group(s) added to folder(s)";
      break;
    case EventType.GroupsRemovedFromFolders:
      eventName = "Group(s) removed from folder(s)";
      break;
    case EventType.GroupsAddedToGroups:
      eventName = "Group(s) added to other group(s)";
      break;
    case EventType.GroupsRemovedFromGroups:
      eventName = "Group(s) removed from other group(s)";
      break;

    case EventType.BreakGlassUsersAddedToGroups:
      eventName = "Break-glass user(s) added to group(s)";
      break;
    case EventType.BreakGlassUsersRemovedFromGroups:
      eventName = "Break-glass user(s) removed from group(s)";
      break;
    case EventType.BreakGlassUsed:
      eventName = "Break-glass access used";
      break;
    case EventType.UsersAddedToConnections:
      eventName = "Users(s) added to app(s)";
      break;
    case EventType.UsersRemovedFromConnections:
      eventName = "Users(s) removed from app(s)";
      break;
    case EventType.UsersAddedToGroups:
      eventName = "User(s) added to group(s)";
      break;
    case EventType.UsersRemovedFromGroups:
      eventName = "User(s) removed from group(s)";
      break;
    case EventType.UsersAddedToResources:
      eventName = "User(s) added to resource(s)";
      break;
    case EventType.UsersRemovedFromResources:
      eventName = "User(s) removed from resource(s)";
      break;
    case EventType.ConnectionUsersUpdated:
      eventName = "App user(s) updated";
      break;
    case EventType.ResourceUsersUpdated:
      eventName = "Resource user(s) updated";
      break;
    case EventType.GroupUsersUpdated:
      eventName = "Group user(s) updated";
      break;
    case EventType.GroupResourcesUpdated:
      eventName = "Group resources(s) updated";
      break;
    case EventType.RoleAssignmentsUpdated:
      eventName = "Role assignment(s) updated";
      break;
    case EventType.UsersUpdated:
      eventName = "User(s) updated";
      break;
    case EventType.UserEmailUpdated:
      eventName = "User(s) email updated";
      break;
    case EventType.UserRemoteIdUpdated:
      eventName = "User(s) remote id updated";
      break;
    case EventType.UserNameUpdated:
      eventName = "User(s) name updated";
      break;
    case EventType.UserPositionUpdated:
      eventName = "User(s) title updated";
      break;
    case EventType.UserManagerUpdated:
      eventName = "User(s) manager updated";
      break;
    case EventType.UserTeamAttrUpdated:
      eventName = "User(s) team attribute updated";
      break;
    case EventType.UserMerged:
      eventName = "User merged into another user";
      break;
    case EventType.UserLoggedInOauth:
      eventName = "User logged into Opal via OAuth";
      break;
    case EventType.UserLoggedInSaml:
      eventName = "User logged into Opal via SAML";
      break;
    case EventType.ConnectionsCreated:
      eventName = "App(s) created";
      break;
    case EventType.ConnectionsDeleted:
      eventName = "App(s) deleted";
      break;
    case EventType.ConnectionsUpdated:
      eventName = "App(s) updated";
      break;
    case EventType.ConnectionNameUpdated:
      eventName = "App name updated";
      break;
    case EventType.ConnectionDescriptionUpdated:
      eventName = "App description updated";
      break;
    case EventType.ConnectionAdminOwnerUpdated:
      eventName = "App admin owner updated";
      break;
    case EventType.ConnectionVisibilityUpdated:
      eventName = "App visibility updated";
      break;
    case EventType.ConnectionImportVisibilityUpdated:
      eventName = "App import visibility updated";
      break;
    case EventType.ConnectionImportSettingUpdated:
      eventName = "App import setting updated";
      break;
    case EventType.ConnectionAutoImportGroupResourcesUpdated:
      eventName = "App auto-import group resources updated";
      break;
    case EventType.ConnectionMetadataUpdated:
      eventName = "App metadata updated";
      break;
    case EventType.ConnectionCredentialsUpdated:
      eventName = "App credentials updated";
      break;
    case EventType.ConnectionWebhookEnabledUpdated:
      eventName = "App webhook enabled updated";
      break;
    case EventType.IdpConnectionUserAttributeMappingCreated:
      eventName = "IDP connection user attribute mapping created";
      break;
    case EventType.IdpConnectionUserAttributeMappingDeleted:
      eventName = "IDP connection user attribute mapping deleted";
      break;
    case EventType.UserMfaReset:
      eventName = "Reset user MFA";
      break;

    case EventType.IdpStatusActive:
      eventName = "IDP status active";
      break;
    case EventType.IdpStatusDeprovisioned:
      eventName = "IDP status deprovisioned";
      break;
    case EventType.IdpStatusDeleted:
      eventName = "IDP status deleted";
      break;
    case EventType.IdpStatusInactive:
      eventName = "IDP status inactive";
      break;
    case EventType.IdpStatusNotFound:
      eventName = "IDP status not found";
      break;
    case EventType.IdpConnectionsCreated:
      eventName = "IDP connection(s) created";
      break;
    case EventType.IdpConnectionsDeleted:
      eventName = "IDP connection(s) deleted";
      break;
    case EventType.IdpConnectionsUpdated:
      eventName = "IDP connection(s) updated";
      break;
    case EventType.ResourceFoldersCreated:
      eventName = "Resource folder(s) created";
      break;
    case EventType.ResourceFoldersDeleted:
      eventName = "Resource folder(s) deleted";
      break;
    case EventType.ResourcesCreated:
      eventName = "Resource(s) created";
      break;
    case EventType.ResourcesDeleted:
      eventName = "Resource(s) deleted";
      break;
    case EventType.ResourcesUpdated:
      eventName = "Resource(s) updated";
      break;
    case EventType.ResourcesAddedToGroups:
      eventName = "Resource(s) added to group(s)";
      break;
    case EventType.ResourcesRemovedFromGroups:
      eventName = "Resource(s) removed from group(s)";
      break;
    case EventType.ResourcesAddedToFolders:
      eventName = "Resource(s) added to folder(s)";
      break;
    case EventType.ResourcesRemovedFromFolders:
      eventName = "Resource(s) removed from folder(s)";
      break;
    case EventType.ResourceConfigurationTemplateUpdated:
      eventName = "Resource configuration template updated";
      break;
    case EventType.RoleAssignmentsCreated:
      eventName = "Role assignment(s) created";
      break;
    case EventType.RoleAssignmentsDeleted:
      eventName = "Role assignment(s) deleted";
      break;
    case EventType.RequestsCreated:
      eventName = "Request(s) created";
      break;
    case EventType.RequestsApproved:
      eventName = "Request(s) approved";
      break;
    case EventType.RequestsAdminApproved:
      eventName = "Request(s) admin approved";
      break;
    case EventType.RequestsDenied:
      eventName = "Request(s) denied";
      break;
    case EventType.RequestsAdminDenied:
      eventName = "Request(s) admin denied";
      break;
    case EventType.RequestsCanceled:
      eventName = "Request(s) canceled";
      break;
    case EventType.RequestCommentAdded:
      eventName = "Comment added to request";
      break;
    case EventType.RequestReviewersAddedToRequests:
      eventName = "Request reviewer(s) added to request(s)";
      break;
    case EventType.RequestReviewersApproved:
      eventName = "Request reviewer(s) approved";
      break;
    case EventType.RequestReviewersDenied:
      eventName = "Request reviewer(s) denied";
      break;
    case EventType.RequestResourceRequested:
      eventName = "Resource requested";
      break;
    case EventType.RequestGroupRequested:
      eventName = "Group requested";
      break;
    case EventType.RequestSyncTicketCreated:
      eventName = "Request sync ticket(s) created";
      break;
    case EventType.RequestSyncTicketUpdated:
      eventName = "Request sync ticket(s) updated";
      break;
    case EventType.RequestSyncTicketClosed:
      eventName = "Request sync ticket(s) closed";
      break;
    case EventType.RequestSyncTicketCommented:
      eventName = "Request sync ticket(s) commented";
      break;
    case EventType.RequestSyncTicketError:
      eventName = "Request sync ticket(s) had an error";
      break;
    case EventType.ReviewersAddedToResources:
      eventName = "Reviewer(s) added to resource(s)";
      break;
    case EventType.ReviewersRemovedFromResources:
      eventName = "Reviewer(s) removed from resource(s)";
      break;
    case EventType.ReviewersAddedToGroups:
      eventName = "Reviewer(s) added to group(s)";
      break;
    case EventType.ReviewersRemovedFromGroups:
      eventName = "Reviewer(s) removed from group(s)";
      break;
    case EventType.GroupReviewerStageCreated:
      eventName = "Group reviewer stage(s) created";
      break;
    case EventType.GroupReviewerStageUpdated:
      eventName = "Group reviewer stage(s) updated";
      break;
    case EventType.GroupReviewerStageDeleted:
      eventName = "Group reviewer stage(s) deleted";
      break;
    case EventType.ResourceReviewerStageCreated:
      eventName = "Resource reviewer stage(s) created";
      break;
    case EventType.ResourceReviewerStageUpdated:
      eventName = "Resource reviewer stage(s) updated";
      break;
    case EventType.ResourceReviewerStageDeleted:
      eventName = "Resource reviewer stage(s) deleted";
      break;
    case EventType.ReviewersReminded:
      eventName = "Reviewer(s) reminded";
      break;
    case EventType.OnCallSchedulesCreated:
      eventName = "On-call schedule(s) created";
      break;
    case EventType.OnCallSchedulesUpdated:
      eventName = "On-call schedule(s) updated";
      break;
    case EventType.OnCallSchedulesDeleted:
      eventName = "On-call schedule(s) deleted";
      break;
    case EventType.OnCallSchedulesAddedToGroups:
      eventName = "On-call schedule(s) added to group(s)";
      break;
    case EventType.OnCallSchedulesUpdatedForGroups:
      eventName = "On-call schedule(s) updated for group(s)";
      break;
    case EventType.OnCallSchedulesRemovedFromGroups:
      eventName = "On-call schedule(s) removed from group(s)";
      break;
    case EventType.AccessReviewsCreated:
      eventName = "Access review(s) created";
      break;
    case EventType.AccessReviewsUpdated:
      eventName = "Access review(s) updated";
      break;
    case EventType.SessionsCreatedForResources:
      eventName = "Session(s) started";
      break;
    case EventType.ThirdPartyIntegrationCreated:
      eventName = "Third party integration created";
      break;
    case EventType.ThirdPartyIntegrationDeleted:
      eventName = "Third party integration deleted";
      break;
    case EventType.ApiTokenCreated:
      eventName = "API token created";
      break;
    case EventType.ApiTokenDeleted:
      eventName = "API token deleted";
      break;
    case EventType.AccessReviewAutoAssignReviewerByOwningTeamAdmin:
      eventName =
        "Access review reviewer(s) assigned by resource / group admin";
      break;
    case EventType.AccessReviewAutoAssignReviewerByManager:
      eventName = "Access review reviewer(s) assigned by manager";
      break;
    case EventType.AccessReviewAutoAssignReviewerByApprovers:
      eventName = "Access review reviewer(s) assigned by approvers";
      break;
    case EventType.AccessReviewResourceReviewersUpdated:
      eventName = "Access review resource reviewer(s) updated";
      break;
    case EventType.AccessReviewReviewerForResourceUserSet:
      eventName = "Reviewer for resource user set";
      break;
    case EventType.AccessReviewReviewerForResourceResourceSet:
      eventName = "Reviewer for resource set";
      break;
    case EventType.UserNotified:
      eventName = "User(s) notified";
      break;
    case EventType.UserNotificationFailed:
      eventName = "User(s) not notified due to error";
      break;
    case EventType.UserNotNotified:
      eventName = "User(s) not notified";
      break;
    case EventType.ReviewersEscalated:
      eventName = "Reviewer(s) escalated";
      break;
    case EventType.AccessReviewConnectionReviewed:
      eventName = "Access review app reviewed";
      break;
    case EventType.AccessReviewConnectionReviewersUpdated:
      eventName = "Access review app reviewers updated";
      break;
    case EventType.AccessReviewReviewerForConnectionUserSet:
      eventName = "Reviewer for app user set";
      break;
    case EventType.AccessReviewUserAccessToConnectionAccepted:
      eventName = "App user access accepted";
      break;
    case EventType.AccessReviewUserAccessToConnectionRevoked:
      eventName = "App user access revoked";
      break;
    case EventType.AccessReviewUserAccessToConnectionRevokedDecision:
      eventName = "App user access revoked decision recorded";
      break;
    case EventType.AccessReviewResourceReviewed:
      eventName = "Access review resource reviewed";
      break;
    case EventType.AccessReviewReviewPerformed:
      eventName = "Review performed";
      break;
    case EventType.AccessReviewUserAccessToResourceChanged:
      eventName = "Resource user access changed";
      break;
    case EventType.AccessReviewUserAccessToResourceChangedDecision:
      eventName = "Resource user access changed decision recorded";
      break;
    case EventType.AccessReviewUserAccessToResourceAccepted:
      eventName = "Resource user access accepted";
      break;
    case EventType.AccessReviewUserAccessToResourceRevoked:
      eventName = "Resource user access revoked";
      break;
    case EventType.AccessReviewUserAccessToResourceRevokedDecision:
      eventName = "Resource user access revoked decision recorded";
      break;
    case EventType.AccessReviewUserAccessToResourceChangedFailure:
      eventName = "User access to resource change failed";
      break;
    case EventType.AccessReviewUserAccessToGroupChangedFailure:
      eventName = "User access to group change failed";
      break;
    case EventType.AccessReviewUserAccessToResourceRevokedFromEndSystem:
      eventName = "Resource user end system access revocation confirmed";
      break;
    case EventType.AccessReviewGroupReviewersUpdated:
      eventName = "Access review group reviewer(s) updated";
      break;
    case EventType.AccessReviewReviewerForGroupUserSet:
      eventName = "Reviewer for group user set";
      break;
    case EventType.AccessReviewReviewerForGroupResourceSet:
      eventName = "Reviewer for group resource set";
      break;
    case EventType.AccessReviewGroupReviewed:
      eventName = "Access review group reviewed";
      break;
    case EventType.AccessReviewUserAccessToGroupAccepted:
      eventName = "Group user access accepted";
      break;
    case EventType.AccessReviewUserAccessToGroupChanged:
      eventName = "Group user access changed";
      break;
    case EventType.AccessReviewUserAccessToGroupChangedDecision:
      eventName = "Group user access changed decision recorded";
      break;
    case EventType.AccessReviewUserAccessToGroupRevoked:
      eventName = "Group user access revoked";
      break;
    case EventType.AccessReviewUserAccessToGroupRevokedDecision:
      eventName = "Group user access revoked decision recorded";
      break;
    case EventType.AccessReviewResourceAccessToGroupAccepted:
      eventName = "Group resource access accepted";
      break;
    case EventType.AccessReviewResourceAccessToGroupRevoked:
      eventName = "Group resource access revoked";
      break;
    case EventType.AccessReviewResourceAccessToGroupRevokedDecision:
      eventName = "Group resource access revoked decision recorded";
      break;
    case EventType.AccessReviewNhiAccessToResourceAccepted:
      eventName = "NHI access to resource accepted";
      break;
    case EventType.AccessReviewNhiAccessToResourceRevoked:
      eventName = "NHI access to resource revoked";
      break;
    case EventType.AccessReviewNhiAccessToResourceRevokedDecision:
      eventName = "NHI access to resource revoked decision recorded";
      break;
    case EventType.AccessReviewUserAccessToGroupRevokedFromEndSystem:
      eventName = "Group user end system access revocation confirmed";
      break;
    case EventType.AccessReviewStopped:
      eventName = "Access review stopped";
      break;
    case EventType.MessageChannelsCreated:
      eventName = "Message channel(s) created";
      break;
    case EventType.MessageChannelsDeleted:
      eventName = "Message channel(s) deleted";
      break;
    case EventType.MessageChannelsAddedToGroups:
      eventName = "Message channel(s) added to group(s)";
      break;
    case EventType.MessageChannelsAddedToResources:
      eventName = "Message channel(s) added to resource(s)";
      break;
    case EventType.MessageChannelsRemovedFromResources:
      eventName = "Message channel(s) removed from resource(s)";
      break;
    case EventType.MessageChannelsRemovedFromGroups:
      eventName = "Message channel(s) removed from group(s)";
      break;
    case EventType.OrgSettingsUpdated:
      eventName = "Organization settings updated";
      break;
    case EventType.ToxicSetViolationsCreated:
      eventName = "Toxic set violation(s) created";
      break;
    case EventType.ToxicSetViolationsUpdated:
      eventName = "Toxic set violation(s) updated";
      break;
    case EventType.ToxicSetViolationsRemediated:
      eventName = "Toxic set violation(s) remediated";
      break;
    case EventType.EventMonitorEventsDetected:
      eventName = "Event monitor events detected";
      break;
    case EventType.AccessReviewResourceUserSupportTicketLinked:
      eventName = "Support ticket linked in access review";
      break;
    case EventType.AccessReviewResourceUserSupportTicketUnlinked:
      eventName = "Support ticket was not found and unlinked";
      break;
    case EventType.AccessReviewTemplatesCreated:
      eventName = "Access review schedule created";
      break;
    case EventType.AccessReviewTemplatesDeleted:
      eventName = "Access review schedule(s) deleted";
      break;
    case EventType.AccessReviewTemplatesUpdated:
      eventName = "Access review schedule updated";
      break;
    case EventType.BundlesCreated:
      eventName = "Bundle(s) created";
      break;
    case EventType.BundlesDeleted:
      eventName = "Bundle(s) deleted";
      break;
    case EventType.BundlesUpdated:
      eventName = "Bundle(s) updated";
      break;
    case EventType.BundleGroupsAdded:
      eventName = "Group(s) added to bundle";
      break;
    case EventType.BundleGroupsRemoved:
      eventName = "Group(s) removed from bundle";
      break;
    case EventType.BundleResourcesAdded:
      eventName = "Resource(s) added to bundle";
      break;
    case EventType.BundleResourcesRemoved:
      eventName = "Resource(s) removed from bundle";
      break;

    // REMOTE EVENTS BELOW
    case EventType.RemoteEventUserAddedToResource:
      eventName = "User added to resource on remote system";
      break;
    case EventType.RemoteEventUserRemovedFromResource:
      eventName = "User removed from resource on remote system";
      break;
    case EventType.RemoteEventUserAddedToGroup:
      eventName = "User added to group on remote system";
      break;
    case EventType.RemoteEventUserRemovedFromGroup:
      eventName = "User removed from group on remote system";
      break;
    case EventType.RemoteEventGroupAddedToResource:
      eventName = "Group added to resource on remote system";
      break;
    case EventType.RemoteEventGroupRemovedFromResource:
      eventName = "Group removed from resource on remote system";
      break;
    case EventType.RemoteEventResourcesCreated:
      eventName = "Resources created on remote system";
      break;
    case EventType.RemoteEventResourcesDeleted:
      eventName = "Resources deleted on remote system";
      break;
    case EventType.RemoteEventGroupsCreated:
      eventName = "Groups created on remote system";
      break;
    case EventType.RemoteEventGroupsDeleted:
      eventName = "Groups deleted on remote system";
      break;
    case EventType.RemoteEventLoginSuccess:
      eventName = "Logged in / used";
      break;
    case EventType.RemoteEventResourceAccessAdded:
      eventName = "User added to resource on remote system";
      break;
    case EventType.RemoteEventResourceAccessRemoved:
      eventName = "User removed from resource on remote system";
      break;
    case EventType.RemoteEventGroupAccessAdded:
      eventName = "User added to group on remote system";
      break;
    case EventType.RemoteEventGroupAccessRemoved:
      eventName = "User removed from group on remote system";
      break;
    case EventType.RemoteEventUserPasswordExpired:
      eventName = "User password has expired on remote system";
      break;
    case EventType.RemoteEventUserPasswordReset:
      eventName = "User password has been reset on remote system";
      break;
    case EventType.RemoteEventUserPasswordUpdated:
      eventName = "User password has been changed on remote system";
      break;
    case EventType.PropagatedAddUserToGroup:
      eventName =
        "Started propagating add user(s) to group(s) on remote system";
      break;
    case EventType.PropagatedRemoveUserFromGroup:
      eventName =
        "Started propagating remove user(s) from group(s) on remote system";
      break;
    case EventType.PropagatedAddUserToResource:
      eventName =
        "Started propagating add user(s) to resource(s) on remote system";
      break;
    case EventType.PropagatedRemoveUserFromResource:
      eventName =
        "Started propagating remove user(s) from resource(s) on remote system";
      break;
    case EventType.PropagatedAddResourceToGroup:
      eventName =
        "Started propagating add resource(s) to group(s) on remote system";
      break;
    case EventType.PropagatedRemoveResourceFromGroup:
      eventName =
        "Started propagating remove resource(s) from group(s) on remote system";
      break;
    case EventType.PropagatedRemoveUserFromConnection:
      eventName =
        "Started propagating removal of user(s) from app(s) on remote system";
      break;
    case EventType.GroupVisibilityGroupsAdded:
      eventName = "Group visibility groups added";
      break;
    case EventType.GroupVisibilityGroupsRemoved:
      eventName = "Group visibility groups removed";
      break;
    case EventType.ConnectionAppVisibilityGroupsChanged:
      eventName = "App visibility groups changed";
      break;
    case EventType.ConnectionAppVisibilityGroupsAdded:
      eventName = "App visibility groups added";
      break;
    case EventType.ConnectionAppVisibilityGroupsRemoved:
      eventName = "App visibility groups removed";
      break;
    // Import visibility group events
    case EventType.ConnectionVisibilityGroupsAdded:
      eventName = "App import visibility groups added";
      break;
    case EventType.ConnectionVisibilityGroupsRemoved:
      eventName = "App import visibility groups removed";
      break;
    case EventType.ResourceVisibilityGroupsAdded:
      eventName = "Resource visibility groups added";
      break;
    case EventType.ResourceVisibilityGroupsRemoved:
      eventName = "Resource visibility groups removed";
      break;
    case EventType.OwnersCreated:
      eventName = "Owners created";
      break;
    case EventType.OwnersUpdated:
      eventName = "Owners updated";
      break;
    case EventType.OwnersDeleted:
      eventName = "Owners deleted";
      break;
    case EventType.OwnerUsersAdded:
      eventName = "Owner users added";
      break;
    case EventType.OwnerUsersUpdated:
      eventName = "Owner users updated";
      break;
    case EventType.OwnerUsersRemoved:
      eventName = "Owner users removed";
      break;
    case EventType.OwnerReviewerChannelUpdated:
      eventName = "Owner reviewer channel updated";
      break;
    case EventType.OwnerReviewerChannelRemoved:
      eventName = "Owner reviewer channel removed";
      break;
    case EventType.OwnerSourceGroupRemoved:
      eventName = "Owner source group removed";
      break;
    case EventType.OwnerSourceGroupUpdated:
      eventName = "Owner source group updated";
      break;
    case EventType.GroupAccessRequestEscalationPeriodUpdated:
      eventName = "Group access request escalation period updated";
      break;
    case EventType.GroupAdminOwnerUpdated:
      eventName = "Group admin owner updated";
      break;
    case EventType.GroupAutoApprovalUpdated:
      eventName = "Group auto-approval updated";
      break;
    case EventType.GroupFunctionUpdated:
      eventName = "Group function updated";
      break;
    case EventType.GroupIsRequestableUpdated:
      eventName = "Group ability to request updated";
      break;
    case EventType.GroupMaxDurationUpdated:
      eventName = "Group max duration updated";
      break;
    case EventType.GroupNameUpdated:
      eventName = "Group name updated";
      break;
    case EventType.GroupRecommendedDurationUpdated:
      eventName = "Group recommended duration updated";
      break;
    case EventType.GroupRequireManagerApprovalUpdated:
      eventName = "Group require manager approval updated";
      break;
    case EventType.GroupRequireMfaUpdated:
      eventName = "Group require MFA updated";
      break;
    case EventType.GroupRequireSupportTicketUpdated:
      eventName = "Group require support ticket updated";
      break;
    case EventType.GroupRequestRequireMfaUpdated:
      eventName = "Group request require MFA updated";
      break;
    case EventType.GroupVisibilityUpdated:
      eventName = "Group visibility updated";
      break;
    case EventType.GroupConfigurationTemplateUpdated:
      eventName = "Group configuration template updated";
      break;
    case EventType.ResourceAdminOwnerUpdated:
      eventName = "Resource admin owner updated";
      break;
    case EventType.ResourceAutoApprovalUpdated:
      eventName = "Resource auto-approval updated";
      break;
    case EventType.ResourceMaxDurationUpdated:
      eventName = "Resource max duration updated";
      break;
    case EventType.ResourceIsRequestableUpdated:
      eventName = "Resource ability to request updated";
      break;
    case EventType.ResourceNameUpdated:
      eventName = "Resource name updated";
      break;
    case EventType.ResourceRecommendedDurationUpdated:
      eventName = "Resource recommended duration updated";
      break;
    case EventType.ResourceRequireManagerApprovalUpdated:
      eventName = "Resource require manager approval updated";
      break;
    case EventType.ResourceApproveRequireMfaUpdated:
      eventName = "Resource approve require MFA updated";
      break;
    case EventType.ResourceConnectRequireMfaUpdated:
      eventName = "Resource connect require MFA updated";
      break;
    case EventType.ResourceRequireSupportTicketUpdated:
      eventName = "Resource require support ticket updated";
      break;
    case EventType.ResourceVisibilityUpdated:
      eventName = "Resource visibility updated";
      break;
    case EventType.RequestSupportTicketAdded:
      eventName = "Request support ticket added";
      break;
    case EventType.RequestSkipManagerAddedToRequests:
      eventName = "Request escalated to skip manager";
      break;
    case EventType.PropagationTimedOut:
      eventName = "Propagation timed out";
      break;
    case EventType.ResourceRequestRequireMfaUpdated:
      eventName = "Resource request require MFA updated";
      break;
    case EventType.PropagationSuccessAddUserToGroup:
      eventName =
        "Successfully propagated addition of user to group on remote system";
      break;
    case EventType.PropagationSuccessAddUserToResource:
      eventName =
        "Successfully propagated addition of user to resource on remote system";
      break;
    case EventType.PropagationSuccessAddResourceToGroup:
      eventName =
        "Successfully propagated addition of resource to group on remote system";
      break;
    case EventType.PropagationSuccessRemoveUserFromGroup:
      eventName =
        "Successfully propagated removal of user from group on remote system";
      break;
    case EventType.PropagationSuccessRemoveUserFromResource:
      eventName =
        "Successfully propagated removal of user from resource on remote system";
      break;
    case EventType.PropagationSuccessRemoveResourceFromGroup:
      eventName =
        "Successfully propagated removal of resource from group on remote system";
      break;
    case EventType.PropagationSuccessRemoveUserFromConnection:
      eventName =
        "Successfully propagated removal of user from app on remote system";
      break;
    case EventType.PropagationFailureAddUserToGroup:
      eventName =
        "Failed to propagate addition of user to group on remote system";
      break;
    case EventType.PropagationFailureAddUserToResource:
      eventName =
        "Failed to propagate addition of user to resource on remote system";
      break;
    case EventType.PropagationFailureAddResourceToGroup:
      eventName =
        "Failed to propagate addition of resource to group on remote system";
      break;
    case EventType.PropagationFailureRemoveUserFromGroup:
      eventName =
        "Failed to propagate removal of user from group on remote system";
      break;
    case EventType.PropagationFailureRemoveUserFromResource:
      eventName =
        "Failed to propagate removal of user from resource on remote system";
      break;
    case EventType.PropagationFailureRemoveResourceFromGroup:
      eventName =
        "Failed to propagate removal of resource from group on remote system";
      break;
    case EventType.PropagationFailureRemoveUserFromConnection:
      eventName =
        "Failed to propagate removal of user from app on remote system";
      break;
    case EventType.PropagationTicketCreatedRemotely:
      eventName = "Ticket(s) created on remote system(s)";
      break;
    case EventType.PropagationTicketUpdatedRemotely:
      eventName = "Ticket(s) updated on remote system(s)";
      break;
    case EventType.PropagationManualAddUserToResource:
      eventName =
        "Waiting on manual propagation of addition of user to resource on remote system";
      break;
    case EventType.PropagationManualRemoveUserFromResource:
      eventName =
        "Waiting on manual propagation of removal of user from resource on remote system";
      break;
    case EventType.ConfigurationTemplateCreated:
      eventName = "Configuration template created";
      break;
    case EventType.ConfigurationTemplateUpdated:
      eventName = "Configuration template updated";
      break;
    case EventType.ConfigurationTemplateDeleted:
      eventName = "Configuration template deleted";
      break;
    case EventType.ConfigurationTemplateNameUpdated:
      eventName = "Configuration template name updated";
      break;
    case EventType.ConfigurationTemplateAdminOwnerUpdated:
      eventName = "Configuration template admin owner updated";
      break;
    case EventType.ConfigurationTemplateVisibilityUpdated:
      eventName = "Configuration template visibility updated";
      break;
    case EventType.ConfigurationTemplateRequireMfaToApproveUpdated:
      eventName = "Configuration template require MFA to approve updated";
      break;
    case EventType.ConfigurationTemplateRequireMfaToConnectUpdated:
      eventName = "Configuration template require MFA to connect updated";
      break;
    case EventType.ConfigurationTemplateRequestConfigUpdated:
      eventName = "Configuration template request config updated";
      break;
    case EventType.ConfigurationTemplateRequestConfigDeleted:
      eventName = "Configuration template request config deleted";
      break;
    case EventType.ConfigurationTemplateAuditMessageChannelUpdated:
      eventName = "Configuration template audit message channel updated";
      break;
    case EventType.ConfigurationTemplateOncallSchedulesUpdated:
      eventName = "Configuration template on-call schedules updated";
      break;
    case EventType.ConfigurationTemplateBreakGlassUsersUpdated:
      eventName = "Configuration template break-glass users updated";
      break;
    case EventType.ConfigurationTemplateVisibilityGroupsUpdated:
      eventName = "Configuration template visibility groups updated";
      break;
    case EventType.ResourceAutoImportConfigTemplateUpdated:
      eventName = "Resource auto-import config template updated";
      break;
    case EventType.ConnectionAutoImportConfigTemplateUpdated:
      eventName = "Auto-import config template updated";
      break;

    case EventType.GroupBindingCreated:
      eventName = "Group link created";
      break;
    case EventType.GroupBindingUpdated:
      eventName = "Group link updated";
      break;
    case EventType.GroupBindingDeleted:
      eventName = "Group link deleted";
      break;
    case EventType.GroupAddedToBinding:
      eventName = "Group added to link";
      break;
    case EventType.GroupRemovedFromBinding:
      eventName = "Group removed from link";
      break;
    case EventType.GroupBindingSourceGroupUpdated:
      eventName = "Group link source group updated";
      break;
    case EventType.EventStreamConnectionsCreated:
      eventName = "Event stream connection created";
      break;
    case EventType.EventStreamConnectionsUpdated:
      eventName = "Event stream connection updated";
      break;
    case EventType.EventStreamConnectionsDeleted:
      eventName = "Event stream connection deleted";
      break;
    case EventType.ImpersonationSessionStarted:
      eventName = "Customer Support impersonation session started";
      break;
    case EventType.ImpersonationSessionStopped:
      eventName = "Customer Support impersonation session stopped";
      break;
    case EventType.GroupGroupsUpdated:
      eventName = "Nested group(s) updated";
      break;
    case EventType.GroupLeadersCreated:
      eventName = "Group leader(s) created";
      break;
    case EventType.GroupLeadersRemoved:
      eventName = "Group leader(s) removed";
      break;
    case EventType.ConfigurationTemplateTicketPropagationUpdated:
      eventName = "Configuration template ticket propagation updated";
      break;
    case EventType.PropagationComputeRemoteUserPushTasks:
      eventName = "Propagated user access on remote system";
      break;
    case EventType.PropagationFailureRoleAssignmentCreated:
      eventName =
        "Failed to propagate creation of role assignment on remote system";
      break;
    case EventType.PropagationSuccessRoleAssignmentCreated:
      eventName =
        "Successfully propagated creation of role assignment on remote system";
      break;
    case EventType.PropagationSuccessRoleAssignmentDeleted:
      eventName =
        "Successfully propagated deletion of role assignment on remote system";
      break;
    case EventType.PropagationFailureRoleAssignmentDeleted:
      eventName =
        "Failed to propagate deletion of role assignment on remote system";
      break;
    case EventType.ResourceTicketPropagationUpdated:
      eventName = "Resource ticket propagation updated";
      break;
    case EventType.RemoteEventResourceRead:
      eventName = "Remote event resource read";
      break;
    case EventType.IdpGroupMappingCreated:
      eventName = "IDP Group Mapping(s) Created";
      break;
    case EventType.IdpGroupMappingDeleted:
      eventName = "IDP Group Mapping(s) Deleted";
      break;
    case EventType.IdpGroupMappingUpdated:
      eventName = "IDP Group Mapping(s) Updated";
      break;
  }

  if (!pluralize) {
    eventName = eventName.replace(/\(s\)/g, "");
  }

  return eventName;
}

/**
 * Used for mapping EventTypes to strings that are displayed in the app.
 *
 * NOTE: PLEASE ALSO UPDATE THE FUNCTION ABOVE IF YOU UPDATE THIS ONE.
 */
export function subEventTypeToString(eventType: EventType) {
  let eventName = "";
  switch (eventType) {
    case EventType.GroupsAddedToFolders:
    case EventType.GroupsAddedToGroups:
    case EventType.UsersAddedToGroups:
    case EventType.UsersAddedToResources:
    case EventType.ResourcesAddedToGroups:
    case EventType.ResourcesAddedToFolders:
    case EventType.MessageChannelsAddedToGroups:
    case EventType.MessageChannelsAddedToResources:
    case EventType.OnCallSchedulesAddedToGroups:
    case EventType.UsersAddedToConnections:
    case EventType.RemoteEventResourceAccessAdded:
    case EventType.RemoteEventGroupAccessAdded:
    case EventType.RemoteEventGroupAddedToResource:
    case EventType.RemoteEventUserAddedToGroup:
    case EventType.RemoteEventUserAddedToResource:
    case EventType.RoleAssignmentsCreated:
      eventName = "added to...";
      break;
    case EventType.ResourceAdminOwnerUpdated:
    case EventType.GroupAdminOwnerUpdated:
    case EventType.ConnectionAdminOwnerUpdated:
      eventName = "owner updated to...";
      break;
    case EventType.PropagatedAddResourceToGroup:
    case EventType.PropagatedAddUserToGroup:
    case EventType.PropagatedAddUserToResource:
      eventName = "started propagating to remote for...";
      break;
    case EventType.PropagationSuccessAddUserToGroup:
    case EventType.PropagationSuccessAddUserToResource:
    case EventType.PropagationSuccessAddResourceToGroup:
    case EventType.PropagationSuccessRoleAssignmentCreated:
      eventName = "successfully propagated to remote for...";
      break;
    case EventType.PropagationSuccessRemoveUserFromGroup:
    case EventType.PropagationSuccessRemoveUserFromResource:
    case EventType.PropagationSuccessRemoveResourceFromGroup:
    case EventType.PropagationSuccessRemoveUserFromConnection:
    case EventType.PropagationSuccessRoleAssignmentDeleted:
      eventName = "successfully propagated removal from remote for...";
      break;
    case EventType.PropagatedRemoveResourceFromGroup:
    case EventType.PropagatedRemoveUserFromGroup:
    case EventType.PropagatedRemoveUserFromResource:
    case EventType.PropagatedRemoveUserFromConnection:
      eventName = "started propagating removal from remote for...";
      break;
    case EventType.PropagationFailureAddUserToGroup:
    case EventType.PropagationFailureAddUserToResource:
    case EventType.PropagationFailureAddResourceToGroup:
      eventName = "failed to propagate to remote for...";
      break;
    case EventType.PropagationFailureRemoveUserFromGroup:
    case EventType.PropagationFailureRemoveUserFromResource:
    case EventType.PropagationFailureRemoveResourceFromGroup:
    case EventType.PropagationFailureRemoveUserFromConnection:
      eventName = "failed to propagate removal from remote for...";
      break;
    case EventType.PropagationTicketCreatedRemotely:
      eventName = "ticket created remotely for...";
      break;
    case EventType.PropagationTicketUpdatedRemotely:
      eventName = "ticket updated remotely for...";
      break;
    case EventType.PropagationManualRemoveUserFromResource:
    case EventType.PropagationManualAddUserToResource:
      eventName = "waiting on manual propagation to remote for...";
      break;
    case EventType.GroupsRemovedFromFolders:
    case EventType.GroupsRemovedFromGroups:
    case EventType.UsersRemovedFromGroups:
    case EventType.UsersRemovedFromResources:
    case EventType.ResourcesRemovedFromGroups:
    case EventType.ResourcesRemovedFromFolders:
    case EventType.MessageChannelsRemovedFromResources:
    case EventType.MessageChannelsRemovedFromGroups:
    case EventType.OnCallSchedulesRemovedFromGroups:
    case EventType.UsersRemovedFromConnections:
    case EventType.RemoteEventResourceAccessRemoved:
    case EventType.RemoteEventGroupAccessRemoved:
    case EventType.RemoteEventUserRemovedFromGroup:
    case EventType.RemoteEventUserRemovedFromResource:
    case EventType.RemoteEventGroupRemovedFromResource:
    case EventType.RoleAssignmentsDeleted:
      eventName = "removed from...";
      break;
    case EventType.ConnectionAppVisibilityGroupsAdded:
    case EventType.ConnectionVisibilityGroupsAdded:
    case EventType.GroupVisibilityGroupsAdded:
    case EventType.ResourceVisibilityGroupsAdded:
      eventName = "added visibility groups";
      break;
    case EventType.ConnectionAppVisibilityGroupsRemoved:
    case EventType.ConnectionVisibilityGroupsRemoved:
    case EventType.GroupVisibilityGroupsRemoved:
    case EventType.ResourceVisibilityGroupsRemoved:
      eventName = "removed visibility groups";
      break;

    case EventType.ResourceNameUpdated:
    case EventType.GroupNameUpdated:
    case EventType.ConnectionNameUpdated:
      eventName = "name updated";
      break;
    case EventType.GroupFunctionUpdated:
      eventName = "function updated";
      break;
    case EventType.ResourceIsRequestableUpdated:
    case EventType.GroupIsRequestableUpdated:
      eventName = "ability to request updated";
      break;
    case EventType.ResourceVisibilityUpdated:
    case EventType.GroupVisibilityUpdated:
    case EventType.ConnectionVisibilityUpdated:
      eventName = "visibility updated";
      break;
    case EventType.ConnectionImportVisibilityUpdated:
      eventName = "import visibility updated";
      break;
    case EventType.ResourceMaxDurationUpdated:
    case EventType.GroupMaxDurationUpdated:
      eventName = "request max duration updated";
      break;
    case EventType.GroupAccessRequestEscalationPeriodUpdated:
      eventName = "escalation period updated";
      break;
    case EventType.ResourceRequireSupportTicketUpdated:
    case EventType.GroupRequireSupportTicketUpdated:
      eventName = "support ticket requirement updated";
      break;
    case EventType.ResourceRequireManagerApprovalUpdated:
    case EventType.GroupRequireManagerApprovalUpdated:
      eventName = "manager approval requirement updated";
      break;
    case EventType.ResourceApproveRequireMfaUpdated:
    case EventType.ResourceConnectRequireMfaUpdated:
    case EventType.GroupRequireMfaUpdated:
      eventName = "MFA requirement updated";
      break;
    case EventType.ResourceAutoApprovalUpdated:
    case EventType.GroupAutoApprovalUpdated:
      eventName = "auto approval updated";
      break;
    case EventType.RequestSupportTicketAdded:
      eventName = "support ticket bound...";
      break;
    case EventType.RequestSkipManagerAddedToRequests:
      eventName = "escalated to skip manager";
      break;
    case EventType.ReviewersAddedToResources:
    case EventType.ReviewersAddedToGroups:
    case EventType.RequestReviewersAddedToRequests:
    case EventType.AccessReviewReviewerForResourceUserSet:
    case EventType.AccessReviewReviewerForGroupUserSet:
    case EventType.AccessReviewReviewerForConnectionUserSet:
    case EventType.AccessReviewReviewerForGroupResourceSet:
    case EventType.AccessReviewReviewerForResourceResourceSet:
      eventName = "added as a reviewer for...";
      break;
    case EventType.ReviewersRemovedFromResources:
    case EventType.ReviewersRemovedFromGroups:
      eventName = "removed as a reviewer for...";
      break;
    case EventType.RequestReviewersApproved:
      eventName = "reviewer approved";
      break;
    case EventType.RequestReviewersDenied:
      eventName = "reviewer denied";
      break;
    case EventType.RequestsApproved:
      eventName = "approved request";
      break;
    case EventType.UserNotified:
      eventName = "user notified";
      break;
    case EventType.RequestResourceRequested:
      eventName = "resource requested...";
      break;
    case EventType.RequestGroupRequested:
      eventName = "group requested...";
      break;
    case EventType.OrgSettingsUpdated:
      break;
    case EventType.ReviewersReminded:
      eventName = "was reminded to review...";
      break;
    case EventType.OnCallSchedulesCreated:
      eventName = "on-call schedule created";
      break;
    case EventType.OnCallSchedulesUpdated:
      eventName = "on-call schedule updated";
      break;
    case EventType.OnCallSchedulesDeleted:
      eventName = "on-call schedule deleted";
      break;
    case EventType.OnCallSchedulesUpdatedForGroups:
      eventName = "on-call schedule updated for...";
      break;
    case EventType.BreakGlassUsersAddedToGroups:
      eventName = "break-glass user added to...";
      break;
    case EventType.BreakGlassUsersRemovedFromGroups:
      eventName = "break-glass user removed from...";
      break;
    case EventType.BreakGlassUsed:
      eventName = "used break-glass access on...";
      break;
    case EventType.GroupLeadersCreated:
      eventName = "added as group leader of...";
      break;
    case EventType.GroupLeadersRemoved:
      eventName = "removed as group leader of...";
      break;
    case EventType.OwnersCreated:
      eventName = "owner has been created";
      break;
    case EventType.OwnersUpdated:
      eventName = "owner has been updated";
      break;
    case EventType.OwnersDeleted:
      eventName = "owner has been deleted";
      break;
    case EventType.OwnerUsersAdded:
      eventName = "has been added to owner";
      break;
    case EventType.OwnerUsersUpdated:
      eventName = "has been updated on owner";
      break;
    case EventType.OwnerUsersRemoved:
      eventName = "has been removed from owner";
      break;
    case EventType.OwnerReviewerChannelUpdated:
      eventName = "owner reviewer channel changed to...";
      break;
    case EventType.OwnerReviewerChannelRemoved:
      eventName = "owner reviewer channel has been removed";
      break;
    case EventType.OwnerSourceGroupUpdated:
      eventName = "owner source group changed to...";
      break;
    case EventType.OwnerSourceGroupRemoved:
      eventName = "owner source group has been removed";
      break;
    case EventType.ConnectionCredentialsUpdated:
      eventName = "app credentials updated";
      break;
    case EventType.ConnectionMetadataUpdated:
      eventName = "app setup updated";
      break;
    case EventType.ConnectionDescriptionUpdated:
      eventName = "description updated";
      break;
    case EventType.ConnectionAutoImportGroupResourcesUpdated:
      eventName = "auto-import group resources updated";
      break;
    case EventType.ConnectionImportSettingUpdated:
      eventName = "import setting updated";
      break;
    case EventType.ConnectionWebhookEnabledUpdated:
      eventName = "webhook enabled updated";
      break;

    // REMOTE EVENTS BELOW
    case EventType.RemoteEventLoginSuccess:
      eventName = "Logged in / used";
      break;
    case EventType.RemoteEventUserPasswordUpdated:
      eventName = "changed password on...";
      break;
    case EventType.RemoteEventUserPasswordReset:
      eventName = "reset password on...";
      break;
    case EventType.RemoteEventUserPasswordExpired:
      eventName = "password expired on...";
      break;
    case EventType.RemoteEventGroupsCreated:
      eventName = "created group";
      break;
    case EventType.RemoteEventGroupsDeleted:
      eventName = "deleted group";
      break;
    case EventType.RemoteEventResourcesCreated:
      eventName = "created resource";
      break;
    case EventType.RemoteEventResourcesDeleted:
      eventName = "deleted resource";
      break;
    case EventType.RequestSyncTicketCreated:
      eventName = "created audit ticket";
      break;
    case EventType.RequestSyncTicketUpdated:
      eventName = "updated audit ticket";
      break;
    case EventType.RequestSyncTicketClosed:
      eventName = "closed audit ticket";
      break;
    case EventType.RequestSyncTicketCommented:
      eventName = "commented on audit ticket";
      break;
    case EventType.RequestSyncTicketError:
      eventName = "error on audit ticket";
      break;
    case EventType.ConfigurationTemplateAdminOwnerUpdated:
      eventName = "admin owner updated";
      break;
    case EventType.ConfigurationTemplateAuditMessageChannelUpdated:
      eventName = "audit message channel updated";
      break;
    case EventType.ConfigurationTemplateBreakGlassUsersUpdated:
      eventName = "break-glass users updated";
      break;
    case EventType.ConfigurationTemplateNameUpdated:
      eventName = "name updated";
      break;
    case EventType.ConfigurationTemplateOncallSchedulesUpdated:
      eventName = "on-call schedules updated";
      break;
    case EventType.ConfigurationTemplateRequireMfaToApproveUpdated:
      eventName = "MFA to approve updated";
      break;
    case EventType.ConfigurationTemplateRequireMfaToConnectUpdated:
      eventName = "MFA to connect updated";
      break;
    case EventType.ConfigurationTemplateRequestConfigUpdated:
      eventName = "request config updated";
      break;
    case EventType.ConfigurationTemplateRequestConfigDeleted:
      eventName = "request config deleted";
      break;
    case EventType.ConfigurationTemplateVisibilityGroupsUpdated:
      eventName = "visibility groups updated";
      break;
    case EventType.ConfigurationTemplateVisibilityUpdated:
      eventName = "visibility updated";
      break;
    case EventType.ResourceConfigurationTemplateUpdated:
    case EventType.GroupConfigurationTemplateUpdated:
    case EventType.ConnectionAutoImportConfigTemplateUpdated:
      eventName = "configuration template updated";
      break;
    case EventType.UserMfaReset:
      eventName = "MFA reset by admin...";
      break;
    case EventType.GroupBindingSourceGroupUpdated:
      eventName = "source group updated";
      break;
    case EventType.GroupAddedToBinding:
      eventName = "added to link";
      break;
    case EventType.GroupRemovedFromBinding:
      eventName = "removed from link";
      break;
    case EventType.GroupBindingCreated:
      eventName = "link created";
      break;
    case EventType.GroupBindingUpdated:
      eventName = "link updated";
      break;
    case EventType.GroupBindingDeleted:
      eventName = "link deleted";
      break;
    case EventType.EventStreamConnectionsCreated:
      eventName = "event stream connection created";
      break;
    case EventType.EventStreamConnectionsUpdated:
      eventName = "event stream connection updated";
      break;
    case EventType.EventStreamConnectionsDeleted:
      eventName = "event stream connection deleted";
      break;
    case EventType.ImpersonationSessionStarted:
      eventName = "was impersonated by an Opal employee";
      break;
    case EventType.ImpersonationSessionStopped:
      eventName = "no longer impersonated by an Opal employee";
      break;
    case EventType.GroupCustomRequestNotificationUpdated:
      eventName = "Group custom request notification updated";
      break;
    case EventType.ResourceCustomRequestNotificationUpdated:
      eventName = "Resource custom request notification updated";
      break;
    case EventType.ConfigurationTemplateCustomRequestNotificationUpdated:
      eventName = "Configuration template custom request notification updated";
      break;
  }

  if (eventName === "") {
    eventName = eventTypeToString(eventType, false).toLowerCase();
  }

  return eventName;
}

export function eventTaskTriggerToString(taskTrigger: TaskTrigger) {
  let triggerString = taskTrigger.toString();
  switch (taskTrigger) {
    case TaskTrigger.AccessExpired:
      triggerString = "Access expired";
      break;
    case TaskTrigger.AccessReview:
      triggerString = "User access review";
      break;
    case TaskTrigger.AdminAction:
      triggerString = "Admin action";
      break;
    case TaskTrigger.ConnectionPull:
      triggerString = "Synced from end system";
      break;
    case TaskTrigger.IdpPull:
      triggerString = "Synced from IDP";
      break;
    case TaskTrigger.ScimPush:
      triggerString = "Synced from SCIM";
      break;
    case TaskTrigger.ThirdPartyProviderPull:
      triggerString = "Synced from third party provider";
      break;
    case TaskTrigger.CustomAccessLevelDeleted:
      triggerString = "Role deleted";
      break;
    case TaskTrigger.CustomResourceImport:
      triggerString = "Imported from CSV";
      break;
    case TaskTrigger.Forfeit:
      triggerString = "User forfeit";
      break;
    case TaskTrigger.GroupCreated:
      triggerString = "Group created";
      break;
    case TaskTrigger.GroupDeleted:
      triggerString = "Group deleted";
      break;
    case TaskTrigger.GroupResourceCreated:
      triggerString = "Resource added to group";
      break;
    case TaskTrigger.GroupResourceDeleted:
      triggerString = "Resource removed from group";
      break;
    case TaskTrigger.GroupUserCreated:
      triggerString = "User added to group";
      break;
    case TaskTrigger.GroupConverted:
      triggerString = "Group function converted";
      break;
    case TaskTrigger.GroupUserDeleted:
      triggerString = "User removed from group";
      break;
    case TaskTrigger.OnCallPull:
      triggerString = "On-call schedule updated";
      break;
    case TaskTrigger.Remediation:
      triggerString = "Sync error manually remediated";
      break;
    case TaskTrigger.RequestAutoApproved:
      triggerString = "Request auto approved";
      break;
    case TaskTrigger.RequestApproved:
      triggerString = "Request approved";
      break;
    case TaskTrigger.ResourceCreated:
      triggerString = "Resource created";
      break;
    case TaskTrigger.ResourceDeleted:
      triggerString = "Resource deleted";
      break;
    case TaskTrigger.UserDeleted:
      triggerString = "User deleted";
      break;
    case TaskTrigger.ConnectionDeleted:
      triggerString = "App deleted";
      break;
    case TaskTrigger.BreakGlassUsed:
      triggerString = "Break-glass access used";
      break;
  }
  return "Reason: " + triggerString;
}
