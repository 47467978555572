import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AddGroupResourceInput,
  GroupType,
  ResourceAccessLevel,
  ResourceFragment,
  ResourceType,
  useAddGroupResourcesMutation,
  useResourceAccessLevelsQuery,
} from "api/generated/graphql";
import GroupSearchDropdown, {
  mergeGroupSelectData,
  removeGroupSelectData,
} from "components/dropdown/GroupSearchDropdown";
import * as styles from "components/modals/AddItemModal.css";
import GroupWithResourceRoleRow from "components/modals/GroupWithResourceRoleRow";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { Modal, Skeleton } from "components/ui";
import { useState } from "react";
import useLogEvent from "utils/analytics";
import { resourceRequiresAtLeastOneRole } from "utils/directory/resources";
import { logError } from "utils/logging";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";

interface Props {
  resource: ResourceFragment;
  onClose: () => void;
  existingDirectRolesByGroupId: Record<string, ResourceAccessLevel[]>;
}

const ResourceAddGroupsModal = (props: Props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [rowErrors, setRowErrors] = useState<Record<string, string>>({});
  const [roleByGroupIdToAdd, setRoleByGroupIdToAdd] = useState<
    Record<string, ResourceAccessLevel[]>
  >({});

  const logEvent = useLogEvent();
  const startPushTaskPoll = usePushTaskLoader();

  const [addGroupResources, { loading }] = useAddGroupResourcesMutation();
  const {
    data,
    error,
    loading: loadingResourceRoles,
  } = useResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceId: props.resource.id,
      },
    },
  });

  if (loadingResourceRoles) {
    return (
      <Modal title="Add Groups" isOpen onClose={props.onClose}>
        <Modal.Body>
          <Skeleton height="48px" />
        </Modal.Body>
      </Modal>
    );
  }

  if (error) {
    return (
      <Modal title="Add Resources" isOpen onClose={props.onClose}>
        <Modal.Body>
          <ModalErrorMessage errorMessage={error.message} />
        </Modal.Body>
      </Modal>
    );
  }

  let allRoles = [];
  switch (data?.accessLevels.__typename) {
    case "ResourceAccessLevelsResult":
      allRoles = data.accessLevels.accessLevels ?? [];
  }

  const handleSubmit = async () => {
    logEvent({
      name: "apps_add_resource_to_groups",
      properties: {
        numGroupsAddedTo: Object.entries(roleByGroupIdToAdd).length,
      },
    });
    try {
      const groupResourcesToAdd: AddGroupResourceInput[] = [];
      const newRowErrors: Record<string, string> = {};
      for (const [groupId, roles] of Object.entries(roleByGroupIdToAdd)) {
        if (roles.length === 0) {
          // If resource requires a role, but none are selected,
          // show an error.
          if (resourceRequiresAtLeastOneRole(props.resource)) {
            newRowErrors[groupId] = "Please select at least one role";
          } else {
            // If resource does not require roles,
            // add an empty role to add the resource directly.
            groupResourcesToAdd.push({
              groupId,
              resourceId: props.resource.id,
              accessLevel: {
                accessLevelName: "",
                accessLevelRemoteId: "",
              },
            });
          }
        }

        roles.forEach((role) => {
          groupResourcesToAdd.push({
            groupId,
            resourceId: props.resource.id,
            accessLevel: {
              accessLevelName: role.accessLevelName,
              accessLevelRemoteId: role.accessLevelRemoteId,
            },
          });
        });
      }

      if (Object.keys(newRowErrors).length > 0) {
        setRowErrors(newRowErrors);
        return;
      }

      const { data } = await addGroupResources({
        variables: {
          input: {
            groupResources: groupResourcesToAdd,
          },
        },
        refetchQueries: ["ResourceDetailColumn"],
      });
      switch (data?.addGroupResources.__typename) {
        case "AddGroupResourcesResult":
          if (data.addGroupResources.taskId) {
            startPushTaskPoll(data.addGroupResources.taskId);
          }
          props.onClose();
          break;
        case "GroupNotFoundError":
        case "GroupResourceAlreadyExists":
          setErrorMessage(data.addGroupResources.message);
          break;
        case "UserFacingError":
          setErrorMessage(data.addGroupResources.message);
          break;
        default:
          logError(new Error(`failed to add resource to groups`));
          setErrorMessage("Error: failed to add resource to groups");
      }
    } catch (error) {
      logError(error, "failed to add resource to groups");
      setErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to add resource to groups",
          error
        )
      );
    }
  };

  const disabledGroupIds: string[] = [];
  props.resource.containingGroups.forEach((groupResource) => {
    // Disabled if all roles are already added
    const allRolesAdded =
      allRoles.length ===
      props.existingDirectRolesByGroupId[groupResource.groupId].length;
    if (allRolesAdded) {
      disabledGroupIds.push(groupResource.groupId);
    }
  });

  return (
    <Modal title="Add Resource to Groups" isOpen onClose={props.onClose}>
      <Modal.Body>
        {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
        <GroupSearchDropdown
          style="search"
          selectedGroupIds={Object.keys(roleByGroupIdToAdd)}
          disabledGroupIds={disabledGroupIds}
          disabledGroupTypes={
            props.resource.resourceType === ResourceType.OktaRole
              ? [GroupType.OktaGroup]
              : undefined
          }
          onSelect={({ groups, actionType }) => {
            setRoleByGroupIdToAdd((prev) => {
              if (actionType === "select-option") {
                return mergeGroupSelectData(prev, groups);
              } else {
                return removeGroupSelectData(prev, groups);
              }
            });
          }}
        />
        <div className={styles.itemsContainer}>
          {Object.keys(roleByGroupIdToAdd).map((groupId) => {
            return (
              <GroupWithResourceRoleRow
                key={groupId}
                groupId={groupId}
                resourceId={props.resource.id}
                existingRoles={
                  props.existingDirectRolesByGroupId[groupId] ?? []
                }
                roles={roleByGroupIdToAdd[groupId] ?? []}
                error={rowErrors[groupId]}
                onGroupRemove={() => {
                  setRoleByGroupIdToAdd((prev) => {
                    delete prev[groupId];
                    return { ...prev };
                  });
                }}
                onRoleSelect={(role) => {
                  setRoleByGroupIdToAdd((prev) => {
                    if (groupId in prev) {
                      return {
                        ...prev,
                        [groupId]: [...prev[groupId], role],
                      };
                    } else {
                      return {
                        ...prev,
                        [groupId]: [role],
                      };
                    }
                  });
                }}
                onRoleRemove={(role) => {
                  setRoleByGroupIdToAdd((prev) => {
                    return {
                      ...prev,
                      [groupId]: prev[groupId].filter(
                        (a) =>
                          a.accessLevelRemoteId !== role.accessLevelRemoteId
                      ),
                    };
                  });
                }}
              />
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Add Groups"
        primaryButtonDisabled={Object.keys(roleByGroupIdToAdd).length === 0}
        primaryButtonLoading={loading}
        onPrimaryButtonClick={handleSubmit}
      />
    </Modal>
  );
};

export default ResourceAddGroupsModal;
