import clsx from "clsx";
import styles from "components/entity_viewer/EntityViewerTabs.module.scss";
import Label from "components/label/Label";
import { Badge, Icon } from "components/ui";
import React, { ReactElement, useEffect } from "react";
import * as Icons from "react-feather";
import { useHistory, useLocation } from "react-router-dom";

export enum EntityViewerTabType {
  Overview = "overview",
  Resources = "resources",
  Groups = "groups",
  Connections = "apps",
  ContainingGroups = "containing-groups",
  Users = "users",
  AllEvents = "all-events",
  Events = "events",
  SavedFilters = "saved-filters",
  SubEvents = "sub-events",
  RequestReviewers = "request-reviewers",
  RequestComments = "request-comments",
  Usage = "usage",
  FlaggedUsers = "flagged-users",
  AllUsers = "all-users",
  Roles = "roles",
  Sessions = "sessions",
  Tags = "tags",
  AccessChanges = "access-changes",
  Metadata = "metadata",
  DirectReports = "direct-reports",
  RolePickerInlinePolicy = "inline-policy",
  RolePickerPolicyWizard = "policy-wizard",
  Violations = "violations",
  AccessReviewsOngoing = "ongoing",
  AccessReviewsCompleted = "ended",
  AccessReviewsTemplates = "schedules",
  Insights = "insights",
}

export type EntityViewerTabInfo = {
  tabType: EntityViewerTabType;
  fallbackFor?: EntityViewerTabType[];
  tabPreferredName?: string;
  tabContent?: ReactElement;
  tabCount?: number;
  badgeColor?: PropsFor<typeof Badge>["color"];
};

export type EntityViewerTabsProps = {
  tabInfos: EntityViewerTabInfo[];
  selectedTab: EntityViewerTabType;
  setSelectedTab: (tab: EntityViewerTabType) => void;
};

export const EntityViewerTabs = (props: EntityViewerTabsProps) => {
  const history = useHistory();
  const location = useLocation();

  const parsedTab = location.hash.substring(1);

  useEffect(() => {
    if (parsedTab === "") {
      let tabFragment = EntityViewerTabType.Overview;

      switch (props.selectedTab) {
        case EntityViewerTabType.FlaggedUsers:
          tabFragment = EntityViewerTabType.FlaggedUsers;
          break;
        case EntityViewerTabType.Resources:
          tabFragment = EntityViewerTabType.Resources;
          break;
        case EntityViewerTabType.Users:
          tabFragment = EntityViewerTabType.Users;
          break;
        case EntityViewerTabType.AllEvents:
          tabFragment = EntityViewerTabType.AllEvents;
          break;
        case EntityViewerTabType.SavedFilters:
          tabFragment = EntityViewerTabType.SavedFilters;
          break;
        case EntityViewerTabType.AccessReviewsOngoing:
          tabFragment = EntityViewerTabType.AccessReviewsOngoing;
          break;
        case EntityViewerTabType.AccessReviewsTemplates:
          tabFragment = EntityViewerTabType.AccessReviewsTemplates;
          break;
        case EntityViewerTabType.AccessReviewsCompleted:
          tabFragment = EntityViewerTabType.AccessReviewsCompleted;
          break;
      }

      // Make sure that the tab we're replacing here is not hidden, otherwise
      // it will simply render a blank page.
      const tabFragmentHasContent = props.tabInfos
        .filter((tabInfo) => Boolean(tabInfo.tabContent))
        .map((tabInfo) => tabInfo.tabType)
        .includes(tabFragment);

      history.replace({
        search: location.search,
        hash: tabFragmentHasContent
          ? tabFragment
          : EntityViewerTabType.Overview,
      });
    } else if (props.selectedTab !== parsedTab) {
      props.setSelectedTab(parsedTab as EntityViewerTabType);
    }
  }, [props, parsedTab, history, location]);

  if (props.selectedTab !== parsedTab) {
    return <></>;
  }

  return (
    <div className={styles.tabs}>
      <div className={styles.fillerTabSmall} />
      {props.tabInfos.map((tabInfo) => {
        return (
          tabInfo.tabContent && (
            <EntityViewerTab
              key={tabInfo.tabType}
              tabInfo={tabInfo}
              isSelected={props.selectedTab === tabInfo.tabType}
              onClick={() => {
                history.replace({
                  search: location.search,
                  hash: tabInfo.tabType,
                });
                props.setSelectedTab(tabInfo.tabType);
              }}
            />
          )
        );
      })}
      <div className={styles.fillerTab} />
    </div>
  );
};

export type EntityViewerTabProps = {
  tabInfo: EntityViewerTabInfo;
  isSelected: boolean;
  onClick: () => void;
};

export const EntityViewerTab = (props: EntityViewerTabProps) => {
  let iconWidth = 2;
  let tabName = "";
  let tabIcon = null;
  switch (props.tabInfo.tabType) {
    case EntityViewerTabType.Overview:
      tabName = "Overview";
      tabIcon = <Icon name="list" />;
      break;
    case EntityViewerTabType.Resources:
      tabName = "Resources";
      tabIcon = <Icon name="cube" />;
      break;
    case EntityViewerTabType.Groups:
      tabName = "Groups";
      tabIcon = <Icon name="users" />;
      break;
    case EntityViewerTabType.ContainingGroups:
      tabName = "Containing Groups";
      tabIcon = <Icon name="users" />;
      break;
    case EntityViewerTabType.Users:
      tabName = "Users";
      tabIcon = <Icon name="user" />;
      break;
    case EntityViewerTabType.Connections:
      tabName = "Apps";
      tabIcon = <Icon name="dots-grid" />;
      break;
    case EntityViewerTabType.Events:
      tabName = "Events";
      tabIcon = <Icon name="events" />;
      break;
    case EntityViewerTabType.AllEvents:
      tabName = "All Events";
      tabIcon = <Icon name="events" />;
      break;
    case EntityViewerTabType.SavedFilters:
      tabName = "Saved Filters";
      tabIcon = <Icon name="filter" />;
      break;
    case EntityViewerTabType.SubEvents:
      tabName = "Sub Events";
      tabIcon = <Icon name="events" />;
      break;
    case EntityViewerTabType.RequestReviewers:
      tabName = "Reviewers";
      tabIcon = <Icon name="users-check" />;
      break;
    case EntityViewerTabType.RequestComments:
      tabName = "Comments";
      tabIcon = <Icons.PenTool strokeWidth={iconWidth} />;
      break;
    case EntityViewerTabType.Usage:
      tabName = "Usage";
      tabIcon = <Icons.Monitor strokeWidth={iconWidth} />;
      break;
    case EntityViewerTabType.FlaggedUsers:
      tabName = "Flagged Users";
      tabIcon = <Icons.UserX strokeWidth={iconWidth} />;
      break;
    case EntityViewerTabType.AllUsers:
      tabName = "All Users";
      tabIcon = <Icon name="users" />;
      break;
    case EntityViewerTabType.Roles:
      tabName = "Roles";
      tabIcon = <Icon name="key" />;
      break;
    case EntityViewerTabType.Tags:
      tabName = "Tags";
      tabIcon = <Icon name="tag" />;
      break;
    case EntityViewerTabType.Sessions:
      tabName = "Sessions";
      tabIcon = <Icon name="terminal" />;
      break;
    case EntityViewerTabType.AccessChanges:
      tabName = "Access Changes";
      tabIcon = <Icons.Repeat strokeWidth={iconWidth} />;
      break;
    case EntityViewerTabType.Metadata:
      tabName = "Setup";
      tabIcon = <Icon name="settings" />;
      break;
    case EntityViewerTabType.DirectReports:
      tabName = "Direct Reports";
      tabIcon = <Icon name="users" />;
      break;
    case EntityViewerTabType.RolePickerInlinePolicy:
      tabName = "Inline Policy";
      tabIcon = <Icons.Code strokeWidth={iconWidth} />;
      break;
    case EntityViewerTabType.RolePickerPolicyWizard:
      tabName = "Policy Wizard";
      tabIcon = <Icon name="zap" />;
      break;
    case EntityViewerTabType.Violations:
      tabName = "Violations";
      tabIcon = <Icons.AlertTriangle strokeWidth={iconWidth} />;
      break;
    case EntityViewerTabType.AccessReviewsOngoing:
      tabName = "Ongoing";
      tabIcon = <Icons.Calendar strokeWidth={iconWidth} />;
      break;
    case EntityViewerTabType.AccessReviewsCompleted:
      tabName = "Ended";
      tabIcon = <Icons.CheckSquare strokeWidth={iconWidth} />;
      break;
    case EntityViewerTabType.AccessReviewsTemplates:
      tabName = "Schedules";
      tabIcon = <Icons.RefreshCw strokeWidth={iconWidth} />;
      break;
    case EntityViewerTabType.Insights:
      tabName = "Insights";
      tabIcon = <Icon name="department" />;
      break;
  }

  if (props.tabInfo.tabPreferredName) {
    tabName = props.tabInfo.tabPreferredName;
  }

  return (
    <div
      className={clsx({
        [styles.tab]: true,
        [styles.selectedTab]: props.isSelected,
      })}
      onClick={props.onClick}
    >
      <Label
        additionalStyleClass={styles.tabLabel}
        text={tabName}
        icon={tabIcon}
        rightIcon={
          props.tabInfo.tabCount !== undefined ? (
            <Badge
              count={props.tabInfo.tabCount}
              color={props.tabInfo.badgeColor}
            />
          ) : undefined
        }
        pointerCursor={true}
      />
    </div>
  );
};

export type EntityViewerTabContentProps = {
  tabInfos: EntityViewerTabInfo[];
  selectedTab: EntityViewerTabType;
  setSelectedTab: (tab: EntityViewerTabType) => void;
};

export const EntityViewerTabContent = (props: EntityViewerTabContentProps) => {
  let selectedTabInfo = props.tabInfos.find(
    (tabInfo) => tabInfo.tabType === props.selectedTab
  );
  if (!selectedTabInfo) {
    const fallbackTabInfo = props.tabInfos.find((tabInfo) =>
      tabInfo.fallbackFor?.includes(props.selectedTab)
    );
    if (fallbackTabInfo) {
      selectedTabInfo = fallbackTabInfo;
    }
  }

  return (
    <div className={styles.tabContent}>
      {selectedTabInfo && selectedTabInfo.tabContent}
    </div>
  );
};
