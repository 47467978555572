import NavigationContext, {
  NavigationContextActionType,
} from "components/layout/NavigationContextProvider";
import filterStyles from "components/only_mine_filter/OnlyMineFilter.module.scss";
import { Switch } from "components/ui";
import React, { useContext } from "react";

type GenericOnlyMineFilterProps = {
  label: string;
  checked?: boolean;
  onClickHandler: () => void;
};

const GenericOnlyMineFilter = (props: GenericOnlyMineFilterProps) => {
  return (
    <div className={filterStyles.onlyMineFilter} onClick={props.onClickHandler}>
      <Switch
        checked={props.checked as boolean}
        onChange={props.onClickHandler}
        label={props.label}
      />
    </div>
  );
};

/**
 * Filter for showing only access review items the current user is assigned to review.
 */
export const OnlyMyReviewsFilter = () => {
  const { navigationState, navigationDispatch } = useContext(NavigationContext);

  return (
    <GenericOnlyMineFilter
      label={`Only my reviews`}
      checked={navigationState.isOnlyMyReviewsFilterOn}
      onClickHandler={() => {
        navigationDispatch({
          type: NavigationContextActionType.IsOnlyMyReviewsFilterChange,
          payload: {
            isOnlyMyReviewsFilterOn: !navigationState.isOnlyMyReviewsFilterOn,
          },
        });
      }}
    />
  );
};
