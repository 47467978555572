import { IdpConnectionFragment, Maybe } from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { IdpIntegrationButton } from "views/settings/third_party_authorizer/IdpIntegrationButton";

type IdpAuthorizerProps = {
  idpConnection: Maybe<IdpConnectionFragment>;
  isConnected: boolean;
  connectionButtonDisabled?: boolean;
};

export const IdpAuthorizer = (props: IdpAuthorizerProps) => {
  const history = useHistory();
  const { authState } = useContext(AuthContext);

  return (
    <div>
      <IdpIntegrationButton
        idpConnection={props.idpConnection}
        isConnected={props.isConnected}
        buttonDisabled={
          props.connectionButtonDisabled || !authState.user?.isAdmin
        }
        onConnectClick={() => {
          history.push("/settings/idp/browse");
        }}
      />
    </div>
  );
};
