import "react-toastify/dist/ReactToastify.css";

import {
  Maybe,
  NotificationType,
  ThirdPartyIntegrationFragment,
  ThirdPartyProvider,
  useUpdateUserSettingsMutation,
  useUserSettingsQuery,
} from "api/generated/graphql";
import clsx from "clsx";
import { useToast } from "components/toast/Toast";
import { Button, Switch, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import React, { ReactElement, useState } from "react";
import * as Icons from "react-feather";
import { integrationExists } from "utils/common";
import { logError } from "utils/logging";
import { OrgSettingsSection } from "views/settings/OrgSettings";
import notificationStyles from "views/user_settings/NotificationPreferences.module.scss";
import { UserSettingsCategoryInfo } from "views/user_settings/UserSettings";

type NotificationInfo = {
  name: string;
  icon: ReactElement;
  isOn(n: NotificationType[]): boolean;
  updateStateFilter: (notifications: NotificationType[]) => NotificationType[];
  thirdPartyProvider: ThirdPartyProvider | null;
};

export type NotificationPreferencesProps = {
  categoryInfo: UserSettingsCategoryInfo;
  integrations: Maybe<ThirdPartyIntegrationFragment[]> | undefined;
};

const NotificationPreferences = (props: NotificationPreferencesProps) => {
  const { data, error, loading } = useUserSettingsQuery();

  let userSettings = null;
  if (data) {
    switch (data.userSettings.__typename) {
      case "UserSettingsResult":
        userSettings = data.userSettings.userSettings;
        break;
      default:
        logError(new Error(`failed to retrieve notification preferences`));
    }
  } else if (error) {
    logError(error, `failed to retrieve notification preferences`);
  }

  const allNotifications = userSettings?.allNotifications || [];

  const notificationInfos: NotificationInfo[] = [
    {
      name: "Slack",
      isOn: (notifications: NotificationType[]) =>
        notifications.includes(NotificationType.Slack),
      icon: <Icons.Slack />,
      updateStateFilter: toggleSlack,
      thirdPartyProvider: ThirdPartyProvider.Slack,
    },
    {
      name: "Email",
      isOn: (notifications: NotificationType[]) =>
        notifications.includes(NotificationType.Email),
      icon: <Icons.Mail />,
      updateStateFilter: toggleEmail,
      thirdPartyProvider: null,
    },
  ];

  return (
    <div id={`#${props.categoryInfo.anchor}`}>
      <OrgSettingsSection title="Notification Preferences">
        {!error && !loading && (
          <NotificationButtons
            notificationInfos={notificationInfos}
            currentNotifications={allNotifications}
            disabled={!!error || loading}
            integrations={props.integrations}
          />
        )}
      </OrgSettingsSection>
    </div>
  );
};

type NotificationsHeaderProps = {
  notificationInfos: NotificationInfo[];
};

const NotificationsHeader = (props: NotificationsHeaderProps) => {
  return (
    <div className={notificationStyles.notificationsHeader}>
      <div className={notificationStyles.columnNames}>
        {props.notificationInfos.map((info) => {
          return (
            <div
              key={`column-name-${info.name}`}
              className={notificationStyles.columnName}
            >
              <span className={notificationStyles.icon}>{info.icon}</span>
              <span className={notificationStyles.text}>{info.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

type NotificationButtonsProps = {
  notificationInfos: NotificationInfo[];
  currentNotifications: NotificationType[];
  disabled: boolean;
  integrations: Maybe<ThirdPartyIntegrationFragment[]> | undefined;
};

const NotificationButtons = (props: NotificationButtonsProps) => {
  const [updateUserSettings] = useUpdateUserSettingsMutation();
  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();

  const [pendingNotifications, setPendingNotifications] = useState(
    props.currentNotifications
  );
  const hasChanges = !_.isEqual(
    pendingNotifications,
    props.currentNotifications
  );

  // Users must have at least one notification type configured.
  const validNotificationSetting = pendingNotifications.length > 0;

  const notificationInfosFiltered = props.notificationInfos.filter((info) =>
    integrationExists(props.integrations, info.thirdPartyProvider)
  );

  let saveButton = (
    <Button
      type="primary"
      label="Save Changes"
      disabled={!validNotificationSetting}
      onClick={async () => {
        displayLoadingToast();

        try {
          const { data } = await updateUserSettings({
            variables: {
              input: {
                allNotifications: pendingNotifications,
              },
            },
          });
          switch (data?.updateUserSettings.__typename) {
            case "UpdateUserSettingsResult":
              displaySuccessToast("Success: notification preferences updated");
              break;
            default:
              logError(new Error(`failed to update notification preferences`));
              displayErrorToast(
                `Error: failed to update notification preferences`
              );
          }
        } catch (e) {
          logError(e, `failed to update notification preferences`);
          displayErrorToast(`Error: failed to update notification preferences`);
        }
      }}
    />
  );

  if (!validNotificationSetting) {
    saveButton = (
      <Tooltip tooltipText={"You must keep at least one notification type on"}>
        {saveButton}
      </Tooltip>
    );
  }

  return (
    <div className={notificationStyles.notificationsContainer}>
      <NotificationsHeader notificationInfos={notificationInfosFiltered} />
      <div className={notificationStyles.switchesHeader}>
        <div className={notificationStyles.label}>Notifications</div>
        <div
          className={sprinkles({ display: "flex", flexDirection: "column" })}
        >
          <div className={notificationStyles.switches}>
            {notificationInfosFiltered.map((info) => {
              return (
                <div
                  key={`switch-${info.name}`}
                  className={clsx({
                    [notificationStyles.switch]: true,
                    [notificationStyles.off]: !info.isOn(pendingNotifications),
                  })}
                >
                  <span className={notificationStyles.button}>
                    <Switch
                      checked={info.isOn(pendingNotifications)}
                      label={info.isOn(pendingNotifications) ? "On" : "Off"}
                      onChange={async () => {
                        setPendingNotifications(
                          info.updateStateFilter(pendingNotifications)
                        );
                      }}
                      disabled={props.disabled}
                    />
                  </span>
                </div>
              );
            })}
          </div>
          <div
            className={sprinkles({
              marginTop: "sm",
              marginRight: "xl",
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            {hasChanges ? saveButton : null}
          </div>
        </div>
      </div>
    </div>
  );
};

const toggleEmail = (notificationTypes: NotificationType[]) => {
  return toggle(notificationTypes, NotificationType.Email);
};

const toggleSlack = (notificationTypes: NotificationType[]) => {
  return toggle(notificationTypes, NotificationType.Slack);
};

const toggle = (
  notificationTypes: NotificationType[],
  notificationTypeToToggle: NotificationType
) => {
  let result = [...notificationTypes];
  if (notificationTypes.includes(notificationTypeToToggle)) {
    result = result.filter(
      (notificationType) => notificationType !== notificationTypeToToggle
    );
  } else {
    result.push(notificationTypeToToggle);
  }
  return result;
};

export default NotificationPreferences;
