import { Icon } from "components/ui";
import { debounce } from "lodash";
import { forwardRef, ReactElement } from "react";
import { useSearchParams } from "react-router-dom-v5-compat";
import { logWarning } from "utils/logging";

import * as styles from "./OpalSearchInput.css";

interface Props extends React.InputHTMLAttributes<HTMLInputElement> {
  type?: "search_page";
}
// thin layer over <input /> for search
const OpalSearchInput = forwardRef(
  (props: Props, ref: React.Ref<HTMLInputElement>) => {
    if (props.value) {
      logWarning(
        new Error(
          "OpalSearchInput probably should be used as an uncontrolled component. People type fast and causing a rerender every key press can lead to performance issues"
        )
      );
    }
    const { type, ...rest } = props;
    return (
      <div className={styles.container({ type })}>
        <div className={styles.icon}>
          <Icon name="search" size="xs" />
        </div>
        <input
          {...rest}
          type="search"
          ref={ref}
          className={styles.inputWithIcon}
        />
      </div>
    );
  }
);

export default OpalSearchInput;

interface HookProps
  extends Omit<
    PropsFor<typeof OpalSearchInput>,
    "defaultValue" | "onChange" | "value"
  > {
  customKey?: string; // custom searchParam key
}

const DEFAULT_KEY = "search";

// handles url syncing and debouncing
export function useOpalSearchInput(
  props?: HookProps
): [string | null, ReactElement<typeof OpalSearchInput>] {
  const [searchParams, setSearchParams] = useSearchParams();
  const key = props?.customKey ?? DEFAULT_KEY;
  const query = searchParams.get(key);
  return [
    query,
    <OpalSearchInput
      {...props}
      defaultValue={query ?? undefined}
      onChange={debounce(
        (e) => setSearchParams({ ...searchParams, [key]: e.target.value }),
        250
      )}
    />,
  ];
}
