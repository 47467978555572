import { gql, useFragment } from "@apollo/client";
import {
  OpalAppIconFragmentDoc,
  OpalAppNameCellFragment,
} from "api/generated/graphql";
import OpalAppIcon from "components/opal/common/icon/OpalAppIcon";
import OpalLink from "components/opal/common/OpalLink";
import { Skeleton } from "components/ui";

import * as styles from "./OpalAppNameCell.css";

const OpalAppNameCell = (props: { appID: string; path: string }) => {
  const { complete, data } = useFragment<OpalAppNameCellFragment>({
    fragment: gql`
      fragment OpalAppNameCell on App {
        id
        name
        ...OpalAppIcon
      }
      ${OpalAppIconFragmentDoc}
    `,
    fragmentName: "OpalAppNameCell",
    from: {
      __typename: "App",
      id: props.appID,
    },
  });

  if (!complete) {
    return <Skeleton variant="text" width="50px" />;
  }

  return (
    <div className={styles.container}>
      <OpalAppIcon appID={data.id} />
      <OpalLink to={props.path}>{complete ? data.name : "loading"}</OpalLink>
    </div>
  );
};

export default OpalAppNameCell;
