import { Maybe, useInitUnauthedAppQuery } from "api/generated/graphql";
import axios from "axios";
import AuthContext, {
  AuthContextActionType,
} from "components/auth/AuthContext";
import React, { useContext, useState } from "react";
import { logError } from "utils/logging";
import AppContext, { AppContextActionType } from "views/app/AppContext";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import FullPageLoading from "views/loading/FullPageLoading";

const UninitializedAuthViews = () => {
  // The following code will get called every time the page is initially loaded
  // (such as when navigating to the page for the first time, or doing Ctrl-R on your keyboard).

  const { authDispatch } = useContext(AuthContext);
  const { appDispatch } = useContext(AppContext);

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const setAndLogError = (errorMessage: string) => {
    setErrorMessage(errorMessage);
  };

  const csrfTokenHeader = "X-CSRF-Token";

  useInitUnauthedAppQuery({
    onCompleted: (data) => {
      let isOnPrem = data.appInfo.appInfo.isOnPrem;

      switch (data.appInfo.__typename) {
        case "AppInfoResult":
          appDispatch({
            type: AppContextActionType.OnPremChange,
            payload: {
              appInfo: data.appInfo.appInfo,
            },
          });
          break;
        default:
          logError(new Error(`failed to fetch app info`));
      }

      switch (data.authProviderInfo.__typename) {
        case "AuthProviderInfoResult": {
          const authProviderInfo = data.authProviderInfo.providerInfo;
          authDispatch({
            type: AuthContextActionType.Auth0Config,
            payload: {
              auth0Config: {
                domain: authProviderInfo.domain,
                audience: authProviderInfo.audience,
                clientId: authProviderInfo.clientId,
                redirectUri: authProviderInfo.redirectUri,
                logoutUri: authProviderInfo.logoutUri,
              },
            },
          });
          break;
        }
        case "ThirdPartyClientNotFoundError": {
          if (isOnPrem) {
            // For on-prem, we want to "initialize" auth context so that we
            // can start the onboarding flow (which is under "unauthenticated"
            // routes stack. See the comment where `OnPremOnboarding` is handled
            // for more info.
            authDispatch({
              type: AuthContextActionType.OnPremOnboarding,
              payload: {},
            });
            // Don't set an error message, we just want to show a blank screen here
          } else {
            logError(new Error(data.authProviderInfo.message));
            setAndLogError(data.authProviderInfo.message);
          }
          break;
        }
        default:
          logError(new Error(`failed to fetch auth provider info`));
          setAndLogError(`Error: failed to fetch auth provider info`);
      }

      switch (data.authSessionStatus.__typename) {
        case "AuthSessionStatusResult": {
          authDispatch({
            type: AuthContextActionType.GetAuthSessionStatus,
            payload: {
              authSessionStatus: data.authSessionStatus.status,
              user: data.authSessionStatus.user,
              impersonatingUser: data.authSessionStatus.userImpersonator,
            },
          });
          break;
        }
        default:
          logError(new Error(`failed to fetch auth session`));
          setAndLogError(`Error: failed to fetch auth session`);
      }

      axios({
        url: "/csrf-token",
        method: "GET",
        responseType: "json",
      })
        .then((response) => {
          let token: string;
          if (response.headers[csrfTokenHeader] !== undefined) {
            token = response.headers[csrfTokenHeader];
          } else if (
            response.headers[csrfTokenHeader.toLowerCase()] !== undefined
          ) {
            token = response.headers[csrfTokenHeader.toLowerCase()];
          } else {
            throw new Error(`token absent from response`);
          }
          window.csrfToken = token;
        })
        .catch((error) => {
          logError(error, `Unable to retrieve CSRF token`);
          setAndLogError(`Error: unable to retrieve CSRF token: ${error}`);
        });
    },
    onError: (error) => {
      logError(error, `failed to initialize unauthed app with error`);
      setAndLogError(
        `Error: failed to initialize unauthed app with error: ${error}`
      );
    },
  });

  if (errorMessage) {
    return <UnexpectedErrorPage />;
  }

  return <FullPageLoading />;
};

export default UninitializedAuthViews;
