import {
  IntegrationType,
  Maybe,
  useInitThirdPartyIntegrationAuthFlowMutation,
} from "api/generated/graphql";
import MessageModal from "components/modals/update/MessageModal";
import _ from "lodash";
import { useState } from "react";
import { logError, logWarning } from "utils/logging";
import { subtitleFormat } from "views/groups/creation/common";
import { IntegrationCreationInfo } from "views/settings/third_party_authorizer/ThirdPartyCodeFlowAuthorizer";

export type UserIntegrationRedirectConfirmModalProps = {
  title: string;
  message: string;
  isModalOpen: boolean;
  stepNumber: number;
  setStepNumber: (step: number) => void;
  totalSteps: number;
  value: string;
  onClose: () => void;
  integrationCreationInfo: IntegrationCreationInfo;
};

export const UserIntegrationRedirectConfirmModal = (
  props: UserIntegrationRedirectConfirmModalProps
) => {
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const [
    initUserAuthFlow,
    { loading },
  ] = useInitThirdPartyIntegrationAuthFlowMutation();

  const modalReset = () => {
    props.onClose();
  };

  return (
    <MessageModal
      title={props.title}
      subtitle={subtitleFormat(props.stepNumber, props.totalSteps)}
      message={props.message}
      isModalOpen={props.isModalOpen}
      onClose={() => {
        modalReset();
      }}
      onSubmit={async () => {
        try {
          const { data } = await initUserAuthFlow({
            variables: {
              input: {
                thirdPartyIntegrationType: IntegrationType.User,
                thirdPartyProvider:
                  props.integrationCreationInfo.thirdPartyProvider,
                orgName:
                  props.integrationCreationInfo.orgName !== ""
                    ? props.integrationCreationInfo.orgName
                    : undefined,
              },
            },
          });
          switch (data?.initThirdPartyIntegrationAuthFlow.__typename) {
            case "InitThirdPartyIntegrationAuthFlowResult":
              modalReset();
              window.location.href = data.initThirdPartyIntegrationAuthFlow.url;
              break;
            case "ThirdPartyIntegrationNotFoundError":
              logWarning(
                new Error(data.initThirdPartyIntegrationAuthFlow.message)
              );
              setErrorMessage(data.initThirdPartyIntegrationAuthFlow.message);
              break;
            default:
              logError(
                new Error(
                  `failed to initiate ${_.upperFirst(
                    _.camelCase(
                      props.integrationCreationInfo.thirdPartyProvider
                    )
                  )} auth flow`
                )
              );
              setErrorMessage(
                `Error: failed to initiate ${_.upperFirst(
                  _.camelCase(props.integrationCreationInfo.thirdPartyProvider)
                )} auth flow`
              );
          }
        } catch (error) {
          logError(
            error,
            `failed to initiate ${_.upperFirst(
              _.camelCase(props.integrationCreationInfo.thirdPartyProvider)
            )} auth flow`
          );
          setErrorMessage(
            `Error: failed to initiate ${_.upperFirst(
              _.camelCase(props.integrationCreationInfo.thirdPartyProvider)
            )} auth flow`
          );
        }
      }}
      value={props.value}
      loading={loading}
      errorMessage={errorMessage}
      isStepModal={true}
      onBack={
        props.stepNumber > 1
          ? () => {
              props.setStepNumber(props.stepNumber - 1);
            }
          : undefined
      }
      inputTypePassword={false}
    />
  );
};
