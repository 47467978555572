import { PropagationStatusCode } from "api/generated/graphql";
import Label from "components/label/Label";
import labelStyles from "components/label/Label.module.scss";
import propagationLabelStyles from "components/propagation/PropagationStatusCodeLabel.module.scss";
import { Tooltip } from "components/ui";
import React from "react";

type PropagationStatusCodeLabelProps = {
  statusCode?: PropagationStatusCode;
  entityTypeName: string;
};

export const PropagationStatusCodeLabel = (
  props: PropagationStatusCodeLabelProps
) => {
  const { entityTypeName } = props;

  let text = "--";
  let stylesClass = "";
  let toolTipPhrase = `The Opal sync engine has not attempted to sync this ${entityTypeName}.`;
  if (props.statusCode) {
    switch (props.statusCode) {
      case PropagationStatusCode.Success:
        text = `Success`;
        stylesClass = labelStyles.statusOkay;
        toolTipPhrase = `This ${entityTypeName} has been successfully synced with the end system.`;
        break;
      case PropagationStatusCode.ErrRemoteUserNotFound:
        text = `Remote user not found`;
        stylesClass = labelStyles.statusError;
        toolTipPhrase = `This ${entityTypeName} failed to sync because the Opal user could not be found on the end system.`;
        break;
      case PropagationStatusCode.ErrRemoteUserNotLinked:
        text = `Remote user not linked`;
        stylesClass = labelStyles.statusError;
        toolTipPhrase = `This ${entityTypeName} failed to sync because the Opal user has not yet linked their remote identity in Opal.`;
        break;
      case PropagationStatusCode.ErrRemoteResourceNotFound:
        text = `Resource not found`;
        stylesClass = labelStyles.statusError;
        toolTipPhrase = `This ${entityTypeName} failed to push because the corresponding resource could not be found on the end system.`;
        break;
      case PropagationStatusCode.ErrNotAuthorizedToQueryResource:
        text = `Unauthorized`;
        stylesClass = labelStyles.statusError;
        toolTipPhrase = `Opal is not authorized to push this ${entityTypeName}.`;
        break;
      case PropagationStatusCode.ErrRemoteThrottle:
        text = `Throttled`;
        stylesClass = labelStyles.statusError;
        toolTipPhrase = `This ${entityTypeName} failed to sync because the remote system rate-limited Opal.`;
        break;
      case PropagationStatusCode.ErrRemoteProvisioningViaIdpFailed:
        text = `Remote provisioning failed`;
        stylesClass = labelStyles.statusError;
        toolTipPhrase = `This user was not successfully provisioned via your IDP on the remote system. Please have your admin login to your IDP to view the error.`;
        break;
      case PropagationStatusCode.Pending:
      case PropagationStatusCode.PendingTicketCreation:
      case PropagationStatusCode.PendingManualPropagation:
        text = `Pending`;
        stylesClass = labelStyles.statusNeutral;
        toolTipPhrase = `Access to the end system is still propagating.`;
        break;
      case PropagationStatusCode.ErrIdpEmailUpdateConflict:
        text = `Email mismatch`;
        stylesClass = labelStyles.statusError;
        toolTipPhrase = `Email mismatch when trying to update the users email.`;
        break;
      case PropagationStatusCode.ErrTimeout:
        text = `Timeout`;
        stylesClass = labelStyles.statusError;
        toolTipPhrase = `This ${entityTypeName} failed to sync due to the task timing out.`;
        break;
      case PropagationStatusCode.ErrOpalInternalError:
        text = `Opal internal error`;
        stylesClass = labelStyles.statusError;
        toolTipPhrase = `This ${entityTypeName} failed to sync due to an unexpected error in Opal.`;
        break;
      case PropagationStatusCode.ErrRemoteInternalError:
        text = `Remote internal error`;
        stylesClass = labelStyles.statusError;
        toolTipPhrase = `This ${entityTypeName} failed to sync due to an unexpected error on the remote system.`;
        break;
      case PropagationStatusCode.ErrOrgReadOnly:
        text = `Push disabled in read-only mode`;
        stylesClass = labelStyles.statusError;
        toolTipPhrase = `Opal cannot push changes to your end systems while in read-only mode.`;
        break;
      case PropagationStatusCode.ErrOperationUnsupported:
        text = `Operation Unsupported`;
        stylesClass = labelStyles.statusError;
        toolTipPhrase = `This operation is not currently allowed by Opal.`;
        break;
      case PropagationStatusCode.ErrDryRunModeEnabled:
        text = `Push disabled in dry-run mode`;
        stylesClass = labelStyles.statusError;
        toolTipPhrase = `Opal cannot push changes to your end systems while in dry-run mode.`;
        break;
      case PropagationStatusCode.ErrUnknown:
      default:
        text = `Unknown error`;
        stylesClass = labelStyles.statusError;
        toolTipPhrase = `An unknown error occurred during syncing.`;
    }
  }

  let content = (
    <span className={propagationLabelStyles.propagationStatusLabel}>
      <Label text={text} additionalStyleClass={stylesClass} />
    </span>
  );

  content = <Tooltip tooltipText={toolTipPhrase}>{content}</Tooltip>;

  return <div>{content}</div>;
};

export default PropagationStatusCodeLabel;
