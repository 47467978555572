import { getModifiedErrorMessage } from "api/ApiContext";
import {
  ConnectionPreviewLargeFragment,
  Maybe,
  useConnectionsQuery,
  useCreateIdpConnectionMutation,
} from "api/generated/graphql";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Modal, Select } from "components/ui";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { logError, logWarning } from "utils/logging";
import { IdpConnectionInfo } from "views/idp/create/BrowseIdpServices";
import OrgContext, { OrgContextActionType } from "views/settings/OrgContext";

type SelectConnectionModalProps = {
  idpConnectionInfo: IdpConnectionInfo;
  isModalOpen: boolean;
  onClose: () => void;
};

const SelectConnectionModal = (props: SelectConnectionModalProps) => {
  const [
    connection,
    setConnection,
  ] = useState<ConnectionPreviewLargeFragment>();
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();
  const { orgDispatch } = useContext(OrgContext);
  const history = useHistory();

  const [
    createIdpConnectionMutation,
    { loading },
  ] = useCreateIdpConnectionMutation();

  const { data, error } = useConnectionsQuery({
    variables: {
      input: {
        connectionTypes: [props.idpConnectionInfo.connectionType],
      },
    },
  });
  let connections: ConnectionPreviewLargeFragment[] = [];
  if (data?.connections) {
    switch (data.connections.__typename) {
      case "ConnectionsResult":
        connections = data?.connections.connections;
        break;
      default:
        logError(new Error(`Error: failed to fetch connections`));
    }
  } else if (error) {
    logError(error, `Error: failed to fetch connections`);
  }

  let onClickHandler = async () => {
    try {
      const { data } = await createIdpConnectionMutation({
        variables: {
          input: {
            idpConnectionType: props.idpConnectionInfo.idpConnectionType,
            existingConnectionId: connection!.id,
          },
        },
        refetchQueries: ["IdpConnection"],
      });
      switch (data?.createIdpConnection.__typename) {
        case "CreateIdpConnectionResult":
          displaySuccessToast(
            `Success: ${props.idpConnectionInfo.name} IDP connection created`
          );

          history.push("/settings#idp-and-hr");
          orgDispatch({
            type: OrgContextActionType.OrgIdpConnection,
            payload: {
              orgIdpConnections: [data.createIdpConnection.idpConnection],
            },
          });
          break;
        case "IdpConnectionExistsError":
        case "ConnectionBadMetadataError":
        case "UserFacingError":
          logWarning(new Error(data.createIdpConnection.message));
          setErrorMessage(data.createIdpConnection.message);
          break;
        default:
          logError(
            new Error(
              `failed to create ${props.idpConnectionInfo.name} IDP connection`
            )
          );
          setErrorMessage(
            `Error: failed to create ${props.idpConnectionInfo.name} IDP connection`
          );
      }
    } catch (error) {
      logError(
        error,
        `failed to create ${props.idpConnectionInfo.name} IDP connection`
      );
      setErrorMessage(
        getModifiedErrorMessage(
          `Error: failed to create ${props.idpConnectionInfo.name} IDP connection`,
          error
        )
      );
    }
  };

  return (
    <Modal
      title={`Select ${props.idpConnectionInfo.name} account to use as your IDP`}
      isOpen={props.isModalOpen}
      onClose={props.onClose}
    >
      <Modal.Body>
        {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
        <Select
          options={connections}
          value={connection}
          getOptionLabel={(option) => option.name}
          getOptionSelected={(option, value) => option.id === value.id}
          getIcon={(option) => ({
            type: "src",
            icon: getConnectionTypeInfo(option.connectionType)?.icon,
          })}
          onChange={(newConnection) => {
            setConnection(newConnection);
          }}
          loading={loading}
          placeholder="Select an app..."
        />
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Connect"
        onPrimaryButtonClick={onClickHandler}
        primaryButtonDisabled={!connection}
      />
    </Modal>
  );
};

export default SelectConnectionModal;
