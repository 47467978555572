import AuthContext from "components/auth/AuthContext";
import React from "react";
import { useContext } from "react";
import { Redirect } from "react-router";
import { FeatureFlag, useRedirectFeatureFlag } from "utils/feature_flags";
import FullPageLoading from "views/loading/FullPageLoading";

const Homepage = () => {
  const { authState } = useContext(AuthContext);

  const hasV3Nav = useRedirectFeatureFlag(FeatureFlag.V3Nav);
  const hasV3Search = useRedirectFeatureFlag(FeatureFlag.V3Search);
  const hasRecommendations = useRedirectFeatureFlag(
    FeatureFlag.LPPMShowHomepage
  );

  if (
    hasV3Nav == undefined ||
    hasRecommendations == undefined ||
    hasV3Search == undefined
  ) {
    return <FullPageLoading />;
  }

  let redirectRoute: string =
    hasV3Nav && hasRecommendations && authState.user?.isAdmin
      ? "/home"
      : hasV3Search
      ? "/search"
      : "/apps";

  return <Redirect to={redirectRoute} />;
};

export default Homepage;
