import {
  AccessReviewGroupUserFragment,
  EntityType,
  ReviewerUserFragment,
} from "api/generated/graphql";
import { ResourceLabel } from "components/label/Label";
import TableSimple, { Header } from "components/ui/table/TableSimple";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import { useParams } from "react-router";

import AccessReviewContext from "../AccessReviewContext";
import { DecisionLabel } from "../AccessReviewReviewerDetails";
import * as styles from "./ResourceUserSubRow.css";
import UserMoreInfo from "./UserMoreInfo";

interface Props {
  groupUser: AccessReviewGroupUserFragment;
}

interface RowData {
  id: string;
  reviewer: string;
  decision: string;
  role: string;
  note: string;
  data: ReviewerUserFragment;
}

const GroupUserSubRow = ({ groupUser }: Props) => {
  const { accessReviewGroupId } = useParams<Record<string, string>>();
  const { accessReviewState } = useContext(AccessReviewContext);

  const performReviewState =
    accessReviewState.performReviewStateByUARGroupId[accessReviewGroupId];
  const reviewers = groupUser.reviewerUsers ?? [];

  const hasRoleUpdate = reviewers.some((reviewer) =>
    Boolean(reviewer.updatedAccessLevel?.accessLevelName)
  );

  const columns: Header<RowData>[] = [
    {
      id: "reviewer",
      label: "Reviewer",
      cellRenderer: ({ data }) => {
        const reviewer = data;
        return (
          <ResourceLabel
            text={reviewer.name}
            entityTypeNew={EntityType.User}
            avatar={reviewer.avatarUrl}
            entityId={reviewer.userId}
          />
        );
      },
    },
    {
      id: "decision",
      label: "Decision",
      cellRenderer: ({ data }) => {
        return <DecisionLabel status={data.status} />;
      },
    },
  ];
  if (hasRoleUpdate) {
    columns.push({
      id: "role",
      label: "Updated Role",
    });
  }
  columns.push({
    id: "note",
    label: "Review Note",
    cellRenderer: ({ data }) => {
      const updatedNote = performReviewState?.groupUserActions.find(
        (groupUserAction) =>
          groupUserAction.accessReviewGroupUserId === groupUser.id
      )?.note;
      return <span>{updatedNote ?? data.note ?? "--"}</span>;
    },
  });

  const rows: RowData[] = reviewers.map((reviewer) => {
    return {
      id: reviewer.userId,
      reviewer: reviewer.userId,
      decision: reviewer.status,
      role: reviewer.updatedAccessLevel?.accessLevelName || "--",
      note: reviewer.note || "--",
      data: reviewer,
    };
  });

  return (
    <div className={styles.container}>
      {groupUser.user && <UserMoreInfo user={groupUser.user} />}
      {rows.length > 0 ? (
        <TableSimple columns={columns} rows={rows} getRowId={(row) => row.id} />
      ) : (
        <div className={sprinkles({ color: "gray600", fontSize: "bodySm" })}>
          No reviewers assigned
        </div>
      )}
    </div>
  );
};

export default GroupUserSubRow;
