import {
  EntityType,
  GroupResourceAccessFragment,
  Maybe,
  ResourceAccessLevel,
} from "api/generated/graphql";
import AccessPathLabel from "components/label/AccessPathLabel";
import AccessPointsLabel, {
  GroupForAccessLabel,
  ResourceForAccessLabel,
} from "components/label/AccessPointsLabel";
import EventLabel from "components/label/item_labels/EventLabel";
import { ResourceLabel } from "components/label/Label";
import MaterialTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MaterialTable";
import sprinkles from "css/sprinkles.css";
import React from "react";
import { dropNothings } from "views/utils";

interface GroupResourceAccessPointRow {
  event: string;
  accessPath: string;
}

type GroupResourceAccessPointsLabelProps = {
  accessPathsInfo: GroupResourceAccessPathsInfo;
  group: Maybe<GroupForAccessLabel> | undefined;
  access: GroupResourceAccessFragment;
  resource: Maybe<ResourceForAccessLabel> | undefined;
  role?: ResourceAccessLevel;
};

const accessPathColMinWidth = "400px";
const accessPathColMaxWidth = "1200px";

export const GroupResourceAccessPointsLabel = (
  props: GroupResourceAccessPointsLabelProps
) => {
  const headers: Header<GroupResourceAccessPointRow>[] = [
    { id: "event", label: "Event" },
    { id: "accessPath", label: "Access Path" },
  ];

  const numAccessPoints = props.accessPathsInfo.accessPointRows.length;
  if (numAccessPoints === 0) {
    return <div>{"--"}</div>;
  }

  return (
    <AccessPointsLabel
      subject={props.group}
      object={props.resource}
      roleName={props.role?.accessLevelName}
      hasDirectAccess={props.accessPathsInfo.hasDirectAccess}
      numAccessPoints={numAccessPoints}
      numInheritedAccessPoints={
        props.accessPathsInfo?.numInheritedAccessPoints ?? 0
      }
      numGroupAccessPoints={0}
      accessPathContent={
        <div className={sprinkles({ padding: "sm" })}>
          <MaterialTable<GroupResourceAccessPointRow>
            headers={headers}
            rows={props.accessPathsInfo.accessPointRows}
            denseFormatting={true}
            tableContainerStyles={{ maxHeight: 300 }}
          />
        </div>
      }
    />
  );
};

export type GroupResourceAccessPathsInfo = {
  accessPointRows: CellRow<GroupResourceAccessPointRow>[];
  numInheritedAccessPoints?: number;
  hasDirectAccess: boolean;
};

type GroupResourceAccessPathsInfoParams = {
  group: Maybe<GroupForAccessLabel> | undefined;
  access: Maybe<GroupResourceAccessFragment> | undefined;
  resource: Maybe<ResourceForAccessLabel> | undefined;
};

export const getGroupResourceAccessPathsInfo = (
  params: GroupResourceAccessPathsInfoParams
) => {
  const accessPointRows: CellRow<GroupResourceAccessPointRow>[] = [];
  const directGroupAccess = params.access?.directAccessPoint;

  if (directGroupAccess) {
    accessPointRows.push({
      id: directGroupAccess.resourceId,
      data: {
        event: {
          value: directGroupAccess.eventId,
          element: <EventLabel eventId={directGroupAccess.eventId} />,
        },
        accessPath: {
          value: `${directGroupAccess.resourceId}-access-path`,
          element: (
            <AccessPathLabel
              accessLabels={[
                <ResourceLabel
                  entityTypeNew={EntityType.Group}
                  entityId={params.group?.id}
                  text={params.group?.name}
                  groupType={params.group?.groupType}
                  maxChars={24}
                />,
                <ResourceLabel
                  entityTypeNew={EntityType.Resource}
                  entityId={params.resource?.id}
                  text={params.resource?.name}
                  resourceType={params.resource?.resourceType}
                  maxChars={24}
                />,
              ]}
              maxWidth={accessPathColMaxWidth}
            />
          ),
          style: { minWidth: accessPathColMinWidth },
        },
      },
    });
  }

  params.access?.indirectAccessPointPaths.forEach((path) => {
    const accessPathLabels = dropNothings([
      ...(path?.indirectAccessPointPath ?? []).map((indirectAccessPoint) => {
        return (
          <ResourceLabel
            key={indirectAccessPoint.groupId}
            entityTypeNew={EntityType.Group}
            entityId={indirectAccessPoint.groupId}
            text={indirectAccessPoint.groupName}
            groupType={indirectAccessPoint.groupType}
            maxChars={32}
          />
        );
      }),
      path.resolvedAccessPoint?.resourceId !== params.resource?.id ? (
        <ResourceLabel
          key={path.resolvedAccessPoint?.resourceId}
          entityTypeNew={EntityType.Resource}
          entityId={path.resolvedAccessPoint?.resourceId}
          resourceType={path.resolvedAccessPoint?.resourceType}
          text={path.resolvedAccessPoint?.resourceName}
          maxChars={32}
        />
      ) : null,
      // finally the resource
      <ResourceLabel
        key={params.resource?.id}
        entityTypeNew={EntityType.Resource}
        entityId={params.resource?.id}
        text={params.resource?.name}
        resourceType={params.resource?.resourceType}
        maxChars={32}
      />,
    ]);
    const id = accessPathLabels.reduce((acc, label) => {
      return acc + label.props.entityId;
    }, "");
    const eventId: string = path.resolvedAccessPoint?.eventId ?? ""; // TODO this should never be null..

    accessPointRows.push({
      id: id,
      data: {
        event: {
          value: eventId,
          element: <EventLabel eventId={eventId} />,
        },
        accessPath: {
          value: `${id}-access-path`,
          element: (
            <AccessPathLabel
              accessLabels={accessPathLabels}
              maxWidth={accessPathColMaxWidth}
            />
          ),
          style: { minWidth: accessPathColMinWidth },
        },
      },
    });
  });

  return {
    accessPointRows,
    hasDirectAccess: !!directGroupAccess,
    numInheritedAccessPoints: params.access?.inheritedAccessPoints.length || 0,
  };
};
