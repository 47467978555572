import {
  AccessOption,
  ConnectionPreviewLargeFragment,
  ConnectionType,
  Maybe,
  SyncErrorFragment,
  SyncTaskFragment,
  SyncType,
  useConnectionPreviewQuery,
  useSyncStatusQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeader";
import { FormMode } from "components/forms/common";
import SyncStatusModal from "components/label/SyncStatusModal";
import useSyncMenuItem, { getSyncLabel } from "components/sync/useSyncMenuItem";
import { Divider, Skeleton, Tabs } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import ConnectionDeleteModal from "views/connections/ConnectionDeleteModal";
import { ConnectionRenameModal } from "views/connections/ConnectionRenameModal";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import { APP_VIEWS } from "./AppDetailContent";
import { ACCESS_OPTION_URL_KEY, AppsContext } from "./AppsContext";
import { useAccessOptionKey } from "./utils";

interface Props {
  mode?: FormMode;
  editButtons?: React.ReactNode;
}

const AppHeader = (props: Props) => {
  const { appId } = useParams<Record<string, string>>();
  const history = useHistory();
  const transitionTo = useTransitionTo({
    preserveQueries: [ACCESS_OPTION_URL_KEY],
  });
  const logEvent = useLogEvent();

  const { data, error, loading } = useConnectionPreviewQuery({
    variables: {
      connectionId: appId,
    },
  });

  const { authState } = useContext(AuthContext);
  const { selectRemoteItems } = useContext(AppsContext);
  const [accessOptionKey] = useAccessOptionKey();
  const [showDeleteConnectionModal, setShowDeleteConnectionModal] = useState(
    false
  );
  const [showRenameConnectionModal, setShowRenameConnectionModal] = useState(
    false
  );
  const [showSyncModal, setShowSyncModal] = useState(false);

  let connection: Maybe<ConnectionPreviewLargeFragment> = null;
  let connectionNotFound = false;
  if (data) {
    switch (data.connection.__typename) {
      case "ConnectionResult":
        connection = data.connection.connection;
        break;
      case "ConnectionNotFoundError":
        connectionNotFound = true;
        break;
      default:
        logError(new Error(`failed to get app`));
    }
  } else if (error) {
    logError(error, `failed to get app`);
  }

  const syncMenuItem = useSyncMenuItem({
    syncType: SyncType.PullConnectionsSingleConnection,
    connection: connection ?? undefined,
    loadingEntity: loading,
    queriesToRefetch: ["ConnectionPreview", "ItemsListSection"],
  });

  const {
    data: syncData,
    error: syncError,
    loading: syncLoading,
  } = useSyncStatusQuery({
    variables: {
      input: {
        syncType: SyncType.PullConnectionsSingleConnection,
        connectionId: appId,
      },
    },
    skip: connectionNotFound,
  });

  let lastSuccessfulSyncTask: SyncTaskFragment | null = null;
  let syncErrors: SyncErrorFragment[] = [];
  if (syncData) {
    switch (syncData.syncStatus.__typename) {
      case "SyncStatusResult":
        lastSuccessfulSyncTask = syncData.syncStatus.lastSuccessfulSyncTask
          ? syncData.syncStatus.lastSuccessfulSyncTask
          : null;
        syncErrors = syncData.syncStatus.syncErrors;
        break;
      case "InvalidSyncTypeError":
      case "ConnectionNotFoundError":
        // we should not get this error if the connection doesn't exist as we're
        // skipping this query if connectionNotFound is true
        logError(syncData.syncStatus.message);
        break;
    }
  }

  if (loading) {
    return (
      <Column isContent>
        <ColumnHeaderSkeleton />
        <Divider margin="md" />
        <div
          className={sprinkles({ display: "flex", justifyContent: "center" })}
        >
          <Skeleton variant="rect" width="400px" height="50px" />
        </div>
      </Column>
    );
  }

  if (error) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  const currentAppView = APP_VIEWS.find((appView) =>
    history.location.pathname.endsWith(appView.key)
  );

  if (!connection || !currentAppView) {
    return null;
  }

  const getTabInfos = (
    connectionType: ConnectionType
  ): PropsFor<typeof Tabs>["tabInfos"] => {
    const isAdmin =
      authState.user?.isAdmin || authState.user?.isReadOnlyAdmin || false;
    const appViews = APP_VIEWS.filter(
      (appView) =>
        appView.hidden === undefined || !appView.hidden(isAdmin, connectionType)
    );

    const selectedAppView = appViews.find((appView) =>
      location.pathname.endsWith(appView.key)
    );

    return appViews.map((appView) => ({
      title: appView.title,
      isSelected: selectedAppView?.key === appView.key,
      onClick: () => {
        transitionTo({
          pathname: `/apps/${appId}/${appView.key}`,
        });
      },
      icon: appView.icon,
    }));
  };

  let sublabel: string;
  if (syncError) {
    sublabel = "Unable to get status";
  } else if (syncLoading) {
    sublabel = "Loading sync status";
  } else {
    sublabel = getSyncLabel(lastSuccessfulSyncTask, syncErrors);
  }

  const hasSyncErrors = authState.user?.isAdmin && syncErrors.length > 0;
  const menuOptions: PropsFor<typeof ColumnHeader>["menuOptions"] = [];
  if (authState.user?.isAdmin) {
    if (syncMenuItem) {
      menuOptions.push({
        label: hasSyncErrors ? "View sync errors" : "View sync details",
        sublabel: sublabel,
        onClick: () => {
          logEvent({
            name: "apps_view_sync_details",
            properties: {
              syncType: SyncType.PullConnectionsSingleResource,
            },
          });
          setShowSyncModal(true);
        },
        icon: hasSyncErrors
          ? { type: "name", icon: "alert-circle" }
          : { type: "name", icon: "info" },
        type: hasSyncErrors ? "warning" : undefined,
      });
      menuOptions.push(syncMenuItem);
    }
    menuOptions.push({
      type: "divider",
    });
    menuOptions.push({
      label: "Rename",
      onClick: () => {
        setShowRenameConnectionModal(true);
      },
      icon: { type: "name", icon: "edit-3" },
    });
    menuOptions.push({
      label: "Remove from Opal",
      onClick: () => {
        setShowDeleteConnectionModal(true);
      },
      type: "danger",
      icon: { type: "name", icon: "trash" },
    });
  }

  const tabInfos = getTabInfos(connection.connectionType);

  return (
    <>
      <ColumnHeader
        title={connection.name}
        subtitle={currentAppView.title}
        icon={{ type: "name", icon: currentAppView.icon }}
        onClose={
          selectRemoteItems.length > 0 &&
          accessOptionKey === AccessOption.Unmanaged
            ? () =>
                history.push(
                  `/apps/${appId}?${ACCESS_OPTION_URL_KEY}=${AccessOption.Unmanaged}`
                )
            : undefined
        }
        rightActions={
          authState.user?.isAdmin ? (
            <div
              className={sprinkles({
                display: "flex",
                alignItems: "center",
                gap: "md",
              })}
            >
              {props.editButtons}
            </div>
          ) : undefined
        }
        menuOptions={menuOptions}
        menuError={hasSyncErrors}
      />
      <Divider margin="md" />
      {props.mode !== "edit" && tabInfos.length > 1 ? (
        <div
          className={sprinkles({ display: "flex", justifyContent: "center" })}
        >
          <Tabs tabInfos={tabInfos} round />
        </div>
      ) : null}
      {showSyncModal ? (
        <SyncStatusModal
          syncType={SyncType.PullConnectionsSingleConnection}
          entity={connection}
          lastSuccessfulSyncTask={lastSuccessfulSyncTask}
          syncErrors={syncErrors}
          isModalOpen={showSyncModal}
          onClose={() => {
            setShowSyncModal(false);
          }}
        />
      ) : null}
      {showRenameConnectionModal && (
        <ConnectionRenameModal
          connection={connection}
          showModal={showRenameConnectionModal}
          setShowModal={setShowRenameConnectionModal}
        />
      )}
      {showDeleteConnectionModal && (
        <ConnectionDeleteModal
          connection={connection}
          showModal={showDeleteConnectionModal}
          setShowModal={setShowDeleteConnectionModal}
        />
      )}
    </>
  );
};

export default AppHeader;
