import { HrIdpStatus } from "api/generated/graphql";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import { IconData } from "components/ui/utils";
import _ from "lodash";
import { useLocation } from "react-router";

export const getUserAvatarIcon = (user: { avatarUrl: string }): IconData => {
  return user.avatarUrl
    ? {
        type: "src",
        icon: user.avatarUrl,
        style: "rounded",
        fallbackIcon: defaultAvatarURL,
      }
    : {
        type: "name",
        icon: "default-avatar",
        style: "rounded",
      };
};

export const formatHrIdpStatus = (hrIdpStatus: HrIdpStatus) => {
  switch (hrIdpStatus) {
    case HrIdpStatus.Active:
      return "Active";
    case HrIdpStatus.Suspended:
      return "Suspended";
    case HrIdpStatus.Deprovisioned:
      return "Deprovisioned";
    case HrIdpStatus.Deleted:
      return "Deleted";
    case HrIdpStatus.NotFound:
      return "Not Found";
  }
};

type FilterInfo = {
  value: string;
  applied: boolean;
};
type filterAttr = "manager" | "team" | "hrIdpStatus" | "title" | "search";

const useUsersFilterInfo = () => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const makeFilterInfo = (urlParam: string): FilterInfo => {
    return {
      value: urlParams.get(urlParam) || "",
      applied: !!urlParams.get(urlParam),
    };
  };

  return {
    manager: makeFilterInfo("manager"),
    team: makeFilterInfo("team"),
    hrIdpStatus: makeFilterInfo("hrIdpStatus"),
    title: makeFilterInfo("title"),
    search: makeFilterInfo("search"),
  };
};

export const useUsersFilter = (): { [key in filterAttr]?: string } => {
  const filterInfos = useUsersFilterInfo();

  const userFilter: { [key in filterAttr]?: string } = {};
  _.forEach(filterInfos, (filterInfo, filterType) => {
    if (filterInfo.applied) {
      switch (filterType) {
        case "manager":
          userFilter.manager = filterInfo.value;
          break;
        case "team":
          userFilter.team = filterInfo.value;
          break;
        case "hrIdpStatus":
          userFilter.hrIdpStatus = filterInfo.value;
          break;
        case "title":
          userFilter.title = filterInfo.value;
          break;
        case "search":
          userFilter.search = filterInfo.value;
          break;
      }
    }
  });
  return userFilter;
};

export const buildFilterURLParams = (
  userFilter: { [key in filterAttr]?: string }
) => {
  const params = new URLSearchParams();
  _.forEach(userFilter, (v, k) => {
    if (v !== undefined) params.append(k, v.toString());
  });
  return params;
};
