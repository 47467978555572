import { ErrorEntity, Maybe } from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { Modal } from "components/ui";
import React from "react";

type ConfirmModalProps = {
  title: string;
  subtitle?: string;
  message: string | JSX.Element;
  isModalOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  submitLabel?: string;
  submitDisabled?: boolean;
  loading?: boolean;
  errorMessage?: Maybe<string>;
  errorEntities?: ErrorEntity[];
};

export const ConfirmModal = (props: ConfirmModalProps) => {
  return (
    <Modal
      isOpen={props.isModalOpen}
      onClose={props.onClose}
      title={props.title}
      subtitle={props.subtitle}
    >
      <Modal.Body>
        <div>{props.message}</div>
        {props.errorMessage && (
          <ModalErrorMessage
            errorMessage={props.errorMessage}
            errorEntities={props.errorEntities}
          />
        )}
      </Modal.Body>
      <Modal.Footer
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={props.onClose}
        primaryButtonLabel={props.submitLabel ? props.submitLabel : "Delete"}
        onPrimaryButtonClick={props.onSubmit}
        primaryButtonLoading={props.loading}
        primaryButtonDisabled={props.submitDisabled}
        primaryButtonType="error"
      />
    </Modal>
  );
};

export default ConfirmModal;
