import { getModifiedErrorMessage } from "api/ApiContext";
import {
  IdpConnectionFragment,
  Maybe,
  useDeleteIdpConnectionMutation,
} from "api/generated/graphql";
import { getNameByIdpConnectionType } from "components/label/Label";
import ConfirmModal from "components/modals/update/ConfirmModal";
import { useToast } from "components/toast/Toast";
import { Button, ButtonV3 } from "components/ui";
import { useContext, useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import OrgContext, { OrgContextActionType } from "views/settings/OrgContext";

type IdpIntegrationButtonProps = {
  idpConnection: Maybe<IdpConnectionFragment>;
  isConnected: boolean;
  onConnectClick?: () => void;
  buttonDisabled: boolean;
};

export const IdpIntegrationButton = (props: IdpIntegrationButtonProps) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const [deleteIdpConnection, { loading }] = useDeleteIdpConnectionMutation();

  const [showConfirmModal, setShowModal] = useState(false);
  const { displaySuccessToast } = useToast();
  const { orgState, orgDispatch } = useContext(OrgContext);

  const confirmModal = (
    <ConfirmModal
      title={`Disconnect ${getNameByIdpConnectionType(
        props.idpConnection?.idpConnectionType
      )}`}
      message={`Are you sure you want to disconnect your ${getNameByIdpConnectionType(
        props.idpConnection?.idpConnectionType
      )} connection?`}
      isModalOpen={showConfirmModal}
      onClose={() => {
        setShowModal(false);
      }}
      onSubmit={async () => {
        if (!props.idpConnection) {
          return;
        }
        try {
          const { data: deleteData } = await deleteIdpConnection({
            variables: {
              input: {
                id: props.idpConnection.id,
              },
            },
            refetchQueries: ["IdpConnection"],
          });
          switch (deleteData?.deleteIdpConnection.__typename) {
            case "DeleteIdpConnectionResult":
              setShowModal(false);
              displaySuccessToast(
                `${getNameByIdpConnectionType(
                  props.idpConnection?.idpConnectionType
                )} connection disconnected`
              );
              orgDispatch({
                type: OrgContextActionType.OrgIdpConnection,
                payload: {
                  orgIdpConnections: orgState.orgIdpConnections.filter(
                    (connection) => connection.id !== props.idpConnection?.id
                  ),
                },
              });
              break;
            default:
              logError(new Error(`failed to disconnect IDP`));
              setErrorMessage("Error: failed to disconnect IDP");
          }
        } catch (error) {
          logError(error, "failed to disconnect IDP");
          setErrorMessage(
            getModifiedErrorMessage("Error: failed to disconnect IDP", error)
          );
        }
      }}
      loading={loading}
      errorMessage={errorMessage}
      submitLabel={"Disconnect"}
    />
  );

  return hasV3 ? (
    <div>
      {props.isConnected ? (
        <ButtonV3
          type="danger"
          leftIconName="disconnect"
          label="Disconnect"
          onClick={() => {
            setShowModal(true);
          }}
          disabled={loading || props.buttonDisabled}
          outline
        />
      ) : (
        <ButtonV3
          type="mainSecondary"
          leftIconName="cloud-blank"
          label="Connect"
          onClick={() => {
            props.onConnectClick && props.onConnectClick();
          }}
          disabled={loading || props.buttonDisabled}
          outline
        />
      )}
      {confirmModal}
    </div>
  ) : (
    <div>
      {props.isConnected ? (
        <Button
          type="error"
          leftIconName="x"
          label="Disconnect"
          onClick={() => {
            setShowModal(true);
          }}
          disabled={loading || props.buttonDisabled}
          outline
        />
      ) : (
        <Button
          type="success"
          leftIconName="plus"
          label="Connect"
          onClick={() => {
            props.onConnectClick && props.onConnectClick();
          }}
          disabled={loading || props.buttonDisabled}
          outline
        />
      )}
      {confirmModal}
    </div>
  );
};
