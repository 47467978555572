import {
  EventFilterFragment,
  Maybe,
  useEventFilterQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import { Button, Divider } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { logError } from "utils/logging";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { createUrlParamsFromEventFilter } from "views/events/utils";
import EventFilterDeleteModal from "views/events/viewer/EventFilterDeleteModal";
import EventFilterViewer from "views/events/viewer/EventFilterViewer";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

const EventFilterDetailColumn = () => {
  const history = useHistory();
  const { eventFilterId } = useParams<Record<string, string>>();
  const [showDeleteEventFilterModal, setShowDeleteEventFilterModal] = useState(
    false
  );

  const { data, error, loading } = useEventFilterQuery({
    variables: {
      input: {
        id: eventFilterId!,
      },
    },
    skip: !eventFilterId,
  });

  let eventFilter: Maybe<EventFilterFragment> = null;
  if (data) {
    switch (data.eventFilter.__typename) {
      case "EventFilterResult":
        eventFilter = data.eventFilter.eventFilter;
        break;
      case "EventFilterNotFoundError":
        return <NotFoundPage />;
      default:
        logError(new Error(`failed to get event filter`));
    }
  } else if (error) {
    logError(error, `failed to get event filter`);
  }

  if (loading) {
    return (
      <Column isContent>
        <ColumnContentSkeleton />
      </Column>
    );
  }

  if (error) {
    logError(error, `failed to list saved event queries`);
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  if (!eventFilter) {
    return null;
  }

  let finalUrlParams = createUrlParamsFromEventFilter(eventFilter);
  const actionButtons = (
    <div className={sprinkles({ display: "flex", gap: "sm" })}>
      <Button
        label="Apply filter"
        borderless
        onClick={() => history.replace("/events" + finalUrlParams)}
        type="primary"
        size="md"
      />
    </div>
  );

  return (
    <>
      <Column isContent maxWidth="lg">
        <ColumnHeader
          icon={{ type: "name", icon: "filter" }}
          title={eventFilter?.name ?? ""}
          menuOptions={[
            {
              label: "Delete",
              onClick: () => setShowDeleteEventFilterModal(true),
              type: "danger",
              icon: { type: "name", icon: "trash" },
            },
          ]}
          rightActions={actionButtons}
        />
        <Divider margin="md" />
        <EventFilterViewer eventFilter={eventFilter} />
      </Column>
      {showDeleteEventFilterModal && (
        <EventFilterDeleteModal
          eventFilter={eventFilter}
          showModal={showDeleteEventFilterModal}
          setShowModal={setShowDeleteEventFilterModal}
        />
      )}
    </>
  );
};

export default EventFilterDetailColumn;
