import {
  EntityType,
  SessionFragment,
  useVaultSessionsQuery,
} from "api/generated/graphql";
import { ResourceLabel } from "components/label/Label";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import MaterialTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MaterialTable";
import moment from "moment/moment";
import React from "react";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { logError } from "utils/logging";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ViewSkeleton from "views/loading/ViewSkeleton";

interface VaultSessionsTableRow {
  user: string;
  startDate: string;
  endDate: string;
}

type VaultSessionsTableProps = {
  resource: {
    id: string;
  };
};

export const VaultSessionsTable = (props: VaultSessionsTableProps) => {
  const { data, error, loading } = useVaultSessionsQuery({
    variables: {
      input: {
        resourceId: props.resource.id,
      },
    },
  });

  let vaultSessions: SessionFragment[] | undefined | null = [];

  if (data) {
    switch (data.vaultSessions.__typename) {
      case "VaultSessionsResult":
        vaultSessions = data.vaultSessions.sessions;
        break;
      default:
        logError(new Error(`unexpected error listing session logs`));
    }
  }

  if (loading) {
    return <ViewSkeleton />;
  }

  if (error) {
    logError(error, `unexpected error listing session logs`);
    return <UnexpectedErrorPage error={error} />;
  }

  const headers: Header<VaultSessionsTableRow>[] = [
    { id: "user", label: "User" },
    { id: "startDate", label: "Start Date" },
    { id: "endDate", label: "End Date" },
  ];

  let rows: CellRow<VaultSessionsTableRow>[] | undefined = vaultSessions?.map(
    (vaultSession) => {
      const user = vaultSession.user;
      const startDate = moment(new Date(vaultSession.createdAt));
      const endDate = moment(new Date(vaultSession.endTime));

      return {
        id: vaultSession.id,
        data: {
          user: {
            value: user!.id,
            element: (
              <>
                {
                  <ResourceLabel
                    text={user?.fullName}
                    entityType={EntityTypeDeprecated.User}
                    avatar={user?.avatarUrl}
                    entityId={user?.id}
                  />
                }
              </>
            ),
          },
          startDate: {
            value: startDate.unix(),
            element: (
              <>{`${startDate.toISOString()} (${startDate.fromNow()})`}</>
            ),
          },
          endDate: {
            value: endDate.unix(),
            element: <>{`${endDate.toISOString()} (${endDate.fromNow()})`}</>,
          },
        },
      };
    }
  );

  return (
    <EmptyStateContentWrapper
      content={
        <MaterialTable<VaultSessionsTableRow>
          headers={headers}
          rows={rows || []}
          denseFormatting={true}
        />
      }
      entityType={EntityType.Activity}
      title={`No sessions for this resource`}
      subtitle={`This resource is not in use by any Opal users.`}
      isEmpty={!rows || rows.length === 0}
    />
  );
};

export default VaultSessionsTable;
