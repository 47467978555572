import { ThirdPartyProvider } from "api/generated/graphql";
import { FormMode } from "components/forms/common";
import { getLogoByThirdPartyProvider } from "components/label/Label";
import TicketProjectDropdown from "components/tickets/TicketProjectDropdown";
import TicketProviderDropdown from "components/tickets/TicketProviderDropdown";
import { FormGroup, FormRow, Icon, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";

export interface TicketProviderFields {
  ticketProviderEnabled: boolean;
  ticketProvider?: ThirdPartyProvider;
  ticketProjectId?: string;
  ticketProjectName?: string;
}

interface Props extends TicketProviderFields {
  mode: FormMode;
  onChange: (input: TicketProviderFields) => void;
}

const ConnectionTicketProviderPropagationRow = (props: Props) => {
  const viewContent = props.ticketProviderEnabled ? (
    <div className={sprinkles({ display: "flex", gap: "sm" })}>
      <Icon
        data={{
          icon: getLogoByThirdPartyProvider(props.ticketProvider) ?? undefined,
          type: "src",
        }}
        size="sm"
      />
      {props.ticketProjectName}
    </div>
  ) : (
    "Disabled"
  );

  const ticketProviderFields = {
    ticketProviderEnabled: props.ticketProviderEnabled,
    ticketProvider: props.ticketProvider,
    ticketProjectId: props.ticketProjectId,
    ticketProjectName: props.ticketProjectName,
  };

  const editContent = (
    <div
      className={sprinkles({
        display: "flex",
        gap: "lg",
        flexDirection: "column",
      })}
    >
      <Switch
        checked={props.ticketProviderEnabled}
        onChange={() => {
          props.onChange({
            ticketProviderEnabled: !props.ticketProviderEnabled,
          });
        }}
        label="Create ticket to propagate access"
      />
      {props.ticketProviderEnabled ? (
        <TicketProviderDropdown
          onSelectTicketProvider={(val) => {
            props.onChange({
              ticketProviderEnabled: true,
              ticketProvider: val,
            });
          }}
          selectedTicketProvider={props.ticketProvider}
        />
      ) : null}
      {props.ticketProviderEnabled && props.ticketProvider ? (
        <FormGroup
          label="Project"
          infoTooltip="Please select the project in which you would want Opal to create
        tickets in."
        >
          <TicketProjectDropdown
            ticketProvider={props.ticketProvider}
            selectedTicketProjectId={props.ticketProjectId}
            selectedTicketProjectName={props.ticketProjectName}
            onSelectTicketProject={(val) => {
              props.onChange({
                ...ticketProviderFields,
                ticketProjectId: val?.id,
                ticketProjectName: val?.name,
              });
            }}
          />
        </FormGroup>
      ) : null}
    </div>
  );

  let helpText = `If enabled, a ticket will be created for all access changes to this
  app's resources in Opal. The ticket assigns one of the resource's
  admins to manually make the access change on the end system.`;

  return (
    <FormRow
      title="Ticket provider for access propagation"
      tooltipText={helpText}
    >
      {props.mode === "view" ? viewContent : editContent}
    </FormRow>
  );
};

export default ConnectionTicketProviderPropagationRow;
