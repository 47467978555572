import {
  RequestTemplateCustomFieldInput,
  RequestTemplateCustomFieldType,
  RequestTemplateFragment,
  useDeleteRequestTemplateMutation,
  useRequestTemplateQuery,
  useUpdateRequestTemplateMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeaderV3";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import {
  ButtonV3,
  FormGroup,
  Icon,
  Input,
  Modal,
  Select,
  Switch,
} from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import { logError } from "utils/logging";
import { ItemDetailsCard } from "views/common/ItemDetailsCard";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { dropNothings } from "views/utils";

import { MultiChoiceFieldEditor } from "./requests/RequestTemplateCustomFieldEdit";
import {
  CUSTOM_FIELD_TYPE_OPTIONS,
  CustomFieldTypeLabels,
} from "./requests/utils";

const CUSTOM_FIELD_NAME_REQUIRED_ERROR =
  "Error: custom fields must have a name.";
const CUSTOM_FIELD_NAME_DISTINCT_ERROR =
  "Error: custom field names must be distinct.";
const DROPDOWN_NO_OPTIONS = "Error: dropdowns must have at least one option.";
const DROPDOWN_EMPTY_OPTION = "Error: dropdown options cannot be empty.";
const DROPDOWN_DUPLICATE_OPTION =
  "Error: a dropdown cannot have duplicate options.";

interface CustomFieldRow {
  index: number;
  name: string;
  type: RequestTemplateCustomFieldType;
  required: boolean;
  description: string;
  actions: boolean;
}

const RequestTemplateDetail = () => {
  const { requestTemplateId } = useParams<{ requestTemplateId: string }>();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showCustomFieldModal, setShowCustomFieldModal] = useState(false);
  const [clickedFieldIndex, setClickedFieldIndex] = useState<
    number | undefined
  >();
  const [selectedFieldIndices, setSelectedFieldIndices] = useState<number[]>(
    []
  );
  const [showDeleteFieldModal, setShowDeleteFieldModal] = useState(false);
  const [showBulkDeleteModal, setShowBulkDeleteModal] = useState(false);

  const { authState } = useContext(AuthContext);
  const isAdmin = authState.user?.isAdmin;

  const { data, error, loading } = useRequestTemplateQuery({
    variables: {
      input: {
        id: requestTemplateId,
      },
    },
    skip: !requestTemplateId,
  });
  const requestTemplate = data?.requestTemplate.requestTemplate;

  if (loading) {
    return (
      <Column isContent maxWidth="none">
        <ColumnHeaderSkeleton includeCard />
      </Column>
    );
  }

  if (error || !requestTemplate) {
    logError(error, "failed to get request template: " + requestTemplateId);
    return (
      <Column isContent maxWidth="none">
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  const detailsCard = (
    <ItemDetailsCard
      title={requestTemplate.name}
      rightActions={
        isAdmin ? (
          <div className={sprinkles({ display: "flex", gap: "sm" })}>
            <ButtonV3
              label="Delete Template"
              type="danger"
              size="sm"
              onClick={() => setShowDeleteModal(true)}
            />
            <ButtonV3
              label="Rename"
              type="main"
              size="sm"
              leftIconName="edit"
              onClick={() => setShowRenameModal(true)}
            />
          </div>
        ) : undefined
      }
    />
  );

  const columns: Header<CustomFieldRow>[] = dropNothings([
    {
      label: "Name",
      id: "name",
    },
    {
      label: "Type",
      id: "type",
      customCellRenderer: (row) => CustomFieldTypeLabels[row.type],
    },
    {
      label: "Required",
      id: "required",
      customCellRenderer: (row) => (row.required ? "Required" : "Not Required"),
    },
    {
      label: "Description",
      id: "description",
    },
    isAdmin
      ? {
          label: "",
          id: "actions",
          width: 40,
          customCellRenderer: (row) => {
            return (
              <div
                className={sprinkles({
                  display: "flex",
                  alignItems: "center",
                  gap: "lg",
                })}
              >
                <Icon
                  name="trash"
                  color="red600V3"
                  size="sm"
                  onClick={() => {
                    setClickedFieldIndex(row.index);
                    setShowDeleteFieldModal(true);
                  }}
                />
                <Icon
                  name="edit"
                  color="blue600V3"
                  size="sm"
                  onClick={() => {
                    setClickedFieldIndex(row.index);
                    setShowCustomFieldModal(true);
                  }}
                />
              </div>
            );
          },
        }
      : undefined,
  ]);

  const rows =
    requestTemplate.customFields?.map((customField, i) => ({
      index: i,
      name: customField.name,
      type: customField.type,
      required: Boolean(customField.required),
      description: customField.description || "--",
      actions: true,
    })) ?? [];

  return (
    <>
      <Column isContent maxWidth="none">
        <ColumnHeader
          breadcrumbs={[
            { name: "Templates", to: "/templates/requests" },
            { name: "Custom Access Requests", to: "/templates/requests" },
            { name: requestTemplate.name },
          ]}
          includeDefaultActions
        />
        <ColumnContent>
          {detailsCard}
          <TableHeader
            totalNumRows={requestTemplate.customFields?.length ?? 0}
            entityName="Custom Field"
            defaultRightActions={
              isAdmin
                ? [
                    {
                      label: "Custom Field",
                      iconName: "plus",
                      type: "mainSecondary",
                      onClick: () => setShowCustomFieldModal(true),
                    },
                  ]
                : undefined
            }
            selectedNumRows={selectedFieldIndices.length}
            bulkRightActions={
              isAdmin
                ? [
                    {
                      label: "Delete",
                      type: "danger",
                      onClick: () => setShowBulkDeleteModal(true),
                    },
                  ]
                : undefined
            }
          />
          <Table
            columns={columns}
            rows={rows}
            totalNumRows={requestTemplate.customFields?.length ?? 0}
            getRowId={(row) => `${row.index}`}
            checkedRowIds={new Set(selectedFieldIndices.map((i) => `${i}`))}
            onCheckedRowsChange={
              isAdmin
                ? (checkedRowIds, checked) => {
                    if (checked) {
                      setSelectedFieldIndices([
                        ...selectedFieldIndices,
                        ...checkedRowIds.map((id) => Number(id)),
                      ]);
                    } else {
                      setSelectedFieldIndices((prev) =>
                        prev.filter((i) => !checkedRowIds.includes(`${i}`))
                      );
                    }
                  }
                : undefined
            }
            selectAllChecked={selectedFieldIndices.length === rows.length}
            onSelectAll={(checked) =>
              checked
                ? setSelectedFieldIndices(rows.map((row) => row.index))
                : setSelectedFieldIndices([])
            }
          />
        </ColumnContent>
      </Column>
      {showDeleteModal && (
        <DeleteModal
          requestTemplate={requestTemplate}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
      {showRenameModal && (
        <RenameModal
          requestTemplate={requestTemplate}
          onClose={() => setShowRenameModal(false)}
        />
      )}
      {showCustomFieldModal && (
        <AddCustomFieldModal
          requestTemplate={requestTemplate}
          onClose={() => setShowCustomFieldModal(false)}
          customField={
            clickedFieldIndex !== undefined
              ? requestTemplate.customFields?.[clickedFieldIndex]
              : undefined
          }
          customFieldIndex={clickedFieldIndex}
        />
      )}
      {showDeleteFieldModal && (
        <DeleteFieldModal
          requestTemplate={requestTemplate}
          fieldIndexToDelete={clickedFieldIndex ?? 0}
          onClose={() => setShowDeleteFieldModal(false)}
        />
      )}
      {showBulkDeleteModal && (
        <BulkDeleteFieldsModal
          requestTemplate={requestTemplate}
          fieldIndicesToDelete={selectedFieldIndices}
          onClose={() => {
            setShowBulkDeleteModal(false);
            setSelectedFieldIndices([]);
          }}
        />
      )}
    </>
  );
};

interface DeleteProps {
  requestTemplate: RequestTemplateFragment;
  onClose: () => void;
}

const DeleteModal = (props: DeleteProps) => {
  const history = useHistory();
  const { requestTemplate } = props;
  const { displaySuccessToast } = useToast();

  const [errors, setErrors] = useState<string[]>([]);

  const [
    deleteRequestTemplate,
    { loading: deleteLoading },
  ] = useDeleteRequestTemplateMutation();

  const handleDelete = async () => {
    if (!props.requestTemplate) {
      return;
    }
    try {
      const { data } = await deleteRequestTemplate({
        variables: {
          input: {
            id: props.requestTemplate.id,
          },
        },
        refetchQueries: ["RequestTemplates"],
      });
      switch (data?.deleteRequestTemplate.__typename) {
        case "DeleteRequestTemplateResult":
          displaySuccessToast("Request template deleted.");
          history.push("/templates/requests");
          props.onClose();
          break;
        default:
          setErrors(["Error: failed to delete request template."]);
          logError(new Error("failed to delete request template"));
      }
    } catch (error) {
      setErrors(["Error: failed to delete request template."]);
      logError(error, "failed to delete request template");
    }
  };

  return (
    <Modal
      title={`Delete ${requestTemplate.name}`}
      isOpen
      onClose={props.onClose}
    >
      <Modal.Body>
        {errors.map((error) => (
          <ModalErrorMessage errorMessage={error} />
        ))}
        Are you sure you want to delete "{requestTemplate.name}"? This cannot be
        undone.
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Delete"
        onPrimaryButtonClick={handleDelete}
        primaryButtonLoading={deleteLoading}
      />
    </Modal>
  );
};

interface RenameModalProps {
  requestTemplate: RequestTemplateFragment;
  onClose: () => void;
}

const RenameModal = (props: RenameModalProps) => {
  const { requestTemplate } = props;
  const { displaySuccessToast } = useToast();

  const [newName, setNewName] = useState(requestTemplate.name);
  const [errors, setErrors] = useState<string[]>([]);

  const [
    updateRequestTemplate,
    { loading: saveLoading },
  ] = useUpdateRequestTemplateMutation();

  const handleRename = async () => {
    try {
      const { data } = await updateRequestTemplate({
        variables: {
          input: {
            id: props.requestTemplate.id,
            name: newName,
          },
        },
        refetchQueries: ["RequestTemplate", "RequestTemplates"],
      });
      switch (data?.updateRequestTemplate.__typename) {
        case "UpdateRequestTemplateResult":
          displaySuccessToast("Request template name updated.");
          props.onClose();
          break;
        case "RequestTemplateNameExistsError":
          setErrors([data.updateRequestTemplate.message]);
          logError(new Error(data.updateRequestTemplate.message));
          break;
        default:
          setErrors(["Error: failed to update request template name."]);
          logError(
            new Error(
              "failed to update request template name for: " +
                props.requestTemplate.id
            )
          );
      }
    } catch (error) {
      setErrors(["Error: failed to update request template name."]);
      logError(
        error,
        "failed to update request template name for: " +
          props.requestTemplate.id
      );
    }
  };

  return (
    <Modal
      title={`Rename ${requestTemplate.name}`}
      isOpen
      onClose={props.onClose}
    >
      <Modal.Body>
        {errors.map((error) => (
          <ModalErrorMessage errorMessage={error} />
        ))}
        <Input value={newName} onChange={setNewName} />
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Rename"
        onPrimaryButtonClick={handleRename}
        primaryButtonDisabled={
          !newName || newName === props.requestTemplate.name
        }
        primaryButtonLoading={saveLoading}
      />
    </Modal>
  );
};

interface CustomFieldModalProps {
  requestTemplate: RequestTemplateFragment;
  onClose: () => void;
  // Edit
  customField?: RequestTemplateCustomFieldInput;
  customFieldIndex?: number;
}

const AddCustomFieldModal = (props: CustomFieldModalProps) => {
  const { displaySuccessToast } = useToast();

  const [newField, setNewField] = useState<RequestTemplateCustomFieldInput>(
    props.customField ?? {
      name: "",
      type: RequestTemplateCustomFieldType.ShortText,
    }
  );
  const [errors, setErrors] = useState<string[]>([]);

  const [
    updateRequestTemplate,
    { loading },
  ] = useUpdateRequestTemplateMutation();

  const validateForm = () => {
    const formErrors = new Set<string>();
    const customFieldNames =
      props.requestTemplate.customFields?.map((field, i) =>
        i === props.customFieldIndex ? "" : field.name
      ) ?? [];

    if (!newField.name) {
      formErrors.add(CUSTOM_FIELD_NAME_REQUIRED_ERROR);
    }
    if (customFieldNames.includes(newField.name)) {
      formErrors.add(CUSTOM_FIELD_NAME_DISTINCT_ERROR);
    }
    if (newField.type === RequestTemplateCustomFieldType.MultiChoice) {
      const allOptions = new Set();
      if (!newField.metadata?.multiChoiceData?.options?.length) {
        formErrors.add(DROPDOWN_NO_OPTIONS);
      } else {
        newField.metadata.multiChoiceData.options.forEach((option) => {
          if (!option.value) {
            formErrors.add(DROPDOWN_EMPTY_OPTION);
          }
          if (allOptions.has(option.value)) {
            formErrors.add(DROPDOWN_DUPLICATE_OPTION);
          }
          allOptions.add(option.value);
        });
      }
    }

    setErrors(Array.from(formErrors));
    return formErrors;
  };

  const handleSave = async () => {
    try {
      if (validateForm().size > 0) {
        return;
      }
      setErrors([]);
      const { data } = await updateRequestTemplate({
        variables: {
          input: {
            id: props.requestTemplate.id,
            name: props.requestTemplate.name,
            customFields:
              props.customFieldIndex !== undefined
                ? props.requestTemplate.customFields?.map((field, i) =>
                    i === props.customFieldIndex ? newField : field
                  )
                : [...(props.requestTemplate.customFields ?? []), newField],
          },
        },
        refetchQueries: ["RequestTemplate", "RequestTemplates"],
      });
      switch (data?.updateRequestTemplate.__typename) {
        case "UpdateRequestTemplateResult":
          displaySuccessToast("Request template updated.");
          props.onClose();
          break;
        case "RequestTemplateNameExistsError":
          setErrors([data.updateRequestTemplate.message]);
          logError(new Error(data.updateRequestTemplate.message));
          break;
        case "CustomFieldExistsError":
          setErrors([data.updateRequestTemplate.message]);
          logError(new Error(data.updateRequestTemplate.message));
          break;
        default:
          setErrors(["Error: failed to update request template."]);
          logError(
            new Error(
              "failed to update request template: " + props.requestTemplate.id
            )
          );
      }
    } catch (error) {
      setErrors(["Error: failed to update request template."]);
      logError(
        error,
        "failed to update request template: " + props.requestTemplate.id
      );
    }
  };

  return (
    <Modal
      title={`${props.customField ? "Edit" : "Create"} Custom Field`}
      isOpen
      onClose={props.onClose}
    >
      <Modal.Body>
        {errors.map((error) => (
          <ModalErrorMessage errorMessage={error} />
        ))}
        <FormGroup label="Name" required>
          <Input
            value={newField.name}
            onChange={(val) => setNewField((prev) => ({ ...prev, name: val }))}
          />
        </FormGroup>
        <FormGroup label="Type">
          <Select
            options={CUSTOM_FIELD_TYPE_OPTIONS}
            value={newField.type}
            onChange={(opt) => {
              setNewField((prev) => {
                const newField: RequestTemplateCustomFieldInput = {
                  type: opt ?? prev.type,
                  name: prev.name,
                  required: prev.required,
                };
                if (opt === RequestTemplateCustomFieldType.MultiChoice) {
                  newField.metadata = {
                    multiChoiceData: {
                      options: [],
                    },
                  };
                }
                return newField;
              });
            }}
            getOptionLabel={(opt) => CustomFieldTypeLabels[opt]}
          />
        </FormGroup>
        <FormGroup label="Description">
          <Input
            type="textarea"
            value={newField.description || ""}
            onChange={(val) =>
              setNewField((prev) => ({
                ...prev,
                description: val,
              }))
            }
          />
        </FormGroup>
        <Switch
          label="Required"
          checked={Boolean(newField.required)}
          onChange={(checked) =>
            setNewField((prev) => ({
              ...prev,
              required: checked,
            }))
          }
        />
        <div className={sprinkles({ marginTop: "lg" })}>
          {newField.type === RequestTemplateCustomFieldType.MultiChoice ? (
            <MultiChoiceFieldEditor
              options={
                newField.metadata?.multiChoiceData?.options?.map(
                  ({ value }) => value
                ) ?? []
              }
              onChange={(opts) =>
                setNewField((prev) => ({
                  ...prev,
                  metadata: {
                    ...prev.metadata,
                    multiChoiceData: {
                      options: opts.map((val) => ({ label: val, value: val })),
                    },
                  },
                }))
              }
            />
          ) : null}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Submit"
        primaryButtonLoading={loading}
        onPrimaryButtonClick={handleSave}
        primaryButtonDisabled={!newField.name}
      />
    </Modal>
  );
};

const DeleteFieldModal = (props: {
  requestTemplate: RequestTemplateFragment;
  fieldIndexToDelete: number;
  onClose: () => void;
}) => {
  const { displaySuccessToast } = useToast();

  const [errors, setErrors] = useState<string[]>([]);

  const [
    updateRequestTemplate,
    { loading },
  ] = useUpdateRequestTemplateMutation();

  const handleDelete = async () => {
    try {
      const { data } = await updateRequestTemplate({
        variables: {
          input: {
            id: props.requestTemplate.id,
            name: props.requestTemplate.name,
            customFields: props.requestTemplate.customFields?.filter((_, i) =>
              i === props.fieldIndexToDelete ? false : true
            ),
          },
        },
        refetchQueries: ["RequestTemplate", "RequestTemplates"],
      });
      switch (data?.updateRequestTemplate.__typename) {
        case "UpdateRequestTemplateResult":
          displaySuccessToast("Request template updated.");
          props.onClose();
          break;
        default:
          setErrors(["Error: failed to update request template."]);
          logError(
            new Error(
              "failed to update request template: " + props.requestTemplate.id
            )
          );
      }
    } catch (error) {
      setErrors(["Error: failed to update request template."]);
      logError(
        error,
        "failed to update request template: " + props.requestTemplate.id
      );
    }
  };

  return (
    <Modal title="Delete Custom Field" isOpen onClose={props.onClose}>
      <Modal.Body>
        {errors.map((error) => (
          <ModalErrorMessage errorMessage={error} />
        ))}
        Are you sure you want to delete "
        {props.requestTemplate.customFields?.[props.fieldIndexToDelete]?.name}"?
        This cannot be undone.
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Delete"
        primaryButtonLoading={loading}
        onPrimaryButtonClick={handleDelete}
      />
    </Modal>
  );
};

const BulkDeleteFieldsModal = (props: {
  requestTemplate: RequestTemplateFragment;
  fieldIndicesToDelete: number[];
  onClose: () => void;
}) => {
  const { displaySuccessToast } = useToast();

  const [errors, setErrors] = useState<string[]>([]);

  const [
    updateRequestTemplate,
    { loading },
  ] = useUpdateRequestTemplateMutation();

  const handleDelete = async () => {
    try {
      const { data } = await updateRequestTemplate({
        variables: {
          input: {
            id: props.requestTemplate.id,
            name: props.requestTemplate.name,
            customFields: props.requestTemplate.customFields?.filter((_, i) =>
              props.fieldIndicesToDelete.includes(i) ? false : true
            ),
          },
        },
        refetchQueries: ["RequestTemplate", "RequestTemplates"],
      });
      switch (data?.updateRequestTemplate.__typename) {
        case "UpdateRequestTemplateResult":
          displaySuccessToast("Request template updated.");
          props.onClose();
          break;
        default:
          setErrors(["Error: failed to update request template."]);
          logError(
            new Error(
              "failed to update request template: " + props.requestTemplate.id
            )
          );
      }
    } catch (error) {
      setErrors(["Error: failed to update request template."]);
      logError(
        error,
        "failed to update request template: " + props.requestTemplate.id
      );
    }
  };

  return (
    <Modal title="Delete Custom Field" isOpen onClose={props.onClose}>
      <Modal.Body>
        {errors.map((error) => (
          <ModalErrorMessage errorMessage={error} />
        ))}
        Are you sure you want to delete{" "}
        {props.requestTemplate.customFields
          ?.filter((_, i) => props.fieldIndicesToDelete.includes(i))
          .map((field) => field.name)
          .join(", ")}
        ? This cannot be undone.
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Delete"
        primaryButtonLoading={loading}
        onPrimaryButtonClick={handleDelete}
      />
    </Modal>
  );
};

export default RequestTemplateDetail;
