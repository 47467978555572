import {
  AccessReviewAction,
  AccessReviewConnectionFragment,
  AccessReviewConnectionUserFragment,
  AccessReviewType,
  EntityType,
  HrIdpStatus,
  ReviewConnectionUserAction,
  ReviewerUserFragment,
  ReviewerUserStatus,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import { ResourceLabel, TooltipPlacement } from "components/label/Label";
import { Icon, Input, Label, ProgressBar, Tooltip } from "components/ui";
import ButtonGroup from "components/ui/buttongroup/ButtonGroupV3";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { filterSearchResults } from "utils/search/filter";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { formatHrIdpStatus } from "views/users/utils";

import { calculateBatchAcceptRevokeState } from "./AccessReviewAcceptRevokeToggleButton";
import {
  AccessReviewContextActionType,
  AccessReviewContextEvent,
  AccessReviewContextState,
  emptyPerformReviewState,
} from "./AccessReviewContext";
import * as styles from "./AccessReviewResourceV3.css";
import DecisionButtons from "./common/DecisionButtons";
import NoteCell from "./common/NoteCell";
import StatusCell from "./common/StatusCell";
import { ReviewerUsers } from "./ReviewerUsers";

interface ConnectionUserRow {
  id: string;
  user: string;
  title?: string;
  manager?: string;
  team?: string;
  hrIdpStatus?: HrIdpStatus;
  outcome?: string;
  note?: string;
  reviewerUsers?: ReviewerUserFragment[];
  data: AccessReviewConnectionUserFragment;
}

interface Props {
  accessReviewConnection: AccessReviewConnectionFragment;
  accessReviewState: AccessReviewContextState;
  accessReviewDispatch: React.Dispatch<AccessReviewContextEvent>;
}

const AccessReviewConnectionV3 = ({
  accessReviewConnection,
  accessReviewState,
  accessReviewDispatch,
}: Props) => {
  const accessReviewId = accessReviewConnection.accessReviewId;
  const { accessReviewConnectionId } = useParams<Record<string, string>>();

  const { authState } = useContext(AuthContext);
  const [selectedConnectionUserIds, setSelectedConnectionUserIds] = useState<
    string[]
  >([]);
  const [
    selectedBulkAction,
    setSelectedBulkAction,
  ] = useState<AccessReviewAction>(AccessReviewAction.NoAction);
  const [bulkUpdatedNote, setBulkUpdatedNote] = useState<string>();

  const [searchQuery, setSearchQuery] = useState("");

  const performReviewState =
    accessReviewState.performReviewStateByUARConnectionId[
      accessReviewConnectionId
    ];

  // Initialize review state if not already initialized
  useEffect(() => {
    if (!performReviewState || !performReviewState.connectionUserActions) {
      accessReviewDispatch({
        type: AccessReviewContextActionType.AccessReviewItemUpdate,
        payload: {
          performReviewStateByUARConnectionId: {
            ...accessReviewState.performReviewStateByUARConnectionId,
            [accessReviewConnectionId]: emptyPerformReviewState(),
          },
        },
      });
    }
  }, [
    accessReviewState,
    accessReviewConnectionId,
    performReviewState,
    accessReviewDispatch,
  ]);

  const selfReviewAllowed =
    accessReviewConnection?.accessReview?.selfReviewAllowed;
  const connection = accessReviewConnection?.connection;

  // Separate out completed and not started users
  const noteByConnectionUserID: Record<string, string> = {};
  const notStartedUsers = (
    accessReviewConnection?.connectionUsers ?? []
  ).filter((user) => {
    return user.reviewerUsers?.find((reviewer) => {
      if (reviewer.userId === authState.user?.user.id) {
        noteByConnectionUserID[user.id] = reviewer.note ?? "";
      }
      return (
        reviewer.userId === authState.user?.user.id &&
        (selfReviewAllowed || authState.user?.user.id !== user.userId) &&
        reviewer.status === ReviewerUserStatus.NotStarted
      );
    });
  });
  const completedUsers = (accessReviewConnection?.connectionUsers ?? []).filter(
    (user) => {
      return user.reviewerUsers?.find((reviewer) => {
        if (reviewer.userId === authState.user?.user.id) {
          noteByConnectionUserID[user.id] = reviewer.note ?? "";
        }
        return (
          reviewer.userId === authState.user?.user.id &&
          (selfReviewAllowed || authState.user?.user.id !== user.userId) &&
          reviewer.status !== ReviewerUserStatus.NotStarted
        );
      });
    }
  );

  const [showCompleted, setShowCompleted] = useState(
    notStartedUsers.length === 0 && completedUsers.length > 0
  );

  const filteredSearchConnectionUsers = filterSearchResults(
    showCompleted ? completedUsers : notStartedUsers,
    searchQuery,
    (connectionUser) => [
      connectionUser.user?.fullName,
      connectionUser.user?.email,
    ]
  );

  if (!performReviewState) {
    return null;
  }

  if (!connection) {
    return (
      <Column isContent maxWidth="lg">
        <UnexpectedErrorPage />
      </Column>
    );
  }

  const getConnectionUserReviewState = (connectionUserId: string) => {
    return performReviewState.connectionUserActions.find(
      (ru) => ru.accessReviewConnectionUserId === connectionUserId
    )?.action;
  };

  const handleReviewAction = (
    state: AccessReviewAction,
    accessReviewConnectionUserId: string
  ) => {
    const existingInfo = performReviewState.connectionUserActions.find(
      (connectionUserAction) =>
        connectionUserAction.accessReviewConnectionUserId ===
        accessReviewConnectionUserId
    );

    if (existingInfo) {
      existingInfo.action = state;
    } else {
      performReviewState.connectionUserActions.push({
        accessReviewConnectionUserId,
        action: state,
      });
    }
    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARConnectionId: {
          ...accessReviewState.performReviewStateByUARConnectionId,
          [accessReviewConnectionId]: performReviewState,
        },
      },
    });
  };

  const handleBulkReviewAction = (state: AccessReviewAction) => {
    performReviewState.connectionUserActions.forEach((connectionUserAction) => {
      connectionUserAction.action = state;
    });

    notStartedUsers.forEach((connectionUser) => {
      const existingInfo = performReviewState.connectionUserActions.find(
        (connectionUserAction) =>
          connectionUserAction.accessReviewConnectionUserId ===
          connectionUser.id
      );

      if (!existingInfo) {
        performReviewState.connectionUserActions.push({
          accessReviewConnectionUserId: connectionUser.id,
          action: state,
        });
      }
    });

    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARConnectionId: {
          ...accessReviewState.performReviewStateByUARConnectionId,
          [accessReviewConnectionId]: performReviewState,
        },
      },
    });
  };

  const connectionUserActionByUarConnectionUserId: Record<
    string,
    ReviewConnectionUserAction
  > = {};
  performReviewState?.connectionUserActions?.forEach((connectionUserAction) => {
    const id = connectionUserAction.accessReviewConnectionUserId;
    connectionUserActionByUarConnectionUserId[id] = connectionUserAction;
  });

  const rows: ConnectionUserRow[] = filteredSearchConnectionUsers.map(
    (connectionUser) => {
      const connectionUserAction =
        connectionUserActionByUarConnectionUserId[connectionUser.id];
      const otherReviewers = (connectionUser.reviewerUsers ?? []).filter(
        (reviewer) => reviewer.userId !== authState.user?.user.id
      );

      const row: ConnectionUserRow = {
        id: connectionUser.id,
        user: connectionUser.user?.fullName || connectionUser.userId,
        title: connectionUser.user?.position,
        manager: connectionUser.user?.manager?.fullName,
        team: connectionUser.user?.teamAttr ?? undefined,
        hrIdpStatus: connectionUser.user?.hrIdpStatus ?? undefined,
        outcome: connectionUser.statusAndOutcome.outcome,
        note:
          connectionUserAction?.note ??
          noteByConnectionUserID[connectionUser.id] ??
          undefined,
        reviewerUsers: otherReviewers,
        data: connectionUser,
      };
      return row;
    }
  );

  const bulkState = calculateBatchAcceptRevokeState(
    performReviewState.connectionUserActions,
    notStartedUsers.length
  );
  const bulkDecisionHeader = (
    <DecisionButtons
      state={bulkState}
      onApproveClick={() =>
        handleBulkReviewAction(
          bulkState === AccessReviewAction.Accept
            ? AccessReviewAction.NoAction
            : AccessReviewAction.Accept
        )
      }
      onRevokeClick={() =>
        handleBulkReviewAction(
          bulkState === AccessReviewAction.Revoke
            ? AccessReviewAction.NoAction
            : AccessReviewAction.Revoke
        )
      }
      clearBackground
      bulkAction
    />
  );

  const userColumn: Header<ConnectionUserRow> = {
    id: "user",
    label: "User",
    width: 130,
    customCellRenderer: (row) => {
      const user = row.data.user;
      if (!user) {
        return <></>;
      }
      return (
        <ResourceLabel
          text={user.fullName}
          avatar={user.avatarUrl}
          pointerCursor={true}
          entityId={user.id}
          entityTypeNew={EntityType.User}
          target="_blank"
        />
      );
    },
  };

  const outcomeColumn: Header<ConnectionUserRow> = {
    id: "outcome",
    label: "",
    sortable: false,
    customHeader: rows.length > 0 ? bulkDecisionHeader : <></>,
    width: 100,
    customCellRenderer: (row) => {
      const state = getConnectionUserReviewState(row.id);
      return (
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
          })}
        >
          <DecisionButtons
            state={state}
            onApproveClick={() =>
              handleReviewAction(
                state === AccessReviewAction.Accept
                  ? AccessReviewAction.NoAction
                  : AccessReviewAction.Accept,
                row.id
              )
            }
            onRevokeClick={() =>
              handleReviewAction(
                state === AccessReviewAction.Revoke
                  ? AccessReviewAction.NoAction
                  : AccessReviewAction.Revoke,
                row.id
              )
            }
          />
          <NoteCell
            note={row.note}
            accessReviewID={accessReviewId}
            targetIDs={[row.id]}
            targetType={AccessReviewType.ConnectionUser}
            onNoteChange={(updatedNoteContent) => {
              const connectionUser = row.data;
              if (!performReviewState) {
                return;
              }

              const existingInfo = performReviewState.connectionUserActions.find(
                (connectionUserAction) =>
                  connectionUserAction.accessReviewConnectionUserId ===
                  connectionUser.id
              );

              if (existingInfo) {
                existingInfo.note = updatedNoteContent;
              } else {
                performReviewState.connectionUserActions.push({
                  accessReviewConnectionUserId: connectionUser.id,
                  action: AccessReviewAction.NoAction,
                  note: updatedNoteContent,
                });
              }

              accessReviewDispatch({
                type: AccessReviewContextActionType.AccessReviewItemUpdate,
                payload: {
                  performReviewStateByUARConnectionId: {
                    ...accessReviewState.performReviewStateByUARConnectionId,
                    [accessReviewConnectionId]: performReviewState,
                  },
                },
              });
            }}
          />
        </div>
      );
    },
  };

  const noteColumn: Header<ConnectionUserRow> = {
    id: "note",
    label: "",
    sortable: false,
    width: 24,
    customCellRenderer: (row) => {
      if (!row.note) return <></>;
      return (
        <Tooltip tooltipText={row.note} placement={TooltipPlacement.Top}>
          <Icon name="message" color="blue700V3" />
        </Tooltip>
      );
    },
  };

  const reviewerUsersColumn: Header<ConnectionUserRow> = {
    id: "reviewerUsers",
    label: "",
    width: 24,
    customCellRenderer: (row) => {
      if (!row.reviewerUsers || row.reviewerUsers.length <= 1) {
        return <></>;
      }

      return <ReviewerUsers reviewerUsers={row.reviewerUsers} />;
    },
  };

  const outcomeCompletedColumn: Header<ConnectionUserRow> = {
    id: "outcome", // Only shown in the completed view
    label: "Status",
    sortable: false,
    width: 80,
    customCellRenderer: (row) => {
      const outcome = row.data.statusAndOutcome.outcome;
      const status = row.data.statusAndOutcome.status;
      if (!outcome || !status) {
        return <></>;
      }

      return <StatusCell outcome={outcome} status={status} />;
    },
  };

  const titleColumn: Header<ConnectionUserRow> = {
    id: "title",
    label: "Title",
    width: 120,
    customCellRenderer: (row) => {
      return <Label label={row.title || "--"} truncateLength={20} oneLine />;
    },
  };

  const managerColumn: Header<ConnectionUserRow> = {
    id: "manager",
    label: "Manager",
    width: 130,
    customCellRenderer: (row) => {
      const manager = row.data.user?.manager;
      if (!manager) {
        return "--";
      }
      return (
        <ResourceLabel
          text={manager.fullName}
          avatar={manager.avatarUrl}
          pointerCursor={true}
          entityId={manager.id}
          entityTypeNew={EntityType.User}
          target="_blank"
        />
      );
    },
  };

  const teamColumn: Header<ConnectionUserRow> = {
    id: "team",
    label: "Team",
    width: 90,
    customCellRenderer: (row) => {
      return <Label label={row.team || "--"} oneLine />;
    },
  };

  const hrIdpStatusColumn: Header<ConnectionUserRow> = {
    id: "hrIdpStatus",
    label: "HR IDP Status",
    width: 65,
    customCellRenderer: (row) => {
      return row.hrIdpStatus ? formatHrIdpStatus(row.hrIdpStatus) : "—-";
    },
  };

  const columns: Header<ConnectionUserRow>[] = [
    userColumn,
    titleColumn,
    managerColumn,
    teamColumn,
    hrIdpStatusColumn,
    noteColumn,
    reviewerUsersColumn,
    showCompleted ? outcomeCompletedColumn : outcomeColumn,
  ];

  return (
    <div
      className={styles.tableContainer({
        multipleTabs: false,
      })}
    >
      <div className={styles.topRow}>
        <div className={styles.controls}>
          <div className={styles.searchContainer}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Filter"
            />
          </div>
          <ButtonGroup
            buttons={[
              {
                label: "Todo",
                onClick: () => setShowCompleted(false),
                selected: !showCompleted,
              },
              {
                label: "Completed",
                onClick: () => setShowCompleted(true),
                selected: showCompleted,
              },
            ]}
          />
          {selectedConnectionUserIds.length > 0 && (
            <div
              className={sprinkles({
                display: "flex",
                alignItems: "center",
                gap: "sm",
              })}
            >
              <div>
                {`You have selected ${
                  selectedConnectionUserIds.length
                } ${pluralize("item", selectedConnectionUserIds.length)}:`}
              </div>
              <DecisionButtons
                state={selectedBulkAction}
                onApproveClick={() => {
                  const actionToPerform =
                    selectedBulkAction === AccessReviewAction.Accept
                      ? AccessReviewAction.NoAction
                      : AccessReviewAction.Accept;
                  selectedConnectionUserIds.forEach((ruID) => {
                    handleReviewAction(actionToPerform, ruID);
                  });
                  setSelectedBulkAction(actionToPerform);
                }}
                onRevokeClick={() => {
                  const actionToPerform =
                    selectedBulkAction === AccessReviewAction.Revoke
                      ? AccessReviewAction.NoAction
                      : AccessReviewAction.Revoke;
                  selectedConnectionUserIds.forEach((ruID) => {
                    handleReviewAction(actionToPerform, ruID);
                  });
                  setSelectedBulkAction(actionToPerform);
                }}
                bulkAction
              />
              <NoteCell
                note={bulkUpdatedNote}
                accessReviewID={accessReviewId}
                targetIDs={selectedConnectionUserIds}
                targetType={AccessReviewType.ConnectionUser}
                onNoteChange={(newNote) => {
                  selectedConnectionUserIds.forEach((connectionUserId) => {
                    const existingInfo = performReviewState.connectionUserActions.find(
                      (connectionUserAction) =>
                        connectionUserAction.accessReviewConnectionUserId ===
                        connectionUserId
                    );

                    if (existingInfo) {
                      existingInfo.note = newNote;
                    } else {
                      performReviewState.connectionUserActions.push({
                        accessReviewConnectionUserId: connectionUserId,
                        action: AccessReviewAction.NoAction,
                        note: newNote,
                      });
                    }
                    accessReviewDispatch({
                      type:
                        AccessReviewContextActionType.AccessReviewItemUpdate,
                      payload: {
                        performReviewStateByUARResourceId: {
                          ...accessReviewState.performReviewStateByUARResourceId,
                          [accessReviewConnectionId]: performReviewState,
                        },
                      },
                    });
                    setBulkUpdatedNote(newNote);
                  });
                }}
              />
            </div>
          )}
        </div>

        <div className={styles.progressBarContainer}>
          <Icon name="check-circle" color="green600" size="xs" />
          <span
            className={sprinkles({
              fontSize: "bodyLg",
              color: "gray700",
            })}
          >{`${completedUsers.length}/${
            notStartedUsers.length + completedUsers.length
          }`}</span>
          <div className={styles.progressBar}>
            <ProgressBar
              size="md"
              percentProgress={
                (completedUsers.length /
                  (notStartedUsers.length + completedUsers.length)) *
                100
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.tableHeader}>{`${pluralize(
        "User",
        rows.length,
        true
      )} ${showCompleted ? "completed" : "to review"}`}</div>
      <Table
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(ru) => ru.id}
        columns={columns}
        emptyState={{
          title: showCompleted ? "No completed users" : "No users to review",
        }}
        onCheckedRowsChange={
          !showCompleted
            ? (checkedRowIds, checked) => {
                if (checked) {
                  setSelectedConnectionUserIds((prev) => [
                    ...prev,
                    ...checkedRowIds,
                  ]);
                } else {
                  setSelectedConnectionUserIds((prev) =>
                    prev.filter((id) => !checkedRowIds.includes(id))
                  );
                }
              }
            : undefined
        }
        checkedRowIds={new Set(selectedConnectionUserIds)}
        selectAllChecked={selectedConnectionUserIds.length === rows.length}
        onSelectAll={(checked) => {
          if (checked) {
            setSelectedConnectionUserIds(rows.map((row) => row.id));
          } else {
            setSelectedConnectionUserIds([]);
          }
        }}
      />
    </div>
  );
};

export default AccessReviewConnectionV3;
