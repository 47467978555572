import {
  Factor,
  FactorFragment,
  FactorType,
  Maybe,
  useListFactorsQuery,
  useVerifyFactorAsyncMutation,
  useVerifyFactorSyncMutation,
  VerifyFactorStatus,
} from "api/generated/graphql";
import oktaVerifyIcon from "assets/logos/okta-verify-icon.png";
import opalLogoSpinner from "assets/spinners/opal-logo-small.gif";
import AuthContext from "components/auth/AuthContext";
import { getLogoByMfaType, getNameByMfaType } from "components/label/Label";
import * as styles from "components/modals/IdpMfaModal.css";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Label, Modal, Select } from "components/ui";
import React, { useCallback, useContext, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { logError } from "utils/logging";

type IdpMfaModalProps = {
  onClose: () => void;

  // Perform the user's action after MFA Success.
  onMfaSuccess: () => void;
};

function getInsufficientApiTokenPermissionError(isAdmin: boolean) {
  if (isAdmin) {
    return "Error: Your Okta API token has insufficient permissions. Group Admin is required at the minimum.";
  }
  return "Error: Your organization's Okta integration was configured incorrectly. Please contact an admin for support.";
}

export const IdpMfaModal = (props: IdpMfaModalProps) => {
  const { authState } = useContext(AuthContext);
  const [selectedFactor, setSelectedFactor] = useState<Maybe<Factor>>(null);

  const { data, error, loading } = useListFactorsQuery();

  let errorMessage = undefined;

  let factors: Factor[] = React.useMemo(() => [], []);
  if (data?.listFactors) {
    switch (data?.listFactors.__typename) {
      case "ListFactorsResult":
        factors = data.listFactors.factors;
        if (factors.length === 0) {
          errorMessage =
            "Opal currently only supports Okta Verify as an MFA factor. Please set up a supported factor in your Okta user settings to continue.";
        }
        break;
      case "NoIDPConfiguredError":
        errorMessage = "Error: identity provider is not configured.";
        break;
      case "ConnectionUserNotFound":
        errorMessage =
          "Okta account not found. If you believe this is in error, please contact an admin for support.";
        break;
      case "MissingTokenPermissionError":
        errorMessage = getInsufficientApiTokenPermissionError(
          authState.user?.isAdmin || false
        );
        break;
    }
  } else if (error) {
    logError(error, `failed to list factors`);
    errorMessage = "Error: failed to list factors";
  }

  useEffect(() => {
    if (factors.length === 1) {
      setSelectedFactor(factors[0]);
      return;
    }
    for (const factor of factors) {
      if (factor.factorType === FactorType.OktaPush) {
        setSelectedFactor(factor);
      }
    }
  }, [factors]);

  const listFactorsModal = (
    <Modal title={"Verify MFA"} isOpen={true} onClose={props.onClose}>
      <Modal.Body>
        <p>MFA verification is required to proceed.</p>
        {factors.length > 0 && (
          <Select
            options={factors}
            placeholder={"Select an MFA factor..."}
            getOptionLabel={(factor: Factor) =>
              getNameByMfaType(factor.factorType)
            }
            getIcon={(factor) => {
              return {
                icon: getLogoByMfaType(factor.factorType) ?? undefined,
                type: "src",
              };
            }}
            value={factors.find(
              (factor) =>
                factor.factorRemoteId === selectedFactor?.factorRemoteId
            )}
            onChange={(factor) => {
              if (!factor) return;
              let newFactor: Factor = {
                factorRemoteId: factor.factorRemoteId,
                factorType: factor.factorType,
              };
              setSelectedFactor(newFactor);
            }}
            loading={loading}
          />
        )}
        {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel={factors.length > 0 ? "Next" : "OK"}
        onPrimaryButtonClick={props.onClose}
        primaryButtonDisabled={factors.length > 0 && selectedFactor === null}
      />
    </Modal>
  );

  return (
    <>
      {selectedFactor == null && !loading && listFactorsModal}
      {selectedFactor && selectedFactor.factorType == FactorType.OktaTotp && (
        <VerifySyncFactorModal
          selectedFactor={selectedFactor}
          onClose={props.onClose}
          onMfaSuccess={props.onMfaSuccess}
        />
      )}
      {selectedFactor && selectedFactor.factorType == FactorType.OktaPush && (
        <VerifyAsyncFactorModal
          selectedFactor={selectedFactor}
          onClose={props.onClose}
          onMfaSuccess={props.onMfaSuccess}
        />
      )}
    </>
  );
};

type VerifyAsyncFactorModalProps = {
  selectedFactor: FactorFragment;
  onClose: () => void;
  onMfaSuccess: () => void;
};

const VerifyAsyncFactorModal = (props: VerifyAsyncFactorModalProps) => {
  const { authState } = useContext(AuthContext);

  const [transactionId, setTransactionId] = useState<Maybe<string>>(null);
  const [status, setStatus] = useState<VerifyFactorStatus>();
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const [verifyFactorAsync] = useVerifyFactorAsyncMutation();

  const { displaySuccessToast } = useToast();

  useEffect(() => {
    const refetcher = setInterval(async () => {
      if (
        !transactionId ||
        status == VerifyFactorStatus.Success ||
        status == VerifyFactorStatus.Timeout ||
        status == VerifyFactorStatus.Rejected
      ) {
        return;
      }
      try {
        const { data } = await verifyFactorAsync({
          variables: {
            input: {
              factorRemoteId: props.selectedFactor.factorRemoteId,
              transactionId: transactionId,
            },
          },
        });
        switch (data?.verifyFactorAsync.__typename) {
          case "VerifyFactorAsyncResult":
            switch (data.verifyFactorAsync.status) {
              case VerifyFactorStatus.Success:
                displaySuccessToast("MFA verification successful.");
                setStatus(VerifyFactorStatus.Success);
                // Close the modal to prevent a blank screen, then execute the success action.
                props.onClose();
                props.onMfaSuccess();
                break;
              case VerifyFactorStatus.Rejected:
                setStatus(VerifyFactorStatus.Failed);
                setErrorMessage("You have chosen to reject this login.");
                break;
              case VerifyFactorStatus.Waiting:
                setStatus(VerifyFactorStatus.Waiting);
                break;
              default:
                setErrorMessage("MFA verification failed");
            }
            break;
          case "FactorNotFoundError":
            setErrorMessage("Error: factor not found.");
            break;
          case "ConnectionUserNotFound":
            setErrorMessage(
              "Okta account not found. If you believe this is in error, please contact an admin for support."
            );
            break;
          case "MissingTokenPermissionError":
            setErrorMessage(
              getInsufficientApiTokenPermissionError(
                authState.user?.isAdmin || false
              )
            );
            break;
        }
      } catch (error) {
        setErrorMessage("Failed to validate MFA.");
        logError(error, "Failed to validate MFA");
      }
    }, 4 * 1000);

    return () => clearInterval(refetcher);
  });

  return (
    <>
      <Modal title={"Verify MFA"} isOpen={true} onClose={props.onClose}>
        <Modal.Body>
          <Label
            label={"Okta Verify"}
            icon={{ type: "src", icon: oktaVerifyIcon }}
          />
          {status === VerifyFactorStatus.Waiting && (
            <div className={styles.loader}>
              <img
                src={opalLogoSpinner}
                alt="push sent"
                height={24}
                width={24}
              />
              <span className={styles.loadingText}>
                Push notification sent. Awaiting approval in your Okta Verify
                app.
              </span>
            </div>
          )}
          {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel={"Send Push"}
          primaryButtonDisabled={
            transactionId != null &&
            status !== VerifyFactorStatus.Success &&
            status !== VerifyFactorStatus.Failed
          }
          onPrimaryButtonClick={async () => {
            setErrorMessage(null);
            try {
              const { data } = await verifyFactorAsync({
                variables: {
                  input: {
                    factorRemoteId: props.selectedFactor.factorRemoteId,
                  },
                },
              });
              switch (data?.verifyFactorAsync.__typename) {
                case "VerifyFactorAsyncResult":
                  switch (data.verifyFactorAsync.status) {
                    case VerifyFactorStatus.Success:
                      displaySuccessToast("MFA verification successful.");
                      break;
                    case VerifyFactorStatus.Waiting:
                      setStatus(VerifyFactorStatus.Waiting);
                      setTransactionId(data.verifyFactorAsync.transactionId);
                      break;
                    default:
                      setErrorMessage("MFA verification failed.");
                  }
                  break;
                case "FactorNotFoundError":
                  setErrorMessage("Error: factor not found.");
                  break;
                case "ConnectionUserNotFound":
                  setErrorMessage(
                    "Okta account not found. If you believe this is in error, please contact an admin for support."
                  );
                  break;
                case "MissingTokenPermissionError":
                  setErrorMessage(
                    getInsufficientApiTokenPermissionError(
                      authState.user?.isAdmin || false
                    )
                  );
                  break;
              }
            } catch (error) {
              setErrorMessage("Failed to validate MFA");
              logError(error, "failed to verify sync factor");
            }
          }}
        />
      </Modal>
    </>
  );
};

type VerifySyncFactorModalProps = {
  selectedFactor: FactorFragment;
  onClose: () => void;
  onMfaSuccess: () => void;
};

const VerifySyncFactorModal = (props: VerifySyncFactorModalProps) => {
  const { authState } = useContext(AuthContext);

  const [passCode, setPassCode] = useState("");
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [status, setStatus] = useState<VerifyFactorStatus>();

  const [verifyFactorSync, { loading }] = useVerifyFactorSyncMutation();

  const { displaySuccessToast } = useToast();

  const { selectedFactor, onClose, onMfaSuccess } = props;

  const onClick = useCallback(async () => {
    try {
      const { data } = await verifyFactorSync({
        variables: {
          input: {
            factorRemoteId: selectedFactor.factorRemoteId,
            passCode: passCode,
          },
        },
      });
      switch (data?.verifyFactorSync.__typename) {
        case "VerifyFactorSyncResult":
          if (data.verifyFactorSync.status === VerifyFactorStatus.Failed) {
            setStatus(VerifyFactorStatus.Failed);
            setErrorMessage("Your code is invalid. Please try again.");
          } else {
            setStatus(VerifyFactorStatus.Success);
            displaySuccessToast("MFA verification successful.");
            // Close the modal to prevent a blank screen, then execute the success action.
            onClose();
            onMfaSuccess();
          }
          break;
        case "FactorNotFoundError":
          setErrorMessage("Error: factor not found.");
          break;
        case "ConnectionUserNotFound":
          setErrorMessage(
            "Okta account not found. If you believe this is in error, please contact an admin for support."
          );
          break;
        case "MissingTokenPermissionError":
          setErrorMessage(
            getInsufficientApiTokenPermissionError(
              authState.user?.isAdmin || false
            )
          );
          break;
      }
    } catch (error) {
      setErrorMessage("Failed to validate MFA.");
      logError(error, "Failed to validate MFA");
    }
  }, [
    passCode,
    selectedFactor,
    onMfaSuccess,
    onClose,
    authState.user?.isAdmin,
    displaySuccessToast,
    verifyFactorSync,
  ]);

  useEffect(() => {
    if (errorMessage) {
      return;
    }
    if (passCode.length === 6) {
      onClick();
    }
  }, [passCode, errorMessage, onClick]);

  return (
    <>
      <Modal title={"Verify MFA"} isOpen={true} onClose={props.onClose}>
        <Modal.Body>
          <div className={styles.modalBlock}>
            <p>
              Please verify your identity by entering the MFA code from your
              Okta Verify app.
            </p>
            <Label
              label={"Okta Verify"}
              icon={{ type: "src", icon: oktaVerifyIcon }}
            />
            <OtpInput
              value={passCode}
              onChange={(value: string) => {
                setPassCode(value);
                setErrorMessage(null);
              }}
              numInputs={6}
              shouldAutoFocus={true}
              inputStyle={styles.otpInput}
              containerStyle={styles.otpGroup}
              isInputNum={true}
            />
          </div>
          {status === VerifyFactorStatus.Success && (
            <div className={styles.modalBlock}>Verification successful.</div>
          )}
          {errorMessage && status !== VerifyFactorStatus.Success && (
            <ModalErrorMessage errorMessage={errorMessage} />
          )}
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel={"Verify"}
          primaryButtonDisabled={status === VerifyFactorStatus.Success}
          primaryButtonLoading={loading}
          onPrimaryButtonClick={onClick}
        />
      </Modal>
    </>
  );
};
