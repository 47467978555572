import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewType,
  Maybe,
  useSetNoteMutation,
} from "api/generated/graphql";
import AccessReviewNoteModal from "components/modals/AccessReviewNoteModal";
import { ContextMenu } from "components/ui";
import { useState } from "react";
import { logError } from "utils/logging";

interface Props {
  note?: string;
  accessReviewID: string;
  targetIDs: string[];
  targetType: AccessReviewType;
  onNoteChange: (note?: string) => void;
}

const NoteCell = ({
  note,
  accessReviewID,
  targetIDs,
  targetType,
  onNoteChange,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const [setNote, { loading }] = useSetNoteMutation();

  const emptyOpts: PropsFor<typeof ContextMenu>["options"] = [
    {
      label: "Add note",
      onClick: () => setShowModal(true),
      icon: {
        type: "name",
        icon: "edit-3",
      },
    },
  ];
  const populatedOpts: PropsFor<typeof ContextMenu>["options"] = [
    {
      label: "Edit note",
      onClick: () => setShowModal(true),
      icon: {
        type: "name",
        icon: "edit-3",
      },
    },
    {
      label: "Clear note",
      onClick: () => saveNote(""),
      icon: {
        type: "name",
        icon: "x",
      },
    },
  ];

  const options: PropsFor<typeof ContextMenu>["options"] = note
    ? populatedOpts
    : emptyOpts;

  const saveNote = async (newNote: Maybe<string>) => {
    setErrorMessage("");

    try {
      const { data } = await setNote({
        variables: {
          input: {
            accessReviewID: accessReviewID,
            note: newNote ?? "",
            targetIDs: targetIDs,
            targetType: targetType,
          },
        },
      });
      switch (data?.setNote.__typename) {
        case "SetNoteResult": {
          setShowModal(false);
          onNoteChange(newNote ?? "");
          break;
        }
        case "AccessReviewAlreadyStoppedError": {
          setErrorMessage(data?.setNote.message);
          break;
        }
        default: {
          setErrorMessage("Unable to save note");
          break;
        }
      }
    } catch (error) {
      logError(error, "Unable to save note");
      setErrorMessage(getModifiedErrorMessage("Unable to save note", error));
    }
  };

  return (
    <>
      <ContextMenu horizontalDots options={options} />
      {showModal && (
        <AccessReviewNoteModal
          initNote={note ?? ""}
          errMsg={errorMessage}
          isOpen={showModal}
          primaryButtonLoading={loading}
          onClose={() => {
            setErrorMessage("");
            setShowModal(false);
          }}
          onSubmit={saveNote}
        />
      )}
    </>
  );
};

export default NoteCell;
