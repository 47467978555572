import { AccessReviewGroupFragment, EntityType } from "api/generated/graphql";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import { EntityViewerTabType } from "components/entity_viewer/EntityViewerTabs";
import { OnlyMyReviewsFilter } from "components/only_mine_filter/OnlyMineFilter";
import React from "react";
import styles from "views/access_reviews/common/AccessReviewTableStyles.module.scss";
import AccessReviewGroupUsersTable from "views/access_reviews/groups/AccessReviewGroupUsersTable";

type AccessReviewGroupUsersRowProps = {
  accessReviewGroup: AccessReviewGroupFragment;
  setSelectedTab: (tab: EntityViewerTabType) => void;
  selectedRows?: Record<string, EntityType>;
  setSelectedRows?: (value: Record<string, EntityType>) => void;
};

export const AccessReviewGroupUsersRow = (
  props: AccessReviewGroupUsersRowProps
) => {
  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableTopContainer}>
        <div className={styles.tableTopLeftContent}>
          <OnlyMyReviewsFilter />
        </div>
      </div>
      <EntityViewerRow
        title={"Users"}
        content={
          <AccessReviewGroupUsersTable
            accessReviewGroup={props.accessReviewGroup}
            setSelectedTab={props.setSelectedTab}
            selectedRows={props.selectedRows}
            setSelectedRows={props.setSelectedRows}
          />
        }
        isTable={true}
      />
    </div>
  );
};

export default AccessReviewGroupUsersRow;
