import { Maybe } from "api/generated/graphql";
import { CustomDurationPicker } from "components/requests/CustomDurationPicker";
import { Button, FormGroup, Modal, Select } from "components/ui";
import React, { useState } from "react";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
  minutesToExpirationValue,
  minutesToExpirationValueNullable,
} from "views/requests/utils";
import { NULLABLE_DURATION_INDEFINITE } from "views/requests/utils";

type EditDurationModalProps = {
  title: string;
  isModalOpen: boolean;
  onClose: () => void;
  onSubmit: (durationInMinutes: number | undefined) => void;
  loading?: boolean;
  errorMessage: Maybe<string>;
  setErrorMessage: (newErrorMessage: Maybe<string>) => void;
  initValue?: number; // duration value in minutes, initValue will be null if duration is "indefinite"
  nullable?: boolean;
  includeIndefinite?: boolean;
};

type EditDurationFormProps = {
  title: string;
  onChange: Maybe<(durationInMinutes: number | undefined) => void>;
  initValue: Maybe<number> | undefined; // duration value in minutes, initValue will be null if duration is "indefinite"
  maxValue?: number;
  maxValueReason?: string;
  nullable?: boolean;
  includeIndefinite?: boolean;
};

export const isDurationValueCustom = (
  durationInMinutes: Maybe<number>
): boolean => {
  const defaultValues = Object.values(ExpirationValue).map((time) => {
    const defaultDuration = expirationValueToDurationInMinutes(time);
    return defaultDuration ? defaultDuration.asMinutes() : null;
  });
  defaultValues.push(NULLABLE_DURATION_INDEFINITE);

  return !defaultValues.includes(durationInMinutes);
};

// NOTE: the duration value returned if the user selects "indefinite" will be undefined
// NOTE: essentially, if initValue is undefined or null, treat that as "indefinite"
export const EditDurationModal = (props: EditDurationModalProps) => {
  // determine which version of the modal we show depending on the defaultValue
  const [duration, setDuration] = useState<number | undefined>(props.initValue);

  const modalReset = () => {
    props.onClose();
    props.setErrorMessage(null);
  };

  return (
    <Modal
      isOpen={props.isModalOpen}
      onClose={() => {
        modalReset();
      }}
      title={props.title}
    >
      <Modal.Body>
        <EditDurationForm
          title="Duration"
          onChange={setDuration}
          initValue={props.initValue}
          nullable={props.nullable}
          includeIndefinite={props.includeIndefinite ?? true}
        />
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Confirm"
        onPrimaryButtonClick={async () => {
          if (duration !== undefined && !props.nullable && duration <= 0) {
            props.setErrorMessage("Error: duration too short");
            return;
          }
          if (
            duration !== undefined &&
            props.nullable &&
            duration !== NULLABLE_DURATION_INDEFINITE &&
            duration <= 0
          ) {
            props.setErrorMessage("Error: duration too short");
            return;
          }

          props.setErrorMessage(null);
          props.onSubmit(duration);
        }}
        primaryButtonLoading={props.loading}
        primaryButtonDisabled={duration === props.initValue}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={() => {
          modalReset();
        }}
      />
    </Modal>
  );
};

export const EditDurationForm = (props: EditDurationFormProps) => {
  // determine which version of the modal we show depending on the defaultValue
  const initValueIsCustom = isDurationValueCustom(
    props.initValue === undefined ? null : props.initValue
  );
  const [usingCustomDurationPicker, setUsingCustomDurationPicker] = useState(
    initValueIsCustom
  );

  // Request hooks
  const [customDuration, setCustomDuration] = useState(
    props.initValue !== null &&
      props.initValue !== undefined &&
      props.initValue !== NULLABLE_DURATION_INDEFINITE
      ? props.initValue
      : 60
  );

  const updateCustomDuration = (mins: number) => {
    if (props.onChange) {
      props.onChange(mins);
    }
    setCustomDuration(mins);
  };

  const [duration, setDuration] = useState(
    props.nullable
      ? minutesToExpirationValueNullable(props.initValue ?? undefined)
      : minutesToExpirationValue(props.initValue ?? null)
  );

  const handleSelectDurationChange = (durationVal?: ExpirationValue) => {
    if (props.onChange) {
      if (props.nullable) {
        if (durationVal == null) {
          props.onChange(undefined);
        } else if (durationVal === ExpirationValue.Indefinite) {
          props.onChange(NULLABLE_DURATION_INDEFINITE);
        } else {
          props.onChange(
            expirationValueToDurationInMinutes(durationVal)?.asMinutes()
          );
        }
      } else {
        const x = expirationValueToDurationInMinutes(
          durationVal ?? ExpirationValue.Indefinite
        );
        props.onChange(x ? x.asMinutes() : undefined);
      }
    }
    setDuration(durationVal ?? null);
  };

  const optionDisabled = (option: ExpirationValue) => {
    if (props.maxValue) {
      if (option === ExpirationValue.Indefinite) {
        return true;
      }
      const duration = expirationValueToDurationInMinutes(option);
      return Boolean(duration && duration.asMinutes() > props.maxValue);
    }
    return false;
  };

  return (
    <FormGroup
      label={props.title}
      rightLabelContent={
        <Button
          label={usingCustomDurationPicker ? "Default" : "Custom"}
          size="sm"
          borderless
          type="primary"
          onClick={() => {
            if (props.onChange) {
              let newDuration = undefined;
              if (!usingCustomDurationPicker) {
                newDuration = customDuration;
              } else if (props.nullable) {
                if (duration === ExpirationValue.Indefinite) {
                  newDuration = NULLABLE_DURATION_INDEFINITE;
                } else if (duration != null) {
                  newDuration = expirationValueToDurationInMinutes(
                    duration
                  )?.asMinutes();
                }
              } else {
                newDuration = expirationValueToDurationInMinutes(
                  duration ?? ExpirationValue.Indefinite
                )?.asMinutes();
              }
              props.onChange(newDuration);
            }
            setUsingCustomDurationPicker(!usingCustomDurationPicker);
          }}
        />
      }
    >
      {!usingCustomDurationPicker && (
        <Select
          options={Object.values(ExpirationValue).filter(
            (opt) =>
              props.includeIndefinite || opt !== ExpirationValue.Indefinite
          )}
          value={duration ?? undefined}
          onChange={handleSelectDurationChange}
          getOptionLabel={(expirationVal) => {
            let label =
              expirationVal === ExpirationValue.Indefinite
                ? "Indefinite access"
                : `Access for ${expirationVal}`;
            if (optionDisabled(expirationVal)) {
              label += ` (${
                props.maxValueReason ?? "Exceeds maximum allowed"
              })`;
            }
            return label;
          }}
          getOptionDisabled={optionDisabled}
          clearable={props.nullable}
        />
      )}
      {usingCustomDurationPicker && (
        <CustomDurationPicker
          durationInMinutes={customDuration}
          setDurationInMinutes={updateCustomDuration}
          maxValue={props.maxValue}
          maxValueReason={props.maxValueReason}
        />
      )}
    </FormGroup>
  );
};

export default EditDurationModal;
