import useResizeObserver from "@react-hook/resize-observer";
import { vars } from "css/vars.css";
import React, { useState } from "react";
import ReactDOM from "react-dom";
import { usePopper } from "react-popper";

import * as styles from "./Popover.css";

interface Props {
  content: React.ReactNode;
  inline?: boolean;
}

const Popover: React.FC<Props> = (props) => {
  const [showPopover, setShowPopover] = useState(false);

  // As recommended by react-popper, useState instead of useRef
  // https://popper.js.org/react-popper/v2/#example
  const [
    referenceElement,
    setReferenceElement,
  ] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const { styles: popperStyles, attributes, update } = usePopper(
    referenceElement,
    popperElement
  );

  // Update popper when content changes size
  useResizeObserver(popperElement, async () => {
    if (!update) return;
    update();
  });

  const body = document.querySelector("body");
  if (!body) return null;

  return (
    <div
      onMouseEnter={() => {
        setShowPopover(true);
      }}
      onMouseLeave={() => setShowPopover(false)}
      className={styles.container({ inline: props.inline })}
    >
      <div ref={setReferenceElement}>{props.children}</div>

      {showPopover
        ? ReactDOM.createPortal(
            <div
              ref={setPopperElement}
              style={{ ...popperStyles.popper, zIndex: vars.zIndex.modal }}
              {...attributes.popper}
            >
              <div className={styles.popoverContainer}>{props.content}</div>
            </div>,
            body
          )
        : null}
    </div>
  );
};

export default Popover;
