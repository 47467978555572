import { Button } from "components/ui";
import React, { useEffect } from "react";
import { useHistory } from "react-router";
import { Footer, GetEnterHandler, Header } from "views/onboarding/Common";
import { OrgCreateInfo } from "views/onboarding/Onboarding";
import onboardingStyles from "views/onboarding/Onboarding.module.scss";
import welcomePageStyles from "views/onboarding/steps/WelcomePage.module.scss";

type SignInPromptProps = {
  orgCreateInfo: OrgCreateInfo;
  setOrgCreateInfo: (orgCreateInfo: OrgCreateInfo) => void;
  stepNumber: number;
  setStepNumber: (step: number) => void;
  totalSteps: number;
  progress: number;
};

export const SignInPrompt = (props: SignInPromptProps) => {
  const history = useHistory();

  const onSubmit = () => {
    history.push("/apps");
  };

  const handleKeyDown = GetEnterHandler({ callback: onSubmit });

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <div className={onboardingStyles.content}>
      <Header progress={props.progress} />
      <div className={onboardingStyles.titleHeader}>
        <div className={onboardingStyles.title}>Sign in with admin email</div>
      </div>
      <div className={onboardingStyles.subtitle}>
        On the next screen, you should sign in with the admin email address you
        specified in the previous step.
      </div>
      <div className={welcomePageStyles.continueButton}>
        <div className={welcomePageStyles.buttonDescription}>
          Press enter or click
        </div>
        <Button
          leftIconName="arrow-right"
          label="Continue"
          onClick={onSubmit}
        />
      </div>
      <Footer
        step={props.stepNumber}
        totalSteps={props.totalSteps}
        backHandler={() => props.setStepNumber(props.stepNumber - 1)}
      />
    </div>
  );
};

export default SignInPrompt;
