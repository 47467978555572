import styles from "components/integrations/AppTile.module.scss";
import React from "react";

export type AppTileProps = {
  logo: string;
};

export const AppTile = (props: AppTileProps) => {
  return (
    <div className={styles.logoTile}>
      <img src={props.logo} alt={"app-logo"} />
    </div>
  );
};
