import { EntityType, GroupType } from "api/generated/graphql";
import { ResourceLabel } from "components/label/Label";
import React, { ReactElement } from "react";

interface GroupResourceLabelProps {
  groupName?: string;
  groupType?: GroupType;
  warningIcon?: ReactElement;
  warningTooltipText?: string;
  maxChars?: number;
  isOnCall?: boolean;
  isManaged?: boolean;
}

export const GroupTableCellResourceLabel = (props: GroupResourceLabelProps) => {
  return (
    <ResourceLabel
      text={props.groupName}
      groupType={props.groupType}
      entityTypeNew={props.isOnCall ? EntityType.OnCallGroup : EntityType.Group}
      bold={true}
      iconLarge={true}
      pointerCursor={true}
      warningIcon={props.warningIcon}
      warningTooltipText={props.warningTooltipText}
      maxChars="auto"
      inactive={props.isManaged !== undefined && !props.isManaged}
      tooltipText={
        props.isManaged !== undefined && !props.isManaged
          ? "This group is currently not managed in Opal"
          : null
      }
    />
  );
};
