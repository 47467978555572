import {
  GroupBindingDetailFragment,
  useDeleteGroupBindingsMutation,
  useGroupBindingDetailsQuery,
} from "api/generated/graphql";
import { useToast } from "components/toast/Toast";
import { Modal, Skeleton } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useMemo } from "react";
import useLogEvent from "utils/analytics";
import { logError } from "utils/logging";
import GroupBindingGroupsRow from "views/group_bindings/rows/GroupBindingGroupsRow";

type Props = {
  isOpen: boolean;
  onModalClose: () => void;
  groupBindingIds: string[];
};

const GroupBindingDeleteModal = (props: Props) => {
  const logEvent = useLogEvent();
  const { data, loading, error } = useGroupBindingDetailsQuery({
    variables: {
      input: props.groupBindingIds,
    },
    skip: props.groupBindingIds.length === 0,
  });

  const [
    deleteGroupBindings,
    { loading: deleteLoading },
  ] = useDeleteGroupBindingsMutation({
    refetchQueries: ["GroupBindingsTable", "GroupBindingsCounts"],
  });
  const { displaySuccessToast, displayErrorToast } = useToast();

  const onDelete = async () => {
    const result = await deleteGroupBindings({
      variables: {
        input: {
          ids: props.groupBindingIds,
        },
      },
    });

    if (
      result.data?.deleteGroupBindings?.__typename ===
      "DeleteGroupBindingsResult"
    ) {
      props.onModalClose();
      displaySuccessToast("Successfully deleted group binding");
      logEvent({ name: "group_bindings_delete" });
    } else {
      displayErrorToast("Failed to delete group binding");
    }
  };

  if (error) {
    logError(error, "failed to group bindings");
    displayErrorToast("Failed to linked groups, please try again.");
    return null;
  }

  const loadingContent = (
    <>
      <Skeleton width="500px" />
      <Skeleton width="500px" />
      <Skeleton width="500px" />
      <Skeleton width="200px" />
    </>
  );

  const groupBindings = data?.groupBindings?.groupBindings ?? [];

  var modalTitle = "Unlink group";
  var modalSubtitle = "Are you sure you want to unlink this group?";
  if (groupBindings.length > 1) {
    modalTitle = `Unlinking ${groupBindings.length} linked groups`;
    modalSubtitle = `Are you sure you want to unlink these groups?`;
  }

  return (
    <Modal
      title={modalTitle}
      subtitle={modalSubtitle}
      isOpen={props.isOpen}
      onClose={props.onModalClose}
    >
      <Modal.Body>
        {loading ? (
          loadingContent
        ) : (
          <>
            {groupBindings.map((binding) => (
              <GroupBindingUnlinkRow groupBinding={binding} />
            ))}
          </>
        )}
      </Modal.Body>
      <Modal.Footer
        onPrimaryButtonClick={onDelete}
        primaryButtonLabel="Unlink"
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={props.onModalClose}
        primaryButtonDisabled={deleteLoading}
      />
    </Modal>
  );
};

const GroupBindingUnlinkRow = (props: {
  groupBinding: GroupBindingDetailFragment;
}) => {
  const { sourceGroup, groups } = props.groupBinding;
  const uniqueUsers: unknown[] = useMemo(() => {
    const groupUserIds = groups.map(
      (group) => group?.groupUsers.map((user) => user.userId) ?? []
    );
    return _.difference(
      _.union(...groupUserIds),
      _.intersection(...groupUserIds)
    );
  }, [groups]);

  var usersInCommonText = "";
  if (groups.length > 1) {
    if (uniqueUsers.length === 0) {
      usersInCommonText = "All members in common";
    } else {
      usersInCommonText = `${uniqueUsers.length} unique members`;
    }
  }

  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        gap: "sm",
        marginBottom: "md",
      })}
    >
      <>{usersInCommonText}</>
      <GroupBindingGroupsRow
        groups={groups}
        sourceGroup={sourceGroup ?? undefined}
      />
    </div>
  );
};

export default GroupBindingDeleteModal;
