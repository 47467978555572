import {
  useGroupAccessLevelsQuery,
  useResourceAccessLevelsQuery,
} from "api/generated/graphql";
import { Select } from "components/ui";
import { logError } from "utils/logging";

interface RoleInfo {
  accessLevelRemoteId: string;
  accessLevelName: string;
}

type RoleDropdownProps = {
  resourceId?: string;
  groupId?: string;
  onChange: (newRole?: RoleInfo) => void;
  disabled?: boolean;
  clearable?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  selectOnly?: boolean;
};

export const RoleDropdown = (props: RoleDropdownProps) => {
  const { clearable = true } = props;

  const {
    data: resourceRolesData,
    error: resourceRolesError,
    loading: resourceRolesLoading,
  } = useResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceId: props.resourceId ?? "",
      },
    },
    skip: !props.resourceId,
  });

  const {
    data: groupRolesData,
    error: groupRolesError,
    loading: groupRolesLoading,
  } = useGroupAccessLevelsQuery({
    variables: {
      input: {
        groupId: props.groupId ?? "",
      },
    },
    skip: !props.groupId,
  });

  let roles: {
    accessLevelRemoteId: string;
    accessLevelName: string;
  }[] = [];

  if (resourceRolesData) {
    switch (resourceRolesData.accessLevels.__typename) {
      case "ResourceAccessLevelsResult": {
        roles = resourceRolesData.accessLevels.accessLevels ?? [];
        break;
      }
      case "ResourceNotFoundError":
        logError(resourceRolesError, `failed to list resource roles`);
        break;
    }
  } else if (resourceRolesError) {
    logError(resourceRolesError, `failed to list resource roles`);
  }

  if (groupRolesData) {
    switch (groupRolesData.groupAccessLevels.__typename) {
      case "GroupAccessLevelsResult": {
        roles = groupRolesData.groupAccessLevels.accessLevels ?? [];
        break;
      }
      case "GroupNotFoundError":
        logError(groupRolesError, `failed to list group roles`);
        break;
    }
  } else if (groupRolesError) {
    logError(groupRolesError, `failed to list group roles`);
  }

  return (
    <Select<RoleInfo>
      options={roles.filter((role) => role.accessLevelRemoteId !== "")}
      value={undefined}
      onChange={props.onChange}
      clearable={clearable}
      disabled={props.disabled}
      getOptionLabel={(option) => option.accessLevelName}
      autoFocus={props.autoFocus}
      placeholder={props.placeholder || "Select a role"}
      selectOnly={props.selectOnly}
      loading={resourceRolesLoading || groupRolesLoading}
    />
  );
};
