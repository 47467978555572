import {
  CurrentUserGroupAccessFragment,
  EntityType,
  GroupType,
  Maybe,
  UserPreviewSmallFragment,
} from "api/generated/graphql";
import AccessPathLabel from "components/label/AccessPathLabel";
import {
  ResourceLabel,
  TimeLabel,
  timeValueIndefinite,
} from "components/label/Label";
import AccessPointModal from "components/modals/AccessPointModal";
import MaterialTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MaterialTable";
import { Button, ButtonV3 } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import React, { useState } from "react";
import useLogEvent from "utils/analytics";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

interface GroupAccessPointRow {
  accessPoint: string;
  expirationDate: string;
  accessPath: string;
}

type GroupExpirationLabelProps = {
  user: Maybe<UserPreviewSmallFragment> | undefined;
  group?: {
    id: string;
    groupType: GroupType;
    name: string;
    currentUserAccess: CurrentUserGroupAccessFragment;
  };
  entityType: EntityType;
  expiration: Maybe<moment.Moment>;
  iconOnly?: boolean;
  hasV3?: boolean;
  buttonSize?: "sm" | "md" | "lg";
};

const expirationColMinWidth = "60px";
const accessPointColMinWidth = "60px";
const accessPathColMinWidth = "400px";
const accessPathColMaxWidth = "800px";

export const GroupExpirationLabel = (props: GroupExpirationLabelProps) => {
  const [showModal, setShowModal] = useState(false);
  const hasEUX = useFeatureFlag(FeatureFlag.EndUserExperience);
  const logEvent = useLogEvent();

  const headers: Header<GroupAccessPointRow>[] = [
    { id: "accessPoint", label: "Access Point" },
    { id: "expirationDate", label: "Expires" },
    { id: "accessPath", label: "Access Path" },
  ];

  const accessPointRows: CellRow<GroupAccessPointRow>[] = [];

  const directAccessPoint =
    props.group?.currentUserAccess.groupUser?.access?.directAccessPoint;
  if (directAccessPoint) {
    const expirationTime = directAccessPoint.expiration
      ? moment(new Date(directAccessPoint.expiration))
      : null;

    accessPointRows.push({
      id: directAccessPoint.groupId,
      data: {
        accessPoint: {
          value: directAccessPoint.groupId,
          element: (
            <ResourceLabel
              entityTypeNew={EntityType.Group}
              text={`${directAccessPoint.groupName}`}
              bold={true}
            />
          ),
          style: { minWidth: accessPointColMinWidth },
        },
        expirationDate: {
          value:
            expirationTime?.unix() || `${directAccessPoint.groupId}-expiration`,
          sortValue: expirationTime?.unix() || timeValueIndefinite,
          element: (
            <TimeLabel
              time={expirationTime}
              supportTicket={directAccessPoint.supportTicket}
              useExpiringLabel
            />
          ),
          style: { minWidth: expirationColMinWidth },
        },
        accessPath: {
          value: `${directAccessPoint.groupId}-access-path`,
          element: (
            <AccessPathLabel
              accessLabels={[
                <ResourceLabel
                  entityTypeNew={EntityType.User}
                  entityId={props.user?.id}
                  text={" "}
                  avatar={props.user?.avatarUrl}
                  maxChars={16}
                />,
                <ResourceLabel
                  entityTypeNew={EntityType.Group}
                  entityId={directAccessPoint.groupId}
                  text={directAccessPoint.groupName}
                  maxChars={16}
                />,
              ]}
              maxWidth={accessPathColMaxWidth}
            />
          ),
          style: { minWidth: accessPathColMinWidth },
        },
      },
    });
  }

  props.group?.currentUserAccess.groupUser?.access?.indirectAccessPointPaths.forEach(
    (indirectAccessPointPath) => {
      const resolvedAccessPoint = indirectAccessPointPath.resolvedAccessPoint;

      if (resolvedAccessPoint) {
        const expirationTime = resolvedAccessPoint?.expiration
          ? moment(new Date(resolvedAccessPoint.expiration))
          : null;

        let accessPathLabels = [
          <ResourceLabel
            entityTypeNew={EntityType.User}
            entityId={props.user?.id}
            text={" "}
            avatar={props.user?.avatarUrl}
            maxChars={16}
          />,
        ];

        accessPathLabels = [
          ...accessPathLabels,
          ...indirectAccessPointPath.indirectAccessPointPath.map(
            (indirectAccessPoint) => {
              return (
                <ResourceLabel
                  entityTypeNew={EntityType.Group}
                  entityId={indirectAccessPoint.groupId}
                  text={indirectAccessPoint.groupName}
                  maxChars={16}
                />
              );
            }
          ),
        ];

        accessPathLabels.push(
          <ResourceLabel
            entityTypeNew={EntityType.Group}
            entityId={props.group?.id}
            text={" "}
            maxChars={16}
          />
        );

        accessPointRows.push({
          id: resolvedAccessPoint.groupId,
          data: {
            accessPoint: {
              value: resolvedAccessPoint.groupId,
              element: (
                <ResourceLabel
                  entityTypeNew={EntityType.Group}
                  entityId={resolvedAccessPoint.groupId}
                  text={resolvedAccessPoint.groupName}
                />
              ),
              style: { minWidth: accessPointColMinWidth },
            },
            expirationDate: {
              value: expirationTime?.unix() || `${props.group?.id}-expiration`,
              sortValue: expirationTime?.unix() || timeValueIndefinite,
              element: (
                <TimeLabel
                  time={expirationTime}
                  supportTicket={resolvedAccessPoint.supportTicket}
                />
              ),
              style: { minWidth: expirationColMinWidth },
            },
            accessPath: {
              value: `${resolvedAccessPoint.groupId}-access-path`,
              element: (
                <AccessPathLabel
                  accessLabels={accessPathLabels}
                  maxWidth={accessPathColMaxWidth}
                />
              ),
              style: { minWidth: accessPathColMinWidth },
            },
          },
        });
      }
    }
  );

  const numAccessPoints = accessPointRows.length;
  if (numAccessPoints === 0) {
    return null;
  }

  return (
    <div>
      {props.hasV3 ? (
        <ButtonV3
          label="Access"
          leftIconName="check-circle"
          type={hasEUX ? "success" : "defaultSecondary"}
          onClick={() => {
            setShowModal(!showModal);
            if (props.group) {
              logEvent({
                name: "apps_access_details_click",
                properties: {
                  itemType: props.group?.groupType,
                },
              });
            }
          }}
          size={props.buttonSize}
        />
      ) : (
        <Button
          label="Access Details"
          leftIconName="check-circle"
          onClick={() => {
            setShowModal(!showModal);
            if (props.group) {
              logEvent({
                name: "apps_access_details_click",
                properties: {
                  itemType: props.group?.groupType,
                },
              });
            }
          }}
          borderless
          size="md"
        />
      )}
      {showModal && (
        <AccessPointModal
          subject={props.user}
          object={props.group}
          accessPathContent={
            <div className={sprinkles({ padding: "sm" })}>
              <MaterialTable<GroupAccessPointRow>
                headers={headers}
                rows={accessPointRows}
                denseFormatting={true}
                tableContainerStyles={{ maxHeight: 300 }}
              />
            </div>
          }
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
};
