import {
  SupportTicketPreviewFragment,
  ThirdPartyProvider,
} from "api/generated/graphql";
import TicketProviderDropdown from "components/tickets/TicketProviderDropdown";
import { FormGroup, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext, useState } from "react";
import { getSupportTicketThirdPartyProviders } from "utils/common";
import OrgContext from "views/settings/OrgContext";
import { SupportTicketsField } from "views/support_tickets/SupportTickets";

const AccessRequestTicketPicker = ({
  supportTicket,
  onChange,
  required,
}: {
  supportTicket?: SupportTicketPreviewFragment;
  onChange: (ticket?: SupportTicketPreviewFragment) => void;
  required?: boolean;
  disabled?: boolean;
}) => {
  const { orgState } = useContext(OrgContext);
  const supportTicketThirdPartyProviders = getSupportTicketThirdPartyProviders(
    orgState.orgThirdPartyIntegrations
  );
  const supportTicketBindingAllowed =
    supportTicketThirdPartyProviders &&
    supportTicketThirdPartyProviders.length !== 0;
  const [showSupportTickets, setShowSupportTickets] = useState(required);
  const [
    currentTicket,
    setCurrentTicket,
  ] = useState<SupportTicketPreviewFragment>();
  // This internal state helps us to restore the selected ticket in case the user toggle the switch by mistake
  const [ticketProvider, setTicketProvider] = useState<ThirdPartyProvider>();

  const selectedProvider =
    supportTicketThirdPartyProviders?.length === 1
      ? supportTicketThirdPartyProviders[0]
      : ticketProvider;
  const showTicketProviderDropdowns =
    supportTicketBindingAllowed && (showSupportTickets || required);

  var infoTooltip = "";
  if (!supportTicketBindingAllowed) {
    infoTooltip = "No ticket providers are configured for this organization.";
  } else if (required) {
    infoTooltip =
      "An access ticket is required for this app. Access will be automatically revoked when the ticket is closed.";
  } else {
    infoTooltip =
      "Enable this option to expire access when a linked ticket is closed";
  }
  return (
    <>
      <div className={sprinkles({ marginBottom: "md" })}>
        <Switch
          checked={showSupportTickets || required || false}
          onChange={(checked) => {
            // setGetTicketsErrorMessage("");
            setShowSupportTickets(checked);
            if (checked) {
              onChange(currentTicket);
            } else {
              onChange(undefined);
            }
          }}
          disabled={!supportTicketBindingAllowed || required}
          label="Expire access when ticket is closed"
          infoTooltip={infoTooltip}
        />
      </div>
      {showTicketProviderDropdowns &&
        supportTicketThirdPartyProviders.length > 1 && (
          <FormGroup label="Ticket Providers" required={required}>
            <TicketProviderDropdown
              selectedTicketProvider={selectedProvider}
              onSelectTicketProvider={(ticketProvider) => {
                setTicketProvider(ticketProvider);
                onChange(undefined);
              }}
              // ServiceNow is not supported yet as a ticketing system. We
              // currently only use it to mirror requests as an audit log,
              // so hide it from the dropdown here.
              hiddenProviders={[ThirdPartyProvider.ServiceNow]}
            />
          </FormGroup>
        )}
      {showTicketProviderDropdowns && selectedProvider && (
        <SupportTicketsField
          thirdPartyProvider={selectedProvider}
          // TODO
          setGetTicketsErrorMessage={() => {}}
          supportTicket={supportTicket}
          setSupportTicket={(supportTicket) => {
            setCurrentTicket(supportTicket);
            onChange(supportTicket);
          }}
          required={required}
        />
      )}
    </>
  );
};

export default AccessRequestTicketPicker;
