export const filterSearchResults = <T>(
  items: T[],
  searchQuery: string,
  // Given an item from `items`, produce terms to match on.
  // Undefined can be passed in for convenience, but it will be skipped.
  getFilterTerms: (item: T) => (string | undefined)[]
): T[] => {
  if (!searchQuery) {
    return items;
  }
  return items.filter((item) => {
    const filterTerms = getFilterTerms(item);
    return filterTerms.some((term) => {
      if (!term) {
        return false;
      }
      return term.toLowerCase().includes(searchQuery.toLowerCase());
    });
  });
};
