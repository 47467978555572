import { Maybe, ResourcePreviewSmallFragment } from "api/generated/graphql";
import Label from "components/label/Label";
import { SectionEntry } from "components/viewer/Viewer";
import React from "react";

type ResourceRemoteNameRowProps = {
  resource?: Maybe<ResourcePreviewSmallFragment>;
};

export const ResourceRemoteNameRow = (props: ResourceRemoteNameRowProps) => {
  return (
    <SectionEntry
      title={"Remote name"}
      tooltipText={`This is the name of the resource as it appears on the end system. This value is not editable from Opal.`}
    >
      <Label text={props.resource?.remoteName} />
    </SectionEntry>
  );
};

export default ResourceRemoteNameRow;
