import OpalSearchInput from "components/opal/common/input/OpalSearchInput";
import TableFilters from "components/ui/table/TableFilters";
import { useURLSearchParam } from "utils/router/hooks";

import { SEARCH_QUERY_URL_KEY } from "./AppsContext";

const AppUsersFilters = () => {
  const [searchQuery, setSearchQuery] = useURLSearchParam(SEARCH_QUERY_URL_KEY);
  return (
    <TableFilters>
      <TableFilters.Left>
        <OpalSearchInput
          placeholder="Search by name or email"
          defaultValue={searchQuery ?? undefined}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
      </TableFilters.Left>
    </TableFilters>
  );
};

export default AppUsersFilters;
