import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AuthType,
  ConnectionType,
  Maybe,
  useCreateConnectionMutation,
  Visibility,
} from "api/generated/graphql";
import teleportLogo from "assets/logos/teleport-logo.png";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { Button, Checkbox, FormGroup, Input } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import {
  CreateConnectionComponents as CreateConnectionComponentsV2,
  CreateConnectionView as CreateConnectionViewV2,
} from "views/connections/create/CreateConnectionComponents";
import {
  CreateConnectionComponentsV3,
  CreateConnectionViewV3,
} from "views/connections/create/CreateConnectionComponentsV3";
import { UploadCertButton } from "views/connections/create/UploadCertButton";
import VisibilitySelector from "views/visibility/VisibilitySelector";

const CreateTeleport = () => {
  return <CreateTeleportForm />;
};

const CreateTeleportForm = () => {
  const history = useHistory();
  const hasAppLevelVisibility = useFeatureFlag(FeatureFlag.AppLevelVisibility);
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const logEvent = useLogEvent();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [name, setName] = useState("Teleport");
  const [description, setDescription] = useState("");
  const [visibility, setVisibility] = useState<Visibility>(Visibility.Global);
  const [visibilityGroupIds, setVisibilityGroupIds] = useState<
    string[] | undefined
  >([]);
  const [credentialsFile, setCredentialsFile] = useState("");
  const [tlsMode, setTlsMode] = useState(true);
  const [tlsCaCertContent, setTlsCaCertContent] = useState<Maybe<string>>(null);

  const [adminOwnerId, setAdminOwnerId] = useState<string | undefined>(
    undefined
  );

  const [host, setHost] = useState("");

  const [createConnectionMutation, { loading }] = useCreateConnectionMutation({
    refetchQueries: ["AppsListColumn", "Connections"],
  });

  const fieldUnset =
    name === "" ||
    description === "" ||
    adminOwnerId === undefined ||
    credentialsFile === "" ||
    host === "";

  const onSubmit = async () => {
    try {
      const { data } = await createConnectionMutation({
        variables: {
          input: {
            name: name,
            description: description,
            connectionType: ConnectionType.Teleport,
            adminOwnerId: adminOwnerId ?? "",
            visibility: visibility,
            visibilityGroupIds: visibilityGroupIds ?? [],
            importVisibility: Visibility.Global,
            metadata: {
              connectionType: ConnectionType.Teleport,
              teleport: {
                host,
                tlsMode,
                tlsCaCertContent,
              },
            },
            credentials: {
              authType: AuthType.Teleport,
              teleport: {
                credentialsFile,
              },
            },
          },
        },
      });
      switch (data?.createConnection.__typename) {
        case "CreateConnectionResult":
          displaySuccessToast(`Success: Teleport app created`);
          history.replace(
            getResourceUrl(
              EntityTypeDeprecated.Connection,
              data.createConnection.connection.id
            )
          );
          logEvent({
            name: "apps_create_click",
            properties: {
              connectionType: ConnectionType.Teleport,
            },
          });

          break;
        case "ConnectionExistsError":
        case "ConnectionBadMetadataError":
        case "UserFacingError":
          logWarning(new Error(data.createConnection.message));
          setErrorMessage(data.createConnection.message);
          break;
        default:
          logError(new Error(`failed to create Teleport connection`));
          setErrorMessage(`Error: failed to create Teleport connection`);
      }
    } catch (error) {
      logError(error, "failed to create Teleport connection");
      setErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to create Teleport connection",
          error
        )
      );
    }
  };

  const CreateConnectionComponents = hasV3
    ? CreateConnectionComponentsV3
    : CreateConnectionComponentsV2;
  const CreateConnectionView = hasV3
    ? CreateConnectionViewV3
    : CreateConnectionViewV2;

  return (
    <CreateConnectionView
      logo={teleportLogo}
      title={"Add your Teleport organization"}
      onSubmit={onSubmit}
      submitDisabled={fieldUnset}
      submitLoading={loading}
    >
      <>
        <CreateConnectionComponents
          title={"Step 1"}
          subtitle={"Create a service account for Opal"}
        >
          <>
            <p>
              Opal requires a service account to manage your Teleport on your
              behalf.
            </p>
            <p>
              To learn more about setting up a service account and generating
              credentials,{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={"https://docs.opal.dev/docs/teleport"}
              >
                check out our official documentation here
              </a>
              .
            </p>
          </>
        </CreateConnectionComponents>
        <CreateConnectionComponents
          title={"Step 2"}
          subtitle={"Add your service account credentials"}
          isLast
        >
          <>
            <p>
              {`Once the service account is created, please input the user's
              credentials and some information about the connection:`}
            </p>
            <FormGroup label="App name:">
              <Input
                onChange={setName}
                placeholder="Identifiable name for the Teleport connection."
                value={name}
              />
            </FormGroup>
            <FormGroup label="App admin:">
              <OwnerDropdown
                selectedOwnerId={adminOwnerId}
                onSelectOwner={(owner) => setAdminOwnerId(owner?.id)}
                placeholder="Select an owner to own this app."
              />
            </FormGroup>
            <FormGroup label="Description:">
              <Input
                onChange={setDescription}
                placeholder="A brief description of the account to further inform people requesting access to it."
                value={description}
              />
            </FormGroup>
            {hasAppLevelVisibility && (
              <FormGroup label="Visibility:">
                <VisibilitySelector
                  visibility={visibility}
                  onChangeVisibility={(vis) => {
                    if (vis == Visibility.Global) {
                      setVisibility(Visibility.Global);
                      setVisibilityGroupIds([]);
                    } else {
                      setVisibility(Visibility.Team);
                    }
                  }}
                  visibilityGroups={visibilityGroupIds ?? []}
                  onChangeVisibilityGroups={setVisibilityGroupIds}
                />
              </FormGroup>
            )}
            <FormGroup label="Teleport address">
              <Input
                onChange={setHost}
                value={host}
                placeholder="organization.teleport.sh"
              />
            </FormGroup>
            <FormGroup label="Opal service account credentials file:">
              <Input
                type="textarea"
                onChange={setCredentialsFile}
                value={credentialsFile}
                placeholder="-----BEGIN RSA PRIVATE KEY-----"
              />
            </FormGroup>
            <FormGroup
              label="TLS Mode"
              infoTooltip={`If enabled, Opal will use TLS to connect to your
                          Teleport instance. Optionally, if you use self-signed
                          certificates, you may manually upload your
                          CA certificate. When disabled, Opal will use the protocol specified
                          in the hostname (http or https), without verifying
                          the certificate.`}
            >
              <div className={sprinkles({ display: "flex", gap: "md" })}>
                <Checkbox
                  size="sm"
                  checked={tlsMode}
                  onChange={() => setTlsMode(!tlsMode)}
                  label="Enabled"
                />
                {tlsMode && (
                  <UploadCertButton
                    buttonTitle={"Upload TLS CA cert"}
                    uploadLabelId={"upload-tls-cert-ca-input"}
                    setCertContent={setTlsCaCertContent}
                    setErrorMessage={setErrorMessage}
                  />
                )}
              </div>
            </FormGroup>
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
            {!hasV3 && (
              <div
                className={sprinkles({
                  display: "flex",
                  justifyContent: "flex-end",
                })}
              >
                <Button
                  type="primary"
                  disabled={fieldUnset}
                  label={"Create"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </div>
            )}
          </>
        </CreateConnectionComponents>
      </>
    </CreateConnectionView>
  );
};

export default CreateTeleport;
