import { TimeZone } from "@vvo/tzdb";
import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewFragment,
  AccessReviewPreviewFragment,
  AccessReviewStatsFragment,
  FiltersMatchMode,
  Maybe,
  OngoingAccessReviewTabStatsFragment,
  useAccessReviewStatsQuery,
  useStopAccessReviewMutation,
  useUpdateAccessReviewMutation,
} from "api/generated/graphql";
import AssignmentPolicy from "components/access_reviews/AssignmentPolicy";
import ReminderNotifications from "components/access_reviews/ReminderNotifications";
import ConnectionsFilter from "components/access_reviews/scope/ConnectionsFilter";
import EntitiesIndividualFilter from "components/access_reviews/scope/EntitiesIndividualFilter";
import EntitiesNamedFilter from "components/access_reviews/scope/EntitiesNamedFilter";
import EntitiesWithAdminFilter from "components/access_reviews/scope/EntitiesWithAdminFilter";
import EntityTypesFilter from "components/access_reviews/scope/EntityTypesFilter";
import TagFilter from "components/access_reviews/scope/TagFilter";
import UsersFilter from "components/access_reviews/scope/UsersFilter";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import ConfirmModal from "components/modals/update/ConfirmModal";
import { SendReminderModal } from "components/modals/update/SendReminderModal";
import { useToast } from "components/toast/Toast";
import {
  Banner,
  Button,
  Card,
  Divider,
  FormGroup,
  Icon,
  Switch,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useContext, useRef, useState } from "react";
import * as Icons from "react-feather";
import { useHistory } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { timeZones } from "utils/time";
import { getDefaultTimezone } from "utils/time";
import AccessReviewContext, {
  AccessReviewContextActionType,
} from "views/access_reviews/AccessReviewContext";
import AccessReviewIncompleteReviewersModal from "views/access_reviews/AccessReviewIncompleteReviewersModal";
import AccessReviewTiles from "views/access_reviews/AccessReviewTiles";
import {
  handleRequestExport,
  UARExportType,
} from "views/access_reviews/common/Export";
import { getDeadlineProgressBarInfo } from "views/access_reviews/common/utils";
import { AccessReviewGroupResourceVisibilityOptions } from "views/access_reviews/create/CreateAccessReviewColumn";

import DeadlineForm from "./common/DeadlineForm";
import { getHasFilters } from "./create/utils";

type AccessReviewOngoingOverviewProps = {
  ongoingAccessReview: AccessReviewFragment;
  userReviewsStats?: OngoingAccessReviewTabStatsFragment;
};

const AccessReviewOngoingOverview = (
  props: AccessReviewOngoingOverviewProps
) => {
  const {
    displaySuccessToast,
    displayLoadingToast,
    displayErrorToast,
  } = useToast();
  const { authState } = useContext(AuthContext);

  const [errorMessage, setErrorMessage] = useState<string>();
  const containerRef = useRef<HTMLDivElement | null>(null);
  const canManage = authState.user?.isAdmin || authState.user?.isAuditor;
  const hasGroupBindings = useFeatureFlag(FeatureFlag.GroupBindings);

  const { filters } = props.ongoingAccessReview;

  const [
    sendReviewerAssignmentNotification,
    setSendReviewerAssignmentNotifications,
  ] = useState(props.ongoingAccessReview.sendReviewerAssignmentNotification);
  const groupResourceVisibility =
    props.ongoingAccessReview.groupResourceVisibilityPolicy;
  const [reminderSchedule, setReminderSchedule] = useState<number[]>(
    props.ongoingAccessReview.reminderSchedule
  );
  const [deadline, setDeadline] = useState(props.ongoingAccessReview.deadline);
  const [timeZone, setTimeZone] = useState(
    timeZones.find((tz) => tz.name === props.ongoingAccessReview.timeZone) ||
      getDefaultTimezone()
  );

  const handleDeadlineChanges = (newDeadline: string, tz: TimeZone) => {
    if (newDeadline !== deadline) {
      setDeadline(newDeadline);
    }

    if (tz.name != timeZone.name) {
      setTimeZone(tz);
    }
  };

  const [reminderTimeOfDay, setReminderTimeOfDay] = useState(
    new Date(props.ongoingAccessReview.reminderTimeOfDay)
  );

  const [reminderIncludeManager, setReminderIncludeManager] = useState(
    props.ongoingAccessReview.reminderIncludeManager
  );

  const [sendReminderModalOpen, setSendReminderModalOpen] = useState(false);

  const [updateAccessReview, { loading }] = useUpdateAccessReviewMutation();

  const { data, error } = useAccessReviewStatsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        accessReviewId: props.ongoingAccessReview.id,
      },
    },
  });

  let accessReviewStats = null;
  if (data) {
    switch (data.accessReviewStats.__typename) {
      case "AccessReviewStatsResult":
        accessReviewStats = data.accessReviewStats.accessReviewStats || null;
        break;
      case "AccessReviewNotFoundError":
        break;
      default:
        logError(new Error(`failed to retrieve access review stats`));
    }
  } else if (error) {
    logError(error, `failed to retrieve access review stats`);
  }

  const assignmentPolicy = props.ongoingAccessReview.reviewerAssignmentPolicy;
  const selfReviewAllowed = props.ongoingAccessReview.selfReviewAllowed;
  const includeGroupBindings = props.ongoingAccessReview.includeGroupBindings;

  const getSaveDisabled = () => {
    return (
      props.ongoingAccessReview.sendReviewerAssignmentNotification ===
        sendReviewerAssignmentNotification &&
      props.ongoingAccessReview.reminderSchedule === reminderSchedule &&
      new Date(props.ongoingAccessReview.reminderTimeOfDay).valueOf() ===
        reminderTimeOfDay.valueOf() &&
      props.ongoingAccessReview.reminderIncludeManager ===
        reminderIncludeManager &&
      props.ongoingAccessReview.deadline === deadline &&
      props.ongoingAccessReview.timeZone === timeZone?.name
    );
  };

  const handleSave = async () => {
    try {
      const { data } = await updateAccessReview({
        variables: {
          input: {
            id: props.ongoingAccessReview.id,
            sendReviewerAssignmentNotification,
            reminderSchedule,
            reminderTimeOfDay: reminderTimeOfDay.toISOString(),
            reminderIncludeManager,
            deadline: deadline,
            timeZone: timeZone?.name,
          },
        },
      });
      switch (data?.updateAccessReview.__typename) {
        case "UpdateAccessReviewResult":
          setErrorMessage("");
          displaySuccessToast(`Success: access review settings updated`);
          break;
        case "AccessReviewNotFoundError":
          setErrorMessage(data.updateAccessReview.message);
          break;
        default:
          logError(new Error(`failed to update access review settings`));
          setErrorMessage(`Error: failed to update access review settings`);
          containerRef.current?.scroll({ top: 0, behavior: "smooth" });
      }
    } catch (error) {
      logError(error, `failed to update access review settings`);
      setErrorMessage(`Error: failed to update access review settings`);
      containerRef.current?.scroll({ top: 0, behavior: "smooth" });
    }
  };

  const canEditSettings = authState.user?.isAdmin || authState.user?.isAuditor;

  const menuOptions: PropsFor<typeof ColumnHeader>["menuOptions"] = [];
  if (canEditSettings) {
    menuOptions.push(
      {
        label: "Send reminder to reviewers",
        onClick: () => setSendReminderModalOpen(true),
        icon: { type: "name", icon: "mail" },
      },
      {
        label: "Export all resource users (.csv)",
        onClick: () => {
          handleRequestExport(
            props.ongoingAccessReview,
            UARExportType.ResourceUsers,
            displayLoadingToast,
            displaySuccessToast,
            displayErrorToast
          );
        },
        icon: { type: "name", icon: "users-right" },
      },
      {
        label: "Export all group users (.csv)",
        onClick: () => {
          handleRequestExport(
            props.ongoingAccessReview,
            UARExportType.GroupUsers,
            displayLoadingToast,
            displaySuccessToast,
            displayErrorToast
          );
        },
        icon: { type: "name", icon: "users-right" },
      }
    );
  }

  return (
    <>
      <Column isContent maxWidth="lg">
        <ColumnHeader
          title="Overview"
          subtitle={props.ongoingAccessReview.name}
          icon={{ type: "name", icon: "list" }}
          menuOptions={menuOptions}
          rightActions={
            canManage ? (
              <div className={sprinkles({ display: "flex", gap: "sm" })}>
                <StopAccessReviewButton
                  ongoingAccessReviewId={props.ongoingAccessReview.id}
                />
                {!getSaveDisabled() && (
                  <Button
                    label="Save"
                    leftIconName="plus"
                    type="primary"
                    onClick={handleSave}
                    loading={loading}
                  />
                )}
              </div>
            ) : undefined
          }
        />
        <Divider />
        <ColumnContent ref={containerRef}>
          <div
            className={sprinkles({
              padding: "lg",
            })}
          >
            {errorMessage ? (
              <div className={sprinkles({ marginBottom: "md" })}>
                <Banner type="error" message={errorMessage} />
              </div>
            ) : null}
            <div
              className={sprinkles({
                marginBottom: "lg",
              })}
            >
              <AccessReviewStats
                accessReview={props.ongoingAccessReview}
                accessReviewStats={accessReviewStats}
                userReviewsStats={props.userReviewsStats}
              />
            </div>
            <Card title="Access Review Information">
              <DeadlineForm
                onChange={handleDeadlineChanges}
                deadline={props.ongoingAccessReview.deadline}
                label={"Deadline"}
                timeZone={timeZone}
              />
            </Card>
            {filters && getHasFilters(filters) ? (
              <Card title="Scope">
                {filters.userIDs.length > 0 && (
                  <FormGroup label="Users">
                    <UsersFilter userIDs={filters.userIDs} />
                  </FormGroup>
                )}
                <div className={sprinkles({ marginBottom: "md" })}>
                  Include entities that match{" "}
                  <span className={sprinkles({ fontWeight: "semibold" })}>
                    {filters.matchMode === FiltersMatchMode.All ? "ALL" : "ANY"}
                  </span>{" "}
                  of the following filters:
                </div>
                <EntitiesNamedFilter names={filters.names} />
                <EntityTypesFilter
                  resourceTypes={filters.resourceTypes}
                  groupTypes={filters.groupTypes}
                />
                <ConnectionsFilter connectionIDs={filters.connectionIDs} />
                <EntitiesWithAdminFilter adminIDs={filters.adminIDs} />
                <TagFilter tags={filters.tags} />
                <EntitiesIndividualFilter entityIds={filters.entityIDs} />
              </Card>
            ) : (
              <Card title="Scope">
                This access review included all items at its creation.
              </Card>
            )}
            <Card title="Notifications">
              <ReminderNotifications
                reminderSchedule={reminderSchedule}
                reminderTimeOfDay={reminderTimeOfDay}
                reminderIncludeManager={reminderIncludeManager}
                timezone={timeZone}
                onChange={
                  canEditSettings
                    ? (
                        thisReminderSchedule,
                        thisReminderTimeOfDay,
                        thisReminderIncludeManager
                      ) => {
                        setReminderSchedule(thisReminderSchedule);
                        setReminderTimeOfDay(thisReminderTimeOfDay);
                        setReminderIncludeManager(thisReminderIncludeManager);
                      }
                    : undefined
                }
              />
              <FormGroup
                label={`New review notifications: ${
                  sendReviewerAssignmentNotification ? "On" : "Off"
                }`}
              >
                <Switch
                  label="Send a notification to reviewers when they're assigned a new review."
                  checked={sendReviewerAssignmentNotification}
                  onChange={(checked) =>
                    setSendReviewerAssignmentNotifications(checked)
                  }
                  rightAlign
                  disabled={!canEditSettings}
                />
              </FormGroup>
            </Card>
            <Card title="Reviews">
              <AssignmentPolicy assignmentPolicy={assignmentPolicy} />
              <FormGroup
                label={`Self-review: ${
                  selfReviewAllowed ? "Allowed" : "Not Allowed"
                }`}
              />
              <FormGroup
                label={`Group Resource Visibility: ${
                  AccessReviewGroupResourceVisibilityOptions.find(
                    (option) => option.value === groupResourceVisibility
                  )?.label
                }`}
                description={
                  AccessReviewGroupResourceVisibilityOptions.find(
                    (option) => option.value === groupResourceVisibility
                  )?.description
                }
              />
              {hasGroupBindings && (
                <FormGroup
                  label={`Include Linked Groups: ${
                    includeGroupBindings ? "Yes" : "No"
                  }`}
                />
              )}
            </Card>
          </div>
        </ColumnContent>
      </Column>
      {sendReminderModalOpen && (
        <SendReminderModal
          accessReview={props.ongoingAccessReview}
          onClose={() => setSendReminderModalOpen(false)}
        />
      )}
    </>
  );
};

type StopAccessReviewButtonProps = {
  ongoingAccessReviewId: string;
};

const StopAccessReviewButton = (props: StopAccessReviewButtonProps) => {
  const history = useHistory();

  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );

  const { displaySuccessToast, displayErrorToast } = useToast();
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const [stopAccessReview, { loading }] = useStopAccessReviewMutation();

  const modalReset = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  const confirmModal = (
    <ConfirmModal
      title={`Stop access review`}
      message="Are you sure you want to stop the ongoing access review? This action affects the entire organization, and cannot be undone."
      isModalOpen={showModal}
      onClose={modalReset}
      onSubmit={async () => {
        try {
          const { data } = await stopAccessReview({
            variables: {
              input: {
                id: props.ongoingAccessReviewId,
              },
            },
            refetchQueries: ["AccessReviews"],
          });
          switch (data?.stopAccessReview.__typename) {
            case "StopAccessReviewResult":
              accessReviewState.ongoingAccessReviewIdSet.delete(
                data.stopAccessReview.accessReview.id
              );
              accessReviewDispatch({
                type: AccessReviewContextActionType.AccessReviewUpdate,
                payload: {
                  ongoingAccessReviewIdSet:
                    accessReviewState.ongoingAccessReviewIdSet,
                },
              });
              // If we didn't get a reportId, PDF gen failed, so warn the user
              if (!data?.stopAccessReview.accessReview.reportId) {
                displayErrorToast(
                  "Access review stopped, but failed to generate PDF report"
                );
              } else {
                // Otherwise, we can proceed with downloading the report
                displaySuccessToast(`Success: access review stopped`);
                handleRequestExport(
                  data.stopAccessReview.accessReview,
                  UARExportType.Report
                );
              }
              history.replace(
                `/access-reviews/${props.ongoingAccessReviewId}?category=ended#overview`
              );
              break;
            case "AccessReviewNotFoundError":
            case "AccessReviewAlreadyStoppedError":
              setErrorMessage(data.stopAccessReview.message);
              break;
            default:
              setErrorMessage(`Error: failed to stop access review`);
          }
        } catch (error) {
          logError(error, "failed to stop access review");
          setErrorMessage(
            getModifiedErrorMessage(
              "Error: failed to stop access review",
              error
            )
          );
        }
      }}
      loading={loading}
      errorMessage={errorMessage}
      submitLabel={"Stop Review"}
    />
  );

  return (
    <div>
      <Button
        type="error"
        label={"Stop Access Review"}
        onClick={() => {
          setShowModal(true);
        }}
        loading={loading}
        borderless
      />
      {confirmModal}
    </div>
  );
};

type AccessReviewStatsProps = {
  accessReview: AccessReviewPreviewFragment;
  // Stats for total number of items that still need to be reviewed
  accessReviewStats: Maybe<AccessReviewStatsFragment>;
  // Stats for how many items current user has been assigned that still need to be reviewed
  userReviewsStats?: OngoingAccessReviewTabStatsFragment;
};

const AccessReviewStats = (props: AccessReviewStatsProps) => {
  const { accessReviewStats, userReviewsStats } = props;
  const { authState } = useContext(AuthContext);
  const isAssigner = authState.user?.isAdmin || authState.user?.isAuditor;

  const [
    incompleteReviewersModalOpen,
    setIncompleteReviewersModalOpen,
  ] = useState(false);

  const numResourcesTotal = accessReviewStats?.numResourcesTotal || 0;
  const numResourcesReviewed = accessReviewStats?.numResourcesReviewed || 0;
  const numResourcesRemaining = numResourcesTotal - numResourcesReviewed;
  const numGroupsTotal = accessReviewStats?.numGroupsTotal || 0;
  const numGroupsReviewed = accessReviewStats?.numGroupsReviewed || 0;
  const numGroupsRemaining = numGroupsTotal - numGroupsReviewed;
  const numConnectionsTotal = accessReviewStats?.numConnectionsTotal || 0;
  const numConnectionsReviewed = accessReviewStats?.numConnectionsReviewed || 0;
  const numConnectionsRemaining = numConnectionsTotal - numConnectionsReviewed;
  const numItemsTotal =
    numResourcesTotal + numGroupsTotal + numConnectionsTotal;
  const numItemsReviewed =
    numResourcesReviewed + numGroupsReviewed + numConnectionsReviewed;

  const numIncompleteReviewers = accessReviewStats?.numReviewersIncomplete ?? 0;
  const numTotalReviewers = accessReviewStats?.numReviewersTotal ?? 0;
  const numCompleteReviewers = numTotalReviewers - numIncompleteReviewers;

  const itemsPercentReviewed =
    Math.round((numItemsReviewed / numItemsTotal) * 100) || 0;

  const reviewersPercentComplete =
    Math.round((numCompleteReviewers / numTotalReviewers) * 100) || 0;

  let deadlineInfo = getDeadlineProgressBarInfo(props.accessReview);

  const strokeWidth = 1.5;

  const remainingResourcesDesc = pluralize(
    `resource`,
    numResourcesRemaining,
    true
  );
  const remainingGroupsDesc = pluralize(`group`, numGroupsRemaining, true);
  const remainingConnectionsDesc = pluralize(
    `app`,
    numConnectionsRemaining,
    true
  );

  const userTotalItemsToReview = userReviewsStats
    ? userReviewsStats?.numAppsToReview +
      userReviewsStats?.numGroupsToReview +
      userReviewsStats?.numResourcesToReview
    : 0;
  const userReviewDescriptionParts = [];
  if (userReviewsStats?.numResourcesToReview) {
    userReviewDescriptionParts.push(
      `${pluralize("resource", userReviewsStats?.numResourcesToReview, true)}`
    );
  }
  if (userReviewsStats?.numGroupsToReview) {
    userReviewDescriptionParts.push(
      `${pluralize("group", userReviewsStats?.numGroupsToReview, true)}`
    );
  }
  if (userReviewsStats?.numAppsToReview) {
    userReviewDescriptionParts.push(
      `${pluralize("app", userReviewsStats?.numAppsToReview, true)}`
    );
  }
  const userItemsToReviewDescription = userReviewDescriptionParts.length
    ? `${userReviewDescriptionParts.join(", ")} left to review`
    : "All reviews complete";

  const incompleteReviewers = accessReviewStats?.incompleteReviewers ?? [];
  // Close incomplete reviewers modal if there are no incomplete reviewers
  if (incompleteReviewers.length === 0 && incompleteReviewersModalOpen) {
    setIncompleteReviewersModalOpen(false);
  }

  return (
    <>
      <AccessReviewTiles
        tileInfos={[
          {
            key: "num-items-completed",
            icon: <Icons.Box strokeWidth={strokeWidth} />,
            header: `${numItemsReviewed}/${numItemsTotal}`,
            subHeader: `${pluralize(`item`, numItemsTotal)} reviewed`,
            progressPercentage: itemsPercentReviewed,
            hide: !isAssigner,
            description: `${remainingResourcesDesc}, ${remainingGroupsDesc} and ${remainingConnectionsDesc} left to review`,
          },
          {
            key: "incomplete-reviewers",
            icon: <Icon name="user-x" />,
            header: `${numCompleteReviewers}/${numTotalReviewers}`,
            subHeader: `${pluralize(
              `reviewers`,
              numTotalReviewers,
              false
            )} finished`,
            progressPercentage: reviewersPercentComplete,
            hide: !isAssigner,
            description: `${reviewersPercentComplete}% of reviewers have completed all their assigned reviews`,
            onClick:
              incompleteReviewers.length > 0
                ? () => setIncompleteReviewersModalOpen(true)
                : undefined,
          },
          {
            key: "incomplete-reviews",
            icon: <Icons.Box strokeWidth={strokeWidth} />,
            header: `${userTotalItemsToReview}`,
            subHeader: `${pluralize(
              `item`,
              userTotalItemsToReview
            )} left to review`,
            description: userItemsToReviewDescription,
            hide: isAssigner,
            descriptionColor: userTotalItemsToReview ? "orange" : "green",
          },
          {
            key: "days-since",
            icon: <Icons.Calendar strokeWidth={strokeWidth} />,
            header: deadlineInfo.daysToShow,
            subHeader: deadlineInfo.diffText,
            progressPercentage: deadlineInfo.progressPercentage,
            description: deadlineInfo.dateText,
          },
        ]}
      />
      <AccessReviewIncompleteReviewersModal
        isOpen={incompleteReviewersModalOpen}
        onClose={() => setIncompleteReviewersModalOpen(false)}
        accessReview={props.accessReview}
        incompleteReviewers={incompleteReviewers}
      />
    </>
  );
};
export { AccessReviewStats };

export default AccessReviewOngoingOverview;
