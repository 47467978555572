import { ColumnContainer } from "components/column/Column";
import { Route } from "react-router-dom";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import OwnersColumn from "./OwnersColumn";
import OwnersColumnV3 from "./OwnersColumnV3";

const Owners = () => {
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);

  if (hasV3Nav) {
    return (
      <Route
        exact
        path={["/owners", "/owners/:ownerId"]}
        component={OwnersComponentV3}
      />
    );
  }

  return (
    <Route
      exact
      path={["/owners", "/owners/:ownerId"]}
      component={OwnersComponent}
    />
  );
};

const OwnersComponentV3 = () => {
  return <OwnersColumnV3 />;
};

const OwnersComponent = () => {
  return (
    <ColumnContainer>
      <OwnersColumn />
    </ColumnContainer>
  );
};

export default Owners;
