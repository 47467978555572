import {
  UserOverviewFragment,
  UserTagFragment,
  useUpdateUserMutation,
} from "api/generated/graphql";
import { UserConfig } from "components/forms/common";
import PositionRow from "components/forms/rows/PositionRow";
import UserRow from "components/forms/rows/UserRow";
import { makeConfigForUser } from "components/forms/utils";
import { useToast } from "components/toast/Toast";
import { Banner, Modal } from "components/ui";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { logError } from "utils/logging";

export type UserEditModalProps = {
  user: UserOverviewFragment;
  userAttributes: UserTagFragment[] | null;
  canEditManager: boolean;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

export const UserEditModal = (props: UserEditModalProps) => {
  const [config, setConfig] = useState<Partial<UserConfig>>({});
  const [initialConfig, setInitialConfig] = useState<Partial<UserConfig>>({});
  const [errors, setErrors] = useState<string[]>([]);
  const { displaySuccessToast } = useToast();

  const [update, { loading: updateLoading }] = useUpdateUserMutation();

  useEffect(() => {
    if (props.user) {
      const _config = makeConfigForUser(
        props.user,
        props.userAttributes,
        props.canEditManager
      );
      setConfig(_config);
      setInitialConfig(_config);
    }
  }, [props.user, props.userAttributes, props.canEditManager]);

  const modalReset = () => {
    props.setShowModal(false);
    setErrors([]);
  };

  const handleSubmit = async () => {
    const errors = [];
    if (
      !props.canEditManager &&
      !_.isEqual(config.manager, initialConfig.manager)
    ) {
      errors.push(
        "The manager field is imported from your IDP/HRIS and can't be edited from Opal."
      );
    }

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    try {
      setErrors([]);
      const { data } = await update({
        variables: {
          input: {
            id: props.user.id,
            // don't attempt to update the manager if it hasn't changed
            managerId: _.isEqual(config.manager?.id, initialConfig.manager?.id)
              ? null
              : { userId: config.manager?.id },
            position: config.position,
          },
        },
        refetchQueries: ["UserDetail"],
      });
      switch (data?.updateUser.__typename) {
        case "UpdateUserResult":
          props.setShowModal(false);
          displaySuccessToast("User updated");
          break;
        case "UserNotFoundError":
        case "SystemUserIsImmutableError":
        case "ManagedHRDataIsImmutableError":
          setErrors([data.updateUser.message]);
          break;
        default:
          logError(new Error(`failed to to update user title`));
          setErrors(["Error: failed to update user title"]);
      }
    } catch (e) {
      setErrors(["Failed to save user"]);
      logError(e, "failed to update owner");
    }
  };

  return (
    <Modal
      title={`Edit Details for ${props.user.fullName}`}
      isOpen={props.showModal}
      onClose={modalReset}
    >
      <Modal.Body>
        {errors.map((error) => (
          <Banner message={error} type="error" />
        ))}
        <PositionRow
          mode={"edit"}
          onChange={(newPosition) => {
            setConfig({ ...config, ["position"]: newPosition });
          }}
          value={config.position ?? undefined}
          styleAsFormGroup
        />
        <UserRow
          title="Manager"
          mode={"edit"}
          onChange={(newManager) => {
            setConfig({ ...config, ["manager"]: newManager });
          }}
          user={config.manager ?? undefined}
          disabled={!config.editableManager}
          editDescription={
            !config.editableManager
              ? "This field is imported from your IDP/HRIS and can't be edited from Opal."
              : undefined
          }
          styleAsFormGroup
        />
      </Modal.Body>
      <Modal.Footer
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLabel="Save"
        primaryButtonLoading={updateLoading}
        primaryButtonType="primary"
        primaryButtonDisabled={
          updateLoading || _.isEqual(config, initialConfig)
        }
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={() => {
          modalReset();
        }}
      />
    </Modal>
  );
};

export default UserEditModal;
