import { getModifiedErrorMessage } from "api/ApiContext";
import {
  Maybe,
  ResourceType,
  useDeleteResourcesMutation,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Banner, Modal } from "components/ui";
import React, { useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { logError } from "utils/logging";
import { useURLSearchParam } from "utils/router/hooks";
import { OKTA_APP_ID_URL_KEY } from "views/apps/AppsContext";

export type ResourceDeleteModalProps = {
  resource: {
    id: string;
    name: string;
    resourceType: ResourceType;
    connectionId: string;
  };
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

export const ResourceDeleteModal = (props: ResourceDeleteModalProps) => {
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();
  const logEvent = useLogEvent();

  const [oktaAppId] = useURLSearchParam(OKTA_APP_ID_URL_KEY);

  const [deleteResources, { loading }] = useDeleteResourcesMutation({
    refetchQueries: [
      "ConnectionOverview",
      "Resource",
      "ResourcesHome",
      "ItemsListSection",
      "AppDetailColumn",
      "AppsListColumn",
    ],
    update: (cache, { data }) => {
      switch (data?.deleteResources.__typename) {
        case "DeleteResourcesResult":
          if (props.resource.resourceType === ResourceType.OktaApp) {
            // fixes a bug requiring a hard refresh for
            // this deletion to appear in the Apps list
            cache.evict({ fieldName: "apps" });
          }
          break;
      }
    },
  });

  const deleteModalReset = () => {
    props.setShowModal(false);
    setErrorMessage(null);
  };

  const getRedirectUrl = () => {
    if (props.resource.resourceType === ResourceType.OktaApp) {
      return {
        pathname: "/apps",
      };
    }
    if (oktaAppId) {
      return {
        pathname: `/apps/${oktaAppId}`,
      };
    }
    return {
      pathname: `/apps/${props.resource.connectionId}`,
    };
  };

  const handleSubmit = async () => {
    logEvent({
      name: "apps_item_remove_click",
      properties: {
        itemType: props.resource.resourceType,
      },
    });

    try {
      const { data } = await deleteResources({
        variables: {
          input: {
            resourceIds: [props.resource.id],
          },
        },
      });
      switch (data?.deleteResources.__typename) {
        case "DeleteResourcesResult":
          deleteModalReset();
          displaySuccessToast(
            `Success: "${props.resource.name}" resource deleted`
          );
          history.replace(getRedirectUrl());
          break;
        case "ResourceNotFoundError":
          setErrorMessage(data.deleteResources.message);
          break;
        case "OpalResourceImmutableError":
          setErrorMessage(data.deleteResources.message);
          break;
        default:
          logError(new Error(`failed to delete resource`));
          setErrorMessage(`Error: failed to delete resource`);
      }
    } catch (error) {
      logError(error, "failed to delete resource");
      setErrorMessage(
        getModifiedErrorMessage("Error: failed to delete resource", error)
      );
    }
  };

  return (
    <Modal
      title={`Remove ${props.resource.name} from Opal`}
      isOpen={props.showModal}
      onClose={deleteModalReset}
    >
      <Modal.Body>
        <p>
          <Banner
            message={`If your resource has any expiring or on-call access provisioned through Opal,
            this access will become permanent on the end system once you remove the resource.`}
            type="warning"
          />
        </p>
        <p>
          Are you sure you want to remove <b>{props.resource.name}</b> from
          Opal? This cannot be undone.
        </p>
        <p>Any children of this resource will also be removed.</p>
        <p>
          Removing a resource from Opal only deletes its configuration in Opal
          and does not cause access to be removed from the end system. This
          includes access provisioned through Opal.
        </p>
        {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
      </Modal.Body>
      <Modal.Footer
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLabel="Remove"
        primaryButtonLoading={loading}
        primaryButtonType="error"
      />
    </Modal>
  );
};

export default ResourceDeleteModal;
