import "app/App.scss";

import { ApolloCache } from "@apollo/client";
import {
  NumRequestsToReviewDocument,
  NumRequestsToReviewQuery,
  useLeftSidebarRoutesQuery,
  useNumRequestsToReviewQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Icon } from "components/ui";
import { useContext } from "react";
import { hasBasicPermissions } from "utils/auth/auth";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import AccessReviews from "views/access_reviews/AccessReviews";
import Items from "views/apps/ItemsPage";
import Bundles from "views/bundles/Bundles";
import DashboardColumn from "views/dashboard/DashboardColumn";
import Events from "views/events/Events";
import GroupBindings from "views/group_bindings/GroupBindings";
import MetricsDashboardView from "views/metrics_dashboard/MetricsDashboardView";
import Owners from "views/owners/Owners";
import Requests from "views/requests/Requests";
import { Search } from "views/search/Search";
import { SettingsComponent } from "views/settings/Settings";
import Tags from "views/tags/Tags";
import Templates from "views/templates/Templates";
import Users from "views/users/Users";
import Insights from "views/viz/Insights";

export type LeftSidebarSectionInfo = {
  title?: string;
  options: LeftSidebarOptionInfo[];
};

export type LeftSidebarOptionInfo = {
  iconName: PropsFor<typeof Icon>["name"];
  route: string;
  extraRoutes?: string[];
  title: string;
  // Component to redirect the main page to when this sidebar element is clicked
  component: React.ComponentType;
  // This allows the route to exist without it needing to appear in the sidebar
  hidden?: boolean;
  badgeCount?: number;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const updateNumRequestsToReview = (cache: ApolloCache<any>) => {
  let cachedNotificationsQuery = cache.readQuery<NumRequestsToReviewQuery>({
    query: NumRequestsToReviewDocument,
    variables: {
      input: {},
    },
  });
  if (cachedNotificationsQuery) {
    let numRequestsToReview = Math.max(
      cachedNotificationsQuery.numRequestsToReview - 1,
      0
    );
    cache.writeQuery<NumRequestsToReviewQuery>({
      query: NumRequestsToReviewDocument,
      data: {
        numRequestsToReview,
      },
    });
  }
};
const useLeftSidebarSectionInfos = (): LeftSidebarSectionInfo[] => {
  const hasDemoDashboard = useFeatureFlag(FeatureFlag.DemoDashboard);
  const hasMetricsDashboard = useFeatureFlag(FeatureFlag.MetricsDashboard);
  const hasGroupBindings = useFeatureFlag(FeatureFlag.GroupBindings);
  const { authState } = useContext(AuthContext);
  const { data } = useLeftSidebarRoutesQuery();
  const isAdmin = authState.user?.isAdmin || authState.user?.isReadOnlyAdmin;

  const { data: numRequestsToReviewData } = useNumRequestsToReviewQuery();

  const showRestrictedView = hasBasicPermissions(authState.user);

  const sections: LeftSidebarSectionInfo[] = [];

  const topSectionOptions: LeftSidebarOptionInfo[] = [
    {
      iconName: "search",
      route: "/search",
      title: "Search",
      component: Search,
      hidden: true,
    },
  ];

  topSectionOptions.push(
    {
      iconName: "dots-grid",
      route: "/apps",
      extraRoutes: ["/resources", "/groups"],
      title: "Apps",
      component: Items,
    },
    {
      iconName: "package",
      route: "/bundles",
      title: "Bundles",
      component: Bundles,
      hidden: true,
    },
    {
      iconName: "inbox",
      route: "/requests",
      title: "Requests",
      component: Requests,
      badgeCount: numRequestsToReviewData?.numRequestsToReview,
    }
  );

  // Top section
  sections.push({
    options: topSectionOptions,
  });

  // Audit section
  const auditOptions: LeftSidebarOptionInfo[] = [
    {
      iconName: "check-circle",
      route: "/access-reviews",
      title: "Access Reviews",
      component: AccessReviews,
    },
  ];
  if (!showRestrictedView) {
    auditOptions.push({
      iconName: "events",
      route: "/events",
      title: "Events",
      component: Events,
      hidden: showRestrictedView,
    });
  }
  sections.push({
    title: "Audit",
    options: auditOptions,
  });

  // Admin section
  const adminOptions: LeftSidebarOptionInfo[] = [];

  if (isAdmin && hasDemoDashboard) {
    adminOptions.push({
      iconName: "speedometer",
      route: "/dashboard-demo",
      title: "Dashboard",
      component: DashboardColumn,
    });
  }

  adminOptions.push(
    {
      iconName: "user-square",
      route: "/owners",
      title: "Owners",
      component: Owners,
      hidden: !(data?.leftSidebarRoutes.hasOwners ?? false),
    },
    {
      iconName: "user",
      route: "/users",
      title: "Users",
      component: Users,
      hidden: !isAdmin && !(data?.leftSidebarRoutes.hasOwners ?? false),
    }
  );

  if (isAdmin) {
    adminOptions.push(
      {
        iconName: "tag",
        route: "/tags",
        title: "Tags",
        component: Tags,
        hidden: !isAdmin,
      },
      {
        iconName: "template",
        route: "/templates",
        title: "Templates",
        component: Templates,
      }
    );
  }
  if (isAdmin && hasMetricsDashboard) {
    adminOptions.push({
      iconName: "line-chart-up",
      route: "/dashboard",
      title: "Dashboard",
      component: MetricsDashboardView,
    });
  }
  if (isAdmin) {
    adminOptions.push({
      iconName: "department",
      route: "/insights",
      title: "Insights (beta)",
      component: Insights,
    });
  }
  if (isAdmin && hasGroupBindings) {
    adminOptions.push({
      iconName: "link",
      route: "/linked-groups",
      title: "Linked Groups",
      component: GroupBindings,
    });
  }
  if (isAdmin) {
    adminOptions.push({
      iconName: "settings",
      route: "/settings",
      title: "Settings",
      extraRoutes: ["/idp/browse", "/idp/create/:serviceID"],
      component: SettingsComponent,
    });
  }
  sections.push({
    title: "Admin",
    options: adminOptions,
  });

  return sections;
};

export default useLeftSidebarSectionInfos;
