import {
  ConnectionType,
  GroupDropdownPreviewFragment,
  UserDropdownPreviewFragment,
  useUserOrGroupDropdownQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { AccessReviewerModalEntry } from "components/modals/AccessReviewerModal";
import { Select } from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";

import { logError } from "../../utils/logging";
import { getGroupTypeInfo } from "../label/GroupTypeLabel";
import { IconData } from "../ui/utils";

type UserOrGroupDropdownProps = {
  id?: string;
  allUsers: UserDropdownPreviewFragment[];
  selectedUsers?: AccessReviewerModalEntry[];
  onChange: (
    newTarget?: UserDropdownPreviewFragment | GroupDropdownPreviewFragment
  ) => void;
  value?: UserDropdownPreviewFragment | GroupDropdownPreviewFragment | null;
  disabled?: boolean;
  clearable?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  selectOnly?: boolean;
  includeTargetGroups?: boolean;
};

export const UserOrGroupDropdown = (props: UserOrGroupDropdownProps) => {
  const { clearable = true } = props;
  const { authState } = useContext(AuthContext);

  const {
    data: groupsData,
    error: groupsError,
  } = useUserOrGroupDropdownQuery();
  if (groupsError) {
    logError(groupsError, "failed to list groups");
  }

  let usersOrGroups: (
    | UserDropdownPreviewFragment
    | GroupDropdownPreviewFragment
  )[] = [];

  let allUsers = props.allUsers;

  allUsers = allUsers.slice().sort((a, b) => {
    if (a && b) {
      return a.fullName.localeCompare(b.fullName);
    }
    return 0;
  });

  usersOrGroups.push(...allUsers);

  if (props.includeTargetGroups) {
    let groups = groupsData?.groups.groups ?? [];

    groups = groups.slice().sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    usersOrGroups.push(...groups);
  }

  const getOptionLabel = (
    option: UserDropdownPreviewFragment | GroupDropdownPreviewFragment
  ) => {
    switch (option.__typename) {
      case "User":
        return option.id === authState.user?.user.id
          ? `${option.fullName} (Me)`
          : option.fullName;
      case "Group":
        if (
          option.connection?.connectionType === ConnectionType.CustomConnector
        ) {
          return `${option.connection?.name ?? "Unknown connector"} / ${
            option.name
          }`;
        }
        return option.name;
    }
    return "";
  };

  const renderOptionLabel = (
    option: UserDropdownPreviewFragment | GroupDropdownPreviewFragment
  ) => {
    switch (option.__typename) {
      case "User":
        return (
          <span>
            {option.fullName}
            <span
              className={sprinkles({ color: "gray500", fontSize: "bodySm" })}
            >
              {" "}
              ({option.email})
            </span>
          </span>
        );
      case "Group":
        return (
          <span>
            {option.name}
            <span
              className={sprinkles({ color: "gray500", fontSize: "bodySm" })}
            >
              {" "}
              ({getGroupTypeInfo(option.groupType)?.name})
            </span>
          </span>
        );
    }
    return <></>;
  };

  const getIcon = (
    option: UserDropdownPreviewFragment | GroupDropdownPreviewFragment
  ): IconData | undefined => {
    switch (option.__typename) {
      case "User":
        return {
          type: "src",
          style: "rounded",
          icon: option.avatarUrl || defaultAvatarURL,
        };
      case "Group":
        if (option.connection) {
          if (
            option.connection.connectionType ===
              ConnectionType.CustomConnector &&
            option.connection.iconUrl
          ) {
            return {
              type: "src",
              icon: option.connection.iconUrl,
            };
          }
          return {
            type: "entity",
            entityType: option.connection.connectionType,
          };
        }
    }
  };

  return (
    <Select
      id={props.id}
      options={usersOrGroups}
      value={props.value ?? undefined}
      onChange={props.onChange}
      clearable={clearable}
      disabled={props.disabled}
      getOptionLabel={getOptionLabel}
      renderOptionLabel={renderOptionLabel}
      getIcon={getIcon}
      getOptionSelected={(option, value) => option.id === value.id}
      autoFocus={props.autoFocus}
      placeholder={props.placeholder}
      selectOnly={props.selectOnly}
    />
  );
};
