import { gql, useQuery } from "@apollo/client";
import {
  AppCategory,
  AppsSortByField,
  InventoryPageQuery,
  OpalAppGroupCountCellFragmentDoc,
  OpalAppNameCellFragmentDoc,
  OpalAppResourceCountCellFragmentDoc,
  OpalAppSourceCellFragmentDoc,
  OpalAppUserCountCellFragmentDoc,
  OpalAppVisibilityCellFragmentDoc,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { useOpalSearchInput } from "components/opal/common/input/OpalSearchInput";
import OpalAppGroupCountCell from "components/opal/table/cells/app/OpalAppGroupCountCell";
import OpalAppNameCell from "components/opal/table/cells/app/OpalAppNameCell";
import OpalAppResourceCountCell from "components/opal/table/cells/app/OpalAppResourceCountCell";
import OpalAppSourceCell from "components/opal/table/cells/app/OpalAppSourceCell";
import OpalAppUserCountCell from "components/opal/table/cells/app/OpalAppUserCountCell";
import OpalAppVisibilityCell from "components/opal/table/cells/app/OpalAppVisibilityCell";
import OpalTable, {
  Columns,
  useServerSortableOpalTable,
} from "components/opal/table/OpalTable";
import { ButtonV3 } from "components/ui";
import { useContext } from "react";
import { useHistory } from "react-router";
import { useURLSearchParamAsEnum } from "utils/router/hooks";
import { useAccessOptionKey } from "views/apps/utils";

import InventoryAppsFilters from "./InventoryAppsFilters";

type RowData = InventoryPageQuery["apps"]["apps"][0];

const COLUMNS: Columns<RowData, AppsSortByField> = [
  {
    id: AppsSortByField.Name,
    label: "Name",
    width: 300,
    sortable: true,
    customCellRenderer: (row: RowData) => {
      return <OpalAppNameCell appID={row.id} path={getPathForRow(row)} />;
    },
  },
  {
    id: AppsSortByField.Visiblity,
    label: "Visibility",
    sortable: true,
    customCellRenderer: (row: RowData) => {
      return <OpalAppVisibilityCell appID={row.id} />;
    },
  },
  {
    id: AppsSortByField.ResourceCount,
    label: "Resources",
    sortable: true,
    customCellRenderer: (row) => {
      return <OpalAppResourceCountCell appID={row.id} />;
    },
  },
  {
    id: "group_access",
    label: "Group Access",
    sortable: false, // groupCount has its own resolver and so can't be sorted server-side
    customCellRenderer: (row) => {
      return <OpalAppGroupCountCell appID={row.id} />;
    },
  },
  {
    id: "user_count",
    label: "User Access",
    sortable: false, // userCount has its own resolver and so can't be sorted server-side
    customCellRenderer: (row) => {
      return <OpalAppUserCountCell appID={row.id} />;
    },
  },
  {
    id: AppsSortByField.Source,
    label: "Source",
    sortable: true,
    customCellRenderer: (row) => {
      return <OpalAppSourceCell appID={row.id} />;
    },
  },
];

const getPathForRow = (row: RowData) => {
  let path = "#";
  switch (row.app.__typename) {
    case "ConnectionApp":
      path = `/inventory/${row.app.connectionId}`;
      break;
    case "OktaResourceApp":
      path = `/resources/${row.app.resourceId}`;
      break;
  }
  return path;
};

const InventoryAppsTable = () => {
  const history = useHistory();
  const { authState } = useContext(AuthContext);
  const {
    sortByVariable,
    sortByTableProps,
  } = useServerSortableOpalTable<AppsSortByField>({
    id: AppsSortByField.Name,
    desc: false,
  });
  const [searchQuery, searchInput] = useOpalSearchInput({
    placeholder: "Filter by name",
  });
  const [accessOptionKey] = useAccessOptionKey();
  const category = useURLSearchParamAsEnum(
    "category",
    AppCategory,
    AppCategory.All
  )[0];

  const { data, fetchMore, networkStatus } = useQuery<InventoryPageQuery>(
    gql`
      query InventoryPage(
        $access: AccessOption!
        $appCategory: AppCategory
        $searchQuery: String
        $cursor: String
        $sortBy: AppsSortBy
      ) {
        apps(
          access: $access
          appCategory: $appCategory
          searchQuery: $searchQuery
          cursor: $cursor
          sortBy: $sortBy
        ) {
          apps {
            id
            app {
              __typename
              ... on ConnectionApp {
                connectionId
              }
              ... on OktaResourceApp {
                resourceId
              }
            }
            ...OpalAppNameCell
            ...OpalAppVisibilityCell
            ...OpalAppResourceCountCell
            ...OpalAppGroupCountCell @defer(label: "OpalAppGroupCountCell")
            ...OpalAppUserCountCell @defer(label: "OpalAppUserCountCell")
            ...OpalAppSourceCell
          }
          cursor
          totalNumApps
        }
      }
      ${OpalAppNameCellFragmentDoc}
      ${OpalAppVisibilityCellFragmentDoc}
      ${OpalAppResourceCountCellFragmentDoc}
      ${OpalAppGroupCountCellFragmentDoc}
      ${OpalAppUserCountCellFragmentDoc}
      ${OpalAppSourceCellFragmentDoc}
    `,
    {
      fetchPolicy: "cache-and-network",
      variables: {
        access: accessOptionKey,
        appCategory: category,
        searchQuery: searchQuery,
        sortBy: sortByVariable,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  return (
    <>
      <OpalTable
        entityName="App"
        networkStatus={networkStatus}
        filters={
          <>
            {searchInput}
            <InventoryAppsFilters />
          </>
        }
        actions={
          authState.user?.isAdmin && [
            <ButtonV3
              type="main"
              label="App"
              leftIconName="plus"
              size="sm"
              onClick={() => history.push("/inventory/add-app")}
            />,
          ]
        }
        rows={data?.apps.apps || []}
        columns={COLUMNS}
        totalNumRows={data?.apps.totalNumApps || 0}
        {...sortByTableProps}
        getRowId={(row) => row.id}
        onLoadMoreRows={
          data?.apps.cursor
            ? async () => {
                await fetchMore({ variables: { cursor: data?.apps.cursor } });
              }
            : undefined
        }
        onRowClickTransitionTo={(row) => getPathForRow(row)}
      ></OpalTable>
    </>
  );
};

export default InventoryAppsTable;
