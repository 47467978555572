import { Maybe } from "api/generated/graphql";
import React from "react";

type LinkLabelProps = {
  link?: Maybe<string>;
  linkTitle: string;
};

export const LinkLabel = (props: LinkLabelProps) => {
  if (!props.link) {
    return <>{"--"}</>;
  }

  return (
    <div>
      <a
        target="_blank"
        rel="noreferrer"
        href={props.link}
        style={{ color: "blue", textDecorationLine: "underline" }}
      >
        {props.linkTitle}
      </a>
    </div>
  );
};

export default LinkLabel;
