import { getModifiedErrorMessage } from "api/ApiContext";
import {
  GeneralSettingType,
  Maybe,
  OrganizationSettingsFragment,
  useDeleteOrganizationMutation,
  useUpdateOrganizationSettingsMutation,
} from "api/generated/graphql";
import clsx from "clsx";
import AuthContext from "components/auth/AuthContext";
import { DeleteOrgModal } from "components/modals/update/DeleteOrgModal";
import { ToastStyle, useToast } from "components/toast/Toast";
import { getToastUrl, MessageCode } from "components/toast/ToastUrlParser";
import { Button, Switch } from "components/ui";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { RequestState } from "utils/common";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import styles from "views/settings/DangerZone.module.scss";
import OrgContext, { OrgContextActionType } from "views/settings/OrgContext";

import { OrgSettingsSection } from "./OrgSettings";

type DangerZoneProps = {
  orgSettings: OrganizationSettingsFragment | null;
};

export const DangerZone = (props: DangerZoneProps) => {
  const allSettings = props.orgSettings?.generalSettings || [];
  const showDeleteOrg = useFeatureFlag(FeatureFlag.ShowDeleteOrganization);
  return (
    <OrgSettingsSection title="Danger Zone">
      <ToggleReadOnlyOrganizationSetting
        toggleState={props.orgSettings?.isRemoteReadOnly === true}
        currentSettings={allSettings}
      />
      <ToggleDryRunModeSetting
        toggleState={props.orgSettings?.dryRunModeEnabled === true}
        currentSettings={allSettings}
      />
      {showDeleteOrg && <DeleteOrganizationSetting />}
    </OrgSettingsSection>
  );
};

type ToggleReadOnlyOrganizationSettingProps = {
  toggleState: boolean;
  currentSettings: GeneralSettingType[];
};

const ToggleReadOnlyOrganizationSetting = (
  props: ToggleReadOnlyOrganizationSettingProps
) => {
  const [updateOrgSettings] = useUpdateOrganizationSettingsMutation();

  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();

  const { orgDispatch } = useContext(OrgContext);
  const { authState } = useContext(AuthContext);

  const togglePanel = (
    <div className={styles.shadedFrame}>
      <div
        className={clsx({
          [styles.shadedPanel]: true,
          [styles.shadedRed]: true,
        })}
      >
        <div
          className={clsx({
            [styles.shadedPanelText]: true,
            [styles.shadedTextRed]: true,
            [styles.indentedTextLeftSmall]: true,
          })}
        >
          <div className={styles.header}>{"Toggle read-only mode"}</div>
          <div className={styles.subText}>
            {
              "Turning on read only mode prevents all users from making access changes within Opal."
            }
          </div>
        </div>
        <div className={styles.shadedButton}>
          {
            <span className={styles.button}>
              <Switch
                key="abcD"
                checked={props.toggleState}
                onChange={async () => {
                  try {
                    displayLoadingToast();
                    const { data } = await updateOrgSettings({
                      variables: {
                        input: {
                          settings: props.currentSettings,
                          isRemoteReadOnly: !props.toggleState,
                        },
                      },
                    });
                    switch (data?.updateOrganizationSettings.__typename) {
                      case "UpdateOrganizationSettingsResult":
                        orgDispatch({
                          type: OrgContextActionType.OrgSettings,
                          payload: {
                            orgSettings:
                              data.updateOrganizationSettings.settings,
                          },
                        });
                        displaySuccessToast("Success: settings updated");
                        break;
                      default:
                        logError(new Error(`failed to update org settings`));
                        displayErrorToast(
                          `Error: failed to update org settings`
                        );
                    }
                  } catch (error) {
                    logError(error, "failed to update org settings");
                    displayErrorToast(
                      getModifiedErrorMessage(
                        `Error: failed to update org settings`,
                        error
                      )
                    );
                  }
                }}
                disabled={!authState.user?.isAdmin}
              />
            </span>
          }
        </div>
      </div>
    </div>
  );
  return <div>{togglePanel}</div>;
};

type ToggleDryRunModeOrganizationSettingProps = {
  toggleState: boolean;
  currentSettings: GeneralSettingType[];
};

const ToggleDryRunModeSetting = (
  props: ToggleDryRunModeOrganizationSettingProps
) => {
  const [updateOrgSettings] = useUpdateOrganizationSettingsMutation();

  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();

  const { orgDispatch } = useContext(OrgContext);
  const { authState } = useContext(AuthContext);

  const togglePanel = (
    <div className={styles.shadedFrame}>
      <div
        className={clsx({
          [styles.shadedPanel]: true,
          [styles.shadedRed]: true,
        })}
      >
        <div
          className={clsx({
            [styles.shadedPanelText]: true,
            [styles.shadedTextRed]: true,
            [styles.indentedTextLeftSmall]: true,
          })}
        >
          <div className={styles.header}>{"Toggle dry-run mode"}</div>
          <div className={styles.subText}>
            {
              "Turning on dry-run mode allows access changes in Opal, but prevents access changes from being pushed to your end systems."
            }
          </div>
        </div>
        <div className={styles.shadedButton}>
          {
            <span className={styles.button}>
              <Switch
                checked={props.toggleState}
                onChange={async () => {
                  try {
                    displayLoadingToast();
                    const { data } = await updateOrgSettings({
                      variables: {
                        input: {
                          settings: props.currentSettings,
                          dryRunModeEnabled: !props.toggleState,
                        },
                      },
                    });
                    switch (data?.updateOrganizationSettings.__typename) {
                      case "UpdateOrganizationSettingsResult":
                        orgDispatch({
                          type: OrgContextActionType.OrgSettings,
                          payload: {
                            orgSettings:
                              data.updateOrganizationSettings.settings,
                          },
                        });
                        displaySuccessToast("Success: settings updated");
                        break;
                      default:
                        logError(new Error(`failed to update org settings`));
                        displayErrorToast(
                          `Error: failed to update org settings`
                        );
                    }
                  } catch (error) {
                    logError(error, "failed to update org settings");
                    displayErrorToast(
                      getModifiedErrorMessage(
                        `Error: failed to update org settings`,
                        error
                      )
                    );
                  }
                }}
                disabled={!authState.user?.isAdmin}
              />
            </span>
          }
        </div>
      </div>
    </div>
  );
  return <div>{togglePanel}</div>;
};

const DeleteOrganizationSetting = () => {
  const history = useHistory();

  const [deleteOrganization, { loading }] = useDeleteOrganizationMutation();

  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const { authState } = useContext(AuthContext);

  const confirmDeleteModal = (
    <DeleteOrgModal
      isModalOpen={showConfirmModal}
      onClose={() => {
        setShowConfirmModal(false);
      }}
      onSubmit={async () => {
        try {
          const { data } = await deleteOrganization();
          switch (data?.deleteOrganization.__typename) {
            case "DeleteOrganizationResult":
              history.replace(
                getToastUrl(
                  "/sign-out",
                  RequestState.Success,
                  MessageCode.SuccessOrganizationDeleted,
                  ToastStyle.Banner
                )
              );
              setShowConfirmModal(false);
              break;
            default:
              logError(new Error(`failed to delete organization`));
              setErrorMessage("Error: failed to delete organization");
          }
        } catch (error) {
          logError(error, `failed to delete organization`);
          setErrorMessage("Error: failed to delete organization");
        }
      }}
      loading={loading}
      errorMessage={errorMessage}
    />
  );

  const deletePanel = (
    <div className={styles.shadedFrame}>
      <div
        className={clsx({
          [styles.shadedPanel]: true,
          [styles.shadedRed]: true,
        })}
      >
        <div
          className={clsx({
            [styles.shadedPanelText]: true,
            [styles.shadedTextRed]: true,
            [styles.indentedTextLeftSmall]: true,
          })}
        >
          <div className={styles.header}>{"Delete this organization"}</div>
          <div className={styles.subText}>
            {
              "Deleting an organization is a permanent action and cannot be reversed. Please be certain."
            }
          </div>
        </div>
        <div className={styles.shadedButton}>
          {
            <Button
              type="error"
              leftIconName="alert-triangle"
              label="Delete Organization"
              onClick={() => {
                setShowConfirmModal(true);
              }}
              disabled={!authState.user?.isAdmin}
            />
          }
        </div>
      </div>
    </div>
  );

  return (
    <div>
      {confirmDeleteModal}
      {deletePanel}
    </div>
  );
};
