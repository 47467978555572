import { TooltipPlacement } from "components/label/Label";
import { useToast } from "components/toast/Toast";
import { Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";

import * as styles from "./ClickToCopyButton.css";

export type ClickToCopyButtonProps = {
  text: string;
  disableCopy?: boolean;
};

export const ClickToCopyButton = (props: ClickToCopyButtonProps) => {
  const { displaySuccessToast } = useToast();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(props.text).then(() => {
      displaySuccessToast("Copied to clipboard!");
    });
  };

  return props.disableCopy ? (
    <span>{props.text}</span>
  ) : (
    <div
      className={sprinkles({
        display: "inline-block",
      })}
    >
      <Tooltip tooltipText={"Copy"} placement={TooltipPlacement.Top}>
        <span className={styles.link} onClick={copyToClipboard}>
          {props.text}
        </span>
      </Tooltip>
    </div>
  );
};
