export const OPAL_IMPERSONATION_REMOTE_ID = "opal-impersonation";
export const OPAL_GLOBAL_IMPERSONATION_REMOTE_ID = "opal-global-impersonation";
export const OPAL_PROD_GLOBAL_IMPERSONATION_REMOTE_ID =
  "opal-global-impersonation-prod";
export const OPAL_AUDITOR_ROLE_REMOTE_ID = "opal-auditor";
export const OPAL_ADMIN_ROLE_REMOTE_ID = "opal-admin";
export const OPAL_READ_ONLY_ADMIN_ROLE_REMOTE_ID = "opal-read-only-admin";

export const IMPERSONATION_SESSION_LENGTH_MINUTES = 60;

export const DEFAULT_SESSION_LENGTH_MINUTES = 60 * 12;
export const DEFAULT_AUTHENTICATION_DB_NAME = "admin";
