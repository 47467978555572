import {
  EntityType,
  Maybe,
  ResourcePreviewLargeFragment,
} from "api/generated/graphql";
import { SectionEntry } from "components/viewer/Viewer";
import { Link } from "react-router-dom";
import { getResourceUrlNew } from "utils/common";

type ResourceAdminRowProps = {
  resource?: Maybe<ResourcePreviewLargeFragment>;
};

const ResourceAdminRow = (props: ResourceAdminRowProps) => {
  if (!props.resource) {
    return null;
  }

  let adminOwner = props.resource.adminOwner;

  return (
    <SectionEntry title="Admin">
      {adminOwner ? (
        <Link
          to={getResourceUrlNew(
            {
              entityId: props.resource.adminOwner?.id ?? null,
              entityType: EntityType.Owner,
            },
            EntityType.Owner
          )}
        >
          {adminOwner.name}
        </Link>
      ) : (
        "--"
      )}
    </SectionEntry>
  );
};

export default ResourceAdminRow;
