import {
  FirstPartyTokenFragment,
  useFirstPartyTokensQuery,
} from "api/generated/graphql";
import { Select } from "components/ui";
import { logError } from "utils/logging";

interface Props {
  value?: { tokenLabel: string; tokenPreview: string };
  onChange: (token?: FirstPartyTokenFragment) => void;
  clearable?: boolean;
  size?: PropsFor<typeof Select>["size"];
}

export const FirstPartyTokenDropdown = (props: Props) => {
  const { data, error, loading } = useFirstPartyTokensQuery();

  var firstPartyTokens: FirstPartyTokenFragment[] = [];
  switch (data?.firstPartyTokens.__typename) {
    case "FirstPartyTokensResult":
      firstPartyTokens = data?.firstPartyTokens.tokens;
      break;
  }

  if (error) {
    logError(error, "failed to list first party tokens");
  }

  return (
    <Select
      options={firstPartyTokens}
      getOptionLabel={(option: FirstPartyTokenFragment) =>
        `${option.tokenLabel} (${option.tokenPreview})`
      }
      onChange={props.onChange}
      placeholder="Select API token"
      multiple={false}
      loading={loading}
      value={firstPartyTokens.find(
        (token) =>
          token.tokenLabel === props.value?.tokenLabel &&
          token.tokenPreview === props.value?.tokenPreview
      )}
      clearable={props.clearable}
      size={props.size}
    />
  );
};

export default FirstPartyTokenDropdown;
