import { EntityIcon, Icon } from "components/ui";
import { useDragScroll } from "utils/hooks";

import { IconData } from "../utils";
import * as styles from "./TabsV3.css";

export interface TabInfo {
  title: string;
  onClick: () => void;
  isSelected: boolean;
  count?: number; // for static counts
  icon?: IconData;
}

interface TabStyle {
  fillWidth: boolean;
  wrap?: boolean;
}

const Tabs = ({
  tabInfos,
  tabStyle,
  tabType,
}: {
  tabInfos: TabInfo[];
  tabStyle?: TabStyle;
  tabType?: "round" | "square";
}) => {
  if (tabType && tabType == "round") {
    return <RoundTabs tabInfos={tabInfos} tabStyle={tabStyle} />;
  }
  return <SquareTabs tabInfos={tabInfos} tabStyle={tabStyle} />;
};

const SquareTabs = ({
  tabInfos,
  tabStyle,
}: {
  tabInfos: TabInfo[];
  tabStyle?: TabStyle;
}) => {
  return (
    <div
      className={styles.squareTabs({
        wrap: tabStyle?.wrap,
      })}
      role="tablist"
    >
      {tabInfos.map((tabInfo) => (
        <div
          className={styles.squareTab({
            fillWidth: tabStyle?.fillWidth,
            selected: tabInfo.isSelected,
          })}
          key={tabInfo.title}
          onClick={() => {
            tabInfo.onClick();
          }}
          role="tab"
          tabIndex={0}
        >
          {tabInfo.icon ? (
            tabInfo.icon.type === "entity" ? (
              <EntityIcon type={tabInfo.icon.entityType} includeBrand={false} />
            ) : (
              <Icon data={tabInfo.icon} size="xs" />
            )
          ) : null}
          <span>
            {tabInfo.count != null
              ? `${tabInfo.title} (${tabInfo.count})`
              : tabInfo.title}
          </span>
        </div>
      ))}
      <div className={styles.filler}></div>
    </div>
  );
};

const RoundTabs = ({
  tabInfos,
  tabStyle,
}: {
  tabInfos: TabInfo[];
  tabStyle?: TabStyle;
}) => {
  const [ref] = useDragScroll();

  return (
    <div
      className={styles.roundTabs({ wrap: tabStyle?.wrap })}
      role="tablist"
      ref={ref}
    >
      {tabInfos.map((tabInfo) => (
        <div
          className={styles.roundTab({
            selected: tabInfo.isSelected,
          })}
          key={tabInfo.title}
          onClick={() => {
            tabInfo.onClick();
          }}
          role="tab"
          tabIndex={0}
        >
          {tabInfo.icon ? (
            tabInfo.icon.type === "entity" ? (
              <EntityIcon type={tabInfo.icon.entityType} includeBrand={false} />
            ) : (
              <Icon data={tabInfo.icon} size="xs" />
            )
          ) : null}
          <span>{tabInfo.title}</span>
          {tabInfo.count !== undefined && (
            <span
              className={styles.badgeCount({ selected: tabInfo.isSelected })}
            >
              {tabInfo.count}
            </span>
          )}
        </div>
      ))}
      <div className={styles.filler}></div>
    </div>
  );
};

export default Tabs;
