import opalLogoSpinner from "assets/spinners/opal-logo-small.gif";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import * as styles from "./Loader.css";

const VizLoader = () => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  return (
    <div className={hasV3 ? styles.loaderV3 : styles.loader}>
      <img
        src={opalLogoSpinner}
        alt="visualization loading"
        height={24}
        width={24}
      />
      <span className={styles.loadingText}>Loading...</span>
    </div>
  );
};

export default VizLoader;
