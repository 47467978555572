import { EntityType } from "api/generated/graphql";
import GroupSearchDropdown from "components/dropdown/GroupSearchDropdown";
import { DataElement, FormGroup, FormRow, Switch } from "components/ui";
import { getResourceUrlNew } from "utils/common";

import sprinkles from "../../../css/sprinkles.css";
import { getGroupTypeInfo } from "../../label/GroupTypeLabel";
import GroupLabel from "../../label/item_labels/GroupLabel";
import { FormMode, OwnerSourceGroup } from "../common";

interface Props {
  mode: FormMode;
  sourceGroup: OwnerSourceGroup | undefined;
  enabledSourceGroup: boolean;

  onSourceGroupChange: (group: OwnerSourceGroup | undefined) => void;
  onEnabledChange: (enabled: boolean) => void;
}

const SourceGroupRow = (props: Props) => {
  const initSourceGroup = props.sourceGroup;

  const viewContent = props.sourceGroup?.id ? (
    <GroupLabel
      text={props.sourceGroup.name}
      icon={getGroupTypeInfo(props.sourceGroup.groupType)?.icon}
      groupId={props.sourceGroup.id}
    />
  ) : (
    "--"
  );

  const editContent = (
    <>
      <div className={sprinkles({ marginBottom: "sm" })}>
        Link a group to this owner to manage the list of users from a
        third-party source.
      </div>
      <div
        className={sprinkles({
          display: "flex",
          flexDirection: "column",
          gap: "md",
        })}
      >
        <Switch
          checked={props.enabledSourceGroup}
          onChange={(checked: boolean) => {
            if (!checked) {
              props.onSourceGroupChange(undefined);
            } else {
              props.onSourceGroupChange(initSourceGroup);
            }
            props.onEnabledChange(checked);
          }}
          label="Enable syncing user list from selected group"
        />
        {props.enabledSourceGroup && (
          <>
            <FormGroup label="Source group:">
              {props.sourceGroup ? (
                <DataElement
                  label={props.sourceGroup?.name}
                  color="teal"
                  leftIcon={
                    props.sourceGroup
                      ? {
                          data: {
                            entityType: props.sourceGroup?.groupType,
                            type: "entity",
                          },
                        }
                      : undefined
                  }
                  link={
                    props.sourceGroup?.id
                      ? getResourceUrlNew({
                          entityId: props.sourceGroup.id,
                          entityType: EntityType.Group,
                        })
                      : undefined
                  }
                  rightIcon={{
                    name: "x",
                    onClick: () => {
                      props.onSourceGroupChange(undefined);
                    },
                  }}
                />
              ) : (
                <GroupSearchDropdown
                  placeholder="Select source group..."
                  limitSelectedGroups={1}
                  selectedGroupIds={[]}
                  onSelect={(data) => {
                    switch (data.actionType) {
                      case "select-option":
                        props.onSourceGroupChange(data.groups[0]);
                        break;
                      case "remove-option":
                        props.onSourceGroupChange(undefined);
                        break;
                    }
                  }}
                  popperForceDownward
                />
              )}
            </FormGroup>
          </>
        )}
      </div>
    </>
  );

  return (
    <FormRow title="Source Group">
      {props.mode === "view" ? viewContent : editContent}
    </FormRow>
  );
};

export default SourceGroupRow;
