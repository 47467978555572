import OpalPage from "components/opal/layout/OpalPage";
import { Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import CustomRemediationScope, {
  isEmptyFilter,
  ScopeFilter,
  useScopeFilterState,
} from "views/recommendations/resolutions/CustomRemediationScope";
import CustomRemediationsTable from "views/recommendations/resolutions/CustomRemediationsTable";

const CustomRemediations = () => {
  const [scopeFilter, setScopeFilter] = useState<ScopeFilter>(
    useScopeFilterState()
  );
  let content = (
    <>
      <CustomRemediationScope filter={scopeFilter} setFilter={setScopeFilter} />
      {isEmptyFilter(scopeFilter) ? (
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            textAlign: "center",
            margin: "sm",
          })}
        >
          <Icon name="zap" size="xxxl" color="gray200" />
          <div style={{ color: "gray200", fontSize: "labelLg" }}>
            Add a scope above to get started!
          </div>
        </div>
      ) : (
        <CustomRemediationsTable
          scopeFilter={scopeFilter}
          setScopeFilter={setScopeFilter}
        />
      )}
    </>
  );

  return (
    <OpalPage title={"Custom Remediations"} icon="odometer">
      {content}
    </OpalPage>
  );
};

export default CustomRemediations;
