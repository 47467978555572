type CurrentUserGroupAccess = {
  groupUser?: {
    access?: {
      directAccessPoint?: {
        expiration?: string | null;
      } | null;
    } | null;
  } | null;
};

export function groupHasForfeitableRole(
  currentUserAccess: CurrentUserGroupAccess | undefined
): boolean {
  return !!currentUserAccess?.groupUser?.access?.directAccessPoint;
}
