import { ThirdPartyProvider } from "api/generated/graphql";
import { getLogoByThirdPartyProvider } from "components/label/Label";
import { Select } from "components/ui";
import _ from "lodash";
import { useContext } from "react";
import { isSupportTicket } from "utils/common";

import OrgContext from "../../views/settings/OrgContext";

interface TicketProviderDropdownProps {
  selectedTicketProvider?: string;
  onSelectTicketProvider: (ticketProvider?: ThirdPartyProvider) => void;
  clearable?: boolean;
  placeholder?: string;
  /* Hide specific ticket providers from the dropdown */
  hiddenProviders?: ThirdPartyProvider[];
}

const TicketProviderDropdown = (props: TicketProviderDropdownProps) => {
  const { orgState } = useContext(OrgContext);

  const integrations = orgState.orgThirdPartyIntegrations ?? [];
  const allTicketProviders =
    integrations
      .filter(isSupportTicket)
      .filter((integration) => {
        if (!props.hiddenProviders) {
          return true;
        }
        return !props.hiddenProviders.includes(integration.thirdPartyProvider);
      })
      .map((integration) => integration.thirdPartyProvider) || [];

  const selectedTicketProvider = allTicketProviders?.find(
    (o) => o === props.selectedTicketProvider
  );

  return (
    <Select
      options={allTicketProviders}
      getIcon={(ticketProvider) => {
        return {
          icon: getLogoByThirdPartyProvider(ticketProvider) ?? undefined,
          type: "src",
        };
      }}
      getOptionLabel={(ticketProvider) =>
        _.upperFirst(_.camelCase(ticketProvider))
      }
      value={selectedTicketProvider}
      onChange={props.onSelectTicketProvider}
      clearable={props.clearable}
      placeholder={props.placeholder}
    />
  );
};

export default TicketProviderDropdown;
