import { Button } from "components/ui";
import React from "react";

export type BulkAssignReviewersButtonsProps = {
  disabled: boolean;
  setShowReviewersModal: (value: boolean) => void;
  onCancel: () => void;
};

export const BulkAssignReviewersButtons = (
  props: BulkAssignReviewersButtonsProps
) => {
  return (
    <>
      <Button
        type="primary"
        label={"Assign"}
        leftIconName="edit-2"
        onClick={() => {
          props.setShowReviewersModal(true);
        }}
        disabled={props.disabled}
      />
      <Button label="Cancel" onClick={() => props.onCancel()} outline />
    </>
  );
};
