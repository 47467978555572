import { useEffect, useState } from "react";

// Adapted from: https://usehooks.com/useDebounce/

// Deprecated: useDebouncedValue from "utils/hooks" instead
function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay || 150);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);
  return debouncedValue;
}

export default useDebounce;
