import { Maybe, ResourcePreviewSmallFragment } from "api/generated/graphql";
import ResourceTypeLabel from "components/label/ResourceTypeLabel";
import { SectionEntry } from "components/viewer/Viewer";
import React from "react";

export const RESOURCE_SERVICE_TYPE_TITLE = "Resource type";

type ResourceTypeRowRowProps = {
  resource?: Maybe<ResourcePreviewSmallFragment>;
};

export const ResourceTypeRow = (props: ResourceTypeRowRowProps) => {
  return (
    <SectionEntry title={RESOURCE_SERVICE_TYPE_TITLE}>
      <div style={{ marginLeft: "6px" }}>
        <ResourceTypeLabel
          resourceType={props.resource?.resourceType}
          fullName={true}
        />
      </div>
    </SectionEntry>
  );
};

export default ResourceTypeRow;
