import {
  EntityType,
  GroupDropdownPreviewFragment,
  useGroupAccessLevelsQuery,
  useGroupsPreviewQuery,
  useResourceAccessLevelsQuery,
} from "api/generated/graphql";
import { ApprovalsEditor } from "components/approvals_editor/ApprovalsEditor";
import GroupSearchDropdown from "components/dropdown/GroupSearchDropdown";
import { RoleDropdown } from "components/dropdown/RoleDropdown";
import { FormMode, ResourceRequestConfig } from "components/forms/common";
import {
  Button,
  DataElement,
  DataElementList,
  Divider,
  Select,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";
import OrgContext from "views/settings/OrgContext";

import MaxDurationRow from "../MaxDurationRow";
import RecommendedDurationRow from "../RecommendedDurationRow";
import RequestTemplateRow from "../RequestTemplateRow";
import RequireMfaToRequestRow from "../RequireMfaToRequestRow";
import SupportTicketRequiredRow from "../SupportTicketRequiredRow";
import * as styles from "./RequestConfigRow.css";

interface Props {
  mode: FormMode;
  entityId?: string;
  entityType: EntityType;
  requestConfig: ResourceRequestConfig;
  onChange: (requestConfig: ResourceRequestConfig) => void;
  onDelete: () => void;
  isViewingAsNonAdmin: boolean;
}

const RequestConfigRow = (props: Props) => {
  const { entityId, mode, isViewingAsNonAdmin, entityType } = props;
  const {
    roleIds,
    groupIds,
    priority,
    isRequestable,
    autoApprove,
    reasonOptional,
    stageData,
    maxDurationMin,
    recommendedDurationMin,
    requireSupportTicket,
    requestTemplateId,
    requireMfaToRequest,
  } = props.requestConfig;
  const { orgState } = useContext(OrgContext);

  const { data: groupData, error: groupError } = useGroupsPreviewQuery({
    variables: {
      input: {
        groupIds: groupIds,
      },
    },
    skip: !groupIds,
  });

  const {
    data: resourceRolesData,
    error: resourceRolesError,
  } = useResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceId: props.entityId ?? "",
      },
    },
    skip: !props.entityId || props.entityType !== EntityType.Resource,
  });

  const {
    data: groupRolesData,
    error: groupRolesError,
  } = useGroupAccessLevelsQuery({
    variables: {
      input: {
        groupId: props.entityId ?? "",
      },
    },
    skip: !props.entityId || props.entityType !== EntityType.Group,
  });

  let roles: {
    accessLevelRemoteId: string;
    accessLevelName: string;
  }[] = [];

  if (resourceRolesData) {
    switch (resourceRolesData.accessLevels.__typename) {
      case "ResourceAccessLevelsResult": {
        roles = resourceRolesData.accessLevels.accessLevels ?? [];
        break;
      }
      case "ResourceNotFoundError":
        logError(resourceRolesError, `failed to list resource roles`);
        break;
    }
  } else if (resourceRolesError) {
    logError(resourceRolesError, `failed to list resource roles`);
  }

  if (groupRolesData) {
    switch (groupRolesData.groupAccessLevels.__typename) {
      case "GroupAccessLevelsResult": {
        roles = groupRolesData.groupAccessLevels.accessLevels ?? [];
        break;
      }
      case "GroupNotFoundError":
        logError(groupRolesError, `failed to list group roles`);
        break;
    }
  } else if (groupRolesError) {
    logError(groupRolesError, `failed to list group roles`);
  }

  if (groupError) {
    return null;
  }

  let groups: GroupDropdownPreviewFragment[] | undefined;
  if (groupData?.groups.__typename === "GroupsResult") {
    groups = groupData.groups.groups;
  }

  const isDefault = priority === 0;

  const handleChange = (key: keyof ResourceRequestConfig) => (
    val: ResourceRequestConfig[keyof ResourceRequestConfig]
  ) => {
    props.onChange({
      ...props.requestConfig,
      [key]: val,
    });
  };

  const isConfigurationTemplate = !entityId;

  return (
    <div className={sprinkles({ marginTop: "lg" })}>
      {mode === "edit" && (
        <>
          <div className={styles.conditionSelectionsContainer}>
            <div className={styles.sectionHeader}>Requesting groups</div>
            <div className={sprinkles({ marginTop: "md", marginBottom: "sm" })}>
              {isDefault
                ? "This is the default policy. It will apply to all users in your org."
                : "Select the groups you want this configuration to apply to:"}
            </div>
            {isDefault ? (
              <Select
                value="Everyone"
                onChange={() => {}}
                options={["Everyone"]}
                getOptionLabel={(opt) => opt}
                getIcon={() => ({ type: "name", icon: "users" })}
                disabled
              />
            ) : (
              <>
                <GroupSearchDropdown
                  selectedGroupIds={groupIds ?? []}
                  placeholder="Add Groups"
                  onSelect={(data) => {
                    switch (data.actionType) {
                      case "select-option":
                        handleChange("groupIds")([
                          ...(groupIds ?? []),
                          data.groups[0].id,
                        ]);
                        break;
                      case "remove-option":
                        handleChange("groupIds")(
                          (groupIds ?? []).filter(
                            (groupId) => groupId !== data.groups[0].id
                          )
                        );
                        break;
                    }
                  }}
                />
                <div className={sprinkles({ marginTop: "sm" })}>
                  <DataElementList>
                    {groups?.map((group) => (
                      <DataElement
                        label={group?.name}
                        color="green"
                        leftIcon={
                          group
                            ? {
                                data: {
                                  entityType: group?.groupType,
                                  type: "entity",
                                },
                              }
                            : undefined
                        }
                        link={
                          group?.id
                            ? getResourceUrlNew({
                                entityId: group.id,
                                entityType: EntityType.Group,
                              })
                            : undefined
                        }
                        rightIcon={{
                          name: "x",
                          onClick: () => {
                            handleChange("groupIds")(
                              groupIds?.filter((g) => g !== group?.id)
                            );
                          },
                        }}
                      />
                    ))}
                  </DataElementList>
                </div>
              </>
            )}
          </div>
          {!isConfigurationTemplate ? (
            <div className={styles.conditionSelectionsContainer}>
              <div className={styles.sectionHeader}>Roles</div>
              <div
                className={sprinkles({ marginTop: "md", marginBottom: "sm" })}
              >
                {isDefault
                  ? "This is the default policy. It will apply to all roles."
                  : "Select the roles you want this configuration to apply to:"}
              </div>
              {isDefault ? (
                <Select
                  value="All"
                  onChange={() => {}}
                  options={["All"]}
                  getOptionLabel={(opt) => opt}
                  getIcon={() => ({ type: "name", icon: "users" })}
                  disabled
                />
              ) : (
                <>
                  <RoleDropdown
                    groupId={
                      entityType === EntityType.Group ? entityId : undefined
                    }
                    resourceId={
                      entityType === EntityType.Resource ? entityId : undefined
                    }
                    onChange={(role) => {
                      role &&
                        !roleIds?.includes(role.accessLevelRemoteId) &&
                        handleChange("roleIds")([
                          ...(roleIds ?? []),
                          role.accessLevelRemoteId,
                        ]);
                    }}
                    placeholder="Add roles"
                    clearable={false}
                    selectOnly
                  />
                  <div className={sprinkles({ marginTop: "sm" })}>
                    <DataElementList>
                      {roleIds?.map((roleId) => (
                        <DataElement
                          label={
                            roles.find((r) => r.accessLevelRemoteId === roleId)
                              ?.accessLevelName ?? roleId
                          }
                          leftIcon={{
                            name: "role",
                          }}
                          color="green"
                          rightIcon={{
                            name: "x",
                            onClick: () => {
                              handleChange("roleIds")(
                                roleIds?.filter((r) => r !== roleId)
                              );
                            },
                          }}
                        />
                      ))}
                    </DataElementList>
                  </div>
                </>
              )}
            </div>
          ) : null}
        </>
      )}
      <div className={styles.sectionHeader}>Approval Flow</div>
      <div className={sprinkles({ marginTop: "md", marginBottom: "md" })}>
        <ApprovalsEditor
          showEdit={mode == "edit"}
          onChange={(isRequestable, autoApprove, reasonOptional, stageData) => {
            props.onChange({
              ...props.requestConfig,
              isRequestable,
              // Disable auto approval and reason optional if not requestable
              autoApprove: !isRequestable ? false : autoApprove,
              reasonOptional: !isRequestable ? false : reasonOptional,
              // Clear stage data if not requestable or auto approval is enabled
              stageData: !isRequestable || autoApprove ? [] : stageData,
            });
          }}
          isRequestable={isRequestable}
          reasonOptional={reasonOptional}
          autoApprove={autoApprove}
          stageData={stageData}
        />
      </div>
      {!isViewingAsNonAdmin && isRequestable ? (
        <>
          <Divider />
          <div className={styles.sectionHeader}>Request Information</div>
          <RequireMfaToRequestRow
            mode={mode}
            requireMfa={requireMfaToRequest}
            onChange={handleChange("requireMfaToRequest")}
          />
          <MaxDurationRow
            mode={mode}
            maxDurationMin={maxDurationMin}
            orgMaxDuration={
              (entityType === EntityType.Resource
                ? orgState.orgSettings?.maxResourceDurationInMinutes
                : orgState.orgSettings?.maxGroupDurationInMinutes) ?? undefined
            }
            onChange={handleChange("maxDurationMin")}
          />
          <RecommendedDurationRow
            mode={mode}
            recommendedDurationMin={recommendedDurationMin}
            onChange={handleChange("recommendedDurationMin")}
          />
          <SupportTicketRequiredRow
            mode={mode}
            requireSupportTicket={requireSupportTicket}
            onChange={handleChange("requireSupportTicket")}
          />
          <RequestTemplateRow
            mode={mode}
            requestTemplateId={requestTemplateId}
            onChange={handleChange("requestTemplateId")}
          />
        </>
      ) : null}
      {mode === "edit" && (
        <div className={sprinkles({ marginTop: "lg" })}>
          <Button
            label="Delete configuration"
            leftIconName="trash"
            type="error"
            borderless
            disabled={isDefault}
            onClick={props.onDelete}
          />
          {isDefault && (
            <div className={sprinkles({ color: "red1000", marginTop: "sm" })}>
              The default configuration cannot be deleted.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default RequestConfigRow;
