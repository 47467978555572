import {
  EntityType,
  SortDirection,
  TagsSortByField,
  useTagsQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import ColumnListItem from "components/column/ColumnListItem";
import ColumnListScroller from "components/column/ColumnListScroller";
import { ColumnSearchAndSort } from "components/column/ColumnSearchAndSort";
import { Divider } from "components/ui";
import { useState } from "react";
import { useContext } from "react";
import { useHistory, useParams } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";

import TagCreateModal from "./TagCreateModal";
import TagDetailColumn from "./TagDetailColumn";

const SORT_OPTIONS = [
  {
    label: "Key (A-Z)",
    value: {
      field: TagsSortByField.Key,
      direction: SortDirection.Asc,
    },
  },
  {
    label: "Key (Z-A)",
    value: {
      field: TagsSortByField.Key,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Newest first",
    value: {
      field: TagsSortByField.CreatedAt,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Oldest first",
    value: {
      field: TagsSortByField.CreatedAt,
      direction: SortDirection.Asc,
    },
  },
];

const TagsColumn = () => {
  const history = useHistory();
  const { tagId } = useParams<Record<string, string>>();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);

  const { authState } = useContext(AuthContext);
  const organizationName = authState.user?.user.organization.name;

  const { data, error, loading, fetchMore } = useTagsQuery({
    variables: {
      input: {
        limit: 100,
        searchQuery: searchQuery.length > 0 ? searchQuery : undefined,
        sortBy: sortBy.value,
      },
    },
  });

  if (error) {
    logError(error, `failed to list tags`);
  }

  const cursor = data?.tags.cursor;
  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            input: {
              limit: 100,
              cursor,
              searchQuery: searchQuery.length > 0 ? searchQuery : undefined,
              sortBy: sortBy.value,
            },
          },
        });
      }
    : undefined;

  const tags = data?.tags.tags ?? [];

  const addMenuOptions: PropsFor<typeof ColumnHeader>["addMenuOptions"] = [];
  if (authState.user?.isAdmin) {
    addMenuOptions.push({
      label: "Create tag",
      onClick: () => setShowCreateModal(true),
      icon: { type: "name", icon: "plus" },
    });
  }

  return (
    <>
      <Column>
        <ColumnHeader
          title="Tags"
          subtitle={organizationName}
          icon={{ type: "name", icon: "tag" }}
          addMenuOptions={addMenuOptions}
        />
        <Divider margin="md" />
        <ColumnSearchAndSort
          key="search"
          setSearchQuery={setSearchQuery}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortOptions={SORT_OPTIONS}
          placeholder="Search tags"
          trackName="tags"
        />
        <ColumnListScroller
          renderRow={(index) => {
            const tag = tags[index];
            if (!tag) return <></>;

            return (
              <ColumnListItem
                key={tag.id}
                label={tag.key}
                sublabel={tag.value ?? undefined}
                selected={tag.id === tagId}
                onClick={() => {
                  history.push(
                    getResourceUrlNew({
                      entityId: tag.id,
                      entityType: EntityType.Tag,
                    })
                  );
                }}
              />
            );
          }}
          numRows={tags.length}
          emptyState={{ title: "No tags to display." }}
          onLoadMore={loadMoreRows}
          hasNextPage={Boolean(cursor)}
          loading={loading}
        />
      </Column>
      {tagId ? <TagDetailColumn /> : null}
      {showCreateModal ? (
        <TagCreateModal onClose={() => setShowCreateModal(false)} />
      ) : null}
    </>
  );
};

export default TagsColumn;
