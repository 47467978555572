import {
  CreateResourceCustomAccessLevelInput,
  ResourceAccessLevelFragment,
  ResourceImportRolesQuery,
  ResourcePreviewWithRolesFragment,
  useCreateResourceCustomAccessLevelsMutation,
  useResourceAccessLevelsQuery,
  useResourceImportRolesQuery,
} from "api/generated/graphql";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import FullscreenViewTitle from "components/fullscreen_modals/FullscreenViewTitle";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import { useToast } from "components/toast/Toast";
import { Banner, ButtonV3, Divider, Icon, Input } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import pluralize from "pluralize";
import { useState } from "react";
import { useParams } from "react-router";
import { useImmer } from "use-immer";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import { canImportCustomRoles } from "utils/directory/resources";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import {
  ForbiddenPage,
  NotFoundPage,
  UnexpectedErrorPage,
} from "views/error/ErrorCodePage";
import { dropNothings } from "views/utils";

import * as styles from "./ResourceImportRolesView.css";

type RoleImportRow = {
  accessLevelRemoteId: string;
  accessLevelName: string;
};

const COLUMNS: Header<RoleImportRow>[] = [
  {
    id: "accessLevelName",
    label: "Role Name",
  },
  {
    id: "accessLevelRemoteId",
    label: "Remote ID",
  },
];

const ResourceImportRolesView = () => {
  const transitionBack = useTransitionBack();
  const logEvent = useLogEvent();
  const { resourceId } = useParams<{ resourceId: string }>();
  const { displaySuccessToast } = useToast();

  const [rolesToImportById, setRolesToImportById] = useImmer<
    Partial<Record<string, RoleImportRow>>
  >({});
  const [errorMessage, setErrorMessage] = useState("");

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);

  const [
    createResourceCustomAccessLevels,
    { loading: importRolesLoading },
  ] = useCreateResourceCustomAccessLevelsMutation();
  const {
    data: resourceData,
    previousData,
    loading: resourceLoading,
    error: resourceError,
  } = useResourceImportRolesQuery({
    variables: {
      resourceId,
      skipRemoteAccessLevels: true,
    },
    fetchPolicy: "cache-and-network",
  });
  const {
    data: remoteRolesData,
    error,
    loading,
  } = useResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceId,
        skipCustomAccessLevels: true,
      },
    },
  });

  let roles: ResourceAccessLevelFragment[] = [];
  let resource: ResourcePreviewWithRolesFragment | null = null;

  // fetch roles
  if (remoteRolesData) {
    switch (remoteRolesData.accessLevels.__typename) {
      case "ResourceAccessLevelsResult": {
        roles = remoteRolesData.accessLevels.accessLevels;
        break;
      }
      case "ResourceNotFoundError":
        logError(new Error(`Error: failed to list resource roles`));
        break;
    }
  } else if (error) {
    logError(error, `failed to list resource roles`);
  }

  const handleClose = () => {
    transitionBack(`/resources/${resourceId}#roles`);
  };
  const getResource = (data: ResourceImportRolesQuery | undefined) => {
    switch (data?.resource.__typename) {
      case "ResourceResult":
        return data?.resource.resource;
      case "ResourceNotFoundError":
        logError(new Error(`Error: failed to load resource`));
        break;
    }
  };
  resource = getResource(resourceData) ?? getResource(previousData) ?? null;
  const canImportRoles =
    resource &&
    canImportCustomRoles(
      resource.serviceType,
      resource.connection?.connectionType
    );

  if (resourceLoading && !resource) {
    return <FullscreenSkeleton />;
  }
  if (!resource?.authorizedActions?.includes(AuthorizedActionManage)) {
    return <ForbiddenPage />;
  }
  if (
    resourceData?.resource.__typename === "ResourceNotFoundError" ||
    resourceError
  ) {
    return <NotFoundPage />;
  }
  if (!resource || error || resourceError || !canImportRoles) {
    return <UnexpectedErrorPage error={error} />;
  }

  const rolesAlreadyImportedById = _.keyBy(
    resource.accessLevels,
    "accessLevelRemoteId"
  );

  const rows = roles
    .filter((role) => {
      if (!debouncedSearchQuery) {
        return true;
      }
      return (
        role.accessLevelName.toLowerCase().includes(debouncedSearchQuery) ||
        role.accessLevelRemoteId.toLowerCase().includes(debouncedSearchQuery)
      );
    })
    .filter((role) => {
      return !rolesAlreadyImportedById[role.accessLevelRemoteId];
    })
    .map((role) => ({
      accessLevelName: role.accessLevelName,
      accessLevelRemoteId: role.accessLevelRemoteId,
    }));

  const handleImportRoles = async () => {
    if (resource == null) {
      return logError(
        new Error(
          `Error: failed to import resource custom roles, missing resource`
        )
      );
    }
    logEvent({
      name: "apps_import_role",
      properties: {
        resourceType: resource.resourceType,
      },
    });
    const createInfos: CreateResourceCustomAccessLevelInput[] = dropNothings(
      Object.keys(rolesToImportById).map((id) => {
        const role = rolesToImportById[id];
        if (!role) {
          return null;
        }
        return {
          resourceId: resourceId,
          accessLevel: {
            // since this is an input, we can't pass excess properties or GQL might complain
            accessLevelName: role.accessLevelName,
            accessLevelRemoteId: role.accessLevelRemoteId,
          },
        };
      })
    );
    try {
      const { data } = await createResourceCustomAccessLevels({
        variables: {
          input: {
            createInfos: createInfos,
          },
        },
        refetchQueries: ["ResourceDetailView", "ResourceCustomAccessLevels"],
      });
      switch (data?.createResourceCustomAccessLevels.__typename) {
        case "CreateResourceCustomAccessLevelsResult":
          displaySuccessToast("Success: resource custom roles imported");
          handleClose();
          break;
        default:
          logError(new Error(`failed to import resource custom roles`));
          setErrorMessage(`Error: failed to import resource custom roles`);
      }
    } catch (error) {
      logError(error, `failed to import resource custom roles`);
      setErrorMessage(`Error: failed to import resource custom roles`);
    }
  };

  const rolesByRemoteId = _.keyBy(roles, "accessLevelRemoteId");
  const numRolesToImport: number = Object.keys(rolesToImportById).length ?? 0;

  return (
    <FullscreenView
      title={
        <FullscreenViewTitle
          entityType={resource.resourceType}
          entityName={resource.name}
          targetEntityName="roles"
          action="import"
        />
      }
      onCancel={handleClose}
      onPrimaryButtonClick={handleImportRoles}
      primaryButtonLabel={`Import ${
        numRolesToImport > 0 ? numRolesToImport : ""
      } ${pluralize("role", numRolesToImport)}`}
      primaryButtonDisabled={numRolesToImport === 0 || importRolesLoading}
      primaryButtonLoading={importRolesLoading}
    >
      <FullscreenView.Content fullWidth>
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textMd",
              fontWeight: "medium",
              marginBottom: "md",
            })}
          >
            Select roles to import to the resource:
          </div>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder="Filter by name or ID"
              autoFocus
            />
          </div>
          <Divider />
          {loading ? (
            <ColumnListItemsSkeleton />
          ) : (
            <Table
              columns={COLUMNS}
              rows={rows}
              totalNumRows={rows.length ?? 0}
              getRowId={(role) => role.accessLevelRemoteId}
              checkedRowIds={new Set(Object.keys(rolesToImportById))}
              onCheckedRowsChange={(checkedRowIds, checked) => {
                if (checked) {
                  setRolesToImportById((draft) => {
                    for (const id of checkedRowIds) {
                      const role = rolesByRemoteId[id];
                      if (!role) {
                        continue;
                      }
                      draft[id] = role;
                    }
                  });
                } else {
                  setRolesToImportById((draft) => {
                    for (const id of checkedRowIds) {
                      delete draft[id];
                    }
                  });
                }
              }}
              onRowClick={(role) => {
                if (rolesToImportById[role.accessLevelRemoteId]) {
                  setRolesToImportById((draft) => {
                    delete draft[role.accessLevelRemoteId];
                  });
                } else {
                  setRolesToImportById((draft) => {
                    draft[role.accessLevelRemoteId] = role;
                  });
                }
              }}
              defaultSortBy={"accessLevelName"}
            />
          )}
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {errorMessage && (
          <Banner message={errorMessage} type="error" marginBottom="lg" />
        )}
        <div
          className={sprinkles({
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "lg",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textLg",
              fontWeight: "medium",
              marginBottom: "lg",
            })}
          >
            Adding {numRolesToImport} {pluralize("Role", numRolesToImport)}
          </div>

          {numRolesToImport > 0 && (
            <ButtonV3
              leftIconName="x"
              label="Clear all"
              size="xs"
              type="dangerBorderless"
              onClick={() => setRolesToImportById({})}
            />
          )}
        </div>
        {Object.keys(rolesToImportById).map((roleRemoteId) => {
          const role = rolesByRemoteId[roleRemoteId];
          if (!role) {
            return null;
          }

          return (
            <div key={roleRemoteId} className={styles.roleCard}>
              <div
                className={sprinkles({
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "sm",
                  flexGrow: 1,
                })}
              >
                <div className={styles.userInfoSection}>
                  <div className={styles.roleCardHeader}>
                    {role.accessLevelName}
                  </div>
                  <div className={styles.roleCardSubtitle}>
                    {role.accessLevelRemoteId}
                  </div>
                </div>
                <div
                  className={sprinkles({ flexShrink: 0, marginLeft: "auto" })}
                >
                  <Icon
                    name="trash"
                    color="red600V3"
                    onClick={() => {
                      setRolesToImportById((draft) => {
                        delete draft[role.accessLevelRemoteId];
                      });
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};

export default ResourceImportRolesView;
