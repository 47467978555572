import { EntityIcon, Icon, Label } from "components/ui";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";

import * as styles from "./CatalogLabel.css";

type Props = {
  icon?: IconData;
  name: string;
  breadcrumb?: string;
  shortenedBreadcrumb?: string;
};

const CatalogLabel = (props: Props) => {
  const breadcrumb = props.shortenedBreadcrumb ?? props.breadcrumb;
  const breadcrumbTooltip =
    props.shortenedBreadcrumb && props.breadcrumb !== breadcrumb
      ? props.breadcrumb
      : undefined;
  return (
    <div className={styles.name}>
      {props.icon?.type === "entity" ? (
        <EntityIcon
          type={props.icon.entityType}
          size="lgr"
          onlyBrandIcon={props.icon.onlyBrandIcon}
        />
      ) : props.icon ? (
        <Icon data={props.icon} size="lgr" />
      ) : null}
      <div
        className={sprinkles({
          display: "flex",
          flexDirection: "column",
          textOverflow: "ellipsis",
          overflow: "hidden",
          whiteSpace: "nowrap",
        })}
      >
        <div className={styles.text({ bold: true })}>
          <Label label={props.name} truncateLength={null} oneLine />
        </div>
        {breadcrumb && (
          <Label
            label={breadcrumb}
            color="gray600"
            oneLine
            truncateLength={null}
            tooltipText={breadcrumbTooltip}
          />
        )}
      </div>
    </div>
  );
};

export default CatalogLabel;
