import { EntityType } from "api/generated/graphql";
import ConfigurationTemplateDropdown from "components/configuration_templates/ConfigurationTemplateDropdown";
import { FormRow } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import React from "react";
import { Link } from "react-router-dom";
import { getResourceUrlNew } from "utils/common";
import { AppsContext } from "views/apps/AppsContext";

import { FormMode, ResourceConfig } from "../common";

interface Props {
  mode: FormMode;
  configurationTemplate?: ResourceConfig["configurationTemplate"];
  onChange: (configurationTemplate?: { name: string; id: string }) => void;
  clearable?: boolean;
  placeholder?: string;
}

const ChildrenDefaultConfigTemplateRow = (props: Props) => {
  const { bulkMode } = useContext(AppsContext);

  const viewContent = props.configurationTemplate?.id ? (
    <Link
      to={getResourceUrlNew(
        {
          entityId: props.configurationTemplate.id,
          entityType: EntityType.ConfigurationTemplate,
        },
        EntityType.ConfigurationTemplate
      )}
    >
      <span className={sprinkles({ fontWeight: "semibold" })}>
        {props.configurationTemplate.name}
      </span>
    </Link>
  ) : (
    "--"
  );

  const editContent = (
    <ConfigurationTemplateDropdown
      selectedConfigurationTemplateId={props.configurationTemplate?.id}
      onSelectConfigurationTemplate={props.onChange}
      clearable={true}
      placeholder={
        props.placeholder ?? (bulkMode === "edit" ? "Leave unchanged" : "None")
      }
    />
  );

  return (
    <FormRow title="Auto-Import Configuration Template">
      <p>
        Children imported through sync auto-import will be assigned this
        configuration by default.
      </p>
      {props.mode === "view" ? viewContent : editContent}
    </FormRow>
  );
};

export default ChildrenDefaultConfigTemplateRow;
