import {
  AccessReviewConnectionFragment,
  EntityType,
} from "api/generated/graphql";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import { OnlyMyReviewsFilter } from "components/only_mine_filter/OnlyMineFilter";
import React from "react";
import styles from "views/access_reviews/common/AccessReviewTableStyles.module.scss";
import AccessReviewConnectionUsersTable from "views/access_reviews/connections/AccessReviewConnectionUsersTable";

type AccessReviewConnectionUsersRowProps = {
  accessReviewConnection: AccessReviewConnectionFragment;
  selectedRows?: Record<string, EntityType>;
  setSelectedRows?: (value: Record<string, EntityType>) => void;
};

export const AccessReviewConnectionUsersRow = (
  props: AccessReviewConnectionUsersRowProps
) => {
  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableTopContainer}>
        <div className={styles.tableTopLeftContent}>
          <OnlyMyReviewsFilter />
        </div>
      </div>
      <EntityViewerRow
        title={"Users"}
        content={
          <AccessReviewConnectionUsersTable
            accessReviewConnection={props.accessReviewConnection}
            selectedRows={props.selectedRows}
            setSelectedRows={props.setSelectedRows}
          />
        }
        isTable={true}
      />
    </div>
  );
};

export default AccessReviewConnectionUsersRow;
