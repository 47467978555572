import { getModifiedErrorMessage } from "api/ApiContext";
import { useUpdateOrganizationSettingsMutation } from "api/generated/graphql";
import clsx from "clsx";
import AuthContext from "components/auth/AuthContext";
import { MoreInfo } from "components/more_info/MoreInfo";
import AccessRequestDurationPicker from "components/requests/AccessRequestDurationPicker";
import { useToast } from "components/toast/Toast";
import { Modal, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import { useContext, useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import OrgContext, { OrgContextActionType } from "views/settings/OrgContext";
import styles from "views/settings/OrgSettings.module.scss";
import * as stylesV3 from "views/settings/OrgSettingsV3.css";

// This is a special expiration date we use to represent "customer support should be on indefinitely"
// It should stay consistent with the defaults defined in the backend & db for this field
export const CustomerSupportIndefiniteDate = new Date("9999-12-31 23:59:59+00");

// The customer support toggle should show as 'disabled' if 'customerSupportExpiresAt' is in the past
const isCustomerSupportDisabled = (customerSupportExpiresAt: string) => {
  return new Date(customerSupportExpiresAt) < new Date();
};

const isCustomerSupportOnIndefinitely = (customerSupportExpiresAt: string) => {
  return new Date(customerSupportExpiresAt) >= CustomerSupportIndefiniteDate;
};

type CustomerSupportToggleProps = {
  currentCustomerSupportExpiresAt: string;
};

export const CustomerSupportToggleSetting = (
  props: CustomerSupportToggleProps
) => {
  const defaultDurationInMinutes = 24 * 60; // 24 hours
  const [durationInMinutes, setDurationInMinutes] = useState(
    defaultDurationInMinutes
  );
  const [showModal, setShowModal] = useState(false);
  const { authState } = useContext(AuthContext);
  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();
  const [updateOrgSettings] = useUpdateOrganizationSettingsMutation();
  const { orgDispatch } = useContext(OrgContext);

  const modalReset = () => {
    setShowModal(false);
  };

  const isSwitchOff = isCustomerSupportDisabled(
    props.currentCustomerSupportExpiresAt
  );

  const handleUpdateOrgSettings = async (
    newExpiration: Date,
    switchingOn: boolean
  ) => {
    const verbForMessages = switchingOn ? "activate" : "deactivate";

    try {
      displayLoadingToast();
      const { data } = await updateOrgSettings({
        variables: {
          input: {
            customerSupportExpiresAt: newExpiration.toISOString(),
          },
        },
      });
      switch (data?.updateOrganizationSettings.__typename) {
        case "UpdateOrganizationSettingsResult":
          orgDispatch({
            type: OrgContextActionType.OrgSettings,
            payload: {
              orgSettings: data.updateOrganizationSettings.settings,
            },
          });

          displaySuccessToast(
            `Success: Opal Customer Support Access ${verbForMessages}d`
          );
          return true;
        case "CustomerSupportSettingsInvalid":
          logError(
            data.updateOrganizationSettings.message,
            "failed to update customer support org setting"
          );
          displayErrorToast(
            `Error: failed to ${verbForMessages} Opal Customer Support Access: ${data.updateOrganizationSettings.message}`
          );
          return false;
        default:
          logError(new Error(`failed to update customer support org setting`));
          displayErrorToast(
            `Error: failed to ${verbForMessages} Opal Customer Support Access`
          );
      }
    } catch (error) {
      logError(error, "failed to update customer support org setting");
      displayErrorToast(
        getModifiedErrorMessage(
          `Error: failed to ${verbForMessages} Opal Customer Support Access`,
          error
        )
      );
      return false;
    }
  };

  let expirationText = "";
  if (
    props.currentCustomerSupportExpiresAt &&
    !isCustomerSupportOnIndefinitely(props.currentCustomerSupportExpiresAt) &&
    !isCustomerSupportDisabled(props.currentCustomerSupportExpiresAt)
  ) {
    expirationText = moment(
      new Date(props.currentCustomerSupportExpiresAt)
    ).format("[Expires at] h:mma [on] M/D/YYYY");
  }

  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const settingsPanel = hasV3 ? (
    <div className={stylesV3.switchesHeader}>
      <div className={sprinkles({ display: "flex", flexDirection: "column" })}>
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "row",
          })}
        >
          <div className={stylesV3.label}>
            {"Enable Opal Customer Support Read Only Access"}
          </div>
          <MoreInfo
            tooltipText={
              "When on, allows Opal's support team to perform read-only impersonations of users in this organization in order to provide customer support."
            }
          />
        </div>
        <div
          className={sprinkles({
            fontSize: "textXs",
            fontWeight: "normal",
            color: "gray800",
            marginLeft: "md",
          })}
        >
          {expirationText}
        </div>
      </div>
      <div className={stylesV3.switches}>
        <Switch
          disabled={!authState.user?.isAdmin}
          checked={!isSwitchOff}
          onChange={async () => {
            if (isSwitchOff) {
              // If the switch was off, they're turning it on, so we display the modal that lets them set the expiration
              setShowModal(true);
            } else {
              // If the switch was on, they're just turning it off.
              // We 'turn off' impersonation by setting the expiration to now
              const newExpiration = new Date();
              await handleUpdateOrgSettings(newExpiration, false);
            }
          }}
        />
      </div>
    </div>
  ) : (
    <div className={styles.switchesHeader}>
      <div className={styles.switches}>
        <div
          className={clsx({
            [styles.switch]: true,
            [styles.off]: isSwitchOff,
          })}
        >
          <Switch
            disabled={!authState.user?.isAdmin}
            checked={!isSwitchOff}
            onChange={async () => {
              if (isSwitchOff) {
                // If the switch was off, they're turning it on, so we display the modal that lets them set the expiration
                setShowModal(true);
              } else {
                // If the switch was on, they're just turning it off.
                // We 'turn off' impersonation by setting the expiration to now
                const newExpiration = new Date();
                await handleUpdateOrgSettings(newExpiration, false);
              }
            }}
          />
        </div>
      </div>
      <div className={sprinkles({ display: "flex", flexDirection: "column" })}>
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "row",
          })}
        >
          <div className={styles.label}>
            {"Enable Opal Customer Support Read Only Access"}
          </div>
          <MoreInfo
            tooltipText={
              "When on, allows Opal's support team to perform read-only impersonations of users in this organization in order to provide customer support."
            }
          />
        </div>
        <div
          className={sprinkles({
            fontSize: "textXs",
            fontWeight: "normal",
            color: "gray800",
            marginLeft: "md",
          })}
        >
          {expirationText}
        </div>
      </div>
    </div>
  );

  const handleClickSave = async () => {
    let newExpiration;
    // A duration of -1 corresponds to 'indefinite', so use our special indefinite date
    if (durationInMinutes === -1) {
      newExpiration = CustomerSupportIndefiniteDate;
    } else {
      newExpiration = new Date(
        // If they provide an extremely large duration, we clamp it to the special indefinite date
        Math.min(
          CustomerSupportIndefiniteDate.valueOf(),
          new Date().valueOf() + durationInMinutes * 60 * 1000
        )
      );
    }
    const success = await handleUpdateOrgSettings(newExpiration, true);
    if (success) {
      modalReset();
    }
  };

  const modal = (
    <Modal
      isOpen={showModal}
      title="Enable Opal Customer Support Read Only Access"
      onClose={() => modalReset()}
      fullWidth={true}
    >
      <Modal.Body>
        <AccessRequestDurationPicker
          label="How long would you like Opal Customer Support enabled?"
          defaultDurationInMinutes={defaultDurationInMinutes}
          durationOptions={[
            { durationInMinutes: 1440, label: "1 day" },
            { durationInMinutes: 2880, label: "2 days" },
            { durationInMinutes: 10080, label: "1 week" },
            { durationInMinutes: -1, label: "Indefinite" },
          ]}
          durationInMinutes={durationInMinutes}
          setDurationInMinutes={setDurationInMinutes}
        ></AccessRequestDurationPicker>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Save"
        onPrimaryButtonClick={handleClickSave}
        primaryButtonLoading={false}
        primaryButtonDisabled={false}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={modalReset}
      />
    </Modal>
  );

  return (
    <div>
      {settingsPanel}
      {modal}
    </div>
  );
};
