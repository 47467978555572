import { IdpConnectionUserAttributeUseAs } from "api/generated/graphql";

const useAsOptionLabels: Record<IdpConnectionUserAttributeUseAs, string> = {
  CUSTOM: "Custom attribute",
  EMAIL: "Email",
  FIRST_NAME: "First name",
  LAST_NAME: "Last name",
  PROFILE_URL: "Profile image URL",
  TITLE: "Title",
  TEAM: "Team",
  MANAGER_EMAIL: "Manager email",
  SECONDARY_EMAIL: "Secondary email",
  GITHUB_USERNAME: "GitHub username",
  TELEPORT_USERNAME: "Teleport username",
};

export const getUserAttributeUseAsOptionLabel = (
  useAs: IdpConnectionUserAttributeUseAs
): string => {
  return useAsOptionLabels[useAs];
};
