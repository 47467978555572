import { gql, useFragment } from "@apollo/client";
import { OpalAppSourceCellFragment } from "api/generated/graphql";
import { Skeleton } from "components/ui";

const OpalAppSourceCell = (props: { appID: string }) => {
  // connectId and resourceId are used for cacheID resolution so we need to fetch them
  const { complete, data } = useFragment<OpalAppSourceCellFragment>({
    fragment: gql`
      fragment OpalAppSourceCell on App {
        app {
          __typename
          ... on ConnectionApp {
            connectionId
          }
          ... on OktaResourceApp {
            resourceId
          }
        }
      }
    `,
    fragmentName: "OpalAppSourceCell",
    from: {
      __typename: "App",
      id: props.appID,
    },
  });

  return complete ? (
    <span>{data.app.__typename === "OktaResourceApp" ? "Okta" : "Native"}</span>
  ) : (
    <Skeleton variant="text" width="50px" />
  );
};

export default OpalAppSourceCell;
