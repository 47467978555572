import {
  EntityType,
  GroupTagFragment,
  TagPreviewLargeFragment,
} from "api/generated/graphql";
import { GroupTableCellResourceLabel } from "components/label/GroupTableCellResourceLabel";
import ConnectionLabel from "components/label/item_labels/ConnectionLabel";
import ServiceTypeLabel from "components/label/ServiceTypeLabel";
import MaterialTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MaterialTable";
import moment from "moment";
import { useHistory } from "react-router";
import { getResourceUrlNew } from "utils/common";

type TagGroupTableRow = {
  group: string;
  connection: string;
  tagSource: string;
  createdAt: string;
};

type TagGroupsTableProps = {
  tag: TagPreviewLargeFragment;
  tagGroups: GroupTagFragment[];
};

const TagGroupsTable = (props: TagGroupsTableProps) => {
  const history = useHistory();

  const headers: Header<TagGroupTableRow>[] = [
    { id: "group", label: "Group" },
    { id: "connection", label: "App" },
    { id: "tagSource", label: "Tag source" },
    { id: "createdAt", label: "Created at" },
  ];

  const rows: CellRow<TagGroupTableRow>[] = props.tagGroups.map(
    (tagGroup, i) => {
      const createdAtTime = moment(new Date(tagGroup.createdAt));
      const group = tagGroup.group;
      const connection = tagGroup.group?.connection;

      return {
        id: tagGroup.groupId,
        data: {
          group: {
            value: group?.id ?? "",
            element: (
              <GroupTableCellResourceLabel
                groupName={group?.name}
                groupType={group?.groupType}
                isOnCall={group?.isOnCallSynced}
              />
            ),
            clickHandler: () => {
              history.push(
                getResourceUrlNew({
                  entityId: group?.id ?? null,
                  entityType: EntityType.Group,
                })
              );
            },
          },
          connection: {
            sortValue: connection?.name || "",
            value: connection?.id || `${connection}-${i}`,
            element: connection ? (
              <ConnectionLabel
                text={connection?.name}
                connectionId={connection.id}
                connectionType={connection?.connectionType}
                pointerCursor={true}
              />
            ) : (
              <>{`--`}</>
            ),
          },
          tagSource: {
            value: `tagSource_${tagGroup.source}`,
            sortValue: tagGroup.source,
            element: <ServiceTypeLabel serviceType={tagGroup.source} />,
          },
          createdAt: {
            value: createdAtTime.unix(),
            element: <>{createdAtTime.fromNow()}</>,
          },
        },
      };
    }
  );

  return (
    <MaterialTable<TagGroupTableRow>
      headers={headers}
      rows={rows}
      denseFormatting={true}
    />
  );
};

export default TagGroupsTable;
