import { ConnectionType, Maybe } from "api/generated/graphql";
import adLogo from "assets/logos/active-directory-logo.png";
import awsLogo from "assets/logos/aws-logo.svg";
import azureLogo from "assets/logos/azure-logo.svg";
import connectionIcon from "assets/logos/connection-icon.svg";
import duoLogo from "assets/logos/duo-logo.svg";
import gcpLogo from "assets/logos/gcp-logo.svg";
import githubLogo from "assets/logos/github-logo.png";
import gitlabLogo from "assets/logos/gitlab-logo.svg";
import googleGroupLogo from "assets/logos/googlegroups-logo.png";
import googleWorkspaceLogo from "assets/logos/googleworkspace-logo.png";
import ldapLogo from "assets/logos/ldap-logo.png";
import mariadbLogo from "assets/logos/mariadb-logo.png";
import mongoLogo from "assets/logos/mongodb-logo.png";
import mysqlLogo from "assets/logos/mysql-logo.png";
import oktaLogo from "assets/logos/okta-logo.png";
import opalLogo from "assets/logos/opal-logo-background.png";
import pagerdutyLogo from "assets/logos/pager-duty-logo.png";
import postgresLogo from "assets/logos/postgres-logo.svg";
import salesforceLogo from "assets/logos/salesforce-logo.png";
import snowflakeLogo from "assets/logos/snowflake-logo.svg";
import tailscaleLogo from "assets/logos/tailscale-logo.png";
import teleportLogo from "assets/logos/teleport-logo.png";
import workdayLogo from "assets/logos/workday-logo.png";

export type ConnectionTypeInfo = {
  name: string;
  icon: string;
};

export const connectionTypeInfoByType: Record<
  ConnectionType,
  ConnectionTypeInfo
> = {
  [ConnectionType.ActiveDirectory]: {
    name: "Active Directory",
    icon: adLogo,
  },
  [ConnectionType.Aws]: {
    name: "Amazon Web Services (Legacy)",
    icon: awsLogo,
  },
  [ConnectionType.AwsSso]: {
    name: "Amazon Web Services",
    icon: awsLogo,
  },
  [ConnectionType.Custom]: {
    name: "Custom Integration",
    icon: connectionIcon,
  },
  [ConnectionType.CustomConnector]: {
    name: "Custom App Connector",
    icon: connectionIcon,
  },
  [ConnectionType.Duo]: {
    name: "Duo",
    icon: duoLogo,
  },
  [ConnectionType.Gcp]: {
    name: "Google Cloud Platform",
    icon: gcpLogo,
  },
  [ConnectionType.GitHub]: {
    name: "GitHub",
    icon: githubLogo,
  },
  [ConnectionType.GitLab]: {
    name: "GitLab",
    icon: gitlabLogo,
  },
  [ConnectionType.GoogleGroups]: {
    name: "Google Groups",
    icon: googleGroupLogo,
  },
  [ConnectionType.GoogleWorkspace]: {
    name: "Google Workspace",
    icon: googleWorkspaceLogo,
  },
  [ConnectionType.Ldap]: {
    name: "LDAP",
    icon: ldapLogo,
  },
  [ConnectionType.Mongo]: {
    name: "MongoDB Database",
    icon: mongoLogo,
  },
  [ConnectionType.MongoAtlas]: {
    name: "MongoDB Atlas Database",
    icon: mongoLogo,
  },
  [ConnectionType.OktaDirectory]: {
    name: "Okta Directory",
    icon: oktaLogo,
  },
  [ConnectionType.Opal]: {
    name: "Opal",
    icon: opalLogo,
  },
  [ConnectionType.Pagerduty]: {
    name: "PagerDuty",
    icon: pagerdutyLogo,
  },
  [ConnectionType.Tailscale]: {
    name: "Tailscale",
    icon: tailscaleLogo,
  },
  [ConnectionType.Salesforce]: {
    name: "Salesforce",
    icon: salesforceLogo,
  },
  [ConnectionType.Workday]: {
    name: "Workday",
    icon: workdayLogo,
  },
  [ConnectionType.Mysql]: {
    name: "MySQL",
    icon: mysqlLogo,
  },
  [ConnectionType.Mariadb]: {
    name: "MariaDB",
    icon: mariadbLogo,
  },
  [ConnectionType.Postgres]: {
    name: "PostgreSQL",
    icon: postgresLogo,
  },
  [ConnectionType.Teleport]: {
    name: "Teleport",
    icon: teleportLogo,
  },
  [ConnectionType.AzureAd]: {
    name: "Azure",
    icon: azureLogo,
  },
  [ConnectionType.Snowflake]: {
    name: "Snowflake",
    icon: snowflakeLogo,
  },
};

export const getConnectionTypeInfo = (
  connectionType?: Maybe<ConnectionType>
) => {
  return connectionType ? connectionTypeInfoByType[connectionType] : null;
};
