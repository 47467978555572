import ButtonGroup from "components/ui/buttongroup/ButtonGroupV3";
import { useContext } from "react";
import { AppsContext } from "views/apps/AppsContext";

const LayoutToggle = (props: { disableLayoutToggle?: boolean }) => {
  const { layoutOption, setLayoutOption } = useContext(AppsContext);
  return (
    <ButtonGroup
      buttons={[
        {
          label: "",
          leftIconName: "grid",
          onClick: () => setLayoutOption("grid"),
          selected: layoutOption == "grid",
          disabled: props.disableLayoutToggle,
        },
        {
          label: "",
          leftIconName: "list",
          onClick: () => setLayoutOption("list"),
          selected: layoutOption == "list",
          disabled: props.disableLayoutToggle,
        },
      ]}
    />
  );
};

export default LayoutToggle;
