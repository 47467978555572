import {
  AwsIdentityCenterImportSetting,
  ConnectionType,
  ImportSetting,
} from "api/generated/graphql";
import { FormMode } from "components/forms/common";
import { Banner, FormGroup, FormRow, RadioGroup } from "components/ui";
import React from "react";

const taggedEndSystems = [
  ConnectionType.ActiveDirectory,
  ConnectionType.Aws,
  ConnectionType.AwsSso,
  ConnectionType.Gcp,
  ConnectionType.GoogleGroups,
  ConnectionType.Ldap,
  ConnectionType.OktaDirectory,
];
const taggedEndSystemImportOptions = [
  ImportSetting.Tagged,
  ImportSetting.Manual,
];
const nonTaggedEndSystemImportOptions = [
  ImportSetting.All,
  ImportSetting.Manual,
];

const getImportOptions = (connectionType: ConnectionType): ImportSetting[] => {
  // One-off handling for some connection types because manual import doesn't
  // work properly for nested resources yet.
  switch (connectionType) {
    case ConnectionType.AwsSso:
    case ConnectionType.OktaDirectory:
      return [ImportSetting.Tagged, ImportSetting.All, ImportSetting.Manual];
  }

  if (taggedEndSystems.includes(connectionType)) {
    return taggedEndSystemImportOptions;
  } else {
    return nonTaggedEndSystemImportOptions;
  }
};

const importSettingInfos = {
  [ImportSetting.All]: {
    label: "Auto-import all",
    description:
      "All resources and groups from the end-system are automatically imported.",
  },
  [ImportSetting.Tagged]: {
    label: "Auto-import tagged",
    description:
      "Only resources and groups tagged in the end-system are automatically imported. Admins may also manually import items by selecting them from Opal.",
  },
  [ImportSetting.Manual]: {
    label: "Manual",
    description: "Only items manually selected by an admin are imported.",
  },
  [ImportSetting.None]: {
    label: "--",
    description: "Items cannot be imported for this app.",
  },
};

const awsAccountImportSettingInfos = {
  [ImportSetting.All]: {
    label: "Auto-import all",
    description: "All accounts from the end-system are automatically imported.",
  },
  [ImportSetting.Tagged]: {
    label: "Auto-import tagged",
    description:
      "Only accounts tagged in the end-system are automatically imported.",
  },
  [ImportSetting.Manual]: {
    label: "Manual",
    description: "Only accounts manually selected by an admin are imported.",
  },
  [ImportSetting.None]: {
    label: "--",
    description: "Items cannot be imported for this app.",
  },
};

const permissionSetImportOptions = [
  AwsIdentityCenterImportSetting.Provisioned,
  AwsIdentityCenterImportSetting.All,
  AwsIdentityCenterImportSetting.Manual,
];
const permissionSetImportSettingInfosV2 = {
  [AwsIdentityCenterImportSetting.All]: {
    label: "Auto-import all permission sets for every account",
    description:
      "Each account will have all permission sets imported, even if they are not already provisioned to the account. Opal will automatically provision permission sets to new accounts as needed.",
  },
  [AwsIdentityCenterImportSetting.Provisioned]: {
    label: "Auto-import provisioned permission sets for each account",
    description:
      "Only the permission sets already provisioned to each account will be imported.",
  },
  [AwsIdentityCenterImportSetting.Manual]: {
    label: "Manual (requires Terraform)",
    description:
      "Only manually selected permission sets are imported. Currently, this must be configured using Terraform.",
  },
};

const permissionSetImportSettingInfosV3 = {
  [AwsIdentityCenterImportSetting.All]: {
    label: "Auto-import all permission sets for every account",
    description:
      "Each account will have all permission sets imported, even if they are not already provisioned to the account. Opal will automatically provision permission sets to new accounts as needed.",
  },
  [AwsIdentityCenterImportSetting.Provisioned]: {
    label: "Auto-import provisioned permission sets for each account",
    description:
      "Only the permission sets already provisioned to each account will be imported.",
  },
  [AwsIdentityCenterImportSetting.Manual]: {
    label: "Manual",
    description: "Only manually selected permission sets are imported.",
  },
};

type ConnectionImportSettingRowProps = {
  mode: FormMode;
  connectionType: ConnectionType;
  importSetting: ImportSetting;
  onChange: (newSetting: ImportSetting) => void;
  awsIdentityCenterImportSetting?: AwsIdentityCenterImportSetting;
  onChangeAwsIdentityCenterImportSetting?: (
    newSetting: AwsIdentityCenterImportSetting
  ) => void;
  awsOrganizationSetImportSetting?: ImportSetting;
  onChangeAwsOrganizationImportSetting?: (newSetting: ImportSetting) => void;
  /** Should only really be true when IC is enabled and Orgs is not. */
  hideAwsOrganizationImportSetting?: boolean;
  /** Should only really be true when Orgs is enabled and IC is not. */
  hideAwsIdentityCenterImportSetting?: boolean;
  isV3?: boolean;
};

const ConnectionImportSettingRow = (props: ConnectionImportSettingRowProps) => {
  const options = getImportOptions(props.connectionType).map((option) => ({
    label: importSettingInfos[option].label,
    description:
      props.connectionType === ConnectionType.AwsSso
        ? awsAccountImportSettingInfos[option].description
        : importSettingInfos[option].description,
    value: option,
  }));

  const viewContent = importSettingInfos[props.importSetting].label;

  const editContent = (
    <RadioGroup
      value={options.find((option) => option.value === props.importSetting)}
      options={options}
      getOptionKey={(option) => option.value}
      getOptionLabel={(option) => option.label}
      getOptionDescription={(option) => option.description}
      onSelectValue={(newSetting) => {
        props.onChange(newSetting.value);
      }}
    />
  );

  let title = "Import setting";
  let tooltipText =
    "Specifies which resources and groups are imported from the end system app into Opal.";

  if (props.connectionType === ConnectionType.AwsSso) {
    // For our AWS integration we have 3 import settings. The first one refers to how accounts are imported (handled here). The other two refer to specific Orgs / Identity Center settings.
    title = "AWS account import setting";
    tooltipText = "Specifies which accounts from AWS are imported into Opal.";
  }

  if (props.isV3) {
    return (
      <>
        {props.mode === "view" ? (
          <FormRow title={title} tooltipText={tooltipText} showToolTipIcon>
            {viewContent}
          </FormRow>
        ) : (
          <FormGroup label={title} infoTooltip={tooltipText}>
            {editContent}
          </FormGroup>
        )}
        {props.importSetting === ImportSetting.All && (
          <Banner
            type="warning"
            message="Auto-import all can result in long sync times for very large apps."
            marginBottom="md"
          />
        )}
        {!props.hideAwsIdentityCenterImportSetting &&
          props.connectionType === ConnectionType.AwsSso &&
          props.awsIdentityCenterImportSetting &&
          props.onChangeAwsIdentityCenterImportSetting && (
            <AWSIdentityCenterImportSettingRow
              mode={props.mode}
              importSetting={props.awsIdentityCenterImportSetting}
              onChange={props.onChangeAwsIdentityCenterImportSetting}
              isV3
            />
          )}
        {!props.hideAwsOrganizationImportSetting &&
          props.connectionType === ConnectionType.AwsSso &&
          props.awsOrganizationSetImportSetting &&
          props.onChangeAwsOrganizationImportSetting && (
            <AWSOrganizationImportSettingRow
              mode={props.mode}
              importSetting={props.awsOrganizationSetImportSetting}
              onChange={props.onChangeAwsOrganizationImportSetting}
              isV3
            />
          )}
      </>
    );
  }

  return (
    <>
      <FormRow title={title} tooltipText={tooltipText}>
        {props.mode === "view" ? viewContent : editContent}
      </FormRow>
      {!props.hideAwsIdentityCenterImportSetting &&
        props.connectionType === ConnectionType.AwsSso &&
        props.awsIdentityCenterImportSetting &&
        props.onChangeAwsIdentityCenterImportSetting && (
          <AWSIdentityCenterImportSettingRow
            mode={props.mode}
            importSetting={props.awsIdentityCenterImportSetting}
            onChange={props.onChangeAwsIdentityCenterImportSetting}
          />
        )}
      {!props.hideAwsOrganizationImportSetting &&
        props.connectionType === ConnectionType.AwsSso &&
        props.awsOrganizationSetImportSetting &&
        props.onChangeAwsOrganizationImportSetting && (
          <AWSOrganizationImportSettingRow
            mode={props.mode}
            importSetting={props.awsOrganizationSetImportSetting}
            onChange={props.onChangeAwsOrganizationImportSetting}
          />
        )}
    </>
  );
};

type AWSIdentityCenterImportSettingRowProps = {
  mode: FormMode;
  importSetting: AwsIdentityCenterImportSetting;
  onChange: (newSetting: AwsIdentityCenterImportSetting) => void;
  isV3?: boolean;
};

const AWSIdentityCenterImportSettingRow = ({
  mode,
  importSetting,
  onChange,
  isV3,
}: AWSIdentityCenterImportSettingRowProps) => {
  const permissionSetImportSettingInfos = isV3
    ? permissionSetImportSettingInfosV3
    : permissionSetImportSettingInfosV2;
  const options = permissionSetImportOptions.map((option) => ({
    label: permissionSetImportSettingInfos[option].label,
    description: permissionSetImportSettingInfos[option].description,
    value: option,
  }));

  const content =
    mode === "view" ? (
      permissionSetImportSettingInfos[importSetting].label
    ) : (
      <RadioGroup
        value={options.find((option) => option.value === importSetting)}
        options={options}
        getOptionKey={(option) => option.value}
        getOptionLabel={(option) => option.label}
        getOptionDescription={(option) => option.description}
        onSelectValue={(newSetting) => {
          onChange(newSetting.value);
        }}
      />
    );

  if (isV3) {
    const tooltipText =
      "How permission sets in each account are imported from AWS Identity Center into Opal.";
    return mode === "view" ? (
      <FormRow title="AWS IC import setting" tooltipText={tooltipText}>
        {content}
      </FormRow>
    ) : (
      <FormGroup label="AWS IC import setting" infoTooltip={tooltipText}>
        {content}
      </FormGroup>
    );
  }

  return (
    <FormRow
      title="AWS IC import setting"
      tooltipText="How permission sets in each account are imported from AWS Identity Center into Opal."
    >
      {content}
    </FormRow>
  );
};

type AWSOrganizationImportSettingRowProps = {
  mode: FormMode;
  importSetting: ImportSetting;
  onChange: (newSetting: ImportSetting) => void;
  isV3?: boolean;
};

const AWSOrganizationImportSettingRow = ({
  mode,
  importSetting,
  onChange,
  isV3,
}: AWSOrganizationImportSettingRowProps) => {
  const awsOrganizationImportOptions = [ImportSetting.Tagged];

  const options = awsOrganizationImportOptions.map((option) => ({
    label: importSettingInfos[option].label,
    description: importSettingInfos[option].description,
    value: option,
  }));

  const content =
    mode === "view" ? (
      importSettingInfos[importSetting].label
    ) : (
      <RadioGroup
        value={options.find((option) => option.value === importSetting)}
        options={options}
        getOptionKey={(option) => option.value}
        getOptionLabel={(option) => option.label}
        getOptionDescription={(option) => option.description}
        onSelectValue={(newSetting) => {
          onChange(newSetting.value);
        }}
      />
    );

  if (isV3) {
    const tooltipText =
      "How resources in each account from AWS Organizations are imported into Opal.";
    return mode === "view" ? (
      <FormRow
        title="AWS Organizations import setting"
        tooltipText={tooltipText}
      >
        {content}
      </FormRow>
    ) : (
      <FormGroup
        label="AWS Organizations import setting"
        infoTooltip={tooltipText}
      >
        {content}
      </FormGroup>
    );
  }

  return (
    <FormRow
      title="AWS Organizations import setting"
      tooltipText="How resources in each account from AWS Organizations are imported into Opal."
    >
      {content}
    </FormRow>
  );
};

export default ConnectionImportSettingRow;
