import {
  AccessReviewConnectionUserFragment,
  AccessReviewGroupUserFragment,
  AccessReviewItemStatus,
  AccessReviewResourceUserFragment,
  ConnectionUserPropagationStatusFragment,
  EntityType,
  GroupPropagationStatusFragment,
  ResourcePropagationStatusFragment,
} from "api/generated/graphql";
import { GroupTableCellResourceLabel } from "components/label/GroupTableCellResourceLabel";
import { ResourceLabel } from "components/label/Label";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import {
  CellRow,
  Header,
  ScrollableMuiVirtualTable,
} from "components/tables/material_table/MuiVirtualTable";
import _ from "lodash";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { SortDirection } from "react-virtualized";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { PropagationType } from "utils/useRemediations";
import AccessReviewContext, {
  AccessReviewContextActionType,
} from "views/access_reviews/AccessReviewContext";
import AccessReviewReviewerDetails, {
  accessReviewReviewerDetailsRowHeight,
} from "views/access_reviews/AccessReviewReviewerDetails";
import { AccessReviewRevocationStatusLabel } from "views/access_reviews/AccessReviewStatus";
import {
  getAccessReviewConnectionUrl,
  getAccessReviewGroupUrl,
  getAccessReviewResourceUrl,
} from "views/access_reviews/common/Routes";
import {
  MARK_LINK_HEADER,
  RESOURCE_NAME_HEADER,
  REVOCATION_STATUS_HEADER,
  ROLE_HEADER,
  USER_NAME_HEADER,
} from "views/access_reviews/common/TableHeaders";
import AccessReviewRevocationToggleButton, {
  calculateSelectAllState,
  RevocationAction,
} from "views/access_reviews/revocations/AccessReviewRevocationsToggleButton";
import AccessReviewUpdateApprovalRequest from "views/access_reviews/revocations/AccessReviewUpdateRequest";

import AccessReviewRevocationsSupportTicket from "./AccessReviewRevocationsSupportTicket";

interface AccessReviewRevocationsTableRow {
  // Entity can be a resource or group.
  entity: string;
  // User is a user with access to the resource or group, whose access was reviewed and revoked.
  user: string;
  // This is the user's role in the resource, or role in the group.
  role: string;
  status: string;
  action: string;
}

type AccessReviewRevocationsTableProps = {
  accessReviewId: string;
  accessReviewResourceUsers: AccessReviewResourceUserFragment[];
  accessReviewGroupUsers: AccessReviewGroupUserFragment[];
  accessReviewConnectionUsers: AccessReviewConnectionUserFragment[];
};

export const AccessReviewRevocationsTable = (
  props: AccessReviewRevocationsTableProps
) => {
  const history = useHistory();

  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );
  const [expandedByRowId, setExpandedByRowId] = useState<
    Record<string, boolean>
  >({});
  const [lastSelectedRow, setLastSelectedRow] = useState<number | undefined>(
    undefined
  );
  const resetLastSelectedRow = () => setLastSelectedRow(undefined);
  const [rowNumberToRowId] = useState<Map<number | undefined, string>>(
    new Map<number | undefined, string>()
  );
  const [rowIdToRowNumber] = useState<Map<string, number | undefined>>(
    new Map<string, number | undefined>()
  );

  const headers: Header<AccessReviewRevocationsTableRow>[] = [
    { ...RESOURCE_NAME_HEADER, id: "entity", label: "Entity" },
    { ...USER_NAME_HEADER, id: "user", label: "User" },
    {
      ...ROLE_HEADER,
      id: "role",
      label: "Role",
    },
    REVOCATION_STATUS_HEADER,
  ];

  let showToggleButtonColumn = false;
  if (accessReviewState.ongoingAccessReviewIdSet.has(props.accessReviewId)) {
    // Count the number of items that have actions.
    const actionableUARResourceUserIds = props.accessReviewResourceUsers
      .filter(
        (uarResourceUser) =>
          uarResourceUser.statusAndOutcome.status ===
            AccessReviewItemStatus.NeedsEndSystemRevocation &&
          !uarResourceUser.supportTicket
      )
      .map((uarResourceUser) => uarResourceUser.id);

    const linkedUARResourceUserIds = props.accessReviewResourceUsers
      .filter(
        (uarResourceUser) =>
          uarResourceUser.statusAndOutcome.status ===
            AccessReviewItemStatus.NeedsEndSystemRevocation &&
          uarResourceUser.supportTicket
      )
      .map((uarResourceUser) => uarResourceUser.id);

    const updatedUarResourceUserIds = props.accessReviewResourceUsers
      .filter(
        (uarResourceUser) =>
          uarResourceUser.statusAndOutcome.status ===
            AccessReviewItemStatus.NeedsUpdateRequestApproval &&
          uarResourceUser.requestId
      )
      .map((uarResourceUser) => uarResourceUser.id);

    if (
      actionableUARResourceUserIds.length > 0 ||
      linkedUARResourceUserIds.length > 0 ||
      updatedUarResourceUserIds.length > 0
    ) {
      showToggleButtonColumn = true;
    }

    if (actionableUARResourceUserIds.length > 0) {
      const selectAllToggleButton = (
        <AccessReviewRevocationToggleButton
          state={calculateSelectAllState(
            Object.values(
              accessReviewState.revocationActionByUARResourceUserId
            ),
            actionableUARResourceUserIds.length
          )}
          markText={`Mark All`}
          linkText={`Link All`}
          onStateChange={(state) => {
            const newActionByUARResourceUserId: Record<
              string,
              RevocationAction
            > = {};

            // If `state` is defined, set all items' actions to `state`, otherwise clear the actions.
            if (state) {
              actionableUARResourceUserIds.forEach((uarResourceUserId) => {
                newActionByUARResourceUserId[uarResourceUserId] = state;
              });
            }

            resetLastSelectedRow();
            accessReviewDispatch({
              type: AccessReviewContextActionType.AccessReviewItemUpdate,
              payload: {
                revocationActionByUARResourceUserId: newActionByUARResourceUserId,
              },
            });
          }}
        />
      );
      headers.push({
        ...MARK_LINK_HEADER,
        customHeader: selectAllToggleButton,
      });
    } else if (
      linkedUARResourceUserIds.length + updatedUarResourceUserIds.length >
      0
    ) {
      headers.push({
        ...MARK_LINK_HEADER,
        customHeader: <div />,
      });
    }
  }

  const resourceRows: CellRow<AccessReviewRevocationsTableRow>[] = props.accessReviewResourceUsers.map(
    (uarResourceUser) => {
      const rowId = uarResourceUser.id;
      const resourceName = uarResourceUser.resource?.name || "Unknown Resource";
      const user = uarResourceUser.user?.fullName || uarResourceUser.userId;

      let showToggleButton = false;
      if (
        showToggleButtonColumn &&
        uarResourceUser.statusAndOutcome.status ===
          AccessReviewItemStatus.NeedsEndSystemRevocation &&
        !uarResourceUser.supportTicket
      ) {
        showToggleButton = true;
      }

      return {
        id: rowId,
        expandableContent: {
          content: (
            <AccessReviewReviewerDetails
              reviewers={uarResourceUser.reviewerUsers}
            />
          ),
          expandedRowHeight: accessReviewReviewerDetailsRowHeight(
            uarResourceUser.reviewerUsers
          ),
          isExpanded: expandedByRowId[rowId],
          setIsExpanded: (expanded) => {
            setExpandedByRowId({
              ...expandedByRowId,
              [rowId]: expanded,
            });
          },
        },
        data: {
          entity: {
            value: resourceName,
            element: (
              <ResourceLabel
                text={resourceName}
                subText={
                  getResourceTypeInfo(uarResourceUser?.resource?.resourceType)
                    ?.name || "--"
                }
                entityType={EntityTypeDeprecated.Resource}
                bold={true}
                resourceType={uarResourceUser?.resource?.resourceType}
                icon={uarResourceUser?.resource?.iconUrl}
                iconLarge={true}
                pointerCursor={true}
              />
            ),
            clickHandler: () => {
              history.push(
                getAccessReviewResourceUrl(
                  uarResourceUser.accessReviewResourceId
                )
              );
            },
          },
          user: {
            value: user,
            element: (
              <ResourceLabel
                text={user}
                entityType={EntityTypeDeprecated.User}
                avatar={uarResourceUser.user?.avatarUrl}
                entityId={uarResourceUser.userId}
              />
            ),
          },
          role: {
            value: uarResourceUser.accessLevel.accessLevelName || "",
            element: (
              <div>{uarResourceUser.accessLevel.accessLevelName || "--"}</div>
            ),
          },
          status: {
            value: `${
              uarResourceUser.statusAndOutcome.status ===
                AccessReviewItemStatus.NeedsEndSystemRevocation &&
              !uarResourceUser.supportTicketId
            }`,
            element: (
              <AccessReviewRevocationStatusLabel
                status={uarResourceUser.statusAndOutcome.status}
                outcome={uarResourceUser.statusAndOutcome.outcome}
                entityType={EntityType.Resource}
                entityInfo={{
                  roleAssignmentKey:
                    uarResourceUser.userId +
                    uarResourceUser.resourceId +
                    uarResourceUser.accessLevel.accessLevelRemoteId,
                  user: uarResourceUser.user,
                  resource: uarResourceUser.resource,
                  role: uarResourceUser.accessLevel,
                  lastExpiringAccessPointExpiration:
                    uarResourceUser.resourceUser?.access
                      ?.latestExpiringAccessPoint.expiration,
                  accessReviewId: props.accessReviewId,
                  accessReviewResourceUser: uarResourceUser,
                }}
                propagationType={PropagationType.ResourceUser}
                propagationStatus={
                  {
                    resourceId: uarResourceUser.resource?.id,
                    userId: uarResourceUser.user?.id,
                    accessLevelRemoteId:
                      uarResourceUser.accessLevel.accessLevelRemoteId,
                    errorMessage: uarResourceUser.errorMessage,
                    statusCode: uarResourceUser.statusCode,
                    taskType: uarResourceUser.taskType,
                    lastSynced: uarResourceUser.lastSynced,
                  } as ResourcePropagationStatusFragment
                }
              />
            ),
          },
          action: {
            value: `${!showToggleButton}`,
            element: showToggleButton ? (
              <AccessReviewRevocationToggleButton
                state={
                  accessReviewState.revocationActionByUARResourceUserId[rowId]
                }
                onStateChange={(state, bulkAction) => {
                  const newActionByUARResourceUserId = {
                    ...accessReviewState.revocationActionByUARResourceUserId,
                  };

                  let rowIndex = rowIdToRowNumber.get(rowId);
                  if (state) {
                    newActionByUARResourceUserId[rowId] = state;
                    if (bulkAction && lastSelectedRow != null) {
                      _.range(lastSelectedRow, rowIndex).forEach((n) => {
                        const _rowId = rowNumberToRowId.get(n);
                        if (_rowId)
                          newActionByUARResourceUserId[_rowId] = state;
                      });
                    }
                  } else {
                    delete newActionByUARResourceUserId[rowId];
                    if (bulkAction && lastSelectedRow != null) {
                      _.range(lastSelectedRow, rowIndex).forEach((n) => {
                        const _rowId = rowNumberToRowId.get(n);
                        if (_rowId) delete newActionByUARResourceUserId[_rowId];
                      });
                    }
                  }
                  setLastSelectedRow(rowIndex);

                  accessReviewDispatch({
                    type: AccessReviewContextActionType.AccessReviewItemUpdate,
                    payload: {
                      revocationActionByUARResourceUserId: newActionByUARResourceUserId,
                    },
                  });
                }}
              />
            ) : uarResourceUser.supportTicket ? (
              <AccessReviewRevocationsSupportTicket
                supportTicket={uarResourceUser.supportTicket}
              />
            ) : uarResourceUser.requestId ? (
              <AccessReviewUpdateApprovalRequest
                requestId={uarResourceUser.requestId}
              />
            ) : (
              <></>
            ),
          },
        },
      };
    }
  );

  const groupRows: CellRow<AccessReviewRevocationsTableRow>[] = props.accessReviewGroupUsers.map(
    (uarGroupUser) => {
      const rowId = uarGroupUser.id;
      const group = uarGroupUser.group?.name || "Unknown Group";
      const user = uarGroupUser.user?.fullName || uarGroupUser.userId;

      return {
        id: rowId,
        expandableContent: {
          content: (
            <AccessReviewReviewerDetails
              reviewers={uarGroupUser.reviewerUsers}
            />
          ),
          expandedRowHeight: accessReviewReviewerDetailsRowHeight(
            uarGroupUser.reviewerUsers
          ),
          isExpanded: expandedByRowId[rowId],
          setIsExpanded: (expanded) => {
            setExpandedByRowId({
              ...expandedByRowId,
              [rowId]: expanded,
            });
          },
        },
        data: {
          entity: {
            value: group,
            element: (
              <GroupTableCellResourceLabel
                groupName={group}
                isOnCall={!!uarGroupUser.group?.onCallSchedules}
              />
            ),
            clickHandler: () => {
              history.push(
                getAccessReviewGroupUrl(uarGroupUser.accessReviewGroupId)
              );
            },
          },
          user: {
            value: user,
            element: (
              <ResourceLabel
                text={user}
                entityType={EntityTypeDeprecated.User}
                avatar={uarGroupUser.user?.avatarUrl}
                entityId={uarGroupUser.userId}
              />
            ),
          },
          role: {
            value: "",
            element: <>--</>,
          },
          status: {
            value: `true`,
            element: (
              <AccessReviewRevocationStatusLabel
                status={uarGroupUser.statusAndOutcome.status}
                outcome={uarGroupUser.statusAndOutcome.outcome}
                entityType={EntityType.Group}
                entityInfo={{
                  roleAssignmentKey:
                    uarGroupUser.userId +
                    uarGroupUser.groupId +
                    uarGroupUser.accessLevel.accessLevelRemoteId,
                  user: uarGroupUser.user,
                  group: uarGroupUser.group,
                  accessReviewId: props.accessReviewId,
                  lastExpiringAccessPointExpiration:
                    uarGroupUser.groupUser?.access?.latestExpiringAccessPoint
                      .expiration,
                  accessReviewGroupUser: uarGroupUser,
                }}
                propagationType={PropagationType.GroupUser}
                propagationStatus={
                  {
                    groupId: uarGroupUser.group?.id,
                    userId: uarGroupUser.user?.id,
                    errorMessage: uarGroupUser.errorMessage,
                    statusCode: uarGroupUser.statusCode,
                    taskType: uarGroupUser.taskType,
                    lastSynced: uarGroupUser.lastSynced,
                  } as GroupPropagationStatusFragment
                }
              />
            ),
          },
          action: {
            value: `true`,
            element: <></>,
          },
        },
      };
    }
  );

  const connectionUserRows: CellRow<AccessReviewRevocationsTableRow>[] = props.accessReviewConnectionUsers.map(
    (uarConnectionUser) => {
      const rowId = uarConnectionUser.id;
      const connection = uarConnectionUser.connection?.name || "Unknown App";
      const user = uarConnectionUser.user?.fullName || uarConnectionUser.userId;

      return {
        id: rowId,
        expandableContent: {
          content: (
            <AccessReviewReviewerDetails
              reviewers={uarConnectionUser.reviewerUsers}
            />
          ),
          expandedRowHeight: accessReviewReviewerDetailsRowHeight(
            uarConnectionUser.reviewerUsers
          ),
          isExpanded: expandedByRowId[rowId],
          setIsExpanded: (expanded) => {
            setExpandedByRowId({
              ...expandedByRowId,
              [rowId]: expanded,
            });
          },
        },
        data: {
          entity: {
            value: connection,
            element: (
              <ResourceLabel
                text={connection}
                entityType={EntityTypeDeprecated.Connection}
                entityId={uarConnectionUser.connectionId}
                url={getAccessReviewConnectionUrl(
                  uarConnectionUser.accessReviewConnectionId
                )}
                bold={true}
                iconLarge={true}
              />
            ),
          },
          user: {
            value: user,
            element: (
              <ResourceLabel
                text={user}
                entityType={EntityTypeDeprecated.User}
                avatar={uarConnectionUser.user?.avatarUrl}
                entityId={uarConnectionUser.userId}
              />
            ),
          },
          role: {
            value: "",
            element: <>--</>,
          },
          status: {
            value: `true`,
            element: (
              <AccessReviewRevocationStatusLabel
                status={uarConnectionUser.statusAndOutcome.status}
                outcome={uarConnectionUser.statusAndOutcome.outcome}
                entityType={EntityType.Connection}
                entityInfo={{
                  roleAssignmentKey:
                    uarConnectionUser.userId + uarConnectionUser.connectionId,
                  user: uarConnectionUser.user,
                  connection: uarConnectionUser.connection,
                  accessReviewId: props.accessReviewId,
                  accessReviewConnectionUser: uarConnectionUser,
                }}
                propagationType={PropagationType.ConnectionUser}
                propagationStatus={
                  {
                    userId: uarConnectionUser.user?.id,
                    errorMessage: uarConnectionUser.errorMessage,
                    statusCode: uarConnectionUser.statusCode,
                    taskType: uarConnectionUser.taskType,
                    lastSynced: uarConnectionUser.lastSynced,
                  } as ConnectionUserPropagationStatusFragment
                }
              />
            ),
          },
          action: {
            value: `true`,
            element: <></>,
          },
        },
      };
    }
  );

  const rows = [...resourceRows, ...groupRows, ...connectionUserRows];

  return (
    <ScrollableMuiVirtualTable
      columns={headers}
      rows={rows}
      defaultSortBy={"entity"}
      defaultSortDirection={SortDirection.ASC}
      allRowsLoaded
      expandable
      onSortChanged={(index, id) => {
        rowNumberToRowId.set(index, id);
        rowIdToRowNumber.set(id, index);
      }}
      autoRowIndexes
    />
  );
};

export default AccessReviewRevocationsTable;
