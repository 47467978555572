import { Label } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React from "react";
import { ItemAccessInfo } from "views/apps/enduser_exp/types";

const AccessInfoDisplay = ({
  hasAccess,
  roles,
  expirationByRoleId,
  soonestExpiration,
}: ItemAccessInfo) => {
  const rolesHaveName = roles.some((role) => role.accessLevelName.length > 0);

  let label = null;
  if (rolesHaveName) {
    label = roles.map((role) => {
      const expiration = expirationByRoleId[role.accessLevelRemoteId];
      return (
        <div
          key={role.accessLevelRemoteId}
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
          })}
        >
          <Label
            label={role.accessLevelName || "No role"}
            icon={{ type: "name", icon: "role" }}
            fontWeight="bold"
          />
          {`Access expires: ${expiration ? expiration?.fromNow() : "never"}`}
        </div>
      );
    });
  } else {
    label = `Access expires: ${
      soonestExpiration ? soonestExpiration?.fromNow() : "never"
    }`;
  }

  return (
    <div
      className={sprinkles({
        display: "flex",
        gap: "sm",
        flexDirection: "column",
      })}
    >
      <div className={sprinkles({ fontWeight: "bold" })}>My current access</div>
      {hasAccess ? <>{label}</> : "You do not have access"}
    </div>
  );
};

export default AccessInfoDisplay;
