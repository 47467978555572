import { RequestStatus, useRequestMetricsQuery } from "api/generated/graphql";
import { LineGraphDataFragment } from "api/generated/graphql";
import clsx from "clsx";
import { Icon, Skeleton } from "components/ui";

import Sparkline from "../graphs/Sparkline";
import { useMetricsQueryInput } from "../MetricsDashboardContext";
import * as styles from "./Metrics.css";

const RequestMetrics = () => {
  const input = useMetricsQueryInput();

  const { data, loading } = useRequestMetricsQuery({
    variables: {
      input,
    },
  });

  if (loading) {
    return (
      <div className={styles.requestMetricsContainer}>
        <RequestMetricSkeleton />
        <RequestMetricSkeleton />
        <RequestMetricSkeleton />
      </div>
    );
  }

  const totalApproved = data?.requestMetrics.totalApproved ?? 0;
  const approvedLineData = data?.requestMetrics.approvedLineData ?? [];
  const totalDenied = data?.requestMetrics.totalDenied ?? 0;
  const deniedLineData = data?.requestMetrics.deniedLineData ?? [];
  const totalCanceled = data?.requestMetrics.totalCanceled ?? 0;
  const canceledLineData = data?.requestMetrics.canceledLineData ?? [];

  return (
    <div className={styles.requestMetricsContainer}>
      <RequestMetric
        total={totalApproved}
        lineData={approvedLineData}
        requestStatus={RequestStatus.Approved}
      />
      <RequestMetric
        total={totalDenied}
        lineData={deniedLineData}
        requestStatus={RequestStatus.Denied}
      />
      <RequestMetric
        total={totalCanceled}
        lineData={canceledLineData}
        requestStatus={RequestStatus.Canceled}
      />
    </div>
  );
};

const RequestMetricSkeleton = () => {
  return (
    <div className={clsx(styles.metricsTile, styles.requestMetricsTile)}>
      <div className={styles.leftStatContainer}>
        <Skeleton height="60px" width="80px" />
        <Skeleton width="120px" />
      </div>
      <Skeleton height="100%" width="250px" />
    </div>
  );
};

interface Props {
  total: number;
  lineData: LineGraphDataFragment[];
  requestStatus: RequestStatus;
}

const RequestMetric = (props: Props) => {
  const { total, lineData } = props;

  return (
    <div className={clsx(styles.metricsTile, styles.requestMetricsTile)}>
      <div className={styles.leftStatContainer}>
        <div className={styles.numberStat}>{total}</div>
        <div className={styles.subtitle}>
          {props.requestStatus.toLowerCase()} requests
        </div>
      </div>
      <div className={styles.sparklineContainer}>
        {total > 0 ? (
          <Sparkline
            data={lineData}
            label={props.requestStatus.toLowerCase()}
          />
        ) : (
          <div className={styles.noDataLabel}>
            <Icon name="help-circle" size="xs" />
            No data available
          </div>
        )}
      </div>
    </div>
  );
};

export default RequestMetrics;
