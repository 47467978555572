import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AddResourceUserInput,
  ResourceAccessLevel,
  ResourceAddUserPreviewFragment,
  ResourcePreviewFragment,
  ResourceType,
  SortDirection,
  useAddResourceUsersMutation,
  useResourceAddUsersQuery,
  UserPreviewSmallFragment,
  UsersSortByField,
} from "api/generated/graphql";
import UploadCSVButton, {
  CSVColumns,
  UserInfo,
} from "components/buttons/UploadCSVButton";
import FullscreenViewTitle from "components/fullscreen_modals/FullscreenViewTitle";
import { ResourceLabel } from "components/label/Label";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import { Role } from "components/modals/ResourceIndividualRoleModal";
import {
  Banner,
  ButtonV3,
  Checkbox,
  Divider,
  Icon,
  Input,
  Select,
} from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableFilters from "components/ui/table/TableFilters";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import React from "react";
import { useMemo, useState } from "react";
import { useParams } from "react-router";
import { useImmer } from "use-immer";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import {
  resourceHasOnlyOneRole,
  resourceRequiresAtLeastOneRole,
  serviceTypeHasMaxOneRole,
} from "utils/directory/resources";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { ForbiddenPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
} from "views/requests/utils";
import { getUserAvatarIcon } from "views/users/utils";
import { dropNothings } from "views/utils";

import * as styles from "./ResourceAddUsersView.css";

const filterUserByRole = (
  user: ResourceAddUserPreviewFragment,
  showAllUsers: boolean,
  resourceHasOnlyOneAccessType: boolean,
  rolesForResource: ResourceAccessLevel[]
) => {
  if (!user.paginatedUserResources?.resourceUsers || showAllUsers) {
    return true;
  }
  const directRoles = user.paginatedUserResources.resourceUsers.filter(
    (resourceUser) => {
      return Boolean(resourceUser.access?.directAccessPoint);
    }
  );
  // Resource does not have access levels
  if (resourceHasOnlyOneAccessType && directRoles.length !== 0) {
    return false;
  }

  // Resource has access levels
  if (
    rolesForResource.length !== 0 &&
    directRoles.length === rolesForResource.length
  ) {
    return false;
  }

  return true;
};

type UserWithExpirationandRoles = {
  user: ResourceAddUserPreviewFragment;
  expiration: ExpirationValue;
  expirationInMinutes?: number;
  roles: Role[];
};
interface UserItemRow {
  id: string;
  [UsersSortByField.FirstName]: string;
  [UsersSortByField.Email]: string;
  item: ResourceAddUserPreviewFragment;
}
const COLUMNS: Header<UserItemRow>[] = [
  {
    id: UsersSortByField.FirstName,
    label: "Name",
    sortable: true,
    customCellRenderer: (row) => {
      let userFullname = row.item?.fullName;
      let icon = row.item ? getUserAvatarIcon(row.item) : null;
      if (!userFullname) {
        return <></>;
      }
      return (
        <div
          className={sprinkles({
            display: "flex",
            alignItems: "center",
            gap: "sm",
          })}
        >
          {icon && <Icon data={icon} />}
          <ResourceLabel
            text={userFullname}
            bold={true}
            pointerCursor={true}
            maxChars="auto"
          />
        </div>
      );
    },
    width: 230,
  },
  {
    id: UsersSortByField.Email,
    label: "Email",
    sortable: true,
    customCellRenderer: (row) => {
      let userEmail = row.item.email;
      if (!userEmail) {
        return <></>;
      }
      return (
        <ResourceLabel
          text={userEmail}
          bold={false}
          pointerCursor={true}
          maxChars="auto"
        />
      );
    },
    width: 230,
  },
];

type SortValue = {
  field: UsersSortByField;
  direction: SortDirection;
};
function isSortableField(str: string): str is UsersSortByField {
  return Object.values<string>(UsersSortByField).includes(str);
}

const ResourceAddUsersView = () => {
  const transitionBack = useTransitionBack();
  const logEvent = useLogEvent();
  const { resourceId } = useParams<{ resourceId: string }>();
  const startPushTaskPoll = usePushTaskLoader();

  const [loadingCSV, setLoadingCSV] = useState<boolean>(false);
  const [usersToAddByUserId, setUsersToAddByUserId] = useImmer<
    Partial<Record<string, UserWithExpirationandRoles>>
  >({});
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  const [errorMessages, setErrorMessages] = useImmer<string[]>([]);
  const [showAllUsers, setShowAllUsers] = useState<boolean>(false);
  const [sortBy, setSortBy] = useState<SortValue | undefined>({
    field: UsersSortByField.FirstName,
    direction: SortDirection.Asc,
  });

  const [
    addResourceUsers,
    { loading: addUsersLoading },
  ] = useAddResourceUsersMutation();
  const {
    data,
    previousData,
    loading,
    error,
    fetchMore,
  } = useResourceAddUsersQuery({
    variables: {
      id: resourceId,
      searchQuery: debouncedSearchQuery,
      sortBy: sortBy,
      maxNumEntries: 100,
    },
    notifyOnNetworkStatusChange: true,
  });
  let resource: ResourcePreviewFragment | undefined;
  if (previousData?.resource.__typename === "ResourceResult") {
    resource = previousData.resource.resource;
  }
  if (data?.resource.__typename === "ResourceResult") {
    resource = data.resource.resource;
  }
  const cursor = data?.users?.cursor || undefined;
  const totalNumUsers = data?.users?.totalNumUsers || 0;
  const allUsers = useMemo(() => {
    return data?.users.users ?? previousData?.users.users ?? [];
  }, [data, previousData]);
  const userById = _.keyBy(allUsers, "id");
  const roles: ResourceAccessLevel[] = useMemo(
    () =>
      resource?.accessLevels?.filter((role) => role.accessLevelName !== "") ??
      [],
    [resource?.accessLevels]
  );

  const resourceRequiresRole =
    resource && resourceRequiresAtLeastOneRole(resource);

  let resourceHasOnlyOneAccessType =
    (resource && resourceHasOnlyOneRole(resource)) ||
    resource?.resourceType === ResourceType.OktaApp;

  // Filter out users who have full direct access already
  const users = useMemo(() => {
    return allUsers.filter((user) =>
      filterUserByRole(
        user,
        showAllUsers,
        resourceHasOnlyOneAccessType,
        resource?.accessLevels ?? []
      )
    );
  }, [
    resourceHasOnlyOneAccessType,
    resource?.accessLevels,
    allUsers,
    showAllUsers,
  ]);

  if (loading && !(data || previousData)) {
    return <FullscreenSkeleton />;
  }
  if (!resource?.authorizedActions?.includes(AuthorizedActionManage)) {
    return <ForbiddenPage />;
  }
  if (!resource || error) {
    return <UnexpectedErrorPage error={error} />;
  }

  const handleClose = () => {
    transitionBack(`/resources/${resourceId}#users`);
  };

  const rows: UserItemRow[] = users.map((user) => {
    return {
      id: user.id,
      [UsersSortByField.FirstName]: user.firstName || "--",
      [UsersSortByField.Email]: user.email || "--",
      item: user,
    };
  });

  const loadMoreRows = async () => {
    if (!cursor) {
      return;
    }
    await fetchMore({
      variables: {
        searchQuery: debouncedSearchQuery,
        id: resourceId,
        cursor: cursor,
        sortBy: sortBy,
      },
    });
  };

  const numUsersToAdd = Object.keys(usersToAddByUserId).length;

  const handleAddUsers = async () => {
    if (resourceRequiresRole) {
      const usersRequireRoles = Object.values(usersToAddByUserId).filter(
        (user) => {
          if (!user) return false;
          const { roles } = user;
          if (
            roles.length === 0 ||
            (roles.length === 1 && roles[0].accessLevelName === "")
          ) {
            return true;
          }
          return false;
        }
      );
      if (usersRequireRoles.length > 0) {
        setErrorMessages(["You must select at least one role for each user."]);
        return;
      }
    }

    logEvent({
      name: "apps_add_user",
      properties: {
        type: "resource",
        numUsers: Object.entries(usersToAddByUserId).length,
      },
    });
    try {
      const resourceUsersToAdd: AddResourceUserInput[] = dropNothings(
        Object.values(usersToAddByUserId).reduce((acc, userMetadata) => {
          if (!userMetadata) {
            return acc;
          }
          const expirationVal =
            userMetadata.expiration || ExpirationValue.Indefinite;
          const accessDurationInMinutes =
            userMetadata.expirationInMinutes ??
            expirationValueToDurationInMinutes(expirationVal)?.asMinutes();
          acc.push(
            ...userMetadata.roles.map((role) => {
              return {
                resourceId,
                userId: userMetadata.user.id,
                accessLevel: {
                  accessLevelName: role.accessLevelName,
                  accessLevelRemoteId: role.accessLevelRemoteId,
                },
                durationInMinutes: accessDurationInMinutes,
              };
            })
          );
          return acc;
        }, [] as AddResourceUserInput[])
      );

      const { data } = await addResourceUsers({
        variables: {
          input: {
            resourceUsers: resourceUsersToAdd,
          },
        },
        refetchQueries: [
          "ResourceDetailView",
          "ResourceDetailPaginatedResourceUsers",
          // Note that AppsListColumn will only refetch the first page, not the
          // current page displayed to the user, eventually this will get fixed
          // if we move to a proper pagination system.
          "AppsListColumn",
          "ResourceUsersTable",
        ],
      });
      switch (data?.addResourceUsers.__typename) {
        case "AddResourceUsersResult":
          startPushTaskPoll(data.addResourceUsers.taskId);
          handleClose();
          break;
        case "CannotAddSystemUserToResourceError":
          logError(new Error(data.addResourceUsers.message));
          setErrorMessages([data.addResourceUsers.message]);
          break;
        case "OpalGlobalImpersonationResourceDirectAddNotAllowedError":
          logError(new Error(data.addResourceUsers.message));
          setErrorMessages([data.addResourceUsers.message]);
          break;
        case "CallToWebhookFailedError":
          setErrorMessages([data.addResourceUsers.message]);
          break;
        case "ResourceUserAlreadyExists":
          setErrorMessages([data.addResourceUsers.message]);
          break;
        default:
          logError(new Error(`failed to update resource user access`));
          setErrorMessages(["Error: failed to update resource user access"]);
      }
    } catch (error) {
      logError(error, "failed to update resource user access");
      setErrorMessages([
        getModifiedErrorMessage(
          "Error: failed to update resource user access",
          error
        ),
      ]);
    }
  };

  const handleUserClick = (user: ResourceAddUserPreviewFragment) => {
    if (user.id in usersToAddByUserId) {
      setUsersToAddByUserId((draft) => {
        delete draft[user.id];
      });
    } else {
      setUsersToAddByUserId((draft) => {
        draft[user.id] = {
          user: user,
          expiration: ExpirationValue.Indefinite,
          roles: [
            {
              accessLevelName: "",
              accessLevelRemoteId: "",
            },
          ],
        };
      });
    }
  };

  const handleUserCSVUpload = async (userInfos: UserInfo[]) => {
    setLoadingCSV(true);
    const userEmails = _.uniq(userInfos.map((userInfo) => userInfo.email));
    // A user can be added multiple times to the CSV with different access
    // levels, accumulate them by unique role remote id
    const userInfosByEmail = userInfos.reduce((acc, userInfo) => {
      acc[userInfo.email] = _.uniqBy(
        [...(acc[userInfo.email] ?? []), userInfo],
        "roleRemoteId"
      );
      return acc;
    }, {} as Record<string, UserInfo[]>);
    // Get the users from our backend, and add them to the table if needed
    const { data, error } = await fetchMore({
      variables: { userEmails, maxNumEntries: userEmails.length },
    });
    if (error) {
      logError(error, `failed to fetch users`);
      setErrorMessages([`Error: failed to fetch users`]);
      setLoadingCSV(false);
      return;
    }
    setErrorMessages([]);
    // Create a map of users by email and their secondaryEmails
    const userByEmail = data.users.users.reduce((acc, user) => {
      for (const email of user.secondaryEmails) {
        acc[email] = user;
      }
      acc[user.email] = user;
      return acc;
    }, {} as Record<string, UserPreviewSmallFragment>);
    // Tell the user that some users were not found
    const usersNotFound: string[] = [];
    for (const email of userEmails) {
      if (!userByEmail[email]) {
        usersNotFound.push(email);
      }
    }
    if (usersNotFound.length > 0) {
      setErrorMessages((draft) => {
        draft.push(
          `${pluralize(
            "user",
            usersNotFound.length,
            true
          )} not found: ${usersNotFound.join(", ")}`
        );
      });
    }

    // Add users that don't already have access to the staging sidebar
    // Notify the user that some users in the CSV already have access
    const users = data.users.users.reduce(
      (acc, user) => {
        // if the user already has all the access roles, we can't add them again
        if (
          !filterUserByRole(user, false, resourceHasOnlyOneAccessType, roles)
        ) {
          acc.skippedUsers.push(user.email);
        } else {
          acc.usersToAdd.push(user);
        }
        return acc;
      },
      {
        skippedUsers: [] as string[],
        usersToAdd: [] as ResourceAddUserPreviewFragment[],
      }
    );
    if (users.usersToAdd.length > 0) {
      setUsersToAddByUserId((draft) => {
        // This is a bit convoluted, we want to set the default empty role if there are no roles or if the resource doesn't have access levels.
        // Otherwise, for every user, we want to set the roles they have in the CSV, with the following logic:
        // 1. if the user has a role that we know about, we want to use the correct translation (accessLevelName)
        // 2. if the user has a role that we don't know about, we want to use the roleRemoteId as the name (this is expected in GCP, custom resources)
        for (const user of users.usersToAdd) {
          const role: ResourceAccessLevel = {
            accessLevelRemoteId: "",
            accessLevelName: "",
          };
          let userRoles: ResourceAccessLevel[] = [];
          let userInfos: UserInfo[] | undefined = userInfosByEmail[user.email];
          if (!userInfos) {
            userInfos = user.secondaryEmails
              .map((email) => userInfosByEmail[email])
              .find((u) => !!u);
          }
          userRoles = dropNothings(
            userInfos?.map((userInfo) => {
              const foundRole = roles.find(
                (role) => role.accessLevelRemoteId == userInfo.roleRemoteId
              );
              if (foundRole) {
                return foundRole;
              }
              return {
                accessLevelRemoteId: userInfo.roleRemoteId ?? "",
                accessLevelName: userInfo.roleRemoteId ?? "",
              };
            }) ?? [role]
          );
          const expiration = userInfos?.[0]?.durationInMinutes ?? undefined;

          draft[user.id] = {
            user,
            expiration: ExpirationValue.Indefinite,
            expirationInMinutes: expiration,
            roles: userRoles,
          };
        }
      });
    }
    if (users.skippedUsers.length > 0) {
      setErrorMessages((draft) => {
        draft.push(
          `${pluralize(
            "user",
            users.skippedUsers.length,
            true
          )} already have access: ${users.skippedUsers.join(", ")}`
        );
      });
    }
    setLoadingCSV(false);
  };

  return (
    <FullscreenView
      title={
        <FullscreenViewTitle
          entityType={resource.resourceType}
          entityName={resource.name}
          targetEntityName="users"
          action="add"
        />
      }
      onCancel={handleClose}
      onPrimaryButtonClick={handleAddUsers}
      primaryButtonLabel={`Add ${
        numUsersToAdd > 0 ? numUsersToAdd : ""
      } ${pluralize("user", numUsersToAdd)}`}
      primaryButtonDisabled={numUsersToAdd === 0 || loadingCSV}
      primaryButtonLoading={addUsersLoading || loadingCSV}
    >
      <FullscreenView.Content fullWidth>
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textMd",
              fontWeight: "medium",
              marginBottom: "md",
            })}
          >
            Select users to add to the resource:
          </div>
          <TableFilters>
            <TableFilters.Left>
              <div className={styles.searchInput}>
                <Input
                  leftIconName="search"
                  type="search"
                  style="search"
                  value={searchQuery}
                  onChange={(value) => {
                    setSearchQuery(value);
                  }}
                  placeholder="Filter by name or email"
                  autoFocus
                />
              </div>
              <Checkbox
                label="Show all users"
                checked={showAllUsers}
                onChange={setShowAllUsers}
              />
            </TableFilters.Left>
            <TableFilters.Right>
              <UploadCSVButton
                onChangeUserInfos={handleUserCSVUpload}
                onChangeErrorMessage={(errorMessage) => {
                  setErrorMessages([errorMessage]);
                }}
                requiredColumns={
                  resourceRequiresRole
                    ? [CSVColumns.Email, CSVColumns.RoleRemoteId]
                    : [CSVColumns.Email]
                }
                optionalColumns={[CSVColumns.DurationInMinutes]}
                trackName="apps_add_user"
              />
            </TableFilters.Right>
          </TableFilters>
          <Divider />
          <Table
            columns={COLUMNS}
            rows={rows}
            totalNumRows={totalNumUsers ?? 0}
            getRowId={(user) => user.id}
            loadingRows={loading}
            onLoadMoreRows={loadMoreRows}
            checkedRowIds={new Set(Object.keys(usersToAddByUserId))}
            onCheckedRowsChange={(checkedRowIds) => {
              for (const userId of checkedRowIds) {
                const user = userById[userId];
                if (user) {
                  handleUserClick(user);
                }
              }
            }}
            onRowClick={(user) => {
              handleUserClick(user.item);
            }}
            manualSortDirection={
              sortBy && {
                sortBy: sortBy.field,
                sortDirection: sortBy.direction,
              }
            }
            handleManualSort={(sortBy, sortDirection) => {
              // Investigate if there is more elegant solution
              if (!sortDirection) {
                setSortBy(undefined);
                return;
              }
              if (!isSortableField(sortBy)) {
                return;
              }
              const direction: SortDirection =
                sortDirection === "DESC"
                  ? SortDirection.Desc
                  : SortDirection.Asc;
              setSortBy({
                field: sortBy,
                direction,
              });
            }}
          />
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {errorMessages && errorMessages.length > 0 && (
          <>
            {errorMessages.map((errorMessage, index) => (
              <Banner
                key={errorMessage + index}
                message={errorMessage}
                type="error"
                marginBottom="lg"
              />
            ))}
          </>
        )}
        <div
          className={sprinkles({
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "lg",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textLg",
              fontWeight: "medium",
              marginBottom: "lg",
            })}
          >
            Adding {numUsersToAdd} {pluralize("User", numUsersToAdd)}
          </div>

          {numUsersToAdd > 0 && (
            <ButtonV3
              leftIconName="x"
              label="Clear all"
              size="xs"
              type="dangerBorderless"
              onClick={() => setUsersToAddByUserId({})}
            />
          )}
        </div>
        {Object.keys(usersToAddByUserId).map((userId) => {
          const user = usersToAddByUserId[userId]?.user;
          if (!user) {
            return null;
          }
          const expirationInMinutes =
            usersToAddByUserId[userId]?.expirationInMinutes;
          const expiration = usersToAddByUserId[userId]?.expiration;

          return (
            <div key={userId} className={styles.userCard}>
              <div
                className={sprinkles({
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "sm",
                  marginBottom: "lg",
                })}
              >
                <div className={sprinkles({ flexShrink: 0 })}>
                  <Icon data={getUserAvatarIcon(user)} />
                </div>
                <div className={styles.userInfoSection}>
                  <div className={styles.userCardHeader}>{user.fullName}</div>
                  <div className={styles.userCardSubtitle}>{user.email}</div>
                </div>
                <div className={sprinkles({ flexShrink: 0 })}>
                  <Icon
                    name="trash"
                    color="red600V3"
                    onClick={() => {
                      setUsersToAddByUserId((draft) => {
                        delete draft[user.id];
                      });
                    }}
                  />
                </div>
              </div>
              {expirationInMinutes ? (
                <div
                  className={sprinkles({
                    paddingX: "sm",
                    marginTop: "sm",
                    fontSize: "textSm",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  })}
                >
                  Access for{" "}
                  {moment.duration(expirationInMinutes, "minutes").humanize()}
                  <Icon
                    name="x"
                    size="xs"
                    onClick={() => {
                      setUsersToAddByUserId((draft) => {
                        const userToUpdate = draft[user.id];
                        if (userToUpdate) {
                          delete userToUpdate.expirationInMinutes;
                        }
                      });
                    }}
                  />
                </div>
              ) : (
                <Select
                  key={userId}
                  options={Object.values(ExpirationValue)}
                  value={expiration}
                  onChange={(val) => {
                    if (val) {
                      setUsersToAddByUserId((draft) => {
                        const userToUpdate = draft[user.id];
                        if (userToUpdate) userToUpdate.expiration = val;
                      });
                    }
                  }}
                  disableBuiltInFiltering
                  getOptionLabel={(expirationVal) =>
                    expirationVal === ExpirationValue.Indefinite
                      ? "Indefinite access"
                      : `Access for ${expirationVal}`
                  }
                />
              )}
              {(roles.length > 0 || resourceRequiresRole) && (
                <div className={sprinkles({ marginTop: "md" })}>
                  <Select
                    key={userId}
                    options={roles.filter((role) => {
                      // Only include roles that the user does not already have
                      // or are currently selected to be added
                      if (
                        user.paginatedUserResources?.resourceUsers
                          ?.map((r) => r.accessLevel.accessLevelRemoteId)
                          .includes(role.accessLevelRemoteId)
                      ) {
                        return false;
                      }
                      if (
                        usersToAddByUserId[user.id]?.roles
                          .map((role) => role.accessLevelRemoteId)
                          .includes(role.accessLevelRemoteId)
                      ) {
                        return false;
                      }
                      return true;
                    })}
                    selectOnly
                    placeholder="Select role"
                    onChange={(val) => {
                      if (!val) {
                        return;
                      }
                      const selectedAccessLevel = {
                        accessLevelName: val.accessLevelName,
                        accessLevelRemoteId: val.accessLevelRemoteId,
                      };
                      const currentRoles =
                        usersToAddByUserId[user.id]?.roles ?? [];
                      if (
                        serviceTypeHasMaxOneRole(resource?.serviceType) ||
                        (currentRoles.length === 1 &&
                          currentRoles[0].accessLevelName === "")
                      ) {
                        setUsersToAddByUserId((draft) => {
                          draft[user.id] = {
                            user: user,
                            expiration:
                              expiration ?? ExpirationValue.Indefinite,
                            roles: [selectedAccessLevel],
                          };
                        });
                      } else {
                        setUsersToAddByUserId((draft) => {
                          draft[user.id] = {
                            user: user,
                            expiration:
                              expiration ?? ExpirationValue.Indefinite,
                            roles: [...currentRoles, selectedAccessLevel],
                          };
                        });
                      }
                    }}
                    getOptionLabel={(role) => role.accessLevelName}
                  />
                  {usersToAddByUserId[user.id]?.roles.map((role) => {
                    if (role.accessLevelName === "") {
                      return null;
                    }
                    return (
                      <div
                        key={role.accessLevelRemoteId}
                        className={sprinkles({
                          paddingX: "sm",
                          marginTop: "sm",
                          fontSize: "textSm",
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                        })}
                      >
                        {role.accessLevelName}
                        <Icon
                          name="x"
                          size="xs"
                          onClick={() => {
                            setUsersToAddByUserId((draft) => {
                              const userToUpdate = draft[user.id];
                              if (userToUpdate) {
                                userToUpdate.roles = userToUpdate.roles.filter(
                                  (r) =>
                                    r.accessLevelRemoteId !==
                                    role.accessLevelRemoteId
                                );
                              }
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          );
        })}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};

export default ResourceAddUsersView;
