import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AddResourceUserInput,
  ConnectionType,
  EntityType,
  ResourceAccessLevel,
  ResourceDropdownPreviewFragment,
  ResourceType,
  useAddResourceUsersMutation,
  useMultipleResourceAccessLevelsQuery,
  usePaginatedResourceDropdownLazyQuery,
  useSearchResourcesQuery,
  useUserAddResourcesQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import FullscreenViewTitle from "components/fullscreen_modals/FullscreenViewTitle";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { Banner, Divider, Input, Label, Loader } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import pluralize from "pluralize";
import { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { resourceRequiresAtLeastOneRole } from "utils/directory/resources";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { formatResourceBreadcrumb } from "utils/resources";
import { useTransitionBack } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { ForbiddenPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
} from "views/requests/utils";

import * as styles from "./GroupAddResources.css";
import { ResourceCard } from "./GroupAddResourcesView";

const PAGE_SIZE = 100;

interface UserResourceRow {
  id: string;
  icon?: IconData;
  name: string;
  sublabel?: string;
  connectionType?: ConnectionType;
  connectionId?: string;
  resourceType?: ResourceType;
  isEmpty?: boolean;
}

const UserAddResourcesView = () => {
  const transitionBack = useTransitionBack();
  const logEvent = useLogEvent();
  const { userId } = useParams<{ userId: string }>();
  const startPushTaskPoll = usePushTaskLoader();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  // TODO: Remove this mess of useState in favor of context
  const [resourceById, setResourceById] = useState<{
    [resourceId: string]: ResourceDropdownPreviewFragment;
  }>({});
  const [resourcesByConnectionId, setResourcesByConnectionId] = useState<{
    [connectionId: string]: ResourceDropdownPreviewFragment[];
  }>({});
  const [resourcesByParentId, setResourcesByParentId] = useState<{
    [resourceId: string]: ResourceDropdownPreviewFragment[];
  }>({});
  const [roleByResourceIdToAdd, setRoleByResourceIdToAdd] = useState<
    Record<string, ResourceAccessLevel[]>
  >({});
  const [
    accessDurationByResourceIdToAdd,
    setAccessDurationByResourceIdToAdd,
  ] = useState<Record<string, ExpirationValue>>({});
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [addError, setAddError] = useState("");
  const rowsById: Record<string, UserResourceRow> = {};
  const [itemsLoadingSubRows, setItemsLoadingSubRows] = useState<string[]>([]);
  const { authState } = useContext(AuthContext);

  // Get User and Connection data
  const { data, loading, error } = useUserAddResourcesQuery({
    variables: {
      userId,
    },
  });
  const user =
    data?.user.__typename === "UserResult" ? data.user.user : undefined;
  const allConnections = data?.connections.connections ?? [];
  const connections = allConnections.filter((connection) =>
    Boolean(connection.numResources)
  );

  // Fetch all non-remote roles for resources that the user has access to
  // Used to determine if the user already has full-access to the resource
  const resourceIDs = new Set(user?.userResources.map((ur) => ur.resourceId));
  const {
    data: rolesData,
    previousData: rolesPreviousData,
    error: rolesError,
  } = useMultipleResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceIds: Array.from(resourceIDs),
        // Don't query end systems for roles upfront to load page faster
        ignoreRemoteAccessLevels: true,
      },
    },
    skip: resourceIDs.size === 0,
  });
  const allNonRemoteRolesByResourceId: Record<
    string,
    ResourceAccessLevel[]
  > = {};
  switch (rolesPreviousData?.multipleAccessLevels.__typename) {
    case "MultipleResourceAccessLevelsResult":
      rolesPreviousData.multipleAccessLevels.results.forEach((role) => {
        allNonRemoteRolesByResourceId[role.resourceId] = role.accessLevels;
      });
  }
  switch (rolesData?.multipleAccessLevels.__typename) {
    case "MultipleResourceAccessLevelsResult":
      rolesData.multipleAccessLevels.results.forEach((role) => {
        allNonRemoteRolesByResourceId[role.resourceId] = role.accessLevels;
      });
  }

  // Allow searching directly for resources
  const {
    data: searchResourcesData,
    loading: searchResourcesLoading,
    error: searchResourcesError,
  } = useSearchResourcesQuery({
    variables: {
      query: debouncedSearchQuery,
      maxNumEntries: PAGE_SIZE,
    },
    skip: debouncedSearchQuery === "",
  });

  useEffect(() => {
    setResourceById((resourceById) => {
      return {
        ...resourceById,
        ...searchResourcesData?.resources.resources.reduce((acc, resource) => {
          acc[resource.id] = resource;
          return acc;
        }, {} as typeof resourceById),
      };
    });
  }, [searchResourcesData]);

  const [getResources] = usePaginatedResourceDropdownLazyQuery();
  const [
    addResourceUsers,
    { loading: addLoading },
  ] = useAddResourceUsersMutation();

  if (loading) {
    return <FullscreenSkeleton />;
  }
  if (!authState.user?.isAdmin) {
    return <ForbiddenPage />;
  }
  if (!user || error || rolesError) {
    return <UnexpectedErrorPage error={error} />;
  }

  // Get the roles that the user already has on each resource
  const directRolesByResourceId: Record<string, ResourceAccessLevel[]> = {};
  user.userResources.forEach((userResource) => {
    if (!userResource.access?.directAccessPoint) {
      return;
    }
    if (!directRolesByResourceId[userResource.resourceId]) {
      directRolesByResourceId[userResource.resourceId] = [];
    }
    directRolesByResourceId[userResource.resourceId] = _.uniqBy(
      [
        ...directRolesByResourceId[userResource.resourceId],
        userResource.accessLevel,
      ],
      "accessLevelRemoteId"
    );
  });

  const handleClose = () => {
    transitionBack(`/users/${userId}#resources`);
  };

  const handleFetchResources = async (
    connectionId: string,
    connectionType?: ConnectionType,
    parentResourceId?: string
  ) => {
    let resourceTypes: ResourceType[] | undefined;
    if (connectionType === ConnectionType.AwsSso) {
      resourceTypes = [ResourceType.AwsAccount];
    }
    try {
      setItemsLoadingSubRows((prev) => [
        ...prev,
        parentResourceId ?? connectionId,
      ]);
      const { data } = await getResources({
        variables: {
          input: {
            connectionIds: parentResourceId ? undefined : [connectionId],
            resourceTypes,
            parentResourceId: parentResourceId
              ? {
                  parentResourceId,
                }
              : undefined,
            maxNumEntries: PAGE_SIZE,
          },
        },
      });

      if (parentResourceId) {
        setResourcesByParentId((resourcesByParentId) => {
          return {
            ...resourcesByParentId,
            [parentResourceId]: data?.resources.resources ?? [],
          };
        });
      } else {
        setResourcesByConnectionId((resourcesByConnectionId) => {
          return {
            ...resourcesByConnectionId,
            [connectionId]: data?.resources.resources ?? [],
          };
        });
      }
      setResourceById((resourceById) => {
        return {
          ...resourceById,
          ...data?.resources.resources.reduce((acc, resource) => {
            acc[resource.id] = resource;
            return acc;
          }, {} as typeof resourceById),
        };
      });
      setItemsLoadingSubRows((prev) =>
        prev.filter((id) => id !== (parentResourceId ?? connectionId))
      );
      return data?.resources.resources ?? [];
    } catch (err) {
      logError(err, "Failed to fetch resources for connection " + connectionId);
    }
  };

  const numResourcesToAdd = Object.keys(roleByResourceIdToAdd).length;

  /*
    Disable any resources that the user already has full access to.
    If the user has no direct access then keep the resource enabled.
    Otherwise, if numNonRemoteRoles != 0 and numDirectRoles === numNonRemoteRoles
    then the user has all custom/static roles available on the resource so disable it.
    This will not disable a resource if a user has all remote roles, so a user
    can still select it, but will be shown they have no roles to add later.
    This is done because fetching remote roles upfront is expensive.
  */
  const disabledResourceIds = new Set();
  user.userResources.forEach((userResource) => {
    const numDirectRoles =
      directRolesByResourceId[userResource.resourceId]?.length ?? 0;
    const numNonRemoteRoles =
      allNonRemoteRolesByResourceId[userResource.resourceId]?.length ?? 0;
    if (numDirectRoles != 0 && numDirectRoles === numNonRemoteRoles) {
      disabledResourceIds.add(userResource.resourceId);
    }
  });

  const hasNestedRows = (row: UserResourceRow) => {
    return (
      Boolean(row.connectionType) ||
      row.resourceType === ResourceType.AwsAccount
    );
  };

  const getNestedRows = (row: UserResourceRow) => {
    const resources = (row.connectionType
      ? resourcesByConnectionId[row.id]
      : resourcesByParentId[row.id]
    )?.filter((resource) => !disabledResourceIds.has(resource.id));
    if (resources && resources.length === 0) {
      return [
        {
          id: `${row.id}-empty`,
          name: "No resources",
          isEmpty: true,
        },
      ];
    }
    return resources?.map((resource) => {
      const iconData: IconData = {
        type: "entity",
        entityType: resource.resourceType,
      };
      const row: UserResourceRow = {
        id: resource.id,
        icon: iconData,
        name: resource.name,
        resourceType: resource.resourceType,
        connectionId: resource?.connection?.id,
        sublabel: formatResourceBreadcrumb(
          resource.ancestorPathToResource,
          null
        ),
      };
      rowsById[row.id] = row;
      return row;
    });
  };

  const handleSubmit = async () => {
    if (!user) {
      return;
    }
    logEvent({
      name: "apps_add_user_to_resources",
      properties: {
        numResourcesAddedTo: Object.entries(roleByResourceIdToAdd).length,
      },
    });
    try {
      const resourceUsersToAdd: AddResourceUserInput[] = [];
      for (const [resourceId, roles] of Object.entries(roleByResourceIdToAdd)) {
        const resource = resourceById[resourceId];

        if (!resource) {
          setAddError("failed to add user to resources");
          return;
        }

        // Convert expiration value to duration in minutes, default to undefined.
        const expirationVal =
          accessDurationByResourceIdToAdd[resourceId] ||
          ExpirationValue.Indefinite;
        const accessDurationInMinutes = expirationValueToDurationInMinutes(
          expirationVal
        )?.asMinutes();

        if (roles.length === 0) {
          // If resource requires a role , but none are selected,
          // show an error.
          if (
            resourceRequiresAtLeastOneRole(resource) ||
            allNonRemoteRolesByResourceId[resourceId]?.length > 0
          ) {
            setAddError(
              "Please select at least one role for resources that have roles."
            );
            return;
          } else {
            // If resource does not require roles,
            // add an empty role to add the resource directly.
            resourceUsersToAdd.push({
              userId: user.id,
              resourceId,
              accessLevel: {
                accessLevelName: "",
                accessLevelRemoteId: "",
              },
              durationInMinutes: accessDurationInMinutes,
            });
          }
        }

        roles.forEach((role) => {
          resourceUsersToAdd.push({
            userId: user?.id ?? "",
            resourceId,
            accessLevel: {
              accessLevelName: role.accessLevelName,
              accessLevelRemoteId: role.accessLevelRemoteId,
            },
            durationInMinutes: accessDurationInMinutes,
          });
        });
      }

      const { data } = await addResourceUsers({
        variables: {
          input: {
            resourceUsers: resourceUsersToAdd,
          },
        },
        refetchQueries: ["User"],
      });
      switch (data?.addResourceUsers.__typename) {
        case "AddResourceUsersResult":
          if (data.addResourceUsers.taskId) {
            startPushTaskPoll(data.addResourceUsers.taskId);
          }
          handleClose();
          break;
        case "ResourceNotFoundError":
        case "ResourceUserAlreadyExists":
          setAddError(data.addResourceUsers.message);
          break;
        default:
          logError(new Error(`failed to add resources to user`));
          setAddError("Error: failed to add resources to user");
      }
    } catch (error) {
      logError(error, "failed to add resources to user");
      setAddError(
        getModifiedErrorMessage("Error: failed to add resources to user", error)
      );
    }
  };

  const COLUMNS: Header<UserResourceRow>[] = [
    {
      id: "name",
      label: "Name",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <div
            className={sprinkles({
              display: "flex",
              alignItems: "center",
              gap: "md",
            })}
          >
            <Label label={row.name} icon={row.icon} detail={row.sublabel} />
            {itemsLoadingSubRows.includes(row.id) && <Loader size="xs" />}
          </div>
        );
      },
      width: 500,
    },
  ];

  const getCheckboxDisabledReason = (row: UserResourceRow) => {
    if (row?.isEmpty) {
      return "No resources";
    }
    if (disabledResourceIds.has(row.id)) {
      return "Already added to resource";
    }
  };

  const onCheckedRowsChange = async (
    checkedRowIds: string[],
    checked: boolean
  ) => {
    if (checked) {
      setSelectedRowIds((prev) => [...prev, ...checkedRowIds]);
    } else {
      setSelectedRowIds((prev) =>
        prev.filter((id) => !checkedRowIds.includes(id))
      );
    }
    checkedRowIds.forEach((id) => {
      const row = rowsById[id];
      onCheckRow(row, checked);
    });
  };

  const updateChildRows = async (row: UserResourceRow, checked: boolean) => {
    if (checked) {
      try {
        setSelectedRowIds((selectedRowIds) => [...selectedRowIds, row.id]);
        if (hasNestedRows(row)) {
          let children: ResourceDropdownPreviewFragment[] | undefined;
          if (row.connectionType) {
            if (resourcesByConnectionId[row.id]) {
              children = resourcesByConnectionId[row.id];
            } else {
              children = await handleFetchResources(row.id, row.connectionType);
            }
          } else if (row.resourceType === ResourceType.AwsAccount) {
            if (resourcesByParentId[row.id]) {
              children = resourcesByParentId[row.id];
            } else {
              children = await handleFetchResources(
                "",
                row.connectionType,
                row.id
              );
            }
          }
          ReactDOM.unstable_batchedUpdates(() => {
            children
              ?.filter((resource) => !disabledResourceIds.has(resource.id))
              .forEach((child) => {
                updateChildRows(child, checked);
              });
          });
        } else {
          setRoleByResourceIdToAdd((prev) => {
            return {
              ...prev,
              [row.id]: [],
            };
          });
        }
      } catch (err) {
        logError(err, "Failed to fetch resources for connection");
      }
    } else {
      setSelectedRowIds((selectedRowIds) =>
        selectedRowIds.filter((id) => id !== row.id)
      );
      if (hasNestedRows(row)) {
        const children = getNestedRows(row);
        children?.forEach((child) => {
          updateChildRows(child, checked);
        });
      } else {
        setRoleByResourceIdToAdd((prev) => {
          const newRoles = { ...prev };
          delete newRoles[row.id];
          return newRoles;
        });
      }
    }
  };

  const onCheckRow = async (row: UserResourceRow, checked: boolean) => {
    if (checked) {
      setSelectedRowIds((selectedRowIds) => [...selectedRowIds, row.id]);
      if (!hasNestedRows(row)) {
        setRoleByResourceIdToAdd((prev) => {
          return {
            ...prev,
            [row.id]: [],
          };
        });
      }
    } else {
      setSelectedRowIds((selectedRowIds) =>
        selectedRowIds.filter((id) => id !== row.id)
      );
      if (!hasNestedRows(row)) {
        setRoleByResourceIdToAdd((prev) => {
          const newRoles = { ...prev };
          delete newRoles[row.id];
          return newRoles;
        });
      }
    }
    if (hasNestedRows(row)) {
      let children: ResourceDropdownPreviewFragment[] | undefined;
      if (row.connectionType) {
        if (resourcesByConnectionId[row.id]) {
          children = resourcesByConnectionId[row.id];
        } else {
          children = await handleFetchResources(row.id, row.connectionType);
        }
      } else if (row.resourceType === ResourceType.AwsAccount) {
        if (resourcesByParentId[row.id]) {
          children = resourcesByParentId[row.id];
        } else {
          children = await handleFetchResources("", row.connectionType, row.id);
        }
      }
      ReactDOM.unstable_batchedUpdates(() => {
        children
          ?.filter((resource) => !disabledResourceIds.has(resource.id))
          .forEach((child) => {
            updateChildRows(child, checked);
          });
      });
    }
  };

  const onRowClick = async (row: UserResourceRow) => {
    if (row.isEmpty || disabledResourceIds.has(row.id)) {
      return;
    }
    if (hasNestedRows(row)) {
      if (row.connectionType && !resourcesByConnectionId[row.id]) {
        await handleFetchResources(row.id, row.connectionType);
      }
      if (
        row.resourceType === ResourceType.AwsAccount &&
        !resourcesByParentId[row.id]
      ) {
        await handleFetchResources("", row.connectionType, row.id);
      }
    } else {
      onCheckRow(row, !selectedRowIds.includes(row.id));
    }
  };

  const renderConnectionsList = () => {
    const rows: UserResourceRow[] = connections.map((connection) => {
      const row: UserResourceRow = {
        id: connection.id,
        icon: {
          type: "src",
          icon: getConnectionTypeInfo(connection.connectionType)?.icon,
        },
        name: connection.name,
        connectionType: connection.connectionType,
      };
      rowsById[row.id] = row;
      return row;
    });

    return (
      <Table
        columns={COLUMNS}
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        getRowCanExpand={(row) => hasNestedRows(row.original)}
        loadingRows={loading || searchResourcesLoading}
        defaultSortBy="name"
        checkedRowIds={new Set(selectedRowIds)}
        onCheckedRowsChange={onCheckedRowsChange}
        getCheckboxDisabledReason={getCheckboxDisabledReason}
        onRowClick={onRowClick}
        onExpandRow={(row) => {
          if (row.connectionType && !resourcesByConnectionId[row.id]) {
            handleFetchResources(row.id, row.connectionType);
          }
          if (
            row.resourceType === ResourceType.AwsAccount &&
            !resourcesByParentId[row.id]
          ) {
            handleFetchResources("", row.connectionType, row.id);
          }
        }}
        getChildRows={getNestedRows}
        expandOnChecked={true}
        expandOnRowClick={true}
      />
    );
  };

  const renderSearchList = () => {
    if (searchResourcesError) {
      return <ModalErrorMessage errorMessage={searchResourcesError.message} />;
    }

    const filteredResources = (
      searchResourcesData?.resources.resources ?? []
    ).filter((resource) => !disabledResourceIds.has(resource.id));

    const rows: UserResourceRow[] = filteredResources.map((resource) => {
      const iconData: IconData = {
        type: "entity",
        entityType: resource.resourceType,
      };

      const row: UserResourceRow = {
        id: resource.id,
        icon: iconData,
        name: resource.name,
        resourceType: resource.resourceType,
        connectionId: resource?.connection?.id,
        sublabel: formatResourceBreadcrumb(
          resource.ancestorPathToResource,
          null,
          resource.connection?.name
        ),
      };
      rowsById[row.id] = row;
      return row;
    });

    return (
      <Table
        columns={COLUMNS}
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        getRowCanExpand={(row) => hasNestedRows(row.original)}
        loadingRows={loading || searchResourcesLoading}
        defaultSortBy="name"
        checkedRowIds={new Set(selectedRowIds)}
        onCheckedRowsChange={onCheckedRowsChange}
        getCheckboxDisabledReason={getCheckboxDisabledReason}
        onRowClick={onRowClick}
        getChildRows={getNestedRows}
      />
    );
  };

  return (
    <FullscreenView
      title={
        <FullscreenViewTitle
          entityType={EntityType.User}
          entityName={user.fullName}
          targetEntityName="Resources"
          action="add"
        />
      }
      onCancel={handleClose}
      onPrimaryButtonClick={handleSubmit}
      primaryButtonDisabled={numResourcesToAdd === 0}
      primaryButtonLabel={`Add ${
        numResourcesToAdd ? numResourcesToAdd : ""
      } ${pluralize("resource", numResourcesToAdd)}`}
      primaryButtonLoading={addLoading}
    >
      <FullscreenView.Content fullWidth>
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textMd",
              fontWeight: "medium",
              marginBottom: "md",
            })}
          >
            Select resources to add to the user:
          </div>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder="Search by name"
              autoFocus
            />
          </div>
          <div className={sprinkles({ color: "gray600", fontSize: "textXs" })}>
            {debouncedSearchQuery === ""
              ? "Showing first 100 resources in each app. Use search to find more results."
              : "Showing first 100 search results. Refine your search to find more."}
          </div>
          <Divider />
          {debouncedSearchQuery === ""
            ? renderConnectionsList()
            : renderSearchList()}
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {addError && (
          <Banner message={addError} type="error" marginBottom="lg" />
        )}
        <div
          className={sprinkles({
            fontSize: "textLg",
            fontWeight: "medium",
            marginBottom: "lg",
          })}
        >
          Adding {numResourcesToAdd} {pluralize("Resource", numResourcesToAdd)}
        </div>
        {Object.keys(roleByResourceIdToAdd).map((resourceId) => {
          const resource = resourceById[resourceId];
          if (!resource) {
            return null;
          }

          return (
            <ResourceCard
              key={resource.id}
              resource={resource}
              existingRoles={directRolesByResourceId[resourceId] ?? []}
              selectedRoles={roleByResourceIdToAdd[resourceId] ?? []}
              onRemove={() => {
                setRoleByResourceIdToAdd((prev) => {
                  const newRoles = { ...prev };
                  delete newRoles[resourceId];
                  return newRoles;
                });
                setSelectedRowIds((prev) =>
                  prev.filter(
                    (id) => id !== resource.id && id !== resource.connection?.id
                  )
                );
              }}
              onUpdateSelectedRoles={(roles) => {
                setRoleByResourceIdToAdd((prev) => {
                  return {
                    ...prev,
                    [resourceId]: roles,
                  };
                });
              }}
              onUpdateAllRoles={(roles) => {
                allNonRemoteRolesByResourceId[resource.id] = roles;
              }}
              accessDuration={
                accessDurationByResourceIdToAdd[resourceId] ??
                ExpirationValue.Indefinite
              }
              setAccessDuration={(access) => {
                setAccessDurationByResourceIdToAdd((prev) => {
                  return {
                    ...prev,
                    [resourceId]: access,
                  };
                });
              }}
            />
          );
        })}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};

export default UserAddResourcesView;
