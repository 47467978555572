import { Icon } from "components/ui";
import { generateUniqueId } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import { useMemo, useState } from "react";
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from "react-beautiful-dnd";

import * as styles from "./DragBubbles.css";

interface DragBubblesProps {
  handleDragEnd: (result: DropResult) => void;
  isDragDisabled: boolean;
  items: {
    id: string;
    priorityLabel?: number;
    isDragDisabled: boolean;
    titleContent: React.ReactNode;
    innerContent?: React.ReactNode;
  }[];
  showAddButton?: boolean;
  addButtonLabel?: string;
  onAdd?: () => void;
  slimBubbles?: boolean;
}

const DragBubbles = (props: DragBubblesProps) => {
  const listId = useMemo(() => generateUniqueId("request-config-list"), []);
  return (
    <>
      <DragDropContext onDragEnd={props.handleDragEnd}>
        <Droppable droppableId={listId}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {props.items.map((item, index) => {
                if (item.isDragDisabled) {
                  return (
                    <DragBubble
                      key={item.id}
                      isDragDisabled={props.isDragDisabled}
                      item={item}
                      isLast={
                        !props.showAddButton && index === props.items.length - 1
                      }
                    />
                  );
                }
                return (
                  <Draggable
                    key={item.id}
                    draggableId={item.id}
                    index={index}
                    isDragDisabled={props.isDragDisabled || item.isDragDisabled}
                  >
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <DragBubble
                          isDragDisabled={props.isDragDisabled}
                          item={item}
                          slimBubbles={props.slimBubbles}
                          isLast={
                            !props.showAddButton &&
                            index === props.items.length - 1
                          }
                        />
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {props.showAddButton ? (
        <div className={styles.addButton} onClick={props.onAdd}>
          <Icon name="plus" color="blue600" size="sm" />
          {props.addButtonLabel}
        </div>
      ) : null}
    </>
  );
};

interface DragBubbleProps {
  isDragDisabled: boolean;
  item: DragBubblesProps["items"][0];
  isLast?: boolean;
  slimBubbles?: boolean;
}
const DragBubble = (props: DragBubbleProps) => {
  const [expanded, setExpanded] = useState(false);

  return (
    <div
      className={styles.wrapper({
        isLast: props.isLast,
        slim: props.slimBubbles,
      })}
    >
      <div className={styles.container({ slim: props.slimBubbles })}>
        <div
          className={sprinkles({
            display: "flex",
            cursor: "pointer",
            alignItems: "center",
          })}
          onClick={() =>
            props.item.innerContent && setExpanded((prev) => !prev)
          }
        >
          <div
            className={sprinkles({ flexGrow: 1, display: "flex", gap: "md" })}
          >
            {!props.isDragDisabled ? (
              <div>
                <Icon
                  name="dots-grid-2"
                  size="xs"
                  color={props.item.isDragDisabled ? "gray100" : "gray400"}
                />
              </div>
            ) : null}
            {!props.slimBubbles ? (
              <div className={styles.priority}>
                {props.item.priorityLabel !== undefined
                  ? props.item.priorityLabel
                  : "—"}
              </div>
            ) : null}
            {props.item.titleContent}
          </div>
          {props.item.innerContent && (
            <Icon name={expanded ? "chevron-up" : "chevron-down"} size="xs" />
          )}
        </div>
        {expanded ? props.item.innerContent : null}
      </div>
    </div>
  );
};

export default DragBubbles;
