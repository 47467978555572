import { KeyboardDatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";

import * as styles from "./DateFilterInput.css";

interface Props {
  type: "start" | "end";
  date?: string;
  onDateChange: (date: MaterialUiPickersDate) => void;
  error?: boolean;
}

const DateFilterInput = (props: Props) => {
  const { type, date, onDateChange, error } = props;

  return (
    <KeyboardDatePicker
      key={type + "-date-filter"}
      className={styles.datePicker}
      value={date ? moment(date, "YYYY-MM-DD") : null}
      placeholder={type === "start" ? "Start Date" : "End Date"}
      onChange={onDateChange}
      minDate={moment("2020-01-01", "YYYY-MM-DD")}
      format="YYYY/MM/DD"
      invalidDateMessage={null}
      autoOk={true}
      maxDateMessage={null}
      minDateMessage={null}
      InputProps={{
        classes: {
          root: styles.datePickerInput({ error }),
        },
        disableUnderline: true,
      }}
      InputAdornmentProps={{
        position: "start",
        classes: {
          root: styles.datePickerAdornment,
        },
      }}
      KeyboardButtonProps={{
        classes: {
          root: styles.datePickerAdornmentButton,
        },
      }}
    />
  );
};

export default DateFilterInput;
