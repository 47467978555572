import { Maybe, ResourcePreviewLargeFragment } from "api/generated/graphql";
import { SectionEntry } from "components/viewer/Viewer";

export const RESOURCE_DESCRIPTION_TITLE = "Description";

type ResourceDescriptionRowProps = {
  resource?: Maybe<ResourcePreviewLargeFragment>;
};

export const ResourceDescriptionRow = (props: ResourceDescriptionRowProps) => {
  return (
    <SectionEntry title={RESOURCE_DESCRIPTION_TITLE}>
      <>{props.resource?.description || "--"}</>
    </SectionEntry>
  );
};

export default ResourceDescriptionRow;
