import { EntityType } from "api/generated/graphql";
import NavigationContext from "components/layout/NavigationContextProvider";
import React, { useContext } from "react";
import styles from "views/access_reviews/AccessReviewProgressBar.module.scss";

type AccessReviewProgressBarProps = {
  accessReviewId: string;
  numCompleted: number;
  numTotal: number;
  entityType?: EntityType;
};

export const AccessReviewProgressBar = (
  props: AccessReviewProgressBarProps
) => {
  const { navigationState } = useContext(NavigationContext);

  let entityTypeText = props.entityType?.toLowerCase();
  if (entityTypeText === "connection") {
    entityTypeText = "app";
  }

  let progressBarPercentage;
  let description;
  if (props.numTotal) {
    progressBarPercentage = (100 / props.numTotal) * props.numCompleted;
    description = `${props.numCompleted}/${props.numTotal} ${entityTypeText} reviews completed`;
  } else {
    progressBarPercentage = 0;
    if (navigationState.isOnlyMyReviewsFilterOn) {
      description = `You have no assigned reviews`;
    } else {
      description = `There is nothing to review`;
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.outerBar}>
        <span
          className={styles.innerBar}
          style={{
            width: progressBarPercentage + "%",
          }}
        />
      </div>
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default AccessReviewProgressBar;
