import { gql, useFragment } from "@apollo/client";
import { OpalAppIconFragment } from "api/generated/graphql";
import { EntityIcon, Icon, Skeleton } from "components/ui";
import { getAppIcon } from "views/apps/utils";

const OpalAppIcon = (props: { appID: string }) => {
  const { complete, data } = useFragment<OpalAppIconFragment>({
    fragment: gql`
      fragment OpalAppIcon on App {
        app {
          __typename
          ... on ConnectionApp {
            connectionIconUrl
            connectionType
          }
          ... on OktaResourceApp {
            iconUrl
          }
        }
      }
    `,
    fragmentName: "OpalAppIcon",
    from: {
      __typename: "App",
      id: props.appID,
    },
  });
  if (!complete) {
    return <Skeleton variant="text" width="50px" />;
  }

  const iconData = getAppIcon(data.app);
  if (iconData.type === "entity") {
    return <EntityIcon type={iconData.entityType} includeBrand size="md" />;
  }
  return <Icon data={iconData} size="md" />;
};

export default OpalAppIcon;
