import confetti from "canvas-confetti";

export const opalConfetti = () => {
  const count = 200;
  const defaults = {
    origin: { y: 0.7 },
  };
  const colors = [
    "#6AF5B6",
    "#FFAD81",
    "#FF748F",
    "#6E5BCD",
    "#67DCEC",
    "#FFDF6B",
    "#FFFFFF",
  ];

  function fire(particleRatio: number, opts: confetti.Options) {
    confetti(
      Object.assign({}, defaults, opts, {
        particleCount: Math.floor(count * particleRatio),
        colors: colors,
        zIndex: 1200, // modals have z-index of 1100, this needs to be higher
      })
    );
  }

  fire(0.25, {
    spread: 26,
    startVelocity: 55,
  });
  fire(0.2, {
    spread: 60,
  });
  fire(0.35, {
    spread: 100,
    decay: 0.91,
    scalar: 0.8,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 25,
    decay: 0.92,
    scalar: 1.2,
  });
  fire(0.1, {
    spread: 120,
    startVelocity: 45,
  });
};
