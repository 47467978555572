import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { FormGroup, Modal, Select } from "components/ui";
import pluralize from "pluralize";
import { useState } from "react";
import { ExpirationValue } from "views/requests/utils";

export const BulkUpdateExpirationModal = (props: {
  isOpen: boolean;
  onSubmit: (expiration: ExpirationValue) => void;
  onClose: () => void;
  errorMessage?: string;
  selectedItemIds: string[];
  entityName: string;

  loading: boolean;
}) => {
  const [expiration, setExpiration] = useState<ExpirationValue>(
    ExpirationValue.OneWeek
  );

  return (
    <Modal isOpen title="Update Expiration" onClose={props.onClose}>
      <Modal.Body>
        {props.errorMessage && (
          <ModalErrorMessage errorMessage={props.errorMessage} />
        )}
        Select the duration of access you want to apply to the{" "}
        {pluralize(
          "selected direct role assignment",
          props.selectedItemIds.length,
          true
        )}{" "}
        in <b>{props.entityName}</b>?
        <br />
        <FormGroup label="">
          {/* no label for the select, the formgroup is just for layout */}
          <Select
            options={Object.values(ExpirationValue)}
            value={expiration}
            onChange={(val) => {
              if (val) setExpiration(val);
            }}
            disableBuiltInFiltering
            getOptionLabel={(expirationVal) =>
              expirationVal === ExpirationValue.Indefinite
                ? "Indefinite access"
                : `Access for ${expirationVal}`
            }
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Update"
        primaryButtonDisabled={props.selectedItemIds.length === 0}
        primaryButtonLoading={props.loading}
        onPrimaryButtonClick={() => {
          props.onSubmit(expiration);
        }}
      />
    </Modal>
  );
};
