import {
  EntityType,
  GroupType,
  OwnersSortByField,
  SortDirection,
  useOwnersTableQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column, ColumnContainer } from "components/column/Column";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import GroupSearchDropdown from "components/dropdown/GroupSearchDropdown";
import OpalPage from "components/opal/layout/OpalPage";
import { ButtonV3, Input, Label } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableFilters from "components/ui/table/TableFilters";
import TableHeader from "components/ui/table/TableHeader";
import moment from "moment";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom-v5-compat";
import { getResourceUrlNew } from "utils/common";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import OwnerCreateModal from "./OwnerCreateModal";
import OwnerDetailV3 from "./OwnerDetailV3";
import * as styles from "./OwnersColumnV3.css";

function isSortableField(str: string): str is OwnersSortByField {
  return Object.values<string>(OwnersSortByField).includes(str);
}

type SortValue = {
  field: OwnersSortByField;
  direction: SortDirection;
};

interface OwnerRow {
  id: string;
  [OwnersSortByField.Name]: string;
  numUsers: number;
  numResourcesAsAdmin: number;
  numResourcesAsReviewer: number;
  [OwnersSortByField.SourceGroupName]:
    | {
        id: string;
        name: string;
        groupType: GroupType;
      }
    | undefined;
  [OwnersSortByField.CreatedAt]: string;
}

const OWNER_COLUMNS: Header<OwnerRow>[] = [
  {
    id: OwnersSortByField.Name,
    label: "Name",
    sortable: true,
    customCellRenderer: (row) => {
      return (
        <Link
          to={getResourceUrlNew({
            entityId: row.id,
            entityType: EntityType.Owner,
          })}
        >
          {row[OwnersSortByField.Name]}
        </Link>
      );
    },
  },
  {
    id: "numUsers",
    label: "Users",
    customCellRenderer: (row) => {
      return <div>{`${pluralize("User", row.numUsers, true)}`}</div>;
    },
    sortable: false,
  },
  {
    id: "numResourcesAsAdmin",
    label: "Assigned Resources as Admin",
    customCellRenderer: (row) => {
      return (
        <div>{`${pluralize("Resource", row.numResourcesAsAdmin, true)}`}</div>
      );
    },
    sortable: false,
  },
  {
    id: "numResourcesAsReviewer",
    label: "Assigned Resources as Reviewer",
    customCellRenderer: (row) => {
      return (
        <div>{`${pluralize(
          "Resource",
          row.numResourcesAsReviewer,
          true
        )}`}</div>
      );
    },
    sortable: false,
  },
  {
    id: OwnersSortByField.SourceGroupName,
    label: "Source Group",
    customCellRenderer: (row) => {
      const sourceGroup = row[OwnersSortByField.SourceGroupName];
      if (!sourceGroup) {
        return <div>—</div>;
      }
      return (
        <Label
          label={sourceGroup.name}
          linkTo={getResourceUrlNew({
            entityId: sourceGroup.id,
            entityType: EntityType.Group,
          })}
          icon={{
            type: "entity",
            entityType: sourceGroup.groupType,
          }}
        />
      );
    },
    sortable: true,
  },
  {
    id: OwnersSortByField.CreatedAt,
    label: "Created",
    customCellRenderer: (row) => {
      return <div>{moment(row[OwnersSortByField.CreatedAt]).fromNow()}</div>;
    },
    sortable: true,
  },
];

const SEARCH_QUERY_KEY = "q";
const SOURCE_GROUP_IDS_KEY = "sourceGroupIds";

const OwnersColumnV3 = () => {
  const transitionTo = useTransitionTo();
  const { ownerId } = useParams<Record<string, string>>();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [filters, setFilters] = useSearchParams({});
  const debouncedFilters = useDebouncedValue(filters, 300);
  const [sortBy, setSortBy] = useState<SortValue | undefined>({
    field: OwnersSortByField.Name,
    direction: SortDirection.Asc,
  });

  const { data, previousData, error, loading, fetchMore } = useOwnersTableQuery(
    {
      fetchPolicy: "cache-and-network",
      variables: {
        input: {
          filters: {
            searchQuery: debouncedFilters.get(SEARCH_QUERY_KEY),
            sourceGroupIds: debouncedFilters.getAll(SOURCE_GROUP_IDS_KEY),
          },
          sortBy: sortBy,
        },
      },
    }
  );

  const { authState } = useContext(AuthContext);
  const isAdmin = authState.user?.isAdmin ?? false;

  const cursor = data?.owners.cursor;
  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            input: {
              cursor,
              filters: debouncedFilters,
              sortBy,
            },
          },
        });
      }
    : undefined;

  const owners = data?.owners.owners ?? previousData?.owners.owners ?? [];
  const totalNumOwners =
    data?.owners.totalNumOwners ?? previousData?.owners.totalNumOwners;

  const rows: OwnerRow[] = owners.map((owner) => {
    let numResourcesAsAdmin = 0;
    let numResourcesAsReviewer = 0;
    owner.ownedResources.forEach((resource) => {
      if (resource.isAdmin) {
        numResourcesAsAdmin++;
      }
      if (resource.isReviewer) {
        numResourcesAsReviewer++;
      }
    });
    owner.ownedGroups.forEach((group) => {
      if (group.isAdmin) {
        numResourcesAsAdmin++;
      }
      if (group.isReviewer) {
        numResourcesAsReviewer++;
      }
    });

    return {
      id: owner.id,
      [OwnersSortByField.Name]: owner.name,
      numUsers: owner.ownerUsers.length,
      numResourcesAsAdmin,
      numResourcesAsReviewer,
      [OwnersSortByField.SourceGroupName]: owner.sourceGroup
        ? { ...owner.sourceGroup }
        : undefined,
      [OwnersSortByField.CreatedAt]: owner.createdAt,
    };
  });

  if (error) {
    logError(error, "failed to list owners");
    return (
      <ColumnContainer>
        <Column isContent>
          <UnexpectedErrorPage error={error} />
        </Column>
      </ColumnContainer>
    );
  }

  if (ownerId) {
    return (
      <ColumnContainer>
        <OwnerDetailV3 />
      </ColumnContainer>
    );
  }

  let hasFilters = false;
  filters.forEach((value) => {
    if (Array.isArray(value)) {
      hasFilters = value.length > 0;
    } else if (typeof value === "string") {
      hasFilters = value !== "";
    }
  });

  return (
    <>
      <OpalPage icon="user-square" title="Owners">
        <TableFilters>
          <TableFilters.Left>
            <div className={styles.filterInput}>
              <Input
                leftIconName="search"
                type="search"
                style="search"
                value={filters.get(SEARCH_QUERY_KEY) ?? ""}
                onChange={(query) => {
                  setFilters((params) => {
                    if (query === "") {
                      params.delete(SEARCH_QUERY_KEY);
                    } else {
                      params.set(SEARCH_QUERY_KEY, query);
                    }
                    return params;
                  });
                }}
                placeholder="Filter by name"
              />
            </div>
            <div className={styles.filterInput}>
              <GroupSearchDropdown
                placeholder="Filter by source group"
                placeholderIcon={{ type: "name", icon: "users" }}
                selectedGroupIds={filters.getAll(SOURCE_GROUP_IDS_KEY) ?? []}
                size="sm"
                onSelect={({ groups, actionType }) => {
                  if (actionType === "select-option") {
                    setFilters((params) => {
                      groups
                        .map((group) => group.id)
                        .forEach((id) => {
                          params.append("sourceGroupIds", id);
                        });
                      return params;
                    });
                  } else if (actionType === "remove-option") {
                    setFilters((params) => {
                      const currentGroupIds = params.getAll(
                        SOURCE_GROUP_IDS_KEY
                      );
                      const groupIdsToRemove = groups.map((group) => group.id);
                      params.delete(SOURCE_GROUP_IDS_KEY);
                      currentGroupIds
                        .filter((id) => !groupIdsToRemove.includes(id))
                        .forEach((id) => {
                          params.append(SOURCE_GROUP_IDS_KEY, id);
                        });
                      return params;
                    });
                  }
                }}
              />
            </div>

            {hasFilters && (
              <ButtonV3
                label="Clear filters"
                type="mainBorderless"
                size="sm"
                onClick={() => setFilters({})}
              />
            )}
          </TableFilters.Left>
        </TableFilters>

        <TableHeader
          entityType={EntityType.Owner}
          totalNumRows={totalNumOwners ?? rows.length}
          loading={loading}
          defaultRightActions={
            isAdmin
              ? [
                  {
                    label: "Owner",
                    type: "main",
                    iconName: "plus",
                    onClick: () => {
                      setShowCreateModal(true);
                    },
                  },
                ]
              : undefined
          }
        />
        <>
          {loading && !data && !previousData ? (
            <ColumnListItemsSkeleton />
          ) : (
            <Table
              rows={rows}
              totalNumRows={cursor ? totalNumOwners || 0 : rows.length}
              emptyState={{ title: "No owners to display." }}
              getRowId={(ru) => ru.id}
              columns={OWNER_COLUMNS}
              onRowClick={(row, event) => {
                transitionTo(
                  {
                    pathname: getResourceUrlNew({
                      entityId: row.id,
                      entityType: EntityType.Owner,
                    }),
                  },
                  event
                );
              }}
              onLoadMoreRows={loadMoreRows}
              manualSortDirection={
                sortBy && {
                  sortBy: sortBy.field,
                  sortDirection: sortBy.direction,
                }
              }
              handleManualSort={(sortBy, sortDirection) => {
                if (!sortDirection) {
                  setSortBy(undefined);
                  return;
                }
                if (!isSortableField(sortBy)) {
                  return;
                }
                const direction: SortDirection =
                  sortDirection === "DESC"
                    ? SortDirection.Desc
                    : SortDirection.Asc;

                setSortBy({
                  field: sortBy,
                  direction,
                });
              }}
            />
          )}
        </>
      </OpalPage>
      {showCreateModal ? (
        <OwnerCreateModal onClose={() => setShowCreateModal(false)} />
      ) : null}
    </>
  );
};

export default OwnersColumnV3;
