import { EntityType, Maybe } from "api/generated/graphql";

export const openInNewTab = (url: string) => {
  const newWindow = window.open(url, "_blank", "noopener,noreferrer");
  if (newWindow) newWindow.opener = null;
};

export type EntityId = string;

export type EntityIdTupleNullable = {
  entityId: Maybe<EntityId>;
  entityType: Maybe<EntityType>;
};

// Removing fractional seconds from timestamp before displaying
export const formatTimestampForDisplay = (timestamp: string): string => {
  const date = new Date(timestamp);
  return date.toISOString().split(".")[0] + "Z";
};
