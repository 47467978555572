import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewAction,
  AccessReviewGroupFragment,
  AccessReviewGroupPreviewFragment,
  AccessReviewGroupUserPreviewFragment,
  EntityType,
  ReviewerUserStatus,
  ReviewGroupUserAction,
  useUpdateGroupUserReviewersMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { EntityViewerTabType } from "components/entity_viewer/EntityViewerTabs";
import AccessReviewNoteLabel from "components/label/AccessReviewNoteLabel";
import { ResourceLabel } from "components/label/Label";
import NavigationContext from "components/layout/NavigationContextProvider";
import AccessReviewerModal from "components/modals/AccessReviewerModal";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import {
  CellRow,
  Header,
  ScrollableMuiVirtualTable,
} from "components/tables/material_table/MuiVirtualTable";
import { useToast } from "components/toast/Toast";
import { Popover } from "components/ui";
import { IconName } from "components/ui/icon/Icon";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { SortDirection } from "react-virtualized";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { logError } from "utils/logging";
import AccessReviewAcceptRevokeToggleButton, {
  calculateBatchAcceptRevokeState,
} from "views/access_reviews/AccessReviewAcceptRevokeToggleButton";
import AccessReviewContext, {
  AccessReviewContextActionType,
} from "views/access_reviews/AccessReviewContext";
import AccessReviewGroupRoleCell, {
  updateReviewStateForGroupUserRoleChange,
} from "views/access_reviews/AccessReviewGroupRoleCell";
import AccessReviewReviewerDetails, {
  accessReviewReviewerDetailsRowHeight,
} from "views/access_reviews/AccessReviewReviewerDetails";
import AccessReviewReviewersCell from "views/access_reviews/AccessReviewReviewersCell";
import {
  accessReviewItemStatusSortValue,
  AccessReviewOutcomeLabel,
  AccessReviewStatusLabel,
} from "views/access_reviews/AccessReviewStatus";
import {
  getReviewerModalEntries,
  getReviewersSortValue,
  isReviewer,
} from "views/access_reviews/common/Common";
import { getAccessReviewUserUrl } from "views/access_reviews/common/Routes";
import {
  ITEM_ACTION_HEADER,
  ITEM_STATUS_HEADER,
  NOTE_HEADER,
  OUTCOME_HEADER,
  REVIEWERS_HEADER,
  ROLE_HEADER,
  USER_NAME_HEADER,
} from "views/access_reviews/common/TableHeaders";
import AccessReviewUserOverview from "views/access_reviews/users/AccessReviewUserOverview";

interface AccessReviewGroupUserTableRow {
  name: string;
  role: string;
  note?: string;
  reviewers?: string;
  status?: string;
  outcome?: string;
  acceptRevoke?: string;
}

type AccessReviewGroupUsersTableProps = {
  accessReviewGroup: AccessReviewGroupFragment;
  setSelectedTab: (tab: EntityViewerTabType) => void;
  selectedRows?: Record<string, EntityType>;
  setSelectedRows?: (value: Record<string, EntityType>) => void;
};

export const AccessReviewGroupUsersTable = (
  props: AccessReviewGroupUsersTableProps
) => {
  const { navigationState } = useContext(NavigationContext);
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );
  const { authState } = useContext(AuthContext);
  const history = useHistory();

  const selfReviewAllowed =
    props.accessReviewGroup.accessReview?.selfReviewAllowed;

  const [expandedByRowId, setExpandedByRowId] = useState<
    Record<string, boolean>
  >({});

  let users = props.accessReviewGroup.groupUsers || [];

  if (navigationState.isOnlyMyReviewsFilterOn) {
    users = users.filter((accessReviewGroupUser) => {
      return (
        authState.user?.user.id &&
        isReviewer(
          authState.user.user.id,
          accessReviewGroupUser.reviewerUsers ?? []
        )
      );
    });
  }

  const performReviewState =
    accessReviewState.performReviewStateByUARGroupId[
      props.accessReviewGroup.id
    ];
  const isGroupBeingReviewed = !!performReviewState;

  let currentUserHasCompleteReviews = false;

  if (isGroupBeingReviewed) {
    users = users.filter((user) => {
      return user.reviewerUsers?.find((reviewer) => {
        if (reviewer.userId === authState.user?.user.id) {
          currentUserHasCompleteReviews = true;
          return (
            (selfReviewAllowed || user.userId !== authState.user?.user.id) &&
            reviewer.status === ReviewerUserStatus.NotStarted
          );
        }
        return false;
      });
    });
  }

  // Don't show role column if the group doesn't have roles specified
  const groupHasAccessLevels = users.some(
    (user) => !!user.accessLevel.accessLevelRemoteId
  );

  /*
   * Figure out which columns to show depending on if the client is
   * performing reviews or viewing reviews.
   */

  // We always show these columns.
  const headers: Header<AccessReviewGroupUserTableRow>[] = [USER_NAME_HEADER];
  if (groupHasAccessLevels) {
    headers.push(ROLE_HEADER, REVIEWERS_HEADER);
  } else {
    headers.push(REVIEWERS_HEADER);
  }

  // These are the conditional columns.
  if (isGroupBeingReviewed && users.length > 0) {
    const batchAcceptRevokeToggleButton = (
      <AccessReviewAcceptRevokeToggleButton
        state={calculateBatchAcceptRevokeState(
          performReviewState.groupUserActions,
          users.length
        )}
        acceptText={"Accept All"}
        revokeText={"Revoke All"}
        onStateChange={(state) => {
          if (!isGroupBeingReviewed) return;

          performReviewState.groupUserActions.forEach((groupUserAction) => {
            groupUserAction.action = state;
          });

          users.forEach((groupUser) => {
            const existingInfo = performReviewState.groupUserActions.find(
              (groupUserAction) =>
                groupUserAction.accessReviewGroupUserId === groupUser.id
            );

            if (!existingInfo) {
              performReviewState.groupUserActions.push({
                accessReviewGroupUserId: groupUser.id,
                action: state,
              });
            }
          });

          accessReviewDispatch({
            type: AccessReviewContextActionType.AccessReviewItemUpdate,
            payload: {
              performReviewStateByUARGroupId: {
                ...accessReviewState.performReviewStateByUARGroupId,
                [props.accessReviewGroup.id]: performReviewState,
              },
            },
          });
        }}
      />
    );
    headers.push(
      {
        ...ITEM_ACTION_HEADER,
        customHeader: batchAcceptRevokeToggleButton,
      },
      NOTE_HEADER
    );
  } else {
    headers.push(ITEM_STATUS_HEADER, OUTCOME_HEADER);
  }

  /*
   * Table rows data
   */

  const groupUserActionByUarGroupUserId: Record<
    string,
    ReviewGroupUserAction
  > = {};
  performReviewState?.groupUserActions?.forEach((groupUserAction) => {
    const id = groupUserAction.accessReviewGroupUserId;
    groupUserActionByUarGroupUserId[id] = groupUserAction;
  });

  const rows: CellRow<AccessReviewGroupUserTableRow>[] = users.map(
    (groupUser, idx) => {
      const rowId = groupUser.id;
      const groupUserAction = groupUserActionByUarGroupUserId[rowId];
      const user = groupUser.user;
      const name = user?.fullName || groupUser.userId;
      const status = groupUser.statusAndOutcome.status;
      const outcome = groupUser.statusAndOutcome.outcome;

      return {
        id: rowId,
        expandableContent: {
          content: (
            <AccessReviewReviewerDetails reviewers={groupUser.reviewerUsers} />
          ),
          expandedRowHeight: accessReviewReviewerDetailsRowHeight(
            groupUser.reviewerUsers
          ),
          isExpanded: expandedByRowId[rowId],
          setIsExpanded: (expanded) => {
            setExpandedByRowId({
              ...expandedByRowId,
              [rowId]: expanded,
            });
          },
        },
        data: {
          name: {
            value: name,
            element: (
              <Popover
                content={<AccessReviewUserOverview userId={groupUser.userId} />}
                inline
              >
                <ResourceLabel
                  text={name}
                  subText={groupUser.user?.teamAttr}
                  entityType={EntityTypeDeprecated.User}
                  iconLarge={true}
                  bold={true}
                  avatar={groupUser.user?.avatarUrl}
                  pointerCursor={true}
                />
              </Popover>
            ),
            clickHandler: () => {
              history.push(
                getAccessReviewUserUrl(
                  props.accessReviewGroup.accessReviewId,
                  groupUser.userId
                )
              );
            },
          },
          role: {
            value: groupUser.accessLevel.accessLevelName || "",
            element: (
              <AccessReviewGroupRoleCell
                role={groupUser.accessLevel}
                updatedRole={groupUserAction?.updatedAccessLevel}
                showEditIcon={isGroupBeingReviewed}
                groupId={groupUser.groupId}
                onRoleChange={(role) => {
                  if (!performReviewState) {
                    return;
                  }

                  const updatedPerformReviewState = updateReviewStateForGroupUserRoleChange(
                    performReviewState,
                    groupUser,
                    role
                  );

                  accessReviewDispatch({
                    type: AccessReviewContextActionType.AccessReviewItemUpdate,
                    payload: {
                      performReviewStateByUARGroupId: {
                        ...accessReviewState.performReviewStateByUARGroupId,
                        [props.accessReviewGroup.id]: updatedPerformReviewState,
                      },
                    },
                  });
                }}
              />
            ),
          },
          reviewers: {
            value: getReviewersSortValue(groupUser.reviewerUsers ?? []),
            element: (
              <GroupUserAccessReviewersCell
                accessReviewGroup={props.accessReviewGroup}
                groupUser={groupUser}
              />
            ),
          },
          status: {
            value: status,
            sortValue: accessReviewItemStatusSortValue(status),
            element: (
              <AccessReviewStatusLabel
                entityType={EntityType.AccessReviewGroupUser}
                status={status}
                warnings={groupUser.warnings}
                group={props.accessReviewGroup.group}
                user={groupUser.user}
                accessReviewId={props.accessReviewGroup.accessReviewId}
              />
            ),
          },
          note: {
            options: {
              display: isGroupBeingReviewed,
            },
            value: "",
            element: (
              <AccessReviewNoteLabel
                initNoteContent={groupUserAction?.note}
                onSubmit={(updatedNoteContent) => {
                  if (!performReviewState) {
                    return;
                  }

                  const existingInfo = performReviewState.groupUserActions.find(
                    (groupUserAction) =>
                      groupUserAction.accessReviewGroupUserId === groupUser.id
                  );

                  if (existingInfo) {
                    existingInfo.note = updatedNoteContent;
                  } else {
                    performReviewState.groupUserActions.push({
                      accessReviewGroupUserId: groupUser.id,
                      action: AccessReviewAction.NoAction,
                      note: updatedNoteContent,
                    });
                  }
                }}
              />
            ),
            sortValue: idx,
          },
          outcome: {
            value: outcome,
            element: (
              <AccessReviewOutcomeLabel
                entityType={EntityType.AccessReviewGroupUser}
                outcome={outcome}
              />
            ),
          },
          acceptRevoke: {
            value: groupUserAction?.action || AccessReviewAction.NoAction,
            element: (
              <AccessReviewAcceptRevokeToggleButton
                state={groupUserAction?.action || AccessReviewAction.NoAction}
                onStateChange={(state) => {
                  if (!performReviewState) {
                    return;
                  }

                  const existingInfo = performReviewState.groupUserActions.find(
                    (groupUserAction) =>
                      groupUserAction.accessReviewGroupUserId === rowId
                  );

                  if (existingInfo) {
                    existingInfo.action = state;
                  } else {
                    performReviewState.groupUserActions.push({
                      accessReviewGroupUserId: rowId,
                      action: state,
                    });
                  }
                  accessReviewDispatch({
                    type: AccessReviewContextActionType.AccessReviewItemUpdate,
                    payload: {
                      performReviewStateByUARGroupId: {
                        ...accessReviewState.performReviewStateByUARGroupId,
                        [props.accessReviewGroup.id]: performReviewState,
                      },
                    },
                  });
                }}
              />
            ),
          },
        },
      };
    }
  );

  let emptyStateTitle: string;
  let emptyStateSubtitle: string;
  let emptyStateButtonTitle: string | undefined;
  let emptyStateButtonIcon: IconName | undefined;
  let emptyStateOnClickHandler: (() => void) | undefined;
  if (isGroupBeingReviewed) {
    if (currentUserHasCompleteReviews) {
      emptyStateTitle = `No users left to review`;
    } else {
      emptyStateTitle = `No users to review`;
    }
    emptyStateSubtitle = `However, you have outstanding reviews for this group's resources.`;
    emptyStateButtonTitle = `Review resource access`;
    emptyStateButtonIcon = "check-circle";
    emptyStateOnClickHandler = () => {
      history.replace(`#${EntityViewerTabType.Resources}`);
      props.setSelectedTab(EntityViewerTabType.Resources);
    };
  } else {
    emptyStateTitle = `No users to review`;
    emptyStateSubtitle = navigationState.isOnlyMyReviewsFilterOn
      ? `No users have been assigned for you to review.`
      : `No users had direct access to this group at the time of the access review.`;
  }

  const onCheckedRowsChange = (ids: string[], checkboxState: boolean) => {
    if (props.selectedRows && props.setSelectedRows) {
      if (checkboxState) {
        const newSelectedRows = props.selectedRows;
        ids.forEach(
          (id) => (newSelectedRows[id] = EntityType.AccessReviewGroupUser)
        );
        props.setSelectedRows({ ...newSelectedRows });
      } else {
        const newSelectedRows = props.selectedRows;
        ids.forEach((id) => delete newSelectedRows[id]);
        props.setSelectedRows({ ...newSelectedRows });
      }
    }
  };

  // build checked row ids set
  const checkedRowIds = props.selectedRows
    ? new Set(Object.keys(props.selectedRows))
    : undefined;

  const numSelectedUserRows = Object.values(props.selectedRows ?? {}).reduce(
    (count, entityType) => {
      return entityType === EntityType.AccessReviewGroupUser
        ? count + 1
        : count;
    },
    0
  );

  const selectAllChecked =
    numSelectedUserRows === props.accessReviewGroup.numGroupUsers;

  const onSelectAllChange = (checkboxState: boolean) => {
    if (props.selectedRows && props.setSelectedRows) {
      if (checkboxState) {
        const newSelectedRows: Record<string, EntityType> = {
          ...props.selectedRows,
        };
        users.forEach((groupUser) => {
          newSelectedRows[groupUser.id] = EntityType.AccessReviewGroupUser;
        });
        props.setSelectedRows({ ...newSelectedRows });
      } else {
        // Clear all users rows by rebuilding selected rows without the users rows
        const newSelectedRows: Record<string, EntityType> = {};
        Object.entries(props.selectedRows).forEach(([id, entityType]) => {
          if (entityType !== EntityType.AccessReviewGroupUser) {
            newSelectedRows[id] = entityType;
          }
        });
        props.setSelectedRows({ ...newSelectedRows });
      }
    }
  };

  return (
    <EmptyStateContentWrapper
      content={
        <ScrollableMuiVirtualTable
          columns={headers}
          rows={rows}
          defaultSortBy={"name"}
          defaultSortDirection={SortDirection.ASC}
          allRowsLoaded
          expandable
          checkedRowIds={checkedRowIds}
          onCheckedRowsChange={onCheckedRowsChange}
          selectAllChecked={selectAllChecked}
          onSelectAllChange={onSelectAllChange}
        />
      }
      isEmpty={users.length === 0}
      entityType={EntityType.User}
      title={emptyStateTitle}
      subtitle={emptyStateSubtitle}
      buttonTitle={emptyStateButtonTitle}
      buttonIcon={emptyStateButtonIcon}
      onClickHandler={emptyStateOnClickHandler}
    />
  );
};

type GroupUserAccessReviewersCellProps = {
  accessReviewGroup: AccessReviewGroupPreviewFragment;
  groupUser: AccessReviewGroupUserPreviewFragment;
};

const GroupUserAccessReviewersCell = (
  props: GroupUserAccessReviewersCellProps
) => {
  const { accessReviewState } = useContext(AccessReviewContext);
  const [showReviewersModal, setShowReviewersModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { displaySuccessToast } = useToast();

  const [
    updateGroupUserReviewers,
    { loading },
  ] = useUpdateGroupUserReviewersMutation();

  const canEditReviewers =
    accessReviewState.ongoingAccessReviewIdSet.has(
      props.accessReviewGroup.accessReviewId
    ) && props.accessReviewGroup.canEditReviewers;

  return (
    <>
      <AccessReviewReviewersCell
        itemType="group"
        reviewerUsers={props.groupUser.reviewerUsers}
        canEditReviewers={canEditReviewers}
        onClick={() => {
          setShowReviewersModal(true);
        }}
      />
      {showReviewersModal && (
        <AccessReviewerModal
          title={"Reviewers"}
          isModalOpen={showReviewersModal}
          onClose={() => {
            setShowReviewersModal(false);
            setErrorMessage("");
          }}
          loading={loading}
          onSubmit={async (reviewers) => {
            try {
              const { data } = await updateGroupUserReviewers({
                variables: {
                  input: {
                    accessReviewGroupUserId: props.groupUser.id,
                    reviewers: reviewers,
                  },
                },
                refetchQueries: ["AccessReviewGroupDetails"],
              });
              switch (data?.updateGroupUserReviewers.__typename) {
                case "UpdateGroupUserReviewersResult": {
                  displaySuccessToast("Success: reviewers updated");
                  setShowReviewersModal(false);
                  break;
                }
                case "AccessReviewAlreadyStoppedError": {
                  setErrorMessage(data?.updateGroupUserReviewers.message);
                  break;
                }
              }
            } catch (error) {
              logError(error, "failed to update access reviewers");
              setErrorMessage(
                getModifiedErrorMessage(
                  "Error: failed to update access reviewers",
                  error
                )
              );
            }
          }}
          errorMessage={errorMessage}
          entryInfos={getReviewerModalEntries(
            props.groupUser.reviewerUsers,
            null
          )}
          canEditReviewers={canEditReviewers}
        />
      )}
    </>
  );
};

export default AccessReviewGroupUsersTable;
