import { EventFilterFields } from "api/generated/graphql";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import { EventsTable } from "views/events/EventsTable";

type ConnectionEventsRowProps = {
  connectionId: string;
};

export const ConnectionEventsRow = (props: ConnectionEventsRowProps) => {
  const eventFilter: EventFilterFields = {
    objects: {
      objectId: props.connectionId,
    },
  };

  const eventsTable = (
    <EventsTable
      eventFilter={eventFilter}
      emptyTitle={`No events for this app`}
      emptySubtitle={`Interact with this app to add events`}
    />
  );

  return (
    <EntityViewerRow title={"Events"} content={eventsTable} isTable={true} />
  );
};

export default ConnectionEventsRow;
