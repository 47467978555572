import {
  DataElement,
  DataElementList,
  FormGroup,
  Modal,
  Select,
  Skeleton,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useState } from "react";
import { useAllOwnersQuery } from "utils/hooks";
import { logError } from "utils/logging";

interface Props {
  adminIDs: string[];
  onChangeAdminIDs?: (newAdminIDs: string[]) => void;
}

const EntitiesWithAdminFilter: React.FC<Props> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalAdminIDs, setModalAdminIDs] = useState<string[]>([]);

  const { data, error, loading } = useAllOwnersQuery({
    variables: {
      input: { includeAll: true },
    },
  });
  if (error) {
    logError(error, "failed to list owners");
  }

  if (loading) {
    return <Skeleton />;
  }

  const allOwners = data?.owners.owners || [];
  const unselectedOwners = allOwners.filter(
    (owner) => !modalAdminIDs.includes(owner.id)
  );

  const handleOpenModal = () => {
    setShowModal(true);
    setModalAdminIDs(props.adminIDs);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    if (!props.onChangeAdminIDs) return;
    props.onChangeAdminIDs([...modalAdminIDs]);
    setShowModal(false);
  };

  if (!props.onChangeAdminIDs) {
    if (props.adminIDs.length === 0) {
      return null;
    }
    return (
      <FormGroup
        label="Entities with admin"
        fontWeight="normal"
        fontSize="textMd"
      >
        <DataElementList>
          {props.adminIDs.map((adminID) => (
            <DataElement
              label={
                allOwners.find((o) => o.id === adminID)?.name ??
                "(Unknown owner)"
              }
              color="pink"
              leftIcon={{ name: "user-square" }}
            />
          ))}
        </DataElementList>
      </FormGroup>
    );
  }

  return (
    <>
      <FormGroup
        label="Entities with admin"
        fontWeight="normal"
        fontSize="textMd"
      >
        <div className={sprinkles({ marginBottom: "sm" })}>
          Add items to the access review that have certain specified admins
        </div>
        <DataElementList>
          {props.adminIDs.map((adminID) => (
            <DataElement
              label={
                allOwners.find((o) => o.id === adminID)?.name ??
                "(Unknown owner)"
              }
              color="pink"
              leftIcon={{ name: "user-square" }}
              rightIcon={{
                name: "x",
                onClick: () => {
                  props.onChangeAdminIDs &&
                    props.onChangeAdminIDs(
                      props.adminIDs.filter((id) => id !== adminID)
                    );
                },
              }}
            />
          ))}
          <DataElement
            label="Add admin"
            color="blue"
            leftIcon={{
              name: "plus",
            }}
            onClick={handleOpenModal}
          />
        </DataElementList>
      </FormGroup>
      <Modal
        isOpen={showModal}
        onClose={handleCancel}
        title="Include entities with admin"
      >
        <Modal.Body>
          <p>
            This access review will include resources and groups who are owned
            by one of the following admins. If no admins are selected, the
            access review will only include items matching the other provided
            scopes.
          </p>
          <div className={sprinkles({ marginBottom: "md" })}>
            <Select
              options={unselectedOwners}
              selectOnly
              getOptionLabel={(option) => option.name}
              onChange={(owner) => {
                if (owner) {
                  setModalAdminIDs([...modalAdminIDs, owner.id]);
                }
              }}
              placeholder="Select an owner"
              autoFocus
            />
          </div>
          <DataElementList>
            {modalAdminIDs.map((adminID) => (
              <DataElement
                label={
                  allOwners.find((o) => o.id === adminID)?.name ??
                  "(Unknown owner)"
                }
                color="pink"
                leftIcon={{ name: "user-square" }}
                rightIcon={{
                  name: "x",
                  onClick: () => {
                    setModalAdminIDs(
                      modalAdminIDs.filter((id) => id !== adminID)
                    );
                  },
                }}
              />
            ))}
          </DataElementList>
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Save"
          onPrimaryButtonClick={handleSave}
          secondaryButtonLabel="Cancel"
          onSecondaryButtonClick={handleCancel}
        />
      </Modal>
    </>
  );
};

export default EntitiesWithAdminFilter;
