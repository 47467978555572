import {
  EntityType,
  GroupPreviewTinyFragment,
  useGroupsHomeQuery,
  Visibility,
} from "api/generated/graphql";
import { PillV3 } from "components/pills/PillsV3";
import { Banner, Button, Card, DataElement, FormRow } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import { getResourceUrlNew } from "utils/common";
import { entityTypeToString } from "utils/entity";
import { AppsContext } from "views/apps/AppsContext";
import VisibilitySelector from "views/visibility/VisibilitySelector";

import { FormMode } from "../common";

interface Props {
  mode: FormMode;
  title?: string;
  tooltipText?: string;
  visibility?: Visibility;
  visibilityGroups: string[];
  parentResourceVisibility?: Visibility;
  parentResourceVisibilityGroups?: string[];
  parentResourceName?: string;
  connectionVisibility?: Visibility;
  connectionVisibilityGroups?: string[];
  connectionName?: string;
  onChangeVisibilityAndGroups: ({
    visibility,
    groupIds,
    setDoNotChange,
  }: {
    visibility?: Visibility;
    groupIds?: string[];
    setDoNotChange?: boolean;
  }) => void;
  entityType: EntityType;
  additionalWarning?: string;
  contentOnly?: boolean;
  isV3?: boolean;
}
const VisibilityRow = (props: Props) => {
  const {
    visibility,
    visibilityGroups,
    parentResourceVisibility,
    parentResourceVisibilityGroups,
    parentResourceName,
    connectionName,
    connectionVisibility,
    connectionVisibilityGroups,
  } = props;

  const { bulkMode } = useContext(AppsContext);

  const groupsToFetch = props.visibilityGroups
    .concat(props.parentResourceVisibilityGroups ?? [])
    .concat(props.connectionVisibilityGroups ?? []);
  const { data, error } = useGroupsHomeQuery({
    variables: {
      input: {
        groupIds: groupsToFetch,
      },
    },
    skip: groupsToFetch.length === 0,
  });

  if (error) {
    return (
      <FormRow title="Visibility">Failed to get visibility groups</FormRow>
    );
  }

  const groups = data?.groups.groups ?? [];
  const groupById: Map<string, GroupPreviewTinyFragment> = groups?.reduce(
    (acc, group) => {
      acc.set(group.id, group);
      return acc;
    },
    new Map()
  );
  const entityName = entityTypeToString(props.entityType).toLocaleLowerCase();

  const hasVisibilityRestrictions =
    visibility === Visibility.Team ||
    parentResourceVisibility === Visibility.Team ||
    connectionVisibility === Visibility.Team;

  const parentIsFullyRestricted =
    (parentResourceVisibility === Visibility.Team &&
      parentResourceVisibilityGroups?.length === 0) ||
    (connectionVisibility === Visibility.Team &&
      connectionVisibilityGroups?.length === 0);
  const someParentsHaveVisibilityGroups =
    (parentResourceVisibility === Visibility.Team &&
      parentResourceVisibilityGroups &&
      parentResourceVisibilityGroups?.length > 0) ||
    (connectionVisibility === Visibility.Team &&
      connectionVisibilityGroups &&
      connectionVisibilityGroups?.length > 0);
  const allParentsHaveVisibilityGroups =
    (connectionVisibilityGroups ?? []).length > 0 &&
    (parentResourceVisibilityGroups ?? []).length > 0;

  const parentContent = (
    <>
      {connectionVisibility && (
        <>
          <VisibilityCard
            groupById={groupById}
            visibility={connectionVisibility}
            visibilityGroups={connectionVisibilityGroups ?? []}
            name={connectionName ?? "Private app"}
            isV3={props.isV3}
          />
        </>
      )}
      {allParentsHaveVisibilityGroups && (
        <div className={sprinkles({ marginBottom: "lg" })}>
          {props.isV3 ? "and" : <Button disabled label="And" size="sm" />}
        </div>
      )}
      {parentResourceVisibility && (
        <>
          <VisibilityCard
            groupById={groupById}
            visibility={parentResourceVisibility}
            visibilityGroups={parentResourceVisibilityGroups ?? []}
            name={parentResourceName ?? "Private parent resource"}
            isV3={props.isV3}
          />
        </>
      )}
    </>
  );

  const warningBannerFullyRestricted = parentIsFullyRestricted && (
    <Banner
      type={"warning"}
      marginBottom="sm"
      message={
        <>
          Only Opal admins, {entityName} admins, and users with access can see
          the item. A parent to this resource is set to "not visible," which
          makes this resource not visible to other users. To make this{" "}
          {entityName} visible to other users you must update the visibility
          settings of the parent with restrictions.
        </>
      }
    />
  );

  // Possible combinations:
  // 1. No visibility restrictions
  // 2. No visibility restrictions, but parent or connection have restrictions (no visibility groups set):
  //    no boxes will show up, but there will be a banner that says "Only Opal
  //    admins, resource admins, and users with access can see this item. A
  //    parent to this resource is restricted which makes this resource not
  //    visible to other users regardless of your settings below. To make this
  //    resource visible to other users you must update the visibility settings
  //    of the parent with restrictions."
  // 3. No visibility restrictions, but parent or connection have restrictions
  //    (visibility groups set): show only parent connections with group
  //    restrictions in a card with "AND" if more than one parent resource has
  //    group restrictions
  // 4. Only admins and users with access can see this resource (parent or
  //    connection don’t have restrictions): only show admins and users with access
  //    message
  // 5. Only admins and users with access can see this resource (parent or
  //    connection have restrictions, no visibility groups set): only show admins
  //    and users with access message
  // 6. Only admins and users with access can see this resource (parent or
  //    connection have restrictions, visibility groups set): only show admins and
  //    users with access message
  // 7. Only admins, users with access and users in the following groups can see
  //    this resource (parent or connection don’t have restrictions): only show
  //    groups on item
  // 8. Only admins, users with access and users in the following groups can see
  //    this resource (parent or connection have restrictions, no visibility groups
  //    set): Show groups on this item with the following banner above: "Only Opal
  //    admins, resource admins, and users with access can see this item. A parent
  //    to this resource is restricted which makes this resource not visible to
  //    other users regardless of the settings below. To make this resource visible
  //    to other users you must update the visibility settings of the parent with
  //    restrictions."
  // 9. Only admins, users with access and users in the following groups can see
  //    this resource (parent or connection have restrictions, visibility groups
  //    set): show only parent connections with group restrictions in a card with
  //    "AND" for parent resources with group restrictions and an "AND" + box for
  //    the current item visible
  let viewContent: React.ReactNode = null;
  if (!hasVisibilityRestrictions) {
    viewContent = <>No visibility restrictions</>;
  } else if (visibility === Visibility.Global && parentIsFullyRestricted) {
    // Eventually we might want to list which parents are restricting visibility
    viewContent = warningBannerFullyRestricted;
  } else if (
    visibility === Visibility.Global &&
    someParentsHaveVisibilityGroups
  ) {
    viewContent = (
      <>
        <div
          className={sprinkles({
            fontWeight: props.isV3 ? "normal" : "medium",
            marginBottom: "sm",
          })}
        >
          Only admins, users with access and users in the following groups can
          see this {entityName}:
        </div>
        {parentContent}
      </>
    );
  } else if (visibility === Visibility.Team && visibilityGroups.length === 0) {
    viewContent = (
      <>Only admins and users with access can see this {entityName}</>
    );
  } else if (visibility === Visibility.Team && visibilityGroups.length > 0) {
    viewContent = (
      <>
        <div
          className={sprinkles({
            fontWeight: props.isV3 ? "normal" : "medium",
            marginBottom: "sm",
          })}
        >
          Only admins, users with access and users in the following groups can
          see this {entityName}:
        </div>
        {parentContent}
        {someParentsHaveVisibilityGroups && (
          <div className={sprinkles({ marginBottom: "lg" })}>
            {props.isV3 ? "and" : <Button disabled label="And" size="sm" />}
          </div>
        )}
        <VisibilityGroupsDisplay
          visibilityGroups={visibilityGroups}
          groupById={groupById}
          isV3={props.isV3}
        />
      </>
    );
  }

  const editContent = (
    <>
      {warningBannerFullyRestricted}
      {parentContent}
      {someParentsHaveVisibilityGroups && (
        <div className={sprinkles({ marginBottom: "lg" })}>
          {props.isV3 ? "and" : <Button disabled label="And" size="sm" />}
        </div>
      )}
      {bulkMode === "edit" || !visibility ? (
        <>
          <VisibilitySelector
            visibility={visibility ?? "Unchanged"}
            hasLeaveUnchanged
            visibilityGroups={visibilityGroups}
            onChangeVisibility={(vis) => {
              if (vis === "Unchanged") {
                props.onChangeVisibilityAndGroups({ setDoNotChange: true });
              } else if (vis === Visibility.Global) {
                props.onChangeVisibilityAndGroups({
                  visibility: vis,
                  groupIds: [],
                });
              } else {
                props.onChangeVisibilityAndGroups({
                  visibility: vis,
                });
              }
            }}
            onChangeVisibilityGroups={(groupIds) => {
              props.onChangeVisibilityAndGroups({
                groupIds,
              });
            }}
            isV3={props.isV3}
          />
        </>
      ) : (
        <>
          <VisibilitySelector
            visibility={visibility}
            onChangeVisibility={(vis) => {
              if (vis == Visibility.Global) {
                props.onChangeVisibilityAndGroups({
                  visibility: Visibility.Global,
                  groupIds: [],
                });
              } else {
                props.onChangeVisibilityAndGroups({
                  visibility: Visibility.Team,
                });
              }
            }}
            visibilityGroups={visibilityGroups}
            onChangeVisibilityGroups={(groupIds) => {
              props.onChangeVisibilityAndGroups({
                groupIds,
              });
            }}
            isV3={props.isV3}
          />
        </>
      )}
    </>
  );

  if (props.contentOnly) {
    return (
      <>
        {props.additionalWarning && (
          <Banner
            type={"warning"}
            marginBottom="sm"
            message={props.additionalWarning}
          />
        )}
        {props.mode === "view" ? viewContent : editContent}
      </>
    );
  }

  return (
    <FormRow
      title={props.title ?? "Visibility"}
      tooltipText={props.tooltipText}
      showToolTipIcon
    >
      {props.additionalWarning && (
        <Banner
          type={"warning"}
          marginBottom="sm"
          message={props.additionalWarning}
        />
      )}
      {props.mode === "view" ? viewContent : editContent}
    </FormRow>
  );
};

const VisibilityCard = (props: {
  title?: string;
  name: string;
  visibility?: Visibility;
  visibilityGroups: string[];
  groupById?: Map<string, GroupPreviewTinyFragment>;
  isV3?: boolean;
}) => {
  if (!props.visibility || props.visibility === Visibility.Global) {
    return null;
  } else if (props.visibilityGroups.length === 0) {
    return null;
  }

  return (
    <>
      <Card title={"Visibility settings of " + props.name}>
        <VisibilityGroupsDisplay
          visibilityGroups={props.visibilityGroups}
          groupById={props.groupById}
          isV3={props.isV3}
        />
      </Card>
    </>
  );
};

const VisibilityGroupsDisplay = (props: {
  visibilityGroups: string[];
  groupById?: Map<string, GroupPreviewTinyFragment>;
  isV3?: boolean;
}) => {
  return (
    <div
      className={sprinkles({
        display: "flex",
        alignItems: "center",
        gap: "sm",
        flexWrap: "wrap",
      })}
    >
      {props.visibilityGroups?.map((groupId, index) => {
        const group = props.groupById?.get(groupId);
        return props.isV3 ? (
          <>
            <PillV3
              key={groupId}
              keyText={group?.name || "Private group"}
              pillColor="Teal"
              icon={{ type: "name", icon: "users" }}
              entityId={
                group?.id
                  ? {
                      entityId: group.id,
                      entityType: EntityType.Group,
                    }
                  : undefined
              }
            />
            {index < props.visibilityGroups?.length - 1 ? "or" : null}
          </>
        ) : (
          <>
            <DataElement
              key={groupId}
              label={group?.name || "Private group"}
              color="gray"
              leftIcon={
                group?.groupType
                  ? {
                      data: {
                        entityType: group?.groupType,
                        type: "entity",
                      },
                    }
                  : undefined
              }
              link={
                group?.id
                  ? getResourceUrlNew({
                      entityId: group?.id,
                      entityType: EntityType.Group,
                    })
                  : undefined
              }
            />
            {index < props.visibilityGroups?.length - 1 ? (
              <Button disabled label="Or" size="sm" />
            ) : null}
          </>
        );
      })}
    </div>
  );
};

export default VisibilityRow;
