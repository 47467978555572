import {
  ConnectionPreviewLargeFragment,
  ConnectionType,
  IdpConnectionType,
  useConnectionsQuery,
} from "api/generated/graphql";
import appsIcon from "assets/logos/connection-icon.svg";
import googleLogo from "assets/logos/google-logo.svg";
import microsoftEntraIdLogo from "assets/logos/microsoft-entra-id-logo.svg";
import oktaLogo from "assets/logos/okta-logo.png";
import workdayLogo from "assets/logos/workday-logo.png";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { Modal } from "components/ui";
import React, { useState } from "react";
import * as Icons from "react-feather";
import { useHistory } from "react-router-dom";
import { logError } from "utils/logging";
import styles from "views/idp/create/BrowseServices.module.scss";
import SelectConnectionModal from "views/idp/create/SelectConnectionModal";

import { FeatureFlag, useFeatureFlag } from "../../../utils/feature_flags";

enum modalType {
  NONE,
  IDP,
  HR,
}

export type IdpConnectionInfo = {
  idpConnectionType: IdpConnectionType;
  connectionType: ConnectionType;
  name: string;
  logo: string;
  category: string;
  createUrl: string;
  createNewConnectionUrl: string;
};

export const CONNECTIONS_LIST: IdpConnectionInfo[] = [
  {
    idpConnectionType: IdpConnectionType.Okta,
    connectionType: ConnectionType.OktaDirectory,
    name: "Okta",
    logo: oktaLogo,
    category: "Identity Provider",
    createUrl: "okta",
    createNewConnectionUrl: "/apps/create/okta_directory",
  },
  {
    idpConnectionType: IdpConnectionType.Google,
    connectionType: ConnectionType.GoogleWorkspace,
    name: "Google",
    logo: googleLogo,
    category: "Identity Provider",
    createUrl: "google",
    createNewConnectionUrl: "/apps/create/google_workspace",
  },
  {
    idpConnectionType: IdpConnectionType.AzureAd,
    connectionType: ConnectionType.AzureAd,
    name: "Microsoft Entra ID",
    logo: microsoftEntraIdLogo,
    category: "Identity Provider",
    createUrl: "azure_ad",
    createNewConnectionUrl: "/apps/create/azure_ad",
  },
  {
    idpConnectionType: IdpConnectionType.Workday,
    connectionType: ConnectionType.Workday,
    name: "Workday",
    logo: workdayLogo,
    category: "HR Provider",
    createUrl: "workday",
    createNewConnectionUrl: "/apps/create/workday",
  },
];

// filter services based on feature flags
const filterServices = (
  services: IdpConnectionInfo[],
  featureFlags: {
    excludeWorkday?: boolean;
  }
) => {
  return services.filter((service) => {
    switch (service.idpConnectionType) {
      case IdpConnectionType.Workday:
        return !featureFlags.excludeWorkday;
      default:
        return true;
    }
  });
};

const BrowseIdpServices = () => {
  const [showModal, setShowModal] = useState(modalType.NONE);
  // check feature flags
  const isWorkdayEnabled = useFeatureFlag(
    FeatureFlag.EnableWorkdayConnectionType
  );

  // filter out workday if feature flag is not enabled
  const filteredServices = filterServices(CONNECTIONS_LIST, {
    excludeWorkday: !isWorkdayEnabled,
  });

  const [
    selectedIdpConnection,
    setSelectedIdpConnection,
  ] = useState<IdpConnectionInfo>();

  const { data, error } = useConnectionsQuery({
    variables: {
      input: {
        connectionTypes: filteredServices.map((info) => info.connectionType),
      },
    },
  });
  let connections: ConnectionPreviewLargeFragment[] = [];
  if (data?.connections) {
    switch (data.connections.__typename) {
      case "ConnectionsResult":
        connections = data?.connections.connections;
        break;
      default:
        logError(new Error(`Error: failed to fetch connections`));
    }
  } else if (error) {
    logError(error, `Error: failed to fetch connections`);
  }

  // For each IDP connection type, figure out if a matching app exists
  const matchingAppExistsByIdpConnectionType: Map<
    IdpConnectionType,
    boolean
  > = new Map(
    filteredServices.map((info) => {
      return [
        info.idpConnectionType,
        connections.find(
          (connection) => connection.connectionType === info.connectionType
        ) !== undefined,
      ];
    })
  );

  return (
    <div className={styles.listContainer}>
      <div className={styles.header}>
        <span className={styles.headerText}>
          <b>{filteredServices.length}</b> available connections
        </span>
      </div>
      <div className={styles.content}>
        {filteredServices.map((info) => (
          <>
            <div className={styles.card} key={info.name}>
              <div className={styles.mainContainer}>
                <div className={styles.logoBox}>
                  <img alt={info.logo} src={info.logo} />
                </div>
                <div>
                  <div className={styles.name}>{info.name}</div>
                  <div className={styles.category}>{info.category}</div>
                </div>
              </div>
              <div className={styles.border} />
              <div className={styles.action}>
                <Icons.Plus strokeWidth={3} size={12} />
                <span
                  onClick={() => {
                    setShowModal(modalType.IDP);
                    setSelectedIdpConnection(info);
                  }}
                >
                  Connect
                </span>
              </div>
            </div>
          </>
        ))}

        {/* "Can't find your integration" info card */}
        <div className={styles.card}>
          <div className={styles.mainContainer}>
            <img
              alt={"question-mark"}
              src={appsIcon}
              className={styles.logoBox}
            />
            <div className={styles.text}>
              <div className={styles.name}>Can't Find It?</div>
              <div className={styles.category}>
                Let us know if you need a different integration
              </div>
            </div>
          </div>
          <div className={styles.action}>
            <Icons.MessageSquare strokeWidth={3} size={12} />
            <span>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={"mailto:support@opal.dev"}
              >
                Contact Us
              </a>
            </span>
          </div>
        </div>

        {selectedIdpConnection &&
          (matchingAppExistsByIdpConnectionType.get(
            selectedIdpConnection.idpConnectionType
          ) ? (
            <SelectConnectionModal
              idpConnectionInfo={selectedIdpConnection}
              isModalOpen={showModal === modalType.IDP}
              onClose={() => setShowModal(modalType.NONE)}
            />
          ) : (
            <ConnectAppFirstModal
              idpConnectionInfo={selectedIdpConnection}
              isModalOpen={showModal === modalType.IDP}
              onClose={() => setShowModal(modalType.NONE)}
            />
          ))}
      </div>
    </div>
  );
};

type ConnectAppFirstModalProps = {
  idpConnectionInfo: IdpConnectionInfo;
  isModalOpen: boolean;
  onClose: () => void;
};

const ConnectAppFirstModal = (props: ConnectAppFirstModalProps) => {
  const history = useHistory();
  const info = props.idpConnectionInfo;
  const connectionTypeName = getConnectionTypeInfo(info.connectionType)?.name;

  return (
    <Modal
      isOpen={props.isModalOpen}
      onClose={props.onClose}
      title={`Use ${info.name} as your identity provider`}
    >
      <Modal.Body>
        <p>
          To use {info.name} as your identity provider, you must connect a{" "}
          <b>{connectionTypeName}</b> app first. Click "Continue" to connect an
          app.
        </p>
      </Modal.Body>
      <Modal.Footer
        onSecondaryButtonClick={props.onClose}
        primaryButtonLabel="Continue"
        onPrimaryButtonClick={() =>
          history.push(info.createNewConnectionUrl || "/apps/browse")
        }
      />
    </Modal>
  );
};

export default BrowseIdpServices;
