import { IdpConnectionType, useSetupStateQuery } from "api/generated/graphql";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import { Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { useMountEffect } from "utils/hooks";
import { CONNECTIONS_LIST } from "views/idp/create/BrowseIdpServices";

import { getIdpConnectionSubtitle } from "../common";
import * as styles from "./IdpStep.css";

const SetupFinished = () => {
  const history = useHistory();
  const logEvent = useLogEvent();
  const { data, refetch } = useSetupStateQuery();

  const idpConnectionInfo = CONNECTIONS_LIST.find(
    (info) =>
      info.idpConnectionType === data?.setupState.state.idpConnectionType
  );
  const connectionId = data?.setupState.state.connectionId;

  useMountEffect(() => {
    refetch();
  });

  if (!connectionId || !idpConnectionInfo) {
    return null;
  }

  const syncedEntityName =
    data.setupState.state.connection?.name ?? "IdP Connection";
  const subtitle = getIdpConnectionSubtitle(
    data.setupState.state.connection?.metadata
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>Import successful</div>
      <div className={sprinkles({ overflowY: "auto", marginBottom: "lg" })}>
        <p>Opal has successfully imported and synced your data from the IdP.</p>
        <div className={styles.connectionCard}>
          <div className={styles.cardTop}>
            <div className={styles.cardLogoContainer}>
              <img alt={syncedEntityName} src={idpConnectionInfo.logo} />
            </div>
            <div>
              <div
                className={sprinkles({
                  fontWeight: "semibold",
                  fontSize: "headlineSm",
                })}
              >
                {syncedEntityName}
              </div>
              <div className={sprinkles({ color: "gray700" })}>{subtitle}</div>
            </div>
          </div>
          <div className={styles.cardBottom}>
            {idpConnectionInfo.idpConnectionType !==
              IdpConnectionType.Google && (
              <div className={styles.cardBottomSection}>
                <div className={styles.cardBottomNumber}>
                  {data.setupState.state.importEntityCounts?.numGroups ?? 0}
                  <Icon name="check-circle" color="green600" size="xs" />
                </div>
                <div>Groups</div>
              </div>
            )}
            {data.setupState.state.importEntityCounts?.resourceCounts.map(
              (resourceCountByType) => {
                const label =
                  resourceTypeInfoByType[resourceCountByType.resourceType]
                    ?.name ?? resourceCountByType.resourceType;

                return (
                  <div className={styles.cardBottomSection}>
                    <div className={styles.cardBottomNumber}>
                      {resourceCountByType.count}
                      <Icon name="check-circle" color="green600" size="xs" />
                    </div>
                    <div>{pluralize(label, resourceCountByType.count)}</div>
                  </div>
                );
              }
            )}
          </div>
        </div>
        <p className={sprinkles({ fontWeight: "semibold", marginTop: "xl" })}>
          Next steps
        </p>
        <p>Here's what you should do next:</p>
        <div
          className={sprinkles({ display: "flex", marginTop: "md", gap: "lg" })}
        >
          <div
            className={styles.tile({})}
            onClick={() => {
              logEvent({
                name: "setup_link_click",
                properties: {
                  url: "/settings#idp-and-hr",
                },
              });
              history.push("/settings#idp-and-hr");
            }}
          >
            <div className={styles.logoContainer}>
              <Icon name="list" size="lg" />
            </div>
            <div
              className={sprinkles({
                fontSize: "bodyMd",
                marginBottom: "md",
              })}
            >
              Map imported attributes
            </div>
            <div className={styles.linkButton}>
              Map attributes <Icon name="arrow-right" size="xs" />
            </div>
          </div>
          <div
            className={styles.tile({})}
            onClick={() => {
              const url = `/apps/${connectionId}`;
              logEvent({
                name: "setup_link_click",
                properties: {
                  url,
                },
              });
              history.push(url);
            }}
          >
            <div className={styles.logoContainer}>
              <Icon name="unlock" size="lg" />
            </div>
            <div
              className={sprinkles({
                fontSize: "bodyMd",
                marginBottom: "md",
              })}
            >
              Make resources requestable
            </div>
            <div className={styles.linkButton}>
              Manage resources <Icon name="arrow-right" size="xs" />
            </div>
          </div>
          <div
            className={styles.tile({})}
            onClick={() => {
              logEvent({
                name: "setup_link_click",
                properties: {
                  url: "/settings#productivity-integrations",
                },
              });
              history.push("/settings#productivity-integrations");
            }}
          >
            <div className={styles.logoContainer}>
              <Icon name="slack" size="lg" />
            </div>
            <div
              className={sprinkles({
                fontSize: "bodyMd",
                marginBottom: "md",
              })}
            >
              Request resources via Slack
            </div>
            <div className={styles.linkButton}>
              Set up Slack <Icon name="arrow-right" size="xs" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetupFinished;
