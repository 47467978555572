import { getModifiedErrorMessage } from "api/ApiContext";
import {
  GeneralSettingType,
  Maybe,
  useUpdateOrganizationSettingsMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { formatDuration } from "components/label/Label";
import EditDurationModal from "components/modals/EditDurationModal";
import { MoreInfo } from "components/more_info/MoreInfo";
import { useToast } from "components/toast/Toast";
import { ButtonV3 } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import React, { useContext, useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import AdminDurationButtonText from "views/settings/AdminDurationButton";
import OrgContext, { OrgContextActionType } from "views/settings/OrgContext";
import styles from "views/settings/OrgSettings.module.scss";
import * as stylesV3 from "views/settings/OrgSettingsV3.css";

export type MaxGroupDurationSettingProps = {
  labelText: string;
  tooltipText: string;
  currentSettings: GeneralSettingType[];
  maxGroupDurationInMinutes?: Maybe<number>;
};

export const MaxGroupDurationSetting = (
  props: MaxGroupDurationSettingProps
) => {
  const { orgDispatch } = useContext(OrgContext);
  const { authState } = useContext(AuthContext);

  const { displaySuccessToast } = useToast();
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const [
    updateOrgSettings,
    { loading },
  ] = useUpdateOrganizationSettingsMutation();

  const [showModal, setShowModal] = useState(false);

  const modalReset = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  const editDurationModal = (
    <EditDurationModal
      title={"Set global max group duration"}
      isModalOpen={showModal}
      onClose={modalReset}
      onSubmit={async (newDuration) => {
        try {
          const { data } = await updateOrgSettings({
            variables: {
              input: {
                settings: props.currentSettings,
                maxGroupDurationInMinutes: {
                  int: newDuration,
                },
              },
            },
          });

          switch (data?.updateOrganizationSettings.__typename) {
            case "UpdateOrganizationSettingsResult":
              orgDispatch({
                type: OrgContextActionType.OrgSettings,
                payload: {
                  orgSettings: data.updateOrganizationSettings.settings,
                },
              });

              modalReset();
              displaySuccessToast("Success: settings updated");
              break;
            case "OrgMaxGroupDurationInvalidError":
              logError(new Error(data.updateOrganizationSettings.message));
              setErrorMessage(data.updateOrganizationSettings.message);
              break;
            default:
              logError(new Error(`failed to update org settings`));
              setErrorMessage("Error: failed to update org settings");
          }
        } catch (error) {
          logError(error, "failed to update org settings");
          setErrorMessage(
            getModifiedErrorMessage(
              "Error: failed to update org settings",
              error
            )
          );
        }
      }}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
      loading={loading}
      initValue={props.maxGroupDurationInMinutes ?? undefined}
      nullable
      includeIndefinite={false}
    />
  );

  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  return hasV3 ? (
    <div className={stylesV3.switchesHeader}>
      <div className={sprinkles({ display: "flex" })}>
        <div className={stylesV3.label}>{props.labelText}</div>
        <MoreInfo tooltipText={props.tooltipText} />
      </div>
      <div className={stylesV3.switches}>
        {editDurationModal}
        <ButtonV3
          onClick={() => setShowModal(true)}
          disabled={!authState.user?.isAdmin}
          label={
            <AdminDurationButtonText
              text={formatDuration(
                props.maxGroupDurationInMinutes
                  ? moment.duration(props.maxGroupDurationInMinutes, "m")
                  : null
              )}
              frequency
            />
          }
          outline={true}
        />
      </div>
    </div>
  ) : (
    <div className={styles.switchesHeader}>
      <div className={styles.switches}>
        {editDurationModal}
        <button
          onClick={() => setShowModal(true)}
          className={styles.orgSettingOpenModalButton}
          disabled={!authState.user?.isAdmin}
        >
          <AdminDurationButtonText
            text={formatDuration(
              props.maxGroupDurationInMinutes
                ? moment.duration(props.maxGroupDurationInMinutes, "m")
                : null
            )}
            frequency
          />
        </button>
      </div>
      <div className={styles.label}>{props.labelText}</div>
      <MoreInfo tooltipText={props.tooltipText} />
    </div>
  );
};

export default MaxGroupDurationSetting;
