import { useNotificationsQuery } from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import useLeftSidebarSectionInfos, {
  LeftSidebarOptionInfo,
  LeftSidebarSectionInfo,
} from "components/layout/left_sidebar/useLeftSidebarRoutes";
import { Badge, ContextMenu, Icon } from "components/ui";
import { throttle } from "lodash";
import moment from "moment";
import React, { useCallback, useContext, useEffect, useRef } from "react";
import { useLocation } from "react-router";
import { Link, useHistory } from "react-router-dom";
import useLogEvent from "utils/analytics";
import AppContext from "views/app/AppContext";

import * as styles from "./LeftSidebar.css";
import OpalLogo from "./OpalLogo";
import UserMenu from "./UserMenu";

const THROTTLE_TIME = 10 * 1000;
const INTERVAL_TIME = 60 * 1000;

const LeftSidebar = () => {
  const { authState } = useContext(AuthContext);
  const { appState } = useContext(AppContext);

  const [collapsed, setCollapsed] = React.useState(false);

  const {
    data: notificationsData,
    loading: notificationLoading,
    refetch: baseNotificationsRefetch,
  } = useNotificationsQuery();

  // I could not quite figure out why this shouldn't be useCallback.
  // See: https://stackoverflow.com/questions/54666401/how-to-use-throttle-or-debounce-with-react-hook
  const refetch = useRef(throttle(baseNotificationsRefetch, THROTTLE_TIME));

  const checkRefetch = useCallback(() => {
    if (document.visibilityState === "hidden") {
      return;
    }
    refetch.current();
  }, [refetch]);

  useEffect(() => {
    // Refetch the notifications every minute or when we regain visibility,
    // at most every 10 seconds.
    const refetcher = setInterval(() => {
      checkRefetch();
    }, INTERVAL_TIME);

    document.addEventListener("visibilitychange", checkRefetch);
    return () => {
      clearInterval(refetcher);
      document.removeEventListener("visibilitychange", checkRefetch);
    };
  }, [checkRefetch]);

  const leftSidebarSectionInfos = useLeftSidebarSectionInfos();

  const { user } = authState;
  if (!user) return null;

  const getNotifications = () => {
    if (notificationLoading || !notificationsData) {
      return [];
    }
    return [...notificationsData.uiNotifications]
      .sort((a, b) => {
        return moment(b.createdAt).diff(moment(a.createdAt));
      })
      .map((notification) => ({
        id: notification.id,
        createdAt: notification.createdAt,
        body: notification.body,
        url: notification.url,
        readAt: notification.readAt ?? undefined,
        trackingId: notification.trackingId ?? undefined,
      }));
  };

  return (
    <div className={styles.container({ collapsed })}>
      <UserMenu
        avatarUrl={user.user.avatarUrl}
        name={user.user.fullName}
        orgName={user.user.organization.name}
        version={appState.version}
        notifications={getNotifications()}
        collapsed={collapsed}
      />
      <div
        className={styles.collapseButton}
        onClick={() => setCollapsed(!collapsed)}
      >
        <Icon
          name={collapsed ? "chevrons-right" : "chevrons-left"}
          size="xxs"
        />
      </div>
      <nav className={styles.menu}>
        <div>
          {leftSidebarSectionInfos
            .filter((sectionInfo) =>
              sectionInfo.options.some((opt) => !opt.hidden)
            )
            .map((sectionInfo, i) => (
              <LeftSidebarNavSection
                key={`${sectionInfo.title}-${i}`}
                sectionInfo={sectionInfo}
                collapsed={collapsed}
              />
            ))}
        </div>
        <LeftSidebarNavFooter collapsed={collapsed} />
      </nav>
    </div>
  );
};

const LeftSidebarNavSection = ({
  sectionInfo,
  collapsed,
}: {
  sectionInfo: LeftSidebarSectionInfo;
  collapsed: boolean;
}) => {
  const logEvent = useLogEvent();
  const location = useLocation();

  const renderMenuItem = (optionInfo: LeftSidebarOptionInfo) => {
    if (optionInfo.hidden) {
      return null;
    }
    let active = location.pathname.startsWith(optionInfo.route);
    optionInfo.extraRoutes?.forEach((route) => {
      if (location.pathname.startsWith(route)) {
        active = true;
      }
    });
    const badgeCount = optionInfo?.badgeCount;
    return (
      <Link
        key={optionInfo.route}
        className={styles.menuItem({ active })}
        to={optionInfo.route}
        onClick={() =>
          logEvent({
            name: "left_sidebar_tab_click",
            properties: { tabName: optionInfo.title },
          })
        }
        title={collapsed ? optionInfo.title : undefined}
      >
        <div className={styles.menuItemLeft}>
          <Icon name={optionInfo.iconName} size="xs" />
          {!collapsed ? (
            <div className={styles.menuItemTitle}>{optionInfo.title}</div>
          ) : null}
        </div>
        {!collapsed && badgeCount && badgeCount > 0 ? (
          <Badge count={optionInfo.badgeCount ?? 0} color="info" size="sm" />
        ) : null}
      </Link>
    );
  };

  return (
    <div key={sectionInfo.title || "Top Section"} className={styles.section}>
      {sectionInfo.title && (
        <div className={styles.sectionTitle}>
          {collapsed ? "" : sectionInfo.title}
        </div>
      )}
      {sectionInfo.options.map((optionInfo) => renderMenuItem(optionInfo))}
    </div>
  );
};

const LeftSidebarNavFooter = ({ collapsed }: { collapsed: boolean }) => {
  const history = useHistory();

  const menuItems = [
    {
      label: "Opal Docs",
      onClick: () => {
        window.open("https://docs.opal.dev/docs");
      },
    },
    {
      label: "Customer Support",
      onClick: () => {
        window.open("https://opaldev.zendesk.com/hc/en-us");
      },
    },
  ];

  return (
    <div>
      <ContextMenu
        block
        options={menuItems}
        renderButton={(onClick) => (
          <button
            className={styles.menuItem({ active: false })}
            onClick={onClick}
          >
            <div className={styles.menuItemLeft}>
              <Icon name="help-circle" size="xs" />
              {!collapsed ? (
                <div className={styles.menuItemTitle}>Help</div>
              ) : null}
            </div>
          </button>
        )}
      />
      <button
        className={styles.menuItem({ active: false })}
        onClick={() => {
          window.open("https://docs.opal.dev/changelog");
        }}
      >
        <div className={styles.menuItemLeft}>
          <Icon name="stars" size="xs" />
          {!collapsed ? (
            <div className={styles.menuItemTitle}>Recent updates</div>
          ) : null}
        </div>
      </button>
      <button
        className={styles.logo}
        onClick={() => {
          history.push("/");
        }}
      >
        <OpalLogo collapsed={collapsed} />
      </button>
    </div>
  );
};

export default LeftSidebar;
