import {
  ConnectionType,
  GroupBindingGroupPreviewFragment,
} from "api/generated/graphql";

export const formatGroupName = (group?: GroupBindingGroupPreviewFragment) => {
  let connectionName = "";
  if (
    group?.connection?.connectionType === ConnectionType.CustomConnector ||
    group?.connection?.connectionType === ConnectionType.Custom
  ) {
    connectionName = `${group.connection.name} / `;
  }
  return `${connectionName}${group?.name ?? "Hidden group"}`;
};

export function isGroupBindingRedirectRequired(group: {
  groupBinding?: {
    sourceGroupId: string;
  } | null;
  id: string;
}): boolean {
  if (group.groupBinding && group.groupBinding.sourceGroupId !== group.id) {
    return true;
  }
  return false;
}
