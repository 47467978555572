import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AuthType,
  ConnectionType,
  Maybe,
  useCreateConnectionMutation,
  Visibility,
} from "api/generated/graphql";
import googlegroupsLogo from "assets/logos/googlegroups-logo.png";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { Button, FormGroup, Input } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import {
  CreateConnectionComponents as CreateConnectionComponentsV2,
  CreateConnectionView as CreateConnectionViewV2,
} from "views/connections/create/CreateConnectionComponents";
import {
  CreateConnectionComponentsV3,
  CreateConnectionViewV3,
} from "views/connections/create/CreateConnectionComponentsV3";
import {
  GCPServiceAccountJSON,
  UploadServiceAccountJSONButton,
} from "views/connections/create/UploadServiceAccountJSONButton";
import VisibilitySelector from "views/visibility/VisibilitySelector";

const CreateGoogleGroups = () => {
  return <CreateGoogleGroupsForm />;
};

const CreateGoogleGroupsForm = () => {
  const history = useHistory();
  const hasAppLevelVisibility = useFeatureFlag(FeatureFlag.AppLevelVisibility);
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const logEvent = useLogEvent();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [visibility, setVisibility] = useState<Visibility>(Visibility.Global);
  const [visibilityGroupIds, setVisibilityGroupIds] = useState<
    string[] | undefined
  >([]);
  const [groupEmail, setGroupEmail] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const [domain, setDomain] = useState("");

  const [serviceAccountInfo, setServiceAccountInfo] = useState<
    Maybe<GCPServiceAccountJSON>
  >(null);

  const [adminOwnerId, setAdminOwnerId] = useState<string | undefined>(
    undefined
  );

  const [createConnectionMutation, { loading }] = useCreateConnectionMutation({
    refetchQueries: ["AppsListColumn", "Connections"],
  });

  const fieldUnset =
    name === "" ||
    description === "" ||
    !adminOwnerId ||
    groupEmail === "" ||
    userEmail === "" ||
    serviceAccountInfo === null;

  const onSubmit = async () => {
    if (!serviceAccountInfo) {
      return;
    }

    try {
      const { data } = await createConnectionMutation({
        variables: {
          input: {
            name: name,
            description: description,
            connectionType: ConnectionType.GoogleGroups,
            adminOwnerId: adminOwnerId ?? "",
            visibility: visibility,
            visibilityGroupIds: visibilityGroupIds ?? [],
            importVisibility: Visibility.Global,
            metadata: {
              connectionType: ConnectionType.GoogleGroups,
              googleGroups: {
                serviceAccount: {
                  type: serviceAccountInfo.type,
                  projectId: serviceAccountInfo.project_id,
                  clientEmail: serviceAccountInfo.client_email,
                  clientId: serviceAccountInfo.client_id,
                  authUri: serviceAccountInfo.auth_uri,
                  tokenUri: serviceAccountInfo.token_uri,
                  authProviderX509CertUrl:
                    serviceAccountInfo.auth_provider_x509_cert_url,
                  clientX509CertUrl: serviceAccountInfo.client_x509_cert_url,
                },
                opalGroupEmail: groupEmail,
                adminUserEmail: userEmail,
                domain: domain === "" ? null : domain,
              },
            },
            credentials: {
              authType: AuthType.GoogleGroups,
              googleGroups: {
                privateKeyId: serviceAccountInfo.private_key_id,
                privateKey: serviceAccountInfo.private_key,
              },
            },
          },
        },
      });
      switch (data?.createConnection.__typename) {
        case "CreateConnectionResult":
          displaySuccessToast(`Success: Google Groups app created`);
          history.replace(
            getResourceUrl(
              EntityTypeDeprecated.Connection,
              data.createConnection.connection.id
            )
          );
          logEvent({
            name: "apps_create_click",
            properties: {
              connectionType: ConnectionType.GoogleGroups,
            },
          });

          break;
        case "ConnectionExistsError":
        case "ConnectionBadMetadataError":
        case "UserFacingError":
          logWarning(new Error(data.createConnection.message));
          setErrorMessage(data.createConnection.message);
          break;
        default:
          logError(new Error(`failed to create Google Groups connection`));
          setErrorMessage(`Error: failed to create Google Groups connection`);
      }
    } catch (error) {
      logError(error, "failed to create Google Groups connection");
      setErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to create Google Groups connection",
          error
        )
      );
    }
  };

  const CreateConnectionComponents = hasV3
    ? CreateConnectionComponentsV3
    : CreateConnectionComponentsV2;
  const CreateConnectionView = hasV3
    ? CreateConnectionViewV3
    : CreateConnectionViewV2;

  return (
    <CreateConnectionView
      logo={googlegroupsLogo}
      title={"Add your Google Groups organization"}
      onSubmit={onSubmit}
      submitDisabled={fieldUnset}
      submitLoading={loading}
    >
      <>
        <CreateConnectionComponents
          title={"Step 1"}
          subtitle={"Create an Opal Google Group"}
        >
          <>
            <p>
              {`Opal uses this Google Group to automatically import groups.`}
            </p>
            <p>
              {`To read more about how we integrate with Google Groups, `}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={"https://docs.opal.dev/docs/google-groups"}
              >
                {`check out our official documentation`}
              </a>
              .
            </p>
          </>
        </CreateConnectionComponents>
        <CreateConnectionComponents
          title={"Step 2"}
          subtitle={"Create a service account for Opal"}
        >
          <>
            <p>
              {`Opal requires a service account to manage your Google Groups on your behalf.`}
            </p>
          </>
        </CreateConnectionComponents>
        <CreateConnectionComponents
          title={"Step 3"}
          subtitle={"Enable the Admin SDK API in Google"}
        >
          <>
            <p>
              Please also{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://console.developers.google.com/apis/api/admin.googleapis.com/overview"
              >
                {`enable the Admin SDK API`}
              </a>
              {` for the organization.`}
            </p>
          </>
        </CreateConnectionComponents>
        <CreateConnectionComponents
          title={"Step 4"}
          subtitle={"Upload your service account credentials"}
          isLast
        >
          <>
            <p>
              {`Once the service account is created, please input the user's
              credentials and some information about the connection:`}
            </p>
            <FormGroup label="App name:">
              <Input
                onChange={setName}
                placeholder="Identifiable name of the Google Groups connection."
                value={name}
              />
            </FormGroup>
            <FormGroup label="App admin:">
              <OwnerDropdown
                selectedOwnerId={adminOwnerId}
                onSelectOwner={(owner) => setAdminOwnerId(owner?.id)}
                placeholder="Select an owner to own this app."
              />
            </FormGroup>
            <FormGroup label="Description:">
              <Input
                onChange={setDescription}
                placeholder="A brief description of the account to further inform people requesting access to it."
                value={description}
              />
            </FormGroup>
            {hasAppLevelVisibility && (
              <FormGroup label="Visibility:">
                <VisibilitySelector
                  visibility={visibility}
                  onChangeVisibility={(vis) => {
                    if (vis == Visibility.Global) {
                      setVisibility(Visibility.Global);
                      setVisibilityGroupIds([]);
                    } else {
                      setVisibility(Visibility.Team);
                    }
                  }}
                  visibilityGroups={visibilityGroupIds ?? []}
                  onChangeVisibilityGroups={setVisibilityGroupIds}
                />
              </FormGroup>
            )}
            <FormGroup label="Opal group email:">
              <Input
                onChange={setGroupEmail}
                placeholder="The Google Group from which Opal will search for groups."
                value={groupEmail}
              />
            </FormGroup>
            <FormGroup label="Google Workspace admin email:">
              <Input
                onChange={setUserEmail}
                placeholder="The email of the Google Workspace user to run queries on behalf of."
                value={userEmail}
              />
            </FormGroup>
            <FormGroup label="Domain (optional):">
              <Input
                onChange={setDomain}
                placeholder="The domain of the Google Workspace (e.g. 'opal.dev'). If not supplied, the domain of the admin email is used."
                value={domain}
              />
            </FormGroup>
            <FormGroup label="Service account credentials JSON:">
              <UploadServiceAccountJSONButton
                setServiceAccountInfos={setServiceAccountInfo}
              />
            </FormGroup>
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
            {!hasV3 && (
              <div
                className={sprinkles({
                  display: "flex",
                  justifyContent: "flex-end",
                })}
              >
                <Button
                  type="primary"
                  disabled={fieldUnset}
                  label={"Create"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </div>
            )}
          </>
        </CreateConnectionComponents>
      </>
    </CreateConnectionView>
  );
};

export default CreateGoogleGroups;
