import FullscreenView from "components/layout/FullscreenView";
import { Divider } from "components/ui";
import { ProgressStages } from "components/ui/progress_stages/ProgressStages";
import sprinkles from "css/sprinkles.css";
import React from "react";
import { useHistory } from "react-router-dom";

import * as styles from "./CreateConnectionComponentsV3.css";

type CreateConnectionViewV3Props = {
  logo: string;
  title: string;
  children: React.ReactElement;
  backUrl?: string;
  onSubmit?: () => Promise<void>;
  submitDisabled?: boolean;
  submitLoading?: boolean;
};
export const CreateConnectionViewV3 = (props: CreateConnectionViewV3Props) => {
  const history = useHistory();

  const topSection = (
    <div
      className={sprinkles({
        display: "flex",
        justifyContent: "center",
        height: "fit-content",
        paddingTop: "md",
        paddingBottom: "lg",
      })}
    >
      <ProgressStages
        stages={[
          {
            label: "Select app",
            state: "completed",
          },
          {
            label: "Connect app",
            state: "active",
          },
        ]}
      />
    </div>
  );

  return (
    <FullscreenView
      title={"Add App"}
      onCancel={() => {
        history.push("/apps");
      }}
      onBack={() => {
        const backUrl = props.backUrl || "/apps/add-app";
        history.push(backUrl);
      }}
      primaryButtonLabel="Create"
      onPrimaryButtonClick={props.onSubmit}
      primaryButtonDisabled={props.submitDisabled}
      primaryButtonLoading={props.submitLoading}
      topSection={topSection}
      topSectionBorder={false}
    >
      <FullscreenView.Content fullWidth>
        <div className={styles.container}>
          <div className={styles.header}>
            <div className={styles.viewHeader}>
              <div className={styles.logoBox}>
                <img className={styles.logo} alt="logo" src={props.logo} />
              </div>
              <span>{props.title}</span>
            </div>
          </div>
          <div className={styles.viewContent}>{props.children}</div>
        </div>
      </FullscreenView.Content>
    </FullscreenView>
  );
};

type CreateConnectionComponentsV3Props = {
  title: string;
  subtitle: string;
  isLast?: boolean;
  children: React.ReactElement;
};

export const CreateConnectionComponentsV3 = (
  props: CreateConnectionComponentsV3Props
) => {
  return (
    <>
      <div className={styles.connectionPanel}>
        <div className={styles.title}>{props.title.replace("Step ", "")}</div>
        <div className={styles.subtitle}>{props.subtitle}</div>
        {props.children}
      </div>
      {!props.isLast && (
        <div className={styles.divider}>
          <Divider />
        </div>
      )}
    </>
  );
};
