import {
  ConnectionType,
  GroupType,
  ResourceMetadataFragment,
  ResourcePreviewSmallFragment,
  ResourceType,
  ServiceType,
} from "api/generated/graphql";
import {
  OPAL_ADMIN_ROLE_REMOTE_ID,
  OPAL_AUDITOR_ROLE_REMOTE_ID,
  OPAL_IMPERSONATION_REMOTE_ID,
} from "utils/constants";

export const nativeConnectionTypes = new Set([
  ConnectionType.Custom,
  // TODO
  // ConnectionType.Mongo,
  // ConnectionType.MongoAtlas,
  // ConnectionType.Mysql,
  // ConnectionType.Mariadb,
  ConnectionType.Postgres,
  ConnectionType.Opal,
]);

export const isConfigurableConnectionType = (
  connectionType: ConnectionType
) => {
  switch (connectionType) {
    case ConnectionType.Opal:
      return false;
    default:
      return true;
  }
};

export const isNativeConnectionType = (connectionType: ConnectionType) => {
  return nativeConnectionTypes.has(connectionType);
};

export const isNativeResourceType = (resourceType: ResourceType) => {
  switch (resourceType) {
    case ResourceType.Custom:
    case ResourceType.MongoInstance:
    case ResourceType.MongoAtlasInstance:
    case ResourceType.MysqlInstance:
    case ResourceType.MariadbInstance:
    case ResourceType.PostgresInstance:
    case ResourceType.OpalRole:
      return true;
    default:
      return false;
  }
};

export const resourceTypeSupportsChildResources = (
  resourceType: ResourceType
) => {
  switch (resourceType) {
    case ResourceType.AwsAccount:
    case ResourceType.AwsRdsMysqlCluster:
    case ResourceType.AwsRdsPostgresCluster:
    case ResourceType.GcpOrganization:
    case ResourceType.GcpFolder:
    case ResourceType.GcpProject:
    case ResourceType.GcpBigQueryDataset:
    case ResourceType.AzureManagementGroup:
    case ResourceType.AzureSubscription:
    case ResourceType.AzureResourceGroup:
    case ResourceType.AzureStorageAccount:
    case ResourceType.AzureSqlServer:
    case ResourceType.AzureSqlManagedInstance:
    case ResourceType.CustomConnector:
    case ResourceType.SnowflakeDatabase:
    case ResourceType.SnowflakeSchema:
      return true;
    default:
      return false;
  }
};

export const resourceTypeCanBeAccessed = (
  resourceType: ResourceType | GroupType
) => {
  switch (resourceType) {
    case ResourceType.AwsAccount:
    case ResourceType.SnowflakeDatabase:
    case ResourceType.SnowflakeSchema:
    case ResourceType.SnowflakeTable:
      return false;
    default:
      return true;
  }
};

export const isNativeGroupType = (groupType: GroupType) => {
  switch (groupType) {
    case GroupType.OpalGroup:
      return true;
    default:
      return false;
  }
};

export const isGcpServiceType = (serviceType: ServiceType) => {
  switch (serviceType) {
    case ServiceType.GcpBigQuery:
    case ServiceType.GcpStorage:
    case ServiceType.GcpIam:
      return true;
    default:
      return false;
  }
};

// isSnowflakeResource returns true if the resource type is a Snowflake resource.
// We need to single out Snowflake resources because:
// - They cannot be added/removed to/from groups
// - They cannot be requested by users
// - A user cannot be directly assigned to a Snowflake resource
//
// Ideally, we'd have a generic function that describes the above constraints
// but until an abstraction becomes more apparent, we are specifcally targeting
// Snowflake resources.
export const isSnowflakeResource = (resourceType: ResourceType) => {
  switch (resourceType) {
    case ResourceType.SnowflakeDatabase:
    case ResourceType.SnowflakeSchema:
    case ResourceType.SnowflakeTable:
      return true;
  }

  return false;
};

export const isResourceRoleVisible = (
  resource: ResourcePreviewSmallFragment,
  roleLength: Number
) => {
  if (resourceRequiresAtLeastOneRole(resource) && roleLength === 0) {
    return false;
  }
  return true;
};

// resourceTypeHasRoles specifies whether or not a resource type CAN have roles
// not necessarily that they require them
export const resourceTypeHasRoles = (resource: {
  resourceType: ResourceType;
  remoteId?: string;
}) => {
  switch (resource.resourceType) {
    case ResourceType.MongoAtlasInstance:
    case ResourceType.MongoInstance:
    case ResourceType.AwsEksCluster:
    case ResourceType.AzureManagementGroup:
    case ResourceType.AzureSubscription:
    case ResourceType.AzureResourceGroup:
    case ResourceType.AzureVirtualMachine:
    case ResourceType.AzureStorageAccount:
    case ResourceType.AzureStorageContainer:
    case ResourceType.AzureSqlServer:
    case ResourceType.AzureUserAssignedManagedIdentity:
    case ResourceType.AzureSqlManagedInstance:
    case ResourceType.AzureSqlManagedDatabase:
    case ResourceType.GcpGkeCluster:
    case ResourceType.GcpBucket:
    case ResourceType.GcpComputeInstance:
    case ResourceType.GcpFolder:
    case ResourceType.GcpOrganization:
    case ResourceType.GcpProject:
    case ResourceType.GcpBigQueryDataset:
    case ResourceType.GcpBigQueryTable:
    case ResourceType.GcpServiceAccount:
    case ResourceType.PostgresInstance:
    case ResourceType.AwsRdsPostgresInstance:
    case ResourceType.AwsRdsPostgresCluster:
    case ResourceType.MysqlInstance:
    case ResourceType.AwsRdsMysqlInstance:
    case ResourceType.AwsRdsMysqlCluster:
    case ResourceType.MariadbInstance:
    case ResourceType.GitHubRepo:
    case ResourceType.GitLabProject:
    case ResourceType.Custom:
    case ResourceType.CustomConnector:
    case ResourceType.OktaApp:
    case ResourceType.WorkdayRole:
    case ResourceType.OktaRole:
    case ResourceType.SnowflakeDatabase:
    case ResourceType.SnowflakeSchema:
    case ResourceType.SnowflakeTable:
      return true;
    case ResourceType.OpalRole:
      return resource.remoteId === OPAL_IMPERSONATION_REMOTE_ID;
    default:
      return false;
  }
};

export const resourceRequiresAtLeastOneRole = (resource: {
  resourceType: ResourceType;
  metadata?: ResourceMetadataFragment | null;
  remoteId?: string;
}) => {
  switch (resource.resourceType) {
    case ResourceType.MongoAtlasInstance:
    case ResourceType.MongoInstance:
    case ResourceType.AwsEksCluster:
    case ResourceType.AzureManagementGroup:
    case ResourceType.AzureSubscription:
    case ResourceType.AzureResourceGroup:
    case ResourceType.AzureVirtualMachine:
    case ResourceType.AzureStorageAccount:
    case ResourceType.AzureStorageContainer:
    case ResourceType.AzureSqlServer:
    case ResourceType.AzureUserAssignedManagedIdentity:
    case ResourceType.AzureSqlManagedInstance:
    case ResourceType.AzureSqlDatabase:
    case ResourceType.AzureSqlManagedDatabase:
    case ResourceType.GcpGkeCluster:
    case ResourceType.GcpBucket:
    case ResourceType.GcpComputeInstance:
    case ResourceType.GcpFolder:
    case ResourceType.GcpOrganization:
    case ResourceType.GcpProject:
    case ResourceType.GcpBigQueryDataset:
    case ResourceType.GcpBigQueryTable:
    case ResourceType.GcpServiceAccount:
    case ResourceType.PostgresInstance:
    case ResourceType.AwsRdsPostgresInstance:
    case ResourceType.AwsRdsPostgresCluster:
    case ResourceType.MysqlInstance:
    case ResourceType.AwsRdsMysqlInstance:
    case ResourceType.AwsRdsMysqlCluster:
    case ResourceType.MariadbInstance:
    case ResourceType.GitHubRepo:
    case ResourceType.GitLabProject:
    case ResourceType.WorkdayRole:
      return true;
    case ResourceType.OktaRole:
      // Custom Okta roles require a role to specify the resource set
      // that the role grants access to
      if (resource.metadata?.__typename === "OktaRoleMetadata") {
        return resource.metadata.roleType === "CUSTOM";
      }
      return false;
    case ResourceType.OpalRole:
      return resource.remoteId === OPAL_IMPERSONATION_REMOTE_ID;
    default:
      return false;
  }
};

export const serviceTypeHasMaxOneRole = (
  serviceType: ServiceType | undefined
) => {
  if (serviceType) {
    switch (serviceType) {
      case ServiceType.GitLab:
        return true;
      default:
        return false;
    }
  }
  return false;
};

export const serviceTypeHasCustomRoles = (
  serviceType: ServiceType,
  connectionType?: ConnectionType,
  resourceType?: ResourceType
) => {
  switch (serviceType) {
    // disable connectors for now as we don't support adding/removing roles from Opal
    // case ServiceType.CustomConnector:
    case ServiceType.Custom:
    case ServiceType.GcpIam:
    case ServiceType.GcpStorage:
    case ServiceType.GcpBigQuery:
    case ServiceType.Kubernetes:
    case ServiceType.Mongo:
    case ServiceType.MongoAtlas:
    case ServiceType.Mariadb:
    case ServiceType.AzureAd:
      return true;
    case ServiceType.Mysql:
      return resourceType !== ResourceType.GcpCloudSqlMysqlInstance;
    case ServiceType.Postgres:
      return resourceType !== ResourceType.GcpCloudSqlPostgresInstance;
    case ServiceType.Ssh:
      return connectionType === ConnectionType.Gcp;
    default:
      return false;
  }
};

// TODO OPAL-1751 this is a misnomer since there are other resources that only have 1 role (e.g. only 1 custom
// role is defined). These are actually resources that only have 1 DEFAULT role whose value is "".
export const resourceHasOnlyOneRole = (resource: {
  serviceType: ServiceType;
  resourceType: ResourceType;
  connection?: {
    connectionType?: ConnectionType;
  } | null;
  remoteId: string;
}) => {
  switch (resource.resourceType) {
    case ResourceType.OktaApp:
      return true;
    default:
      return entityHasOnlyOneRole(
        resource.serviceType,
        resource.connection?.connectionType,
        resource.remoteId
      );
  }
};

export const entityHasOnlyOneRole = (
  serviceType: ServiceType,
  connectionType: ConnectionType | undefined,
  remoteId: string
) => {
  switch (serviceType) {
    case ServiceType.AwsIam:
      return true;
    case ServiceType.AwsSso:
      return true;
    case ServiceType.Ssh:
      return connectionType !== ConnectionType.Gcp;
    case ServiceType.Opal:
      switch (remoteId) {
        case OPAL_AUDITOR_ROLE_REMOTE_ID:
        case OPAL_ADMIN_ROLE_REMOTE_ID:
          return true;
        default:
          return false;
      }
    case ServiceType.Salesforce:
      return true;
    default:
      return false;
  }
};

// returns true if the remote id is that of the Opal Global Impersonation resource or
// the Opal Global Impersonation Prod resource
export const isGlobalImpersonationResource = (remoteId: string): boolean => {
  return (
    remoteId === "opal-global-impersonation" ||
    remoteId === "opal-global-impersonation-prod"
  );
};

// Returns true if the resource type can be added to a group type
export const canAddResourceTypeToGroupType = (
  resourceType: ResourceType,
  groupType: GroupType
) => {
  if (resourceType === ResourceType.AwsAccount) {
    return false;
  }
  if (isSnowflakeResource(resourceType)) {
    return groupType === GroupType.SnowflakeRole;
  }

  return true;
};

export const principalResourceTypes = [
  ResourceType.GcpServiceAccount,
  ResourceType.AzureUserAssignedManagedIdentity,
];

// Returns true if the resource type can act as a principal
// and have access to other resources or groups
export const resourceTypeCanBePrincipal = (resourceType: ResourceType) => {
  return principalResourceTypes.includes(resourceType);
};

export function canImportCustomRoles(
  serviceType: ServiceType,
  connectionType: ConnectionType | undefined
): boolean {
  return (
    isGcpServiceType(serviceType) ||
    connectionType === ConnectionType.Gcp ||
    connectionType === ConnectionType.AzureAd
  );
}
