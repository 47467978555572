import {
  ThirdPartyProvider,
  useCreateThirdPartyIntegrationMutation,
} from "api/generated/graphql";
import { useToast } from "components/toast/Toast";
import { cloneDeep } from "lodash";
import { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMountEffect } from "utils/hooks";
import { logError, logWarning } from "utils/logging";
import FullPageLoading from "views/loading/FullPageLoading";

import OrgContext, {
  OrgContextActionType,
} from "../../views/settings/OrgContext";

// SlackCallback is a dedicated component for handling the auth redirect
// and parsing/removing the auth token fragment from the URL for Slack integration.
export const SlackCallback = () => {
  const location = useLocation();
  const { orgState, orgDispatch } = useContext(OrgContext);
  const integrations = cloneDeep(orgState.orgThirdPartyIntegrations ?? []);

  const { displaySuccessToast, displayErrorToast } = useToast();

  const history = useHistory();
  const [
    createThirdPartyIntegration,
  ] = useCreateThirdPartyIntegrationMutation();

  useMountEffect(() => {
    async function createIntegrationWrapper() {
      const query = new URLSearchParams(location.search);
      const code = query.get("code");
      const state = query.get("state");

      if (code && state) {
        try {
          // We have received callback parameters from Slack,
          // so we need to forward them on to the Opal server.
          const { data } = await createThirdPartyIntegration({
            variables: {
              input: {
                thirdPartyProvider: ThirdPartyProvider.Slack,
                codeFlow: {
                  code: code,
                  state: state,
                },
              },
            },
            refetchQueries: ["ThirdPartyIntegrations"],
          });
          switch (data?.createThirdPartyIntegration.__typename) {
            case "CreateThirdPartyIntegrationResult":
              displaySuccessToast("Success: Slack account linked");
              integrations.push(
                data.createThirdPartyIntegration.thirdPartyIntegration
              );
              orgDispatch({
                type: OrgContextActionType.OrgThirdPartyIntegrations,
                payload: {
                  orgThirdPartyIntegrations: integrations,
                },
              });
              break;
            case "SlackOrganizationAlreadyExistsError":
              logWarning(new Error(data.createThirdPartyIntegration.message));
              displayErrorToast(data.createThirdPartyIntegration.message);
              break;
            default:
              logError(new Error(`failed to link Slack account`));
              displayErrorToast("Error: failed to link Slack account");
          }
        } catch (error) {
          logError(error, `failed to link Slack account`);
          displayErrorToast("Error: failed to link Slack account");
        }
        history.replace("/settings");
      } else {
        if (code && !state) {
          // If just state is missing, it's possible the user is trying to initiate
          // the installation from the Slack dashboard (instead of via the Connect button on this page).
          //
          // We don't support that currently, so show an error directing the user.
          displayErrorToast(
            "Error: If you are trying to connect to Opal from the Slack dashboard, try initiating the connection from this page."
          );
          history.replace("/settings");
          return;
        }
        displayErrorToast("Error: failed to link Slack account");
        history.replace("/settings");
      }
    }
    createIntegrationWrapper();
  });

  return <FullPageLoading />;
};

export default SlackCallback;
