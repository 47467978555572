import {
  EntityIdTuple,
  EntityType,
  Maybe,
  ReviewerUserInput,
} from "api/generated/graphql";
import { PaginatedUserDropdown } from "components/dropdown/PaginatedUserDropdown";
import UserLabel from "components/label/item_labels/UserLabel";
import { TooltipPlacement } from "components/label/Label";
import accessReviewerModalStyles from "components/modals/AccessReviewerModal.module.scss";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { Banner, Button, Modal, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { ReactElement, useEffect, useRef, useState } from "react";
import * as Icons from "react-feather";
import { getResourceUrlNew } from "utils/common";

export type AccessReviewerModalEntry = {
  id: string;
  name: string;
  avatarUrl: string;
  canBeRemoved: boolean;
  entityIds?: Maybe<EntityIdTuple[]>;
};

type AccessReviewerModalProps = {
  title: string;
  isModalOpen: boolean;
  onClose: () => void;
  onSubmit: (reviewers: ReviewerUserInput[]) => void;
  entryInfos: AccessReviewerModalEntry[];
  canEditReviewers: boolean;
  loading: boolean;
  errorMessage: string;
  numUserReviews?: number;
  numResourceReviews?: number;
};

const AccessReviewerModal = (props: AccessReviewerModalProps) => {
  const [users, setUsers] = useState<AccessReviewerModalEntry[]>(
    props.entryInfos
  );
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const reviewersListDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollToBottom && reviewersListDiv.current) {
      reviewersListDiv.current.scrollTop =
        reviewersListDiv.current.offsetHeight;
      setScrollToBottom(false);
    }
  }, [scrollToBottom, reviewersListDiv]);

  const formUnchanged = isFormUnchanged(props.entryInfos, users);

  const onSubmit = async () => {
    const reviewers = users.map((user) => {
      return { userId: user.id, entityIds: user.entityIds };
    });
    await props.onSubmit(reviewers);
  };

  const prefixVerb = props.canEditReviewers ? `Assigning` : `Displaying`;
  let subtitle = "";
  if (props.numUserReviews !== undefined) {
    if (props.numResourceReviews === undefined) {
      subtitle = `${prefixVerb} reviewers to ${
        props.numUserReviews
      } ${pluralize("user", props.numUserReviews)} in this resource`;
    } else {
      subtitle = `${prefixVerb} reviewers to ${
        props.numUserReviews
      } ${pluralize("user", props.numUserReviews)} and ${
        props.numResourceReviews
      } ${pluralize("resource", props.numResourceReviews)} in this group`;
    }
  }

  return (
    <Modal
      isOpen={props.isModalOpen}
      onClose={() => {
        props.onClose();
        setShowUserDropdown(false);
      }}
      title={props.title}
      subtitle={subtitle}
    >
      <Modal.Body>
        {props.errorMessage && (
          <ModalErrorMessage errorMessage={props.errorMessage} />
        )}

        <div
          className={sprinkles({ marginBottom: "md" })}
          ref={reviewersListDiv}
        >
          {users
            .sort((a, b) => {
              return Number(a.canBeRemoved) - Number(b.canBeRemoved);
            })
            .map((user) => {
              return (
                <AccessReviewerModalUser
                  onFocus={() => {
                    setShowUserDropdown(false);
                  }}
                  key={`entry_${user.id}`}
                  entryInfo={user}
                  canEditReviewers={props.canEditReviewers}
                  numUserReviews={props.numUserReviews}
                  numResourceReviews={props.numResourceReviews}
                  removeUser={(userId) => {
                    const updatedUsers = users.filter((user) => {
                      return user.id !== userId;
                    });
                    setUsers(updatedUsers);
                  }}
                />
              );
            })}

          {props.canEditReviewers &&
            (!showUserDropdown ? (
              <div
                className={accessReviewerModalStyles.addItemContainer}
                onClick={() => {
                  setShowUserDropdown(true);
                }}
              >
                <Button leftIconName="plus" type="primary" borderless />
                <span
                  className={sprinkles({
                    marginLeft: "sm",
                    fontSize: "labelLg",
                  })}
                >
                  Add Reviewer
                </span>
              </div>
            ) : (
              <div className={accessReviewerModalStyles.userDropdownContainer}>
                <PaginatedUserDropdown
                  value={undefined} // Not used, because this dropdown is immediately hidden after input is selected
                  onChange={(newUser) => {
                    if (!newUser) {
                      return; // Shouldn't hit this since not clearable
                    }
                    setUsers([
                      ...users,
                      {
                        name: newUser.fullName,
                        avatarUrl: newUser.avatarUrl,
                        id: newUser.id,
                        canBeRemoved: true,
                      },
                    ]);
                    setShowUserDropdown(false);
                    setScrollToBottom(true);
                  }}
                  hiddenUserIds={users.map((user) => user.id)}
                  clearable={false}
                  autoFocus
                />
              </div>
            ))}
        </div>

        {!formUnchanged && users.length > 0 && (
          <Banner
            type="info"
            message={`When you click "Assign Reviewers", you certify that all
                reviewers have the authority and context to perform the review.`}
          />
        )}
      </Modal.Body>

      {props.canEditReviewers ? (
        <Modal.Footer
          secondaryButtonLabel="Close"
          onSecondaryButtonClick={() => {
            props.onClose();
            setShowUserDropdown(false);
          }}
          primaryButtonLabel="Assign Reviewers"
          primaryButtonDisabled={formUnchanged || props.loading}
          primaryButtonLoading={props.loading}
          onPrimaryButtonClick={onSubmit}
        />
      ) : (
        <Modal.Footer
          onPrimaryButtonClick={props.onClose}
          primaryButtonLabel={"Close"}
        />
      )}
    </Modal>
  );
};

const isFormUnchanged = (
  initReviewers: AccessReviewerModalEntry[],
  selectedReviewers: AccessReviewerModalEntry[]
) => {
  if (initReviewers.length !== selectedReviewers.length) {
    return false;
  }

  for (const entryInfo of initReviewers) {
    let foundEqual = false;
    for (const user of selectedReviewers) {
      if (entryInfo.id === user.id && entryInfo.entityIds === user.entityIds) {
        foundEqual = true;
        break;
      }
    }
    if (!foundEqual) {
      return false;
    }
  }
  return true;
};

type AccessReviewerModalUserProps = {
  entryInfo: AccessReviewerModalEntry;
  canEditReviewers: boolean;
  removeUser: (userId: string) => void;
  onFocus: () => void;
  numUserReviews?: number;
  numResourceReviews?: number;
};

const AccessReviewerModalUser = (props: AccessReviewerModalUserProps) => {
  let subText;
  if (props.numUserReviews !== undefined) {
    if (props.entryInfo.entityIds) {
      const numUsers = props.entryInfo.entityIds.filter(
        (e) =>
          e.entityType === EntityType.AccessReviewResourceUser ||
          e.entityType === EntityType.AccessReviewGroupUser ||
          e.entityType === EntityType.AccessReviewConnectionUser
      ).length;
      subText = `Reviewing ${numUsers} ${pluralize("user", numUsers)}`;
    } else {
      subText = `Reviewing ${props.numUserReviews} ${pluralize(
        "user",
        props.numResourceReviews
      )}`;
    }
  }

  if (props.numResourceReviews !== undefined) {
    if (props.entryInfo.entityIds) {
      const numResources = props.entryInfo.entityIds.filter(
        (e) => e.entityType === EntityType.AccessReviewGroupResource
      ).length;
      subText += ` and ${numResources} ${pluralize("resources", numResources)}`;
    } else {
      subText += ` and ${props.numResourceReviews} ${pluralize(
        "resources",
        props.numResourceReviews
      )}`;
    }
  }

  let trashIcon: ReactElement | undefined;
  if (props.canEditReviewers) {
    if (props.entryInfo.canBeRemoved) {
      trashIcon = (
        <div
          className={accessReviewerModalStyles.deleteIconClickable}
          onClick={() => {
            props.removeUser(props.entryInfo.id);
            props.onFocus();
          }}
        >
          <Icons.Trash2 strokeWidth={2} size={22} />
        </div>
      );
    } else {
      trashIcon = (
        <div className={accessReviewerModalStyles.deleteIcon}>
          <Tooltip
            tooltipText={`Reviewers who already completed a review cannot be removed.`}
            placement={TooltipPlacement.Bottom}
          >
            <div>
              <Icons.Trash2 strokeWidth={2} size={22} />
            </div>
          </Tooltip>
        </div>
      );
    }
  }

  return (
    <div className={accessReviewerModalStyles.entryContainer}>
      <UserLabel
        name={props.entryInfo.name}
        avatar={props.entryInfo.avatarUrl}
        url={getResourceUrlNew(
          {
            entityId: props.entryInfo.id,
            entityType: EntityType.User,
          },
          EntityType.User
        )}
        subText={subText}
        large
        bold
      />
      <div className={accessReviewerModalStyles.dropdownTrashIconContainer}>
        {trashIcon}
      </div>
    </div>
  );
};

export default AccessReviewerModal;
