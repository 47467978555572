import { IconName } from "components/ui/icon/Icon";
import { useContext } from "react";
import { Route, Switch } from "react-router";
import { AppsContext } from "views/apps/AppsContext";

import BundleEventsColumn from "./BundleEventsColumn";
import BundleGroupsColumn from "./BundleGroupsColumn";
import BundleOverviewColumn from "./BundleOverviewColumn";
import BundleResourcesColumn from "./BundleResourcesColumn";

type BundleViewKey = "overview" | "resources" | "groups" | "events" | "details";

export interface BundleView {
  key: BundleViewKey;
  title: string;
  icon: IconName;
  content: React.ComponentType;
}

export const BUNDLE_VIEWS: BundleView[] = [
  {
    key: "overview",
    title: "Overview",
    icon: "list",
    content: BundleOverviewColumn,
  },
  {
    key: "resources",
    title: "Resources",
    icon: "cube",
    content: BundleResourcesColumn,
  },
  {
    key: "groups",
    title: "Groups",
    icon: "users",
    content: BundleGroupsColumn,
  },
  {
    key: "events",
    title: "Events",
    icon: "events",
    content: BundleEventsColumn,
  },
];

const BundleDetailContent = () => {
  const { selectedBundleItems } = useContext(AppsContext);
  if (selectedBundleItems.length > 0) {
    return null;
  }

  return (
    <Switch>
      {BUNDLE_VIEWS.map((view) => (
        <Route
          key={view.key}
          exact
          path={`/bundles/:bundleId/${view.key}`}
          component={view.content}
        />
      ))}
    </Switch>
  );
};

export default BundleDetailContent;
