import {
  AccessReviewAction,
  AccessReviewResourceFragment,
  AccessReviewResourceResourceFragment,
  AccessReviewResourceUserFragment,
  AccessReviewType,
  EntityType,
  HrIdpStatus,
  Maybe,
  ResourceAccessLevel,
  ResourcePreviewTinyFragment,
  ReviewerUserFragment,
  ReviewerUserStatus,
  ReviewResourceResourceAction,
  ReviewResourceUserAction,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import { ResourceLabel, TooltipPlacement } from "components/label/Label";
import {
  Icon,
  Input,
  Label,
  ProgressBar,
  TabsV3,
  Tooltip,
} from "components/ui";
import ButtonGroup from "components/ui/buttongroup/ButtonGroupV3";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import pluralize from "pluralize";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { resourceTypeCanBePrincipal } from "utils/directory/resources";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { filterSearchResults } from "utils/search/filter";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { formatHrIdpStatus } from "views/users/utils";
import { dropNothings } from "views/utils";

import { calculateBatchAcceptRevokeState } from "./AccessReviewAcceptRevokeToggleButton";
import {
  AccessReviewContextActionType,
  AccessReviewContextEvent,
  AccessReviewContextState,
  emptyPerformReviewState,
} from "./AccessReviewContext";
import { updateReviewStateForResourceUserRoleChange } from "./AccessReviewResourceRoleCell";
import * as styles from "./AccessReviewResourceV3.css";
import DecisionButtons from "./common/DecisionButtons";
import NoteCell from "./common/NoteCell";
import StatusCell from "./common/StatusCell";
import { ReviewerUsers } from "./ReviewerUsers";

interface ResourceUserRow {
  id: string;
  user: string;
  role: string;
  title?: string;
  manager?: string;
  team?: string;
  hrIdpStatus?: HrIdpStatus;
  updatedRole?: string;
  expires?: string;
  outcome?: string;
  note?: string;
  reviewerUsers?: ReviewerUserFragment[];
  data: AccessReviewResourceUserFragment;
}

interface ResourceResourceRow {
  id: string;
  resource: ResourcePreviewTinyFragment;
  role: string;
  updatedRole?: string;
  expires?: string;
  outcome?: string;
  note?: string;
  reviewerUsers?: ReviewerUserFragment[];
  data: AccessReviewResourceResourceFragment;
}

interface Props {
  accessReviewResource: AccessReviewResourceFragment;
  accessReviewState: AccessReviewContextState;
  accessReviewDispatch: React.Dispatch<AccessReviewContextEvent>;
  mode?: "principalView" | "entityView";
}

const AccessReviewResourceV3 = ({
  accessReviewResource,
  accessReviewState,
  accessReviewDispatch,
}: Props) => {
  const location = useLocation();
  const history = useHistory();
  const { authState } = useContext(AuthContext);

  const { accessReviewResourceId } = useParams<Record<string, string>>();
  const performReviewState =
    accessReviewState.performReviewStateByUARResourceId[accessReviewResourceId];

  const hasNHIs = useFeatureFlag(FeatureFlag.NonHumanIdentities);

  // Initialize review state if not already initialized
  useEffect(() => {
    if (!performReviewState || !performReviewState.resourceUserActions) {
      accessReviewDispatch({
        type: AccessReviewContextActionType.AccessReviewItemUpdate,
        payload: {
          performReviewStateByUARResourceId: {
            ...accessReviewState.performReviewStateByUARResourceId,
            [accessReviewResourceId]: emptyPerformReviewState(),
          },
        },
      });
    }
  }, [
    accessReviewState,
    accessReviewResourceId,
    performReviewState,
    accessReviewDispatch,
  ]);

  if (!performReviewState) {
    return null;
  }

  if (!accessReviewResource.resource) {
    return (
      <Column isContent maxWidth="lg">
        <UnexpectedErrorPage />
      </Column>
    );
  }

  const canBePrincipal = resourceTypeCanBePrincipal(
    accessReviewResource.resource.resourceType
  );
  const selfReviewAllowed =
    accessReviewResource?.accessReview?.selfReviewAllowed;

  const numIncompleteResourceUsers = (
    accessReviewResource?.resourceUsers ?? []
  ).filter((user) => {
    return user.reviewerUsers?.find((reviewer) => {
      return (
        reviewer.userId === authState.user?.user.id &&
        (selfReviewAllowed || authState.user?.user.id !== user.userId) &&
        reviewer.status === ReviewerUserStatus.NotStarted
      );
    });
  })?.length;

  let numIncompleteNHIReviews = 0;
  let numIncompleteResourceReviews = 0;
  accessReviewResource.resourceResources?.forEach((resourceResource) => {
    resourceResource.reviewerUsers?.forEach((reviewer) => {
      if (
        reviewer.userId === authState.user?.user.id &&
        reviewer.status === ReviewerUserStatus.NotStarted
      ) {
        if (
          accessReviewResource.id ==
          resourceResource.accessReviewResourceEntityId
        ) {
          numIncompleteNHIReviews++;
        } else if (
          accessReviewResource.id ==
          resourceResource.accessReviewResourcePrincipalId
        ) {
          numIncompleteResourceReviews++;
        }
      }
    });
  });

  if (!hasNHIs) {
    return (
      <AccessReviewResourceUsers
        accessReviewResource={accessReviewResource}
        accessReviewState={accessReviewState}
        accessReviewDispatch={accessReviewDispatch}
      />
    );
  }

  return (
    <>
      <div className={styles.tabsContainer}>
        <div className={styles.tabs}>
          <TabsV3
            tabInfos={dropNothings([
              {
                title: "Users",
                onClick: () => history.replace({ hash: "#users" }),
                isSelected: !location.hash || location.hash === "#users",
                icon: { type: "name", icon: "users" },
                badgeCount: numIncompleteResourceUsers,
              },
              {
                title: "Non-Human Identities",
                onClick: () => history.replace({ hash: "#nhis" }),
                isSelected: location.hash === "#nhis",
                icon: { type: "name", icon: "service-account" },
                badgeCount: numIncompleteNHIReviews,
              },
              canBePrincipal
                ? {
                    title: "Resources",
                    onClick: () => history.replace({ hash: "#resources" }),
                    isSelected: location.hash === "#resources",
                    icon: { type: "name", icon: "cube" },
                    badgeCount: numIncompleteResourceReviews,
                  }
                : undefined,
            ])}
          />
        </div>
      </div>
      {(location.hash === "#users" || location.hash === "") && (
        <AccessReviewResourceUsers
          accessReviewResource={accessReviewResource}
          accessReviewState={accessReviewState}
          accessReviewDispatch={accessReviewDispatch}
        />
      )}
      {location.hash === "#resources" && (
        <AccessReviewResourceResources
          accessReviewResource={accessReviewResource}
          accessReviewState={accessReviewState}
          accessReviewDispatch={accessReviewDispatch}
          mode="entityView"
        />
      )}
      {location.hash === "#nhis" && (
        <AccessReviewResourceResources
          accessReviewResource={accessReviewResource}
          accessReviewState={accessReviewState}
          accessReviewDispatch={accessReviewDispatch}
          mode="principalView"
        />
      )}
    </>
  );
};

const AccessReviewResourceUsers = ({
  accessReviewResource,
  accessReviewState,
  accessReviewDispatch,
}: Props) => {
  const accessReviewID = accessReviewResource.accessReviewId;
  const { accessReviewResourceId } = useParams<Record<string, string>>();

  const { authState } = useContext(AuthContext);
  const [selectedResourceUserIds, setSelectedResourceUserIds] = useState<
    string[]
  >([]);
  const [
    selectedBulkAction,
    setSelectedBulkAction,
  ] = useState<AccessReviewAction>(AccessReviewAction.NoAction);
  const [bulkUpdatedNote, setBulkUpdatedNote] = useState<string>();

  const [searchQuery, setSearchQuery] = useState("");

  const hasNHIs = useFeatureFlag(FeatureFlag.NonHumanIdentities);

  const performReviewState =
    accessReviewState.performReviewStateByUARResourceId[accessReviewResourceId];

  const selfReviewAllowed =
    accessReviewResource?.accessReview?.selfReviewAllowed;
  const resource = accessReviewResource?.resource;

  const noteByResourceUserID: Record<string, string> = {};
  const completedUsers = (accessReviewResource?.resourceUsers ?? []).filter(
    (user) => {
      return user.reviewerUsers?.find((reviewer) => {
        if (reviewer.userId === authState.user?.user.id) {
          noteByResourceUserID[user.id] = reviewer.note ?? "";
        }
        return (
          reviewer.userId === authState.user?.user.id &&
          (selfReviewAllowed || authState.user?.user.id !== user.userId) &&
          reviewer.status !== ReviewerUserStatus.NotStarted
        );
      });
    }
  );
  const notStartedUsers = (accessReviewResource?.resourceUsers ?? []).filter(
    (user) => {
      return user.reviewerUsers?.find((reviewer) => {
        if (reviewer.userId === authState.user?.user.id) {
          noteByResourceUserID[user.id] = reviewer.note ?? "";
        }
        return (
          reviewer.userId === authState.user?.user.id &&
          (selfReviewAllowed || authState.user?.user.id !== user.userId) &&
          reviewer.status === ReviewerUserStatus.NotStarted
        );
      });
    }
  );

  const [showCompleted, setShowCompleted] = useState(
    notStartedUsers.length === 0 && completedUsers.length > 0
  );

  const filteredSearchResourceUsers = filterSearchResults(
    showCompleted ? completedUsers : notStartedUsers,
    searchQuery,
    (resourceUser) => [resourceUser.user?.fullName, resourceUser.user?.email]
  );

  if (!performReviewState) {
    return null;
  }

  if (!resource) {
    return (
      <Column isContent maxWidth="lg">
        <UnexpectedErrorPage />
      </Column>
    );
  }

  const getResourceUserReviewState = (resourceUserId: string) => {
    return performReviewState.resourceUserActions.find(
      (ru) => ru.accessReviewResourceUserId === resourceUserId
    )?.action;
  };

  const handleReviewAction = (
    state: AccessReviewAction,
    accessReviewResourceUserId: string
  ) => {
    const existingInfo = performReviewState.resourceUserActions.find(
      (resourceUserAction) =>
        resourceUserAction.accessReviewResourceUserId ===
        accessReviewResourceUserId
    );

    if (existingInfo) {
      existingInfo.action = state;
      delete existingInfo.updatedAccessLevel;
      delete existingInfo.updatedResource;
    } else {
      performReviewState.resourceUserActions.push({
        accessReviewResourceUserId,
        action: state,
      });
    }
    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARResourceId: {
          ...accessReviewState.performReviewStateByUARResourceId,
          [accessReviewResourceId]: performReviewState,
        },
      },
    });
  };

  const handleBulkReviewAction = (state: AccessReviewAction) => {
    performReviewState.resourceUserActions.forEach((resourceUserAction) => {
      resourceUserAction.action = state;
    });

    notStartedUsers.forEach((resourceUser) => {
      const existingInfo = performReviewState.resourceUserActions.find(
        (resourceUserAction) =>
          resourceUserAction.accessReviewResourceUserId === resourceUser.id
      );

      if (!existingInfo) {
        performReviewState.resourceUserActions.push({
          accessReviewResourceUserId: resourceUser.id,
          action: state,
        });
      } else {
        delete existingInfo.updatedAccessLevel;
      }
    });

    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARResourceId: {
          ...accessReviewState.performReviewStateByUARResourceId,
          [accessReviewResourceId]: performReviewState,
        },
      },
    });
  };

  const resourceUserActionByUarResourceUserId: Record<
    string,
    ReviewResourceUserAction
  > = {};
  performReviewState?.resourceUserActions?.forEach((resourceUserAction) => {
    const id = resourceUserAction.accessReviewResourceUserId;
    resourceUserActionByUarResourceUserId[id] = resourceUserAction;
  });

  const rows: ResourceUserRow[] = filteredSearchResourceUsers.map(
    (resourceUser) => {
      const resourceUserAction =
        resourceUserActionByUarResourceUserId[resourceUser.id];
      const otherReviewers = (resourceUser.reviewerUsers ?? []).filter(
        (reviewer) => reviewer.userId !== authState.user?.user.id
      );

      return {
        id: resourceUser.id,
        user: resourceUser.user?.fullName || resourceUser.userId,
        outcome: resourceUser.statusAndOutcome.outcome,
        updatedRole: resourceUserAction?.updatedAccessLevel?.accessLevelName,
        role: resourceUser.accessLevel.accessLevelName ?? "--",
        note:
          resourceUserAction?.note ??
          noteByResourceUserID[resourceUser.id] ??
          undefined,
        reviewerUsers: otherReviewers,
        data: resourceUser,
        title: resourceUser.user?.position,
        manager: resourceUser.user?.manager?.fullName ?? "--",
        team: resourceUser.user?.teamAttr ?? "--",
        hrIdpStatus: resourceUser.user?.hrIdpStatus ?? undefined,
      };
    }
  );

  const resourceHasRoles = notStartedUsers.some(
    (user) => !!user.accessLevel.accessLevelRemoteId
  );

  const bulkState = calculateBatchAcceptRevokeState(
    performReviewState.resourceUserActions,
    notStartedUsers.length
  );
  const bulkDecisionHeader = (
    <DecisionButtons
      state={bulkState}
      resourceId={resource.id}
      onApproveClick={() =>
        handleBulkReviewAction(
          bulkState === AccessReviewAction.Accept
            ? AccessReviewAction.NoAction
            : AccessReviewAction.Accept
        )
      }
      onRevokeClick={() =>
        handleBulkReviewAction(
          bulkState === AccessReviewAction.Revoke
            ? AccessReviewAction.NoAction
            : AccessReviewAction.Revoke
        )
      }
      onRoleSelect={(role) => {
        if (!performReviewState) {
          return;
        }

        let updatedPerformReviewState = {
          ...performReviewState,
        };
        notStartedUsers.forEach((resourceUser) => {
          updatedPerformReviewState = updateReviewStateForResourceUserRoleChange(
            updatedPerformReviewState,
            resourceUser,
            role as Maybe<ResourceAccessLevel>
          );
        });

        accessReviewDispatch({
          type: AccessReviewContextActionType.AccessReviewItemUpdate,
          payload: {
            performReviewStateByUARResourceId: {
              ...accessReviewState.performReviewStateByUARResourceId,
              [accessReviewResourceId]: updatedPerformReviewState,
            },
          },
        });
      }}
      showRole={resourceHasRoles}
      bulkAction
      clearBackground
    />
  );

  const userColumn: Header<ResourceUserRow> = {
    id: "user",
    label: "User",
    width: 130,
    customCellRenderer: (row) => {
      const user = row.data.user;
      if (!user) {
        return <></>;
      }
      return (
        <ResourceLabel
          text={user.fullName}
          avatar={user.avatarUrl}
          pointerCursor={true}
          entityId={user.id}
          entityTypeNew={EntityType.User}
          target="_blank"
        />
      );
    },
  };

  const expiresColumn: Header<ResourceUserRow> = {
    id: "expires",
    label: "Expires",
    width: 75,
    customCellRenderer: (row) => {
      const expirationString =
        row.data.resourceUser?.access?.latestExpiringAccessPoint?.expiration;
      if (!expirationString) {
        return <>Never</>;
      }
      const expires = moment(expirationString).fromNow();
      return <Label label={expires} oneLine />;
    },
  };

  const roleColumn: Header<ResourceUserRow> = {
    id: "role",
    label: "Role",
    width: 130,
    customCellRenderer: (row) => {
      return (
        <span
          className={sprinkles({
            color: row.updatedRole ? "blue600" : "black",
          })}
        >
          <Label
            label={row.updatedRole ?? row.role}
            truncateLength={20}
            oneLine
          />
        </span>
      );
    },
  };

  const outcomeColumn: Header<ResourceUserRow> = {
    id: "outcome",
    label: "",
    sortable: false,
    customHeader: rows.length > 0 ? bulkDecisionHeader : <></>,
    width: resourceHasRoles ? 200 : 140,
    customCellRenderer: (row) => {
      const state = getResourceUserReviewState(row.id);
      return (
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
          })}
        >
          <DecisionButtons
            state={state}
            resourceId={resource.id}
            onApproveClick={() =>
              handleReviewAction(
                state === AccessReviewAction.Accept
                  ? AccessReviewAction.NoAction
                  : AccessReviewAction.Accept,
                row.id
              )
            }
            onRevokeClick={() =>
              handleReviewAction(
                state === AccessReviewAction.Revoke
                  ? AccessReviewAction.NoAction
                  : AccessReviewAction.Revoke,
                row.id
              )
            }
            onRoleSelect={(role) => {
              if (!performReviewState) {
                return;
              }
              const resourceUser = row.data;

              const updatedPerformReviewState = updateReviewStateForResourceUserRoleChange(
                performReviewState,
                resourceUser,
                role as Maybe<ResourceAccessLevel>
              );

              accessReviewDispatch({
                type: AccessReviewContextActionType.AccessReviewItemUpdate,
                payload: {
                  performReviewStateByUARResourceId: {
                    ...accessReviewState.performReviewStateByUARResourceId,
                    [accessReviewResourceId]: updatedPerformReviewState,
                  },
                },
              });
            }}
            showRole={resourceHasRoles}
          />
          <NoteCell
            note={row.note}
            accessReviewID={accessReviewID}
            targetIDs={[row.id]}
            targetType={AccessReviewType.ResourceUser}
            onNoteChange={(updatedNoteContent) => {
              const resourceUser = row.data;
              if (!performReviewState) {
                return;
              }
              const existingInfo = performReviewState.resourceUserActions.find(
                (resourceUserAction) =>
                  resourceUserAction.accessReviewResourceUserId ===
                  resourceUser.id
              );
              if (existingInfo) {
                existingInfo.note = updatedNoteContent;
              } else {
                performReviewState.resourceUserActions.push({
                  accessReviewResourceUserId: resourceUser.id,
                  action: AccessReviewAction.NoAction,
                  note: updatedNoteContent,
                });
              }
              accessReviewDispatch({
                type: AccessReviewContextActionType.AccessReviewItemUpdate,
                payload: {
                  performReviewStateByUARResourceId: {
                    ...accessReviewState.performReviewStateByUARResourceId,
                    [accessReviewResourceId]: performReviewState,
                  },
                },
              });
            }}
          />
        </div>
      );
    },
  };

  const noteColumn: Header<ResourceUserRow> = {
    id: "note",
    label: "",
    width: 24,
    customCellRenderer: (row) => {
      if (!row.note) return <></>;
      return (
        <Tooltip tooltipText={row.note} placement={TooltipPlacement.Top}>
          <Icon name="message" color="blue700V3" />
        </Tooltip>
      );
    },
  };

  const reviewerUsersColumn: Header<ResourceUserRow> = {
    id: "reviewerUsers",
    label: "",
    width: 24,
    customCellRenderer: (row) => {
      if (!row.reviewerUsers || row.reviewerUsers.length <= 1) {
        return <></>;
      }

      return <ReviewerUsers reviewerUsers={row.reviewerUsers} />;
    },
  };

  const outcomeCompletedColumn: Header<ResourceUserRow> = {
    id: "outcome", // Only shown in the completed view
    label: "Status",
    sortable: false,
    width: 80,
    customCellRenderer: (row) => {
      const outcome = row.data.statusAndOutcome.outcome;
      const status = row.data.statusAndOutcome.status;
      if (!outcome || !status) {
        return <></>;
      }
      return <StatusCell outcome={outcome} status={status} />;
    },
  };

  const titleColumn: Header<ResourceUserRow> = {
    id: "title",
    label: "Title",
    width: 120,
    customCellRenderer: (row) => {
      return <Label label={row.title || "--"} truncateLength={18} oneLine />;
    },
  };

  const managerColumn: Header<ResourceUserRow> = {
    id: "manager",
    label: "Manager",
    width: 110,
    customCellRenderer: (row) => {
      const manager = row.data.user?.manager;
      if (!manager) {
        return "--";
      }
      return (
        <ResourceLabel
          text={manager.fullName}
          avatar={manager.avatarUrl}
          pointerCursor={true}
          entityId={manager.id}
          entityTypeNew={EntityType.User}
          target="_blank"
        />
      );
    },
  };

  const teamColumn: Header<ResourceUserRow> = {
    id: "team",
    label: "Team",
    width: 90,
    customCellRenderer: (row) => {
      return <Label label={row.team || "--"} oneLine />;
    },
  };

  const hrIdpStatusColumn: Header<ResourceUserRow> = {
    id: "hrIdpStatus",
    label: "HR IDP Status",
    width: 80,
    customCellRenderer: (row) => {
      return row.hrIdpStatus ? formatHrIdpStatus(row.hrIdpStatus) : "-—";
    },
  };

  let columns: Header<ResourceUserRow>[] = [
    userColumn,
    ...(resourceHasRoles ? [roleColumn] : []),
    titleColumn,
    managerColumn,
    teamColumn,
    hrIdpStatusColumn,
    expiresColumn,
    noteColumn,
    reviewerUsersColumn,
    outcomeColumn,
  ];
  if (showCompleted) {
    columns = [
      userColumn,
      ...(resourceHasRoles ? [roleColumn] : []),
      titleColumn,
      managerColumn,
      teamColumn,
      hrIdpStatusColumn,
      noteColumn,
      reviewerUsersColumn,
      outcomeCompletedColumn,
    ];
  }

  return (
    <div
      className={styles.tableContainer({
        multipleTabs: hasNHIs,
      })}
    >
      <div className={styles.topRow}>
        <div className={styles.controls}>
          <div className={styles.searchContainer}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Filter"
            />
          </div>
          <ButtonGroup
            buttons={[
              {
                label: "Todo",
                onClick: () => setShowCompleted(false),
                selected: !showCompleted,
              },
              {
                label: "Completed",
                onClick: () => setShowCompleted(true),
                selected: showCompleted,
              },
            ]}
          />
          {selectedResourceUserIds.length > 0 && (
            <div
              className={sprinkles({
                display: "flex",
                alignItems: "center",
                gap: "sm",
              })}
            >
              <div>
                {`You have selected ${
                  selectedResourceUserIds.length
                } ${pluralize("item", selectedResourceUserIds.length)}:`}
              </div>
              <DecisionButtons
                state={selectedBulkAction}
                resourceId={resource.id}
                onApproveClick={() => {
                  const actionToPerform =
                    selectedBulkAction === AccessReviewAction.Accept
                      ? AccessReviewAction.NoAction
                      : AccessReviewAction.Accept;
                  selectedResourceUserIds.forEach((ruID) => {
                    handleReviewAction(actionToPerform, ruID);
                  });
                  setSelectedBulkAction(actionToPerform);
                }}
                onRevokeClick={() => {
                  const actionToPerform =
                    selectedBulkAction === AccessReviewAction.Revoke
                      ? AccessReviewAction.NoAction
                      : AccessReviewAction.Revoke;
                  selectedResourceUserIds.forEach((ruID) => {
                    handleReviewAction(actionToPerform, ruID);
                  });
                  setSelectedBulkAction(actionToPerform);
                }}
                bulkAction
              />
              <NoteCell
                note={bulkUpdatedNote}
                accessReviewID={accessReviewID}
                targetIDs={selectedResourceUserIds}
                targetType={AccessReviewType.ResourceUser}
                onNoteChange={(newNote) => {
                  selectedResourceUserIds.forEach((resourceUserId) => {
                    const existingInfo = performReviewState.resourceUserActions.find(
                      (resourceUserAction) =>
                        resourceUserAction.accessReviewResourceUserId ===
                        resourceUserId
                    );

                    if (existingInfo) {
                      existingInfo.note = newNote;
                    } else {
                      performReviewState.resourceUserActions.push({
                        accessReviewResourceUserId: resourceUserId,
                        action: AccessReviewAction.NoAction,
                        note: newNote,
                      });
                    }
                    accessReviewDispatch({
                      type:
                        AccessReviewContextActionType.AccessReviewItemUpdate,
                      payload: {
                        performReviewStateByUARResourceId: {
                          ...accessReviewState.performReviewStateByUARResourceId,
                          [accessReviewResourceId]: performReviewState,
                        },
                      },
                    });
                    setBulkUpdatedNote(newNote);
                  });
                }}
              />
            </div>
          )}
        </div>

        <div className={styles.progressBarContainer}>
          <Icon name="check-circle" color="green600" size="xs" />
          <span
            className={sprinkles({
              fontSize: "bodyLg",
              color: "gray700",
            })}
          >{`${completedUsers.length}/${
            notStartedUsers.length + completedUsers.length
          }`}</span>
          <div className={styles.progressBar}>
            <ProgressBar
              size="md"
              percentProgress={
                (completedUsers.length /
                  (notStartedUsers.length + completedUsers.length)) *
                100
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.tableHeader}>{`${pluralize(
        "User",
        rows.length,
        true
      )} ${showCompleted ? "completed" : "to review"}`}</div>
      <Table
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(ru) => ru.id}
        columns={columns}
        emptyState={{
          title: showCompleted ? "No completed users" : "No users to review",
        }}
        onCheckedRowsChange={
          !showCompleted
            ? (checkedRowIds, checked) => {
                if (checked) {
                  setSelectedResourceUserIds((prev) => [
                    ...prev,
                    ...checkedRowIds,
                  ]);
                } else {
                  setSelectedResourceUserIds((prev) =>
                    prev.filter((id) => !checkedRowIds.includes(id))
                  );
                }
              }
            : undefined
        }
        checkedRowIds={new Set(selectedResourceUserIds)}
        selectAllChecked={selectedResourceUserIds.length === rows.length}
        onSelectAll={(checked) => {
          if (checked) {
            setSelectedResourceUserIds(rows.map((row) => row.id));
          } else {
            setSelectedResourceUserIds([]);
          }
        }}
      />
    </div>
  );
};

const AccessReviewResourceResources = ({
  accessReviewResource,
  accessReviewState,
  accessReviewDispatch,
  mode,
}: Props) => {
  const accessReviewID = accessReviewResource.accessReviewId;
  const { accessReviewResourceId } = useParams<Record<string, string>>();
  const { authState } = useContext(AuthContext);
  const [
    selectedResourceResourceIds,
    setSelectedResourceResourceIds,
  ] = useState<string[]>([]);

  const [
    selectedBulkAction,
    setSelectedBulkAction,
  ] = useState<AccessReviewAction>(AccessReviewAction.NoAction);
  const [bulkUpdatedNote, setBulkUpdatedNote] = useState<string>();

  const [searchQuery, setSearchQuery] = useState("");
  const resource = accessReviewResource?.resource;
  const viewingPrincipalsList = mode === "principalView";
  const performReviewState =
    accessReviewState.performReviewStateByUARResourceId[accessReviewResourceId];

  const noteByResourceResourceID: Record<string, string> = {};
  const completed = (accessReviewResource?.resourceResources ?? []).filter(
    (resourceResource) => {
      if (
        viewingPrincipalsList &&
        accessReviewResourceId !== resourceResource.accessReviewResourceEntityId
      ) {
        return false;
      } else if (
        !viewingPrincipalsList &&
        accessReviewResourceId !==
          resourceResource.accessReviewResourcePrincipalId
      ) {
        return false;
      }
      return resourceResource.reviewerUsers?.find((reviewer) => {
        if (reviewer.userId === authState.user?.user.id) {
          noteByResourceResourceID[resourceResource.id] = reviewer.note ?? "";
        }
        return (
          reviewer.userId === authState.user?.user.id &&
          reviewer.status !== ReviewerUserStatus.NotStarted
        );
      });
    }
  );
  const notStarted = (accessReviewResource?.resourceResources ?? []).filter(
    (resourceResource) => {
      if (
        viewingPrincipalsList &&
        accessReviewResourceId !== resourceResource.accessReviewResourceEntityId
      ) {
        return false;
      } else if (
        !viewingPrincipalsList &&
        accessReviewResourceId !==
          resourceResource.accessReviewResourcePrincipalId
      ) {
        return false;
      }
      return resourceResource.reviewerUsers?.find((reviewer) => {
        if (reviewer.userId === authState.user?.user.id) {
          noteByResourceResourceID[resourceResource.id] = reviewer.note ?? "";
        }
        return (
          reviewer.userId === authState.user?.user.id &&
          reviewer.status === ReviewerUserStatus.NotStarted
        );
      });
    }
  );

  const [showCompleted, setShowCompleted] = useState(
    notStarted.length === 0 && completed.length > 0
  );

  const filteredSearchResourceResources = filterSearchResults(
    showCompleted ? completed : notStarted,
    searchQuery,
    (resourceResource) => [
      resourceResource.resourceEntity?.name,
      resourceResource.resourcePrincipal?.name,
    ]
  );

  if (!performReviewState) {
    return null;
  }

  if (!resource) {
    return (
      <Column isContent maxWidth="lg">
        <UnexpectedErrorPage />
      </Column>
    );
  }

  const getResourceResourceReviewState = (resourceResourceId: string) => {
    return performReviewState.resourceResourceActions.find(
      (action) => action.accessReviewResourceResourceId === resourceResourceId
    )?.action;
  };

  const handleReviewAction = (
    state: AccessReviewAction,
    accessReviewResourceResourceId: string
  ) => {
    const existingInfo = performReviewState.resourceResourceActions.find(
      (resourceResourceAction) =>
        resourceResourceAction.accessReviewResourceResourceId ===
        accessReviewResourceResourceId
    );

    if (existingInfo) {
      existingInfo.action = state;
    } else {
      performReviewState.resourceResourceActions.push({
        accessReviewResourceResourceId: accessReviewResourceResourceId,
        action: state,
        isAccessReviewResourceActingAsPrincipal: !viewingPrincipalsList,
      });
    }
    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARResourceId: {
          ...accessReviewState.performReviewStateByUARResourceId,
          [accessReviewResourceId]: performReviewState,
        },
      },
    });
  };

  const handleBulkReviewAction = (state: AccessReviewAction) => {
    performReviewState.resourceResourceActions
      .filter((action) => {
        return (
          action.isAccessReviewResourceActingAsPrincipal ===
          !viewingPrincipalsList
        );
      })
      .forEach((resourceResourceAction) => {
        resourceResourceAction.action = state;
      });

    notStarted.forEach((resourceResource) => {
      const existingInfo = performReviewState.resourceResourceActions.find(
        (resourceResourceAction) =>
          resourceResourceAction.accessReviewResourceResourceId ===
          resourceResource.id
      );

      if (!existingInfo) {
        performReviewState.resourceResourceActions.push({
          accessReviewResourceResourceId: resourceResource.id,
          action: state,
          isAccessReviewResourceActingAsPrincipal: !viewingPrincipalsList,
        });
      }
    });

    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARResourceId: {
          ...accessReviewState.performReviewStateByUARResourceId,
          [accessReviewResourceId]: performReviewState,
        },
      },
    });
  };

  const actionByUarResourceResourceId: Record<
    string,
    ReviewResourceResourceAction
  > = {};
  performReviewState?.resourceResourceActions?.forEach(
    (resourceResourceAction) => {
      const id = resourceResourceAction.accessReviewResourceResourceId;
      actionByUarResourceResourceId[id] = resourceResourceAction;
    }
  );

  const rows: ResourceResourceRow[] = filteredSearchResourceResources.flatMap(
    (resourceResource) => {
      const resource = viewingPrincipalsList
        ? resourceResource.resourcePrincipal
        : resourceResource.resourceEntity;
      if (!resource) return [];

      const action = actionByUarResourceResourceId[resourceResource.id];
      const otherReviewers = (resourceResource.reviewerUsers ?? []).filter(
        (reviewer) => reviewer.userId !== authState.user?.user.id
      );

      return [
        {
          id: resourceResource.id,
          resource: resource,
          outcome: resourceResource.statusAndOutcome.outcome,
          role: resourceResource.accessLevel.accessLevelName || "--",
          note:
            action?.note ??
            noteByResourceResourceID[resourceResource.id] ??
            undefined,
          reviewerUsers: otherReviewers,
          data: resourceResource,
        },
      ];
    }
  );

  const resourceHasRoles = accessReviewResource?.resourceResources?.some(
    (resourceResource) => !!resourceResource.accessLevel.accessLevelRemoteId
  );

  const bulkState = calculateBatchAcceptRevokeState(
    performReviewState.resourceResourceActions.filter((action) => {
      return (
        action.isAccessReviewResourceActingAsPrincipal ===
        !viewingPrincipalsList
      );
    }),
    notStarted.length
  );

  const bulkDecisionHeader = (
    <DecisionButtons
      state={bulkState}
      resourceId={resource.id}
      onApproveClick={() =>
        handleBulkReviewAction(
          bulkState === AccessReviewAction.Accept
            ? AccessReviewAction.NoAction
            : AccessReviewAction.Accept
        )
      }
      onRevokeClick={() =>
        handleBulkReviewAction(
          bulkState === AccessReviewAction.Revoke
            ? AccessReviewAction.NoAction
            : AccessReviewAction.Revoke
        )
      }
      clearBackground
      bulkAction
    />
  );

  const resourceColumn: Header<ResourceResourceRow> = {
    id: "resource",
    label: viewingPrincipalsList ? "Non-Human Identity" : "Resource",
    width: 130,
    customCellRenderer: (row) => {
      return (
        <ResourceLabel
          text={row.resource.name}
          pointerCursor={true}
          entityId={row.resource.id}
          entityTypeNew={EntityType.Resource}
          resourceType={row.resource.resourceType}
          target="_blank"
        />
      );
    },
  };

  const expiresColumn: Header<ResourceResourceRow> = {
    id: "expires",
    label: "Expires",
    width: 75,
    customCellRenderer: (row) => {
      const expirationString =
        row.data.resourceResourceAccess?.latestExpiringAccessPoint?.expiration;
      if (!expirationString) {
        return <>Never</>;
      }
      const expires = moment(expirationString).fromNow();
      return <Label label={expires} oneLine />;
    },
  };

  const roleColumn: Header<ResourceResourceRow> = {
    id: "role",
    label: "Role",
    width: 180,
    customCellRenderer: (row) => {
      return (
        <span
          className={sprinkles({
            color: row.updatedRole ? "blue600" : "black",
          })}
        >
          <Label label={row.updatedRole ?? row.role} oneLine />
        </span>
      );
    },
  };

  const outcomeColumn: Header<ResourceResourceRow> = {
    id: "outcome",
    label: "",
    sortable: false,
    customHeader: rows.length > 0 ? bulkDecisionHeader : <></>,
    width: resourceHasRoles ? 200 : 140,
    customCellRenderer: (row) => {
      const state = getResourceResourceReviewState(row.id);
      return (
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
          })}
        >
          <DecisionButtons
            state={state}
            resourceId={resource.id}
            onApproveClick={() =>
              handleReviewAction(
                state === AccessReviewAction.Accept
                  ? AccessReviewAction.NoAction
                  : AccessReviewAction.Accept,
                row.id
              )
            }
            onRevokeClick={() =>
              handleReviewAction(
                state === AccessReviewAction.Revoke
                  ? AccessReviewAction.NoAction
                  : AccessReviewAction.Revoke,
                row.id
              )
            }
          />
          <NoteCell
            note={row.note}
            accessReviewID={accessReviewID}
            targetIDs={[row.id]}
            targetType={AccessReviewType.ResourceResource}
            onNoteChange={(updatedNoteContent) => {
              const resourceResource = row.data;
              if (!performReviewState) {
                return;
              }
              const existingInfo = performReviewState.resourceResourceActions.find(
                (resourceResourceAction) =>
                  resourceResourceAction.accessReviewResourceResourceId ===
                  resourceResource.id
              );
              if (existingInfo) {
                existingInfo.note = updatedNoteContent;
              } else {
                performReviewState.resourceResourceActions.push({
                  accessReviewResourceResourceId: resourceResource.id,
                  action: AccessReviewAction.NoAction,
                  note: updatedNoteContent,
                  isAccessReviewResourceActingAsPrincipal: !viewingPrincipalsList,
                });
              }
              accessReviewDispatch({
                type: AccessReviewContextActionType.AccessReviewItemUpdate,
                payload: {
                  performReviewStateByUARResourceId: {
                    ...accessReviewState.performReviewStateByUARResourceId,
                    [accessReviewResourceId]: performReviewState,
                  },
                },
              });
            }}
          />
        </div>
      );
    },
  };

  const noteColumn: Header<ResourceResourceRow> = {
    id: "note",
    label: "",
    width: 24,
    customCellRenderer: (row) => {
      if (!row.note) return <></>;
      return (
        <Tooltip tooltipText={row.note} placement={TooltipPlacement.Top}>
          <Icon name="message" color="blue700V3" />
        </Tooltip>
      );
    },
  };

  const reviewerUsersColumn: Header<ResourceResourceRow> = {
    id: "reviewerUsers",
    label: "",
    width: 24,
    customCellRenderer: (row) => {
      if (!row.reviewerUsers || row.reviewerUsers.length == 0) {
        return <></>;
      }

      return <ReviewerUsers reviewerUsers={row.reviewerUsers} />;
    },
  };

  const outcomeCompletedColumn: Header<ResourceResourceRow> = {
    id: "outcome", // Only shown in the completed view
    label: "Status",
    sortable: false,
    width: 80,
    customCellRenderer: (row) => {
      const outcome = row.data.statusAndOutcome.outcome;
      const status = row.data.statusAndOutcome.status;
      if (!outcome || !status) {
        return <></>;
      }
      return <StatusCell outcome={outcome} status={status} />;
    },
  };

  let columns: Header<ResourceResourceRow>[] = [
    resourceColumn,
    ...(resourceHasRoles ? [roleColumn] : []),
    expiresColumn,
    noteColumn,
    reviewerUsersColumn,
    outcomeColumn,
  ];
  if (showCompleted) {
    columns = [
      resourceColumn,
      ...(resourceHasRoles ? [roleColumn] : []),
      noteColumn,
      reviewerUsersColumn,
      outcomeCompletedColumn,
    ];
  }

  return (
    <div
      className={styles.tableContainer({
        multipleTabs: true,
      })}
    >
      <div className={styles.topRow}>
        <div className={styles.controls}>
          <div className={styles.searchContainer}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Filter"
            />
          </div>
          <ButtonGroup
            buttons={[
              {
                label: "Todo",
                onClick: () => setShowCompleted(false),
                selected: !showCompleted,
              },
              {
                label: "Completed",
                onClick: () => setShowCompleted(true),
                selected: showCompleted,
              },
            ]}
          />
          {selectedResourceResourceIds.length > 0 && (
            <div
              className={sprinkles({
                display: "flex",
                alignItems: "center",
                gap: "sm",
              })}
            >
              <div>
                {`You have selected ${
                  selectedResourceResourceIds.length
                } ${pluralize("item", selectedResourceResourceIds.length)}:`}
              </div>
              <DecisionButtons
                state={undefined}
                resourceId={resource.id}
                onApproveClick={() => {
                  const actionToPerform =
                    selectedBulkAction === AccessReviewAction.Accept
                      ? AccessReviewAction.NoAction
                      : AccessReviewAction.Accept;
                  selectedResourceResourceIds.forEach((rrID) => {
                    handleReviewAction(actionToPerform, rrID);
                  });
                  setSelectedBulkAction(actionToPerform);
                }}
                onRevokeClick={() => {
                  const actionToPerform =
                    selectedBulkAction === AccessReviewAction.Revoke
                      ? AccessReviewAction.NoAction
                      : AccessReviewAction.Revoke;
                  selectedResourceResourceIds.forEach((rrID) => {
                    handleReviewAction(actionToPerform, rrID);
                  });
                  setSelectedBulkAction(actionToPerform);
                }}
              />
              <NoteCell
                note={bulkUpdatedNote}
                accessReviewID={accessReviewID}
                targetIDs={selectedResourceResourceIds}
                targetType={AccessReviewType.ResourceResource}
                onNoteChange={(newNote) => {
                  selectedResourceResourceIds.forEach((resourceResourceId) => {
                    const existingInfo = performReviewState.resourceResourceActions.find(
                      (resourceResourceAction) =>
                        resourceResourceAction.accessReviewResourceResourceId ===
                        resourceResourceId
                    );

                    if (existingInfo) {
                      existingInfo.note = newNote;
                    } else {
                      performReviewState.resourceResourceActions.push({
                        accessReviewResourceResourceId: resourceResourceId,
                        action: AccessReviewAction.NoAction,
                        note: newNote,
                        isAccessReviewResourceActingAsPrincipal: !viewingPrincipalsList,
                      });
                    }
                    accessReviewDispatch({
                      type:
                        AccessReviewContextActionType.AccessReviewItemUpdate,
                      payload: {
                        performReviewStateByUARResourceId: {
                          ...accessReviewState.performReviewStateByUARResourceId,
                          [accessReviewResourceId]: performReviewState,
                        },
                      },
                    });
                    setBulkUpdatedNote(newNote);
                  });
                }}
              />
            </div>
          )}
        </div>

        <div className={styles.progressBarContainer}>
          <Icon name="check-circle" color="green600" size="xs" />
          <span
            className={sprinkles({
              fontSize: "bodyLg",
              color: "gray700",
            })}
          >{`${completed.length}/${
            notStarted.length + completed.length
          }`}</span>
          <div className={styles.progressBar}>
            <ProgressBar
              size="md"
              percentProgress={
                (completed.length / (notStarted.length + completed.length)) *
                100
              }
            />
          </div>
        </div>
      </div>
      <div className={styles.tableHeader}>{`${pluralize(
        viewingPrincipalsList ? "Non-Human Identity" : "Resource",
        rows.length,
        true
      )} ${showCompleted ? "completed" : "to review"}`}</div>
      <Table
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(ru) => ru.id}
        columns={columns}
        emptyState={{
          title: showCompleted
            ? "No completed resources"
            : "No resources to review",
        }}
        onCheckedRowsChange={
          !showCompleted
            ? (checkedRowIds, checked) => {
                if (checked) {
                  setSelectedResourceResourceIds((prev) => [
                    ...prev,
                    ...checkedRowIds,
                  ]);
                } else {
                  setSelectedResourceResourceIds((prev) =>
                    prev.filter((id) => !checkedRowIds.includes(id))
                  );
                }
              }
            : undefined
        }
        checkedRowIds={new Set(selectedResourceResourceIds)}
        selectAllChecked={selectedResourceResourceIds.length === rows.length}
        onSelectAll={(checked) => {
          if (checked) {
            setSelectedResourceResourceIds(rows.map((row) => row.id));
          } else {
            setSelectedResourceResourceIds([]);
          }
        }}
      />
    </div>
  );
};

export default AccessReviewResourceV3;
