import {
  GroupType,
  ResourceType,
  useGroupTypesWithCountsQuery,
  useResourceTypesWithCountsQuery,
} from "api/generated/graphql";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import {
  DataElement,
  DataElementList,
  FormGroup,
  Modal,
  Select,
  Skeleton,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import React, { useState } from "react";
import { logError } from "utils/logging";
import { by } from "utils/search/sort";

interface Props {
  resourceTypes: ResourceType[];
  groupTypes: GroupType[];
  onChangeResourceTypes?: (resourceTypes: ResourceType[]) => void;
  onChangeGroupTypes?: (groupTypes: GroupType[]) => void;
}

const EntityTypesFilter: React.FC<Props> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalResourceTypes, setModalResourceTypes] = useState<ResourceType[]>(
    []
  );
  const [modalGroupTypes, setModalGroupTypes] = useState<GroupType[]>([]);

  const {
    data: resourceTypesData,
    error: resourceTypesError,
    loading: resourceTypesLoading,
  } = useResourceTypesWithCountsQuery({ variables: { input: {} } });
  const {
    data: groupTypesData,
    error: groupTypesError,
    loading: groupTypesLoading,
  } = useGroupTypesWithCountsQuery({ variables: { input: {} } });

  if (resourceTypesError) {
    logError(resourceTypesError, `failed to list resourceTypes`);
  }
  if (groupTypesError) {
    logError(groupTypesError, "failed to list groupTypes");
  }
  if (resourceTypesLoading || groupTypesLoading) {
    return <Skeleton />;
  }

  const allResourceTypes =
    resourceTypesData?.resourceTypesWithCounts.resourceTypesWithCounts ?? [];
  const allGroupTypes =
    groupTypesData?.groupTypesWithCounts.groupTypesWithCounts ?? [];

  const unselectedResourceTypes = _.uniqBy(
    allResourceTypes.filter(
      ({ resourceType }) => !modalResourceTypes.includes(resourceType)
    ),
    (typeCount) => typeCount.resourceType
  ).sort(by((typeCount) => typeCount.resourceType));

  const unselectedGroupTypes = _.uniqBy(
    allGroupTypes.filter(
      ({ groupType }) => !modalGroupTypes.includes(groupType)
    ),
    (typeCount) => typeCount.groupType
  ).sort(by((typeCount) => typeCount.groupType));

  const handleOpenModal = () => {
    setShowModal(true);
    setModalResourceTypes(props.resourceTypes);
    setModalGroupTypes(props.groupTypes);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    if (props.onChangeResourceTypes) {
      props.onChangeResourceTypes([...modalResourceTypes]);
    }
    if (props.onChangeGroupTypes) {
      props.onChangeGroupTypes([...modalGroupTypes]);
    }
    setShowModal(false);
  };

  if (!props.onChangeGroupTypes && !props.onChangeResourceTypes) {
    if (props.resourceTypes.length + props.groupTypes.length === 0) {
      return null;
    }
    return (
      <FormGroup
        label="Entities with type"
        fontWeight="normal"
        fontSize="textMd"
      >
        <DataElementList>
          {props.resourceTypes.map((resourceType) => (
            <DataElement
              label={getResourceTypeInfo(resourceType)?.fullName ?? "--"}
              color="pink"
              leftIcon={{ data: { entityType: resourceType, type: "entity" } }}
            />
          ))}
          {props.groupTypes.map((groupType) => (
            <DataElement
              label={getGroupTypeInfo(groupType)?.name ?? "--"}
              color="pink"
              leftIcon={{ data: { entityType: groupType, type: "entity" } }}
            />
          ))}
        </DataElementList>
      </FormGroup>
    );
  }

  return (
    <>
      <FormGroup
        label="Entities with type"
        fontWeight="normal"
        fontSize="textMd"
      >
        <div className={sprinkles({ marginBottom: "sm" })}>
          Add items to the access review that are of the specified types
        </div>
        <DataElementList>
          {props.resourceTypes.map((resourceType) => (
            <DataElement
              label={getResourceTypeInfo(resourceType)?.fullName ?? "--"}
              color="pink"
              leftIcon={{ data: { entityType: resourceType, type: "entity" } }}
              rightIcon={{
                name: "x",
                onClick: () => {
                  props.onChangeResourceTypes &&
                    props.onChangeResourceTypes(
                      props.resourceTypes.filter((rt) => rt !== resourceType)
                    );
                },
              }}
            />
          ))}
          {props.groupTypes.map((groupType) => (
            <DataElement
              label={getGroupTypeInfo(groupType)?.name ?? "--"}
              color="pink"
              leftIcon={{ data: { entityType: groupType, type: "entity" } }}
              rightIcon={{
                name: "x",
                onClick: () => {
                  props.onChangeGroupTypes &&
                    props.onChangeGroupTypes(
                      props.groupTypes.filter((gt) => gt !== groupType)
                    );
                },
              }}
            />
          ))}
          <DataElement
            label="Add entity type"
            color="blue"
            leftIcon={{
              name: "plus",
            }}
            onClick={handleOpenModal}
          />
        </DataElementList>
      </FormGroup>
      <Modal
        isOpen={showModal}
        onClose={handleCancel}
        title="Include entities with type"
      >
        <Modal.Body>
          <p>
            This access review will include resources and groups of the
            following types. If no types are selected, the access review will
            only include items matching the other provided scopes.
          </p>
          <FormGroup label="Resource types">
            <Select
              options={unselectedResourceTypes}
              selectOnly
              getOptionLabel={(option) =>
                getResourceTypeInfo(option.resourceType)?.fullName || "--"
              }
              getIcon={(option) => ({
                type: "entity",
                entityType: option.resourceType,
              })}
              onChange={(resourceType) => {
                if (resourceType) {
                  setModalResourceTypes([
                    ...modalResourceTypes,
                    resourceType.resourceType,
                  ]);
                }
              }}
              placeholder="Select a resource type"
            />
            <div className={sprinkles({ marginTop: "lg" })}>
              <DataElementList>
                {modalResourceTypes.map((resourceType) => (
                  <DataElement
                    label={getResourceTypeInfo(resourceType)?.fullName ?? "--"}
                    color="pink"
                    leftIcon={{
                      data: { entityType: resourceType, type: "entity" },
                    }}
                    rightIcon={{
                      name: "x",
                      onClick: () => {
                        setModalResourceTypes(
                          modalResourceTypes.filter((rt) => rt !== resourceType)
                        );
                      },
                    }}
                  />
                ))}
              </DataElementList>
            </div>
          </FormGroup>
          <FormGroup label="Group types">
            <Select
              options={unselectedGroupTypes}
              selectOnly
              getOptionLabel={(option) =>
                getGroupTypeInfo(option.groupType)?.name || "--"
              }
              getIcon={(option) => ({
                type: "entity",
                entityType: option.groupType,
              })}
              onChange={(groupType) => {
                if (groupType) {
                  setModalGroupTypes([...modalGroupTypes, groupType.groupType]);
                }
              }}
              placeholder="Select a group type"
            />
          </FormGroup>
          <DataElementList>
            {modalGroupTypes.map((groupType) => (
              <DataElement
                label={getGroupTypeInfo(groupType)?.name ?? "--"}
                color="pink"
                leftIcon={{ data: { entityType: groupType, type: "entity" } }}
                rightIcon={{
                  name: "x",
                  onClick: () => {
                    setModalGroupTypes(
                      modalGroupTypes.filter((gt) => gt !== groupType)
                    );
                  },
                }}
              />
            ))}
          </DataElementList>
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Save"
          onPrimaryButtonClick={handleSave}
          secondaryButtonLabel="Cancel"
          onSecondaryButtonClick={handleCancel}
        />
      </Modal>
    </>
  );
};

export default EntityTypesFilter;
