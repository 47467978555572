import {
  EntityDisplayInfoFragment,
  EntityIdTupleFragment,
  EntityType,
  EventType,
  PaginatedSubEventsOutput,
  SubEventFragment,
  useEventDetailQuery,
} from "api/generated/graphql";
import UserLabel from "components/label/item_labels/UserLabel";
import {
  ResourceLabel,
  SupportTicketLabel,
  SupportTicketLabelViaHTTP,
  TooltipPlacement,
} from "components/label/Label";
import { getOncallScheduleIcon } from "components/label/OnCallScheduleLabel";
import { ContentPill, PillV3 } from "components/pills/PillsV3";
import {
  ActivityIcon,
  AddIcon,
  ApprovedIcon,
  CanceledIcon,
  DeniedIcon,
  EditIcon,
  FailedIcon,
  MinusIcon,
  PendingIcon,
} from "components/requests/RequestIcons";
import { Label, Masonry, Tooltip } from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import _ from "lodash";
import React from "react";
import * as Icons from "react-feather";
import { getResourceUrlNew } from "utils/common";
import { eventTaskTriggerToString, subEventTypeToString } from "utils/events";
import { NotFoundPage } from "views/error/ErrorCodePage";
import styles from "views/events/viewer/SubEventsTable.module.scss";
import { NotificationDeliveryMethodsV3 } from "views/requests/RequestEventsTable";

export type SubEventsTableProps = {
  id: string;
};

export const SubEventsTableV3 = (props: SubEventsTableProps) => {
  const { data, error, loading, fetchMore } = useEventDetailQuery({
    variables: {
      id: props.id,
      subEvents: {
        // TODO: eventually figure out sorting -- columns are so random right
        // now that having a proper backend sorting functionality is
        // impossible.
      },
    },
  });

  const event =
    data?.event.__typename === "EventResult" ? data.event.event : null;
  const subEvents: PaginatedSubEventsOutput = event?.paginatedSubEvents ?? {
    subEvents: [],
    totalNumSubEvents: event?.numSubEvents ?? 0,
  };

  if (error || data?.event.__typename === "EventNotFoundError") {
    return <NotFoundPage />;
  }

  const cursor = event?.paginatedSubEvents.cursor ?? null;
  const loadMoreRows =
    cursor && !loading
      ? async () => {
          await fetchMore({
            variables: {
              id: props.id,
              subEvents: {
                cursor,
              },
            },
          });
        }
      : undefined;

  return (
    <Masonry
      loadingItems={loading}
      onLoadMoreItems={loadMoreRows}
      totalNumItems={subEvents.totalNumSubEvents}
      items={subEvents.subEvents}
      getItemKey={(subEvent) => subEvent.id}
      // Only have one column
      breakpointCols={1}
      renderItem={(subEvent) => <SubEventDisplay subEvent={subEvent} />}
      allowHorizontalScroll
    />
  );
};

const getSubEventObject = (
  subEventObjectName?: string,
  objectId?: EntityIdTupleFragment,
  object?: EntityDisplayInfoFragment
) => {
  if (!objectId || !objectId.entityType) return null;

  const objectName = subEventObjectName || objectId?.entityId;

  switch (objectId?.entityType) {
    case EntityType.User:
      return (
        <Label
          label={objectName}
          linkTo={getResourceUrlNew(objectId)}
          icon={{
            type: "src",
            icon: object?.avatarURL || defaultAvatarURL,
            fallbackIcon: defaultAvatarURL,
            style: "rounded",
          }}
        />
      );
    case EntityType.Resource:
      return (
        <Label
          label={objectName}
          linkTo={getResourceUrlNew(objectId)}
          icon={
            object?.resourceType
              ? { type: "entity", entityType: object?.resourceType }
              : undefined
          }
        />
      );
    case EntityType.Group:
      return (
        <Label
          label={objectName}
          linkTo={getResourceUrlNew(objectId)}
          icon={
            object?.groupType
              ? { type: "entity", entityType: object?.groupType }
              : undefined
          }
        />
      );
    case EntityType.Connection:
      return (
        <Label
          label={objectName}
          linkTo={getResourceUrlNew(objectId)}
          icon={
            object?.connectionType
              ? { type: "entity", entityType: object?.connectionType }
              : undefined
          }
        />
      );
    case EntityType.Owner:
      return (
        <Label
          label={objectName}
          linkTo={getResourceUrlNew(objectId)}
          icon={{ type: "name", icon: "user-square" }}
        />
      );
    case EntityType.Request:
      return (
        <Label
          label="Access request"
          linkTo={getResourceUrlNew(objectId)}
          icon={{ type: "name", icon: "inbox" }}
        />
      );
    case EntityType.AccessReview:
    case EntityType.AccessReviewTemplate:
      return (
        <Label
          label={objectName}
          linkTo={getResourceUrlNew(objectId)}
          icon={{ type: "name", icon: "check-circle" }}
        />
      );
    case EntityType.MessageChannel:
      return (
        <Label label={objectName} icon={{ type: "name", icon: "slack" }} />
      );
    case EntityType.OnCallSchedule:
      return (
        <Label
          label={objectName}
          icon={{
            type: "src",
            icon: object?.provider
              ? getOncallScheduleIcon(object?.provider)
              : undefined,
          }}
        />
      );
    case EntityType.Session:
      return <div>Session</div>;
    case EntityType.SupportTicket:
      return <SupportTicketLabelViaHTTP supportTicketId={objectId.entityId} />;
    case EntityType.OrgSetting:
    case EntityType.ReviewerStage:
    case EntityType.IdpConnection:
      return null;
    case EntityType.ConfigurationTemplate:
      if (!subEventObjectName) {
        return null;
      }
      return (
        <Label
          label={objectName}
          linkTo={getResourceUrlNew(objectId)}
          icon={{ type: "name", icon: "template" }}
        />
      );
    case EntityType.GroupBinding:
      return (
        <Label
          label={objectName}
          linkTo={getResourceUrlNew(objectId)}
          icon={{ type: "name", icon: "link" }}
        />
      );
    case EntityType.EventStreamConnection:
      return (
        <Label
          label={objectName}
          linkTo={getResourceUrlNew({
            // Explicitly not passing subEvent.objectId here because we currently don't show different
            // UX based on the eventStreamID (aka subEvent.objectId)
            entityId: null,
            entityType: EntityType.EventStreamConnection,
          })}
          icon={{ type: "name", icon: "events" }}
        />
      );
    default:
      return <Label label={objectName} />;
  }
};

const SubEventDisplay = ({ subEvent }: { subEvent: SubEventFragment }) => {
  let icon,
    actionText,
    actionContent,
    metadataContent = null;

  icon = subEvent.subEventType ? (
    eventTypeToIcon(subEvent.subEventType)
  ) : (
    <ActivityIcon />
  );
  actionText = subEvent.subEventType
    ? subEventTypeToString(subEvent.subEventType)
    : "--";

  let objectContent =
    getSubEventObject(
      subEvent.objectName ?? undefined,
      subEvent.objectId,
      subEvent.object ?? undefined
    ) || "--";

  actionContent = <div className={styles.actionContent}>{actionText}</div>;

  let secondaryObject =
    getSubEventObject(
      subEvent.secondaryObjectName ?? undefined,
      subEvent.secondaryObjectId ?? undefined,
      subEvent.secondaryObject ?? undefined
    ) || "--";

  let tertiaryObject = getSubEventObject(
    subEvent.tertiaryObjectName ?? undefined,
    subEvent.tertiaryObjectId ?? undefined,
    subEvent.tertiaryObject ?? undefined
  );

  let object4 = getSubEventObject(
    subEvent.object4Name ?? undefined,
    subEvent.object4Id ?? undefined,
    subEvent.object4 ?? undefined
  );

  if (subEvent.metadata) {
    let parsedJson = JSON.parse(subEvent.metadata);
    let sameString = false;
    let oldValue, newValue;

    switch (subEvent.subEventType) {
      case EventType.UserNotified:
        metadataContent = (
          <div style={{ paddingLeft: "4px" }}>
            <NotificationDeliveryMethodsV3 subEvents={[subEvent]} />
          </div>
        );
        break;
      case EventType.IdpConnectionUserAttributeMappingCreated:
        oldValue = "--";
        newValue = parsedJson
          ? `${parsedJson.idp_user_attribute_mapping_change.key} mapped to ${parsedJson.idp_user_attribute_mapping_change.use_as}`
          : "--";
        secondaryObject = (
          <FieldChangedPillLabel oldValue={oldValue} newValue={newValue} />
        );
        break;
      case EventType.IdpConnectionUserAttributeMappingDeleted:
        newValue = "--";
        oldValue = parsedJson
          ? `${parsedJson.idp_user_attribute_mapping_change.key} mapped to ${parsedJson.idp_user_attribute_mapping_change.use_as}`
          : "--";
        secondaryObject = (
          <FieldChangedPillLabel oldValue={oldValue} newValue={newValue} />
        );
        break;
      case EventType.ApiTokenCreated:
      case EventType.ApiTokenDeleted:
        metadataContent = (
          <PillV3
            keyText={parsedJson.api_token_name}
            tooltipText={parsedJson.api_token_preview}
          />
        );
        break;
      case EventType.UserEmailUpdated:
      case EventType.UserNameUpdated:
      case EventType.UserPositionUpdated:
      case EventType.UserTeamAttrUpdated:
      case EventType.UserRemoteIdUpdated: {
        let { oldValue, newValue } = getUserUpdatedValues(parsedJson);
        if (
          subEvent.subEventType === EventType.UserRemoteIdUpdated ||
          parsedJson.user_updated.user_field_name === "SecondaryEmails"
        ) {
          oldValue = `${parsedJson.user_updated.user_field_name}: ${oldValue}`;
          newValue = `${parsedJson.user_updated.user_field_name}: ${newValue}`;
        }
        secondaryObject = (
          <FieldChangedPillLabel oldValue={oldValue} newValue={newValue} />
        );
        break;
      }
      case EventType.UserMerged: {
        let { oldValue, newValue } = getUserUpdatedValues(parsedJson);
        metadataContent = (
          <FieldChangedPillLabel oldValue={oldValue} newValue={newValue} />
        );
        break;
      }
      case EventType.UserManagerUpdated: {
        const oldManagerId = parsedJson.user_updated.old_user_field_value;
        const hasOldManager = !!oldManagerId;
        const oldManagerName = parsedJson.user_updated.old_manager?.full_name;
        const oldManagerAvatarURL =
          parsedJson.user_updated.old_manager?.avatar_url;

        const newManagerId = parsedJson.user_updated.new_user_field_value;
        const hasNewManager = !!newManagerId;
        const newManagerName = parsedJson.user_updated.new_manager?.full_name;
        const newManagerAvatarURL =
          parsedJson.user_updated.new_manager?.avatar_url;
        secondaryObject = (
          <div className={styles.secondaryObjectContent}>
            <ContentPill pillColor="Red">
              {hasOldManager ? (
                <UserLabel
                  name={oldManagerName}
                  avatar={oldManagerAvatarURL}
                  url={getResourceUrlNew({
                    entityId: oldManagerId,
                    entityType: EntityType.User,
                  })}
                />
              ) : (
                "--"
              )}
            </ContentPill>
            <div className={styles.arrowContainer}>
              <Icons.ArrowRight strokeWidth={2} size={16} />
            </div>
            <ContentPill pillColor="LightGreen">
              {hasNewManager ? (
                <UserLabel
                  name={newManagerName}
                  avatar={newManagerAvatarURL}
                  url={getResourceUrlNew({
                    entityId: newManagerId,
                    entityType: EntityType.User,
                  })}
                />
              ) : (
                "--"
              )}
            </ContentPill>
          </div>
        );
        metadataContent = parsedJson.task_trigger && (
          <PillV3
            keyText={eventTaskTriggerToString(parsedJson.task_trigger)}
            maxChars={200}
          />
        );
        break;
      }
      case EventType.OrgSettingsUpdated:
        objectContent = (
          <ResourceLabel
            text={_.camelCase(parsedJson.org_setting.org_setting_name)}
            entityTypeNew={subEvent.objectId.entityType}
            entityId={subEvent.objectId.entityId}
            additionalStyleClass={styles.objectText}
          />
        );
        oldValue = parsedJson.org_setting.old_org_setting_value
          ? parsedJson.org_setting.old_org_setting_value
          : "Disabled";
        newValue = parsedJson.org_setting.new_org_setting_value
          ? parsedJson.org_setting.new_org_setting_value
          : "Disabled";
        secondaryObject = (
          <FieldChangedPillLabel oldValue={oldValue} newValue={newValue} />
        );
        break;
      case EventType.ResourceRequireSupportTicketUpdated:
      case EventType.ResourceAutoApprovalUpdated:
      case EventType.ResourceIsRequestableUpdated:
      case EventType.ResourceMaxDurationUpdated:
      case EventType.ResourceRecommendedDurationUpdated:
      case EventType.ResourceRequestRequireMfaUpdated:
      case EventType.GroupRequireSupportTicketUpdated:
      case EventType.GroupAutoApprovalUpdated:
      case EventType.GroupIsRequestableUpdated:
      case EventType.GroupMaxDurationUpdated:
      case EventType.GroupRecommendedDurationUpdated:
      case EventType.GroupRequestRequireMfaUpdated:
        if (parsedJson.request_config_setting_change) {
          object4 = (
            <code>{parsedJson.request_config_setting_change.condition}</code>
          );
          oldValue = parsedJson.request_config_setting_change.old_value ?? "--";
          newValue = parsedJson.request_config_setting_change.new_value ?? "--";
          secondaryObject = (
            <FieldChangedPillLabel oldValue={oldValue} newValue={newValue} />
          );
        }
        break;
      case EventType.RequestSupportTicketAdded: {
        const supportTicket = parsedJson.support_ticket;
        metadataContent = (
          <SupportTicketLabel
            identifier={supportTicket.identifier || supportTicket.id}
            thirdPartyProvider={supportTicket.provider}
            url={supportTicket.url}
          />
        );
        break;
      }
      case EventType.PropagationSuccessAddUserToGroup:
      case EventType.PropagationSuccessAddUserToResource:
      case EventType.PropagationSuccessAddResourceToGroup:
      case EventType.PropagationSuccessRemoveUserFromGroup:
      case EventType.PropagationSuccessRemoveUserFromResource:
      case EventType.PropagationSuccessRemoveResourceFromGroup:
      case EventType.PropagationSuccessRemoveUserFromConnection:
      case EventType.PropagationTicketUpdatedRemotely:
      case EventType.PropagationManualAddUserToResource:
      case EventType.PropagationManualRemoveUserFromResource: {
        // Show nothing
        break;
      }
      case EventType.PropagationFailureAddUserToGroup:
      case EventType.PropagationFailureAddUserToResource:
      case EventType.PropagationFailureAddResourceToGroup:
      case EventType.PropagationFailureRemoveUserFromGroup:
      case EventType.PropagationFailureRemoveUserFromResource:
      case EventType.PropagationFailureRemoveResourceFromGroup:
      case EventType.PropagationFailureRemoveUserFromConnection: {
        metadataContent = parsedJson.error_message && (
          <Tooltip
            tooltipText={parsedJson.error_message}
            placement={TooltipPlacement.Top}
          >
            <PillV3 keyText="ERROR" pillColor="Red" />
          </Tooltip>
        );
        break;
      }
      case EventType.ImpersonationSessionStopped: {
        if (parsedJson.impersonation_session_stopped_reason) {
          let reasonStr;
          switch (parsedJson.impersonation_session_stopped_reason) {
            case "LOGOUT":
              reasonStr = "Reason: impersonating user logged out";
              break;
            case "EXPIRED":
              reasonStr = "Reason: session expired";
              break;
            case "CUSTOMER_SUPPORT_DISABLED":
              reasonStr = "Reason: Customer Support disabled";
              break;
            default:
              reasonStr = `Reason: ${parsedJson.impersonation_session_stopped_reason}`;
              break;
          }
          metadataContent = (
            <ContentPill>
              <div>{reasonStr}</div>
            </ContentPill>
          );
        }
        break;
      }
      default: {
        if (parsedJson.access_level_name && parsedJson.access_level_remote_id) {
          sameString =
            parsedJson.access_level_name === parsedJson.access_level_remote_id;
        }
        metadataContent = (
          <>
            {parsedJson.access_level_name && !sameString && (
              <PillV3
                keyText={parsedJson.access_level_name}
                maxChars={20}
                tooltipText={
                  parsedJson.access_level_name.length > 15
                    ? parsedJson.access_level_name
                    : null
                }
                clickToCopy
              />
            )}
            {parsedJson.access_level_remote_id && (
              <PillV3
                keyText={parsedJson.access_level_remote_id}
                maxChars={20}
                clickToCopy
              />
            )}
            {parsedJson.task_trigger && (
              <PillV3
                keyText={eventTaskTriggerToString(parsedJson.task_trigger)}
                maxChars={200}
              />
            )}
            {parsedJson.session_remote_id && (
              <PillV3
                keyText={parsedJson.session_remote_id}
                maxChars={20}
                clickToCopy
              />
            )}
          </>
        );
      }
    }
  }

  return (
    <div className={styles.eventRowV3}>
      <div className={styles.iconCol}>{icon}</div>
      <div className={styles.objectCol}>{objectContent}</div>
      <div className={styles.actionCol}>{actionContent}</div>
      <div className={styles.secondaryObjectCol}>{secondaryObject}</div>
      {tertiaryObject != null && (
        <div className={styles.tertiaryObjectCol}>{tertiaryObject}</div>
      )}
      {object4 != null && <div className={styles.object4Col}>{object4}</div>}
      <div className={styles.metadataCol}>
        <div className={styles.metadataContainer}>{metadataContent}</div>
      </div>
    </div>
  );
};

const getUserUpdatedValues = (parsedJson: {
  user_updated: {
    old_user_field_value: string;
    new_user_field_value: string;
  };
}) => {
  const oldValue = parsedJson.user_updated.old_user_field_value
    ? parsedJson.user_updated.old_user_field_value
    : "--";
  const newValue = parsedJson.user_updated.new_user_field_value
    ? parsedJson.user_updated.new_user_field_value
    : "--";
  return { oldValue, newValue };
};

export const eventTypeToIcon = (eventType: EventType) => {
  let eventIcon = <ActivityIcon />;
  switch (eventType) {
    case EventType.UsersDeleted:
    case EventType.GroupsDeleted:
    case EventType.ResourcesDeleted:
    case EventType.GroupFoldersDeleted:
    case EventType.GroupsRemovedFromFolders:
    case EventType.UsersRemovedFromGroups:
    case EventType.UsersRemovedFromResources:
    case EventType.GroupsRemovedFromGroups:
    case EventType.ResourcesRemovedFromGroups:
    case EventType.ResourcesRemovedFromFolders:
    case EventType.ConnectionsDeleted:
    case EventType.IdpConnectionsDeleted:
    case EventType.ResourceFoldersDeleted:
    case EventType.ReviewersRemovedFromResources:
    case EventType.ReviewersRemovedFromGroups:
    case EventType.OnCallSchedulesDeleted:
    case EventType.ThirdPartyIntegrationDeleted:
    case EventType.ApiTokenDeleted:
    case EventType.MessageChannelsRemovedFromResources:
    case EventType.MessageChannelsRemovedFromGroups:
      eventIcon = <FailedIcon large={true} />;
      break;
    case EventType.GroupsAddedToFolders:
    case EventType.GroupsAddedToGroups:
    case EventType.ResourcesAddedToGroups:
    case EventType.ResourcesAddedToFolders:
    case EventType.UsersCreated:
    case EventType.GroupFoldersCreated:
    case EventType.GroupsCreated:
    case EventType.ConnectionsCreated:
    case EventType.IdpConnectionsCreated:
    case EventType.RemoteEventGroupAccessAdded:
    case EventType.EventStreamConnectionsCreated:
      eventIcon = <AddIcon />;
      break;
    case EventType.RemoteEventGroupAccessRemoved:
    case EventType.EventStreamConnectionsDeleted:
      eventIcon = <MinusIcon />;
      break;
    case EventType.ResourceFoldersCreated:
    case EventType.ResourcesCreated:
    case EventType.RequestReviewersAddedToRequests:
    case EventType.ReviewersAddedToResources:
    case EventType.ReviewersAddedToGroups:
    case EventType.OnCallSchedulesCreated:
    case EventType.AccessReviewsCreated:
    case EventType.AccessReviewTemplatesCreated:
    case EventType.ThirdPartyIntegrationCreated:
    case EventType.ApiTokenCreated:
    case EventType.MessageChannelsCreated:
    case EventType.MessageChannelsAddedToGroups:
    case EventType.MessageChannelsAddedToResources:
    case EventType.UsersAddedToConnections:
    case EventType.BreakGlassUsersAddedToGroups:
    case EventType.GroupLeadersCreated:
      eventIcon = <AddIcon />;
      break;
    case EventType.BreakGlassUsersRemovedFromGroups:
    case EventType.UsersRemovedFromConnections:
    case EventType.GroupLeadersRemoved:
      eventIcon = <MinusIcon />;
      break;
    case EventType.UsersAddedToResources:
    case EventType.UsersAddedToGroups:
      eventIcon = <AddIcon />;
      break;
    case EventType.RequestsCreated:
      eventIcon = <PendingIcon ok={true} large={true} />;
      break;
    case EventType.RequestReviewersApproved:
    case EventType.RequestsApproved:
      eventIcon = <ApprovedIcon large={true} />;
      break;
    case EventType.RequestReviewersDenied:
    case EventType.RequestsDenied:
      eventIcon = <DeniedIcon large={true} />;
      break;
    case EventType.RequestsCanceled:
      eventIcon = <CanceledIcon large={true} />;
      break;
    case EventType.OrgSettingsUpdated:
    case EventType.EventStreamConnectionsUpdated:
      eventIcon = <EditIcon />;
  }
  return eventIcon;
};

type FieldChangedPillLabelProps = {
  oldValue: string;
  newValue: string;
};

const FieldChangedPillLabel = (props: FieldChangedPillLabelProps) => {
  return (
    <div className={styles.secondaryObjectContent}>
      <PillV3
        keyText={props.oldValue}
        pillColor="Red"
        tooltipText={"Previously set value: " + props.oldValue}
      />
      <div className={styles.arrowContainer}>
        <Icons.ArrowRight strokeWidth={2} size={16} />
      </div>
      <PillV3
        keyText={props.newValue}
        pillColor="LightGreen"
        tooltipText={"Updated value: " + props.newValue}
      />
    </div>
  );
};
