import { getModifiedErrorMessage } from "api/ApiContext";
import {
  ThirdPartyProvider,
  TicketProvider,
  useUpdateOrganizationSettingsMutation,
} from "api/generated/graphql";
import { getLogoByThirdPartyProvider } from "components/label/Label";
import { MoreInfo } from "components/more_info/MoreInfo";
import TicketProjectDropdown from "components/tickets/TicketProjectDropdown";
import TicketProviderDropdown from "components/tickets/TicketProviderDropdown";
import { useToast } from "components/toast/Toast";
import { ButtonV3, Icon, Modal, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import OrgContext, { OrgContextActionType } from "views/settings/OrgContext";
import styles from "views/settings/OrgSettings.module.scss";
import * as stylesV3 from "views/settings/OrgSettingsV3.css";

const HELP_TEXT = `If enabled, an audit ticket will be created in your third-party
ticketing platform for every access request created in Opal.`;

type AuditTicketProviderSettingProps = {
  auditTicketProvider?: TicketProvider;
};

export const AuditTicketProviderSetting = (
  props: AuditTicketProviderSettingProps
) => {
  const [showModal, setShowModal] = useState(false);

  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  return hasV3 ? (
    <div className={stylesV3.switchesHeader}>
      <div className={sprinkles({ display: "flex" })}>
        <div className={stylesV3.label}>
          Create audit ticket for all access requests created in Opal
        </div>
        <MoreInfo tooltipText={HELP_TEXT} />
      </div>
      <div className={stylesV3.switches}>
        <ButtonV3
          onClick={() => setShowModal(true)}
          label={
            props.auditTicketProvider ? (
              <>
                <Icon
                  data={{
                    icon:
                      getLogoByThirdPartyProvider(
                        props.auditTicketProvider.ticketProvider
                      ) ?? undefined,
                    type: "src",
                  }}
                  size="sm"
                />
                {_.upperFirst(
                  _.camelCase(props.auditTicketProvider.ticketProvider)
                )}
              </>
            ) : (
              "None"
            )
          }
          outline={true}
        />
      </div>

      <AuditTicketProviderModal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        auditTicketProvider={props.auditTicketProvider}
      />
    </div>
  ) : (
    <div className={styles.switchesHeader}>
      <div className={styles.switches}>
        <button
          onClick={() => setShowModal(true)}
          className={styles.orgSettingOpenModalButton}
        >
          {props.auditTicketProvider ? (
            <>
              <Icon
                data={{
                  icon:
                    getLogoByThirdPartyProvider(
                      props.auditTicketProvider.ticketProvider
                    ) ?? undefined,
                  type: "src",
                }}
                size="sm"
              />
              {_.upperFirst(
                _.camelCase(props.auditTicketProvider.ticketProvider)
              )}
            </>
          ) : (
            "None"
          )}
        </button>
      </div>
      <div className={styles.label}>
        Create audit ticket for all access requests created in Opal
      </div>
      <MoreInfo tooltipText={HELP_TEXT} />

      <AuditTicketProviderModal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
        }}
        auditTicketProvider={props.auditTicketProvider}
      />
    </div>
  );
};

type AuditTicketProviderModalProps = {
  isOpen: boolean;
  onClose: () => void;
  auditTicketProvider?: TicketProvider;
};

const AuditTicketProviderModal = (props: AuditTicketProviderModalProps) => {
  const { orgDispatch } = useContext(OrgContext);

  const [enableAuditTickets, setEnableAuditTickets] = useState<boolean>(
    !!props.auditTicketProvider
  );
  const [auditTicketProvider, setAuditTicketProvider] = useState<
    ThirdPartyProvider | undefined
  >(props.auditTicketProvider?.ticketProvider);
  const [auditTicketProjectId, setAuditTicketProjectId] = useState<
    string | undefined
  >(props.auditTicketProvider?.ticketProjectId);
  const [auditTicketProjectName, setAuditTicketProjectName] = useState<
    string | undefined
  >(props.auditTicketProvider?.ticketProjectName);

  useEffect(() => {
    setEnableAuditTickets(!!props.auditTicketProvider);
    setAuditTicketProvider(props.auditTicketProvider?.ticketProvider);
    setAuditTicketProjectId(props.auditTicketProvider?.ticketProjectId);
    setAuditTicketProjectName(props.auditTicketProvider?.ticketProjectName);
  }, [props.auditTicketProvider]);

  const { displaySuccessToast, displayErrorToast } = useToast();

  const [
    updateOrgSettings,
    { loading },
  ] = useUpdateOrganizationSettingsMutation();

  const hasChanged =
    enableAuditTickets !== !!props.auditTicketProvider ||
    (enableAuditTickets &&
      (auditTicketProvider !== props.auditTicketProvider?.ticketProvider ||
        auditTicketProjectId !== props.auditTicketProvider?.ticketProjectId ||
        auditTicketProjectName !==
          props.auditTicketProvider?.ticketProjectName));
  const requireProject =
    auditTicketProvider &&
    auditTicketProvider !== ThirdPartyProvider.ServiceNow;

  const modalReset = () => {
    setEnableAuditTickets(!!props.auditTicketProvider);
    setAuditTicketProvider(props.auditTicketProvider?.ticketProvider);
    setAuditTicketProjectId(props.auditTicketProvider?.ticketProjectId);
    setAuditTicketProjectName(props.auditTicketProvider?.ticketProjectName);
  };

  const onSubmit = async () => {
    try {
      const { data } = await updateOrgSettings({
        variables: {
          input: {
            auditTicketProvider: {
              ticketProviderInfo:
                enableAuditTickets && auditTicketProvider
                  ? {
                      ticketProvider: auditTicketProvider,
                      ticketProjectId: auditTicketProjectId || "",
                      ticketProjectName: auditTicketProjectName || "",
                    }
                  : null,
            },
          },
        },
      });

      switch (data?.updateOrganizationSettings.__typename) {
        case "UpdateOrganizationSettingsResult":
          orgDispatch({
            type: OrgContextActionType.OrgSettings,
            payload: {
              orgSettings: data.updateOrganizationSettings.settings,
            },
          });
          props.onClose();
          displaySuccessToast("Success: settings updated");
          break;
        default:
          logError(new Error(`failed to update org settings`));
          displayErrorToast("Error: failed to update org settings");
      }
    } catch (error) {
      logError(error, "failed to update org settings");
      displayErrorToast(
        getModifiedErrorMessage("Error: failed to update org settings", error)
      );
    }
  };

  return (
    <Modal
      title="Configure audit tickets for requests"
      isOpen={props.isOpen}
      onClose={() => {
        props.onClose();
        modalReset();
      }}
    >
      <Modal.Body>
        <div style={{ display: "flex", gap: "20px", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              gap: "8px",
              flexDirection: "column",
            }}
          >
            <div>{HELP_TEXT}</div>
            <div>
              You can use audit tickets to consolidate analytics and reporting
              on your central ITSM platform.
            </div>
          </div>

          <Switch
            checked={enableAuditTickets}
            onChange={() => {
              setEnableAuditTickets(!enableAuditTickets);
            }}
            label="Create audit ticket for every request"
          />

          {enableAuditTickets && (
            <>
              <TicketProviderDropdown
                selectedTicketProvider={auditTicketProvider}
                onSelectTicketProvider={(newProvider) => {
                  if (newProvider !== auditTicketProvider) {
                    setAuditTicketProvider(newProvider);
                    setAuditTicketProjectId(undefined);
                  }
                }}
                placeholder={"Select ticket provider"}
              />
              {requireProject && (
                <div
                  style={{
                    display: "flex",
                    gap: "8px",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    Please select the project where Opal should create audit
                    tickets.
                  </div>
                  <TicketProjectDropdown
                    ticketProvider={auditTicketProvider}
                    onSelectTicketProject={(newProject) => {
                      setAuditTicketProjectId(newProject?.id ?? undefined);
                      setAuditTicketProjectName(newProject?.name ?? undefined);
                    }}
                    selectedTicketProjectId={auditTicketProjectId}
                    selectedTicketProjectName={auditTicketProjectName}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Save"
        onPrimaryButtonClick={onSubmit}
        primaryButtonDisabled={
          !hasChanged ||
          // Disable button if input is invalid
          (enableAuditTickets &&
            (!auditTicketProvider || (requireProject && !auditTicketProjectId)))
        }
        primaryButtonLoading={loading}
      />
    </Modal>
  );
};
