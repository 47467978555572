import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AuthType,
  ConnectionType,
  Maybe,
  useCreateConnectionMutation,
  Visibility,
} from "api/generated/graphql";
import azureLogo from "assets/logos/azure-logo.svg";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { Button, FormGroup, Input, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useEffect, useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import {
  CreateConnectionComponents as CreateConnectionComponentsV2,
  CreateConnectionView as CreateConnectionViewV2,
} from "views/connections/create/CreateConnectionComponents";
import {
  CreateConnectionComponentsV3,
  CreateConnectionViewV3,
} from "views/connections/create/CreateConnectionComponentsV3";
import VisibilitySelector from "views/visibility/VisibilitySelector";

export const CreateAzureADCallback = () => {
  useEffect(() => {
    if (!window.opener || !window.opener.azureConsentCallback) {
      window.close();
    }

    const query = new URLSearchParams(location.search);
    window.opener.azureConsentCallback(query);
    window.close();
  });
  return <div>You may now close this window.</div>;
};

const CreateAzureAD = () => {
  return <CreateAzureADForm />;
};

const CreateAzureADForm = () => {
  const history = useHistory();
  const appName = "Azure";
  const hasAppLevelVisibility = useFeatureFlag(FeatureFlag.AppLevelVisibility);
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const logEvent = useLogEvent();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [name, setName] = useState(appName);
  const [description, setDescription] = useState("");
  const [visibility, setVisibility] = useState<Visibility>(Visibility.Global);
  const [visibilityGroupIds, setVisibilityGroupIds] = useState<
    string[] | undefined
  >([]);

  const [adminOwnerId, setAdminOwnerId] = useState<string | undefined>(
    undefined
  );

  const [tenantId, setTenantID] = useState("");
  const [clientId, setClientID] = useState("");
  const [azureInfraEnabled, setAzureInfraEnabled] = useState(true);
  const [eventHubNamespace] = useState("");
  const [eventHub] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const [createConnectionMutation, { loading }] = useCreateConnectionMutation({
    refetchQueries: ["AppsListColumn", "Connections"],
  });

  const fieldUnset =
    name === "" ||
    description === "" ||
    adminOwnerId === undefined ||
    tenantId == "" ||
    clientId == "" ||
    clientSecret == "";

  useEffect(() => {
    window.azureConsentCallback = async (query: URLSearchParams) => {
      if (query.get("error")) {
        const desc = query.get("error_description");
        if (desc) {
          setErrorMessage(desc);
        } else {
          setErrorMessage("Failed to authorize app registration.");
        }
        return;
      }

      setErrorMessage(null);
      try {
        const { data } = await createConnectionMutation({
          variables: {
            input: {
              name: name,
              description: description,
              connectionType: ConnectionType.AzureAd,
              adminOwnerId: adminOwnerId ?? "",
              visibility: visibility,
              visibilityGroupIds: visibilityGroupIds ?? [],
              importVisibility: Visibility.Global,
              metadata: {
                connectionType: ConnectionType.AzureAd,
                azureAd: {
                  clientId,
                  tenantId,
                  azureInfraEnabled,
                  eventHubNamespace,
                  eventHub,
                },
              },
              credentials: {
                authType: AuthType.AzureAd,
                azureAd: {
                  clientSecret,
                },
              },
            },
          },
        });
        switch (data?.createConnection.__typename) {
          case "CreateConnectionResult":
            displaySuccessToast(`Success: ${appName} app created`);
            history.replace(
              getResourceUrl(
                EntityTypeDeprecated.Connection,
                data.createConnection.connection.id
              )
            );
            logEvent({
              name: "apps_create_click",
              properties: {
                connectionType: ConnectionType.AzureAd,
              },
            });

            break;
          case "ConnectionExistsError":
          case "ConnectionBadMetadataError":
          case "UserFacingError":
            logWarning(new Error(data.createConnection.message));
            setErrorMessage(data.createConnection.message);
            break;
          default:
            logError(new Error(`failed to create ${appName} connection`));
            setErrorMessage(`Error: failed to create ${appName} connection`);
        }
      } catch (error) {
        logError(error, `failed to create ${appName} connection`);
        setErrorMessage(
          getModifiedErrorMessage(
            `Error: failed to create ${appName} connection`,
            error
          )
        );
      }
    };
  });

  const CreateConnectionComponents = hasV3
    ? CreateConnectionComponentsV3
    : CreateConnectionComponentsV2;
  const CreateConnectionView = hasV3
    ? CreateConnectionViewV3
    : CreateConnectionViewV2;

  return (
    <CreateConnectionView
      logo={azureLogo}
      title={`Add your ${appName} instance`}
      onSubmit={async () => {
        window.open(
          `https://login.microsoftonline.com/${tenantId}/adminconsent?client_id=${clientId}&redirect_uri=${window.location.protocol}//${window.location.host}/apps/create/azure_ad/callback`,
          "popUpWindow",
          "height=600,width=600,left=100,top=100,resizable=yes"
        );
      }}
      submitDisabled={fieldUnset}
      submitLoading={loading}
    >
      <>
        <CreateConnectionComponents
          title={"Step 1"}
          subtitle={"Create an app registration for Opal"}
        >
          <>
            <p>
              Opal requires an app registration to administer {appName} on your
              behalf.
            </p>
            <p>
              To learn more about setting up an app registration,{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={"https://docs.opal.dev/docs/azure-ad"}
              >
                check out our official documentation here.
              </a>
              .
            </p>
          </>
        </CreateConnectionComponents>
        <CreateConnectionComponents
          title={"Step 2"}
          subtitle={"Add your app registration credentials"}
          isLast
        >
          <>
            <p>
              {`Once the app registration is created, please input the user's
              credentials and some information about the connection:`}
            </p>
            <FormGroup label="App name:">
              <Input
                onChange={setName}
                placeholder={`Identifiable name for the ${appName} connection.`}
                value={name}
              />
            </FormGroup>
            <FormGroup label="App admin:">
              <OwnerDropdown
                selectedOwnerId={adminOwnerId}
                onSelectOwner={(owner) => setAdminOwnerId(owner?.id)}
                placeholder="Select an owner to own this app."
              />
            </FormGroup>
            <FormGroup label="Description:">
              <Input
                onChange={setDescription}
                placeholder="A brief description of the account to further inform people requesting access to it."
                value={description}
              />
            </FormGroup>
            {hasAppLevelVisibility && (
              <FormGroup label="Visibility:">
                <VisibilitySelector
                  visibility={visibility}
                  onChangeVisibility={(vis) => {
                    if (vis == Visibility.Global) {
                      setVisibility(Visibility.Global);
                      setVisibilityGroupIds([]);
                    } else {
                      setVisibility(Visibility.Team);
                    }
                  }}
                  visibilityGroups={visibilityGroupIds ?? []}
                  onChangeVisibilityGroups={setVisibilityGroupIds}
                />
              </FormGroup>
            )}
            <FormGroup label="Tenant ID">
              <Input
                onChange={setTenantID}
                value={tenantId}
                placeholder="a138396d-3790-4622-8405-36bf9283d298"
              />
            </FormGroup>
            <FormGroup label="Client ID">
              <Input
                onChange={setClientID}
                value={clientId}
                placeholder="6e292892-aa75-22aa-9ade-ae66e6e753c8"
              />
            </FormGroup>
            <FormGroup label="Client Secret">
              <Input
                type="password"
                onChange={setClientSecret}
                value={clientSecret}
                placeholder="1lQ8Q~4QLtsARzlkdLSdx_pYE22OGxmjitm6OdjU"
              />
            </FormGroup>
            <div
              className={sprinkles({
                padding: "sm",
                paddingBottom: "lg",
              })}
            >
              <Switch
                label="Enable Azure Infrastructure management"
                checked={azureInfraEnabled}
                onChange={() => setAzureInfraEnabled(!azureInfraEnabled)}
                infoTooltip="Toggles management of Azure Subscriptions and Resource Groups."
              />
            </div>
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
            <p>
              You will be redirected through Azure to approve the app
              registration.
            </p>
            {!hasV3 && (
              <div
                className={sprinkles({
                  display: "flex",
                  justifyContent: "flex-end",
                })}
              >
                <Button
                  label="Authorize & Create"
                  disabled={fieldUnset}
                  type="primary"
                  loading={loading}
                  onClick={() => {
                    window.open(
                      `https://login.microsoftonline.com/${tenantId}/adminconsent?client_id=${clientId}&redirect_uri=${window.location.protocol}//${window.location.host}/apps/create/azure_ad/callback`,
                      "popUpWindow",
                      "height=600,width=600,left=100,top=100,resizable=yes"
                    );
                  }}
                />
              </div>
            )}
          </>
        </CreateConnectionComponents>
      </>
    </CreateConnectionView>
  );
};

export default CreateAzureAD;
