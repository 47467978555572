import { gql, useQuery } from "@apollo/client";
import {
  EntityType,
  OwnersSortByField,
  OwnersTableQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { useOpalSearchInput } from "components/opal/common/input/OpalSearchInput";
import OpalLink from "components/opal/common/OpalLink";
import OpalTable, {
  Columns,
  useServerSortableOpalTable,
} from "components/opal/table/OpalTable";
import { ButtonV3, Label } from "components/ui";
import moment from "moment";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { getResourceUrlNew } from "utils/common";
import { useTransitionTo } from "utils/router/hooks";
import OwnerCreateModal from "views/owners/OwnerCreateModal";

type Owner = OwnersTableQuery["owners"]["owners"][0];
const OWNER_COLUMNS: Columns<Owner, OwnersSortByField> = [
  {
    id: OwnersSortByField.Name,
    label: "Name",
    sortable: true,
    customCellRenderer: (owner) => {
      return (
        <OpalLink
          to={getResourceUrlNew({
            entityId: owner.id,
            entityType: EntityType.Owner,
          })}
        >
          {owner.name}
        </OpalLink>
      );
    },
  },
  {
    id: "numUsers",
    label: "Users",
    customCellRenderer: (owner) => {
      return <div>{`${pluralize("User", owner.ownerUsers.length, true)}`}</div>;
    },
    sortable: false,
  },
  {
    id: "numResourcesAsAdmin",
    label: "Assigned Resources as Admin",
    customCellRenderer: (owner) => {
      let numResourcesAsAdmin = 0;
      owner.ownedResources.forEach((resource) => {
        resource.isAdmin && numResourcesAsAdmin++;
      });
      owner.ownedGroups.forEach((group) => {
        group.isAdmin && numResourcesAsAdmin++;
      });
      return <div>{`${pluralize("Resource", numResourcesAsAdmin, true)}`}</div>;
    },
    sortable: false,
  },
  {
    id: "numResourcesAsReviewer",
    label: "Assigned Resources as Reviewer",
    customCellRenderer: (owner) => {
      let numResourcesAsReviewer = 0;
      owner.ownedResources.forEach((resource) => {
        resource.isReviewer && numResourcesAsReviewer++;
      });
      owner.ownedGroups.forEach((group) => {
        group.isReviewer && numResourcesAsReviewer++;
      });
      return (
        <div>{`${pluralize("Resource", numResourcesAsReviewer, true)}`}</div>
      );
    },
    sortable: false,
  },
  {
    id: OwnersSortByField.SourceGroupName,
    label: "Source Group",
    customCellRenderer: (owner) => {
      const sourceGroup = owner.sourceGroup;
      if (!sourceGroup) {
        return <div>—</div>;
      }
      return (
        <Label
          label={sourceGroup.name}
          linkTo={getResourceUrlNew({
            entityId: sourceGroup.id,
            entityType: EntityType.Group,
          })}
          icon={{
            type: "entity",
            entityType: sourceGroup.groupType,
          }}
        />
      );
    },
    sortable: true,
  },
  {
    id: OwnersSortByField.CreatedAt,
    label: "Created",
    customCellRenderer: (owner) => {
      return <div>{moment(owner.createdAt).fromNow()}</div>;
    },
    sortable: true,
  },
];

const InventoryOwnersTable = () => {
  const transitionTo = useTransitionTo();
  const [showCreateModal, setShowCreateModal] = useState(false);

  const [searchQuery, searchInput] = useOpalSearchInput({
    placeholder: "Filter by name",
  });

  const {
    sortByVariable,
    sortByTableProps,
  } = useServerSortableOpalTable<OwnersSortByField>({
    id: OwnersSortByField.Name,
    desc: false,
  });

  const { data, previousData, networkStatus, fetchMore } = useQuery(
    gql`
      query InventoryOwnersTable(
        $searchQuery: String
        $sortBy: OwnersSortBy
        $cursor: String
      ) {
        owners(
          input: {
            searchQuery: $searchQuery
            sortBy: $sortBy
            cursor: $cursor
            maxNumEntries: 50
          }
        ) {
          __typename
          ... on OwnersResult {
            owners {
              id
              name
              createdAt
              ownerUsers {
                userId
              }
              sourceGroup {
                id
                name
                groupType
              }
              ownedResources {
                isAdmin
                isReviewer
              }
              ownedGroups {
                isAdmin
                isReviewer
              }
              ownedConfigurationTemplates {
                isAdmin
                isReviewer
              }
            }
            totalNumOwners
            cursor
          }
        }
      }
    `,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: "cache-and-network",
      variables: {
        searchQuery: searchQuery,
        sortBy: sortByVariable,
      },
    }
  );

  const { authState } = useContext(AuthContext);
  const isAdmin = authState.user?.isAdmin ?? false;

  const cursor = data?.owners.cursor;
  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            cursor,
          },
        });
      }
    : undefined;

  const owners = data?.owners.owners ?? previousData?.owners.owners ?? [];
  const totalNumOwners =
    data?.owners.totalNumOwners || previousData?.owners.totalNumOwners;

  return (
    <>
      <OpalTable
        rows={owners}
        filters={searchInput}
        actions={
          isAdmin && (
            <ButtonV3
              label="Owner"
              type="main"
              size="sm"
              leftIconName="plus"
              onClick={() => {
                setShowCreateModal(true);
              }}
            />
          )
        }
        totalNumRows={cursor ? totalNumOwners || 0 : owners.length}
        getRowId={(ru) => ru.id}
        columns={OWNER_COLUMNS}
        onRowClick={(owner, event) => {
          transitionTo(
            {
              pathname: getResourceUrlNew({
                entityId: owner.id,
                entityType: EntityType.Owner,
              }),
            },
            event
          );
        }}
        onLoadMoreRows={loadMoreRows}
        entityName="Owner"
        networkStatus={networkStatus}
        {...sortByTableProps}
      />
      {showCreateModal ? (
        <OwnerCreateModal onClose={() => setShowCreateModal(false)} />
      ) : null}
    </>
  );
};

export default InventoryOwnersTable;
