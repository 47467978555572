import { getModifiedErrorMessage } from "api/ApiContext";
import {
  IntegrationType,
  Maybe,
  ServiceNowCredentials,
  ThirdPartyIntegrationMetadataInput,
  ThirdPartyProvider,
  useCreateThirdPartyIntegrationMutation,
} from "api/generated/graphql";
import { IntegrationInfo } from "components/integrations/OrgIntegrationApp";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Button, ButtonGroup, FormGroup, Input, Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { produce } from "immer";
import _ from "lodash";
import { cloneDeep } from "lodash";
import { ReactElement, useContext, useState } from "react";
import { logError, logWarning } from "utils/logging";
import { validateHostname } from "views/connections/create/common";
import { subtitleFormat } from "views/groups/creation/common";
import { IntegrationButton } from "views/settings/third_party_authorizer/IntegrationButton";

import OrgContext, { OrgContextActionType } from "../OrgContext";

export type ClientInfo = {
  clientId: string;
  clientSecret: string;
};

export type IntegrationCreationInfo = {
  thirdPartyProvider: ThirdPartyProvider;
  token: string;
  orgName: string;
  jiraProjectName: string;
  serviceNow: ServiceNowCredentials;
  metadata: Maybe<ThirdPartyIntegrationMetadataInput>;
};

type GetOrgDataAdditionalInfo = {
  jiraUseOrgUrl?: boolean;
};

enum IntegrationCreationSteps {
  Token,
  OrgName,
  ServiceNowInstanceName,
  ServiceNowCatalogItemId,
  ServiceNowUserCredentials,
  ServiceNowClientCredentials,
  JiraProjectName,
  JiraTokenEmail,
}

export const thirdPartyProviderToOrgName = (
  thirdPartyProvider: ThirdPartyProvider,
  additionalInfo: GetOrgDataAdditionalInfo = {}
) => {
  switch (thirdPartyProvider) {
    case ThirdPartyProvider.Jira:
      if (additionalInfo?.jiraUseOrgUrl) {
        return "Jira instance API URL: ";
      }
      return "Organization domain name: ";
    case ThirdPartyProvider.ServiceNow:
      return "ServiceNow instance hostname: ";
    default:
      return "";
  }
};

export const thirdPartyProviderToTokenName = (
  thirdPartyProvider: ThirdPartyProvider
) => {
  const integrationName = _.startCase(_.lowerCase(thirdPartyProvider));
  switch (thirdPartyProvider) {
    case ThirdPartyProvider.ServiceNow:
      return integrationName + " password: ";
    default:
      return integrationName + " token: ";
  }
};

export const getOrgTooltip = (
  thirdPartyProvider: ThirdPartyProvider,
  additionalInfo: GetOrgDataAdditionalInfo = {}
) => {
  switch (thirdPartyProvider) {
    case ThirdPartyProvider.Jira:
      if (additionalInfo?.jiraUseOrgUrl) {
        return 'e.g. "https://jira.acme-corp.com". This is the URL of your internal, self-hosted Jira instance.';
      }
      return 'e.g. "my-organization" where your Jira URL is "https://my-organization.atlassian.net"';
    case ThirdPartyProvider.ServiceNow:
      return 'e.g. "acme-corp.service-now.com"';
  }
};

export const getOrgTitle = (thirdPartyProvider: ThirdPartyProvider) => {
  switch (thirdPartyProvider) {
    case ThirdPartyProvider.Jira:
      return "Jira Info";
    case ThirdPartyProvider.ServiceNow:
      return "ServiceNow Info";
    default:
      return "Org Info";
  }
};

export const getProjectTooltip = (thirdPartyProvider: ThirdPartyProvider) => {
  switch (thirdPartyProvider) {
    case ThirdPartyProvider.Jira:
      return 'This can be found under the "Projects" tab in the Jira application';
  }
};

export const getTokenMessage = (
  thirdPartyProvider: ThirdPartyProvider,
  useOrgUrl: boolean
) => {
  switch (thirdPartyProvider) {
    case ThirdPartyProvider.Jira:
      if (useOrgUrl) {
        return (
          <span>
            To enable our Jira integration, Opal requires the administrator of
            your Jira workspace to create a Jira Personal Access Token. The
            administrator's Jira account email should match the email entered in
            the previous step.
          </span>
        );
      } else {
        return (
          <span>
            To enable our Jira integration, Opal requires the administrator of
            your Jira workspace to create a Jira API token, using this&nbsp;
            <ModalMessageLink
              url={
                "https://id.atlassian.com/manage-profile/security/api-tokens"
              }
              text={"link"}
            />
            . The administrator's Jira account email should match the email
            entered in the previous step.
          </span>
        );
      }
    case ThirdPartyProvider.ServiceNow:
      return (
        <span>Please enter the password associated with the system user.</span>
      );
    case ThirdPartyProvider.PagerDuty:
      return (
        <span>
          To enable our PagerDuty integration, Opal requires a "read-only"
          PagerDuty API token. Navigate to your PagerDuty dashboard and create
          an API token by following this&nbsp;
          <ModalMessageLink
            url={
              "https://support.pagerduty.com/docs/generating-api-keys#generating-a-general-access-rest-api-key"
            }
            text={"link"}
          />
          .
        </span>
      );
    case ThirdPartyProvider.Linear:
      return (
        <span>
          To enable our Linear integration, Opal requires the administrator of
          your Linear workspace to create a Personal API key, using this&nbsp;
          <ModalMessageLink
            url={"https://linear.app/settings/api"}
            text={"link"}
          />
          .
        </span>
      );
  }
};

type ModalMessageLinkProps = {
  url: string;
  text: string;
};
const ModalMessageLink = (props: ModalMessageLinkProps) => {
  return (
    <a
      href={props.url}
      target={"_blank"}
      rel={"noreferrer"}
      className={sprinkles({ color: "blue600" })}
    >
      {props.text}
    </a>
  );
};

type ThirdPartyManualFlowAuthorizerProps = {
  integrationType: IntegrationType;
  integrationInfo: IntegrationInfo;
  isConnected: boolean;
  openThirdPartyProvider: Maybe<ThirdPartyProvider>;
  setOpenThirdPartyProvider: (integration: Maybe<ThirdPartyProvider>) => void;
  connectionButtonDisabled: boolean;
};

export const ThirdPartyManualFlowAuthorizer = (
  props: ThirdPartyManualFlowAuthorizerProps
) => {
  const [stepNumber, setStepNumber] = useState(1);
  const [useOrgUrl, setUseOrgUrl] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const { displaySuccessToast } = useToast();
  const { orgState, orgDispatch } = useContext(OrgContext);
  const integrations = cloneDeep(orgState.orgThirdPartyIntegrations ?? []);

  const [
    createThirdPartyIntegration,
    { loading },
  ] = useCreateThirdPartyIntegrationMutation();

  const thirdPartyProvider = props.integrationInfo.thirdPartyProvider;

  const initCreateInfo: IntegrationCreationInfo = {
    thirdPartyProvider: thirdPartyProvider,
    token: "",
    orgName: "",
    jiraProjectName: "",
    serviceNow: {
      userId: "",
      userPassword: "",
      clientId: "",
      clientSecret: "",
    },
    metadata: null,
  };

  const [
    integrationCreationInfo,
    setIntegrationCreationInfo,
  ] = useState<IntegrationCreationInfo>(initCreateInfo);

  let steps = null;
  switch (thirdPartyProvider) {
    case ThirdPartyProvider.Jira:
      steps = [
        IntegrationCreationSteps.OrgName,
        IntegrationCreationSteps.JiraProjectName,
        IntegrationCreationSteps.JiraTokenEmail,
        IntegrationCreationSteps.Token,
      ];
      break;
    case ThirdPartyProvider.ServiceNow:
      steps = [
        IntegrationCreationSteps.ServiceNowInstanceName,
        IntegrationCreationSteps.ServiceNowCatalogItemId,
        IntegrationCreationSteps.ServiceNowUserCredentials,
        IntegrationCreationSteps.ServiceNowClientCredentials,
      ];
      break;
    default:
      steps = [IntegrationCreationSteps.Token];
  }

  const step = steps[stepNumber - 1];

  const totalSteps = steps.length;

  const integrationName = _.startCase(_.lowerCase(thirdPartyProvider));

  const onClose = () => {
    props.setOpenThirdPartyProvider(null);
    setIntegrationCreationInfo(initCreateInfo);
    setStepNumber(1);
  };

  const handleSubmit = async () => {
    try {
      let token = integrationCreationInfo.token;
      if (
        integrationCreationInfo.thirdPartyProvider ===
        ThirdPartyProvider.ServiceNow
      ) {
        token = JSON.stringify(integrationCreationInfo.serviceNow);
      }
      const { data } = await createThirdPartyIntegration({
        variables: {
          input: {
            thirdPartyProvider: integrationCreationInfo.thirdPartyProvider,
            token,
            orgName: integrationCreationInfo.orgName || null,
            metadata: integrationCreationInfo.metadata,
          },
        },
        refetchQueries: ["ThirdPartyIntegrations"],
      });
      switch (data?.createThirdPartyIntegration.__typename) {
        case "CreateThirdPartyIntegrationResult":
          onClose();
          setErrorMessage(null);
          displaySuccessToast(
            `Successfully created ${integrationName} integration`
          );
          integrations.push(
            data.createThirdPartyIntegration.thirdPartyIntegration
          );
          orgDispatch({
            type: OrgContextActionType.OrgThirdPartyIntegrations,
            payload: {
              orgThirdPartyIntegrations: integrations,
            },
          });
          break;
        case "SlackOrganizationAlreadyExistsError":
        case "UserFacingError":
          logWarning(new Error(data.createThirdPartyIntegration.message));
          setErrorMessage(data.createThirdPartyIntegration.message);
          break;
        default:
          logError(
            new Error(`failed to create ${integrationName} integration`)
          );
          setErrorMessage(
            `Error: failed to create ${integrationName} integration`
          );
      }
    } catch (error) {
      logError(error, `failed to create ${integrationName} integration`);
      setErrorMessage(
        getModifiedErrorMessage(
          `Error: failed to create ${integrationName} integration`,
          error
        )
      );
    }
  };

  let modal: Maybe<ReactElement> = null;
  switch (step) {
    case IntegrationCreationSteps.Token:
      modal = (
        <Modal
          title={`Link ${integrationName}`}
          isOpen={true}
          onClose={onClose}
          subtitle={subtitleFormat(stepNumber, totalSteps)}
        >
          <Modal.Body>
            <p>{getTokenMessage(thirdPartyProvider, useOrgUrl)}</p>
            <FormGroup
              label={thirdPartyProviderToTokenName(thirdPartyProvider)}
            >
              <Input
                value={integrationCreationInfo.token}
                onChange={(val) => {
                  setIntegrationCreationInfo({
                    ...integrationCreationInfo,
                    token: val,
                  });
                }}
                type="password"
              />
            </FormGroup>
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Create"
            onPrimaryButtonClick={handleSubmit}
            primaryButtonLoading={loading}
            secondaryButtonLabel={stepNumber > 1 ? "Back" : undefined}
            onSecondaryButtonClick={
              stepNumber > 1 ? () => setStepNumber(stepNumber - 1) : undefined
            }
          />
        </Modal>
      );
      break;
    case IntegrationCreationSteps.OrgName:
      modal = (
        <Modal
          title={getOrgTitle(thirdPartyProvider)}
          isOpen={true}
          onClose={onClose}
          subtitle={subtitleFormat(stepNumber, totalSteps)}
        >
          <Modal.Body>
            <FormGroup
              label={thirdPartyProviderToOrgName(thirdPartyProvider, {
                jiraUseOrgUrl: useOrgUrl,
              })}
              infoTooltip={getOrgTooltip(thirdPartyProvider, {
                jiraUseOrgUrl: useOrgUrl,
              })}
              rightLabelContent={
                <ButtonGroup>
                  <Button
                    label={"Atlassian-hosted"}
                    onClick={() => setUseOrgUrl(false)}
                    outline={useOrgUrl}
                    size="sm"
                  />
                  <Button
                    label={"Self-hosted"}
                    onClick={() => setUseOrgUrl(true)}
                    outline={!useOrgUrl}
                    size="sm"
                  />
                </ButtonGroup>
              }
            >
              <Input
                value={integrationCreationInfo.orgName}
                onChange={(val) => {
                  // in the case of on-prem for self-hosted jira urls, also set orgName as the url
                  setIntegrationCreationInfo({
                    ...integrationCreationInfo,
                    orgName: val,
                    metadata: {
                      jira: {
                        projectName:
                          integrationCreationInfo?.metadata?.jira
                            ?.projectName || "",
                        orgUrl: useOrgUrl ? val : "",
                        email:
                          integrationCreationInfo?.metadata?.jira?.email || "",
                      },
                    },
                  });
                }}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Next"
            onPrimaryButtonClick={() => setStepNumber(stepNumber + 1)}
            secondaryButtonLabel={stepNumber > 1 ? "Back" : undefined}
            onSecondaryButtonClick={
              stepNumber > 1 ? () => setStepNumber(stepNumber - 1) : undefined
            }
          />
        </Modal>
      );
      break;
    case IntegrationCreationSteps.ServiceNowInstanceName:
      modal = (
        <Modal
          title={getOrgTitle(thirdPartyProvider)}
          isOpen={true}
          onClose={onClose}
          subtitle={subtitleFormat(stepNumber, totalSteps)}
        >
          <Modal.Body>
            <p>Please enter the hostname of your ServiceNow instance.</p>
            <p>
              For example: <b>acme-corp.service-now.com</b>
            </p>
            <FormGroup label={thirdPartyProviderToOrgName(thirdPartyProvider)}>
              <Input
                value={
                  integrationCreationInfo.metadata?.servicenow?.hostname || ""
                }
                onChange={(val) => {
                  setIntegrationCreationInfo(
                    produce(integrationCreationInfo, (draft) => {
                      if (!draft.metadata) {
                        draft.metadata = {};
                      }
                      if (!draft.metadata.servicenow) {
                        draft.metadata.servicenow = {
                          hostname: "",
                          catalogItemId: "",
                        };
                      }
                      draft.metadata.servicenow.hostname = val;
                    })
                  );
                }}
              />
            </FormGroup>
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Next"
            onPrimaryButtonClick={() => {
              let hostnameErrorMessage = validateHostname(
                integrationCreationInfo?.metadata?.servicenow?.hostname || "",
                "Hostname"
              );
              if (hostnameErrorMessage) {
                setErrorMessage(`Error: ${hostnameErrorMessage}`);
                return;
              }
              setErrorMessage(null);
              setStepNumber(stepNumber + 1);
            }}
            secondaryButtonLabel={stepNumber > 1 ? "Back" : undefined}
            onSecondaryButtonClick={
              stepNumber > 1 ? () => setStepNumber(stepNumber - 1) : undefined
            }
          />
        </Modal>
      );
      break;
    case IntegrationCreationSteps.ServiceNowUserCredentials:
      modal = (
        <Modal
          title={getOrgTitle(thirdPartyProvider)}
          isOpen={true}
          onClose={onClose}
          subtitle={subtitleFormat(stepNumber, totalSteps)}
        >
          <Modal.Body>
            <p>
              To enable our ServiceNow integration, Opal requires the
              administrator of your ServiceNow instance to create a dedicated
              service user. API calls will be made on behalf of this user.
              <br />
            </p>{" "}
            <FormGroup label="User ID">
              <Input
                value={integrationCreationInfo.serviceNow.userId}
                onChange={(val) => {
                  setIntegrationCreationInfo(
                    produce(integrationCreationInfo, (draft) => {
                      draft.serviceNow.userId = val;
                    })
                  );
                }}
              />
            </FormGroup>
            <FormGroup label="User Password">
              <Input
                type="password"
                value={integrationCreationInfo.serviceNow.userPassword}
                onChange={(val) => {
                  setIntegrationCreationInfo(
                    produce(integrationCreationInfo, (draft) => {
                      draft.serviceNow.userPassword = val;
                    })
                  );
                }}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Next"
            onPrimaryButtonClick={() => setStepNumber(stepNumber + 1)}
            secondaryButtonLabel={stepNumber > 1 ? "Back" : undefined}
            onSecondaryButtonClick={
              stepNumber > 1 ? () => setStepNumber(stepNumber - 1) : undefined
            }
          />
        </Modal>
      );
      break;
    case IntegrationCreationSteps.ServiceNowCatalogItemId:
      modal = (
        <Modal
          title={getOrgTitle(thirdPartyProvider)}
          isOpen={true}
          onClose={onClose}
          subtitle={subtitleFormat(stepNumber, totalSteps)}
        >
          <Modal.Body>
            <p>
              Please create a catalog item for Opal in your ServiceNow instance
              to track requests coming from Opal.
            </p>
            <p>
              Once created, please enter the catalog item <b>Sys ID</b> below.
            </p>
            <FormGroup label="Catalog Item Sys ID">
              <Input
                value={
                  integrationCreationInfo.metadata?.servicenow?.catalogItemId
                }
                onChange={(val) => {
                  setIntegrationCreationInfo(
                    produce(integrationCreationInfo, (draft) => {
                      if (!draft.metadata) {
                        draft.metadata = {};
                      }
                      if (!draft.metadata.servicenow) {
                        draft.metadata.servicenow = {
                          hostname: "",
                          catalogItemId: "",
                        };
                      }
                      draft.metadata.servicenow.catalogItemId = val;
                    })
                  );
                }}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Next"
            onPrimaryButtonClick={() => setStepNumber(stepNumber + 1)}
            secondaryButtonLabel={stepNumber > 1 ? "Back" : undefined}
            onSecondaryButtonClick={
              stepNumber > 1 ? () => setStepNumber(stepNumber - 1) : undefined
            }
          />
        </Modal>
      );
      break;
    case IntegrationCreationSteps.ServiceNowClientCredentials:
      modal = (
        <Modal
          title={`Link ${integrationName}`}
          isOpen={true}
          onClose={onClose}
          subtitle={subtitleFormat(stepNumber, totalSteps)}
        >
          <Modal.Body>
            <p>
              Next, create an <b>OAuth application</b> for Opal in your
              ServiceNow instance with your desired configurations. Enter the
              following information when the application is created.
            </p>
            <FormGroup label={"Client ID"}>
              <Input
                value={integrationCreationInfo.serviceNow.clientId}
                onChange={(val) => {
                  setIntegrationCreationInfo(
                    produce(integrationCreationInfo, (draft) => {
                      draft.serviceNow.clientId = val;
                    })
                  );
                }}
              />
            </FormGroup>
            <FormGroup label={"Client Secret"}>
              <Input
                value={integrationCreationInfo.serviceNow.clientSecret}
                onChange={(val) => {
                  setIntegrationCreationInfo(
                    produce(integrationCreationInfo, (draft) => {
                      draft.serviceNow.clientSecret = val;
                    })
                  );
                }}
                type="password"
              />
            </FormGroup>
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Create"
            onPrimaryButtonClick={handleSubmit}
            primaryButtonLoading={loading}
            secondaryButtonLabel={stepNumber > 1 ? "Back" : undefined}
            onSecondaryButtonClick={
              stepNumber > 1 ? () => setStepNumber(stepNumber - 1) : undefined
            }
          />
        </Modal>
      );
      break;
    case IntegrationCreationSteps.JiraProjectName:
      modal = (
        <Modal
          title="Jira Project Name"
          isOpen={true}
          onClose={onClose}
          subtitle={subtitleFormat(stepNumber, totalSteps)}
        >
          <Modal.Body>
            <FormGroup
              label="Jira Project Name"
              infoTooltip={getProjectTooltip(thirdPartyProvider)}
            >
              <Input
                value={integrationCreationInfo.jiraProjectName}
                onChange={(val) => {
                  setIntegrationCreationInfo({
                    ...integrationCreationInfo,
                    jiraProjectName: val,
                    metadata: {
                      jira: {
                        projectName: val,
                        orgUrl:
                          integrationCreationInfo?.metadata?.jira?.orgUrl || "",
                        email:
                          integrationCreationInfo?.metadata?.jira?.email || "",
                      },
                    },
                  });
                }}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Next"
            onPrimaryButtonClick={() => setStepNumber(stepNumber + 1)}
            secondaryButtonLabel={stepNumber > 1 ? "Back" : undefined}
            onSecondaryButtonClick={
              stepNumber > 1 ? () => setStepNumber(stepNumber - 1) : undefined
            }
          />
        </Modal>
      );
      break;
    case IntegrationCreationSteps.JiraTokenEmail:
      modal = (
        <Modal
          title="Jira API Token Email"
          isOpen={true}
          onClose={onClose}
          subtitle={subtitleFormat(stepNumber, totalSteps)}
        >
          <Modal.Body>
            <p>
              To enable our Jira integration, Opal requires a Jira administrator
              account to proxy access. Please enter the email address of the
              Jira admin account you'd like to use.
            </p>
            <FormGroup label="Jira API Token Email">
              <Input
                value={integrationCreationInfo?.metadata?.jira?.email || ""}
                onChange={(val) => {
                  setIntegrationCreationInfo({
                    ...integrationCreationInfo,
                    metadata: {
                      jira: {
                        projectName:
                          integrationCreationInfo?.metadata?.jira
                            ?.projectName || "",
                        orgUrl:
                          integrationCreationInfo?.metadata?.jira?.orgUrl || "",
                        email: val,
                      },
                    },
                  });
                }}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Next"
            onPrimaryButtonClick={() => setStepNumber(stepNumber + 1)}
            secondaryButtonLabel={stepNumber > 1 ? "Back" : undefined}
            onSecondaryButtonClick={
              stepNumber > 1 ? () => setStepNumber(stepNumber - 1) : undefined
            }
          />
        </Modal>
      );
      break;
  }

  const isModalOpen =
    props.openThirdPartyProvider === props.integrationInfo.thirdPartyProvider;

  return (
    <div>
      <IntegrationButton
        integrationType={props.integrationType}
        integrationInfo={props.integrationInfo}
        isConnected={props.isConnected}
        setOpenThirdPartyProvider={props.setOpenThirdPartyProvider}
        buttonDisabled={props.connectionButtonDisabled}
      />
      {isModalOpen && modal}
    </div>
  );
};
