import { Maybe, useCreateTagMutation } from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { FormGroup, Input, Modal } from "components/ui";
import React, { useState } from "react";
import { logError } from "utils/logging";

const TagCreateModal = ({ onClose }: { onClose: () => void }) => {
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const [createTag, { loading }] = useCreateTagMutation();
  const { displaySuccessToast } = useToast();

  const handleClose = () => {
    setErrorMessage(null);
    setKey("");
    setValue("");
    onClose();
  };

  const handleSubmit = async () => {
    try {
      const { data } = await createTag({
        variables: {
          input: {
            key: key,
            value: value,
          },
        },
        refetchQueries: ["Tags", "TagsTable"],
        awaitRefetchQueries: true,
      });

      switch (data?.createTag.__typename) {
        case "CreateTagResult":
          handleClose();
          displaySuccessToast("Success: resource tag created");
          break;
        case "TagExistsError":
          logError(new Error(data.createTag.message));
          setErrorMessage(data.createTag.message);
          break;
        default:
          logError(new Error("failed to create resource tag"));
          setErrorMessage("Error: failed to create resource tag");
      }
    } catch (error) {
      logError(error, `failed to create tag`);
      setErrorMessage("Error: failed to create tag");
    }
  };

  return (
    <Modal isOpen onClose={handleClose} title="Create Tag">
      <Modal.Body>
        <FormGroup label="Tag Key:">
          <Input value={key} onChange={setKey} maxLength={255} />
        </FormGroup>
        <FormGroup label="Tag Value:">
          <Input value={value} onChange={setValue} maxLength={255} />
        </FormGroup>
        {errorMessage ? (
          <ModalErrorMessage errorMessage={errorMessage} />
        ) : null}
      </Modal.Body>
      <Modal.Footer
        onSecondaryButtonClick={handleClose}
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLabel={"Create"}
        primaryButtonLoading={loading}
        primaryButtonDisabled={!key}
      />
    </Modal>
  );
};

export default TagCreateModal;
