import { GroupAccessLevel, ResourceAccessLevel } from "api/generated/graphql";
import { TooltipPlacement } from "components/label/Label";
import { Icon, Label, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";

type Props = {
  // data-related props
  hasAccess: boolean;
  soonestExpiration: moment.Moment | null;
  roles: ResourceAccessLevel[] | GroupAccessLevel[];
  expirationByRoleId: Record<string, moment.Moment | null>;

  // style-related props
  showRoleName?: boolean;
};

const AccessLabel = ({
  hasAccess,
  soonestExpiration,
  roles,
  expirationByRoleId,
  ...props
}: Props) => {
  if (!hasAccess) {
    return <Label label="No access" color="gray700" />;
  }

  let rolesLabel = null;
  const hasNamedRoles = roles.some((role) => role.accessLevelName.length > 0);
  if (hasNamedRoles) {
    const roleNames = (
      <>
        {roles?.map((role) => {
          const expiration = expirationByRoleId[role.accessLevelRemoteId];
          if (expiration) {
            return (
              <div key={role.accessLevelRemoteId}>
                {role.accessLevelName} {`(expires ${expiration.fromNow()})`}
              </div>
            );
          }
          return (
            <div key={role.accessLevelRemoteId}>{role.accessLevelName}</div>
          );
        })}
      </>
    );
    // Note that we're going to use a Tooltip with two elements in it rather
    // than a Label because the tooltip will only show on the text (which is
    // rather short) rather than the icon itself.
    // Modifying Label any further might break other use cases.
    rolesLabel = (
      <Tooltip placement={TooltipPlacement.Top} arrow tooltipText={roleNames}>
        <span
          className={sprinkles({
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            color: "gray700",
            gap: "xs",
            textOverflow: "ellipsis",
            overflow: "hidden",
          })}
        >
          <span>
            {/* This span makes sure that the svg doesn't resize with the space
            that's given to this "label" */}
            <Icon name="role" size="sm" />
          </span>
          <span
            className={sprinkles({
              textOverflow: "ellipsis",
              overflow: "hidden",
            })}
          >
            {props.showRoleName && roles.length === 1
              ? roles[0].accessLevelName
              : `${roles.length}`}
          </span>
        </span>
      </Tooltip>
    );
  }

  const getAccessLabelProps = (): {
    label: string;
    color: PropsFor<typeof Label>["color"];
    icon: PropsFor<typeof Label>["icon"];
    iconColor?: PropsFor<typeof Label>["iconColor"];
  } => {
    const twoDaysFromNow = moment().add(2, "days");
    const isExpired = soonestExpiration?.isBefore(moment()) ?? false;
    const isExpiringSoon = soonestExpiration?.isBefore(twoDaysFromNow) ?? false;
    // Expired
    if (isExpired) {
      return {
        label: "Expired",
        color: "gray700",
        icon: { type: "name", icon: "x-circle" },
      };
    }
    // About to expire in 2 days
    if (isExpiringSoon) {
      return {
        label: `Expiring ${soonestExpiration?.fromNow()}`,
        color: "red600",
        icon: { type: "name", icon: "alarm-clock" },
      };
    }
    return {
      label: soonestExpiration
        ? `Access expires ${soonestExpiration.fromNow()}`
        : "Access",
      color: "gray700",
      iconColor: "green600",
      icon: { type: "name", icon: "check-circle" },
    };
  };

  const accessLabel = <Label {...getAccessLabelProps()} inline oneLine />;

  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "row",
        gap: "sm",
        alignItems: "center",
        overflow: "hidden",
      })}
    >
      <span className={sprinkles({ display: "flex" })}>{accessLabel}</span>
      {rolesLabel}
    </div>
  );
};

export default AccessLabel;
