import {
  ConnectionType,
  EntityType,
  ResourceType,
} from "api/generated/graphql";
import { GroupType } from "api/generated/graphql";
import oktaLogo from "assets/icons/okta-app.svg";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { BreadcrumbsV3, Icon, PopoverV3 } from "components/ui";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import { getResourceUrlNew } from "utils/common";
import { useGetResourceBreadcrumbs } from "utils/hooks";

interface Props {
  entity: EntityCellDetails;
  openInNewTab?: boolean;
  hideAvatar?: boolean;
}

export interface EntityCellDetails {
  id: String;
  type: EntityType;
  name: String;
  resourceType?: ResourceType;
  iconUrl?: String;
  groupType?: GroupType;
  connectionType?: ConnectionType;
}

const getEntityIcon = (entity: EntityCellDetails) => {
  if (entity.type === EntityType.Resource) {
    if (entity.resourceType == ResourceType.OktaApp) {
      return {
        type: "src",
        icon: entity.iconUrl || oktaLogo,
      };
    }
  }
  return {
    type: "src",
    icon: getConnectionTypeInfo(entity.connectionType)?.icon,
  };
};

const getEntityPopoverContent = (
  entity: EntityCellDetails,
  hideAvatar: boolean | undefined,
  breadcrumbs: { name: string; to: string }[],
  breadcrumbsLoading: boolean
) => {
  var iconName = getEntityIcon(entity);
  return (
    <div
      className={sprinkles({
        display: "flex",
        alignItems: "center",
        gap: "md",
      })}
    >
      {!hideAvatar && <Icon data={iconName as IconData} size="lg" />}
      <div
        className={sprinkles({
          display: "flex",
          flexDirection: "column",
        })}
      >
        <div className={sprinkles({ fontSize: "bodySm" })}>
          {!breadcrumbsLoading && (
            <BreadcrumbsV3 breadcrumbInfos={breadcrumbs} linkColor={"grey"} />
          )}
        </div>
        <div className={sprinkles({ fontSize: "bodyLg" })}>{entity.name}</div>
      </div>
    </div>
  );
};

const EntityCell = ({ entity, openInNewTab, hideAvatar }: Props) => {
  const {
    data: breadcrumbs,
    error: breadcrumbsError,
    loading: breadcrumbsLoading,
  } = useGetResourceBreadcrumbs({
    id: entity.id.toString(),
  });

  return (
    <div
      className={sprinkles({
        display: "flex",
        alignItems: "center",
        gap: "sm",
      })}
    >
      <PopoverV3
        position="left"
        content={getEntityPopoverContent(
          entity,
          hideAvatar,
          breadcrumbs,
          breadcrumbsLoading
        )}
        enabled={!breadcrumbsError}
      >
        <a
          href={getResourceUrlNew({
            entityId: entity.id.toString(),
            entityType: entity.type,
          })}
          target={openInNewTab ? "_blank" : ""}
          rel="noopener noreferrer"
        >
          {entity.name}
        </a>
      </PopoverV3>
    </div>
  );
};
export default EntityCell;
