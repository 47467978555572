import {
  ConnectionPreviewSmallFragment,
  EntityType,
  ResourcePreviewSmallFragment,
  useConnectionsQuery,
  useNonHumanIdentityResourcesQuery,
} from "api/generated/graphql";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import { ResourceLabel } from "components/label/Label";
import OpalPage from "components/opal/layout/OpalPage";
import { Input, Select } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableFilters from "components/ui/table/TableFilters";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import pluralize from "pluralize";
import { getResourceUrlNew } from "utils/common";
import { nhiConnectionTypes } from "utils/directory/connections";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useURLSearchParam } from "utils/router/hooks";
import {
  CONNECTION_ID_URL_KEY,
  SEARCH_QUERY_URL_KEY,
} from "views/apps/AppsContext";

import * as styles from "./NonHumanIdentitiesColumn.css";

interface NonHumanIdentityRow {
  id: string;
  name: string;
  remoteName: string;
  resourceCount: number;
  createdAt: string;
  resource: ResourcePreviewSmallFragment;
  connection?: ConnectionPreviewSmallFragment | null;
}

const NON_HUMAN_IDENTITY_COLUMNS: Header<NonHumanIdentityRow>[] = [
  {
    id: "name",
    label: "Name",
    sortable: false,
    customCellRenderer: (row) => {
      return (
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
            fontWeight: "medium",
            whiteSpace: "nowrap",
          })}
        >
          <ResourceLabel
            text={row.name}
            pointerCursor={true}
            entityId={row.resource.id}
            entityTypeNew={EntityType.Resource}
            resourceType={row.resource.resourceType}
          />
        </div>
      );
    },
  },
  {
    id: "remoteName",
    label: "Remote Name",
    sortable: false,
    customCellRenderer: (row) => {
      return <div className={styles.nameField}>{row.remoteName}</div>;
    },
    width: 200,
  },
  {
    id: "connection",
    label: "App",
    sortable: false,
    width: 100,
    customCellRenderer: (row) => {
      if (!row.connection) {
        return "--";
      }
      return (
        <div
          onClick={(e) => e.stopPropagation()}
          className={sprinkles({
            display: "flex",
            gap: "sm",
            fontWeight: "medium",
            whiteSpace: "nowrap",
          })}
        >
          <ResourceLabel
            text={row.connection.name}
            pointerCursor={true}
            entityId={row.connection.id}
            entityTypeNew={EntityType.Connection}
            connectionType={row.connection.connectionType}
          />
        </div>
      );
    },
  },
  {
    id: "resourceCount",
    label: "Resources",
    sortable: false,
    width: 75,
    customCellRenderer: (row) => {
      return (
        <div
          className={sprinkles({
            color: row.resourceCount > 0 ? undefined : "gray600",
          })}
        >
          {`${row.resourceCount || "No"} ${pluralize(
            "Resource",
            row.resourceCount
          )}`}
        </div>
      );
    },
  },
  {
    id: "createdAt",
    label: "Created",
    sortable: false,
    width: 75,
    customCellRenderer: (row) => {
      return <div>{moment(row.createdAt).fromNow()}</div>;
    },
  },
];

const NonHumanIdentitiesColumn = () => {
  const [searchQueryParam, setSearchQuery] = useURLSearchParam(
    SEARCH_QUERY_URL_KEY
  );
  const debouncedSearchQuery = useDebouncedValue(searchQueryParam, 300);

  const [connectionIdParam, setConnectionIdParam] = useURLSearchParam(
    CONNECTION_ID_URL_KEY
  );

  const {
    data: resourcesData,
    error: resourcesError,
    loading: resourcesLoading,
    fetchMore,
  } = useNonHumanIdentityResourcesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        searchQuery: debouncedSearchQuery,
        nonHumanIdentitiesOnly: true,
        connectionIds: connectionIdParam ? [connectionIdParam] : undefined,
      },
    },
  });

  const {
    data: connectionsData,
    error: connectionsError,
  } = useConnectionsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        connectionTypes: Array.from(nhiConnectionTypes),
      },
    },
  });

  if (resourcesError) {
    logError(
      resourcesError,
      `failed to list resources home: non-human identities due to failure loading resources`
    );
  }

  if (connectionsError) {
    logError(
      connectionsError,
      `failed to list resources home: non-human identities due to failure loading connections`
    );
  }

  const connections = connectionsData?.connections?.connections ?? [];
  const selectedConnection = connections?.find(
    (connection) => connection.id === connectionIdParam
  );

  const loading = resourcesLoading && !resourcesData;
  const resources = resourcesData?.resources.resources ?? [];
  const cursor = resourcesData?.resources.cursor;
  const totalNumResources = resourcesData?.resources.totalNumResources ?? 0;
  const rows: NonHumanIdentityRow[] = resources.map((resource) => {
    return {
      id: resource.id,
      name: resource.name,
      remoteName: resource.remoteName ?? "",
      resourceCount: resource.numEntityAssignmentsForPrincipal,
      createdAt: resource.createdAt,
      resource: resource,
      connection: resource.connection,
    };
  });

  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            input: {
              cursor,
              searchQuery: debouncedSearchQuery,
              nonHumanIdentitiesOnly: true,
            },
          },
        });
      }
    : undefined;

  return (
    <OpalPage title="Non-human Identities" icon="service-account">
      <TableFilters>
        <TableFilters.Left>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQueryParam || ""}
              onChange={(query) => {
                query.length > 0 ? setSearchQuery(query) : setSearchQuery(null);
              }}
              placeholder="Filter by name"
            />
          </div>
          {/* First wrapper div, constraints the second wrapper div so it does not take up full width */}
          <div>
            {/* Second wrapper div, makes the select 34px wider to offset the fact that clearable selects are 34px smaller */}
            <div className={styles.connectionInput}>
              <Select
                options={connections}
                onChange={(connection) =>
                  setConnectionIdParam(connection?.id ?? null)
                }
                getOptionLabel={(connection) => connection.name}
                getIcon={(connection) => ({
                  type: "entity",
                  entityType: connection.connectionType,
                })}
                placeholder="App"
                value={selectedConnection}
                clearable
                popperForceDownward
                size="sm"
              />
            </div>
          </div>
        </TableFilters.Left>
      </TableFilters>
      <TableHeader
        entityName="Non-human Identity"
        totalNumRows={totalNumResources}
        loading={loading}
      />
      {loading ? (
        <ColumnListItemsSkeleton />
      ) : (
        <Table
          rows={rows}
          totalNumRows={totalNumResources ?? Number.MAX_SAFE_INTEGER}
          getRowId={(ru) => ru.id}
          columns={NON_HUMAN_IDENTITY_COLUMNS}
          loadingRows={loading}
          onLoadMoreRows={loadMoreRows}
          onRowClickTransitionTo={(row) =>
            getResourceUrlNew({
              entityId: row.resource.id,
              entityType: EntityType.Resource,
            })
          }
        />
      )}
    </OpalPage>
  );
};

export default NonHumanIdentitiesColumn;
