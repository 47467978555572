import {
  ResourceAccessLevel,
  ResourcePreviewWithAccessLevelsFragment,
  useResourcePreviewWithAccessLevelsQuery,
} from "api/generated/graphql";
import { Button, EntityIcon, Icon, Select, Skeleton } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { ExpirationValue } from "views/requests/utils";

import * as styles from "./AddItemModal.css";
import ModalErrorMessage from "./ModalErrorMessage";

interface RowProps {
  resourceId: string;
  existingRoles: ResourceAccessLevel[];
  roles: ResourceAccessLevel[];
  onResourceRemove: () => void;
  onRoleSelect: (role: ResourceAccessLevel) => void;
  onRoleRemove: (role: ResourceAccessLevel) => void;
  expirationValue?: ExpirationValue;
  onExpirationValueChange?: (value: ExpirationValue) => void;
  error?: string;
}

const ResourceRoleRow = (props: RowProps) => {
  const {
    existingRoles: existingRoles,
    roles: roles,
    expirationValue,
    onExpirationValueChange,
  } = props;
  const { data, loading, error } = useResourcePreviewWithAccessLevelsQuery({
    variables: {
      input: {
        id: props.resourceId,
      },
    },
  });

  if (loading) {
    return <Skeleton height="44px" />;
  }

  let resource: ResourcePreviewWithAccessLevelsFragment | undefined;
  switch (data?.resource.__typename) {
    case "ResourceResult":
      resource = data.resource.resource;
      break;
  }

  if (error || !resource) {
    return null;
  }

  const allRoles = resource.accessLevels ?? [];

  const hasRoles =
    allRoles.length > 0 && allRoles[0].accessLevelRemoteId !== "";

  const options = allRoles.filter((role) => {
    return ![...existingRoles, ...roles].some(
      (existingRole) =>
        existingRole.accessLevelRemoteId === role.accessLevelRemoteId
    );
  });

  return (
    <div>
      <div className={styles.itemRow}>
        <EntityIcon type={resource.resourceType} />
        <div className={sprinkles({ flexGrow: 1 })}>
          <div className={sprinkles({ fontSize: "labelMd" })}>
            {resource.name}
          </div>
          <div className={sprinkles({ color: "gray500", fontSize: "labelSm" })}>
            {resource.parentResource
              ? `${resource.parentResource.name} / `
              : ""}
            {resource.connection?.name}
          </div>
        </div>
        <Button
          leftIconName="trash-2"
          borderless
          type="error"
          onClick={props.onResourceRemove}
          size="sm"
          round
        />
      </div>
      {hasRoles && (
        <div
          className={sprinkles({
            padding: "sm",
            marginTop: "sm",
            backgroundColor: "gray100",
            borderRadius: "md",
          })}
        >
          <Select
            options={options}
            placeholder="Select a role"
            onChange={(opt) => {
              if (opt) {
                props.onRoleSelect(opt);
              }
            }}
            getOptionLabel={(opt) => opt.accessLevelName}
            selectOnly
          />

          {roles
            .filter((role) => Boolean(role.accessLevelRemoteId))
            .map((role) => (
              <div
                className={sprinkles({
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingX: "xs",
                  marginTop: "xs",
                  fontSize: "labelMd",
                })}
              >
                {role.accessLevelName}
                <Icon
                  name="x"
                  size="xs"
                  color="gray600"
                  onClick={() => props.onRoleRemove(role)}
                />
              </div>
            ))}
        </div>
      )}
      {expirationValue && onExpirationValueChange && (
        <div
          className={sprinkles({
            padding: "sm",
            marginTop: "sm",
            backgroundColor: "gray100",
            borderRadius: "md",
          })}
        >
          <Select
            options={Object.values(ExpirationValue)}
            placeholder="Select an expiration value"
            onChange={(opt) => {
              if (opt) {
                onExpirationValueChange(opt);
              }
            }}
            value={expirationValue}
            getOptionLabel={(opt) => opt}
          />
        </div>
      )}
      {props.error && <ModalErrorMessage errorMessage={props.error} />}
    </div>
  );
};

export default ResourceRoleRow;
