import { AccessOption, AppCategory } from "api/generated/graphql";
import ButtonGroupV3 from "components/ui/buttongroup/ButtonGroupV3";
import Select from "components/ui/select/Select";
import { useURLSearchParamAsEnum } from "utils/router/hooks";
import { useAccessOptionKey } from "views/apps/utils";
import { categoryInfoByCategory } from "views/connections/create/BrowseServices";

const InventoryAppsFilters = () => {
  const [accessOptionKey, setAccessOptionKey] = useAccessOptionKey();
  const [category, setCategory] = useURLSearchParamAsEnum(
    "category",
    AppCategory,
    AppCategory.All
  );
  return (
    <>
      <ButtonGroupV3
        buttons={[
          {
            label: "All",
            onClick: () => setAccessOptionKey(AccessOption.All),
            selected: accessOptionKey === AccessOption.All,
          },
          {
            label: "My Access",
            onClick: () => setAccessOptionKey(AccessOption.Mine),
            selected: accessOptionKey === AccessOption.Mine,
          },
        ]}
      />
      <Select
        value={categoryInfoByCategory[category]}
        options={Object.values(categoryInfoByCategory).filter(
          (info) => info.category != AppCategory.All
        )}
        getOptionLabel={(info) => info.name.slice(0, 17)}
        getIcon={(info) => info.icon || undefined}
        getBrandIcon={(info) => info.brandIcon}
        onChange={(info) =>
          info ? setCategory(info?.category) : setCategory(AppCategory.All)
        }
        placeholder="App Category"
        size="sm"
        clearable={category != AppCategory.All}
      />
    </>
  );
};

export default InventoryAppsFilters;
