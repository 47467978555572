import AuthContext from "components/auth/AuthContext";
import { ActionIcon } from "components/column/ColumnHeaderV3";
import { ButtonV3 } from "components/ui";
import { useContext } from "react";

const OpalHeaderExtraActions = (props: { actionIcons: ActionIcon[] }) => {
  const { authState } = useContext(AuthContext);
  const { actionIcons } = props;
  return (
    <>
      {actionIcons
        .map((iconConfig, idx) => {
          if (
            iconConfig.adminOnly === true &&
            authState.user?.isAdmin === false
          ) {
            return null;
          }
          return (
            <ButtonV3
              label={iconConfig.label}
              leftIconName={iconConfig.iconName}
              onClick={iconConfig.onClick}
              sublabel={iconConfig.sublabel}
              size="lg"
              key={idx}
            />
          );
        })
        .filter(Boolean)}
    </>
  );
};

export default OpalHeaderExtraActions;
