import { formatTimestampForDisplay } from "api/common/common";
import {
  EntityType,
  EventPreviewFragment,
  Maybe,
  useEventsQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnListItem, {
  ColumnListItemsSkeleton,
} from "components/column/ColumnListItem";
import ColumnListScroller from "components/column/ColumnListScroller";
import moment from "moment";
import { useHistory, useParams } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { eventTypeToString } from "utils/events";
import { logError } from "utils/logging";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { getEventFilterEndDateInput, useEventFilter } from "views/events/utils";

const EventsRow = () => {
  const eventFilter = useEventFilter();
  const { eventId } = useParams<Record<string, string>>();
  const history = useHistory();

  const {
    data: eventsData,
    error: eventsError,
    loading: eventsLoading,
    fetchMore: fetchMoreEvents,
  } = useEventsQuery({
    variables: {
      input: {
        filter: {
          ...eventFilter,
          endDate: eventFilter.endDate?.date
            ? {
                date:
                  getEventFilterEndDateInput(eventFilter.endDate.date) ?? "",
              }
            : undefined,
        },
      },
    },
  });

  let events: EventPreviewFragment[] = [];
  let cursor: Maybe<string> = null;
  let filterError: string | null = null;
  switch (eventsData?.events.__typename) {
    case "EventsResult":
      events = eventsData.events.events.map((event) => ({
        ...event,
        createdAt: formatTimestampForDisplay(event.createdAt),
      }));
      cursor = eventsData.events.cursor ?? null;
      break;
    case "InvalidObjectIDFilterError":
      filterError = "Error: Object ID is not a valid UUID";
  }
  if (eventsError) {
    logError(eventsError, `failed to list events`);
    return (
      <Column isContent>
        <UnexpectedErrorPage error={eventsError} />
      </Column>
    );
  }

  const renderRow = (index: number) => {
    const sortedEvents = events
      .slice()
      .sort(
        (a, b) =>
          new Date(b.createdAt).valueOf() - new Date(a.createdAt).valueOf()
      );
    const event = sortedEvents[index];
    if (!event) {
      return <></>;
    }

    const createdAtTime = moment(new Date(event.createdAt));

    const eventLabel = eventTypeToString(event.eventType, true);
    const timeLabel = createdAtTime.fromNow();
    const actorLabel = event.actorUser?.fullName ?? event.actorUserId;
    const sublabel = `${actorLabel} • ${timeLabel}`;

    return (
      <ColumnListItem
        key={event.id}
        label={eventLabel}
        sublabel={sublabel}
        icon={{
          type: "src",
          icon: event.actorUser?.avatarUrl,
          style: "rounded",
        }}
        selected={event.id === eventId}
        onClick={() => {
          history.push(
            getResourceUrlNew({
              entityId: event.id,
              entityType: EntityType.Event,
            }) + location.search
          );
        }}
      />
    );
  };

  const loadMore = cursor
    ? async () => {
        await fetchMoreEvents({
          variables: {
            input: {
              cursor,
              filter: eventFilter,
            },
          },
        });
      }
    : undefined;

  return eventsLoading && !eventsData ? (
    <ColumnListItemsSkeleton />
  ) : (
    <ColumnListScroller
      renderRow={renderRow}
      numRows={events.length}
      onLoadMore={loadMore}
      hasNextPage={cursor != null}
      loading={eventsLoading}
      emptyState={
        filterError
          ? {
              title: filterError,
            }
          : undefined
      }
    />
  );
};

export default EventsRow;
