import {
  EntityType,
  Maybe,
  TagPreviewLargeFragment,
  useDeleteTagsMutation,
} from "api/generated/graphql";
import ConfirmModal from "components/modals/update/ConfirmModal";
import { tagDisplayName } from "components/tags/Tags";
import { useToast } from "components/toast/Toast";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";

type TagDeleteModalProps = {
  tag: TagPreviewLargeFragment;
  showDeleteTagModal: boolean;
  setShowDeleteTagModal: (showModal: boolean) => void;
};

const TagDeleteModal = (props: TagDeleteModalProps) => {
  const history = useHistory();

  const { displaySuccessToast } = useToast();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [deleteTags, { loading }] = useDeleteTagsMutation();

  const deleteModalReset = () => {
    props.setShowDeleteTagModal(false);
    setErrorMessage(null);
  };

  const onSubmit = async () => {
    try {
      const { data } = await deleteTags({
        variables: {
          input: {
            ids: [props.tag.id],
          },
        },
        update: (cache, { data }) => {
          switch (data?.deleteTags.__typename) {
            case "DeleteTagsResult": {
              data.deleteTags.entries.forEach((entry) => {
                cache.evict({
                  id: cache.identify(entry.tag),
                });
              });
            }
          }
        },
      });
      switch (data?.deleteTags.__typename) {
        case "DeleteTagsResult":
          deleteModalReset();
          displaySuccessToast("Success: tag deleted");

          history.replace(
            getResourceUrlNew({
              entityId: null,
              entityType: EntityType.Tag,
            })
          );
          break;
        default:
          logError(new Error(`failed to delete tag`));
          setErrorMessage(`Error: failed to delete tag`);
      }
    } catch (error) {
      logError(error, `failed to delete tag`);
      setErrorMessage(`Error: failed to delete tag`);
    }
  };

  return (
    <ConfirmModal
      key={"delete_modal"}
      title={`Delete "${tagDisplayName(props.tag)}"`}
      message={`Are you sure you want to delete "${tagDisplayName(
        props.tag
      )}"? This cannot be undone.`}
      isModalOpen={props.showDeleteTagModal}
      onClose={() => {
        deleteModalReset();
      }}
      onSubmit={onSubmit}
      loading={loading}
      errorMessage={errorMessage}
    />
  );
};

export default TagDeleteModal;
