import {
  GroupDropdownPreviewFragment,
  GroupType,
  GroupTypeWithCountFragment,
  SearchGroupPreviewFragment,
  useGroupTypesWithCountsQuery,
  usePaginatedGroupDropdownQuery,
  useSearchGroupsQuery,
} from "api/generated/graphql";
import {
  getGroupTypeInfo,
  getGroupTypeName,
} from "components/label/GroupTypeLabel";
import { Select } from "components/ui";
import { IconData } from "components/ui/utils";
import React from "react";
import { AuthorizedActionManage } from "utils/auth/auth";
import { logError } from "utils/logging";

import EntityCountOption from "./EntityCountOption";

type EntitySelectorPage = "groupTypes" | "groups";

const PAGE_SIZE = 100;
const SEARCH_INITIAL_SECTION_SIZE = 3;

type SearchGroup = "groups";

type SearchID = { groupId: string };

type SearchOption = {
  id: SearchID;
  name: string;
  group: SearchGroup;
  icon: IconData;
  groupType: GroupType;
  connectionId: string;
  connectionName?: string;
  canManage: boolean;
};

interface NavigationStackItem {
  page: EntitySelectorPage;
  pageTitle: string;
  groupType?: GroupType;
  connectionFilter?: string[];
}

export interface GroupSelectData {
  id: string;
  name: string;
  groupType: GroupType;
  connectionId: string;
}

interface SelectData {
  actionType?: "select-option" | "remove-option";
  groups: GroupSelectData[];
}

const optionToGroupSelectData = (option: SearchOption): GroupSelectData => ({
  id: option.id.groupId,
  name: option.name,
  groupType: option.groupType,
  connectionId: option.connectionId,
});

const fragmentToGroupSelectData = (
  fragment: GroupDropdownPreviewFragment
): GroupSelectData => ({
  id: fragment.id,
  name: fragment.name,
  groupType: fragment.groupType,
  connectionId: fragment.connectionId,
});

export const mergeGroupSelectData = <T extends object>(
  prev: Record<string, T[]>,
  next: GroupSelectData[]
): Record<string, T[]> => {
  const newGroups = next.reduce((acc, group) => {
    return { ...acc, [group.id]: [] };
  }, {});
  return { ...prev, ...newGroups };
};

export const removeGroupSelectData = <T extends object>(
  prev: Record<string, T[]>,
  next: GroupSelectData[]
): Record<string, T[]> => {
  next.forEach((group) => {
    delete prev[group.id];
  });
  return { ...prev };
};

interface Props {
  selectedGroupIds: string[];
  onSelect: (data: SelectData) => void;
  disabledGroupIds?: string[];
  disabledGroupTypes?: GroupType[];
  disabledConnectionIds?: string[];
  disableForNonAdmin?: boolean;
  style?: PropsFor<typeof Select>["style"];
  placeholder?: string;
  placeholderIcon?: IconData;
  alwaysShowPlaceholder?: boolean;
  limitSelectedGroups?: number;
  size?: PropsFor<typeof Select>["size"];
  popperForceDownward?: PropsFor<typeof Select>["popperForceDownward"];
  popperHeight?: PropsFor<typeof Select>["popperHeight"];
}

const GroupSearchDropdown = (props: Props) => {
  const [navigationStack, setNavigationStack] = React.useState<
    NavigationStackItem[]
  >([{ page: "groupTypes", pageTitle: "Groups" }]);

  const popNavigationStack = () => {
    if (navigationStack.length === 1) {
      return;
    }
    const newStack = navigationStack.slice();
    newStack.pop();
    setNavigationStack(newStack);
  };

  const pushNavigationStack = (newPage: NavigationStackItem) => {
    setNavigationStack([...navigationStack, newPage]);
  };

  const currentNavigationItem = navigationStack[navigationStack.length - 1];
  const page = currentNavigationItem.page;
  const pageTitle = currentNavigationItem.pageTitle;

  const [searchQuery, setSearchQuery] = React.useState("");

  const {
    data: groupsData,
    error: groupsError,
    fetchMore: groupsFetchMore,
    loading: groupsLoading,
  } = usePaginatedGroupDropdownQuery({
    variables: {
      input: {
        connectionIds: currentNavigationItem.connectionFilter,
        groupType: currentNavigationItem.groupType,
        maxNumEntries: PAGE_SIZE,
      },
    },
    skip: page !== "groups",
  });
  if (groupsError) {
    logError(groupsError, "failed to list groups");
  }

  let groupsCursor: string | null | undefined;
  let groups: GroupDropdownPreviewFragment[] = [];
  switch (groupsData?.groups.__typename) {
    case "GroupsResult":
      groups = groupsData.groups.groups;
      groupsCursor = groupsData.groups.cursor;
      break;
    default:
      break;
  }

  const {
    data: groupTypesData,
    error: groupTypesError,
    loading: groupTypesLoading,
  } = useGroupTypesWithCountsQuery({
    variables: {
      input: {},
    },
    skip: page !== "groupTypes",
  });

  if (groupTypesError) {
    logError(groupTypesError, "failed to list group types");
  }

  let groupTypes: GroupTypeWithCountFragment[] = [];
  switch (groupTypesData?.groupTypesWithCounts.__typename) {
    case "GroupTypesWithCountsResult":
      groupTypes = groupTypesData.groupTypesWithCounts.groupTypesWithCounts
        .slice()
        .sort((a, b) => {
          return getGroupTypeName(a).toLowerCase() >
            getGroupTypeName(b).toLowerCase()
            ? 1
            : -1;
        });
      break;
    default:
      break;
  }

  const {
    data: searchGroupsData,
    error: searchGroupsError,
    fetchMore: searchGroupsFetchMore,
  } = useSearchGroupsQuery({
    variables: {
      query: searchQuery,
      maxNumEntries: SEARCH_INITIAL_SECTION_SIZE,
    },

    skip: searchQuery === "",
  });
  if (searchGroupsError) {
    logError(searchGroupsError, "failed to execute groups search");
  }

  let searchGroupsCursor: string | null | undefined;
  let searchGroups: SearchGroupPreviewFragment[] = [];
  switch (searchGroupsData?.groups.__typename) {
    case "GroupsResult":
      searchGroups = searchGroupsData.groups.groups;
      searchGroupsCursor = searchGroupsData.groups.cursor;
      break;
    default:
      break;
  }

  const sharedProps = {
    style: props.style,
    placeholder: props.placeholder ?? "Search",
    placeholderIcon: props.placeholderIcon,
    alwaysShowPlaceholder: props.alwaysShowPlaceholder,
    onInputChange: setSearchQuery,
    disableBuiltInFiltering: true,
    size: props.size,
    popperForceDownward: props.popperForceDownward,
    popperHeight: props.popperHeight,
  };

  const disableUnselected = (groupId: string): boolean => {
    if (
      props.limitSelectedGroups !== undefined &&
      props.selectedGroupIds.length >= props.limitSelectedGroups
    ) {
      return !props.selectedGroupIds.includes(groupId);
    }
    return false;
  };

  if (searchQuery !== "") {
    const searchOptions: SearchOption[] = searchGroups.map((searchGroup) => ({
      id: { groupId: searchGroup.id },
      name: searchGroup.name,
      groupType: searchGroup.groupType,
      connectionId: searchGroup.connection?.id ?? "",
      connectionName: searchGroup.connection?.name,
      group: "groups",
      icon: {
        type: "entity",
        entityType: searchGroup.groupType,
      },
      canManage:
        searchGroup.authorizedActions?.includes(AuthorizedActionManage) ??
        false,
    }));

    return (
      <Select<typeof searchOptions[0]>
        {...sharedProps}
        value={searchOptions.filter((searchOption) => {
          return props.selectedGroupIds.includes(searchOption.id.groupId);
        })}
        multiple
        options={searchOptions}
        getOptionLabel={(option) => option.name}
        getOptionDisabled={(option) => {
          return (
            props.disabledGroupIds?.includes(option.id.groupId) ||
            props.disabledGroupTypes?.includes(option.groupType) ||
            props.disabledConnectionIds?.includes(option.connectionId) ||
            (props.disableForNonAdmin && !option.canManage) ||
            disableUnselected(option.id.groupId) ||
            false
          );
        }}
        getIcon={(option) => option.icon}
        groupBy={(option) => option.group}
        groupHasLoadMore={(groupName) => {
          if (groupName === "groups") {
            return Boolean(searchGroupsCursor);
          }
          return false;
        }}
        renderOptionLabel={(option) => (
          <EntityCountOption
            name={option.name}
            parentResourceName={option.connectionName}
          />
        )}
        onGroupLoadMore={(groupName) => {
          if (groupName === "groups") {
            searchGroupsFetchMore({
              variables: {
                resourcesCursor: searchGroupsCursor,
                maxNumEntries: PAGE_SIZE,
              },
            });
          }
        }}
        onSelectValue={(option, reason) => {
          if (
            option &&
            (reason === "select-option" || reason === "remove-option")
          ) {
            props.onSelect({
              actionType: reason,
              groups: [optionToGroupSelectData(option)],
            });
          }
        }}
        onBulkSelectValue={(options, reason) => {
          if (
            options &&
            (reason === "select-option" || reason === "remove-option")
          ) {
            props.onSelect({
              actionType: reason,
              groups: options.map(optionToGroupSelectData),
            });
          }
        }}
      />
    );
  }

  const anyLoading = groupTypesLoading || groupsLoading;

  if (page === "groups") {
    return (
      <Select<GroupDropdownPreviewFragment>
        {...sharedProps}
        multiple
        value={props.selectedGroupIds
          ?.map(
            (filterGroupId) =>
              groups.find((group) => group.id === filterGroupId)!
          )
          .filter((group) => group)}
        listboxHeader={{
          title: pageTitle,
          onGoBack: popNavigationStack,
        }}
        options={groups}
        onSelectValue={(option, reason) => {
          if (!option) {
            return;
          }

          if (reason === "select-option" || reason === "remove-option") {
            props.onSelect({
              actionType: reason,
              groups: [fragmentToGroupSelectData(option)],
            });
          }
        }}
        onBulkSelectValue={(option, reason) => {
          if (
            option &&
            (reason === "select-option" || reason === "remove-option")
          ) {
            props.onSelect({
              actionType: reason,
              groups: option.map(fragmentToGroupSelectData),
            });
          }
        }}
        getOptionLabel={(option) => option.name}
        renderOptionLabel={(option) => (
          <EntityCountOption
            name={option.name}
            count={option.numGroupUsers}
            entityType="users"
          />
        )}
        getOptionDisabled={(option) => {
          return (
            props.disabledGroupIds?.includes(option.id) ||
            (props.disableForNonAdmin &&
              !option.authorizedActions?.includes(AuthorizedActionManage)) ||
            disableUnselected(option.id) ||
            false
          );
        }}
        getIcon={(option) => ({
          type: "entity",
          entityType: option.groupType,
        })}
        onScrollToBottom={async () => {
          if (groupsCursor) {
            await groupsFetchMore({
              variables: {
                input: {
                  cursor: groupsCursor,
                  maxNumEntries: PAGE_SIZE,
                },
              },
            });
          }
        }}
        loading={anyLoading}
      />
    );
  }

  return (
    <Select<GroupTypeWithCountFragment>
      {...sharedProps}
      options={groupTypes}
      getOptionLabel={(option) => option.groupType}
      renderOptionLabel={(option) => (
        <EntityCountOption
          name={getGroupTypeName(option)}
          count={option.numGroups}
          entityType={getGroupTypeInfo(option.groupType)?.name || "group"}
        />
      )}
      getIcon={(option) => ({
        type: "entity",
        entityType: option.groupType,
      })}
      onChange={() => {}}
      optionHasDrilldown={() => true}
      getOptionDisabled={(option) =>
        props.disabledGroupTypes?.includes(option.groupType) ||
        (option.connectionId &&
          props.disabledConnectionIds?.includes(option.connectionId)) ||
        false
      }
      onDrilldown={(option) => {
        if (!option.connectionId) {
          return;
        }
        pushNavigationStack({
          page: "groups",
          pageTitle: option.connection?.name ?? "",
          groupType: option.groupType,
          connectionFilter: [option.connectionId],
        });
      }}
      loading={anyLoading}
    />
  );
};

export default GroupSearchDropdown;
