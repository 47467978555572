import { ConnectionPreviewSmallFragment, Maybe } from "api/generated/graphql";
import { connectionTypeInfoByType } from "components/label/ConnectionTypeLabel";
import { Label } from "components/ui";
import { SectionEntry } from "components/viewer/Viewer";
import React from "react";

type ConnectionTypeRowProps = {
  connection?: Maybe<ConnectionPreviewSmallFragment>;
};

export const ConnectionTypeRow = (props: ConnectionTypeRowProps) => {
  if (!props.connection) return null;
  return (
    <SectionEntry title={"Type"}>
      <Label
        label={connectionTypeInfoByType[props.connection.connectionType].name}
        icon={{ type: "entity", entityType: props.connection.connectionType }}
      />
    </SectionEntry>
  );
};

export default ConnectionTypeRow;
