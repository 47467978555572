import {
  RequestTemplateCustomFieldFragment,
  RequestTemplateCustomFieldType,
  RequestTemplateFragment,
  useDeleteRequestTemplateMutation,
  useUpdateRequestTemplateMutation,
} from "api/generated/graphql";
import clsx from "clsx";
import AuthContext from "components/auth/AuthContext";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Button, Divider, FormGroup, Icon, Input, Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import * as React from "react";
import { useHistory } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";

import * as styles from "./RequestTemplateColumn.css";
import RequestTemplateCustomFieldEdit from "./RequestTemplateCustomFieldEdit";
import {
  CUSTOM_FIELDS_MAX_NUM,
  CustomFieldTypeIcons,
  DEFAULT_CUSTOM_FIELD,
} from "./utils";

const CUSTOM_FIELD_NAME_REQUIRED_ERROR =
  "Error: custom fields must have a name.";
const CUSTOM_FIELD_NAME_DISTINCT_ERROR =
  "Error: custom field names must be distinct.";
const DROPDOWN_NO_OPTIONS = "Error: dropdowns must have at least one option.";
const DROPDOWN_EMPTY_OPTION = "Error: dropdown options cannot be empty.";
const DROPDOWN_DUPLICATE_OPTION =
  "Error: a dropdown cannot have duplicate options.";

interface EditFormProps {
  requestTemplate: RequestTemplateFragment;
}

const RequestTemplateColumn: React.FC<EditFormProps> = (props) => {
  const history = useHistory();
  const { displaySuccessToast } = useToast();
  const [customFields, setCustomFields] = React.useState<
    RequestTemplateFragment["customFields"]
  >(props.requestTemplate.customFields);
  const [selectedFieldIndex, setSelectedFieldIndex] = React.useState<
    number | null
  >(null);
  const [errors, setErrors] = React.useState<string[]>([]);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [showRenameModal, setShowRenameModal] = React.useState(false);
  const [newName, setNewName] = React.useState(props.requestTemplate.name);
  const { authState } = React.useContext(AuthContext);

  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);

  const [
    updateRequestTemplate,
    { loading: saveLoading },
  ] = useUpdateRequestTemplateMutation();
  const [
    deleteRequestTemplate,
    { loading: deleteLoading },
  ] = useDeleteRequestTemplateMutation();

  const validateForm = () => {
    const formErrors = new Set<string>();
    const customFieldNames = new Set();
    for (let customField of customFields ?? []) {
      if (!customField.name) {
        formErrors.add(CUSTOM_FIELD_NAME_REQUIRED_ERROR);
      }
      if (customFieldNames.has(customField.name)) {
        formErrors.add(CUSTOM_FIELD_NAME_DISTINCT_ERROR);
      }
      customFieldNames.add(customField.name);
      if (customField.type === RequestTemplateCustomFieldType.MultiChoice) {
        const allOptions = new Set();
        if (!customField.metadata?.multiChoiceData?.options?.length) {
          formErrors.add(DROPDOWN_NO_OPTIONS);
        } else {
          customField.metadata.multiChoiceData.options.forEach((option) => {
            if (!option.value) {
              formErrors.add(DROPDOWN_EMPTY_OPTION);
            }
            if (allOptions.has(option.value)) {
              formErrors.add(DROPDOWN_DUPLICATE_OPTION);
            }
            allOptions.add(option.value);
          });
        }
      }
    }
    setErrors(Array.from(formErrors));
    return formErrors;
  };

  const handleRename = async () => {
    try {
      const { data } = await updateRequestTemplate({
        variables: {
          input: {
            id: props.requestTemplate.id,
            name: newName,
          },
        },
        refetchQueries: ["RequestTemplate", "RequestTemplates"],
      });
      switch (data?.updateRequestTemplate.__typename) {
        case "UpdateRequestTemplateResult":
          displaySuccessToast("Request template name updated.");
          setSelectedFieldIndex(null);
          break;
        case "RequestTemplateNameExistsError":
          setErrors([data.updateRequestTemplate.message]);
          logError(new Error(data.updateRequestTemplate.message));
          break;
        default:
          setErrors(["Error: failed to update request template name."]);
          logError(
            new Error(
              "failed to update request template name for: " +
                props.requestTemplate.id
            )
          );
      }
    } catch (error) {
      setErrors(["Error: failed to update request template name."]);
      logError(
        error,
        "failed to update request template name for: " +
          props.requestTemplate.id
      );
    }
  };

  const handleDelete = async () => {
    if (!props.requestTemplate) {
      return;
    }
    try {
      const { data } = await deleteRequestTemplate({
        variables: {
          input: {
            id: props.requestTemplate.id,
          },
        },
        refetchQueries: ["RequestTemplates"],
      });
      switch (data?.deleteRequestTemplate.__typename) {
        case "DeleteRequestTemplateResult":
          displaySuccessToast("Request template deleted.");
          history.push("/templates");
          break;
        default:
          setErrors(["Error: failed to delete request template."]);
          logError(new Error("failed to delete request template"));
      }
    } catch (error) {
      setErrors(["Error: failed to delete request template."]);
      logError(error, "failed to delete request template");
    }
  };

  const handleSave = async () => {
    try {
      if (validateForm().size > 0) {
        return;
      }
      setErrors([]);
      const { data } = await updateRequestTemplate({
        variables: {
          input: {
            id: props.requestTemplate.id,
            name: props.requestTemplate.name,
            customFields: customFields,
          },
        },
        refetchQueries: ["RequestTemplate", "RequestTemplates"],
      });
      switch (data?.updateRequestTemplate.__typename) {
        case "UpdateRequestTemplateResult":
          displaySuccessToast("Request template updated.");
          setSelectedFieldIndex(null);
          break;
        case "RequestTemplateNameExistsError":
          setErrors([data.updateRequestTemplate.message]);
          logError(new Error(data.updateRequestTemplate.message));
          break;
        case "CustomFieldExistsError":
          setErrors([data.updateRequestTemplate.message]);
          logError(new Error(data.updateRequestTemplate.message));
          break;
        default:
          setErrors(["Error: failed to update request template."]);
          logError(
            new Error(
              "failed to update request template: " + props.requestTemplate.id
            )
          );
      }
    } catch (error) {
      setErrors(["Error: failed to update request template."]);
      logError(
        error,
        "failed to update request template: " + props.requestTemplate.id
      );
    }
  };

  const menuOptions: PropsFor<typeof ColumnHeader>["menuOptions"] = [
    {
      label: "Rename",
      onClick: () => setShowRenameModal(true),
      icon: { type: "name", icon: "edit-3" },
    },
  ];

  if (props.requestTemplate) {
    menuOptions.push({
      label: "Delete",
      onClick: () => setShowDeleteModal(true),
      icon: { type: "name", icon: "trash" },
    });
  }

  const canSave = !_.isEqual(customFields, props.requestTemplate.customFields);
  const isAdmin = authState.user?.isAdmin ?? false;

  return (
    <>
      <ColumnHeader
        title={props.requestTemplate.name}
        breadcrumbs={
          hasV3Nav
            ? [
                {
                  name: "Request Templates",
                  to: "/templates/requests",
                },
              ]
            : undefined
        }
        icon={{ type: "name", icon: "template" }}
        rightActions={
          <div className={sprinkles({ display: "flex", gap: "sm" })}>
            {canSave ? (
              <Button
                label="Cancel"
                onClick={() => {
                  setCustomFields(props.requestTemplate.customFields);
                  setSelectedFieldIndex(null);
                }}
                outline
                borderless
              />
            ) : null}
            <Button
              type="primary"
              label="Save"
              disabled={!canSave}
              loading={saveLoading}
              onClick={handleSave}
              leftIconName="check"
            />
          </div>
        }
        menuOptions={isAdmin ? menuOptions : undefined}
      />
      <Divider />
      <ColumnContent>
        <div className={styles.contentContainer}>
          <div className={styles.mainContentContainer}>
            {errors.map((error) => (
              <ModalErrorMessage errorMessage={error} />
            ))}
            <FormGroup label="Custom fields">
              {customFields?.map((field, i) => (
                <CustomFieldRow
                  customField={field}
                  onSelectEdit={() => setSelectedFieldIndex(i)}
                  selected={selectedFieldIndex === i}
                  onDelete={() => {
                    if (selectedFieldIndex === i) {
                      setSelectedFieldIndex(null);
                    }
                    setCustomFields((prev) => [
                      ...(prev ?? []).slice(0, i),
                      ...(prev ?? []).slice(i + 1),
                    ]);
                  }}
                />
              ))}
              <div
                className={clsx(styles.addFieldButton, {
                  [styles.addButtonDisabled]:
                    (customFields?.length ?? 0) >= CUSTOM_FIELDS_MAX_NUM,
                })}
                onClick={() => {
                  if ((customFields?.length ?? 0) < CUSTOM_FIELDS_MAX_NUM) {
                    setCustomFields((prev) => [
                      ...(prev ?? []),
                      DEFAULT_CUSTOM_FIELD,
                    ]);
                    setSelectedFieldIndex(customFields?.length ?? 0);
                  }
                }}
              >
                Add
              </div>
            </FormGroup>
          </div>
          <div className={styles.editSidebarContainer}>
            <RequestTemplateCustomFieldEdit
              index={selectedFieldIndex ?? -1}
              customField={
                selectedFieldIndex == null || customFields == null
                  ? undefined
                  : customFields[selectedFieldIndex]
              }
              onChange={(updatedField) =>
                setCustomFields((prev) =>
                  prev?.map((field, i) =>
                    i === selectedFieldIndex ? updatedField : field
                  )
                )
              }
              onClose={() => setSelectedFieldIndex(null)}
            />
          </div>
        </div>
      </ColumnContent>
      <Modal
        title={`Delete ${props.requestTemplate.name}`}
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      >
        <Modal.Body>
          {errors.map((error) => (
            <ModalErrorMessage errorMessage={error} />
          ))}
          Are you sure you want to delete "{props.requestTemplate.name}"? This
          cannot be undone.
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Delete"
          onPrimaryButtonClick={handleDelete}
          primaryButtonLoading={deleteLoading}
        />
      </Modal>
      <Modal
        title={`Rename ${props.requestTemplate.name}`}
        isOpen={showRenameModal}
        onClose={() => setShowRenameModal(false)}
      >
        <Modal.Body>
          {errors.map((error) => (
            <ModalErrorMessage errorMessage={error} />
          ))}
          <Input value={newName} onChange={setNewName} />
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Rename"
          onPrimaryButtonClick={handleRename}
          primaryButtonDisabled={
            !newName || newName === props.requestTemplate.name
          }
          primaryButtonLoading={saveLoading}
        />
      </Modal>
    </>
  );
};

interface CustomFieldRowProps {
  customField: RequestTemplateCustomFieldFragment;
  selected: boolean;
  onSelectEdit: () => void;
  onDelete: () => void;
}

const CustomFieldRow: React.FC<CustomFieldRowProps> = (props) => {
  return (
    <div
      className={clsx(styles.customFieldRow, {
        [styles.customFieldRowSelected]: props.selected,
      })}
      onClick={props.onSelectEdit}
    >
      <Icon name={CustomFieldTypeIcons[props.customField.type]} />
      <div className={styles.customFieldRowName}>
        {props.customField.name}
        {props.customField.required ? (
          <span className={sprinkles({ color: "red600" })}>*</span>
        ) : null}
      </div>
      <div className={styles.deleteCustomField} onClick={props.onDelete}>
        <Icon name="trash" size="sm" />
      </div>
    </div>
  );
};

export default RequestTemplateColumn;
