import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewGroupForDetailFragment,
  AccessReviewGroupResourceForDetailFragment,
  AccessReviewGroupUserFragment,
  EntityIdTuple,
  EntityType,
  ReviewerUserInput,
  useAccessReviewGroupDetailsQuery,
  useUpdateAccessReviewGroupReviewersMutation,
  useUpdateGroupResourceReviewersMutation,
  useUpdateGroupUserReviewersMutation,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import GroupBindingDetailPopover from "components/group_bindings/GroupBindingDetailPopover";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import { ResourceLabel } from "components/label/Label";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import AccessReviewerModal from "components/modals/AccessReviewerModal";
import { useToast } from "components/toast/Toast";
import { Banner, Button, Divider, Input, Tabs } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { filterSearchResults } from "utils/search/filter";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import * as styles from "./AccessReviewResource.css";
import AccessReviewReviewersCell from "./AccessReviewReviewersCell";
import {
  AccessReviewOutcomeLabel,
  AccessReviewStatusLabel,
} from "./AccessReviewStatus";
import {
  getReviewerModalEntries,
  getReviewersSortValue,
} from "./common/Common";
import EventsTable from "./common/EventsTable";
import GroupResourceSubRow from "./common/GroupResourceSubRow";
import GroupUserSubRow from "./common/GroupUserSubRow";
import UserCell from "./common/UserCell";
import AccessReviewGroupOverview from "./groups/AccessReviewGroupOverview";

const AccessReviewGroupDetails = () => {
  const location = useLocation();
  const history = useHistory();
  const { accessReviewId, accessReviewGroupId } = useParams<
    Record<string, string>
  >();
  const { displaySuccessToast } = useToast();

  const [selectedGroupUserIds, setSelectedGroupUserIds] = useState<string[]>(
    []
  );
  const [selectedGroupResourceIds, setSelectedGroupResourceIds] = useState<
    string[]
  >([]);
  const [showBulkReviewersModal, setShowBulkReviewersModal] = useState(false);
  const [reviewersError, setReviewersError] = useState("");

  const [
    updateGroupReviewers,
    { loading: updateLoading },
  ] = useUpdateAccessReviewGroupReviewersMutation();
  const { data, error, loading } = useAccessReviewGroupDetailsQuery({
    variables: {
      input: {
        id: accessReviewGroupId,
      },
    },
    skip: !accessReviewGroupId,
  });

  if (loading) {
    return (
      <Column isContent>
        <ColumnContentSkeleton />
      </Column>
    );
  }

  let accessReviewGroup: AccessReviewGroupForDetailFragment | undefined;
  switch (data?.accessReviewGroup.__typename) {
    case "AccessReviewGroupResult":
      accessReviewGroup = data.accessReviewGroup.accessReviewGroup;
      break;
    case "AccessReviewGroupNotFoundError":
      return (
        <Column isContent>
          <NotFoundPage entity="Group" />
        </Column>
      );
  }

  const group = accessReviewGroup?.group;

  if (error || !accessReviewGroup || !group) {
    return (
      <Column isContent>
        <UnexpectedErrorPage />
      </Column>
    );
  }

  const currentAllGroupUserIds = new Set(
    accessReviewGroup.groupUsers?.map((u) => u.id)
  );
  const filteredGroupUserIds = selectedGroupUserIds.filter((id) =>
    currentAllGroupUserIds.has(id)
  );
  const currentAllGroupResourceIds = new Set(
    accessReviewGroup.groupResources?.map((r) => r.id)
  );
  const filteredGroupResourceIds = selectedGroupResourceIds.filter((id) =>
    currentAllGroupResourceIds.has(id)
  );

  const handleSubmitReviewers = async (reviewers: ReviewerUserInput[]) => {
    const entityIds: EntityIdTuple[] = [
      ...filteredGroupUserIds.map((id) => ({
        entityId: id,
        entityType: EntityType.AccessReviewGroupUser,
      })),
      ...filteredGroupResourceIds.map((id) => ({
        entityId: id,
        entityType: EntityType.AccessReviewGroupResource,
      })),
    ];
    reviewers = reviewers.map((reviewer) => {
      reviewer.entityIds = entityIds;
      return reviewer;
    });

    // Only update reviewers for checked rows
    accessReviewGroup?.reviewerUsers.forEach((reviewerUser) => {
      if (
        ![...filteredGroupUserIds, ...filteredGroupResourceIds].includes(
          reviewerUser.entityId
        )
      ) {
        reviewers.push({
          userId: reviewerUser.userId,
          entityIds: [
            {
              entityId: reviewerUser.entityId,
              entityType: reviewerUser.entityType,
            },
          ],
        });
      }
    });

    try {
      const { data } = await updateGroupReviewers({
        variables: {
          input: {
            accessReviewGroupId,
            reviewers,
            updateOnlyForEntities: false,
          },
        },
        refetchQueries: [
          "MyAccessReviewItems",
          "AccessReviewItems",
          "AccessReviewGroupDetails",
        ],
      });
      switch (data?.updateAccessReviewGroupReviewers.__typename) {
        case "UpdateAccessReviewGroupReviewersResult": {
          displaySuccessToast("Success: reviewers updated");
          setSelectedGroupUserIds([]);
          setSelectedGroupResourceIds([]);
          setShowBulkReviewersModal(false);
          break;
        }
        case "AccessReviewAlreadyStoppedError": {
          setReviewersError(data?.updateAccessReviewGroupReviewers.message);
          break;
        }
      }
    } catch (error) {
      setReviewersError(
        getModifiedErrorMessage(
          "Error: failed to update access reviewers",
          error
        )
      );
    }
  };

  const isStoppedReview = accessReviewGroup.accessReview?.stoppedDate != null;
  const isUsersTab = !location.hash || location.hash === "#users";
  const isResourcesTab = location.hash === "#resources";
  const showBulkAssignButton =
    !isStoppedReview && (isUsersTab || isResourcesTab);

  return (
    <>
      <Column isContent>
        <ColumnHeader
          title={group.name}
          titleAccessory={
            group.groupBinding ? (
              <GroupBindingDetailPopover
                groupId={group.id}
                groupBinding={group.groupBinding}
              />
            ) : undefined
          }
          icon={{ type: "entity", entityType: group.groupType }}
          subtitle={groupTypeInfoByType[group.groupType].name}
          onClose={() =>
            history.push(`/access-reviews/${accessReviewId}/entities`)
          }
          rightActions={
            showBulkAssignButton ? (
              <Button
                label="Bulk assign reviewers"
                type="primary"
                borderless
                leftIconName="edit"
                disabled={
                  filteredGroupUserIds.length === 0 &&
                  filteredGroupResourceIds.length === 0
                }
                onClick={() => setShowBulkReviewersModal(true)}
              />
            ) : undefined
          }
        />
        <Divider />
        <div
          className={sprinkles({
            display: "flex",
            justifyContent: "center",
            marginY: "md",
          })}
        >
          <div className={sprinkles({ width: "fit-content" })}>
            <Tabs
              tabInfos={[
                {
                  title: "Users",
                  isSelected: isUsersTab,
                  onClick: () => history.push({ hash: "#users" }),
                  icon: "users",
                  badgeCount: accessReviewGroup.numGroupUsers,
                },
                {
                  title: "Resources",
                  isSelected: isResourcesTab,
                  onClick: () => history.push({ hash: "#resources" }),
                  icon: "cube",
                  badgeCount: accessReviewGroup.numGroupResources,
                },
                {
                  title: "Overview",
                  isSelected: location.hash === "#overview",
                  onClick: () => history.push({ hash: "#overview" }),
                  icon: "list",
                },
                {
                  title: "Events",
                  isSelected: location.hash === "#events",
                  onClick: () => history.push({ hash: "#events" }),
                  icon: "events",
                },
              ]}
              round
            />
          </div>
        </div>
        <ColumnContent>
          {!location.hash || location.hash === "#users" ? (
            <Users
              accessReviewGroup={accessReviewGroup}
              selectedIds={filteredGroupUserIds}
              onSelectRows={(ids) => {
                setSelectedGroupUserIds((prev) => _.uniq([...prev, ...ids]));
              }}
              onRemoveRows={(ids) => {
                setSelectedGroupUserIds((prev) =>
                  prev.filter((id) => !ids.includes(id))
                );
              }}
            />
          ) : null}
          {location.hash === "#resources" && (
            <Resources
              accessReviewGroup={accessReviewGroup}
              selectedIds={filteredGroupResourceIds}
              onSelectRows={(ids) => {
                setSelectedGroupResourceIds((prev) =>
                  _.uniq([...prev, ...ids])
                );
              }}
              onRemoveRows={(ids) => {
                setSelectedGroupResourceIds((prev) =>
                  prev.filter((id) => !ids.includes(id))
                );
              }}
            />
          )}
          {location.hash === "#overview" && (
            <AccessReviewGroupOverview accessReviewGroup={accessReviewGroup} />
          )}
          {location.hash === "#events" && (
            <div className={styles.tableContainer}>
              <EventsTable
                eventFilter={{
                  objects: {
                    objectId: accessReviewGroup.id,
                  },
                }}
              />
            </div>
          )}
        </ColumnContent>
      </Column>
      {showBulkReviewersModal && (
        <AccessReviewerModal
          title="Reviewers"
          isModalOpen={showBulkReviewersModal}
          numUserReviews={filteredGroupUserIds.length}
          numResourceReviews={filteredGroupResourceIds.length}
          onClose={() => {
            setShowBulkReviewersModal(false);
            setReviewersError("");
          }}
          loading={updateLoading}
          onSubmit={handleSubmitReviewers}
          errorMessage={reviewersError}
          entryInfos={getReviewerModalEntries(
            accessReviewGroup.reviewerUsers,
            new Set([...filteredGroupUserIds, ...filteredGroupResourceIds])
          )}
          canEditReviewers={!isStoppedReview}
        />
      )}
    </>
  );
};

interface ViewProps {
  accessReviewGroup: AccessReviewGroupForDetailFragment;
  selectedIds: string[];
  onSelectRows: (ids: string[]) => void;
  onRemoveRows: (ids: string[]) => void;
}
interface GroupUserRow {
  id: string;
  name: string;
  role: string;
  reviewers: string;
  status: string;
  outcome: string;
  data: AccessReviewGroupUserFragment;
}

const Users = ({
  accessReviewGroup,
  selectedIds,
  onSelectRows,
  onRemoveRows,
}: ViewProps) => {
  const { displaySuccessToast } = useToast();

  const [selectedGroupUser, setSelectedGroupUser] = useState<
    AccessReviewGroupUserFragment | undefined
  >();
  const [showReviewerModal, setShowReviewerModal] = useState(false);
  const [reviewerError, setReviewerError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [
    updateGroupUserReviewers,
    { loading },
  ] = useUpdateGroupUserReviewersMutation();

  const groupUsers = accessReviewGroup.groupUsers ?? [];
  const isStoppedReview = accessReviewGroup.accessReview?.stoppedDate != null;
  const isSourceGroup = accessReviewGroup.group?.groupBinding
    ? accessReviewGroup.group?.groupBinding.sourceGroupId ===
      accessReviewGroup.group?.id
    : true;

  const rows: GroupUserRow[] = filterSearchResults(
    groupUsers.slice(),
    searchQuery,
    (groupUser) => [groupUser.user?.fullName, groupUser.user?.email]
  ).map((groupUser) => {
    return {
      id: groupUser.id,
      name: groupUser.user?.fullName ?? "",
      role: groupUser.accessLevel.accessLevelName || "--",
      reviewers: getReviewersSortValue(groupUser.reviewerUsers ?? []),
      status: groupUser.statusAndOutcome.status,
      outcome: groupUser.statusAndOutcome.outcome,
      data: groupUser,
    };
  });

  // Don't show role column if the group doesn't have roles specified
  const groupHasRoles = groupUsers.some(
    (user) => !!user.accessLevel.accessLevelRemoteId
  );

  const columns: Header<GroupUserRow>[] = [
    {
      id: "name",
      label: "Name",
      width: groupHasRoles ? 200 : 400,
      customCellRenderer: (row) => {
        const user = row.data.user;
        if (!user) {
          return <></>;
        }
        return <UserCell user={user} />;
      },
    },
  ];
  if (groupHasRoles) {
    columns.push({
      id: "role",
      label: "Role",
      width: 200,
    });
  }
  columns.push(
    {
      id: "reviewers",
      label: "Reviewers",
      width: 150,
      customCellRenderer: (row) => {
        return (
          <AccessReviewReviewersCell
            itemType="group"
            canEditReviewers={!isStoppedReview}
            reviewerUsers={row.data.reviewerUsers ?? []}
            onClick={() => {
              setSelectedGroupUser(row.data);
              setShowReviewerModal(true);
            }}
          />
        );
      },
    },
    {
      id: "status",
      label: "Status",
      width: 150,
      customCellRenderer: (row) => {
        return (
          <AccessReviewStatusLabel
            entityType={EntityType.AccessReviewGroupUser}
            status={row.data.statusAndOutcome.status}
            warnings={row.data.warnings}
            group={accessReviewGroup.group}
            user={row.data.user}
            accessReviewId={accessReviewGroup.accessReviewId}
          />
        );
      },
    },
    {
      id: "outcome",
      label: "Outcome",
      width: 120,
      customCellRenderer: (row) => {
        return (
          <AccessReviewOutcomeLabel
            entityType={EntityType.AccessReviewGroupUser}
            outcome={row.data.statusAndOutcome.outcome}
          />
        );
      },
    }
  );

  const renderSubRow = (row: GroupUserRow) => {
    return <GroupUserSubRow groupUser={row.data} />;
  };

  const multiSelectProps = isStoppedReview
    ? {}
    : {
        checkedRowIds: new Set(selectedIds),
        onCheckedRowsChange: (ids: string[], checked: boolean) => {
          if (checked) {
            onSelectRows(ids);
          } else {
            onRemoveRows(ids);
          }
        },
        selectAllChecked: selectedIds.length === rows.length,
        onSelectAll: (checked: boolean) => {
          if (checked) {
            onSelectRows(rows.map((row) => row.id));
          } else {
            onRemoveRows(rows.map((row) => row.id));
          }
        },
      };

  const groupBinding = accessReviewGroup.group?.groupBinding;

  return (
    <>
      <div className={styles.tableContainer}>
        {isStoppedReview && (
          <div className={sprinkles({ marginBottom: "md" })}>
            <Banner
              type="warning"
              message="This access review has been stopped. No further actions can be taken on reviewer assignments and reviews."
            />
          </div>
        )}
        {!isSourceGroup && (
          <div className={sprinkles({ marginBottom: "md" })}>
            <Banner
              type="warning"
              message={
                `This is a linked group that is not the source of truth for this access review. Only users that don't belong to the source of truth group can be reviewed here. ` +
                ` To review the full list of group users, please review "${groupBinding?.sourceGroup?.name}".`
              }
            />
          </div>
        )}
        <div className={styles.searchContainer}>
          <Input
            leftIconName="search"
            type="search"
            style="search"
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Search this table"
          />
        </div>
        <Table
          rows={rows}
          totalNumRows={rows.length}
          getRowId={(row) => row.id}
          columns={columns}
          renderSubRow={renderSubRow}
          emptyState={{
            title: "No users to review",
            subtitle:
              "No users had direct access to this group at the time of the access review.",
          }}
          {...multiSelectProps}
        />
      </div>
      {selectedGroupUser && showReviewerModal && (
        <AccessReviewerModal
          title="Reviewers"
          isModalOpen={showReviewerModal}
          onClose={() => {
            setShowReviewerModal(false);
            setReviewerError("");
          }}
          loading={loading}
          onSubmit={async (reviewers) => {
            if (!selectedGroupUser) {
              return;
            }
            try {
              const { data } = await updateGroupUserReviewers({
                variables: {
                  input: {
                    accessReviewGroupUserId: selectedGroupUser.id,
                    reviewers,
                  },
                },
                refetchQueries: ["AccessReviewGroupDetails"],
              });
              switch (data?.updateGroupUserReviewers.__typename) {
                case "UpdateGroupUserReviewersResult": {
                  displaySuccessToast("Success: reviewers updated");
                  setShowReviewerModal(false);
                  break;
                }
                case "AccessReviewAlreadyStoppedError": {
                  setReviewerError(data?.updateGroupUserReviewers.message);
                  break;
                }
                case "UpdateAccessReviewReviewersValidationError": {
                  setReviewerError(data?.updateGroupUserReviewers.message);
                  break;
                }
              }
            } catch (error) {
              setReviewerError(
                getModifiedErrorMessage(
                  "Error: failed to update access reviewers",
                  error
                )
              );
            }
          }}
          errorMessage={reviewerError}
          entryInfos={getReviewerModalEntries(
            selectedGroupUser.reviewerUsers,
            null
          )}
          canEditReviewers={!isStoppedReview}
        />
      )}
    </>
  );
};

interface GroupResourceRow {
  id: string;
  resource: string;
  role: string;
  reviewers: string;
  status: string;
  outcome: string;
  data: AccessReviewGroupResourceForDetailFragment;
}

const Resources = ({
  accessReviewGroup,
  selectedIds,
  onRemoveRows,
  onSelectRows,
}: ViewProps) => {
  const { displaySuccessToast } = useToast();

  const [selectedGroupResource, setSelectedGroupResource] = useState<
    AccessReviewGroupResourceForDetailFragment | undefined
  >();
  const [showReviewerModal, setShowReviewerModal] = useState(false);
  const [reviewerError, setReviewerError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [
    updateGroupResourceReviewers,
    { loading },
  ] = useUpdateGroupResourceReviewersMutation();

  const groupResources = accessReviewGroup.groupResources ?? [];
  const isStoppedReview = accessReviewGroup.accessReview?.stoppedDate != null;

  const rows: GroupResourceRow[] = filterSearchResults(
    groupResources.slice(),
    searchQuery,
    (groupResource) => [groupResource.resource?.name]
  ).map((groupResource) => {
    return {
      id: groupResource.id,
      resource: groupResource.resource?.name ?? "",
      role: groupResource.accessLevel.accessLevelName || "--",
      reviewers: getReviewersSortValue(groupResource.reviewerUsers ?? []),
      status: groupResource.statusAndOutcome.status,
      outcome: groupResource.statusAndOutcome.outcome,
      data: groupResource,
    };
  });

  const columns: Header<GroupResourceRow>[] = [
    {
      id: "resource",
      label: "Resource",
      width: 300,
      customCellRenderer: (row) => {
        const resource = row.data.resource;
        if (!resource) {
          return <></>;
        }
        return (
          <ResourceLabel
            text={resource.name}
            subText={getResourceTypeInfo(resource.resourceType)?.name || "--"}
            entityTypeNew={EntityType.Resource}
            resourceType={resource.resourceType}
            iconLarge
            bold
            icon={resource.iconUrl}
          />
        );
      },
    },
    {
      id: "role",
      label: "Role",
      width: 200,
    },
    {
      id: "reviewers",
      label: "Reviewers",
      width: 150,
      customCellRenderer: (row) => {
        return (
          <AccessReviewReviewersCell
            itemType="group"
            canEditReviewers={!isStoppedReview}
            reviewerUsers={row.data.reviewerUsers ?? []}
            onClick={() => {
              setSelectedGroupResource(row.data);
              setShowReviewerModal(true);
            }}
          />
        );
      },
    },
    {
      id: "status",
      label: "Status",
      width: 150,
      customCellRenderer: (row) => {
        return (
          <AccessReviewStatusLabel
            entityType={EntityType.AccessReviewGroupResource}
            status={row.data.statusAndOutcome.status}
            group={accessReviewGroup.group}
            resource={row.data.resource}
            accessReviewId={accessReviewGroup.accessReviewId}
          />
        );
      },
    },
    {
      id: "outcome",
      label: "Outcome",
      width: 120,
      customCellRenderer: (row) => {
        return (
          <AccessReviewOutcomeLabel
            entityType={EntityType.AccessReviewGroupResource}
            outcome={row.data.statusAndOutcome.outcome}
          />
        );
      },
    },
  ];

  const renderSubRow = (row: GroupResourceRow) => {
    return <GroupResourceSubRow groupResource={row.data} />;
  };

  const handleSubmitReviewers = async (reviewers: ReviewerUserInput[]) => {
    if (!selectedGroupResource) {
      return;
    }
    try {
      const { data } = await updateGroupResourceReviewers({
        variables: {
          input: {
            accessReviewGroupResourceId: selectedGroupResource.id,
            reviewers,
          },
        },
        refetchQueries: [
          "MyAccessReviewItems",
          "AccessReviewItems",
          "AccessReviewGroupDetails",
        ],
      });
      switch (data?.updateGroupResourceReviewers.__typename) {
        case "UpdateGroupResourceReviewersResult": {
          displaySuccessToast("Success: reviewers updated");
          setShowReviewerModal(false);
          break;
        }
        case "AccessReviewAlreadyStoppedError": {
          setReviewerError(data?.updateGroupResourceReviewers.message);
          break;
        }
      }
    } catch (error) {
      setReviewerError(
        getModifiedErrorMessage(
          "Error: failed to update access reviewers",
          error
        )
      );
    }
  };

  const multiSelectProps = isStoppedReview
    ? {}
    : {
        checkedRowIds: new Set(selectedIds),
        onCheckedRowsChange: (ids: string[], checked: boolean) => {
          if (checked) {
            onSelectRows(ids);
          } else {
            onRemoveRows(ids);
          }
        },
        selectAllChecked: selectedIds.length === rows.length,
        onSelectAll: (checked: boolean) => {
          if (checked) {
            onSelectRows(rows.map((row) => row.id));
          } else {
            onRemoveRows(rows.map((row) => row.id));
          }
        },
      };

  return (
    <>
      <div className={styles.tableContainer}>
        {isStoppedReview && (
          <div className={sprinkles({ marginBottom: "md" })}>
            <Banner
              type="warning"
              message="This access review has been stopped. No further actions can be taken on reviewer assignments and reviews."
            />
          </div>
        )}
        <div className={styles.searchContainer}>
          <Input
            leftIconName="search"
            type="search"
            style="search"
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Search this table"
          />
        </div>
        <Table
          rows={rows}
          totalNumRows={rows.length}
          getRowId={(row) => row.id}
          columns={columns}
          renderSubRow={renderSubRow}
          emptyState={{
            title: "No resources to review",
            subtitle:
              "This group had no direct access to any resources at the time of the access review.",
          }}
          {...multiSelectProps}
        />
      </div>
      {selectedGroupResource && showReviewerModal && (
        <AccessReviewerModal
          title="Reviewers"
          isModalOpen={showReviewerModal}
          onClose={() => {
            setShowReviewerModal(false);
            setReviewerError("");
          }}
          loading={loading}
          onSubmit={handleSubmitReviewers}
          errorMessage={reviewerError}
          entryInfos={getReviewerModalEntries(
            selectedGroupResource.reviewerUsers,
            null
          )}
          canEditReviewers={!isStoppedReview}
        />
      )}
    </>
  );
};

export default AccessReviewGroupDetails;
