import {
  AccessReviewItemStatus,
  AccessReviewPreviewFragment,
} from "api/generated/graphql";
import moment from "moment-timezone";
import pluralize from "pluralize";

export type DeadlineProgressBarInfo = {
  progressPercentage: number;
  daysToShow: string;
  diffText: string;
  dateText: string;
};

export const getDeadlineProgressBarInfo: (
  accessReview: AccessReviewPreviewFragment | null | undefined
) => DeadlineProgressBarInfo = (accessReview) => {
  if (!accessReview) {
    return {
      progressPercentage: 0,
      daysToShow: "",
      diffText: "",
      dateText: "",
    };
  }

  let daysToShow, pastDeadline;

  const reviewTimeZone = accessReview.timeZone;
  const accessReviewStartDate = moment(accessReview.createdAt).tz(
    reviewTimeZone
  );
  // get deadline date without time
  const accessReviewDeadline = moment(accessReview.deadline)
    .tz(reviewTimeZone)
    .endOf("day");
  let daysUntilAccessReviewDeadline = accessReviewDeadline.diff(
    moment(),
    "days"
  );
  if (daysUntilAccessReviewDeadline >= 0) {
    daysToShow = daysUntilAccessReviewDeadline;
    pastDeadline = false;
  } else {
    daysToShow = -daysUntilAccessReviewDeadline;
    pastDeadline = true;
  }
  const totalAvailableDays = accessReviewDeadline.diff(
    accessReviewStartDate,
    "days"
  );

  return {
    progressPercentage: Math.min(
      100,
      totalAvailableDays === 0
        ? 100
        : (100 / totalAvailableDays) *
            (totalAvailableDays - daysUntilAccessReviewDeadline)
    ),
    daysToShow: `${daysToShow}`,
    diffText: `${pluralize(`day`, daysToShow, false)} ${
      pastDeadline ? `overdue` : `left in review`
    }`,
    dateText: `Deadline:\n${accessReviewDeadline.format("ddd l LT z")}`,
  };
};

export const getAccessReviewBadgeColor = (
  deadlineInfo: DeadlineProgressBarInfo
) => {
  return Number(deadlineInfo.daysToShow) < 3 ? "error" : "warning";
};

export const accessReviewStatusIsDone = (
  status?: AccessReviewItemStatus | null
) => {
  return (
    status === AccessReviewItemStatus.Completed ||
    status === AccessReviewItemStatus.NoReviewNeeded ||
    status === AccessReviewItemStatus.NeedsEndSystemRevocation // In terms of reviewing, this is done, but the end system revocation is still pending
  );
};

export const accessReviewStatusIsInProgress = (
  status?: AccessReviewItemStatus | null
) => {
  return (
    status === AccessReviewItemStatus.PartiallyCompleted ||
    status === AccessReviewItemStatus.NotStarted
  );
};
