import * as styles from "./ProgressBar.css";

interface Props {
  percentProgress: number;
  color?: "green" | "blue";
  size?: "md" | "lg";
}

const ProgressBar = (props: Props) => {
  const { percentProgress, color = "green", size = "md" } = props;

  return (
    <div className={styles.container({ size })}>
      <div
        className={styles.progress({ color, size })}
        style={{
          width: `${percentProgress}%`,
        }}
      />
    </div>
  );
};

export default ProgressBar;
