import { RiskLevel } from "api/generated/graphql";
import { PillV3 } from "components/pills/PillsV3";
import { startCase } from "lodash";
import React from "react";

export type RiskSensitivityPillProps = {
  entityId: string;
  riskLevel: RiskLevel;
};

export const riskLevelText = (riskLevel: RiskLevel) => {
  return startCase(riskLevel.toLowerCase());
};

export const RiskSensitivityPill = (props: RiskSensitivityPillProps) => {
  const riskLevelColor = (riskLevel: RiskLevel) => {
    switch (riskLevel) {
      case RiskLevel.Low:
        return "LightGreen";
      case RiskLevel.Medium:
        return "Orange";
      case RiskLevel.High:
        return "DeepOrange";
      case RiskLevel.Critical:
        return "Red";
      default:
        return "Gray";
    }
  };

  return (
    <PillV3
      key={props.entityId}
      keyText={riskLevelText(props.riskLevel)}
      pillColor={riskLevelColor(props.riskLevel)}
    />
  );
};
