import { EntityType, OwnerPreviewSmallFragment } from "api/generated/graphql";
import { FormMode } from "components/forms/common";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { FormRow, RadioGroup } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getResourceUrlNew } from "utils/common";

const options = [
  {
    value: false,
    label: "Disabled",
    description:
      "No notification will be sent when a new resource or group is imported into Opal.",
  },

  {
    value: true,
    label: "Enabled",
    description:
      "When a new resource or group is imported into Opal, a notification will be sent to the configured owner's slack channel. If no channel is set, an email will be sent to all users for the owner.",
  },
];

type ConnectionImportNotificationRowProps = {
  mode: FormMode;
  shouldNotify: Boolean;
  recipientOwner?: OwnerPreviewSmallFragment;
  onShouldNotifyChange: (val: boolean) => void;
  onRecipientOwnerChange: (owner?: { name: string; id: string }) => void;
};

export const ConnectionImportNotificationRow = (
  props: ConnectionImportNotificationRowProps
) => {
  const viewContent =
    props.shouldNotify && props.recipientOwner ? (
      <Link
        to={getResourceUrlNew(
          {
            entityId: props.recipientOwner.id,
            entityType: EntityType.Owner,
          },
          EntityType.Owner
        )}
      >
        {props.recipientOwner.name}
      </Link>
    ) : (
      "Disabled"
    );
  const [shouldNotify, setShouldNotify] = useState<Boolean>(props.shouldNotify);

  const editContent = (
    <div
      className={sprinkles({
        display: "flex",
        alignItems: "center",
        gap: "sm",
        flexWrap: "wrap",
      })}
    >
      <RadioGroup
        value={options.find((option) => option.value === props.shouldNotify)}
        options={options}
        getOptionKey={(option) => option.value}
        getOptionLabel={(option) => option.label}
        getOptionDescription={(option) => option.description}
        onSelectValue={(newSetting) => {
          setShouldNotify(newSetting.value);
          props.onShouldNotifyChange(newSetting.value);
        }}
      />
      {shouldNotify ? (
        <div
          className={sprinkles({
            paddingLeft: "lg",
          })}
        >
          <OwnerDropdown
            selectedOwnerId={props.recipientOwner?.id}
            onSelectOwner={(owner) => {
              props.onRecipientOwnerChange(owner);
            }}
          />
        </div>
      ) : (
        <></>
      )}
    </div>
  );

  return (
    <FormRow
      title="Notify on import"
      tooltipText={`If a resource or group is imported into Opal, this
      specifies whether a notification should be delivered to the configured destination.`}
    >
      {props.mode === "view" ? viewContent : editContent}
    </FormRow>
  );
};

export default ConnectionImportNotificationRow;
