import { GroupBindingSmall } from "components/forms/common";
import { Banner, Label, Tooltip } from "components/ui";

type GroupBindingWarningBannerProps = {
  groupBinding?: GroupBindingSmall;
  bulkMode?: boolean;
};

const GroupBindingWarningBanner: React.FC<GroupBindingWarningBannerProps> = ({
  groupBinding,
  bulkMode,
}) => {
  if (!groupBinding) {
    return null;
  }

  return (
    <Banner
      type="info"
      message={
        bulkMode ? (
          "One or more groups is linked to another group. Settings, such as on-call schedules, break-glass users, and request configurations, can only be updated to source groups or unlinked groups."
        ) : (
          <>
            This is a linked group that is not a source of truth. Users cannot
            request a non-source of truth group, they will be redirected to
            request the{" "}
            <Tooltip
              contentInline
              tooltipText={groupBinding?.sourceGroupName ?? "Hidden group"}
            >
              source of truth
            </Tooltip>
            {". "}
            You cannot set up an on-call schedule or break-glass users for this
            group either, since the user membership is managed through the
            source of truth group,{" "}
            <Label
              inline
              label={groupBinding?.sourceGroupName ?? "Hidden group"}
              icon={
                groupBinding?.sourceGroupType
                  ? {
                      type: "entity",
                      entityType: groupBinding?.sourceGroupType,
                    }
                  : undefined
              }
              linkTo={`/groups/${groupBinding?.sourceGroupId}`}
            />
            .
          </>
        )
      }
    />
  );
};

export default GroupBindingWarningBanner;
