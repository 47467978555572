import { Maybe, useUsersQuery } from "api/generated/graphql";
import { FormGroup, Select } from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import { logError } from "utils/logging";

type SelectTicketAssigneeProps = {
  ticketAssigneeUserId: Maybe<string>;
  setTicketAssigneeUserId: (userId: Maybe<string>) => void;
  resetBelowFields: () => void;
};

export const SelectTicketAssigneeEditor = (
  props: SelectTicketAssigneeProps
) => {
  const { data, error } = useUsersQuery({
    variables: {
      input: {
        maxNumEntries: 1000,
      },
    },
  });

  let users = data?.users.users || [];
  if (error) {
    logError(new Error(`failed to list users: ${error}`));
  }

  return (
    <FormGroup
      label="Ticket assignee"
      infoTooltip="Specify the assigned user for the ticket."
    >
      <Select
        value={users.find((user) => user.id === props.ticketAssigneeUserId)}
        onChange={(user) => {
          if (user) {
            props.setTicketAssigneeUserId(user.id);
            props.resetBelowFields();
          }
        }}
        options={users}
        getOptionLabel={(user) => user.fullName}
        getIcon={(user) => ({
          type: "src",
          style: "rounded",
          icon: user.avatarUrl || defaultAvatarURL,
          fallbackIcon: defaultAvatarURL,
        })}
      />
    </FormGroup>
  );
};
