import { Maybe } from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { CodeEditor, FormGroup, Input, Modal } from "components/ui";
import { RoleDescriptionInfo as RoleDescriptionInfo } from "views/resources/viewer/rows/ResourceCustomRolesRow";

type CreateRoleModalProps = {
  isModalOpen: boolean;
  roleName: string;
  roleRemoteId: string;
  setRoleName: (roleName: string) => void;
  setRoleRemoteId: (roleRemoteId: string) => void;
  onClose: () => void;
  onSubmit: () => void;
  errorMessage?: Maybe<string>;
  disabled?: boolean;
  loading?: boolean;
  submitDisabled?: boolean;
  isDataPrivate?: boolean;
  setPolicyContent: (policy: string) => void;
  policyContent: Maybe<string>;
  roleDescriptionInfo: RoleDescriptionInfo;
  resourceCustomRolesCount: number;
};

export const CreateRoleModal = (props: CreateRoleModalProps) => {
  return (
    <Modal
      isOpen={props.isModalOpen}
      onClose={props.onClose}
      title="Create Role"
    >
      <Modal.Body>
        <FormGroup
          label="Role Name:"
          infoTooltip={props.roleDescriptionInfo.roleNameDescription}
        >
          <Input value={props.roleName} onChange={props.setRoleName} />
        </FormGroup>
        <FormGroup
          label="Role Remote ID:"
          infoTooltip={props.roleDescriptionInfo.roleRemoteIdDescription}
        >
          <Input value={props.roleRemoteId} onChange={props.setRoleRemoteId} />
        </FormGroup>

        {props.roleDescriptionInfo.includePolicy ? (
          <FormGroup
            label="Policy:"
            infoTooltip={
              props.roleDescriptionInfo.policyDescription || undefined
            }
          >
            <CodeEditor
              onChangeCode={props.setPolicyContent}
              code={props.policyContent ?? ""}
              language={props.roleDescriptionInfo.policyFormat.toString()}
            />
          </FormGroup>
        ) : null}
        {props.errorMessage && (
          <ModalErrorMessage errorMessage={props.errorMessage} />
        )}
      </Modal.Body>
      <Modal.Footer
        onSecondaryButtonClick={props.onClose}
        onPrimaryButtonClick={props.onSubmit}
        primaryButtonLoading={props.loading}
        primaryButtonDisabled={
          props.loading ||
          props.disabled ||
          props.roleName === "" ||
          props.roleRemoteId === "" ||
          props.submitDisabled
        }
        primaryButtonLabel="Create"
      />
    </Modal>
  );
};

export default CreateRoleModal;
