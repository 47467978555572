import { Maybe, ThirdPartyIntegrationFragment } from "api/generated/graphql";
import AuthContext, {
  AuthContextActionType,
} from "components/auth/AuthContext";
import { Editor } from "components/entity_viewer/EntityViewer";
import Label, { Icon } from "components/label/Label";
import { LockTooltip } from "components/tooltips/LockTooltip";
import { Avatar } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { ReactElement, useContext, useState } from "react";
import * as Icons from "react-feather";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import OrgContext from "views/settings/OrgContext";
import { OrgSettingsSection } from "views/settings/OrgSettings";
import accountSettingsStyles from "views/user_settings/AccountSettings.module.scss";
import ManagerModal from "views/user_settings/modals/ManagerModal";
import { UserSettingsCategoryInfo } from "views/user_settings/UserSettings";

export type SettingsEntryInfo = {
  title: string;
  value?: Maybe<ReactElement | string>;
  icon: ReactElement;
  editor?: ReactElement;
};

export type AccountSettingsProps = {
  categoryInfo: UserSettingsCategoryInfo;
  orgIntegrations: Maybe<ThirdPartyIntegrationFragment>[];
};

const AccountSettings = (props: AccountSettingsProps) => {
  const { orgState } = useContext(OrgContext);
  const { authState, authDispatch } = useContext(AuthContext);

  const [isManagerModalOpen, setIsManagerModalOpen] = useState(false);

  const currentUser = authState.user?.user;
  const manager = currentUser?.manager;

  const canEditManager = authState.user?.isAdmin && !orgState.isIdpEnabled;

  const settingsEntryInfos: SettingsEntryInfo[] = [
    {
      title: "Name",
      value: currentUser?.fullName,
      icon: <Icons.User />,
    },
    {
      title: "Email",
      value: currentUser?.email,
      icon: <Icons.Mail />,
    },
    {
      title: "Secondary Emails",
      value: currentUser?.secondaryEmails?.join(", ") || "--",
      icon: <Icons.Mail />,
    },
    {
      title: "Manager",
      value: manager?.fullName ? (
        <Label
          url={getResourceUrl(EntityTypeDeprecated.User, manager?.id)}
          text={manager.fullName}
        />
      ) : (
        "None"
      ),
      icon: manager?.avatarUrl ? (
        <Icon icon={manager?.avatarUrl} />
      ) : (
        <Icons.Users />
      ),
      editor: canEditManager ? (
        <Editor
          menuOptions={[
            {
              label: "Edit",
              handler: () => {
                setIsManagerModalOpen(true);
              },
            },
          ]}
        />
      ) : undefined,
    },
  ];

  return (
    <div id={`#${props.categoryInfo.anchor}`}>
      <OrgSettingsSection title="Account Settings">
        <div className={accountSettingsStyles.profileHeader}>
          <div>
            <Avatar url={currentUser?.avatarUrl} size="large" />
          </div>
          <div className={accountSettingsStyles.profileLabel}>
            <div className={accountSettingsStyles.profileTitle}>
              Profile Photo
            </div>
          </div>
        </div>
      </OrgSettingsSection>
      <hr className={accountSettingsStyles.spacer} />
      <SettingsEntries settingsEntryInfos={settingsEntryInfos} />
      {authState.user && (
        <ManagerModal
          isModalOpen={isManagerModalOpen}
          setIsModalOpen={setIsManagerModalOpen}
          user={authState.user.user}
          manager={authState.user.user.manager ?? undefined}
          onUserUpdate={(user) => {
            authDispatch({
              type: AuthContextActionType.RefreshUser,
              payload: {
                refreshUser: user,
              },
            });
          }}
        />
      )}
    </div>
  );
};

type SettingsEntriesProps = {
  settingsEntryInfos: SettingsEntryInfo[];
};

const SettingsEntries = (props: SettingsEntriesProps) => {
  return (
    <div className={accountSettingsStyles.settingsEntries}>
      {props.settingsEntryInfos.map((info) => (
        <SettingsEntry
          key={`settings-entry-info-${info.title}`}
          settingsEntryInfo={info}
        />
      ))}
    </div>
  );
};

type SettingsEntryProps = {
  settingsEntryInfo: SettingsEntryInfo;
};

const SettingsEntry = (props: SettingsEntryProps) => {
  return (
    <div className={accountSettingsStyles.settingsEntry}>
      <div className={sprinkles({ display: "flex" })}>
        <div className={accountSettingsStyles.title}>
          {props.settingsEntryInfo.title}
        </div>
        {!props.settingsEntryInfo.editor && (
          <LockTooltip
            tooltipText={
              "This field is imported from your IDP/HRIS and can't be edited from Opal."
            }
          />
        )}
      </div>
      <div className={accountSettingsStyles.value}>
        <div className={accountSettingsStyles.icon}>
          {props.settingsEntryInfo.icon}
        </div>
        <div className={accountSettingsStyles.text}>
          {props.settingsEntryInfo.value || "None"}
        </div>
        <div>{props.settingsEntryInfo.editor}</div>
      </div>
    </div>
  );
};

export default AccountSettings;
