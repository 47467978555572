import {
  Maybe,
  UserOverviewFragment,
  UserPreviewLargeFragment,
} from "api/generated/graphql";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import React from "react";
import UsersTable from "views/users/UsersTable";

type DirectReportsRowProps = {
  user: UserOverviewFragment;
};

export const DirectReportsRow = (props: DirectReportsRowProps) => {
  const reportsTable = (
    <UsersTable
      users={props.user.directReports as Maybe<UserPreviewLargeFragment>[]}
      allRowsLoaded={true}
    />
  );

  return (
    <EntityViewerRow
      title={"Direct Reports"}
      content={reportsTable}
      isTable={true}
    />
  );
};

export default DirectReportsRow;
