import {
  PropagationStatusCode,
  PropagationTaskType,
  RequestFragment,
  RequestStatus,
} from "api/generated/graphql";
import {
  ProgressStage,
  ProgressStages,
} from "components/ui/progress_stages/ProgressStages";

export type RequestProgressStagesProps = {
  request: RequestFragment;
  accessRevoked: boolean;
  propagationStatusCode?: PropagationStatusCode;
  propagationTaskType?: PropagationTaskType;
  setShowPropagationStatusModal: (show: boolean) => void;
};

export const RequestProgressStages = (props: RequestProgressStagesProps) => {
  const stages: ProgressStage[] = [
    {
      label: "Requested",
      state: "completed",
    },
  ];

  let includeRevocationPill = false;
  if (props.request.durationInMinutes) {
    includeRevocationPill = true;
  }

  const includePropagationPill = !(
    props.request.targetGroupId && props.request.requestedGroups.length > 0
  );

  let propagatedStage: ProgressStage;
  switch (
    props.request.resourceProposal
      ? PropagationStatusCode.Success
      : props.propagationStatusCode
  ) {
    case PropagationStatusCode.Success: {
      propagatedStage = {
        label: "Propagated",
        state: "completed",
      };
      break;
    }
    case PropagationStatusCode.Pending: {
      propagatedStage = {
        label: "Syncing",
        state: "active",
      };
      break;
    }
    case PropagationStatusCode.PendingTicketCreation: {
      propagatedStage = {
        label: "Pending ticket creation",
        state: "active",
      };
      break;
    }
    case PropagationStatusCode.PendingManualPropagation: {
      propagatedStage = {
        label:
          props.propagationTaskType ===
            PropagationTaskType.ResourcesCreateUsers ||
          props.propagationTaskType === PropagationTaskType.GroupsCreateUsers
            ? "Pending manual propagation"
            : "Pending manual revocation",
        state: "active",
      };
      break;
    }
    case undefined: {
      propagatedStage = {
        label: "Propagation",
        state: "uncompleted",
      };
      break;
    }
    default: {
      propagatedStage = {
        label: "Propagation Error",
        state: "cancelled",
      };
      break;
    }
  }

  if (!props.request.resourceProposal) {
    propagatedStage.onClick = () => props.setShowPropagationStatusModal(true);
  }

  switch (props.request.status) {
    case RequestStatus.Pending:
      stages.push({
        label: "Review",
        state: "active",
      });
      if (includePropagationPill) {
        stages.push({
          label: "Propagation",
          state: "uncompleted",
        });
      }
      break;
    case RequestStatus.Approved:
      stages.push({
        label: "Reviewed",
        state: "completed",
      });
      if (includePropagationPill) {
        stages.push(propagatedStage);
      }
      break;
    case RequestStatus.Denied:
      stages.push({
        label: "Denied",
        state: "cancelled",
      });
      if (includePropagationPill) {
        stages.push({
          label: "Propagation",
          state: "uncompleted",
        });
      }
      break;
    case RequestStatus.Canceled:
      stages.push({
        label: "Cancelled",
        state: "cancelled",
      });
      if (includePropagationPill) {
        stages.push({
          label: "Propagation",
          state: "uncompleted",
        });
      }
      break;
  }

  if (includeRevocationPill) {
    stages.push({
      label: props.accessRevoked ? "Revoked" : "Revocation",
      state: props.accessRevoked ? "cancelled" : "uncompleted",
    });
  }

  return <ProgressStages stages={stages} />;
};
