import {
  Maybe,
  PropagationStatusCode,
  PropagationTaskType,
  SupportTicketPreviewFragment,
} from "api/generated/graphql";
import Label, { getLogoByThirdPartyProvider } from "components/label/Label";
import labelStyles from "components/label/Label.module.scss";
import propagationLabelStyles from "components/propagation/PropagationStatusLabel.module.scss";
import { Icon, Tooltip } from "components/ui";
import React from "react";
import * as Icons from "react-feather";

type PropagationStatusLabelProps = {
  propagationStatusCode: Maybe<PropagationStatusCode> | undefined;
  propagationTicket?: SupportTicketPreviewFragment;
  taskType?: PropagationTaskType;
  showText?: boolean;
  pointerCursor?: boolean;
};

export const PropagationStatusLabel = (props: PropagationStatusLabelProps) => {
  const statusCode = props.propagationStatusCode;
  let showText = props.showText;
  let showUrl = true;

  let text = "";
  let action = "access change";
  let stylesClass = labelStyles.statusOkay;
  let icon = <Icons.RefreshCw size={16} strokeWidth={3} />;
  let toolTipPhrase =
    "Access to this resource has been successfully synced with the end system.";
  let url = null;
  if (statusCode) {
    switch (statusCode) {
      case PropagationStatusCode.Success:
        text = "Synced";
        stylesClass = labelStyles.statusOkay;
        icon = <Icons.RefreshCw size={16} strokeWidth={3} />;
        toolTipPhrase =
          "Access to this resource has been successfully synced with the end system.";
        break;
      case PropagationStatusCode.Pending:
        text = "Pending";
        stylesClass = labelStyles.statusNeutral;
        icon = (
          <span className={propagationLabelStyles.spinIcon}>
            <Icons.Loader size={16} strokeWidth={3} />
          </span>
        );
        toolTipPhrase =
          "Access to this resource is in the process of being synced with the end system.";
        break;
      case PropagationStatusCode.PendingTicketCreation:
        text = "Pending ticket creation";
        stylesClass = labelStyles.statusNeutral;
        icon = (
          <span>
            <Icons.Clock size={16} strokeWidth={3} />
          </span>
        );
        toolTipPhrase =
          "Access to this resource is pending, and a ticket is being created to make the change on the end system.";
        break;
      case PropagationStatusCode.PendingManualPropagation:
        text = "Pending manual propagation";
        icon = (
          <span>
            <Icons.Clock size={16} strokeWidth={3} />
          </span>
        );
        if (props.propagationTicket) {
          const ticket = props.propagationTicket;
          text = ticket.identifier;
          showUrl = !!showText;
          showText = true;
          url = ticket.url;
          icon = (
            <span>
              <Icon
                data={{
                  icon: getLogoByThirdPartyProvider(ticket.thirdPartyProvider),
                  type: "src",
                }}
              />
            </span>
          );
        }
        stylesClass = labelStyles.statusNeutral;
        switch (props.taskType) {
          case PropagationTaskType.ResourcesCreateUsers:
          case PropagationTaskType.GroupsCreateUsers:
            action = "access grant";
            break;
          case PropagationTaskType.ResourcesDeleteUsers:
          case PropagationTaskType.GroupsDeleteUsers:
            action = "access removal";
            break;
        }
        toolTipPhrase = `This ${action} is pending, and a ticket has been created to make the change on the end system.`;
        break;
      default:
        text = "Failed to sync";
        stylesClass = labelStyles.statusError;
        icon = <Icons.AlertCircle size={16} strokeWidth={3} />;
        toolTipPhrase =
          "Access to this resource has failed to sync with the end system.";
    }
  }

  return (
    <Tooltip tooltipText={toolTipPhrase}>
      <Label
        externalUrl={showUrl ? url : undefined}
        text={showText ? text : ""}
        icon={icon}
        additionalStyleClass={stylesClass}
        pointerCursor={props.pointerCursor}
      />
    </Tooltip>
  );
};
