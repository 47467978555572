import {
  ResourcePreviewSmallFragment,
  Visibility,
} from "api/generated/graphql";
import { ResourceLabel } from "components/label/Label";
import { SectionEntry } from "components/viewer/Viewer";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";

import * as styles from "./ResourceVisibilityRow.css";

type ResourceVisibilityRowProps = {
  resource: ResourcePreviewSmallFragment;
};

export const ResourceVisibilityRow = (props: ResourceVisibilityRowProps) => {
  const limitedVisiblityText = "Admins and users with access";

  const renderValue = () => {
    if (props.resource.visibility === Visibility.Global) {
      return "Global";
    } else if (props.resource.visibilityGroups.length === 0) {
      return limitedVisiblityText;
    } else {
      return props.resource.visibilityGroups.map((visibilityGroup) => {
        return (
          <ResourceLabel
            text={visibilityGroup?.visibilityGroup?.name || "Private group"}
            entityType={EntityTypeDeprecated.Group}
            entityId={visibilityGroup.visibilityGroupId}
          />
        );
      });
    }
  };

  return (
    <div className={styles.visibilityContainer}>
      <SectionEntry
        title="Visibility"
        tooltipText="Specifies who can see and access this resource."
      >
        {renderValue()}
      </SectionEntry>
    </div>
  );
};

export default ResourceVisibilityRow;
