import { gql, useFragment } from "@apollo/client";
import {
  EntityType,
  GroupBindingTinyFragmentDoc,
  GroupDisplayFragment,
} from "api/generated/graphql";
import GroupBindingDetailPopover from "components/group_bindings/GroupBindingDetailPopover";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import { EntityIcon, Icon, Label } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { Link } from "react-router-dom";
import { getResourceUrlNew } from "utils/common";

import * as styles from "./EntityDisplay.css";

const GroupDisplay = ({ groupId }: { groupId: string }) => {
  const { complete, data: group } = useFragment<GroupDisplayFragment>({
    fragment: GroupDisplay.fragment,
    fragmentName: "GroupDisplay",
    from: {
      __typename: "Group",
      id: groupId,
    },
  });

  if (!complete) {
    return null;
  }

  let parentApp = null;
  switch (group.parentApp?.app.__typename) {
    case "ConnectionApp":
      parentApp = group.parentApp.app.connection;
      break;
    case "OktaResourceApp":
      parentApp = group.parentApp.app.resource;
      break;
  }
  const groupName = group.name;
  const entityIconType = group?.connection?.connectionType ?? group?.groupType;
  const imageUrl = parentApp?.imageUrl ?? group.connection?.imageUrl;
  const breadcrumb = parentApp
    ? `${parentApp.name}/`
    : group.connection
      ? `${group.connection?.name}/`
      : "";

  return (
    <Link
      to={getResourceUrlNew({
        entityId: group.id,
        entityType: EntityType.Group,
      })}
      className={styles.displayLayout}
    >
      {imageUrl ? (
        <Icon
          data={{ type: "src", icon: imageUrl }}
          iconStyle="rounded"
          size="lg"
        />
      ) : (
        <EntityIcon type={entityIconType} iconStyle="rounded" size="lg" />
      )}
      <div className={sprinkles({ flexGrow: 1 })}>
        <div className={styles.entityName}>
          {groupName}
          {group.groupBinding && (
            <span className={sprinkles({ marginLeft: "sm" })}>
              <GroupBindingDetailPopover
                groupId={group.id}
                groupBinding={group.groupBinding}
              />
            </span>
          )}
        </div>
        {breadcrumb && <div className={styles.breadcrumb}>{breadcrumb}</div>}
      </div>
      <Label
        color="gray900"
        inline
        icon={{
          type: "entity",
          entityType: group.groupType,
          includeBrand: false,
        }}
        label={groupTypeInfoByType[group.groupType].name}
      />
    </Link>
  );
};

GroupDisplay.fragment = gql`
  fragment GroupDisplay on Group {
    id
    name
    groupType
    parentApp {
      id
      app {
        ... on ConnectionApp {
          connectionId
          connection {
            id
            name
            imageUrl
            connectionType
          }
        }
        ... on OktaResourceApp {
          resourceId
          resource {
            id
            name
            imageUrl
            resourceType
          }
        }
      }
    }
    connection {
      id
      name
      imageUrl
      connectionType
    }
    groupBinding {
      ...GroupBindingTiny
    }
  }
  ${GroupBindingTinyFragmentDoc}
`;

export default GroupDisplay;
