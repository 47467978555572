import { openInNewTab } from "api/common/common";
import {
  AppCategory,
  ConnectionPreviewLargeFragment,
  ConnectionType,
} from "api/generated/graphql";
import activeDirectoryLogo from "assets/logos/active-directory-logo.png";
import awsLogo from "assets/logos/aws-logo.svg";
import azureLogo from "assets/logos/azure-logo.svg";
import customLogo from "assets/logos/custom-logo.png";
import duoLogo from "assets/logos/duo-logo.svg";
import gcpLogo from "assets/logos/gcp-logo-new.svg";
import githubLogo from "assets/logos/github-logo.png";
import gitlabLogo from "assets/logos/gitlab-logo.svg";
import googleGroupsLogo from "assets/logos/googlegroups-logo.png";
import googleWorkspaceLogo from "assets/logos/googleworkspace-logo.png";
import ldapLogo from "assets/logos/ldap-logo.png";
import mariadbLogo from "assets/logos/mariadb-logo.png";
import mongoLogo from "assets/logos/mongodb-logo.png";
import mySqlLogo from "assets/logos/mysql-logo.png";
import oktaLogo from "assets/logos/okta-logo.png";
import pagerdutyLogo from "assets/logos/pager-duty-logo.png";
import postgresLogo from "assets/logos/postgres-logo.svg";
import requestServiceLogo from "assets/logos/request-a-service-icon.svg";
import salesforceLogo from "assets/logos/salesforce-logo.png";
import snowflakeLogo from "assets/logos/snowflake-logo.svg";
import tailscaleLogo from "assets/logos/tailscale-logo.png";
import teleportLogo from "assets/logos/teleport-logo.png";
import workdayLogo from "assets/logos/workday-logo.png";
import clsx from "clsx";
import ColumnHeader from "components/column/ColumnHeader";
import { ButtonV3, Divider, Icon, Tooltip } from "components/ui";
import { IconData } from "components/ui/utils";
import pluralize from "pluralize";
import * as Icons from "react-feather";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import { ConditionalWrapper } from "views/Common";
import styles from "views/connections/create/BrowseServices.module.scss";

export type CategoryInfo = {
  name: string;
  category: AppCategory;
  icon?: IconData | null;
  brandIcon?: PropsFor<typeof Icon>["brandIcon"];
};

export const categoryInfoByCategory: Record<AppCategory, CategoryInfo> = {
  [AppCategory.All]: {
    name: "All Categories",
    category: AppCategory.All,
    icon: null,
  },
  [AppCategory.CloudProviders]: {
    name: "Cloud Providers",
    category: AppCategory.CloudProviders,
    icon: { type: "name", icon: "cloud" },
  },
  [AppCategory.Custom]: {
    name: "Custom",
    category: AppCategory.Custom,
    icon: { type: "name", icon: "tool" },
  },
  [AppCategory.Databases]: {
    name: "Databases",
    category: AppCategory.Databases,
    icon: { type: "name", icon: "database" },
  },
  [AppCategory.Identity]: {
    name: "Identity & Groups Providers",
    category: AppCategory.Identity,
    icon: { type: "name", icon: "user" },
  },
  [AppCategory.DeveloperTools]: {
    name: "Developer Tools",
    category: AppCategory.DeveloperTools,
    icon: { type: "name", icon: "code" },
  },
  [AppCategory.OktaApp]: {
    name: "Okta Apps",
    category: AppCategory.OktaApp,
    icon: { type: "name", icon: "apps" },
    brandIcon: "brand-okta",
  },
};

type UnimplementedConnectionInfo = {
  urlDirectory: string;
  logo: string;
  name: string;
  category: CategoryInfo;
};

export type ConnectionInfo = {
  connectionType: ConnectionType;
} & UnimplementedConnectionInfo;

export const CATEGORY_BY_CONNECTION_TYPE: Record<
  ConnectionType,
  AppCategory
> = {
  [ConnectionType.ActiveDirectory]: AppCategory.Identity,
  [ConnectionType.Aws]: AppCategory.CloudProviders,
  [ConnectionType.AwsSso]: AppCategory.CloudProviders,
  [ConnectionType.AzureAd]: AppCategory.CloudProviders,
  [ConnectionType.Duo]: AppCategory.Identity,
  [ConnectionType.Gcp]: AppCategory.CloudProviders,
  [ConnectionType.GoogleGroups]: AppCategory.Identity,
  [ConnectionType.GoogleWorkspace]: AppCategory.Identity,
  [ConnectionType.Custom]: AppCategory.Custom,
  [ConnectionType.CustomConnector]: AppCategory.Custom,
  [ConnectionType.GitHub]: AppCategory.DeveloperTools,
  [ConnectionType.GitLab]: AppCategory.DeveloperTools,
  [ConnectionType.Ldap]: AppCategory.Identity,
  [ConnectionType.Mongo]: AppCategory.Databases,
  [ConnectionType.MongoAtlas]: AppCategory.Databases,
  [ConnectionType.OktaDirectory]: AppCategory.Identity,
  [ConnectionType.Opal]: AppCategory.Identity,
  [ConnectionType.Pagerduty]: AppCategory.DeveloperTools,
  [ConnectionType.Tailscale]: AppCategory.DeveloperTools,
  [ConnectionType.Salesforce]: AppCategory.CloudProviders,
  [ConnectionType.Mysql]: AppCategory.Databases,
  [ConnectionType.Mariadb]: AppCategory.Databases,
  [ConnectionType.Postgres]: AppCategory.Databases,
  [ConnectionType.Teleport]: AppCategory.DeveloperTools,
  [ConnectionType.Workday]: AppCategory.Identity,
  [ConnectionType.Snowflake]: AppCategory.Databases,
};

export const CONNECTIONS_LIST: ConnectionInfo[] = [
  {
    connectionType: ConnectionType.ActiveDirectory,
    urlDirectory: "active_directory",
    logo: activeDirectoryLogo,
    name: "Active Directory",
    category:
      categoryInfoByCategory[
        CATEGORY_BY_CONNECTION_TYPE[ConnectionType.ActiveDirectory]
      ],
  },
  {
    connectionType: ConnectionType.Aws,
    urlDirectory: "aws",
    logo: awsLogo,
    name: "Amazon Web Services (Legacy)",
    category:
      categoryInfoByCategory[CATEGORY_BY_CONNECTION_TYPE[ConnectionType.Aws]],
  },
  {
    connectionType: ConnectionType.AwsSso,
    urlDirectory: "aws_sso",
    logo: awsLogo,
    name: "Amazon Web Services",
    category:
      categoryInfoByCategory[
        CATEGORY_BY_CONNECTION_TYPE[ConnectionType.AwsSso]
      ],
  },
  {
    connectionType: ConnectionType.Duo,
    urlDirectory: "duo",
    logo: duoLogo,
    name: "Duo",
    category:
      categoryInfoByCategory[CATEGORY_BY_CONNECTION_TYPE[ConnectionType.Duo]],
  },
  {
    connectionType: ConnectionType.Gcp,
    urlDirectory: "gcp",
    logo: gcpLogo,
    name: "Google Cloud Platform",
    category:
      categoryInfoByCategory[CATEGORY_BY_CONNECTION_TYPE[ConnectionType.Gcp]],
  },
  {
    connectionType: ConnectionType.GoogleGroups,
    urlDirectory: "google_groups",
    logo: googleGroupsLogo,
    name: "Google Groups",
    category:
      categoryInfoByCategory[
        CATEGORY_BY_CONNECTION_TYPE[ConnectionType.GoogleGroups]
      ],
  },
  {
    connectionType: ConnectionType.GoogleWorkspace,
    urlDirectory: "google_workspace",
    logo: googleWorkspaceLogo,
    name: "Google Workspace",
    category:
      categoryInfoByCategory[
        CATEGORY_BY_CONNECTION_TYPE[ConnectionType.GoogleWorkspace]
      ],
  },
  {
    connectionType: ConnectionType.Custom,
    urlDirectory: "custom",
    logo: customLogo,
    name: "Custom App",
    category:
      categoryInfoByCategory[
        CATEGORY_BY_CONNECTION_TYPE[ConnectionType.Custom]
      ],
  },
  {
    connectionType: ConnectionType.GitHub,
    urlDirectory: "github",
    logo: githubLogo,
    name: "GitHub",
    category:
      categoryInfoByCategory[
        CATEGORY_BY_CONNECTION_TYPE[ConnectionType.GitHub]
      ],
  },
  {
    connectionType: ConnectionType.GitLab,
    urlDirectory: "gitlab",
    logo: gitlabLogo,
    name: "GitLab",
    category:
      categoryInfoByCategory[
        CATEGORY_BY_CONNECTION_TYPE[ConnectionType.GitLab]
      ],
  },
  {
    connectionType: ConnectionType.Ldap,
    urlDirectory: "ldap",
    logo: ldapLogo,
    name: "LDAP",
    category:
      categoryInfoByCategory[CATEGORY_BY_CONNECTION_TYPE[ConnectionType.Ldap]],
  },
  {
    connectionType: ConnectionType.Mongo,
    urlDirectory: "mongo",
    logo: mongoLogo,
    name: "MongoDB",
    category:
      categoryInfoByCategory[CATEGORY_BY_CONNECTION_TYPE[ConnectionType.Mongo]],
  },
  {
    connectionType: ConnectionType.MongoAtlas,
    urlDirectory: "mongo_atlas",
    logo: mongoLogo,
    name: "MongoDB Atlas",
    category:
      categoryInfoByCategory[
        CATEGORY_BY_CONNECTION_TYPE[ConnectionType.MongoAtlas]
      ],
  },
  {
    connectionType: ConnectionType.OktaDirectory,
    urlDirectory: "okta_directory",
    logo: oktaLogo,
    name: "Okta Directory",
    category:
      categoryInfoByCategory[
        CATEGORY_BY_CONNECTION_TYPE[ConnectionType.OktaDirectory]
      ],
  },
  {
    connectionType: ConnectionType.Pagerduty,
    urlDirectory: "pagerduty",
    logo: pagerdutyLogo,
    name: "PagerDuty",
    category:
      categoryInfoByCategory[
        CATEGORY_BY_CONNECTION_TYPE[ConnectionType.Pagerduty]
      ],
  },
  {
    connectionType: ConnectionType.Tailscale,
    urlDirectory: "tailscale",
    logo: tailscaleLogo,
    name: "Tailscale",
    category:
      categoryInfoByCategory[
        CATEGORY_BY_CONNECTION_TYPE[ConnectionType.Tailscale]
      ],
  },
  {
    connectionType: ConnectionType.Salesforce,
    urlDirectory: "salesforce",
    logo: salesforceLogo,
    name: "Salesforce",
    category:
      categoryInfoByCategory[
        CATEGORY_BY_CONNECTION_TYPE[ConnectionType.Salesforce]
      ],
  },
  {
    connectionType: ConnectionType.Mysql,
    urlDirectory: "mysql",
    logo: mySqlLogo,
    name: "MySQL",
    category:
      categoryInfoByCategory[CATEGORY_BY_CONNECTION_TYPE[ConnectionType.Mysql]],
  },
  {
    connectionType: ConnectionType.Mariadb,
    urlDirectory: "mariadb",
    logo: mariadbLogo,
    name: "MariaDB",
    category:
      categoryInfoByCategory[
        CATEGORY_BY_CONNECTION_TYPE[ConnectionType.Mariadb]
      ],
  },
  {
    connectionType: ConnectionType.Postgres,
    urlDirectory: "postgres",
    logo: postgresLogo,
    name: "PostgreSQL",
    category:
      categoryInfoByCategory[
        CATEGORY_BY_CONNECTION_TYPE[ConnectionType.Postgres]
      ],
  },
  {
    connectionType: ConnectionType.Teleport,
    urlDirectory: "teleport",
    logo: teleportLogo,
    name: "Teleport",
    category:
      categoryInfoByCategory[
        CATEGORY_BY_CONNECTION_TYPE[ConnectionType.Teleport]
      ],
  },
  {
    connectionType: ConnectionType.AzureAd,
    urlDirectory: "azure_ad",
    logo: azureLogo,
    name: "Azure",
    category:
      categoryInfoByCategory[
        CATEGORY_BY_CONNECTION_TYPE[ConnectionType.AzureAd]
      ],
  },
  {
    connectionType: ConnectionType.Snowflake,
    urlDirectory: "snowflake",
    logo: snowflakeLogo,
    name: "Snowflake",
    category:
      categoryInfoByCategory[
        CATEGORY_BY_CONNECTION_TYPE[ConnectionType.Snowflake]
      ],
  },
  {
    connectionType: ConnectionType.Workday,
    urlDirectory: "workday",
    logo: workdayLogo,
    name: "Workday",
    category:
      categoryInfoByCategory[
        CATEGORY_BY_CONNECTION_TYPE[ConnectionType.Workday]
      ],
  },
];

export const BrowseServicesList = ({
  services,
  connections,
  selectedCategory,
}: {
  services: ConnectionInfo[];
  connections: ConnectionPreviewLargeFragment[];
  selectedCategory: string;
}) => {
  const connectionCountByType: Record<string, number> = {};
  connections.forEach((connection) => {
    if (connection) {
      connectionCountByType[connection.connectionType] =
        connectionCountByType[connection.connectionType] != null
          ? connectionCountByType[connection.connectionType] + 1
          : 1;
    }
  });

  const header = (
    <>
      <ColumnHeader
        title={selectedCategory}
        subtitle={`${services.length} available integrations`}
      />
      <Divider />
    </>
  );

  return (
    <div className={styles.listContainer}>
      {header}
      <div className={styles.content}>
        {services.map((app, index) => (
          <div
            className={clsx({
              [styles.card]: true,
            })}
            key={`onboarding_card_${index}`}
          >
            <div className={styles.mainContainer}>
              <div className={styles.logoBox}>
                <img alt={app.logo} src={app.logo} />
              </div>
              <div className={styles.text}>
                <span className={styles.name}>{app.name}</span>
                <span className={styles.category}>{app.category.name}</span>
              </div>
              <div className={styles.connectionCount}>
                <div
                  className={clsx({
                    [styles.status]: true,
                    [styles.statusDisconnected]:
                      connectionCountByType[app.connectionType] == null,
                    [styles.statusConnected]:
                      connectionCountByType[app.connectionType] != null,
                  })}
                />
                {connectionCountByType[app.connectionType] ? (
                  <div className={styles.statusText}>{`${pluralize(
                    "App",
                    connectionCountByType[app.connectionType],
                    true
                  )}`}</div>
                ) : (
                  <div className={styles.statusText}>{`No Connections`}</div>
                )}
              </div>
            </div>
            <div className={styles.border} />
            <div className={styles.outerContainer}>
              <div className={styles.connectLabel}>
                <div className={styles.connectEnabled}>
                  <Icons.Plus strokeWidth={3} size={12} />
                  <span className={styles.text}>
                    <Link to={`create/${app.urlDirectory}`}>Connect</Link>
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className={styles.card}>
          <div className={styles.mainContainer}>
            <img
              alt={"question-mark"}
              src={requestServiceLogo}
              className={styles.logoBox}
            />
            <div className={styles.text}>
              <span className={styles.name}>{`Can't find it?`}</span>
              <span className={styles.category}>Request an integration</span>
            </div>
          </div>
          <div className={styles.border} />
          <div className={styles.outerContainer}>
            <div className={styles.connectLabel}>
              <div className={styles.connectEnabled}>
                <Icons.MessageSquare strokeWidth={3} size={12} />
                <span className={styles.text}>
                  <a
                    target="_blank"
                    rel="noopener noreferrer"
                    href={"mailto:support@opal.dev"}
                  >
                    Request
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const BrowseServicesListV3 = ({
  services,
  connections,
}: {
  services: ConnectionInfo[];
  connections: ConnectionPreviewLargeFragment[];
}) => {
  const history = useHistory();

  const connectionCountByType: Record<string, number> = {};
  connections.forEach((connection) => {
    if (connection) {
      connectionCountByType[connection.connectionType] =
        connectionCountByType[connection.connectionType] != null
          ? connectionCountByType[connection.connectionType] + 1
          : 1;
    }
  });

  return (
    <div className={styles.listContainerV3}>
      <div className={styles.contentV3}>
        {services.map((app, index) => {
          const truncatedAppName = `${app.name.substring(0, 50)}${
            app.name.length >= 50 ? "..." : ""
          }`;
          return (
            <div className={styles.cardV3} key={`onboarding_card_${index}`}>
              <div className={styles.mainContainerV3}>
                <div className={styles.logoBoxV3}>
                  <img alt={app.logo} src={app.logo} />
                </div>
                <div className={styles.textV3}>
                  <ConditionalWrapper
                    wrapper={(children) => (
                      <Tooltip tooltipText={app.name}>{children}</Tooltip>
                    )}
                    useWrapper={app.name.length >= 50}
                  >
                    <span className={styles.nameV3}>{truncatedAppName}</span>
                  </ConditionalWrapper>
                  <span className={styles.categoryV3}>{app.category.name}</span>
                </div>
                <div className={styles.connectionCountV3}>
                  <Icon name="account" size="xs" />
                  {connectionCountByType[app.connectionType] ? (
                    <div className={styles.statusTextV3}>{`${pluralize(
                      "App",
                      connectionCountByType[app.connectionType],
                      true
                    )}`}</div>
                  ) : (
                    <div
                      className={styles.statusTextV3}
                    >{`No Connections`}</div>
                  )}
                </div>
                <ButtonV3
                  label="Connect"
                  leftIconName="plus"
                  type="mainSecondary"
                  onClick={() => {
                    history.push(`/apps/create/${app.urlDirectory}`);
                  }}
                />
              </div>
            </div>
          );
        })}

        <div className={styles.cardV3}>
          <div className={styles.mainContainerV3}>
            <div>
              <img
                alt={"question-mark"}
                src={requestServiceLogo}
                className={styles.logoBoxV3}
              />
              <div className={styles.textV3}>
                <span className={styles.nameV3}>{`Can't find it?`}</span>
                <span className={styles.categoryV3}>Request an app</span>
              </div>
            </div>
            <ButtonV3
              label="Request"
              leftIconName="message"
              type="mainSecondary"
              onClick={() => {
                openInNewTab("mailto:support@opal.dev");
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
