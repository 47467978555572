import { faker } from "@faker-js/faker";
import { ConnectionType } from "api/generated/graphql";
import { Icon } from "components/ui";
import EntityIcon from "components/ui/entityicon/EntityIcon";
import { randomElementInList } from "utils/random";

import { IconGroup } from "./icon_group/IconGroup";

/** Generates a random ID, mainly for use in htmlFor and labels.
 *  prefix - prefix for the ID
 */
export const generateUniqueId = (prefix: string) =>
  Math.random()
    .toString(36)
    .replace("0.", prefix + "_");

export type IconData =
  | {
      type: "src";
      style?: "default" | "rounded";
      icon: string | undefined;
      fallbackIcon?: string | undefined;
    }
  | {
      type: "name";
      style?: "default" | "rounded";
      icon: PropsFor<typeof Icon>["name"];
    }
  | {
      type: "entity";
      entityType: PropsFor<typeof EntityIcon>["type"];
      style?: "default" | "rounded";
      includeBrand?: boolean;
      onlyBrandIcon?: boolean;
    };

export const connectionTypes = Object.values(
  ConnectionType
) as Array<ConnectionType>;

export const makeResource = (): PropsFor<typeof IconGroup>["items"][0] => {
  return {
    key: faker.string.uuid(),
    name: faker.hacker.noun(),
    linkTo: faker.internet.url(),
    icon: {
      type: "entity",
      entityType: randomElementInList(connectionTypes),
    },
  };
};

export const makeUser = (): PropsFor<typeof IconGroup>["items"][0] => {
  return {
    key: faker.string.uuid(),
    name: faker.person.fullName(),
    linkTo: faker.internet.url(),
    icon: {
      type: "src",
      icon: faker.image.avatar(),
    },
  };
};
