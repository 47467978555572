import {
  ConnectionType,
  ResourceType,
  SyncErrorFragment,
  SyncTaskFragment,
  SyncType,
} from "api/generated/graphql";
import moment from "moment";
import {
  isNativeGroupType,
  isNativeResourceType,
} from "utils/directory/resources";
import { logError } from "utils/logging";

import { Props } from "./SyncButtons";

export const getLabelForSyncStatus = (
  lastSuccessfulSyncTask: SyncTaskFragment | null,
  syncErrors: SyncErrorFragment[]
) => {
  let label: string;
  if (syncErrors.length > 0) {
    const sortedErrors = syncErrors
      .slice()
      .sort((a, b) => +new Date(b.createdAt) - +new Date(a.createdAt));
    let newestSyncError = sortedErrors[0];

    label = moment(newestSyncError.createdAt).fromNow();
  } else if (lastSuccessfulSyncTask) {
    label = moment(lastSuccessfulSyncTask.updatedAt).fromNow();
  } else {
    label = "Not yet synced";
  }
  return label;
};

export const getLabelForToasts = (props: Props) => {
  switch (props.syncType) {
    case SyncType.PullConnectionsAll:
      return "all resources and groups";
    case SyncType.PullConnectionsAllResources:
      return "all resources";
    case SyncType.PullConnectionsAllGroups:
      return "all groups";
    case SyncType.PullConnectionsSingleConnection:
    case SyncType.PullConnectionsSingleConnectionFast:
      if (props.connection) {
        return `app "${props.connection.name}"`;
      } else if (!props.loadingEntity) {
        logError(new Error(`SyncButtons app must not be undefined`));
        return;
      }
      break;
    case SyncType.PullConnectionsSingleResource:
      if (props.resource) {
        if (isNativeResourceType(props.resource.resourceType)) {
          return;
        }
        return `resource "${props.resource.name}"`;
      } else if (!props.loadingEntity) {
        logError(new Error(`SyncButtons resource must not be undefined`));
        return;
      }
      break;
    case SyncType.PullConnectionsSingleGroup:
      if (props.group) {
        if (
          isNativeGroupType(props.group.groupType) &&
          // Allow on-call team reviewers sync
          !props.group.isOnCallSynced
        ) {
          return;
        }
        return `group "${props.group.name}"`;
      } else if (!props.loadingEntity) {
        logError(new Error(`SyncButtons group must not be undefined`));
        return;
      }
      break;
    case SyncType.PullHrIdpData:
      return "all HR and IDP data";
    case SyncType.PullUarRemoteTickets:
      return "all linked tickets";
    case SyncType.PullPropagationTickets:
      if (
        props.resource &&
        props.resource.resourceType === ResourceType.Custom
      ) {
        return `resource "${props.resource.name}"`;
      } else if (
        props.connection &&
        props.connection.connectionType === ConnectionType.Custom
      ) {
        return `app "${props.connection.name}"`;
      } else if (!props.loadingEntity) {
        logError(
          new Error(
            `SyncButtons resource must not be undefined for custom resources`
          )
        );
        return;
      }
      break;
    default:
      logError(
        new Error(`unsupported SyncType in SyncButtons: ${props.syncType}`)
      );
      return;
  }
};
