import { EntityId } from "api/common/common";
import {
  ActiveRequestConfigurationFragment,
  BundleItemFragment,
  EntityType,
  GeneralSettingType,
  GroupAccessLevel,
  GroupPreviewLargeFragment,
  IntegrationType,
  Maybe,
  OidcProviderType,
  RequestCustomMetadataInput,
  RequestedGroupInput,
  RequestedResourceInput,
  RequestStatus,
  RequestTemplateCustomFieldType,
  RequestTemplateFragment,
  ResourceAccessLevel,
  ResourceAccessLevelFragment,
  ResourcePreviewLargeFragment,
  SupportTicketPreviewFragment,
  ThirdPartyProvider,
  useBulkActiveGroupRequestConfigsQuery,
  useBulkActiveResourceRequestConfigsQuery,
  useBulkRequestColumnGroupsQuery,
  useBulkRequestColumnResourcesQuery,
  useCreateRequestMutation,
  useInitOidcAuthFlowMutation,
  useMultipleGroupAccessLevelsQuery,
  useMultipleResourceAccessLevelsQuery,
  UserDropdownPreviewFragment,
  useRequestTemplatesWithFieldsQuery,
  useThirdPartyIntegrationsQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import { opalConfetti } from "components/confetti/Confetti";
import { isDurationValueCustom } from "components/modals/EditDurationModal";
import { IdpMfaModal } from "components/modals/IdpMfaModal";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import RequestModalRoleDropdown from "components/modals/RequestModalRoleDropdown";
import RequestCustomFieldRow from "components/requests/RequestCustomFieldRow";
import RequestDurationPicker from "components/requests/RequestDurationPicker";
import TicketProviderDropdown from "components/tickets/TicketProviderDropdown";
import { useToast } from "components/toast/Toast";
import {
  Banner,
  Button,
  DataElement,
  Divider,
  FormGroup,
  Input,
  Link,
  Skeleton,
  Switch,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import pluralize from "pluralize";
import { isValidElement, useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import {
  getIsGlobalImpersonationResource,
  getItemRequiresRoles,
} from "utils/access_requests";
import { generateState } from "utils/auth/auth";
import {
  getResourceUrlNew,
  getSupportTicketThirdPartyProviders,
} from "utils/common";
import { OPAL_IMPERSONATION_REMOTE_ID } from "utils/constants";
import {
  connectionRequiresThirdPartyProvider,
  thirdPartyProviderByConnectionType,
  thirdPartyProviderNameByThirdPartyProvider,
} from "utils/directory/third_party_providers";
import { logError, logWarning } from "utils/logging";
import { MfaCustomParams } from "utils/mfa/mfa";
import { setOidcData } from "utils/oidc/oidc";
import { calculateMaxDurationInMinutes } from "views/Common";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import RequestForUserDropdown from "views/requests/RequestForUserDropdown";
import RequestModalMfaFiller from "views/requests/RequestModalMfaFiller";
import { getExtraParamsByConnectionMetadata } from "views/requests/utils";
import {
  expirationValueToDurationInMinutes,
  getCurrentUserGroupAccessSummary,
  getCurrentUserResourceAccessSummary,
  getDefaultRequestDurationMinutes,
  getDefaultRequestExpiration,
} from "views/requests/utils";
import OrgContext from "views/settings/OrgContext";
import { SupportTicketsField } from "views/support_tickets/SupportTickets";

import {
  AppsContext,
  SELECTED_BUNDLE_ITEMS_LOCAL_STORAGE_KEY,
  SELECTED_ITEMS_LOCAL_STORAGE_KEY,
  SelectedItem,
} from "./AppsContext";
import * as styles from "./BulkRequestColumn.css";

const BulkRequestColumn = () => {
  const {
    selectedItems: selectedAppItems,
    selectedBundleItems,
    clearBundleItems,
    clearSelectedItems,
    bulkMode,
    setBulkMode,
    clearItem,
  } = useContext(AppsContext);

  const history = useHistory();
  const location = useLocation();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [customMetadata, setCustomMetadata] = useState<
    Record<string, RequestCustomMetadataInput>
  >({});
  const [isIdpMfaModalOpen, setIsIdpMfaModalOpen] = useState(false);
  const { authState } = useContext(AuthContext);
  const { orgState } = useContext(OrgContext);
  const { displaySuccessToast, displayErrorToast } = useToast();

  const [targetUser, setTargetUser] = useState<
    UserDropdownPreviewFragment | undefined
  >(authState.user?.user ? authState.user.user : undefined);

  const initialSelectedRoleByItemId: Record<EntityId, ResourceAccessLevel> = {};

  const selectedItems =
    selectedAppItems.length > 0 ? selectedAppItems : selectedBundleItems;
  const isBundleRequest =
    location.pathname.includes("bundles") && selectedBundleItems.length > 0;

  const resourceIdsToFetch: string[] = [];
  const groupIdsToFetch: string[] = [];
  const requestableBundleItems: BundleItemFragment[] = [];
  selectedItems.forEach((item) => {
    // Bundle item
    if ("resource" in item && item.resource?.isRequestable) {
      resourceIdsToFetch.push(item.resource.id);
      requestableBundleItems.push(item);
      if (isBundleRequest) {
        initialSelectedRoleByItemId[item.resource.id] = {
          accessLevelName: item.accessLevelName,
          accessLevelRemoteId: item.accessLevelRemoteId,
        };
      }
    } else if ("group" in item && item.group?.isRequestable) {
      groupIdsToFetch.push(item.group.id);
      requestableBundleItems.push(item);
      if (isBundleRequest) {
        initialSelectedRoleByItemId[item.group.id] = {
          accessLevelName: item.accessLevelName,
          accessLevelRemoteId: item.accessLevelRemoteId,
        };
      }
      // App item
    } else if ("resourceType" in item && item.isRequestable) {
      resourceIdsToFetch.push(item.id);
    } else if ("groupType" in item && item.isRequestable) {
      groupIdsToFetch.push(item.id);
    }
  });

  const [selectedRoleByItemId, setSelectedRoleByItemId] = useState<
    Record<EntityId, ResourceAccessLevel | GroupAccessLevel>
  >(initialSelectedRoleByItemId);

  const {
    data: bulkRequestColumnResourcesData,
    error: bulkRequestColumnResourcesError,
    loading: bulkRequestColumnResourcesLoading,
  } = useBulkRequestColumnResourcesQuery({
    variables: {
      resourceIds: resourceIdsToFetch,
    },
    skip: resourceIdsToFetch.length === 0,
  });

  const {
    data: bulkRequestColumnGroupsData,
    error: bulkRequestColumnGroupsError,
    loading: bulkRequestColumnGroupsLoading,
  } = useBulkRequestColumnGroupsQuery({
    variables: {
      groupIds: groupIdsToFetch,
    },
    skip: groupIdsToFetch.length === 0,
    fetchPolicy: "network-only",
  });

  let groups = bulkRequestColumnGroupsData?.groups.groups || [];
  let resources = bulkRequestColumnResourcesData?.resources.resources || [];
  let requestableItems = [...groups, ...resources];

  const {
    data: resourceRequestConfigData,
    error: resourceRequestConfigError,
    loading: resourceRequestConfigLoading,
  } = useBulkActiveResourceRequestConfigsQuery({
    variables: {
      targetUserId: targetUser?.id,
      resourceInputs: resourceIdsToFetch.map((resourceId) => ({
        resourceId: resourceId,
        accessLevelRemoteId:
          selectedRoleByItemId[resourceId]?.accessLevelRemoteId,
      })),
    },
    skip: resourceIdsToFetch.length === 0,
    fetchPolicy: "network-only",
  });

  const {
    data: groupRequestConfigData,
    error: groupRequestConfigError,
    loading: groupRequestConfigLoading,
  } = useBulkActiveGroupRequestConfigsQuery({
    variables: {
      targetUserId: targetUser?.id,
      groupInputs: groupIdsToFetch.map((groupId) => ({
        groupId: groupId,
        accessLevelRemoteId: selectedRoleByItemId[groupId]?.accessLevelRemoteId,
      })),
    },
    skip: groupIdsToFetch.length === 0,
  });

  const activeRequestConfigurationByItemId: Record<
    EntityId,
    ActiveRequestConfigurationFragment
  > = {};

  if (resourceRequestConfigData?.activeResourceRequestConfigurations) {
    resourceRequestConfigData.activeResourceRequestConfigurations.forEach(
      (config) => {
        activeRequestConfigurationByItemId[config.resourceId] =
          config.requestConfiguration;
      }
    );
  }

  if (groupRequestConfigData?.activeGroupRequestConfigurations) {
    groupRequestConfigData.activeGroupRequestConfigurations.forEach(
      (config) => {
        activeRequestConfigurationByItemId[config.groupId] =
          config.requestConfiguration;
      }
    );
  }
  const requestConfigLoading =
    resourceRequestConfigLoading || groupRequestConfigLoading;

  const resourcesByResourceId: Record<
    string,
    ResourcePreviewLargeFragment
  > = {};
  const groupsByGroupId: Record<string, GroupPreviewLargeFragment> = {};
  const requestTemplateIds: string[] = [];

  requestableItems.forEach((item) => {
    switch (item.__typename) {
      case "Resource":
        resourcesByResourceId[item.id] = item;
        break;
      case "Group":
        groupsByGroupId[item.id] = item;
        break;
    }
    const requestTemplateId =
      activeRequestConfigurationByItemId[item.id]?.requestTemplate?.id;
    if (requestTemplateId) {
      requestTemplateIds.push(requestTemplateId);
    }
  });

  const { data: requestTemplateData } = useRequestTemplatesWithFieldsQuery({
    variables: {
      input: {
        ids: requestTemplateIds,
      },
    },
    skip: requestTemplateIds.length === 0,
  });

  const requestTemplateById: {
    [templateId: string]: RequestTemplateFragment;
  } = {};
  if (requestTemplateData?.requestTemplatesWithFields) {
    requestTemplateData.requestTemplatesWithFields.requestTemplates.forEach(
      (template) => {
        requestTemplateById[template.id] = template;
      }
    );
  }

  const {
    data: thirdPartyUserIntegrationsData,
    error: thirdPartyUserIntegrationsError,
  } = useThirdPartyIntegrationsQuery({
    variables: {
      input: {
        integrationType: IntegrationType.User,
      },
    },
  });

  const [
    createRequest,
    { loading: createRequestLoading },
  ] = useCreateRequestMutation({
    refetchQueries: ["RequestsPendingStat", "RequestStats"],
  });
  const [initOidcAuthFlow] = useInitOidcAuthFlowMutation();

  if (thirdPartyUserIntegrationsError) {
    logError(
      thirdPartyUserIntegrationsError,
      `failed to list third party integrations`
    );
  }

  // Get support ticket hooks
  const integrations = orgState.orgThirdPartyIntegrations;
  const supportTicketThirdPartyProviders = getSupportTicketThirdPartyProviders(
    integrations
  );

  const [
    supportTicketThirdPartyProvider,
    setSupportTicketThirdPartyProvider,
  ] = useState<ThirdPartyProvider | undefined>(
    supportTicketThirdPartyProviders.length > 0
      ? supportTicketThirdPartyProviders[0]
      : undefined
  );

  const [getTicketsErrorMessage, setGetTicketsErrorMessage] = useState<
    string | undefined
  >(undefined);

  const [showSupportTickets, setShowSupportTickets] = useState(false);

  const [
    supportTicket,
    setSupportTicket,
  ] = useState<SupportTicketPreviewFragment>();

  // Create request hooks

  const [reason, setReason] = useState("");

  const itemsMaxDuration = Math.min(
    ...requestableItems.map((item) => {
      const maxDurationItem =
        activeRequestConfigurationByItemId[item.id]?.maxDurationInMinutes;
      switch (item.__typename) {
        case "Resource":
          return (
            calculateMaxDurationInMinutes(
              orgState.orgSettings?.maxResourceDurationInMinutes,
              maxDurationItem
            ) || Infinity
          );
        case "Group":
          return (
            calculateMaxDurationInMinutes(
              orgState.orgSettings?.maxGroupDurationInMinutes,
              maxDurationItem
            ) || Infinity
          );
        default:
          return Infinity;
      }
    })
  );

  // If all items have the same recommended duration, use that duration.
  const itemsRecommendedDurations = new Set<number | undefined>();
  requestableItems.forEach((item) => {
    const recommendedDurationItem =
      activeRequestConfigurationByItemId[item.id]?.recommendedDurationInMinutes;
    itemsRecommendedDurations.add(recommendedDurationItem ?? undefined);
  });
  const itemsRecommendedDuration =
    itemsRecommendedDurations.size === 1
      ? Array.from(itemsRecommendedDurations)[0]
      : undefined;

  const defaultRequestDuration = getDefaultRequestDurationMinutes(
    itemsMaxDuration,
    itemsRecommendedDuration
  );

  const defaultRequestExpiration = getDefaultRequestExpiration(
    itemsMaxDuration,
    itemsRecommendedDuration
  );

  const [usingCustomDurationPicker, setUsingCustomDurationPicker] = useState(
    Boolean(
      defaultRequestDuration !== Infinity &&
        defaultRequestDuration &&
        isDurationValueCustom(defaultRequestDuration)
    )
  );

  const [expiration, setExpiration] = useState(defaultRequestExpiration);
  const [customDuration, setCustomDuration] = useState(
    defaultRequestDuration !== Infinity &&
      defaultRequestDuration != null &&
      isDurationValueCustom(defaultRequestDuration)
      ? defaultRequestDuration
      : 30
  );

  useEffect(() => {
    if (requestConfigLoading) return;
    setExpiration(defaultRequestExpiration);
    if (defaultRequestDuration) {
      const isCustomDuration = isDurationValueCustom(defaultRequestDuration);
      setUsingCustomDurationPicker(
        defaultRequestDuration !== Infinity && isCustomDuration
      );
      setCustomDuration(
        defaultRequestDuration !== Infinity && isCustomDuration
          ? defaultRequestDuration
          : 30
      );
    }
  }, [defaultRequestExpiration, defaultRequestDuration, requestConfigLoading]);

  const resourceIds = Object.keys(resourcesByResourceId);
  const groupIds = Object.keys(groupsByGroupId);

  const availableRolesByItemId: Record<
    string,
    ResourceAccessLevelFragment[] | GroupAccessLevel[]
  > = {};

  const {
    data: resourcesRolesData,
    loading: resourcesRolesLoading,
    error: resourcesRolesError,
  } = useMultipleResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceIds: resourceIds,
        onlyRequestableTargetUser: targetUser?.id,
      },
    },
    skip: resourceIds.length === 0,
  });

  // fetch roles
  if (resourcesRolesData) {
    switch (resourcesRolesData.multipleAccessLevels.__typename) {
      case "MultipleResourceAccessLevelsResult": {
        resourcesRolesData.multipleAccessLevels.results.forEach((result) => {
          const resourceRoles = result.accessLevels;
          availableRolesByItemId[result.resourceId] = resourceRoles;
        });
        break;
      }
      case "ResourceNotFoundError":
        logError(new Error(`Error: failed to list resource roles`));
        break;
    }
  } else if (resourcesRolesError) {
    logError(resourcesRolesError, `failed to list resource roles`);
  }

  const {
    data: groupsRolesData,
    loading: groupsRolesLoading,
    error: groupsRolesError,
  } = useMultipleGroupAccessLevelsQuery({
    variables: {
      input: {
        groupIds: groupIds,
        onlyRequestableTargetUser: targetUser?.id,
      },
    },
    skip: groupIds.length === 0,
  });

  // fetch roles for groups
  if (groupsRolesData) {
    switch (groupsRolesData.multipleGroupAccessLevels.__typename) {
      case "MultipleGroupAccessLevelsResult": {
        groupsRolesData.multipleGroupAccessLevels.results.forEach((result) => {
          const groupRoles = result.accessLevels;
          if (groupRoles) availableRolesByItemId[result.groupId] = groupRoles;
        });
        break;
      }
      case "GroupNotFoundError":
        logError(new Error(`Error: failed to list group roles`));
        break;
    }
  } else if (groupsRolesError) {
    logError(groupsRolesError, `failed to list group roles`);
  }

  if (
    bulkRequestColumnResourcesError ||
    bulkRequestColumnGroupsError ||
    resourceRequestConfigError ||
    groupRequestConfigError
  ) {
    if (bulkRequestColumnResourcesError)
      logError(
        bulkRequestColumnResourcesError,
        `failed to get bulk request column data for resources`
      );
    if (bulkRequestColumnGroupsError)
      logError(
        bulkRequestColumnGroupsError,
        `failed to get bulk request column data for groups`
      );
    if (resourceRequestConfigError)
      logError(
        resourceRequestConfigError,
        "failed to get bulk request config data for resources"
      );
    if (groupRequestConfigError)
      logError(
        groupRequestConfigError,
        "failed to get bulk request config data for groups"
      );
    return (
      <Column isContent maxWidth="md">
        <UnexpectedErrorPage />
      </Column>
    );
  }

  const modalReset = () => {
    setErrorMessage(null);
    setSelectedRoleByItemId({});
  };

  const getAllRolesSet = () => {
    let allRolesSet = true;
    requestableItems.forEach((item) => {
      if (
        getItemRequiresRoles(item, availableRolesByItemId[item.id]) &&
        !selectedRoleByItemId[item.id]
      ) {
        allRolesSet = false;
      }
    });
    return allRolesSet;
  };

  const getThirdPartyProviderRequirements = () => {
    const userIntegrations =
      thirdPartyUserIntegrationsData?.thirdPartyIntegrations
        .thirdPartyIntegrations || [];

    const thirdPartyProvideRequirements = requestableItems
      .filter((resourceOrGroup) => {
        const connection = resourceOrGroup.connection;
        return (
          connection &&
          connectionRequiresThirdPartyProvider(connection) &&
          !userIntegrations.find(
            (integration) =>
              integration.thirdPartyProvider ===
              thirdPartyProviderByConnectionType[connection.connectionType]
          )
        );
      })
      .map((resourceOrGroup) => {
        const connection = resourceOrGroup.connection!;
        let extraParams: string = "";
        const thirdPartyProvider =
          thirdPartyProviderByConnectionType[connection.connectionType];
        const thirdPartyProviderName: string = thirdPartyProviderNameByThirdPartyProvider[
          thirdPartyProvider!
        ]!;
        if (connection.metadata)
          extraParams = getExtraParamsByConnectionMetadata(connection.metadata);
        return {
          providerName: thirdPartyProviderName,
          thirdPartyProvider: thirdPartyProvider,
          url: `/user/settings?showIntegrationModal=${thirdPartyProvider}&${extraParams}`,
        };
      })
      .filter(
        (value, index, self) =>
          self.findIndex(
            (item) => item.thirdPartyProvider === value.thirdPartyProvider
          ) === index
      );
    return thirdPartyProvideRequirements;
  };

  const getIsSupportTicketRequired = () => {
    const isSupportTicketRequiredGlobal =
      orgState.orgSettings?.generalSettings.some(
        (setting) => setting === GeneralSettingType.RequireSupportTicket
      ) || false;

    const itemsSupportTicketRequired = requestableItems.some((item) => {
      return activeRequestConfigurationByItemId[item.id]?.requireSupportTicket;
    });
    return isSupportTicketRequiredGlobal || itemsSupportTicketRequired;
  };

  const getIsReasonRequired = () => {
    const allItemsReasonOptional = requestableItems.every((item) => {
      return activeRequestConfigurationByItemId[item.id]?.reasonOptional;
    });
    return !allItemsReasonOptional;
  };

  const validateForm = () => {
    if (getIsReasonRequired() && reason === "") {
      return false;
    }

    if (!getAllRolesSet() && !isBundleRequest) {
      return false;
    }

    if (getIsSupportTicketRequired() && !supportTicket) {
      return false;
    }

    const thirdPartyProvideRequirements = getThirdPartyProviderRequirements();
    if (thirdPartyProvideRequirements.length > 0) {
      return false;
    }

    for (let templateId of requestTemplateIds) {
      const customFields = requestTemplateById[templateId]?.customFields ?? [];
      for (let field of customFields) {
        if (field.required) {
          const metadata = customMetadata[field.name];
          if (metadata == null || metadata.metadataValue == null) {
            return false;
          }
          switch (field.type) {
            case RequestTemplateCustomFieldType.ShortText:
              if (!metadata.metadataValue.shortTextValue?.value) {
                return false;
              }
              break;
            case RequestTemplateCustomFieldType.LongText:
              if (!metadata.metadataValue.longTextValue?.value) {
                return false;
              }
              break;
            case RequestTemplateCustomFieldType.Boolean:
              if (!metadata.metadataValue.booleanValue?.value) {
                return false;
              }
              break;
            case RequestTemplateCustomFieldType.MultiChoice:
              if (!metadata.metadataValue.multiChoiceValue?.value) {
                return false;
              }
          }
        }
      }
    }

    return true;
  };

  const handleSelectSupportTicketChange = (provider?: ThirdPartyProvider) => {
    setSupportTicketThirdPartyProvider(provider);
    setGetTicketsErrorMessage("");
    setSupportTicket(undefined);
  };

  const currentUserAccessByItemId: {
    [itemId: string]: {
      hasAccess: boolean;
      currentExpiration: moment.Moment | undefined;
    };
  } = {};
  requestableItems.forEach((item) => {
    const selectedRole = selectedRoleByItemId[item.id];
    const includeRoles = getItemRequiresRoles(
      item,
      availableRolesByItemId[item.id]
    );

    let currentUserHasAccess = false;
    let currentExpiration: moment.Moment | undefined = undefined;
    if ("resourceType" in item) {
      const result = getCurrentUserResourceAccessSummary(
        item,
        includeRoles,
        (selectedRole as ResourceAccessLevel) ?? undefined
      );
      currentUserHasAccess = result.hasAccess;
      currentExpiration = result.expiration;
    } else if ("groupType" in item) {
      const result = getCurrentUserGroupAccessSummary(
        item,
        includeRoles,
        (selectedRole as ResourceAccessLevel) ?? undefined
      );
      currentUserHasAccess = result.hasAccess;
      currentExpiration = result.expiration;
    }
    const hasAccess =
      currentUserHasAccess && targetUser?.id === authState.user?.user.id;

    currentUserAccessByItemId[item.id] = {
      hasAccess,
      currentExpiration,
    };
  });
  if (isBundleRequest) {
    requestableBundleItems.forEach((item) => {
      let currentUserHasAccess = false;
      let currentExpiration: moment.Moment | undefined = undefined;
      let itemId = "";
      if (item.resource) {
        itemId = item.resource.id;
        item.resource.currentUserAccess.resourceUsers.forEach((ru) => {
          if (
            ru.access?.directAccessPoint?.accessLevel.accessLevelRemoteId ===
            item.accessLevelRemoteId
          ) {
            currentUserHasAccess = true;
            const currentAccessExpiration =
              ru.access.directAccessPoint.expiration;
            currentExpiration = currentAccessExpiration
              ? moment(new Date(currentAccessExpiration))
              : undefined;
          }
        });
      } else if (item.group) {
        itemId = item.group.id;
        let directRoleRemoteId: undefined | string;
        if (item.group.currentUserAccess.groupUser?.access?.directAccessPoint) {
          directRoleRemoteId =
            item.group.currentUserAccess.groupUser.access.directAccessPoint
              .accessLevel?.accessLevelRemoteId ?? "";
        }
        currentUserHasAccess = directRoleRemoteId === item.accessLevelRemoteId;
        const currentAccessExpiration =
          item.group.currentUserAccess.groupUser?.access?.directAccessPoint
            ?.expiration;
        currentExpiration = currentAccessExpiration
          ? moment(new Date(currentAccessExpiration))
          : undefined;
      }
      const hasAccess =
        currentUserHasAccess && targetUser?.id === authState.user?.user.id;
      currentUserAccessByItemId[itemId] = {
        hasAccess,
        currentExpiration,
      };
    });
  }

  const handleClickRequest = async (cachedData?: MfaCustomParams) => {
    try {
      if (!targetUser) {
        return;
      }

      const durationInMinutes = (usingCustomDurationPicker
        ? moment.duration(customDuration, "m")
        : expirationValueToDurationInMinutes(expiration)
      )?.asMinutes();
      if (durationInMinutes !== undefined && durationInMinutes <= 0) {
        setErrorMessage("Error: duration can't be negative or zero");
        return;
      }

      const requestedResources: RequestedResourceInput[] = [];
      const requestedGroups: RequestedGroupInput[] = [];

      if (isBundleRequest) {
        requestableBundleItems.forEach((bundleItem) => {
          if (bundleItem.resource) {
            requestedResources.push({
              resourceId: bundleItem.resource.id,
              accessLevel: {
                accessLevelName: bundleItem.accessLevelName,
                accessLevelRemoteId: bundleItem.accessLevelRemoteId,
              },
            });
          } else if (bundleItem.group) {
            requestedGroups.push({
              groupId: bundleItem.group.id,
              accessLevel: {
                accessLevelName: bundleItem.accessLevelName,
                accessLevelRemoteId: bundleItem.accessLevelRemoteId,
              },
            });
          }
        });
      } else {
        requestableItems.forEach((item) => {
          if (item.__typename === "Resource") {
            const role = (cachedData?.requestSelectedRoleByItemId ??
              selectedRoleByItemId)[item.id] as ResourceAccessLevel;
            requestedResources.push({
              resourceId: item.id,
              accessLevel: {
                accessLevelName: role?.accessLevelName || "",
                accessLevelRemoteId: role?.accessLevelRemoteId || "",
              },
            });
          } else {
            requestedGroups.push({
              groupId: item.id,
              accessLevel: (cachedData?.requestSelectedRoleByItemId ??
                selectedRoleByItemId)[item.id] as GroupAccessLevel,
            });
          }
        });
      }

      let customMetadataInput: RequestCustomMetadataInput[] = [];
      if (cachedData?.requestCustomFields) {
        customMetadataInput = Object.values(cachedData.requestCustomFields);
      } else {
        requestTemplateIds.forEach((templateId) => {
          const customFields =
            requestTemplateById[templateId]?.customFields ?? [];
          customFields.forEach((field) => {
            if (customMetadata[field.name]) {
              customMetadataInput.push(customMetadata[field.name]);
            } else {
              customMetadataInput.push({
                fieldName: field.name,
                fieldType: field.type,
                metadataValue: {},
              });
            }
          });
        });
      }

      let supportTicketInput: SupportTicketPreviewFragment | undefined =
        supportTicket || undefined;
      if (cachedData?.requestSupportTicket) {
        supportTicketInput = cachedData.requestSupportTicket;
      }

      const { data } = await createRequest({
        variables: {
          input: {
            targetUserId:
              cachedData?.requestTarget?.__typename === "User"
                ? cachedData?.requestTarget?.id
                : targetUser?.id,
            targetGroupId:
              cachedData?.requestTarget?.__typename === "Group"
                ? cachedData?.requestTarget?.id
                : null,
            requestedResources: requestedResources,
            requestedGroups: requestedGroups,
            reason: cachedData?.requestReason ?? reason,
            durationInMinutes:
              cachedData?.requestDurationInMinutes ?? durationInMinutes,
            supportTicket: supportTicketInput
              ? {
                  remoteId: supportTicketInput.remoteId,
                  url: supportTicketInput.url,
                  thirdPartyProvider: supportTicketInput.thirdPartyProvider,
                  identifier: supportTicketInput.identifier,
                }
              : null,
            customMetadata: customMetadataInput,
          },
        },
        refetchQueries: ["Requests"],
        update: (_, { data }) => {
          switch (data?.createRequest.__typename) {
            case "CreateRequestResult": {
              history.push(
                `${getResourceUrlNew({
                  entityId: data.createRequest.request.id,
                  entityType: EntityType.Request,
                })}?o=1`
              );
            }
          }
        },
      });

      switch (data?.createRequest.__typename) {
        case "CreateRequestResult": {
          modalReset();
          if (data.createRequest.request.status === RequestStatus.Approved) {
            displaySuccessToast("Success: request auto-approved");
            opalConfetti();
          } else {
            clearSelectedItems && clearSelectedItems();
            displaySuccessToast(
              <>
                <span>Success: request created </span>
                <Link
                  url={getResourceUrlNew({
                    entityId: data.createRequest.request.id,
                    entityType: EntityType.Request,
                  })}
                >
                  View
                </Link>
              </>
            );
          }
          break;
        }
        case "RequestDurationTooLargeError":
        case "RequestRequiresUserAuthTokenForConnectionError":
        case "NoManagerSetForRequestingUserError":
        case "ItemCannotBeRequestedError":
          setErrorMessage(data.createRequest.message);
          break;
        case "BulkRequestTooLargeError":
          logWarning(
            new Error(
              `Bulk request too large: requested ${requestableItems.length} items`
            )
          );
          setErrorMessage(data.createRequest.message);
          break;
        case "NoReviewersSetForOwnerError":
        case "NoReviewersSetForResourceError":
        case "NoReviewersSetForGroupError":
          setErrorMessage("This request has no approvers set.");
          break;
        case "MfaInvalidError": {
          const useOktaMfa = orgState.orgSettings?.generalSettings.some(
            (setting) =>
              setting === GeneralSettingType.UseOktaMfaForGatingOpalActions
          );
          const useOidcMfa = orgState.orgSettings?.generalSettings.some(
            (setting) =>
              setting === GeneralSettingType.UseOidcMfaForGatingOpalActions
          );
          const redirectUrl = history.location.pathname;
          if (isBundleRequest) {
            localStorage.setItem(
              SELECTED_BUNDLE_ITEMS_LOCAL_STORAGE_KEY,
              JSON.stringify(selectedItems)
            );
          } else {
            localStorage.setItem(
              SELECTED_ITEMS_LOCAL_STORAGE_KEY,
              JSON.stringify(selectedItems)
            );
          }

          let mfaState = {
            requestReason: reason,
            requestDurationInMinutes: durationInMinutes,
            requestSelectedRoleByItemId: selectedRoleByItemId,
            requestTargetUser: targetUser,
            requestSupportTicket: supportTicket,
            requestCustomFields: customMetadata,
          };
          if (useOidcMfa) {
            try {
              const state = generateState();
              const { data } = await initOidcAuthFlow({
                variables: {
                  input: {
                    state: state,
                    oidcProviderType: OidcProviderType.Mfa,
                  },
                },
              });
              switch (data?.initOidcAuthFlow.__typename) {
                case "InitOidcAuthFlowResult":
                  setOidcData({
                    state: state,
                    oidcProviderType: OidcProviderType.Mfa,
                    postAuthPath: redirectUrl,
                    mfaParams: mfaState,
                  });
                  window.location.replace(data?.initOidcAuthFlow.authorizeUrl);
                  break;
                case "OidcProviderNotFoundError":
                  displayErrorToast(
                    "Error: Failed to trigger MFA. Please contact your admin."
                  );
                  break;
                default:
                  displayErrorToast(
                    "Error: Failed to trigger MFA. Please try again or contact support if the issue persists."
                  );
              }
            } catch (e) {
              logError(e, "Error: could not complete MFA");
            }
          } else if (useOktaMfa) {
            setIsIdpMfaModalOpen(true);
          } else {
            authState.authClient?.mfaFlow(redirectUrl, mfaState);
          }
          break;
        }
        case "TargetUserHasNestedAccessError": {
          const failedGroupId =
            data.createRequest.groupIds &&
            data.createRequest.groupIds.length > 0
              ? data.createRequest.groupIds?.[0]
              : null;
          const foundSelectedItem = (selectedItems as Array<
            SelectedItem | BundleItemFragment
          >).find((si) => {
            switch (si.__typename) {
              case "BundleItem":
                return si.group?.id === failedGroupId;
              case "Group":
                return si.id === failedGroupId;
              default:
                return false;
            }
          });
          const name =
            (foundSelectedItem as BundleItemFragment).group?.name ??
            (foundSelectedItem as SelectedItem)?.name ??
            "";
          setErrorMessage(
            `Error: target user already has nested access to group ${name}.`
          );
          break;
        }
        default:
          logError(new Error(`failed to create request`));
          setErrorMessage("Error: failed to create request");
      }
    } catch (error) {
      logError(error, `failed to create request`);
      setErrorMessage("Error: failed to create request");
    }
  };

  const renderRoleSelection = (
    item: ResourcePreviewLargeFragment | GroupPreviewLargeFragment
  ) => {
    const selectedRole = selectedRoleByItemId[item.id];
    switch (item.__typename) {
      case "Resource":
        if (
          getItemRequiresRoles(item, availableRolesByItemId[item.id]) &&
          !getIsGlobalImpersonationResource(item)
        ) {
          return (
            <RequestModalRoleDropdown
              availableRoles={availableRolesByItemId[item.id]}
              selectedRole={selectedRole}
              setSelectedRole={(role) => {
                if (role) {
                  setSelectedRoleByItemId({
                    ...selectedRoleByItemId,
                    [item.id]: role,
                  });
                }
              }}
              isImpersonationResource={
                item.remoteId === OPAL_IMPERSONATION_REMOTE_ID
              }
            />
          );
        }
        return "Full Access";
      case "Group":
        if (getItemRequiresRoles(item, availableRolesByItemId[item.id])) {
          return (
            <RequestModalRoleDropdown
              availableRoles={availableRolesByItemId[item.id]}
              selectedRole={selectedRole}
              setSelectedRole={(role) => {
                if (role) {
                  setSelectedRoleByItemId({
                    ...selectedRoleByItemId,
                    [item.id]: role,
                  });
                }
              }}
              isImpersonationResource={false}
            />
          );
        }
        return "";
    }
  };

  const renderThirdPartyProviderRequirementsBanner = () => {
    const thirdPartyProvideRequirements = getThirdPartyProviderRequirements();
    if (thirdPartyProvideRequirements.length === 0) return null;
    return thirdPartyProvideRequirements.map(({ providerName, url }) => {
      return (
        <div className={sprinkles({ marginBottom: "md" })}>
          <Banner
            key={providerName}
            message={
              <>
                {`Some resources require you to link your ${providerName} account to Opal before requesting access. You can link your ${providerName} account `}
                <Banner.Link label="here" to={url} />
                {`.`}
              </>
            }
            type="warning"
          />
        </div>
      );
    });
  };

  const renderRequestItems = () => {
    if (
      resourcesRolesLoading ||
      groupsRolesLoading ||
      bulkRequestColumnResourcesLoading ||
      bulkRequestColumnGroupsLoading
    ) {
      return (
        <div className={styles.requestedItemsLoadingContainer}>
          {selectedItems.slice(0, 5).map(() => (
            <Skeleton height="45px" />
          ))}
        </div>
      );
    }

    if (isBundleRequest) {
      return (
        <div className={styles.requestedItemsContainer}>
          {requestableBundleItems.map((item) => {
            let label = "";
            if (item.resource) {
              label = item.resource.name;
            } else if (item.group) {
              label = item.group.name;
            }
            if (item.accessLevelName) {
              label = `${label} (${item.accessLevelName})`;
            }
            return (
              <div className={styles.requestedItemContainer({})} key={item.key}>
                <DataElement
                  label={label}
                  color="navy"
                  leftIcon={{
                    name: item.resource ? "cube" : "users",
                  }}
                  rightIcon={{
                    name: "x",
                    onClick: () => {
                      clearBundleItems([item]);
                    },
                  }}
                  truncateLength={50}
                />
              </div>
            );
          })}
        </div>
      );
    }

    return (
      <div className={styles.requestedItemsContainer}>
        {requestableItems.map((item) => {
          const roleSelection = renderRoleSelection(item);
          const hasRoleSelection = isValidElement(roleSelection);
          const currentUserAccess = currentUserAccessByItemId[item.id];

          return (
            <div className={sprinkles({ width: "100%" })}>
              <div
                className={styles.requestedItemContainer({
                  hasRoleSelection: hasRoleSelection,
                })}
                key={item.id}
              >
                <DataElement
                  label={item.name}
                  color="navy"
                  leftIcon={{
                    name: item.__typename === "Resource" ? "cube" : "users",
                  }}
                  rightIcon={{
                    name: "x",
                    onClick: () => {
                      clearItem(item);
                    },
                  }}
                  truncateLength={30}
                />
                {hasRoleSelection ? roleSelection : null}
              </div>
              {currentUserAccess?.hasAccess ? (
                <div
                  className={sprinkles({
                    marginTop: "md",
                  })}
                >
                  <Banner
                    message={
                      currentUserAccess?.currentExpiration == null
                        ? "You have indefinite access."
                        : `Your current access will expire ${currentUserAccess.currentExpiration.fromNow()}`
                    }
                    type="success"
                  />
                </div>
              ) : null}
            </div>
          );
        })}
      </div>
    );
  };

  const renderCustomFieldRows = () => {
    const seenTemplateIds = new Set<string>();
    const customFieldRows: JSX.Element[] = [];
    for (let templateId of requestTemplateIds) {
      if (seenTemplateIds.has(templateId)) continue;
      seenTemplateIds.add(templateId);

      const customFields = requestTemplateById[templateId]?.customFields ?? [];
      for (let field of customFields) {
        customFieldRows.push(
          <RequestCustomFieldRow
            customField={field}
            metadataValue={customMetadata[field.name]?.metadataValue}
            onChange={(val) =>
              setCustomMetadata((prev) => ({
                ...prev,
                [field.name]: {
                  fieldName: field.name,
                  fieldType: field.type,
                  metadataValue: val,
                },
              }))
            }
          />
        );
      }
      if (customFields.length) {
        customFieldRows.push(<Divider />);
      }
    }

    return (
      <>
        {customFieldRows.length > 0 && <Divider />}
        {customFieldRows}
      </>
    );
  };

  const renderSupportTicketFields = () => {
    const supportTicketBindingAllowed =
      supportTicketThirdPartyProviders &&
      supportTicketThirdPartyProviders.length !== 0;
    const isSupportTicketRequired = getIsSupportTicketRequired();

    const overriddenShowSupportTickets =
      showSupportTickets || isSupportTicketRequired;

    return (
      <>
        <div className={sprinkles({ marginBottom: "md" })}>
          <Switch
            checked={overriddenShowSupportTickets}
            onChange={() => {
              setGetTicketsErrorMessage("");
              setShowSupportTickets(!showSupportTickets);
            }}
            disabled={!supportTicketBindingAllowed || isSupportTicketRequired}
            label="Expire access when ticket is closed"
            infoTooltip={
              isSupportTicketRequired
                ? "Your organization requires this request to be bound to an access ticket."
                : "Enable this option to expire access when a linked ticket is closed."
            }
          />
        </div>
        {supportTicketBindingAllowed && overriddenShowSupportTickets && (
          <FormGroup label="Ticket Providers">
            <TicketProviderDropdown
              selectedTicketProvider={supportTicketThirdPartyProvider}
              onSelectTicketProvider={handleSelectSupportTicketChange}
              // ServiceNow is not supported yet as a ticketing system. We
              // currently only use it to mirror requests as an audit log,
              // so hide it from the dropdown here.
              hiddenProviders={[ThirdPartyProvider.ServiceNow]}
            />
          </FormGroup>
        )}
        {supportTicketBindingAllowed &&
          overriddenShowSupportTickets &&
          supportTicketThirdPartyProvider && (
            <SupportTicketsField
              thirdPartyProvider={supportTicketThirdPartyProvider}
              setGetTicketsErrorMessage={setGetTicketsErrorMessage}
              supportTicket={supportTicket}
              setSupportTicket={setSupportTicket}
            />
          )}
      </>
    );
  };

  // If none of the selected items are requestable, don't show request form
  if (requestableItems.length === 0) {
    return null;
  }

  // User must be either bulk selecting from apps or bundles
  if (bulkMode !== "request" && !location.pathname.includes("bundles")) {
    return null;
  }

  return (
    <>
      <Column isContent maxWidth="md">
        <ColumnHeader
          title={`Request access to ${requestableItems.length} ${pluralize(
            "resource",
            requestableItems.length
          )}`}
          icon={{ type: "name", icon: "lock" }}
          onClose={() => {
            setBulkMode(undefined);
            if (isBundleRequest) {
              clearSelectedItems();
            }
          }}
        />
        <Divider margin="md" />
        {renderThirdPartyProviderRequirementsBanner()}
        {renderRequestItems()}
        <Divider margin="md" />
        <ColumnContent>
          <div className={sprinkles({ padding: "xxs" })}>
            {targetUser ? (
              <FormGroup label="Request for" required>
                <RequestForUserDropdown
                  entities={requestableItems}
                  targetUser={targetUser}
                  onChange={setTargetUser}
                />
              </FormGroup>
            ) : null}
            {getIsReasonRequired() ? (
              <FormGroup label="Reason" required>
                <Input
                  type="textarea"
                  rows={4}
                  placeholder={"I need access to this because..."}
                  value={reason}
                  onChange={setReason}
                />
              </FormGroup>
            ) : null}
            {renderCustomFieldRows()}
            <RequestDurationPicker
              maxDurationInMinutes={itemsMaxDuration}
              recommendedDurationInMinutes={itemsRecommendedDuration}
              expiration={expiration}
              setExpiration={setExpiration}
              customDuration={customDuration}
              setCustomDuration={setCustomDuration}
              usingCustomDurationPicker={usingCustomDurationPicker}
              setUsingCustomDurationPicker={setUsingCustomDurationPicker}
              itemsWithAccess={requestableItems
                .filter((item) => currentUserAccessByItemId[item.id]?.hasAccess)
                .map((item) => item.name)}
            />
            {renderSupportTicketFields()}
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
            {getTicketsErrorMessage && (
              <ModalErrorMessage errorMessage={getTicketsErrorMessage} />
            )}
            <div
              className={sprinkles({
                display: "flex",
                justifyContent: "flex-end",
              })}
            >
              <Button
                type="primary"
                label="Request"
                onClick={() => handleClickRequest()}
                loading={createRequestLoading}
                disabled={!validateForm()}
              />
            </div>
          </div>
        </ColumnContent>
      </Column>
      {isIdpMfaModalOpen && (
        <IdpMfaModal
          onClose={() => setIsIdpMfaModalOpen(false)}
          onMfaSuccess={() => handleClickRequest()}
        />
      )}
      <RequestModalMfaFiller
        callback={(requestModalParams: MfaCustomParams) => {
          handleClickRequest(requestModalParams);
        }}
      />
    </>
  );
};

export default BulkRequestColumn;
