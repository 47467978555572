import { refetchQueries } from "api/ApiContext";
import { SyncTaskStatus, useSyncTaskQuery } from "api/generated/graphql";
import { useToast } from "components/toast/Toast";
import { logError } from "utils/logging";

interface Props {
  loadingText: string;
  queriesToRefetch?: Parameters<typeof refetchQueries>[0]["include"];
}

/**
 * Returns a function that can be called with a `syncTaskId` to display a toast that
 * polls for the sync task being completed.
 *
 * @param loadingText toast text to be shown as the sync task is pending
 */
const useSyncStatusToast = ({ loadingText, queriesToRefetch }: Props) => {
  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
    clearToast,
  } = useToast();

  const { refetch } = useSyncTaskQuery({
    // We only want to make this query when calling refetch.
    skip: true,
  });

  const checkSyncStatus = async (
    syncTaskId: string,
    waitInterval: number,
    totalWaited: number
  ) => {
    displayLoadingToast(loadingText);
    const syncTaskErrorText = `Error: could not get sync status`;
    try {
      const result = await refetch({
        input: {
          id: syncTaskId,
        },
      });
      if (!result) {
        // This happens if the client moved to a different page.
        clearToast();
        return;
      }
      const { data: syncTaskData } = result;

      if (syncTaskData) {
        switch (syncTaskData?.syncTask.__typename) {
          case "SyncTaskResult": {
            const { status } = syncTaskData.syncTask.syncTask;
            if (status === SyncTaskStatus.Started) {
              // If in progress, keep polling to check for sync task completion
              setTimeout(() => {
                checkSyncStatus(
                  syncTaskId,
                  Math.min(2000, waitInterval * 2),
                  totalWaited + waitInterval
                );
              }, waitInterval);
            } else {
              // If finished, refetch the relevant data
              if (queriesToRefetch) {
                try {
                  await refetchQueries({
                    include: queriesToRefetch,
                  });
                } catch (error) {
                  logError(error, `failed to refresh UI after sync`);
                }
              }

              try {
                await refetchQueries({ include: ["SyncStatus"] });
              } catch (error) {
                logError(error, `failed to refresh UI after sync`);
              }

              if (status === SyncTaskStatus.Completed) {
                displaySuccessToast(`Success: finished syncing`);
              } else {
                displayErrorToast(`Finished syncing, but with errors`);
              }
            }
            break;
          }
          case "SyncTaskNotFoundError":
          default:
            // Upon error, stop polling
            logError(new Error(syncTaskErrorText));
            displayErrorToast(syncTaskErrorText);
        }
      }
    } catch (error) {
      logError(error, syncTaskErrorText);
      displayErrorToast(syncTaskErrorText);
    }
  };

  return (syncTaskId: string) =>
    setTimeout(() => {
      checkSyncStatus(syncTaskId, 500, 250);
    }, 250);
};

export default useSyncStatusToast;
