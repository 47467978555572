import * as styles from "./Divider.css";

interface Props {
  margin?: "xs" | "sm" | "md" | "lg";
  label?: string;
  /** Defaults to center */
  labelPosition?: "left" | "center" | "right";
}

const Divider = (props: Props) => {
  const { margin = "md", labelPosition = "center" } = props;

  return (
    <div className={styles.stageDivider}>
      <hr className={styles.divider({ margin })} />
      {props.label ? (
        <span className={styles.stageDividerText({ position: labelPosition })}>
          {props.label}
        </span>
      ) : null}
    </div>
  );
};

export default Divider;
