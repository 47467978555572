import { SyncType } from "api/generated/graphql";
import OpalPage from "components/opal/layout/OpalPage";
import SyncButtons from "components/sync/SyncButtons";
import { ReactNode } from "react";
import { useHistory } from "react-router-dom";

import InventoryAppsTable from "./InventoryAppsTable";
import InventoryBundlesTable from "./InventoryBundlesTable";
import InventoryNHIsTable from "./InventoryNHIsTable";
import InventoryOwnersTable from "./InventoryOwnersTable";
import InventoryTagsTable from "./InventoryTagsTable";
import InventoryUsersTable from "./InventoryUsersTable";

// Equivalent of Nested Routes for when we move to react router 6
const VIEWS: {
  [key: string]: {
    path: string;
    title: string;
    Component: React.FC;
    syncButton?: ReactNode;
  };
} = {
  INVENTORY: {
    path: "/inventory",
    title: "Apps",
    Component: InventoryAppsTable,
    syncButton: (
      <SyncButtons
        syncType={SyncType.PullConnectionsAll}
        queriesToRefetch={["InventoryPage"]}
      />
    ),
  },
  BUNDLES: {
    path: "/inventory/bundles",
    title: "Bundles",
    Component: InventoryBundlesTable,
  },
  USERS: {
    path: "/inventory/users",
    title: "Users",
    Component: InventoryUsersTable,
    syncButton: (
      <SyncButtons
        syncType={SyncType.PullHrIdpData}
        label="Sync Users"
        queriesToRefetch={["UsersHome"]}
      />
    ),
  },
  NHIS: {
    path: "/inventory/non-human-identities",
    title: "NHIs",
    Component: InventoryNHIsTable,
  },
  OWNERS: {
    path: "/inventory/owners",
    title: "Owners",
    Component: InventoryOwnersTable,
  },
  TAGS: {
    path: "/inventory/tags",
    title: "Tags",
    Component: InventoryTagsTable,
  },
};

const TAB_ORDER: (keyof typeof VIEWS)[] = [
  "INVENTORY",
  "USERS",
  "NHIS",
  "OWNERS",
  "TAGS",
  "BUNDLES",
];

const Inventory = () => {
  const history = useHistory();

  let currentView = VIEWS.INVENTORY;

  const tabs = TAB_ORDER.map((view_key) => {
    const view = VIEWS[view_key];

    let isSelected = false;
    // figure out current view
    if (history.location.pathname === view.path) {
      currentView = view;
      isSelected = true;
    }

    return {
      title: view.title,
      isSelected: isSelected,
      onClick: () => history.push(view.path),
    };
  });

  return (
    <OpalPage
      title="Inventory"
      icon="inventory"
      tabs={tabs}
      extraActions={[currentView.syncButton]}
    >
      <currentView.Component />
    </OpalPage>
  );
};

export default Inventory;
