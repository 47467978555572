import {
  BundleResourceInput,
  ResourceAccessLevel,
  ResourcePreviewSmallFragment,
  useCreateBundleResourcesMutation,
  useMultipleResourceAccessLevelsQuery,
  useResourcePreviewLazyQuery,
} from "api/generated/graphql";
import ResourceSearchDropdown, {
  mergeResourceSelectData,
  removeResourceSelectData,
} from "components/dropdown/ResourceSearchDropdown";
import * as styles from "components/modals/AddItemModal.css";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import ResourceRoleRow from "components/modals/ResourceRoleRow";
import { useToast } from "components/toast/Toast";
import { Modal, Skeleton } from "components/ui";
import { useState } from "react";
import { resourceRequiresAtLeastOneRole } from "utils/directory/resources";
import { logError } from "utils/logging";

interface Props {
  bundleId: string;
  existingRolesByResourceId: Record<string, ResourceAccessLevel[]>;
  onClose: () => void;
}

const BundleAddResourcesModal = (props: Props) => {
  const [roleByResourceIdToAdd, setRoleByResourceIdToAdd] = useState<
    Record<string, ResourceAccessLevel[]>
  >({});
  const [error, setError] = useState("");
  const [rowErrors, setRowErrors] = useState<Record<string, string>>({}); // key is resource id

  const { displaySuccessToast } = useToast();
  const [
    createBundleResources,
    { loading },
  ] = useCreateBundleResourcesMutation();

  const [getResourceInfo] = useResourcePreviewLazyQuery();
  const {
    data,
    error: rolesError,
    loading: loadingResourceRoles,
  } = useMultipleResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceIds: Object.keys(props.existingRolesByResourceId),
      },
    },
  });

  if (loadingResourceRoles) {
    return (
      <Modal title="Add Resources" isOpen onClose={props.onClose}>
        <Modal.Body>
          <Skeleton height="48px" />
        </Modal.Body>
      </Modal>
    );
  }

  if (rolesError) {
    return (
      <Modal title="Add Resources" isOpen onClose={props.onClose}>
        <Modal.Body>
          <ModalErrorMessage errorMessage={rolesError.message} />
        </Modal.Body>
      </Modal>
    );
  }

  let allRoles;
  switch (data?.multipleAccessLevels.__typename) {
    case "MultipleResourceAccessLevelsResult":
      allRoles = data.multipleAccessLevels.results;
  }

  const allRolesByResourceId: Record<string, ResourceAccessLevel[]> = {};
  allRoles?.forEach((role) => {
    allRolesByResourceId[role.resourceId] = role.accessLevels;
  });

  const handleSubmit = async () => {
    try {
      const resourceInputs: BundleResourceInput[] = [];
      const newRowErrors: Record<string, string> = {};
      for (const [resourceId, roles] of Object.entries(roleByResourceIdToAdd)) {
        const { data } = await getResourceInfo({
          variables: {
            input: {
              id: resourceId,
            },
          },
        });
        let resource: ResourcePreviewSmallFragment | undefined;
        switch (data?.resource.__typename) {
          case "ResourceResult":
            resource = data.resource.resource;
            break;
        }
        if (!resource) {
          setError("Failed to add resources to bundle");
          return;
        }

        if (roles.length === 0) {
          // If resource requires a role, but none are selected,
          // show an error.
          if (resourceRequiresAtLeastOneRole(resource)) {
            newRowErrors[resourceId] = "Please select at least one role";
          } else {
            // If resource does not require roles,
            // add an empty role to add the resource directly.
            resourceInputs.push({
              bundleId: props.bundleId,
              resourceId,
              accessLevelName: "",
              accessLevelRemoteId: "",
            });
          }
        }

        roles.forEach((role) => {
          resourceInputs.push({
            bundleId: props.bundleId,
            resourceId,
            accessLevelName: role.accessLevelName,
            accessLevelRemoteId: role.accessLevelRemoteId,
          });
        });
      }

      if (Object.keys(newRowErrors).length > 0) {
        setRowErrors(newRowErrors);
        return;
      }

      const { data } = await createBundleResources({
        variables: {
          input: {
            inputs: resourceInputs,
          },
        },
        refetchQueries: ["BundleItems", "Bundle"],
      });

      if (data?.createBundleResources.bundleResources) {
        displaySuccessToast("Success: resources added to bundle");
        props.onClose();
      }
    } catch (err) {
      logError(err, "Failed to add resources to bundle");
      setError("Failed to add resources to bundle");
    }
  };
  const disabledResourceIds: string[] = [];
  Object.keys(props.existingRolesByResourceId).forEach((resourceId) => {
    const allRolesAdded =
      allRolesByResourceId[resourceId]?.length ===
      props.existingRolesByResourceId[resourceId]?.length;
    if (allRolesAdded) {
      disabledResourceIds.push(resourceId);
    }
  });

  return (
    <Modal isOpen title="Add Resources" onClose={props.onClose}>
      <Modal.Body>
        {error && <ModalErrorMessage errorMessage={error} />}
        <ResourceSearchDropdown
          style="search"
          selectedResourceIds={Object.keys(roleByResourceIdToAdd)}
          onSelect={({ actionType, resources }) => {
            setRoleByResourceIdToAdd((prev) => {
              if (actionType === "select-option") {
                return mergeResourceSelectData(prev, resources);
              } else {
                return removeResourceSelectData(prev, resources);
              }
            });
          }}
          disabledResourceIds={disabledResourceIds}
        />
        <div className={styles.itemsContainer}>
          {Object.keys(roleByResourceIdToAdd).map((resourceId) => {
            return (
              <ResourceRoleRow
                key={resourceId}
                resourceId={resourceId}
                existingRoles={
                  props.existingRolesByResourceId[resourceId] ?? []
                }
                roles={roleByResourceIdToAdd[resourceId] ?? []}
                error={rowErrors[resourceId]}
                onResourceRemove={() => {
                  setRoleByResourceIdToAdd((prev) => {
                    delete prev[resourceId];
                    return { ...prev };
                  });
                }}
                onRoleSelect={(role) => {
                  setRoleByResourceIdToAdd((prev) => {
                    if (resourceId in prev) {
                      return {
                        ...prev,
                        [resourceId]: [...prev[resourceId], role],
                      };
                    } else {
                      return {
                        ...prev,
                        [resourceId]: [role],
                      };
                    }
                  });
                }}
                onRoleRemove={(role) => {
                  setRoleByResourceIdToAdd((prev) => {
                    return {
                      ...prev,
                      [resourceId]: prev[resourceId].filter(
                        (a) =>
                          a.accessLevelRemoteId !== role.accessLevelRemoteId
                      ),
                    };
                  });
                }}
              />
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Submit"
        primaryButtonDisabled={Object.keys(roleByResourceIdToAdd).length === 0}
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLoading={loading}
      />
    </Modal>
  );
};

export default BundleAddResourcesModal;
