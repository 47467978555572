import { useMostRequestedResourcesQuery } from "api/generated/graphql";

import BarGraph, { BarGraphLegend } from "../graphs/BarGraph";
import { useMetricsQueryInput } from "../MetricsDashboardContext";
import * as styles from "./Metrics.css";
import MetricsGraphCard from "./MetricsGraphCard";

const MostRequestedResources = () => {
  const input = useMetricsQueryInput();

  const { data, loading, error } = useMostRequestedResourcesQuery({
    variables: {
      input,
    },
  });

  const barData = data?.mostRequestedResources.barData ?? [];
  const datasets = barData.map((barData) => ({
    label: barData.label,
    data: barData.data.map((d) => d.value),
  }));
  const xAxisLabels = barData[0]?.data.map((data) => data.timestamp) ?? [];

  return (
    <MetricsGraphCard
      title="Resource request volume"
      subtitle="Which resources are being requested the most?"
      units="number of requests"
      loading={loading}
      error={Boolean(error)}
      noData={datasets.length === 0}
    >
      <div className={styles.barGraph}>
        <BarGraph datasets={datasets} xAxisLabels={xAxisLabels} />
      </div>
      <div className={styles.graphLegend}>
        <BarGraphLegend datasets={datasets} xAxisLabels={xAxisLabels} />
      </div>
    </MetricsGraphCard>
  );
};

export default MostRequestedResources;
