import {
  EntityType,
  GroupDetailViewFragment,
  ResourcePreviewSmallFragment,
  SyncErrorFragment,
  SyncTaskFragment,
  SyncType,
  useGroupDetailViewQuery,
  useResourcePreviewQuery,
  useSyncStatusQuery,
  Visibility,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeaderV3";
import ResourcesConfigFormV3 from "components/forms/ResourcesConfigFormV3";
import { makeConfigForGroup } from "components/forms/utils";
import GroupBindingDetailPopover from "components/group_bindings/GroupBindingDetailPopover";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import SyncStatusModal from "components/label/SyncStatusModal";
import RequestModal from "components/modals/RequestModal";
import OpalHeaderExtraActions from "components/opal/layout/OpalHeaderExtraActions";
import OpalPage from "components/opal/layout/OpalPage";
import { PillV3 } from "components/pills/PillsV3";
import useSyncActionIcon, {
  getSyncLabel,
} from "components/sync/useSyncActionIcon";
import { BreadcrumbsV3, Link, TabsV3 } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import useLogEvent from "utils/analytics";
import {
  AuthorizedActionExport,
  AuthorizedActionManage,
  AuthorizedActionManageUser,
  hasBasicPermissions,
} from "utils/auth/auth";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useHasInventory, useRecordViewFor } from "utils/hooks";
import { logError } from "utils/logging";
import {
  useBooleanURLSearchParam,
  useQuery,
  useURLSearchParam,
} from "utils/router/hooks";
import { useAccessRequestTransition } from "views/access_request/AccessRequestContext";
import {
  formatRequestDataForItems,
  useHandleRedirectToEndUserExp,
} from "views/apps/enduser_exp/utils";
import { ItemDetailsCard } from "views/common/ItemDetailsCard";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import EventsTableV3Component from "views/events/EventsTableV3Component";
import { isGroupBindingRedirectRequired } from "views/group_bindings/common";
import GroupBindingDeleteModal from "views/group_bindings/modals/GroupBindingDeleteModal";
import GroupBindingEditModal from "views/group_bindings/modals/GroupBindingEditModal";
import GroupBindingManualLinkModal from "views/group_bindings/modals/GroupBindingManualLinkModal";
import { useGroupBindingRequestRedirectModal } from "views/group_bindings/modals/GroupBindingRequestRedirectModal";
import GroupDeleteModal from "views/groups/GroupDeleteModal";
import GroupLeadersDetailsView from "views/groups/GroupLeadersDetailsView";
import GroupResourcesTableV3 from "views/groups/GroupResourcesTableV3";
import GroupUsersTableV3 from "views/groups/GroupUsersTableV3";
import MemberGroupsTableV3 from "views/groups/MemberGroupsTableV3";

import { OKTA_APP_ID_URL_KEY } from "./AppsContext";
import { GroupActionButtonsV3 } from "./GroupActionButtonsV3";

interface GroupView {
  key: string;
  title: string;
  content: JSX.Element;
  count?: number;
}

const GroupDetailView = () => {
  const history = useHistory();
  const logEvent = useLogEvent();
  const { authState } = useContext(AuthContext);
  const { groupId } = useParams<Record<string, string>>();
  const redirectToEndUserExp = useHandleRedirectToEndUserExp();
  const canUseGroupProjects = useFeatureFlag(FeatureFlag.GroupProjects);
  const hasEndUserXP = useFeatureFlag(FeatureFlag.EndUserExperience);
  const hasInventory = useHasInventory();
  const searchParams = useQuery();
  const transitionToAccessRequest = useAccessRequestTransition();

  const [showSyncModal, setShowSyncModal] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
  const [showEditGroupBindingModal, setShowEditGroupBindingModal] = useState(
    false
  );
  const [showLinkGroupModal, setShowLinkGroupModal] = useState(false);
  const [showUnlinkGroupModal, setShowUnlinkGroupModal] = useState(false);
  const {
    open: openGroupBindingRequestRedirectModal,
    maybeRenderModal: maybeRenderGroupBindingRequestRedirectModal,
  } = useGroupBindingRequestRedirectModal();
  const [
    showUnmanagedResources,
    setShowUnmanagedResources,
  ] = useBooleanURLSearchParam("unmanaged");
  const [forceRequestModal, setForceRequestModal] = useURLSearchParam(
    "request"
  );

  useRecordViewFor("group", groupId);

  // These are set from the resources view of an imported Okta app (i.e., a non-native integration).
  // When the user navigates to this view from the resources view of an imported Okta app, we want to
  // ensure the breadcrumbs and other UI elements reference the imported Okta app to help the user
  // better understand the context of what they are viewing.
  const oktaAppId = searchParams.get(OKTA_APP_ID_URL_KEY) || undefined;
  const getSearchParameters = () => {
    return oktaAppId ? searchParams : undefined;
  };

  const {
    data: groupData,
    error: groupError,
    loading: groupLoading,
    refetch,
  } = useGroupDetailViewQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      id: groupId,
    },
  });

  let group: GroupDetailViewFragment | undefined;
  let groupNotFound = true;
  if (groupData) {
    switch (groupData.group.__typename) {
      case "GroupResult":
        group = groupData.group.group;
        groupNotFound = false;
        break;
      case "GroupNotFoundError":
        break;
      default:
        logError(new Error(`failed to get group`));
    }
  } else if (groupError) {
    logError(groupError, `failed to get group`);
  }

  const handleRequestAction = (group: GroupDetailViewFragment) => {
    const appId = oktaAppId || group.parentApp?.id || "";
    const isOktaGroupRole =
      oktaAppId || group.parentApp?.app.__typename === "OktaResourceApp";
    if (isGroupBindingRedirectRequired(group)) {
      setShowRequestModal(false);
      openGroupBindingRequestRedirectModal(group.id, group.groupBinding!.id);
      return;
    }
    if (group?.isRequestable) {
      if (hasEndUserXP) {
        setForceRequestModal(null);
        transitionToAccessRequest({
          ...formatRequestDataForItems(
            {
              entityId: group?.id || "",
              entityType: EntityType.Group,
            },
            isOktaGroupRole ? appId : undefined
          ),
          appId: appId,
        });
      } else {
        setShowRequestModal(true);
      }
      return;
    }
  };

  // Redirect users to the correct path when they're browsing a resource but
  // they need to be shown the end user UX.
  useEffect(() => {
    if (!group?.id || !group?.connectionId) {
      return;
    }

    let redirected = redirectToEndUserExp(
      group.connectionId,
      group.id,
      EntityType.Group
    );

    if (!redirected) {
      if (forceRequestModal) {
        return handleRequestAction(group);
      }
    }
    // don't include redirectToEndUserExp as deps
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    group?.id,
    group?.connectionId,
    group?.isRequestable,
    oktaAppId,
    forceRequestModal,
    group?.groupBinding,
    handleRequestAction,
  ]);

  // Fetch the original Okta app details when the group is navigated to via an Okta app screen
  const {
    data: resourceData,
    error: resourceError,
    loading: resourceLoading,
  } = useResourcePreviewQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        id: oktaAppId,
      },
    },
    skip: !oktaAppId,
  });

  let oktaApp: ResourcePreviewSmallFragment | undefined;
  if (resourceData) {
    switch (resourceData.resource.__typename) {
      case "ResourceResult":
        oktaApp = resourceData.resource.resource;
        break;
      case "ResourceNotFoundError":
        break;
      default:
        logError(new Error(`failed to get resource`));
    }
  } else if (resourceError) {
    logError(resourceError, `failed to get resource`);
  }

  const {
    data: syncData,
    error: syncError,
    loading: syncLoading,
  } = useSyncStatusQuery({
    variables: {
      input: {
        syncType: SyncType.PullConnectionsSingleGroup,
        groupId,
      },
    },
    skip: groupNotFound,
  });

  let lastSuccessfulSyncTask: SyncTaskFragment | null = null;
  let syncErrors: SyncErrorFragment[] = [];
  if (syncData) {
    switch (syncData.syncStatus.__typename) {
      case "SyncStatusResult":
        lastSuccessfulSyncTask = syncData.syncStatus.lastSuccessfulSyncTask
          ? syncData.syncStatus.lastSuccessfulSyncTask
          : null;
        syncErrors = syncData.syncStatus.syncErrors;
        break;
      case "InvalidSyncTypeError":
      case "GroupNotFoundError":
        logError(syncData.syncStatus.message);
        break;
    }
  }
  let syncStatus: string;
  if (syncError) {
    syncStatus = "Unable to get status";
  } else if (syncLoading) {
    syncStatus = "Loading sync status";
  } else {
    syncStatus = getSyncLabel(lastSuccessfulSyncTask, syncErrors);
  }

  const syncActionIcon = useSyncActionIcon({
    syncType: SyncType.PullConnectionsSingleGroup,
    group: group ?? undefined,
    queriesToRefetch: ["Group", "GroupAccessLevels"],
    loadingEntity: (groupLoading && !groupData) || groupNotFound,
    label: "Sync group",
    adminOnly: false,
  });

  let contents;
  const actionIcons: PropsFor<typeof ColumnHeader>["actionIcons"] = [];

  const breadcrumbs: PropsFor<typeof ColumnHeader>["breadcrumbs"] = hasInventory
    ? [{ name: "Apps", to: "/inventory" }]
    : [
        { name: "Catalog", to: "/apps" },
        { name: "Apps", to: "/apps" },
      ];

  if ((groupLoading && !groupData) || (resourceLoading && !resourceData)) {
    contents = <ColumnHeaderSkeleton includeCard />;
  } else if (groupNotFound) {
    contents = <NotFoundPage entity="Group" />;
  } else if (!group || groupError) {
    contents = <UnexpectedErrorPage error={groupError} />;
  } else {
    const canManage = group.authorizedActions?.includes(AuthorizedActionManage);
    const userAccessTabVisible =
      group.authorizedActions?.includes(AuthorizedActionManageUser) ||
      group.authorizedActions?.includes(AuthorizedActionManage) ||
      group.authorizedActions?.includes(AuthorizedActionExport);
    const adminTabsVisible =
      group.authorizedActions?.includes(AuthorizedActionManage) ||
      group.authorizedActions?.includes(AuthorizedActionExport);

    const resourcesCount = new Set(
      (showUnmanagedResources
        ? group.groupResources
        : group.groupResources.filter(
            (resource) => resource.resource?.isManaged
          )
      ).map((resource) => resource.resourceId)
    ).size;
    const groupsCount = new Set(
      (showUnmanagedResources
        ? group.containingGroups
        : group.containingGroups.filter(
            (group) => group.containingGroup?.isManaged
          )
      ).map((group) => group.containingGroupId)
    ).size;

    const views: GroupView[] = [
      {
        key: "resources",
        title: "Resources",
        content: (
          <GroupResourcesTableV3
            group={group}
            showUnmanagedResources={showUnmanagedResources}
            setShowUnmanagedResources={setShowUnmanagedResources}
          />
        ),
        count: resourcesCount + groupsCount,
      },
    ];
    if (userAccessTabVisible) {
      views.push({
        key: "users",
        title: "User Access",
        content: <GroupUsersTableV3 group={group} />,
        count: group.numGroupUsers,
      });
    }
    if (canUseGroupProjects) {
      views.push({
        key: "groups",
        title: "Group Access",
        content: (
          <MemberGroupsTableV3
            group={group}
            showUnmanagedGroups={showUnmanagedResources}
            setShowUnmanagedGroups={setShowUnmanagedResources}
          />
        ),
        count: new Set(
          (showUnmanagedResources
            ? group.memberGroups
            : group.memberGroups.filter((group) => group.memberGroup?.isManaged)
          ).map((group) => group.memberGroupId)
        ).size,
      });
    }
    if (adminTabsVisible) {
      views.push({
        key: "events",
        title: "Events",
        content: (
          <EventsTableV3Component
            eventFilter={{
              objects: {
                objectId: group.id,
              },
            }}
            route={{
              pathname: `/groups/${group.id}`,
              hash: "#events",
              search: getSearchParameters()?.toString(),
            }}
          />
        ),
      });
    }
    views.push({
      key: "details",
      title: "Details",
      content: (
        <ResourcesConfigFormV3
          mode="view"
          config={makeConfigForGroup(group, group.configTemplate ?? undefined)}
          onChange={() => {}}
          isViewingAsNonAdmin={!adminTabsVisible}
        />
      ),
    });

    const selectedView = location.hash.slice(1) || views[0].key;

    const tabInfos: PropsFor<typeof TabsV3>["tabInfos"] = views.map((view) => ({
      title: view.title,
      onClick: () =>
        history.push({
          hash: view.key,
          search: getSearchParameters()?.toString(),
        }),
      isSelected: selectedView === view.key,
      count: view.count,
    }));

    if (!hasBasicPermissions(authState.user) || canManage) {
      if (canManage && syncActionIcon) {
        actionIcons.push(syncActionIcon);
      }
      actionIcons.push({
        label: "View Sync Details",
        sublabel: syncStatus,
        onClick: () => {
          logEvent({
            name: "apps_view_sync_details",
            properties: {
              syncType: SyncType.PullConnectionsSingleConnection,
            },
          });
          setShowSyncModal(true);
        },
        adminOnly: false,
      });
    }

    const preMenuLayout = (
      <>
        {canUseGroupProjects && (
          <GroupLeadersDetailsView groupLeaderUsers={group.groupLeaders} />
        )}
      </>
    );
    const rightActions = (
      <GroupActionButtonsV3
        group={group}
        selectedGroupViewKey="overview"
        onNavigate={(viewKey) => {
          if (viewKey === "request") {
            group && handleRequestAction(group);
          }
        }}
        setShowDeleteGroupModal={setShowDeleteGroupModal}
        setShowEditGroupBindingModal={setShowEditGroupBindingModal}
        setShowLinkGroupModal={setShowLinkGroupModal}
        setShowUnlinkGroupModal={setShowUnlinkGroupModal}
      />
    );

    if (!oktaApp && group.connection) {
      breadcrumbs.push({
        name: group.connection.name,
        to: `/apps/${group.connection.id}`,
      });
    }
    if (oktaApp) {
      breadcrumbs.push({
        name: oktaApp.name,
        to: `/resources/${oktaAppId}`,
      });
    }
    breadcrumbs.push({
      name: group.name,
    });

    const footerFields: Record<string, string | JSX.Element> = {
      Admin: group.adminOwner ? (
        <Link
          url={`/owners/${group.adminOwner?.id}`}
          color="black"
          underline={false}
          entityTypeNew={EntityType.Owner}
        >
          {group.adminOwner?.name}
        </Link>
      ) : (
        "--"
      ),
      Visibility: group.visibility === Visibility.Global ? "Global" : "Limited",
      ["Configuration Template"]: group.configTemplate?.name || "No Template",
      ["MFA to Approve"]: group.requireMfaToApprove
        ? "Required"
        : "Not Required",
    };

    const selectedViewInfo =
      views.find((view) => view.key === selectedView) ?? views[0];
    const pillText = oktaApp
      ? `${oktaApp.name} Access Via ${getGroupTypeInfo(group.groupType)?.name}`
      : getGroupTypeInfo(group.groupType)?.name;

    const titleAccessory = group.groupBinding ? (
      <div
        className={sprinkles({
          display: "flex",
          gap: "sm",
          alignItems: "center",
        })}
      >
        <PillV3
          pillColor="Teal"
          icon={{ type: "entity", entityType: group.groupType }}
          keyText={pillText}
        />
        <GroupBindingDetailPopover
          groupId={group.id}
          groupBinding={group.groupBinding}
        />
      </div>
    ) : (
      <PillV3
        pillColor="Teal"
        icon={{ type: "entity", entityType: group.groupType }}
        keyText={pillText}
      />
    );

    contents = (
      <>
        {showSyncModal && (
          <SyncStatusModal
            syncType={SyncType.PullConnectionsSingleGroup}
            entity={group}
            lastSuccessfulSyncTask={lastSuccessfulSyncTask}
            syncErrors={syncErrors}
            isModalOpen={showSyncModal}
            onClose={() => {
              setShowSyncModal(false);
            }}
          />
        )}
        {showRequestModal && (
          <RequestModal
            entity={group}
            isGlobalImpersonationResource={false}
            isImpersonationResource={false}
            entityType={EntityType.Group}
            isModalOpen
            onClose={() => {
              // Persist existing params
              setForceRequestModal(null);
              setShowRequestModal(false);
            }}
          />
        )}
        {showDeleteGroupModal && (
          <GroupDeleteModal
            group={group}
            showModal={showDeleteGroupModal}
            setShowModal={setShowDeleteGroupModal}
          />
        )}
        {showLinkGroupModal && (
          <GroupBindingManualLinkModal
            isOpen={showLinkGroupModal}
            onModalClose={() => {
              setShowLinkGroupModal(false);
              refetch();
            }}
            groupId={group.id}
          />
        )}
        {showEditGroupBindingModal && (
          <GroupBindingEditModal
            isOpen={showEditGroupBindingModal}
            onModalClose={() => {
              setShowEditGroupBindingModal(false);
              refetch();
            }}
            groupBindingIds={[group.groupBinding?.id ?? ""]}
          />
        )}
        {showUnlinkGroupModal && (
          <GroupBindingDeleteModal
            isOpen={showUnlinkGroupModal}
            onModalClose={() => {
              setShowUnlinkGroupModal(false);
              refetch();
            }}
            groupBindingIds={[group.groupBinding?.id ?? ""]}
          />
        )}
        {maybeRenderGroupBindingRequestRedirectModal()}

        <ItemDetailsCard
          icon={
            oktaApp?.iconUrl
              ? { type: "src", icon: oktaApp.iconUrl }
              : {
                  type: "entity",
                  entityType:
                    group.connection?.connectionType || group.groupType,
                }
          }
          title={group.name}
          titleAccessory={titleAccessory}
          subtitle={group.description || "--"}
          preMenuLayout={preMenuLayout}
          rightActions={rightActions}
          footerFields={footerFields}
          tags={group.tags.map((tag) => ({
            id: tag.tagId,
            key: tag.tag?.key || "",
            value: tag.tag?.value || "",
          }))}
          messageChannels={group.auditMessageChannels?.map(
            (channel) => channel.name
          )}
        />
        <TabsV3 tabInfos={tabInfos} />
        {selectedViewInfo?.content}
      </>
    );
  }

  return (
    <OpalPage
      pageTitleOverride={group?.name}
      title={
        hasInventory ? (
          "Inventory"
        ) : (
          <BreadcrumbsV3 breadcrumbInfos={breadcrumbs} />
        )
      }
      icon={hasInventory ? "inventory" : undefined}
      extraActions={<OpalHeaderExtraActions actionIcons={actionIcons} />}
      breadcrumbs={hasInventory ? breadcrumbs : undefined}
    >
      {contents}
    </OpalPage>
  );
};

export default GroupDetailView;
