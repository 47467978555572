import {
  ApiAccessLevel,
  AuthUserFragment,
  EntityType,
  useLeftSidebarRoutesQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { useContext } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

type ApiAccessLevelInfo = {
  name: string;
};

export const apiRoleByRole: Record<ApiAccessLevel, ApiAccessLevelInfo> = {
  [ApiAccessLevel.ReadOnly]: {
    name: "Read-only",
  },
  [ApiAccessLevel.FullAccess]: {
    name: "Full-access",
  },
};

export const generateState = (): string => {
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  let result = "";
  for (let i = 0; i < 40; i++) {
    result += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return result;
};

export const hasBasicPermissions = (user: AuthUserFragment | null): boolean => {
  if (user === null) {
    return false;
  }
  return !user.isAdmin && !user.isReadOnlyAdmin && !user.isAuditor;
};

// Hook to get which experience the user gets
// This applies to V3 and beyond only
export enum UserExperience {
  AdminUX = "admin",
  EndUserUX = "end_user",
}
export const useUserHasUserExperience = (): UserExperience => {
  const { data } = useLeftSidebarRoutesQuery();
  const { authState } = useContext(AuthContext);
  const hasEndUserExp = useFeatureFlag(FeatureFlag.EndUserExperience);
  const { user } = authState;

  if (hasEndUserExp) {
    if (user === null) {
      return UserExperience.EndUserUX;
    }
    if (
      data?.leftSidebarRoutes.hasOwners ||
      data?.leftSidebarRoutes.isGroupLeader ||
      !hasBasicPermissions(user)
    ) {
      return UserExperience.AdminUX;
    }
    return UserExperience.EndUserUX;
  }

  return UserExperience.AdminUX;
};

export const useRestrictUserViewingEntity = (
  entityType?: EntityType | null
) => {
  const { authState } = useContext(AuthContext);
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  if (authState.user === null) {
    return true;
  }
  const isAdmin = authState.user.isAdmin;
  const hasOnlyBasicPermissions = hasBasicPermissions(authState.user);
  switch (entityType) {
    case EntityType.Connection:
      return !isAdmin;
    case EntityType.User:
    case EntityType.Owner:
    case EntityType.Tag:
      return hasV3 && hasOnlyBasicPermissions;
    default:
      return false;
  }
};

export const PresignedURLQueryParam = "ps";

export const getPresignedAuthentication = (): string | null => {
  const url = new URL(window.location.href);
  return url.searchParams.get(PresignedURLQueryParam);
};

// TODO: generate from web/backend/models/schema/authorizedactions/authorized_actions.go
export const AuthorizedActionRead = "read";
export const AuthorizedActionManage = "manage";
export const AuthorizedActionManageUser = "manage_user";
export const AuthorizedActionRequest = "request";
export const AuthorizedActionExport = "export";
export const AuthorizedActionSync = "sync";
export const AuthorizedActionCreateAccessReview = "create_access_review";
export const AuthorizedActionCreateAccessReviewTemplate =
  "create_access_review_template";
