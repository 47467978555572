import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
  withStyles,
} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Typography from "@material-ui/core/Typography";
import clsx from "clsx";
import ThreeStateCheckbox, {
  CheckboxState,
} from "components/tables/material_table/ThreeStateCheckbox";
import { Button } from "components/ui";
import sort from "fast-sort";
import React, { ReactElement } from "react";

export type CellDataValue = string | number;

export type Header<Data> = {
  id: keyof Data;
  label: string;
  hide?: boolean;
};

export type CellData = {
  sortValue?: CellDataValue;
  value: CellDataValue;
  element: React.ReactElement;
  className?: string;
  overflowClassName?: string;
  hide?: boolean;
  style?: { minWidth: string };
  clickHandler?: () => void;
};

export type CellRow<Data> = {
  id: string;
  data: Record<keyof Data, CellData>;
};

type OrderDirection = "asc" | "desc";
type Order<Data> = {
  direction: OrderDirection;
  orderBy: keyof Data;
};

const compareByType: (field: CellDataValue) => CellDataValue = (
  field: CellDataValue
) => {
  switch (typeof field) {
    case "string":
      return field.toLowerCase();
    default:
      return field;
  }
};

function stableSort<Data>(cellRows: CellRow<Data>[], order: Order<Data>) {
  if (order.direction === "asc") {
    return sort(cellRows).asc((c) =>
      compareByType(
        c.data[order.orderBy].sortValue || c.data[order.orderBy].value
      )
    );
  }
  return sort(cellRows).desc((c) =>
    compareByType(
      c.data[order.orderBy].sortValue || c.data[order.orderBy].value
    )
  );
}

interface MaterialTableHeadProps<Data> {
  classes: ReturnType<typeof useStyles>;
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  order?: OrderDirection;
  orderBy: keyof Data;
  rowCount: number;
  headers: Header<Data>[];
  hasExtraFirstColumn: boolean;
}

function MaterialTableHead<Data>(props: MaterialTableHeadProps<Data>) {
  const filteredHeaders = props.headers.filter((header) => !header.hide);
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (
    event: React.MouseEvent<unknown>
  ) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {props.hasExtraFirstColumn && (
          <StyledTableCell padding="checkbox" className={classes.header}>
            <div />
          </StyledTableCell>
        )}
        {filteredHeaders.map((header) => (
          <StyledTableCell
            className={classes.header}
            key={header.id.toString()}
            align={"left"}
            padding={"default"}
            sortDirection={orderBy === header.id ? order : false}
          >
            <TableSortLabel
              className={classes.header}
              active={order && orderBy === header.id}
              direction={orderBy === header.id ? order : "asc"}
              onClick={createSortHandler(header.id)}
            >
              {header.label}
              {orderBy === header.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </StyledTableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    buttons: {
      whiteSpace: "nowrap",
      fontWeight: "bold",
    },
    root: {
      marginBottom: "22px",
      display: "flex",
      border: 0,
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
      fontWeight: "bold",
      margin: "8px 0px",
    },
  })
);

interface MaterialTableToolbarProps {
  title?: string;
  buttons?: ReactElement;
}

const MaterialTableToolbar = (props: MaterialTableToolbarProps) => {
  const classes = useToolbarStyles();

  return props.title ? (
    <div className={clsx(classes.root, {})}>
      <Typography className={classes.title} id="tableTitle" component="div">
        {props.title}
      </Typography>
      <div className={classes.buttons}>{props.buttons && props.buttons}</div>
    </div>
  ) : (
    <></>
  );
};

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      margin: "0px 0px 0px 0px",
      width: "100%",
    },
    row: {
      "&:hover": {
        cursor: "auto",
      },
    },
    header: {
      border: 0,
      padding: "0px 0px 0px 16px",
      height: "48px",
      color: "#000000",
      fontSize: "14px",
      fontWeight: "bold",
      "&:hover": {
        color: "#000000",
      },
    },
    cell: {
      border: 0,
      padding: 0,
      fontSize: "14px",
      content: "X",
    },
    cellOverflow: {
      padding: "0px 0px 0px 16px",
      boxSizing: "border-box",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    table: {},
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

const StyledTableRow = withStyles({
  root: {
    border: 0,
    height: "48px",
    cursor: "pointer",
    "&:hover": {
      cursor: "auto",
      backgroundColor: "rgba(0, 0, 0, 0.03)",
    },
    "&$selected, &$selected:hover": {
      backgroundColor: "rgb(230, 69, 69, 0.3)",
    },
  },
  selected: {},
  hover: {},
})(TableRow);

const StyledTableCell = withStyles({
  root: {
    border: 0,
    padding: 0,
    fontSize: "14px",
  },
  stickyHeader: {
    position: "sticky",
    top: 0,
    left: 0,
    zIndex: 2,
    backgroundColor: "white",
  },
})(TableCell);

export type MaterialTableProps<Data> = {
  title?: string;
  headers: Header<Data>[];
  rows: CellRow<Data>[];
  buttons?: ReactElement;
  loadingLabel?: string;
  numRowsPerPage?: number;
  denseFormatting?: boolean;
  defaultSortField?: keyof Data;
  defaultSortOrder?: OrderDirection;
  isUnsorted?: boolean;
  tableContainerStyles?: React.CSSProperties;
  checkboxStatesById?: Record<string, CheckboxState>;
  setCheckboxStateById?: (id: string, checkboxState: CheckboxState) => void;
  onRowDelete?: (rowId: string) => void;
};

function MaterialTable<Data>(props: MaterialTableProps<Data>) {
  const classes = useStyles();
  const filteredHeaders = props.headers.filter((header) => !header.hide);
  const defaultOrderBy = filteredHeaders.find(
    (head) => head.id === props.defaultSortField
  )?.id;
  const [order, setOrder] = React.useState<Order<Data>>({
    direction: props.defaultSortOrder || "desc",
    orderBy: defaultOrderBy || filteredHeaders[0].id,
  });
  const [unsorted, setUnsorted] = React.useState<boolean>(
    props.isUnsorted ?? false
  );

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = order.orderBy === property && order.direction === "asc";
    setOrder({
      direction: isAsc ? "desc" : "asc",
      orderBy: property,
    });
    setUnsorted(false);
  };

  const useCheckboxes = !!(
    props.checkboxStatesById && props.setCheckboxStateById
  );
  const useDeleteButtons = !!props.onRowDelete;

  return (
    <div className={classes.root}>
      <MaterialTableToolbar title={props.title} buttons={props.buttons} />
      <TableContainer style={props.tableContainerStyles}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size={"small"}
          aria-label="enhanced table"
          stickyHeader
        >
          <MaterialTableHead
            classes={classes}
            numSelected={0}
            order={unsorted ? undefined : order.direction}
            orderBy={order.orderBy}
            onRequestSort={handleRequestSort}
            rowCount={props.rows.length}
            headers={filteredHeaders}
            hasExtraFirstColumn={useCheckboxes || useDeleteButtons}
          />
          <TableBody>
            {(unsorted ? props.rows : stableSort(props.rows, order)).map(
              (row: CellRow<Data>) => {
                const checkboxState =
                  (props.checkboxStatesById &&
                    props.checkboxStatesById[row.id]) ||
                  CheckboxState.Unselected;
                return (
                  <StyledTableRow
                    role="checkbox"
                    tabIndex={-1}
                    key={row.id}
                    selected={checkboxState === CheckboxState.Indeterminate}
                  >
                    {useCheckboxes && (
                      <StyledTableCell
                        padding="checkbox"
                        className={classes.cell}
                      >
                        <ThreeStateCheckbox
                          checkboxState={checkboxState}
                          setCheckboxState={(checkboxState) => {
                            props.setCheckboxStateById &&
                              props.setCheckboxStateById(row.id, checkboxState);
                          }}
                        />
                      </StyledTableCell>
                    )}
                    {useDeleteButtons && (
                      <StyledTableCell className={classes.cell}>
                        <Button
                          leftIconName="x"
                          onClick={() => props.onRowDelete!(row.id)}
                          round
                        />
                      </StyledTableCell>
                    )}
                    {Object.keys(row.data)
                      .filter((key) => {
                        const cellData: CellData = row.data[key as keyof Data];
                        return !cellData.hide;
                      })
                      .map(function (key: string, i: number) {
                        const cellData: CellData = row.data[key as keyof Data];
                        let style = cellData.style || {};
                        if (cellData.clickHandler) {
                          style = {
                            ...style,
                            hover: {
                              cursor: "pointer",
                            },
                          };
                        }
                        return (
                          <StyledTableCell
                            key={`cell_key_${row.id}_${i}`}
                            className={cellData.className || classes.cell}
                            style={style}
                            align="left"
                            onClick={() => {
                              cellData.clickHandler && cellData.clickHandler();
                            }}
                          >
                            <div
                              className={
                                cellData.overflowClassName ||
                                classes.cellOverflow
                              }
                            >
                              {cellData.element
                                ? cellData.element
                                : cellData.value}
                            </div>
                          </StyledTableCell>
                        );
                      })}
                  </StyledTableRow>
                );
              }
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default MaterialTable;
