import { EntityType } from "api/generated/graphql";
import { PillV3, TagPillV3 } from "components/pills/PillsV3";
import {
  ButtonV3,
  ContextMenu,
  EntityIcon,
  Icon,
  Modal,
  Skeleton,
} from "components/ui";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";

import * as styles from "./ItemDetailsCard.css";

type ItemDetailsCardProps = {
  icon?: IconData;
  title?: string;
  subtitle?: string;
  preMenuLayout?: JSX.Element;
  rightActions?: JSX.Element;
  extraMenuOptions?: PropsFor<typeof ContextMenu>["options"];
  bodyFields?: Record<string, JSX.Element | string>;
  footerFields?: Record<string, string | JSX.Element>;
  tags?: { id: string; key: string; value: string }[];
  messageChannels?: string[];
  titleAccessory?: JSX.Element;
  footerElement?: JSX.Element;
  url?: string;
};

type MetadataListProps = {
  items: JSX.Element[];
  type: "tags" | "messageChannels";
  title: string;
};

export const MetadataList = ({ items, type, title }: MetadataListProps) => {
  const [showModal, setShowModal] = useState(false);

  if (!items.length) return null;
  return (
    <>
      <div
        className={styles.listedMetadata({
          type: type,
        })}
      >
        {items.slice(0, 3)}
        {items.length > 3 ? (
          <div
            className={styles.showMoreLink}
            onClick={() => setShowModal(!showModal)}
          >
            {"+ " + String(items.length - 3) + " more"}
          </div>
        ) : (
          ""
        )}
      </div>
      {showModal && (
        <Modal
          isOpen={showModal}
          onClose={() => {
            setShowModal(false);
          }}
          title={title}
          maxWidth={"sm"}
        >
          <Modal.Body>
            <div
              className={sprinkles({
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
                gap: "md",
              })}
            >
              {items}
            </div>
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Close"
            onPrimaryButtonClick={() => {
              setShowModal(false);
            }}
          />
        </Modal>
      )}
    </>
  );
};

export const ItemDetailsCard = (props: ItemDetailsCardProps) => {
  const {
    bodyFields = {},
    footerFields = {},
    tags = [],
    messageChannels = [],
  } = props;

  const itemTags: JSX.Element[] = tags.map((tag) => {
    return (
      <TagPillV3
        key={tag.key}
        icon={{ type: "name", icon: "tag" }}
        pillColor="LightGreen"
        keyText={tag.key}
        valueText={tag.value}
        entityId={{
          entityId: tag.id,
          entityType: EntityType.Tag,
        }}
      />
    );
  });

  const itemMessageChannels: JSX.Element[] = messageChannels.map((channel) => {
    return (
      <PillV3
        key={channel}
        icon={{ type: "name", icon: "slack" }}
        pillColor="Purple"
        keyText={channel}
      />
    );
  });

  const hasIcon = Boolean(props.icon);
  const renderIcon = () => {
    const iconData = props.icon;
    if (!iconData) return null;

    if (iconData.type === "src") {
      return <Icon data={iconData} size="xxl" iconStyle="rounded" />;
    } else if (iconData.type === "name") {
      return <Icon name={iconData.icon} size="xxl" iconStyle="rounded" />;
    } else {
      return (
        <EntityIcon type={iconData.entityType} size="xxl" iconStyle="rounded" />
      );
    }
  };

  const renderBodyFields = () => {
    if (!Object.entries(bodyFields)) return;
    return Object.entries(bodyFields).map(([fieldName, fieldValue]) => {
      return (
        <div className={styles.bodyField} key={fieldName}>
          <div>{fieldName}</div>
          <div>{fieldValue}</div>
        </div>
      );
    });
  };

  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <div className={sprinkles({ display: "flex", gap: "mdlg" })}>
          {renderIcon()}
          <div
            className={sprinkles({ display: "flex", flexDirection: "column" })}
          >
            <div className={styles.title}>
              {props.title}
              {props.titleAccessory && (
                <div className={styles.titleAccessory}>
                  {props.titleAccessory}
                </div>
              )}
            </div>
            <div className={styles.subtitle}>{props.subtitle}</div>
          </div>
        </div>
        <div className={styles.actions}>
          {props.preMenuLayout}
          {props.rightActions}
          {props.extraMenuOptions && props.extraMenuOptions.length > 0 && (
            <ContextMenu
              rightAligned
              options={props.extraMenuOptions}
              renderButton={(onClick) => (
                <div
                  className={sprinkles({ marginLeft: "sm" })}
                  onClick={onClick}
                >
                  <ButtonV3 leftIconName="dots-horizontal" size="sm" />
                </div>
              )}
            />
          )}
        </div>
      </div>
      <div className={styles.cardBody({ hasIcon })}>
        <MetadataList
          items={itemTags}
          type="tags"
          title={`Tags on ${props.title}`}
        />
        {(itemMessageChannels || Object.entries(bodyFields)) && (
          <div
            className={sprinkles({
              display: "flex",
              gap: "lg",
            })}
          >
            <MetadataList
              items={itemMessageChannels}
              type="messageChannels"
              title={`Message Channels Linked to ${props.title}`}
            />
            {renderBodyFields()}
          </div>
        )}
      </div>
      {(Object.keys(footerFields).length > 0 || props.footerElement) && (
        <div className={styles.cardFooter}>
          {Object.keys(footerFields).length > 0 && (
            <div
              className={sprinkles({
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
              })}
            >
              {Object.entries(footerFields)
                .filter(([, fieldValue]) => fieldValue)
                .map(([fieldName, fieldValue]) => {
                  return (
                    <div
                      key={fieldName}
                      className={sprinkles({
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      })}
                    >
                      <div className={sprinkles({ fontSize: "bodyMd" })}>
                        {fieldValue}
                      </div>
                      <div
                        className={sprinkles({
                          fontSize: "bodySm",
                          color: "gray700",
                        })}
                      >
                        {fieldName}
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          {props.footerElement}
        </div>
      )}
    </div>
  );
};

export const ItemDetailsCardSkeleton = () => {
  return (
    <div className={styles.card}>
      <div className={styles.cardHeader}>
        <div className={sprinkles({ width: "100%" })}>
          <Skeleton height="100px" width="60%" />
          <Skeleton height="40px" width="60%" />
          <Skeleton height="40px" width="100%" />
          <Skeleton height="40px" width="100%" />
        </div>
      </div>
    </div>
  );
};
