import {
  Maybe,
  ResourceAccessLevel,
  ResourceAccessLevelFragment,
  useResourceAccessLevelsQuery,
} from "api/generated/graphql";
import RequestModalRoleDropdown from "components/modals/RequestModalRoleDropdown";
import { Modal } from "components/ui";
import React, { useState } from "react";
import { logError } from "utils/logging";

type AccessReviewEditRoleModalProps = {
  onSubmit: (role: ResourceAccessLevel) => void;
  onClose: () => void;
  currentRole: Maybe<ResourceAccessLevel>;
  resourceId: string;
};

const AccessReviewEditResourceRoleModal = (
  props: AccessReviewEditRoleModalProps
) => {
  const [selectedRole, setSelectedRole] = useState<
    ResourceAccessLevel | undefined
  >(props.currentRole ?? undefined);

  const { data, loading, error } = useResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceId: props.resourceId,
      },
    },
  });

  let roles: ResourceAccessLevelFragment[] = [];
  if (data) {
    switch (data.accessLevels.__typename) {
      case "ResourceAccessLevelsResult": {
        roles.push(...data.accessLevels.accessLevels);
        break;
      }
      case "ResourceNotFoundError":
        logError(new Error(`Error: failed to list resource roles`));
        break;
    }
  } else if (error) {
    logError(error, `failed to list resource roles`);
  }

  return (
    <Modal isOpen={true} onClose={props.onClose} title="Edit role">
      <Modal.Body>
        <RequestModalRoleDropdown
          availableRoles={roles}
          selectedRole={selectedRole}
          setSelectedRole={(role) =>
            // TODO: we don't yet support roles on group in UAR, so we can simply cast this for now
            setSelectedRole(role as ResourceAccessLevel)
          }
          isImpersonationResource={false}
          loading={loading}
        />
      </Modal.Body>
      <Modal.Footer
        onSecondaryButtonClick={props.onClose}
        onPrimaryButtonClick={() => {
          if (selectedRole) {
            props.onSubmit(selectedRole);
          }
        }}
        primaryButtonLabel={"Select"}
        primaryButtonDisabled={
          !selectedRole ||
          selectedRole.accessLevelRemoteId ===
            props.currentRole?.accessLevelRemoteId
        }
      />
    </Modal>
  );
};

export default AccessReviewEditResourceRoleModal;
