import { EntityType } from "api/generated/graphql";
import clsx from "clsx";
import AuthContext from "components/auth/AuthContext";
import emptyStateStyles from "components/tables/EmptyState.module.scss";
import { Button, Tooltip } from "components/ui";
import { IconName } from "components/ui/icon/Icon";
import React, { ReactElement, useContext } from "react";
import * as Icons from "react-feather";
import ViewSkeleton from "views/loading/ViewSkeleton";
import OrgContext from "views/settings/OrgContext";

export type EmptyStateProps = {
  entityType?: EntityType;
  title: string;
  subtitle?: string;
  icon?: ReactElement;
  buttonTitle?: string;
  buttonIcon?: IconName;
  onClickHandler?: () => void;
  secondaryButtonTitle?: string;
  secondaryButtonIcon?: ReactElement;
  onSecondaryClickHandler?: () => void;
  small?: boolean;
};

export const EmptyState = (props: EmptyStateProps) => {
  let icon = props.icon;
  if (!icon) {
    switch (props.entityType) {
      case EntityType.Resource:
        icon = <Icons.Box strokeWidth={1} />;
        break;
      case EntityType.Group:
        icon = <Icons.Users strokeWidth={1} />;
        break;
      case EntityType.User:
        icon = <Icons.User strokeWidth={1} />;
        break;
      case EntityType.Connection:
        icon = <Icons.Layers strokeWidth={1} />;
        break;
      case EntityType.SearchQuery:
        icon = <Icons.Search strokeWidth={1} />;
        break;
      case EntityType.Request:
        icon = <Icons.Inbox strokeWidth={1} />;
        break;
      case EntityType.Activity:
        icon = <Icons.Terminal strokeWidth={1} />;
        break;
      case EntityType.AccessLevel:
        icon = <Icons.Key strokeWidth={1} />;
        break;
      case EntityType.AccessReview:
        icon = <Icons.CheckSquare strokeWidth={1} />;
        break;
      case EntityType.AccessReviewTemplate:
        icon = <Icons.RefreshCw strokeWidth={1} />;
        break;
      case EntityType.AuthToken:
        icon = <Icons.Disc strokeWidth={1} />;
        break;
      case EntityType.Tag:
        icon = <Icons.Tag strokeWidth={1} />;
        break;
      case EntityType.Event:
        icon = <Icons.Activity strokeWidth={1} />;
        break;
    }
  }

  const { authState } = useContext(AuthContext);
  const { orgState } = useContext(OrgContext);

  let primaryButton = (
    <Button
      outline
      label={props.buttonTitle || ""}
      leftIconName={props.buttonIcon ?? "plus"}
      onClick={props.onClickHandler}
    />
  );
  let secondaryButton = (
    <Button
      outline
      label={props.secondaryButtonTitle || ""}
      leftIconName={props.buttonIcon ?? "plus"}
      onClick={props.onSecondaryClickHandler}
    />
  );
  if (orgState.orgSettings?.isRemoteReadOnly) {
    const tooltipText =
      "Disabled while your organization is in read only mode.";
    primaryButton = (
      <Tooltip tooltipText={tooltipText}>{primaryButton}</Tooltip>
    );
    secondaryButton = (
      <Tooltip tooltipText={tooltipText}>{secondaryButton}</Tooltip>
    );
  }

  return (
    <div
      className={clsx({
        [emptyStateStyles.emptyStateContainer]: true,
        [emptyStateStyles.emptyStateContainerSmall]: props.small,
      })}
    >
      <div className={emptyStateStyles.content}>
        <div className={emptyStateStyles.icon}>{icon}</div>
        <div className={emptyStateStyles.header}>
          <div className={emptyStateStyles.title}>{props.title}</div>
          {props.subtitle && (
            <div className={emptyStateStyles.subtitle}>{props.subtitle}</div>
          )}
        </div>
        {props.onClickHandler && authState.user?.isAdmin && (
          <div className={emptyStateStyles.button}>{primaryButton}</div>
        )}
        {props.onSecondaryClickHandler && authState.user?.isAdmin && (
          <div className={emptyStateStyles.button}>{secondaryButton}</div>
        )}
      </div>
    </div>
  );
};

export type EmptyStateTableWrapperProps = {
  header?: ReactElement;
  content?: ReactElement;
  isEmpty: boolean;
  entityType?: EntityType;
  title: string;
  subtitle?: string;
  buttonTitle?: string;
  icon?: ReactElement;
  buttonIcon?: IconName;
  onClickHandler?: () => void;
  secondaryButtonTitle?: string;
  secondaryButtonIcon?: ReactElement;
  onSecondaryClickHandler?: () => void;
  small?: boolean;
  extraSmall?: boolean;
  loading?: boolean;
};

export const EmptyStateContentWrapper = (
  props: EmptyStateTableWrapperProps
) => {
  return (
    <div className={emptyStateStyles.tableWrapper}>
      {props.loading ? (
        <ViewSkeleton />
      ) : props.isEmpty ? (
        <EmptyState
          entityType={props.entityType}
          title={props.title}
          subtitle={props.subtitle}
          icon={props.icon}
          buttonTitle={props.buttonTitle}
          buttonIcon={props.buttonIcon}
          onClickHandler={props.onClickHandler}
          small={props.small}
          secondaryButtonTitle={props.secondaryButtonTitle}
          secondaryButtonIcon={props.secondaryButtonIcon}
          onSecondaryClickHandler={props.onSecondaryClickHandler}
        />
      ) : (
        <>
          {props.header}
          <div className={emptyStateStyles.table}>{props.content}</div>
        </>
      )}
    </div>
  );
};
