import sprinkles from "css/sprinkles.css";
import React from "react";

export type FileAccept =
  | ".csv"
  | ".json"
  | ".pem"
  | ".crt"
  | ".key"
  | ".txt"
  | ".png"
  | ".jpeg"
  | ".jpg"
  | ".cer"
  | ".cert";

export const CERTIFICATE_FILE_ACCEPT: FileAccept[] = [
  ".pem",
  ".crt",
  ".cer",
  ".cert",
];

interface Props {
  renderButton: (onClick: () => void) => React.ReactNode;
  // Currently, only single file upload is supported
  handleUpload: (file: File) => void;
  accept?: FileAccept[];
}

const FileUpload = (props: Props) => {
  const hiddenFileInput = React.useRef<HTMLInputElement | null>(null);

  const handleClick = () => {
    hiddenFileInput.current?.click();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length > 0) {
      const fileUploaded = event.target.files[0];
      props.handleUpload(fileUploaded);
      // This is to clear the file input after the file, so that the user can upload the same file again
      event.target.value = "";
    }
  };

  return (
    <>
      {props.renderButton(handleClick)}
      <input
        type="file"
        accept={props.accept?.join(",")}
        ref={hiddenFileInput}
        onInput={handleChange}
        className={sprinkles({ display: "none" })}
      />
    </>
  );
};

export default FileUpload;
