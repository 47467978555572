import { useAuthCodeCallbackMutation } from "api/generated/graphql";
import AuthContext, {
  AuthContextActionType,
} from "components/auth/AuthContext";
import { ToastStyle } from "components/toast/Toast";
import { getToastUrl, MessageCode } from "components/toast/ToastUrlParser";
import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom-v5-compat";
import { AuthResultStatus } from "utils/auth/AuthClient";
import { RequestState } from "utils/common";
import { useMountEffect } from "utils/hooks";
import { logError, logWarning } from "utils/logging";
import { FullPageLoading } from "views/loading/FullPageLoading";

// AuthCodeCallback is a dedicated component for handling the auth redirect
// and parsing/removing the auth token fragment from the URL.
export const AuthCodeCallbackUnauthed = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { authState, authDispatch } = useContext(AuthContext);

  const [authCodeCallback] = useAuthCodeCallbackMutation();

  // handle initializing auth flow
  useMountEffect(() => {
    async function createIntegrationWrapper() {
      if (authState.isAuthenticated) {
        return;
      }

      const query = new URLSearchParams(location.search);
      const code = query.get("code");
      const state = query.get("state");
      const urlError = query.get("error");
      const urlErrorDescription = query.get("error_description");

      if (code && state) {
        try {
          // We have received callback parameters from Auth0, so we need to forward them
          // on to the Opal server.
          const { data } = await authCodeCallback({
            variables: {
              input: {
                code: code,
                state: state,
              },
            },
          });
          switch (data?.authCodeCallback.__typename) {
            case "AuthCodeCallbackResult":
              // We have passed the Auth0 callback data to the Opal server and received a successful
              // response from the Opal server containing user information, which we dispatch to our
              // auth context. This will update `authState.isAuthenticated` to be `true`, which will
              // cause a redirect away from this page.
              authDispatch({
                type: AuthContextActionType.SignIn,
                payload: {
                  user: data.authCodeCallback.user,
                },
              });
              break;
            case "UserNotFoundError": {
              logWarning(new Error(data?.authCodeCallback.message));
              const additionalParams: Record<string, string> = {};
              if (data.authCodeCallback.email) {
                additionalParams["email"] = data.authCodeCallback.email;
              }
              navigate(
                getToastUrl(
                  "/sign-out",
                  RequestState.Failure,
                  MessageCode.ErrorNotInvitedSignIn,
                  ToastStyle.Banner,
                  undefined,
                  additionalParams
                ),
                { replace: true }
              );
              break;
            }
            case "SamlLoginRequiredError":
              logWarning(new Error(data?.authCodeCallback.message));
              navigate(
                getToastUrl(
                  "/sign-out",
                  RequestState.Failure,
                  MessageCode.ErrorSamlLoginRequired,
                  ToastStyle.Banner
                ),
                { replace: true }
              );
              break;
            case "UserAlreadyExistsInAnotherOrg":
              logWarning(new Error(data?.authCodeCallback.message));
              navigate(
                getToastUrl(
                  "/sign-out",
                  RequestState.Failure,
                  MessageCode.ErrorUserAlreadyExistsInAnotherOrg,
                  ToastStyle.Banner
                ),
                { replace: true }
              );
              break;
            default:
              logError(new Error(`auth code callback failed`));
              navigate(
                getToastUrl(
                  "/sign-out",
                  RequestState.Failure,
                  MessageCode.ErrorAuthFlowFailure,
                  ToastStyle.Banner
                ),
                { replace: true }
              );
          }
        } catch (error) {
          logError(error, `auth code callback failed`);
          navigate(
            getToastUrl(
              "/sign-out",
              RequestState.Failure,
              MessageCode.ErrorAuthFlowFailure,
              ToastStyle.Banner
            ),
            { replace: true }
          );
        }
      } else if (urlError) {
        let messageCode = null;
        if (urlError === AuthResultStatus.Unauthorized) {
          messageCode = MessageCode.ErrorAuthSessionNotGated;
        } else if (urlError === AuthResultStatus.LoginRequired) {
          messageCode = MessageCode.ErrorLoginRequired;
        } else if (urlError === AuthResultStatus.AccessDenied) {
          messageCode = MessageCode.ErrorMfaResetRequired;
        }
        logError(urlError, `auth code callback failed: ${urlErrorDescription}`);

        window.location.href = getToastUrl(
          "/sign-out",
          RequestState.Failure,
          messageCode,
          ToastStyle.Banner
        );
      } else {
        logError(
          new Error(
            `auth code callback failed because code or state was missing`
          )
        );

        navigate(
          getToastUrl(
            "/sign-out",
            RequestState.Failure,
            MessageCode.ErrorAuthFlowFailure,
            ToastStyle.Banner
          ),
          {
            replace: true,
          }
        );
      }
    }

    createIntegrationWrapper();
  });

  return <FullPageLoading />;
};

export default AuthCodeCallbackUnauthed;
