import {
  useGroupBindingDetailsQuery,
  useUpdateGroupBindingsMutation,
} from "api/generated/graphql";
import { useToast } from "components/toast/Toast";
import { useMemo, useState } from "react";
import useLogEvent from "utils/analytics";
import { logError } from "utils/logging";
import GroupBindingEditModalBase, {
  GroupBindingState,
} from "views/group_bindings/modals/GroupBindingEditModalBase";

type Props = {
  isOpen: boolean;
  onModalClose: () => void;
  groupBindingIds: string[];
};

const GroupBindingEditModal: React.FC<Props> = (props: Props) => {
  const { displaySuccessToast, displayErrorToast } = useToast();
  const { data, loading, error } = useGroupBindingDetailsQuery({
    variables: {
      input: props.groupBindingIds,
    },
    skip: props.groupBindingIds.length === 0,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const logEvent = useLogEvent();

  const groupBindings = useMemo(
    () => data?.groupBindings?.groupBindings ?? [],
    [data]
  );

  const [
    updateGroupBindings,
    { loading: updateLoading },
  ] = useUpdateGroupBindingsMutation({
    refetchQueries: ["GroupBindingsTable"],
  });

  if (error) {
    logError(error, "failed to group bindings");
    displayErrorToast("Failed to linked groups, please try again.");
    return null;
  }

  const onUpdate = async (bindings: GroupBindingState[]) => {
    const invalidBindings = bindings.filter(
      (binding) => !binding.sourceGroup || binding.groups.length === 0
    );
    if (invalidBindings.length > 0) {
      setErrorMessage(
        "Please select a source of truth and at least one linked group for each binding."
      );
      return false;
    }
    setErrorMessage("");

    const result = await updateGroupBindings({
      variables: {
        input: {
          groupBindings: bindings.map((binding) => ({
            id: binding.id,
            sourceGroupId: binding.sourceGroup!.id,
            groupIds: binding.groups.map((g) => g.id),
          })),
        },
      },
    });

    const data = result.data?.updateGroupBindings;
    switch (data?.__typename) {
      case "UpdateGroupBindingsResult":
        props.onModalClose();
        displaySuccessToast("Successfully updated linked groups");
        logEvent({ name: "group_bindings_edit" });
        break;
      case "GroupAlreadyBelongsToBindingError":
        if (data.group?.name && data.groupBinding?.sourceGroup?.name) {
          setErrorMessage(
            `Group ${data.group.name} is already being linked to ${data.groupBinding.sourceGroup.name}`
          );
        } else {
          setErrorMessage(
            "Failed to update linked groups: one of the groups is already linked"
          );
        }
        break;
      case "GroupBindingHasNoGroupsError":
        setErrorMessage(
          "Failed to update linked groups: one of the bindings has no groups"
        );
        break;
      case "GroupBindingNotFoundError":
        setErrorMessage("Failed to update linked groups: not found");
        break;
      default:
        break;
    }
  };

  var modalTitle = "Update linked groups";
  var modalSubtitle =
    "Select the groups you want to link or change the source of truth.";
  if (groupBindings.length > 1) {
    modalTitle = `Updating ${groupBindings.length} linked groups`;
  }

  return (
    <GroupBindingEditModalBase
      isOpen={props.isOpen}
      onModalClose={props.onModalClose}
      title={modalTitle}
      subtitle={modalSubtitle}
      submitButtonLabel={"Update"}
      onModalSubmit={onUpdate}
      initialGroupBindings={groupBindings.map<GroupBindingState>((binding) => ({
        id: binding.id,
        sourceGroup: binding.sourceGroup ?? undefined,
        groups: binding.groups,
      }))}
      loading={loading || updateLoading}
      errorMessage={errorMessage}
    />
  );
};

export default GroupBindingEditModal;
