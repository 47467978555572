import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewAction,
  AccessReviewConnectionFragment,
  AccessReviewGroupFragment,
  AccessReviewResourceFragment,
  AccessReviewUserFragment,
  Maybe,
  OidcProviderType,
  ReviewerUserStatus,
  useInitOidcAuthFlowMutation,
  usePerformReviewMutation,
} from "api/generated/graphql";
import clsx from "clsx";
import AuthContext from "components/auth/AuthContext";
import modalStyles from "components/modals/Modals.module.scss";
import ConfirmModal from "components/modals/update/ConfirmModal";
import { useToast } from "components/toast/Toast";
import { Button } from "components/ui";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { ReactElement, useContext, useState } from "react";
import { useHistory } from "react-router";
import { generateState } from "utils/auth/auth";
import { useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";
import {
  clearOidcData,
  getOidcData,
  OidcPostAuthAction,
  setOidcData,
} from "utils/oidc/oidc";
import AccessReviewContext, {
  AccessReviewContextActionType,
  PerformReviewState,
} from "views/access_reviews/AccessReviewContext";
import { getAccessReviewUrl } from "views/access_reviews/common/Routes";
import styles from "views/access_reviews/common/SubmitReviewModalStyles.module.scss";

type SubmitAccessReviewButtonsProps = {
  entityName: string;
  accessReviewId: string;
  performReviewState: PerformReviewState;
  accessReviewItem:
    | AccessReviewResourceFragment
    | AccessReviewGroupFragment
    | AccessReviewUserFragment
    | AccessReviewConnectionFragment;
};

export const SubmitAccessReviewButtons = (
  props: SubmitAccessReviewButtonsProps
) => {
  const { accessReviewDispatch } = useContext(AccessReviewContext);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [performReview, { loading }] = usePerformReviewMutation();
  const [initOidcAuthFlow] = useInitOidcAuthFlowMutation();
  const { displaySuccessToast, displayErrorToast } = useToast();
  const history = useHistory();
  const { authState } = useContext(AuthContext);

  const filterReviewState = (performReviewState: PerformReviewState) => {
    const resourceUserActions = performReviewState.resourceUserActions
      .filter((action) => action.action !== AccessReviewAction.NoAction)
      .map((action) => {
        if (action.updatedAccessLevel) {
          action.updatedAccessLevel = {
            accessLevelName: action.updatedAccessLevel.accessLevelName,
            accessLevelRemoteId: action.updatedAccessLevel.accessLevelRemoteId,
          };
        }
        return action;
      });
    const groupUserActions = performReviewState.groupUserActions.filter(
      (action) => action.action !== AccessReviewAction.NoAction
    );
    const groupResourceActions = performReviewState.groupResourceActions.filter(
      (action) => action.action !== AccessReviewAction.NoAction
    );
    const connectionUserActions = performReviewState.connectionUserActions.filter(
      (action) => action.action !== AccessReviewAction.NoAction
    );
    const resourceResourceActions = performReviewState.resourceResourceActions.filter(
      (action) => action.action !== AccessReviewAction.NoAction
    );
    return {
      resourceUserActions: resourceUserActions,
      groupUserActions: groupUserActions,
      groupResourceActions: groupResourceActions,
      connectionUserActions: connectionUserActions,
      resourceResourceActions: resourceResourceActions,
    };
  };
  const filteredReviewState = filterReviewState(props.performReviewState);

  const noItemsReviewed =
    filteredReviewState.resourceUserActions.length +
      filteredReviewState.groupUserActions.length +
      filteredReviewState.groupResourceActions.length +
      filteredReviewState.connectionUserActions.length ===
    0;

  const resetReview = () => {
    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARResourceId: {},
        performReviewStateByUARGroupId: {},
        performReviewStateByUARUserId: {},
        performReviewStateByUARConnectionId: {},
      },
    });
  };

  const modalReset = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  const isCurrentReviewerReviewComplete = () => {
    let isAccessReviewItemBeingReviewed = !!props.performReviewState;
    switch (props.accessReviewItem.__typename) {
      case "AccessReviewResource": {
        let resourceUsers = props.accessReviewItem.resourceUsers || [];
        if (isAccessReviewItemBeingReviewed) {
          resourceUsers = resourceUsers.filter((user) => {
            return user.reviewerUsers?.find((reviewer) => {
              return (
                reviewer.userId === authState.user?.user.id &&
                reviewer.status === ReviewerUserStatus.NotStarted
              );
            });
          });
        }
        return (
          resourceUsers.length -
            filteredReviewState.resourceUserActions.length ===
          0
        );
      }
      case "AccessReviewGroup": {
        let groupUsers = props.accessReviewItem.groupUsers || [];
        if (isAccessReviewItemBeingReviewed) {
          groupUsers = groupUsers.filter((user) => {
            return user.reviewerUsers?.find((reviewer) => {
              return (
                reviewer.userId === authState.user?.user.id &&
                reviewer.status === ReviewerUserStatus.NotStarted
              );
            });
          });
        }

        let groupResources = props.accessReviewItem.groupResources || [];
        if (isAccessReviewItemBeingReviewed) {
          groupResources = groupResources.filter((resource) => {
            return resource.reviewerUsers?.find((reviewer) => {
              return (
                reviewer.userId === authState.user?.user.id &&
                reviewer.status === ReviewerUserStatus.NotStarted
              );
            });
          });
        }

        let noRemainingGroupUsers =
          groupUsers.length - filteredReviewState.groupUserActions.length === 0;
        let noRemainingGroupResources =
          groupResources.length -
            filteredReviewState.groupResourceActions.length ===
          0;
        return noRemainingGroupUsers && noRemainingGroupResources;
      }
      case "AccessReviewUser": {
        let groupUsers = props.accessReviewItem.groupUsers || [];
        if (isAccessReviewItemBeingReviewed) {
          groupUsers = groupUsers.filter((user) => {
            return user.reviewerUsers?.find((reviewer) => {
              return (
                reviewer.userId === authState.user?.user.id &&
                reviewer.status === ReviewerUserStatus.NotStarted
              );
            });
          });
        }

        let resourceUsers = props.accessReviewItem.resourceUsers || [];
        if (isAccessReviewItemBeingReviewed) {
          resourceUsers = resourceUsers.filter((resource) => {
            return resource.reviewerUsers?.find((reviewer) => {
              return (
                reviewer.userId === authState.user?.user.id &&
                reviewer.status === ReviewerUserStatus.NotStarted
              );
            });
          });
        }

        let noRemainingGroupUsers =
          groupUsers.length - filteredReviewState.groupUserActions.length === 0;
        let noRemainingResourceUsers =
          resourceUsers.length -
            filteredReviewState.resourceUserActions.length ===
          0;
        return noRemainingGroupUsers && noRemainingResourceUsers;
      }
      case "AccessReviewConnection": {
        let connectionUsers = props.accessReviewItem.connectionUsers || [];
        if (isAccessReviewItemBeingReviewed) {
          connectionUsers = connectionUsers.filter((user) => {
            return user.reviewerUsers?.find((reviewer) => {
              return (
                reviewer.userId === authState.user?.user.id &&
                reviewer.status === ReviewerUserStatus.NotStarted
              );
            });
          });
        }
        return (
          connectionUsers.length -
            filteredReviewState.connectionUserActions.length ===
          0
        );
      }
    }
  };
  const submitPerformReview = async (
    filteredReviewState: PerformReviewState
  ) => {
    const refetchQueries = [
      "OngoingAccessReviewStats",
      "OngoingAccessReviewTabStats",
      "OngoingAccessReviewSubtabStats",
    ];
    if (filteredReviewState.connectionUserActions.length > 0) {
      refetchQueries.push("AccessReviewConnection");
      refetchQueries.push("AccessReviewConnectionUsers");
    }
    if (
      filteredReviewState.resourceUserActions.length +
        filteredReviewState.groupResourceActions.length >
      0
    ) {
      refetchQueries.push("AccessReviewResource");
      refetchQueries.push("AccessReviewResources");
    }
    if (
      filteredReviewState.groupUserActions.length +
        filteredReviewState.groupResourceActions.length >
      0
    ) {
      refetchQueries.push("AccessReviewGroup");
      refetchQueries.push("AccessReviewGroups");
    }
    if (
      filteredReviewState.resourceUserActions.length +
        filteredReviewState.groupUserActions.length >
      0
    ) {
      refetchQueries.push("AccessReviewUser");
      refetchQueries.push("AccessReviewUsers");
    }

    try {
      const { data } = await performReview({
        variables: {
          input: {
            accessReviewId: props.accessReviewId,
            resourceUserActions: filteredReviewState.resourceUserActions,
            groupUserActions: filteredReviewState.groupUserActions,
            groupResourceActions: filteredReviewState.groupResourceActions,
            connectionUserActions: filteredReviewState.connectionUserActions,
          },
        },
        refetchQueries: refetchQueries,
      });
      switch (data?.performReview.__typename) {
        case "PerformReviewResult":
          displaySuccessToast(`Success: access reviews submitted`);
          resetReview();
          modalReset();

          if (isCurrentReviewerReviewComplete()) {
            switch (props.accessReviewItem.__typename) {
              case "AccessReviewResource":
                history.push(
                  getAccessReviewUrl(props.accessReviewId) + "#resources"
                );
                break;
              case "AccessReviewGroup":
                history.push(
                  getAccessReviewUrl(props.accessReviewId) + "#groups"
                );
                break;
              case "AccessReviewUser":
                history.push(
                  getAccessReviewUrl(props.accessReviewId) + "#users"
                );
                break;
              case "AccessReviewConnection":
                history.push(
                  getAccessReviewUrl(props.accessReviewId) + "#apps"
                );
                break;
            }
          }
          break;
        case "AccessReviewNotFoundError":
        case "AccessReviewAlreadyStoppedError":
        case "ItemCannotBeRequestedError":
          setErrorMessage(data.performReview.message);
          logError(new Error(data.performReview.message));
          break;
        case "OidcIDTokenNotFoundError":
          try {
            const state = generateState();
            const { data } = await initOidcAuthFlow({
              variables: {
                input: {
                  state: state,
                  oidcProviderType: OidcProviderType.AwsSession,
                },
              },
            });
            switch (data?.initOidcAuthFlow.__typename) {
              case "InitOidcAuthFlowResult":
                setOidcData({
                  state: state,
                  oidcProviderType: OidcProviderType.AwsSession,
                  postAuthPath: window.location.pathname,
                  postAuthHash: window.location.hash,
                  params: {
                    action: OidcPostAuthAction.PerformAccessReview,
                    performReviewState: props.performReviewState,
                  },
                });
                window.location.replace(data?.initOidcAuthFlow.authorizeUrl);
                break;
              case "OidcProviderNotFoundError":
                displayErrorToast(
                  "Error: Failed to trigger MFA. Please contact your admin."
                );
                break;
              default:
                displayErrorToast(
                  "Error: Failed to trigger MFA. Please try again or contact support if the issue persists."
                );
            }
          } catch (e) {
            logError(e, "Error: resource forfeiture failed");
            setErrorMessage(
              getModifiedErrorMessage("Error: resource forfeiture failed", e)
            );
          }
          break;
        default:
          setErrorMessage(`Error: failed to submit access reviews`);
          logError(new Error(`failed to submit access reviews`));
      }
    } catch (error) {
      logError(error, "failed to submit access reviews");
      setErrorMessage(
        getModifiedErrorMessage("Error: failed to submit access reviews", error)
      );
    }
  };

  useMountEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has("oidc_auth")) {
      const oidcAuthStatus = queryParams.get("oidc_auth");
      if (oidcAuthStatus === "success") {
        const oidcData = getOidcData();
        if (
          oidcData &&
          oidcData.params?.action === OidcPostAuthAction.PerformAccessReview
        ) {
          setShowModal(true);
          if (oidcData.params.performReviewState) {
            submitPerformReview(
              filterReviewState(oidcData.params.performReviewState)
            );
          }
          clearOidcData();
          queryParams.delete("oidc_auth");
          history.replace({
            search: queryParams.toString(),
          });
        }
      } else {
        displayErrorToast(
          "Authentication failed. Please try again or contact support if the issue remains."
        );
      }
    }
  });

  return (
    <div
      className={sprinkles({
        display: "flex",
        alignItems: "center",
        gap: "md",
      })}
    >
      <Button label="Cancel" onClick={resetReview} />
      <Button
        label="Submit Review"
        type="error"
        leftIconName="check-circle"
        onClick={() => setShowModal(true)}
        disabled={noItemsReviewed}
      />
      {showModal && (
        <ConfirmModal
          title={`Submit access review`}
          subtitle={props.entityName}
          message={
            <div className={styles.modalFieldContainer}>
              <p>You are submitting access reviews for:</p>
              <div
                className={clsx(
                  modalStyles.boxedItemsContainer,
                  modalStyles.fieldContainer
                )}
              >
                {filteredReviewState.resourceUserActions.length > 0 && (
                  <ReviewedItem
                    entityType={`user`}
                    description={
                      <>
                        Access to <i>{props.entityName}</i> for{" "}
                        {pluralize(
                          `user`,
                          filteredReviewState.resourceUserActions.length,
                          true
                        )}
                      </>
                    }
                    reviewActions={filteredReviewState.resourceUserActions}
                  />
                )}
                {filteredReviewState.groupUserActions.length > 0 && (
                  <ReviewedItem
                    entityType={`user`}
                    description={
                      <>
                        Access to <i>{props.entityName}</i> for{" "}
                        {pluralize(
                          `user`,
                          filteredReviewState.groupUserActions.length,
                          true
                        )}
                      </>
                    }
                    reviewActions={filteredReviewState.groupUserActions}
                  />
                )}
                {filteredReviewState.groupResourceActions.length > 0 && (
                  <ReviewedItem
                    entityType={`resource`}
                    description={
                      <>
                        Access to
                        {` `}
                        {pluralize(
                          `resource`,
                          filteredReviewState.groupResourceActions.length,
                          true
                        )}
                        {` `}
                        for <i>{props.entityName}</i>
                      </>
                    }
                    reviewActions={filteredReviewState.groupResourceActions}
                  />
                )}
                {filteredReviewState.connectionUserActions.length > 0 && (
                  <ReviewedItem
                    entityType={`user`}
                    description={
                      <>
                        <p>
                          Access to <i>{props.entityName}</i> for{" "}
                          {pluralize(
                            `user`,
                            filteredReviewState.connectionUserActions.length,
                            true
                          )}
                        </p>
                        {filteredReviewState.connectionUserActions.some(
                          (action) =>
                            action.action === AccessReviewAction.Revoke
                        ) && (
                          <p
                            className={sprinkles({
                              fontWeight: "semibold",
                              color: "red600",
                            })}
                          >
                            Revoked users will be deprovisioned
                          </p>
                        )}
                      </>
                    }
                    reviewActions={filteredReviewState.connectionUserActions}
                  />
                )}
              </div>
              <p>
                Please confirm that you are ready to finalize these reviews. Are
                you sure you want to proceed?
              </p>
            </div>
          }
          isModalOpen={showModal}
          onClose={modalReset}
          onSubmit={async () => {
            submitPerformReview(filteredReviewState);
          }}
          loading={loading}
          errorMessage={errorMessage}
          submitLabel={"Submit"}
          submitDisabled={noItemsReviewed}
        />
      )}
    </div>
  );
};

type WrappedReviewAction = {
  action: AccessReviewAction;
};

type ReviewedItemProps = {
  entityType: string;
  description: ReactElement;
  reviewActions: WrappedReviewAction[];
};

export const ReviewedItem = (props: ReviewedItemProps) => {
  const numReviewed = props.reviewActions.length;
  let numAccepted = 0,
    numRevoked = 0,
    numUpdated = 0;
  for (let action of props.reviewActions) {
    if (action.action === AccessReviewAction.Accept) {
      numAccepted++;
    } else if (action.action === AccessReviewAction.Revoke) {
      numRevoked++;
    } else if (action.action === AccessReviewAction.Update) {
      numUpdated++;
    }
  }

  return (
    <div className={clsx(modalStyles.boxedItem, styles.reviewedItem)}>
      <div>
        <b>{pluralize(props.entityType, numReviewed, true)}</b>
        {` (`}
        <span className={styles.acceptedDesc}>{numAccepted} accepted</span>
        {` | `}
        <span className={styles.updatedDesc}>{numUpdated} updated</span>
        {` | `}
        <span className={styles.revokedDesc}>{numRevoked} revoked</span>)
      </div>
      <div className={modalStyles.small}>{props.description}</div>
    </div>
  );
};
