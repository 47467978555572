import { refetchQueries } from "api/ApiContext";
import {
  EventStreamFragment,
  PubsubPublishMessageStatusCode,
  useListEventStreamMessagesQuery,
  useRequeueEventStreamMessagesMutation,
} from "api/generated/graphql";
import { useToast } from "components/toast/Toast";
import { ButtonV3, CodeEditor, Modal, Select } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";

import * as styles from "./EventStreamConnections.css";

type EventStreamMessagesTableProps = {
  eventStreams: EventStreamFragment[];
};

type EventStreamMessageRow = {
  messageId: string;
  name: string;
  date: string;
  error?: string;
  message: {
    id: string;
    statusCode: PubsubPublishMessageStatusCode;
    payload: string;
    error?: string | null;
    createdAt: string;
    pubsubPublishConnection: {
      id: string;
      name: string;
    };
  };
};

export const EventStreamMessagesTable = ({
  eventStreams,
}: EventStreamMessagesTableProps) => {
  const [selectedEventStream, setSelectedEventStream] = useState<
    EventStreamFragment | undefined
  >(eventStreams.length > 0 ? eventStreams[0] : undefined);
  const [selectedMessageIDs, setSelectedMessageIDs] = useState<string[]>([]);
  const { displaySuccessToast, displayErrorToast } = useToast();

  const [requeueMessages] = useRequeueEventStreamMessagesMutation();

  const { data, previousData, fetchMore } = useListEventStreamMessagesQuery({
    skip: !selectedEventStream,
    variables: {
      input: {
        eventStreamId: selectedEventStream?.id ?? "",
        statusCode: PubsubPublishMessageStatusCode.Canceled,
        cursor: null,
      },
    },
  });

  const messages =
    data?.listEventStreamMessages?.messages ??
    previousData?.listEventStreamMessages.messages ??
    [];
  const cursor = data?.listEventStreamMessages?.cursor ?? null;
  const totalNumMessages = data?.listEventStreamMessages?.totalNumMessages ?? 0;
  const tableHeaderLabel =
    totalNumMessages > 0
      ? `${totalNumMessages} failed deliveries in the last 30 days`
      : "No failed deliveries in the last 30 days";

  const tableColumns: Header<EventStreamMessageRow>[] = [
    {
      id: "messageId",
      label: "Message ID",
      sortable: false,
      width: 200,
    },
    {
      id: "name",
      label: "Event Streaming Connection",
      sortable: false,
    },
    {
      id: "date",
      label: "Date",
      sortable: false,
    },
    {
      id: "error",
      label: "",
      sortable: false,
      customCellRenderer: (row) => {
        return (
          <div
            className={sprinkles({
              display: "flex",
              gap: "mdlg",
              alignItems: "center",
              justifyContent: "flex-end",
            })}
          >
            <EventSteamMessageErrorModal message={row.message} />
            <ButtonV3
              type="defaultSecondary"
              size="sm"
              rightIconName="refresh"
              onClick={() => {
                handleRequeueMessages([row.message.id]);
              }}
            />
          </div>
        );
      },
    },
  ];

  const rows: Array<EventStreamMessageRow> = messages.map((message) => {
    return {
      messageId: message.id,
      name: message.pubsubPublishConnection?.name ?? "",
      date: message.createdAt,
      error: message.error ?? undefined,
      message: message,
    };
  });

  const handleRequeueMessages = async (messageIDs: string[]) => {
    const { data } = await requeueMessages({
      variables: {
        input: {
          connectionId: selectedEventStream?.connection.id ?? "",
          messageIds: messageIDs,
        },
      },
    });
    switch (data?.requeueEventStreamMessages.__typename) {
      case "RequeueEventStreamMessagesResult":
        await refetchQueries({ include: ["ListEventStreamMessages"] });
        displaySuccessToast("Connection updated");
        setSelectedMessageIDs([]);
        break;
      case "RequeueEventStreamMessagesError":
        displayErrorToast(data.requeueEventStreamMessages.message);
        break;
    }
  };

  const handleOnLoadMoreRows = cursor
    ? async () => {
        if (cursor) {
          await fetchMore({
            variables: {
              input: {
                eventStreamId: selectedEventStream!.id,
                statusCode: PubsubPublishMessageStatusCode.Canceled,
                cursor: cursor,
              },
            },
          });
        }
      }
    : undefined;

  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        height: "100%",
        paddingTop: "md",
      })}
    >
      <div
        className={sprinkles({
          marginBottom: "md",
        })}
      >
        <TableHeader
          totalNumRows={messages.length}
          selectedNumRows={selectedMessageIDs.length}
          entityName="Event"
          bulkRightActions={[
            {
              label: "Retry",
              iconName: "refresh",
              type: "mainSecondary",
              onClick: () => {
                handleRequeueMessages(selectedMessageIDs);
              },
            },
          ]}
          customLabel={tableHeaderLabel}
        ></TableHeader>
      </div>
      <div className={styles.select}>
        <Select<EventStreamFragment>
          getOptionLabel={(option) => option.connection.name}
          onChange={(option: EventStreamFragment | undefined) => {
            if (option) {
              setSelectedEventStream(option);
            }
          }}
          onSelectValue={(option: EventStreamFragment | undefined) => {
            setSelectedEventStream(
              eventStreams.find((eventStream) => eventStream.id === option?.id)
            );
          }}
          value={selectedEventStream}
          placeholder="Select an Event Stream Connection"
          options={eventStreams}
        />
      </div>
      <div style={{ maxHeight: "70vh" }}>
        <Table
          rows={rows}
          getRowId={(ru) => ru.messageId}
          columns={tableColumns}
          totalNumRows={totalNumMessages}
          onLoadMoreRows={handleOnLoadMoreRows}
          checkedRowIds={new Set(selectedMessageIDs)}
          onCheckedRowsChange={(checkedRowIDs, checked) => {
            if (checked) {
              setSelectedMessageIDs([...selectedMessageIDs, ...checkedRowIDs]);
            } else {
              setSelectedMessageIDs(
                selectedMessageIDs.filter((id) => !checkedRowIDs.includes(id))
              );
            }
          }}
        ></Table>
      </div>
    </div>
  );
};

const EventSteamMessageErrorModal = (props: {
  message: {
    id: string;
    statusCode: PubsubPublishMessageStatusCode;
    payload: string;
    error?: string | null;
    createdAt: string;
    pubsubPublishConnection: {
      name: string;
    };
  };
}) => {
  const [showModal, setShowModal] = useState(false);

  const formattedPayload = JSON.stringify(
    JSON.parse(props.message.payload),
    null,
    2
  );
  return (
    <>
      <div onClick={() => setShowModal(true)} className={styles.label}>
        View Details
      </div>
      <Modal
        title={"Failed Delivery"}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        maxWidth="lg"
      >
        <Modal.Body>
          <table>
            <tr>
              <th className={sprinkles({ paddingRight: "lg" })}>Date</th>
              <th className={sprinkles({ paddingRight: "lg" })}>Name</th>
              <th>Message ID</th>
            </tr>
            <tr>
              <td
                className={sprinkles({
                  paddingRight: "lg",
                  paddingBottom: "xl",
                })}
              >
                {props.message.createdAt}
              </td>
              <td
                className={sprinkles({
                  paddingRight: "lg",
                  paddingBottom: "xl",
                })}
              >
                {props.message.pubsubPublishConnection.name}
              </td>
              <td className={sprinkles({ paddingBottom: "xl" })}>
                {props.message.id}
              </td>
            </tr>
            <tr>
              <th>Error</th>
            </tr>
            <tr>
              <td
                className={sprinkles({
                  color: "red700V3",
                  paddingBottom: "xl",
                })}
              >
                {props.message.error ?? "Message failed to deliver"}
              </td>
            </tr>
            <tr>
              <th>JSON Payload</th>
            </tr>
          </table>
          <div
            className={sprinkles({
              marginBottom: "md",
            })}
          >
            <CodeEditor
              code={"\n" + formattedPayload}
              height={"350px"}
              dark
              language="json"
            />
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};
