import { getApolloStatusCode } from "api/ApiContext";
import { Button } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React from "react";
import { logError } from "utils/logging";
import errorCodePageStyles from "views/error/ErrorCodePage.module.scss";

type ErrorCodePageProps = {
  errorCode: number;
  subtitle: string;
  showAccountSwitchButton: boolean;
};

export const ErrorCodePage = (props: ErrorCodePageProps) => {
  return (
    <div className={errorCodePageStyles.content}>
      <div className={errorCodePageStyles.welcomeBox}>
        <div className={errorCodePageStyles.titleText}>{props.errorCode}</div>
        <div className={errorCodePageStyles.subtitleText}>{props.subtitle}</div>
        {props.showAccountSwitchButton && (
          <div className={sprinkles({ marginBottom: "md" })}>
            <Button
              leftIconName="log-in"
              label="Log Out to Switch Accounts"
              onClick={() => {
                window.location.href = "/sign-out";
              }}
              outline
            />
          </div>
        )}
        <Button
          leftIconName="arrow-left"
          label="Back to home"
          onClick={() => {
            window.location.href = "/";
          }}
          outline
        />
      </div>
    </div>
  );
};

export const NotFoundPage = ({ entity }: { entity?: string }) => {
  return (
    <ErrorCodePage
      errorCode={404}
      subtitle={(entity || "Page") + " not found"}
      showAccountSwitchButton={true}
    />
  );
};

export const ForbiddenPage = () => {
  return (
    <ErrorCodePage
      errorCode={403}
      subtitle="Forbidden"
      showAccountSwitchButton
    />
  );
};

export const UnexpectedErrorPage = ({ error }: { error?: Error }) => {
  let codeToUse = 500;
  let messageToUse = "Something went wrong";
  if (error) {
    const code = getApolloStatusCode(error);
    if (code) {
      switch (code) {
        case 401:
          codeToUse = 401;
          messageToUse = "Invalid authentication error";
          break;
        case 403:
          codeToUse = 403;
          messageToUse = "Invalid authorization error";
          break;
        case 422:
          codeToUse = 422;
          messageToUse = "Invalid argument error";
          break;
      }
    }
  }
  logError(error, "user encountered unexpected error page", {
    errorCode: codeToUse,
  });
  return (
    <ErrorCodePage
      errorCode={codeToUse}
      subtitle={messageToUse}
      showAccountSwitchButton={false}
    />
  );
};

export default ErrorCodePage;
