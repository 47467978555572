import { ColumnContainer } from "components/column/Column";
import React from "react";

import SettingsColumn from "./SettingsColumn";
import SettingsContent from "./SettingsContent";
import SettingsV3 from "./SettingsV3";

export const SettingsComponent = () => {
  return (
    <ColumnContainer>
      <ColumnContainer>
        <SettingsColumn />
        <SettingsContent />
      </ColumnContainer>
    </ColumnContainer>
  );
};

export const SettingsV3Component = () => {
  return (
    <ColumnContainer>
      <SettingsV3 />
    </ColumnContainer>
  );
};

enum AdminCategory {
  IdpAndHr,
  Integrations,
  Authentication,
  AccessRequests,
  AWSSettings,
  Advanced,
  Api,
  Webhooks,
  DangerZone,
}

export type AdminCategoryInfo = {
  category: AdminCategory;
  sideBarName: string;
  titleName: string;
  anchor: string;
};

const categoryInfos: AdminCategoryInfo[] = [
  {
    category: AdminCategory.IdpAndHr,
    sideBarName: "IDP & HR Integrations",
    titleName: "IDP & HR Integrations",
    anchor: "idp-and-hr",
  },
  {
    category: AdminCategory.Integrations,
    sideBarName: "Productivity Integrations",
    titleName: "Productivity Integrations",
    anchor: "productivity-integrations",
  },
  {
    category: AdminCategory.Authentication,
    sideBarName: "Authentication",
    titleName: "Authentication",
    anchor: "authentication",
  },
  {
    category: AdminCategory.AccessRequests,
    sideBarName: "Access Requests",
    titleName: "Access Requests",
    anchor: "access-requests",
  },
  {
    category: AdminCategory.AWSSettings,
    sideBarName: "AWS Settings",
    titleName: "AWS Settings",
    anchor: "aws-settings",
  },
  {
    category: AdminCategory.Advanced,
    sideBarName: "Advanced",
    titleName: "Advanced",
    anchor: "advanced",
  },
  {
    category: AdminCategory.Api,
    sideBarName: "API Access Tokens",
    titleName: "API Access Tokens",
    anchor: "api",
  },
  {
    category: AdminCategory.Webhooks,
    sideBarName: "Webhooks",
    titleName: "Webhooks",
    anchor: "webhooks",
  },
  {
    category: AdminCategory.DangerZone,
    sideBarName: "Danger Zone",
    titleName: "Danger Zone",
    anchor: "danger-zone",
  },
];

const categoryInfoByCategory: Record<number, AdminCategoryInfo> = {};
categoryInfos.forEach((categoryInfo) => {
  categoryInfoByCategory[categoryInfo.category] = categoryInfo;
});

const categoryByAnchor: Record<string, AdminCategory> = {};
categoryInfos.forEach((categoryInfo) => {
  categoryByAnchor["#" + categoryInfo.anchor] = categoryInfo.category;
});
