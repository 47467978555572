import { AccessReviewUserFragment } from "api/generated/graphql";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import { EntityViewerTabType } from "components/entity_viewer/EntityViewerTabs";
import { OnlyMyReviewsFilter } from "components/only_mine_filter/OnlyMineFilter";
import React from "react";
import styles from "views/access_reviews/common/AccessReviewTableStyles.module.scss";
import AccessReviewUserResourcesTable from "views/access_reviews/users/AccessReviewUserResourcesTable";

type AccessReviewUserResourcesRowProps = {
  accessReviewUser: AccessReviewUserFragment;
  setSelectedTab: (tab: EntityViewerTabType) => void;
};

export const AccessReviewUserResourcesRow = (
  props: AccessReviewUserResourcesRowProps
) => {
  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableTopContainer}>
        <div className={styles.tableTopLeftContent}>
          <OnlyMyReviewsFilter />
        </div>
      </div>
      <EntityViewerRow
        title={"Resources with direct access"}
        content={
          <AccessReviewUserResourcesTable
            accessReviewUser={props.accessReviewUser}
            setSelectedTab={props.setSelectedTab}
          />
        }
        isTable={true}
      />
    </div>
  );
};

export default AccessReviewUserResourcesRow;
