import {
  GroupBindingDropdownPreviewFragment,
  usePaginatedGroupBindingDropdownQuery,
} from "api/generated/graphql";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { Select } from "components/ui";
import _ from "lodash";
import { useEffect, useState } from "react";
import { logError } from "utils/logging";
import { formatGroupName } from "views/group_bindings/common";

// Limits number of options rendered for performance.
const MAX_OPTIONS_TO_DISPLAY = 50;

type PaginatedGroupBindingDropdownProps = {
  value?: GroupBindingDropdownPreviewFragment;
  onChange: (group?: GroupBindingDropdownPreviewFragment) => void;
  disabled?: boolean;
  clearable?: boolean;
  /** Focus the input component on mount. */
  autoFocus?: boolean;
  placeholder?: string;
  selectOnly?: boolean;
};

/**
 * A searchable dropdown containing all resources in the organization matching
 * the given filters. This dropdown statically limits the number of rendered
 * options to preserve performance -- the user is expected to leverage search
 * instead of scrolling to select the desired resource.
 */
const PaginatedGroupBindingDropdown = ({
  value,
  onChange,
  disabled,
  clearable = true,
  autoFocus,
  placeholder,
  selectOnly,
}: PaginatedGroupBindingDropdownProps) => {
  const [searchQuery, setSearchQuery] = useState("");

  const {
    data: groupBindingsData,
    error: groupBindingsError,
    refetch,
  } = usePaginatedGroupBindingDropdownQuery({
    variables: {
      input: {
        filters: {
          searchQuery,
        },
        pageSize: MAX_OPTIONS_TO_DISPLAY,
      },
    },
    fetchPolicy: "no-cache",
  });
  if (groupBindingsError) {
    logError(groupBindingsError, "failed to list group bindings");
  }

  useEffect(() => {
    refetch({
      input: {
        filters: {
          searchQuery,
        },
        pageSize: MAX_OPTIONS_TO_DISPLAY,
      },
    });
  }, [refetch, searchQuery]);

  const handleSearchInputChange = _.debounce((s: string) => {
    setSearchQuery(s);
  }, 250);

  const handleInputChange = (input: string) => {
    handleSearchInputChange(input);
  };

  let groupBindings: GroupBindingDropdownPreviewFragment[] = [];
  switch (groupBindingsData?.groupBindings.__typename) {
    case "GroupBindingsOutput":
      groupBindings = groupBindingsData.groupBindings.groupBindings;
      break;
    default:
      break;
  }

  const handleSelectValue = (value?: GroupBindingDropdownPreviewFragment) => {
    onChange(value);
    if (selectOnly) handleSearchInputChange("");
  };

  return (
    <Select
      options={groupBindings}
      value={value}
      onChange={handleSelectValue}
      onInputChange={handleInputChange}
      clearable={clearable}
      disabled={disabled}
      getOptionLabel={(option) =>
        formatGroupName(option.sourceGroup ?? undefined)
      }
      getOptionSublabel={(option) =>
        "Other groups: " +
        option.groups
          .filter((g) => g.id !== option.sourceGroupId)
          .map((g) => g.name)
          .join(", ")
      }
      getIcon={(option) => ({
        type: "src",
        icon: getGroupTypeInfo(option.sourceGroup?.groupType)?.icon,
      })}
      getOptionSelected={(option, value) => option.id === value.id}
      disableBuiltInFiltering // Search is already handled server-side
      autoFocus={autoFocus}
      placeholder={placeholder}
      selectOnly={selectOnly}
      popperForceDownward
      popperHeight="sm"
    />
  );
};

export default PaginatedGroupBindingDropdown;
