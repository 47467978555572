import { Popper } from "@material-ui/core";
import { ResourceType, useUserMenuQuery } from "api/generated/graphql";
import { SelfRefreshingTimestamp } from "components/time/time";
import { Badge, Icon } from "components/ui";
import Avatar from "components/ui/avatar/Avatar";
import moment from "moment";
import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useHistory } from "react-router-dom";
import { ITEM_TYPE_URL_KEY } from "views/apps/AppsContext";

import * as styles from "./UserMenu.css";

interface Notification {
  id: string;
  createdAt: string;
  body: string;
  url: string;
  readAt?: string;
  trackingId?: string;
}

interface UserMenuComponentProps {
  avatarUrl: string;
  name: string;
  orgName: string;
  version: string;
  notifications: Notification[];
  collapsed?: boolean;
}

const UserMenuComponent = (props: UserMenuComponentProps) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [showNotifications, setShowNotifications] = React.useState(false);
  const history = useHistory();
  const buttonRef = React.useRef<HTMLDivElement>(null);

  const { data } = useUserMenuQuery();
  const opalConnectionId =
    data?.connections.connections.length === 1
      ? data?.connections.connections[0].id
      : null;

  const numUnread = props.notifications.filter((n) => !n.readAt).length;

  if (props.collapsed && !isOpen) {
    return (
      <div
        className={styles.collapsedContainer}
        onClick={() => setIsOpen(!isOpen)}
        ref={buttonRef}
      >
        <button className={styles.collapsedInnerContainer}>
          <div className={styles.avatarContainer}>
            <Avatar url={props.avatarUrl} size="normal" />
            {numUnread > 0 ? (
              <div className={styles.avatarDot}>
                <div className={styles.unreadDot} />
              </div>
            ) : null}
          </div>
        </button>
        <div className={styles.collapsedUserPopover}>
          <div className={styles.userTitle}>{props.name}</div>
          <div className={styles.userSubtitle}>{props.orgName}</div>
        </div>
      </div>
    );
  }

  // Add a 250ms delay to the notifications popover so there is some buffer
  // for the user to hover on it.
  let hideNotificationsTimeout: NodeJS.Timeout;
  const handleHideNotifications = () => {
    hideNotificationsTimeout = setTimeout(() => {
      setShowNotifications(false);
    }, 250);
  };
  const handleShowNotifications = () => {
    clearTimeout(hideNotificationsTimeout);
    setShowNotifications(true);
  };

  const renderNotifications = () => {
    if (!showNotifications) return null;

    if (props.notifications.length === 0) {
      return (
        <div
          className={styles.notificationsContainer}
          onMouseEnter={handleShowNotifications}
          onMouseLeave={handleHideNotifications}
        >
          <div className={styles.noNotificationsContainer}>
            <Icon name="bell" size="xs" />
            <div className={styles.noNotificationsTitle}>No Notifications</div>
            <div className={styles.noNotificationsSubtitle}>
              Good time to get a coffee.
            </div>
          </div>
        </div>
      );
    }

    return (
      <div
        className={styles.notificationsContainer}
        onMouseEnter={handleShowNotifications}
        onMouseLeave={handleHideNotifications}
      >
        {props.notifications.map((n) => (
          <button
            className={styles.notification}
            onClick={() => history.push(n.url)}
          >
            <div>{n.body}</div>
            <div className={styles.timestamp}>
              {!n.readAt ? <div className={styles.unreadDot} /> : null}
              <SelfRefreshingTimestamp timestamp={moment(n.createdAt)} />
            </div>
          </button>
        ))}
      </div>
    );
  };

  const renderMenuItems = () => {
    if (!isOpen) return null;
    return (
      <Popper
        placement="bottom"
        open
        anchorEl={buttonRef.current}
        className={styles.menuItems}
      >
        <OutsideClickHandler
          onOutsideClick={(e) => {
            if (
              e.target instanceof Element &&
              buttonRef.current?.contains(e.target) === true
            ) {
              // Do not handle this if it is a click on the main user menu button
              return;
            }
            setIsOpen(false);
          }}
        >
          <div>
            <button
              className={styles.menuItem}
              onClick={() => setShowNotifications(!showNotifications)}
              onMouseEnter={handleShowNotifications}
              onMouseLeave={handleHideNotifications}
            >
              <div className={styles.leftIcon}>
                <Icon name="bell" size="xs" />
              </div>
              <div className={styles.menuItemTitle}>Notifications</div>
              {numUnread > 0 ? (
                <Badge count={numUnread} color="info" size="xs" maxCount={99} />
              ) : null}
              <Icon name="chevron-right" color="gray400" size="xs" />
            </button>
            {renderNotifications()}
          </div>
          <button
            className={styles.menuItem}
            onClick={() =>
              history.push(
                `/apps/${opalConnectionId}?${ITEM_TYPE_URL_KEY}=${ResourceType.OpalRole}`
              )
            }
          >
            <div className={styles.leftIcon}>
              <Icon name="role" size="xs" />
            </div>
            <div className={styles.menuItemTitle}>My Opal Roles</div>
          </button>
          <button
            className={styles.menuItem}
            onClick={() => history.push("/user/settings")}
          >
            <div className={styles.leftIcon}>
              <Icon name="settings" size="xs" />
            </div>
            <div className={styles.menuItemTitle}>Account settings</div>
          </button>
          <button
            className={styles.menuItem}
            onClick={() => {
              history.push("/sign-out");
            }}
          >
            <div className={styles.leftIcon}>
              <Icon name="log-out" size="xs" />
            </div>
            <div className={styles.menuItemTitle}>Sign out</div>
          </button>
          <div className={styles.version}>
            <a href="https://docs.opal.dev/changelog" target="_blank">
              {props.version}
            </a>
          </div>
        </OutsideClickHandler>
      </Popper>
    );
  };

  return (
    <div className={styles.container({ isOpen })} ref={buttonRef}>
      <button
        className={styles.userMenuItem}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className={styles.avatarContainer}>
          <Avatar url={props.avatarUrl} size="normal" />
          {numUnread > 0 ? (
            <div className={styles.avatarDot}>
              <div className={styles.unreadDot} />
            </div>
          ) : null}
        </div>
        <div>
          <div className={styles.userTitle}>{props.name}</div>
          <div className={styles.userSubtitle}>{props.orgName}</div>
        </div>
        <Icon
          name={isOpen ? "chevron-up" : "chevron-down"}
          color="gray400"
          size="xs"
        />
      </button>
      {renderMenuItems()}
    </div>
  );
};

export default UserMenuComponent;
