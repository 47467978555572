import {
  GroupAccessLevel,
  GroupDropdownPreviewFragment,
  useGroupPreviewWithAccessLevelsQuery,
} from "api/generated/graphql";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { EntityIcon, Icon, Select } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { groupTypeHasRoles } from "utils/directory/groups";
import { ExpirationValue } from "views/requests/utils";

import * as styles from "./GroupCard.css";

interface Props {
  group: GroupDropdownPreviewFragment;
  existingRoles: GroupAccessLevel[];
  selectedRoles: GroupAccessLevel[];
  onRemove: () => void;
  onUpdateSelectedRoles: (roles: GroupAccessLevel[]) => void;
  onUpdateAllRoles?: (roles: GroupAccessLevel[]) => void;
  onUpdateAccessDuration?: (duration: ExpirationValue) => void;
  accessDurationByGroupIdToAdd?: Record<string, ExpirationValue>;
}

const GroupCard = (props: Props) => {
  const { group } = props;

  const hasRoles = groupTypeHasRoles(group.groupType);
  const { data, loading, error } = useGroupPreviewWithAccessLevelsQuery({
    variables: {
      input: {
        id: group.id,
      },
    },
    skip: !hasRoles,
  });

  let allRoles: GroupAccessLevel[] = [];
  if (data?.group.__typename === "GroupResult") {
    allRoles =
      data.group.group.accessLevels?.filter(
        (role) => role.accessLevelRemoteId !== ""
      ) ?? [];
    props.onUpdateAllRoles && props.onUpdateAllRoles(allRoles);
  }

  if (!group.connection) {
    return null;
  }

  const roleOptions = allRoles.filter((role) => {
    return ![...props.existingRoles, ...props.selectedRoles].some(
      (existingRole) =>
        existingRole.accessLevelRemoteId === role.accessLevelRemoteId
    );
  });

  const alreadyHasAllRoles =
    allRoles.length > 0 &&
    roleOptions.length === 0 &&
    props.selectedRoles.length === 0;

  return (
    <div key={group.id} className={styles.resourceCard}>
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "flex-start",
          gap: "sm",
        })}
      >
        <div
          className={sprinkles({
            flexShrink: 0,
          })}
        >
          <EntityIcon
            type={group.connection.connectionType}
            iconStyle="rounded"
          />
        </div>
        <div className={styles.resourceInfoSection}>
          <div className={styles.resourceCardHeader}>{group.name}</div>
          <div className={styles.resourceCardSubtitle}>
            {group.connection.name}
          </div>
          <div className={styles.resourceCardType}>
            <EntityIcon type={group.groupType} includeBrand={false} />
            {groupTypeInfoByType[group.groupType].name}
          </div>
        </div>
        <div className={sprinkles({ flexShrink: 0 })}>
          <Icon name="trash" color="red600V3" onClick={props.onRemove} />
        </div>
      </div>
      {props.accessDurationByGroupIdToAdd && (
        <div className={sprinkles({ marginTop: "md" })}>
          <Select
            key={group.id}
            options={Object.values(ExpirationValue)}
            placeholder="Select access duration"
            value={props.accessDurationByGroupIdToAdd[group.id]}
            onChange={(val) => {
              if (val) {
                props.onUpdateAccessDuration &&
                  props.onUpdateAccessDuration(val);
              }
            }}
            disableBuiltInFiltering
            getOptionLabel={(expirationVal) =>
              expirationVal === ExpirationValue.Indefinite
                ? "Indefinite access"
                : `Access for ${expirationVal}`
            }
          />
        </div>
      )}
      {alreadyHasAllRoles ? (
        <div
          className={sprinkles({
            paddingTop: "md",
            paddingLeft: "md",
            paddingRight: "md",
          })}
        >
          All roles on this group have already been granted, remove this from
          selection.
        </div>
      ) : (
        (allRoles.length > 0 || loading) && (
          <div className={sprinkles({ marginTop: "md" })}>
            {error && <ModalErrorMessage errorMessage={error.message} />}
            <Select
              options={roleOptions}
              loading={loading}
              placeholder="Select role"
              getOptionLabel={(role) => role.accessLevelName}
              onChange={(role) => {
                if (role) {
                  props.onUpdateSelectedRoles([role]);
                }
              }}
              selectOnly
            />
            {props.selectedRoles.map((role) => {
              return (
                <div
                  key={role.accessLevelRemoteId}
                  className={sprinkles({
                    paddingX: "sm",
                    marginTop: "sm",
                    fontSize: "textSm",
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                  })}
                >
                  {role.accessLevelName}
                  <Icon
                    name="x"
                    size="xs"
                    onClick={() => {
                      props.onUpdateSelectedRoles(
                        props.selectedRoles.filter(
                          (r) =>
                            r.accessLevelRemoteId !== role.accessLevelRemoteId
                        )
                      );
                    }}
                  />
                </div>
              );
            })}
          </div>
        )
      )}
    </div>
  );
};

export default GroupCard;
