import {
  BundleDetailFragment,
  useBundleItemsLazyQuery,
  useBundleQuery,
  useUpdateBundleMutation,
} from "api/generated/graphql";
import { Column, ColumnContainer } from "components/column/Column";
import BundlesConfigForm from "components/forms/BundlesConfigForm";
import { BundleConfig, FormMode } from "components/forms/common";
import { makeBundleConfig } from "components/forms/utils";
import { useToast } from "components/toast/Toast";
import { Banner, Button } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { AuthorizedActionManage } from "utils/auth/auth";
import { logError } from "utils/logging";
import { AppsContext } from "views/apps/AppsContext";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import BundleContentHeader from "./BundleContentHeader";
import { SORT_OPTIONS } from "./BundleDetailColumn";

const BundleOverviewColumn = () => {
  const { bundleId } = useParams<Record<string, string>>();
  const { selectBundleItems } = useContext(AppsContext);

  const [mode, setMode] = useState<FormMode>("view");
  const [initialConfig, setInitialConfig] = useState<Partial<BundleConfig>>();
  const [config, setConfig] = useState<Partial<BundleConfig>>();
  const [updateError, setUpdateError] = useState("");

  const { displaySuccessToast } = useToast();

  const { data, error, loading } = useBundleQuery({
    variables: {
      input: {
        id: bundleId,
      },
    },
    skip: !bundleId,
  });

  const [getBundleItems] = useBundleItemsLazyQuery();

  let bundle: BundleDetailFragment | undefined;
  switch (data?.bundle.__typename) {
    case "BundleResult":
      bundle = data.bundle.bundle;
      break;
  }

  useEffect(() => {
    if (!bundle) return;
    const bundleConfig: BundleConfig = makeBundleConfig(bundle);
    setConfig(bundleConfig);
    setInitialConfig(bundleConfig);
  }, [bundle]);

  const [updateBundle, { loading: updateLoading }] = useUpdateBundleMutation();

  if (loading) {
    return (
      <Column isContent maxWidth="lg">
        <ColumnContentSkeleton />
      </Column>
    );
  }

  if (!bundle || !config || error) {
    return null;
  }

  const handleSave = async () => {
    if (!bundle) {
      return;
    }
    try {
      const { data } = await updateBundle({
        variables: {
          input: {
            id: bundle.id,
            description: config.description,
            adminOwnerId: config.adminOwner?.id ?? undefined,
            visibility: config.visibility ?? undefined,
            visibilityGroupsIds: config.visibilityGroupsIds ?? undefined,
          },
        },
        refetchQueries: ["Bundle"],
      });

      switch (data?.updateBundle.__typename) {
        case "UpdateBundleResult":
          displaySuccessToast("Success: bundle updated");
          setMode("view");
      }
    } catch (err) {
      logError(err, "Failed to update bundle");
      setUpdateError("Failed to update bundle");
    }
  };

  const selectAll = async () => {
    const { data, fetchMore } = await getBundleItems({
      variables: {
        input: {
          bundleId,
          sortBy: SORT_OPTIONS[0].value,
          maxPageSize: 50,
        },
      },
    });
    let thisCursor = data?.bundleItems.cursor;
    selectBundleItems(data?.bundleItems.items ?? []);

    while (thisCursor) {
      const { data, error } = await fetchMore({
        variables: {
          input: {
            bundleId,
            sortBy: SORT_OPTIONS[0].value,
            maxPageSize: 50,
            cursor: thisCursor,
          },
        },
      });
      if (error) {
        logError(error, "failed to select all items.");
        break;
      }

      selectBundleItems(data.bundleItems.items ?? []);
      thisCursor = data?.bundleItems.cursor;
    }
  };

  const canManage =
    bundle.authorizedActions?.includes(AuthorizedActionManage) ?? false;

  const headerButtons =
    mode === "view" ? (
      <div className={sprinkles({ display: "flex", gap: "sm" })}>
        {canManage && (
          <Button
            label="Edit"
            leftIconName="edit"
            borderless
            onClick={() => setMode("edit")}
            size="md"
          />
        )}
        {bundle.totalNumItems > 0 && (
          <Button
            label="Request"
            leftIconName="lock"
            onClick={selectAll}
            type="primary"
          />
        )}
      </div>
    ) : (
      <div className={sprinkles({ display: "flex", gap: "sm" })}>
        <Button
          label="Cancel"
          onClick={() => {
            setMode("view");
            setConfig(initialConfig);
            setUpdateError("");
          }}
          disabled={updateLoading}
          borderless
          size="md"
        />
        <Button
          label={updateLoading ? "Saving..." : "Save"}
          leftIconName="check"
          type="primary"
          onClick={handleSave}
          disabled={updateLoading || _.isEqual(config, initialConfig)}
          size="md"
        />
      </div>
    );

  return (
    <ColumnContainer>
      <Column isContent maxWidth="lg">
        <BundleContentHeader
          actionButtons={headerButtons}
          canManage={canManage}
        />
        {updateError && <Banner type="error" message={updateError} />}
        <BundlesConfigForm mode={mode} config={config} onChange={setConfig} />
      </Column>
    </ColumnContainer>
  );
};

export default BundleOverviewColumn;
