import {
  AccessReviewTemplateFragment,
  FiltersMatchMode,
  useAccessReviewTemplateQuery,
} from "api/generated/graphql";
import { Months } from "components/access_reviews/AccessReviewMonthPicker";
import AssignmentPolicy from "components/access_reviews/AssignmentPolicy";
import ReminderNotifications, {
  DEFAULT_REMINDER_TIME_OF_DAY,
} from "components/access_reviews/ReminderNotifications";
import StartAccessReviewStatsV3 from "components/access_reviews/scope/AccessReviewStatsV3";
import ConnectionsFilter from "components/access_reviews/scope/ConnectionsFilter";
import EntitiesIndividualFilter from "components/access_reviews/scope/EntitiesIndividualFilter";
import EntitiesNamedFilter from "components/access_reviews/scope/EntitiesNamedFilter";
import EntitiesWithAdminFilter from "components/access_reviews/scope/EntitiesWithAdminFilter";
import EntityTypesFilter from "components/access_reviews/scope/EntityTypesFilter";
import TagFilter from "components/access_reviews/scope/TagFilter";
import UsersFilter from "components/access_reviews/scope/UsersFilter";
import AuthContext from "components/auth/AuthContext";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeaderV3 from "components/column/ColumnHeaderV3";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import { PillV3 } from "components/pills/PillsV3";
import { ButtonV3, Divider, FormGroup } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import React, { useContext } from "react";
import { useHistory, useParams } from "react-router";
import { getNumberWithOrdinal } from "utils/common";
import { logError, logWarning } from "utils/logging";
import { getTimeZoneFromString, getTimeZoneGuess } from "utils/time";
import { TemplateDeleteModal } from "views/access_reviews/create/CreateAccessReviewScheduleColumn";
import {
  ItemDetailsCard,
  ItemDetailsCardSkeleton,
} from "views/common/ItemDetailsCard";
import { NotFoundPage } from "views/error/ErrorCodePage";

import * as styles from "./AccessReviewScheduleDetailV3.css";
import { defaultAccessReviewFilters } from "./create/utils";
import { getHasFilters } from "./create/utils";

const AccessReviewScheduleDetailV3 = () => {
  const history = useHistory();
  const { accessReviewTemplateId } = useParams<Record<string, string>>();
  const buttonSize = "sm";
  const { authState } = useContext(AuthContext);

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const { data, error, loading } = useAccessReviewTemplateQuery({
    variables: {
      input: {
        accessReviewTemplateId: accessReviewTemplateId,
      },
    },
  });

  let accessReviewTemplate:
    | AccessReviewTemplateFragment
    | undefined = undefined;

  if (data) {
    switch (data.accessReviewTemplate.__typename) {
      case "AccessReviewTemplateResult":
        accessReviewTemplate = data.accessReviewTemplate.accessReviewTemplate;
        break;
      case "AccessReviewTemplateNotFound":
        logWarning(new Error("error: access review schedule not found"));
        return <NotFoundPage />;
      default:
        logWarning(new Error("error: access review schedule not found"));
    }
  } else if (error) {
    logError(error, `failed to retrieve access review schedule`);
  }

  if (loading || !accessReviewTemplate) {
    return (
      <>
        <ItemDetailsCardSkeleton />
        <ColumnListItemsSkeleton />
      </>
    );
  }

  const filters = accessReviewTemplate.filters ?? defaultAccessReviewFilters();
  const canManage: boolean = Boolean(
    authState.user?.isAdmin || authState.user?.isAuditor
  );
  const timezone =
    getTimeZoneFromString(accessReviewTemplate.timeZone) || getTimeZoneGuess();
  // Need to copy array otherwise the page errors out because the sort function tries
  // to write to a readonly property. It should only ever have 12 elements at most, so overhead is low.
  // Additionally, the sort function requires a comparator function otherwise JS decides to sort
  // the numeric elements as strings
  const monthSchedule = [...accessReviewTemplate.monthSchedule]
    .sort((m1, m2) => m1 - m2)
    .map((m) => Months.find((month) => month.id === m)?.name);
  return (
    <>
      {showDeleteModal && accessReviewTemplateId && (
        <TemplateDeleteModal
          templateId={accessReviewTemplateId}
          showModal={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
      <ColumnHeaderV3
        includeDefaultActions
        breadcrumbs={[
          { name: "Access Reviews", to: "/access-reviews" },
          {
            name: "Schedules",
            to: "/access-reviews?category=schedules",
          },
          {
            name: accessReviewTemplate.name,
          },
        ]}
      />
      <ColumnContent>
        <ItemDetailsCard
          title={accessReviewTemplate.name}
          subtitle={
            accessReviewTemplate.accessReviewDuration +
            " days on the " +
            getNumberWithOrdinal(accessReviewTemplate.startDayOfMonth) +
            " of the month"
          }
          rightActions={
            canManage ? (
              <div className={sprinkles({ display: "flex", gap: "sm" })}>
                <ButtonV3
                  label="Delete Schedule"
                  type="danger"
                  onClick={() => {
                    setShowDeleteModal(true);
                  }}
                  size={buttonSize}
                />
                <ButtonV3
                  label="Edit"
                  type="main"
                  onClick={() => {
                    if (accessReviewTemplate) {
                      history.push(
                        "/access-reviews/t/" + accessReviewTemplate.id + "/edit"
                      );
                    }
                  }}
                  leftIconName="edit"
                  size={buttonSize}
                />
              </div>
            ) : (
              <></>
            )
          }
          bodyFields={{
            Occurs: (
              <div
                className={sprinkles({
                  display: "flex",
                  flexWrap: "wrap",
                  gap: "xs",
                })}
              >
                {monthSchedule.map((m) => (
                  <PillV3 key={m} pillColor="Cyan" keyText={m} />
                ))}
              </div>
            ),
            Timezone: (
              <PillV3
                pillColor="Cyan"
                keyText={accessReviewTemplate.timeZone}
                icon={{ icon: "globe", type: "name" }}
              />
            ),
            "Next Scheduled UAR": (
              <PillV3
                pillColor="Cyan"
                keyText={moment(accessReviewTemplate.nextScheduledRun).format(
                  "YYYY-MM-DD"
                )}
                icon={{ icon: "calendar", type: "name" }}
              />
            ),
          }}
        />
        <div className={styles.sectionHeader}>Scope</div>
        <div
          className={sprinkles({
            marginBottom: "lg",
          })}
        >
          <StartAccessReviewStatsV3 filters={filters} />
        </div>
        <FormGroup
          label="Users Included in this Access Review Schedule"
          fontWeight="bold"
          fontSize="textMd"
        >
          {filters.userIDs.length === 0 ? (
            "All users"
          ) : (
            <UsersFilter userIDs={filters.userIDs} />
          )}
        </FormGroup>

        <div
          className={sprinkles({
            marginBottom: "md",
            fontWeight: "bold",
            fontSize: "textMd",
          })}
        >
          Include entities that match{" "}
          <span>
            {filters.matchMode === FiltersMatchMode.All ? "ALL" : "ANY"}
          </span>{" "}
          of the following filters:
        </div>
        {filters && getHasFilters(filters) ? (
          <>
            <EntitiesNamedFilter names={filters.names} />
            <EntityTypesFilter
              resourceTypes={filters.resourceTypes}
              groupTypes={filters.groupTypes}
            />
            <ConnectionsFilter connectionIDs={filters.connectionIDs} />
            <EntitiesWithAdminFilter adminIDs={filters.adminIDs} />
            <TagFilter tags={filters.tags} />
            <EntitiesIndividualFilter entityIds={filters.entityIDs} />
          </>
        ) : (
          "No filters set"
        )}
        <Divider />
        <div className={styles.sectionHeader}>Notifications</div>
        <ReminderNotifications
          reminderSchedule={accessReviewTemplate.reminderSchedule}
          reminderTimeOfDay={
            new Date(accessReviewTemplate.reminderTimeOfDay) ||
            DEFAULT_REMINDER_TIME_OF_DAY
          }
          reminderIncludeManager={accessReviewTemplate.reminderIncludeManager}
          timezone={timezone}
        />
        <>
          New review notifications:{" "}
          {accessReviewTemplate.sendReviewerAssignmentNotification
            ? "On"
            : "Off"}
        </>
        <Divider />
        <div className={styles.sectionHeader}>Reviews</div>
        <>
          <AssignmentPolicy
            assignmentPolicy={accessReviewTemplate.reviewerAssignmentPolicy}
          />
          <FormGroup
            label={`Self-review: ${
              accessReviewTemplate.selfReviewAllowed ? "Allowed" : "Not Allowed"
            }`}
          />
        </>
      </ColumnContent>
    </>
  );
};

export default AccessReviewScheduleDetailV3;
