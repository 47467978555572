import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AuthType,
  ConnectionType,
  Maybe,
  useCreateConnectionMutation,
  Visibility,
} from "api/generated/graphql";
import oktaLogo from "assets/logos/okta-logo.png";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { Button, FormGroup, Input } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import { validateHostname } from "views/connections/create/common";
import {
  CreateConnectionComponents as CreateConnectionComponentsV2,
  CreateConnectionView as CreateConnectionViewV2,
} from "views/connections/create/CreateConnectionComponents";
import {
  CreateConnectionComponentsV3,
  CreateConnectionViewV3,
} from "views/connections/create/CreateConnectionComponentsV3";
import VisibilitySelector from "views/visibility/VisibilitySelector";

const CreateOktaDirectory = () => {
  return <CreateOktaDirectoryForm />;
};

const CreateOktaDirectoryForm = () => {
  const history = useHistory();
  const hasAppLevelVisibility = useFeatureFlag(FeatureFlag.AppLevelVisibility);
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const logEvent = useLogEvent();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [visibility, setVisibility] = useState<Visibility>(Visibility.Global);
  const [visibilityGroupIds, setVisibilityGroupIds] = useState<
    string[] | undefined
  >([]);
  const [orgUrl, setOrgUrl] = useState("");
  const [apiToken, setApiToken] = useState("");

  const [adminOwnerId, setAdminOwnerId] = useState<string | undefined>(
    undefined
  );

  const [createConnectionMutation, { loading }] = useCreateConnectionMutation({
    refetchQueries: ["AppsListColumn", "Connections"],
  });

  const fieldUnset =
    name === "" ||
    description === "" ||
    !adminOwnerId ||
    apiToken === "" ||
    orgUrl === "";

  const onSubmit = async () => {
    let hostnameErrorMessage = validateHostname(
      orgUrl,
      "Organization hostname"
    );
    if (orgUrl.includes("-admin")) {
      hostnameErrorMessage =
        'Organization hostname should not contain "-admin"';
    }
    if (hostnameErrorMessage) {
      setErrorMessage(`Error: ${hostnameErrorMessage}`);
      return;
    }
    try {
      const { data } = await createConnectionMutation({
        variables: {
          input: {
            name: name,
            description: description,
            connectionType: ConnectionType.OktaDirectory,
            adminOwnerId: adminOwnerId ?? "",
            visibility: visibility,
            visibilityGroupIds: visibilityGroupIds ?? [],
            importVisibility: Visibility.Global,
            metadata: {
              connectionType: ConnectionType.OktaDirectory,
              oktaDirectory: {
                orgUrl: orgUrl,
              },
            },
            credentials: {
              authType: AuthType.OktaDirectory,
              oktaDirectory: {
                adminToken: apiToken,
              },
            },
          },
        },
      });
      switch (data?.createConnection.__typename) {
        case "CreateConnectionResult":
          displaySuccessToast(`Success: Okta Directory app created`);
          history.replace(
            getResourceUrl(
              EntityTypeDeprecated.Connection,
              data.createConnection.connection.id
            )
          );
          logEvent({
            name: "apps_create_click",
            properties: {
              connectionType: ConnectionType.OktaDirectory,
            },
          });

          break;
        case "ConnectionExistsError":
        case "ConnectionBadMetadataError":
        case "UserFacingError":
          logWarning(new Error(data.createConnection.message));
          setErrorMessage(data.createConnection.message);
          break;
        default:
          logError(new Error(`failed to create Okta Directory connection`));
          setErrorMessage(`Error: failed to create Okta Directory connection`);
      }
    } catch (error) {
      logError(error, "failed to create Okta Directory connection");
      setErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to create Okta Directory connection",
          error
        )
      );
    }
  };

  const CreateConnectionComponents = hasV3
    ? CreateConnectionComponentsV3
    : CreateConnectionComponentsV2;
  const CreateConnectionView = hasV3
    ? CreateConnectionViewV3
    : CreateConnectionViewV2;

  return (
    <CreateConnectionView
      logo={oktaLogo}
      title={"Add your Okta organization"}
      onSubmit={onSubmit}
      submitDisabled={fieldUnset}
      submitLoading={loading}
    >
      <>
        <CreateConnectionComponents
          title={"Step 1"}
          subtitle={"Create an API token for Opal"}
        >
          <>
            <p>
              Opal requires an API token to manage your Okta groups,
              applications, users, and roles.
            </p>
            <p>
              To enable all of the capabilities above, you will need an API
              token created under an account with Super Administrator
              permissions. However, if your organization only needs Opal to
              manage groups, applications, and users (and not Okta admin roles),
              then you can scope down the API token's permissions.
            </p>
            <p>
              For more information, please{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  "https://docs.opal.dev/docs/5525158-okta-directory-getting-started#step-1---configure-an-api-token-for-opal"
                }
              >
                see our documentation here
              </a>
              .
            </p>
          </>
        </CreateConnectionComponents>
        <CreateConnectionComponents
          title={"Step 2"}
          subtitle={"Upload your Okta account credentials"}
          isLast
        >
          <>
            <p>
              {`Please input the API token and some information about the connection:`}
            </p>
            <FormGroup label="App name:">
              <Input
                onChange={setName}
                placeholder="Identifiable name for the Okta Directory connection."
                value={name}
              />
            </FormGroup>
            <FormGroup label="App admin:">
              <OwnerDropdown
                selectedOwnerId={adminOwnerId}
                onSelectOwner={(owner) => setAdminOwnerId(owner?.id)}
                placeholder="Select an owner to own this app."
              />
            </FormGroup>
            <FormGroup label="Description:">
              <Input
                onChange={setDescription}
                placeholder="A brief description of the account to further inform people requesting access to it."
                value={description}
              />
            </FormGroup>
            {hasAppLevelVisibility && (
              <FormGroup label="Visibility:">
                <VisibilitySelector
                  visibility={visibility}
                  onChangeVisibility={(vis) => {
                    if (vis == Visibility.Global) {
                      setVisibility(Visibility.Global);
                      setVisibilityGroupIds([]);
                    } else {
                      setVisibility(Visibility.Team);
                    }
                  }}
                  visibilityGroups={visibilityGroupIds ?? []}
                  onChangeVisibilityGroups={setVisibilityGroupIds}
                />
              </FormGroup>
            )}
            <FormGroup label="Organization hostname:">
              <Input
                onChange={setOrgUrl}
                placeholder='The Okta organization hostname (e.g. "mydomain.okta.com")'
                value={orgUrl}
              />
            </FormGroup>
            <FormGroup label="API token:">
              <Input
                onChange={setApiToken}
                placeholder="The API token for the Okta account."
                value={apiToken}
                type="password"
              />
            </FormGroup>
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
            {!hasV3 && (
              <div
                className={sprinkles({
                  display: "flex",
                  justifyContent: "flex-end",
                })}
              >
                <Button
                  type="primary"
                  disabled={fieldUnset}
                  label={"Create"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </div>
            )}
          </>
        </CreateConnectionComponents>
      </>
    </CreateConnectionView>
  );
};

export default CreateOktaDirectory;
