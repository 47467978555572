import {
  AccessReviewConnectionUserFragment,
  AccessReviewGroupUserFragment,
  AccessReviewItemStatus,
  AccessReviewResourceUserFragment,
  ConnectionPreviewTinyFragment,
  ConnectionType,
  ConnectionUserPropagationStatusFragment,
  EntityType,
  GroupPreviewTinyFragment,
  GroupPropagationStatusFragment,
  GroupType,
  ResourcePreviewTinyFragment,
  ResourcePropagationStatusFragment,
  ResourceType,
  useAccessReviewChangeDetailsQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import { Divider, Input } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import { filterSearchResults } from "utils/search/filter";
import { PropagatedEntityInfo, PropagationType } from "utils/useRemediations";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import AccessReviewContext, {
  AccessReviewContextActionType,
} from "./AccessReviewContext";
import * as styles from "./AccessReviewResource.css";
import { AccessReviewRevocationStatusLabel } from "./AccessReviewStatus";
import ConnectionUserSubRow from "./common/ConnectionUserSubRow";
import GroupUserSubRow from "./common/GroupUserSubRow";
import ResourceUserSubRow from "./common/ResourceUserSubRow";
import RevocationButtonsCell from "./common/RevocationButtonsCell";
import UserCell from "./common/UserCell";
import AccessReviewRevocationsActionButtons from "./revocations/AccessReviewRevocationsActionButtons";
import AccessReviewRevocationsSupportTicket from "./revocations/AccessReviewRevocationsSupportTicket";
import {
  calculateSelectAllState,
  RevocationAction,
} from "./revocations/AccessReviewRevocationsToggleButton";
import AccessReviewUpdateApprovalRequest from "./revocations/AccessReviewUpdateRequest";

interface Props {
  entity:
    | ResourcePreviewTinyFragment
    | GroupPreviewTinyFragment
    | ConnectionPreviewTinyFragment;
}

type AccessChangeUserData =
  | AccessReviewResourceUserFragment
  | AccessReviewConnectionUserFragment
  | AccessReviewGroupUserFragment;

interface AccessChangeRow {
  id: string;
  user: string;
  role: string;
  status: string;
  action: string;
  data: AccessChangeUserData;
}

const getEntityInfo = (
  user: AccessChangeUserData
): Omit<PropagatedEntityInfo, "accessReviewId"> => {
  if ("resource" in user) {
    return {
      roleAssignmentKey:
        user.resourceId + user.userId + user.accessLevel.accessLevelRemoteId,
      user: user.user,
      resource: user.resource,
      role: user.accessLevel,
      lastExpiringAccessPointExpiration:
        user.resourceUser?.access?.latestExpiringAccessPoint.expiration,
      accessReviewResourceUser: user,
    };
  } else if ("group" in user) {
    return {
      roleAssignmentKey:
        user.groupId + user.userId + user.accessLevel.accessLevelRemoteId,
      user: user.user,
      group: user.group,
      lastExpiringAccessPointExpiration:
        user.groupUser?.access?.latestExpiringAccessPoint.expiration,
      accessReviewGroupUser: user,
    };
  } else if ("connection" in user) {
    return {
      roleAssignmentKey: user.connectionId + user.userId,
      user: user.user,
      connection: user.connection,
      accessReviewConnectionUser: user,
    };
  }

  return { roleAssignmentKey: "" };
};

const getPropagationStatus = (
  user: AccessChangeUserData
):
  | ResourcePropagationStatusFragment
  | GroupPropagationStatusFragment
  | ConnectionUserPropagationStatusFragment
  | undefined => {
  if (!user.statusCode || !user.taskType || !user.lastSynced) return;

  if ("resource" in user) {
    return {
      resourceId: user.resourceId,
      userId: user.userId,
      accessLevelRemoteId: user.accessLevel.accessLevelRemoteId,
      errorMessage: user.errorMessage,
      statusCode: user.statusCode,
      taskType: user.taskType,
      lastSynced: user.lastSynced,
    };
  } else if ("group" in user) {
    return {
      groupId: user.groupId,
      userId: user.userId,
      errorMessage: user.errorMessage,
      statusCode: user.statusCode,
      taskType: user.taskType,
      lastSynced: user.lastSynced,
    };
  } else if ("connection" in user) {
    return {
      connectionId: user.connectionId,
      userId: user.userId,
      errorMessage: user.errorMessage,
      statusCode: user.statusCode,
      taskType: user.taskType,
      lastSynced: user.lastSynced,
    };
  }
};

const AccessChangeContent = ({ entity }: Props) => {
  const {
    accessReviewId,
    accessReviewResourceId,
    accessReviewGroupId,
    accessReviewConnectionId,
  } = useParams<Record<string, string>>();
  const history = useHistory();
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );
  const [searchQuery, setSearchQuery] = useState("");

  let entityType: ResourceType | GroupType | ConnectionType | undefined;
  let uarEntityType: EntityType = EntityType.AccessReviewResource;
  let sublabel = "";
  if ("resourceType" in entity) {
    entityType = entity.resourceType;
    sublabel = resourceTypeInfoByType[entityType].fullName;
  } else if ("groupType" in entity) {
    entityType = entity.groupType;
    uarEntityType = EntityType.AccessReviewGroup;
    sublabel = groupTypeInfoByType[entityType].name;
  } else if ("connectionType" in entity) {
    uarEntityType = EntityType.AccessReviewConnection;
    entityType = entity.connectionType;
    sublabel = "App";
  }

  const entityId =
    accessReviewResourceId ?? accessReviewGroupId ?? accessReviewConnectionId;
  const { data, error, loading } = useAccessReviewChangeDetailsQuery({
    variables: {
      input: {
        accessReviewId,
        entityId: {
          entityId,
          entityType: uarEntityType,
        },
      },
    },
  });

  if (!entityType) {
    return null;
  }

  if (loading) {
    return (
      <Column isContent>
        <ColumnHeader
          title={entity.name}
          onClose={() =>
            history.push(`/access-reviews/${accessReviewId}/access-changes`)
          }
          icon={{ type: "entity", entityType }}
          subtitle={sublabel}
        />
        <Divider />
        <ColumnContentSkeleton />
      </Column>
    );
  }

  if (error) {
    return (
      <Column isContent>
        <ColumnHeader
          title={entity.name}
          onClose={() =>
            history.push(`/access-reviews/${accessReviewId}/access-changes`)
          }
          icon={{ type: "entity", entityType }}
          subtitle={sublabel}
        />
        <Divider />
        <ColumnContent>
          <UnexpectedErrorPage error={error} />
        </ColumnContent>
      </Column>
    );
  }

  const users: AccessChangeUserData[] =
    (
      data?.accessReviewChange.users.resourceUsers ??
      data?.accessReviewChange.users.groupUsers ??
      data?.accessReviewChange.users.connectionUsers
    )?.map((ru) => ru) ?? [];

  const actionableResourceUserIds = users
    .filter(
      (user) =>
        "resource" in user &&
        user.statusAndOutcome.status ===
          AccessReviewItemStatus.NeedsEndSystemRevocation &&
        !user.supportTicket
    )
    .map((uarResourceUser) => uarResourceUser.id);
  const linkedResourceUserIds = users
    .filter(
      (user) =>
        "resource" in user &&
        user.statusAndOutcome.status ===
          AccessReviewItemStatus.NeedsEndSystemRevocation &&
        user.supportTicket
    )
    .map((uarResourceUser) => uarResourceUser.id);

  const updatedResourceUserIds = users
    .filter(
      (user) =>
        "resource" in user &&
        user.statusAndOutcome.status ===
          AccessReviewItemStatus.NeedsUpdateRequestApproval &&
        user.requestId
    )
    .map((uarResourceUser) => uarResourceUser.id);
  const showActionColumn =
    actionableResourceUserIds.length > 0 ||
    linkedResourceUserIds.length > 0 ||
    updatedResourceUserIds.length > 0;

  const columns: Header<AccessChangeRow>[] = [
    {
      id: "user",
      label: "User",
      customCellRenderer: (row) => {
        const user = row.data.user;
        if (!user) {
          return <></>;
        }
        return <UserCell user={user} />;
      },
    },
    {
      id: "role",
      label: "Role",
    },
    {
      id: "status",
      label: "Status",
      customCellRenderer: (row) => {
        const user = row.data;

        let entityType = EntityType.Resource;
        let propagationType = PropagationType.ResourceUser;
        if ("accessReviewGroupId" in user) {
          entityType = EntityType.Group;
          propagationType = PropagationType.GroupUser;
        } else if ("accessReviewConnectionId" in user) {
          entityType = EntityType.Connection;
          propagationType = PropagationType.ConnectionUser;
        }
        return (
          <AccessReviewRevocationStatusLabel
            status={user.statusAndOutcome.status}
            outcome={user.statusAndOutcome.outcome}
            entityType={entityType}
            entityInfo={{
              ...getEntityInfo(user),
              accessReviewId,
            }}
            propagationType={propagationType}
            propagationStatus={getPropagationStatus(user)}
          />
        );
      },
    },
  ];

  if (showActionColumn) {
    let header = <></>;
    if (actionableResourceUserIds.length > 0) {
      header = (
        <RevocationButtonsCell
          state={calculateSelectAllState(
            Object.values(
              accessReviewState.revocationActionByUARResourceUserId
            ),
            actionableResourceUserIds.length
          )}
          onStateChange={(state) => {
            const newActionByUARResourceUserId: Record<
              string,
              RevocationAction
            > = {};

            // If `state` is defined, set all items' actions to `state`, otherwise clear the actions.
            if (state) {
              actionableResourceUserIds.forEach((uarResourceUserId) => {
                newActionByUARResourceUserId[uarResourceUserId] = state;
              });
            }

            accessReviewDispatch({
              type: AccessReviewContextActionType.AccessReviewItemUpdate,
              payload: {
                revocationActionByUARResourceUserId: newActionByUARResourceUserId,
              },
            });
          }}
          isHeader
        />
      );
    }
    columns.push({
      id: "action",
      label: "Action",
      sortable: false,
      customHeader: header,
      customCellRenderer: (row) => {
        const user = row.data;
        const showActionButton =
          showActionColumn &&
          "resource" in user &&
          user.statusAndOutcome.status ===
            AccessReviewItemStatus.NeedsEndSystemRevocation &&
          !user.supportTicket;

        if (showActionButton) {
          return (
            <RevocationButtonsCell
              state={
                accessReviewState.revocationActionByUARResourceUserId[row.id]
              }
              onStateChange={(state) => {
                const newActionByUARResourceUserId = {
                  ...accessReviewState.revocationActionByUARResourceUserId,
                };

                if (state) {
                  newActionByUARResourceUserId[row.id] = state;
                } else {
                  delete newActionByUARResourceUserId[row.id];
                }

                accessReviewDispatch({
                  type: AccessReviewContextActionType.AccessReviewItemUpdate,
                  payload: {
                    revocationActionByUARResourceUserId: newActionByUARResourceUserId,
                  },
                });
              }}
            />
          );
        } else if ("resource" in user && user.supportTicket) {
          return (
            <AccessReviewRevocationsSupportTicket
              supportTicket={user.supportTicket}
            />
          );
        } else if ("resource" in user && user.requestId) {
          return (
            <AccessReviewUpdateApprovalRequest requestId={user.requestId} />
          );
        }
        return <></>;
      },
    });
  }

  const rows: AccessChangeRow[] = filterSearchResults(
    users,
    searchQuery,
    (user) => [user.user?.fullName, user.user?.email]
  ).map((user) => {
    return {
      id: user.id,
      user: user.user?.fullName ?? "",
      role:
        "accessLevel" in user ? user.accessLevel.accessLevelName || "--" : "--",
      status: user.statusAndOutcome.status,
      action: "",
      data: user,
    };
  });

  const renderSubRow = (row: AccessChangeRow) => {
    if ("resource" in row.data) {
      return <ResourceUserSubRow resourceUser={row.data} />;
    } else if ("group" in row.data) {
      return <GroupUserSubRow groupUser={row.data} />;
    } else if ("connection" in row.data) {
      return <ConnectionUserSubRow connectionUser={row.data} />;
    }
    return <></>;
  };

  return (
    <Column isContent>
      <ColumnHeader
        title={entity.name}
        onClose={() =>
          history.push(`/access-reviews/${accessReviewId}/access-changes`)
        }
        icon={{ type: "entity", entityType }}
        subtitle={sublabel}
        rightActions={
          <AccessReviewRevocationsActionButtons
            accessReviewId={accessReviewId}
          />
        }
      />
      <Divider />
      <ColumnContent>
        <div className={styles.tableContainer}>
          <div className={styles.searchContainer}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Search this table"
            />
          </div>
          <Table
            columns={columns}
            rows={rows}
            totalNumRows={rows.length}
            getRowId={(row) => row.id}
            renderSubRow={renderSubRow}
          />
        </div>
      </ColumnContent>
    </Column>
  );
};

export default AccessChangeContent;
