import { ReactComponent as activedirectory } from "./brands_square/activedirectory.svg";
import { ReactComponent as aws } from "./brands_square/aws.svg";
import { ReactComponent as azure } from "./brands_square/azure.svg";
import { ReactComponent as customapp } from "./brands_square/customapp.svg";
import { ReactComponent as customconnection } from "./brands_square/customconnection.svg";
import { ReactComponent as datadog } from "./brands_square/datadog.svg";
import { ReactComponent as duo } from "./brands_square/duo.svg";
import { ReactComponent as figma } from "./brands_square/figma.svg";
import { ReactComponent as gcp } from "./brands_square/gcp.svg";
import { ReactComponent as github } from "./brands_square/github.svg";
import { ReactComponent as gitlab } from "./brands_square/gitlab.svg";
import { ReactComponent as google } from "./brands_square/google.svg";
import { ReactComponent as googlegroups } from "./brands_square/googlegroups.svg";
import { ReactComponent as ldap } from "./brands_square/ldap.svg";
import { ReactComponent as mariadb } from "./brands_square/mariadb.svg";
import { ReactComponent as microsoftentraid } from "./brands_square/microsoftentraid.svg";
import { ReactComponent as mongo } from "./brands_square/mongo.svg";
import { ReactComponent as mysql } from "./brands_square/mysql.svg";
import { ReactComponent as okta } from "./brands_square/okta.svg";
import { ReactComponent as opal } from "./brands_square/opal.svg";
import { ReactComponent as pagerduty } from "./brands_square/pagerduty.svg";
import { ReactComponent as postgres } from "./brands_square/postgres.svg";
import { ReactComponent as salesforce } from "./brands_square/salesforce.svg";
import { ReactComponent as snowflake } from "./brands_square/snowflake.svg";
import { ReactComponent as tailscale } from "./brands_square/tailscale.svg";
import { ReactComponent as teleport } from "./brands_square/teleport.svg";
import { ReactComponent as workday } from "./brands_square/workday.svg";

export const brandSquareIconSet = {
  "brand-square-active-directory": activedirectory,
  "brand-square-aws": aws,
  "brand-square-awsiam": aws,
  "brand-square-azure": azure,
  "brand-square-customapp": customapp,
  "brand-square-customconnection": customconnection,
  "brand-square-datadog": datadog,
  "brand-square-duo": duo,
  "brand-square-figma": figma,
  "brand-square-gcp": gcp,
  "brand-square-github": github,
  "brand-square-gitlab": gitlab,
  "brand-square-google": google,
  "brand-square-googlegroups": googlegroups,
  "brand-square-ldap": ldap,
  "brand-square-mariadb": mariadb,
  "brand-square-microsoftentraid": microsoftentraid,
  "brand-square-mongo": mongo,
  "brand-square-mysql": mysql,
  "brand-square-okta": okta,
  "brand-square-opal": opal,
  "brand-square-pagerduty": pagerduty,
  "brand-square-postgres": postgres,
  "brand-square-salesforce": salesforce,
  "brand-square-snowflake": snowflake,
  "brand-square-tailscale": tailscale,
  "brand-square-teleport": teleport,
  "brand-square-workday": workday,
};
