import { RecommendationsSubscoreType } from "api/generated/graphql";
import { Label, Select } from "components/ui";
import sprinkles from "css/sprinkles.css";
import {
  outsideAccessRiskFactorText,
  perpetualAccessRiskFactorText,
  unusedAccessRiskFactorText,
} from "views/recommendations/ThreatPill";
import {
  outsideAccessIcon,
  permanentAccessIcon,
  unusedAccessIcon,
} from "views/recommendations/ThreatPill";

interface RiskFactorDropdownProps {
  riskFactors: RecommendationsSubscoreType[] | undefined;
  setRiskFactorFilter: (
    riskFactors?: RecommendationsSubscoreType[] | undefined
  ) => void;
  defaultLabel?: string;
}

export const allFilterableRiskFactors: RecommendationsSubscoreType[] = [
  RecommendationsSubscoreType.PerpetualAccess,
  RecommendationsSubscoreType.UnusedAccess,
  RecommendationsSubscoreType.UnmanagedAccess,
];

export const riskFactorToText = (
  riskFactor: RecommendationsSubscoreType | undefined
) => {
  switch (riskFactor) {
    case RecommendationsSubscoreType.PerpetualAccess:
      return perpetualAccessRiskFactorText;
    case RecommendationsSubscoreType.UnusedAccess:
      return unusedAccessRiskFactorText;
    case RecommendationsSubscoreType.UnmanagedAccess:
      return outsideAccessRiskFactorText;
  }
  return "";
};

const RiskFactorDropdown = (props: RiskFactorDropdownProps) => {
  let defaultLabel = "Filter by Risk Factor";
  if (props.defaultLabel) {
    defaultLabel = props.defaultLabel;
  }

  const handleSetRiskFactorFilter = (
    newRiskFactorFilter: RecommendationsSubscoreType[] | undefined
  ): void => {
    if (newRiskFactorFilter?.length === 0) {
      props.setRiskFactorFilter(undefined);
    } else {
      props.setRiskFactorFilter(newRiskFactorFilter);
    }
  };

  const renderRiskFactorInputValue = (
    value?: RecommendationsSubscoreType[]
  ) => {
    if (!value || value?.length === 0) {
      return defaultLabel;
    } else if (value?.length === 1) {
      return riskFactorToText(value[0]);
    } else {
      return (
        riskFactorToText(value[0]) +
        (value?.length > 1 ? ` + ${String(value.length - 1)} more` : "")
      );
    }
  };

  return (
    <Select<RecommendationsSubscoreType>
      multiple
      value={props.riskFactors}
      options={allFilterableRiskFactors}
      getOptionLabel={(riskFactor) => riskFactorToText(riskFactor)}
      getOptionSelected={(option, value) => {
        return option === value;
      }}
      alwaysShowPlaceholderIcon={true}
      onChange={handleSetRiskFactorFilter}
      getIcon={(riskFactor) => {
        if (riskFactor === RecommendationsSubscoreType.PerpetualAccess) {
          return permanentAccessIcon;
        } else if (riskFactor === RecommendationsSubscoreType.UnusedAccess) {
          return unusedAccessIcon;
        } else if (riskFactor === RecommendationsSubscoreType.UnmanagedAccess) {
          return outsideAccessIcon;
        } else {
          throw new Error(`Unexpected risk factor: ${riskFactor}`);
        }
      }}
      renderInputValue={renderRiskFactorInputValue}
      highlightWhenSelected={true}
      placeholder={renderRiskFactorInputValue(props.riskFactors)}
      placeholderIcon={{
        type: "name",
        icon: "odometer",
      }}
      listboxFooter={{
        footer: (
          <div
            className={sprinkles({
              fontFamily: "body",
              padding: "md",
            })}
          >
            {props.riskFactors && props.riskFactors.length > 0 ? (
              <Label
                label={`Clear selection (${props.riskFactors.length})`}
                color="blue600V3"
                onClick={() => handleSetRiskFactorFilter(undefined)}
              />
            ) : (
              <Label label="No items selected" color="gray700" />
            )}
          </div>
        ),
        sticky: true,
      }}
    />
  );
};

export default RiskFactorDropdown;
