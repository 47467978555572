import sprinkles from "../../css/sprinkles.css";
import { FormSection } from "../ui";
import { FormMode, OwnerConfig } from "./common";
import DescriptionRow from "./rows/DescriptionRow";
import EscalationDurationPolicyRow from "./rows/EscalationDurationPolicyRow";
import SlackChannelRow from "./rows/SlackChannelRow";
import SourceGroupRow from "./rows/SourceGroupRow";

interface Props {
  mode: FormMode;
  config: Partial<OwnerConfig>;
  onChange: (config: Partial<OwnerConfig>) => void;
}

export const OwnersConfigForm = (props: Props) => {
  const handleChange = (key: keyof OwnerConfig) => (
    val: OwnerConfig[keyof OwnerConfig]
  ) => {
    if (props.onChange) {
      props.onChange({ ...props.config, [key]: val });
    }
  };

  return (
    <>
      <div
        className={sprinkles({
          padding: "md",
          display: "flex",
          flexDirection: "column",
          gap: "xl",
        })}
      >
        <FormSection title="General">
          <DescriptionRow
            mode={props.mode}
            value={props.config.description}
            onChange={handleChange("description")}
          />
        </FormSection>
        <FormSection title="Membership information">
          <SourceGroupRow
            mode={props.mode}
            sourceGroup={props.config.sourceGroup ?? undefined}
            enabledSourceGroup={props.config.enabledSourceGroup ?? false}
            onSourceGroupChange={handleChange("sourceGroup")}
            onEnabledChange={handleChange("enabledSourceGroup")}
          />
        </FormSection>
        <FormSection title="Reviewer information">
          <EscalationDurationPolicyRow
            mode={props.mode}
            escalationDurationMin={
              props.config.escalationDurationMin ?? undefined
            }
            linkedMessageChannel={props.config.reviewerMessageChannel ?? null}
            ownerUsers={props.config.users ?? []}
            onChange={handleChange("escalationDurationMin")}
          />
          <SlackChannelRow
            mode={props.mode}
            adminOwnerName={props.config.name ?? ""}
            messageChannel={props.config.reviewerMessageChannel ?? undefined}
            onChange={handleChange("reviewerMessageChannel")}
          />
        </FormSection>
      </div>
    </>
  );
};
