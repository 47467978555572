import {
  ThirdPartyProvider,
  useCreateThirdPartyIntegrationMutation,
} from "api/generated/graphql";
import { useToast } from "components/toast/Toast";
import { useHistory, useLocation } from "react-router-dom";
import { useMountEffect } from "utils/hooks";
import { logError, logWarning } from "utils/logging";
import { FullPageLoading } from "views/loading/FullPageLoading";

// GitLabCallback is a dedicated component for handling the auth redirect
// and parsing/removing the auth token fragment from the URL for GitLab integration.
export const GitLabCallback = () => {
  const location = useLocation();

  const { displaySuccessToast, displayErrorToast } = useToast();

  const history = useHistory();
  const [createUserIntegration] = useCreateThirdPartyIntegrationMutation();

  useMountEffect(() => {
    async function createIntegrationWrapper() {
      const query = new URLSearchParams(location.search);
      const code = query.get("code");
      const state = query.get("state");

      if (code && state) {
        try {
          // We have received callback parameters from GitLab,
          // so we need to forward them on to the Opal server.
          const { data } = await createUserIntegration({
            variables: {
              input: {
                thirdPartyProvider: ThirdPartyProvider.GitLab,
                codeFlow: {
                  code: code,
                  state: state,
                },
              },
            },
          });
          switch (data?.createThirdPartyIntegration.__typename) {
            case "CreateThirdPartyIntegrationResult":
              displaySuccessToast("Success: GitLab account linked");
              break;
            case "ThirdPartyUserNotMatchedError":
              logWarning(new Error(data.createThirdPartyIntegration.message));
              displayErrorToast(data.createThirdPartyIntegration.message);
              break;
            default:
              logError(new Error(`failed to link GitLab account`));
              displayErrorToast("Error: failed to link GitLab account");
          }
        } catch (error) {
          logError(error, `failed to link GitLab account`);
          displayErrorToast("Error: failed to link GitLab account");
        }
        history.replace("/user/settings#identities");
      } else {
        displayErrorToast("Error: failed to link GitLab account");
        history.replace("/user/settings#identities");
      }
    }
    createIntegrationWrapper();
  });

  return <FullPageLoading />;
};

export default GitLabCallback;
