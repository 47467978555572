import {
  SyncStatusQuery,
  SyncTaskStatus,
  SyncType,
  useSetupStateQuery,
  useStartSyncMutation,
  useSyncStatusQuery,
  useSyncTaskQuery,
} from "api/generated/graphql";
import { Banner, Icon, Loader } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { CONNECTIONS_LIST } from "views/idp/create/BrowseIdpServices";

import { getIdpConnectionSubtitle, IDP_SECTIONS } from "../common";
import { SetupContext } from "../SetupContext";
import * as styles from "./IdpStep.css";

const Sync = () => {
  // Loading and error states are handled by the parent component
  const { data: setupData } = useSetupStateQuery();

  const { hasSuccessfulSync, setHasSuccessfulSync } = useContext(SetupContext);
  const [startSyncMutation] = useStartSyncMutation();
  const { refetch } = useSyncTaskQuery({
    skip: true,
  });
  const { refetch: refetchOngoing } = useSyncStatusQuery({
    skip: true,
  });

  const [syncInProgress, setSyncInProgress] = useState(false);
  const [syncError, setSyncError] = useState("");

  const idpConnectionInfo = CONNECTIONS_LIST.find(
    (info) =>
      info.idpConnectionType === setupData?.setupState.state.idpConnectionType
  );
  const connectionId = setupData?.setupState.state.connectionId;
  const syncedEntityName =
    setupData?.setupState.state.connection?.name ?? "IdP Connection";
  const subtitle = getIdpConnectionSubtitle(
    setupData?.setupState.state.connection?.metadata
  );

  useEffect(() => {
    const fetchSync = async (syncedEntityName: string) => {
      const ongoingResult = await refetchOngoing({
        input: {
          syncType: SyncType.PullIdpConnection,
          connectionId,
        },
      });
      const data = ongoingResult.data;
      if (data && data.syncStatus.__typename === "SyncStatusResult") {
        // If there is a recent (last 48 hours) successful sync, don't sync again
        if (hasRecentSuccessfulSync(data)) {
          setHasSuccessfulSync(true);
          return;
        }
        const taskID = data.syncStatus.ongoingSyncTask
          ? data.syncStatus.ongoingSyncTask.id
          : null;
        if (taskID != null) {
          setSyncInProgress(true);
          setTimeout(() => {
            checkSyncStatus(taskID, syncedEntityName, 500, 250);
          }, 250);
        } else {
          handleStartSync();
        }
      }
    };
    // On mount, check if there is a recent IDP sync or current ongoing IDP sync.
    // If not, start a new sync.
    if (connectionId) {
      fetchSync(syncedEntityName);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionId]);

  if (!setupData?.setupState.state || !idpConnectionInfo) {
    return null;
  }

  const checkSyncStatus = async (
    syncTaskId: string,
    syncedEntityName: string,
    waitInterval: number,
    totalWaited: number
  ) => {
    const syncTaskErrorText = `Error: could not get sync status`;
    try {
      const result = await refetch({
        input: {
          id: syncTaskId,
        },
      });
      const { data: syncTaskData } = result;
      let status: SyncTaskStatus;

      if (syncTaskData) {
        switch (syncTaskData?.syncTask.__typename) {
          case "SyncTaskResult":
            status = syncTaskData.syncTask.syncTask.status;
            if (status === SyncTaskStatus.Started) {
              // If in progress, keep polling to check for sync task completion
              setTimeout(() => {
                checkSyncStatus(
                  syncTaskId,
                  syncedEntityName,
                  Math.min(2000, waitInterval * 2),
                  totalWaited + waitInterval
                );
              }, waitInterval);
            } else {
              await handleSyncFinished();
            }

            break;
          case "SyncTaskNotFoundError":
          default:
            // Upon error, stop polling
            setSyncError(syncTaskErrorText);
            setSyncInProgress(false);
        }
      }
    } catch (error) {
      setSyncError(syncTaskErrorText);
      setSyncInProgress(false);
    }
  };

  const handleStartSync = async () => {
    setSyncInProgress(true);
    const startSyncErrorText = `Error: failed to sync ${syncedEntityName}`;
    try {
      const { data } = await startSyncMutation({
        variables: {
          input: {
            syncType: SyncType.PullIdpConnection,
            connectionId,
          },
        },
      });
      let syncTaskId: string;
      switch (data?.startSync.__typename) {
        case "StartSyncResult":
          // Poll to check for sync task completion
          syncTaskId = data.startSync.syncTask.id;
          setTimeout(() => {
            checkSyncStatus(syncTaskId, syncedEntityName, 500, 250);
          }, 250);
          break;
        case "InvalidSyncTypeError":
        case "ConnectionNotFoundError":
        case "ResourceNotFoundError":
        case "GroupNotFoundError":
        case "AccessReviewNotFoundError":
        default:
          setSyncError(startSyncErrorText);
          setSyncInProgress(false);
      }
    } catch (error) {
      setSyncError(startSyncErrorText);
      setSyncInProgress(false);
    }
  };

  const handleSyncFinished = async () => {
    setSyncInProgress(false);
    setHasSuccessfulSync(true);
  };

  return (
    <div className={styles.container}>
      <div className={styles.header}>Sync data</div>
      <p>
        {hasSuccessfulSync
          ? "Opal has successfully synced your IdP connection."
          : "Opal has successfully connected to your IdP and is now syncing data."}
      </p>
      {!hasSuccessfulSync && (
        <p>
          This might take awhile, so feel free to exit this flow. We'll notify
          you when the sync is finished and you can continue setting up this
          identity provider.
        </p>
      )}
      <div className={styles.connectionCard}>
        {syncInProgress && (
          <div className={styles.cardLoader}>
            <Loader size="lg" opalLogo />
          </div>
        )}
        <div className={styles.cardTop}>
          <div className={styles.cardLogoContainer}>
            <img alt={syncedEntityName} src={idpConnectionInfo.logo} />
          </div>
          <div>
            <div
              className={sprinkles({
                fontWeight: "semibold",
                fontSize: "headlineSm",
              })}
            >
              {syncedEntityName}
            </div>
            <div className={sprinkles({ color: "gray700" })}>{subtitle}</div>
          </div>
        </div>
        <div className={styles.cardBottom}>
          {IDP_SECTIONS[idpConnectionInfo.idpConnectionType].map((section) => (
            <div className={styles.cardBottomSection}>
              <div className={sprinkles({ marginBottom: "sm" })}>
                {hasSuccessfulSync ? (
                  <Icon name="check-circle" size="xs" color="green600" />
                ) : (
                  <Icon name="minus" size="xs" />
                )}
              </div>
              <div>{section}</div>
            </div>
          ))}
        </div>
      </div>
      {syncError && <Banner type="error" message={syncError} marginTop="xl" />}
    </div>
  );
};

export const hasRecentSuccessfulSync = (data?: SyncStatusQuery) => {
  let lastSuccessfulSyncTask;
  if (data?.syncStatus.__typename === "SyncStatusResult") {
    lastSuccessfulSyncTask = data.syncStatus.lastSuccessfulSyncTask;
  }
  return (
    lastSuccessfulSyncTask &&
    lastSuccessfulSyncTask?.syncType === SyncType.PullIdpConnection &&
    moment().diff(moment(lastSuccessfulSyncTask.updatedAt), "hours") < 48
  );
};

export default Sync;
