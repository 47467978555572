import AWSRoleCreationContent from "components/aws_role_creation/AWSRoleCreation";
import ColumnHeader from "components/column/ColumnHeader";
import { Divider } from "components/ui";

import AppsContentColumn from "./AppsContentColumn";

const AWSCreateRoleColumn = () => {
  return (
    <>
      <AppsContentColumn maxWidth="md">
        <ColumnHeader
          title="Create role"
          icon={{ type: "name", icon: "role" }}
        />
        <Divider margin="md" />
        <AWSRoleCreationContent />
      </AppsContentColumn>
    </>
  );
};

export default AWSCreateRoleColumn;
