import { FC } from "react";

import * as styles from "./FormSection.css";

interface Props {
  title: string;
}

const FormSection: FC<Props> = (props) => {
  return (
    <div>
      <h2 className={styles.title}>{props.title}</h2>
      {props.children}
    </div>
  );
};

export default FormSection;
