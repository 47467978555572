import {
  EntityIdTupleFragment,
  EntityType,
  SearchResultEntryFragment,
  useSearchQuery,
  useSuggestionsQuery,
} from "api/generated/graphql";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { ErrorMessageLightLabel } from "components/label/MessageLabel";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { Avatar, EntityIcon, Icon } from "components/ui";
import { KBarResults } from "kbar";
import React from "react";
import { Box } from "react-feather";
import { useHistory } from "react-router-dom";
import useLogEvent from "utils/analytics";
import { getResourceUrlNew } from "utils/common";
import useDebounce from "utils/search/useDebounce";

import ResultItem from "./ResultItem";
import styles from "./SearchResults.module.scss";
import { KBarItem, KBarResultItem, useSpotlightSearch } from "./utils";

const SEARCH_LIMIT = 5;

const ICON_PROPS = {
  size: 18,
  strokeWidth: 1.5,
};

const getEntityUrl = (entity: EntityIdTupleFragment) => {
  if (entity.entityType === EntityType.SearchQuery) {
    if (entity.entityId) {
      const encodedSearchQuery = encodeURIComponent(entity.entityId);
      return `/search?q=${encodedSearchQuery}`;
    }
    return "/search";
  }
  return getResourceUrlNew(entity);
};

const getIconAndEntityTypeName = (
  entry: SearchResultEntryFragment
): {
  icon: JSX.Element;
  entityTypeName?: string;
} => {
  switch (entry.objectId.entityType) {
    case EntityType.Resource: {
      const resourceInfo = getResourceTypeInfo(entry.resourceType);
      return {
        icon: <EntityIcon type={entry.resourceType} />,
        entityTypeName: resourceInfo?.name,
      };
    }
    case EntityType.Group: {
      const groupInfo = getGroupTypeInfo(entry.groupType);
      return {
        icon: <EntityIcon type={entry.groupType} />,
        entityTypeName: groupInfo?.name,
      };
    }
    case EntityType.Owner: {
      return {
        icon: <Icon name="user-square" />,
        entityTypeName: "Owner",
      };
    }
    case EntityType.Connection: {
      return {
        icon: entry.connection?.connectionType ? (
          <EntityIcon type={entry.connection?.connectionType} />
        ) : (
          <Icon name="layers" />
        ),
        entityTypeName: "App",
      };
    }
    case EntityType.User: {
      return {
        icon: <Avatar size="normal" url={entry.avatarUrl || undefined} />,
        entityTypeName: "User",
      };
    }
    case EntityType.Bundle: {
      return {
        icon: <Icon name="package" />,
        entityTypeName: "Bundle",
      };
    }
    default:
      return {
        icon: <Box {...ICON_PROPS} />,
        entityTypeName: "Unknown",
      };
  }
};

const SearchResults: React.FC<{}> = () => {
  const { searchQuery } = useSpotlightSearch();
  const debouncedSearchQuery = useDebounce(searchQuery);

  const history = useHistory();
  const logEvent = useLogEvent();
  const { data: suggestionsData } = useSuggestionsQuery({
    fetchPolicy: "no-cache",
  });

  const { error, data, loading } = useSearchQuery({
    skip: debouncedSearchQuery.length <= 1,
    variables: {
      input: {
        query: debouncedSearchQuery,
      },
    },
  });
  const searchResultEntries = data?.search.entries || [];

  const suggestionsEntries = suggestionsData?.suggestions?.suggestions || [];

  React.useEffect(() => {
    if (!loading && data?.search) {
      logEvent({
        name: "k_bar_search_results_view",
        properties: {
          searchQuery: debouncedSearchQuery,
          numResults: searchResultEntries.length,
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery, loading, data?.search]);

  if (error) {
    return (
      <div className={styles.error}>
        <ErrorMessageLightLabel errorMessage="Error: search functionality is degraded" />
      </div>
    );
  }

  let suggestionItems: KBarResultItem[] = suggestionsEntries.map((entry) => {
    let entityType;
    let entityId;
    let specificTypeName;
    let rightText = "";
    let specificType;
    if (entry.__typename === "GroupSuggestion") {
      entityType = EntityType.Group;
      entityId = entry.groupId;
      specificType = entry.groupType;
      specificTypeName = getGroupTypeInfo(entry.groupType)?.name ?? "";
    } else {
      entityType = EntityType.Resource;
      entityId = entry.resourceId;
      specificType = entry.resourceType;
      specificTypeName = getResourceTypeInfo(entry.resourceType)?.name ?? "";
      rightText = entry.parentResourceName ?? "";
    }
    const objectId = { entityId, entityType };

    return {
      type: "result",
      command: {
        perform: () => {
          history.push(getEntityUrl(objectId));
        },
      },
      label: entry.name,
      icon: <EntityIcon type={specificType} />,
      subText: specificTypeName ?? "",
      rightText,
    };
  });

  let items: KBarItem[] = searchResultEntries
    .slice(0, SEARCH_LIMIT)
    .map((entry) => {
      const { icon, entityTypeName } = getIconAndEntityTypeName(entry);
      return {
        type: "result",
        command: {
          perform: () => {
            logEvent({
              name: "k_bar_search_result_click",
              properties: {
                searchQuery,
                entityID: entry.objectId.entityId,
                entityName: entry.name,
                entityType: entry.objectId.entityType,
              },
            });
            history.push(getEntityUrl(entry.objectId));
          },
        },
        label: entry.name,
        icon: icon,
        subText: (entry.annotationText || entityTypeName) ?? "",
        rightText: entry.connection?.name ?? "",
      };
    });

  if (searchResultEntries.length > 0) {
    items.push({
      type: "see-all",
      command: {
        perform: () => {
          logEvent({
            name: "k_bar_see_all_results_click",
            properties: {
              searchQuery,
              numResults: searchResultEntries.length,
            },
          });
          const url = getEntityUrl({
            entityId: searchQuery,
            entityType: EntityType.SearchQuery,
          });
          history.push(url);
        },
      },
    });
  } else if (!loading && debouncedSearchQuery.length > 1) {
    items.push({
      type: "no-results",
      command: {
        perform: () => {},
      },
    });
  }
  if (searchQuery.length <= 1) {
    items = [
      { type: "section", name: "Most Visited", command: { perform: () => {} } },
      ...suggestionItems,
    ];
  }

  const renderResult = (resultData: { item: unknown; active: boolean }) => {
    // KBar typings aren't the best. Through inspection, the item here is KBarItem.
    const kbarResultItem: KBarItem = resultData.item as KBarItem;
    return (
      <ResultItem
        active={resultData.active}
        item={kbarResultItem}
        searchQuery={searchQuery}
      />
    );
  };

  return <KBarResults items={items} onRender={renderResult} />;
};

export default SearchResults;
