import {
  GroupType,
  IdpConnectionType,
  ResourceType,
  useSetupStateQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { createContext, useContext, useState } from "react";
import { GCPServiceAccountJSON } from "views/connections/create/UploadServiceAccountJSONButton";
import OrgContext from "views/settings/OrgContext";

import { ImportStep } from "./common";

export interface Credentials {
  // Common
  name?: string;
  adminOwnerId?: string;
  description?: string;
  // Okta
  orgUrl?: string;
  apiToken?: string;
  // Google
  adminEmail?: string;
  serviceAccountJson?: GCPServiceAccountJSON;
  customerId?: string;
  // Azure
  tenantId?: string;
  clientId?: string;
  clientSecret?: string;
  // Workday
  username?: string;
  password?: string;
  tenantName?: string;
  tenantSubdomain?: string;
}

interface ImportItem {
  id: string;
  name: string;
  groupType?: GroupType;
  resourceType?: ResourceType;
}

interface SetupState {
  currentStep: number;
  connectionType?: IdpConnectionType;
  credentials?: Credentials;
  credentialsError?: string;
  hasSuccessfulSync: boolean;
  importMode: "all" | "selected";
  importStep: ImportStep;
  itemsToImport?: ImportItem[];
  hasSuccessfulImport: boolean;
}

interface SetupData extends SetupState {
  setCurrentStep: (step: number) => void;
  setConnectionType: (connectionType: IdpConnectionType) => void;
  setCrendentials: (crendentials: Credentials) => void;
  setCredentialsError: (error?: string) => void;
  setHasSuccessfulSync: (hasSuccessfulSync: boolean) => void;
  setImportMode: (importMode: "all" | "selected") => void;
  setImportStep: (importStep: number) => void;
  setImportItems: (items: ImportItem[]) => void;
  setHasSuccessfulImport: (hasSuccessfulImport: boolean) => void;
}

export const SetupContext = createContext<SetupData>({
  currentStep: 0,
  setCurrentStep: () => {},
  connectionType: undefined,
  setConnectionType: () => {},
  credentials: undefined,
  setCrendentials: () => {},
  setCredentialsError: () => {},
  hasSuccessfulSync: false,
  setHasSuccessfulSync: () => {},
  importMode: "all",
  setImportMode: () => {},
  importStep: 0,
  setImportStep: () => {},
  setImportItems: () => {},
  hasSuccessfulImport: false,
  setHasSuccessfulImport: () => {},
});

export const SetupContextProvider: React.FC = ({ children }) => {
  const { orgState } = useContext(OrgContext);
  const idpConnection = orgState.orgIdpConnections[0];
  const [currentStep, setCurrentStep] = useState<number>(idpConnection ? 2 : 0);
  const [connectionType, setConnectionType] = useState<
    IdpConnectionType | undefined
  >();
  const [credentials, setCrendentials] = useState<Credentials | undefined>();
  const [credentialsError, setCredentialsError] = useState<
    string | undefined
  >();
  const [hasSuccessfulSync, setHasSuccessfulSync] = useState<boolean>(false);
  const [importMode, setImportMode] = useState<"all" | "selected">("all");
  const [importStep, setImportStep] = useState<number>(ImportStep.SELECT_MODE);
  const [itemsToImport, setImportItems] = useState<ImportItem[]>([]);
  const [hasSuccessfulImport, setHasSuccessfulImport] = useState<boolean>(
    false
  );

  const handleSetCrendentials = (crendentials: Credentials) => {
    setCrendentials((prev) => ({ ...prev, ...crendentials }));
  };

  return (
    <SetupContext.Provider
      value={{
        currentStep,
        setCurrentStep,
        connectionType,
        setConnectionType,
        credentials,
        setCrendentials: handleSetCrendentials,
        credentialsError,
        setCredentialsError,
        hasSuccessfulSync,
        setHasSuccessfulSync,
        importMode,
        setImportMode,
        importStep,
        setImportStep,
        itemsToImport,
        setImportItems,
        hasSuccessfulImport,
        setHasSuccessfulImport,
      }}
    >
      {children}
    </SetupContext.Provider>
  );
};

// useResolvedState reconciliates current state with the state from the backend
export const useResolvedState = (): [
  SetupState,
  { loading: boolean; error?: string }
] => {
  const { authState } = useContext(AuthContext);
  const { data, loading, error } = useSetupStateQuery({
    skip: !authState.user?.isAdmin,
  });
  const {
    currentStep,
    connectionType,
    credentials,
    credentialsError,
    hasSuccessfulSync,
    importMode,
    importStep,
    itemsToImport,
    hasSuccessfulImport,
  } = useContext(SetupContext);

  const minStep = data?.setupState.state.minStep ?? 0;

  return [
    {
      currentStep: Math.max(currentStep, minStep),
      connectionType:
        data?.setupState.state.idpConnectionType || connectionType,
      credentials,
      credentialsError,
      hasSuccessfulSync,
      importMode,
      importStep: minStep === 3 ? ImportStep.PROGRESS : importStep,
      itemsToImport,
      hasSuccessfulImport,
    },
    { loading, error: error?.message },
  ];
};
