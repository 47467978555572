import {
  EventFilterFields,
  EventPreviewFragment,
  useEventsQuery,
} from "api/generated/graphql";
import { EventTypeLabel } from "components/label/EventTypeLabel";
import Table, { Header } from "components/ui/table/Table";
import moment from "moment";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { getEventFilterEndDateInput } from "views/events/utils";
import ViewSkeleton from "views/loading/ViewSkeleton";

import UserCell from "./UserCell";

interface Props {
  eventFilter: EventFilterFields;
}

interface EventRow {
  eventID: string;
  actor: string;
  eventType: string;
  date: string;
  data: EventPreviewFragment;
}

const EventsTable = (props: Props) => {
  const { data, error, loading, fetchMore } = useEventsQuery({
    variables: {
      input: {
        filter: {
          ...props.eventFilter,
          endDate: props.eventFilter.endDate?.date
            ? {
                date:
                  getEventFilterEndDateInput(props.eventFilter.endDate.date) ??
                  "",
              }
            : undefined,
        },
        maxNumEntries: 5,
      },
    },
  });
  switch (data?.events.__typename) {
    case "InvalidObjectIDFilterError":
      return (
        <UnexpectedErrorPage error={new Error("Invalid event object ID")} />
      );
  }
  let events = data?.events.events;
  let cursor = data?.events.cursor;

  if (loading) {
    return <ViewSkeleton />;
  }

  if (error || !events) {
    return <UnexpectedErrorPage error={error} />;
  }

  const columns: Header<EventRow>[] = [
    {
      id: "actor",
      label: "Actor",
      customCellRenderer: (row) => {
        const user = row.data.actorUser;
        if (!user) return <></>;

        return <UserCell user={user} />;
      },
    },
    {
      id: "eventType",
      label: "Event",
      customCellRenderer: (row) => {
        return (
          <EventTypeLabel
            eventType={row.data.eventType}
            eventSource={row.data.source}
            eventId={row.eventID}
          />
        );
      },
    },
    {
      id: "date",
      label: "Date",
      customCellRenderer: (row) => {
        const createdAtTime = moment(new Date(row.data.createdAt));

        return (
          <>{`${createdAtTime.toISOString()} (${createdAtTime.fromNow()})`}</>
        );
      },
    },
  ];

  const rows: EventRow[] = events.map((event) => {
    return {
      eventID: event.id,
      actor: event.actorUser?.fullName || "",
      eventType: event.eventType,
      date: event.createdAt,
      data: event,
    };
  });

  return (
    <Table
      columns={columns}
      rows={rows}
      totalNumRows={cursor ? rows.length + 1 : rows.length}
      getRowId={(row) => row.eventID}
      onLoadMoreRows={async () => {
        if (!cursor) {
          return;
        }

        await fetchMore({
          variables: {
            input: {
              cursor,
              filter: props.eventFilter,
            },
          },
        });
      }}
    />
  );
};

export default EventsTable;
