import opalLogoSpinner from "assets/spinners/opal-logo-spinner.gif";
import React from "react";
import styles from "views/loading/FullPageLoading.module.scss";

export const FullPageLoading = () => {
  return (
    <div className={styles.container}>
      <OpalLogoSpinner />
    </div>
  );
};

const OpalLogoSpinner = () => {
  return (
    <div className={styles.opalLogoSpinner}>
      <img src={opalLogoSpinner} alt={"opal-logo-spinner"} />
    </div>
  );
};

export default FullPageLoading;
