import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewAction,
  AccessReviewResourceFragment,
  AccessReviewResourcePreviewFragment,
  AccessReviewResourceUserPreviewFragment,
  EntityType,
  ResourceFragment,
  ResourceType,
  ReviewerUserStatus,
  ReviewResourceUserAction,
  useUpdateResourceUserReviewersMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import AccessReviewNoteLabel from "components/label/AccessReviewNoteLabel";
import { ResourceLabel } from "components/label/Label";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import NavigationContext from "components/layout/NavigationContextProvider";
import AccessReviewerModal from "components/modals/AccessReviewerModal";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import {
  CellRow,
  Header,
  ScrollableMuiVirtualTable,
} from "components/tables/material_table/MuiVirtualTable";
import { useToast } from "components/toast/Toast";
import { Popover } from "components/ui";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import { SortDirection } from "react-virtualized";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { logError } from "utils/logging";
import AccessReviewAcceptRevokeToggleButton, {
  calculateBatchAcceptRevokeState,
} from "views/access_reviews/AccessReviewAcceptRevokeToggleButton";
import AccessReviewContext, {
  AccessReviewContextActionType,
} from "views/access_reviews/AccessReviewContext";
import AccessReviewResourceRoleCell, {
  updateReviewStateForResourceUserRoleChange,
} from "views/access_reviews/AccessReviewResourceRoleCell";
import AccessReviewResourceUpdatedResourceCell, {
  updateReviewStateForResourceUserUpdatedResourceChange,
} from "views/access_reviews/AccessReviewResourceUpdatedResourceCell";
import AccessReviewReviewerDetails, {
  accessReviewReviewerDetailsRowHeight,
} from "views/access_reviews/AccessReviewReviewerDetails";
import AccessReviewReviewersCell from "views/access_reviews/AccessReviewReviewersCell";
import {
  accessReviewItemStatusSortValue,
  AccessReviewOutcomeLabel,
  AccessReviewStatusLabel,
} from "views/access_reviews/AccessReviewStatus";
import {
  getReviewerModalEntries,
  getReviewersSortValue,
  isReviewer,
} from "views/access_reviews/common/Common";
import { getAccessReviewUserUrl } from "views/access_reviews/common/Routes";
import {
  ITEM_ACTION_HEADER,
  ITEM_STATUS_HEADER,
  NOTE_HEADER,
  OUTCOME_HEADER,
  REVIEWERS_HEADER,
  ROLE_HEADER,
  UPDATED_RESOURCE_HEADER,
  USER_NAME_HEADER,
} from "views/access_reviews/common/TableHeaders";
import AccessReviewUserOverview from "views/access_reviews/users/AccessReviewUserOverview";

interface AccessReviewResourceUserTableRow {
  name: string;
  role: string;
  note?: string;
  reviewers?: string;
  status?: string;
  outcome?: string;
  acceptRevoke?: string;
  updatedResource?: string;
}

const EditableResourceTypes: ResourceType[] = [
  ResourceType.SalesforceProfile,
  ResourceType.SalesforceRole,
  ResourceType.OktaRole,
];

type AccessReviewResourceUsersTableProps = {
  accessReviewResource: AccessReviewResourceFragment;
  selectedRows?: Record<string, EntityType>;
  setSelectedRows?: (value: Record<string, EntityType>) => void;
};

export const AccessReviewResourceUsersTable = (
  props: AccessReviewResourceUsersTableProps
) => {
  const { navigationState } = useContext(NavigationContext);
  const { authState } = useContext(AuthContext);
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );
  const history = useHistory();

  const selfReviewAllowed =
    props.accessReviewResource.accessReview?.selfReviewAllowed;

  const [expandedByRowId, setExpandedByRowId] = useState<
    Record<string, boolean>
  >({});

  let users = props.accessReviewResource.resourceUsers || [];

  if (navigationState.isOnlyMyReviewsFilterOn) {
    users = users.filter((accessReviewResourceUser) => {
      return (
        authState.user?.user.id &&
        isReviewer(
          authState.user.user.id,
          accessReviewResourceUser.reviewerUsers ?? []
        )
      );
    });
  }

  const performReviewState =
    accessReviewState.performReviewStateByUARResourceId[
      props.accessReviewResource.id
    ];
  const isResourceBeingReviewed = !!performReviewState;

  if (isResourceBeingReviewed) {
    users = users.filter((user) => {
      return user.reviewerUsers?.find((reviewer) => {
        return (
          reviewer.userId === authState.user?.user.id &&
          (selfReviewAllowed || authState.user?.user.id !== user.userId) &&
          reviewer.status === ReviewerUserStatus.NotStarted
        );
      });
    });
  }

  // Don't show role column if the resource doesn't have roles specified
  const resourceHasRoles = users.some(
    (user) => !!user.accessLevel.accessLevelRemoteId
  );
  const resourceCanBeUpdated =
    !resourceHasRoles &&
    props.accessReviewResource.resource &&
    EditableResourceTypes.includes(
      props.accessReviewResource.resource?.resourceType
    );

  /*
   * Components for rendering the table
   */

  const headers: Header<AccessReviewResourceUserTableRow>[] = [
    USER_NAME_HEADER,
  ];

  if (resourceHasRoles) {
    headers.push(ROLE_HEADER, REVIEWERS_HEADER);
  } else {
    headers.push(REVIEWERS_HEADER);
  }

  if (isResourceBeingReviewed) {
    const batchAcceptRevokeToggleButton = (
      <AccessReviewAcceptRevokeToggleButton
        state={calculateBatchAcceptRevokeState(
          performReviewState.resourceUserActions,
          users.length
        )}
        acceptText={"Accept All"}
        revokeText={"Revoke All"}
        onStateChange={(state) => {
          if (!isResourceBeingReviewed) return;

          performReviewState.resourceUserActions.forEach(
            (resourceUserAction) => {
              resourceUserAction.action = state;
            }
          );

          users.forEach((resourceUser) => {
            const existingInfo = performReviewState.resourceUserActions.find(
              (resourceUserAction) =>
                resourceUserAction.accessReviewResourceUserId ===
                resourceUser.id
            );

            if (!existingInfo) {
              performReviewState.resourceUserActions.push({
                accessReviewResourceUserId: resourceUser.id,
                action: state,
              });
            }
          });

          accessReviewDispatch({
            type: AccessReviewContextActionType.AccessReviewItemUpdate,
            payload: {
              performReviewStateByUARResourceId: {
                ...accessReviewState.performReviewStateByUARResourceId,
                [props.accessReviewResource.id]: performReviewState,
              },
            },
          });
        }}
      />
    );
    headers.push({
      ...ITEM_ACTION_HEADER,
      customHeader: batchAcceptRevokeToggleButton,
    });
    if (resourceCanBeUpdated) {
      if (props.accessReviewResource.resource?.resourceType) {
        const resourceTypeInfo =
          resourceTypeInfoByType[
            props.accessReviewResource.resource?.resourceType
          ];
        headers.push({
          ...UPDATED_RESOURCE_HEADER,
          label: "Updated " + resourceTypeInfo.name,
        });
      } else {
        headers.push(UPDATED_RESOURCE_HEADER);
      }
    }
    headers.push(NOTE_HEADER);
  } else {
    headers.push(ITEM_STATUS_HEADER, OUTCOME_HEADER);
  }

  /*
   * Table rows data
   */

  const resourceUserActionByUarResourceUserId: Record<
    string,
    ReviewResourceUserAction
  > = {};
  performReviewState?.resourceUserActions?.forEach((resourceUserAction) => {
    const id = resourceUserAction.accessReviewResourceUserId;
    resourceUserActionByUarResourceUserId[id] = resourceUserAction;
  });

  const rows: CellRow<AccessReviewResourceUserTableRow>[] = users.map(
    (resourceUser, idx) => {
      const rowId = resourceUser.id;
      const resourceUserAction = resourceUserActionByUarResourceUserId[rowId];

      const name = resourceUser.user?.fullName || resourceUser.userId;
      const status = resourceUser.statusAndOutcome.status;
      const outcome = resourceUser.statusAndOutcome.outcome;

      return {
        id: rowId,
        expandableContent: {
          content: (
            <AccessReviewReviewerDetails
              reviewers={resourceUser.reviewerUsers}
            />
          ),
          expandedRowHeight: accessReviewReviewerDetailsRowHeight(
            resourceUser.reviewerUsers
          ),
          isExpanded: expandedByRowId[rowId],
          setIsExpanded: (expanded) => {
            setExpandedByRowId({
              ...expandedByRowId,
              [rowId]: expanded,
            });
          },
        },
        data: {
          name: {
            value: name,
            element: (
              <Popover
                content={
                  <AccessReviewUserOverview userId={resourceUser.userId} />
                }
                inline
              >
                <ResourceLabel
                  text={name}
                  subText={resourceUser.user?.teamAttr}
                  iconLarge={true}
                  bold={true}
                  entityType={EntityTypeDeprecated.User}
                  avatar={resourceUser.user?.avatarUrl}
                  pointerCursor={true}
                />
              </Popover>
            ),
            clickHandler: () => {
              history.push(
                getAccessReviewUserUrl(
                  props.accessReviewResource.accessReviewId,
                  resourceUser.userId
                )
              );
            },
          },
          role: {
            value: resourceUser.accessLevel.accessLevelName || "",
            element: (
              <AccessReviewResourceRoleCell
                role={resourceUser.accessLevel}
                updatedRole={resourceUserAction?.updatedAccessLevel}
                showEditIcon={isResourceBeingReviewed}
                resourceId={resourceUser.resourceId}
                onRoleChange={(role) => {
                  if (!performReviewState) {
                    return;
                  }

                  const updatedPerformReviewState = updateReviewStateForResourceUserRoleChange(
                    performReviewState,
                    resourceUser,
                    role
                  );

                  accessReviewDispatch({
                    type: AccessReviewContextActionType.AccessReviewItemUpdate,
                    payload: {
                      performReviewStateByUARResourceId: {
                        ...accessReviewState.performReviewStateByUARResourceId,
                        [props.accessReviewResource
                          .id]: updatedPerformReviewState,
                      },
                    },
                  });
                }}
              />
            ),
          },
          reviewers: {
            value: getReviewersSortValue(resourceUser.reviewerUsers ?? []),
            element: (
              <AccessReviewerResourceUsersCell
                accessReviewResource={props.accessReviewResource}
                resourceUser={resourceUser}
              />
            ),
          },
          status: {
            value: status,
            sortValue: accessReviewItemStatusSortValue(status),
            element: (
              <AccessReviewStatusLabel
                entityType={EntityType.AccessReviewResourceUser}
                status={status}
                warnings={resourceUser.warnings}
                resource={props.accessReviewResource.resource}
                user={resourceUser.user}
                accessReviewId={props.accessReviewResource.accessReviewId}
              />
            ),
          },
          outcome: {
            value: outcome,
            element: (
              <AccessReviewOutcomeLabel
                entityType={EntityType.AccessReviewResourceUser}
                outcome={outcome}
              />
            ),
          },
          note: {
            options: {
              display: isResourceBeingReviewed,
            },
            value: "",
            element: (
              <AccessReviewNoteLabel
                initNoteContent={resourceUserAction?.note}
                onSubmit={(updatedNoteContent) => {
                  if (!performReviewState) {
                    return;
                  }

                  const existingInfo = performReviewState.resourceUserActions.find(
                    (resourceUserAction) =>
                      resourceUserAction.accessReviewResourceUserId ===
                      resourceUser.id
                  );

                  if (existingInfo) {
                    existingInfo.note = updatedNoteContent;
                  } else {
                    performReviewState.resourceUserActions.push({
                      accessReviewResourceUserId: resourceUser.id,
                      action: AccessReviewAction.NoAction,
                      note: updatedNoteContent,
                    });
                  }
                }}
              />
            ),
            sortValue: idx,
          },
          acceptRevoke: {
            value: resourceUserAction?.action || AccessReviewAction.NoAction,
            element: (
              <AccessReviewAcceptRevokeToggleButton
                state={
                  resourceUserAction?.action || AccessReviewAction.NoAction
                }
                onStateChange={(state) => {
                  if (!performReviewState) {
                    return;
                  }

                  const existingInfo = performReviewState.resourceUserActions.find(
                    (resourceUserAction) =>
                      resourceUserAction.accessReviewResourceUserId === rowId
                  );

                  if (existingInfo) {
                    existingInfo.action = state;
                    delete existingInfo.updatedAccessLevel;
                    delete existingInfo.updatedResource;
                  } else {
                    performReviewState.resourceUserActions.push({
                      accessReviewResourceUserId: rowId,
                      action: state,
                    });
                  }
                  accessReviewDispatch({
                    type: AccessReviewContextActionType.AccessReviewItemUpdate,
                    payload: {
                      performReviewStateByUARResourceId: {
                        ...accessReviewState.performReviewStateByUARResourceId,
                        [props.accessReviewResource.id]: performReviewState,
                      },
                    },
                  });
                }}
              />
            ),
          },
          updatedResource: {
            value: resourceUserAction?.updatedResource?.name || "",
            element: (
              <AccessReviewResourceUpdatedResourceCell
                resource={
                  props.accessReviewResource.resource as ResourceFragment
                }
                updatedResource={resourceUserAction?.updatedResource}
                showEditIcon={isResourceBeingReviewed}
                resourceId={resourceUser.resourceId}
                resourceBeingRevoked={
                  resourceUserAction?.action === AccessReviewAction.Revoke
                }
                onUpdatedResourceChange={(resource) => {
                  if (!performReviewState) {
                    return;
                  }

                  const updatedPerformReviewState = updateReviewStateForResourceUserUpdatedResourceChange(
                    performReviewState,
                    resourceUser,
                    resource
                  );

                  accessReviewDispatch({
                    type: AccessReviewContextActionType.AccessReviewItemUpdate,
                    payload: {
                      performReviewStateByUARResourceId: {
                        ...accessReviewState.performReviewStateByUARResourceId,
                        [props.accessReviewResource
                          .id]: updatedPerformReviewState,
                      },
                    },
                  });
                }}
              />
            ),
          },
        },
      };
    }
  );

  const onCheckedRowsChange = (ids: string[], checkboxState: boolean) => {
    if (props.selectedRows && props.setSelectedRows) {
      if (checkboxState) {
        const newSelectedRows = props.selectedRows;
        ids.forEach(
          (id) => (newSelectedRows[id] = EntityType.AccessReviewResourceUser)
        );
        props.setSelectedRows({ ...newSelectedRows });
      } else {
        const newSelectedRows = props.selectedRows;
        ids.forEach((id) => delete newSelectedRows[id]);
        props.setSelectedRows({ ...newSelectedRows });
      }
    }
  };

  // build checked row ids set
  const checkedRowIds = props.selectedRows
    ? new Set(Object.keys(props.selectedRows))
    : undefined;

  const selectAllChecked =
    Object.keys(props.selectedRows ?? {}).length ===
    props.accessReviewResource.numResourceUsers;

  const onSelectAllChange = (checkboxState: boolean) => {
    if (props.selectedRows && props.setSelectedRows) {
      if (checkboxState) {
        const newSelectedRows: Record<string, EntityType> = {};
        users.forEach((resourceUser) => {
          newSelectedRows[resourceUser.id] =
            EntityType.AccessReviewResourceUser;
        });
        props.setSelectedRows({ ...newSelectedRows });
      } else {
        props.setSelectedRows({});
      }
    }
  };

  return (
    <EmptyStateContentWrapper
      content={
        <ScrollableMuiVirtualTable
          columns={headers}
          rows={rows}
          defaultSortBy={"name"}
          defaultSortDirection={SortDirection.ASC}
          allRowsLoaded
          expandable
          checkedRowIds={checkedRowIds}
          onCheckedRowsChange={onCheckedRowsChange}
          selectAllChecked={selectAllChecked}
          onSelectAllChange={onSelectAllChange}
        />
      }
      isEmpty={users.length === 0}
      entityType={EntityType.User}
      title={`No users to review`}
      subtitle={
        navigationState.isOnlyMyReviewsFilterOn
          ? `No users have been assigned for you to review.`
          : `No users had direct access to this resource at the time of the access review.`
      }
    />
  );
};

type AccessReviewerResourceUsersCellProps = {
  accessReviewResource: AccessReviewResourcePreviewFragment;
  resourceUser: AccessReviewResourceUserPreviewFragment;
};

const AccessReviewerResourceUsersCell = (
  props: AccessReviewerResourceUsersCellProps
) => {
  const { accessReviewState } = useContext(AccessReviewContext);
  const [showReviewersModal, setShowReviewersModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { displaySuccessToast } = useToast();

  const [
    updateResourceUserReviewers,
    { loading },
  ] = useUpdateResourceUserReviewersMutation({});

  const canEditReviewers =
    accessReviewState.ongoingAccessReviewIdSet.has(
      props.accessReviewResource.accessReviewId
    ) && props.accessReviewResource.canEditReviewers;

  return (
    <>
      <AccessReviewReviewersCell
        itemType="resource"
        reviewerUsers={props.resourceUser.reviewerUsers}
        canEditReviewers={canEditReviewers}
        onClick={() => {
          setShowReviewersModal(true);
        }}
      />
      {showReviewersModal && (
        <AccessReviewerModal
          title={"Reviewers"}
          isModalOpen={showReviewersModal}
          onClose={() => {
            setShowReviewersModal(false);
            setErrorMessage("");
          }}
          loading={loading}
          onSubmit={async (reviewers) => {
            try {
              const { data } = await updateResourceUserReviewers({
                variables: {
                  input: {
                    accessReviewResourceUserId: props.resourceUser.id,
                    reviewers: reviewers,
                  },
                },
              });
              switch (data?.updateResourceUserReviewers.__typename) {
                case "UpdateResourceUserReviewersResult": {
                  displaySuccessToast("Success: reviewers updated");
                  setShowReviewersModal(false);
                  break;
                }
                case "AccessReviewAlreadyStoppedError": {
                  setErrorMessage(data?.updateResourceUserReviewers.message);
                  break;
                }
              }
            } catch (error) {
              logError(error, "failed to update access reviewers");
              setErrorMessage(
                getModifiedErrorMessage(
                  "Error: failed to update access reviewers",
                  error
                )
              );
            }
          }}
          errorMessage={errorMessage}
          entryInfos={getReviewerModalEntries(
            props.resourceUser.reviewerUsers,
            null
          )}
          canEditReviewers={canEditReviewers}
        />
      )}
    </>
  );
};

export default AccessReviewResourceUsersTable;
