import { getModifiedErrorMessage } from "api/ApiContext";
import {
  OwnerFragment,
  OwnersListDocument,
  useDeleteOwnerMutation,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Modal } from "components/ui";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { logError } from "utils/logging";

type OwnerDeleteModalProps = {
  owner: OwnerFragment;
  isOpen: boolean;
  onClose: () => void;
};

const OwnerDeleteModal = (props: OwnerDeleteModalProps) => {
  const history = useHistory();
  const [errorMessage, setErrorMessage] = useState("");
  const { displaySuccessToast } = useToast();
  const [deleteOwnerMutation, { loading }] = useDeleteOwnerMutation();

  const handleDelete = async () => {
    try {
      const { data } = await deleteOwnerMutation({
        variables: {
          input: {
            id: props.owner.id,
          },
        },
        refetchQueries: [
          "OwnersList",
          "OwnersTable", // Refetch the main owners list query on the current page
          {
            query: OwnersListDocument,
            variables: {
              input: {
                includeAll: true,
              },
            },
          }, // Refetch any cached owners dropdown queries
        ],
      });
      switch (data?.deleteOwner.__typename) {
        case "OwnerOwnsItemsError":
        case "OwnerReviewsItemsError":
          setErrorMessage(
            `${data.deleteOwner.message} ${data.deleteOwner.entities
              .map((e) => e.name)
              .join(", ")}`
          );
          break;
        case "DeleteOwnerResult":
          displaySuccessToast("Success: owner deleted");
          props.onClose();
          history.replace("/owners");
          break;
        default:
          logError(new Error(`failed to delete owner`));
          setErrorMessage(`Error: failed to delete owner`);
      }
    } catch (error) {
      logError(error, "failed to delete owner");
      setErrorMessage(
        getModifiedErrorMessage("Error: failed to delete owner", error)
      );
    }
  };

  return (
    <Modal
      title={`Delete ${props.owner.name}`}
      isOpen={props.isOpen}
      onClose={props.onClose}
    >
      <Modal.Body>
        {errorMessage ? (
          <ModalErrorMessage errorMessage={errorMessage} />
        ) : null}
        Are you sure you want to delete "{props.owner.name}"? This cannot be
        undone.
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Delete"
        onPrimaryButtonClick={handleDelete}
        primaryButtonLoading={loading}
      />
    </Modal>
  );
};

export default OwnerDeleteModal;
