import {
  AwsIdentityCenterImportSetting,
  CommonMetadataInput,
  ConnectionType,
  GroupTagPreviewFragment,
  GroupType,
  HrIdpStatus,
  ImportSetting,
  MessageChannelFragment,
  OnCallSchedule,
  OwnerPreviewSmallFragment,
  OwnerUserFragment,
  ResourceTagPreviewFragment,
  ResourceType,
  ReviewStageOperator,
  RiskSensitivityDetails,
  ThirdPartyProvider,
  UserDropdownPreviewFragment,
  UserIdentities,
  UserTagFragment,
  Visibility,
} from "api/generated/graphql";

export interface ReviewerStage {
  requireAdminApproval: boolean;
  requireManagerApproval: boolean;
  operator: ReviewStageOperator;
  ownerIds: string[];
}

export type FormMode = "view" | "edit";

// We use this prefix to differentiate between a client-side created request config
// and an existing request config from the server.
// When creating a new request config, we still temporarily need some non-index related id to
// identify the request config in the form, before an ID is auto-created by backend server.
// But we do not pass this client id to the backend server.
export const CLIENT_REQUEST_CONFIG_ID_PREFIX = "client-request-config-";

interface ConfigTemplateSmall {
  id: string;
  name: string;
}

export interface GroupBindingSmall {
  sourceGroupId: string;
  sourceGroupName: string;
  sourceGroupType?: GroupType;
}

export interface ResourceConfig {
  entityId?: string;
  name?: string;
  remoteName?: string;
  remoteId?: string;
  entityType?: ResourceType | GroupType;
  connectionId?: string;
  connectionType?: ConnectionType;
  connectionName?: string;
  connectionVisibility?: Visibility;
  connectionVisibilityGroups?: string[];
  description?: string;
  adminOwner: OwnerPreviewSmallFragment | null;
  visibility: Visibility;
  visibilityGroups?: string[];
  parentResourceId?: string;
  parentResourceName?: string;
  parentResourceType?: ResourceType;
  parentResourceVisibility?: Visibility;
  parentResourceVisibilityGroups?: string[];
  parentConfigurationTemplateId?: string;
  parentConfigurationTemplateName?: string;
  messageChannels?: MessageChannelFragment[];
  requestConfigs: ResourceRequestConfig[];
  requireMfaToApprove: boolean;
  requireMfaToConnect: boolean;
  onCallSchedules?: OnCallSchedule[];
  breakGlassUsers?: UserDropdownPreviewFragment[];
  tagIds?: string[];
  tagsWithSource?: Array<ResourceTagPreviewFragment | GroupTagPreviewFragment>;
  commonMetadata?: CommonMetadataInput;
  configurationTemplate?: ConfigTemplateSmall;
  useParentConfig?: boolean;
  childrenDefaultConfigTemplate?: ConfigTemplateSmall;
  forkConfigurationTemplates?: boolean;
  groupBinding?: GroupBindingSmall;
  ticketPropagation?: ResourceTicketPropagation;
  groupLeaderUsers?: UserDropdownPreviewFragment[];
  riskSensitivity?: RiskSensitivityDetails;
  customRequestNotification?: string;
}

export interface ResourceTicketPropagation {
  onGrant: boolean;
  onRevocation: boolean;
  ticketProvider?: {
    ticketProvider: ThirdPartyProvider;
    ticketProjectId: string;
    ticketProjectName: string;
  };
}

export interface ResourceRequestConfig {
  id: string;
  groupIds?: string[];
  roleIds?: string[];
  priority: number;
  isRequestable: boolean;
  autoApprove: boolean;
  reasonOptional: boolean;
  stageData: ReviewerStage[];
  maxDurationMin: number | null;
  recommendedDurationMin: number | null;
  requireSupportTicket: boolean;
  requestTemplateId: string | null;
  requireMfaToRequest: boolean;
}

export type OwnerSourceGroup = {
  id: string;
  name: string;
  groupType: GroupType;
};

export interface OwnerConfig {
  name: string;
  description: string;
  sourceGroup?: OwnerSourceGroup | null;
  enabledSourceGroup: boolean;
  escalationDurationMin?: number | null;
  reviewerMessageChannel?: MessageChannelFragment | null;
  users?: OwnerUserFragment[];
}

export interface UserConfig {
  email: string;
  secondaryEmails?: string[];
  manager?: UserDropdownPreviewFragment | null;
  editableManager: boolean;
  position?: string | null;
  team: string | null;
  hrIdpStatus?: HrIdpStatus | null;
  userAttributeTags?: UserTagFragment[] | null;
  userAttributeTagIds?: string[] | null;
  identities?: UserIdentities;
}

export interface ConnectionConfig {
  name: string;
  description: string;
  adminOwnerId?: string;
  adminOwnerName?: string;
  webhookEnabled: boolean;
  ticketProviderEnabled: boolean;
  ticketProvider?: ThirdPartyProvider;
  ticketProjectId?: string;
  ticketProjectName?: string;
  importSetting: ImportSetting;
  awsIdentityCenterImportSetting?: AwsIdentityCenterImportSetting;
  awsOrganizationImportSetting?: ImportSetting;
  autoImportGroupResources: boolean;
  visibility: Visibility;
  visibilityGroupIds?: string[];
  importVisibility: Visibility;
  importVisibilityGroups?: string[];
  childrenDefaultConfigTemplate?: {
    id: string;
    name: string;
  };
  importNotification?: {
    shouldNotify: boolean;
    recipientOwnerId?: string;
    recipientOwnerName?: string;
  };
}

export interface BundleConfig {
  name: string;
  description: string;
  adminOwner: OwnerPreviewSmallFragment;
  visibility: Visibility;
  visibilityGroupsIds: string[];
}
