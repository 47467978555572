import {
  AddGroupUserInput,
  GroupAccessLevel,
  GroupPreviewSmallFragment,
  useAddGroupUsersMutation,
  useGroupPreviewLazyQuery,
  useMultipleGroupAccessLevelsQuery,
  UserGroupFragment,
  UserPreviewSmallFragment,
} from "api/generated/graphql";
import GroupSearchDropdown, {
  mergeGroupSelectData,
  removeGroupSelectData,
} from "components/dropdown/GroupSearchDropdown";
import * as styles from "components/modals/AddItemModal.css";
import GroupRoleRow from "components/modals/GroupRoleRow";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { Modal, Skeleton } from "components/ui";
import _ from "lodash";
import { useState } from "react";
import useLogEvent from "utils/analytics";
import { groupTypeHasRoles } from "utils/directory/groups";
import { logError } from "utils/logging";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";

interface Props {
  user: UserPreviewSmallFragment;
  userGroups: UserGroupFragment[];
  onClose: () => void;
}

const UserAddGroupsModal = (props: Props) => {
  const [roleByGroupIdToAdd, setRoleByGroupIdToAdd] = useState<
    Record<string, GroupAccessLevel[]>
  >({});
  const [errorMessage, setErrorMessage] = useState("");
  const [rowErrors, setRowErrors] = useState<Record<string, string>>({}); // key is group id

  const logEvent = useLogEvent();
  const startPushTaskPoll = usePushTaskLoader();

  const [addGroupUsers, { loading }] = useAddGroupUsersMutation();

  const existingRolesByGroupId: Record<string, GroupAccessLevel[]> = {};
  props.userGroups.forEach((groupUser) => {
    if (!existingRolesByGroupId[groupUser.groupId]) {
      existingRolesByGroupId[groupUser.groupId] = [];
    }
    if (groupUser.access?.directAccessPoint?.accessLevel) {
      existingRolesByGroupId[groupUser.groupId] = _.uniqBy(
        [
          ...existingRolesByGroupId[groupUser.groupId],
          groupUser.access?.directAccessPoint?.accessLevel,
        ],
        "accessLevelRemoteId"
      );
    }
  });

  const [getGroupInfo] = useGroupPreviewLazyQuery();
  const {
    data,
    error: rolesError,
    loading: loadingRoles,
  } = useMultipleGroupAccessLevelsQuery({
    variables: {
      input: {
        groupIds: Object.keys(existingRolesByGroupId),
      },
    },
  });

  if (loadingRoles) {
    return (
      <Modal title="Add User to Groups" isOpen onClose={props.onClose}>
        <Modal.Body>
          <Skeleton height="48px" />
        </Modal.Body>
      </Modal>
    );
  }

  if (rolesError) {
    return (
      <Modal title="Add User to Groups" isOpen onClose={props.onClose}>
        <Modal.Body>
          <ModalErrorMessage errorMessage={rolesError.message} />
        </Modal.Body>
      </Modal>
    );
  }

  let allRoles;
  switch (data?.multipleGroupAccessLevels.__typename) {
    case "MultipleGroupAccessLevelsResult":
      allRoles = data.multipleGroupAccessLevels.results;
  }

  const allRolesByGroupId: Record<string, GroupAccessLevel[]> = {};
  allRoles?.forEach((role) => {
    allRolesByGroupId[role.groupId] = role.accessLevels ?? [];
  });

  const handleSubmit = async () => {
    logEvent({
      name: "apps_add_user_to_groups",
      properties: {
        numGroupsAddedTo: Object.entries(roleByGroupIdToAdd).length,
      },
    });
    try {
      const groupInputs: AddGroupUserInput[] = [];
      const newRowErrors: Record<string, string> = {};
      for (const [groupId, roles] of Object.entries(roleByGroupIdToAdd)) {
        const { data } = await getGroupInfo({
          variables: {
            input: {
              id: groupId,
            },
          },
        });
        let group: GroupPreviewSmallFragment | undefined;
        switch (data?.group.__typename) {
          case "GroupResult":
            group = data.group.group;
            break;
        }
        if (!group) {
          setErrorMessage("Failed to add user to groups");
          return;
        }

        if (roles.length === 0) {
          // If group requires a role, but none are selected,
          // show an error.
          if (groupTypeHasRoles(group.groupType)) {
            newRowErrors[groupId] = "Please select at least one role";
          } else {
            // If group does not require roles, omit the role.
            groupInputs.push({
              userId: props.user.id,
              groupId,
            });
          }
        }

        roles.forEach((role) => {
          groupInputs.push({
            userId: props.user.id,
            groupId,
            accessLevel: {
              accessLevelName: role.accessLevelName,
              accessLevelRemoteId: role.accessLevelRemoteId,
            },
          });
        });
      }

      if (Object.keys(newRowErrors).length > 0) {
        setRowErrors(newRowErrors);
        return;
      }

      const { data } = await addGroupUsers({
        variables: {
          input: {
            groupUsers: groupInputs,
          },
        },
        refetchQueries: ["UserGroups"],
        awaitRefetchQueries: true,
      });
      switch (data?.addGroupUsers.__typename) {
        case "AddGroupUsersResult": {
          startPushTaskPoll(data.addGroupUsers.taskId);
          props.onClose();
          break;
        }
        case "GroupUserAlreadyExists":
          setErrorMessage(data.addGroupUsers.message);
          break;
        default:
          logError(new Error(`failed to add user to groups`));
          setErrorMessage("Error: failed to add user to groups");
      }
    } catch (err) {
      logError(err, "Failed to add user to groups");
      setErrorMessage("Failed to add user to groups");
    }
  };

  // A user can only be added to a group with 1 role, so disable selecting
  // them if they have any access.
  const disabledGroupIds: string[] = props.userGroups.map(
    (groupUser) => groupUser.groupId
  );

  return (
    <Modal isOpen title="Add User to Groups" onClose={props.onClose}>
      <Modal.Body>
        {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
        <GroupSearchDropdown
          style="search"
          selectedGroupIds={Object.keys(roleByGroupIdToAdd)}
          onSelect={({ actionType, groups }) => {
            setRoleByGroupIdToAdd((prev) => {
              if (actionType === "select-option") {
                return mergeGroupSelectData(prev, groups);
              } else {
                return removeGroupSelectData(prev, groups);
              }
            });
          }}
          disabledGroupIds={disabledGroupIds}
        />
        <div className={styles.itemsContainer}>
          {Object.keys(roleByGroupIdToAdd).map((groupId) => {
            return (
              <GroupRoleRow
                key={groupId}
                groupId={groupId}
                existingRoles={existingRolesByGroupId[groupId] ?? []}
                roles={roleByGroupIdToAdd[groupId] ?? []}
                error={rowErrors[groupId]}
                onGroupRemove={() => {
                  setRoleByGroupIdToAdd((prev) => {
                    delete prev[groupId];
                    return { ...prev };
                  });
                }}
                onRoleSelect={(role) => {
                  setRoleByGroupIdToAdd((prev) => {
                    if (groupId in prev) {
                      return {
                        ...prev,
                        [groupId]: [...prev[groupId], role],
                      };
                    } else {
                      return {
                        ...prev,
                        [groupId]: [role],
                      };
                    }
                  });
                }}
                onRoleRemove={(role) => {
                  setRoleByGroupIdToAdd((prev) => {
                    return {
                      ...prev,
                      [groupId]: prev[groupId].filter(
                        (a) =>
                          a.accessLevelRemoteId !== role.accessLevelRemoteId
                      ),
                    };
                  });
                }}
              />
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Add Groups"
        primaryButtonDisabled={Object.keys(roleByGroupIdToAdd).length === 0}
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLoading={loading}
      />
    </Modal>
  );
};

export default UserAddGroupsModal;
