import { openInNewTab } from "api/common/common";
import { Button, Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";

import { ALERT_COLORS, NORMAL_COLORS } from "./common";
import * as styles from "./DashboardGraphCard.css";
import Graph, { GraphData } from "./Graph";

interface Props {
  title: string;
  description: string;
  alerting?: boolean;
  warning?: string;
  limit: number;
  teamToNotify: string;
  graphLabel: string;
  graphData: GraphData[];
}

const DashboardGraphCard = (props: Props) => {
  const {
    title,
    description,
    graphLabel,
    alerting,
    warning,
    limit,
    teamToNotify,
    graphData,
  } = props;

  let currentVal = 0;
  graphData.forEach((data) => (currentVal += data.data[data.data.length - 1]));

  return (
    <div className={styles.card}>
      <div style={{ height: "35%", display: "flex", flexDirection: "column" }}>
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
            alignItems: "center",
          })}
        >
          {alerting && <Icon name="alert-triangle" color="red600" size="sm" />}
          <div
            className={sprinkles({
              flexGrow: 1,
              fontSize: "labelLg",
              color: alerting ? "red1000" : undefined,
              fontWeight: "medium",
            })}
          >
            {title}
          </div>
          <div
            className={styles.insightsButton}
            onClick={() =>
              openInNewTab(
                "/insights?vd=JTdCJTIyc2VsZWN0aW9uJTIyJTNBJTdCJTIybXVsdGlSZXNvdXJjZVNlbGVjdGlvbnMlMjIlM0ElNUIlN0IlMjJjb25uZWN0aW9uSWQlMjIlM0ElMjI4MjRiZjVkYS1iNTk0LTQwYzUtOGU0Yy1mNzgzMWRlMTc4YzMlMjIlMkMlMjJyZXNvdXJjZVR5cGUlMjIlM0ElMjJBV1NfQUNDT1VOVCUyMiUyQyUyMm5hbWUlMjIlM0ElMjJBV1MlMjBPcmdhbml6YXRpb24lMjAlMkYlMjBBY2NvdW50JTIyJTdEJTVEJTdEJTJDJTIyYXR0cmlidXRlc1Nob3duJTIyJTNBJTdCJTdEJTJDJTIyYWNjZXNzVHlwZXMlMjIlM0ElNUIlMjJMT05HU1RBTkRJTkclMjIlNUQlMkMlMjJzaG93SGlnaGxpZ2h0ZWRPbmx5JTIyJTNBdHJ1ZSU3RA%3D%3D"
              )
            }
          >
            <Icon name="department" size="xs" />
          </div>
        </div>
        <div
          className={sprinkles({
            color: alerting ? "red600" : "blue600",
            fontSize: "displayLg",
          })}
        >
          {currentVal}
        </div>
        <div
          className={sprinkles({
            color: "gray600",
            fontSize: "bodyLg",
          })}
          style={{
            flexGrow: 1,
          }}
        >
          {description}
        </div>
        {warning && (
          <div className={styles.warningContainer}>
            <Icon name="trending-up" size="sm" />
            <div
              className={sprinkles({ color: "red1000", fontSize: "bodySm" })}
            >
              {warning}
            </div>
          </div>
        )}
      </div>

      <div className={styles.divider} />
      <div
        className={sprinkles({
          fontWeight: "medium",
          marginBottom: "lg",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        })}
      >
        {graphLabel}
        {props.graphData.length > 1 && (
          <div className={sprinkles({ display: "flex", gap: "md" })}>
            {props.graphData.map((data, i) => (
              <div
                className={sprinkles({
                  display: "flex",
                  gap: "xs",
                  alignItems: "center",
                })}
              >
                <div
                  style={{
                    backgroundColor: alerting
                      ? ALERT_COLORS[i]
                      : NORMAL_COLORS[i],
                    width: 16,
                    height: 16,
                    borderRadius: "50%",
                  }}
                />
                {data.label}
              </div>
            ))}
          </div>
        )}
      </div>
      <div style={{ position: "relative", height: "45%" }}>
        <Graph
          limit={props.limit}
          alerting={props.alerting}
          datasets={props.graphData}
        />
      </div>
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: "md",
          fontSize: "labelMd",
          marginTop: "md",
        })}
      >
        {`At ${limit} grants, notify the ${teamToNotify}`}
        <Button label="Edit" size="sm" leftIconName="edit-2" borderless />
      </div>
    </div>
  );
};

export default DashboardGraphCard;
