import {
  AccessReviewPreviewFragment,
  EntityType,
  IncompleteAccessReviewerFragment,
} from "api/generated/graphql";
import { SendReminderModal } from "components/modals/update/SendReminderModal";
import MaterialTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MaterialTable";
import { Label, Modal } from "components/ui";
import { useState } from "react";

interface AccessReviewIncompleteReviewersModalProps {
  isOpen: boolean;
  onClose: () => void;
  accessReview: AccessReviewPreviewFragment;
  incompleteReviewers: IncompleteAccessReviewerFragment[];
}

interface TableRow {
  reviewer: string;
  incompleteResources: number;
  incompleteGroups: number;
  incompleteConnections: number;
}

const AccessReviewIncompleteReviewersModal = (
  props: AccessReviewIncompleteReviewersModalProps
) => {
  const [sendReminderModalOpen, setSendReminderModalOpen] = useState(false);

  const headers: Header<TableRow>[] = [
    {
      id: "reviewer",
      label: "Reviewer",
    },
    {
      id: "incompleteResources",
      label: "Incomplete Resources",
    },
    {
      id: "incompleteGroups",
      label: "Incomplete Groups",
    },
    {
      id: "incompleteConnections",
      label: "Incomplete Apps",
    },
  ];

  const rows: CellRow<TableRow>[] = props.incompleteReviewers.map(
    (reviewer) => {
      const fullName = reviewer.user?.fullName ?? "--";
      return {
        id: reviewer.userId,
        data: {
          reviewer: {
            value: fullName,
            element: (
              <Label
                label={fullName}
                entityType={EntityType.User}
                icon={{
                  type: "src",
                  icon: reviewer.user?.avatarUrl,
                }}
              />
            ),
          },
          incompleteResources: {
            value: reviewer.incompleteResources,
            element: <>{reviewer.incompleteResources}</>,
          },
          incompleteGroups: {
            value: reviewer.incompleteGroups,
            element: <>{reviewer.incompleteGroups}</>,
          },
          incompleteConnections: {
            value: reviewer.incompleteConnections,
            element: <>{reviewer.incompleteConnections}</>,
          },
        },
      };
    }
  );

  return (
    <>
      {sendReminderModalOpen && (
        <SendReminderModal
          accessReview={props.accessReview}
          onClose={() => setSendReminderModalOpen(false)}
        />
      )}
      <Modal
        isOpen={props.isOpen}
        onClose={props.onClose}
        title="Reviewers with incomplete reviews"
        maxWidth={false}
      >
        <Modal.Body>
          <MaterialTable headers={headers} rows={rows} />
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Close"
          onPrimaryButtonClick={props.onClose}
          secondaryButtonLabel="Send reminder"
          onSecondaryButtonClick={() => {
            props.onClose();
            setSendReminderModalOpen(true);
          }}
        />
      </Modal>
    </>
  );
};

export default AccessReviewIncompleteReviewersModal;
