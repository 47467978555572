import { GroupType, useGroupBindingsFiltersQuery } from "api/generated/graphql";
import GroupSearchDropdown from "components/dropdown/GroupSearchDropdown";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import {
  Button,
  DataElement,
  DataElementList,
  Input,
  Select,
} from "components/ui";
import TableFilters from "components/ui/table/TableFilters";
import sprinkles from "css/sprinkles.css";
import { useEffect, useState } from "react";
import useLogEvent from "utils/analytics";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import {
  useGroupBindingsFilterDispatch,
  useGroupBindingsFilterState,
} from "views/group_bindings/GroupBindingsContext";

import * as styles from "./GroupBindingsFilters.css";

const GroupBindingsFilters = () => {
  const {
    connectionIds,
    groupIds,
    groupTypes,
    searchQuery: filterSearchQuery,
  } = useGroupBindingsFilterState();
  const dispatch = useGroupBindingsFilterDispatch();
  const logEvent = useLogEvent();
  const [searchQuery, setSearchQuery] = useState(filterSearchQuery);
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 300);

  useEffect(() => {
    setSearchQuery(filterSearchQuery);
  }, [filterSearchQuery]);

  useEffect(() => {
    dispatch({
      type: "SET_SEARCH_QUERY",
      payload: {
        searchQuery: debouncedSearchQuery,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchQuery]);

  const { data, loading, error } = useGroupBindingsFiltersQuery({
    variables: {
      groupIds,
    },
  });

  if (error) {
    logError(error, "Error loading group bindings filters");
  }

  const allConnections = data?.connections.connections ?? [];
  const unselectedConnections = allConnections.filter(
    (connection) => !connectionIds.includes(connection.id)
  );
  const groups = data?.groups.groups ?? [];
  const availableGroupTypes =
    data?.groupTypesWithCounts.groupTypesWithCounts ?? [];

  const hasFilters =
    connectionIds.length > 0 || groupIds.length > 0 || groupTypes.length > 0;

  return (
    <div className={styles.filtersLayout}>
      <TableFilters>
        <TableFilters.Left>
          <div className={styles.searchInput}>
            <Input
              type="search"
              style="search"
              leftIconName="search"
              placeholder="Filter"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
            />
          </div>
          <Select
            loading={loading}
            options={unselectedConnections}
            onChange={(connection) => {
              if (connection) {
                logEvent({
                  name: "group_bindings_filter_select",
                  properties: {
                    filterType: "app",
                    filterValue: connection.id,
                  },
                });
                dispatch({
                  type: "SET_CONNECTION_IDS",
                  payload: {
                    ids: [...connectionIds, connection.id],
                  },
                });
              }
            }}
            getOptionLabel={(connection) => connection.name}
            getIcon={(connection) => ({
              type: "entity",
              entityType: connection.connectionType,
            })}
            placeholder="Filter by app"
            placeholderIcon={{ type: "name", icon: "dots-grid" }}
            selectOnly
            size="sm"
          />
          <Select
            loading={loading}
            options={availableGroupTypes}
            onChange={(groupType) => {
              if (groupType) {
                logEvent({
                  name: "group_bindings_filter_select",
                  properties: {
                    filterType: "group_type",
                    filterValue: groupType.groupType,
                  },
                });
                dispatch({
                  type: "SET_GROUP_TYPES",
                  payload: {
                    types: [...groupTypes, groupType.groupType],
                  },
                });
              }
            }}
            getOptionLabel={(groupType) =>
              getGroupTypeInfo(groupType.groupType)?.name ?? groupType.groupType
            }
            placeholder="Filter by group type"
            getIcon={(groupType) => ({
              type: "src",
              icon: getGroupTypeInfo(groupType.groupType)?.icon,
            })}
            selectOnly
            size="sm"
          />
          <GroupSearchDropdown
            selectedGroupIds={groupIds}
            onSelect={({ actionType, groups }) => {
              if (actionType === "remove-option") {
                dispatch({
                  type: "SET_GROUP_IDS",
                  payload: {
                    ids: groupIds.filter(
                      (id: string) => !groups.map((g) => g.id).includes(id)
                    ),
                  },
                });
              } else {
                logEvent({
                  name: "group_bindings_filter_select",
                  properties: {
                    filterType: "group",
                    filterValue: groups.map((g) => g.id).join(", "),
                  },
                });
                dispatch({
                  type: "SET_GROUP_IDS",
                  payload: {
                    ids: [...groupIds, ...groups.map((g) => g.id)],
                  },
                });
              }
            }}
            placeholder="Filter by group"
            placeholderIcon={{ type: "name", icon: "users" }}
            alwaysShowPlaceholder
            size="sm"
          />
          {hasFilters && (
            <Button
              label="Clear filters"
              outline
              onClick={() => {
                dispatch({
                  type: "CLEAR_FILTERS",
                });
              }}
            />
          )}
        </TableFilters.Left>
      </TableFilters>
      {hasFilters && (
        <div className={sprinkles({ display: "flex" })}>
          <DataElementList>
            {connectionIds.map((connectionID) => {
              const connection = allConnections.find(
                (o) => o.id === connectionID
              );
              if (!connection) {
                return null;
              }
              return (
                <DataElement
                  label={connection.name}
                  color="blue"
                  size="sm"
                  leftIcon={{
                    data: {
                      type: "entity",
                      entityType: connection.connectionType,
                    },
                  }}
                  rightIcon={{
                    name: "x",
                    onClick: () => {
                      dispatch({
                        type: "SET_CONNECTION_IDS",
                        payload: {
                          ids: connectionIds.filter(
                            (id) => id !== connectionID
                          ),
                        },
                      });
                    },
                  }}
                />
              );
            })}
            {groupTypes.map((groupType) => {
              const groupTypeInfo = getGroupTypeInfo(groupType as GroupType);
              return (
                <DataElement
                  label={groupTypeInfo?.name ?? groupType}
                  color="green"
                  size="sm"
                  leftIcon={{
                    data: {
                      type: "src",
                      icon: groupTypeInfo?.icon,
                    },
                  }}
                  rightIcon={{
                    name: "x",
                    onClick: () => {
                      dispatch({
                        type: "SET_GROUP_TYPES",
                        payload: {
                          types: groupTypes.filter(
                            (type) => type !== groupType
                          ),
                        },
                      });
                    },
                  }}
                />
              );
            })}
            {groupIds.map((groupID) => {
              const group = groups.find((o) => o.id === groupID);
              if (!group) {
                return null;
              }
              return (
                <DataElement
                  label={group.name}
                  color="pink"
                  size="sm"
                  leftIcon={{
                    data: {
                      type: "entity",
                      entityType: group.groupType,
                    },
                  }}
                  rightIcon={{
                    name: "x",
                    onClick: () => {
                      dispatch({
                        type: "SET_GROUP_IDS",
                        payload: {
                          ids: groupIds.filter((id) => id !== groupID),
                        },
                      });
                    },
                  }}
                />
              );
            })}
          </DataElementList>
        </div>
      )}
    </div>
  );
};

export default GroupBindingsFilters;
