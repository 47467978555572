import {
  AccessReviewConnectionPreviewFragment,
  AccessReviewGroupPreviewFragment,
  AccessReviewResourcePreviewFragment,
  AccessReviewUserPreviewFragment,
} from "api/generated/graphql";

export const getAccessReviewEntityUrl = (
  entity:
    | AccessReviewResourcePreviewFragment
    | AccessReviewGroupPreviewFragment
    | AccessReviewConnectionPreviewFragment
    | AccessReviewUserPreviewFragment
) => {
  switch (entity.__typename) {
    case "AccessReviewResource":
      return getAccessReviewResourceUrl(entity.id);
    case "AccessReviewGroup":
      return getAccessReviewGroupUrl(entity.id);
    case "AccessReviewConnection":
      return getAccessReviewConnectionUrl(entity.id);
    case "AccessReviewUser":
      return getAccessReviewUserUrl(entity.accessReviewId, entity.userId);
  }
  return "";
};

export const getAccessReviewUrl = (accessReviewId: string) => {
  return `/access-reviews/${accessReviewId}`;
};

export const getAccessReviewResourceUrl = (accessReviewResourceId: string) => {
  return `/access-reviews/r/${accessReviewResourceId}`;
};

export const getAccessReviewGroupUrl = (accessReviewGroupId: string) => {
  return `/access-reviews/g/${accessReviewGroupId}`;
};

export const getAccessReviewConnectionUrl = (
  accessReviewConnectionId: string
) => {
  return `/access-reviews/c/${accessReviewConnectionId}`;
};

export const getAccessReviewUserUrl = (
  accessReviewId: string,
  userId: string
) => {
  return `/access-reviews/u/${accessReviewId}/${userId}`;
};
