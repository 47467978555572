// AuthCodeCallback is a dedicated component for handling the auth redirect
// and parsing/removing the auth token fragment from the URL.
import { useMfaCallbackMutation } from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import React, { useContext } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useMountEffect } from "utils/hooks";
import { getMfaState } from "utils/mfa/mfa";
import FullPageLoading from "views/loading/FullPageLoading";

import { AuthResultStatus } from "../../utils/auth/AuthClient";

export const AuthCodeCallbackAuthed = () => {
  const history = useHistory();
  const location = useLocation();

  const { authState } = useContext(AuthContext);

  const [mfaCallback] = useMfaCallbackMutation();

  // handle initializing auth flow
  useMountEffect(() => {
    if (!authState.isAuthenticated) {
      return;
    }

    async function handleMfaFlow(
      state: string,
      code: string,
      nextLocalUrl: string
    ) {
      try {
        const { data } = await mfaCallback({
          variables: {
            input: {
              code: code,
              state: state,
            },
          },
        });
        switch (data?.mfaCallback.__typename) {
          case "MfaCallbackResult":
            history.replace(nextLocalUrl + "?mfa=success");
            break;
        }
      } catch (error) {
        // In the case of an error we will still redirect and add a query param
        // to indicate that the mfa flow failed and we'll let the page handle
        // how to interpret that
        history.replace(nextLocalUrl + "?mfa=failure");
      }
    }

    const query = new URLSearchParams(location.search);
    const nextLocalUrl = query.get("next");
    const state = query.get("state");
    const code = query.get("code");
    const error = query.get("error");
    const expectedState = getMfaState();

    if (state && code && state === expectedState) {
      handleMfaFlow(state, code, nextLocalUrl || "/");
    } else if (error && error === AuthResultStatus.AccessDenied) {
      // The Auth0 MFA Action denied access since a specific factor is enforced for the org.
      history.replace(
        decodeURIComponent(nextLocalUrl || "/") + "?mfa=access_denied"
      );
    } else {
      // We've successfully authenticated, so we should redirect
      // back to home to clear the URL auth fragments or follow the
      // `next` param
      if (nextLocalUrl) {
        history.replace(decodeURIComponent(nextLocalUrl));
      } else {
        history.replace("/");
      }
    }
  });

  return <FullPageLoading />;
};

export default AuthCodeCallbackAuthed;
