import { Icon } from "components/ui";

import * as styles from "./ZoomControls.css";

interface ZoomControlsProps {
  zoom: number;
  onZoomIn: () => void;
  onZoomOut: () => void;
  onZoomReset: () => void;
}

const ZoomControls = (props: ZoomControlsProps) => {
  return (
    <div className={styles.zoomControlsContainer}>
      <div className={styles.zoomButton} onClick={props.onZoomIn}>
        <Icon name="plus" />
      </div>
      <div className={styles.zoomLabel} onClick={props.onZoomReset}>
        <div className={styles.zoomValue}>{`${Math.round(
          props.zoom * 100
        )}%`}</div>
        <div className={styles.zoomReset}>Reset</div>
      </div>
      <div className={styles.zoomButton} onClick={props.onZoomOut}>
        <Icon name="minus" />
      </div>
    </div>
  );
};

export default ZoomControls;
