import { TagPill } from "components/pills/TagPills";
import { DataElement, Icon, Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";

import { ConfigTemplateTag } from "./TemplateMappingsView";

type ConfirmDeleteTemplateMappingModalProps = {
  mappingToDelete?: ConfigTemplateTag;
  deleteMappings: () => void;
  setShowConfirmDeleteModal: (show: boolean) => void;
};

const ConfirmDeleteTemplateMappingModal = ({
  mappingToDelete,
  deleteMappings,
  setShowConfirmDeleteModal,
}: ConfirmDeleteTemplateMappingModalProps) => {
  return (
    <Modal
      title="Remove tag mapping"
      isOpen
      onClose={() => {
        setShowConfirmDeleteModal(false);
      }}
    >
      <Modal.Body>
        <p>Are you sure you want to delete this mapping?</p>
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
          })}
        >
          {mappingToDelete?.tag ? (
            <TagPill tagId={mappingToDelete.tag.id} tag={mappingToDelete.tag} />
          ) : (
            <p>Tag: none</p>
          )}
          <div>
            <Icon name="arrow-right" />
          </div>
          {mappingToDelete?.configTemplate ? (
            <DataElement
              color="blue"
              leftIcon={{ name: "template" }}
              label={mappingToDelete.configTemplate.name}
              size="sm"
            />
          ) : (
            <p>Configuration template: none</p>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel={"Delete"}
        onPrimaryButtonClick={deleteMappings}
      />
    </Modal>
  );
};

export default ConfirmDeleteTemplateMappingModal;
