import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AuthType,
  ConnectionType,
  Maybe,
  useCreateConnectionMutation,
  Visibility,
} from "api/generated/graphql";
import githubLogo from "assets/logos/github-logo.png";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { Button, FormGroup, Input } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import {
  CreateConnectionComponents as CreateConnectionComponentsV2,
  CreateConnectionView as CreateConnectionViewV2,
} from "views/connections/create/CreateConnectionComponents";
import {
  CreateConnectionComponentsV3,
  CreateConnectionViewV3,
} from "views/connections/create/CreateConnectionComponentsV3";
import VisibilitySelector from "views/visibility/VisibilitySelector";

const CreateGitHub = () => {
  return <CreateGitHubForm />;
};

const CreateGitHubForm = () => {
  const history = useHistory();
  const hasAppLevelVisibility = useFeatureFlag(FeatureFlag.AppLevelVisibility);
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const logEvent = useLogEvent();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [name, setName] = useState("");
  const [orgName, setOrgName] = useState("");
  const [description, setDescription] = useState("");
  const [visibility, setVisibility] = useState<Visibility>(Visibility.Global);
  const [visibilityGroupIds, setVisibilityGroupIds] = useState<
    string[] | undefined
  >([]);
  const [adminToken, setAdminToken] = useState("");
  const [clientID, setClientID] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const [adminOwnerId, setAdminOwnerId] = useState<string | undefined>(
    undefined
  );

  const [createConnectionMutation, { loading }] = useCreateConnectionMutation({
    refetchQueries: ["AppsListColumn", "Connections"],
  });

  const fieldUnset =
    orgName === "" || description === "" || adminToken === "" || !adminOwnerId;

  const onSubmit = async () => {
    try {
      const { data } = await createConnectionMutation({
        variables: {
          input: {
            name: name,
            description: description,
            connectionType: ConnectionType.GitHub,
            adminOwnerId: adminOwnerId ?? "",
            visibility: visibility,
            visibilityGroupIds: visibilityGroupIds ?? [],
            importVisibility: Visibility.Global,
            metadata: {
              connectionType: ConnectionType.GitHub,
              gitHub: {
                orgName: orgName,
              },
            },
            credentials: {
              authType: AuthType.GitHub,
              gitHub: {
                adminToken: adminToken,
                clientId: clientID,
                clientSecret: clientSecret,
              },
            },
          },
        },
      });
      switch (data?.createConnection.__typename) {
        case "CreateConnectionResult":
          history.replace(
            getResourceUrl(
              EntityTypeDeprecated.Connection,
              data.createConnection.connection.id
            )
          );
          displaySuccessToast(`Success: GitHub app created`);
          logEvent({
            name: "apps_create_click",
            properties: {
              connectionType: ConnectionType.GitHub,
            },
          });

          break;
        case "ConnectionExistsError":
        case "UserFacingError":
          logWarning(new Error(data.createConnection.message));
          setErrorMessage(data.createConnection.message);
          break;
        default:
          logError(new Error(`app creation failed`));
          setErrorMessage(`Error: app creation failed`);
      }
    } catch (error) {
      logError(error, "app creation failed");
      setErrorMessage(
        getModifiedErrorMessage("Error: app creation failed", error)
      );
    }
  };

  const CreateConnectionComponents = hasV3
    ? CreateConnectionComponentsV3
    : CreateConnectionComponentsV2;
  const CreateConnectionView = hasV3
    ? CreateConnectionViewV3
    : CreateConnectionViewV2;

  return (
    <CreateConnectionView
      logo={githubLogo}
      title={"Add your GitHub organization"}
      onSubmit={onSubmit}
      submitDisabled={fieldUnset}
      submitLoading={loading}
    >
      <>
        <CreateConnectionComponents
          title={"Step 1"}
          subtitle={
            "Create a GitHub organization admin account and admin personal access token"
          }
        >
          <>
            <p>
              {`Opal requires a GitHub organization admin account to manage your GitHub organization on your behalf.`}
            </p>
            <br />
            <p>
              {`To learn more about setting up an admin account,`}{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  "https://docs.opal.dev/docs/github#step-1---create-a-github-organization-admin-account-and-admin-personal-access-token"
                }
              >
                check out our official documentation here
              </a>
              .
            </p>
            <br />
            <p>
              {`Create a new GitHub account, and make it the admin of your GitHub organization.
                Then generate a personal access token, following instructions from`}{" "}
              {
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    " https://docs.github.com/en/free-pro-team@latest/github/authenticating-to-github/creating-a-personal-access-token#creating-a-token"
                  }
                >
                  this link
                </a>
              }
              {`. When creating the personal access token, the "repo" and "admin:org" scopes should be checked off, with everything else unchecked.
                Record this access token, which will be input into the "Admin Token" field in Step 3.`}
            </p>
          </>
        </CreateConnectionComponents>
        <CreateConnectionComponents
          title={"Step 2"}
          subtitle={"Create a GitHub organization Oauth App"}
        >
          <>
            <p>
              {`Opal requires an Oauth app in your GitHub organization for matching GitHub
                accounts with Opal user accounts.`}
            </p>
            <br />
            <p>
              {`To learn more about setting up an Oauth app,`}{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  "https://docs.opal.dev/docs/github#step-2---create-a-github-organization-oauth-app"
                }
              >
                check out our official documentation here
              </a>
              .
            </p>
            <br />
            <p>
              {`Instructions for creating a GitHub Oauth app for your organization
                can be found at `}{" "}
              {
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    "https://docs.github.com/en/free-pro-team@latest/developers/apps/creating-an-oauth-app"
                  }
                >
                  this link
                </a>
              }
              {"."}
              <br />
              <br />
              {`During the Oauth app creation process, for "Application Name", you can enter "Opal" or any other name you prefer.
                    For "Homepage URL", enter the domain name for your Opal instance.
                    For "Authorization callback URL", enter your domain name, followed by "/callback/github" (e.g.,
                    https://app.opal.dev/callback/github).`}
              <br />
              <br />
              {`After your app is created, record the Client ID and generate a new client secret. Record the generated client secret.
                These will be input into the "Client ID" and "Client Secret" fields in Step 3.`}
            </p>
          </>
        </CreateConnectionComponents>
        <CreateConnectionComponents
          title={"Step 3"}
          subtitle={"Fill in details about your GitHub organization"}
        >
          <div>
            <FormGroup label="App name:">
              <Input
                onChange={setName}
                placeholder="Identifiable name of the GitHub connection."
                value={name}
              />
            </FormGroup>
            <FormGroup label="Organization name:">
              <Input
                onChange={setOrgName}
                placeholder="The name of the GitHub organization."
                value={orgName}
              />
            </FormGroup>
            <FormGroup label="App admin:">
              <OwnerDropdown
                selectedOwnerId={adminOwnerId}
                onSelectOwner={(owner) => setAdminOwnerId(owner?.id)}
                placeholder="Select an owner to own this app."
              />
            </FormGroup>
            <FormGroup label="Description:">
              <Input
                onChange={setDescription}
                placeholder="A brief description of the account to further inform people requesting access to it."
                value={description}
              />
            </FormGroup>
            {hasAppLevelVisibility && (
              <FormGroup label="Visibility:">
                <VisibilitySelector
                  visibility={visibility}
                  onChangeVisibility={(vis) => {
                    if (vis == Visibility.Global) {
                      setVisibility(Visibility.Global);
                      setVisibilityGroupIds([]);
                    } else {
                      setVisibility(Visibility.Team);
                    }
                  }}
                  visibilityGroups={visibilityGroupIds ?? []}
                  onChangeVisibilityGroups={setVisibilityGroupIds}
                />
              </FormGroup>
            )}
            <FormGroup label="Admin token:">
              <Input
                onChange={setAdminToken}
                placeholder="The personal access token of the GitHub admin account created in Step 1."
                value={adminToken}
                type="password"
              />
            </FormGroup>
            <FormGroup label="Client ID:">
              <Input
                onChange={setClientID}
                placeholder="The client ID of the Oauth app created in Step 2."
                value={clientID}
              />
            </FormGroup>
            <FormGroup label="Client secret:">
              <Input
                onChange={setClientSecret}
                placeholder="The client secret of the Oauth app created in Step 2."
                value={clientSecret}
                type="password"
              />
            </FormGroup>
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
            {!hasV3 && (
              <div
                className={sprinkles({
                  display: "flex",
                  justifyContent: "flex-end",
                })}
              >
                <Button
                  type="primary"
                  disabled={fieldUnset}
                  label={"Create"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </div>
            )}
          </div>
        </CreateConnectionComponents>
        <CreateConnectionComponents
          title={"Step 4"}
          subtitle={"Link GitHub identities to Opal accounts"}
          isLast
        >
          <>
            <p>
              {`Opal's GitHub integration requires an extra step of linking each GitHub user identity to an Opal account.
              To learn more,`}{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  "https://docs.opal.dev/docs/github#step-4---link-github-identities-to-opal-accounts"
                }
              >
                check out our official documentation here
              </a>
              .
            </p>
          </>
        </CreateConnectionComponents>
      </>
    </CreateConnectionView>
  );
};

export default CreateGitHub;
