import { FormMode } from "components/forms/common";
import { Checkbox, Input } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

interface CustomRequestNotificationRowProps {
  mode: FormMode;
  customRequestNotification: string | undefined;
  onChange?: (val: string | undefined) => void;
}

const CustomRequestNotificationRow = (
  props: CustomRequestNotificationRowProps
) => {
  const { mode, customRequestNotification, onChange } = props;
  const [includeCustomNotification, setIncludeCustomNotification] = useState(
    (customRequestNotification && customRequestNotification.length > 0) || false
  );
  const isCustomRequestNotificationsEnabled = useFeatureFlag(
    FeatureFlag.CustomRequestNotifications
  );

  if (!isCustomRequestNotificationsEnabled) {
    return null;
  }

  return (
    <div className={sprinkles({ marginBottom: "md" })}>
      <Checkbox
        label="Include custom notification text with approvals"
        checked={includeCustomNotification}
        onChange={(val) => {
          setIncludeCustomNotification(val);
          if (onChange && !val) {
            onChange(undefined);
          }
        }}
        disabled={mode === "view"}
      />
      {includeCustomNotification && (
        <div className={sprinkles({ marginY: "md" })}>
          <Input
            value={customRequestNotification}
            onChange={(val) => {
              if (onChange) {
                onChange(val);
              }
            }}
            placeholder="Enter custom notification text"
            type="textarea"
            rows={3}
            maxLength={500}
            disabled={mode === "view"}
          />
        </div>
      )}
    </div>
  );
};

export default CustomRequestNotificationRow;
