import { EntityType } from "api/generated/graphql";
import ConfigurationTemplateDropdown from "components/configuration_templates/ConfigurationTemplateDropdown";
import { Checkbox, FormGroup, FormRow, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import React from "react";
import { Link } from "react-router-dom";
import { getResourceUrlNew } from "utils/common";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { AppsContext } from "views/apps/AppsContext";

import { FormMode, ResourceConfig } from "../common";

interface Props {
  mode: FormMode;
  configurationTemplate?: ResourceConfig["configurationTemplate"];
  onChange: (configurationTemplate?: { name: string; id: string }) => void;
  useParentConfig: boolean;
  onChangeUseParentConfig: (useParentConfig: boolean) => void;
  showParentConfigSettings?: boolean;
  clearable?: boolean;
  placeholder?: string;
  isV3?: boolean;
  includeTitle?: boolean;
}

const ConfigurationTemplateRow = (props: Props) => {
  const { includeTitle = true } = props;
  const { bulkMode } = useContext(AppsContext);
  const allowUseParentConfig = useFeatureFlag(FeatureFlag.UseParentConfig);
  const showParentSettings =
    props.showParentConfigSettings && allowUseParentConfig;

  const viewContent = props.configurationTemplate?.id ? (
    <div
      className={sprinkles({
        display: "flex",
        alignItems: "flex-start",
      })}
    >
      {showParentSettings && props.useParentConfig && (
        <Tooltip
          tooltipText={
            props.useParentConfig
              ? "This resource is using the configuration template from its parent."
              : undefined
          }
        >
          <span
            className={sprinkles({
              display: "flex",
              alignItems: "center",
              marginRight: "xs",
            })}
          >
            Inheriting from parent:
          </span>
        </Tooltip>
      )}
      <Link
        to={getResourceUrlNew(
          {
            entityId: props.configurationTemplate.id,
            entityType: EntityType.ConfigurationTemplate,
          },
          EntityType.ConfigurationTemplate
        )}
      >
        <span className={sprinkles({ fontWeight: "semibold" })}>
          {props.configurationTemplate.name}
        </span>
      </Link>
    </div>
  ) : (
    "--"
  );

  const editContent = (
    <>
      {showParentSettings && (
        <div
          className={sprinkles({
            paddingBottom: "md",
            display: "flex",
            flexDirection: "column",
          })}
        >
          <Checkbox
            label="Inherit template from parent"
            checked={props.useParentConfig}
            onChange={() =>
              props.onChangeUseParentConfig(!props.useParentConfig)
            }
          />
        </div>
      )}
      <div>
        <ConfigurationTemplateDropdown
          selectedConfigurationTemplateId={props.configurationTemplate?.id}
          onSelectConfigurationTemplate={props.onChange}
          clearable={!showParentSettings || !props.useParentConfig}
          disabled={showParentSettings && props.useParentConfig}
          placeholder={
            props.placeholder ??
            (bulkMode === "edit" ? "Leave unchanged" : "None")
          }
        />
      </div>
    </>
  );

  if (!includeTitle) {
    return (
      <div>
        <p>
          Link a single configuration to many resources and groups. Changing the
          template will override all settings below to the values set in the
          template.
        </p>
        {props.mode === "view" ? viewContent : editContent}
      </div>
    );
  }

  const infoText = `Link a single configuration to many resources and groups. Changing the template will override all settings below to the values set in the template.`;
  if (props.isV3) {
    if (props.mode === "view") {
      return (
        <FormRow
          title="Configuration Template"
          tooltipText={infoText}
          showToolTipIcon
        >
          {viewContent}
        </FormRow>
      );
    } else {
      return (
        <FormGroup label="Configuration Template" infoTooltip={infoText}>
          {editContent}
        </FormGroup>
      );
    }
  }

  return (
    <FormRow title="Configuration Template">
      <p>
        Link a single configuration to many resources and groups. Changing the
        template will override all settings below to the values set in the
        template.
      </p>
      {props.mode === "view" ? viewContent : editContent}
    </FormRow>
  );
};

export default ConfigurationTemplateRow;
