import {
  AccessReviewFragment,
  AccessReviewPreviewFragment,
  AccessReviewStatsFragment,
  FiltersMatchMode,
  OngoingAccessReviewTabStatsFragment,
} from "api/generated/graphql";
import AssignmentPolicy from "components/access_reviews/AssignmentPolicy";
import ReminderNotifications from "components/access_reviews/ReminderNotifications";
import ConnectionsFilter from "components/access_reviews/scope/ConnectionsFilter";
import EntitiesIndividualFilter from "components/access_reviews/scope/EntitiesIndividualFilter";
import EntitiesNamedFilter from "components/access_reviews/scope/EntitiesNamedFilter";
import EntitiesWithAdminFilter from "components/access_reviews/scope/EntitiesWithAdminFilter";
import EntityTypesFilter from "components/access_reviews/scope/EntityTypesFilter";
import TagFilter from "components/access_reviews/scope/TagFilter";
import UsersFilter from "components/access_reviews/scope/UsersFilter";
import AuthContext from "components/auth/AuthContext";
import { Divider, FormGroup } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { Maybe } from "graphql/jsutils/Maybe";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { timeZones } from "utils/time";

import AccessReviewIncompleteReviewersModal from "./AccessReviewIncompleteReviewersModal";
import AccessReviewTilesV3 from "./AccessReviewTilesV3";
import { getDeadlineProgressBarInfo } from "./common/utils";
import { getHasFilters } from "./create/utils";

type AccessReviewStatsV3Props = {
  accessReview: AccessReviewPreviewFragment;
  // Stats for total number of items that still need to be reviewed
  accessReviewStats: Maybe<AccessReviewStatsFragment>;
  // Stats for how many items current user has been assigned that still need to be reviewed
  userReviewsStats?: OngoingAccessReviewTabStatsFragment;
};

const AccessReviewStatsV3 = (props: AccessReviewStatsV3Props) => {
  const { accessReviewStats, userReviewsStats } = props;
  const { authState } = useContext(AuthContext);
  const isAssigner = authState.user?.isAdmin || authState.user?.isAuditor;
  const hasNHIs = useFeatureFlag(FeatureFlag.NonHumanIdentities);

  const [
    incompleteReviewersModalOpen,
    setIncompleteReviewersModalOpen,
  ] = useState(false);

  const {
    numReviewersIncomplete = 0,
    numReviewersTotal = 0,
    numUserAssignmentsTotal = 0,
    numUserAssignmentsInReview = 0,
    numUserAssignmentsCompleted = 0,
    numGroupResourceAssignmentsTotal = 0,
    numGroupResourceAssignmentsInReview = 0,
    numGroupResourceAssignmentsCompleted = 0,
    numNHIAssignmentsTotal = 0,
    numNHIAssignmentsInReview = 0,
    numNHIAssignmentsCompleted = 0,
  } = accessReviewStats || {};

  const numAssignmentsTotal =
    numUserAssignmentsTotal +
    numGroupResourceAssignmentsTotal +
    (hasNHIs ? numNHIAssignmentsTotal : 0);
  const numAssignmentsInReview =
    numUserAssignmentsInReview +
    numGroupResourceAssignmentsInReview +
    (hasNHIs ? numNHIAssignmentsInReview : 0);
  const numAssignmentsCompleted =
    numUserAssignmentsCompleted +
    numGroupResourceAssignmentsCompleted +
    (hasNHIs ? numNHIAssignmentsCompleted : 0);
  const numCompleteReviewers = numReviewersTotal - numReviewersIncomplete;

  const assignmentsPercentWithReviewers =
    Math.round((numAssignmentsInReview / numAssignmentsTotal) * 100) || 0;

  const assignmentsPercentCompleted =
    Math.round((numAssignmentsCompleted / numAssignmentsTotal) * 100) || 0;

  const reviewersPercentComplete =
    Math.round((numCompleteReviewers / numReviewersTotal) * 100) || 0;

  let deadlineInfo = getDeadlineProgressBarInfo(props.accessReview);

  const userTotalItemsToReview = userReviewsStats
    ? userReviewsStats?.numAppsToReview +
      userReviewsStats?.numGroupsToReview +
      userReviewsStats?.numResourcesToReview
    : 0;
  const userReviewDescriptionParts = [];
  if (userReviewsStats?.numResourcesToReview) {
    userReviewDescriptionParts.push(
      `${pluralize("resource", userReviewsStats?.numResourcesToReview, true)}`
    );
  }
  if (userReviewsStats?.numGroupsToReview) {
    userReviewDescriptionParts.push(
      `${pluralize("group", userReviewsStats?.numGroupsToReview, true)}`
    );
  }
  if (userReviewsStats?.numAppsToReview) {
    userReviewDescriptionParts.push(
      `${pluralize("app", userReviewsStats?.numAppsToReview, true)}`
    );
  }
  const userItemsToReviewDescription = userReviewDescriptionParts.length
    ? `${userReviewDescriptionParts.join(", ")} left to review`
    : "All reviews complete";

  const incompleteReviewers = accessReviewStats?.incompleteReviewers ?? [];
  // Close incomplete reviewers modal if there are no incomplete reviewers
  if (incompleteReviewers.length === 0 && incompleteReviewersModalOpen) {
    setIncompleteReviewersModalOpen(false);
  }

  return (
    <>
      <AccessReviewTilesV3
        tileInfos={[
          {
            key: "num-assignments-with-reviewers",
            header: `${numAssignmentsInReview}/${numAssignmentsTotal}`,
            subHeader: `${pluralize(
              `reviewers`,
              numAssignmentsTotal
            )} assigned`,
            progressPercentage: assignmentsPercentWithReviewers,
            hide: !isAssigner,
            description: [
              hasNHIs
                ? `${
                    numUserAssignmentsTotal - numUserAssignmentsInReview
                  } user, ${
                    numGroupResourceAssignmentsTotal -
                    numGroupResourceAssignmentsInReview
                  } group and ${
                    numNHIAssignmentsTotal - numNHIAssignmentsInReview
                  } NHI access points need a reviewer`
                : `${
                    numUserAssignmentsTotal - numUserAssignmentsInReview
                  } user and ${
                    numGroupResourceAssignmentsTotal -
                    numGroupResourceAssignmentsInReview
                  } group access points need a reviewer`,
            ],
          },
          {
            key: "num-reviews-completed",
            header: `${numAssignmentsCompleted}/${numAssignmentsTotal}`,
            subHeader: `${pluralize(`reviews`, numAssignmentsTotal)} completed`,
            progressPercentage: assignmentsPercentCompleted,
            hide: !isAssigner,
            description: [
              hasNHIs
                ? `${
                    numUserAssignmentsTotal - numUserAssignmentsCompleted
                  } user, ${
                    numGroupResourceAssignmentsTotal -
                    numGroupResourceAssignmentsCompleted
                  } group and ${
                    numNHIAssignmentsTotal - numNHIAssignmentsCompleted
                  } NHI access points left to review`
                : `${
                    numUserAssignmentsTotal - numUserAssignmentsCompleted
                  } user and ${
                    numGroupResourceAssignmentsTotal -
                    numGroupResourceAssignmentsCompleted
                  } group access points left to review`,
            ],
          },
          {
            key: "incomplete-reviewers",
            header: `${numCompleteReviewers}/${numReviewersTotal}`,
            subHeader: `${pluralize(
              `reviewers`,
              numReviewersTotal,
              false
            )} finished`,
            progressPercentage: reviewersPercentComplete,
            hide: !isAssigner,
            description: [
              `${reviewersPercentComplete}% have completed all their assigned reviews`,
            ],
            onClick:
              incompleteReviewers.length > 0
                ? () => setIncompleteReviewersModalOpen(true)
                : undefined,
          },
          {
            key: "incomplete-reviews",
            header: `${userTotalItemsToReview}`,
            subHeader: `${pluralize(
              `item`,
              userTotalItemsToReview
            )} left to review`,
            description: [userItemsToReviewDescription],
            hide: isAssigner,
          },
          {
            key: "days-since",
            header: deadlineInfo.daysToShow,
            subHeader: deadlineInfo.diffText,
            progressPercentage: deadlineInfo.progressPercentage,
            description: [deadlineInfo.dateText],
          },
        ]}
      />
      <AccessReviewIncompleteReviewersModal
        isOpen={incompleteReviewersModalOpen}
        onClose={() => setIncompleteReviewersModalOpen(false)}
        accessReview={props.accessReview}
        incompleteReviewers={incompleteReviewers}
      />
    </>
  );
};

type AccessReviewOverviewV3Props = {
  ongoingAccessReview: AccessReviewFragment;
  userReviewsStats?: OngoingAccessReviewTabStatsFragment;
};

const AccessReviewOverviewV3 = (props: AccessReviewOverviewV3Props) => {
  const { filters } = props.ongoingAccessReview;
  const assignmentPolicy = props.ongoingAccessReview.reviewerAssignmentPolicy;
  const selfReviewAllowed = props.ongoingAccessReview.selfReviewAllowed;
  return (
    <>
      <div
        className={sprinkles({
          marginBottom: "lg",
        })}
      >
        <AccessReviewStatsV3
          accessReview={props.ongoingAccessReview}
          accessReviewStats={props.ongoingAccessReview.stats}
          userReviewsStats={props.userReviewsStats}
        />
      </div>
      <p className={sprinkles({ fontSize: "textLg" })}>Scope</p>
      {filters && getHasFilters(filters) ? (
        <>
          {filters.userIDs.length > 0 && (
            <FormGroup
              label="Users Included in this Access Review"
              fontWeight="bold"
              fontSize="textMd"
            >
              <UsersFilter userIDs={filters.userIDs} />
            </FormGroup>
          )}
          <div
            className={sprinkles({
              marginBottom: "md",
              fontWeight: "bold",
              fontSize: "textMd",
            })}
          >
            Include entities that match{" "}
            <span>
              {filters.matchMode === FiltersMatchMode.All ? "ALL" : "ANY"}
            </span>{" "}
            of the following filters:
          </div>
          <EntitiesNamedFilter names={filters.names} />
          <EntityTypesFilter
            resourceTypes={filters.resourceTypes}
            groupTypes={filters.groupTypes}
          />
          <ConnectionsFilter connectionIDs={filters.connectionIDs} />
          <EntitiesWithAdminFilter adminIDs={filters.adminIDs} />
          <TagFilter tags={filters.tags} />
          <EntitiesIndividualFilter entityIds={filters.entityIDs} />
        </>
      ) : (
        <>This access review included all items at its creation.</>
      )}
      <Divider />
      <p className={sprinkles({ fontSize: "textLg" })}>Notifications</p>
      <>
        <ReminderNotifications
          reminderSchedule={props.ongoingAccessReview.reminderSchedule}
          reminderTimeOfDay={
            new Date(props.ongoingAccessReview.reminderTimeOfDay)
          }
          reminderIncludeManager={
            props.ongoingAccessReview.reminderIncludeManager
          }
          timezone={timeZones.find(
            (tz) => tz.name === props.ongoingAccessReview.timeZone
          )}
        />
        <>
          New review notifications:{" "}
          {props.ongoingAccessReview.sendReviewerAssignmentNotification
            ? "On"
            : "Off"}
        </>
        <Divider />
      </>
      <p>Reviews</p>
      <>
        <AssignmentPolicy assignmentPolicy={assignmentPolicy} />
        <FormGroup
          label={`Self-review: ${
            selfReviewAllowed ? "Allowed" : "Not Allowed"
          }`}
        />
      </>
    </>
  );
};

export default AccessReviewOverviewV3;
