import {
  AuthFlowType,
  GroupOnCallSchedulePreviewSmallFragment,
  IntegrationType,
  Maybe,
  ThirdPartyIntegrationFragment,
  ThirdPartyProvider,
} from "api/generated/graphql";
import clsx from "clsx";
import { AppTile } from "components/integrations/AppTile";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import pluralize from "pluralize";
import React, { useState } from "react";
import integrationsStyles from "views/settings/OrgIntegrations.module.scss";
import { ThirdPartyCodeFlowAuthorizer } from "views/settings/third_party_authorizer/ThirdPartyCodeFlowAuthorizer";
import { ThirdPartyLinkFlowAuthorizer } from "views/settings/third_party_authorizer/ThirdPartyLinkFlowAuthorizer";
import { ThirdPartyManualFlowAuthorizer } from "views/settings/third_party_authorizer/ThirdPartyManualFlowAuthorizer";

import { ThirdPartyProviderOnCallSchedulesModal } from "../modals/update/ThirdPartyProviderOnCallSchedulesModal";

export type IntegrationInfo = {
  name: string;
  logo: string;
  thirdPartyProvider: ThirdPartyProvider;
  authFlowType: AuthFlowType;
};

export type OrgIntegrationAppProps = {
  integrationInfo: IntegrationInfo;
  integrations: ThirdPartyIntegrationFragment[];
  disabled: boolean;
  groupOnCallSchedules?: GroupOnCallSchedulePreviewSmallFragment[];
};

export const OrgIntegrationApp = (props: OrgIntegrationAppProps) => {
  const [openThirdPartyProvider, setOpenThirdPartyProvider] = useState<
    Maybe<ThirdPartyProvider>
  >(null);
  const [
    showOnCallSchedulesModal,
    setOnCallSchedulesModal,
  ] = useState<boolean>();

  const isConnected = !!props.integrations.find(
    (integration) =>
      integration.thirdPartyProvider ===
      props.integrationInfo.thirdPartyProvider
  );
  const hasLinkedOnCallSchedules = isConnected && !!props.groupOnCallSchedules;
  const numSchedules = _(props.groupOnCallSchedules)
    .uniqBy(
      (groupOnCallSchedules) => groupOnCallSchedules.onCallSchedule?.remoteId
    )
    .value().length;
  const numGroups = _(props.groupOnCallSchedules)
    .uniqBy((groupOnCallSchedules) => groupOnCallSchedules.group?.id)
    .value().length;

  let notes: React.ReactNode[] = [];

  if (isConnected) {
    for (const integration of props.integrations) {
      if (
        integration.thirdPartyProvider ===
        props.integrationInfo.thirdPartyProvider
      ) {
        if (integration.connectionActive !== null) {
          notes.push(integration.connectionActive ? "Active" : "Inactive");
        }

        if (
          integration.slackMetadata &&
          integration.slackMetadata.enterpriseId
        ) {
          notes.push(
            <>
              Installed in the <b>{integration.slackMetadata.enterpriseName}</b>{" "}
              organization in{" "}
              {integration.slackMetadata.workspaces.length > 0 ? (
                integration.slackMetadata.workspaces
                  .slice(0, 3)
                  .map<React.ReactNode>((w) => (
                    <span key={w.id} title={w.id}>
                      {w.name}
                    </span>
                  ))
                  .reduce((prev, curr) => [prev, ", ", curr])
              ) : (
                <>0 workspaces</>
              )}
              {integration.slackMetadata.workspaces.length > 3
                ? `, and ${
                    integration.slackMetadata.workspaces.length - 3
                  } more workspaces`
                : undefined}
              . Add or remove workspaces{" "}
              <a
                className={sprinkles({ textDecoration: "underline" })}
                target="_blank"
                href={`https://app.slack.com/manage/${integration.slackMetadata.enterpriseId}/integrations/profile/${integration.slackMetadata.appId}`}
              >
                here
              </a>
              .
            </>
          );
        }
      }
    }
  }

  let connectionStatusText: React.ReactChild = isConnected
    ? "Connected"
    : "No connection";
  if (isConnected && notes.length > 0) {
    connectionStatusText = (
      <>
        {connectionStatusText} (
        {notes.reduce((prev, curr) => [prev, ", ", curr])})
      </>
    );
  }

  let authorizer = null;
  switch (props.integrationInfo.authFlowType) {
    case AuthFlowType.Link:
      authorizer = (
        <ThirdPartyLinkFlowAuthorizer
          integrationType={IntegrationType.Org}
          integrationInfo={props.integrationInfo}
          isConnected={isConnected}
          connectionButtonDisabled={props.disabled}
          openThirdPartyProvider={openThirdPartyProvider}
          setOpenThirdPartyProvider={setOpenThirdPartyProvider}
        />
      );
      break;
    case AuthFlowType.Code:
      authorizer = (
        <ThirdPartyCodeFlowAuthorizer
          integrationType={IntegrationType.Org}
          integrationInfo={props.integrationInfo}
          isConnected={isConnected}
          connectionButtonDisabled={props.disabled}
          openThirdPartyProvider={openThirdPartyProvider}
          setOpenThirdPartyProvider={setOpenThirdPartyProvider}
        />
      );
      break;
    case AuthFlowType.Manual:
      authorizer = (
        <ThirdPartyManualFlowAuthorizer
          integrationType={IntegrationType.Org}
          integrationInfo={props.integrationInfo}
          isConnected={isConnected}
          connectionButtonDisabled={props.disabled}
          openThirdPartyProvider={openThirdPartyProvider}
          setOpenThirdPartyProvider={setOpenThirdPartyProvider}
        />
      );
      break;
  }

  return (
    <div>
      <div className={integrationsStyles.appInfo}>
        <div className={integrationsStyles.leftContent}>
          <AppTile logo={props.integrationInfo.logo} />
          <div className={integrationsStyles.text}>
            <div className={integrationsStyles.appName}>
              {props.integrationInfo.name}
            </div>
            <div
              className={sprinkles({ display: "flex", flexDirection: "row" })}
            >
              <div className={integrationsStyles.subtitle}>
                <div
                  className={clsx({
                    [integrationsStyles.subtitleStatus]: true,
                    [integrationsStyles.statusDisconnected]: !isConnected,
                    [integrationsStyles.statusConnected]: isConnected,
                  })}
                />
                <div className={integrationsStyles.subtitleText}>
                  {connectionStatusText}
                </div>
              </div>
              {hasLinkedOnCallSchedules && (
                <div className={integrationsStyles.attributesLink}>
                  <div
                    className={integrationsStyles.subtitleText}
                    onClick={() => {
                      setOnCallSchedulesModal(true);
                    }}
                  >
                    {`${pluralize(
                      "on-call schedules",
                      numSchedules,
                      true
                    )} linked to ${pluralize("groups", numGroups, true)}`}
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={integrationsStyles.rightContent}>{authorizer}</div>
      </div>
      {showOnCallSchedulesModal && (
        <ThirdPartyProviderOnCallSchedulesModal
          isOpen={showOnCallSchedulesModal}
          onClose={() => setOnCallSchedulesModal(false)}
          groupOnCallSchedules={props.groupOnCallSchedules}
          thirdPartyProvider={props.integrationInfo.thirdPartyProvider}
        />
      )}
    </div>
  );
};
