import {
  BundleItemFragment,
  useDeleteBundleResourcesMutation,
} from "api/generated/graphql";
import * as styles from "components/modals/AddItemModal.css";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Checkbox, EntityIcon, Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { logError } from "utils/logging";

interface Props {
  bundleId: string;
  bundleItems: BundleItemFragment[];
  onClose: () => void;
}

const BundleRemoveResourcesModal = (props: Props) => {
  const [resourcesToRemove, setResourcesToRemove] = useState<
    { resourceId: string; roleRemoteId: string }[]
  >([]);
  const [error, setError] = useState("");

  const { displaySuccessToast } = useToast();
  const [
    deleteBundleResources,
    { loading },
  ] = useDeleteBundleResourcesMutation();

  const handleSubmit = async () => {
    try {
      const { data } = await deleteBundleResources({
        variables: {
          input: {
            inputs: resourcesToRemove.map(({ resourceId, roleRemoteId }) => ({
              bundleId: props.bundleId,
              resourceId,
              accessLevelRemoteId: roleRemoteId,
            })),
          },
        },
        refetchQueries: ["BundleItems", "Bundle"],
      });

      if (data?.deleteBundleResources) {
        displaySuccessToast("Success: removed resources from bundles");
        props.onClose();
      } else {
        setError("Failed to remove resources from bundle");
      }
    } catch (err) {
      logError("Failed to remove resources from bundles");
    }
  };

  return (
    <Modal isOpen title="Remove Resources" onClose={props.onClose}>
      <Modal.Body>
        {error && <ModalErrorMessage errorMessage={error} />}
        <div className={styles.itemsContainer}>
          {props.bundleItems.map((bundleItem) => {
            if (!bundleItem.resource) {
              return null;
            }
            const resource = bundleItem.resource;
            return (
              <div className={styles.itemRow}>
                <EntityIcon type={resource.resourceType} />
                <div className={sprinkles({ flexGrow: 1 })}>
                  <div className={sprinkles({ fontSize: "labelMd" })}>
                    {resource.name}{" "}
                    {bundleItem.accessLevelName &&
                      `(${bundleItem.accessLevelName})`}
                  </div>
                  <div
                    className={sprinkles({
                      color: "gray500",
                      fontSize: "labelSm",
                    })}
                  >
                    {resource.connection?.name}{" "}
                    {resource.parentResource &&
                      `/ ${resource.parentResource?.name}`}
                  </div>
                </div>
                <Checkbox
                  size="sm"
                  checked={resourcesToRemove.some(
                    ({ resourceId, roleRemoteId }) =>
                      resourceId === resource.id &&
                      roleRemoteId === bundleItem.accessLevelRemoteId
                  )}
                  onChange={(checked) => {
                    setResourcesToRemove((prev) => {
                      if (checked) {
                        return [
                          ...prev,
                          {
                            resourceId: resource.id,
                            roleRemoteId: bundleItem.accessLevelRemoteId,
                          },
                        ];
                      } else {
                        return prev.filter(
                          ({ resourceId, roleRemoteId }) =>
                            resourceId !== resource.id &&
                            roleRemoteId !== bundleItem.accessLevelRemoteId
                        );
                      }
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Remove"
        primaryButtonDisabled={resourcesToRemove.length === 0}
        primaryButtonLoading={loading}
        onPrimaryButtonClick={handleSubmit}
      />
    </Modal>
  );
};

export default BundleRemoveResourcesModal;
