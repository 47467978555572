import {
  AccessFragment,
  AccessLevel,
  EntityType,
  GroupType,
  Maybe,
  ResourceType,
} from "api/generated/graphql";
import AccessPathLabel from "components/label/AccessPathLabel";
import {
  AccessPointsLabel,
  EntityForAccessLabel,
  getEntityTypeForAccessLabelEntity,
  getNameForAccessLabelEntity,
} from "components/label/AccessPointsLabel";
import EventLabel from "components/label/item_labels/EventLabel";
import {
  ResourceLabel,
  TimeLabel,
  timeValueIndefinite,
} from "components/label/Label";
import MaterialTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MaterialTable";
import sprinkles from "css/sprinkles.css";
import moment from "moment";

type RoleAssignmentAccessPointsLabelProps = {
  accessPathsInfo: AccessPathsInfo;
  subject?: Maybe<EntityForAccessLabel>;
  object?: Maybe<EntityForAccessLabel>;
  role?: AccessLevel | null;
  isNotClickable?: boolean;
};

export const RoleAssignmentAccessPointsLabel = (
  props: RoleAssignmentAccessPointsLabelProps
) => {
  const headers: Header<AccessPointRow>[] = [
    { id: "expirationDate", label: "Expires" },
    { id: "event", label: "Event" },
    { id: "accessPath", label: "Access Path" },
  ];

  const numAccessPoints = props.accessPathsInfo.accessPointRows.length;
  if (numAccessPoints === 0) {
    return <div>{"--"}</div>;
  }

  return (
    <AccessPointsLabel
      subject={props.subject}
      object={props.object}
      roleName={props.role?.accessLevelName}
      hasDirectAccess={props.accessPathsInfo.hasDirectAccess}
      numAccessPoints={numAccessPoints}
      numInheritedAccessPoints={props.accessPathsInfo.numInheritedAccessPoints}
      numGroupAccessPoints={props.accessPathsInfo.numGroupAccessPoints}
      accessPathContent={
        <div className={sprinkles({ padding: "sm" })}>
          <MaterialTable<AccessPointRow>
            headers={headers}
            rows={props.accessPathsInfo.accessPointRows}
            denseFormatting={true}
            tableContainerStyles={{ maxHeight: 300 }}
          />
        </div>
      }
      isNotClickable={props.isNotClickable}
    />
  );
};

export type AccessPathsInfo = {
  accessPointRows: CellRow<AccessPointRow>[];
  numInheritedAccessPoints: number;
  numGroupAccessPoints: number;
  hasDirectAccess: boolean;
};

interface AccessPointRow {
  expirationDate: string;
  event: string;
  accessPath: string;
}

const expirationColMinWidth = "60px";
const accessPathColMinWidth = "400px";
const accessPathColMaxWidth = "800px";

type RoleAssignmentAccessInfo = {
  subject: Maybe<EntityForAccessLabel> | undefined;
  access: Maybe<AccessFragment> | undefined;
  object: Maybe<EntityForAccessLabel> | undefined;
};

export const getRoleAssignmentAccessPathsInfo = (
  props: RoleAssignmentAccessInfo
) => {
  const accessPointRows: CellRow<AccessPointRow>[] = [];
  const directAccess = props.access?.directAccessPoint;

  if (directAccess) {
    const expirationTime = directAccess.expiration
      ? moment(new Date(directAccess.expiration))
      : null;
    accessPointRows.push({
      id: directAccess.entityId,
      data: {
        expirationDate: {
          value:
            expirationTime?.unix() || `${directAccess.entityId}-expiration`,
          sortValue: expirationTime?.unix() || timeValueIndefinite,
          element: (
            <TimeLabel
              time={expirationTime}
              supportTicket={directAccess.supportTicket}
            />
          ),
          style: { minWidth: expirationColMinWidth },
        },
        event: {
          value: directAccess.eventId ?? "",
          element: <EventLabel eventId={directAccess.eventId ?? ""} />,
        },
        accessPath: {
          value: `${directAccess.entityId}-access-path`,
          element: (
            <AccessPathLabel
              accessLabels={[
                <ResourceLabel
                  entityTypeNew={getEntityTypeForAccessLabelEntity(
                    props.subject
                  )}
                  entityId={props.subject?.id}
                  text={getNameForAccessLabelEntity(props.subject)}
                  avatar={
                    props.subject?.__typename === "User"
                      ? props.subject?.avatarUrl
                      : undefined
                  }
                  resourceType={
                    props.subject?.__typename === "Resource"
                      ? props.subject.resourceType
                      : undefined
                  }
                  groupType={
                    props.subject?.__typename === "Group"
                      ? props.subject.groupType
                      : undefined
                  }
                  maxChars={32}
                />,
                <ResourceLabel
                  entityTypeNew={getEntityTypeForAccessLabelEntity(
                    props.object
                  )}
                  entityId={props.object?.id}
                  text={getNameForAccessLabelEntity(props.object)}
                  resourceType={
                    directAccess.entityType === EntityType.Resource
                      ? (directAccess.entityItemType as ResourceType)
                      : undefined
                  }
                  groupType={
                    directAccess.entityType === EntityType.Group
                      ? (directAccess.entityItemType as GroupType)
                      : undefined
                  }
                  maxChars={32}
                />,
              ]}
              maxWidth={accessPathColMaxWidth}
            />
          ),
          style: { minWidth: accessPathColMinWidth },
        },
      },
    });
  }

  props.access?.inheritedAccessPoints.forEach((inheritedAccess) => {
    const expirationTime = inheritedAccess.expiration
      ? moment(new Date(inheritedAccess.expiration))
      : null;
    const id = inheritedAccess.entityId || "unknown-inherited";

    accessPointRows.push({
      id: id,
      data: {
        expirationDate: {
          value: expirationTime?.unix() || `${id}-expiration`,
          sortValue: expirationTime?.unix() || timeValueIndefinite,
          element: (
            <TimeLabel
              time={expirationTime}
              supportTicket={inheritedAccess.supportTicket}
              useExpiringLabel
            />
          ),
          style: { minWidth: expirationColMinWidth },
        },
        event: {
          value: inheritedAccess.eventId ?? "",
          element: <EventLabel eventId={inheritedAccess.eventId ?? ""} />,
        },
        accessPath: {
          value: `${id}-access-path`,
          element: (
            <AccessPathLabel
              accessLabels={[
                <ResourceLabel
                  entityTypeNew={getEntityTypeForAccessLabelEntity(
                    props.subject
                  )}
                  entityId={props.subject?.id}
                  avatar={
                    props.subject?.__typename === "User"
                      ? props.subject?.avatarUrl
                      : undefined
                  }
                  resourceType={
                    props.subject?.__typename === "Resource"
                      ? props.subject.resourceType
                      : undefined
                  }
                  groupType={
                    props.subject?.__typename === "Group"
                      ? props.subject.groupType
                      : undefined
                  }
                  text={getNameForAccessLabelEntity(props.subject)}
                  maxChars={32}
                />,
                <ResourceLabel
                  entityTypeNew={inheritedAccess.entityType}
                  entityId={id}
                  text={inheritedAccess.entityName}
                  resourceType={
                    inheritedAccess.entityType === EntityType.Resource
                      ? (inheritedAccess.entityItemType as ResourceType)
                      : undefined
                  }
                  groupType={
                    inheritedAccess.entityType === EntityType.Group
                      ? (inheritedAccess.entityItemType as GroupType)
                      : undefined
                  }
                  maxChars={32}
                />,
                <ResourceLabel
                  entityTypeNew={getEntityTypeForAccessLabelEntity(
                    props.object
                  )}
                  entityId={props.object?.id}
                  text={getNameForAccessLabelEntity(props.object)}
                  resourceType={
                    props.object?.__typename === "Resource"
                      ? props.object.resourceType
                      : undefined
                  }
                  groupType={
                    props.object?.__typename === "Group"
                      ? props.object.groupType
                      : undefined
                  }
                  maxChars={32}
                />,
              ]}
              maxWidth={accessPathColMaxWidth}
            />
          ),
          style: { minWidth: accessPathColMinWidth },
        },
      },
    });
  });

  return {
    accessPointRows,
    hasDirectAccess: !!directAccess,
    numInheritedAccessPoints: props.access?.inheritedAccessPoints.length || 0,
    numGroupAccessPoints: 0,
  };
};
