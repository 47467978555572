import {
  ConfigurationTemplateFragment,
  useDeleteConfigurationTemplateMutation,
  useUpdateConfigurationTemplateMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import { FormMode, ResourceConfig } from "components/forms/common";
import ResourcesConfigForm from "components/forms/ResourcesConfigForm";
import {
  getResourceConfigChangedFields,
  makeConfigForTemplate,
  makeUpdateInputForTemplate,
  validateResourceConfig,
} from "components/forms/utils";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Banner, Button, Divider, Input, Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import OrgContext from "views/settings/OrgContext";

interface ConfigurationTemplatesColumnProps {
  configurationTemplate: ConfigurationTemplateFragment;
}

const ConfigurationTemplatesColumn = (
  props: ConfigurationTemplatesColumnProps
) => {
  const history = useHistory();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const { displaySuccessToast } = useToast();
  const [errors, setErrors] = useState<string[]>([]);
  const [newName, setNewName] = useState(props.configurationTemplate.name);
  const [mode, setMode] = useState<FormMode>("view");
  const [config, setConfig] = useState<Partial<ResourceConfig>>({});
  const [initialConfig, setInitialConfig] = useState<Partial<ResourceConfig>>();
  const { orgState } = useContext(OrgContext);
  const { authState } = useContext(AuthContext);

  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);

  const [
    updateConfigurationTemplate,
    { loading: saveLoading },
  ] = useUpdateConfigurationTemplateMutation();
  const [
    deleteConfigurationTemplate,
    { loading: deleteLoading },
  ] = useDeleteConfigurationTemplateMutation();

  const configurationTemplate = props.configurationTemplate;

  useEffect(() => {
    if (!configurationTemplate) return;
    const templateConfig = makeConfigForTemplate(configurationTemplate);
    // Something in ResourcesConfigFormV2 is causing this parent component to refetch the template
    // which causes this effect to get rerun, resetting configV2 to the initial config.
    // TODO: figure out why this is happening and fix it, but for now this is a workaround.
    // It's possible it might not be an issue once we add a resolver for requestConfigs.
    if (
      Object.keys(config).length === 0 ||
      config.entityId !== configurationTemplate.id
    ) {
      setConfig(templateConfig);
    }
    setInitialConfig(templateConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [configurationTemplate]);

  if (!configurationTemplate) {
    return null;
  }

  const menuOptions: PropsFor<typeof ColumnHeader>["menuOptions"] = [
    {
      label: "Rename",
      onClick: () => setShowRenameModal(true),
      icon: { type: "name", icon: "edit-3" },
    },
    {
      label: "Delete",
      onClick: () => setShowDeleteModal(true),
      icon: { type: "name", icon: "trash" },
    },
  ];

  const handleRename = async () => {
    try {
      const { data } = await updateConfigurationTemplate({
        variables: {
          input: {
            id: props.configurationTemplate.id,
            name: newName,
          },
        },
        refetchQueries: [
          "GetConfigurationTemplate",
          "ListConfigurationsTemplates",
        ],
      });
      switch (data?.updateConfigurationTemplate.__typename) {
        case "UpdateConfigurationTemplateResult":
          displaySuccessToast("Configuration template name updated.");
          setShowRenameModal(false);
          setErrors([]);
          break;
        case "ConfigurationTemplateNameExistsError":
          setErrors([data.updateConfigurationTemplate.message]);
          logError(new Error(data.updateConfigurationTemplate.message));
          break;
        default:
          setErrors(["Error: failed to update configuration template name."]);
          logError(
            new Error(
              "failed to update configuration template name for: " +
                props.configurationTemplate.id
            )
          );
      }
    } catch (error) {
      setErrors(["Error: failed to update configuration template name."]);
      logError(
        error,
        "failed to update configuration configuration name for: " +
          props.configurationTemplate.id
      );
    }
  };

  const handleDelete = async () => {
    try {
      const { data } = await deleteConfigurationTemplate({
        variables: {
          id: props.configurationTemplate.id,
        },
        refetchQueries: ["ListConfigurationsTemplates"],
      });
      switch (data?.deleteConfigurationTemplate.__typename) {
        case "ConfigurationInUseError":
          setErrors([data.deleteConfigurationTemplate.message]);
          break;
        case "DeleteConfigurationTemplateResult":
          displaySuccessToast("Configuration template deleted.");
          history.push("/templates/configurations");
          break;
        default:
          setErrors(["Error: failed to delete configuration template."]);
          logError(new Error("failed to delete configuration template"));
      }
    } catch (error) {
      setErrors(["Error: failed to delete configuration template."]);
      logError(error, "failed to delete configuration template");
    }
  };

  const handleSave = async () => {
    if (!configurationTemplate || !initialConfig) return;

    const errors = validateResourceConfig(config, orgState);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    try {
      setErrors([]);
      const changedConfig = getResourceConfigChangedFields(
        initialConfig,
        config
      );

      const { data } = await updateConfigurationTemplate({
        variables: {
          input: makeUpdateInputForTemplate(
            configurationTemplate.id,
            changedConfig
          ),
        },
      });

      switch (data?.updateConfigurationTemplate.__typename) {
        case "UpdateConfigurationTemplateResult":
          setMode("view");
          setErrors([]);
          displaySuccessToast("Successfully updated configuration template");
          break;
        case "ConfigurationTemplateNameExistsError":
          setErrors([data.updateConfigurationTemplate.message]);
          break;
        default:
          logError("Failed to update configuration template");
          setErrors(["Failed to update configuration template."]);
      }
    } catch (err) {
      logError(err, "Failed to update configuration template");
      setErrors(["Failed to update configuration template."]);
    }
  };

  const hasChanges = !_.isEqual(config, initialConfig);
  const canEdit = authState.user?.isAdmin;

  const headerButtons =
    mode === "view" ? (
      <Button
        label="Edit"
        leftIconName="edit"
        borderless
        onClick={() => setMode("edit")}
        size="md"
      />
    ) : (
      <div className={sprinkles({ display: "flex", gap: "sm" })}>
        <Button
          label="Cancel"
          onClick={() => {
            setErrors([]);
            setMode("view");
            if (initialConfig) {
              setConfig(initialConfig);
            }
          }}
          disabled={saveLoading}
          borderless
          size="md"
        />
        <Button
          label={saveLoading ? "Saving..." : "Save"}
          leftIconName="check"
          type="primary"
          onClick={handleSave}
          disabled={saveLoading || !hasChanges}
          size="md"
        />
      </div>
    );

  return (
    <>
      <ColumnHeader
        title={configurationTemplate.name}
        breadcrumbs={
          hasV3Nav
            ? [
                {
                  name: "Configuration Templates",
                  to: "/templates/configurations",
                },
              ]
            : undefined
        }
        icon={{ type: "name", icon: "template" }}
        rightActions={canEdit ? headerButtons : undefined}
        menuOptions={canEdit ? menuOptions : undefined}
      />
      <Divider />
      <ColumnContent>
        {errors.map((error) => (
          <Banner message={error} type="error" />
        ))}
        <ResourcesConfigForm
          config={config}
          mode={mode}
          isTemplate
          onChange={setConfig}
        />
      </ColumnContent>
      <Modal
        title={`Delete ${props.configurationTemplate.name}`}
        isOpen={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
      >
        <Modal.Body>
          {errors.map((error) => (
            <ModalErrorMessage errorMessage={error} />
          ))}
          Are you sure you want to delete "{props.configurationTemplate.name}"?
          This cannot be undone.
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Delete"
          onPrimaryButtonClick={handleDelete}
          primaryButtonLoading={deleteLoading}
        />
      </Modal>
      <Modal
        title={`Rename ${props.configurationTemplate.name}`}
        isOpen={showRenameModal}
        onClose={() => setShowRenameModal(false)}
      >
        <Modal.Body>
          {errors.map((error) => (
            <ModalErrorMessage errorMessage={error} />
          ))}
          <Input value={newName} onChange={setNewName} />
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Rename"
          onPrimaryButtonClick={handleRename}
          primaryButtonDisabled={
            !newName || newName === props.configurationTemplate.name
          }
          primaryButtonLoading={saveLoading}
        />
      </Modal>
    </>
  );
};

export default ConfigurationTemplatesColumn;
