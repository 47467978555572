import { getModifiedErrorMessage } from "api/ApiContext";
import {
  ConnectionPreviewLargeFragment,
  EntityType,
  Maybe,
  useUpdateConnectionMutation,
} from "api/generated/graphql";
import { Editor } from "components/entity_viewer/EntityViewer";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { Modal } from "components/ui";
import { SectionEntry } from "components/viewer/Viewer";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";

type ConnectionAdminRowProps = {
  connection?: Maybe<ConnectionPreviewLargeFragment>;
};

const ConnectionAdminRow = (props: ConnectionAdminRowProps) => {
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [updateConnection, { loading }] = useUpdateConnectionMutation();

  const [showModal, setShowModal] = useState(false);

  const initAdminId = props.connection?.adminOwner?.id;
  const [adminOwnerId, setAdminOwnerId] = useState<string | undefined>(
    initAdminId
  );

  const editor = (
    <Editor
      menuOptions={[
        {
          label: "Edit",
          handler: () => {
            setShowModal(true);
          },
        },
      ]}
    />
  );

  if (!props.connection) {
    return null;
  }

  let adminOwner = props.connection.adminOwner;

  const modalReset = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  const handleSubmit = async () => {
    if (!props.connection?.id) {
      return;
    }
    try {
      const { data } = await updateConnection({
        variables: {
          input: {
            id: props.connection.id,
            adminOwnerId: adminOwnerId,
          },
        },
      });
      switch (data?.updateConnection.__typename) {
        case "UpdateConnectionResult":
          modalReset();
          displaySuccessToast("Success: app admin updated");
          break;
        case "ConnectionNotFoundError":
        case "UserFacingError":
          setErrorMessage(data.updateConnection.message);
          break;
        default:
          logError(new Error(`failed to update app admin`));
          setErrorMessage("Error: failed to update app admin");
      }
    } catch (error) {
      logError(error, "failed to update app admin");
      setErrorMessage(
        getModifiedErrorMessage("Error: failed to update app admin", error)
      );
    }
  };

  return (
    <SectionEntry title="Admin" adminEditor={editor}>
      {adminOwner ? (
        <Link
          to={getResourceUrlNew(
            {
              entityId: adminOwner.id,
              entityType: EntityType.Owner,
            },
            EntityType.Owner
          )}
        >
          {adminOwner.name}
        </Link>
      ) : (
        "--"
      )}
      <Modal title="Set Admin" isOpen={showModal} onClose={modalReset}>
        <Modal.Body>
          {errorMessage ? (
            <ModalErrorMessage errorMessage={errorMessage} />
          ) : null}
          <OwnerDropdown
            selectedOwnerId={adminOwnerId}
            onSelectOwner={(owner) => setAdminOwnerId(owner?.id)}
          />
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Save"
          onPrimaryButtonClick={handleSubmit}
          primaryButtonDisabled={adminOwnerId === initAdminId}
          primaryButtonLoading={loading}
        />
      </Modal>
    </SectionEntry>
  );
};

export default ConnectionAdminRow;
