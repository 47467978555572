import { ConnectionType, EntityType, EventType } from "api/generated/graphql";
import { connectionTypeInfoByType } from "components/label/ConnectionTypeLabel";
import Label from "components/label/Label";
import sprinkles from "css/sprinkles.css";
import React from "react";
import { useHistory } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { eventTypeToString } from "utils/events";

type EventLabelProps = {
  eventType: EventType;
  eventSource: ConnectionType;
  eventId?: string;
};

export const EventTypeLabel = (props: EventLabelProps) => {
  const history = useHistory();
  const connectionTypeInfo = connectionTypeInfoByType[props.eventSource];
  const eventTypeString = eventTypeToString(props.eventType, true);

  const url = props.eventId
    ? getResourceUrlNew({
        entityId: props.eventId,
        entityType: EntityType.Event,
      })
    : undefined;

  if (connectionTypeInfo) {
    let iconTooltipText;
    if (props.eventSource !== ConnectionType.Opal) {
      iconTooltipText = `This event is imported from ${connectionTypeInfo.name}`;
    }

    return (
      <div className={sprinkles({ display: "flex" })}>
        <Label
          icon={connectionTypeInfo.icon}
          text={eventTypeString}
          iconTooltipText={iconTooltipText}
          url={url}
        />
      </div>
    );
  } else {
    return (
      <span
        onClick={() => {
          if (url) {
            history.push(url);
          }
        }}
      >
        {eventTypeString}
      </span>
    );
  }
};
