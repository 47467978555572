import { ImgHTMLAttributes, useState } from "react";

interface Props {
  fallbackSrc?: string;
}

const ImgWithFallback: React.FC<
  Props & ImgHTMLAttributes<HTMLImageElement>
> = ({ src, fallbackSrc, ...props }) => {
  const [imgSrc, setImgSrc] = useState(src);

  return (
    <img
      src={imgSrc}
      onError={() => {
        if (fallbackSrc && imgSrc !== fallbackSrc) {
          setImgSrc(fallbackSrc);
        }
      }}
      {...props}
    />
  );
};

export default ImgWithFallback;
