import _ from "lodash";
import Papa from "papaparse";

/**
 * Parse a CSV with a header into an array of rows:
 *
 * [
 *   {"Column 1": "foo", "Column 2": "bar"},
 *   {"Column 1": "abc", "Column 2": "def"},
 * ]
 *
 * Wraps `Papa.parse` to validate that there are no duplicate header columns.
 */
export function parseCSV(file: File) {
  return new Promise<Record<string, string>[]>((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = async () => {
      const csv = fileReader.result;
      if (!csv) {
        reject("File was empty or failed to upload.");
        return;
      }

      const csvAsString = `${csv}`;

      // Validate that there are no duplicate header columns.
      const headerResults = Papa.parse(csvAsString, {
        preview: 1,
      });
      const duplicates = findDuplicates(headerResults.data[0] as string[]);
      if (duplicates.length > 0) {
        reject(`Columns have duplicate headers: ${duplicates.join(", ")}`);
        return;
      }

      // Parse the CSV.
      const results = Papa.parse(csvAsString, {
        header: true,
        // Needed due to issue in Papa: https://stackoverflow.com/a/51111107/1232944
        skipEmptyLines: true,
      });

      resolve(results.data as Record<string, string>[]);
    };

    fileReader.onerror = function () {
      reject("Failed to read file: " + fileReader.error);
    };

    fileReader.readAsText(file);
  });
}

export const checkRequiredColumns = (
  row: Record<string, string>,
  requiredColumns: string[]
) => {
  const missingColumns = requiredColumns
    .filter((column) => {
      return !Object.prototype.hasOwnProperty.call(row, column);
    })
    .map((column) => `"${column}"`);
  if (missingColumns.length === 1) {
    throw new Error(`Required column ${missingColumns[0]} is missing.`);
  } else if (missingColumns.length > 1) {
    throw new Error(
      `Required columns ${missingColumns.join(", ")} are missing.`
    );
  }
};

/**
 * Return an array containing the duplicate elements in `array`.
 * https://stackoverflow.com/a/31683002/1232944
 */
const findDuplicates = (array: string[]) => {
  const grouped = _.groupBy(array, (n) => n);
  return _.uniq(
    _.flatten(
      _.filter(grouped, function (n) {
        return n.length > 1;
      })
    )
  );
};
