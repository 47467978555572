import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewFragment,
  EntityType,
  Maybe,
  useAccessReviewQuery,
  useOngoingAccessReviewTabStatsQuery,
  useStopAccessReviewMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeaderV3 from "components/column/ColumnHeaderV3";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import ConfirmModal from "components/modals/update/ConfirmModal";
import { SendReminderModal } from "components/modals/update/SendReminderModal";
import { PillV3 } from "components/pills/PillsV3";
import { useToast } from "components/toast/Toast";
import { Banner, ButtonV3, TabsV3 } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import { useContext, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { hasBasicPermissions } from "utils/auth/auth";
import { getResourceUrlNew } from "utils/common";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { useReadUINotification } from "utils/notifications";
import { getTimeZoneFromString } from "utils/time";
import AccessReviewEditModalV3 from "views/access_reviews/AccessReviewEditModalV3";
import AccessReviewEndedV3 from "views/access_reviews/AccessReviewEndedV3";
import AccessReviewOverviewV3 from "views/access_reviews/AccessReviewOverviewV3";
import {
  handleRequestExport,
  UARExportType,
} from "views/access_reviews/common/Export";
import {
  ItemDetailsCard,
  ItemDetailsCardSkeleton,
} from "views/common/ItemDetailsCard";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { getUserAvatarIcon } from "views/users/utils";

import AccessChangesV3 from "./AccessChangesV3";
import AccessReviewContext, {
  AccessReviewContextActionType,
} from "./AccessReviewContext";
import AccessReviewEventsTable from "./AccessReviewEventsTable";
import AccessReviewGroupResourceAssignments from "./AccessReviewGroupResourcesAssignments";
import AccessReviewMyResourcesV3 from "./AccessReviewMyResourcesV3";
import AccessReviewResourcePrincipalAssignments from "./AccessReviewResourcePrincipalAssignments";
import AccessReviewReviewersV3 from "./AccessReviewReviewersV3";
import AccessReviewUserAssignments from "./AccessReviewUserAssignments";

const buttonSize = "sm";

const AccessReviewDetailColumn = () => {
  const history = useHistory();
  const location = useLocation();
  const { accessReviewId } = useParams<Record<string, string>>();
  const { authState } = useContext(AuthContext);
  const {
    displaySuccessToast,
    displayLoadingToast,
    displayErrorToast,
  } = useToast();
  const [sendReminderModalOpen, setSendReminderModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);

  const hasNHIs = useFeatureFlag(FeatureFlag.NonHumanIdentities);

  const { data, error, loading } = useAccessReviewQuery({
    variables: {
      input: {
        accessReviewId: accessReviewId,
      },
    },
    skip: !accessReviewId,
  });
  let accessReviewNotFound = false;
  let accessReview: AccessReviewFragment | null = null;
  if (data) {
    switch (data.accessReview.__typename) {
      case "AccessReviewResult":
        accessReview = data.accessReview.accessReview;
        break;
      case "AccessReviewNotFoundError":
        accessReviewNotFound = true;
        break;
      default:
        logError(new Error(`failed to retrieve access reviews`));
    }
  } else if (error) {
    logError(error, `failed to retrieve access reviews`);
  }

  const changeTab = (hash: string) => {
    const params = new URLSearchParams(location.search);
    params.forEach((_, key) => {
      if (key !== "category") {
        params.delete(key);
      }
    });
    history.push({ hash: `#${hash}`, search: params.toString() });
  };

  const {
    data: tabStatsData,
    error: tabStatsError,
  } = useOngoingAccessReviewTabStatsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        accessReviewId: accessReview?.id ?? "",
      },
    },
    skip: !accessReview,
  });

  let tabStats = null;
  if (tabStatsData) {
    switch (tabStatsData.ongoingAccessReviewTabStats.__typename) {
      case "OngoingAccessReviewTabStatsResult":
        tabStats =
          tabStatsData.ongoingAccessReviewTabStats
            .ongoingAccessReviewTabStats || null;
        break;
      case "AccessReviewNotFoundError":
        accessReviewNotFound = true;
        break;
      case "AccessReviewAlreadyStoppedError":
        break;
      default:
        logError(new Error(`failed to retrieve access review stats`));
    }
  } else if (tabStatsError) {
    logError(tabStatsError, `failed to retrieve access review stats`);
  }

  const isPastAccessReview = accessReview?.stoppedDate !== null;
  useReadUINotification(accessReview?.id ?? "");

  if (loading) {
    return (
      <>
        <ItemDetailsCardSkeleton />
        <ColumnListItemsSkeleton />
      </>
    );
  }

  if (accessReviewNotFound) {
    return <NotFoundPage />;
  }

  if (!accessReviewId || !accessReview || error) {
    return <UnexpectedErrorPage error={error} />;
  }

  const defaultContent = isPastAccessReview ? (
    <AccessReviewEndedV3 accessReview={accessReview} />
  ) : (
    <AccessReviewOverviewV3
      ongoingAccessReview={accessReview}
      userReviewsStats={tabStats ?? undefined}
    />
  );

  let content;
  switch (location.hash) {
    case "#my-reviews":
      content = <AccessReviewMyResourcesV3 accessReviewId={accessReview.id} />;
      break;
    case "#user-review":
      content = (
        <AccessReviewUserAssignments accessReviewId={accessReview.id} />
      );
      break;
    case "#reviewers":
      content = <AccessReviewReviewersV3 accessReviewId={accessReview.id} />;
      break;
    case "#group-review":
      content = (
        <AccessReviewGroupResourceAssignments
          accessReviewId={accessReview.id}
        />
      );
      break;
    case "#nhi-review":
      content = hasNHIs ? (
        <AccessReviewResourcePrincipalAssignments
          accessReviewId={accessReview.id}
        />
      ) : (
        defaultContent
      );
      break;
    case "#access-changes":
      content = <AccessChangesV3 accessReviewId={accessReview.id} />;
      break;
    case "#events":
      content = <AccessReviewEventsTable accessReviewId={accessReview.id} />;
      break;
    default:
      content = defaultContent;
      break;
  }

  const shouldShowAdminContent = !hasBasicPermissions(authState.user);
  const shouldShowOverview = shouldShowAdminContent || !isPastAccessReview;
  const tabInfos = [];
  if (shouldShowOverview) {
    tabInfos.push({
      title: "Overview",
      onClick: () => changeTab("overview"),
      isSelected: !location.hash || location.hash === "#overview",
    });
  }

  if (!isPastAccessReview) {
    tabInfos.push({
      title: "My Reviews",
      onClick: () => changeTab("my-reviews"),
      isSelected: location.hash === "#my-reviews",
    });
  }

  if (shouldShowAdminContent) {
    tabInfos.push({
      title: "User Review",
      onClick: () => changeTab("user-review"),
      isSelected: location.hash === "#user-review",
    });

    tabInfos.push({
      title: "Group Review",
      onClick: () => changeTab("group-review"),
      isSelected: location.hash === "#group-review",
    });

    if (hasNHIs) {
      tabInfos.push({
        title: "NHI Review",
        onClick: () => changeTab("nhi-review"),
        isSelected: location.hash === "#nhi-review",
      });
    }

    tabInfos.push({
      title: "Reviewers",
      onClick: () => changeTab("reviewers"),
      isSelected: location.hash === "#reviewers",
    });

    tabInfos.push({
      title: "Access Changes",
      onClick: () => changeTab("access-changes"),
      isSelected: location.hash === "#access-changes",
    });

    tabInfos.push({
      title: "Events",
      onClick: () => changeTab("events"),
      isSelected: location.hash === "#events",
    });
  }

  const canEditSettings = authState.user?.isAdmin || authState.user?.isAuditor;

  const menuOptions: PropsFor<typeof ItemDetailsCard>["extraMenuOptions"] = [];
  if (canEditSettings && accessReview && !isPastAccessReview) {
    menuOptions.push(
      {
        label: "Send reminder to reviewers",
        onClick: () => setSendReminderModalOpen(true),
        icon: { type: "name", icon: "mail" },
      },
      {
        label: "Export all resource users (.csv)",
        onClick: () => {
          handleRequestExport(
            accessReview!,
            UARExportType.ResourceUsers,
            displayLoadingToast,
            displaySuccessToast,
            displayErrorToast
          );
        },
        icon: { type: "name", icon: "users-right" },
      },
      {
        label: "Export all group users (.csv)",
        onClick: () => {
          handleRequestExport(
            accessReview!,
            UARExportType.GroupUsers,
            displayLoadingToast,
            displaySuccessToast,
            displayErrorToast
          );
        },
        icon: { type: "name", icon: "users-right" },
      }
    );
  }

  return (
    <>
      {sendReminderModalOpen && (
        <SendReminderModal
          accessReview={accessReview}
          onClose={() => setSendReminderModalOpen(false)}
        />
      )}
      {editModalOpen && (
        <AccessReviewEditModalV3
          id={accessReview.id}
          isOpen={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          initialConfig={{
            name: accessReview.name,
            deadline: accessReview.deadline,
            reminderSchedule: accessReview.reminderSchedule,
            reminderTimeOfDay: moment(accessReview.reminderTimeOfDay).toDate(),
            reminderIncludeManager: accessReview.reminderIncludeManager,
            timeZone: getTimeZoneFromString(accessReview.timeZone),
            sendReviewerAssignmentNotifications:
              accessReview.sendReviewerAssignmentNotification,
          }}
        />
      )}
      <ColumnHeaderV3
        includeDefaultActions
        breadcrumbs={[
          { name: "Access Reviews", to: "/access-reviews" },
          {
            name: accessReview.stoppedByUser ? "Ended" : "Ongoing",
            to:
              "/access-reviews?category=" +
              (accessReview.stoppedByUser ? "ended" : "ongoing"),
          },
          {
            name: accessReview.name,
          },
        ]}
      />
      <ColumnContent>
        <>
          {accessReview.stoppedByUser && (
            <div
              className={sprinkles({
                marginBottom: "mdlg",
              })}
            >
              <Banner
                type="warning"
                message="This access review has been stopped. No further actions can be taken on reviewer assignments and reviews."
              />
            </div>
          )}
          <ItemDetailsCard
            title={accessReview.name}
            extraMenuOptions={menuOptions}
            bodyFields={{
              "Started by": accessReview.startedByUser ? (
                <PillV3
                  keyText={accessReview.startedByUser.fullName ?? ""}
                  pillColor="DeepOrange"
                  icon={getUserAvatarIcon(accessReview.startedByUser)}
                  url={getResourceUrlNew({
                    entityId: accessReview.startedByUser.id,
                    entityType: EntityType.User,
                  })}
                />
              ) : (
                <>None</>
              ),
              "Started on": (
                <PillV3
                  keyText={moment(accessReview.createdAt).format("YYYY-MM-DD")}
                  pillColor="Cyan"
                  icon={{ type: "name", icon: "calendar" }}
                />
              ),
              "Due on": (
                <PillV3
                  keyText={moment(accessReview.deadline).format("YYYY-MM-DD")}
                  pillColor="Cyan"
                  icon={{ type: "name", icon: "calendar" }}
                />
              ),
              Timezone: (
                <PillV3
                  keyText={accessReview.timeZone}
                  pillColor="Cyan"
                  icon={{ type: "name", icon: "globe" }}
                />
              ),
            }}
            rightActions={
              <>
                {canEditSettings && !isPastAccessReview ? (
                  <div className={sprinkles({ display: "flex", gap: "sm" })}>
                    <StopAccessReviewButton
                      ongoingAccessReviewId={accessReview.id}
                    />
                    <ButtonV3
                      label="Edit"
                      type="mainSecondary"
                      leftIconName="edit"
                      onClick={() => setEditModalOpen(true)}
                      size={buttonSize}
                    />
                  </div>
                ) : null}
              </>
            }
          />
        </>
        <TabsV3 tabInfos={tabInfos} />
        {content}
      </ColumnContent>
    </>
  );
};

type StopAccessReviewButtonProps = {
  ongoingAccessReviewId: string;
};

const StopAccessReviewButton = (props: StopAccessReviewButtonProps) => {
  const history = useHistory();

  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );

  const { displaySuccessToast, displayErrorToast } = useToast();
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const [stopAccessReview, { loading }] = useStopAccessReviewMutation();

  const modalReset = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  const confirmModal = (
    <ConfirmModal
      title={`Stop access review`}
      message={
        <div>
          <div style={{ paddingBottom: "12px" }}>
            Are you sure you want to stop the ongoing access review? This action
            affects the entire organization, and cannot be undone.
          </div>
          <div>
            Any pending actions identified in this review, such as access
            revocation, will be executed when the review is stopped
          </div>
        </div>
      }
      isModalOpen={showModal}
      onClose={modalReset}
      onSubmit={async () => {
        try {
          const { data } = await stopAccessReview({
            variables: {
              input: {
                id: props.ongoingAccessReviewId,
              },
            },
            refetchQueries: ["AccessReviews"],
          });
          switch (data?.stopAccessReview.__typename) {
            case "StopAccessReviewResult":
              accessReviewState.ongoingAccessReviewIdSet.delete(
                data.stopAccessReview.accessReview.id
              );
              accessReviewDispatch({
                type: AccessReviewContextActionType.AccessReviewUpdate,
                payload: {
                  ongoingAccessReviewIdSet:
                    accessReviewState.ongoingAccessReviewIdSet,
                },
              });
              // If we didn't get a reportId, PDF gen failed, so warn the user
              if (!data?.stopAccessReview.accessReview.reportId) {
                displayErrorToast(
                  "Access review stopped, but failed to generate PDF report"
                );
              } else {
                // Otherwise, we can proceed with downloading the report
                displaySuccessToast(`Success: access review stopped`);
                handleRequestExport(
                  data.stopAccessReview.accessReview,
                  UARExportType.Report
                );
              }
              history.replace(
                `/access-reviews/${props.ongoingAccessReviewId}?category=ended#overview`
              );
              break;
            case "AccessReviewNotFoundError":
            case "AccessReviewAlreadyStoppedError":
              setErrorMessage(data.stopAccessReview.message);
              break;
            default:
              setErrorMessage(`Error: failed to stop access review`);
          }
        } catch (error) {
          logError(error, "failed to stop access review");
          setErrorMessage(
            getModifiedErrorMessage(
              "Error: failed to stop access review",
              error
            )
          );
        }
      }}
      loading={loading}
      errorMessage={errorMessage}
      submitLabel={"Stop Review"}
    />
  );

  return (
    <div>
      <ButtonV3
        type="danger"
        label={"Stop Access Review"}
        onClick={() => {
          setShowModal(true);
        }}
        loading={loading}
        size={buttonSize}
      />
      {confirmModal}
    </div>
  );
};

export default AccessReviewDetailColumn;
