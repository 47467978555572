import * as Apollo from "@apollo/client";
import {
  BundleDetailFragment,
  BundleItemsQuery,
  BundleItemsSortByField,
  EntityType,
  SortDirection,
  useBundleItemsQuery,
  useBundleQuery,
  Visibility,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import { ColumnHeaderSkeleton } from "components/column/ColumnHeaderV3";
import BundlesConfigForm from "components/forms/BundlesConfigForm";
import { makeBundleConfig } from "components/forms/utils";
import OpalPage from "components/opal/layout/OpalPage";
import { BreadcrumbsV3, ButtonV3, Link, TabsV3 } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { AuthorizedActionManage } from "utils/auth/auth";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import {
  MAX_REQUESTABLE_ENTITIES,
  useAccessRequestTransition,
} from "views/access_request/AccessRequestContext";
import { AppsContext } from "views/apps/AppsContext";
import BulkRequestModal from "views/apps/BulkRequestModal";
import { formatRequestDataForItems } from "views/apps/enduser_exp/utils";
import { ItemDetailsCard } from "views/common/ItemDetailsCard";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import EventsTableV3Component from "views/events/EventsTableV3Component";

import BundleDeleteModal from "./BundleDeleteModal";
import { BundleView } from "./BundleDetailContent";
import BundleResourcesColumnV3 from "./BundleResourcesV3";

const RESOURCES_VIEW_KEY = "resources";
const buttonSize = "sm";

export const SORT_OPTIONS = [
  {
    label: "Name (A-Z)",
    value: {
      field: BundleItemsSortByField.Name,
      direction: SortDirection.Asc,
    },
  },
  {
    label: "Name (Z-A)",
    value: {
      field: BundleItemsSortByField.Name,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Newest first",
    value: {
      field: BundleItemsSortByField.CreatedAt,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Oldest first",
    value: {
      field: BundleItemsSortByField.CreatedAt,
      direction: SortDirection.Asc,
    },
  },
];

const RequestBundleButton = ({
  data,
  loading,
  error,
  setShowRequestModal,
}: {
  data: BundleItemsQuery | undefined;
  loading: boolean;
  error: Apollo.ApolloError | undefined;
  setShowRequestModal: (show: boolean) => void;
}) => {
  const { selectBundleItems } = useContext(AppsContext);
  const hasEndUserXP = useFeatureFlag(FeatureFlag.EndUserExperience);
  const transitionToAccessRequest = useAccessRequestTransition();

  const requestableItems =
    data?.bundleItems.items?.filter((item) =>
      Boolean(item.resource?.isRequestable || item.group?.isRequestable)
    ) ?? [];

  const handleRequestBundle = async () => {
    selectBundleItems(requestableItems);
    const hasMoreThanLimit = requestableItems.length > MAX_REQUESTABLE_ENTITIES;
    if (hasEndUserXP) {
      transitionToAccessRequest({
        ...formatRequestDataForItems(
          requestableItems.slice(0, MAX_REQUESTABLE_ENTITIES).map((item) => ({
            entityId: item.resource?.id ?? item.group?.id ?? "",
            entityType:
              item.resource?.__typename === "Resource"
                ? EntityType.Resource
                : EntityType.Group,
            roles: [
              {
                accessLevelRemoteId: item.accessLevelRemoteId,
                accessLevelName: item.accessLevelName,
              },
            ],
          }))
        ),
        skippedEntityCount: hasMoreThanLimit
          ? requestableItems.length - MAX_REQUESTABLE_ENTITIES
          : undefined,
      });
    } else {
      setShowRequestModal(true);
    }
  };

  return (
    <ButtonV3
      size={buttonSize}
      type="main"
      label="Request"
      leftIconName="raised-hand"
      loading={loading}
      onClick={handleRequestBundle}
      disabled={Boolean(error) || requestableItems.length === 0}
      disabledTooltip="No requestable items in bundle"
    />
  );
};

const BundleDetailColumnV3 = () => {
  const { clearSelectedItems } = useContext(AppsContext);
  const { bundleId } = useParams<Record<string, string>>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const isInventory = location.pathname.startsWith("/inventory");

  const {
    data: bundleData,
    error: bundleError,
    loading: bundleLoading,
  } = useBundleQuery({
    variables: {
      input: {
        id: bundleId,
      },
    },
    skip: !bundleId,
  });

  const {
    data: bundleItemsData,
    error: bundleItemsErr,
    loading: bundleItemsLoading,
  } = useBundleItemsQuery({
    variables: {
      input: {
        bundleId,
      },
    },
    skip: !bundleId,
  });

  if (!bundleId) {
    return null;
  }
  if (bundleError) {
    return (
      <Column isContent maxWidth="none">
        <UnexpectedErrorPage error={bundleError} />
      </Column>
    );
  }

  if (bundleLoading || bundleItemsLoading) {
    return (
      <Column isContent maxWidth="none">
        <ColumnHeaderSkeleton includeCard />
      </Column>
    );
  }

  let bundle: BundleDetailFragment | undefined;
  switch (bundleData?.bundle.__typename) {
    case "BundleResult":
      bundle = bundleData.bundle.bundle;
      break;
    case "BundleNotFoundError":
      return (
        <Column isContent maxWidth="none">
          <NotFoundPage />
        </Column>
      );
  }

  if (!bundle) {
    return (
      <Column isContent maxWidth="none">
        <NotFoundPage />
      </Column>
    );
  }

  const canManage =
    bundle.authorizedActions?.includes(AuthorizedActionManage) ?? false;

  const bundleViews: BundleView[] = [
    {
      key: RESOURCES_VIEW_KEY,
      title: "Resources",
      icon: "cube",
      content: BundleResourcesColumnV3,
    },
  ];
  if (canManage) {
    bundleViews.push({
      key: "events",
      title: "Events",
      icon: "events",
      content: () => {
        return (
          <EventsTableV3Component
            eventFilter={{
              objects: {
                objectId: bundleId,
              },
            }}
            route={{
              pathname: `/bundles/${bundleId}/events`,
            }}
            emptyState={{
              title: "No events for this bundle",
              subtitle: "Interact with this bundle to add events",
            }}
          />
        );
      },
    });
  }
  bundleViews.push({
    key: "details",
    title: "Details",
    icon: "list",
    content: () => {
      return (
        <BundlesConfigForm
          mode="view"
          config={bundle ? makeBundleConfig(bundle) : {}}
          onChange={() => {}}
        />
      );
    },
  });

  const overviewButtons = (
    <div className={sprinkles({ display: "flex", gap: "sm" })}>
      {canManage && (
        <ButtonV3
          label="Edit"
          type="mainSecondary"
          onClick={() => {
            history.push(`/bundles/${bundleId}/edit`);
          }}
          leftIconName="edit"
          size={buttonSize}
        />
      )}
      <RequestBundleButton
        data={bundleItemsData}
        loading={bundleItemsLoading}
        error={bundleItemsErr}
        setShowRequestModal={setShowRequestModal}
      />
    </div>
  );

  const currentView = bundleViews.find((view) =>
    location.pathname.endsWith(view.key)
  );
  if (!currentView) {
    // Using `replace` instead of `push` because `push` breaks the back button when coming from the
    // bundle creation UI (i.e., hitting back puts you in an infinite loop such that a user can't leave
    // this page using the back button).
    history.replace(
      // Default to the resources tab if the current view is not found as it is always present for all user roles.
      `/bundles/${bundleId}/${RESOURCES_VIEW_KEY}`
    );
    return null;
  }
  const tabInfos: PropsFor<typeof TabsV3>["tabInfos"] = bundleViews.map(
    (view) => {
      return {
        title: view.title,
        onClick: isInventory
          ? () => history.push(`inventory/bundles/${bundleId}/${view.key}`)
          : () => history.push(`/bundles/${bundleId}/${view.key}`),
        isSelected: location.pathname.endsWith(view.key),
        count:
          view.key === "resources"
            ? bundleItemsData?.bundleItems.totalNumItems
            : undefined,
      };
    }
  );

  const View = currentView.content;

  const breadcrumbInfos = isInventory
    ? [
        { name: "Bundles", to: "/inventory/bundles" },
        {
          name: bundle.name,
          to: "",
        },
      ]
    : [
        { name: "Catalog", to: "/apps" },
        { name: "Bundles", to: "/bundles" },
        {
          name: bundle.name,
          to: "",
        },
      ];
  const breadcrumbs = <BreadcrumbsV3 breadcrumbInfos={breadcrumbInfos} />;

  return (
    <OpalPage
      title={isInventory ? "Inventory" : breadcrumbs}
      icon={isInventory ? "inventory" : undefined}
      breadcrumbs={isInventory ? breadcrumbInfos : undefined}
    >
      <ItemDetailsCard
        icon={{ type: "name", icon: "package" }}
        title={bundle.name}
        subtitle={bundle.description || "—"}
        rightActions={overviewButtons}
        extraMenuOptions={
          canManage
            ? [
                {
                  label: "Remove from Opal",
                  onClick: () => setShowDeleteModal(true),
                  type: "danger",
                },
              ]
            : undefined
        }
        footerFields={{
          Admin: bundle.adminOwner ? (
            <Link
              url={`/owners/${bundle.adminOwnerId}`}
              underline={false}
              color="black"
              entityTypeNew={EntityType.Owner}
            >
              {bundle.adminOwner.name}
            </Link>
          ) : (
            "--"
          ),
          Visibility:
            bundle.visibility === Visibility.Global ? "Global" : "Limited",
        }}
      />
      <div
        className={sprinkles({
          display: "flex",
          justifyContent: "center",
        })}
      >
        <TabsV3 tabInfos={tabInfos} />
      </div>

      <View />
      {showDeleteModal && (
        <BundleDeleteModal
          bundle={bundle}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
      <BulkRequestModal
        isOpen={showRequestModal}
        onClose={() => {
          clearSelectedItems();
          setShowRequestModal(false);
        }}
      />
    </OpalPage>
  );
};

export default BundleDetailColumnV3;
