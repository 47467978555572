import {
  EntityIdTuple,
  Maybe,
  UpdateSamlBreakGlassUserInput,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { PaginatedUserDropdown } from "components/dropdown/PaginatedUserDropdown";
import UserLabel from "components/label/item_labels/UserLabel";
import accessReviewerModalStyles from "components/modals/AccessReviewerModal.module.scss";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { Banner, Button, Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import * as Icons from "react-feather";

export type SamlBreakGlassUserModalEntry = {
  id: string;
  name: string;
  avatarUrl: string;
  canBeRemoved: boolean;
  entityIds?: Maybe<EntityIdTuple[]>;
};

type SamlBreakGlassUsersModalProps = {
  title: string;
  isModalOpen: boolean;
  onClose: () => void;
  onSubmit: (samlBreakGlassUsers: UpdateSamlBreakGlassUserInput[]) => void;
  entryInfos: SamlBreakGlassUserModalEntry[];
  loading: boolean;
  errorMessage: string;
};

const SamlBreakGlassUsersModal = (props: SamlBreakGlassUsersModalProps) => {
  const { authState } = useContext(AuthContext);
  const [users, setUsers] = useState<SamlBreakGlassUserModalEntry[]>(
    props.entryInfos
  );
  const [showUserDropdown, setShowUserDropdown] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(false);
  const samlBreakGlassUsersListDiv = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (scrollToBottom && samlBreakGlassUsersListDiv.current) {
      samlBreakGlassUsersListDiv.current.scrollTop =
        samlBreakGlassUsersListDiv.current.offsetHeight;
      setScrollToBottom(false);
    }
  }, [scrollToBottom, samlBreakGlassUsersListDiv]);

  const formUnchanged = isFormUnchanged(props.entryInfos, users);

  const onSubmit = async () => {
    const samlBreakGlassUsers = users.map((user) => {
      return { userId: user.id, entityIds: user.entityIds };
    });
    await props.onSubmit(samlBreakGlassUsers);
  };

  const previouslyHadLoggedInUser = props.entryInfos.some(
    (user) => user.id === authState.user?.user.id
  );
  const currentlyHasLoggedInUser = users.some(
    (user) => user.id === authState.user?.user.id
  );
  const showLoggedInUserWarning =
    previouslyHadLoggedInUser && !currentlyHasLoggedInUser;

  return (
    <Modal
      title={props.title}
      isOpen={props.isModalOpen}
      onClose={() => {
        props.onClose();
        setShowUserDropdown(false);
      }}
    >
      <Modal.Body>
        {props.errorMessage && (
          <ModalErrorMessage errorMessage={props.errorMessage} />
        )}

        <div className={accessReviewerModalStyles.mainContentContainer}>
          <div
            className={accessReviewerModalStyles.modalFieldContainer}
            ref={samlBreakGlassUsersListDiv}
          >
            {users
              .sort((a, b) => {
                return Number(a.canBeRemoved) - Number(b.canBeRemoved);
              })
              .map((user) => {
                return (
                  <SamlBreakGlassUserModalUser
                    onFocus={() => {
                      setShowUserDropdown(false);
                    }}
                    key={`entry_${user.id}`}
                    entryInfo={user}
                    removeUser={(userId) => {
                      const updatedUsers = users.filter((user) => {
                        return user.id !== userId;
                      });
                      setUsers(updatedUsers);
                    }}
                  />
                );
              })}

            {!showUserDropdown ? (
              <div
                className={accessReviewerModalStyles.addItemContainer}
                onClick={() => {
                  setShowUserDropdown(true);
                }}
              >
                <Button leftIconName="plus" type="primary" borderless />
                <span
                  className={sprinkles({
                    marginLeft: "sm",
                    fontSize: "labelLg",
                  })}
                >
                  Add User
                </span>
              </div>
            ) : (
              <div className={accessReviewerModalStyles.userDropdownContainer}>
                <PaginatedUserDropdown
                  value={undefined} // Not used, because this dropdown is immediately hidden after input is selected
                  onChange={(newUser) => {
                    if (!newUser) {
                      return; // Shouldn't hit this since not clearable
                    }
                    setUsers([
                      ...users,
                      {
                        name: newUser.fullName,
                        avatarUrl: newUser.avatarUrl,
                        id: newUser.id,
                        canBeRemoved: true,
                      },
                    ]);
                    setShowUserDropdown(false);
                    setScrollToBottom(true);
                  }}
                  hiddenUserIds={users.map((user) => user.id)}
                  clearable={false}
                  autoFocus
                />
              </div>
            )}
          </div>
        </div>
        {showLoggedInUserWarning && (
          <Banner
            type="warning"
            message="Warning! If you remove yourself, you will only be able to log in via SAML."
          />
        )}
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Update Users"
        primaryButtonDisabled={formUnchanged}
        primaryButtonLoading={props.loading}
        onPrimaryButtonClick={onSubmit}
      />
    </Modal>
  );
};

const isFormUnchanged = (
  initSamlBreakGlassUsers: SamlBreakGlassUserModalEntry[],
  selectedSamlBreakGlassUsers: SamlBreakGlassUserModalEntry[]
) => {
  if (initSamlBreakGlassUsers.length !== selectedSamlBreakGlassUsers.length) {
    return false;
  }

  for (const entryInfo of initSamlBreakGlassUsers) {
    let foundEqual = false;
    for (const user of selectedSamlBreakGlassUsers) {
      if (entryInfo.id === user.id && entryInfo.entityIds === user.entityIds) {
        foundEqual = true;
        break;
      }
    }
    if (!foundEqual) {
      return false;
    }
  }
  return true;
};

type SamlBreakGlassUserModalProps = {
  entryInfo: SamlBreakGlassUserModalEntry;
  removeUser: (userId: string) => void;
  onFocus: () => void;
};

const SamlBreakGlassUserModalUser = (props: SamlBreakGlassUserModalProps) => (
  <div className={accessReviewerModalStyles.entryContainer}>
    <UserLabel
      name={props.entryInfo.name}
      avatar={props.entryInfo.avatarUrl}
      large
      bold
    />
    <div className={accessReviewerModalStyles.dropdownTrashIconContainer}>
      <div
        className={accessReviewerModalStyles.deleteIconClickable}
        onClick={() => {
          props.removeUser(props.entryInfo.id);
          props.onFocus();
        }}
      >
        <Icons.Trash2 strokeWidth={2} size={22} />
      </div>
    </div>
  </div>
);

export default SamlBreakGlassUsersModal;
