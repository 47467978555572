import { getModifiedErrorMessage } from "api/ApiContext";
import { useUseGroupBreakGlassMutation } from "api/generated/graphql";
import ConfirmModal from "components/modals/update/ConfirmModal";
import { useToast } from "components/toast/Toast";
import { Button, ButtonV3 } from "components/ui";
import React, { useState } from "react";
import { logError } from "utils/logging";

interface GroupBreakGlassButtonProps {
  onClick: () => void;
  hasV3?: boolean;
  buttonSize?: "sm" | "md" | "lg";
}

export const GroupBreakGlassButton: React.FC<GroupBreakGlassButtonProps> = (
  props
) => {
  return props.hasV3 ? (
    <ButtonV3
      label="Break glass"
      leftIconName="life-buoy"
      type="main"
      onClick={props.onClick}
      size={props.buttonSize}
    />
  ) : (
    <Button
      label="Break glass"
      leftIconName="life-buoy"
      onClick={props.onClick}
      size="md"
      type="primary"
    />
  );
};

interface GroupBreakGlassModalProps {
  isOpen: boolean;
  onClose: () => void;
  groupId: string;
}

export const GroupBreakGlassModal: React.FC<GroupBreakGlassModalProps> = (
  props
) => {
  const [errorMessage, setErrorMessage] = useState<string>();
  const [breakGlass, { loading }] = useUseGroupBreakGlassMutation();
  const { displaySuccessToast } = useToast();

  // Clear error message if modal is closed
  if (!props.isOpen && errorMessage) {
    setErrorMessage(undefined);
  }

  const doBreakGlass = async () => {
    try {
      const { data } = await breakGlass({
        variables: {
          input: {
            groupId: props.groupId,
          },
        },
      });
      switch (data?.useGroupBreakGlass?.__typename) {
        case "UseGroupBreakGlassResult":
          displaySuccessToast("Success: break-glass access provisioned");
          props.onClose();
          break;
        case "NoBreakGlassAccessError":
        case "GroupNotFoundError":
        case "GroupUserAlreadyExists":
          setErrorMessage(data.useGroupBreakGlass.message);
          break;
        default:
          logError(
            data,
            "failed to use break-glass access: unknown response type"
          );
          setErrorMessage("Error: failed to use break-glass access");
      }
    } catch (error) {
      logError(error, `failed to use break-glass access`);
      setErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to use break-glass access",
          error
        )
      );
    }
  };

  return (
    <ConfirmModal
      title={"Use break-glass access?"}
      message={
        "Are you sure you want to use break-glass access? Break-glass usage will be logged."
      }
      isModalOpen={props.isOpen}
      onClose={props.onClose}
      onSubmit={doBreakGlass}
      loading={loading}
      errorMessage={errorMessage}
      submitLabel="Continue"
    />
  );
};
