import { openInNewTab } from "api/common/common";
import { EntityIcon, Icon, Label, Loader } from "components/ui";
import {
  useFilterDispatch,
  useFilterState,
} from "components/viz/contexts/FilterContext";
import NodeMenu from "components/viz/menus/NodeMenu";
import Node from "components/viz/nodes/Node";
import pluralize from "pluralize";
import React from "react";
import { useContext } from "react";
import useLogEvent from "utils/analytics";

import {
  BASE_NODE_HEIGHT,
  ICON_HEIGHT,
  ICON_WIDTH,
  NODE_PADDING,
  ResourceNodeData,
  SPACING,
  TEXT_SPACING,
} from "../common";
import { GraphContext } from "../contexts/GraphContext";
import { getNodeWidth } from "../utils";
import * as styles from "./ResourceNode.css";

interface ResourceNodeProps {
  data: ResourceNodeData;
  onClick?: () => void;
  onCmdClick?: () => void;
}

const ResourceNode = ({ data, onCmdClick }: ResourceNodeProps) => {
  const filterDispatch = useFilterDispatch();
  const filterState = useFilterState();
  const { graphState, graphDispatch } = useContext(GraphContext);
  const logEvent = useLogEvent();

  const setLoading = React.useCallback(
    (loading: boolean) => {
      graphDispatch({
        type: "SET_LOADING",
        payload: {
          loading,
        },
      });
    },
    [graphDispatch]
  );

  const numChildren = data.numChildResources ?? data.numRoles ?? 0;
  const childNoun = data.numChildResources ? "child resource" : "role";
  const width = getNodeWidth(data);

  const nodeMenuOptions: PropsFor<typeof NodeMenu>["options"] = [];

  if (data.isManaged) {
    nodeMenuOptions.push({
      label: "View details",
      onClick: () => {
        data.url && openInNewTab(data.url);
        logEvent({
          name: "viz_view_details_click",
          properties: {
            entityName: data.name ?? "",
            nodeID: data.nodeId,
            entityType: data.kind,
          },
        });
      },
      icon: { type: "name", icon: "link-external" },
    });
  }

  const selectedResourceIds = filterState.selection.resourceIds ?? [];
  const numResourceTypes =
    filterState.selection.multiResourceSelections?.length ?? 0;
  if (
    numResourceTypes > 0 ||
    selectedResourceIds.length !== 1 ||
    !selectedResourceIds.includes(data.nodeId)
  ) {
    nodeMenuOptions.push({
      label: "View relations",
      onClick: () => {
        setLoading(true);
        filterDispatch({
          type: "ADD_RESOURCE",
          payload: {
            id: data.nodeId,
            clearFilter: true,
          },
        });
        logEvent({
          name: "viz_view_relations_click",
          properties: {
            entityName: data.name ?? "",
            nodeID: data.nodeId,
            entityType: data.kind,
          },
        });
      },
      icon: { type: "name", icon: "eye" },
    });
  }

  return (
    <Node
      data={data}
      onClick={() => {
        if (data.expandable) {
          graphDispatch({
            type: "TOGGLE_EXPAND_NODE",
            payload: {
              nodeId: data.nodeId,
            },
          });
        } else {
          graphDispatch({
            type: "TOGGLE_SELECT_RESOURCE",
            payload: {
              resourceId: data.nodeId,
            },
          });
        }
      }}
      onCmdClick={onCmdClick}
    >
      <foreignObject
        width={ICON_WIDTH + 10}
        height={ICON_HEIGHT}
        x={NODE_PADDING}
        y={BASE_NODE_HEIGHT / 2 - ICON_HEIGHT / 2}
        className={styles.icon}
      >
        <EntityIcon type={data.resourceType} />
      </foreignObject>
      {data.loading ? (
        <foreignObject
          width={ICON_WIDTH}
          height={ICON_HEIGHT}
          x={width - SPACING.xl - NODE_PADDING}
          y={BASE_NODE_HEIGHT / 2 - ICON_HEIGHT / 2}
        >
          <Loader size="md" />
        </foreignObject>
      ) : null}
      {!data.loading && data.expandable ? (
        <foreignObject
          width={ICON_WIDTH}
          height={ICON_HEIGHT}
          x={width - NODE_PADDING * 2}
          y={BASE_NODE_HEIGHT / 2 - ICON_HEIGHT / 2}
          className={styles.expandIconOuterContainer}
        >
          <span className={styles.expandIconInnerContainer}>
            <Icon
              name={
                graphState.expandedIds.includes(data.nodeId)
                  ? "chevron-up"
                  : "chevron-down"
              }
              size="xs"
            />
          </span>
        </foreignObject>
      ) : null}
      <foreignObject
        className={styles.label}
        x={NODE_PADDING + ICON_WIDTH + SPACING.sm}
        y={
          numChildren > 0
            ? BASE_NODE_HEIGHT / 2 - 14
            : BASE_NODE_HEIGHT / 2 - 10
        }
        height={TEXT_SPACING}
        width={
          width -
          (ICON_WIDTH + NODE_PADDING * 3 + (numChildren > 0 ? SPACING.md : 0))
        }
      >
        <Label label={data.name} oneLine />
      </foreignObject>
      {numChildren > 0 ? (
        <text
          x={NODE_PADDING + ICON_WIDTH + SPACING.sm}
          y={BASE_NODE_HEIGHT / 2 + TEXT_SPACING / 2}
          className={styles.subtitle}
        >
          <tspan>{`${numChildren} ${pluralize(childNoun, numChildren)}`}</tspan>
        </text>
      ) : null}
      <foreignObject
        width={ICON_WIDTH}
        height={ICON_HEIGHT}
        x={
          data.expandable
            ? width - ICON_WIDTH - NODE_PADDING * 2
            : width - ICON_WIDTH - NODE_PADDING
        }
        y={BASE_NODE_HEIGHT / 2 - ICON_HEIGHT / 2}
      >
        <NodeMenu options={nodeMenuOptions} />
      </foreignObject>
    </Node>
  );
};

export default ResourceNode;
