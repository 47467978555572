import {
  UserOverviewFragment,
  UserTagFragment,
  useUserDetailQuery,
} from "api/generated/graphql";
import { UserConfig } from "components/forms/common";
import { UsersConfigForm } from "components/forms/UsersConfigForm";
import { makeConfigForUser } from "components/forms/utils";
import { Loader } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useEffect, useState } from "react";
import { logError } from "utils/logging";

const AccessReviewUserOverview = ({ userId }: { userId: string }) => {
  const [config, setConfig] = useState<Partial<UserConfig>>({});
  const { data, error, loading } = useUserDetailQuery({
    variables: {
      id: userId,
    },
  });

  let user: UserOverviewFragment | null = null;
  let userAttributes: UserTagFragment[] | null = null;
  if (data) {
    switch (data.user.__typename) {
      case "UserResult":
        user = data.user.user;
        break;
      case "UserNotFoundError":
        break;
      default:
        logError(new Error(`failed to list users`));
    }
    switch (data.userAttributes.__typename) {
      case "UserTagsResult":
        userAttributes = data.userAttributes.userTags;
        break;
      default:
        logError(new Error(`failed to list users`));
    }
  } else if (error) {
    logError(error, `failed to fetch user detail ${userId}`);
  }

  useEffect(() => {
    if (user) {
      const _config = makeConfigForUser(user, userAttributes, false);
      setConfig(_config);
    }
  }, [user, userAttributes]);

  if (loading) {
    return (
      <div
        className={sprinkles({
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          padding: "lg",
        })}
      >
        <Loader label="Loading user details..." />
      </div>
    );
  }

  return (
    <div className={sprinkles({ width: "100%" })}>
      <UsersConfigForm mode="view" config={config} />
    </div>
  );
};

export default AccessReviewUserOverview;
