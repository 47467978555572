import { Button, FileUpload, Label } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useState } from "react";

export type GCPServiceAccountJSON = {
  type: string;
  project_id: string;
  private_key_id: string;
  private_key: string;
  client_email: string;
  client_id: string;
  auth_uri: string;
  token_uri: string;
  auth_provider_x509_cert_url: string;
  client_x509_cert_url: string;
};

type UploadJSONButtonProps = {
  setServiceAccountInfos: (serviceAccountInfos: GCPServiceAccountJSON) => void;
};

export const UploadServiceAccountJSONButton = (
  props: UploadJSONButtonProps
) => {
  const [uploadedFileName, setUploadedFileName] = useState<string>();

  const handleUpload = (file: File) => {
    const fileReader = new FileReader();
    fileReader.onload = async () => {
      const json = fileReader.result;
      if (json) {
        const serviceAccountInfos: GCPServiceAccountJSON = JSON.parse(
          `${json}`
        );
        props.setServiceAccountInfos(serviceAccountInfos);
      }
    };
    if (file) {
      setUploadedFileName(file.name);
      fileReader.readAsText(file);
    }
  };

  return (
    <>
      <FileUpload
        renderButton={(onClick) => (
          <Button label="Upload JSON File" onClick={onClick} borderless />
        )}
        handleUpload={handleUpload}
        accept={[".json"]}
      />
      {uploadedFileName ? (
        <div className={sprinkles({ marginTop: "xs" })}>
          <Label
            label={uploadedFileName}
            icon={{ type: "name", icon: "paperclip" }}
            iconSize="xs"
          />
        </div>
      ) : null}
    </>
  );
};
