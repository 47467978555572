import React from "react";

import * as styles from "./OpalLogo.css";

interface OpalLogoProps {
  collapsed: boolean;
}

const OpalLogo = (props: OpalLogoProps) => {
  return (
    <svg
      width={props.collapsed ? "32" : "241"}
      height="32"
      viewBox={props.collapsed ? "0 0 32 32" : "0 0 241 32"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={styles.logo}
    >
      {!props.collapsed ? (
        <>
          <path
            d="M64.3213 14.0725C64.3213 21.245 59.4438 26.0262 52.4638 26.0262C45.4838 26.0262 40.6063 21.245 40.6063 14.0725C40.6063 6.89999 45.4838 2.11874 52.4638 2.11874C59.4438 2.11874 64.3213 6.89999 64.3213 14.0725ZM43.6975 14.0725C43.6975 19.9212 47.2038 23.3162 52.4638 23.3162C57.405 23.3162 61.23 19.9212 61.23 14.0725C61.23 8.22374 57.405 4.82874 52.4638 4.82874C47.2038 4.82874 43.6975 8.22374 43.6975 14.0725Z"
            fill="black"
          />
          <path
            d="M75.7863 26.0262C73.045 26.0262 71.1325 24.9112 70.0488 22.775C69.9538 22.5837 69.89 22.52 69.73 22.52C69.6025 22.52 69.5388 22.5837 69.5388 22.7112V31.605H66.67V8.9725H69.5388V11.81C69.5388 11.9375 69.6025 12.0012 69.73 12.0012C69.89 12.0012 69.9538 11.9375 70.0488 11.7462C71.1325 9.61125 73.045 8.495 75.7863 8.495C79.7713 8.495 83.755 11.3637 83.755 17.2612C83.755 23.1587 79.77 26.0275 75.7863 26.0275V26.0262ZM75.1175 11.045C71.77 11.045 69.38 13.2762 69.38 17.2612C69.38 21.2462 71.77 23.4775 75.1175 23.4775C78.465 23.4775 80.855 21.2462 80.855 17.2612C80.855 13.2762 78.4638 11.045 75.1175 11.045Z"
            fill="black"
          />
          <path
            d="M91.4175 15.8575L97.4738 15.6025V14.71C97.4738 12.3512 96.2625 10.98 93.33 10.98C90.3975 10.98 89.2175 12.35 89.1538 14.0725H86.1888C86.3163 10.9162 88.5475 8.49374 93.3288 8.49374C98.11 8.49374 100.246 10.9162 100.246 14.8687V25.5475H97.4725V23.0287C97.4725 22.9012 97.4088 22.8375 97.2813 22.8375C97.1538 22.8375 97.0425 22.8687 96.9625 23.06C96.2138 24.8137 94.5725 26.025 91.735 26.025C87.91 26.025 85.36 23.8575 85.36 20.67C85.36 17.8012 87.2725 16.0325 91.4163 15.8562L91.4175 15.8575ZM92.215 23.54C95.4025 23.54 97.475 21.7225 97.475 18.2162V18.025L91.4188 18.28C89.1875 18.375 88.2313 19.2362 88.2313 20.67C88.2313 22.4237 89.6663 23.5387 92.2163 23.5387L92.215 23.54Z"
            fill="black"
          />
          <path
            d="M105.85 2.5975V25.5475H102.981V2.5975H105.85Z"
            fill="black"
          />
          <path
            d="M135.43 9.13249H132.338C132.179 6.13624 130.043 4.82874 126.919 4.82874C123.795 4.82874 121.786 6.11999 121.786 8.57374C121.786 11.0275 123.826 11.8575 126.121 12.255L129.023 12.765C132.385 13.355 135.653 14.885 135.653 19.22C135.653 23.555 132.019 26.025 127.269 26.025C122.519 26.025 118.184 23.57 118.025 18.0562H121.118C121.341 21.785 123.891 23.3162 127.27 23.3162C130.649 23.3162 132.593 21.77 132.593 19.2525C132.593 16.735 130.378 15.89 127.97 15.4587L125.069 14.9487C122.009 14.4075 118.725 12.8762 118.725 8.60499C118.725 4.33374 122.455 2.11874 126.918 2.11874C131.38 2.11874 135.238 4.34999 135.429 9.13124L135.43 9.13249Z"
            fill="black"
          />
          <path
            d="M146.054 8.49499C151.218 8.49499 153.959 11.8425 153.959 16.4637V18.0575H140.555C140.763 21.7862 143.041 23.54 146.118 23.54C149.194 23.54 150.564 22.17 151.026 20.2887H154.023C153.544 22.8062 151.376 26.0262 146.118 26.0262C140.859 26.0262 137.703 22.6787 137.703 17.26C137.703 11.8412 140.954 8.49374 146.054 8.49374V8.49499ZM140.635 15.6662H151.09C150.963 12.6062 149.083 10.98 146.054 10.98C143.313 10.98 141.113 12.5425 140.635 15.6662Z"
            fill="black"
          />
          <path
            d="M164.569 26.0262C159.596 26.0262 156.057 22.9025 156.057 17.26C156.057 11.6175 159.596 8.49374 164.569 8.49374C169.541 8.49374 172.283 11.5225 172.474 15.0287H169.51C169.224 12.7012 167.756 11.0437 164.569 11.0437C161.221 11.0437 158.959 13.275 158.959 17.26C158.959 21.245 161.223 23.4762 164.569 23.4762C167.915 23.4762 169.414 21.6275 169.637 19.0137H172.602C172.347 22.9987 169.543 26.0262 164.57 26.0262H164.569Z"
            fill="black"
          />
          <path
            d="M190.19 25.5487H187.321V22.8712C187.321 22.7437 187.258 22.68 187.13 22.68C186.971 22.68 186.906 22.7437 186.844 22.8712C185.92 24.72 184.454 26.0275 181.265 26.0275C177.28 26.0275 174.89 23.6375 174.89 19.3337V8.97375H177.759V18.855C177.759 21.8837 179.353 23.4775 182.221 23.4775C185.409 23.4775 187.321 21.565 187.321 17.58V8.97375H190.19V25.5487Z"
            fill="black"
          />
          <path
            d="M192.991 8.97249H195.86V11.6187C195.86 11.7775 195.924 11.8412 196.084 11.8412C196.275 11.8412 196.339 11.7462 196.403 11.5225C196.976 9.70499 198.411 8.87624 200.515 8.87624H202.396V11.6812H200.101C197.328 11.6812 195.861 12.9562 195.861 16.1437V25.5475H192.993V8.97249H192.991Z"
            fill="black"
          />
          <path
            d="M208.607 2.5975V6.4225H204.464V2.5975H208.607ZM207.97 8.9725V25.5475H205.101V8.9725H207.97Z"
            fill="black"
          />
          <path
            d="M210.673 8.97249H214.498V4.50999H217.366V8.97249H222.148V11.5225H217.366V18.3762C217.366 21.245 217.206 22.0737 217.206 22.7112C217.206 22.8712 217.238 22.9662 217.334 23.0612C217.429 23.1575 217.525 23.1887 217.684 23.1887C218.289 23.1887 219.118 22.9975 221.669 22.9975H222.148V25.5475H219.279C216.044 25.5475 214.498 24.0012 214.498 20.7662V11.5225H210.673V8.97249Z"
            fill="black"
          />
          <path
            d="M224.656 8.9725H227.525L232.275 23.8587C232.339 24.05 232.434 24.1137 232.625 24.1137C232.816 24.1137 232.911 24.05 232.975 23.8587L237.725 8.9725H240.594V9.61L234.793 27.4925C233.773 30.6162 232.084 31.605 228.576 31.605H225.516V29.055H227.206C229.119 29.055 229.884 29.2787 230.521 29.2787C230.935 29.2787 231.143 29.1187 231.255 28.8L232.179 26.3137C232.275 26.0587 232.306 25.9 232.21 25.74C232.115 25.58 232.019 25.5487 231.764 25.5487H229.819L224.655 9.61125V8.97375L224.656 8.9725Z"
            fill="black"
          />
        </>
      ) : null}
      <path
        d="M9.78 7.385L10.6413 4.21375C10.7913 3.68125 11.34 3.35875 11.8888 3.495L15.0825 4.35L15.9813 1.005L12.7875 0.149997C10.3913 -0.482503 7.90875 0.929996 7.27125 3.3075L6.41 6.47875L9.78 7.38375V7.385Z"
        fill="black"
      />
      <path
        d="M25.5213 6.48L24.66 3.30875C24.0225 0.931248 21.54 -0.481252 19.1438 0.151248L15.95 1.00625L16.8488 4.35125L20.0425 3.49625C20.5913 3.36 21.14 3.68125 21.29 4.215L22.1513 7.38625L25.5213 6.48125V6.48Z"
        fill="black"
      />
      <path
        d="M22.1513 24.615L21.29 27.7862C21.14 28.3187 20.5913 28.6412 20.0425 28.505L16.8488 27.65L15.95 30.995L19.1438 31.85C21.54 32.4825 24.0225 31.07 24.66 28.6925L25.5213 25.5212L22.1513 24.6162V24.615Z"
        fill="black"
      />
      <path
        d="M6.41 25.52L7.27125 28.6912C7.90875 31.0687 10.3913 32.4812 12.7875 31.8487L15.9813 30.9937L15.0825 27.6487L11.8888 28.5037C11.34 28.64 10.7913 28.3187 10.6413 27.785L9.78 24.6137L6.41 25.5187V25.52Z"
        fill="black"
      />
      <path
        d="M7.385 22.1862L4.21375 21.325C3.68125 21.175 3.35875 20.6262 3.495 20.0775L4.35 16.8837L1.005 15.985L0.150004 19.1787C-0.482496 21.575 0.930004 24.0575 3.3075 24.695L6.47875 25.5562L7.38375 22.1862H7.385Z"
        fill="black"
      />
      <path
        d="M6.48001 6.445L3.30876 7.30625C0.931255 7.94375 -0.481244 10.4262 0.151256 12.8225L1.00626 16.0163L4.35126 15.1175L3.49626 11.9237C3.36001 11.375 3.68126 10.8263 4.21501 10.6762L7.38626 9.815L6.48126 6.445H6.48001Z"
        fill="black"
      />
      <path
        d="M24.615 9.81375L27.7863 10.675C28.3188 10.825 28.6413 11.3737 28.505 11.9225L27.65 15.1162L30.995 16.015L31.85 12.8212C32.4825 10.425 31.07 7.9425 28.6925 7.305L25.5213 6.44375L24.6163 9.81375H24.615Z"
        fill="black"
      />
      <path
        d="M25.52 25.555L28.6913 24.6937C31.0688 24.0562 32.4813 21.5737 31.8488 19.1775L30.9938 15.9837L27.6488 16.8825L28.5038 20.0762C28.64 20.625 28.3188 21.1737 27.785 21.3237L24.6138 22.185L25.5188 25.555H25.52Z"
        fill="black"
      />
    </svg>
  );
};

export default OpalLogo;
