import React from "react";
import SetupBar from "views/setup/SetupBar";

import ImpersonationBanner from "./ImpersonationBanner";
import * as styles from "./Layout.css";
import LeftSidebar from "./left_sidebar/LeftSidebar";
import TopBar from "./TopBar";

const Layout: React.FC<{}> = (props) => {
  return (
    <div className={styles.fullPageContainer}>
      <ImpersonationBanner />
      <div className={styles.layoutContainer}>
        <LeftSidebar />
        <div className={styles.rightContainer}>
          <div className={styles.topBar}>
            <TopBar />
          </div>
          <SetupBar />
          <div className={styles.content}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default Layout;
