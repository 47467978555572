import { EntityIdTupleNullable } from "api/common/common";
import { Maybe } from "api/generated/graphql";
import clsx from "clsx";
import styles from "components/directory_listing/DirectoryListing.module.scss";
import {
  ResourceIndividualRoleAutocomplete,
  RoleData,
} from "components/modals/ResourceIndividualRoleModal";
import { Icon } from "components/ui";
import React, { ReactElement } from "react";

export type DirectoryListingEntryInfo = {
  entityId: EntityIdTupleNullable;
  label: ReactElement | string;
  clickHandler?: () => void;
  isBold: boolean;
  isHighlighted?: boolean;
  getRightSideElement?: (
    entityId: EntityIdTupleNullable
  ) => Maybe<ReactElement>;
  entitySearchableText?: String;
};

export type DirectoryListingEntriesProps = {
  entryInfos: DirectoryListingEntryInfo[];
  onDeleteEntry?: (entry: DirectoryListingEntryInfo) => void;
  roleData?: RoleData;
  isImpersonationResource?: boolean;
};

export const DirectoryListingEntries = (
  props: DirectoryListingEntriesProps
) => {
  return (
    <div>
      {props.entryInfos.map((entryInfo) => {
        return (
          <DirectoryListingEntry
            key={`entry_${entryInfo.entityId.entityId}`}
            entryInfo={entryInfo}
            roleData={props.roleData}
            isImpersonationResource={props.isImpersonationResource}
            onDeleteEntry={props.onDeleteEntry}
          />
        );
      })}
    </div>
  );
};

export type DirectoryListingEntryProps = {
  entryInfo: DirectoryListingEntryInfo;
  roleData?: RoleData;
  isImpersonationResource?: boolean;
  onDeleteEntry?: (entry: DirectoryListingEntryInfo) => void;
};

export const DirectoryListingEntry = (props: DirectoryListingEntryProps) => {
  const rightSideElement =
    props.entryInfo.getRightSideElement &&
    props.entryInfo.getRightSideElement(props.entryInfo.entityId);

  const selectable = !!props.entryInfo.clickHandler;
  let checkedData = null;
  if (props.roleData?.checkedDataItems && props.entryInfo.entityId.entityId) {
    checkedData = props.roleData.checkedDataItems.find(
      (item) => item.entityId === props.entryInfo.entityId.entityId
    );
  }

  return (
    <>
      <div
        className={clsx({
          [styles.entry]: true,
          [styles.bold]: props.entryInfo.isBold,
          [styles.unselectableLabel]: !selectable,
          [styles.highlighted]: props.entryInfo.isHighlighted,
        })}
      >
        <span
          className={clsx({
            [styles.label]: selectable,
            [styles.unselectableLabel]: !selectable,
          })}
          onClick={() => {
            selectable &&
              props.entryInfo.clickHandler &&
              props.entryInfo.clickHandler();
          }}
        >
          {props.entryInfo.label}
        </span>
        <span
          className={clsx({
            [styles.rightGroup]: true,
          })}
        >
          {rightSideElement}
          {props.onDeleteEntry ? (
            <span
              className={clsx({
                [styles.deleteIcon]: true,
              })}
            >
              <Icon
                name="x"
                onClick={() =>
                  props.onDeleteEntry && props.onDeleteEntry(props.entryInfo)
                }
                size="sm"
              />
            </span>
          ) : null}
        </span>
      </div>
      {!!checkedData && props.roleData?.autocompleteData && (
        <ResourceIndividualRoleAutocomplete
          {...checkedData}
          {...props.roleData.autocompleteData}
          isImpersonationResource={props.isImpersonationResource}
        />
      )}
    </>
  );
};
