import clsx from "clsx";
import React, { ReactElement } from "react";

import * as styles from "./AccessReviewTiles.css";

export type TileInfo = {
  key: string;
  icon: ReactElement;
  header: string;
  subHeader: string;
  description: string;
  progressPercentage?: number;
  onClick?: () => void;
  hide?: boolean;
  descriptionColor?: "default" | "orange" | "green";
};

type AccessReviewTilesProps = {
  tileInfos: TileInfo[];
};

export const AccessReviewTiles = (props: AccessReviewTilesProps) => {
  return (
    <div className={styles.accessReviewTiles}>
      {props.tileInfos.map((tileInfo) =>
        tileInfo.hide ? null : (
          <AccessReviewTile tileInfo={tileInfo} key={tileInfo.key} />
        )
      )}
    </div>
  );
};

type AccessReviewTileProps = {
  tileInfo: TileInfo;
};

export const AccessReviewTile = (props: AccessReviewTileProps) => {
  return (
    <div
      className={clsx(styles.accessReviewTile, {
        [styles.clickable]: !!props.tileInfo.onClick,
      })}
      onClick={() => props.tileInfo.onClick?.()}
    >
      <div className={styles.icon}>{props.tileInfo.icon}</div>
      <div className={styles.header}>{props.tileInfo.header}</div>
      <div className={styles.subHeader}>{props.tileInfo.subHeader}</div>
      {props.tileInfo.progressPercentage !== undefined && (
        <AccessReviewTileProgressBar
          progressPercentage={props.tileInfo.progressPercentage}
          tileKey={props.tileInfo.key}
        />
      )}
      <div
        className={styles.description({
          color: props.tileInfo.descriptionColor ?? "default",
        })}
      >
        {props.tileInfo.description}
      </div>
    </div>
  );
};

type AccessReviewTileProgressBarProps = {
  progressPercentage: number;
  tileKey: string;
};

export const AccessReviewTileProgressBar = (
  props: AccessReviewTileProgressBarProps
) => {
  return (
    <div
      style={{
        width: "240px",
        height: "10px",
        background: "#F5F5F5",
        borderRadius: "100px",
        position: "relative",
        marginTop: "12px",
        marginBottom: "18px",
      }}
    >
      <span
        style={{
          background: props.tileKey === "days-since" ? "#E52E2E" : "#2CB929",
          borderRadius: "100px",
          position: "absolute",
          left: 0,
          top: 0,
          height: "100%",
          width: props.progressPercentage + "%",
          transition: ".4s",
        }}
      />
    </div>
  );
};

export default AccessReviewTiles;
