import { TagPill } from "components/pills/TagPills";
import { DataElement, DragBubbles, Icon, Modal } from "components/ui";
import { useState } from "react";
import { DropResult } from "react-beautiful-dnd";

import { ConfigTemplateTag } from "./TemplateMappingsView";
interface ModalProps {
  onClose: () => void;
  isAdmin?: boolean;
  configTemplateTags: ConfigTemplateTag[];
  setConfigTemplateTags: (newConfigTemplateTags: ConfigTemplateTag[]) => void;
  reorderPriorities: (configs: ConfigTemplateTag[]) => ConfigTemplateTag[];
  updateMappings: (newConfigTemplateTags: ConfigTemplateTag[]) => void;
}

const ReorderTemplateMappingModal: React.FC<ModalProps> = ({
  onClose,
  reorderPriorities,
  isAdmin,
  configTemplateTags,
  updateMappings,
}) => {
  const [currentOrdering, setCurrentOrdering] = useState<ConfigTemplateTag[]>(
    configTemplateTags
  );

  const handleDragEnd = (result: DropResult) => {
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination?.index;

    if (destinationIndex === undefined || sourceIndex === destinationIndex)
      return;

    const newMappings = currentOrdering.slice();
    const [removed] = newMappings.splice(sourceIndex, 1);
    newMappings.splice(destinationIndex, 0, removed);
    setCurrentOrdering(reorderPriorities(newMappings));
  };

  return (
    <Modal
      title={`Reorder Template Mappings`}
      subtitle={`If items have multiple matching tags, the highest priority mapping will be used`}
      isOpen
      onClose={onClose}
      fullWidth
    >
      <Modal.Body>
        <DragBubbles
          handleDragEnd={handleDragEnd}
          isDragDisabled={!isAdmin}
          items={currentOrdering.map((configTemplateTag) => ({
            id: configTemplateTag.id,
            priorityLabel: configTemplateTag.priority,
            isDragDisabled: false,
            titleContent: (
              <>
                {configTemplateTag.tag && (
                  <TagPill
                    tagId={configTemplateTag.tag.id}
                    tag={configTemplateTag.tag}
                  />
                )}
                <Icon name="arrow-right" />
                {configTemplateTag.configTemplate && (
                  <DataElement
                    color="blue"
                    leftIcon={{ name: "template" }}
                    label={configTemplateTag.configTemplate.name}
                    size="sm"
                  />
                )}
              </>
            ),
          }))}
        />
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Save"
        onPrimaryButtonClick={() => {
          updateMappings(currentOrdering);
          onClose();
        }}
        primaryButtonLoading={false}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={onClose}
        secondaryButtonDisabled={false}
      />
    </Modal>
  );
};

export default ReorderTemplateMappingModal;
