import {
  GroupBindingGroupPreviewFragment,
  GroupPreviewTinyFragment,
} from "api/generated/graphql";
import { Icon, Popover } from "components/ui";
import sprinkles from "css/sprinkles.css";

import GroupBindingGroupLabel from "./GroupBindingGroupLabel";

type Props = {
  sourceGroup?: GroupPreviewTinyFragment;
  groups: GroupBindingGroupPreviewFragment[];
};

const GroupBindingGroupsRow: React.FC<Props> = ({ sourceGroup, groups }) => {
  const moreGroups = groups.filter(
    (group) => Boolean(group) && group?.id !== sourceGroup?.id
  );
  const otherGroup = moreGroups.splice(0, 1)[0];

  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "row",
        gap: "md",
        alignItems: "center",
      })}
    >
      <GroupBindingGroupLabel group={sourceGroup} />
      <Icon name="link" size="xxs" color="gray500" />
      <GroupBindingGroupLabel group={otherGroup} />
      {moreGroups.length > 0 && (
        <Popover
          inline
          content={
            <div
              className={sprinkles({
                display: "flex",
                flexDirection: "column",
                gap: "md",
                fontSize: "labelMd",
              })}
            >
              {moreGroups.map((group) => {
                return <GroupBindingGroupLabel key={group?.id} group={group} />;
              })}
            </div>
          }
        >
          {`${moreGroups.length} more`}
        </Popover>
      )}
    </div>
  );
};

export default GroupBindingGroupsRow;
