import { useBulkUpdateMutation } from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import { ResourceConfig } from "components/forms/common";
import ResourcesConfigForm from "components/forms/ResourcesConfigForm";
import {
  makeRequestConfigsInput,
  validateResourceConfig,
} from "components/forms/utils";
import { useToast } from "components/toast/Toast";
import { Banner, Button, DataElement, Divider } from "components/ui";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import { useWarnBeforeUnload } from "utils/hooks";
import { logError } from "utils/logging";
import useSyncStatusToast from "utils/sync/useSyncStatusToast";
import OrgContext from "views/settings/OrgContext";

import { AppsContext, SelectedItem } from "./AppsContext";
import * as styles from "./BulkEditColumn.css";
import BulkEditConfirmModal from "./BulkEditConfirmModal";

export const itemEntityType = (item: SelectedItem) => {
  if ("resourceType" in item) {
    return item.resourceType;
  } else {
    return item.groupType;
  }
};

const BulkEditColumn = () => {
  const {
    selectedItems,
    toggleItem,
    bulkMode,
    setBulkMode,
    clearSelectedItems,
  } = useContext(AppsContext);
  const { orgState } = useContext(OrgContext);
  const [config, setConfig] = useState<Partial<ResourceConfig>>({});
  const [errors, setErrors] = useState<string[]>([]);
  const [showConfirmModal, setShowConfirmModal] = useState<boolean>();
  const logEvent = useLogEvent();

  const showSyncStatusToast = useSyncStatusToast({
    loadingText: "Successfully updated, now syncing...",
    queriesToRefetch: ["ItemsListSection"],
  });
  const { displaySuccessToast } = useToast();

  const hasChange =
    config !== undefined &&
    Object.values(config).length > 0 &&
    Object.values(config).some((value) => value !== undefined);

  useWarnBeforeUnload(hasChange);

  const [bulkUpdate, { loading }] = useBulkUpdateMutation({
    refetchQueries: ["ResourceDetailColumn", "Group"],
  });

  const selectedEditableItems = selectedItems.filter((item) =>
    item.authorizedActions?.includes(AuthorizedActionManage)
  );
  const selectedUneditableItems = selectedItems.filter(
    (item) => !item.authorizedActions?.includes(AuthorizedActionManage)
  );

  const hasBulkSelectedItemsWithConfigTemplate = selectedEditableItems.some(
    (item) => item.configTemplate
  );

  if (bulkMode !== "edit" || selectedItems.length === 0) {
    return null;
  }

  const handleSave = async () => {
    const errors = validateResourceConfig(config, orgState);

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    const groupIds: string[] = [];
    const resourceIds: string[] = [];
    selectedEditableItems.forEach((item) => {
      if ("resourceType" in item) {
        resourceIds.push(item.id);
      } else {
        groupIds.push(item.id);
      }
    });

    logEvent({
      name: "apps_bulk_select_edit_view",
      properties: {
        numSelected: selectedEditableItems.length,
      },
    });

    try {
      setErrors([]);
      const input = {
        resourceIds,
        groupIds,
        description: config.description,
        adminOwnerId: config.adminOwner?.id,
        configurationId: config.configurationTemplate
          ? {
              configurationId: config.configurationTemplate?.id,
            }
          : undefined,
        visibility: config.visibility,
        visibilityGroupsIds: config.visibilityGroups,
        messageChannelIds: config.messageChannels?.map((channel) => channel.id),
        requireMfaToApprove: config.requireMfaToApprove,
        requireMfaToConnect: config.requireMfaToConnect,
        breakGlassUsersIds: config.breakGlassUsers?.map((user) => user.id),
        onCallSchedules: config.onCallSchedules?.map((schedule) => ({
          scheduleName: schedule.name,
          remoteId: schedule.remoteId,
          thirdPartyProvider: schedule.thirdPartyProvider,
        })),
        tagIds: config.tagIds,
        commonMetadata: config.commonMetadata,
        requestConfigs: makeRequestConfigsInput(config.requestConfigs ?? []),
        forkConfigurationTemplates: config.forkConfigurationTemplates,
        useParentConfig: config.useParentConfig,
        ticketPropagation: config.ticketPropagation,
      };
      const { data } = await bulkUpdate({
        variables: {
          input,
        },
      });

      let updatedItemsCount = 0;
      switch (data?.bulkUpdateItems.__typename) {
        case "BulkUpdateItemsResult":
          clearSelectedItems();
          if (data.bulkUpdateItems.syncTask?.id) {
            showSyncStatusToast(data.bulkUpdateItems.syncTask.id);
          } else {
            updatedItemsCount =
              (data.bulkUpdateItems.updatedGroups?.length ?? 0) +
              (data.bulkUpdateItems.updatedResources?.length ?? 0);
            displaySuccessToast(
              `Successfully updated ${pluralize(
                "item",
                updatedItemsCount,
                true
              )}`
            );
          }
          break;
        case "InvalidUpdateResourceVisibilityGroupError":
        case "InvalidUpdateGroupVisibilityGroupError":
          setErrors(["Invalid visibility group"]);
          break;
        case "InvalidReviewerSettingsError":
          setErrors(["Invalid reviewer stage configuration"]);
          break;
        case "GroupMaxDurationTooLargeError":
        case "ResourceMaxDurationTooLargeError":
          setErrors([
            "Max access duration cannot exceed org-wide max duration setting",
          ]);
          break;
        case "CannotUpdateConfigurationTemplateError":
          setErrors([
            "One or more items are linked to a configuration template. To change settings controlled by templates, you must first unlink the template.",
          ]);
          break;
        default:
          logError("Failed to bulk update items");
          setErrors(["Failed to update items."]);
      }
    } catch (err) {
      logError(err, "Failed to bulk update items");
      setErrors(["Failed to update items."]);
    }
  };

  const actionButtons = (
    <div className={sprinkles({ display: "flex", gap: "sm" })}>
      <Button
        label="Cancel"
        borderless
        onClick={() => setBulkMode(undefined)}
      />
      <Button
        label={loading ? "Saving..." : "Save"}
        leftIconName="check"
        type="primary"
        onClick={() => {
          const errors = validateResourceConfig(config, orgState);
          if (errors.length > 0) {
            setErrors(errors);
            return;
          }
          setShowConfirmModal(true);
        }}
        disabled={loading || !hasChange || selectedEditableItems.length === 0}
      />
    </div>
  );

  return (
    <Column isContent maxWidth="lg">
      {showConfirmModal && (
        <BulkEditConfirmModal
          isOpen={showConfirmModal}
          onClose={() => setShowConfirmModal(false)}
          onSaveClick={() => {
            handleSave();
            setShowConfirmModal(false);
          }}
          editConfig={config}
          selectedItems={selectedEditableItems}
        />
      )}
      <ColumnHeader
        title={`Edit ${selectedEditableItems.length} ${pluralize(
          "resource",
          selectedEditableItems.length
        )}`}
        icon={{ type: "name", icon: "edit-2" }}
        rightActions={actionButtons}
        onClose={() => setBulkMode(undefined)}
      />
      <Divider margin="md" />
      <ColumnContent>
        {selectedEditableItems.length > 0 && (
          <div className={styles.entityListContainer}>
            {selectedEditableItems.map((item) => {
              return (
                <DataElement
                  label={item.name}
                  color="blue"
                  onClick={() => toggleItem(item)}
                  rightIcon={{ name: "x" }}
                  leftIcon={{
                    data: { type: "entity", entityType: itemEntityType(item) },
                  }}
                />
              );
            })}
          </div>
        )}
        {selectedUneditableItems.length > 0 && (
          <>
            <Divider
              labelPosition="left"
              label={
                selectedUneditableItems.length === 1
                  ? "This item cannot be edited"
                  : "These items cannot be edited"
              }
            />
            <div className={styles.entityListContainer}>
              {selectedUneditableItems.map((item) => {
                return (
                  <DataElement
                    label={item.name}
                    color="gray"
                    onClick={() => toggleItem(item)}
                    rightIcon={{ name: "x" }}
                    leftIcon={{
                      data: {
                        type: "entity",
                        entityType: itemEntityType(item),
                      },
                    }}
                  />
                );
              })}
            </div>
          </>
        )}
        {errors.map((error) => (
          <Banner message={error} type="error" />
        ))}
        <Divider margin="md" />
        <ResourcesConfigForm
          config={config}
          onChange={setConfig}
          hasBulkSelectedItemsWithConfigTemplate={
            hasBulkSelectedItemsWithConfigTemplate
          }
          mode="edit"
        />
      </ColumnContent>
    </Column>
  );
};

export default BulkEditColumn;
