import {
  GroupBindingGroupPreviewFragment,
  GroupBindingGroupWithUsersFragment,
  GroupPreviewTinyFragment,
  useGroupBindingGroupUserIdsQuery,
} from "api/generated/graphql";
import GroupSearchDropdown from "components/dropdown/GroupSearchDropdown";
import PaginatedGroupBindingDropdown from "components/dropdown/PaginatedGroupBindingDropdown";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import UserLabel from "components/label/item_labels/UserLabel";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { Divider, Modal, Popover, RadioGroup, Skeleton } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { produce } from "immer";
import _ from "lodash";
import { useEffect, useMemo, useReducer, useState } from "react";
import { logError } from "utils/logging";

import { formatGroupName } from "../common";
import { CompareGroupUsersTable } from "./GroupBindingCompareModal";
import * as styles from "./GroupBindingEditModalBase.css";

export type GroupBindingState = {
  id: string;
  sourceGroup?: GroupBindingGroupPreviewFragment;
  groups: GroupBindingGroupPreviewFragment[];
};

const isValidState = (binding: GroupBindingState) => {
  return binding.sourceGroup && binding.groups.length > 1;
};

const MAX_GROUPS_IN_BINDING = 4;

enum groupBindingEditActionType {
  addGroup,
  removeGroup,
  changeSource,
  setInitialState,
}

type groupBindingEditAction =
  | {
      type:
        | groupBindingEditActionType.addGroup
        | groupBindingEditActionType.removeGroup
        | groupBindingEditActionType.changeSource;
      payload: {
        id: string;
        group: GroupPreviewTinyFragment;
      };
    }
  | {
      type: groupBindingEditActionType.setInitialState;
      payload: GroupBindingState[];
    };

const editReducer = (
  state: GroupBindingState[],
  action: groupBindingEditAction
) => {
  switch (action.type) {
    case groupBindingEditActionType.setInitialState:
      return action.payload.map((binding) => ({
        id: binding.id,
        sourceGroup: binding.sourceGroup ?? undefined,
        groups: binding.groups,
      }));

    case groupBindingEditActionType.addGroup: {
      const { id: bindingId, group } = action.payload;
      return produce(state, (draft) => {
        const binding = draft.find((b) => b.id === bindingId);
        if (binding) {
          binding.groups = _.uniqBy([...binding.groups, group], "id");
        }
      });
    }
    case groupBindingEditActionType.removeGroup: {
      const { id: bindingId, group } = action.payload;
      return produce(state, (draft) => {
        const binding = draft.find((b) => b.id === bindingId);
        if (binding) {
          binding.groups = binding.groups.filter((g) => g.id !== group.id);
          binding.sourceGroup =
            binding.sourceGroup?.id === group.id
              ? undefined
              : binding.sourceGroup;
        }
      });
    }
    case groupBindingEditActionType.changeSource: {
      const { id: bindingId, group } = action.payload;
      return produce(state, (draft) => {
        const binding = draft.find((b) => b.id === bindingId);
        if (binding) {
          binding.sourceGroup = group;
        }
      });
    }
  }
};

type Props = {
  title: string;
  subtitle?: string;
  isOpen: boolean;
  submitButtonLabel: string;
  onModalSubmit: (bindings: GroupBindingState[]) => void;
  onModalClose: () => void;
  loading?: boolean;
  initialGroupBindings: GroupBindingState[];
  errorMessage?: string;
  allowMergingExistingBindings?: boolean;
};

enum editModeType {
  Merge,
  New,
}

const GroupBindingEditModalBase = (props: Props) => {
  const [editState, dispatch] = useReducer(editReducer, []);
  const [editMode, setEditMode] = useState<editModeType>(editModeType.New);

  useEffect(() => {
    if (props.isOpen && editState.length === 0) {
      dispatch({
        type: groupBindingEditActionType.setInitialState,
        payload: props.initialGroupBindings,
      });
    }
  }, [props.isOpen, props.initialGroupBindings, editState.length]);

  const loadingContent = (
    <>
      <Skeleton width="500px" />
      <Skeleton width="500px" />
      <Skeleton width="500px" />
      <Skeleton width="200px" />
    </>
  );

  const mergeState = editState[0]?.groups.length > 1 ? editState[0] : undefined;
  const mergeExistingBindingsContent = (
    <>
      <PaginatedGroupBindingDropdown
        clearable
        selectOnly
        placeholder="Select or search a source of truth group to link to"
        value={
          mergeState
            ? {
                id: mergeState?.sourceGroup?.id ?? "",
                sourceGroupId: mergeState?.sourceGroup?.id ?? "",
                groups: mergeState?.groups ?? [],
                sourceGroup: mergeState?.sourceGroup ?? undefined,
              }
            : undefined
        }
        onChange={(groupBinding) => {
          if (groupBinding) {
            dispatch({
              type: groupBindingEditActionType.setInitialState,
              payload: [
                {
                  id: groupBinding.id,
                  sourceGroup: groupBinding.sourceGroup ?? undefined,
                  groups: [
                    ...groupBinding.groups,
                    ...props.initialGroupBindings[0].groups,
                  ],
                },
              ],
            });
          } else {
            dispatch({
              type: groupBindingEditActionType.setInitialState,
              payload: props.initialGroupBindings,
            });
          }
        }}
      />
      <CompareGroupUsers
        groupIds={mergeState?.groups.map((g) => g?.id) ?? []}
        sourceGroupId={mergeState?.sourceGroup?.id ?? ""}
      />
      {mergeState && (
        <>
          <div className={sprinkles({ paddingTop: "md" })}>
            Updated groups in the binding:
          </div>
          <RadioGroup
            options={mergeState?.groups ?? []}
            getOptionLabel={(option) => formatGroupName(option)}
            getOptionIcon={(option) => ({
              type: "src",
              icon: getGroupTypeInfo(option?.groupType)?.icon,
            })}
            getOptionKey={(option) => option.id}
            value={mergeState?.sourceGroup}
            onSelectValue={(group) => {
              if (!group) {
                return;
              }
              dispatch({
                type: groupBindingEditActionType.changeSource,
                payload: { id: mergeState.id, group },
              });
            }}
            getOptionRightSideAction={(option) => {
              if (mergeState.groups.length <= 2) {
                return undefined;
              }
              return {
                iconName: "x-close",
                onClick: () => {
                  dispatch({
                    type: groupBindingEditActionType.removeGroup,
                    payload: { id: mergeState.id, group: option },
                  });
                },
              };
            }}
          />
        </>
      )}
    </>
  );

  // based on bulkMode we need to show the compare component above or below the group search dropdown
  const bulkMode = editState.length > 1;

  const updateOrCreateBindingsContent = editState.map((binding, index) => (
    <>
      <div key={binding.id} id={binding.id} className={styles.editModalContent}>
        <span className={styles.editModalSourceHint}>
          Please select the source of truth
          {binding.groups.length < 2 && <> (at least 2 groups are required)</>}
        </span>
        <RadioGroup
          options={binding.groups}
          getOptionLabel={(option) => formatGroupName(option)}
          getOptionIcon={(option) => ({
            type: "src",
            icon: getGroupTypeInfo(option?.groupType)?.icon,
          })}
          getOptionKey={(option) => option.id}
          value={binding.sourceGroup}
          onSelectValue={(group) =>
            dispatch({
              type: groupBindingEditActionType.changeSource,
              payload: { id: binding.id, group },
            })
          }
          getOptionRightSideAction={(option) => {
            if (binding.groups.length <= 2) {
              return undefined;
            }
            return {
              iconName: "x-close",
              onClick: () => {
                dispatch({
                  type: groupBindingEditActionType.removeGroup,
                  payload: { id: binding.id, group: option },
                });
              },
            };
          }}
        />
        {bulkMode && (
          <CompareGroupUsers
            groupIds={binding.groups.map((g) => g?.id)}
            sourceGroupId={binding.sourceGroup?.id ?? ""}
            bulkMode={bulkMode}
          />
        )}
        {binding.groups.length >= MAX_GROUPS_IN_BINDING ? (
          <span className={styles.editModalSourceHint}>
            No more than 4 groups are allowed in a binding at a time.
          </span>
        ) : null}
        <GroupSearchDropdown
          alwaysShowPlaceholder
          selectedGroupIds={binding.groups.map((g) => g.id)}
          placeholder="Add a new group to this binding"
          limitSelectedGroups={MAX_GROUPS_IN_BINDING}
          disabledConnectionIds={
            binding.sourceGroup ? [binding.sourceGroup?.connectionId] : []
          }
          onSelect={(data) => {
            switch (data.actionType) {
              case "select-option":
                dispatch({
                  type: groupBindingEditActionType.addGroup,
                  payload: {
                    id: binding.id,
                    group: {
                      ...data.groups[0],
                    },
                  },
                });
                break;
              case "remove-option":
                dispatch({
                  type: groupBindingEditActionType.removeGroup,
                  payload: {
                    id: binding.id,
                    group: {
                      ...data.groups[0],
                    },
                  },
                });
                break;
            }
          }}
        />
        {!bulkMode && (
          <CompareGroupUsers
            groupIds={binding.groups.map((g) => g?.id)}
            sourceGroupId={binding.sourceGroup?.id ?? ""}
          />
        )}
      </div>
      {index !== editState.length - 1 && <Divider margin="lg" />}
    </>
  ));

  return (
    <Modal
      title={props.title}
      subtitle={props.subtitle}
      isOpen={props.isOpen}
      onClose={props.onModalClose}
      maxWidth={"lg"}
    >
      <Modal.Body>
        <>
          {props.loading ? (
            loadingContent
          ) : (
            <>
              {props.allowMergingExistingBindings && (
                <>
                  <RadioGroup
                    inline
                    options={[editModeType.New, editModeType.Merge]}
                    value={editMode}
                    onSelectValue={(option) => {
                      setEditMode(option);
                      if (option === editModeType.Merge) {
                        dispatch({
                          type: groupBindingEditActionType.setInitialState,
                          payload: [],
                        });
                      } else {
                        dispatch({
                          type: groupBindingEditActionType.setInitialState,
                          payload: props.initialGroupBindings,
                        });
                      }
                    }}
                    getOptionLabel={(option) => {
                      switch (option) {
                        case editModeType.New:
                          return "Create new group link";
                        case editModeType.Merge:
                          return "Add to existing linked groups";
                      }
                    }}
                    getOptionKey={(option) => option}
                  />
                  <Divider margin="lg" />
                </>
              )}
              {editMode === editModeType.Merge && (
                <>{mergeExistingBindingsContent}</>
              )}
              {editMode === editModeType.New && (
                <>{updateOrCreateBindingsContent}</>
              )}
            </>
          )}
          {props.errorMessage && (
            <ModalErrorMessage errorMessage={props.errorMessage} />
          )}
        </>
      </Modal.Body>
      <Modal.Footer
        onPrimaryButtonClick={() =>
          props.onModalSubmit(
            editState.map<GroupBindingState>((s) => ({
              id: s.id,
              sourceGroup: s.sourceGroup,
              groups: s.groups,
            }))
          )
        }
        primaryButtonLabel={props.submitButtonLabel}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={props.onModalClose}
        primaryButtonDisabled={
          props.loading || editState.some((s) => !isValidState(s))
        }
      />
    </Modal>
  );
};

const CompareGroupUsers = (props: {
  groupIds: string[];
  sourceGroupId: string;
  bulkMode?: boolean;
}) => {
  const { data, error, loading } = useGroupBindingGroupUserIdsQuery({
    variables: {
      input: props.groupIds,
    },
  });

  const groups = data?.groups.groups ?? [];

  if (error) {
    logError(error);
    return <>Failed to load group users for the groups above.</>;
  }

  if (props.groupIds.length < 2) {
    return <></>;
  }

  if (props.bulkMode) {
    if (loading) {
      return <Skeleton height="24px" width="400px" />;
    }
    return <CompareGroupUsersPopover groups={groups} />;
  }

  return <CompareGroupUsersTable groups={groups} />;
};

const CompareGroupUsersPopover = ({
  groups,
}: {
  groups: GroupBindingGroupWithUsersFragment[];
}) => {
  const uniqueUserIds = useMemo(() => {
    const users =
      groups.map((group) => group.groupUsers.map((u) => u.userId)) ?? [];
    return _.difference(_.union(...users), _.intersection(...users));
  }, [groups]);
  const userById = useMemo(() => {
    const users =
      groups.flatMap((group) => group.groupUsers.map((u) => u.user)) ?? [];
    return _.keyBy(users, "id");
  }, [groups]);

  if (uniqueUserIds.length === 0) {
    return <>All members in common</>;
  }

  return (
    <Popover
      inline
      content={
        <div className={styles.editModalGroupComparisonPopover}>
          <div>Unique users:</div>
          {uniqueUserIds.map((userId) => {
            const user = userById[userId];
            if (!user) {
              return null;
            }
            return (
              <UserLabel
                key={userId}
                avatar={user.avatarUrl}
                name={user.fullName}
              />
            );
          })}
        </div>
      }
    >
      {`${uniqueUserIds.length} unique members`}
    </Popover>
  );
};

export default GroupBindingEditModalBase;
