import {
  GroupType,
  OnCallMetadataInput,
  ServiceType,
} from "api/generated/graphql";

export type GroupCreationInfo = {
  name: string;
  adminOwnerId?: string;
  connectionId?: string;
  groupType: GroupType;
  description: string;
  serviceType: ServiceType;
  onCallMetadata?: OnCallMetadataInput;
};

export const subtitleFormat = (step: number, totalSteps: number) => {
  return `Step ${step} of ${totalSteps}`;
};
