import {
  ResourcePreviewLargeFragment,
  ServiceType,
} from "api/generated/graphql";
import {
  Section,
  SectionColumn,
  SectionRow,
  ViewerOverview,
} from "components/viewer/Viewer";
import React from "react";
import AccessReviewSnapshotRow from "views/access_reviews/AccessReviewSnapshotRow";
import ResourceAdminRow from "views/resources/viewer/rows/ResourceAdminRow";
import { ResourceConnectionRow } from "views/resources/viewer/rows/ResourceConnectionRow";
import { ResourceDescriptionRow } from "views/resources/viewer/rows/ResourceDescriptionRow";
import ResourceRemoteIdRow from "views/resources/viewer/rows/ResourceRemoteIdRow";
import ResourceRemoteNameRow from "views/resources/viewer/rows/ResourceRemoteNameRow";
import { ResourceTypeRow } from "views/resources/viewer/rows/ResourceTypeRow";
import ResourceVisibilityRow from "views/resources/viewer/rows/ResourceVisibilityRow";

type AccessReviewResourceOverviewProps = {
  resource: ResourcePreviewLargeFragment;
  createdAt: string;
};

export const AccessReviewResourceOverviewRow = (
  props: AccessReviewResourceOverviewProps
) => {
  return (
    <ViewerOverview>
      <SectionColumn large>
        <AccessReviewResourceOverviewSection
          resource={props.resource}
          createdAt={props.createdAt}
        />
      </SectionColumn>
    </ViewerOverview>
  );
};

type AccessReviewResourceOverviewSectionProps = {
  resource: ResourcePreviewLargeFragment;
  disableEditor?: boolean;
  hideBorder?: boolean;
  createdAt: string;
};

export const AccessReviewResourceOverviewSection = (
  props: AccessReviewResourceOverviewSectionProps
) => {
  const { resource, createdAt } = props;
  return resource ? (
    <Section
      style={
        props.hideBorder ? { border: "none", boxShadow: "none" } : undefined
      }
    >
      <AccessReviewSnapshotRow snapshotTime={createdAt} />
      {resource.serviceType !== ServiceType.Opal && (
        <SectionRow>
          <ResourceRemoteNameRow resource={resource} />
          <ResourceRemoteIdRow resource={resource} />
        </SectionRow>
      )}
      <SectionRow>
        <ResourceTypeRow resource={resource} />
        <ResourceConnectionRow resource={resource} />
      </SectionRow>
      <SectionRow>
        <ResourceAdminRow resource={resource} />
        <ResourceVisibilityRow resource={resource} />
      </SectionRow>
      <SectionRow>
        <ResourceDescriptionRow resource={resource} />
      </SectionRow>
    </Section>
  ) : (
    <></>
  );
};

export default AccessReviewResourceOverviewRow;
