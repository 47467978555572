import {
  ConnectionType,
  EntityType,
  RemoveUserTagInput,
  ServiceType,
  TagFragment,
  useRemoveUserTagsMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { ResourceLabel } from "components/label/Label";
import ServiceTypeLabel from "components/label/ServiceTypeLabel";
import { useToast } from "components/toast/Toast";
import { Modal } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import _ from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { logError } from "utils/logging";

import ConnectionLabel from "../../components/label/item_labels/ConnectionLabel";

type TagUsersTableProps = {
  tag: TagFragment;
};

interface UserRow {
  id: string;
  user?: {
    id?: string;
    fullName?: string;
    avatarUrl?: string;
  };
  tagSource: ServiceType;
  connection?: {
    id?: string;
    name?: string;
    connectionType?: ConnectionType;
  };
  createdAt: string;
}

const USERS_COLUMNS: Header<UserRow>[] = [
  {
    id: "user",
    label: "Name",
    sortable: false,
    customCellRenderer: (user) => (
      <ResourceLabel
        text={user.user?.fullName}
        entityTypeNew={EntityType.User}
        avatar={user?.user?.avatarUrl}
        entityId={user?.id}
        url={`/users/${user?.user?.id}`}
      />
    ),
  },
  {
    id: "tagSource",
    label: "Tag Source",
    sortable: false,
    customCellRenderer: (row) => {
      if (row.connection?.id == null) {
        return <ServiceTypeLabel serviceType={row.tagSource} />;
      }

      return (
        <ConnectionLabel
          text={row.connection?.name}
          connectionId={row.connection?.id}
          connectionType={row.connection?.connectionType}
        />
      );
    },
  },
  {
    id: "createdAt",
    label: "Created",
    sortable: false,
    customCellRenderer: (row) => moment(new Date(row.createdAt)).fromNow(),
  },
];

type RemoveUsersFromTagModalProps = {
  idsToRemove: string[];
  setShowModal: (show: boolean) => void;
  setSelectedItemIds: (ids: string[]) => void;
};

const RemoveUsersFromTagModal = ({
  idsToRemove,
  setShowModal,
  setSelectedItemIds,
}: RemoveUsersFromTagModalProps) => {
  const [removeTagUsers, { loading }] = useRemoveUserTagsMutation();
  const { displaySuccessToast, displayErrorToast } = useToast();

  const removeSelectedUsers = async () => {
    const userTagsToRemove: RemoveUserTagInput[] = idsToRemove.map(
      (userTagId) => ({ userTagId: userTagId })
    );

    try {
      const { data } = await removeTagUsers({
        variables: {
          input: {
            userTags: userTagsToRemove,
          },
        },
        refetchQueries: ["UserTags", "Tag"],
      });

      switch (data?.removeUserTags.__typename) {
        case "RemoveUserTagsResult": {
          let userTags: string[] = [];
          data.removeUserTags.entries.forEach((entry) => {
            switch (entry.__typename) {
              case "RemoveUserTagsEntryResult":
                userTags.push(entry.userTag.userId);
                break;
            }
          });
          if (userTags.length !== idsToRemove.length) {
            logError(new Error(`removed tag to some, but not all users`));
            displayErrorToast(`Error: removed tag to some, but not all users`);
            setSelectedItemIds(_.difference(idsToRemove, userTags));
          } else {
            displaySuccessToast(
              `Success: removed tag from ${pluralize(
                "user",
                idsToRemove.length,
                true
              )}`
            );
            setSelectedItemIds([]);
          }
          break;
        }
        case "UserTagNotFoundError": {
          logError(new Error(data.removeUserTags.message));
          displayErrorToast(data.removeUserTags.message);
          break;
        }
        default:
          logError(new Error(`failed to remove tag from selected users`));
          displayErrorToast("Error: failed to remove tag from selected users");
      }
    } catch (error) {
      logError(error, `failed to remove group tag from selected users`);
      displayErrorToast("Error: failed to remove tag from selected users");
    }

    setShowModal(false);
  };

  return (
    <Modal isOpen onClose={() => setShowModal(false)} title="Remove Tag Users">
      <Modal.Body>
        Are you sure you want to remove{" "}
        {pluralize("user", idsToRemove.length, true)} from this tag?
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel={"Remove"}
        onPrimaryButtonClick={() => {
          removeSelectedUsers();
          setShowModal(false);
        }}
        primaryButtonLoading={loading}
        primaryButtonTypeV3={"danger"}
      />
    </Modal>
  );
};

const TagUsersTable = ({ tag }: TagUsersTableProps) => {
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [showRemoveUsersModal, setShowRemoveUsersModal] = useState(false);
  const { authState } = useContext(AuthContext);
  const isAdmin = authState.user?.isAdmin;
  const history = useHistory();

  const rows: UserRow[] =
    tag?.tagUsers.map((user) => {
      return {
        id: user.id,
        user: {
          id: user.user?.id,
          fullName: user.user?.fullName,
          avatarUrl: user.user?.avatarUrl,
        },
        tagSource: user.source,
        createdAt: user.createdAt,
        connection: {
          id: user.connection?.id,
          name: user.connection?.name,
          connectionType: user.connection?.connectionType,
        },
      };
    }) || [];

  const allSelected = rows.every((row) => selectedItemIds.includes(row?.id));

  const table = (
    <Table
      rows={rows}
      getRowId={(row) => row.id}
      columns={USERS_COLUMNS}
      totalNumRows={rows.length}
      defaultSortBy="user"
      emptyState={{
        title: "No Users",
      }}
      checkedRowIds={new Set(selectedItemIds)}
      onCheckedRowsChange={(checkedRowIds, checked) => {
        if (checked) {
          setSelectedItemIds((prev) => [...prev, ...checkedRowIds]);
          return;
        } else {
          setSelectedItemIds((prev) =>
            prev.filter((id) => !checkedRowIds.includes(id))
          );
        }
      }}
      selectAllChecked={allSelected}
      onSelectAll={(checked) => {
        if (checked) {
          const allIds = rows.map((row) => row.id);
          setSelectedItemIds(allIds);
        } else {
          setSelectedItemIds([]);
        }
      }}
    />
  );

  return (
    <div>
      <TableHeader
        entityType={EntityType.User}
        totalNumRows={rows.length}
        selectedNumRows={selectedItemIds.length}
        defaultRightActions={
          isAdmin
            ? [
                {
                  label: "Add Users",
                  type: "mainSecondary",
                  iconName: "plus",
                  onClick: () => history.push(`/tags/${tag.id}/add-users`),
                },
              ]
            : []
        }
        bulkRightActions={[
          {
            label: "Remove",
            type: "danger",
            iconName: "trash",
            onClick: () => setShowRemoveUsersModal((prev) => !prev),
          },
        ]}
      />
      {table}
      {showRemoveUsersModal && (
        <RemoveUsersFromTagModal
          key={`remove-users-from-tag-modal`}
          idsToRemove={selectedItemIds}
          setShowModal={setShowRemoveUsersModal}
          setSelectedItemIds={setSelectedItemIds}
        />
      )}
    </div>
  );
};

export default TagUsersTable;
