import { useAccessGrantedTimesQuery } from "api/generated/graphql";
import { formatDuration } from "components/label/Label";
import moment from "moment";

import LineGraph from "../graphs/LineGraph";
import { useMetricsQueryInput } from "../MetricsDashboardContext";
import MetricsGraphCard from "./MetricsGraphCard";

const MINUTES_IN_YEAR = 525600;
const MINUTES_IN_MONTH = 43800;
const MINUTES_IN_DAY = 1440;

type YAxisUnits = "m" | "d" | "hours";

const AccessDurationGranted = () => {
  const input = useMetricsQueryInput();

  const { data, loading, error } = useAccessGrantedTimesQuery({
    variables: {
      input,
    },
  });

  const values = data?.accessGrantedTimes.lineData.map((d) => d.value) ?? [];
  const maxValue = Math.max(...values);
  // Max requestable duration is 1 year
  // If max value in time range is >= 3 months, show months
  // If max value is > 1 day, show days
  // Otherwise show hours
  let displayData = values;
  let yAxisUnits: YAxisUnits = "hours";
  if (maxValue >= MINUTES_IN_MONTH * 3) {
    yAxisUnits = "m";
    // Keep values in minutes to preserve enough granularity.
    // We will handle formatting the value in renderValue.
  } else if (maxValue > MINUTES_IN_DAY) {
    displayData = values.map((v) =>
      parseFloat((v / MINUTES_IN_DAY).toFixed(2))
    );
    yAxisUnits = "d";
  } else {
    displayData = values.map((v) => parseFloat((v / 60).toFixed(2)));
  }

  const renderYAxisLabel = (value: number) => {
    if (yAxisUnits === "m") {
      switch (value) {
        case MINUTES_IN_YEAR:
          return "1y";
        case 0:
          return "0";
        default:
          return `${value / MINUTES_IN_MONTH}m`;
      }
    } else {
      return `${value}${yAxisUnits === "hours" ? "h" : yAxisUnits}`;
    }
  };

  const renderValue = (value: number) => {
    return formatDuration(
      moment.duration(value, yAxisUnits === "m" ? "minutes" : yAxisUnits)
    );
  };

  return (
    <MetricsGraphCard
      loading={loading}
      error={Boolean(error)}
      title="Average length of access granted"
      subtitle="        Are we doing a good job at granting just in time access? (excluding
  direct adds)"
    >
      <LineGraph
        datasets={[
          {
            data: displayData,
            label: "Average access granted by request",
          },
        ]}
        xAxisLabels={
          data?.accessGrantedTimes.lineData.map((data) => data.timestamp) ?? []
        }
        hideDecimals
        customYAxis={
          yAxisUnits === "m"
            ? {
                min: 0,
                max: MINUTES_IN_YEAR,
                stepSize: MINUTES_IN_YEAR / 4,
              }
            : undefined
        }
        renderYAxisLabel={renderYAxisLabel}
        renderValue={renderValue}
      />
    </MetricsGraphCard>
  );
};

export default AccessDurationGranted;
