import {
  EntityType,
  GroupPreviewSmallFragment,
  Visibility,
} from "api/generated/graphql";
import { PillV3 } from "components/pills/PillsV3";
import {
  Banner,
  Button,
  DataElement,
  FormGroup,
  RadioGroup,
  Select,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import { getResourceUrlNew } from "utils/common";
import { useAllGroupsQuery } from "utils/hooks";
import { logError } from "utils/logging";

export type VisibilityOrUnchanged = Visibility | "Unchanged";

interface VisibilityOption {
  value: VisibilityOrUnchanged;
  label: string;
  description?: string;
}

const visibilityOptions: VisibilityOption[] = [
  {
    value: "Unchanged",
    label: "Leave Unchanged",
  },
  {
    value: Visibility.Global,
    label: "No visibility restrictions",
    description:
      "Everyone who can see this item's parents can also see this item.",
  },
  {
    value: Visibility.Team,
    label: "Restrict to groups",
    description:
      "Only users in certain groups (as well as Opal admins, resource admins and users with access) can see the item.",
  },
];

interface DefaultVisibilitySelectorProps {
  visibility: Visibility;
  onChangeVisibility: (visibility: Visibility) => void;
  visibilityGroups: string[];
  onChangeVisibilityGroups: (groups: string[]) => void;
  hasLeaveUnchanged?: false;
  isV3?: boolean;
}

interface VisibilitySelectorWithLeaveUnchangedProps {
  visibility: VisibilityOrUnchanged;
  onChangeVisibility: (visibility: VisibilityOrUnchanged) => void;
  visibilityGroups: string[];
  onChangeVisibilityGroups: (groups: string[]) => void;
  hasLeaveUnchanged: true;
  isV3?: boolean;
}

type VisibilitySelectorProps =
  | DefaultVisibilitySelectorProps
  | VisibilitySelectorWithLeaveUnchangedProps;

type GroupForVisibilitySelector = Pick<
  GroupPreviewSmallFragment,
  "id" | "name" | "groupType"
>;

const VisibilitySelector = (props: VisibilitySelectorProps) => {
  const { hasLeaveUnchanged = false } = props;
  const { data: groupsData, error: groupsError } = useAllGroupsQuery();
  let groups: GroupForVisibilitySelector[] = [];
  if (groupsData?.groups) {
    switch (groupsData.groups.__typename) {
      case "GroupsResult":
        groups = groupsData?.groups.groups;
        break;
      default:
        logError(new Error(`Error: failed to fetch groups`));
    }
  } else if (groupsError) {
    logError(groupsError, `failed to fetch groups`);
  }

  const groupsDict: Map<string, GroupForVisibilitySelector> = groups.reduce(
    (acc, group) => {
      acc.set(group.id, group);
      return acc;
    },
    new Map()
  );

  const options = visibilityOptions.filter((option) => {
    if (!hasLeaveUnchanged) {
      return option.value !== "Unchanged";
    }
    return true;
  });

  return (
    <>
      <RadioGroup
        options={options}
        value={options.find((option) => option.value === props.visibility)}
        onSelectValue={(option) => {
          if (option.value === "Unchanged") {
            if (props.hasLeaveUnchanged) {
              props.onChangeVisibility("Unchanged");
            }
          } else {
            props.onChangeVisibility(option.value);
          }
        }}
        getOptionLabel={(option) => option.label}
        getOptionDescription={(option) => option.description}
        getOptionKey={(option) => option.value}
      />
      {props.visibility === Visibility.Team ? (
        <div className={sprinkles({ marginTop: "lg" })}>
          <FormGroup label="Add groups with visibility:">
            <div
              className={sprinkles({
                display: "flex",
                alignItems: "center",
                gap: "sm",
                flexWrap: "wrap",
              })}
            >
              {props.visibilityGroups.map((groupId) => {
                const group = groupsDict.get(groupId);
                return props.isV3 ? (
                  <>
                    <PillV3
                      key={groupId}
                      keyText={group?.name || "Private group"}
                      pillColor="Teal"
                      icon={{ type: "name", icon: "users" }}
                      entityId={
                        group?.id
                          ? {
                              entityId: group.id,
                              entityType: EntityType.Group,
                            }
                          : undefined
                      }
                      onRemove={() => {
                        props.onChangeVisibilityGroups(
                          props.visibilityGroups.filter((g) => g !== group?.id)
                        );
                      }}
                    />
                    or
                  </>
                ) : (
                  <>
                    <DataElement
                      key={groupId}
                      label={group?.name || "Private group"}
                      color="gray"
                      leftIcon={
                        group
                          ? {
                              data: {
                                entityType: group?.groupType,
                                type: "entity",
                              },
                            }
                          : undefined
                      }
                      link={
                        group?.id
                          ? getResourceUrlNew({
                              entityId: group.id,
                              entityType: EntityType.Group,
                            })
                          : undefined
                      }
                      rightIcon={{
                        name: "x",
                        onClick: () => {
                          props.onChangeVisibilityGroups(
                            props.visibilityGroups.filter(
                              (g) => g !== group?.id
                            )
                          );
                        },
                      }}
                    />
                    <Button disabled label="Or" size="sm" />
                  </>
                );
              })}
              <Select
                options={groups.filter(
                  (group) => !props.visibilityGroups.some((g) => g === group.id)
                )}
                getOptionLabel={(group) => group.name}
                getIcon={(group) => ({
                  type: "entity",
                  entityType: group.groupType,
                })}
                selectOnly
                onChange={(selectedGroup) => {
                  if (!selectedGroup) return;
                  props.onChangeVisibilityGroups([
                    ...props.visibilityGroups,
                    selectedGroup.id,
                  ]);
                }}
                style="bottomBorder"
              />
            </div>
            {props.visibilityGroups.length === 0 ? (
              <div className={sprinkles({ marginTop: "sm" })}>
                <Banner
                  message={
                    <>
                      <b>Setting as admin-only: </b>
                      <span>
                        No groups are selected, so this item will only be
                        visible to admins and users with access.
                      </span>
                    </>
                  }
                />
              </div>
            ) : null}
          </FormGroup>
        </div>
      ) : null}
    </>
  );
};

export default VisibilitySelector;
