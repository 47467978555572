import {
  EntityType,
  ErrorEntity,
  EventFilterFragment,
  Maybe,
  useDeleteEventFilterMutation,
} from "api/generated/graphql";
import { ResourceLabel } from "components/label/Label";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { ButtonV3, Modal } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import { useState } from "react";
import { logError, logWarning } from "utils/logging";

const EventFiltersManageModal = (props: {
  eventFilters: EventFilterFragment[];
  showModal: boolean;
  onClose: () => void;
}) => {
  const { displaySuccessToast } = useToast();
  const [deleteEventFilter, { loading }] = useDeleteEventFilterMutation();
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [errorEntities, setErrorEntities] = useState<ErrorEntity[]>([]);
  const deleteModalReset = () => {
    setErrorMessage(null);
    setErrorEntities([]);
  };

  const columns: Header<EventFilterFragment>[] = [
    {
      id: "name",
      label: "Name",
    },
    {
      id: "creator",
      label: "Created by",
      customCellRenderer(rowData) {
        return (
          <ResourceLabel
            text={rowData.creator.fullName}
            entityId={rowData.creator.id}
            entityTypeNew={EntityType.User}
            avatar={rowData.creator.avatarUrl}
          />
        );
      },
    },
    {
      id: "id",
      label: "",
      customCellRenderer(eventFilter) {
        return (
          <div style={{ float: "right" }}>
            <ButtonV3
              leftIconName="trash"
              size="md"
              type="dangerBorderless"
              loading={loading}
              onClick={async () => {
                try {
                  const { data } = await deleteEventFilter({
                    variables: {
                      input: {
                        id: eventFilter.id,
                      },
                    },

                    update: (cache, { data }) => {
                      switch (data?.deleteEventFilter.__typename) {
                        case "DeleteEventFilterResult":
                          cache.evict({
                            id: cache.identify(
                              data?.deleteEventFilter.deletedEventFilter
                            ),
                          });
                          break;
                      }
                    },
                  });
                  switch (data?.deleteEventFilter.__typename) {
                    case "DeleteEventFilterResult":
                      deleteModalReset();
                      displaySuccessToast("Success: filter deleted");
                      break;
                    case "EventFilterNotFoundError":
                      logError(
                        new Error(
                          `event filter with id: ${eventFilter.id} not found`
                        )
                      );
                      setErrorMessage("failed to delete filter");
                      break;
                    case "EventFilterInUseError":
                      logWarning(new Error(data.deleteEventFilter.message));
                      setErrorMessage(data.deleteEventFilter.message);
                      setErrorEntities(data.deleteEventFilter.entities);
                      break;
                    default:
                      logError(new Error(`failed to delete event filter`));
                  }
                } catch (error) {
                  logError(error, `failed to delete event filter`);
                  setErrorMessage(`ERROR: failed to delete saved filter`);
                }
              }}
            ></ButtonV3>
          </div>
        );
      },
    },
  ];

  return (
    <Modal
      title={"Manage Saved Filters"}
      isOpen={props.showModal}
      onClose={props.onClose}
    >
      <Modal.Body>
        <Table
          columns={columns}
          rows={props.eventFilters}
          totalNumRows={props.eventFilters.length}
          getRowId={(data: EventFilterFragment) => data.id}
        />
        {errorMessage && (
          <ModalErrorMessage
            errorMessage={errorMessage}
            errorEntities={errorEntities}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <ButtonV3
          label="Close"
          onClick={props.onClose}
          type="defaultSecondary"
        ></ButtonV3>
      </Modal.Footer>
    </Modal>
  );
};

export default EventFiltersManageModal;
