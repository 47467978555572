import {
  AccessReviewConnectionUserFragment,
  AccessReviewFragment,
  AccessReviewGroupUserFragment,
  AccessReviewResourceUserFragment,
  ConnectionPreviewTinyFragment,
  ConnectionType,
  GroupPreviewTinyFragment,
  GroupType,
  ResourcePreviewTinyFragment,
  ResourceType,
  SortDirection,
  useAccessReviewChangesQuery,
  useAccessReviewQuery,
} from "api/generated/graphql";
import { Column, ColumnContainer } from "components/column/Column";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeader";
import ColumnListItem, {
  ColumnListItemsSkeleton,
} from "components/column/ColumnListItem";
import ColumnListScroller from "components/column/ColumnListScroller";
import { ColumnSearchAndSort } from "components/column/ColumnSearchAndSort";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import { Divider } from "components/ui";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { filterSearchResults } from "utils/search/filter";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import AccessChangeDetails from "./AccessChangeContent";

type AccessChangeUserData =
  | AccessReviewResourceUserFragment
  | AccessReviewConnectionUserFragment
  | AccessReviewGroupUserFragment;

const AccessChanges = () => {
  const { accessReviewId } = useParams<Record<string, string>>();

  const { data, error, loading } = useAccessReviewQuery({
    variables: {
      input: {
        accessReviewId,
      },
    },
    skip: !accessReviewId,
  });
  let accessReview: AccessReviewFragment | null = null;
  if (data?.accessReview.__typename === "AccessReviewResult") {
    accessReview = data.accessReview.accessReview;
  }

  if (loading) {
    return (
      <ColumnContainer>
        <Column>
          <ColumnHeaderSkeleton />
          <Divider />
          <ColumnListItemsSkeleton />
        </Column>
      </ColumnContainer>
    );
  }

  if (!accessReviewId || !accessReview || error) {
    return (
      <ColumnContainer>
        <Column isContent>
          <UnexpectedErrorPage error={error} />
        </Column>
      </ColumnContainer>
    );
  }

  return (
    <ColumnContainer>
      <AccessChangesColumn accessReview={accessReview} />
    </ColumnContainer>
  );
};

const SORT_OPTIONS = [
  {
    label: "Name (A-Z)",
    value: {
      field: "name",
      direction: SortDirection.Asc,
    },
  },
  {
    label: "Name (Z-A)",
    value: {
      field: "name",
      direction: SortDirection.Desc,
    },
  },
];

interface AccessChangeEntity {
  entity:
    | ResourcePreviewTinyFragment
    | GroupPreviewTinyFragment
    | ConnectionPreviewTinyFragment;
  uarEntityId: string;
  users: AccessChangeUserData[];
}

const AccessChangesColumn = ({
  accessReview,
}: {
  accessReview: AccessReviewFragment;
}) => {
  const history = useHistory();
  const {
    accessReviewId,
    accessReviewResourceId,
    accessReviewGroupId,
    accessReviewConnectionId,
  } = useParams<Record<string, string>>();
  const selectedEntityId =
    accessReviewResourceId || accessReviewGroupId || accessReviewConnectionId;

  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);

  const { data, loading, error } = useAccessReviewChangesQuery({
    variables: {
      input: {
        accessReviewId,
      },
    },
    skip: !accessReviewId,
  });

  if (loading) {
    return (
      <Column>
        <ColumnHeaderSkeleton />
        <Divider />
        <ColumnListItemsSkeleton />
      </Column>
    );
  }

  if (error) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  const items = data?.accessReviewChanges.items ?? [];
  const accessChangeByEntityId: {
    [entityId: string]: AccessChangeEntity;
  } = {};
  items.forEach((item) => {
    const uarEntity =
      item.item.resource ?? item.item.group ?? item.item.connection;
    if (!uarEntity) {
      return;
    }

    let entity:
      | ResourcePreviewTinyFragment
      | GroupPreviewTinyFragment
      | ConnectionPreviewTinyFragment
      | undefined;
    if ("resource" in uarEntity && uarEntity.resource) {
      entity = uarEntity.resource;
    } else if ("group" in uarEntity && uarEntity.group) {
      entity = uarEntity.group;
    } else if ("connection" in uarEntity && uarEntity.connection) {
      entity = uarEntity.connection;
    }

    if (!entity) {
      return;
    }
    accessChangeByEntityId[uarEntity.id] = {
      entity,
      uarEntityId: uarEntity.id,
      users: [],
    };
  });

  const rows = filterSearchResults(
    Object.values(accessChangeByEntityId),
    searchQuery,
    (item) => [item.entity.name]
  ).sort((a, b) => {
    if (sortBy.value.direction === SortDirection.Desc) {
      return a.entity.name.toLowerCase() < b.entity.name.toLowerCase() ? 1 : -1;
    } else {
      return a.entity.name.toLowerCase() < b.entity.name.toLowerCase() ? -1 : 1;
    }
  });

  const renderRow = (index: number) => {
    const row = rows[index];
    if (!row) return <></>;

    const { entity, uarEntityId } = row;

    let entityType: ResourceType | GroupType | ConnectionType | undefined;
    let sublabel = "";
    let urlKey = "";
    if ("resourceType" in entity) {
      entityType = entity.resourceType;
      sublabel = resourceTypeInfoByType[entityType].fullName;
      urlKey = "r";
    } else if ("groupType" in entity) {
      entityType = entity.groupType;
      sublabel = groupTypeInfoByType[entityType].name;
      urlKey = "g";
    } else if ("connectionType" in entity) {
      entityType = entity.connectionType;
      sublabel = "App";
      urlKey = "c";
    }

    if (!entityType) return <></>;

    return (
      <ColumnListItem
        key={entity.id}
        label={entity.name}
        icon={{ type: "entity", entityType }}
        sublabel={sublabel}
        onClick={() =>
          history.push(
            `/access-reviews/${accessReviewId}/access-changes/${urlKey}/${uarEntityId}`
          )
        }
        selected={uarEntityId === selectedEntityId}
      />
    );
  };

  const selectedEntity = accessChangeByEntityId[selectedEntityId];
  const isStoppedReview = accessReview.stoppedDate != null;

  return (
    <>
      <Column>
        <ColumnHeader
          title="Access Changes"
          icon={{ type: "name", icon: "refresh" }}
          breadcrumbs={[
            { name: "Access Reviews", to: "/access-reviews" },
            {
              name: isStoppedReview ? "Ended" : "Ongoing",
              to: `/access-reviews?category=${
                isStoppedReview ? "ended" : "ongoing"
              }`,
            },
            {
              name: accessReview.name,
              to: `/access-reviews/${accessReviewId}`,
            },
          ]}
        />
        <Divider />
        <ColumnSearchAndSort
          setSearchQuery={setSearchQuery}
          sortOptions={SORT_OPTIONS}
          sortBy={sortBy}
          setSortBy={setSortBy}
          placeholder="Search access changes"
          trackName="access-change-items"
        />
        <ColumnListScroller numRows={rows.length} renderRow={renderRow} />
      </Column>
      {selectedEntity && <AccessChangeDetails entity={selectedEntity.entity} />}
    </>
  );
};

export default AccessChanges;
