import clsx from "clsx";

import * as styles from "./AccessReviewTilesV3.css";

export type TileInfo = {
  key: string;
  header: string;
  subHeader: string;
  description: string[];
  progressPercentage?: number;
  onClick?: () => void;
  hide?: boolean;
  descriptionColor?: "default" | "orange" | "green";
};

type AccessReviewTilesV3Props = {
  tileInfos: TileInfo[];
};

export const AccessReviewTilesV3 = (props: AccessReviewTilesV3Props) => {
  return (
    <div className={styles.accessReviewTiles}>
      {props.tileInfos.map((tileInfo) =>
        tileInfo.hide ? null : (
          <AccessReviewTile tileInfo={tileInfo} key={tileInfo.key} />
        )
      )}
    </div>
  );
};

type AccessReviewTileProps = {
  tileInfo: TileInfo;
};

export const AccessReviewTile = (props: AccessReviewTileProps) => {
  return (
    <div
      className={clsx(styles.accessReviewTile, {
        [styles.clickable]: !!props.tileInfo.onClick,
      })}
      onClick={() => props.tileInfo.onClick?.()}
    >
      <div className={styles.header}>{props.tileInfo.header}</div>
      <div className={styles.subHeader}>{props.tileInfo.subHeader}</div>
      {props.tileInfo.progressPercentage !== undefined && (
        <AccessReviewTileProgressBar
          progressPercentage={props.tileInfo.progressPercentage}
          tileKey={props.tileInfo.key}
        />
      )}
      <div
        className={styles.description({
          color: props.tileInfo.descriptionColor ?? "default",
        })}
      >
        {props.tileInfo.description.map((line) => (
          <div>{line}</div>
        ))}
      </div>
    </div>
  );
};

type AccessReviewTileProgressBarProps = {
  progressPercentage: number;
  tileKey: string;
};

export const AccessReviewTileProgressBar = (
  props: AccessReviewTileProgressBarProps
) => {
  return (
    <div className={styles.progressBarContainer}>
      <span
        className={styles.progressBar}
        style={{ width: props.progressPercentage + "%" }}
      />
    </div>
  );
};

export default AccessReviewTilesV3;
