import {
  AccessReviewConnectionUserWarningFragment,
  AccessReviewGroupProposedChangeFragment,
  AccessReviewGroupUserWarningFragment,
  AccessReviewResourceProposedChangeFragment,
  AccessReviewResourceUserWarningFragment,
  ConnectionPreviewSmallFragment,
  EntityType,
  GroupPreviewSmallFragment,
  Maybe,
  ResourcePreviewSmallFragment,
  useAccessReviewProposedChangesLazyQuery,
  UserPreviewSmallFragment,
  useUpdateAccessReviewGroupReviewersMutation,
  useUpdateAccessReviewResourceReviewersMutation,
} from "api/generated/graphql";
import { PaginatedUserDropdown } from "components/dropdown/PaginatedUserDropdown";
import { GenericEntityViewerRow } from "components/entity_viewer/EntityViewer";
import ErrorMessageLabelFixed from "components/label/ErrorMessageLabelFixed";
import { ResourceLabel } from "components/label/Label";
import styles from "components/label/SyncStatusLabel.module.scss";
import modalStyles from "components/modals/Modals.module.scss";
import MaterialTable, {
  CellRow as MaterialTableCellRow,
  Header as MaterialTableHeader,
} from "components/tables/material_table/MaterialTable";
import { useToast } from "components/toast/Toast";
import { Icon, Modal } from "components/ui";
import { Section, SectionColumn, SectionRow } from "components/viewer/Viewer";
import sprinkles from "css/sprinkles.css";
import React from "react";
import useLogEvent from "utils/analytics";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { logError } from "utils/logging";
import { FormatReason } from "views/access_reviews/common/AccessReviewWarnings";

import ModalErrorMessage from "./ModalErrorMessage";

interface AccessReviewUserWarningRow {
  reason: string;
  user: string;
  reviewer?: string;
  entity?: string;
}

export type AccessReviewUserWarningFragment =
  | AccessReviewResourceUserWarningFragment
  | AccessReviewGroupUserWarningFragment
  | AccessReviewConnectionUserWarningFragment;

type AccessReviewWarningsModalProps = {
  accessReviewId: string;
  showModal: boolean;
  onClose: () => void;
  warnings?: Maybe<AccessReviewUserWarningFragment[]>;
  resource?: Maybe<ResourcePreviewSmallFragment>;
  group?: Maybe<GroupPreviewSmallFragment>;
  connection?: Maybe<ConnectionPreviewSmallFragment>;
  user?: Maybe<UserPreviewSmallFragment>;
  canEditReviewers?: boolean;
};

interface ProposedChangesRow {
  user: string;
  currentReviewer: string;
  newReviewer: string;
}

const ProposedChangesHeaders: MaterialTableHeader<ProposedChangesRow>[] = [
  { id: "user", label: "User" },
  { id: "currentReviewer", label: "Current Reviewer" },
  { id: "newReviewer", label: "New Reviewer" },
];

type ProposedChange =
  | AccessReviewResourceProposedChangeFragment
  | AccessReviewGroupProposedChangeFragment;

const AccessReviewWarningsModal = (props: AccessReviewWarningsModalProps) => {
  const [proposedChanges, setProposedChanges] = React.useState<
    ProposedChange[]
  >([]);
  const [proposedChangeError, setProposedChangeError] = React.useState("");

  const { displaySuccessToast } = useToast();
  const logEvent = useLogEvent();

  const [
    getProposedChanges,
    { loading: proposedChangesLoading },
  ] = useAccessReviewProposedChangesLazyQuery({
    variables: {
      input: {
        accessReviewId: props.accessReviewId,
        resourceUserReviewerIds:
          props.resource && props.warnings
            ? props.warnings.flatMap((warning) =>
                warning.__typename === "AccessReviewResourceUserWarning"
                  ? [warning.accessReviewResourceUserReviewerId]
                  : []
              )
            : undefined,
        groupUserReviewerIds:
          props.group && props.warnings
            ? props.warnings.flatMap((warning) =>
                warning.__typename === "AccessReviewGroupUserWarning"
                  ? [warning.accessReviewGroupUserReviewerId]
                  : []
              )
            : undefined,
      },
    },
  });
  const [
    updateResourceReviewers,
    { loading: updateResourceReviewersLoading },
  ] = useUpdateAccessReviewResourceReviewersMutation();
  const [
    updateGroupReviewers,
    { loading: updateGroupReviewersLoading },
  ] = useUpdateAccessReviewGroupReviewersMutation();

  // E.g. if group and resource are provided, produce label = "group resource"
  // E.g. if only user is provided, produce label = "user"
  const labelItems = [];
  if (props.connection) {
    labelItems.push("app");
  }
  if (props.group) {
    labelItems.push("group");
  }
  if (props.resource) {
    labelItems.push("resource");
  }
  if (props.user) {
    labelItems.push("user");
  }
  if (labelItems.length === 0) {
    labelItems.push("item");
  }
  const label = labelItems.join(" ");

  const headers: MaterialTableHeader<AccessReviewUserWarningRow>[] = [
    { id: "reason", label: "Reason" },
    { id: "user", label: "User" },
    { id: "entity", label: "Entity", hide: !props.user },
    { id: "reviewer", label: "Reviewer" },
  ];

  const getEntityId = (warning: AccessReviewUserWarningFragment): string => {
    switch (warning.__typename) {
      case "AccessReviewResourceUserWarning":
        return (
          warning.accessReviewResourceId + warning.accessReviewUserWarningType
        );
      case "AccessReviewGroupUserWarning":
        return (
          warning.accessReviewGroupId + warning.accessReviewUserWarningType
        );
      case "AccessReviewConnectionUserWarning":
        return (
          warning.accessReviewConnectionId + warning.accessReviewUserWarningType
        );
      default:
        return "";
    }
  };

  const getEntityValue = (warning: AccessReviewUserWarningFragment): string => {
    if ("resource" in warning) {
      return warning.resource?.name ?? "";
    } else if ("group" in warning) {
      return warning.group?.name ?? "";
    } else if ("connection" in warning) {
      return warning.connection?.name ?? "";
    }
    return "";
  };

  const getEntityElement = (
    warning: AccessReviewUserWarningFragment
  ): JSX.Element => {
    if ("resource" in warning) {
      return (
        <ResourceLabel
          text={warning.resource?.name}
          entityTypeNew={EntityType.AccessReviewResource}
          entityId={warning.accessReviewResourceId}
          icon={<Icon name="cube" />}
        />
      );
    } else if ("group" in warning) {
      return (
        <ResourceLabel
          text={warning.group?.name}
          entityTypeNew={EntityType.AccessReviewGroup}
          entityId={warning.accessReviewGroupId}
          icon={<Icon name="users" />}
        />
      );
    } else if ("connection" in warning) {
      return (
        <ResourceLabel
          text={warning.connection?.name}
          entityTypeNew={EntityType.AccessReviewConnection}
          entityId={warning.accessReviewConnectionId}
          icon={<Icon name="dots-grid" />}
        />
      );
    }
    return <></>;
  };

  const handleAutoFixClick = async () => {
    logEvent({ name: "uar_proposed_changes_preview" });
    setProposedChangeError("");
    try {
      const { data } = await getProposedChanges();
      switch (data?.accessReviewProposedChanges.__typename) {
        case "AccessReviewProposedChangesResult":
          if (data?.accessReviewProposedChanges.proposedChanges?.length) {
            setProposedChanges(
              data.accessReviewProposedChanges.proposedChanges
            );
            if (
              data.accessReviewProposedChanges.proposedChanges.length !==
              props.warnings?.length
            ) {
              setProposedChangeError(
                "Warning: some warnings were not auto-fixable."
              );
            } else {
              setProposedChangeError("");
            }
          } else {
            setProposedChangeError("Error: there are no auto-fixable changes.");
          }
          break;
        default:
          logError(
            new Error(
              "Failed to get proposed changes for access review warnings"
            )
          );
          setProposedChangeError("Failed to get proposed changes");
      }
    } catch (error) {
      logError(
        error,
        "Failed to get proposed changes for access review warnings"
      );
      setProposedChangeError("Failed to get proposed changes");
    }
  };

  const handleApplyProposedChanges = async () => {
    logEvent({ name: "uar_proposed_changes_apply" });
    if (props.resource) {
      let resourceWarning:
        | AccessReviewResourceUserWarningFragment
        | undefined = undefined;
      for (let warning of props.warnings ?? []) {
        if ("accessReviewResourceId" in warning) {
          resourceWarning = warning;
        }
      }
      if (!resourceWarning) return;

      const { accessReviewResourceId } = resourceWarning;

      try {
        const { data } = await updateResourceReviewers({
          variables: {
            input: {
              accessReviewResourceId,
              reviewers: proposedChanges.map((change) => {
                return {
                  userId: change.proposedReviewer.userId,
                  entityIds: [
                    {
                      entityId: change.proposedReviewer.entityId,
                      entityType: EntityType.AccessReviewResourceUser,
                    },
                  ],
                };
              }),
              updateOnlyForEntities: true,
            },
          },
        });
        switch (data?.updateAccessReviewResourceReviewers.__typename) {
          case "UpdateAccessReviewResourceReviewersResult":
            displaySuccessToast("Reviewers updated");
            props.onClose();
            break;
          default:
            setProposedChangeError("Failed to apply proposed changes");
            logError(new Error("Failed to update resource reviewers"));
        }
      } catch (error) {
        setProposedChangeError("Failed to apply proposed changes");
        logError(error, "Failed to update resource reviewers");
      }
    } else if (props.group) {
      let groupWarning:
        | AccessReviewGroupUserWarningFragment
        | undefined = undefined;
      for (let warning of props.warnings ?? []) {
        if ("accessReviewGroupId" in warning) {
          groupWarning = warning;
        }
      }
      if (!groupWarning) return;

      const { accessReviewGroupId } = groupWarning;

      try {
        const { data } = await updateGroupReviewers({
          variables: {
            input: {
              accessReviewGroupId,
              reviewers: proposedChanges.map((change) => {
                return {
                  userId: change.proposedReviewer.userId,
                  entityIds: [
                    {
                      // entityId is the access review group user id
                      entityId: change.proposedReviewer.entityId,
                      entityType: EntityType.AccessReviewGroupUser,
                    },
                  ],
                };
              }),
              updateOnlyForEntities: true,
            },
          },
        });
        switch (data?.updateAccessReviewGroupReviewers.__typename) {
          case "UpdateAccessReviewGroupReviewersResult":
            displaySuccessToast("Reviewers updated");
            props.onClose();
            break;
          default:
            setProposedChangeError("Failed to apply proposed changes");
            logError(new Error("Failed to update group reviewers"));
        }
      } catch (error) {
        setProposedChangeError("Failed to apply proposed changes");
        logError(error, "Failed to update group reviewers");
      }
    }
  };

  const warningRows: MaterialTableCellRow<AccessReviewUserWarningRow>[] =
    props.warnings?.map((warning) => ({
      id: getEntityId(warning),
      data: {
        reason: {
          value: warning.accessReviewUserWarningType,
          element: (
            <ErrorMessageLabelFixed
              errorMessage={
                FormatReason(warning.accessReviewUserWarningType) || "--"
              }
            />
          ),
        },
        user: {
          value: warning.userId ?? "",
          element: (
            <>
              {
                <ResourceLabel
                  text={warning.user?.fullName}
                  entityType={EntityTypeDeprecated.User}
                  avatar={warning.user?.avatarUrl}
                  entityId={warning.user?.id}
                />
              }
            </>
          ),
        },
        entity: {
          value: getEntityValue(warning),
          element: getEntityElement(warning),
          hide: !props.user,
        },
        reviewer: {
          value: warning.reviewerId ?? "",
          element: (
            <>
              {
                <ResourceLabel
                  text={warning.reviewer?.fullName}
                  entityType={EntityTypeDeprecated.User}
                  avatar={warning.reviewer?.avatarUrl}
                  entityId={warning.reviewer?.id}
                />
              }
            </>
          ),
        },
      },
    })) ?? [];

  const proposedChangesRows = proposedChanges.map((proposedChange, i) => ({
    id: i.toString(),
    data: {
      user: {
        value: proposedChange.user.fullName,
        element: (
          <ResourceLabel
            text={proposedChange.user.fullName}
            entityTypeNew={EntityType.User}
            entityId={proposedChange.user.id}
            avatar={proposedChange.user.avatarUrl}
          />
        ),
      },
      currentReviewer: {
        value: proposedChange.currentReviewer.name,
        element: (
          <ResourceLabel
            text={proposedChange.currentReviewer.name}
            entityTypeNew={EntityType.User}
            entityId={proposedChange.currentReviewer.userId}
            avatar={proposedChange.currentReviewer.avatarUrl}
          />
        ),
      },
      newReviewer: {
        value: proposedChange.proposedReviewer.name,
        element: (
          <ProposedReviewerCell
            proposedReviewer={proposedChange.proposedReviewer}
            onChange={(updatedReviewer) => {
              setProposedChanges((prev) =>
                prev.map((prevProposedChange) => {
                  if (prevProposedChange.user.id !== proposedChange.user.id) {
                    return prevProposedChange;
                  }
                  return {
                    ...prevProposedChange,
                    proposedReviewer: updatedReviewer,
                  };
                })
              );
            }}
          />
        ),
      },
    },
  }));

  const getModalFooter = () => {
    if (proposedChanges.length > 0) {
      return (
        <Modal.Footer
          primaryButtonLabel="Apply and resolve"
          primaryButtonLoading={
            updateGroupReviewersLoading || updateResourceReviewersLoading
          }
          onPrimaryButtonClick={handleApplyProposedChanges}
          secondaryButtonLabel="Close"
          onSecondaryButtonClick={props.onClose}
        />
      );
    }

    if (!props.user && props.canEditReviewers) {
      return (
        <Modal.Footer
          primaryButtonLabel="Get proposed changes"
          primaryButtonLoading={proposedChangesLoading}
          onPrimaryButtonClick={handleAutoFixClick}
          secondaryButtonLabel="Close"
          onSecondaryButtonClick={props.onClose}
        />
      );
    }

    return (
      <Modal.Footer
        primaryButtonLabel="Close"
        onPrimaryButtonClick={props.onClose}
      />
    );
  };

  return (
    <Modal
      isOpen={props.showModal}
      onClose={props.onClose}
      title={`Warnings for this ${label}'s access reviews`}
      maxWidth={false}
    >
      <Modal.Body>
        <SectionColumn large>
          <Section>
            {props.connection && (
              <SectionRow>
                <GenericEntityViewerRow entity={props.connection} />
              </SectionRow>
            )}
            {props.group && (
              <SectionRow>
                <GenericEntityViewerRow entity={props.group} />
              </SectionRow>
            )}
            {props.resource && (
              <SectionRow>
                <GenericEntityViewerRow entity={props.resource} />
              </SectionRow>
            )}
            {props.user && (
              <SectionRow>
                <GenericEntityViewerRow entity={props.user} />
              </SectionRow>
            )}
          </Section>
          {warningRows ? (
            <Section title={`Warnings`} titleSmall style={{ gap: 0 }}>
              <SectionRow style={{ overflowX: "auto" }}>
                <MaterialTable<AccessReviewUserWarningRow>
                  headers={headers}
                  rows={warningRows}
                  denseFormatting={true}
                  tableContainerStyles={{ maxHeight: 300 }}
                />
              </SectionRow>
            </Section>
          ) : (
            <Section>
              <div className={styles.emptyState}>
                Error: warnings could not be loaded.
              </div>
            </Section>
          )}
        </SectionColumn>
        <div className={modalStyles.filler} />
        {proposedChanges?.length || proposedChangeError ? (
          <>
            <Section
              title="Proposed changes"
              titleSmall
              helpTooltipText="Opal is suggesting another user from the admin owners. If no eligible users, Opal will select an Auditor or Admin."
            >
              {proposedChangeError ? (
                <ModalErrorMessage errorMessage={proposedChangeError} />
              ) : null}
              {proposedChanges?.length ? (
                <SectionRow>
                  <MaterialTable<ProposedChangesRow>
                    headers={ProposedChangesHeaders}
                    rows={proposedChangesRows}
                  />
                </SectionRow>
              ) : null}
            </Section>
            <div className={modalStyles.filler} />
          </>
        ) : null}
      </Modal.Body>
      {getModalFooter()}
    </Modal>
  );
};

type Reviewer = AccessReviewGroupProposedChangeFragment["proposedReviewer"];
interface ProposedReviewerCellProps {
  proposedReviewer: Reviewer;
  onChange: (updatedReviewer: Reviewer) => void;
}

const ProposedReviewerCell: React.FC<ProposedReviewerCellProps> = (props) => {
  const { proposedReviewer } = props;
  const [showDropdown, setShowDropdown] = React.useState(false);

  if (showDropdown) {
    return (
      <PaginatedUserDropdown
        selectOnly
        clearable={false}
        onChange={(user) => {
          if (user) {
            props.onChange({
              name: user.fullName,
              userId: user.id,
              avatarUrl: user.avatarUrl,
              entityId: proposedReviewer.entityId,
            });
          }
          setShowDropdown(false);
        }}
      />
    );
  }

  return (
    <div
      className={sprinkles({
        display: "flex",
        gap: "sm",
        alignItems: "center",
      })}
    >
      <ResourceLabel
        text={proposedReviewer.name}
        entityTypeNew={EntityType.User}
        entityId={proposedReviewer.userId}
        avatar={proposedReviewer.avatarUrl}
      />
      <Icon name="edit-2" size="xs" onClick={() => setShowDropdown(true)} />
    </div>
  );
};

export default AccessReviewWarningsModal;
