import { AccessReviewConnectionFragment } from "api/generated/graphql";
import {
  Section,
  SectionColumn,
  SectionRow,
  ViewerOverview,
} from "components/viewer/Viewer";
import React from "react";
import AccessReviewSnapshotRow from "views/access_reviews/AccessReviewSnapshotRow";
import ConnectionAdminRow from "views/connections/viewer/rows/ConnectionAdminRow";
import { ConnectionDescriptionRow } from "views/connections/viewer/rows/ConnectionDescriptionRow";
import ConnectionTypeRow from "views/connections/viewer/rows/ConnectionTypeRow";

type AccessReviewConnectionOverviewProps = {
  accessReviewConnection: AccessReviewConnectionFragment;
};

export const AccessReviewConnectionOverviewRow = (
  props: AccessReviewConnectionOverviewProps
) => {
  return (
    <ViewerOverview>
      <SectionColumn large>
        <AccessReviewConnectionOverviewSection
          accessReviewConnection={props.accessReviewConnection}
        />
      </SectionColumn>
    </ViewerOverview>
  );
};

type AccessReviewConnectionOverviewSectionProps = {
  accessReviewConnection: AccessReviewConnectionFragment;
};

export const AccessReviewConnectionOverviewSection = (
  props: AccessReviewConnectionOverviewSectionProps
) => {
  const connection = props.accessReviewConnection.connection;
  return (
    <Section>
      <SectionRow>
        <AccessReviewSnapshotRow
          snapshotTime={props.accessReviewConnection.createdAt}
        />
      </SectionRow>
      <SectionRow>
        <ConnectionAdminRow connection={connection} />
        <ConnectionTypeRow connection={connection} />
      </SectionRow>
      <SectionRow>
        <ConnectionDescriptionRow connection={connection} />
      </SectionRow>
    </Section>
  );
};

export default AccessReviewConnectionOverviewRow;
