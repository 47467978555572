import { TimeZone } from "@vvo/tzdb";
import { getModifiedErrorMessage } from "api/ApiContext";
import {
  Maybe,
  UpdateAccessReviewInput,
  useUpdateAccessReviewMutation,
} from "api/generated/graphql";
import ReminderNotifications from "components/access_reviews/ReminderNotifications";
import { useToast } from "components/toast/Toast";
import { Banner, FormGroup, Input, Modal, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { logError } from "utils/logging";
import DeadlineForm from "views/access_reviews/common/DeadlineForm";

interface AccessReviewConfig {
  name: string;
  timeZone?: TimeZone;
  deadline?: string;
  reminderSchedule: number[];
  reminderTimeOfDay: Date;
  reminderIncludeManager: boolean;
  sendReviewerAssignmentNotifications: boolean;
}

interface AccessReviewEditModalV3Props {
  id: string;
  isOpen: boolean;
  onClose: () => void;
  initialConfig: AccessReviewConfig;
}

const AccessReviewEditModalV3 = (props: AccessReviewEditModalV3Props) => {
  const [config, setConfig] = useState<AccessReviewConfig>(props.initialConfig);
  const [updateAccessReview] = useUpdateAccessReviewMutation();
  const { displaySuccessToast } = useToast();
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [loading, setLoading] = useState(false);

  const handleUpdate = async () => {
    if (config.deadline && config.timeZone) {
      try {
        setLoading(true);
        const input: UpdateAccessReviewInput = {
          id: props.id,
          name: config.name,
          deadline: config.deadline,
          reminderSchedule: config.reminderSchedule,
          reminderTimeOfDay: config.reminderTimeOfDay.toISOString(),
          reminderIncludeManager: config.reminderIncludeManager,
          timeZone: config.timeZone.name,
          sendReviewerAssignmentNotification:
            config.sendReviewerAssignmentNotifications,
        };

        const { data } = await updateAccessReview({
          variables: { input },
          refetchQueries: ["AccessReviews", "AccessReview"],
        });
        setLoading(false);
        switch (data?.updateAccessReview.__typename) {
          case "UpdateAccessReviewResult":
            displaySuccessToast(`Success: access review updated`);
            props.onClose();
            setLoading(false);
            break;
          default:
            logError(new Error(`failed to update access review`));
            setErrorMessage(`Error: failed to update access review`);
        }
      } catch (error) {
        setLoading(false);
        logError(error, "failed to update access review");
        setErrorMessage(
          getModifiedErrorMessage(
            `Error: failed to update access review`,
            error
          )
        );
      }
    }
  };

  const handleDeadlineChanges = (newDeadline: string, tz: TimeZone) => {
    if (config.timeZone && tz.name != config.timeZone.name) {
      setConfig({ ...config, timeZone: tz, deadline: newDeadline });
    } else if (newDeadline !== config.deadline) {
      setConfig({ ...config, deadline: newDeadline });
    }
  };

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title="Edit Access Review"
    >
      <Modal.Body>
        <FormGroup label="Name" required>
          <Input
            value={config.name}
            onChange={(newName) => setConfig({ ...config, name: newName })}
          />
        </FormGroup>
        <DeadlineForm
          onChange={handleDeadlineChanges}
          deadline={config.deadline}
          label={"Set a deadline"}
          timeZone={config.timeZone}
        />
        <ReminderNotifications
          reminderSchedule={config.reminderSchedule}
          reminderTimeOfDay={config.reminderTimeOfDay}
          reminderIncludeManager={config.reminderIncludeManager}
          timezone={config.timeZone}
          onChange={(
            thisReminderSchedule,
            thisReminderTimeOfDay,
            thisReminderIncludeManager
          ) => {
            setConfig({
              ...config,
              reminderSchedule: thisReminderSchedule,
              reminderTimeOfDay: thisReminderTimeOfDay,
              reminderIncludeManager: thisReminderIncludeManager,
            });
          }}
        />
        <FormGroup
          label={`New review notifications: ${
            config.sendReviewerAssignmentNotifications ? "On" : "Off"
          }`}
        >
          <Switch
            label="Send a notification to reviewers when they're assigned a new review."
            checked={config.sendReviewerAssignmentNotifications}
            onChange={(checked) =>
              setConfig({
                ...config,
                sendReviewerAssignmentNotifications: checked,
              })
            }
            rightAlign
          />
        </FormGroup>
        {errorMessage ? (
          <div className={sprinkles({ marginBottom: "md" })}>
            <Banner type="error" message={errorMessage} />
          </div>
        ) : null}
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Save"
        onPrimaryButtonClick={handleUpdate}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={props.onClose}
        primaryButtonLoading={loading}
      />
    </Modal>
  );
};

export default AccessReviewEditModalV3;
