import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import React from "react";
import VaultSessionsTable from "views/vault_sessions/VaultSessionsTable";

type ResourceVaultSessionsRowProps = {
  resource: { id: string };
};

export const ResourceVaultSessionsRow = (
  props: ResourceVaultSessionsRowProps
) => {
  const vaultSessionsTable = <VaultSessionsTable resource={props.resource} />;

  return (
    <EntityViewerRow
      title={"Sessions"}
      content={vaultSessionsTable}
      isTable={true}
    />
  );
};

export default ResourceVaultSessionsRow;
