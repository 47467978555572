import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import ColumnHeaderV3 from "components/column/ColumnHeaderV3";
import ColumnListItem from "components/column/ColumnListItem";
import { Divider, TabsV3 } from "components/ui";
import { useContext } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import {
  AuthorizedActionCreateAccessReview,
  AuthorizedActionCreateAccessReviewTemplate,
} from "utils/auth/auth";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import AccessReviewDetailColumnV3 from "views/access_reviews/AccessReviewDetailColumnV3";

import AccessReviewDetailColumn from "./AccessReviewDetailColumn";
import AccessReviewSchedulesColumn from "./AccessReviewSchedulesColumn";
import AccessReviewsEndedColumn from "./AccessReviewsEndedColumn";
import AccessReviewsOngoingColumn from "./AccessReviewsOngoingColumn";
import AccessReviewConnectionViewerWrapper from "./connections/AccessReviewConnectionViewerWrapper";
import AccessReviewGroupViewerWrapper from "./groups/AccessReviewGroupViewerWrapper";
import AccessReviewResourceViewerWrapper from "./resources/AccessReviewResourceViewerWrapper";
import AccessReviewUserViewerWrapper from "./users/AccessReviewUserViewerWrapper";

const AccessReviewCategoryColumn = () => {
  const history = useHistory();
  const location = useLocation();

  const { authState } = useContext(AuthContext);
  const organizationName = authState.user?.user.organization.name;
  const canManage = authState.user?.isAdmin || authState.user?.isAuditor;
  const canRead = authState.user?.isReadOnlyAdmin;

  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);

  const {
    accessReviewId,
    accessReviewResourceId,
    accessReviewGroupId,
    accessReviewConnectionId,
    accessReviewTemplateId,
    userId,
  } = useParams<Record<string, string>>();
  const urlParams = new URLSearchParams(location.search);

  let selectedCategory = urlParams.get("category");
  if (accessReviewTemplateId) {
    selectedCategory = "schedules";
  }

  const viewingSingleUAREntity =
    accessReviewResourceId ||
    accessReviewGroupId ||
    accessReviewConnectionId ||
    userId;

  let entityContent;
  if (accessReviewResourceId) {
    entityContent = <AccessReviewResourceViewerWrapper />;
  }
  if (accessReviewGroupId) {
    entityContent = <AccessReviewGroupViewerWrapper />;
  }
  if (accessReviewConnectionId) {
    entityContent = <AccessReviewConnectionViewerWrapper />;
  }
  if (userId) {
    entityContent = <AccessReviewUserViewerWrapper />;
  }

  const viewingAccessReview = accessReviewId || viewingSingleUAREntity;
  const canCreateUAR = authState.user?.organizationAuthorizedActions?.includes(
    AuthorizedActionCreateAccessReview
  );
  const canCreateUARTemplate = authState.user?.organizationAuthorizedActions?.includes(
    AuthorizedActionCreateAccessReviewTemplate
  );

  const addMenuOptions: PropsFor<typeof ColumnHeader>["addMenuOptions"] = [];
  if (canCreateUAR) {
    addMenuOptions.push({
      label: "Create access review",
      onClick: () => history.push("/access-reviews/new?category=ongoing"),
      icon: { type: "name", icon: "plus" },
    });
  }
  if (canCreateUARTemplate) {
    addMenuOptions.push({
      label: "Create access review schedule",
      onClick: () => history.push("/access-reviews/t/new?category=schedules"),
      icon: { type: "name", icon: "plus" },
    });
  }

  if (hasV3Nav) {
    if (!selectedCategory) {
      selectedCategory = "ongoing";
    }
    const tabInfos = [
      {
        title: "Ongoing",
        onClick: () => history.push("/access-reviews?category=ongoing"),
        isSelected: selectedCategory === "ongoing",
      },
    ];
    if (canManage || canRead) {
      tabInfos.push(
        {
          title: "Ended",
          onClick: () => history.push("/access-reviews?category=ended"),
          isSelected: selectedCategory === "ended",
        },
        {
          title: "Schedules",
          onClick: () => history.push("/access-reviews?category=schedules"),
          isSelected: selectedCategory === "schedules",
        }
      );
    }

    const viewingUAR = accessReviewId && !viewingSingleUAREntity;

    return (
      <Column isContent maxWidth="none">
        {!viewingUAR && !accessReviewTemplateId && (
          <ColumnHeaderV3
            title="Access Reviews"
            icon={{ type: "name", icon: "edit" }}
            includeDefaultActions
          />
        )}
        {!viewingUAR && !accessReviewTemplateId && (
          <TabsV3 tabInfos={tabInfos} />
        )}
        {selectedCategory === "ongoing" && !viewingAccessReview ? (
          <AccessReviewsOngoingColumn />
        ) : null}
        {selectedCategory === "ended" && !viewingAccessReview ? (
          <AccessReviewsEndedColumn />
        ) : null}
        {selectedCategory === "schedules" ? (
          <AccessReviewSchedulesColumn />
        ) : null}
        {viewingUAR ? <AccessReviewDetailColumnV3 /> : null}
      </Column>
    );
  }

  return (
    <>
      {!selectedCategory && !viewingAccessReview ? (
        <Column>
          <ColumnHeader
            title="Access Reviews"
            subtitle={organizationName}
            icon={{ type: "name", icon: "check-circle" }}
            addMenuOptions={addMenuOptions}
          />
          <Divider />
          <ColumnListItem
            key="ongoing"
            label="Ongoing"
            onClick={() => history.push("/access-reviews?category=ongoing")}
          />
          {canManage && (
            <>
              <ColumnListItem
                key="ended"
                label="Ended"
                onClick={() => history.push("/access-reviews?category=ended")}
              />
              <ColumnListItem
                key="schedules"
                label="Schedules"
                onClick={() =>
                  history.push("/access-reviews?category=schedules")
                }
              />
            </>
          )}
        </Column>
      ) : null}
      {selectedCategory === "ongoing" && !viewingAccessReview ? (
        <AccessReviewsOngoingColumn />
      ) : null}
      {selectedCategory === "ended" && !viewingAccessReview ? (
        <AccessReviewsEndedColumn />
      ) : null}
      {selectedCategory === "schedules" ? (
        <AccessReviewSchedulesColumn />
      ) : null}
      {accessReviewId && !viewingSingleUAREntity ? (
        <AccessReviewDetailColumn />
      ) : null}
      {entityContent ? <Column isContent>{entityContent}</Column> : null}
    </>
  );
};

export default AccessReviewCategoryColumn;
