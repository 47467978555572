import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AddGroupUserInput,
  ConnectionType,
  EntityType,
  GroupAccessLevel,
  GroupDropdownPreviewFragment,
  GroupType,
  useAddGroupUsersMutation,
  useMultipleGroupAccessLevelsQuery,
  usePaginatedGroupDropdownLazyQuery,
  useSearchGroupsQuery,
  useUserAddGroupsQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import FullscreenViewTitle from "components/fullscreen_modals/FullscreenViewTitle";
import GroupCard from "components/fullscreen_modals/GroupCard";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { Banner, Divider, Input, Label, Loader } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import pluralize from "pluralize";
import { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { groupTypeHasRoles } from "utils/directory/groups";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { ForbiddenPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
} from "views/requests/utils";

import * as styles from "./GroupAddResources.css";

const PAGE_SIZE = 100;

interface UserGroupRow {
  id: string;
  icon?: IconData;
  name: string;
  connectionType?: ConnectionType;
  connectionId?: string;
  groupType?: GroupType;
  isEmpty?: boolean;
}

const UserAddGroupsView = () => {
  const transitionBack = useTransitionBack();
  const logEvent = useLogEvent();
  const { userId } = useParams<{ userId: string }>();
  const startPushTaskPoll = usePushTaskLoader();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  // TODO: Remove this mess of useState in favor of context
  const [groupById, setGroupById] = useState<{
    [groupId: string]: GroupDropdownPreviewFragment;
  }>({});
  const [groupsByConnectionId, setGroupsByConnectionId] = useState<{
    [connectionId: string]: GroupDropdownPreviewFragment[];
  }>({});
  const [roleByGroupIdToAdd, setRoleByGroupIdToAdd] = useState<
    Record<string, GroupAccessLevel[]>
  >({});
  const [
    accessDurationByGroupIdToAdd,
    setAccessDurationByGroupIdToAdd,
  ] = useState<Record<string, ExpirationValue>>({});
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [addError, setAddError] = useState("");
  const rowsById: Record<string, UserGroupRow> = {};
  const [itemsLoadingSubRows, setItemsLoadingSubRows] = useState<string[]>([]);
  const { authState } = useContext(AuthContext);

  // Get User and Connection data
  const { data, loading, error } = useUserAddGroupsQuery({
    variables: {
      userId,
    },
  });
  const user =
    data?.user.__typename === "UserResult" ? data.user.user : undefined;
  const allConnections = data?.connections.connections ?? [];
  const connections = allConnections.filter((connection) =>
    Boolean(connection.numResources)
  );

  // Get the roles that the user already has on each group
  const existingRolesByGroupId: Record<string, GroupAccessLevel[]> = {};
  user?.userGroups.forEach((groupUser) => {
    if (!existingRolesByGroupId[groupUser.groupId]) {
      existingRolesByGroupId[groupUser.groupId] = [];
    }
    if (groupUser.access?.directAccessPoint?.accessLevel) {
      existingRolesByGroupId[groupUser.groupId] = _.uniqBy(
        [
          ...existingRolesByGroupId[groupUser.groupId],
          groupUser.access?.directAccessPoint?.accessLevel,
        ],
        "accessLevelRemoteId"
      );
    }
  });

  const {
    data: rolesData,
    error: rolesError,
    loading: rolesLoading,
  } = useMultipleGroupAccessLevelsQuery({
    variables: {
      input: {
        groupIds: Object.keys(existingRolesByGroupId),
      },
    },
    skip: Object.keys(existingRolesByGroupId).length === 0,
  });

  let allRoles;

  switch (rolesData?.multipleGroupAccessLevels.__typename) {
    case "MultipleGroupAccessLevelsResult":
      allRoles = rolesData.multipleGroupAccessLevels.results;
  }

  const allRolesByGroupId: Record<string, GroupAccessLevel[]> = {};
  allRoles?.forEach((role) => {
    allRolesByGroupId[role.groupId] = role.accessLevels ?? [];
  });

  // Allow searching directly for groups
  const {
    data: searchGroupsData,
    loading: searchGroupsLoading,
    error: searchGroupsError,
  } = useSearchGroupsQuery({
    variables: {
      query: debouncedSearchQuery,
      maxNumEntries: PAGE_SIZE,
    },
    skip: debouncedSearchQuery === "",
  });

  useEffect(() => {
    setGroupById((groupById) => {
      return {
        ...groupById,
        ...searchGroupsData?.groups.groups.reduce((acc, group) => {
          acc[group.id] = group;
          return acc;
        }, {} as typeof groupById),
      };
    });
  }, [searchGroupsData]);

  const [getGroups] = usePaginatedGroupDropdownLazyQuery();
  const [addGroupUsers, { loading: addLoading }] = useAddGroupUsersMutation();

  if (loading || rolesLoading) {
    return <FullscreenSkeleton />;
  }
  if (!authState.user?.isAdmin) {
    return <ForbiddenPage />;
  }
  if (!user || error || rolesError) {
    return <UnexpectedErrorPage error={error} />;
  }

  const handleClose = () => {
    transitionBack(`/users/${userId}#groups`);
  };

  const handleFetchGroups = async (connectionId: string) => {
    try {
      setItemsLoadingSubRows((prev) => [...prev, connectionId]);
      const { data } = await getGroups({
        variables: {
          input: {
            connectionIds: [connectionId],
            maxNumEntries: PAGE_SIZE,
          },
        },
      });

      setGroupsByConnectionId((groupsByConnectionId) => {
        return {
          ...groupsByConnectionId,
          [connectionId]: data?.groups.groups ?? [],
        };
      });

      setGroupById((groupById) => {
        return {
          ...groupById,
          ...data?.groups.groups.reduce((acc, group) => {
            acc[group.id] = group;
            return acc;
          }, {} as typeof groupById),
        };
      });
      setItemsLoadingSubRows((prev) =>
        prev.filter((id) => id !== connectionId)
      );
      return data?.groups.groups ?? [];
    } catch (err) {
      logError(err, "Failed to fetch groups for connection " + connectionId);
    }
  };

  const numGroupsToAdd = Object.keys(roleByGroupIdToAdd).length;

  // A user can only be added to a group with 1 role, so disable selecting
  // a group if it's already been added to the bundle with any role.
  const disabledGroupIds = new Set(Object.keys(existingRolesByGroupId));

  const hasNestedRows = (row: UserGroupRow) => {
    return Boolean(row.connectionType);
  };

  const getNestedRows = (row: UserGroupRow) => {
    const groups = groupsByConnectionId[row.id]?.filter(
      (group) => !disabledGroupIds.has(group.id)
    );
    if (groups && groups.length === 0) {
      return [
        {
          id: `${row.id}-empty`,
          name: "No groups",
          isEmpty: true,
        },
      ];
    }
    return groups?.map((group) => {
      const iconData: IconData = {
        type: "entity",
        entityType: group.groupType,
      };
      const row: UserGroupRow = {
        id: group.id,
        icon: iconData,
        name: group.name,
        groupType: group.groupType,
        connectionId: group?.connection?.id,
      };
      rowsById[row.id] = row;
      return row;
    });
  };

  const handleSubmit = async () => {
    if (!user) {
      return;
    }
    logEvent({
      name: "apps_add_user_to_groups",
      properties: {
        numGroupsAddedTo: Object.entries(roleByGroupIdToAdd).length,
      },
    });
    try {
      const groupInputs: AddGroupUserInput[] = [];
      for (const [groupId, roles] of Object.entries(roleByGroupIdToAdd)) {
        const group = groupById[groupId];

        if (!group) {
          setAddError("failed to add user to groups");
          return;
        }

        // Convert expiration value to duration in minutes, default to undefined.
        const expirationVal =
          accessDurationByGroupIdToAdd[groupId] || ExpirationValue.Indefinite;
        const accessDurationInMinutes = expirationValueToDurationInMinutes(
          expirationVal
        )?.asMinutes();

        if (roles.length === 0) {
          // If group requires a role , but none are selected,
          // show an error.
          if (groupTypeHasRoles(group.groupType)) {
            setAddError(
              "Please select at least one role for groups that have roles."
            );
            return;
          } else {
            // If group does not require roles,
            // add an empty role to add the resource directly.
            groupInputs.push({
              userId: user.id,
              groupId,
              durationInMinutes: accessDurationInMinutes,
            });
          }
        }

        roles.forEach((role) => {
          groupInputs.push({
            userId: user.id,
            groupId,
            accessLevel: {
              accessLevelName: role.accessLevelName,
              accessLevelRemoteId: role.accessLevelRemoteId,
            },
            durationInMinutes: accessDurationInMinutes,
          });
        });
      }

      const { data } = await addGroupUsers({
        variables: {
          input: {
            groupUsers: groupInputs,
          },
        },
        refetchQueries: ["UserGroups"],
      });
      switch (data?.addGroupUsers.__typename) {
        case "AddGroupUsersResult":
          if (data.addGroupUsers.taskId) {
            startPushTaskPoll(data.addGroupUsers.taskId);
          }
          handleClose();
          break;
        case "GroupNotFoundError":
        case "GroupUserAlreadyExists":
          setAddError(data.addGroupUsers.message);
          break;
        default:
          logError(new Error(`failed to add user to groups`));
          setAddError("Error: failed to add user to groups");
      }
    } catch (error) {
      logError(error, "failed to add user to groups");
      setAddError(
        getModifiedErrorMessage("Error: failed to add user to groups", error)
      );
    }
  };

  const COLUMNS: Header<UserGroupRow>[] = [
    {
      id: "name",
      label: "Name",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <div
            className={sprinkles({
              display: "flex",
              alignItems: "center",
              gap: "md",
            })}
          >
            <Label label={row.name} icon={row.icon} />
            {itemsLoadingSubRows.includes(row.id) && <Loader size="xs" />}
          </div>
        );
      },
      width: 500,
    },
  ];

  const getCheckboxDisabledReason = (row: UserGroupRow) => {
    if (row?.isEmpty) {
      return "No resources";
    }
    if (disabledGroupIds.has(row.id)) {
      return "Already added to group";
    }
  };

  const onCheckedRowsChange = async (
    checkedRowIds: string[],
    checked: boolean
  ) => {
    if (checked) {
      setSelectedRowIds((prev) => [...prev, ...checkedRowIds]);
    } else {
      setSelectedRowIds((prev) =>
        prev.filter((id) => !checkedRowIds.includes(id))
      );
    }
    checkedRowIds.forEach((id) => {
      const row = rowsById[id];
      if (hasNestedRows(row)) {
        onCheckConnection(row, checked);
      } else {
        onCheckGroup(row);
      }
    });
  };

  const onCheckGroup = async (row: UserGroupRow) => {
    if (row.id in roleByGroupIdToAdd) {
      setSelectedRowIds((prev) =>
        prev.filter((id) => id !== row.id && id !== row.connectionId)
      );
      setRoleByGroupIdToAdd((prev) => {
        const newRoles = { ...prev };
        delete newRoles[row.id];
        return newRoles;
      });
      setAccessDurationByGroupIdToAdd((prev) => {
        const newDurations = { ...prev };
        delete newDurations[row.id];
        return newDurations;
      });
    } else {
      setSelectedRowIds((prev) => [...prev, row.id]);
      setRoleByGroupIdToAdd((prev) => {
        return {
          ...prev,
          [row.id]: [],
        };
      });
      setAccessDurationByGroupIdToAdd((prev) => {
        return {
          ...prev,
          [row.id]: ExpirationValue.Indefinite,
        };
      });
    }
  };

  const onCheckConnection = async (row: UserGroupRow, checked: Boolean) => {
    if (checked) {
      if (selectedRowIds.includes(row.id)) {
        return;
      }
      try {
        setSelectedRowIds((prev) => [...prev, row.id]);
        let items: GroupDropdownPreviewFragment[] | undefined;
        if (groupsByConnectionId[row.id]) {
          items = groupsByConnectionId[row.id];
        } else {
          if (row.connectionType) {
            items = await handleFetchGroups(row.id);
          }
        }
        ReactDOM.unstable_batchedUpdates(() => {
          setRoleByGroupIdToAdd((prev) => {
            const newRoles = { ...prev };
            items?.forEach((item) => {
              if (!(item.id in newRoles) && !disabledGroupIds.has(item.id)) {
                newRoles[item.id] = [];
                setSelectedRowIds((prev) => [...prev, item.id]);
                setAccessDurationByGroupIdToAdd((prev) => {
                  return {
                    ...prev,
                    [item.id]: ExpirationValue.Indefinite,
                  };
                });
              }
            });
            return newRoles;
          });
        });
      } catch (err) {
        logError(err, "Failed to fetch groups for connection");
      }
    } else {
      const items = getNestedRows(row);
      setSelectedRowIds((prev) =>
        prev.filter((id) => !items.map((row) => row.id).includes(id))
      );
      setAccessDurationByGroupIdToAdd((prev) => {
        const newDurations = { ...prev };
        items.forEach((row) => {
          if (row.id in newDurations) {
            delete newDurations[row.id];
            return newDurations;
          }
        });
        return newDurations;
      });
      setRoleByGroupIdToAdd((prev) => {
        const newRoles = { ...prev };
        items.forEach((row) => {
          if (row.id in newRoles) {
            delete newRoles[row.id];
            return newRoles;
          }
        });
        return newRoles;
      });
    }
  };

  const onRowClick = async (row: UserGroupRow) => {
    if (row.isEmpty || disabledGroupIds.has(row.id)) {
      return;
    }
    if (hasNestedRows(row)) {
      if (row.connectionType && !groupsByConnectionId[row.id]) {
        handleFetchGroups(row.id);
      }
    } else {
      onCheckGroup(row);
    }
  };

  const renderConnectionsList = () => {
    const rows: UserGroupRow[] = connections.map((connection) => {
      const row: UserGroupRow = {
        id: connection.id,
        icon: {
          type: "src",
          icon: getConnectionTypeInfo(connection.connectionType)?.icon,
        },
        name: connection.name,
        connectionType: connection.connectionType,
      };
      rowsById[row.id] = row;
      return row;
    });

    return (
      <Table
        columns={COLUMNS}
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        getRowCanExpand={(row) => hasNestedRows(row.original)}
        loadingRows={loading || searchGroupsLoading}
        defaultSortBy="name"
        checkedRowIds={new Set(selectedRowIds)}
        onCheckedRowsChange={onCheckedRowsChange}
        getCheckboxDisabledReason={getCheckboxDisabledReason}
        onRowClick={onRowClick}
        onExpandRow={(row) => handleFetchGroups(row.id)}
        getChildRows={getNestedRows}
        expandOnChecked={true}
        expandOnRowClick={true}
      />
    );
  };

  const renderSearchList = () => {
    if (searchGroupsError) {
      return <ModalErrorMessage errorMessage={searchGroupsError.message} />;
    }

    const filteredGroups = (searchGroupsData?.groups.groups ?? []).filter(
      (group) => !disabledGroupIds.has(group.id)
    );

    const rows: UserGroupRow[] = filteredGroups.map((group) => {
      const iconData: IconData = {
        type: "entity",
        entityType: group.groupType,
      };
      const row: UserGroupRow = {
        id: group.id,
        icon: iconData,
        name: group.name,
        groupType: group.groupType,
        connectionId: group?.connection?.id,
      };
      rowsById[row.id] = row;
      return row;
    });

    return (
      <Table
        columns={COLUMNS}
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        getRowCanExpand={(row) => hasNestedRows(row.original)}
        loadingRows={loading || searchGroupsLoading}
        defaultSortBy="name"
        checkedRowIds={new Set(selectedRowIds)}
        onCheckedRowsChange={onCheckedRowsChange}
        getCheckboxDisabledReason={getCheckboxDisabledReason}
        onRowClick={onRowClick}
        getChildRows={getNestedRows}
      />
    );
  };

  return (
    <FullscreenView
      title={
        <FullscreenViewTitle
          entityType={EntityType.User}
          entityName={user.fullName}
          targetEntityName="Groups"
          action="add"
        />
      }
      onCancel={handleClose}
      onPrimaryButtonClick={handleSubmit}
      primaryButtonDisabled={numGroupsToAdd === 0}
      primaryButtonLabel={`Add ${
        numGroupsToAdd ? numGroupsToAdd : ""
      } ${pluralize("group", numGroupsToAdd)}`}
      primaryButtonLoading={addLoading}
    >
      <FullscreenView.Content fullWidth>
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textMd",
              fontWeight: "medium",
              marginBottom: "md",
            })}
          >
            Select groups to add to the user:
          </div>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder="Search by name"
              autoFocus
            />
          </div>
          <div className={sprinkles({ color: "gray600", fontSize: "textXs" })}>
            {debouncedSearchQuery === ""
              ? "Showing first 100 resources in each app. Use search to find more results."
              : "Showing first 100 search results. Refine your search to find more."}
          </div>
          <Divider />
          {debouncedSearchQuery === ""
            ? renderConnectionsList()
            : renderSearchList()}
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {addError && (
          <Banner message={addError} type="error" marginBottom="lg" />
        )}
        <div
          className={sprinkles({
            fontSize: "textLg",
            fontWeight: "medium",
            marginBottom: "lg",
          })}
        >
          Adding {numGroupsToAdd} {pluralize("Group", numGroupsToAdd)}
        </div>
        {Object.keys(roleByGroupIdToAdd).map((groupId) => {
          const group = groupById[groupId];
          if (!group || !group.connection) {
            return null;
          }

          return (
            <GroupCard
              key={group.id}
              group={group}
              existingRoles={existingRolesByGroupId[groupId] ?? []}
              selectedRoles={roleByGroupIdToAdd[groupId] ?? []}
              onRemove={() => {
                setSelectedRowIds((prev) =>
                  prev.filter(
                    (id) => id !== groupId && id !== group.connection?.id
                  )
                );
                setRoleByGroupIdToAdd((prev) => {
                  const newRoles = { ...prev };
                  delete newRoles[group.id];
                  return newRoles;
                });
                setAccessDurationByGroupIdToAdd((prev) => {
                  const newDurations = { ...prev };
                  delete newDurations[group.id];
                  return newDurations;
                });
              }}
              onUpdateSelectedRoles={(role) =>
                setRoleByGroupIdToAdd((prev) => {
                  const newRoles = { ...prev };
                  newRoles[groupId] = role;
                  return newRoles;
                })
              }
              onUpdateAllRoles={(roles) => {
                allRolesByGroupId[group.id] = roles;
              }}
              onUpdateAccessDuration={(val) => {
                if (val) {
                  setAccessDurationByGroupIdToAdd((prev) => {
                    return {
                      ...prev,
                      [group.id]: val,
                    };
                  });
                }
              }}
              accessDurationByGroupIdToAdd={accessDurationByGroupIdToAdd}
            />
          );
        })}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};

export default UserAddGroupsView;
