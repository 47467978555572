import {
  AccessType,
  EdgeMetadata,
  ResourceAccessLevel,
  UserPreviewSmallFragment,
} from "api/generated/graphql";
import { vars } from "css/vars.css";
import moment from "moment";

import {
  BASE_NODE_WIDTH,
  GroupByOption,
  NESTED_NODE_MARGIN,
  TreeNodeData,
} from "./common";
import { FilterState } from "./contexts/FilterContext";
import { GraphState } from "./contexts/GraphContext";

export const getNodeWidth = (data: TreeNodeData) => {
  let width = BASE_NODE_WIDTH;
  if ("depth" in data) {
    width -= data.depth * NESTED_NODE_MARGIN;
  }
  return width;
};

export const makeRolelNodeId = (
  resourceId: string,
  role: ResourceAccessLevel
) => {
  return resourceId + role.accessLevelRemoteId;
};

export const getLinkHighlightColorV3 = (
  backgrounded: boolean,
  metadata?: EdgeMetadata
) => {
  switch (metadata?.accessType?.type) {
    case AccessType.Birthright:
      return backgrounded ? vars.color.yellow200V3 : vars.color.yellow600V3;
    case AccessType.Expiring:
      return backgrounded ? vars.color.green200V3 : vars.color.green600V3;
    case AccessType.Longstanding:
      return backgrounded ? vars.color.red200V3 : vars.color.red600V3;
  }
};

export const shouldShowLinkV3 = (
  filterState: FilterState,
  metadata?: EdgeMetadata
) => {
  const { accessTypes, usage } = filterState;

  if (
    accessTypes.includes(AccessType.Birthright) &&
    metadata?.accessType?.type === AccessType.Birthright
  ) {
    return true;
  }
  if (
    accessTypes.includes(AccessType.Expiring) &&
    metadata?.accessType?.type === AccessType.Expiring
  ) {
    return true;
  }
  if (
    accessTypes.includes(AccessType.Longstanding) &&
    metadata?.accessType?.type === AccessType.Longstanding
  ) {
    return true;
  }
  if (usage != null && filterState.usage != null) {
    let shouldShow = true;
    if (usage.noData) {
      shouldShow = !metadata?.lastUsageTime;
    } else {
      const daysSinceLastUsage = moment().diff(
        moment(metadata?.lastUsageTime ?? moment("2010-01-01")),
        "days"
      );

      if (usage.maxDays && daysSinceLastUsage > usage.maxDays) {
        shouldShow = false;
      }
      if (usage.minDays && daysSinceLastUsage < usage.minDays) {
        shouldShow = false;
      }
      if (!metadata?.lastUsageTime) {
        shouldShow = false;
      }
    }

    return shouldShow;
  }

  return false;
};

export const getLinkHighlightColor = (
  filterState: FilterState,
  graphState: GraphState,
  backgrounded: boolean,
  metadata?: EdgeMetadata
) => {
  const { accessTypes, usage } = filterState;

  let strokeColor;

  if (
    accessTypes.includes(AccessType.Birthright) &&
    metadata?.accessType?.type === AccessType.Birthright
  ) {
    strokeColor = backgrounded ? vars.color.yellow200 : vars.color.yellow600;
  }
  if (
    accessTypes.includes(AccessType.Expiring) &&
    metadata?.accessType?.type === AccessType.Expiring
  ) {
    strokeColor = backgrounded ? vars.color.green200 : vars.color.green600;
  }
  if (
    accessTypes.includes(AccessType.Longstanding) &&
    metadata?.accessType?.type === AccessType.Longstanding
  ) {
    strokeColor = backgrounded ? vars.color.red200 : vars.color.red600;
  }
  if (usage != null && filterState.usage != null) {
    let shouldHighlight = true;
    if (usage.noData) {
      shouldHighlight = !metadata?.lastUsageTime;
    } else {
      const daysSinceLastUsage = moment().diff(
        moment(metadata?.lastUsageTime ?? moment("2010-01-01")),
        "days"
      );

      if (usage.maxDays && daysSinceLastUsage > usage.maxDays) {
        shouldHighlight = false;
      }
      if (usage.minDays && daysSinceLastUsage < usage.minDays) {
        shouldHighlight = false;
      }
      if (!metadata?.lastUsageTime) {
        shouldHighlight = false;
      }
    }

    if (shouldHighlight) {
      strokeColor = backgrounded ? vars.color.blue200 : vars.color.blue600;
    }
  }

  return strokeColor;
};

export const getUserGroupByValue = (
  userData: UserPreviewSmallFragment,
  groupBy: GroupByOption
): string => {
  switch (groupBy) {
    case "userRole":
      return userData.position;
    case "userTeam":
      return userData.teamAttr ?? "";
  }
};
