import { EntityType } from "api/generated/graphql";
import { GroupType } from "api/generated/graphql";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import GroupLabel from "components/label/item_labels/GroupLabel";
import UserLabel from "components/label/item_labels/UserLabel";
import { Icon, PopoverV3 } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { getResourceUrlNew } from "utils/common";
import UserCell from "views/access_reviews/common/UserCell";

interface Props {
  principal: PrincipalCellDetails;
  openInNewTab?: boolean;
  hideAvatar?: boolean;
}

export interface PrincipalCellDetails {
  id: String;
  type: EntityType;
  name: String;
  iconUrl?: String;
  position?: String;
  email?: String;
  groupType?: GroupType;
}

const getPrincipalPopoverContent = (principal: PrincipalCellDetails) => {
  if (principal.type == EntityType.User) {
    return (
      <div>
        <UserLabel
          medium
          hideAvatar={principal?.iconUrl == undefined}
          name={principal.name.toString()}
          email={principal.email?.toString()}
          subText={principal.position?.toString()}
          avatar={principal?.iconUrl?.toString()}
          avatarSize="medium"
          large
          bold
        />
      </div>
    );
  } else if (principal.type == EntityType.Group) {
    return (
      <div>
        <GroupLabel
          groupId={principal?.id.toString()}
          text={principal?.name.toString()}
          icon={getGroupTypeInfo(principal?.groupType)?.icon}
        />
      </div>
    );
  }
};

function getPrincipalIcon(principal: PrincipalCellDetails) {
  if (principal.type === EntityType.User) {
    return "user";
  } else {
    return "users";
  }
}

const PrincipalCell = ({ principal, openInNewTab, hideAvatar }: Props) => {
  if (principal.type === EntityType.User) {
    return (
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "center",
          gap: "sm",
        })}
      >
        <div className={sprinkles({ flexShrink: 0 })}>
          <Icon name={getPrincipalIcon(principal)} size="sm" />
        </div>
        <PopoverV3
          position="left"
          content={getPrincipalPopoverContent(principal)}
        >
          <UserCell
            user={{
              id: principal.id.toString(),
              fullName: principal.name.toString(),
              avatarUrl: principal.iconUrl?.toString() ?? "",
              email: principal.email?.toString() ?? "",
            }}
            openInNewTab={true}
            hideAvatar={hideAvatar}
          />
        </PopoverV3>
      </div>
    );
  } else if (principal.type === EntityType.Group) {
    return (
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "center",
          gap: "sm",
        })}
      >
        <div className={sprinkles({ flexShrink: 0 })}>
          <Icon name={getPrincipalIcon(principal)} size="sm" />
        </div>
        <PopoverV3
          position="left"
          content={getPrincipalPopoverContent(principal)}
        >
          <a
            href={getResourceUrlNew({
              entityId: principal.id.toString(),
              entityType: principal.type,
            })}
            target={openInNewTab ? "_blank" : ""}
            rel="noopener noreferrer"
          >
            {principal.name}
          </a>
        </PopoverV3>
      </div>
    );
  } else {
    return <div>{principal.name}</div>;
  }
};
export default PrincipalCell;
