import {
  ConnectionPreviewSmallFragment,
  GroupPreviewSmallFragment,
  Maybe,
  ResourcePreviewSmallFragment,
  SyncErrorFragment,
  SyncTaskFragment,
  SyncType,
  useDismissSyncErrorsMutation,
} from "api/generated/graphql";
import { SelfRefreshingTimestamp } from "components/time/time";
import {
  Banner,
  Button,
  Card,
  CodeEditor,
  Col,
  FormGroup,
  Modal,
  Row,
} from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import moment from "moment";
import React, { useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";

type SyncStatusModalProps = {
  syncType: SyncType;
  entity:
    | ConnectionPreviewSmallFragment
    | ResourcePreviewSmallFragment
    | GroupPreviewSmallFragment
    | null;
  lastSuccessfulSyncTask: SyncTaskFragment | null;
  syncErrors: SyncErrorFragment[];
  isModalOpen: boolean;
  onClose: () => void;
};

const SyncStatusModal = (props: SyncStatusModalProps) => {
  let syncedEntity = "";
  switch (props.syncType) {
    case SyncType.PullConnectionsAll:
      syncedEntity = "All apps, resources, and groups";
      break;
    case SyncType.PullConnectionsAllResources:
      syncedEntity = "All resources";
      break;
    case SyncType.PullConnectionsAllGroups:
      syncedEntity = "All groups";
      break;
    case SyncType.PullHrIdpData:
      syncedEntity = "User HR and IDP data";
      break;
  }

  return (
    <Modal
      isOpen={props.isModalOpen}
      onClose={props.onClose}
      title="Sync Status"
      maxWidth="md"
    >
      <Modal.Body>
        <Card title={props.entity?.name || syncedEntity}>
          <Row>
            <Col>
              <FormGroup label="Last successful sync">
                {props.lastSuccessfulSyncTask ? (
                  <SelfRefreshingTimestamp
                    timestamp={moment(props.lastSuccessfulSyncTask.updatedAt)}
                    showTooltip
                  />
                ) : (
                  "Never"
                )}
              </FormGroup>
            </Col>
            <Col>
              <FormGroup label="Sync initiated by">
                {props.lastSuccessfulSyncTask?.startedByUser?.fullName || "--"}
              </FormGroup>
            </Col>
          </Row>
        </Card>
        {props.syncErrors.length === 0 ? (
          Boolean(props.lastSuccessfulSyncTask) && (
            <Card>
              There have been no errors since the last successful sync 🥳 🎉
            </Card>
          )
        ) : (
          <Card title="Errors since last successful sync">
            <SyncErrorsTable syncErrors={props.syncErrors} />
          </Card>
        )}
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Close"
        onPrimaryButtonClick={props.onClose}
      />
    </Modal>
  );
};

interface SyncErrorRow {
  id: string;
  connectionName: string;
  firstSeen: number;
  lastSeen: number;
  message: string;
  stackTrace: string;
}

type SyncErrorsTableProps = {
  syncErrors: SyncErrorFragment[];
};

const SyncErrorsTable = (props: SyncErrorsTableProps) => {
  const hasShowSyncErrorStackTrace = useFeatureFlag(
    FeatureFlag.ShowSyncErrorStackTrace
  );
  const [dismissSyncErrors, { loading }] = useDismissSyncErrorsMutation({
    refetchQueries: ["SyncStatus"],
  });
  const [loadingDismissedId, setLoadingDismissedId] = useState<string>();
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const handleRowDelete = async (rowId: string) => {
    setErrorMessage(null);
    setLoadingDismissedId(rowId);
    try {
      const { data } = await dismissSyncErrors({
        variables: {
          input: {
            ids: [rowId],
          },
        },
      });
      setLoadingDismissedId(undefined);
      switch (data?.dismissSyncErrors.__typename) {
        case "DismissSyncErrorsResult":
          break;
        default:
          logError(new Error(`failed to dismiss sync error: ${rowId}`));
          setErrorMessage("Error: failed to dismiss sync error");
      }
    } catch (error) {
      logError(error, `failed to dismiss sync error`);
      setErrorMessage("Error: failed to dismiss sync error");
    }
  };

  const timestampRenderer = (timestamp: number) => {
    return (
      <SelfRefreshingTimestamp timestamp={moment(timestamp)} showTooltip />
    );
  };

  const lastSeenRenderer = (row: SyncErrorRow) => {
    return timestampRenderer(row.lastSeen);
  };

  const firstSeenRenderer = (row: SyncErrorRow) => {
    return timestampRenderer(row.firstSeen);
  };

  const headers: Header<SyncErrorRow>[] = [
    { id: "connectionName", label: "App", width: 100 },
    {
      id: "lastSeen",
      label: "Last Seen",
      customCellRenderer: lastSeenRenderer,
      width: 100,
    },
    {
      id: "firstSeen",
      label: "First Seen",
      customCellRenderer: firstSeenRenderer,
      width: 100,
    },
    { id: "message", label: "Error Message", width: 300 },
    {
      id: "id",
      label: "",
      customCellRenderer(row) {
        const id = row.id;
        return (
          <Button
            leftIconName="x"
            borderless
            loading={loading && loadingDismissedId === id}
            onClick={() => handleRowDelete(id)}
          />
        );
      },
      width: 40,
    },
  ];

  const syncErrorRows: SyncErrorRow[] = props.syncErrors.map((syncError) => ({
    id: syncError.id,
    connectionName: syncError.connection?.name || "",
    firstSeen: moment(syncError.firstSeenAt).valueOf(),
    lastSeen: moment(syncError.createdAt).valueOf(),
    message: syncError.message,
    stackTrace: syncError.stackTrace,
  }));

  const renderSubRow = (row: SyncErrorRow) => {
    return row.stackTrace ? <CodeEditor code={row.stackTrace} dark /> : <></>;
  };

  const hasSubRows =
    hasShowSyncErrorStackTrace && syncErrorRows.some((row) => row.stackTrace);

  return (
    <>
      <Banner type="error" message={errorMessage} marginBottom="md" />
      <Table
        columns={headers}
        rows={syncErrorRows}
        totalNumRows={syncErrorRows.length}
        getRowId={(data) => data.id}
        getRowCanExpand={(row) => row.original.stackTrace !== ""}
        defaultSortBy="lastSeen"
        defaultSortDirection="DESC"
        renderSubRow={hasSubRows ? renderSubRow : undefined}
      />
    </>
  );
};

export default SyncStatusModal;
