import { useRequestTemplateQuery } from "api/generated/graphql";
import { Column } from "components/column/Column";
import { useParams } from "react-router";
import { logError } from "utils/logging";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import RequestTemplateColumn from "./requests/RequestTemplateColumn";

const RequestTemplatesContent = () => {
  const { requestTemplateId } = useParams<{ requestTemplateId: string }>();
  const { data, error, loading } = useRequestTemplateQuery({
    variables: {
      input: {
        id: requestTemplateId,
      },
    },
    fetchPolicy: "cache-and-network",
    skip: !requestTemplateId,
  });

  if (error) {
    logError(error, "failed to get request template: " + requestTemplateId);
    return <UnexpectedErrorPage error={error} />;
  }

  if (requestTemplateId && loading) {
    return (
      <Column isContent maxWidth="lg">
        <ColumnContentSkeleton />
      </Column>
    );
  }

  const requestTemplate = data?.requestTemplate.requestTemplate;
  if (!requestTemplate) {
    return null;
  }

  return (
    <Column isContent maxWidth="lg">
      <RequestTemplateColumn requestTemplate={requestTemplate} />
    </Column>
  );
};

export default RequestTemplatesContent;
