import { ConnectionType } from "api/generated/graphql";
import { FormMode } from "components/forms/common";
import { FormRow, Switch } from "components/ui";
import React from "react";
import { isNativeConnectionType } from "utils/directory/resources";

interface Props {
  mode: FormMode;
  connectionType: ConnectionType;
  webhookEnabled: boolean;
  onChange: (webhookEnabled: boolean) => void;
  isV3?: boolean;
}

const ConnectionWebhookToggleRow = (props: Props) => {
  const viewContent = props.webhookEnabled ? "Enabled" : "Disabled";

  const editContent = (
    <Switch checked={props.webhookEnabled} onChange={props.onChange} />
  );

  let helpText = `If enabled, Opal sends a webhook event to the endpoint configured in your
  Admin Settings for each access change to this app's resources.${
    isNativeConnectionType(props.connectionType)
      ? ``
      : ` This is done instead of pushing changes to the end system.`
  }`;

  return (
    <FormRow
      title="Webhook for access changes"
      tooltipText={helpText}
      showToolTipIcon={props.isV3}
    >
      {props.mode === "view" ? viewContent : editContent}
    </FormRow>
  );
};

export default ConnectionWebhookToggleRow;
