import { SortDirection } from "api/generated/graphql";
import {
  Button,
  Checkbox,
  ContextMenu,
  Icon,
  Input,
  Loader,
} from "components/ui";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import { debounce } from "lodash";
import { useContext, useRef, useState } from "react";
import * as React from "react";
import useLogEvent from "utils/analytics";
import { AppsContext } from "views/apps/AppsContext";

import * as styles from "./ColumnSearchAndSort.css";

interface SortOption {
  label: string;
  value: {
    field: string;
    direction?: SortDirection;
  };
}

interface FilterOption {
  label: string;
  value: string;
  icon?: IconData;
}

export function ColumnSearchAndSort<
  T extends SortOption,
  U extends FilterOption
>({
  initialSearchQuery,
  setSearchQuery,
  sortOptions,
  sortBy,
  setSortBy,
  // For complex, multi-step filters
  filterComponent,
  // For simple filters
  filterByOptions,
  filterBy,
  setFilterBy,
  placeholder,
  checkbox,
  trackName,
}: {
  initialSearchQuery?: string;
  setSearchQuery: (s: string) => void;
  sortOptions: T[];
  sortBy: T;
  setSortBy: (s: T) => void;
  filterComponent?: JSX.Element;
  filterByOptions?: U[];
  filterBy?: U;
  setFilterBy?: (s: U | undefined) => void;
  placeholder: string;
  trackName: string;
  checkbox?: {
    alwaysShow?: boolean;
    checked: boolean;
    onChange: (checked: boolean) => void;
    loading: boolean;
  };
}) {
  const { isSelectMode } = useContext(AppsContext);
  const logEvent = useLogEvent();

  const [internalSearchQuery, setInternalSearchQuery] = useState(
    initialSearchQuery || ""
  );

  const handleSearchInputChange = useRef(
    debounce(async (s: string) => {
      logEvent({
        name: "apps_search_and_sort",
        properties: {
          entryPoint: trackName,
          searchQuery: s,
        },
      });
      setSearchQuery(s);
    }, 250)
  ).current;

  React.useEffect(() => {
    return () => {
      handleSearchInputChange.cancel();
    };
  }, [handleSearchInputChange]);

  let checkboxComponent;
  if (checkbox && (checkbox.alwaysShow || isSelectMode)) {
    if (checkbox.loading) {
      checkboxComponent = (
        <div className={sprinkles({ margin: "xs" })}>
          <Loader size={"sm"} />
        </div>
      );
    } else {
      checkboxComponent = (
        <div className={sprinkles({ margin: "xs" })}>
          <Checkbox checked={checkbox.checked} onChange={checkbox.onChange} />
        </div>
      );
    }
  }
  return (
    <>
      <div
        className={sprinkles({
          marginBottom: "sm",
          display: "flex",
          alignItems: "center",
          gap: "xs",
        })}
      >
        {checkboxComponent}
        <Input
          leftIconName="search"
          type="search"
          style="search"
          value={internalSearchQuery}
          onChange={(value) => {
            setInternalSearchQuery(value);
            handleSearchInputChange(value);
          }}
          placeholder={placeholder}
        />
        {filterComponent}
        {filterByOptions && filterByOptions?.length > 0 ? (
          <ContextMenu
            rightAligned
            options={filterByOptions.map((option) => ({
              label: option.label,
              icon: option.icon,
              disabled: filterBy === option,
              onClick: () => {
                if (setFilterBy) {
                  setFilterBy(option);
                  logEvent({
                    name: "apps_search_and_sort",
                    properties: {
                      entryPoint: trackName,
                      filter: option.value,
                    },
                  });
                }
              },
            }))}
            renderButton={(onClick) => (
              <Button
                leftIconName="filter"
                borderless
                onClick={onClick}
                round
              />
            )}
          />
        ) : null}
        <ContextMenu
          rightAligned
          options={sortOptions.map((option) => ({
            label: option.label,
            disabled: sortBy === option,
            onClick: () => {
              setSortBy(option);
              logEvent({
                name: "apps_search_and_sort",
                properties: {
                  entryPoint: trackName,
                  sort: `${option.value.field} ${option.value.direction}`,
                },
              });
            },
          }))}
          renderButton={(onClick) => (
            <Button leftIconName="sort" borderless onClick={onClick} round />
          )}
        />
      </div>
      {filterBy ? (
        <div className={styles.searchAndSortExplainer}>
          Filtered by "{filterBy.label}"
          <span className={sprinkles({ marginLeft: "xs" })}>
            <Icon
              name="x"
              size="xs"
              onClick={() => {
                setFilterBy && setFilterBy(undefined);
              }}
            />
          </span>
        </div>
      ) : null}
    </>
  );
}
