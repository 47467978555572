import { formatDuration } from "components/label/Label";
import { isDurationValueCustom } from "components/modals/EditDurationModal";
import modalsStyles from "components/modals/Modals.module.scss";
import { CustomDurationPicker } from "components/requests/CustomDurationPicker";
import { Banner, FormGroup, Select } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
  minutesToExpirationValueNullable,
  NULLABLE_DURATION_INDEFINITE,
} from "views/requests/utils";

import * as styles from "./RequestDurationPicker.css";

type RequestDurationPickerProps = {
  hasAccess?: boolean;
  itemsWithAccess?: string[];
  maxDurationInMinutes?: number | null;
  maxValueReason?: string;
  recommendedDurationInMinutes?: number;
  expiration: ExpirationValue;
  setExpiration: (expiration: ExpirationValue) => void;
  customDuration: number;
  setCustomDuration: (duration: number) => void;
  usingCustomDurationPicker: boolean;
  setUsingCustomDurationPicker: (usingCustomDurationPicker: boolean) => void;
};

// Deprecated: This component is deprecated in favor of the new AccessRequestDurationPicker component.
const RequestDurationPicker = (props: RequestDurationPickerProps) => {
  const {
    expiration,
    setExpiration,
    customDuration,
    setCustomDuration,
    usingCustomDurationPicker,
    setUsingCustomDurationPicker,
  } = props;

  let recommendedDurationIsValid = true;
  if (props.maxDurationInMinutes != null) {
    if (
      props.recommendedDurationInMinutes === NULLABLE_DURATION_INDEFINITE ||
      (props.recommendedDurationInMinutes != null &&
        props.recommendedDurationInMinutes > props.maxDurationInMinutes)
    ) {
      recommendedDurationIsValid = false;
    }
  }

  const handleSelectExpirationChange = (value?: ExpirationValue) => {
    setExpiration(value ?? ExpirationValue.OneHour);
  };

  const durationInMinutes = (usingCustomDurationPicker
    ? moment.duration(customDuration, "m")
    : expirationValueToDurationInMinutes(expiration)
  )?.asMinutes();

  const warnings: string[] = [];
  // Show warning if user selects duration that is not recommended duration
  if (
    props.recommendedDurationInMinutes != null &&
    recommendedDurationIsValid &&
    !(
      props.recommendedDurationInMinutes === NULLABLE_DURATION_INDEFINITE &&
      durationInMinutes == null
    ) &&
    props.recommendedDurationInMinutes !== durationInMinutes
  ) {
    warnings.push(
      `Recommended duration is ${
        props.recommendedDurationInMinutes === NULLABLE_DURATION_INDEFINITE
          ? "indefinite"
          : formatDuration(
              moment.duration(props.recommendedDurationInMinutes, "m")
            )
      }.`
    );
  }
  // Show warning/notice if user has access already that access will be reset.
  if (props.hasAccess) {
    warnings.push("This request will reset existing access duration.");
  } else if (props.itemsWithAccess && props.itemsWithAccess.length > 0) {
    warnings.push(
      "This request will reset existing access duration for the following item(s): " +
        props.itemsWithAccess.join(", ")
    );
  }

  return (
    <FormGroup
      label="Expires In"
      rightLabelContent={
        <span
          className={modalsStyles.hyperlinkText}
          onClick={() =>
            setUsingCustomDurationPicker(!usingCustomDurationPicker)
          }
        >
          {usingCustomDurationPicker ? "default" : "custom"}
        </span>
      }
      required
    >
      {!usingCustomDurationPicker && (
        <Select
          value={expiration}
          onChange={handleSelectExpirationChange}
          options={Object.values(ExpirationValue).filter((time) => {
            if (
              !props.maxDurationInMinutes ||
              props.maxDurationInMinutes === Infinity
            )
              return true;
            const expirationValueInMinutes = expirationValueToDurationInMinutes(
              time
            )?.asMinutes();
            return (
              expirationValueInMinutes &&
              expirationValueInMinutes <= props.maxDurationInMinutes
            );
          })}
          getOptionLabel={(opt) => opt}
          renderOptionLabel={(opt) => {
            const isRecommended = isDurationValueCustom(
              props.recommendedDurationInMinutes ?? null
            )
              ? false
              : minutesToExpirationValueNullable(
                  props.recommendedDurationInMinutes
                ) === opt;
            return (
              <span>
                {opt}
                {isRecommended ? (
                  <span className={styles.recommendedBadge}>Recommended</span>
                ) : null}
              </span>
            );
          }}
        />
      )}
      {usingCustomDurationPicker && (
        <CustomDurationPicker
          durationInMinutes={customDuration}
          setDurationInMinutes={setCustomDuration}
          maxValue={props.maxDurationInMinutes || undefined}
          maxValueReason={props.maxValueReason}
        />
      )}
      {warnings.map((message) => {
        return (
          <div className={sprinkles({ marginTop: "md" })}>
            <Banner type="warning" message={message} />
          </div>
        );
      })}
    </FormGroup>
  );
};

export default RequestDurationPicker;
