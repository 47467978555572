import { gql, useQuery } from "@apollo/client";
import {
  EntityType,
  HrIdpStatus,
  InventoryUsersTableQuery,
  UsersSortByField,
} from "api/generated/graphql";
import axios from "axios";
import AuthContext from "components/auth/AuthContext";
import InviteTeammatesModal from "components/modals/InviteTeammatesModal";
import OpalTable, {
  Columns,
  useServerSortableOpalTable,
} from "components/opal/table/OpalTable";
import { useToast } from "components/toast/Toast";
import { ButtonV3, Icon, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import pluralize from "pluralize";
import { useCallback, useContext, useState } from "react";
import { Link } from "react-router-dom";
import { getResourceUrlNew } from "utils/common";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import UserFilterBarV3 from "views/users/UserFilterBarV3";
import * as styles from "views/users/UsersColumnV3.css";
import { getUserAvatarIcon, useUsersFilter } from "views/users/utils";

type User = InventoryUsersTableQuery["users"]["users"][0];

const USER_COLUMNS: Columns<User, UsersSortByField> = [
  {
    id: UsersSortByField.FirstName,
    label: "Name",
    sortable: true,
    customCellRenderer: (user) => {
      return (
        <div className={sprinkles({ display: "flex", gap: "sm" })}>
          <div className={sprinkles({ flexShrink: 0 })}>
            <Icon
              size="sm"
              data={getUserAvatarIcon({ avatarUrl: user.avatarUrl })}
            />
          </div>
          <Link
            onClick={(e) => e.stopPropagation()}
            to={getResourceUrlNew({
              entityId: user.id,
              entityType: EntityType.User,
            })}
            className={styles.nameField}
          >
            {user.fullName}
          </Link>
        </div>
      );
    },
  },
  {
    id: UsersSortByField.Email,
    label: "Email",
    sortable: true,
    customCellRenderer: (user) => {
      return <div className={styles.emailCell}>{user.email ?? ""}</div>;
    },
  },
  {
    id: UsersSortByField.Manager,
    label: "Manager",
    sortable: true,
    customCellRenderer: (user) => {
      if (!user.manager) {
        return <></>;
      }
      return (
        <div className={sprinkles({ display: "flex", gap: "sm" })}>
          <div className={sprinkles({ flexShrink: 0 })}>
            <Icon
              size="sm"
              data={getUserAvatarIcon({
                avatarUrl: user.manager.avatarUrl,
              })}
            />
          </div>
          <Link
            onClick={(e) => e.stopPropagation()}
            to={getResourceUrlNew({
              entityId: user.manager.id,
              entityType: EntityType.User,
            })}
            className={styles.nameField}
          >
            {user.manager.fullName}
          </Link>
        </div>
      );
    },
  },
  {
    id: UsersSortByField.Team,
    label: "Team",
    sortable: true,
  },
  {
    id: UsersSortByField.Title,
    label: "Title",
    sortable: true,
  },
  {
    id: "resourceCount",
    label: "Resources",
    sortable: false,
    customCellRenderer: (user) => {
      return (
        <div
          className={sprinkles({
            color: user.numResources > 0 ? undefined : "gray600",
          })}
        >
          {`${user.numResources || "No"} ${pluralize(
            "Resource",
            user.numResources
          )}`}
        </div>
      );
    },
  },
  {
    id: UsersSortByField.CreatedAt,
    label: "Created",
    sortable: true,
    width: 100,
    customCellRenderer: (user) => {
      return <div>{moment(user.createdAt).fromNow()}</div>;
    },
  },
  {
    id: "idpStatus",
    label: "Status",
    sortable: false,
    width: 110,
    customCellRenderer: (user) => {
      let iconName: PropsFor<typeof Icon>["name"] = "user";
      let color: PropsFor<typeof Icon>["color"] = "gray400";
      let tooltipContent: React.ReactNode;
      let labelContent: React.ReactNode;
      switch (user.hrIdpStatus) {
        case HrIdpStatus.Active:
          iconName = "user-check";
          color = "green500";
          labelContent = "Active";
          tooltipContent = "This account is active";
          break;
        case HrIdpStatus.Deleted:
          iconName = "user-x";
          color = "red400";
          labelContent = "Deleted";
          tooltipContent = "This account has been deleted";
          break;
        case HrIdpStatus.Deprovisioned:
          iconName = "user-x";
          color = "red400";
          labelContent = "Deprovisioned";
          tooltipContent = "This account has been deprovisioned";
          break;
        case HrIdpStatus.NotFound:
          iconName = "user-x";
          color = "gray400";
          labelContent = "Not Found";
          tooltipContent = "Unable to locate this account";
          break;
        case HrIdpStatus.Suspended:
          iconName = "user-x";
          color = "red400";
          labelContent = "Suspended";
          tooltipContent = "This account has been suspended";
          break;
        default:
          iconName = "user-x";
          color = "gray400";
          labelContent = "Inactive";
          tooltipContent = "This account is inactive";
          break;
      }
      return (
        <div className={sprinkles({ whiteSpace: "nowrap" })}>
          <Tooltip tooltipText={tooltipContent} contentInline arrow>
            <Icon name={iconName} size="xs" color={color} /> {labelContent}
          </Tooltip>
        </div>
      );
    },
  },
];

const UsersColumnV3 = () => {
  const transitionTo = useTransitionTo();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { authState } = useContext(AuthContext);

  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();

  const usersFilter = useUsersFilter();
  const { sortByVariable, sortByTableProps } = useServerSortableOpalTable({
    id: UsersSortByField.FirstName,
    desc: false,
  });

  const debouncedSearchQuery = useDebouncedValue(usersFilter.search, 300);
  // NOTE: if you add a new filter, make sure to update ApiContext with the new filter in keyArgs
  const {
    data: usersData,
    error: usersError,
    networkStatus: networkStatusLoading,
    fetchMore,
  } = useQuery(
    gql`
      query InventoryUsersTable(
        $cursor: String
        $idpStatusFilter: IdpStatusFilter
        $managerFilter: ManagerFilter
        $searchQuery: String
        $sortBy: UsersSortBy
        $teamFilter: TeamFilter
        $titleFilter: TitleFilter
      ) {
        users(
          input: {
            cursor: $cursor
            idpStatusFilter: $idpStatusFilter
            managerFilter: $managerFilter
            searchQuery: $searchQuery
            sortBy: $sortBy
            teamFilter: $teamFilter
            titleFilter: $titleFilter
          }
        ) {
          __typename
          ... on UsersResult {
            users {
              id
              email
              fullName
              avatarUrl
              createdAt
              manager {
                id
                fullName
                avatarUrl
              }
              hrIdpStatus
              numResources
              numGroups
            }
            cursor
            totalNumUsers
          }
        }
      }
    `,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        searchQuery: debouncedSearchQuery,
        sortBy: sortByVariable,
        managerFilter:
          usersFilter.manager !== undefined
            ? { managerId: usersFilter.manager }
            : undefined,
        titleFilter:
          usersFilter.title !== undefined
            ? { titles: [usersFilter.title] }
            : undefined,
        teamFilter:
          usersFilter.team !== undefined
            ? { teams: [usersFilter.team] }
            : undefined,
        idpStatusFilter:
          usersFilter.hrIdpStatus !== undefined
            ? {
                statuses: [usersFilter.hrIdpStatus as HrIdpStatus],
              }
            : undefined,
      },
    }
  );

  if (usersError) {
    logError(usersError, `failed to list users home`);
  }

  const users = usersData?.users.users ?? [];
  const cursor = usersData?.users.cursor;
  const totalNumUsers = usersData?.users.totalNumUsers ?? 0;

  const handleExportUsers = useCallback(() => {
    displayLoadingToast("Generating export...");
    axios({
      url: "/export/users",
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Opal_All_Users_" + moment().toISOString() + ".csv"
        );
        link.click();
        displaySuccessToast(`Success: downloaded all users`);
      })
      .catch(() => {
        displayErrorToast(`Error: failed to generate export`);
      });
  }, [displayErrorToast, displayLoadingToast, displaySuccessToast]);

  return (
    <>
      <>
        <OpalTable
          filters={<UserFilterBarV3 forOpalTable />}
          actions={
            <>
              <ButtonV3
                label="Export Users"
                type="mainSecondary"
                leftIconName="users-right"
                onClick={handleExportUsers}
                size="sm"
              />
              {authState.user?.isAdmin && (
                <ButtonV3
                  label="User"
                  type="main"
                  leftIconName="plus"
                  onClick={() => {
                    setShowCreateModal(true);
                  }}
                  size="sm"
                />
              )}
            </>
          }
          entityName="User"
          rows={users}
          totalNumRows={totalNumUsers}
          getRowId={(user) => user.id}
          columns={USER_COLUMNS}
          onRowClick={(user, event) => {
            transitionTo(
              {
                pathname: getResourceUrlNew({
                  entityId: user.id,
                  entityType: EntityType.User,
                }),
              },
              event
            );
          }}
          onLoadMoreRows={
            cursor
              ? async () => {
                  await fetchMore({
                    variables: {
                      cursor,
                    },
                  });
                }
              : undefined
          }
          networkStatus={networkStatusLoading}
          {...sortByTableProps}
        />
      </>
      {showCreateModal ? (
        <InviteTeammatesModal
          isModalOpen={showCreateModal}
          onClose={() => setShowCreateModal(false)}
        />
      ) : null}
    </>
  );
};

export default UsersColumnV3;
