import {
  UserIdentitiesFragment,
  UserPreviewLargeFragment,
  useUserDetailQuery,
} from "api/generated/graphql";
import { DataElement, DataElementList, Skeleton } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { formatHrIdpStatus } from "views/users/utils";

const UserMoreInfo = ({ user }: { user: UserPreviewLargeFragment }) => {
  const { data, error, loading } = useUserDetailQuery({
    variables: {
      id: user.id,
    },
  });

  if (loading) {
    return (
      <div className={sprinkles({ marginBottom: "md" })}>
        <div
          className={sprinkles({
            fontSize: "labelMd",
            fontWeight: "medium",
            marginBottom: "sm",
          })}
        >
          More information about {user.fullName}
        </div>
        <Skeleton height="48px" />
      </div>
    );
  }

  if (error) {
    return (
      <div className={sprinkles({ marginBottom: "md", color: "red600" })}>
        Failed to fetch user info
      </div>
    );
  }

  let identities: UserIdentitiesFragment | undefined;
  if (data?.user.__typename === "UserResult") {
    identities = data.user.user.identities ?? undefined;
  }

  return (
    <div className={sprinkles({ marginBottom: "md" })}>
      <div
        className={sprinkles({
          fontSize: "labelMd",
          fontWeight: "medium",
          marginBottom: "sm",
        })}
      >
        More information about {user.fullName}
      </div>
      <DataElementList>
        <DataElement
          leftIcon={{
            name: "role",
          }}
          color="blue"
          label={user.position ?? "--"}
          size="sm"
          disableHover
        />
        <DataElement
          leftIcon={{
            name: "user-square",
          }}
          color="blue"
          label={user.manager?.fullName ?? "--"}
          size="sm"
          disableHover
        />
        <DataElement
          leftIcon={{
            name: "users",
          }}
          color="blue"
          label={user.teamAttr ?? "--"}
          size="sm"
          disableHover
        />
        <DataElement
          color="blue"
          label={`HRIS/IDP Status: ${
            user.hrIdpStatus ? formatHrIdpStatus(user.hrIdpStatus) : "--"
          }`}
          size="sm"
          disableHover
        />
        <DataElement
          color="blue"
          label={`Secondary email(s): ${
            user.secondaryEmails && user.secondaryEmails.length > 0
              ? user.secondaryEmails.join(", ")
              : "--"
          }`}
          size="sm"
          disableHover
        />
        {identities && (
          <>
            {identities.gitHubUsername?.value &&
              identities.gitHubUsername.shouldShow && (
                <DataElement
                  color="blue"
                  label={`GitHub Username: ${identities.gitHubUsername.value}`}
                  size="sm"
                  disableHover
                />
              )}
            {identities.gitLabUserId?.value &&
              identities.gitLabUserId.shouldShow && (
                <DataElement
                  color="blue"
                  label={`GitLab UserId: ${identities.gitLabUserId.value}`}
                  size="sm"
                  disableHover
                />
              )}
            {identities.teleportUsername?.value &&
              identities.teleportUsername.shouldShow && (
                <DataElement
                  color="blue"
                  label={`Teleport Username: ${identities.teleportUsername.value}`}
                  size="sm"
                  disableHover
                />
              )}
          </>
        )}
      </DataElementList>
    </div>
  );
};

export default UserMoreInfo;
