import {
  AuthFlowType,
  GroupOnCallSchedulePreviewSmallFragment,
  ThirdPartyIntegrationFragment,
  ThirdPartyProvider,
  useGroupOnCallSchedulesQuery,
} from "api/generated/graphql";
import jiraLogo from "assets/logos/jira-logo.png";
import linearLogo from "assets/logos/linear-logo.png";
import opsgenieLogo from "assets/logos/opsgenie-logo.png";
import pagerDutyLogo from "assets/logos/pager-duty-logo.png";
import servicenowIcon from "assets/logos/servicenow-icon.png";
import slackLogo from "assets/logos/slack-logo.svg";
import AuthContext from "components/auth/AuthContext";
import {
  IntegrationInfo,
  OrgIntegrationApp,
} from "components/integrations/OrgIntegrationApp";
import { useContext } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import { OrgSettingsSection } from "./OrgSettings";

const integrationInfos: IntegrationInfo[] = [
  {
    name: "Slack",
    logo: slackLogo,
    thirdPartyProvider: ThirdPartyProvider.Slack,
    authFlowType: AuthFlowType.Code,
  },
  {
    name: "Jira",
    logo: jiraLogo,
    thirdPartyProvider: ThirdPartyProvider.Jira,
    authFlowType: AuthFlowType.Manual,
  },
  {
    name: "PagerDuty",
    logo: pagerDutyLogo,
    thirdPartyProvider: ThirdPartyProvider.PagerDuty,
    authFlowType: AuthFlowType.Manual,
  },
  {
    name: "Opsgenie",
    logo: opsgenieLogo,
    thirdPartyProvider: ThirdPartyProvider.Opsgenie,
    authFlowType: AuthFlowType.Manual,
  },
  {
    name: "Linear",
    logo: linearLogo,
    thirdPartyProvider: ThirdPartyProvider.Linear,
    authFlowType: AuthFlowType.Manual,
  },
  {
    name: "ServiceNow",
    logo: servicenowIcon,
    thirdPartyProvider: ThirdPartyProvider.ServiceNow,
    authFlowType: AuthFlowType.Manual,
  },
];

export type OrgIntegrationsProps = {
  title: string;
  integrations: ThirdPartyIntegrationFragment[];
};

const OrgIntegrations = (props: OrgIntegrationsProps) => {
  const { authState } = useContext(AuthContext);

  const groupOnCallSchedulesByThirdPartyProvider: Partial<
    Record<ThirdPartyProvider, GroupOnCallSchedulePreviewSmallFragment[]>
  > = {};
  const { data: groupOnCallSchedulesData } = useGroupOnCallSchedulesQuery();
  if (groupOnCallSchedulesData?.groupOnCallSchedules) {
    groupOnCallSchedulesData.groupOnCallSchedules.groupOnCallSchedules.forEach(
      (groupOnCallSchedule) => {
        const thirdPartyProvider =
          groupOnCallSchedule.onCallSchedule.thirdPartyProvider;
        if (groupOnCallSchedulesByThirdPartyProvider[thirdPartyProvider])
          groupOnCallSchedulesByThirdPartyProvider[thirdPartyProvider]?.push(
            groupOnCallSchedule
          );
        else
          groupOnCallSchedulesByThirdPartyProvider[thirdPartyProvider] = [
            groupOnCallSchedule,
          ];
      },
      {} as Record<
        ThirdPartyProvider,
        GroupOnCallSchedulePreviewSmallFragment[]
      >
    );
  }

  const hasServiceNow = useFeatureFlag(FeatureFlag.ServiceNowIntegration);

  return (
    <OrgSettingsSection title={props.title}>
      {integrationInfos.map((integrationInfo) =>
        integrationInfo.thirdPartyProvider === ThirdPartyProvider.ServiceNow &&
        !hasServiceNow ? null : (
          <OrgIntegrationApp
            key={`app_info_${integrationInfo.name}`}
            integrationInfo={integrationInfo}
            integrations={props.integrations}
            disabled={!authState.user?.isAdmin}
            groupOnCallSchedules={
              groupOnCallSchedulesByThirdPartyProvider[
                integrationInfo.thirdPartyProvider
              ]
            }
          />
        )
      )}
    </OrgSettingsSection>
  );
};

export default OrgIntegrations;
