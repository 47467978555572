import {
  AccessReviewConnectionSmallFragment,
  AccessReviewFragment,
  AccessReviewGroupSmallFragment,
  AccessReviewItemFragment,
  AccessReviewItemsSortByField,
  AccessReviewResourceSmallFragment,
  AccessReviewSummaryStatus,
  SortDirection,
  useAccessReviewItemsQuery,
  useAccessReviewQuery,
} from "api/generated/graphql";
import AccessReviewEntitiesFilter, {
  AccessReviewEntitiesFilterData,
} from "components/access_reviews/AccessReviewEntitiesFilter";
import AccessReviewFilterViewer from "components/access_reviews/AccessReviewFilterViewer";
import { Column, ColumnContainer } from "components/column/Column";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeader";
import ColumnListItem, {
  ColumnListItemsSkeleton,
} from "components/column/ColumnListItem";
import ColumnListScroller from "components/column/ColumnListScroller";
import { ColumnSearchAndSort } from "components/column/ColumnSearchAndSort";
import GroupBindingDetailPopover from "components/group_bindings/GroupBindingDetailPopover";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import { Divider, Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { Route, Switch, useHistory, useParams } from "react-router";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import AccessReviewConnectionDetails from "./AccessReviewConnectionDetails";
import AccessReviewGroupDetails from "./AccessReviewGroupDetails";
import AccessReviewResourceDetails from "./AccessReviewResourceDetails";

const AccessReviewEntities = () => {
  return (
    <ColumnContainer>
      <AccessReviewEntitiesColumn />
      <Switch>
        <Route
          path="/access-reviews/:accessReviewId/entities/r/:accessReviewResourceId"
          component={AccessReviewResourceDetails}
        />
        <Route
          path="/access-reviews/:accessReviewId/entities/g/:accessReviewGroupId"
          component={AccessReviewGroupDetails}
        />
        <Route
          path="/access-reviews/:accessReviewId/entities/c/:accessReviewConnectionId"
          component={AccessReviewConnectionDetails}
        />
      </Switch>
    </ColumnContainer>
  );
};

type AccessReviewEntity =
  | AccessReviewResourceSmallFragment
  | AccessReviewGroupSmallFragment
  | AccessReviewConnectionSmallFragment;

const SORT_OPTIONS = [
  {
    label: "Status (Incomplete first)",
    value: {
      field: AccessReviewItemsSortByField.Status,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Status (Completed first)",
    value: {
      field: AccessReviewItemsSortByField.Status,
      direction: SortDirection.Asc,
    },
  },
  {
    label: "First Name (A-Z)",
    value: {
      field: AccessReviewItemsSortByField.Name,
      direction: SortDirection.Asc,
    },
  },
  {
    label: "First Name (Z-A)",
    value: {
      field: AccessReviewItemsSortByField.Name,
      direction: SortDirection.Desc,
    },
  },
];

const getEntity = (
  item: AccessReviewItemFragment
): AccessReviewEntity | undefined => {
  if (item.resource) {
    return item.resource;
  } else if (item.group) {
    return item.group;
  } else if (item.connection) {
    return item.connection;
  }
};

const getEntityName = (entity: AccessReviewEntity) => {
  if ("resource" in entity) {
    return entity.resource?.name ?? "--";
  }
  if ("group" in entity) {
    return entity.group?.name ?? "--";
  }
  if ("connection" in entity) {
    return entity.connection?.name ?? "--";
  }
  return "--";
};

const getEntityType = (entity: AccessReviewEntity) => {
  if ("resource" in entity) {
    return entity.resource?.resourceType;
  }
  if ("group" in entity) {
    return entity.group?.groupType;
  }
  if ("connection" in entity) {
    return entity.connection?.connectionType;
  }
};

const getEntitySublabel = (entity: AccessReviewEntity) => {
  if ("resource" in entity && entity.resource?.resourceType) {
    return `${resourceTypeInfoByType[entity.resource.resourceType]?.fullName}${
      entity.resource.parentResource?.name
        ? " / " + entity.resource.parentResource?.name
        : ""
    }`;
  }
  if ("group" in entity && entity.group?.groupType) {
    return groupTypeInfoByType[entity.group.groupType].name;
  }
  if ("connection" in entity) {
    return "App";
  }
};

const getEntityAccessory = (entity: AccessReviewEntity) => {
  if ("group" in entity && entity.group && entity.group?.groupBinding) {
    return (
      <GroupBindingDetailPopover
        groupId={entity.group?.id ?? ""}
        groupBinding={entity.group.groupBinding}
      />
    );
  }
};

const getStatusIcon = (status: AccessReviewSummaryStatus) => {
  switch (status) {
    case AccessReviewSummaryStatus.NoReviewNeeded:
      return <Icon name="check-circle" color="gray600" size="xs" />;
    case AccessReviewSummaryStatus.Completed:
      return <Icon name="check-circle" color="green600" size="xs" />;
    case AccessReviewSummaryStatus.NotStarted:
      return <Icon name="minus-circle" color="gray600" size="xs" />;
    case AccessReviewSummaryStatus.PartiallyCompleted:
      return <Icon name="incomplete" color="yellow600" size="xs" />;
    case AccessReviewSummaryStatus.NeedsUpdateRequestApproval:
    case AccessReviewSummaryStatus.NeedsEndSystemRevocation:
    case AccessReviewSummaryStatus.NeedsAttention:
      return <Icon name="alert-circle" color="yellow600" size="xs" />;
  }
};

const AccessReviewEntitiesColumn = () => {
  const history = useHistory();
  const {
    accessReviewId,
    accessReviewResourceId,
    accessReviewGroupId,
    accessReviewConnectionId,
  } = useParams<Record<string, string>>();

  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const [filter, setFilter] = useState<AccessReviewEntitiesFilterData>({});

  const { data, error, loading } = useAccessReviewQuery({
    variables: {
      input: {
        accessReviewId,
      },
    },
    skip: !accessReviewId,
  });
  let accessReview: AccessReviewFragment | null = null;
  if (data?.accessReview.__typename === "AccessReviewResult") {
    accessReview = data.accessReview.accessReview;
  }

  const {
    data: itemsData,
    error: itemsError,
    loading: itemsLoading,
    previousData: prevItemsData,
    fetchMore,
  } = useAccessReviewItemsQuery({
    variables: {
      input: {
        accessReviewId,
        searchQuery,
        sortBy: sortBy.value,
        reviewerFilter: filter.reviewerFilter
          ? {
              userId: filter.reviewerFilter.userId,
            }
          : undefined,
      },
    },
    skip: !accessReviewId,
  });

  const cursor = itemsData?.accessReviewItems.cursor;
  const loadMore = cursor
    ? async () => {
        await fetchMore({
          variables: {
            input: {
              accessReviewId,
              searchQuery,
              sortBy: sortBy.value,
              reviewerFilter: filter.reviewerFilter
                ? {
                    userId: filter.reviewerFilter.userId,
                  }
                : undefined,
              cursor,
            },
          },
        });
      }
    : undefined;

  const accessReviewItems =
    itemsData?.accessReviewItems.items ??
    prevItemsData?.accessReviewItems.items ??
    [];

  if (loading || (itemsLoading && !prevItemsData)) {
    return (
      <Column>
        <ColumnHeaderSkeleton />
        <Divider />
        <ColumnListItemsSkeleton />
      </Column>
    );
  }

  if (!accessReviewId || !accessReview || error || itemsError) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  const renderItemRow = (index: number) => {
    const item = accessReviewItems[index];
    if (!item) return <></>;

    const entity = getEntity(item);

    if (!entity) {
      return <></>;
    }

    const entityType = getEntityType(entity);
    if (!entityType) {
      return <></>;
    }

    return (
      <ColumnListItem
        key={entity.id}
        label={getEntityName(entity) ?? "--"}
        icon={{ type: "entity", entityType }}
        sublabel={getEntitySublabel(entity)}
        rightContent={
          <>
            {getEntityAccessory(entity)}
            {getStatusIcon(entity.status)}
          </>
        }
        selected={
          entity.id === accessReviewResourceId ||
          entity.id === accessReviewGroupId ||
          entity.id === accessReviewConnectionId
        }
        onClick={() => {
          let itemUrlKey = "r";
          if (item.group) {
            itemUrlKey = "g";
          } else if (item.connection) {
            itemUrlKey = "c";
          }
          history.push(
            `/access-reviews/${accessReviewId}/entities/${itemUrlKey}/${entity.id}`
          );
        }}
      />
    );
  };

  const isPastAccessReview = accessReview?.stoppedDate !== null;

  return (
    <Column>
      <ColumnHeader
        title="Review Entities"
        icon={{ type: "name", icon: "cube" }}
        breadcrumbs={[
          { name: "Access Reviews", to: "/access-reviews" },
          {
            name: isPastAccessReview ? "Ended" : "Ongoing",
            to: `/access-reviews?category=${
              isPastAccessReview ? "ended" : "ongoing"
            }`,
          },
          {
            name: accessReview.name,
            to: `/access-reviews/${accessReviewId}`,
          },
        ]}
      />
      <Divider />
      <ColumnSearchAndSort
        setSearchQuery={setSearchQuery}
        setSortBy={setSortBy}
        sortOptions={SORT_OPTIONS}
        sortBy={sortBy}
        placeholder="Search for access review item"
        trackName="access-review-items"
        filterComponent={<AccessReviewEntitiesFilter onSetFilter={setFilter} />}
      />
      <div className={sprinkles({ marginBottom: "sm" })}>
        <AccessReviewFilterViewer filters={filter} onSetFilters={setFilter} />
      </div>
      <ColumnListScroller
        renderRow={renderItemRow}
        numRows={accessReviewItems.length}
        hasNextPage={cursor != null}
        onLoadMore={loadMore}
        loading={itemsLoading}
      />
    </Column>
  );
};

export default AccessReviewEntities;
