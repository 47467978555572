import { openInNewTab } from "api/common/common";
import {
  RequestTemplateSmallFragment,
  useRequestTemplatesQuery,
} from "api/generated/graphql";
import { Button, FormRow, Select, Skeleton, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import { AppsContext } from "views/apps/AppsContext";

import { FormMode } from "../common";

interface Props {
  mode: FormMode;
  // undefined = leave unchanged for bulk edit
  // null = unset request template
  requestTemplateId?: string | null;
  onChange: (requestTemplateId: string | undefined | null) => void;
}

const RequestTemplateRow = (props: Props) => {
  const { bulkMode } = useContext(AppsContext);

  const { data, loading, error } = useRequestTemplatesQuery();

  if (loading) {
    return (
      <FormRow title="Custom fields">
        <Skeleton />
      </FormRow>
    );
  }

  if (error) {
    return (
      <FormRow title="Custom fields">Failed to get request templates</FormRow>
    );
  }

  const requestTemplateByID: {
    [key: string]: RequestTemplateSmallFragment;
  } = {};
  data?.requestTemplates.requestTemplates.forEach((template) => {
    requestTemplateByID[template.id] = template;
  });

  const options =
    data?.requestTemplates.requestTemplates.map((template) => template.id) ??
    [];

  const viewContent =
    (props.requestTemplateId &&
      requestTemplateByID[props.requestTemplateId]?.name) ??
    "--";

  const editContent = (
    <>
      {bulkMode === "edit" && (
        <div className={sprinkles({ marginBottom: "md" })}>
          <Switch
            label="Leave unchanged"
            checked={props.requestTemplateId === undefined}
            onChange={(val) => props.onChange(val ? undefined : null)}
            rightAlign
          />
        </div>
      )}
      <Select
        value={props.requestTemplateId ?? undefined}
        options={options}
        onChange={(val) => props.onChange(val ?? null)}
        getOptionLabel={(opt) => requestTemplateByID[opt]?.name}
        clearable
      />
      <div className={sprinkles({ marginTop: "md" })}>
        <Button
          label="Edit/Create request templates"
          leftIconName="clipboard"
          type="primary"
          borderless
          onClick={() => openInNewTab("/templates")}
        />
      </div>
    </>
  );

  return (
    <FormRow title="Custom fields">
      {props.mode === "view" ? viewContent : editContent}
    </FormRow>
  );
};

export default RequestTemplateRow;
