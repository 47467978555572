import {
  RequestFragment,
  RequestStatus,
  useSendManualRequestReminderMutation,
} from "api/generated/graphql";
import { useToast } from "components/toast/Toast";
import { Button, ButtonV3, Modal } from "components/ui";
import { useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import { useRequestPermissions } from "views/requests/RequestOverview";

interface Props {
  request: RequestFragment;
}

const RequestRemindReviewersButton = (props: Props) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const { belongsToCurrentUser } = useRequestPermissions(props.request);
  const { displaySuccessToast, displayErrorToast } = useToast();
  const [reminderModalOpen, setReminderModalOpen] = useState(false);

  const [
    sendManualRequestReminder,
    { loading },
  ] = useSendManualRequestReminderMutation({
    refetchQueries: ["EventsFull"],
  });

  if (props.request.status !== RequestStatus.Pending || !belongsToCurrentUser) {
    return null;
  }

  const handleSendRequestReminder = async () => {
    try {
      const { data } = await sendManualRequestReminder({
        variables: {
          input: {
            id: props.request.id,
          },
        },
      });
      switch (data?.sendManualRequestReminder.__typename) {
        case "SendManualRequestReminderResult":
          displaySuccessToast(`Success: reminder sent`);
          setReminderModalOpen(false);
          break;
        case "RequestNotFoundError":
          logWarning(new Error(`request not found`));
          displayErrorToast(`Error: request not found`);
          break;
        case "ReviewersAlreadyRemindedError":
          logWarning(new Error(`reviewers are already reminded`));
          displayErrorToast(
            `Error: reviewers can only be reminded once every 24 hours`
          );
          break;
        default:
          logError(new Error(`failed to send manual request reminder`));
          displayErrorToast(`Error: failed to send manual request reminder`);
      }
    } catch (error) {
      logError(error, `failed to send manual request reminder`);
      displayErrorToast(`Error: failed to send manual request reminder`);
    }
  };

  return (
    <>
      {hasV3 ? (
        <ButtonV3
          label="Send reminder to reviewers"
          leftIconName="mail"
          onClick={() => setReminderModalOpen(true)}
          size="sm"
          type="defaultSecondary"
        />
      ) : (
        <Button
          label="Send reminder to reviewers"
          leftIconName="mail"
          onClick={() => setReminderModalOpen(true)}
          size="sm"
          borderless
        />
      )}
      <Modal
        title="Send reminder to reviewers"
        isOpen={reminderModalOpen}
        onClose={() => setReminderModalOpen(false)}
      >
        <Modal.Body>
          Click "Send" to send a reminder notification to all reviewers who are
          still needed to approve your access request.
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Send"
          primaryButtonLoading={loading}
          onPrimaryButtonClick={handleSendRequestReminder}
          secondaryButtonLabel="Cancel"
          onSecondaryButtonClick={() => setReminderModalOpen(false)}
        />
      </Modal>
    </>
  );
};

export default RequestRemindReviewersButton;
