import { getModifiedErrorMessage } from "api/ApiContext";
import {
  RequestApprovalType,
  RequestFragment,
  RequestStatus,
  useEscalateRequestToSkipManagerMutation,
  useUserSkipManagerQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { useToast } from "components/toast/Toast";
import { Button, ButtonV3, Modal } from "components/ui";
import { useContext, useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";

interface Props {
  request: RequestFragment;
}

const RequestEscalateManagerButton = (props: Props) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const { authState } = useContext(AuthContext);
  const { displaySuccessToast, displayErrorToast } = useToast();
  const [escalateRequestModalOpen, setEscalateRequestModalOpen] = useState(
    false
  );

  const { data: userSkipManager } = useUserSkipManagerQuery({
    variables: {
      input: authState.user?.user.id ?? "",
    },
  });

  const [
    escalateRequestToSkipManager,
    { loading },
  ] = useEscalateRequestToSkipManagerMutation({
    refetchQueries: ["EventsFull"],
  });

  let userHasSkipManager = !!authState.user?.user.managerId;
  switch (userSkipManager?.user.__typename) {
    case "UserResult":
      userHasSkipManager =
        userHasSkipManager && !!userSkipManager.user.user.manager?.managerId;
      break;
    default:
      userHasSkipManager = false;
  }

  const requestRequiresManagerApproval =
    props.request.stages.some((item) =>
      item.stages.some(
        (stage) =>
          stage.reviewers.some(
            (reviewer) =>
              reviewer.reviewerType === RequestApprovalType.Manager &&
              !reviewer.reviewerAction
          ) ?? false
      )
    ) ?? false;

  if (
    props.request.status !== RequestStatus.Pending ||
    props.request.requesterId !== authState.user?.user.id ||
    props.request.escalatedToSkipManager ||
    !userHasSkipManager ||
    !requestRequiresManagerApproval
  ) {
    return null;
  }

  const handleEscalateToSkipManager = async () => {
    try {
      const { data } = await escalateRequestToSkipManager({
        variables: {
          input: {
            id: props.request.id,
          },
        },
      });
      switch (data?.escalateRequestToSkipManager.__typename) {
        case "EscalateRequestToSkipManagerResult": {
          displaySuccessToast(
            `Success: request has been escalated to your skip-manager`
          );
          setEscalateRequestModalOpen(false);
          break;
        }
        case "RequestNotFoundError":
        case "ManagerHasAlreadyApprovedError":
        case "NoManagerSetForRequestingUserError":
        case "NoSkipManagerSetForTargetUserError":
          displayErrorToast(data.escalateRequestToSkipManager.message);
          break;
        default:
          logError(new Error(`request escalation failed`));
          displayErrorToast(`Error: request escalation failed`);
      }
    } catch (error) {
      logError(error, "request escalation failed");
      displayErrorToast(
        getModifiedErrorMessage(`Error: request escalation failed`, error)
      );
    }
  };

  return (
    <>
      <Modal
        title="Escalate to skip-manager"
        isOpen={escalateRequestModalOpen}
        onClose={() => setEscalateRequestModalOpen(false)}
      >
        <Modal.Body>
          Click "Escalate" to notify your skip-manager to approve your access
          request.
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Escalate"
          primaryButtonLoading={loading}
          onPrimaryButtonClick={handleEscalateToSkipManager}
          secondaryButtonLabel="Cancel"
          onSecondaryButtonClick={() => setEscalateRequestModalOpen(false)}
        />
      </Modal>
      {hasV3 ? (
        <ButtonV3
          label="Escalate to skip manager"
          leftIconName="user-plus"
          onClick={() => setEscalateRequestModalOpen(true)}
          size="sm"
          type="defaultSecondary"
        />
      ) : (
        <Button
          label="Escalate to skip manager"
          leftIconName="user-plus"
          onClick={() => setEscalateRequestModalOpen(true)}
          size="sm"
          borderless
        />
      )}
    </>
  );
};

export default RequestEscalateManagerButton;
