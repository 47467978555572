import { getModifiedErrorMessage } from "api/ApiContext";
import {
  EntityType,
  GroupFragment,
  GroupType,
  ImportSetting,
  Maybe,
  RemoveGroupResourceInput,
  ResourceAccessLevel,
  ResourceType,
  useRemoveGroupResourcesMutation,
} from "api/generated/graphql";
import { ConditionalEditor } from "components/entity_viewer/editor/ConditionalEditor";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import ResourceLabel from "components/label/item_labels/ResourceLabel";
import ResourceRoleSelectModal from "components/modals/ResourceRoleSelectModal";
import { SelectType } from "components/modals/update/SelectItemsModal";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import { Banner, Checkbox } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import pluralize from "pluralize";
import React, { useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import { hasRemoteGroupResources } from "utils/directory/connections";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import GroupAddResourcesModal from "views/groups/GroupAddResourcesModal";
import GroupResourcesTable from "views/groups/GroupResourcesTable";

import { Role } from "../../../../components/modals/ResourceIndividualRoleModal";

type GroupResourcesRowProps = {
  group: GroupFragment;
};

export const GroupResourcesRow = (props: GroupResourcesRowProps) => {
  const canEdit = props.group.authorizedActions?.includes(
    AuthorizedActionManage
  );

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const startPushTaskPoll = usePushTaskLoader();
  const logEvent = useLogEvent();
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const history = useHistory();

  const [
    removeGroupResources,
    { loading: removeLoading },
  ] = useRemoveGroupResourcesMutation();

  const [showResourcesAddModal, setShowResourcesAddModal] = useState(false);

  const [showResourcesRemoveModal, setShowResourcesRemoveModal] = useState(
    false
  );
  const [roleByResourceIdToRemove, setRoleByResourceIdToRemove] = useState<
    Record<string, Role[]>
  >({});
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [showUnmanagedResources, setShowUnmanagedResources] = useState(false);

  const resourcesTable = (
    <GroupResourcesTable
      group={props.group}
      groupResources={props.group.groupResources}
      denseFormatting={true}
      showUnmanagedResources={showUnmanagedResources}
    />
  );

  const editor = (
    <ConditionalEditor
      isAdmin={canEdit}
      menuOptions={[
        {
          label: "Add",
          handler: () => {
            if (hasV3) {
              history.push(`/groups/${props.group.id}/add-resources`);
            } else {
              setShowResourcesAddModal(true);
            }
          },
        },
        {
          label: "Remove",
          handler: () => {
            setShowResourcesRemoveModal(true);
          },
        },
      ]}
      disabledInReadOnlyMode
    />
  );

  const directRolesByResourceId: Record<string, ResourceAccessLevel[]> = {};
  const numDirectAccessPointsByResourceId: Record<string, number> = {};
  props.group.groupResources.forEach((groupResource) => {
    if (!directRolesByResourceId[groupResource.resourceId]) {
      directRolesByResourceId[groupResource.resourceId] = [];
    }
    directRolesByResourceId[groupResource.resourceId] = _.uniqBy(
      [
        ...directRolesByResourceId[groupResource.resourceId],
        groupResource.accessLevel,
      ],
      "accessLevelRemoteId"
    );

    if (!numDirectAccessPointsByResourceId[groupResource.resourceId]) {
      numDirectAccessPointsByResourceId[groupResource.resourceId] = 0;
    }
    numDirectAccessPointsByResourceId[groupResource.resourceId] += 1;
  });

  const addModalReset = () => {
    setShowResourcesAddModal(false);
    setErrorMessage(null);
  };

  const resourcesAddModal = (
    <GroupAddResourcesModal
      onClose={addModalReset}
      group={props.group}
      existingRolesByResourceId={directRolesByResourceId}
    />
  );

  const removeModalReset = () => {
    setShowResourcesRemoveModal(false);
    setErrorMessage(null);
    setRoleByResourceIdToRemove({});
    setSearchQuery("");
  };

  const resourcesRemoveModal = (
    <ResourceRoleSelectModal
      title={"Remove resources"}
      selectType={SelectType.Remove}
      itemName={"resource"}
      entryInfos={_.uniqBy(props.group.groupResources, "resourceId")
        .filter(
          (resource) =>
            !!resource.access.directAccessPoint &&
            resource.resource?.name
              .toLowerCase()
              .includes(searchQuery.toLocaleLowerCase()) &&
            resource.resource.isManaged
        )
        .sort((a, b) => {
          if (a && b) {
            const aSortString = a.resource?.name || "";
            const bSortString = b.resource?.name || "";
            return aSortString.localeCompare(bSortString);
          }
          return 0;
        })
        .map((groupResource) => {
          const directRoleCount =
            numDirectAccessPointsByResourceId[groupResource.resourceId] || 0;
          const canManageResource = groupResource.resource?.authorizedActions?.includes(
            AuthorizedActionManage
          );

          let disabled = false;
          let tooltipText;
          if (
            groupResource.resource?.resourceType === ResourceType.OktaRole &&
            props.group.groupType === GroupType.OktaGroup
          ) {
            disabled = true;
            tooltipText =
              "Okta Role and Group relationships cannot be managed in Opal. Please make these changes in Okta.";
          } else if (!canManageResource) {
            disabled = true;
            tooltipText =
              "You can only remove resources which you administer. Please ask your Opal admin to remove it.";
          }

          let name = groupResource.resource?.name;
          const parentName = groupResource.resource?.parentResource?.name;
          if (parentName) {
            name = `${parentName} / ${name}`;
          }

          return {
            entityId: {
              entityId: groupResource.resourceId,
              entityType: EntityType.User,
            },
            label: (
              <ResourceLabel
                resourceId={groupResource.resourceId}
                text={name}
                subText={`${directRoleCount} direct ${pluralize(
                  "role",
                  directRoleCount
                )}`}
                icon={groupResource.resource?.iconUrl}
                bold={true}
                iconLarge={true}
              />
            ),
            isBold: false,
            isBreadcrumb: false,
            disabled,
            tooltipText,
          };
        })}
      isModalOpen={showResourcesRemoveModal}
      onClose={removeModalReset}
      onSubmit={async () => {
        logEvent({
          name: "apps_remove_resources_from_group",
          properties: {
            numResourcesRemoved: Object.entries(roleByResourceIdToRemove)
              .length,
          },
        });
        try {
          const groupResourcesToRemove: RemoveGroupResourceInput[] = [];
          for (const [resourceId, roles] of Object.entries(
            roleByResourceIdToRemove
          )) {
            roles.forEach((role) => {
              groupResourcesToRemove.push({
                groupId: props.group.id,
                resourceId: resourceId,
                accessLevel: {
                  accessLevelName: role.accessLevelName,
                  accessLevelRemoteId: role.accessLevelRemoteId,
                },
              });
            });
          }

          const { data } = await removeGroupResources({
            variables: {
              input: {
                groupResources: groupResourcesToRemove,
              },
            },
          });
          switch (data?.removeGroupResources.__typename) {
            case "RemoveGroupResourcesResult":
              startPushTaskPoll(data.removeGroupResources.taskId, {
                refetchOnComplete: [{ groupId: props.group.id }],
              });
              removeModalReset();
              break;
            case "UserFacingError":
              setErrorMessage(data.removeGroupResources.message);
              break;
            default:
              logError(new Error(`failed to remove resources from group`));
              setErrorMessage("Error: failed to remove resources from group");
          }
        } catch (error) {
          logError(error, "failed to remove resources from group");
          setErrorMessage(
            getModifiedErrorMessage(
              "Error: failed to remove resources from group",
              error
            )
          );
        }
      }}
      loading={removeLoading}
      errorMessage={errorMessage}
      submitDisabled={Object.keys(roleByResourceIdToRemove).length === 0}
      updatedRolesByEntityId={roleByResourceIdToRemove}
      setUpdatedRolesByEntityId={setRoleByResourceIdToRemove}
      existingDirectRolesByEntityId={directRolesByResourceId}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    />
  );

  const connection = props.group.connection;

  const showUnmanagedResourcesCheckbox = (
    <Checkbox
      size="sm"
      label="Show unmanaged resources"
      checked={showUnmanagedResources}
      onChange={(value) => {
        setShowUnmanagedResources(value);
      }}
    />
  );

  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        width: "100%",
      })}
    >
      {connection &&
        hasRemoteGroupResources(connection.connectionType) &&
        connection.importSetting !== ImportSetting.All &&
        !connection.autoImportGroupResources && (
          <div className={sprinkles({ marginTop: "md", marginX: "md" })}>
            <Banner
              message={`This group may have access to resources that aren't imported into
              Opal because the app is not configured to auto-import group resources.`}
            />
          </div>
        )}
      <EntityViewerRow
        title={"Resources"}
        content={
          <EmptyStateContentWrapper
            content={resourcesTable}
            entityType={EntityType.Resource}
            title={`No resources in group`}
            subtitle={`Add resources to this group to populate the table`}
            buttonTitle={`Add resources`}
            isEmpty={props.group.groupResources.length === 0}
            onClickHandler={() => {
              setShowResourcesAddModal(true);
            }}
          />
        }
        editor={editor}
        checkbox={showUnmanagedResourcesCheckbox}
        modals={
          <>
            {showResourcesAddModal && resourcesAddModal}
            {showResourcesRemoveModal && resourcesRemoveModal}
          </>
        }
        isTable={true}
      />
    </div>
  );
};

export default GroupResourcesRow;
