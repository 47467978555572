import { EventFragment, useEventQuery } from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import { Divider } from "components/ui";
import _ from "lodash";
import { useParams } from "react-router";
import { eventTypeToString } from "utils/events";
import { logError } from "utils/logging";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import EventViewer from "./viewer/EventViewer";

const EventDetailColumn = () => {
  const { eventId } = useParams<Record<string, string>>();

  const { data, error, loading } = useEventQuery({
    variables: {
      input: {
        id: eventId!,
      },
    },
  });

  let event: EventFragment | null = null;
  if (data) {
    switch (data.event.__typename) {
      case "EventResult":
        event = data.event.event;
        break;
      case "EventNotFoundError":
        return (
          <Column isContent>
            <NotFoundPage />
          </Column>
        );
      default:
        logError(new Error(`failed to list events`));
    }
  } else if (error) {
    logError(error, `failed to list events`);
  }

  if (loading) {
    return (
      <Column isContent>
        <ColumnContentSkeleton />
      </Column>
    );
  }

  if (!eventId || !event || error) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  return (
    <Column isContent>
      <ColumnHeader
        icon={{ type: "name", icon: "events" }}
        title={eventTypeToString(event.eventType, true)}
        subtitle={_.truncate(event.id, {
          length: 8,
          omission: "",
        })}
      />
      <Divider margin="md" />
      <EventViewer event={event} />
    </Column>
  );
};

export default EventDetailColumn;
