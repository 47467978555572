import { gql, useFragment } from "@apollo/client";
import { OpalAppUserCountCellFragment } from "api/generated/graphql";

import OpalCountCell from "../OpalCountCell";

const OpalAppUserCountCell = (props: { appID: string }) => {
  const { complete, data } = useFragment<OpalAppUserCountCellFragment>({
    fragment: gql`
      fragment OpalAppUserCountCell on App {
        userAccessCount
      }
    `,
    fragmentName: "OpalAppUserCountCell",
    from: {
      __typename: "App",
      id: props.appID,
    },
  });

  return (
    <OpalCountCell
      subject="User"
      count={data.userAccessCount}
      complete={complete}
    />
  );
};

export default OpalAppUserCountCell;
