import { ColumnContainer } from "components/column/Column";
import { Route, Switch } from "react-router";
import { UserExperience, useUserHasUserExperience } from "utils/auth/auth";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import BundleCatalog from "views/apps/enduser_exp/BundleCatalog";
import BundleDetails from "views/apps/enduser_exp/BundleDetails";

import BundleDetailColumn from "./BundleDetailColumn";
import BundleDetailColumnV3 from "./BundleDetailColumnV3";
import BundleDetailContent from "./BundleDetailContent";
import BundlesColumn from "./BundlesColumn";

const Bundles = () => {
  const hasBundles = useFeatureFlag(FeatureFlag.Bundles);
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);
  const showEndUserExp =
    useUserHasUserExperience() === UserExperience.EndUserUX;

  if (!hasBundles) {
    return null;
  }

  if (hasV3Nav) {
    return (
      <ColumnContainer>
        <Switch>
          <Route
            exact
            path="/bundles"
            component={showEndUserExp ? BundleCatalog : BundlesColumn}
          />
          <Route
            exact
            path={["/bundles/:bundleId", "/bundles/:bundleId/:bundleView"]}
            component={showEndUserExp ? BundleDetails : BundleDetailColumnV3}
          />
        </Switch>
      </ColumnContainer>
    );
  }

  return (
    <Switch>
      <Route exact path="/bundles" component={BundlesColumn} />
      <Route
        exact
        path={["/bundles/:bundleId", "/bundles/:bundleId/:bundleView"]}
        component={hasV3Nav ? BundleDetailColumnV3 : BundleDetailColumn}
      />
      <Route path="/bundles/:bundleId" component={BundleDetailContent} />
    </Switch>
  );
};

export default Bundles;
