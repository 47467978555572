import {
  AccessReviewGroupFragment,
  Maybe,
  useAccessReviewGroupQuery,
} from "api/generated/graphql";
import React from "react";
import { useParams } from "react-router";
import { logError } from "utils/logging";
import AccessReviewGroupViewer from "views/access_reviews/groups/AccessReviewGroupViewer";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

const AccessReviewGroupViewerWrapper = () => {
  const { accessReviewGroupId } = useParams<Record<string, string>>();

  const { data, error, loading } = useAccessReviewGroupQuery({
    variables: {
      input: {
        id: accessReviewGroupId!,
      },
    },
  });

  let accessReviewGroup: Maybe<AccessReviewGroupFragment> = null;
  if (data) {
    switch (data.accessReviewGroup.__typename) {
      case "AccessReviewGroupResult":
        accessReviewGroup = data.accessReviewGroup.accessReviewGroup;
        break;
      case "AccessReviewGroupNotFoundError":
        return <NotFoundPage />;
      default:
        logError(new Error(`failed to get access review resource`));
    }
  }

  if (loading) {
    return <ColumnContentSkeleton />;
  }

  if (!accessReviewGroup || !accessReviewGroup.accessReview || error) {
    logError(error, `failed to get access review resource`);
    return <UnexpectedErrorPage error={error} />;
  }

  return <AccessReviewGroupViewer accessReviewGroup={accessReviewGroup} />;
};

export default AccessReviewGroupViewerWrapper;
