import {
  FilterInput,
  FiltersInput,
  FiltersMatchMode,
  GroupType,
  ResourceType,
  TagFilterMatchMode,
} from "api/generated/graphql";
import { TagKeyValue } from "components/tags/Tags";

export interface AccessReviewFilters {
  tags: TagKeyValue[];
  names: string[];
  adminIDs: string[];
  resourceTypes: ResourceType[];
  groupTypes: GroupType[];
  entityIDs: string[];
  connectionIDs: string[];
  userIDs: string[];
  matchMode: FiltersMatchMode;
}

export function defaultAccessReviewFilters(): AccessReviewFilters {
  return {
    tags: [],
    names: [],
    adminIDs: [],
    resourceTypes: [],
    groupTypes: [],
    entityIDs: [],
    connectionIDs: [],
    userIDs: [],
    matchMode: FiltersMatchMode.Any,
  };
}

export function buildAccessReviewFilter(
  filters: AccessReviewFilters
): FiltersInput {
  const apiFilters: FilterInput[] = [];

  if (filters.tags && filters.tags.length > 0) {
    apiFilters.push({
      tagFilter: {
        // TS allows us to pass the tags directly but
        // we actually have to remove any irrelevant fields first
        // or the server will reject the request
        tags: filters.tags.map((t) => ({
          key: t.key,
          value: t.value,
        })),
        matchMode: TagFilterMatchMode.Any,
      },
    });
  }

  if (filters.names && filters.names.length > 0) {
    apiFilters.push({
      namesFilter: {
        names: filters.names,
      },
    });
  }

  if (filters.adminIDs && filters.adminIDs.length > 0) {
    apiFilters.push({
      adminsFilter: {
        adminIDs: filters.adminIDs,
      },
    });
  }

  if (filters.resourceTypes && filters.resourceTypes.length > 0) {
    apiFilters.push({
      resourceTypesFilter: {
        resourceTypes: filters.resourceTypes,
      },
    });
  }

  if (filters.groupTypes && filters.groupTypes.length > 0) {
    apiFilters.push({
      groupTypesFilter: {
        groupTypes: filters.groupTypes,
      },
    });
  }

  if (filters.entityIDs && filters.entityIDs.length > 0) {
    apiFilters.push({
      entityIDsFilter: {
        entityIDs: filters.entityIDs,
      },
    });
  }

  if (filters.connectionIDs && filters.connectionIDs.length > 0) {
    apiFilters.push({
      connectionsFilter: {
        connectionIDs: filters.connectionIDs,
      },
    });
  }

  if (filters.userIDs && filters.userIDs.length > 0) {
    apiFilters.push({
      usersFilter: {
        userIDs: filters.userIDs,
      },
    });
  }

  return {
    matchMode: filters.matchMode,
    filters: apiFilters,
  };
}

export const getHasFilters = (filters: AccessReviewFilters) => {
  return (
    filters &&
    (filters.names.length > 0 ||
      filters.adminIDs.length > 0 ||
      filters.tags.length > 0 ||
      filters.resourceTypes.length > 0 ||
      filters.groupTypes.length > 0 ||
      filters.entityIDs.length > 0 ||
      filters.connectionIDs.length > 0 ||
      filters.userIDs.length > 0)
  );
};
