import {
  RequestsSortByField,
  RequestStatus,
  RequestType as GQLRequestType,
  SortDirection,
  useRequestsQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import ColumnListItem, {
  ColumnListItemsSkeleton,
} from "components/column/ColumnListItem";
import ColumnListScroller from "components/column/ColumnListScroller";
import { ColumnSearchAndSort } from "components/column/ColumnSearchAndSort";
import { Divider, Icon } from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { logError } from "utils/logging";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { getRequestedItemsSummaryText } from "views/requests/Requests";

import * as styles from "./RequestsListColumn.css";
import { RequestsType } from "./utils";

const SORT_OPTIONS = [
  {
    label: "Most recently updated first",
    value: {
      field: RequestsSortByField.UpdatedAt,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Least recently updated first",
    value: {
      field: RequestsSortByField.UpdatedAt,
      direction: SortDirection.Asc,
    },
  },
  {
    label: "Most recently created first",
    value: {
      field: RequestsSortByField.CreatedAt,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Least recently created first",
    value: {
      field: RequestsSortByField.CreatedAt,
      direction: SortDirection.Asc,
    },
  },
];

interface Props {
  requestsType: RequestsType;
}

const REQUEST_TYPE_TO_TITLE: Record<RequestsType, string> = {
  inbox: "Inbox",
  sent: "Sent requests",
  admin: "Admin",
};

const REQUESTS_PER_PAGE = 50;

const getGQLRequestType = (
  requestType: RequestsType
): GQLRequestType | null => {
  switch (requestType) {
    case "inbox":
      return GQLRequestType.Incoming;
    case "sent":
      return GQLRequestType.Outgoing;
    case "admin":
      return null;
  }
};

const HIDE_COMPLETED_PARAM = "hideCompleted";

const RequestsListColumn = (props: Props) => {
  const { requestsType } = props;
  const title = REQUEST_TYPE_TO_TITLE[requestsType];
  const history = useHistory();
  const { requestId } = useParams<{ requestId: string }>();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const hideCompleted = query.get(HIDE_COMPLETED_PARAM) === "true";
  const requestType = getGQLRequestType(props.requestsType);

  const [searchQuery, setSearchQuery] = useState<string>("");
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);

  const { data, error, loading, fetchMore } = useRequestsQuery({
    variables: {
      input: {
        requestType,
        maxNumEntries: REQUESTS_PER_PAGE,
        searchQuery: searchQuery.length ? searchQuery : undefined,
        sortBy: sortBy.value,
        showPendingOnly: hideCompleted,
      },
    },
  });

  if (loading && !searchQuery) {
    return (
      <Column>
        <ColumnHeader
          title={title}
          icon={{ icon: "send", type: "name" }}
          breadcrumbs={[{ name: "Requests", to: "/requests" }]}
        />
        <Divider />
        <ColumnListItemsSkeleton />
      </Column>
    );
  }

  if (error) {
    logError(error, `failed to list requests`);
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  const requests = data?.requests.requests;
  const cursor = data?.requests.cursor;

  const renderRow = (index: number) => {
    if (!requests) {
      return <></>;
    }
    const request = requests[index];
    if (!request) return <></>;

    let requestedItemsSummaryText = getRequestedItemsSummaryText(request, true);

    let rightIcon: React.ReactNode;
    switch (request.status) {
      case RequestStatus.Pending:
        rightIcon = "•";
        break;
      case RequestStatus.Approved:
        rightIcon = <Icon name="thumbs-up" size="xs" />;
        break;
      case RequestStatus.Denied:
        rightIcon = <Icon name="thumbs-down" size="xs" />;
        break;
      case RequestStatus.Canceled:
        rightIcon = <Icon name="x-circle" size="xs" />;
        break;
    }

    const rightContent = (
      <div
        className={styles.statusIcon({
          status: request.status.toLowerCase() as
            | "pending"
            | "approved"
            | "denied"
            | "canceled",
        })}
      >
        {rightIcon}
      </div>
    );

    // If we're in a subpage, keep it in the URL but replace the request ID
    let basePath = "/requests/";
    const currentPath = history.location.pathname;
    if (currentPath.startsWith("/requests/inbox")) {
      basePath = "/requests/inbox/";
    } else if (currentPath.startsWith("/requests/sent")) {
      basePath = "/requests/sent/";
    } else if (currentPath.startsWith("/requests/admin")) {
      basePath = "/requests/admin/";
    }

    return (
      <ColumnListItem
        key={request.id}
        label={request.requester?.fullName || ""}
        sublabel={requestedItemsSummaryText}
        icon={{
          icon: request.requester?.avatarUrl || defaultAvatarURL,
          fallbackIcon: defaultAvatarURL,
          type: "src",
          style: "rounded",
        }}
        rightContent={rightContent}
        selected={request.id === requestId}
        onClick={() =>
          history.push({
            pathname: basePath + request.id,
            search: history.location.search,
          })
        }
      />
    );
  };

  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            input: {
              requestType,
              cursor,
              maxNumEntries: REQUESTS_PER_PAGE,
              searchQuery: searchQuery.length ? searchQuery : undefined,
              sortBy: sortBy.value,
              showPendingOnly: hideCompleted,
            },
          },
        });
      }
    : undefined;

  const handleToggleHideCompleted = () => {
    const newHideCompleted = !hideCompleted;
    const newQuery = new URLSearchParams(location.search);
    newQuery.set(HIDE_COMPLETED_PARAM, newHideCompleted.toString());
    history.push({
      pathname: location.pathname,
      search: newQuery.toString(),
    });
  };

  return (
    <Column>
      <ColumnHeader
        title={title}
        icon={{ icon: "send", type: "name" }}
        breadcrumbs={[{ name: "Requests", to: "/requests" }]}
        menuOptions={
          props.requestsType !== "inbox"
            ? [
                {
                  label: `${
                    hideCompleted ? "Show" : "Hide"
                  } completed requests`,
                  onClick: handleToggleHideCompleted,
                  icon: { type: "name", icon: "eye" },
                },
              ]
            : []
        }
      />
      <Divider />
      {props.requestsType !== "sent" ? (
        <ColumnSearchAndSort
          key="search"
          setSearchQuery={setSearchQuery}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortOptions={SORT_OPTIONS}
          placeholder="Filter by requester name or email"
          trackName="requests"
        />
      ) : null}
      <ColumnListScroller
        renderRow={renderRow}
        numRows={requests?.length ?? 0}
        hasNextPage={Boolean(cursor)}
        loading={loading}
        onLoadMore={loadMoreRows}
        emptyState={{ title: "No requests." }}
      />
    </Column>
  );
};

export default RequestsListColumn;
