import { getModifiedErrorMessage } from "api/ApiContext";
import {
  EntityType,
  GroupAccessLevelInput,
  GroupFragment,
  GroupUserFragment,
  GroupUserSource,
  Maybe,
  useAddGroupUsersMutation,
  useGroupAccessLevelsQuery,
  UserDropdownPreviewFragment,
  useRemoveGroupUsersMutation,
} from "api/generated/graphql";
import onCallGroupLogo from "assets/logos/on-call-group-icon.svg";
import { PaginatedUserDropdown } from "components/dropdown/PaginatedUserDropdown";
import { ConditionalEditor } from "components/entity_viewer/editor/ConditionalEditor";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import UserLabel from "components/label/item_labels/UserLabel";
import AddGroupUsersModal, {
  IdInfo,
} from "components/modals/update/AddGroupUsersModal";
import SelectItemsModal, {
  SelectType,
} from "components/modals/update/SelectItemsModal";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import { Icon, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { groupUserHasDirectAccess } from "views/Common";
import GroupUsersTable from "views/groups/GroupUsersTable";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
} from "views/requests/utils";
import { GroupUsersUploadCSVButton } from "views/users/GroupUsersUploadCSVButton";

import { Role } from "../../../../components/modals/ResourceIndividualRoleModal";
import { groupTypeHasRoles } from "../../../../utils/directory/groups";

type GroupUsersRowProps = {
  group: GroupFragment;
};

export const GroupUsersRow = (props: GroupUsersRowProps) => {
  const history = useHistory();
  const startPushTaskPoll = usePushTaskLoader();
  const logEvent = useLogEvent();
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  let groupUsers: GroupUserFragment[] = props.group.groupUsers;

  const [users, setUsers] = useState<UserDropdownPreviewFragment[]>([]);

  const [addUsersErrorMessage, setAddUsersErrorMessage] = useState<
    Maybe<string>
  >(null);
  const [removeUsersErrorMessage, setRemoveUsersErrorMessage] = useState<
    Maybe<string>
  >(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [
    accessDurationsByUserIdToAdd,
    setAccessDurationsByUserIdToAdd,
  ] = useState<Record<string, ExpirationValue>>({});
  const [roleByUserIdToAdd, setRoleByUserIdToAdd] = useState<
    Record<string, Role[]>
  >({});

  const [
    addGroupUsers,
    { loading: addUsersLoading },
  ] = useAddGroupUsersMutation();

  const [
    removeGroupUsers,
    { loading: removeUsersLoading },
  ] = useRemoveGroupUsersMutation();

  const [showUsersAddModal, setShowUsersAddModal] = useState(false);
  const [userInfosToAdd, setUserInfosToAdd] = useState<IdInfo[]>([]);

  const [showUsersRemoveModal, setShowUsersRemoveModal] = useState(false);
  const [idsToRemove, setIdsToRemove] = useState<string[]>([]);

  var availableRoles: Maybe<Role[]> | undefined;
  const { data, error } = useGroupAccessLevelsQuery({
    variables: {
      input: {
        groupId: props.group.id,
      },
    },
    skip: !groupTypeHasRoles(props.group.groupType),
  });
  if (data) {
    switch (data.groupAccessLevels.__typename) {
      case "GroupAccessLevelsResult":
        availableRoles = data.groupAccessLevels.accessLevels;
        break;
      default:
        logError(new Error(`Error: failed to list group roles`));
        break;
    }
  } else if (error) {
    logError(new Error(`Error: failed to list group roles`));
  }

  const usersTable = <GroupUsersTable group={props.group} users={groupUsers} />;

  const editor = (
    <ConditionalEditor
      menuOptions={[
        {
          label: "Add",
          handler: () => {
            if (hasV3) {
              history.push(`/groups/${props.group.id}/add-users`);
            } else {
              setShowUsersAddModal(true);
            }
          },
        },
        {
          label: "Remove",
          handler: () => {
            setShowUsersRemoveModal(true);
          },
        },
      ]}
      disabledInReadOnlyMode
      isAdmin={props.group.authorizedActions?.includes(AuthorizedActionManage)}
    />
  );

  const groupUsersWithDirectAccess = groupUsers.filter((groupUser) =>
    groupUserHasDirectAccess(groupUser)
  );
  const groupUserIdsWithDirectAccess = groupUsersWithDirectAccess.map(
    (groupUser) => groupUser.userId
  );

  const usersAddModalReset = () => {
    setShowUsersAddModal(false);
    setAddUsersErrorMessage(null);
    setUserInfosToAdd([]);
    setUsers([]);
    setSearchQuery("");
    setAccessDurationsByUserIdToAdd({});
  };

  const addUsersModal = (
    <AddGroupUsersModal
      key={"users_add"}
      title={"Add users"}
      // @ts-ignore MUSTFIX: ignored-for-upgrade
      selectType={SelectType.Add}
      serviceType={props.group.serviceType}
      itemName={"user"}
      customSearchComponent={
        <PaginatedUserDropdown
          value={undefined} // Not used, because this dropdown is immediately hidden after input is selected
          onChange={(newUser) => {
            if (!newUser) return;
            setUsers([...users, newUser]);
            setUserInfosToAdd([
              ...userInfosToAdd,
              {
                id: newUser.id,
              },
            ]);
          }}
          hiddenUserIds={[
            ...users.map((user) => user.id),
            ...groupUsers.map((gu) => gu.userId),
          ]}
          clearable={false}
          autoFocus
          selectOnly
        />
      }
      entryInfos={
        users
          ?.filter((user) => {
            if (
              !user ||
              user.isSystemUser ||
              groupUserIdsWithDirectAccess.includes(user.id)
            ) {
              return false;
            }

            return (
              user.fullName
                .toLowerCase()
                .includes(searchQuery.toLocaleLowerCase()) ||
              user.email.toLowerCase().includes(searchQuery.toLocaleLowerCase())
            );
          })
          .map((user) => {
            return {
              entityId: {
                entityId: user.id,
                entityType: EntityType.User,
              },
              label: (
                <UserLabel
                  name={user.fullName}
                  avatar={user.avatarUrl}
                  large
                  bold
                />
              ),
              clickHandler: () => {},
              isBold: false,
              isBreadcrumb: false,
            };
          }) || []
      }
      entityType={EntityTypeDeprecated.User}
      idInfosToUpdate={userInfosToAdd}
      setIdInfosToUpdate={setUserInfosToAdd}
      onDeleteId={(id) => {
        setUsers(users.filter((user) => user.id !== id));
        setUserInfosToAdd(userInfosToAdd.filter((info) => info.id !== id));
      }}
      currentIdInfos={
        users?.map((user) => {
          return {
            id: user.id,
            value: "",
          };
        }) || []
      }
      isModalOpen={showUsersAddModal}
      onClose={usersAddModalReset}
      onSubmit={async () => {
        logEvent({
          name: "apps_add_user",
          properties: {
            type: "group",
            numUsers: Object.entries(userInfosToAdd).length,
          },
        });
        try {
          const { data } = await addGroupUsers({
            variables: {
              input: {
                groupUsers: userInfosToAdd.map((info) => {
                  const expirationVal =
                    accessDurationsByUserIdToAdd[info.id] ||
                    ExpirationValue.Indefinite;
                  const accessDurationInMinutes = expirationValueToDurationInMinutes(
                    expirationVal
                  )?.asMinutes();

                  let role: GroupAccessLevelInput | null = null;
                  if (roleByUserIdToAdd[info.id]) {
                    role = {
                      accessLevelName:
                        roleByUserIdToAdd[info.id][0].accessLevelName,
                      accessLevelRemoteId:
                        roleByUserIdToAdd[info.id][0].accessLevelRemoteId,
                    };
                  }

                  return {
                    groupId: props.group.id,
                    userId: info.id,
                    durationInMinutes: accessDurationInMinutes,
                    accessLevel: role,
                  };
                }),
              },
            },
            refetchQueries: ["Group"],
          });
          switch (data?.addGroupUsers.__typename) {
            case "AddGroupUsersResult":
              startPushTaskPoll(data.addGroupUsers.taskId);
              usersAddModalReset();
              break;
            case "CannotAddSystemUserToGroupError":
              logError(new Error(data.addGroupUsers.message));
              setAddUsersErrorMessage(data.addGroupUsers.message);
              break;
            case "CallToWebhookFailedError":
              setAddUsersErrorMessage(data.addGroupUsers.message);
              break;
            case "GroupUserAlreadyExists":
              setAddUsersErrorMessage(data.addGroupUsers.message);
              break;
            default:
              logError(new Error(`failed to add group users`));
              setAddUsersErrorMessage("Error: failed to add group users");
          }
        } catch (error) {
          logError(error, "failed to add group users");
          setAddUsersErrorMessage(
            getModifiedErrorMessage("Error: failed to add group users", error)
          );
        }
      }}
      loading={addUsersLoading}
      errorMessage={addUsersErrorMessage}
      secondaryButton={
        <GroupUsersUploadCSVButton
          onLoadUsers={(users) => {
            setUserInfosToAdd(
              users.map((user) => ({
                id: user.id,
                value: user.productRole,
              }))
            );
            setUsers(users);
          }}
          setErrorMessage={setAddUsersErrorMessage}
          groupUserIdsWithDirectAccess={groupUserIdsWithDirectAccess}
        />
      }
      updatedAccessDurationsByEntityId={accessDurationsByUserIdToAdd}
      setUpdatedAccessDurationsByEntityId={setAccessDurationsByUserIdToAdd}
      updatedRolesByEntityId={roleByUserIdToAdd}
      setUpdatedRolesByEntityId={setRoleByUserIdToAdd}
      availableRoles={availableRoles || undefined}
    />
  );

  const removeUsersModalReset = () => {
    setShowUsersRemoveModal(false);
    setRemoveUsersErrorMessage(null);
    setIdsToRemove([]);
    setSearchQuery("");
  };

  const removeUsersModal = (
    <SelectItemsModal
      key={"users_remove"}
      title={"Remove user access"}
      selectType={SelectType.Remove}
      itemName={"user"}
      entryInfos={groupUsersWithDirectAccess
        .slice()
        .sort((a, b) => {
          if (a && b) {
            const aSortString = a.user?.fullName || "";
            const bSortString = b.user?.fullName || "";
            return aSortString.localeCompare(bSortString);
          }
          return 0;
        })
        .filter((groupUser) => {
          if (!groupUserHasDirectAccess(groupUser)) return false;

          return (
            groupUser.user?.fullName
              .toLowerCase()
              .includes(searchQuery.toLocaleLowerCase()) ||
            groupUser.user?.email
              .toLowerCase()
              .includes(searchQuery.toLocaleLowerCase())
          );
        })
        .map((user) => {
          let rightSideContent: Maybe<JSX.Element> = null;
          if (user.source === GroupUserSource.OnCall) {
            rightSideContent = (
              <div>
                <Tooltip
                  tooltipText={`This user was added via an on-call binding.
                  To remove them, please remove the on-call binding.`}
                >
                  <Icon externalIconUrl={onCallGroupLogo} />
                </Tooltip>
              </div>
            );
          } else if (user.source === GroupUserSource.RegularNested) {
            rightSideContent = (
              <div className={sprinkles({ color: "gray400" })}>
                <Tooltip
                  tooltipText={`This user was added due to membership in a nested group
                    that grants access to this group.
                    To remove them, please remove them from the nested group.`}
                >
                  <Icon name="slash" size="sm" />
                </Tooltip>
              </div>
            );
          }

          return {
            entityId: { entityId: user.userId, entityType: EntityType.User },
            label: (
              <UserLabel
                name={user.user?.fullName}
                subText={user.user?.email}
                avatar={user.user?.avatarUrl}
                large
                bold
              />
            ),
            getRightSideElement: rightSideContent
              ? () => rightSideContent
              : undefined,
            clickHandler: () => {},
            isBold: false,
            isBreadcrumb: false,
          };
        })}
      idsToUpdate={idsToRemove}
      setIdsToUpdate={setIdsToRemove}
      isModalOpen={showUsersRemoveModal}
      onClose={removeUsersModalReset}
      onSubmit={async () => {
        logEvent({
          name: "apps_remove_user",
          properties: {
            type: "group",
            numUsers: idsToRemove.length,
          },
        });

        try {
          const { data } = await removeGroupUsers({
            variables: {
              input: {
                groupUsers: idsToRemove.map((id) => {
                  return {
                    groupId: props.group.id,
                    userId: id,
                  };
                }),
              },
            },
          });
          switch (data?.removeGroupUsers.__typename) {
            case "RemoveGroupUsersResult":
              startPushTaskPoll(data.removeGroupUsers.taskId, {
                refetchOnComplete: [{ groupId: props.group.id }],
              });
              removeUsersModalReset();
              break;
            case "CallToWebhookFailedError":
              setAddUsersErrorMessage(data.removeGroupUsers.message);
              break;
            default:
              logError(new Error(`failed to remove group users`));
              setRemoveUsersErrorMessage("Error: failed to remove group users");
          }
        } catch (error) {
          logError(error, "failed to remove group users");
          setRemoveUsersErrorMessage(
            getModifiedErrorMessage(
              "Error: failed to remove group users",
              error
            )
          );
        }
      }}
      loading={removeUsersLoading}
      errorMessage={removeUsersErrorMessage}
      submitDisabled={idsToRemove.length === 0}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    />
  );
  return (
    <EntityViewerRow
      title={"Users"}
      content={
        <EmptyStateContentWrapper
          content={usersTable}
          entityType={EntityType.User}
          title={`No users in group`}
          subtitle={`Add users to this group to populate the table`}
          buttonTitle={`Add users`}
          isEmpty={groupUsers.length === 0}
          onClickHandler={() => {
            setShowUsersAddModal(true);
          }}
        />
      }
      editor={editor}
      modals={
        <>
          {showUsersAddModal && addUsersModal}
          {showUsersRemoveModal && removeUsersModal}
        </>
      }
      isTable={true}
    />
  );
};

export default GroupUsersRow;
