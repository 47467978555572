import {
  AccessReviewGroupForDetailFragment,
  EntityType,
  ServiceType,
} from "api/generated/graphql";
import AdminRow from "components/forms/rows/AdminRow";
import AppRow from "components/forms/rows/AppRow";
import DescriptionRow from "components/forms/rows/DescriptionRow";
import SnapshotTimeRow from "components/forms/rows/SnapshotTimeRow";
import VisibilityRow from "components/forms/rows/VisibilityRow";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import { FormRow, Label } from "components/ui";
import sprinkles from "css/sprinkles.css";

interface Props {
  accessReviewGroup: AccessReviewGroupForDetailFragment;
}

const AccessReviewGroupOverview = ({ accessReviewGroup }: Props) => {
  const group = accessReviewGroup.group;
  if (!group) {
    return null;
  }

  return (
    <div className={sprinkles({ padding: "md" })}>
      <SnapshotTimeRow createdAt={accessReviewGroup.createdAt} />
      {group.serviceType !== ServiceType.Opal && (
        <>
          <FormRow title="Remote name">{group.remoteName || "--"}</FormRow>
          <FormRow title="Remote ID">{group.remoteId || "--"}</FormRow>
        </>
      )}
      <FormRow title="Resource type">
        <Label
          label={groupTypeInfoByType[group.groupType].name}
          icon={{ type: "entity", entityType: group.groupType }}
        />
      </FormRow>
      <AppRow connectionId={group.connectionId} mode="view" />
      {group.adminOwner && (
        <AdminRow owner={group.adminOwner} mode="view" onChange={() => {}} />
      )}
      <VisibilityRow
        entityType={EntityType.Group}
        visibility={group.visibility}
        visibilityGroups={group.visibilityGroups.map(
          (group) => group.visibilityGroupId
        )}
        mode="view"
        onChangeVisibilityAndGroups={() => {}}
      />
      <DescriptionRow
        value={group.description}
        mode="view"
        onChange={() => {}}
      />
    </div>
  );
};

export default AccessReviewGroupOverview;
