import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AuthType,
  AwsIdentityCenterImportSetting,
  ConnectionType,
  ImportSetting,
  Maybe,
  OidcProviderType,
  useCreateConnectionMutation,
  useOidcProviderQuery,
  Visibility,
} from "api/generated/graphql";
import awsLogo from "assets/logos/aws-logo.svg";
import AuthContext from "components/auth/AuthContext";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import {
  Banner,
  Button,
  FormGroup,
  Input,
  Select,
  Switch,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import AppContext from "views/app/AppContext";
import {
  CreateConnectionComponents as CreateConnectionComponentsV2,
  CreateConnectionView as CreateConnectionViewV2,
} from "views/connections/create/CreateConnectionComponents";
import {
  CreateConnectionComponentsV3,
  CreateConnectionViewV3,
} from "views/connections/create/CreateConnectionComponentsV3";
import VisibilitySelector from "views/visibility/VisibilitySelector";

import { AWSServicePrincipalOption } from "../../requests/utils";

const SSO_INSTANCE_ARN_REGEX = /^arn:(aws|aws-us-gov|aws-cn|aws-iso|aws-iso-b):sso:::instance\/(sso)?ins-[a-zA-Z0-9-.]{16}$/;
const AWS_ACCOUNT_REGEX = /^\d{12}$/;
const IDENTITY_STORE_ID_REGEX = /^d-[0-9a-f]{10}$|^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/;
const ACCESS_PORTAL_URL_REGEX = /^https:\/\/.+\.awsapps\.com\/start$/;
const AWS_REGION_REGEX = /[a-z-]*-[a-z]*-[a-z0-9]*/;
const CLOUDTRAIL_SQS_URL_REGEX = /^https:\/\/sqs\.[a-z0-9-]+\.amazonaws\.com\/\d+\/[a-zA-Z0-9_-]+$/;

const CreateAWSSSO = () => {
  return <CreateAWSSSOForm />;
};

const CreateAWSSSOForm = () => {
  const { authState } = useContext(AuthContext);
  const { appState } = useContext(AppContext);
  const orgId = authState.user?.user.organizationId;
  const history = useHistory();
  const hasAppLevelVisibility = useFeatureFlag(FeatureFlag.AppLevelVisibility);
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const hasEDS = useFeatureFlag(FeatureFlag.AwsEds);
  const logEvent = useLogEvent();

  const [credentialsErrors, setCredentialsErrors] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [visibility, setVisibility] = useState<Visibility>(Visibility.Global);
  const [visibilityGroupIds, setVisibilityGroupIds] = useState<
    string[] | undefined
  >([]);
  const [managementAccountId, setManagementAccountId] = useState("");
  const [ssoInstanceArn, setSsoInstanceArn] = useState("");
  const [identityStoreId, setIdentityStoreId] = useState("");
  const [accessPortalUrl, setAccessPortalUrl] = useState("");
  const [awsRegion, setAwsRegion] = useState("");
  const [accessKeyId, setAccessKeyId] = useState("");
  const [secretAccessKey, setSecretAccessKey] = useState("");
  const [awsOrganizationEnabled, setAwsOrganizationEnabled] = useState(false);
  const [awsServicePrincipalOption, setAwsServicePrincipalOption] = useState<
    AWSServicePrincipalOption | undefined
  >(undefined);
  const [awsSsoEnabled, setAwsSsoEnabled] = useState(false);
  const [cloudtrailEventsSqsUrl, setCloudtrailEventsSqsUrl] = useState("");

  const [adminOwnerId, setAdminOwnerId] = useState<string | undefined>(
    undefined
  );

  const [hasOidcProvider, setHasOidcProvider] = useState<boolean>(false);
  const { data } = useOidcProviderQuery({
    variables: {
      input: {
        oidcProviderType: OidcProviderType.AwsSession,
      },
    },
  });
  useEffect(() => {
    switch (data?.oidcProvider.__typename) {
      case "OidcProviderResult":
        setHasOidcProvider(true);
        setAwsOrganizationEnabled(true);
        break;
      default:
        setHasOidcProvider(false);
        setAwsOrganizationEnabled(false);
    }
  }, [data?.oidcProvider.__typename]);

  const [createConnectionMutation, { loading }] = useCreateConnectionMutation({
    refetchQueries: ["AppsListColumn", "Connections"],
  });
  const notFilled =
    (!awsSsoEnabled && !awsOrganizationEnabled) ||
    [name, description, managementAccountId].some((field) => field === "") ||
    (awsSsoEnabled &&
      [ssoInstanceArn, identityStoreId, accessPortalUrl, awsRegion].some(
        (field) => field === ""
      )) ||
    (appState.isOnPrem &&
      awsServicePrincipalOption ===
        AWSServicePrincipalOption.IamUserCredentials &&
      [accessKeyId, secretAccessKey].some((field) => field === "")) ||
    (appState.isOnPrem && !awsServicePrincipalOption) ||
    !adminOwnerId;

  const handleSubmit = async () => {
    const credentialsErrors: string[] = [];
    if (!managementAccountId.match(AWS_ACCOUNT_REGEX)) {
      credentialsErrors.push("AWS Management Account pattern is invalid");
    }
    if (
      cloudtrailEventsSqsUrl.length > 0 &&
      !cloudtrailEventsSqsUrl.match(CLOUDTRAIL_SQS_URL_REGEX)
    ) {
      credentialsErrors.push("SQS URL pattern is invalid");
    }
    if (awsSsoEnabled) {
      if (!ssoInstanceArn.match(SSO_INSTANCE_ARN_REGEX)) {
        credentialsErrors.push("SSO Instance ARN pattern is invalid");
      }
      if (!identityStoreId.match(IDENTITY_STORE_ID_REGEX)) {
        credentialsErrors.push("Identity Store ID pattern is invalid");
      }
      if (!accessPortalUrl.match(ACCESS_PORTAL_URL_REGEX)) {
        credentialsErrors.push(
          "Access portal URL pattern is invalid, should match: https://x.awsapps.com/start"
        );
      }
      if (!awsRegion.match(AWS_REGION_REGEX)) {
        credentialsErrors.push("AWS Region pattern is invalid");
      }
    } else {
      setSsoInstanceArn("");
      setIdentityStoreId("");
      setAccessPortalUrl("");
      setAwsRegion("");
    }
    setCredentialsErrors(credentialsErrors);
    if (credentialsErrors.length) {
      return;
    }
    try {
      const maybeAccessKeyId = accessKeyId || null;
      const maybeSecretAccessKey = secretAccessKey || null;
      const { data } = await createConnectionMutation({
        variables: {
          input: {
            name: name,
            description: description,
            connectionType: ConnectionType.AwsSso,
            adminOwnerId: adminOwnerId ?? "",
            visibility: visibility,
            visibilityGroupIds: visibilityGroupIds ?? [],
            importVisibility: Visibility.Global,
            metadata: {
              connectionType: ConnectionType.AwsSso,
              awsSso: {
                managementAccountId,
                ssoInstanceArn,
                identityStoreId,
                accessPortalUrl,
                awsRegion,
                awsOrganizationEnabled,
                awsSsoEnabled,
                awsIdentityCenterImportSetting:
                  AwsIdentityCenterImportSetting.Provisioned,
                awsOrganizationImportSetting: ImportSetting.Tagged,
                ec2RoleChainingEnabled:
                  awsServicePrincipalOption ===
                  AWSServicePrincipalOption.Ec2InstanceRole,
                podRoleChainingEnabled:
                  awsServicePrincipalOption ===
                  AWSServicePrincipalOption.PodRole,
                cloudtrailEventsSqsUrl,
              },
            },
            credentials: {
              authType: AuthType.AwsSso,
              awsSso: {
                accessKeyId:
                  awsServicePrincipalOption ===
                  AWSServicePrincipalOption.IamUserCredentials
                    ? maybeAccessKeyId
                    : null,
                secretAccessKey:
                  awsServicePrincipalOption ===
                  AWSServicePrincipalOption.IamUserCredentials
                    ? maybeSecretAccessKey
                    : null,
              },
            },
          },
        },
      });
      switch (data?.createConnection.__typename) {
        case "CreateConnectionResult":
          history.replace(
            getResourceUrl(
              EntityTypeDeprecated.Connection,
              data.createConnection.connection.id
            )
          );
          displaySuccessToast(`Success: AWS app created`);
          logEvent({
            name: "apps_create_click",
            properties: {
              connectionType: ConnectionType.AwsSso,
            },
          });

          break;
        case "ConnectionExistsError":
        case "UserFacingError":
          logWarning(new Error(data.createConnection.message));
          setErrorMessage(data.createConnection.message);
          break;
        default:
          logError(new Error(`app creation failed`));
          setErrorMessage(`Error: app creation failed`);
      }
    } catch (error) {
      logError(error, "app creation failed");
      setErrorMessage(
        getModifiedErrorMessage("Error: app creation failed", error)
      );
    }
  };

  let opalServicePrincipalOptions: [AWSServicePrincipalOption] = [
    AWSServicePrincipalOption.IamUserCredentials,
  ];
  if (appState.isOnPrem) {
    opalServicePrincipalOptions.unshift(
      AWSServicePrincipalOption.Ec2InstanceRole,
      AWSServicePrincipalOption.PodRole
    );
  }

  const CreateConnectionComponents = hasV3
    ? CreateConnectionComponentsV3
    : CreateConnectionComponentsV2;
  const CreateConnectionView = hasV3
    ? CreateConnectionViewV3
    : CreateConnectionViewV2;

  return (
    <CreateConnectionView
      title={"Connect your AWS infrastructure"}
      logo={awsLogo}
      onSubmit={handleSubmit}
      submitDisabled={notFilled}
      submitLoading={loading}
    >
      <>
        <CreateConnectionComponents
          title={"Step 1"}
          subtitle={"Configure your AWS infrastructure for Opal to connect"}
        >
          <>
            <p>
              Before creating the app, you must set up your AWS IAM
              infrastructure to give Opal necessary access. Setup instructions
              can be found{" "}
              <a
                href="https://docs.opal.dev/docs/setting-up-your-aws-organization-in-opal"
                target="_blank"
              >
                <b>here</b>
              </a>
              .
            </p>
            <FormGroup label="Your External ID">
              <Input disabled={true} onChange={() => {}} value={orgId} />
            </FormGroup>
          </>
        </CreateConnectionComponents>

        <CreateConnectionComponents
          title={"Step 2"}
          subtitle={"Configure your AWS app"}
        >
          <>
            {appState.isOnPrem && (
              <FormGroup label="Opal Service Principal">
                <Select
                  options={opalServicePrincipalOptions}
                  value={awsServicePrincipalOption}
                  getOptionLabel={(option) => option}
                  onChange={(value) => setAwsServicePrincipalOption(value)}
                  placeholder={"Select option"}
                />
              </FormGroup>
            )}
            {appState.isOnPrem &&
              awsServicePrincipalOption ==
                AWSServicePrincipalOption.IamUserCredentials && (
                <>
                  <FormGroup label="User access key ID">
                    <Input
                      onChange={setAccessKeyId}
                      placeholder="The access key ID associated with the created IAM user."
                      value={accessKeyId}
                    />
                  </FormGroup>
                  <FormGroup label="User secret access key">
                    <Input
                      onChange={setSecretAccessKey}
                      placeholder="The access key secret associated with the created IAM user."
                      value={secretAccessKey}
                      type="password"
                    />
                  </FormGroup>
                </>
              )}
            <FormGroup label="AWS Management or Delegated Administrator Account ID">
              <Input
                onChange={setManagementAccountId}
                placeholder="Identifier of the AWS organization's management or delegated administrator account"
                value={managementAccountId}
              />
            </FormGroup>
            {hasEDS && (
              <FormGroup label="CloudTrail events SQS queue URL">
                <Input
                  onChange={setCloudtrailEventsSqsUrl}
                  placeholder="URL of the SQS queue that is notified when new CloudTrail events are created"
                  value={cloudtrailEventsSqsUrl}
                />
              </FormGroup>
            )}
            {!hasOidcProvider && (
              <Banner
                type="info"
                message={
                  <>
                    Opal uses OpenID Connect (OIDC) to authenticate your users
                    when granting access to your AWS Organization. Please
                    configure your OIDC provider in your{" "}
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href="/settings#aws-settings"
                    >
                      organization settings
                    </a>{" "}
                    to enable AWS Organizations management.
                  </>
                }
              />
            )}
            <div
              className={sprinkles({
                padding: "sm",
              })}
            >
              <Switch
                label="Enable AWS Organizations management"
                disabled={!hasOidcProvider}
                checked={hasOidcProvider && awsOrganizationEnabled}
                onChange={() =>
                  setAwsOrganizationEnabled(!awsOrganizationEnabled)
                }
                infoTooltip="Toggles management of IAM Roles, EC2 instances, EKS Clusters, and RDS Databases."
              />
            </div>
            <div
              className={sprinkles({
                padding: "sm",
              })}
            >
              <Switch
                label="Enable IAM Identity Center management"
                checked={awsSsoEnabled}
                onChange={() => setAwsSsoEnabled(!awsSsoEnabled)}
                infoTooltip="Toggles management of AWS IAM Identity Center groups and permission sets in Opal."
              />
            </div>
            {awsSsoEnabled && (
              <>
                <FormGroup label="AWS IAM Identity Center Region">
                  <Input
                    onChange={setAwsRegion}
                    placeholder="AWS Region String (i.e. us-east-2)"
                    value={awsRegion}
                  />
                </FormGroup>
                <FormGroup label="AWS IAM Identity Center Instance ARN">
                  <Input
                    onChange={setSsoInstanceArn}
                    placeholder="ARN of the IAM Identity Center Instance"
                    value={ssoInstanceArn}
                  />
                </FormGroup>
                <FormGroup label="AWS Identity Store ID">
                  <Input
                    onChange={setIdentityStoreId}
                    placeholder="ID of the AWS Identity Store instance"
                    value={identityStoreId}
                  />
                </FormGroup>
                <FormGroup label="AWS Access Portal URL">
                  <Input
                    onChange={setAccessPortalUrl}
                    placeholder="Access portal URL for the IAM Identity Center instance"
                    value={accessPortalUrl}
                  />
                </FormGroup>
              </>
            )}
            {credentialsErrors.length > 0 ? (
              <>
                {credentialsErrors.map((err) => (
                  <ModalErrorMessage errorMessage={err} />
                ))}
              </>
            ) : null}
          </>
        </CreateConnectionComponents>

        <CreateConnectionComponents
          title={"Step 3"}
          subtitle={"Customize your app"}
          isLast
        >
          <>
            <FormGroup label="App name">
              <Input
                onChange={setName}
                placeholder="Identifiable name of the AWS app"
                value={name}
              />
            </FormGroup>
            <FormGroup label="Description">
              <Input
                onChange={setDescription}
                placeholder="A brief description of this app"
                value={description}
              />
            </FormGroup>
            <FormGroup label="App owner">
              <OwnerDropdown
                selectedOwnerId={adminOwnerId}
                onSelectOwner={(owner) => setAdminOwnerId(owner?.id)}
                placeholder="Select an owner to own this app"
              />
            </FormGroup>
            {hasAppLevelVisibility && (
              <FormGroup label="Visibility">
                <VisibilitySelector
                  visibility={visibility}
                  onChangeVisibility={(vis) => {
                    if (vis == Visibility.Global) {
                      setVisibility(Visibility.Global);
                      setVisibilityGroupIds([]);
                    } else {
                      setVisibility(Visibility.Team);
                    }
                  }}
                  visibilityGroups={visibilityGroupIds ?? []}
                  onChangeVisibilityGroups={setVisibilityGroupIds}
                />
              </FormGroup>
            )}
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
            {!hasV3 && (
              <div
                className={sprinkles({
                  display: "flex",
                  justifyContent: "flex-end",
                })}
              >
                <Button
                  type="primary"
                  disabled={notFilled}
                  label={"Create"}
                  loading={loading}
                  onClick={handleSubmit}
                />
              </div>
            )}
          </>
        </CreateConnectionComponents>
      </>
    </CreateConnectionView>
  );
};

export default CreateAWSSSO;
