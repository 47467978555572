import {
  AccessReviewConnectionFragment,
  EntityType,
} from "api/generated/graphql";
import { EntityViewerTitle } from "components/entity_viewer/EntityViewer";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { ResourceLabel } from "components/label/Label";
import { Breadcrumbs } from "components/ui";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import {
  getDeletedIcon,
  getDeletedTooltip,
} from "views/access_reviews/common/Deleted";

type AccessReviewConnectionTitleRowProps = {
  accessReviewConnection: AccessReviewConnectionFragment;
};

export const AccessReviewConnectionTitleRow = (
  props: AccessReviewConnectionTitleRowProps
) => {
  if (!props.accessReviewConnection.connection) {
    return <></>;
  }

  let entityId;
  let warningTooltip;
  let warningIcon;
  let strikeThrough = false;
  if (props.accessReviewConnection.connection.isDeleted) {
    warningTooltip = getDeletedTooltip("connection");
    warningIcon = getDeletedIcon();
    strikeThrough = true;
  } else {
    entityId = props.accessReviewConnection.connection.id;
  }

  return (
    <EntityViewerTitle
      title={
        <ResourceLabel
          text={props.accessReviewConnection.connection?.name}
          entityType={EntityTypeDeprecated.Connection}
          entityId={entityId}
          warningTooltipText={warningTooltip}
          warningIcon={warningIcon}
          strikeThrough={strikeThrough}
          icon={
            getConnectionTypeInfo(
              props.accessReviewConnection.connection?.connectionType
            )?.icon
          }
          iconLarge={true}
        />
      }
      subtitle={
        <Breadcrumbs
          breadcrumbInfos={[
            {
              name: "Access Reviews",
              entityId: {
                entityId: null,
                entityType: null,
              },
            },
            {
              name:
                props.accessReviewConnection.accessReview?.name ||
                "Access Review",
              entityId: {
                entityId: props.accessReviewConnection.accessReview?.id ?? null,
                entityType: EntityType.AccessReview,
              },
            },
          ]}
        />
      }
    />
  );
};

export default AccessReviewConnectionTitleRow;
