import {
  AccessLevel,
  EntityType,
  GroupType,
  RecommendationsSubscoreType,
  ResourceType,
  RoleAssignmentsInput,
  RoleAssignmentsSortBy,
  RoleAssignmentsSortByField,
  RoleAssignmentWithSuggestionFragment,
  useRoleAssignmentsWithSuggestionsQuery,
} from "api/generated/graphql";
import { allPrincipalTypes } from "components/dropdown/PrincipalTypeDropdown";
import PrincipalTypeDropdown from "components/dropdown/PrincipalTypeDropdown";
import RiskFactorDropdown from "components/dropdown/RiskFactorDropdown";
import OpalSearchInput from "components/opal/common/input/OpalSearchInput";
import OpalTable, {
  Columns,
  useServerSortableOpalTable,
} from "components/opal/table/OpalTable";
import moment from "moment";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import EntityCell, {
  EntityCellDetails,
} from "views/access_reviews/common/EntityCell";
import PrincipalCell, {
  PrincipalCellDetails,
} from "views/access_reviews/common/PrincipalCell";
import { ScopeFilter } from "views/recommendations/resolutions/CustomRemediationScope";
import ThreatIcon from "views/recommendations/ThreatIcon";
const defaultSuggestionInMinutes = 7 * 24 * 60;
const pageSize = 50;
interface CustomRemediationsTableProps {
  scopeFilter: ScopeFilter;
  setScopeFilter: Dispatch<SetStateAction<ScopeFilter>>;
}

function generateQueryInput(
  scopeFilter: ScopeFilter,
  cursor: string | null,
  sortByVariable: RoleAssignmentsSortBy | undefined
): RoleAssignmentsInput {
  let groupIds: string[] = [];
  let resourceIds: string[] = [];
  scopeFilter.entities?.map((entity) => {
    if (entity.entityType == EntityType.Group) {
      groupIds.push(entity.id.toString());
    } else if (entity.entityType == EntityType.Resource) {
      resourceIds.push(entity.id.toString());
    }
  });

  let resourceTypes: ResourceType[] = [];
  let groupTypes: GroupType[] = [];
  scopeFilter.entityTypes?.map((entityType) => {
    if (Object.values(ResourceType).includes(entityType as ResourceType)) {
      resourceTypes.push(entityType as ResourceType);
    } else {
      groupTypes.push(entityType as GroupType);
    }
  });
  return {
    scope: {
      entityScope: {
        groupIDs: groupIds.length === 0 ? null : groupIds,
        resourceIDs: resourceIds.length === 0 ? null : resourceIds,
      },
      entityTypeScope: {
        resourceTypes: resourceTypes,
        groupTypes: groupTypes,
      },
      riskFactors: scopeFilter.riskFactors,
      principalTypes: scopeFilter.principalTypes ?? allPrincipalTypes,
    },
    first: pageSize,
    after: cursor,
    sortBy: sortByVariable,
    searchQuery: scopeFilter.searchQuery,
  };
}

type RoleAssignmentRow = {
  id: String;
  principal: PrincipalCellDetails;
  entity: EntityCellDetails;
  lastUsedAt: String | null;
  canHaveUsageData: boolean;
  accessLevel: AccessLevel | null;
  riskFactors: RecommendationsSubscoreType[] | null;
  durationInMinutes: number | null;
  expiresAt: String | null;
};

function RoleAssignmentsToRoleAssignmentRows(
  roleAssignments: RoleAssignmentWithSuggestionFragment[]
): RoleAssignmentRow[] {
  return roleAssignments.map((roleAssignment) => {
    const principal: PrincipalCellDetails = {
      id: roleAssignment.principal?.id.toString() ?? "",
      type:
        roleAssignment.principal?.__typename == "Group"
          ? EntityType.Group
          : EntityType.User,
      name: roleAssignment.principal?.name ?? "",
    };

    if (roleAssignment.principal?.__typename == "User") {
      principal.position = roleAssignment.principal?.position;
      principal.iconUrl = roleAssignment.principal.avatarUrl;
    } else if (roleAssignment.principal?.__typename == "Group") {
      principal.groupType = roleAssignment.principal?.groupType;
    }
    const entity: EntityCellDetails = {
      id: roleAssignment.entity?.id?.toString() ?? "",
      type:
        roleAssignment.entity?.__typename === "Resource"
          ? EntityType.Resource
          : EntityType.Group,
      name: roleAssignment.entity?.name || "",
      iconUrl: roleAssignment.entity?.iconUrl || undefined,
      connectionType: roleAssignment.entity?.connection?.connectionType,
      ...(roleAssignment.entity?.__typename === "Resource" && {
        resourceType: roleAssignment.entity.resourceType,
      }),
      ...(roleAssignment.entity?.__typename === "Group" && {
        groupType: roleAssignment.entity.groupType,
      }),
    };

    var expiresAt = null;
    if (roleAssignment.access != null) {
      expiresAt = roleAssignment.access.directAccessPoint;
    }

    const roleAssignmentRow: RoleAssignmentRow = {
      id: roleAssignment.id,
      principal: principal,
      entity: entity,
      lastUsedAt: roleAssignment.lastUsedAt ?? null,
      riskFactors: roleAssignment.suggestion?.riskFactors || [],
      accessLevel: roleAssignment.accessLevel ?? null,
      canHaveUsageData: roleAssignment.entity?.canHaveUsageData ?? false,
      durationInMinutes: defaultSuggestionInMinutes,
      expiresAt: expiresAt?.expiration ?? null,
    };

    return roleAssignmentRow;
  });
}

const columns: Columns<RoleAssignmentRow, RoleAssignmentsSortByField> = [
  {
    id: RoleAssignmentsSortByField.PrincipalName,
    label: "Principal",
    customCellRenderer: ({ principal }) => {
      return <PrincipalCell principal={principal} hideAvatar openInNewTab />;
    },
  },
  {
    id: RoleAssignmentsSortByField.EntityName,
    label: "Has Access To",
    customCellRenderer: (row) => {
      return <EntityCell entity={row.entity} openInNewTab />;
    },
  },
  {
    id: "lastUsedAt",
    label: "Last Used",
    sortable: false,
    customCellRenderer: (row) => {
      if (!row.canHaveUsageData) {
        return "---";
      } else if (row.canHaveUsageData && row.lastUsedAt == null) {
        return "Never";
      } else {
        return moment(String(row.lastUsedAt)).fromNow();
      }
    },
  },
  {
    id: RoleAssignmentsSortByField.Role,
    label: "Role",
    customCellRenderer: (row) => {
      return <>{row.accessLevel?.accessLevelName || "---"}</>;
    },
  },
  {
    id: "expiresAt",
    label: "Expiration",
    width: 75,
    sortable: false,
    customCellRenderer: (row) => {
      const label = row.expiresAt
        ? moment(row.expiresAt.toString()).fromNow()
        : "Never";
      return <>{label}</>;
    },
  },
  {
    id: "riskFactors",
    label: "Risk Factors",
    width: 100,
    sortable: false,
    customCellRenderer: (row) => {
      return row.riskFactors?.length ? (
        <div style={{ display: "flex", gap: "8px", flexWrap: "wrap" }}>
          {[...row.riskFactors]
            .sort((a, b) => a.localeCompare(b))
            .map((subscoreType) => (
              <ThreatIcon
                key={subscoreType}
                type={subscoreType}
                popoverDirection="bottom"
              />
            ))}
        </div>
      ) : (
        ""
      );
    },
  },
];

const CustomRemediationsTable = ({
  scopeFilter,
  setScopeFilter,
}: CustomRemediationsTableProps) => {
  const [roleAssignmentRows, setRoleAssignmentRows] = useState<
    RoleAssignmentRow[]
  >([]);
  const {
    sortByVariable,
    sortByTableProps,
  } = useServerSortableOpalTable<RoleAssignmentsSortByField>({
    id: RoleAssignmentsSortByField.PrincipalName,
    desc: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [cursor, setCursor] = useState<string | null>(null);

  const {
    data,
    fetchMore,
    networkStatus,
  } = useRoleAssignmentsWithSuggestionsQuery({
    variables: {
      input: generateQueryInput(scopeFilter, null, sortByVariable),
    },
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (data?.roleAssignments.roleAssignments) {
      const rows = RoleAssignmentsToRoleAssignmentRows(
        data.roleAssignments.roleAssignments
      );
      setRoleAssignmentRows(rows);
      setCursor(data.roleAssignments.pageInfo.endCursor ?? null);
    }
  }, [data]);

  const handleFetchMore = async () => {
    if (!cursor || isLoading) return;
    setIsLoading(true); // Prevent race condition fetchMore calls
    const result = await fetchMore({
      variables: {
        input: generateQueryInput(scopeFilter, cursor, sortByVariable),
      },
    });

    if (result.data?.roleAssignments.roleAssignments) {
      const newRows = RoleAssignmentsToRoleAssignmentRows(
        result.data.roleAssignments.roleAssignments
      );
      setRoleAssignmentRows([...roleAssignmentRows, ...newRows]);
      setCursor(result.data.roleAssignments.pageInfo.endCursor ?? null);
    }
    setIsLoading(false);
  };
  let totalCount = data?.roleAssignments.totalCount ?? 0;
  return (
    <>
      <OpalTable
        entityName="Direct Access Grant"
        networkStatus={networkStatus}
        rows={roleAssignmentRows}
        columns={columns}
        actions={null}
        filters={[
          <>
            <OpalSearchInput
              placeholder="Search by name"
              defaultValue={scopeFilter.searchQuery ?? undefined}
              onChange={(e) =>
                setScopeFilter((prevFilter: ScopeFilter) => ({
                  ...prevFilter,
                  searchQuery: e.target.value,
                }))
              }
            />
            <RiskFactorDropdown
              riskFactors={scopeFilter.riskFactors}
              setRiskFactorFilter={(newRiskFactorFilter) => {
                setScopeFilter((prevFilter: ScopeFilter) => ({
                  ...prevFilter,
                  riskFactors: newRiskFactorFilter,
                }));
              }}
              defaultLabel="Risk Factors"
            />
            <PrincipalTypeDropdown
              principalTypes={scopeFilter.principalTypes}
              setPrincipalTypesFilter={(newPrincipalTypeFilter) => {
                setScopeFilter((prevFilter) => ({
                  ...prevFilter,
                  principalTypes: newPrincipalTypeFilter,
                }));
              }}
              defaultLabel="Principal Types"
            />
          </>,
        ]}
        totalNumRows={totalCount}
        {...sortByTableProps}
        getRowId={(row) => {
          return (
            row.entity.id.toString() +
            row.principal.id.toString() +
            row.accessLevel?.accessLevelRemoteId
          );
        }}
        onLoadMoreRows={handleFetchMore}
      />
    </>
  );
};

export default CustomRemediationsTable;
