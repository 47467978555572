import {
  AccessReviewResourceFragment,
  AccessReviewTab,
  EntityType,
  useOngoingAccessReviewSubtabStatsQuery,
} from "api/generated/graphql";
import EntityViewer from "components/entity_viewer/EntityViewer";
import { EntityViewerTabType } from "components/entity_viewer/EntityViewerTabs";
import { useContext, useState } from "react";
import { logError } from "utils/logging";
import { useReadUINotification } from "utils/notifications";
import AccessReviewContext from "views/access_reviews/AccessReviewContext";
import AccessReviewResourceActionButtons from "views/access_reviews/resources/AccessReviewResourceActionButtons";
import AccessReviewResourceEventsRow from "views/access_reviews/resources/AccessReviewResourceEventsRow";
import { AccessReviewResourceOverviewRow } from "views/access_reviews/resources/AccessReviewResourceOverviewRow";
import AccessReviewResourceTitleRow from "views/access_reviews/resources/AccessReviewResourceTitleRow";
import AccessReviewResourceUsersRow from "views/access_reviews/resources/AccessReviewResourceUsersRow";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import {
  getAccessReviewBadgeColor,
  getDeadlineProgressBarInfo,
} from "../common/utils";

type AccessReviewResourceViewerProps = {
  accessReviewResource: AccessReviewResourceFragment;
};

export const AccessReviewResourceViewer = (
  props: AccessReviewResourceViewerProps
) => {
  useReadUINotification(props.accessReviewResource.accessReviewId);
  const { accessReviewState } = useContext(AccessReviewContext);
  const [selectedTab, setSelectedTab] = useState<EntityViewerTabType>(
    EntityViewerTabType.Users
  );

  // bulk assign
  const [showBulkUpdateColumn, setShowBulkUpdateColumn] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Record<string, EntityType>>(
    {}
  );

  const { data, error } = useOngoingAccessReviewSubtabStatsQuery({
    variables: {
      input: {
        tab: AccessReviewTab.ResourceTab,
        itemId: props.accessReviewResource.id,
        accessReviewId: props.accessReviewResource.accessReview?.id ?? "",
      },
    },
  });

  let tabStats = null;
  if (data) {
    switch (data.ongoingAccessReviewSubtabStats.__typename) {
      case "OngoingAccessReviewSubtabStatsResult":
        tabStats =
          data.ongoingAccessReviewSubtabStats.ongoingAccessReviewSubtabStats ||
          null;
        break;
      case "AccessReviewNotFoundError":
      case "AccessReviewAlreadyStoppedError":
        break;
      default:
        logError(new Error(`failed to retrieve access review stats`));
    }
  } else if (error) {
    logError(error, `failed to retrieve access review stats`);
  }

  if (!props.accessReviewResource.resource) {
    return <UnexpectedErrorPage />;
  }

  const deadlineInfo = getDeadlineProgressBarInfo(
    props.accessReviewResource.accessReview
  );
  const badgeColor = getAccessReviewBadgeColor(deadlineInfo);

  return (
    <EntityViewer
      title={
        <AccessReviewResourceTitleRow
          accessReviewResource={props.accessReviewResource}
        />
      }
      tabInfos={[
        {
          tabType: EntityViewerTabType.Overview,
          tabContent: (
            <AccessReviewResourceOverviewRow
              resource={props.accessReviewResource.resource}
              createdAt={props.accessReviewResource.createdAt}
            />
          ),
        },
        {
          tabType: EntityViewerTabType.Users,
          tabContent: (
            <AccessReviewResourceUsersRow
              accessReviewResource={props.accessReviewResource}
              selectedRows={showBulkUpdateColumn ? selectedRows : undefined}
              setSelectedRows={
                showBulkUpdateColumn ? setSelectedRows : undefined
              }
            />
          ),
          tabCount:
            tabStats && tabStats.numUsersToReview > 0
              ? tabStats.numUsersToReview
              : undefined,
          badgeColor,
        },
        {
          tabType: EntityViewerTabType.Events,
          tabContent: (
            <AccessReviewResourceEventsRow
              accessReviewResource={props.accessReviewResource}
            />
          ),
        },
      ]}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      button={
        accessReviewState.ongoingAccessReviewIdSet.has(
          props.accessReviewResource.accessReviewId
        ) ? (
          <AccessReviewResourceActionButtons
            accessReviewResource={props.accessReviewResource}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            showBulkUpdateColumn={showBulkUpdateColumn}
            setShowBulkUpdateColumn={setShowBulkUpdateColumn}
          />
        ) : (
          <></>
        )
      }
    />
  );
};

export default AccessReviewResourceViewer;
