import {
  ConnectionPreviewLargeFragment,
  EntityType,
  FiltersInput,
  SortDirection,
  useAccessReviewFilteredConnectionsQuery,
} from "api/generated/graphql";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { ResourceLabel } from "components/label/Label";
import { useToast } from "components/toast/Toast";
import React, { useEffect, useState } from "react";
import { AccessReviewItemPreviewModal } from "views/access_reviews/settings/scope/AccessReviewItemPreviewModal";

interface Props {
  onClose: () => void;
  filters: FiltersInput;
}

export const AccessReviewConnectionsPreviewModal: React.FC<Props> = (props) => {
  const { onClose } = props;
  const [sort, setSort] = useState(SortDirection.Asc);

  const { data, error, loading } = useAccessReviewFilteredConnectionsQuery({
    variables: {
      input: {
        filters: props.filters,
      },
    },
  });
  const { displayErrorToast } = useToast();
  useEffect(() => {
    if (error) {
      displayErrorToast(
        "An error occurred while loading the connections list."
      );
      onClose();
    }
  }, [onClose, error, displayErrorToast]);

  const connections: ConnectionPreviewLargeFragment[] =
    data?.filteredConnections.connections ?? [];

  const items = connections
    .map((c) => {
      return {
        id: c.id,
        value: c.name,
        rendered: (
          <ResourceLabel
            text={c.name}
            subText={getConnectionTypeInfo(c.connectionType)?.name}
            entityId={c.id}
            entityTypeNew={EntityType.Connection}
            icon={getConnectionTypeInfo(c.connectionType)?.icon}
            bold
            iconLarge
            target="_blank"
          />
        ),
        admin: c.adminOwner ?? undefined,
      };
    })
    .sort((a, b) =>
      sort === SortDirection.Desc
        ? b.value.localeCompare(a.value)
        : a.value.localeCompare(b.value)
    );

  return (
    <AccessReviewItemPreviewModal
      itemType="app"
      onClose={onClose}
      items={items}
      loading={loading}
      onSortDirectionChange={setSort}
      sortDirection={sort}
      loadMoreRows={() => new Promise(() => {})}
      totalNumRows={items.length}
    />
  );
};
