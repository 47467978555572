import { formatDuration } from "components/label/Label";
import { FormRow, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import { useContext, useState } from "react";
import { AppsContext } from "views/apps/AppsContext";

import {
  MessageChannelFragment,
  OwnerUserFragment,
} from "../../../api/generated/graphql";
import { EscalationPolicyForm } from "../../../views/owners/owner_viewer/OwnerEscalationPolicy";
import ReviewerEscalationFlowModal from "../../modals/ReviewerEscalationFlowModal";
import { FormMode } from "../common";

interface Props {
  mode: FormMode;
  linkedMessageChannel: MessageChannelFragment | null;
  escalationDurationMin?: number | null;
  ownerUsers: OwnerUserFragment[];
  onChange: (escalationMin: number | undefined | null) => void;
}

const EscalationDurationPolicyRow = (props: Props) => {
  const { bulkMode } = useContext(AppsContext);
  const [showEscalationFlowModal, setShowEscalationFlowModal] = useState(false);
  const escalationVisualizationContent = (
    <>
      <div
        className={sprinkles({
          cursor: "pointer",
          textDecoration: "underline",
        })}
        onClick={() => setShowEscalationFlowModal(true)}
      >
        See who will be notified
      </div>
      {showEscalationFlowModal && (
        <ReviewerEscalationFlowModal
          accessRequestEscalationPeriodInMinutes={props.escalationDurationMin}
          reviewers={props.ownerUsers.map((user) => ({
            id: user.user?.id,
            priority: user.reviewerPriority,
            fullName: user.user?.fullName,
            avatarUrl: user.user?.avatarUrl,
          }))}
          reviewerMessageChannel={props.linkedMessageChannel}
          showModal={showEscalationFlowModal}
          setShowModal={setShowEscalationFlowModal}
        />
      )}
    </>
  );

  let durationString;
  if (props.linkedMessageChannel && !props.escalationDurationMin) {
    durationString = "Notify only Slack channel";
  } else if (props.escalationDurationMin != null) {
    durationString = formatDuration(
      moment.duration(props.escalationDurationMin, "m")
    );
  } else {
    durationString = "Notify all reviewers";
  }
  const viewContent = (
    <div className={sprinkles({ display: "flex", gap: "sm" })}>
      {durationString}
      <div>{`—`}</div>
      {escalationVisualizationContent}
    </div>
  );

  const editContent = (
    <>
      {bulkMode && (
        <div className={sprinkles({ marginBottom: "md" })}>
          <Switch
            label="Leave unchanged"
            checked={props.escalationDurationMin === undefined}
            onChange={(val) => props.onChange(val ? undefined : null)}
            rightAlign
          />
        </div>
      )}
      <EscalationPolicyForm
        accessRequestEscalationPeriodInMinutes={props.escalationDurationMin}
        onChange={props.onChange}
      />
    </>
  );
  return (
    <FormRow title="Reviewer escalation policy">
      {props.mode === "view" ? viewContent : editContent}
    </FormRow>
  );
};

export default EscalationDurationPolicyRow;
