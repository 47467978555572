import { ColumnContainer } from "components/column/Column";

import UserSettingsColumn from "./UserSettingsColumn";

export enum UserSettingsCategory {
  AccountSettings,
  NotificationPreferences,
  IdentitySettings,
}

export type UserSettingsCategoryInfo = {
  category: UserSettingsCategory;
  sideBarName: string;
  titleName: string;
  anchor: string;
};

export const categoryInfos: UserSettingsCategoryInfo[] = [
  {
    category: UserSettingsCategory.AccountSettings,
    sideBarName: "Account Settings",
    titleName: "Account Settings",
    anchor: "account-settings",
  },
  {
    category: UserSettingsCategory.NotificationPreferences,
    sideBarName: "Notification Preferences",
    titleName: "Notification Preferences",
    anchor: "notification-preferences",
  },
  {
    category: UserSettingsCategory.IdentitySettings,
    sideBarName: "Identities",
    titleName: "Identities",
    anchor: "identities",
  },
];

const categoryInfoByCategory: Record<number, UserSettingsCategoryInfo> = {};
categoryInfos.forEach((categoryInfo) => {
  categoryInfoByCategory[categoryInfo.category] = categoryInfo;
});

const UserSettings = () => {
  return (
    <ColumnContainer>
      <UserSettingsColumn />
    </ColumnContainer>
  );
};

export default UserSettings;
