import { getModifiedErrorMessage } from "api/ApiContext";
import {
  EntityType,
  OwnersListDocument,
  useCreateOwnerMutation,
} from "api/generated/graphql";
import GroupSearchDropdown from "components/dropdown/GroupSearchDropdown";
import { PaginatedUserDropdown } from "components/dropdown/PaginatedUserDropdown";
import { OwnerConfig } from "components/forms/common";
import { validateOwnerConfig } from "components/forms/utils";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import {
  DataElement,
  FormGroup,
  Input,
  List,
  Modal,
  Switch,
} from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";

interface User {
  id: string;
  fullName: string;
  avatarUrl: string;
}

interface ModalProps {
  onClose: () => void;
}

const OwnerCreateModal = (props: ModalProps) => {
  const [errors, setErrors] = useState<string[]>([]);
  const [createOwnerMutation, { loading }] = useCreateOwnerMutation();

  const [users, setUsers] = useState<User[]>([]);
  const [config, setConfig] = useState<Partial<OwnerConfig>>({});
  const handleChange = (key: keyof OwnerConfig) => (
    val: OwnerConfig[keyof OwnerConfig]
  ) => {
    setConfig((prevConfig) => ({ ...prevConfig, [key]: val }));
  };

  const history = useHistory();
  const { displaySuccessToast } = useToast();

  const handleClose = () => {
    setErrors([]);
    setConfig({});
    props.onClose();
  };

  const handleSubmit = async () => {
    const errors = validateOwnerConfig(config);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    try {
      const { data } = await createOwnerMutation({
        variables: {
          input: {
            // Owner name is validated in validateOwnerConfig
            name: config.name ?? "",
            description: config.description || "",
            sourceGroupId: config.enabledSourceGroup
              ? config.sourceGroup?.id
              : undefined,
            setUserIds: !config.enabledSourceGroup
              ? users.map((user) => user.id)
              : [],
          },
        },
        refetchQueries: [
          "OwnersList",
          "OwnersTable", // Refetch the main owners list query on the current page
          {
            query: OwnersListDocument,
            variables: {
              input: {
                includeAll: true,
              },
            },
          }, // Refetch any cached owners dropdown queries
        ],
        update: (cache, { data }) => {
          switch (data?.createOwner.__typename) {
            case "CreateOwnerResult":
              cache.evict({ fieldName: "owners" });
              break;
          }
        },
      });
      switch (data?.createOwner.__typename) {
        case "CreateOwnerResult":
          displaySuccessToast(
            `Success: "${data.createOwner.owner.name}" owner created`
          );
          handleClose();
          history.push(`/owners/${data.createOwner.owner.id}`);
          break;
        case "OwnerNameAlreadyExists":
          setErrors([data.createOwner.message]);
          break;
        default:
          logError(new Error(`failed to create owner`));
          setErrors([`Error: failed to create owner`]);
      }
    } catch (error) {
      logError(error, "failed to create owner");
      setErrors([
        getModifiedErrorMessage("Error: failed to create owner", error),
      ]);
    }
  };

  return (
    <Modal isOpen onClose={handleClose} title="Create Owner">
      <Modal.Body>
        {errors.map((error) => (
          <ModalErrorMessage errorMessage={error} />
        ))}
        <FormGroup label="Name:">
          <Input
            value={config.name}
            onChange={handleChange("name")}
            placeholder="Enter Owner Name"
          />
        </FormGroup>
        <FormGroup label="Description:">
          <Input
            value={config.description}
            onChange={handleChange("description")}
            type="textarea"
            placeholder="Enter Owner Description"
          />
        </FormGroup>
        <div className={sprinkles({ paddingBottom: "sm" })}>
          <Switch
            checked={Boolean(config.enabledSourceGroup)}
            onChange={(checked: boolean) => {
              if (!checked) {
                handleChange("sourceGroup")(undefined);
              }
              handleChange("enabledSourceGroup")(checked);
            }}
            label="Enable syncing user list from selected group"
          />
        </div>
        {config.enabledSourceGroup ? (
          <>
            <FormGroup label="Source group:">
              {config.sourceGroup ? (
                <DataElement
                  label={config.sourceGroup?.name}
                  color="teal"
                  leftIcon={
                    config.sourceGroup
                      ? {
                          data: {
                            entityType: config.sourceGroup?.groupType,
                            type: "entity",
                          },
                        }
                      : undefined
                  }
                  link={
                    config.sourceGroup?.id
                      ? getResourceUrlNew({
                          entityId: config.sourceGroup.id,
                          entityType: EntityType.Group,
                        })
                      : undefined
                  }
                  rightIcon={{
                    name: "x",
                    onClick: () => {
                      handleChange("sourceGroup")(undefined);
                    },
                  }}
                />
              ) : (
                <GroupSearchDropdown
                  placeholder="Select source group..."
                  limitSelectedGroups={1}
                  selectedGroupIds={[]}
                  onSelect={(data) => {
                    switch (data.actionType) {
                      case "select-option":
                        handleChange("sourceGroup")(data.groups[0]);
                        break;
                      case "remove-option":
                        handleChange("sourceGroup")(undefined);
                        break;
                    }
                  }}
                  popperForceDownward
                  popperHeight="xs"
                />
              )}
            </FormGroup>
          </>
        ) : (
          <>
            <FormGroup label="Add users">
              <List
                items={users}
                getItemLabel={(user) => user.fullName}
                getItemKey={(user) => user.id}
                getIcon={(option) => ({
                  type: "src",
                  style: "rounded",
                  icon: option.avatarUrl || defaultAvatarURL,
                  fallbackIcon: defaultAvatarURL,
                })}
                onDeleteItem={(user) =>
                  setUsers((oldUsers) =>
                    oldUsers.filter((u) => u.id !== user.id)
                  )
                }
                noItemsMessage="An Owner must contain at least 1 user."
              />
            </FormGroup>
            <PaginatedUserDropdown
              placeholder="Add a user"
              onChange={(newUser) => {
                if (!newUser) return;
                if (users.some((user) => user.id === newUser.id)) {
                  return;
                }
                setUsers((oldUsers) => [...oldUsers, newUser]);
              }}
              selectOnly
              hiddenUserIds={users.map((u) => u.id)}
            />
          </>
        )}
      </Modal.Body>
      <Modal.Footer
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLabel="Submit"
        primaryButtonLoading={loading}
        primaryButtonDisabled={
          !config.name ||
          (config.enabledSourceGroup
            ? !config.sourceGroup?.id
            : users.length === 0)
        }
      />
    </Modal>
  );
};

export default OwnerCreateModal;
