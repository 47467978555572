import {
  EntityType,
  useCreateConfigurationTemplateMutation,
  Visibility,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import { ResourceConfig } from "components/forms/common";
import ResourcesConfigForm from "components/forms/ResourcesConfigForm";
import {
  makeCreateInputForTemplate,
  makeDefaultRequestConfig,
  validateResourceConfig,
} from "components/forms/utils";
import { useToast } from "components/toast/Toast";
import { Banner, Button, Divider, FormRow, Input } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";
import OrgContext from "views/settings/OrgContext";

const CreateConfigurationTemplate = () => {
  const history = useHistory();
  const { displaySuccessToast } = useToast();
  const [errors, setErrors] = useState<string[]>([]);
  const [name, setName] = useState("");
  const [config, setConfig] = useState<Partial<ResourceConfig>>({
    requestConfigs: [makeDefaultRequestConfig()],
    visibility: Visibility.Global,
    requireMfaToApprove: false,
    requireMfaToConnect: false,
    ticketPropagation: {
      onGrant: false,
      onRevocation: false,
    },
  });
  const { orgState } = useContext(OrgContext);

  const [
    createConfigurationTemplate,
    { loading: saveLoading },
  ] = useCreateConfigurationTemplateMutation();

  const handleSave = async () => {
    const errors = validateResourceConfig(config, orgState);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }
    if (!name) {
      setErrors(["A unique name is required."]);
      return;
    }
    if (!config.adminOwner) {
      setErrors(["Admin owner is required."]);
      return;
    }

    try {
      setErrors([]);
      const { data } = await createConfigurationTemplate({
        variables: {
          input: makeCreateInputForTemplate(name, config),
        },
        refetchQueries: [
          "GetConfigurationTemplate",
          "ListConfigurationsTemplates",
        ],
      });

      switch (data?.createConfigurationTemplate.__typename) {
        case "CreateConfigurationTemplateResult":
          setErrors([]);
          displaySuccessToast("Successfully created configuration template");
          history.push(
            getResourceUrlNew({
              entityId:
                data.createConfigurationTemplate.configurationTemplate.id,
              entityType: EntityType.ConfigurationTemplate,
            })
          );
          break;
        case "ConfigurationTemplateNameExistsError":
          setErrors([data.createConfigurationTemplate.message]);
          break;
        default:
          logError("Failed to create configuration template");
          setErrors(["Failed to create configuration template."]);
      }
    } catch (err) {
      logError(err, "Failed to create configuration template");
      setErrors(["Failed to create configuration template."]);
    }
  };

  const headerButtons = (
    <div className={sprinkles({ display: "flex", gap: "sm" })}>
      <Button
        label="Cancel"
        onClick={() => {
          history.push("/templates/configurations");
        }}
        disabled={saveLoading}
        borderless
        size="md"
      />
      <Button
        label={saveLoading ? "Saving..." : "Save"}
        leftIconName="check"
        type="primary"
        onClick={handleSave}
        disabled={saveLoading}
        size="md"
      />
    </div>
  );
  return (
    <Column isContent maxWidth="lg">
      <ColumnHeader
        title="Create Configuration Template"
        icon={{ type: "name", icon: "template" }}
        rightActions={headerButtons}
      />
      <Divider />
      <ColumnContent>
        {errors.map((error) => (
          <Banner message={error} type="error" />
        ))}
        <div
          className={sprinkles({
            padding: "md",
            display: "flex",
            flexDirection: "column",
            gap: "xl",
          })}
        >
          <FormRow title="Name">
            <Input value={name} onChange={(v) => setName(v)} />
          </FormRow>
        </div>
        <ResourcesConfigForm
          config={config}
          mode={"edit"}
          isTemplate
          onChange={setConfig}
        />
      </ColumnContent>
    </Column>
  );
};

export default CreateConfigurationTemplate;
