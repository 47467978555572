import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AddGroupResourceInput,
  GroupFragment,
  GroupType,
  ResourceAccessLevel,
  ResourcePreviewSmallFragment,
  ResourceType,
  useAddGroupResourcesMutation,
  useMultipleResourceAccessLevelsQuery,
  useResourcePreviewLazyQuery,
} from "api/generated/graphql";
import ResourceSearchDropdown, {
  mergeResourceSelectData,
  removeResourceSelectData,
} from "components/dropdown/ResourceSearchDropdown";
import * as styles from "components/modals/AddItemModal.css";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import ResourceRoleRow from "components/modals/ResourceRoleRow";
import { Modal, Skeleton } from "components/ui";
import { useState } from "react";
import useLogEvent from "utils/analytics";
import { resourceRequiresAtLeastOneRole } from "utils/directory/resources";
import { logError } from "utils/logging";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";

interface Props {
  group: GroupFragment;
  onClose: () => void;
  existingRolesByResourceId: Record<string, ResourceAccessLevel[]>;
}

const GroupAddResourcesModal = (props: Props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [rowErrors, setRowErrors] = useState<Record<string, string>>({}); // key is resource id
  const [roleByResourceIdToAdd, setRoleByResourceIdToAdd] = useState<
    Record<string, ResourceAccessLevel[]>
  >({});

  const logEvent = useLogEvent();
  const startPushTaskPoll = usePushTaskLoader();

  const [addGroupResources, { loading }] = useAddGroupResourcesMutation();
  const [getResourceInfo] = useResourcePreviewLazyQuery();
  const {
    data,
    error,
    loading: loadingResourceRoles,
  } = useMultipleResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceIds: Object.keys(props.existingRolesByResourceId),
      },
    },
  });

  if (loadingResourceRoles) {
    return (
      <Modal title="Add Resources" isOpen onClose={props.onClose}>
        <Modal.Body>
          <Skeleton height="48px" />
        </Modal.Body>
      </Modal>
    );
  }

  if (error) {
    return (
      <Modal title="Add Resources" isOpen onClose={props.onClose}>
        <Modal.Body>
          <ModalErrorMessage errorMessage={error.message} />
        </Modal.Body>
      </Modal>
    );
  }

  let allRoles;
  switch (data?.multipleAccessLevels.__typename) {
    case "MultipleResourceAccessLevelsResult":
      allRoles = data.multipleAccessLevels.results;
  }

  const allRolesByResourceId: Record<string, ResourceAccessLevel[]> = {};
  allRoles?.forEach((role) => {
    allRolesByResourceId[role.resourceId] = role.accessLevels;
  });

  const handleSubmit = async () => {
    logEvent({
      name: "apps_add_resources_to_group",
      properties: {
        numResourcesAdded: Object.entries(roleByResourceIdToAdd).length,
      },
    });
    try {
      const groupResourcesToAdd: AddGroupResourceInput[] = [];
      const newRowErrors: Record<string, string> = {};
      for (const [resourceId, roles] of Object.entries(roleByResourceIdToAdd)) {
        const { data } = await getResourceInfo({
          variables: {
            input: {
              id: resourceId,
            },
          },
        });
        let resource: ResourcePreviewSmallFragment | undefined;
        switch (data?.resource.__typename) {
          case "ResourceResult":
            resource = data.resource.resource;
            break;
        }
        if (!resource) {
          setErrorMessage("failed to add resources to group");
          return;
        }

        if (roles.length === 0) {
          // If resource requires a role , but none are selected,
          // show an error.
          if (resourceRequiresAtLeastOneRole(resource)) {
            newRowErrors[resourceId] = "Please select at least one role";
          } else {
            // If resource does not require roles,
            // add an empty role to add the resource directly.
            groupResourcesToAdd.push({
              groupId: props.group.id,
              resourceId,
              accessLevel: {
                accessLevelName: "",
                accessLevelRemoteId: "",
              },
            });
          }
        }

        roles.forEach((role) => {
          groupResourcesToAdd.push({
            groupId: props.group.id,
            resourceId,
            accessLevel: {
              accessLevelName: role.accessLevelName,
              accessLevelRemoteId: role.accessLevelRemoteId,
            },
          });
        });
      }

      if (Object.keys(newRowErrors).length > 0) {
        setRowErrors(newRowErrors);
        return;
      }

      const { data } = await addGroupResources({
        variables: {
          input: {
            groupResources: groupResourcesToAdd,
          },
        },
        refetchQueries: ["Group"],
      });
      switch (data?.addGroupResources.__typename) {
        case "AddGroupResourcesResult":
          if (data.addGroupResources.taskId) {
            startPushTaskPoll(data.addGroupResources.taskId);
          }
          props.onClose();
          break;
        case "GroupNotFoundError":
        case "GroupResourceAlreadyExists":
          setErrorMessage(data.addGroupResources.message);
          break;
        case "UserFacingError":
          setErrorMessage(data.addGroupResources.message);
          break;
        default:
          logError(new Error(`failed to add resources to group`));
          setErrorMessage("Error: failed to add resources to group");
      }
    } catch (error) {
      logError(error, "failed to add resources to group");
      setErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to add resources to group",
          error
        )
      );
    }
  };

  const disabledResourceIds: string[] = [];
  props.group.groupResources.forEach((groupResource) => {
    // Disabled if all roles are already added
    const allRolesAdded =
      allRolesByResourceId[groupResource.resourceId]?.length ===
      props.existingRolesByResourceId[groupResource.resourceId].length;
    if (allRolesAdded) {
      disabledResourceIds.push(groupResource.resourceId);
    }
  });

  const disabledResourceTypes: ResourceType[] = [];
  if (props.group.groupType === GroupType.OktaGroup) {
    disabledResourceTypes.push(ResourceType.OktaRole);
  }
  if (props.group.groupType !== GroupType.SnowflakeRole) {
    // Snowflake resources are only available for Snowflake roles
    disabledResourceTypes.push(
      ...[
        ResourceType.SnowflakeDatabase,
        ResourceType.SnowflakeSchema,
        ResourceType.SnowflakeTable,
      ]
    );
  }

  return (
    <Modal title="Add Resources" isOpen onClose={props.onClose}>
      <Modal.Body>
        {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
        <ResourceSearchDropdown
          style="search"
          selectedResourceIds={Object.keys(roleByResourceIdToAdd)}
          disableForNonAdmin
          disabledResourceIds={disabledResourceIds}
          disabledResourceTypes={disabledResourceTypes}
          onSelect={({ resources, actionType }) => {
            setRoleByResourceIdToAdd((prev) => {
              if (actionType === "select-option") {
                return mergeResourceSelectData(prev, resources);
              } else {
                return removeResourceSelectData(prev, resources);
              }
            });
          }}
        />
        <div className={styles.itemsContainer}>
          {Object.keys(roleByResourceIdToAdd).map((resourceId) => {
            return (
              <ResourceRoleRow
                key={resourceId}
                resourceId={resourceId}
                existingRoles={
                  props.existingRolesByResourceId[resourceId] ?? []
                }
                roles={roleByResourceIdToAdd[resourceId] ?? []}
                error={rowErrors[resourceId]}
                onResourceRemove={() => {
                  setRoleByResourceIdToAdd((prev) => {
                    delete prev[resourceId];
                    return { ...prev };
                  });
                }}
                onRoleSelect={(role) => {
                  setRoleByResourceIdToAdd((prev) => {
                    if (resourceId in prev) {
                      return {
                        ...prev,
                        [resourceId]: [...prev[resourceId], role],
                      };
                    } else {
                      return {
                        ...prev,
                        [resourceId]: [role],
                      };
                    }
                  });
                }}
                onRoleRemove={(role) => {
                  setRoleByResourceIdToAdd((prev) => {
                    return {
                      ...prev,
                      [resourceId]: prev[resourceId].filter(
                        (a) =>
                          a.accessLevelRemoteId !== role.accessLevelRemoteId
                      ),
                    };
                  });
                }}
              />
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Add Resources"
        primaryButtonDisabled={Object.keys(roleByResourceIdToAdd).length === 0}
        primaryButtonLoading={loading}
        onPrimaryButtonClick={handleSubmit}
      />
    </Modal>
  );
};

export default GroupAddResourcesModal;
