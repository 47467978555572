import clsx from "clsx";
import { TooltipPlacement } from "components/label/Label";
import {
  CustomTooltip,
  CustomTooltipTypography,
} from "components/mui/MUIOverrides";
import sprinkles from "css/sprinkles.css";
import React from "react";

import * as styles from "./Tooltip.css";

/** tooltipText: Body text of the tooltip
tooltipTitleText: Bolded text on top of the body text of the tooltip
placement: Location of the tooltip relative to the wrapped element
arrow: Shows arrow pointing to the wrapped element
contentInline: Adds "inline" styling to wrapped element (eg. use when element is a text in a paragraph)
*/
type TooltipProps = React.PropsWithChildren<{
  tooltipText: React.ReactNode;
  tooltipTitleText?: React.ReactNode;
  placement?: TooltipPlacement;
  arrow?: boolean;
  contentInline?: boolean;
}>;

const Tooltip = (props: TooltipProps) => {
  return (
    <CustomTooltip
      open={props.tooltipText ? undefined : false} // Prevent empty tooltips from showing
      title={
        <>
          <CustomTooltipTypography variant={"subtitle1"}>
            {props.tooltipTitleText}
          </CustomTooltipTypography>
          <CustomTooltipTypography
            variant={"body1"}
            className={styles.tooltipContent}
          >
            {props.tooltipText}
          </CustomTooltipTypography>
        </>
      }
      placement={props.placement}
      arrow={props.arrow}
    >
      <span
        className={clsx(
          {
            [styles.contentInline]: props.contentInline,
          },
          sprinkles({
            overflow: "hidden",
            textOverflow: "ellipsis",
          })
        )}
      >
        {props.children}
      </span>
    </CustomTooltip>
  );
};

export default Tooltip;
