import Editor from "@monaco-editor/react";
import React from "react";

type CodeEditorProps = {
  code?: string;
  onChangeCode?: (code: string) => void;
  dark?: boolean;
  language?: string;
  height?: string;
};

const CodeEditor = (props: CodeEditorProps) => {
  const handleChangeCode = (code?: string) => {
    props.onChangeCode && props.onChangeCode(code ?? "");
  };

  return (
    <Editor
      width="100%"
      height={props.height || "350px"}
      defaultLanguage={props.language ?? "json"}
      options={{
        minimap: { enabled: false },
        scrollbar: { alwaysConsumeMouseWheel: false },
        scrollBeyondLastLine: false,
        readOnly: !props.onChangeCode,
        lineNumbers: "off",
      }}
      onChange={handleChangeCode}
      theme={props.dark ? "vs-dark" : "vs-light"}
      value={props.code}
    />
  );
};

export default CodeEditor;
