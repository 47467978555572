import { ResourceAccessLevelFragment } from "api/generated/graphql";
import { FormGroup, Modal, Select } from "components/ui";
import { useCallback, useMemo, useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";

type PendingRequest = {
  id: string;
  requestedResources: {
    resourceId: string;
    accessLevel: ResourceAccessLevelFragment;
  }[];
};
type ClickHandler = () => void;
type PendingRequestMenuOptionProps = {
  resourceId: string;
  pendingRequests: PendingRequest[];
  showMenuOption: (onClick: ClickHandler) => void;
};

export const PendingRequestMenuOption = (
  props: PendingRequestMenuOptionProps
) => {
  const history = useHistory();
  const logEvent = useLogEvent();

  const [showModal, setShowModal] = useState(false);

  // Get all roles that are pending for this resource.
  // Recall that a request may have multiple resources and roles attached to it.
  const { requestByRemoteId, allRoles } = useMemo(() => {
    const roleByRemoteId: Record<string, ResourceAccessLevelFragment> = {};
    const requestByRemoteId: Record<string, PendingRequest> = {};

    // Iterate over pendingRequests and filter resources by resourceId
    props.pendingRequests.forEach((pendingRequest) => {
      pendingRequest.requestedResources
        .filter(
          (requestedResource) =>
            requestedResource.resourceId === props.resourceId
        )
        .forEach((requestedResource) => {
          roleByRemoteId[requestedResource.accessLevel.accessLevelRemoteId] =
            requestedResource.accessLevel;
          requestByRemoteId[
            requestedResource.accessLevel.accessLevelRemoteId
          ] = pendingRequest;
        });
    });

    // Extract all roles from roleByRemoteId
    const allRoles = Object.values(roleByRemoteId);

    return { roleByRemoteId, requestByRemoteId, allRoles };
  }, [props.pendingRequests, props.resourceId]);

  const redirectToRequest = useCallback(
    (roleRemoteId: string) => {
      history.push(
        `${getResourceUrl(
          EntityTypeDeprecated.Request,
          requestByRemoteId[roleRemoteId]!.id
        )}?o=1`
      );
    },
    [history, requestByRemoteId]
  );

  return (
    <>
      {props.showMenuOption(async () => {
        if (allRoles.length === 1) {
          redirectToRequest(allRoles[0].accessLevelRemoteId);
        } else {
          setShowModal(true);
        }
        logEvent({ name: "apps_pending_click" });
      })}
      {showModal && (
        <RoleDropdownModal
          roles={allRoles.sort((a, b) => {
            if (a && b) {
              return a.accessLevelName.localeCompare(b.accessLevelName);
            }
            return 0;
          })}
          title={"View pending requests"}
          onSubmit={(role: ResourceAccessLevelFragment) => {
            setShowModal(false);
            redirectToRequest(role.accessLevelRemoteId);
          }}
          onClose={() => {
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};

type RoleDropdownModalProps = {
  roles: ResourceAccessLevelFragment[];
  activeSessionRoleRemoteIds?: string[];
  title: string;
  onSubmit: (role: ResourceAccessLevelFragment) => void;
  submitLoading?: boolean;
  onClose: () => void;
  initRole?: ResourceAccessLevelFragment;
  onClickRequestAnother?: () => void;
};

/**
 * Wraps a component so that a role must be selected.
 */
const RoleDropdownModal = (props: RoleDropdownModalProps) => {
  const [
    modalRole,
    setModalRole,
  ] = useState<ResourceAccessLevelFragment | null>(props.initRole || null);

  const getRoleByRemoteId = (roleRemoteId: string) => {
    return props.roles.filter(
      (role) => role.accessLevelRemoteId === roleRemoteId
    )[0];
  };

  const activeSessionRoleRemoteIds = new Set(props.activeSessionRoleRemoteIds);
  const getRoleLabel = (role: ResourceAccessLevelFragment) => {
    if (activeSessionRoleRemoteIds.has(role.accessLevelRemoteId)) {
      return role.accessLevelName + " (Active)";
    }
    return role.accessLevelName;
  };

  const modalReset = () => {
    setModalRole(null);
    props.onClose();
  };

  return (
    <Modal title={props.title} isOpen onClose={modalReset}>
      <Modal.Body>
        <FormGroup label="Select role">
          <Select
            value={modalRole ?? undefined}
            options={props.roles ?? []}
            getOptionLabel={(role) => getRoleLabel(role)}
            onChange={(role) => {
              if (role != null) {
                setModalRole(getRoleByRemoteId(role.accessLevelRemoteId));
              } else {
                setModalRole(null);
              }
            }}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="View"
        onPrimaryButtonClick={() => {
          if (modalRole !== null) {
            setModalRole(null);
            props.onSubmit(modalRole);
          }
        }}
        primaryButtonDisabled={modalRole == null}
        primaryButtonLoading={props.submitLoading}
      />
    </Modal>
  );
};
