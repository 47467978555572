import {
  PropagationTaskType,
  RequestFragment,
  SupportTicketPreviewFragment,
} from "api/generated/graphql";
import { SupportTicketLabel } from "components/label/Label";
import { Banner } from "components/ui";
import pluralize from "pluralize";
import { useRequestPermissions } from "views/requests/RequestOverview";

export const RequestTicketPropagationBanner = ({
  request,
  propagationTickets,
  propagationTaskType,
}: {
  request: RequestFragment;
  propagationTickets: SupportTicketPreviewFragment[];
  propagationTaskType?: PropagationTaskType;
}) => {
  const { canAdminReview, isReviewer } = useRequestPermissions(request);
  const currentUserIsReviewer = Boolean(canAdminReview || isReviewer);

  if (propagationTickets.length === 0) {
    return <></>;
  }

  let message: string | JSX.Element | undefined;

  if (currentUserIsReviewer) {
    const isPropagation = propagationTaskType?.includes("CREATE") ?? true;
    const ticketType = isPropagation ? "propagation" : "revocation";
    const verb = propagationTickets.length > 1 ? "require" : "requires";
    message = (
      <>
        The following {ticketType}{" "}
        {pluralize("ticket", propagationTickets.length)} {verb} attention,
        please take action them as soon as possible to complete this access
        request:{" "}
        {propagationTickets.map((ticket, index) => (
          <SupportTicketLabel
            inline
            url={ticket.url}
            thirdPartyProvider={ticket.thirdPartyProvider}
            identifier={ticket.identifier}
            key={index}
          />
        ))}
      </>
    );
  } else {
    message =
      "This request requires a manual step to be completed before accessing the resources. You will receive a notification once the process is complete.";
  }

  return <Banner marginBottom="md" type="warning" message={message} />;
};
