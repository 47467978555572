import lockTooltipStyles from "components/tooltips/LockTooltip.module.scss";
import { Tooltip } from "components/ui";
import React, { ReactElement } from "react";
import * as Icons from "react-feather";

export type LockTooltipProps = {
  tooltipText: string | ReactElement;
  hoverOn?: boolean;
};

export const LockTooltip = (props: LockTooltipProps) => {
  return (
    <div className={lockTooltipStyles.lockIconTooltipContainer}>
      <Tooltip tooltipText={props.tooltipText}>
        <span className={lockTooltipStyles.lockIconContainer}>
          <Icons.Lock strokeWidth={2} size={13} />
        </span>
      </Tooltip>
    </div>
  );
};
