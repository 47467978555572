import { createContext, useState } from "react";

interface UARSelectData {
  // List of selected resource user ids per access review resource
  selectedResourceUserIds: {
    [accessReviewResourceId: string]: string[];
  };
  setSelectedResourceUserIds: (resourceId: string, ids: string[]) => void;
  // List of selected group user ids per access review group
  selectedGroupUserIds: {
    [accessReviewGroupId: string]: string[];
  };
  setSelectedGroupUserIds: (groupId: string, ids: string[]) => void;
  // List of selected group resource ids per access review group
  selectedGroupResourceIds: {
    [accessReviewGroupId: string]: string[];
  };
  setSelectedGroupResourceIds: (groupId: string, ids: string[]) => void;
  // List of selected connection user ids per access review connection
  selectedConnectionUserIds: {
    [accessReviewConnectionId: string]: string[];
  };
  setSelectedConnectionUserIds: (connectionId: string, ids: string[]) => void;
  clearAllSelected: () => void;
}

export const UARSelectContext = createContext<UARSelectData>({
  selectedResourceUserIds: {},
  setSelectedResourceUserIds: () => {},
  selectedGroupUserIds: {},
  setSelectedGroupUserIds: () => {},
  selectedGroupResourceIds: {},
  setSelectedGroupResourceIds: () => {},
  selectedConnectionUserIds: {},
  setSelectedConnectionUserIds: () => {},
  clearAllSelected: () => {},
});

export const UARSelectContextProvider: React.FC = ({ children }) => {
  const [selectedResourceUserIds, setSelectedResourceUserIds] = useState<{
    [resourceId: string]: string[];
  }>({});
  const [selectedGroupUserIds, setSelectedGroupUserIds] = useState<{
    [groupId: string]: string[];
  }>({});
  const [selectedGroupResourceIds, setSelectedGroupResourceIds] = useState<{
    [groupId: string]: string[];
  }>({});
  const [selectedConnectionUserIds, setSelectedConnectionUserIds] = useState<{
    [connectionId: string]: string[];
  }>({});

  const handleSetSelectedResourceUserIds = (
    resourceId: string,
    ids: string[]
  ) => {
    setSelectedResourceUserIds((prev) => ({
      ...prev,
      [resourceId]: ids,
    }));
  };

  const handleSetSelectedGroupUserIds = (groupId: string, ids: string[]) => {
    setSelectedGroupUserIds((prev) => ({
      ...prev,
      [groupId]: ids,
    }));
  };

  const handleSetSelectedGroupResourceIds = (
    groupId: string,
    ids: string[]
  ) => {
    setSelectedGroupResourceIds((prev) => ({
      ...prev,
      [groupId]: ids,
    }));
  };

  const handleSetSelectedConnectionUserIds = (
    connectionId: string,
    ids: string[]
  ) => {
    setSelectedConnectionUserIds((prev) => ({
      ...prev,
      [connectionId]: ids,
    }));
  };

  const clearAllSelected = () => {
    setSelectedResourceUserIds({});
    setSelectedGroupUserIds({});
    setSelectedGroupResourceIds({});
    setSelectedConnectionUserIds({});
  };

  return (
    <UARSelectContext.Provider
      value={{
        selectedResourceUserIds,
        setSelectedResourceUserIds: handleSetSelectedResourceUserIds,
        selectedGroupUserIds,
        setSelectedGroupUserIds: handleSetSelectedGroupUserIds,
        selectedGroupResourceIds,
        setSelectedGroupResourceIds: handleSetSelectedGroupResourceIds,
        selectedConnectionUserIds,
        setSelectedConnectionUserIds: handleSetSelectedConnectionUserIds,
        clearAllSelected,
      }}
    >
      {children}
    </UARSelectContext.Provider>
  );
};
