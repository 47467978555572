import { GroupUserSource } from "api/generated/graphql";
import { Label, Tooltip } from "components/ui";
import React from "react";

const groupUserSourceDisplayInfo = {
  [GroupUserSource.Regular]: {
    sortValue: 0,
    label: <Label label={"Direct"} />,
    tooltipText: "This user is a member via direct assignment.",
  },
  [GroupUserSource.RegularNested]: {
    sortValue: 1,
    label: <Label label={"Nested group"} />,
    tooltipText:
      "This user is a member of a nested group that grants access to this group.",
  },
  [GroupUserSource.OnCall]: {
    sortValue: 2,
    label: <Label label={"On-call schedule"} />,
    tooltipText: "This user is on-call in a schedule bound to this group.",
  },
  [GroupUserSource.BreakGlass]: {
    sortValue: 3,
    label: <Label label={"Break-glass access"} />,
    tooltipText: "This user gave themselves break-glass access to this group.",
  },
  [GroupUserSource.RegularIndirect]: {
    sortValue: 4,
    label: <Label label={"Indirect"} />,
    tooltipText:
      "This user is a member of a group that grants access to this group.",
  },
};

export const getGroupUserSourceSortValue = (source: GroupUserSource) => {
  const displayInfo = groupUserSourceDisplayInfo[source];
  return displayInfo ? displayInfo.sortValue : -1;
};

type GroupUserSourceLabelProps = {
  source: GroupUserSource;
};

const GroupUserSourceLabel = (props: GroupUserSourceLabelProps) => {
  if (!props.source) {
    return <div>{"--"}</div>;
  }

  const displayInfo = groupUserSourceDisplayInfo[props.source];

  return (
    <div>
      <Tooltip tooltipText={displayInfo.tooltipText}>
        {displayInfo.label}
      </Tooltip>
    </div>
  );
};

export default GroupUserSourceLabel;
