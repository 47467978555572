import { useRequestApprovalTimesQuery } from "api/generated/graphql";
import pluralize from "pluralize";

import LineGraph from "../graphs/LineGraph";
import { useMetricsQueryInput } from "../MetricsDashboardContext";
import MetricsGraphCard from "./MetricsGraphCard";

const RequestApprovalTime = () => {
  const input = useMetricsQueryInput();

  const { data, loading, error } = useRequestApprovalTimesQuery({
    variables: {
      input,
    },
  });

  return (
    <MetricsGraphCard
      loading={loading}
      error={Boolean(error)}
      title="Average time to request approval"
      subtitle="Are we getting faster at approving requests?"
      units="hrs"
    >
      <LineGraph
        datasets={[
          {
            // Display values in hours
            data:
              data?.requestApprovalTimes.lineData.map((d) =>
                parseFloat((d.value / 60).toFixed(2))
              ) ?? [],
            label: "Average time to request approval",
          },
        ]}
        xAxisLabels={
          data?.requestApprovalTimes.lineData.map((d) => d.timestamp) ?? []
        }
        renderValue={(value) => `${value} ${pluralize("hour", value)}`}
        hideDecimals
      />
    </MetricsGraphCard>
  );
};

export default RequestApprovalTime;
