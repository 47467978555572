import { UserPreviewSmallFragment } from "api/generated/graphql";
import UserLabel from "components/label/item_labels/UserLabel";
import { TooltipPlacement } from "components/label/Label";
import { Avatar, Label, Popover, Tooltip } from "components/ui";
import React from "react";

import * as styles from "./GroupLeadersDetailsView.css";

// Number of group leaders that should be seen before popover activates
const MAX_VISIBLE_GROUP_LEADERS_COUNT = 5;

type GroupLeadersDetailsProps = {
  groupLeaderUsers?: UserPreviewSmallFragment[];
};

export const GroupLeadersDetailsView = (props: GroupLeadersDetailsProps) => {
  const groupLeaders = props.groupLeaderUsers ?? [];

  if (groupLeaders.length === 0) {
    return <></>;
  }

  // Present owners in alpha-order
  const sortedGroupLeaders = groupLeaders.slice().sort((a, b) => {
    return a.fullName.localeCompare(b.fullName);
  });

  // Get the leaders we'll display initially
  const visibleSortedGroupLeaders = sortedGroupLeaders.slice(
    0,
    MAX_VISIBLE_GROUP_LEADERS_COUNT
  );

  // Count of leaders to display in the popover bubble
  const nonVisibleGroupLeaderCount =
    sortedGroupLeaders.length - visibleSortedGroupLeaders.length;

  // Build out the view
  const content = (
    <>
      <div className={styles.ledByStyle}>
        <Label label="Led by" />
      </div>
      <div className={styles.leadersStyle}>
        {visibleSortedGroupLeaders.map((user) => {
          return (
            <div className={styles.leaderStyle} key={user?.id}>
              <Tooltip
                tooltipText={user?.fullName}
                placement={TooltipPlacement.Top}
              >
                <Avatar size="medium" url={user?.avatarUrl} />
              </Tooltip>
            </div>
          );
        })}
        {sortedGroupLeaders.length > MAX_VISIBLE_GROUP_LEADERS_COUNT && (
          <Popover
            content={sortedGroupLeaders.map((user) => {
              return (
                <div key={user?.id}>
                  <UserLabel
                    name={user.fullName}
                    avatar={user.avatarUrl}
                    large
                  />
                </div>
              );
            })}
          >
            <div className={styles.numberCircleStyle}>
              +{nonVisibleGroupLeaderCount}
            </div>
          </Popover>
        )}
      </div>
    </>
  );

  return content;
};

export default GroupLeadersDetailsView;
