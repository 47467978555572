import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AuthType,
  ConnectionType,
  Maybe,
  useCreateConnectionMutation,
  Visibility,
} from "api/generated/graphql";
import adLogo from "assets/logos/active-directory-logo.png";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { Button, FormGroup, Input } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import { validateHostname } from "views/connections/create/common";
import {
  CreateConnectionComponents as CreateConnectionComponentsV2,
  CreateConnectionView as CreateConnectionViewV2,
} from "views/connections/create/CreateConnectionComponents";
import {
  CreateConnectionComponentsV3,
  CreateConnectionViewV3,
} from "views/connections/create/CreateConnectionComponentsV3";
import VisibilitySelector from "views/visibility/VisibilitySelector";

const CreateAD = () => {
  return <CreateADForm />;
};

const CreateADForm = () => {
  const history = useHistory();
  const hasAppLevelVisibility = useFeatureFlag(FeatureFlag.AppLevelVisibility);
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const logEvent = useLogEvent();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [visibility, setVisibility] = useState<Visibility>(Visibility.Global);
  const [visibilityGroupIds, setVisibilityGroupIds] = useState<
    string[] | undefined
  >([]);
  const [serverHostname, setServerHostname] = useState("");
  const [serverPort, setServerPort] = useState("");
  const [groupsDN, setGroupsDN] = useState("");
  const [usersDN, setUsersDN] = useState("");
  const [rootUN, setRootUN] = useState("");
  const [rootPW, setRootPW] = useState("");

  const [adminOwnerId, setAdminOwnerId] = useState<string | undefined>(
    undefined
  );

  const [createConnectionMutation, { loading }] = useCreateConnectionMutation({
    refetchQueries: ["AppsListColumn", "Connections"],
  });

  const fieldUnset =
    name === "" ||
    description === "" ||
    !adminOwnerId ||
    serverHostname === "" ||
    serverPort === "" ||
    groupsDN === "" ||
    usersDN === "" ||
    rootUN === "" ||
    rootPW === "";

  const onSubmit = async () => {
    const hostnameErrorMessage = validateHostname(
      serverHostname,
      "Server hostname"
    );
    if (hostnameErrorMessage) {
      setErrorMessage(`Error: ${hostnameErrorMessage}`);
      return;
    }
    try {
      const { data } = await createConnectionMutation({
        variables: {
          input: {
            name: name,
            description: description,
            connectionType: ConnectionType.ActiveDirectory,
            adminOwnerId: adminOwnerId ?? "",
            visibility: visibility,
            visibilityGroupIds: visibilityGroupIds ?? [],
            importVisibility: Visibility.Global,
            metadata: {
              connectionType: ConnectionType.ActiveDirectory,
              ad: {
                serverHostname: serverHostname,
                serverPort: Number(serverPort),
                groupsDistinguishedName: groupsDN,
                usersDistinguishedName: usersDN,
              },
            },
            credentials: {
              authType: AuthType.ActiveDirectory,
              ad: {
                rootUsername: rootUN,
                rootPassword: rootPW,
              },
            },
          },
        },
      });
      switch (data?.createConnection.__typename) {
        case "CreateConnectionResult":
          displaySuccessToast(`Success: Active Directory app created`);
          logEvent({
            name: "apps_create_click",
            properties: {
              connectionType: ConnectionType.ActiveDirectory,
            },
          });
          history.replace(
            getResourceUrl(
              EntityTypeDeprecated.Connection,
              data.createConnection.connection.id
            )
          );

          break;
        case "ConnectionExistsError":
        case "ConnectionBadMetadataError":
        case "UserFacingError":
          logWarning(new Error(data.createConnection.message));
          setErrorMessage(data.createConnection.message);
          break;
        default:
          logError(new Error(`app creation failed`));
          setErrorMessage(`Error: app creation failed`);
      }
    } catch (error) {
      logError(error, "app creation failed");
      setErrorMessage(
        getModifiedErrorMessage(`Error: app creation failed`, error)
      );
    }
  };

  const CreateConnectionComponents = hasV3
    ? CreateConnectionComponentsV3
    : CreateConnectionComponentsV2;
  const CreateConnectionView = hasV3
    ? CreateConnectionViewV3
    : CreateConnectionViewV2;

  return (
    <CreateConnectionView
      logo={adLogo}
      title={"Add your Active Directory server"}
      onSubmit={onSubmit}
      submitDisabled={fieldUnset}
      submitLoading={loading}
    >
      <>
        <CreateConnectionComponents
          title={"Step 1"}
          subtitle={"Create an Opal group"}
        >
          <>
            <p>
              {`Opal requires a root AD user to manage your AD server on your behalf.
              Please also ensure that each user has the "mail" attribute set to the email
              address of the user in Opal.`}
            </p>
            <p>
              {`To read more about how we integrate with Active Directory, `}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={"https://docs.opal.dev/docs/active-directory"}
              >
                {`check out our official documentation`}
              </a>
              .
            </p>
          </>
        </CreateConnectionComponents>
        <CreateConnectionComponents
          title={"Step 2"}
          subtitle={"Fill in details about your Active Directory server"}
          isLast
        >
          <div>
            <FormGroup label="App name:">
              <Input
                onChange={(value) => {
                  setName(value);
                }}
                placeholder="Identifiable name of the Active Directory server."
                value={name}
              />
            </FormGroup>
            <FormGroup label="App admin:">
              <OwnerDropdown
                selectedOwnerId={adminOwnerId}
                onSelectOwner={(owner) => setAdminOwnerId(owner?.id)}
                placeholder="Select an owner to own this app."
              />
            </FormGroup>
            <FormGroup label="Description:">
              <Input
                onChange={setDescription}
                placeholder="A brief description of the account to further inform people requesting access to it."
                value={description}
              />
            </FormGroup>
            {hasAppLevelVisibility && (
              <FormGroup label="Visibility:">
                <VisibilitySelector
                  visibility={visibility}
                  onChangeVisibility={(vis) => {
                    if (vis == Visibility.Global) {
                      setVisibility(Visibility.Global);
                      setVisibilityGroupIds([]);
                    } else {
                      setVisibility(Visibility.Team);
                    }
                  }}
                  visibilityGroups={visibilityGroupIds ?? []}
                  onChangeVisibilityGroups={setVisibilityGroupIds}
                />
              </FormGroup>
            )}
            <FormGroup label="Server hostname:">
              <Input
                onChange={setServerHostname}
                placeholder={`The hostname of your AD server. (e.g. "ad.acme.com") Please ensure it's reachable from Opal.`}
                value={serverHostname}
              />
            </FormGroup>
            <FormGroup label="Server port:">
              <Input
                onChange={setServerPort}
                placeholder="The port your Active Directory server is exposed on (e.g. 389)."
                value={serverPort}
              />
            </FormGroup>
            <FormGroup label="Groups distinguished name:">
              <Input
                onChange={setGroupsDN}
                placeholder={`The DN from which Opal will search for groups (e.g. "OU=groups,DC=corp,DC=opal,DC=dev").`}
                value={groupsDN}
              />
            </FormGroup>
            <FormGroup label="Users distinguished name:">
              <Input
                onChange={setUsersDN}
                placeholder={`The DN from which Opal will search for users (e.g. "OU=users,DC=corp,DC=opal,DC=dev").`}
                value={usersDN}
              />
            </FormGroup>
            <FormGroup label="Root username:">
              <Input
                onChange={setRootUN}
                placeholder="The username of the AD root admin user."
                value={rootUN}
              />
            </FormGroup>
            <FormGroup label="Root password:">
              <Input
                onChange={setRootPW}
                placeholder="The password of the AD root admin user."
                value={rootPW}
                type="password"
              />
            </FormGroup>
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
            {!hasV3 && (
              <div
                className={sprinkles({
                  display: "flex",
                  justifyContent: "flex-end",
                })}
              >
                <Button
                  type="primary"
                  disabled={fieldUnset}
                  label={"Create"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </div>
            )}
          </div>
        </CreateConnectionComponents>
      </>
    </CreateConnectionView>
  );
};

export default CreateAD;
