import { EntityType, useGroupBindingDetailsQuery } from "api/generated/graphql";
import { Banner, Loader, Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { useImmer } from "use-immer";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useAccessRequestTransition } from "views/access_request/AccessRequestContext";
import { formatRequestDataForItems } from "views/apps/enduser_exp/utils";

import GroupBindingGroupLabel from "../rows/GroupBindingGroupLabel";

interface Props {
  groupId: string;
  groupBindingId: string;
  isOpen: boolean;
  onModalClose: () => void;
}

const GroupBindingRequestRedirectModal: React.FC<Props> = (props) => {
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);
  const hasEndUserXP = useFeatureFlag(FeatureFlag.EndUserExperience);
  const transitionToAccessRequest = useAccessRequestTransition();
  const history = useHistory();
  const { groupId, groupBindingId } = props;
  const { data, loading, error } = useGroupBindingDetailsQuery({
    variables: {
      input: [groupBindingId],
    },
    skip: groupBindingId.length === 0,
  });

  if (error) {
    return null;
  }

  const sourceGroupId = data?.groupBindings.groupBindings[0].sourceGroupId;
  const sourceGroup = data?.groupBindings.groupBindings[0].sourceGroup;
  const currentGroup = data?.groupBindings.groupBindings[0].groups.find(
    (group) => group.id === groupId
  );

  return (
    <Modal isOpen={props.isOpen} onClose={props.onModalClose} title={"Request"}>
      <Modal.Body>
        {loading ? (
          <Loader />
        ) : (
          <div
            className={sprinkles({
              display: "flex",
              flexDirection: "column",
              gap: "md",
            })}
          >
            <Banner
              message={
                "This is a linked group. To gain access you must request the source of truth."
              }
              type="warning"
            />
            <>You tried to request:</>
            <GroupBindingGroupLabel group={currentGroup} />
            <>The source of truth for this group is:</>
            <GroupBindingGroupLabel group={sourceGroup ?? undefined} />
          </div>
        )}
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Request source of truth"
        onPrimaryButtonClick={() => {
          props.onModalClose();
          if (hasV3Nav) {
            if (hasEndUserXP) {
              transitionToAccessRequest({
                ...formatRequestDataForItems({
                  entityId: sourceGroupId || "",
                  entityType: EntityType.Group,
                }),
                appId: sourceGroup?.connectionId,
              });
            } else {
              history.push(`/groups/${sourceGroupId}?request=true`);
            }
          } else {
            history.push(`/groups/${sourceGroupId}/request`);
          }
        }}
        primaryButtonLoading={loading}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={() => {
          props.onModalClose();
        }}
      />
    </Modal>
  );
};

export const useGroupBindingRequestRedirectModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalData, setModalData] = useImmer<{
    groupId: string;
    groupBindingId: string;
  }>({
    groupId: "",
    groupBindingId: "",
  });

  return {
    open: (groupId: string, groupBindingId: string) => {
      setModalData((draft) => {
        draft.groupId = groupId;
        draft.groupBindingId = groupBindingId;
      });
      setIsOpen(true);
    },
    maybeRenderModal: () => {
      return isOpen ? (
        <GroupBindingRequestRedirectModal
          groupId={modalData.groupId}
          groupBindingId={modalData.groupBindingId}
          isOpen={isOpen}
          onModalClose={() => setIsOpen(false)}
        />
      ) : null;
    },
  };
};

export default GroupBindingRequestRedirectModal;
