import {
  EntityType,
  ResourceDetailViewFragment,
  ResourceUsageLogFragment,
  useResourceUsageQuery,
} from "api/generated/graphql";
import UserLabel from "components/label/item_labels/UserLabel";
import { ResourceLabel } from "components/label/Label";
import LinkLabel from "components/label/LinkLabel";
import Table, { Header } from "components/ui/table/Table";
import moment from "moment/moment";
import { logError, logWarning } from "utils/logging";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ViewSkeleton from "views/loading/ViewSkeleton";

interface UsageTableRow {
  id: string;
  user: string;
  startDate: moment.Moment;
  endDate: moment.Moment | null;
  details: string;
  data: {
    usageLog: ResourceUsageLogFragment;
  };
}

type UsageTableProps = {
  resource: ResourceDetailViewFragment;
};

export const UsageTableV3 = (props: UsageTableProps) => {
  const { data, error, loading } = useResourceUsageQuery({
    variables: {
      input: {
        id: props.resource.id,
      },
    },
  });

  let usageLogs: ResourceUsageLogFragment[] | undefined | null = [];
  let emptyStateTitle = `No usage for this resource`;
  let emptyStateSubtitle = `This resource is not in use by any Opal users.`;
  if (data) {
    switch (data.resourceUsage.__typename) {
      case "ResourceUsageResult":
        usageLogs = data.resourceUsage.usageLogs;
        break;
      case "ResourceNotFoundError":
        logWarning(
          new Error(
            `unexpected error listing resource usage logs: ${data.resourceUsage.message}`
          )
        );
        break;
      case "UserFacingError":
        emptyStateTitle = "Failed to get usage logs";
        emptyStateSubtitle = data.resourceUsage.message;
        break;
      default:
        logError(new Error(`unexpected error listing resource usage logs`));
    }
  }

  if (loading) {
    return <ViewSkeleton />;
  }

  if (error) {
    logError(error, `unexpected error listing resource usage logs`);
    return <UnexpectedErrorPage error={error} />;
  }

  const headers: Header<UsageTableRow>[] = [
    {
      id: "user",
      label: "User",
      customCellRenderer: (row) => {
        const usageLog = row.data.usageLog;
        const isUser = usageLog.user !== undefined && usageLog.user !== null;
        const user = usageLog.user;

        return (
          <>
            {isUser ? (
              <ResourceLabel
                text={user?.fullName}
                entityTypeNew={EntityType.User}
                avatar={user?.avatarUrl}
                entityId={user?.id}
              />
            ) : (
              <UserLabel name={row.user} />
            )}
          </>
        );
      },
    },
    {
      id: "startDate",
      label: "Start Date",
      customCellRenderer: (row) => {
        return (
          <>{`${row.startDate.toISOString()} (${row.startDate.fromNow()})`}</>
        );
      },
      sortingFn: (a, b) => {
        return a.original.startDate.diff(b.original.startDate);
      },
    },
    {
      id: "endDate",
      label: "End Date",
      customCellRenderer: (row) => {
        return (
          <>
            {row.endDate
              ? `${row.endDate.toISOString()} (${row.endDate.fromNow()})`
              : "Ongoing"}
          </>
        );
      },
      sortingFn: (a, b) => {
        if (a.original.endDate && b.original.endDate) {
          return a.original.endDate.diff(b.original.endDate);
        } else if (a.original.endDate) {
          return -1;
        } else if (b.original.endDate) {
          return 1;
        } else {
          return 0;
        }
      },
    },
    {
      id: "details",
      label: "Details",
      customCellRenderer: (row) => {
        return <LinkLabel linkTitle={"View Remote Logs"} link={row.details} />;
      },
      sortable: false,
    },
  ];

  const rows: UsageTableRow[] | undefined = usageLogs?.map((usageLog) => {
    return {
      id: usageLog.id,
      user: usageLog.name || "",
      startDate: moment(new Date(usageLog.startDate)),
      endDate: usageLog.endDate ? moment(new Date(usageLog.endDate)) : null,
      details: usageLog.details || "",
      data: {
        usageLog: usageLog,
      },
    };
  });

  return (
    <Table
      rows={rows || []}
      columns={headers}
      getRowId={(data) => data.id}
      totalNumRows={rows?.length || 0}
      defaultSortBy="startDate"
      defaultSortDirection="DESC"
      emptyState={{
        title: emptyStateTitle,
        subtitle: emptyStateSubtitle,
        icon: "alert-circle",
      }}
    />
  );
};

export default UsageTableV3;
