import {
  Maybe,
  ReviewerUserFragment,
  ReviewerUserStatus,
} from "api/generated/graphql";
import { AccessReviewerModalEntry } from "components/modals/AccessReviewerModal";
import moment from "moment";

export const formatMonthDateStr = (dateStr?: Maybe<string>) => {
  const date = dateStr ? moment(new Date(dateStr)) : null;
  return date ? `${date.month() + 1}/${date.date()}` : `Ongoing`;
};

export const formatFullCalendarStr = (dateStr?: Maybe<string>) => {
  const date = dateStr ? moment(new Date(dateStr)) : null;
  return date ? date.calendar() : `Ongoing`;
};

export const isReviewer = (
  userId: string,
  reviewerUsers: Maybe<ReviewerUserFragment[]>
) => {
  if (!reviewerUsers) {
    return false;
  }

  for (let i = 0; i < reviewerUsers.length; i++) {
    if (reviewerUsers[i].userId === userId) {
      return true;
    }
  }
  return false;
};

export const getReviewerModalEntries = (
  reviewerUsers: Maybe<ReviewerUserFragment[]> | undefined,
  selectedEntityIdsFilter: Maybe<Set<string>> | undefined
): AccessReviewerModalEntry[] => {
  const reviewersByUserId: {
    [userId: string]: AccessReviewerModalEntry;
  } = {};
  // Filter reviewer users using selected entity ids
  if (selectedEntityIdsFilter) {
    reviewerUsers = reviewerUsers?.filter((reviewerUser) => {
      return selectedEntityIdsFilter?.has(reviewerUser.entityId);
    });
  }

  reviewerUsers?.forEach((reviewerUser) => {
    if (!reviewersByUserId[reviewerUser.userId]) {
      reviewersByUserId[reviewerUser.userId] = {
        id: reviewerUser.userId,
        name: reviewerUser.name,
        avatarUrl: reviewerUser.avatarUrl,
        entityIds: [],
        canBeRemoved: true,
      };
    }

    const entityId = {
      entityId: reviewerUser.entityId,
      entityType: reviewerUser.entityType,
    };
    reviewersByUserId[reviewerUser.userId].entityIds?.push(entityId);
    if (reviewersByUserId[reviewerUser.userId].canBeRemoved) {
      reviewersByUserId[reviewerUser.userId].canBeRemoved =
        reviewerUser.status === ReviewerUserStatus.NotStarted;
    }
  });

  return Object.values(reviewersByUserId);
};

// Allows sorting by reviewers to sort by num unique reviewers then reviewers name
export const getReviewersSortValue = (
  reviewerUsers: ReviewerUserFragment[]
): string => {
  if (reviewerUsers.length <= 0) {
    return "";
  }

  let reviewerValue = "";

  const reviewerUserNameSet = reviewerUsers.reduce((set, reviewerUser) => {
    return set.add(reviewerUser.name);
  }, new Set<string>());

  // Get first name only
  reviewerValue += reviewerUsers[0].name;

  reviewerValue += String(reviewerUserNameSet.size);

  return reviewerValue;
};
