import { getModifiedErrorMessage } from "api/ApiContext";
import {
  GeneralSettingType,
  OidcProviderType,
  PropagationStatusCode,
  RequestDecisionLevel,
  RequestFragment,
  RequestPreviewLargeFragment,
  RequestsDocument,
  RequestsQuery,
  RequestStatus,
  RequestType,
  useApproveRequestMutation,
  useInitOidcAuthFlowMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { opalConfetti } from "components/confetti/Confetti";
import { updateNumRequestsToReview } from "components/layout/left_sidebar/useLeftSidebarRoutes";
import { IdpMfaModal } from "components/modals/IdpMfaModal";
import { useToast } from "components/toast/Toast";
import { FormGroup, Input, Modal } from "components/ui";
import MultiButton, {
  MultiButtonOption,
} from "components/ui/button/MultiButton";
import MultiButtonV3 from "components/ui/buttonV3/MultiButtonV3";
import * as React from "react";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { generateState } from "utils/auth/auth";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";
import { clearMfaData, getMfaParams } from "utils/mfa/mfa";
import { clearOidcData, getOidcData, setOidcData } from "utils/oidc/oidc";
import { HasStatusCode, usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { useRequestPermissions } from "views/requests/RequestOverview";
import OrgContext from "views/settings/OrgContext";

type RequestApproveButtonProps = {
  request: RequestFragment;
};

export const RequestApproveButton = (props: RequestApproveButtonProps) => {
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);
  const { authState } = useContext(AuthContext);
  const { orgState } = useContext(OrgContext);
  const history = useHistory();
  const location = useLocation();
  const {
    canAdminReview,
    canNormalReview,
    targetIsCurrentUser,
  } = useRequestPermissions(props.request);
  const [showReasonModal, setShowReasonModal] = React.useState(false);
  const [reason, setReason] = React.useState("");
  const [isIdpMfaModalOpen, setIsIdpMfaModalOpen] = useState(false);

  const { displaySuccessToast, displayErrorToast } = useToast();

  const [approveRequest, { loading }] = useApproveRequestMutation({
    refetchQueries: ["EventsFull", "RequestsPendingStat", "RequestStats"],
  });
  const startPushTaskPoll = usePushTaskLoader();
  const [initOidcAuthFlow] = useInitOidcAuthFlowMutation();

  let resourceId: string | undefined;
  if (props.request.requestedResources.length > 0) {
    resourceId = props.request.requestedResources[0].resourceId;
  }
  let groupId: string | undefined;
  if (props.request.requestedGroups.length > 0) {
    groupId = props.request.requestedGroups[0].groupId;
  }

  useMountEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has("mfa")) {
      const mfaStatus = queryParams.get("mfa");
      if (mfaStatus === "success") {
        displaySuccessToast("MFA challenge completed");
        const mfaParams = getMfaParams();
        if (mfaParams && mfaParams.requestDecisionLevel) {
          doApproveRequest(
            mfaParams.requestDecisionLevel,
            mfaParams.requestAdminApprovalReason
          );
          clearMfaData();
        }
      } else if (mfaStatus === "access_denied") {
        displayErrorToast(
          "Error: Please contact your Opal Admin to reset your MFA."
        );
      } else {
        displayErrorToast(
          "MFA challenge failed. Please try again or contact support if the issue remains."
        );
      }

      queryParams.delete("mfa");
      history.replace({
        search: queryParams.toString(),
      });
    } else if (queryParams.has("oidc_auth")) {
      // Restore state from before redirect.
      const oidcAuthStatus = queryParams.get("oidc_auth");
      if (oidcAuthStatus === "success") {
        const oidcData = getOidcData();
        if (oidcData && oidcData.mfaParams?.requestDecisionLevel) {
          doApproveRequest(
            oidcData.mfaParams?.requestDecisionLevel,
            oidcData.mfaParams?.requestAdminApprovalReason
          );
          clearOidcData();
        }
      } else {
        displayErrorToast(
          "MFA challenge failed. Please try again or contact support if the issue remains."
        );
      }
      queryParams.delete("oidc_auth");
      history.replace({
        search: queryParams.toString(),
      });
    }
  });

  if (props.request.status !== RequestStatus.Pending) {
    return null;
  }

  if ((!canAdminReview && !canNormalReview) || targetIsCurrentUser) {
    return null;
  }

  const doApproveRequest = async (
    level: RequestDecisionLevel,
    approvalReason?: string
  ) => {
    approvalReason = approvalReason || reason;

    try {
      const { data } = await approveRequest({
        variables: {
          input: {
            id: props.request.id,
            level: level,
            comment: approvalReason,
          },
        },
        refetchQueries: hasV3Nav ? ["RequestsTable"] : [],
        update: (cache, { data }) => {
          switch (data?.approveRequest.__typename) {
            case "ApproveRequestResult": {
              const approvedRequest = data.approveRequest.request;
              // Remove when v2 is deprecated. This behavior is only
              // valid for v2 nav, where we have a sidebar of requests.
              !hasV3Nav &&
                [RequestType.Incoming, RequestType.Outgoing].forEach(
                  (requestType) => {
                    let cachedQuery = cache.readQuery<RequestsQuery>({
                      query: RequestsDocument,
                      variables: {
                        input: {
                          requestType: requestType,
                        },
                      },
                    });
                    if (cachedQuery) {
                      let approvedRequests: RequestPreviewLargeFragment[] = cachedQuery.requests.requests.filter(
                        (request) => request.id !== approvedRequest.id
                      );
                      cache.writeQuery<RequestsQuery>({
                        query: RequestsDocument,
                        variables: {
                          input: {
                            requestType: requestType,
                          },
                        },
                        data: {
                          ...cachedQuery,
                          requests: {
                            ...cachedQuery.requests,
                            requests: approvedRequests,
                          },
                        },
                      });
                    }
                  }
                );
              updateNumRequestsToReview(cache);
            }
          }
        },
      });
      switch (data?.approveRequest.__typename) {
        case "ApproveRequestResult":
          setShowReasonModal(false);
          setReason("");
          // TODO this only polls for 1 of the resources/groups requested
          if (data.approveRequest.taskId) {
            startPushTaskPoll(data.approveRequest.taskId, {
              messageOverride: {
                propagation: `Sent notification for access to be added`,
              },
              refetchOnComplete: [
                {
                  resourceId: resourceId,
                },
                {
                  groupId: groupId,
                },
              ],
              onComplete: (pushTask) => {
                const statuses: HasStatusCode[] = pushTask.result.propStatuses;
                const allSuccessful = statuses.every(
                  (status) =>
                    status.statusCode === PropagationStatusCode.Success
                );
                if (allSuccessful) {
                  opalConfetti();
                }
              },
            });
          }
          break;
        case "MfaInvalidError": {
          const useOktaMfa = orgState.orgSettings?.generalSettings.some(
            (setting) =>
              setting === GeneralSettingType.UseOktaMfaForGatingOpalActions
          );
          const useOidcMfa = orgState.orgSettings?.generalSettings.some(
            (setting) =>
              setting === GeneralSettingType.UseOidcMfaForGatingOpalActions
          );
          if (useOidcMfa) {
            try {
              const state = generateState();
              const { data } = await initOidcAuthFlow({
                variables: {
                  input: {
                    state: state,
                    oidcProviderType: OidcProviderType.Mfa,
                  },
                },
              });
              switch (data?.initOidcAuthFlow.__typename) {
                case "InitOidcAuthFlowResult":
                  setOidcData({
                    state: state,
                    oidcProviderType: OidcProviderType.Mfa,
                    postAuthPath: history.location.pathname,
                    mfaParams: {
                      requestDecisionLevel: level,
                      requestAdminApprovalReason: reason,
                    },
                  });
                  window.location.replace(data?.initOidcAuthFlow.authorizeUrl);
                  break;
                case "OidcProviderNotFoundError":
                  displayErrorToast(
                    "Error: Failed to trigger MFA. Please contact your admin."
                  );
                  break;
                default:
                  displayErrorToast(
                    "Error: Failed to trigger MFA. Please try again or contact support if the issue persists."
                  );
              }
            } catch (e) {
              logError(e, "Error: could not complete MFA");
            }
          } else if (useOktaMfa) {
            setIsIdpMfaModalOpen(true);
          } else {
            authState.authClient?.mfaFlow(history.location.pathname, {
              requestDecisionLevel: level,
              requestAdminApprovalReason: reason,
            });
          }
          break;
        }
        case "RequestAlreadyActionedError":
        case "RequestNotFoundError":
        case "AWSRolePolicyInvalidError":
        case "AWSRoleCreationError":
        case "AdminApproveRequiresReasonError":
        case "OrganizationInReadOnlyModeError":
          displayErrorToast(data.approveRequest.message);
          break;
        case "GroupNestingNotAllowedError": {
          const fromGroupName =
            data.approveRequest.fromGroup?.name ?? "Unknown group";
          const toGroupName =
            data.approveRequest.toGroup?.name ?? "Unknown group";
          displayErrorToast(
            `Error: Group nesting not allowed. Cannot approve request from ${fromGroupName} to ${toGroupName},` +
              ` as it would create a circular dependency between groups. Contact your admin for assistance.`
          );
          break;
        }
        default:
          logError(new Error(`request approval failed`));
          displayErrorToast(`Error: request approval failed`);
      }
    } catch (error) {
      logError(error, "request approval failed");
      displayErrorToast(
        getModifiedErrorMessage(`Error: request approval failed`, error)
      );
    }
  };

  const buttons: MultiButtonOption[] = [];
  if (canNormalReview && !props.request.reviewersError) {
    buttons.push({
      label: "Approve",
      handler: () => {
        doApproveRequest(RequestDecisionLevel.Regular);
      },
    });
  }
  if (canAdminReview) {
    buttons.push({
      label: "Approve (Admin Override)",
      handler: () => setShowReasonModal(true),
    });
  }

  const approveReasonModal = (
    <Modal
      isOpen={showReasonModal}
      onClose={() => setShowReasonModal(false)}
      title="Admin approve request"
    >
      <Modal.Body>
        <FormGroup label="Add a comment">
          <Input
            type="textarea"
            value={reason}
            onChange={setReason}
            placeholder="I am using admin override to approve this request because..."
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Approve"
        primaryButtonDisabled={reason.trim().length === 0 || loading}
        onPrimaryButtonClick={() => {
          doApproveRequest(RequestDecisionLevel.Admin);
        }}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={() => setShowReasonModal(false)}
      />
    </Modal>
  );

  const multiButton = hasV3Nav ? (
    <MultiButtonV3
      buttons={buttons}
      loading={loading}
      disabled={loading}
      type="successSecondary"
      leftIconName="thumbs-up"
      size="md"
      // This key is super important, it forces the component to remount when the request changes
      key={`multibutton-${props.request.id}`}
      round
    />
  ) : (
    <MultiButton
      buttons={buttons}
      loading={loading}
      disabled={loading}
      type="success"
      leftIconName="thumbs-up"
      size="md"
      // This key is super important, it forces the component to remount when the request changes
      key={`multibutton-${props.request.id}`}
      round
    />
  );

  return (
    <>
      {multiButton}
      {approveReasonModal}
      {isIdpMfaModalOpen && (
        <IdpMfaModal
          onClose={() => setIsIdpMfaModalOpen(false)}
          onMfaSuccess={() => {
            setIsIdpMfaModalOpen(false);
            reason
              ? doApproveRequest(RequestDecisionLevel.Admin)
              : doApproveRequest(RequestDecisionLevel.Regular);
          }}
        />
      )}
    </>
  );
};

export default RequestApproveButton;
