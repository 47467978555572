import {
  ConnectionPreviewLargeFragment,
  ConnectionUserFragment,
  PropagationStatusCode,
  useConnectionUsersRowQuery,
} from "api/generated/graphql";
import OpalTable, { Columns } from "components/opal/table/OpalTable";
import PropagationStatusLabelWithModal from "components/propagation/PropagationStatusLabelWithModal";
import { Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useHistory, useParams } from "react-router";
import { logError } from "utils/logging";
import { useURLSearchParam } from "utils/router/hooks";
import { PropagationType } from "utils/useRemediations";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { getUserAvatarIcon } from "views/users/utils";

import { SEARCH_QUERY_URL_KEY } from "./AppsContext";
import AppUsersFilters from "./AppUsersFilters";

interface UserRow {
  id: string;
  name: string;
  email: string;
  title: string;
  manager: string;
  status?: PropagationStatusCode;
  data: ConnectionUserFragment;
}

const AppUsersTable = () => {
  const history = useHistory();
  const { appId } = useParams<Record<string, string>>();
  const [searchQueryParam] = useURLSearchParam(SEARCH_QUERY_URL_KEY);

  const { data, error, networkStatus } = useConnectionUsersRowQuery({
    /**
     * Do not cache results here because we potentially return way too many
     * objects at once, and the cache update is performed upfront, which adds a
     * long blocking operation that slow's down the component's mount by 2-10s.
     *
     * This policy can be changed back to "cache-first" (default) once we have
     * server-side pagination on this query.
     */
    fetchPolicy: "no-cache",
    variables: {
      input: {
        id: appId,
      },
    },
  });

  let connectionUsers: ConnectionUserFragment[] = [];
  let connection: ConnectionPreviewLargeFragment | undefined;
  if (data) {
    switch (data.connection.__typename) {
      case "ConnectionResult":
        connectionUsers = data.connection.connection.connectionUsers;
        connection = data.connection.connection;
        break;
      default:
        logError(new Error(`failed to get app`));
    }
  } else if (error) {
    logError(error, `failed to get app`);
  }

  if (error) {
    return (
      <div className={sprinkles({ marginTop: "xl" })}>
        <UnexpectedErrorPage error={error} />
      </div>
    );
  }

  const rows: UserRow[] = connectionUsers
    .filter((user) => {
      let searchQuery = searchQueryParam?.toString();
      if (!searchQuery || searchQuery === "") {
        return true;
      }
      const stripWhitespaceAndLowerCase = (str: string) =>
        str.toLowerCase().replace(/\s/g, "");
      const processedName = stripWhitespaceAndLowerCase(
        user.user?.fullName ?? ""
      );
      const processedEmail = stripWhitespaceAndLowerCase(
        user.user?.email ?? ""
      );
      const processedQuery = stripWhitespaceAndLowerCase(searchQuery);

      return (
        processedName.includes(processedQuery) ||
        processedEmail.includes(processedQuery)
      );
    })
    .map((user) => {
      return {
        id: user.user?.id ?? "",
        name: user.user?.fullName ?? "Unknown user",
        email: user.user?.email ?? "Unknown email",
        title: user.user?.position || "--",
        manager: user.user?.manager?.fullName || "--",
        status: user.propagationStatus?.statusCode,
        data: user,
      };
    });

  const columns: Columns<UserRow> = [
    {
      id: "name",
      label: "Name",
      customCellRenderer: ({ data }) => {
        if (data.user) {
          const userId = data.user.id;
          return (
            <div
              className={sprinkles({
                display: "flex",
                gap: "sm",
                cursor: "pointer",
              })}
              onClick={() => history.push(`/users/${userId}`)}
            >
              <Icon data={getUserAvatarIcon(data.user)} size="sm" />
              {data.user.fullName}
            </div>
          );
        }
        return "--";
      },
    },
    {
      id: "email",
      label: "Email",
    },
    {
      id: "title",
      label: "Title",
    },
    {
      id: "manager",
      label: "Manager",
    },
    {
      id: "status",
      label: "Status",
      width: 60,
      customCellRenderer: ({ data }) => {
        return (
          <PropagationStatusLabelWithModal
            propagationType={PropagationType.ConnectionUser}
            propagationStatus={data.propagationStatus}
            isAccessReview={false}
            entityInfo={{
              roleAssignmentKey: data.userId + data.connectionId,
              user: data.user,
              connection,
            }}
          />
        );
      },
    },
  ];

  return (
    <div>
      <AppUsersFilters />
      <OpalTable
        entityName="User"
        networkStatus={networkStatus}
        rows={rows}
        columns={columns}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        onRowClickTransitionTo={(row) => row.id}
      ></OpalTable>
    </div>
  );
};

export default AppUsersTable;
