import { TruncatedLabel } from "components/label/Label";
import React from "react";

type EventLabelProps = {
  eventId: string;
};

export const EventLabel = (props: EventLabelProps) => {
  let url = null;
  if (props.eventId) {
    url = `/events/${props.eventId}`;
  }

  return <TruncatedLabel text={"See details"} url={url} />;
};

export default EventLabel;
