import { TagFragment, useAddResourceTagsMutation } from "api/generated/graphql";
import ResourceSearchDropdown, {
  ResourceSelectData,
} from "components/dropdown/ResourceSearchDropdown";
import * as styles from "components/modals/AddItemModal.css";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Button, EntityIcon, Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { logError, logWarning } from "utils/logging";

interface Props {
  tag: TagFragment;
  onClose: () => void;
}

const TagAddResourcesModal = (props: Props) => {
  const [resourcesToAdd, setResourcesToAdd] = useState<ResourceSelectData[]>(
    []
  );
  const [error, setError] = useState("");

  const { displaySuccessToast } = useToast();
  const [addResourceTags, { loading }] = useAddResourceTagsMutation();

  const handleSubmit = async () => {
    try {
      const { data } = await addResourceTags({
        variables: {
          input: {
            resourceTags: resourcesToAdd.map((resource) => ({
              tagId: props.tag.id,
              resourceId: resource.id,
            })),
          },
        },
        refetchQueries: ["ResourceTags", "Tag"],
      });
      switch (data?.addResourceTags.__typename) {
        case "AddResourceTagsResult":
          displaySuccessToast("Success: tag added to resources");
          props.onClose();
          break;
        case "ResourceNotFoundError":
        case "TagNotFoundError":
          logWarning(new Error(data.addResourceTags.message));
          setError(data.addResourceTags.message);
          break;
        default:
          logError(new Error(`failed to add tag to resources`));
          setError("Error: failed to add tag to resources");
      }
    } catch (err) {
      logError(err, "Failed to add tag to resources");
      setError("Failed to add tag to resources");
    }
  };

  const disabledResourceIds: string[] = props.tag.tagResources.map(
    (tagResource) => tagResource.resourceId
  );

  return (
    <Modal isOpen title="Add Tag to Resources" onClose={props.onClose}>
      <Modal.Body>
        {error && <ModalErrorMessage errorMessage={error} />}
        <ResourceSearchDropdown
          style="search"
          selectedResourceIds={resourcesToAdd.map((resource) => resource.id)}
          onSelect={({ actionType, resources }) => {
            setResourcesToAdd((prev) => {
              if (actionType === "select-option") {
                return [...prev, ...resources];
              } else {
                const resourceIdSet = new Set(
                  resources.map((resource) => resource.id)
                );
                return prev.filter(
                  (resource) => !resourceIdSet.has(resource.id)
                );
              }
            });
          }}
          disabledResourceIds={disabledResourceIds}
        />
        <div className={styles.itemsContainer}>
          {resourcesToAdd.map((resource) => (
            <div className={styles.itemRow} key={resource.id}>
              <EntityIcon type={resource.resourceType} />
              <div className={sprinkles({ flexGrow: 1 })}>
                <div className={sprinkles({ fontSize: "labelMd" })}>
                  {resource.name}
                </div>
              </div>
              <Button
                leftIconName="trash-2"
                borderless
                type="error"
                onClick={() => {
                  setResourcesToAdd((prev) => {
                    return prev.filter((g) => g.id !== resource.id);
                  });
                }}
                size="sm"
                round
              />
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Submit"
        primaryButtonDisabled={resourcesToAdd.length === 0}
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLoading={loading}
      />
    </Modal>
  );
};

export default TagAddResourcesModal;
