import { Icon, ProgressBar } from "components/ui";
import pluralize from "pluralize";

import * as styles from "./ProgressCard.css";

interface Props {
  totalNumItems: number;
  completedItems: number;
}

const ProgressCard = ({ totalNumItems, completedItems }: Props) => {
  const todoItems = totalNumItems - completedItems;
  return (
    <div className={styles.progressContainer}>
      <div className={styles.progressHeader}>
        <Icon name="check-circle" size="xs" color="green600" />
        {completedItems}/{totalNumItems}
      </div>
      <ProgressBar percentProgress={(completedItems / totalNumItems) * 100} />
      <div className={styles.progressSublabel}>
        {todoItems} {pluralize("item", todoItems)} left to review
      </div>
    </div>
  );
};

export default ProgressCard;
