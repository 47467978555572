import { openInNewTab } from "api/common/common";
import { DataElement, Label, Loader } from "components/ui";
import Avatar from "components/ui/avatar/Avatar";
import {
  BASE_NODE_HEIGHT,
  ICON_HEIGHT,
  ICON_WIDTH,
  NODE_ATTRIBUTE_HEIGHT,
  NODE_PADDING,
  SPACING,
  TEXT_SPACING,
  UserNodeData,
} from "components/viz/common";
import NodeMenu from "components/viz/menus/NodeMenu";
import Node from "components/viz/nodes/Node";
import React from "react";
import { useContext } from "react";
import useLogEvent from "utils/analytics";

import { useFilterDispatch, useFilterState } from "../contexts/FilterContext";
import { GraphContext } from "../contexts/GraphContext";
import { getNodeWidth } from "../utils";
import * as styles from "./UserNode.css";

interface UserNodeProps {
  data: UserNodeData;
  onClick?: () => void;
  onCmdClick?: () => void;
}

const UserNode = ({ data, onClick, onCmdClick }: UserNodeProps) => {
  const filterDispatch = useFilterDispatch();
  const filterState = useFilterState();
  const { graphDispatch } = useContext(GraphContext);
  const {
    attributesShown: { email, position, team },
  } = filterState;
  const logEvent = useLogEvent();
  const nodeWidth = getNodeWidth(data);
  const canViewAttributes = data.height > BASE_NODE_HEIGHT;

  const setLoading = React.useCallback(
    (loading: boolean) => {
      graphDispatch({
        type: "SET_LOADING",
        payload: {
          loading,
        },
      });
    },
    [graphDispatch]
  );

  let attributeY = BASE_NODE_HEIGHT - SPACING.xs;
  let emailY, positionY, teamY;
  if (email) {
    emailY = attributeY;
    attributeY += NODE_ATTRIBUTE_HEIGHT;
  }
  if (team) {
    teamY = attributeY;
    attributeY += NODE_ATTRIBUTE_HEIGHT;
  }
  if (position) {
    positionY = attributeY;
    attributeY += NODE_ATTRIBUTE_HEIGHT;
  }

  const nodeMenuOptions: PropsFor<typeof NodeMenu>["options"] = [
    {
      label: "View details",
      onClick: () => {
        if (data.url) {
          openInNewTab(data.url);
        }
        logEvent({
          name: "viz_view_details_click",
          properties: {
            entityName: data.name ?? "",
            nodeID: data.nodeId,
            entityType: data.kind,
          },
        });
      },
      icon: { type: "name", icon: "link-external" },
    },
  ];
  const selectedUserIds = filterState.selection.userIds ?? [];
  if (selectedUserIds.length !== 1 || !selectedUserIds.includes(data.nodeId)) {
    nodeMenuOptions.push({
      label: "View relations",
      onClick: () => {
        setLoading(true);
        filterDispatch({
          type: "ADD_USER",
          payload: {
            id: data.nodeId,
            clearFilter: true,
          },
        });
        logEvent({
          name: "viz_view_relations_click",
          properties: {
            entityName: data.name ?? "",
            nodeID: data.nodeId,
            entityType: data.kind,
          },
        });
      },
      icon: { type: "name", icon: "eye" },
    });
  }

  return (
    <Node
      data={data}
      onClick={() => {
        onClick && onClick();
        graphDispatch({
          type: "TOGGLE_SELECT_USER",
          payload: {
            userId: data.nodeId,
          },
        });
      }}
      onCmdClick={onCmdClick}
    >
      <foreignObject
        width={ICON_WIDTH}
        height={ICON_HEIGHT}
        y={BASE_NODE_HEIGHT / 2 - ICON_HEIGHT / 2}
        x={NODE_PADDING}
        className={styles.iconGroup}
      >
        <Avatar size="normal" url={data.avatarUrl} />
      </foreignObject>
      {data.loading && (
        <foreignObject
          width={ICON_WIDTH}
          height={ICON_HEIGHT}
          x={nodeWidth - SPACING.xl - NODE_PADDING}
          y={BASE_NODE_HEIGHT / 2 - ICON_HEIGHT / 2}
        >
          <Loader size="md" />
        </foreignObject>
      )}
      <foreignObject
        className={styles.label}
        x={NODE_PADDING + ICON_WIDTH + SPACING.sm}
        y={BASE_NODE_HEIGHT / 2 - 10}
        height={TEXT_SPACING}
        width={nodeWidth - (ICON_WIDTH + NODE_PADDING * 3)}
      >
        <Label label={data.name ?? ""} oneLine />
      </foreignObject>
      <foreignObject
        width={ICON_WIDTH}
        height={ICON_HEIGHT}
        x={nodeWidth - ICON_WIDTH - NODE_PADDING}
        y={BASE_NODE_HEIGHT / 2 - ICON_HEIGHT / 2}
      >
        <NodeMenu options={nodeMenuOptions} />
      </foreignObject>

      {email && canViewAttributes ? (
        <foreignObject
          className={styles.dataElementForeignObject}
          width={nodeWidth - NODE_PADDING * 2}
          height={NODE_ATTRIBUTE_HEIGHT}
          x={NODE_PADDING}
          y={emailY}
        >
          <DataElement
            label={data.email ? data.email : "Unknown"}
            leftIcon={{
              name: "at-sign",
            }}
            color="gray"
            bold={false}
            size="sm"
            disableHover
            oneLine
          />
        </foreignObject>
      ) : null}
      {team && canViewAttributes ? (
        <foreignObject
          className={styles.dataElementForeignObject}
          width={nodeWidth - NODE_PADDING * 2}
          height={NODE_ATTRIBUTE_HEIGHT}
          x={NODE_PADDING}
          y={teamY}
        >
          <DataElement
            label={data.team ? data.team : "Unknown"}
            leftIcon={{
              name: "users",
            }}
            color="gray"
            bold={false}
            size="sm"
            disableHover
            oneLine
          />
        </foreignObject>
      ) : null}
      {position && canViewAttributes ? (
        <foreignObject
          className={styles.dataElementForeignObject}
          width={nodeWidth - NODE_PADDING * 2}
          height={NODE_ATTRIBUTE_HEIGHT}
          x={NODE_PADDING}
          y={positionY}
        >
          <DataElement
            label={data.position ? data.position : "Unknown"}
            leftIcon={{
              name: "role",
            }}
            color="gray"
            bold={false}
            size="sm"
            disableHover
            oneLine
          />
        </foreignObject>
      ) : null}
    </Node>
  );
};

export default UserNode;
