import {
  ConnectionType,
  GroupDropdownPreviewFragment,
  usePaginatedGroupDropdownQuery,
} from "api/generated/graphql";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { Select } from "components/ui";
import _ from "lodash";
import { useEffect, useState } from "react";
import { logError } from "utils/logging";
import { SearchParamValue } from "utils/router/hooks";

// Limits number of options rendered for performance.
const MAX_OPTIONS_TO_DISPLAY = 50;

type PaginatedGroupDropdownProps = {
  value?: GroupDropdownPreviewFragment;
  valueId?: SearchParamValue;
  onChange: (group?: GroupDropdownPreviewFragment) => void;
  connectionType?: ConnectionType;
  disabled?: boolean;
  clearable?: boolean;
  /** Focus the input component on mount. */
  autoFocus?: boolean;
  placeholder?: string;
  selectOnly?: boolean;
  size?: "xs" | "sm" | "md";
};

/**
 * A searchable dropdown containing all resources in the organization matching
 * the given filters. This dropdown statically limits the number of rendered
 * options to preserve performance -- the user is expected to leverage search
 * instead of scrolling to select the desired resource.
 */
const PaginatedGroupDropdown = ({
  value,
  valueId,
  onChange,
  connectionType,
  disabled,
  clearable = true,
  autoFocus,
  placeholder,
  selectOnly,
  size,
}: PaginatedGroupDropdownProps) => {
  const [searchQuery, setSearchQuery] = useState("");

  const {
    data: groupsData,
    error: groupsError,
    refetch,
  } = usePaginatedGroupDropdownQuery({
    variables: {
      input: {
        searchQuery,
        connectionType,
        maxNumEntries: MAX_OPTIONS_TO_DISPLAY,
      },
    },
    fetchPolicy: "no-cache",
  });
  if (groupsError) {
    logError(groupsError, "failed to list groups");
  }

  useEffect(() => {
    refetch({
      input: {
        searchQuery,
        connectionType,
        maxNumEntries: MAX_OPTIONS_TO_DISPLAY,
      },
    });
  }, [refetch, connectionType, searchQuery]);

  const handleSearchInputChange = _.debounce((s: string) => {
    setSearchQuery(s);
  }, 250);

  const handleInputChange = (input: string) => {
    handleSearchInputChange(input);
  };

  let groups: GroupDropdownPreviewFragment[] = [];
  switch (groupsData?.groups.__typename) {
    case "GroupsResult":
      groups = groupsData.groups.groups;
      break;
    default:
      break;
  }

  const handleSelectValue = (value?: GroupDropdownPreviewFragment) => {
    onChange(value);
    if (selectOnly) handleSearchInputChange("");
  };

  return (
    <Select
      options={groups}
      value={
        !value && valueId ? groups.find((group) => group.id === valueId) : value
      }
      onChange={handleSelectValue}
      onInputChange={handleInputChange}
      clearable={clearable}
      disabled={disabled}
      getOptionLabel={(option) => option.name}
      getIcon={(group) => ({
        type: "src",
        icon: getGroupTypeInfo(group.groupType)?.icon,
      })}
      getOptionSelected={(option, value) => option.id === value.id}
      disableBuiltInFiltering // Search is already handled server-side
      autoFocus={autoFocus}
      placeholder={placeholder}
      selectOnly={selectOnly}
      popperForceDownward
      popperHeight="sm"
      size={size}
    />
  );
};

export default PaginatedGroupDropdown;
