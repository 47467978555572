import { AccessReviewGroupFragment } from "api/generated/graphql";
import {
  Section,
  SectionColumn,
  SectionRow,
  ViewerOverview,
} from "components/viewer/Viewer";
import React from "react";
import AccessReviewSnapshotRow from "views/access_reviews/AccessReviewSnapshotRow";
import GroupAdminRow from "views/groups/viewer/rows/GroupAdminRow";
import { GroupConnectionRow } from "views/groups/viewer/rows/GroupConnectionRow";
import { GroupDescriptionRow } from "views/groups/viewer/rows/GroupDescriptionRow";

type AccessReviewGroupOverviewProps = {
  accessReviewGroup: AccessReviewGroupFragment;
};

export const AccessReviewGroupOverviewRow = (
  props: AccessReviewGroupOverviewProps
) => {
  return (
    <ViewerOverview>
      <SectionColumn large>
        <AccessReviewGroupOverviewSection
          accessReviewGroup={props.accessReviewGroup}
        />
      </SectionColumn>
    </ViewerOverview>
  );
};

type AccessReviewGroupOverviewSectionProps = {
  accessReviewGroup: AccessReviewGroupFragment;
};

export const AccessReviewGroupOverviewSection = (
  props: AccessReviewGroupOverviewSectionProps
) => {
  const group = props.accessReviewGroup.group;
  return (
    <Section>
      <SectionRow>
        <AccessReviewSnapshotRow
          snapshotTime={props.accessReviewGroup.createdAt}
        />
      </SectionRow>
      <SectionRow>
        <GroupConnectionRow group={group} />
        <GroupAdminRow group={group} />
      </SectionRow>
      <SectionRow>
        <GroupDescriptionRow group={group} />
      </SectionRow>
    </Section>
  );
};

export default AccessReviewGroupOverviewRow;
