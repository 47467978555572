import { ConnectionType, GroupType } from "api/generated/graphql";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { Label } from "components/ui";
import sprinkles from "css/sprinkles.css";

const GroupBindingGroupLabel = ({
  group,
  inline,
}: {
  inline?: boolean;
  group?: {
    id: string;
    name: string;
    groupType: GroupType;
    connection?: {
      id: string;
      name: string;
      connectionType: ConnectionType;
    } | null;
  };
}) => {
  let connectionLabel = null;
  if (group?.connection?.connectionType === ConnectionType.CustomConnector) {
    connectionLabel = (
      <>
        <Label
          label={group.connection.name}
          inline={inline}
          linkTo={`/apps/${group.connection.id}`}
          icon={{
            type: "src",
            icon: getConnectionTypeInfo(group.connection.connectionType)?.icon,
          }}
        />
        {"/"}
      </>
    );
  }
  return (
    <span
      className={sprinkles({
        display: inline ? "inline-flex" : "flex",
        flexDirection: "row",
        gap: "xs",
        alignItems: "center",
      })}
    >
      {connectionLabel}
      <Label
        inline={inline}
        linkTo={`/groups/${group?.id}`}
        label={group?.name ?? "Deleted group"}
        icon={
          connectionLabel
            ? undefined
            : {
                type: "src",
                icon: getGroupTypeInfo(group?.groupType)?.icon,
              }
        }
      />
    </span>
  );
};

export default GroupBindingGroupLabel;
