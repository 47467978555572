import { GroupPreviewLargeFragment, Maybe } from "api/generated/graphql";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { ResourceLabel } from "components/label/Label";
import { SectionEntry } from "components/viewer/Viewer";
import React from "react";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";

export const GROUP_CONNECTION_TITLE = "App";

type GroupConnectionRowProps = {
  group?: Maybe<GroupPreviewLargeFragment>;
};

export const GroupConnectionRow = (props: GroupConnectionRowProps) => {
  return (
    <SectionEntry title={GROUP_CONNECTION_TITLE}>
      <ResourceLabel
        text={
          props.group?.connection?.name || props.group?.connectionId || "--"
        }
        entityType={EntityTypeDeprecated.Connection}
        entityId={props.group?.connectionId}
        icon={
          getConnectionTypeInfo(props.group?.connection?.connectionType)?.icon
        }
      />
    </SectionEntry>
  );
};

export default GroupConnectionRow;
