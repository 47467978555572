import { AccessReviewReviewerAssignmentPolicy } from "api/generated/graphql";
import { Button, FormGroup, Modal, RadioGroup } from "components/ui";
import React, { useState } from "react";

import { ACCESS_REVIEW_ASSIGNMENT_LABELS } from "../../views/access_reviews/settings/utils";

type AccessReviewReviewerAssignmentPolicySelectorProps = {
  assignmentPolicy: AccessReviewReviewerAssignmentPolicy;
  onChange?: (policy: AccessReviewReviewerAssignmentPolicy) => void;
};

type AccessReviewReviewerAssignmentPolicyOption =
  | AccessReviewReviewerAssignmentPolicy.Manually
  | AccessReviewReviewerAssignmentPolicy.ByManager
  | AccessReviewReviewerAssignmentPolicy.ByOwningTeamAdmin
  | AccessReviewReviewerAssignmentPolicy.ByApprovers;

const ACCESS_REVIEW_ASSIGNMENT_OPTIONS: Array<{
  value: AccessReviewReviewerAssignmentPolicyOption;
  label: string;
  description?: string;
  hasMode?: boolean;
}> = [
  {
    value: AccessReviewReviewerAssignmentPolicy.Manually,
    label: "Manually assign reviewers",
  },
  {
    value: AccessReviewReviewerAssignmentPolicy.ByManager,
    label: "Auto-assign to user's manager",
  },
  {
    value: AccessReviewReviewerAssignmentPolicy.ByOwningTeamAdmin,
    label: "Auto-assign to resource admins",
    description: "Assign reviews to admin owners",
    hasMode: true,
  },
  {
    value: AccessReviewReviewerAssignmentPolicy.ByApprovers,
    label: "Auto-assign to resource approvers",
    description:
      "Assign reviews to approving owners, including managers if applicable",
    hasMode: true,
  },
];

type AssignmentModeOptionKey = "round_robin" | "all";
interface AssignmentModeOption {
  value: AssignmentModeOptionKey;
  label: string;
  description: string;
}

const ACCESS_REVIEW_ASSIGNMENT_MODE_OPTIONS: AssignmentModeOption[] = [
  {
    value: "round_robin",
    label: "Round robin",
    description: "Assign reviews via round robin",
  },
  {
    value: "all",
    label: "All owner users",
    description: "Assign reviews to all users in the owner",
  },
];

const getPolicyAndModeForState = (
  policy: AccessReviewReviewerAssignmentPolicy
): {
  policy: AccessReviewReviewerAssignmentPolicyOption;
  mode?: AssignmentModeOptionKey;
} => {
  if (
    policy === AccessReviewReviewerAssignmentPolicy.Manually ||
    policy === AccessReviewReviewerAssignmentPolicy.ByManager
  ) {
    return {
      policy,
    };
  }
  if (
    policy === AccessReviewReviewerAssignmentPolicy.ByOwningTeamAdmin ||
    policy === AccessReviewReviewerAssignmentPolicy.ByApprovers
  ) {
    return {
      policy,
      mode: "round_robin",
    };
  }
  if (policy === AccessReviewReviewerAssignmentPolicy.ByOwningTeamAdminAll) {
    return {
      policy: AccessReviewReviewerAssignmentPolicy.ByOwningTeamAdmin,
      mode: "all",
    };
  }
  if (policy === AccessReviewReviewerAssignmentPolicy.ByApproversAll) {
    return {
      policy: AccessReviewReviewerAssignmentPolicy.ByApprovers,
      mode: "all",
    };
  }
  return {
    policy: AccessReviewReviewerAssignmentPolicy.Manually,
  };
};

const getPolicyForSubmit = (
  policy: AccessReviewReviewerAssignmentPolicyOption,
  mode?: AssignmentModeOptionKey
): AccessReviewReviewerAssignmentPolicy => {
  if (
    policy === AccessReviewReviewerAssignmentPolicy.Manually ||
    policy === AccessReviewReviewerAssignmentPolicy.ByManager
  ) {
    return policy;
  }
  if (policy === AccessReviewReviewerAssignmentPolicy.ByOwningTeamAdmin) {
    return mode === "all"
      ? AccessReviewReviewerAssignmentPolicy.ByOwningTeamAdminAll
      : AccessReviewReviewerAssignmentPolicy.ByOwningTeamAdmin;
  }
  if (policy === AccessReviewReviewerAssignmentPolicy.ByApprovers) {
    return mode === "all"
      ? AccessReviewReviewerAssignmentPolicy.ByApproversAll
      : AccessReviewReviewerAssignmentPolicy.ByApprovers;
  }
  return AccessReviewReviewerAssignmentPolicy.Manually;
};

const AccessReviewReviewerAssignmentPolicySelector = (
  props: AccessReviewReviewerAssignmentPolicySelectorProps
) => {
  const [showModal, setShowModal] = useState(false);

  const [
    modalAssignmentPolicy,
    setModalAssignmentPolicy,
  ] = useState<AccessReviewReviewerAssignmentPolicyOption>();
  const [
    modalAssignmentMode,
    setModalAssignmentMode,
  ] = useState<AssignmentModeOptionKey>();

  const handleOpenModal = () => {
    setShowModal(true);
    const {
      policy: initialPolicy,
      mode: initialMode,
    } = getPolicyAndModeForState(props.assignmentPolicy);
    setModalAssignmentPolicy(initialPolicy);
    setModalAssignmentMode(initialMode);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    if (!modalAssignmentPolicy || !props.onChange) return;
    props.onChange(
      getPolicyForSubmit(modalAssignmentPolicy, modalAssignmentMode)
    );
    setShowModal(false);
  };

  const getDisabled = () => {
    // Disable if the policy or mode is not set
    if (!modalAssignmentPolicy) {
      return true;
    }
    if (
      modalAssignmentPolicy ===
        AccessReviewReviewerAssignmentPolicy.ByOwningTeamAdmin ||
      modalAssignmentPolicy === AccessReviewReviewerAssignmentPolicy.ByApprovers
    ) {
      if (!modalAssignmentMode) {
        return true;
      }
    }
    // Disable if the policy is unchanged
    const policyForSubmit = getPolicyForSubmit(
      modalAssignmentPolicy,
      modalAssignmentMode
    );
    if (policyForSubmit === props.assignmentPolicy) {
      return true;
    }
    return false;
  };

  if (!props.onChange) {
    return (
      <FormGroup
        label={`Review auto-assignment: ${
          ACCESS_REVIEW_ASSIGNMENT_LABELS[props.assignmentPolicy]
        }`}
      />
    );
  }

  return (
    <>
      <FormGroup
        label={`Review auto-assignment: ${
          ACCESS_REVIEW_ASSIGNMENT_LABELS[props.assignmentPolicy]
        }`}
        rightLabelContent={
          <Button
            label="Select Policy"
            borderless={false}
            type="primary"
            size="lg"
            onClick={handleOpenModal}
          />
        }
      >
        Auto-assign reviews based on a pre-specified policy. If auto-assignment
        is on, specific assignments can still be manually adjusted after the
        access review is started.
      </FormGroup>
      <Modal
        isOpen={showModal}
        onClose={handleCancel}
        title="Review auto-assignment"
      >
        <Modal.Body>
          <p>
            Auto-assign reviews based on a pre-specified policy. If
            auto-assignment is on, specific assignments can still be manually
            adjusted after the access review is started.
          </p>
          <FormGroup label="Assignment Policy">
            <RadioGroup
              options={ACCESS_REVIEW_ASSIGNMENT_OPTIONS}
              value={ACCESS_REVIEW_ASSIGNMENT_OPTIONS.find(
                (option) => option.value === modalAssignmentPolicy
              )}
              onSelectValue={(newValue) =>
                setModalAssignmentPolicy(newValue?.value)
              }
              getOptionKey={(option) => option.value}
              getOptionLabel={(option) => option.label}
              getOptionDescription={(option) => option.description}
            />
          </FormGroup>
          {ACCESS_REVIEW_ASSIGNMENT_OPTIONS.find(
            (option) => option.value === modalAssignmentPolicy
          )?.hasMode ? (
            <FormGroup label="Assignment Mode">
              <RadioGroup
                options={ACCESS_REVIEW_ASSIGNMENT_MODE_OPTIONS}
                value={ACCESS_REVIEW_ASSIGNMENT_MODE_OPTIONS.find(
                  (option) => option.value === modalAssignmentMode
                )}
                onSelectValue={(newValue) =>
                  setModalAssignmentMode(newValue?.value)
                }
                getOptionKey={(option) => option.value}
                getOptionLabel={(option) => option.label}
                getOptionDescription={(option) => option.description}
              />
            </FormGroup>
          ) : null}
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Confirm"
          onPrimaryButtonClick={handleSave}
          primaryButtonDisabled={getDisabled()}
          secondaryButtonLabel="Cancel"
          onSecondaryButtonClick={handleCancel}
        />
      </Modal>
    </>
  );
};

export default AccessReviewReviewerAssignmentPolicySelector;
