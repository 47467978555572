import { FormRow, Icon, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";

interface Props {
  createdAt: string;
}

const SnapshotTimeRow = (props: Props) => {
  const snapshotTime = moment(new Date(props.createdAt));

  return (
    <FormRow title="Snapshot time">
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "center",
          gap: "sm",
        })}
      >
        {snapshotTime.format("MMMM Do YYYY, h:mm:ss a")}
        <Tooltip
          tooltipText="This is when a snapshot was taken for this access review. An access review
involves reviewing a historical snapshot of user access, not live access, because live
access is constantly changing."
        >
          <Icon name="info-circle" size="xs" color="gray600" />
        </Tooltip>
      </div>
    </FormRow>
  );
};

export default SnapshotTimeRow;
