import {
  useAccessReviewConnectionsStatsQuery,
  useAccessReviewGroupsStatsQuery,
  useAccessReviewResourcesStatsQuery,
} from "api/generated/graphql";
import AccessReviewStatV3 from "components/access_reviews/scope/AccessReviewStatV3";
import { ButtonV3, Skeleton } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import {
  AccessReviewFilters,
  buildAccessReviewFilter,
} from "views/access_reviews/create/utils";
import { AccessReviewConnectionsPreviewModal } from "views/access_reviews/settings/scope/AccessReviewConnectionsPreviewModal";
import { AccessReviewGroupsPreviewModal } from "views/access_reviews/settings/scope/AccessReviewGroupsPreviewModal";
import { AccessReviewResourcesPreviewModal } from "views/access_reviews/settings/scope/AccessReviewResourcesPreviewModal";

import * as styles from "./AccessReviewStatsV3.css";

type StartAccessReviewStatsProps = {
  filters: AccessReviewFilters;
  styleTextOnly?: boolean;
  includeGroupBindings?: boolean;
};

const StartAccessReviewStatsV3 = (props: StartAccessReviewStatsProps) => {
  const [showResourceSelectorModal, setShowResourceSelectorModal] = useState(
    false
  );
  const [showGroupSelectorModal, setShowGroupSelectorModal] = useState(false);
  const [
    showConnectionSelectorModal,
    setShowConnectionSelectorModal,
  ] = useState(false);

  const apiFilters = buildAccessReviewFilter(props.filters);

  const {
    data: filteredResourcesCount,
    loading: loadingResourcesCount,
  } = useAccessReviewResourcesStatsQuery({
    variables: {
      input: {
        filters: apiFilters,
      },
    },
  });

  const {
    data: filteredGroupsCount,
    loading: loadingGroupsCount,
  } = useAccessReviewGroupsStatsQuery({
    variables: {
      input: {
        filters: apiFilters,
        includeSourceGroups: props.includeGroupBindings,
      },
    },
  });

  const {
    data: filteredConnectionsCount,
    loading: loadingConnectionsCount,
  } = useAccessReviewConnectionsStatsQuery({
    variables: {
      input: {
        filters: apiFilters,
      },
    },
  });

  const countsLoading =
    loadingResourcesCount || loadingGroupsCount || loadingConnectionsCount;
  if (countsLoading) {
    return <Skeleton repeat={3} width="65px" />;
  }

  const numResourcesMatchingFilter =
    filteredResourcesCount?.countFilteredResources?.totalNumResources;
  const numGroupsMatchingFilter =
    filteredGroupsCount?.countFilteredGroups?.totalNumGroups;
  const numConnectionsMatchingFilter =
    filteredConnectionsCount?.countFilteredConnections.totalNumConnections;

  return (
    <>
      {showResourceSelectorModal && (
        <AccessReviewResourcesPreviewModal
          filters={apiFilters}
          onClose={() => setShowResourceSelectorModal(false)}
        />
      )}
      {showGroupSelectorModal && (
        <AccessReviewGroupsPreviewModal
          filters={apiFilters}
          includeGroupBindings={props.includeGroupBindings}
          onClose={() => setShowGroupSelectorModal(false)}
        />
      )}
      {showConnectionSelectorModal && (
        <AccessReviewConnectionsPreviewModal
          filters={apiFilters}
          onClose={() => setShowConnectionSelectorModal(false)}
        />
      )}
      {props.styleTextOnly ? (
        <span className={sprinkles({ gap: "md" })}>
          <ButtonV3
            type="link"
            onClick={() => setShowResourceSelectorModal(true)}
            label={`${numResourcesMatchingFilter} resources`}
          />
          ,{" "}
          <ButtonV3
            type="link"
            onClick={() => setShowGroupSelectorModal(true)}
            label={`${numGroupsMatchingFilter} groups`}
          />
          ,{" "}
          <ButtonV3
            type="link"
            onClick={() => setShowConnectionSelectorModal(true)}
            label={`${numConnectionsMatchingFilter} apps`}
          />{" "}
          selected
        </span>
      ) : (
        <div className={styles.statsContainer}>
          <AccessReviewStatV3
            type="resource"
            num={numResourcesMatchingFilter}
            icon="box"
            onClick={() => setShowResourceSelectorModal(true)}
          />
          <AccessReviewStatV3
            type="group"
            num={numGroupsMatchingFilter}
            icon="users"
            onClick={() => setShowGroupSelectorModal(true)}
          />
          <AccessReviewStatV3
            type="app"
            num={numConnectionsMatchingFilter}
            icon="dots-grid"
            onClick={() => setShowConnectionSelectorModal(true)}
          />
        </div>
      )}
    </>
  );
};

export default StartAccessReviewStatsV3;
