import {
  ConnectionType,
  EntityType,
  usePaginatedEntityDropdownQuery,
} from "api/generated/graphql";
import EntityOptionLabel from "components/dropdown/EntityOptionLabel";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { Banner, Label, Select } from "components/ui";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import { useMemo, useState } from "react";
import { connectionTypeHasNestedResources } from "utils/directory/connections";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { formatResourceBreadcrumb } from "utils/resources";

export type EntityOptionData = {
  id: string;
  type: EntityType.Group | EntityType.Resource;
  label: string;
  breadcrumb?: string;
  description: string;
  icon?: IconData;
  typeLabel?: string;
};

type Props = {
  id?: string;
  connectionId: string;
  selectedIds: string[];
  onSelect: (options: EntityOptionData[]) => void;
  onRemove: (options: EntityOptionData[]) => void;
  onError?: (error: "app-not-found" | "items-not-found") => void;
  includeOnlyRequestable?: boolean;
};

const PaginatedEntityDropdown = ({
  connectionId,
  selectedIds,
  onSelect,
  onRemove,
  ...props
}: Props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 500);

  const { data, error, loading, fetchMore } = usePaginatedEntityDropdownQuery({
    variables: {
      id: connectionId,
      searchQuery: debouncedSearchQuery,
      includeOnlyRequestable: props.includeOnlyRequestable,
    },
    onCompleted: (data) => {
      if (data.app.__typename === "AppNotFoundError") {
        props.onError?.("app-not-found");
      } else if (
        data.app.__typename === "App" &&
        data.app.items.items?.length === 0 &&
        searchQuery === ""
      ) {
        props.onError?.("items-not-found");
      }
    },
  });

  const items = useMemo(() => {
    return data?.app.__typename === "App" ? data.app.items.items ?? [] : [];
  }, [data?.app]);
  const cursor = data?.app.__typename === "App" ? data.app.items.cursor : null;
  const loadMore = cursor
    ? async () =>
        await fetchMore({
          variables: {
            id: connectionId,
            searchQuery: debouncedSearchQuery,
            cursor,
          },
        })
    : undefined;
  const appNotFound = data?.app.__typename === "AppNotFoundError";

  const options = useMemo(() => {
    const groupOrResource: EntityOptionData[] = [];
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      const connection = item.group?.connection ?? item.resource?.connection;
      const hasNestedResources = connection?.connectionType
        ? connectionTypeHasNestedResources(
            connection?.connectionType,
            connection?.metadata
            // TODO: move the following check to the connectionTypeHasNestedResources function
            // once we get rid of v2
          ) || connection?.connectionType === ConnectionType.AwsSso
        : false;

      if (item.group) {
        const typeInfo = getGroupTypeInfo(item.group.groupType);
        groupOrResource.push({
          id: item.group.id,
          type: EntityType.Group,
          label: item.group.name,
          description: item.group.description,
          typeLabel: typeInfo?.name,
          icon: {
            type: "name",
            icon: typeInfo?.iconName,
          },
        });
      } else if (item.resource) {
        const typeInfo = getResourceTypeInfo(item.resource.resourceType);
        groupOrResource.push({
          id: item.resource.id,
          type: EntityType.Resource,
          label: item.resource.name,
          breadcrumb: hasNestedResources
            ? formatResourceBreadcrumb(item.resource.ancestorPathToResource, 60)
            : undefined,
          description: item.resource.description,
          typeLabel: typeInfo?.name,
          icon: {
            type: "name",
            icon: typeInfo?.iconName,
          },
        });
      }
    }
    return groupOrResource;
  }, [items]);

  if (error || appNotFound) {
    logError(error, "Failed to fetch paginated entity dropdown items");
    return <Banner type="error" message={"App not found"} />;
  }

  const selectedValues = options.filter((item) =>
    selectedIds.includes(item.id)
  );

  return (
    <Select<EntityOptionData>
      id={props.id}
      value={selectedValues}
      multiple
      options={options}
      disabled={debouncedSearchQuery.length === 0 && options.length === 0}
      placeholder={
        options.length > 0
          ? "Add item to request"
          : "No requestable items found"
      }
      disableBuiltInFiltering
      searchable
      onInputChange={(value) => setSearchQuery(value)}
      loading={loading}
      onScrollToBottom={loadMore}
      onBulkSelectValue={(items, reason) => {
        switch (reason) {
          case "select-option":
            onSelect(items);
            break;
          case "remove-option":
            onRemove(items);
            break;
        }
      }}
      onSelectValue={(item, reason) => {
        switch (reason) {
          case "select-option":
            item && onSelect([item]);
            break;
          case "remove-option":
            item && onRemove([item]);
            break;
        }
      }}
      getOptionSublabel={(item) => item.description}
      getOptionLabel={(item) => item.label}
      renderOptionLabel={(item) => <EntityOptionLabel {...item} />}
      checkboxSize={"lg"}
      listboxFooter={{
        footer: (
          <div
            className={sprinkles({
              fontFamily: "body",
              padding: "md",
            })}
          >
            {selectedValues.length > 0 ? (
              <Label
                label={`Clear selection (${selectedValues.length})`}
                color="blue600V3"
                onClick={() => onRemove(selectedValues)}
              />
            ) : (
              <Label label="No items selected" color="gray700" />
            )}
          </div>
        ),
        sticky: true,
      }}
      noOptionsText="No items found"
    />
  );
};

export default PaginatedEntityDropdown;
