import {
  AppItemsSortByField,
  EntityType,
  GroupType,
  ResourceType,
  SortDirection,
  useItemsListSectionQuery,
  useResourceAncestorsQuery,
} from "api/generated/graphql";
import ColumnListItem, {
  ColumnListItemsSkeleton,
} from "components/column/ColumnListItem";
import ColumnListScroller from "components/column/ColumnListScroller";
import { ColumnSearchAndSort } from "components/column/ColumnSearchAndSort";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { useToast } from "components/toast/Toast";
import { Icon, ResourceTreeItem, Tabs } from "components/ui";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { isPropertyValue } from "utils/enums";
import { useWarnBeforeUnload } from "utils/hooks";
import { logError } from "utils/logging";
import { getResourceSublabel } from "utils/resources";
import { useTransitionTo, useURLSearchParam } from "utils/router/hooks";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import {
  ACCESS_OPTION_URL_KEY,
  APP_ID_URL_KEY,
  AppsContext,
  ITEM_TYPE_URL_KEY,
  OKTA_APP_ID_URL_KEY,
  SelectedItem,
} from "./AppsContext";
import * as styles from "./ResourceTreeSection.css";
import { useAccessOptionKey } from "./utils";

interface Props {
  appId: string;
}

export interface SortOption {
  label: string;
  value: { field: AppItemsSortByField; direction: SortDirection };
}

const SORT_OPTIONS: SortOption[] = [
  {
    label: "Name (A-Z)",
    value: {
      field: AppItemsSortByField.Name,
      direction: SortDirection.Asc,
    },
  },
  {
    label: "Name (Z-A)",
    value: {
      field: AppItemsSortByField.Name,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Newest first",
    value: {
      field: AppItemsSortByField.CreatedAt,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Oldest first",
    value: {
      field: AppItemsSortByField.CreatedAt,
      direction: SortDirection.Asc,
    },
  },
];

const ResourceTreeSection = (props: Props) => {
  const {
    selectedItems,
    toggleItem,
    selectItem,
    selectItems,
    clearItem,
    clearItems,
    isSelectMode,
    appDetailState,
    setAppDetailState,
    setBulkMode,
    bulkMode,
  } = useContext(AppsContext);
  const [accessOptionKey] = useAccessOptionKey();

  const transitionTo = useTransitionTo({
    preserveQueries: [
      ACCESS_OPTION_URL_KEY,
      APP_ID_URL_KEY,
      ITEM_TYPE_URL_KEY,
      OKTA_APP_ID_URL_KEY,
    ],
  });
  const { resourceId, groupId, oktaAppId } = useParams<
    Record<string, string>
  >();
  const [searchQuery, setSearchQuery] = useState<string>(
    appDetailState?.searchQuery || ""
  );
  const [sortOption, setSortOption] = useState(
    appDetailState?.sortOption || SORT_OPTIONS[0]
  );
  const [showGroups, setShowGroups] = useState<boolean>(!!groupId);
  const [selectAllToggle, setSelectAllToggle] = useState<boolean>(false);
  const [clearAllToggle, setClearAllToggle] = useState<boolean>(false);
  const [selectedItemType, setSelectedItemType] = useURLSearchParam(
    ITEM_TYPE_URL_KEY
  );
  const { displayErrorToast } = useToast();
  useWarnBeforeUnload(selectedItems.length > 0 && bulkMode !== "request");
  const [lastClicked, setLastClicked] = useState<number | null>(null);

  const [showTabs, setShowTabs] = useState(false);
  const showOnlyGroups = !searchQuery && showGroups;
  const showListResults =
    showGroups ||
    searchQuery.length > 0 ||
    (selectedItemType && selectedItemType.length > 0);

  const {
    data: ancestorsData,
    error: ancestorsError,
  } = useResourceAncestorsQuery({
    variables: {
      id: resourceId,
    },
    skip: !resourceId,
  });
  const selectedResourceAncestors =
    ancestorsData?.resourceAncestors.__typename == "ResourcesResult"
      ? ancestorsData?.resourceAncestors.resources
      : [];

  const {
    data,
    loading,
    error,
    fetchMore,
    previousData,
  } = useItemsListSectionQuery({
    variables: {
      id: props.appId,
      itemType: selectedItemType,
      access: accessOptionKey,
      parentResourceId: showListResults ? null : { resourceId: null },
      searchQuery,
      sortBy: sortOption.value,
    },
    notifyOnNetworkStatusChange: true,
  });

  const app = data?.app.__typename === "App" ? data.app : null;
  const previousApp =
    previousData?.app.__typename === "App" ? previousData.app : null;

  // set lastClicked index to null when data changes
  useEffect(() => {
    setLastClicked(null);
  }, [app?.items?.items]);
  // switch to correct tab when clicking into group or resource
  useEffect(() => {
    setShowGroups(!!groupId);
  }, [groupId]);

  // Prevent flash of item type selector when changing search/sort
  const itemTypes = app?.itemTypes ?? previousApp?.itemTypes ?? [];
  const itemTypesOptions = itemTypes.map((i) => ({
    label: i.displayText,
    value: i.itemType,
    icon: {
      type: "entity",
      entityType: i.itemType as ResourceType | GroupType,
    } as IconData,
  }));
  const groupExists = itemTypes.some((itemType) => {
    return isPropertyValue(GroupType, itemType.itemType);
  });
  const resourceExists = itemTypes.some((itemType) => {
    return isPropertyValue(ResourceType, itemType.itemType);
  });

  useEffect(() => {
    setShowTabs(
      !searchQuery && !selectedItemType && groupExists && resourceExists
    );
  }, [searchQuery, selectedItemType, groupExists, resourceExists]);
  useEffect(() => {
    if (groupExists && !resourceExists) {
      setShowGroups(true);
    } else if (resourceExists && !groupExists) {
      setShowGroups(false);
    }
  }, [groupExists, resourceExists]);

  const cursor = app?.items.cursor;
  const loadMore = cursor
    ? async () => {
        await fetchMore({
          variables: {
            cursor,
          },
        });
      }
    : undefined;

  const loadAll = async () => {
    let thisCursor = cursor;
    while (thisCursor) {
      const { data, error } = await fetchMore({
        variables: {
          cursor: thisCursor,
        },
      });
      if (error) {
        logError(error, "failed to load all items.");
        displayErrorToast("failed to load all items.");
        break;
      }
      thisCursor = data.app.__typename === "App" ? data.app.items.cursor : null;
    }
  };

  const selectAll = async () => {
    if (!showListResults) {
      setSelectAllToggle(true);
      setClearAllToggle(false);
      return;
    }

    let thisCursor = cursor;
    app?.items.items?.forEach((item) => {
      item.resource && selectItem(item.resource);
      item.group && selectItem(item.group);
    });

    while (thisCursor) {
      const { data, error } = await fetchMore({
        variables: {
          cursor: thisCursor,
        },
      });
      if (error) {
        logError(error, "failed to select all items.");
        displayErrorToast("failed to select all items.");
        break;
      }
      const items =
        data?.app.__typename === "App" ? data.app.items.items : null;
      items?.forEach((item) => {
        item.resource && selectItem(item.resource);
        item.group && selectItem(item.group);
      });
      thisCursor = data.app.__typename === "App" ? data.app.items.cursor : null;
    }
  };

  const clearAll = async () => {
    if (!showListResults) {
      setClearAllToggle(true);
      setSelectAllToggle(false);
      return;
    }

    app?.items.items?.forEach((item) => {
      item.resource && clearItem(item.resource);
      item.group && clearItem(item.group);
    });
  };

  if (loading && !data && !searchQuery) {
    return <ColumnListItemsSkeleton />;
  }

  if (ancestorsError) {
    logError(ancestorsError, "failed to get resource ancestors query");
    return <UnexpectedErrorPage error={ancestorsError} />;
  }

  if ((error || !data) && !searchQuery) {
    logError(error, "failed to get items list section query");
    return <UnexpectedErrorPage error={error} />;
  }

  // Load all root items for resource hierarchy.
  if (!showListResults) {
    loadAll();
  }

  const navigateToItem = (id: string, entityType: EntityType) => {
    setBulkMode(undefined);
    setAppDetailState({
      searchQuery,
      sortOption,
    });

    const searchParams = new URLSearchParams();
    if (oktaAppId) {
      searchParams.set(OKTA_APP_ID_URL_KEY, oktaAppId);
    }

    transitionTo({
      pathname: getResourceUrlNew({
        entityId: id,
        entityType: entityType,
      }),
      search: searchParams.toString(),
    });
  };

  const renderItems = () => {
    if (!app?.items.items) return <ColumnListItemsSkeleton />;

    if (showListResults) {
      const items = app?.items.items.flatMap((result, idx) => {
        if (!showOnlyGroups && result.resource) {
          const { id, name, resourceType, currentUserAccess } = result.resource;
          const hasAccess = currentUserAccess.resourceUsers.length > 0;
          return [
            <ColumnListItem
              key={id}
              label={name}
              sublabel={getResourceSublabel(result.resource)}
              onClick={() => navigateToItem(id, EntityType.Resource)}
              selected={resourceId === id}
              checkbox={
                isSelectMode
                  ? {
                      checked: selectedItems.some((item) => item.id === id),
                      onChange: () => {
                        if (result.resource) {
                          toggleItem(result.resource);
                          setLastClicked(idx);
                        }
                      },
                      onChangeShift: (checked: boolean) => {
                        if (lastClicked !== null) {
                          const min = Math.min(lastClicked, idx);
                          const max = Math.max(lastClicked, idx);
                          const resources = app?.items.items
                            ?.slice(min, max + 1)
                            .map((item) => item?.resource)
                            .filter(
                              (item) => item !== undefined
                            ) as SelectedItem[];
                          checked
                            ? selectItems(resources)
                            : clearItems(resources);
                        } else if (result.resource) {
                          toggleItem(result.resource);
                        }
                        setLastClicked(idx);
                      },
                    }
                  : undefined
              }
              largeIcon
              icon={{
                type: "entity",
                entityType: resourceType,
              }}
              rightContent={
                hasAccess ? (
                  <Icon name="check-circle" size="xs" color="green600" />
                ) : undefined
              }
            />,
          ];
        }
        if (result.group) {
          const { id, name, groupType, currentUserAccess } = result.group;
          const hasAccess = Boolean(currentUserAccess.groupUser);
          return [
            <ColumnListItem
              key={id}
              label={name}
              sublabel={getGroupTypeInfo(groupType)?.name}
              onClick={() => navigateToItem(id, EntityType.Group)}
              selected={groupId === id}
              checkbox={
                isSelectMode
                  ? {
                      checked: selectedItems.some((item) => item.id === id),
                      onChange: () => {
                        if (result.group) toggleItem(result.group);
                      },
                    }
                  : undefined
              }
              largeIcon
              icon={{
                type: "entity",
                entityType: groupType,
              }}
              rightContent={
                hasAccess ? (
                  <Icon name="check-circle" size="xs" color="green600" />
                ) : undefined
              }
            />,
          ];
        }
        return [];
      });

      return (
        <ColumnListScroller
          numRows={items.length}
          renderRow={(index) => items[index]}
          onLoadMore={loadMore}
          hasNextPage={cursor != null}
          loading={loading}
          emptyState={{ title: "No items to display." }}
        />
      );
    } else {
      if (app.items.items.length == 0) {
        return (
          <div className={sprinkles({ padding: "sm" })}>
            <div
              className={sprinkles({
                fontSize: "bodyLg",
                fontWeight: "medium",
              })}
            >
              {"No items to display."}
            </div>
          </div>
        );
      }
      return (
        <div className={styles.resourceTreeContainer}>
          {app?.items.items.flatMap((result) => {
            if (result.resource) {
              const {
                id,
                name,
                resourceType,
                currentUserAccess,
              } = result.resource;
              const hasAccess = currentUserAccess.resourceUsers.length > 0;
              return [
                <ResourceTreeItem
                  key={id}
                  appId={props.appId}
                  resource={result.resource}
                  label={name}
                  sublabel={
                    getResourceTypeInfo(result.resource.resourceType)?.fullName
                  }
                  icon={{ type: "entity", entityType: resourceType }}
                  largeIcon
                  selected={resourceId === id}
                  navigateToItem={navigateToItem}
                  sortOption={sortOption}
                  hasAccess={hasAccess}
                  initialShowSection
                  selectAll={selectAllToggle}
                  setSelectAll={setSelectAllToggle}
                  clearAll={clearAllToggle}
                  setClearAll={setClearAllToggle}
                  expandedResourceIDs={selectedResourceAncestors.map(
                    (ancestor) => ancestor.id
                  )}
                />,
              ];
            }
            return [];
          })}{" "}
        </div>
      );
    }
  };

  const allSelected = showListResults
    ? isSelectMode &&
      !!app?.items.items?.every((item) => {
        const id = item.group?.id || item.resource?.id;
        return selectedItems.some((i) => i.id === id);
      })
    : selectAllToggle;

  return (
    <>
      <ColumnSearchAndSort
        placeholder="Filter items"
        sortOptions={SORT_OPTIONS}
        sortBy={sortOption}
        setSortBy={setSortOption}
        initialSearchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterByOptions={
          itemTypes.length && itemTypes.length > 1
            ? itemTypesOptions
            : undefined
        }
        filterBy={itemTypesOptions.find((i) => i.value === selectedItemType)}
        setFilterBy={(value) => setSelectedItemType(value?.value ?? null)}
        checkbox={{
          checked: allSelected,
          onChange: allSelected ? clearAll : selectAll,
          loading: loading,
        }}
        trackName="items"
      />
      {showTabs && (
        <div
          className={sprinkles({
            display: "flex",
            justifyContent: "center",
            marginBottom: "sm",
          })}
        >
          <div className={sprinkles({ width: "100%" })}>
            <Tabs
              tabInfos={[
                {
                  title: "Resources",
                  isSelected: !showGroups,
                  onClick: () => setShowGroups(false),
                  icon: "cube",
                },
                {
                  title: "Groups",
                  isSelected: showGroups,
                  onClick: () => setShowGroups(true),
                  icon: "users",
                },
              ]}
              tabStyle={{ fillWidth: true }}
              round
            />
          </div>
        </div>
      )}
      {renderItems()}
    </>
  );
};

export default ResourceTreeSection;
