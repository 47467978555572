type SortBy<T> = (item: T) => string | number;
type ComparisonFn<T> = (a: T, b: T) => number;

/** Sorting helper. Ignores case and accents.
 *  Example usages:
 *  - sortedOptions = options.sort(by(option => option.name))
 *  - sortedOptions = options.sort(by())
 */
export const by = <T>(sortBy?: SortBy<T>): ComparisonFn<T> => (a, b) => {
  const aSortKey = sortBy ? sortBy(a) : a;
  const bSortKey = sortBy ? sortBy(b) : b;
  if (typeof aSortKey === "number" && typeof bSortKey === "number") {
    return aSortKey - bSortKey;
  }
  return String(aSortKey).localeCompare(String(bSortKey));
};
