import {
  ConnectionType,
  GroupDropdownPreviewFragment,
  usePaginatedUserOrGroupDropdownQuery,
  UserDropdownPreviewFragment,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Select } from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { logError } from "utils/logging";

import { getGroupTypeInfo } from "../label/GroupTypeLabel";
import { IconData } from "../ui/utils";

// Limits number of options rendered for performance.
const MAX_USERS_TO_DISPLAY = 200;

type UserDropdownProps = {
  id?: string;
  value?: UserDropdownPreviewFragment | GroupDropdownPreviewFragment;
  onChange: (
    newTarget?: UserDropdownPreviewFragment | GroupDropdownPreviewFragment
  ) => void;
  /**
   * Already selected users -- will be hidden within this dropdown.
   * Currently this component doesn't display a UI for selecting/deselecting
   * multiple users.
   */
  hiddenUserIds?: string[];
  /** Additional static options, like "Unset Manager". These will be prepended. */
  additionalUsers?: UserDropdownPreviewFragment[];
  includeSystemUser?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  /** Focus the input component on mount. */
  autoFocus?: boolean;
  placeholder?: string;
  selectOnly?: boolean;
  size?: "xs" | "sm" | "md";
  popperForceDownward?: PropsFor<typeof Select>["popperForceDownward"];
  popperHeight?: PropsFor<typeof Select>["popperHeight"];
  includeTargetGroups?: boolean;
};

/**
 * A searchable dropdown containing all users in the organization. This dropdown
 * statically limits the number of rendered options to preserve performance --
 * the user is expected to leverage search instead of scrolling to select the
 * desired user.
 */
export const PaginatedUserOrGroupDropdown = ({
  value,
  onChange,
  hiddenUserIds,
  additionalUsers,
  disabled,
  clearable = true,
  autoFocus,
  placeholder,
  selectOnly,
  size,
  popperForceDownward,
  popperHeight,
  includeSystemUser,
  includeTargetGroups,
  ...props
}: UserDropdownProps) => {
  const { authState } = useContext(AuthContext);
  const [searchQuery, setSearchQuery] = useState("");

  const {
    data: usersOrGroupsData,
    error: usersOrGroupsError,
    refetch,
  } = usePaginatedUserOrGroupDropdownQuery({
    variables: {
      searchQuery: searchQuery,
      maxNumEntries: MAX_USERS_TO_DISPLAY,
      includeSystemUser,
    },
  });
  if (usersOrGroupsError) {
    logError(usersOrGroupsError, "failed to list users and groups");
  }

  useEffect(() => {
    refetch({
      searchQuery,
      maxNumEntries: MAX_USERS_TO_DISPLAY,
      includeSystemUser,
    });
  }, [refetch, searchQuery, includeSystemUser]);

  const handleSearchInputChange = _.debounce((s: string) => {
    setSearchQuery(s);
  }, 250);

  const handleInputChange = (input: string) => {
    handleSearchInputChange(input);
  };

  let usersOrGroups: (
    | UserDropdownPreviewFragment
    | GroupDropdownPreviewFragment
  )[] = [];

  let users = usersOrGroupsData?.users.users ?? [];

  users = _.uniqBy([...(additionalUsers ?? []), ...users], (user) => user.id);
  if (hiddenUserIds) {
    users = users.filter((user) => !hiddenUserIds.includes(user.id));
  }
  usersOrGroups.push(...users);

  if (includeTargetGroups) {
    let groups = usersOrGroupsData?.groups.groups ?? [];

    groups = groups.slice().sort((a, b) => {
      return a.name.localeCompare(b.name);
    });
    usersOrGroups.unshift(...groups);
  }

  const handleSelectValue = (
    value?: UserDropdownPreviewFragment | GroupDropdownPreviewFragment
  ) => {
    onChange(value);
    if (selectOnly) handleSearchInputChange("");
  };

  const getOptionLabel = (
    option: UserDropdownPreviewFragment | GroupDropdownPreviewFragment
  ) => {
    switch (option.__typename) {
      case "User":
        return option.id === authState.user?.user.id
          ? `${option.fullName} (Me)`
          : option.fullName;
      case "Group":
        if (
          option.connection?.connectionType === ConnectionType.CustomConnector
        ) {
          return `${option.connection?.name ?? "Unknown connector"} / ${
            option.name
          }`;
        }
        return option.name;
    }
    return "";
  };

  const getIcon = (
    option: UserDropdownPreviewFragment | GroupDropdownPreviewFragment
  ): IconData | undefined => {
    switch (option.__typename) {
      case "User":
        return {
          type: "src",
          style: "rounded",
          icon: option.avatarUrl || defaultAvatarURL,
        };
      case "Group":
        if (option.connection) {
          if (
            option.connection.connectionType ===
              ConnectionType.CustomConnector &&
            option.connection.iconUrl
          ) {
            return {
              type: "src",
              icon: option.connection.iconUrl,
            };
          }
          return {
            type: "entity",
            entityType: option.connection.connectionType,
          };
        }
    }
  };

  const renderOptionLabel = (
    option: UserDropdownPreviewFragment | GroupDropdownPreviewFragment
  ) => {
    switch (option.__typename) {
      case "User":
        return (
          <span>
            {option.fullName}
            <span
              className={sprinkles({ color: "gray500", fontSize: "bodySm" })}
            >
              {" "}
              ({option.email})
            </span>
          </span>
        );
      case "Group":
        return (
          <span>
            {option.name}
            <span
              className={sprinkles({ color: "gray500", fontSize: "bodySm" })}
            >
              {" "}
              ({getGroupTypeInfo(option.groupType)?.name})
            </span>
          </span>
        );
    }
    return <></>;
  };

  return (
    <Select
      id={props.id}
      options={usersOrGroups || []}
      value={value}
      onChange={handleSelectValue}
      onInputChange={handleInputChange}
      clearable={clearable}
      disabled={disabled}
      getOptionLabel={getOptionLabel}
      renderOptionLabel={renderOptionLabel}
      getIcon={getIcon}
      getOptionSelected={(option, value) => option.id === value.id}
      disableBuiltInFiltering // Search is already handled server-side
      autoFocus={autoFocus}
      placeholder={placeholder}
      selectOnly={selectOnly}
      size={size}
      popperForceDownward={popperForceDownward}
      popperHeight={popperHeight}
    />
  );
};
