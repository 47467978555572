import {
  AccessReviewResourceFragment,
  Maybe,
  useAccessReviewResourceQuery,
} from "api/generated/graphql";
import React from "react";
import { useParams } from "react-router";
import { logError } from "utils/logging";
import AccessReviewResourceViewer from "views/access_reviews/resources/AccessReviewResourceViewer";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

const AccessReviewResourceViewerWrapper = () => {
  const { accessReviewResourceId } = useParams<Record<string, string>>();

  const { data, error, loading } = useAccessReviewResourceQuery({
    variables: {
      input: {
        id: accessReviewResourceId!,
      },
    },
  });

  let accessReviewResource: Maybe<AccessReviewResourceFragment> = null;
  if (data) {
    switch (data.accessReviewResource.__typename) {
      case "AccessReviewResourceResult":
        accessReviewResource = data.accessReviewResource.accessReviewResource;
        break;
      case "AccessReviewResourceNotFoundError":
        return <NotFoundPage />;
      default:
        logError(new Error(`failed to get access review resource`));
    }
  }

  if (loading) {
    return <ColumnContentSkeleton />;
  }

  if (!accessReviewResource || !accessReviewResource.accessReview || error) {
    logError(error, `failed to get access review resource`);
    return <UnexpectedErrorPage error={error} />;
  }

  return (
    <AccessReviewResourceViewer accessReviewResource={accessReviewResource} />
  );
};

export default AccessReviewResourceViewerWrapper;
