import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AuthType,
  ConnectionCredentialsInput,
  ConnectionMetadataInput,
  ConnectionType,
  Maybe,
  ThirdPartyProvider,
  useCreateConnectionMutation,
  Visibility,
} from "api/generated/graphql";
import customLogo from "assets/logos/custom-logo.png";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { SigningSecretModal } from "components/modals/SigningSecretModal";
import OwnerDropdown from "components/owners/OwnerDropdown";
import TicketProjectDropdown from "components/tickets/TicketProjectDropdown";
import TicketProviderDropdown from "components/tickets/TicketProviderDropdown";
import { useToast } from "components/toast/Toast";
import {
  Button,
  Checkbox,
  FileUpload,
  FormGroup,
  Icon,
  Input,
  RadioGroup,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import { randomAlphanumericString } from "utils/random";
import { validateUrl } from "views/connections/create/common";
import {
  CreateConnectionComponents as CreateConnectionComponentsV2,
  CreateConnectionView as CreateConnectionViewV2,
} from "views/connections/create/CreateConnectionComponents";
import {
  CreateConnectionComponentsV3,
  CreateConnectionViewV3,
} from "views/connections/create/CreateConnectionComponentsV3";
import { UploadCertButton } from "views/connections/create/UploadCertButton";
import VisibilitySelector from "views/visibility/VisibilitySelector";

const webhookLabelText = "Send webhook events";
const webhookDescription = `Every time a user gains or loses access to one of your custom app's resources
   in Opal, Opal will send a webhook event to the endpoint configured in your org's Admin Settings. You can
   use this webhook event to automatically add or remove the user from your end system. If no webhooks are
   configured, this custom app will act as a bookkeeping tool, and Opal will not pull data or push access
   changes.`;

const connectorLabelText = "Use custom app connector";
const connectorDescription = `Opal will use the connector to automatically pull the current resource list
   and user assignments from your end system, and to automatically push
   access changes made on Opal to your end system.`;

const ticketPropagationLabelText = "Create ticket to propagate access";
const ticketPropagationDescription = `If enabled, a ticket will be created for all access changes to this
   app's resources in Opal. The ticket assigns one of the resource's
   admins to manually make the access change on the end system.`;

const CreateCustom = () => {
  return <CreateCustomForm2 />;
};

export const CreateCustomForm2 = () => {
  const history = useHistory();
  const hasAppLevelVisibility = useFeatureFlag(FeatureFlag.AppLevelVisibility);
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const logEvent = useLogEvent();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displayErrorToast, displaySuccessToast } = useToast();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [visibility, setVisibility] = useState<Visibility>(Visibility.Global);
  const [visibilityGroupIds, setVisibilityGroupIds] = useState<
    string[] | undefined
  >([]);

  const [connectorId, setConnectorId] = useState("");
  const [connectorBaseUrl, setConnectorBaseUrl] = useState("");
  const [connectorSigningSecret, setConnectorSigningSecret] = useState("");
  const [connectionIcon, setConnectionIcon] = useState<File | null>(null);
  const [tlsMode, setTlsMode] = useState(true);
  const [tlsCaCertContent, setTlsCaCertContent] = useState<Maybe<string>>(null);
  const [supportsGroups, setSupportsGroups] = useState(false);
  const [supportsNestedResources, setSupportsNestedResources] = useState(false);

  const [ticketPropagationProvider, setTicketPropagationProvider] = useState<
    ThirdPartyProvider | undefined
  >(undefined);
  const [ticketProjectId, setTicketProjectId] = useState<string | undefined>();
  const [ticketProjectName, setTicketProjectName] = useState<
    string | undefined
  >();

  const [adminOwnerId, setAdminOwnerId] = useState<string | undefined>(
    undefined
  );

  const INTEGRATION_WEBHOOK = "webhook";
  const INTEGRATION_CONNECTOR = "connector";
  const INTEGRATION_TICKET_PROPAGATION = "ticketPropagation";

  const integrationOptions = [
    {
      value: INTEGRATION_WEBHOOK,
      label: webhookLabelText,
      description: webhookDescription,
    },
    {
      value: INTEGRATION_CONNECTOR,
      label: connectorLabelText,
      description: connectorDescription,
    },
    {
      value: INTEGRATION_TICKET_PROPAGATION,
      label: ticketPropagationLabelText,
      description: ticketPropagationDescription,
    },
  ];

  const [integrationToggle, setIntegrationToggle] = useState<
    typeof integrationOptions[0]
  >(integrationOptions[0]);
  const shouldShowServiceNow = useFeatureFlag(
    FeatureFlag.ServiceNowAccessAndPropagationTickets
  );

  const connectionPropagationTicketProviderDropdown = (
    <TicketProviderDropdown
      selectedTicketProvider={ticketPropagationProvider}
      onSelectTicketProvider={setTicketPropagationProvider}
      placeholder={"Select a ticket provider"}
      hiddenProviders={
        shouldShowServiceNow ? [] : [ThirdPartyProvider.ServiceNow]
      }
    />
  );

  const connectionPropagationTicketProjectDropdown = ticketPropagationProvider && (
    <TicketProjectDropdown
      ticketProvider={ticketPropagationProvider}
      onSelectTicketProject={(ticketProject) => {
        setTicketProjectId(ticketProject?.id);
        setTicketProjectName(ticketProject?.name);
      }}
      selectedTicketProjectId={ticketProjectId}
      selectedTicketProjectName={ticketProjectName}
    />
  );

  const [createConnectionMutation, { loading }] = useCreateConnectionMutation({
    refetchQueries: ["AppsListColumn", "Connections"],
  });

  const fieldUnset =
    name === "" ||
    description === "" ||
    !adminOwnerId ||
    (integrationToggle?.value === INTEGRATION_CONNECTOR &&
      (!connectorId || !connectorSigningSecret || !connectorBaseUrl)) ||
    (integrationToggle?.value === INTEGRATION_TICKET_PROPAGATION &&
      (!ticketPropagationProvider || !ticketProjectId));

  const onSubmit = async () => {
    if (integrationToggle?.value === INTEGRATION_CONNECTOR) {
      const urlErrorMessage = validateUrl(connectorBaseUrl || "", "URL");
      if (urlErrorMessage) {
        setErrorMessage(`Error: ${urlErrorMessage}`);
        return;
      }
    }
    try {
      const connectionType =
        integrationToggle?.value === INTEGRATION_CONNECTOR
          ? ConnectionType.CustomConnector
          : ConnectionType.Custom;
      let credentials: ConnectionCredentialsInput | null = null;
      let metadata: ConnectionMetadataInput | null = null;
      switch (connectionType) {
        case ConnectionType.CustomConnector:
          credentials = {
            authType: AuthType.CustomConnector,
            customConnector: {
              signingSecret: connectorSigningSecret,
            },
          };
          metadata = {
            connectionType: connectionType,
            customConnector: {
              identifier: connectorId,
              baseUrl: connectorBaseUrl,
              tlsMode: tlsMode,
              tlsCaCertContent: tlsCaCertContent,
              supportsGroups: supportsGroups,
              supportsNestedResources: supportsNestedResources,
            },
          };
          break;
        case ConnectionType.Custom:
          metadata = {
            connectionType: connectionType,
            propagationTicket: {
              enableTicketPropagation:
                integrationToggle?.value === INTEGRATION_TICKET_PROPAGATION,
              ticketProvider:
                integrationToggle?.value === INTEGRATION_TICKET_PROPAGATION
                  ? {
                      ticketProvider: ticketPropagationProvider!,
                      ticketProjectId: ticketProjectId!,
                      ticketProjectName: ticketProjectName ?? "",
                    }
                  : undefined,
            },
          };
          break;
      }
      const { data } = await createConnectionMutation({
        variables: {
          input: {
            name: name,
            description: description,
            connectionType: connectionType,
            adminOwnerId: adminOwnerId ?? "",
            visibility: visibility,
            visibilityGroupIds: visibilityGroupIds ?? [],
            importVisibility: Visibility.Global,
            credentials: credentials,
            connectionImage: connectionIcon,
            metadata: metadata,
          },
        },
      });
      switch (data?.createConnection.__typename) {
        case "CreateConnectionResult":
          history.replace(
            getResourceUrl(
              EntityTypeDeprecated.Connection,
              data.createConnection.connection.id
            )
          );
          logEvent({
            name: "apps_create_click",
            properties: {
              connectionType: connectionType,
            },
          });
          displaySuccessToast(`Success: ${name} app created`);
          break;
        case "ConnectionExistsError":
        case "ConnectionBadMetadataError":
        case "UserFacingError":
          logWarning(new Error(data.createConnection.message));
          setErrorMessage(data.createConnection.message);
          break;
        default:
          logError(new Error(`app creation failed`));
          setErrorMessage(`Error: app creation failed`);
      }
    } catch (error) {
      logError(error, "app creation failed");
      setErrorMessage(
        getModifiedErrorMessage("Error: app creation failed", error)
      );
    }
  };

  const CreateConnectionComponents = hasV3
    ? CreateConnectionComponentsV3
    : CreateConnectionComponentsV2;
  const CreateConnectionView = hasV3
    ? CreateConnectionViewV3
    : CreateConnectionViewV2;

  const ticketProjectDropdownLabel =
    ticketPropagationProvider === ThirdPartyProvider.ServiceNow
      ? "Catalog Item:"
      : "Ticket project:";

  return (
    <CreateConnectionView
      title={"Add your custom app"}
      logo={customLogo}
      onSubmit={onSubmit}
      submitDisabled={fieldUnset}
      submitLoading={loading}
    >
      <>
        <CreateConnectionComponents
          title={"Step 1"}
          subtitle={"Define app info"}
        >
          <>
            <FormGroup label="App name:">
              <Input
                onChange={setName}
                placeholder="Identifiable name of the custom app."
                value={name}
              />
            </FormGroup>
            <FormGroup label="App admin:">
              <OwnerDropdown
                selectedOwnerId={adminOwnerId}
                onSelectOwner={(owner) => setAdminOwnerId(owner?.id)}
                placeholder="Select an owner to own this app."
              />
            </FormGroup>
            <FormGroup label="Description:">
              <Input
                onChange={setDescription}
                placeholder="A brief description of the account to further inform people requesting access to it."
                value={description}
              />
            </FormGroup>
            {hasAppLevelVisibility && (
              <FormGroup label="Visibility:">
                <VisibilitySelector
                  visibility={visibility}
                  onChangeVisibility={(vis) => {
                    if (vis == Visibility.Global) {
                      setVisibility(Visibility.Global);
                      setVisibilityGroupIds([]);
                    } else {
                      setVisibility(Visibility.Team);
                    }
                  }}
                  visibilityGroups={visibilityGroupIds ?? []}
                  onChangeVisibilityGroups={setVisibilityGroupIds}
                />
              </FormGroup>
            )}
            <FormGroup label="App icon:">
              <div
                className={sprinkles({
                  display: "flex",
                  gap: "sm",
                  alignItems: "center",
                })}
              >
                {connectionIcon && (
                  <Icon
                    data={{
                      type: "src",
                      icon: URL.createObjectURL(connectionIcon),
                    }}
                    size="lg"
                  />
                )}
                <FileUpload
                  renderButton={(onClick) => (
                    <Button label="Upload icon" onClick={onClick} borderless />
                  )}
                  handleUpload={(file) => {
                    var filesize = file.size / 1024 / 1024; // MB
                    if (filesize > 2) {
                      displayErrorToast(
                        `Error: file size too large, must be less than 2MB`
                      );
                      return;
                    }

                    setConnectionIcon(file);
                  }}
                  accept={[".jpeg", ".jpg", ".png"]}
                />
              </div>
            </FormGroup>
          </>
        </CreateConnectionComponents>

        <CreateConnectionComponents
          title={"Step 2"}
          subtitle={"Configure integration with end system"}
          isLast
        >
          <>
            <p>
              Specify how Opal will pull and push access changes to your custom
              app's end system.
            </p>

            <RadioGroup
              options={integrationOptions}
              value={integrationToggle}
              onSelectValue={setIntegrationToggle}
              getOptionLabel={(option) => option.label}
              getOptionDescription={(option) => option.description}
              getOptionKey={(option) => option.value}
              marginBottom="md"
            />

            {integrationToggle?.value === INTEGRATION_CONNECTOR && (
              <CustomAppConnectorForm
                id={connectorId}
                setId={setConnectorId}
                baseUrl={connectorBaseUrl}
                setBaseUrl={setConnectorBaseUrl}
                signingSecret={connectorSigningSecret}
                setSigningSecret={setConnectorSigningSecret}
                setErrorMessage={setErrorMessage}
                setTLSCaCertContent={setTlsCaCertContent}
                setTlsMode={setTlsMode}
                tlsMode={tlsMode}
                supportsGroups={supportsGroups}
                setSupportsGroups={setSupportsGroups}
                supportsNestedResources={supportsNestedResources}
                setSupportsNestedResources={setSupportsNestedResources}
              />
            )}

            {integrationToggle?.value === INTEGRATION_TICKET_PROPAGATION && (
              <>
                <FormGroup label="Ticket provider:">
                  {connectionPropagationTicketProviderDropdown}
                </FormGroup>
                {ticketPropagationProvider && (
                  <FormGroup label={ticketProjectDropdownLabel}>
                    {connectionPropagationTicketProjectDropdown}
                  </FormGroup>
                )}
              </>
            )}

            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
            {!hasV3 && (
              <div
                className={sprinkles({
                  display: "flex",
                  justifyContent: "flex-end",
                })}
              >
                <Button
                  type="primary"
                  disabled={fieldUnset}
                  label={"Create Custom App"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </div>
            )}
          </>
        </CreateConnectionComponents>
      </>
    </CreateConnectionView>
  );
};

interface CustomAppConnectorFormProps {
  id: string;
  setId: (id: string) => void;
  baseUrl: string;
  setBaseUrl: (baseUrl: string) => void;
  signingSecret: string;
  setSigningSecret: (secret: string) => void;
  setErrorMessage: (message: string) => void;
  tlsMode: boolean;
  setTlsMode: (tlsMode: boolean) => void;
  setTLSCaCertContent: (tlsCaCertContent: Maybe<string>) => void;
  supportsGroups: boolean;

  setSupportsGroups: (supportsGroups: boolean) => void;
  supportsNestedResources: boolean;
  setSupportsNestedResources: (supportsNestedResources: boolean) => void;
}

const CustomAppConnectorForm = (props: CustomAppConnectorFormProps) => {
  const [showModal, setShowModal] = useState(false);

  const generateSecret = () => {
    props.setSigningSecret(randomAlphanumericString(32));
    setShowModal(true);
  };

  return (
    <div className={sprinkles({ marginBottom: "xl" })}>
      <FormGroup
        label={"Identifier"}
        infoTooltip={
          "Unique identifier for your custom app connector. Opal will provide this identifier with every API call."
        }
      >
        <Input onChange={props.setId} value={props.id} />
      </FormGroup>
      <FormGroup
        label={"Base URL"}
        infoTooltip={
          "The base url of your custom app connector. Opal will make requests to the specified API routes at this URL."
        }
      >
        <Input onChange={props.setBaseUrl} value={props.baseUrl} />
      </FormGroup>
      <FormGroup
        label="Signing Secret"
        infoTooltip="This is the secret used to sign requests sent to your custom app connector."
        rightLabelContent={
          <Button
            size="sm"
            label={props.signingSecret ? "Regenerate" : "Generate"}
            // TODO: Check if this is OK from a security perspective since we're generating the secret on the client
            //       and we're also letting users technically provide their own 32 byte string secret if they wanted
            onClick={generateSecret}
          />
        }
      >
        {props.signingSecret
          ? props.signingSecret.slice(0, 4) + "********"
          : null}
      </FormGroup>
      <FormGroup
        label="Connector Groups"
        infoTooltip="If enabled, Opal will query group endpoints to your connector."
      >
        <Checkbox
          size="sm"
          checked={props.supportsGroups}
          onChange={() => props.setSupportsGroups(!props.supportsGroups)}
          label="Enabled"
        />
      </FormGroup>
      <FormGroup
        label="Nested Resources"
        infoTooltip="If enabled, Opal will query nested resource endpoints to your connector."
      >
        <Checkbox
          size="sm"
          checked={props.supportsNestedResources}
          onChange={() =>
            props.setSupportsNestedResources(!props.supportsNestedResources)
          }
          label="Enabled"
        />
      </FormGroup>
      <FormGroup
        label="TLS Mode"
        infoTooltip={`If enabled, Opal will use TLS to connect to your
                      connector instance. Optionally, if you use self-signed
                      certificates, you may manually upload your
                      CA certificate. When disabled, Opal will use the protocol specified
                      in the hostname (http or https), without verifying
                      the certificate.`}
      >
        <div className={sprinkles({ display: "flex", gap: "md" })}>
          <Checkbox
            size="sm"
            checked={props.tlsMode}
            onChange={() => props.setTlsMode(!props.tlsMode)}
            label="Enabled"
          />
          {props.tlsMode && (
            <UploadCertButton
              buttonTitle={"Upload TLS CA cert"}
              uploadLabelId={"upload-tls-cert-ca-input"}
              setCertContent={props.setTLSCaCertContent}
              setErrorMessage={props.setErrorMessage}
            />
          )}
        </div>
      </FormGroup>
      <SigningSecretModal
        isModalOpen={showModal}
        signingSecret={props.signingSecret}
        onClose={() => {
          setShowModal(false);
        }}
        onSubmit={() => {
          setShowModal(false);
        }}
      />
    </div>
  );
};

export default CreateCustom;
