import {
  ConnectionType,
  EndUserGroupDetailsFragment,
  EntityType,
  GroupRequestStatusCountsResult,
  ResourceType,
  useGroupDetailsModalQuery,
  useOktaGroupIdpMappingQuery,
} from "api/generated/graphql";
import AccessInfoDisplay from "components/enduser_exp/AccessInfoDisplay";
import { AccessRequestCountDisplay } from "components/enduser_exp/AccessRequestCountDisplay";
import { GroupDetailsAccessTable } from "components/enduser_exp/GroupDetailsAccessTable";
import GroupBindingDetailPopover from "components/group_bindings/GroupBindingDetailPopover";
import { ButtonV3, Col, Modal, Row, Skeleton } from "components/ui";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import { useEffect } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { groupHasForfeitableRole } from "utils/groups";
import { usePageTitle, useRecordViewFor } from "utils/hooks";
import { useAccessRequestTransition } from "views/access_request/AccessRequestContext";
import { NO_PERMISSION_TO_REQUEST } from "views/apps/enduser_exp/constants";
import {
  formatRequestDataForItems,
  getGroupUserAccessInfo,
  getOwnerUserIconList,
} from "views/apps/enduser_exp/utils";
import { EndUserItemDetailsCard } from "views/common/EndUserItemDetailsCard";
import { ItemUsageDetails } from "views/common/ItemUsageDetails";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { isGroupBindingRedirectRequired } from "views/group_bindings/common";
import { useGroupBindingRequestRedirectModal } from "views/group_bindings/modals/GroupBindingRequestRedirectModal";
import { ForfeitGroupButton } from "views/groups/ForfeitGroupButton";

type GroupDetailsModalProps = {
  showModal: boolean;
  closeModal: () => void;
  groupId: string;
  showButtons?: boolean;
  forOktaAppId?: string;
};

export const GroupDetailsModal = ({
  groupId,
  showButtons = true,
  showModal,
  closeModal,
  forOktaAppId,
}: GroupDetailsModalProps) => {
  const logEvent = useLogEvent();
  const history = useHistory();
  const transitionToAccessRequest = useAccessRequestTransition();
  const hasOktaAlias = useFeatureFlag(FeatureFlag.OktaGroupAlias);
  const { data, loading, error } = useGroupDetailsModalQuery({
    variables: {
      id: groupId,
    },
  });
  const {
    data: idpMappingData,
    loading: idpMappingLoading,
    error: idpMappingError,
  } = useOktaGroupIdpMappingQuery({
    variables: {
      id: groupId,
      resourceId: forOktaAppId ?? "",
    },
    skip: !forOktaAppId,
  });
  const {
    open: openGroupBindingRedirectModal,
    maybeRenderModal: maybeRenderGroupBindingRedirectModal,
  } = useGroupBindingRequestRedirectModal();

  const group: EndUserGroupDetailsFragment | null =
    data && data.group.__typename === "GroupResult" ? data.group.group : null;

  let name = group?.name ?? "";

  if (hasOktaAlias && forOktaAppId && idpMappingData?.idpGroupMapping?.alias) {
    name = idpMappingData.idpGroupMapping?.alias;
  }

  const groupRequestsCount: GroupRequestStatusCountsResult | null =
    data &&
    data.groupRequestStatusCounts.__typename ===
      "GroupRequestStatusCountsResult"
      ? data?.groupRequestStatusCounts
      : null;

  const hasForfeitableAccess = groupHasForfeitableRole(
    group?.currentUserAccess
  );

  const pendingRequest = group?.currentUserAccess.pendingRequest;

  const groupOwners = getOwnerUserIconList(group?.adminOwner?.ownerUsers);
  usePageTitle(name);

  const fullDetailsURL = `/groups/${group?.id}`;

  useRecordViewFor("group", groupId);

  // Auto-close modal if group is not visible or doesn't exist
  useEffect(() => {
    if (data?.group.__typename === "GroupNotFoundError") {
      closeModal();
    }
  }, [data, closeModal]);

  const titleAccessory = group?.groupBinding ? (
    <GroupBindingDetailPopover
      groupId={group.id}
      groupBinding={group.groupBinding}
    />
  ) : undefined;

  return (
    <Modal
      isOpen={showModal}
      title="More Info"
      onClose={(event) => {
        event.stopPropagation();
        closeModal();
      }}
      maxWidth="md"
      fullWidth={true}
    >
      <Modal.Body>
        {(loading || idpMappingLoading) && <Skeleton />}
        {(error || idpMappingError) && <UnexpectedErrorPage />}
        {group && (
          <div
            className={sprinkles({
              display: "flex",
              flexDirection: "column",
              gap: "xl",
              paddingBottom: "xl",
            })}
          >
            <EndUserItemDetailsCard
              icon={{
                type: "entity",
                entityType: group?.groupType || ConnectionType.Custom,
              }}
              title={name}
              titleAccessory={titleAccessory}
              subtitle={
                group.connection ? `${group.connection?.name}/` : undefined
              }
              description={group?.description}
              isModal={true}
              detailsURL={fullDetailsURL}
              actions={
                <>
                  {showButtons && (
                    <ButtonV3
                      label="Request"
                      type="main"
                      size="xs"
                      disabled={!group.isRequestable}
                      disabledTooltip={NO_PERMISSION_TO_REQUEST}
                      onClick={(event) => {
                        if (isGroupBindingRedirectRequired(group)) {
                          openGroupBindingRedirectModal(
                            group.id,
                            group.groupBinding!.id
                          );
                          return;
                        }
                        transitionToAccessRequest(
                          {
                            ...formatRequestDataForItems(
                              {
                                entityId: group.id,
                                entityType: EntityType.Group,
                              },
                              forOktaAppId
                            ),
                            appId: forOktaAppId
                              ? forOktaAppId
                              : group.connectionId,
                          },
                          event
                        );
                      }}
                    />
                  )}
                  {hasForfeitableAccess && showButtons && (
                    <ForfeitGroupButton
                      group={group}
                      hasV3
                      noIcon
                      buttonSize="xs"
                    />
                  )}
                  {pendingRequest && showButtons && (
                    <ButtonV3
                      label="View Request"
                      type="defaultSecondary"
                      size="xs"
                      onClick={() => {
                        logEvent({ name: "apps_pending_click" });
                        history.push(
                          `${getResourceUrl(
                            EntityTypeDeprecated.Request,
                            pendingRequest?.id
                          )}?o=1`
                        );
                      }}
                    />
                  )}
                </>
              }
              rightContent={
                forOktaAppId ? (
                  <ItemUsageDetails
                    teamUsageCount={group?.accessStats?.teamAccessCount}
                    titleUsageCount={group?.accessStats?.titleAccessCount}
                    entityType={EntityType.Resource}
                    resourceType={ResourceType.OktaRole}
                    ownerIcons={groupOwners}
                  />
                ) : (
                  <ItemUsageDetails
                    teamUsageCount={group?.accessStats?.teamAccessCount}
                    titleUsageCount={group?.accessStats?.titleAccessCount}
                    entityType={EntityType.Group}
                    groupType={group?.groupType}
                    ownerIcons={groupOwners}
                  />
                )
              }
            />
            <Row>
              <Col>
                <AccessRequestCountDisplay
                  approvedCount={
                    groupRequestsCount?.requestStatusCounts.approved || 0
                  }
                  deniedCount={
                    groupRequestsCount?.requestStatusCounts.denied || 0
                  }
                  orgUsersCount={group?.accessStats?.totalAccessCount || 0}
                  groupsCount={group?.containingGroups?.length || 0}
                  groups={
                    group?.containingGroups
                      .map((item) => {
                        return {
                          key: item.containingGroupId,
                          label: item.containingGroup?.name || "",
                          icon: {
                            type: "entity",
                            entityType:
                              item.containingGroup?.connection?.connectionType,
                            style: "rounded",
                          } as IconData,
                        };
                      })
                      .sort((a, b) =>
                        a.label
                          .toLowerCase()
                          .localeCompare(b.label.toLowerCase())
                      )
                      .filter((item) => item !== null) || []
                  }
                />
              </Col>
              <Col>
                <AccessInfoDisplay
                  {...getGroupUserAccessInfo(group.currentUserAccess)}
                />
              </Col>
            </Row>
            <GroupDetailsAccessTable
              resources={group.groupResources || []}
              groups={group.containingGroups || []}
            />
            {maybeRenderGroupBindingRedirectModal()}
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
