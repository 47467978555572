import {
  BASE_NODE_HEIGHT,
  NODE_STARTING_Y,
  TreeNodeData,
} from "components/viz/common";
import React, { useContext } from "react";
import useLogEvent from "utils/analytics";

import { GraphContext } from "../contexts/GraphContext";
import { getNodeWidth } from "../utils";
import * as styles from "./Node.css";

interface NodeProps {
  data: TreeNodeData;
  onClick?: () => void;
  onCmdClick?: () => void;
}

const Node: React.FC<NodeProps> = React.memo(
  ({ onClick, onCmdClick, children, data }) => {
    const { graphState } = useContext(GraphContext);
    const logEvent = useLogEvent();

    const neighbors = graphState.neighbors;
    const selectedIds = [
      ...graphState.selectedUserIds,
      ...graphState.selectedGroupIds,
      ...graphState.selectedResourceIds,
    ];
    const selected = selectedIds.includes(data.nodeId);

    const isManaged = "isManaged" in data ? !!data.isManaged : true;

    let backgrounded = selectedIds.length > 0;
    selectedIds.forEach((selectedId) => {
      if (selectedId === data.nodeId) {
        backgrounded = false;
        return;
      }
      const connectedNodes = neighbors[selectedId] ?? [];
      if (connectedNodes.includes(data.nodeId)) {
        backgrounded = false;
        return;
      }
    });

    const clickable = "expandable" in data || isManaged;

    const width = getNodeWidth(data);

    return (
      <>
        <rect
          rx={8}
          ry={8}
          y={NODE_STARTING_Y}
          height={data.height}
          width={width}
          strokeDasharray={isManaged ? undefined : 6}
          className={styles.nodeRect({
            clickable:
              clickable && (onClick !== undefined || onCmdClick !== undefined),
            selected,
            backgrounded,
            unmanaged: !isManaged,
          })}
          onClick={(e) => {
            e.preventDefault();
            if (clickable) {
              if (e.metaKey) {
                onCmdClick && onCmdClick();
              } else if (onClick) {
                onClick();
                logEvent({
                  name: "viz_node_click",
                  properties: {
                    entityName: "name" in data ? data.name ?? "" : "",
                    nodeID: data.nodeId,
                    entityType: data.kind,
                    selected,
                  },
                });
              }
            }
          }}
        />
        {/* Layout nodes from the top left */}
        <g
          className={styles.nodeBody}
          transform={`translate(0,-${BASE_NODE_HEIGHT / 2})`}
        >
          {children}
        </g>
      </>
    );
  }
);

export default Node;
