import { TagFragment, useAddGroupTagsMutation } from "api/generated/graphql";
import GroupSearchDropdown, {
  GroupSelectData,
} from "components/dropdown/GroupSearchDropdown";
import * as styles from "components/modals/AddItemModal.css";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Button, EntityIcon, Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { logError, logWarning } from "utils/logging";

interface Props {
  tag: TagFragment;
  onClose: () => void;
}

const TagAddGroupsModal = (props: Props) => {
  const [groupsToAdd, setGroupsToAdd] = useState<GroupSelectData[]>([]);
  const [error, setError] = useState("");

  const { displaySuccessToast } = useToast();
  const [addGroupTags, { loading }] = useAddGroupTagsMutation();

  const handleSubmit = async () => {
    try {
      const { data } = await addGroupTags({
        variables: {
          input: {
            groupTags: groupsToAdd.map((group) => ({
              tagId: props.tag.id,
              groupId: group.id,
            })),
          },
        },
        refetchQueries: ["Group", "Tag"],
      });
      switch (data?.addGroupTags.__typename) {
        case "AddGroupTagsResult":
          displaySuccessToast("Success: tag added to groups");
          props.onClose();
          break;
        case "GroupNotFoundError":
        case "TagNotFoundError":
          logWarning(new Error(data.addGroupTags.message));
          setError(data.addGroupTags.message);
          break;
        default:
          logError(new Error(`failed to add tag to groups`));
          setError("Error: failed to add tag to groups");
      }
    } catch (err) {
      logError(err, "Failed to add tag to groups");
      setError("Failed to add tag to groups");
    }
  };

  const disabledGroupIds: string[] = props.tag.tagGroups.map(
    (tagGroup) => tagGroup.groupId
  );

  return (
    <Modal isOpen title="Add Tag to Groups" onClose={props.onClose}>
      <Modal.Body>
        {error && <ModalErrorMessage errorMessage={error} />}
        <GroupSearchDropdown
          style="search"
          selectedGroupIds={groupsToAdd.map((group) => group.id)}
          onSelect={({ actionType, groups }) => {
            setGroupsToAdd((prev) => {
              if (actionType === "select-option") {
                return [...prev, ...groups];
              } else {
                const groupIdSet = new Set(groups.map((g) => g.id));
                return prev.filter((g) => !groupIdSet.has(g.id));
              }
            });
          }}
          disabledGroupIds={disabledGroupIds}
        />
        <div className={styles.itemsContainer}>
          {groupsToAdd.map((group) => (
            <div className={styles.itemRow} key={group.id}>
              <EntityIcon type={group.groupType} />
              <div className={sprinkles({ flexGrow: 1 })}>
                <div className={sprinkles({ fontSize: "labelMd" })}>
                  {group.name}
                </div>
              </div>
              <Button
                leftIconName="trash-2"
                borderless
                type="error"
                onClick={() => {
                  setGroupsToAdd((prev) => {
                    return prev.filter((g) => g.id !== group.id);
                  });
                }}
                size="sm"
                round
              />
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Submit"
        primaryButtonDisabled={groupsToAdd.length === 0}
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLoading={loading}
      />
    </Modal>
  );
};

export default TagAddGroupsModal;
