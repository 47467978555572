import {
  HrIdpStatus,
  IdpStatusFilter,
  ManagerFilter,
  TeamFilter,
  TitleFilter,
  useManagersQuery,
  useTeamsQuery,
  useTitlesQuery,
} from "api/generated/graphql";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import FacetFilter from "components/ui/facet_filter/FacetFilter";
import { IconName } from "components/ui/icon/Icon";
import { IconData } from "components/ui/utils";
import _ from "lodash";
import React, { useEffect } from "react";

const facets: { iconName?: IconName; label: string }[] = [
  {
    iconName: "user-square",
    label: "Manager",
  },
  {
    iconName: "users",
    label: "Team",
  },
  {
    iconName: "role",
    label: "Title",
  },
  {
    iconName: "cloud-blank",
    label: "IDP Status",
  },
];

export interface UsersFilterData {
  // Hacky because it doesn't match the structure of managerFilter,
  // we just stick the manager name in here for display purposes.
  hackyManagerFilterName?: string;
  managerFilter?: ManagerFilter;
  teamFilter?: TeamFilter;
  titleFilter?: TitleFilter;
  idpStatusFilter?: IdpStatusFilter;
}

const UsersFilter = ({
  onSetFilter,
}: {
  onSetFilter: (newFilter: UsersFilterData) => void;
}) => {
  const [searchQuery, setSearchQuery] = React.useState("");
  const [selectedFacet, setSelectedFacet] = React.useState<typeof facets[0]>();

  useEffect(() => {
    if (selectedFacet === undefined) {
      setSearchQuery("");
    }
  }, [selectedFacet]);

  const {
    data: managersData,
    loading: managersLoading,
    fetchMore: managersFetchMore,
  } = useManagersQuery({
    variables: {
      searchQuery: searchQuery ? searchQuery : undefined,
    },
    skip: selectedFacet?.label !== "Manager",
  });

  const {
    data: teamsData,
    loading: teamsLoading,
    fetchMore: teamsFetchMore,
  } = useTeamsQuery({
    variables: {
      searchQuery: searchQuery ? searchQuery : undefined,
    },
    skip: selectedFacet?.label !== "Team",
  });

  const {
    data: titlesData,
    loading: titlesLoading,
    fetchMore: titlesFetchMore,
  } = useTitlesQuery({
    variables: {
      searchQuery: searchQuery ? searchQuery : undefined,
    },
    skip: selectedFacet?.label !== "Title",
  });

  const loading = managersLoading || teamsLoading || titlesLoading;

  const fetchMore = () => {
    if (selectedFacet?.label === "Manager") {
      managersFetchMore({
        variables: {
          searchQuery: searchQuery ? searchQuery : undefined,
          cursor: managersData?.users.cursor,
        },
      });
    } else if (selectedFacet?.label === "Team") {
      teamsFetchMore({
        variables: {
          searchQuery: searchQuery ? searchQuery : undefined,
          cursor: teamsData?.teams.cursor,
        },
      });
    } else if (selectedFacet?.label === "Title") {
      titlesFetchMore({
        variables: {
          searchQuery: searchQuery ? searchQuery : undefined,
          cursor: titlesData?.titles.cursor,
        },
      });
    }
  };

  let cursor: String | null | undefined = undefined;
  let results: {
    label: string;
    onClick: () => void;
    icon?: IconData;
  }[] = [];
  if (selectedFacet?.label === "Manager") {
    results =
      managersData?.users.users.map((result) => ({
        label: result.fullName,
        icon: {
          type: "src",
          style: "rounded",
          icon: result.avatarUrl || defaultAvatarURL,
          fallbackIcon: defaultAvatarURL,
        },
        onClick: () => {
          setSearchQuery("");
          setSelectedFacet(undefined);
          onSetFilter({
            hackyManagerFilterName: result.fullName,
            managerFilter: {
              managerId: result.id,
            },
          });
        },
      })) ?? [];
    if (searchQuery === "") {
      results.unshift({
        label: "None set",
        icon: {
          type: "name",
          icon: "none",
        },
        onClick: () => {
          setSearchQuery("");
          setSelectedFacet(undefined);
          onSetFilter({
            managerFilter: {
              managerUnset: true,
            },
          });
        },
      });
    }
    cursor = managersData?.users.cursor;
  } else if (selectedFacet?.label === "Team") {
    results =
      teamsData?.teams.teams.map((result) => ({
        label: result.name,
        icon: {
          type: "name",
          icon: "users",
        },
        onClick: () => {
          setSearchQuery("");
          setSelectedFacet(undefined);
          onSetFilter({
            teamFilter: {
              teams: [result.name],
            },
          });
        },
      })) ?? [];
    if (searchQuery === "") {
      results.unshift({
        label: "None set",
        icon: {
          type: "name",
          icon: "none",
        },
        onClick: () => {
          setSearchQuery("");
          setSelectedFacet(undefined);
          onSetFilter({
            teamFilter: {
              teamUnset: true,
            },
          });
        },
      });
    }
    cursor = teamsData?.teams.cursor;
  } else if (selectedFacet?.label === "Title") {
    results =
      titlesData?.titles.titles.map((result) => ({
        label: result.name,
        icon: {
          type: "name",
          icon: "role",
        },
        onClick: () => {
          setSearchQuery("");
          setSelectedFacet(undefined);
          onSetFilter({
            titleFilter: {
              titles: [result.name],
            },
          });
        },
      })) ?? [];
    if (searchQuery === "") {
      results.unshift({
        label: "None set",
        icon: {
          type: "name",
          icon: "none",
        },
        onClick: () => {
          setSearchQuery("");
          setSelectedFacet(undefined);
          onSetFilter({
            titleFilter: {
              titleUnset: true,
            },
          });
        },
      });
    }
    cursor = titlesData?.titles.cursor;
  } else if (selectedFacet?.label === "IDP Status") {
    results = Object.keys(HrIdpStatus)
      .filter((key) => {
        if (!searchQuery) {
          return true;
        } else {
          const searchQueryRegex = new RegExp(searchQuery, "i");
          if (key.match(searchQueryRegex)) {
            return true;
          }
        }
        return false;
      })
      .map((key) => ({
        label: _.startCase(key),
        icon: {
          type: "name",
          icon: "cloud-blank",
        },
        onClick: () => {
          setSearchQuery("");
          setSelectedFacet(undefined);
          onSetFilter({
            idpStatusFilter: {
              statuses: [HrIdpStatus[key as keyof typeof HrIdpStatus]],
            },
          });
        },
      }));
    if (searchQuery === "") {
      results.unshift({
        label: "None set",
        icon: {
          type: "name",
          icon: "none",
        },
        onClick: () => {
          setSearchQuery("");
          setSelectedFacet(undefined);
          onSetFilter({
            idpStatusFilter: {
              statusUnset: true,
            },
          });
        },
      });
    }
  }

  return (
    <FacetFilter
      facetOptions={facets}
      onSearch={(query) => {
        setSearchQuery(query);
      }}
      searchLoading={loading}
      searchOptions={results}
      onSelectedFacet={(facet) => {
        setSelectedFacet(facet);
      }}
      onLoadMore={fetchMore}
      hasNextPage={cursor !== null}
    />
  );
};
export default UsersFilter;
