import { useSignOutMutation } from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { useContext } from "react";
import { useLocation } from "react-router-dom";
import { useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";

export const SignOut = () => {
  const location = useLocation();
  const { authState } = useContext(AuthContext);

  const [signOut] = useSignOutMutation({
    onError: (error) => {
      logError(error, `failed to sign out`);
    },
    onCompleted: () => {
      // Complete sign out by clearing state on Auth0 server and redirecting to sign in page.
      authState.authClient?.signOut(location.search);
    },
  });

  useMountEffect(() => {
    async function signOutWrapper() {
      try {
        const { data } = await signOut();
        switch (data?.signOut.__typename) {
          case "SignOutResult":
            // Complete sign out by clearing state on Auth0 server and redirecting to sign in page.
            authState.authClient?.signOut(location.search);
            break;
          default:
            logError(new Error(`failed to sign out`));
        }
      } catch (error) {
        logError(error, `failed to sign out`);
      }
    }

    signOutWrapper();
  });

  return null;
};

export default SignOut;
