import { NetworkStatus } from "@apollo/client";
import {
  Maybe,
  SupportTicketFragment,
  SupportTicketPreviewFragment,
  ThirdPartyProvider,
  useTicketsQuery,
} from "api/generated/graphql";
import { getLogoByThirdPartyProvider, Icon } from "components/label/Label";
import modalsStyles from "components/modals/Modals.module.scss";
import { Button, FormGroup, Select, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import React, { useState } from "react";
import { logError } from "utils/logging";

type SupportTicketTooltipProps = {
  supportTicket: Maybe<SupportTicketPreviewFragment> | undefined;
};

export const SupportTicketTooltip = (props: SupportTicketTooltipProps) => {
  if (!props.supportTicket) {
    return null;
  }

  return (
    <Tooltip
      tooltipText={
        "This resource is bound to " +
        _.startCase(_.lowerCase(props.supportTicket.thirdPartyProvider)) +
        " ticket " +
        props.supportTicket.identifier +
        "."
      }
      tooltipTitleText={props.supportTicket.identifier}
    >
      <div className={modalsStyles.tooltip}>
        <Icon
          icon={getLogoByThirdPartyProvider(
            props.supportTicket.thirdPartyProvider
          )}
        />
      </div>
    </Tooltip>
  );
};

type SupportTicketFieldProps = {
  thirdPartyProvider: ThirdPartyProvider;
  setGetTicketsErrorMessage: (getTicketsErrorMessage?: string) => void;
  supportTicket?: SupportTicketPreviewFragment;
  setSupportTicket: (supportTicket?: SupportTicketPreviewFragment) => void;
  required?: boolean;
};

export const SupportTicketsField = (props: SupportTicketFieldProps) => {
  const {
    data: ticketsData,
    error: ticketsError,
    loading: ticketsLoading,
    refetch: ticketsRefetch,
    networkStatus: ticketsNetworkStatus,
  } = useTicketsQuery({
    variables: {
      input: {
        thirdPartyProvider: props.thirdPartyProvider,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  React.useEffect(() => {
    ticketsRefetch();
  }, [ticketsRefetch]);

  const [projectNameFilter, setProjectNameFilter] = useState(
    props.supportTicket?.projectName
  );

  let tickets: Maybe<SupportTicketFragment[]> = null;
  let projectNames: string[] | null = null;
  if (ticketsData) {
    switch (ticketsData.supportTickets.__typename) {
      case "SupportTicketsResult":
        tickets = ticketsData.supportTickets.tickets;
        projectNames = _.uniq(
          tickets?.map((ticket) => ticket.projectName) || []
        );
        break;
      case "SupportTicketAPIKeyNotFoundError":
        props.setGetTicketsErrorMessage(ticketsData.supportTickets.message);
        break;
      case "UserFacingError":
        props.setGetTicketsErrorMessage(ticketsData.supportTickets.message);
        break;
      default:
        logError(new Error("failed to load ticket"));
    }
  } else if (ticketsError) {
    props.setGetTicketsErrorMessage("Error: failed to load ticket");
    logError(ticketsError, `failed to load tickets`);
  }

  return (
    <FormGroup
      label={_.startCase(_.lowerCase(props.thirdPartyProvider)) + " Tickets"}
      rightLabelContent={
        <Tooltip tooltipText="Tickets assigned to you may take a few seconds to become available to Opal.">
          <Button
            leftIconName="refresh-cw"
            onClick={async () => {
              await ticketsRefetch();
            }}
            loading={ticketsNetworkStatus === NetworkStatus.refetch}
            outline
            round
          />
        </Tooltip>
      }
      required={props.required}
    >
      {projectNames && projectNames.length > 1 && (
        <div className={sprinkles({ marginBottom: "sm" })}>
          <Select
            value={projectNameFilter}
            onChange={(newValue) => {
              setProjectNameFilter(newValue);
            }}
            options={projectNames}
            loading={ticketsLoading}
            getOptionLabel={(option) => option}
            placeholder="Search for projects"
          />
        </div>
      )}
      <Select
        value={tickets?.find(
          (ticket) => ticket.remoteId === props.supportTicket?.remoteId
        )}
        onChange={(newValue) => {
          if (newValue) {
            props.setSupportTicket(newValue);
          }
        }}
        options={
          tickets?.filter((ticket) =>
            projectNameFilter ? ticket.projectName === projectNameFilter : true
          ) || []
        }
        loading={ticketsLoading}
        getOptionLabel={(tix) => `${tix.identifier}: ${tix.summary}`}
        placeholder="Search for tickets"
      />
    </FormGroup>
  );
};
