import {
  Maybe,
  PropagationStatusCode,
  PropagationTaskType,
  RequestStatus,
} from "api/generated/graphql";
import clsx from "clsx";
import styles from "components/requests/RequestPills.module.scss";
import React, { ReactElement } from "react";
import * as Icons from "react-feather";

export const RequestedPill = () => {
  return (
    <BasePill
      icon={<Icons.Check strokeWidth={3} />}
      text={"Requested"}
      ok={true}
    />
  );
};

export type ReviewedPillProps = {
  status: RequestStatus;
};

export const ReviewedPill = (props: ReviewedPillProps) => {
  let icon = null;
  switch (props.status) {
    case RequestStatus.Approved:
      icon = <Icons.Check strokeWidth={3} />;
      break;
    case RequestStatus.Denied:
      icon = <Icons.X strokeWidth={3} />;
      break;
    case RequestStatus.Canceled:
      icon = <Icons.X strokeWidth={3} />;
      break;
  }

  return (
    <BasePill
      icon={icon}
      text={props.status === RequestStatus.Pending ? "Review" : "Reviewed"}
      ok={props.status === RequestStatus.Approved}
      alert={props.status === RequestStatus.Denied}
      neutral={
        props.status === RequestStatus.Pending ||
        props.status === RequestStatus.Canceled
      }
    />
  );
};

export type PropagatedPillProps = {
  status?: PropagationStatusCode;
  taskType?: PropagationTaskType;
};

export const PropagatedPill = (props: PropagatedPillProps) => {
  if (props.status === undefined) {
    return <BasePill icon={null} text={"Propagation"} neutral={true} />;
  }
  if (props.status === PropagationStatusCode.Success) {
    return (
      <BasePill
        icon={<Icons.Check strokeWidth={3} />}
        text={"Propagated"}
        ok={true}
      />
    );
  }
  if (props.status === PropagationStatusCode.Pending) {
    return (
      <BasePill
        icon={<Icons.RefreshCw strokeWidth={3} />}
        text={"Syncing"}
        neutral={true}
      />
    );
  }
  if (props.status === PropagationStatusCode.PendingManualPropagation) {
    return (
      <BasePill
        icon={<Icons.RefreshCw strokeWidth={3} />}
        text={
          props.taskType === PropagationTaskType.ResourcesCreateUsers ||
          props.taskType === PropagationTaskType.GroupsCreateUsers
            ? "Pending manual propagation"
            : "Pending manual revocation"
        }
        neutral={true}
      />
    );
  }
  return (
    <BasePill
      icon={<Icons.X strokeWidth={3} />}
      text={"Propagation Error"}
      alert={true}
    />
  );
};

export type RevokedPillProps = {
  complete: boolean;
};

export const RevokedPill = (props: RevokedPillProps) => {
  return (
    <BasePill
      icon={props.complete ? <Icons.Check strokeWidth={3} /> : null}
      text={props.complete ? "Revoked" : "Revocation"}
      alert={props.complete}
      neutral={!props.complete}
    />
  );
};

export const OkBridge = () => {
  return (
    <span className={styles.okBridge}>
      <hr />
    </span>
  );
};

export const NeutralBridge = () => {
  return (
    <span className={styles.neutralBridge}>
      <hr />
    </span>
  );
};

export const AlertBridge = () => {
  return (
    <span className={styles.alertBridge}>
      <hr />
    </span>
  );
};

type BasePillProps = {
  icon: Maybe<ReactElement>;
  text: string;
  alert?: boolean;
  ok?: boolean;
  neutral?: boolean;
};

const BasePill = (props: BasePillProps) => {
  return (
    <div
      className={clsx({
        [styles.pillWrapper]: true,
        [styles.alert]: props.alert,
        [styles.ok]: props.ok,
        [styles.neutral]: props.neutral,
      })}
    >
      <div className={styles.pillInnerWrapper}>
        {props.icon}
        <span className={styles.pillText}>{props.text}</span>
      </div>
    </div>
  );
};
