import {
  AccessReviewAction,
  AccessReviewResourceUserFragment,
  Maybe,
  ResourceFragment,
  ResourceType,
  ReviewResourceUserActionUpdatedResourceInput,
} from "api/generated/graphql";
import sprinkles from "css/sprinkles.css";
import React, { useState } from "react";
import * as Icons from "react-feather";
import { PerformReviewState } from "views/access_reviews/AccessReviewContext";
import AccessReviewEditResourceUpdatedResourceModal from "views/access_reviews/AccessReviewEditResourceModal";

import * as styles from "./AccessReviewResourceRole.css";

type AccessReviewResourceUpdatedResourceProps = {
  resource: Maybe<ResourceFragment> | undefined;
  updatedResource:
    | Maybe<ReviewResourceUserActionUpdatedResourceInput>
    | undefined;
  resourceId: string;
  showEditIcon?: boolean;
  onUpdatedResourceChange?: (resource: Maybe<ResourceFragment>) => void;
  resourceBeingRevoked?: boolean;
};

const AccessReviewResourceUpdatedResourceCell = (
  props: AccessReviewResourceUpdatedResourceProps
) => {
  const [showModal, setShowModal] = useState(false);
  let editIcon;
  let defaultText;

  if (props.resource && props.showEditIcon) {
    editIcon = (
      <span
        className={sprinkles({
          cursor: "pointer",
          paddingLeft: "sm",
        })}
        onClick={() => setShowModal(true)}
      >
        <Icons.Edit2 strokeWidth={2} size={14} />
      </span>
    );
  }

  if (!props.resource) {
    return <></>;
  }

  if (
    props.resource.resourceType === ResourceType.SalesforceProfile &&
    props.resourceBeingRevoked
  ) {
    defaultText = <i>Standard User (default)</i>;
  }

  return (
    <div>
      <div
        className={styles.resourceName({
          type: props.updatedResource ? "updated" : undefined,
        })}
      >
        <span>{editIcon}</span>
        <span>
          {!!props.updatedResource && props.updatedResource?.name}
          {!props.updatedResource && props.resourceBeingRevoked && defaultText}
        </span>
      </div>
      {showModal && (
        <AccessReviewEditResourceUpdatedResourceModal
          onSubmit={(resource) => {
            if (props.onUpdatedResourceChange) {
              props.onUpdatedResourceChange(resource);
            }
            setShowModal(false);
          }}
          onClose={() => setShowModal(false)}
          currentResource={props.resource}
        />
      )}
    </div>
  );
};

export const updateReviewStateForResourceUserUpdatedResourceChange = (
  performReviewState: PerformReviewState,
  accessReviewResourceUser: AccessReviewResourceUserFragment,
  resource: Maybe<ResourceFragment>
): PerformReviewState => {
  const existingInfo = performReviewState.resourceUserActions.find(
    (resourceUserAction) =>
      resourceUserAction.accessReviewResourceUserId ===
      accessReviewResourceUser.id
  );

  if (existingInfo && accessReviewResourceUser.resourceId === resource?.id) {
    // This can happen if a user changes the updated resource to a new one and
    // then changes it back to the existing one. Then we want to remove the
    // review action
    performReviewState.resourceUserActions = performReviewState.resourceUserActions.filter(
      (action) => {
        return (
          action.accessReviewResourceUserId !==
          existingInfo.accessReviewResourceUserId
        );
      }
    );
  } else if (existingInfo) {
    existingInfo.action = AccessReviewAction.Update;
    existingInfo.updatedResource = {
      id: resource?.id ?? "",
      name: resource?.name,
    };
  } else {
    performReviewState.resourceUserActions.push({
      accessReviewResourceUserId: accessReviewResourceUser.id,
      action: AccessReviewAction.Update,
      updatedResource: {
        id: resource?.id ?? "",
        name: resource?.name,
      },
    });
  }

  return performReviewState;
};

export default AccessReviewResourceUpdatedResourceCell;
