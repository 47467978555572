import { gql, useQuery } from "@apollo/client";
import {
  EntityType,
  InventoryTagsTableQuery,
  TagsSortByField,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { useOpalSearchInput } from "components/opal/common/input/OpalSearchInput";
import OpalLink from "components/opal/common/OpalLink";
import OpalTable, {
  Columns,
  useServerSortableOpalTable,
} from "components/opal/table/OpalTable";
import { ButtonV3 } from "components/ui";
import moment from "moment";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import TagCreateModal from "views/tags/TagCreateModal";
import * as styles from "views/tags/TagsColumnV3.css";

const CREATED_AT_COL_ID = TagsSortByField.CreatedAt;
const KEY_COL_ID = TagsSortByField.Key;
const VALUE_COL_ID = TagsSortByField.Value;
const RESOURCE_COUNT_COL_ID = TagsSortByField.ResourceCount;
const GROUP_COUNT_COL_ID = TagsSortByField.GroupCount;
const USER_COUNT_COL_ID = TagsSortByField.UserCount;

type Tag = InventoryTagsTableQuery["tags"]["tags"][0];

const TAG_COLUMNS: Columns<Tag, TagsSortByField> = [
  {
    id: KEY_COL_ID,
    label: "Key",
    sortable: true,
    customCellRenderer: (tag) => {
      return (
        <OpalLink
          to={getResourceUrlNew({
            entityId: tag.id,
            entityType: EntityType.Tag,
          })}
          className={styles.tagCell}
        >
          {tag.key}
        </OpalLink>
      );
    },
  },
  {
    id: VALUE_COL_ID,
    label: "Value",
    sortable: true,
    customCellRenderer: (tag) => {
      return <span className={styles.tagCell}>{tag.value}</span>;
    },
  },
  {
    id: RESOURCE_COUNT_COL_ID,
    label: "Resources",
    width: 80,
    sortable: true,
    customCellRenderer: (tag) =>
      `${pluralize("Resource", tag.tagResources.length, true)}`,
  },
  {
    id: GROUP_COUNT_COL_ID,
    label: "Groups",
    width: 80,
    sortable: true,
    customCellRenderer: (tag) =>
      `${pluralize("Group", tag.tagGroups.length, true)}`,
  },
  {
    id: USER_COUNT_COL_ID,
    label: "Users",
    width: 80,
    sortable: true,
    customCellRenderer: (tag) =>
      `${pluralize("User", tag.tagUsers.length, true)}`,
  },
  {
    id: CREATED_AT_COL_ID,
    label: "Created",
    width: 80,
    sortable: true,
    customCellRenderer: (tag) => moment(tag.createdAt).fromNow(),
  },
];

const TagsColumn = () => {
  const transitionTo = useTransitionTo();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { authState } = useContext(AuthContext);
  const [searchQuery, searchInput] = useOpalSearchInput({
    placeholder: "Filter by name",
  });

  const { sortByVariable, sortByTableProps } = useServerSortableOpalTable({
    id: TagsSortByField.Key,
    desc: false,
  });

  const isAdmin = authState.user?.isAdmin;

  const { data, error, networkStatus, fetchMore } = useQuery(
    gql`
      query InventoryTagsTable(
        $limit: Int
        $searchQuery: String
        $sortBy: TagsSortBy
        $cursor: String
      ) {
        tags(
          input: {
            limit: $limit
            searchQuery: $searchQuery
            sortBy: $sortBy
            cursor: $cursor
          }
        ) {
          __typename
          ... on TagsResult {
            tags {
              __typename
              id
              key
              value
              createdAt
              tagResources {
                __typename
                resourceId
              }
              tagGroups {
                __typename
                groupId
              }
              tagUsers {
                __typename
                userId
              }
            }
            totalNumTags
            cursor
          }
        }
      }
    `,
    {
      notifyOnNetworkStatusChange: true,
      variables: {
        limit: 50,
        searchQuery: searchQuery,
        sortBy: sortByVariable,
      },
    }
  );

  if (error) {
    logError(error, `failed to list tags`);
  }

  const cursor = data?.tags.cursor;
  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            cursor,
          },
        });
      }
    : undefined;

  const tags = data?.tags.tags ?? [];

  return (
    <>
      <OpalTable
        rows={tags}
        filters={searchInput}
        actions={
          isAdmin && (
            <ButtonV3
              label="Tag"
              type="main"
              size="sm"
              leftIconName="plus"
              onClick={() => {
                setShowCreateModal(true);
              }}
            />
          )
        }
        totalNumRows={data?.tags.totalNumTags ?? 0}
        getRowId={(ru) => ru.id}
        columns={TAG_COLUMNS}
        onRowClick={(row, event) => {
          transitionTo(
            {
              pathname: getResourceUrlNew({
                entityId: row.id,
                entityType: EntityType.Tag,
              }),
            },
            event
          );
        }}
        onLoadMoreRows={loadMoreRows}
        entityName="Tag"
        networkStatus={networkStatus}
        {...sortByTableProps}
      />
      {showCreateModal ? (
        <TagCreateModal onClose={() => setShowCreateModal(false)} />
      ) : null}
    </>
  );
};

export default TagsColumn;
