import {
  AccessReviewFragment,
  EntityType,
  FiltersMatchMode,
} from "api/generated/graphql";
import ConnectionsFilter from "components/access_reviews/scope/ConnectionsFilter";
import EntitiesIndividualFilter from "components/access_reviews/scope/EntitiesIndividualFilter";
import EntitiesNamedFilter from "components/access_reviews/scope/EntitiesNamedFilter";
import EntitiesWithAdminFilter from "components/access_reviews/scope/EntitiesWithAdminFilter";
import EntityTypesFilter from "components/access_reviews/scope/EntityTypesFilter";
import TagFilter from "components/access_reviews/scope/TagFilter";
import UsersFilter from "components/access_reviews/scope/UsersFilter";
import { ResourceLabel } from "components/label/Label";
import { useToast } from "components/toast/Toast";
import { ButtonV3, Col, Divider, FormGroup, Row } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React from "react";
import { formatFullCalendarStr } from "views/access_reviews/common/Common";
import {
  handleRequestExport,
  UARExportType,
} from "views/access_reviews/common/Export";

import RegenerateAccessReviewReportButton from "./common/RegenerateAccessReviewReportButton";
import { getHasFilters } from "./create/utils";

type AccessReviewOverviewProps = {
  accessReview: AccessReviewFragment;
};

const AccessReviewEndedV3 = (props: AccessReviewOverviewProps) => {
  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();

  const { filters } = props.accessReview;

  return (
    <div
      className={sprinkles({
        padding: "lg",
      })}
    >
      <FormGroup label="Full ID">{props.accessReview.id}</FormGroup>
      {props?.accessReview?.reportId ? (
        <>
          <FormGroup label="Download Report">
            <ButtonV3
              label={"Download"}
              leftIconName="download"
              onClick={() => {
                handleRequestExport(
                  props.accessReview,
                  UARExportType.Report,
                  displayLoadingToast,
                  displaySuccessToast,
                  displayErrorToast
                );
              }}
              outline
            />
          </FormGroup>
        </>
      ) : (
        <>
          <FormGroup label="Regenerate Report">
            <RegenerateAccessReviewReportButton
              label="Regenerate"
              isV3={true}
              accessReview={props.accessReview}
              displayErrorToast={displayErrorToast}
              displayLoadingToast={displayLoadingToast}
              displaySuccessToast={displaySuccessToast}
            />
          </FormGroup>
        </>
      )}

      <div className={sprinkles({ marginBottom: "lg" })}>
        <Row>
          <Col>
            <FormGroup label="Started at">
              {formatFullCalendarStr(props.accessReview.createdAt)}
            </FormGroup>
          </Col>
          <Col>
            <FormGroup label="Started by">
              <ResourceLabel
                text={props.accessReview.startedByUser?.fullName}
                entityTypeNew={EntityType.User}
                avatar={props.accessReview.startedByUser?.avatarUrl}
                entityId={props.accessReview.startedByUserId}
              />
            </FormGroup>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          <FormGroup label="Stopped at">
            {formatFullCalendarStr(props.accessReview.stoppedDate)}
          </FormGroup>
        </Col>
        <Col>
          <FormGroup label="Stopped by">
            <ResourceLabel
              text={props.accessReview.stoppedByUser?.fullName}
              entityTypeNew={EntityType.User}
              avatar={props.accessReview.stoppedByUser?.avatarUrl}
              entityId={props.accessReview.stoppedByUserId}
            />
          </FormGroup>
        </Col>
      </Row>
      <Divider />
      <p>Scope</p>
      {filters && getHasFilters(filters) ? (
        <>
          {filters.userIDs.length > 0 && (
            <FormGroup label="Users">
              <UsersFilter userIDs={filters.userIDs} />
            </FormGroup>
          )}
          <div className={sprinkles({ marginBottom: "md" })}>
            Include entities that match{" "}
            <span className={sprinkles({ fontWeight: "semibold" })}>
              {filters.matchMode === FiltersMatchMode.All ? "ALL" : "ANY"}
            </span>{" "}
            of the following filters:
          </div>
          <EntitiesNamedFilter names={filters.names} />
          <EntityTypesFilter
            resourceTypes={filters.resourceTypes}
            groupTypes={filters.groupTypes}
          />
          <ConnectionsFilter connectionIDs={filters.connectionIDs} />
          <EntitiesWithAdminFilter adminIDs={filters.adminIDs} />
          <TagFilter tags={filters.tags} />
          <EntitiesIndividualFilter entityIds={filters.entityIDs} />
        </>
      ) : (
        <>This access review included all items at its creation.</>
      )}
    </div>
  );
};

export default AccessReviewEndedV3;
