import {
  AccessReviewAction,
  AccessReviewGroupUserFragment,
  GroupAccessLevel,
  GroupAccessLevelInput,
  Maybe,
} from "api/generated/graphql";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import * as Icons from "react-feather";
import { PerformReviewState } from "views/access_reviews/AccessReviewContext";
import AccessReviewEditGroupRoleModal from "views/access_reviews/AccessReviewEditGroupRoleModal";

import * as styles from "./AccessReviewResourceRole.css";

type AccessReviewGroupRoleProps = {
  role: Maybe<GroupAccessLevel>;
  updatedRole: Maybe<GroupAccessLevelInput> | undefined;
  groupId: string;
  showEditIcon?: boolean;
  onRoleChange?: (role: Maybe<GroupAccessLevel>) => void;
};

const AccessReviewGroupRoleCell = (props: AccessReviewGroupRoleProps) => {
  const [showModal, setShowModal] = useState(false);
  let editIcon;

  if (props.role && props.role.accessLevelName && props.showEditIcon) {
    editIcon = (
      <span
        className={sprinkles({
          cursor: "pointer",
          paddingRight: "sm",
        })}
        onClick={() => setShowModal(true)}
      >
        <Icons.Edit2 strokeWidth={2} size={14} />
      </span>
    );
  }

  let role = props.updatedRole || props.role;
  let roleName = role?.accessLevelName || "--";

  return (
    <div className={sprinkles({ display: "flex", width: "100%" })}>
      <>
        <span
          className={styles.roleName({
            type: props.updatedRole ? "updated" : undefined,
          })}
        >
          {roleName}
        </span>
        <span>{editIcon}</span>
      </>
      {showModal && (
        <AccessReviewEditGroupRoleModal
          onSubmit={(role) => {
            if (props.onRoleChange) {
              props.onRoleChange(role);
            }
            setShowModal(false);
          }}
          onClose={() => setShowModal(false)}
          currentRole={role}
          groupId={props.groupId}
        />
      )}
    </div>
  );
};

export const updateReviewStateForGroupUserRoleChange = (
  performReviewState: PerformReviewState,
  accessReviewGroupUser: AccessReviewGroupUserFragment,
  role: Maybe<GroupAccessLevel>
): PerformReviewState => {
  const existingInfo = performReviewState.groupUserActions.find(
    (groupUserAction) =>
      groupUserAction.accessReviewGroupUserId === accessReviewGroupUser.id
  );

  if (
    existingInfo &&
    accessReviewGroupUser.accessLevel.accessLevelName === role?.accessLevelName
  ) {
    // This can happen if a user changes the role to a new one and
    // then changes it back to the existing one. Then we want to remove the
    // review action
    performReviewState.groupUserActions = performReviewState.groupUserActions.filter(
      (action) => {
        return (
          action.accessReviewGroupUserId !==
          existingInfo.accessReviewGroupUserId
        );
      }
    );
  } else if (existingInfo) {
    existingInfo.action = AccessReviewAction.Update;
    existingInfo.updatedAccessLevel = role;
  } else {
    performReviewState.groupUserActions.push({
      accessReviewGroupUserId: accessReviewGroupUser.id,
      action: AccessReviewAction.Update,
      updatedAccessLevel: role,
    });
  }

  return performReviewState;
};

export default AccessReviewGroupRoleCell;
