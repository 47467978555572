import { ReviewStageOperator } from "api/generated/graphql";
import { Button, Icon, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import * as styles from "./ApprovalsEditor.css";
import ApprovalsEditorStage from "./ApprovalsEditorStage";
import { GenericReviewer } from "./generic_reviewers";

export interface ReviewerStage {
  requireManagerApproval: boolean;
  requireAdminApproval: boolean;
  operator: ReviewStageOperator;
  ownerIds: string[];
}

interface Props {
  isRequestable: boolean;
  autoApprove: boolean;
  reasonOptional: boolean;
  stageData: ReviewerStage[];
  onChange: (
    isRequestable: boolean,
    autoApprove: boolean,
    reasonOptional: boolean,
    stageData: ReviewerStage[]
  ) => void;
  showEdit: boolean;
}

export const ApprovalsEditor = (props: Props) => {
  const { isRequestable, autoApprove, stageData, reasonOptional } = props;
  const hasReasonOptional = useFeatureFlag(FeatureFlag.ReasonOptional);

  const getEmptyStageData = (stage: number) => ({
    stage,
    requireManagerApproval: false,
    requireAdminApproval: false,
    ownerIds: [],
    operator: "AND" as ReviewStageOperator,
  });

  const handleChangeStageData = (
    stage: number,
    data: Partial<ReviewerStage>
  ) => {
    const newData = stageData.map((d, i) => {
      if (i === stage) {
        return { ...d, ...data };
      } else if (data.requireManagerApproval) {
        // Only 1 stage can have require manager.
        return { ...d, requireManagerApproval: false };
      } else if (data.requireAdminApproval) {
        // Only 1 stage can have require admin.
        return { ...d, requireAdminApproval: false };
      }
      return d;
    });
    props.onChange(isRequestable, autoApprove, reasonOptional, newData);
  };

  const handleDeleteStage = (stage: number) => {
    const newData = stageData.filter((d, i) => i !== stage);
    props.onChange(isRequestable, autoApprove, reasonOptional, newData);
  };

  const renderIsRequestableSwitch = () => {
    if (!props.showEdit && isRequestable) return null;
    return (
      <Switch
        label="Allow requests"
        checked={isRequestable}
        onChange={(value) => {
          let newStageData = stageData;
          // If changing isRequestable from false to true, and auto-approvals are turned off,
          // make sure there is always at least one stage.
          if (value && !autoApprove && stageData.length === 0) {
            newStageData = [getEmptyStageData(1)];
          }
          props.onChange(value, autoApprove, reasonOptional, newStageData);
        }}
        disabled={!props.showEdit}
        rightAlign
      />
    );
  };

  const renderAutoApproveSwitch = () => {
    if (!isRequestable || (!props.showEdit && !autoApprove)) return null;
    return (
      <Switch
        label="Auto-approve requests"
        checked={autoApprove}
        onChange={(value) => {
          let newStageData = stageData;
          // If changing auto-approve from true to false, make sure there is always at least one stage.
          if (!value && stageData.length === 0) {
            newStageData = [getEmptyStageData(1)];
          }
          props.onChange(isRequestable, value, reasonOptional, newStageData);
        }}
        disabled={!props.showEdit}
        rightAlign
      />
    );
  };

  const renderReasonOptionalSwitch = () => {
    if (
      !isRequestable ||
      (!props.showEdit && !reasonOptional) ||
      !hasReasonOptional
    )
      return null;
    return (
      <Switch
        label="Include Reason field in request"
        checked={!reasonOptional}
        onChange={(value) =>
          props.onChange(isRequestable, autoApprove, !value, stageData)
        }
        disabled={!props.showEdit}
        rightAlign
      />
    );
  };

  const renderApprovalsEditor = () => {
    if (!isRequestable || autoApprove) return null;

    const allSelectedOwners = stageData.flatMap((d) => d.ownerIds);
    const anyStageHasManagerApproval = stageData.some(
      (d) => d.requireManagerApproval
    );
    const anyStageHasAdminApproval = stageData.some(
      (d) => d.requireAdminApproval
    );

    return (
      <div className={styles.editor}>
        {stageData.map((d, i) => {
          return (
            <React.Fragment key={i}>
              <ApprovalsEditorStage
                stageNumber={i + 1}
                requireAdminApproval={d.requireAdminApproval}
                requireManagerApproval={d.requireManagerApproval}
                setGenericReviewer={(
                  reviewerRequired: GenericReviewer,
                  value: boolean
                ) => {
                  switch (reviewerRequired) {
                    case GenericReviewer.Manager:
                      handleChangeStageData(i, {
                        requireManagerApproval: value,
                      });
                      break;
                    case GenericReviewer.Admin:
                      handleChangeStageData(i, { requireAdminApproval: value });
                      break;
                  }
                }}
                showGenericReviewerButtons={{
                  [GenericReviewer.Manager]: !anyStageHasManagerApproval,
                  [GenericReviewer.Admin]: !anyStageHasAdminApproval,
                }}
                ownerIds={d.ownerIds}
                setOwnerIds={(ownerIds) =>
                  handleChangeStageData(i, { ownerIds })
                }
                excludedOwnerIds={allSelectedOwners}
                operator={d.operator}
                setOperator={(operator) =>
                  handleChangeStageData(i, { operator })
                }
                onDelete={() => handleDeleteStage(i)}
                editView={props.showEdit}
              />
              {i !== stageData.length - 1 ||
              (props.showEdit && stageData.length < 3) ? (
                <div className={sprinkles({ marginLeft: "md" })}>
                  <Icon name="arrow-down" color="green700" size="sm" />
                </div>
              ) : null}
            </React.Fragment>
          );
        })}
        {stageData.length < 3 && props.showEdit ? (
          <Button
            label="Add a stage"
            leftIconName="plus"
            borderless
            type="success"
            onClick={() => {
              props.onChange(isRequestable, autoApprove, reasonOptional, [
                ...stageData,
                getEmptyStageData(stageData.length + 1),
              ]);
            }}
            fullWidth={false}
          />
        ) : null}
      </div>
    );
  };

  return (
    <div className={styles.container}>
      {renderIsRequestableSwitch()}
      {renderAutoApproveSwitch()}
      {renderReasonOptionalSwitch()}
      {renderApprovalsEditor()}
    </div>
  );
};
