import { NetworkStatus } from "@apollo/client";
import {
  Cell,
  CellContext,
  ColumnDef,
  ColumnSort,
  flexRender,
  functionalUpdate,
  getCoreRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  Header,
  OnChangeFn,
  Row,
  RowSelectionState,
  SortingFnOption,
  useReactTable,
} from "@tanstack/react-table";
import {
  useVirtualizer,
  VirtualItem,
  Virtualizer,
} from "@tanstack/react-virtual";
import { SortDirection } from "api/generated/graphql";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import Icon from "components/ui/icon/Icon";
import pluralize from "pluralize";
import React, { memo, useContext, useEffect } from "react";
import { useMemo, useState } from "react";
import { useInView } from "react-spring";
import { useTransitionTo } from "utils/router/hooks";

import { OpalPageContext } from "../layout/OpalPage";
import * as styles from "./OpalTable.css";
import OpalTableCheckbox from "./OpalTableCheckbox";
import OpalTableHeader from "./OpalTableHeader";

export type SortDirectionType = "ASC" | "DESC";

export type { CellContext };

interface SortableColumn<RowData, SortBy> extends ColumnBase<RowData> {
  sortable: true;
  id: SortBy;
}

interface UnsortableColumn<RowData> extends ColumnBase<RowData> {
  sortable?: false | undefined;
  id: string;
}

export interface ColumnBase<RowData> {
  label: string;
  width?: number;
  minWidth?: number;
  fixedWidth?: boolean; // we default to flex: 1. Setting this to true overrides that behavior.
  customHeader?: React.ReactElement;
  customCellRenderer?: (rowData: RowData) => React.ReactElement | string;
  sortingFn?: SortingFnOption<RowData>;
  resizable?: boolean;
}

export type Columns<RowData, SortBy = never> = Array<
  SortableColumn<RowData, SortBy> | UnsortableColumn<RowData>
>;

interface Props<RowData, SortBy> {
  columns: Columns<RowData, SortBy>;
  rows: RowData[];
  totalNumRows: number;
  entityName: string;
  actions?: React.ReactNode;
  filters?: React.ReactNode;

  getRowId: (row: RowData) => string;

  // Infinite scroll/pagination
  onLoadMoreRows?: (id?: string) => Promise<void>;

  // Sort
  currentSort?: { id: SortBy; desc: boolean };
  onSort?: (state: { id: SortBy; desc: boolean }) => void;

  // Clickable rows
  onRowClick?: (
    row: RowData,
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => void;

  // Selectable rows
  selectedRows?: RowSelectionState;
  onSelectedRowsChange?: OnChangeFn<RowSelectionState>;
  getUnselectableReason?: (row: RowData) => string | undefined; // if unselectable, return a string with reason.
  bulkActions?: React.ReactNode;

  // pass in a function of Data that returns a pathname to transtion to
  // onRowClick
  onRowClickTransitionTo?: (row: RowData) => string | null | undefined;

  hideHeader?: boolean;
  networkStatus?: NetworkStatus;
}

/**
 * this is a rewrite of <Table /> currently only used in
 * InventoryApps so not all features are tested so use at your
 * own risk. It also should only be used in OpalPage. As it relies on CSS
 * there to fill up the remainder of the page
 *
 * list of features that *should* work
 *
 * - infinite scroll
 * - virtualization
 */
const OpalTable = <RowData, SortBy>(props: Props<RowData, SortBy>) => {
  const {
    rows: propRows,
    onLoadMoreRows,
    totalNumRows,
    hideHeader,
    onRowClick,
    onRowClickTransitionTo,
    entityName,
    actions,
    filters,
    networkStatus,
    onSort,
    currentSort,
    selectedRows,
    onSelectedRowsChange,
    getUnselectableReason,
    bulkActions,
    columns,
  } = props;

  const { body: opalPageBody } = useContext(OpalPageContext);

  const transitionTo = useTransitionTo();

  const rowClickCallback = onRowClickTransitionTo
    ? (
        row: RowData,
        event: React.MouseEvent<HTMLTableRowElement, MouseEvent>
      ) => {
        const path = onRowClickTransitionTo(row);
        if (path != null) {
          transitionTo({ pathname: path }, event);
        }
      }
    : onRowClick;

  const fixedWidthCols = [
    "select-col", // always fixed,
  ];
  columns.forEach(
    (column) => column.fixedWidth && fixedWidthCols.push(column.id as string)
  );

  // should never change since props.columns should be static
  const columnData: ColumnDef<RowData>[] = useMemo(() => {
    const checkbox_columns: ColumnDef<RowData>[] = onSelectedRowsChange
      ? [
          {
            id: "select-col",
            header: ({ table }) => (
              <OpalTableCheckbox
                checked={table.getIsAllRowsSelected()}
                indeterminate={table.getIsSomeRowsSelected()}
                onChange={table.getToggleAllRowsSelectedHandler()}
              />
            ),
            enableResizing: false,
            cell: ({ row }) => (
              <OpalTableCheckbox
                checked={row.getIsSelected()}
                disabled={!row.getCanSelect()}
                disabledTooltip={
                  getUnselectableReason &&
                  getUnselectableReason(propRows[row.index])
                }
                onChange={row.getToggleSelectedHandler()}
              />
            ),
            size: 35,
          },
        ]
      : [];
    const config_columns = columns.map((col) => {
      const colData: ColumnDef<RowData> = {
        accessorKey: col.id as string,
        header: col.customHeader ? () => col.customHeader : col.label,
        size: col.width,
        minSize: col.minWidth,
        enableSorting: col.sortable,
        sortingFn: col.sortingFn || "alphanumeric",
        enableResizing:
          col.resizable != null // if resizable is set
            ? col.resizable // use that
            : col.fixedWidth != null // otherwise if fixedWidth is set
            ? false // return false
            : true, // otherwise, default to true
      };
      if (col.customCellRenderer) {
        const renderCell = col.customCellRenderer;
        colData.cell = ({ row }) => renderCell(row.original);
      }
      return colData;
    });

    return [...checkbox_columns, ...config_columns];
  }, [columns, getUnselectableReason, onSelectedRowsChange, propRows]);

  const tableData = useReactTable({
    data: props.rows,
    columns: columnData,
    columnResizeMode: "onChange",
    getCoreRowModel: getCoreRowModel(),
    state: {
      rowSelection: onSelectedRowsChange ? selectedRows : undefined,
      sorting: onSort
        ? currentSort
          ? [currentSort as ColumnSort]
          : []
        : undefined,
    },
    getRowId: props.getRowId,

    enableSortingRemoval: false,
    manualSorting: Boolean(props.onSort),
    onSortingChange: onSort
      ? (updater) => {
          onSort(
            functionalUpdate(
              updater,
              currentSort ? [currentSort as ColumnSort] : []
            )[0] as {
              id: SortBy;
              desc: boolean;
            }
          );
        }
      : undefined,
    onRowSelectionChange: onSelectedRowsChange ?? undefined,
    enableRowSelection: getUnselectableReason
      ? (row: Row<RowData>): boolean => {
          return typeof getUnselectableReason(propRows[row.index]) !== "string";
        }
      : undefined,
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
  });

  const { rows } = tableData.getRowModel();

  const tableContainerRef = React.useRef<HTMLDivElement | null>(null);

  const rowVirtualizer = useVirtualizer({
    count: rows.length,
    estimateSize: () => 48, //estimate row height for accurate scrollbar dragging
    getScrollElement: () => opalPageBody,
    scrollMargin: tableContainerRef?.current?.offsetTop ?? 0, // gap between scrolling element (OpalPage/body) and the top of the virtualizer
    //measure dynamic row height, except in firefox because it measures table border height incorrectly
    measureElement:
      typeof window !== "undefined" &&
      navigator.userAgent.indexOf("Firefox") === -1
        ? (element) => element?.getBoundingClientRect().height
        : undefined,
    overscan: 5,
  });

  // Infinite Scroll
  // When inViewRef is in the viewport we fetch more data;
  const [inViewRef, inView] = useInView();
  useEffect(() => {
    if (onLoadMoreRows && inView && propRows.length < totalNumRows) {
      onLoadMoreRows();
    }
  }, [inView, networkStatus, onLoadMoreRows, propRows.length, totalNumRows]);

  // initial load or when filters change (e.g. a load that is not loadMore)
  const fullLoad =
    networkStatus === NetworkStatus.loading ||
    networkStatus === NetworkStatus.setVariables;

  return (
    <>
      {hideHeader !== true ? (
        <OpalTableHeader
          isLoading={fullLoad}
          totalNumRows={totalNumRows}
          entityName={entityName}
          actions={actions}
          filters={filters}
        />
      ) : null}
      <div
        ref={tableContainerRef}
        style={{
          height: `${rowVirtualizer.getTotalSize()}`,
          position: "relative",
        }}
      >
        <table className={styles.table}>
          <thead className={styles.header}>
            {tableData.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id} className={styles.tableRow}>
                {headerGroup.headers.map((header) => {
                  return (
                    <TableHeaderMemo
                      header={header}
                      width={header.getSize()}
                      isResizing={header.column.getIsResizing()}
                      sortDirection={header.getContext().column.getIsSorted()}
                      fixedWidth={fixedWidthCols.includes(header.column.id)}
                    />
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody
            style={{
              display: "grid",
              height: `${rows.length ? rowVirtualizer.getTotalSize() : 0}px`, //tells scrollbar how big the table is
              position: "relative", //needed for absolute positioning of rows
            }}
          >
            {rowVirtualizer.getVirtualItems().map((virtualItem) => {
              // if the table is selectable, we can't memoize the rows since they will change on click
              return (
                <TableRow
                  virtualRow={virtualItem}
                  virtualizer={rowVirtualizer}
                  scrollMargin={rowVirtualizer.options.scrollMargin}
                  rowClickCallback={rowClickCallback}
                  row={rows[virtualItem.index]}
                  fixedWidthCols={fixedWidthCols}
                />
              );
            })}
          </tbody>
        </table>
        {fullLoad ? (
          <ColumnListItemsSkeleton />
        ) : props.networkStatus === NetworkStatus.fetchMore ? (
          <div className={styles.loading}>Loading...</div>
        ) : totalNumRows === 0 ? (
          <div className={styles.emptyStateContainer}>
            <div className={styles.emptyStateTitle}>{`No ${
              entityName ?? "row"
            }s to display.`}</div>
          </div>
        ) : null}
        <div ref={inViewRef} style={{ height: "48px" }} />
        {onSelectedRowsChange &&
          tableData.getSelectedRowModel().rows.length > 0 && (
            <div className={styles.bulkActionBar}>
              {`${pluralize(
                entityName,
                tableData.getSelectedRowModel().rows.length,
                true
              )} Selected`}
              <div className={styles.bulkActions}>{bulkActions}</div>
            </div>
          )}
      </div>
    </>
  );
};

const TableHeader = <RowData,>(props: {
  header: Header<RowData, unknown>;
  width: number;
  isResizing: boolean;
  sortDirection?: "asc" | "desc" | false; // need to pass this in separately since this component is memoized
  fixedWidth: boolean;
}) => {
  const { header, sortDirection, width, fixedWidth } = props;
  return (
    <th
      key={header.id}
      className={styles.columnHeader({ fixedWidth: fixedWidth })}
      style={{ width: width }}
    >
      <div
        className={styles.headerCell({
          sortable: header.column.getCanSort(),
        })}
        onClick={header.column.getToggleSortingHandler()}
      >
        {flexRender(header.column.columnDef.header, header.getContext())}
        {sortDirection
          ? {
              asc: <Icon name="arrow-up" color="gray600" size="xs" />,
              desc: <Icon name="arrow-down" color="gray600" size="xs" />,
            }[sortDirection]
          : null}
      </div>
      {header.column.getCanResize() ? (
        <div
          onDoubleClick={() => header.column.resetSize()}
          onMouseDown={header.getResizeHandler()}
          onTouchStart={header.getResizeHandler()}
          className={styles.headerResizer({
            isResizing: props.isResizing,
          })}
        />
      ) : null}
    </th>
  );
};

const TableRow = <RowData,>(props: {
  virtualRow: VirtualItem;
  virtualizer: Virtualizer<HTMLDivElement, Element>;
  scrollMargin: number;
  rowClickCallback?: (
    row: RowData,
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>
  ) => void;
  row: Row<RowData>;
  fixedWidthCols: string[];
}) => {
  const {
    row,
    virtualRow,
    scrollMargin,
    rowClickCallback,
    virtualizer,
  } = props;
  return (
    <>
      <tr
        data-index={virtualRow.index} //needed for dynamic row height measurement
        ref={(node) => virtualizer.measureElement(node)} //measure dynamic row height
        key={row.id}
        style={{
          display: "flex",
          position: "absolute",
          transform: `translateY(${virtualRow.start - scrollMargin}px)`,
          width: "100%",
        }}
        className={styles.row({
          clickable: Boolean(rowClickCallback),
          // Use row index to render alternating row colors instead of css property nth-child,
          // because the virtualization causes the rows to switch between odd and even
          gray: virtualRow.index % 2 === 1,
        })}
        onClick={(e) => {
          rowClickCallback?.(row.original, e);
        }}
      >
        {row.getVisibleCells().map((cell) => {
          return (
            <TableCellMemo
              cell={cell}
              width={cell.column.getSize()}
              fixedWidth={props.fixedWidthCols.includes(cell.column.id)}
            />
          );
        })}
      </tr>
    </>
  );
};

const TableCell = <RowData,>(props: {
  cell: Cell<RowData, unknown>;
  width: number;
  fixedWidth: boolean;
}) => {
  return (
    <td
      key={props.cell.id}
      className={styles.cell({
        border: false,
        fixedWidth: props.fixedWidth,
      })}
      style={{
        width: props.width,
      }}
    >
      {flexRender(props.cell.column.columnDef.cell, props.cell.getContext())}
    </td>
  );
};

// need to cast type since generic doesn't work well with memo
const TableCellMemo = memo(TableCell) as typeof TableCell;
const TableHeaderMemo = memo(TableHeader) as typeof TableHeader;

// magically sets up sorting if you're using server side sorting and OpalTable
export const useServerSortableOpalTable = <SortBy,>(initialSort?: {
  id: SortBy;
  desc: boolean;
}) => {
  const [sortState, setSortState] = useState<
    { id: SortBy; desc: boolean } | undefined
  >(initialSort);

  const sortByTableProps = {
    onSort: setSortState,
    currentSort: sortState,
  };
  return {
    sortByVariable: sortState
      ? {
          field: sortState.id,
          direction:
            sortState.desc === true ? SortDirection.Desc : SortDirection.Asc,
        }
      : undefined,
    sortByTableProps,
  };
};

// magically sets up selectable opal table
export const useSelectableOpalTable = <RowData,>({
  initialState,
  getUnselectableReason,
  bulkActions,
}: {
  initialState?: RowSelectionState;
  getUnselectableReason?: Props<RowData, never>["getUnselectableReason"];
  bulkActions?: Props<RowData, never>["bulkActions"];
}) => {
  const [selectedRows, setSelectedRows] = useState<RowSelectionState>(
    initialState ?? {}
  );

  return {
    selectedRows,
    resetSelected: () => setSelectedRows({}),
    selectableOpalTableProps: {
      selectedRows: selectedRows,
      onSelectedRowsChange: setSelectedRows,
      getUnselectableReason,
      bulkActions,
    },
  };
};

export default OpalTable;
