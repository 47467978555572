import {
  EntityType,
  GeneralSettingType,
  IdpConnectionType,
  IdpConnectionUserAttributeUseAs,
  OidcProviderType,
  OrganizationSettingsFragment,
} from "api/generated/graphql";
import { SamlApp } from "components/auth/SamlApp";
import { IdpHrIntegrationAppV3 } from "components/integrations/IdpHrIntegrationAppV3";
import { Divider } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useContext } from "react";
import { useHistory } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import AppContext from "views/app/AppContext";
import AccessExpiringNotificationSetting from "views/settings/AccessExpiringNotificationSetting";
import { AuditTicketProviderSetting } from "views/settings/AuditTicketProviderSetting";
import ErrorNotificationSetting from "views/settings/ErrorHandlerOwnerSetting";
import MaxGroupDurationSetting from "views/settings/MaxGroupDurationSetting";
import MaxResourceDurationSetting from "views/settings/MaxResourceDurationSetting";
import { OidcMultiStepModal } from "views/settings/oidc/OidcMultiStepModal";
import OrgContext from "views/settings/OrgContext";
import { OrgSettingSwitchV3 } from "views/settings/OrgSettingSwitchV3";
import { OrgSettingSwitchWithModalV3 } from "views/settings/OrgSettingSwitchWithModalV3";
import { RoleCreationSetting } from "views/settings/RoleCreationSetting";
import SessionLifetimeSetting from "views/settings/SessionLifetimeSetting";

import * as styles from "../../components/event_streaming/EventStreamConnections.css";
import TableHeader from "../../components/ui/table/TableHeader";
import TabsV3 from "../../components/ui/tabs/TabsV3";
import { CustomerSupportToggleSetting } from "./customer_support/CustomerSupportToggleSetting";
import { MFASettings } from "./MFASettings";

export type OrgSettingAttributes = {
  initialStateOn: boolean;
  settingType: GeneralSettingType;
};

type OrgSettingsPropsV3 = {
  title: string;
  orgSettings: OrganizationSettingsFragment | null;
};

export const AdvancedOrgSettingsV3: React.FC<OrgSettingsPropsV3> = (props) => {
  const { appState } = useContext(AppContext);

  const allSettings = props.orgSettings?.generalSettings || [];
  return (
    <OrgSettingsSection title={props.title}>
      <OrgSettingSwitchV3
        labelText={"Enable user impersonation"}
        tooltipText={
          "When on, allows users with access to Opal's User Impersonation role to perform read-only impersonations of other users in the organization."
        }
        setting={{
          initialStateOn: allSettings.includes(
            GeneralSettingType.UserImpersonation
          ),
          settingType: GeneralSettingType.UserImpersonation,
        }}
        currentSettings={allSettings}
      />
      {props.orgSettings?.customerSupportGatingAllowed && (
        <CustomerSupportToggleSetting
          currentCustomerSupportExpiresAt={
            props.orgSettings?.customerSupportExpiresAt || ""
          }
        />
      )}
      {(!appState.isOnPrem || window.location.hostname === "us.opal.dev") && (
        <OrgSettingSwitchV3
          labelText={"Enable LogRocket monitoring"}
          tooltipText={
            "When on, allows for user sessions to be monitored through LogRocket, which helps Opal debug support requests. Sensitive customer data, such as API keys, is obfuscated in session monitoring. Sessions are kept for a maximum of 30 days before they are deleted."
          }
          setting={{
            initialStateOn: !allSettings.includes(
              GeneralSettingType.LogRocketDisabled
            ),
            settingType: GeneralSettingType.LogRocketDisabled,
          }}
          currentSettings={allSettings}
        />
      )}
      <ErrorNotificationSetting
        currentSettings={allSettings}
        currentErrorNotificationSetting={
          props.orgSettings?.errorNotificationSetting
        }
        ownerId={props.orgSettings?.syncErrorHandlerOwnerId}
      />

      <AccessExpiringNotificationSetting
        currentSettings={allSettings}
        currentSchedule={props.orgSettings?.accessExpiringNotifications || []}
      />
    </OrgSettingsSection>
  );
};

export type IDPAndHRSettingsProps = {
  title: string;
  orgSettings: OrganizationSettingsFragment | null;
};

export const IDPAndHRSettingsV3 = (props: IDPAndHRSettingsProps) => {
  const { orgState } = useContext(OrgContext);
  const hasMultiIdp = useFeatureFlag(FeatureFlag.MultipleUserAttributeSources);

  const allSettings = props.orgSettings?.generalSettings || [];
  const idpConnections = orgState.orgIdpConnections;
  const isConnected = orgState.isIdpEnabled;
  const history = useHistory();

  const importsSecondaryEmail = idpConnections.some((idp) =>
    idp.userAttributeImportMappings.some(
      (mapping) =>
        mapping.useAs === IdpConnectionUserAttributeUseAs.SecondaryEmail
    )
  );

  let idpApps = [];

  if (hasMultiIdp) {
    idpApps = idpConnections.map((idp) => {
      return <IdpHrIntegrationAppV3 idpConnection={idp} />;
    });

    if (idpApps.length === 0) {
      idpApps.push(
        <div className={styles.emptyStateContainer}>
          <div className={styles.emptyStateTitle}>{"IDP/HRIS Connections"}</div>
          <div>
            Add connections to import user attributes from HR Information
            Systems or Identity Providers
          </div>
        </div>
      );
    }
  } else {
    const connection = idpConnections.length > 0 ? idpConnections[0] : null;
    idpApps.push(<IdpHrIntegrationAppV3 idpConnection={connection} />);
  }

  return (
    <OrgSettingsSection title={props.title}>
      {hasMultiIdp && [
        idpApps.length > 1 && (
          <TabsV3
            tabInfos={[
              {
                title: "Settings",
                isSelected: true,
                onClick: () => {
                  history.push("/settings#idp-and-hr");
                },
              },
              {
                title: "Attribute Mapping",
                isSelected: false,
                onClick: () => {
                  history.push("/settings/idp/mappings");
                },
              },
            ]}
          />
        ),
        <TableHeader
          entityType={EntityType.IdpConnection}
          totalNumRows={idpConnections.length}
          defaultRightActions={[
            {
              label: "IDP/HRIS Connection",
              type: "main",
              onClick: () => {
                history.push("/settings/idp/browse");
              },
              iconName: "plus",
            },
          ]}
        />,
      ]}
      {idpApps}
      {isConnected && <Divider />}
      {isConnected && (
        <OrgSettingSwitchWithModalV3
          labelText={"Delete users when deprovisioned in IDP/HRIS"}
          tooltipText={`If enabled, when a user is deprovisioned in the IDP/HRIS, Opal will automatically delete them in Opal and remove their access.`}
          modalTitle={"Configure user deprovisioning setting"}
          setting={{
            initialStateOn: allSettings.includes(
              GeneralSettingType.UserDeprovisionDeleteDeprovisioned
            ),
            settingType: GeneralSettingType.UserDeprovisionDeleteDeprovisioned,
          }}
          currentSettings={allSettings}
        />
      )}
      {importsSecondaryEmail && (
        <OrgSettingSwitchWithModalV3
          labelText={"Auto-merge Opal users by secondary email"}
          tooltipText={
            "When this setting is enabled, Opal users whose email matches the secondary email of another Opal user will be auto-merged during IDP sync."
          }
          modalTitle={"Configure auto-merge setting"}
          modalWarning={
            "Auto-merging relies on the secondary email(s) imported from your IDP to be accurate. Inaccurate secondary emails will result in unintended and potentially irreversible auto-merges."
          }
          setting={{
            initialStateOn: allSettings.includes(
              GeneralSettingType.AutoMergeUsersByEmail
            ),
            settingType: GeneralSettingType.AutoMergeUsersByEmail,
          }}
          currentSettings={allSettings}
        />
      )}
    </OrgSettingsSection>
  );
};

export const AuthOrgSettingsV3 = (props: OrgSettingsPropsV3) => {
  const { orgState } = useContext(OrgContext);

  const allSettings = props.orgSettings?.generalSettings || [];
  const idpConnections = orgState.orgIdpConnections;
  const hasOidcMfa = useFeatureFlag(FeatureFlag.OidcMFAForOpalActions);

  let initialState = allSettings.includes(
    GeneralSettingType.UseOidcMfaForGatingOpalActions
  )
    ? GeneralSettingType.UseOidcMfaForGatingOpalActions
    : allSettings.includes(GeneralSettingType.UseOktaMfaForGatingOpalActions)
    ? GeneralSettingType.UseOktaMfaForGatingOpalActions
    : null;

  return (
    <OrgSettingsSection title={props.title}>
      <OrgSettingSwitchV3
        labelText={"Require Opal MFA for logins"}
        tooltipText={
          "If enabled, users are required to set up multi-factor authentication with Opal and login with MFA. Note: If you've set up a SAML provider, we recommend you turn this setting OFF in order to use your SAML provider's MFA instead of Opal's."
        }
        setting={{
          initialStateOn: allSettings.includes(
            GeneralSettingType.RequireOpalMfaForLogins
          ),
          settingType: GeneralSettingType.RequireOpalMfaForLogins,
        }}
        currentSettings={allSettings}
      />
      {!hasOidcMfa &&
        idpConnections.some(
          (idp) => idp.idpConnectionType === IdpConnectionType.Okta
        ) && (
          <OrgSettingSwitchV3
            labelText={"Use Okta MFA for gating Opal actions"}
            tooltipText={
              "If enabled, Opal validates users via their Okta MFA, rather than Opal's separate MFA provider. This lets you consolidate MFA providers and manage MFA resets in Okta."
            }
            setting={{
              initialStateOn: allSettings.includes(
                GeneralSettingType.UseOktaMfaForGatingOpalActions
              ),
              settingType: GeneralSettingType.UseOktaMfaForGatingOpalActions,
            }}
            currentSettings={allSettings}
          />
        )}
      {hasOidcMfa && (
        <MFASettings
          currentSettings={allSettings}
          initialState={initialState}
        />
      )}
      <SessionLifetimeSetting
        labelText={"Login session lifetime"}
        tooltipText={`Specifies how long a user can stay logged into Opal before re-authenticating. The maximum allowed setting is 30 days.`}
        currentSettings={allSettings}
        authSessionExpirationInMinutes={
          props.orgSettings?.authSessionExpirationInMinutes
        }
      />
      <SamlApp />
      <OrgSettingSwitchV3
        labelText="Only allow Opal Admins to login"
        tooltipText="If enabled, non-admins will not be able to see this org in the list of org options when signing in."
        setting={{
          initialStateOn: allSettings.includes(
            GeneralSettingType.DisableNonAdminLogins
          ),
          settingType: GeneralSettingType.DisableNonAdminLogins,
        }}
        currentSettings={allSettings}
      />
    </OrgSettingsSection>
  );
};

export const AccessRequestsOrgSettingsV3 = (props: OrgSettingsPropsV3) => {
  const allSettings = props.orgSettings?.generalSettings || [];
  return (
    <OrgSettingsSection title={props.title}>
      <MaxResourceDurationSetting
        labelText={"Max request duration for resources"}
        tooltipText={`The maximum duration a resource can be requested for. If unset, users can request indefinite access. When set, this will only apply to future requests.`}
        currentSettings={allSettings}
        maxResourceDurationInMinutes={
          props.orgSettings?.maxResourceDurationInMinutes
        }
      />
      <MaxGroupDurationSetting
        labelText={"Max request duration for groups"}
        tooltipText={`The maximum duration a group can be requested for. If unset, users can request indefinite access. When set, this will only apply to future requests.`}
        currentSettings={allSettings}
        maxGroupDurationInMinutes={props.orgSettings?.maxGroupDurationInMinutes}
      />
      <OrgSettingSwitchV3
        labelText={"Allow access approvals from Opal integrations"}
        tooltipText={
          "If enabled, access requests can be approved or denied from outside the Opal app, such as from Slack."
        }
        setting={{
          initialStateOn: allSettings.includes(
            GeneralSettingType.SlackApprovals
          ),
          settingType: GeneralSettingType.SlackApprovals,
        }}
        currentSettings={allSettings}
      />
      <OrgSettingSwitchV3
        labelText={"Require manager approval for all access requests"}
        tooltipText={
          "If enabled, all access requests must include the requester's manager as a mandatory reviewer."
        }
        setting={{
          initialStateOn: allSettings.includes(
            GeneralSettingType.RequireManagerCc
          ),
          settingType: GeneralSettingType.RequireManagerCc,
        }}
        currentSettings={allSettings}
      />
      <OrgSettingSwitchV3
        labelText={"Requests must link ticket that auto-expires access"}
        tooltipText={
          "If enabled, all access requests must be linked to a support ticket that automatically expires access when the ticket is closed."
        }
        setting={{
          initialStateOn: allSettings.includes(
            GeneralSettingType.RequireSupportTicket
          ),
          settingType: GeneralSettingType.RequireSupportTicket,
        }}
        currentSettings={allSettings}
      />
      <AuditTicketProviderSetting
        auditTicketProvider={
          props.orgSettings?.auditTicketProvider ?? undefined
        }
      />
      {useFeatureFlag(FeatureFlag.OpalRequesterRole) && (
        <OrgSettingSwitchV3
          labelText={"Enable global requester role"}
          tooltipText={
            "If enabled, an Opal Global Requester role will be available, users with this role will be able to request access for any other user."
          }
          setting={{
            initialStateOn: allSettings.includes(
              GeneralSettingType.GlobalRequesterRole
            ),
            settingType: GeneralSettingType.GlobalRequesterRole,
          }}
          currentSettings={allSettings}
        />
      )}
    </OrgSettingsSection>
  );
};

export const AWSOrgSettingsV3 = (props: OrgSettingsPropsV3) => {
  const allSettings = props.orgSettings?.generalSettings || [];
  const hasAwsLegacy = useFeatureFlag(FeatureFlag.AWSLegacy);
  return (
    <OrgSettingsSection title={props.title}>
      <OidcMultiStepModal oidcProviderType={OidcProviderType.AwsSession} />
      {hasAwsLegacy && (
        <RoleCreationSetting
          labelText={"AWS (Legacy) IAM role creation reviewer"}
          tooltipText={
            "This sets the owner that reviews requests to create dynamic cloud IAM roles."
          }
          currentSettings={allSettings}
          roleCreationReviewerOwner={
            props.orgSettings?.roleCreationReviewerOwner ?? undefined
          }
        />
      )}
    </OrgSettingsSection>
  );
};

type OrgSettingsSectionProps = {
  title: string;
};

export const OrgSettingsSection: React.FC<OrgSettingsSectionProps> = (
  props
) => {
  return (
    <>
      <div
        className={sprinkles({
          fontSize: "headlineXs",
          marginBottom: "xl",
        })}
      >
        {props.title}
      </div>
      {props.children}
    </>
  );
};
