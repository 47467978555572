import { useListConfigurationsTemplatesQuery } from "api/generated/graphql";
import { Banner, Select } from "components/ui";
import { logError } from "utils/logging";

export interface ConfigurationTemplate {
  name: string;
  id: string;
}

interface ConfigurationTemplateDropdownProps {
  selectedConfigurationTemplateId?: string;
  onSelectConfigurationTemplate: (
    configurationTemplate?: ConfigurationTemplate
  ) => void;
  clearable?: boolean;
  placeholder?: string;
  disabled?: boolean;
}

const ConfigurationTemplateDropdown = (
  props: ConfigurationTemplateDropdownProps
) => {
  const { data, error, loading } = useListConfigurationsTemplatesQuery();
  if (error) {
    logError(error, `failed to list configuration templates`);
    return (
      <Banner message="Failed to list configuration templates" type="error" />
    );
  }

  const allConfigurationTemplates = data?.configurationTemplates.configurationTemplates.map(
    (ct) => ({
      name: ct.name,
      id: ct.id,
    })
  );

  const selectedConfigurationTemplate = allConfigurationTemplates?.find(
    (o) => o.id === props.selectedConfigurationTemplateId
  );

  return (
    <Select
      options={allConfigurationTemplates || []}
      value={selectedConfigurationTemplate}
      getOptionLabel={(option) => option.name}
      getIcon={() => ({ type: "name", icon: "template" })}
      onChange={props.onSelectConfigurationTemplate}
      loading={loading}
      clearable={props.clearable}
      placeholder={props.placeholder}
      disabled={props.disabled}
    />
  );
};

export default ConfigurationTemplateDropdown;
