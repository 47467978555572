import React from "react";

import * as styles from "./RowBar.css";

interface Props {
  padding?: "xs" | "default";
  color?: "blue" | "green" | "yellow";
}

const RowBar: React.FC<Props> = (props) => {
  const { padding = "default", color } = props;
  return (
    <div className={styles.container({ padding, color })}>{props.children}</div>
  );
};

export default RowBar;
