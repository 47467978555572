import {
  Maybe,
  ThirdPartyProvider,
  useCreateThirdPartyIntegrationMutation,
} from "api/generated/graphql";
import { useToast } from "components/toast/Toast";
import { useHistory, useLocation } from "react-router-dom";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { useMountEffect } from "utils/hooks";
import { logError, logWarning } from "utils/logging";
import { FullPageLoading } from "views/loading/FullPageLoading";

// GitLabConnectionCallback is a dedicated component for handling the auth redirect
// and parsing/removing the auth token fragment from the URL for GitLab integration.
export const GitLabConnectionCallback = () => {
  const location = useLocation();

  const { displaySuccessToast, displayErrorToast } = useToast();

  const history = useHistory();
  const [
    createConnectionIntegration,
  ] = useCreateThirdPartyIntegrationMutation();

  useMountEffect(() => {
    async function createIntegrationWrapper() {
      const query = new URLSearchParams(location.search);
      const code = query.get("code");
      const state = query.get("state");

      let connectionId: Maybe<string> | undefined = "";

      if (code && state) {
        try {
          // We have received callback parameters from GitLab,
          // so we need to forward them on to the Opal server.
          const { data } = await createConnectionIntegration({
            variables: {
              input: {
                thirdPartyProvider: ThirdPartyProvider.GitLabConnection,
                codeFlow: {
                  code: code,
                  state: state,
                },
              },
            },
          });
          switch (data?.createThirdPartyIntegration.__typename) {
            case "CreateThirdPartyIntegrationResult":
              connectionId =
                data.createThirdPartyIntegration.thirdPartyIntegration
                  .connectionId;
              displaySuccessToast("Success: GitLab account linked");
              break;
            case "ThirdPartyConnectionNotMatchedError":
              logWarning(new Error(data.createThirdPartyIntegration.message));
              displayErrorToast(data.createThirdPartyIntegration.message);
              break;
            default:
              logError(new Error(`failed to link GitLab account`));
              displayErrorToast("Error: failed to link GitLab account");
          }
        } catch (error) {
          logError(error, `failed to link GitLab account`);
          displayErrorToast("Error: failed to link GitLab account");
        }
        history.replace(
          getResourceUrl(EntityTypeDeprecated.Connection, connectionId)
        );
      } else {
        displayErrorToast("Error: failed to link GitLab account");
        history.replace(
          getResourceUrl(EntityTypeDeprecated.Connection, connectionId)
        );
      }
    }
    createIntegrationWrapper();
  });

  return <FullPageLoading />;
};

export default GitLabConnectionCallback;
