import {
  ConnectionType,
  EntityType,
  RemoveResourceTagInput,
  ResourceType,
  ServiceType,
  TagFragment,
  useRemoveResourceTagsMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import ConnectionLabel from "components/label/item_labels/ConnectionLabel";
import { ResourceLabel } from "components/label/Label";
import ServiceTypeLabel from "components/label/ServiceTypeLabel";
import { useToast } from "components/toast/Toast";
import { Modal } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import _ from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { logError } from "utils/logging";
import { getResourceSublabel } from "utils/resources";
import { useTransitionTo } from "utils/router/hooks";

type TagResourcesTableProps = {
  tag: TagFragment;
};

interface ResourceRow {
  id: string;
  resource?: {
    id?: string;
    name?: string;
    iconUrl?: string | null;
    resourceType: ResourceType;
  } | null;
  connection?: {
    name: string;
    connectionType?: ConnectionType;
  } | null;
  connectionId?: string;
  tagSource: ServiceType;
  createdAt: string;
}

const RESOURCES_COLUMNS: Header<ResourceRow>[] = [
  {
    id: "resource",
    label: "Name",
    sortable: false,
    customCellRenderer: ({ resource }) => (
      <ResourceLabel
        text={resource?.name}
        subText={resource ? getResourceSublabel(resource) : "--"}
        entityType={EntityTypeDeprecated.Resource}
        resourceType={resource?.resourceType}
        bold={true}
        icon={resource?.iconUrl}
        iconLarge={true}
        pointerCursor={true}
      />
    ),
  },
  {
    id: "connection",
    label: "App",
    sortable: false,
    customCellRenderer: ({ connection, connectionId }) => (
      <ConnectionLabel
        text={connection?.name}
        connectionId={connectionId}
        connectionType={connection?.connectionType}
      />
    ),
  },
  {
    id: "tagSource",
    label: "Tag Source",
    sortable: false,
    customCellRenderer: (row) => (
      <ServiceTypeLabel serviceType={row.tagSource} />
    ),
  },
  {
    id: "createdAt",
    label: "Created",
    sortable: false,
    customCellRenderer: (row) => moment(new Date(row.createdAt)).fromNow(),
  },
];

type RemoveResourcesFromTagModalProps = {
  idsToRemove: string[];
  setShowModal: (show: boolean) => void;
  setSelectedItemIds: (ids: string[]) => void;
};

const RemoveResourcesFromTagModal = ({
  idsToRemove,
  setShowModal,
  setSelectedItemIds,
}: RemoveResourcesFromTagModalProps) => {
  const [removeTagResources, { loading }] = useRemoveResourceTagsMutation();
  const { displaySuccessToast, displayErrorToast } = useToast();

  const removeSelectedResources = async () => {
    const resourceTagsToRemove: RemoveResourceTagInput[] = idsToRemove.map(
      (resourceTagId) => ({ resourceTagId: resourceTagId })
    );

    try {
      const { data } = await removeTagResources({
        variables: {
          input: {
            resourceTags: resourceTagsToRemove,
          },
        },
        refetchQueries: ["ResourceTags", "Tag"],
      });

      switch (data?.removeResourceTags.__typename) {
        case "RemoveResourceTagsResult": {
          let resourceTags: string[] = [];
          data.removeResourceTags.entries.forEach((entry) => {
            switch (entry.__typename) {
              case "RemoveResourceTagsEntryResult":
                resourceTags.push(entry.resourceTag.resourceId);
                break;
            }
          });
          if (resourceTags.length !== idsToRemove.length) {
            logError(new Error(`removed tag to some, but not all resources`));
            displayErrorToast(
              `Error: removed tag to some, but not all resources`
            );
            setSelectedItemIds(_.difference(idsToRemove, resourceTags));
          } else {
            displaySuccessToast(
              `Success: removed tag from ${pluralize(
                "resource",
                idsToRemove.length,
                true
              )}`
            );
            setSelectedItemIds([]);
          }
          break;
        }
        case "ResourceTagNotFoundError": {
          logError(new Error(data.removeResourceTags.message));
          displayErrorToast(data.removeResourceTags.message);
          break;
        }
        default:
          logError(new Error(`failed to remove tag from selected resources`));
          displayErrorToast(
            "Error: failed to remove tag from selected resources"
          );
      }
    } catch (error) {
      logError(error, `failed to remove group tag from selected resources`);
      displayErrorToast("Error: failed to remove tag from selected resources");
    }

    setShowModal(false);
  };

  return (
    <Modal
      isOpen
      onClose={() => setShowModal(false)}
      title="Remove Tag Resources"
    >
      <Modal.Body>
        Are you sure you want to remove{" "}
        {pluralize("resource", idsToRemove.length, true)} from this tag?
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel={"Remove"}
        onPrimaryButtonClick={() => {
          removeSelectedResources();
          setShowModal(false);
        }}
        primaryButtonLoading={loading}
        primaryButtonTypeV3={"danger"}
      />
    </Modal>
  );
};

const TagResourcesTable = ({ tag }: TagResourcesTableProps) => {
  const transitionTo = useTransitionTo();
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [showRemoveResourcesModal, setShowRemoveResourcesModal] = useState(
    false
  );
  const { authState } = useContext(AuthContext);
  const isAdmin = authState.user?.isAdmin;
  const history = useHistory();

  const rows: ResourceRow[] =
    tag?.tagResources.map(({ id, resource, source, createdAt }) => {
      return {
        id: id,
        resource: resource,
        connection: resource?.connection,
        connectionId: resource?.connectionId,
        tagSource: source,
        createdAt: createdAt,
      };
    }) || [];

  const allSelected = rows.every((row) => selectedItemIds.includes(row?.id));

  const table = (
    <Table
      rows={rows}
      getRowId={(row) => row.id}
      columns={RESOURCES_COLUMNS}
      totalNumRows={rows.length}
      defaultSortBy="resource"
      emptyState={{
        title: "No Resources",
      }}
      onRowClick={(row, event) => {
        transitionTo(
          {
            pathname: `/resources/${row.resource?.id}`,
          },
          event
        );
      }}
      checkedRowIds={new Set(selectedItemIds)}
      onCheckedRowsChange={(checkedRowIds, checked) => {
        if (checked) {
          setSelectedItemIds((prev) => [...prev, ...checkedRowIds]);
          return;
        } else {
          setSelectedItemIds((prev) =>
            prev.filter((id) => !checkedRowIds.includes(id))
          );
        }
      }}
      selectAllChecked={allSelected}
      onSelectAll={(checked) => {
        if (checked) {
          const allIds = rows.map((row) => row.id);
          setSelectedItemIds(allIds);
        } else {
          setSelectedItemIds([]);
        }
      }}
    />
  );

  return (
    <div>
      <TableHeader
        entityType={EntityType.Resource}
        totalNumRows={rows.length}
        selectedNumRows={selectedItemIds.length}
        defaultRightActions={
          isAdmin
            ? [
                {
                  label: "Add Resources",
                  type: "mainSecondary",
                  iconName: "plus",
                  onClick: () => history.push(`/tags/${tag.id}/add-resources`),
                },
              ]
            : []
        }
        bulkRightActions={[
          {
            label: "Remove",
            type: "danger",
            iconName: "trash",
            onClick: () => setShowRemoveResourcesModal((prev) => !prev),
          },
        ]}
      />
      {table}
      {showRemoveResourcesModal && (
        <RemoveResourcesFromTagModal
          key={`remove-resources-from-tag-modal`}
          idsToRemove={selectedItemIds}
          setShowModal={setShowRemoveResourcesModal}
          setSelectedItemIds={setSelectedItemIds}
        />
      )}
    </div>
  );
};

export default TagResourcesTable;
