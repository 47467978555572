import { Maybe } from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import messageModalStyles from "components/modals/update/MessageModal.module.scss";
import { Modal } from "components/ui";
import React from "react";

type MessageModalProps = {
  title: string;
  subtitle?: string;
  message: React.ReactChild;
  isModalOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  value: string;
  isStepModal?: boolean;
  onBack?: () => void;
  inputTypePassword?: boolean;
  loading?: boolean;
  errorMessage?: Maybe<string>;
};

/**
 * @deprecated Use Modal from components/ui
 */
export const MessageModal = (props: MessageModalProps) => {
  return (
    <Modal
      isOpen={props.isModalOpen}
      onClose={props.onClose}
      title={props.title}
      subtitle={props.subtitle}
    >
      <Modal.Body>
        <div className={messageModalStyles.modalMessageContainer}>
          {props.message}
        </div>
        {props.errorMessage && (
          <ModalErrorMessage errorMessage={props.errorMessage} />
        )}
      </Modal.Body>
      <Modal.Footer
        onPrimaryButtonClick={props.onSubmit}
        primaryButtonLabel={props.isStepModal ? "Next" : ""}
        primaryButtonLoading={props.loading}
        onSecondaryButtonClick={props.onBack}
        secondaryButtonLabel="Back"
      />
    </Modal>
  );
};

export default MessageModal;
