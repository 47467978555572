import { gql, useFragment } from "@apollo/client";
import {
  OpalAppVisibilityCellFragment,
  Visibility,
} from "api/generated/graphql";
import { Skeleton } from "components/ui";

const OpalAppVisibilityCell = (props: { appID: string }) => {
  const { complete, data } = useFragment<OpalAppVisibilityCellFragment>({
    fragment: gql`
      fragment OpalAppVisibilityCell on App {
        visibility
      }
    `,
    fragmentName: "OpalAppVisibilityCell",
    from: {
      __typename: "App",
      id: props.appID,
    },
  });

  return complete ? (
    <span>{data.visibility === Visibility.Global ? "Global" : "Limited"}</span>
  ) : (
    <Skeleton variant="text" width="50px" />
  );
};

export default OpalAppVisibilityCell;
