import {
  ConnectionPreviewLargeFragment,
  IntegrationType,
  Maybe,
  ThirdPartyIntegrationFragment,
  ThirdPartyProvider,
  useInitThirdPartyIntegrationAuthFlowMutation,
  useThirdPartyIntegrationQuery,
} from "api/generated/graphql";
import { useToast } from "components/toast/Toast";
import { Button, FormRow, Label, Tooltip } from "components/ui";
import { SectionEntry } from "components/viewer/Viewer";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { logError } from "utils/logging";

type ConnectionOAuthTokenRowProps = {
  connection: ConnectionPreviewLargeFragment;
};

export const ConnectionOAuthTokenRow = (
  props: ConnectionOAuthTokenRowProps
) => {
  const { displayErrorToast } = useToast();

  const { data, error, loading } = useThirdPartyIntegrationQuery({
    variables: {
      input: {
        thirdPartyProvider: ThirdPartyProvider.GitLabConnection,
        connectionId: props.connection.id,
      },
    },
  });

  let thirdPartyIntegration:
    | Maybe<ThirdPartyIntegrationFragment>
    | undefined = null;
  if (data) {
    switch (data.thirdPartyIntegration.__typename) {
      case "ThirdPartyIntegrationResult":
        thirdPartyIntegration =
          data.thirdPartyIntegration.thirdPartyIntegration;
        break;
      case "ThirdPartyIntegrationNotFoundError":
        // do nothing
        break;
      default:
        logError(new Error(`failed to get app`));
    }
  } else if (error) {
    logError(error, `failed to get app`);
  }

  const [
    initConnectionAuthFlow,
  ] = useInitThirdPartyIntegrationAuthFlowMutation();

  if (loading) {
    return <SectionEntry title={"OAuth Access Token"} />;
  }

  const resetAuthToken = async () => {
    try {
      const { data } = await initConnectionAuthFlow({
        variables: {
          input: {
            thirdPartyIntegrationType: IntegrationType.Connection,
            thirdPartyProvider: ThirdPartyProvider.GitLabConnection,
            connectionId: props.connection.id,
          },
        },
      });
      switch (data?.initThirdPartyIntegrationAuthFlow.__typename) {
        case "InitThirdPartyIntegrationAuthFlowResult":
          window.location.href = data.initThirdPartyIntegrationAuthFlow.url;
          break;
        default:
          throw new Error(
            `failed to initiate ${_.upperFirst(
              _.camelCase(ThirdPartyProvider.GitLabConnection)
            )} auth flow`
          );
      }
    } catch (error) {
      logError(error);
      displayErrorToast(
        `Error: failed to initiate ${_.upperFirst(
          _.camelCase(ThirdPartyProvider.GitLabConnection)
        )} auth flow`
      );
    }
  };

  const oauthConnected = !!thirdPartyIntegration;
  const tooltipText = oauthConnected
    ? "There is already an OAuth token set up for your GitLab instance. To connect a new token, click the button below."
    : "Grant Opal access to sync with your GitLab instance by linking a GitLab OAuth admin token here.";

  return (
    <FormRow title={"OAuth Access Token"} tooltipText={tooltipText}>
      {oauthConnected ? (
        <div className={sprinkles({ display: "flex", gap: "md" })}>
          <Label
            icon={{ type: "name", icon: "check" }}
            label="OAuth token set up"
          />
          <Tooltip tooltipText="Click here to connect a new OAuth token if Opal fails to sync with your GitLab instance.">
            <Button
              leftIconName="key"
              onClick={resetAuthToken}
              outline
              size="sm"
              round
            />
          </Tooltip>
        </div>
      ) : (
        <div>
          <Button
            label="Connect OAuth Admin Account"
            onClick={resetAuthToken}
          />
        </div>
      )}
    </FormRow>
  );
};

export default ConnectionOAuthTokenRow;
