import { Maybe } from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { FormGroup, Input, Modal } from "components/ui";
import React from "react";

type EditDescriptionModalProps = {
  title: string;
  subtitle?: string;
  sectionTitle?: string;
  isModalOpen: boolean;
  onChange: (description: string) => void;
  onClose: () => void;
  onSubmit: () => void;
  value: string;
  isStepModal?: boolean;
  onBack?: () => void;
  loading?: boolean;
  errorMessage?: Maybe<string>;
  submitDisabled?: boolean;
  shouldCloseOnOverlayClick?: boolean;
  primaryButtonLabel?: string;
};

export const EditDescriptionModal = (props: EditDescriptionModalProps) => {
  return (
    <Modal
      title={props.title}
      subtitle={props.subtitle}
      isOpen={props.isModalOpen}
      onClose={props.onClose}
      disableBackdropClick={!props.shouldCloseOnOverlayClick}
    >
      <Modal.Body>
        <FormGroup label={props.sectionTitle ?? "Description"}>
          <Input
            type="textarea"
            value={props.value}
            onChange={(val) => props.onChange(val)}
          />
        </FormGroup>
        {props.errorMessage && (
          <ModalErrorMessage errorMessage={props.errorMessage} />
        )}
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel={
          props.primaryButtonLabel ?? (props.isStepModal ? "Next" : "Save")
        }
        primaryButtonDisabled={props.submitDisabled}
        onPrimaryButtonClick={props.onSubmit}
        secondaryButtonLabel={
          props.isStepModal && props.onBack ? "Back" : undefined
        }
        onSecondaryButtonClick={props.onBack}
      />
    </Modal>
  );
};

export default EditDescriptionModal;
