import {
  EntityType,
  GroupType,
  OwnedGroupFragment,
} from "api/generated/graphql";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { Icon, Label } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import React from "react";
import { getResourceUrlNew } from "utils/common";
import { useTransitionTo } from "utils/router/hooks";

interface OwnerGroupsTableRow {
  id: string;
  name: string;
  type: GroupType;
  admin: boolean;
  reviewer: boolean;
}

type OwnerGroupsTablePropsV3 = {
  ownedGroups: OwnedGroupFragment[];
};

export const OwnerGroupsTableV3 = (props: OwnerGroupsTablePropsV3) => {
  const transitionTo = useTransitionTo();

  const OWNER_RESOURCES_COLUMNS: Header<OwnerGroupsTableRow>[] = [
    { id: "name", label: "Name", sortable: true },
    {
      id: "type",
      label: "Type",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <Label
            label={getGroupTypeInfo(row.type)?.name ?? ""}
            icon={{ type: "entity", entityType: row.type }}
          />
        );
      },
    },
    {
      id: "admin",
      label: "Admin",
      sortable: false,
      customCellRenderer: (row) => {
        return (
          <span>
            {row.admin ? <Icon name="check-circle" size="sm" /> : "—"}
          </span>
        );
      },
    },
    {
      id: "reviewer",
      label: "Reviewer",
      sortable: false,
      customCellRenderer: (row) => {
        return (
          <span>
            {row.reviewer ? <Icon name="check-circle" size="sm" /> : "—"}
          </span>
        );
      },
    },
  ];

  const rows = [
    ...props.ownedGroups.map<OwnerGroupsTableRow>((item) => {
      return {
        id: item.group.id,
        name: item.group.name,
        typename: item.group.__typename,
        type: item.group.groupType,
        admin: item.isAdmin,
        reviewer: item.isReviewer,
      };
    }),
  ];

  return (
    <div>
      <TableHeader entityType={EntityType.Group} totalNumRows={rows.length} />
      <Table
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        columns={OWNER_RESOURCES_COLUMNS}
        defaultSortBy="name"
        onRowClick={(row, event) => {
          transitionTo(
            {
              pathname: getResourceUrlNew({
                entityId: row.id,
                entityType: EntityType.Group,
              }),
            },
            event
          );
        }}
      />
    </div>
  );
};

export default OwnerGroupsTableV3;
