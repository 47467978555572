import { Maybe, useForfeitGroupMutation } from "api/generated/graphql";
import ConfirmModal from "components/modals/update/ConfirmModal";
import { Button, ButtonV3, Tooltip } from "components/ui";
import React, { useContext, useState } from "react";
import useLogEvent from "utils/analytics";
import { logError, logWarning } from "utils/logging";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import OrgContext from "views/settings/OrgContext";

type ForfeitableGroup = {
  id: string;
  groupType: string;
};

export type ForfeitGroupModalProps = {
  group: ForfeitableGroup;
  onSuccessCallback?: () => void;
  hasV3?: boolean;
  buttonSize?: PropsFor<typeof ButtonV3>["size"];
  noIcon?: boolean;
};

export const ForfeitGroupButton = (props: ForfeitGroupModalProps) => {
  const logEvent = useLogEvent();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [showModal, setShowModal] = useState(false);
  const [polling, setPolling] = useState(false);
  const startPushTaskPoll = usePushTaskLoader();

  const [forfeitGroup, { loading }] = useForfeitGroupMutation();

  const modalReset = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  const { orgState } = useContext(OrgContext);

  const confirmModal = (
    <ConfirmModal
      title={`Forfeit group`}
      message={`Are you sure you want to forfeit direct access to this group? Note: indirect access
        to this group through group membership will not be affected.`}
      isModalOpen={showModal}
      onClose={() => {
        modalReset();
      }}
      onSubmit={async () => {
        logEvent({
          name: "apps_forfeit_click",
          properties: {
            itemType: props.group.groupType,
          },
        });

        try {
          const { data } = await forfeitGroup({
            variables: {
              input: {
                groupId: props.group.id,
              },
            },
          });
          switch (data?.forfeitGroup.__typename) {
            case "ForfeitGroupResult":
              modalReset();
              if (data.forfeitGroup.taskId) {
                setPolling(true);
              }
              // Always call this, since it also handles empty `taskId`.
              startPushTaskPoll(data.forfeitGroup.taskId, {
                refetchOnComplete: [
                  {
                    groupId: props.group.id,
                  },
                ],
                onComplete: () => {
                  setPolling(false);
                },
                messageOverride: {
                  loading: null,
                  success: `Success: forfeited direct access to group`,
                  error: `Error: could not forfeit access to group`,
                },
              });
              break;
            case "GroupNotFoundError":
              logWarning(new Error(data.forfeitGroup.message));
              setErrorMessage(data.forfeitGroup.message);
              break;
            default:
              logError(new Error(`group forfeit failed`));
              setErrorMessage(`Error: group forfeit failed`);
          }
        } catch (error) {
          logError(error, `group forfeit failed`);
          setErrorMessage(`Error: group forfeit failed`);
        }
      }}
      loading={loading}
      errorMessage={errorMessage}
      submitLabel={`Forfeit`}
    />
  );

  let forfeitButton = props.hasV3 ? (
    <ButtonV3
      label="Forfeit"
      leftIconName={props.noIcon ? undefined : "zap-off"}
      type="danger"
      onClick={() => {
        setShowModal(true);
      }}
      disabled={!!orgState.orgSettings?.isRemoteReadOnly}
      loading={polling}
      size={props.buttonSize}
    />
  ) : (
    <Button
      type="error"
      leftIconName="zap-off"
      label={"Forfeit"}
      onClick={() => {
        setShowModal(true);
      }}
      disabled={!!orgState.orgSettings?.isRemoteReadOnly}
      loading={polling}
      borderless
      size="md"
    />
  );

  if (orgState.orgSettings?.isRemoteReadOnly) {
    forfeitButton = (
      <Tooltip tooltipText="You can't forfeit your access to this group because your organization is in read only mode.">
        {forfeitButton}
      </Tooltip>
    );
  }

  return (
    <>
      {forfeitButton}
      {confirmModal}
    </>
  );
};
