import {
  Maybe,
  MessageChannelFragment,
  MessageChannelType,
} from "api/generated/graphql";
import CreateMessageChannel from "components/message_channel/CreateMessageChannel";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { List, Select } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";

import * as styles from "./AuditMessageChannelModal.css";

interface BaseProps {
  messageChannelOptions: MessageChannelFragment[];
  selectedChannels: MessageChannelFragment[];
  setSelectedChannels: (channels: MessageChannelFragment[]) => void;

  loading: boolean;
  errorMessage: Maybe<string>;
  channelNameToCreate: string;
  dropdownErrorMessage: Maybe<string>;
  adminTeamName: string;

  // For authorization
  resourceId?: string;
  groupId?: string;
}

export const AuditMessageChannelEdit = (props: BaseProps) => {
  const numTotalOptions =
    props.messageChannelOptions.length + props.selectedChannels.length;

  const handleSelectValue = (newMessageChannel?: MessageChannelFragment) => {
    if (!newMessageChannel) return;

    props.setSelectedChannels(
      _.uniqBy(
        [...props.selectedChannels, newMessageChannel],
        (messageChannel) => messageChannel.id
      )
    );
  };

  return (
    <>
      <div className={sprinkles({ marginBottom: "lg" })}>
        {numTotalOptions === 0 ? (
          "Create or link your first channel below:"
        ) : (
          <>
            <div className={sprinkles({ marginBottom: "sm" })}>
              <Select
                selectOnly
                onChange={handleSelectValue}
                options={props.messageChannelOptions}
                loading={props.loading}
                getOptionLabel={(messageChannel) => messageChannel.name}
                getIcon={(option) => ({
                  type: "name",
                  icon: option.isPrivate ? "lock" : "hash",
                })}
                placeholder="Search for channels"
                clearable
              />
            </div>

            <List
              items={props.selectedChannels}
              getItemLabel={(item) => item.name}
              getItemKey={(item) => item.id}
              onDeleteItem={(item) => {
                props.setSelectedChannels(
                  props.selectedChannels.filter(
                    (channel) => channel.id !== item.id
                  )
                );
              }}
              noItemsMessage="No items selected."
              getIcon={(item) => ({
                type: "name",
                icon: item.isPrivate ? "lock" : "hash",
              })}
            />
          </>
        )}
      </div>

      {props.dropdownErrorMessage && (
        <ModalErrorMessage errorMessage={props.dropdownErrorMessage} />
      )}
      {props.errorMessage && (
        <ModalErrorMessage errorMessage={props.errorMessage} />
      )}

      <div className={styles.createContainer}>
        <CreateMessageChannel
          channelNameToCreate={props.channelNameToCreate}
          adminTeamName={props.adminTeamName}
          channelType={MessageChannelType.Audit}
          onCreateChannel={(newChannel) => {
            props.setSelectedChannels(
              _.uniqBy(
                [...props.selectedChannels, newChannel],
                (messageChannel) => messageChannel.id
              )
            );
          }}
          resourceId={props.resourceId}
          groupId={props.groupId}
        />
      </div>
    </>
  );
};
