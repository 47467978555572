import { refetchQueries } from "api/ApiContext";
import {
  EventStreamFragment,
  PubsubPublishConnectionMetadataInput,
  PubsubPublishConnectionsCredentialsInput,
  PubsubPublishConnectionType,
  useCreateEventStreamMutation,
  useDeleteEventStreamMutation,
  useUpdateEventStreamMutation,
  WebhookPubsubPublishConnectionApiKeyLocation,
  WebhookPubsubPublishConnectionAuthType,
} from "api/generated/graphql";
import { useToast } from "components/toast/Toast";
import {
  ButtonV3,
  Col,
  FormGroup,
  FormSection,
  Icon,
  Input,
  Modal,
  RadioGroup,
  Row,
  Switch,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import { useState } from "react";
import { logError } from "utils/logging";
import { randomAlphanumericString } from "utils/random";
import { dropNothings } from "views/utils";

import * as styles from "./EventStreamConnectionModals.css";

type CreateEventStreamConnectionProps = {
  showModal: boolean;
  onCreate: () => void;
  onCancel: () => void;
};

export const CreateEventStreamConnectionModal = ({
  showModal,
  onCreate,
  onCancel,
}: CreateEventStreamConnectionProps) => {
  const [name, setName] = useState("");
  // Only support a single connection type right now so default everything to webhooks
  const [connectionType] = useState(PubsubPublishConnectionType.Webhook);
  const [
    metadata,
    setMetadata,
  ] = useState<PubsubPublishConnectionMetadataInput>({
    connectionType: PubsubPublishConnectionType.Webhook,
    webhook: { url: "" },
  });
  const [
    credentials,
    setCredentials,
  ] = useState<PubsubPublishConnectionsCredentialsInput>({
    connectionType: PubsubPublishConnectionType.Webhook,
    webhook: { authType: WebhookPubsubPublishConnectionAuthType.None },
  });
  const [errorMessage, setErrorMessage] = useState("");

  const [
    createEventStreamMutation,
    { loading },
  ] = useCreateEventStreamMutation();
  const { displaySuccessToast } = useToast();
  const handleCancel = () => {
    reset();
    onCancel();
  };
  // Reset the modal state when it's closed
  const reset = () => {
    setName("");
    setMetadata({
      connectionType: PubsubPublishConnectionType.Webhook,
      webhook: { url: "" },
    });
    setCredentials({
      connectionType: PubsubPublishConnectionType.Webhook,
      webhook: { authType: WebhookPubsubPublishConnectionAuthType.None },
    });
    setErrorMessage("");
  };

  const handleAddConnection = async () => {
    const normalizedConfig = trimConnectionConfiguration({
      name,
      connectionType,
      metadata,
      credentials,
    });

    const errorMessage = validateConnectionConfiguration(normalizedConfig);
    if (errorMessage) {
      setErrorMessage(errorMessage);
      return;
    }

    try {
      const { data } = await createEventStreamMutation({
        variables: {
          input: {
            name: normalizedConfig.name,
            connectionType: normalizedConfig.connectionType,
            metadata: normalizedConfig.metadata,
            credentials: normalizedConfig.credentials,
          },
        },
      });

      switch (data?.createEventStream.__typename) {
        case "CreateEventStreamResult":
          await refetchQueries({ include: ["ListEventStreams"] });
          displaySuccessToast("Connection created");
          reset();
          onCreate();
          break;
        case "CreateEventStreamError":
          setErrorMessage(data.createEventStream.message);
          break;
      }
    } catch (error) {
      logError(error);
      setErrorMessage("Failed to create connection");
    }
  };

  return (
    <Modal
      isOpen={showModal}
      onClose={handleCancel}
      title="Add Event Streaming Connection"
      fullWidth
    >
      <Modal.Body>
        <WebhookEventStreamConnectionInput
          name={name}
          setName={setName}
          metadata={metadata}
          setMetadata={setMetadata}
          credentials={credentials}
          setCredentials={setCredentials}
        />
      </Modal.Body>
      {errorMessage && (
        <div className={styles.errorMessages}>{errorMessage}</div>
      )}
      <Modal.Footer
        primaryButtonLabel="Add Connection"
        primaryButtonLoading={loading}
        onPrimaryButtonClick={handleAddConnection}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={handleCancel}
      />
    </Modal>
  );
};

type EditEventStreamConnectionProps = {
  eventStream: EventStreamFragment;
  showModal: boolean;
  onSubmit: () => void;
  onCancel: () => void;
};

export const EditEventStreamConnectionModal = ({
  eventStream,
  showModal,
  onSubmit,
  onCancel,
}: EditEventStreamConnectionProps) => {
  // Capture existing values
  const connection = eventStream.connection;
  const existingCredentials = {
    connectionType: connection.connectionType,
    webhook: {
      authType: connection.credentials.authType,
      apiKey: connection.credentials.apiKey,
      hmac: connection.credentials.hmac,
    },
  };
  const existingMetadata = {
    connectionType: connection.connectionType,
    webhook: { url: connection.metadata.url },
  };

  // Intitialize state with null values
  const [updatedName, setUpdatedName] = useState<string>();
  const [updatedEnabledState, setUpdatedEnabledState] = useState<boolean>();
  const [
    updatedMetadata,
    setUpdatedMetadata,
  ] = useState<PubsubPublishConnectionMetadataInput>();
  const [
    updatedCredentials,
    setUpdatedCredentials,
  ] = useState<PubsubPublishConnectionsCredentialsInput>();

  // Show updated values if they've been set, otherwise show existing values
  const name = updatedName ?? connection.name;
  const enabled = updatedEnabledState ?? connection.enabled;
  const metadata = updatedMetadata ?? existingMetadata;
  const credentials = updatedCredentials ?? existingCredentials;

  const [deleteButtonClicked, setDeleteButtonClicked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [
    updateEventStreamMutation,
    { loading: updateLoading },
  ] = useUpdateEventStreamMutation();
  const [
    deleteEventStreamMutation,
    { loading: deleteLoading },
  ] = useDeleteEventStreamMutation();
  const { displaySuccessToast } = useToast();

  // Reset the modal state when it's closed
  const reset = () => {
    setUpdatedName("");
    setUpdatedMetadata({
      connectionType: PubsubPublishConnectionType.Webhook,
      webhook: { url: "" },
    });
    setUpdatedCredentials({
      connectionType: PubsubPublishConnectionType.Webhook,
      webhook: { authType: WebhookPubsubPublishConnectionAuthType.None },
    });
    setErrorMessage("");
  };
  const handleCancel = () => {
    reset();
    onCancel();
  };

  const handleUpdateConnection = async () => {
    const normalizedConfig = trimConnectionConfiguration({
      name: name,
      connectionType: connection.connectionType,
      metadata: metadata,
      credentials: credentials,
    });

    const errorMessage = validateConnectionConfiguration(normalizedConfig);
    if (errorMessage) {
      setErrorMessage(errorMessage);
      return;
    }

    try {
      const { data } = await updateEventStreamMutation({
        variables: {
          input: {
            id: eventStream.id,
            name: updatedName ? normalizedConfig.name : undefined,
            enabled: updatedEnabledState,
            metadata: updatedMetadata ? normalizedConfig.metadata : undefined,
            credentials: updatedCredentials
              ? normalizedConfig.credentials
              : undefined,
          },
        },
      });

      switch (data?.updateEventStream.__typename) {
        case "UpdateEventStreamResult":
          await refetchQueries({ include: ["ListEventStreams"] });
          displaySuccessToast("Connection updated");
          reset();
          onSubmit();
          break;
        case "UpdateEventStreamError":
          setErrorMessage(data.updateEventStream.message);
          break;
      }
    } catch (error) {
      logError(error);
      setErrorMessage("Failed to update connection");
    }
  };

  const handleDeleteConnection = async () => {
    try {
      const { data } = await deleteEventStreamMutation({
        variables: {
          input: { id: eventStream.id },
        },
      });

      switch (data?.deleteEventStream.__typename) {
        case "DeleteEventStreamResult":
          await refetchQueries({ include: ["ListEventStreams"] });
          displaySuccessToast("Connection deleted");
          reset();
          onSubmit();
          break;
      }
    } catch (error) {
      logError(error);
      setErrorMessage("Failed to delete connection");
    }
  };

  const hasNoChanges =
    (!updatedName || updatedName === connection.name) &&
    (updatedEnabledState === undefined ||
      updatedEnabledState === connection.enabled) &&
    (!updatedMetadata || _.isEqual(updatedMetadata, existingMetadata)) &&
    (!updatedCredentials || _.isEqual(updatedCredentials, existingCredentials));

  return (
    <Modal
      isOpen={showModal}
      onClose={handleCancel}
      title="Edit Event Streaming Connection"
      fullWidth
    >
      <Modal.Body>
        <WebhookEventStreamConnectionInput
          name={name}
          setName={setUpdatedName}
          enabled={enabled}
          setEnabled={setUpdatedEnabledState}
          metadata={metadata}
          setMetadata={setUpdatedMetadata}
          credentials={credentials}
          setCredentials={setUpdatedCredentials}
        />
      </Modal.Body>
      {errorMessage && (
        <div className={styles.errorMessages}>{errorMessage}</div>
      )}
      {deleteButtonClicked ? (
        <Modal.Footer
          primaryButtonLabel={"Yes, Delete"}
          primaryButtonLoading={deleteLoading}
          primaryButtonTypeV3={"danger"}
          onPrimaryButtonClick={handleDeleteConnection}
          secondaryButtonLabel="No, Cancel"
          onSecondaryButtonClick={() => setDeleteButtonClicked(false)}
          leftComponent={
            <div
              className={sprinkles({
                display: "flex",
                alignItems: "center",
                color: "red600V3",
              })}
            >
              <div>Are you sure you want to delete this connection?</div>
            </div>
          }
        />
      ) : (
        <Modal.Footer
          primaryButtonLabel={"Update"}
          primaryButtonLoading={updateLoading}
          primaryButtonTypeV3={"main"}
          onPrimaryButtonClick={handleUpdateConnection}
          primaryButtonDisabled={hasNoChanges}
          secondaryButtonLabel="Cancel"
          onSecondaryButtonClick={handleCancel}
          leftComponent={
            <ButtonV3
              label={"Delete Connection"}
              onClick={() => setDeleteButtonClicked(true)}
              type={"danger"}
              leftIconName={"trash"}
              size="sm"
            />
          }
        />
      )}
    </Modal>
  );
};

type MetadataProps = {
  metadata: PubsubPublishConnectionMetadataInput;
  setMetadata: (metadata: PubsubPublishConnectionMetadataInput) => void;
};

type CredentialProps = {
  credentials: PubsubPublishConnectionsCredentialsInput;
  setCredentials: (
    credentials: PubsubPublishConnectionsCredentialsInput
  ) => void;
};

type WebhookEventStreamConnectionProps = {
  name: string;
  setName: (name: string) => void;
  enabled?: boolean;
  setEnabled?: (enabled: boolean) => void;
} & MetadataProps &
  CredentialProps;

const WebhookEventStreamConnectionInput = ({
  name,
  setName,
  enabled,
  setEnabled,
  metadata,
  setMetadata,
  credentials,
  setCredentials,
}: WebhookEventStreamConnectionProps) => {
  const [authType, setAuthType] = useState(
    credentials.webhook?.authType ?? WebhookPubsubPublishConnectionAuthType.None
  );

  const authTypeOptions = dropNothings([
    { value: WebhookPubsubPublishConnectionAuthType.None, label: "None" },
    { value: WebhookPubsubPublishConnectionAuthType.Hmac, label: "HMAC" },
    { value: WebhookPubsubPublishConnectionAuthType.ApiKey, label: "API Key" },
  ]);

  const handleAuthTypeChange = (
    authType: WebhookPubsubPublishConnectionAuthType
  ) => {
    setAuthType(authType);
    switch (authType) {
      case WebhookPubsubPublishConnectionAuthType.None:
        setCredentials({
          ...credentials,
          webhook: {
            authType: WebhookPubsubPublishConnectionAuthType.None,
          },
        });
        break;
      case WebhookPubsubPublishConnectionAuthType.ApiKey:
        setCredentials({
          ...credentials,
          webhook: {
            authType: WebhookPubsubPublishConnectionAuthType.ApiKey,
          },
        });
        break;
      case WebhookPubsubPublishConnectionAuthType.Hmac:
        setCredentials({
          ...credentials,
          webhook: {
            authType: WebhookPubsubPublishConnectionAuthType.Hmac,
            hmac: {
              credential1: {
                id: crypto.randomUUID(),
                secret: randomAlphanumericString(32),
                createdAt: new Date().toISOString(),
              },
            },
          },
        });
        break;
      default:
        // This shouldn't happen unless we add a new auth type
        logError(new Error(`Unsupported webhook auth type: ${authType}`));
    }
  };

  return (
    <>
      {setEnabled && enabled !== undefined && (
        <div
          className={sprinkles({
            marginBottom: "mdlg",
          })}
        >
          <Switch
            label="Connection Enabled"
            checked={enabled}
            onChange={(value) => {
              setEnabled(value);
            }}
          />
        </div>
      )}
      <FormGroup label="Name" required>
        <Input value={name} onChange={setName} />
      </FormGroup>
      <FormGroup label="URL" required>
        <Input
          value={metadata?.webhook?.url || ""}
          onChange={(value) =>
            setMetadata({
              ...metadata,
              webhook: {
                url: value,
              },
            })
          }
        />
      </FormGroup>
      <FormGroup label="Authorization Type">
        <RadioGroup
          options={authTypeOptions}
          value={authTypeOptions.find((option) => option.value === authType)}
          getOptionLabel={(option) => option.label}
          getOptionKey={(option) => option.value}
          onSelectValue={(option) => handleAuthTypeChange(option.value)}
          inline
        />
      </FormGroup>
      {authType === WebhookPubsubPublishConnectionAuthType.Hmac && (
        <WebhookHmacAuthTypeInput
          credentials={credentials}
          setCredentials={setCredentials}
        />
      )}
      {authType === WebhookPubsubPublishConnectionAuthType.ApiKey && (
        <WebhookAPIKeysAuthTypeInput
          credentials={credentials}
          setCredentials={setCredentials}
        />
      )}
    </>
  );
};

type WebhookAPIKey = {
  id: string;
  name: string;
  value: string;
  location: WebhookPubsubPublishConnectionApiKeyLocation;
};

const WebhookAPIKeysAuthTypeInput = ({
  credentials,
  setCredentials,
}: CredentialProps) => {
  const [apiKeys, setAPIKeys] = useState<Array<WebhookAPIKey>>(
    credentials.webhook?.apiKey?.credentials.map((credential) => {
      return {
        id: credential.id,
        name: credential.name,
        value: credential.value,
        location: credential.location,
      };
    }) ?? [
      {
        id: crypto.randomUUID(),
        name: "",
        value: "",
        location: WebhookPubsubPublishConnectionApiKeyLocation.Header,
      },
    ]
  );

  const updateCredentials = (updatedAPIKeys: Array<WebhookAPIKey>) => {
    setCredentials({
      ...credentials,
      webhook: {
        authType: WebhookPubsubPublishConnectionAuthType.ApiKey,
        apiKey: { credentials: updatedAPIKeys },
      },
    });
  };

  const handleAddKey = () => {
    const newAPIKeys = [
      ...apiKeys,
      {
        id: crypto.randomUUID(),
        name: "",
        value: "",
        location: WebhookPubsubPublishConnectionApiKeyLocation.Header,
      },
    ];
    setAPIKeys(newAPIKeys);
    updateCredentials(newAPIKeys);
  };

  const handleEditKey = (index: number, updatedKey: WebhookAPIKey) => {
    const newAPIKeys = [...apiKeys];
    newAPIKeys[index] = updatedKey;
    setAPIKeys(newAPIKeys);
    updateCredentials(newAPIKeys);
  };

  const handleRemoveKey = (index: number) => {
    const newAPIKeys = [...apiKeys];
    newAPIKeys.splice(index, 1);
    setAPIKeys(newAPIKeys);
    updateCredentials(newAPIKeys);
  };

  return (
    <>
      <div
        className={sprinkles({
          marginBottom: "sm",
        })}
      >
        <Row>
          <Col>
            <span className={styles.sectionHeader}>{`${pluralize(
              "API Key",
              apiKeys.length,
              true
            )}`}</span>
          </Col>
          <Col>
            <div className={styles.sectionHeaderButton}>
              <ButtonV3
                type="defaultSecondary"
                label="Add API Key"
                leftIconName="plus"
                size="sm"
                disabled={apiKeys.length >= 2}
                disabledTooltip="You can only have 2 API keys"
                onClick={handleAddKey}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          {apiKeys.map((apiKey, index) => (
            <Row>
              <Col>
                <WebhookAPIKeyRow
                  key={apiKey.id}
                  apiKey={apiKey}
                  setAPIKey={(updatedKey) => handleEditKey(index, updatedKey)}
                  removeAPIKey={() => handleRemoveKey(index)}
                  showRemoveButton={apiKeys.length > 1}
                />
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </>
  );
};

type WebhookAPIKeyRowProps = {
  apiKey: WebhookAPIKey;
  showRemoveButton: boolean;
  setAPIKey: (apiKey: WebhookAPIKey) => void;
  removeAPIKey: () => void;
};

const WebhookAPIKeyRow = ({
  apiKey,
  showRemoveButton,
  setAPIKey,
  removeAPIKey,
}: WebhookAPIKeyRowProps) => {
  const locationOptions = [
    {
      value: WebhookPubsubPublishConnectionApiKeyLocation.Header,
      label: "Header",
    },
    {
      value: WebhookPubsubPublishConnectionApiKeyLocation.QueryParam,
      label: "Query Param",
    },
  ];

  return (
    <div className={styles.apiKeyContainer}>
      <FormSection title="">
        <FormGroup
          label="Key"
          fontWeight="normal"
          rightLabelContent={
            showRemoveButton && (
              <ButtonV3
                label="Remove Key"
                type="mainBorderless"
                size="xs"
                onClick={removeAPIKey}
              />
            )
          }
        >
          <Input
            value={apiKey.name}
            onChange={(value) => {
              setAPIKey({ ...apiKey, name: value });
            }}
          />
        </FormGroup>
        <FormGroup label="Value" fontWeight="normal">
          <Input
            value={apiKey.value}
            onChange={(value) => {
              setAPIKey({ ...apiKey, value: value });
            }}
          />
        </FormGroup>
        <FormGroup label="Add to" fontWeight="normal">
          <RadioGroup
            options={locationOptions}
            value={locationOptions.find(
              (option) => option.value === apiKey.location
            )}
            getOptionLabel={(option) => option.label}
            getOptionKey={(option) => option.value}
            onSelectValue={(option) => {
              setAPIKey({ ...apiKey, location: option.value });
            }}
            inline
          />
        </FormGroup>
      </FormSection>
    </div>
  );
};

type WebhookHmacSecret = {
  id: string;
  secret: string;
  createdAt: string;
};

const WebhookHmacAuthTypeInput = ({
  credentials,
  setCredentials,
}: CredentialProps) => {
  const [hmacSecrets, setHmacSecrets] = useState<Array<WebhookHmacSecret>>(
    credentials.webhook?.hmac
      ? dropNothings([
          credentials.webhook?.hmac?.credential1 && {
            id: credentials.webhook?.hmac?.credential1.id,
            secret: credentials.webhook?.hmac?.credential1.secret,
            createdAt: credentials.webhook?.hmac?.credential1.createdAt,
          },
          credentials.webhook?.hmac?.credential2 && {
            id: credentials.webhook?.hmac?.credential2.id,
            secret: credentials.webhook?.hmac?.credential2.secret,
            createdAt: credentials.webhook?.hmac?.credential2.createdAt,
          },
        ])
      : []
  );

  const updateCredentials = (updatedHmacSecrets: Array<WebhookHmacSecret>) => {
    setCredentials({
      ...credentials,
      webhook: {
        authType: WebhookPubsubPublishConnectionAuthType.Hmac,
        hmac: {
          credential1:
            updatedHmacSecrets.length >= 1 ? updatedHmacSecrets[0] : undefined,
          credential2:
            updatedHmacSecrets.length >= 2 ? updatedHmacSecrets[1] : undefined,
        },
      },
    });
  };

  const handleAddSecret = () => {
    const newHmacSecrets = [
      ...hmacSecrets,
      {
        id: crypto.randomUUID(),
        secret: randomAlphanumericString(32),
        createdAt: new Date().toISOString(),
      },
    ];
    setHmacSecrets(newHmacSecrets);
    updateCredentials(newHmacSecrets);
  };

  const handleRemoveSecret = (index: number) => {
    const newHmacSecrets = [...hmacSecrets];
    newHmacSecrets.splice(index, 1);
    setHmacSecrets(newHmacSecrets);
    updateCredentials(newHmacSecrets);
  };

  return (
    <>
      <div
        className={sprinkles({
          marginBottom: "sm",
        })}
      >
        <Row>
          <Col>
            <div className={styles.sectionHeader}>
              {`${pluralize("Secret", hmacSecrets.length, true)}`}
            </div>
          </Col>
          <Col>
            <div className={styles.sectionHeaderButton}>
              <ButtonV3
                type="defaultSecondary"
                label="Generate Secret"
                leftIconName="plus"
                size="sm"
                disabled={hmacSecrets.length >= 2}
                disabledTooltip="You can only have 2 secrets at once"
                onClick={handleAddSecret}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Row>
        <Col>
          {hmacSecrets.map((hmacSecret, index) => (
            <Row key={hmacSecret.id}>
              <Col>
                <WebhookHmacSecretRow
                  hmacSecret={hmacSecret}
                  index={index}
                  removeHmacSecret={() => handleRemoveSecret(index)}
                />
              </Col>
            </Row>
          ))}
        </Col>
      </Row>
    </>
  );
};

type WebhookHmacSecretRowProps = {
  index: number;
  hmacSecret: WebhookHmacSecret;
  removeHmacSecret: () => void;
};

const WebhookHmacSecretRow = ({
  hmacSecret,
  index,
  removeHmacSecret,
}: WebhookHmacSecretRowProps) => {
  const [hasCopied, setHasCopied] = useState(false);

  const secretTitle = (
    <div
      className={sprinkles({
        display: "flex",
        justifyContent: "space-between",
      })}
    >
      <div
        className={sprinkles({
          display: "flex",
          gap: "xs",
        })}
      >
        <div>{"Secret " + (index + 1)}</div>
        {hmacSecret.createdAt && (
          <div
            className={sprinkles({
              fontSize: "textSm",
              color: "gray600",
            })}
          >
            {"(Generated on " +
              moment(new Date(hmacSecret.createdAt)).format(
                "MM/DD/YYYY [at] h:mm a"
              ) +
              ")"}
          </div>
        )}
      </div>
      <div
        className={sprinkles({
          color: "blue600V3",
          cursor: "pointer",
        })}
        onClick={removeHmacSecret}
      >
        Delete Secret
      </div>
    </div>
  );

  const previewContent = (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        gap: "xs",
      })}
    >
      {secretTitle}
      <Input value={hmacSecret.secret} onChange={() => {}} readOnly />
    </div>
  );

  const newSecretContent = (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        gap: "xs",
      })}
    >
      <div
        className={sprinkles({
          display: "flex",
          flexDirection: "column",
        })}
      >
        {secretTitle}
        <div>
          This secret will not be visible again. If you lose it, you will need
          to generate a new one.
        </div>
      </div>
      <div className={styles.secretContainer}>
        <div
          className={sprinkles({
            display: "flex",
            alignItems: "center",
            gap: "xs",
            fontSize: "textXs",
            cursor: "pointer",
          })}
          onClick={() => {
            navigator.clipboard.writeText(hmacSecret.secret).then(() => {
              setHasCopied(true);
              setTimeout(() => {
                setHasCopied(false);
              }, 2000);
            });
          }}
        >
          <Icon
            data={{ type: "name", icon: hasCopied ? "check" : "copy" }}
            size="xs"
          />
          <div>{hasCopied ? "Copied!" : "Copy"}</div>
        </div>
        <div>{hmacSecret.secret}</div>
      </div>
    </div>
  );

  const content = hmacSecret.secret.startsWith("******")
    ? previewContent
    : newSecretContent;
  return (
    <div
      className={sprinkles({
        marginBottom: "md",
      })}
    >
      {content}
    </div>
  );
};

type ConnectionConfiguration = {
  name: string;
  connectionType: PubsubPublishConnectionType;
  metadata: PubsubPublishConnectionMetadataInput;
  credentials: PubsubPublishConnectionsCredentialsInput;
};

function trimConnectionConfiguration(config: ConnectionConfiguration) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- We need to use any here because we are using JSON.parse and JSON.stringify
  const replacer = (key: string, value: any) => {
    if (typeof value === "string") {
      return (value as string).trim();
    }
    return value;
  };

  return JSON.parse(
    JSON.stringify(config, replacer)
  ) as ConnectionConfiguration;
}

/**
 * Performs basic client side validation before we hit the server.
 * The server will do real validations.
 *
 * @returns Empty string if there are no errors. Otherwise, an error message
 */
function validateConnectionConfiguration({
  name,
  connectionType,
  metadata,
  credentials,
}: ConnectionConfiguration) {
  if (!name || !name.trim()) {
    return "Name is required";
  }

  if (!metadata.webhook?.url) {
    return "URL is required";
  }

  switch (connectionType) {
    case PubsubPublishConnectionType.Webhook:
      if (!metadata.webhook?.url?.trim()) {
        return "URL is required";
      }
      if (
        credentials.webhook?.authType ===
        WebhookPubsubPublishConnectionAuthType.ApiKey
      ) {
        const apiKeyCredentials =
          credentials.webhook?.apiKey?.credentials || [];
        if (apiKeyCredentials.length < 1) {
          return "At least one API key is required";
        }

        apiKeyCredentials.forEach((apiKey) => {
          if (!apiKey.name) {
            return "API key name is required";
          }
          if (!apiKey.value) {
            return "API key value is required";
          }

          return "";
        });
      }
      break;
    default:
      throw new Error(`Unsupported connection type: ${connectionType}`);
  }

  return "";
}
