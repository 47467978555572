import { TooltipPlacement } from "components/label/Label";
import { Linkify, Tooltip } from "components/ui";
import { generateUniqueId } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import React from "react";
import { Info } from "react-feather";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import * as styles from "./FormGroup.css";

type FormGroupProps = {
  label: string;
  description?: string;
  infoTooltip?: React.ReactNode;
  required?: boolean;
  disabled?: boolean;
  rightLabelContent?: React.ReactNode;
  marginSize?: "sm" | "md" | "lg";
  fontWeight?: "normal" | "medium" | "semibold" | "bold";
  fontSize?: "textXs" | "textSm" | "textMd" | "textLg";
  compact?: boolean;
};

const FormGroup: React.FC<FormGroupProps> = (props) => {
  let { children } = props;
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  // Add id to the child and htmlFor to the label. This will allow
  // clicking the label to focus the field. The child element must have
  // an id prop in order for this to work.
  const id = React.useMemo(() => generateUniqueId("form"), []);
  if (React.isValidElement(children)) {
    children = React.cloneElement(children, { ...children.props, id });
  }

  return (
    <div
      className={styles.formGroup({
        disabled: props.disabled,
        marginSize: props.marginSize,
        hasV3,
      })}
    >
      <div className={styles.formGroupHeader({ compact: props.compact })}>
        <div className={styles.labelContainer}>
          <label
            className={
              hasV3
                ? styles.labelV3({
                    fontWeight: props.fontWeight,
                    fontSize: props.fontSize,
                  })
                : styles.label
            }
            htmlFor={id}
          >
            {props.label}
            {props.required ? (
              <span className={sprinkles({ color: "red600" })}>*</span>
            ) : null}
            {props.infoTooltip && (
              <Tooltip
                tooltipText={props.infoTooltip}
                placement={TooltipPlacement.Right}
              >
                <Info size={14} strokeWidth={2} className={styles.infoIcon} />
              </Tooltip>
            )}
          </label>
          {props.rightLabelContent}
        </div>
        {props.description && (
          <span className={styles.description}>
            <Linkify>{props.description}</Linkify>
          </span>
        )}
      </div>
      {children}
    </div>
  );
};

export default FormGroup;
