import { refetchQueries } from "api/ApiContext";
import {
  BundleDocument,
  BundleItemsDocument,
  useDeleteBundleGroupsMutation,
  useDeleteBundleResourcesMutation,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Modal } from "components/ui";
import pluralize from "pluralize";
import { useState } from "react";
import { logError } from "utils/logging";

type ResourceIdentifier = {
  resourceId: string;
  accessLevelRemoteId: string;
  key: string;
};

type GroupIdentifier = {
  groupId: string;
  accessLevelRemoteId: string;
  key: string;
};

interface Props {
  bundleId: string;
  resourcesToRemove: ResourceIdentifier[];
  groupsToRemove: GroupIdentifier[];
  onClose: () => void;
  onSuccess: ({ keysRemoved }: { keysRemoved: string[] }) => void;
}

const BundleRemoveItemsModal = (props: Props) => {
  const [error, setError] = useState("");

  const { displaySuccessToast } = useToast();
  const [
    deleteBundleResources,
    { loading: deleteBundleResourcesLoading },
  ] = useDeleteBundleResourcesMutation();
  const [
    deleteBundleGroups,
    { loading: deleteBundleGroupsLoading },
  ] = useDeleteBundleGroupsMutation();

  const loading = deleteBundleResourcesLoading || deleteBundleGroupsLoading;
  const numItems = props.resourcesToRemove.length + props.groupsToRemove.length;

  const handleSubmit = async () => {
    try {
      // TODO: Move this to one operation on the server inside a tx.
      const { data: resourcesData } = await deleteBundleResources({
        variables: {
          input: {
            inputs: props.resourcesToRemove.map(
              ({ resourceId, accessLevelRemoteId }) => ({
                bundleId: props.bundleId,
                resourceId,
                accessLevelRemoteId,
              })
            ),
          },
        },
      });
      if (!resourcesData?.deleteBundleResources) {
        setError("Failed to remove resources from bundle");
      }

      const { data: groupsData } = await deleteBundleGroups({
        variables: {
          input: {
            inputs: props.groupsToRemove.map(
              ({ groupId, accessLevelRemoteId }) => ({
                bundleId: props.bundleId,
                groupId,
                accessLevelRemoteId,
              })
            ),
          },
        },
      });
      if (!groupsData?.deleteBundleGroups) {
        setError("Failed to remove groups from bundle");
      }

      await refetchQueries({
        include: [BundleItemsDocument, BundleDocument],
      });

      displaySuccessToast(
        `Success: removed ${pluralize("item", numItems, true)} from bundle`
      );
      props.onSuccess({
        keysRemoved: [
          ...props.resourcesToRemove.map(({ key }) => key),
          ...props.groupsToRemove.map(({ key }) => key),
        ],
      });
      props.onClose();
    } catch (err) {
      logError("Failed to remove items from bundles");
    }
  };

  return (
    <Modal isOpen title="Remove Resources" onClose={props.onClose}>
      <Modal.Body>
        {error && <ModalErrorMessage errorMessage={error} />}
        Are you sure you want to delete {pluralize("item", numItems, true)} in
        this bundle? This cannot be undone.
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Remove"
        primaryButtonDisabled={numItems === 0}
        primaryButtonLoading={loading}
        onPrimaryButtonClick={handleSubmit}
      />
    </Modal>
  );
};

export default BundleRemoveItemsModal;
