import OpalPage from "components/opal/layout/OpalPage";
import { useHistory } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import ConfigurationTemplatesView from "./ConfigurationTemplatesView";
import RequestTemplatesView from "./RequestTemplatesView";
import TemplateMappingsView from "./TemplateMappingsView";

enum SelectedTab {
  Requests = "requests",
  Configurations = "configurations",
  Mapping = "mapping",
}

const TemplatesColumnV3 = () => {
  const history = useHistory();

  const hasConfigurationTemplates = useFeatureFlag(
    FeatureFlag.ConfigurationTemplates
  );

  let selectedTab: SelectedTab = SelectedTab.Requests;
  let content: React.ReactNode = <RequestTemplatesView />;

  // these should probably be nested routes
  if (history.location.pathname.endsWith("/templates/configurations")) {
    selectedTab = SelectedTab.Configurations;
    content = <ConfigurationTemplatesView />;
  } else if (history.location.pathname.endsWith("/configurations/mapping")) {
    selectedTab = SelectedTab.Mapping;
    content = <TemplateMappingsView />;
  }

  return (
    <OpalPage
      title="Templates"
      icon="template"
      tabs={[
        {
          title: "Custom Access Requests",
          isSelected: selectedTab === SelectedTab.Requests,
          onClick: () => history.push("/templates/requests"),
        },
        ...(hasConfigurationTemplates
          ? [
              {
                title: "Configuration Templates",
                isSelected: selectedTab === SelectedTab.Configurations,
                onClick: () => history.push("/templates/configurations"),
              },
            ]
          : []),
        {
          title: "Template Mapping & Priority",
          isSelected: selectedTab === SelectedTab.Mapping,
          onClick: () => history.push("/templates/configurations/mapping"),
        },
      ]}
    >
      {content}
    </OpalPage>
  );
};

export default TemplatesColumnV3;
