import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AwsTagInput,
  EntityType,
  Maybe,
  ResourceType,
  ServiceType,
  useAwsRoleCreationQuery,
  useCreateRequestProposalMutation,
} from "api/generated/graphql";
import ColumnContent from "components/column/ColumnContent";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import {
  Banner,
  Button,
  CodeEditor,
  DataElement,
  DataElementList,
  Divider,
  FormGroup,
  Input,
  Loader,
  Select,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
} from "views/requests/utils";

const AWS_ACCOUNT_ID_PLACEHOLDER = "{AWS_ACCOUNT_ID}";

const AWSRoleCreationContent = () => {
  const history = useHistory();

  const [selectedConnectionId, setSelectedConnectionId] = useState<
    Maybe<string>
  >(null);
  const [adminOwnerId, setAdminOwnerId] = useState<Maybe<string>>(null);
  const [roleName, setRoleName] = useState("");
  const [roleDescription, setRoleDescription] = useState("");
  const [requestReason, setRequestReason] = useState("");
  const [expiration, setExpiration] = useState(ExpirationValue.OneHour);

  const [policyName, setPolicyName] = useState("");
  const [policyDocument, setPolicyDocument] = useState(
    defaultRolePolicyDocument()
  );
  const [assumeRolePolicyDocument, setAssumeRolePolicyDocument] = useState(
    defaultAssumeRolePolicyDocument()
  );
  const [tags, setTags] = useState<AwsTagInput[]>(defaultOpalTags());

  const [showTagSelector, setShowTagSelector] = useState(false);

  const [errorMessage, setErrorMessage] = useState("");

  const { data, error, loading } = useAwsRoleCreationQuery();

  const [
    createRequestProposal,
    { loading: createResourceLoading },
  ] = useCreateRequestProposalMutation();

  const { displaySuccessToast } = useToast();

  const connections = data?.connections?.connections ?? [];
  const connectionId =
    connections.length === 1 ? connections[0].id : selectedConnectionId;
  const filteredTags = (data?.tags.tags ?? []).filter(
    (tag) =>
      !tags.find(
        (selectedTag) =>
          selectedTag.key === tag.key && selectedTag.value === tag.value
      )
  );

  if (error) {
    logError(error, `Error: failed to fetch connections`);
  }

  if (loading || !data) {
    return (
      <div className={sprinkles({ marginTop: "xl" })}>
        <Loader size="md" block />
      </div>
    );
  }

  const handleSubmit = async () => {
    if (!connectionId) {
      return;
    }
    try {
      const { data } = await createRequestProposal({
        variables: {
          input: {
            reason: requestReason,
            durationInMinutes: expirationValueToDurationInMinutes(
              expiration
            )?.asMinutes(),
            proposalMetadata: {
              awsRole: {
                policyName: policyName,
                policyDocument: policyDocument,
                policyDescription: "",
                assumeRolePolicyDocument: assumeRolePolicyDocument,
                roleName: roleName,
                roleDescription: roleDescription,
                tags: tags,
              },
            },
            connectionId: connectionId,
            serviceType: ServiceType.AwsIam,
            adminOwnerId: adminOwnerId ?? "",
            resourceType: ResourceType.AwsIamRole,
          },
        },
      });
      switch (data?.createRequestProposal.__typename) {
        case "CreateRequestProposalResult":
          setErrorMessage("");
          displaySuccessToast(`Success: resource request created`);
          history.replace(
            getResourceUrlNew({
              entityId: data.createRequestProposal.request.id,
              entityType: EntityType.Request,
            })
          );
          break;
        case "AWSRolePolicyInvalidError":
        case "AWSRoleCreationReviewerNotSetError":
        case "NoManagerSetForRequestingUserError":
          setErrorMessage(data?.createRequestProposal.message);
          break;
        case "NoReviewersSetForOwnerError":
        case "NoReviewersSetForResourceError":
        case "NoReviewersSetForGroupError":
          setErrorMessage("This request has no approvers set.");
          break;
        default:
          logError(new Error(`failed to create resource request`));
          setErrorMessage(`Error: failed to create resource request`);
      }
    } catch (error) {
      logError(error, "failed to create resource request");
      setErrorMessage(
        getModifiedErrorMessage(
          `Error: failed to create resource request`,
          error
        )
      );
    }
  };

  const submitDisabled =
    policyName === "" ||
    roleDescription === "" ||
    policyDocument === "" ||
    assumeRolePolicyDocument === "" ||
    requestReason === "" ||
    roleName === "" ||
    expiration === null ||
    connectionId === null ||
    adminOwnerId === null;

  return (
    <ColumnContent>
      <div className={sprinkles({ padding: "xs" })}>
        <FormGroup label="Role Name:" required>
          <Input
            value={roleName}
            onChange={setRoleName}
            placeholder="Role name"
          />
        </FormGroup>
        <FormGroup label="Role Description:" required>
          <Input
            type="textarea"
            rows={6}
            value={roleDescription}
            onChange={setRoleDescription}
            placeholder="Description of the resource in Opal..."
          />
        </FormGroup>
        <FormGroup label="AWS Account:" required>
          <Select
            value={connections.find((c) => c.id === connectionId)}
            onChange={(newConnection) => {
              setSelectedConnectionId(newConnection?.id || null);
            }}
            options={connections}
            getOptionLabel={(connection) => connection.name}
            placeholder="Select an account"
            disabled={connections.length === 1}
          />
        </FormGroup>
        <FormGroup label="Resource admin" required>
          <OwnerDropdown
            selectedOwnerId={adminOwnerId ?? undefined}
            onSelectOwner={(o) => {
              if (!o) return;
              setAdminOwnerId(o.id);
            }}
          />
        </FormGroup>
        <FormGroup label="Tags">
          <DataElementList>
            {tags.map(({ key, value }) => (
              <DataElement
                label={value ? `${key}:${value}` : key}
                color="blue"
                leftIcon={{ name: "tag" }}
                rightIcon={{
                  name: "x",
                  onClick: () => {
                    setTags(
                      tags.filter((t) => t.key !== key || t.value !== value)
                    );
                  },
                }}
              />
            ))}
            {showTagSelector ? null : (
              <DataElement
                label="Add Tag"
                color="green"
                leftIcon={{
                  name: "plus",
                }}
                onClick={() => setShowTagSelector(true)}
              />
            )}
          </DataElementList>
          {showTagSelector ? (
            <div className={sprinkles({ marginTop: "md" })}>
              <Select
                options={filteredTags}
                getOptionLabel={(tag) =>
                  tag.value ? `${tag.key}:${tag.value}` : tag.key || ""
                }
                onChange={(tag) => {
                  if (!tag) return;
                  setTags([...tags, tag]);
                  setShowTagSelector(false);
                }}
                loading={loading}
                selectOnly
                placeholder="Select a tag to add"
              />
            </div>
          ) : null}
        </FormGroup>
        <Divider label="Policy Details" labelPosition="left" />
        <FormGroup label="Policy Name:" required>
          <Input
            value={policyName}
            onChange={setPolicyName}
            placeholder="Policy name"
          />
        </FormGroup>
        <FormGroup label="Policy Document:" required>
          <CodeEditor
            onChangeCode={setPolicyDocument}
            code={policyDocument}
            dark
          />
        </FormGroup>
        <FormGroup
          label="Assume role policy document:"
          infoTooltip={`Note: template variables like {AWS_ACCOUNT_ID} will be substituted on the server with the appropriate values`}
          required
        >
          <CodeEditor
            onChangeCode={setAssumeRolePolicyDocument}
            code={assumeRolePolicyDocument}
            dark
          />
        </FormGroup>
        <Divider label="Request Details" labelPosition="left" />
        <FormGroup
          label="Access expiration"
          infoTooltip="Specifies for how long access to the role will be granted to you once the request is approved"
          required
        >
          <Select
            value={expiration}
            onChange={(value?: ExpirationValue) => {
              setExpiration(value ?? ExpirationValue.OneHour);
            }}
            options={Object.values(ExpirationValue)}
            getOptionLabel={(opt) => opt}
          />
        </FormGroup>
        <FormGroup label="Request description" required>
          <Input
            type="textarea"
            placeholder="I need access to this because..."
            onChange={setRequestReason}
            value={requestReason}
          />
        </FormGroup>
        {errorMessage ? (
          <div className={sprinkles({ marginBottom: "lg" })}>
            <Banner type="error" message={errorMessage} />
          </div>
        ) : null}
        <div
          className={sprinkles({ display: "flex", justifyContent: "flex-end" })}
        >
          <Button
            label="Submit resource proposal"
            type="primary"
            onClick={handleSubmit}
            disabled={submitDisabled}
            loading={createResourceLoading}
          />
        </div>
      </div>
    </ColumnContent>
  );
};

function defaultRolePolicyDocument() {
  return `{
    "Version": "2012-10-17",
    "Statement": []
}
`;
}

function defaultAssumeRolePolicyDocument() {
  return `{
    "Version": "2012-10-17",
    "Statement": [
        {
            "Effect": "Allow",
            "Principal": {
                "AWS": "arn:aws:iam::${AWS_ACCOUNT_ID_PLACEHOLDER}:root"
            },
            "Action": "sts:AssumeRole"
        }
    ]
}`;
}

function defaultOpalTags(): AwsTagInput[] {
  return [
    {
      key: "opal",
      value: "",
    },
    {
      key: "opal-created",
      value: "",
    },
  ];
}

export default AWSRoleCreationContent;
