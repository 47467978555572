import { Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import styles from "views/settings/OrgSettings.module.scss";

type AdminDurationButtonTextProps = {
  text: string;
  frequency?: boolean;
};

const AdminDurationButtonText = (props: AdminDurationButtonTextProps) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const textArray = props.text.split(", ");
  let value = null;
  let unit = null;

  // get the highest order unit
  // ie: if text = 1 day, 2 hours, the highest order unit would be "day"
  if (textArray.length > 0) {
    const highestOrderTerm = textArray[0].split(" ");
    if (highestOrderTerm.length === 2) {
      value =
        textArray.length > 1 ? `~${highestOrderTerm[0]}` : highestOrderTerm[0];
      unit = highestOrderTerm[1];
    }
  }

  let displayContent = hasV3 ? (
    <div className={sprinkles({ display: "flex", gap: "xs" })}>
      <span className={styles.displayedValue}>{value}</span>
      <span className={styles.displayedUnit}>{unit}</span>
    </div>
  ) : (
    <div className={sprinkles({ display: "flex" })}>
      <span className={styles.displayedValue}>{value}</span>
      <span className={styles.displayedUnit}>{unit}</span>
    </div>
  );

  // we only show the highest order unit and its value, meaning the full duration might not be displayed
  // if the full text is cut off, include a tooltips that displays the full duration
  if (textArray.length > 1) {
    displayContent = (
      <Tooltip tooltipText={props.text}>{displayContent}</Tooltip>
    );
  }

  return (
    <div>
      {value ? (
        displayContent
      ) : (
        <div style={{ fontSize: "14px" }}>
          {props.frequency ? "None" : "Indefinite"}
        </div>
      )}
    </div>
  );
};

export default AdminDurationButtonText;
