import * as React from "react";

import Icon, { IconName } from "../icon/Icon";
import * as styles from "./ButtonGroupV3.css";

interface ButtonInfo {
  label: string;
  onClick: () => void;
  selected?: boolean;
  leftIconName?: IconName;
  disabled?: boolean;
}

interface Props {
  buttons: ButtonInfo[];
}

const ButtonGroup = (props: Props) => {
  return (
    <div className={styles.buttonGroup}>
      {props.buttons.map((buttonInfo) => {
        return (
          <button
            key={buttonInfo.leftIconName ?? buttonInfo.label}
            className={styles.button({
              selected: buttonInfo.selected,
              disabled: buttonInfo.disabled,
            })}
            onClick={buttonInfo.onClick}
          >
            {buttonInfo.leftIconName && (
              <Icon name={buttonInfo.leftIconName} size="sm" />
            )}
            {buttonInfo.label}
          </button>
        );
      })}
    </div>
  );
};

export default ButtonGroup;
