import {
  AppDropdownPreviewFragment,
  AppThreatCountsQuery,
  AppThreatPreviewFragment,
  EntityType,
  FiltersMatchMode,
  RecommendationsEntityMetadata,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { PillV3 } from "components/pills/PillsV3";
import { ButtonV3, PopoverV3 } from "components/ui";
import EntityIcon from "components/ui/entityicon/EntityIcon";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { getResourceUrlNew } from "utils/common";
import { useAllAppThreatCountsQuery } from "utils/hooks";
import AccessReviewCreateSimpleModal from "views/access_reviews/AccessReviewCreateSimpleModal";
import AppIcon from "views/apps/AppIcon";

import EntityRemediationModal from "./resolutions/EntityRemediationModal";
import * as popover_styles from "./ThreatPill.css";

type AppRow = {
  id: string;
  appThreat: AppThreatPreviewFragment;
};

const RiskyApps = () => {
  const [displayedAppsData, setDisplayedAppsData] = useState<
    AppThreatCountsQuery | undefined
  >(undefined);
  const [rowDisplayCount, setRowDisplayCount] = useState(5);
  const loadMore = () => {
    setRowDisplayCount(rowDisplayCount + 4);
  };

  const { data: appsData, loading: appsLoading } = useAllAppThreatCountsQuery();

  useEffect(() => {
    if (!appsLoading) {
      setDisplayedAppsData(appsData);
    }
  }, [appsLoading, appsData]);

  const getAppId = (
    app: AppDropdownPreviewFragment | AppThreatPreviewFragment
  ) => {
    switch (app.app.__typename) {
      case "OktaResourceApp":
        return app.app.resourceId;
      case "ConnectionApp":
        return app.app.connectionId;
      default:
        throw new Error(`Unexpected app type: ${app.app.__typename}`);
    }
  };

  const allRows = (displayedAppsData?.apps.apps || [])
    .slice()
    .sort((app1, app2) => app2.threats.length - app1.threats.length)
    .filter((app) => app.threats.length > 0)
    .map((app) => {
      return {
        id: getAppId(app),
        appThreat: app,
      };
    });

  const columns: Header<AppRow>[] = [
    {
      id: "id",
      label: "",
      customCellRenderer: (row: AppRow) => {
        return (
          <RiskyAppRow
            id={row.id}
            appThreat={row.appThreat}
            filteredThreats={row.appThreat.threats}
          />
        );
      },
    },
  ];

  // TODO(sravan): Hack until we store suggestions - prevents buggy mismatch experience being obvious
  if (allRows.length == 0) {
    return <></>;
  }

  const DataSection = () => {
    return (
      <>
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
            alignItems: "center",
            paddingBottom: "md",
          })}
        >
          <Table
            rows={allRows.slice(0, rowDisplayCount)}
            columns={columns}
            loadingRows={appsLoading}
            totalNumRows={allRows.length}
            getRowId={(row) => row.id}
            emptyState={{
              title: "No suggestions to remediate",
            }}
            hideHeader
          />
        </div>
        <div>
          {allRows.length > rowDisplayCount && (
            <ButtonV3
              type="default"
              size="sm"
              outline
              label="Load More"
              onClick={() => {
                loadMore();
              }}
            />
          )}
        </div>
      </>
    );
  };
  return <DataSection />;
};

const RiskyAppRow = ({
  id,
  appThreat,
  filteredThreats,
}: {
  id: string;
  appThreat: AppThreatPreviewFragment;
  filteredThreats: RecommendationsEntityMetadata[];
}) => {
  const [showCreateUarModal, setShowCreateUarModal] = useState(false);
  const [showRemediationModal, setShowRemediationModal] = useState(false);
  const { authState } = useContext(AuthContext);
  const isAdmin = authState.user?.isAdmin;
  const entityType = (() => {
    switch (appThreat.app.__typename) {
      case "OktaResourceApp":
        return EntityType.Resource;
      case "ConnectionApp":
        return EntityType.Connection;
      default:
        throw new Error(`Unexpected app type: ${appThreat.app.__typename}`);
    }
  })();

  const appIcon =
    appThreat.app.__typename === "OktaResourceApp" ? (
      <AppIcon
        app={{
          __typename: "OktaResourceApp",
          iconUrl: appThreat.app.iconUrl,
        }}
        iconSize="lg"
        iconStyle="rounded"
      />
    ) : appThreat.app.__typename === "ConnectionApp" ? (
      <EntityIcon
        type={appThreat.app.connectionType}
        size="lg"
        iconStyle="rounded"
      />
    ) : undefined;

  const uarFilter = {
    tags: [],
    names: [],
    adminIDs: [],
    resourceTypes: [],
    groupTypes: [],
    entityIDs: Array.from(
      new Set(filteredThreats.map((value) => value.entityKey.entityId))
    ),
    connectionIDs: [],
    userIDs: [],
    matchMode: FiltersMatchMode.Any,
  };

  return (
    <div
      key={id}
      className={sprinkles({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        gap: "sm",
      })}
    >
      <div
        className={sprinkles({
          display: "flex",
          gap: "sm",
          alignItems: "center",
          fontSize: "textLg",
        })}
      >
        {appIcon}
        <Link
          to={getResourceUrlNew(
            { entityId: id, entityType: entityType },
            entityType
          )}
        >
          {appThreat.name}
        </Link>
      </div>
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "center",
          gap: "md",
        })}
      >
        <PillV3
          key={appThreat.name}
          keyText={`${filteredThreats.length} ${pluralize(
            "Threat",
            filteredThreats.length
          )}`}
          pillColor={"Red"}
        />
        <PopoverV3
          content={
            <div className={popover_styles.tooltip}>
              {"You must be an super-admin to dismiss remediations"}
            </div>
          }
          position={"left"}
          enabled={!isAdmin}
        >
          <ButtonV3
            size="sm"
            outline
            type="mainBorderless"
            label="Create UAR"
            disabled={!isAdmin}
            onClick={() => setShowCreateUarModal(true)}
          />
        </PopoverV3>
        <ButtonV3
          size="sm"
          type="main"
          label="Review"
          onClick={() => setShowRemediationModal(true)}
        />
        {showRemediationModal && (
          <EntityRemediationModal
            entityKeys={
              filteredThreats.map((threat) => ({
                entityId: threat.entityKey.entityId,
                entityType: threat.entityKey.entityType,
              })) || []
            }
            onClose={() => setShowRemediationModal(false)}
            suggestionTypes={
              filteredThreats.map((threat) => threat.suggestionType) || []
            }
            isOktaApp={false}
          />
        )}
      </div>
      {showCreateUarModal ? (
        <AccessReviewCreateSimpleModal
          filters={uarFilter}
          title={`Create UAR for ${appThreat.name}`}
          onClose={() => setShowCreateUarModal(false)}
          linkToAccessReview={true}
        />
      ) : null}
    </div>
  );
};

export default RiskyApps;
