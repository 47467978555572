import { HierarchyPointNode } from "@visx/hierarchy/lib/types";
import {
  GroupBindingTinyFragment,
  GroupType,
  ResourceType,
} from "api/generated/graphql";
import { vars } from "css/vars.css";
import moment from "moment";

export const PAGE_MARGIN_LEFT = 100;

export const BASE_NODE_WIDTH = 256;
export const BASE_NODE_HEIGHT = 44;
export const GROUPS_VERTICAL_OFFSET = 16;
export const NODE_ATTRIBUTE_HEIGHT = 24;
export const NODE_STARTING_Y = -BASE_NODE_HEIGHT / 2;
export const HEADER_HEIGHT = 32;
export const SPACING = {
  xs: 4,
  sm: 8,
  md: 12,
  lg: 20,
  xl: 32,
};
export const NESTED_NODE_MARGIN = 16;

export const NODE_GAP_X = 140;
export const NODE_PADDING = SPACING.md;
export const ICON_WIDTH = 24;
export const ICON_HEIGHT = 24;
export const TEXT_SIZE = 12;
export const SUB_TEXT_SIZE = 8;
export const TEXT_SPACING = 16;

export function expiresColor(
  resourceType?: "group" | "resource",
  expires?: Date | null
): string {
  if (resourceType == null || expires === undefined) {
    return vars.color.gray600;
  }
  if (expires !== null) {
    return vars.color.green300;
  }
  if (resourceType === "group") {
    return vars.color.gray600;
  }
  return vars.color.red500;
}

export function lastUsedColor(lastUsed?: Date | null): string {
  if (lastUsed == null) {
    return vars.color.gray600;
  }
  if (moment(lastUsed).isBefore(moment().add(-90, "days"))) {
    return vars.color.red500;
  }
  return vars.color.green300;
}

// Used as a fake root node to create trees that seem to have multiple root nodes
export interface GhostNodeData {
  kind: "ghost";
  nodeId: string;
  height: number;
  x?: number;
  y?: number;
  children: TreeNodeData[];
}

export interface ResourceNodeData {
  kind: "resource";
  nodeId: string;
  selected?: boolean;
  expandable?: boolean;
  loading?: boolean;

  name: string;
  resourceType: ResourceType;
  isManaged?: boolean;
  expires?: Date | null;
  numChildResources?: number;
  numRoles?: number;
  url?: string;

  depth: number;
  height: number;
  x?: number;
  y?: number;
  children?: TreeNodeData[];
}

export interface ResourceRoleNodeData {
  kind: "resourceRole";
  nodeId: string;
  selected?: boolean;
  loading?: boolean;
  visible?: boolean;

  resourceId: string;
  isManaged?: boolean;
  roleName: string;
  roleRemoteId: string;
  icon?: string;
  expires?: Date | null;

  depth: number;
  height: number;
  x?: number;
  y?: number;
  children?: TreeNodeData[];
}

export interface GroupNodeData {
  kind: "group";
  nodeId: string;
  groupId?: string;
  loading: boolean;
  selected?: boolean;
  name: string;
  userCount?: number;
  role?: string;
  expires?: Date | null;
  url?: string;
  groupType: GroupType;
  isOnCallSynced: boolean;
  isManaged?: boolean;
  groupBinding?: GroupBindingTinyFragment;
  showLinkAbove?: boolean;

  height: number;
  x?: number;
  y?: number;
  children: TreeNodeData[];
}

export interface UserNodeData {
  kind: "user";
  nodeId: string;
  selected?: boolean;
  loading?: boolean;

  name?: string;
  email?: string;
  team?: string;
  position?: string;
  expires?: Date | null;
  mostRecentSessionEnded?: Date | null;
  accessEntityType?: "group" | "resource";
  roleName?: string;
  roleRemoteId?: string;
  avatarUrl?: string;
  url?: string;

  height: number;
  x?: number;
  y?: number;
  depth: number;
}

export interface GroupByNodeData {
  kind: "groupBy";
  nodeId: string;
  label: string;
  count: number;

  height: number;
  x?: number;
  y?: number;
}

export type TreeNodeData =
  | GhostNodeData
  | ResourceNodeData
  | ResourceRoleNodeData
  | GroupNodeData
  | UserNodeData
  | GroupByNodeData;

export type NodeKind = TreeNodeData["kind"];

export type HierarchyNode = HierarchyPointNode<TreeNodeData>;

export type UsageRange = {
  maxDays?: number;
  minDays?: number;
  noData?: boolean;
};

export const GroupByOptions = ["userRole", "userTeam"] as const;
export type GroupByOption = typeof GroupByOptions[number];
