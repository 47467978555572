import {
  EntityType,
  GroupDropdownPreviewFragment,
  useGroupAccessLevelsQuery,
  useGroupsPreviewQuery,
  useResourceAccessLevelsQuery,
} from "api/generated/graphql";
import { ResourceRequestConfig } from "components/forms/common";
import { Icon, Link, Skeleton } from "components/ui";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";

import * as styles from "./RequestConfigRow.css";

interface Props {
  requestConfig: ResourceRequestConfig;
  entityType?: EntityType;
  entityId?: string;
}

const RequestConfigTitleRow = (props: Props) => {
  const { groupIds, roleIds, priority } = props.requestConfig;

  const {
    data: groupData,
    loading: groupLoading,
    error: groupError,
  } = useGroupsPreviewQuery({
    variables: {
      input: {
        groupIds: groupIds,
      },
    },
    skip: !groupIds,
  });

  if (groupError) {
    logError(groupError, `failed to list groups`);
  }

  let groups: GroupDropdownPreviewFragment[] | undefined;
  if (groupData?.groups.__typename === "GroupsResult") {
    groups = groupData.groups.groups;
  }

  const {
    data: resourceRolesData,
    error: resourceRolesError,
  } = useResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceId: props.entityId ?? "",
      },
    },
    skip: !props.entityId || props.entityType !== EntityType.Resource,
  });

  const {
    data: groupRolesData,
    error: groupRolesError,
  } = useGroupAccessLevelsQuery({
    variables: {
      input: {
        groupId: props.entityId ?? "",
      },
    },
    skip: !props.entityId || props.entityType !== EntityType.Group,
  });

  let roles: {
    accessLevelRemoteId: string;
    accessLevelName: string;
  }[] = [];

  if (resourceRolesData) {
    switch (resourceRolesData.accessLevels.__typename) {
      case "ResourceAccessLevelsResult": {
        roles = resourceRolesData.accessLevels.accessLevels ?? [];
        break;
      }
      case "ResourceNotFoundError":
        logError(resourceRolesError, `failed to list resource roles`);
        break;
    }
  } else if (resourceRolesError) {
    logError(resourceRolesError, `failed to list resource roles`);
  }

  if (groupRolesData) {
    switch (groupRolesData.groupAccessLevels.__typename) {
      case "GroupAccessLevelsResult": {
        roles = groupRolesData.groupAccessLevels.accessLevels ?? [];
        break;
      }
      case "GroupNotFoundError":
        logError(groupRolesError, `failed to list group roles`);
        break;
    }
  } else if (groupRolesError) {
    logError(groupRolesError, `failed to list group roles`);
  }

  const isDefault = priority === 0;

  return (
    <>
      {isDefault ? (
        <>
          <div className={styles.conditionParameter}>Default</div>
        </>
      ) : null}
      {!isDefault &&
      (groupIds?.length === 0 || groups?.length === 0) &&
      roleIds?.length === 0 ? (
        <div className={styles.conditionParameter}>Select groups and roles</div>
      ) : null}
      <div className={styles.groupsList}>
        {groups?.map((group) => (
          <div className={styles.conditionParameter}>
            <Icon name="users" size="xs" />
            {groupLoading ? (
              <Skeleton height="24px" />
            ) : (
              <Link
                url={getResourceUrlNew({
                  entityId: group?.id ?? null,
                  entityType: EntityType.Group,
                })}
                target="_blank"
              >
                {group?.name}
              </Link>
            )}
          </div>
        ))}
        {roleIds?.map((roleId) => (
          <div className={styles.conditionParameter}>
            <Icon name="role" size="xs" />
            {roles.find((role) => role.accessLevelRemoteId === roleId)
              ?.accessLevelName ?? roleId}
          </div>
        ))}
      </div>
    </>
  );
};
export default RequestConfigTitleRow;
