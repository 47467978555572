import { gql, useFragment } from "@apollo/client";
import { OpalAppGroupCountCellFragment } from "api/generated/graphql";

import OpalCountCell from "../OpalCountCell";

const OpalAppGroupCountCell = (props: { appID: string }) => {
  const { complete, data } = useFragment<OpalAppGroupCountCellFragment>({
    fragment: gql`
      fragment OpalAppGroupCountCell on App {
        groupAccessCount
      }
    `,
    fragmentName: "OpalAppGroupCountCell",
    from: {
      __typename: "App",
      id: props.appID,
    },
  });

  return (
    <OpalCountCell
      complete={complete}
      subject="Group"
      count={data?.groupAccessCount}
    />
  );
};

export default OpalAppGroupCountCell;
