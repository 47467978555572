import { EntityType, ResourceType } from "api/generated/graphql";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import { EntityIcon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { getResourceUrlNew } from "utils/common";

import * as styles from "./Cell.css";

export type ResourceForResourceCell = {
  id: string;
  name: string;
  type: ResourceType;
};

interface Props {
  resource: ResourceForResourceCell;
  openInNewTab?: boolean;
}

const ResourceCell = ({ resource, openInNewTab }: Props) => {
  return (
    <div
      className={sprinkles({
        display: "flex",
        alignItems: "center",
        gap: "sm",
      })}
    >
      <EntityIcon
        type={resource.type as ResourceType}
        iconStyle="rounded"
        size="sm"
      />
      <div>
        <a
          className={styles.mainText}
          href={getResourceUrlNew({
            entityId: resource.id,
            entityType: EntityType.Resource,
          })}
          target={openInNewTab ? "_blank" : ""}
          rel="noopener noreferrer"
        >
          {resource.name}
        </a>
        <div className={styles.subText}>
          {resourceTypeInfoByType[resource.type].fullName}
        </div>
      </div>
    </div>
  );
};

export default ResourceCell;
