import {
  EntityType,
  GroupTagFragment,
  Maybe,
  TagFragment,
  useRemoveGroupTagsMutation,
} from "api/generated/graphql";
import { ConditionalEditor } from "components/entity_viewer/editor/ConditionalEditor";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import GroupLabel from "components/label/item_labels/GroupLabel";
import SelectItemsModal, {
  SelectType,
} from "components/modals/update/SelectItemsModal";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import { useToast } from "components/toast/Toast";
import { useState } from "react";
import { logError } from "utils/logging";
import TagAddGroupsModal from "views/tags/TagAddGroupsModal";
import TagGroupsTable from "views/tags/TagGroupsTable";

type TagGroupsRowProps = {
  tag: TagFragment;
};

export const TagGroupsRow = (props: TagGroupsRowProps) => {
  const [showGroupsAddModal, setShowGroupsAddModal] = useState(false);
  const [showGroupsRemoveModal, setShowGroupsRemoveModal] = useState(false);

  const editor = (
    <ConditionalEditor
      menuOptions={[
        {
          label: "Add",
          handler: () => {
            setShowGroupsAddModal(true);
          },
        },
        {
          label: "Remove",
          handler: () => {
            setShowGroupsRemoveModal(true);
          },
        },
      ]}
    />
  );

  const addTagToGroupsModal = (
    <TagAddGroupsModal
      tag={props.tag}
      onClose={() => {
        setShowGroupsAddModal(false);
      }}
    />
  );

  const removeTagFromGroupsModal = (
    <RemoveTagFromGroupsModal
      key={`remove-tag-from-groups-modal`}
      tag={props.tag}
      groupTags={props.tag.tagGroups}
      showModal={showGroupsRemoveModal}
      setShowModal={setShowGroupsRemoveModal}
    />
  );

  return (
    <EntityViewerRow
      title={"Groups"}
      content={
        <EmptyStateContentWrapper
          content={
            <TagGroupsTable tag={props.tag} tagGroups={props.tag.tagGroups} />
          }
          entityType={EntityType.Group}
          title={`No groups have this tag`}
          subtitle={`Add this tag to groups to make them show up here.`}
          buttonTitle={`Add groups`}
          isEmpty={props.tag.tagGroups.length === 0}
          onClickHandler={() => {
            setShowGroupsAddModal(true);
          }}
          small={true}
        />
      }
      editor={editor}
      modals={
        <>
          {showGroupsAddModal && addTagToGroupsModal}
          {showGroupsRemoveModal && removeTagFromGroupsModal}
        </>
      }
      isTable={true}
    />
  );
};

type RemoveTagFromGroupsModalProps = {
  tag: TagFragment;
  groupTags: GroupTagFragment[];
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

const RemoveTagFromGroupsModal = (props: RemoveTagFromGroupsModalProps) => {
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { displaySuccessToast } = useToast();

  const [removeTagGroups, { loading }] = useRemoveGroupTagsMutation();
  const [groupIdsToRemove, setGroupIdsToRemove] = useState<string[]>([]);

  const modalReset = () => {
    props.setShowModal(false);
    setErrorMessage(null);
    setSearchQuery("");
  };

  const onSubmit = async () => {
    try {
      const { data } = await removeTagGroups({
        variables: {
          input: {
            groupTags: groupIdsToRemove.map((groupId) => {
              return {
                groupId: groupId,
                tagId: props.tag.id,
              };
            }),
          },
        },
        refetchQueries: ["Group", "Tag"],
      });
      switch (data?.removeGroupTags.__typename) {
        case "RemoveGroupTagsResult": {
          modalReset();
          displaySuccessToast("Success: removed tags from groups");
          break;
        }
        default:
          logError(new Error(`failed to remove tag from selected groups`));
          setErrorMessage("Error: failed to remove tag from selected groups");
      }
    } catch (error) {
      logError(error, `failed to remove group tag from selected groups`);
      setErrorMessage("Error: failed to remove tag from selected groups");
    }
  };

  return (
    <SelectItemsModal
      key={"groups_remove"}
      title={"Remove tag from groups"}
      selectType={SelectType.Remove}
      entryInfos={props.tag.tagGroups
        .filter((tagGroup) =>
          tagGroup.group?.name
            .toLowerCase()
            .includes(searchQuery.toLocaleLowerCase())
        )
        .sort((a, b) => {
          if (a && b) {
            const aSortString = a.group?.name || "";
            const bSortString = b.group?.name || "";
            return aSortString.localeCompare(bSortString);
          }
          return 0;
        })
        .map((tagGroup) => {
          return {
            entityId: {
              entityId: tagGroup.groupId,
              entityType: EntityType.Group,
            },
            label: (
              <GroupLabel
                groupId={tagGroup.groupId}
                text={tagGroup.group?.name}
              />
            ),
            isBold: false,
            isBreadcrumb: false,
          };
        })}
      itemName={"group"}
      isModalOpen={props.showModal}
      errorMessage={errorMessage}
      submitDisabled={groupIdsToRemove.length === 0}
      onClose={modalReset}
      onSubmit={onSubmit}
      loading={loading}
      idsToUpdate={groupIdsToRemove}
      setIdsToUpdate={setGroupIdsToRemove}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    />
  );
};

export default TagGroupsRow;
