import { getModifiedErrorMessage } from "api/ApiContext";
import {
  ApiAccessLevel,
  EntityType,
  FirstPartyTokenFragment,
  FirstPartyTokensDocument,
  FirstPartyTokensQuery,
  Maybe,
  useCreateFirstPartyTokenMutation,
  useDeleteFirstPartyTokensMutation,
  useFirstPartyTokensQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { ConditionalEditor } from "components/entity_viewer/editor/ConditionalEditor";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import { formatDuration, ResourceLabel } from "components/label/Label";
import { EditDurationForm } from "components/modals/EditDurationModal";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { FieldLabel } from "components/modals/SessionDetailsModal";
import SelectItemsModal, {
  SelectType,
} from "components/modals/update/SelectItemsModal";
import { useToast } from "components/toast/Toast";
import { Banner, FormGroup, Input, Modal, Select } from "components/ui";
import moment from "moment";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { apiRoleByRole } from "utils/auth/auth";
import { logError } from "utils/logging";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
} from "views/requests/utils";
import ApiTokensTable from "views/settings/api/ApiTokensTable";

import { OrgSettingsSection } from "../OrgSettings";

export type ApiProps = {
  title: string;
};

const Api = (props: ApiProps) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showTokenModal, setShowTokenModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const [token, setToken] = useState<Maybe<string>>(null);

  const { data, error } = useFirstPartyTokensQuery();

  let tokens: FirstPartyTokenFragment[] = [];
  if (data) {
    switch (data.firstPartyTokens.__typename) {
      case "FirstPartyTokensResult":
        tokens = data.firstPartyTokens.tokens;
        break;
      default:
        logError(new Error(`failed to retrieve API tokens`));
    }
  } else if (error) {
    logError(error, `failed to retrieve API tokens`);
  }

  let editor = (
    <ConditionalEditor
      menuOptions={[
        {
          label: "Create",
          handler: () => {
            setShowCreateModal(true);
          },
        },
        {
          label: "Delete",
          handler: () => {
            setShowDeleteModal(true);
          },
          warning: true,
        },
      ]}
    />
  );

  return (
    <OrgSettingsSection title={props.title}>
      <EntityViewerRow
        title={"Tokens"}
        content={
          <ApiTokensTable
            tokens={tokens}
            buttonTitle={"Generate API token"}
            buttonOnClickHandler={() => {
              setShowCreateModal(true);
            }}
          />
        }
        editor={editor}
        modals={
          <>
            {showCreateModal && (
              <CreateModal
                setShowModal={setShowCreateModal}
                onSuccess={(token: string) => {
                  setToken(token);
                  setShowTokenModal(true);
                }}
              />
            )}
            {showTokenModal && token && (
              <TokenModal setShowModal={setShowTokenModal} token={token} />
            )}
            {showDeleteModal && (
              <DeleteModal
                setShowModal={setShowDeleteModal}
                tokens={tokens || []}
              />
            )}
          </>
        }
        isTable={true}
      />
    </OrgSettingsSection>
  );
};

type CreateModalProps = {
  setShowModal: (show: boolean) => void;
  onSuccess: (token: string) => void;
};

const CreateModal = (props: CreateModalProps) => {
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { authState } = useContext(AuthContext);

  const { displaySuccessToast } = useToast();

  const [tokenLabel, setTokenLabel] = useState<string>("");
  const [role, setRole] = useState<ApiAccessLevel>(ApiAccessLevel.ReadOnly);
  const [duration, setDuration] = useState<number | undefined>();

  const fieldUnset = tokenLabel === "";

  const adminExpirationTime = authState.user?.opalAdminExpirationTime
    ? moment(new Date(authState.user.opalAdminExpirationTime))
    : undefined;
  let maxExpirationDuration: moment.Duration | null = null;
  let maxExpirationDurationStr = "";
  let initExpirationDurationVal: number | undefined = undefined;
  if (adminExpirationTime) {
    maxExpirationDuration = moment.duration(
      adminExpirationTime.diff(moment(new Date()))
    );
    maxExpirationDurationStr = formatDuration(maxExpirationDuration);

    // find the largest expiration default value that is less than the max expiration duration
    // this way we can autoselect the best option in the EditDuration dropdown
    initExpirationDurationVal =
      Object.values(ExpirationValue)
        .map((time) => expirationValueToDurationInMinutes(time))
        .filter((duration) =>
          duration ? duration <= maxExpirationDuration! : false
        )
        .pop()
        ?.asMinutes() ?? undefined;
  }

  const [
    createFirstPartyToken,
    { loading },
  ] = useCreateFirstPartyTokenMutation();

  const handleSubmit = async () => {
    try {
      const { data } = await createFirstPartyToken({
        variables: {
          input: {
            tokenLabel: tokenLabel,
            accessLevel: role,
            expiresAt: duration
              ? moment(Date()).add(duration, "m").toDate().toISOString()
              : null,
          },
        },
        update: (cache, { data }) => {
          switch (data?.createFirstPartyToken.__typename) {
            case "CreateFirstPartyTokenResult": {
              let cachedQuery = cache.readQuery<FirstPartyTokensQuery>({
                query: FirstPartyTokensDocument,
                variables: {
                  input: {},
                },
              });
              if (cachedQuery) {
                cache.writeQuery<FirstPartyTokensQuery>({
                  query: FirstPartyTokensDocument,
                  variables: {
                    input: {},
                  },
                  data: {
                    ...cachedQuery,
                    firstPartyTokens: {
                      ...cachedQuery.firstPartyTokens,
                      tokens: [
                        ...cachedQuery.firstPartyTokens.tokens,
                        data.createFirstPartyToken.token,
                      ],
                    },
                  },
                });
              }
            }
          }
        },
      });
      switch (data?.createFirstPartyToken.__typename) {
        case "CreateFirstPartyTokenResult":
          props.setShowModal(false);
          displaySuccessToast("Success: API token created");
          props.onSuccess(data.createFirstPartyToken.signedToken);
          break;
        default:
          logError(new Error(`failed to create API token`));
          setErrorMessage("Error: failed to create API token");
      }
    } catch (error) {
      logError(error, `failed to create API token`);
      setErrorMessage("Error: failed to create API token");
    }
  };

  const handleClose = () => {
    props.setShowModal(false);
  };

  return (
    <Modal isOpen onClose={handleClose} title="Create API token">
      <Modal.Body>
        <FormGroup label="Label">
          <Input onChange={setTokenLabel} value={tokenLabel} />
        </FormGroup>
        <FormGroup label="Role">
          <Select
            onChange={(role) => {
              if (role) {
                setRole(role.value);
              }
            }}
            getOptionLabel={(option) => option.label}
            value={{
              label: apiRoleByRole[role].name,
              value: role,
            }}
            options={[
              {
                label: apiRoleByRole[ApiAccessLevel.ReadOnly].name,
                value: ApiAccessLevel.ReadOnly,
              },
              {
                label: apiRoleByRole[ApiAccessLevel.FullAccess].name,
                value: ApiAccessLevel.FullAccess,
              },
            ]}
          />
        </FormGroup>
        <EditDurationForm
          title="Expires In"
          onChange={setDuration}
          initValue={initExpirationDurationVal}
          includeIndefinite
          maxValue={
            maxExpirationDuration
              ? maxExpirationDuration.asMinutes()
              : undefined
          }
          maxValueReason={
            "Your access to the Opal admin role is expiring in " +
            maxExpirationDurationStr
          }
        />
        {adminExpirationTime && (
          <Banner
            message={
              <>
                Your access to the Opal admin role is expiring in{" "}
                <b>{maxExpirationDurationStr}</b>. If the creator of an API
                token loses admin access, their token will expire. For that
                reason, the max expiration you can set for this token is{" "}
                <b>{maxExpirationDurationStr}</b>.
              </>
            }
            type="warning"
          />
        )}
        {errorMessage ? (
          <ModalErrorMessage errorMessage={errorMessage} />
        ) : null}
      </Modal.Body>
      <Modal.Footer
        onSecondaryButtonClick={handleClose}
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLabel={"Create"}
        primaryButtonDisabled={loading || fieldUnset}
        primaryButtonLoading={loading}
      />
    </Modal>
  );
};

type TokenModalProps = {
  setShowModal: (show: boolean) => void;
  token: string;
};

const TokenModal = (props: TokenModalProps) => {
  const handleClose = () => {
    props.setShowModal(false);
  };
  return (
    <Modal isOpen onClose={handleClose} title="API Token">
      <Modal.Body>
        <p>
          This key will not be visible again. If you lose it, you should delete
          the API token and create a new one.
        </p>
        <FieldLabel isDataPrivate={true} value={props.token} />
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel={"Okay, understood"}
        onPrimaryButtonClick={handleClose}
      />
    </Modal>
  );
};

type DeleteModalProps = {
  setShowModal: (show: boolean) => void;
  tokens: FirstPartyTokenFragment[];
};

const DeleteModal = (props: DeleteModalProps) => {
  const [idsToRemove, setIdsToRemove] = useState<string[]>([]);

  const { displaySuccessToast } = useToast();
  const [errorMessage, setErrorMessage] = useState<string | undefined>(
    undefined
  );

  const [
    deleteFirstPartyTokens,
    { loading },
  ] = useDeleteFirstPartyTokensMutation();

  const modalReset = () => {
    props.setShowModal(false);
    setIdsToRemove([]);
    setErrorMessage(undefined);
  };

  return (
    <SelectItemsModal
      title={"Delete API tokens"}
      selectType={SelectType.Remove}
      itemName={"token"}
      entryInfos={props.tokens.map((token) => {
        return {
          entityId: { entityId: token.id, entityType: EntityType.AuthToken },
          label: (
            <ResourceLabel
              entityTypeNew={EntityType.AuthToken}
              text={token.tokenLabel}
            />
          ),
          clickHandler: () => {},
          isBold: false,
          isBreadcrumb: false,
        };
      })}
      idsToUpdate={idsToRemove}
      setIdsToUpdate={setIdsToRemove}
      isModalOpen={true}
      onClose={() => {
        modalReset();
      }}
      onSubmit={async () => {
        try {
          const { data } = await deleteFirstPartyTokens({
            variables: {
              input: {
                ids: idsToRemove,
              },
            },
            update: (cache, { data }) => {
              switch (data?.deleteFirstPartyTokens.__typename) {
                case "DeleteFirstPartyTokensResult":
                  data?.deleteFirstPartyTokens.entries.forEach((entry) => {
                    switch (entry.__typename) {
                      case "DeleteFirstPartyTokenEntryResult":
                        cache.evict({
                          id: cache.identify({
                            __typename: "FirstPartyToken",
                            id: entry.id,
                          }),
                        });
                    }
                  });
                  break;
              }
            },
          });
          if (data) {
            switch (data.deleteFirstPartyTokens.__typename) {
              case "DeleteFirstPartyTokensResult": {
                modalReset();
                displaySuccessToast(
                  `Success: ${pluralize(
                    "token",
                    idsToRemove.length,
                    true
                  )} deleted`
                );
                break;
              }
              default:
                logError(new Error(`failed to delete tokens`));
                setErrorMessage("Error: failed to delete tokens");
            }
          }
        } catch (error) {
          logError(error, "failed to delete tokens");
          setErrorMessage(
            getModifiedErrorMessage("Error: failed to delete tokens", error)
          );
        }
      }}
      loading={loading}
      errorMessage={errorMessage}
      submitDisabled={idsToRemove.length === 0}
    />
  );
};

export default Api;
