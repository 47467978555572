import { gql, useQuery } from "@apollo/client";
import { getModifiedErrorMessage } from "api/ApiContext";
import {
  EntityType,
  GroupPreviewSmallFragmentDoc,
  GroupResourcePropagationStatusFragmentDoc,
  GroupType,
  Maybe,
  RemoveGroupResourceInput,
  ResourceGroupsOpalTableQuery,
  ResourcePreviewSmallFragmentDoc,
  ResourceType,
  SupportTicketPreviewFragmentDoc,
  UpdateGroupResourceInput,
  useRemoveGroupResourcesMutation,
  useUpdateGroupResourcesMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { ResourceLabel, TimeLabel } from "components/label/Label";
import { BulkUpdateExpirationModal } from "components/modals/BulkUpdateExpirationModal";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OpalSearchInput from "components/opal/common/input/OpalSearchInput";
import OpalTable, {
  Columns,
  useSelectableOpalTable,
} from "components/opal/table/OpalTable";
import PropagationStatusLabelWithModal from "components/propagation/PropagationStatusLabelWithModal";
import { useToast } from "components/toast/Toast";
import { ButtonV3, Checkbox, EntityIcon, Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _, { debounce } from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import React, { useContext } from "react";
import { useState } from "react";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage, hasBasicPermissions } from "utils/auth/auth";
import { getResourceUrlNew } from "utils/common";
import { isSnowflakeResource } from "utils/directory/resources";
import { logError } from "utils/logging";
import { useBooleanURLSearchParam, useTransitionTo } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { PropagationType } from "utils/useRemediations";
import { useTransitionToImportItems } from "views/apps/AppImportItemsView";
import { expirationValueToDurationInMinutes } from "views/requests/utils";

type ResourceGroup = NonNullable<
  Extract<
    ResourceGroupsOpalTableQuery["resource"],
    { __typename?: "ResourceResult" }
  >["resource"]["containingGroups"]
>[0];

const ResourceGroupsOpalTable = (props: { resourceId: string }) => {
  const transitionTo = useTransitionTo();
  const { authState } = useContext(AuthContext);

  const [transitionToImportItems] = useTransitionToImportItems();

  const [
    updateExpirationErrorMessage,
    setUpdateExpirationErrorMessage,
  ] = useState<string>("");
  const [showUpdateExpirationModal, setShowUpdateExpirationModal] = useState(
    false
  );
  const [removeGroupsErrorMessage, setRemoveGroupsErrorMessage] = useState<
    Maybe<string>
  >(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const [
    showUnmanagedGroups,
    setShowUnmanagedGroups,
  ] = useBooleanURLSearchParam("unmanaged");
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [
    updateGroupResources,
    { loading: updateLoading },
  ] = useUpdateGroupResourcesMutation();
  const [
    removeGroupResources,
    { loading: removeLoading },
  ] = useRemoveGroupResourcesMutation();

  const startPushTaskPoll = usePushTaskLoader();
  const { displaySuccessToast } = useToast();

  // should probably be written as fragment on Resource
  const { data } = useQuery<ResourceGroupsOpalTableQuery>(
    gql`
      query ResourceGroupsOpalTable($resourceId: ResourceId) {
        resource(input: { id: $resourceId }) {
          ... on ResourceResult {
            resource {
              id
              name
              resourceType
              authorizedActions
              connection {
                id
              }
              ...ResourcePreviewSmall
              containingGroups {
                propagationStatus {
                  ...GroupResourcePropagationStatus
                }
                access {
                  directAccessPoint {
                    __typename
                  }
                  accessLevel {
                    accessLevelName
                    accessLevelRemoteId
                  }
                  latestExpiringAccessPoint {
                    expiration
                    supportTicket {
                      ...SupportTicketPreview
                    }
                  }
                }
                group {
                  ...GroupPreviewSmall
                  id
                  isManaged
                  name
                  groupType
                  connection {
                    connectionType
                  }
                }
              }
            }
          }
        }
      }
      ${SupportTicketPreviewFragmentDoc}
      ${GroupPreviewSmallFragmentDoc}
      ${ResourcePreviewSmallFragmentDoc}
      ${GroupResourcePropagationStatusFragmentDoc}
    `,
    {
      variables: { resourceId: props.resourceId },
    }
  );

  const connectionId =
    data?.resource.__typename === "ResourceResult"
      ? data?.resource.resource.connection?.id
      : "";

  const resource =
    data?.resource?.__typename === "ResourceResult" ? data?.resource : null;

  const resourceType = resource?.resource?.resourceType;

  const canEdit =
    resource?.resource.authorizedActions?.includes(AuthorizedActionManage) ??
    false;

  const canSeePropagationStatus =
    !hasBasicPermissions(authState.user) || canEdit;

  let rows = resource?.resource.containingGroups ?? [];
  if (!showUnmanagedGroups || searchQuery) {
    rows = rows.filter((resourceGroup) => {
      if (!showUnmanagedGroups && resourceGroup.group?.isManaged === false) {
        return false;
      }
      if (
        searchQuery &&
        !resourceGroup.group?.name
          .toLowerCase()
          .includes(searchQuery.toLowerCase())
      ) {
        return false;
      }
      return true;
    });
  }

  const groupsById: { [key: string]: ResourceGroup } = {};
  rows.forEach((resourceGroup) => {
    if (resourceGroup.group != null) {
      groupsById[resourceGroup.group.id] = resourceGroup;
    }
  });

  const logEvent = useLogEvent();

  const {
    selectedRows,
    selectableOpalTableProps,
    resetSelected,
  } = useSelectableOpalTable<ResourceGroup>({
    bulkActions: canEdit ? (
      <>
        <ButtonV3
          label="Update Expiration"
          type="mainSecondary"
          leftIconName="clock"
          onClick={() => setShowUpdateExpirationModal(true)}
        />
        <ButtonV3
          label="Remove"
          type="danger"
          leftIconName="trash"
          onClick={() => setShowRemoveModal(true)}
        />
      </>
    ) : null,
    getUnselectableReason: (resourceGroup) => {
      if (!resource) return undefined;
      const canManageGroup = resource.resource.authorizedActions?.includes(
        AuthorizedActionManage
      );
      if (
        resourceGroup.group?.groupType === GroupType.OktaGroup &&
        resource.resource.resourceType === ResourceType.OktaRole
      ) {
        return "Okta Role and Group relationships cannot be managed in Opal. Please make these changes in Okta.";
      } else if (!canManageGroup) {
        return "You can only remove resources which you administer. Please ask your Opal admin to remove it.";
      } else if (!resourceGroup.access?.directAccessPoint) {
        return "The group has no direct access to remove";
      }

      if (isSnowflakeResource(resource.resource.resourceType)) {
        return "Snowflake resources cannot be managed in Opal. Please make these changes in Snowflake.";
      }
      return undefined;
    },
  });

  const selectedItemIds: string[] = [];
  Object.keys(selectedRows).forEach(
    (id) => selectedRows[id] && selectedItemIds.push(id)
  );

  const submitRemoval = async (
    groupResourcesToRemove: RemoveGroupResourceInput[]
  ) => {
    logEvent({
      name: "apps_remove_resource_from_groups",
      properties: {
        numGroupsRemovedFrom: _.uniqBy(groupResourcesToRemove, "groupId")
          .length,
      },
    });

    try {
      const { data } = await removeGroupResources({
        variables: {
          input: {
            groupResources: groupResourcesToRemove,
          },
        },
      });
      switch (data?.removeGroupResources.__typename) {
        case "RemoveGroupResourcesResult":
          startPushTaskPoll(data.removeGroupResources.taskId, {
            refetchOnComplete: [
              {
                resourceId: props.resourceId,
              },
            ],
          });
          setRemoveGroupsErrorMessage(null);
          resetSelected();
          setShowRemoveModal(false);
          break;
        case "UserFacingError":
          setRemoveGroupsErrorMessage(data.removeGroupResources.message);
          break;
        default:
          logError(new Error(`failed to remove resource from groups`));
          setRemoveGroupsErrorMessage(
            `Error: failed to remove resource from groups`
          );
      }
    } catch (error) {
      logError(error, "failed to remove resource from groups");
      setRemoveGroupsErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to remove resource from groups",
          error
        )
      );
    }
  };

  const submitUpdateExpiration = async (
    groupResourcesToUpdate: UpdateGroupResourceInput[]
  ) => {
    logEvent({
      name: "apps_update_group_resources",
      properties: {
        numResourcesUpdated: _.uniqBy(groupResourcesToUpdate, "resourceId")
          .length,
      },
    });

    try {
      const { data } = await updateGroupResources({
        variables: {
          input: {
            groupResources: groupResourcesToUpdate,
          },
        },
        refetchQueries: ["ResourceDetailView"],
      });
      switch (data?.updateGroupResources.__typename) {
        case "UpdateGroupResourcesResult":
          if (data.updateGroupResources.taskIds.length > 0) {
            for (const taskId of data.updateGroupResources.taskIds) {
              startPushTaskPoll(taskId, {
                refetchOnComplete: [{ resourceId: props.resourceId }],
              });
            }
          } else {
            displaySuccessToast(
              `Successfully updates ${pluralize(
                "groups",
                groupResourcesToUpdate.length,
                true
              )}`
            );
          }
          setShowUpdateExpirationModal(false);
          setUpdateExpirationErrorMessage("");
          resetSelected();
          setSearchQuery("");
          break;
        case "UserFacingError":
          setUpdateExpirationErrorMessage(data.updateGroupResources.message);
          break;
        default:
          logError(new Error(`failed to update expiration for groups`));
          setUpdateExpirationErrorMessage(
            "Error: failed to update expiration for groups"
          );
      }
    } catch (error) {
      logError(error, "failed to update expiration");
      setUpdateExpirationErrorMessage(
        getModifiedErrorMessage("Error: failed to update expiration", error)
      );
    }
  };

  const COLUMNS: Columns<ResourceGroup> = [
    {
      id: "name",
      label: "Name",
      customCellRenderer: (resourceGroup) => {
        return resourceGroup.group != null ? (
          <div
            onClick={(event) => {
              if (resourceGroup.group?.isManaged) {
                transitionTo(
                  {
                    pathname: getResourceUrlNew({
                      entityId: resourceGroup.group.id,
                      entityType: EntityType.Group,
                    }),
                  },
                  event
                );
              } else {
                transitionToImportItems(
                  {
                    connectionId: connectionId ?? "",
                    groupIds: resourceGroup.group?.id
                      ? [resourceGroup.group?.id]
                      : [],
                  },
                  event
                );
              }
            }}
          >
            <ResourceLabel
              text={resourceGroup.group?.name}
              bold={true}
              pointerCursor={true}
              maxChars="auto"
              inactive={!resourceGroup.group.isManaged}
              tooltipText={
                !resourceGroup.group.isManaged
                  ? "This group is currently not managed in Opal"
                  : null
              }
            />
          </div>
        ) : (
          "Unable to load group"
        );
      },
      width: 130,
    },
    {
      id: "connectionType",
      label: "Type",
      customCellRenderer: (resourceGroup) => {
        const groupInfo = getGroupTypeInfo(resourceGroup.group?.groupType);
        const connectionType = resourceGroup.group?.connection?.connectionType;
        if (!groupInfo || !connectionType) return <></>;
        return (
          <div
            className={sprinkles({
              display: "flex",
              gap: "sm",
              alignItems: "center",
            })}
          >
            <EntityIcon type={connectionType} iconStyle="rounded" />
            <div>{groupInfo.name}</div>
          </div>
        );
      },
    },
    {
      id: "role",
      label: "Role",
      customCellRenderer: (resourceGroup) =>
        resourceGroup?.access?.accessLevel?.accessLevelName || "-",
    },
    {
      id: "expiry",
      label: "Expires",
      customCellRenderer: (resourceGroup) => {
        const expirationTime = resourceGroup.access.latestExpiringAccessPoint
          ?.expiration
          ? moment(
              new Date(
                resourceGroup.access.latestExpiringAccessPoint.expiration
              )
            )
          : null;
        return (
          <TimeLabel
            time={expirationTime}
            supportTicket={
              resourceGroup.access?.latestExpiringAccessPoint.supportTicket
            }
            useExpiringLabel
          />
        );
      },
      width: 125,
    },
  ];

  canSeePropagationStatus &&
    COLUMNS.push({
      id: "syncStatus",
      label: "Status",
      customCellRenderer: (resourceGroup) => {
        return (
          <PropagationStatusLabelWithModal
            propagationType={PropagationType.GroupResource}
            propagationStatus={resourceGroup.propagationStatus}
            isAccessReview={false}
            entityInfo={{
              roleAssignmentKey:
                resourceGroup.group?.id +
                props.resourceId +
                resourceGroup.access.accessLevel.accessLevelRemoteId,
              group: resourceGroup.group,
              resource: resource?.resource,
              role: resourceGroup.access.accessLevel,
            }}
          />
        );
      },
      width: 50,
    });

  return (
    <>
      <OpalTable
        filters={
          <>
            <OpalSearchInput
              placeholder="Filter by name"
              onChange={debounce((e) => setSearchQuery(e.target.value), 250)}
            />
            <Checkbox
              size="md"
              label="Show unmanaged groups"
              checked={showUnmanagedGroups}
              onChange={(value) => {
                setShowUnmanagedGroups(value);
              }}
            />
          </>
        }
        actions={
          canEdit && resourceType && !isSnowflakeResource(resourceType) ? (
            <ButtonV3
              label="Add Groups"
              type="mainSecondary"
              leftIconName="plus"
              onClick={() => {
                transitionTo({
                  pathname: `/resources/${props.resourceId}/add-groups`,
                });
              }}
            />
          ) : undefined
        }
        onRowClickTransitionTo={(resourceGroup) =>
          getResourceUrlNew({
            entityId: resourceGroup.group?.id ?? "",
            entityType: EntityType.Group,
          })
        }
        columns={COLUMNS}
        rows={rows}
        totalNumRows={rows.length}
        entityName="Group"
        getRowId={(resourceGroup) => resourceGroup.group?.id ?? ""}
        {...selectableOpalTableProps}
      />
      {showUpdateExpirationModal && (
        <BulkUpdateExpirationModal
          isOpen={showUpdateExpirationModal}
          onSubmit={(expiration) => {
            const groupResourcesToUpdate: UpdateGroupResourceInput[] = [];
            const accessDurationInMinutes =
              expirationValueToDurationInMinutes(expiration)?.asMinutes() ??
              null;
            for (const rowId of selectedItemIds) {
              const resourceGroup = groupsById[rowId];
              groupResourcesToUpdate.push({
                resourceId: props.resourceId,
                groupId: resourceGroup.group?.id ?? "",
                accessLevel: {
                  accessLevelName:
                    resourceGroup.access.accessLevel.accessLevelName,
                  accessLevelRemoteId:
                    resourceGroup.access.accessLevel.accessLevelRemoteId,
                },
                durationInMinutes: {
                  int: accessDurationInMinutes,
                },
              });
            }
            submitUpdateExpiration(groupResourcesToUpdate);
          }}
          selectedItemIds={selectedItemIds}
          entityName={resource?.resource.name ?? ""}
          errorMessage={updateExpirationErrorMessage}
          onClose={() => setShowUpdateExpirationModal(false)}
          loading={updateLoading}
        />
      )}
      {showRemoveModal && (
        <Modal
          isOpen
          title="Remove Resource Groups"
          onClose={() => setShowRemoveModal(false)}
        >
          <Modal.Body>
            {removeGroupsErrorMessage && (
              <ModalErrorMessage errorMessage={removeGroupsErrorMessage} />
            )}
            Are you sure you want to remove{" "}
            {pluralize("direct role assignment", selectedItemIds.length, true)}{" "}
            from this resource?
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Remove"
            primaryButtonDisabled={selectedItemIds.length === 0}
            primaryButtonLoading={removeLoading}
            onPrimaryButtonClick={() => {
              const groupResourcesToRemove: RemoveGroupResourceInput[] = [];
              for (const rowId of selectedItemIds) {
                const resourceGroup = groupsById[rowId];
                groupResourcesToRemove.push({
                  groupId: resourceGroup.group?.id ?? "",
                  resourceId: props.resourceId,
                  accessLevel: {
                    accessLevelName:
                      resourceGroup.access.accessLevel.accessLevelName,
                    accessLevelRemoteId:
                      resourceGroup.access.accessLevel.accessLevelRemoteId,
                  },
                });
              }
              submitRemoval(groupResourcesToRemove);
            }}
          />
        </Modal>
      )}
    </>
  );
};

export default ResourceGroupsOpalTable;
