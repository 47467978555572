import opalLogo from "assets/logos/opal-security-logo-white.svg";
import clsx from "clsx";
import React, { ReactElement } from "react";
import * as Icons from "react-feather";
import LoadingBar from "react-top-loading-bar";
import onboardingStyles from "views/onboarding/Onboarding.module.scss";

type HeaderProps = {
  progress: number;
};

export const Header = (props: HeaderProps) => {
  return (
    <div className={onboardingStyles.header}>
      <div className={onboardingStyles.logo}>
        <img src={opalLogo} alt="Opal logo" />
      </div>
      <LoadingBar color={"white"} height={6} progress={props.progress * 100} />
    </div>
  );
};

type FooterProps = {
  step: number;
  totalSteps: number;
  backHandler?: () => void;
};

export const Footer = (props: FooterProps) => {
  return (
    <div className={onboardingStyles.footer}>
      {props.backHandler && (
        <BackButton
          onClick={() => {
            props.backHandler && props.backHandler();
          }}
        />
      )}
      <ProgressDots step={props.step} totalSteps={props.totalSteps} />
      {props.backHandler && (
        <div className={onboardingStyles.backButtonEmpty} />
      )}
    </div>
  );
};

type BackButtonProps = {
  onClick: () => void;
  hidden?: boolean;
};

export const BackButton = (props: BackButtonProps) => {
  return (
    <div
      className={onboardingStyles.backButton}
      onClick={() => {
        props.onClick();
      }}
      style={{ opacity: props.hidden ? 0 : 1 }}
    >
      <Icons.ArrowLeft strokeWidth={3} size={30} />
      Go back
    </div>
  );
};

type ProgressDotsProps = {
  step: number;
  totalSteps: number;
};

export const ProgressDots = (props: ProgressDotsProps) => {
  const colorClasses = [
    onboardingStyles.green,
    onboardingStyles.orange,
    onboardingStyles.red,
    onboardingStyles.blue,
  ];

  return (
    <div className={onboardingStyles.progressDots}>
      {[...Array(props.totalSteps)].map((_, i) => (
        <span
          key={`${colorClasses[i]}_dot`}
          className={clsx({
            [onboardingStyles.dot]: true,
            [colorClasses[i]]: true,
          })}
          style={{ opacity: i + 1 === props.step ? 1 : 0.3 }}
        />
      ))}
    </div>
  );
};

type InputIconProps = {
  inputIcon: ReactElement;
  disabled: boolean;
  error?: boolean;
};

export const InputIcon = (props: InputIconProps) => {
  return (
    <span
      className={clsx({
        [onboardingStyles.inputIcon]: !props.error && !props.disabled,
        [onboardingStyles.inputIconFaded]: !props.error && props.disabled,
        [onboardingStyles.inputIconError]: props.error,
      })}
    >
      {props.inputIcon}
    </span>
  );
};

type ErrorMessageProps = {
  errorMessage: string;
};

export const ErrorMessage = (props: ErrorMessageProps) => {
  return (
    <div className={onboardingStyles.error}>
      <InputIcon
        inputIcon={<Icons.AlertTriangle strokeWidth={2} />}
        disabled={false}
        error={true}
      />
      <span className={onboardingStyles.message}>{props.errorMessage}</span>
    </div>
  );
};

type GetEnterHandlerProps = {
  callback: () => void;
};

export const GetEnterHandler = (props: GetEnterHandlerProps) => {
  return (event: React.KeyboardEvent | KeyboardEvent) => {
    if (event.key === "Enter") {
      props.callback();
    }
  };
};
