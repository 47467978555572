import { EntityType, UserDropdownPreviewFragment } from "api/generated/graphql";
import { PaginatedUserDropdown } from "components/dropdown/PaginatedUserDropdown";
import { ResourceLabel } from "components/label/Label";
import { PillV3 } from "components/pills/PillsV3";
import {
  DataElementList,
  FormGroup,
  FormRow,
  Icon,
  Switch,
} from "components/ui";
import Avatar from "components/ui/avatar/Avatar";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import { AppsContext } from "views/apps/AppsContext";
import { getUserAvatarIcon } from "views/users/utils";

import { FormMode } from "../common";

interface Props {
  mode: FormMode;
  breakGlassUsers?: UserDropdownPreviewFragment[];
  onChange: (breakGlassUsers?: UserDropdownPreviewFragment[]) => void;
  showTemplateWarning?: boolean;
  isV3?: boolean;
  includeTitle?: boolean;
}

const BreakGlassUsersRow = (props: Props) => {
  const { breakGlassUsers = [], includeTitle = true } = props;
  const { bulkMode } = useContext(AppsContext);
  const sortedBreakGlassUsers = breakGlassUsers.slice().sort((a, b) => {
    return a.fullName.localeCompare(b.fullName);
  });

  const content = (
    <>
      <PaginatedUserDropdown
        value={undefined}
        onChange={(user) => {
          if (user != null) {
            props.onChange([...breakGlassUsers, user]);
          }
        }}
        hiddenUserIds={breakGlassUsers.map((user) => user.id)}
        clearable={false}
        selectOnly
        popperHeight="sm"
      />
      {breakGlassUsers.length > 0 && (
        <div className={sprinkles({ marginTop: "md" })}>
          <DataElementList>
            {breakGlassUsers.map((user) => {
              return (
                <PillV3
                  key={user.id}
                  keyText={user.fullName ?? "--"}
                  icon={getUserAvatarIcon(user)}
                  pillColor="DeepOrange"
                  onRemove={() =>
                    props.onChange(
                      breakGlassUsers.filter((u) => u.id !== user.id)
                    )
                  }
                />
              );
            })}
          </DataElementList>
        </div>
      )}
    </>
  );

  if (!includeTitle) {
    return (
      <div>
        <p>
          Break-glass users have the ability to give themselves temporary 12
          hour access to the group. Usage of break-glass access will be logged
          in events.
        </p>
        {content}
      </div>
    );
  }

  if (props.isV3) {
    return (
      <FormGroup
        label="Break-glass users"
        infoTooltip="Break-glass users have the ability to give themselves temporary 12 hour
    access to the group. Usage of break-glass access will be logged in
    events."
      >
        {content}
      </FormGroup>
    );
  }

  const viewContent =
    sortedBreakGlassUsers.length > 0
      ? sortedBreakGlassUsers.map((user) => (
          <ResourceLabel
            key={user.id}
            text={user.fullName}
            entityTypeNew={EntityType.User}
            entityId={user.id}
            avatar={user.avatarUrl}
          />
        ))
      : "None";

  const editContent = (
    <>
      {bulkMode === "edit" && (
        <div className={sprinkles({ marginBottom: "md" })}>
          <Switch
            label="Leave unchanged"
            checked={props.breakGlassUsers === undefined}
            onChange={(val) => props.onChange(val ? undefined : [])}
            rightAlign
          />
        </div>
      )}
      <div className={sprinkles({ marginBottom: "sm" })}>
        Break-glass users have the ability to give themselves temporary 12 hour
        access to the group. Usage of break-glass access will be logged in
        events.
      </div>
      <PaginatedUserDropdown
        value={undefined}
        onChange={(user) => {
          if (user != null) {
            props.onChange([...breakGlassUsers, user]);
          }
        }}
        hiddenUserIds={breakGlassUsers.map((user) => user.id)}
        clearable={false}
        selectOnly
        popperHeight="sm"
      />

      {breakGlassUsers.length > 0 && (
        <div>
          {breakGlassUsers.map((user) => {
            return (
              <div
                className={sprinkles({
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  marginTop: "sm",
                })}
              >
                <div>
                  <Avatar url={user.avatarUrl} size="small" />
                  <span className={sprinkles({ marginLeft: "sm" })}>
                    {user.fullName ?? "--"}
                  </span>
                </div>
                <div
                  className={sprinkles({ color: "gray600", cursor: "pointer" })}
                  onClick={() =>
                    props.onChange(
                      breakGlassUsers.filter((u) => u.id !== user.id)
                    )
                  }
                >
                  <Icon name="x" size="xs" />
                </div>
              </div>
            );
          })}
        </div>
      )}
    </>
  );

  return (
    <FormRow title="Break-glass users">
      {props.mode === "view" ? viewContent : editContent}
      {props.showTemplateWarning ? (
        <p className={sprinkles({ color: "gray500" })}>
          This only applies to groups
        </p>
      ) : null}
    </FormRow>
  );
};

export default BreakGlassUsersRow;
