import {
  AccessReviewFragment,
  AccessReviewGroupPreviewFragment,
  AccessReviewSummaryStatus,
  EntityType,
  useAccessReviewGroupsQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import NavigationContext from "components/layout/NavigationContextProvider";
import { OnlyMyReviewsFilter } from "components/only_mine_filter/OnlyMineFilter";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import { Input } from "components/ui";
import React, { useContext } from "react";
import { logError } from "utils/logging";
import { filterSearchResults } from "utils/search/filter";
import AccessReviewProgressBar from "views/access_reviews/AccessReviewProgressBar";
import styles from "views/access_reviews/common/AccessReviewTableStyles.module.scss";
import { isReviewer } from "views/access_reviews/common/Common";
import AccessReviewGroupsTable from "views/access_reviews/groups/AccessReviewGroupsTable";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ViewSkeleton from "views/loading/ViewSkeleton";

type AccessReviewGroupsProps = {
  accessReview: AccessReviewFragment;
};

const AccessReviewGroups = (props: AccessReviewGroupsProps) => {
  const { navigationState } = useContext(NavigationContext);
  const { authState } = useContext(AuthContext);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const { accessReview } = props;

  const { data, error, loading } = useAccessReviewGroupsQuery({
    variables: {
      input: {
        id: props.accessReview.id,
      },
    },
  });

  let accessReviewGroups: AccessReviewGroupPreviewFragment[] = [];
  if (data?.accessReviewGroups) {
    switch (data.accessReviewGroups.__typename) {
      case "AccessReviewGroupsResult":
        if (navigationState.isOnlyMyReviewsFilterOn) {
          accessReviewGroups = data.accessReviewGroups.accessReviewGroups.filter(
            (accessReviewGroup) => {
              // this should show all groups where the current users is either a reviewer
              // or has the ability to assign a reviewer.
              return (
                authState.user?.user.id &&
                isReviewer(
                  authState.user.user.id,
                  accessReviewGroup.reviewerUsers
                )
              );
            }
          );
        } else {
          accessReviewGroups = data.accessReviewGroups.accessReviewGroups;
        }
        break;
      default:
        logError(new Error(`failed to retrieve access review resources`));
    }
  } else if (error) {
    logError(error, `failed to retrieve access review resources`);
    return <UnexpectedErrorPage error={error} />;
  }
  if (loading) {
    return <ViewSkeleton />;
  }

  const filteredAccessReviewGroups = filterSearchResults(
    accessReviewGroups,
    searchQuery,
    (group: AccessReviewGroupPreviewFragment) => [
      group.group?.name,
      group.group?.connection?.name,
    ]
  );

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableTopContainer}>
        <div className={styles.tableTopLeftContent}>
          <Input
            value={searchQuery}
            onChange={(value) => setSearchQuery(value)}
            placeholder="Search groups"
            leftIconName="search"
          />
        </div>
        <div className={styles.tableTopRightContent}>
          <OnlyMyReviewsFilter />
          <AccessReviewProgressBar
            accessReviewId={accessReview.id}
            numCompleted={
              accessReviewGroups.filter((accessReviewGroup) => {
                const status = accessReviewGroup.status;
                return (
                  status === AccessReviewSummaryStatus.Completed ||
                  status === AccessReviewSummaryStatus.NoReviewNeeded
                );
              }).length
            }
            numTotal={accessReviewGroups.length}
            entityType={EntityType.Group}
          />
        </div>
      </div>
      <EmptyStateContentWrapper
        content={
          <AccessReviewGroupsTable
            accessReview={accessReview}
            accessReviewGroups={filteredAccessReviewGroups}
          />
        }
        entityType={EntityType.Group}
        title={`No groups to review`}
        subtitle={
          navigationState.isOnlyMyReviewsFilterOn
            ? `No groups have been assigned for you to review.`
            : `There are no groups that require an access review.`
        }
        isEmpty={accessReviewGroups.length === 0}
      />
    </div>
  );
};

export default AccessReviewGroups;
