import { datadogRum } from "@datadog/browser-rum";
import AuthContext, { AuthContextState } from "components/auth/AuthContext";
import { useContext } from "react";
import { useLocation } from "react-router";

import { Event } from "./constants";

/**
 * makeLogEvent is our internal wrapper around amplitude's track function
 * that returns a logEvent function to use.
 *
 * @param userProperties are the current values of user properties to update before
 * tracking the event
 * @param defaultEventProperties specifies some pre-computed set of default properties
 *  that we pass for each event (e.g. userID)
 * @returns a logEvent function that takes event data (name, properties)
 */
const makeLogEvent = (
  defaultEventProperties: Record<string, string>,
  skipLogEvent: boolean
) => (event: Event) => {
  if (skipLogEvent) return;

  const { name, properties } = event;

  datadogRum.addAction(name, {
    ...defaultEventProperties,
    ...properties,
  });
};

export default function useLogEvent() {
  const location = useLocation();
  const { authState } = useContext(AuthContext);

  // Skip logging events when impersonating a user.
  const skipLogEvent = Boolean(authState.impersonatingUser?.id);

  return makeLogEvent(
    {
      pageURL: location.pathname,
    },
    skipLogEvent
  );
}

export function getUserRoles(authState: AuthContextState) {
  const roles = [];
  if (authState.user?.isAdmin) {
    roles.push("Admin");
  }
  if (authState.user?.isAuditor) {
    roles.push("Auditor");
  }
  if (authState.user?.isReadOnlyAdmin) {
    roles.push("Read-only Admin");
  }

  if (roles.length === 0) {
    roles.push("Member");
  }
  return roles;
}
