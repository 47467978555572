import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AuthType,
  ConnectionType,
  Maybe,
  useCreateConnectionMutation,
  Visibility,
} from "api/generated/graphql";
import salesforceLogo from "assets/logos/salesforce-logo.png";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { Button, FormGroup, Input } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import {
  CreateConnectionComponents as CreateConnectionComponentsV2,
  CreateConnectionView as CreateConnectionViewV2,
} from "views/connections/create/CreateConnectionComponents";
import {
  CreateConnectionComponentsV3,
  CreateConnectionViewV3,
} from "views/connections/create/CreateConnectionComponentsV3";
import VisibilitySelector from "views/visibility/VisibilitySelector";

const CreateSalesforce = () => {
  return <CreateSalesforceForm />;
};

const CreateSalesforceForm = () => {
  const history = useHistory();
  const hasAppLevelVisibility = useFeatureFlag(FeatureFlag.AppLevelVisibility);
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const logEvent = useLogEvent();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [name, setName] = useState("Salesforce");
  const [description, setDescription] = useState("");
  const [visibility, setVisibility] = useState<Visibility>(Visibility.Global);
  const [visibilityGroupIds, setVisibilityGroupIds] = useState<
    string[] | undefined
  >([]);
  const [email, setEmail] = useState("");
  const [hostname, setHostname] = useState("");
  const [password, setPassword] = useState("");
  const [clientID, setClientID] = useState("");
  const [clientSecret, setClientSecret] = useState("");

  const [adminOwnerId, setAdminOwnerId] = useState<string | undefined>(
    undefined
  );

  const [createConnectionMutation, { loading }] = useCreateConnectionMutation({
    refetchQueries: ["AppsListColumn", "Connections"],
  });

  const fieldUnset =
    name === "" ||
    description === "" ||
    adminOwnerId === undefined ||
    email === "" ||
    password === "" ||
    hostname === "" ||
    clientID === "" ||
    clientSecret === "";

  const onSubmit = async () => {
    try {
      const { data } = await createConnectionMutation({
        variables: {
          input: {
            name: name,
            description: description,
            connectionType: ConnectionType.Salesforce,
            adminOwnerId: adminOwnerId ?? "",
            visibility: visibility,
            visibilityGroupIds: visibilityGroupIds ?? [],
            importVisibility: Visibility.Global,
            metadata: {
              connectionType: ConnectionType.Salesforce,
              salesforce: {
                hostname,
                email,
              },
            },
            credentials: {
              authType: AuthType.Salesforce,
              salesforce: {
                password,
                clientID,
                clientSecret,
              },
            },
          },
        },
      });
      switch (data?.createConnection.__typename) {
        case "CreateConnectionResult":
          displaySuccessToast(`Success: Salesforce app created`);
          history.replace(
            getResourceUrl(
              EntityTypeDeprecated.Connection,
              data.createConnection.connection.id
            )
          );
          logEvent({
            name: "apps_create_click",
            properties: {
              connectionType: ConnectionType.Salesforce,
            },
          });

          break;
        case "ConnectionExistsError":
        case "ConnectionBadMetadataError":
        case "UserFacingError":
          logWarning(new Error(data.createConnection.message));
          setErrorMessage(data.createConnection.message);
          break;
        default:
          logError(new Error(`failed to create Salesforce connection`));
          setErrorMessage(`Error: failed to create Salesforce connection`);
      }
    } catch (error) {
      logError(error, "failed to create Salesforce connection");
      setErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to create Salesforce connection",
          error
        )
      );
    }
  };

  const CreateConnectionComponents = hasV3
    ? CreateConnectionComponentsV3
    : CreateConnectionComponentsV2;
  const CreateConnectionView = hasV3
    ? CreateConnectionViewV3
    : CreateConnectionViewV2;

  return (
    <CreateConnectionView
      logo={salesforceLogo}
      title={"Add your Salesforce organization"}
      onSubmit={onSubmit}
      submitDisabled={fieldUnset}
      submitLoading={loading}
    >
      <>
        <CreateConnectionComponents
          title={"Step 1"}
          subtitle={"Create a service account for Opal"}
        >
          <>
            <p>
              Opal requires a service account to manage Salesforce on your
              behalf. Follow these instructions:
            </p>
            <ol>
              <li>
                In Salesforce, open Setup &rsaquo; Platform Tools &rsaquo; Apps
                &rsaquo; App Manager &rsaquo; New Connected App (top right). Use
                the name "Opal", API name "opal", and any contact email. Select
                "Enable OAuth Settings." Set the callback URL to
                "https://auth.opal.dev" and add the "Manage User Data via APIs",
                and "Perform requests at any time" scopes. Save the app, then
                copy the Consumer Key and Consumer Secret below. Click Manage
                &rsaquo; Edit Policies. &rsaquo; Under Oauth Policies &rsaquo;
                Permitted Users, select "All users may self-authorize." Under IP
                Relaxation, select "Relax IP restrictions." Save these settings.
              </li>
              <li>
                On the left menu, open Setup &rsaquo; Administration &rsaquo;
                Users &rsaquo; Profiles, and create a new profile for Opal. We
                recommend using the Existing Profile "Minimum Access -
                Salesforce" and setting the Profile Name to "Opal Integration".
              </li>
              <li>
                On the following page, click "Edit" and ensure the profile has
                the following permissions:
                <ul>
                  <li>API Enabled</li>
                  <li>Assign Permission Sets</li>
                  <li>Manage Internal Users</li>
                  <li>Manage Profiles and Permission Sets</li>
                  <li>Manage Roles</li>
                  <li>View all Profiles</li>
                  <li>View all Users</li>
                  <li>View Roles and Role Hierarchy</li>
                  <li>View Setup and Configuration</li>
                </ul>
                The Opal integration will be prohibited from assigning any
                profile with the "Modify All Data" permission (e.g. System
                Administrator) unless it also has that permission:
                <ul>
                  <li>Modify All Data</li>
                </ul>
              </li>
              <li>
                In Setup &rsaquo; Administration &rsaquo; Users &rsaquo; Users,
                create a new user. Select the "Salesforce" User License and the
                "Opal Integration" profile you created. You must use a real
                email address to complete account activation; save the email
                address below. Finally, set all other the required fields to any
                values; e.g., set Last Name to "Opal".
              </li>
              <li>
                Open the account activation email and set a long, 32+ random
                character password (think of this as an API key). For the
                security question, choose a different long 32+ random character
                random value. Save the password below.
              </li>
              <li>
                Log in to the service account and click the user profile avatar
                in the top right of the page. Copy the Salesforce hostname
                listed in the dropdown, and save it below.
              </li>
            </ol>
          </>
        </CreateConnectionComponents>
        <CreateConnectionComponents
          title={"Step 2"}
          subtitle={"Add your service account credentials"}
          isLast
        >
          <>
            <p>
              {`Once the service account is created, please input the user's
              credentials and some information about the connection:`}
            </p>
            <FormGroup label="App name:">
              <Input
                onChange={setName}
                placeholder="Identifiable name for the Salesforce connection."
                value={name}
              />
            </FormGroup>
            <FormGroup label="App admin:">
              <OwnerDropdown
                selectedOwnerId={adminOwnerId}
                onSelectOwner={(owner) => setAdminOwnerId(owner?.id)}
                placeholder="Select an owner to own this app."
              />
            </FormGroup>
            <FormGroup label="Description:">
              <Input
                onChange={setDescription}
                placeholder="A brief description of the account to further inform people requesting access to it."
                value={description}
              />
            </FormGroup>
            {hasAppLevelVisibility && (
              <FormGroup label="Visibility:">
                <VisibilitySelector
                  visibility={visibility}
                  onChangeVisibility={(vis) => {
                    if (vis == Visibility.Global) {
                      setVisibility(Visibility.Global);
                      setVisibilityGroupIds([]);
                    } else {
                      setVisibility(Visibility.Team);
                    }
                  }}
                  visibilityGroups={visibilityGroupIds ?? []}
                  onChangeVisibilityGroups={setVisibilityGroupIds}
                />
              </FormGroup>
            )}
            <FormGroup label="Salesforce Consumer Key:">
              <Input onChange={setClientID} value={clientID} />
            </FormGroup>
            <FormGroup label="Salesforce Consumer Secret:">
              <Input
                onChange={(value) => {
                  setClientSecret(value);
                }}
                value={clientSecret}
              />
            </FormGroup>
            <FormGroup label="Salesforce hostname:">
              <Input
                onChange={setHostname}
                placeholder="Example: my-organization.my.salesforce.com"
                value={hostname}
              />
            </FormGroup>
            <FormGroup label="Salesforce service account email:">
              <Input onChange={setEmail} value={email} />
            </FormGroup>
            <FormGroup label="Salesforce service account password:">
              <Input onChange={setPassword} value={password} type="password" />
            </FormGroup>
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
            {!hasV3 && (
              <div
                className={sprinkles({
                  display: "flex",
                  justifyContent: "flex-end",
                })}
              >
                <Button
                  type="primary"
                  disabled={fieldUnset}
                  label={"Create"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </div>
            )}
          </>
        </CreateConnectionComponents>
      </>
    </CreateConnectionView>
  );
};

export default CreateSalesforce;
