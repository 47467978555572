import { useListEventStreamsQuery } from "api/generated/graphql";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import { CreateEventStreamConnectionModal } from "components/event_streaming/EventStreamConnectionModals";
import { EventStreamConnections } from "components/event_streaming/EventStreamConnections";
import React, { useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { OrgSettingsSection } from "views/settings/OrgSettingsV3";

export type EventStreamingProps = {
  title: string;
};

export const EventStreaming = (props: EventStreamingProps) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const hasEventStreaming = useFeatureFlag(FeatureFlag.EventStreaming);
  const canUseEventStreaming = hasV3 && hasEventStreaming;
  const [showCreateModal, setShowCreateModal] = useState(false);

  const { data, error, loading } = useListEventStreamsQuery({
    skip: !canUseEventStreaming,
    fetchPolicy: "cache-and-network",
  });

  if (!canUseEventStreaming) {
    return (
      <>
        <NotFoundPage />
      </>
    );
  }

  if (error) {
    logError(new Error(`failed to fetch event streams`));
    return (
      <>
        <UnexpectedErrorPage error={error} />
      </>
    );
  }

  const eventStreams = data?.listEventStreams.eventStreams ?? [];
  return (
    <>
      <OrgSettingsSection title={props.title}>
        {!data && loading ? (
          <ColumnListItemsSkeleton />
        ) : (
          <EventStreamConnections
            eventStreams={eventStreams}
            setShowCreateModal={setShowCreateModal}
          />
        )}
      </OrgSettingsSection>
      <CreateEventStreamConnectionModal
        showModal={showCreateModal}
        onCreate={() => setShowCreateModal(false)}
        onCancel={() => setShowCreateModal(false)}
      ></CreateEventStreamConnectionModal>
    </>
  );
};
