import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewConnectionPreviewFragment,
  AccessReviewFragment,
  EntityType,
  ReviewerUserStatus,
  useUpdateConnectionReviewersMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { ResourceLabel } from "components/label/Label";
import AccessReviewerModal from "components/modals/AccessReviewerModal";
import {
  CellRow,
  Header,
  ScrollableMuiVirtualTable,
} from "components/tables/material_table/MuiVirtualTable";
import { useToast } from "components/toast/Toast";
import { Button } from "components/ui";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { SortDirection } from "react-virtualized";
import { logError } from "utils/logging";
import AccessReviewContext, {
  AccessReviewContextActionType,
  emptyPerformReviewState,
} from "views/access_reviews/AccessReviewContext";
import AccessReviewReviewersCell from "views/access_reviews/AccessReviewReviewersCell";
import {
  AccessReviewStatusLabel,
  accessReviewSummaryStatusSortValue,
} from "views/access_reviews/AccessReviewStatus";
import {
  getReviewerModalEntries,
  getReviewersSortValue,
} from "views/access_reviews/common/Common";
import { getAccessReviewEntityUrl } from "views/access_reviews/common/Routes";
import {
  CONNECTION_NAME_HEADER,
  REVIEWERS_HEADER,
  SUMMARY_ACTION_HEADER,
  SUMMARY_STATUS_HEADER,
} from "views/access_reviews/common/TableHeaders";

interface ConnectionsAccessReviewTableRow {
  name: string;
  reviewers: string;
  status: string;
  action: string;
}

type AccessReviewConnectionsTableProps = {
  accessReview: AccessReviewFragment;
  accessReviewConnections: AccessReviewConnectionPreviewFragment[];
};

const AccessReviewConnectionsTable = (
  props: AccessReviewConnectionsTableProps
) => {
  const history = useHistory();
  const { authState } = useContext(AuthContext);
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );

  const headers: Header<ConnectionsAccessReviewTableRow>[] = [
    CONNECTION_NAME_HEADER,
    REVIEWERS_HEADER,
    SUMMARY_STATUS_HEADER,
  ];

  if (accessReviewState.ongoingAccessReviewIdSet.has(props.accessReview.id)) {
    headers.push(SUMMARY_ACTION_HEADER);
  }

  let rows: CellRow<ConnectionsAccessReviewTableRow>[] = [];

  props.accessReviewConnections.forEach((accessReviewConnection) => {
    if (accessReviewConnection.connection) {
      const name = accessReviewConnection.connection.name;
      const status = accessReviewConnection.status;
      const userCanReviewConnection = authState.user?.user.id
        ? getUserCanReviewConnection(
            authState.user.user.id,
            accessReviewConnection
          )
        : false;

      rows.push({
        id: accessReviewConnection.id,
        data: {
          name: {
            value: name,
            element: (
              <ResourceLabel
                text={name}
                connectionType={
                  accessReviewConnection.connection.connectionType
                }
                bold={true}
                icon={
                  getConnectionTypeInfo(
                    accessReviewConnection.connection.connectionType
                  )?.icon
                }
                iconLarge={true}
                pointerCursor={true}
              />
            ),
            clickHandler: () => {
              history.push(getAccessReviewEntityUrl(accessReviewConnection));
            },
          },
          reviewers: {
            value: getReviewersSortValue(accessReviewConnection.reviewerUsers),
            element:
              accessReviewConnection.numConnectionUsers === 0 ? (
                <>--</>
              ) : (
                <ConnectionAccessReviewersCell
                  accessReviewConnection={accessReviewConnection}
                />
              ),
          },
          status: {
            sortValue:
              10 * accessReviewSummaryStatusSortValue(status) +
              (authState.user?.user.id &&
              getUserCanReviewConnection(
                authState.user.user.id,
                accessReviewConnection
              )
                ? 0
                : 1),
            value: status,
            element: (
              <AccessReviewStatusLabel
                entityType={EntityType.Connection}
                status={status}
                warnings={accessReviewConnection.warnings}
                connection={accessReviewConnection.connection}
                accessReviewId={accessReviewConnection.accessReviewId}
              />
            ),
          },
          action: {
            value: userCanReviewConnection.toString(),
            element: userCanReviewConnection ? (
              <Button
                type="warning"
                label="Review Access"
                leftIconName="check-square"
                onClick={() => {
                  if (accessReviewConnection.connection) {
                    accessReviewDispatch({
                      type:
                        AccessReviewContextActionType.AccessReviewItemUpdate,
                      payload: {
                        performReviewStateByUARConnectionId: {
                          ...accessReviewState.performReviewStateByUARConnectionId,
                          [accessReviewConnection.id]: emptyPerformReviewState(),
                        },
                      },
                    });
                    history.push(
                      getAccessReviewEntityUrl(accessReviewConnection)
                    );
                  }
                }}
              />
            ) : (
              <>{"--"}</>
            ),
          },
        },
      });
    }
  });

  return (
    <ScrollableMuiVirtualTable
      columns={headers}
      rows={rows}
      defaultSortBy={"status"}
      defaultSortDirection={SortDirection.ASC}
      allRowsLoaded
    />
  );
};

export const getUserCanReviewConnection = (
  userId: string,
  connection: AccessReviewConnectionPreviewFragment
) => {
  for (const reviewerUser of connection.reviewerUsers) {
    if (
      reviewerUser.userId === userId &&
      reviewerUser.status === ReviewerUserStatus.NotStarted
    ) {
      return true;
    }
  }
  return false;
};

type ConnectionAccessReviewersCellProps = {
  accessReviewConnection: AccessReviewConnectionPreviewFragment;
};

const ConnectionAccessReviewersCell = (
  props: ConnectionAccessReviewersCellProps
) => {
  const { accessReviewState } = useContext(AccessReviewContext);
  const [showReviewersModal, setShowReviewersModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { displaySuccessToast } = useToast();

  const [
    updateConnectionReviewers,
    { loading },
  ] = useUpdateConnectionReviewersMutation();

  const canEditReviewers =
    accessReviewState.ongoingAccessReviewIdSet.has(
      props.accessReviewConnection.accessReviewId
    ) && props.accessReviewConnection.canEditReviewers;

  return (
    <>
      <AccessReviewReviewersCell
        itemType="app"
        reviewerUsers={getReviewerModalEntries(
          props.accessReviewConnection.reviewerUsers,
          null
        )}
        canEditReviewers={canEditReviewers}
        onClick={() => {
          setShowReviewersModal(true);
        }}
      />
      {showReviewersModal && (
        <AccessReviewerModal
          title={"Reviewers"}
          isModalOpen={showReviewersModal}
          numUserReviews={props.accessReviewConnection.numConnectionUsers}
          onClose={() => {
            setShowReviewersModal(false);
            setErrorMessage("");
          }}
          loading={loading}
          onSubmit={async (reviewers) => {
            try {
              const { data } = await updateConnectionReviewers({
                variables: {
                  input: {
                    accessReviewConnectionId: props.accessReviewConnection.id,
                    reviewers: reviewers,
                  },
                },
                refetchQueries: [
                  "OngoingAccessReviewStats",
                  "OngoingAccessReviewTabStats",
                  "OngoingAccessReviewSubtabStats",
                ],
              });
              switch (data?.updateConnectionReviewers.__typename) {
                case "UpdateConnectionReviewersResult": {
                  displaySuccessToast("Success: Reviewers Updated");
                  setShowReviewersModal(false);
                  break;
                }
                case "AccessReviewAlreadyStoppedError": {
                  setErrorMessage(data?.updateConnectionReviewers.message);
                  break;
                }
              }
            } catch (error) {
              logError(error, "failed to update access reviewers");
              setErrorMessage(
                getModifiedErrorMessage(
                  "Error: failed to update access reviewers",
                  error
                )
              );
            }
          }}
          errorMessage={errorMessage}
          entryInfos={getReviewerModalEntries(
            props.accessReviewConnection.reviewerUsers,
            null
          )}
          canEditReviewers={canEditReviewers}
        />
      )}
    </>
  );
};
export default AccessReviewConnectionsTable;
