import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";

import ColumnListItem, { ColumnListItemsSkeleton } from "./ColumnListItem";
import * as styles from "./ColumnListScroller.css";

interface Props {
  numRows: number;
  renderRow: (index: number) => JSX.Element;
  // For infinite scroll
  onLoadMore?: () => Promise<void>;
  loading?: boolean;
  hasNextPage?: boolean;
  emptyState?: {
    title: string;
    subtitle?: string;
  };
}

const ROW_HEIGHT = 48;

const ColumnListScroller: React.FC<Props> = (props) => {
  const {
    renderRow,
    numRows,
    onLoadMore,
    hasNextPage,
    loading,
    emptyState,
  } = props;

  if (loading && numRows === 0) {
    return <ColumnListItemsSkeleton />;
  }

  // Add one row to show loading indicator
  const totalRows = hasNextPage ? numRows + 1 : numRows;

  const isItemLoaded = (index: number) => !hasNextPage || index < numRows;

  const RowComponent = ({
    index,
    style,
  }: ListChildComponentProps): React.ReactElement => {
    const content = isItemLoaded(index) ? (
      renderRow(index)
    ) : (
      <ColumnListItem label="Loading..." type="loader" />
    );
    return <div style={style}>{content}</div>;
  };

  // Don't call load more if already in the process of loading more items
  const handleLoadMoreItems =
    onLoadMore && !loading ? onLoadMore : () => Promise.resolve();

  let content = (
    <AutoSizer>
      {({ height, width }) => (
        <InfiniteLoader
          isItemLoaded={isItemLoaded}
          loadMoreItems={handleLoadMoreItems}
          itemCount={totalRows}
        >
          {({ onItemsRendered, ref }) => {
            return (
              <FixedSizeList
                ref={ref}
                itemCount={totalRows}
                // These -1 offsets are needed to correct a rounding issue where
                // AutoSizer will sometimes return a div that's larger than its
                // parent, causing an unwanted double scrollbar or blinking
                // scrollbar.
                // See: https://github.com/bvaughn/react-virtualized/issues/1287
                height={height - 1}
                width={width - 1}
                itemSize={ROW_HEIGHT}
                onItemsRendered={onItemsRendered}
              >
                {RowComponent}
              </FixedSizeList>
            );
          }}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );

  if (numRows === 0) {
    let emptyStateTitle = emptyState?.title ?? "No rows";
    content = (
      <div className={styles.emptyState}>
        <div className={styles.emptyStateTitle}>{emptyStateTitle}</div>
        {emptyState?.subtitle ? (
          <div className={styles.emptyStateSubtitle}>{emptyState.subtitle}</div>
        ) : null}
      </div>
    );
  }

  return <div className={styles.container}>{content}</div>;
};

export default ColumnListScroller;
