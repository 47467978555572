import { ColumnHeaderSkeleton } from "components/column/ColumnHeader";
import { Divider } from "components/ui";
import React from "react";

import ViewSkeleton from "./ViewSkeleton";

const ColumnContentSkeleton = () => {
  return (
    <>
      <ColumnHeaderSkeleton />
      <Divider margin="md" />
      <ViewSkeleton />
    </>
  );
};

export default ColumnContentSkeleton;
