import { Badge, Icon, Label } from "components/ui";
import { useContext } from "react";

import {
  BASE_NODE_HEIGHT,
  BASE_NODE_WIDTH,
  GroupByNodeData,
  ICON_HEIGHT,
  ICON_WIDTH,
  NODE_PADDING,
  TEXT_SPACING,
} from "../common";
import { GraphContext } from "../contexts/GraphContext";
import * as styles from "./GroupByNode.css";
import Node from "./Node";

interface Props {
  data: GroupByNodeData;
}

const GroupByNode = ({ data }: Props) => {
  const { graphState, graphDispatch } = useContext(GraphContext);

  return (
    <Node
      data={data}
      onClick={() => {
        graphDispatch({
          type: "TOGGLE_EXPAND_NODE",
          payload: {
            nodeId: data.nodeId,
          },
        });
      }}
    >
      <foreignObject
        className={styles.label}
        x={NODE_PADDING}
        y={BASE_NODE_HEIGHT / 2 - 10}
        height={TEXT_SPACING}
        width={BASE_NODE_WIDTH - ICON_WIDTH * 2 - NODE_PADDING}
      >
        <Label label={data.label} oneLine />
      </foreignObject>
      <foreignObject
        width={ICON_WIDTH + 12}
        height={ICON_HEIGHT}
        x={BASE_NODE_WIDTH - 60}
        y={BASE_NODE_HEIGHT / 2 - 8}
      >
        <span className={styles.badgeContainer}>
          <Badge count={data.count} size="sm" maxCount={999} />
        </span>
      </foreignObject>
      <foreignObject
        width={ICON_WIDTH}
        height={ICON_HEIGHT}
        x={BASE_NODE_WIDTH - NODE_PADDING * 2}
        y={BASE_NODE_HEIGHT / 2 - ICON_HEIGHT / 2}
        className={styles.expandIconOuterContainer}
      >
        <span className={styles.expandIconInnerContainer}>
          <Icon
            name={
              graphState.expandedIds.includes(data.nodeId)
                ? "chevron-up"
                : "chevron-down"
            }
            size="xs"
          />
        </span>
      </foreignObject>
    </Node>
  );
};

export default GroupByNode;
