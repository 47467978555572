import { getTimeZones, TimeZone } from "@vvo/tzdb";

const isPriorityTimeZone = (timeZoneData: TimeZone) => {
  return timeZoneData.countryCode === "US" || timeZoneData.countryCode === "CA";
};

export const timeZones: TimeZone[] = getTimeZones().sort((a, b) => {
  if (isPriorityTimeZone(a) && isPriorityTimeZone(b)) {
    return a.currentTimeOffsetInMinutes - b.currentTimeOffsetInMinutes;
  } else if (isPriorityTimeZone(a)) {
    return -1;
  } else if (isPriorityTimeZone(b)) {
    return 1;
  }
  return a.currentTimeOffsetInMinutes - b.currentTimeOffsetInMinutes;
});

export const getDefaultTimezone = (): TimeZone => {
  const value = timeZones.find((timeZone) => {
    return timeZone.name === "America/Los_Angeles";
  });

  return value || timeZones[0];
};

/**
 * Render `timeZone` into a string that looks like Google Calendar's time zones.
 */
export const getTimeZoneDisplayText = (timeZoneData: TimeZone) => {
  // "-07:00 Pacific Time - Los Angeles, San Diego" -> "-07:00"
  const currentTimeFormat = timeZoneData.currentTimeFormat;
  const timeOffsetStr = currentTimeFormat.substring(
    0,
    currentTimeFormat.indexOf(" ")
  );

  // "America/Los_Angeles" -> "Los Angeles"
  let location = timeZoneData.name
    .substring(timeZoneData.name.indexOf("/") + 1)
    .replace("_", " ");

  // "Indiana/Indianapolis" -> "Indianapolis, Indiana"
  if (location.indexOf("/") !== -1) {
    const locationPieces = location.split("/");
    location = locationPieces[1] + ", " + locationPieces[0];
  }

  // "(GMT-07:00) Pacific Time - Los Angeles"
  return `(GMT${timeOffsetStr}) ${timeZoneData.alternativeName} - ${location}`;
};

export const getTimeZoneFromString = (timeZoneString?: string) => {
  if (!timeZoneString) return undefined;
  const timeZones = getTimeZones();
  return timeZones.find((t) => t.name === timeZoneString);
};

export const getTimeZoneGuess = () => {
  const guess = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return timeZones.find((tz) => tz.name === guess);
};

export interface Time {
  key: string;
  label: string;
  value: Date;
}

export const getTimeLabel = (date: Date, timezone?: string) => {
  return date.toLocaleString([], {
    hour: "numeric",
    hour12: true,
    minute: "numeric",
    timeZone: timezone ?? "UTC",
  });
};

export const getTimesEveryHalfHour = () => {
  var time = new Date(Date.UTC(1970, 1, 1));

  var endTime = new Date(Date.UTC(1970, 1, 1));
  endTime.setDate(endTime.getDate() + 1);

  var intervals = [];

  while (time < endTime) {
    intervals.push({
      key: time.toTimeString(),
      label: getTimeLabel(time, "UTC"),
      value: new Date(time),
    });
    time.setMinutes(time.getMinutes() + 30);
  }

  return intervals;
};
