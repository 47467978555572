import { getModifiedErrorMessage } from "api/ApiContext";
import {
  EntityType,
  Maybe,
  useDeleteUserMutation,
  UserPreviewSmallFragment,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Banner, Modal } from "components/ui";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { logError, logWarning } from "utils/logging";

export type UserDeleteModalProps = {
  user: UserPreviewSmallFragment;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

export const UserDeleteModal = (props: UserDeleteModalProps) => {
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [deleteUsers, { loading }] = useDeleteUserMutation();

  const deleteModalReset = () => {
    props.setShowModal(false);
    setErrorMessage(null);
  };

  const handleSubmit = async () => {
    try {
      const { data } = await deleteUsers({
        variables: {
          input: {
            id: props.user.id,
          },
        },
        refetchQueries: ["Users", "UsersHome"],
        update: (cache, { data }) => {
          switch (data?.deleteUser.__typename) {
            case "DeleteUserResult":
              cache.evict({
                id: cache.identify(data?.deleteUser.user),
              });
              break;
          }
        },
      });
      switch (data?.deleteUser.__typename) {
        case "DeleteUserResult":
          deleteModalReset();
          displaySuccessToast(`Success: user deleted`);
          history.replace(
            getResourceUrlNew({
              entityId: null,
              entityType: EntityType.User,
            })
          );
          break;
        case "UserNotFoundError":
        case "SystemUserIsImmutableError":
        case "OpalAdminRoleMustHaveAtLeastOneDirectUser":
          logWarning(new Error(data.deleteUser.message));
          setErrorMessage(data.deleteUser.message);
          break;
        default:
          logError(new Error(`failed to delete user`));
          setErrorMessage(`Error: failed to delete user`);
      }
    } catch (error) {
      logError(error, `failed to delete user`);
      setErrorMessage(
        getModifiedErrorMessage(`Error: failed to delete user`, error)
      );
    }
  };

  return (
    <Modal
      title={`Remove ${props.user.fullName} from Opal`}
      isOpen={props.showModal}
      onClose={deleteModalReset}
    >
      <Modal.Body>
        <p>
          <Banner
            message={`If your user has any expiring or on-call access provisioned through Opal,
            this access will become permanent on the end system once you remove the user.`}
            type="warning"
          />
        </p>
        <p>
          Are you sure you want to remove{" "}
          <b>
            {props.user.fullName} ({props.user.email})
          </b>{" "}
          from Opal? This cannot be undone.
        </p>
        <p>
          Removing a user from Opal only deletes their configuration in Opal and
          does not cause access to be removed from the end system. This includes
          access provisioned through Opal.
        </p>
        {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
      </Modal.Body>
      <Modal.Footer
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLabel="Remove"
        primaryButtonLoading={loading}
        primaryButtonType="error"
      />
    </Modal>
  );
};

export default UserDeleteModal;
