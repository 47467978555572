import {
  EntityType,
  Maybe,
  ResourceType,
  ResourceUserFragment,
  UserPreviewSmallFragment,
} from "api/generated/graphql";
import AccessPathLabel from "components/label/AccessPathLabel";
import EventLabel from "components/label/item_labels/EventLabel";
import {
  ResourceLabel,
  TimeLabel,
  timeValueIndefinite,
} from "components/label/Label";
import AccessPointModal from "components/modals/AccessPointModal";
import MaterialTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MaterialTable";
import { Button, ButtonV3 } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import React, { useState } from "react";
import useLogEvent from "utils/analytics";

interface ResourceAccessPointRow {
  role: string;
  expirationDate: string;
  event: string;
  accessPath: string;
}

type ResourceExpirationLabelProps = {
  user: Maybe<UserPreviewSmallFragment> | undefined;
  resource: {
    id: string;
    name: string;
    resourceType: ResourceType;
  };
  entityType: EntityType;
  expiration: Maybe<moment.Moment>;
  currentUserResourceUsers: ResourceUserFragment[];
  iconOnly?: boolean;
  hasV3?: boolean;
  buttonSize?: PropsFor<typeof ButtonV3>["size"];
  hasOtherPrimaryButton?: boolean;
};

const expirationColMinWidth = "60px";
const accessPathColMinWidth = "400px";
const accessPathColMaxWidth = "800px";

export const ResourceExpirationLabel = (
  props: ResourceExpirationLabelProps
) => {
  const [showModal, setShowModal] = useState(false);
  const logEvent = useLogEvent();

  const headers: Header<ResourceAccessPointRow>[] = [
    { id: "role", label: "Role" },
    { id: "expirationDate", label: "Expires" },
    { id: "event", label: "Event" },
    { id: "accessPath", label: "Access Path" },
  ];

  const accessPointRows: CellRow<ResourceAccessPointRow>[] = [];

  props.currentUserResourceUsers.forEach((resourceUser) => {
    const directAccessPoint = resourceUser.access?.directAccessPoint;
    if (directAccessPoint) {
      const expirationTime = directAccessPoint.expiration
        ? moment(new Date(directAccessPoint.expiration))
        : null;
      accessPointRows.push({
        id: directAccessPoint.resourceId,
        data: {
          role: {
            value: directAccessPoint.accessLevel?.accessLevelName || "",
            element: (
              <div>
                {directAccessPoint.accessLevel?.accessLevelName || "--"}
              </div>
            ),
          },
          expirationDate: {
            value:
              expirationTime?.unix() ||
              `${directAccessPoint.resourceId}-expiration`,
            sortValue: expirationTime?.unix() || timeValueIndefinite,
            element: (
              <TimeLabel
                time={expirationTime}
                supportTicket={directAccessPoint.supportTicket}
                useExpiringLabel
              />
            ),
            style: { minWidth: expirationColMinWidth },
          },
          event: {
            value: directAccessPoint.eventId,
            element: <EventLabel eventId={directAccessPoint.eventId} />,
          },
          accessPath: {
            value: `${directAccessPoint.resourceId}-access-path`,
            element: (
              <AccessPathLabel
                accessLabels={[
                  <ResourceLabel
                    entityTypeNew={EntityType.User}
                    entityId={props.user?.id}
                    text={" "}
                    avatar={props.user?.avatarUrl}
                    maxChars={16}
                  />,
                  <ResourceLabel
                    entityTypeNew={EntityType.Resource}
                    entityId={directAccessPoint.resourceId}
                    text={props.resource?.name}
                    resourceType={props.resource?.resourceType}
                    maxChars={16}
                  />,
                ]}
                maxWidth={accessPathColMaxWidth}
              />
            ),
            style: { minWidth: accessPathColMinWidth },
          },
        },
      });
    }

    resourceUser.access?.groupUserAccesses.forEach((groupUserAccess) => {
      const directAccessPoint = groupUserAccess.directAccessPoint;

      if (directAccessPoint) {
        const expirationTime = directAccessPoint?.expiration
          ? moment(new Date(directAccessPoint.expiration))
          : null;

        accessPointRows.push({
          id: directAccessPoint.groupId,
          data: {
            role: {
              value: resourceUser.accessLevel.accessLevelName,
              element: <div>{resourceUser.accessLevel.accessLevelName}</div>,
            },
            expirationDate: {
              value:
                expirationTime?.unix() ||
                `${groupUserAccess.groupId}-expiration`,
              sortValue: expirationTime?.unix() || timeValueIndefinite,
              element: (
                <TimeLabel
                  time={expirationTime}
                  supportTicket={directAccessPoint.supportTicket}
                />
              ),
              style: { minWidth: expirationColMinWidth },
            },
            event: {
              value: directAccessPoint.eventId,
              element: <EventLabel eventId={directAccessPoint.eventId} />,
            },
            accessPath: {
              value: `${groupUserAccess.groupId}-access-path`,
              element: (
                <AccessPathLabel
                  accessLabels={[
                    <ResourceLabel
                      entityTypeNew={EntityType.User}
                      entityId={props.user?.id}
                      text={" "}
                      avatar={props.user?.avatarUrl}
                      maxChars={16}
                    />,
                    <ResourceLabel
                      entityTypeNew={EntityType.Group}
                      entityId={directAccessPoint.groupId}
                      text={directAccessPoint.groupName}
                      maxChars={16}
                    />,
                    <ResourceLabel
                      entityTypeNew={EntityType.Resource}
                      entityId={props.resource?.id}
                      text={props.resource?.name}
                      resourceType={props.resource?.resourceType}
                      maxChars={16}
                    />,
                  ]}
                  maxWidth={accessPathColMaxWidth}
                />
              ),
              style: { minWidth: accessPathColMinWidth },
            },
          },
        });
      }

      groupUserAccess.indirectAccessPointPaths.forEach(
        (indirectAccessPointPath) => {
          const resolvedAccessPoint =
            indirectAccessPointPath.resolvedAccessPoint;

          if (resolvedAccessPoint) {
            const expirationTime = resolvedAccessPoint?.expiration
              ? moment(new Date(resolvedAccessPoint.expiration))
              : null;

            let accessPathLabels = [
              <ResourceLabel
                entityTypeNew={EntityType.User}
                entityId={props.user?.id}
                text={" "}
                avatar={props.user?.avatarUrl}
                maxChars={16}
              />,
            ];

            accessPathLabels = [
              ...accessPathLabels,
              ...indirectAccessPointPath.indirectAccessPointPath.map(
                (indirectAccessPoint) => {
                  return (
                    <ResourceLabel
                      entityTypeNew={EntityType.Group}
                      entityId={indirectAccessPoint.groupId}
                      text={indirectAccessPoint.groupName}
                      maxChars={16}
                    />
                  );
                }
              ),
            ];

            accessPathLabels.push(
              <ResourceLabel
                entityTypeNew={EntityType.Resource}
                entityId={props.resource?.id}
                text={props.resource?.name}
                resourceType={props.resource?.resourceType}
                maxChars={16}
              />
            );

            accessPointRows.push({
              id: resolvedAccessPoint.groupId,
              data: {
                role: {
                  value: resourceUser.accessLevel.accessLevelName,
                  element: (
                    <div>{resourceUser.accessLevel.accessLevelName}</div>
                  ),
                },
                expirationDate: {
                  value:
                    expirationTime?.unix() ||
                    `${groupUserAccess.groupId}-expiration`,
                  sortValue: expirationTime?.unix() || timeValueIndefinite,
                  element: (
                    <TimeLabel
                      time={expirationTime}
                      supportTicket={resolvedAccessPoint.supportTicket}
                    />
                  ),
                  style: { minWidth: expirationColMinWidth },
                },
                event: {
                  value: resolvedAccessPoint.eventId,
                  element: <EventLabel eventId={resolvedAccessPoint.eventId} />,
                },
                accessPath: {
                  value: `${groupUserAccess.groupId}-access-path`,
                  element: (
                    <AccessPathLabel
                      accessLabels={accessPathLabels}
                      maxWidth={accessPathColMaxWidth}
                    />
                  ),
                  style: { minWidth: accessPathColMinWidth },
                },
              },
            });
          }
        }
      );
    });
  });

  const numAccessPoints = accessPointRows.length;
  if (numAccessPoints === 0) {
    return null;
  }

  return (
    <div>
      {props.hasV3 ? (
        <ButtonV3
          label="Access"
          leftIconName="check-circle"
          onClick={() => {
            setShowModal(!showModal);
            if (props.resource) {
              logEvent({
                name: "apps_access_details_click",
                properties: {
                  itemType: props.resource?.resourceType,
                },
              });
            }
          }}
          type={props.hasOtherPrimaryButton ? "successSecondary" : "success"}
          size={props.buttonSize}
        />
      ) : (
        <Button
          label="Access Details"
          leftIconName="check-circle"
          onClick={() => {
            setShowModal(!showModal);
            if (props.resource) {
              logEvent({
                name: "apps_access_details_click",
                properties: {
                  itemType: props.resource?.resourceType,
                },
              });
            }
          }}
          borderless
          size="md"
        />
      )}
      {showModal && (
        <AccessPointModal
          subject={props.user}
          object={props.resource}
          accessPathContent={
            <div className={sprinkles({ padding: "sm" })}>
              <MaterialTable<ResourceAccessPointRow>
                headers={headers}
                rows={accessPointRows}
                denseFormatting={true}
                tableContainerStyles={{ maxHeight: 300 }}
              />
            </div>
          }
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
};
