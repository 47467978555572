import { useBundleQuery } from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import ColumnHeader from "components/column/ColumnHeader";
import { Divider, Tabs } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { AuthorizedActionManage } from "utils/auth/auth";

import BundleDeleteModal from "./BundleDeleteModal";
import { BUNDLE_VIEWS } from "./BundleDetailContent";
import BundleRenameModal from "./BundleRenameModal";

interface Props {
  actionButtons?: React.ReactElement;
  canManage: boolean;
}

const BundleContentHeader = (props: Props) => {
  const history = useHistory();
  const location = useLocation();
  const { bundleId } = useParams<Record<string, string>>();
  const { authState } = useContext(AuthContext);

  const [showRenameModal, setShowRenameModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data } = useBundleQuery({
    variables: {
      input: {
        id: bundleId,
      },
    },
    skip: !bundleId,
  });

  if (!bundleId || data?.bundle.__typename !== "BundleResult") {
    return null;
  }

  const currentView = BUNDLE_VIEWS.find((view) =>
    location.pathname.endsWith(view.key)
  );

  if (!currentView) {
    return null;
  }

  const bundle = data.bundle.bundle;

  const menuOptions: PropsFor<typeof ColumnHeader>["menuOptions"] = [];
  if (bundle.authorizedActions?.includes(AuthorizedActionManage)) {
    menuOptions.push({
      label: "Rename",
      icon: { type: "name", icon: "edit-3" },
      onClick: () => setShowRenameModal(true),
    });
  }
  if (authState.user?.isAdmin) {
    menuOptions.push({
      label: "Delete",
      onClick: () => setShowDeleteModal(true),
      type: "danger",
      icon: { type: "name", icon: "trash" },
    });
  }

  const getBadgeCount = (view: typeof BUNDLE_VIEWS[number]) => {
    if (view.key === "resources") {
      return bundle.totalNumResources;
    } else if (view.key === "groups") {
      return bundle.totalNumGroups;
    }
  };

  return (
    <>
      <ColumnHeader
        title={bundle.name}
        subtitle={currentView.title}
        icon={{ type: "name", icon: currentView.icon }}
        menuOptions={menuOptions}
        rightActions={props.actionButtons}
      />
      <Divider />
      {props.canManage && (
        <div
          className={sprinkles({ display: "flex", justifyContent: "center" })}
        >
          <Tabs
            tabInfos={BUNDLE_VIEWS.map((view) => ({
              title: view.title,
              isSelected: currentView.key === view.key,
              onClick: () => {
                history.push(`/bundles/${bundleId}/${view.key}`);
              },
              badgeCount: getBadgeCount(view),
            }))}
            round
          />
        </div>
      )}
      {showRenameModal && (
        <BundleRenameModal
          bundle={bundle}
          onClose={() => setShowRenameModal(false)}
        />
      )}
      {showDeleteModal && (
        <BundleDeleteModal
          bundle={bundle}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
    </>
  );
};

export default BundleContentHeader;
