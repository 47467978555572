import { Popper } from "@material-ui/core";
import { Button, Icon, Input, Label, Loader } from "components/ui";
import { IconData } from "components/ui/utils";
import { useRef, useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { AutoSizer } from "react-virtualized";
import { FixedSizeList, ListChildComponentProps } from "react-window";
import InfiniteLoader from "react-window-infinite-loader";

import * as styles from "./FacetFilter.css";

interface FacetOption {
  label: string;
  iconName?: PropsFor<typeof Icon>["name"];
}

interface SearchOption {
  label: string;
  onClick: () => void;
  icon?: IconData;
}

interface Props {
  facetOptions: FacetOption[];
  searchOptions?: SearchOption[];
  searchLoading: boolean;
  onSelectedFacet: (facetOption: FacetOption | undefined) => void;
  onSearch: (query: string) => void;
  onLoadMore: () => void;
  hasNextPage?: boolean;
}

const FacetFilter = (props: Props) => {
  const buttonRef = useRef<HTMLDivElement>(null);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedFacetOption, setSelectedFacetOption] = useState<
    FacetOption | undefined
  >();

  let content: JSX.Element | null = null;

  const numRows = (props.searchOptions ?? []).length;
  const isItemLoaded = (index: number) => !props.hasNextPage || index < numRows;

  const renderRow = (index: number) => {
    if (!props.searchOptions) {
      return null;
    }
    const menuOption = props.searchOptions[index];
    return (
      <li
        key={index}
        className={styles.menuItem}
        onClick={() => {
          menuOption.onClick();
          setShowMenu(false);
          setSelectedFacetOption(undefined);
        }}
      >
        {menuOption.icon ? (
          <span className={styles.iconContainer}>
            <Icon data={menuOption.icon} size="xs" />
          </span>
        ) : null}
        <div>
          <Label label={menuOption.label} oneLine truncateLength={20} />
        </div>
      </li>
    );
  };

  const RowComponent = ({
    index,
    style,
  }: ListChildComponentProps): React.ReactElement => {
    const content = isItemLoaded(index) ? renderRow(index) : null;
    return <div style={style}>{content}</div>;
  };

  if (!selectedFacetOption) {
    content = (
      <ul className={styles.menuContainer}>
        {props.facetOptions.map((menuOption, index) => (
          <li
            key={index}
            className={styles.menuItem}
            onClick={() => {
              setSelectedFacetOption(menuOption);
              props.onSelectedFacet(menuOption);
            }}
          >
            {menuOption.iconName ? (
              <span className={styles.iconContainer}>
                <Icon name={menuOption.iconName} size="xs" />
              </span>
            ) : null}
            <div>
              <Label label={menuOption.label} oneLine />
            </div>
          </li>
        ))}
      </ul>
    );
  } else {
    // Don't call load more if already in the process of loading more items
    const handleLoadMoreItems = !props.searchLoading
      ? props.onLoadMore
      : () => Promise.resolve();

    const totalRows = props.hasNextPage ? numRows + 1 : numRows;

    let infiniteScroller = (
      <AutoSizer>
        {({ height, width }) => (
          <InfiniteLoader
            isItemLoaded={isItemLoaded}
            loadMoreItems={handleLoadMoreItems}
            itemCount={totalRows}
          >
            {({ onItemsRendered, ref }) => {
              return (
                <FixedSizeList
                  ref={ref}
                  itemCount={totalRows}
                  height={height}
                  width={width}
                  itemSize={30}
                  onItemsRendered={onItemsRendered}
                >
                  {RowComponent}
                </FixedSizeList>
              );
            }}
          </InfiniteLoader>
        )}
      </AutoSizer>
    );

    content = (
      <div className={styles.menuContainer}>
        <Input
          type="search"
          style="facetMenu"
          onChange={(v) => {
            props.onSearch(v);
          }}
          leftIconName="search"
          autoFocus
          placeholder={selectedFacetOption.label}
        />
        <div
          className={styles.scrollContainer({ loading: props.searchLoading })}
        >
          {props.searchLoading ? (
            <Loader label="Loading" />
          ) : (
            <>
              {props.searchOptions?.length === 0 ? "No results" : null}
              {infiniteScroller}
            </>
          )}
        </div>
      </div>
    );
  }

  return (
    <>
      <div className={styles.buttonContainer} ref={buttonRef}>
        <Button
          leftIconName="filter"
          borderless
          round
          onClick={() =>
            setShowMenu((prev) => {
              if (prev) {
                setSelectedFacetOption(undefined);
                props.onSelectedFacet(undefined);
              }
              return !prev;
            })
          }
        />
      </div>
      <Popper
        placement="bottom-end"
        open={showMenu}
        anchorEl={buttonRef.current}
        className={styles.overlayContainer}
      >
        <OutsideClickHandler
          onOutsideClick={(e) => {
            if (
              e.target instanceof Element &&
              buttonRef.current?.contains(e.target) === true
            ) {
              // Do not handle this if it is a click on our button
              return;
            }
            setShowMenu(false);
            setSelectedFacetOption(undefined);
            props.onSelectedFacet(undefined);
          }}
        >
          <div className={styles.overlay}>{content}</div>
        </OutsideClickHandler>
      </Popper>
    </>
  );
};

export default FacetFilter;
