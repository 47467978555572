import { UserIdentities } from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { serviceTypeInfoByType } from "components/label/ServiceTypeLabel";
import { ButtonV3, FormRow, Icon, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useContext } from "react";
import { formatHrIdpStatus } from "views/users/utils";

import { FormMode, UserConfig } from "./common";
import PositionRow from "./rows/PositionRow";
import UserRow from "./rows/UserRow";
import * as styles from "./UsersConfigFormV3.css";
import { sortTagWithOktaStandardAttributes } from "./utils";

interface Props {
  mode: FormMode;
  config: Partial<UserConfig>;
  onChange?: (config: Partial<UserConfig>) => void;
  setShowEditModal?: (show: boolean) => void;
}

export const UsersConfigFormV3 = (props: Props) => {
  const { authState } = useContext(AuthContext);

  const handleChange = (key: keyof UserConfig) => (
    val: UserConfig[keyof UserConfig]
  ) => {
    if (props.onChange) {
      props.onChange({ ...props.config, [key]: val });
    }
  };

  const sortedUserAttributes = sortTagWithOktaStandardAttributes(
    props.config.userAttributeTags ?? []
  );

  return (
    <div className={styles.detailsContainer}>
      <FormRow title="User Details">
        {authState.user?.isAdmin && (
          <div
            className={sprinkles({
              flexGrow: 1,
              display: "flex",
              justifyContent: "flex-end",
            })}
          >
            <ButtonV3
              type="mainBorderless"
              label="Edit Details"
              leftIconName="edit"
              size="md"
              onClick={() => {
                props.setShowEditModal && props.setShowEditModal(true);
              }}
            />
          </div>
        )}
      </FormRow>
      <FormRow title="Email">{props.config.email}</FormRow>
      <FormRow title="Secondary Emails">
        {props.config.secondaryEmails?.length
          ? props.config.secondaryEmails.join(", ")
          : `--`}
      </FormRow>
      <PositionRow
        mode={props.mode}
        onChange={handleChange("position")}
        value={props.config.position ?? undefined}
      />
      <FormRow title="Team">
        {props.config.team ? props.config.team : `--`}
      </FormRow>
      <UserRow
        title="Manager"
        mode={props.mode}
        onChange={handleChange("manager")}
        user={props.config.manager ?? undefined}
        disabled={!props.config.editableManager}
        editDescription={
          !props.config.editableManager
            ? "This field is imported from your IDP/HRIS and can't be edited from Opal."
            : undefined
        }
      />
      <FormRow title="HRIS/IDP Status">
        <>
          {props.config.hrIdpStatus
            ? formatHrIdpStatus(props.config.hrIdpStatus)
            : "None"}
        </>
      </FormRow>
      <UserIdentitiesSection identities={props.config.identities} />
      {sortedUserAttributes.length > 0 &&
        sortedUserAttributes.map((tag) => {
          const serviceTypeInfo = serviceTypeInfoByType[tag.source];
          return (
            <FormRow title={`${_.startCase(tag.tag?.key)}`}>
              <div
                className={sprinkles({
                  display: "flex",
                  justifyContent: "space-between",
                })}
              >
                {tag.tag?.value}{" "}
                <Tooltip
                  tooltipText={`Imported from ${serviceTypeInfo.name}`}
                  arrow
                >
                  <Icon externalIconUrl={serviceTypeInfo.icon ?? undefined} />
                </Tooltip>
              </div>
            </FormRow>
          );
        })}
    </div>
  );
};

interface UserIdentitiesSectionProps {
  identities?: UserIdentities;
}

const UserIdentitiesSection = (props: UserIdentitiesSectionProps) => {
  if (
    props.identities?.gitLabUserId?.shouldShow ||
    props.identities?.gitHubUsername?.shouldShow ||
    props.identities?.teleportUsername?.shouldShow
  ) {
    return (
      <>
        {props.identities.gitHubUsername?.shouldShow && (
          <FormRow title="GitHub Username">
            {props.identities.gitHubUsername.value || "--"}
          </FormRow>
        )}
        {props.identities.gitLabUserId?.shouldShow && (
          <FormRow title="GitLab UserId">
            {props.identities.gitLabUserId.value || "--"}
          </FormRow>
        )}
        {props.identities.teleportUsername?.shouldShow && (
          <FormRow title="Teleport Username">
            {props.identities.teleportUsername.value || "--"}
          </FormRow>
        )}
      </>
    );
  }

  return null;
};
