import { GroupBindingTinyFragment } from "api/generated/graphql";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { Divider, Icon, Label, Popover } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React from "react";

type GroupBindingDetailPopoverProps = {
  groupId: string;
  groupBinding: GroupBindingTinyFragment;
};

const GroupBindingDetailPopover: React.FC<GroupBindingDetailPopoverProps> = ({
  groupId,
  groupBinding,
}) => {
  const thisGroupStr =
    groupBinding.sourceGroupId === groupId ? "(this group)" : "";
  const sourceGroup = groupBinding.groups.find(
    (g) => g.id === groupBinding.sourceGroupId
  );
  return (
    <Popover
      inline
      content={
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            padding: "sm",
            gap: "xs",
          })}
        >
          <span>This group is linked to</span>
          {groupBinding.groups
            .filter((g) => g.id !== groupId)
            .map((g) => (
              <Label
                linkTo={`/groups/${g?.id}`}
                key={g?.id}
                label={g.name ?? "Hidden group"}
                icon={{
                  type: "src",
                  icon: getGroupTypeInfo(g?.groupType)?.icon,
                }}
              />
            ))}
          <Divider margin="md" />
          <span>Source of truth:</span>
          <span
            className={sprinkles({
              fontWeight: "bold",
              display: "flex",
              flexDirection: "row",
              gap: "sm",
            })}
          >
            <Label
              linkTo={`/groups/${groupBinding.sourceGroupId}`}
              label={sourceGroup?.name ?? "Hidden group"}
              icon={{
                type: "src",
                icon: getGroupTypeInfo(sourceGroup?.groupType)?.icon,
              }}
            />
            <span
              className={sprinkles({ fontWeight: "normal", color: "gray600" })}
            >
              {thisGroupStr}
            </span>
          </span>
        </div>
      }
    >
      <Icon name="link" size="xs" />
    </Popover>
  );
};

export default GroupBindingDetailPopover;
