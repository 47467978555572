import { EventType, UserFragment, useUserQuery } from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import FirstPartyTokenDropdown from "components/dropdown/FirstPartyTokenDropdown";
import { PaginatedUserDropdown } from "components/dropdown/PaginatedUserDropdown";
import { ButtonV3, Input, Select } from "components/ui";
import DateRangePicker from "components/ui/date_picker/DateRangePicker";
import TableFilters from "components/ui/table/TableFilters";
import { LocationDescriptorObject } from "history";
import { isEqual } from "lodash";
import moment from "moment";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { isPropertyValue } from "utils/enums";
import { eventTypeToString } from "utils/events";

import * as styles from "./EventFilterBarV3.css";
import { SaveFilterModal } from "./Events";
import { buildFilterURLParams, useEventFilter, useEventTypes } from "./utils";

const propagationErrorEventTypes = [
  EventType.PropagationFailureAddUserToGroup,
  EventType.PropagationFailureAddUserToResource,
  EventType.PropagationFailureAddResourceToGroup,
  EventType.PropagationFailureRemoveUserFromGroup,
  EventType.PropagationFailureRemoveUserFromResource,
  EventType.PropagationFailureRemoveUserFromConnection,
  EventType.PropagationFailureRemoveResourceFromGroup,
];

interface EventFilterBarV3Props {
  route?: LocationDescriptorObject;
  hideObjectFilter?: boolean;
  enableSaveFilter?: boolean;
  onChange?: () => void;
}

const EventFilterBarV3 = ({
  route,
  hideObjectFilter: hideObjectFiter,
  enableSaveFilter = false,
  onChange,
}: EventFilterBarV3Props) => {
  const history = useHistory();
  const { authState } = useContext(AuthContext);
  const filtersInfosState = useEventFilter();
  const [showSaveModal, setShowSaveModal] = useState(false);
  const [currentObjectId, setCurrentObjectId] = useState<string>(
    filtersInfosState.objects?.objectId ?? ""
  );
  const eventTypes = useEventTypes();

  const startDateInfo = filtersInfosState.startDate;
  const endDateInfo = filtersInfosState.endDate;
  const startDate = startDateInfo?.date
    ? moment(startDateInfo.date, "YYYY-MM-DD").toDate()
    : undefined;
  const endDate = endDateInfo?.date
    ? moment(endDateInfo.date, "YYYY-MM-DD").toDate()
    : undefined;
  const actorInfo = filtersInfosState.actors;
  const eventTypeInfo = filtersInfosState.eventTypes;
  const systemEventsInfo = filtersInfosState.systemEvents;
  const apiTokensInfo = filtersInfosState.apiTokens;

  const isPropagationErrorFilter = isEqual(
    eventTypeInfo?.eventTypes,
    propagationErrorEventTypes
  );

  let actorUser: UserFragment | undefined;
  const { data: actorUserData } = useUserQuery({
    variables: {
      input: {
        id: actorInfo?.userId ?? "",
      },
    },
    skip: !actorInfo?.userId,
  });

  switch (actorUserData?.user.__typename) {
    case "UserResult":
      actorUser = actorUserData.user.user;
  }

  const setFiltersInfosState = (newState: typeof filtersInfosState) => {
    const searchParameters = new URLSearchParams(route?.search);
    const newParams = buildFilterURLParams(newState);
    // Allow the new parameters to overwrite any conflicting existing parameters.
    // There *shouldn't* be any conflicts, but this is a safety measure. Without it,
    // the same parameter could be added multiple times in the query string.
    newParams.forEach((value, key) => {
      searchParameters.set(key, value);
    });
    history.push({
      pathname: route?.pathname ? route.pathname : "/events",
      search: searchParameters.toString(),
      hash: route?.hash,
    });

    if (onChange) {
      onChange();
    }
  };

  const hasFilters = Object.keys(filtersInfosState).length > 0;

  let eventValue: EventType | string | undefined;
  if (systemEventsInfo) {
    eventValue = "System Events";
  } else if (isPropagationErrorFilter) {
    eventValue = "Propagation Errors";
  } else {
    eventValue = eventTypeInfo?.eventTypes
      ? eventTypeInfo.eventTypes[0]
      : undefined;
  }

  return (
    <>
      <TableFilters>
        <TableFilters.Left>
          <div className={styles.dateFilter}>
            <DateRangePicker
              size="sm"
              selectedRange={{
                from: startDate,
                to: endDate,
              }}
              leftAlign
              setSelectedRange={(range) => {
                setFiltersInfosState({
                  ...filtersInfosState,
                  startDate: range
                    ? {
                        date: moment(range?.from).format("YYYY/MM/DD"),
                      }
                    : undefined,
                  endDate: range
                    ? {
                        date: moment(range?.to).format("YYYY/MM/DD"),
                      }
                    : undefined,
                });
              }}
            />
          </div>
          <div className={styles.filter}>
            <PaginatedUserDropdown
              size="sm"
              value={actorUser}
              onChange={(user) =>
                setFiltersInfosState({
                  ...filtersInfosState,
                  actors: {
                    userId: user?.id ?? "",
                  },
                })
              }
              clearable
              placeholder="Select user"
              includeSystemUser
            />
          </div>
          <div className={styles.filter}>
            <Select
              size="sm"
              options={["System Events", "Propagation Errors", ...eventTypes]}
              onChange={(eventType) => {
                if (eventType == null) {
                  setFiltersInfosState({
                    ...filtersInfosState,
                    systemEvents: undefined,
                    eventTypes: undefined,
                  });
                }
                if (isPropertyValue(EventType, eventType)) {
                  setFiltersInfosState({
                    ...filtersInfosState,
                    eventTypes: {
                      eventTypes: [eventType],
                    },
                    systemEvents: undefined,
                  });
                } else if (eventType === "System Events") {
                  setFiltersInfosState({
                    ...filtersInfosState,
                    systemEvents: true,
                    eventTypes: undefined,
                  });
                } else if (eventType === "Propagation Errors") {
                  setFiltersInfosState({
                    ...filtersInfosState,
                    eventTypes: {
                      eventTypes: propagationErrorEventTypes,
                    },
                    systemEvents: undefined,
                  });
                }
              }}
              getOptionLabel={(option) =>
                isPropertyValue(EventType, option)
                  ? eventTypeToString(option, true)
                  : option
              }
              placeholder="Select event type"
              value={eventValue}
              clearable
            />
          </div>
          {!hideObjectFiter && (
            <div className={styles.filter}>
              <Input
                size="smmd"
                value={currentObjectId}
                type="text"
                onChange={setCurrentObjectId}
                onEnter={() =>
                  setFiltersInfosState({
                    ...filtersInfosState,
                    objects: {
                      objectId: currentObjectId,
                    },
                  })
                }
                placeholder="Object ID"
              />
            </div>
          )}
          <div className={styles.filter}>
            <FirstPartyTokenDropdown
              size="sm"
              onChange={(token) =>
                setFiltersInfosState({
                  ...filtersInfosState,
                  apiTokens: token
                    ? {
                        apiTokenLabel: token.tokenLabel,
                        apiTokenPreview: token.tokenPreview,
                      }
                    : undefined,
                })
              }
              value={
                apiTokensInfo
                  ? {
                      tokenLabel: apiTokensInfo.apiTokenLabel,
                      tokenPreview: apiTokensInfo.apiTokenPreview,
                    }
                  : undefined
              }
              clearable
            />
          </div>
          {hasFilters && authState.user?.isAdmin && enableSaveFilter && (
            <ButtonV3
              label="Save Filters"
              type="mainSecondary"
              onClick={() => setShowSaveModal(true)}
              size="sm"
            />
          )}
        </TableFilters.Left>
      </TableFilters>

      {showSaveModal && (
        <SaveFilterModal
          redirectOnSave={false}
          onClose={() => setShowSaveModal(false)}
        />
      )}
    </>
  );
};

export default EventFilterBarV3;
