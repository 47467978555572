import {
  ConnectionPreviewFragment,
  ConnectionType,
  EntityType,
  ImportSetting,
  Visibility,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { ClickToCopyButton } from "components/buttons/ClickToCopyButton";
import { ConnectionConfig } from "components/forms/common";
import AdminRow from "components/forms/rows/AdminRow";
import ChildrenDefaultConfigTemplateRow from "components/forms/rows/ChildrenDefaultConfigTemplateRow";
import ConnectionAutoImportGroupResourcesRow from "components/forms/rows/ConnectionAutoImportGroupResourcesRow";
import ConnectionImportNotificationRowV3 from "components/forms/rows/ConnectionImportNotificationRowV3";
import ConnectionImportSettingRow from "components/forms/rows/ConnectionImportSettingRow";
import ConnectionTicketProviderPropagationRow, {
  TicketProviderFields,
} from "components/forms/rows/ConnectionTicketProviderPropagationRow";
import ConnectionWebhookToggleRow from "components/forms/rows/ConnectionWebhookToggleRow";
import DescriptionRow from "components/forms/rows/DescriptionRow";
import VisibilityRow from "components/forms/rows/VisibilityRow";
import { connectionTypeInfoByType } from "components/label/ConnectionTypeLabel";
import { ButtonV3, FormRow, Label } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import { useHistory, useParams } from "react-router";
import { hasRemoteGroupResources } from "utils/directory/connections";
import { isNativeConnectionType } from "utils/directory/resources";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import * as styles from "./AppDetailsV3.css";

interface DetailsProps {
  connection: ConnectionPreviewFragment;
  config: Partial<ConnectionConfig>;
  setConfig: (config: Partial<ConnectionConfig>) => void;
}

const AppDetailsV3 = (props: DetailsProps) => {
  const { connection, config, setConfig } = props;
  const { authState } = useContext(AuthContext);
  const history = useHistory();
  const { appId } = useParams<Record<string, string>>();

  const hasWebhookToggleFF = useFeatureFlag(
    FeatureFlag.ConnectionWebhookToggle
  );
  const hasConfigTemplates = useFeatureFlag(FeatureFlag.ConfigurationTemplates);
  const hasAutoImportConfigTemplate = useFeatureFlag(
    FeatureFlag.ConnectionAutoImportConfigTemplate
  );
  const hasAppLevelVisibility = useFeatureFlag(FeatureFlag.AppLevelVisibility);

  const handleChange = (key: keyof ConnectionConfig) => (
    val: ConnectionConfig[keyof ConnectionConfig]
  ) => {
    setConfig({
      ...config,
      [key]: val,
    });
  };

  const handleChangeShouldNotify = (val: boolean) => {
    let importNotification = {
      shouldNotify: val,
      recipientOwnerId: config.importNotification?.recipientOwnerId,
      recipientOwnerName: config.importNotification?.recipientOwnerName,
    };

    setConfig({
      ...config,
      importNotification: importNotification,
    });
  };

  const handleChangeImportRecipientOwner = (recipient?: {
    name: string;
    id: string;
  }) => {
    let importNotification = {
      shouldNotify: config.importNotification
        ? config.importNotification.shouldNotify
        : false,
      recipientOwnerId: recipient?.id,
      recipientOwnerName: recipient?.name,
    };

    setConfig({
      ...config,
      importNotification: importNotification,
    });
  };

  const handleChangeTicketProvider = ({
    ticketProviderEnabled,
    ticketProjectId,
    ticketProjectName,
    ticketProvider,
  }: TicketProviderFields) => {
    setConfig({
      ...config,
      ticketProviderEnabled,
      ticketProjectId,
      ticketProjectName,
      ticketProvider,
    });
  };

  const handleChangeOwner = (owner?: { name: string; id: string }) => {
    setConfig({
      ...config,
      adminOwnerId: owner?.id,
      adminOwnerName: owner?.name,
    });
  };

  const importFormRows = [];
  if (!isNativeConnectionType(connection.connectionType)) {
    const awsMetadata =
      (connection.metadata?.__typename === "AWSSSOConnectionMetadata" &&
        connection.metadata) ||
      null;

    importFormRows.push(
      <ConnectionImportSettingRow
        key="importSetting"
        connectionType={connection.connectionType}
        mode={"view"}
        importSetting={config.importSetting ?? ImportSetting.None}
        onChange={handleChange("importSetting")}
        awsIdentityCenterImportSetting={config.awsIdentityCenterImportSetting}
        onChangeAwsIdentityCenterImportSetting={handleChange(
          "awsIdentityCenterImportSetting"
        )}
        awsOrganizationSetImportSetting={config.awsOrganizationImportSetting}
        onChangeAwsOrganizationImportSetting={handleChange(
          "awsOrganizationImportSetting"
        )}
        hideAwsOrganizationImportSetting={
          awsMetadata !== null && !awsMetadata.awsOrganizationEnabled
        }
        hideAwsIdentityCenterImportSetting={
          awsMetadata !== null && !awsMetadata.awsSsoEnabled
        }
        isV3
      />
    );
    if (
      config.importSetting === ImportSetting.All ||
      config.importSetting === ImportSetting.Tagged
    ) {
      importFormRows.push(
        <ConnectionImportNotificationRowV3
          key="importNotification"
          mode={"view"}
          shouldNotify={
            config.importNotification
              ? config.importNotification!.shouldNotify
              : false
          }
          recipientOwner={
            config.importNotification?.recipientOwnerId &&
            config.importNotification?.recipientOwnerName
              ? {
                  id: config.importNotification!.recipientOwnerId,
                  name: config.importNotification!.recipientOwnerName,
                }
              : config.adminOwnerId && config.adminOwnerName
              ? {
                  id: config.adminOwnerId,
                  name: config.adminOwnerName,
                }
              : undefined
          }
          onShouldNotifyChange={handleChangeShouldNotify}
          onRecipientOwnerChange={handleChangeImportRecipientOwner}
        />
      );
    }

    if (
      hasRemoteGroupResources(connection.connectionType) &&
      connection.importSetting !== ImportSetting.All
    ) {
      importFormRows.push(
        <ConnectionAutoImportGroupResourcesRow
          key="autoImportGroupResources"
          mode={"view"}
          autoImportGroupResources={Boolean(config.autoImportGroupResources)}
          onChange={handleChange("autoImportGroupResources")}
        />
      );
    }
  }
  importFormRows.push(
    <VisibilityRow
      entityType={EntityType.Connection}
      key="importVisibility"
      title="Import visibility"
      tooltipText="Specifies the default visibility setting of items imported into this app."
      mode={"view"}
      visibility={config.importVisibility}
      visibilityGroups={config.importVisibilityGroups ?? []}
      onChangeVisibilityAndGroups={(val: {
        visibility?: Visibility;
        groupIds?: string[];
      }) => {
        const { visibility, groupIds } = val;
        const visDict = visibility ? { importVisibility: visibility } : {};
        const visGroupsDict = groupIds
          ? { importVisibilityGroups: groupIds }
          : {};
        setConfig({
          ...config,
          ...visDict,
          ...visGroupsDict,
        });
      }}
      isV3
    />
  );
  if (hasConfigTemplates && hasAutoImportConfigTemplate) {
    importFormRows.push(
      <ChildrenDefaultConfigTemplateRow
        key="importedChildrenConfigurationTemplate"
        mode={"view"}
        configurationTemplate={config.childrenDefaultConfigTemplate}
        onChange={handleChange("childrenDefaultConfigTemplate")}
      />
    );
  }

  // Hide webhook toggle for non-native connections, unless already enabled.
  const showConnectionWebhookToggle =
    isNativeConnectionType(connection.connectionType) ||
    hasWebhookToggleFF ||
    config.webhookEnabled;

  return (
    <div className={styles.detailsContainer}>
      <div>
        <FormRow title="App Details">
          {authState.user?.isAdmin && (
            <div
              className={sprinkles({
                flexGrow: 1,
                display: "flex",
                justifyContent: "flex-end",
              })}
            >
              <ButtonV3
                type="mainBorderless"
                label="Edit Details"
                leftIconName="edit"
                size="md"
                onClick={() => history.push(`/apps/${appId}/edit`)}
              />
            </div>
          )}
        </FormRow>
        <FormRow title="Name">
          <Label label={connection.name} />
        </FormRow>
        <FormRow title="Type">
          <Label
            label={connectionTypeInfoByType[connection.connectionType].name}
            icon={{ type: "entity", entityType: connection.connectionType }}
          />
        </FormRow>
        <DescriptionRow
          mode={"view"}
          value={config.description}
          onChange={handleChange("description")}
        />
        <FormRow title="ID">
          {<ClickToCopyButton text={connection.id} />}
        </FormRow>
        <AdminRow
          owner={
            config.adminOwnerId && config.adminOwnerName
              ? {
                  id: config.adminOwnerId,
                  name: config.adminOwnerName,
                }
              : undefined
          }
          mode={"view"}
          onChange={handleChangeOwner}
        />
        {hasAppLevelVisibility && (
          <VisibilityRow
            key="visibility"
            title="App visibility"
            tooltipText="App visibility determines which users can see this app and all its resources."
            mode={"view"}
            entityType={EntityType.Connection}
            visibility={config.visibility}
            visibilityGroups={config.visibilityGroupIds ?? []}
            onChangeVisibilityAndGroups={(val: {
              visibility?: Visibility;
              groupIds?: string[];
            }) => {
              const { visibility, groupIds } = val;
              const visDict = visibility ? { visibility } : {};
              const visGroupsDict = groupIds
                ? { visibilityGroupIds: groupIds }
                : {};
              setConfig({
                ...config,
                ...visDict,
                ...visGroupsDict,
              });
            }}
            additionalWarning={
              connection.connectionType === ConnectionType.OktaDirectory &&
              config.visibility === Visibility.Team
                ? `The visibility settings of this app do not impact the
            visibility of Okta Apps or the groups associated with them. To
            manage their visibility, please utilize the individual item
            visibility settings. This specific Okta Directory App will remain
            undiscoverable to members with access unless they possess an
            Admin role or are part of a designated visibility group.`
                : undefined
            }
            isV3
          />
        )}
        {showConnectionWebhookToggle && (
          <>
            <ConnectionWebhookToggleRow
              mode={"view"}
              connectionType={connection.connectionType}
              webhookEnabled={Boolean(config.webhookEnabled)}
              onChange={handleChange("webhookEnabled")}
              isV3
            />
            {connection.connectionType == ConnectionType.Custom ? (
              <ConnectionTicketProviderPropagationRow
                mode={"view"}
                ticketProviderEnabled={Boolean(config.ticketProviderEnabled)}
                ticketProjectId={config.ticketProjectId}
                ticketProjectName={config.ticketProjectName}
                ticketProvider={config.ticketProvider}
                onChange={handleChangeTicketProvider}
              />
            ) : null}
          </>
        )}
        {importFormRows}
      </div>
    </div>
  );
};

export default AppDetailsV3;
