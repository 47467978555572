import {
  ConnectionType,
  useAppDetailColumnForGroupQuery,
  useAppDetailColumnForItemsQuery,
  useAppDetailColumnForResourceQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import { ColumnHeaderSkeleton } from "components/column/ColumnHeader";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import { Divider } from "components/ui";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { logError } from "utils/logging";
import { useURLSearchParam } from "utils/router/hooks";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import AccountDetailColumn from "./AccountDetailColumn";
import AppDetailColumn from "./AppDetailColumn";
import {
  ACCOUNT_ID_URL_KEY,
  APP_ID_URL_KEY,
  OKTA_APP_ID_URL_KEY,
} from "./AppsContext";
import AWSAccountsColumn from "./AWSAccountsColumn";
import AWSAllAccountsColumn from "./AWSAllAccountsColumn";

const AppDetailColumnForItems = () => {
  const {
    resourceId,
    groupId,
    accountId: accountIdFromUrl,
    oktaAppId: oktaAppIdFromParam,
    appId: appIdFromParam,
  } = useParams<Record<string, string>>();

  // HACKY: When we navigate from the app detail page to
  // a resource or group page, `appIdFromParam` becomes undefined which
  // triggers a loading state of this component/scroll jump until the connectionID is fetched
  // from the graphql queries. This code below is to prevent that.
  const [oldAppIdFromParam, setOldAppIdFromParam] = useState(appIdFromParam);
  useEffect(() => {
    if (appIdFromParam !== undefined) {
      setOldAppIdFromParam(appIdFromParam);
    }
  }, [appIdFromParam]);

  const [appIdFromSearchParam] = useURLSearchParam(APP_ID_URL_KEY);
  const [oktaAppIdFromSearchParam] = useURLSearchParam(OKTA_APP_ID_URL_KEY);
  const [accountIdFromSearchParam] = useURLSearchParam(ACCOUNT_ID_URL_KEY);

  // Use appId from the URL if it exists. Otherwise, use the appId from the
  // context. If neither exist, use appId from the resource/group query.
  const connectionIdFromUrl = appIdFromParam || appIdFromSearchParam;
  const appIdFromUrl =
    oktaAppIdFromParam || oktaAppIdFromSearchParam || connectionIdFromUrl;

  const {
    data: appData,
    loading: appLoading,
    error: appError,
  } = useAppDetailColumnForItemsQuery({
    variables: {
      id: connectionIdFromUrl || "",
    },
    skip: !connectionIdFromUrl,
  });

  const {
    data: resourceData,
    previousData: previousResourceData,
    loading: resourceLoading,
    error: resourceError,
  } = useAppDetailColumnForResourceQuery({
    variables: {
      id: resourceId,
    },
    skip: Boolean(!resourceId || appIdFromUrl),
  });

  const {
    data: groupData,
    previousData: previousGroupData,
    loading: groupLoading,
    error: groupError,
  } = useAppDetailColumnForGroupQuery({
    variables: {
      id: groupId,
    },
    skip: Boolean(!groupId || appIdFromUrl),
  });

  let connectionIdFromQuery;
  let connectionType: ConnectionType | undefined;
  if (resourceData) {
    switch (resourceData.resource.__typename) {
      case "ResourceResult":
        connectionIdFromQuery = resourceData.resource.resource.connection?.id;
        connectionType =
          resourceData.resource.resource.connection?.connectionType;
        break;
      case "ResourceNotFoundError":
        break;
    }
  }
  if (groupData) {
    switch (groupData.group.__typename) {
      case "GroupResult":
        connectionIdFromQuery = groupData.group.group.connection?.id;
        connectionType = groupData.group.group.connection?.connectionType;
        break;
      case "GroupNotFoundError":
        break;
    }
  }

  // HACKY: When we navigate from one resource/group to
  // another, these queries reload which makes us lose the connection ID,
  // causing a loading state/scroll jump. This code below is to prevent that
  // by re-using the previous connection ID until the new one loads.
  if (!connectionIdFromQuery) {
    if (previousResourceData) {
      switch (previousResourceData.resource.__typename) {
        case "ResourceResult":
          connectionIdFromQuery =
            previousResourceData.resource.resource.connection?.id;
          connectionType =
            previousResourceData.resource.resource.connection?.connectionType;
          break;
        case "ResourceNotFoundError":
          break;
      }
    }
    if (previousGroupData) {
      switch (previousGroupData.group.__typename) {
        case "GroupResult":
          connectionIdFromQuery = previousGroupData.group.group.connection?.id;
          connectionType =
            previousGroupData.group.group.connection?.connectionType;
          break;
        case "GroupNotFoundError":
          break;
      }
    }
  }

  if (resourceError || groupError || appError) {
    logError(resourceError || groupError || appError);
    return (
      <Column>
        <UnexpectedErrorPage />
      </Column>
    );
  }

  const appId =
    appIdFromUrl || connectionIdFromQuery || oldAppIdFromParam || undefined;
  const accountId = accountIdFromUrl || accountIdFromSearchParam || undefined;

  if (accountId) {
    return <AccountDetailColumn accountId={accountId} />;
  }

  if (
    connectionIdFromUrl &&
    appData?.app.__typename === "App" &&
    appData.app.app.__typename === "ConnectionApp" &&
    appData.app.app.connectionType === ConnectionType.AwsSso
  ) {
    return <AWSAccountsColumn />;
  }

  if (connectionIdFromQuery && connectionType === ConnectionType.AwsSso) {
    return <AWSAllAccountsColumn appId={connectionIdFromQuery} />;
  }

  const loading = appLoading || resourceLoading || groupLoading;
  if (loading && !appId) {
    return (
      <Column>
        <ColumnHeaderSkeleton />
        <Divider margin="md" />
        <ColumnListItemsSkeleton />
      </Column>
    );
  } else if (!appId) {
    return null;
  }

  return <AppDetailColumn appId={appId} />;
};

export default AppDetailColumnForItems;
