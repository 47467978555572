import {
  ThirdPartyProvider,
  TicketProject,
  useTicketProjectsQuery,
} from "api/generated/graphql";
import { Select } from "components/ui";

import { logError } from "../../utils/logging";
import ModalErrorMessage from "../modals/ModalErrorMessage";

interface TicketProjectDropdownProps {
  ticketProvider: ThirdPartyProvider;
  selectedTicketProjectId?: string;
  selectedTicketProjectName?: string;
  onSelectTicketProject: (ticketProject?: TicketProject) => void;
  clearable?: boolean;
  placeholder?: string;
}

const TicketProjectDropdown = (props: TicketProjectDropdownProps) => {
  const { data, error, loading } = useTicketProjectsQuery({
    variables: {
      input: {
        thirdPartyProvider: props.ticketProvider,
      },
    },
  });
  if (error) {
    logError(error, `failed to list third party integrations`);
    return (
      <ModalErrorMessage
        errorMessage={"Error: failed to load ticket providers"}
      />
    );
  }

  let ticketProjects: TicketProject[] = [];
  switch (data?.ticketProjects.__typename) {
    case "TicketProjectsResult":
      ticketProjects = data.ticketProjects.ticketProjects;
      break;
    case "SupportTicketAPIKeyNotFoundError":
      return (
        <ModalErrorMessage
          errorMessage={"Error: failed to load ticket providers"}
        />
      );
  }

  return (
    <Select
      options={ticketProjects}
      getIcon={(ticketProject: TicketProject) => {
        return {
          icon: ticketProject.avatarUrl ?? undefined,
          type: "src",
        };
      }}
      getOptionLabel={(ticketProject: TicketProject) =>
        ticketProject.key
          ? `${ticketProject.key}: ${ticketProject.name}`
          : ticketProject.name
      }
      value={ticketProjects.find(
        (ticketProject) =>
          ticketProject.id === props.selectedTicketProjectId ||
          ticketProject.name === props.selectedTicketProjectName
      )}
      onChange={(ticketProject) => {
        props.onSelectTicketProject(ticketProject);
      }}
      loading={loading}
      clearable={props.clearable}
      placeholder={props.placeholder}
    />
  );
};

export default TicketProjectDropdown;
