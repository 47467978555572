import Label from "components/label/Label";
import messageLabelStyles from "components/label/MessageLabel.module.scss";
import React from "react";
import * as Icons from "react-feather";

type ErrorMessageLabelProps = {
  errorMessage: string;
  onDismiss?: () => void;
};

export const ErrorMessageLabel = (props: ErrorMessageLabelProps) => {
  return (
    <div className={messageLabelStyles.visibilityLabel}>
      <Label
        text={props.errorMessage}
        icon={<Icons.AlertTriangle size={16} strokeWidth={3} />}
        additionalStyleClass={messageLabelStyles.errorMessage}
        maxChars={props.errorMessage.length}
      />
    </div>
  );
};

type ErrorMessageLightLabelProps = {
  errorMessage: string;
};

export const ErrorMessageLightLabel = (props: ErrorMessageLightLabelProps) => {
  return (
    <div className={messageLabelStyles.visibilityLabel}>
      <Label
        text={props.errorMessage}
        icon={<Icons.AlertTriangle size={16} strokeWidth={3} />}
        additionalStyleClass={messageLabelStyles.errorMessageLight}
        maxChars={props.errorMessage.length}
      />
    </div>
  );
};
