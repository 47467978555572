import { getModifiedErrorMessage } from "api/ApiContext";
import {
  Maybe,
  OidcProviderType,
  ResourceAccessLevelFragment,
  ResourceType,
  useForfeitResourceMutation,
  useInitOidcAuthFlowMutation,
  useResourceAccessLevelsQuery,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { FormGroup, Modal, Select } from "components/ui";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { generateState } from "utils/auth/auth";
import { useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";
import {
  clearOidcData,
  getOidcData,
  OidcPostAuthAction,
  setOidcData,
} from "utils/oidc/oidc";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import OrgContext from "views/settings/OrgContext";

type CurrentUserResourceAccess = {
  resourceUsers: {
    access?: {
      directAccessPoint?: {
        accessLevel: ResourceAccessLevelFragment;
      } | null;
    } | null;
  }[];
};

type ForfeitableResource = {
  id: string;
  resourceType: ResourceType;
  currentUserAccess: CurrentUserResourceAccess;
};

export type ForfeitResourceMenuOptionProps = {
  resource: ForfeitableResource;
  refetchResource?: () => void;
  hasV3?: boolean;
  showMenuOption: (onClick: () => void) => void;
};

export const ForfeitResourceMenuOption = (
  props: ForfeitResourceMenuOptionProps
) => {
  const logEvent = useLogEvent();

  const history = useHistory();
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [polling, setPolling] = useState(false);
  const startPushTaskPoll = usePushTaskLoader();

  const [forfeitResource, { loading }] = useForfeitResourceMutation();
  const [initOidcAuthFlow] = useInitOidcAuthFlowMutation();
  const { orgState } = useContext(OrgContext);

  const [
    selectedRole,
    setSelectedRole,
  ] = useState<ResourceAccessLevelFragment>();

  const [roles, setResourceRoles] = useState<ResourceAccessLevelFragment[]>([]);

  const { displayErrorToast, displayCustomToast } = useToast();

  const modalReset = () => {
    setShowModal(false);
    setErrorMessage(null);
    if (roles.length !== 1) {
      setSelectedRole(undefined);
    }
  };
  const submitForfeiture = async () => {
    logEvent({
      name: "apps_forfeit_click",
      properties: {
        itemType: props.resource.resourceType,
      },
    });

    try {
      const { data } = await forfeitResource({
        variables: {
          input: {
            resourceId: props.resource.id,
            accessLevel: {
              accessLevelName: selectedRole?.accessLevelName || "",
              accessLevelRemoteId: selectedRole?.accessLevelRemoteId || "",
            },
          },
        },
      });
      switch (data?.forfeitResource.__typename) {
        case "ForfeitResourceResult":
          modalReset();
          if (data.forfeitResource.taskId) {
            setPolling(true);
          } else {
            props.refetchResource && props.refetchResource();
          }
          // Always call this, since it also handles empty `taskId`.
          startPushTaskPoll(data.forfeitResource.taskId, {
            refetchOnComplete: [
              {
                resourceId: props.resource.id,
              },
            ],
            onComplete: () => {
              setPolling(false);
            },
            messageOverride: {
              loading: null,
              success: `Success: forfeited direct access to resource`,
              propagation: `Success: notification sent for access to be removed`,
              error: `Error: could not forfeit access to resource`,
            },
          });
          break;
        case "OidcIDTokenNotFoundError":
          try {
            const state = generateState();
            const { data } = await initOidcAuthFlow({
              variables: {
                input: {
                  state: state,
                  oidcProviderType: OidcProviderType.AwsSession,
                },
              },
            });
            switch (data?.initOidcAuthFlow.__typename) {
              case "InitOidcAuthFlowResult":
                setOidcData({
                  state: state,
                  oidcProviderType: OidcProviderType.AwsSession,
                  postAuthPath: window.location.pathname,
                  postAuthHash: window.location.hash,
                  params: {
                    resourceId: props.resource.id,
                    accessLevel: {
                      accessLevelRemoteId:
                        selectedRole?.accessLevelRemoteId || "",
                      accessLevelName: selectedRole?.accessLevelName || "",
                    },
                    action: OidcPostAuthAction.ForfeitResource,
                  },
                });
                window.location.replace(data?.initOidcAuthFlow.authorizeUrl);
                break;
              case "OidcProviderNotFoundError":
                displayErrorToast(
                  "Error: Failed to trigger MFA. Please contact your admin."
                );
                break;
              default:
                displayErrorToast(
                  "Error: Failed to trigger MFA. Please try again or contact support if the issue persists."
                );
            }
          } catch (e) {
            logError(e, "Error: resource forfeiture failed");
            setErrorMessage(
              getModifiedErrorMessage("Error: resource forfeiture failed", e)
            );
          }
          break;

        case "ResourceNotFoundError":
        case "OpalAdminRoleMustHaveAtLeastOneDirectUser":
          logError(new Error(data.forfeitResource.message));
          setErrorMessage(data.forfeitResource.message);
          break;
        default:
          setErrorMessage(`Error: resource forfeiture failed`);
          logError(new Error(`Error: resource forfeiture failed`));
      }
    } catch (error) {
      setErrorMessage(`Error: resource forfeiture failed`);
      logError(error, `Error: resource forfeiture failed`);
    }
  };

  useMountEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has("oidc_auth")) {
      const oidcAuthStatus = queryParams.get("oidc_auth");
      if (oidcAuthStatus === "success") {
        const oidcData = getOidcData();
        if (
          oidcData &&
          oidcData.params?.action === OidcPostAuthAction.ForfeitResource &&
          oidcData.params.resourceId === props.resource.id
        ) {
          setShowModal(true);
          setSelectedRole({
            __typename: "ResourceAccessLevel",
            accessLevelName: oidcData.params.accessLevel?.accessLevelName || "",
            accessLevelRemoteId:
              oidcData.params.accessLevel?.accessLevelRemoteId || "",
          });
          submitForfeiture();
          clearOidcData();
          queryParams.delete("oidc_auth");
          history.replace({
            search: queryParams.toString(),
          });
        }
      } else {
        displayErrorToast(
          "Authentication failed. Please try again or contact support if the issue remains."
        );
      }
    }
  });

  const { loading: roleLoading, error } = useResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceId: props.resource.id,
        onlyMine: true,
      },
    },
    fetchPolicy: "no-cache",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      switch (data?.accessLevels.__typename) {
        case "ResourceAccessLevelsResult": {
          const directRoleRemoteIds =
            props.resource.currentUserAccess.resourceUsers.map(
              (ru) =>
                ru.access?.directAccessPoint?.accessLevel.accessLevelRemoteId
            ) ?? [];
          const directRoles = data.accessLevels.accessLevels.filter((role) =>
            directRoleRemoteIds.includes(role.accessLevelRemoteId)
          );

          setResourceRoles(directRoles);
          if (directRoles.length === 1) {
            setSelectedRole(directRoles[0]);
          }
          break;
        }
        case "ResourceNotFoundError":
          logError(new Error(`Error: failed to list resource roles`));
          break;
      }
    },
  });
  if (error) {
    logError(error, "failed to list resource roles");
  }

  const isDisabledByReadOnly =
    props.resource.resourceType !== ResourceType.OpalRole &&
    orgState.orgSettings?.isRemoteReadOnly;

  return (
    <>
      {props.showMenuOption(async () => {
        if (isDisabledByReadOnly) {
          displayErrorToast(
            "Error: Unable to forfeit because your organization is in read only mode."
          );
        } else if (polling) {
          displayCustomToast(
            "Warning: Pending forfeit request. Try again later.",
            "hourglass",
            true
          );
        } else {
          setShowModal(true);
        }
      })}
      {showModal && (
        <Modal isOpen={showModal} onClose={modalReset} title="Forfeit resource">
          <Modal.Body>
            <p>
              Are you sure you want to forfeit direct access to this resource?
              Note: indirect access to this resource through group membership
              will not be affected.
            </p>
            {roles.length >= 2 && (
              <FormGroup label="Role">
                <Select
                  value={selectedRole}
                  onChange={(newValue?: ResourceAccessLevelFragment) => {
                    if (newValue) {
                      setSelectedRole(newValue);
                    }
                  }}
                  options={roles || []}
                  loading={roleLoading}
                  getOptionLabel={(role) => role.accessLevelName}
                  placeholder="Search for roles"
                />
              </FormGroup>
            )}
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
          </Modal.Body>
          <Modal.Footer
            onSecondaryButtonClick={modalReset}
            primaryButtonLabel="Forfeit"
            primaryButtonType="error"
            onPrimaryButtonClick={submitForfeiture}
            primaryButtonLoading={loading}
            primaryButtonDisabled={roleLoading}
          />
        </Modal>
      )}
    </>
  );
};
