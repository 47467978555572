import { refetchQueries } from "api/ApiContext";
import {
  EntityType,
  RecommendationsEntityKey,
  RecommendationsEntityType,
  RecommendationsSubscoreType,
  useEntityForRemediationQuery,
  useRescoreEntityMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { useToast } from "components/toast/Toast";
import { Banner, Icon, Input, Modal, Skeleton } from "components/ui";
import { ExtraActions } from "components/ui/modal/Modal";
import Table from "components/ui/table/Table";
import TableHeader, { RightAction } from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import { range } from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { RemediationMetadata } from "views/recommendations/ThreatResolutionNew";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
} from "views/requests/utils";

import {
  entityHasRoles,
  getMutationHookAndConverter,
  getRemediationColumns,
  getRemediationRows,
  isUserRemediationRow,
  RemediationEntityLabel,
  RemediationRow,
} from "./RemediationColumns";
import RemediationSuggestionSection from "./RemediationSuggestionSection";
import * as styles from "./Resolutions.css";

function CountRowsFromSuggestions(
  newAccessByRowId: Record<string, number>,
  originalSuggestionsByRowId: Set<string>
): [number, number] {
  let jitCountFromSuggestions = 0;
  let revokeCountFromSuggestions = 0;
  for (const [key, value] of Object.entries(newAccessByRowId)) {
    const keyValuePair = `${key}:${value}`;
    if (originalSuggestionsByRowId.has(keyValuePair)) {
      if (value == 0) {
        revokeCountFromSuggestions++;
      } else {
        jitCountFromSuggestions++;
      }
    }
  }
  return [jitCountFromSuggestions, revokeCountFromSuggestions];
}

interface Props {
  entityKeys: RecommendationsEntityKey[];
  onClose: () => void;
  suggestionTypes: RecommendationsSubscoreType[];
  onRemediationComplete?: (metadata: RemediationMetadata) => void;
  entityRemediated?: (entityRemediatedId: string) => void;
  onDismissal?: (
    entityId: string,
    entityType: RecommendationsEntityType
  ) => void;
  isOktaApp: boolean;
}

const EntityRemediationModal = (props: Props) => {
  const { entityKeys, onClose, suggestionTypes } = props;
  const { authState } = useContext(AuthContext);
  const isAdmin = authState.user?.isAdmin;

  const { displaySuccessToast } = useToast();

  const [currentEntityKeyIndex, setCurrentEntityKeyIndex] = useState(0);
  const [entityKey, setEntityKey] = useState(entityKeys[currentEntityKeyIndex]);

  const [filterText, setFilterText] = useState("");
  const [error, setError] = useState("");
  const [newAccessByRowId, setNewAccessByRowId] = useState<
    Record<string, number>
  >({});
  const [originalSuggestionsByRowId, setOriginalSuggestionsByRowId] = useState<
    Set<string>
  >(new Set());
  const [selectedRows, setSelectedRows] = useState<string[]>([]);
  const [editMode, setEditMode] = useState(false);
  const [runRescoreMutation] = useRescoreEntityMutation();

  const onMutationCompleted = async () => {
    const [
      jitCountFromSuggestions,
      revokeCountFromSuggestions,
    ] = CountRowsFromSuggestions(newAccessByRowId, originalSuggestionsByRowId);
    props.onRemediationComplete &&
      props.onRemediationComplete({
        entityKey: entityKeys[currentEntityKeyIndex],
        jitCount: Object.values(newAccessByRowId).filter(
          (duration) => duration != null && duration !== 0
        ).length,
        jitCountFromSuggestions: jitCountFromSuggestions,
        revokeCount: Object.values(newAccessByRowId).filter(
          (duration) => duration === 0
        ).length,
        revokeCountFromSuggestions: revokeCountFromSuggestions,
        remediationType: suggestionType,
        totalSuggestionsCount: rows.length,
      });
    props.entityRemediated &&
      props.entityRemediated(entityKeys[currentEntityKeyIndex].entityId);

    await runRescoreMutation({
      variables: {
        input: entityKeys[currentEntityKeyIndex],
      },
    });
  };

  const suggestionType = suggestionTypes?.[currentEntityKeyIndex];
  const entityType = entityKeys[currentEntityKeyIndex].entityType;

  // TODO: change this when we have more edges
  const targetType =
    suggestionType === RecommendationsSubscoreType.SuggestionNotUsingAccess
      ? RecommendationsEntityType.Resource
      : RecommendationsEntityType.User;

  const {
    useMutationHook: initialUseMutationHook,
    convertRowsToMutationInput: initialConvertRowsToMutationInput,
    handleMutationErrors: initialHandleMutationErrors,
  } = getMutationHookAndConverter(entityKey.entityType, targetType);

  const [useMutationHook, setUseMutationHook] = useState(
    () => initialUseMutationHook
  );
  const [convertRowsToMutationInput, setConvertRowsToMutationInput] = useState(
    () => initialConvertRowsToMutationInput
  );
  const [handleMutationErrors, setHandleMutationErrors] = useState(
    () => initialHandleMutationErrors
  );

  useEffect(() => {
    if (entityKey.entityType == null || targetType == null) {
      return;
    }
    const {
      useMutationHook: newUseMutationHook,
      convertRowsToMutationInput: newConvertRowsToMutationInput,
      handleMutationErrors: newHandleMutationErrors,
    } = getMutationHookAndConverter(entityKey.entityType, targetType);
    setUseMutationHook(() => newUseMutationHook);
    setConvertRowsToMutationInput(() => newConvertRowsToMutationInput);
    setHandleMutationErrors(() => newHandleMutationErrors);

    if (targetType == RecommendationsEntityType.Resource) {
      setFilterText("Filter by Name");
    } else {
      setFilterText("Filter by Name or Title");
    }
  }, [entityKey.entityType, targetType]);

  const [filteredRows, setFilteredRows] = useState<RemediationRow[]>([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [runMutation, { loading: mutationLoading }] = useMutationHook({
    onCompleted: onMutationCompleted,
  });
  const onCloseAndResetIndex = () => {
    refetchQueries({
      include: ["appThreatCounts"],
    });
    setCurrentEntityKeyIndex(0);
    onClose();
  };

  useEffect(() => {
    currentEntityKeyIndex < entityKeys.length &&
      setEntityKey(entityKeys[currentEntityKeyIndex]);
    setNewAccessByRowId({});
  }, [currentEntityKeyIndex, entityKeys]);

  const hasThreatsRemaining = currentEntityKeyIndex < entityKeys.length - 1;

  const { data, loading: queryLoading } = useEntityForRemediationQuery({
    variables: {
      entityKey,
    },
    fetchPolicy: "cache-and-network",
  });

  const entity = data?.entityForRemediation;

  const rows = useMemo(() => {
    if (queryLoading || !entity) {
      return [];
    }
    return getRemediationRows(entity, targetType);
  }, [queryLoading, entity, targetType]);

  useEffect(() => {
    const newFilteredRows = rows.filter((row) => {
      if (isUserRemediationRow(row)) {
        return [row?.name, row?.title].some((field) =>
          field?.toLowerCase().includes(searchQuery.toLowerCase())
        );
      } else {
        return [row?.name].some((field) =>
          field?.toLowerCase().includes(searchQuery.toLowerCase())
        );
      }
    });
    setFilteredRows(newFilteredRows);
  }, [rows, searchQuery]);
  const showRoles = entityHasRoles(rows);
  const columns = useMemo(() => {
    if (queryLoading || !entity) {
      return [];
    }
    return getRemediationColumns(
      entity,
      targetType,
      suggestionType,
      newAccessByRowId,
      setNewAccessByRowId,
      editMode,
      showRoles
    );
  }, [
    queryLoading,
    entity,
    targetType,
    suggestionType,
    newAccessByRowId,
    editMode,
    showRoles,
  ]);

  const [extraActions, setExtraActions] = useState<ExtraActions[]>([]); // Explicitly type the state here
  useEffect(() => {
    const actions: ExtraActions[] = [];

    if (currentEntityKeyIndex === entityKeys.length - 1) {
      setExtraActions([]);
    }

    if (hasThreatsRemaining) {
      actions.push({
        label: "Skip",
        onClick: () => {
          setCurrentEntityKeyIndex((prevIndex) =>
            Math.min(prevIndex + 1, entityKeys.length - 1)
          );
        },
      });
    } else {
      actions.push({
        label: "Dismiss",
        iconName: "x-close",
        onClick: () => {
          if (props.onDismissal) {
            props.onDismissal(entityKeys[0].entityId, entityKeys[0].entityType);
          }
        },
      });
    }

    setExtraActions(actions);
  }, [
    currentEntityKeyIndex,
    entityKeys.length,
    hasThreatsRemaining,
    entityKeys,
    props,
  ]);

  if (!entity) {
    return null;
  }

  const handleSubmit = async () => {
    // @ts-ignore @andrewsy-opal TODO: bad, resolve the union type weirdness
    const mutationInput = convertRowsToMutationInput(rows, newAccessByRowId);

    const { data: mutationData } = await runMutation({
      variables: {
        // @ts-ignore @andrewsy-opal TODO: bad, resolve the union type weirdness
        input: mutationInput,
      },
    });

    // @ts-ignore @andrewsy-opal TODO: bad, resolve the union type weirdness
    handleMutationErrors(mutationData ?? {}, displaySuccessToast, setError);
  };

  const getBulkEditFn = (duration: number) => {
    return () => {
      setSelectedRows([]);
      setNewAccessByRowId((prev) =>
        selectedRows.reduce(
          (acc, rowId) => {
            acc[rowId] = duration;
            return acc;
          },
          { ...prev }
        )
      );
    };
  };

  const updateCounts = Object.entries(newAccessByRowId).reduce(
    (acc, [, duration]) => {
      if (duration === 0) {
        acc.revoke += 1;
      } else if (duration != null) {
        acc.jit += 1;
      }
      return acc;
    },
    { revoke: 0, jit: 0 }
  );

  return (
    <Modal
      title="Remediate Threats"
      isOpen
      onClose={onCloseAndResetIndex}
      maxWidth="lg"
    >
      <Modal.Body>
        {!queryLoading ? (
          <div
            className={sprinkles({
              fontFamily: "body",
            })}
          >
            {error && <Banner type="error" message={error} />}
            <div className={styles.fixedHeader}>
              <RemediationEntityLabel
                entity={entity}
                setError={setError}
                isOktaApp={props.isOktaApp}
              />
            </div>
            <RemediationSuggestionSection
              entityKey={entityKey}
              setNewAccess={setNewAccessByRowId}
              originalSuggestionsByRowId={originalSuggestionsByRowId}
              setOriginalSuggestionsByRowId={setOriginalSuggestionsByRowId}
              editMode={editMode}
              type={suggestionType}
            />
            <div
              className={sprinkles({
                flexDirection: "column",
                display: "flex",
                gap: "sm",
              })}
            >
              {
                <TableHeader
                  entityType={EntityType.User}
                  selectedNumRows={selectedRows.length}
                  totalNumRows={filteredRows.length}
                  customLabel={
                    <>
                      <Icon name="user" size="sm" color="black" />
                      <span
                        className={sprinkles({
                          marginRight: "sm",
                        })}
                      >
                        {rows.length} total access grants
                      </span>
                      {updateCounts.jit > 0 && (
                        <>
                          <Icon name="user" size="sm" color="orange500" />
                          <span
                            className={sprinkles({
                              color: "orange500",
                              marginRight: "sm",
                            })}
                          >
                            {updateCounts.jit} access grants to convert to JIT
                          </span>
                        </>
                      )}
                      {updateCounts.revoke > 0 && (
                        <>
                          <Icon name="user" size="sm" color="red600V3" />
                          <span
                            className={sprinkles({
                              color: "red600V3",
                              marginRight: "sm",
                            })}
                          >
                            {updateCounts.revoke} access grants to revoke
                          </span>
                        </>
                      )}
                    </>
                  }
                  bulkRightActions={
                    [
                      ...(suggestionType ==
                        RecommendationsSubscoreType.SuggestionUnusedAccess &&
                      entityType == RecommendationsEntityType.Group
                        ? [
                            {
                              label: "Remove from Group",
                              type: "mainSecondary",
                              onClick: getBulkEditFn(0),
                            },
                            {
                              label: editMode ? "Cancel" : "Edit",
                              type: "defaultSecondary",
                              onClick: () => {
                                setSelectedRows([]);
                                setEditMode(!editMode);
                              },
                              iconName: !editMode ? "edit" : undefined,
                            },
                          ]
                        : [
                            {
                              label: "Expire in 30 days",
                              type: "mainSecondary",
                              onClick: getBulkEditFn(
                                expirationValueToDurationInMinutes(
                                  ExpirationValue.OneMonth
                                )?.asMinutes() ?? 0
                              ),
                            },
                            {
                              label: "Expire in 7 days",
                              type: "mainSecondary",
                              onClick: getBulkEditFn(
                                expirationValueToDurationInMinutes(
                                  ExpirationValue.OneWeek
                                )?.asMinutes() ?? 0
                              ),
                            },
                            {
                              label: "Revoke Now",
                              type: "mainSecondary",
                              onClick: getBulkEditFn(0),
                            },
                            {
                              label: editMode ? "Cancel" : "Edit",
                              type: "defaultSecondary",
                              onClick: () => {
                                setSelectedRows([]);
                                setEditMode(!editMode);
                              },
                              iconName: !editMode ? "edit" : undefined,
                            },
                          ]),
                    ] as RightAction[]
                  }
                  defaultRightActions={[
                    {
                      label: editMode ? "Discard Changes" : "Customize",
                      type: "mainBorderless",
                      onClick: () => setEditMode(!editMode),
                      iconName: editMode ? "trash" : "edit",
                    },
                  ]}
                />
              }
              <div className={styles.searchInput}>
                <Input
                  leftIconName="search"
                  type="search"
                  style="search"
                  placeholder={filterText}
                  value={searchQuery}
                  onChange={setSearchQuery}
                />
              </div>
              <Table
                rows={filteredRows}
                getRowId={(row) => row.id}
                totalNumRows={filteredRows.length}
                getRowHighlighted={(row) => newAccessByRowId[row.id] != null}
                getRowHighlightColor={(row) =>
                  newAccessByRowId[row.id] != null
                    ? newAccessByRowId[row.id] === 0
                      ? "red"
                      : "orange"
                    : undefined
                }
                checkedRowIds={new Set(selectedRows)}
                onCheckedRowsChange={
                  editMode
                    ? (checkedRowIds, checked) => {
                        if (checked) {
                          setSelectedRows((prev) => [
                            ...prev,
                            ...checkedRowIds,
                          ]);
                        } else {
                          setSelectedRows((prev) =>
                            prev.filter((id) => !checkedRowIds.includes(id))
                          );
                        }
                      }
                    : undefined
                }
                onSelectAll={(checked) => {
                  if (checked) {
                    setSelectedRows(filteredRows.map((row) => row.id));
                  } else {
                    setSelectedRows([]);
                  }
                }}
                selectAllChecked={
                  selectedRows.length === filteredRows.length &&
                  filteredRows.length > 0
                }
                // @ts-ignore @andrewsy-opal TODO: bad, resolve the union type weirdness
                columns={columns}
                defaultSortBy="name"
                borderedCells={true}
              />
            </div>
          </div>
        ) : (
          <>
            {range(0, 5).map(() => (
              <Skeleton width="1232px" height="50px" />
            ))}
          </>
        )}
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Apply"
        onPrimaryButtonClick={
          !queryLoading
            ? () => {
                handleSubmit();
                if (hasThreatsRemaining) {
                  setCurrentEntityKeyIndex(currentEntityKeyIndex + 1);
                } else {
                  onCloseAndResetIndex();
                }
              }
            : undefined
        }
        primaryButtonLoading={mutationLoading}
        primaryButtonDisabled={
          Object.keys(newAccessByRowId).length === 0 ||
          !isAdmin ||
          searchQuery != ""
        }
        primaryButtonDisabledPopover={
          !isAdmin
            ? "You must be a super-admin to apply remediations"
            : Object.keys(newAccessByRowId).length === 0
            ? "You must have at least one access row selected to apply remediations"
            : searchQuery != ""
            ? "Can't apply remediation while filtering rows"
            : undefined
        }
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={onCloseAndResetIndex}
        leftComponent={
          !queryLoading &&
          hasThreatsRemaining && (
            <div
              className={sprinkles({
                display: "flex",
                gap: "sm",
                alignItems: "center",
              })}
            >
              <Icon name="circle" size="xs" color="red500V3" />
              <span>
                {entityKeys.length - currentEntityKeyIndex - 1} threats left
              </span>
            </div>
          )
        }
        extraActions={extraActions}
      />
    </Modal>
  );
};

export default EntityRemediationModal;
