import {
  OnCallSchedule,
  OnCallScheduleFragment,
  useOnCallSchedulesQuery,
} from "api/generated/graphql";
import OnCallScheduleLabel, {
  getOncallScheduleIcon,
} from "components/label/OnCallScheduleLabel";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { PillV3 } from "components/pills/PillsV3";
import { DataElementList, Icon, Select } from "components/ui";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import { logError } from "utils/logging";
import styles from "views/groups/oncall/GroupOnCallSchedulesModal.module.scss";

type OnCallScheduleRowProps = {
  schedule: OnCallScheduleFragment;
  onClick?: (scheduleId: string) => void;
};

const OnCallScheduleRow = (props: OnCallScheduleRowProps) => {
  return (
    <div className={styles.scheduleRow}>
      <div className={styles.scheduleRowContent}>
        <OnCallScheduleLabel
          scheduleName={props.schedule.name}
          thirdPartyProvider={props.schedule.thirdPartyProvider}
          showMissingFromRemoteWarning={!props.schedule.existsInRemote}
        />
        <div
          className={styles.scheduleDeleteButton}
          onClick={() => {
            props.onClick && props.onClick(props.schedule.remoteId);
          }}
        >
          <Icon name="x" size="xs" />
        </div>
      </div>
    </div>
  );
};

interface Props {
  onCallSchedules: OnCallSchedule[];
  onChange: (schedules: OnCallSchedule[]) => void;
  isV3?: boolean;
}

export const GroupOnCallSchedulesEdit = (props: Props) => {
  const { data, loading, error } = useOnCallSchedulesQuery();

  let availableSchedules: OnCallScheduleFragment[] = [];
  let dropdownErrorMessage = null;
  if (data) {
    switch (data.onCallSchedules.__typename) {
      case "OnCallSchedulesResult":
        availableSchedules = data.onCallSchedules.onCallSchedules;
        break;
      case "UserFacingError":
        dropdownErrorMessage = data.onCallSchedules.message;
        break;
      default:
        dropdownErrorMessage = `Error: failed to fetch available on call schedules`;
    }
  } else if (error) {
    dropdownErrorMessage = `Error: failed to fetch available on call schedules`;
    logError(error, `failed to fetch available on call schedules`);
  }

  const allSchedulesById: Record<string, OnCallScheduleFragment> = {};
  availableSchedules.forEach((schedule) => {
    allSchedulesById[schedule.remoteId] = schedule;
  });
  // In case there's some schedules on the group that are no longer on the remote.
  props.onCallSchedules?.forEach((schedule) => {
    if (!allSchedulesById[schedule.remoteId]) {
      allSchedulesById[schedule.remoteId] = schedule;
    }
  });

  const sortedOnCallSchedules = [...(props.onCallSchedules ?? [])];
  const selectedScheduleIds = sortedOnCallSchedules.map(
    (schedule) => schedule.remoteId
  );
  const remainingSchedules = availableSchedules.filter((schedule) => {
    return !selectedScheduleIds.includes(schedule.remoteId);
  });

  const schedulesList = props.isV3 ? (
    <div className={sprinkles({ marginTop: "md" })}>
      <DataElementList>
        {selectedScheduleIds.map((scheduleId) => {
          const schedule = allSchedulesById[scheduleId];
          let icon: IconData;
          if (schedule.thirdPartyProvider) {
            const externalIconUrl = getOncallScheduleIcon(
              schedule.thirdPartyProvider
            );
            icon = {
              type: "src",
              icon: externalIconUrl,
            };
          } else {
            icon = {
              type: "src",
              icon: "clipboard",
            };
          }
          return (
            <PillV3
              key={schedule.remoteId}
              keyText={schedule.name}
              icon={icon}
              pillColor="LightGreen"
              onRemove={() => {
                props.onChange(
                  props.onCallSchedules.filter(
                    (s) => s.remoteId !== schedule.remoteId
                  )
                );
              }}
            />
          );
        })}
      </DataElementList>
    </div>
  ) : (
    <div>
      {selectedScheduleIds.map((scheduleId) => {
        const schedule = allSchedulesById[scheduleId];
        return (
          <OnCallScheduleRow
            key={schedule.remoteId}
            schedule={schedule}
            onClick={(removeId) => {
              props.onChange(
                props.onCallSchedules.filter(
                  (schedule) => schedule.remoteId !== removeId
                )
              );
            }}
          />
        );
      })}
    </div>
  );

  return (
    <>
      {dropdownErrorMessage && (
        <ModalErrorMessage errorMessage={dropdownErrorMessage} />
      )}
      <Select
        options={remainingSchedules}
        selectOnly
        getOptionLabel={(option) => option.name}
        loading={loading}
        onChange={(newSchedule) => {
          if (!newSchedule) return;
          const newSelectedScheduleIds = [...selectedScheduleIds];
          if (!newSelectedScheduleIds.includes(newSchedule.remoteId)) {
            props.onChange([...props.onCallSchedules, newSchedule]);
          }
        }}
        getIcon={(option) => ({
          type: "src",
          icon: getOncallScheduleIcon(option.thirdPartyProvider),
        })}
        placeholder="Search for on-call schedules..."
      />
      {schedulesList}
    </>
  );
};
