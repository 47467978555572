export const validateHostname = (newHostname: string, label: string) => {
  // Verify that the hostname doesn't contain a trailing slash
  if (newHostname.endsWith("/")) {
    return `${label}  should not contain a trailing slash (/)`;
  }

  let tryUrl;

  // Verify that the hostname doesn't contain a protocol
  try {
    tryUrl = new URL(newHostname);
  } catch {
    // We expect an error for a hostname without protocol so we use an empty catch here
  }
  if (tryUrl) {
    return `${label} should not contain the protocol (${tryUrl.protocol}//)`;
  }

  // Verify that the hostname is a valid url when a protocol is added
  try {
    tryUrl = new URL(`https://${newHostname}`);
  } catch (_) {
    return `${label} is not a valid hostname`;
  }
  if (tryUrl.protocol === "https:") {
    return null;
  }
  return "The provided hostname is not a valid hostname";
};

export const validateUrl = (newUrl: string, label: string) => {
  // Verify that the url doesn't contain a trailing slash
  if (newUrl.endsWith("/")) {
    return `${label}  should not contain a trailing slash (/)`;
  }

  let tryUrl;

  // Verify that the url is a valid url when a protocol is added
  try {
    tryUrl = new URL(newUrl);
  } catch (_) {
    return `${label} is not a valid url`;
  }
  if (tryUrl.protocol) {
    return null;
  }
  return "The provided url is not a valid url";
};
