import { CustomDurationPicker } from "components/requests/CustomDurationPicker";
import { FormGroup, Select, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import pluralize from "pluralize";
import { useEffect, useReducer } from "react";

interface EscalationPeriod {
  duration: number;
  unit: "minute" | "hour";
}

const ESCALATION_PERIODS: EscalationPeriod[] = [
  { duration: 15, unit: "minute" },
  { duration: 30, unit: "minute" },
  { duration: 45, unit: "minute" },
  { duration: 1, unit: "hour" },
  { duration: 2, unit: "hour" },
  { duration: 4, unit: "hour" },
  { duration: 8, unit: "hour" },
];

type EscalationPeriodState =
  | {
      displayState: "disabled";
    }
  | {
      displayState: "preset";
      presetDuration: EscalationPeriod;
    }
  | {
      displayState: "custom";
      customDuration?: number;
    };

const getPresetDurationInMinutes = (escalationPeriod: EscalationPeriod) => {
  return moment
    .duration(escalationPeriod.duration, escalationPeriod.unit)
    .asMinutes();
};

const getEscalationPeriodText = (escalationPeriod: EscalationPeriod) => {
  const { duration, unit } = escalationPeriod;
  return `After ${duration} ${pluralize(unit, duration)}`;
};

const getDurationInMinutesFromState = (
  escalationPeriodState: EscalationPeriodState
) => {
  if (escalationPeriodState.displayState === "disabled") {
    return undefined;
  }
  if (escalationPeriodState.displayState === "preset") {
    return getPresetDurationInMinutes(escalationPeriodState.presetDuration);
  }
  return escalationPeriodState.customDuration;
};

const getEscalationPeriodStateFromMinutes = (
  accessRequestEscalationPeriodInMinutes?: number
): EscalationPeriodState => {
  if (accessRequestEscalationPeriodInMinutes === undefined) {
    return {
      displayState: "disabled",
    };
  }
  const matchedInitialEscalationPeriod = ESCALATION_PERIODS.find(
    (period) =>
      getPresetDurationInMinutes(period) ===
      accessRequestEscalationPeriodInMinutes
  );
  if (matchedInitialEscalationPeriod) {
    return {
      displayState: "preset",
      presetDuration: matchedInitialEscalationPeriod,
    };
  }
  return {
    displayState: "custom",
    customDuration: accessRequestEscalationPeriodInMinutes,
  };
};

interface EscalationPolicyFormProps {
  accessRequestEscalationPeriodInMinutes?: number | null;
  onChange: (accessRequestEscalationPeriodInMinutes: number | null) => void;
  includeDescription?: boolean;
}

export const EscalationPolicyForm = (props: EscalationPolicyFormProps) => {
  const { includeDescription = true } = props;
  const [escalationPeriodState, setEscalationPeriodState] = useReducer(
    (
      prev: EscalationPeriodState,
      updated: number | undefined | EscalationPeriod
    ): EscalationPeriodState => {
      let newState: EscalationPeriodState;
      let updatedDuration: number | undefined;
      if (typeof updated === "object") {
        newState = {
          displayState: "preset",
          presetDuration: updated,
        };
        updatedDuration = getDurationInMinutesFromState(newState);
      } else if (prev.displayState === "custom") {
        // if the display state is custom, we want to keep the custom duration
        newState = {
          ...prev,
          customDuration: updated,
        };
        updatedDuration = updated;
      } else {
        newState = getEscalationPeriodStateFromMinutes(updated);
        updatedDuration = updated;
      }
      props.onChange(updatedDuration ?? null);
      return newState;
    },
    { displayState: "disabled" }
  );

  useEffect(() => {
    setEscalationPeriodState(
      props.accessRequestEscalationPeriodInMinutes ?? undefined
    );
  }, [props.accessRequestEscalationPeriodInMinutes]);

  const { displayState } = escalationPeriodState;

  return (
    <>
      {includeDescription && (
        <p>
          By default, all reviewers are notified when an access request is
          created. To set a reviewer escalation policy, enable the option below.
          An escalation policy notifies one reviewer at a time, and notifies the
          next reviewer only when the request hasn't been actioned within a set
          amount of time.
        </p>
      )}
      <div className={sprinkles({ marginBottom: "md" })}>
        <Switch
          checked={displayState !== "disabled"}
          onChange={(checked) => {
            if (checked) {
              setEscalationPeriodState(ESCALATION_PERIODS[0]);
            } else {
              setEscalationPeriodState(undefined);
            }
          }}
          label="Enable reviewer escalation policy"
        />
      </div>
      {displayState !== "disabled" ? (
        <FormGroup
          label="Escalation time"
          rightLabelContent={
            <div
              className={sprinkles({
                display: "flex",
                justifyContent: "flex-end",
                color: "blue600",
                cursor: "pointer",
              })}
              onClick={() =>
                setEscalationPeriodState(
                  displayState === "preset" ? 0 : ESCALATION_PERIODS[0]
                )
              }
            >
              {displayState === "preset"
                ? "Custom duration"
                : "Preset duration"}
            </div>
          }
        >
          {displayState === "preset" ? (
            <Select
              options={Object.values(ESCALATION_PERIODS)}
              onChange={(newValue) => {
                if (!newValue) return;
                setEscalationPeriodState(newValue);
              }}
              getOptionLabel={(option) => getEscalationPeriodText(option)}
              value={escalationPeriodState.presetDuration}
            />
          ) : null}
          {displayState === "custom" ? (
            <CustomDurationPicker
              durationInMinutes={escalationPeriodState.customDuration ?? 0}
              setDurationInMinutes={setEscalationPeriodState}
            />
          ) : null}
        </FormGroup>
      ) : null}
    </>
  );
};
