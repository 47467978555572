import { IdpConnectionType } from "api/generated/graphql";
import { Checkbox, Divider, Link } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import {
  CONNECTIONS_LIST,
  IdpConnectionInfo,
} from "views/idp/create/BrowseIdpServices";

import { FeatureFlag, useFeatureFlag } from "../../../utils/feature_flags";
import { SetupContext } from "../SetupContext";
import * as styles from "./IdpStep.css";

const IdpSelect = () => {
  const { connectionType, setConnectionType } = useContext(SetupContext);
  const isWorkdayEnabled = useFeatureFlag(
    FeatureFlag.EnableWorkdayConnectionType
  );

  return (
    <div className={sprinkles({ display: "flex", flexDirection: "column" })}>
      <div className={styles.header}>Select an identity provider</div>
      <div className={styles.content}>
        <div className={styles.description}>
          Thanks for choosing Opal. In this flow, you'll connect Opal to an
          identity provider (IdP) and import the following data: Users,
          attributes, groups, and the resources they'll be able to request and
          access. {"\n\n"}We recommend importing everything from your IdP into
          Opal. Once that's done, you'll be able to make apps and resources
          visible and requestable in your organization. {"\n\n"}If you need to
          pause mid-setup, you will be able to save your progress and return
          when you're ready.
        </div>
        <Divider margin="lg" />
        <div
          className={sprinkles({ fontWeight: "semibold", marginBottom: "md" })}
        >
          Select one of the supported identity providers below to get started:
        </div>
        <p>
          If you would like to use an existing app as your IdP, you can do so in{" "}
          <Link url="/settings#idp-and-hr">Settings.</Link>
        </p>
        <div
          className={sprinkles({ display: "flex", gap: "lg", marginTop: "lg" })}
        >
          {CONNECTIONS_LIST.filter(
            (info) =>
              info.idpConnectionType !== IdpConnectionType.Workday ||
              isWorkdayEnabled
          ).map((info) => (
            <IdpTile
              key={info.connectionType}
              info={info}
              selected={connectionType === info.idpConnectionType}
              onClick={() => setConnectionType(info.idpConnectionType)}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

interface TileProps {
  info: IdpConnectionInfo;
  selected: boolean;
  onClick: () => void;
}

const IdpTile = (props: TileProps) => {
  return (
    <div
      className={styles.tile({ selected: props.selected })}
      onClick={props.onClick}
    >
      <div className={styles.checkbox}>
        <Checkbox checked={props.selected} size="sm" onChange={props.onClick} />
      </div>
      <div className={styles.logoContainer}>
        <img alt={props.info.name} src={props.info.logo} />
      </div>
      <div
        className={sprinkles({
          fontSize: "bodyLg",
          fontWeight: "medium",
        })}
      >
        {props.info.name}
      </div>
      <div className={sprinkles({ color: "gray700", fontSize: "bodySm" })}>
        {props.info.category}
      </div>
    </div>
  );
};

export default IdpSelect;
