import {
  AccessReviewItemsSortByField,
  AccessReviewReviewerFragment,
  SortDirection,
  useAccessReviewReviewersQuery,
} from "api/generated/graphql";
import { Column, ColumnContainer } from "components/column/Column";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeader";
import ColumnListItem, {
  ColumnListItemsSkeleton,
} from "components/column/ColumnListItem";
import ColumnListScroller from "components/column/ColumnListScroller";
import { ColumnSearchAndSort } from "components/column/ColumnSearchAndSort";
import { Divider, Icon } from "components/ui";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { logError, logWarning } from "utils/logging";
import { filterSearchResults } from "utils/search/filter";
import { by } from "utils/search/sort";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import AccessReviewReviewer from "./AccessReviewReviewer";

const SORT_OPTIONS = [
  {
    label: "First Name (A-Z)",
    value: {
      field: AccessReviewItemsSortByField.Name,
      direction: SortDirection.Asc,
    },
  },
  {
    label: "First Name (Z-A)",
    value: {
      field: AccessReviewItemsSortByField.Name,
      direction: SortDirection.Desc,
    },
  },
];

const AccessReviewReviewers = () => {
  const history = useHistory();
  const { accessReviewId, reviewerId } = useParams<Record<string, string>>();

  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);

  const { data, error, loading } = useAccessReviewReviewersQuery({
    variables: {
      accessReviewId,
    },
  });

  let reviewers: AccessReviewReviewerFragment[] = [];
  let accessReviewName = "";
  let hasError = false;
  if (data) {
    switch (data.accessReview.__typename) {
      case "AccessReviewResult":
        reviewers = data.accessReview.accessReview.reviewers || [];
        accessReviewName = data.accessReview.accessReview.name;
        break;
      default:
        hasError = true;
        logWarning(new Error("error: access review schedule not found"));
    }
  } else if (error) {
    hasError = true;
    logError(error, `failed to retrieve access review schedule`);
  }

  if (loading) {
    return (
      <ColumnContainer>
        <Column>
          <ColumnHeaderSkeleton />
          <Divider />
          <ColumnListItemsSkeleton />
        </Column>
      </ColumnContainer>
    );
  }

  if (hasError) {
    return (
      <ColumnContainer>
        <Column isContent>
          <UnexpectedErrorPage error={error} />
        </Column>
      </ColumnContainer>
    );
  }

  reviewers = filterSearchResults(
    reviewers,
    searchQuery,
    (reviewer: AccessReviewReviewerFragment) =>
      reviewer.user ? [reviewer.user.fullName, reviewer.user.email] : []
  );
  reviewers = reviewers
    .slice()
    .sort(by((reviewer) => reviewer.user?.fullName || ""));
  if (sortBy.value.direction === SortDirection.Desc) {
    reviewers.reverse();
  }

  const renderItemRow = (index: number) => {
    const item = reviewers[index];
    if (!item) return <></>;

    if (!item.user) {
      return <></>;
    }

    const { completedItemsCount: completed, totalItemsCount: total } = item;

    return (
      <ColumnListItem
        key={item.userId}
        label={item.user.fullName}
        icon={{ type: "src", icon: item.user.avatarUrl, style: "rounded" }}
        sublabel={`${completed}/${total} completed`}
        rightContent={
          completed === total ? (
            <Icon name="check-square" color="green600" size="xs" />
          ) : undefined
        }
        onClick={() =>
          history.push(
            `/access-reviews/${accessReviewId}/reviewers/${item.userId}`
          )
        }
        selected={reviewerId === item.userId}
      />
    );
  };

  return (
    <ColumnContainer>
      <Column>
        <ColumnHeader
          title="Reviewers"
          icon={{ type: "name", icon: "user-check" }}
          breadcrumbs={[
            { name: "Access Reviews", to: "/access-reviews" },
            {
              name: "Ongoing",
              to: "/access-reviews?category=ongoing",
            },
            {
              name: accessReviewName,
              to: `/access-reviews/${accessReviewId}`,
            },
          ]}
        />
        <Divider />
        <ColumnSearchAndSort
          setSearchQuery={setSearchQuery}
          setSortBy={setSortBy}
          sortOptions={SORT_OPTIONS}
          sortBy={sortBy}
          placeholder="Search for reviewer"
          trackName="access-review-reviewer"
        />
        <ColumnListScroller
          renderRow={renderItemRow}
          numRows={reviewers.length}
        />
      </Column>
      {reviewerId && <AccessReviewReviewer />}
    </ColumnContainer>
  );
};

export default AccessReviewReviewers;
