import {
  GroupAccessLevel,
  Maybe,
  ResourceAccessLevel,
  ServiceType,
} from "api/generated/graphql";
import Label from "components/label/Label";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import roleStyles from "components/modals/ResourceIndividualRoleModal.module.scss";
import dropdownModalStyles from "components/modals/update/DropdownModal.module.scss";
import { SelectType } from "components/modals/update/SelectItemsModal";
import { Select } from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import _ from "lodash";
import { useState } from "react";
import * as Icons from "react-feather";
import { ExpirationValue } from "views/requests/utils";

import { serviceTypeHasMaxOneRole } from "../../utils/directory/resources";

export type Role = ResourceAccessLevel | GroupAccessLevel;

export type ResourceIndividualRoleAutocompleteData = {
  existingDirectRolesByEntityId?: Record<string, Role[]>;
  updatedRolesByEntityId?: Record<string, Role[]>;
  setUpdatedRolesByEntityId?: (
    updatedRolesByEntityId: Record<string, Role[]>
  ) => void;
  updatedAccessDurationsByEntityId?: Record<string, ExpirationValue>;
  setUpdatedAccessDurationsByEntityId?: (
    updatedAccessDurationsByEntityId: Record<string, ExpirationValue>
  ) => void;
  rolesLoading?: boolean;
  serviceType?: ServiceType | undefined;
};

export type ResourceIndividualRoleCheckedData = {
  entityId: string;
  loading?: boolean;
  errorMessage?: Maybe<string>;
  submitDisabled?: boolean;
  selectType: SelectType;
  rolesForResource: Role[];
};

export type RoleData = {
  roles?: Role[];
  autocompleteData: ResourceIndividualRoleAutocompleteData;
  checkedDataItems: ResourceIndividualRoleCheckedData[];
};

type ResourceIndividualRoleAutocompleteProps = ResourceIndividualRoleAutocompleteData &
  ResourceIndividualRoleCheckedData & {
    isImpersonationResource?: boolean;
  };

export const ResourceIndividualRoleAutocomplete = (
  props: ResourceIndividualRoleAutocompleteProps
) => {
  const autocompleteStyles = { ...dropdownModalStyles };

  let existingSelectedRoles: Role[] = [];
  if (
    props.updatedRolesByEntityId &&
    props.updatedRolesByEntityId[props.entityId]
  ) {
    existingSelectedRoles = props.updatedRolesByEntityId[props.entityId];
  }
  // these are the roles the user has selected in the modal.
  const [selectedRoles, setSelectedRoles] = useState<Role[]>(
    existingSelectedRoles
  );

  let existingRolesForEntity: Role[] = [];
  if (
    props.existingDirectRolesByEntityId &&
    props.existingDirectRolesByEntityId[props.entityId]
  ) {
    existingRolesForEntity = [
      ...props.existingDirectRolesByEntityId[props.entityId],
    ];
  }

  const entityId = props.entityId;

  const isRemove =
    props.selectType === SelectType.Delete ||
    props.selectType === SelectType.Remove;

  let availableRoles: Role[] = [];

  // if we are adding users, then only display roles they don't have, otherwise display roles they do have
  // we should only do this calculation in the cases where there are more than one level of access
  if (props.rolesForResource.length > 1) {
    if (props.selectType === SelectType.Add) {
      availableRoles = props.rolesForResource.filter((Role) => {
        return !_.some(
          existingRolesForEntity,
          (existingRole) =>
            existingRole.accessLevelRemoteId === Role.accessLevelRemoteId
        );
      });
    } else if (isRemove && props.existingDirectRolesByEntityId) {
      const existingDirectRoles = props.existingDirectRolesByEntityId[entityId];
      if (existingDirectRoles) {
        existingDirectRoles.forEach((role) => {
          availableRoles.push(role);
        });
      }
    }
  } else if (props.rolesForResource.length === 1) {
    availableRoles.push(props.rolesForResource[0]);
  }

  availableRoles = availableRoles.filter(
    (role) =>
      !_.some(
        selectedRoles,
        (selectedRole) =>
          selectedRole.accessLevelRemoteId === role.accessLevelRemoteId
      )
  );

  const [expiration, setExpiration] = useState(
    props.updatedAccessDurationsByEntityId
      ? props.updatedAccessDurationsByEntityId[props.entityId] ||
          ExpirationValue.Indefinite
      : ExpirationValue.Indefinite
  );
  const handleSelectExpirationChange = (expirationVal?: ExpirationValue) => {
    if (expirationVal) {
      setExpiration(expirationVal);
      if (
        props.updatedAccessDurationsByEntityId &&
        props.setUpdatedAccessDurationsByEntityId
      ) {
        props.setUpdatedAccessDurationsByEntityId({
          ...props.updatedAccessDurationsByEntityId,
          [props.entityId]: expirationVal,
        });
      }
    }
  };

  const loading = props.loading || props.rolesLoading;

  // if neither dropdown will be shown, then don't bother rendering anything else
  if (
    !loading &&
    (!props.updatedAccessDurationsByEntityId ||
      !props.setUpdatedAccessDurationsByEntityId) &&
    props.rolesForResource.length < 1
  ) {
    return null;
  }

  return (
    <div style={{ padding: "20px 12px 16px" }}>
      {(loading || props.rolesForResource.length >= 1) && (
        <div className={autocompleteStyles.autocompleteContainer}>
          {props.rolesForResource.length > 1 ? (
            <div className={roleStyles.roleSelect}>
              <Select
                selectOnly
                onChange={(newRole?: Role) => {
                  // add item to selected list
                  if (
                    newRole &&
                    props.updatedRolesByEntityId &&
                    props.setUpdatedRolesByEntityId
                  ) {
                    if (serviceTypeHasMaxOneRole(props.serviceType)) {
                      props.setUpdatedRolesByEntityId({
                        ...props.updatedRolesByEntityId,
                        [props.entityId]: [newRole],
                      });
                      setSelectedRoles([newRole]);
                    } else {
                      const updated = _.uniqBy(
                        [...selectedRoles, newRole],
                        "accessLevelRemoteId"
                      );
                      const entityUpdatedRoles =
                        props.updatedRolesByEntityId[props.entityId] || [];
                      props.setUpdatedRolesByEntityId({
                        ...props.updatedRolesByEntityId,
                        [props.entityId]: _.uniqBy(
                          [...entityUpdatedRoles, ...updated],
                          "accessLevelRemoteId"
                        ),
                      });
                      setSelectedRoles(updated);
                    }
                  }
                }}
                getIcon={(option) => {
                  switch (option.__typename) {
                    case "ResourceAccessLevel": {
                      return {
                        type: "src",
                        style: "rounded",
                        icon: props.isImpersonationResource
                          ? option.accessLevelMetadata?.avatarUrl ||
                            defaultAvatarURL
                          : undefined,
                        fallbackIcon: props.isImpersonationResource
                          ? defaultAvatarURL
                          : undefined,
                      };
                    }
                  }
                  return {
                    type: "src",
                    style: "rounded",
                  };
                }}
                options={availableRoles}
                loading={props.loading || props.rolesLoading}
                getOptionLabel={(role) => role.accessLevelName || "Default"}
                placeholder="Search for roles"
              />
            </div>
          ) : null}
          {!!selectedRoles.length && (
            <div className={roleStyles.content}>
              {Array.from(selectedRoles)
                .sort((a, b) => {
                  if (a && b) {
                    return a.accessLevelName.localeCompare(b.accessLevelName);
                  }
                  return 0;
                })
                .map((role) => {
                  return (
                    <RoleRow
                      showRemoveButton={props.rolesForResource.length > 1}
                      key={role.accessLevelRemoteId}
                      role={role}
                      onRemove={(removedRole) => {
                        if (
                          !props.updatedRolesByEntityId ||
                          !props.setUpdatedRolesByEntityId
                        )
                          return;
                        const updated = selectedRoles.filter(
                          (role) =>
                            role.accessLevelRemoteId !==
                            removedRole.accessLevelRemoteId
                        );
                        const entityUpdatedRoles =
                          props.updatedRolesByEntityId[props.entityId].filter(
                            (role) =>
                              role.accessLevelRemoteId !==
                              removedRole.accessLevelRemoteId
                          ) || [];

                        if (entityUpdatedRoles.length === 0) {
                          delete props.updatedRolesByEntityId[props.entityId];
                        } else {
                          props.updatedRolesByEntityId[
                            props.entityId
                          ] = entityUpdatedRoles;
                        }
                        props.setUpdatedRolesByEntityId({
                          ...props.updatedRolesByEntityId,
                        });
                        setSelectedRoles(updated);
                      }}
                    />
                  );
                })}
            </div>
          )}
          {props.errorMessage && (
            <ModalErrorMessage errorMessage={props.errorMessage} />
          )}
        </div>
      )}
      {props.updatedAccessDurationsByEntityId &&
        props.setUpdatedAccessDurationsByEntityId && (
          <div className={autocompleteStyles.accessDurationContainer}>
            <Select
              options={Object.values(ExpirationValue)}
              value={expiration}
              onChange={handleSelectExpirationChange}
              getOptionLabel={(expirationVal) =>
                expirationVal === ExpirationValue.Indefinite
                  ? "Indefinite access"
                  : `Access for ${expirationVal}`
              }
            />
          </div>
        )}
    </div>
  );
};

type RoleRowProps = {
  role: ResourceAccessLevel | GroupAccessLevel;
  onRemove: (role: ResourceAccessLevel | GroupAccessLevel) => void;
  showRemoveButton?: boolean;
};

const RoleRow = (props: RoleRowProps) => {
  return (
    <div className={roleStyles.itemRow}>
      <div className={roleStyles.itemRowContent}>
        <Label text={props.role.accessLevelName || "Default"} maxChars={45} />
        {props.showRemoveButton ? (
          <div
            className={roleStyles.itemButton}
            onClick={() => {
              props.onRemove(props.role);
            }}
          >
            <Icons.X strokeWidth={4} />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default ResourceIndividualRoleAutocomplete;
