import {
  ConnectionPreviewLargeFragment,
  useConnectionPreviewQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeader";
import { Divider, FormGroup, RadioGroup } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { useMountEffect } from "utils/hooks";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import ResourceFromScratch from "./components/ResourceFromScratch";
import ResourceUsersUploadCSV from "./components/ResourceUsersUploadCSV";
import {
  getSingleImportableResourceType,
  hasCreationMethodCards,
  ResourceCreationInfo,
} from "./utils";

const creationMethods = [
  { label: "Importing from CSV", isCsvImport: true },
  { label: "Creating from scratch", isCsvImport: false },
];

const CreateResourceColumn = () => {
  const { appId } = useParams<Record<string, string>>();
  const history = useHistory();
  const logEvent = useLogEvent();

  useMountEffect(() => {
    logEvent({ name: "apps_create_resource_start" });
  });

  const { data: connectionData, loading, error } = useConnectionPreviewQuery({
    variables: {
      connectionId: appId,
    },
    skip: !appId,
  });

  let connection: ConnectionPreviewLargeFragment | undefined = undefined;
  switch (connectionData?.connection.__typename) {
    case "ConnectionResult":
      connection = connectionData.connection.connection;
  }

  if (loading) {
    return (
      <Column isContent maxWidth="md">
        <ColumnHeaderSkeleton />
        <Divider />
        <ColumnContentSkeleton />
      </Column>
    );
  }

  if (error || !connection) {
    return (
      <Column isContent maxWidth="md">
        <ColumnHeader
          title="Create Resource"
          onClose={() => history.goBack()}
          icon={{ type: "name", icon: "plus" }}
        />
        <Divider />
        <UnexpectedErrorPage />
      </Column>
    );
  }

  return (
    <Column isContent maxWidth="md">
      <ColumnHeader
        title="Create Resource"
        onClose={() => history.goBack()}
        icon={{ type: "name", icon: "plus" }}
      />
      <Divider />
      <ColumnContent>
        <div className={sprinkles({ margin: "xs" })}>
          <CreateResourceBody connection={connection} />
        </div>
      </ColumnContent>
    </Column>
  );
};

interface BodyProps {
  connection: ConnectionPreviewLargeFragment;
}

const CreateResourceBody = (props: BodyProps) => {
  const { connection } = props;

  const resourceType = getSingleImportableResourceType(
    connection.connectionType
  );

  const [isCSVImport, setIsCSVImport] = useState(false);
  const [creationInfo, setCreationInfo] = useState<ResourceCreationInfo>({
    connection,
    resourceType,
  });

  const hasCreationOptions = hasCreationMethodCards(connection.connectionType);

  return (
    <>
      {hasCreationOptions && (
        <>
          <div className={sprinkles({ marginBottom: "sm" })}>
            <FormGroup label="Create resource by:">
              <RadioGroup
                options={creationMethods}
                value={creationMethods.find(
                  (method) => method.isCsvImport === isCSVImport
                )}
                getOptionKey={(option) => option.isCsvImport}
                getOptionLabel={(option) => option.label}
                onSelectValue={(option) => setIsCSVImport(option.isCsvImport)}
              />
            </FormGroup>
          </div>
          <Divider />
        </>
      )}
      {isCSVImport && <ResourceUsersUploadCSV connectionId={connection.id} />}
      {!isCSVImport && (
        <ResourceFromScratch
          creationInfo={creationInfo}
          setCreationInfo={setCreationInfo}
        />
      )}
    </>
  );
};

export default CreateResourceColumn;
