import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from "chart.js";
import _ from "lodash";
import { Line } from "react-chartjs-2";

import {
  ALERT_COLORS,
  LIMIT_ALERT_COLOR,
  LIMIT_NORMAL_COLOR,
  NORMAL_COLORS,
} from "./common";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const NUM_DATA_POINTS = 12;
const MONTHS = ["NOV", "DEC", "JAN", "FEB"];

const options: PropsFor<typeof Line>["options"] = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      // Don't show tooltip for line that represents limit.
      filter: ({ dataset }) => {
        return dataset.label !== "Limit";
      },
      callbacks: {
        title: () => "",
      },
    },
  },
  // Show tooltip on hover over any part of the graph to the nearest point.
  interaction: {
    mode: "nearest",
    intersect: false,
    axis: "xy",
  },
  scales: {
    y: {
      position: "right",
      border: {
        display: false,
      },
      grid: {
        tickLength: 0,
      },
      ticks: {
        padding: 10,
        color: "#B4B4B4",
        font: {
          family: "IBM Plex Sans, sans",
        },
      },
      max: 300,
      min: 0,
    },
    x: {
      grid: {
        // Don't draw vertical lines
        drawOnChartArea: false,
        tickLength: 0,
      },
      border: {
        color: "#E9E9E9",
        width: 2,
      },
      ticks: {
        // For demo purposes, hard coded to show 4 months across 12 data points.
        callback: (_, index) => {
          if (index % 3 === 1) {
            return MONTHS[(index - 1) / 3];
          }
        },
        padding: 10,
        color: "#B4B4B4",
        font: {
          family: "IBM Plex Sans, sans",
          weight: "500",
        },
      },
    },
  },
};

export interface GraphData {
  data: number[];
  label: string;
}

interface Props {
  datasets: GraphData[];
  limit?: number;
  alerting?: boolean;
}

const Graph = (props: Props) => {
  const labels = _.times(NUM_DATA_POINTS);

  if (props.datasets.length === 0) {
    return null;
  }

  const datasets = [];
  if (props.limit) {
    datasets.push({
      data: _.times(NUM_DATA_POINTS, () => props.limit),
      borderColor: props.alerting ? LIMIT_ALERT_COLOR : LIMIT_NORMAL_COLOR,
      backgroundColor: props.alerting ? LIMIT_ALERT_COLOR : LIMIT_NORMAL_COLOR,
      borderDash: [8],
      pointRadius: 0,
      pointHoverRadius: 0,
      label: "Limit",
    });
  }
  props.datasets.forEach((ds, index) => {
    const color = props.alerting ? ALERT_COLORS[index] : NORMAL_COLORS[index];
    datasets.push({
      data: ds.data,
      borderColor: color,
      backgroundColor: color,
      pointRadius: 0,
      label: ds.label,
    });
  });

  const data: PropsFor<typeof Line>["data"] = {
    labels,
    datasets,
  };
  return <Line data={data} options={options} />;
};

export default Graph;
