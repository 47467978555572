import { PillV3 } from "components/pills/PillsV3";
import { DataElementList, Icon, Select } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
  minutesToExpirationValue,
} from "views/requests/utils";

import * as styles from "./ActionSidebarSection.css";

interface Props {
  accessDurationMin?: number;
  groups: string[];
  onChange: (durationMin?: number) => void;
}

const ConvertGroupAccessSection = (props: Props) => {
  const [expanded, setExpanded] = useState(false);
  if (props.groups.length === 0) {
    return null;
  }

  return (
    <div className={styles.actionContainer}>
      <div
        className={styles.actionHeader({ expanded })}
        onClick={() => setExpanded((prev) => !prev)}
      >
        <Icon name="alarm-clock" size="xs" />
        <span className={styles.actionTitle}>Convert to time-based access</span>
        <Icon name={expanded ? "chevron-up" : "chevron-down"} size="xs" />
      </div>
      {expanded ? (
        <div className={styles.actionContent}>
          <DataElementList>
            {props.groups.map((group) => (
              <PillV3
                key={group}
                keyText={group}
                pillColor="Teal"
                icon={{
                  type: "name",
                  icon: "users",
                }}
              />
            ))}
          </DataElementList>
          <div className={sprinkles({ marginTop: "md" })}>
            <Select
              options={Object.values(ExpirationValue).filter(
                (val) => val !== ExpirationValue.Indefinite
              )}
              value={
                props.accessDurationMin == null
                  ? undefined
                  : minutesToExpirationValue(props.accessDurationMin)
              }
              getOptionLabel={(opt) => opt}
              onChange={(opt) => {
                if (opt == null) {
                  props.onChange();
                } else {
                  const durationMin = expirationValueToDurationInMinutes(
                    opt
                  )?.asMinutes();
                  props.onChange(durationMin);
                }
              }}
              clearable
            />
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default ConvertGroupAccessSection;
