import labelStyles from "components/label/AccessPathLabel.module.scss";
import React, { ReactElement } from "react";
import * as Icons from "react-feather";

type AccessPathLabelProps = {
  accessLabels: ReactElement[];
  maxWidth?: string;
};

export const AccessPathLabel = (props: AccessPathLabelProps) => {
  return (
    <span
      className={labelStyles.accessPathLabel}
      style={{ maxWidth: props.maxWidth || "400px" }}
    >
      {props.accessLabels.map((accessLabel, i) => {
        return (
          <AccessLabelElement
            key={i}
            accessLabel={accessLabel}
            isFirst={i === 0}
            isLast={i === props.accessLabels.length - 1}
          />
        );
      })}
    </span>
  );
};

type AccessLabelElementProps = {
  accessLabel: ReactElement;
  isFirst: boolean;
  isLast: boolean;
};

const AccessLabelElement = (props: AccessLabelElementProps) => {
  return (
    <span className={labelStyles.accessLabelElement}>
      <span className={labelStyles.accessLabelElement}>
        {props.accessLabel}
      </span>
      {!props.isLast && (
        <span className={labelStyles.accessLabelElementArrow}>
          <Icons.ArrowRight strokeWidth={2} size={16} />
        </span>
      )}
    </span>
  );
};

export default AccessPathLabel;
