import {
  ConnectionType,
  EntityType,
  GroupType,
  ResourceType,
  ThirdPartyProvider,
  Visibility,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { ClickToCopyButton } from "components/buttons/ClickToCopyButton";
import ResourceTicketPropagationRow from "components/forms/rows/ResourceTicketPropagationRow";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import { RiskSensitivityPill } from "components/risksensitivity/RiskSensitivityPill";
import { Banner, ButtonV3, Checkbox, FormRow, Label } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import { useHistory, useParams } from "react-router";
import { isSessionableType } from "utils/directory/connections";
import { resourceTypeCanBeAccessed } from "utils/directory/resources";
import { isPropertyValue } from "utils/enums";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { AppsContext } from "views/apps/AppsContext";
import GroupBindingWarningBanner from "views/group_bindings/banners/GroupBindingWarningBanner";
import OrgContext from "views/settings/OrgContext";

import { FormMode, ResourceConfig, ResourceRequestConfig } from "./common";
import * as styles from "./ResourcesConfigFormV3.css";
import AdminRow from "./rows/AdminRow";
import AppRow from "./rows/AppRow";
import BreakGlassUsersRow from "./rows/BreakGlassUsersRow";
import ConditionalConfigs from "./rows/conditional_config/ConditionalConfigs";
import ConfigurationTemplateRow from "./rows/ConfigurationTemplateRow";
import DescriptionRow from "./rows/DescriptionRow";
import OnCallSchedulesRow from "./rows/OnCallSchedulesRow";
import RequireMfaToApproveRow from "./rows/RequireMfaToApproveRow";
import RequireMfaToConnectRow from "./rows/RequireMfaToConnectRow";
import SlackChannelsRowV3 from "./rows/SlackChannelsRowV3";
import TagsRowV3 from "./rows/TagsRowV3";
import VisibilityRow from "./rows/VisibilityRow";

interface Props {
  mode: FormMode;
  config: Partial<ResourceConfig>;
  onChange: (config: Partial<ResourceConfig>) => void;
  isViewingAsNonAdmin?: boolean;
  isTemplate?: boolean;
  hasBulkSelectedItemsWithConfigTemplate?: boolean;
}

/**
 * ResourcesConfigFormV3 renders a form in either view or edit mode
 * for a given resource/group or collection of resources/groups.
 * When in edit mode, this component will call a callback for
 * changes made to any form row.
 *
 * This component also handles the logic for deciding whether
 * a specific form row should be visible or not, depending on
 * the selected resources/groups and other org settings and integrations.
 */
const ResourcesConfigFormV3 = (props: Props) => {
  const hasConfigurationTemplates = useFeatureFlag(
    FeatureFlag.ConfigurationTemplates
  );
  const hasGroupBindings = useFeatureFlag(FeatureFlag.GroupBindings);
  const hasTicketPropagation = useFeatureFlag(
    FeatureFlag.ResourceTicketPropagation
  );
  const { authState } = useContext(AuthContext);
  const history = useHistory();
  const { resourceId, groupId } = useParams<Record<string, string>>();
  const {
    mode,
    isViewingAsNonAdmin,
    isTemplate,
    hasBulkSelectedItemsWithConfigTemplate,
  } = props;
  const {
    name,
    entityId,
    remoteName,
    remoteId,
    entityType,
    connectionId,
    connectionType,
    connectionName,
    connectionVisibility,
    connectionVisibilityGroups,
    description,
    adminOwner,
    visibility,
    visibilityGroups,
    parentResourceId,
    parentResourceName,
    parentResourceType,
    parentResourceVisibility,
    parentResourceVisibilityGroups,
    messageChannels,
    requestConfigs,
    requireMfaToApprove,
    requireMfaToConnect,
    onCallSchedules,
    breakGlassUsers,
    tagIds,
    commonMetadata,
    configurationTemplate,
    useParentConfig,
    forkConfigurationTemplates,
    groupBinding,
    ticketPropagation,
    riskSensitivity,
  } = props.config;

  const { orgState } = useContext(OrgContext);
  const { selectedItems, bulkMode, selectedRemoteItems } = useContext(
    AppsContext
  );
  const allowUseParentConfig = useFeatureFlag(FeatureFlag.UseParentConfig);
  const canViewRiskSensitivity = useFeatureFlag(FeatureFlag.RiskSensitivity);

  const isSlackIntegrationEnabled =
    orgState.orgThirdPartyIntegrations?.some(
      (integration) =>
        integration.thirdPartyProvider === ThirdPartyProvider.Slack
    ) ?? false;

  let resourceType: ResourceType | undefined;
  let groupType: GroupType | undefined;
  if (isPropertyValue(ResourceType, entityType)) {
    resourceType = entityType;
  } else if (isPropertyValue(GroupType, entityType)) {
    groupType = entityType;
  }

  let isRequestable = true;
  let allSessionable = true;
  let allGroups = true;
  let allResources = true;
  let descriptionEditable = true;
  let allChildResources = true;
  // We set this as default to true because if there are no group bindings
  // We assume that the group is a source of truth
  let allGroupBindingSources = true;

  if (bulkMode === "edit") {
    selectedItems.forEach((item) => {
      if ("resourceType" in item) {
        allGroups = false;
        if (item.resourceType === ResourceType.OpalRole) {
          descriptionEditable = false;
        }
        if (!item.parentResource) {
          allChildResources = false;
        }
        if (!resourceTypeCanBeAccessed(item.resourceType)) {
          isRequestable = false;
        }
      }
      if ("groupType" in item) {
        allResources = false;
        allChildResources = false;
        if (
          hasGroupBindings &&
          item.groupBinding &&
          item.groupBinding.sourceGroupId !== item.id
        ) {
          allGroupBindingSources = false;
        }
        if (!resourceTypeCanBeAccessed(item.groupType)) {
          isRequestable = false;
        }
      }
      if (
        item.connection?.connectionType &&
        !isSessionableType(item.connection?.connectionType)
      ) {
        allSessionable = false;
      }
    });
  } else if (selectedRemoteItems.length > 0) {
    selectedRemoteItems.forEach((item) => {
      if (item.resourceType) {
        allGroups = false;

        if (!resourceTypeCanBeAccessed(item.resourceType)) {
          isRequestable = false;
        }
      }

      if (!isSessionableType(item.connectionType)) {
        allSessionable = false;
      }

      if (item.groupType) {
        allResources = false;

        if (!resourceTypeCanBeAccessed(item.groupType)) {
          isRequestable = false;
        }
      }
    });
  } else {
    if (resourceType && !resourceTypeCanBeAccessed(resourceType)) {
      isRequestable = false;
    }
    if (groupType && !resourceTypeCanBeAccessed(groupType)) {
      isRequestable = false;
    }

    allSessionable = Boolean(
      connectionType && isSessionableType(connectionType)
    );
    allGroups = groupType != null;
    allResources = resourceType != null;
    allChildResources = allResources && Boolean(parentResourceId);
    if (hasGroupBindings) {
      allGroupBindingSources = groupBinding
        ? groupBinding.sourceGroupId === entityId
        : true;
    }
  }

  const showMessageChannels =
    (isTemplate && isSlackIntegrationEnabled) ||
    (isRequestable && isSlackIntegrationEnabled && !isViewingAsNonAdmin);
  const canBeAccessed = isRequestable && allGroupBindingSources;

  const isOktaResource = connectionType === ConnectionType.OktaDirectory;

  const handleChange = (key: keyof ResourceConfig) => (
    val: ResourceConfig[keyof ResourceConfig]
  ) => {
    if (props.onChange) {
      props.onChange({
        ...props.config,
        [key]: val,
      });
    }
  };

  const handleRequestConfigsChange = (
    requestConfigs: ResourceRequestConfig[]
  ) => {
    if (props.onChange) {
      props.onChange({
        ...props.config,
        requestConfigs,
      });
    }
  };

  const handleConfigTemplateAndUseParentConfig = (
    configurationTemplateName?: string | null,
    configurationTemplateId?: string | null,
    useParent?: boolean
  ) => {
    if (props.onChange) {
      const templateDict =
        configurationTemplateName != null && configurationTemplateId != null
          ? {
              configurationTemplate: {
                name: configurationTemplateName,
                id: configurationTemplateId,
              },
            }
          : {};
      props.onChange({
        ...props.config,
        ...templateDict,
        useParentConfig: useParent,
      });
    }
  };

  const hasConfigTemplateForkRequired =
    mode === "edit" &&
    hasConfigurationTemplates &&
    !isTemplate &&
    (configurationTemplate !== undefined ||
      hasBulkSelectedItemsWithConfigTemplate);

  const handleConfigTemplate = handleChange("configurationTemplate");

  const handleUseParentConfig = (useParent: boolean) => {
    if (useParent) {
      let parentConfig: { name: string | null; id: string | null } | undefined;

      if (selectedItems.length === 0) {
        handleConfigTemplateAndUseParentConfig(
          props.config.parentConfigurationTemplateName,
          props.config.parentConfigurationTemplateId,
          useParent
        );
        return;
      }

      for (const item of selectedItems) {
        if (!("resourceType" in item)) {
          break;
        }
        const configTemplate = item.parentResource?.configTemplate;

        if (configTemplate === null) {
          break;
        }
        if (parentConfig === undefined) {
          parentConfig = configTemplate;
          continue;
        }

        const isIdMatch = parentConfig.id === configTemplate?.id;

        if (!isIdMatch) {
          parentConfig = undefined;
          break;
        }
      }
      if (parentConfig !== undefined) {
        handleConfigTemplateAndUseParentConfig(
          parentConfig.name,
          parentConfig.id,
          useParent
        );
        return;
      }
    }
    handleChange("useParentConfig")(useParent);
    return;
  };

  return (
    <div className={styles.detailsContainer}>
      {!allGroupBindingSources && (
        <>
          <div className={sprinkles({ marginBottom: "md", marginTop: "md" })}>
            <GroupBindingWarningBanner
              groupBinding={groupBinding}
              bulkMode={bulkMode === "edit"}
            />
          </div>
        </>
      )}
      <div>
        <FormRow title="Resource Details">
          {authState.user?.isAdmin && (
            <div
              className={sprinkles({
                flexGrow: 1,
                display: "flex",
                justifyContent: "flex-end",
              })}
            >
              <ButtonV3
                type="mainBorderless"
                label="Edit Details"
                leftIconName="edit"
                size="md"
                onClick={() => {
                  if (resourceId) {
                    history.push(`/resources/${resourceId}/edit`);
                  } else if (groupId) {
                    history.push(`/groups/${groupId}/edit`);
                  } else {
                    logError("Edit details: No resourceId or groupId found");
                  }
                }}
              />
            </div>
          )}
        </FormRow>
        {name && (
          <FormRow title="Name">
            <Label label={name} />
          </FormRow>
        )}
        {connectionId && <AppRow connectionId={connectionId} mode={mode} />}
        {resourceType && (
          <FormRow title="Resource type" disabled={mode === "edit"}>
            <Label
              label={resourceTypeInfoByType[resourceType].fullName}
              icon={{ type: "entity", entityType: resourceType }}
            />
          </FormRow>
        )}
        {parentResourceId && parentResourceName && parentResourceType ? (
          <FormRow
            title={resourceTypeInfoByType[parentResourceType].name}
            disabled={mode === "edit"}
          >
            <Label
              label={parentResourceName}
              icon={{ type: "entity", entityType: parentResourceType }}
              linkTo={
                parentResourceType === ResourceType.AwsAccount
                  ? `/apps/${connectionId}/account/${parentResourceId}/overview`
                  : `/resources/${parentResourceId}/overview`
              }
            />
          </FormRow>
        ) : null}
        {!isTemplate ? (
          <DescriptionRow
            mode={mode}
            value={description}
            onChange={handleChange("description")}
            disabled={!descriptionEditable && mode === "edit"}
            matchRemoteDescription={
              commonMetadata?.matchRemoteDescription ?? false
            }
            setMatchRemoteDescription={(match) => {
              handleChange("commonMetadata")({
                matchRemoteDescription: match,
              });
            }}
          />
        ) : null}
        {entityId && (
          <FormRow title="ID" disabled={mode === "edit"}>
            {
              <ClickToCopyButton
                text={entityId}
                disableCopy={mode === "edit"}
              />
            }
          </FormRow>
        )}
        {remoteName != null &&
          groupType !== GroupType.OpalGroup &&
          resourceType !== ResourceType.OpalRole && (
            <FormRow title="Remote name" disabled={mode === "edit"}>
              {remoteName}
            </FormRow>
          )}
        {remoteId &&
          groupType !== GroupType.OpalGroup &&
          resourceType !== ResourceType.OpalRole && (
            <FormRow title="Remote ID" disabled={mode === "edit"}>
              {remoteId}
            </FormRow>
          )}
        {canViewRiskSensitivity && riskSensitivity && (
          <FormRow
            title="Risk Sensitivity"
            disabled={mode === "edit"}
            tooltipText={
              "Indicates level of concern/responsiveness towards potential risks affecting this resource."
            }
            showToolTipIcon
          >
            {
              <RiskSensitivityPill
                entityId={resourceId}
                riskLevel={riskSensitivity.value}
              />
            }
          </FormRow>
        )}
        {!isViewingAsNonAdmin && !isTemplate ? (
          <TagsRowV3
            mode={mode}
            selectedTagIds={tagIds}
            onChange={handleChange("tagIds")}
          />
        ) : null}
        {!isTemplate &&
        hasConfigurationTemplates &&
        !forkConfigurationTemplates ? (
          <ConfigurationTemplateRow
            mode={mode}
            onChange={handleConfigTemplate}
            configurationTemplate={configurationTemplate}
            showParentConfigSettings={allChildResources}
            useParentConfig={useParentConfig ?? false}
            onChangeUseParentConfig={handleUseParentConfig}
            isV3
          />
        ) : null}
        {hasConfigTemplateForkRequired ? (
          <>
            {!forkConfigurationTemplates ? (
              <Banner
                type="warning"
                message={
                  bulkMode === "edit"
                    ? "One or more items is linked to a configuration template. To bulk-change settings controlled by templates, you must first unlink the templates. If you want to make a mass-change to templated items, please edit the template instead."
                    : "This item is linked to a configuration template. To change settings controlled by templates, you must first unlink the template. If you want to make a mass-change to templated items, please edit the template instead."
                }
              />
            ) : null}
            <Checkbox
              label={
                useParentConfig && allowUseParentConfig
                  ? "To unlink this template, first unlink any parent templates"
                  : bulkMode === "edit"
                  ? "Unlink configuration templates"
                  : "Unlink configuration template"
              }
              disabled={useParentConfig && allowUseParentConfig}
              checked={forkConfigurationTemplates ?? false}
              onChange={(checked) => {
                handleChange("forkConfigurationTemplates")(checked);
              }}
            />
          </>
        ) : null}
        {!hasConfigTemplateForkRequired || forkConfigurationTemplates ? (
          <>
            <AdminRow
              owner={adminOwner ?? undefined}
              mode={mode}
              onChange={handleChange("adminOwner")}
              placeholder={
                isTemplate
                  ? "Select an owner for items linked to this template"
                  : undefined
              }
            />
            {!isViewingAsNonAdmin ? (
              <VisibilityRow
                mode={mode}
                entityType={groupType ? EntityType.Group : EntityType.Resource}
                visibility={visibility}
                visibilityGroups={visibilityGroups ?? []}
                parentResourceName={parentResourceName}
                parentResourceVisibility={parentResourceVisibility}
                parentResourceVisibilityGroups={parentResourceVisibilityGroups}
                // For okta resources, things work a little differently. We don't
                // consider the visibility of the connection when computing the
                // final visibility of an app or group, rather we only consider
                // the final visibility of the item.
                connectionName={isOktaResource ? undefined : connectionName}
                connectionVisibility={
                  isOktaResource ? undefined : connectionVisibility
                }
                connectionVisibilityGroups={
                  isOktaResource ? undefined : connectionVisibilityGroups
                }
                onChangeVisibilityAndGroups={(val: {
                  visibility?: Visibility;
                  groupIds?: string[];
                  setDoNotChange?: boolean;
                }) => {
                  const { visibility, groupIds, setDoNotChange } = val;
                  if (setDoNotChange) {
                    props.onChange({
                      ...props.config,
                      visibility: undefined,
                      visibilityGroups: undefined,
                    });
                    return;
                  }
                  const visDict = visibility ? { visibility } : {};
                  const visGroupsDict = groupIds
                    ? { visibilityGroups: groupIds }
                    : {};
                  props.onChange({
                    ...props.config,
                    ...visDict,
                    ...visGroupsDict,
                  });
                }}
                isV3
              />
            ) : null}
            {showMessageChannels && (
              <SlackChannelsRowV3
                mode={mode}
                messageChannels={messageChannels}
                adminOwnerName={adminOwner?.name ?? ""}
                onChange={handleChange("messageChannels")}
              />
            )}
            {(canBeAccessed || isTemplate) && (
              <ConditionalConfigs
                mode={mode}
                entityId={entityId}
                entityType={
                  Object.values(GroupType).includes(entityType as GroupType)
                    ? EntityType.Group
                    : EntityType.Resource
                }
                requestConfigs={requestConfigs ?? []}
                onChange={handleRequestConfigsChange}
                isViewingAsNonAdmin={Boolean(isViewingAsNonAdmin)}
                isV3
              />
            )}
            {hasTicketPropagation && (allResources || isTemplate) && (
              <ResourceTicketPropagationRow
                key={entityId}
                value={ticketPropagation}
                onChange={handleChange("ticketPropagation")}
                mode={mode}
              />
            )}
            {(allGroups && allGroupBindingSources && !isViewingAsNonAdmin) ||
            isTemplate ? (
              <>
                <OnCallSchedulesRow
                  mode={mode}
                  onCallSchedules={onCallSchedules}
                  onChange={handleChange("onCallSchedules")}
                  showTemplateWarning={isTemplate}
                />
                <BreakGlassUsersRow
                  mode={mode}
                  breakGlassUsers={breakGlassUsers}
                  onChange={handleChange("breakGlassUsers")}
                  showTemplateWarning={isTemplate}
                />
              </>
            ) : null}
            {(canBeAccessed || isTemplate) && (
              <>
                <RequireMfaToApproveRow
                  mode={mode}
                  requireMfa={requireMfaToApprove}
                  onChange={handleChange("requireMfaToApprove")}
                />
                {(allSessionable && allResources) || isTemplate ? (
                  <RequireMfaToConnectRow
                    mode={mode}
                    requireMfa={requireMfaToConnect}
                    onChange={handleChange("requireMfaToConnect")}
                    showTemplateWarning={isTemplate}
                  />
                ) : null}
              </>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
};

export default ResourcesConfigFormV3;
