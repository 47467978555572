import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AddResourceUserInput,
  EntityType,
  Maybe,
  OidcProviderType,
  RemoveResourceUserInput,
  ResourceAccessLevel,
  ResourceFragment,
  ResourceType,
  ResourceUserFragment,
  ResourceUserSource,
  useAddResourceUsersMutation,
  useInitOidcAuthFlowMutation,
  useRemoveResourceUsersMutation,
  useUsersQuery,
} from "api/generated/graphql";
import { ConditionalEditor } from "components/entity_viewer/editor/ConditionalEditor";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import UserLabel from "components/label/item_labels/UserLabel";
import ResourceRoleSelectModal from "components/modals/ResourceRoleSelectModal";
import { SelectType } from "components/modals/update/SelectItemsModal";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import { useToast } from "components/toast/Toast";
import { Banner } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import pluralize from "pluralize";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { generateState } from "utils/auth/auth";
import { AuthorizedActionManage } from "utils/auth/auth";
import { resourceHasOnlyOneRole } from "utils/directory/resources";
import { isSnowflakeResource } from "utils/directory/resources";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useDebouncedValue, useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";
import {
  clearOidcData,
  getOidcData,
  OidcPostAuthAction,
  setOidcData,
} from "utils/oidc/oidc";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
} from "views/requests/utils";
import ResourceUsersTable from "views/resources/ResourceUsersTable";

import { Role } from "../../../../components/modals/ResourceIndividualRoleModal";
import * as styles from "./ResourceUsersRow.css";

/* Limit for performance reasons - user needs to use search beyond the first N */
const MAX_USERS_TO_DISPLAY = 100;

type ResourceUsersRowProps = {
  resource: ResourceFragment;
};

export const ResourceUsersRow = (props: ResourceUsersRowProps) => {
  let resourceUsers: ResourceUserFragment[] = props.resource.resourceUsers;

  const startPushTaskPoll = usePushTaskLoader();
  const logEvent = useLogEvent();
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  const history = useHistory();
  const [addUsersErrorMessage, setAddUsersErrorMessage] = useState<
    Maybe<string>
  >(null);
  const [removeUsersErrorMessage, setRemoveUsersErrorMessage] = useState<
    Maybe<string>
  >(null);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);

  const { data: usersData, error: usersError, refetch } = useUsersQuery({
    variables: {
      input: {
        maxNumEntries: MAX_USERS_TO_DISPLAY,
      },
    },
  });

  useEffect(() => {
    refetch({
      input: {
        searchQuery: debouncedSearchQuery || undefined,
        maxNumEntries: MAX_USERS_TO_DISPLAY,
      },
    });
  }, [refetch, debouncedSearchQuery]);

  const users = usersData?.users.users;
  if (usersError) {
    logError(usersError, `failed to list users`);
  }

  const [
    addResourceUsers,
    { loading: addUsersLoading },
  ] = useAddResourceUsersMutation();

  const [
    removeResourceUsers,
    { loading: removeUsersLoading },
  ] = useRemoveResourceUsersMutation();

  const [showUsersAddModal, setShowUsersAddModal] = useState(false);
  const [roleByUserIdToAdd, setRolesByUserIdToAdd] = useState<
    Record<string, Role[]>
  >({});
  const [
    accessDurationsByUserIdToAdd,
    setAccessDurationsByUserIdToAdd,
  ] = useState<Record<string, ExpirationValue>>({});

  const [showUsersRemoveModal, setShowUsersRemoveModal] = useState(false);
  const [roleByUserIdToRemove, setRoleByUserIdToRemove] = useState<
    Record<string, Role[]>
  >({});

  const [initOidcAuthFlow] = useInitOidcAuthFlowMutation();
  const { displayErrorToast } = useToast();

  const usersTable = (
    <ResourceUsersTable resource={props.resource} users={resourceUsers} />
  );

  const editor = (
    <ConditionalEditor
      menuOptions={[
        {
          label: "Add",
          handler: () => {
            if (hasV3) {
              history.push(`/resources/${props.resource.id}/add-users`);
            } else {
              setShowUsersAddModal(true);
            }
          },
        },
        {
          label: "Remove",
          handler: () => {
            setShowUsersRemoveModal(true);
          },
        },
      ]}
      disabledInReadOnlyMode={
        props.resource.resourceType !== ResourceType.OpalRole
      }
      isAdmin={props.resource.authorizedActions?.includes(
        AuthorizedActionManage
      )}
      additionalConditions={[
        {
          canEdit: () => !isSnowflakeResource(props.resource.resourceType),
          tooltipText:
            "Users can only be added/removed from a Snowflake resources via a Snowflake Role.",
        },
      ]}
    />
  );

  const resourceUsersWithDirectAccess = _.uniqBy(
    resourceUsers.filter((user) => user.access?.directAccessPoint),
    "userId"
  );

  const directRolesByUserId: Record<string, ResourceAccessLevel[]> = {};
  const numDirectAccessPointsByUserId: Record<string, number> = {};
  resourceUsers.forEach((resourceUser) => {
    if (resourceUser.access?.directAccessPoint) {
      if (!directRolesByUserId[resourceUser.userId]) {
        directRolesByUserId[resourceUser.userId] = [];
      }
      directRolesByUserId[resourceUser.userId] = _.uniqBy(
        [...directRolesByUserId[resourceUser.userId], resourceUser.accessLevel],
        "accessLevelRemoteId"
      );

      if (!numDirectAccessPointsByUserId[resourceUser.userId]) {
        numDirectAccessPointsByUserId[resourceUser.userId] = 0;
      }
      numDirectAccessPointsByUserId[resourceUser.userId] += 1;
    }
  });

  let resourceHasOnlyOneAccessType =
    props.resource && resourceHasOnlyOneRole(props.resource);

  if (props.resource.resourceType === ResourceType.OktaApp) {
    resourceHasOnlyOneAccessType = true;
  }

  const addUsersModalReset = () => {
    setShowUsersAddModal(false);
    setAddUsersErrorMessage(null);
    setRolesByUserIdToAdd({});
    setSearchQuery("");
  };

  const addUsersModal = (
    <ResourceRoleSelectModal
      key={"users_add"}
      title={"Add user access"}
      selectType={SelectType.Add}
      itemName={"user"}
      entryInfos={
        users
          ?.filter((user) => {
            const directRolelCount =
              numDirectAccessPointsByUserId[user.id] || 0;
            if (
              user.isSystemUser ||
              (resourceHasOnlyOneAccessType && directRolelCount !== 0)
            ) {
              return false;
            }

            return true;
          })
          .map((user) => {
            const directRoleCount = numDirectAccessPointsByUserId[user.id] || 0;
            return {
              entityId: { entityId: user.id, entityType: EntityType.User },
              label: (
                <div className={styles.userLabelContainer}>
                  <UserLabel
                    name={user.fullName}
                    email={user.email}
                    avatar={user.avatarUrl}
                    subText={`${directRoleCount} direct ${pluralize(
                      "role",
                      directRoleCount
                    )}`}
                    large
                    bold
                  />
                </div>
              ),
              isBold: false,
              isBreadcrumb: false,
            };
          }) || []
      }
      isModalOpen={showUsersAddModal}
      onClose={addUsersModalReset}
      onSubmit={async () => {
        logEvent({
          name: "apps_add_user",
          properties: {
            type: "resource",
            numUsers: Object.entries(roleByUserIdToAdd).length,
          },
        });
        try {
          const resourceUsersToAdd: AddResourceUserInput[] = [];
          for (const [userId, roles] of Object.entries(roleByUserIdToAdd)) {
            const expirationVal =
              accessDurationsByUserIdToAdd[userId] ||
              ExpirationValue.Indefinite;
            const accessDurationInMinutes = expirationValueToDurationInMinutes(
              expirationVal
            )?.asMinutes();
            roles.forEach((role) => {
              resourceUsersToAdd.push({
                resourceId: props.resource.id,
                userId: userId,
                accessLevel: {
                  accessLevelName: role.accessLevelName,
                  accessLevelRemoteId: role.accessLevelRemoteId,
                },
                durationInMinutes: accessDurationInMinutes,
              });
            });
          }

          const { data } = await addResourceUsers({
            variables: {
              input: {
                resourceUsers: resourceUsersToAdd,
              },
            },
            refetchQueries: ["ResourceDetailColumn"],
          });
          switch (data?.addResourceUsers.__typename) {
            case "AddResourceUsersResult":
              startPushTaskPoll(data.addResourceUsers.taskId);
              addUsersModalReset();
              break;
            case "CannotAddSystemUserToResourceError":
              logError(new Error(data.addResourceUsers.message));
              setAddUsersErrorMessage(data.addResourceUsers.message);
              break;
            case "OpalGlobalImpersonationResourceDirectAddNotAllowedError":
              logError(new Error(data.addResourceUsers.message));
              setAddUsersErrorMessage(data.addResourceUsers.message);
              break;
            case "CallToWebhookFailedError":
              setAddUsersErrorMessage(data.addResourceUsers.message);
              break;
            case "ResourceUserAlreadyExists":
              setAddUsersErrorMessage(data.addResourceUsers.message);
              break;
            default:
              logError(new Error(`failed to update resource user access`));
              setAddUsersErrorMessage(
                "Error: failed to update resource user access"
              );
          }
        } catch (error) {
          logError(error, "failed to update resource user access");
          setAddUsersErrorMessage(
            getModifiedErrorMessage(
              "Error: failed to update resource user access",
              error
            )
          );
        }
      }}
      loading={addUsersLoading}
      errorMessage={addUsersErrorMessage}
      setErrorMessage={setAddUsersErrorMessage}
      submitDisabled={Object.keys(roleByUserIdToAdd).length === 0}
      resource={props.resource}
      existingDirectRolesByEntityId={directRolesByUserId}
      updatedRolesByEntityId={roleByUserIdToAdd}
      setUpdatedRolesByEntityId={setRolesByUserIdToAdd}
      updatedAccessDurationsByEntityId={accessDurationsByUserIdToAdd}
      setUpdatedAccessDurationsByEntityId={setAccessDurationsByUserIdToAdd}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    />
  );

  const removeUsersModalReset = () => {
    setShowUsersRemoveModal(false);
    setRemoveUsersErrorMessage(null);
    setRoleByUserIdToRemove({});
    setSearchQuery("");
  };

  const submitRemoval = async (
    resourceUsersToRemove: RemoveResourceUserInput[]
  ) => {
    logEvent({
      name: "apps_remove_user",
      properties: {
        type: "resource",
        numUsers: resourceUsersToRemove.length,
      },
    });

    try {
      const { data } = await removeResourceUsers({
        variables: {
          input: {
            resourceUsers: resourceUsersToRemove,
          },
        },
      });
      switch (data?.removeResourceUsers.__typename) {
        case "RemoveResourceUsersResult":
          startPushTaskPoll(data.removeResourceUsers.taskId, {
            refetchOnComplete: [{ resourceId: props.resource.id }],
          });
          removeUsersModalReset();
          break;
        case "OpalAdminRoleMustHaveAtLeastOneDirectUser":
          logError(new Error(data.removeResourceUsers.message));
          setRemoveUsersErrorMessage(data.removeResourceUsers.message);
          break;
        case "CallToWebhookFailedError":
          setRemoveUsersErrorMessage(data.removeResourceUsers.message);
          break;
        case "OidcIDTokenNotFoundError":
          try {
            const state = generateState();
            const { data } = await initOidcAuthFlow({
              variables: {
                input: {
                  state: state,
                  oidcProviderType: OidcProviderType.AwsSession,
                },
              },
            });
            switch (data?.initOidcAuthFlow.__typename) {
              case "InitOidcAuthFlowResult":
                setOidcData({
                  state: state,
                  oidcProviderType: OidcProviderType.AwsSession,
                  postAuthPath: window.location.pathname,
                  postAuthHash: window.location.hash,
                  params: {
                    action: OidcPostAuthAction.RemoveResourceUser,
                    resourceId: props.resource.id,
                    resourceUsersToRemove: resourceUsersToRemove,
                  },
                });
                window.location.replace(data?.initOidcAuthFlow.authorizeUrl);
                break;
              case "OidcProviderNotFoundError":
                displayErrorToast(
                  "Error: Failed to trigger MFA. Please contact your admin."
                );
                break;
              default:
                displayErrorToast(
                  "Error: Failed to trigger MFA. Please try again or contact support if the issue persists."
                );
            }
          } catch (e) {
            logError(e, "Error: resource forfeiture failed");
            setRemoveUsersErrorMessage(
              getModifiedErrorMessage("Error: resource forfeiture failed", e)
            );
          }
          break;
        default:
          logError(new Error(`failed to update resource user access`));
          setRemoveUsersErrorMessage(
            "Error: failed to update resource user access"
          );
      }
    } catch (error) {
      logError(error, "failed to update resource user access");
      setRemoveUsersErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to update resource user access",
          error
        )
      );
    }
  };

  useMountEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has("oidc_auth")) {
      const oidcAuthStatus = queryParams.get("oidc_auth");
      if (oidcAuthStatus === "success") {
        const oidcData = getOidcData();
        if (
          oidcData &&
          oidcData.params?.action === OidcPostAuthAction.RemoveResourceUser &&
          oidcData.params.resourceId === props.resource.id &&
          oidcData.params.resourceUsersToRemove
        ) {
          clearOidcData();
          setShowUsersRemoveModal(true);
          submitRemoval(oidcData.params.resourceUsersToRemove);
          // Clear oidc_auth parameter
          queryParams.delete("oidc_auth");
          history.replace({
            search: queryParams.toString(),
          });
        }
      } else {
        displayErrorToast(
          "Authentication failed. Please try again or contact support if the issue remains."
        );
      }
    }
  });

  const removeUsersModal = (
    <ResourceRoleSelectModal
      key={"users_remove"}
      title={"Remove user access"}
      selectType={SelectType.Remove}
      itemName={"user"}
      entryInfos={
        resourceUsersWithDirectAccess
          .filter((resourceUser) => {
            return (
              resourceUser.user?.fullName
                .toLowerCase()
                .includes(searchQuery.toLocaleLowerCase()) ||
              resourceUser.user?.email
                .toLowerCase()
                .includes(searchQuery.toLocaleLowerCase())
            );
          })
          .sort((a, b) => {
            if (a && b) {
              const aSortString = a.user?.fullName || "";
              const bSortString = b.user?.fullName || "";
              return aSortString.localeCompare(bSortString);
            }
            return 0;
          })
          .map((user) => {
            let disabled = false;
            let tooltipText = "";
            if (user.source === ResourceUserSource.Inherited) {
              disabled = true;
              tooltipText = `This user was added due to having inherited access to the resource from another entity.
                    To remove them, please remove them from that entity on the end system.`;
            }

            const directRoleCount =
              numDirectAccessPointsByUserId[user.userId] || 0;
            return {
              entityId: { entityId: user.userId, entityType: EntityType.User },
              label: (
                <div className={styles.userLabelContainer}>
                  <UserLabel
                    name={user.user?.fullName}
                    email={user.user?.email}
                    subText={`${directRoleCount} direct ${pluralize(
                      "role",
                      directRoleCount
                    )}`}
                    avatar={user.user?.avatarUrl}
                    large
                    bold
                  />
                </div>
              ),
              disabled: disabled,
              tooltipText: tooltipText,
              isBold: false,
              isBreadcrumb: false,
            };
          }) || []
      }
      isModalOpen={showUsersRemoveModal}
      onClose={removeUsersModalReset}
      onSubmit={() => {
        const resourceUsersToRemove: RemoveResourceUserInput[] = [];
        for (const [userId, roles] of Object.entries(roleByUserIdToRemove)) {
          roles.forEach((role) => {
            resourceUsersToRemove.push({
              resourceId: props.resource.id,
              userId: userId,
              accessLevel: {
                accessLevelName: role.accessLevelName,
                accessLevelRemoteId: role.accessLevelRemoteId,
              },
            });
          });
        }
        submitRemoval(resourceUsersToRemove);
      }}
      loading={removeUsersLoading}
      errorMessage={removeUsersErrorMessage}
      submitDisabled={Object.keys(roleByUserIdToRemove).length === 0}
      resource={props.resource}
      existingDirectRolesByEntityId={directRolesByUserId}
      updatedRolesByEntityId={roleByUserIdToRemove}
      setUpdatedRolesByEntityId={setRoleByUserIdToRemove}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    />
  );

  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        width: "100%",
        height: "100%",
      })}
    >
      {resourceUsers.length === 0 &&
        (props.resource.resourceType == ResourceType.GcpCloudSqlMysqlInstance ||
          props.resource.resourceType ==
            ResourceType.GcpCloudSqlPostgresInstance) && (
          <div className={sprinkles({ marginTop: "md", marginX: "md" })}>
            <Banner
              message={`Note that this instance will display no users if it is stopped.`}
            />
          </div>
        )}
      <EntityViewerRow
        title={"Users"}
        content={
          <EmptyStateContentWrapper
            content={usersTable}
            entityType={EntityType.User}
            title={`No users with access`}
            subtitle={`Grant users direct access to this resource to populate the table`}
            buttonTitle={`Add user access`}
            isEmpty={resourceUsers.length === 0}
            onClickHandler={() => {
              setShowUsersAddModal(true);
            }}
          />
        }
        editor={editor}
        modals={
          <>
            {showUsersAddModal && addUsersModal}
            {showUsersRemoveModal && removeUsersModal}
          </>
        }
        isTable={true}
      />
    </div>
  );
};

export default ResourceUsersRow;
