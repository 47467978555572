export enum EntityTypeDeprecated {
  Resource,
  Group,
  ResourceFolder,
  GroupFolder,
  Request,
  Service,
  Observation,
  Connection,
  Team,
  User,
  ResourceService,
  ConnectionService,
  Event,
}
