import { Route, useRouteMatch } from "react-router";

import MetricsDashboard from "./MetricsDashboard";

const MetricsDashboardView = () => {
  const match = useRouteMatch();

  return <Route exact path={`${match.path}`} component={MetricsDashboard} />;
};

export default MetricsDashboardView;
