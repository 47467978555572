import { EntityIdTupleNullable } from "api/common/common";
import {
  EntityIdTuple,
  EntityType,
  Maybe,
  MessageChannelFragment,
  ThirdPartyIntegrationFragment,
  ThirdPartyProvider,
} from "api/generated/graphql";
import {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import { useLocation, useParams } from "react-router-dom";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { logError } from "utils/logging";

// From https://stackoverflow.com/questions/32553158/detect-click-outside-react-component
export const useComponentVisible = (
  initialIsVisible: boolean
): [
  RefObject<HTMLInputElement>,
  boolean,
  Dispatch<SetStateAction<boolean>>
] => {
  const [isComponentVisible, setIsComponentVisible] = useState<boolean>(
    initialIsVisible
  );
  const ref = useRef<HTMLInputElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (ref && ref.current && !ref.current.contains(event.target as Node)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  return [ref, isComponentVisible, setIsComponentVisible];
};

export const truncate = (str: string, n: number): string => {
  return str.length > n ? str.substr(0, n - 1) + "..." : str;
};

export const capitalize = (str: string): string => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

export const sleep = (milliseconds: number) => {
  return new Promise((resolve) => setTimeout(resolve, milliseconds));
};

export const useBasePath = () => {
  const location = useLocation();
  const params = useParams<Record<string, string>>();

  return Object.values(params).reduce(
    (path, param) => path.replace("/" + param, ""),
    location.pathname
  );
};

export const getNumberWithOrdinal = (n: number) => {
  const s = ["th", "st", "nd", "rd"],
    v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const getResourceUrl = (
  entityTypeDeprecated: EntityTypeDeprecated | null,
  entityId: string | null | undefined,
  hasInventory?: boolean
) => {
  let urlBase = "";
  switch (entityTypeDeprecated) {
    case EntityTypeDeprecated.Resource:
      urlBase = "resources";
      break;
    case EntityTypeDeprecated.Group:
      urlBase = "groups";
      break;
    case EntityTypeDeprecated.Request:
      urlBase = "requests";
      break;
    case EntityTypeDeprecated.Connection:
      urlBase = hasInventory ? "inventory" : "apps";
      break;
    case EntityTypeDeprecated.User:
      urlBase = "users";
      break;
    case EntityTypeDeprecated.Service:
      urlBase = "";
      break;
    case EntityTypeDeprecated.Event:
      urlBase = "events";
      break;
  }

  let url = `/${urlBase}/${entityId}`;

  if (entityId) {
    return url;
  }

  return `/${urlBase}`;
};

export type EntityIdTupleWrapper = {
  entityId: Maybe<EntityIdTuple>;
  entityType: EntityType;
};

export const getResourceUrlNew = (
  entityId: EntityIdTupleNullable,
  override?: EntityType,
  hasInventory?: boolean
) => {
  let urlBase = "";
  switch (override || entityId.entityType) {
    case EntityType.Resource:
      urlBase = "resources";
      break;
    case EntityType.Group:
    case EntityType.OnCallGroup:
      urlBase = "groups";
      break;
    case EntityType.Request:
      urlBase = "requests";
      break;
    case EntityType.Connection:
      urlBase = hasInventory ? "inventory" : "apps";
      break;
    case EntityType.User:
      urlBase = "users";
      break;
    case EntityType.Owner:
      urlBase = "owners";
      break;
    case EntityType.Event:
      urlBase = "events";
      break;
    case EntityType.AccessReview:
      urlBase = "access-reviews";
      break;
    case EntityType.AccessReviewResource:
      urlBase = "access-reviews/r";
      break;
    case EntityType.AccessReviewGroup:
      urlBase = "access-reviews/g";
      break;
    case EntityType.AccessReviewConnection:
      urlBase = "access-reviews/c";
      break;
    case EntityType.AccessReviewTemplate:
      urlBase = "access-reviews/t";
      break;
    case EntityType.Session:
      // note: we currently do not support displaying sessions, this is a placeholder
      urlBase = "sessions";
      break;
    case EntityType.IdpConnection:
      // note: we currently do not support displaying idp connections, this is a placeholder
      urlBase = "admin/settings/idp/browse";
      break;
    case EntityType.MessageChannel:
      // note: we currently do not support displaying message channels, this is a placeholder
      urlBase = "messagechannels";
      break;
    case EntityType.OnCallSchedule:
      // note: we currently do not support displaying on-call schedules, this is a placeholder
      urlBase = "oncall-schedules";
      break;
    case EntityType.Tag:
      urlBase = "tags";
      break;
    case EntityType.EventFilter:
      urlBase = "events/filters";
      break;
    case EntityType.RequestTemplate:
      urlBase = "templates/requests";
      break;
    case EntityType.OrgSetting:
      // note: org settings is a bit special because it doesn't represent an actual entity.
      urlBase = "settings";
      return `/${urlBase}`;
    case EntityType.Bundle:
      urlBase = "bundles";
      break;
    case EntityType.ConfigurationTemplate:
      urlBase = "templates/configurations";
      break;
    case EntityType.GroupBinding:
      urlBase = "linked-groups";
      break;
    case EntityType.EventStreamConnection:
      urlBase = "settings#event-streaming";
      break;
    default:
      logError(
        `Tried to derive a URL with an unknown entity type. Broken link in the app! entityId: ${entityId.entityId}, entityType: ${entityId.entityType}`
      );
      return "";
  }

  if (!entityId.entityId) {
    return `/${urlBase}`;
  }

  return `/${urlBase}/${entityId.entityId}`;
};

export function notEmpty<TValue>(
  value: TValue | null | undefined
): value is TValue {
  return value !== null && value !== undefined;
}

export enum RequestState {
  Loading,
  Success,
  Failure,
  Warning,
}

export enum TextInputType {
  Text = "text",
  Password = "password",
  Number = "number",
  Boolean = "boolean",
}

export function isSupportTicket(integration: ThirdPartyIntegrationFragment) {
  switch (integration.thirdPartyProvider) {
    case ThirdPartyProvider.Jira:
      return true;
    case ThirdPartyProvider.Linear:
      return true;
    case ThirdPartyProvider.ServiceNow:
      // ServiceNow is not a fully supported ticketing system. It is only
      // used as an audit log for access requests, and cannot be used for
      // auto-expiration of access when the remote ticket is closed.
      return true;
    default:
      return false;
  }
}

export function getSupportTicketThirdPartyProviders(
  integrations: Maybe<ThirdPartyIntegrationFragment[]> | undefined
): ThirdPartyProvider[] {
  if (!integrations) {
    return [];
  }

  return integrations
    .filter(isSupportTicket)
    .map((integration) => integration.thirdPartyProvider);
}

export function integrationExists(
  integrations: Maybe<ThirdPartyIntegrationFragment[]> | undefined,
  thirdPartyProvider: ThirdPartyProvider | null
): boolean {
  if (thirdPartyProvider == null) {
    return true;
  }

  if (!integrations) {
    return false;
  }
  return integrations.some(
    (integration) => integration.thirdPartyProvider === thirdPartyProvider
  );
}

export enum GeneratedChannelState {
  DOES_NOT_EXIST,
  CREATED,
  LINKED,
}

export const getGeneratedChannelState = (
  generatedChannelName: string,
  selectedChannels: MessageChannelFragment[],
  messageChannelOptions: MessageChannelFragment[]
): GeneratedChannelState => {
  let generatedChannelState: GeneratedChannelState;
  const generatedChannelIsLinked = selectedChannels.find(
    (selectedChannel) =>
      selectedChannel && selectedChannel.name === generatedChannelName
  );
  const generatedChannelExists = messageChannelOptions.find(
    (channel) => channel.name === generatedChannelName
  );

  if (generatedChannelIsLinked) {
    generatedChannelState = GeneratedChannelState.LINKED;
  } else if (generatedChannelExists) {
    generatedChannelState = GeneratedChannelState.CREATED;
  } else {
    generatedChannelState = GeneratedChannelState.DOES_NOT_EXIST;
  }

  return generatedChannelState;
};
