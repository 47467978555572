import {
  AuthSessionStatus,
  AuthUserFragment,
  UserFragment,
  UserPreviewSmallFragment,
} from "api/generated/graphql";
import {
  createContext,
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  useReducer,
} from "react";
import AuthClient, { Auth0Config } from "utils/auth/AuthClient";

export enum AuthContextActionType {
  Auth0Config,
  SignIn,
  SignOut,
  GetAuthSessionStatus,
  OnPremOnboarding,
  RefreshUser,
  AccessChanged,
}

export type AuthContextState = {
  isInitialized: boolean;
  user: AuthUserFragment | null;
  isAuthenticated: boolean;
  lastMfaTime: Date | null;
  authClient: AuthClient | null;
  authSessionStatus: AuthSessionStatus | null;
  impersonatingUser: UserPreviewSmallFragment | null;
};

const initialContext: AuthContextState = {
  isInitialized: false,
  user: null,
  isAuthenticated: false,
  lastMfaTime: null,
  authClient: null,
  authSessionStatus: null,
  impersonatingUser: null,
};

type ContextEvent = {
  type: AuthContextActionType;
  payload: {
    user?: AuthUserFragment | undefined | null;
    authSessionStatus?: AuthSessionStatus | undefined | null;
    auth0Config?: Auth0Config | undefined | null;
    lastMfaTime?: Date | undefined | null;
    refreshUser?: UserFragment;
    impersonatingUser?: UserPreviewSmallFragment | undefined | null;
  };
};

const AuthContext = createContext<{
  authState: AuthContextState;
  authDispatch: Dispatch<ContextEvent>;
}>({
  authState: initialContext,
  authDispatch: () => null,
});

const reducer = (
  state: AuthContextState,
  action: ContextEvent
): AuthContextState => {
  const payloadUser = action.payload.user;
  switch (action.type) {
    case AuthContextActionType.Auth0Config: {
      let authClient: AuthClient | null = null;
      if (action.payload.auth0Config) {
        authClient = new AuthClient(action.payload.auth0Config);
      }
      return {
        ...state,
        authClient: authClient,
      };
    }
    case AuthContextActionType.SignIn:
      if (!payloadUser) {
        throw new Error("User unexpectedly null after sign in.");
      }

      return {
        ...state,
        isAuthenticated: true,
        user: payloadUser,
      };
    case AuthContextActionType.GetAuthSessionStatus:
      if (action.payload.authSessionStatus === AuthSessionStatus.SessionValid) {
        if (!payloadUser) {
          throw new Error("User unexpectedly null after sign in.");
        }

        return {
          ...state,
          isInitialized: true,
          isAuthenticated: true,
          user: payloadUser,
          authSessionStatus: action.payload.authSessionStatus,
          impersonatingUser: action.payload.impersonatingUser || null,
        };
      } else if (
        action.payload.authSessionStatus ===
          AuthSessionStatus.SessionNotFound ||
        action.payload.authSessionStatus === AuthSessionStatus.SessionExpired
      ) {
        return {
          ...state,
          isInitialized: true,
          isAuthenticated: false,
          authSessionStatus: action.payload.authSessionStatus,
        };
      } else {
        return state;
      }
    case AuthContextActionType.OnPremOnboarding:
      // We have an edge case with onboarding for on-prem. Half of the onboarding
      // flow is done with an uninitialized auth client. Rather than break the
      // flow into multiple parts, we simply pop out of the uninitialized call
      // stack with this event and eventually initialize the auth client later
      // in the flow.
      return {
        ...state,
        isInitialized: true,
      };
    case AuthContextActionType.RefreshUser:
      if (!action.payload.refreshUser) {
        throw new Error("User unexpectedly null for refresh.");
      }
      return {
        ...state,
        user: {
          user: action.payload.refreshUser,
          isReadOnlyAdmin: state.user?.isReadOnlyAdmin || false,
          isAdmin: state.user?.isAdmin || false,
          isAuditor: state.user?.isAuditor || false,
          isGlobalRequester: state.user?.isGlobalRequester || false,
        },
      };
    case AuthContextActionType.AccessChanged:
      if (payloadUser) {
        return {
          ...state,
          user: payloadUser,
        };
      }
      return state;
    default:
      return state;
  }
};

export const AuthContextProvider: FunctionComponent = (
  props: PropsWithChildren<{}>
) => {
  const [authState, authDispatch] = useReducer(reducer, initialContext);

  return (
    <AuthContext.Provider value={{ authState, authDispatch }}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
