import {
  SyncTaskStatus,
  SyncType,
  useSetupStateQuery,
  useSyncStatusQuery,
  useSyncTaskQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import RowBar from "components/layout/RowBar";
import { Button, Link } from "components/ui";
import Icon, { IconName } from "components/ui/icon/Icon";
import sprinkles from "css/sprinkles.css";
import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import { ImportStep } from "./common";
import { SetupContext, useResolvedState } from "./SetupContext";
import { hasRecentSuccessfulSync } from "./steps/Sync";

const SetupBar = () => {
  const hasSetup = useFeatureFlag(FeatureFlag.SetupWizard);
  const history = useHistory();
  const { authState } = useContext(AuthContext);
  const { data, loading } = useSetupStateQuery({
    skip: !authState.user?.isAdmin,
  });
  const {
    hasSuccessfulSync,
    setHasSuccessfulSync,
    hasSuccessfulImport,
    setHasSuccessfulImport,
  } = useContext(SetupContext);
  const [
    { currentStep, importStep },
    { loading: stateLoading },
  ] = useResolvedState();
  const logEvent = useLogEvent();

  const { refetch } = useSyncTaskQuery({
    skip: true,
  });
  const { refetch: refetchOngoing } = useSyncStatusQuery({
    skip: true,
  });

  const [syncInProgress, setSyncInProgress] = useState(false);
  const [syncError, setSyncError] = useState("");

  const connectionId = data?.setupState.state.connectionId;

  useEffect(() => {
    const fetchSync = async () => {
      if (!connectionId || (currentStep !== 2 && currentStep !== 3)) {
        return;
      }
      const ongoingResult = await refetchOngoing({
        input:
          currentStep === 2
            ? {
                syncType: SyncType.PullIdpConnection,
                connectionId,
              }
            : {
                syncType: SyncType.PullConnectionSetupImport,
              },
      });
      const data = ongoingResult.data;
      if (data && data.syncStatus.__typename === "SyncStatusResult") {
        if (hasRecentSuccessfulSync(data)) {
          currentStep === 2
            ? setHasSuccessfulSync(true)
            : setHasSuccessfulImport(true);
          return;
        }
        const taskID = data.syncStatus.ongoingSyncTask
          ? data.syncStatus.ongoingSyncTask.id
          : null;
        if (taskID != null) {
          setSyncInProgress(true);
          setTimeout(() => {
            checkSyncStatus(taskID, 500, 250);
          }, 250);
        }
      }
    };
    // On mount, check if there is a recent IDP sync or current ongoing IDP sync.
    fetchSync();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [connectionId, currentStep]);

  if (loading || stateLoading || !hasSetup || !authState.user?.isAdmin) {
    return null;
  }

  if (data?.setupState.state.minStep === 4) {
    return null;
  }

  const checkSyncStatus = async (
    syncTaskId: string,
    waitInterval: number,
    totalWaited: number
  ) => {
    const syncTaskErrorText = `Error: could not get sync status`;
    try {
      const result = await refetch({
        input: {
          id: syncTaskId,
        },
      });
      const { data: syncTaskData } = result;
      let status: SyncTaskStatus;

      if (syncTaskData) {
        switch (syncTaskData?.syncTask.__typename) {
          case "SyncTaskResult":
            status = syncTaskData.syncTask.syncTask.status;
            if (status === SyncTaskStatus.Started) {
              // If in progress, keep polling to check for sync task completion
              setTimeout(() => {
                checkSyncStatus(
                  syncTaskId,
                  Math.min(2000, waitInterval * 2),
                  totalWaited + waitInterval
                );
              }, waitInterval);
            } else {
              setSyncInProgress(false);
              currentStep === 2
                ? setHasSuccessfulSync(true)
                : setHasSuccessfulImport(true);
            }

            break;
          case "SyncTaskNotFoundError":
          default:
            // Upon error, stop polling
            setSyncError(syncTaskErrorText);
            setSyncInProgress(false);
        }
      }
    } catch (error) {
      setSyncError(syncTaskErrorText);
      setSyncInProgress(false);
    }
  };

  let iconName: IconName = "users";
  let label: string | JSX.Element =
    "Connect an identity provider to finish setting up your organization.";
  let buttonText = "Connect identity provider";
  let barColor: "blue" | "green" | "yellow" = "blue";
  let color: "blue1000" | "green1000" | "yellow1000" = "blue1000";
  let iconColor: "blue600" | "green600" | "yellow600" = "blue600";
  let buttonType: "primary" | "success" | "warning" = "primary";

  switch (currentStep) {
    // Sync
    case 2: {
      if (hasSuccessfulSync) {
        iconName = "check-circle";
        label =
          "Your IdP sync has finished. Return to set up to import resources into Opal.";
        buttonText = "Return to set up";
        barColor = "green";
        color = "green1000";
        iconColor = "green600";
        buttonType = "success";
      } else if (syncInProgress) {
        iconName = "refresh";
        label =
          "Syncing resources from your IdP. We'll let you know when sync is complete.";
        buttonText = "Return to set up";
      } else if (syncError) {
        iconName = "alert-triangle";
        label = syncError;
        buttonText = "Return to set up";
        barColor = "yellow";
        color = "yellow1000";
        iconColor = "yellow600";
        buttonType = "warning";
      }
      break;
    }
    // Import
    case 3: {
      if (hasSuccessfulImport) {
        iconName = "check-circle";
        label = (
          <div>
            Import has finished. You can now{" "}
            <Link url="/settings#idp-and-hr">map custom attributes</Link>,{" "}
            <Link url={`/apps/${connectionId}`}>
              apply policies to resources
            </Link>
            , or{" "}
            <Link url="/settings#productivity-integrations">
              set up the Slack integration.
            </Link>
          </div>
        );
        buttonText = "";
        barColor = "green";
        color = "green1000";
        iconColor = "green600";
        buttonType = "success";
      } else if (syncInProgress) {
        iconName = "refresh";
        label =
          "Syncing imported resources from your IdP. We'll let you know when the sync is complete.";
        buttonText = "Return to set up";
      } else if (syncError) {
        iconName = "alert-triangle";
        label = syncError;
        buttonText = "Return to set up";
        barColor = "yellow";
        color = "yellow1000";
        iconColor = "yellow600";
        buttonType = "warning";
      } else if (
        importStep === ImportStep.SELECT_MODE ||
        importStep === ImportStep.SELECT_DATA
      ) {
        iconName = "check-circle";
        label =
          "Your IdP sync has finished. Return to set up to import resources into Opal.";
        buttonText = "Return to set up";
        barColor = "green";
        color = "green1000";
        iconColor = "green600";
        buttonType = "success";
      }
      break;
    }
  }

  return (
    <RowBar color={barColor}>
      <Icon name={iconName} size="sm" color={iconColor} />
      <div className={sprinkles({ flexGrow: 1, marginLeft: "sm", color })}>
        {label}
      </div>
      {buttonText && (
        <Button
          label={buttonText}
          size="sm"
          type={buttonType}
          onClick={() => {
            logEvent({
              name: "setup_open",
              properties: {
                currentStep,
              },
            });
            history.push("/setup");
          }}
        />
      )}
    </RowBar>
  );
};

export default SetupBar;
