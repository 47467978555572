import { AuthSessionStatus } from "api/generated/graphql";
import AuthContext, {
  AuthContextActionType,
} from "components/auth/AuthContext";
import { ToastStyle } from "components/toast/Toast";
import { getToastUrl, MessageCode } from "components/toast/ToastUrlParser";
import { useContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";
import { RequestState } from "utils/common";

export const Unauthenticated = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authState, authDispatch } = useContext(AuthContext);

  useEffect(() => {
    const locationURL = (): string => {
      return encodeURIComponent(
        location.pathname + location.search + location.hash
      );
    };

    // TODO: https://opalsecurity.atlassian.net/browse/OPAL-455
    if (
      !authState.authSessionStatus ||
      authState.authSessionStatus === AuthSessionStatus.SessionNotFound ||
      authState.authSessionStatus === AuthSessionStatus.SessionExpired
    ) {
      let nextLocalUrl: string | undefined = undefined;
      if (location.pathname !== "/sign-in" && location.pathname !== "/") {
        nextLocalUrl = locationURL();

        if (
          authState.authClient &&
          nextLocalUrl &&
          authState.authClient.config.nextLocalUrl !== nextLocalUrl
        ) {
          authDispatch({
            type: AuthContextActionType.Auth0Config,
            payload: {
              auth0Config: {
                domain: authState.authClient.config.domain,
                audience: authState.authClient.config.audience,
                clientId: authState.authClient.config.clientId,
                redirectUri: authState.authClient.config.redirectUri,
                logoutUri: authState.authClient.config.logoutUri,
                nextLocalUrl: nextLocalUrl,
              },
            },
          });
        }
      }

      if (
        !authState.authSessionStatus ||
        authState.authSessionStatus === AuthSessionStatus.SessionNotFound
      ) {
        let signInUrl = "/sign-in";
        if (nextLocalUrl) {
          signInUrl += `?next=${nextLocalUrl}`;
        }
        navigate(signInUrl, { replace: true });
      } else if (
        authState.authSessionStatus === AuthSessionStatus.SessionExpired
      ) {
        navigate(
          getToastUrl(
            "/sign-out",
            RequestState.Warning,
            MessageCode.ErrorAuthSessionInvalid,
            ToastStyle.Banner,
            nextLocalUrl
          ),
          { replace: true }
        );
      }
    }
  }, [
    authDispatch,
    authState.authClient,
    authState.authSessionStatus,
    location.hash,
    location.pathname,
    location.search,
    navigate,
  ]);

  return null;
};

export default Unauthenticated;
