import { Icon } from "components/ui";
import React, { useState } from "react";

import * as styles from "./ExpandableSection.css";

type ExpandableSectionProps = {
  label: string;
  /** If specified, ExpandableSection will be a controlled component whose state is managed
   *  by the parent. */
  showSection?: boolean;
  /** If specified, ExpandableSection will be a controlled component whose state is managed
   *  by the parent. */
  setShowSection?: (showSection: boolean) => void;
  /** Only used if the above are not specified. In this case, ExpandableSection will be an
   *  uncontrolled component that manages its own state. Defaults to false (not shown). */
  initialShowSection?: boolean;
  marginBottom?: "sm" | "md" | "lg";
};

const ExpandableSection: React.FC<ExpandableSectionProps> = (props) => {
  const [internalShowSection, setInternalShowSection] = useState<boolean>(
    Boolean(props.initialShowSection)
  );

  let showSection = internalShowSection;
  let setShowSection: (showSection: boolean) => void = setInternalShowSection;
  if (props.showSection !== undefined && props.setShowSection !== undefined) {
    showSection = props.showSection;
    setShowSection = props.setShowSection;
  }

  return (
    <div className={styles.wrapper({ marginBottom: props.marginBottom })}>
      <div
        className={styles.label}
        onClick={() => {
          setShowSection(!showSection);
        }}
      >
        {showSection ? (
          <Icon name="chevron-down" size="sm" />
        ) : (
          <Icon name="chevron-right" size="sm" />
        )}
        <div>{props.label}</div>
      </div>

      {showSection && props.children}
    </div>
  );
};

export default ExpandableSection;
