import {
  ConnectionPreviewSmallFragment,
  ConnectionType,
  ThirdPartyProvider,
} from "api/generated/graphql";

type ThirdPartyProviderInfo = {
  name: string;
  thirdPartyProvider: ThirdPartyProvider;
};

const thirdPartyProviderInfoByConnectionType: Partial<
  Record<ConnectionType, ThirdPartyProviderInfo>
> = {
  [ConnectionType.GitHub]: {
    name: "GitHub",
    thirdPartyProvider: ThirdPartyProvider.GitHub,
  },
  [ConnectionType.GitLab]: {
    name: "GitLab",
    thirdPartyProvider: ThirdPartyProvider.GitLab,
  },
};

export const getThirdPartyProviderInfoByConnectionType = (
  connectionType: ConnectionType | undefined
): ThirdPartyProviderInfo | undefined => {
  if (!connectionType) {
    return undefined;
  }
  return thirdPartyProviderInfoByConnectionType[connectionType];
};

export const thirdPartyProviderByConnectionType: Partial<
  Record<ConnectionType, ThirdPartyProvider>
> = {
  [ConnectionType.GitHub]: ThirdPartyProvider.GitHub,
  [ConnectionType.GitLab]: ThirdPartyProvider.GitLab,
};

export const getThirdPartyProviderByConnectionType = (
  connectionType: ConnectionType | undefined
): ThirdPartyProvider | undefined => {
  if (!connectionType) return undefined;
  return thirdPartyProviderByConnectionType[connectionType];
};

export const thirdPartyProviderNameByThirdPartyProvider: Partial<
  Record<ThirdPartyProvider, string>
> = {
  [ThirdPartyProvider.GitHub]: "GitHub",
  [ThirdPartyProvider.GitLab]: "GitLab",
};

export const connectionRequiresThirdPartyProvider = (connection: {
  connectionType: ConnectionType;
  metadata?: ConnectionPreviewSmallFragment["metadata"];
}) => {
  switch (connection.connectionType) {
    case ConnectionType.GitHub:
      return true;
    case ConnectionType.GitLab:
      // this only applies to GitLab SAAS
      if (
        connection.metadata?.__typename === "GitLabConnectionMetadata" &&
        !connection.metadata.hostName
      ) {
        // GitLab on-prem does not require a third party provider as it can
        // import users automatically
        return true;
      }
      return false;
    default:
      return false;
  }
};
