import {
  AccessReviewFragment,
  AccessReviewSummaryStatus,
  AccessReviewUserPreviewFragment,
  EntityType,
  useAccessReviewUsersQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import NavigationContext from "components/layout/NavigationContextProvider";
import { OnlyMyReviewsFilter } from "components/only_mine_filter/OnlyMineFilter";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import { Input } from "components/ui";
import React, { useContext } from "react";
import { logError } from "utils/logging";
import { filterSearchResults } from "utils/search/filter";
import AccessReviewProgressBar from "views/access_reviews/AccessReviewProgressBar";
import styles from "views/access_reviews/common/AccessReviewTableStyles.module.scss";
import { isReviewer } from "views/access_reviews/common/Common";
import AccessReviewUsersTable from "views/access_reviews/users/AccessReviewUsersTable";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ViewSkeleton from "views/loading/ViewSkeleton";

export const getAccessReviewUserId = (
  accessReviewUser: AccessReviewUserPreviewFragment
): string => {
  return `${accessReviewUser.accessReviewId}|${accessReviewUser.userId}`;
};

export const parseAccessReviewUserId = (accessReviewUserId: string) => {
  const parts = accessReviewUserId.split("|");
  return {
    accessReviewUserId: parts[0],
    userId: parts[1],
  };
};

type AccessReviewUsersProps = {
  accessReview: AccessReviewFragment;
};

const AccessReviewUsers = (props: AccessReviewUsersProps) => {
  const { navigationState } = useContext(NavigationContext);
  const { authState } = useContext(AuthContext);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const { accessReview } = props;

  const { data, error, loading } = useAccessReviewUsersQuery({
    variables: {
      input: {
        accessReviewId: props.accessReview.id,
      },
    },
  });

  let accessReviewUsers: AccessReviewUserPreviewFragment[] = [];
  if (data?.accessReviewUsers) {
    switch (data.accessReviewUsers.__typename) {
      case "AccessReviewUsersResult":
        if (navigationState.isOnlyMyReviewsFilterOn) {
          accessReviewUsers = data.accessReviewUsers.accessReviewUsers.filter(
            (accessReviewUser) => {
              // this should show all users where the current users is either a reviewer
              // or has the ability to assign a reviewer.
              return (
                authState.user?.user.id &&
                isReviewer(
                  authState.user.user.id,
                  accessReviewUser.reviewerUsers
                )
              );
            }
          );
        } else {
          accessReviewUsers = data.accessReviewUsers.accessReviewUsers;
        }
        break;
      default:
        logError(new Error(`failed to retrieve access review users`));
    }
  } else if (error) {
    logError(error, `failed to retrieve access review users`);
    return <UnexpectedErrorPage error={error} />;
  }

  if (loading) {
    return <ViewSkeleton />;
  }

  const filteredAccessReviewUsers = filterSearchResults(
    accessReviewUsers,
    searchQuery,
    (user: AccessReviewUserPreviewFragment) => [user.user?.fullName]
  );

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableTopContainer}>
        <div className={styles.tableTopLeftContent}>
          <Input
            value={searchQuery}
            onChange={(value) => setSearchQuery(value)}
            placeholder="Search users"
            leftIconName="search"
          />
        </div>
        <div className={styles.tableTopRightContent}>
          <OnlyMyReviewsFilter />
          <AccessReviewProgressBar
            accessReviewId={accessReview.id}
            numCompleted={
              accessReviewUsers.filter((accessReviewUser) => {
                const status = accessReviewUser.status;
                return (
                  status === AccessReviewSummaryStatus.Completed ||
                  status === AccessReviewSummaryStatus.NoReviewNeeded
                );
              }).length
            }
            numTotal={accessReviewUsers.length}
            entityType={EntityType.User}
          />
        </div>
      </div>
      <EmptyStateContentWrapper
        content={
          <AccessReviewUsersTable
            accessReview={accessReview}
            accessReviewUsers={filteredAccessReviewUsers}
          />
        }
        entityType={EntityType.User}
        title={`No users to review`}
        subtitle={
          navigationState.isOnlyMyReviewsFilterOn
            ? `No users have been assigned for you to review.`
            : `There are no users that require an access review.`
        }
        isEmpty={accessReviewUsers.length === 0}
      />
    </div>
  );
};

export default AccessReviewUsers;
