import { Header } from "components/tables/material_table/MuiVirtualTable";

export type UsersTableRow = {
  name: string;
  position: string;
  manager: string;
};

export const UsersTableHeaders: Header<UsersTableRow>[] = [
  {
    id: "name",
    label: "Name",
    width: 300,
    minWidth: 250,
  },
  {
    id: "position",
    label: "Title",
    width: 200,
    minWidth: 150,
  },
  {
    id: "manager",
    label: "Manager",
    width: 200,
    minWidth: 150,
  },
];
