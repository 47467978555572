import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewAction,
  AccessReviewGroupFragment,
  AccessReviewGroupPreviewFragment,
  AccessReviewGroupResourceFragment,
  EntityType,
  ResourcePreviewLargeFragment,
  ReviewerUserStatus,
  ReviewGroupResourceAction,
  useResourcesHomeQuery,
  useUpdateGroupResourceReviewersMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { EntityViewerTabType } from "components/entity_viewer/EntityViewerTabs";
import AccessReviewNoteLabel from "components/label/AccessReviewNoteLabel";
import ConnectionLabel from "components/label/item_labels/ConnectionLabel";
import { ResourceLabel } from "components/label/Label";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import NavigationContext from "components/layout/NavigationContextProvider";
import AccessReviewerModal from "components/modals/AccessReviewerModal";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import {
  CellRow,
  Header,
  ScrollableMuiVirtualTable,
} from "components/tables/material_table/MuiVirtualTable";
import { useToast } from "components/toast/Toast";
import { Popover } from "components/ui";
import { IconName } from "components/ui/icon/Icon";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { SortDirection } from "react-virtualized";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { logError } from "utils/logging";
import AccessReviewAcceptRevokeToggleButton, {
  calculateBatchAcceptRevokeState,
} from "views/access_reviews/AccessReviewAcceptRevokeToggleButton";
import AccessReviewContext, {
  AccessReviewContextActionType,
} from "views/access_reviews/AccessReviewContext";
import AccessReviewReviewerDetails, {
  accessReviewReviewerDetailsRowHeight,
} from "views/access_reviews/AccessReviewReviewerDetails";
import AccessReviewReviewersCell from "views/access_reviews/AccessReviewReviewersCell";
import {
  accessReviewItemStatusSortValue,
  AccessReviewOutcomeLabel,
  AccessReviewStatusLabel,
} from "views/access_reviews/AccessReviewStatus";
import {
  getReviewerModalEntries,
  getReviewersSortValue,
  isReviewer,
} from "views/access_reviews/common/Common";
import { getAccessReviewResourceUrl } from "views/access_reviews/common/Routes";
import {
  CONNECTION_HEADER,
  ITEM_ACTION_HEADER,
  ITEM_STATUS_HEADER,
  NOTE_HEADER,
  OUTCOME_HEADER,
  RESOURCE_NAME_HEADER,
  REVIEWERS_HEADER,
  ROLE_HEADER,
} from "views/access_reviews/common/TableHeaders";
import { AccessReviewResourceOverviewSection } from "views/access_reviews/resources/AccessReviewResourceOverviewRow";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ViewSkeleton from "views/loading/ViewSkeleton";

interface AccessReviewGroupResourceTableRow {
  name: string;
  role: string;
  connection: string;
  note?: string;
  reviewers?: string;
  status?: string;
  outcome?: string;
  acceptRevoke?: string;
}

type AccessReviewGroupResourcesTableProps = {
  accessReviewGroup: AccessReviewGroupFragment;
  setSelectedTab: (tab: EntityViewerTabType) => void;
  selectedRows?: Record<string, EntityType>;
  setSelectedRows?: (value: Record<string, EntityType>) => void;
};

export const AccessReviewGroupResourcesTable = (
  props: AccessReviewGroupResourcesTableProps
) => {
  const { navigationState } = useContext(NavigationContext);
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );
  const { authState } = useContext(AuthContext);
  const history = useHistory();

  const [expandedByRowId, setExpandedByRowId] = useState<
    Record<string, boolean>
  >({});

  // If the resource itself was not included in the access review,
  // we need to dynamically fetch the original resource to display under the group,
  // because the accessReviewResource object will not exist.
  const resourcesIdsToFetch: string[] = [];
  props.accessReviewGroup.groupResources?.forEach((groupResource) => {
    if (!groupResource.accessReviewResource) {
      resourcesIdsToFetch.push(groupResource.resourceId);
    }
  });

  const { data, loading, error } = useResourcesHomeQuery({
    variables: {
      input: {
        resourceIds: resourcesIdsToFetch,
      },
    },
    skip: resourcesIdsToFetch.length === 0,
  });

  if (loading) {
    return <ViewSkeleton />;
  }

  if (error) {
    return <UnexpectedErrorPage />;
  }

  const resourcesById: {
    [resourceId: string]: ResourcePreviewLargeFragment;
  } = {};
  data?.resources.resources.forEach(
    (resource) => (resourcesById[resource.id] = resource)
  );

  const canEditReviewers =
    accessReviewState.ongoingAccessReviewIdSet.has(
      props.accessReviewGroup.accessReviewId
    ) && props.accessReviewGroup.canEditReviewers;

  let resources = props.accessReviewGroup.groupResources || [];

  if (navigationState.isOnlyMyReviewsFilterOn) {
    resources = resources.filter((accessReviewGroupResource) => {
      return (
        authState.user?.user.id &&
        isReviewer(
          authState.user.user.id,
          accessReviewGroupResource.reviewerUsers ?? []
        )
      );
    });
  }

  const performReviewState =
    accessReviewState.performReviewStateByUARGroupId[
      props.accessReviewGroup.id
    ];
  const isGroupBeingReviewed = !!performReviewState;

  let currentUserHasCompleteReviews = false;
  if (isGroupBeingReviewed) {
    resources = resources.filter((resource) => {
      return resource.reviewerUsers?.find((reviewer) => {
        if (reviewer.userId === authState.user?.user.id) {
          currentUserHasCompleteReviews = true;
          return reviewer.status === ReviewerUserStatus.NotStarted;
        }
        return false;
      });
    });
  }
  /*
   * Figure out which columns to show depending on if the client is
   * performing reviews or viewing reviews.
   */

  // We always show these columns.
  const headers: Header<AccessReviewGroupResourceTableRow>[] = [
    RESOURCE_NAME_HEADER,
    ROLE_HEADER,
    CONNECTION_HEADER,
    REVIEWERS_HEADER,
  ];

  // These are the conditional columns.
  if (isGroupBeingReviewed && resources.length > 0) {
    const batchAcceptRevokeToggleButton = (
      <AccessReviewAcceptRevokeToggleButton
        state={calculateBatchAcceptRevokeState(
          performReviewState.groupResourceActions,
          resources.length
        )}
        acceptText={"Accept All"}
        revokeText={"Revoke All"}
        onStateChange={(state) => {
          if (!isGroupBeingReviewed) return;

          performReviewState.groupResourceActions.forEach(
            (groupResourceAction) => {
              groupResourceAction.action = state;
            }
          );

          resources.forEach((groupResource) => {
            const existingInfo = performReviewState.groupResourceActions.find(
              (groupResourceAction) =>
                groupResourceAction.accessReviewGroupResourceId ===
                groupResource.id
            );

            if (!existingInfo) {
              performReviewState.groupResourceActions.push({
                accessReviewGroupResourceId: groupResource.id,
                action: state,
              });
            }
          });

          accessReviewDispatch({
            type: AccessReviewContextActionType.AccessReviewItemUpdate,
            payload: {
              performReviewStateByUARGroupId: {
                ...accessReviewState.performReviewStateByUARGroupId,
                [props.accessReviewGroup.id]: performReviewState,
              },
            },
          });
        }}
      />
    );
    headers.push(
      {
        ...ITEM_ACTION_HEADER,
        customHeader: batchAcceptRevokeToggleButton,
      },
      NOTE_HEADER
    );
  } else {
    headers.push(ITEM_STATUS_HEADER, OUTCOME_HEADER);
  }

  /*
   * Table rows data
   */

  const groupResourceActionByUARGroupResourceId: Record<
    string,
    ReviewGroupResourceAction
  > = {};
  performReviewState?.groupResourceActions?.forEach((groupResourceAction) => {
    const id = groupResourceAction.accessReviewGroupResourceId;
    groupResourceActionByUARGroupResourceId[id] = groupResourceAction;
  });

  const rows: CellRow<AccessReviewGroupResourceTableRow>[] = resources.map(
    (groupResource, i) => {
      const rowId = groupResource.id;
      const groupResourceAction =
        groupResourceActionByUARGroupResourceId[rowId];
      const resource =
        groupResource.accessReviewResource?.resource ||
        resourcesById[groupResource.resourceId];
      const resourceName = resource?.name || groupResource.resourceId;
      const resourceType = resource?.resourceType;
      const resourceId =
        groupResource.accessReviewResource?.resourceId ||
        groupResource.resourceId;
      const status = groupResource.statusAndOutcome.status;
      const outcome = groupResource.statusAndOutcome.outcome;
      const connection = resource?.connection;
      const connectionId = resource?.connectionId;

      return {
        id: rowId,
        expandableContent: {
          content: (
            <AccessReviewReviewerDetails
              reviewers={groupResource.reviewerUsers}
            />
          ),
          expandedRowHeight: accessReviewReviewerDetailsRowHeight(
            groupResource.reviewerUsers
          ),
          isExpanded: expandedByRowId[rowId],
          setIsExpanded: (expanded) => {
            setExpandedByRowId({
              ...expandedByRowId,
              [rowId]: expanded,
            });
          },
        },
        data: {
          name: {
            value: groupResource.resourceId,
            element: (
              <Popover
                content={
                  resource ? (
                    <AccessReviewResourceOverviewSection
                      resource={resource}
                      createdAt={props.accessReviewGroup.createdAt}
                      disableEditor
                      hideBorder
                    />
                  ) : null
                }
                inline
              >
                <ResourceLabel
                  text={resourceName}
                  subText={getResourceTypeInfo(resourceType)?.name || "--"}
                  entityType={EntityTypeDeprecated.Resource}
                  resourceType={resourceType}
                  iconLarge={true}
                  bold={true}
                  icon={groupResource?.accessReviewResource?.resource?.iconUrl}
                  tooltipText={
                    resourceId
                      ? undefined
                      : "This resource is not included in the scope of this access review."
                  }
                  pointerCursor={true}
                />
              </Popover>
            ),
            clickHandler: () => {
              const url = groupResource.accessReviewResourceId
                ? getAccessReviewResourceUrl(
                    groupResource.accessReviewResourceId
                  )
                : undefined;
              if (url) {
                history.push(url);
              }
            },
          },
          role: {
            value: groupResource.accessLevel?.accessLevelName || "",
            element: (
              <div>{groupResource.accessLevel?.accessLevelName || "--"}</div>
            ),
          },
          connection: {
            sortValue: connection?.name || "",
            value: connectionId || "",
            element: (
              <ConnectionLabel
                text={connection?.name}
                connectionType={connection?.connectionType}
              />
            ),
          },
          reviewers: {
            value: getReviewersSortValue(groupResource.reviewerUsers ?? []),
            element: (
              <GroupResourceAccessReviewersCell
                accessReviewGroup={props.accessReviewGroup}
                groupResource={groupResource}
              />
            ),
          },
          note: {
            hide: !isGroupBeingReviewed,
            value: "",
            element: (
              <AccessReviewNoteLabel
                initNoteContent={groupResourceAction?.note}
                onSubmit={(updatedNoteContent) => {
                  if (!performReviewState) {
                    return;
                  }

                  const existingInfo = performReviewState.groupResourceActions.find(
                    (groupResourceAction) =>
                      groupResourceAction.accessReviewGroupResourceId ===
                      groupResource.id
                  );

                  if (existingInfo) {
                    existingInfo.note = updatedNoteContent;
                  } else {
                    performReviewState.groupResourceActions.push({
                      accessReviewGroupResourceId: groupResource.id,
                      action: AccessReviewAction.NoAction,
                      note: updatedNoteContent,
                    });
                  }
                }}
              />
            ),
            sortValue: i,
          },
          status: {
            value: status,
            sortValue: accessReviewItemStatusSortValue(status),
            element: (
              <AccessReviewStatusLabel
                entityType={EntityType.AccessReviewGroupResource}
                status={status}
                group={props.accessReviewGroup.group}
                resource={groupResource?.accessReviewResource?.resource}
                accessReviewId={props.accessReviewGroup.accessReviewId}
                canEditReviewers={canEditReviewers}
              />
            ),
          },
          outcome: {
            value: outcome,
            element: (
              <AccessReviewOutcomeLabel
                entityType={EntityType.AccessReviewGroupResource}
                outcome={outcome}
              />
            ),
          },
          acceptRevoke: {
            value: groupResourceAction?.action || AccessReviewAction.NoAction,
            element: (
              <AccessReviewAcceptRevokeToggleButton
                state={
                  groupResourceAction?.action || AccessReviewAction.NoAction
                }
                onStateChange={(state) => {
                  if (!performReviewState) {
                    return;
                  }

                  const existingInfo = performReviewState.groupResourceActions.find(
                    (groupResourceAction) =>
                      groupResourceAction.accessReviewGroupResourceId === rowId
                  );

                  if (existingInfo) {
                    existingInfo.action = state;
                  } else {
                    performReviewState.groupResourceActions.push({
                      accessReviewGroupResourceId: rowId,
                      action: state,
                    });
                  }
                  accessReviewDispatch({
                    type: AccessReviewContextActionType.AccessReviewItemUpdate,
                    payload: {
                      performReviewStateByUARGroupId: {
                        ...accessReviewState.performReviewStateByUARGroupId,
                        [props.accessReviewGroup.id]: performReviewState,
                      },
                    },
                  });
                }}
              />
            ),
          },
        },
      };
    }
  );

  let emptyStateTitle: string;
  let emptyStateSubtitle: string;
  let emptyStateButtonTitle: string | undefined;
  let emptyStateButtonIcon: IconName | undefined;
  let emptyStateOnClickHandler: (() => void) | undefined;
  if (isGroupBeingReviewed) {
    if (currentUserHasCompleteReviews) {
      emptyStateTitle = `No resources left to review`;
    } else {
      emptyStateTitle = `No resources to review`;
    }
    emptyStateSubtitle = `However, you have outstanding reviews for this group's users.`;
    emptyStateButtonTitle = `Review user access`;
    emptyStateButtonIcon = "check-circle";
    emptyStateOnClickHandler = () => {
      history.replace(`#${EntityViewerTabType.Users}`);
      props.setSelectedTab(EntityViewerTabType.Users);
    };
  } else {
    emptyStateTitle = `No resources to review`;
    emptyStateSubtitle = navigationState.isOnlyMyReviewsFilterOn
      ? `No resources have been assigned for you to review.`
      : `This group had no direct access to any resources at the time of the access review.`;
  }

  const onCheckedRowsChange = (ids: string[], checkboxState: boolean) => {
    if (props.selectedRows && props.setSelectedRows) {
      if (checkboxState) {
        const newSelectedRows = props.selectedRows;
        ids.forEach(
          (id) => (newSelectedRows[id] = EntityType.AccessReviewGroupResource)
        );
        props.setSelectedRows({ ...newSelectedRows });
      } else {
        const newSelectedRows = props.selectedRows;
        ids.forEach((id) => delete newSelectedRows[id]);
        props.setSelectedRows({ ...newSelectedRows });
      }
    }
  };

  // build checked row ids set
  const checkedRowIds = props.selectedRows
    ? new Set(Object.keys(props.selectedRows))
    : undefined;

  const numSelectedResourceRows = Object.values(
    props.selectedRows ?? {}
  ).reduce((count, entityType) => {
    return entityType === EntityType.AccessReviewGroupResource
      ? count + 1
      : count;
  }, 0);

  const selectAllChecked =
    numSelectedResourceRows === props.accessReviewGroup.numGroupResources;

  const onSelectAllChange = (checkboxState: boolean) => {
    if (props.selectedRows && props.setSelectedRows) {
      if (checkboxState) {
        // keep the old selected rows and add to it
        const newSelectedRows: Record<string, EntityType> = {
          ...props.selectedRows,
        };
        resources.forEach((groupResource) => {
          newSelectedRows[groupResource.id] =
            EntityType.AccessReviewGroupResource;
        });
        props.setSelectedRows({ ...newSelectedRows });
      } else {
        // Clear all resource rows by rebuilding selected rows without resource rows
        const newSelectedRows: Record<string, EntityType> = {};
        Object.entries(props.selectedRows).forEach(([id, entityType]) => {
          if (entityType !== EntityType.AccessReviewGroupResource) {
            newSelectedRows[id] = entityType;
          }
        });
        props.setSelectedRows({ ...newSelectedRows });
      }
    }
  };

  return (
    <EmptyStateContentWrapper
      content={
        <ScrollableMuiVirtualTable
          columns={headers}
          rows={rows}
          defaultSortBy={"name"}
          defaultSortDirection={SortDirection.ASC}
          allRowsLoaded
          expandable
          checkedRowIds={checkedRowIds}
          onCheckedRowsChange={onCheckedRowsChange}
          selectAllChecked={selectAllChecked}
          onSelectAllChange={onSelectAllChange}
        />
      }
      isEmpty={resources.length === 0}
      entityType={EntityType.Resource}
      title={emptyStateTitle}
      subtitle={emptyStateSubtitle}
      buttonTitle={emptyStateButtonTitle}
      buttonIcon={emptyStateButtonIcon}
      onClickHandler={emptyStateOnClickHandler}
    />
  );
};

type GroupResourcesAccessReviewersCellProps = {
  accessReviewGroup: AccessReviewGroupPreviewFragment;
  groupResource: AccessReviewGroupResourceFragment;
};

const GroupResourceAccessReviewersCell = (
  props: GroupResourcesAccessReviewersCellProps
) => {
  const { accessReviewState } = useContext(AccessReviewContext);
  const [showReviewersModal, setShowReviewersModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { displaySuccessToast } = useToast();

  const [
    updateGroupResourceReviewers,
    { loading },
  ] = useUpdateGroupResourceReviewersMutation();

  const canEditReviewers =
    accessReviewState.ongoingAccessReviewIdSet.has(
      props.accessReviewGroup.accessReviewId
    ) && props.accessReviewGroup.canEditReviewers;

  return (
    <>
      <AccessReviewReviewersCell
        itemType="group"
        reviewerUsers={props.groupResource.reviewerUsers}
        canEditReviewers={canEditReviewers}
        onClick={() => {
          setShowReviewersModal(true);
        }}
      />
      {showReviewersModal && (
        <AccessReviewerModal
          title={"Reviewers"}
          isModalOpen={showReviewersModal}
          onClose={() => {
            setShowReviewersModal(false);
            setErrorMessage("");
          }}
          loading={loading}
          onSubmit={async (reviewers) => {
            try {
              const { data } = await updateGroupResourceReviewers({
                variables: {
                  input: {
                    accessReviewGroupResourceId: props.groupResource.id,
                    reviewers: reviewers,
                  },
                },
                refetchQueries: ["AccessReviewGroup"],
              });
              switch (data?.updateGroupResourceReviewers.__typename) {
                case "UpdateGroupResourceReviewersResult": {
                  displaySuccessToast("Success: reviewers updated");
                  setShowReviewersModal(false);
                  break;
                }
                case "AccessReviewAlreadyStoppedError": {
                  setErrorMessage(data?.updateGroupResourceReviewers.message);
                  break;
                }
              }
            } catch (error) {
              logError(error, "failed to update access reviewers");
              setErrorMessage(
                getModifiedErrorMessage(
                  "Error: failed to update access reviewers",
                  error
                )
              );
            }
          }}
          errorMessage={errorMessage}
          entryInfos={getReviewerModalEntries(
            props.groupResource.reviewerUsers,
            null
          )}
          canEditReviewers={canEditReviewers}
        />
      )}
    </>
  );
};

export default AccessReviewGroupResourcesTable;
