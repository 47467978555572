import { EventForTableFragment } from "api/generated/graphql";
import { Modal } from "components/ui";
import pluralize from "pluralize";
import { useState } from "react";
import EventsTableV3 from "views/events/EventsTableV3";

import * as styles from "./AccessReviewEventsCell.css";

type Props = {
  events: EventForTableFragment[];
};

const AccessReviewEventsCell = (props: Props) => {
  const [showModal, setShowModal] = useState(false);

  if (!props.events.length) {
    return <div className={styles.noEventsLabel}>No Events</div>;
  }
  return (
    <>
      <div onClick={() => setShowModal(true)} className={styles.label}>
        {`${pluralize("Event", props.events.length, true)}`}
      </div>
      <Modal
        title="Events"
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        maxWidth="lg"
      >
        <Modal.Body>
          <EventsTableV3
            events={props.events}
            totalNumRows={props.events.length}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default AccessReviewEventsCell;
