import { EntityType } from "api/generated/graphql";
import { Label, Select } from "components/ui";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";

export const allPrincipalTypes = [EntityType.User, EntityType.Group];

interface PrincipalTypeDropdownProps {
  principalTypes: EntityType[] | undefined;
  setPrincipalTypesFilter: (principalTypes?: EntityType[] | undefined) => void;
  defaultLabel?: string;
}

const PrincipalTypeDropdown = (props: PrincipalTypeDropdownProps) => {
  let defaultLabel = "Filter by Principal Type";
  if (props.defaultLabel) {
    defaultLabel = props.defaultLabel;
  }
  const principalTypeToText = (principalType: EntityType | undefined) => {
    switch (principalType) {
      case EntityType.User:
        return "Users";
      case EntityType.Group:
        return "Groups";
    }
    return "";
  };

  const renderPrincipalTypeInputValue = (value?: EntityType[]) => {
    if (!value || value?.length === 0) {
      return defaultLabel;
    } else if (value?.length === 1) {
      return principalTypeToText(value[0]);
    } else {
      return (
        principalTypeToText(value[0]) +
        (value?.length > 1 ? ` + ${String(value.length - 1)} more` : "")
      );
    }
  };

  const handleSetPrincipalTypeFilter = (
    newPrincipalTypeFilter: EntityType[] | undefined
  ): void => {
    if (newPrincipalTypeFilter?.length === 0) {
      props.setPrincipalTypesFilter(undefined);
    } else {
      props.setPrincipalTypesFilter(newPrincipalTypeFilter);
    }
  };

  return (
    <Select<EntityType>
      multiple
      value={props.principalTypes}
      options={allPrincipalTypes}
      getOptionLabel={(principalType) => principalTypeToText(principalType)}
      getOptionSelected={(option, value) => {
        return option === value;
      }}
      alwaysShowPlaceholderIcon={true}
      onChange={handleSetPrincipalTypeFilter}
      getIcon={(principalType) => {
        if (principalType === EntityType.User) {
          return {
            type: "name",
            icon: "user",
            style: "rounded",
          } as IconData;
        } else if (principalType === EntityType.Group) {
          return {
            type: "name",
            icon: "users",
            style: "rounded",
          } as IconData;
        } else {
          throw new Error(`Unexpected principal type: ${principalType}`);
        }
      }}
      renderInputValue={renderPrincipalTypeInputValue}
      highlightWhenSelected={true}
      placeholder={renderPrincipalTypeInputValue(props.principalTypes)}
      placeholderIcon={{
        type: "name",
        icon: "user",
      }}
      listboxFooter={{
        footer: (
          <div
            className={sprinkles({
              fontFamily: "body",
              padding: "md",
            })}
          >
            {props.principalTypes && props.principalTypes.length > 0 ? (
              <Label
                label={`Clear selection (${props.principalTypes.length})`}
                color="blue600V3"
                onClick={() => handleSetPrincipalTypeFilter(undefined)}
              />
            ) : (
              <Label label="No items selected" color="gray700" />
            )}
          </div>
        ),
        sticky: true,
      }}
    />
  );
};

export default PrincipalTypeDropdown;
