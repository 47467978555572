import {
  ConnectionType,
  CreateResourceCustomAccessLevelInfo,
  EntityType,
  Maybe,
  ResourceAccessLevelsDocument,
  ResourceAccessLevelsQuery,
  ResourceCustomAccessLevelFragment,
  ResourceCustomAccessLevelsDocument,
  ResourceCustomAccessLevelsQuery,
  ResourceType,
  ServiceType,
  useCreateResourceCustomAccessLevelMutation,
  useCreateResourceCustomAccessLevelsMutation,
  useDeleteResourceCustomAccessLevelsMutation,
  useResourceCustomAccessLevelsQuery,
} from "api/generated/graphql";
import { Editor, EntityViewerRow } from "components/entity_viewer/EntityViewer";
import { ResourceLabel } from "components/label/Label";
import CreateRoleModal from "components/modals/update/CreateRoleModal";
import ImportRolesModal from "components/modals/update/ImportRolesModal";
import SelectItemsModal, {
  SelectType,
} from "components/modals/update/SelectItemsModal";
import { useToast } from "components/toast/Toast";
import { useState } from "react";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import { isGcpServiceType } from "utils/directory/resources";
import { logError } from "utils/logging";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ViewSkeleton from "views/loading/ViewSkeleton";
import ResourceCustomRolesTable from "views/resources/ResourceCustomRolesTable";

import { Role } from "../../../../components/modals/ResourceIndividualRoleModal";

type ResourceCustomRolesRowProps = {
  resource: {
    id: string;
    name: string;
    serviceType: ServiceType;
    connection?: {
      connectionType: ConnectionType;
    } | null;
    resourceType: ResourceType;
    authorizedActions?: string[] | null;
  };
};

// TODO: the policy format should be enforced from the backend, and properly validated as well.
export enum PolicyFormat {
  JSON = "json",
  SQL = "sql",
}

export const ResourceCustomRolesRow = (props: ResourceCustomRolesRowProps) => {
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showImportModal, setShowImportModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { data, error, loading } = useResourceCustomAccessLevelsQuery({
    variables: {
      input: {
        resourceId: props.resource.id,
      },
    },
    fetchPolicy: "cache-first",
  });

  let resourceCustomRoles: ResourceCustomAccessLevelFragment[] = [];

  if (data) {
    switch (data.resourceCustomAccessLevels.__typename) {
      case "ResourceCustomAccessLevelsResult":
        resourceCustomRoles =
          data.resourceCustomAccessLevels.resourceCustomAccessLevels;
        break;
      default:
        logError(new Error(`unexpected error listing resource policies`));
    }
  }

  if (loading) {
    return <ViewSkeleton />;
  }

  if (error) {
    logError(error, `unexpected error listing resource policies`);
    return <UnexpectedErrorPage error={error} />;
  }

  const menuOptions = [];

  const isGCPService =
    isGcpServiceType(props.resource.serviceType) ||
    props.resource.connection?.connectionType === ConnectionType.Gcp;

  if (
    !isGCPService &&
    props.resource.connection?.connectionType !== ConnectionType.AzureAd
  ) {
    menuOptions.push({
      label: "Create",
      handler: () => {
        setShowCreateModal(true);
      },
    });
  } else {
    menuOptions.push({
      label: "Import",
      handler: () => {
        setShowImportModal(true);
      },
    });
  }

  menuOptions.push({
    label: "Delete",
    handler: () => {
      setShowDeleteModal(true);
    },
  });

  const editor = <Editor menuOptions={menuOptions} />;

  const rolesTable = (
    <ResourceCustomRolesTable
      resourceCustomRoles={resourceCustomRoles}
      setShowCreateRoleModal={setShowCreateModal}
      setShowImportRoleModal={setShowImportModal}
      isGCPService={isGCPService}
      serviceType={props.resource.serviceType}
      resourceType={props.resource.resourceType}
    />
  );

  const canManage = props.resource.authorizedActions?.includes(
    AuthorizedActionManage
  );

  return (
    <EntityViewerRow
      title={"Roles"}
      content={rolesTable}
      isTable={true}
      adminEditor={editor}
      canEdit={canManage}
      modals={
        <>
          {showCreateModal && (
            <CreateRoleWrapperModal
              resource={props.resource}
              showCreateModal={showCreateModal}
              setShowCreateModal={setShowCreateModal}
              resourceCustomRoles={resourceCustomRoles}
            />
          )}
          {showImportModal && (
            <ImportRoleWrapperModal
              resource={props.resource}
              showImportModal={showImportModal}
              setShowImportModal={setShowImportModal}
              resourceCustomRoles={resourceCustomRoles}
            />
          )}
          {showDeleteModal && (
            <DeleteRoleWrapperModal
              resourceCustomRoles={resourceCustomRoles}
              showDeleteModal={showDeleteModal}
              setShowDeleteModal={setShowDeleteModal}
              resourceType={props.resource.resourceType}
            />
          )}
        </>
      }
    />
  );
};

type CreateRoleModalProps = {
  resourceCustomRoles: ResourceCustomAccessLevelFragment[];
  resource: {
    id: string;
    name: string;
    resourceType: ResourceType;
    serviceType: ServiceType;
  };
  showCreateModal: boolean;
  setShowCreateModal: (showModal: boolean) => void;
};

export const CreateRoleWrapperModal = (props: CreateRoleModalProps) => {
  const { displaySuccessToast } = useToast();
  const logEvent = useLogEvent();

  const [roleName, setRoleName] = useState("");
  const [roleRemoteId, setRoleRemoteId] = useState("");
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [
    createResourceCustomRole,
    { loading },
  ] = useCreateResourceCustomAccessLevelMutation();
  const defaultPolicyContent = getDefaultPolicyContent(props.resource);
  const [policyContent, setPolicyContent] = useState(defaultPolicyContent);

  const createModalReset = () => {
    props.setShowCreateModal(false);
    setErrorMessage(null);
  };

  const roleDescriptionInfo = getRoleDescriptionInfo(
    props.resource.serviceType,
    props.resource.resourceType
  );

  return (
    <CreateRoleModal
      key={"create_role_modal"}
      isModalOpen={props.showCreateModal}
      roleName={roleName}
      roleRemoteId={roleRemoteId}
      setRoleName={setRoleName}
      setRoleRemoteId={setRoleRemoteId}
      roleDescriptionInfo={roleDescriptionInfo}
      onClose={() => {
        createModalReset();
      }}
      onSubmit={async () => {
        if (
          props.resourceCustomRoles.some(
            (role) => role.accessLevel.accessLevelRemoteId === roleRemoteId
          )
        ) {
          setErrorMessage(`Error: that role remote ID has already been used.`);
          return;
        }
        if (
          policyContent &&
          roleDescriptionInfo.policyFormat === PolicyFormat.JSON
        ) {
          try {
            JSON.stringify(JSON.parse(policyContent));
          } catch (error) {
            setErrorMessage(
              `Error: failed to parse policy. Please ensure that it's valid JSON.`
            );
            return;
          }
        }

        logEvent({
          name: "apps_create_role",
          properties: {
            resourceType: props.resource.resourceType,
          },
        });

        try {
          const { data } = await createResourceCustomRole({
            variables: {
              input: {
                resourceId: props.resource.id,
                accessLevel: {
                  accessLevelName: roleName,
                  accessLevelRemoteId: roleRemoteId,
                },
                policy: policyContent,
              },
            },
            refetchQueries: ["ResourceCustomAccessLevels"],
            update: (cache, { data }) => {
              switch (data?.createResourceCustomAccessLevel.__typename) {
                case "CreateResourceCustomAccessLevelResult": {
                  const createdRole =
                    data.createResourceCustomAccessLevel
                      .resourceCustomAccessLevel;

                  let cachedResourceRolesQuery = cache.readQuery<ResourceAccessLevelsQuery>(
                    {
                      query: ResourceAccessLevelsDocument,
                      variables: {
                        input: {
                          resourceId: props.resource.id,
                        },
                      },
                    }
                  );
                  if (cachedResourceRolesQuery) {
                    switch (cachedResourceRolesQuery.accessLevels.__typename) {
                      case "ResourceAccessLevelsResult":
                        cache.writeQuery<ResourceAccessLevelsQuery>({
                          query: ResourceAccessLevelsDocument,
                          variables: {
                            input: {
                              resourceId: props.resource.id,
                            },
                          },
                          data: {
                            ...cachedResourceRolesQuery,
                            accessLevels: {
                              ...cachedResourceRolesQuery.accessLevels,
                              accessLevels: [
                                ...cachedResourceRolesQuery.accessLevels
                                  .accessLevels,
                                createdRole.accessLevel,
                              ],
                            },
                          },
                        });
                    }
                  }
                }
              }
            },
          });
          switch (data?.createResourceCustomAccessLevel.__typename) {
            case "CreateResourceCustomAccessLevelResult":
              createModalReset();
              displaySuccessToast("Success: resource custom role created");
              break;
            case "ResourceCustomAccessLevelPriorityError":
              logError(new Error(data.createResourceCustomAccessLevel.message));
              setErrorMessage(data.createResourceCustomAccessLevel.message);
              break;
            case "ResourceCustomAccessLevelAlreadyExistsError":
              logError(new Error(data.createResourceCustomAccessLevel.message));
              setErrorMessage(data.createResourceCustomAccessLevel.message);
              break;
            default:
              logError(new Error(`failed to create resource custom role`));
              setErrorMessage(`Error: failed to create resource custom role`);
          }
        } catch (error) {
          logError(error, `failed to create resource custom role`);
          setErrorMessage(`Error: failed to create resource custom role`);
        }
      }}
      errorMessage={errorMessage}
      loading={loading}
      setPolicyContent={setPolicyContent}
      policyContent={policyContent}
      resourceCustomRolesCount={props.resourceCustomRoles.length}
    />
  );
};

type ImportRoleModalProps = {
  resource: {
    id: string;
    resourceType: ResourceType;
  };
  showImportModal: boolean;
  setShowImportModal: (showModal: boolean) => void;
  resourceCustomRoles: ResourceCustomAccessLevelFragment[];
};

const ImportRoleWrapperModal = (props: ImportRoleModalProps) => {
  const { displaySuccessToast } = useToast();
  const logEvent = useLogEvent();

  const [updatedRolesByEntityId, setUpdatedRolesByEntityId] = useState<
    Record<string, Role[]>
  >({});
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [
    createResourceCustomAccessLevels,
    { loading },
  ] = useCreateResourceCustomAccessLevelsMutation();

  const existingDirectRolesByEntityId: Record<string, Role[]> = {};
  props.resourceCustomRoles.forEach((customAccessLevel) => {
    const role = {
      accessLevelName: customAccessLevel.accessLevel.accessLevelName,
      accessLevelRemoteId: customAccessLevel.accessLevel.accessLevelRemoteId,
    };
    if (!existingDirectRolesByEntityId[props.resource.id]) {
      existingDirectRolesByEntityId[props.resource.id] = [];
    }
    existingDirectRolesByEntityId[props.resource.id].push(role);
  });

  const createModalReset = () => {
    props.setShowImportModal(false);
    setErrorMessage(null);
  };

  const createInfos: CreateResourceCustomAccessLevelInfo[] = [];
  for (const [resourceId, roles] of Object.entries(updatedRolesByEntityId)) {
    roles.forEach((role) => {
      createInfos.push({
        resourceId: resourceId,
        accessLevel: {
          accessLevelName: role.accessLevelName,
          accessLevelRemoteId: role.accessLevelRemoteId,
        },
      });
    });
  }

  return (
    <ImportRolesModal
      title={"Import roles"}
      isModalOpen={props.showImportModal}
      existingDirectRolesByEntityId={existingDirectRolesByEntityId}
      updatedRolesByEntityId={updatedRolesByEntityId}
      setUpdatedRolesByEntityId={setUpdatedRolesByEntityId}
      entityId={props.resource.id}
      onClose={() => {
        createModalReset();
      }}
      onSubmit={async () => {
        logEvent({
          name: "apps_import_role",
          properties: {
            resourceType: props.resource.resourceType,
          },
        });
        try {
          const { data } = await createResourceCustomAccessLevels({
            variables: {
              input: {
                createInfos: createInfos,
              },
            },
            update: (cache, { data }) => {
              switch (data?.createResourceCustomAccessLevels.__typename) {
                case "CreateResourceCustomAccessLevelsResult": {
                  data.createResourceCustomAccessLevels.entries.forEach(
                    (entry) => {
                      switch (entry.resourceCustomAccessLevel.__typename) {
                        case "ResourceCustomAccessLevel": {
                          const createdRole = entry.resourceCustomAccessLevel;

                          let cachedResourceCustomRolesQuery = cache.readQuery<ResourceCustomAccessLevelsQuery>(
                            {
                              query: ResourceCustomAccessLevelsDocument,
                              variables: {
                                input: {
                                  resourceId: props.resource.id,
                                },
                              },
                            }
                          );
                          if (cachedResourceCustomRolesQuery) {
                            cache.writeQuery<ResourceCustomAccessLevelsQuery>({
                              query: ResourceCustomAccessLevelsDocument,
                              variables: {
                                input: {
                                  resourceId: props.resource.id,
                                },
                              },
                              data: {
                                ...cachedResourceCustomRolesQuery,
                                resourceCustomAccessLevels: {
                                  ...cachedResourceCustomRolesQuery.resourceCustomAccessLevels,
                                  resourceCustomAccessLevels: [
                                    ...cachedResourceCustomRolesQuery
                                      .resourceCustomAccessLevels
                                      .resourceCustomAccessLevels,
                                    createdRole,
                                  ],
                                },
                              },
                            });
                          }

                          let cachedResourceRolesQuery = cache.readQuery<ResourceAccessLevelsQuery>(
                            {
                              query: ResourceAccessLevelsDocument,
                              variables: {
                                input: {
                                  resourceId: props.resource.id,
                                },
                              },
                            }
                          );
                          if (cachedResourceRolesQuery) {
                            switch (
                              cachedResourceRolesQuery.accessLevels.__typename
                            ) {
                              case "ResourceAccessLevelsResult":
                                cache.writeQuery<ResourceAccessLevelsQuery>({
                                  query: ResourceAccessLevelsDocument,
                                  variables: {
                                    input: {
                                      resourceId: props.resource.id,
                                    },
                                  },
                                  data: {
                                    ...cachedResourceRolesQuery,
                                    accessLevels: {
                                      ...cachedResourceRolesQuery.accessLevels,
                                      accessLevels: [
                                        ...cachedResourceRolesQuery.accessLevels
                                          .accessLevels,
                                        createdRole.accessLevel,
                                      ],
                                    },
                                  },
                                });
                                break;
                            }
                          }
                          break;
                        }
                      }
                    }
                  );
                }
              }
            },
          });
          switch (data?.createResourceCustomAccessLevels.__typename) {
            case "CreateResourceCustomAccessLevelsResult":
              createModalReset();
              displaySuccessToast("Success: resource custom roles imported");
              break;
            default:
              logError(new Error(`failed to import resource custom roles`));
              setErrorMessage(`Error: failed to import resource custom roles`);
          }
        } catch (error) {
          logError(error, `failed to import resource custom roles`);
          setErrorMessage(`Error: failed to import resource custom roles`);
        }
      }}
      errorMessage={errorMessage || undefined}
      loading={loading}
      submitDisabled={Object.keys(updatedRolesByEntityId).length === 0}
    />
  );
};

type DeleteRoleModalProps = {
  resourceCustomRoles: ResourceCustomAccessLevelFragment[];
  resourceType: ResourceType;
  showDeleteModal: boolean;
  setShowDeleteModal: (showModal: boolean) => void;
};

const DeleteRoleWrapperModal = (props: DeleteRoleModalProps) => {
  const { displaySuccessToast } = useToast();
  const logEvent = useLogEvent();

  const [idsToDelete, setIdsToDelete] = useState<string[]>([]);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [
    deleteResourceCustomAccessLevels,
    { loading },
  ] = useDeleteResourceCustomAccessLevelsMutation();

  const deleteModalReset = () => {
    props.setShowDeleteModal(false);
    setIdsToDelete([]);
    setErrorMessage(null);
  };

  return (
    <SelectItemsModal
      key={"delete_role_modal"}
      title={"Delete roles"}
      selectType={SelectType.Delete}
      itemName={"role"}
      infoMessage={
        "Deleting a role will remove access for all users and groups using that role. Access will be removed from both Opal and the end system."
      }
      entryInfos={props.resourceCustomRoles
        .slice()
        .sort((a, b) => {
          if (a && b) {
            return a.accessLevel.accessLevelName.localeCompare(
              b.accessLevel.accessLevelName
            );
          }
          return 0;
        })
        .map((role) => {
          return {
            entityId: {
              entityId: role.id,
              entityType: EntityType.AccessLevel,
            },
            label: (
              <ResourceLabel
                text={role.accessLevel.accessLevelName}
                maxChars={32}
                entityTypeNew={EntityType.AccessLevel}
              />
            ),
            isBold: false,
            isBreadcrumb: false,
          };
        })}
      idsToUpdate={idsToDelete}
      setIdsToUpdate={setIdsToDelete}
      isModalOpen={props.showDeleteModal}
      onClose={() => {
        deleteModalReset();
      }}
      onSubmit={async () => {
        logEvent({
          name: "apps_delete_role",
          properties: {
            resourceType: props.resourceType,
          },
        });

        try {
          const { data } = await deleteResourceCustomAccessLevels({
            variables: {
              input: {
                ids: idsToDelete,
              },
            },
            refetchQueries: ["ResourceCustomAccessLevels"],
          });
          switch (data?.deleteResourceCustomAccessLevels.__typename) {
            case "DeleteResourceCustomAccessLevelsResult":
              deleteModalReset();
              displaySuccessToast("Success: resource custom roles deleted");
              break;
            default:
              logError(new Error(`failed to delete resource custom roles`));
              setErrorMessage(`Error: failed to delete resource custom roles`);
          }
        } catch (error) {
          logError(error, `failed to delete resource custom roles`);
          setErrorMessage(`Error: failed to delete resource custom roles`);
        }
      }}
      errorMessage={errorMessage}
      loading={loading}
    />
  );
};

const getDefaultPolicyContent = (resource: { name: string }) => {
  let defaultPolicyContent = null;

  // TODO: change this to use something more structured than name
  if (resource.name.toLowerCase().includes("salesforce")) {
    defaultPolicyContent = `{
    "role": "-- No Role --",
    "profile": "",
    "salesforceGroups": [],
    "featureLicenses": [],
    "publicGroups": []
}`;
    defaultPolicyContent = JSON.stringify(
      JSON.parse(defaultPolicyContent),
      null,
      2
    );
  }

  return defaultPolicyContent;
};

export type RoleDescriptionInfo = {
  roleNameDescription: string;
  roleRemoteIdDescription: string;
  policyDescription: string;
  includePolicy: boolean;
  policyFormat: PolicyFormat;
};

export const getRoleDescriptionInfo = (
  serviceType: ServiceType,
  resourceType: ResourceType
): RoleDescriptionInfo => {
  let roleNameDescription = "A human readable name for this role";
  let roleRemoteIdDescription =
    "A unique (across this resource) identifier for this role";
  let policyDescription = "";
  let includePolicy = false;
  let policyFormat = PolicyFormat.JSON;

  switch (serviceType) {
    case ServiceType.Kubernetes:
      roleNameDescription =
        'A human readable name for the role used to access this cluster (e.g. "ClusterAdmin").';
      roleRemoteIdDescription =
        'The remote ID of the role used to access this cluster (e.g. "arn:aws:iam::23404986318:role/ClusterAdmin").';
      break;
    case ServiceType.Postgres:
    case ServiceType.Mysql:
    case ServiceType.Mariadb:
      roleNameDescription =
        'A human readable name for the database user used to access this database (e.g. "Read-Only").';
      roleRemoteIdDescription =
        'The name of the database user used to access this database (e.g. "readonly").';
      if (
        resourceType === ResourceType.MariadbInstance ||
        resourceType === ResourceType.MysqlInstance ||
        resourceType === ResourceType.PostgresInstance
      ) {
        includePolicy = true;
        policyDescription =
          "A SQL statement that gets executed when creating the temporary user and grants access to schemas in the database.";
        policyFormat = PolicyFormat.SQL;
      }
      break;
    case ServiceType.Mongo:
    case ServiceType.MongoAtlas:
      roleNameDescription =
        'A human readable name for the type of user used to access this database (e.g. "Read-Only").';
      roleRemoteIdDescription =
        'A unique (across this database) identifier for the type of user used to access this database (e.g. "readonly").';
      policyDescription =
        "A JSON template to describe the authorization capabilities of the user that is dynamically create a database user to access this database.";
      includePolicy = true;
      break;
    case ServiceType.OktaDirectory:
      roleNameDescription =
        'A human readable name that describes the role for this Okta app (e.g. "Admin").';
      roleRemoteIdDescription =
        'A unique (across this Okta app) identifier for the role for this Okta app (e.g. "admin").';
      policyDescription =
        "A JSON template to describe the authorization capabilities of the user for this Okta app.";
      includePolicy = true;
      break;
  }

  return {
    roleNameDescription: roleNameDescription,
    roleRemoteIdDescription: roleRemoteIdDescription,
    policyDescription: policyDescription,
    includePolicy: includePolicy,
    policyFormat: policyFormat,
  };
};

export default ResourceCustomRolesRow;
