import { EntityType, Maybe } from "api/generated/graphql";
import slackLogo from "assets/logos/slack-logo.svg";
import { ResourceLabel } from "components/label/Label";
import styles from "components/modals/ReviewerEscalationFlowModal.module.scss";
import { MoreInfo } from "components/more_info/MoreInfo";
import { Modal } from "components/ui";
import pluralize from "pluralize";
import React, { ReactElement } from "react";
import * as Icons from "react-feather";
import { getNumberWithOrdinal } from "utils/common";

const getPrioritySubtext = (
  priority?: number,
  escalationTime?: number | null
) => {
  let subtext = "Reviewer";
  if (priority && priority > 0 && escalationTime) {
    if (priority === 1) {
      subtext = "Primary reviewer";
    } else {
      subtext = `${getNumberWithOrdinal(priority)} to be notified`;
    }
  }

  return subtext;
};

export const getEscalationPeriodText = (
  accessRequestEscalationPeriodInMinutes: number
) => {
  if (accessRequestEscalationPeriodInMinutes < 60) {
    return `After ${pluralize(
      "minute",
      accessRequestEscalationPeriodInMinutes,
      true
    )}`;
  }
  return `After ${pluralize(
    "hour",
    accessRequestEscalationPeriodInMinutes / 60,
    true
  )}`;
};

export type ReviewerEscalationFlowModalProps = {
  accessRequestEscalationPeriodInMinutes?: number | null;
  reviewerMessageChannel?: {
    name: string;
  } | null;
  reviewers: Array<{
    id?: string;
    fullName?: string;
    avatarUrl?: string;
    priority?: number;
  }>;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

export const ReviewerEscalationFlowModal = (
  props: ReviewerEscalationFlowModalProps
) => {
  let escalationTimeContent: Maybe<ReactElement> = null;
  let escalationPeriodInMinutes: Maybe<number> = null;
  if (
    props.accessRequestEscalationPeriodInMinutes !== undefined &&
    props.accessRequestEscalationPeriodInMinutes !== null
  ) {
    escalationPeriodInMinutes = props.accessRequestEscalationPeriodInMinutes;
  }
  if (escalationPeriodInMinutes) {
    escalationTimeContent = (
      <>
        <FlowArrow />
        <FlowItem
          content={`${getEscalationPeriodText(
            escalationPeriodInMinutes
          )}, if request is not actioned, notifies...`}
        />
      </>
    );
  }

  let reviewerMessageChannelContent: Maybe<ReactElement> = null;
  const reviewerMessageChannel = props.reviewerMessageChannel;
  if (reviewerMessageChannel) {
    reviewerMessageChannelContent = (
      <>
        <FlowArrow />
        <ResourceLabel
          text={reviewerMessageChannel.name}
          icon={slackLogo}
          subText={"Reviewer Slack channel"}
        />
      </>
    );
  }

  // If escalation time is none, we only ever notify the 1st in line.
  let reviewersToShow = props.reviewers.slice().sort((a, b) => {
    if (a.priority && b.priority) return a.priority - b.priority;
    return 0;
  });

  let notifyEveryone = true;
  if (escalationPeriodInMinutes == null && reviewerMessageChannel) {
    reviewersToShow = [];
  } else if (escalationPeriodInMinutes) {
    notifyEveryone = false;
  }

  return (
    <Modal
      isOpen={props.showModal}
      onClose={() => {
        props.setShowModal(false);
      }}
      title="Reviewer Escalation Flow"
    >
      <Modal.Body>
        <div className={styles.content}>
          {!reviewerMessageChannel && props.reviewers.length === 0 ? (
            `This team has no reviewers, so there is nobody to be notified.`
          ) : (
            <>
              <FlowItem
                content={`When a request is made involving this team, notifies...`}
              />
              {reviewerMessageChannelContent}
              <div
                className={
                  notifyEveryone
                    ? styles.reviewersNoEscalation
                    : styles.reviewersWithEscalation
                }
              >
                {reviewersToShow.slice().map((reviewer, i) => {
                  const priorityText = getPrioritySubtext(
                    reviewer.priority,
                    escalationPeriodInMinutes
                  );
                  return (
                    <>
                      {!notifyEveryone && (
                        <>
                          {(i !== 0 || reviewerMessageChannel) &&
                            escalationTimeContent}
                          <FlowArrow />
                        </>
                      )}
                      <ResourceLabel
                        text={reviewer.fullName}
                        subText={priorityText}
                        entityTypeNew={EntityType.User}
                        entityId={reviewer.id}
                        avatar={reviewer.avatarUrl}
                        tooltipText={`The ${priorityText.toLowerCase()} is DM'd and/or emailed, depending on their notifications settings.`}
                      />
                    </>
                  );
                })}
              </div>
            </>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Close"
        onPrimaryButtonClick={() => {
          props.setShowModal(false);
        }}
      />
    </Modal>
  );
};

type FlowItemProps = {
  content: string;
  tooltipText?: string;
};

const FlowItem = (props: FlowItemProps) => {
  return (
    <div className={styles.flowItem}>
      <div>{props.content}</div>
      {props.tooltipText && <MoreInfo tooltipText={props.tooltipText} />}
    </div>
  );
};

const FlowArrow = () => {
  return (
    <div className={styles.flowArrow}>
      <div className={styles.flowArrowIcon}>
        <Icons.ArrowDown size={20} strokeWidth={2} />
      </div>
    </div>
  );
};

export default ReviewerEscalationFlowModal;
