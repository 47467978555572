import ImgWithFallback from "components/ui/image/ImgWithFallback";

import * as styles from "./Avatar.css";

export const defaultAvatarURL =
  "https://opal-landing-page.s3-us-west-1.amazonaws.com/default-profile-pic.jpg";

type AvatarProps = {
  url?: string;
  size: "large" | "medium" | "normal" | "small";
  inactive?: boolean;
};

const Avatar = (props: AvatarProps) => {
  return (
    <ImgWithFallback
      className={styles.avatar({
        size: props.size,
        inactive: props.inactive ?? false,
      })}
      alt=""
      src={props.url || defaultAvatarURL}
      fallbackSrc={defaultAvatarURL}
    />
  );
};

export default Avatar;
