import { formatTimestampForDisplay } from "api/common/common";
import {
  EventFragment,
  EventType,
  PropagationStatusCode,
  PropagationTaskType,
  RequestFragment,
  SupportTicketPreviewFragment,
  useEventsFullQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import PropagationStatusModal, {
  PropagationStatusInfo,
} from "components/propagation/PropagationStatusModal";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { logError } from "utils/logging";
import { useReadUINotification } from "utils/notifications";
import { PropagationType } from "utils/useRemediations";
import {
  RequestBanner,
  RequestGroupBindingBanner,
} from "views/requests/RequestBanner";
import { RequestEventsTable } from "views/requests/RequestEventsTable";
import { RequestInfo } from "views/requests/RequestInfo";
import { RequestProgressBar } from "views/requests/RequestProgressBar";
import { RequestProgressStages } from "views/requests/RequestProgressStages";

interface OverviewProps {
  request: RequestFragment;
  refetchRequest: Function;
  setRequestProgressStages?: (bar: JSX.Element) => void;
}

const RequestOverview = (props: OverviewProps) => {
  const { canAdminReview, isReviewer } = useRequestPermissions(props.request);
  const [showPropagationStatusModal, setShowPropagationStatusModal] = useState(
    false
  );

  let events: EventFragment[] = [];
  const {
    data,
    error,
    refetch: refetchEvents,
    loading: eventsLoading,
  } = useEventsFullQuery({
    variables: {
      input: {
        filter: {
          objects: {
            objectId: props.request.id,
          },
        },
      },
    },
  });
  if (data?.events?.__typename === "EventsResult") {
    events = data.events.events.map((event) => ({
      ...event,
      createdAt: formatTimestampForDisplay(event.createdAt),
    }));
  } else if (error) {
    logError(error, `failed to list events`);
  }

  const refetchRequest = props.refetchRequest;

  useEffect(() => {
    // If the request was made less than 5 seconds ago, refresh the request in a
    // few seconds to try to load request-creation events (i.e. since requester/reviewer
    // notifications happen async from request creation).
    if (
      moment().diff(moment(new Date(props.request.createdAt)), "seconds") < 5
    ) {
      // Do it twice in case 2 seconds is too quick for a check. This lets us hedge against
      // being fast. We could alternatively refetch until {5 seconds, new events returned}, but that
      // seems more complicated than necessary.
      setTimeout(() => refetchEvents(), 2 * 1000);
      setTimeout(() => refetchEvents(), 4 * 1000);
      // Refetch the request, so that we can show the request ticket copy if it's been created.
      setTimeout(() => refetchRequest(), 4 * 1000);
    }
  }, [props.request, refetchRequest, refetchEvents]);

  useReadUINotification(props.request.id);

  // for now just aggregate prop statuses
  let propagationStatusCode: PropagationStatusCode | undefined;
  const unfilteredStatuses = [
    ...props.request.requestedResources,
    ...props.request.requestedGroups,
  ].map((entity) => entity.propagationStatus);
  const statuses = unfilteredStatuses
    .filter((status) => Boolean(status))
    .map((status) => status?.statusCode);
  if (statuses.length > 0) {
    const allSuccess = statuses.every(
      (status) => status === PropagationStatusCode.Success
    );
    const anyPending = statuses.some(
      (status) =>
        status === PropagationStatusCode.Pending ||
        status === PropagationStatusCode.PendingTicketCreation
    );
    const anyPendingManualPropagation = statuses.some(
      (status) => status === PropagationStatusCode.PendingManualPropagation
    );
    if (allSuccess) {
      propagationStatusCode = PropagationStatusCode.Success;
    } else if (anyPending) {
      propagationStatusCode = PropagationStatusCode.Pending;
    } else if (anyPendingManualPropagation) {
      propagationStatusCode = PropagationStatusCode.PendingManualPropagation;
    } else {
      propagationStatusCode = PropagationStatusCode.ErrUnknown;
    }
  }

  let propagationTaskType: PropagationTaskType | undefined;
  const taskTypes = unfilteredStatuses
    .filter((status) => Boolean(status))
    .map((status) => status?.taskType);
  if (taskTypes.length > 0) {
    const allCreateUsers = taskTypes.every(
      (taskType) =>
        taskType === PropagationTaskType.ResourcesCreateUsers ||
        taskType === PropagationTaskType.GroupsCreateUsers
    );
    if (allCreateUsers) {
      propagationTaskType = taskTypes[0];
    } else {
      propagationTaskType = taskTypes.find(
        (taskType) =>
          taskType !== PropagationTaskType.ResourcesCreateUsers &&
          taskType !== PropagationTaskType.GroupsCreateUsers
      );
    }
  }

  // Build propagation statuses from requested groups and requested resources
  const propagationStatusInfos: PropagationStatusInfo[] = [];
  props.request.requestedResources.forEach((requestedResource) => {
    const propStatusInfo: PropagationStatusInfo = {
      propagationType: PropagationType.ResourceUser,
      propagationStatus: requestedResource.propagationStatus,
      propagationTickets: requestedResource.propagationStatus?.metadata?.ticket
        ? [requestedResource.propagationStatus.metadata.ticket]
        : undefined,
      isAccessReview: false,
      entityInfo: {
        roleAssignmentKey:
          requestedResource.resourceId +
          props.request.requesterId +
          requestedResource.accessLevel.accessLevelRemoteId,
        resource: requestedResource.resource,
        user: props.request.requester,
        role: requestedResource.accessLevel,
      },
    };

    propagationStatusInfos.push(propStatusInfo);
  });

  props.request.requestedGroups.forEach((requestedGroup) => {
    const propStatusInfo = {
      propagationType: PropagationType.GroupUser,
      propagationStatus: requestedGroup.propagationStatus,
      propagationTickets:
        requestedGroup.propagationStatus?.ticketPropagation?.resourceTickets
          .filter((resourceTicket) => Boolean(resourceTicket))
          .map(
            (resourceTicket) =>
              resourceTicket.ticket as SupportTicketPreviewFragment
          ) ?? [],
      isAccessReview: false,
      entityInfo: {
        roleAssignmentKey: requestedGroup.groupId + props.request.requesterId,
        group: requestedGroup.group,
        user: props.request.requester,
      },
    };

    propagationStatusInfos.push(propStatusInfo);
  });

  const accessRevoked = Boolean(
    events.find((event) =>
      event.subEvents.find(
        (subEvent) =>
          subEvent.subEventType === EventType.UsersRemovedFromGroups ||
          subEvent.subEventType === EventType.UsersRemovedFromResources
      )
    )
  );
  const { request, setRequestProgressStages } = props;
  useEffect(() => {
    if (setRequestProgressStages) {
      setRequestProgressStages(
        <RequestProgressStages
          request={request}
          accessRevoked={accessRevoked}
          propagationStatusCode={propagationStatusCode}
          setShowPropagationStatusModal={setShowPropagationStatusModal}
        />
      );
    }
  }, [accessRevoked, propagationStatusCode, request, setRequestProgressStages]);

  return (
    <div style={{ width: "100%" }}>
      <div className={sprinkles({ margin: "md" })}>
        <RequestProgressBar
          request={props.request}
          events={events}
          propagationStatusCode={propagationStatusCode}
          propagationTaskType={propagationTaskType}
          setShowPropagationStatusModal={setShowPropagationStatusModal}
        />
        <RequestBanner
          request={props.request}
          propagationStatusCode={propagationStatusCode}
          propagationTaskType={propagationTaskType}
          propagationStatusInfos={propagationStatusInfos}
          setShowPropagationStatusModal={setShowPropagationStatusModal}
        />
        <RequestGroupBindingBanner request={props.request} />
        <RequestInfo
          request={props.request}
          currentUserIsReviewer={Boolean(canAdminReview || isReviewer)}
        />
        <RequestEventsTable
          request={props.request}
          events={events}
          loading={eventsLoading}
        />
      </div>
      {showPropagationStatusModal && (
        <PropagationStatusModal
          propagationStatusInfos={propagationStatusInfos}
          showModal={showPropagationStatusModal}
          setShowModal={setShowPropagationStatusModal}
        />
      )}
    </div>
  );
};

interface RequestPermissions {
  isReviewer: boolean;
  canAdminReview: boolean;
  canNormalReview: boolean;
  belongsToCurrentUser: boolean;
  targetIsCurrentUser: boolean;
}

export const useRequestPermissions = (
  request: RequestFragment
): RequestPermissions => {
  const { authState } = useContext(AuthContext);

  let canAdminReview = Boolean(authState.user?.isAdmin);
  if (!canAdminReview && !request.resourceProposalId) {
    const ownAllResources = request.requestedResources.every(
      (requestedResource) => {
        return requestedResource.resource?.adminOwner?.ownerUsers.some(
          (ownerUser) => ownerUser.user?.id === authState.user?.user.id
        );
      }
    );
    const ownAllGroups = request.requestedGroups.every((requestedGroup) => {
      return requestedGroup.group?.adminOwner?.ownerUsers.some(
        (ownerUser) => ownerUser.user?.id === authState.user?.user.id
      );
    });
    canAdminReview = ownAllResources && ownAllGroups;
  }

  const canNormalReview = request.stages.some((item) =>
    item.stages.some((stage) =>
      stage.reviewers.some(
        (reviewer) =>
          reviewer.userId === authState.user?.user.id &&
          !reviewer.reviewerAction
      )
    )
  );

  const isReviewer = request.stages.some((item) =>
    item.stages.some((stage) =>
      stage.reviewers.some(
        (reviewer) => reviewer.userId === authState.user?.user.id
      )
    )
  );

  const belongsToCurrentUser = request.requesterId === authState.user?.user.id;
  const targetIsCurrentUser = request.targetUserId === authState.user?.user.id;

  return {
    isReviewer,
    canAdminReview: canAdminReview,
    canNormalReview: canNormalReview,
    belongsToCurrentUser,
    targetIsCurrentUser,
  };
};

export default RequestOverview;
