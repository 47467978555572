import {
  AddUserTagInput,
  EntityType,
  Maybe,
  RemoveUserTagInput,
  TagFragment,
  useAddUserTagsMutation,
  useRemoveUserTagsMutation,
  UserTagFragment,
} from "api/generated/graphql";
import { ConditionalEditor } from "components/entity_viewer/editor/ConditionalEditor";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import UserLabel from "components/label/item_labels/UserLabel";
import SelectItemsModal, {
  SelectType,
} from "components/modals/update/SelectItemsModal";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import { useToast } from "components/toast/Toast";
import pluralize from "pluralize";
import { useState } from "react";
import { useAllUsersQuery } from "utils/hooks";
import { logError, logWarning } from "utils/logging";
import TagUsersTable from "views/tags/TagUsersTable";

type TagUsersRowProps = {
  tag: TagFragment;
};

export const TagUsersRow = (props: TagUsersRowProps) => {
  const [showUsersAddModal, setShowUsersAddModal] = useState(false);
  const [showUsersRemoveModal, setShowUsersRemoveModal] = useState(false);

  const editor = (
    <ConditionalEditor
      menuOptions={[
        {
          label: "Add",
          handler: () => {
            setShowUsersAddModal(true);
          },
        },
        {
          label: "Remove",
          handler: () => {
            setShowUsersRemoveModal(true);
          },
        },
      ]}
    />
  );

  const addTagToUsersModal = (
    <AddTagToUsersModal
      key={`add-tag-to-users-modal`}
      tag={props.tag}
      userTags={props.tag.tagUsers}
      showModal={showUsersAddModal}
      setShowModal={setShowUsersAddModal}
    />
  );

  const removeTagFromUsersModal = (
    <RemoveTagFromUsersModal
      key={`remove-tag-from-users-modal`}
      tag={props.tag}
      userTags={props.tag.tagUsers}
      showModal={showUsersRemoveModal}
      setShowModal={setShowUsersRemoveModal}
    />
  );

  return (
    <EntityViewerRow
      title={"Users"}
      content={
        <EmptyStateContentWrapper
          content={
            <TagUsersTable tag={props.tag} tagUsers={props.tag.tagUsers} />
          }
          entityType={EntityType.User}
          title={`No users have this tag`}
          subtitle={`Add this tag to users to make them show up here.`}
          buttonTitle={`Add users`}
          isEmpty={props.tag.tagUsers.length === 0}
          onClickHandler={() => {
            setShowUsersAddModal(true);
          }}
          small={true}
        />
      }
      editor={editor}
      modals={
        <>
          {showUsersAddModal && addTagToUsersModal}
          {showUsersRemoveModal && removeTagFromUsersModal}
        </>
      }
      isTable={true}
    />
  );
};

type AddTagToUsersModalProps = {
  tag: TagFragment;
  userTags: UserTagFragment[];
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

const AddTagToUsersModal = (props: AddTagToUsersModalProps) => {
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [addUserTags, { loading }] = useAddUserTagsMutation();
  const [idsToAdd, setIdsToAdd] = useState<string[]>([]);

  const [searchQuery, setSearchQuery] = useState<string>("");

  const modalReset = () => {
    props.setShowModal(false);
    setErrorMessage(null);
  };

  const onSubmit = async () => {
    const userTagsToAdd: AddUserTagInput[] = [];
    for (const userId of idsToAdd) {
      userTagsToAdd.push({
        tagId: props.tag.id,
        userId: userId,
      });
    }

    try {
      const { data } = await addUserTags({
        variables: {
          input: {
            userTags: userTagsToAdd,
          },
        },
        refetchQueries: ["UserTags", "Tag"],
      });
      switch (data?.addUserTags.__typename) {
        case "AddUserTagsResult": {
          let userTags = [];
          data.addUserTags.entries.forEach((entry) => {
            switch (entry.__typename) {
              case "AddUserTagsEntryResult":
                userTags.push(entry.userTag);
            }
          });
          if (userTags.length !== userTagsToAdd.length) {
            setErrorMessage(
              `Error: tag was not added to selected users successfully`
            );
          } else {
            modalReset();
            displaySuccessToast(
              `Success: tag added to ${pluralize(
                "users",
                userTagsToAdd.length,
                true
              )}`
            );
          }
          break;
        }
        case "UserNotFoundError":
        case "TagNotFoundError": {
          logWarning(new Error(data.addUserTags.message));
          setErrorMessage(data.addUserTags.message);
          break;
        }
        default:
          logError(new Error(`failed to add tag to users`));
          setErrorMessage("Error: failed to add tag to users");
      }
    } catch (error) {
      logError(error, `failed to add tag to users`);
      setErrorMessage("Error: failed to add tag to users");
    }
  };

  // Fetch all users
  const existingUserIds = new Set(
    props.userTags.map((userTag) => userTag.userId)
  );

  const { data: usersData, error: usersError } = useAllUsersQuery();
  if (usersError) {
    logError(usersError, `failed to list users`);
  }

  const users = usersData?.users.users ?? [];

  return (
    <SelectItemsModal
      key={"users_remove"}
      title={"Add tag to users"}
      selectType={SelectType.Add}
      entryInfos={users
        ?.filter((user) => {
          return (
            !existingUserIds.has(user.id) &&
            user.fullName
              .toLowerCase()
              .includes(searchQuery.toLocaleLowerCase())
          );
        })
        .sort((a, b) => {
          if (a && b) {
            const aSortString = a.fullName || "";
            const bSortString = b.fullName || "";
            return aSortString.localeCompare(bSortString);
          }
          return 0;
        })
        .map((user) => {
          return {
            entityId: {
              entityId: user.id,
              entityType: EntityType.User,
            },
            label: (
              <UserLabel
                avatar={user.avatarUrl}
                name={user.fullName}
                large={true}
                bold={true}
              />
            ),
            isBold: false,
            isBreadcrumb: false,
          };
        })}
      itemName={"user"}
      isModalOpen={props.showModal}
      errorMessage={errorMessage}
      submitDisabled={idsToAdd.length === 0}
      onClose={modalReset}
      onSubmit={onSubmit}
      loading={loading}
      idsToUpdate={idsToAdd}
      setIdsToUpdate={setIdsToAdd}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    />
  );
};

type RemoveTagFromUsersModalProps = {
  tag: TagFragment;
  userTags: UserTagFragment[];
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

const RemoveTagFromUsersModal = (props: RemoveTagFromUsersModalProps) => {
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { displaySuccessToast } = useToast();

  const [removeTagUsers, { loading }] = useRemoveUserTagsMutation();
  const [idsToRemove, setIdsToRemove] = useState<string[]>([]);

  const modalReset = () => {
    props.setShowModal(false);
    setErrorMessage(null);
    setSearchQuery("");
  };

  const onSubmit = async () => {
    const userTagsToRemove: RemoveUserTagInput[] = [];
    idsToRemove.forEach((userTagId) => {
      userTagsToRemove.push({
        userTagId: userTagId,
      });
    });

    try {
      const { data } = await removeTagUsers({
        variables: {
          input: {
            userTags: userTagsToRemove,
          },
        },
        refetchQueries: ["UserTags", "Tag"],
      });
      switch (data?.removeUserTags.__typename) {
        case "RemoveUserTagsResult": {
          let userTags = [];
          data.removeUserTags.entries.forEach((entry) => {
            switch (entry.__typename) {
              case "RemoveUserTagsEntryResult":
                userTags.push(entry.userTag);
                break;
            }
          });
          if (userTags.length !== idsToRemove.length) {
            logError(new Error(`removed tag to some, but not all users`));
            setErrorMessage(`Error: removed tag to some, but not all users`);
          } else {
            modalReset();
            displaySuccessToast(
              `Success: removed tag from ${pluralize(
                "user",
                idsToRemove.length,
                true
              )}`
            );
          }
          break;
        }
        case "UserTagNotFoundError": {
          logError(new Error(data.removeUserTags.message));
          setErrorMessage(data.removeUserTags.message);
          break;
        }
        default:
          logError(new Error(`failed to remove tag from selected users`));
          setErrorMessage("Error: failed to remove tag from selected users");
      }
    } catch (error) {
      logError(error, `failed to remove group tag from selected users`);
      setErrorMessage("Error: failed to remove tag from selected users");
    }
  };

  return (
    <SelectItemsModal
      key={"users_remove"}
      title={"Remove tag from users"}
      selectType={SelectType.Remove}
      entryInfos={props.tag.tagUsers
        .filter((tagUser) =>
          tagUser.user?.fullName
            .toLowerCase()
            .includes(searchQuery.toLocaleLowerCase())
        )
        .sort((a, b) => {
          if (a && b) {
            const aSortString = a.user?.fullName || "";
            const bSortString = b.user?.fullName || "";
            return aSortString.localeCompare(bSortString);
          }
          return 0;
        })
        .map((tagUser) => {
          return {
            entityId: {
              entityId: tagUser.id,
              entityType: EntityType.User,
            },
            label: (
              <UserLabel
                avatar={tagUser.user?.avatarUrl}
                name={tagUser.user?.fullName}
              />
            ),
            isBold: false,
            isBreadcrumb: false,
          };
        })}
      itemName={"user"}
      isModalOpen={props.showModal}
      errorMessage={errorMessage}
      submitDisabled={idsToRemove.length === 0}
      onClose={modalReset}
      onSubmit={onSubmit}
      loading={loading}
      idsToUpdate={idsToRemove}
      setIdsToUpdate={setIdsToRemove}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    />
  );
};

export default TagUsersRow;
