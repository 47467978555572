import {
  AuthFlowType,
  Maybe,
  ThirdPartyIntegrationFragment,
  ThirdPartyProvider,
} from "api/generated/graphql";
import githubLogo from "assets/logos/github-logo.png";
import gitlabLogo from "assets/logos/gitlab-logo.svg";
import { IntegrationInfo } from "components/integrations/OrgIntegrationApp";
import { UserIntegrationApp } from "components/integrations/UserIntegrationApp";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useMountEffect } from "utils/hooks";
import { OrgSettingsSection } from "views/settings/OrgSettings";
import { UserSettingsCategoryInfo } from "views/user_settings/UserSettings";

const integrationInfos: IntegrationInfo[] = [
  {
    name: "GitHub",
    logo: githubLogo,
    thirdPartyProvider: ThirdPartyProvider.GitHub,
    authFlowType: AuthFlowType.Code,
  },
  {
    name: "GitLab",
    logo: gitlabLogo,
    thirdPartyProvider: ThirdPartyProvider.GitLab,
    authFlowType: AuthFlowType.Code,
  },
];

export type UserIntegrationsProps = {
  categoryInfo: UserSettingsCategoryInfo;
  integrations: Maybe<ThirdPartyIntegrationFragment[]> | undefined;
  disabled: boolean;
};

const UserIntegrations = (props: UserIntegrationsProps) => {
  const [openThirdPartyProvider, setOpenThirdPartyProvider] = useState<
    Maybe<ThirdPartyProvider>
  >(null);

  const urlParams = new URLSearchParams(useLocation().search);

  useMountEffect(() => {
    let thirdPartyProvider: ThirdPartyProvider | null = null;
    const showIntegrationModal = urlParams
      .get("showIntegrationModal")
      ?.toString();
    switch (showIntegrationModal) {
      case "GIT_HUB":
        thirdPartyProvider = ThirdPartyProvider.GitHub;
        break;
      case "GIT_LAB":
        thirdPartyProvider = ThirdPartyProvider.GitLab;
        break;
    }
    if (thirdPartyProvider) {
      setOpenThirdPartyProvider(thirdPartyProvider);
    }
  });

  return (
    <div id={`#${props.categoryInfo.anchor}`}>
      <OrgSettingsSection title="Identities">
        <IntegrationApps
          integrations={props.integrations || []}
          openThirdPartyProvider={openThirdPartyProvider}
          setOpenThirdPartyProvider={setOpenThirdPartyProvider}
          disabled={props.disabled}
        />
      </OrgSettingsSection>
    </div>
  );
};

type IntegrationAppsProps = {
  integrations: ThirdPartyIntegrationFragment[];
  openThirdPartyProvider: Maybe<ThirdPartyProvider>;
  setOpenThirdPartyProvider: (integration: Maybe<ThirdPartyProvider>) => void;
  disabled: boolean;
};

const IntegrationApps = (props: IntegrationAppsProps) => {
  return (
    <>
      {integrationInfos.map((integrationInfo) => (
        <UserIntegrationApp
          key={`app_info_${integrationInfo.name}`}
          integrationInfo={integrationInfo}
          integrations={props.integrations}
          openThirdPartyProvider={props.openThirdPartyProvider}
          setOpenThirdPartyProvider={props.setOpenThirdPartyProvider}
          disabled={props.disabled}
        />
      ))}
    </>
  );
};

export default UserIntegrations;
