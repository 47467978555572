import {
  useNumRequestsToReviewQuery,
  useRequestStatsQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column, ColumnContainer } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import ColumnListItem, {
  ColumnListItemsSkeleton,
} from "components/column/ColumnListItem";
import { Divider } from "components/ui";
import { useContext } from "react";
import { useHistory, useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { hasBasicPermissions } from "utils/auth/auth";
import { logError } from "utils/logging";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import RequestDetailView from "./RequestDetailView";
import RequestsListColumn from "./RequestsListColumn";
import { RequestsType } from "./utils";

const RequestsView = ({ selectedType }: { selectedType?: RequestsType }) => {
  const { authState } = useContext(AuthContext);
  const { requestId } = useParams<{ requestId: string }>();
  const history = useHistory();
  const logEvent = useLogEvent();

  const organizationName = authState.user?.user.organization.name;

  const { data: requestStatsWrapped, loading, error } = useRequestStatsQuery();

  const {
    data: numRequestsToReviewData,
    loading: numRequestsToReviewLoading,
    error: numRequestsToReviewError,
  } = useNumRequestsToReviewQuery();

  if (loading || numRequestsToReviewLoading) {
    return (
      <Column>
        <ColumnHeader
          title="Requests"
          subtitle={organizationName}
          icon={{ icon: "inbox", type: "name" }}
        />
        <Divider />
        <ColumnListItemsSkeleton numItems={3} />
      </Column>
    );
  }

  if (error) {
    logError(error, `failed to list request stats`);
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  if (numRequestsToReviewError) {
    logError(numRequestsToReviewError, `failed to list num requests to review`);
    return (
      <Column isContent>
        <UnexpectedErrorPage error={numRequestsToReviewError} />
      </Column>
    );
  }

  const currentUserRequestStats =
    requestStatsWrapped?.currentUserStats.currentUserStats;

  const showReviewTab =
    currentUserRequestStats?.reviewsItems ||
    (numRequestsToReviewData?.numRequestsToReview ?? 0) > 0;

  const showAdminTab =
    (currentUserRequestStats?.numGroupsAdmined ?? 0) > 0 ||
    (currentUserRequestStats?.numResourcesAdmined ?? 0) > 0 ||
    !hasBasicPermissions(authState.user);

  const showTypeSelectorColumn = !selectedType;

  const requestTypes = [
    {
      label: "Inbox",
      key: "inbox",
      count: numRequestsToReviewData?.numRequestsToReview,
      show: showReviewTab,
    },
    {
      label: "Sent",
      key: "sent",
      count:
        (currentUserRequestStats?.numRequestsSentPending ?? 0) +
        (currentUserRequestStats?.numRequestsSentPendingOnBehalfOfUser ?? 0),
      show: true,
    },
    {
      label: "Admin",
      key: "admin",
      show: showAdminTab,
    },
  ];

  const handleRequestTypeClick = (key: string) => {
    logEvent({
      name: "request_type_click",
      properties: {
        type: key,
        hasInbox: showReviewTab,
        hasAdmin: Boolean(showAdminTab),
      },
    });
    history.push(`/requests/${key}`);
  };

  return (
    <ColumnContainer>
      {showTypeSelectorColumn ? (
        <Column>
          <ColumnHeader
            title="Requests"
            subtitle={organizationName}
            icon={{ icon: "inbox", type: "name" }}
          />
          <Divider />
          {requestTypes
            .filter((requestType) => Boolean(requestType.show))
            .map((requestType) => (
              <ColumnListItem
                key={requestType.key}
                label={requestType.label}
                count={requestType.count}
                onClick={() => handleRequestTypeClick(requestType.key)}
                badgeColor="info"
              />
            ))}
        </Column>
      ) : null}
      {selectedType ? <RequestsListColumn requestsType={selectedType} /> : null}
      {requestId ? <RequestDetailView requestId={requestId} /> : null}
    </ColumnContainer>
  );
};

export default RequestsView;
