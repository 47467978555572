import { FormRow, Select } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import { AppsContext } from "views/apps/AppsContext";

import { FormMode } from "../common";

interface Props {
  mode: FormMode;
  requireMfa?: boolean;
  onChange: (requireMfa?: boolean) => void;
  showTemplateWarning?: boolean;
}

const RequireMfaToConnectRow = (props: Props) => {
  const { bulkMode } = useContext(AppsContext);

  const viewContent = props.requireMfa ? "Required" : "Not required";

  const editContent = (
    <>
      <div className={sprinkles({ marginBottom: "sm" })}>
        Require that users must complete a MFA challenge within 5 minutes before
        connecting to this resource.
      </div>
      <Select
        value={props.requireMfa}
        options={[true, false]}
        getOptionLabel={(opt) => (opt ? "Required" : "Not required")}
        onChange={props.onChange}
        clearable={bulkMode === "edit"}
        placeholder="Leave unchanged"
      />
    </>
  );

  return (
    <FormRow title="Require MFA to connect">
      {props.mode === "view" ? viewContent : editContent}
      {props.showTemplateWarning ? (
        <p className={sprinkles({ color: "gray500" })}>
          This only applies to resources with Opal-managed sessions
        </p>
      ) : null}
    </FormRow>
  );
};

export default RequireMfaToConnectRow;
