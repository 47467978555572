import {
  ConnectionCredentialsInput,
  ConnectionPreviewSmallFragment,
  Maybe,
} from "api/generated/graphql";
import { SigningSecretModal } from "components/modals/SigningSecretModal";
import { Button, FileUpload, FormRow, FormSection, Input } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useState } from "react";
import { randomAlphanumericString } from "utils/random";

import {
  getObjKeyValues,
  InputInfo,
  InputType,
  useMetadataAndCredsUpdater,
} from "./ConnectionMetadataAndCredsSetup";

type ConnectionMetadataCredsRowProps = {
  connection: ConnectionPreviewSmallFragment;
  editMode?: boolean;
  initialCreds?: Maybe<ConnectionCredentialsInput>;
  credsVal: ConnectionCredentialsInput;
  setCredsVal: (creds: ConnectionCredentialsInput) => void;
  hasV3?: boolean;
  objKeyInputInfo: Record<string, InputInfo>;
};

const ConnectionCredsSection = (props: ConnectionMetadataCredsRowProps) => {
  const { credsVal, setCredsVal, initialCreds, objKeyInputInfo } = props;
  const [showGenerateSecretModal, setShowGenerateSecretModal] = useState({
    open: false,
    secret: "",
  });
  const updateCreds = useMetadataAndCredsUpdater(objKeyInputInfo);

  const defaultVal = "**************";

  let inputInfos: InputInfo[] = [];

  if (initialCreds) {
    const initialCredsKeyVals = getObjKeyValues(initialCreds);
    for (const [objKey, objVal] of Object.entries(initialCredsKeyVals)) {
      if (objKey in objKeyInputInfo) {
        inputInfos.push(objKeyInputInfo[objKey]);
      } else {
        inputInfos.push({
          inputKey: objKey,
          inputTitle: _.startCase(objKey),
          inputType:
            typeof objVal === "boolean" ? InputType.BOOLEAN : InputType.TEXT,
        });
      }
    }
  } else {
    return null;
  }

  const inputs = (
    <>
      {inputInfos.map((inputInfo) => {
        var input;
        switch (inputInfo.inputType) {
          case InputType.FILE:
            input = (
              <FileUpload
                renderButton={(onClick) => (
                  <Button
                    borderless
                    label={`Upload ${inputInfo.inputTitle}`}
                    onClick={onClick}
                  />
                )}
                handleUpload={(file: File) => {
                  const fileReader = new FileReader();
                  fileReader.onload = async () => {
                    const data = fileReader.result;
                    if (data && typeof data === "string") {
                      const updatedCreds = updateCreds(
                        credsVal,
                        inputInfo.inputKey,
                        data
                      );
                      setCredsVal({ ...updatedCreds });
                    }
                  };
                  if (file) {
                    fileReader.readAsText(file);
                  }
                }}
              />
            );
            break;
          case InputType.GENERATE_RANDOM_STRING:
            input = (
              <Button
                borderless
                label={`Regenerate ${inputInfo.inputTitle}`}
                onClick={() => {
                  const newRandomString = randomAlphanumericString(32);
                  const updatedCreds = updateCreds(
                    credsVal,
                    inputInfo.inputKey,
                    newRandomString
                  );
                  setCredsVal({ ...updatedCreds });
                  setShowGenerateSecretModal({
                    open: true,
                    secret: newRandomString,
                  });
                }}
              />
            );
            break;
          case InputType.TEXTAREA:
            input = (
              <Input
                value={
                  credsVal
                    ? Reflect.get(credsVal, inputInfo.inputKey)
                    : undefined
                }
                onChange={(value: string) => {
                  const updatedCreds = updateCreds(
                    credsVal,
                    inputInfo.inputKey,
                    value
                  );
                  setCredsVal({ ...updatedCreds });
                }}
                type={"textarea"}
                placeholder={defaultVal}
              />
            );
            break;
          default:
            input = (
              <Input
                value={
                  credsVal
                    ? Reflect.get(credsVal, inputInfo.inputKey)
                    : undefined
                }
                onChange={(value: string) => {
                  const updatedCreds = updateCreds(
                    credsVal,
                    inputInfo.inputKey,
                    value
                  );
                  setCredsVal({ ...updatedCreds });
                }}
                type={"password"}
                placeholder={defaultVal}
              />
            );
        }

        return (
          <FormRow title={inputInfo.inputTitle}>
            <div className={sprinkles({ paddingRight: "md" })}>{input}</div>
          </FormRow>
        );
      })}
    </>
  );

  return props.editMode ? (
    <>
      {props.hasV3 ? (
        inputs
      ) : (
        <FormSection title="Credentials">{inputs}</FormSection>
      )}
      {showGenerateSecretModal && showGenerateSecretModal.open && (
        <SigningSecretModal
          isModalOpen={showGenerateSecretModal.open}
          signingSecret={showGenerateSecretModal.secret}
          onClose={() => {
            setShowGenerateSecretModal({ open: false, secret: "" });
          }}
          onSubmit={() => {
            setShowGenerateSecretModal({ open: false, secret: "" });
          }}
        />
      )}
    </>
  ) : (
    <FormRow title="Credentials">{defaultVal}</FormRow>
  );
};

export default ConnectionCredsSection;
