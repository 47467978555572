import {
  Maybe,
  ReviewConnectionUserAction,
  ReviewGroupResourceAction,
  ReviewGroupUserAction,
  ReviewResourceResourceAction,
  ReviewResourceUserAction,
} from "api/generated/graphql";
import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  useReducer,
} from "react";
import { useWarnBeforeUnload } from "utils/hooks";
import { RevocationAction } from "views/access_reviews/revocations/AccessReviewRevocationsToggleButton";

export enum AccessReviewContextActionType {
  AccessReviewUpdate,
  AccessReviewItemUpdate,
}

export type PerformReviewState = {
  resourceUserActions: ReviewResourceUserAction[];
  groupUserActions: ReviewGroupUserAction[];
  groupResourceActions: ReviewGroupResourceAction[];
  connectionUserActions: ReviewConnectionUserAction[];
  resourceResourceActions: ReviewResourceResourceAction[];
};

export type AccessReviewContextState = {
  isInitialized: boolean;
  ongoingAccessReviewIdSet: Set<string>;
  performReviewStateByUARResourceId: Record<string, PerformReviewState>;
  performReviewStateByUARGroupId: Record<string, PerformReviewState>;
  performReviewStateByUARConnectionId: Record<string, PerformReviewState>;
  performReviewStateByUARUserId: Record<string, PerformReviewState>;
  revocationActionByUARResourceUserId: Record<string, RevocationAction>;
};

export const emptyPerformReviewState = () => {
  return {
    resourceUserActions: [],
    groupUserActions: [],
    groupResourceActions: [],
    connectionUserActions: [],
    resourceResourceActions: [],
  };
};

export type AccessReviewContextEvent = {
  type: AccessReviewContextActionType;
  payload: {
    ongoingAccessReviewIdSet?: Set<string> | undefined;
    performReviewStateByUARResourceId?:
      | Maybe<Record<string, PerformReviewState>>
      | undefined;
    performReviewStateByUARGroupId?:
      | Maybe<Record<string, PerformReviewState>>
      | undefined;
    performReviewStateByUARConnectionId?:
      | Maybe<Record<string, PerformReviewState>>
      | undefined;
    performReviewStateByUARUserId?:
      | Maybe<Record<string, PerformReviewState>>
      | undefined;
    revocationActionByUARResourceUserId?:
      | Maybe<Record<string, RevocationAction>>
      | undefined;
  };
};

export const initialAccessReviewContext: AccessReviewContextState = {
  isInitialized: false,
  ongoingAccessReviewIdSet: new Set(),
  performReviewStateByUARResourceId: {},
  performReviewStateByUARGroupId: {},
  performReviewStateByUARConnectionId: {},
  performReviewStateByUARUserId: {},
  revocationActionByUARResourceUserId: {},
};

const AccessReviewContext = createContext<{
  accessReviewState: AccessReviewContextState;
  accessReviewDispatch: Dispatch<AccessReviewContextEvent>;
}>({
  accessReviewState: initialAccessReviewContext,
  accessReviewDispatch: () => null,
});

export const accessReviewContextReducer = (
  state: AccessReviewContextState,
  action: AccessReviewContextEvent
): AccessReviewContextState => {
  switch (action.type) {
    case AccessReviewContextActionType.AccessReviewUpdate:
      return {
        ...state,
        isInitialized: true,
        ongoingAccessReviewIdSet:
          action.payload.ongoingAccessReviewIdSet ||
          state.ongoingAccessReviewIdSet,
      };
    case AccessReviewContextActionType.AccessReviewItemUpdate:
      return {
        ...state,
        performReviewStateByUARResourceId:
          action.payload.performReviewStateByUARResourceId ||
          state.performReviewStateByUARResourceId,
        performReviewStateByUARGroupId:
          action.payload.performReviewStateByUARGroupId ||
          state.performReviewStateByUARGroupId,
        performReviewStateByUARConnectionId:
          action.payload.performReviewStateByUARConnectionId ||
          state.performReviewStateByUARConnectionId,
        performReviewStateByUARUserId:
          action.payload.performReviewStateByUARUserId ||
          state.performReviewStateByUARUserId,
        revocationActionByUARResourceUserId:
          action.payload.revocationActionByUARResourceUserId ||
          state.revocationActionByUARResourceUserId,
      };
    default:
      return state;
  }
};

export const AccessReviewContextProvider: FunctionComponent = (
  props: PropsWithChildren<{}>
) => {
  const [accessReviewState, accessReviewDispatch] = useReducer(
    accessReviewContextReducer,
    initialAccessReviewContext
  );

  const hasChange =
    Object.keys(accessReviewState.performReviewStateByUARResourceId).length >
      0 ||
    Object.keys(accessReviewState.performReviewStateByUARGroupId).length > 0 ||
    Object.keys(accessReviewState.performReviewStateByUARConnectionId).length >
      0 ||
    Object.keys(accessReviewState.performReviewStateByUARUserId).length > 0;

  useWarnBeforeUnload(hasChange);

  return (
    <AccessReviewContext.Provider
      value={{
        accessReviewState: accessReviewState,
        accessReviewDispatch: accessReviewDispatch,
      }}
    >
      {props.children}
    </AccessReviewContext.Provider>
  );
};

export default AccessReviewContext;
