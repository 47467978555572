import { ReactComponent as activedirectory } from "./brands/activedirectory.svg";
import { ReactComponent as aws } from "./brands/aws.svg";
import { ReactComponent as azure } from "./brands/azure.svg";
import { ReactComponent as customapp } from "./brands/customapp.svg";
import { ReactComponent as customconnection } from "./brands/customconnection.svg";
import { ReactComponent as duo } from "./brands/duo.svg";
import { ReactComponent as gcp } from "./brands/gcp.svg";
import { ReactComponent as github } from "./brands/github.svg";
import { ReactComponent as gitlab } from "./brands/gitlab.svg";
import { ReactComponent as google } from "./brands/google.svg";
import { ReactComponent as googlegroups } from "./brands/googlegroups.svg";
import { ReactComponent as kubernetes } from "./brands/kubernetes.svg";
import { ReactComponent as ldap } from "./brands/ldap.svg";
import { ReactComponent as mariadb } from "./brands/mariadb.svg";
import { ReactComponent as microsoftentraid } from "./brands/microsoftentraid.svg";
import { ReactComponent as mongo } from "./brands/mongo.svg";
import { ReactComponent as mysql } from "./brands/mysql.svg";
import { ReactComponent as okta } from "./brands/okta.svg";
import { ReactComponent as opal } from "./brands/opal.svg";
import { ReactComponent as pagerduty } from "./brands/pagerduty.svg";
import { ReactComponent as postgres } from "./brands/postgres.svg";
import { ReactComponent as salesforce } from "./brands/salesforce.svg";
import { ReactComponent as selfhosted } from "./brands/selfhosted.svg";
import { ReactComponent as snowflake } from "./brands/snowflake.svg";
import { ReactComponent as tailscale } from "./brands/tailscale.svg";
import { ReactComponent as teleport } from "./brands/teleport.svg";
import { ReactComponent as workday } from "./brands/workday.svg";

export const brandIconSet = {
  "brand-active-directory": activedirectory,
  "brand-aws": aws,
  "brand-awsiam": aws,
  "brand-azure": azure,
  "brand-customapp": customapp,
  "brand-customconnection": customconnection,
  "brand-duo": duo,
  "brand-gcp": gcp,
  "brand-github": github,
  "brand-gitlab": gitlab,
  "brand-google": google,
  "brand-googlegroups": googlegroups,
  "brand-kubernetes": kubernetes,
  "brand-ldap": ldap,
  "brand-mariadb": mariadb,
  "brand-microsoftentraid": microsoftentraid,
  "brand-mongo": mongo,
  "brand-mysql": mysql,
  "brand-okta": okta,
  "brand-opal": opal,
  "brand-pagerduty": pagerduty,
  "brand-postgres": postgres,
  "brand-salesforce": salesforce,
  "brand-selfhosted": selfhosted,
  "brand-snowflake": snowflake,
  "brand-tailscale": tailscale,
  "brand-teleport": teleport,
  "brand-workday": workday,
};
