import { EntityType, useEventFiltersQuery } from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnListItem from "components/column/ColumnListItem";
import ColumnListScroller from "components/column/ColumnListScroller";
import { useContext } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

const EventFiltersRow = () => {
  const location = useLocation();
  const history = useHistory();
  const { eventFilterId } = useParams<Record<string, string>>();
  const { authState } = useContext(AuthContext);

  const { data, error, loading } = useEventFiltersQuery({
    skip: !authState?.user?.isAdmin,
  });
  const eventFilters = data?.eventFilters.eventFilters ?? [];

  if (error) {
    logError(error, `failed to list saved event queries`);
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  const renderRow = (index: number) => {
    const filter = eventFilters[index];
    if (!filter) {
      return <></>;
    }

    return (
      <ColumnListItem
        key={filter.id}
        label={filter.name}
        selected={filter.id === eventFilterId}
        onClick={() => {
          history.push(
            getResourceUrlNew({
              entityType: EntityType.EventFilter,
              entityId: filter.id,
            }) + location.search
          );
        }}
      />
    );
  };

  return (
    <ColumnListScroller
      renderRow={renderRow}
      numRows={eventFilters.length}
      loading={loading}
      emptyState={{
        title: "You haven’t created any saved filters yet.",
        subtitle: "Create and save a filter from the Filter Events top bar.",
      }}
    />
  );
};

export default EventFiltersRow;
