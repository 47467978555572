import sprinkles from "css/sprinkles.css";

import * as styles from "./Dashboard.css";
import DashboardGraphCard from "./DashboardGraphCard";

const TIME_RANGES = ["1D", "1M", "3M", "6M", "YTD", "1Y"];

export const Dashboard = () => {
  return (
    <>
      <div
        className={sprinkles({
          display: "flex",
          justifyContent: "center",
          gap: "sm",
          margin: "md",
        })}
      >
        {TIME_RANGES.map((range) => (
          <div
            className={styles.timeRange({
              selected: range === "3M",
            })}
          >
            {range}
          </div>
        ))}
      </div>
      <div className={styles.container}>
        <DashboardGraphCard
          title="Unused grants"
          alerting
          description="Number of access grants that have not been used in the past 90 days"
          limit={295}
          warning="Unused grants are up 35% from last month"
          graphLabel="90 day unused"
          teamToNotify="DevOps Team"
          graphData={[
            {
              data: [20, 35, 20, 57, 18, 122, 118, 150, 30, 280, 110, 297],
              label: "",
            },
          ]}
        />
        <DashboardGraphCard
          title="Unrecognized grants"
          description="Number of access grants that come from an unrecognized source, i.e.,
        neither birthright nor Opal-approved just-in-time"
          limit={200}
          graphLabel="90 day unrecognized"
          teamToNotify="DevOps Team"
          graphData={[
            {
              data: [20, 35, 40, 52, 48, 70, 65, 87, 80, 85, 150, 185],
              label: "",
            },
          ]}
        />
        <DashboardGraphCard
          title="Anomalous grants"
          alerting
          description="Number of access grants that are anomalous relative to their cohort, specifically, conformity score < 0.01"
          limit={100}
          warning="Anomalous grants are up 12% from last month"
          graphLabel="90 day anomalous"
          teamToNotify="DevOps Team"
          graphData={[
            {
              data: [60, 70, 71, 72, 80, 90, 120, 125, 150, 152, 170, 185],
              label: "Team",
            },
            {
              data: [15, 20, 30, 32, 40, 50, 70, 76, 120, 119, 110, 105],
              label: "Role",
            },
            {
              data: [19, 30, 52, 55, 60, 83, 85, 90, 110, 96, 102, 112],
              label: "Dept",
            },
          ]}
        />
      </div>
    </>
  );
};

export default Dashboard;
