import { EntityType } from "api/generated/graphql";
import {
  CLIENT_REQUEST_CONFIG_ID_PREFIX,
  FormMode,
  ResourceRequestConfig,
} from "components/forms/common";
import RequestConfigRow from "components/forms/rows/conditional_config/RequestConfigRow";
import RequestConfigTitleRow from "components/forms/rows/conditional_config/RequestConfigTitleRow";
import { makeDefaultRequestConfig } from "components/forms/utils";
import { DragBubbles, FormGroup, FormRow, Switch } from "components/ui";
import { generateUniqueId } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import { DropResult } from "react-beautiful-dnd";
import { AppsContext } from "views/apps/AppsContext";

import CustomRequestNotificationRow from "./CustomRequestNotificationRow";

interface Props {
  mode: FormMode;
  entityId?: string;
  entityType: EntityType;
  requestConfigs: ResourceRequestConfig[];
  onChange: (requestConfigs: ResourceRequestConfig[]) => void;
  isViewingAsNonAdmin: boolean;
  orgMaxDuration?: number;
  isV3?: boolean;
  includeTitle?: boolean;
  customRequestNotification?: string;
  onCustomRequestNotificationChange?: (val?: string) => void;
}

const reorderPriorities = (configs: ResourceRequestConfig[]) => {
  return configs.map((config, i) => ({
    ...config,
    // Default config should always be priority 0
    priority: config.priority === 0 ? 0 : i + 1,
  }));
};

const ConditionalConfigs = (props: Props) => {
  const { includeTitle = true } = props;
  const { bulkMode } = useContext(AppsContext);

  const handleDeleteConfig = (requestConfig: ResourceRequestConfig) => {
    const newConfigs = props.requestConfigs.filter((config) => {
      return config.priority !== requestConfig.priority;
    });
    props.onChange(reorderPriorities(newConfigs));
  };

  const handleAddConfig = () => {
    const newConfigs = props.requestConfigs.slice();
    newConfigs.push({
      id: generateUniqueId(CLIENT_REQUEST_CONFIG_ID_PREFIX),
      groupIds: [],
      roleIds: [],
      priority: props.requestConfigs.length,
      isRequestable: false,
      autoApprove: false,
      reasonOptional: false,
      stageData: [],
      maxDurationMin: null,
      recommendedDurationMin: null,
      requireSupportTicket: false,
      requestTemplateId: null,
      requireMfaToRequest: false,
    });
    props.onChange(newConfigs);
  };

  const handleDragEnd = (result: DropResult) => {
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination?.index;
    if (destinationIndex === undefined || sourceIndex === destinationIndex)
      return;

    const newConfigs = props.requestConfigs.slice();
    const [removed] = newConfigs.splice(sourceIndex, 1);
    newConfigs.splice(destinationIndex, 0, removed);
    props.onChange(reorderPriorities(newConfigs));
  };

  const sortedConfigs = props.requestConfigs.slice().sort((a, b) => {
    // Always show default config last
    if (a.priority === 0) {
      return 1;
    }
    if (b.priority === 0) {
      return -1;
    }
    // Otherwise sort in order of descending priority
    return a.priority - b.priority;
  });

  const content = (
    <>
      <CustomRequestNotificationRow
        mode={props.mode}
        customRequestNotification={props.customRequestNotification}
        onChange={props.onCustomRequestNotificationChange}
      />
      <DragBubbles
        showAddButton={props.mode === "edit"}
        isDragDisabled={
          // Only allow reordering if there are at least 3 configs
          // (default config + 2 others to reorder)
          props.mode !== "edit" || sortedConfigs.length < 3
        }
        handleDragEnd={handleDragEnd}
        items={sortedConfigs.map((requestConfig, index) => ({
          id: requestConfig.id,
          index,
          priorityLabel:
            requestConfig.priority > 0 ? requestConfig.priority : undefined,
          titleContent: (
            <RequestConfigTitleRow
              requestConfig={requestConfig}
              entityId={props.entityId}
              entityType={props.entityType}
            />
          ),
          innerContent: (
            <RequestConfigRow
              key={requestConfig.id}
              mode={props.mode}
              isViewingAsNonAdmin={props.isViewingAsNonAdmin}
              entityId={props.entityId}
              entityType={props.entityType}
              requestConfig={requestConfig}
              onChange={(newConfig) => {
                const newConfigs = sortedConfigs.map((prevConfig, i) => {
                  return i === index ? newConfig : prevConfig;
                });
                props.onChange(newConfigs);
              }}
              onDelete={() => handleDeleteConfig(requestConfig)}
            />
          ),
          isDragDisabled: requestConfig.priority === 0,
        }))}
        addButtonLabel="Add a new configuration"
        onAdd={handleAddConfig}
      />
    </>
  );

  if (!includeTitle) {
    return (
      <div>
        <div className={sprinkles({ marginBottom: "lg" })}>
          Users who request access are subject to the Default request
          configuration, unless there is another request configuration defined
          whose group they belong to. Users are subject to the first matching
          configuration.
        </div>
        {content}
      </div>
    );
  }

  if (props.isV3) {
    const tooltipText = `Users who request access are subject to the Default request configuration, unless there is another request configuration defined whose group they belong to. Users are subject to the first matching configuration.`;
    return props.mode === "view" ? (
      <FormRow
        title="Request Configuration"
        tooltipText={tooltipText}
        showToolTipIcon
      >
        {content}
      </FormRow>
    ) : (
      <FormGroup label="Request configuration" infoTooltip={tooltipText}>
        {content}
      </FormGroup>
    );
  }

  return (
    <FormRow title="Request configuration">
      {bulkMode === "edit" && (
        <div className={sprinkles({ marginBottom: "md" })}>
          <Switch
            label="Leave unchanged"
            checked={sortedConfigs.length === 0}
            onChange={(val) => {
              if (val) {
                props.onChange([]);
              } else {
                props.onChange([makeDefaultRequestConfig()]);
              }
            }}
            rightAlign
          />
        </div>
      )}
      <div className={sprinkles({ marginBottom: "lg" })}>
        Users who request access are subject to the Default request
        configuration, unless there is another request configuration defined
        whose group they belong to. Users are subject to the first matching
        configuration.
      </div>
      {content}
    </FormRow>
  );
};

export default ConditionalConfigs;
