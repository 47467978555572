import {
  ResourceFragment,
  ResourcePreviewLargeFragment,
  useResourcesHomeQuery,
} from "api/generated/graphql";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import RequestModalResourceDropdown from "components/modals/RequestModalResourceDropdown";
import { Modal } from "components/ui";
import React, { useState } from "react";
import { logError } from "utils/logging";

type AccessReviewEditResourceModalProps = {
  onSubmit: (resource: ResourceFragment) => void;
  onClose: () => void;
  currentResource: ResourceFragment;
};

const AccessReviewEditResourceModal = (
  props: AccessReviewEditResourceModalProps
) => {
  const [selectedResource, setSelectedResource] = useState<
    ResourcePreviewLargeFragment | undefined
  >(props.currentResource);

  const { data, loading, error } = useResourcesHomeQuery({
    variables: {
      input: {
        serviceType: props.currentResource.serviceType,
        resourceTypes: [props.currentResource.resourceType],
      },
    },
    fetchPolicy: "no-cache",
  });

  let resources: ResourcePreviewLargeFragment[] = [];
  if (data) {
    switch (data.resources.__typename) {
      case "ResourcesResult": {
        resources = data.resources.resources;
        break;
      }
    }
  } else if (error) {
    logError(error, `failed to list resources`);
  }

  const resourceTypeName = resourceTypeInfoByType[
    props.currentResource.resourceType
  ].name.toLocaleLowerCase();

  return (
    <Modal
      isOpen={true}
      onClose={props.onClose}
      title={`Edit ${resourceTypeName}`}
      subtitle={``}
    >
      <Modal.Body>
        <p>
          To edit the user's {resourceTypeName}, please select the new{" "}
          {resourceTypeName} below. This will create a request for the user to
          gain access to the new {resourceTypeName}. The request can be tracked
          in the access review's "Access changes" tab.
        </p>
        <RequestModalResourceDropdown
          title={`Updated ${resourceTypeName}`}
          availableResources={resources}
          selectedResource={selectedResource}
          setSelectedResource={setSelectedResource}
          loading={loading}
        />
      </Modal.Body>
      <Modal.Footer
        onSecondaryButtonClick={props.onClose}
        onPrimaryButtonClick={() => {
          if (selectedResource) {
            props.onSubmit(selectedResource as ResourceFragment);
          }
        }}
        primaryButtonLabel={"Select"}
        primaryButtonDisabled={
          !selectedResource || selectedResource.id === props.currentResource?.id
        }
      />
    </Modal>
  );
};

export default AccessReviewEditResourceModal;
