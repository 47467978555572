import {
  EntityType,
  GroupType,
  RecommendationsSubscoreType,
  ResourceType,
} from "api/generated/graphql";
import EntityDropdown, {
  Group,
  Resource,
} from "components/dropdown/EntityDropdown";
import EntityTypeDropdown from "components/dropdown/EntityTypeDropdown";
import { Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { Dispatch, SetStateAction, useCallback, useEffect } from "react";
import { useHistory, useLocation } from "react-router";

import * as styles from "./CustomRemediationScope.css";

export interface ScopeFilter {
  entities?: (Resource | Group)[];
  riskFactors?: RecommendationsSubscoreType[];
  principalTypes?: EntityType[];
  entityTypes?: (ResourceType | GroupType)[];
  searchQuery?: string;
}
const SCOPE_FILTER_URL_KEY = "filters";

// Fetches the current filters from the URL
export const useScopeFilterState = (): ScopeFilter => {
  const { search } = useLocation();
  if (!search) {
    return {};
  }

  const params = new URLSearchParams(search);
  const data = params.get(SCOPE_FILTER_URL_KEY);

  if (!data) {
    return {};
  }

  const inputString = decodeURIComponent(atob(data));

  const parsed = JSON.parse(inputString);
  return {
    ...parsed,
  };
};

interface ScopeFilterSectionProps {
  filter: ScopeFilter;
  setFilter: Dispatch<SetStateAction<ScopeFilter>>;
}

export const isEmptyFilter = (filter: ScopeFilter): boolean => {
  return (
    (!filter.entities || filter.entities.length == 0) &&
    (!filter.entityTypes || filter.entityTypes.length == 0)
  );
};

const makeFilterStateURLHash = (filterState: ScopeFilter) => {
  if (isEmptyFilter(filterState)) {
    return undefined;
  }
  const output = btoa(encodeURIComponent(JSON.stringify(filterState)));
  return `?${SCOPE_FILTER_URL_KEY}=${output}`;
};

const CustomRemediationScope = ({
  filter,
  setFilter,
}: ScopeFilterSectionProps) => {
  const history = useHistory();

  useEffect(() => {
    const hash = makeFilterStateURLHash(filter);
    if (hash) {
      history.push(hash);
    }
  }, [filter, history]);

  const pushFilter = useCallback(
    (newFilter: ScopeFilter) => {
      if (isEmptyFilter(newFilter)) {
        setFilter({});
        history.push("#");
        return newFilter;
      }
      const hash = makeFilterStateURLHash(newFilter);
      if (hash) {
        history.push(hash);
      }
    },
    [setFilter, history]
  );
  const handleSetEntityTypeFilter = (
    newEntityTypeFilter: (GroupType | ResourceType)[] | undefined
  ): void => {
    setFilter((prevFilter) => {
      const newFilter = {
        ...prevFilter,
        entityTypes:
          newEntityTypeFilter && newEntityTypeFilter.length === 0
            ? undefined
            : newEntityTypeFilter,
      };
      pushFilter(newFilter);
      return newFilter;
    });
  };

  const handleSetEntityFilter = (
    newEntityFilter: (Group | Resource)[] | undefined
  ): void => {
    setFilter((prevFilter) => {
      const newFilter = {
        ...prevFilter,
        entities:
          newEntityFilter && newEntityFilter.length === 0
            ? undefined
            : newEntityFilter,
      };
      pushFilter(newFilter);
      return newFilter;
    });
  };

  return (
    <>
      <div className={styles.card}>
        <div className={styles.title}>
          <Icon name="settings" color="gray500" />
          <b>Scope</b>
        </div>
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
            alignItems: "center",
            flexWrap: "wrap",
            width: "100%",
            justifyContent: "center",
            flexGrow: 1,
          })}
        >
          <div
            className={sprinkles({
              flexGrow: 1,
            })}
          >
            <EntityDropdown
              entityFilter={filter.entities}
              setEntityFilter={handleSetEntityFilter}
              entityTypes={filter.entityTypes}
            />
          </div>
          <div
            className={sprinkles({
              flexGrow: 1,
            })}
          >
            <EntityTypeDropdown
              entityTypes={filter.entityTypes}
              setEntityTypeFilter={handleSetEntityTypeFilter}
              overrideEntityTypes={filter.entities // When entities are selected, only show those entity's types
                ?.map((entity) => {
                  if (entity.entityType === EntityType.Resource) {
                    return (entity as Resource).resourceType;
                  } else if (entity.entityType === EntityType.Group) {
                    return (entity as Group).groupType;
                  }
                  return undefined;
                })
                .filter(
                  (type): type is ResourceType | GroupType => type !== undefined
                )}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomRemediationScope;
