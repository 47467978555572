import {
  AddResourceTagInput,
  ConnectionType,
  EntityType,
  Resource,
  ResourceDropdownPreviewFragment,
  ResourceType,
  useAddResourceTagsMutation,
  useConnectionsSummaryQuery,
  usePaginatedResourceDropdownLazyQuery,
  useSearchResourcesQuery,
  useTagQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import FullscreenViewTitle from "components/fullscreen_modals/FullscreenViewTitle";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import {
  Banner,
  Divider,
  EntityIcon,
  Icon,
  Input,
  Label,
  Loader,
} from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router";
import { useDebouncedValue } from "utils/hooks";
import { logError, logWarning } from "utils/logging";
import { formatResourceBreadcrumb } from "utils/resources";
import { useTransitionBack } from "utils/router/hooks";
import { ForbiddenPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";

import * as styles from "./TagAddResourcesView.css";

const PAGE_SIZE = 100;

interface TagResourceRow {
  id: string;
  icon?: IconData;
  name: string;
  sublabel?: string;
  connectionType?: ConnectionType;
  connectionId?: string;
  resourceType?: ResourceType;
  isEmpty?: boolean;
}

const TagAddResourcesView = () => {
  const transitionBack = useTransitionBack();
  const { tagId } = useParams<Record<string, string>>();
  const { displaySuccessToast } = useToast();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  const [resourceById, setResourceById] = useState<{
    [resourceId: string]: ResourceDropdownPreviewFragment;
  }>({});
  const [resourcesByConnectionId, setResourcesByConnectionId] = useState<{
    [connectionId: string]: ResourceDropdownPreviewFragment[];
  }>({});
  const [resourcesByParentId, setResourcesByParentId] = useState<{
    [resourceId: string]: ResourceDropdownPreviewFragment[];
  }>({});
  const [resourcesToAddByResourceId, setResourcesToAddByResourceId] = useState<
    Record<string, Resource>
  >({});
  const [selectedRowIds, setSelectedRowIds] = useState<Set<string>>(new Set());
  const rowsById: Record<string, TagResourceRow> = {};
  const [itemsLoadingSubRows, setItemsLoadingSubRows] = useState<string[]>([]);
  const [addResourcesErrorMessage, setAddResourcesErrorMessage] = useState("");
  const numResourcesToAdd = Object.keys(resourcesToAddByResourceId).length;

  const COLUMNS: Header<TagResourceRow>[] = [
    {
      id: "name",
      label: "Name",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <div className={styles.nameCell}>
            <Label label={row.name} icon={row.icon} detail={row.sublabel} />
            {itemsLoadingSubRows.includes(row.id) && <Loader size="xs" />}
          </div>
        );
      },
      width: 500,
    },
  ];

  const { authState } = useContext(AuthContext);

  // Get Tag Data
  const { data: tagData, loading: tagLoading, error: tagError } = useTagQuery({
    variables: { input: { id: tagId ?? "" } },
    fetchPolicy: "cache-and-network",
    skip: tagId == null,
  });

  const tag =
    tagData?.tag.__typename === "TagResult" ? tagData.tag.tag : undefined;

  const disabledResourceIds = new Set();

  // Get Connection Data
  const {
    data: connectionsData,
    loading: connectionsLoading,
    error: connectionsError,
  } = useConnectionsSummaryQuery({
    variables: { input: {} },
    fetchPolicy: "cache-and-network",
    skip: tagId == null,
  });

  const allConnections = connectionsData?.connections.connections ?? [];
  const connections = allConnections.filter((connection) =>
    Boolean(connection.numResources)
  );

  // Allow searching directly for resources
  const {
    data: searchResourcesData,
    loading: searchResourcesLoading,
    error: searchResourcesError,
  } = useSearchResourcesQuery({
    variables: {
      query: debouncedSearchQuery,
      maxNumEntries: PAGE_SIZE,
    },
    skip: debouncedSearchQuery === "",
  });

  useEffect(() => {
    setResourceById((resourceById) => {
      return {
        ...resourceById,
        ...searchResourcesData?.resources.resources.reduce((acc, resource) => {
          acc[resource.id] = resource;
          return acc;
        }, {} as typeof resourceById),
      };
    });
  }, [searchResourcesData]);

  const tagResourceIds = new Set(
    tag?.tagResources.map((resource) => resource.resourceId)
  );

  // On each render, add all retrieved resources to disabledResourceIds if they are a resource of the tag
  Object.keys(resourceById).forEach((resourceId) => {
    if (tagResourceIds.has(resourceId)) {
      disabledResourceIds.add(resourceId);
    }
  });

  const [getResources] = usePaginatedResourceDropdownLazyQuery();
  const [
    addResourceTags,
    { loading: addLoading },
  ] = useAddResourceTagsMutation();

  if (tagLoading || connectionsLoading) {
    return <FullscreenSkeleton />;
  }
  if (!tag || tagError || connectionsError) {
    return <UnexpectedErrorPage error={tagError || connectionsError} />;
  }

  if (!authState.user?.isAdmin) {
    return <ForbiddenPage />;
  }

  const handleClose = () => {
    transitionBack(`/tags/${tagId}/#resources`);
  };

  // retrieve and populate resources by resource id
  const handleFetchResources = async (
    connectionId: string,
    connectionType?: ConnectionType,
    parentResourceId?: string
  ) => {
    let resourceTypes: ResourceType[] | undefined;
    if (connectionType === ConnectionType.AwsSso) {
      resourceTypes = [ResourceType.AwsAccount];
    }
    try {
      setItemsLoadingSubRows((prev) => [
        ...prev,
        parentResourceId ?? connectionId,
      ]);
      const { data } = await getResources({
        variables: {
          input: {
            connectionIds: parentResourceId ? undefined : [connectionId],
            resourceTypes,
            parentResourceId: parentResourceId
              ? {
                  parentResourceId,
                }
              : undefined,
            maxNumEntries: PAGE_SIZE,
          },
        },
      });

      if (parentResourceId) {
        setResourcesByParentId((resourcesByParentId) => {
          return {
            ...resourcesByParentId,
            [parentResourceId]: data?.resources.resources ?? [],
          };
        });
      } else {
        setResourcesByConnectionId((resourcesByConnectionId) => {
          return {
            ...resourcesByConnectionId,
            [connectionId]: data?.resources.resources ?? [],
          };
        });
      }
      setResourceById((resourceById) => {
        return {
          ...resourceById,
          ...data?.resources.resources.reduce((acc, resource) => {
            acc[resource.id] = resource;
            if (tagResourceIds.has(resource.id)) {
              disabledResourceIds.add(resource.id);
            }
            return acc;
          }, {} as typeof resourceById),
        };
      });
      setItemsLoadingSubRows((prev) =>
        prev.filter((id) => id !== (parentResourceId ?? connectionId))
      );
      return data?.resources.resources ?? [];
    } catch (err) {
      logError(err, "Failed to fetch resources for connection " + connectionId);
    }
  };

  const hasNestedRows = (row: TagResourceRow) => {
    return (
      Boolean(row.connectionType) ||
      row.resourceType === ResourceType.AwsAccount
    );
  };

  const updateChildRows = async (row: TagResourceRow, checked: boolean) => {
    if (checked) {
      try {
        setSelectedRowIds((selectedRowIds) => {
          selectedRowIds.add(row.id);
          return selectedRowIds;
        });

        // update the children
        if (hasNestedRows(row)) {
          let children: ResourceDropdownPreviewFragment[] | undefined;
          if (row.connectionType) {
            if (resourcesByConnectionId[row.id]) {
              children = resourcesByConnectionId[row.id];
            } else {
              children = await handleFetchResources(row.id, row.connectionType);
            }
          } else if (row.resourceType === ResourceType.AwsAccount) {
            if (resourcesByParentId[row.id]) {
              children = resourcesByParentId[row.id];
            } else {
              children = await handleFetchResources(
                "",
                row.connectionType,
                row.id
              );
            }
          }
          ReactDOM.unstable_batchedUpdates(() => {
            children
              ?.filter((resource) => !disabledResourceIds.has(resource.id))
              .forEach((child) => {
                updateChildRows(child, checked);
              });
          });
        } else {
          setResourcesToAddByResourceId((prev) => {
            return {
              ...prev,
              [row.id]: row as Resource,
            };
          });
        }
      } catch (err) {
        logError(err, "Failed to fetch resources for connection");
      }
    } else {
      setSelectedRowIds((selectedRowIds) => {
        const filteredRowIds: Set<string> = new Set();
        selectedRowIds.forEach((id) => {
          if (id !== row.id && !disabledResourceIds.has(id)) {
            filteredRowIds.add(id);
          }
        });
        return filteredRowIds;
      });
      if (hasNestedRows(row)) {
        const children = getNestedRows(row);
        children?.forEach((child) => {
          updateChildRows(child, checked);
        });
      } else {
        setResourcesToAddByResourceId((prev) => {
          const prevResources = { ...prev };
          delete prevResources[row.id];
          return prevResources;
        });
      }
    }
  };

  const onCheckRow = async (row: TagResourceRow, checked: boolean) => {
    if (checked) {
      setSelectedRowIds((selectedRowIds) => {
        selectedRowIds.add(row.id);
        return selectedRowIds;
      });

      // handle if this is a leaf row
      if (!hasNestedRows(row)) {
        setResourcesToAddByResourceId((prev) => {
          return {
            ...prev,
            [row.id]: row as Resource,
          };
        });
      }
    } else {
      setSelectedRowIds((selectedRowIds) => {
        const filteredRowIds: Set<string> = new Set();
        selectedRowIds.forEach((id) => {
          if (id !== row.id) {
            filteredRowIds.add(id);
          }
        });
        return filteredRowIds;
      });
      if (!hasNestedRows(row)) {
        setResourcesToAddByResourceId((prev) => {
          const prevResources = { ...prev };
          delete prevResources[row.id];
          return prevResources;
        });
      }
    }

    // handle all sub rows
    if (hasNestedRows(row)) {
      let children: ResourceDropdownPreviewFragment[] | undefined;
      if (row.connectionType) {
        if (resourcesByConnectionId[row.id]) {
          children = resourcesByConnectionId[row.id];
        } else {
          children = await handleFetchResources(row.id, row.connectionType);
        }
      } else if (row.resourceType === ResourceType.AwsAccount) {
        if (resourcesByParentId[row.id]) {
          children = resourcesByParentId[row.id];
        } else {
          children = await handleFetchResources("", row.connectionType, row.id);
        }
      }
      ReactDOM.unstable_batchedUpdates(() => {
        children
          ?.filter((resource) => !disabledResourceIds.has(resource.id))
          .forEach((child) => {
            updateChildRows(child, checked);
          });
      });
    }
  };

  const onCheckedRowsChange = async (
    checkedRowIds: string[],
    checked: boolean
  ) => {
    if (checked) {
      // add checkedRowIds to selectedRowIds
      setSelectedRowIds((prev) => {
        checkedRowIds.forEach((checkedRowId) => prev.add(checkedRowId));
        return prev;
      });
    } else {
      // remove checkdRowIds from selectedRowIds
      setSelectedRowIds((selectedRowIds) => {
        const filteredRowIds: Set<string> = new Set();
        selectedRowIds.forEach((id) => {
          if (!checkedRowIds.includes(id)) {
            filteredRowIds.add(id);
          }
        });
        return filteredRowIds;
      });
    }

    // handle each checkedRowId
    checkedRowIds.forEach((id) => {
      const row = rowsById[id];
      onCheckRow(row, checked);
    });
  };

  const handleSubmit = async () => {
    if (tag === null) {
      return;
    }
    const resourceTagsToAdd: AddResourceTagInput[] = Object.keys(
      resourcesToAddByResourceId
    ).map((id) => ({
      tagId: tag !== null ? tag.id : "",
      resourceId: id,
    }));

    try {
      const { data } = await addResourceTags({
        variables: {
          input: {
            resourceTags: resourceTagsToAdd,
          },
        },
        refetchQueries: ["ResourceTags", "Tag"],
      });
      switch (data?.addResourceTags.__typename) {
        case "AddResourceTagsResult": {
          const resourceTags = data.addResourceTags.entries
            .filter(
              (entry) => entry.__typename === "AddResourceTagsEntryResult"
            )
            .map((entry) => entry.resourceTag);

          if (resourceTags.length !== resourceTagsToAdd.length) {
            setAddResourcesErrorMessage(
              `Error: tag was not added to selected resources successfully`
            );
          } else {
            handleClose();
            displaySuccessToast(
              `Success: tag added to ${pluralize(
                "resources",
                resourceTagsToAdd.length,
                true
              )}`
            );
          }
          break;
        }
        case "ResourceNotFoundError":
        case "TagNotFoundError": {
          logWarning(new Error(data.addResourceTags.message));
          setAddResourcesErrorMessage(data.addResourceTags.message);
          break;
        }
        default:
          logError(new Error(`failed to add tag to resources`));
          setAddResourcesErrorMessage("Error: failed to add tag to resources");
      }
    } catch (error) {
      logError(error, `failed to add tag to resources`);
      setAddResourcesErrorMessage("Error: failed to add tag to resources");
    }
  };

  const getCheckboxDisabledReason = (row: TagResourceRow) => {
    if (row?.isEmpty) {
      return "No resources";
    }
    if (disabledResourceIds.has(row.id)) {
      return "Already in tag";
    }
  };

  const onRowClick = async (row: TagResourceRow) => {
    if (row.isEmpty || disabledResourceIds.has(row.id)) {
      return;
    }
    if (hasNestedRows(row)) {
      if (row.connectionType && !resourcesByConnectionId[row.id]) {
        await handleFetchResources(row.id, row.connectionType);
      }
      if (
        row.resourceType === ResourceType.AwsAccount &&
        !resourcesByParentId[row.id]
      ) {
        await handleFetchResources("", row.connectionType, row.id);
      }
    } else {
      onCheckRow(row, !selectedRowIds.has(row.id));
    }
  };

  const getNestedRows = (row: TagResourceRow) => {
    const resources = row.connectionType
      ? resourcesByConnectionId[row.id]
      : resourcesByParentId[row.id];
    if (resources && resources.length === 0) {
      return [
        {
          id: `${row.id}-empty`,
          name: "No resources",
          isEmpty: true,
        },
      ];
    }
    return resources?.map((resource) => {
      const iconData: IconData = {
        type: "entity",
        entityType: resource.resourceType,
      };
      const row: TagResourceRow = {
        id: resource.id,
        icon: iconData,
        name: resource.name,
        resourceType: resource.resourceType,
        connectionId: resource?.connection?.id,
        sublabel: formatResourceBreadcrumb(
          resource.ancestorPathToResource,
          null
        ),
      };
      rowsById[row.id] = row;
      return row;
    });
  };

  const renderConnectionsList = () => {
    const rows: TagResourceRow[] = connections.map((connection) => {
      const row: TagResourceRow = {
        id: connection.id,
        icon: {
          type: "src",
          icon: getConnectionTypeInfo(connection.connectionType)?.icon,
        },
        name: connection.name,
        connectionType: connection.connectionType,
      };
      rowsById[row.id] = row;
      return row;
    });

    return (
      <Table
        columns={COLUMNS}
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        getRowCanExpand={(row) => hasNestedRows(row.original)}
        loadingRows={tagLoading || connectionsLoading || searchResourcesLoading}
        defaultSortBy="name"
        checkedRowIds={selectedRowIds}
        onCheckedRowsChange={onCheckedRowsChange}
        getCheckboxDisabledReason={getCheckboxDisabledReason}
        onRowClick={onRowClick}
        onExpandRow={(row) => {
          if (row.connectionType && !resourcesByConnectionId[row.id]) {
            handleFetchResources(row.id, row.connectionType);
          }
          if (
            row.resourceType === ResourceType.AwsAccount &&
            !resourcesByParentId[row.id]
          ) {
            handleFetchResources("", row.connectionType, row.id);
          }
        }}
        getChildRows={getNestedRows}
        expandOnChecked={true}
        expandOnRowClick={true}
      />
    );
  };

  const renderSearchList = () => {
    if (searchResourcesError) {
      return <ModalErrorMessage errorMessage={searchResourcesError.message} />;
    }

    const filteredResources = (
      searchResourcesData?.resources.resources ?? []
    ).filter((resource) => !disabledResourceIds.has(resource.id));

    const rows: TagResourceRow[] = filteredResources.map((resource) => {
      const iconData: IconData = {
        type: "entity",
        entityType: resource.resourceType,
      };

      const row: TagResourceRow = {
        id: resource.id,
        icon: iconData,
        name: resource.name,
        resourceType: resource.resourceType,
        connectionId: resource?.connection?.id,
        sublabel: formatResourceBreadcrumb(
          resource.ancestorPathToResource,
          null,
          resource.connection?.name
        ),
      };
      rowsById[row.id] = row;
      return row;
    });

    return (
      <Table
        columns={COLUMNS}
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        getRowCanExpand={(row) => hasNestedRows(row.original)}
        loadingRows={tagLoading || connectionsLoading || searchResourcesLoading}
        defaultSortBy="name"
        checkedRowIds={selectedRowIds}
        onCheckedRowsChange={onCheckedRowsChange}
        getCheckboxDisabledReason={getCheckboxDisabledReason}
        onRowClick={onRowClick}
        getChildRows={getNestedRows}
      />
    );
  };

  return (
    <FullscreenView
      title={
        <FullscreenViewTitle
          entityType={EntityType.Tag}
          entityName={tag.key}
          targetEntityName="Resources"
          action="add"
        />
      }
      onCancel={handleClose}
      onPrimaryButtonClick={handleSubmit}
      primaryButtonDisabled={numResourcesToAdd === 0}
      primaryButtonLabel={`Add ${
        numResourcesToAdd ? numResourcesToAdd : ""
      } ${pluralize("resource", numResourcesToAdd)}`}
      primaryButtonLoading={addLoading}
    >
      <FullscreenView.Content fullWidth>
        <div className={styles.headerContainer}>
          <div className={styles.header}>
            Select resources to add to the tag:
          </div>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder="Search by name"
              autoFocus
            />
          </div>
          <div className={styles.resultsHint}>
            {debouncedSearchQuery === ""
              ? "Showing first 100 resources in each app. Use search to find more results."
              : "Showing first 100 search results. Refine your search to find more."}
          </div>
          <Divider />
          {debouncedSearchQuery === ""
            ? renderConnectionsList()
            : renderSearchList()}
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {addResourcesErrorMessage && (
          <Banner
            message={addResourcesErrorMessage}
            type="error"
            marginBottom="lg"
          />
        )}
        <div className={styles.addLabel}>
          Adding {numResourcesToAdd} {pluralize("Resource", numResourcesToAdd)}
        </div>
        {Object.keys(resourcesToAddByResourceId).map((resourceId) => {
          const resource = resourceById[resourceId];
          if (!resource) {
            return null;
          }

          return (
            <ResourceCard
              key={resource.id}
              resource={resource}
              onRemove={() => {
                setResourcesToAddByResourceId((prev) => {
                  const prevResources = { ...prev };
                  delete prevResources[resourceId];
                  return prevResources;
                });
                setSelectedRowIds((selectedRowIds) => {
                  selectedRowIds.delete(resource.id);
                  return selectedRowIds;
                });
              }}
            />
          );
        })}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};

interface Props {
  resource: ResourceDropdownPreviewFragment;
  onRemove: () => void;
}

export const ResourceCard = (props: Props) => {
  const { resource } = props;

  if (!resource.connection) {
    return null;
  }

  return (
    <div key={resource.id} className={styles.resourceCard}>
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "flex-start",
          gap: "sm",
        })}
      >
        <div
          className={sprinkles({
            flexShrink: 0,
          })}
        >
          <EntityIcon
            type={resource.connection.connectionType}
            iconStyle="rounded"
          />
        </div>
        <div className={styles.resourceInfoSection}>
          <div className={styles.resourceCardHeader}>{resource.name}</div>
          <div className={styles.resourceCardSubtitle}>
            {resource.parentResource
              ? `${resource.parentResource.name} / `
              : ""}
            {resource.connection.name}
          </div>
          <div className={styles.resourceCardType}>
            <EntityIcon type={resource.resourceType} includeBrand={false} />
            {resourceTypeInfoByType[resource.resourceType].name}
          </div>
        </div>
        <div className={sprinkles({ flexShrink: 0 })}>
          <Icon name="trash" color="red600V3" onClick={props.onRemove} />
        </div>
      </div>
    </div>
  );
};

export default TagAddResourcesView;
