import { EntityType, HrIdpStatus } from "api/generated/graphql";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import MuiVirtualTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MuiVirtualTable";
import { Icon, Label } from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import _ from "lodash";
import { useHistory } from "react-router";
import { SortDirection } from "react-virtualized";
import { getResourceUrlNew } from "utils/common";

interface User {
  id: string;
  fullName: string;
  avatarUrl: string;
  position: string;
  hrIdpStatus?: HrIdpStatus | null;
}

interface UsersTableRow {
  name: string;
  hrIdpStatus: string;
  position: string;
  order: number;
}

type OwnerUsersTableProps = {
  users: User[];
  hasEscalation: boolean;
};

const OwnerUsersTable = (props: OwnerUsersTableProps) => {
  const history = useHistory();

  const usersTableHeaders: Header<UsersTableRow>[] = [
    {
      id: "name",
      label: "Name",
      width: 300,
      minWidth: 250,
    },
    {
      id: "hrIdpStatus",
      label: "Status",
      width: 200,
      minWidth: 150,
    },
    {
      id: "position",
      label: "Title",
      width: 200,
      minWidth: 150,
    },
  ];

  if (props.hasEscalation) {
    usersTableHeaders.push({
      id: "order",
      label: "Escalation Order",
      width: 200,
      minWidth: 150,
    });
  }

  let userRows: CellRow<UsersTableRow>[] = props.users.map((user, index) => ({
    id: user?.id || "",
    data: {
      name: {
        value: user.fullName || "",
        element: (
          <Label
            label={user.fullName}
            entityType={EntityType.User}
            icon={
              user.avatarUrl
                ? {
                    type: "src",
                    icon: user.avatarUrl,
                    fallbackIcon: defaultAvatarURL,
                  }
                : undefined
            }
            inactive={user.hrIdpStatus !== HrIdpStatus.Active}
          />
        ),
        clickHandler: () => {
          history.push(
            getResourceUrlNew({
              entityId: user.id,
              entityType: EntityType.User,
            })
          );
        },
      },
      hrIdpStatus: {
        value: user.hrIdpStatus || "",
        element: <>{_.startCase(_.camelCase(user.hrIdpStatus ?? "None"))}</>,
      },
      position: {
        value: user.position || "",
        element: <>{user.position || "--"}</>,
      },
      order: {
        value: index + 1,
        element: <>{index + 1}</>,
      },
    },
  }));

  return (
    <EmptyStateContentWrapper
      content={
        <MuiVirtualTable
          columns={usersTableHeaders}
          rows={userRows}
          defaultSortBy="order"
          defaultSortDirection={SortDirection.ASC}
          allRowsLoaded
        />
      }
      isEmpty={userRows.length === 0}
      icon={<Icon name="user" />}
      title="No users"
      small={false}
    />
  );
};

export default OwnerUsersTable;
