import { EntityId } from "api/common/common";
import {
  GroupAccessLevel,
  GroupDropdownPreviewFragment,
  RequestCustomMetadataInput,
  RequestDecisionLevel,
  ResourceAccessLevel,
  SupportTicketPreviewFragment,
  UserDropdownPreviewFragment,
} from "api/generated/graphql";
import { AccessRequestState } from "views/access_request/AccessRequestContext";

export interface MfaCustomParams {
  // For starting a session
  resourceId?: string;
  accessLevel?: ResourceAccessLevel;
  groupId?: string;
  forceNewSession?: boolean;

  // For approving requests
  requestDecisionLevel?: RequestDecisionLevel;
  requestAdminApprovalReason?: string;

  // For requesting access
  requestReason?: string;
  requestDurationInMinutes?: number;
  requestAccessLevel?: ResourceAccessLevel | GroupAccessLevel;
  requestSelectedRoleByItemId?: Record<
    EntityId,
    ResourceAccessLevel | GroupAccessLevel
  >;
  requestTarget?: UserDropdownPreviewFragment | GroupDropdownPreviewFragment;
  requestSupportTicket?: SupportTicketPreviewFragment | null;
  requestCustomFields?: Record<string, RequestCustomMetadataInput>;

  // New request access state, just serialize the whole state rather than certain fields
  requestState?: AccessRequestState;

  // For presigned requests.
  presignedUserToken?: string;
}

interface MfaData {
  state: string;
  params: MfaCustomParams;
}

export const setMfaData = (data: MfaData) => {
  localStorage.setItem("mfaData", JSON.stringify(data));
};

export const clearMfaData = () => {
  localStorage.removeItem("mfaData");
};

export const getMfaParams = (): MfaCustomParams | null => {
  const dataString = localStorage.getItem("mfaData");
  if (!dataString) {
    return null;
  }
  const data = JSON.parse(dataString);
  return data.params;
};

export const getMfaState = (): string | null => {
  const dataString = localStorage.getItem("mfaData");
  if (!dataString) {
    return null;
  }
  const data = JSON.parse(dataString);
  return data.state;
};
