// eslint-disable-next-line no-restricted-imports
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import {
  AccessReviewAction,
  ReviewConnectionUserAction,
  ReviewGroupResourceAction,
  ReviewGroupUserAction,
  ReviewResourceResourceAction,
  ReviewResourceUserAction,
} from "api/generated/graphql";
import _ from "lodash";
import React from "react";
import styles from "views/access_reviews/AccessReviewAcceptRevokeToggleButton.module.scss";

type AcceptRevokeToggleButtonProps = {
  state: AccessReviewAction;
  onStateChange: (state: AccessReviewAction) => void;
  acceptText?: string;
  revokeText?: string;
  updateText?: string;
};

const AcceptRevokeToggleButton = (props: AcceptRevokeToggleButtonProps) => {
  let acceptText = props.acceptText || "Accept";
  let revokeText = props.revokeText || "Revoke";
  let updateText = props.revokeText || "Update";

  return (
    <div>
      <ToggleButtonGroup
        value={props.state}
        className={styles.buttonGroup}
        exclusive
        onChange={(event, newState) =>
          props.onStateChange(newState || AccessReviewAction.NoAction)
        }
      >
        <ToggleButton
          className={styles.accept}
          value={AccessReviewAction.Accept}
        >
          {acceptText}
        </ToggleButton>
        <ToggleButton
          className={
            props.state === AccessReviewAction.Update
              ? styles.update
              : styles.revoke
          }
          value={
            props.state === AccessReviewAction.Update
              ? AccessReviewAction.Update
              : AccessReviewAction.Revoke
          }
        >
          {props.state === AccessReviewAction.Update ? updateText : revokeText}
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
};

type GenericAccessReviewAction =
  | ReviewResourceUserAction
  | ReviewGroupUserAction
  | ReviewGroupResourceAction
  | ReviewConnectionUserAction
  | ReviewResourceResourceAction;

export const calculateBatchAcceptRevokeState = (
  actions: GenericAccessReviewAction[],
  numReviewableItems: number
): AccessReviewAction => {
  if (actions.length !== numReviewableItems) {
    return AccessReviewAction.NoAction;
  }

  if (
    _.every(actions, (action) => action.action === AccessReviewAction.Accept)
  ) {
    return AccessReviewAction.Accept;
  } else if (
    _.every(actions, (action) => action.action === AccessReviewAction.Revoke)
  ) {
    return AccessReviewAction.Revoke;
  }

  return AccessReviewAction.NoAction;
};

export default AcceptRevokeToggleButton;
