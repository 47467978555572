import { Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import React, { useEffect, useState } from "react";

const getRefreshInterval = (timestamp: moment.Moment) => {
  if (moment().diff(timestamp, "hours") >= 22) {
    // When displaying "a day ago", refresh every hour
    return 3600000;
  } else if (moment().diff(timestamp, "seconds") >= 45) {
    // When displaying "a minute ago / 13 hours ago", refresh every minute
    return 60000;
  } else {
    // When displaying "a few seconds ago", refresh every 10 seconds
    return 10000;
  }
};

type SelfRefreshingTimestampProps = {
  timestamp: moment.Moment;
  // Show the exact time in parentheses after the formatted time, e.g.:
  // "Last Wednesday at 5:00 PM (3/23/2022 5:00 PM)"
  showParens?: boolean;
  // Show the exact time in a tooltip.
  showTooltip?: boolean;
};

export const SelfRefreshingTimestamp = (
  props: SelfRefreshingTimestampProps
) => {
  // Helper that lets us refresh the component as desired.
  const [currentTime, setCurrentTime] = useState<moment.Moment>(moment());
  const refreshInterval = getRefreshInterval(props.timestamp);

  // Refresh every 60 seconds and updated the display text
  useEffect(() => {
    if (refreshInterval > 0) {
      const timer = setInterval(() => {
        setCurrentTime(moment());
      }, refreshInterval);
      return () => clearInterval(timer);
    }
  }, [currentTime, refreshInterval]);

  let displayText = props.timestamp.fromNow();
  if (props.showParens) {
    displayText = `${displayText} (${props.timestamp.format("llll")})`;
  }

  if (props.showTooltip) {
    return (
      <div className={sprinkles({ display: "flex" })}>
        <Tooltip tooltipText={props.timestamp.format("llll")}>
          <div>{displayText}</div>
        </Tooltip>
      </div>
    );
  }

  return <span>{displayText}</span>;
};
