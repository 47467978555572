import {
  HrIdpStatus,
  UserFragment,
  useTeamsQuery,
  useTitlesQuery,
  useUserQuery,
} from "api/generated/graphql";
import { PaginatedUserDropdown } from "components/dropdown/PaginatedUserDropdown";
import { Input, Select } from "components/ui";
import TableFilters from "components/ui/table/TableFilters";
import { LocationDescriptorObject } from "history";
import _ from "lodash";
import { useState } from "react";
import { useHistory } from "react-router";

import * as styles from "./UserFilterBarV3.css";
import {
  buildFilterURLParams,
  formatHrIdpStatus,
  useUsersFilter,
} from "./utils";

interface UserFilterBarV3Props {
  route?: LocationDescriptorObject;
  forOpalTable?: boolean; // OpalTable handles filter layout itself
}

const UserFilterBarV3 = ({ route, forOpalTable }: UserFilterBarV3Props) => {
  const history = useHistory();
  const usersFilter = useUsersFilter();
  const [titleSearchFilter, setTitleSearchFilter] = useState("");
  const handleTitleSearchInputChange = _.debounce((s: string) => {
    setTitleSearchFilter(s);
  }, 250);

  const [teamSearchFilter, setTeamSearchFilter] = useState("");
  const handleTeamSearchInputChange = _.debounce((s: string) => {
    setTeamSearchFilter(s);
  }, 250);

  const setFiltersInfosState = (newState: typeof usersFilter) => {
    const newParams = buildFilterURLParams(newState);
    const searchParameters = new URLSearchParams(route?.search);
    // Allow the new parameters to overwrite any conflicting existing parameters.
    // There *shouldn't* be any conflicts, but this is a safety measure. Without it,
    // the same parameter could be added multiple times in the query string.
    newParams.forEach((value, key) => {
      searchParameters.set(key, value);
    });
    history.push({
      search: searchParameters.toString(),
      hash: route?.hash,
    });
  };

  const { data: teamsData } = useTeamsQuery({
    variables: {
      searchQuery: teamSearchFilter === "" ? undefined : teamSearchFilter,
    },
  });

  const { data: titlesData } = useTitlesQuery({
    variables: {
      searchQuery: titleSearchFilter === "" ? undefined : titleSearchFilter,
    },
  });

  let managerUser: UserFragment | undefined;
  const { data: managerUserData } = useUserQuery({
    variables: {
      input: {
        id: usersFilter.manager ?? "",
      },
    },
    skip: !usersFilter.manager,
  });

  switch (managerUserData?.user.__typename) {
    case "UserResult":
      managerUser = managerUserData.user.user;
  }

  const content = (
    <>
      <div className={styles.searchFilter}>
        <Input
          leftIconName="search"
          type="search"
          style="search"
          placeholder="Search by name or email"
          value={usersFilter.search}
          onChange={(s) => {
            setFiltersInfosState({
              ...usersFilter,
              search: s === "" ? undefined : s,
            });
          }}
        />
      </div>
      <div className={styles.usersFilter}>
        <PaginatedUserDropdown
          value={managerUser}
          onChange={(manager) =>
            setFiltersInfosState({
              ...usersFilter,
              manager: manager?.id,
            })
          }
          clearable
          placeholder="Select manager"
          includeOnlyManagers
          size="sm"
        />
      </div>
      <Select
        options={teamsData?.teams?.teams.map((datum) => datum.name) ?? []}
        onChange={(team) => {
          setFiltersInfosState({
            ...usersFilter,
            team,
          });
        }}
        onInputChange={handleTeamSearchInputChange}
        getOptionLabel={(teamName) => teamName}
        placeholder="Team"
        value={usersFilter.team}
        clearable
        disableBuiltInFiltering
        popperForceDownward
        size="sm"
      />
      <Select
        options={titlesData?.titles?.titles.map((datum) => datum.name) ?? []}
        onChange={(title) => {
          setFiltersInfosState({
            ...usersFilter,
            title,
          });
        }}
        onInputChange={handleTitleSearchInputChange}
        getOptionLabel={(title) => title}
        placeholder="Title"
        value={usersFilter.title}
        clearable
        disableBuiltInFiltering
        popperForceDownward
        size="sm"
      />
      <Select
        options={Object.values(HrIdpStatus)}
        onChange={(hrIdpStatus) => {
          setFiltersInfosState({
            ...usersFilter,
            hrIdpStatus,
          });
        }}
        getOptionLabel={(idpStatus) => formatHrIdpStatus(idpStatus)}
        placeholder="IDP Status"
        value={usersFilter.hrIdpStatus as HrIdpStatus}
        clearable
        popperForceDownward
        size="sm"
      />
    </>
  );

  if (forOpalTable) return content;

  return (
    <TableFilters>
      <TableFilters.Left>{content}</TableFilters.Left>
    </TableFilters>
  );
};

export default UserFilterBarV3;
