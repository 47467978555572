import React from "react";

import * as styles from "./Grid.css";

export const Row: React.FC<{}> = (props) => {
  return <div className={styles.row}>{props.children}</div>;
};

export const Col: React.FC<{}> = (props) => {
  return <div className={styles.col}>{props.children}</div>;
};
