import { GeneralSettingType } from "api/generated/graphql";
import { FormRow, Icon, Select, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import { AppsContext } from "views/apps/AppsContext";
import OrgContext from "views/settings/OrgContext";

import { FormMode } from "../common";

interface Props {
  mode: FormMode;
  requireSupportTicket?: boolean;
  onChange: (requireSupportTicket?: boolean) => void;
}

const SupportTicketRequiredRow = (props: Props) => {
  const { orgState } = useContext(OrgContext);
  const { bulkMode } = useContext(AppsContext);

  const isSupportTicketRequiredGlobal =
    orgState.orgSettings?.generalSettings.some(
      (setting) => setting === GeneralSettingType.RequireSupportTicket
    ) || false;

  const label =
    props.requireSupportTicket || isSupportTicketRequiredGlobal
      ? "Required"
      : "Not required";

  const viewContent = label;

  const editContent = isSupportTicketRequiredGlobal ? (
    <div
      className={sprinkles({
        display: "flex",
        gap: "sm",
        alignItems: "center",
      })}
    >
      {label}
      <Tooltip
        tooltipText="This setting is not configurable because your org settings require all access requests
             to be linked to a support ticket that automatically expires access when the ticket is closed."
      >
        <Icon name="info-circle" size="xs" />
      </Tooltip>
    </div>
  ) : (
    <>
      <div className={sprinkles({ marginBottom: "sm" })}>
        If enabled, access requests must be linked to a support ticket that
        automatically expires access when the ticket is closed.
      </div>
      <Select
        value={props.requireSupportTicket}
        options={[true, false]}
        getOptionLabel={(opt) => (opt ? "Required" : "Not required")}
        onChange={props.onChange}
        clearable={bulkMode === "edit"}
        placeholder="Leave unchanged"
      />
    </>
  );

  return (
    <FormRow
      title="Must link ticket that auto-expires access"
      disabled={props.mode === "edit" && isSupportTicketRequiredGlobal}
    >
      {props.mode === "view" ? viewContent : editContent}
    </FormRow>
  );
};

export default SupportTicketRequiredRow;
