import { useGroupBindingsCountsQuery } from "api/generated/graphql";
import { Column, ColumnContainer } from "components/column/Column";
import OpalPage from "components/opal/layout/OpalPage";
import { Divider, Tabs } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useHistory } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { usePageTitle } from "utils/hooks";
import GroupBindingsFilters from "views/group_bindings/filters/GroupBindingsFilters";
import GroupBindingsTable from "views/group_bindings/GroupBindingsTable";
import GroupBindingSuggestionsTable from "views/group_bindings/GroupBindingSuggestionsTable";

const GroupBindings = () => {
  const history = useHistory();
  const { data } = useGroupBindingsCountsQuery({
    fetchPolicy: "cache-and-network",
  });
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);
  usePageTitle("Linked Groups"); // delete once on v3 since OpalPage handles it

  const currentTab = () => {
    if (location.hash.includes("#suggestions")) {
      return "suggestions";
    }
    return "all";
  };

  const bindingsCount = data?.groupBindings?.totalNumItems;
  const suggestionsCount = data?.groupBindingSuggestions?.totalNumItems;
  if (hasV3Nav) {
    return (
      <OpalPage
        title="Linked Groups"
        icon="link"
        tabs={[
          {
            title: "All Linked Groups",
            isSelected: currentTab() === "all",
            count: bindingsCount,
            onClick: () => history.push({ hash: "#all" }),
          },
          {
            title: "Suggestions",
            isSelected: currentTab() === "suggestions",
            count: suggestionsCount,
            onClick: () => history.push({ hash: "#suggestions" }),
          },
        ]}
      >
        <GroupBindingsFilters />
        {
          // move to nested routes eventually
          currentTab() === "suggestions" ? (
            <GroupBindingSuggestionsTable />
          ) : (
            <GroupBindingsTable />
          )
        }
      </OpalPage>
    );
  }
  return (
    <ColumnContainer>
      <Column isContent maxWidth="none">
        <>
          <div
            className={sprinkles({
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            })}
          >
            <Tabs
              round
              tabInfos={[
                {
                  title: "All Linked Groups",
                  badgeCount: bindingsCount,
                  onClick: () => history.push({ hash: "#all" }),
                  isSelected: currentTab() === "all",
                },
                {
                  title: "Suggestions",
                  badgeCount: suggestionsCount,
                  onClick: () => history.push({ hash: "#suggestions" }),
                  isSelected: currentTab() === "suggestions",
                },
              ]}
            />
          </div>
          <Divider />
        </>
        {currentTab() === "suggestions" ? (
          <>
            <GroupBindingsFilters />
            <GroupBindingSuggestionsTable />
          </>
        ) : (
          <>
            <GroupBindingsFilters />
            <GroupBindingsTable />
          </>
        )}
      </Column>
    </ColumnContainer>
  );
};

export default GroupBindings;
