import { ConnectionType, GroupType, ResourceType } from "api/generated/graphql";
import { Label } from "components/ui";

import { getConnectionTypeInfo } from "./ConnectionTypeLabel";
import { getGroupTypeInfo } from "./GroupTypeLabel";
import { getResourceTypeInfo } from "./ResourceTypeLabel";

const ItemTypeLabel = ({
  itemType,
}: {
  itemType: ResourceType | GroupType | ConnectionType;
}) => {
  let label = "";
  if (getResourceTypeInfo(itemType as ResourceType)) {
    label = getResourceTypeInfo(itemType as ResourceType)?.fullName ?? "";
  } else if (getGroupTypeInfo(itemType as GroupType)) {
    label = getGroupTypeInfo(itemType as GroupType)?.name ?? "";
  } else if (getConnectionTypeInfo(itemType as ConnectionType)) {
    label = getConnectionTypeInfo(itemType as ConnectionType)?.name ?? "";
  }

  return (
    <Label label={label} icon={{ type: "entity", entityType: itemType }} />
  );
};

export default ItemTypeLabel;
