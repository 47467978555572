import {
  BundleItemFragment,
  useDeleteBundleGroupsMutation,
} from "api/generated/graphql";
import * as styles from "components/modals/AddItemModal.css";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Checkbox, EntityIcon, Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { logError } from "utils/logging";

interface Props {
  bundleId: string;
  bundleItems: BundleItemFragment[];
  onClose: () => void;
}

const BundleRemoveGroupsModal = (props: Props) => {
  const [groupsToRemove, setGroupsToRemove] = useState<
    { groupId: string; roleRemoteId: string }[]
  >([]);
  const [error, setError] = useState("");

  const { displaySuccessToast } = useToast();
  const [deleteBundleGroups, { loading }] = useDeleteBundleGroupsMutation();

  const handleSubmit = async () => {
    try {
      const { data } = await deleteBundleGroups({
        variables: {
          input: {
            inputs: groupsToRemove.map(({ groupId, roleRemoteId }) => ({
              bundleId: props.bundleId,
              groupId,
              accessLevelRemoteId: roleRemoteId,
            })),
          },
        },
        refetchQueries: ["BundleItems", "Bundle"],
      });

      if (data?.deleteBundleGroups) {
        displaySuccessToast("Success: removed groups from bundles");
        props.onClose();
      } else {
        setError("Failed to remove groups from bundle");
      }
    } catch (err) {
      logError("Failed to remove groups from bundles");
    }
  };

  return (
    <Modal isOpen title="Remove Groups" onClose={props.onClose}>
      <Modal.Body>
        {error && <ModalErrorMessage errorMessage={error} />}
        <div className={styles.itemsContainer}>
          {props.bundleItems.map((bundleItem) => {
            if (!bundleItem.group) {
              return null;
            }
            const group = bundleItem.group;
            return (
              <div className={styles.itemRow}>
                <EntityIcon type={group.groupType} />
                <div className={sprinkles({ flexGrow: 1 })}>
                  <div className={sprinkles({ fontSize: "labelMd" })}>
                    {group.name}{" "}
                    {bundleItem.accessLevelName &&
                      `(${bundleItem.accessLevelName})`}
                  </div>
                  <div
                    className={sprinkles({
                      color: "gray500",
                      fontSize: "labelSm",
                    })}
                  >
                    {group.connection?.name}
                  </div>
                </div>
                <Checkbox
                  size="sm"
                  checked={groupsToRemove.some(
                    ({ groupId, roleRemoteId: accessLevelRemoteId }) =>
                      groupId === group.id &&
                      accessLevelRemoteId === bundleItem.accessLevelRemoteId
                  )}
                  onChange={(checked) => {
                    setGroupsToRemove((prev) => {
                      if (checked) {
                        return [
                          ...prev,
                          {
                            groupId: group.id,
                            roleRemoteId: bundleItem.accessLevelRemoteId,
                          },
                        ];
                      } else {
                        return prev.filter(
                          ({ groupId, roleRemoteId: accessLevelRemoteId }) =>
                            groupId !== group.id &&
                            accessLevelRemoteId !==
                              bundleItem.accessLevelRemoteId
                        );
                      }
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Remove"
        primaryButtonDisabled={groupsToRemove.length === 0}
        primaryButtonLoading={loading}
        onPrimaryButtonClick={handleSubmit}
      />
    </Modal>
  );
};

export default BundleRemoveGroupsModal;
