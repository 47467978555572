import { gql, useFragment } from "@apollo/client";
import {
  EntityType,
  ResourceDisplayFragment,
  ResourceType,
} from "api/generated/graphql";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import { EntityIcon, Icon, Label } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { Link } from "react-router-dom";
import { getResourceUrlNew } from "utils/common";
import { formatResourceBreadcrumb } from "utils/resources";

import * as styles from "./EntityDisplay.css";

const ResourceDisplay = ({ resourceId }: { resourceId: string }) => {
  const { complete, data: resource } = useFragment<ResourceDisplayFragment>({
    fragment: ResourceDisplay.fragment,
    fragmentName: "ResourceDisplay",
    from: {
      __typename: "Resource",
      id: resourceId,
    },
  });

  if (!complete) {
    return null;
  }

  let resourceName = resource.name;
  const entityIconType =
    resource?.connection?.connectionType ?? resource?.resourceType;
  const imageUrl = resource.imageUrl ?? resource.connection?.imageUrl;
  const breadcrumb =
    resource.resourceType !== ResourceType.OktaApp
      ? formatResourceBreadcrumb(
          resource.ancestorPathToResource,
          null,
          resource.connection?.name,
        )
      : "";

  return (
    <Link
      to={getResourceUrlNew({
        entityId: resource.id,
        entityType: EntityType.Resource,
      })}
      className={styles.displayLayout}
    >
      {imageUrl ? (
        <Icon
          data={{ type: "src", icon: imageUrl }}
          iconStyle="rounded"
          size="lg"
        />
      ) : (
        <EntityIcon type={entityIconType} iconStyle="rounded" size="lg" />
      )}
      <div className={sprinkles({ flexGrow: 1 })}>
        <div className={styles.entityName}>{resourceName}</div>
        {breadcrumb && <div className={styles.breadcrumb}>{breadcrumb}</div>}
      </div>
      <Label
        color="gray900"
        inline
        icon={{
          type: "entity",
          entityType: resource.resourceType,
          includeBrand: false,
        }}
        label={resourceTypeInfoByType[resource.resourceType].fullName}
      />
    </Link>
  );
};

ResourceDisplay.fragment = gql`
  fragment ResourceDisplay on Resource {
    id
    name
    imageUrl
    ancestorPathToResource
    connection {
      id
      name
      imageUrl
      connectionType
    }
    resourceType
  }
`;

export default ResourceDisplay;
