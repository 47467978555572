import {
  AccessOption,
  EntityType,
  SuggestionFragment,
  useSuggestionsQuery,
} from "api/generated/graphql";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { EntityIcon, Icon, Skeleton } from "components/ui";
import { ReactElement, useContext } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";
import * as styles from "views/apps/Suggestions.css";

import { ACCESS_OPTION_URL_KEY, AppsContext } from "./AppsContext";

const Suggestions = () => {
  const { data, loading, error } = useSuggestionsQuery({});
  const { bulkMode } = useContext(AppsContext);

  if (error) {
    logError(error, "Error: failed to fetch suggestions");
    return null;
  }

  if (bulkMode) {
    return null;
  }

  let suggestions: SuggestionFragment[] = [];
  switch (data?.suggestions.__typename) {
    case "SuggestionsResult":
      suggestions = data.suggestions.suggestions;
  }

  const placeholders = [];
  for (let i = suggestions.length; i < 10; i++) {
    if (loading) {
      placeholders.push(<SuggestionLoading key={`${i}-loading`} />);
    } else {
      placeholders.push(<SuggestionPlaceholder key={`${i}-placeholder`} />);
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <div className={styles.header}>Most viewed items</div>
        <div className={styles.suggestions}>
          {suggestions &&
            suggestions.map((suggestion) => {
              if (suggestion.__typename == "GroupSuggestion") {
                return (
                  <Suggestion
                    key={suggestion.groupId}
                    name={suggestion.name}
                    typeString={getGroupTypeInfo(suggestion.groupType)?.name}
                    url={getResourceUrlNew({
                      entityId: suggestion.groupId,
                      entityType: EntityType.Group,
                    })}
                    icon={<EntityIcon type={suggestion.groupType} />}
                  />
                );
              } else if (suggestion.__typename == "ResourceSuggestion") {
                return (
                  <Suggestion
                    key={suggestion.resourceId}
                    name={suggestion.name}
                    typeString={
                      getResourceTypeInfo(suggestion.resourceType)?.fullName
                    }
                    parentResourceName={
                      suggestion.parentResourceName ?? undefined
                    }
                    url={getResourceUrlNew({
                      entityId: suggestion.resourceId,
                      entityType: EntityType.Resource,
                    })}
                    icon={<EntityIcon type={suggestion.resourceType} />}
                  />
                );
              }
            })}
          {placeholders}
        </div>
        <div className={styles.footer}>
          <Icon name={"arrow-left"} size={"sm"} color={"blue600"} />
          Browse the Apps section in the side bar to request access to an app or
          resource
        </div>
      </div>
    </div>
  );
};

interface SuggestionProps {
  name: string;
  typeString?: string;
  parentResourceName?: string;
  icon: ReactElement;
  url: string;
}

const Suggestion = (props: SuggestionProps) => {
  const history = useHistory();
  const logEvent = useLogEvent();

  const sublabel = props.parentResourceName
    ? `${props.typeString} • ${props.parentResourceName}`
    : props.typeString;

  return (
    <div
      className={styles.suggestion}
      onClick={() => {
        logEvent({ name: "apps_suggestions_click" });
        history.push({
          pathname: props.url,
          search: `${ACCESS_OPTION_URL_KEY}=${AccessOption.All}`,
        });
      }}
    >
      <div className={styles.suggestionIcon}>{props.icon}</div>
      <div className={styles.suggestionText}>
        <div className={styles.suggestionName}>{props.name}</div>
        <div className={styles.suggestionType}>{sublabel}</div>
      </div>
    </div>
  );
};

const SuggestionPlaceholder = () => {
  return <div className={styles.placeholder}></div>;
};

const SuggestionLoading = () => {
  return (
    <div className={styles.suggestion}>
      <Skeleton variant="circle" width="20px" height="20px" />
      <div>
        <Skeleton width={"50px"} />
        <Skeleton width={"150px"} />
      </div>
    </div>
  );
};

export default Suggestions;
