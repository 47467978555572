import {
  BundleDetailFragment,
  BundleGroupInput,
  ConnectionType,
  GroupAccessLevel,
  GroupDropdownPreviewFragment,
  GroupType,
  useBundleItemsQuery,
  useBundleQuery,
  useConnectionsSummaryQuery,
  useCreateBundleGroupsMutation,
  useMultipleGroupAccessLevelsQuery,
  usePaginatedGroupDropdownLazyQuery,
  useSearchGroupsQuery,
} from "api/generated/graphql";
import GroupCard from "components/fullscreen_modals/GroupCard";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Banner, Divider, Icon, Input, Label, Loader } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router";
import { AuthorizedActionManage } from "utils/auth/auth";
import { groupTypeHasRoles } from "utils/directory/groups";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import { ForbiddenPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";

import * as styles from "./BundleAddGroups.css";

const PAGE_SIZE = 100;

interface BundleGroupRow {
  id: string;
  icon?: IconData;
  name: string;
  connectionType?: ConnectionType;
  connectionId?: string;
  groupType?: GroupType;
  isEmpty?: boolean;
}

const BundleAddGroupsView = () => {
  const transitionBack = useTransitionBack();
  const { bundleId } = useParams<{ bundleId: string }>();
  const { displaySuccessToast } = useToast();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  const [groupById, setGroupById] = useState<{
    [groupId: string]: GroupDropdownPreviewFragment;
  }>({});
  const [groupsByConnectionId, setGroupsByConnectionId] = useState<{
    [connectionId: string]: GroupDropdownPreviewFragment[];
  }>({});
  const [roleByGroupIdToAdd, setRoleByGroupIdToAdd] = useState<
    Record<string, GroupAccessLevel[]>
  >({});
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([]);
  const [addError, setAddError] = useState("");
  const rowsById: Record<string, BundleGroupRow> = {};
  const [itemsLoadingSubRows, setItemsLoadingSubRows] = useState<string[]>([]);

  // Get bundle and connection data
  const {
    data: bundleData,
    error: bundleError,
    loading: bundleLoading,
  } = useBundleQuery({
    variables: {
      input: {
        id: bundleId,
      },
    },
  });

  let bundle: BundleDetailFragment | undefined;
  if (bundleData?.bundle.__typename === "BundleResult") {
    bundle = bundleData.bundle.bundle;
  }

  const { data, error, loading } = useBundleItemsQuery({
    variables: {
      input: {
        bundleId,
        searchQuery,
      },
    },
    skip: !bundleId,
  });

  const allItems = data?.bundleItems.items ?? [];

  const {
    data: connectionsSummaryData,
    error: connectionsSummaryError,
    loading: connectionsSummaryLoading,
  } = useConnectionsSummaryQuery({
    variables: { input: {} },
  });

  const allConnections = connectionsSummaryData?.connections.connections ?? [];
  const connections = allConnections.filter((connection) =>
    Boolean(connection.numGroups)
  );

  // Fetch all groups that are in the bundle
  const existingRolesByGroupId: Record<string, GroupAccessLevel[]> = {};
  allItems.forEach((item) => {
    if (item.group) {
      if (item.group.id in existingRolesByGroupId) {
        existingRolesByGroupId[item.group.id].push({
          accessLevelRemoteId: item.accessLevelRemoteId,
          accessLevelName: item.accessLevelName,
        });
      } else {
        existingRolesByGroupId[item.group.id] = [
          {
            accessLevelRemoteId: item.accessLevelRemoteId,
            accessLevelName: item.accessLevelName,
          },
        ];
      }
    }
  });

  const {
    data: rolesData,
    error: rolesError,
    loading: rolesLoading,
  } = useMultipleGroupAccessLevelsQuery({
    variables: {
      input: {
        groupIds: Object.keys(existingRolesByGroupId),
      },
    },
    skip: Object.keys(existingRolesByGroupId).length === 0,
  });

  let allRoles;

  switch (rolesData?.multipleGroupAccessLevels.__typename) {
    case "MultipleGroupAccessLevelsResult":
      allRoles = rolesData.multipleGroupAccessLevels.results;
  }

  const allRolesByGroupId: Record<string, GroupAccessLevel[]> = {};
  allRoles?.forEach((role) => {
    allRolesByGroupId[role.groupId] = role.accessLevels ?? [];
  });

  // Allow searching directly for groups
  const {
    data: searchGroupsData,
    loading: searchGroupsLoading,
    error: searchGroupsError,
  } = useSearchGroupsQuery({
    variables: {
      query: debouncedSearchQuery,
      maxNumEntries: PAGE_SIZE,
    },
    skip: debouncedSearchQuery === "",
  });

  useEffect(() => {
    setGroupById((groupById) => {
      return {
        ...groupById,
        ...searchGroupsData?.groups.groups.reduce((acc, group) => {
          acc[group.id] = group;
          return acc;
        }, {} as typeof groupById),
      };
    });
  }, [searchGroupsData]);

  const [getGroups] = usePaginatedGroupDropdownLazyQuery();
  const [
    createBundleGroups,
    { loading: addLoading },
  ] = useCreateBundleGroupsMutation();

  if (bundleLoading || connectionsSummaryLoading || rolesLoading) {
    return <FullscreenSkeleton />;
  }
  if (!bundle?.authorizedActions?.includes(AuthorizedActionManage)) {
    return <ForbiddenPage />;
  }
  if (
    !bundle ||
    error ||
    bundleError ||
    connectionsSummaryError ||
    rolesError
  ) {
    return <UnexpectedErrorPage error={error} />;
  }

  const handleClose = () => {
    transitionBack(`/bundles/${bundleId}/resources`);
  };

  const handleFetchGroups = async (connectionId: string) => {
    try {
      setItemsLoadingSubRows((prev) => [...prev, connectionId]);
      const { data } = await getGroups({
        variables: {
          input: {
            connectionIds: [connectionId],
            maxNumEntries: PAGE_SIZE,
          },
        },
      });

      setGroupsByConnectionId((groupsByConnectionId) => {
        return {
          ...groupsByConnectionId,
          [connectionId]: data?.groups.groups ?? [],
        };
      });

      setGroupById((groupById) => {
        return {
          ...groupById,
          ...data?.groups.groups.reduce((acc, group) => {
            acc[group.id] = group;
            return acc;
          }, {} as typeof groupById),
        };
      });
      setItemsLoadingSubRows((prev) =>
        prev.filter((id) => id !== connectionId)
      );
      return data?.groups.groups ?? [];
    } catch (err) {
      logError(err, "Failed to fetch groups for connection " + connectionId);
    }
  };

  const title = (
    <>
      Add Groups:
      <div className={sprinkles({ display: "flex", alignItems: "center" })}>
        <Icon name="bundle" size="lg" />
      </div>
      {bundle.name}
    </>
  );

  const numGroupsToAdd = Object.keys(roleByGroupIdToAdd).length;

  // A user can only be added to a group with 1 role, so disable selecting
  // a group if it's already been added to the bundle with any role.
  const disabledGroupIds = new Set(Object.keys(existingRolesByGroupId));

  const hasNestedRows = (row: BundleGroupRow) => {
    return Boolean(row.connectionType);
  };

  const getNestedRows = (row: BundleGroupRow) => {
    const groups = groupsByConnectionId[row.id]?.filter(
      (group) => !disabledGroupIds.has(group.id)
    );
    if (groups && groups.length === 0) {
      return [
        {
          id: `${row.id}-empty`,
          name: "No groups",
          isEmpty: true,
        },
      ];
    }
    return groups?.map((group) => {
      const iconData: IconData = {
        type: "entity",
        entityType: group.groupType,
      };
      const row: BundleGroupRow = {
        id: group.id,
        icon: iconData,
        name: group.name,
        groupType: group.groupType,
        connectionId: group?.connection?.id,
      };
      rowsById[row.id] = row;
      return row;
    });
  };

  const handleSubmit = async () => {
    if (!bundle) {
      return;
    }

    try {
      const groupInputs: BundleGroupInput[] = [];
      for (const [groupId, roles] of Object.entries(roleByGroupIdToAdd)) {
        const group = groupById[groupId];
        if (!group) {
          setAddError("Failed to add groups to bundle");
          return;
        }

        if (roles.length === 0) {
          // If group requires a role , but none are selected,
          // show an error.
          if (
            groupTypeHasRoles(group.groupType) ||
            allRolesByGroupId[groupId]?.length > 0
          ) {
            setAddError(
              "Please select at least one role for groups that have roles."
            );
            return;
          } else {
            // If group does not require roles,
            // add an empty role to add the group directly.
            groupInputs.push({
              bundleId: bundleId,
              groupId,
              accessLevelName: "",
              accessLevelRemoteId: "",
            });
          }
        }

        roles.forEach((role) => {
          groupInputs.push({
            bundleId: bundleId,
            groupId,
            accessLevelName: role.accessLevelName,
            accessLevelRemoteId: role.accessLevelRemoteId,
          });
        });
      }

      const { data } = await createBundleGroups({
        variables: {
          input: {
            inputs: groupInputs,
          },
        },
        refetchQueries: ["BundleItems", "Bundle"],
      });

      if (data?.createBundleGroups.bundleGroups) {
        displaySuccessToast("Successfully added groups to bundle");
        handleClose();
      }
    } catch (err) {
      logError(err, "Failed to add groups to bundle");
      setAddError("Failed to add groups to bundle");
    }
  };

  const COLUMNS: Header<BundleGroupRow>[] = [
    {
      id: "name",
      label: "Name",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <div
            className={sprinkles({
              display: "flex",
              alignItems: "center",
              gap: "md",
            })}
          >
            <Label label={row.name} icon={row.icon} />
            {itemsLoadingSubRows.includes(row.id) && <Loader size="xs" />}
          </div>
        );
      },
      width: 500,
    },
  ];

  const getCheckboxDisabledReason = (row: BundleGroupRow) => {
    if (row?.isEmpty) {
      return "No groups";
    }
    if (disabledGroupIds.has(row.id)) {
      return "Already in bundle";
    }
  };

  const onCheckedRowsChange = async (
    checkedRowIds: string[],
    checked: boolean
  ) => {
    if (checked) {
      setSelectedRowIds((prev) => [...prev, ...checkedRowIds]);
    } else {
      setSelectedRowIds((prev) =>
        prev.filter((id) => !checkedRowIds.includes(id))
      );
    }
    checkedRowIds.forEach((id) => {
      const row = rowsById[id];
      if (hasNestedRows(row)) {
        onCheckConnection(row, checked);
      } else {
        onCheckGroup(row);
      }
    });
  };

  const onCheckGroup = async (row: BundleGroupRow) => {
    if (row.id in roleByGroupIdToAdd) {
      setSelectedRowIds((prev) =>
        prev.filter((id) => id !== row.id && id !== row.connectionId)
      );
      setRoleByGroupIdToAdd((prev) => {
        const newRoles = { ...prev };
        delete newRoles[row.id];
        return newRoles;
      });
    } else {
      setSelectedRowIds((prev) => [...prev, row.id]);
      setRoleByGroupIdToAdd((prev) => {
        return {
          ...prev,
          [row.id]: [],
        };
      });
    }
  };

  const onCheckConnection = async (row: BundleGroupRow, checked: Boolean) => {
    if (checked) {
      if (selectedRowIds.includes(row.id)) {
        return;
      }
      try {
        setSelectedRowIds((prev) => [...prev, row.id]);
        let items: GroupDropdownPreviewFragment[] | undefined;
        if (groupsByConnectionId[row.id]) {
          items = groupsByConnectionId[row.id];
        } else {
          if (row.connectionType) {
            items = await handleFetchGroups(row.id);
          }
        }
        ReactDOM.unstable_batchedUpdates(() => {
          setRoleByGroupIdToAdd((prev) => {
            const newRoles = { ...prev };
            items?.forEach((item) => {
              if (!(item.id in newRoles) && !disabledGroupIds.has(item.id)) {
                newRoles[item.id] = [];
                setSelectedRowIds((prev) => [...prev, item.id]);
              }
            });
            return newRoles;
          });
        });
      } catch (err) {
        logError(err, "Failed to fetch groups for connection");
      }
    } else {
      const items = getNestedRows(row);
      setSelectedRowIds((prev) =>
        prev.filter((id) => !items.map((row) => row.id).includes(id))
      );
      setRoleByGroupIdToAdd((prev) => {
        const newRoles = { ...prev };
        items.forEach((row) => {
          if (row.id in newRoles) {
            delete newRoles[row.id];
            return newRoles;
          }
        });
        return newRoles;
      });
    }
  };

  const onRowClick = async (row: BundleGroupRow) => {
    if (row.isEmpty || disabledGroupIds.has(row.id)) {
      return;
    }
    if (hasNestedRows(row)) {
      if (row.connectionType && !groupsByConnectionId[row.id]) {
        handleFetchGroups(row.id);
      }
    } else {
      onCheckGroup(row);
    }
  };

  const renderConnectionsList = () => {
    const rows: BundleGroupRow[] = connections.map((connection) => {
      const row: BundleGroupRow = {
        id: connection.id,
        icon: {
          type: "src",
          icon: getConnectionTypeInfo(connection.connectionType)?.icon,
        },
        name: connection.name,
        connectionType: connection.connectionType,
      };
      rowsById[row.id] = row;
      return row;
    });

    return (
      <Table
        columns={COLUMNS}
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        getRowCanExpand={(row) => hasNestedRows(row.original)}
        loadingRows={loading || searchGroupsLoading}
        defaultSortBy="name"
        checkedRowIds={new Set(selectedRowIds)}
        onCheckedRowsChange={onCheckedRowsChange}
        getCheckboxDisabledReason={getCheckboxDisabledReason}
        onRowClick={onRowClick}
        onExpandRow={(row) => handleFetchGroups(row.id)}
        getChildRows={getNestedRows}
        expandOnChecked={true}
        expandOnRowClick={true}
      />
    );
  };

  const renderSearchList = () => {
    if (searchGroupsError) {
      return <ModalErrorMessage errorMessage={searchGroupsError.message} />;
    }

    const filteredGroups = (searchGroupsData?.groups.groups ?? []).filter(
      (group) => !disabledGroupIds.has(group.id)
    );

    const rows: BundleGroupRow[] = filteredGroups.map((group) => {
      const iconData: IconData = {
        type: "entity",
        entityType: group.groupType,
      };
      const row: BundleGroupRow = {
        id: group.id,
        icon: iconData,
        name: group.name,
        groupType: group.groupType,
        connectionId: group?.connection?.id,
      };
      rowsById[row.id] = row;
      return row;
    });

    return (
      <Table
        columns={COLUMNS}
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        getRowCanExpand={(row) => hasNestedRows(row.original)}
        loadingRows={loading || searchGroupsLoading}
        defaultSortBy="name"
        checkedRowIds={new Set(selectedRowIds)}
        onCheckedRowsChange={onCheckedRowsChange}
        getCheckboxDisabledReason={getCheckboxDisabledReason}
        onRowClick={onRowClick}
        getChildRows={getNestedRows}
      />
    );
  };

  return (
    <FullscreenView
      title={title}
      onCancel={handleClose}
      onPrimaryButtonClick={handleSubmit}
      primaryButtonDisabled={numGroupsToAdd === 0}
      primaryButtonLabel={`Add ${
        numGroupsToAdd ? numGroupsToAdd : ""
      } ${pluralize("group", numGroupsToAdd)}`}
      primaryButtonLoading={addLoading}
    >
      <FullscreenView.Content fullWidth>
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            height: "100%",
            overflowY: "auto",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textMd",
              fontWeight: "medium",
              marginBottom: "md",
            })}
          >
            Select groups to add to the bundle:
          </div>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder="Search by name"
              autoFocus
            />
          </div>
          <div className={sprinkles({ color: "gray600", fontSize: "textXs" })}>
            {debouncedSearchQuery === ""
              ? "Showing first 100 groups in each app. Use search to find more results."
              : "Showing first 100 search results. Refine your search to find more."}
          </div>
          <Divider />
          {debouncedSearchQuery === ""
            ? renderConnectionsList()
            : renderSearchList()}
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {addError && (
          <Banner message={addError} type="error" marginBottom="lg" />
        )}
        <div
          className={sprinkles({
            fontSize: "textLg",
            fontWeight: "medium",
            marginBottom: "lg",
          })}
        >
          Adding {numGroupsToAdd} {pluralize("Group", numGroupsToAdd)}
        </div>
        {Object.keys(roleByGroupIdToAdd).map((groupId) => {
          const group = groupById[groupId];
          if (!group) {
            return null;
          }

          return (
            <GroupCard
              key={group.id}
              group={group}
              existingRoles={existingRolesByGroupId[groupId] ?? []}
              selectedRoles={roleByGroupIdToAdd[groupId] ?? []}
              onRemove={() => {
                setSelectedRowIds((prev) =>
                  prev.filter(
                    (id) => id !== groupId && id !== group.connection?.id
                  )
                );
                setRoleByGroupIdToAdd((prev) => {
                  const newRoles = { ...prev };
                  delete newRoles[groupId];
                  return newRoles;
                });
              }}
              onUpdateSelectedRoles={(roles) => {
                setRoleByGroupIdToAdd((prev) => {
                  return {
                    ...prev,
                    [groupId]: roles,
                  };
                });
              }}
              onUpdateAllRoles={(roles) => {
                allRolesByGroupId[group.id] = roles;
              }}
            />
          );
        })}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};

export default BundleAddGroupsView;
