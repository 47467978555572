import { SectionEntry } from "components/viewer/Viewer";
import moment from "moment";
import React from "react";

type AccessReviewSnapshotRowProps = {
  snapshotTime: string;
};

export const AccessReviewSnapshotRow = (
  props: AccessReviewSnapshotRowProps
) => {
  const snapshotTime = moment(new Date(props.snapshotTime));

  return (
    <SectionEntry
      title={"Snapshot time"}
      tooltipText={`This is when a snapshot was taken for this access review. An access review
      involves reviewing a historical snapshot of user access, not live access, because live
      access is constantly changing.`}
    >
      <>{snapshotTime.format("MMMM Do YYYY, h:mm:ss a")}</>
    </SectionEntry>
  );
};

export default AccessReviewSnapshotRow;
