import opalLogo from "assets/logos/opal-security-logo-white.svg";
import styles from "components/centered_display/CenteredDisplay.module.scss";
import React from "react";

const CenteredDisplay = (props: React.PropsWithChildren<{}>) => {
  return (
    <div className={styles.underlay}>
      <div className={styles.mainFrame}>
        <div className={styles.opalFrame}>
          <img src={opalLogo} alt="Opal logo" />
        </div>
        <div className={styles.contentFrame}>{props.children}</div>
      </div>
    </div>
  );
};

export default CenteredDisplay;
