import { ColumnContainer } from "components/column/Column";
import React from "react";
import { Switch } from "react-router";
import { Route } from "react-router-dom";

import AccessChanges from "./AccessChanges";
import AccessReviewCategoryColumn from "./AccessReviewCategoryColumn";
import AccessReviewEntities from "./AccessReviewEntities";
import AccessReviewReviewers from "./AccessReviewReviewers";
import MyAccessReviews from "./MyAccessReviews";
import UARBulkSelectBar from "./UARBulkSelectBar";
import { UARSelectContextProvider } from "./UARSelectContext";

const AccessReviews = () => {
  return (
    <UARSelectContextProvider>
      <UARBulkSelectBar />
      <Switch>
        <Route
          exact
          path={`/access-reviews/r/:accessReviewResourceId`}
          component={AccessReviewsComponent}
        />
        <Route
          exact
          path={`/access-reviews/g/:accessReviewGroupId`}
          component={AccessReviewsComponent}
        />
        <Route
          exact
          path={`/access-reviews/c/:accessReviewConnectionId`}
          component={AccessReviewsComponent}
        />
        <Route
          exact
          path={`/access-reviews/u/:accessReviewId/:userId`}
          component={AccessReviewsComponent}
        />
        <Route
          exact
          path={`/access-reviews/new`}
          component={AccessReviewsComponent}
        />
        <Route
          exact
          path={`/access-reviews/t/:accessReviewTemplateId`}
          component={AccessReviewsComponent}
        />
        <Route
          path={[
            "/access-reviews/:accessReviewId/my-reviews/r/:accessReviewResourceId",
            "/access-reviews/:accessReviewId/my-reviews/g/:accessReviewGroupId",
            "/access-reviews/:accessReviewId/my-reviews/c/:accessReviewConnectionId",
            "/access-reviews/:accessReviewId/my-reviews",
          ]}
          component={MyAccessReviews}
        />
        <Route
          path={[
            "/access-reviews/:accessReviewId/entities/r/:accessReviewResourceId",
            "/access-reviews/:accessReviewId/entities/g/:accessReviewGroupId",
            "/access-reviews/:accessReviewId/entities/c/:accessReviewConnectionId",
            "/access-reviews/:accessReviewId/entities",
          ]}
          component={AccessReviewEntities}
        />
        <Route
          path={[
            "/access-reviews/:accessReviewId/reviewers/:reviewerId",
            "/access-reviews/:accessReviewId/reviewers",
          ]}
          component={AccessReviewReviewers}
        />
        <Route
          path={[
            "/access-reviews/:accessReviewId/access-changes/r/:accessReviewResourceId",
            "/access-reviews/:accessReviewId/access-changes/g/:accessReviewGroupId",
            "/access-reviews/:accessReviewId/access-changes/c/:accessReviewConnectionId",
            "/access-reviews/:accessReviewId/access-changes",
          ]}
          component={AccessChanges}
        />
        <Route
          path={`/access-reviews/:accessReviewId`}
          component={AccessReviewsComponent}
        />
        <Route
          exact
          path={`/access-reviews`}
          component={AccessReviewsComponent}
        />
      </Switch>
    </UARSelectContextProvider>
  );
};

const AccessReviewsComponent = () => {
  return (
    <ColumnContainer>
      <AccessReviewCategoryColumn />
    </ColumnContainer>
  );
};

export default AccessReviews;
