import {
  ConnectionMetadataOutput,
  IdpConnectionType,
} from "api/generated/graphql";

export enum SetupStep {
  SELECT_IDP,
  CREDENTIALS,
  SYNC_DATA,
  IMPORT_DATA,
  FINISHED,
}

interface SetupStepInfo {
  step: SetupStep;
  label: string;
}

export const SETUP_STEPS: Record<number, SetupStepInfo> = {
  0: {
    step: SetupStep.SELECT_IDP,
    label: "Select an identity provider",
  },
  1: {
    step: SetupStep.CREDENTIALS,
    label: "Enter credentials",
  },
  2: {
    step: SetupStep.SYNC_DATA,
    label: "Sync data",
  },
  3: {
    step: SetupStep.IMPORT_DATA,
    label: "Import data",
  },
  4: {
    step: SetupStep.FINISHED,
    label: "Finished",
  },
};

export enum ImportStep {
  SELECT_MODE,
  SELECT_DATA,
  PROGRESS,
}

export const IDP_SECTIONS: Record<IdpConnectionType, string[]> = {
  [IdpConnectionType.Okta]: ["Users", "Groups", "Attributes", "Apps", "Roles"],
  [IdpConnectionType.Google]: ["Users", "Attributes", "Roles"],
  [IdpConnectionType.AzureAd]: ["Users", "Groups", "Attributes"],
  [IdpConnectionType.Workday]: ["Users", "Attributes"],
};

export const MAX_STEP = Object.keys(SETUP_STEPS).length - 1;

export const getIdpConnectionSubtitle = (
  metadata?: ConnectionMetadataOutput | null
) => {
  if (!metadata) {
    return "";
  }
  switch (metadata.__typename) {
    case "OktaDirectoryConnectionMetadata":
      return metadata.orgUrl;
    case "GoogleWorkspaceConnectionMetadata":
      return metadata.serviceAccount.projectId;
  }
  return "";
};
