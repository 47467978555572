import {
  IdpConnectionFragment,
  IdpConnectionType,
  IdpConnectionUserAttributeUseAs,
  Maybe,
  useCreateIdpConnectionUserAttributeImportMappingMutation,
  useDeleteIdpConnectionUserAttributeImportMappingMutation,
} from "api/generated/graphql";
import Label from "components/label/Label";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import idpAttributesModalStyles from "components/modals/update/IdpAttributesModal.module.scss";
import { useToast } from "components/toast/Toast";
import {
  Banner,
  Button,
  FormGroup,
  Icon,
  Input,
  Modal,
  Select,
} from "components/ui";
import React, { useState } from "react";
import * as Icons from "react-feather";
import { logError } from "utils/logging";
import { getUserAttributeUseAsOptionLabel } from "utils/user_attribute";

import ConfirmModal from "./ConfirmModal";

const textFieldNote = (
  <p>
    Only text fields are supported. Multi-value types and other types will be
    ignored.
  </p>
);

const idpNotes: Partial<Record<IdpConnectionType, React.ReactNode>> = {
  [IdpConnectionType.Google]: (
    <>
      <p>
        Google IDP supports importing user attributes using{" "}
        <a
          href="https://developers.google.com/admin-sdk/directory/v1/guides/manage-schemas"
          target="_blank"
        >
          Custom Schemas
        </a>
        .
      </p>
      <p>
        The field "item" under the "engineering" schema should be specified as{" "}
        <code>engineering.item</code> as the attribute below.
      </p>
      {textFieldNote}
    </>
  ),
  [IdpConnectionType.AzureAd]: (
    <>
      <p>
        Microsoft Entra ID supports importing user attributes using{" "}
        <a
          href="https://learn.microsoft.com/en-us/azure/active-directory/fundamentals/custom-security-attributes-overview"
          target="_blank"
        >
          Custom Security Attributes
        </a>
        .
      </p>
      <p>
        The attribute "item" under the "engineering" attribute set should be
        specified as <code>engineering.item</code> as the attribute below.
      </p>
      {textFieldNote}
    </>
  ),
};

const useAsWarnings: Partial<
  Record<IdpConnectionUserAttributeUseAs, string>
> = {
  EMAIL:
    "Please ensure IDP attribute values are correct. Opal will update the primary email based on the provided attribute. Incorrect values can result in unexpected access.",
  SECONDARY_EMAIL:
    "Please ensure IDP attribute values are correct. Opal will associate end-system accounts with Opal users under any of their primary or secondary emails. Incorrect values can result in unexpected access.",
  GITHUB_USERNAME:
    "Please ensure IDP attribute values are correct. Opal cannot validate that a GitHub username belongs to an underlying user and will trust the values provided from your IDP. Incorrect values can result in unexpected access.",
  TELEPORT_USERNAME:
    "Please ensure IDP attribute values are correct. Opal cannot validate that a Teleport username belongs to an underlying user and will trust the values provided from your IDP. Incorrect values can result in unexpected access.",
};

const useAsOptions = Object.entries(IdpConnectionUserAttributeUseAs).map(
  ([key, value]) => {
    return {
      key,
      value,
      label: getUserAttributeUseAsOptionLabel(value),
    };
  }
);

type IdpAttributesModalProps = {
  idpConnection: IdpConnectionFragment;
  isModalOpen: boolean;
  onClose: () => void;
};

export const IdpAttributesModal = (props: IdpAttributesModalProps) => {
  const attributes = props.idpConnection.userAttributeImportMappings;
  const [currentAttribute, setCurrentAttribute] = useState("");
  const [currentUseAs, setCurrentUseAs] = useState(
    useAsOptions.find((o) => o.key === IdpConnectionUserAttributeUseAs.Custom)
  );

  const [
    createMapping,
    { loading: createMappingLoading },
  ] = useCreateIdpConnectionUserAttributeImportMappingMutation();

  const { displaySuccessToast } = useToast();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const handleAddAttribute = async () => {
    if (currentAttribute === "") {
      setErrorMessage("Must specify attribute.");
      return;
    }

    if (!currentUseAs) {
      setErrorMessage("Must specify how to use attribute.");
      return;
    }

    if (
      attributes.find(
        (attribute) =>
          attribute.key === currentAttribute &&
          attribute.useAs === currentUseAs.value
      )
    ) {
      setErrorMessage("Attribute key is already mapped.");
      return;
    }

    if (
      currentUseAs.value !== IdpConnectionUserAttributeUseAs.Custom &&
      currentUseAs.value !== IdpConnectionUserAttributeUseAs.SecondaryEmail &&
      attributes.find((attribute) => attribute.useAs === currentUseAs.value)
    ) {
      setErrorMessage(
        `Mapping to ${getUserAttributeUseAsOptionLabel(
          currentUseAs.value
        )} already exists.`
      );
      return;
    }

    setErrorMessage(null);

    try {
      const { data } = await createMapping({
        variables: {
          input: {
            idpConnectionId: props.idpConnection.id,
            key: currentAttribute,
            useAs: currentUseAs.value,
          },
        },
        refetchQueries: ["IdpConnection"],
      });
      switch (data?.createIdpConnectionUserAttributeImportMapping.__typename) {
        case "CreateIdpConnectionUserAttributeImportMappingResult": {
          displaySuccessToast("Success: user attribute import mapping created");
          break;
        }
        case "IdpConnectionNotFoundError": {
          setErrorMessage(
            data?.createIdpConnectionUserAttributeImportMapping.message
          );
          break;
        }
      }
    } catch (error) {
      logError(error, "failed to create user attribute import mapping");
      setErrorMessage("Error: failed to create user attribute import mapping");
    }
  };

  const modalReset = () => {
    props.onClose();
    setErrorMessage(null);
    setCurrentAttribute("");
    setCurrentUseAs(
      useAsOptions.find((o) => o.key === IdpConnectionUserAttributeUseAs.Custom)
    );
  };

  const warning = currentUseAs && useAsWarnings[currentUseAs.value];
  const idpNote = idpNotes[props.idpConnection.idpConnectionType];

  return (
    <Modal
      isOpen={props.isModalOpen}
      onClose={modalReset}
      title="Set imported user attributes"
    >
      <Modal.Body>
        {idpNote && (
          <>
            <Banner message={idpNote} type="info" />
            <br />
          </>
        )}
        <FormGroup label="Attribute">
          <Input
            value={currentAttribute}
            onChange={setCurrentAttribute}
            placeholder="idp_key"
          />
        </FormGroup>
        <FormGroup
          label="Use as"
          infoTooltip={`Select how Opal will interpret the imported attribute. Use "Custom attribute" for attributes to be shown in the user profile without being interpreted.`}
        >
          <Select
            multiple={false}
            options={useAsOptions}
            getOptionLabel={(o) => o.label}
            value={currentUseAs}
            onChange={(v) => setCurrentUseAs(v)}
          />
        </FormGroup>
        {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
        {warning && <Banner message={warning} type="warning" />}
        <div className={idpAttributesModalStyles.teammatesButtonsContainer}>
          <div className={idpAttributesModalStyles.teammatesButtons}>
            <Button
              outline
              label="Add Attribute"
              onClick={handleAddAttribute}
              disabled={
                currentAttribute === "" || !currentUseAs || createMappingLoading
              }
            />
          </div>
        </div>
        <IdpAttributeRowsBlock attributes={attributes} />
      </Modal.Body>
    </Modal>
  );
};

type IdpAttributeRowsBlockProps = {
  attributes: IdpConnectionFragment["userAttributeImportMappings"];
};

const IdpAttributeRowsBlock = (props: IdpAttributeRowsBlockProps) => {
  const [
    deleteMapping,
  ] = useDeleteIdpConnectionUserAttributeImportMappingMutation();

  const { displaySuccessToast, displayErrorToast } = useToast();

  return (
    <div>
      {props.attributes.length > 0 ? (
        <div className={idpAttributesModalStyles.userRows}>
          {props.attributes.map((attribute) => (
            <AttributeRow
              key={attribute.key}
              useAs={attribute.useAs}
              attribute={attribute.key}
              onRemove={async () => {
                try {
                  const { data } = await deleteMapping({
                    variables: {
                      input: {
                        id: attribute.id,
                      },
                    },
                    refetchQueries: ["IdpConnection"],
                  });
                  switch (
                    data?.deleteIdpConnectionUserAttributeImportMapping
                      .__typename
                  ) {
                    case "DeleteIdpConnectionUserAttributeImportMappingResult": {
                      displaySuccessToast(
                        "Success: user attribute import mapping deleted"
                      );
                      break;
                    }
                    case "IdpConnectionUserAttributeImportMappingNotFoundError": {
                      break;
                    }
                  }
                } catch (error) {
                  logError(
                    error,
                    "failed to create user attribute import mapping"
                  );
                  displayErrorToast(
                    "Error: failed to delete user attribute import mapping"
                  );
                }
              }}
            />
          ))}
        </div>
      ) : (
        <div className={idpAttributesModalStyles.teammatesPlaceholder}>
          <div className={idpAttributesModalStyles.placeholderIcon}>
            <Icons.UserCheck strokeWidth={2} />
          </div>
          <div className={idpAttributesModalStyles.placeholderText}>
            No custom user attributes added
          </div>
        </div>
      )}
    </div>
  );
};

type AttributeRowProps = {
  attribute: string;
  useAs: IdpConnectionUserAttributeUseAs;
  onRemove: () => void;
};

const AttributeRow = (props: AttributeRowProps) => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>();

  return (
    <>
      {isModalOpen && (
        <ConfirmModal
          isModalOpen={isModalOpen}
          title={"Are you sure?"}
          message={
            "Deleting the attribute mapping will delete all imported attributes on all the IDP users."
          }
          onClose={function (): void {
            setIsModalOpen(false);
          }}
          onSubmit={function (): void {
            props.onRemove();
            setIsModalOpen(false);
          }}
        />
      )}
      <div className={idpAttributesModalStyles.reviewerRow}>
        <div className={idpAttributesModalStyles.reviewerRowContent}>
          <div className={idpAttributesModalStyles.reviewerKey}>
            <Label text={props.attribute} />
          </div>
          <Icon size="xs" name="arrow-right" />
          <div className={idpAttributesModalStyles.reviewerUseAs}>
            <Label text={getUserAttributeUseAsOptionLabel(props.useAs)} />
          </div>
          <div
            className={idpAttributesModalStyles.removeReviewerButton}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            <Icons.X strokeWidth={4} size={16} />
          </div>
        </div>
      </div>
    </>
  );
};

export default IdpAttributesModal;
