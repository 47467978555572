import { DataElement, DataElementList } from "components/ui";

import { AccessReviewEntitiesFilterData } from "./AccessReviewEntitiesFilter";

interface Props {
  filters: AccessReviewEntitiesFilterData;
  onSetFilters: (filters: AccessReviewEntitiesFilterData) => void;
}

const AccessReviewFilterViewer = (props: Props) => {
  const { filters, onSetFilters } = props;
  return (
    <DataElementList>
      {filters.reviewerFilter && (
        <DataElement
          leftIcon={{
            name: "user-check",
          }}
          label={filters.reviewerFilter.label}
          color="blue"
          rightIcon={{
            name: "x",
          }}
          onClick={() => {
            onSetFilters({});
          }}
        />
      )}
    </DataElementList>
  );
};

export default AccessReviewFilterViewer;
