import { BarElement, Chart as ChartJS } from "chart.js";
import moment from "moment";
import { Bar } from "react-chartjs-2";

import * as styles from "./BarGraph.css";

ChartJS.register(BarElement);

interface GraphData {
  data: number[];
  label: string;
}

interface Props {
  datasets: GraphData[];
  xAxisLabels: string[];
}

const COLORS = ["#D1ECE9", "#D1EFFD", "#DCDFF2", "#EDD8F0", "#FBD6E3"];
const BORDER_COLORS = ["#179F93", "#1AB1F5", "#5061BC", "#A33EB4", "#EB3271"];

const BarGraph = (props: Props) => {
  if (props.datasets.length === 0) {
    return null;
  }

  const datasets: PropsFor<typeof Bar>["data"]["datasets"] = props.datasets.map(
    (ds, i) => {
      return {
        data: ds.data,
        label: ds.label,
        backgroundColor: COLORS[i % COLORS.length],
        borderColor: BORDER_COLORS[i % BORDER_COLORS.length],
        borderWidth: {
          top: 2,
          right: 0,
          bottom: 0,
          left: 0,
        },
      };
    }
  );

  const options: PropsFor<typeof Bar>["options"] = {
    responsive: true,
    aspectRatio: 2,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        filter: (item) => {
          return item.parsed.y !== 0;
        },
        callbacks: {
          title: (item) => {
            if (item[0]) {
              return moment(item[0].label).format("L");
            }
          },
        },
        displayColors: false,
      },
    },
    // Show tooltip on hover over any part of the graph to the nearest point.
    interaction: {
      mode: "nearest",
      intersect: false,
      axis: "xy",
    },
    scales: {
      x: {
        stacked: true,
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          padding: 10,
          autoSkip: false,
          color: "#B4B4B4",
          font: {
            family: "IBM Plex Sans, sans",
          },
          maxRotation: 0,
          precision: 0,
          callback: (_, index) => {
            // Show first, last, and middle dates (unless < 5 days)
            const isFirstDate = index === 0;
            const isLastDate = index === props.xAxisLabels.length - 1;
            const isMiddleDate =
              props.xAxisLabels.length >= 5 &&
              index === Math.floor(props.xAxisLabels.length / 2);
            if (!isFirstDate && !isLastDate && !isMiddleDate) {
              return "";
            }
            return moment(props.xAxisLabels[index]).format("MMM D");
          },
        },
      },
      y: {
        stacked: true,
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          padding: 10,
          color: "#B4B4B4",
          font: {
            family: "IBM Plex Sans, sans",
          },
          precision: 0,
        },
      },
    },
  };

  const data: PropsFor<typeof Bar>["data"] = {
    datasets,
    labels: props.xAxisLabels,
  };

  return <Bar data={data} options={options} />;
};

export const BarGraphLegend = (props: Props) => {
  return (
    <div>
      {props.datasets.map((ds, i) => {
        const color = BORDER_COLORS[i % BORDER_COLORS.length];
        return (
          <div className={styles.legendLabel} key={ds.label}>
            <div
              className={styles.legendColor}
              style={{ backgroundColor: color }}
            />
            {ds.label}
          </div>
        );
      })}
    </div>
  );
};

export default BarGraph;
