import { EntityType, ResourceFragment } from "api/generated/graphql";
import ColumnHeader from "components/column/ColumnHeader";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import RequestModal from "components/modals/RequestModal";
import { Divider } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React from "react";
import {
  OPAL_GLOBAL_IMPERSONATION_REMOTE_ID,
  OPAL_IMPERSONATION_REMOTE_ID,
} from "utils/constants";

import AppsContentColumn from "./AppsContentColumn";
import { ResourceViewKey } from "./utils";

interface Props {
  resource: ResourceFragment;
  onNavigate: (resourceViewKey: ResourceViewKey) => void;
}

const ResourceRequestColumn = (props: Props) => {
  const isGlobalImpersonationResource =
    props.resource.remoteId === OPAL_GLOBAL_IMPERSONATION_REMOTE_ID;
  const isImpersonationResource =
    props.resource.remoteId === OPAL_IMPERSONATION_REMOTE_ID;

  return (
    <AppsContentColumn maxWidth="md">
      <ColumnHeader
        title={`Request access to ${props.resource.name}`}
        subtitle={getResourceTypeInfo(props.resource.resourceType)?.name}
        icon={{ type: "name", icon: "lock" }}
        onClose={() => props.onNavigate("overview")}
      />
      <Divider margin="md" />
      <div className={sprinkles({ padding: "md" })}>
        <RequestModal
          entity={props.resource}
          isGlobalImpersonationResource={isGlobalImpersonationResource}
          isImpersonationResource={isImpersonationResource}
          entityType={EntityType.Resource}
          containingResourceGroups={props.resource.containingGroups}
          // Remove after corinthian GA
          isModalOpen={true}
          onClose={() => {}}
          contentOnly
        />
      </div>
    </AppsContentColumn>
  );
};

export default ResourceRequestColumn;
