import {
  AppDropdownPreviewFragment,
  GroupType,
  RecommendationsSubscoreType,
  ResourceType,
  TagPreviewLargeFragment,
  useGroupTypesWithCountsQuery,
  useResourceTypesWithCountsQuery,
  useTagsQuery,
} from "api/generated/graphql";
import EntityTypeDropdown from "components/dropdown/EntityTypeDropdown";
import RiskFactorDropdown from "components/dropdown/RiskFactorDropdown";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { ButtonV3, Input, Label, Select } from "components/ui";
import TableFilters from "components/ui/table/TableFilters";
import sprinkles from "css/sprinkles.css";
import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router";
import { useAllAppsQuery } from "utils/hooks";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import {
  outsideAccessRiskFactorText,
  perpetualAccessRiskFactorText,
  unusedAccessRiskFactorText,
} from "views/recommendations/ThreatPill";

import * as styles from "./ThreatCenter.css";
const MAX_OPTIONS_TO_DISPLAY = 25;

export interface RecommendationsFilter {
  appFilter?: AppDropdownPreviewFragment[];
  riskScoreFilter?: RiskScoreRange[];
  tagFilter?: TagPreviewLargeFragment[];
  riskFactorFilter?: RecommendationsSubscoreType[];
  entityTypeFilter?: (ResourceType | GroupType)[];
  searchQuery?: string;
}
const METRICS_FILTER_URL_KEY = "filters";

export interface RiskScoreRange {
  minScore: number;
  maxScore: number;
}

// Fetches the current filters from the URL
export const useRecommendationsFilterState = (): RecommendationsFilter => {
  const { search } = useLocation();
  if (!search) {
    return {};
  }

  const params = new URLSearchParams(search);
  const data = params.get(METRICS_FILTER_URL_KEY);

  if (!data) {
    return {};
  }

  const inputString = decodeURIComponent(atob(data));

  const parsed = JSON.parse(inputString);
  return {
    ...parsed,
  };
};

export const allFilterableRiskFactors: string[] = [
  perpetualAccessRiskFactorText,
  unusedAccessRiskFactorText,
  outsideAccessRiskFactorText,
];

export const oktaEntityTypes: (ResourceType | GroupType)[] = [
  ResourceType.OktaApp,
  GroupType.OktaGroup,
];

export const allRiskScoreRanges: RiskScoreRange[] = [
  {
    minScore: 0,
    maxScore: 25,
  },
  {
    minScore: 26,
    maxScore: 50,
  },
  {
    minScore: 51,
    maxScore: 75,
  },
  {
    minScore: 76,
    maxScore: 100,
  },
];

export const isEmptyFilter = (filter: RecommendationsFilter): boolean => {
  return (
    !filter.riskScoreFilter &&
    (!filter.appFilter || filter.appFilter.length == 0) &&
    (!filter.tagFilter || filter.tagFilter.length == 0) &&
    (!filter.riskFactorFilter || filter.riskFactorFilter.length == 0) &&
    (!filter.entityTypeFilter || filter.entityTypeFilter.length == 0) &&
    !filter.searchQuery
  );
};

interface RecommendationFilterSectionProps {
  filter: RecommendationsFilter;
  setFilter: Dispatch<SetStateAction<RecommendationsFilter>>;
}

const makeFilterStateURLHash = (filterState: RecommendationsFilter) => {
  if (isEmptyFilter(filterState)) {
    return undefined;
  }
  const output = btoa(encodeURIComponent(JSON.stringify(filterState)));
  return `?${METRICS_FILTER_URL_KEY}=${output}`;
};

const RecommendationFilterSection = ({
  filter,
  setFilter,
}: RecommendationFilterSectionProps) => {
  const history = useHistory();

  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined);

  const debouncedSearchQuery = useDebouncedValue(searchQuery, 250);
  const pushFilter = useCallback(
    (newFilter: RecommendationsFilter) => {
      if (isEmptyFilter(newFilter)) {
        setFilter({});
        history.push("#");
        return newFilter;
      }
      const hash = makeFilterStateURLHash(newFilter);
      if (hash) {
        history.push(hash);
      }
    },
    [setFilter, history] // dependencies
  );

  useEffect(() => {
    if (debouncedSearchQuery) {
      setFilter((prevFilter) => {
        const newFilter = { ...prevFilter };

        newFilter.searchQuery = debouncedSearchQuery;
        pushFilter(newFilter);
        return newFilter;
      });
    }
  }, [debouncedSearchQuery, pushFilter, setFilter]);

  useEffect(() => {
    const hash = makeFilterStateURLHash(filter);
    if (hash) {
      history.push(hash);
    }
  }, [filter, history]);

  // Fetching apps (non paginated)
  const { data: appsData, error: appsError } = useAllAppsQuery({});
  if (appsError) {
    logError(appsError, "failed to list apps");
  }

  let apps: AppDropdownPreviewFragment[] = [];
  switch (appsData?.apps.__typename) {
    case "AppsOutput":
      apps = appsData.apps.apps;
      break;
    default:
      break;
  }

  // Fetching tags
  const {
    data: tagSearchData,
    error: tagSearchError,
    fetchMore: tagSearchFetchMore,
  } = useTagsQuery({
    variables: {
      input: {
        limit: MAX_OPTIONS_TO_DISPLAY,
      },
    },
  });

  if (tagSearchError) {
    logError(tagSearchError, "failed to list tags");
  }

  const tagsCursor = tagSearchData?.tags.cursor;
  const loadMoreTags = tagsCursor
    ? async () => {
        await tagSearchFetchMore({
          variables: {
            input: {
              limit: MAX_OPTIONS_TO_DISPLAY,
              cursor: tagsCursor,
            },
          },
        });
      }
    : undefined;

  let tags: TagPreviewLargeFragment[] = [];
  switch (tagSearchData?.tags.__typename) {
    case "TagsResult":
      tags = tagSearchData.tags.tags;
      break;
    default:
      break;
  }

  // Fetching entity types
  const entityTypeToConnectionIDMap: Map<
    ResourceType | GroupType,
    string | null | undefined
  > = new Map();

  const {
    data: groupTypesData,
    error: groupTypesError,
  } = useGroupTypesWithCountsQuery({
    variables: {
      input: { connectionIds: filter.appFilter?.map((app) => app.id) },
    },
  });

  if (groupTypesError) {
    logError(groupTypesError, "failed to list group types");
  }
  switch (groupTypesData?.groupTypesWithCounts?.__typename) {
    case "GroupTypesWithCountsResult":
      groupTypesData?.groupTypesWithCounts.groupTypesWithCounts.forEach(
        (groupType) => {
          entityTypeToConnectionIDMap.set(
            groupType.groupType,
            groupType.connectionId
          );
        }
      );
      break;
    default:
      break;
  }

  const {
    data: resourceTypesData,
    error: resourceTypesDataError,
  } = useResourceTypesWithCountsQuery({
    variables: {
      input: { connectionIds: filter.appFilter?.map((app) => app.id) },
    },
  });

  if (resourceTypesDataError) {
    logError(resourceTypesDataError, "failed to list resource types");
  }
  switch (resourceTypesData?.resourceTypesWithCounts?.__typename) {
    case "ResourceTypesWithCountsResult":
      resourceTypesData?.resourceTypesWithCounts.resourceTypesWithCounts.forEach(
        (resource) => {
          entityTypeToConnectionIDMap.set(
            resource.resourceType,
            resource.connectionId
          );
        }
      );
      break;
    default:
      break;
  }

  let additionalValues: (ResourceType | GroupType)[] = [];

  filter.appFilter?.forEach((app) => {
    switch (app.app.__typename) {
      case "OktaResourceApp":
        additionalValues = additionalValues.concat(oktaEntityTypes);
        break;
      default:
        break;
    }
  });

  const handleClearingFilters = (): void => {
    setFilter({});
    history.push("#");
  };

  const handleSetAppFilter = (
    newAppFilter: AppDropdownPreviewFragment[] | undefined
  ): void => {
    setFilter((prevFilter) => {
      if (!newAppFilter) return {};
      const newFilter = {
        ...prevFilter,
        appFilter:
          newAppFilter && newAppFilter.length === 0 ? undefined : newAppFilter,
      };
      pushFilter(newFilter);
      return newFilter;
    });
  };

  const handleSetRiskScoreFilter = (
    newRiskScoreFilter: RiskScoreRange[] | undefined
  ): void => {
    setFilter((prevFilter) => {
      const newFilter = {
        ...prevFilter,
        riskScoreFilter:
          newRiskScoreFilter && newRiskScoreFilter.length === 0
            ? undefined
            : newRiskScoreFilter,
      };
      pushFilter(newFilter);
      return newFilter;
    });
  };

  const handleSetRiskFactorFilter = (
    newRiskFactorFilter: RecommendationsSubscoreType[] | undefined
  ): void => {
    setFilter((prevFilter) => {
      const newFilter = {
        ...prevFilter,
        riskFactorFilter:
          newRiskFactorFilter && newRiskFactorFilter.length === 0
            ? undefined
            : newRiskFactorFilter,
      };
      pushFilter(newFilter);
      return newFilter;
    });
  };

  const handleSetEntityTypeFilter = (
    newEntityTypeFilter: (GroupType | ResourceType)[] | undefined
  ): void => {
    setFilter((prevFilter) => {
      const newFilter = {
        ...prevFilter,
        entityTypeFilter:
          newEntityTypeFilter && newEntityTypeFilter.length === 0
            ? undefined
            : newEntityTypeFilter,
      };
      pushFilter(newFilter);
      return newFilter;
    });
  };

  const handleSetTagFilter = (
    newTagFilter: TagPreviewLargeFragment[] | undefined
  ): void => {
    setFilter((prevFilter) => {
      const newFilter = {
        ...prevFilter,
        tagFilter:
          newTagFilter && newTagFilter.length === 0 ? undefined : newTagFilter,
      };

      pushFilter(newFilter);
      return newFilter;
    });
  };

  const handleSetSearchQuery = (searchQuery: string | undefined): void => {
    if (searchQuery == "" || searchQuery == undefined) {
      setFilter((prevFilter) => {
        const newFilter = { ...prevFilter, searchQuery: undefined };
        pushFilter(newFilter);
        return newFilter;
      });
    }
    setSearchQuery(searchQuery);
  };

  const getRiskScoreLabelPrefix = (range: RiskScoreRange): string => {
    let labelPrefix: string;
    if (range.maxScore <= 25) {
      labelPrefix = "Low";
    } else if (range.maxScore <= 50) {
      labelPrefix = "Medium";
    } else if (range.maxScore <= 75) {
      labelPrefix = "High";
    } else {
      labelPrefix = "Critical";
    }
    return labelPrefix;
  };

  const getRiskScoreOptionLabel = (range: RiskScoreRange): string => {
    return (
      getRiskScoreLabelPrefix(range) + ` (${range.minScore}-${range.maxScore})`
    );
  };

  const renderRiskScoreInputValue = (value?: RiskScoreRange[]) => {
    if (!value || value?.length === 0) {
      return "Filter by Risk Score";
    } else if (value?.length === 1) {
      return getRiskScoreOptionLabel(value[0]);
    } else {
      return value.map((score) => getRiskScoreLabelPrefix(score)).join(" + ");
    }
  };

  const renderTagInputValue = (value?: TagPreviewLargeFragment[]) => {
    if (!value || value?.length === 0) {
      return "Filter by Tags";
    } else if (value?.length === 1) {
      return getTagLabel(value[0]);
    } else {
      return (
        getTagLabel(value[0]) +
        (value?.length > 1 ? ` + ${String(value.length - 1)} more` : "")
      );
    }
  };

  const renderAppInputValue = (value?: AppDropdownPreviewFragment[]) => {
    if (!value || value?.length === 0) {
      return "Filter by Apps";
    } else if (value?.length === 1) {
      return value[0].name;
    } else {
      return (
        value[0].name +
        (value?.length > 1 ? ` + ${String(value.length - 1)} more` : "")
      );
    }
  };

  const getTagLabel = (tag: TagPreviewLargeFragment) => {
    return tag.value ? `${tag.key}:${tag.value}` : tag.key;
  };

  const filteredApps = (
    apps: AppDropdownPreviewFragment[] | undefined,
    entityTypes: (ResourceType | GroupType)[]
  ) => {
    if (!entityTypes || entityTypes.length === 0) {
      return apps ?? [];
    }

    const hasOktaEntityType = entityTypes.some((entityType) =>
      oktaEntityTypes.includes(entityType)
    );
    const filteredConnectionIDs = entityTypes
      .map((entityType) => {
        if (entityType === undefined) {
          return null;
        }
        return entityTypeToConnectionIDMap.get(entityType);
      })
      .filter((value): value is string => value !== null);
    if (apps) {
      return apps?.filter((app) => {
        if (
          filteredConnectionIDs.length > 0 &&
          filteredConnectionIDs.includes(app.id)
        ) {
          return true;
        }
        if (app.app.__typename == "OktaResourceApp" && hasOktaEntityType) {
          return true;
        }
        return false;
      });
    } else {
      return [];
    }
  };
  return (
    <TableFilters>
      <TableFilters.Left>
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
            alignItems: "center",
            flexWrap: "wrap",
            width: "100%",
            flexGrow: 1,
          })}
        >
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={handleSetSearchQuery}
              placeholder="Filter by Name"
            />
          </div>
          <div className={styles.filterInput}>
            <Select<AppDropdownPreviewFragment>
              size="sm"
              multiple
              value={filter.appFilter}
              options={filteredApps(apps, filter.entityTypeFilter || [])}
              getOptionLabel={(connection) => connection.name}
              getIcon={(app) => {
                switch (app.app.__typename) {
                  case "OktaResourceApp":
                    return {
                      type: "src",
                      icon: app.app.iconUrl ? app.app.iconUrl : undefined,
                    };
                  case "ConnectionApp":
                    return {
                      type: "src",
                      icon: getConnectionTypeInfo(app.app.connectionType)?.icon,
                    };
                  default:
                    return undefined;
                }
              }}
              placeholderIcon={{ type: "name", icon: "dots-grid" }}
              alwaysShowPlaceholderIcon={true}
              searchable={true}
              onChange={handleSetAppFilter}
              renderInputValue={renderAppInputValue}
              placeholder={renderAppInputValue(filter.appFilter)}
              highlightWhenSelected={true}
              getOptionSelected={(option, value) => {
                return option.id === value.id;
              }}
              listboxFooter={{
                footer: (
                  <div
                    className={sprinkles({
                      fontFamily: "body",
                      padding: "md",
                    })}
                  >
                    {filter.appFilter && filter.appFilter.length > 0 ? (
                      <Label
                        label={`Clear selection (${filter.appFilter.length})`}
                        color="blue600V3"
                        onClick={() => handleSetAppFilter([])}
                      />
                    ) : (
                      <Label label="No items selected" color="gray700" />
                    )}
                  </div>
                ),
                sticky: true,
              }}
            />
          </div>
          <div className={styles.filterInput}>
            <Select<RiskScoreRange>
              multiple
              size="sm"
              value={filter?.riskScoreFilter}
              options={allRiskScoreRanges}
              getOptionLabel={getRiskScoreOptionLabel}
              placeholderIcon={{
                type: "name",
                icon: "odometer",
              }}
              alwaysShowPlaceholderIcon={true}
              onChange={handleSetRiskScoreFilter}
              renderInputValue={renderRiskScoreInputValue}
              highlightWhenSelected={true}
              placeholder={renderRiskScoreInputValue(filter.riskScoreFilter)}
              getOptionSelected={(option, value) =>
                getRiskScoreOptionLabel(option) ===
                getRiskScoreOptionLabel(value)
              }
              listboxFooter={{
                footer: (
                  <div
                    className={sprinkles({
                      fontFamily: "body",
                      padding: "md",
                    })}
                  >
                    {filter.riskScoreFilter &&
                    filter.riskScoreFilter.length > 0 ? (
                      <Label
                        label={`Clear selection (${filter.riskScoreFilter.length})`}
                        color="blue600V3"
                        onClick={() => handleSetRiskScoreFilter([])}
                      />
                    ) : (
                      <Label label="No items selected" color="gray700" />
                    )}
                  </div>
                ),
                sticky: true,
              }}
            />
          </div>
          <div className={styles.filterInput}>
            <Select<TagPreviewLargeFragment>
              size="sm"
              multiple
              value={filter.tagFilter}
              options={tags}
              getOptionSelected={(option, value) => option.id === value.id}
              getOptionLabel={(tag) => getTagLabel(tag)}
              onChange={handleSetTagFilter}
              placeholderIcon={{ type: "name", icon: "tag" }}
              alwaysShowPlaceholderIcon={true}
              onScrollToBottom={() => {
                if (loadMoreTags) loadMoreTags();
              }}
              renderInputValue={renderTagInputValue}
              placeholder={renderTagInputValue(filter.tagFilter)}
              highlightWhenSelected={true}
              listboxFooter={{
                footer: (
                  <div
                    className={sprinkles({
                      fontFamily: "body",
                      padding: "md",
                    })}
                  >
                    {filter.tagFilter && filter.tagFilter.length > 0 ? (
                      <Label
                        label={`Clear selection (${filter.tagFilter.length})`}
                        color="blue600V3"
                        onClick={() => handleSetTagFilter([])}
                      />
                    ) : (
                      <Label label="No items selected" color="gray700" />
                    )}
                  </div>
                ),
                sticky: true,
              }}
            />
          </div>
          <div className={styles.filterInput}>
            <EntityTypeDropdown
              entityTypes={filter.entityTypeFilter}
              setEntityTypeFilter={handleSetEntityTypeFilter}
              connectionIds={filter.appFilter?.map((app) => app.id)}
              additionalValues={additionalValues}
            />
          </div>
          <div className={styles.filterInput}>
            <RiskFactorDropdown
              riskFactors={filter.riskFactorFilter}
              setRiskFactorFilter={handleSetRiskFactorFilter}
            />
          </div>
          {!isEmptyFilter(filter) && (
            <ButtonV3
              size="sm"
              outline
              type="mainBorderless"
              label="Clear all filters"
              onClick={() => handleClearingFilters()}
            />
          )}
        </div>
      </TableFilters.Left>
    </TableFilters>
  );
};
export default RecommendationFilterSection;
