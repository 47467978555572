import { getModifiedErrorMessage } from "api/ApiContext";
import {
  GeneralSettingType,
  Maybe,
  useUpdateOrganizationSettingsMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { MoreInfo } from "components/more_info/MoreInfo";
import { useToast } from "components/toast/Toast";
import { Banner, ButtonV3, Modal, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useContext, useState } from "react";
import { logError } from "utils/logging";
import OrgContext, { OrgContextActionType } from "views/settings/OrgContext";
import { OrgSettingAttributes } from "views/settings/OrgSettings";
import * as styles from "views/settings/OrgSettingsV3.css";

type OrgSettingSwitchWithModalPropsV3 = {
  labelText: string;
  tooltipText: string;
  modalTitle: string;
  modalWarning?: string;
  setting: OrgSettingAttributes;
  currentSettings: GeneralSettingType[];
};

export const OrgSettingSwitchWithModalV3 = (
  props: OrgSettingSwitchWithModalPropsV3
) => {
  const { orgDispatch } = useContext(OrgContext);
  const { authState } = useContext(AuthContext);

  const [settingState, setSettingState] = useState(
    props.setting.initialStateOn
  );
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [
    updateOrgSettings,
    { loading },
  ] = useUpdateOrganizationSettingsMutation();

  const { displaySuccessToast } = useToast();

  const modalReset = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  return (
    <div className={styles.switchesHeader}>
      <div className={sprinkles({ display: "flex" })}>
        <div className={styles.label}>{props.labelText}</div>
        <MoreInfo tooltipText={props.tooltipText} />
      </div>
      <div className={styles.switches}>
        <ButtonV3
          onClick={() => setShowModal(true)}
          label={props.setting.initialStateOn ? "Enabled" : "Disabled"}
          disabled={!authState.user?.isAdmin}
          outline={true}
        />
      </div>

      {showModal && (
        <Modal isOpen={showModal} onClose={modalReset} title={props.modalTitle}>
          <Modal.Body>
            {props.modalWarning && (
              <p>
                <Banner message={props.modalWarning} type="warning" />
              </p>
            )}
            <p>{props.tooltipText}</p>

            <Switch
              label={props.labelText}
              onChange={setSettingState}
              checked={settingState}
            />
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
          </Modal.Body>

          <Modal.Footer
            primaryButtonLabel="Confirm"
            onPrimaryButtonClick={async () => {
              setErrorMessage(null);
              try {
                const { data } = await updateOrgSettings({
                  variables: {
                    input: {
                      settings: props.currentSettings.includes(
                        props.setting.settingType
                      )
                        ? props.currentSettings.filter(
                            (setting) => setting !== props.setting.settingType
                          )
                        : [...props.currentSettings, props.setting.settingType],
                    },
                  },
                });
                switch (data?.updateOrganizationSettings.__typename) {
                  case "UpdateOrganizationSettingsResult":
                    orgDispatch({
                      type: OrgContextActionType.OrgSettings,
                      payload: {
                        orgSettings: data.updateOrganizationSettings.settings,
                      },
                    });

                    modalReset();
                    displaySuccessToast("Success: settings updated");
                    break;
                  default:
                    logError(new Error(`failed to update org settings`));
                    setErrorMessage("Error: failed to update org settings");
                }
              } catch (error) {
                logError(error, "failed to update org settings");
                setErrorMessage(
                  getModifiedErrorMessage(
                    "Error: failed to update org settings",
                    error
                  )
                );
              }
            }}
            primaryButtonDisabled={
              settingState === props.setting.initialStateOn
            }
            primaryButtonLoading={loading}
            secondaryButtonLabel="Cancel"
            onSecondaryButtonClick={modalReset}
          />
        </Modal>
      )}
    </div>
  );
};
