import { ConnectionType } from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { TruncatedLabel } from "components/label/Label";
import React, { ReactElement, useContext } from "react";
import * as Icons from "react-feather";

const strokeWidthDefault = 2;

type ConnectionLabelProps = {
  text?: string | null;
  subText?: string;
  connectionId?: string;
  connectionType?: ConnectionType;
  maxChars?: number;
  pointerCursor?: boolean;
  bold?: boolean;
  url?: string;
  target?: string;
};

export const ConnectionLabel = (props: ConnectionLabelProps) => {
  const { authState } = useContext(AuthContext);
  let url = props.url;
  if (!url && props.connectionId) {
    url = `/apps/${props.connectionId}`;
  }

  if (!authState.user?.isAdmin && !authState.user?.isReadOnlyAdmin) {
    url = undefined;
  }

  let text = props.text;
  if (!props.text || props.text === "") {
    text = props.connectionId?.substr(0, 7) || "--";
  }

  let icon: string | ReactElement = (
    <Icons.Layers strokeWidth={strokeWidthDefault} />
  );
  return (
    <TruncatedLabel
      text={text}
      subText={props.subText}
      icon={icon}
      iconLarge={true}
      connectionType={props.connectionType}
      url={url}
      maxChars={props.maxChars}
      pointerCursor={props.pointerCursor}
      bold={props.bold}
      target={props.target}
    />
  );
};

export default ConnectionLabel;
