import {
  AccessReviewUserFragment,
  Maybe,
  useAccessReviewUserQuery,
} from "api/generated/graphql";
import React from "react";
import { useParams } from "react-router";
import { logError } from "utils/logging";
import AccessReviewUserViewer from "views/access_reviews/users/AccessReviewUserViewer";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

const AccessReviewUserViewerWrapper = () => {
  const { accessReviewId, userId } = useParams<Record<string, string>>();

  const { data, error, loading } = useAccessReviewUserQuery({
    variables: {
      input: {
        accessReviewId: accessReviewId!,
        userId: userId!,
      },
    },
  });

  let accessReviewUser: Maybe<AccessReviewUserFragment> = null;
  if (data) {
    switch (data.accessReviewUser.__typename) {
      case "AccessReviewUserResult":
        accessReviewUser = data.accessReviewUser.accessReviewUser;
        break;
      case "AccessReviewUserNotFoundError":
        return <NotFoundPage />;
      default:
        logError(new Error(`failed to get access review user`));
    }
  }

  if (loading) {
    return <ColumnContentSkeleton />;
  }

  if (!accessReviewUser || !accessReviewUser.accessReview || error) {
    logError(error, "failed to get access review resource");
    return <UnexpectedErrorPage error={error} />;
  }

  return <AccessReviewUserViewer accessReviewUser={accessReviewUser} />;
};

export default AccessReviewUserViewerWrapper;
