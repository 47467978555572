import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AuthType,
  ConnectionType,
  Maybe,
  useCreateConnectionMutation,
  Visibility,
} from "api/generated/graphql";
import gcpLogo from "assets/logos/gcp-logo-new.svg";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { Button, FormGroup, Input } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import {
  CreateConnectionComponents as CreateConnectionComponentsV2,
  CreateConnectionView as CreateConnectionViewV2,
} from "views/connections/create/CreateConnectionComponents";
import {
  CreateConnectionComponentsV3,
  CreateConnectionViewV3,
} from "views/connections/create/CreateConnectionComponentsV3";
import {
  GCPServiceAccountJSON,
  UploadServiceAccountJSONButton,
} from "views/connections/create/UploadServiceAccountJSONButton";
import VisibilitySelector from "views/visibility/VisibilitySelector";

const CreateGCP = () => {
  return <CreateGCPForm />;
};

const CreateGCPForm = () => {
  const history = useHistory();
  const hasAppLevelVisibility = useFeatureFlag(FeatureFlag.AppLevelVisibility);
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const logEvent = useLogEvent();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [visibility, setVisibility] = useState<Visibility>(Visibility.Global);
  const [visibilityGroupIds, setVisibilityGroupIds] = useState<
    string[] | undefined
  >([]);
  const [
    serviceAccountInfo,
    setServiceAccountInfo,
  ] = useState<GCPServiceAccountJSON>();

  const [adminOwnerId, setAdminOwnerId] = useState<string | undefined>(
    undefined
  );
  const [pubSubProjectId] = useState("");
  const [subscriptionId] = useState("");

  const [createConnectionMutation, { loading }] = useCreateConnectionMutation({
    refetchQueries: ["AppsListColumn", "Connections"],
  });

  const fieldUnset =
    name === "" || !adminOwnerId || description === "" || !serviceAccountInfo;

  const onSubmit = async () => {
    if (!serviceAccountInfo) {
      return;
    }

    try {
      const { data } = await createConnectionMutation({
        variables: {
          input: {
            name: name,
            description: description,
            connectionType: ConnectionType.Gcp,
            adminOwnerId: adminOwnerId ?? "",
            visibility: visibility,
            visibilityGroupIds: visibilityGroupIds ?? [],
            importVisibility: Visibility.Global,
            metadata: {
              connectionType: ConnectionType.Gcp,
              gcp: {
                serviceAccount: {
                  type: serviceAccountInfo.type,
                  projectId: serviceAccountInfo.project_id,
                  clientEmail: serviceAccountInfo.client_email,
                  clientId: serviceAccountInfo.client_id,
                  authUri: serviceAccountInfo.auth_uri,
                  tokenUri: serviceAccountInfo.token_uri,
                  authProviderX509CertUrl:
                    serviceAccountInfo.auth_provider_x509_cert_url,
                  clientX509CertUrl: serviceAccountInfo.client_x509_cert_url,
                },
                pubSubProjectId: pubSubProjectId,
                subscriptionId: subscriptionId,
              },
            },
            credentials: {
              authType: AuthType.Gcp,
              gcp: {
                privateKeyId: serviceAccountInfo.private_key_id,
                privateKey: serviceAccountInfo.private_key,
              },
            },
          },
        },
      });
      switch (data?.createConnection.__typename) {
        case "CreateConnectionResult":
          displaySuccessToast(`Success: GCP app created`);
          history.replace(
            getResourceUrl(
              EntityTypeDeprecated.Connection,
              data.createConnection.connection.id
            )
          );
          logEvent({
            name: "apps_create_click",
            properties: {
              connectionType: ConnectionType.Gcp,
            },
          });

          break;
        case "ConnectionExistsError":
        case "UserFacingError":
          logWarning(new Error(data.createConnection.message));
          setErrorMessage(data.createConnection.message);
          logWarning(new Error(data.createConnection.message));
          break;
        default:
          logError(new Error(`app creation failed`));
          setErrorMessage(`Error: app creation failed`);
      }
    } catch (error) {
      logError(error, "app creation failed");
      setErrorMessage(
        getModifiedErrorMessage("Error: app creation failed", error)
      );
    }
  };

  const CreateConnectionComponents = hasV3
    ? CreateConnectionComponentsV3
    : CreateConnectionComponentsV2;
  const CreateConnectionView = hasV3
    ? CreateConnectionViewV3
    : CreateConnectionViewV2;

  return (
    <CreateConnectionView
      logo={gcpLogo}
      title={"Add your Google Cloud organization"}
      onSubmit={onSubmit}
      submitDisabled={fieldUnset}
      submitLoading={loading}
    >
      <>
        <CreateConnectionComponents
          title={"Step 1"}
          subtitle={"Tag infrastructure in GCP"}
        >
          <div>
            <p>
              To begin, you should go to the GCP Console and tag the roles,
              databases, and other resources that you want to import into Opal.
            </p>
            <p>
              To learn how to tag your GCP resources, check out{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  "https://docs.opal.dev/docs/5525120-adding-a-project#step-2-label-your-project"
                }
              >
                our official documentation.
              </a>
            </p>
          </div>
        </CreateConnectionComponents>
        <CreateConnectionComponents
          title={"Step 2"}
          subtitle={"Create a service account for Opal"}
        >
          <p>
            Opal requires an IAM service account to manage your Google Cloud
            resources on your behalf. To learn how to create a service account
            with the right credentials, check out{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={
                "https://docs.opal.dev/docs/google-cloud-platform#getting-started"
              }
            >
              our documentation.
            </a>
            .
          </p>
        </CreateConnectionComponents>
        <CreateConnectionComponents
          title={"Step 3"}
          subtitle={"Enable the IAM API in GCP"}
        >
          <p>
            Please also{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://console.developers.google.com/apis/api/iam.googleapis.com/overview"
            >
              enable the IAM API
            </a>{" "}
            for the projects you want to manage with Opal.
          </p>
        </CreateConnectionComponents>
        <CreateConnectionComponents
          title={"Step 4"}
          subtitle={"Upload your service account credentials"}
          isLast
        >
          <>
            <p>
              Once the service account is created, please input the user{"'"}s
              credentials and some information about the connection:
            </p>
            <FormGroup label="App name:">
              <Input
                onChange={setName}
                placeholder="Identifiable name of the Google Cloud account."
                value={name}
              />
            </FormGroup>
            <FormGroup label="App admin:">
              <OwnerDropdown
                selectedOwnerId={adminOwnerId}
                onSelectOwner={(owner) => setAdminOwnerId(owner?.id)}
                placeholder="Select an owner to own this app."
              />
            </FormGroup>
            <FormGroup label="Description:">
              <Input
                onChange={setDescription}
                placeholder="A brief description of the account to further inform people requesting access to it."
                value={description}
              />
            </FormGroup>
            {hasAppLevelVisibility && (
              <FormGroup label="Visibility:">
                <VisibilitySelector
                  visibility={visibility}
                  onChangeVisibility={(vis) => {
                    if (vis == Visibility.Global) {
                      setVisibility(Visibility.Global);
                      setVisibilityGroupIds([]);
                    } else {
                      setVisibility(Visibility.Team);
                    }
                  }}
                  visibilityGroups={visibilityGroupIds ?? []}
                  onChangeVisibilityGroups={setVisibilityGroupIds}
                />
              </FormGroup>
            )}
            <FormGroup label="GCP service account credentials JSON:">
              <UploadServiceAccountJSONButton
                setServiceAccountInfos={setServiceAccountInfo}
              />
            </FormGroup>
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
            {!hasV3 && (
              <div
                className={sprinkles({
                  display: "flex",
                  justifyContent: "flex-end",
                })}
              >
                <Button
                  type="primary"
                  disabled={fieldUnset}
                  label={"Create"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </div>
            )}
          </>
        </CreateConnectionComponents>
      </>
    </CreateConnectionView>
  );
};

export default CreateGCP;
