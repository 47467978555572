import TabsV3, { TabInfo } from "components/ui/tabs/TabsV3";
import React, { createContext, useState } from "react";
import { usePageTitle } from "utils/hooks";

import OpalHeader from "./OpalHeader";
import * as styles from "./OpalPage.css";

interface Props extends PropsFor<typeof OpalHeader> {
  children: React.ReactNode;
  tabs?: TabInfo[];
  customBody?: boolean; // for pages that want to do their own thing (e.g. insights)
  pageTitleOverride?: string; // defaults to the same as title
}
export const OpalPageContext = createContext<{
  body: HTMLDivElement | null;
}>({ body: null });

const OpalPage = ({
  icon,
  title,
  children,
  tabs,
  extraActions,
  customBody = false,
  pageTitleOverride,
  leftActions,
  breadcrumbs,
}: Props) => {
  usePageTitle(
    pageTitleOverride ?? (typeof title === "string" ? title : undefined)
  );

  // need to store the element in state since the entire component
  // gets blown away on page transitions, but useRef will persist
  // and reference the original element
  const [body, setBody] = useState<HTMLDivElement | null>(null);

  return (
    <section className={styles.container}>
      <OpalHeader
        icon={icon}
        title={title}
        breadcrumbs={breadcrumbs}
        extraActions={extraActions}
        leftActions={leftActions}
      />
      <div
        ref={(node) => setBody(node)}
        className={styles.body({
          customBody: customBody,
        })}
      >
        <OpalPageContext.Provider value={{ body: body }}>
          {tabs != null && <TabsV3 tabInfos={tabs} />}
          {children}
        </OpalPageContext.Provider>
      </div>
    </section>
  );
};

export default OpalPage;
