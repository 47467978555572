import {
  OidcProviderType,
  RemoveResourceUserInput,
  ResourceAccessLevel,
} from "api/generated/graphql";
import { MfaCustomParams } from "utils/mfa/mfa";
import { PerformReviewState } from "views/access_reviews/AccessReviewContext";

export enum OidcPostAuthAction {
  StartSession,
  ForfeitResource,
  RemoveResourceUser,
  PerformAccessReview,
}

export interface OidcCustomParams {
  action: OidcPostAuthAction;
  resourceId?: string;
  accessLevel?: ResourceAccessLevel;
  resourceUsersToRemove?: RemoveResourceUserInput[];
  performReviewState?: PerformReviewState;
  forceNewSession?: boolean;
}

export interface OidcData {
  state: string;
  oidcProviderType: OidcProviderType;

  // postAuthPath is the url where the user should end up after the OIDC Callback has completed
  postAuthPath: string;

  // postAuthHash holds the fragment of a URL (i.e. the part of the url after the '#').
  postAuthHash?: string;

  // params holds state for OIDC callbacks triggered by the AWS Orgs integration.
  // This corresponds to OidcProviderType.AwsSession.
  params?: OidcCustomParams;

  // mfaParams holds state for OIDC callbacks triggered by MFA requests.
  // This corresponds to OidcProviderType.MFA.
  mfaParams?: MfaCustomParams;
}

export const setOidcData = (data: OidcData) => {
  localStorage.setItem("oidcData", JSON.stringify(data));
};

export const clearOidcData = () => {
  localStorage.removeItem("oidcData");
};

export const getOidcData = (): OidcData | null => {
  const dataString = localStorage.getItem("oidcData");
  if (!dataString) {
    return null;
  }
  const data = JSON.parse(dataString);
  return data;
};
