import { TagFragment } from "api/generated/graphql";
import { FormRow } from "components/ui";
import sprinkles from "css/sprinkles.css";

type TagOverviewRowProps = {
  tag: TagFragment;
};

const TagOverviewRow = (props: TagOverviewRowProps) => {
  return (
    <div
      className={sprinkles({
        padding: "md",
        display: "flex",
        flexDirection: "column",
        gap: "xl",
      })}
    >
      <div>
        <FormRow title="Key">{props.tag.key}</FormRow>
        <FormRow title="Value">{props.tag.value}</FormRow>
      </div>
    </div>
  );
};

export default TagOverviewRow;
