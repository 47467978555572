import { GroupAccessLevel, ResourceAccessLevel } from "api/generated/graphql";
import { Label, Select } from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";

type Role = ResourceAccessLevel | GroupAccessLevel;

type BaseProps = {
  id?: string;
  availableRoles: Role[];
  isImpersonationResource: boolean;
  loading?: boolean;
  expirationByRoleRemoteId?: Record<string, moment.Moment | null>;
};

type SingleSelectProps = BaseProps & {
  multiple?: false;
  selectedRole?: Role;
  setSelectedRole: (role?: Role) => void;
};

type MultiSelectProps = BaseProps & {
  multiple: true;
  selectedRoles?: Role[];
  onSelectRoles?: (roles?: Role[]) => void;
  onRemoveRoles?: (roles?: Role[]) => void;
};

export const RequestModalRoleDropdown = (
  props: SingleSelectProps | MultiSelectProps
) => {
  const getIcon = props.isImpersonationResource
    ? (option: Role): IconData | undefined =>
        option.__typename === "ResourceAccessLevel"
          ? {
              type: "src",
              style: "rounded",
              icon: option.accessLevelMetadata?.avatarUrl || defaultAvatarURL,
              fallbackIcon: defaultAvatarURL,
            }
          : undefined
    : undefined;

  if (props.multiple) {
    const renderInputValue =
      props.selectedRoles && props.selectedRoles.length > 0
        ? (values?: Role[]) => {
            if (!values || values.length === 0) {
              return "";
            }
            return (
              values[0].accessLevelName +
              (values.length > 1 ? ` +${values.length - 1}` : "")
            );
          }
        : undefined;

    const selectedRoles = props.selectedRoles ?? [];

    return (
      <Select<Role>
        multiple
        id={props.id}
        options={props.availableRoles}
        value={selectedRoles}
        onSelectValue={(role, reason) => {
          if (reason === "select-option" && role)
            props.onSelectRoles && props.onSelectRoles([role]);
          else if (reason === "remove-option" && role)
            props.onRemoveRoles && props.onRemoveRoles([role]);
        }}
        onBulkSelectValue={(roles, reason) => {
          if (reason === "select-option")
            props.onSelectRoles && props.onSelectRoles(roles);
          else if (reason === "remove-option")
            props.onRemoveRoles && props.onRemoveRoles(roles);
        }}
        getOptionLabel={(option) => option.accessLevelName}
        getOptionSublabel={(option) => {
          const expiration =
            props.expirationByRoleRemoteId?.[option.accessLevelRemoteId];
          if (expiration === null) {
            return "Indefinite access";
          } else if (expiration) {
            return `Expires ${expiration.fromNow()}`;
          }
          return "";
        }}
        getOptionSelected={(option, value) =>
          option.accessLevelRemoteId === value.accessLevelRemoteId
        }
        loading={props.loading}
        placeholder="Select a role"
        getIcon={getIcon}
        checkboxSize="md"
        listboxFooter={{
          footer: (
            <div
              className={sprinkles({
                fontFamily: "body",
                padding: "md",
              })}
            >
              {selectedRoles.length > 0 ? (
                <Label
                  label={`Clear selection (${selectedRoles.length})`}
                  color="blue600V3"
                  onClick={() =>
                    props.onRemoveRoles && props.onRemoveRoles(selectedRoles)
                  }
                />
              ) : (
                <Label label="No roles selected" color="gray700" />
              )}
            </div>
          ),
          sticky: true,
        }}
        renderInputValue={renderInputValue}
      />
    );
  }

  return (
    <Select<Role>
      id={props.id}
      options={props.availableRoles}
      value={props.selectedRole}
      onChange={props.setSelectedRole}
      getOptionLabel={(option) => option.accessLevelName}
      getOptionSublabel={(option) => {
        const expiration =
          props.expirationByRoleRemoteId?.[option.accessLevelRemoteId];
        if (expiration) return `Expires ${expiration.fromNow()}`;
        else if (expiration === null) return "Unlimited access";
        return "";
      }}
      getOptionSelected={(option, value) =>
        option.accessLevelRemoteId === value.accessLevelRemoteId
      }
      loading={props.loading}
      placeholder="Select a role"
      getIcon={getIcon}
    />
  );
};

export default RequestModalRoleDropdown;
