import {
  FirstPartyTokenFragment,
  useFirstPartyTokensQuery,
} from "api/generated/graphql";
import { logError } from "utils/logging";

import { OrgSettingsSection } from "../OrgSettingsV3";
import ApiTokensTableV3 from "./ApiTokensTableV3";

export type ApiV3Props = {
  title: string;
};

const ApiV3 = (props: ApiV3Props) => {
  const { data, error } = useFirstPartyTokensQuery();

  let tokens: FirstPartyTokenFragment[] = [];
  if (data) {
    switch (data.firstPartyTokens.__typename) {
      case "FirstPartyTokensResult":
        tokens = data.firstPartyTokens.tokens;
        break;
      default:
        logError(new Error(`failed to retrieve API tokens`));
    }
  } else if (error) {
    logError(error, `failed to retrieve API tokens`);
  }

  return (
    <OrgSettingsSection title={props.title}>
      <ApiTokensTableV3 tokens={tokens} />
    </OrgSettingsSection>
  );
};

export default ApiV3;
