import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewAction,
  AccessReviewUserFragment,
  AccessReviewUserPreviewFragment,
  AccessReviewUserResourcePreviewFragment,
  EntityType,
  ReviewerUserStatus,
  ReviewResourceUserAction,
  useUpdateResourceUserReviewersMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { EntityViewerTabType } from "components/entity_viewer/EntityViewerTabs";
import AccessReviewNoteLabel from "components/label/AccessReviewNoteLabel";
import ConnectionLabel from "components/label/item_labels/ConnectionLabel";
import { ResourceLabel } from "components/label/Label";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import NavigationContext from "components/layout/NavigationContextProvider";
import AccessReviewerModal from "components/modals/AccessReviewerModal";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import {
  CellRow,
  Header,
  ScrollableMuiVirtualTable,
} from "components/tables/material_table/MuiVirtualTable";
import { useToast } from "components/toast/Toast";
import { IconName } from "components/ui/icon/Icon";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { SortDirection } from "react-virtualized";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { logError } from "utils/logging";
import AccessReviewAcceptRevokeToggleButton, {
  calculateBatchAcceptRevokeState,
} from "views/access_reviews/AccessReviewAcceptRevokeToggleButton";
import AccessReviewContext, {
  AccessReviewContextActionType,
} from "views/access_reviews/AccessReviewContext";
import AccessReviewResourceRoleCell, {
  updateReviewStateForResourceUserRoleChange,
} from "views/access_reviews/AccessReviewResourceRoleCell";
import AccessReviewReviewerDetails, {
  accessReviewReviewerDetailsRowHeight,
} from "views/access_reviews/AccessReviewReviewerDetails";
import AccessReviewReviewersCell from "views/access_reviews/AccessReviewReviewersCell";
import {
  accessReviewItemStatusSortValue,
  AccessReviewOutcomeLabel,
  AccessReviewStatusLabel,
} from "views/access_reviews/AccessReviewStatus";
import {
  getReviewerModalEntries,
  getReviewersSortValue,
  isReviewer,
} from "views/access_reviews/common/Common";
import { getAccessReviewResourceUrl } from "views/access_reviews/common/Routes";
import {
  CONNECTION_HEADER,
  ITEM_ACTION_HEADER,
  ITEM_STATUS_HEADER,
  NOTE_HEADER,
  OUTCOME_HEADER,
  RESOURCE_NAME_HEADER,
  REVIEWERS_HEADER,
  ROLE_HEADER,
} from "views/access_reviews/common/TableHeaders";
import { getAccessReviewUserId } from "views/access_reviews/users/AccessReviewUsers";

interface AccessReviewUserResourceTableRow {
  name: string;
  role: string;
  connection: string;
  note?: string;
  reviewers?: string;
  status?: string;
  outcome?: string;
  acceptRevoke?: string;
}

type AccessReviewUserResourcesTableProps = {
  accessReviewUser: AccessReviewUserFragment;
  setSelectedTab: (tab: EntityViewerTabType) => void;
};

export const AccessReviewUserResourcesTable = (
  props: AccessReviewUserResourcesTableProps
) => {
  const { navigationState } = useContext(NavigationContext);
  const { authState } = useContext(AuthContext);
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );
  const history = useHistory();

  const [expandedByRowId, setExpandedByRowId] = useState<
    Record<string, boolean>
  >({});

  let resources = props.accessReviewUser.resourceUsers || [];

  if (navigationState.isOnlyMyReviewsFilterOn) {
    resources = resources.filter((accessReviewResourceUser) => {
      return (
        authState.user?.user.id &&
        isReviewer(
          authState.user.user.id,
          accessReviewResourceUser.reviewerUsers ?? []
        )
      );
    });
  }

  const performReviewState =
    accessReviewState.performReviewStateByUARUserId[
      getAccessReviewUserId(props.accessReviewUser)
    ];
  const isUserBeingReviewed = !!performReviewState;

  let currentUserHasCompleteReviews = false;
  if (isUserBeingReviewed) {
    resources = resources.filter((user) => {
      return user.reviewerUsers?.find((reviewer) => {
        if (reviewer.userId === authState.user?.user.id) {
          currentUserHasCompleteReviews = true;
          return reviewer.status === ReviewerUserStatus.NotStarted;
        }
        return false;
      });
    });
  }
  /*
   * Figure out which columns to show depending on if the client is
   * performing reviews or viewing reviews.
   */

  // We always show these columns.
  const headers: Header<AccessReviewUserResourceTableRow>[] = [
    RESOURCE_NAME_HEADER,
    ROLE_HEADER,
    CONNECTION_HEADER,
    REVIEWERS_HEADER,
  ];

  if (isUserBeingReviewed && resources.length > 0) {
    const batchAcceptRevokeToggleButton = (
      <AccessReviewAcceptRevokeToggleButton
        state={calculateBatchAcceptRevokeState(
          performReviewState.resourceUserActions,
          resources.length
        )}
        acceptText={"Accept All"}
        revokeText={"Revoke All"}
        onStateChange={(state) => {
          if (!isUserBeingReviewed) return;

          performReviewState.resourceUserActions.forEach(
            (resourceUserAction) => {
              resourceUserAction.action = state;
            }
          );

          resources.forEach((resourceUser) => {
            const existingInfo = performReviewState.resourceUserActions.find(
              (resourceUserAction) =>
                resourceUserAction.accessReviewResourceUserId ===
                resourceUser.id
            );

            if (!existingInfo) {
              performReviewState.resourceUserActions.push({
                accessReviewResourceUserId: resourceUser.id,
                action: state,
              });
            }
          });

          accessReviewDispatch({
            type: AccessReviewContextActionType.AccessReviewItemUpdate,
            payload: {
              performReviewStateByUARUserId: {
                ...accessReviewState.performReviewStateByUARUserId,
                [getAccessReviewUserId(
                  props.accessReviewUser
                )]: performReviewState,
              },
            },
          });
        }}
      />
    );
    headers.push(
      {
        ...ITEM_ACTION_HEADER,
        customHeader: batchAcceptRevokeToggleButton,
      },
      NOTE_HEADER
    );
  } else {
    headers.push(ITEM_STATUS_HEADER, OUTCOME_HEADER);
  }

  /*
   * Table rows data
   */

  const resourceUserActionByUarResourceUserId: Record<
    string,
    ReviewResourceUserAction
  > = {};
  performReviewState?.resourceUserActions?.forEach((resourceUserAction) => {
    const id = resourceUserAction.accessReviewResourceUserId;
    resourceUserActionByUarResourceUserId[id] = resourceUserAction;
  });

  const rows: CellRow<AccessReviewUserResourceTableRow>[] = resources.map(
    (resourceUser, idx) => {
      const rowId = resourceUser.id;
      const resourceUserAction = resourceUserActionByUarResourceUserId[rowId];

      const resourceName =
        resourceUser.resource?.name || resourceUser.resourceId;
      const connection = resourceUser.resource?.connection;
      const connectionId = resourceUser.resource?.connectionId;
      const status = resourceUser.statusAndOutcome.status;
      const outcome = resourceUser.statusAndOutcome.outcome;

      return {
        id: rowId,
        expandableContent: {
          content: (
            <AccessReviewReviewerDetails
              reviewers={resourceUser.reviewerUsers}
            />
          ),
          expandedRowHeight: accessReviewReviewerDetailsRowHeight(
            resourceUser.reviewerUsers
          ),
          isExpanded: expandedByRowId[rowId],
          setIsExpanded: (expanded) => {
            setExpandedByRowId({
              ...expandedByRowId,
              [rowId]: expanded,
            });
          },
        },
        data: {
          name: {
            value: resourceName,
            element: (
              <ResourceLabel
                text={resourceName}
                subText={
                  getResourceTypeInfo(resourceUser?.resource?.resourceType)
                    ?.name || "--"
                }
                entityType={EntityTypeDeprecated.Resource}
                bold={true}
                resourceType={resourceUser?.resource?.resourceType}
                icon={resourceUser?.resource?.iconUrl}
                iconLarge={true}
                pointerCursor={true}
              />
            ),
            clickHandler: () => {
              history.push(
                getAccessReviewResourceUrl(resourceUser.accessReviewResourceId)
              );
            },
          },
          role: {
            value: resourceUser.accessLevel.accessLevelName || "",
            element: (
              <AccessReviewResourceRoleCell
                role={resourceUser.accessLevel}
                updatedRole={resourceUserAction?.updatedAccessLevel}
                showEditIcon={isUserBeingReviewed}
                resourceId={resourceUser.resourceId}
                onRoleChange={(role) => {
                  if (!performReviewState) {
                    return;
                  }

                  const updatedPerformReviewState = updateReviewStateForResourceUserRoleChange(
                    performReviewState,
                    resourceUser,
                    role
                  );

                  accessReviewDispatch({
                    type: AccessReviewContextActionType.AccessReviewItemUpdate,
                    payload: {
                      performReviewStateByUARResourceId: {
                        ...accessReviewState.performReviewStateByUARUserId,
                        [getAccessReviewUserId(
                          props.accessReviewUser
                        )]: updatedPerformReviewState,
                      },
                    },
                  });
                }}
              />
            ),
          },
          connection: {
            sortValue: connection?.name || "",
            value: connectionId || "",
            element: (
              <ConnectionLabel
                text={connection?.name}
                connectionType={connection?.connectionType}
              />
            ),
          },
          reviewers: {
            value: getReviewersSortValue(resourceUser.reviewerUsers ?? []),
            element: (
              <AccessReviewerUserResourcesCell
                accessReviewUser={props.accessReviewUser}
                resourceUser={resourceUser}
              />
            ),
          },
          status: {
            value: status,
            sortValue: accessReviewItemStatusSortValue(status),
            element: (
              <AccessReviewStatusLabel
                entityType={EntityType.AccessReviewResourceUser}
                status={status}
                warnings={resourceUser.warnings}
                resource={resourceUser.resource}
                user={props.accessReviewUser.user}
                accessReviewId={props.accessReviewUser.accessReviewId}
              />
            ),
          },
          outcome: {
            value: outcome,
            element: (
              <AccessReviewOutcomeLabel
                entityType={EntityType.AccessReviewResourceUser}
                outcome={outcome}
              />
            ),
          },
          note: {
            options: {
              display: isUserBeingReviewed,
            },
            value: "",
            element: (
              <AccessReviewNoteLabel
                initNoteContent={resourceUserAction?.note}
                onSubmit={(updatedNoteContent) => {
                  if (!performReviewState) {
                    return;
                  }

                  const existingInfo = performReviewState.resourceUserActions.find(
                    (resourceUserAction) =>
                      resourceUserAction.accessReviewResourceUserId ===
                      resourceUser.id
                  );

                  if (existingInfo) {
                    existingInfo.note = updatedNoteContent;
                  } else {
                    performReviewState.resourceUserActions.push({
                      accessReviewResourceUserId: resourceUser.id,
                      action: AccessReviewAction.NoAction,
                      note: updatedNoteContent,
                    });
                  }
                }}
              />
            ),
            sortValue: idx,
          },
          acceptRevoke: {
            value: resourceUserAction?.action || AccessReviewAction.NoAction,
            element: (
              <AccessReviewAcceptRevokeToggleButton
                state={
                  resourceUserAction?.action || AccessReviewAction.NoAction
                }
                onStateChange={(state) => {
                  if (!performReviewState) {
                    return;
                  }

                  const existingInfo = performReviewState.resourceUserActions.find(
                    (resourceUserAction) =>
                      resourceUserAction.accessReviewResourceUserId === rowId
                  );

                  if (existingInfo) {
                    existingInfo.action = state;
                    delete existingInfo.updatedAccessLevel;
                  } else {
                    performReviewState.resourceUserActions.push({
                      accessReviewResourceUserId: rowId,
                      action: state,
                    });
                  }
                  accessReviewDispatch({
                    type: AccessReviewContextActionType.AccessReviewItemUpdate,
                    payload: {
                      performReviewStateByUARUserId: {
                        ...accessReviewState.performReviewStateByUARUserId,
                        [getAccessReviewUserId(
                          props.accessReviewUser
                        )]: performReviewState,
                      },
                    },
                  });
                }}
              />
            ),
          },
        },
      };
    }
  );

  let emptyStateTitle: string;
  let emptyStateSubtitle: string;
  let emptyStateButtonTitle: string | undefined;
  let emptyStateButtonIcon: IconName | undefined;
  let emptyStateOnClickHandler: (() => void) | undefined;
  if (isUserBeingReviewed) {
    if (currentUserHasCompleteReviews) {
      emptyStateTitle = `No resources left to review`;
    } else {
      emptyStateTitle = `No resources to review`;
    }
    emptyStateSubtitle = `However, you have outstanding reviews for this user's groups.`;
    emptyStateButtonTitle = `Review group access`;
    emptyStateButtonIcon = "check-circle";
    emptyStateOnClickHandler = () => {
      history.replace(`#${EntityViewerTabType.Groups}`);
      props.setSelectedTab(EntityViewerTabType.Groups);
    };
  } else {
    emptyStateTitle = `No resources to review`;
    emptyStateSubtitle = navigationState.isOnlyMyReviewsFilterOn
      ? `No resources have been assigned for you to review.`
      : `This user had no direct access to any resources at the time of the access review.`;
  }

  return (
    <EmptyStateContentWrapper
      content={
        <ScrollableMuiVirtualTable
          columns={headers}
          rows={rows}
          defaultSortBy={"name"}
          defaultSortDirection={SortDirection.ASC}
          allRowsLoaded
          expandable
        />
      }
      entityType={EntityType.User}
      title={emptyStateTitle}
      subtitle={emptyStateSubtitle}
      buttonTitle={emptyStateButtonTitle}
      buttonIcon={emptyStateButtonIcon}
      onClickHandler={emptyStateOnClickHandler}
      isEmpty={resources.length === 0}
    />
  );
};

type AccessReviewerUserResourcesCellProps = {
  accessReviewUser: AccessReviewUserPreviewFragment;
  resourceUser: AccessReviewUserResourcePreviewFragment;
};

const AccessReviewerUserResourcesCell = (
  props: AccessReviewerUserResourcesCellProps
) => {
  const { accessReviewState } = useContext(AccessReviewContext);
  const [showReviewersModal, setShowReviewersModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { displaySuccessToast } = useToast();

  const [
    updateUserResourceReviewers,
    { loading },
  ] = useUpdateResourceUserReviewersMutation({});

  const canEditReviewers =
    accessReviewState.ongoingAccessReviewIdSet.has(
      props.accessReviewUser.accessReviewId
    ) && props.accessReviewUser.canEditReviewers;

  return (
    <>
      <AccessReviewReviewersCell
        itemType="resource"
        reviewerUsers={props.resourceUser.reviewerUsers}
        canEditReviewers={canEditReviewers}
        onClick={() => {
          setShowReviewersModal(true);
        }}
      />
      {showReviewersModal && (
        <AccessReviewerModal
          title={"Reviewers"}
          isModalOpen={showReviewersModal}
          onClose={() => {
            setShowReviewersModal(false);
            setErrorMessage("");
          }}
          loading={loading}
          onSubmit={async (reviewers) => {
            try {
              const { data } = await updateUserResourceReviewers({
                variables: {
                  input: {
                    accessReviewResourceUserId: props.resourceUser.id,
                    reviewers: reviewers,
                  },
                },
                refetchQueries: ["AccessReviewUser"],
              });
              switch (data?.updateResourceUserReviewers.__typename) {
                case "UpdateResourceUserReviewersResult": {
                  displaySuccessToast("Success: reviewers updated");
                  setShowReviewersModal(false);
                  break;
                }
                case "AccessReviewAlreadyStoppedError": {
                  setErrorMessage(data?.updateResourceUserReviewers.message);
                  break;
                }
              }
            } catch (error) {
              logError(error, "failed to update access reviewers");
              setErrorMessage(
                getModifiedErrorMessage(
                  "Error: failed to update access reviewers",
                  error
                )
              );
            }
          }}
          errorMessage={errorMessage}
          entryInfos={getReviewerModalEntries(
            props.resourceUser.reviewerUsers,
            null
          )}
          canEditReviewers={canEditReviewers}
        />
      )}
    </>
  );
};

export default AccessReviewUserResourcesTable;
