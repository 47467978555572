import {
  AccessReviewConnectionSmallFragment,
  AccessReviewFragment,
  AccessReviewGroupSmallFragment,
  AccessReviewItemStatus,
  AccessReviewResourceSmallFragment,
  SortDirection,
  useAccessReviewQuery,
  useMyAccessReviewItemsQuery,
} from "api/generated/graphql";
import { Column, ColumnContainer } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeader";
import ColumnListItem, {
  ColumnListItemsSkeleton,
} from "components/column/ColumnListItem";
import { ColumnSearchAndSort } from "components/column/ColumnSearchAndSort";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import { Divider, Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useEffect, useState } from "react";
import { Route, Switch, useHistory, useParams } from "react-router";
import { filterSearchResults } from "utils/search/filter";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import AccessReviewConnection from "./AccessReviewConnection";
import AccessReviewGroup from "./AccessReviewGroup";
import AccessReviewResource from "./AccessReviewResource";
import ProgressCard from "./common/ProgressCard";

const MyAccessReviews = () => {
  return (
    <ColumnContainer>
      <MyReviewsColumn />
      <Switch>
        <Route
          path="/access-reviews/:accessReviewId/my-reviews/r/:accessReviewResourceId"
          component={AccessReviewResource}
        />
        <Route
          path="/access-reviews/:accessReviewId/my-reviews/g/:accessReviewGroupId"
          component={AccessReviewGroup}
        />
        <Route
          path="/access-reviews/:accessReviewId/my-reviews/c/:accessReviewConnectionId"
          component={AccessReviewConnection}
        />
      </Switch>
    </ColumnContainer>
  );
};

type ReviewItem =
  | AccessReviewResourceSmallFragment
  | AccessReviewGroupSmallFragment
  | AccessReviewConnectionSmallFragment;

interface ReviewItems {
  resources: AccessReviewResourceSmallFragment[];
  groups: AccessReviewGroupSmallFragment[];
  connections: AccessReviewConnectionSmallFragment[];
}

const SORT_OPTIONS = [
  {
    label: "Default",
    value: {
      field: "default",
    },
  },
  {
    label: "Name (A-Z)",
    value: {
      field: "name",
      direction: SortDirection.Asc,
    },
  },
  {
    label: "Name (Z-A)",
    value: {
      field: "name",
      direction: SortDirection.Desc,
    },
  },
];

const getItemName = (item: ReviewItem) => {
  if ("resource" in item && item.resource) {
    return item.resource.name;
  }
  if ("group" in item && item.group) {
    return item.group.name;
  }
  if ("connection" in item && item.connection) {
    return item.connection.name;
  }
};

const MyReviewsColumn = () => {
  const {
    accessReviewId,
    accessReviewResourceId,
    accessReviewConnectionId,
    accessReviewGroupId,
  } = useParams<Record<string, string>>();
  const history = useHistory();

  const [searchQuery, setSearchQuery] = useState("");
  const [sortOption, setSortOption] = useState(SORT_OPTIONS[0]);

  const { data, error, loading } = useAccessReviewQuery({
    variables: {
      input: {
        accessReviewId,
      },
    },
    skip: !accessReviewId,
  });
  let accessReview: AccessReviewFragment | null = null;
  if (data?.accessReview.__typename === "AccessReviewResult") {
    accessReview = data.accessReview.accessReview;
  }

  const {
    data: itemsData,
    loading: itemsLoading,
    previousData: prevItemsData,
  } = useMyAccessReviewItemsQuery({
    variables: {
      input: {
        accessReviewId,
      },
    },
    skip: !accessReviewId,
  });
  let reviewItems: ReviewItems | undefined;
  if (
    itemsData?.myAccessReviewItems.__typename === "MyAccessReviewItemsResult"
  ) {
    reviewItems = itemsData.myAccessReviewItems;
  }

  const todoItems: ReviewItem[] = [];
  const completedItems: ReviewItem[] = [];
  reviewItems?.resources.forEach((resource) => {
    if (
      resource.currentUserReviewerStatus?.itemStatus ===
        AccessReviewItemStatus.NotStarted ||
      resource.currentUserReviewerStatus?.itemStatus ===
        AccessReviewItemStatus.PartiallyCompleted
    ) {
      todoItems.push(resource);
    } else if (
      resource.currentUserReviewerStatus?.itemStatus ===
      AccessReviewItemStatus.Completed
    ) {
      completedItems.push(resource);
    }
  });
  reviewItems?.groups.forEach((group) => {
    if (
      group.currentUserReviewerStatus?.itemStatus ===
        AccessReviewItemStatus.NotStarted ||
      group.currentUserReviewerStatus?.itemStatus ===
        AccessReviewItemStatus.PartiallyCompleted
    ) {
      todoItems.push(group);
    } else if (
      group.currentUserReviewerStatus?.itemStatus ===
      AccessReviewItemStatus.Completed
    ) {
      completedItems.push(group);
    }
  });
  reviewItems?.connections.forEach((connection) => {
    if (
      connection.currentUserReviewerStatus?.itemStatus ===
        AccessReviewItemStatus.NotStarted ||
      connection.currentUserReviewerStatus?.itemStatus ===
        AccessReviewItemStatus.PartiallyCompleted
    ) {
      todoItems.push(connection);
    } else if (
      connection.currentUserReviewerStatus?.itemStatus ===
      AccessReviewItemStatus.Completed
    ) {
      completedItems.push(connection);
    }
  });

  // On initial render, open first actionable item by default
  useEffect(() => {
    if (
      !itemsLoading &&
      !prevItemsData &&
      !accessReviewResourceId &&
      !accessReviewGroupId &&
      !accessReviewConnectionId
    ) {
      const firstTodoItem = todoItems[0];
      if (firstTodoItem) {
        let urlKey = "r";
        if ("group" in firstTodoItem) {
          urlKey = "g";
        } else if ("connection" in firstTodoItem) {
          urlKey = "c";
        }
        history.push(
          `/access-reviews/${accessReviewId}/my-reviews/${urlKey}/${firstTodoItem.id}`
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsLoading]);

  if (loading || itemsLoading) {
    return (
      <Column>
        <ColumnHeaderSkeleton />
        <Divider />
        <ColumnListItemsSkeleton />
      </Column>
    );
  }

  if (!accessReviewId || !accessReview || error) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  let filteredTodoItems = filterSearchResults(
    todoItems,
    searchQuery,
    (item) => [getItemName(item)]
  );
  let filteredCompletedItems = filterSearchResults(
    completedItems,
    searchQuery,
    (item) => [getItemName(item)]
  );
  filteredTodoItems.sort((a, b) => {
    const aName = getItemName(a) ?? "";
    const bName = getItemName(b) ?? "";
    if (sortOption.value.field === "name") {
      if (sortOption.value.direction === SortDirection.Asc) {
        return aName < bName ? -1 : 1;
      } else {
        return aName < bName ? 1 : -1;
      }
    }
    return 1;
  });
  filteredCompletedItems.sort((a, b) => {
    const aName = getItemName(a) ?? "";
    const bName = getItemName(b) ?? "";
    if (sortOption.value.field === "name") {
      if (sortOption.value.direction === SortDirection.Asc) {
        return aName < bName ? -1 : 1;
      } else {
        return aName < bName ? 1 : -1;
      }
    }
    return 1;
  });

  const renderItem = (item: ReviewItem) => {
    let statusIcon;
    if (
      item.currentUserReviewerStatus?.itemStatus ===
      AccessReviewItemStatus.PartiallyCompleted
    ) {
      statusIcon = (
        <div className={sprinkles({ display: "flex" })}>
          <Icon name="incomplete" color="yellow700" size="xs" />
        </div>
      );
    } else if (
      item.currentUserReviewerStatus?.itemStatus ===
      AccessReviewItemStatus.Completed
    ) {
      statusIcon = (
        <div className={sprinkles({ display: "flex" })}>
          <Icon name="check-circle" color="green600" size="xs" />
        </div>
      );
    }

    if ("resource" in item && item.resource) {
      return (
        <ColumnListItem
          key={item.id}
          label={item.resource.name}
          icon={{
            type: "entity",
            entityType: item.resource.resourceType,
          }}
          sublabel={resourceTypeInfoByType[item.resource.resourceType].fullName}
          rightContent={statusIcon}
          selected={accessReviewResourceId === item.id}
          onClick={() =>
            history.push(
              `/access-reviews/${accessReviewId}/my-reviews/r/${item.id}`
            )
          }
        />
      );
    }
    if ("group" in item && item.group) {
      return (
        <ColumnListItem
          key={item.id}
          label={item.group.name}
          icon={{
            type: "entity",
            entityType: item.group.groupType,
          }}
          sublabel={groupTypeInfoByType[item.group.groupType].name}
          rightContent={statusIcon}
          selected={accessReviewGroupId === item.id}
          onClick={() =>
            history.push(
              `/access-reviews/${accessReviewId}/my-reviews/g/${item.id}`
            )
          }
        />
      );
    }
    if ("connection" in item && item.connection) {
      return (
        <ColumnListItem
          key={item.id}
          label={item.connection.name}
          icon={{
            type: "entity",
            entityType: item.connection.connectionType,
          }}
          rightContent={statusIcon}
          sublabel="App"
          selected={accessReviewConnectionId === item.id}
          onClick={() =>
            history.push(
              `/access-reviews/${accessReviewId}/my-reviews/c/${item.id}`
            )
          }
        />
      );
    }
  };

  const renderItems = () => {
    const totalNumItems = todoItems.length + completedItems.length;
    if (totalNumItems === 0) {
      return (
        <div className={sprinkles({ margin: "sm" })}>Nothing to review</div>
      );
    }

    return (
      <>
        <ProgressCard
          totalNumItems={totalNumItems}
          completedItems={completedItems.length}
        />
        <ColumnSearchAndSort
          setSearchQuery={setSearchQuery}
          sortOptions={SORT_OPTIONS}
          sortBy={sortOption}
          setSortBy={setSortOption}
          placeholder="Search for access review item"
          trackName="access-review-my-items"
        />
        <ColumnContent>
          {filteredTodoItems.length + filteredCompletedItems.length === 0 &&
            "No search results"}
          {filteredTodoItems.length > 0 && (
            <Divider label="To do" labelPosition="left" />
          )}
          {filteredTodoItems.map(renderItem)}
          {filteredCompletedItems.length > 0 && (
            <Divider label="Completed" labelPosition="left" />
          )}
          {filteredCompletedItems.map(renderItem)}
        </ColumnContent>
      </>
    );
  };

  return (
    <Column>
      <ColumnHeader
        title="My Reviews"
        icon={{ type: "name", icon: "hourglass" }}
        breadcrumbs={[
          { name: "Access Reviews", to: "/access-reviews" },
          {
            name: "Ongoing",
            to: "/access-reviews?category=ongoing",
          },
          {
            name: accessReview.name,
            to: `/access-reviews/${accessReviewId}`,
          },
        ]}
      />
      <Divider />
      {renderItems()}
    </Column>
  );
};

export default MyAccessReviews;
