import React, { FunctionComponent, PropsWithChildren, useRef } from "react";

export type VirtualTableInfo = {
  lastRenderedIndex: number;
  scrollTop: number;
};

export type VirtualTableInfoMap = Map<string, VirtualTableInfo>;

export type VirtualTableContextValue = {
  virtualTableInfoMap: VirtualTableInfoMap;
};

const VirtualTableContext = React.createContext<VirtualTableContextValue>({
  virtualTableInfoMap: new Map(),
});

export const VirtualTableContextProvider: FunctionComponent = (
  props: PropsWithChildren<{}>
) => {
  const virtualTableInfoMap = useRef<VirtualTableInfoMap>(new Map());

  return (
    <VirtualTableContext.Provider
      value={{ virtualTableInfoMap: virtualTableInfoMap.current }}
    >
      {props.children}
    </VirtualTableContext.Provider>
  );
};

export default VirtualTableContext;
