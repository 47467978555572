import { getModifiedErrorMessage } from "api/ApiContext";
import {
  Maybe,
  useResetUserMfaMutation,
  UserPreviewSmallFragment,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Modal } from "components/ui";
import React, { useState } from "react";
import { useContext } from "react";
import { isOnPrem } from "utils/environment";
import { logError, logWarning } from "utils/logging";
import AppContext from "views/app/AppContext";

export type UserResetMFAModalProps = {
  user: UserPreviewSmallFragment;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

export const UserResetMFAModal = (props: UserResetMFAModalProps) => {
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();
  const { appState } = useContext(AppContext);

  const [resetUserMFA, { loading }] = useResetUserMfaMutation();

  const modalReset = () => {
    props.setShowModal(false);
    setErrorMessage(null);
  };

  const handleSubmit = async () => {
    try {
      const { data } = await resetUserMFA({
        variables: {
          input: {
            id: props.user.id,
          },
        },
      });
      switch (data?.resetUserMFA.__typename) {
        case "ResetUserMFAResult":
          modalReset();
          displaySuccessToast(`Success: User MFA has been reset.`);
          break;
        case "ResetAuth0MFAError":
          logWarning(new Error(data.resetUserMFA.message));
          setErrorMessage(data.resetUserMFA.message);
          break;
        case "UserNotFoundError":
        case "SystemUserIsImmutableError":
        default:
          logError(new Error(`failed to reset user mfa`));
          setErrorMessage(`Error: failed to reset user mfa`);
      }
    } catch (error) {
      logError(error, `failed to reset user mfa`);
      setErrorMessage(
        getModifiedErrorMessage(`Error: failed to reset user mfa`, error)
      );
    }
  };

  if (isOnPrem(appState.environment)) {
    return (
      <Modal
        title={`Reset Opal MFA for ${props.user.fullName}`}
        isOpen={props.showModal}
        onClose={modalReset}
      >
        <Modal.Body>
          <p>
            Please reach out to Opal support (support@opal.dev) to reset your
            user's MFA.
          </p>
        </Modal.Body>
        <Modal.Footer
          primaryButtonType="error"
          primaryButtonLabel="Done"
          onPrimaryButtonClick={() => {
            modalReset();
          }}
        />
      </Modal>
    );
  }

  return (
    <Modal
      title={`Reset Opal MFA for ${props.user.fullName}`}
      isOpen={props.showModal}
      onClose={modalReset}
    >
      <Modal.Body>
        <p>
          Are you sure you want to reset Opal MFA for{" "}
          <b>
            {props.user.fullName} ({props.user.email})
          </b>
          ? This cannot be undone.
        </p>
        <p>
          This will remove all MFA factors that the user has registered, such as
          any Webauthn (e.g. Yubikey, TouchID) or SMS factors. The user will be
          prompted to re-register MFA factors on their next login.
        </p>
        {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
      </Modal.Body>
      <Modal.Footer
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLabel="Remove"
        primaryButtonLoading={loading}
        primaryButtonType="error"
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={() => {
          modalReset();
        }}
      />
    </Modal>
  );
};

export default UserResetMFAModal;
