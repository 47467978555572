import { AccessType, EntityType } from "api/generated/graphql";
import { produce } from "immer";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router";

import { GroupByOption, UsageRange } from "../common";

const VIZ_DATA_URL_KEY = "vd";

interface FilteredMultiGroup {
  connectionId?: string;
  groupType: string;
  name: string;
}

interface FilteredMultiResource {
  connectionId?: string;
  parentResourceId?: string;
  resourceType: string;
  name: string;
}

export type FilterState = {
  selection: {
    resourceIds?: string[];
    userIds?: string[];
    groupIds?: string[];

    multiResourceSelections?: FilteredMultiResource[];
    multiGroupSelections?: FilteredMultiGroup[];
  };
  attributesShown: {
    email?: boolean;
    position?: boolean;
    team?: boolean;
  };
  accessTypes: AccessType[];
  usage?: UsageRange;
  showHighlightedOnly?: boolean;
  showUnmanagedItems?: boolean;
  hideGroups?: boolean;
  groupBy?: GroupByOption;
};

export const getInitialFilterState = (): FilterState => {
  return {
    selection: {},
    attributesShown: {},
    accessTypes: [
      AccessType.Expiring,
      AccessType.Birthright,
      AccessType.Longstanding,
    ],
    showHighlightedOnly: false,
    showUnmanagedItems: false,
  };
};

export type FilterAction =
  | {
      type: "ADD_USER";
      payload: {
        id: string;
        clearFilter?: boolean;
      };
    }
  | {
      type: "REMOVE_USER";
      payload: {
        id: string;
      };
    }
  | {
      type: "ADD_RESOURCE";
      payload: {
        id: string;
        clearFilter?: boolean;
      };
    }
  | {
      type: "REMOVE_RESOURCE";
      payload: {
        id: string;
      };
    }
  | {
      type: "ADD_GROUP";
      payload: {
        id: string;
        clearFilter?: boolean;
      };
    }
  | {
      type: "REMOVE_GROUP";
      payload: {
        id: string;
      };
    }
  | {
      type: "ADD_MULTI_RESOURCE";
      payload: {
        connectionId?: string;
        parentResourceId?: string;
        resourceType: string;
        name: string;
      };
    }
  | {
      type: "REMOVE_MULTI_RESOURCE";
      payload: {
        connectionId?: string;
        resourceType: string;
      };
    }
  | {
      type: "ADD_MULTI_GROUP";
      payload: {
        connectionId?: string;
        groupType: string;
        name: string;
      };
    }
  | {
      type: "REMOVE_MULTI_GROUP";
      payload: {
        connectionId?: string;
        groupType: string;
      };
    }
  | {
      type: "SET_SHOW_EMAIL";
      payload: {
        showEmail: boolean;
      };
    }
  | {
      type: "SET_SHOW_POSITION";
      payload: {
        showPosition: boolean;
      };
    }
  | {
      type: "SET_SHOW_TEAM";
      payload: {
        showTeam: boolean;
      };
    }
  | {
      type: "UPDATE_ACCESS_TYPE_FILTER";
      payload: {
        accessType: AccessType;
        selected: boolean;
      };
    }
  | {
      type: "UPDATE_USAGE_FILTER";
      payload: {
        value?: UsageRange;
      };
    }
  | {
      type: "UPDATE_SHOW_HIGHLIGHTED_ONLY";
      payload: {
        value: boolean;
      };
    }
  | {
      type: "UPDATE_SHOW_UNMANAGED_ITEMS";
      payload: {
        value: boolean;
      };
    }
  | {
      type: "UPDATE_HIDE_GROUPS";
      payload: {
        value: boolean;
      };
    }
  | {
      type: "UPDATE_GROUP_BY";
      payload: {
        value: GroupByOption | undefined;
      };
    };

export function filterReducer(
  state: FilterState,
  action: FilterAction
): FilterState {
  switch (action.type) {
    case "ADD_USER": {
      return produce(state, (draft) => {
        if (action.payload.clearFilter) {
          draft.selection = {};
        }

        if (!draft.selection.userIds) {
          draft.selection.userIds = [];
        }
        draft.selection.userIds?.push(action.payload.id);
      });
    }
    case "REMOVE_USER": {
      return produce(state, (draft) => {
        if (!draft.selection.userIds) {
          draft.selection.userIds = [];
        }
        draft.selection.userIds = draft.selection.userIds?.filter(
          (userId) => userId !== action.payload.id
        );
      });
    }
    case "ADD_RESOURCE": {
      return produce(state, (draft) => {
        if (action.payload.clearFilter) {
          draft.selection = {};
        }

        if (!draft.selection.resourceIds) {
          draft.selection.resourceIds = [];
        }
        draft.selection.resourceIds?.push(action.payload.id);
      });
    }
    case "REMOVE_RESOURCE": {
      return produce(state, (draft) => {
        if (!draft.selection.resourceIds) {
          draft.selection.resourceIds = [];
        }
        draft.selection.resourceIds = draft.selection.resourceIds?.filter(
          (resourceId) => resourceId !== action.payload.id
        );
      });
    }
    case "ADD_GROUP": {
      return produce(state, (draft) => {
        if (action.payload.clearFilter) {
          draft.selection = {};
        }

        if (!draft.selection.groupIds) {
          draft.selection.groupIds = [];
        }
        draft.selection.groupIds?.push(action.payload.id);
      });
    }
    case "REMOVE_GROUP": {
      return produce(state, (draft) => {
        if (!draft.selection.groupIds) {
          draft.selection.groupIds = [];
        }

        draft.selection.groupIds = draft.selection.groupIds?.filter(
          (groupId) => groupId !== action.payload.id
        );
      });
    }
    case "ADD_MULTI_RESOURCE": {
      return produce(state, (draft) => {
        if (!draft.selection.multiResourceSelections) {
          draft.selection.multiResourceSelections = [];
        }
        draft.selection.multiResourceSelections?.push({
          connectionId: action.payload.connectionId,
          parentResourceId: action.payload.parentResourceId,
          resourceType: action.payload.resourceType,
          name: action.payload.name,
        });
      });
    }
    case "REMOVE_MULTI_RESOURCE": {
      return produce(state, (draft) => {
        if (!draft.selection.multiResourceSelections) {
          draft.selection.multiResourceSelections = [];
        }

        draft.selection.multiResourceSelections = draft.selection.multiResourceSelections?.filter(
          (resource) =>
            resource.connectionId !== action.payload.connectionId ||
            resource.resourceType !== action.payload.resourceType
        );
      });
    }
    case "ADD_MULTI_GROUP": {
      return produce(state, (draft) => {
        if (!draft.selection.multiGroupSelections) {
          draft.selection.multiGroupSelections = [];
        }
        draft.selection.multiGroupSelections?.push({
          connectionId: action.payload.connectionId,
          groupType: action.payload.groupType,
          name: action.payload.name,
        });
      });
    }
    case "REMOVE_MULTI_GROUP": {
      return produce(state, (draft) => {
        if (!draft.selection.multiGroupSelections) {
          draft.selection.multiGroupSelections = [];
        }

        draft.selection.multiGroupSelections = draft.selection.multiGroupSelections?.filter(
          (group) =>
            group.connectionId !== action.payload.connectionId ||
            group.groupType !== action.payload.groupType
        );
      });
    }
    case "SET_SHOW_EMAIL": {
      return produce(state, (draft) => {
        draft.attributesShown.email = action.payload.showEmail;
      });
    }
    case "SET_SHOW_POSITION": {
      return produce(state, (draft) => {
        draft.attributesShown.position = action.payload.showPosition;
      });
    }
    case "SET_SHOW_TEAM": {
      return produce(state, (draft) => {
        draft.attributesShown.team = action.payload.showTeam;
      });
    }
    case "UPDATE_ACCESS_TYPE_FILTER": {
      return produce(state, (draft) => {
        if (action.payload.selected) {
          draft.accessTypes.push(action.payload.accessType);
          // Unselect other filters
          draft.usage = undefined;
        } else {
          draft.accessTypes = draft.accessTypes.filter(
            (type) => type !== action.payload.accessType
          );
        }
      });
    }
    case "UPDATE_USAGE_FILTER": {
      return produce(state, (draft) => {
        draft.usage = action.payload.value;
        if (draft.usage != null) {
          // Unselect other filters
          draft.accessTypes = [];
        }
      });
    }
    case "UPDATE_SHOW_HIGHLIGHTED_ONLY": {
      return produce(state, (draft) => {
        draft.showHighlightedOnly = action.payload.value;
      });
    }
    case "UPDATE_SHOW_UNMANAGED_ITEMS": {
      return produce(state, (draft) => {
        draft.showUnmanagedItems = action.payload.value;
      });
    }
    case "UPDATE_HIDE_GROUPS": {
      return produce(state, (draft) => {
        draft.hideGroups = action.payload.value;
      });
    }
    case "UPDATE_GROUP_BY": {
      return produce(state, (draft) => {
        draft.groupBy = action.payload.value;
      });
    }
  }
}

const makeFilterStateURLHash = (filterState: FilterState) => {
  const outputString = encodeURIComponent(JSON.stringify(filterState));
  const output = btoa(outputString);
  return `?${VIZ_DATA_URL_KEY}=${output}`;
};

export const makeURLForEntityViz = (
  entityId: string,
  entityType: EntityType
) => {
  const filterState = getInitialFilterState();
  switch (entityType) {
    case EntityType.Resource:
      filterState.selection.resourceIds = [entityId];
      break;
    case EntityType.Group:
      filterState.selection.groupIds = [entityId];
      break;
    case EntityType.User:
      filterState.selection.userIds = [entityId];
      break;
  }
  return makeFilterStateURLHash(filterState);
};

export const useFilterState = (): FilterState => {
  const { search } = useLocation();
  if (!search) {
    return getInitialFilterState();
  }
  const params = new URLSearchParams(search);
  const data = params.get(VIZ_DATA_URL_KEY);

  if (!data) {
    return getInitialFilterState();
  }

  // Parse URL hash for filter state
  const inputString = decodeURIComponent(atob(data));

  const parsed = JSON.parse(inputString) as FilterState;

  return parsed;
};

export const useFilterDispatch = () => {
  const currentState = useFilterState();
  const history = useHistory();

  const dispatch = useCallback(
    (action: FilterAction) => {
      const updatedState = filterReducer(currentState, action);

      // Encode state to base64 and update to URL
      const hash = makeFilterStateURLHash(updatedState);
      history.push(hash);
    },
    [currentState, history]
  );

  return dispatch;
};
