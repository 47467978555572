import {
  EventFilterFragment,
  useEventFiltersQuery,
} from "api/generated/graphql";
import { ButtonV3, Select } from "components/ui";
import { vars } from "css/vars.css";
import { useState } from "react";
import { useHistory } from "react-router";

import { createUrlParamsFromEventFilter } from "./utils";
import EventFiltersManageModal from "./viewer/EventFiltersManageModal";

const SavedEventFilterDropdown = (props: {
  selectedEventFilter: EventFilterFragment | undefined;
  setSelectedEventFilter: (filter: EventFilterFragment | undefined) => void;
}) => {
  const { selectedEventFilter, setSelectedEventFilter } = props;
  const {
    data: eventFiltersData,
    loading: isEventFiltersLoading,
  } = useEventFiltersQuery();
  const eventFilters = eventFiltersData?.eventFilters.eventFilters ?? [];

  const history = useHistory();
  const [showManageEventsModal, setShowManageEventsModal] = useState<boolean>(
    false
  );

  const handleEventFilterChange = (
    eventFilter: EventFilterFragment | undefined
  ) => {
    if (eventFilter == null) {
      setSelectedEventFilter(undefined);
      history.push("/events");
      return;
    }

    if (eventFilter != null) {
      setSelectedEventFilter(eventFilter);
      const newParams = createUrlParamsFromEventFilter(eventFilter);
      history.push("/events" + newParams);
    }
  };

  const manageFiltersFooter = () => {
    return eventFilters.length > 0 ? (
      <div style={{ color: vars.color.blue600V3, width: "100%" }}>
        <ButtonV3
          type="mainBorderless"
          label="Manage saved filters..."
          size="xs"
          onClick={() => {
            setShowManageEventsModal(true);
            setSelectedEventFilter(undefined);
          }}
        />
      </div>
    ) : undefined;
  };

  return (
    <div style={{ minWidth: "200px" }}>
      <Select
        options={eventFilters}
        loading={isEventFiltersLoading}
        onChange={handleEventFilterChange}
        getOptionLabel={(filter) => {
          return filter.name;
        }}
        placeholder="Saved filters"
        value={selectedEventFilter}
        clearable={selectedEventFilter != null}
        listboxFooter={{ footer: manageFiltersFooter() }}
      />
      <EventFiltersManageModal
        eventFilters={eventFilters}
        showModal={showManageEventsModal}
        onClose={() => {
          setShowManageEventsModal(false);
        }}
      />
    </div>
  );
};

export default SavedEventFilterDropdown;
