import { Placement } from "@popperjs/core";
import { RecommendationsSubscoreType } from "api/generated/graphql";
import { PopoverV3 } from "components/ui";
import { Icon } from "components/ui";

interface Props {
  type: RecommendationsSubscoreType;
  hidePopover?: boolean;
  popoverDirection?: Placement;
}

import {
  outsideAccessIcon,
  permanentAccessIcon,
  typeToThreatContext,
  unusedAccessIcon,
} from "views/recommendations/ThreatPill";

import * as styles from "./ThreatPill.css";

const delay = 100;

const ThreatIcon = (props: Props) => {
  const { type, hidePopover, popoverDirection = "right" } = props;
  var icon = undefined;

  switch (type) {
    case RecommendationsSubscoreType.UnusedAccess:
      icon = unusedAccessIcon;
      break;
    case RecommendationsSubscoreType.PerpetualAccess:
      icon = permanentAccessIcon;
      break;
    case RecommendationsSubscoreType.UnmanagedAccess:
      icon = outsideAccessIcon;
      break;
  }
  if (hidePopover) {
    return <Icon size="xs" color="red500" iconStyle={"rounded"} data={icon} />;
  } else {
    return (
      <PopoverV3
        content={
          <div className={styles.tooltip}>{typeToThreatContext(type)}</div>
        }
        position={popoverDirection}
        delay={delay}
      >
        <Icon size="xs" color="red500" iconStyle={"rounded"} data={icon} />
      </PopoverV3>
    );
  }
};

export default ThreatIcon;
