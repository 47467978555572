import {
  ConnectionType,
  ResourceDropdownPreviewFragment,
  ResourceType,
  usePaginatedResourceDropdownQuery,
} from "api/generated/graphql";
import { Select } from "components/ui";
import _ from "lodash";
import { useEffect, useState } from "react";
import { logError } from "utils/logging";
import { SearchParamValue } from "utils/router/hooks";

// Limits number of options rendered for performance.
const MAX_OPTIONS_TO_DISPLAY = 50;

type PaginatedResourceDropdownProps = {
  value?: ResourceDropdownPreviewFragment;
  valueId?: SearchParamValue;
  onChange: (resource?: ResourceDropdownPreviewFragment) => void;
  resourceTypes?: ResourceType[];
  connectionType?: ConnectionType;
  selectedResourceIDs?: string[];
  disabled?: boolean;
  clearable?: boolean;
  /** Focus the input component on mount. */
  autoFocus?: boolean;
  placeholder?: string;
  selectOnly?: boolean;
  size?: "xs" | "sm" | "md";
};

/**
 * A searchable dropdown containing all resources in the organization matching
 * the given filters. This dropdown statically limits the number of rendered
 * options to preserve performance -- the user is expected to leverage search
 * instead of scrolling to select the desired resource.
 */
const PaginatedResourceDropdown = ({
  value,
  valueId,
  onChange,
  resourceTypes,
  connectionType,
  selectedResourceIDs,
  disabled,
  clearable = true,
  autoFocus,
  placeholder,
  selectOnly,
  size,
}: PaginatedResourceDropdownProps) => {
  const [searchQuery, setSearchQuery] = useState("");

  const {
    data: resourcesData,
    error: resourcesError,
    refetch,
  } = usePaginatedResourceDropdownQuery({
    variables: {
      input: {
        searchQuery,
        resourceTypes,
        connectionType,
        maxNumEntries: MAX_OPTIONS_TO_DISPLAY,
      },
    },
  });
  if (resourcesError) {
    logError(resourcesError, "failed to list resources");
  }

  useEffect(() => {
    refetch({
      input: {
        searchQuery,
        resourceTypes,
        connectionType,
        maxNumEntries: MAX_OPTIONS_TO_DISPLAY,
      },
    });
  }, [refetch, resourceTypes, connectionType, searchQuery]);

  const handleSearchInputChange = _.debounce((s: string) => {
    setSearchQuery(s);
  }, 250);

  const handleInputChange = (input: string) => {
    handleSearchInputChange(input);
  };

  let resources: ResourceDropdownPreviewFragment[] = [];
  switch (resourcesData?.resources.__typename) {
    case "ResourcesResult":
      resources = resourcesData.resources.resources;
      break;
    default:
      break;
  }
  const resourceById = _.fromPairs(resources.map((r) => [r.id, r]));
  let selectedResource;
  if (value) {
    selectedResource = resourceById[value.id];
  } else if (valueId != null) {
    selectedResource = resourceById[valueId];
  }

  const handleSelectValue = (value?: ResourceDropdownPreviewFragment) => {
    onChange(value);
    if (selectOnly) handleSearchInputChange("");
  };

  if (selectedResourceIDs) {
    resources = resources.filter(
      (resource) => !selectedResourceIDs.includes(resource.id)
    );
  }

  return (
    <Select
      options={resources}
      value={selectedResource}
      onChange={handleSelectValue}
      onInputChange={handleInputChange}
      clearable={clearable}
      disabled={disabled}
      getOptionLabel={(option) => option.name}
      getOptionSublabel={(option) => option.parentResource?.name ?? ""}
      getIcon={(resource) => {
        if (resource.iconUrl) {
          return {
            type: "src",
            icon: resource.iconUrl,
          };
        } else {
          return {
            type: "entity",
            entityType: resource.resourceType,
          };
        }
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      disableBuiltInFiltering // Search is already handled server-side
      autoFocus={autoFocus}
      placeholder={placeholder}
      selectOnly={selectOnly}
      size={size}
    />
  );
};

export default PaginatedResourceDropdown;
