import { Badge, Icon } from "components/ui";
import { IconName } from "components/ui/icon/Icon";
import React, { useEffect, useRef, useState } from "react";

import * as styles from "./Tabs.css";

interface TabInfo {
  title: string;
  onClick: () => void;
  isSelected: boolean;
  badgeCount?: number;
  icon?: IconName;
}

interface TabStyle {
  fillWidth: boolean;
}

const Tabs = ({
  tabInfos,
  tabStyle,
  round = false,
}: {
  tabInfos: TabInfo[];
  tabStyle?: TabStyle;
  round?: boolean;
}) => {
  if (round) {
    return <RoundTabs tabInfos={tabInfos} tabStyle={tabStyle} />;
  }
  return <SquareTabs tabInfos={tabInfos} tabStyle={tabStyle} />;
};

const SquareTabs = ({
  tabInfos,
  tabStyle,
}: {
  tabInfos: TabInfo[];
  tabStyle?: TabStyle;
}) => {
  return (
    <div className={styles.squareTabs}>
      {tabInfos.map((tabInfo) => (
        <div
          className={styles.squareTab({
            fillWidth: tabStyle?.fillWidth,
            selected: tabInfo.isSelected,
          })}
          key={tabInfo.title}
          onClick={() => {
            tabInfo.onClick();
          }}
        >
          {tabInfo.icon ? <Icon name={tabInfo.icon} size="xs" /> : null}
          <span>{tabInfo.title}</span>
          {tabInfo.badgeCount !== undefined && (
            <Badge
              count={tabInfo.badgeCount}
              color={tabInfo.isSelected ? "info" : "default"}
            />
          )}
        </div>
      ))}
      <div className={styles.filler}></div>
    </div>
  );
};

const RoundTabs = ({
  tabInfos,
  tabStyle,
}: {
  tabInfos: TabInfo[];
  tabStyle?: TabStyle;
}) => {
  const [gliderStyle, setGliderStyle] = useState<
    React.CSSProperties | undefined
  >();
  const activeTabRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const currentTab = activeTabRef.current;
    if (currentTab) {
      setGliderStyle({
        left: currentTab.offsetLeft,
        width: currentTab.clientWidth,
        transitionDuration: "300ms",
      });
    } else {
      setGliderStyle({ left: 0, width: 0 });
    }
  }, [tabInfos]);

  return (
    <div className={styles.roundTabs}>
      {tabInfos.map((tabInfo) => (
        <div
          className={styles.roundTab({
            fillWidth: tabStyle?.fillWidth,
            selected: tabInfo.isSelected,
          })}
          key={tabInfo.title}
          onClick={tabInfo.onClick}
          ref={tabInfo.isSelected ? activeTabRef : undefined}
        >
          {tabInfo.icon ? <Icon name={tabInfo.icon} size="xs" /> : null}
          <span>{tabInfo.title}</span>
          {tabInfo.badgeCount !== undefined && (
            <Badge
              count={tabInfo.badgeCount}
              color={tabInfo.isSelected ? "info" : "default"}
            />
          )}
        </div>
      ))}
      <div className={styles.glider} style={gliderStyle} />
    </div>
  );
};

export default Tabs;
