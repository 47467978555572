import axios from "axios";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import ColumnListItem from "components/column/ColumnListItem";
import { useToast } from "components/toast/Toast";
import { Divider } from "components/ui";
import moment from "moment";
import { useContext } from "react";
import { useHistory, useLocation } from "react-router";
import EventsRow from "views/events/viewer/rows/EventsRow";

const EventsColumn = () => {
  const history = useHistory();
  const location = useLocation();
  const { authState } = useContext(AuthContext);
  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();

  const organizationName = authState.user?.user.organization.name;

  const handleRequestExport = () => {
    const urlParams = new URLSearchParams(location.search);
    let endDate = urlParams.get("endDate");
    if (endDate) {
      endDate = moment(endDate, "YYYY-MM-DD")
        .add(1, "days")
        .format("YYYY-MM-DD");
      urlParams.delete("endDate");
      urlParams.append("endDate", endDate);
    }
    const queryString = urlParams.toString();

    displayLoadingToast("Generating export...");
    axios({
      url: "/export/events?" + queryString,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Opal_Events_" + moment().toISOString() + ".csv"
        );
        link.click();
        displaySuccessToast(`Success: downloaded events`);
      })
      .catch(() => {
        displayErrorToast(`Error: failed to generate export`);
      });
  };

  const menuOptions: PropsFor<typeof ColumnHeader>["menuOptions"] = [
    {
      label: "Export",
      onClick: handleRequestExport,
      icon: { type: "name", icon: "users-right" },
    },
  ];

  return (
    <>
      <Column>
        <ColumnHeader
          title="Events"
          subtitle={organizationName}
          icon={{ type: "name", icon: "events" }}
          menuOptions={menuOptions}
        />
        <Divider margin="md" />
        {authState.user?.isAdmin ? (
          <>
            <ColumnListItem
              key="saved-filters"
              label="Saved filters"
              icon={{ type: "name", icon: "filter" }}
              onClick={() => history.push("/events/filters" + location.search)}
            />
            <Divider margin="md" />
          </>
        ) : null}
        <EventsRow />
      </Column>
    </>
  );
};

export default EventsColumn;
