import { EntityType, EventFragment } from "api/generated/graphql";
import ColumnContent from "components/column/ColumnContent";
import UserLabel from "components/label/item_labels/UserLabel";
import { FormRow, FormSection } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import { getResourceUrlNew } from "utils/common";
import { SubEventsTable } from "views/events/viewer/SubEventsTable";

type Props = {
  event: EventFragment;
};

export const EventViewer = (props: Props) => {
  const { event } = props;

  const createdAt = moment(new Date(props.event.createdAt));

  let userAgent = event.actorUserAgent;
  let userIP = event.actorIP;

  // Don't display Slack IP addresses, which are just Slack's corporate servers
  if (userAgent !== undefined && userAgent?.startsWith("Slackbot")) {
    userAgent = "Slack";
    userIP = undefined;
  }
  // XXX: we should set this user agent in the CLI itself, but for now a nice UX improvement
  if (userAgent === "node-fetch/1.0 (+https://github.com/bitinn/node-fetch)") {
    userAgent = "Opal CLI";
  }

  return (
    <div
      className={sprinkles({
        padding: "md",
        display: "flex",
        flexDirection: "column",
        gap: "xl",
      })}
    >
      <FormSection title="Info">
        <FormRow title="Actor">
          <UserLabel
            url={getResourceUrlNew({
              entityId: event.actorUserId,
              entityType: EntityType.User,
            })}
            name={event.actorUser?.fullName}
            avatar={event.actorUser?.avatarUrl}
          />
        </FormRow>
        <FormRow title="Date">
          <>{`${createdAt.toISOString()} (${createdAt.fromNow()})`}</>
        </FormRow>
        {userIP && <FormRow title="IP Address">{userIP}</FormRow>}
        {userAgent && <FormRow title="User Agent">{userAgent}</FormRow>}
        {event.apiTokenLabel && event.apiTokenPreview && (
          <FormRow title="API Token">
            {event.apiTokenLabel} ({event.apiTokenPreview})
          </FormRow>
        )}
      </FormSection>
      {event.subEvents.length > 0 && (
        <FormSection title="Details">
          <ColumnContent>
            <SubEventsTable event={event} />
          </ColumnContent>
        </FormSection>
      )}
    </div>
  );
};

export default EventViewer;
