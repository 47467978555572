import {
  RequestTemplateCustomFieldFragment,
  RequestTemplateCustomFieldType,
} from "api/generated/graphql";
import clsx from "clsx";
import { FormGroup, Icon, Input, Select, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import * as React from "react";

import * as styles from "./RequestTemplateCustomFieldEdit.css";
import {
  CUSTOM_FIELD_DESCRIPTION_MAX_LEN,
  CUSTOM_FIELD_NAME_MAX_LEN,
  CUSTOM_FIELD_TYPE_OPTIONS,
  CustomFieldTypeLabels,
  DROPDOWN_OPTIONS_MAX_NUM,
} from "./utils";

interface Props {
  index: number;
  customField?: RequestTemplateCustomFieldFragment;
  onChange: (customField: RequestTemplateCustomFieldFragment) => void;
  onClose: () => void;
}

const RequestTemplateCustomFieldEdit: React.FC<Props> = (props) => {
  if (props.customField == null) {
    return null;
  }
  const { customField } = props;

  return (
    <div className={styles.container}>
      <div className={styles.closeButton}>
        <Icon name="x" onClick={props.onClose} />
      </div>
      <div
        className={sprinkles({
          fontWeight: "semibold",
          fontSize: "bodyMd",
          marginBottom: "lg",
        })}
      >
        Edit Custom Field
      </div>
      <FormGroup label="Type">
        <Select
          key={`custom-field-type-dropdown-${props.index}`}
          options={CUSTOM_FIELD_TYPE_OPTIONS}
          value={props.customField.type}
          onChange={(opt) => {
            const newField: RequestTemplateCustomFieldFragment = {
              type: opt ?? customField.type,
              name: customField.name,
              required: customField.required,
            };
            if (opt === RequestTemplateCustomFieldType.MultiChoice) {
              newField.metadata = {
                multiChoiceData: {
                  options: [],
                },
              };
            }
            props.onChange(newField);
          }}
          getOptionLabel={(opt) => CustomFieldTypeLabels[opt]}
        />
      </FormGroup>
      <FormGroup label="Name">
        <Input
          type="text"
          value={props.customField.name}
          onChange={(val) =>
            props.onChange({
              ...customField,
              name: val,
            })
          }
          maxLength={CUSTOM_FIELD_NAME_MAX_LEN}
        />
      </FormGroup>
      <FormGroup label="Description">
        <Input
          type="textarea"
          value={props.customField?.description ?? undefined}
          onChange={(val) =>
            props.onChange({
              ...customField,
              description: val,
            })
          }
          maxLength={CUSTOM_FIELD_DESCRIPTION_MAX_LEN}
        />
      </FormGroup>
      <div className={sprinkles({ marginBottom: "lg" })}>
        <Switch
          label="Required"
          checked={props.customField.required ?? false}
          onChange={(checked) =>
            props.onChange({ ...customField, required: checked })
          }
          infoTooltip={
            props.customField.type === RequestTemplateCustomFieldType.Boolean
              ? "If required, requester must check the box before requesting."
              : undefined
          }
        />
      </div>

      {props.customField.type === RequestTemplateCustomFieldType.MultiChoice ? (
        <MultiChoiceFieldEditor
          options={
            props.customField.metadata?.multiChoiceData?.options?.map(
              ({ value }) => value
            ) ?? []
          }
          onChange={(opts) =>
            props.onChange({
              ...customField,
              metadata: {
                ...customField.metadata,
                multiChoiceData: {
                  options: opts.map((val) => ({ label: val, value: val })),
                },
              },
            })
          }
        />
      ) : null}
    </div>
  );
};

export const MultiChoiceFieldEditor = ({
  options,
  onChange,
}: {
  options: string[];
  onChange: (options: string[]) => void;
}) => {
  return (
    <FormGroup label="Options">
      <div className={styles.multiChoiceOptionsContainer}>
        {options.map((opt, i) => (
          <div className={styles.multiChoiceOptionRow}>
            <Input
              type="text"
              value={opt}
              onChange={(val) =>
                onChange(
                  options.map((prevOpt, prevI) => (prevI === i ? val : prevOpt))
                )
              }
              maxLength={DROPDOWN_OPTIONS_MAX_NUM}
            />
            <div className={sprinkles({ marginLeft: "sm" })}>
              <Icon
                name="x"
                size="sm"
                onClick={() =>
                  onChange([...options.slice(0, i), ...options.slice(i + 1)])
                }
              />
            </div>
          </div>
        ))}
      </div>
      <div
        className={clsx(styles.addFieldButton, {
          [styles.addButtonDisabled]:
            options.length >= DROPDOWN_OPTIONS_MAX_NUM,
        })}
        onClick={() => {
          if (options.length < DROPDOWN_OPTIONS_MAX_NUM) {
            onChange([...options, ""]);
          }
        }}
      >
        Add
      </div>
    </FormGroup>
  );
};

export default RequestTemplateCustomFieldEdit;
