import * as styles from "./TableSimple.css";

export interface Header<Data> {
  id: keyof Data & string;
  label: string;
  cellRenderer?: (data: Data) => React.ReactElement;
}

interface Props<Data> {
  columns: Header<Data>[];
  rows: Data[];
  getRowId: (data: Data) => string;
}

const TableSimple = <Data,>(props: Props<Data>) => {
  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.tableHeader}>
          {props.columns.map((col) => {
            return <th key={col.id}>{col.label}</th>;
          })}
        </tr>
      </thead>
      <tbody>
        {props.rows.map((row) => {
          return (
            <tr key={props.getRowId(row)} className={styles.tableRow}>
              {props.columns.map((col) => {
                return (
                  <td key={`${props.getRowId(row)}-${col.id}`}>
                    {col.cellRenderer ? col.cellRenderer(row) : row[col.id]}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </table>
  );
};

export default TableSimple;
