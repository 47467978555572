import {
  EntityType,
  PropagationStatusCode,
  PropagationTaskType,
  RequestFragment,
  RequestStatus,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { PropagationStatusInfo } from "components/propagation/PropagationStatusModal";
import { Banner } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import pluralize from "pluralize";
import { useContext } from "react";
import { getResourceUrlNew } from "utils/common";
import { REQUEST_TEMPLATE_ID_PARAM_KEY } from "views/requests/RequestTemplateParser";
import { RequestTicketPropagationBanner } from "views/requests/RequestTicketPropagationBanner";

export type RequestBannerProps = {
  request: RequestFragment;
  propagationStatusCode?: PropagationStatusCode;
  propagationTaskType?: PropagationTaskType;
  propagationStatusInfos?: PropagationStatusInfo[];
  setShowPropagationStatusModal: (show: boolean) => void;
};

export const RequestBanner = (props: RequestBannerProps) => {
  const { authState } = useContext(AuthContext);

  if (
    props.propagationStatusCode ===
    PropagationStatusCode.PendingManualPropagation
  ) {
    const propagationTickets =
      props.propagationStatusInfos?.flatMap(
        (propagationStatusInfo) =>
          propagationStatusInfo.propagationTickets ?? []
      ) ?? [];
    return (
      <RequestTicketPropagationBanner
        request={props.request}
        propagationTickets={propagationTickets}
        propagationTaskType={props.propagationTaskType}
      />
    );
  }

  // TODO: Support banners for requests with multiple requested items
  if (
    props.request.requestedResources.length +
      props.request.requestedGroups.length !==
    1
  ) {
    return null;
  }

  const expirationDuration = moment.duration(
    props.request.durationInMinutes,
    "minutes"
  );

  const requestLastUpdatedDate = moment(new Date(props.request.updatedAt));
  const expirationDurationHasElapsed =
    requestLastUpdatedDate.add(expirationDuration) < moment(new Date());

  const resubmitDuration = moment.duration(8, "days");
  const resubmitDurationHasElapsed =
    requestLastUpdatedDate.add(resubmitDuration) < moment(new Date());

  let requesteeType = EntityType.Resource;
  let requesteeId = "";
  let requesteeLabel = "resource";

  if (props.request.requestedResources.length === 1) {
    requesteeId = props.request.requestedResources[0].resourceId;
  } else {
    requesteeId = props.request.requestedGroups[0].groupId;
    requesteeType = EntityType.Group;
    requesteeLabel = "group";
  }

  const resourceUrl = getResourceUrlNew({
    entityId: requesteeId,
    entityType: requesteeType,
  });
  const reRequestUrl = `${resourceUrl}/request?${REQUEST_TEMPLATE_ID_PARAM_KEY}=${props.request.id}`;

  let bannerContent;
  let bannerType: PropsFor<typeof Banner>["type"] = "info";

  if (props.request.reviewersError) {
    bannerType = "error";
    bannerContent = props.request.reviewersError;
  } else if (
    !expirationDurationHasElapsed &&
    props.request.targetUserId === authState.user?.user.id &&
    props.request.status === RequestStatus.Approved
  ) {
    if (props.propagationStatusCode === undefined) {
      return null;
    } else if (props.propagationStatusCode === PropagationStatusCode.Success) {
      bannerType = "success";
      bannerContent = (
        <>
          Congrats, you've been granted access to this {requesteeLabel}! Access
          it <Banner.Link label="here" to={resourceUrl} />.
        </>
      );
    } else if (props.propagationStatusCode === PropagationStatusCode.Pending) {
      bannerContent = "Propagating...";
    } else {
      bannerType = "error";
      bannerContent = (
        <>
          There was an error propagating access. See{" "}
          <Banner.Link
            label="here"
            onClick={() => {
              props.setShowPropagationStatusModal(true);
            }}
          />{" "}
          for more details.
        </>
      );
    }
  } else if (
    !resubmitDurationHasElapsed &&
    props.request.status === RequestStatus.Denied
  ) {
    bannerType = "error";
    bannerContent =
      authState.user?.user.id === props.request.requesterId ? (
        <>
          Your access request was denied. If necessary, you can resubmit a
          request using <Banner.Link label="this template" to={reRequestUrl} />.
        </>
      ) : (
        <>This access request was denied.</>
      );
  } else if (props.request.status === RequestStatus.Canceled) {
    bannerType = "info";
    bannerContent =
      authState.user?.user.id === props.request.requesterId ? (
        <>
          Your access request was canceled. If necessary, you can resubmit a
          request using <Banner.Link label="this template" to={reRequestUrl} />.
        </>
      ) : (
        <>This access request was canceled.</>
      );
  } else if (
    expirationDurationHasElapsed &&
    !resubmitDurationHasElapsed &&
    props.request.durationInMinutes &&
    props.request.status === RequestStatus.Approved
  ) {
    bannerContent = (
      <>
        Your access grant expired. If you need to renew access, you can resubmit
        a request using <Banner.Link label="this template" to={reRequestUrl} />.
      </>
    );
  }

  if (!bannerContent || !bannerType) {
    return null;
  }

  return (
    <div style={{ marginBottom: "16px", width: "800px" }}>
      <Banner message={bannerContent} type={bannerType} />
    </div>
  );
};

// Eventually merge with the RequestBanner component above once we support
// multiple requested items at once
export const RequestGroupBindingBanner = ({
  request,
}: {
  request: RequestFragment;
}) => {
  const groupsInBinding = [];
  for (const requestedGroup of request.requestedGroups) {
    const group = requestedGroup.group;
    // We only want to show the banner if the group is in a binding at the time of the request (or after)
    if (
      group?.groupBinding &&
      request.createdAt > group.groupBinding?.createdAt
    ) {
      groupsInBinding.push(group);
    }
  }
  if (groupsInBinding.length === 0) {
    return null;
  }
  return (
    <div
      style={{ width: "800px" }}
      className={sprinkles({
        marginBottom: "md",
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: "md",
      })}
    >
      {groupsInBinding.map((group) => {
        const groupNames = group.groupBinding?.groups
          .filter((g) => g.id !== group.id)
          .map((g) => g.name)
          .join(", ");
        return (
          <Banner
            message={
              <>
                The {group.name} requested below is a linked group, approving
                access to this group will also grant access to the following{" "}
                {pluralize("group", groupNames?.length)}: {groupNames} once the
                linked groups are synced.
              </>
            }
            type={"info"}
          />
        );
      })}
    </div>
  );
};
