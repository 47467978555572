import { ColumnContainer } from "components/column/Column";
import { Route, Switch } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import EventDetailColumn from "views/events/EventDetailColumn";
import EventFilterDetailColumn from "views/events/EventFilterDetailColumn";
import EventFiltersColumn from "views/events/EventFiltersColumn";

import EventFilterBar from "./EventFilterBar";
import EventsColumn from "./EventsColumn";
import EventsColumnV3 from "./EventsColumnV3";

const EventsView = () => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  if (hasV3) {
    return (
      <>
        <Switch>
          <Route
            exact
            path={[
              `/events/filters`,
              `/events/filters/:eventFilterId`,
              `/events/q/:eventFilterId`,
            ]}
            component={EventFiltersColumn}
          />
          <Route
            exact
            path={[`/events`, `/events/:eventId`]}
            component={EventsColumnV3}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path={[
              `/events/filters`,
              `/events/filters/:eventFilterId`,
              `/events/q/:eventFilterId`,
            ]}
            component={EventFilterDetailColumn}
          />
        </Switch>
      </>
    );
  }

  return (
    <>
      <EventFilterBar />
      <ColumnContainer>
        <Switch>
          <Route
            exact
            path={[
              `/events/filters`,
              `/events/filters/:eventFilterId`,
              `/events/q/:eventFilterId`,
            ]}
            component={EventFiltersColumn}
          />
          <Route
            exact
            path={[`/events`, `/events/:eventId`]}
            component={EventsColumn}
          />
        </Switch>
        <Switch>
          <Route
            exact
            path={[
              `/events/filters`,
              `/events/filters/:eventFilterId`,
              `/events/q/:eventFilterId`,
            ]}
            component={EventFilterDetailColumn}
          />
          <Route
            exact
            path={[`/events/:eventId`]}
            component={EventDetailColumn}
          />
        </Switch>
      </ColumnContainer>
    </>
  );
};

export default EventsView;
