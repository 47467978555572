import { TimeZone } from "@vvo/tzdb";
import NotificationPeriod from "components/data_elements/NotificationPeriod";
import {
  Banner,
  Button,
  Checkbox,
  DataElement,
  DataElementList,
  FormGroup,
  Input,
  Modal,
  Select,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useState } from "react";
import { getTimeLabel, getTimesEveryHalfHour } from "utils/time";

export const DEFAULT_REMINDER_SCHEDULE: number[] = [7, 3, 1, 0];
// Default time is 10:00 AM, UTC -- since we only need the time component, express everything in UTC
export const DEFAULT_REMINDER_TIME_OF_DAY: Date = new Date(
  Date.UTC(1970, 1, 1, 10)
);

interface Props {
  reminderSchedule: number[];
  reminderTimeOfDay: Date;
  reminderIncludeManager: boolean;
  onChange?: (
    reminderSchedule: number[],
    reminderTime: Date,
    reminderIncludeManager: boolean
  ) => void;
  timezone?: TimeZone;
}

const ReminderNotifications: React.FC<Props> = (props) => {
  const [showModal, setShowModal] = useState(false);

  const [daysBeforeInput, setDaysBeforeInput] = useState<number | undefined>();
  const [modalReminderSchedule, setModalReminderSchedule] = useState<number[]>(
    []
  );
  const [modalReminderTime, setModalReminderTime] = useState<Date>(new Date());
  const [
    modalReminderIncludeManager,
    setModalReminderIncludeManager,
  ] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
    setModalReminderSchedule([...props.reminderSchedule]);
    setModalReminderTime(props.reminderTimeOfDay);
    setModalReminderIncludeManager(props.reminderIncludeManager);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    if (!props.onChange) return;
    props.onChange(
      modalReminderSchedule,
      modalReminderTime,
      modalReminderIncludeManager
    );
    setShowModal(false);
  };

  const getNumDaysBeforeLabel = (numDaysBefore: number) => {
    if (numDaysBefore === 0) {
      return "Day of";
    } else if (numDaysBefore === 1) {
      return "1 day before";
    } else {
      return `${numDaysBefore} days before`;
    }
  };

  const timeOfDayOptions = getTimesEveryHalfHour();
  const currentReminderTime = getTimeLabel(props.reminderTimeOfDay);
  const includeManagerText = props.reminderIncludeManager
    ? " and reviewers' managers"
    : "";
  const summaryText = (
    <div className={sprinkles({ marginBottom: "sm" })}>
      Reminder notifications will be sent to all reviewers
      <span className={sprinkles({ fontWeight: "medium" })}>
        {includeManagerText}
      </span>{" "}
      with incomplete access reviews according to the following schedule at{" "}
      <span
        className={sprinkles({ fontWeight: "medium" })}
      >{`${currentReminderTime} ${props.timezone?.abbreviation}`}</span>
      .
    </div>
  );

  if (!props.onChange) {
    return (
      <FormGroup label="Access Review Reminders">
        {summaryText}
        <DataElementList>
          {props.reminderSchedule.map((numDaysBefore) => (
            <NotificationPeriod
              key={numDaysBefore}
              label={getNumDaysBeforeLabel(numDaysBefore)}
            />
          ))}
        </DataElementList>
      </FormGroup>
    );
  }

  return (
    <>
      <FormGroup label="Access Review Reminders">
        {summaryText}
        <DataElementList>
          {props.reminderSchedule.map((numDaysBefore) => (
            <NotificationPeriod
              key={numDaysBefore}
              label={getNumDaysBeforeLabel(numDaysBefore)}
              handleDelete={() => {
                if (props.onChange) {
                  props.onChange(
                    props.reminderSchedule.filter((n) => n !== numDaysBefore),
                    props.reminderTimeOfDay,
                    props.reminderIncludeManager
                  );
                }
              }}
            />
          ))}
          <DataElement
            label="Add reminder"
            color="blue"
            leftIcon={{
              name: "plus",
            }}
            onClick={handleOpenModal}
          />
        </DataElementList>
      </FormGroup>
      <Modal
        isOpen={showModal}
        onClose={handleCancel}
        title="Access Review Reminders"
      >
        <Modal.Body>
          <p>
            Reminder notifications will be sent to all reviewers with incomplete
            access reviews according to the following schedule:
          </p>
          {modalReminderSchedule.length === 0 && (
            <Banner
              message="No reminders are currently scheduled. If none are configured, no reminders will be sent."
              type="warning"
              marginBottom="md"
            />
          )}
          <div className={sprinkles({ marginBottom: "md" })}>
            <FormGroup label="Add reminder">
              <div className={sprinkles({ display: "flex", gap: "sm" })}>
                <Input
                  type="number"
                  value={daysBeforeInput}
                  onChange={setDaysBeforeInput}
                  rightText="days before deadline"
                  min={0}
                  autoFocus
                />
                <Button
                  type="primary"
                  borderless
                  leftIconName="plus"
                  size="lg"
                  onClick={() => {
                    if (daysBeforeInput !== undefined) {
                      setModalReminderSchedule([
                        ...modalReminderSchedule,
                        daysBeforeInput,
                      ]);
                      setDaysBeforeInput(undefined);
                    }
                  }}
                  disabled={
                    daysBeforeInput === undefined ||
                    modalReminderSchedule.includes(daysBeforeInput)
                  }
                />
              </div>
            </FormGroup>
          </div>
          <div className={sprinkles({ marginBottom: "md" })}>
            <DataElementList>
              {modalReminderSchedule.map((numDaysBefore) => (
                <NotificationPeriod
                  key={numDaysBefore}
                  label={getNumDaysBeforeLabel(numDaysBefore)}
                  handleDelete={() => {
                    setModalReminderSchedule(
                      modalReminderSchedule.filter((n) => n !== numDaysBefore)
                    );
                  }}
                />
              ))}
            </DataElementList>
          </div>
          <FormGroup
            label={`Notification time of day (${props.timezone?.name})`}
          >
            <Select
              onChange={(value) => {
                if (value) {
                  setModalReminderTime(value.value);
                }
              }}
              getOptionLabel={(o) => o.label}
              value={timeOfDayOptions.find(
                (o) => o.value.valueOf() === modalReminderTime.valueOf()
              )}
              options={timeOfDayOptions}
            />
          </FormGroup>
          <Checkbox
            checked={modalReminderIncludeManager}
            onChange={(checked) => setModalReminderIncludeManager(checked)}
            label="Also send notification to reviewer's manager"
          />
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Save"
          onPrimaryButtonClick={handleSave}
          secondaryButtonLabel="Cancel"
          onSecondaryButtonClick={handleCancel}
        />
      </Modal>
    </>
  );
};

export default ReminderNotifications;
