import {
  Maybe,
  ThirdPartyProvider,
  useDeleteThirdPartyIntegrationMutation,
} from "api/generated/graphql";
import { IntegrationInfo } from "components/integrations/OrgIntegrationApp";
import ConfirmModal from "components/modals/update/ConfirmModal";
import { useToast } from "components/toast/Toast";
import { Button } from "components/ui";
import { useState } from "react";
import { logError } from "utils/logging";

type UserIntegrationButtonProps = {
  integrationInfo: IntegrationInfo;
  isConnected: boolean;
  setOpenThirdPartyProvider: (integration: Maybe<ThirdPartyProvider>) => void;
  onConnectClick?: () => void;
  buttonDisabled: boolean;
};

export const UserIntegrationButton = (props: UserIntegrationButtonProps) => {
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [
    deleteUserIntegration,
    { loading },
  ] = useDeleteThirdPartyIntegrationMutation();

  const modalReset = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  const confirmModal = (
    <ConfirmModal
      title={`Disconnect ${props.integrationInfo.name} connection`}
      message={`Are you sure you want to disconnect your ${props.integrationInfo.name} connection?`}
      isModalOpen={showModal}
      onClose={() => {
        modalReset();
      }}
      onSubmit={async () => {
        try {
          const { data } = await deleteUserIntegration({
            variables: {
              input: {
                thirdPartyProvider: props.integrationInfo.thirdPartyProvider,
              },
            },
            update: (cache, { data }) => {
              switch (data?.deleteThirdPartyIntegration.__typename) {
                case "DeleteThirdPartyIntegrationResult":
                  cache.evict({
                    id: cache.identify(
                      data?.deleteThirdPartyIntegration.thirdPartyIntegration
                    ),
                  });
                  break;
              }
            },
          });
          switch (data?.deleteThirdPartyIntegration.__typename) {
            case "DeleteThirdPartyIntegrationResult":
              modalReset();
              displaySuccessToast(
                `${props.integrationInfo.name} connection disconnected`
              );
              break;
            default:
              logError(
                new Error(
                  `failed to disconnect ${props.integrationInfo.name} integration`
                )
              );
              setErrorMessage(
                `Error: failed to disconnect ${props.integrationInfo.name} integration`
              );
          }
        } catch (error) {
          logError(
            error,
            `failed to disconnect ${props.integrationInfo.name} integration`
          );
          setErrorMessage(
            `Error: failed to disconnect ${props.integrationInfo.name} integration`
          );
        }
      }}
      loading={loading}
      errorMessage={errorMessage}
      submitLabel={"Disconnect"}
    />
  );

  return (
    <div>
      {props.isConnected ? (
        <Button
          type="error"
          leftIconName="x"
          label="Disconnect"
          onClick={() => {
            setShowModal(true);
          }}
          disabled={loading || props.buttonDisabled}
          outline
        />
      ) : (
        <Button
          type="success"
          leftIconName="plus"
          label="Connect"
          onClick={() => {
            props.setOpenThirdPartyProvider(
              props.integrationInfo.thirdPartyProvider
            );
            props.onConnectClick && props.onConnectClick();
          }}
          disabled={loading || props.buttonDisabled}
          outline
        />
      )}
      {confirmModal}
    </div>
  );
};
