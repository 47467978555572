import { Maybe, ResourcePreviewLargeFragment } from "api/generated/graphql";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { ResourceLabel } from "components/label/Label";
import { SectionEntry } from "components/viewer/Viewer";
import React from "react";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";

export const RESOURCE_CONNECTION_TITLE = "App";

type ResourceConnectionRowProps = {
  resource?: Maybe<ResourcePreviewLargeFragment>;
};

export const ResourceConnectionRow = (props: ResourceConnectionRowProps) => {
  return (
    <SectionEntry title={RESOURCE_CONNECTION_TITLE}>
      <ResourceLabel
        text={props.resource?.connection?.name}
        entityType={EntityTypeDeprecated.Connection}
        entityId={props.resource?.connectionId}
        icon={
          getConnectionTypeInfo(props.resource?.connection?.connectionType)
            ?.icon
        }
      />
    </SectionEntry>
  );
};

export default ResourceConnectionRow;
