import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AuthType,
  ConnectionType,
  Maybe,
  useCreateConnectionMutation,
  Visibility,
} from "api/generated/graphql";
import duoLogo from "assets/logos/duo-logo.svg";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { Button, FormGroup, Input } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import { validateHostname } from "views/connections/create/common";
import {
  CreateConnectionComponents as CreateConnectionComponentsV2,
  CreateConnectionView as CreateConnectionViewV2,
} from "views/connections/create/CreateConnectionComponents";
import {
  CreateConnectionComponentsV3,
  CreateConnectionViewV3,
} from "views/connections/create/CreateConnectionComponentsV3";
import VisibilitySelector from "views/visibility/VisibilitySelector";

const CreateDuo = () => {
  return <CreateDuoForm />;
};

const CreateDuoForm = () => {
  const history = useHistory();
  const hasAppLevelVisibility = useFeatureFlag(FeatureFlag.AppLevelVisibility);
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const logEvent = useLogEvent();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [visibility, setVisibility] = useState<Visibility>(Visibility.Global);
  const [visibilityGroupIds, setVisibilityGroupIds] = useState<
    string[] | undefined
  >([]);
  const [apiHostname, setApiHostname] = useState("");
  const [integrationKey, setIntegrationKey] = useState("");
  const [secretKey, setSecretKey] = useState("");

  const [adminOwnerId, setAdminOwnerId] = useState<string | undefined>(
    undefined
  );

  const [createConnectionMutation, { loading }] = useCreateConnectionMutation({
    refetchQueries: ["AppsListColumn", "Connections"],
  });

  const fieldUnset =
    name === "" ||
    description === "" ||
    !adminOwnerId ||
    integrationKey === "" ||
    secretKey === "" ||
    apiHostname === "";

  const onSubmit = async () => {
    const hostnameErrorMessage = validateHostname(apiHostname, "API hostname");
    if (hostnameErrorMessage) {
      setErrorMessage(`Error: ${hostnameErrorMessage}`);
      return;
    }
    try {
      const { data } = await createConnectionMutation({
        variables: {
          input: {
            name: name,
            description: description,
            connectionType: ConnectionType.Duo,
            adminOwnerId: adminOwnerId ?? "",
            visibility: visibility,
            visibilityGroupIds: visibilityGroupIds ?? [],
            importVisibility: Visibility.Global,
            metadata: {
              connectionType: ConnectionType.Duo,
              duo: {
                apiHostname: apiHostname,
              },
            },
            credentials: {
              authType: AuthType.Duo,
              duo: {
                integrationKey: integrationKey,
                secretKey: secretKey,
              },
            },
          },
        },
      });
      switch (data?.createConnection.__typename) {
        case "CreateConnectionResult":
          displaySuccessToast(`Success: Duo app created`);
          history.replace(
            getResourceUrl(
              EntityTypeDeprecated.Connection,
              data.createConnection.connection.id
            )
          );
          logEvent({
            name: "apps_create_click",
            properties: {
              connectionType: ConnectionType.Duo,
            },
          });

          break;
        case "ConnectionExistsError":
        case "ConnectionBadMetadataError":
        case "UserFacingError":
          logWarning(new Error(data.createConnection.message));
          setErrorMessage(data.createConnection.message);
          break;
        default:
          logError(new Error(`failed to create Duo connection`));
          setErrorMessage(`Error: failed to create Duo connection`);
      }
    } catch (error) {
      logError(error, "failed to create Duo connection");
      setErrorMessage(
        getModifiedErrorMessage("Error: failed to create Duo connection", error)
      );
    }
  };

  const CreateConnectionComponents = hasV3
    ? CreateConnectionComponentsV3
    : CreateConnectionComponentsV2;
  const CreateConnectionView = hasV3
    ? CreateConnectionViewV3
    : CreateConnectionViewV2;

  return (
    <CreateConnectionView
      logo={duoLogo}
      title={"Add your Duo organization"}
      onSubmit={onSubmit}
      submitDisabled={fieldUnset}
      submitLoading={loading}
    >
      <>
        <CreateConnectionComponents
          title={"Step 1"}
          subtitle={"Create Admin API credentials for Opal"}
        >
          <>
            <p>
              {`Opal requires Admin API credentials in order to manage your Duo Groups on your behalf.`}
            </p>
            <p>
              {`To learn more about setting up Admin API credentials,`}{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={"https://duo.com/docs/adminapi#first-steps"}
              >
                check out the official Duo documentation here
              </a>
              .
            </p>
            <p>
              {`Once the Admin API is created, the following permissions will need to be checked for Opal to successfully manage this Duo account.
              Admin API permissions can be found in the Duo Admin Portal under Applications > Admin API > Permissions.`}
            </p>
            <ul>
              <li>Grant read information</li>
              <li>Grant read log</li>
              <li>Grant read resource</li>
              <li>Grant write resource</li>
            </ul>
          </>
        </CreateConnectionComponents>
        <CreateConnectionComponents
          title={"Step 2"}
          subtitle={"Upload your Admin API credentials"}
          isLast
        >
          <>
            <p>
              {`Once the Admin API credentials are created, please input the following
               information about the connection:`}
            </p>
            <FormGroup label="App name:">
              <Input
                onChange={setName}
                placeholder="Identifiable name for the Duo app."
                value={name}
              />
            </FormGroup>
            <FormGroup label="App admin:">
              <OwnerDropdown
                selectedOwnerId={adminOwnerId}
                onSelectOwner={(owner) => setAdminOwnerId(owner?.id)}
                placeholder="Select an owner to own this app."
              />
            </FormGroup>
            <FormGroup label="Description:">
              <Input
                onChange={setDescription}
                placeholder="A brief description of the account to further inform people requesting access to it."
                value={description}
              />
            </FormGroup>
            {hasAppLevelVisibility && (
              <FormGroup label="Visibility:">
                <VisibilitySelector
                  visibility={visibility}
                  onChangeVisibility={(vis) => {
                    if (vis == Visibility.Global) {
                      setVisibility(Visibility.Global);
                      setVisibilityGroupIds([]);
                    } else {
                      setVisibility(Visibility.Team);
                    }
                  }}
                  visibilityGroups={visibilityGroupIds ?? []}
                  onChangeVisibilityGroups={setVisibilityGroupIds}
                />
              </FormGroup>
            )}
            <FormGroup label="API hostname:">
              <Input
                onChange={setApiHostname}
                placeholder='The Duo API hostname (e.g. "api-12345678.duosecurity.com").'
                value={apiHostname}
              />
            </FormGroup>
            <FormGroup label="Integration key:">
              <Input
                onChange={setIntegrationKey}
                placeholder="The integration key (iKey)."
                value={integrationKey}
                type="password"
              />
            </FormGroup>
            <FormGroup label="Secret key:">
              <Input
                onChange={setSecretKey}
                placeholder="The secret key (sKey)."
                value={secretKey}
                type="password"
              />
            </FormGroup>
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
            {!hasV3 && (
              <div
                className={sprinkles({
                  display: "flex",
                  justifyContent: "flex-end",
                })}
              >
                <Button
                  type="primary"
                  disabled={fieldUnset}
                  label={"Create"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </div>
            )}
          </>
        </CreateConnectionComponents>
      </>
    </CreateConnectionView>
  );
};

export default CreateDuo;
