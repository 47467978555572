import {
  Maybe,
  useCreateAuthProviderApplicationMutation,
} from "api/generated/graphql";
import AuthContext, {
  AuthContextActionType,
} from "components/auth/AuthContext";
import { CustomInputDark } from "components/mui/MUIOverrides";
import { Button } from "components/ui";
import React, { useContext, useState } from "react";
import * as Icons from "react-feather";
import {
  ErrorMessage,
  Footer,
  GetEnterHandler,
  Header,
  InputIcon,
} from "views/onboarding/Common";
import { OrgCreateInfo } from "views/onboarding/Onboarding";
import onboardingStyles from "views/onboarding/Onboarding.module.scss";

type OrganizationFormProps = {
  orgCreateInfo: OrgCreateInfo;
  setOrgCreateInfo: (orgCreateInfo: OrgCreateInfo) => void;
  stepNumber: number;
  setStepNumber: (step: number) => void;
  totalSteps: number;
  progress: number;
  defaultValue?: string;
};

export const OrganizationForm = (props: OrganizationFormProps) => {
  const { authState, authDispatch } = useContext(AuthContext);

  const [inputErrorMessage, setInputErrorMessage] = useState<Maybe<string>>(
    null
  );

  const [
    createAuthProviderApplication,
    { loading },
  ] = useCreateAuthProviderApplicationMutation();

  const onSubmit = async () => {
    setInputErrorMessage(null);

    if (authState.authClient) {
      props.setStepNumber(props.stepNumber + 1);
    } else {
      try {
        const { data } = await createAuthProviderApplication({
          variables: {
            input: {
              organizationName: props.orgCreateInfo.orgName.trim(),
              hostname: window.location.origin,
            },
          },
        });
        switch (data?.createAuthProviderApplication.__typename) {
          case "CreateAuthProviderApplicationResult": {
            const providerInfo =
              data.createAuthProviderApplication.providerInfo;
            authDispatch({
              type: AuthContextActionType.Auth0Config,
              payload: {
                auth0Config: {
                  domain: providerInfo.domain,
                  audience: providerInfo.audience,
                  clientId: providerInfo.clientId,
                  redirectUri: providerInfo.redirectUri,
                  logoutUri: providerInfo.logoutUri,
                },
              },
            });
            props.setStepNumber(props.stepNumber + 1);
            break;
          }
          case "HttpsHostnameRequiredError":
            setInputErrorMessage(data.createAuthProviderApplication.message);
            break;
          default:
            setInputErrorMessage(
              "Error: failed to create auth provider application."
            );
        }
      } catch (e) {
        setInputErrorMessage(
          "Error: failed to create auth provider application."
        );
      }
    }
  };

  return (
    <div className={onboardingStyles.content}>
      <Header progress={props.progress} />

      <div className={onboardingStyles.titleHeader}>
        <div className={onboardingStyles.title}>Your organization name</div>
        <div className={onboardingStyles.subtitle}>
          Enter the name of your organization
        </div>
      </div>
      <div className={onboardingStyles.midSection}>
        <CustomInputDark
          value={props.orgCreateInfo.orgName}
          onChange={(e) => {
            props.setOrgCreateInfo({
              ...props.orgCreateInfo,
              orgName: e.currentTarget.value,
            });
          }}
          startAdornment={
            <InputIcon
              inputIcon={<Icons.Briefcase strokeWidth={2} />}
              disabled={props.orgCreateInfo.orgName.trim() === ""}
              error={inputErrorMessage !== null}
            />
          }
          error={inputErrorMessage !== null}
          placeholder={`e.g. "Acme Corp"`}
          onKeyDown={GetEnterHandler({ callback: onSubmit })}
        />
      </div>
      {inputErrorMessage && <ErrorMessage errorMessage={inputErrorMessage} />}
      <div className={onboardingStyles.continueButton}>
        <div className={onboardingStyles.buttonDescription}>
          Press enter or click
        </div>
        <Button
          leftIconName="arrow-right"
          label="Continue"
          onClick={onSubmit}
          disabled={props.orgCreateInfo.orgName.trim() === ""}
          loading={loading}
        />
      </div>

      <Footer step={props.stepNumber} totalSteps={props.totalSteps} />
    </div>
  );
};

export default OrganizationForm;
