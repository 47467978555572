import { useClosedRequestMetricsQuery } from "api/generated/graphql";

import PieChart, { PieChartLegend } from "../graphs/PieChart";
import { useMetricsQueryInput } from "../MetricsDashboardContext";
import * as styles from "./Metrics.css";
import MetricsGraphCard from "./MetricsGraphCard";

type ClosedRequestType =
  | "Manual approval"
  | "Admin override"
  | "Auto approval"
  | "Canceled"
  | "Denied";

const COLORS: Record<ClosedRequestType, string> = {
  "Auto approval": "#0C3758",
  "Manual approval": "#186DB1",
  "Admin override": "#359FF4",
  Denied: "#86C6F8",
  Canceled: "#D7ECFD",
};

const ClosedRequestMetrics = () => {
  const input = useMetricsQueryInput();

  const { data, loading, error } = useClosedRequestMetricsQuery({
    variables: {
      input,
    },
  });

  const sections = [
    {
      value: data?.closedRequestMetrics.numAutoApproved ?? 0,
      label: "Auto approval",
      color: COLORS["Auto approval"],
    },
    {
      value: data?.closedRequestMetrics.numManualApproved ?? 0,
      label: "Manual approval",
      color: COLORS["Manual approval"],
    },
    {
      value: data?.closedRequestMetrics.numAdminApproved ?? 0,
      label: "Admin override",
      color: COLORS["Admin override"],
    },
    {
      value: data?.closedRequestMetrics.numDenied ?? 0,
      label: "Denied",
      color: COLORS["Denied"],
    },
    {
      value: data?.closedRequestMetrics.numCanceled ?? 0,
      label: "Canceled",
      color: COLORS["Canceled"],
    },
  ];
  const noData = sections.every((section) => section.value === 0);

  return (
    <MetricsGraphCard
      title="Closed requests"
      subtitle="What methods are being used to resolve requests?"
      loading={loading}
      error={Boolean(error)}
      noData={noData}
    >
      <div className={styles.pieChart}>
        <PieChart sections={sections} />
      </div>
      <div className={styles.graphLegend}>
        <PieChartLegend sections={sections} />
      </div>
    </MetricsGraphCard>
  );
};

export default ClosedRequestMetrics;
