import pluralize from "pluralize";

import {
  GroupOnCallSchedulePreviewSmallFragment,
  GroupType,
  OnCallSchedule,
  ThirdPartyProvider,
} from "../../../api/generated/graphql";
import sprinkles from "../../../css/sprinkles.css";
import { getGroupTypeInfo } from "../../label/GroupTypeLabel";
import GroupLabel from "../../label/item_labels/GroupLabel";
import OnCallScheduleLabel from "../../label/OnCallScheduleLabel";
import { Divider, Modal } from "../../ui";
import * as styles from "./ThirdPartyProviderOnCallSchedulesModal.css";

export type ThirdPartyProviderOnCallSchedulesModalProps = {
  isOpen: boolean;
  onClose: () => void;
  groupOnCallSchedules?: GroupOnCallSchedulePreviewSmallFragment[];
  thirdPartyProvider: ThirdPartyProvider;
};

export const ThirdPartyProviderOnCallSchedulesModal = (
  props: ThirdPartyProviderOnCallSchedulesModalProps
) => {
  let onCallSchedulesByRemoteId: Record<string, OnCallSchedule> = {};
  let groupsByScheduleRemoteId: Record<
    string,
    { id: string; name: string; groupType: GroupType }[]
  > = {};
  for (const groupOnCallSchedule of props.groupOnCallSchedules || []) {
    const onCallSchedule = groupOnCallSchedule.onCallSchedule;
    if (!onCallSchedulesByRemoteId[onCallSchedule.remoteId]) {
      onCallSchedulesByRemoteId[onCallSchedule.remoteId] = onCallSchedule;
    }
    if (!groupOnCallSchedule.group) {
      continue;
    }
    if (groupsByScheduleRemoteId[onCallSchedule.remoteId]) {
      groupsByScheduleRemoteId[onCallSchedule.remoteId].push(
        groupOnCallSchedule.group
      );
    } else {
      groupsByScheduleRemoteId[onCallSchedule.remoteId] = [
        groupOnCallSchedule.group,
      ];
    }
  }

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={"On Call Schedules"}
    >
      <Modal.Body>
        <p>
          You have{" "}
          {pluralize(
            "on-call schedule",
            Object.entries(onCallSchedulesByRemoteId).length,
            true
          )}{" "}
          linked to{" "}
          {pluralize("group", props.groupOnCallSchedules?.length, true)}:
        </p>
        {Object.values(onCallSchedulesByRemoteId).map(
          (onCallSchedule, i, all) => {
            const groups = groupsByScheduleRemoteId[onCallSchedule.remoteId];
            const isLast = i === all.length - 1;
            return (
              <>
                <div
                  className={sprinkles({
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    paddingBottom: isLast ? "lg" : undefined,
                  })}
                >
                  <div className={styles.onCallScheduleColumn}>
                    <OnCallScheduleLabel
                      key={onCallSchedule.remoteId}
                      scheduleName={onCallSchedule.name}
                      thirdPartyProvider={onCallSchedule.thirdPartyProvider}
                      showMissingFromRemoteWarning={
                        !onCallSchedule.existsInRemote
                      }
                    />
                  </div>
                  <div
                    className={sprinkles({
                      paddingLeft: "md",
                      display: "flex",
                      flexDirection: "column",
                      gap: "sm",
                    })}
                  >
                    {groups.map((group) => (
                      <GroupLabel
                        groupId={group?.id}
                        text={group?.name}
                        icon={getGroupTypeInfo(group?.groupType)?.icon}
                      />
                    ))}
                  </div>
                </div>
                {!isLast && <Divider />}
              </>
            );
          }
        )}
      </Modal.Body>
    </Modal>
  );
};
