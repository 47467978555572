import { GroupType } from "../../api/generated/graphql";

export const groupTypeHasRoles = (groupType: GroupType) => {
  switch (groupType) {
    case GroupType.GitLabGroup:
      return true;
    default:
      return false;
  }
};
