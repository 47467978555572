import {
  EntityType,
  Maybe,
  RequestCustomMetadataFragment,
  RequestFragment,
  RequestStatus,
  RequestTemplateCustomFieldType,
  ResourceType,
  ServiceType,
  UserPreviewSmallFragment,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import GroupBindingDetailPopover from "components/group_bindings/GroupBindingDetailPopover";
import { SupportTicketLabel } from "components/label/Label";
import { getServiceTypeInfo } from "components/label/ServiceTypeLabel";
import { MoreInfo } from "components/more_info/MoreInfo";
import { CodeEditor, Label, Linkify } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import pluralize from "pluralize";
import { useContext } from "react";
import { getResourceUrlNew } from "utils/common";
import styles from "views/requests/RequestInfo.module.scss";

export type RequestInfoProps = {
  request: RequestFragment;
  currentUserIsReviewer: boolean;
};

export const RequestInfo = (props: RequestInfoProps) => {
  const { authState } = useContext(AuthContext);

  if (!props.request.requester) {
    return null;
  }
  const isRequestForAnotherUser =
    props.request.targetUserId !== props.request.requesterId;

  const formatUserDetail = (user?: Maybe<UserPreviewSmallFragment>) => {
    if (user?.teamAttr) {
      return user?.teamAttr + ", " + user?.email;
    }
    return user?.email;
  };

  const requesterContent = (
    <Label
      entityType={EntityType.User}
      label={props.request.requester.fullName}
      detail={formatUserDetail(props.request.requester)}
      icon={
        props.request.requester.avatarUrl
          ? {
              type: "src",
              icon: props.request.requester.avatarUrl,
            }
          : undefined
      }
      linkTo={getResourceUrlNew({
        entityId: props.request.requesterId,
        entityType: EntityType.User,
      })}
    />
  );

  const targetUserContent = (
    <Label
      entityType={EntityType.User}
      label={props.request.targetUser?.fullName ?? ""}
      detail={formatUserDetail(props.request.targetUser)}
      icon={
        props.request.targetUser?.avatarUrl
          ? {
              type: "src",
              icon: props.request.targetUser?.avatarUrl,
            }
          : undefined
      }
      linkTo={getResourceUrlNew({
        entityId: props.request.targetUserId ?? "",
        entityType: EntityType.User,
      })}
    />
  );

  const manager = isRequestForAnotherUser
    ? props.request.targetUser?.manager
    : props.request.requester.manager;
  const managerContent = (
    <Label
      entityType={EntityType.User}
      label={manager?.fullName ?? ""}
      detail={formatUserDetail(manager)}
      icon={
        manager?.avatarUrl
          ? {
              type: "src",
              icon: manager?.avatarUrl,
            }
          : undefined
      }
      linkTo={getResourceUrlNew({
        entityId: manager?.id ?? "",
        entityType: EntityType.User,
      })}
    />
  );

  const requestedItems: JSX.Element[] = [];
  let requestedItemsTitle = pluralize(
    "Requested item",
    props.request.requestedResources.length +
      props.request.requestedGroups.length
  );
  let isRenewRequest = false;
  let currentExpiration: string | undefined | null;

  props.request.requestedResources.forEach((requestedResource) => {
    const { resource, targetUserAccess, accessLevel } = requestedResource;

    if (targetUserAccess?.resourceUsers.length) {
      if (accessLevel.accessLevelRemoteId) {
        const currentRole = targetUserAccess.resourceUsers.find(
          (resourceUser) =>
            resourceUser.accessLevel.accessLevelRemoteId ===
            requestedResource.accessLevel.accessLevelRemoteId
        );
        isRenewRequest = Boolean(currentRole?.access?.directAccessPoint);
        currentExpiration = currentRole?.access?.directAccessPoint?.expiration;
      } else {
        isRenewRequest = Boolean(
          targetUserAccess.resourceUsers[0].access?.directAccessPoint
        );
        currentExpiration =
          targetUserAccess.resourceUsers[0].access?.directAccessPoint
            ?.expiration;
      }
    }
    let resourceName = resource?.name;
    if (resource?.name && resource?.parentResource?.name) {
      resourceName = `${resource.parentResource.name} / ${resource.name}`;
    }
    requestedItems.push(
      <Label
        label={resourceName || requestedResource.resourceId}
        detail={requestedResource.accessLevel.accessLevelName}
        linkTo={`/resources/${requestedResource.resourceId}`}
        icon={
          resource
            ? {
                type: "entity",
                entityType: resource.resourceType,
              }
            : undefined
        }
        entityType={EntityType.Resource}
        key={requestedResource.resourceId}
        iconSize={"md"}
      />
    );
  });

  props.request.requestedGroups.forEach((requestedGroup) => {
    const { group, targetUserAccess } = requestedGroup;
    isRenewRequest = Boolean(
      targetUserAccess?.groupUser?.access?.directAccessPoint
    );
    currentExpiration =
      targetUserAccess?.groupUser?.access?.directAccessPoint?.expiration;

    const groupBindingDetail = group?.groupBinding ? (
      <GroupBindingDetailPopover
        groupId={group.id}
        groupBinding={group.groupBinding}
      />
    ) : undefined;

    requestedItems.push(
      <div
        className={sprinkles({
          display: "flex",
          flexDirection: "row",
          gap: "sm",
          alignItems: "center",
        })}
      >
        <Label
          label={group?.name || requestedGroup.groupId}
          detail={requestedGroup.accessLevel?.accessLevelName}
          icon={
            group
              ? {
                  type: "entity",
                  entityType: group.groupType,
                }
              : undefined
          }
          linkTo={`/groups/${requestedGroup.groupId}`}
          entityType={EntityType.Group}
          key={requestedGroup.groupId}
          iconSize={"md"}
        />
        {groupBindingDetail}
      </div>
    );
  });

  const resourceProposal = props.request.resourceProposal;
  if (resourceProposal) {
    switch (resourceProposal.metadata?.__typename) {
      case "AWSRoleProposalMetadata": {
        const serviceTypeInfo = getServiceTypeInfo(ServiceType.AwsIam);
        requestedItems.push(
          <Label
            label={resourceProposal.metadata?.roleName}
            icon={
              serviceTypeInfo?.icon
                ? {
                    type: "entity",
                    entityType: ResourceType.AwsIamRole,
                  }
                : undefined
            }
            entityType={EntityType.Resource}
            key={resourceProposal.id}
          />
        );
        break;
      }
    }
  }

  const supportTicket = props.request.supportTicket;
  const requestTicket = props.request.requestTicket;

  let requestDurationLabel = "Indefinite";
  if (props.request.durationInMinutes) {
    const expirationDuration = moment.duration(
      props.request.durationInMinutes,
      "minutes"
    );
    requestDurationLabel = expirationDuration.humanize();
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <div className={styles.col1}>
          <div className={styles.title}>{"Requester"}</div>
          {requesterContent}
        </div>
      </div>
      {isRequestForAnotherUser ? (
        <div className={styles.row}>
          <div className={styles.col1}>
            <div className={styles.title}>{"Requested for"}</div>
            {targetUserContent}
          </div>
        </div>
      ) : null}
      {manager && (
        <div className={styles.row}>
          <div className={styles.col1}>
            <div className={styles.title}>{"Manager"}</div>
            {managerContent}
          </div>
        </div>
      )}
      <div className={styles.row}>
        <div className={styles.col1}>
          <div className={styles.title}>{requestedItemsTitle}</div>
          {requestedItems.map((item) => (
            <div key={item.key}>{item}</div>
          ))}
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.col1}>
          <div className={styles.title}>Requested duration</div>
          {requestDurationLabel}
        </div>
        {isRenewRequest &&
        !isRequestForAnotherUser &&
        props.request.status === RequestStatus.Pending ? (
          <div className={styles.col2}>
            <div className={styles.title}>Current access remaining</div>
            {currentExpiration
              ? moment(new Date(currentExpiration)).fromNow(true)
              : "Indefinite"}
          </div>
        ) : null}
      </div>
      <div className={styles.row}>
        <div className={styles.col1}>
          <div className={styles.title}>{"Reason"}</div>
          <div className={styles.reason}>
            <Linkify>{props.request.reason}</Linkify>
          </div>
        </div>
      </div>
      {props.request.customMetadata?.map((metadata) => (
        <RequestInfoCustomFieldRow metadata={metadata} />
      ))}
      {supportTicket && (
        <div className={styles.row}>
          <div className={styles.col1}>
            <div className={styles.title}>
              {"Access expires when ticket is closed"}
              <MoreInfo
                tooltipText={`Access to the requested item(s) will expire when the linked ticket is closed.`}
              />
            </div>
            <SupportTicketLabel
              identifier={supportTicket.identifier || supportTicket.remoteId}
              url={supportTicket.url}
              thirdPartyProvider={supportTicket.thirdPartyProvider}
            />
          </div>
        </div>
      )}
      {requestTicket &&
        (props.currentUserIsReviewer || authState.user?.isAdmin) && (
          <div className={styles.row}>
            <div className={styles.col1}>
              <div className={styles.title}>
                {"Audit ticket"}
                <MoreInfo
                  tooltipText={`This audit ticket was created to track this request.
                  All updates to this request are posted to the ticket.
                  Audit tickets can be useful for consolidating analytics and reporting.`}
                />
              </div>
              <SupportTicketLabel
                identifier={requestTicket.identifier || requestTicket.remoteId}
                url={requestTicket.url}
                thirdPartyProvider={requestTicket.thirdPartyProvider}
              />
            </div>
          </div>
        )}
      {props.request?.resourceProposal && (
        <div className={styles.row}>
          <div className={styles.col1}>
            <div className={styles.title}>{"Role policy permissions"}</div>
            <div className={styles.policyContent}>
              <CodeEditor
                code={props.request.resourceProposal.metadata?.policyDocument}
              />
            </div>
          </div>
        </div>
      )}
      {props.request?.resourceProposal && (
        <div className={styles.row}>
          <div className={styles.col1}>
            <div className={styles.title}>{"Role trust policy"}</div>
            <div className={styles.policyContent}>
              <CodeEditor
                code={
                  props.request.resourceProposal.metadata
                    ?.assumeRolePolicyDocument
                }
              />
            </div>
          </div>
        </div>
      )}
      {props.request?.resourceProposal &&
        props.request.resourceProposal.metadata?.tags &&
        props.request.resourceProposal.metadata.tags.length > 0 && (
          <div className={styles.row}>
            <div className={styles.col1}>
              <div className={styles.title}>{"Role tags"}</div>
              {props.request.resourceProposal.metadata?.tags.map((tag) => {
                return (
                  <div key={tag.key}>
                    {tag.value ? `${tag.key}:${tag.value}` : tag.key}
                  </div>
                );
              })}
            </div>
          </div>
        )}
    </div>
  );
};

const RequestInfoCustomFieldRow = ({
  metadata,
}: {
  metadata: RequestCustomMetadataFragment;
}) => {
  let body;
  switch (metadata.fieldType) {
    case RequestTemplateCustomFieldType.ShortText:
      body = <span>{metadata.metadataValue?.shortTextValue?.value}</span>;
      break;
    case RequestTemplateCustomFieldType.LongText:
      body = <span>{metadata.metadataValue?.longTextValue?.value}</span>;
      break;
    case RequestTemplateCustomFieldType.Boolean:
      body = (
        <span>
          {metadata.metadataValue?.booleanValue?.value ? "True" : "False"}
        </span>
      );
      break;
    case RequestTemplateCustomFieldType.MultiChoice:
      body = <span>{metadata.metadataValue?.multiChoiceValue?.value}</span>;
      break;
  }
  return (
    <div className={styles.row}>
      <div className={styles.col1}>
        <div className={styles.title}>{metadata.fieldName}</div>
        {body}
      </div>
    </div>
  );
};
