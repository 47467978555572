import {
  AccessReviewReviewerFragment,
  useAccessReviewReviewersQuery,
} from "api/generated/graphql";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import FacetFilter from "components/ui/facet_filter/FacetFilter";
import { IconName } from "components/ui/icon/Icon";
import { IconData } from "components/ui/utils";
import { useEffect, useState } from "react";
import { useParams } from "react-router";
import { filterSearchResults } from "utils/search/filter";
import { by } from "utils/search/sort";

const facets: { iconName?: IconName; label: string }[] = [
  {
    iconName: "user-check",
    label: "Reviewer",
  },
];

export interface AccessReviewEntitiesFilterData {
  reviewerFilter?: {
    userId: string | null;
    label: string;
  };
}

interface Props {
  onSetFilter: (filter: AccessReviewEntitiesFilterData) => void;
}

const AccessReviewEntitiesFilter = (props: Props) => {
  const { accessReviewId } = useParams<Record<string, string>>();

  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFacet, setSelectedFacet] = useState<typeof facets[0]>();

  useEffect(() => {
    if (selectedFacet === undefined) {
      setSearchQuery("");
    }
  }, [selectedFacet]);

  const {
    data: reviewersData,
    loading: reviewersLoading,
  } = useAccessReviewReviewersQuery({
    variables: {
      accessReviewId,
    },
    skip: selectedFacet?.label !== "Reviewer",
  });

  const loading = reviewersLoading;

  let results: {
    label: string;
    onClick: () => void;
    icon?: IconData;
  }[] = [];
  if (selectedFacet?.label === "Reviewer") {
    let reviewers: AccessReviewReviewerFragment[] = [];
    if (reviewersData?.accessReview.__typename === "AccessReviewResult") {
      reviewers = reviewersData.accessReview.accessReview.reviewers ?? [];
    }
    results = filterSearchResults(
      reviewers.slice(),
      searchQuery,
      (reviewer) => [reviewer.user?.fullName, reviewer.user?.email]
    )
      .sort(by((reviewer) => reviewer.user?.fullName || ""))
      .map((reviewer) => {
        return {
          label: reviewer.user?.fullName ?? "--",
          icon: {
            type: "src",
            style: "rounded",
            icon: reviewer.user?.avatarUrl || defaultAvatarURL,
            fallbackIcon: defaultAvatarURL,
          },
          onClick: () => {
            setSearchQuery("");
            setSelectedFacet(undefined);
            props.onSetFilter({
              reviewerFilter: {
                userId: reviewer.userId,
                label: reviewer.user?.fullName ?? "--",
              },
            });
          },
        };
      });
    if (searchQuery === "") {
      results.unshift({
        label: "Unassigned",
        icon: {
          type: "name",
          icon: "none",
        },
        onClick: () => {
          setSearchQuery("");
          setSelectedFacet(undefined);
          props.onSetFilter({
            reviewerFilter: {
              userId: null,
              label: "Unassigned",
            },
          });
        },
      });
    }
  }

  return (
    <FacetFilter
      facetOptions={facets}
      searchLoading={loading}
      onSearch={setSearchQuery}
      onSelectedFacet={setSelectedFacet}
      searchOptions={results}
      onLoadMore={() => {}}
    />
  );
};

export default AccessReviewEntitiesFilter;
