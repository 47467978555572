import sprinkles from "css/sprinkles.css";
import { format } from "date-fns";
import { useState } from "react";
import { DateRange, DayPicker } from "react-day-picker";
import { createPortal } from "react-dom";
import OutsideClickHandler from "react-outside-click-handler";
import { usePopper } from "react-popper";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import { Icon } from "..";
import * as styles from "./DatePicker.css";

interface Props {
  selectedRange?: DateRange;
  setSelectedRange: (range?: DateRange) => void;
  disableFutureDates?: boolean;
  leftAlign?: boolean;
  size?: "sm" | "md";
  clearable?: boolean;
}

const DateRangePicker = (props: Props) => {
  const [showPopover, setShowPopover] = useState(false);
  const [isCustom, setIsCustom] = useState(false);
  const clearable = props.clearable ?? true;
  const showClearIcon = clearable && hasDateRangeSelection(props.selectedRange);
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  // As recommended by react-popper, useState instead of useRef
  // https://popper.js.org/react-popper/v2/#example
  const [
    referenceElement,
    setReferenceElement,
  ] = useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );

  const { styles: popperStyles, attributes } = usePopper(
    referenceElement,
    popperElement,
    {
      placement: props.leftAlign ? "bottom-start" : "bottom-end",
    }
  );

  const handleChange = (range?: DateRange) => {
    props.setSelectedRange(range);
  };

  const renderRightIcons = () => {
    return (
      <div className={styles.inputRightIcons}>
        {showClearIcon && (
          <div className={styles.inputClearIcon}>
            <Icon
              name="x"
              onClick={() => {
                // Clear the selection
                handleChange(undefined);
              }}
              size="xs"
            />
          </div>
        )}
        <Icon name="chevron-down" size="xs" />
      </div>
    );
  };

  const body = document.querySelector("body");
  if (!body) return null;

  return (
    <>
      <div
        ref={setReferenceElement}
        className={styles.input({
          hasSelectedDate: Boolean(props.selectedRange),
          size: props.size,
          hasV3,
        })}
        onClick={() => setShowPopover(true)}
      >
        <span
          className={sprinkles({
            gap: "sm",
            display: "flex",
            alignItems: "center",
            whiteSpace: "nowrap",
          })}
        >
          <Icon name="calendar" size="sm" />
          {getDateRangeString(props.selectedRange)}
        </span>
        {renderRightIcons()}
      </div>
      {showPopover
        ? createPortal(
            <OutsideClickHandler onOutsideClick={() => setShowPopover(false)}>
              <div
                ref={setPopperElement}
                className={styles.picker({ hasV3 })}
                style={popperStyles.popper}
                {...attributes.popper}
              >
                <Presets
                  setSelectedRange={(range) => {
                    props.setSelectedRange(range);
                    setIsCustom(false);
                  }}
                  selectedRange={props.selectedRange}
                  onSelectCustom={() => setIsCustom(true)}
                  isCustom={isCustom}
                />
                {isCustom && (
                  <DayPicker
                    mode="range"
                    selected={props.selectedRange}
                    onSelect={handleChange}
                    toDate={props.disableFutureDates ? new Date() : undefined}
                  />
                )}
              </div>
            </OutsideClickHandler>,
            body
          )
        : null}
    </>
  );
};

const DATE_RANGE_PRESETS = [
  {
    label: "Last 30 days",
    getSelectedRange: () => {
      const from = new Date();
      from.setDate(from.getDate() - 30);
      return { from };
    },
  },
  {
    label: "Last 60 days",
    getSelectedRange: () => {
      const from = new Date();
      from.setDate(from.getDate() - 60);
      return { from };
    },
  },
  {
    label: "Last 90 days",
    getSelectedRange: () => {
      const from = new Date();
      from.setDate(from.getDate() - 90);
      return { from };
    },
  },
];

interface PresetProps {
  selectedRange?: DateRange;
  setSelectedRange: (range?: DateRange) => void;
  isCustom: boolean;
  onSelectCustom: () => void;
}

const datesAreEqual = (first: Date, second: Date) =>
  first.getFullYear() === second.getFullYear() &&
  first.getMonth() === second.getMonth() &&
  first.getDate() === second.getDate();

const Presets = (props: PresetProps) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const selectedPreset = DATE_RANGE_PRESETS.find((preset) => {
    const selectedRange = props.selectedRange;
    if (!selectedRange || !selectedRange.from) return false;
    const presetRange = preset.getSelectedRange();
    const selectedTo = selectedRange.to || new Date();

    return (
      datesAreEqual(selectedRange.from, presetRange.from) &&
      datesAreEqual(selectedTo, new Date())
    );
  });

  return (
    <div
      className={styles.presetsContainer({ isCustom: props.isCustom, hasV3 })}
    >
      {DATE_RANGE_PRESETS.map((preset) => {
        return (
          <div
            key={preset.label}
            className={styles.preset({
              selected:
                preset.label === selectedPreset?.label && !props.isCustom,
              hasV3,
            })}
            onClick={() => props.setSelectedRange(preset.getSelectedRange())}
          >
            {preset.label}
          </div>
        );
      })}
      <div
        className={styles.preset({
          selected: props.isCustom,
          hasV3,
        })}
        onClick={props.onSelectCustom}
      >
        Custom
      </div>
    </div>
  );
};

const getDateRangeString = (range?: DateRange) => {
  if (!range || !range.from) {
    return "Select a date range";
  }
  const fromString = format(range.from, "PP");
  const toString = range.to ? format(range.to, "PP") : "Today";
  return `${fromString} - ${toString}`;
};

const hasDateRangeSelection = (range?: DateRange) => {
  return range && (range.from != null || range.to != null);
};

export default DateRangePicker;
