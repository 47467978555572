import { Icon, Label } from "components/ui";
import Node from "components/viz/nodes/Node";
import { useContext } from "react";

import {
  BASE_NODE_HEIGHT,
  ICON_HEIGHT,
  ICON_WIDTH,
  NODE_PADDING,
  ResourceRoleNodeData,
  SPACING,
  TEXT_SPACING,
} from "../common";
import { GraphContext } from "../contexts/GraphContext";
import { getNodeWidth } from "../utils";
import * as styles from "./RoleNode.css";

interface Props {
  data: ResourceRoleNodeData;
  onClick?: () => void;
  onCmdClick?: () => void;
}

const RoleNode = ({ data, onCmdClick }: Props) => {
  const { graphDispatch } = useContext(GraphContext);
  const width = getNodeWidth(data);

  return (
    <Node
      data={data}
      onClick={() => {
        graphDispatch({
          type: "TOGGLE_SELECT_RESOURCE",
          payload: {
            resourceId: data.nodeId,
          },
        });
      }}
      onCmdClick={onCmdClick}
    >
      <foreignObject
        width={ICON_WIDTH}
        height={ICON_HEIGHT}
        x={NODE_PADDING}
        y={BASE_NODE_HEIGHT / 2 - ICON_HEIGHT / 2}
        className={styles.icon}
      >
        <Icon name="key" />
      </foreignObject>
      <foreignObject
        x={NODE_PADDING + ICON_WIDTH + SPACING.xs}
        y={BASE_NODE_HEIGHT / 2 - SPACING.sm}
        height={TEXT_SPACING}
        width={width - (ICON_WIDTH + NODE_PADDING * 3)}
        className={styles.label}
      >
        <Label label={data.roleName} oneLine />
      </foreignObject>
    </Node>
  );
};

export default RoleNode;
