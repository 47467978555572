import { RequestFragment, useRequestQuery } from "api/generated/graphql";
import { useToast } from "components/toast/Toast";
import { useLocation } from "react-router-dom";
import { logError, logWarning } from "utils/logging";

export const REQUEST_TEMPLATE_ID_PARAM_KEY = "requestTemplateId";

export type RequestTemplateParserProps = {
  callback: (request: RequestFragment) => void;
};

export const RequestTemplateParser = (props: RequestTemplateParserProps) => {
  const location = useLocation();

  const { displayErrorToast } = useToast();

  const queryParams = new URLSearchParams(location.search);
  const requestIdParam = queryParams.get(REQUEST_TEMPLATE_ID_PARAM_KEY);

  useRequestQuery({
    variables: {
      input: {
        id: requestIdParam ?? "",
      },
    },
    skip: !requestIdParam,
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      switch (data.request.__typename) {
        case "RequestResult":
          props.callback(data.request.request);
          break;
        case "RequestNotFoundError":
          logWarning(
            new Error(
              "error: failed to create request template because prior request was not found"
            )
          );
          displayErrorToast(
            "Error: failed to create request template because prior request was not found"
          );
          break;
        default:
          logError(new Error("failed to create request template"));
          displayErrorToast("Error: failed to create request template");
      }
    },
    onError: (error) => {
      logError(error, `failed to create request template`);
      displayErrorToast("Error: failed to create request template");
    },
  });

  return null;
};

export default RequestTemplateParser;
