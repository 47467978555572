import { formatTimestampForDisplay } from "api/common/common";
import {
  EventFilterFields,
  EventForTableFragment,
  EventsInput,
  useEventsTableQuery,
} from "api/generated/graphql";
import { LocationDescriptorObject } from "history";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import EventFilterBarV3 from "views/events/EventFilterBarV3";
import ViewSkeleton from "views/loading/ViewSkeleton";

import EventsTableV3 from "./EventsTableV3";
import { getEventFilterEndDateInput, useEventFilter } from "./utils";

const EVENTS_PER_PAGE = 50;

interface Props {
  eventFilter: EventFilterFields;
  route?: LocationDescriptorObject;
  emptyState?: { title: string; subtitle?: string };
  enableSaveFilter?: boolean;
}

/**
 * EventsTableV3Component is a wrapper around EventsTableV3 that also
 * handles fetching events from the server.
 */
const EventsTableV3Component = ({
  eventFilter,
  route,
  emptyState,
  enableSaveFilter,
}: Props) => {
  const eventFilterURL = useEventFilter();
  const endDateFilter = eventFilterURL.endDate?.date
    ? {
        date: getEventFilterEndDateInput(eventFilterURL.endDate.date) ?? "",
      }
    : eventFilter.endDate?.date
    ? {
        date: getEventFilterEndDateInput(eventFilter.endDate.date) ?? "",
      }
    : undefined;

  const input: EventsInput = {
    filter: {
      ...eventFilter,
      ...eventFilterURL,
      endDate: endDateFilter,
    },
    maxNumEntries: EVENTS_PER_PAGE,
  };

  const { data, error, loading, fetchMore } = useEventsTableQuery({
    variables: { input },
  });

  let events: EventForTableFragment[] = [];
  let cursor: string | null = null;
  let filterError: string | null = null;
  switch (data?.events.__typename) {
    case "EventsResult":
      cursor = data.events.cursor ?? null;
      events = data.events.events.map((event) => ({
        ...event,
        createdAt: formatTimestampForDisplay(event.createdAt),
      }));
      break;
    case "InvalidObjectIDFilterError":
      filterError = "Error: Object ID is not a valid UUID";
  }

  if (loading) {
    return <ViewSkeleton />;
  }

  if (error || !events) {
    return <UnexpectedErrorPage error={error} />;
  }

  return (
    <>
      <EventFilterBarV3
        route={route}
        hideObjectFilter={!!eventFilter.objects}
        enableSaveFilter={enableSaveFilter}
      />
      <EventsTableV3
        events={events}
        loadingRows={loading}
        totalNumRows={Number.MAX_SAFE_INTEGER} // Hack: until events query returns total count
        emptyState={filterError ? { title: filterError } : emptyState}
        onLoadMoreRows={async () => {
          if (!cursor) {
            return;
          }
          await fetchMore({
            variables: {
              input: {
                ...input,
                cursor,
              },
            },
          });
        }}
      />
    </>
  );
};

export default EventsTableV3Component;
