import * as React from "react";

import * as styles from "./Card.css";

interface Props {
  title?: string;
}

const Card: React.FC<Props> = (props) => {
  return (
    <div className={styles.container}>
      {props.title ? <div className={styles.title}>{props.title}</div> : null}
      {props.children}
    </div>
  );
};

export default Card;
