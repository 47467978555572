import {
  MessageChannelFragment,
  MessageChannelType,
  useMessageChannelsQuery,
} from "api/generated/graphql";
import CreateMessageChannel from "components/message_channel/CreateMessageChannel";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { PillV3 } from "components/pills/PillsV3";
import {
  ButtonV3,
  DataElementList,
  FormGroup,
  FormRow,
  Modal,
  Select,
} from "components/ui";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useState } from "react";

import { FormMode } from "../common";

interface Props {
  mode: FormMode;
  messageChannels?: MessageChannelFragment[];
  onChange: (channels?: MessageChannelFragment[]) => void;
  adminOwnerName: string;

  // For authorization
  resourceId?: string;
  groupId?: string;
}

const HELP_TEXT =
  "Subscribe to an audit log of access updates to this resource by linking a Slack channel here. Opal will only display Slack channels that are created from Opal. If multiple channels are linked, they'll all receive these updates.";

const SlackChannelsRowV3 = (props: Props) => {
  const [channelNameToCreate, setChannelNameToCreate] = useState("");
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { data, error, loading } = useMessageChannelsQuery({
    variables: {
      input: {
        generateChannelName: props.adminOwnerName
          ? {
              itemName: props.adminOwnerName,
              channelType: MessageChannelType.Audit,
            }
          : undefined,
      },
    },
  });

  const channelById: { [channelId: string]: MessageChannelFragment } = {};
  let messageChannels: MessageChannelFragment[] = [];
  let dropdownErrorMessage = null;
  if (data) {
    switch (data.messageChannels.__typename) {
      case "MessageChannelsResult":
        messageChannels = data.messageChannels.messageChannels;
        messageChannels.forEach(
          (channel) => (channelById[channel.id] = channel)
        );
        if (
          channelNameToCreate === "" &&
          data.messageChannels.generatedChannelName
        ) {
          setChannelNameToCreate(data.messageChannels.generatedChannelName);
        }
        break;
      case "MessageChannelMissingOAuthScopeError":
        dropdownErrorMessage = data.messageChannels.message;
        break;
      case "MessageChannelRateLimitedError":
        dropdownErrorMessage = data.messageChannels.message;
        break;
      default:
        dropdownErrorMessage = `Error: failed to fetch message channels`;
    }
  } else if (error) {
    dropdownErrorMessage = `Error: failed to fetch message channels`;
  }

  const messageChannelOptions = messageChannels.filter(
    (channel) => !props.messageChannels?.find((c) => c.id === channel.id)
  );

  const handleSelectValue = (newMessageChannel?: MessageChannelFragment) => {
    if (!newMessageChannel) return;

    props.onChange(
      _.uniqBy(
        [...(props.messageChannels ?? []), newMessageChannel],
        (messageChannel) => messageChannel.id
      )
    );
  };

  if (props.mode === "view") {
    return (
      <FormRow
        title="Linked audit Slack channels"
        tooltipText={HELP_TEXT}
        showToolTipIcon
      >
        {props.messageChannels?.length ? (
          props.messageChannels.map((channel) => {
            const icon: IconData = {
              type: "name",
              icon: channel.isPrivate ? "lock" : "hash",
            };
            return (
              <PillV3
                key={channel.id}
                icon={icon}
                keyText={channel.name}
                pillColor="Purple"
              />
            );
          })
        ) : (
          <div className={sprinkles({ fontWeight: "medium" })}>
            No linked channels
          </div>
        )}
      </FormRow>
    );
  }
  return (
    <>
      <FormGroup label="Linked audit Slack channels" infoTooltip={HELP_TEXT}>
        {dropdownErrorMessage && (
          <ModalErrorMessage errorMessage={dropdownErrorMessage} />
        )}
        <div className={sprinkles({ marginBottom: "md" })}>
          <Select
            selectOnly
            onChange={handleSelectValue}
            options={messageChannelOptions}
            loading={loading}
            getOptionLabel={(messageChannel) => messageChannel.name}
            getIcon={(option) => ({
              type: "name",
              icon: option.isPrivate ? "lock" : "hash",
            })}
            placeholder="Search for channel to add"
            clearable
          />
        </div>
        {Boolean(props.messageChannels?.length) && (
          <div className={sprinkles({ marginBottom: "md" })}>
            <DataElementList>
              {props.messageChannels?.map((channel) => {
                const icon: IconData = {
                  type: "name",
                  icon: channel.isPrivate ? "lock" : "hash",
                };
                return (
                  <PillV3
                    key={channel.id}
                    icon={icon}
                    keyText={channel.name}
                    pillColor="Purple"
                    onRemove={() =>
                      props.onChange(
                        props.messageChannels?.filter(
                          (c) => c.id !== channel.id
                        )
                      )
                    }
                  />
                );
              })}
            </DataElementList>
          </div>
        )}
        <ButtonV3
          size="xs"
          type="mainSecondary"
          label="Create or link a channel"
          leftIconName="plus"
          onClick={() => setShowCreateModal(true)}
        />
      </FormGroup>
      {showCreateModal && (
        <Modal
          title="Create or link Slack channel"
          isOpen
          onClose={() => setShowCreateModal(false)}
        >
          <Modal.Body>
            <CreateMessageChannel
              channelNameToCreate={channelNameToCreate}
              adminTeamName={props.adminOwnerName}
              channelType={MessageChannelType.Audit}
              onCreateChannel={(newChannel) => {
                props.onChange(
                  _.uniqBy(
                    [...(props.messageChannels ?? []), newChannel],
                    (messageChannel) => messageChannel.id
                  )
                );
              }}
              resourceId={props.resourceId}
              groupId={props.groupId}
            />
          </Modal.Body>
          <Modal.Footer
            secondaryButtonLabel="Cancel"
            onSecondaryButtonClick={() => setShowCreateModal(false)}
          />
        </Modal>
      )}
    </>
  );
};

export default SlackChannelsRowV3;
