import { Maybe, useForfeitGroupMutation } from "api/generated/graphql";
import ConfirmModal from "components/modals/update/ConfirmModal";
import { useToast } from "components/toast/Toast";
import { ButtonV3 } from "components/ui";
import React, { useContext, useState } from "react";
import useLogEvent from "utils/analytics";
import { logError, logWarning } from "utils/logging";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import OrgContext from "views/settings/OrgContext";

type ForfeitableGroup = {
  id: string;
  groupType: string;
};

export type ForfeitGroupMenuOptionProps = {
  group: ForfeitableGroup;
  onSuccessCallback?: () => void;
  hasV3?: boolean;
  buttonSize?: PropsFor<typeof ButtonV3>["size"];
  noIcon?: boolean;
  showMenuOption: (onClick: () => void) => void;
};

export const ForfeitGroupMenuOption = (props: ForfeitGroupMenuOptionProps) => {
  const logEvent = useLogEvent();
  const { orgState } = useContext(OrgContext);

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [showModal, setShowModal] = useState(false);
  const [polling, setPolling] = useState(false);
  const startPushTaskPoll = usePushTaskLoader();

  const [forfeitGroup, { loading }] = useForfeitGroupMutation();

  const modalReset = () => {
    setShowModal(false);
    setErrorMessage(null);
  };
  const { displayErrorToast, displayCustomToast } = useToast();

  const confirmModal = (
    <ConfirmModal
      title={`Forfeit group`}
      message={`Are you sure you want to forfeit direct access to this group? Note: indirect access
        to this group through group membership will not be affected.`}
      isModalOpen={showModal}
      onClose={() => {
        modalReset();
      }}
      onSubmit={async () => {
        logEvent({
          name: "apps_forfeit_click",
          properties: {
            itemType: props.group.groupType,
          },
        });

        try {
          const { data } = await forfeitGroup({
            variables: {
              input: {
                groupId: props.group.id,
              },
            },
          });
          switch (data?.forfeitGroup.__typename) {
            case "ForfeitGroupResult":
              modalReset();
              if (data.forfeitGroup.taskId) {
                setPolling(true);
              }
              // Always call this, since it also handles empty `taskId`.
              startPushTaskPoll(data.forfeitGroup.taskId, {
                refetchOnComplete: [
                  {
                    groupId: props.group.id,
                  },
                ],
                onComplete: () => {
                  setPolling(false);
                },
                messageOverride: {
                  loading: null,
                  success: `Success: forfeited direct access to group`,
                  error: `Error: could not forfeit access to group`,
                },
              });
              break;
            case "GroupNotFoundError":
              logWarning(new Error(data.forfeitGroup.message));
              setErrorMessage(data.forfeitGroup.message);
              break;
            default:
              logError(new Error(`group forfeit failed`));
              setErrorMessage(`Error: group forfeit failed`);
          }
        } catch (error) {
          logError(error, `group forfeit failed`);
          setErrorMessage(`Error: group forfeit failed`);
        }
      }}
      loading={loading}
      errorMessage={errorMessage}
      submitLabel={`Forfeit`}
    />
  );

  const isDisabledByReadOnly = orgState.orgSettings?.isRemoteReadOnly;

  return (
    <>
      {props.showMenuOption(async () => {
        if (isDisabledByReadOnly) {
          displayErrorToast(
            "Error: Unable to forfeit because your organization is in read only mode."
          );
        } else if (polling) {
          displayCustomToast(
            "Warning: Pending forfeit request. Try again later.",
            "hourglass",
            true
          );
        } else {
          setShowModal(true);
        }
      })}
      {confirmModal}
    </>
  );
};
