import { ResourceConfig } from "components/forms/common";
import { Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";

import { SelectedItem } from "./AppsContext";

interface BulkEditConfirmModalProps {
  isOpen: boolean;
  onClose: (event: React.MouseEvent | React.KeyboardEvent) => void;
  onSaveClick: () => void;
  editConfig: Partial<ResourceConfig>;
  selectedItems: SelectedItem[];
}

const resourceConfigToText = (config: Partial<ResourceConfig>) => {
  return (
    <ul className={sprinkles({ paddingLeft: "lg" })}>
      {config.description !== undefined && <li>Description</li>}
      {config.commonMetadata?.matchRemoteDescription !== undefined && (
        <li>Match remote description</li>
      )}
      {config.adminOwner !== undefined && <li>Admin</li>}
      {config.visibility !== undefined && (
        <li>
          Visibility{` `}
          {config.visibilityGroups !== undefined && <>and visibility groups</>}
        </li>
      )}
      {config.messageChannels !== undefined && <li>Slack message channels</li>}
      {"requestConfigs" in config &&
        config.requestConfigs !== undefined &&
        config.requestConfigs.length > 0 && <li>Request configurations</li>}
      {config.onCallSchedules !== undefined && <li>On-call schedules</li>}
      {config.breakGlassUsers !== undefined && <li>Break-glass users</li>}
      {config.tagIds !== undefined && <li>Tags</li>}
      {config.requireMfaToApprove !== undefined && (
        <li>MFA setting to approve request</li>
      )}
      {config.requireMfaToConnect !== undefined && (
        <li>MFA setting to connect to resource</li>
      )}
      {config.configurationTemplate !== undefined && (
        <li>Configuration template</li>
      )}
      {config.ticketPropagation !== undefined && <li>Ticket propagation</li>}
    </ul>
  );
};

const BulkEditConfirmModal = (props: BulkEditConfirmModalProps) => {
  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      title={"Please confirm your changes"}
    >
      <Modal.Body>
        The following fields will be updated for{" "}
        {pluralize("item", props.selectedItems.length, true)}:
        {resourceConfigToText(props.editConfig)}
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Confirm"
        onPrimaryButtonClick={props.onSaveClick}
      />
    </Modal>
  );
};

export default BulkEditConfirmModal;
