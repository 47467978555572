import {
  EntityType,
  useCreateBundleMutation,
  Visibility,
} from "api/generated/graphql";
import VisibilityRow from "components/forms/rows/VisibilityRow";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { FormGroup, Input, Modal } from "components/ui";
import { useState } from "react";
import { useHistory } from "react-router";
import { logError } from "utils/logging";

interface Props {
  onClose: () => void;
  hasV3?: boolean;
}

interface CreateBundleConfig {
  name: string;
  description: string;
  adminOwnerId: string;
  visibility: Visibility;
  visibilityGroupsIds: string[];
}

const BundleCreateModal = (props: Props) => {
  const history = useHistory();
  const [config, setConfig] = useState<CreateBundleConfig>({
    name: "",
    description: "",
    adminOwnerId: "",
    visibility: Visibility.Global,
    visibilityGroupsIds: [],
  });
  const [error, setError] = useState("");

  const { displaySuccessToast } = useToast();

  const [createBundle, { loading }] = useCreateBundleMutation();

  const handleSubmit = async () => {
    try {
      const { data } = await createBundle({
        variables: {
          input: {
            name: config.name,
            description: config.description,
            adminOwnerId: config.adminOwnerId,
            visibility: config.visibility,
            visibilityGroupsIds: config.visibilityGroupsIds,
          },
        },
        refetchQueries: ["Bundles"],
      });

      switch (data?.createBundle.__typename) {
        case "BundleNameExistsError":
          setError(data.createBundle.message);
          break;
        case "CreateBundleResult":
          displaySuccessToast("Success: bundle created");
          props.onClose();
          if (props.hasV3) {
            history.push(`/bundles/${data.createBundle.bundle.id}/resources`);
          } else {
            history.push(`/bundles/${data.createBundle.bundle.id}/overview`);
          }
      }
    } catch (err) {
      logError(err, "Failed to create bundle");
      setError("Failed to create bundle");
    }
  };

  return (
    <Modal isOpen onClose={props.onClose} title="Create Bundle">
      <Modal.Body>
        {error && <ModalErrorMessage errorMessage={error} />}
        <FormGroup label="Name" required>
          <Input
            value={config.name}
            onChange={(val) => setConfig((prev) => ({ ...prev, name: val }))}
          />
        </FormGroup>
        <FormGroup label="Description">
          <Input
            type="textarea"
            value={config.description}
            onChange={(val) =>
              setConfig((prev) => ({ ...prev, description: val }))
            }
          />
        </FormGroup>
        <FormGroup
          label="Admin"
          infoTooltip="Admins of the bundle can add and remove items."
          required
        >
          <OwnerDropdown
            selectedOwnerId={config.adminOwnerId}
            onSelectOwner={(owner) => {
              if (owner) {
                setConfig((prev) => ({
                  ...prev,
                  adminOwnerId: owner.id,
                }));
              }
            }}
          />
        </FormGroup>
        <VisibilityRow
          mode={"edit"}
          entityType={EntityType.Bundle}
          visibility={config.visibility}
          visibilityGroups={config.visibilityGroupsIds ?? []}
          onChangeVisibilityAndGroups={(val: {
            visibility?: Visibility;
            groupIds?: string[];
            setDoNotChange?: boolean;
          }) => {
            const { visibility, groupIds, setDoNotChange } = val;
            if (setDoNotChange) {
              setConfig((prev) => ({
                ...prev,
                visibility: Visibility.Global,
                visibilityGroupsIds: [],
              }));
              return;
            }
            const visDict = visibility ? { visibility } : {};
            const visGroupsDict = groupIds
              ? { visibilityGroupsIds: groupIds }
              : {};
            setConfig((prev) => ({
              ...prev,
              ...visDict,
              ...visGroupsDict,
            }));
          }}
        />
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Submit"
        primaryButtonDisabled={config.name.length === 0 || !config.adminOwnerId}
        primaryButtonLoading={loading}
        onPrimaryButtonClick={handleSubmit}
      />
    </Modal>
  );
};

export default BundleCreateModal;
