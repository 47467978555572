import {
  EntityType,
  EventFilterFields,
  useEventsQuery,
} from "api/generated/graphql";
import { EventTypeLabel } from "components/label/EventTypeLabel";
import { ResourceLabel } from "components/label/Label";
import {
  EmptyState,
  EmptyStateContentWrapper,
} from "components/tables/EmptyState";
import MuiVirtualTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MuiVirtualTable";
import { Icon } from "components/ui";
import _ from "lodash";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router";
import { SortDirection } from "react-virtualized";
import useLogEvent from "utils/analytics";
import { getResourceUrlNew } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { logError } from "utils/logging";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ViewSkeleton from "views/loading/ViewSkeleton";

import * as styles from "./EventsTable.css";
import { getEventFilterEndDateInput } from "./utils";

type EventRow = {
  eventID: string;
  actor: string;
  eventType: string;
  date: string;
};

export type EventsTableProps = {
  eventFilter: EventFilterFields;
  emptyTitle: string;
  emptySubtitle: string;
};

export const EventsTable = (props: EventsTableProps) => {
  const history = useHistory();
  const logEvent = useLogEvent();

  const headers: Header<EventRow>[] = [
    {
      id: "eventID",
      label: "",
      width: 100,
      minWidth: 80,
    },
    {
      id: "actor",
      label: "Actor",
      width: 160,
      minWidth: 150,
    },
    {
      id: "eventType",
      label: "Event type",
      width: 280,
      minWidth: 220,
    },
    {
      id: "date",
      label: "Date",
      width: 280,
      minWidth: 250,
    },
  ];

  const { data, error, loading, fetchMore } = useEventsQuery({
    variables: {
      input: {
        filter: {
          ...props.eventFilter,
          endDate: props.eventFilter.endDate?.date
            ? {
                date:
                  getEventFilterEndDateInput(props.eventFilter.endDate.date) ??
                  "",
              }
            : undefined,
        },
      },
    },
  });
  switch (data?.events.__typename) {
    case "InvalidObjectIDFilterError":
      return (
        <div className={styles.errorWrapper}>
          <EmptyState
            icon={<Icon name="alert-circle" />}
            title="Filter Error"
            subtitle="Object ID is not a valid UUID"
          />
        </div>
      );
  }
  if (error) {
    logError(error, `failed to list events`);
  }

  let events = data?.events.events;
  let cursor = data?.events.cursor;

  if (loading) {
    return <ViewSkeleton />;
  }

  if (error || !events) {
    return <UnexpectedErrorPage error={error} />;
  }

  let eventRows: CellRow<EventRow>[] = events.map((event) => {
    const createdAtTime = moment(new Date(event.createdAt));

    return {
      id: event.id,
      data: {
        eventID: {
          value: event.id,
          element: (
            <ResourceLabel
              text="See details"
              entityType={EntityTypeDeprecated.Event}
              pointerCursor={true}
            />
          ),
          clickHandler: () => {
            logEvent({
              name: "table_row_click",
              properties: {
                entityID: event.id,
                entityName: event.eventType,
                entityType: EntityType.Event,
              },
            });
            history.push(
              getResourceUrlNew({
                entityId: event.id,
                entityType: EntityType.Event,
              })
            );
          },
        },
        actor: {
          value: event.actorUserId,
          sortValue: event.actorUser?.fullName || "",
          element: (
            <ResourceLabel
              text={
                event.actorUser
                  ? _.truncate(event.actorUser.fullName, {
                      length: 18,
                    })
                  : event.actorUserId
              }
              entityType={EntityTypeDeprecated.User}
              avatar={event.actorUser?.avatarUrl}
              entityId={event.actorUserId}
            />
          ),
        },
        eventType: {
          value: event.eventType,
          element: (
            <EventTypeLabel
              eventType={event.eventType}
              eventSource={event.source}
            />
          ),
        },
        date: {
          value: createdAtTime.unix(),
          element: (
            <>{`${createdAtTime.toISOString()} (${createdAtTime.fromNow()})`}</>
          ),
        },
      },
    };
  });

  // Implements loadMoreRows for InfiniteLoader from react-virtualized library.
  // Disregards startIndex, stopIndex and fetches a new page which will update the query and trigger a re-render.
  const loadMoreRows = async () => {
    if (!cursor) {
      // This is an annoying hack to handle the case where we remove rows from the
      // virtual table. Basically, we should never "fetch more" when the cursor is null,
      // so we can always short-circuit that request.
      return;
    }

    await fetchMore({
      variables: {
        input: {
          cursor: cursor,
          filter: props.eventFilter,
        },
      },
    });
  };

  return (
    <EmptyStateContentWrapper
      content={
        <MuiVirtualTable
          columns={headers}
          rows={eventRows}
          defaultSortBy={"date"}
          defaultSortDirection={SortDirection.DESC}
          loadMoreRows={loadMoreRows}
          allRowsLoaded={cursor === null}
        />
      }
      entityType={EntityType.Event}
      title={props.emptyTitle || ""}
      subtitle={props.emptySubtitle || ""}
      isEmpty={eventRows.length === 0}
      small={false}
    />
  );
};

export default EventsTable;
