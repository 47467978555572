import { DurationOption } from "api/generated/graphql";
import { formatDuration } from "components/label/Label";
import modalsStyles from "components/modals/Modals.module.scss";
import { CustomDurationPicker } from "components/requests/CustomDurationPicker";
import { Banner, FormGroup, Select } from "components/ui";
import _ from "lodash";
import moment from "moment/moment";
import { useEffect } from "react";
import { useImmer } from "use-immer";
import { NULLABLE_DURATION_INDEFINITE } from "views/requests/utils";

import * as styles from "./AccessRequestDurationPicker.css";

type Props = {
  durationOptions: DurationOption[];
  recommendedDurationInMinutes?: number;
  defaultDurationInMinutes: number;
  maxDurationInMinutes?: number;

  durationInMinutes?: number;
  setDurationInMinutes: (duration: number) => void;

  loading?: boolean;
  label?: string;
};

const AccessRequestDurationPicker = (props: Props) => {
  const label = props.label ?? "Expires In";

  const [state, setState] = useImmer({
    showCustomDurationPicker: false,
    lastSelectedDuration: undefined as number | undefined,
  });

  const warnings: string[] = [];
  const durationOptions = _.sortBy(
    props.durationOptions.filter((option) => option.durationInMinutes !== 0),
    "durationInMinutes"
  );
  const selectValue = durationOptions.find(
    (option) =>
      option.durationInMinutes ===
      (props.durationInMinutes ?? NULLABLE_DURATION_INDEFINITE)
  );

  useEffect(() => {
    if (state.lastSelectedDuration !== undefined) {
      return;
    }
    if (durationOptions.length > 0 && selectValue === undefined) {
      setState((draft) => {
        draft.showCustomDurationPicker = true;
      });
    } else if (durationOptions.length > 0) {
      setState((draft) => {
        draft.showCustomDurationPicker = false;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [durationOptions, selectValue]);

  if (
    props.recommendedDurationInMinutes != null &&
    state.showCustomDurationPicker
  ) {
    warnings.push(
      `Recommended duration is ${
        props.recommendedDurationInMinutes === NULLABLE_DURATION_INDEFINITE
          ? "indefinite"
          : formatDuration(
              moment.duration(props.recommendedDurationInMinutes, "m")
            )
      }.`
    );
  }

  return (
    <FormGroup
      label={label}
      rightLabelContent={
        <span
          className={modalsStyles.hyperlinkText}
          onClick={() => {
            setState((draft) => {
              draft.lastSelectedDuration = props.durationInMinutes;
              draft.showCustomDurationPicker = !draft.showCustomDurationPicker;
            });
            props.setDurationInMinutes(state.lastSelectedDuration ?? 60);
          }}
        >
          {state.showCustomDurationPicker ? "default" : "custom"}
        </span>
      }
      required
    >
      {state.showCustomDurationPicker ? (
        <CustomDurationPicker
          durationInMinutes={
            props.durationInMinutes ?? props.defaultDurationInMinutes ?? 60
          }
          setDurationInMinutes={(duration) => {
            props.setDurationInMinutes(duration);
          }}
          maxValue={props.maxDurationInMinutes || undefined}
          maxValueReason={
            // Define warning messages for exceeding organization and resource maximum values
            `The requested duration exceeds the allowed limit of ${formatDuration(
              moment.duration(props.maxDurationInMinutes, "m")
            )} set by admins`
          }
        />
      ) : (
        <Select<DurationOption>
          id="duration-picker"
          value={selectValue}
          onChange={(duration) => {
            props.setDurationInMinutes(
              duration?.durationInMinutes ?? props.defaultDurationInMinutes
            );
          }}
          getOptionSelected={(option, value) => option === value}
          options={durationOptions}
          getOptionLabel={(duration) => duration.label}
          loading={props.loading}
          renderOptionLabel={(duration) => {
            const isRecommended =
              props.recommendedDurationInMinutes === duration.durationInMinutes;
            const isMaxDuration =
              props.maxDurationInMinutes === duration.durationInMinutes;
            return (
              <span>
                {duration.label}
                {isRecommended && (
                  <span className={styles.badge({ suggested: true })}>
                    Recommended
                  </span>
                )}
                {isMaxDuration && (
                  <span
                    className={styles.badge({
                      max: true,
                    })}
                  >
                    Max allowed
                  </span>
                )}
              </span>
            );
          }}
        />
      )}
      {warnings.map((message) => {
        return <Banner type="warning" message={message} marginTop="md" />;
      })}
    </FormGroup>
  );
};

export default AccessRequestDurationPicker;
