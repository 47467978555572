import { UserDropdownPreviewFragment } from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { AccessReviewerModalEntry } from "components/modals/AccessReviewerModal";
import { Select } from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";

type UserDropdownProps = {
  allUsers: UserDropdownPreviewFragment[];
  selectedUsers?: AccessReviewerModalEntry[];
  onChange: (newUser?: UserDropdownPreviewFragment) => void;
  value?: UserDropdownPreviewFragment | null;
  disabled?: boolean;
  clearable?: boolean;
  autoFocus?: boolean;
  placeholder?: string;
  selectOnly?: boolean;
};

export const UserDropdown = (props: UserDropdownProps) => {
  const { clearable = true } = props;
  const { authState } = useContext(AuthContext);

  let allUsers = props.allUsers;
  const selectedUsersByID: Record<string, AccessReviewerModalEntry> = {};

  allUsers = allUsers.slice().sort((a, b) => {
    if (a && b) {
      return a.fullName.localeCompare(b.fullName);
    }
    return 0;
  });

  props.selectedUsers?.forEach((user) => {
    selectedUsersByID[user.id] = user;
  });

  const getOptionLabel = (option: UserDropdownPreviewFragment) => {
    return option.id === authState.user?.user.id
      ? `${option.fullName} (Me)`
      : option.fullName;
  };

  const renderOptionLabel = (option: UserDropdownPreviewFragment) => {
    return (
      <span>
        {option.fullName}
        <span className={sprinkles({ color: "gray500", fontSize: "bodySm" })}>
          {" "}
          ({option.email})
        </span>
      </span>
    );
  };

  return (
    <Select
      options={allUsers.filter((user) => !selectedUsersByID[user.id])}
      value={props.value ?? undefined}
      onChange={props.onChange}
      clearable={clearable}
      disabled={props.disabled}
      getOptionLabel={getOptionLabel}
      renderOptionLabel={renderOptionLabel}
      getIcon={(option) => ({
        type: "src",
        style: "rounded",
        icon: option.avatarUrl || defaultAvatarURL,
        fallbackIcon: defaultAvatarURL,
      })}
      getOptionSelected={(option, value) => option.id === value.id}
      autoFocus={props.autoFocus}
      placeholder={props.placeholder || "Select a user"}
      selectOnly={props.selectOnly}
    />
  );
};
