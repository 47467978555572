import { Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";

import { MAX_STEP, SETUP_STEPS } from "./common";
import * as styles from "./SetupProgress.css";

interface Props {
  currentStep: number;
}

const SetupProgress = (props: Props) => {
  return (
    <div className={styles.container}>
      {Object.entries(SETUP_STEPS).map(([key, value]) => {
        const step = Number(key);
        let connectorColor: "gray" | "blue" | "green" = "gray";
        if (step === props.currentStep && step !== MAX_STEP) {
          connectorColor = "blue";
        } else if (step < props.currentStep || props.currentStep === MAX_STEP) {
          connectorColor = "green";
        }
        return (
          <>
            <ProgressStep
              key={key}
              step={step}
              currentStep={props.currentStep}
              label={value.label}
            />
            {step !== MAX_STEP && (
              <div className={styles.connector({ color: connectorColor })} />
            )}
          </>
        );
      })}
    </div>
  );
};

interface StepProps {
  step: number;
  currentStep: number;
  label: string;
}

const ProgressStep = ({ step, currentStep, label }: StepProps) => {
  let content;
  if (step === currentStep && step !== MAX_STEP) {
    content = (
      <>
        <div className={styles.currentStepIcon}>
          <div className={styles.currentStepIconInner} />
        </div>
        <div className={sprinkles({ fontWeight: "medium" })}>{label}</div>
      </>
    );
  } else if (step < currentStep || currentStep === MAX_STEP) {
    content = (
      <>
        <div className={styles.completedStepIcon}>
          <Icon name="check" size="xxs" color="white" />
        </div>
        <div className={sprinkles({ color: "gray700" })}>{label}</div>
      </>
    );
  } else {
    content = (
      <>
        <div className={styles.todoStepIcon} />
        <div className={sprinkles({ color: "gray700" })}>{label}</div>
      </>
    );
  }

  return (
    <div
      className={sprinkles({
        display: "flex",
        gap: "md",
        alignItems: "center",
      })}
    >
      {content}
    </div>
  );
};

export default SetupProgress;
