import {
  ResourceEditViewDetailFragment,
  useBulkUpdateMutation,
  useResourceEditViewQuery,
} from "api/generated/graphql";
import { ResourceConfig } from "components/forms/common";
import {
  getResourceConfigChangedFields,
  makeConfigForResource,
  makeUpdateInputForResource,
  validateResourceConfig,
} from "components/forms/utils";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import { useUnsavedChangesModal } from "components/modals/update/UnsavedChangesModal";
import { useToast } from "components/toast/Toast";
import { EntityIcon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useContext, useState } from "react";
import { useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import useSyncStatusToast from "utils/sync/useSyncStatusToast";
import {
  ForbiddenPage,
  NotFoundPage,
  UnexpectedErrorPage,
} from "views/error/ErrorCodePage";
import OrgContext from "views/settings/OrgContext";

import ResourceEditForm from "./ResourceEditForm";

const ResourceEditView = () => {
  const { resourceId } = useParams<Record<string, string>>();

  const { data, error, loading } = useResourceEditViewQuery({
    variables: {
      id: resourceId,
    },
    fetchPolicy: "cache-and-network",
  });
  let resource: ResourceEditViewDetailFragment | undefined;
  let notFound = false;
  if (data?.resource.__typename === "ResourceResult") {
    resource = data.resource.resource;
  } else if (data?.resource.__typename === "ResourceNotFoundError") {
    notFound = true;
  }

  if (loading) {
    return <FullscreenSkeleton />;
  }
  if (!resource?.authorizedActions?.includes(AuthorizedActionManage)) {
    return <ForbiddenPage />;
  }
  if (notFound) {
    return <NotFoundPage entity="Resource" />;
  }
  if (!resource || error) {
    return <UnexpectedErrorPage error={error} />;
  }

  return <ResourceEdit resource={resource} />;
};

interface Props {
  resource: ResourceEditViewDetailFragment;
}

const ResourceEdit = ({ resource }: Props) => {
  const logEvent = useLogEvent();
  const { orgState } = useContext(OrgContext);
  const { displaySuccessToast } = useToast();

  const [bulkUpdate, { loading: updateLoading }] = useBulkUpdateMutation({
    refetchQueries: ["ResourceDetailColumn", "ResourceEditView"],
  });
  const showSyncStatusToast = useSyncStatusToast({
    loadingText: "Successfully updated, now syncing...",
    queriesToRefetch: ["ResourceDetailColumn"],
  });

  const initialConfig = makeConfigForResource(
    resource,
    resource.configTemplate ?? undefined
  );
  const [config, setConfig] = useState<Partial<ResourceConfig>>(initialConfig);
  const [errors, setErrors] = useState<string[]>([]);

  const {
    handleClose,
    maybeRenderUnsavedChangesModal,
  } = useUnsavedChangesModal(`/resources/${resource.id}`);

  const handleSave = async () => {
    if (!resource || !initialConfig) return;

    const errors = validateResourceConfig(config, orgState);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    logEvent({
      name: "apps_item_edit_click",
      properties: {
        itemType: resource.resourceType,
      },
    });

    try {
      setErrors([]);
      const changedConfig = getResourceConfigChangedFields(
        initialConfig,
        config
      );
      const inputFields = makeUpdateInputForResource(changedConfig, resource);
      if (
        initialConfig.childrenDefaultConfigTemplate?.id &&
        !config.childrenDefaultConfigTemplate?.id
      ) {
        inputFields.childrenDefaultConfigTemplateId = {};
      }
      if (
        initialConfig.configurationTemplate?.id &&
        !config.configurationTemplate?.id
      ) {
        inputFields.configurationId = {};
        inputFields.forkConfigurationTemplates = true;
      }
      if (
        config.configurationTemplate?.id &&
        config.forkConfigurationTemplates
      ) {
        inputFields.configurationId = {};
      }

      const input = inputFields;
      const { data } = await bulkUpdate({
        variables: {
          input: {
            resourceIds: [resource.id],
            ...input,
          },
        },
      });

      switch (data?.bulkUpdateItems.__typename) {
        case "BulkUpdateItemsResult":
          setErrors([]);
          if (data.bulkUpdateItems.syncTask?.id) {
            showSyncStatusToast(data.bulkUpdateItems.syncTask.id);
          } else {
            displaySuccessToast(`Successfully updated resource`);
          }
          handleClose(false);
          break;
        case "TagNotFoundError":
          setErrors(["Failed to get tag data"]);
          break;
        case "InvalidUpdateResourceVisibilityGroupError":
          setErrors(["Invalid visibility group"]);
          break;
        case "InvalidReviewerSettingsError":
          setErrors(["Invalid reviewer stage configuration"]);
          break;
        case "ResourceMaxDurationTooLargeError":
          setErrors([
            "Resource max access duration cannot exceed org-wide max duration",
          ]);
          break;
        case "CannotUpdateConfigurationTemplateError":
          setErrors([
            "This item is linked to a configuration template. To change settings controlled by templates, you must first unlink the template.",
          ]);
          break;
        default:
          setErrors(["Failed to update resource."]);
      }
    } catch (err) {
      setErrors(["Failed to update resource."]);
    }
  };

  const title = (
    <>
      Edit:
      <div className={sprinkles({ display: "flex", alignItems: "center" })}>
        <EntityIcon type={resource.resourceType} size="lg" />
      </div>
      {resource.name}
    </>
  );

  const hasChanges = !_.isEqual(config, initialConfig);
  const hasRequiredFields =
    (config.name?.trim() && config.adminOwner?.id) ||
    config.commonMetadata?.matchRemoteName;

  return (
    <FullscreenView
      title={title}
      onCancel={() => handleClose(hasChanges)}
      onPrimaryButtonClick={handleSave}
      primaryButtonDisabled={updateLoading || !hasChanges || !hasRequiredFields}
    >
      <ResourceEditForm config={config} onChange={setConfig} errors={errors} />
      {maybeRenderUnsavedChangesModal()}
    </FullscreenView>
  );
};

export default ResourceEditView;
