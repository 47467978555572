import {
  TagPreviewLargeFragment,
  TagPreviewSmallFragment,
  useTagsQuery,
} from "api/generated/graphql";
import ConfigurationTemplateDropdown, {
  ConfigurationTemplate,
} from "components/configuration_templates/ConfigurationTemplateDropdown";
import { FormGroup, Modal, Select } from "components/ui";
import { generateUniqueId } from "components/ui/utils";
import { useState } from "react";
import { useDebouncedValue } from "utils/hooks";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import { ConfigTemplateTag } from "./TemplateMappingsView";

type ConfigTemplateTags = {
  id: string;
  priority: number;
  tag?: TagPreviewSmallFragment;
  configTemplate?: {
    id: string;
    name: string;
  } | null;
};
interface ModalProps {
  onClose: () => void;
  configTemplateTags: ConfigTemplateTags[];
  setConfigTemplateTags: (value: ConfigTemplateTags[]) => void;
  updateMappings: (configTemplateTags: ConfigTemplateTags[]) => void;
  mappingToEdit?: ConfigTemplateTag;
}

const TAG_PAGE_SIZE = 200;

const CreateTemplateMappingModal: React.FC<ModalProps> = ({
  onClose,
  configTemplateTags,
  setConfigTemplateTags,
  updateMappings,
  mappingToEdit,
}) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedTag, setSelectedTag] = useState<
    TagPreviewSmallFragment | undefined
  >(mappingToEdit?.tag);
  const [selectedConfigTemplate, setSelectedConfigTemplate] = useState<
    ConfigurationTemplate | undefined
  >(mappingToEdit?.configTemplate as ConfigurationTemplate);

  let tagSearchCursor: string | null | undefined;

  // This query feeds the list of available tags in the dropdown
  const {
    data: tagSearchData,
    error: tagSearchError,
    fetchMore: tagSearchFetchMore,
  } = useTagsQuery({
    variables: {
      input: {
        searchQuery,
        limit: TAG_PAGE_SIZE,
        cursor: tagSearchCursor,
      },
    },
  });
  const tagSearchList = tagSearchData?.tags.tags ?? [];
  tagSearchCursor = tagSearchData?.tags.cursor;

  const debouncedSearchQuery = useDebouncedValue(searchQuery, 250);

  const handleInputChange = (input: string) => {
    setSearchQuery(input);
  };

  const tagsNotInUse = tagSearchList.filter(
    (tag) =>
      !configTemplateTags.some(
        (configTemplateTag) => configTemplateTag.tag?.id === tag.id
      ) || mappingToEdit?.tag?.id === tag.id
  );

  return (
    <Modal
      title={`${mappingToEdit ? "Edit" : "Create"} Mapping`}
      isOpen
      onClose={onClose}
    >
      {tagSearchError ? (
        <UnexpectedErrorPage error={tagSearchError} />
      ) : (
        <>
          <Modal.Body>
            <FormGroup label="Select Tag">
              <Select<TagPreviewLargeFragment>
                options={tagsNotInUse}
                getOptionLabel={(tag) =>
                  tag.value ? `${tag.key}:${tag.value}` : tag.key || ""
                }
                getOptionSelected={(tag, value) => tag.id === value.id}
                value={selectedTag}
                onChange={(tag) => {
                  setSelectedTag(tag);
                }}
                onInputChange={handleInputChange}
                disableBuiltInFiltering // Search is already handled server-side
                loading={false}
                placeholder="Select a tag"
                getIcon={() => ({ type: "name", icon: "tag" })}
                onScrollToBottom={async () => {
                  // If user scrolls to bottom of dropdown, fetch the next batch of tags
                  if (tagSearchCursor) {
                    await tagSearchFetchMore({
                      variables: {
                        input: {
                          cursor: tagSearchCursor,
                          searchQuery: debouncedSearchQuery,
                          limit: TAG_PAGE_SIZE,
                        },
                      },
                    });
                  }
                }}
              />
            </FormGroup>
            <FormGroup label="Select Template">
              <ConfigurationTemplateDropdown
                selectedConfigurationTemplateId={selectedConfigTemplate?.id}
                onSelectConfigurationTemplate={(configTemplate) => {
                  setSelectedConfigTemplate(configTemplate);
                }}
              />
            </FormGroup>
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel={mappingToEdit ? "Save" : "Submit"}
            onPrimaryButtonClick={() => {
              let newMappings = [];
              if (mappingToEdit) {
                for (const ctt of configTemplateTags) {
                  if (ctt.id === mappingToEdit?.id) {
                    newMappings.push({
                      ...ctt,
                      tag: selectedTag,
                      configTemplate: selectedConfigTemplate,
                    });
                  } else {
                    newMappings.push(ctt);
                  }
                }
              } else {
                newMappings = [
                  ...configTemplateTags,
                  {
                    id: generateUniqueId("new-mapping"),
                    priority: configTemplateTags.length + 1,
                    tag: selectedTag,
                    configTemplate: selectedConfigTemplate,
                  } as ConfigTemplateTags,
                ];
              }
              updateMappings(newMappings);
              setConfigTemplateTags(newMappings);
              onClose();
            }}
            primaryButtonDisabled={!selectedTag || !selectedConfigTemplate}
            primaryButtonLoading={false}
            secondaryButtonLabel="Cancel"
            onSecondaryButtonClick={onClose}
            secondaryButtonDisabled={false}
          />
        </>
      )}
    </Modal>
  );
};

export default CreateTemplateMappingModal;
