import {
  AccessReviewPreviewFragment,
  useSendAccessReviewReminderMutation,
} from "api/generated/graphql";
import { useToast } from "components/toast/Toast";
import { Checkbox, Divider, FormGroup, Input, Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useState } from "react";
import { logError } from "utils/logging";

type SendReminderModalProps = {
  accessReview: AccessReviewPreviewFragment;
  onClose: () => void;
};

const FULL_DATE_TIME_FORMAT = Intl.DateTimeFormat([], {
  weekday: "short",
  year: "numeric",
  month: "short",
  day: "numeric",
  hour: "numeric",
  hour12: true,
  minute: "numeric",
  timeZoneName: "short",
});

export const SendReminderModal = (props: SendReminderModalProps) => {
  const [messageText, setMessageText] = useState("");
  const [includeManager, setIncludeManager] = useState(false);
  const { displayErrorToast, displaySuccessToast } = useToast();

  const [
    sendAccessReviewReminder,
    { loading },
  ] = useSendAccessReviewReminderMutation();

  const onSubmit = async () => {
    try {
      const { data } = await sendAccessReviewReminder({
        variables: {
          input: {
            accessReviewId: props.accessReview.id,
            message: messageText,
            notifyReviewerManager: includeManager,
          },
        },
      });
      switch (data?.sendAccessReviewReminder.__typename) {
        case "SendAccessReviewReminderResult":
          displaySuccessToast(
            "Success: reminder notifications sent to all reviewers with incomplete access reviews"
          );
          props.onClose();
          break;
        case "AccessReviewNotFoundError":
        case "AccessReviewAlreadyStoppedError":
          displayErrorToast(data.sendAccessReviewReminder.message);
          logError(new Error(data.sendAccessReviewReminder.message));
          break;
        default:
          displayErrorToast(
            `Error: failed to send access review notifications`
          );
          logError(
            new Error(
              `failed to send access review notifications (unknown response type)`
            )
          );
      }
    } catch (error) {
      displayErrorToast(`Error: failed to send access review notifications`);
      logError(error, `failed to send access review notifications`);
    }
  };

  return (
    <Modal
      isOpen={true}
      onClose={props.onClose}
      title="Send reminder to reviewers"
    >
      <Modal.Body>
        <p>
          Click "Send" below to send a reminder notification to all reviewers
          with incomplete access reviews. You may also include an optional
          message below.
        </p>

        <Input
          placeholder={"Enter your message here..."}
          value={messageText}
          onChange={setMessageText}
          type="textarea"
          rows={3}
        />

        <div className={sprinkles({ color: "gray600" })}>
          Last reminder sent:{" "}
          {props.accessReview.lastReminderAt
            ? FULL_DATE_TIME_FORMAT.format(
                new Date(props.accessReview.lastReminderAt)
              )
            : "Never"}
        </div>

        <Divider />

        <FormGroup label="Additional options">
          <Checkbox
            label="Notify reviewer's manager"
            checked={includeManager}
            onChange={setIncludeManager}
          />
        </FormGroup>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Send"
        onPrimaryButtonClick={onSubmit}
        primaryButtonLoading={loading}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={props.onClose}
      />
    </Modal>
  );
};
