import {
  ConnectionPreviewLargeFragment,
  Maybe,
  useDeleteConnectionMutation,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Banner, Modal } from "components/ui";
import React, { useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { logError, logWarning } from "utils/logging";

export type ConnectionDeleteModalProps = {
  connection: ConnectionPreviewLargeFragment;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

export const ConnectionDeleteModal = (props: ConnectionDeleteModalProps) => {
  const history = useHistory();
  const logEvent = useLogEvent();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [deleteConnections, { loading }] = useDeleteConnectionMutation();

  const deleteModalReset = () => {
    props.setShowModal(false);
    setErrorMessage(null);
  };

  const handleSubmit = async () => {
    logEvent({
      name: "apps_app_remove_click",
      properties: {
        connectionType: props.connection.connectionType,
      },
    });

    try {
      const { data } = await deleteConnections({
        variables: {
          input: {
            id: props.connection.id,
          },
        },
        refetchQueries: ["Connections", "AppsListColumn"],
        update: (cache, { data }) => {
          switch (data?.deleteConnection.__typename) {
            case "DeleteConnectionResult":
              cache.evict({ fieldName: "apps" });
              break;
          }
        },
      });
      switch (data?.deleteConnection.__typename) {
        case "DeleteConnectionResult":
          deleteModalReset();
          displaySuccessToast(
            `Success: "${props.connection.name}" app deleted`
          );
          history.replace("/apps");
          break;
        case "ConnectionNotFoundError":
          logWarning(new Error(data.deleteConnection.message));
          setErrorMessage(data.deleteConnection.message);
          break;
        default:
          logError(new Error(`failed to delete app`));
          setErrorMessage(`Error: failed to delete app`);
      }
    } catch (error) {
      logError(error, `failed to delete app`);
      setErrorMessage(`Error: failed to delete app`);
    }
  };

  return (
    <Modal
      title={`Remove ${props.connection.name} from Opal`}
      isOpen={props.showModal}
      onClose={deleteModalReset}
    >
      <Modal.Body>
        <p>
          <Banner
            message={`If your app has any expiring or on-call access provisioned through Opal,
            this access will become permanent on the end system once you remove the app.`}
            type="warning"
          />
        </p>
        <p>
          Are you sure you want to remove <b>{props.connection.name}</b> from
          Opal? This will remove all resources and groups associated with the
          app from Opal. This cannot be undone.
        </p>
        <p>
          Removing an app from Opal only disconnects Opal's integration and does
          not cause access to be removed from the end system. This includes
          access provisioned through Opal.
        </p>
        {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
      </Modal.Body>
      <Modal.Footer
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLabel="Remove"
        primaryButtonLoading={loading}
        primaryButtonType="error"
      />
    </Modal>
  );
};

export default ConnectionDeleteModal;
