import {
  EntityType,
  ThirdPartyProvider,
  Visibility,
} from "api/generated/graphql";
import { ResourceConfig } from "components/forms/common";
import BreakGlassUsersRow from "components/forms/rows/BreakGlassUsersRow";
import ConditionalConfigs from "components/forms/rows/conditional_config/ConditionalConfigs";
import ResourceTicketPropagationRow from "components/forms/rows/ResourceTicketPropagationRow";
import SlackChannelsRowV3 from "components/forms/rows/SlackChannelsRowV3";
import VisibilityRow from "components/forms/rows/VisibilityRow";
import FullscreenView from "components/layout/FullscreenView";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { Banner, Divider, FormGroup, Input, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { GroupOnCallSchedulesEdit } from "views/groups/oncall/GroupOnCallSchedulesModal";
import OrgContext from "views/settings/OrgContext";

interface Props {
  config: Partial<ResourceConfig>;
  onChange: (config: Partial<ResourceConfig>) => void;
  errors: string[];
}

const ConfigTemplateForm = (props: Props) => {
  const { config } = props;
  const { orgState } = useContext(OrgContext);
  const hasTicketPropagation = useFeatureFlag(
    FeatureFlag.ResourceTicketPropagation
  );

  const handleChange = (key: keyof ResourceConfig) => (
    val: ResourceConfig[keyof ResourceConfig]
  ) => {
    props.onChange({
      ...config,
      [key]: val,
    });
  };

  const isSlackIntegrationEnabled =
    orgState.orgThirdPartyIntegrations?.some(
      (integration) =>
        integration.thirdPartyProvider === ThirdPartyProvider.Slack
    ) ?? false;

  return (
    <>
      <FullscreenView.Sidebar>
        <FormGroup label="Name">
          <Input
            value={config.name}
            onChange={handleChange("name")}
            placeholder="Enter name"
          />
        </FormGroup>
        <FormGroup label="Admin">
          <OwnerDropdown
            selectedOwnerId={config.adminOwner?.id}
            onSelectOwner={handleChange("adminOwner")}
          />
        </FormGroup>
        {isSlackIntegrationEnabled && (
          <SlackChannelsRowV3
            mode="edit"
            messageChannels={config.messageChannels}
            adminOwnerName={config.adminOwner?.name ?? ""}
            onChange={handleChange("messageChannels")}
            resourceId={config.entityId}
          />
        )}
        <FormGroup
          label="Members on-call schedules"
          infoTooltip="Choose the on-call schedule(s) that define this group's members. This
        group's member list will be updated each time a shift changes."
        >
          <GroupOnCallSchedulesEdit
            onCallSchedules={config.onCallSchedules ?? []}
            onChange={handleChange("onCallSchedules")}
            isV3
          />
          <div className={sprinkles({ color: "gray700", fontSize: "textXs" })}>
            This only applies to groups
          </div>
        </FormGroup>
        <div className={sprinkles({ marginBottom: "md" })}>
          <BreakGlassUsersRow
            mode="edit"
            breakGlassUsers={config.breakGlassUsers}
            onChange={handleChange("breakGlassUsers")}
            isV3
          />
        </div>
        <div
          className={sprinkles({
            color: "gray700",
            fontSize: "textXs",
            marginBottom: "lg",
          })}
        >
          This only applies to groups
        </div>
        <FormGroup
          label="MFA to approve requests"
          infoTooltip="Require reviewers to have completed an MFA challenge in the past 5 minutes before approving a request for this resource."
        >
          <Switch
            label="Required"
            checked={config.requireMfaToApprove ?? false}
            onChange={(checked) => {
              handleChange("requireMfaToApprove")(checked);
            }}
          />
        </FormGroup>
        <FormGroup
          label="MFA to connect"
          infoTooltip="Require that users must complete a MFA challenge within 5 minutes before connecting to this resource."
        >
          <Switch
            label="Required"
            checked={config.requireMfaToConnect ?? false}
            onChange={(checked) => {
              handleChange("requireMfaToConnect")(checked);
            }}
          />
          <div
            className={sprinkles({
              color: "gray700",
              fontSize: "textXs",
              marginTop: "md",
            })}
          >
            This only applies to resources with Opal-managed sessions
          </div>
        </FormGroup>
        {hasTicketPropagation && (
          <ResourceTicketPropagationRow
            value={config.ticketPropagation}
            onChange={handleChange("ticketPropagation")}
            mode="edit"
            isV3
          />
        )}
      </FullscreenView.Sidebar>
      <FullscreenView.Content>
        {props.errors.map((error) => (
          <Banner type="error" message={error} marginBottom="md" />
        ))}
        <FormGroup label="Who can view and request this item">
          <VisibilityRow
            mode="edit"
            entityType={EntityType.Resource}
            visibility={config.visibility}
            visibilityGroups={config.visibilityGroups ?? []}
            parentResourceName={config.parentResourceName}
            parentResourceVisibility={config.parentResourceVisibility}
            parentResourceVisibilityGroups={
              config.parentResourceVisibilityGroups
            }
            onChangeVisibilityAndGroups={(val: {
              visibility?: Visibility;
              groupIds?: string[];
              setDoNotChange?: boolean;
            }) => {
              const { visibility, groupIds, setDoNotChange } = val;
              if (setDoNotChange) {
                props.onChange({
                  ...props.config,
                  visibility: undefined,
                  visibilityGroups: undefined,
                });
                return;
              }
              const visDict = visibility ? { visibility } : {};
              const visGroupsDict = groupIds
                ? { visibilityGroups: groupIds }
                : {};
              props.onChange({
                ...props.config,
                ...visDict,
                ...visGroupsDict,
              });
            }}
            contentOnly
            isV3
          />
        </FormGroup>
        <Divider margin="lg" />
        <ConditionalConfigs
          mode="edit"
          entityType={EntityType.Resource}
          requestConfigs={config.requestConfigs ?? []}
          onChange={handleChange("requestConfigs")}
          isViewingAsNonAdmin={false}
          customRequestNotification={config.customRequestNotification}
          onCustomRequestNotificationChange={handleChange(
            "customRequestNotification"
          )}
          isV3
        />
      </FullscreenView.Content>
    </>
  );
};

export default ConfigTemplateForm;
