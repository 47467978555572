import {
  AccessReviewGroupFragment,
  AccessReviewTab,
  EntityType,
  useOngoingAccessReviewSubtabStatsQuery,
} from "api/generated/graphql";
import EntityViewer from "components/entity_viewer/EntityViewer";
import { EntityViewerTabType } from "components/entity_viewer/EntityViewerTabs";
import React, { useContext, useState } from "react";
import { logError } from "utils/logging";
import { useReadUINotification } from "utils/notifications";
import AccessReviewContext from "views/access_reviews/AccessReviewContext";
import AccessReviewGroupActionButtons from "views/access_reviews/groups/AccessReviewGroupActionButtons";
import AccessReviewGroupEventsRow from "views/access_reviews/groups/AccessReviewGroupEventsRow";
import { AccessReviewGroupOverviewRow } from "views/access_reviews/groups/AccessReviewGroupOverviewRow";
import AccessReviewGroupResourcesRow from "views/access_reviews/groups/AccessReviewGroupResourcesRow";
import AccessReviewGroupTitleRow from "views/access_reviews/groups/AccessReviewGroupTitleRow";
import AccessReviewGroupUsersRow from "views/access_reviews/groups/AccessReviewGroupUsersRow";

import {
  getAccessReviewBadgeColor,
  getDeadlineProgressBarInfo,
} from "../common/utils";

type AccessReviewGroupViewerProps = {
  accessReviewGroup: AccessReviewGroupFragment;
};

export const AccessReviewGroupViewer = (
  props: AccessReviewGroupViewerProps
) => {
  useReadUINotification(props.accessReviewGroup.accessReviewId);
  const { accessReviewState } = useContext(AccessReviewContext);
  const [selectedTab, setSelectedTab] = useState<EntityViewerTabType>(
    EntityViewerTabType.Users
  );

  const { data, error } = useOngoingAccessReviewSubtabStatsQuery({
    variables: {
      input: {
        tab: AccessReviewTab.GroupsTab,
        itemId: props.accessReviewGroup.id,
        accessReviewId: props.accessReviewGroup.accessReview?.id ?? "",
      },
    },
  });
  let tabStats = null;
  if (data) {
    switch (data.ongoingAccessReviewSubtabStats.__typename) {
      case "OngoingAccessReviewSubtabStatsResult":
        tabStats =
          data.ongoingAccessReviewSubtabStats.ongoingAccessReviewSubtabStats ||
          null;
        break;
      case "AccessReviewNotFoundError":
      case "AccessReviewAlreadyStoppedError":
        break;
      default:
        logError(new Error(`failed to retrieve access review stats`));
    }
  } else if (error) {
    logError(error, `failed to retrieve access review stats`);
  }

  // bulk assign
  const [showBulkUpdateColumn, setShowBulkUpdateColumn] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Record<string, EntityType>>(
    {}
  );

  const deadlineInfo = getDeadlineProgressBarInfo(
    props.accessReviewGroup.accessReview
  );
  const badgeColor = getAccessReviewBadgeColor(deadlineInfo);

  return (
    <EntityViewer
      title={
        <AccessReviewGroupTitleRow
          accessReviewGroup={props.accessReviewGroup}
        />
      }
      tabInfos={[
        {
          tabType: EntityViewerTabType.Overview,
          tabContent: (
            <AccessReviewGroupOverviewRow
              accessReviewGroup={props.accessReviewGroup}
            />
          ),
        },
        {
          tabType: EntityViewerTabType.Users,
          tabContent: (
            <AccessReviewGroupUsersRow
              accessReviewGroup={props.accessReviewGroup}
              setSelectedTab={setSelectedTab}
              selectedRows={showBulkUpdateColumn ? selectedRows : undefined}
              setSelectedRows={
                showBulkUpdateColumn ? setSelectedRows : undefined
              }
            />
          ),
          tabCount:
            tabStats && tabStats.numUsersToReview > 0
              ? tabStats.numUsersToReview
              : undefined,
          badgeColor,
        },
        {
          tabType: EntityViewerTabType.Resources,
          tabContent: (
            <AccessReviewGroupResourcesRow
              accessReviewGroup={props.accessReviewGroup}
              setSelectedTab={setSelectedTab}
              selectedRows={showBulkUpdateColumn ? selectedRows : undefined}
              setSelectedRows={
                showBulkUpdateColumn ? setSelectedRows : undefined
              }
            />
          ),
          tabCount:
            tabStats && tabStats.numResourcesToReview > 0
              ? tabStats.numResourcesToReview
              : undefined,
          badgeColor,
        },
        {
          tabType: EntityViewerTabType.Events,
          tabContent: (
            <AccessReviewGroupEventsRow
              accessReviewGroup={props.accessReviewGroup}
            />
          ),
        },
      ]}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      button={
        accessReviewState.ongoingAccessReviewIdSet.has(
          props.accessReviewGroup.accessReviewId
        ) ? (
          <AccessReviewGroupActionButtons
            accessReviewGroup={props.accessReviewGroup}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            showBulkUpdateColumn={showBulkUpdateColumn}
            setShowBulkUpdateColumn={setShowBulkUpdateColumn}
          />
        ) : (
          <></>
        )
      }
    />
  );
};

export default AccessReviewGroupViewer;
