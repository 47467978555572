import { Checkbox } from "components/ui";
import {
  useFilterDispatch,
  useFilterState,
} from "components/viz/contexts/FilterContext";
import sprinkles from "css/sprinkles.css";
import React from "react";
import useLogEvent from "utils/analytics";

import GroupByDropdown from "../GroupByDropdown";
import * as styles from "./UserDetailsFilter.css";

const UserDetailsFilter = () => {
  const filterState = useFilterState();
  const dispatch = useFilterDispatch();
  const logEvent = useLogEvent();

  return (
    <div className={styles.userDetailsFilter}>
      <p>Group By</p>
      <GroupByDropdown />
      <div className={sprinkles({ marginTop: "lg" })}>Show</div>
      <div className={styles.userDetailsFilterInner}>
        <Checkbox
          size="sm"
          label="Unmanaged items"
          checked={Boolean(filterState.showUnmanagedItems)}
          onChange={(value) => {
            dispatch({
              type: "UPDATE_SHOW_UNMANAGED_ITEMS",
              payload: {
                value,
              },
            });
            logEvent({
              name: "viz_show_unmanaged_items_toggle",
              properties: {
                checked: value,
              },
            });
          }}
        />
        <Checkbox
          size="sm"
          label="Only highlighted results"
          checked={Boolean(filterState.showHighlightedOnly)}
          onChange={(value) => {
            dispatch({
              type: "UPDATE_SHOW_HIGHLIGHTED_ONLY",
              payload: {
                value,
              },
            });
            logEvent({
              name: "viz_highlighted_only_filter_toggle",
              properties: {
                checked: value,
              },
            });
          }}
        />
        <Checkbox
          size="sm"
          label="User email"
          checked={Boolean(filterState.attributesShown.email)}
          onChange={(checked) => {
            dispatch({
              type: "SET_SHOW_EMAIL",
              payload: { showEmail: checked },
            });
            logEvent({
              name: "viz_user_attribute_toggle",
              properties: {
                attributeName: "email",
                checked,
              },
            });
          }}
        />
        <Checkbox
          size="sm"
          label="User team"
          checked={Boolean(filterState.attributesShown.team)}
          onChange={(checked) => {
            dispatch({
              type: "SET_SHOW_TEAM",
              payload: { showTeam: checked },
            });
            logEvent({
              name: "viz_user_attribute_toggle",
              properties: {
                attributeName: "team",
                checked,
              },
            });
          }}
        />
        <Checkbox
          size="sm"
          label="User role"
          checked={Boolean(filterState.attributesShown.position)}
          onChange={(checked) => {
            dispatch({
              type: "SET_SHOW_POSITION",
              payload: { showPosition: checked },
            });
            logEvent({
              name: "viz_user_attribute_toggle",
              properties: {
                attributeName: "role",
                checked,
              },
            });
          }}
        />
      </div>
    </div>
  );
};

export default UserDetailsFilter;
