import { Checkbox, createStyles, WithStyles } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import { EventType } from "api/generated/graphql";
import clsx from "clsx";
import VirtualTableContext from "components/tables/material_table/VirtualTableContextProvider";
import sprinkles from "css/sprinkles.css";
import sort from "fast-sort";
import _ from "lodash";
import React, {
  CSSProperties,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import * as Icons from "react-feather";
import { useHistory } from "react-router-dom";
import {
  AutoSizer,
  Column,
  defaultTableRowRenderer,
  Index,
  IndexRange,
  InfiniteLoader,
  SortDirection,
  Table,
} from "react-virtualized";
import { TableRowRenderer } from "react-virtualized/dist/es/Table";

export type CellDataValue = string | number | EventType[];

type SortDirectionType = "ASC" | "DESC";

export type Header<Data> = {
  id: keyof Data & string;
  label: string;
  width: number; // flex-basis passed to react-virtualized Column component as a required prop
  minWidth: number; // Used to prevent column content from getting cut-off at smaller widths
  // This allows us to specify custom header. If an element is
  // specified it will be rendered instead of the regular header.
  // Note: We currently disable sorting for custom headers.
  customHeader?: React.ReactElement;
  // Defaults to true
  sortable?: boolean;
};

export type CellData = {
  sortValue?: CellDataValue;
  value: CellDataValue;
  element: React.ReactElement;
  isSelected?: boolean;
  clickHandler?: () => void;
};

interface BaseExpandableContent {
  isExpanded: boolean;
  setIsExpanded: (isExpanded: boolean) => void;
}

interface CustomExpandableContent extends BaseExpandableContent {
  content: React.ReactElement;
  expandedRowHeight: number;
}

interface ChildRows<Data> extends BaseExpandableContent {
  rowData: CellRow<Data>[];
}

export type ExpandableContent<Data> = CustomExpandableContent | ChildRows<Data>;

export type CellRow<Data> = {
  id: string;
  data: Record<keyof Data, CellData>;
  expandableContent?: ExpandableContent<Data>;
  rowIndex?: number;
  isChildRow?: boolean;
};

// Extending the props with MUI class props from tableStyles
interface MaterialTableProps<Data> extends WithStyles<typeof tableStyles> {
  columns: Header<Data>[];
  rows: CellRow<Data>[];
  defaultSortBy?: keyof Data & string;
  defaultSortDirection?: SortDirectionType;
  onSortChanged?: (rowIndex: number, rowId: string) => void;
  onSortOptionsChanged?: (
    sortBy: keyof Data,
    sortDirection: SortDirectionType
  ) => void;
  loadMoreRows?: (params: IndexRange) => Promise<void>;
  allRowsLoaded: boolean;
  checkedRowIds?: Set<string>;
  onCheckedRowsChange?: (ids: string[], checkboxState: boolean) => void;
  selectAllChecked?: boolean;
  onSelectAllChange?: (checkboxState: boolean) => void;
  // Allows the table to have expandable content. Each CellRow that has expandable content
  // needs to supply the optional expandableContent param.
  expandable?: boolean;
  rowHeight?: number;
  disableHeader?: boolean;
  disableForceScroll?: boolean;
  autoRowIndexes?: boolean;
}

// ScrollState is used to control the scrolling logic for VirtualTable.
export type ScrollState = {
  applyingScroll: boolean; // True when component is mounted or history changes, until scroll operations are done.
  lastRenderedIndex: number; // Minimum index that should be loaded to scroll correctly.
  scrollTop: number; // Top offset of the scroll window in pixels.
  locationKey: string; // locationKey stored in state to determine if location has changed.
  urlKey: string; // urlKey stored in state to determine if url has changed.
};

const tableStyles = () =>
  createStyles({
    flexContainer: {
      display: "flex",
      alignItems: "center",
      boxSizing: "border-box",
    },
    grid: {
      "&:focus": {
        outline: "none",
      },
    },
    headerRow: {
      outline: "none",
    },
    header: {
      border: 0,
      padding: "0px 0px 0px 16px",
      color: "#000000",
      fontSize: "14px",
      fontWeight: "bold",
      whiteSpace: "nowrap",
    },
    sortLabel: {
      width: "100%",
      "&:hover": {
        color: "#000000",
      },
    },
    tableRowHover: {
      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, 0.03)",
      },
    },
    tableCell: {
      flex: 1,
      border: 0,
      fontSize: "14px",
      padding: "0px 0px 0px 16px",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
    isSelected: {
      backgroundColor: "rgba(0, 0, 0, 0.03)",
    },
    isHoverPointer: {
      "&:hover": {
        cursor: "pointer",
      },
    },
    childRowCell: {
      marginLeft: "16px",
    },
  });

const VirtualTable = <Data,>(props: MaterialTableProps<Data>) => {
  const history = useHistory();
  const tableRef = useRef<Table>(null);

  const {
    rows: propRows,
    columns,
    classes,
    defaultSortBy,
    defaultSortDirection,
    loadMoreRows,
    allRowsLoaded,
    selectAllChecked,
    onSelectAllChange,
  } = props;
  const rowHeight = props.rowHeight || 48;

  let rows: CellRow<Data>[] = [];
  propRows.forEach((propRow) => {
    rows.push(propRow);
    if (
      propRow.expandableContent?.isExpanded &&
      "rowData" in propRow.expandableContent
    ) {
      propRow.expandableContent.rowData.forEach((childRowData) => {
        rows.push({ ...childRowData, isChildRow: true });
      });
    }
  });

  // For expandable rows we need to calculate the row
  // height based on the expanded state
  const calculateRowHeight = ({ index }: Index): number => {
    const row = rows[index];
    if (
      row.expandableContent?.isExpanded &&
      "expandedRowHeight" in row.expandableContent
    ) {
      return row.expandableContent.expandedRowHeight;
    }
    return rowHeight;
  };

  const headerHeight = 48;

  // displayRowCount is used by Table component to display already loaded rows.
  const displayRowCount = rows.length;
  // loaderRowCount is used by InfiniteLoader, corresponds to maximum rowCount that we know is possible so far
  // If allRowsLoaded is false we know there is at least one more row which is the cursor, so add 1 to count.
  const loaderRowCount = allRowsLoaded ? rows.length : rows.length + 1;

  const [sortBy, setSortBy] = React.useState<string | undefined>(defaultSortBy);
  const [sortDirection, setSortDirection] = React.useState<SortDirectionType>(
    defaultSortDirection ? defaultSortDirection : SortDirection.DESC
  );

  const { virtualTableInfoMap } = useContext(VirtualTableContext);

  // locationKey is the unique key assigned by React Router for each entry in history, defaults to pathname.
  const locationKey = history.location.key ?? history.location.pathname;
  // urlKey is the path+search parameters, used if the page is a new entry in history.
  const urlKey =
    history.location.pathname + history.location.hash + history.location.search;

  const [scrollState, setScrollState] = React.useState<ScrollState>({
    applyingScroll: false,
    lastRenderedIndex: 0,
    scrollTop: 0,
    locationKey: "",
    urlKey: "",
  });

  const [lastClickedCheckboxIndex, setLastClickedCheckboxIndex] = useState<
    number | null
  >(null);

  // When component mounts or history changes without mount/unmount, this should run to set correct scroll position.
  if (
    !props.disableForceScroll &&
    (scrollState.locationKey !== locationKey ||
      scrollState.locationKey === "" ||
      scrollState.urlKey !== urlKey ||
      scrollState.urlKey === "")
  ) {
    // If unique location was visited previously, use locationKey, otherwise use urlKey.
    const tableContext =
      virtualTableInfoMap.get(locationKey) ?? virtualTableInfoMap.get(urlKey);
    // If tableContext was found for either locationKey or urlKey, initiate scroll. Else force scroll to top.
    if (tableContext) {
      setScrollState({
        applyingScroll: true,
        lastRenderedIndex: tableContext.lastRenderedIndex,
        scrollTop: tableContext.scrollTop,
        locationKey: locationKey,
        urlKey: urlKey,
      });
    } else {
      setScrollState({
        applyingScroll: true,
        lastRenderedIndex: 0,
        scrollTop: 0,
        locationKey: locationKey,
        urlKey: urlKey,
      });
    }
    setLastClickedCheckboxIndex(null);
  }

  // Used by react-virtualized to get new rows to render
  const rowGetter = ({ index }: { index: number }) => rows[index];

  const compareByType: (field: CellDataValue) => CellDataValue = (
    field: CellDataValue
  ) => {
    switch (typeof field) {
      case "string":
        return field.toLowerCase();
      default:
        return field;
    }
  };

  const sortRows = ({
    sortBy,
    sortDirection,
  }: {
    sortBy: string;
    sortDirection: string;
  }) => {
    if (sortDirection === SortDirection.ASC) {
      const sortedParentRows = sort(propRows.slice()).asc((c) =>
        compareByType(
          c.data[sortBy as keyof Data].sortValue ||
            c.data[sortBy as keyof Data].value
        )
      );
      const sortedRows: CellRow<Data>[] = [];
      sortedParentRows.forEach((row) => {
        sortedRows.push(row);
        // Expandable, child rows should always appear after their parent rows
        if (
          row.expandableContent?.isExpanded &&
          "rowData" in row.expandableContent
        ) {
          const sortedChildRows = sort(row.expandableContent.rowData).asc((c) =>
            compareByType(
              c.data[sortBy as keyof Data].sortValue ||
                c.data[sortBy as keyof Data].value
            )
          );
          sortedRows.push(
            ...sortedChildRows.map((row) => ({ ...row, isChildRow: true }))
          );
        }
      });
      return sortedRows;
    }
    const sortedParentRows = sort(propRows.slice()).desc((c) =>
      compareByType(
        c.data[sortBy as keyof Data].sortValue ||
          c.data[sortBy as keyof Data].value
      )
    );
    const sortedRows: CellRow<Data>[] = [];
    sortedParentRows.forEach((row) => {
      sortedRows.push(row);
      // Expandable, child rows should always appear after their parent rows
      if (
        row.expandableContent?.isExpanded &&
        "rowData" in row.expandableContent
      ) {
        const sortedChildRows = sort(row.expandableContent.rowData).desc((c) =>
          compareByType(
            c.data[sortBy as keyof Data].sortValue ||
              c.data[sortBy as keyof Data].value
          )
        );
        sortedRows.push(
          ...sortedChildRows.map((row) => ({ ...row, isChildRow: true }))
        );
      }
    });
    return sortedRows;
  };

  // Called by react-virtualized everytime a sortable header is clicked
  const handleRequestSort = ({
    sortBy,
    sortDirection,
  }: {
    sortBy: string;
    sortDirection: SortDirectionType;
  }) => {
    setSortBy(sortBy);
    setSortDirection(sortDirection);
    props.onSortOptionsChanged?.(sortBy as keyof Data, sortDirection);
    setLastClickedCheckboxIndex(null);
    tableRef.current?.recomputeRowHeights();
  };

  // Used to apply hover style. Hover is not applied to index -1, as this is the header row
  const getRowClassName = ({ index }: { index: number }) => {
    return clsx(classes.flexContainer, {
      [classes.tableRowHover]: index !== -1!,
    });
  };

  // Used by react-virtualized to render non-header table cells
  const cellRenderer = ({
    cellData,
    columnIndex,
    rowData,
  }: {
    cellData?: CellData;
    columnIndex: number;
    rowData: CellRow<Data>;
  }) => {
    const { classes } = props;

    let styles: CSSProperties = { height: rowHeight };

    return (
      <TableCell
        component="div"
        className={clsx({
          [classes.tableCell]: true,
          [classes.flexContainer]: true,
          [classes.isSelected]: cellData?.isSelected,
          [classes.isHoverPointer]: Boolean(cellData?.clickHandler),
          [classes.childRowCell]: useCheckboxes
            ? rowData.isChildRow && columnIndex === 2
            : rowData.isChildRow && columnIndex === 1,
        })}
        variant="body"
        style={styles}
        align="left"
        onClick={() => {
          if (cellData?.clickHandler) {
            cellData.clickHandler();
          }
        }}
      >
        {cellData?.element ? cellData.element : cellData?.value}
      </TableCell>
    );
  };

  // Used by react-virtualized to render header cells
  const headerRenderer = ({
    label,
    id,
    customHeader,
    sortable,
  }: {
    label?: React.ReactNode;
    id: keyof Data;
    customHeader?: React.ReactElement;
    sortable: boolean;
  }) => {
    const { classes } = props;

    let headerContent: ReactNode = customHeader;
    if (!headerContent) {
      if (sortable) {
        // TableSortLabel uses lowercase direction, while react-virtualized Table uses uppercase direction
        const sortLabelDirection =
          sortDirection === SortDirection.ASC ? "asc" : "desc";

        headerContent = (
          <TableSortLabel
            className={classes.sortLabel}
            active={sortBy === id}
            direction={sortBy === id ? sortLabelDirection : "asc"}
          >
            {label}
          </TableSortLabel>
        );
      } else {
        headerContent = label;
      }
    }

    return (
      <TableCell
        component="div"
        className={clsx(classes.header, classes.flexContainer)}
        variant="head"
        style={{ height: headerHeight }}
        align="left"
      >
        {headerContent}
      </TableCell>
    );
  };

  // Used by InfiniteLoader, if return value false, InfiniteLoader will call loadMoreRows with the index.
  const isRowLoaded = ({ index }: { index: number }) => {
    // If the index is higher than displayRowCount, this is the cursor, so need to trigger page load.
    return index < displayRowCount;
  };

  // Load rows until lastRenderedIndex is loaded.
  if (
    scrollState.applyingScroll &&
    !isRowLoaded({ index: scrollState.lastRenderedIndex }) &&
    loadMoreRows
  ) {
    loadMoreRows({ startIndex: 0, stopIndex: 0 }).then();
  }

  // handleRowsRendered is called every time Table needs to render new rows. Used to save scroll position.
  const handleRowsRendered = useMemo(() => {
    return _.debounce(
      ({
        startIndex,
        stopIndex,
      }: {
        startIndex: number;
        stopIndex: number;
      }) => {
        if (virtualTableInfoMap && tableRef.current) {
          // Get the offset of the first row in the render window.
          const scrollTop = tableRef.current.getOffsetForRow({
            index: startIndex,
          });
          // Set both keys in the context to their new value.
          virtualTableInfoMap
            .set(locationKey, {
              lastRenderedIndex: stopIndex,
              scrollTop: scrollTop,
            })
            .set(urlKey, {
              lastRenderedIndex: stopIndex,
              scrollTop: scrollTop,
            });
        }
      },
      50 // wait 50ms to prevent performance issues.
    );
  }, [virtualTableInfoMap, locationKey, urlKey, tableRef]);

  const scrollToPosition =
    scrollState.applyingScroll &&
    isRowLoaded({ index: scrollState.lastRenderedIndex })
      ? scrollState.scrollTop
      : null;
  useEffect(() => {
    if (scrollToPosition !== null && tableRef.current) {
      tableRef.current.scrollToPosition(scrollToPosition);
      // Consume the scrollState
      setScrollState((prevState) => ({
        ...prevState,
        applyingScroll: false,
        lastRenderedIndex: 0,
        scrollTop: 0,
      }));
    }
  }, [scrollToPosition]);

  // Placeholder function does nothing, for tables without pagination
  const loadMoreRowsPlaceHolder = () => Promise.resolve();

  // For rows that have expandableContent, we use this to render the expandable
  // content if the row is expanded
  const rowRenderer: TableRowRenderer = (tableRowRendererProps) => {
    const { style, className, key, rowData } = tableRowRendererProps;
    if (props.expandable && rowData.expandableContent?.isExpanded) {
      if ("content" in rowData.expandableContent) {
        return (
          <div
            style={{ ...style, display: "flex", flexDirection: "column" }}
            className={className}
            key={key}
          >
            {defaultTableRowRenderer({
              ...tableRowRendererProps,
              style: { width: style.width, height: rowHeight },
            })}
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              {rowData.expandableContent.content}
            </div>
          </div>
        );
      }
    }
    return defaultTableRowRenderer(tableRowRendererProps);
  };

  // Sort the rows as required before rendering the component
  if (sortBy) {
    rows = sortRows({ sortBy, sortDirection });
  }

  const useCheckboxes = !!(props.checkedRowIds && props.onCheckedRowsChange);
  (useCheckboxes || props.autoRowIndexes) &&
    rows.forEach((row, index) => {
      row.rowIndex = index;
      props.onSortChanged && props.onSortChanged(row.rowIndex, row.id);
    });

  // For expandable tables, expandable content could grow, so we will
  // re-compute the row heights on every render for tables that are
  // expandable
  if (props.expandable) {
    tableRef.current?.recomputeRowHeights();
  }

  return (
    <AutoSizer onResize={() => tableRef.current?.recomputeRowHeights()}>
      {({ height, width }) => (
        <InfiniteLoader
          isRowLoaded={isRowLoaded}
          loadMoreRows={loadMoreRows || loadMoreRowsPlaceHolder}
          rowCount={loaderRowCount}
        >
          {({ onRowsRendered }) => (
            <Table
              ref={tableRef}
              // These -1 offsets are needed to correct a rounding issue where
              // AutoSizer will sometimes return a div that's larger than its
              // parent, causing an unwanted double scrollbar or blinking
              // scrollbar.
              // See: https://github.com/bvaughn/react-virtualized/issues/1287
              height={height - 1}
              width={width - 1}
              rowHeight={calculateRowHeight}
              headerHeight={headerHeight}
              rowCount={displayRowCount}
              rowGetter={rowGetter}
              sort={handleRequestSort}
              sortBy={sortBy}
              sortDirection={sortDirection}
              rowClassName={getRowClassName} // Used for all rows including header
              headerClassName={classes.headerRow} // Only used for header rows
              gridClassName={classes.grid} // Used for the underlying Grid component
              rowRenderer={rowRenderer}
              onRowsRendered={({ startIndex, stopIndex }) => {
                onRowsRendered({ startIndex, stopIndex });
                handleRowsRendered({ startIndex, stopIndex });
              }}
              disableHeader={props.disableHeader}
            >
              {useCheckboxes && (
                <Column
                  key={"checkbox"}
                  dataKey={"checkbox"}
                  label={"checkbox"}
                  headerRenderer={() => {
                    if (onSelectAllChange && selectAllChecked !== undefined) {
                      return (
                        <div
                          className={clsx(
                            classes.header,
                            classes.flexContainer
                          )}
                        >
                          <Checkbox
                            checked={selectAllChecked}
                            onChange={(event) => {
                              onSelectAllChange(event.target.checked);
                            }}
                            inputProps={{ "aria-label": "controlled" }}
                          />
                        </div>
                      );
                    }
                    return <></>;
                  }}
                  cellRenderer={cellRenderer}
                  cellDataGetter={({ rowData }) => {
                    const checkboxState =
                      props.checkedRowIds &&
                      props.checkedRowIds.has(rowData.id);

                    return {
                      value: checkboxState,
                      element: (
                        <Checkbox
                          checked={checkboxState}
                          onClick={(event) => {
                            if (
                              event.shiftKey &&
                              lastClickedCheckboxIndex !== null
                            ) {
                              const idsInRange = [];
                              if (
                                lastClickedCheckboxIndex <= rowData.rowIndex
                              ) {
                                for (
                                  let i = lastClickedCheckboxIndex;
                                  i <= rowData.rowIndex;
                                  i++
                                ) {
                                  idsInRange.push(rows[i].id);
                                }
                              } else if (
                                lastClickedCheckboxIndex > rowData.rowIndex
                              ) {
                                for (
                                  let i = lastClickedCheckboxIndex;
                                  i >= rowData.rowIndex;
                                  i--
                                ) {
                                  idsInRange.push(rows[i].id);
                                }
                              }
                              props.onCheckedRowsChange &&
                                props.onCheckedRowsChange(
                                  idsInRange,
                                  !checkboxState
                                );
                            } else {
                              props.onCheckedRowsChange &&
                                props.onCheckedRowsChange(
                                  [rowData.id],
                                  !checkboxState
                                );
                            }
                            setLastClickedCheckboxIndex(rowData.rowIndex);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                      ),
                    };
                  }} // This passes cellData argument to cellRenderer
                  flexGrow={1}
                  width={width} // flex-basis
                  minWidth={50}
                  maxWidth={50}
                  disableSort={true}
                />
              )}
              {props.expandable && (
                <Column
                  key={"collapse"}
                  dataKey={"collapse"}
                  label={"collapse"}
                  headerRenderer={() => <></>}
                  cellRenderer={cellRenderer}
                  cellDataGetter={({ rowData }) => {
                    if (!rowData.expandableContent) {
                      return;
                    }

                    const expanded = rowData.expandableContent.isExpanded;
                    const icon = expanded ? (
                      <Icons.ChevronUp strokeWidth={1} />
                    ) : (
                      <Icons.ChevronDown strokeWidth={1} />
                    );

                    return {
                      value: expanded,
                      element: (
                        <div
                          className={sprinkles({
                            display: "flex",
                            alignItems: "center",
                            cursor: "pointer",
                          })}
                          onClick={() => {
                            rowData.expandableContent.setIsExpanded(!expanded);
                            tableRef.current?.recomputeRowHeights();
                          }}
                        >
                          {icon}
                        </div>
                      ),
                    };
                  }} // This passes cellData argument to cellRenderer
                  flexGrow={1}
                  width={width} // flex-basis
                  minWidth={40}
                  maxWidth={45}
                  disableSort={true}
                />
              )}
              {columns.map(
                ({
                  id,
                  label,
                  width,
                  minWidth,
                  customHeader,
                  sortable = true,
                }) => {
                  return (
                    <Column
                      key={id}
                      dataKey={id}
                      label={label}
                      headerRenderer={(headerProps) =>
                        headerRenderer({
                          ...headerProps,
                          id: id,
                          customHeader: customHeader,
                          sortable,
                        })
                      }
                      cellRenderer={cellRenderer}
                      cellDataGetter={({ rowData }) => {
                        const data = rowData.data[id];
                        return data;
                      }} // This passes cellData argument to cellRenderer
                      flexGrow={1}
                      width={width} // flex-basis
                      minWidth={minWidth}
                      // Currently for simplicity custom headers are not sortable
                      // Shouldn't be too hard to make this configurable if the need comes up
                      disableSort={!!customHeader || !sortable}
                    />
                  );
                }
              )}
            </Table>
          )}
        </InfiniteLoader>
      )}
    </AutoSizer>
  );
};

// Need to pass MaterialTableProps which was extended with the MUI classes
const MuiVirtualTable = withStyles(
  tableStyles
  // This component will be deprecated soon in favor of components/ui/table,
  // so ignore this lint error for now.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
)(({ classes, ...others }: MaterialTableProps<any>) => (
  <VirtualTable classes={classes} {...others} />
));

// Similar to MuiVirtualTable, but allows horizontal scroll if the viewport is too small.
// Note: This currently requires that the parent has a height and is a flex component
export const ScrollableMuiVirtualTable = withStyles(tableStyles)(
  // This component will be deprecated soon in favor of components/ui/table,
  // so ignore this lint error for now.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ({ classes, columns, expandable, ...others }: MaterialTableProps<any>) => {
    let minWidth = columns.reduce(
      (width, column) => width + column.minWidth,
      0
    );

    if (expandable) {
      minWidth += 50;
    }

    return (
      <div
        style={{
          flex: 1,
          minHeight: "48px",
          minWidth: minWidth,
          overflowX: "auto",
        }}
      >
        <VirtualTable
          classes={classes}
          columns={columns}
          expandable={expandable}
          {...others}
        />
      </div>
    );
  }
);

export default MuiVirtualTable;
