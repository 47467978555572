import { Maybe, ResourcePreviewSmallFragment } from "api/generated/graphql";
import Label from "components/label/Label";
import { SectionEntry } from "components/viewer/Viewer";
import React from "react";

type ResourceRemoteNameRowProps = {
  resource?: Maybe<ResourcePreviewSmallFragment>;
};

export const ResourceRemoteIdRow = (props: ResourceRemoteNameRowProps) => {
  return (
    <SectionEntry
      title={"Remote ID"}
      tooltipText={`This is the ID of the resource in the end system.`}
    >
      <Label text={props.resource?.remoteId ?? props.resource?.id} />
    </SectionEntry>
  );
};

export default ResourceRemoteIdRow;
