import {
  AccessReviewResourceFragment,
  EntityType,
} from "api/generated/graphql";
import { EntityViewerTitle } from "components/entity_viewer/EntityViewer";
import { ResourceLabel } from "components/label/Label";
import { Breadcrumbs } from "components/ui";
import React from "react";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import {
  getDeletedIcon,
  getDeletedTooltip,
} from "views/access_reviews/common/Deleted";

type AccessReviewResourceTitleRowProps = {
  accessReviewResource: AccessReviewResourceFragment;
};

export const AccessReviewResourceTitleRow = (
  props: AccessReviewResourceTitleRowProps
) => {
  if (!props.accessReviewResource.resource) {
    return <></>;
  }

  let entityId;
  let warningTooltip;
  let warningIcon;
  let strikeThrough = false;
  if (props.accessReviewResource.resource.isDeleted) {
    warningTooltip = getDeletedTooltip("resource");
    warningIcon = getDeletedIcon();
    strikeThrough = true;
  } else {
    entityId = props.accessReviewResource.resource.id;
  }

  return (
    <EntityViewerTitle
      title={
        <ResourceLabel
          text={props.accessReviewResource.resource.name}
          entityType={EntityTypeDeprecated.Resource}
          entityId={entityId}
          icon={props.accessReviewResource.resource.iconUrl}
          resourceType={props.accessReviewResource.resource.resourceType}
          warningTooltipText={warningTooltip}
          warningIcon={warningIcon}
          strikeThrough={strikeThrough}
          iconLarge={true}
        />
      }
      subtitle={
        <Breadcrumbs
          breadcrumbInfos={[
            {
              name: "Access Reviews",
              entityId: {
                entityId: null,
                entityType: null,
              },
            },
            {
              name:
                props.accessReviewResource.accessReview?.name ||
                "Access Review",
              entityId: {
                entityId: props.accessReviewResource.accessReview?.id ?? null,
                entityType: EntityType.AccessReview,
              },
            },
          ]}
        />
      }
    />
  );
};

export default AccessReviewResourceTitleRow;
