import graphVizIcon from "assets/icons/graph-viz.svg";
import microsoft from "assets/logos/active-directory-logo.png";
import opalLogoStandaloneLight from "assets/logos/opal-logo-standalone-white.svg";
import slack from "assets/logos/slack-logo.svg";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";

export const customIconSet = {
  "default-avatar": defaultAvatarURL,
  "graph-viz": graphVizIcon,
  "opal-logo-light": opalLogoStandaloneLight,
  microsoft: microsoft,
  slack: slack,
};
