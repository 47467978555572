import { UserDropdownPreviewFragment } from "api/generated/graphql";
import { PaginatedUserDropdown } from "components/dropdown/PaginatedUserDropdown";
import { PillV3 } from "components/pills/PillsV3";
import { Banner, Checkbox, DataElementList, FormGroup } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { getUserAvatarIcon } from "views/users/utils";

const MAX_GROUP_LEADERS = 10;

interface Props {
  groupLeaderUsers?: UserDropdownPreviewFragment[];
  onChange: (groupLeaderUsers?: UserDropdownPreviewFragment[]) => void;
  isBulkEdit?: boolean;
}

// Only a V3 component!
const GroupLeaderUsersRow = (props: Props) => {
  const groupLeaderUsers = props.groupLeaderUsers ?? [];
  const sortedGroupLeaderUsers = groupLeaderUsers.slice().sort((a, b) => {
    return a.fullName.localeCompare(b.fullName);
  });
  const [isChecked, setIsChecked] = useState(groupLeaderUsers.length !== 0);
  const hasMaxGroupLeaders = groupLeaderUsers.length === MAX_GROUP_LEADERS;

  const handleCheckboxChange = () => {
    if (isChecked) {
      // Remove group users if the checkbox is currently checked and going to unchecked
      props.onChange([]);
    }
    setIsChecked(!isChecked);
  };

  const content = (
    <>
      {hasMaxGroupLeaders ? null : (
        <PaginatedUserDropdown
          value={undefined}
          onChange={(user) => {
            if (user != null && groupLeaderUsers.length < MAX_GROUP_LEADERS) {
              props.onChange([...groupLeaderUsers, user]);
            }
          }}
          hiddenUserIds={groupLeaderUsers.map((user) => user.id)}
          clearable={false}
          selectOnly
          popperHeight="sm"
          placeholder="Add User"
          disabled={hasMaxGroupLeaders}
        />
      )}
      {hasMaxGroupLeaders ? (
        <Banner
          message="A group cannot have more than 10 leaders."
          type={"warning"}
          marginBottom="md"
          marginTop="sm"
        />
      ) : null}
      {sortedGroupLeaderUsers.length > 0 && (
        <div className={sprinkles({ marginTop: "md" })}>
          <DataElementList>
            {sortedGroupLeaderUsers.map((user) => {
              return (
                <PillV3
                  key={user.id}
                  keyText={user.fullName ?? "--"}
                  icon={getUserAvatarIcon(user)}
                  pillColor="DeepOrange"
                  onRemove={() =>
                    props.onChange(
                      groupLeaderUsers.filter((u) => u.id !== user.id)
                    )
                  }
                />
              );
            })}
          </DataElementList>
        </div>
      )}
    </>
  );

  // Handle bulk edit mode with no label and no checkbox
  if (props.isBulkEdit) {
    return (
      <div>
        <p>
          Assign group leaders who can request on behalf of this group and
          manage its users.
        </p>
        {content}
      </div>
    );
  }

  // Normal mode!
  return (
    <>
      <div className={sprinkles({ paddingBottom: "sm" })}>
        <Checkbox
          label={
            "Assign group leaders who can request on behalf of this group and manage its users"
          }
          checked={isChecked}
          onChange={handleCheckboxChange}
        />
      </div>
      {isChecked ? (
        <FormGroup label="Add users as group leaders">{content}</FormGroup>
      ) : null}
    </>
  );
};

export default GroupLeaderUsersRow;
