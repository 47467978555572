import { useAccountDetailColumnQuery } from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeader";
import ColumnListItem, {
  ColumnListItemsSkeleton,
} from "components/column/ColumnListItem";
import { ContextMenu, Divider } from "components/ui";
import React, { useContext } from "react";
import { useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";

import {
  ACCESS_OPTION_URL_KEY,
  AppsContext,
  ITEM_TYPE_URL_KEY,
} from "./AppsContext";
import ItemsListSection from "./ItemsListSection";
import { appsBreadcrumb } from "./utils";

interface Props {
  accountId: string;
}

const AccountDetailColumn = (props: Props) => {
  let { accountId: accountIdFromUrl, appView } = useParams<
    Record<string, string>
  >();
  let { accountId } = props;
  accountId = accountId || accountIdFromUrl;

  const transitionTo = useTransitionTo({
    preserveQueries: [ACCESS_OPTION_URL_KEY, ITEM_TYPE_URL_KEY],
  });
  const logEvent = useLogEvent();

  const { isSelectMode, setIsSelectMode, clearSelectedItems } = useContext(
    AppsContext
  );

  const {
    data: resourceData,
    error: resourceError,
    loading: resourceLoading,
  } = useAccountDetailColumnQuery({
    variables: {
      id: accountId,
    },
  });

  const getResourceData = () => {
    if (resourceData) {
      switch (resourceData.resource.__typename) {
        case "ResourceResult":
          return {
            resource: resourceData.resource.resource,
            resourceNotFound: false,
          };
        case "ResourceNotFoundError":
          return { resourceNotFound: true, resource: null };
        default:
          logError(new Error(`failed to list resources`));
      }
    } else if (resourceError) {
      logError(resourceError, `failed to list resources`);
    }
    return { resource: null, resourceNotFound: false };
  };
  const { resource, resourceNotFound } = getResourceData();

  if (resourceNotFound) {
    return (
      <Column isContent>
        <NotFoundPage />
      </Column>
    );
  }

  if (resourceLoading) {
    return (
      <Column>
        <ColumnHeaderSkeleton />
        <Divider margin="md" />
        <ColumnListItemsSkeleton />
      </Column>
    );
  }

  if (resourceError) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={resourceError} />
      </Column>
    );
  }

  const connection = resource?.connection;
  if (!resource || !connection) {
    return null;
  }

  const menuOptions: PropsFor<typeof ContextMenu>["options"] = [
    {
      label: isSelectMode ? "Cancel bulk select" : "Bulk select",
      icon: { type: "name", icon: "check-square" },
      onClick: () => {
        if (isSelectMode) {
          clearSelectedItems();
        } else {
          logEvent({
            name: "apps_bulk_select_start",
            properties: {
              entryPoint: "account_detail",
            },
          });
          setIsSelectMode(true);
        }
      },
    },
  ];

  const showAccountInfo = resource.authorizedActions?.includes(
    AuthorizedActionManage
  );

  return (
    <Column>
      <ColumnHeader
        title={resource.name}
        icon={{ entityType: resource.resourceType, type: "entity" }}
        menuOptions={menuOptions}
        breadcrumbs={[
          appsBreadcrumb,
          {
            name: connection.name,
            to: `/apps/${connection.id}`,
          },
        ]}
      />
      <Divider margin="md" />
      {showAccountInfo ? (
        <>
          <ColumnListItem
            label="Account Information"
            icon={{ icon: "info-circle", type: "name" }}
            selected={Boolean(accountIdFromUrl && appView)}
            onClick={() =>
              transitionTo({
                pathname: `/apps/${connection.id}/account/${accountId}/overview`,
              })
            }
          />
          <Divider margin="md" />
        </>
      ) : null}
      <ItemsListSection appId={connection.id} accountId={accountId} />
    </Column>
  );
};

export default AccountDetailColumn;
