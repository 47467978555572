import { AccessReviewUserFragment, EntityType } from "api/generated/graphql";
import { EntityViewerTitle } from "components/entity_viewer/EntityViewer";
import { ResourceLabel } from "components/label/Label";
import { Breadcrumbs } from "components/ui";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import {
  getDeletedIcon,
  getDeletedTooltip,
} from "views/access_reviews/common/Deleted";

type AccessReviewUserTitleRowProps = {
  accessReviewUser: AccessReviewUserFragment;
};

export const AccessReviewUserTitleRow = (
  props: AccessReviewUserTitleRowProps
) => {
  if (!props.accessReviewUser.user) {
    return <></>;
  }

  let entityId;
  let avatar;
  let warningTooltip;
  let warningIcon;
  let strikeThrough = false;
  if (props.accessReviewUser.user.isDeleted) {
    warningTooltip = getDeletedTooltip("user");
    warningIcon = getDeletedIcon();
    strikeThrough = true;
  } else {
    entityId = props.accessReviewUser.userId;
    avatar = props.accessReviewUser.user.avatarUrl;
  }

  return (
    <EntityViewerTitle
      title={
        <ResourceLabel
          text={props.accessReviewUser.user.fullName}
          entityType={EntityTypeDeprecated.User}
          entityId={entityId}
          avatar={avatar}
          warningTooltipText={warningTooltip}
          warningIcon={warningIcon}
          strikeThrough={strikeThrough}
        />
      }
      subtitle={
        <Breadcrumbs
          breadcrumbInfos={[
            {
              name: "Access Reviews",
              entityId: {
                entityId: null,
                entityType: null,
              },
            },
            {
              name:
                props.accessReviewUser.accessReview?.name || "Access Review",
              entityId: {
                entityId: props.accessReviewUser.accessReview?.id ?? null,
                entityType: EntityType.AccessReview,
              },
            },
          ]}
        />
      }
    />
  );
};

export default AccessReviewUserTitleRow;
