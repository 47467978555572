import ColumnContent from "components/column/ColumnContent";
import { useParams } from "react-router";
import ConnectionEventsRow from "views/connections/viewer/rows/ConnectionEventsRow";

import AppHeader from "./AppHeader";
import AppsContentColumn from "./AppsContentColumn";

const AppEventsColumn = () => {
  const { appId } = useParams<Record<string, string>>();

  return (
    <AppsContentColumn>
      <AppHeader />
      <ColumnContent>
        <ConnectionEventsRow connectionId={appId} />
      </ColumnContent>
    </AppsContentColumn>
  );
};

export default AppEventsColumn;
