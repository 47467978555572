import {
  useCreateGroupBindingsMutation,
  useGroupBindingGroupUserIdsQuery,
  useUpdateGroupBindingsMutation,
} from "api/generated/graphql";
import { useToast } from "components/toast/Toast";
import _ from "lodash";
import { useState } from "react";
import useLogEvent from "utils/analytics";
import { logError } from "utils/logging";
import GroupBindingEditModalBase, {
  GroupBindingState,
} from "views/group_bindings/modals/GroupBindingEditModalBase";

type Props = {
  groupId: string;
  isOpen: boolean;
  onModalClose: () => void;
};

const GroupBindingManualLinkModal = (props: Props) => {
  const { displaySuccessToast, displayErrorToast } = useToast();
  const [errorMessage, setErrorMessage] = useState("");
  const logEvent = useLogEvent();

  const { data, loading, error } = useGroupBindingGroupUserIdsQuery({
    variables: {
      input: [props.groupId],
    },
  });

  const [
    createGroupBinding,
    { loading: loadingCreate },
  ] = useCreateGroupBindingsMutation({
    refetchQueries: ["GroupBindingsCounts", "GroupBindingsTable"],
  });

  const [
    updateGroupBinding,
    { loading: loadingUpdate },
  ] = useUpdateGroupBindingsMutation({
    refetchQueries: ["GroupBindingsCounts", "GroupBindingsTable"],
  });

  if (error) {
    logError(error, "failed to load group");
    displayErrorToast("Failed to load group");
    return null;
  }

  const groupById = _.keyBy(data?.groups.groups, "id");
  const currentGroup = groupById[props.groupId];

  const onModalSubmit = async (bindings: GroupBindingState[]) => {
    const invalidBindings = bindings.filter(
      (binding) => !binding.sourceGroup || binding.groups.length === 0
    );
    if (invalidBindings.length > 0) {
      setErrorMessage(
        "Please select a source of truth and at least one linked group for each binding."
      );
      return;
    }
    setErrorMessage("");

    const bindingsToCreate = bindings.filter((binding) => binding.id === "");
    const bindingsToUpdate = bindings.filter((binding) => binding.id !== "");

    if (bindingsToCreate.length > 0) {
      const result = await createGroupBinding({
        variables: {
          input: {
            groupBindings: bindingsToCreate.map((binding) => ({
              sourceGroupId: binding.sourceGroup?.id ?? props.groupId,
              groupIds: binding.groups.map((g) => g.id),
            })),
          },
        },
      });

      if (result.errors || !result.data) {
        setErrorMessage("Failed to link groups, please try again.");
        return;
      }

      const data = result.data?.createGroupBindings;
      switch (data?.__typename) {
        case "CreateGroupBindingsResult":
          displaySuccessToast("Successfully linked groups");
          logEvent({
            name: "group_bindings_create",
            properties: { fromSuggestion: false },
          });
          props.onModalClose();
          break;
        case "GroupAlreadyBelongsToBindingError":
          if (data.group?.name && data.groupBinding?.sourceGroup?.name) {
            setErrorMessage(
              `Group ${data.group?.name} is already being linked to ${data.groupBinding?.sourceGroup?.name}. ` +
                `Use the "Add to existing linked groups" option to add this group.`
            );
          } else {
            setErrorMessage(
              "Failed to link groups: one of the groups is already linked"
            );
          }
          break;
        case "GroupBindingHasNoGroupsError":
          setErrorMessage(
            "Failed to link groups: no groups specified in the binding"
          );
          break;
      }
    } else if (bindingsToUpdate.length > 0) {
      const result = await updateGroupBinding({
        variables: {
          input: {
            groupBindings: bindingsToUpdate.map((binding) => ({
              id: binding.id,
              sourceGroupId: binding.sourceGroup?.id ?? props.groupId,
              groupIds: binding.groups.map((g) => g.id),
            })),
          },
        },
      });

      if (result.errors || !result.data) {
        setErrorMessage("Failed to link groups, please try again.");
        return;
      }

      const data = result.data?.updateGroupBindings;
      switch (data?.__typename) {
        case "UpdateGroupBindingsResult":
          displaySuccessToast("Successfully linked groups");
          logEvent({ name: "group_bindings_edit" });
          props.onModalClose();
          break;
        case "GroupAlreadyBelongsToBindingError":
          if (data.group?.name && data.groupBinding?.sourceGroup?.name) {
            setErrorMessage(
              `Group ${data.group?.name} is already being linked to ${data.groupBinding?.sourceGroup?.name}`
            );
          } else {
            setErrorMessage(
              "Failed to link groups: one of the groups is already linked"
            );
          }
          break;
        case "GroupBindingHasNoGroupsError":
          setErrorMessage(
            "Failed to link groups: no groups specified in the binding"
          );
          break;
      }
    }
  };

  return (
    <GroupBindingEditModalBase
      errorMessage={errorMessage}
      loading={loading || loadingCreate || loadingUpdate}
      allowMergingExistingBindings
      initialGroupBindings={
        currentGroup
          ? [
              {
                id: "",
                sourceGroup: currentGroup,
                groups: [currentGroup],
              },
            ]
          : []
      }
      isOpen={props.isOpen}
      onModalClose={props.onModalClose}
      title={`Link groups to ${currentGroup?.name}`}
      subtitle={
        "Linking one or more groups to this group will combine the data for the groups and only show the selected source of truth group in the app, until they are unlinked."
      }
      submitButtonLabel={"Link groups"}
      onModalSubmit={onModalSubmit}
    />
  );
};

export default GroupBindingManualLinkModal;
