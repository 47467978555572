import {
  GroupTagPreviewFragment,
  ResourceTagPreviewFragment,
  TagPreviewLargeFragment,
  UserTagPreviewFragment,
  useTagsQuery,
} from "api/generated/graphql";
import { TagPill } from "components/pills/TagPills";
import { FormRow, Select, Skeleton, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useContext, useState } from "react";
import { useAutoPaginatingTagsQuery } from "utils/hooks";
import { AppsContext } from "views/apps/AppsContext";

import { FormMode } from "../common";

interface Props {
  mode: FormMode;
  title?: string;
  selectedTagIds?: string[];
  onChange?: (tagsIds?: string[]) => void;
  tagsWithSource?: Array<
    | ResourceTagPreviewFragment
    | GroupTagPreviewFragment
    | UserTagPreviewFragment
  >;
  disabled?: boolean;
  includeTitle?: boolean;
}

const PAGE_SIZE = 200;

const TagsRow = (props: Props) => {
  const { selectedTagIds = [], includeTitle = true } = props;

  const { bulkMode } = useContext(AppsContext);

  const [searchQuery, setSearchQuery] = useState("");
  let tagSearchCursor: string | null | undefined;

  // If we have existing tags on this item, let's fetch them first, by ids
  // but we'll use a hook that fetches all matching tags, auto-paginating
  const {
    data: existingTagsData,
    error: existingTagsError,
    loading: existingTagsLoading,
  } = useAutoPaginatingTagsQuery({
    variables: {
      input: {
        tagIds: selectedTagIds,
      },
    },
    skip: selectedTagIds.length === 0,
    fetchPolicy: "no-cache", // works around a caching conflict with the second query below
  });

  // This query feeds the list of available tags in the dropdown in edit mode
  const {
    data: tagSearchData,
    error: tagSearchError,
    fetchMore: tagSearchFetchMore,
  } = useTagsQuery({
    variables: {
      input: {
        searchQuery,
        limit: PAGE_SIZE,
        cursor: tagSearchCursor,
      },
    },
  });

  const tagSearchList = tagSearchData?.tags.tags ?? [];
  tagSearchCursor = tagSearchData?.tags.cursor;

  if (existingTagsLoading) {
    const content = _.times(Math.min(selectedTagIds.length, 1), () => (
      <Skeleton width="120px" />
    ));
    return (
      <FormRow title={props.title ? props.title : "Tags"}>{content}</FormRow>
    );
  }

  if (existingTagsError || tagSearchError) {
    return (
      <FormRow title={props.title ? props.title : "Tags"}>
        Failed to get tags
      </FormRow>
    );
  }

  const tagsById: { [tagId: string]: TagPreviewLargeFragment } = {};
  (existingTagsData?.tags.tags ?? []).forEach((tag) => {
    tagsById[tag.id] = tag;
  });
  tagSearchList.forEach((tag) => {
    tagsById[tag.id] = tag;
  });

  const handleSearchInputChange = _.debounce((s: string) => {
    setSearchQuery(s);
  }, 250);

  const handleInputChange = (input: string) => {
    handleSearchInputChange(input);
  };

  const viewContent =
    selectedTagIds.length > 0 ? (
      <div
        className={sprinkles({ display: "flex", gap: "sm", flexWrap: "wrap" })}
      >
        {selectedTagIds.filter(Boolean).map((tagId) => {
          const tag = tagsById[tagId];
          if (!tag) {
            return null;
          }

          const source = props.tagsWithSource?.find(
            (tag) => tag.tagId === tagId
          )?.source;

          return (
            <TagPill tagId={tag.id} tag={tag} sourceServiceType={source} />
          );
        })}
      </div>
    ) : (
      "--"
    );

  // Filter our existing tags out of what we'll show as available tags in the dropdown
  const filteredTags = tagSearchList.filter(
    (tag) => !selectedTagIds.includes(tag.id)
  );

  const editContent = (
    <>
      {bulkMode === "edit" && (
        <div className={sprinkles({ marginBottom: "md" })}>
          <Switch
            label="Leave unchanged"
            checked={props.selectedTagIds === undefined}
            onChange={(val) =>
              props.onChange && props.onChange(val ? undefined : [])
            }
            rightAlign
          />
        </div>
      )}
      <Select<TagPreviewLargeFragment>
        options={filteredTags}
        getOptionLabel={(tag) =>
          tag.value ? `${tag.key}:${tag.value}` : tag.key || ""
        }
        onChange={(tag) => {
          if (tag && props.onChange) {
            props.onChange([...selectedTagIds, tag.id]);
            // Selecting an option will briefly populate the search input with the option's label.
            // But we want to blank out the search input, so we call the debounced handler here
            handleSearchInputChange("");
          }
        }}
        onInputChange={handleInputChange}
        selectOnly
        placeholder="Select a tag to add"
        disableBuiltInFiltering // Search is already handled server-side
        onScrollToBottom={async () => {
          // If user scrolls to bottom of dropdown, fetch the next batch of tags
          if (tagSearchCursor) {
            await tagSearchFetchMore({
              variables: {
                input: {
                  cursor: tagSearchCursor,
                  searchQuery: searchQuery,
                  limit: PAGE_SIZE,
                },
              },
            });
          }
        }}
      />
      <div
        className={sprinkles({
          display: "flex",
          gap: "sm",
          flexWrap: "wrap",
          marginTop: "md",
        })}
      >
        {selectedTagIds.map((tagId) => {
          const tag = tagsById[tagId];
          if (!tag) {
            return null;
          }

          const source = props.tagsWithSource?.find(
            (tag) => tag.tagId === tagId
          )?.source;

          return (
            <TagPill
              tagId={tag.id}
              tag={tag}
              clearable
              onRemove={() => {
                if (props.onChange)
                  props.onChange(
                    selectedTagIds.filter((tagId) => tagId !== tag.id)
                  );
              }}
              sourceServiceType={source}
            />
          );
        })}
      </div>
    </>
  );

  if (!includeTitle) {
    return (
      <>{props.mode === "view" || props.disabled ? viewContent : editContent}</>
    );
  }

  return (
    <FormRow title={props.title ? props.title : "Tags"}>
      {props.mode === "view" || props.disabled ? viewContent : editContent}
    </FormRow>
  );
};

export default TagsRow;
