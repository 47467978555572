import {
  EntityType,
  GroupAccessLevel,
  GroupUserAccessFragment,
  Maybe,
} from "api/generated/graphql";
import AccessPathLabel from "components/label/AccessPathLabel";
import AccessPointsLabel, {
  GroupForAccessLabel,
  UserForAccessLabel,
} from "components/label/AccessPointsLabel";
import EventLabel from "components/label/item_labels/EventLabel";
import { ResourceLabel } from "components/label/Label";
import MaterialTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MaterialTable";
import sprinkles from "css/sprinkles.css";
import React from "react";

import { IconData } from "../../components/ui/utils";

interface GroupUserAccessPointRow {
  event: string;
  accessPath: string;
}

type GroupUserAccessPointsLabelProps = {
  accessPathsInfo: GroupUserAccessPathsInfo;
  user: Maybe<UserForAccessLabel> | undefined;
  group: Maybe<GroupForAccessLabel> | undefined;
  role?: GroupAccessLevel;
  icon?: IconData;
};

const accessPathColMinWidth = "400px";
const accessPathColMaxWidth = "800px";

export const GroupUserAccessPointsLabel = (
  props: GroupUserAccessPointsLabelProps
) => {
  const headers: Header<GroupUserAccessPointRow>[] = [
    { id: "event", label: "Event" },
    { id: "accessPath", label: "Access Path" },
  ];

  const numAccessPoints = props.accessPathsInfo.accessPointRows.length;
  if (numAccessPoints === 0) {
    return <div>{"--"}</div>;
  }

  return (
    <AccessPointsLabel
      subject={props.user}
      object={props.group}
      roleName={props.role?.accessLevelName}
      hasDirectAccess={props.accessPathsInfo.hasDirectAccess}
      numAccessPoints={numAccessPoints}
      numInheritedAccessPoints={props.accessPathsInfo.numInheritedAccessPoints}
      numGroupAccessPoints={0}
      accessPathContent={
        <div className={sprinkles({ padding: "sm" })}>
          <MaterialTable<GroupUserAccessPointRow>
            headers={headers}
            rows={props.accessPathsInfo.accessPointRows}
            denseFormatting={true}
            tableContainerStyles={{ maxHeight: 300 }}
          />
        </div>
      }
      useIndirectType={true}
      isNotClickable={true}
      icon={props.icon}
    />
  );
};

export type GroupUserAccessPathsInfo = {
  accessPointRows: CellRow<GroupUserAccessPointRow>[];
  numInheritedAccessPoints: number;
  hasDirectAccess: boolean;
};

type GroupUserAccessPathsInfoParams = {
  user: Maybe<UserForAccessLabel> | undefined;
  access: Maybe<GroupUserAccessFragment> | undefined;
  group: Maybe<GroupForAccessLabel> | undefined;
};

export const getGroupUserAccessPathsInfo = (
  params: GroupUserAccessPathsInfoParams
) => {
  const accessPointRows: CellRow<GroupUserAccessPointRow>[] = [];
  const directUserAccess = params.access?.directAccessPoint;

  if (directUserAccess) {
    accessPointRows.push({
      id: directUserAccess.groupId,
      data: {
        event: {
          value: directUserAccess.eventId,
          element: <EventLabel eventId={directUserAccess.eventId} />,
        },
        accessPath: {
          value: `${directUserAccess.groupId}-access-path`,
          element: (
            <AccessPathLabel
              accessLabels={[
                <ResourceLabel
                  entityTypeNew={EntityType.User}
                  entityId={params.user?.id}
                  avatar={params.user?.avatarUrl}
                  text={params.user?.fullName}
                  maxChars={24}
                />,
                <ResourceLabel
                  entityTypeNew={EntityType.Group}
                  entityId={params.group?.id}
                  text={params.group?.name}
                  groupType={params.group?.groupType}
                  maxChars={24}
                />,
              ]}
              maxWidth={accessPathColMaxWidth}
            />
          ),
          style: { minWidth: accessPathColMinWidth },
        },
      },
    });
  }

  params.access?.indirectAccessPointPaths.forEach((indirectAccessPointPath) => {
    const resolvedAccessPoint = indirectAccessPointPath.resolvedAccessPoint;

    const accessPathLabels = [
      <ResourceLabel
        key={params.user?.id}
        entityTypeNew={EntityType.User}
        entityId={params.user?.id}
        avatar={params.user?.avatarUrl}
        text={params.user?.fullName}
        maxChars={32}
      />,
      ...indirectAccessPointPath.indirectAccessPointPath.map(
        (indirectAccessPoint) => {
          return (
            <ResourceLabel
              key={indirectAccessPoint.groupId}
              entityTypeNew={EntityType.Group}
              entityId={indirectAccessPoint.groupId}
              text={indirectAccessPoint.groupName}
              groupType={indirectAccessPoint.groupType}
              maxChars={32}
            />
          );
        }
      ),
    ];

    accessPointRows.push({
      id: resolvedAccessPoint.groupId,
      data: {
        event: {
          value: resolvedAccessPoint.eventId,
          element: <EventLabel eventId={resolvedAccessPoint.eventId} />,
        },
        accessPath: {
          value: `${params.group?.id}-access-path`,
          element: (
            <AccessPathLabel
              accessLabels={accessPathLabels}
              maxWidth={accessPathColMaxWidth}
            />
          ),
          style: { minWidth: accessPathColMinWidth },
        },
      },
    });
  });

  return {
    accessPointRows,
    hasDirectAccess: !!directUserAccess,
    numInheritedAccessPoints:
      params.access?.indirectAccessPointPaths.length || 0,
  };
};
