import { gql, useFragment } from "@apollo/client";
import { RequestedItemsFragment } from "api/generated/graphql";
import GroupDisplay from "components/opal/common/entity/GroupDisplay";
import ResourceDisplay from "components/opal/common/entity/ResourceDisplay";
import RequestedItemCard from "components/requests/RequestedItemCard";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";

import * as styles from "./RequestedItems.css";

const RequestedItems = ({ requestId }: { requestId: string }) => {
  const { data, complete } = useFragment<RequestedItemsFragment>({
    fragment: RequestedItems.fragment,
    fragmentName: "RequestedItems",
    from: {
      __typename: "Request",
      id: requestId,
    },
  });

  if (!complete) {
    return null;
  }

  const { requestedResources, requestedGroups } = data;
  const totalNumItems = requestedResources.length + requestedGroups.length;

  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        gap: "md",
      })}
    >
      <div className={styles.header}>
        {pluralize("Requested Item", totalNumItems, true)}
      </div>
      {requestedResources?.map((requestedResource) => {
        return (
          <RequestedItemCard
            accessLevel={requestedResource.accessLevel ?? undefined}
            key={
              requestedResource.resourceId +
              requestedResource.accessLevel?.accessLevelRemoteId
            }
          >
            <ResourceDisplay resourceId={requestedResource.resourceId} />
          </RequestedItemCard>
        );
      })}
      {requestedGroups?.map((requestedGroup) => {
        return (
          <RequestedItemCard
            accessLevel={requestedGroup.accessLevel ?? undefined}
            key={
              requestedGroup.groupId +
              requestedGroup.accessLevel?.accessLevelRemoteId
            }
          >
            <GroupDisplay groupId={requestedGroup.groupId} />
          </RequestedItemCard>
        );
      })}
    </div>
  );
};

RequestedItems.fragment = gql`
  fragment RequestedItems on Request {
    id
    requestedResources {
      resourceId
      resource {
        ...ResourceDisplay
      }
      accessLevel {
        accessLevelRemoteId
        accessLevelName
      }
    }
    requestedGroups {
      groupId
      group {
        ...GroupDisplay
      }
      accessLevel {
        accessLevelRemoteId
        accessLevelName
      }
    }
  }
  ${ResourceDisplay.fragment}
  ${GroupDisplay.fragment}
`;

export default RequestedItems;
