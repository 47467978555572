import { gql, useQuery } from "@apollo/client";
import { getModifiedErrorMessage } from "api/ApiContext";
import {
  EntityType,
  HrIdpStatus,
  Maybe,
  OidcProviderType,
  PaginatedResourceUsersFragmentDoc,
  RemoveResourceUserInput,
  ResourcePreviewSmallFragmentDoc,
  ResourceUserFiltersInput,
  ResourceUserSortByField,
  ResourceUsersTableQuery,
  UpdateResourceUserInput,
  useInitOidcAuthFlowMutation,
  useRemoveResourceUsersMutation,
  useUpdateUserResourcesMutation,
} from "api/generated/graphql";
import { ResourceLabel, TimeLabel } from "components/label/Label";
import { BulkUpdateExpirationModal } from "components/modals/BulkUpdateExpirationModal";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OpalTable, {
  Columns,
  useSelectableOpalTable,
  useServerSortableOpalTable,
} from "components/opal/table/OpalTable";
import PropagationStatusLabelWithModal from "components/propagation/PropagationStatusLabelWithModal";
import { useToast } from "components/toast/Toast";
import {
  ButtonV3,
  Checkbox,
  Icon,
  Input,
  Modal,
  Select,
  Tooltip,
} from "components/ui";
import _ from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import { useState } from "react";
import { useHistory } from "react-router";
import { useImmer } from "use-immer";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage, generateState } from "utils/auth/auth";
import {
  isSnowflakeResource,
  resourceTypeHasRoles,
} from "utils/directory/resources";
import { useDebouncedValue, useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";
import {
  clearOidcData,
  getOidcData,
  OidcPostAuthAction,
  setOidcData,
} from "utils/oidc/oidc";
import { useTransitionTo } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { PropagationType } from "utils/useRemediations";
import { AVAILABLE_PROPAGATION_STATUSES } from "views/Common";
import { expirationValueToDurationInMinutes } from "views/requests/utils";
import { formatHrIdpStatus } from "views/users/utils";

import { ResourceUserAccessPointsLabel } from "./ResourceUserAccessPointsLabel";
import * as styles from "./ResourceUsersTableV3.css";

type ResourceUser = Extract<
  ResourceUsersTableQuery["resource"],
  { __typename?: "ResourceResult" }
>["resource"]["paginatedResourceUsers"]["resourceUsers"][0];

// rewrite/fork of ResourceUsersTableV3 under FeatureFlag.OpalTable
export const ResourceUsersOpalTable = (props: { resourceId: string }) => {
  const transitionTo = useTransitionTo();
  const { sortByVariable, sortByTableProps } = useServerSortableOpalTable({
    id: ResourceUserSortByField.UserFullName,
    desc: false,
  });

  const {
    selectedRows,
    resetSelected,
    selectableOpalTableProps,
  } = useSelectableOpalTable<ResourceUser>({
    bulkActions: (
      <>
        <ButtonV3
          label="Update Expiration"
          type="mainSecondary"
          onClick={() => setShowUpdateExpirationModal(true)}
          leftIconName="clock"
        />
        <ButtonV3
          label="Remove"
          type="danger"
          onClick={() => setShowRemoveModal(true)}
          leftIconName="trash"
        />
      </>
    ),
    getUnselectableReason: (user) => {
      if (!user.access?.directAccessPoint) {
        return "The user has no direct access to remove";
      }
      return undefined;
    },
  });

  const selectedItemIds: string[] = [];
  Object.keys(selectedRows).forEach(
    (id) => selectedRows[id] && selectedItemIds.push(id)
  );

  const [removeUsersErrorMessage, setRemoveUsersErrorMessage] = useState<
    Maybe<string>
  >(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const [
    updateExpirationErrorMessage,
    setUpdateExpirationErrorMessage,
  ] = useState<string>("");
  const [showUpdateExpirationModal, setShowUpdateExpirationModal] = useState(
    false
  );
  const history = useHistory();
  const startPushTaskPoll = usePushTaskLoader();
  const logEvent = useLogEvent();
  const [initOidcAuthFlow] = useInitOidcAuthFlowMutation();
  const { displayErrorToast } = useToast();
  const [filters, setFilters] = useImmer<ResourceUserFiltersInput>({});
  const debouncedFilters = useDebouncedValue(filters, 250);

  // this should probably be written as a fragment on Resource
  const {
    data,
    previousData,
    networkStatus,
    fetchMore,
    refetch,
  } = useQuery<ResourceUsersTableQuery>(
    gql`
      query ResourceUsersTable(
        $resourceId: ResourceId
        $sortBy: PaginatedResourceUsersSortBy
        $cursor: String
        $filters: ResourceUserFiltersInput
      ) {
        resource(input: { id: $resourceId }) {
          ... on ResourceResult {
            resource {
              resourceType
              id
              name
              authorizedActions
              paginatedResourceUsers(
                input: { sortBy: $sortBy, cursor: $cursor, filters: $filters }
              ) {
                totalNumResourceUsers
                ...PaginatedResourceUsers
              }
              ...ResourcePreviewSmall
            }
          }
        }
      }
      ${PaginatedResourceUsersFragmentDoc}
      ${ResourcePreviewSmallFragmentDoc}
    `,
    {
      variables: {
        resourceId: props.resourceId,
        sortBy: sortByVariable,
        filters: debouncedFilters,
      },
      notifyOnNetworkStatusChange: true,
    }
  );

  const [
    updateResourceUsers,
    { loading: updateUsersLoading },
  ] = useUpdateUserResourcesMutation();
  const [
    removeResourceUsers,
    { loading: removeUsersLoading },
  ] = useRemoveResourceUsersMutation();

  const submitRemoval = async (
    resourceUsersToRemove: RemoveResourceUserInput[]
  ) => {
    logEvent({
      name: "apps_remove_user",
      properties: {
        type: "resource",
        numUsers: resourceUsersToRemove.length,
      },
    });

    try {
      const { data } = await removeResourceUsers({
        variables: {
          input: {
            resourceUsers: resourceUsersToRemove,
          },
        },
      });
      switch (data?.removeResourceUsers.__typename) {
        case "RemoveResourceUsersResult":
          startPushTaskPoll(data.removeResourceUsers.taskId, {
            refetchOnComplete: [{ resourceId: props.resourceId }],
            onComplete: () => {
              refetch();
            },
          });
          setRemoveUsersErrorMessage(null);
          resetSelected();
          setShowRemoveModal(false);
          break;
        case "OpalAdminRoleMustHaveAtLeastOneDirectUser":
          logError(new Error(data.removeResourceUsers.message));
          setRemoveUsersErrorMessage(data.removeResourceUsers.message);
          break;
        case "CallToWebhookFailedError":
          setRemoveUsersErrorMessage(data.removeResourceUsers.message);
          break;
        case "OidcIDTokenNotFoundError":
          try {
            const state = generateState();
            const { data } = await initOidcAuthFlow({
              variables: {
                input: {
                  state: state,
                  oidcProviderType: OidcProviderType.AwsSession,
                },
              },
            });
            switch (data?.initOidcAuthFlow.__typename) {
              case "InitOidcAuthFlowResult":
                setOidcData({
                  state: state,
                  oidcProviderType: OidcProviderType.AwsSession,
                  postAuthPath: window.location.pathname,
                  postAuthHash: window.location.hash,
                  params: {
                    action: OidcPostAuthAction.RemoveResourceUser,
                    resourceId: props.resourceId,
                    resourceUsersToRemove: resourceUsersToRemove,
                  },
                });
                window.location.replace(data?.initOidcAuthFlow.authorizeUrl);
                break;
              case "OidcProviderNotFoundError":
                displayErrorToast(
                  "Error: Failed to trigger MFA. Please contact your admin."
                );
                break;
              default:
                displayErrorToast(
                  "Error: Failed to trigger MFA. Please try again or contact support if the issue persists."
                );
            }
          } catch (e) {
            logError(e, "Error: resource forfeiture failed");
            setRemoveUsersErrorMessage(
              getModifiedErrorMessage("Error: resource forfeiture failed", e)
            );
          }
          break;
        default:
          logError(new Error(`failed to update resource user access`));
          setRemoveUsersErrorMessage(
            "Error: failed to update resource user access"
          );
      }
    } catch (error) {
      logError(error, "failed to update resource user access");
      setRemoveUsersErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to update resource user access",
          error
        )
      );
    }
  };

  const submitUpdateExpiration = async (
    resourceUsersToUpdate: UpdateResourceUserInput[]
  ) => {
    logEvent({
      name: "apps_update_user",
      properties: {
        type: "resource",
        numUsers: resourceUsersToUpdate.length,
      },
    });

    try {
      const { data } = await updateResourceUsers({
        variables: {
          input: {
            resourceUsers: resourceUsersToUpdate,
          },
        },
      });
      switch (data?.updateResourceUsers.__typename) {
        case "UpdateResourceUsersResult":
          refetch();
          setUpdateExpirationErrorMessage("");
          resetSelected();
          setShowUpdateExpirationModal(false);
          break;
        default:
          logError(new Error(`failed to update resource user access`));
          setRemoveUsersErrorMessage(
            "Error: failed to update resource user access"
          );
      }
    } catch (error) {
      logError(error, "failed to update resource user access");
      setRemoveUsersErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to update resource user access",
          error
        )
      );
    }
  };

  useMountEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (queryParams.has("oidc_auth")) {
      const oidcAuthStatus = queryParams.get("oidc_auth");
      if (oidcAuthStatus === "success") {
        const oidcData = getOidcData();
        if (
          oidcData &&
          oidcData.params?.action === OidcPostAuthAction.RemoveResourceUser &&
          oidcData.params.resourceId === props.resourceId &&
          oidcData.params.resourceUsersToRemove
        ) {
          clearOidcData();
          submitRemoval(oidcData.params.resourceUsersToRemove);
          // Clear oidc_auth parameter
          queryParams.delete("oidc_auth");
          history.replace({
            search: queryParams.toString(),
          });
        }
      } else {
        displayErrorToast(
          "Authentication failed. Please try again or contact support if the issue remains."
        );
      }
    }
  });
  const shownData = data ?? previousData;
  if (shownData?.resource.__typename === "ResourceResult") {
    const resource = shownData.resource.resource;
    const paginatedResourceUsers = resource.paginatedResourceUsers;

    const COLUMNS: Columns<ResourceUser, ResourceUserSortByField> = [
      {
        id: ResourceUserSortByField.UserFullName,
        label: "User",
        sortable: true,
        customCellRenderer: (user) => {
          return (
            <ResourceLabel
              text={user.user?.fullName}
              avatar={user.user?.avatarUrl}
              pointerCursor
              entityId={user.user?.id}
              entityTypeNew={EntityType.User}
            />
          );
        },
        width: 130,
      },
      {
        id: ResourceUserSortByField.UserEmail,
        label: "Email",
        customCellRenderer: (user) => user.user?.email ?? "-",
        width: 130,
      },
      {
        id: ResourceUserSortByField.AccessLevelName,
        label: "Role",
        customCellRenderer: (user) => user.accessLevel.accessLevelName ?? "-",
      },
      {
        id: ResourceUserSortByField.SourceOfAccess,
        label: "Access Paths",
        customCellRenderer: (user) => (
          <ResourceUserAccessPointsLabel
            access={user.access}
            user={user.user}
            resource={resource}
          />
        ),
        width: 110,
      },
      {
        id: ResourceUserSortByField.ExpiresAt,
        label: "Expires",
        customCellRenderer: (user) => {
          const expirationTime = user.access?.latestExpiringAccessPoint
            ?.expiration
            ? moment(new Date(user.access.latestExpiringAccessPoint.expiration))
            : null;
          return (
            <TimeLabel
              time={expirationTime}
              supportTicket={
                user.access?.latestExpiringAccessPoint.supportTicket
              }
              useExpiringLabel
            />
          );
        },
        width: 125,
      },
      {
        id: ResourceUserSortByField.PropagationStatus,
        label: "Status",
        customCellRenderer: (user) => {
          return (
            <PropagationStatusLabelWithModal
              // key is required to force re-render when a resource user has multiple roles and multiple tickets
              key={
                user.userId + resource.id + user.accessLevel.accessLevelRemoteId
              }
              propagationType={PropagationType.ResourceUser}
              propagationStatus={user.propagationStatus}
              isAccessReview={false}
              entityInfo={{
                roleAssignmentKey:
                  user.userId +
                  resource.id +
                  user.accessLevel.accessLevelRemoteId,
                user: user.user,
                resource: resource,
                role: user.accessLevel,
                lastExpiringAccessPointExpiration:
                  user.access?.latestExpiringAccessPoint.expiration,
              }}
            />
          );
        },
        width: 55,
        fixedWidth: true,
      },
      {
        id: "idpStatus",
        label: "",
        width: 32,
        fixedWidth: true,

        customCellRenderer: (user) => {
          let iconName: PropsFor<typeof Icon>["name"] = "user";
          let color: PropsFor<typeof Icon>["color"] = "gray400";
          switch (user.user?.hrIdpStatus) {
            case HrIdpStatus.Active:
              iconName = "user-check";
              color = "gray400";
              break;
            default:
              iconName = "user-x";
              color = "red600";
              break;
          }
          return user.user?.hrIdpStatus ? (
            <Tooltip tooltipText={formatHrIdpStatus(user.user?.hrIdpStatus)}>
              <Icon name={iconName} size="xs" color={color} />
            </Tooltip>
          ) : (
            ""
          );
        },
      },
    ];
    const usersById: { [key: string]: ResourceUser } = {};
    paginatedResourceUsers.resourceUsers.forEach((resourceUser) => {
      usersById[resourceUser.userId] = resourceUser;
    });

    const availableAccessLevels = _.sortBy(
      (paginatedResourceUsers.uniqueAccessLevels ?? []).filter(
        (accessLevel) => accessLevel.accessLevelName !== ""
      ),
      "accessLevelName"
    );

    const showRolesDropdown =
      resourceTypeHasRoles(resource) && availableAccessLevels.length > 1;

    const canManage = resource.authorizedActions?.includes(
      AuthorizedActionManage
    );

    const cursor = paginatedResourceUsers.cursor;
    const selectableOpalTablePropsFiltered = canManage
      ? selectableOpalTableProps
      : {};
    return (
      <>
        <OpalTable
          filters={
            <>
              <div className={styles.searchInput}>
                <Input
                  leftIconName="search"
                  type="search"
                  style="search"
                  placeholder="Filter by name"
                  value={filters.userFullName ?? ""}
                  onChange={(value) => {
                    logEvent({
                      name: "apps_filter_users",
                      properties: {
                        type: "resource",
                        filter: "userFullName",
                      },
                    });
                    setFilters((draft) => {
                      draft.userFullName = value;
                    });
                  }}
                />
              </div>
              <div className={styles.dropdown}>
                <Select
                  multiple
                  size="sm"
                  value={AVAILABLE_PROPAGATION_STATUSES.filter((status) => {
                    if (!filters.propagationStatuses) return false;
                    return status.values.some((value) => {
                      return filters?.propagationStatuses?.includes(value);
                    });
                  })}
                  options={AVAILABLE_PROPAGATION_STATUSES}
                  getOptionLabel={(option) => option.label}
                  renderInputValue={(value) => {
                    if (!value || value.length === 0) {
                      return "Filter by propagation status";
                    }
                    return value.map((option) => option.label).join(", ");
                  }}
                  onSelectValue={(value, reason) => {
                    logEvent({
                      name: "apps_filter_users",
                      properties: {
                        type: "resource",
                        filter: "propagationStatuses",
                      },
                    });
                    setFilters((draft) => {
                      if (!value) return;
                      switch (reason) {
                        case "select-option":
                          draft.propagationStatuses = _.uniq([
                            ...(draft.propagationStatuses ?? []),
                            ...value.values,
                          ]);
                          break;
                        case "remove-option":
                          draft.propagationStatuses = draft.propagationStatuses?.filter(
                            (status) =>
                              !value.values.some((value) => status === value)
                          );
                          break;
                      }
                    });
                  }}
                />
              </div>
              {showRolesDropdown && (
                <div className={styles.dropdown}>
                  <Select
                    multiple
                    size="sm"
                    value={availableAccessLevels.filter(
                      (accessLevel) =>
                        filters.accessLevelRemoteIds?.includes(
                          accessLevel.accessLevelRemoteId
                        ) ?? false
                    )}
                    options={availableAccessLevels}
                    getOptionLabel={(option) => option.accessLevelName}
                    renderInputValue={(value) => {
                      if (!value || value.length === 0) {
                        return "Filter by role";
                      }
                      return value
                        .map((option) => option.accessLevelName)
                        .join(", ");
                    }}
                    onSelectValue={(value, reason) => {
                      logEvent({
                        name: "apps_filter_users",
                        properties: {
                          type: "resource",
                          filter: "roles",
                        },
                      });
                      setFilters((draft) => {
                        if (!value) return;
                        switch (reason) {
                          case "select-option":
                            draft.accessLevelRemoteIds = _.uniq([
                              ...(draft.accessLevelRemoteIds ?? []),
                              value.accessLevelRemoteId,
                            ]);
                            break;
                          case "remove-option":
                            draft.accessLevelRemoteIds = draft.accessLevelRemoteIds?.filter(
                              (accessLevel) =>
                                accessLevel !== value.accessLevelRemoteId
                            );
                            break;
                        }
                      });
                    }}
                  />
                </div>
              )}
              {
                <Checkbox
                  checked={filters.directAccessOnly ?? false}
                  onChange={(checked) => {
                    logEvent({
                      name: "apps_filter_users",
                      properties: {
                        type: "resource",
                        filter: "directAccessOnly",
                      },
                    });
                    setFilters((draft) => {
                      draft.directAccessOnly = checked;
                    });
                  }}
                  label="Direct Access Only"
                />
              }
            </>
          }
          columns={COLUMNS}
          rows={
            shownData.resource.resource.paginatedResourceUsers.resourceUsers
          }
          totalNumRows={
            shownData.resource.resource.paginatedResourceUsers.totalNumUsers
          }
          onLoadMoreRows={
            cursor
              ? async () => {
                  await fetchMore({ variables: { cursor: cursor } });
                }
              : undefined
          }
          entityName="User"
          getRowId={(user) => user.userId}
          actions={
            canManage && !isSnowflakeResource(resource.resourceType) ? (
              <ButtonV3
                label="Add Users"
                type="mainSecondary"
                leftIconName="plus"
                onClick={() => {
                  transitionTo({
                    pathname: `/resources/${resource.id}/add-users`,
                  });
                }}
              />
            ) : null
          }
          networkStatus={networkStatus}
          {...sortByTableProps}
          {...selectableOpalTablePropsFiltered}
        />
        {showUpdateExpirationModal && (
          <BulkUpdateExpirationModal
            isOpen={showUpdateExpirationModal}
            onSubmit={(expiration) => {
              const resourceUsersToUpdate: UpdateResourceUserInput[] = [];
              const accessDurationInMinutes =
                expirationValueToDurationInMinutes(expiration)?.asMinutes() ??
                null;
              for (const rowId of selectedItemIds) {
                const user = usersById[rowId];
                resourceUsersToUpdate.push({
                  resourceId: resource.id,
                  userId: user.userId,
                  accessLevel: {
                    accessLevelName: user.accessLevel.accessLevelName,
                    accessLevelRemoteId: user.accessLevel.accessLevelRemoteId,
                  },
                  durationInMinutes: { int: accessDurationInMinutes },
                });
              }
              submitUpdateExpiration(resourceUsersToUpdate);
            }}
            selectedItemIds={selectedItemIds}
            entityName={resource.name}
            errorMessage={updateExpirationErrorMessage}
            onClose={() => setShowUpdateExpirationModal(false)}
            loading={updateUsersLoading}
          />
        )}
        {showRemoveModal && (
          <Modal
            isOpen
            title="Remove Resource Users"
            onClose={() => setShowRemoveModal(false)}
          >
            <Modal.Body>
              {removeUsersErrorMessage && (
                <ModalErrorMessage errorMessage={removeUsersErrorMessage} />
              )}
              Are you sure you want to remove{" "}
              {pluralize(
                "direct role assignment",
                selectedItemIds.length,
                true
              )}{" "}
              from this resource?
            </Modal.Body>
            <Modal.Footer
              primaryButtonLabel="Remove"
              primaryButtonDisabled={selectedItemIds.length === 0}
              primaryButtonLoading={removeUsersLoading}
              onPrimaryButtonClick={() => {
                const resourceUsersToRemove: RemoveResourceUserInput[] = [];
                for (const rowId of selectedItemIds) {
                  const user = usersById[rowId];
                  resourceUsersToRemove.push({
                    resourceId: resource.id,
                    userId: user.userId,
                    accessLevel: {
                      accessLevelName: user.accessLevel.accessLevelName,
                      accessLevelRemoteId: user.accessLevel.accessLevelRemoteId,
                    },
                  });
                }
                submitRemoval(resourceUsersToRemove);
              }}
            />
          </Modal>
        )}
      </>
    );
  } else {
    return null;
  }
};

export default ResourceUsersOpalTable;
