import { EntityType, EventFilterFragment } from "api/generated/graphql";
import { ResourceLabel } from "components/label/Label";
import { FormRow, FormSection } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import { eventTypeToString } from "utils/events";

type Props = {
  eventFilter: EventFilterFragment;
};

export const EventFilterViewer = (props: Props) => {
  const { eventFilter } = props;
  const createdAtTime = moment(new Date(eventFilter.createdAt));
  const createdAtText =
    createdAtTime.toDate().toDateString() + ` (${createdAtTime.fromNow()})`;

  return (
    <div
      className={sprinkles({
        padding: "md",
        display: "flex",
        flexDirection: "column",
        gap: "xl",
      })}
    >
      <FormSection title="Overview">
        <FormRow title="Created by">
          <ResourceLabel
            text={props.eventFilter.creator.fullName}
            entityTypeNew={EntityType.User}
            entityId={props.eventFilter.creator.id}
            pointerCursor={true}
            avatar={props.eventFilter.creator.avatarUrl}
          />
        </FormRow>
        <FormRow title="Created at">{createdAtText}</FormRow>
      </FormSection>
      <FormSection title="Properties">
        <FormRow title="Start date">{eventFilter.startDate ?? "--"}</FormRow>
        <FormRow title="End date">{eventFilter.endDate ?? "--"}</FormRow>
        <FormRow title="Actor">{eventFilter.actor?.fullName ?? "--"}</FormRow>
        <FormRow title="Object ID">{eventFilter.objectId ?? "--"}</FormRow>
        <FormRow title="Event types">
          {eventFilter.eventTypes
            ?.map((eventType) => eventTypeToString(eventType, true))
            .join(", ") ?? "--"}
        </FormRow>
        <FormRow title="System events">
          {eventFilter.systemEvents ? "True" : "--"}
        </FormRow>
        <FormRow title="API token">
          {eventFilter.apiTokenLabel && eventFilter.apiTokenPreview
            ? `${eventFilter.apiTokenLabel} (${eventFilter.apiTokenPreview})`
            : "--"}
        </FormRow>
      </FormSection>
    </div>
  );
};

export default EventFilterViewer;
