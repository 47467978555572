import { useBreakGlassUsersCountsQuery } from "api/generated/graphql";

import LineGraph from "../graphs/LineGraph";
import { useMetricsQueryInput } from "../MetricsDashboardContext";
import MetricsGraphCard from "./MetricsGraphCard";

const BreakGlassUsersCounts = () => {
  const input = useMetricsQueryInput();

  const { data, loading, error } = useBreakGlassUsersCountsQuery({
    variables: {
      input,
    },
  });

  return (
    <MetricsGraphCard
      title="Break glass access used"
      subtitle="How often are users using break glass access?"
      units="number of users"
      loading={loading}
      error={Boolean(error)}
    >
      <LineGraph
        datasets={[
          {
            data:
              data?.breakGlassUsersCounts.lineData.map((d) => d.value) ?? [],
            label: "Break glass users",
          },
        ]}
        xAxisLabels={
          data?.breakGlassUsersCounts.lineData.map((d) => d.timestamp) ?? []
        }
        hideDecimals
      />
    </MetricsGraphCard>
  );
};

export default BreakGlassUsersCounts;
