import { EntityType } from "api/generated/graphql";
import sprinkles from "css/sprinkles.css";
import { MouseEventHandler } from "react";
import ReactLinkify from "react-linkify";
import { Link as ReactDOMLink } from "react-router-dom";
import { useRestrictUserViewingEntity } from "utils/auth/auth";

import { Icon } from "..";
import * as styles from "./Link.css";

interface Props {
  url: string;
  external?: boolean;
  target?: "_self" | "_blank";
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  color?: "blue" | "black";
  underline?: boolean;
  entityTypeNew?: EntityType | null;
}
const Link: React.FC<Props> = (props) => {
  const {
    external,
    target = "_self",
    children,
    onClick,
    url,
    color,
    underline,
  } = props;

  const disallowLink = useRestrictUserViewingEntity(props.entityTypeNew);

  const linkProps = {
    className: styles.link({ color, underline, disabled: disallowLink }),
    target,
  };

  return external ? (
    <a {...linkProps} href={url} rel="noopener noreferrer" onClick={onClick}>
      {children}
      <span className={sprinkles({ marginLeft: "xs" })}>
        <Icon name="external-link" size="xs" />
      </span>
    </a>
  ) : (
    <ReactDOMLink {...linkProps} onClick={onClick} to={url ?? ""}>
      {children}
    </ReactDOMLink>
  );
};

export const Linkify: React.FC<{}> = (props) => {
  return (
    <ReactLinkify
      componentDecorator={(decoratedHref, decoratedText, key) => (
        <Link key={key} url={decoratedHref} external>
          {decoratedText}
        </Link>
      )}
    >
      {props.children}
    </ReactLinkify>
  );
};

export default Link;
