import { EntityType, useItemsListSectionQuery } from "api/generated/graphql";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { Checkbox, EntityIcon, Icon } from "components/ui";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import { resourceTypeSupportsChildResources } from "utils/directory/resources";
import { logError } from "utils/logging";
import { AppsContext, SelectedItem } from "views/apps/AppsContext";
import { SortOption } from "views/apps/ItemsListSection";
import { useAccessOptionKey } from "views/apps/utils";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import * as columnStyles from "../../column/ColumnListItem.css";
import * as styles from "./ResourceTreeItem.css";

export interface ResourceTreeItemProps {
  appId: string;
  resource: SelectedItem;
  label: string;
  sublabel?: string;
  sublabelColor?: "gray" | "red";
  icon?: IconData;
  brandIcon?: PropsFor<typeof Icon>["brandIcon"];
  largeIcon?: boolean;
  type?: "default" | "loader" | "success" | "info" | "danger" | "terminal";
  active?: boolean;
  selected?: boolean;
  disabled?: boolean;
  inactive?: boolean;
  navigateToItem: (id: string, entityType: EntityType) => void;
  sortOption: SortOption;
  hasAccess?: boolean;
  initialShowSection?: boolean;
  selectAll: boolean;
  clearAll: boolean;
  setSelectAll: (value: boolean) => void;
  setClearAll: (value: boolean) => void;
  expandedResourceIDs: string[];
}

const ResourceTreeItem: React.FC<ResourceTreeItemProps> = (props) => {
  const {
    type = "default",
    active = false,
    selected = false,
    disabled = false,
    inactive = false,
    hasAccess = false,
    initialShowSection = false,
    selectAll = false,
    clearAll = false,
    sublabelColor = "gray",
  } = props;

  const { selectedItems, selectItem, clearItem, isSelectMode } = useContext(
    AppsContext
  );

  const renderIcon = () => {
    if (!props.icon) return null;

    if (props.icon.type === "name") {
      return (
        <div
          className={columnStyles.icon({
            size: props.largeIcon ? "lg" : undefined,
            inactive,
          })}
        >
          <Icon
            name={props.icon.icon}
            brandIcon={props.brandIcon}
            size={props.largeIcon ? "sm" : "xs"}
            iconStyle={props.icon.style}
          />
        </div>
      );
    }

    if (props.icon.type === "src") {
      return (
        <div
          className={columnStyles.icon({
            size: props.largeIcon ? "lg" : undefined,
            inactive,
          })}
        >
          <Icon
            data={props.icon}
            brandIcon={props.brandIcon}
            size={props.largeIcon ? "sm" : "xs"}
            iconStyle={props.icon.style}
          />
        </div>
      );
    }

    if (props.icon.type === "entity") {
      return (
        <div
          className={columnStyles.icon({
            size: props.largeIcon ? "lg" : undefined,
            inactive,
          })}
        >
          <EntityIcon
            type={props.icon.entityType}
            size={props.largeIcon ? "md" : "sm"}
          />
        </div>
      );
    }

    return null;
  };

  const [showSection, setShowSection] = useState<boolean>(
    initialShowSection ||
      props.expandedResourceIDs.some((id) => id === props.resource.id)
  );
  const [accessOptionKey] = useAccessOptionKey();

  useEffect(() => {
    if (selectAll) {
      selectItem(props.resource);
      setShowSection(true);
    } else if (clearAll) {
      clearItem(props.resource);
    }
  }, [clearAll, selectAll, selectItem, clearItem, props.resource]);

  const { resourceId } = useParams<Record<string, string>>();

  const renderItem = (isParent: Boolean) => {
    return (
      <button
        className={columnStyles.container({ type, selected, active, disabled })}
        onClick={
          disabled
            ? undefined
            : () => props.navigateToItem(props.resource.id, EntityType.Resource)
        }
        disabled={disabled}
        data-test-id={props.label}
      >
        {isParent && (
          <div
            className={columnStyles.icon()}
            onClick={(event) => {
              event.stopPropagation();
              setShowSection(!showSection);
            }}
            onMouseDown={(event) => event.preventDefault()}
          >
            <Icon
              name={showSection ? "chevron-down" : "chevron-right"}
              size="xs"
              color={active ? "white" : "gray400"}
            />
          </div>
        )}
        {isSelectMode ? (
          <div className={sprinkles({ marginRight: "xs" })}>
            <Checkbox
              size="sm"
              checked={selectedItems.some((i) => i.id === props.resource.id)}
              onChange={(checked) => {
                if (checked) {
                  selectItem(props.resource);
                  clearAll && props.setClearAll(false);
                } else if (!checked) {
                  clearItem(props.resource);
                  selectAll && props.setSelectAll(false);
                }
              }}
              disabled={disabled}
            />
          </div>
        ) : null}
        <div className={columnStyles.labelContainer}>
          <div className={columnStyles.label({ inactive })} title={props.label}>
            {props.label}
          </div>
          <div
            className={columnStyles.sublabel({ active, color: sublabelColor })}
            title={props.sublabel}
          >
            {props.sublabel}
          </div>
        </div>
        {hasAccess ? (
          <div>
            <Icon name="check-circle" size="xs" color="green600" />
          </div>
        ) : null}
        {renderIcon()}
      </button>
    );
  };

  const canHaveChildren =
    props.resource.__typename === "Resource"
      ? resourceTypeSupportsChildResources(props.resource.resourceType)
      : false;
  const { data, loading, error, fetchMore } = useItemsListSectionQuery({
    variables: {
      id: props.appId,
      access: accessOptionKey,
      parentResourceId: { resourceId: props.resource.id },
      sortBy: props.sortOption.value,
    },
    notifyOnNetworkStatusChange: true,
    skip: !canHaveChildren,
  });

  const app = data?.app.__typename === "App" ? data.app : null;
  const cursor = app?.items.cursor;

  if (!canHaveChildren) {
    return (
      <div className={styles.wrapper({ hasChildren: false })}>
        {renderItem(false)}
      </div>
    );
  }

  if (loading && !data) {
    return <ColumnListItemsSkeleton numItems={1} />;
  }

  if (error || !data) {
    logError(error, "failed to get resource tree section query");
    return <UnexpectedErrorPage error={error} />;
  }

  // Load all children for now.
  const loadAll = async () => {
    let thisCursor = cursor;
    while (thisCursor) {
      const { data, error } = await fetchMore({
        variables: {
          cursor: thisCursor,
        },
      });
      if (error) {
        logError(error, "failed to load all resources.");
        break;
      }
      thisCursor = data.app.__typename === "App" ? data.app.items.cursor : null;
    }
  };
  loadAll();

  const hasChildren = app?.items.items?.length != 0;
  return (
    <div className={styles.wrapper({ hasChildren })}>
      {renderItem(hasChildren)}
      {hasChildren && showSection && (
        <div className={styles.children}>
          {app?.items?.items?.map((child) => {
            if (child.resource) {
              const {
                id,
                name,
                resourceType,
                currentUserAccess,
              } = child.resource;
              return [
                <ResourceTreeItem
                  key={child.resource.id}
                  appId={props.appId}
                  resource={child.resource}
                  label={name}
                  sublabel={
                    getResourceTypeInfo(child.resource.resourceType)?.fullName
                  }
                  icon={{ type: "entity", entityType: resourceType }}
                  largeIcon
                  selected={resourceId === id}
                  navigateToItem={props.navigateToItem}
                  sortOption={props.sortOption}
                  hasAccess={currentUserAccess.resourceUsers.length > 0}
                  selectAll={selectAll}
                  setSelectAll={props.setSelectAll}
                  clearAll={clearAll}
                  setClearAll={props.setClearAll}
                  expandedResourceIDs={props.expandedResourceIDs}
                />,
              ];
            }
            return [];
          })}
        </div>
      )}
    </div>
  );
};

export default ResourceTreeItem;
