import {
  GroupPreviewSmallFragment,
  ResourcePreviewLargeFragment,
  UserPreviewSmallFragment,
} from "api/generated/graphql";
import { DataElement, DataElementList } from "components/ui";
import {
  useFilterDispatch,
  useFilterState,
} from "components/viz/contexts/FilterContext";
import React from "react";

import { GraphContext } from "./contexts/GraphContext";
import * as styles from "./EntityDataElement.css";

const EntityDataElements = () => {
  const filterState = useFilterState();
  const filterDispatch = useFilterDispatch();
  const selection = filterState.selection;
  const { graphState } = React.useContext(GraphContext);

  const vizData = graphState.data;

  const userById: { [userId: string]: UserPreviewSmallFragment } = {};
  const resourceById: {
    [resourceId: string]: ResourcePreviewLargeFragment;
  } = {};
  const groupById: { [groupId: string]: GroupPreviewSmallFragment } = {};
  vizData?.users.forEach((user) => (userById[user.id] = user));
  vizData?.resources.forEach(
    (resource) => (resourceById[resource.id] = resource)
  );
  vizData?.groups.forEach((group) => (groupById[group.id] = group));

  return (
    <div className={styles.entityDataElements}>
      <DataElementList>
        {selection.userIds?.map((userId) => {
          const user = userById[userId];
          if (!user) {
            return null;
          }
          return (
            <DataElement
              label={user.fullName}
              color="blue"
              leftIcon={{ name: "user" }}
              rightIcon={{ name: "x" }}
              onClick={() =>
                filterDispatch({
                  type: "REMOVE_USER",
                  payload: {
                    id: user.id,
                  },
                })
              }
              allowedToWrap
            />
          );
        })}
        {selection.resourceIds?.map((resourceId) => {
          const resource = resourceById[resourceId];
          if (!resource) {
            return null;
          }
          return (
            <DataElement
              label={resource.name}
              color="blue"
              leftIcon={{ name: "cube" }}
              rightIcon={{ name: "x" }}
              onClick={() =>
                filterDispatch({
                  type: "REMOVE_RESOURCE",
                  payload: {
                    id: resource.id,
                  },
                })
              }
              allowedToWrap
            />
          );
        })}
        {selection.groupIds?.map((groupId) => {
          const group = groupById[groupId];
          if (!group) {
            return null;
          }
          return (
            <DataElement
              label={group.name}
              color="blue"
              leftIcon={{ name: "users" }}
              rightIcon={{ name: "x" }}
              onClick={() =>
                filterDispatch({
                  type: "REMOVE_GROUP",
                  payload: {
                    id: group.id,
                  },
                })
              }
              allowedToWrap
            />
          );
        })}
        {selection.multiGroupSelections?.map((groupTypeSelection) => (
          <DataElement
            label={groupTypeSelection.name}
            color="blue"
            leftIcon={{ name: "users" }}
            rightIcon={{ name: "x" }}
            onClick={() =>
              filterDispatch({
                type: "REMOVE_MULTI_GROUP",
                payload: {
                  connectionId: groupTypeSelection.connectionId,
                  groupType: groupTypeSelection.groupType,
                },
              })
            }
            allowedToWrap
          />
        ))}
        {selection.multiResourceSelections?.map((resourceTypeSelection) => (
          <DataElement
            label={resourceTypeSelection.name}
            color="blue"
            leftIcon={{ name: "box" }}
            rightIcon={{ name: "x" }}
            onClick={() =>
              filterDispatch({
                type: "REMOVE_MULTI_RESOURCE",
                payload: {
                  connectionId: resourceTypeSelection.connectionId,
                  resourceType: resourceTypeSelection.resourceType,
                },
              })
            }
            allowedToWrap
          />
        ))}
      </DataElementList>
    </div>
  );
};

export default EntityDataElements;
