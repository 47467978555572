import { ContextMenu, Icon } from "components/ui";
import { useCallback } from "react";

import * as styles from "./NodeMenu.css";

interface Props {
  options: PropsFor<typeof ContextMenu>["options"];
}

const NodeMenu = (props: Props) => {
  const renderButton = useCallback((onClick) => {
    return (
      <button className={styles.nodeMenuButton} onClick={onClick}>
        <Icon name="more-horizontal" size="xs" />
      </button>
    );
  }, []);

  return (
    <div
      onWheel={() => {
        // Required for NodeMenu to update position correctly when panning graph.
        // The library we use for popovers only listens to 'scroll' and 'resize' events for auto updating position.
        // https://github.com/floating-ui/floating-ui/blob/11d058a17b923317648090515dfbc585bc13b7af/packages/dom/src/autoUpdate.ts#L65
        window.dispatchEvent(new Event("scroll"));
      }}
    >
      <ContextMenu renderButton={renderButton} options={props.options} />
    </div>
  );
};

export default NodeMenu;
