import { AccessOption } from "api/generated/graphql";
import axios from "axios";
import AuthContext from "components/auth/AuthContext";
import RowBar from "components/layout/RowBar";
import { useToast } from "components/toast/Toast";
import { Button, Icon, Link } from "components/ui";
import { IconName } from "components/ui/icon/Icon";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import pluralize from "pluralize";
import React, { useContext } from "react";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { BulkDeleteModal } from "views/common/BulkUpdateActionButtons";

import { ACCESS_OPTION_URL_KEY, AppsContext } from "./AppsContext";
import { useAccessOptionKey } from "./utils";

const BulkSelectBar = () => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const { authState } = useContext(AuthContext);
  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();

  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const logEvent = useLogEvent();

  const {
    selectedItems,
    clearSelectedItems,
    isSelectMode,
    setBulkMode,
    selectedRemoteItems,
  } = useContext(AppsContext);
  const [accessOptionKey] = useAccessOptionKey();

  if ((!isSelectMode && accessOptionKey !== AccessOption.Unmanaged) || hasV3) {
    return null;
  }
  const numSelected =
    accessOptionKey === AccessOption.Unmanaged
      ? selectedRemoteItems.length
      : selectedItems.length;

  const selectedResourceIds = selectedItems
    .filter((item) => item.__typename === "Resource")
    .map((item) => item.id);
  const selectedGroupIds = selectedItems
    .filter((item) => item.__typename === "Group")
    .map((item) => item.id);

  const handleClickRequest = () => {
    setBulkMode("request");
    logEvent({
      name: "apps_bulk_select_request_view",
      properties: {
        numSelected,
      },
    });
  };

  const handleEditClick = () => {
    setBulkMode("edit");
    logEvent({
      name: "apps_bulk_select_edit_view",
      properties: {
        numSelected,
      },
    });
  };

  const handleExportUserClick = () => {
    logEvent({
      name: "apps_export_users",
      properties: {
        exportType: "bulk",
      },
    });
    displayLoadingToast("Generating export...");
    axios({
      url: `/export/items/users`,
      params: {
        resourceIDs: selectedResourceIds,
        groupIDs: selectedGroupIds,
      },
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        if (!selectedItems) return;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Opal_Bulk_Export_Items_" + _.uniqueId() + "_Users.csv"
        );
        link.click();
        displaySuccessToast(
          `Success: downloaded users for ${pluralize(
            "item",
            selectedItems.length
          )}`
        );
      })
      .catch(() => {
        displayErrorToast(`Error: failed to generate export`);
      });
  };

  const getShowEditButton = () => {
    return selectedItems.some((item) =>
      item.authorizedActions?.includes(AuthorizedActionManage)
    );
  };

  const renderButtons = () => {
    if (numSelected === 0 || accessOptionKey === AccessOption.Unmanaged) {
      return null;
    } else {
      return (
        <div className={sprinkles({ display: "flex", gap: "sm" })}>
          {selectedItems.some((item) => item.isRequestable) ? (
            <Button
              label="Request"
              size="sm"
              leftIconName="lock"
              type="primary"
              borderless
              onClick={handleClickRequest}
            />
          ) : null}
          {getShowEditButton() ? (
            <Button
              label="Edit"
              size="sm"
              leftIconName="edit"
              type="primary"
              borderless
              onClick={handleEditClick}
            />
          ) : null}
          {authState.user?.isAdmin && (
            <>
              <Button
                label="Remove from Opal"
                size="sm"
                leftIconName="trash-2"
                type="error"
                borderless
                onClick={() => setShowDeleteModal(true)}
              />
              <Button
                label="Export users"
                size="sm"
                leftIconName="download"
                type="default"
                borderless
                onClick={handleExportUserClick}
              />
            </>
          )}
        </div>
      );
    }
  };

  let iconName: IconName = "check-square";
  let bannerText = `You have selected ${numSelected} ${pluralize(
    "items",
    numSelected
  )}.`;
  if (!isSelectMode) {
    iconName = "plus-square";
    if (numSelected == 0) {
      bannerText =
        "Viewing unimported items. Select items to configure and add to Opal.";
    }
  }

  const returnToAllLink = (
    <Link url={`?${ACCESS_OPTION_URL_KEY}=${AccessOption.All}`}>
      Return to all.
    </Link>
  );

  return (
    <>
      <BulkDeleteModal
        isOpen={showDeleteModal}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onComplete={() => {
          setShowDeleteModal(false);
          clearSelectedItems();
        }}
        resourceIds={selectedResourceIds}
        groupIds={selectedGroupIds}
      />
      <RowBar>
        <div
          className={sprinkles({
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          })}
        >
          <div
            className={sprinkles({
              display: "flex",
              alignItems: "center",
            })}
          >
            <Icon name={iconName} size="xs" color="blue600" />
            <div
              className={sprinkles({
                marginX: "xs",
                fontWeight: "medium",
                display: "flex",
                gap: "xs",
              })}
            >
              {bannerText}
              {accessOptionKey === AccessOption.Unmanaged && returnToAllLink}
            </div>
            {renderButtons()}
          </div>
          {isSelectMode ? (
            <Button
              size="sm"
              leftIconName="x"
              round
              type="primary"
              borderless
              onClick={clearSelectedItems}
            />
          ) : null}
          {!isSelectMode && numSelected > 0 ? (
            <Button
              size="sm"
              label="Clear"
              type="primary"
              borderless
              onClick={clearSelectedItems}
            />
          ) : null}
        </div>
      </RowBar>
    </>
  );
};

export default BulkSelectBar;
