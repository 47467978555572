import { EntityIdTuple } from "api/generated/graphql";
import Label, { ResourceLabel } from "components/label/Label";
import * as styles from "components/pills/PillsV3.css";
import { EntityIcon, Icon } from "components/ui";
import { IconData } from "components/ui/utils";
import { PropsWithChildren } from "react";

type PillV3BaseProps = {
  pillColor?:
    | "Red"
    | "RedInverse"
    | "Blue"
    | "Purple"
    | "Pink"
    | "Orange"
    | "Cyan"
    | "Teal"
    | "LightGreen"
    | "DeepPurple"
    | "DeepOrange"
    | "Gray";
  onRemove?: () => void;
};

const PillWrapper = (props: PropsWithChildren<PillV3BaseProps>) => {
  const { pillColor = "DeepPurple", onRemove, children } = props;

  return (
    <div className={styles.pillWrapper({ pillColor: pillColor })}>
      {children && props.children}
      {onRemove && <Icon name="x" size="xxs" onClick={onRemove} />}
    </div>
  );
};

export type PillV3Props = PillV3BaseProps & {
  keyText?: string | null;
  tooltipText?: string;
  icon?: IconData;
  hideIcon?: boolean;
  maxChars?: number;
  url?: string;
  externalUrl?: string;
  clickToCopy?: boolean;
  target?: string;
  /*
    Provided entityId is used to generate a ResourceLabel Link.
    If provided, url and clickToCopy are ignored.
  */
  entityId?: EntityIdTuple;
};

export const PillV3 = (props: PillV3Props) => {
  const {
    keyText: text,
    pillColor,
    icon,
    onRemove,
    maxChars = 35,
    tooltipText,
    clickToCopy = false,
    target,
  } = props;

  if (!text && !icon) {
    return <span>&mdash;</span>;
  }

  // Use the passed in text, or default to the pill's text if it's long.
  // Otherwise, don't show a tooltip
  const tooltip = tooltipText || (text && text.length > 40 ? text : null);

  return (
    <PillWrapper pillColor={pillColor} onRemove={onRemove}>
      {icon && (
        <div className={styles.pillIcon({ pillColor })}>
          {icon.type === "entity" ? (
            <EntityIcon
              type={icon.entityType}
              size="md"
              separate
              includeBrand={false}
              useDefaultColor={false}
            />
          ) : (
            <Icon
              size="xs"
              iconStyle={icon.type == "src" ? "rounded" : "default"}
              data={icon}
            />
          )}
        </div>
      )}
      {text && (
        <div className={styles.pillText}>
          {props.entityId ? (
            <ResourceLabel
              text={text}
              maxChars={maxChars}
              tooltipText={tooltip}
              entityId={props.entityId.entityId}
              entityTypeNew={props.entityId.entityType}
              target={target}
              hideIcon
            />
          ) : (
            <Label
              text={text}
              maxChars={maxChars}
              tooltipText={tooltip}
              url={props.url}
              externalUrl={props.externalUrl}
              clickToCopy={clickToCopy}
              target={target}
            />
          )}
        </div>
      )}
    </PillWrapper>
  );
};

export type TagPillV3Props = PillV3Props & {
  valueText?: string;
};

export const TagPillV3 = (props: TagPillV3Props) => {
  const { keyText, valueText } = props;
  const text = valueText ? `${keyText}:${valueText}` : keyText;
  return PillV3({
    ...props,
    keyText: text,
  });
};

export type ContentPillProps = PropsWithChildren<PillV3BaseProps>;

export const ContentPill = (props: ContentPillProps) => {
  const { pillColor, onRemove } = props;

  return (
    <PillWrapper pillColor={pillColor} onRemove={onRemove}>
      {props.children}
    </PillWrapper>
  );
};
