import {
  AccessReviewAction,
  AccessReviewItemStatus,
  AccessReviewResourceFragment,
  AccessReviewResourceUserFragment,
  AccessReviewType,
  Maybe,
  ResourceAccessLevel,
  ReviewerUserStatus,
  ReviewResourceUserAction,
  useAccessReviewResourceQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import { Divider, Input } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import moment from "moment";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { filterSearchResults } from "utils/search/filter";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import { calculateBatchAcceptRevokeState } from "./AccessReviewAcceptRevokeToggleButton";
import AccessReviewContext, {
  AccessReviewContextActionType,
  emptyPerformReviewState,
} from "./AccessReviewContext";
import * as styles from "./AccessReviewResource.css";
import { updateReviewStateForResourceUserRoleChange } from "./AccessReviewResourceRoleCell";
import DecisionButtons from "./common/DecisionButtons";
import NoteCell from "./common/NoteCell";
import ResourceUserSubRow from "./common/ResourceUserSubRow";
import StatusCell from "./common/StatusCell";
import SubmitAccessReviewButton from "./common/SubmitAccessReviewButtonV2";
import UserCell from "./common/UserCell";
import { UARSelectContext } from "./UARSelectContext";

interface ResourceUserRow {
  id: string;
  user: string;
  expires?: string;
  role: string;
  updatedRole?: string;
  outcome?: string;
  note?: string;
  data: AccessReviewResourceUserFragment;
}

const AccessReviewResource = () => {
  const { accessReviewResourceId } = useParams<Record<string, string>>();

  const { data, error, loading } = useAccessReviewResourceQuery({
    variables: {
      input: {
        id: accessReviewResourceId,
      },
    },
    skip: !accessReviewResourceId,
  });

  if (loading) {
    return (
      <Column isContent maxWidth="lg">
        <ColumnContentSkeleton />
      </Column>
    );
  }

  let accessReviewResource: AccessReviewResourceFragment | undefined;
  switch (data?.accessReviewResource.__typename) {
    case "AccessReviewResourceResult":
      accessReviewResource = data.accessReviewResource.accessReviewResource;
      break;
    case "AccessReviewResourceNotFoundError":
      return (
        <Column isContent maxWidth="lg">
          <NotFoundPage entity="Resource" />
        </Column>
      );
  }

  if (error || !accessReviewResource) {
    return (
      <Column isContent maxWidth="lg">
        <UnexpectedErrorPage />
      </Column>
    );
  }

  return (
    <Column isContent maxWidth="lg">
      {accessReviewResource.currentUserReviewerStatus?.itemStatus ===
      AccessReviewItemStatus.Completed ? (
        <AccessReviewResourceCompleted
          accessReviewResource={accessReviewResource}
        />
      ) : (
        <AccessReviewResourceTodo accessReviewResource={accessReviewResource} />
      )}
    </Column>
  );
};

interface CompletedProps {
  accessReviewResource: AccessReviewResourceFragment;
}

const AccessReviewResourceCompleted = ({
  accessReviewResource,
}: CompletedProps) => {
  const { accessReviewId } = useParams<Record<string, string>>();
  const history = useHistory();

  const [searchQuery, setSearchQuery] = useState("");

  const resource = accessReviewResource.resource;
  // Filter for search results
  const resourceUsers = filterSearchResults(
    accessReviewResource.resourceUsers ?? [],
    searchQuery,
    (resourceUser) => [resourceUser.user?.fullName, resourceUser.user?.email]
  );

  if (!resource) {
    return (
      <Column isContent maxWidth="lg">
        <UnexpectedErrorPage />
      </Column>
    );
  }

  const resourceHasRoles = resourceUsers.some(
    (user) => !!user.accessLevel.accessLevelRemoteId
  );

  const rows: ResourceUserRow[] = resourceUsers.map((resourceUser) => {
    return {
      id: resourceUser.id,
      user: resourceUser.user?.fullName || resourceUser.userId,
      outcome: resourceUser.statusAndOutcome.outcome,
      role: resourceUser.accessLevel.accessLevelName ?? "",
      data: resourceUser,
    };
  });

  const columns: Header<ResourceUserRow>[] = [
    {
      id: "user",
      label: "User",
      customCellRenderer: (row) => {
        const user = row.data.user;
        if (!user) {
          return <></>;
        }
        return <UserCell user={user} />;
      },
    },
  ];
  if (resourceHasRoles) {
    columns.push({
      id: "role",
      label: "Role",
    });
  }
  columns.push({
    id: "outcome",
    label: "Status",
    sortable: false,
    width: 80,
    customCellRenderer: (row) => {
      const outcome = row.data.statusAndOutcome.outcome;
      const status = row.data.statusAndOutcome.status;
      if (!outcome || !status) {
        return <></>;
      }

      return <StatusCell outcome={outcome} status={status} />;
    },
  });

  const renderSubRow = (row: ResourceUserRow) => {
    return <ResourceUserSubRow resourceUser={row.data} />;
  };

  return (
    <>
      <ColumnHeader
        title={`${resource.name} ${
          resource.parentResource?.name
            ? `(${resource.parentResource?.name})`
            : ""
        }`}
        icon={{ type: "entity", entityType: resource.resourceType }}
        subtitle={`${resource.connection?.name} / ${
          resourceTypeInfoByType[resource.resourceType].fullName
        } ${
          resource.description ? `(Description: ${resource.description})` : ""
        }`}
        onClose={() =>
          history.push(`/access-reviews/${accessReviewId}/my-reviews`)
        }
      />
      <Divider />
      <ColumnContent>
        <div className={styles.tableContainer}>
          <div className={styles.searchContainer}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Search this table"
            />
          </div>
          <Table
            rows={rows}
            totalNumRows={rows.length}
            getRowId={(ru) => ru.id}
            columns={columns}
            renderSubRow={renderSubRow}
          />
        </div>
      </ColumnContent>
    </>
  );
};

interface TodoProps {
  accessReviewResource: AccessReviewResourceFragment;
}

const AccessReviewResourceTodo = ({ accessReviewResource }: TodoProps) => {
  const { accessReviewId, accessReviewResourceId } = useParams<
    Record<string, string>
  >();
  const history = useHistory();
  const { authState } = useContext(AuthContext);
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );
  const { selectedResourceUserIds, setSelectedResourceUserIds } = useContext(
    UARSelectContext
  );
  const [searchQuery, setSearchQuery] = useState("");

  const performReviewState =
    accessReviewState.performReviewStateByUARResourceId[accessReviewResourceId];

  // Initialize review state if not already initialized
  useEffect(() => {
    if (!performReviewState || !performReviewState.resourceUserActions) {
      accessReviewDispatch({
        type: AccessReviewContextActionType.AccessReviewItemUpdate,
        payload: {
          performReviewStateByUARResourceId: {
            ...accessReviewState.performReviewStateByUARResourceId,
            [accessReviewResourceId]: emptyPerformReviewState(),
          },
        },
      });
    }
  }, [
    accessReviewState,
    accessReviewResourceId,
    performReviewState,
    accessReviewDispatch,
  ]);

  const selfReviewAllowed =
    accessReviewResource?.accessReview?.selfReviewAllowed;
  const resource = accessReviewResource?.resource;
  // Filter for search results and
  // only show incomplete resource users current user needs to review
  const resourceUsers = (accessReviewResource?.resourceUsers ?? []).filter(
    (user) => {
      return user.reviewerUsers?.find((reviewer) => {
        return (
          reviewer.userId === authState.user?.user.id &&
          (selfReviewAllowed || authState.user?.user.id !== user.userId) &&
          reviewer.status === ReviewerUserStatus.NotStarted
        );
      });
    }
  );
  const filteredSearchResourceUsers = filterSearchResults(
    resourceUsers,
    searchQuery,
    (resourceUser) => [resourceUser.user?.fullName, resourceUser.user?.email]
  );

  if (!performReviewState) {
    return null;
  }

  if (!resource) {
    return (
      <Column isContent maxWidth="lg">
        <UnexpectedErrorPage />
      </Column>
    );
  }

  const getResourceUserReviewState = (resourceUserId: string) => {
    return performReviewState.resourceUserActions.find(
      (ru) => ru.accessReviewResourceUserId === resourceUserId
    )?.action;
  };

  const handleReviewAction = (
    state: AccessReviewAction,
    accessReviewResourceUserId: string
  ) => {
    const existingInfo = performReviewState.resourceUserActions.find(
      (resourceUserAction) =>
        resourceUserAction.accessReviewResourceUserId ===
        accessReviewResourceUserId
    );

    if (existingInfo) {
      existingInfo.action = state;
      delete existingInfo.updatedAccessLevel;
      delete existingInfo.updatedResource;
    } else {
      performReviewState.resourceUserActions.push({
        accessReviewResourceUserId,
        action: state,
      });
    }
    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARResourceId: {
          ...accessReviewState.performReviewStateByUARResourceId,
          [accessReviewResourceId]: performReviewState,
        },
      },
    });
  };

  const handleBulkReviewAction = (state: AccessReviewAction) => {
    performReviewState.resourceUserActions.forEach((resourceUserAction) => {
      resourceUserAction.action = state;
    });

    resourceUsers.forEach((resourceUser) => {
      const existingInfo = performReviewState.resourceUserActions.find(
        (resourceUserAction) =>
          resourceUserAction.accessReviewResourceUserId === resourceUser.id
      );

      if (!existingInfo) {
        performReviewState.resourceUserActions.push({
          accessReviewResourceUserId: resourceUser.id,
          action: state,
        });
      } else {
        delete existingInfo.updatedAccessLevel;
      }
    });

    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARResourceId: {
          ...accessReviewState.performReviewStateByUARResourceId,
          [accessReviewResourceId]: performReviewState,
        },
      },
    });
  };

  const goBack = () => {
    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARResourceId: {},
        performReviewStateByUARGroupId: {},
        performReviewStateByUARUserId: {},
        performReviewStateByUARConnectionId: {},
      },
    });
    history.push(`/access-reviews/${accessReviewId}/my-reviews`);
  };

  const resourceUserActionByUarResourceUserId: Record<
    string,
    ReviewResourceUserAction
  > = {};
  performReviewState?.resourceUserActions?.forEach((resourceUserAction) => {
    const id = resourceUserAction.accessReviewResourceUserId;
    resourceUserActionByUarResourceUserId[id] = resourceUserAction;
  });

  const rows: ResourceUserRow[] = filteredSearchResourceUsers.map(
    (resourceUser) => {
      const resourceUserAction =
        resourceUserActionByUarResourceUserId[resourceUser.id];
      return {
        id: resourceUser.id,
        user: resourceUser.user?.fullName || resourceUser.userId,
        outcome: resourceUser.statusAndOutcome.outcome,
        updatedRole: resourceUserAction?.updatedAccessLevel?.accessLevelName,
        role: resourceUser.accessLevel.accessLevelName ?? "",
        note: resourceUserAction?.note ?? undefined,
        data: resourceUser,
      };
    }
  );

  const resourceHasRoles = resourceUsers.some(
    (user) => !!user.accessLevel.accessLevelRemoteId
  );

  const bulkState = calculateBatchAcceptRevokeState(
    performReviewState.resourceUserActions,
    resourceUsers.length
  );
  const bulkDecisionHeader = (
    <DecisionButtons
      state={bulkState}
      resourceId={resource.id}
      onApproveClick={() =>
        handleBulkReviewAction(
          bulkState === AccessReviewAction.Accept
            ? AccessReviewAction.NoAction
            : AccessReviewAction.Accept
        )
      }
      onRevokeClick={() =>
        handleBulkReviewAction(
          bulkState === AccessReviewAction.Revoke
            ? AccessReviewAction.NoAction
            : AccessReviewAction.Revoke
        )
      }
      onRoleSelect={(role) => {
        if (!performReviewState) {
          return;
        }

        let updatedPerformReviewState = {
          ...performReviewState,
        };
        resourceUsers.forEach((resourceUser) => {
          updatedPerformReviewState = updateReviewStateForResourceUserRoleChange(
            updatedPerformReviewState,
            resourceUser,
            role as Maybe<ResourceAccessLevel>
          );
        });

        accessReviewDispatch({
          type: AccessReviewContextActionType.AccessReviewItemUpdate,
          payload: {
            performReviewStateByUARResourceId: {
              ...accessReviewState.performReviewStateByUARResourceId,
              [accessReviewResourceId]: updatedPerformReviewState,
            },
          },
        });
      }}
      showRole={resourceHasRoles}
      clearBackground
    />
  );

  const columns: Header<ResourceUserRow>[] = [
    {
      id: "user",
      label: "User",
      width: resourceHasRoles ? 200 : 400,
      customCellRenderer: (row) => {
        const user = row.data.user;
        if (!user) {
          return <></>;
        }
        return <UserCell user={user} />;
      },
    },
    {
      id: "expires",
      label: "Expires",
      width: resourceHasRoles ? 160 : 200,
      customCellRenderer: (row) => {
        const expirationString =
          row.data.resourceUser?.access?.latestExpiringAccessPoint?.expiration;
        if (!expirationString) {
          return <>Never</>;
        }
        const expires = moment(expirationString).fromNow();
        return <span>{expires}</span>;
      },
    },
  ];
  if (resourceHasRoles) {
    columns.push({
      id: "role",
      label: "Role",
      width: 200,
      customCellRenderer: (row) => {
        return (
          <span
            className={sprinkles({
              color: row.updatedRole ? "blue600" : "black",
            })}
          >
            {row.updatedRole ?? row.role}
          </span>
        );
      },
    });
  }
  columns.push(
    {
      id: "outcome",
      label: "",
      sortable: false,
      customHeader: rows.length > 0 ? bulkDecisionHeader : <></>,
      width: resourceHasRoles ? 160 : 100,
      customCellRenderer: (row) => {
        const state = getResourceUserReviewState(row.id);
        return (
          <DecisionButtons
            state={state}
            resourceId={resource.id}
            onApproveClick={() =>
              handleReviewAction(
                state === AccessReviewAction.Accept
                  ? AccessReviewAction.NoAction
                  : AccessReviewAction.Accept,
                row.id
              )
            }
            onRevokeClick={() =>
              handleReviewAction(
                state === AccessReviewAction.Revoke
                  ? AccessReviewAction.NoAction
                  : AccessReviewAction.Revoke,
                row.id
              )
            }
            onRoleSelect={(role) => {
              if (!performReviewState) {
                return;
              }
              const resourceUser = row.data;

              const updatedPerformReviewState = updateReviewStateForResourceUserRoleChange(
                performReviewState,
                resourceUser,
                role as Maybe<ResourceAccessLevel>
              );

              accessReviewDispatch({
                type: AccessReviewContextActionType.AccessReviewItemUpdate,
                payload: {
                  performReviewStateByUARResourceId: {
                    ...accessReviewState.performReviewStateByUARResourceId,
                    [accessReviewResourceId]: updatedPerformReviewState,
                  },
                },
              });
            }}
            showRole={resourceHasRoles}
          />
        );
      },
    },
    {
      id: "note",
      label: "",
      sortable: false,
      width: 40,
      customCellRenderer: (row) => (
        <NoteCell
          note={row.note}
          accessReviewID={accessReviewId}
          targetIDs={[row.id]}
          targetType={AccessReviewType.ResourceUser}
          onNoteChange={(updatedNoteContent) => {
            const resourceUser = row.data;
            if (!performReviewState) {
              return;
            }

            const existingInfo = performReviewState.resourceUserActions.find(
              (resourceUserAction) =>
                resourceUserAction.accessReviewResourceUserId ===
                resourceUser.id
            );

            if (existingInfo) {
              existingInfo.note = updatedNoteContent;
            } else {
              performReviewState.resourceUserActions.push({
                accessReviewResourceUserId: resourceUser.id,
                action: AccessReviewAction.NoAction,
                note: updatedNoteContent,
              });
            }

            accessReviewDispatch({
              type: AccessReviewContextActionType.AccessReviewItemUpdate,
              payload: {
                performReviewStateByUARResourceId: {
                  ...accessReviewState.performReviewStateByUARResourceId,
                  [accessReviewResourceId]: performReviewState,
                },
              },
            });
          }}
        />
      ),
    }
  );

  const renderSubRow = (row: ResourceUserRow) => {
    return <ResourceUserSubRow resourceUser={row.data} />;
  };

  const selectedIds = selectedResourceUserIds[accessReviewResourceId] ?? [];

  return (
    <>
      <ColumnHeader
        title={`${resource.name} ${
          resource.parentResource?.name
            ? `(${resource.parentResource?.name})`
            : ""
        }`}
        icon={{ type: "entity", entityType: resource.resourceType }}
        subtitle={`${resource.connection?.name} / ${
          resourceTypeInfoByType[resource.resourceType].fullName
        } ${
          resource.description ? `(Description: ${resource.description})` : ""
        }`}
        onClose={goBack}
        rightActions={
          <SubmitAccessReviewButton
            accessReviewId={accessReviewId}
            onCancel={goBack}
            entityName={resource.name}
            performReviewState={performReviewState}
          />
        }
      />
      <Divider />
      <div className={sprinkles({ margin: "sm", marginTop: "lg" })}>
        Please review the following access:
      </div>
      <ColumnContent>
        <div className={styles.tableContainer}>
          <div className={styles.searchContainer}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Search this table"
            />
          </div>
          <Table
            rows={rows}
            totalNumRows={rows.length}
            getRowId={(ru) => ru.id}
            columns={columns}
            renderSubRow={renderSubRow}
            emptyState={{
              title: "No users to review",
            }}
            onCheckedRowsChange={(ids, checked) => {
              if (checked) {
                setSelectedResourceUserIds(
                  accessReviewResourceId,
                  _.uniq([...ids, ...selectedIds])
                );
              } else {
                setSelectedResourceUserIds(
                  accessReviewResourceId,
                  selectedIds.filter((id) => !ids.includes(id))
                );
              }
            }}
            checkedRowIds={new Set(selectedIds)}
            selectAllChecked={selectedIds.length === rows.length}
            onSelectAll={(checked) => {
              if (checked) {
                setSelectedResourceUserIds(
                  accessReviewResourceId,
                  rows.map((row) => row.id)
                );
              } else {
                setSelectedResourceUserIds(accessReviewResourceId, []);
              }
            }}
          />
        </div>
      </ColumnContent>
    </>
  );
};

export default AccessReviewResource;
