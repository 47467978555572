import { AccessReviewConnectionFragment } from "api/generated/graphql";
import AdminRow from "components/forms/rows/AdminRow";
import DescriptionRow from "components/forms/rows/DescriptionRow";
import SnapshotTimeRow from "components/forms/rows/SnapshotTimeRow";
import { connectionTypeInfoByType } from "components/label/ConnectionTypeLabel";
import { FormRow, Label } from "components/ui";
import sprinkles from "css/sprinkles.css";

interface Props {
  accessReviewConnection: AccessReviewConnectionFragment;
}

const AccessReviewConnectionOverview = ({ accessReviewConnection }: Props) => {
  const connection = accessReviewConnection.connection;
  if (!connection) {
    return null;
  }

  return (
    <div className={sprinkles({ padding: "md" })}>
      <SnapshotTimeRow createdAt={accessReviewConnection.createdAt} />
      <FormRow title="Type">
        <Label
          label={connectionTypeInfoByType[connection.connectionType].name}
          icon={{ type: "entity", entityType: connection.connectionType }}
        />
      </FormRow>
      {connection.adminOwner && (
        <AdminRow
          owner={connection.adminOwner}
          mode="view"
          onChange={() => {}}
        />
      )}
      <DescriptionRow
        value={connection.description}
        mode="view"
        onChange={() => {}}
      />
    </div>
  );
};

export default AccessReviewConnectionOverview;
