import { BundleFragment, useUpdateBundleMutation } from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Input, Modal } from "components/ui";
import { useState } from "react";
import { logError } from "utils/logging";

interface Props {
  bundle: BundleFragment;
  onClose: () => void;
}

const BundleRenameModal = (props: Props) => {
  const [name, setName] = useState(props.bundle.name);
  const [error, setError] = useState("");

  const { displaySuccessToast } = useToast();
  const [updateBundle, { loading }] = useUpdateBundleMutation();

  const handleSubmit = async () => {
    try {
      const { data } = await updateBundle({
        variables: {
          input: {
            id: props.bundle.id,
            name,
          },
        },
      });

      switch (data?.updateBundle.__typename) {
        case "UpdateBundleResult":
          displaySuccessToast("Success: bundle name updated");
          props.onClose();
          break;
        case "BundleNameExistsError":
          setError(data.updateBundle.message);
          break;
      }
    } catch (err) {
      logError(err, "Failed to rename bundle");
      setError("Failed to rename bundle");
    }
  };

  return (
    <Modal isOpen onClose={props.onClose} title="Rename Bundle">
      <Modal.Body>
        {error && <ModalErrorMessage errorMessage={error} />}
        <Input value={name} onChange={setName} />
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Submit"
        primaryButtonDisabled={name.length === 0 || name === props.bundle.name}
        primaryButtonLoading={loading}
        onPrimaryButtonClick={handleSubmit}
      />
    </Modal>
  );
};

export default BundleRenameModal;
