import { getModifiedErrorMessage } from "api/ApiContext";
import {
  EventFiltersDocument,
  EventFiltersQuery,
  useCreateEventFilterMutation,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { FormGroup, Input, Modal } from "components/ui";
import { useState } from "react";
import {
  Route,
  Switch,
  useHistory,
  useLocation,
  useRouteMatch,
} from "react-router-dom";
import { logError } from "utils/logging";

import EventsView from "./EventsView";
import { useEventFilter } from "./utils";

const Events = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      <Route
        exact
        path={[
          `${match.path}/filters`,
          `${match.path}/filters/:eventFilterId`,
          `${match.path}/q/:eventFilterId`,
        ]}
        component={EventsView}
      />
      <Route exact path={`${match.path}/:eventId`} component={EventsView} />
      <Route exact path={`${match.path}`} component={EventsView} />
    </Switch>
  );
};

export const SaveFilterModal = ({
  onClose,
  // Default to true because V2/V3 use the same modal
  // V2 should continue redirecting to the event filter
  // management view.
  redirectOnSave = true,
}: {
  onClose: () => void;
  redirectOnSave?: boolean;
}) => {
  const [filterName, setFilterName] = useState("");
  const { displaySuccessToast, displayErrorToast } = useToast();
  const [errorMessage, setErrorMessage] = useState("");
  const history = useHistory();
  const location = useLocation();
  const eventFilter = useEventFilter();

  const [createEventFilter] = useCreateEventFilterMutation();

  const handleSubmit = async () => {
    try {
      const { data } = await createEventFilter({
        variables: {
          input: {
            name: filterName,
            startDate: eventFilter.startDate?.date,
            endDate: eventFilter.endDate?.date,
            eventTypes: eventFilter.eventTypes?.eventTypes,
            objectId: eventFilter.objects?.objectId,
            actorId: eventFilter.actors?.userId,
            systemEvents: eventFilter.systemEvents,
            apiToken: eventFilter.apiTokens,
          },
        },

        update: (cache, { data }) => {
          switch (data?.createEventFilter.__typename) {
            case "CreateEventFilterResult": {
              const eventFilter = data.createEventFilter.eventFilter;
              let cachedEventFiltersQuery = cache.readQuery<EventFiltersQuery>({
                query: EventFiltersDocument,
                variables: {
                  input: {},
                },
              });
              if (cachedEventFiltersQuery && eventFilter) {
                cache.writeQuery<EventFiltersQuery>({
                  query: EventFiltersDocument,
                  variables: {
                    input: {},
                  },
                  data: {
                    ...cachedEventFiltersQuery,
                    eventFilters: {
                      ...cachedEventFiltersQuery.eventFilters,
                      eventFilters: [
                        ...cachedEventFiltersQuery.eventFilters.eventFilters,
                        eventFilter,
                      ],
                    },
                  },
                });
              }
            }
          }
        },
      });

      switch (data?.createEventFilter.__typename) {
        case "CreateEventFilterResult":
          displaySuccessToast("Success: event filter saved");
          setFilterName("");
          if (redirectOnSave) {
            history.replace({
              search: location.search,
              pathname: `/events/filters/${data.createEventFilter.eventFilter.id}`,
            });
          }
          onClose();
          break;
        case "InvalidEventFilterNameError":
          setErrorMessage("please enter a valid name");
          break;
        default:
          logError(new Error(`failed to create event filter`));
      }
    } catch (error) {
      logError(error, "failed to create event filter");
      displayErrorToast(
        getModifiedErrorMessage("failed to save filter", error)
      );
      setFilterName("");
      onClose();
    }
  };

  const handleClose = () => {
    setFilterName("");
    setErrorMessage("");
    onClose();
  };

  return (
    <Modal title="New filter" isOpen={true} onClose={handleClose}>
      <Modal.Body>
        <FormGroup label="Name">
          <Input
            value={filterName}
            onChange={(updatedVal) => {
              setFilterName(updatedVal);
            }}
          />
        </FormGroup>
        {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
      </Modal.Body>
      <Modal.Footer
        onSecondaryButtonClick={handleClose}
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLabel="Save"
        primaryButtonDisabled={filterName.trim() === ""}
      />
    </Modal>
  );
};

export default Events;
