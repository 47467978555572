import { EventFilterFields } from "api/generated/graphql";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import React from "react";
import { EventsTable } from "views/events/EventsTable";

type UserEventsRowProps = {
  userId: string;
};

export const UserEventsRow = (props: UserEventsRowProps) => {
  const eventFilter: EventFilterFields = {
    objects: {
      objectId: props.userId,
    },
  };

  const eventsTable = (
    <EventsTable
      eventFilter={eventFilter}
      emptyTitle={`No events for this user`}
      emptySubtitle={`Interact with this user to add events`}
    />
  );

  return (
    <EntityViewerRow title={"Events"} content={eventsTable} isTable={true} />
  );
};

export default UserEventsRow;
