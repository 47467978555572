import {
  Button,
  DataElement,
  DataElementList,
  FormGroup,
  Modal,
} from "components/ui";
import { Input } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useState } from "react";

interface Props {
  names: string[];
  onChangeNames?: (newNames: string[]) => void;
}

const EntitiesNamedFilter: React.FC<Props> = (props) => {
  const [nameInputValue, setNameInputValue] = useState<string>();
  const [showModal, setShowModal] = useState(false);
  const [modalNames, setModalNames] = useState<string[]>([]);

  const handleEnterName = () => {
    if (nameInputValue) {
      setModalNames([...modalNames, nameInputValue]);
      setNameInputValue("");
    }
  };

  const handleOpenModal = () => {
    setShowModal(true);
    setModalNames(props.names);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    if (!props.onChangeNames) return;
    props.onChangeNames([...modalNames]);
    setShowModal(false);
  };

  if (!props.onChangeNames) {
    if (props.names.length === 0) {
      return null;
    }
    return (
      <FormGroup label="Entities named" fontWeight="normal" fontSize="textMd">
        <DataElementList>
          {props.names.map((name) => (
            <DataElement label={name} color="pink" />
          ))}
        </DataElementList>
      </FormGroup>
    );
  }

  return (
    <>
      <FormGroup label="Entities named" fontWeight="normal" fontSize="textMd">
        <div className={sprinkles({ marginBottom: "sm" })}>
          Add resources, groups, or apps to the access review that match one of
          several names
        </div>
        <DataElementList>
          {props.names.map((name) => (
            <DataElement
              label={name}
              color="pink"
              rightIcon={{
                name: "x",
                onClick: () => {
                  props.onChangeNames &&
                    props.onChangeNames(props.names.filter((n) => n !== name));
                },
              }}
            />
          ))}
          <DataElement
            label="Add item word"
            color="blue"
            leftIcon={{
              name: "plus",
            }}
            onClick={handleOpenModal}
          />
        </DataElementList>
      </FormGroup>
      <Modal
        isOpen={showModal}
        onClose={handleCancel}
        title="Include entities named"
      >
        <Modal.Body>
          <p>
            This access review will include resources and groups whose name
            contains one of the following strings. If no strings are given, the
            access review will only include items matching the other provided
            scopes.
          </p>
          <div
            className={sprinkles({
              display: "flex",
              gap: "sm",
              marginBottom: "md",
            })}
          >
            <Input
              value={nameInputValue}
              onChange={setNameInputValue}
              placeholder="Enter item word"
              onEnter={handleEnterName}
              autoFocus
            />
            <Button
              leftIconName="plus"
              type="primary"
              borderless
              onClick={handleEnterName}
              disabled={!nameInputValue}
            />
          </div>
          <DataElementList>
            {modalNames.map((name) => (
              <DataElement
                label={name}
                color="pink"
                rightIcon={{
                  name: "x",
                  onClick: () => {
                    setModalNames(modalNames.filter((n) => n !== name));
                  },
                }}
              />
            ))}
          </DataElementList>
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Save"
          onPrimaryButtonClick={handleSave}
          primaryButtonDisabled={nameInputValue !== ""}
          secondaryButtonLabel="Cancel"
          onSecondaryButtonClick={handleCancel}
        />
      </Modal>
    </>
  );
};

export default EntitiesNamedFilter;
