import {
  EntityType,
  HrIdpStatus,
  SortDirection,
  SyncErrorFragment,
  SyncTaskFragment,
  SyncType,
  UsersSortByField,
  useSyncStatusQuery,
  useUsersHomeQuery,
} from "api/generated/graphql";
import axios from "axios";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import ColumnListItem from "components/column/ColumnListItem";
import ColumnListScroller from "components/column/ColumnListScroller";
import { ColumnSearchAndSort } from "components/column/ColumnSearchAndSort";
import SyncStatusModal from "components/label/SyncStatusModal";
import InviteTeammatesModal from "components/modals/InviteTeammatesModal";
import useSyncMenuItem, { getSyncLabel } from "components/sync/useSyncMenuItem";
import { useToast } from "components/toast/Toast";
import { Divider, Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";
import UsersFilter, { UsersFilterData } from "views/users/UsersFilter";
import { UsersFilterViewer } from "views/users/UsersFilterViewer";

import UserDetailColumn from "./UserDetailColumn";
import { getUserAvatarIcon } from "./utils";

const SORT_OPTIONS = [
  {
    label: "First Name (A-Z)",
    value: {
      field: UsersSortByField.FirstName,
      direction: SortDirection.Asc,
    },
  },
  {
    label: "First Name (Z-A)",
    value: {
      field: UsersSortByField.FirstName,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Last Name (A-Z)",
    value: {
      field: UsersSortByField.LastName,
      direction: SortDirection.Asc,
    },
  },
  {
    label: "Last Name (Z-A)",
    value: {
      field: UsersSortByField.LastName,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Newest first",
    value: {
      field: UsersSortByField.CreatedAt,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Oldest first",
    value: {
      field: UsersSortByField.CreatedAt,
      direction: SortDirection.Asc,
    },
  },
];

const UsersColumn = () => {
  const history = useHistory();
  const { userId } = useParams<Record<string, string>>();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { authState } = useContext(AuthContext);
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState(SORT_OPTIONS[0]);
  const logEvent = useLogEvent();

  const organizationName = authState.user?.user.organization.name;
  const [usersFilter, setUsersFilter] = useState<UsersFilterData>({});

  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();
  const syncMenuItem = useSyncMenuItem({
    syncType: SyncType.PullHrIdpData,
    queriesToRefetch: ["UsersHome"],
  });

  // NOTE: if you add a new filter, make sure to update ApiContext with the new filter in keyArgs
  const {
    data: usersData,
    error: usersError,
    loading: usersLoading,
    fetchMore,
  } = useUsersHomeQuery({
    variables: {
      input: {
        searchQuery: searchQuery.length > 0 ? searchQuery : undefined,
        sortBy: sortBy.value,
        managerFilter: usersFilter.managerFilter,
        titleFilter: usersFilter.titleFilter,
        teamFilter: usersFilter.teamFilter,
        idpStatusFilter: usersFilter.idpStatusFilter,
      },
    },
    notifyOnNetworkStatusChange: true,
  });
  if (usersError) {
    logError(usersError, `failed to list users home`);
  }

  const {
    data: syncData,
    error: syncError,
    loading: syncLoading,
  } = useSyncStatusQuery({
    variables: {
      input: {
        syncType: SyncType.PullHrIdpData,
      },
    },
  });

  let lastSuccessfulSyncTask: SyncTaskFragment | null = null;
  let syncErrors: SyncErrorFragment[] = [];
  if (syncData) {
    switch (syncData.syncStatus.__typename) {
      case "SyncStatusResult":
        lastSuccessfulSyncTask = syncData.syncStatus.lastSuccessfulSyncTask
          ? syncData.syncStatus.lastSuccessfulSyncTask
          : null;
        syncErrors = syncData.syncStatus.syncErrors;
        break;
      case "InvalidSyncTypeError":
      case "ResourceNotFoundError":
        logError(syncData.syncStatus.message);
        break;
    }
  }

  const users = usersData?.users.users ?? [];
  const cursor = usersData?.users.cursor;
  const totalNumUsers = usersData?.users.totalNumUsers;

  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            input: {
              cursor,
              searchQuery: searchQuery.length > 0 ? searchQuery : undefined,
              sortBy: sortBy.value,
              managerFilter: usersFilter.managerFilter,
              titleFilter: usersFilter.titleFilter,
              teamFilter: usersFilter.teamFilter,
              idpStatusFilter: usersFilter.idpStatusFilter,
            },
          },
        });
      }
    : undefined;

  const renderRow = (index: number) => {
    const user = users[index];
    if (!user) return <></>;

    const avatarIcon = getUserAvatarIcon(user);

    return (
      <ColumnListItem
        key={user.id}
        label={user.fullName}
        sublabel={user.email ?? undefined}
        icon={avatarIcon}
        selected={user.id === userId}
        onClick={() => {
          history.push(
            getResourceUrlNew({
              entityId: user?.id ?? null,
              entityType: EntityType.User,
            })
          );
        }}
        rightContent={
          user?.hrIdpStatus !== HrIdpStatus.Active ? (
            <Icon name="user-x" size="xxs" color="red600" />
          ) : undefined
        }
        inactive={user?.hrIdpStatus !== HrIdpStatus.Active}
      />
    );
  };

  const handleExportUsers = () => {
    displayLoadingToast("Generating export...");
    axios({
      url: "/export/users",
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Opal_All_Users_" + moment().toISOString() + ".csv"
        );
        link.click();
        displaySuccessToast(`Success: downloaded all users`);
      })
      .catch(() => {
        displayErrorToast(`Error: failed to generate export`);
      });
  };

  let sublabel: string;
  if (syncError) {
    sublabel = "Unable to get status";
  } else if (syncLoading) {
    sublabel = "Loading sync status";
  } else {
    sublabel = getSyncLabel(lastSuccessfulSyncTask, syncErrors);
  }

  const hasSyncErrors = authState.user?.isAdmin && syncErrors.length > 0;
  const menuOptions: PropsFor<typeof ColumnHeader>["menuOptions"] = [];
  const addMenuOptions: PropsFor<typeof ColumnHeader>["addMenuOptions"] = [];
  if (authState.user?.isAdmin) {
    if (syncMenuItem) {
      menuOptions.push({
        label: hasSyncErrors ? "View sync errors" : "View sync details",
        sublabel: sublabel,
        onClick: () => {
          logEvent({
            name: "apps_view_sync_details",
            properties: {
              syncType: SyncType.PullHrIdpData,
            },
          });
          setShowSyncModal(true);
        },
        icon: hasSyncErrors
          ? { type: "name", icon: "alert-circle" }
          : { type: "name", icon: "info" },
        type: hasSyncErrors ? "warning" : undefined,
      });
      menuOptions.push(syncMenuItem);
      menuOptions.push({ type: "divider" });
    }
    menuOptions.push({
      label: "Export all users",
      onClick: handleExportUsers,
      icon: { type: "name", icon: "users-right" },
    });
    addMenuOptions.push({
      label: "Add users",
      icon: { type: "name", icon: "plus" },
      onClick: () => setShowCreateModal(true),
    });
  }

  return (
    <>
      {showSyncModal ? (
        <SyncStatusModal
          syncType={SyncType.PullHrIdpData}
          entity={null}
          lastSuccessfulSyncTask={lastSuccessfulSyncTask}
          syncErrors={syncErrors}
          isModalOpen={showSyncModal}
          onClose={() => {
            setShowSyncModal(false);
          }}
        />
      ) : null}
      <Column>
        <ColumnHeader
          title="Users"
          subtitle={organizationName}
          icon={{ type: "name", icon: "user" }}
          count={totalNumUsers}
          menuError={hasSyncErrors}
          menuOptions={menuOptions}
          addMenuOptions={addMenuOptions}
        />
        <Divider margin="md" />
        <ColumnSearchAndSort
          key="search"
          setSearchQuery={setSearchQuery}
          sortBy={sortBy}
          setSortBy={setSortBy}
          sortOptions={SORT_OPTIONS}
          placeholder="Search users"
          trackName="users"
          filterComponent={<UsersFilter onSetFilter={setUsersFilter} />}
        />
        <div className={sprinkles({ marginBottom: "sm" })}>
          <UsersFilterViewer
            filter={usersFilter}
            onSetFilter={setUsersFilter}
          />
        </div>
        <ColumnListScroller
          onLoadMore={loadMoreRows}
          numRows={users.length}
          renderRow={renderRow}
          hasNextPage={Boolean(cursor)}
          loading={usersLoading}
        />
      </Column>
      {userId ? <UserDetailColumn /> : null}
      {showCreateModal ? (
        <InviteTeammatesModal
          isModalOpen={showCreateModal}
          onClose={() => setShowCreateModal(false)}
        />
      ) : null}
    </>
  );
};

export default UsersColumn;
