import {
  ConnectionPreviewLargeFragment,
  ConnectionType,
  Maybe,
  useConnectionPreviewQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import { useParams } from "react-router";
import { logError } from "utils/logging";
import AwsConnectionMetadataSetup from "views/connections/viewer/rows/AwsConnectionMetadataSetup";
import ConnectionMetadataAndCredsSetup from "views/connections/viewer/rows/ConnectionMetadataAndCredsSetup";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import AppsContentColumn from "./AppsContentColumn";

const AppSetupColumn = () => {
  const { appId } = useParams<Record<string, string>>();

  const { data, error, loading } = useConnectionPreviewQuery({
    variables: {
      connectionId: appId,
    },
  });

  let connection: Maybe<ConnectionPreviewLargeFragment> = null;
  if (data) {
    switch (data.connection.__typename) {
      case "ConnectionResult":
        connection = data.connection.connection;
        break;
      case "ConnectionNotFoundError":
        return (
          <Column isContent>
            <NotFoundPage />
          </Column>
        );
      default:
        logError(new Error(`failed to get app`));
    }
  } else if (error) {
    logError(error, `failed to get app`);
  }

  if (loading) {
    return (
      <Column isContent>
        <ColumnContentSkeleton />
      </Column>
    );
  }

  if (error) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  if (!connection) {
    return null;
  }

  return (
    <AppsContentColumn>
      {connection.connectionType === ConnectionType.AwsSso ? (
        <AwsConnectionMetadataSetup connection={connection} />
      ) : (
        <ConnectionMetadataAndCredsSetup connection={connection} />
      )}
    </AppsContentColumn>
  );
};

export default AppSetupColumn;
