import {
  GroupPreviewSmallFragment,
  ResourceAccessLevel,
  useGroupPreviewQuery,
  useResourceAccessLevelsQuery,
} from "api/generated/graphql";
import { Button, EntityIcon, Icon, Select, Skeleton } from "components/ui";
import sprinkles from "css/sprinkles.css";

import * as styles from "./AddItemModal.css";
import ModalErrorMessage from "./ModalErrorMessage";

interface RowProps {
  groupId: string;
  resourceId: string;
  existingRoles: ResourceAccessLevel[];
  roles: ResourceAccessLevel[];
  onGroupRemove: () => void;
  onRoleSelect: (role: ResourceAccessLevel) => void;
  onRoleRemove: (role: ResourceAccessLevel) => void;
  error?: string;
}

// This component is used to represent adding a group to a resource with a specific role.
// e.g. User is currently on Resource A's page. Resource A has roles "Read" and "Write".
// User wants to add Group B to Resource A with role "Read".
const GroupWithResourceRoleRow = (props: RowProps) => {
  const { existingRoles: existingRoles, roles: roles } = props;

  const {
    data: groupData,
    loading: groupLoading,
    error: groupError,
  } = useGroupPreviewQuery({
    variables: {
      input: {
        id: props.groupId,
      },
    },
  });

  const { data, loading, error } = useResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceId: props.resourceId,
      },
    },
  });

  if (loading || groupLoading) {
    return <Skeleton height="44px" />;
  }

  let group: GroupPreviewSmallFragment | undefined;
  switch (groupData?.group.__typename) {
    case "GroupResult":
      group = groupData.group.group;
      break;
  }

  let allRoles: ResourceAccessLevel[] = [];
  switch (data?.accessLevels.__typename) {
    case "ResourceAccessLevelsResult":
      allRoles = data.accessLevels.accessLevels;
  }

  if (error || groupError || !group) {
    return null;
  }

  const hasRoles =
    allRoles.length > 0 && allRoles[0].accessLevelRemoteId !== "";

  const options = allRoles.filter((role) => {
    return ![...existingRoles, ...roles].some(
      (existingRole) =>
        existingRole.accessLevelRemoteId === role.accessLevelRemoteId
    );
  });

  return (
    <div>
      <div className={styles.itemRow}>
        <EntityIcon type={group.groupType} />
        <div className={sprinkles({ flexGrow: 1 })}>
          <div className={sprinkles({ fontSize: "labelMd" })}>{group.name}</div>
          <div className={sprinkles({ color: "gray500", fontSize: "labelSm" })}>
            {group.connection?.name}
          </div>
        </div>
        <Button
          leftIconName="trash-2"
          borderless
          type="error"
          onClick={props.onGroupRemove}
          size="sm"
          round
        />
      </div>
      {hasRoles && (
        <div
          className={sprinkles({
            padding: "sm",
            marginTop: "sm",
            backgroundColor: "gray100",
            borderRadius: "md",
          })}
        >
          <Select
            options={options}
            placeholder="Select a role"
            onChange={(opt) => {
              if (opt) {
                props.onRoleSelect(opt);
              }
            }}
            getOptionLabel={(opt) => opt.accessLevelName}
            selectOnly
          />

          {roles
            .filter((role) => Boolean(role.accessLevelRemoteId))
            .map((role) => (
              <div
                className={sprinkles({
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingX: "xs",
                  marginTop: "xs",
                  fontSize: "labelMd",
                })}
              >
                {role.accessLevelName}
                <Icon
                  name="x"
                  size="xs"
                  color="gray600"
                  onClick={() => props.onRoleRemove(role)}
                />
              </div>
            ))}
        </div>
      )}
      {props.error && <ModalErrorMessage errorMessage={props.error} />}
    </div>
  );
};

export default GroupWithResourceRoleRow;
