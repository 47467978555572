import {
  AccessReviewConnectionFragment,
  EventFilterFields,
} from "api/generated/graphql";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import React from "react";
import { EventsTable } from "views/events/EventsTable";

type AccessReviewConnectionEventsRowProps = {
  accessReviewConnection: AccessReviewConnectionFragment;
};

export const AccessReviewConnectionEventsRow = (
  props: AccessReviewConnectionEventsRowProps
) => {
  const eventFilter: EventFilterFields = {
    objects: {
      objectId: props.accessReviewConnection.id,
    },
  };

  const eventsTable = (
    <EventsTable
      eventFilter={eventFilter}
      emptyTitle={`No access reviews events for this app`}
      emptySubtitle={`Conduct access reviews on this app to add events`}
    />
  );

  return (
    <EntityViewerRow title={"Events"} content={eventsTable} isTable={true} />
  );
};

export default AccessReviewConnectionEventsRow;
