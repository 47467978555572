import { RecommendationsSubscoreType } from "api/generated/graphql";
import { Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React from "react";
import ThreatPill from "views/recommendations/ThreatPill";

import * as styles from "./RiskScoreInformationModal.css";

const RiskScoreInformationModal = ({ onClose }: { onClose: () => void }) => {
  return (
    <Modal isOpen onClose={onClose} title={"How are risk scores calculated?"}>
      <Modal.Body>
        <div className={styles.header}>
          Opal calculates risk scores based on a weighted average of the risk
          factors below
        </div>
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            gap: "lg",
          })}
        >
          <div className={styles.informationSection}>
            <ThreatPill
              type={RecommendationsSubscoreType.PerpetualAccess}
              hidePopover
            />
            Duration of access is imported from third party IDPs / applications
            and updated as access is granted through Opal.
          </div>
          <div className={styles.informationSection}>
            <ThreatPill
              type={RecommendationsSubscoreType.UnusedAccess}
              hidePopover
            />
            Usage data for cloud applications are gathered from usage of the
            specific resource from cloud logs, and from successful logins for
            other applications.
          </div>
          <div className={styles.informationSection}>
            <ThreatPill
              type={RecommendationsSubscoreType.ResourceVulnerability}
              hidePopover
            />
            Applications and resources are rated based on what are most
            sensitive (crown jewels) and what are least compromising
            (applications and resources without PII or critical business flows).
          </div>
          <div className={styles.informationSection}>
            <ThreatPill
              type={RecommendationsSubscoreType.UnmanagedAccess}
              hidePopover
            />
            Application access that is provisioned external to your Opal
            approval flow is marked as outside access, often called Shadow IT.
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer
        secondaryButtonLabel={"Close"}
        onSecondaryButtonClick={onClose}
      />
    </Modal>
  );
};

export default RiskScoreInformationModal;
