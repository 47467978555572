import { getModifiedErrorMessage } from "api/ApiContext";
import {
  EntityType,
  Maybe,
  RemoveResourceUserInput,
  useRemoveResourceUsersMutation,
  UserPreviewSmallFragment,
  UserResourceFragment,
  useUserResourcesQuery,
} from "api/generated/graphql";
import { ConditionalEditor } from "components/entity_viewer/editor/ConditionalEditor";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import ResourceLabel from "components/label/item_labels/ResourceLabel";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import ResourceRoleSelectModal from "components/modals/ResourceRoleSelectModal";
import { SelectType } from "components/modals/update/SelectItemsModal";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import _ from "lodash";
import pluralize from "pluralize";
import React, { useState } from "react";
import { logError } from "utils/logging";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ViewSkeleton from "views/loading/ViewSkeleton";
import UserResourcesTable from "views/resources/UserResourcesTable";
import UserAddResourcesModal from "views/users/UserAddResourcesModal";

import { Role } from "../../../../components/modals/ResourceIndividualRoleModal";
import { Checkbox } from "../../../../components/ui";

type UserResourcesRowProps = {
  userId: string;
};

export const UserResourcesRow = (props: UserResourcesRowProps) => {
  const [showResourcesAddModal, setShowResourcesAddModal] = useState(false);
  const [showResourcesRemoveModal, setShowResourcesRemoveModal] = useState(
    false
  );
  const [showUnmanagedResources, setShowUnmanagedResources] = useState(false);

  const { data, previousData, loading, error } = useUserResourcesQuery({
    variables: {
      id: props.userId,
    },
  });

  if (loading && !data && !previousData) {
    return <ViewSkeleton />;
  }

  if (error) {
    return <UnexpectedErrorPage error={error} />;
  }

  let user: UserPreviewSmallFragment | undefined;
  let userResources: UserResourceFragment[] = [];
  if (previousData?.user.__typename === "UserResult") {
    user = previousData.user.user;
    userResources = previousData.user.user.userResources;
  }
  if (data?.user.__typename === "UserResult") {
    user = data.user.user;
    userResources = data.user.user.userResources;
  }

  if (!user) {
    return <NotFoundPage entity="User" />;
  }

  const editor = (
    <ConditionalEditor
      menuOptions={[
        {
          label: "Add",
          handler: () => {
            setShowResourcesAddModal(true);
          },
        },
        {
          label: "Remove",
          handler: () => {
            setShowResourcesRemoveModal(true);
          },
        },
      ]}
      disabledInReadOnlyMode
    />
  );

  const addUserToResourcesModal = (
    <UserAddResourcesModal
      user={user}
      userResources={userResources}
      onClose={() => {
        setShowResourcesAddModal(false);
      }}
    />
  );

  const removeUserFromResourcesModal = (
    <RemoveUserFromResourcesModal
      key={`remove-user-from-resources-modal`}
      user={user}
      resources={userResources
        .filter((resource) => resource.access?.directAccessPoint)
        .filter((resource) => resource.resource?.isManaged)}
      showModal={showResourcesRemoveModal}
      setShowModal={setShowResourcesRemoveModal}
    />
  );

  const showUnmanagedResourcesCheckbox = (
    <Checkbox
      size="sm"
      label="Show unmanaged resources"
      checked={showUnmanagedResources}
      onChange={(value) => {
        setShowUnmanagedResources(value);
      }}
    />
  );

  return (
    <EntityViewerRow
      title={"Resources"}
      content={
        <EmptyStateContentWrapper
          content={
            <UserResourcesTable
              user={user}
              userResources={userResources}
              showUnmanagedResources={showUnmanagedResources}
            />
          }
          entityType={EntityType.Resource}
          title={`No resources for user`}
          subtitle={`Grant user access to resources to populate the table`}
          buttonTitle={`Add resources`}
          isEmpty={userResources.length === 0}
          onClickHandler={() => {
            setShowResourcesAddModal(true);
          }}
        />
      }
      editor={editor}
      checkbox={showUnmanagedResourcesCheckbox}
      modals={
        <>
          {showResourcesAddModal && addUserToResourcesModal}
          {showResourcesRemoveModal && removeUserFromResourcesModal}
        </>
      }
      isTable={true}
    />
  );
};

type RemoveUserFromResourcesModalProps = {
  user: UserPreviewSmallFragment;
  resources: UserResourceFragment[];
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

const RemoveUserFromResourcesModal = (
  props: RemoveUserFromResourcesModalProps
) => {
  const [roleByResourceIdToRemove, setRoleByResourceIdToRemove] = useState<
    Record<string, Role[]>
  >({});

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const [removeResourceUsers, { loading }] = useRemoveResourceUsersMutation();
  const startPushTaskPoll = usePushTaskLoader();

  const directRolesByResourceId: Record<string, Role[]> = {};
  const numDirectAccessPointsByResourceId: Record<string, number> = {};
  props.resources.forEach((resource) => {
    if (!directRolesByResourceId[resource.resourceId]) {
      directRolesByResourceId[resource.resourceId] = [];
    }
    if (resource?.access?.accessLevel) {
      directRolesByResourceId[resource.resourceId] = _.uniqBy(
        [...directRolesByResourceId[resource.resourceId], resource.accessLevel],
        "accessLevelRemoteId"
      );
    }

    if (!numDirectAccessPointsByResourceId[resource.resourceId]) {
      numDirectAccessPointsByResourceId[resource.resourceId] = 0;
    }
    numDirectAccessPointsByResourceId[resource.resourceId] += 1;
  });

  const modalReset = () => {
    props.setShowModal(false);
    setErrorMessage(null);
    setRoleByResourceIdToRemove({});
    setSearchQuery("");
  };

  return (
    <ResourceRoleSelectModal
      key={"resources_remove"}
      title={"Remove resources"}
      selectType={SelectType.Remove}
      itemName={"resource"}
      setErrorMessage={setErrorMessage}
      entryInfos={_.uniqBy(props.resources, "resourceId")
        .filter((resource) =>
          resource.resource?.name
            .toLowerCase()
            .includes(searchQuery.toLocaleLowerCase())
        )
        .sort((a, b) => {
          if (a && b) {
            const aSortString = a.resource?.name || "";
            const bSortString = b.resource?.name || "";
            return aSortString.localeCompare(bSortString);
          }
          return 0;
        })
        .map((userResource) => {
          const directRoleCount =
            numDirectAccessPointsByResourceId[userResource.resourceId] || 0;
          return {
            entityId: {
              entityId: userResource.resourceId,
              entityType: EntityType.User,
            },
            label: (
              <ResourceLabel
                resourceId={userResource.resourceId}
                text={userResource.resource?.name}
                subText={`${directRoleCount} direct ${pluralize(
                  "role",
                  directRoleCount
                )}`}
                icon={
                  userResource.resource?.iconUrl ||
                  getResourceTypeInfo(userResource?.resource?.resourceType)
                    ?.icon
                }
                bold={true}
                iconLarge={true}
              />
            ),
            isBold: false,
            isBreadcrumb: false,
          };
        })}
      isModalOpen={props.showModal}
      onClose={modalReset}
      onSubmit={async () => {
        try {
          const resourceUsersToRemove: RemoveResourceUserInput[] = [];
          for (const [resourceId, roles] of Object.entries(
            roleByResourceIdToRemove
          )) {
            roles.forEach((role) => {
              resourceUsersToRemove.push({
                userId: props.user.id,
                resourceId: resourceId,
                accessLevel: {
                  accessLevelName: role.accessLevelName,
                  accessLevelRemoteId: role.accessLevelRemoteId,
                },
              });
            });
          }

          const { data } = await removeResourceUsers({
            variables: {
              input: {
                resourceUsers: resourceUsersToRemove,
              },
            },
          });
          switch (data?.removeResourceUsers.__typename) {
            case "RemoveResourceUsersResult":
              startPushTaskPoll(data.removeResourceUsers.taskId, {
                refetchOnComplete: [{ userId: props.user.id }],
              });
              modalReset();
              break;
            case "OpalAdminRoleMustHaveAtLeastOneDirectUser":
              logError(new Error(data.removeResourceUsers.message));
              setErrorMessage(data.removeResourceUsers.message);
              break;
            default:
              logError(
                new Error(
                  `failed to remove user access to the selected resources`
                )
              );
              setErrorMessage(
                "Error: failed to remove user access to the selected resources"
              );
          }
        } catch (error) {
          logError(
            error,
            "failed to remove user access to the selected resources"
          );
          setErrorMessage(
            getModifiedErrorMessage(
              "Error: failed to remove user access to the selected resources",
              error
            )
          );
        }
      }}
      loading={loading}
      errorMessage={errorMessage}
      submitDisabled={Object.keys(roleByResourceIdToRemove).length === 0}
      existingDirectRolesByEntityId={directRolesByResourceId}
      updatedRolesByEntityId={roleByResourceIdToRemove}
      setUpdatedRolesByEntityId={setRoleByResourceIdToRemove}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    />
  );
};

export default UserResourcesRow;
