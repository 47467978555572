import {
  EntityType,
  ResourceType,
  ThirdPartyProvider,
  useAppsBulkEditQuery,
  useBulkUpdateMutation,
  Visibility,
} from "api/generated/graphql";
import { ResourceConfig } from "components/forms/common";
import BreakGlassUsersRow from "components/forms/rows/BreakGlassUsersRow";
import ConditionalConfigs from "components/forms/rows/conditional_config/ConditionalConfigs";
import ConfigurationTemplateRow from "components/forms/rows/ConfigurationTemplateRow";
import GroupLeaderUsersRow from "components/forms/rows/GroupLeaderUsersRow";
import SlackChannelsRowV3 from "components/forms/rows/SlackChannelsRowV3";
import TagsRowV3 from "components/forms/rows/TagsRowV3";
import VisibilityRow from "components/forms/rows/VisibilityRow";
import {
  makeDefaultRequestConfig,
  makeRequestConfigsInput,
  validateResourceConfig,
} from "components/forms/utils";
import { TooltipPlacement } from "components/label/Label";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { PillV3 } from "components/pills/PillsV3";
import { useToast } from "components/toast/Toast";
import {
  Banner,
  Checkbox,
  DataElementList,
  Icon,
  Input,
  Switch,
  Tooltip,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { FC, useContext, useState } from "react";
import { useLocation } from "react-router";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import { isSessionableType } from "utils/directory/connections";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import useSyncStatusToast from "utils/sync/useSyncStatusToast";
import { ForbiddenPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { GroupOnCallSchedulesEdit } from "views/groups/oncall/GroupOnCallSchedulesModal";
import OrgContext from "views/settings/OrgContext";

import * as styles from "./AppsBulkEditView.css";

const BULK_EDIT_URL_KEY = "be";

interface SelectedItems {
  resourceIds: string[];
  groupIds: string[];
}

export const makeBulkEditSelectedItemsUrl = (
  selectedItems: SelectedItems
): string => {
  if (selectedItems.resourceIds.length + selectedItems.groupIds.length === 1) {
    if (selectedItems.resourceIds.length === 1)
      return `/resources/${selectedItems.resourceIds[0]}/edit`;
    if (selectedItems.groupIds.length === 1)
      return `/groups/${selectedItems.groupIds[0]}/edit`;
  }
  const outputString = encodeURIComponent(JSON.stringify(selectedItems));
  const output = btoa(outputString);
  return `/apps/bulk-edit?${BULK_EDIT_URL_KEY}=${output}`;
};

const getSelectedItemsFromSearch = (urlSearch: string): SelectedItems => {
  const params = new URLSearchParams(urlSearch);
  const data = params.get(BULK_EDIT_URL_KEY);
  if (!data) return { resourceIds: [], groupIds: [] };

  const inputString = decodeURIComponent(atob(data));
  const parsed = JSON.parse(inputString) as SelectedItems;
  return parsed;
};

const AppsBulkEditView = () => {
  const { search } = useLocation();
  const transitionBack = useTransitionBack();
  const logEvent = useLogEvent();
  const { orgState } = useContext(OrgContext);
  const hasConfigurationTemplates = useFeatureFlag(
    FeatureFlag.ConfigurationTemplates
  );
  const hasGroupBindings = useFeatureFlag(FeatureFlag.GroupBindings);
  const canUseGroupProjects = useFeatureFlag(FeatureFlag.GroupProjects);
  const showSyncStatusToast = useSyncStatusToast({
    loadingText: "Successfully updated, now syncing...",
  });
  const { displaySuccessToast } = useToast();

  const { resourceIds, groupIds } = getSelectedItemsFromSearch(search);
  const totalSelected = resourceIds.length + groupIds.length;

  const [config, setConfig] = useState<Partial<ResourceConfig>>({});
  const [errors, setErrors] = useState<string[]>([]);

  const { data, loading, error } = useAppsBulkEditQuery({
    variables: {
      resourceIds,
      groupIds,
      maxNumEntries: resourceIds.length + groupIds.length,
    },
  });
  const [bulkUpdate, { loading: updateLoading }] = useBulkUpdateMutation({});

  const resources = data?.resources.resources ?? [];
  const groups = data?.groups.groups ?? [];
  const canEdit =
    resources.every((resource) =>
      resource.authorizedActions?.includes(AuthorizedActionManage)
    ) &&
    groups.every((group) =>
      group.authorizedActions?.includes(AuthorizedActionManage)
    );

  if (loading) {
    return <FullscreenSkeleton />;
  }
  if (!canEdit) {
    return <ForbiddenPage />;
  }
  if (error) {
    return <UnexpectedErrorPage error={error} />;
  }

  const handleClose = () => {
    transitionBack(`/apps`);
  };

  const handleChange = (key: keyof ResourceConfig) => (
    val: ResourceConfig[keyof ResourceConfig]
  ) => {
    setConfig({
      ...config,
      [key]: val,
    });
  };

  const handleConfigTemplateAndUseParentConfig = (
    configurationTemplateName?: string | null,
    configurationTemplateId?: string | null,
    useParent?: boolean
  ) => {
    const templateDict =
      configurationTemplateName != null && configurationTemplateId != null
        ? {
            configurationTemplate: {
              name: configurationTemplateName,
              id: configurationTemplateId,
            },
          }
        : {};
    setConfig((prev) => ({
      ...prev,
      ...templateDict,
      useParentConfig: useParent,
    }));
  };

  const handleUseParentConfig = (useParent: boolean) => {
    if (useParent) {
      handleConfigTemplateAndUseParentConfig(
        config.parentConfigurationTemplateName,
        config.parentConfigurationTemplateId,
        useParent
      );
      return;
    }
    handleChange("useParentConfig")(useParent);
    return;
  };

  const topSection = (
    <div className={styles.topSection}>
      <div className={sprinkles({ marginBottom: "sm" })}>
        Editing {totalSelected} {pluralize("Resource", totalSelected)}
      </div>
      <DataElementList maxHeight={68}>
        {resources.map((resource) => (
          <PillV3
            key={resource.id}
            keyText={resource.name}
            pillColor="Teal"
            icon={{ type: "entity", entityType: resource.resourceType }}
          />
        ))}
        {groups.map((group) => (
          <PillV3
            key={group.id}
            keyText={group.name}
            pillColor="Teal"
            icon={{ type: "entity", entityType: group.groupType }}
          />
        ))}
      </DataElementList>
    </div>
  );

  const handleSave = async () => {
    const errors = validateResourceConfig(config, orgState);

    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    const groupIds = groups.map((group) => group.id);
    const resourceIds = resources.map((resource) => resource.id);

    logEvent({
      name: "apps_bulk_select_edit_view",
      properties: {
        numSelected: totalSelected,
      },
    });

    try {
      setErrors([]);
      const input = {
        resourceIds,
        groupIds,
        description: config.description,
        adminOwnerId: config.adminOwner?.id,
        configurationId: config.configurationTemplate
          ? {
              configurationId: config.configurationTemplate?.id,
            }
          : undefined,
        visibility: config.visibility,
        visibilityGroupsIds: config.visibilityGroups,
        messageChannelIds: config.messageChannels?.map((channel) => channel.id),
        requireMfaToApprove: config.requireMfaToApprove,
        requireMfaToConnect: config.requireMfaToConnect,
        breakGlassUsersIds: config.breakGlassUsers?.map((user) => user.id),
        groupLeaderUserIds: config.groupLeaderUsers?.map((user) => user.id),
        onCallSchedules: config.onCallSchedules?.map((schedule) => ({
          scheduleName: schedule.name,
          remoteId: schedule.remoteId,
          thirdPartyProvider: schedule.thirdPartyProvider,
        })),
        tagIds: config.tagIds,
        commonMetadata: config.commonMetadata,
        requestConfigs: makeRequestConfigsInput(config.requestConfigs ?? []),
        forkConfigurationTemplates: config.forkConfigurationTemplates,
        useParentConfig: config.useParentConfig,
        customRequestNotification: Object.keys(config).includes(
          "customRequestNotification"
        )
          ? {
              string: config.customRequestNotification,
            }
          : undefined,
      };
      const { data } = await bulkUpdate({
        variables: {
          input,
        },
      });

      let updatedItemsCount = 0;
      switch (data?.bulkUpdateItems.__typename) {
        case "BulkUpdateItemsResult":
          handleClose();
          if (data.bulkUpdateItems.syncTask?.id) {
            showSyncStatusToast(data.bulkUpdateItems.syncTask.id);
          } else {
            updatedItemsCount =
              (data.bulkUpdateItems.updatedGroups?.length ?? 0) +
              (data.bulkUpdateItems.updatedResources?.length ?? 0);
            displaySuccessToast(
              `Successfully updated ${pluralize(
                "item",
                updatedItemsCount,
                true
              )}`
            );
          }
          break;
        case "InvalidUpdateResourceVisibilityGroupError":
        case "InvalidUpdateGroupVisibilityGroupError":
          setErrors(["Invalid visibility group"]);
          break;
        case "InvalidReviewerSettingsError":
          setErrors(["Invalid reviewer stage configuration"]);
          break;
        case "GroupMaxDurationTooLargeError":
        case "ResourceMaxDurationTooLargeError":
          setErrors([
            "Max access duration cannot exceed org-wide max duration setting",
          ]);
          break;
        case "CannotUpdateConfigurationTemplateError":
          setErrors([
            "One or more items are linked to a configuration template. To change settings controlled by templates, you must first unlink the template.",
          ]);
          break;
        case "TooManyGroupLeadersError":
          logError(new Error("Tried to create too many group leaders"));
          setErrors(["A group cannot have more than 10 leaders"]);
          break;
        default:
          logError("Failed to bulk update items");
          setErrors(["Failed to update items."]);
      }
    } catch (err) {
      logError(err, "Failed to bulk update items");
      setErrors(["Failed to update items."]);
    }
  };

  const allChildResources =
    groups.length === 0 && resources.every((r) => Boolean(r.parentResource));
  const hasItemsWithConfigTemplate =
    groups.some((group) => Boolean(group.configTemplate)) ||
    resources.some((resource) => Boolean(resource.configTemplate));
  const hasAWSAccounts = resources.some(
    (resource) => resource.resourceType === ResourceType.AwsAccount
  );
  const allGroups = resources.length === 0;
  const allGroupBindingSources = hasGroupBindings
    ? groups.every(
        (g) => !g.groupBinding || g.groupBinding?.sourceGroupId === g.id
      )
    : true;

  const hasConfigTemplateForkRequired =
    hasConfigurationTemplates &&
    ((config.configurationTemplate != null &&
      Boolean(config.configurationTemplate.id)) ||
      hasItemsWithConfigTemplate);
  const showTemplatableRows =
    !hasConfigTemplateForkRequired || config.forkConfigurationTemplates;
  const isSlackIntegrationEnabled =
    orgState.orgThirdPartyIntegrations?.some(
      (integration) =>
        integration.thirdPartyProvider === ThirdPartyProvider.Slack
    ) ?? false;
  const showMessageChannels = !hasAWSAccounts && isSlackIntegrationEnabled;
  const allSessionable =
    groups.length === 0 &&
    resources.every(
      (resource) =>
        resource.connection?.connectionType &&
        isSessionableType(resource.connection.connectionType)
    );

  const hasChange =
    config !== undefined &&
    Object.values(config).length > 0 &&
    Object.values(config).some((value) => value !== undefined);

  return (
    <FullscreenView
      title="Bulk edit"
      onCancel={handleClose}
      onPrimaryButtonClick={handleSave}
      primaryButtonLoading={updateLoading}
      primaryButtonDisabled={!hasChange}
      topSection={topSection}
    >
      <FullscreenView.Content>
        <div className={sprinkles({ paddingX: "xl" })}>
          {errors.map((error) => (
            <Banner message={error} type="error" marginBottom="md" />
          ))}
          <div
            className={sprinkles({ marginBottom: "md", fontSize: "textMd" })}
          >
            Check the values below that you would like to override
          </div>
          <EditSection
            label="Description"
            checked={config.description != null}
            onChange={(checked) => {
              if (!checked) {
                setConfig((prev) => ({
                  ...prev,
                  description: undefined,
                  commonMetadata: prev.commonMetadata
                    ? {
                        ...prev.commonMetadata,
                        matchRemoteDescription: undefined,
                      }
                    : undefined,
                }));
              } else {
                handleChange("description")("");
              }
            }}
          >
            <Input
              value={config.description}
              onChange={handleChange("description")}
              placeholder="Enter description"
              type="textarea"
            />
            <Checkbox
              checked={config.commonMetadata?.matchRemoteDescription ?? false}
              label="Use description from end system"
              onChange={(checked) => {
                handleChange("commonMetadata")({
                  ...config.commonMetadata,
                  matchRemoteDescription: checked,
                });
              }}
            />
          </EditSection>
          <EditSection
            label="Tags"
            checked={config.tagIds != null}
            onChange={(checked) =>
              handleChange("tagIds")(checked ? [] : undefined)
            }
          >
            <TagsRowV3
              mode="edit"
              selectedTagIds={config.tagIds}
              onChange={handleChange("tagIds")}
              includeTitle={false}
            />
          </EditSection>
          {hasConfigurationTemplates && !config.forkConfigurationTemplates && (
            <EditSection
              label="Configuration Template"
              checked={config.configurationTemplate != null}
              onChange={(checked) =>
                handleChange("configurationTemplate")(
                  checked ? { name: "", id: "" } : undefined
                )
              }
            >
              <ConfigurationTemplateRow
                mode="edit"
                onChange={handleChange("configurationTemplate")}
                configurationTemplate={config.configurationTemplate}
                showParentConfigSettings={allChildResources}
                useParentConfig={config.useParentConfig ?? false}
                onChangeUseParentConfig={handleUseParentConfig}
                includeTitle={false}
              />
            </EditSection>
          )}
          {hasConfigTemplateForkRequired && (
            <>
              {!config.forkConfigurationTemplates && (
                <Banner
                  type="warning"
                  message={
                    "One or more items is linked to a configuration template. To bulk-change settings controlled by templates, you must first unlink the templates. If you want to make a mass-change to templated items, please edit the template instead."
                  }
                  marginBottom="sm"
                />
              )}
              <Checkbox
                label={"Unlink configuration templates"}
                checked={config.forkConfigurationTemplates ?? false}
                onChange={(checked) => {
                  handleChange("forkConfigurationTemplates")(checked);
                }}
              />
            </>
          )}
          {showTemplatableRows && (
            <>
              <EditSection
                label="Admin"
                checked={config.adminOwner != null}
                onChange={(checked) =>
                  handleChange("adminOwner")(
                    checked
                      ? {
                          name: "",
                          id: "",
                        }
                      : undefined
                  )
                }
              >
                <OwnerDropdown
                  selectedOwnerId={config.adminOwner?.id}
                  onSelectOwner={handleChange("adminOwner")}
                />
              </EditSection>
              {showMessageChannels && (
                <EditSection
                  label="Linked audit Slack channels"
                  checked={config.messageChannels != null}
                  onChange={(checked) =>
                    handleChange("messageChannels")(checked ? [] : undefined)
                  }
                >
                  <SlackChannelsRowV3
                    mode="edit"
                    messageChannels={config.messageChannels}
                    onChange={handleChange("messageChannels")}
                    adminOwnerName={config.adminOwner?.name ?? ""}
                  />
                </EditSection>
              )}
              {allGroups && (
                <>
                  <EditSection
                    label="Members On-Call Schedules"
                    checked={config.onCallSchedules != null}
                    onChange={(checked) =>
                      handleChange("onCallSchedules")(checked ? [] : undefined)
                    }
                    warningTooltipText={
                      !allGroupBindingSources
                        ? `One or more of the resources you selected is a linked
                          group that is not marked as the source of truth. Those
                          groups can't configure their on call schedule users, so any
                          changes you make here will not be reflected for those groups.`
                        : undefined
                    }
                  >
                    <GroupOnCallSchedulesEdit
                      onCallSchedules={config.onCallSchedules ?? []}
                      onChange={handleChange("onCallSchedules")}
                      isV3
                    />
                  </EditSection>
                  <EditSection
                    label="Break-Glass Users"
                    checked={config.breakGlassUsers != null}
                    onChange={(checked) =>
                      handleChange("breakGlassUsers")(checked ? [] : undefined)
                    }
                    warningTooltipText={
                      !allGroupBindingSources
                        ? `One or more of the resources you selected is a linked
                         group that is not marked as the source of truth. Those
                         groups can't configure their break-glass users, so any
                         changes you make here will not be reflected for those groups.`
                        : undefined
                    }
                  >
                    <BreakGlassUsersRow
                      mode="edit"
                      breakGlassUsers={config.breakGlassUsers}
                      onChange={handleChange("breakGlassUsers")}
                      includeTitle={false}
                    />
                  </EditSection>
                  {canUseGroupProjects ? (
                    <EditSection
                      label="Group Leaders"
                      checked={config.groupLeaderUsers != null}
                      onChange={(checked) =>
                        handleChange("groupLeaderUsers")(
                          checked ? [] : undefined
                        )
                      }
                    >
                      <GroupLeaderUsersRow
                        groupLeaderUsers={config.groupLeaderUsers}
                        onChange={handleChange("groupLeaderUsers")}
                        isBulkEdit={true}
                      />
                    </EditSection>
                  ) : null}
                </>
              )}
              {!hasAWSAccounts && (
                <>
                  <EditSection
                    label="MFA to approve"
                    checked={config.requireMfaToApprove != null}
                    onChange={(checked) =>
                      handleChange("requireMfaToApprove")(
                        checked ? false : undefined
                      )
                    }
                  >
                    <Switch
                      label="Required"
                      checked={config.requireMfaToApprove ?? false}
                      onChange={(checked) => {
                        handleChange("requireMfaToApprove")(checked);
                      }}
                    />
                  </EditSection>
                  {allSessionable && (
                    <EditSection
                      label="MFA to connect"
                      checked={config.requireMfaToConnect != null}
                      onChange={(checked) =>
                        handleChange("requireMfaToConnect")(
                          checked ? false : undefined
                        )
                      }
                    >
                      <Switch
                        label="Required"
                        checked={config.requireMfaToConnect ?? false}
                        onChange={(checked) => {
                          handleChange("requireMfaToConnect")(checked);
                        }}
                      />
                    </EditSection>
                  )}
                </>
              )}
              <EditSection
                label="Visibility"
                checked={config.visibility != null}
                onChange={(checked) => {
                  if (checked) {
                    handleChange("visibility")(Visibility.Global);
                  } else {
                    setConfig((prev) => ({
                      ...prev,
                      visibility: undefined,
                      visibilityGroups: undefined,
                    }));
                  }
                }}
              >
                <VisibilityRow
                  mode="edit"
                  entityType={EntityType.Resource}
                  visibility={config.visibility}
                  visibilityGroups={config.visibilityGroups ?? []}
                  parentResourceName={config.parentResourceName}
                  parentResourceVisibility={config.parentResourceVisibility}
                  parentResourceVisibilityGroups={
                    config.parentResourceVisibilityGroups
                  }
                  onChangeVisibilityAndGroups={(val: {
                    visibility?: Visibility;
                    groupIds?: string[];
                  }) => {
                    const { visibility, groupIds } = val;
                    const visDict = visibility ? { visibility } : {};
                    const visGroupsDict = groupIds
                      ? { visibilityGroups: groupIds }
                      : {};
                    setConfig((prev) => ({
                      ...prev,
                      ...visDict,
                      ...visGroupsDict,
                    }));
                  }}
                  contentOnly
                  isV3
                />
              </EditSection>
              {!hasAWSAccounts && (
                <EditSection
                  label="Request Configuration"
                  checked={config.requestConfigs != null}
                  onChange={(checked) =>
                    handleChange("requestConfigs")(
                      checked ? [makeDefaultRequestConfig()] : undefined
                    )
                  }
                  warningTooltipText={
                    !allGroupBindingSources
                      ? `One or more of the resources you selected is a linked
                         group that is not marked as the source of truth. Those
                         groups inherit their request configuration from the source
                         of truth, so any changes you make here will not be
                         reflected for those groups.`
                      : undefined
                  }
                >
                  <ConditionalConfigs
                    mode="edit"
                    entityType={EntityType.Resource}
                    requestConfigs={config.requestConfigs ?? []}
                    onChange={handleChange("requestConfigs")}
                    isViewingAsNonAdmin={false}
                    includeTitle={false}
                    isV3
                    customRequestNotification={config.customRequestNotification}
                    onCustomRequestNotificationChange={handleChange(
                      "customRequestNotification"
                    )}
                  />
                </EditSection>
              )}
            </>
          )}
        </div>
      </FullscreenView.Content>
    </FullscreenView>
  );
};

interface EditSectionProps {
  label: string;
  checked: boolean;
  onChange: (checked: boolean) => void;
  warningTooltipText?: string;
}

const EditSection: FC<EditSectionProps> = ({
  children,
  label,
  checked,
  onChange,
  warningTooltipText,
}) => {
  return (
    <div className={sprinkles({ marginBottom: "sm" })}>
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          gap: "md",
        })}
      >
        <Checkbox label={label} checked={checked} onChange={onChange} />
        {warningTooltipText && (
          <Tooltip
            tooltipText={warningTooltipText}
            placement={TooltipPlacement.Bottom}
          >
            <Icon name="alert-circle" color={"red500V3"} size="sm" />
          </Tooltip>
        )}
      </div>
      {checked && (
        <div className={sprinkles({ marginLeft: "xl", marginY: "md" })}>
          {children}
        </div>
      )}
    </div>
  );
};

export default AppsBulkEditView;
