import { Maybe } from "api/generated/graphql";
import { Button, FileUpload } from "components/ui";
import { useState } from "react";
import { logError } from "utils/logging";

type UploadCertButtonProps = {
  uploadLabelId: string;
  buttonTitle: string;
  setCertContent: (certContent: Maybe<string>) => void;
  setErrorMessage: (errorMessage: string) => void;
};

export const UploadCertButton = (props: UploadCertButtonProps) => {
  const [uploadButtonState, setUploadButtonState] = useState(props.buttonTitle);

  return (
    <FileUpload
      accept={[".pem", ".crt"]}
      renderButton={(onClick) => (
        <Button label={uploadButtonState} onClick={onClick} borderless />
      )}
      handleUpload={(file: File) => {
        const fileReader = new FileReader();
        fileReader.onload = async () => {
          try {
            const content = fileReader.result;
            if (content) {
              props.setCertContent(btoa(String(content)));
            }
          } catch (error) {
            logError(error, `failed to parse cert content`);
            props.setErrorMessage("Error: failed to parse cert content");
          }
        };
        if (file) {
          setUploadButtonState(file.name);
          fileReader.readAsText(file);
        }
      }}
    />
  );
};
