import { Maybe, useCreateOrganizationMutation } from "api/generated/graphql";
import { CustomInputDark } from "components/mui/MUIOverrides";
import { Button } from "components/ui";
import React, { useState } from "react";
import * as Icons from "react-feather";
import { logError } from "utils/logging";
import {
  ErrorMessage,
  Footer,
  GetEnterHandler,
  Header,
  InputIcon,
} from "views/onboarding/Common";
import { OrgCreateInfo } from "views/onboarding/Onboarding";
import onboardingStyles from "views/onboarding/Onboarding.module.scss";

type AdminFormProps = {
  orgCreateInfo: OrgCreateInfo;
  setOrgCreateInfo: (orgCreateInfo: OrgCreateInfo) => void;
  stepNumber: number;
  setStepNumber: (step: number) => void;
  totalSteps: number;
  progress: number;
};

export const AdminForm = (props: AdminFormProps) => {
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [createOrg, { loading }] = useCreateOrganizationMutation();

  const onSubmit = async () => {
    setErrorMessage(null);

    if (!props.orgCreateInfo.adminEmail?.includes("@")) {
      setErrorMessage(
        "Error: Admin email is not properly formatted. Please try again."
      );
      return;
    }

    try {
      const { data } = await createOrg({
        variables: {
          input: {
            organizationName: props.orgCreateInfo.orgName,
            userAdminEmail: props.orgCreateInfo.adminEmail,
            userAdminFirstName: "",
            userAdminLastName: "",
          },
        },
      });
      switch (data?.createOrganization.__typename) {
        case "CreateOrganizationResult":
          props.setStepNumber(props.stepNumber + 1);
          break;
        case "OrganizationExistsError":
          setErrorMessage(
            "Error: organization already exists. Please try a new name."
          );
          break;
        default:
          logError(new Error(`organization creation failed`));
          setErrorMessage("Error: organization creation failed");
      }
    } catch (error) {
      logError(error, `organization creation failed`);
      setErrorMessage("Error: organization creation failed");
    }
  };

  return (
    <div className={onboardingStyles.content}>
      <Header progress={props.progress} />

      <div className={onboardingStyles.titleHeader}>
        <div className={onboardingStyles.title}>
          Which email would you like to use for the admin?
        </div>
        <div className={onboardingStyles.subtitle}>
          This email will be the first administrator and primary account owner
        </div>
      </div>
      <div className={onboardingStyles.midSection}>
        <CustomInputDark
          value={props.orgCreateInfo.adminEmail || ""}
          onChange={(e) => {
            props.setOrgCreateInfo({
              ...props.orgCreateInfo,
              adminEmail: e.currentTarget.value,
            });
          }}
          startAdornment={
            <InputIcon
              inputIcon={<Icons.Globe strokeWidth={2} />}
              disabled={props.orgCreateInfo.adminEmail === ""}
              error={errorMessage !== null}
            />
          }
          placeholder={`e.g. "john.doe@company.com"`}
          error={errorMessage !== null}
          onKeyDown={GetEnterHandler({ callback: onSubmit })}
        />
      </div>
      {errorMessage && <ErrorMessage errorMessage={errorMessage} />}
      <div className={onboardingStyles.continueButton}>
        <div className={onboardingStyles.buttonDescription}>
          Press enter or click
        </div>
        <Button
          leftIconName="arrow-right"
          label="Continue"
          onClick={onSubmit}
          disabled={!props.orgCreateInfo.adminEmail}
          loading={loading}
        />
      </div>
      <Footer
        step={props.stepNumber}
        totalSteps={props.totalSteps}
        backHandler={() => {
          props.stepNumber > 1 && props.setStepNumber(props.stepNumber - 1);
        }}
      />
    </div>
  );
};

export default AdminForm;
