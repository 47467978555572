import { Maybe, useAllowedToCreateOrgQuery } from "api/generated/graphql";
import React, { ReactElement, useState } from "react";
import { useNavigate } from "react-router-dom-v5-compat";
import onboardingStyles from "views/onboarding/Onboarding.module.scss";
import AdminForm from "views/onboarding/steps/AdminForm";
import OrganizationForm from "views/onboarding/steps/OrganizationForm";
import SignInPrompt from "views/onboarding/steps/SignInPrompt";

export type OrgCreateInfo = {
  orgName: string;
  adminEmail: string;
};

enum OnboardingSteps {
  Organization,
  Admin,
  SignInPrompt,
}

export const Onboarding = () => {
  const navigate = useNavigate();
  useAllowedToCreateOrgQuery({
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      if (data.allowedToCreateOrganizations.allowed === false) {
        navigate("/");
      }
    },
  });

  const [stepNumber, setStepNumber] = useState(1);

  let steps = [
    OnboardingSteps.Organization,
    OnboardingSteps.Admin,
    OnboardingSteps.SignInPrompt,
  ];

  const step = steps[stepNumber - 1];

  const totalSteps = steps.length;
  const progress = stepNumber / totalSteps;

  const [orgCreateInfo, setOrgCreateInfo] = useState<OrgCreateInfo>({
    orgName: "",
    adminEmail: "",
  });

  let content: Maybe<ReactElement> = null;
  switch (step) {
    case OnboardingSteps.Organization:
      content = (
        <OrganizationForm
          orgCreateInfo={orgCreateInfo}
          setOrgCreateInfo={setOrgCreateInfo}
          stepNumber={stepNumber}
          setStepNumber={setStepNumber}
          totalSteps={totalSteps}
          progress={progress}
        />
      );
      break;
    case OnboardingSteps.Admin:
      content = (
        <AdminForm
          orgCreateInfo={orgCreateInfo}
          setOrgCreateInfo={setOrgCreateInfo}
          stepNumber={stepNumber}
          setStepNumber={setStepNumber}
          totalSteps={totalSteps}
          progress={progress}
        />
      );
      break;
    case OnboardingSteps.SignInPrompt:
      content = (
        <SignInPrompt
          orgCreateInfo={orgCreateInfo}
          setOrgCreateInfo={setOrgCreateInfo}
          stepNumber={stepNumber}
          setStepNumber={setStepNumber}
          totalSteps={totalSteps}
          progress={progress}
        />
      );
      break;
  }

  return <div className={onboardingStyles.container}>{content}</div>;
};
