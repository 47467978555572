import moment from "moment";
import { Line } from "react-chartjs-2";

export interface GraphData {
  data: number[];
  label: string;
}

interface Props {
  datasets: GraphData[];
  xAxisLabels: string[];
  renderValue?: (value: number) => string;
  customYAxis?: {
    min: number;
    max: number;
    stepSize: number;
  };
  renderYAxisLabel?: (value: number) => string;
  hideDecimals?: boolean;
}

const LineGraph = (props: Props) => {
  if (props.datasets.length === 0) {
    return null;
  }

  const datasets = props.datasets.map((ds) => {
    return {
      data: ds.data,
      borderColor: "#359FF4",
      backgroundColor: "#359FF4",
      pointRadius: 0,
      label: ds.label,
      borderWidth: 2,
    };
  });

  const options: PropsFor<typeof Line>["options"] = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
        callbacks: {
          title: (item) => {
            if (item[0]) {
              return moment(item[0].label).format("L");
            }
          },
          label: (item) => {
            if (props.renderValue) {
              return props.renderValue(item.parsed.y);
            }
            return item.formattedValue;
          },
        },
      },
    },
    // Show tooltip on hover over any part of the graph to the nearest point.
    interaction: {
      mode: "nearest",
      intersect: false,
      axis: "xy",
    },
    scales: {
      y: {
        min: props.customYAxis?.min ?? 0,
        max: props.customYAxis?.max,
        border: {
          display: false,
        },
        grid: {
          drawOnChartArea: false,
          tickLength: 0,
        },
        ticks: {
          stepSize: props.customYAxis?.stepSize,
          padding: 10,
          color: "#B4B4B4",
          font: {
            family: "IBM Plex Sans, sans",
          },
          precision: props.hideDecimals ? 0 : undefined,
          callback: props.renderYAxisLabel
            ? (value) => {
                if (!props.renderYAxisLabel || typeof value !== "number") {
                  return value;
                }
                return props.renderYAxisLabel(value);
              }
            : undefined,
        },
      },
      x: {
        grid: {
          drawOnChartArea: false,
          tickLength: 0,
        },
        border: {
          display: false,
        },
        ticks: {
          padding: 10,
          autoSkip: false,
          color: "#B4B4B4",
          font: {
            family: "IBM Plex Sans, sans",
          },
          maxRotation: 0,
          precision: 0,
          callback: (_, index) => {
            // Show first, last, and middle dates (unless < 5 days)
            const isFirstDate = index === 0;
            const isLastDate = index === props.xAxisLabels.length - 1;
            const isMiddleDate =
              props.xAxisLabels.length >= 5 &&
              index === Math.floor(props.xAxisLabels.length / 2);
            if (!isFirstDate && !isLastDate && !isMiddleDate) {
              return "";
            }
            return moment(props.xAxisLabels[index]).format("MMM D");
          },
        },
      },
    },
  };

  const data: PropsFor<typeof Line>["data"] = {
    labels: props.xAxisLabels,
    datasets,
  };
  return <Line data={data} options={options} />;
};

export default LineGraph;
