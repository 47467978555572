import { produce } from "immer";
import { useCallback } from "react";
import { useHistory, useLocation } from "react-router";

const GROUP_BINDINGS_FILTER_URL_KEY = "filters";
type GroupBindingsFilterState = {
  connectionIds: string[];
  groupIds: string[];
  groupTypes: string[];
  searchQuery: string;
};

const initialState: GroupBindingsFilterState = {
  connectionIds: [],
  groupIds: [],
  groupTypes: [],
  searchQuery: "",
};

export const useGroupBindingsFilterState = (): GroupBindingsFilterState => {
  const { search } = useLocation();
  if (!search) {
    return initialState;
  }

  const params = new URLSearchParams(search);
  const data = params.get(GROUP_BINDINGS_FILTER_URL_KEY);

  if (!data) {
    return initialState;
  }

  // Parse URL hash for filter state
  const inputString = decodeURIComponent(atob(data));
  return JSON.parse(inputString);
};

export type GroupBindingsFilterAction =
  | {
      type: "SET_CONNECTION_IDS";
      payload: {
        ids: string[];
      };
    }
  | {
      type: "SET_GROUP_IDS";
      payload: {
        ids: string[];
      };
    }
  | {
      type: "SET_GROUP_TYPES";
      payload: {
        types: string[];
      };
    }
  | {
      type: "SET_SEARCH_QUERY";
      payload: {
        searchQuery: string;
      };
    }
  | {
      type: "CLEAR_FILTERS";
    };

export const groupBindingsFilterReducer = (
  state: GroupBindingsFilterState,
  action: GroupBindingsFilterAction
): GroupBindingsFilterState => {
  switch (action.type) {
    case "SET_CONNECTION_IDS":
      return produce(state, (draft) => {
        draft.connectionIds = action.payload.ids;
      });
    case "SET_GROUP_IDS":
      return produce(state, (draft) => {
        draft.groupIds = action.payload.ids;
      });
    case "SET_GROUP_TYPES":
      return produce(state, (draft) => {
        draft.groupTypes = action.payload.types;
      });
    case "SET_SEARCH_QUERY":
      return produce(state, (draft) => {
        draft.searchQuery = action.payload.searchQuery;
      });
    case "CLEAR_FILTERS":
      return produce(state, (draft) => {
        draft.connectionIds = [];
        draft.groupIds = [];
        draft.groupTypes = [];
        draft.searchQuery = "";
      });
  }
};

export const useGroupBindingsFilterDispatch = () => {
  const { search, hash } = useLocation();
  const currentState = useGroupBindingsFilterState();
  const history = useHistory();

  const dispatch = useCallback(
    (action: GroupBindingsFilterAction) => {
      const updatedState = groupBindingsFilterReducer(currentState, action);

      // Encode state to base64 and update to URL
      const encodedState = btoa(
        encodeURIComponent(JSON.stringify(updatedState))
      );
      const params = new URLSearchParams(search);
      params.set(GROUP_BINDINGS_FILTER_URL_KEY, encodedState);

      history.push({
        search: params.toString(),
        hash,
      });
    },
    [currentState, history, search, hash]
  );

  return dispatch;
};
