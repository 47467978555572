import { getModifiedErrorMessage } from "api/ApiContext";
import {
  ConnectionPreviewLargeFragment,
  EntityType,
  GroupType,
  ServiceType,
  useConnectionPreviewQuery,
  useCreateGroupMutation,
  Visibility,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeader";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { Button, Divider, FormGroup, Input } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrlNew } from "utils/common";
import {
  groupTypeByServiceTypeByConnectionType,
  serviceTypesByConnectionType,
} from "utils/directory/services";
import { useMountEffect } from "utils/hooks";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import { GroupCreationInfo } from "./common";

const CreateGroupColumn = () => {
  const { appId: connectionId } = useParams<Record<string, string>>();
  const history = useHistory();

  const { data, loading, error } = useConnectionPreviewQuery({
    variables: {
      connectionId,
    },
    skip: !connectionId,
  });

  let connection: ConnectionPreviewLargeFragment | undefined = undefined;
  switch (data?.connection.__typename) {
    case "ConnectionResult":
      connection = data.connection.connection;
  }

  if (loading) {
    return (
      <Column isContent maxWidth="md">
        <ColumnHeaderSkeleton />
        <Divider />
        <ColumnContentSkeleton />
      </Column>
    );
  }

  if (error || !connection) {
    return (
      <Column isContent maxWidth="md">
        <ColumnHeader
          title="Create Group"
          onClose={() => history.goBack()}
          icon={{ type: "name", icon: "plus" }}
        />
        <Divider />
        <UnexpectedErrorPage />
      </Column>
    );
  }

  const serviceType =
    serviceTypesByConnectionType[connection.connectionType][0];

  const groupType = (groupTypeByServiceTypeByConnectionType[
    connection.connectionType
  ][serviceType] ?? [])[0];

  return (
    <Column isContent maxWidth="md">
      <ColumnHeader
        title="Create Group"
        onClose={() => history.goBack()}
        icon={{ type: "name", icon: "plus" }}
      />
      <Divider />
      <ColumnContent>
        <CreateGroupBody
          connectionId={connectionId}
          serviceType={serviceType}
          groupType={groupType}
        />
      </ColumnContent>
    </Column>
  );
};

interface Props {
  connectionId: string;
  serviceType: ServiceType;
  groupType: GroupType;
}

const CreateGroupBody = (props: Props) => {
  const { displaySuccessToast } = useToast();
  const logEvent = useLogEvent();
  const history = useHistory();

  useMountEffect(() => {
    logEvent({ name: "apps_create_group_start" });
  });

  const [createGroup, { loading }] = useCreateGroupMutation();

  const initCreationState: GroupCreationInfo = {
    name: "",
    adminOwnerId: "",
    connectionId: props.connectionId ?? "",
    groupType: props.groupType ?? GroupType.OpalGroup,
    description: "",
    onCallMetadata: undefined,
    serviceType: props.serviceType ?? ServiceType.Opal,
  };

  const [creationInfo, setCreationInfo] = useState<GroupCreationInfo>(
    initCreationState
  );
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const handleChange = (key: keyof GroupCreationInfo) => (
    val: GroupCreationInfo[keyof GroupCreationInfo]
  ) => {
    setCreationInfo((prev) => ({
      ...prev,
      [key]: val,
    }));
  };

  const handleSubmit = async () => {
    const createGroupInput = {
      name: creationInfo.name,
      description: creationInfo.description,
      groupType: creationInfo.groupType,
      adminOwnerId: creationInfo.adminOwnerId ?? "",
      connectionId: creationInfo.connectionId ?? "",
      visibility: Visibility.Global,
      serviceType: creationInfo.serviceType,
      metadata: {
        onCallMetadata: creationInfo.onCallMetadata,
      },
    };

    try {
      logEvent({
        name: "apps_create_group_end",
      });
      const { data } = await createGroup({
        variables: {
          input: createGroupInput,
        },
        refetchQueries: ["ItemsListSection", "GroupsHome"],
      });
      switch (data?.createGroup.__typename) {
        case "CreateGroupResult":
          history.push(
            getResourceUrlNew({
              entityId: data.createGroup.group.id,
              entityType: EntityType.Group,
            })
          );
          displaySuccessToast(
            `Success: "${data.createGroup.group.name}" group created`
          );
          break;
        case "OktaGroupError":
          setErrorMessage(data?.createGroup.message);
          break;
        case "GoogleGroupError":
          setErrorMessage(data?.createGroup.message);
          break;
        default:
          setErrorMessage(`Error: failed to create group`);
      }
    } catch (error) {
      setErrorMessage(
        getModifiedErrorMessage("Error: failed to create group", error)
      );
    }
  };

  return (
    <div className={sprinkles({ margin: "xs" })}>
      {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
      <FormGroup label="Name" required>
        <Input
          value={creationInfo.name}
          onChange={handleChange("name")}
          placeholder="Enter group name"
        />
      </FormGroup>
      <FormGroup label="Admin" required>
        <OwnerDropdown
          selectedOwnerId={creationInfo.adminOwnerId}
          onSelectOwner={(owner) => {
            if (owner) {
              handleChange("adminOwnerId")(owner.id);
            }
          }}
          clearable={false}
        />
      </FormGroup>
      <FormGroup label="Description">
        <Input
          type="textarea"
          value={creationInfo.description}
          onChange={handleChange("description")}
          placeholder="Enter group description"
        />
      </FormGroup>
      <div
        className={sprinkles({
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        })}
      >
        <Button
          label="Create"
          type="primary"
          onClick={handleSubmit}
          disabled={!creationInfo.name || !creationInfo.adminOwnerId}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default CreateGroupColumn;
