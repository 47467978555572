import { gql, useQuery } from "@apollo/client";
import {
  BundlesSortByField,
  InventoryBundlesQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { useOpalSearchInput } from "components/opal/common/input/OpalSearchInput";
import OpalTable, {
  Columns,
  useServerSortableOpalTable,
} from "components/opal/table/OpalTable";
import { ButtonV3 } from "components/ui";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { useMountEffect } from "utils/hooks";
import { useTransitionTo } from "utils/router/hooks";
import BundleCreateModal from "views/bundles/BundleCreateModal";

type Bundle = NonNullable<InventoryBundlesQuery["bundles"]["bundles"]>[0];

const BUNDLE_COLUMNS: Columns<Bundle, BundlesSortByField> = [
  {
    id: BundlesSortByField.Name,
    label: "Name",
    sortable: true,
    customCellRenderer: (bundle) => bundle.name || "-",
  },
  {
    id: "description",
    label: "Description",
    sortable: false,
    customCellRenderer: (bundle) => bundle.description || "-",
  },
  {
    id: "admin",
    label: "Admin",
    sortable: false,
    customCellRenderer: (bundle) => bundle.adminOwner.name,
  },
  {
    id: "itemCount",
    label: "Resources",
    sortable: false,
    customCellRenderer: (bundle) => bundle.totalNumItems + "",
  },
];

const InventoryBundlesTable = () => {
  const logEvent = useLogEvent();
  const history = useHistory();
  const transitionTo = useTransitionTo();
  const { authState } = useContext(AuthContext);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [searchQuery, searchInput] = useOpalSearchInput({
    placeholder: "Filter by name",
  });

  const { sortByVariable, sortByTableProps } = useServerSortableOpalTable({
    id: BundlesSortByField.Name,
    desc: false,
  });

  const { data, networkStatus } = useQuery(
    gql`
      query InventoryBundles($searchQuery: String, $sortBy: BundlesSortBy) {
        bundles(input: { searchQuery: $searchQuery, sortBy: $sortBy }) {
          bundles {
            id
            name
            description
            adminOwner {
              id
              name
            }
            totalNumItems
          }
        }
      }
    `,
    {
      variables: {
        searchQuery: searchQuery,
        sortBy: sortByVariable,
      },
      fetchPolicy: "cache-and-network",
    }
  );

  useMountEffect(() => {
    logEvent({
      name: "catalog_bundles",
      properties: {
        view: "admin",
      },
    });
  });

  const bundles = data?.bundles.bundles ?? [];

  return (
    <>
      <OpalTable
        rows={bundles}
        filters={searchInput}
        actions={
          authState.user?.isAdmin && (
            <ButtonV3
              label="Bundle"
              type="main"
              size="sm"
              leftIconName="plus"
              onClick={() => {
                history.push(`/bundles/create`);
              }}
            />
          )
        }
        totalNumRows={data?.bundles.totalNumBundles ?? bundles.length}
        getRowId={(bundle) => bundle.id}
        columns={BUNDLE_COLUMNS}
        onRowClick={(bundle, event) => {
          transitionTo(
            {
              pathname: `/inventory/bundles/${bundle.id}/resources`,
            },
            event
          );
        }}
        entityName="Bundle"
        networkStatus={networkStatus}
        {...sortByTableProps}
      />
      {showCreateModal && (
        <BundleCreateModal onClose={() => setShowCreateModal(false)} hasV3 />
      )}
    </>
  );
};

export default InventoryBundlesTable;
