import { curveMonotoneX } from "@visx/curve";
import {
  AreaSeries,
  AreaStack,
  Axis,
  GlyphSeries,
  Tooltip,
  XYChart,
} from "@visx/xychart";
import { useRecommendationsMetricsGraphQuery } from "api/generated/graphql";
import sprinkles from "css/sprinkles.css";
import moment from "moment";

import {
  GRAPH_HEIGHT_MULTIPLIER,
  GRAPH_WIDTH_MULTIPLIER,
  RISK_COLOR_CRITICAL,
  RISK_COLOR_LOW,
} from "./constants";
import * as styles from "./RiskScoreGraph.css";
import { ThreatCenterGraphSize } from "./ThreatCenter";

type Datum = { x: string; y: number };

const accessors = {
  xAccessor: (d: Datum) => d.x,
  yAccessor: (d: Datum) => d.y,
};

type Props = {
  graphSize: ThreatCenterGraphSize;
};

const AccessOverTimeGraph = ({ graphSize }: Props) => {
  const { data, loading } = useRecommendationsMetricsGraphQuery();

  const perpetualData = data?.recommendationsMetricsGraph?.perpetualAccessGraph
    ?.map((d) => ({
      x: moment(d.date).format("YYYY-MM-DD"),
      y: d.value,
    }))
    ?.filter((d) => !!d) as Datum[];

  const perpetualGraphData = loading ? [] : perpetualData;

  const boundData = data?.recommendationsMetricsGraph?.timeboundAccessGraph
    ?.map((d) => ({
      x: moment(d.date).format("YYYY-MM-DD"),
      y: d.value,
    }))
    ?.filter((d) => !!d) as Datum[];

  const boundGraphData = loading ? [] : boundData;

  // create maps for tooltip
  const perpetualDataMap = new Map(perpetualGraphData.map((d) => [d.x, d.y]));
  const boundDataMap = new Map(boundGraphData.map((d) => [d.x, d.y]));

  return (
    <div className={styles.container}>
      <XYChart
        height={(GRAPH_HEIGHT_MULTIPLIER + 20) * graphSize}
        width={(GRAPH_WIDTH_MULTIPLIER + 20) * graphSize}
        xScale={{ type: "band" }}
        yScale={{ type: "linear" }}
      >
        <Axis orientation="left" numTicks={4} hideAxisLine />
        <Axis hideAxisLine orientation="bottom" numTicks={4} />
        <AreaStack curve={curveMonotoneX} offset="none" renderLine={true}>
          <AreaSeries
            dataKey="Bound Access"
            data={boundGraphData}
            fillOpacity={0.4}
            curve={curveMonotoneX}
            fill={RISK_COLOR_LOW}
            {...accessors}
          />
          <AreaSeries
            dataKey="Unbound Access"
            data={perpetualGraphData}
            fillOpacity={0.4}
            curve={curveMonotoneX}
            fill={RISK_COLOR_CRITICAL}
            {...accessors}
          />
        </AreaStack>
        <GlyphSeries
          dataKey="Bound Access Glyphs"
          data={boundGraphData}
          colorAccessor={() => RISK_COLOR_LOW}
          {...accessors}
        />
        <GlyphSeries
          dataKey="Unbound Access Glyphs"
          data={boundGraphData.map((d, i) => ({
            x: d.x,
            y: d.y + perpetualGraphData[i].y,
          }))}
          colorAccessor={() => RISK_COLOR_CRITICAL}
          {...accessors}
        />
        <Tooltip
          showVerticalCrosshair
          renderTooltip={({ tooltipData }) => {
            const datum = tooltipData?.nearestDatum?.datum as Datum;
            return (
              <div
                className={sprinkles({
                  fontFamily: "body",
                  fontWeight: "medium",
                })}
              >
                <div>Date: {moment(datum.x).format("MMM DD, YYYY")}</div>
                <div>Unbound Access: {perpetualDataMap.get(datum.x)}</div>
                <div>Bound Access: {boundDataMap.get(datum.x)}</div>
              </div>
            );
          }}
        />
      </XYChart>
    </div>
  );
};

export default AccessOverTimeGraph;
