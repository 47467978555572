import {
  AccessReviewConnectionFragment,
  Maybe,
  useAccessReviewConnectionQuery,
} from "api/generated/graphql";
import React from "react";
import { useParams } from "react-router";
import { logError } from "utils/logging";
import AccessReviewConnectionViewer from "views/access_reviews/connections/AccessReviewConnectionViewer";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

const AccessReviewConnectionViewerWrapper = () => {
  const { accessReviewConnectionId } = useParams<Record<string, string>>();

  const { data, error, loading } = useAccessReviewConnectionQuery({
    variables: {
      input: {
        id: accessReviewConnectionId!,
      },
    },
  });

  let accessReviewConnection: Maybe<AccessReviewConnectionFragment> = null;
  if (data) {
    switch (data.accessReviewConnection.__typename) {
      case "AccessReviewConnectionResult":
        accessReviewConnection =
          data.accessReviewConnection.accessReviewConnection;
        break;
      case "AccessReviewConnectionNotFoundError":
        return <NotFoundPage />;
      default:
        logError(new Error(`failed to get access review app`));
    }
  }

  if (loading) {
    return <ColumnContentSkeleton />;
  }

  if (
    !accessReviewConnection ||
    !accessReviewConnection.accessReview ||
    error
  ) {
    logError(error, `failed to get access review app`);
    return <UnexpectedErrorPage error={error} />;
  }

  return (
    <AccessReviewConnectionViewer
      accessReviewConnection={accessReviewConnection}
    />
  );
};

export default AccessReviewConnectionViewerWrapper;
