import {
  TagPreviewLargeFragment,
  TagPreviewSmallFragment,
  useConfigTemplateTagsQuery,
  useTagsQuery,
  useUpdateConfigTemplateTagsMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import ConfigurationTemplateDropdown from "components/configuration_templates/ConfigurationTemplateDropdown";
import { TagPill } from "components/pills/TagPills";
import { useToast } from "components/toast/Toast";
import {
  Banner,
  Button,
  Card,
  DataElement,
  Divider,
  DragBubbles,
  FormGroup,
  FormRow,
  Icon,
  Modal,
  Select,
} from "components/ui";
import { generateUniqueId } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useContext, useState } from "react";
import { DropResult } from "react-beautiful-dnd";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

const TAG_PAGE_SIZE = 200;

const TemplateMapping = () => {
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);
  const { authState } = useContext(AuthContext);
  const isAdmin = authState.user?.isAdmin;

  const [configTemplateTags, setConfigTemplateTags] = useState<
    {
      id: string;
      priority: number;
      tag?: TagPreviewSmallFragment;
      configTemplate?: {
        id: string;
        name: string;
      } | null;
    }[]
  >([]);
  const { loading, error } = useConfigTemplateTagsQuery({
    onCompleted: (data) => {
      const mappings = data.configTemplateTags.configTemplateTags.slice();
      const orderedMappings = mappings.sort((a, b) => a.priority - b.priority);
      setConfigTemplateTags(orderedMappings);
    },
  });

  // This query feeds the list of available tags in the dropdown
  const [searchQuery, setSearchQuery] = useState("");
  let tagSearchCursor: string | null | undefined;
  const {
    data: tagSearchData,
    error: tagSearchError,
    fetchMore: tagSearchFetchMore,
  } = useTagsQuery({
    variables: {
      input: {
        searchQuery,
        limit: TAG_PAGE_SIZE,
        cursor: tagSearchCursor,
      },
    },
  });
  const tagSearchList = tagSearchData?.tags.tags ?? [];
  tagSearchCursor = tagSearchData?.tags.cursor;

  const handleSearchInputChange = _.debounce((s: string) => {
    setSearchQuery(s);
  }, 250);

  const handleInputChange = (input: string) => {
    handleSearchInputChange(input);
  };

  const { clearToast, displaySuccessToast } = useToast();
  const [
    updateConfigTemplateTags,
    { error: mutationError },
  ] = useUpdateConfigTemplateTagsMutation();

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [mappingToDelete, setMappingToDelete] = useState<
    typeof configTemplateTags[0]
  >();

  if (loading) {
    return (
      <Column isContent>
        <ColumnContentSkeleton />
      </Column>
    );
  }
  if (error || tagSearchError) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error || tagSearchError} />
      </Column>
    );
  }
  const tagsNotInUse = tagSearchList.filter(
    (tag) =>
      !configTemplateTags.some(
        (configTemplateTag) => configTemplateTag.tag?.id === tag.id
      )
  );

  const reorderPriorities = (configs: typeof configTemplateTags) => {
    return configs.map((config, i) => ({
      ...config,
      priority: i + 1,
    }));
  };

  const handleDragEnd = (result: DropResult) => {
    const sourceIndex = result.source.index;
    const destinationIndex = result.destination?.index;
    if (destinationIndex === undefined || sourceIndex === destinationIndex)
      return;

    const newMappings = configTemplateTags.slice();
    const [removed] = newMappings.splice(sourceIndex, 1);
    newMappings.splice(destinationIndex, 0, removed);
    const orderednewMappings = reorderPriorities(newMappings);
    setConfigTemplateTags(orderednewMappings);
    runMutation(orderednewMappings);
  };

  const runMutation = async (
    newConfigTemplateTags: typeof configTemplateTags
  ) => {
    const filteredMappings = reorderPriorities(
      newConfigTemplateTags.filter(
        (configTemplateTag) =>
          // skip mappings that are not complete
          configTemplateTag.tag && configTemplateTag.configTemplate
      )
    );
    clearToast();
    await updateConfigTemplateTags({
      variables: {
        input: {
          configTemplateTags: filteredMappings.map((configTemplateTag) => ({
            configTemplateId: configTemplateTag.configTemplate!.id,
            tagId: configTemplateTag.tag!.id,
            priority: configTemplateTag.priority,
          })),
        },
      },
      refetchQueries: ["ConfigTemplateTags"],
    });
    displaySuccessToast("Saved");
  };

  return (
    <>
      <Column isContent maxWidth="lg">
        {hasV3Nav ? null : (
          <>
            <ColumnHeader title="Template Mapping" />
            <Divider />
          </>
        )}

        <ColumnContent>
          <FormRow title="Tag-based import">
            {mutationError ? (
              <Banner
                message="Could not save this mapping. Please refresh and try again."
                type="error"
              />
            ) : null}
            <p>
              Maps tags to configuration templates.
              <ul>
                <li>
                  When resources and groups are auto-imported, if they have the
                  specified tag, they will be assigned the specified
                  configuration template.
                </li>
                <li>
                  This mapping takes precedence over app-level or account-level
                  mappings.
                </li>
                <li>
                  This will not apply to items that are already imported or to
                  manual import actions.
                </li>
                <li>
                  If items have multiple matching tags, the highest priority
                  mapping will be used (change priorities with drag-and-drop).
                </li>
              </ul>
            </p>
            <DragBubbles
              handleDragEnd={handleDragEnd}
              isDragDisabled={!isAdmin}
              showAddButton={isAdmin}
              addButtonLabel="Add a new mapping"
              onAdd={() => {
                const highestPriority =
                  configTemplateTags[configTemplateTags.length - 1]?.priority ??
                  0;
                setConfigTemplateTags([
                  ...configTemplateTags,
                  {
                    id: generateUniqueId("new-mapping"),
                    priority: highestPriority + 1,
                  },
                ]);
              }}
              items={configTemplateTags.map((configTemplateTag) => ({
                id: configTemplateTag.id,
                priorityLabel: configTemplateTag.priority,
                isDragDisabled: false,
                titleContent: (
                  <>
                    {configTemplateTag.tag ? (
                      <TagPill
                        tagId={configTemplateTag.tag.id}
                        tag={configTemplateTag.tag}
                      />
                    ) : (
                      "Select a tag"
                    )}
                    <Icon name="arrow-right" />
                    {configTemplateTag.configTemplate ? (
                      <DataElement
                        color="blue"
                        leftIcon={{ name: "template" }}
                        label={configTemplateTag.configTemplate.name}
                        size="sm"
                      />
                    ) : (
                      "Select a configuration template"
                    )}
                  </>
                ),
                innerContent: (
                  <div className={sprinkles({ marginTop: "md" })}>
                    <Card>
                      <FormGroup label="Tag">
                        <Select<TagPreviewLargeFragment>
                          options={tagsNotInUse}
                          getOptionLabel={(tag) =>
                            tag.value
                              ? `${tag.key}:${tag.value}`
                              : tag.key || ""
                          }
                          value={configTemplateTag.tag}
                          onChange={(tag) => {
                            // onChange will get called when you delete text in the search bar - so we return early,
                            // rather than updating the mappings and effectively this mapping
                            if (!tag) {
                              return;
                            }
                            const newMappings = configTemplateTags.map(
                              (ctt) => {
                                if (ctt.id === configTemplateTag.id) {
                                  return {
                                    ...ctt,
                                    tag,
                                  };
                                }
                                return ctt;
                              }
                            );
                            setConfigTemplateTags(newMappings);
                            if (configTemplateTag.configTemplate?.id) {
                              runMutation(newMappings);
                            }
                            // Selecting an option will briefly populate the search input with the option's label.
                            // But we want to blank out the search input, so we call the debounced handler here
                            handleSearchInputChange("");
                          }}
                          onInputChange={handleInputChange}
                          disableBuiltInFiltering // Search is already handled server-side
                          loading={loading}
                          placeholder="Select a tag"
                          getIcon={() => ({ type: "name", icon: "tag" })}
                          onScrollToBottom={async () => {
                            // If user scrolls to bottom of dropdown, fetch the next batch of tags
                            if (tagSearchCursor) {
                              await tagSearchFetchMore({
                                variables: {
                                  input: {
                                    cursor: tagSearchCursor,
                                    searchQuery: searchQuery,
                                    limit: TAG_PAGE_SIZE,
                                  },
                                },
                              });
                            }
                          }}
                        />
                      </FormGroup>
                      <FormGroup label="Configuration template">
                        <ConfigurationTemplateDropdown
                          selectedConfigurationTemplateId={
                            configTemplateTag.configTemplate?.id
                          }
                          onSelectConfigurationTemplate={(configTemplate) => {
                            const newMappings = configTemplateTags.map(
                              (ctt) => {
                                if (ctt.id === configTemplateTag.id) {
                                  return {
                                    ...ctt,
                                    configTemplate,
                                  };
                                }
                                return ctt;
                              }
                            );
                            setConfigTemplateTags(newMappings);
                            if (configTemplateTag.tag?.id) {
                              runMutation(newMappings);
                            }
                          }}
                        />
                      </FormGroup>
                    </Card>
                    {isAdmin && (
                      <Button
                        label="Delete mapping"
                        leftIconName="trash"
                        type="error"
                        onClick={() => {
                          setMappingToDelete(configTemplateTag);
                          setShowConfirmDeleteModal(true);
                        }}
                      />
                    )}
                  </div>
                ),
              }))}
            />
          </FormRow>
        </ColumnContent>
      </Column>
      <Modal
        title="Remove tag mapping"
        isOpen={showConfirmDeleteModal}
        onClose={() => {
          setShowConfirmDeleteModal(false);
        }}
      >
        <Modal.Body>
          <p>Are you sure you want to delete this mapping?</p>
          {mappingToDelete?.tag ? (
            <TagPill tagId={mappingToDelete.tag.id} tag={mappingToDelete.tag} />
          ) : (
            <p>Tag: none</p>
          )}
          <div>
            <Icon name="arrow-right" />
          </div>
          {mappingToDelete?.configTemplate ? (
            <DataElement
              color="blue"
              leftIcon={{ name: "template" }}
              label={mappingToDelete.configTemplate.name}
              size="sm"
            />
          ) : (
            <p>Configuration template: none</p>
          )}
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel={"Delete"}
          onPrimaryButtonClick={async () => {
            if (!mappingToDelete) {
              return;
            }

            setShowConfirmDeleteModal(false);
            setMappingToDelete(undefined);
            const newMappings = reorderPriorities(
              configTemplateTags.filter((ctt) => ctt.id !== mappingToDelete.id)
            );
            setConfigTemplateTags(newMappings);
            runMutation(newMappings);
          }}
        />
      </Modal>
    </>
  );
};
export default TemplateMapping;
