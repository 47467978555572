import {
  AccessReviewResourceFragment,
  EventFilterFields,
} from "api/generated/graphql";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import React from "react";
import { EventsTable } from "views/events/EventsTable";

type AccessReviewResourceEventsRowProps = {
  accessReviewResource: AccessReviewResourceFragment;
};

export const AccessReviewResourceEventsRow = (
  props: AccessReviewResourceEventsRowProps
) => {
  const eventFilter: EventFilterFields = {
    objects: {
      objectId: props.accessReviewResource.id,
    },
  };

  const eventsTable = (
    <EventsTable
      eventFilter={eventFilter}
      emptyTitle={`No access review events for this resource`}
      emptySubtitle={`Conduct an access review on this resource to add events`}
    />
  );

  return (
    <EntityViewerRow title={"Events"} content={eventsTable} isTable={true} />
  );
};

export default AccessReviewResourceEventsRow;
