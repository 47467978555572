import { EntityType, FirstPartyTokenFragment } from "api/generated/graphql";
import Label, { ResourceLabel } from "components/label/Label";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import MaterialTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MaterialTable";
import moment from "moment";
import React from "react";
import { apiRoleByRole } from "utils/auth/auth";

interface ApiTokenRow {
  name: string;
  token: string;
  createdBy: string;
  created: string;
  lastUsed: string;
  expiry: string;
  role: string;
}

type ApiTokenTableProps = {
  tokens: FirstPartyTokenFragment[];
  buttonTitle?: string;
  buttonOnClickHandler?: () => void;
};

export const ApiTokensTable = (props: ApiTokenTableProps) => {
  const headers: Header<ApiTokenRow>[] = [
    { id: "name", label: "Name" },
    { id: "token", label: "Token" },
    { id: "createdBy", label: "Created By" },
    { id: "created", label: "Created" },
    { id: "lastUsed", label: "Last Used" },
    { id: "expiry", label: "Expiry" },
    { id: "role", label: "Role" },
  ];

  const rows: CellRow<ApiTokenRow>[] | undefined = props.tokens.map((token) => {
    const createdAtTime = moment(new Date(token.createdAt));
    const lastUsedAtTime = token.lastUsedAt
      ? moment(new Date(token.lastUsedAt))
      : null;

    const expiryTime = token.expiresAt
      ? moment(new Date(token.expiresAt))
      : null;

    return {
      id: token.id,
      data: {
        name: {
          value: token.tokenLabel,
          element: (
            <ResourceLabel
              text={token.tokenLabel}
              entityTypeNew={EntityType.AuthToken}
            />
          ),
        },
        token: {
          value: token.tokenPreview,
          element: <>{token.tokenPreview}</>,
        },
        createdBy: {
          value: token.creatorUserId,
          element: token.creatorUser ? (
            <ResourceLabel
              text={token.creatorUser.fullName}
              entityTypeNew={EntityType.User}
              avatar={token.creatorUser.avatarUrl}
              entityId={token.creatorUser.id}
            />
          ) : (
            <Label text={token.creatorUserId} maxChars={8} />
          ),
        },
        created: {
          value: createdAtTime.unix(),
          element: <>{createdAtTime.fromNow()}</>,
        },
        lastUsed: {
          value: lastUsedAtTime?.unix() || 0,
          element: <>{lastUsedAtTime ? lastUsedAtTime.fromNow() : `--`}</>,
        },
        expiry: {
          value: expiryTime?.unix() || 0,
          element: <>{expiryTime ? expiryTime.fromNow() : `--`}</>,
        },
        role: {
          value: token.accessLevel,
          element: <>{apiRoleByRole[token.accessLevel].name}</>,
        },
      },
    };
  });

  return (
    <EmptyStateContentWrapper
      content={
        <MaterialTable<ApiTokenRow>
          headers={headers}
          rows={rows || []}
          denseFormatting={true}
          tableContainerStyles={{
            maxHeight: "320px",
          }}
          defaultSortField={"created"}
          defaultSortOrder={"desc"}
        />
      }
      entityType={EntityType.AuthToken}
      title={"Tokens"}
      subtitle={`Generate tokens to populate the table`}
      isEmpty={!rows || rows.length === 0}
      onClickHandler={props.buttonOnClickHandler}
      buttonTitle={props.buttonTitle}
      small={true}
      extraSmall={true}
    />
  );
};

export default ApiTokensTable;
