import {
  IdpConnectionType,
  SyncTaskStatus,
  SyncType,
  useSetupStateQuery,
  useSyncStatusQuery,
  useSyncTaskQuery,
} from "api/generated/graphql";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import { Banner, Icon, Loader, ProgressBar } from "components/ui";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { useMountEffect } from "utils/hooks";
import { CONNECTIONS_LIST } from "views/idp/create/BrowseIdpServices";

import { getIdpConnectionSubtitle } from "../common";
import { SetupContext } from "../SetupContext";
import * as styles from "./IdpStep.css";

const ImportProgress = () => {
  const { hasSuccessfulImport, setHasSuccessfulImport } = useContext(
    SetupContext
  );
  const { data, refetch: refetchSetupState } = useSetupStateQuery();

  const { refetch } = useSyncTaskQuery({
    skip: true,
  });
  const { refetch: refetchOngoing } = useSyncStatusQuery({
    skip: true,
  });

  const importStatus = data?.setupState.state.importStatus;
  const [syncInProgress, setSyncInProgress] = useState(
    importStatus === SyncTaskStatus.Started
  );
  const [syncProgressPercent, setSyncProgressPercent] = useState(0);
  const [syncError, setSyncError] = useState("");

  const idpConnectionInfo = CONNECTIONS_LIST.find(
    (info) =>
      info.idpConnectionType === data?.setupState.state.idpConnectionType
  );

  useMountEffect(() => {
    const fetchSync = async (syncedEntityName: string) => {
      const ongoingResult = await refetchOngoing({
        input: {
          syncType: SyncType.PullConnectionSetupImport,
        },
      });
      const data = ongoingResult.data;

      if (data && data.syncStatus.__typename === "SyncStatusResult") {
        if (data.syncStatus.lastSuccessfulSyncTask) {
          setSyncInProgress(false);
          setSyncError("");
          setHasSuccessfulImport(true);
          return;
        }
        const taskID = data.syncStatus.ongoingSyncTask
          ? data.syncStatus.ongoingSyncTask.id
          : null;
        if (taskID != null) {
          setSyncInProgress(true);
          setTimeout(() => {
            checkSyncStatus(taskID, syncedEntityName, 500, 250);
          }, 250);
        }
      }
    };
    refetchSetupState();
    // On mount, check if there is an ongoing import sync.
    fetchSync(syncedEntityName);
  });

  if (!data?.setupState.state || !idpConnectionInfo) {
    return null;
  }

  const checkSyncStatus = async (
    syncTaskId: string,
    syncedEntityName: string,
    waitInterval: number,
    totalWaited: number
  ) => {
    const syncTaskErrorText = `Error: could not get sync status`;
    try {
      const result = await refetch({
        input: {
          id: syncTaskId,
        },
      });
      const { data: syncTaskData } = result;
      let status: SyncTaskStatus;

      if (syncTaskData) {
        switch (syncTaskData?.syncTask.__typename) {
          case "SyncTaskResult": {
            status = syncTaskData.syncTask.syncTask.status;
            let totalNumItems = 0;
            let totalCompleted = 0;
            syncTaskData.syncTask.syncTask.progress?.steps.forEach((step) => {
              totalNumItems += step.totalNumItems;
              totalCompleted += step.numItemsCompleted;
            });
            setSyncProgressPercent((totalCompleted / totalNumItems) * 100);
            if (status === SyncTaskStatus.Started) {
              // If in progress, keep polling to check for sync task completion
              setTimeout(() => {
                checkSyncStatus(
                  syncTaskId,
                  syncedEntityName,
                  Math.min(2000, waitInterval * 2),
                  totalWaited + waitInterval
                );
              }, waitInterval);
            } else {
              handleSyncFinished();
            }

            break;
          }
          case "SyncTaskNotFoundError":
          default:
            // Upon error, stop polling
            setSyncError(syncTaskErrorText);
            setSyncInProgress(false);
        }
      }
    } catch (error) {
      setSyncError(syncTaskErrorText);
      setSyncInProgress(false);
    }
  };

  const handleSyncFinished = () => {
    setSyncInProgress(false);
    setHasSuccessfulImport(true);
  };

  const syncedEntityName =
    data.setupState.state.connection?.name ?? "IdP Connection";
  const subtitle = getIdpConnectionSubtitle(
    data.setupState.state.connection?.metadata
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>Importing data</div>
      <p>Opal is now importing and syncing data from your identity provider.</p>
      <p>
        <b>This might take awhile, so feel free to exit this flow.</b> We'll
        notify you when the sync is finished and you can continue setting up
        this identity provider.
      </p>
      <div className={styles.connectionCard}>
        {syncInProgress && (
          <div className={styles.cardLoader}>
            <Loader size="lg" opalLogo />
          </div>
        )}
        <div className={styles.cardTop}>
          <div className={styles.cardLogoContainer}>
            <img alt={syncedEntityName} src={idpConnectionInfo.logo} />
          </div>
          <div>
            <div
              className={sprinkles({
                fontWeight: "semibold",
                fontSize: "headlineSm",
              })}
            >
              {syncedEntityName}
            </div>
            <div className={sprinkles({ color: "gray700" })}>{subtitle}</div>
          </div>
        </div>
        <div className={styles.cardBottom}>
          {idpConnectionInfo.idpConnectionType !== IdpConnectionType.Google && (
            <div className={styles.cardBottomSection}>
              <div className={styles.cardBottomNumber}>
                {data.setupState.state.importEntityCounts?.numGroups ?? 0}
                {hasSuccessfulImport && (
                  <Icon name="check-circle" size="xs" color="green600" />
                )}
              </div>
              <div>Groups</div>
            </div>
          )}
          {data.setupState.state.importEntityCounts?.resourceCounts.map(
            (resourceCountByType) => {
              const label =
                resourceTypeInfoByType[resourceCountByType.resourceType]
                  ?.name ?? resourceCountByType.resourceType;

              return (
                <div className={styles.cardBottomSection}>
                  <div className={styles.cardBottomNumber}>
                    {resourceCountByType.count}
                    {hasSuccessfulImport && (
                      <Icon name="check-circle" size="xs" color="green600" />
                    )}
                  </div>
                  <div>{pluralize(label, 2)}</div>
                </div>
              );
            }
          )}
        </div>
      </div>
      {syncError && <Banner type="error" message={syncError} marginTop="xl" />}
      <div className={sprinkles({ fontWeight: "semibold", marginY: "lg" })}>
        {hasSuccessfulImport ? "Import complete" : "Syncing imported data..."}
      </div>
      <ProgressBar
        percentProgress={syncProgressPercent}
        color={hasSuccessfulImport ? "green" : "blue"}
        size="lg"
      />
    </div>
  );
};

export default ImportProgress;
