import ImpersonationBanner from "./ImpersonationBanner";
import * as styles from "./Layout.css";

const FullscreenLayout: React.FC = (props) => {
  return (
    <div className={styles.fullPageContainer}>
      <ImpersonationBanner />
      {props.children}
    </div>
  );
};

export default FullscreenLayout;
