import {
  ConnectionSummaryFragment,
  EntityType,
  GroupDropdownPreviewFragment,
  GroupTypeWithCountFragment,
  ParentResourceInput,
  ResourceDropdownPreviewFragment,
  ResourceType,
  ResourceTypeWithCountFragment,
  SearchGroupPreviewFragment,
  SearchGroupTypePreviewFragment,
  SearchResourcePreviewFragment,
  SearchResourceTypePreviewFragment,
  SearchUserPreviewFragment,
  useConnectionsSummaryQuery,
  useGroupTypesWithCountsQuery,
  usePaginatedGroupDropdownQuery,
  usePaginatedResourceDropdownQuery,
  usePaginatedUserDropdownQuery,
  UserDropdownPreviewFragment,
  useResourceTypesWithCountsQuery,
  useSearchGroupsQuery,
  useSearchResourcesQuery,
  useSearchTypesQuery,
  useSearchUsersQuery,
} from "api/generated/graphql";
import EntityCountOption from "components/dropdown/EntityCountOption";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import {
  getGroupTypeInfo,
  getGroupTypeName,
} from "components/label/GroupTypeLabel";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { Select } from "components/ui";
import { defaultAvatarURL } from "components/ui/avatar/Avatar";
import { IconData } from "components/ui/utils";
import {
  FilterAction,
  useFilterDispatch,
  useFilterState,
} from "components/viz/contexts/FilterContext";
import React from "react";
import useLogEvent from "utils/analytics";
import { logError } from "utils/logging";

import { GraphContext } from "./contexts/GraphContext";

type EntitySelectorPage =
  | "home"
  | "users"
  | "groupTypes"
  | "groups"
  | "connectionsResources"
  | "resourceTypes"
  | "resources";

const PAGE_SIZE = 100;
const SEARCH_INITIAL_SECTION_SIZE = 3;

const topLevelBrowseOptions: {
  label: string;
  value: EntitySelectorPage;
  icon: IconData;
}[] = [
  { label: "Users", value: "users", icon: { type: "name", icon: "user" } },
  {
    label: "Groups",
    value: "groupTypes",
    icon: { type: "name", icon: "users" },
  },
  {
    label: "Resources",
    value: "connectionsResources",
    icon: { type: "name", icon: "cube" },
  },
];

type SearchGroup =
  | "resources"
  | "users"
  | "groups"
  | "group types"
  | "resource types";
type SearchOption = {
  name: string;
  group: SearchGroup;
  icon: IconData;
  parentResourceName?: string;
  relatedEntityCount?: number;
  relatedEntityLabel?: string;
  addAction: FilterAction;
  removeAction: FilterAction;
};

interface NavigationStackItem {
  page: EntitySelectorPage;
  pageTitle: string;

  connectionFilter?: string[];
  resourceTypeFilter?: ResourceType[];
  parentResourceFilter?: ParentResourceInput;
}

const EntitySelector = () => {
  const filterState = useFilterState();
  const dispatch = useFilterDispatch();
  const { graphDispatch } = React.useContext(GraphContext);
  const logEvent = useLogEvent();

  const [navigationStack, setNavigationStack] = React.useState<
    NavigationStackItem[]
  >([{ page: "home", pageTitle: "Home" }]);

  const setLoading = React.useCallback(
    (loading: boolean) => {
      graphDispatch({
        type: "SET_LOADING",
        payload: {
          loading,
        },
      });
    },
    [graphDispatch]
  );

  const popNavigationStack = () => {
    if (navigationStack.length === 1) {
      return;
    }
    const newStack = navigationStack.slice();
    newStack.pop();
    setNavigationStack(newStack);
  };

  const pushNavigationStack = (newPage: NavigationStackItem) => {
    setNavigationStack([...navigationStack, newPage]);
  };

  const currentNavigationItem = navigationStack[navigationStack.length - 1];
  const page = currentNavigationItem.page;
  const pageTitle = currentNavigationItem.pageTitle;

  const [searchQuery, setSearchQuery] = React.useState("");

  const {
    data: connectionsSummaryData,
    error: connectionsSummaryError,
    loading: connectionsSummaryLoading,
  } = useConnectionsSummaryQuery({
    variables: { input: {} },
  });
  if (connectionsSummaryError) {
    logError(connectionsSummaryError, "failed to list connections");
  }

  let connections: ConnectionSummaryFragment[] = [];
  switch (connectionsSummaryData?.connections.__typename) {
    case "ConnectionsResult":
      connections = connectionsSummaryData.connections.connections;
      break;
    default:
      break;
  }

  const {
    data: usersData,
    error: usersError,
    fetchMore: usersFetchMore,
    loading: usersLoading,
  } = usePaginatedUserDropdownQuery({
    variables: {
      input: {
        maxNumEntries: PAGE_SIZE,
      },
    },
    skip: page !== "users",
  });
  if (usersError) {
    logError(usersError, "failed to list users");
  }

  let usersCursor: string | null | undefined;
  let users: UserDropdownPreviewFragment[] = [];
  switch (usersData?.users.__typename) {
    case "UsersResult":
      // Never show Opal System user
      users = usersData.users.users.filter((user) => !user.isSystemUser);
      usersCursor = usersData.users.cursor;
      break;
    default:
      break;
  }

  const {
    data: groupsData,
    error: groupsError,
    fetchMore: groupsFetchMore,
    loading: groupsLoading,
  } = usePaginatedGroupDropdownQuery({
    variables: {
      input: {
        connectionIds: currentNavigationItem.connectionFilter,
        maxNumEntries: PAGE_SIZE,
      },
    },
    skip: page !== "groups",
  });
  if (groupsError) {
    logError(groupsError, "failed to list groups");
  }

  let groupsCursor: string | null | undefined;
  let groups: GroupDropdownPreviewFragment[] = [];
  switch (groupsData?.groups.__typename) {
    case "GroupsResult":
      groups = groupsData.groups.groups;
      groupsCursor = groupsData.groups.cursor;
      break;
    default:
      break;
  }

  const {
    data: groupTypesData,
    error: groupTypesError,
    loading: groupTypesLoading,
  } = useGroupTypesWithCountsQuery({
    variables: {
      input: {},
    },
    skip: page !== "groupTypes",
  });

  if (groupTypesError) {
    logError(groupTypesError, "failed to list group types");
  }

  let groupTypes: GroupTypeWithCountFragment[] = [];
  switch (groupTypesData?.groupTypesWithCounts.__typename) {
    case "GroupTypesWithCountsResult":
      groupTypes = groupTypesData.groupTypesWithCounts.groupTypesWithCounts
        .slice()
        .sort((a, b) => {
          return getGroupTypeName(a).toLowerCase() >
            getGroupTypeName(b).toLowerCase()
            ? 1
            : -1;
        });
      break;
    default:
      break;
  }

  const {
    data: resourceTypesData,
    error: resourceTypesError,
    loading: resourceTypesLoading,
  } = useResourceTypesWithCountsQuery({
    variables: {
      input: {
        connectionIds: currentNavigationItem.connectionFilter,
        parentResourceId: currentNavigationItem.parentResourceFilter,
      },
    },
    skip: page !== "resourceTypes",
  });

  if (resourceTypesError) {
    logError(resourceTypesError, "failed to list resource types");
  }

  let resourceTypes: ResourceTypeWithCountFragment[] = [];
  switch (resourceTypesData?.resourceTypesWithCounts.__typename) {
    case "ResourceTypesWithCountsResult":
      resourceTypes =
        resourceTypesData.resourceTypesWithCounts.resourceTypesWithCounts;
      break;
    default:
      break;
  }

  const {
    data: resourcesData,
    error: resourcesError,
    fetchMore: resourcesFetchMore,
    loading: resourcesLoading,
  } = usePaginatedResourceDropdownQuery({
    variables: {
      input: {
        connectionIds: currentNavigationItem.connectionFilter,
        resourceTypes: currentNavigationItem.resourceTypeFilter,
        maxNumEntries: PAGE_SIZE,
        parentResourceId: currentNavigationItem.parentResourceFilter,
      },
    },
    skip: page !== "resources",
  });
  if (resourcesError) {
    logError(resourcesError, "failed to list resources");
  }

  let resourcesCursor: string | null | undefined;
  let resources: ResourceDropdownPreviewFragment[] = [];
  switch (resourcesData?.resources.__typename) {
    case "ResourcesResult":
      resources = resourcesData.resources.resources;
      resourcesCursor = resourcesData.resources.cursor;
      break;
    default:
      break;
  }

  const {
    data: vizSearchResourcesData,
    error: vizSearchResourcesError,
    fetchMore: vizSearchResourcesFetchMore,
  } = useSearchResourcesQuery({
    variables: {
      query: searchQuery,
      maxNumEntries: SEARCH_INITIAL_SECTION_SIZE,
    },

    skip: searchQuery === "",
  });
  if (vizSearchResourcesError) {
    logError(vizSearchResourcesError, "failed to execute viz resources search");
  }

  let vizSearchResourcesCursor: string | null | undefined;
  let vizSearchResources: SearchResourcePreviewFragment[] = [];
  switch (vizSearchResourcesData?.resources.__typename) {
    case "ResourcesResult":
      vizSearchResources = vizSearchResourcesData.resources.resources;
      vizSearchResourcesCursor = vizSearchResourcesData.resources.cursor;
      break;
    default:
      break;
  }

  const {
    data: vizSearchUsersData,
    error: vizSearchUsersError,
    fetchMore: vizSearchUsersFetchMore,
  } = useSearchUsersQuery({
    variables: {
      query: searchQuery,
      maxNumEntries: SEARCH_INITIAL_SECTION_SIZE,
    },

    skip: searchQuery === "",
  });
  if (vizSearchUsersError) {
    logError(vizSearchUsersError, "failed to execute viz users search");
  }

  let vizSearchUsersCursor: string | null | undefined;
  let vizSearchUsers: SearchUserPreviewFragment[] = [];
  switch (vizSearchUsersData?.users.__typename) {
    case "UsersResult":
      vizSearchUsers = vizSearchUsersData.users.users;
      vizSearchUsersCursor = vizSearchUsersData.users.cursor;
      break;
    default:
      break;
  }

  const {
    data: vizSearchGroupsData,
    error: vizSearchGroupsError,
    fetchMore: vizSearchGroupsFetchMore,
  } = useSearchGroupsQuery({
    variables: {
      query: searchQuery,
      maxNumEntries: SEARCH_INITIAL_SECTION_SIZE,
    },

    skip: searchQuery === "",
  });
  if (vizSearchGroupsError) {
    logError(vizSearchGroupsError, "failed to execute viz groups search");
  }

  let vizSearchGroupsCursor: string | null | undefined;
  let vizSearchGroups: SearchGroupPreviewFragment[] = [];
  switch (vizSearchGroupsData?.groups.__typename) {
    case "GroupsResult":
      vizSearchGroups = vizSearchGroupsData.groups.groups;
      vizSearchGroupsCursor = vizSearchGroupsData.groups.cursor;
      break;
    default:
      break;
  }

  const {
    data: vizSearchTypesData,
    error: vizSearchTypesError,
  } = useSearchTypesQuery({
    variables: {
      query: searchQuery,
    },

    skip: searchQuery === "",
  });
  if (vizSearchTypesError) {
    logError(vizSearchTypesError, "failed to execute viz types search");
  }

  let vizSearchGroupTypes: SearchGroupTypePreviewFragment[] = [];
  let vizSearchResourceTypes: SearchResourceTypePreviewFragment[] = [];
  switch (vizSearchTypesData?.groupTypesWithCounts.__typename) {
    case "GroupTypesWithCountsResult":
      vizSearchGroupTypes =
        vizSearchTypesData.groupTypesWithCounts.groupTypesWithCounts;
      break;
    default:
      break;
  }
  switch (vizSearchTypesData?.resourceTypesWithCounts.__typename) {
    case "ResourceTypesWithCountsResult":
      vizSearchResourceTypes =
        vizSearchTypesData.resourceTypesWithCounts.resourceTypesWithCounts;
      break;
    default:
      break;
  }

  // Note: while it may be tempting to decompose this component,
  // the <Select>s must all be rendered by the same parent (afaik) in order for there
  // to be seamless page transitions; otherwise React unmounts the DOM elements

  const sharedProps = {
    style: "search" as "search",
    placeholder: "Search",
    onInputChange: setSearchQuery,
    disableBuiltInFiltering: true,
  };

  if (searchQuery !== "") {
    let searchOptions: SearchOption[] = [];

    const resourceOptions: SearchOption[] = vizSearchResources.map(
      (vizSearchResource) => ({
        id: vizSearchResource.id,
        name: vizSearchResource.name,
        group: "resources",
        icon: {
          type: "entity",
          entityType: vizSearchResource.resourceType,
        },
        relatedEntityCount: vizSearchResource.numChildResources
          ? vizSearchResource.numChildResources
          : undefined,
        parentResourceName: vizSearchResource.parentResource?.name,
        relatedEntityLabel: "child resources",
        addAction: {
          type: "ADD_RESOURCE",
          payload: {
            id: vizSearchResource.id,
            name: vizSearchResource.name,
          },
        },
        removeAction: {
          type: "REMOVE_RESOURCE",
          payload: {
            id: vizSearchResource.id,
          },
        },
      })
    );

    const userOptions: SearchOption[] = vizSearchUsers.map((vizSearchUser) => ({
      name: vizSearchUser.fullName,
      group: "users",
      icon: {
        type: "src",
        style: "rounded",
        icon: vizSearchUser.avatarUrl || defaultAvatarURL,
        fallbackIcon: defaultAvatarURL,
      },
      addAction: {
        type: "ADD_USER",
        payload: {
          id: vizSearchUser.id,
          name: vizSearchUser.fullName,
        },
      },
      removeAction: {
        type: "REMOVE_USER",
        payload: {
          id: vizSearchUser.id,
        },
      },
    }));

    const groupOptions: SearchOption[] = vizSearchGroups.map(
      (vizSearchGroup) => ({
        name: vizSearchGroup.name,
        group: "groups",
        icon: {
          type: "entity",
          entityType: vizSearchGroup.groupType,
        },
        addAction: {
          type: "ADD_GROUP",
          payload: {
            id: vizSearchGroup.id,
            name: vizSearchGroup.name,
          },
        },
        removeAction: {
          type: "REMOVE_GROUP",
          payload: {
            id: vizSearchGroup.id,
          },
        },
      })
    );

    const groupTypeOptions: SearchOption[] = vizSearchGroupTypes.map(
      (vizSearchGroupType) => {
        let groupTypeName =
          getGroupTypeInfo(vizSearchGroupType.groupType)?.name ?? "";
        if (vizSearchGroupType.connection?.name) {
          groupTypeName =
            vizSearchGroupType.connection?.name + " / " + groupTypeName;
        }

        return {
          name: groupTypeName,
          group: "group types",
          icon: {
            type: "entity",
            entityType: vizSearchGroupType.groupType,
          },
          relatedEntityCount: vizSearchGroupType.numGroups,
          relatedEntityLabel: "groups",
          addAction: {
            type: "ADD_MULTI_GROUP",
            payload: {
              connectionId: vizSearchGroupType.connection?.id,
              groupType: vizSearchGroupType.groupType,
              name: groupTypeName,
            },
          },
          removeAction: {
            type: "REMOVE_MULTI_GROUP",
            payload: {
              connectionId: vizSearchGroupType.connection?.id,
              groupType: vizSearchGroupType.groupType,
            },
          },
        };
      }
    );

    const resourceTypeOptions: SearchOption[] = vizSearchResourceTypes.map(
      (vizSearchResourceType) => {
        let resourceTypeName =
          getResourceTypeInfo(vizSearchResourceType.resourceType)?.name ?? "";
        if (vizSearchResourceType.connection?.name) {
          resourceTypeName =
            vizSearchResourceType.connection?.name + " / " + resourceTypeName;
        }
        return {
          name: resourceTypeName,
          group: "resource types",
          icon: {
            type: "entity",
            entityType: vizSearchResourceType.resourceType,
          },
          relatedEntityCount: vizSearchResourceType.numResources,
          relatedEntityLabel: "resources",
          addAction: {
            type: "ADD_MULTI_RESOURCE",
            payload: {
              connectionId: vizSearchResourceType.connection?.id,
              resourceType: vizSearchResourceType.resourceType,
              name: resourceTypeName,
            },
          },
          removeAction: {
            type: "REMOVE_MULTI_RESOURCE",
            payload: {
              connectionId: vizSearchResourceType.connection?.id,
              resourceType: vizSearchResourceType.resourceType,
            },
          },
        };
      }
    );

    // If we're on some page in the browse hierarchy, sort options to be related to the page if possible
    // Silly?
    if (page === "users") {
      searchOptions = [
        ...userOptions,
        ...groupOptions,
        ...resourceOptions,
        ...groupTypeOptions,
        ...resourceTypeOptions,
      ];
    } else if (
      page === "resourceTypes" ||
      page === "resources" ||
      page == "connectionsResources"
    ) {
      searchOptions = [
        ...resourceOptions,
        ...resourceTypeOptions,
        ...userOptions,
        ...groupOptions,
        ...groupTypeOptions,
      ];
    } else if (page === "groups" || page === "groupTypes") {
      searchOptions = [
        ...groupOptions,
        ...groupTypeOptions,
        ...userOptions,
        ...resourceOptions,
        ...resourceTypeOptions,
      ];
    } else {
      searchOptions = [
        ...resourceOptions,
        ...userOptions,
        ...groupOptions,
        ...groupTypeOptions,
        ...resourceTypeOptions,
      ];
    }

    return (
      <Select<typeof searchOptions[0]>
        {...sharedProps}
        value={searchOptions.filter((searchOption) => {
          const optionId =
            "id" in searchOption.removeAction.payload
              ? searchOption.removeAction.payload.id
              : undefined;
          const optionConnectionId =
            "connectionId" in searchOption.removeAction.payload
              ? searchOption.removeAction.payload.connectionId
              : undefined;
          const optionGroupType =
            "groupType" in searchOption.removeAction.payload
              ? searchOption.removeAction.payload.groupType
              : undefined;
          const optionResourceType =
            "resourceType" in searchOption.removeAction.payload
              ? searchOption.removeAction.payload.resourceType
              : undefined;
          if (searchOption.group === "users") {
            return filterState.selection.userIds?.includes(optionId ?? "");
          }
          if (searchOption.group === "groups") {
            return filterState.selection.groupIds?.includes(optionId ?? "");
          }
          if (searchOption.group === "resources") {
            return filterState.selection.resourceIds?.includes(optionId ?? "");
          }
          if (searchOption.group === "group types") {
            return filterState.selection.multiGroupSelections?.find(
              (groupTypeSelection) =>
                groupTypeSelection.connectionId === optionConnectionId &&
                groupTypeSelection.groupType === optionGroupType
            );
          }
          if (searchOption.group === "resource types") {
            return filterState.selection.multiResourceSelections?.find(
              (resourceTypeSelection) =>
                resourceTypeSelection.connectionId === optionConnectionId &&
                resourceTypeSelection.resourceType === optionResourceType
            );
          }
        })}
        multiple
        options={searchOptions}
        getOptionLabel={(option) => option.name}
        getIcon={(option) => option.icon}
        groupBy={(option) => option.group}
        groupHasLoadMore={(groupName) => {
          if (groupName === "resources") {
            return Boolean(vizSearchResourcesCursor);
          } else if (groupName === "users") {
            return Boolean(vizSearchUsersCursor);
          } else if (groupName === "groups") {
            return Boolean(vizSearchGroupsCursor);
          }
          return false;
        }}
        renderOptionLabel={(option) => (
          <EntityCountOption
            name={option.name}
            count={option.relatedEntityCount}
            parentResourceName={option.parentResourceName}
            entityType={option.relatedEntityLabel}
          />
        )}
        onGroupLoadMore={(groupName) => {
          if (groupName === "resources") {
            vizSearchResourcesFetchMore({
              variables: {
                resourcesCursor: vizSearchResourcesCursor,
                maxNumEntries: PAGE_SIZE,
              },
            });
          } else if (groupName === "users") {
            vizSearchUsersFetchMore({
              variables: {
                usersCursor: vizSearchUsersCursor,
                maxNumEntries: PAGE_SIZE,
              },
            });
          } else if (groupName === "groups") {
            vizSearchGroupsFetchMore({
              variables: {
                usersCursor: vizSearchGroupsCursor,
                maxNumEntries: PAGE_SIZE,
              },
            });
          }
        }}
        onSelectValue={(option, reason) => {
          let entityType = "";
          switch (option?.addAction.type) {
            case "ADD_GROUP":
            case "REMOVE_GROUP":
              entityType = EntityType.Group;
              break;
            case "ADD_RESOURCE":
            case "REMOVE_RESOURCE":
              entityType = EntityType.Resource;
              break;
            case "ADD_USER":
            case "REMOVE_USER":
              entityType = EntityType.User;
              break;
            case "ADD_MULTI_RESOURCE":
            case "REMOVE_MULTI_RESOURCE":
              entityType = "RESOURCE_TYPE";
              break;
            case "ADD_MULTI_GROUP":
            case "REMOVE_MULTI_GROUP":
              entityType = "GROUP_TYPE";
              break;
            default:
              break;
          }
          if (reason === "select-option") {
            if (option && option.addAction) {
              dispatch(option.addAction);
              logEvent({
                name: "entity_option_select",
                properties: {
                  entityName: option.name,
                  entityID:
                    "id" in option.addAction.payload
                      ? option.addAction.payload.id
                      : "",
                  entityType,
                  checked: true,
                  searchQuery,
                },
              });
              setLoading(true);
            }
          } else if (reason === "remove-option") {
            if (option && option.removeAction) {
              dispatch(option.removeAction);
              logEvent({
                name: "entity_option_select",
                properties: {
                  entityName: option.name,
                  entityID:
                    "id" in option.addAction.payload
                      ? option.addAction.payload.id
                      : "",
                  entityType,
                  checked: false,
                  searchQuery,
                },
              });
            }
          }
        }}
      />
    );
  }

  const anyLoading =
    usersLoading ||
    groupsLoading ||
    connectionsSummaryLoading ||
    resourceTypesLoading ||
    resourcesLoading ||
    groupTypesLoading;

  if (page === "users") {
    return (
      <Select<UserDropdownPreviewFragment>
        {...sharedProps}
        multiple
        value={filterState.selection.userIds?.map(
          (filterUserId) => users.find((user) => user.id === filterUserId)!
        )}
        listboxHeader={{
          title: "Users",
          onGoBack: popNavigationStack,
        }}
        options={users}
        onSelectValue={(option, reason) => {
          if (!option) {
            return;
          }
          const eventProperties = {
            entityID: option.id,
            entityName: option.fullName,
            entityType: EntityType.User,
            searchQuery,
          };
          if (reason === "select-option") {
            dispatch({
              type: "ADD_USER",
              payload: {
                id: option.id,
              },
            });
            logEvent({
              name: "entity_option_select",
              properties: {
                ...eventProperties,
                checked: true,
              },
            });
            setLoading(true);
          } else if (reason === "remove-option") {
            dispatch({
              type: "REMOVE_USER",
              payload: {
                id: option.id,
              },
            });
            logEvent({
              name: "entity_option_select",
              properties: {
                ...eventProperties,
                checked: false,
              },
            });
          }
        }}
        getOptionLabel={(option) => option?.fullName}
        getIcon={(option) => ({
          type: "src",
          style: "rounded",
          icon: option.avatarUrl || defaultAvatarURL,
          fallbackIcon: defaultAvatarURL,
        })}
        onScrollToBottom={async () => {
          if (usersCursor) {
            await usersFetchMore({
              variables: {
                input: {
                  cursor: usersCursor,
                  maxNumEntries: PAGE_SIZE,
                },
              },
            });
          }
        }}
        loading={anyLoading}
      />
    );
  }

  if (page === "groups") {
    return (
      <Select<GroupDropdownPreviewFragment>
        {...sharedProps}
        multiple
        value={filterState.selection.groupIds
          ?.map(
            (filterGroupId) =>
              groups.find((group) => group.id === filterGroupId)!
          )
          .filter((group) => group)}
        listboxHeader={{
          title: pageTitle,
          onGoBack: popNavigationStack,
        }}
        options={groups}
        onSelectValue={(option, reason) => {
          if (!option) {
            return;
          }
          const eventProperties = {
            entityID: option.id,
            entityName: option.name,
            entityType: EntityType.Group,
            searchQuery,
          };
          if (reason === "select-option") {
            dispatch({
              type: "ADD_GROUP",
              payload: {
                id: option.id,
              },
            });
            logEvent({
              name: "entity_option_select",
              properties: {
                ...eventProperties,
                checked: true,
              },
            });
            setLoading(true);
          } else if (reason === "remove-option") {
            dispatch({
              type: "REMOVE_GROUP",
              payload: {
                id: option.id,
              },
            });
            logEvent({
              name: "entity_option_select",
              properties: {
                ...eventProperties,
                checked: false,
              },
            });
          }
        }}
        getOptionLabel={(option) => option.name}
        renderOptionLabel={(option) => (
          <EntityCountOption
            name={option.name}
            count={option.numGroupUsers}
            entityType="users"
          />
        )}
        getIcon={(option) => ({
          type: "entity",
          entityType: option.groupType,
        })}
        onScrollToBottom={async () => {
          if (groupsCursor) {
            await groupsFetchMore({
              variables: {
                input: {
                  cursor: groupsCursor,
                  maxNumEntries: PAGE_SIZE,
                },
              },
            });
          }
        }}
        loading={anyLoading}
      />
    );
  }

  if (page === "groupTypes") {
    const multiGroupSelections =
      filterState.selection.multiGroupSelections ?? [];
    return (
      <Select<GroupTypeWithCountFragment>
        {...sharedProps}
        multiple
        value={multiGroupSelections
          .map(
            (selection) =>
              groupTypes.find(
                (gt) =>
                  gt.connectionId === selection.connectionId &&
                  gt.groupType === selection.groupType
              )!
          )
          .filter((c) => c)}
        options={groupTypes}
        getOptionLabel={(option) => option.groupType}
        renderOptionLabel={(option) => (
          <EntityCountOption
            name={getGroupTypeName(option)}
            count={option.numGroups}
            entityType="group"
          />
        )}
        getIcon={(option) => ({
          type: "entity",
          entityType: option.groupType,
        })}
        onSelectValue={(option, reason) => {
          if (!option || !option.groupType || !option.connectionId) {
            return;
          }
          let groupTypeName = getGroupTypeInfo(option.groupType)?.name ?? "";
          if (option.connection?.name) {
            groupTypeName = option.connection?.name + " / " + groupTypeName;
          }
          const eventProperties = {
            entityID: "",
            entityName: groupTypeName,
            entityType: "GROUP_TYPE",
            searchQuery,
          };
          if (reason === "select-option") {
            dispatch({
              type: "ADD_MULTI_GROUP",
              payload: {
                groupType: option.groupType,
                connectionId: option.connectionId,
                name: groupTypeName,
              },
            });
            logEvent({
              name: "entity_option_select",
              properties: {
                ...eventProperties,
                checked: true,
              },
            });
            setLoading(true);
          } else if (reason === "remove-option") {
            dispatch({
              type: "REMOVE_MULTI_GROUP",
              payload: {
                groupType: option.groupType,
                connectionId: option.connectionId,
              },
            });
            logEvent({
              name: "entity_option_select",
              properties: {
                ...eventProperties,
                checked: false,
              },
            });
          }
        }}
        listboxHeader={{
          title: "Groups",
          onGoBack: popNavigationStack,
        }}
        optionHasDrilldown={() => true}
        onDrilldown={(option) => {
          if (!option.connectionId) {
            return;
          }
          pushNavigationStack({
            page: "groups",
            pageTitle: option.connection?.name ?? "",
            connectionFilter: [option.connectionId],
          });
        }}
        loading={anyLoading}
      />
    );
  }

  if (page === "connectionsResources") {
    return (
      <Select
        {...sharedProps}
        options={connections.filter((connection) => connection.numResources)}
        getOptionLabel={(option) => option.name}
        renderOptionLabel={(option) => (
          <EntityCountOption
            name={option.name}
            count={option.numResources}
            entityType="resource"
          />
        )}
        getIcon={(option) => ({
          type: "src",
          icon: getConnectionTypeInfo(option.connectionType)?.icon,
        })}
        onChange={() => {}}
        listboxHeader={{
          title: "Resources",
          onGoBack: popNavigationStack,
        }}
        optionHasDrilldown={() => true}
        onDrilldown={(option) => {
          pushNavigationStack({
            page: "resourceTypes",
            pageTitle: option.name,
            connectionFilter: [option.id],
            parentResourceFilter: { parentResourceId: null },
          });
        }}
        loading={anyLoading}
      />
    );
  }

  if (page === "resourceTypes") {
    const multiResourceSelections =
      filterState.selection.multiResourceSelections ?? [];
    return (
      <Select<ResourceTypeWithCountFragment>
        {...sharedProps}
        multiple
        options={resourceTypes}
        getOptionLabel={(option) =>
          getResourceTypeInfo(option.resourceType)?.name ?? ""
        }
        value={multiResourceSelections
          .map(
            (selection) =>
              resourceTypes.find(
                (r) =>
                  r.resourceType === selection.resourceType &&
                  r.connectionId === selection.connectionId
              )!
          )
          .filter((r) => r)}
        renderOptionLabel={(option) => (
          <EntityCountOption
            name={getResourceTypeInfo(option.resourceType)?.name ?? ""}
            count={option.numResources}
            entityType="resource"
          />
        )}
        getIcon={(option) => ({
          type: "entity",
          entityType: option.resourceType,
        })}
        onSelectValue={(option, reason) => {
          if (!option || !option.resourceType || !option.connectionId) {
            return;
          }
          let resourceTypeName =
            getResourceTypeInfo(option.resourceType)?.name ?? "";
          if (option.connection?.name) {
            resourceTypeName =
              option.connection?.name + " / " + resourceTypeName;
          }
          const eventProperties = {
            entityID: "",
            entityType: "RESOURCE_TYPE",
            entityName: resourceTypeName,
            searchQuery,
          };
          if (reason === "select-option") {
            dispatch({
              type: "ADD_MULTI_RESOURCE",
              payload: {
                resourceType: option.resourceType,
                parentResourceId:
                  currentNavigationItem.parentResourceFilter
                    ?.parentResourceId ?? undefined,
                connectionId: option.connectionId,
                name: resourceTypeName,
              },
            });
            logEvent({
              name: "entity_option_select",
              properties: {
                ...eventProperties,
                checked: true,
              },
            });
            setLoading(true);
          } else if (reason === "remove-option") {
            dispatch({
              type: "REMOVE_MULTI_RESOURCE",
              payload: {
                resourceType: option.resourceType,
                connectionId: option.connectionId,
              },
            });
            logEvent({
              name: "entity_option_select",
              properties: {
                ...eventProperties,
                checked: false,
              },
            });
          }
        }}
        listboxHeader={{
          title: pageTitle,
          onGoBack: popNavigationStack,
        }}
        optionHasDrilldown={() => true}
        onDrilldown={(option) => {
          pushNavigationStack({
            page: "resources",
            pageTitle: getResourceTypeInfo(option.resourceType)?.name ?? "",
            connectionFilter: currentNavigationItem.connectionFilter,
            parentResourceFilter: currentNavigationItem.parentResourceFilter,
            resourceTypeFilter: [option.resourceType],
          });
        }}
        loading={anyLoading}
      />
    );
  }

  if (page === "resources") {
    return (
      <Select<ResourceDropdownPreviewFragment>
        {...sharedProps}
        multiple
        value={filterState.selection.resourceIds
          ?.map(
            (filterResourceId) =>
              resources.find((resource) => resource.id === filterResourceId)!
          )
          .filter((resource) => resource)}
        listboxHeader={{
          title: pageTitle,
          onGoBack: popNavigationStack,
        }}
        options={resources}
        onSelectValue={(option, reason) => {
          if (!option) {
            return;
          }
          const eventProperties = {
            entityID: option.id,
            entityName: option.name,
            entityType: EntityType.Resource,
            searchQuery,
          };
          if (reason === "select-option") {
            dispatch({
              type: "ADD_RESOURCE",
              payload: {
                id: option.id,
              },
            });
            logEvent({
              name: "entity_option_select",
              properties: {
                ...eventProperties,
                checked: true,
              },
            });
            setLoading(true);
          } else if (reason === "remove-option") {
            dispatch({
              type: "REMOVE_RESOURCE",
              payload: {
                id: option.id,
              },
            });
            logEvent({
              name: "entity_option_select",
              properties: {
                ...eventProperties,
                checked: false,
              },
            });
          }
        }}
        getOptionLabel={(option) => option.name}
        renderOptionLabel={(option) => (
          <EntityCountOption
            name={option.name}
            count={
              option.numChildResources ? option.numChildResources : undefined
            }
            entityType="child resource"
          />
        )}
        optionHasDrilldown={(option) => option.numChildResources > 0}
        onDrilldown={(option) => {
          pushNavigationStack({
            page: "resourceTypes",
            pageTitle: option.name,
            connectionFilter: currentNavigationItem.connectionFilter,
            resourceTypeFilter: currentNavigationItem.resourceTypeFilter,
            parentResourceFilter: { parentResourceId: option.id },
          });
        }}
        getIcon={(option) => ({
          type: "entity",
          entityType: option.resourceType,
        })}
        onScrollToBottom={async () => {
          if (resourcesCursor) {
            await resourcesFetchMore({
              variables: {
                input: {
                  cursor: resourcesCursor,
                  maxNumEntries: PAGE_SIZE,
                },
              },
            });
          }
        }}
        loading={anyLoading}
      />
    );
  }

  return (
    <Select
      {...sharedProps}
      options={topLevelBrowseOptions}
      getOptionLabel={(option) => option.label}
      getIcon={(option) => option.icon}
      onChange={() => {}}
      optionHasDrilldown={() => true}
      onDrilldown={(option) =>
        pushNavigationStack({
          pageTitle: option.label,
          page: option.value,
        })
      }
      oneTimeHelperText={{
        localStorageKey: "visualization-entity-selector-welcome",
        text: <>Search what you're looking for above, or browse below.</>,
      }}
    />
  );
};

export default EntitySelector;
