import { EntityIdTupleNullable } from "api/common/common";
import { Maybe } from "api/generated/graphql";
import {
  DirectoryListingEntries,
  DirectoryListingEntryInfo,
} from "components/directory_listing/DirectoryListing";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import selectItemsModalStyles from "components/modals/update/SelectItemsModal.module.scss";
import { Banner, Checkbox, Input, Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import PropTypes from "prop-types";
import React from "react";

export enum SelectType {
  Add,
  Remove,
  Delete,
  Edit,
}

export type SelectItemsModalProps = {
  title: string;
  selectType: SelectType;
  entryInfos: DirectoryListingEntryInfo[];
  itemName: string;
  idsToUpdate: string[];
  setIdsToUpdate: (ids: string[]) => void;
  isModalOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  loading?: boolean;
  errorMessage?: Maybe<string>;
  submitDisabled?: boolean;
  searchQuery?: string;
  setSearchQuery?: (searchQuery: string) => void;
  warningMessage?: string;
  infoMessage?: string;
};

export const SelectItemsModal = (props: SelectItemsModalProps) => {
  const selectHandler = (changedId: string) => {
    if (props.idsToUpdate.includes(changedId)) {
      props.setIdsToUpdate(
        props.idsToUpdate.filter((entityId) => entityId !== changedId)
      );
    } else {
      props.setIdsToUpdate([...props.idsToUpdate, changedId]);
    }
  };

  const getRightSideElement = (entityId: EntityIdTupleNullable) => {
    return (
      <Checkbox
        size="sm"
        checked={
          entityId.entityId
            ? props.idsToUpdate.includes(entityId.entityId)
            : false
        }
        onChange={() => entityId.entityId && selectHandler(entityId.entityId)}
      />
    );
  };

  const getPrimaryButtonLabel = () => {
    switch (props.selectType) {
      case SelectType.Add:
        return `Add ${pluralize(
          props.itemName,
          props.idsToUpdate.length,
          props.idsToUpdate.length > 0
        )}`;
      case SelectType.Remove:
        return `Remove ${pluralize(
          props.itemName,
          props.idsToUpdate.length,
          props.idsToUpdate.length > 0
        )}`;
      case SelectType.Delete:
        return `Delete ${pluralize(
          props.itemName,
          props.idsToUpdate.length,
          props.idsToUpdate.length > 0
        )}`;
      default:
        return "Submit";
    }
  };

  props.entryInfos.forEach((entryInfo) => {
    if (!entryInfo.getRightSideElement) {
      entryInfo.getRightSideElement = getRightSideElement;
    }
  });

  return (
    <Modal
      isOpen={props.isModalOpen}
      onClose={props.onClose}
      title={props.title}
    >
      <Modal.Body>
        {props.errorMessage && (
          <ModalErrorMessage errorMessage={props.errorMessage} />
        )}
        {!props.errorMessage && props.warningMessage && (
          <div className={sprinkles({ marginBottom: "sm" })}>
            <Banner message={props.warningMessage} type={"warning"} />
          </div>
        )}
        {!props.errorMessage && !props.warningMessage && props.infoMessage && (
          <div className={sprinkles({ marginBottom: "sm" })}>
            <Banner message={props.infoMessage} type={"info"} />
          </div>
        )}
        {props.setSearchQuery && (
          <Input
            leftIconName="search"
            onChange={props.setSearchQuery}
            value={props.searchQuery}
            placeholder="Search"
          />
        )}
        <div className={selectItemsModalStyles.filler} />
        <div className={selectItemsModalStyles.modalFieldContainer}>
          {props.entryInfos.length > 0 ? (
            <DirectoryListingEntries entryInfos={props.entryInfos} />
          ) : (
            <div className={selectItemsModalStyles.emptyState}>{`No ${pluralize(
              props.itemName,
              2,
              false
            )} to display.`}</div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel={getPrimaryButtonLabel()}
        primaryButtonDisabled={props.idsToUpdate.length === 0}
        onPrimaryButtonClick={props.onSubmit}
        primaryButtonLoading={props.loading}
        secondaryButtonLabel="Close"
        onSecondaryButtonClick={props.onClose}
      />
    </Modal>
  );
};

SelectItemsModal.propTypes = {
  idsToUpdate: PropTypes.arrayOf(PropTypes.string),
};

export default SelectItemsModal;
