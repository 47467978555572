type Header<id> = {
  id: id;
  label: string;
  width: number;
  minWidth: number;
};

export const RESOURCE_NAME_HEADER: Header<"name"> = {
  id: "name",
  label: "Resource",
  width: 310,
  minWidth: 280,
};
export const GROUP_NAME_HEADER: Header<"name"> = {
  ...RESOURCE_NAME_HEADER,
  label: "Group",
};
export const CONNECTION_NAME_HEADER: Header<"name"> = {
  ...RESOURCE_NAME_HEADER,
  label: "App",
};
export const USER_NAME_HEADER: Header<"name"> = {
  id: "name",
  label: "Name",
  width: 180,
  minWidth: 160,
};

/**
 * Common headers
 */

export const SERVICE_HEADER: Header<"service"> = {
  id: "service",
  label: "Service",
  width: 200,
  minWidth: 180,
};

export const CONNECTION_HEADER: Header<"connection"> = {
  id: "connection",
  label: "App",
  width: 200,
  minWidth: 180,
};

export const ITEM_TYPE_HEADER: Header<"itemType"> = {
  ...SERVICE_HEADER,
  id: "itemType",
  label: "Type",
};

export const ROLE_HEADER: Header<"role"> = {
  id: "role",
  label: "Role",
  width: 230,
  minWidth: 200,
};
export const REVIEWERS_HEADER: Header<"reviewers"> = {
  id: "reviewers",
  label: "Reviewers",
  width: 160,
  minWidth: 160,
};
export const SUMMARY_STATUS_HEADER: Header<"status"> = {
  id: "status",
  label: "Status",
  width: 180,
  minWidth: 170,
};
export const REVOCATION_STATUS_HEADER: Header<"status"> = {
  id: "status",
  label: "Status",
  width: 200,
  minWidth: 190,
};

export const SUMMARY_ACTION_HEADER: Header<"action"> = {
  id: "action",
  label: "Action",
  width: 160,
  minWidth: 160,
};
export const MARK_LINK_HEADER: Header<"action"> = {
  id: "action",
  label: "--",
  width: 250,
  minWidth: 250,
};

/**
 * Conditional headers (displayed conditionally if currently performing review or not)
 *
 * The widths of the 2 groups of headers below must sum up to the same value. This ensures the previous
 * columns in the table don't shift when a user switches between the "perform" and "view" views.
 */

export const ITEM_STATUS_HEADER: Header<"status"> = {
  id: "status",
  label: "Status",
  width: 180,
  minWidth: 170,
};
export const OUTCOME_HEADER: Header<"outcome"> = {
  id: "outcome",
  label: "Review Decision",
  width: 170,
  minWidth: 110,
};

export const ITEM_ACTION_HEADER: Header<"acceptRevoke"> = {
  id: "acceptRevoke",
  label: "--",
  width: 220,
  minWidth: 220,
};

export const UPDATED_RESOURCE_HEADER: Header<"updatedResource"> = {
  id: "updatedResource",
  label: "Updated Access",
  width: 230,
  minWidth: 200,
};

export const NOTE_HEADER: Header<"note"> = {
  id: "note",
  label: "Note",
  width: 130,
  minWidth: 110,
};
