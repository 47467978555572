import {
  MessageChannelFragment,
  MessageChannelType,
  useMessageChannelsQuery,
} from "api/generated/graphql";
import { MessageChannelLabel } from "components/label/MessageChannelLabel";
import { AuditMessageChannelEdit } from "components/message_channel/AuditMessageChannelModal";
import { FormRow, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext, useState } from "react";
import { logError } from "utils/logging";
import { AppsContext } from "views/apps/AppsContext";

import { FormMode } from "../common";

const HELP_TEXT =
  "Subscribe to an audit log of access updates to this resource by linking a Slack channel here. Opal will only display Slack channels that are created from Opal. If multiple channels are linked, they'll all receive these updates.";

interface Props {
  mode: FormMode;
  messageChannels?: MessageChannelFragment[];
  onChange: (channels?: MessageChannelFragment[]) => void;
  adminOwnerName: string;
}

const SlackChannelsRow = (props: Props) => {
  const { bulkMode } = useContext(AppsContext);

  const [channelNameToCreate, setChannelNameToCreate] = useState("");

  const { data, error, loading } = useMessageChannelsQuery({
    variables: {
      input: {
        generateChannelName: props.adminOwnerName
          ? {
              itemName: props.adminOwnerName,
              channelType: MessageChannelType.Audit,
            }
          : undefined,
      },
    },
  });

  const channelById: { [channelId: string]: MessageChannelFragment } = {};
  let messageChannels: MessageChannelFragment[] = [];
  let dropdownErrorMessage = null;
  if (data) {
    switch (data.messageChannels.__typename) {
      case "MessageChannelsResult":
        messageChannels = data.messageChannels.messageChannels;
        messageChannels.forEach(
          (channel) => (channelById[channel.id] = channel)
        );
        if (
          channelNameToCreate === "" &&
          data.messageChannels.generatedChannelName
        ) {
          setChannelNameToCreate(data.messageChannels.generatedChannelName);
        }
        break;
      case "MessageChannelMissingOAuthScopeError":
        dropdownErrorMessage = data.messageChannels.message;
        break;
      case "MessageChannelRateLimitedError":
        dropdownErrorMessage = data.messageChannels.message;
        break;
      default:
        dropdownErrorMessage = `Error: failed to fetch message channels`;
        logError(new Error(`failed to fetch message channels`));
    }
  } else if (error) {
    dropdownErrorMessage = `Error: failed to fetch message channels`;
    logError(error, `failed to fetch message channels`);
  }

  const viewContent = (
    <>
      <div className={sprinkles({ marginBottom: "sm" })}>{HELP_TEXT}</div>
      {props.messageChannels?.length ? (
        props.messageChannels.map((channel) => (
          <MessageChannelLabel
            messageChannelName={channel.name}
            thirdPartyProvider={channel.thirdPartyProvider}
          />
        ))
      ) : (
        <div className={sprinkles({ fontWeight: "medium" })}>
          No linked channels
        </div>
      )}
    </>
  );

  const editContent = (
    <>
      {bulkMode === "edit" && (
        <div className={sprinkles({ marginBottom: "md" })}>
          <Switch
            label="Leave unchanged"
            checked={props.messageChannels == null}
            onChange={(val) => {
              if (val) {
                props.onChange(undefined);
              } else {
                props.onChange([]);
              }
            }}
            rightAlign
          />
        </div>
      )}
      <div className={sprinkles({ marginBottom: "sm" })}>{HELP_TEXT}</div>
      <AuditMessageChannelEdit
        messageChannelOptions={messageChannels.filter(
          (channel) => !props.messageChannels?.find((c) => c.id === channel.id)
        )}
        selectedChannels={props.messageChannels ?? []}
        setSelectedChannels={(selectedChannels) => {
          props.onChange(selectedChannels);
        }}
        loading={loading}
        channelNameToCreate={channelNameToCreate}
        adminTeamName={props.adminOwnerName}
        errorMessage=""
        dropdownErrorMessage={dropdownErrorMessage}
      />
    </>
  );

  return (
    <FormRow title="Linked audit Slack channels">
      {props.mode === "view" ? viewContent : editContent}
    </FormRow>
  );
};

export default SlackChannelsRow;
