import { NetworkStatus } from "@apollo/client";
import {
  AccessOption,
  AppDataFragment,
  AppsSortByField,
  SortDirection,
  useAppsListColumnQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import LayoutToggle from "components/enduser_exp/LayoutToggle";
import OpalPage from "components/opal/layout/OpalPage";
import {
  ButtonV3,
  Input,
  InteractiveCard,
  Label,
  Masonry,
  Select,
  Skeleton,
  Tooltip,
} from "components/ui";
import ButtonGroup from "components/ui/buttongroup/ButtonGroupV3";
import Table, { Header } from "components/ui/table/Table";
import TableFilters from "components/ui/table/TableFilters";
import TableHeader from "components/ui/table/TableHeader";
import _ from "lodash";
import pluralize from "pluralize";
import { useContext, useMemo, useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { useDebouncedValue, useMountEffect } from "utils/hooks";
import { usePageTitle } from "utils/hooks";
import { useTransitionTo, useURLSearchParam } from "utils/router/hooks";
import { useAccessRequestTransition } from "views/access_request/AccessRequestContext";
import {
  ACCESS_OPTION_URL_KEY,
  AppsContext,
  ITEM_TYPE_URL_KEY,
} from "views/apps/AppsContext";
import { getAppIcon, useAccessOptionKey } from "views/apps/utils";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import * as appStyles from "./AppCatalog.css";
import * as styles from "./Catalog.css";
import { BREAKPOINT_COLUMNS } from "./constants";
import * as tableStyles from "./Table.css";

const NAME_COL_ID = AppsSortByField.Name;
const SORT_OPTIONS: { label: string; value: SortValue }[] = [
  {
    label: "Name (A-Z)",
    value: {
      field: NAME_COL_ID,
      direction: SortDirection.Asc,
    },
  },
  {
    label: "Name (Z-A)",
    value: {
      field: NAME_COL_ID,
      direction: SortDirection.Desc,
    },
  },
];

type AccessStats = {
  teamAccessCount?: number | null;
  titleAccessCount?: number | null;
};

type SortValue = {
  field: AppsSortByField;
  direction: SortDirection;
};

type AppRow = {
  id: string;
  [NAME_COL_ID]: string;
  transitionTo?: React.MouseEventHandler;
  requestTransitionTo?: React.MouseEventHandler;
  appData: AppDataFragment;
  teamAccessCount?: number | null;
  titleAccessCount?: number | null;
  // access: string; // TODO
  cta?: string;
};

const NAME_COL: Header<AppRow> = {
  id: NAME_COL_ID,
  label: "Name",
  sortable: true,
  width: 326,
  customCellRenderer: (row) => {
    return (
      <div
        className={tableStyles.text({
          bold: true,
        })}
      >
        <Label
          label={row[NAME_COL_ID]}
          icon={getAppIcon(row.appData)}
          iconSize="lgr"
          oneLine
        />
      </div>
    );
  },
};

const TEAM_ACCESS_COUNT_COL: Header<AppRow> = {
  id: "teamAccessCount",
  label: "Used by people on your team",
  sortable: false,
  width: 100,
  customCellRenderer: (row) => {
    return row.teamAccessCount != null ? (
      <div
        className={tableStyles.text({
          noCounts: row.teamAccessCount === 0,
        })}
      >
        <Label
          label={`${pluralize("team member", row.teamAccessCount, true)}`}
          oneLine
        />
      </div>
    ) : (
      <></>
    );
  },
};

const TITLE_ACCESS_COUNT_COL: Header<AppRow> = {
  id: "titleAccessCount",
  label: "Used by people with your title",
  sortable: false,
  width: 100,
  customCellRenderer: (row) => {
    return row.titleAccessCount != null ? (
      <div
        className={tableStyles.text({
          noCounts: row.titleAccessCount === 0,
        })}
      >
        <Label
          label={`${pluralize(
            "people",
            row.titleAccessCount,
            true
          )} with your title`}
          oneLine
        />
      </div>
    ) : (
      <></>
    );
  },
};

const REQUEST_ITEMS_COL: Header<AppRow> = {
  id: "cta",
  label: "",
  sortable: false,
  width: 25,
  customCellRenderer: (row) => {
    return (
      <div className={tableStyles.requestCta}>
        <Tooltip tooltipText={"Request items"}>
          <ButtonV3
            leftIconName="raised-hand"
            size="xs"
            type="mainBorderless"
            onClick={(event) => {
              event.stopPropagation();
              if (row.requestTransitionTo) {
                row.requestTransitionTo(event);
              }
            }}
          />
        </Tooltip>
      </div>
    );
  },
};

function isSortableField(str: string): str is AppsSortByField {
  return Object.values<string>(AppsSortByField).includes(str);
}

const AppCatalog = () => {
  // TODO: add category filter and amplitude event logging
  const logEvent = useLogEvent();
  const [sortBy, setSortBy] = useState<SortValue | undefined>(
    SORT_OPTIONS[0].value
  );
  const history = useHistory();
  const transitionTo = useTransitionTo({
    preserveQueries: [ACCESS_OPTION_URL_KEY, ITEM_TYPE_URL_KEY],
  });
  const transitionToAccessRequest = useAccessRequestTransition();
  const { authState } = useContext(AuthContext);
  const columns: Header<AppRow>[] = [
    NAME_COL,
    ...(authState.user?.user?.teamAttr != null ? [TEAM_ACCESS_COUNT_COL] : []),
    ...(authState.user?.user.position !== "" ? [TITLE_ACCESS_COUNT_COL] : []),
    REQUEST_ITEMS_COL,
  ];

  // const {
  //   displayLoadingToast,
  //   displaySuccessToast,
  //   displayErrorToast,
  // } = useToast();

  const [searchQuery, setSearchQuery] = useURLSearchParam("search", "");
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 200);
  const { layoutOption } = useContext(AppsContext);
  const [accessOptionKey, setAccessOptionKey] = useAccessOptionKey();

  useMountEffect(() => {
    logEvent({
      name: "catalog_apps",
      properties: {
        view: "eux",
      },
    });
  });

  const {
    data: appsListData,
    previousData: previousAppListData,
    loading: appsListLoading,
    error: appsListError,
    fetchMore: fetchMoreApps,
    networkStatus: appsListNetworkStatus,
  } = useAppsListColumnQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      access: accessOptionKey,
      searchQuery: debouncedSearchQuery,
      sortBy: sortBy,
      fetchAccessStats: true,
    },
    notifyOnNetworkStatusChange: true,
  });
  // fetchMore status is when we're fetching more items
  // loading is only used on first load
  // setVariables is used when changing the search query, category or access
  // If we're fetching more items, use the previous data until the new one comes in
  const appsFetchingMore = appsListNetworkStatus === NetworkStatus.fetchMore;
  const appsList = appsFetchingMore
    ? appsListData || previousAppListData
    : appsListData;

  const appsToDisplay = useMemo(() => appsList?.apps.apps || [], [appsList]);
  const totalNumApps = appsList?.apps.totalNumApps ?? 0;
  const cursor = appsList?.apps.cursor;

  const loadMoreRows = cursor
    ? async () => {
        await fetchMoreApps({
          variables: {
            cursor,
            access: accessOptionKey,
            searchQuery: debouncedSearchQuery,
            sortBy: sortBy,
            fetchAccessStats: true,
          },
        });
      }
    : undefined;

  usePageTitle("Apps");

  function getAppTransitionTo(
    app: AppDataFragment
  ): React.MouseEventHandler<HTMLElement> | undefined {
    switch (app.__typename) {
      case "ConnectionApp":
        return (event) =>
          transitionTo({ pathname: `/apps/${app.connectionId}` }, event);
      case "OktaResourceApp":
        return (event) =>
          transitionTo({ pathname: `/apps/${app.resourceId}` }, event);
    }
  }

  function getAppRequestTransition(
    app: AppDataFragment
  ): React.MouseEventHandler<HTMLElement> | undefined {
    switch (app.__typename) {
      case "ConnectionApp":
        return (event) =>
          transitionToAccessRequest({ appId: app.connectionId }, event);
      case "OktaResourceApp":
        return (event) =>
          transitionToAccessRequest({ appId: app.resourceId }, event);
    }
  }

  function getAccessStats(app: AppDataFragment): AccessStats {
    switch (app.__typename) {
      case "ConnectionApp":
        return {
          teamAccessCount: app.connection?.accessStats?.teamAccessCount,
          titleAccessCount: app.connection?.accessStats?.titleAccessCount,
        };
      case "OktaResourceApp":
        return {
          teamAccessCount: app.resource?.accessStats?.teamAccessCount,
          titleAccessCount: app.resource?.accessStats?.titleAccessCount,
        };
      default: {
        return {
          teamAccessCount: undefined,
          titleAccessCount: undefined,
        };
      }
    }
  }

  const handleChangeAccessMode = (option: AccessOption) => {
    logEvent({
      name: "apps_access_mode_change",
      properties: {
        access_mode: option,
      },
    });
    setAccessOptionKey(option);
  };

  const rows: AppRow[] = useMemo(
    () =>
      appsToDisplay.map((app) => {
        const accessStats = getAccessStats(app.app);
        return {
          id: app.id,
          [NAME_COL_ID]: app.name,
          transitionTo: getAppTransitionTo(app.app),
          requestTransitionTo: getAppRequestTransition(app.app),
          appData: app.app,
          titleAccessCount: accessStats.titleAccessCount,
          teamAccessCount: accessStats.teamAccessCount,
        };
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [appsToDisplay]
  );

  if (appsListError) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={appsListError} />
      </Column>
    );
  }

  return (
    <OpalPage
      title="Catalog"
      icon="apps"
      tabs={[
        {
          title: "Apps",
          isSelected: history.location.pathname.endsWith("/apps"), // TODO
          onClick: () => history.push("/apps"),
        },
        {
          title: "Bundles",
          isSelected: history.location.pathname.endsWith("/bundles"), // TODO
          onClick: () => history.push("/bundles"),
        },
      ]}
    >
      <TableFilters>
        <TableFilters.Left>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery ?? ""}
              onChange={setSearchQuery}
              placeholder="Filter Apps by name"
            />
          </div>
          <ButtonGroup
            buttons={[
              {
                label: "All",
                onClick: () => handleChangeAccessMode(AccessOption.All),
                selected: accessOptionKey === AccessOption.All,
              },
              {
                label: "My Access",
                onClick: () => handleChangeAccessMode(AccessOption.Mine),
                selected: accessOptionKey === AccessOption.Mine,
              },
            ]}
          />
        </TableFilters.Left>
        <TableFilters.Right>
          {layoutOption === "grid" && (
            <div className={styles.sortBySelect}>
              <Select
                value={SORT_OPTIONS.find((option) =>
                  _.isEqual(option.value, sortBy)
                )}
                searchable={false}
                options={SORT_OPTIONS}
                getOptionLabel={(option) => option.label}
                onChange={(option) => {
                  if (option) setSortBy(option.value);
                }}
                size="sm"
              />
            </div>
          )}
          <LayoutToggle />
        </TableFilters.Right>
      </TableFilters>

      {appsListLoading && !appsList ? (
        <Skeleton variant="text" width="100px" />
      ) : (
        <>
          <TableHeader entityName="App" totalNumRows={totalNumApps} />
          {layoutOption === "grid" ? (
            <Masonry
              loadingItems={appsListLoading}
              onLoadMoreItems={loadMoreRows}
              totalNumItems={totalNumApps}
              items={rows}
              getItemKey={(row) => row.id}
              breakpointCols={BREAKPOINT_COLUMNS}
              renderItem={(app) => (
                <InteractiveCard
                  title={app[NAME_COL_ID]}
                  icon={getAppIcon(app.appData)}
                  iconSize="xxl"
                  onClick={(event) => {
                    event.stopPropagation();
                    if (app.transitionTo) {
                      app.transitionTo(event);
                    }
                  }}
                  renderCTA={(isHovering) => {
                    if (isHovering) {
                      return app.teamAccessCount != null ? (
                        <div className={appStyles.hoverLabel}>
                          <Label
                            label={`${pluralize(
                              "team member",
                              app.teamAccessCount,
                              true
                            )} ${
                              app.teamAccessCount === 1 ? "uses" : "use"
                            } this app`}
                            icon={{ type: "name", icon: "users" }}
                          />
                        </div>
                      ) : (
                        <></>
                      );
                    }
                  }}
                  renderHeaderCTA={(isHovering) => {
                    if (isHovering) {
                      return (
                        <>
                          <Tooltip tooltipText={"Request items"}>
                            <ButtonV3
                              leftIconName="raised-hand"
                              size="xs"
                              type="mainBorderless"
                              onClick={(event) => {
                                event.stopPropagation();
                                if (app.requestTransitionTo) {
                                  app.requestTransitionTo(event);
                                }
                              }}
                            />
                          </Tooltip>
                        </>
                      );
                    }
                  }}
                />
              )}
            />
          ) : (
            <Table
              rows={rows}
              totalNumRows={cursor ? Number.MAX_SAFE_INTEGER : rows.length}
              emptyState={{
                title: "No apps",
              }}
              getRowId={(ru) => ru.id}
              columns={columns}
              onRowClick={(row, event) => {
                event.stopPropagation();
                if (row.transitionTo) {
                  row.transitionTo(event);
                }
              }}
              onLoadMoreRows={loadMoreRows}
              manualSortDirection={
                sortBy && {
                  sortBy: sortBy.field,
                  sortDirection: sortBy.direction,
                }
              }
              handleManualSort={(sortBy, sortDirection) => {
                if (!sortDirection) {
                  setSortBy(undefined);
                  return;
                }
                if (!isSortableField(sortBy)) {
                  return;
                }
                const direction: SortDirection =
                  sortDirection === "DESC"
                    ? SortDirection.Desc
                    : SortDirection.Asc;

                setSortBy({
                  field: sortBy,
                  direction,
                });
              }}
            />
          )}
        </>
      )}
    </OpalPage>
  );
};

export default AppCatalog;
