import {
  OidcProviderType,
  useInitOidcAuthFlowPresignedMutation,
} from "api/generated/graphql";
import { useToast } from "components/toast/Toast";
import React, { useState } from "react";
import { generateState, getPresignedAuthentication } from "utils/auth/auth";
import { useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";
import { setOidcData } from "utils/oidc/oidc";
import { ErrorCodePage } from "views/error/ErrorCodePage";
import { FullPageLoading } from "views/loading/FullPageLoading";

// SlackMfaOidcPresigned is a dedicated component for triggering the OIDC Callback flow
// for MFA requests originating from the slack app. Users may not be logged in
// with username/password, so we provide a presigned user token with their URL,
// and use that to authenticate them.
export const SlackMfaOidcPresigned = () => {
  const [initOidcAuthFlowPresigned] = useInitOidcAuthFlowPresignedMutation();
  const { displayErrorToast } = useToast();
  const [returnError, setReturnError] = useState(false);

  async function executeMfaCallback() {
    let presignedUserToken = getPresignedAuthentication();
    if (presignedUserToken === null) {
      setReturnError(true);
      return;
    }

    try {
      const state = generateState();
      const { data } = await initOidcAuthFlowPresigned({
        variables: {
          input: {
            oidcProviderType: OidcProviderType.Mfa,
            state: state,
          },
        },
      });
      switch (data?.initOidcAuthFlowPresigned.__typename) {
        case "InitOidcAuthFlowResult":
          setOidcData({
            state: state,
            oidcProviderType: OidcProviderType.Mfa,
            postAuthPath: "/callback/slackmfa",
            mfaParams: {
              presignedUserToken: presignedUserToken,
            },
          });
          window.location.replace(data?.initOidcAuthFlowPresigned.authorizeUrl);
          break;
        case "OidcProviderNotFoundError":
          displayErrorToast(
            "Error: Failed to trigger MFA. Please contact your admin."
          );
          break;
        default:
          displayErrorToast(
            "Error: Failed to trigger MFA. Please contact your admin."
          );
      }
    } catch (e) {
      logError(new Error(`validating and saving OIDC ID token failed`));
      setReturnError(true);
    }
  }

  useMountEffect(() => {
    executeMfaCallback();
  });

  return returnError ? (
    <ErrorCodePage
      errorCode={401}
      subtitle={"Invalid authorization, please try again"}
      showAccountSwitchButton={false}
    />
  ) : (
    <FullPageLoading />
  );
};

export default SlackMfaOidcPresigned;
