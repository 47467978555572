import {
  ConnectionMetadataInput,
  ConnectionPreviewLargeFragment,
} from "api/generated/graphql";
import {
  Button,
  Checkbox,
  FileUpload,
  FormRow,
  Icon,
  Input,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useState } from "react";

import {
  InputInfo,
  InputType,
  useMetadataAndCredsUpdater,
} from "./ConnectionMetadataAndCredsSetup";

const MAX_METADATA_FIELD_LENGTH: number = 200;

type ConnectionMetadataRowProps = {
  connection: ConnectionPreviewLargeFragment;
  metadataKey: string;
  metadataVal: string;
  metadata: ConnectionMetadataInput;
  setMetadata: (metadata: ConnectionMetadataInput) => void;
  editMode?: boolean;
  objKeyInputInfo: Record<string, InputInfo>;
};

const ConnectionMetadataRow = (props: ConnectionMetadataRowProps) => {
  const { objKeyInputInfo } = props;
  const updateMetadata = useMetadataAndCredsUpdater(objKeyInputInfo);
  const setCurrentMetadataVal = (val: string | boolean) => {
    const updatedMetadata = updateMetadata(
      props.metadata,
      props.metadataKey,
      val
    );
    props.setMetadata({ ...updatedMetadata });
  };
  const [uploadedFileName, setUploadedFileName] = useState<string | null>();

  const defaultVal = "--";

  const inputInfo = objKeyInputInfo[props.metadataKey];
  var input;
  switch (inputInfo?.inputType) {
    case InputType.FILE:
      input = (
        <div>
          {uploadedFileName ? (
            <div className={sprinkles({ marginBottom: "md" })}>
              <Icon name="check" />
              {uploadedFileName}
            </div>
          ) : null}
          <FileUpload
            handleUpload={(file) => {
              const fileReader = new FileReader();
              fileReader.onload = async () => {
                const data = fileReader.result;
                if (data) {
                  setCurrentMetadataVal(data.toString());
                }
              };
              if (file) {
                setUploadedFileName(file.name);
                fileReader.readAsText(file);
              }
            }}
            renderButton={(onClick) => (
              <Button
                borderless
                label={`Upload ${inputInfo.inputTitle}`}
                onClick={onClick}
              />
            )}
            accept={inputInfo.fileAccept}
          />
        </div>
      );
      break;
    case InputType.BOOLEAN:
      input = (
        <Checkbox
          checked={Boolean(props.metadataVal)}
          onChange={() => setCurrentMetadataVal(!props.metadataVal)}
          label="Enabled"
        />
      );
      break;
    default:
      input = (
        <Input
          value={String(props.metadataVal)}
          onChange={(updatedVal) => {
            setCurrentMetadataVal(updatedVal);
          }}
        />
      );
  }

  let title = inputInfo?.inputTitle ?? _.startCase(props.metadataKey);

  // capitalise acronyms in the title
  const acronyms = ["ID", "URL"];
  acronyms.forEach((acronym) => {
    // check word boundary
    const re = new RegExp(`\\b${acronym}\\b`, "gi");
    title = title.replace(re, acronym);
  });

  let val: string;
  if (inputInfo?.valueConversion && props.metadataVal) {
    val = inputInfo.valueConversion.read.call(
      null,
      props.metadataVal.toString()
    );
  } else {
    val = props.metadataVal?.toString() || defaultVal;
  }

  if (val.length > MAX_METADATA_FIELD_LENGTH) {
    val = _.truncate(val, { length: MAX_METADATA_FIELD_LENGTH });
  }

  return <FormRow title={title}>{props.editMode ? input : val}</FormRow>;
};

export default ConnectionMetadataRow;
