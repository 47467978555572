import * as Sentry from "@sentry/react";
import {
  GeneralSettingType,
  IntegrationType,
  useAccessReviewsNoStatsQuery,
  useIdpConnectionQuery,
  useOrganizationSettingsQuery,
  useThirdPartyIntegrationsQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import LogRocket from "logrocket";
import { useContext } from "react";
import { getUserRoles } from "utils/analytics";
import { initDatadogRUMTracer } from "utils/datadog/tracer";
import { useIdentify } from "utils/feature_flags";
import { logError } from "utils/logging";
import AccessReviewContext, {
  AccessReviewContextActionType,
} from "views/access_reviews/AccessReviewContext";
import AppContext from "views/app/AppContext";
import OrgContext, { OrgContextActionType } from "views/settings/OrgContext";

// any hooks/setup we need to do when the app initializes.
const useInitApp = () => {
  const { authState } = useContext(AuthContext);
  const { appState } = useContext(AppContext);
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );
  const { orgState, orgDispatch } = useContext(OrgContext);

  // Identify user for LaunchDarkly feature flags.
  useIdentify(authState, appState);

  if (appState.isRemoteLoggingEnabled) {
    Sentry.init({
      dsn:
        "https://6e6b86c3c574446ba8a7a446186b4532@o581619.ingest.sentry.io/5736543",
      integrations: [Sentry.browserTracingIntegration()],

      // Set tracesSampleRate to 1.0 to capture 100%
      // of transactions for performance monitoring.
      // We recommend adjusting this value in production
      tracesSampleRate: 0.2,
      release: appState.version,
      environment: appState.environment,
      attachStacktrace: true,
    });

    Sentry.setUser({ id: authState.user!.user.id });
    Sentry.setTag("userId", authState.user!.user.id);
    Sentry.setTag("userName", authState.user!.user.fullName);
    Sentry.setTag("orgId", authState.user!.user.organization.id);
    Sentry.setTag("orgName", authState.user!.user.organization.name);

    var isLogRocketEnabled =
      orgState.orgSettings &&
      !orgState.orgSettings.generalSettings.includes(
        GeneralSettingType.LogRocketDisabled
      );
    if (
      (!appState.isOnPrem || window.location.hostname === "us.opal.dev") &&
      isLogRocketEnabled
    ) {
      LogRocket.identify(authState.user!.user.id, {
        name: authState.user!.user.fullName,
        email: authState.user!.user.email,
      });

      LogRocket.init("owtokk/opal", {
        release: `v${appState.version}`,
        network: {
          isEnabled: true,
          requestSanitizer: (request) => {
            request.body = undefined;
            if (
              "authorization" in request.headers &&
              request.headers["authorization"]
            ) {
              request.headers["authorization"] = "";
            }
            return request;
          },
          responseSanitizer: (response) => {
            response.body = undefined;
            return response;
          },
        },
      });
    }
    // Initialize Datadog RUM tracing
    initDatadogRUMTracer(appState.environment, appState.version, {
      id: authState.user!.user.id,
      orgId: authState.user!.user.organization.id,
      orgName: authState.user!.user.organization.name,
      userRoles: getUserRoles(authState),
    });
  }

  useOrganizationSettingsQuery({
    onCompleted: (data) => {
      switch (data.organizationSettings.__typename) {
        case "OrganizationSettingsResult": {
          const orgSettings = data.organizationSettings.settings;
          orgDispatch({
            type: OrgContextActionType.OrgSettings,
            payload: {
              orgSettings: orgSettings,
            },
          });
          break;
        }
        default:
          logError(new Error(`failed to initialize org settings`));
      }
    },
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      logError(error, `failed to initialize org settings with error`);
    },
  });

  useAccessReviewsNoStatsQuery({
    variables: {
      input: {
        ongoingOnly: true,
      },
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      switch (data.accessReviews.__typename) {
        case "AccessReviewsResult": {
          data.accessReviews.accessReviews.forEach((accessReview) => {
            accessReviewState.ongoingAccessReviewIdSet.add(accessReview.id);
          });
          accessReviewDispatch({
            type: AccessReviewContextActionType.AccessReviewUpdate,
            payload: {
              ongoingAccessReviewIdSet:
                accessReviewState.ongoingAccessReviewIdSet,
            },
          });
          break;
        }
        default:
          logError(new Error(`failed to initialize access reviews state`));
      }
    },
    onError: (error) => {
      logError(error, `failed to initialize access reviews state with error`);
      accessReviewDispatch({
        type: AccessReviewContextActionType.AccessReviewUpdate,
        payload: {
          ongoingAccessReviewIdSet: new Set(),
        },
      });
    },
  });

  useThirdPartyIntegrationsQuery({
    variables: {
      input: {
        integrationType: IntegrationType.Org,
      },
    },
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      switch (data.thirdPartyIntegrations.__typename) {
        case "ThirdPartyIntegrationsResult": {
          const orgIntegrations =
            data.thirdPartyIntegrations.thirdPartyIntegrations;
          orgDispatch({
            type: OrgContextActionType.OrgThirdPartyIntegrations,
            payload: {
              orgThirdPartyIntegrations: orgIntegrations,
            },
          });
          break;
        }
        default:
          logError(new Error(`failed to initialize third party integrations`));
      }
    },
    onError: (error) => {
      logError(
        error,
        `failed to initialize third party integrations with error`
      );
    },
  });

  useIdpConnectionQuery({
    onCompleted: (data) => {
      switch (data.idpConnection.__typename) {
        case "IdpConnectionResult": {
          const idpConnections = data.idpConnection.idpConnections;
          orgDispatch({
            type: OrgContextActionType.OrgIdpConnection,
            payload: {
              orgIdpConnections: idpConnections,
            },
          });
          break;
        }
        case "IdpConnectionNotFoundError":
          break;
        default:
          logError(new Error(`failed to initialize idp connection`));
      }
    },
    notifyOnNetworkStatusChange: true,
    onError: (error) => {
      logError(error, `failed to initialize idp connection with error`);
    },
  });
};

export default useInitApp;
