import { Group } from "@visx/group";
import { DataElement, Icon } from "components/ui";
import { IconName } from "components/ui/icon/Icon";
import sprinkles from "css/sprinkles.css";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import { BASE_NODE_WIDTH, HEADER_HEIGHT } from "../common";

interface Props {
  key: string;
  x: number;
  y: number;
  title: string;
  iconName?: IconName;
  count?: number;

  onShowHideToggle?: (show: boolean) => void;
  isShowing?: boolean;
}

const ColumnHeader = (props: Props) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  return (
    <Group key={`${props.key}-column-header`} top={props.y} left={props.x}>
      <foreignObject height={HEADER_HEIGHT} width={BASE_NODE_WIDTH}>
        {hasV3 ? (
          <div
            className={sprinkles({
              display: "flex",
              gap: "sm",
              fontSize: "textMd",
              fontWeight: "medium",
            })}
          >
            <Icon name={props.iconName} />
            {props.title}
            {props.count !== undefined ? ` (${props.count})` : null}
          </div>
        ) : (
          <DataElement
            label={props.title}
            leftIcon={{
              name: props.iconName,
            }}
            color="gray"
            disableHover
            rightIcon={
              props.onShowHideToggle
                ? {
                    name: props.isShowing ? "eye-off" : "eye",
                    onClick: () => {
                      props.onShowHideToggle &&
                        props.onShowHideToggle(!props.isShowing);
                    },
                  }
                : undefined
            }
          />
        )}
      </foreignObject>
    </Group>
  );
};

export default ColumnHeader;
