import { ConnectionType } from "api/generated/graphql";
import { IconName } from "components/ui/icon/Icon";
import React from "react";
import { Route, Switch, useRouteMatch } from "react-router";
import { isConnectionTypeUsersProvider } from "utils/directory/connections";
import { isConfigurableConnectionType } from "utils/directory/resources";
import { NotFoundPage } from "views/error/ErrorCodePage";

import AppEventsColumn from "./AppEventsColumn";
import AppOverviewColumn from "./AppOverviewColumn";
import AppSetupColumn from "./AppSetupColumn";
import AppUsersColumn from "./AppUsersColumn";

type AppViewKey = "add" | "all" | "overview" | "users" | "events" | "setup";
interface AppView {
  key: AppViewKey;
  title: string;
  content: React.ComponentType;
  icon: IconName;
  hidden?: (isAdmin: boolean, connectionType: ConnectionType) => boolean;
  type?: "success";
}

export const APP_VIEWS: AppView[] = [
  {
    key: "overview",
    title: "Overview",
    content: AppOverviewColumn,
    icon: "list",
  },
  {
    key: "users",
    title: "Users",
    content: AppUsersColumn,
    icon: "user",
    hidden: (isAdmin, connectionType) =>
      !isAdmin || !isConnectionTypeUsersProvider(connectionType),
  },
  {
    key: "events",
    title: "Events",
    content: AppEventsColumn,
    icon: "events",
    hidden: (isAdmin) => !isAdmin,
  },
  {
    key: "setup",
    title: "Setup",
    content: AppSetupColumn,
    icon: "settings",
    hidden: (isAdmin, connectionType) =>
      !isAdmin || !isConfigurableConnectionType(connectionType),
  },
];

const AppDetailContent = () => {
  const match = useRouteMatch();

  return (
    <Switch>
      {APP_VIEWS.map((appView) => (
        <Route
          key={appView.key}
          exact
          path={`${match.path}/${appView.key}`}
          component={appView.content}
        />
      ))}
      <Route exact path={`${match.path}`} />
      <Route path={`${match.path}`} component={NotFoundPage} />
    </Switch>
  );
};

export default AppDetailContent;
