import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewFragment,
  AccessReviewGroupPreviewFragment,
  EntityType,
  ReviewerUserStatus,
  useUpdateAccessReviewGroupReviewersMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { GroupTableCellResourceLabel } from "components/label/GroupTableCellResourceLabel";
import ConnectionLabel from "components/label/item_labels/ConnectionLabel";
import AccessReviewerModal from "components/modals/AccessReviewerModal";
import {
  CellRow,
  Header,
  ScrollableMuiVirtualTable,
} from "components/tables/material_table/MuiVirtualTable";
import { useToast } from "components/toast/Toast";
import { Button } from "components/ui";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { SortDirection } from "react-virtualized";
import { logError } from "utils/logging";
import AccessReviewContext, {
  AccessReviewContextActionType,
  emptyPerformReviewState,
} from "views/access_reviews/AccessReviewContext";
import AccessReviewReviewersCell from "views/access_reviews/AccessReviewReviewersCell";
import {
  AccessReviewStatusLabel,
  accessReviewSummaryStatusSortValue,
} from "views/access_reviews/AccessReviewStatus";
import {
  getReviewerModalEntries,
  getReviewersSortValue,
} from "views/access_reviews/common/Common";
import { getAccessReviewEntityUrl } from "views/access_reviews/common/Routes";
import {
  CONNECTION_HEADER,
  GROUP_NAME_HEADER,
  REVIEWERS_HEADER,
  SUMMARY_ACTION_HEADER,
  SUMMARY_STATUS_HEADER,
} from "views/access_reviews/common/TableHeaders";

interface GroupsAccessReviewTableRow {
  name: string;
  connection: string;
  reviewers: string;
  status: string;
  action: string;
}

type AccessReviewGroupsTableProps = {
  accessReview: AccessReviewFragment;
  accessReviewGroups: AccessReviewGroupPreviewFragment[];
};

const AccessReviewGroupsTable = (props: AccessReviewGroupsTableProps) => {
  const history = useHistory();
  const { authState } = useContext(AuthContext);
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );

  const headers: Header<GroupsAccessReviewTableRow>[] = [
    GROUP_NAME_HEADER,
    CONNECTION_HEADER,
    REVIEWERS_HEADER,
    SUMMARY_STATUS_HEADER,
  ];

  if (accessReviewState.ongoingAccessReviewIdSet.has(props.accessReview.id)) {
    headers.push(SUMMARY_ACTION_HEADER);
  }

  let rows: CellRow<GroupsAccessReviewTableRow>[] = [];

  props.accessReviewGroups.forEach((accessReviewGroup, i) => {
    if (accessReviewGroup.group) {
      const name = accessReviewGroup.group.name;
      const status = accessReviewGroup.status;
      const userCanReviewGroup = authState.user?.user.id
        ? getUserCanReviewGroup(authState.user.user.id, accessReviewGroup)
        : false;

      const connection = accessReviewGroup.group.connection;

      const canEditReviewers =
        accessReviewState.ongoingAccessReviewIdSet.has(
          accessReviewGroup.accessReviewId
        ) && accessReviewGroup.canEditReviewers;

      rows.push({
        id: accessReviewGroup.id,
        data: {
          name: {
            value: name,
            element: (
              <GroupTableCellResourceLabel
                groupName={name}
                groupType={accessReviewGroup.group.groupType}
                isOnCall={accessReviewGroup.group.isOnCallSynced}
              />
            ),
            clickHandler: () => {
              history.push(getAccessReviewEntityUrl(accessReviewGroup));
            },
          },
          connection: {
            sortValue: connection?.name || "",
            value: connection?.id || `${connection}-${i}`,
            element: connection ? (
              <ConnectionLabel
                text={connection?.name}
                connectionType={connection?.connectionType}
              />
            ) : (
              <>{`--`}</>
            ),
          },
          reviewers: {
            value: getReviewersSortValue(accessReviewGroup.reviewerUsers),
            element:
              accessReviewGroup.numGroupUsers === 0 &&
              accessReviewGroup.numGroupResources === 0 ? (
                <>--</>
              ) : (
                <GroupAccessReviewersCell
                  accessReviewGroup={accessReviewGroup}
                />
              ),
          },
          status: {
            sortValue:
              10 * accessReviewSummaryStatusSortValue(status) +
              (authState.user?.user.id &&
              getUserCanReviewGroup(authState.user.user.id, accessReviewGroup)
                ? 0
                : 1),
            value: status,
            element: (
              <AccessReviewStatusLabel
                entityType={EntityType.Group}
                status={status}
                warnings={accessReviewGroup.warnings}
                group={accessReviewGroup.group}
                accessReviewId={accessReviewGroup.accessReviewId}
                canEditReviewers={canEditReviewers}
              />
            ),
          },
          action: {
            value: userCanReviewGroup.toString(),
            element: userCanReviewGroup ? (
              <Button
                type="warning"
                label="Review Access"
                leftIconName="check-square"
                onClick={() => {
                  if (accessReviewGroup.group) {
                    accessReviewDispatch({
                      type:
                        AccessReviewContextActionType.AccessReviewItemUpdate,
                      payload: {
                        performReviewStateByUARGroupId: {
                          ...accessReviewState.performReviewStateByUARGroupId,
                          [accessReviewGroup.id]: emptyPerformReviewState(),
                        },
                      },
                    });
                    history.push(getAccessReviewEntityUrl(accessReviewGroup));
                  }
                }}
              />
            ) : (
              <>{"--"}</>
            ),
          },
        },
      });
    }
  });

  return (
    <ScrollableMuiVirtualTable
      columns={headers}
      rows={rows}
      defaultSortBy={"status"}
      defaultSortDirection={SortDirection.ASC}
      allRowsLoaded
    />
  );
};

export const getUserCanReviewGroup = (
  userId: string,
  group: AccessReviewGroupPreviewFragment
) => {
  for (const reviewerUser of group.reviewerUsers) {
    if (
      reviewerUser.userId === userId &&
      reviewerUser.status === ReviewerUserStatus.NotStarted
    ) {
      return true;
    }
  }
  return false;
};

type GroupAccessReviewersCellProps = {
  accessReviewGroup: AccessReviewGroupPreviewFragment;
};

export const GroupAccessReviewersCell = (
  props: GroupAccessReviewersCellProps
) => {
  const { accessReviewState } = useContext(AccessReviewContext);
  const [showReviewersModal, setShowReviewersModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { displaySuccessToast } = useToast();

  const [
    updateGroupReviewers,
    { loading },
  ] = useUpdateAccessReviewGroupReviewersMutation();

  const canEditReviewers =
    accessReviewState.ongoingAccessReviewIdSet.has(
      props.accessReviewGroup.accessReviewId
    ) && props.accessReviewGroup.canEditReviewers;

  return (
    <>
      <AccessReviewReviewersCell
        itemType="group"
        reviewerUsers={getReviewerModalEntries(
          props.accessReviewGroup.reviewerUsers,
          null
        )}
        canEditReviewers={canEditReviewers}
        onClick={() => {
          setShowReviewersModal(true);
        }}
      />
      {showReviewersModal && (
        <AccessReviewerModal
          title={"Reviewers"}
          isModalOpen={showReviewersModal}
          numUserReviews={props.accessReviewGroup.numGroupUsers}
          numResourceReviews={props.accessReviewGroup.numGroupResources}
          onClose={() => {
            setShowReviewersModal(false);
            setErrorMessage("");
          }}
          loading={loading}
          onSubmit={async (reviewers) => {
            try {
              const { data } = await updateGroupReviewers({
                variables: {
                  input: {
                    accessReviewGroupId: props.accessReviewGroup.id,
                    reviewers: reviewers,
                    updateOnlyForEntities: false,
                  },
                },
                refetchQueries: [
                  "OngoingAccessReviewStats",
                  "OngoingAccessReviewTabStats",
                  "OngoingAccessReviewSubtabStats",
                ],
              });
              switch (data?.updateAccessReviewGroupReviewers.__typename) {
                case "UpdateAccessReviewGroupReviewersResult": {
                  displaySuccessToast("Success: Reviewers Updated");
                  setShowReviewersModal(false);
                  break;
                }
                case "AccessReviewAlreadyStoppedError": {
                  setErrorMessage(
                    data?.updateAccessReviewGroupReviewers.message
                  );
                  break;
                }
              }
            } catch (error) {
              logError(error, "failed to update access reviewers");
              setErrorMessage(
                getModifiedErrorMessage(
                  "Error: failed to update access reviewers",
                  error
                )
              );
            }
          }}
          errorMessage={errorMessage}
          entryInfos={getReviewerModalEntries(
            props.accessReviewGroup.reviewerUsers,
            null
          )}
          canEditReviewers={canEditReviewers}
        />
      )}
    </>
  );
};
export default AccessReviewGroupsTable;
