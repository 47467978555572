import { EntityIdTupleNullable } from "api/common/common";
import { ResourceLabel } from "components/label/Label";
import React from "react";
import { Link } from "react-router-dom";

import * as stylesV3 from "./BreadcrumbsV3.css";

export type BreadcrumbInfo =
  | {
      name: string;
      entityId: EntityIdTupleNullable;
    }
  | {
      name: string;
      to?: string;
    };

type BreadcrumbsProps = {
  showIcons?: boolean;
  breadcrumbInfos: BreadcrumbInfo[];
  linkColor?: "blue" | "grey"; // Optional parameter for breadcrumb color
  linkLast?: boolean; // Optional parameter for making the last breadcrumb a link
};

const Breadcrumbs = ({
  showIcons = true,
  breadcrumbInfos,
  linkColor = "blue", // Default to blue if linkColor is not provided
  linkLast = false, // Default to false if linkLast is not provided
}: BreadcrumbsProps) => {
  const renderBreadcrumb = (breadcrumbInfo: BreadcrumbInfo, i: number) => {
    if ("entityId" in breadcrumbInfo) {
      return (
        <ResourceLabel
          key={`group-breadcrumb-${breadcrumbInfo.entityId.entityId}`}
          text={breadcrumbInfo.name}
          entityTypeNew={breadcrumbInfo.entityId.entityType}
          entityId={breadcrumbInfo.entityId.entityId}
          hideIcon={!showIcons}
        />
      );
    }
    // the last entry in breadcrumbs should not be a link unless linkLast is true
    if (breadcrumbInfo.to && (linkLast || i != breadcrumbInfos.length - 1)) {
      const linkStyle =
        linkColor === "grey" ? stylesV3.greyedOutLink : stylesV3.link;
      return (
        <Link className={linkStyle} to={breadcrumbInfo.to}>
          {breadcrumbInfo.name}
        </Link>
      );
    } else {
      return <div>{breadcrumbInfo.name}</div>;
    }
  };

  return (
    <div className={stylesV3.breadcrumbs}>
      {breadcrumbInfos.map((breadcrumbInfo, i) => {
        return (
          <React.Fragment key={i}>
            {i !== 0 ? <span>/</span> : null}
            <span className={stylesV3.breadcrumb}>
              {renderBreadcrumb(breadcrumbInfo, i)}
            </span>
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default Breadcrumbs;
