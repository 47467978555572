import {
  ErrorEntity,
  EventFilterFragment,
  Maybe,
  useDeleteEventFilterMutation,
} from "api/generated/graphql";
import { EntityViewerTabType } from "components/entity_viewer/EntityViewerTabs";
import ConfirmModal from "components/modals/update/ConfirmModal";
import { useToast } from "components/toast/Toast";
import React, { useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { logError, logWarning } from "utils/logging";

export type EventFilterDeleteModalProps = {
  eventFilter: EventFilterFragment;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

export const EventFilterDeleteModal = (props: EventFilterDeleteModalProps) => {
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [errorEntities, setErrorEntities] = useState<ErrorEntity[]>([]);

  const { displaySuccessToast } = useToast();
  const [deleteEventFilter, { loading }] = useDeleteEventFilterMutation();
  const location = useLocation();

  const deleteModalReset = () => {
    props.setShowModal(false);
    setErrorMessage(null);
    setErrorEntities([]);
  };

  return (
    <ConfirmModal
      key={"delete_modal"}
      title={`Delete "${props.eventFilter.name}"`}
      message={`Are you sure you want to delete "${props.eventFilter.name}"? This cannot be undone.`}
      isModalOpen={props.showModal}
      onClose={() => {
        deleteModalReset();
      }}
      onSubmit={async () => {
        try {
          const { data } = await deleteEventFilter({
            variables: {
              input: {
                id: props.eventFilter.id,
              },
            },

            update: (cache, { data }) => {
              switch (data?.deleteEventFilter.__typename) {
                case "DeleteEventFilterResult":
                  cache.evict({
                    id: cache.identify(
                      data?.deleteEventFilter.deletedEventFilter
                    ),
                  });
                  break;
              }
            },
          });
          switch (data?.deleteEventFilter.__typename) {
            case "DeleteEventFilterResult":
              deleteModalReset();
              displaySuccessToast("Success: filter deleted");
              history.replace("/events");
              history.replace({
                search: location.search,
                hash: EntityViewerTabType.SavedFilters,
              });
              break;
            case "EventFilterNotFoundError":
              logError(
                new Error(
                  `event filter with id: ${props.eventFilter.id} not found`
                )
              );
              setErrorMessage("failed to delete filter");
              break;
            case "EventFilterInUseError":
              logWarning(new Error(data.deleteEventFilter.message));
              setErrorMessage(data.deleteEventFilter.message);
              setErrorEntities(data.deleteEventFilter.entities);
              break;
            default:
              logError(new Error(`failed to delete event filter`));
          }
        } catch (error) {
          logError(error, `failed to delete event filter`);
          setErrorMessage(`ERROR: failed to delete saved filter`);
        }
      }}
      loading={loading}
      errorMessage={errorMessage}
      errorEntities={errorEntities}
    />
  );
};

export default EventFilterDeleteModal;
