import {
  AccessOption,
  AppItemFragment,
  AppItemsSortByField,
  ConnectionPreviewFragment,
  GroupType,
  ResourceType,
  SortDirection,
  SyncType,
  useBulkUpdateMutation,
  useConnectionPreviewQuery,
  useItemsListSectionImportQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import { ResourceConfig } from "components/forms/common";
import {
  makeDefaultRequestConfig,
  makeRequestConfigsInput,
  validateResourceConfig,
} from "components/forms/utils";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { ResourceLabel } from "components/label/Label";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import useSyncActionIcon from "components/sync/useSyncActionIcon";
import { ButtonV3, Icon, Input, Label, Select, Skeleton } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import { produce } from "immer";
import _ from "lodash";
import pluralize from "pluralize";
import { useCallback, useContext, useReducer, useState } from "react";
import { useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { isValidUUID } from "utils/auth/ids";
import { useDebouncedValue, useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";
import {
  useTransitionBack,
  useTransitionTo,
  useURLSearchParam,
} from "utils/router/hooks";
import useSyncStatusToast from "utils/sync/useSyncStatusToast";
import { ForbiddenPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import OrgContext from "views/settings/OrgContext";

import AppIcon from "./AppIcon";
import * as styles from "./AppImportItemsView.css";
import ResourceEditForm from "./ResourceEditForm";

type ImportItemsStep = "select" | "configure";

interface ItemForImport {
  id: string;
  remoteId: string;
  resourceType?: ResourceType;
  groupType?: GroupType;
  name: string;
}

function getItemTypeName(item: ItemForImport): string {
  if (item.resourceType) {
    return getResourceTypeInfo(item.resourceType)?.fullName ?? "";
  } else if (item.groupType) {
    return getGroupTypeInfo(item.groupType)?.name ?? "";
  }
  return "";
}

function getEntityType(
  items: ItemForImport[]
): ResourceType | GroupType | undefined {
  // We do the following update only to make sure that if we import all
  // groups, we show the group-specific settings, otherwise show only the
  // resource ones in the ResourceEditForm.
  return items.reduce((acc: ResourceType | GroupType | undefined, item) => {
    if (acc === undefined) {
      return item.resourceType || item.groupType;
    }
    if (item.groupType && Object.values(GroupType).includes(acc as GroupType)) {
      return acc;
    }

    return item.resourceType;
  }, undefined);
}

type AppImportItemsState = {
  step: ImportItemsStep;
  importAll: boolean;
  selectedItems: ItemForImport[];
  totalItems: number;
  searchQuery: string;
  itemTypeFilter?: ResourceType | GroupType;
  config: Partial<ResourceConfig>;
};

enum AppImportItemsActionType {
  AddSelectedItems,
  RemoveSelectedItems,
  ResetSelectedItems,
  SetSearchQuery,
  SetItemTypeFilter,
  SetSelectItemsState,
  SetConfigureItemsState,
  ConfigureAndImportAll,
  UpdateConfig,
  Reset,
}

type AppImportItemsAction =
  | {
      type: AppImportItemsActionType.AddSelectedItems;
      payload: ItemForImport[];
    }
  | {
      type: AppImportItemsActionType.RemoveSelectedItems;
      payload: string[];
    }
  | {
      type: AppImportItemsActionType.ResetSelectedItems;
    }
  | {
      type: AppImportItemsActionType.SetSearchQuery;
      payload: string;
    }
  | {
      type: AppImportItemsActionType.SetItemTypeFilter;
      payload: ResourceType | GroupType | undefined;
    }
  | {
      type:
        | AppImportItemsActionType.SetSelectItemsState
        | AppImportItemsActionType.SetConfigureItemsState
        | AppImportItemsActionType.Reset;
    }
  | {
      type: AppImportItemsActionType.ConfigureAndImportAll;
      payload: number;
    }
  | {
      type: AppImportItemsActionType.UpdateConfig;
      payload: Partial<ResourceConfig>;
    };

export const importReducer = (
  state: AppImportItemsState,
  action: AppImportItemsAction
): AppImportItemsState => {
  switch (action.type) {
    case AppImportItemsActionType.AddSelectedItems:
      return produce(state, (draft) => {
        // make sure to always have this set to false except when we are importing all
        draft.importAll = false;
        draft.selectedItems = _.uniqBy(
          [...state.selectedItems, ...action.payload],
          "id"
        );
        draft.totalItems = draft.selectedItems.length;
        // We do the following update only to make sure that if we import all
        // groups, we show the group-specific settings, otherwise show only the
        // resource ones in the ResourceEditForm.
        draft.config.entityType = getEntityType(draft.selectedItems);
      });
    case AppImportItemsActionType.ResetSelectedItems:
      return produce(state, (draft) => {
        // make sure to always have this set to false except when we are importing all
        draft.importAll = false;
        draft.selectedItems = [];
        draft.totalItems = draft.selectedItems.length;
        draft.config.entityType = undefined;
      });
    case AppImportItemsActionType.RemoveSelectedItems:
      return produce(state, (draft) => {
        // make sure to always have this set to false except when we are importing all
        draft.importAll = false;
        draft.selectedItems = state.selectedItems.filter(
          (item) => !action.payload.includes(item.id)
        );
        draft.totalItems = draft.selectedItems.length;
        draft.config.entityType = getEntityType(draft.selectedItems);
      });
    case AppImportItemsActionType.SetSearchQuery:
      return produce(state, (draft) => {
        draft.searchQuery = action.payload;
      });
    case AppImportItemsActionType.SetItemTypeFilter:
      return produce(state, (draft) => {
        draft.itemTypeFilter = action.payload;
      });
    case AppImportItemsActionType.SetSelectItemsState:
      return produce(state, (draft) => {
        // make sure to always have this set to false except when we are importing all
        draft.importAll = false;
        draft.step = "select";
        draft.totalItems = draft.selectedItems.length;
      });
    case AppImportItemsActionType.SetConfigureItemsState:
      return produce(state, (draft) => {
        draft.step = "configure";
      });
    case AppImportItemsActionType.ConfigureAndImportAll:
      return produce(state, (draft) => {
        draft.importAll = true;
        draft.totalItems = action.payload;
        draft.step = "configure";
      });
    case AppImportItemsActionType.UpdateConfig:
      return produce(state, (draft) => {
        draft.config = { ...draft.config, ...action.payload };
      });
    case AppImportItemsActionType.Reset:
      return {
        step: "select",
        importAll: false,
        selectedItems: [],
        totalItems: 0,
        searchQuery: "",
        config: {
          description: "",
          messageChannels: [],
          onCallSchedules: [],
          breakGlassUsers: [],
          requireMfaToApprove: false,
          requireMfaToConnect: false,
          tagIds: [],
          requestConfigs: [makeDefaultRequestConfig()],
          childrenDefaultConfigTemplate: undefined,
          groupLeaderUsers: [],
        },
      };
    default:
      return state;
  }
};

const AppImportItemsView = () => {
  const transitionBack = useTransitionBack();
  const logEvent = useLogEvent();
  const { connectionId } = useParams<Record<string, string>>();
  const { authState } = useContext(AuthContext);
  const { orgState } = useContext(OrgContext);
  const showSyncStatusToast = useSyncStatusToast({
    loadingText: "Successfully imported, now syncing...",
    queriesToRefetch: ["ConnectionPreview", "AppResources"],
  });

  const [importState, importStateDispatch] = useReducer(importReducer, {
    step: "select",
    importAll: false,
    searchQuery: "",
    selectedItems: [],
    totalItems: 0,
    config: {
      description: "",
      messageChannels: [],
      onCallSchedules: [],
      breakGlassUsers: [],
      requireMfaToApprove: false,
      requireMfaToConnect: false,
      tagIds: [],
      requestConfigs: [makeDefaultRequestConfig()],
      childrenDefaultConfigTemplate: undefined,
      groupLeaderUsers: [],
    },
  });

  const { data, loading, error } = useConnectionPreviewQuery({
    variables: {
      connectionId,
    },
    onCompleted(data) {
      if (data.connection.__typename === "ConnectionResult") {
        const connection = data.connection.connection;
        importStateDispatch({
          type: AppImportItemsActionType.UpdateConfig,
          payload: {
            visibilityGroups: connection.importVisibilityGroups?.map(
              (x) => x.visibilityGroupId
            ),
            visibility: connection.importVisibility,
          },
        });
      }
    },
  });

  let connection: ConnectionPreviewFragment | undefined;
  if (data?.connection.__typename === "ConnectionResult") {
    connection = data.connection.connection;
  }
  const { config } = importState;
  const [errors, setErrors] = useState<string[]>([]);

  const [bulkUpdate, { loading: importLoading }] = useBulkUpdateMutation({
    refetchQueries: ["ConnectionPreview", "AppResources"],
  });

  const refreshItems = useSyncActionIcon({
    syncType: SyncType.PullConnectionsSingleConnectionFast,
    connection: connection ?? undefined,
    label: "Refresh items",
    queriesToRefetch: ["ItemsListSectionImport"],
    loadingEntity: loading || !connection,
  });

  if (loading) {
    return <FullscreenSkeleton />;
  }
  if (!authState.user?.isAdmin) {
    return <ForbiddenPage />;
  }
  if (error || !connection) {
    return <UnexpectedErrorPage error={error} />;
  }

  const handleClose = () => {
    importStateDispatch({ type: AppImportItemsActionType.Reset });
    transitionBack(`/apps/${connectionId}`);
  };

  const handleNext = () => {
    if (importState.step === "select") {
      importStateDispatch({
        type: AppImportItemsActionType.SetConfigureItemsState,
      });
    } else {
      handleSave();
    }
  };

  const handleSave = async () => {
    const errors = validateResourceConfig(config, orgState);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    const resourceIds: string[] = [];
    const groupIds: string[] = [];

    const itemTypes = new Set<string>();
    importState.selectedItems.forEach((item) => {
      if (item.resourceType) {
        resourceIds.push(item.id);
        itemTypes.add(`${item.resourceType}`);
      } else {
        groupIds.push(item.id);
        itemTypes.add(`${item.groupType}`);
      }
    });

    logEvent({
      name: "apps_import_items_click",
      properties: {
        numItems: importState.totalItems,
        itemTypes: Array.from(itemTypes),
      },
    });

    try {
      let defaultInput = {
        resourceIds,
        groupIds,
        importUnmanagedItemsFilter: importState.importAll
          ? {
              connectionId: connectionId,
              itemTypes: importState.itemTypeFilter
                ? [importState.itemTypeFilter]
                : null,
              searchQuery: importState.searchQuery,
            }
          : null,

        importUnmanagedItems: true,
        description: config.description,
        configurationId: config.configurationTemplate
          ? {
              configurationId: config.configurationTemplate?.id,
            }
          : undefined,

        tagIds: config.tagIds,
        childrenDefaultConfigTemplateId: config.childrenDefaultConfigTemplate
          ?.id
          ? {
              configurationId: config.childrenDefaultConfigTemplate.id,
            }
          : undefined,
      };
      let input;
      // If a configuration template is selected, we do not want to include attributes
      // managed by the configuration template in this query. Otherwise, we will
      // attempt to modify the config template, which is not allowed.
      if (!defaultInput.configurationId) {
        input = {
          ...defaultInput,
          adminOwnerId: config.adminOwner?.id ?? undefined,
          messageChannelIds: config.messageChannels?.map(
            (channel) => channel.id
          ),

          visibility: config.visibility,
          visibilityGroupsIds: config.visibilityGroups,

          requireMfaToApprove: config.requireMfaToApprove,
          requireMfaToConnect: config.requireMfaToConnect,

          breakGlassUsersIds: config.breakGlassUsers?.map((user) => user.id),
          onCallSchedules: config.onCallSchedules?.map((schedule) => ({
            scheduleName: schedule.name,
            remoteId: schedule.remoteId,
            thirdPartyProvider: schedule.thirdPartyProvider,
          })),

          groupLeaderUserIds: config.groupLeaderUsers?.map((user) => user.id),
          requestConfigs: makeRequestConfigsInput(config.requestConfigs ?? []),
          ticketPropagation: config.ticketPropagation,
        };
      } else {
        input = defaultInput;
      }
      const { data } = await bulkUpdate({
        variables: {
          input,
        },
      });
      switch (data?.bulkUpdateItems.__typename) {
        case "BulkUpdateItemsResult":
          if (data.bulkUpdateItems.syncTask) {
            showSyncStatusToast(data.bulkUpdateItems.syncTask.id);
          }
          handleClose();

          break;
        case "TagNotFoundError":
          setErrors(["Failed to get tag data"]);
          break;
        case "InvalidUpdateResourceVisibilityGroupError":
        case "InvalidUpdateGroupVisibilityGroupError":
          setErrors(["Invalid visibility group"]);
          break;
        case "InvalidReviewerSettingsError":
          setErrors(["Invalid reviewer stage configuration"]);
          break;
        case "GroupMaxDurationTooLargeError":
        case "ResourceMaxDurationTooLargeError":
          setErrors([
            "Max access duration cannot exceed org-wide max duration setting",
          ]);
          break;
        case "CannotUpdateConfigurationTemplateError":
          setErrors([
            "Cannot update configuration template. Please select a different configuration template.",
          ]);
          break;
        case "TooManyGroupLeadersError":
          setErrors(["A group cannot have more than 10 leaders"]);
          break;
        default:
          setErrors(["Failed to bulk import items"]);
      }
    } catch (err) {
      logError(err, "Failed to bulk import items");
      setErrors(["Failed to bulk import items"]);
    }
  };

  const title = (
    <div
      className={sprinkles({
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        marginRight: "md",
      })}
    >
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "center",
          gap: "sm",
        })}
      >
        Import:
        <div className={sprinkles({ display: "flex" })}>
          <AppIcon
            app={{
              __typename: "ConnectionApp",
              connectionType: connection.connectionType,
              connectionIconUrl: connection.iconUrl,
            }}
          />
        </div>
        {connection.name}
      </div>
      {refreshItems && (
        <ButtonV3
          type="mainBorderless"
          label={refreshItems.label}
          leftIconName={refreshItems.iconName}
          disabled={refreshItems.disabled}
          onClick={refreshItems.onClick}
        />
      )}
    </div>
  );

  const { selectedItems, totalItems, step } = importState;

  return (
    <FullscreenView
      title={title}
      onCancel={handleClose}
      onPrimaryButtonClick={handleNext}
      primaryButtonLabel={`${step === "select" ? "Configure" : "Import"} ${
        totalItems ? totalItems : ""
      } ${pluralize("resource", totalItems)}`}
      primaryButtonDisabled={totalItems === 0}
      primaryButtonLoading={importLoading}
      onBack={
        step === "configure"
          ? () =>
              importStateDispatch({
                type: AppImportItemsActionType.SetSelectItemsState,
              })
          : undefined
      }
    >
      {step === "select" ? (
        <>
          <SelectItemsTable
            importState={importState}
            importStateDispatch={importStateDispatch}
          />
          <FullscreenView.Sidebar>
            <div
              className={sprinkles({
                display: "flex",
                justifyContent: "space-between",
                marginBottom: "lg",
              })}
            >
              <div
                className={sprinkles({
                  fontSize: "textLg",
                  fontWeight: "medium",
                })}
              >
                Importing {totalItems} {pluralize("resource", totalItems)}
              </div>
              {totalItems > 0 && (
                <ButtonV3
                  leftIconName="x"
                  label="Clear all"
                  size="xs"
                  type="dangerBorderless"
                  onClick={() =>
                    importStateDispatch({
                      type: AppImportItemsActionType.ResetSelectedItems,
                    })
                  }
                />
              )}
            </div>
            {selectedItems.map((item) => {
              return (
                <div key={item.id} className={styles.resourceCard}>
                  <div
                    className={sprinkles({
                      display: "flex",
                      alignItems: "flex-start",
                      gap: "sm",
                    })}
                  >
                    <div className={styles.resourceInfoSection}>
                      <div className={styles.resourceCardHeader}>
                        {item.name}
                      </div>
                      <div className={styles.resourceCardSubtitle}>
                        {item.remoteId ?? "--"}
                      </div>
                      <div className={styles.resourceCardType}>
                        <Label
                          label={getItemTypeName(item)}
                          icon={{
                            type: "name",
                            icon: item.resourceType ? "cube" : "users",
                          }}
                        />
                      </div>
                    </div>
                    <div className={sprinkles({ flexShrink: 0 })}>
                      <Icon
                        name="trash"
                        color="red600V3"
                        onClick={() => {
                          importStateDispatch({
                            type: AppImportItemsActionType.RemoveSelectedItems,
                            payload: [item.id],
                          });
                        }}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </FullscreenView.Sidebar>
        </>
      ) : (
        <ResourceEditForm
          config={config}
          onChange={(config) =>
            importStateDispatch({
              type: AppImportItemsActionType.UpdateConfig,
              payload: config,
            })
          }
          errors={errors}
          isImport
        />
      )}
    </FullscreenView>
  );
};

const APP_ITEM_NAME_COL_ID = AppItemsSortByField.Name;
const APP_ITEM_REMOTE_ID_COL_ID = AppItemsSortByField.RemoteId;

interface AppItemRow {
  id: string;
  [APP_ITEM_NAME_COL_ID]: string;
  [APP_ITEM_REMOTE_ID_COL_ID]: string;
  entityType?: GroupType | ResourceType;
  item?: AppItemFragment;
}

type SortValue = {
  field: AppItemsSortByField;
  direction: SortDirection;
};

function isSortableField(str: string): str is AppItemsSortByField {
  return Object.values<string>(AppItemsSortByField).includes(str);
}

function appItemToItemForImport(item: AppItemFragment): ItemForImport {
  return {
    id: item.key,
    remoteId: item.resource?.remoteId ?? item.group?.remoteId ?? "--",
    resourceType: item.resource?.resourceType,
    groupType: item.group?.groupType,
    name: item.resource?.name ?? item.group?.name ?? "--",
  };
}

interface SelectItemsProps {
  importState: AppImportItemsState;
  importStateDispatch: React.Dispatch<AppImportItemsAction>;
}

const SelectItemsTable = (props: SelectItemsProps) => {
  const { connectionId } = useParams<Record<string, string>>();
  const [sortBy, setSortBy] = useState<SortValue | undefined>({
    field: AppItemsSortByField.Name,
    direction: SortDirection.Asc,
  });

  const [showImportAllMessage, setShowImportAllMessage] = useState<boolean>(
    false
  );
  const debouncedSearchQuery = useDebouncedValue(props.importState.searchQuery);

  const [groupIds, setGroupIds] = useURLSearchParam("groupIds", "");
  const [resourceIds, setResourceIds] = useURLSearchParam("resourceIds", "");

  const { data, loading, error, fetchMore } = useItemsListSectionImportQuery({
    variables: {
      id: connectionId,
      itemType: props.importState.itemTypeFilter,
      access: AccessOption.Unmanaged,
      searchQuery: debouncedSearchQuery,
      sortBy,
    },
  });

  useMountEffect(() => {
    var foundGroupIds: string[] = [];
    var foundResourceIds: string[] = [];
    if (groupIds && groupIds.length > 0) {
      try {
        foundGroupIds = JSON.parse(groupIds).filter((id: string) => {
          if (id.length > 0 && isValidUUID(id)) {
            return true;
          }
          return false;
        });
      } catch (e) {
        logError(e, "Failed to parse groupIds from URL");
      }
    }
    if (resourceIds && resourceIds.length > 0) {
      try {
        foundResourceIds = JSON.parse(resourceIds).reduce(
          (acc: string[], id: string) => {
            if (id.length > 0 && isValidUUID(id)) {
              acc.push(id);
            }
            return acc;
          },
          [] as string[]
        );
      } catch (e) {
        logError(e, "Failed to parse resourceIds from URL");
      }
    }

    const fetchGroupsAndResources = async (
      groupIds: string[],
      resourceIds: string[]
    ) => {
      const { data } = await fetchMore({
        variables: {
          id: connectionId,
          access: AccessOption.Unmanaged,
          resourceIds: foundResourceIds,
          groupIds: foundGroupIds,
          limit: groupIds.length + resourceIds.length,
        },
      });
      if (data.app.__typename === "App" && data.app.items.items) {
        const appsToAdd = data.app.items.items.reduce((acc, item) => {
          // Since the endpoint might return more items that what we actually
          // requested, we want to filter anything that the user didn't
          // explictly select.
          if (
            groupIds.includes(item.group?.id ?? "") ||
            resourceIds.includes(item.resource?.id ?? "")
          ) {
            acc.push(appItemToItemForImport(item));
          }

          return acc;
        }, [] as ItemForImport[]);

        if (appsToAdd.length > 0) {
          props.importStateDispatch({
            type: AppImportItemsActionType.AddSelectedItems,
            payload: appsToAdd,
          });
        }
      }
    };

    if (foundGroupIds.length > 0 || foundResourceIds.length > 0) {
      fetchGroupsAndResources(foundGroupIds, foundResourceIds);
    }

    setResourceIds(null);
    setGroupIds(null);
  });

  if (error) {
    return <UnexpectedErrorPage error={error} />;
  }

  const APP_ITEMS_COLUMNS: Header<AppItemRow>[] = [
    {
      id: APP_ITEM_NAME_COL_ID,
      label: "Name",
      sortable: true,
      customCellRenderer: (row: AppItemRow) => {
        var entityType: GroupType | ResourceType = ResourceType.Custom;
        if (row.item?.resource) {
          entityType = row.item.resource.resourceType;
        }
        if (row.item?.group) {
          entityType = row.item.group.groupType;
        }

        return (
          <>
            <Icon data={{ type: "entity", entityType }} size="sm" />
            <ResourceLabel
              text={row[APP_ITEM_NAME_COL_ID]}
              bold={true}
              pointerCursor={true}
              maxChars="auto"
            />
          </>
        );
      },
      width: 230,
    },
    {
      id: APP_ITEM_REMOTE_ID_COL_ID,
      label: "Remote ID",
      customCellRenderer: (row) => {
        if (row.item?.resource) {
          return row.item.resource.remoteId;
        }
        if (row.item?.group) {
          return row.item.group.remoteId ?? "";
        }
        return "--";
      },
      width: 130,
    },
    {
      id: "entityType",
      label: "Type",
      sortable: false,
      customCellRenderer: (row) => {
        const formattedType =
          (row.item?.group
            ? getGroupTypeInfo(row.entityType as GroupType)?.name
            : getResourceTypeInfo(row.entityType as ResourceType)?.fullName) ||
          "";

        return (
          <Label
            label={formattedType}
            icon={
              row.entityType
                ? { type: "entity", entityType: row.entityType }
                : undefined
            }
          />
        );
      },
      width: 100,
    },
  ];

  const items =
    data?.app.__typename === "App" ? data.app.items.items ?? [] : [];
  const cursor =
    data?.app.__typename === "App" ? data.app.items.cursor : undefined;
  const itemTypes = data?.app.__typename === "App" ? data.app.itemTypes : [];
  const totalNumItems =
    data?.app.__typename === "App" ? data.app.items.totalNumItems : 0;

  const loadMoreRows = async () => {
    if (!cursor) return;
    await fetchMore({
      variables: {
        cursor,
        id: connectionId,
        itemType: props.importState.itemTypeFilter,
        access: AccessOption.Unmanaged,
        searchQuery: debouncedSearchQuery,
        sortBy,
      },
    });
  };

  const rows: AppItemRow[] = items.map((item) => {
    return {
      id: item.key,
      [APP_ITEM_NAME_COL_ID]: item.resource?.name ?? item.group?.name ?? "--",
      [APP_ITEM_REMOTE_ID_COL_ID]:
        item.resource?.remoteId ?? item.group?.remoteId ?? "--",
      entityType: item.resource?.resourceType ?? item.group?.groupType,
      item,
    };
  });
  const itemById = _.keyBy(items, "key");

  const selectedType = itemTypes.find(
    (t) => t.itemType === props.importState.itemTypeFilter
  );

  const selectedItemIds = new Set(
    props.importState.selectedItems.map(({ id }) => id)
  );

  const allItemsHaveSelectedIds = items.every(
    (item) =>
      (item.resource?.id && selectedItemIds.has(item.resource.id)) ||
      (item.group?.id && selectedItemIds.has(item.group.id))
  );

  return (
    <FullscreenView.Content fullWidth>
      <div className={styles.listContainer}>
        <div
          className={sprinkles({
            display: "flex",
            gap: "md",
            marginBottom: "md",
            alignItems: "center",
          })}
        >
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              placeholder="Filter by name"
              value={props.importState.searchQuery}
              onChange={(value) => {
                setShowImportAllMessage(false);
                props.importStateDispatch({
                  type: AppImportItemsActionType.SetSearchQuery,
                  payload: value,
                });
              }}
            />
          </div>
          <div className={styles.resourceTypeFilter}>
            <Select
              options={itemTypes}
              value={selectedType}
              getOptionLabel={(itemType) => itemType.displayText}
              getIcon={(itemType) => ({
                type: "entity",
                entityType: itemType.itemType as ResourceType | GroupType,
              })}
              onChange={(itemType) => {
                setShowImportAllMessage(false);
                props.importStateDispatch({
                  type: AppImportItemsActionType.SetItemTypeFilter,
                  payload: itemType?.itemType as
                    | ResourceType
                    | GroupType
                    | undefined,
                });
              }}
              placeholder="Filter by type"
              clearable
            />
          </div>
        </div>
        <div
          className={sprinkles({
            display: "flex",
            gap: "lg",
            alignItems: "center",
            marginBottom: "md",
            justifyContent: "space-between",
          })}
        >
          <div>
            {loading ? (
              <Skeleton width="200px" />
            ) : (
              <div
                className={sprinkles({
                  display: "flex",
                  gap: "md",
                  alignItems: "center",
                })}
              >
                <div
                  className={sprinkles({
                    fontSize: "textLg",
                    fontWeight: "medium",
                  })}
                >
                  {totalNumItems + " "} {pluralize("Resource", totalNumItems)}
                </div>
              </div>
            )}
            <div className={sprinkles({ display: "flex" })}>
              {!showImportAllMessage || props.importState.totalItems === 0 ? (
                "Select resources to import"
              ) : (
                <div
                  className={sprinkles({
                    display: "flex",
                    gap: "sm",
                    alignItems: "center",
                  })}
                >
                  <Icon name="info" color="blue600V3" size="xs" /> You have
                  selected {props.importState.totalItems} resources on this
                  page. To import all click the button on the right.
                </div>
              )}
            </div>
          </div>
          {totalNumItems > 0 && (
            <ButtonV3
              label={`Import and configure all ${totalNumItems} resources`}
              type="mainSecondary"
              size="md"
              onClick={() => {
                props.importStateDispatch({
                  type: AppImportItemsActionType.ConfigureAndImportAll,
                  payload: totalNumItems,
                });
              }}
            />
          )}
        </div>
        {loading ? (
          <ColumnListItemsSkeleton />
        ) : (
          <Table
            rows={rows}
            totalNumRows={totalNumItems ?? 0}
            getRowId={(row) => row.id}
            columns={APP_ITEMS_COLUMNS}
            checkedRowIds={
              new Set(props.importState.selectedItems.map((item) => item.id))
            }
            onLoadMoreRows={loadMoreRows}
            manualSortDirection={
              sortBy && {
                sortBy: sortBy.field,
                sortDirection: sortBy.direction,
              }
            }
            handleManualSort={(sortBy, sortDirection) => {
              if (!sortDirection) {
                setSortBy(undefined);
                return;
              }
              if (!isSortableField(sortBy)) {
                return;
              }
              const direction: SortDirection =
                sortDirection === "DESC"
                  ? SortDirection.Desc
                  : SortDirection.Asc;

              setSortBy({
                field: sortBy,
                direction,
              });
            }}
            onCheckedRowsChange={(checkedRowIds, checked) => {
              if (checked) {
                props.importStateDispatch({
                  type: AppImportItemsActionType.AddSelectedItems,
                  payload: checkedRowIds.map((id) =>
                    appItemToItemForImport(itemById[id])
                  ),
                });
              } else {
                props.importStateDispatch({
                  type: AppImportItemsActionType.RemoveSelectedItems,
                  payload: checkedRowIds,
                });
              }
            }}
            onRowClick={(row) => {
              const item = appItemToItemForImport(itemById[row.id]);
              if (
                props.importState.selectedItems.some((i) => i.id === row.id)
              ) {
                props.importStateDispatch({
                  type: AppImportItemsActionType.RemoveSelectedItems,
                  payload: [row.id],
                });
              } else {
                props.importStateDispatch({
                  type: AppImportItemsActionType.AddSelectedItems,
                  payload: [item],
                });
              }
            }}
            selectAllChecked={allItemsHaveSelectedIds}
            onSelectAll={(checked) => {
              setShowImportAllMessage(checked);
              if (checked) {
                props.importStateDispatch({
                  type: AppImportItemsActionType.AddSelectedItems,
                  payload: items.map((item) => appItemToItemForImport(item)),
                });
              } else {
                props.importStateDispatch({
                  type: AppImportItemsActionType.RemoveSelectedItems,
                  payload: items.map((item) => item.key),
                });
              }
            }}
          />
        )}
      </div>
    </FullscreenView.Content>
  );
};

export const useTransitionToImportItems = () => {
  const transitionTo = useTransitionTo();

  const transitionToImportItems = useCallback(
    (
      params: {
        connectionId: string;
        groupIds?: string[];
        resourceIds?: string[];
      },
      event?: React.MouseEvent<HTMLElement, MouseEvent>
    ) => {
      const urlParams = new URLSearchParams();
      if (params.groupIds && params.groupIds.length > 0) {
        urlParams.set("groupIds", JSON.stringify(params.groupIds));
      }
      if (params.resourceIds && params.resourceIds.length > 0) {
        urlParams.set("resourceIds", JSON.stringify(params.resourceIds));
      }

      transitionTo(
        {
          pathname: `/apps/${params.connectionId}/import`,
          search: urlParams.toString(),
        },
        event
      );
    },

    [transitionTo]
  );

  return [transitionToImportItems];
};

export default AppImportItemsView;
