import { Popper, PopperProps } from "@material-ui/core";
// Only this component should import Autocomplete from "@material-ui/lab"
// eslint-disable-next-line no-restricted-imports
import {
  Autocomplete,
  AutocompleteChangeDetails,
  AutocompleteChangeReason,
  RenderGroupParams,
  RenderInputParams,
} from "@material-ui/lab";
import { Button, Checkbox, EntityIcon, Icon, Loader } from "components/ui";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import { colorVars } from "css/vars.css";
import { max, min } from "lodash";
import { matchSorter } from "match-sorter";
import React, { AriaRole } from "react";
import useLogEvent from "utils/analytics";
import { Event } from "utils/analytics/constants";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import * as styles from "./Select.css";

const DEFAULT_DROPDOWN_HEIGHT = "sm";

type SelectStyle = "search" | "borderless" | "bottomBorder";

interface ListboxOptions {
  title?: string;
  onGoBack?: () => void;
}

interface ListboxFooterOptions {
  footer?: React.ReactNode;
  sticky?: boolean;
}

interface ListboxContextProps extends ListboxOptions, ListboxFooterOptions {
  style?: SelectStyle;
}

interface SelectProps<Option> {
  options: Option[];
  placeholder?: string;
  placeholderIcon?: IconData;
  placeHolderIconColor?: colorVars;
  alwaysShowPlaceholder?: boolean;
  alwaysShowPlaceholderIcon?: boolean;
  /** Used to determine the string value for a given option in the input and options list. */
  getOptionLabel: (option: Option) => string;
  getOptionSublabel?: (option: Option) => string;
  renderOptionLabel?: (option: Option) => JSX.Element;
  /** Used to determine the icon for a given option in the options list. */
  getIcon?: (option: Option) => IconData | undefined;
  getBrandIcon?: (option: Option) => PropsFor<typeof Icon>["brandIcon"];
  /** Used to determine if an option is selected, considering the current value.
   *  The default behavior is to use reference equality. */
  getOptionSelected?: (option: Option, value: Option) => boolean;
  getOptionDisabled?: (option: Option) => boolean;
  getOptionCheckboxDisabled?: (option: Option) => boolean;
  /** Used to handle changes from user typing. */
  onInputChange?: (inputValue: string) => void;
  /* When provided, each option will have a right-chevron which can be clicked to "drill down". `optionHasDrilldown` must return true to render this */
  onDrilldown?: (option: Option) => void;
  optionHasDrilldown?: (option: Option) => boolean;
  /** Used for FormGroup labels. */
  id?: string;
  loading?: boolean;
  disabled?: boolean;
  clearable?: boolean;
  searchable?: boolean;
  size?: "xs" | "sm" | "md" | "lg";
  /** If set to true, this select will never have a value. */
  selectOnly?: boolean;
  /** Used for dropdowns where search is handled server-side. This disables
   * additional client-side filtering. */
  disableBuiltInFiltering?: boolean;
  /** Autofocus the input component on mount. */
  autoFocus?: boolean;
  /** Title header with a back button that appears at the top of the drop-down */
  listboxHeader?: ListboxOptions;
  /** Fixed to the bottom of the open dropdown */
  listboxFooter?: ListboxFooterOptions;
  /** Closeable helper text that appears at the top of the drop-down. Closed state is remembered in localStorage. */
  oneTimeHelperText?: OneTimeHelperTextProps;
  /** Group options with little headers. Returned string is the text in each header.  */
  groupBy?: (option: Option) => string;
  /** When this function returns true, the bottom of the group will have a button to load more items */
  groupHasLoadMore?: (groupName: string) => boolean;
  /** Called when the button is pressed to load more items for the group */
  onGroupLoadMore?: (groupName: string) => void;
  /** The style the input should display as */
  style?: SelectStyle;
  /** Can be used to implement internal pagination */
  onScrollToBottom?: () => void;
  /** If set, drop-down popper will always appear below the select. Note this also currently disables height limit */
  popperForceDownward?: boolean;
  /** Size of drop-down popper */
  popperHeight?: "md" | "sm" | "xs";
  noOptionsText?: React.ReactNode;
  highlightWhenSelected?: boolean;
  focusEvent?: Event;
}

interface SingleSelectProps<Option> extends SelectProps<Option> {
  multiple?: false;
  value?: Option;
  onChange: (value?: Option, reason?: AutocompleteChangeReason) => void;
}

interface MultipleSelectProps<Option> extends SelectProps<Option> {
  multiple: true;
  value?: Option[];
  onChange?: (value?: Option[]) => void;
  onSelectValue?: (
    value: Option | undefined,
    reason: AutocompleteChangeReason
  ) => void;
  onBulkSelectValue?: (
    values: Option[],
    reason: AutocompleteChangeReason
  ) => void;
  checkboxSize?: PropsFor<typeof Checkbox>["size"];
  renderInputValue?: (value?: Option[]) => string;
}

type CombinedSelectProps<Option> =
  | SingleSelectProps<Option>
  | MultipleSelectProps<Option>;

const listboxContext = React.createContext<ListboxContextProps | null>(null);

const oneTimeHelperTextContext = React.createContext<OneTimeHelperTextProps | null>(
  null
);

const onScrollToBottomContext = React.createContext<(() => void) | null>(null);

const popperHeightContext = React.createContext<"sm" | "md" | "xs">(
  DEFAULT_DROPDOWN_HEIGHT
);

const Select = <Option extends {}>(props: CombinedSelectProps<Option>) => {
  const { searchable = true } = props;
  const [focus, setFocus] = React.useState(false);
  const [inputValue, setInputValue] = React.useState<string>("");
  const [lastClicked, setLastClicked] = React.useState<number>();
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const logEvent = useLogEvent();

  // Ensure scroll doesn't jump to top when new options are loaded from the server
  // Pretty hacky, this is unsolved in the default listbox component https://github.com/mui/material-ui/issues/29508
  const optionsTracker = React.useRef(props.options);

  // These pieces of code should happen in sequence:
  // 1. When options have changed, apply scroll hack
  if (optionsTracker.current !== props.options) {
    applyScrollHack();
  }

  // 2. After Select has re-rendered, remove the scroll hack
  React.useLayoutEffect(() => {
    removeScrollHack();
  });

  // 3. "Remember" old `options`
  React.useEffect(() => {
    optionsTracker.current = props.options;
  }, [props.options]);

  const renderIcon = (option: Option, location: "option" | "value") => {
    if (!props.getIcon) return null;
    const iconData = props.getIcon(option);
    if (!iconData) return null;
    const brandIcon = props.getBrandIcon
      ? props.getBrandIcon(option)
      : undefined;

    if (iconData.type === "name") {
      return (
        <div className={styles.icon({ iconStyle: iconData.style, location })}>
          <Icon
            name={iconData.icon}
            size="xs"
            color={"gray800"}
            brandIcon={brandIcon}
          />
        </div>
      );
    }

    if (iconData.type === "entity") {
      return (
        <div className={styles.icon({ location })}>
          <EntityIcon type={iconData.entityType} size="sm" />
        </div>
      );
    }

    if (!iconData.icon) return null;
    return (
      <div className={styles.icon({ iconStyle: iconData.style, location })}>
        <Icon
          data={iconData}
          size="xs"
          brandIcon={brandIcon}
          // if we don't pass a key, the icon will not re-render when the icon changes
          key={iconData.icon}
        />
      </div>
    );
  };

  const renderOptionLabel = (option: Option, hideSublabel?: boolean) => {
    if (props.renderOptionLabel) {
      return (
        <div className={styles.optionText({ v3: hasV3 })}>
          {props.renderOptionLabel(option)}
        </div>
      );
    }
    if (props.getOptionSublabel && !hideSublabel) {
      return (
        <div>
          {props.getOptionLabel(option)}
          <div className={styles.optionSublabel({ v3: hasV3 })}>
            {props.getOptionSublabel(option)}
          </div>
        </div>
      );
    }
    return (
      <div className={styles.optionText({ v3: hasV3 })}>
        {props.getOptionLabel(option)}
      </div>
    );
  };

  const renderInput = (params: RenderInputParams) => {
    const showClearIcon = props.clearable && props.value != null;
    const rightIcons = (
      <div className={styles.inputRightIcons({ style: props.style })}>
        {showClearIcon && (
          <div className={styles.inputClearIcon({ size: props.size })}>
            <Icon
              name="x"
              onClick={() => {
                if (props.multiple && inputValue) {
                  // In these cases, we just want to clear the input, not remove selected values.
                  // For internal search, removing selected values has a separate clear button
                  // For multiple selection, first clear any search term before deleting selected values.
                  handleChangeInputValue("", "clear");
                } else {
                  props.onChange && props.onChange(undefined);
                  setInputValue("");
                }
              }}
              size="xs"
            />
          </div>
        )}
        {props.loading ? (
          <Loader color="inherit" />
        ) : (
          props.style !== "search" && <Icon name="chevron-down" size="xs" />
        )}
      </div>
    );

    let input = (
      <div className={styles.inputIconContainer}>
        <input
          autoFocus={props.autoFocus}
          type="text"
          {...params.inputProps}
          className={styles.input({
            searchable,
            clearable: props.clearable,
            selected: props.value != null,
          })}
          placeholder={props.placeholder ?? "Select..."}
        />
        {rightIcons}
      </div>
    );

    if (!searchable) {
      input = (
        <>
          <div
            tabIndex={0}
            {...params.inputProps}
            className={styles.input({ searchable })}
          >
            {props.value != null && props.multiple !== true ? (
              renderOptionLabel(props.value, props.size !== "lg")
            ) : (
              <span className={sprinkles({ color: "gray600" })}>
                {props.placeholder || "Select..."}
              </span>
            )}
          </div>
          {rightIcons}
        </>
      );
    }

    if (props.style === "borderless") {
      input =
        props.value != null && props.multiple !== true ? (
          <div
            tabIndex={0}
            {...params.inputProps}
            className={styles.input({ style: props.style, searchable })}
          >
            <div>
              <div className={styles.borderlessOptionLabel({ v3: hasV3 })}>
                <span
                  className={sprinkles({
                    fontSize: "labelLg",
                    fontWeight: "medium",
                  })}
                >
                  {props.getOptionLabel(props.value)}
                </span>
                {rightIcons}
              </div>
              {props.getOptionSublabel ? (
                <div className={styles.optionSublabel({ v3: hasV3 })}>
                  {props.getOptionSublabel(props.value)}
                </div>
              ) : null}
            </div>
          </div>
        ) : (
          <>
            <div
              tabIndex={0}
              {...params.inputProps}
              className={styles.input({ style: props.style, searchable })}
            >
              <span className={sprinkles({ color: "gray600" })}>
                {props.placeholder || "Select..."}
              </span>
            </div>
            {rightIcons}
          </>
        );
    }
    return (
      <div
        ref={params.InputProps.ref}
        className={styles.inputContainer({
          focus,
          disabled: props.disabled,
          style: props.style,
          size: props.size,
          selected: props.highlightWhenSelected && props.value != null,
        })}
      >
        {props.style === "search" && (
          <div className={styles.icon({ location: "search" })}>
            <Icon name="search" size="xs" />
          </div>
        )}
        {props.value != null && !props.multiple
          ? renderIcon(props.value, "value")
          : null}
        {((props.style !== "search" &&
          (!props.value || props.alwaysShowPlaceholderIcon)) ||
          props.alwaysShowPlaceholder) &&
          props.placeholderIcon && (
            <div className={styles.icon({ location: "value" })}>
              {props.placeHolderIconColor ? (
                <Icon
                  data={props.placeholderIcon}
                  size="xs"
                  color={props.placeHolderIconColor}
                />
              ) : (
                <Icon
                  data={props.placeholderIcon}
                  size="xs"
                  color={props.placeHolderIconColor}
                />
              )}
            </div>
          )}
        {input}
      </div>
    );
  };

  const renderOption = (option: Option) => {
    return (
      <div className={styles.option}>
        {props.multiple && (
          <span className={styles.checkbox}>
            <Checkbox
              size={props.checkboxSize ?? "sm"}
              checked={
                props.value?.some((value) =>
                  props.getOptionSelected
                    ? props.getOptionSelected(value, option)
                    : value === option
                ) ?? false
              }
              onChange={(checked) => {
                applyScrollHack();
                removeScrollHack();
                const oldValues = props.value ?? [];
                if (checked) {
                  props.onChange && props.onChange([...oldValues, option]);
                  props.onSelectValue &&
                    props.onSelectValue(option, "select-option");
                } else {
                  props.onChange &&
                    props.onChange([
                      ...oldValues.filter((value) => value !== option),
                    ]);
                  props.onSelectValue &&
                    props.onSelectValue(option, "remove-option");
                }
                setLastClicked(props.options.indexOf(option));
              }}
              onChangeShift={(checked) => {
                applyScrollHack();
                removeScrollHack();
                const oldValues = props.value ?? [];

                // based on gmail's behavior, we remove all options
                // from the last clicked option to the current option
                const optionIndex = props.options.indexOf(option);
                const optionsFromLastClicked =
                  lastClicked !== undefined
                    ? props.options.slice(
                        min([lastClicked, optionIndex]),
                        // will never be undefined because we do a null check
                        (max([lastClicked, optionIndex]) ?? 0) + 1
                      )
                    : [option];

                if (checked) {
                  props.onChange &&
                    props.onChange([...oldValues, ...optionsFromLastClicked]);
                  props.onBulkSelectValue
                    ? props.onBulkSelectValue(
                        [...oldValues, ...optionsFromLastClicked],
                        "select-option"
                      )
                    : props.onSelectValue &&
                      props.onSelectValue(option, "select-option");
                } else {
                  props.onChange && props.onChange(optionsFromLastClicked);
                  props.onBulkSelectValue
                    ? props.onBulkSelectValue(
                        optionsFromLastClicked,
                        "remove-option"
                      )
                    : props.onSelectValue &&
                      props.onSelectValue(option, "remove-option");
                }
                setLastClicked(props.options.indexOf(option));
              }}
              disabled={
                (props.getOptionCheckboxDisabled &&
                  props.getOptionCheckboxDisabled(option)) ||
                false
              }
            />
          </span>
        )}
        {renderIcon(option, "option")}
        {renderOptionLabel(option)}
        {props.onDrilldown &&
          props.optionHasDrilldown &&
          props.optionHasDrilldown(option) && (
            <div className={styles.drilldownIcon}>
              <Icon name="chevron-right" size="xs" />
            </div>
          )}
      </div>
    );
  };

  // Never return undefined or you will have a bad time with controlled->uncontrolled
  const getInputValue = (): string => {
    if (focus && inputValue !== undefined) {
      return inputValue ?? "";
    }
    if (props.style === "search") {
      return inputValue ?? "";
    }
    if (props.multiple) {
      if (props.alwaysShowPlaceholder) {
        return "";
      }
      if (props.renderInputValue) {
        return props.renderInputValue(props.value);
      }
      if (props.value && props.value.length) {
        return `${props.value.length} selected`;
      }
      return "";
    }
    return props.value != null ? props.getOptionLabel(props.value) : "";
  };

  const handleChangeInputValue = (newInputValue: string, reason: string) => {
    if (
      reason === "reset" &&
      props.disableBuiltInFiltering &&
      newInputValue === ""
    ) {
      return;
    }
    // Retain search text when props.multiple=true and an item was just selected.
    // The dropdown is open, we want to continue showing filtered values.
    if (props.multiple && reason === "reset") {
      return;
    }
    setInputValue(newInputValue);
    if (props.onInputChange && reason === "input") {
      props.onInputChange(newInputValue);
    }
  };

  const singleValueGetValue = (): Option | null => {
    if (props.multiple) {
      return null;
    }
    if (props.selectOnly) {
      return null;
    }

    // Convert undefined -> null as Autocomplete treats null as "no value" but not undefined
    return props.value ?? null;
  };

  const multipleValueGetValue = (): Option[] | undefined => {
    if (!props.multiple) {
      return [];
    }
    if (props.selectOnly) {
      return [];
    }
    return props.value ?? [];
  };

  const singleValueOnChange = (
    e: React.ChangeEvent<{}>,
    newValue?: Option | null,
    reason?: AutocompleteChangeReason
  ) => {
    if (props.multiple) {
      return;
    }
    if (
      newValue &&
      props.onDrilldown &&
      props.optionHasDrilldown &&
      props.optionHasDrilldown(newValue)
    ) {
      props.onDrilldown(newValue);
      // Clear the input so filters don't apply across drilldown pages
      handleChangeInputValue("", "drilldown");
      return;
    }
    props.onChange(newValue ?? undefined, reason);
  };

  const multipleValueOnChange = (
    e: React.ChangeEvent<{}>,
    newValue: Option[] | null | undefined,
    reason: AutocompleteChangeReason,
    details: AutocompleteChangeDetails<Option> | undefined
  ) => {
    if (!props.multiple) {
      return;
    }
    applyScrollHack();
    removeScrollHack();

    if (
      props.onDrilldown &&
      props.optionHasDrilldown &&
      details?.option &&
      props.optionHasDrilldown(details?.option)
    ) {
      const newlySelected = details?.option;
      // Clear the input so filters don't apply across drilldown pages
      newlySelected && props.onDrilldown(newlySelected);
      handleChangeInputValue("", "drilldown");
      return;
    }

    props.onChange && props.onChange(newValue ?? undefined);
    props.onSelectValue && props.onSelectValue(details?.option, reason);
  };

  const filterOptions = (
    options: Option[],
    { inputValue }: { inputValue: string }
  ) => {
    if (!inputValue) {
      return options;
    }
    return matchSorter(options, inputValue, {
      keys: [(opt: Option) => props.getOptionLabel(opt)],
    });
  };

  // The true/false discrimination on `multiple` by Autocomplete makes it necessary
  // to instantiate the component twice, i.e., can't do <Autocomplete ... multiple={props.multiple} />
  const autoCompleteProps = {
    id: props.id,
    options: props.loading ? [] : props.options,
    getOptionLabel: props.getOptionLabel,
    getOptionSelected: props.selectOnly ? () => false : props.getOptionSelected,
    getOptionDisabled: props.getOptionDisabled,
    filterOptions: props.disableBuiltInFiltering
      ? (x: Option[]) => x
      : filterOptions,
    loadingText: "Loading...",
    loading: props.loading,
    onFocus: () => {
      if (props.focusEvent) {
        logEvent(props.focusEvent);
      }
      if (props.value === undefined) {
        setInputValue("");
      }
      setFocus(true);
    },
    onBlur: () => {
      setFocus(false);
    },
    openOnFocus: true,
    clearOnBlur: false,
    clearOnEscape: false,
    renderInput: renderInput,
    renderOption: renderOption,
    ListboxComponent: ListboxComponent,
    inputValue: getInputValue(),
    onInputChange: (
      _: React.ChangeEvent<{}>,
      newInputValue: string,
      reason: string
    ) => handleChangeInputValue(newInputValue, reason),
    blurOnSelect: props.selectOnly ?? false,
    disabled: props.disabled,
    // Prevent menu from closing on drilldown page switches
    disableCloseOnSelect: Boolean(props.onDrilldown),
    groupBy: props.groupBy,
    renderGroup: (params: RenderGroupParams) => (
      <>
        <div className={styles.groupHeader}>{params.group}</div>
        {params.children}
        {props.groupHasLoadMore && props.groupHasLoadMore(params.group) && (
          <div className={styles.loadMoreButton}>
            <Button
              label="Load more"
              outline
              fullWidth
              onClick={() =>
                props.onGroupLoadMore && props.onGroupLoadMore(params.group)
              }
            />
          </div>
        )}
      </>
    ),
    PopperComponent: props.popperForceDownward
      ? ForcedDownwardPopper
      : PopperWithoutGPUAcceleration,
    noOptionsText: props.noOptionsText ?? <NoOptions />,
  };

  const autoCompleteComponent = props.multiple ? (
    <Autocomplete
      {...autoCompleteProps}
      value={multipleValueGetValue()}
      onChange={multipleValueOnChange}
      disableCloseOnSelect
      multiple
    />
  ) : (
    <Autocomplete
      {...autoCompleteProps}
      value={singleValueGetValue()}
      onChange={singleValueOnChange}
    />
  );

  return (
    <popperHeightContext.Provider
      value={props.popperHeight ?? DEFAULT_DROPDOWN_HEIGHT}
    >
      <onScrollToBottomContext.Provider value={props.onScrollToBottom ?? null}>
        <oneTimeHelperTextContext.Provider
          value={props.oneTimeHelperText ?? null}
        >
          <listboxContext.Provider
            value={{
              ...props.listboxHeader,
              ...props.listboxFooter,
              style: props.style,
            }}
          >
            {autoCompleteComponent}
          </listboxContext.Provider>
        </oneTimeHelperTextContext.Provider>
      </onScrollToBottomContext.Provider>
    </popperHeightContext.Provider>
  );
};

const ForcedDownwardPopper: React.FC<PopperProps> = ({ style, ...props }) => {
  // https://github.com/mui/material-ui/issues/21661#issuecomment-1198077932
  return (
    <Popper
      {...props}
      placement="bottom"
      style={{
        ...style,
        height: 0,
      }}
      modifiers={{ computeStyle: { gpuAcceleration: false } }}
    />
  );
};

const PopperWithoutGPUAcceleration: React.FC<PopperProps> = ({
  style,
  ...props
}) => {
  return (
    <Popper
      {...props}
      style={{ ...style }}
      modifiers={{ computeStyle: { gpuAcceleration: false } }}
    />
  );
};

/** Horrendous hack!!!
 * Prevent this code: https://github.com/mui/material-ui/blob/3e5fd3c4f200e50a1331857f092a547b41f6db1e/packages/material-ui-lab/src/useAutocomplete/useAutocomplete.js#L137-L177
 * from running which does horrible things to the listbox's scroll position during multiple selection.
 * It's otherwise necessary to have the listbox role set...
 * This hack is probably an accessibility issue.
 * To see the problem: delete this code chunk, create a Select with lots of items, scroll to the bottom, and toggle options on/off.
 * Scroll will jump all over the place.
 */
const applyScrollHack = () => {
  const listbox = document.querySelector(
    `.${styles.listboxContainerWrapper} [role="listbox"]`
  );
  listbox?.setAttribute("role", "hack");
};
const removeScrollHack = () => {
  setTimeout(() => {
    const listbox = document.querySelector(
      `.${styles.listboxContainerWrapper} [role="hack"]`
    );
    listbox?.setAttribute("role", "listbox");
  }, 10);
};

// Don't inline this component into Select unless you want to have a bad time with scroll issues.
const ListboxComponent = React.forwardRef<HTMLDivElement, ListboxProps>(
  function ListboxComponentInner(listboxProps, ref) {
    const listboxOptions = React.useContext(listboxContext);
    const oneTimeHelperText = React.useContext(oneTimeHelperTextContext);
    const onScrollToBottom = React.useContext(onScrollToBottomContext);
    const popperHeight = React.useContext(popperHeightContext);
    const intersectionRef = React.useRef<HTMLSpanElement>(null);

    React.useEffect(() => {
      if (!onScrollToBottom || !intersectionRef.current) {
        return;
      }
      const intersectionObserver = new IntersectionObserver((entries) => {
        if (!entries[0] || entries[0].intersectionRatio <= 0) return;
        onScrollToBottom();
      });
      intersectionObserver.observe(intersectionRef.current);
      return () => intersectionObserver.disconnect();
    }, [intersectionRef, onScrollToBottom]);
    return (
      <div className={styles.listboxContainerWrapper}>
        <div
          {...listboxProps}
          className={styles.listboxContainer({
            popperHeight,
            style: listboxOptions?.style,
          })}
          ref={ref}
        >
          {oneTimeHelperText && <OneTimeHelperText {...oneTimeHelperText} />}
          {listboxOptions?.title && listboxOptions.onGoBack && (
            <ListboxHeader {...listboxOptions} />
          )}
          {listboxProps.children}
          <ListboxFooter {...listboxOptions} />
          <span ref={intersectionRef} />
        </div>
      </div>
    );
  }
);

const NoOptions = () => {
  const listboxOptions = React.useContext(listboxContext);
  const oneTimeHelperText = React.useContext(oneTimeHelperTextContext);
  const popperHeight = React.useContext(popperHeightContext);
  const intersectionRef = React.useRef<HTMLSpanElement>(null);

  return (
    <div
      className={styles.listboxContainer({
        popperHeight,
        style: listboxOptions?.style,
      })}
      onMouseDown={(event) => {
        event.preventDefault();
      }}
    >
      {oneTimeHelperText && <OneTimeHelperText {...oneTimeHelperText} />}
      {listboxOptions?.title && listboxOptions.onGoBack && (
        <ListboxHeader {...listboxOptions} />
      )}
      <div className={styles.noOptionsText}>No Options</div>
      <ListboxFooter {...listboxOptions} />
      <span ref={intersectionRef} />
    </div>
  );
};

const ListboxHeader = (props: ListboxContextProps) => (
  <div className={styles.listboxHeader}>
    <div className={styles.listboxHeaderText}>
      <div className={styles.listboxHeaderChevron}>
        <Icon name="chevron-left" onClick={props.onGoBack} size="xs" />
      </div>
      {props.title}
    </div>
  </div>
);

const ListboxFooter = (props: ListboxFooterOptions) => {
  return props?.footer ? (
    <div className={styles.listboxFooter({ sticky: props.sticky })}>
      {props.footer}
    </div>
  ) : (
    <></>
  );
};

interface ListboxProps {
  role?: AriaRole;
  id?: string;
  footer?: JSX.Element;
}

interface OneTimeHelperTextProps {
  localStorageKey: string;
  text: JSX.Element;
}

const OneTimeHelperText = (props: OneTimeHelperTextProps) => {
  const [closed, setClosed] = React.useState(false);
  const key = `select-onetimehelpertext-${props.localStorageKey}`;
  if (closed || localStorage.getItem(key)) {
    return null;
  }
  return (
    <div className={styles.oneTimeHelperBox}>
      <div className={styles.oneTimeHelperTextClose}>
        <Icon
          name="x"
          size="xxs"
          onClick={() => {
            setClosed(true);
            localStorage.setItem(key, "closed");
          }}
        />
      </div>
      <div className={styles.oneTimeHelperText}>{props.text}</div>
    </div>
  );
};

export default Select;
