import {
  ButtonV3,
  Checkbox,
  ContextMenu,
  EntityIcon,
  Icon,
  Tooltip,
} from "components/ui";
import { IconGroup } from "components/ui/icon_group/IconGroup";
import { IconData } from "components/ui/utils";
import React from "react";
import { useState } from "react";
import { useDebouncedValue } from "utils/hooks";

import * as styles from "./InteractiveCard.css";

type IconSize = PropsFor<typeof Icon>["size"];
type BaseProps = {
  title?: string;
  subtitle?: string;
  subtitleTooltip?: PropsFor<typeof Tooltip>["tooltipText"];
  // pass an empty description string if you think some of your cards might
  // have a description and some might not
  description?: string;
  note?: string | React.ReactNode;

  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

  topLabel?: string | React.ReactNode;
  checked?: boolean;
  onCheckedChange?: (checked: boolean) => void;
  disabledReason?: string;

  renderCTA?: (isHovering: boolean) => React.ReactNode;
  renderHeaderCTA?: (isHovering: boolean) => React.ReactNode;
  menuOptions?: PropsFor<typeof ContextMenu>["options"];
  disabledBulk?: boolean;
};

type PropsWithIcon = BaseProps & {
  icon: IconData;
  iconSize?: IconSize;
  icons?: never;
};
type PropsWithIconGroup = BaseProps & {
  icons: PropsFor<typeof IconGroup>["items"];
  iconSize?: IconSize;
  icon?: never;
};
type Props = BaseProps | PropsWithIcon | PropsWithIconGroup;

const InteractiveCard: React.FC<Props> = (props) => {
  const [isHovering, setIsHovering] = useState(false);
  // Debounce the hover state to prevent flickering
  const isHoveringDebounced = useDebouncedValue(isHovering, 100);

  const renderIcon = () => {
    if ("icon" in props && props.icon) {
      return renderSingleIcon(props.icon, props.iconSize);
    } else if ("icons" in props && props.icons) {
      return renderIconGroup(props.icons, props.iconSize);
    }

    return null;
  };
  return (
    <div
      className={styles.container({
        interactive: Boolean(props.onClick),
      })}
      onClick={props.onClick}
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      <div className={styles.header}>
        <div className={styles.headerLeft}>
          {props.topLabel ? (
            <div className={styles.checkboxContainer}>
              {props.onCheckedChange ? (
                <Tooltip tooltipText={props.disabledReason}>
                  <Checkbox
                    disabled={
                      Boolean(props.disabledReason) || props.disabledBulk
                    }
                    checked={Boolean(props.checked)}
                    onChange={props.onCheckedChange}
                    label={props.topLabel}
                  />
                </Tooltip>
              ) : (
                <>{props.topLabel}</>
              )}
              {renderIcon()}
            </div>
          ) : null}
          {!props.topLabel ? renderIcon() : null}
          {props.title ? (
            <div className={styles.title({ large: props.subtitle == null })}>
              {props.title}
            </div>
          ) : null}
        </div>
        {props.renderHeaderCTA ? (
          <div>{props.renderHeaderCTA(isHoveringDebounced)}</div>
        ) : null}
        {props.menuOptions && props.menuOptions.length ? (
          <ContextMenu
            renderButton={(onClick) => (
              <div onClick={onClick}>
                <ButtonV3
                  leftIconName="dots-horizontal"
                  size="xs"
                  type="defaultBorderless"
                />
              </div>
            )}
            rightAligned
            options={props.menuOptions}
          />
        ) : null}
      </div>
      {props.subtitle != null ? (
        <Tooltip tooltipText={props.subtitleTooltip}>
          <div className={styles.subtitle}>{props.subtitle}</div>
        </Tooltip>
      ) : null}

      {props.description !== undefined ? (
        <div className={styles.description}>{props.description}</div>
      ) : null}
      {props.note !== undefined ? (
        <div className={styles.note}>{props.note}</div>
      ) : null}

      <div className={styles.cta}>
        {props.renderCTA ? props.renderCTA(isHoveringDebounced) : null}
      </div>
    </div>
  );
};

function renderSingleIcon(icon: IconData, iconSize?: IconSize) {
  if (icon) {
    if (icon.type === "entity") {
      // Weird hack to get the icon the right size
      // TODO: Remove EntityIcon and consolidate everything into Icon
      const entityIconSize = ["sm", "md", "lg", "xl", "xxl"].find(
        (size) => size === iconSize
      ) as PropsFor<typeof EntityIcon>["size"];
      return (
        <div className={styles.icon()}>
          <EntityIcon
            type={icon.entityType}
            size={entityIconSize}
            onlyBrandIcon={icon.onlyBrandIcon}
          />
        </div>
      );
    }
    return (
      <div className={styles.icon()}>
        <Icon data={icon} size={iconSize} />
      </div>
    );
  }

  return (
    <div className={styles.icon({ empty: true })}>
      <Icon color="white" name="placeholder" size={iconSize} />
    </div>
  );
}

function renderIconGroup(
  icons: PropsFor<typeof IconGroup>["items"],
  iconSize?: IconSize
) {
  if (icons && icons.length > 0) {
    const iconGroupSize = ["sm", "md", "lg", "xl", "xxl", "xxxl"].find(
      (size) => size === iconSize
    ) as PropsFor<typeof IconGroup>["iconSize"];
    return <IconGroup items={icons} iconSize={iconGroupSize} />;
  }

  return (
    <div className={styles.icon({ empty: true })}>
      <Icon color="white" name="placeholder" size={iconSize} />
    </div>
  );
}

export default InteractiveCard;
