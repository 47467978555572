import {
  RequestCustomMetadataValueInput,
  RequestTemplateCustomFieldFragment,
  RequestTemplateCustomFieldType,
} from "api/generated/graphql";
import { Checkbox, FormGroup, Input, Select } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React from "react";

import * as styles from "./RequestCustomFieldRow.css";

interface Props {
  customField: RequestTemplateCustomFieldFragment;
  onChange: (value: RequestCustomMetadataValueInput) => void;
  metadataValue?: RequestCustomMetadataValueInput;
}

const RequestCustomFieldRow: React.FC<Props> = (props) => {
  if (props.customField.type === RequestTemplateCustomFieldType.Boolean) {
    return (
      <>
        <div className={styles.checkboxContainer}>
          <Checkbox
            label={
              <span className={sprinkles({ display: "flex" })}>
                {props.customField.name}
                {props.customField.required ? (
                  <span className={sprinkles({ color: "red600" })}>*</span>
                ) : null}
              </span>
            }
            checked={Boolean(props.metadataValue?.booleanValue?.value)}
            onChange={(checked) =>
              props.onChange({ booleanValue: { value: checked } })
            }
          />
          <span className={styles.description}>
            {props.customField.description}
          </span>
        </div>
      </>
    );
  }

  let body = <></>;
  switch (props.customField.type) {
    case RequestTemplateCustomFieldType.ShortText:
      body = (
        <Input
          value={props.metadataValue?.shortTextValue?.value ?? ""}
          onChange={(value) => props.onChange({ shortTextValue: { value } })}
        />
      );
      break;
    case RequestTemplateCustomFieldType.LongText:
      body = (
        <Input
          type="textarea"
          value={props.metadataValue?.longTextValue?.value ?? ""}
          onChange={(value) => props.onChange({ longTextValue: { value } })}
        />
      );
      break;
    case RequestTemplateCustomFieldType.MultiChoice:
      body = (
        <Select
          value={props.metadataValue?.multiChoiceValue?.value}
          options={
            props.customField.metadata?.multiChoiceData?.options?.map(
              ({ value }) => value
            ) ?? []
          }
          getOptionLabel={(opt) => opt}
          onChange={(opt) =>
            props.onChange({
              multiChoiceValue: opt ? { value: opt } : undefined,
            })
          }
          clearable
        />
      );
  }
  return (
    <FormGroup
      label={props.customField.name}
      required={Boolean(props.customField.required)}
      description={props.customField.description ?? undefined}
    >
      {body}
    </FormGroup>
  );
};

export default RequestCustomFieldRow;
