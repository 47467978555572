import {
  IntegrationType,
  Maybe,
  useInitThirdPartyIntegrationAuthFlowMutation,
} from "api/generated/graphql";
import MessageModal from "components/modals/update/MessageModal";
import React, { useContext, useState } from "react";
import { logError } from "utils/logging";
import AppContext from "views/app/AppContext";
import { subtitleFormat } from "views/groups/creation/common";
import { IntegrationCreationInfo } from "views/settings/third_party_authorizer/ThirdPartyCodeFlowAuthorizer";

export type IntegrationRedirectConfirmModalProps = {
  title: string;
  message: React.ReactChild;
  isModalOpen: boolean;
  stepNumber: number;
  setStepNumber: (step: number) => void;
  totalSteps: number;
  value: string;
  onClose: () => void;
  integrationCreationInfo: IntegrationCreationInfo;
};

export const OrgIntegrationRedirectConfirmModal = (
  props: IntegrationRedirectConfirmModalProps
) => {
  const { appState } = useContext(AppContext);

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const [
    initThirdPartyAuthFlow,
    { loading },
  ] = useInitThirdPartyIntegrationAuthFlowMutation();

  const modalReset = () => {
    props.onClose();
    setErrorMessage(null);
  };

  return (
    <MessageModal
      title={props.title}
      subtitle={subtitleFormat(props.stepNumber, props.totalSteps)}
      message={props.message}
      isModalOpen={props.isModalOpen}
      onClose={() => {
        modalReset();
      }}
      onSubmit={async () => {
        let clientInfo = null;
        try {
          if (appState.isOnPrem) {
            clientInfo = {
              clientId: props.integrationCreationInfo.clientInfo.clientId,
              clientSecret:
                props.integrationCreationInfo.clientInfo.clientSecret,
              signingSecret:
                props.integrationCreationInfo.clientInfo.signingSecret,
              clientSecondaryToken:
                props.integrationCreationInfo.clientInfo
                  .clientSecondaryToken === ""
                  ? null
                  : props.integrationCreationInfo.clientInfo
                      .clientSecondaryToken,
            };
          }
          const { data } = await initThirdPartyAuthFlow({
            variables: {
              input: {
                thirdPartyIntegrationType: IntegrationType.Org,
                thirdPartyProvider:
                  props.integrationCreationInfo.thirdPartyProvider,
                orgName: props.integrationCreationInfo.orgName,
                clientInfo: clientInfo,
              },
            },
          });
          switch (data?.initThirdPartyIntegrationAuthFlow.__typename) {
            case "InitThirdPartyIntegrationAuthFlowResult":
              modalReset();
              window.location.href = data.initThirdPartyIntegrationAuthFlow.url;
              break;
            case "ThirdPartyIntegrationNotFoundError":
              setErrorMessage(data.initThirdPartyIntegrationAuthFlow.message);
              break;
            default:
              logError(new Error(`failed to initiate third-party auth flow`));
              setErrorMessage(
                `Error: failed to initiate third-party auth flow`
              );
          }
        } catch (error) {
          logError(error, `failed to initiate third-party auth flow`);
          setErrorMessage(`Error: failed to initiate third-party auth flow`);
        }
      }}
      value={props.value}
      loading={loading}
      errorMessage={errorMessage}
      isStepModal={true}
      onBack={
        props.stepNumber > 1
          ? () => {
              props.setStepNumber(props.stepNumber - 1);
            }
          : undefined
      }
      inputTypePassword={false}
    />
  );
};
