import {
  GroupAccessLevel,
  GroupPreviewWithAccessLevelsFragment,
  useGroupPreviewWithAccessLevelsQuery,
} from "api/generated/graphql";
import { Button, EntityIcon, Select, Skeleton } from "components/ui";
import sprinkles from "css/sprinkles.css";

import * as styles from "./AddItemModal.css";
import ModalErrorMessage from "./ModalErrorMessage";

interface RowProps {
  groupId: string;
  existingRoles: GroupAccessLevel[];
  roles: GroupAccessLevel[];
  onGroupRemove: () => void;
  onRoleSelect: (role: GroupAccessLevel) => void;
  onRoleRemove: (role: GroupAccessLevel) => void;
  error?: string;
}

const GroupRoleRow = (props: RowProps) => {
  const { existingRoles: existingRoles, roles: roles } = props;

  const { data, loading, error } = useGroupPreviewWithAccessLevelsQuery({
    variables: {
      input: {
        id: props.groupId,
      },
    },
  });

  if (loading) {
    return <Skeleton height="44px" />;
  }

  let group: GroupPreviewWithAccessLevelsFragment | undefined;
  switch (data?.group.__typename) {
    case "GroupResult":
      group = data.group.group;
      break;
  }

  if (error || !group) {
    return null;
  }

  const allRoles = group.accessLevels ?? [];

  const hasRoles =
    allRoles.length > 0 && allRoles[0].accessLevelRemoteId !== "";

  const options = allRoles.filter((role) => {
    return ![...existingRoles, ...roles].some(
      (existingRole) =>
        existingRole.accessLevelRemoteId === role.accessLevelRemoteId
    );
  });

  return (
    <div>
      <div className={styles.itemRow}>
        <EntityIcon type={group.groupType} />
        <div className={sprinkles({ flexGrow: 1 })}>
          <div className={sprinkles({ fontSize: "labelMd" })}>{group.name}</div>
          <div className={sprinkles({ color: "gray500", fontSize: "labelSm" })}>
            {group.connection?.name}
          </div>
        </div>
        <Button
          leftIconName="trash-2"
          borderless
          type="error"
          onClick={props.onGroupRemove}
          size="sm"
          round
        />
      </div>
      {hasRoles && (
        <div
          className={sprinkles({
            padding: "sm",
            marginTop: "sm",
            backgroundColor: "gray100",
            borderRadius: "md",
          })}
        >
          <Select
            options={options}
            placeholder="Select a role"
            onChange={(opt) => {
              if (opt) {
                props.onRoleSelect(opt);
              }
            }}
            getOptionLabel={(opt) => opt.accessLevelName}
            multiple={false}
            value={roles.length > 0 ? roles[0] : undefined}
          />
        </div>
      )}
      {props.error && <ModalErrorMessage errorMessage={props.error} />}
    </div>
  );
};

export default GroupRoleRow;
