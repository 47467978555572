import { useConnectionsQuery } from "api/generated/graphql";
import {
  DataElement,
  DataElementList,
  FormGroup,
  Modal,
  Select,
  Skeleton,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useState } from "react";
import { logError } from "utils/logging";

interface Props {
  connectionIDs: string[];
  onChangeConnectionIDs?: (connectionIDs: string[]) => void;
}

const ConnectionsFilter: React.FC<Props> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalConnectionIDs, setModalConnectionIDs] = useState<string[]>([]);

  const { data, loading, error } = useConnectionsQuery({
    variables: {
      input: {},
    },
  });
  if (error) {
    logError(error, `failed to list connections`);
  }
  if (loading) {
    return <Skeleton />;
  }

  const allConnections = data?.connections.connections ?? [];
  const unselectedConnections = allConnections.filter(
    (connection) => !modalConnectionIDs.includes(connection.id)
  );

  const handleOpenModal = () => {
    setShowModal(true);
    setModalConnectionIDs(props.connectionIDs);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    if (!props.onChangeConnectionIDs) return;
    props.onChangeConnectionIDs([...modalConnectionIDs]);
    setShowModal(false);
  };

  if (!props.onChangeConnectionIDs) {
    if (props.connectionIDs.length === 0) {
      return null;
    }
    return (
      <FormGroup
        label="Entities from app"
        fontWeight="normal"
        fontSize="textMd"
      >
        <DataElementList>
          {props.connectionIDs.map((connectionID) => {
            const connection = allConnections.find(
              (o) => o.id === connectionID
            );
            if (!connection) {
              return null;
            }
            return (
              <DataElement
                label={connection.name}
                color="pink"
                leftIcon={{
                  data: {
                    type: "entity",
                    entityType: connection.connectionType,
                  },
                }}
              />
            );
          })}
        </DataElementList>
      </FormGroup>
    );
  }

  return (
    <>
      <FormGroup
        label="Entities from app"
        fontWeight="normal"
        fontSize="textMd"
      >
        <div className={sprinkles({ marginBottom: "sm" })}>
          Add items to the access review that are from the following apps
        </div>
        <DataElementList>
          {props.connectionIDs.map((connectionID) => {
            const connection = allConnections.find(
              (o) => o.id === connectionID
            );
            if (!connection) {
              return null;
            }
            return (
              <DataElement
                label={connection.name}
                color="pink"
                leftIcon={{
                  data: {
                    type: "entity",
                    entityType: connection.connectionType,
                  },
                }}
                rightIcon={{
                  name: "x",
                  onClick: () => {
                    props.onChangeConnectionIDs &&
                      props.onChangeConnectionIDs(
                        props.connectionIDs.filter((id) => id !== connectionID)
                      );
                  },
                }}
              />
            );
          })}
          <DataElement
            label="Add app"
            color="blue"
            leftIcon={{
              name: "plus",
            }}
            onClick={handleOpenModal}
          />
        </DataElementList>
      </FormGroup>
      <Modal
        isOpen={showModal}
        onClose={handleCancel}
        title="Include entities from app"
      >
        <Modal.Body>
          <p>
            This access review will include resources and groups that are from
            one of the following apps. It will also include any apps themselves.
            If no apps are selected, the access review will only include items
            matching the other provided scopes.
          </p>
          <div className={sprinkles({ marginBottom: "md" })}>
            <Select
              options={unselectedConnections}
              selectOnly
              getOptionLabel={(option) => option.name}
              onChange={(connection) => {
                if (connection) {
                  setModalConnectionIDs([...modalConnectionIDs, connection.id]);
                }
              }}
              getIcon={(connection) => ({
                type: "entity",
                entityType: connection.connectionType,
              })}
              placeholder="Select an app"
              autoFocus
            />
          </div>
          <DataElementList>
            {modalConnectionIDs.map((connectionID) => {
              const connection = allConnections.find(
                (o) => o.id === connectionID
              );
              if (!connection) {
                return null;
              }
              return (
                <DataElement
                  label={connection.name}
                  color="pink"
                  leftIcon={{
                    data: {
                      type: "entity",
                      entityType: connection.connectionType,
                    },
                  }}
                  rightIcon={{
                    name: "x",
                    onClick: () => {
                      setModalConnectionIDs(
                        modalConnectionIDs.filter((id) => id !== connectionID)
                      );
                    },
                  }}
                />
              );
            })}
          </DataElementList>
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Save"
          onPrimaryButtonClick={handleSave}
          secondaryButtonLabel="Cancel"
          onSecondaryButtonClick={handleCancel}
        />
      </Modal>
    </>
  );
};

export default ConnectionsFilter;
