import {
  AccessReviewConnectionSmallFragment,
  AccessReviewGroupSmallFragment,
  AccessReviewItemStatus,
  AccessReviewResourceSmallFragment,
  AccessReviewReviewerDetailFragment,
  AccessReviewReviewerItemFragment,
  EntityType,
  useAccessReviewReviewerQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { ResourceLabel } from "components/label/Label";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { Divider, Icon, Input, Tabs } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { filterSearchResults } from "utils/search/filter";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";
import { getUserAvatarIcon } from "views/users/utils";

import * as styles from "./AccessReviewResource.css";
import EventsTable from "./common/EventsTable";
import ProgressCard from "./common/ProgressCard";

const AccessReviewReviewer = () => {
  const location = useLocation();
  const history = useHistory();
  const { accessReviewId, reviewerId } = useParams<Record<string, string>>();

  const { data, error, loading } = useAccessReviewReviewerQuery({
    variables: {
      input: {
        accessReviewId,
        userId: reviewerId,
      },
    },
  });

  const reviewer = data?.accessReviewReviewer.reviewer;
  const user = data?.accessReviewReviewer.reviewer.user;
  const items = data?.accessReviewReviewer.reviewer.items ?? [];

  if (loading) {
    return (
      <Column isContent>
        <ColumnContentSkeleton />
      </Column>
    );
  }

  if (error || !reviewer || !user) {
    return (
      <Column isContent>
        <UnexpectedErrorPage />
      </Column>
    );
  }

  return (
    <Column isContent>
      <ColumnHeader
        title={user.fullName}
        icon={getUserAvatarIcon(user)}
        subtitle={user.email}
        onClose={() =>
          history.push(`/access-reviews/${accessReviewId}/reviewers`)
        }
      />
      <Divider />
      <div
        className={sprinkles({
          display: "flex",
          justifyContent: "center",
          marginY: "md",
        })}
      >
        <div className={sprinkles({ width: "fit-content" })}>
          <Tabs
            tabInfos={[
              {
                title: "Items to review",
                isSelected: !location.hash || location.hash === "#items",
                onClick: () => history.push({ hash: "#items" }),
                icon: "cube",
                badgeCount: items.length,
              },
              {
                title: "Events",
                isSelected: location.hash === "#events",
                onClick: () => history.push({ hash: "#events" }),
                icon: "events",
              },
            ]}
            round
          />
        </div>
      </div>
      {!location.hash || location.hash === "#items" ? (
        <ReviewItems reviewer={reviewer} />
      ) : null}
      {location.hash === "#events" && (
        <div className={styles.tableContainer}>
          <EventsTable
            eventFilter={{
              objects: {
                objectId: accessReviewId,
              },
              actors: {
                userId: reviewerId,
              },
            }}
          />
        </div>
      )}
    </Column>
  );
};

interface ReviewItemRow {
  id: string;
  name: string;
  status: string;
  data: AccessReviewReviewerItemFragment;
}

type ReviewEntity =
  | AccessReviewResourceSmallFragment
  | AccessReviewGroupSmallFragment
  | AccessReviewConnectionSmallFragment;

const getEntityName = (entity?: ReviewEntity) => {
  if (!entity) {
    return "";
  }
  if ("resource" in entity) {
    return entity.resource?.name;
  } else if ("group" in entity) {
    return entity.group?.name;
  } else if ("connection" in entity) {
    return entity.connection?.name;
  }
};

const getEntitySublabel = (entity: ReviewEntity) => {
  if ("resource" in entity) {
    return getResourceTypeInfo(entity.resource?.resourceType)?.fullName || "--";
  } else if ("group" in entity) {
    return getGroupTypeInfo(entity.group?.groupType)?.name || "--";
  } else if ("connection" in entity) {
    return "App";
  }
};

const ReviewItems = ({
  reviewer,
}: {
  reviewer: AccessReviewReviewerDetailFragment;
}) => {
  const { accessReviewId } = useParams<Record<string, string>>();

  const [searchQuery, setSearchQuery] = useState("");

  const totalNumItems = reviewer.totalItemsCount;
  const completedItems = reviewer.completedItemsCount;

  const rows: ReviewItemRow[] = [];
  filterSearchResults((reviewer.items ?? []).slice(), searchQuery, (item) => [
    getEntityName(
      item.item.resource ?? item.item.group ?? item.item.connection ?? undefined
    ),
  ]).forEach((item) => {
    const entity: ReviewEntity | undefined | null =
      item.item.resource ?? item.item.group ?? item.item.connection;
    if (!entity) {
      return;
    }
    rows.push({
      id: entity.id,
      name: getEntityName(entity) ?? "",
      status: item.reviewStatus,
      data: item,
    });
  });

  const columns: Header<ReviewItemRow>[] = [
    {
      id: "name",
      label: "Name",
      customCellRenderer: (row) => {
        const entity: ReviewEntity | undefined | null =
          row.data.item.resource ??
          row.data.item.group ??
          row.data.item.connection;
        if (!entity) return <></>;

        let entityType = EntityType.Resource;
        let urlKey = "r";
        if ("group" in entity) {
          entityType = EntityType.Group;
          urlKey = "g";
        } else if ("connection" in entity) {
          entityType = EntityType.Connection;
          urlKey = "c";
        }

        return (
          <ResourceLabel
            text={row.name}
            subText={getEntitySublabel(entity)}
            entityTypeNew={entityType}
            resourceType={
              "resource" in entity ? entity.resource?.resourceType : undefined
            }
            groupType={"group" in entity ? entity.group?.groupType : undefined}
            connectionType={
              "connection" in entity
                ? entity.connection?.connectionType
                : undefined
            }
            iconLarge
            bold
            url={`/access-reviews/${accessReviewId}/entities/${urlKey}/${entity.id}`}
          />
        );
      },
    },
    {
      id: "status",
      label: "Status",
      customCellRenderer: (row) => {
        const status = row.data.reviewStatus;
        switch (status) {
          case AccessReviewItemStatus.Completed:
            return (
              <div
                className={sprinkles({
                  color: "green1000",
                  display: "flex",
                  gap: "sm",
                  alignItems: "center",
                })}
              >
                <Icon name="check-circle" color="green600" size="xs" />
                Completed
              </div>
            );
          case AccessReviewItemStatus.PartiallyCompleted:
            return (
              <div
                className={sprinkles({
                  color: "yellow1000",
                  display: "flex",
                  gap: "sm",
                  alignItems: "center",
                })}
              >
                <Icon name="incomplete" color="yellow700" size="xs" />
                Partially Complete
              </div>
            );
          case AccessReviewItemStatus.NotStarted:
            return (
              <div
                className={sprinkles({
                  color: "gray900",
                  display: "flex",
                  gap: "sm",
                  alignItems: "center",
                })}
              >
                <Icon name="alert-circle" color="gray600" size="xs" />
                Not Started
              </div>
            );
        }

        return <></>;
      },
    },
  ];

  return (
    <>
      <ProgressCard
        totalNumItems={totalNumItems}
        completedItems={completedItems}
      />
      <ColumnContent>
        <div className={styles.tableContainer}>
          <div className={styles.searchContainer}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Search this table"
            />
          </div>
          <Table
            rows={rows}
            totalNumRows={rows.length}
            columns={columns}
            getRowId={(row) => row.id}
            defaultSortBy="status"
            defaultSortDirection="DESC"
          />
        </div>
      </ColumnContent>
    </>
  );
};

export default AccessReviewReviewer;
