import styles from "components/more_info/MoreInfo.module.scss";
import { Tooltip } from "components/ui";
import React, { ReactElement } from "react";
import * as Icons from "react-feather";

export type MoreInfoProps = {
  tooltipText: string | ReactElement;
};

export const MoreInfo = (props: MoreInfoProps) => {
  return (
    <div className={styles.moreInfoRootContainer}>
      <Tooltip tooltipText={props.tooltipText}>
        <span className={styles.iconContainer}>
          <Icons.Info
            strokeWidth={3}
            size={13}
            className={styles.tooltipIcon}
          />
        </span>
      </Tooltip>
    </div>
  );
};

/**
 * When wrapped around an element, adds styling to the contained text and shows a
 * tooltips upon hover.
 */
export const TextHoverTooltip: React.FC<MoreInfoProps> = (props) => {
  return (
    <Tooltip tooltipText={props.tooltipText} arrow contentInline>
      <span className={styles.textHoverContainer}>{props.children}</span>
    </Tooltip>
  );
};
