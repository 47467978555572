import {
  AccessOption,
  AppGroupRowFragment,
  AppIdpGroupMappingRowFragment,
  AppResourceRowFragment,
  EntityType,
  GroupType,
  ResourceType,
  useAppResourcesQuery,
  Visibility,
} from "api/generated/graphql";
import axios from "axios";
import AuthContext from "components/auth/AuthContext";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import OpalLink from "components/opal/common/OpalLink";
import { useToast } from "components/toast/Toast";
import {
  ButtonV3,
  EntityIcon,
  Icon,
  Input,
  Loader,
  Select,
  Skeleton,
  Tooltip,
} from "components/ui";
import ButtonGroup from "components/ui/buttongroup/ButtonGroupV3";
import Table, { Header } from "components/ui/table/Table";
import TableFilters from "components/ui/table/TableFilters";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useDebouncedValue } from "utils/hooks";
import {
  useTransitionTo,
  useURLSearchParam,
  useURLSearchParamAsEnum,
} from "utils/router/hooks";
import { useAccessRequestTransition } from "views/access_request/AccessRequestContext";
import { BulkDeleteModal } from "views/common/BulkUpdateActionButtons";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import * as styles from "./AppResourcesTable.css";
import { makeBulkEditSelectedItemsUrl } from "./AppsBulkEditView";
import {
  ACCESS_OPTION_URL_KEY,
  AppsContext,
  ITEM_TYPE_URL_KEY,
  OKTA_APP_ID_URL_KEY,
} from "./AppsContext";
import BulkEditAliasModal from "./BulkEditAliasModal";
import BulkRemoveMappingModal from "./BulkRemoveMappingModal";
import BulkRequestModal from "./BulkRequestModal";
import { formatRequestDataForItems } from "./enduser_exp/utils";
import BulkHideFromEndUserModal from "./HideFromEndUserModal";
import { truncateResourceAncestorPath } from "./utils";

interface ResourceRow {
  id: string;
  name: string;
  alias: string;
  hiddenFromEndUser: boolean;
  type: ResourceType | GroupType;
  admin: string;
  visibility: number;
  data: AppResourceRowFragment | AppGroupRowFragment;
  hasVisibleChildren: boolean;
  description: string;
  createdAt: string;
  numUsers: number;
}

interface Props {
  totalResources?: number;
  appId?: string;
  resourceType?: ResourceType;
}

const AppResourcesTable = (props: Props) => {
  const history = useHistory();
  const transitionTo = useTransitionTo();
  const logEvent = useLogEvent();
  const { appId, resourceId } = useParams<Record<string, string>>();
  const { authState } = useContext(AuthContext);
  const hasEndUserXP = useFeatureFlag(FeatureFlag.EndUserExperience);
  const transitionToAccessRequest = useAccessRequestTransition();
  const { selectItems, clearSelectedItems } = useContext(AppsContext);
  const hasOktaAlias = useFeatureFlag(FeatureFlag.OktaGroupAlias);
  const isOktaApp = props.resourceType === ResourceType.OktaApp;
  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();

  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  const [accessOption, setAccessOption] = useURLSearchParamAsEnum(
    ACCESS_OPTION_URL_KEY,
    AccessOption,
    AccessOption.All
  );
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRequestModal, setShowRequestModal] = useState(false);
  const [showEditAliasModal, setShowEditAliasModal] = useState(false);
  const [showConfirmHideModal, setShowConfirmHideModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [itemsLoadingSubRows, setItemsLoadingSubRows] = useState<string[]>([]);
  const [selectedGroupId, setSelectedGroupId] = useState<string>();

  const [selectedItemType, setSelectedItemType] = useURLSearchParam(
    ITEM_TYPE_URL_KEY
  );

  // Clear selected items when navigating away from this page.
  useEffect(() => {
    return clearSelectedItems;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showListResults =
    searchQuery.length > 0 ||
    selectedItemType ||
    accessOption != AccessOption.All;
  const parentResourceFilter = resourceId
    ? { resourceId }
    : { resourceId: null };
  const {
    data,
    loading,
    error,
    previousData,
    fetchMore,
  } = useAppResourcesQuery({
    variables: {
      id: props.appId ?? appId,
      itemType: selectedItemType,
      access: accessOption,
      searchQuery: debouncedSearchQuery,
      ancestorResourceId: resourceId ? { resourceId } : null,
      parentResourceId: !showListResults ? parentResourceFilter : null,
      includeGroups: Boolean(appId),
      hasV3: true,
    },
    fetchPolicy: "cache-and-network",
  });

  const app = data?.app.__typename === "App" ? data.app : null;
  const previousApp =
    previousData?.app.__typename === "App" ? previousData.app : null;
  const items = app?.items.items ?? previousApp?.items.items ?? [];
  const cursor = app?.items.cursor;
  const totalNumItems = app?.items.totalNumItems;
  const itemsByParentId: Record<
    string,
    (AppResourceRowFragment | AppGroupRowFragment)[]
  > = {};
  const itemsWithChildren: Set<String> = new Set();
  const itemsById: Record<
    string,
    AppResourceRowFragment | AppGroupRowFragment
  > = {};
  const idpGroupMappingByGroupId: Record<
    string,
    { mapping: AppIdpGroupMappingRowFragment; name: string }
  > = {};

  items.forEach((item) => {
    const parentResourceId = item.resource?.parentResource?.id;
    let resource;
    if (item.resource) {
      resource = item.resource;
    } else if (item.group) {
      resource = item.group;
      if (item.idpGroupMapping) {
        idpGroupMappingByGroupId[item.group.id] = {
          mapping: item.idpGroupMapping,
          name: item.group.name,
        };
      }
    } else {
      return;
    }
    if (resource) {
      itemsById[resource.id] = resource;
    }
    if (showListResults) {
      // If not showing a resource hierarchy then
      // skip updating itemsByParentId & itemsWithChildren
      return;
    }
    if (parentResourceId && resource) {
      itemsByParentId[parentResourceId] = [
        ...(itemsByParentId[parentResourceId] ?? []),
        resource,
      ];
    }
    if (item.resource && item.resource.hasVisibleChildren) {
      itemsWithChildren.add(item.resource.id);
    }
  });
  const selectedIdpGroupMappings: Record<
    string,
    { mapping: AppIdpGroupMappingRowFragment; name: string }
  > = {};
  const selectedResourceIds = selectedItemIds.filter(
    (id) => itemsById[id]?.__typename === "Resource"
  );
  const selectedGroupIds = selectedItemIds.filter((id) => {
    if (idpGroupMappingByGroupId[id]) {
      selectedIdpGroupMappings[id] = idpGroupMappingByGroupId[id];
    }
    return itemsById[id]?.__typename === "Group";
  });

  const buildRow = (item: AppResourceRowFragment | AppGroupRowFragment) => ({
    id: item.id,
    name: item.name,
    alias: idpGroupMappingByGroupId[item.id]?.mapping.alias ?? "",
    hiddenFromEndUser:
      idpGroupMappingByGroupId[item.id]?.mapping.hiddenFromEndUser ?? false,
    type: item.__typename === "Resource" ? item.resourceType : item.groupType,
    admin: item.adminOwner?.name ?? "",
    visibility:
      item.visibility === Visibility.Global ? -1 : item.visibilityGroups.length,
    data: item,
    hasVisibleChildren:
      item.__typename === "Resource" ? item.hasVisibleChildren : false,
    description: item.description.length > 0 ? item.description : "—",
    createdAt: item.createdAt,
    numUsers:
      item.__typename === "Resource"
        ? item.numResourceUsers
        : item.numGroupUsers,
  });

  const rows: ResourceRow[] = [];
  items.forEach((item) => {
    if (item.group) {
      rows.push(buildRow(item.group));
      return;
    }
    const resource = item.resource ?? item.group;
    if (!resource) {
      return;
    }
    if (showListResults) {
      rows.push(buildRow(resource));
      return;
    }
    // Only include top-level resources in top rows.
    // A top level resource is one that has no parent or whose parent is the current resource we're viewing.
    const parentResourceId =
      "parentResource" in resource && resource.parentResource?.id;
    if (resourceId && parentResourceId === resourceId) {
      rows.push(buildRow(resource));
    } else if (!resourceId && !parentResourceId) {
      rows.push(buildRow(resource));
    }
  });

  const loadMoreRows = async (id?: string) => {
    if (!id && !cursor) return;
    if (id) setItemsLoadingSubRows([...itemsLoadingSubRows, id]);
    await fetchMore({
      variables: {
        cursor,
        id: props.appId ?? appId,
        itemType: selectedItemType,
        access: accessOption,
        searchQuery: debouncedSearchQuery,
        ancestorResourceId: resourceId ? { resourceId } : null,
        parentResourceId: { resourceId: id },
      },
    });
    if (id) {
      setItemsLoadingSubRows(
        itemsLoadingSubRows.filter((itemId) => itemId !== id)
      );
    }
  };

  const getAllChecked = (itemId: string): boolean => {
    const children = itemsByParentId[itemId] ?? [];
    return (
      selectedItemIds.includes(itemId) &&
      children.every((child) => getAllChecked(child.id))
    );
  };

  const getAllItemIds = () => {
    return items.map((item) => item.resource?.id || item.group?.id || "");
  };

  const oktaAppDefaultActions: PropsFor<
    typeof TableHeader
  >["defaultRightActions"] = [];

  const handleExportUserClick = () => {
    logEvent({
      name: "apps_export_users",
      properties: {
        exportType: "bulk",
      },
    });
    displayLoadingToast("Generating export...");
    axios({
      url: `/export/items/users`,
      params: {
        resourceIDs: selectedResourceIds,
        groupIDs: selectedGroupIds,
      },
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        if (!selectedItemIds) return;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Opal_Bulk_Export_Items_" + _.uniqueId() + "_Users.csv"
        );
        link.click();
        displaySuccessToast(
          `Success: downloaded users for ${selectedItemIds.length} ${pluralize(
            "item",
            selectedItemIds.length
          )}`
        );
      })
      .catch(() => {
        displayErrorToast(`Error: failed to generate export`);
      });
  };

  const columns: Header<ResourceRow>[] = [
    {
      id: "name",
      label: "Name",
      customCellRenderer: (row) => {
        const { name, data } = row;
        const subText =
          showListResults && data.__typename === "Resource"
            ? truncateResourceAncestorPath(data.ancestorPathToResource)
            : undefined;
        return (
          <div
            className={sprinkles({
              display: "flex",
              alignItems: "center",
              gap: "md",
            })}
          >
            <div
              className={sprinkles({
                display: "flex",
                flexDirection: "column",
              })}
            >
              <OpalLink to={getPathnameForRow(row)}>{name}</OpalLink>
              <div className={styles.sublabel}>{subText}</div>
            </div>
            {itemsLoadingSubRows.includes(data.id) && <Loader size="xs" />}
          </div>
        );
      },
    },
    {
      id: "type",
      label: "Type",
      width: 150,
      customCellRenderer: ({ type, data }) => {
        let label = type.toString();
        let entityType: ResourceType | GroupType | undefined;
        if (data.__typename === "Resource") {
          label = resourceTypeInfoByType[data.resourceType].fullName;
          entityType = data.resourceType;
        } else {
          label = groupTypeInfoByType[data.groupType].name;
          entityType = data.groupType;
        }
        if (entityType) {
          return (
            <span
              className={sprinkles({
                display: "flex",
                gap: "sm",
                alignItems: "center",
              })}
            >
              <EntityIcon type={entityType} />
              {label}
            </span>
          );
        }
        return label;
      },
    },
    {
      id: "admin",
      label: "Admin",
      width: 100,
    },
    {
      id: "visibility",
      label: "Visibility",
      width: 100,
      customCellRenderer: ({ data, hiddenFromEndUser }) => {
        const visibility =
          data.visibility === Visibility.Global
            ? "Global"
            : data.visibilityGroups.length === 0
            ? "Admin only"
            : `${data.visibilityGroups.length} ${pluralize(
                "group",
                data.visibilityGroups.length
              )}`;

        if (isOktaApp && hasOktaAlias && hiddenFromEndUser) {
          return (
            <Tooltip tooltipText="This resource is hidden in the catalog.">
              <span
                className={sprinkles({
                  color: "red600V3",
                  display: "flex",
                  gap: "xs",
                  alignItems: "center",
                })}
              >
                {visibility}
                <Icon name="eye-off" color="red600V3" size="xs" />
              </span>
            </Tooltip>
          );
        }
        return visibility;
      },
    },
    {
      id: "numUsers",
      label: "Users",
      width: 100,
      customCellRenderer: (row) => {
        return pluralize("User", row.numUsers, true);
      },
      sortable: false,
    },
    {
      id: "description",
      label: "Description",
      sortable: false,
      width: 200,
      customCellRenderer: (row) => {
        return <div className={styles.descriptionField}>{row.description}</div>;
      },
    },
    {
      id: "createdAt",
      label: "Created",
      sortable: true,
      width: 100,
      customCellRenderer: (row) => {
        return moment(row.createdAt).fromNow();
      },
    },
  ];

  if (isOktaApp && hasOktaAlias) {
    const aliasCol: Header<ResourceRow> = {
      id: "alias",
      label: "Catalog Name",
      customCellRenderer: ({ alias }) => {
        return (
          <div
            className={sprinkles({
              display: "flex",
              flexDirection: "column",
              gap: "md",
            })}
          >
            {alias}
          </div>
        );
      },
    };
    columns.splice(1, 0, aliasCol);

    const hideCol: Header<ResourceRow> = {
      id: "hiddenFromEndUser",
      label: "",
      width: 30,
      customCellRenderer: ({ id, hiddenFromEndUser }) => {
        return (
          <div className={styles.cta}>
            <ButtonV3
              size="xs"
              leftIconName={hiddenFromEndUser ? "eye" : "eye-off"}
              type="defaultBorderless"
              onClick={(e) => {
                e.stopPropagation();
                setSelectedGroupId(id);
                setShowConfirmHideModal((prev) => !prev);
              }}
              round
            />
          </div>
        );
      },
    };
    columns.push(hideCol);

    oktaAppDefaultActions.push({
      label: "Add Resources",
      type: "mainSecondary",
      onClick: () => {
        transitionTo({
          pathname: `/resources/${props.appId}/add-resources`,
        });
      },
      iconName: "plus",
    });
  }

  if (loading && !data && !previousData) {
    return (
      <div className={sprinkles({ marginTop: "xl" })}>
        <Skeleton height="40px" width="200px" />
        <ColumnListItemsSkeleton />
      </div>
    );
  }

  if (error) {
    return (
      <div className={sprinkles({ marginTop: "xl" })}>
        <UnexpectedErrorPage error={error} />
      </div>
    );
  }

  const getBulkActions = (): PropsFor<
    typeof TableHeader
  >["bulkRightActions"] => {
    const actions: PropsFor<typeof TableHeader>["bulkRightActions"] = [];
    let shouldShowEditButton = false;
    let editButtonEnabled = true;
    let showRequestButton = false;
    let requestButtonEnabled = true;
    for (let selectedItemId of selectedItemIds) {
      const item = itemsById[selectedItemId];
      if (item?.authorizedActions?.includes(AuthorizedActionManage)) {
        shouldShowEditButton = true;
      } else {
        editButtonEnabled = false;
      }
      if (item?.isRequestable) {
        showRequestButton = true;
      } else {
        requestButtonEnabled = false;
      }
    }
    if (authState.user?.isAdmin) {
      if (!hasOktaAlias || !isOktaApp) {
        actions.push({
          label: "Remove from Opal",
          type: "danger",
          iconName: "trash",
          onClick: () => setShowDeleteModal(true),
        });
      }
      actions.push({
        label: "Export Users",
        type: "mainSecondary",
        iconName: "users-right",
        onClick: handleExportUserClick,
      });
    }
    if (isOktaApp && hasOktaAlias) {
      actions.push({
        label: "Edit Catalog Name",
        type: "mainSecondary",
        iconName: "refresh",
        onClick: () => {
          setShowEditAliasModal((prev) => !prev);
        },
      });
      actions.unshift({
        label: "Remove",
        type: "danger",
        iconName: "trash",
        onClick: () => {
          setShowRemoveModal((prev) => !prev);
        },
      });
    }
    if (shouldShowEditButton) {
      actions.push({
        label: "Edit",
        type: "mainSecondary",
        iconName: "edit",
        disabledTooltip: editButtonEnabled
          ? undefined
          : "You do not have permission to edit some selected resources",
        onClick: () => {
          if (editButtonEnabled) {
            history.push(
              makeBulkEditSelectedItemsUrl({
                resourceIds: selectedResourceIds,
                groupIds: selectedGroupIds,
              })
            );
          }
        },
      });
    }
    if (showRequestButton) {
      actions.push({
        label: "Request",
        type: "main",
        iconName: "raised-hand",
        disabledTooltip: requestButtonEnabled
          ? undefined
          : "Some selected resources are not requestable",
        onClick: () => {
          let selectedItems = selectedItemIds.map((id) => itemsById[id]);
          if (hasEndUserXP) {
            transitionToAccessRequest({
              ...formatRequestDataForItems(
                selectedItems.map((item) => ({
                  entityId: item.id,
                  entityType:
                    item.__typename === "Resource"
                      ? EntityType.Resource
                      : EntityType.Group,
                }))
              ),
              appId: props.appId ? undefined : appId,
            });
          } else {
            setShowRequestModal(true);
            selectItems(selectedItems);
          }
        },
      });
    }

    return actions;
  };

  const itemTypes = app?.itemTypes ?? previousApp?.itemTypes ?? [];
  const selectedType = itemTypes.find((t) => t.itemType === selectedItemType);

  const numResources =
    showListResults && !loading
      ? items.length
      : props.totalResources || items.length;
  const hasExpandableRows = rows.some(
    (row) => itemsWithChildren.has(row.id) || Boolean(itemsByParentId[row.id])
  );
  const allSelected = rows.every((row) => getAllChecked(row.id));

  return (
    <>
      <div
        className={sprinkles({
          display: "flex",
          flexDirection: "column",
          height: "100%",
        })}
      >
        <TableFilters>
          <TableFilters.Left>
            <div className={styles.searchInput}>
              <Input
                leftIconName="search"
                type="search"
                style="search"
                placeholder="Search by name"
                value={searchQuery}
                onChange={setSearchQuery}
              />
            </div>
            <ButtonGroup
              buttons={[
                {
                  label: "All",
                  onClick: () => setAccessOption(AccessOption.All),
                  selected: accessOption === AccessOption.All,
                },
                {
                  label: "My Access",
                  onClick: () => setAccessOption(AccessOption.Mine),
                  selected: accessOption === AccessOption.Mine,
                },
              ]}
            />
            {props.resourceType != ResourceType.OktaApp && (
              <div className={styles.resourceTypeFilter}>
                <Select
                  options={itemTypes}
                  onChange={(itemTypeOption) =>
                    setSelectedItemType(itemTypeOption?.itemType || null)
                  }
                  getOptionLabel={(option) => option.displayText}
                  placeholder="Filter by type"
                  value={selectedType}
                  disableBuiltInFiltering
                  clearable
                  size="sm"
                />
              </div>
            )}
          </TableFilters.Left>
        </TableFilters>

        <TableHeader
          entityType={EntityType.Resource}
          totalNumRows={Math.max(numResources, totalNumItems ?? 0)}
          selectedNumRows={selectedItemIds.length}
          bulkRightActions={getBulkActions()}
          loading={loading}
          defaultRightActions={oktaAppDefaultActions}
        />

        {loading ? (
          <ColumnListItemsSkeleton />
        ) : (
          <>
            <Table
              rows={rows}
              columns={columns}
              getRowId={(row) => row.id}
              totalNumRows={cursor ? Number.MAX_SAFE_INTEGER : numResources}
              loadingRows={loading}
              emptyState={{
                title: "No resources",
                subtitle: 'Try searching or use the "My Access" tab.',
              }}
              onRowClick={(row, event) => {
                const searchParams = new URLSearchParams();
                const id = props.appId ?? appId;
                if (
                  props.resourceType == ResourceType.OktaApp &&
                  row.type === GroupType.OktaGroup
                ) {
                  // Save Okta app id to the URL so the next view has context
                  // to know the Okta group is being viewed as part of an Okta app,
                  // and not just a standalone group.
                  searchParams.set(OKTA_APP_ID_URL_KEY, id);
                }
                transitionTo(
                  {
                    pathname: getPathnameForRow(row),
                    search: searchParams.toString(),
                  },
                  event
                );
              }}
              getRowCanExpand={(row) =>
                itemsWithChildren.has(row.id) ||
                Boolean(itemsByParentId[row.id])
              }
              getChildRows={
                hasExpandableRows
                  ? (row) => itemsByParentId[row.id]?.map(buildRow)
                  : undefined
              }
              checkedRowIds={new Set(selectedItemIds)}
              onCheckedRowsChange={(checkedRowIds, checked) => {
                if (checked) {
                  setSelectedItemIds((prev) => [...prev, ...checkedRowIds]);
                  return;
                } else {
                  setSelectedItemIds((prev) =>
                    prev.filter((id) => !checkedRowIds.includes(id))
                  );
                }
              }}
              selectAllChecked={allSelected}
              onSelectAll={(checked) => {
                if (checked) {
                  const allIds = getAllItemIds();
                  setSelectedItemIds(allIds);
                } else {
                  setSelectedItemIds([]);
                }
              }}
              onLoadMoreRows={loadMoreRows}
              defaultSortBy="name"
            />
          </>
        )}
      </div>
      <BulkDeleteModal
        isOpen={showDeleteModal}
        onCancel={() => {
          setShowDeleteModal(false);
        }}
        onComplete={() => {
          setShowDeleteModal(false);
          setSelectedItemIds([]);
        }}
        resourceIds={selectedResourceIds}
        groupIds={selectedGroupIds}
      />
      <BulkRequestModal
        isOpen={showRequestModal}
        onClose={() => setShowRequestModal(false)}
      />
      {showEditAliasModal && (
        <BulkEditAliasModal
          isOpen={showEditAliasModal}
          onClose={() => setShowEditAliasModal(false)}
          idpGroupMappings={selectedIdpGroupMappings}
        />
      )}
      {setShowConfirmHideModal && (
        <BulkHideFromEndUserModal
          isOpen={showConfirmHideModal}
          onClose={() => setShowConfirmHideModal(false)}
          groupId={selectedGroupId}
        />
      )}
      <BulkRemoveMappingModal
        isOpen={showRemoveModal}
        onCancel={() => setShowRemoveModal(false)}
        onComplete={() => {
          setShowRemoveModal(false);
          setSelectedItemIds([]);
        }}
        groupIds={selectedGroupIds}
        resourceId={resourceId}
      />
    </>
  );
};

const getPathnameForRow = (row: ResourceRow) => {
  return row.data.__typename === "Resource"
    ? `/resources/${row.id}`
    : `/groups/${row.id}`;
};

export default AppResourcesTable;
