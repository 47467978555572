import {
  ConnectionType,
  EntityType,
  GroupType,
  Maybe,
  RemoveGroupTagInput,
  ServiceType,
  TagFragment,
  useRemoveGroupTagsMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { GroupTableCellResourceLabel } from "components/label/GroupTableCellResourceLabel";
import ConnectionLabel from "components/label/item_labels/ConnectionLabel";
import ServiceTypeLabel from "components/label/ServiceTypeLabel";
import { useToast } from "components/toast/Toast";
import { Modal } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import moment from "moment";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";

type TagGroupsTableProps = {
  tag: TagFragment;
};

interface GroupRow {
  id: string;
  group?: {
    id?: string;
    name?: string;
    iconUrl?: string | null;
    groupType: GroupType;
    isOnCallSynced: boolean;
  } | null;
  connection?: {
    name: string;
    connectionType?: ConnectionType;
  } | null;
  connectionId?: string;
  source: Maybe<ServiceType>;
  createdAt: string;
}

const GROUPS_COLUMNS: Header<GroupRow>[] = [
  {
    id: "group",
    label: "Name",
    sortable: false,
    customCellRenderer: ({ group }) => (
      <GroupTableCellResourceLabel
        groupName={group?.name}
        groupType={group?.groupType}
        isOnCall={group?.isOnCallSynced}
      />
    ),
  },
  {
    id: "connection",
    label: "App",
    sortable: false,
    customCellRenderer: ({ connection, connectionId }) => (
      <ConnectionLabel
        text={connection?.name}
        connectionId={connectionId}
        connectionType={connection?.connectionType}
        pointerCursor={true}
      />
    ),
  },
  {
    id: "source",
    label: "Tag Source",
    sortable: false,
    customCellRenderer: ({ source }) => (
      <ServiceTypeLabel serviceType={source} />
    ),
  },
  {
    id: "createdAt",
    label: "Created",
    sortable: false,
    customCellRenderer: (row) => moment(new Date(row.createdAt)).fromNow(),
  },
];

const TagGroupsTable = ({ tag }: TagGroupsTableProps) => {
  const transitionTo = useTransitionTo();
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [showRemoveGroupsModal, setShowRemoveGroupsModal] = useState(false);
  const { authState } = useContext(AuthContext);
  const isAdmin = authState.user?.isAdmin;
  const history = useHistory();

  const rows: GroupRow[] =
    tag?.tagGroups.map(({ groupId, group, source, createdAt }) => {
      return {
        id: groupId,
        group: group,
        isOnCallSynced: group?.isOnCallSynced,
        connection: group?.connection,
        connectionId: group?.connectionId,
        source: source,
        createdAt: createdAt,
      };
    }) || [];

  const allSelected = rows.every((row) => selectedItemIds.includes(row?.id));

  const table = (
    <Table
      rows={rows}
      getRowId={(row) => row.id}
      columns={GROUPS_COLUMNS}
      totalNumRows={rows.length}
      defaultSortBy="group"
      emptyState={{
        title: "No Groups",
      }}
      onRowClick={(row, event) => {
        transitionTo(
          {
            pathname: `/groups/${row.group?.id}`,
          },
          event
        );
      }}
      checkedRowIds={new Set(selectedItemIds)}
      onCheckedRowsChange={(checkedRowIds, checked) => {
        if (checked) {
          setSelectedItemIds((prev) => [...prev, ...checkedRowIds]);
          return;
        } else {
          setSelectedItemIds((prev) =>
            prev.filter((id) => !checkedRowIds.includes(id))
          );
        }
      }}
      selectAllChecked={allSelected}
      onSelectAll={(checked) => {
        if (checked) {
          const allIds = rows.map((row) => row.id);
          setSelectedItemIds(allIds);
        } else {
          setSelectedItemIds([]);
        }
      }}
    />
  );

  return (
    <div>
      <TableHeader
        entityType={EntityType.Group}
        totalNumRows={rows.length}
        selectedNumRows={selectedItemIds.length}
        defaultRightActions={
          isAdmin
            ? [
                {
                  label: "Add Groups",
                  type: "mainSecondary",
                  iconName: "plus",
                  onClick: () => history.push(`/tags/${tag.id}/add-groups`),
                },
              ]
            : []
        }
        bulkRightActions={[
          {
            label: "Remove",
            type: "danger",
            iconName: "trash",
            onClick: () => setShowRemoveGroupsModal((prev) => !prev),
          },
        ]}
      />
      {table}
      {showRemoveGroupsModal && (
        <RemoveGroupsFromTagModal
          key={`remove-groups-from-tag-modal`}
          idsToRemove={selectedItemIds}
          setShowModal={setShowRemoveGroupsModal}
          setSelectedItemIds={setSelectedItemIds}
          tagId={tag.id}
        />
      )}
    </div>
  );
};

type RemoveGroupsFromTagModalProps = {
  idsToRemove: string[];
  setShowModal: (show: boolean) => void;
  setSelectedItemIds: (ids: string[]) => void;
  tagId: string;
};

const RemoveGroupsFromTagModal = ({
  idsToRemove,
  setShowModal,
  setSelectedItemIds,
  tagId,
}: RemoveGroupsFromTagModalProps) => {
  const [removeTagGroups, { loading }] = useRemoveGroupTagsMutation();
  const { displaySuccessToast, displayErrorToast } = useToast();

  const removeSelectedGroups = async () => {
    const groupTagsToRemove: RemoveGroupTagInput[] = idsToRemove.map(
      (groupId) => ({
        groupId: groupId,
        tagId: tagId,
      })
    );

    try {
      const { data } = await removeTagGroups({
        variables: {
          input: {
            groupTags: groupTagsToRemove,
          },
        },
        refetchQueries: ["GroupTag", "Tag"],
      });

      switch (data?.removeGroupTags.__typename) {
        case "RemoveGroupTagsResult": {
          if (data.removeGroupTags.success) {
            displaySuccessToast(
              `Success: removed tag from ${pluralize(
                "group",
                idsToRemove.length,
                true
              )}`
            );
            setSelectedItemIds([]);
          } else {
            logError(new Error(`failed to remove tag from selected groups`));
            displayErrorToast(
              "Error: failed to remove tag from selected groups"
            );
          }
          break;
        }
        default:
          logError(new Error(`failed to remove tag from selected groups`));
          displayErrorToast("Error: failed to remove tag from selected groups");
      }
    } catch (error) {
      logError(error, `failed to remove group tag from selected groups`);
      displayErrorToast("Error: failed to remove tag from selected groups");
    }

    setShowModal(false);
  };

  return (
    <Modal isOpen onClose={() => setShowModal(false)} title="Remove Tag Groups">
      <Modal.Body>
        Are you sure you want to remove{" "}
        {pluralize("group", idsToRemove.length, true)} from this tag?
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Remove"
        onPrimaryButtonClick={() => {
          removeSelectedGroups();
          setShowModal(false);
        }}
        primaryButtonTypeV3="danger"
        primaryButtonLoading={loading}
      />
    </Modal>
  );
};

export default TagGroupsTable;
