import { ButtonV3, Icon, Skeleton } from "components/ui";
import { IconName } from "components/ui/icon/Icon";
import React from "react";

import * as styles from "./AccessReviewStatV3.css";

const AccessReviewStatLoading = () => {
  return (
    <div className={styles.stat}>
      <Skeleton width="100%" />
    </div>
  );
};

interface Props {
  type: "resource" | "group" | "app";
  num?: number;
  icon: IconName;
  onClick: () => void;
}

const AccessReviewStatV3 = ({ type, num, icon, onClick }: Props) => {
  let title = "";
  switch (type) {
    case "resource":
      title = "Resources";
      break;
    case "group":
      title = "Groups";
      break;
    case "app":
      title = "Apps";
      break;
  }

  if (num === undefined) {
    return <AccessReviewStatLoading />;
  }

  return (
    <div className={styles.stat}>
      <div className={styles.statIcon}>
        <Icon data={{ type: "name", icon }} size="lg" />
      </div>
      <div className={styles.statNumber}>{num}</div>
      <div className={styles.statTitle}>{title}</div>
      <div className={styles.lineBreak}></div>
      {num > 0 ? (
        <ButtonV3
          size="sm"
          label="View all"
          type="mainSecondary"
          onClick={onClick}
        />
      ) : null}
    </div>
  );
};

export default AccessReviewStatV3;
