import "app/App.scss";

import MomentUtils from "@date-io/moment";
import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { ApiContextProvider } from "api/ApiContext";
import { Aldwin } from "components/aldwin/Aldwin";
import AuthContext, { AuthContextProvider } from "components/auth/AuthContext";
import SessionValidListener from "components/auth/SessionValidListener";
import { MUI_OVERRIDE_COLORS } from "components/mui/MUIOverrides";
import { CustomToastContainer } from "components/toast/Toast";
import { ToastUrlParser } from "components/toast/ToastUrlParser";
import React from "react";
import { AccessReviewContextProvider } from "views/access_reviews/AccessReviewContext";
import { AppContextProvider } from "views/app/AppContext";
import AuthenticatedViews from "views/AuthenticatedViews";
import { OrgContextProvider } from "views/settings/OrgContext";
import UnauthenticatedViews from "views/UnauthenticatedViews";
import UninitializedAuthViews from "views/UninitializedAuthViews";

const theme = createMuiTheme({
  typography: {
    fontFamily: "inherit",
  },
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: MUI_OVERRIDE_COLORS.primaryBlue,
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: MUI_OVERRIDE_COLORS.primaryBlue,
      // dark: will be calculated from palette.secondary.main,
    },
    text: {
      disabled: MUI_OVERRIDE_COLORS.primaryLightGray,
    },
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: 15,
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: MUI_OVERRIDE_COLORS.primaryLightBlue,
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          borderRadius: 4,
          "&:focus": {
            borderRadius: 4,
            backgroundColor: "white",
          },
        },
      },
    },
  },
});

const App = () => {
  return (
    <ThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <ApiContextProvider>
          <AppContextProvider>
            <OrgContextProvider>
              <AccessReviewContextProvider>
                <AuthContextProvider>
                  <AuthContext.Consumer>
                    {(value) =>
                      value.authState.isInitialized ? (
                        value.authState.isAuthenticated ? (
                          <AuthenticatedViews />
                        ) : (
                          <UnauthenticatedViews />
                        )
                      ) : (
                        <UninitializedAuthViews />
                      )
                    }
                  </AuthContext.Consumer>
                  <Aldwin />
                  <SessionValidListener />
                </AuthContextProvider>
              </AccessReviewContextProvider>
            </OrgContextProvider>
          </AppContextProvider>
        </ApiContextProvider>
        <CustomToastContainer />
        <ToastUrlParser />
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
};

export default App;
