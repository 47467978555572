import { getModifiedErrorMessage } from "api/ApiContext";
import {
  ConnectionForResourceImportFragment,
  ImportCustomResourceInfo,
  ImportCustomResourcesDryRunResult,
  ImportCustomResourcesInput,
  useImportCustomResourcesDryRunMutation,
  useImportCustomResourcesMutation,
  useImportResourcesBodyQuery,
  Visibility,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { TextHoverTooltip } from "components/more_info/MoreInfo";
import { useToast } from "components/toast/Toast";
import { Button, FileUpload } from "components/ui";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { checkRequiredColumns, parseCSV } from "utils/csv";
import { logError, logWarning } from "utils/logging";

import styles from "./ResourceUsersUploadCSVBody.module.scss";

interface Props {
  connectionId: string;
}

/**
 * Form for uploading resource users as a CSV, and populating the CSV data into data structures
 * as per `setRolesByUserId` and `setUploadCsvUserIds`.
 *
 * Unlike `ResourceUsersUploadCSVButton`, this lets you upload resource users for multiple resources
 * at the same time.
 */
export const ResourceUsersUploadCSV = (props: Props) => {
  const logEvent = useLogEvent();
  const history = useHistory();
  let { data, error, loading } = useImportResourcesBodyQuery({
    variables: {
      input: {
        id: props.connectionId || "",
      },
    },
  });

  const [uploadLoading, setUploadLoading] = useState<boolean>(false);
  const [filename, setFilename] = useState<string | null>(null);
  const [
    importCustomResourcesInput,
    setImportCustomResourcesInput,
  ] = useState<ImportCustomResourcesInput | null>(null);
  const [
    importCustomResourcesDryRunResult,
    setImportCustomResourcesDryRunResult,
  ] = useState<ImportCustomResourcesDryRunResult | null>(null);
  const [errorMessages, setErrorMessages] = useState<string[]>([]);
  const [
    connection,
    setConnection,
  ] = useState<ConnectionForResourceImportFragment>();

  const { displaySuccessToast, displayErrorToast } = useToast();

  const [
    importCustomResourcesDryRun,
    { loading: dryRunLoading },
  ] = useImportCustomResourcesDryRunMutation();
  const [
    importCustomResources,
    { loading: submitLoading },
  ] = useImportCustomResourcesMutation();

  React.useEffect(() => {
    if (data) {
      switch (data.connection.__typename) {
        case "ConnectionResult":
          setConnection(data.connection.connection);
          break;
        default:
          logError(new Error("failed to get connection"));
          displayErrorToast("Error: failed to get connection");
      }
    } else if (error) {
      logError(error, "failed to get connection");
    }
  }, [data, error, displayErrorToast]);

  if (loading || connection === undefined) {
    return null;
  }

  const resetModal = () => {
    setFilename(null);
    setImportCustomResourcesInput(null);
    setImportCustomResourcesDryRunResult(null);
    setErrorMessages([]);
  };

  const parseUserUploadCSVRows = (
    rows: Record<string, string>[]
  ): {
    uniqUserEmails: string[];
    uniqResourceInfos: ImportCustomResourceInfo[];
  } => {
    // Parse resource names, user emails, and resource users to import.
    // Set the inputs to `ImportCustomResourcesDryRun` using these values.

    const uniqUserEmails: string[] = [];
    const seenResources: string[] = [];
    const uniqResourceInfos: ImportCustomResourceInfo[] = [];
    const resourceNameToResourceInfo: Record<
      string,
      ImportCustomResourceInfo
    > = {};

    rows.forEach((row: Record<string, string>) => {
      const email = row["email"].toLowerCase();
      const resource = row["resource"];
      const description = row["description"] ? row["description"] : null;
      if (!uniqUserEmails.includes(email)) {
        uniqUserEmails.push(email);
      }

      if (!seenResources.includes(resource)) {
        const resourceInfo = {
          name: resource,
          description: description,
          usersWithAccess: [],
        };
        uniqResourceInfos.push(resourceInfo);
        resourceNameToResourceInfo[resource] = resourceInfo;
        seenResources.push(resource);
      }
      resourceNameToResourceInfo[resource].usersWithAccess.push({
        email: email,
        cellContent: "Y", // Only Y is permitted at the moment
      });
    });

    return { uniqUserEmails, uniqResourceInfos };
  };

  const onUploadCSV = (file: File) => {
    setUploadLoading(true);
    setFilename(file.name);
    setImportCustomResourcesInput(null);
    setImportCustomResourcesDryRunResult(null);
    setErrorMessages([]);

    // NOTE: please do not use this approach as a template for CSV uploads. There are two issues with it:
    //       1. From a UX perspective, we should be giving line by line feedback on any validation issues
    //          in uploaded CSV files, e.g. line 103: duplicate user / resource email. The pre-parse in the FE
    //          into an object prior to uploading makes this impossible to do.
    //       2. It doesn't make a ton of sense to partially validate the CSV on the client side and then repeat
    //          some / all of the validation on the backend. Similarly, the pre-parse means that some backend
    //          validation is not possible or doesn't make sense. Doing simple validation, e.g. CSV is empty seems
    //          reasonable, but we should put the validation logic on the backend.
    parseCSV(file)
      .then(async (rows) => {
        if (rows.length === 0) {
          setErrorMessages([`Uploaded CSV is empty`]);
          setUploadLoading(false);
          return;
        }
        checkRequiredColumns(rows[0], ["email", "resource", "description"]);
        const { uniqUserEmails, uniqResourceInfos } = parseUserUploadCSVRows(
          rows
        );

        const importCustomResourcesInput = {
          connectionId: connection.id,
          adminOwnerId: connection.adminOwnerId ?? "",
          visibility: Visibility.Global,
          resources: uniqResourceInfos,
          userEmails: uniqUserEmails,
          filename: file.name,
        };
        setImportCustomResourcesInput(importCustomResourcesInput);

        try {
          const { data } = await importCustomResourcesDryRun({
            variables: {
              input: importCustomResourcesInput,
            },
          });
          switch (data?.importCustomResourcesDryRun.__typename) {
            case "ImportCustomResourcesDryRunResult":
              setImportCustomResourcesDryRunResult(
                data.importCustomResourcesDryRun
              );
              break;
            case "ImportCustomResourcesInputValidationError":
              logWarning(
                new Error(
                  `${
                    data.importCustomResourcesDryRun.message
                  }: ${data.importCustomResourcesDryRun.reasons.join("; ")}`
                )
              );
              setErrorMessages(data.importCustomResourcesDryRun.reasons);
              break;
            default:
              logError(new Error(`failed to import custom resources`));
              setErrorMessages([`Error: failed to import custom resources`]);
          }
        } catch (e) {
          logError(e, "failed to import custom resources");
          if (e instanceof Error) {
            setErrorMessages([
              getModifiedErrorMessage(
                "Error: failed to import custom resources",
                e
              ),
            ]);
          }
        }
        setUploadLoading(false);
      })
      .catch((e) => {
        setErrorMessages([String(e)]);
        setUploadLoading(false);
      });
  };

  // Let's be extra sure that we're ready to submit.
  const submitReady =
    !uploadLoading &&
    !dryRunLoading &&
    importCustomResourcesInput !== null &&
    importCustomResourcesDryRunResult !== null &&
    errorMessages.length === 0;

  const onSubmit = async () => {
    if (!importCustomResourcesInput) {
      return;
    }

    logEvent({
      name: "apps_create_resource_end",
      properties: {
        type: "csv",
        connectionType: connection.connectionType,
      },
    });

    try {
      const { data } = await importCustomResources({
        variables: {
          input: importCustomResourcesInput,
        },
      });

      switch (data?.importCustomResources.__typename) {
        case "ImportCustomResourcesResult":
          resetModal();
          displaySuccessToast(
            `Import submitted, we'll notify you once it completes.`
          );
          break;
        case "ImportCustomResourcesInputValidationError":
          logWarning(
            new Error(
              `${
                data.importCustomResources.message
              }: ${data.importCustomResources.reasons.join("; ")}`
            )
          );
          setErrorMessages(data.importCustomResources.reasons);
          break;
        default:
          logError(new Error(`failed to import custom resources`));
          setErrorMessages([`Error: failed to import custom resources`]);
      }
      history.goBack();
    } catch (e) {
      logError(e, "failed to import custom resources");
      if (e instanceof Error) {
        setErrorMessages([
          getModifiedErrorMessage(
            "Error: failed to import custom resources",
            e
          ),
        ]);
      }
    }
  };

  return (
    <>
      <p>
        To bulk import resources and{" "}
        <TextHoverTooltip
          tooltipText={
            "A resource user describes a specific user's permission to access a specific resource."
          }
        >
          resource users
        </TextHoverTooltip>
        , please upload a CSV with{" "}
        <TextHoverTooltip
          tooltipText={
            <ul>
              <li>
                The header row should contain <i>email</i>, <i>resource</i>, and{" "}
                <i>description</i>.
              </li>
              <li>
                The first column should contain a list of user emails. All
                emails must correspond to an existing user in Opal.
              </li>
              <li>
                The second column should contain the resource name that the user
                has access to. There should be a new row for each resource a
                user has access to.
              </li>
              <li>
                If a description is present for a resource on its <b>first</b>{" "}
                appearance on the CSV, the resource will have its description
                updated.
              </li>
            </ul>
          }
        >
          the following format
        </TextHoverTooltip>
        :
      </p>

      <UploadedCSVExample />
      <FileUpload
        renderButton={(onClick) => (
          <Button
            onClick={onClick}
            label={filename || "Upload CSV"}
            loading={uploadLoading || dryRunLoading}
            borderless
          />
        )}
        handleUpload={onUploadCSV}
        accept={[".csv"]}
      />

      {errorMessages.length !== 0 && (
        <ModalErrorMessage
          errorMessage={
            errorMessages.length > 1 ? (
              <ul>
                {errorMessages.map((message, index) => (
                  <li key={index}>{message}</li>
                ))}
              </ul>
            ) : (
              <p>{errorMessages[0]}</p>
            )
          }
        />
      )}

      {submitReady &&
        importCustomResourcesInput &&
        importCustomResourcesDryRunResult && (
          <UploadedCSVStats
            userEmails={importCustomResourcesInput.userEmails}
            resourcesToCreate={
              importCustomResourcesDryRunResult.resourcesToCreate
            }
            existingResources={
              importCustomResourcesDryRunResult.existingResources
            }
            importResourceInfos={importCustomResourcesInput.resources}
          />
        )}
      <div
        className={sprinkles({
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        })}
      >
        <Button
          label="Create"
          type="primary"
          disabled={submitLoading || !submitReady}
          loading={submitLoading}
          onClick={onSubmit}
        />
      </div>
    </>
  );
};

const UploadedCSVExample = () => {
  const { authState } = useContext(AuthContext);

  let domain = "@yourorg.com";
  const user = authState.user?.user;
  if (user) {
    domain = user.email.substring(user.email.indexOf("@"));
  }

  return (
    <table className={styles.exampleCsv}>
      <thead>
        <th>email</th>
        <th>resource</th>
        <th>description</th>
      </thead>
      <tr>
        <td>user1{domain}</td>
        <td>Resource1</td>
        <td>Description for Resource1</td>
      </tr>
      <tr>
        <td>user2{domain}</td>
        <td>Resource1</td>
        <td />
      </tr>
      <tr>
        <td>user2{domain}</td>
        <td>Resource2</td>
        <td>Description for Resource2</td>
      </tr>
      <tr>
        <td>user3{domain}</td>
        <td>Resource3</td>
        <td />
      </tr>
      <tr>
        <td>...</td>
        <td>...</td>
        <td />
      </tr>
    </table>
  );
};

type UploadedCSVStatsProps = {
  userEmails: string[];
  resourcesToCreate: string[];
  existingResources: string[];
  importResourceInfos: ImportCustomResourceInfo[];
};

const UploadedCSVStats = (props: UploadedCSVStatsProps) => {
  const {
    userEmails,
    resourcesToCreate,
    existingResources,
    importResourceInfos,
  } = props;

  const numUsers = userEmails.length;
  const numResources = importResourceInfos.length;

  let resourcesToCreateSorted = [...resourcesToCreate];
  let resourcesExistingSorted = [...existingResources];
  resourcesToCreateSorted.sort();
  resourcesExistingSorted.sort();

  const resourceNameToNumResourceUsers: Record<string, number> = {};
  importResourceInfos.forEach((resourceInfo) => {
    resourceNameToNumResourceUsers[resourceInfo.name] =
      resourceInfo.usersWithAccess.length;
  });

  return (
    <div className={styles.uploadedCsvStats}>
      <p>
        {`Your upload specifies permissions for how ${numUsers} ${pluralize(
          "user",
          numUsers
        )} are granted access to ${numResources} ${pluralize(
          "resource",
          numResources
        )}.`}
      </p>

      {resourcesToCreateSorted.length > 0 && (
        <UploadedCSVStatsSection
          message={"do not currently exist, and will be created"}
          resources={resourcesToCreateSorted}
          resourceNameToNumResourceUsers={resourceNameToNumResourceUsers}
        />
      )}
      {resourcesExistingSorted.length > 0 && (
        <UploadedCSVStatsSection
          message={"already exist and will have their permissions edited"}
          resources={resourcesExistingSorted}
          resourceNameToNumResourceUsers={resourceNameToNumResourceUsers}
        />
      )}
    </div>
  );
};

type UploadedCSVStatsSectionProps = {
  message: string;
  resources: string[];
  resourceNameToNumResourceUsers: Record<string, number>;
};

const UploadedCSVStatsSection = (props: UploadedCSVStatsSectionProps) => {
  const { message, resources, resourceNameToNumResourceUsers } = props;
  return (
    <p>
      {`${resources.length} ${pluralize(
        "resource",
        resources.length
      )} ${message}:`}
      <ul>
        {resources.map((resource, index) => {
          const numResourceUsers = resourceNameToNumResourceUsers[resource];
          return (
            <li key={index}>
              {resource}: {numResourceUsers}{" "}
              {pluralize("user", numResourceUsers)} with access
            </li>
          );
        })}
      </ul>
    </p>
  );
};

export default ResourceUsersUploadCSV;
