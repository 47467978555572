import { Popper, PopperPlacementType } from "@material-ui/core";
import clsx from "clsx";
import menuStyles from "components/overlay/Overlay.module.scss";
import React, { ReactElement, useRef } from "react";
import OutsideClickHandler from "react-outside-click-handler";

export enum Alignment {
  Left,
  Right,
  Center,
}

type OverlayProps = {
  showOverlay: boolean;
  onClose: () => void;
  content: ReactElement;
  outerContent?: ReactElement;
  button?: ReactElement;
  alignment: Alignment;
  topOffset: number;
  rightOffset?: number;
  leftOffset?: number;
};

const Overlay = (props: OverlayProps) => {
  let leftOffset = 0;
  let rightOffset = 0;

  let placement: PopperPlacementType;
  switch (props.alignment) {
    case Alignment.Left:
      placement = "bottom-start";
      leftOffset = props.leftOffset ?? 0;
      break;
    case Alignment.Center:
      placement = "bottom";
      break;
    case Alignment.Right:
      placement = "bottom-end";
      rightOffset = props.rightOffset ?? 0;
      break;
  }

  const margin = `${props.topOffset}px ${rightOffset}px 0 ${leftOffset}px`;

  const buttonRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <div className={menuStyles.buttonContainer} ref={buttonRef}>
        {props.button}
      </div>
      <Popper
        placement={placement}
        open={props.showOverlay}
        anchorEl={buttonRef.current}
        className={menuStyles.overlayPopper}
      >
        <OutsideClickHandler
          onOutsideClick={(e) => {
            if (
              e.target instanceof Element &&
              buttonRef.current?.contains(e.target) === true
            ) {
              // Do not handle this if it is a click on our button
              return;
            }
            props.onClose();
          }}
        >
          <div
            className={clsx({
              [menuStyles.overlay]: true,
            })}
            style={{ margin }}
          >
            {props.content}
          </div>
        </OutsideClickHandler>
      </Popper>
    </>
  );
};

export default Overlay;
