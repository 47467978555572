import {
  AccessReviewConnectionPreviewFragment,
  AccessReviewFragment,
  AccessReviewSummaryStatus,
  EntityType,
  useAccessReviewConnectionsQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import NavigationContext from "components/layout/NavigationContextProvider";
import { OnlyMyReviewsFilter } from "components/only_mine_filter/OnlyMineFilter";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import { Input } from "components/ui";
import React, { useContext } from "react";
import { logError } from "utils/logging";
import { filterSearchResults } from "utils/search/filter";
import AccessReviewProgressBar from "views/access_reviews/AccessReviewProgressBar";
import styles from "views/access_reviews/common/AccessReviewTableStyles.module.scss";
import { isReviewer } from "views/access_reviews/common/Common";
import AccessReviewConnectionsTable from "views/access_reviews/connections/AccessReviewConnectionsTable";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ViewSkeleton from "views/loading/ViewSkeleton";

type AccessReviewConnectionsProps = {
  accessReview: AccessReviewFragment;
};

const AccessReviewConnections = (props: AccessReviewConnectionsProps) => {
  const { navigationState } = useContext(NavigationContext);
  const { authState } = useContext(AuthContext);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const { accessReview } = props;

  const { data, error, loading } = useAccessReviewConnectionsQuery({
    variables: {
      input: {
        id: props.accessReview.id,
      },
    },
  });

  let accessReviewConnections: AccessReviewConnectionPreviewFragment[] = [];
  if (data?.accessReviewConnections) {
    switch (data.accessReviewConnections.__typename) {
      case "AccessReviewConnectionsResult":
        if (navigationState.isOnlyMyReviewsFilterOn) {
          accessReviewConnections = data.accessReviewConnections.accessReviewConnections.filter(
            (accessReviewConnection) => {
              // this should show all connections where the current users is either a reviewer
              // or has the ability to assign a reviewer.
              return (
                authState.user?.user.id &&
                isReviewer(
                  authState.user.user.id,
                  accessReviewConnection.reviewerUsers
                )
              );
            }
          );
        } else {
          accessReviewConnections =
            data.accessReviewConnections.accessReviewConnections;
        }
        break;
      default:
        logError(new Error(`failed to retrieve access review apps`));
    }
  } else if (error) {
    logError(error, `failed to retrieve access review apps`);
    return <UnexpectedErrorPage error={error} />;
  }
  if (loading) {
    return <ViewSkeleton />;
  }

  const filteredAccessReviewConnections = filterSearchResults(
    accessReviewConnections,
    searchQuery,
    (connection: AccessReviewConnectionPreviewFragment) => [
      connection.connection?.name,
    ]
  );

  return (
    <div className={styles.tableContainer}>
      <div className={styles.tableTopContainer}>
        <div className={styles.tableTopLeftContent}>
          <Input
            value={searchQuery}
            onChange={(value) => setSearchQuery(value)}
            placeholder="Search apps"
            leftIconName="search"
          />
        </div>
        <div className={styles.tableTopRightContent}>
          <OnlyMyReviewsFilter />
          <AccessReviewProgressBar
            accessReviewId={accessReview.id}
            numCompleted={
              accessReviewConnections.filter((accessReviewConnection) => {
                const status = accessReviewConnection.status;
                return (
                  status === AccessReviewSummaryStatus.Completed ||
                  status === AccessReviewSummaryStatus.NoReviewNeeded
                );
              }).length
            }
            numTotal={accessReviewConnections.length}
            entityType={EntityType.Connection}
          />
        </div>
      </div>
      <EmptyStateContentWrapper
        content={
          <AccessReviewConnectionsTable
            accessReview={accessReview}
            accessReviewConnections={filteredAccessReviewConnections}
          />
        }
        entityType={EntityType.Connection}
        title={`No apps to review`}
        subtitle={
          navigationState.isOnlyMyReviewsFilterOn
            ? `No apps have been assigned for you to review.`
            : `There are no apps that require an access review.`
        }
        isEmpty={accessReviewConnections.length === 0}
      />
    </div>
  );
};

export default AccessReviewConnections;
