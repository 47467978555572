import {
  Maybe,
  RequestPreviewLargeFragment,
  useCreateRequestCommentMutation,
} from "api/generated/graphql";
import { useToast } from "components/toast/Toast";
import { Button, ButtonV3 } from "components/ui";
import { useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import RequestsCommentModal from "views/requests/RequestsCommentModal";

type RequestsCommentsButtonProps = {
  request: RequestPreviewLargeFragment;
};

export const RequestsCommentsButton = (props: RequestsCommentsButtonProps) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [createRequestComment, { loading }] = useCreateRequestCommentMutation();

  const modalReset = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  return (
    <div>
      {hasV3 ? (
        <ButtonV3
          type="mainSecondary"
          size="sm"
          label="Comment"
          leftIconName="plus"
          onClick={() => setShowModal(true)}
        />
      ) : (
        <Button
          outline
          label={"Add a new comment"}
          onClick={() => setShowModal(true)}
        />
      )}
      {showModal && (
        <RequestsCommentModal
          isModalOpen={showModal}
          onClose={() => modalReset()}
          onSubmit={async (comment) => {
            try {
              const { data } = await createRequestComment({
                variables: {
                  input: {
                    requestId: props.request.id,
                    comment: comment,
                  },
                },
                refetchQueries: ["EventsFull"],
              });
              switch (data?.createRequestComment.__typename) {
                case "CreateRequestCommentResult":
                  modalReset();
                  displaySuccessToast(`Success: request comment created`);
                  break;
                default:
                  logError(new Error(`failed to save comment`));
                  setErrorMessage(`Error: failed to save comment`);
              }
            } catch (e) {
              logError(e, `failed to save comment`);
              setErrorMessage(`Error: failed to save comment`);
            }
          }}
          errorMessage={errorMessage}
          loading={loading}
        />
      )}
    </div>
  );
};

export default RequestsCommentsButton;
