import clsx from "clsx";
import styles from "components/requests/RequestIcons.module.scss";
import React, { ReactElement } from "react";
import * as Icons from "react-feather";

export type ApprovedIconProps = {
  large?: boolean;
};

export const ApprovedIcon = (props: ApprovedIconProps) => {
  return (
    <BaseIcon
      icon={<Icons.ThumbsUp strokeWidth={3} />}
      large={props.large}
      ok={true}
    />
  );
};

export type DeniedIconProps = {
  large?: boolean;
};

export const DeniedIcon = (props: DeniedIconProps) => {
  return (
    <BaseIcon
      icon={<Icons.ThumbsDown strokeWidth={3} />}
      alert={true}
      large={props.large}
    />
  );
};

export type FailedIconProps = {
  large?: boolean;
};

export const FailedIcon = (props: FailedIconProps) => {
  return (
    <BaseIcon
      icon={<Icons.X strokeWidth={3} />}
      alert={true}
      large={props.large}
    />
  );
};

export type PendingIconProps = {
  large?: boolean;
  neutral?: boolean;
  ok?: boolean;
};

export const PendingIcon = (props: PendingIconProps) => {
  return (
    <BaseIcon
      icon={<Icons.Send strokeWidth={3} />}
      neutral={props.neutral}
      ok={props.ok}
      large={props.large}
    />
  );
};

export type CanceledIconProps = {
  large?: boolean;
};

export const CanceledIcon = (props: CanceledIconProps) => {
  return (
    <BaseIcon
      icon={<Icons.X strokeWidth={3} />}
      neutral={true}
      large={props.large}
    />
  );
};

export type GrantedIconProps = {
  large?: boolean;
};

export const GrantedIcon = (props: GrantedIconProps) => {
  return (
    <BaseIcon
      icon={<Icons.Check strokeWidth={3} />}
      large={props.large}
      ok={true}
    />
  );
};

export type CommentedIconProps = {
  large?: boolean;
};

export const CommentedIcon = (props: CommentedIconProps) => {
  return (
    <BaseIcon
      icon={<Icons.MessageSquare strokeWidth={3} />}
      large={props.large}
      neutral={true}
    />
  );
};

export const ActivityIcon = () => {
  return (
    <BaseIcon
      icon={<Icons.Activity strokeWidth={3} />}
      neutral={true}
      large={true}
    />
  );
};

export const AddIcon = () => {
  return (
    <BaseIcon icon={<Icons.Plus strokeWidth={3} />} ok={true} large={true} />
  );
};

export const MinusIcon = () => {
  return (
    <BaseIcon icon={<Icons.Minus strokeWidth={3} />} ok={true} large={true} />
  );
};
export const RemoveIcon = () => {
  return (
    <BaseIcon
      icon={<Icons.Minus strokeWidth={3} />}
      alert={true}
      large={true}
    />
  );
};

export type NotNotifiedIconProps = {
  large?: boolean;
};

export const NotNotifiedIcon = (props: NotNotifiedIconProps) => {
  return (
    <BaseIcon
      icon={<Icons.BellOff strokeWidth={3} />}
      large={props.large}
      ok={true}
    />
  );
};

export type NotifiedIconProps = {
  large?: boolean;
};

export const NotifiedIcon = (props: NotifiedIconProps) => {
  return (
    <BaseIcon
      icon={<Icons.Bell strokeWidth={3} />}
      large={props.large}
      ok={true}
    />
  );
};

export const AddedResourceIcon = () => {
  return (
    <BaseIcon icon={<Icons.Box strokeWidth={3} />} ok={true} large={true} />
  );
};

export const AddedGroupIcon = () => {
  return (
    <BaseIcon icon={<Icons.Users strokeWidth={3} />} ok={true} large={true} />
  );
};

export const RemovedResourceIcon = () => {
  return (
    <BaseIcon icon={<Icons.Box strokeWidth={3} />} alert={true} large={true} />
  );
};

export const RemovedGroupIcon = () => {
  return (
    <BaseIcon
      icon={<Icons.Users strokeWidth={3} />}
      alert={true}
      large={true}
    />
  );
};

export const EditIcon = () => {
  return (
    <BaseIcon icon={<Icons.Edit strokeWidth={3} />} ok={true} large={true} />
  );
};

type BaseIconProps = {
  icon: ReactElement;
  alert?: boolean;
  ok?: boolean;
  neutral?: boolean;
  large?: boolean;
};

const BaseIcon = (props: BaseIconProps) => {
  return (
    <div
      className={clsx({
        [styles.iconWrapper]: !props.large,
        [styles.iconWrapperLarge]: props.large,
        [styles.alert]: props.alert,
        [styles.ok]: props.ok,
        [styles.neutral]: props.neutral,
      })}
    >
      <div
        className={clsx({
          [styles.iconInnerWrapper]: !props.large,
          [styles.iconInnerWrapperLarge]: props.large,
        })}
      >
        {props.icon}
      </div>
    </div>
  );
};
