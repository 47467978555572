import { getModifiedErrorMessage } from "api/ApiContext";
import {
  Maybe,
  ResourceDetailColumnDocument,
  ResourceFragment,
  useUpdateResourcesMutation,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Banner, Checkbox, Input, Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import React from "react";
import useLogEvent from "utils/analytics";
import { logError } from "utils/logging";

import * as styles from "./ResourceRenameModal.css";

export type ResourceRenameModalProps = {
  resource: ResourceFragment;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

export const ResourceRenameModal = (props: ResourceRenameModalProps) => {
  const [name, setName] = useState(props.resource.name);
  const [placeholder, setPlaceholder] = useState("");
  const [matchRemoteName, setMatchRemoteName] = useState(
    props.resource.commonMetadata.matchRemoteName
  );

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();
  const logEvent = useLogEvent();

  const [updateResource, { loading }] = useUpdateResourcesMutation();

  const modalReset = () => {
    props.setShowModal(false);
    setErrorMessage(null);
  };

  const isUpdated =
    name !== props.resource.name ||
    matchRemoteName !== props.resource.commonMetadata.matchRemoteName;

  const handleSubmit = async () => {
    logEvent({
      name: "apps_item_rename_click",
      properties: {
        itemType: props.resource.resourceType,
      },
    });

    try {
      const { data } = await updateResource({
        variables: {
          input: {
            resourceIds: [props.resource.id],
            name: name,
            commonMetadata: {
              matchRemoteName: matchRemoteName,
            },
          },
        },
        refetchQueries: [
          {
            query: ResourceDetailColumnDocument,
            variables: {
              id: props.resource.id,
            },
          },
        ],
      });
      if (data) {
        switch (data.updateResources.__typename) {
          case "UpdateResourcesResult":
            modalReset();
            displaySuccessToast(`Success: resource name updated`);
            break;
          case "ResourceNotFoundError":
          case "OpalResourceImmutableError":
          default:
            logError(new Error(`failed to update resource`));
            setErrorMessage("Error: failed to update resource");
        }
      }
    } catch (error) {
      logError(error, `failed to update resource`);
      setErrorMessage(
        getModifiedErrorMessage("Error: failed to update resource", error)
      );
    }
  };

  return (
    <Modal title="Rename" isOpen={props.showModal} onClose={modalReset}>
      <Modal.Body>
        <div className={styles.body}>
          {!props.resource.commonMetadata.matchRemoteName && matchRemoteName && (
            <div className={sprinkles({ marginBottom: "lg" })}>
              <Banner
                message={
                  "When selected, this item's name in Opal will match its name on the end system. To reflect newer changes, sync this item."
                }
                type="info"
              />
            </div>
          )}
          <div className={sprinkles({ marginBottom: "lg" })}>
            <Checkbox
              checked={matchRemoteName}
              label="Use name from end system"
              onChange={(checked) => {
                setMatchRemoteName(checked);
                if (checked) {
                  setName(props.resource.remoteName);
                  setPlaceholder(
                    `${props.resource.remoteName} (will be synced from end system)`
                  );
                } else {
                  setName(props.resource.name);
                  setPlaceholder("");
                }
              }}
            />
          </div>
          <Input
            value={placeholder || name}
            onChange={setName}
            disabled={matchRemoteName}
          />
          {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Save"
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLoading={loading}
        primaryButtonDisabled={!isUpdated}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={modalReset}
      />
    </Modal>
  );
};
