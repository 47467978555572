import { openInNewTab } from "api/common/common";
import {
  EntityType,
  GroupFragment,
  Maybe,
  SyncErrorFragment,
  SyncTaskFragment,
  SyncType,
  useBulkUpdateMutation,
  useGroupQuery,
  useRecordGroupViewMutation,
  useSyncStatusQuery,
} from "api/generated/graphql";
import axios from "axios";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import { FormMode, ResourceConfig } from "components/forms/common";
import ResourcesConfigForm from "components/forms/ResourcesConfigForm";
import {
  getResourceConfigChangedFields,
  makeConfigForGroup,
  makeUpdateInputForGroup,
  validateResourceConfig,
} from "components/forms/utils";
import GroupBindingDetailPopover from "components/group_bindings/GroupBindingDetailPopover";
import SyncStatusModal from "components/label/SyncStatusModal";
import useSyncMenuItem, { getSyncLabel } from "components/sync/useSyncMenuItem";
import { useToast } from "components/toast/Toast";
import { Banner, Button, Divider, Link, Tabs } from "components/ui";
import { IconName } from "components/ui/icon/Icon";
import { makeURLForEntityViz } from "components/viz/contexts/FilterContext";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router";
import useLogEvent from "utils/analytics";
import {
  AuthorizedActionExport,
  AuthorizedActionManage,
} from "utils/auth/auth";
import { getResourceUrlNew } from "utils/common";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";
import { useReadUINotification } from "utils/notifications";
import { useTransitionTo } from "utils/router/hooks";
import useSyncStatusToast from "utils/sync/useSyncStatusToast";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import GroupBindingDeleteModal from "views/group_bindings/modals/GroupBindingDeleteModal";
import GroupBindingEditModal from "views/group_bindings/modals/GroupBindingEditModal";
import GroupBindingManualLinkModal from "views/group_bindings/modals/GroupBindingManualLinkModal";
import GroupBindingRequestRedirectModal from "views/group_bindings/modals/GroupBindingRequestRedirectModal";
import GroupDeleteModal from "views/groups/GroupDeleteModal";
import { GroupRenameModal } from "views/groups/GroupRenameModal";
import GroupEventsRow from "views/groups/viewer/rows/GroupEventsRow";
import GroupResourcesRow from "views/groups/viewer/rows/GroupResourcesRow";
import GroupUsersRow from "views/groups/viewer/rows/GroupUsersRow";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";
import OrgContext from "views/settings/OrgContext";

import AppsContentColumn from "./AppsContentColumn";
import {
  ACCESS_OPTION_URL_KEY,
  ACCOUNT_ID_URL_KEY,
  APP_ID_URL_KEY,
  AppsContext,
  ITEM_TYPE_URL_KEY,
  OKTA_APP_ID_URL_KEY,
} from "./AppsContext";
import BulkImportColumnV2 from "./BulkImportColumnV2";
import { GroupActionButtons } from "./GroupActionButtons";
import GroupRequestColumn from "./GroupRequestColumn";
import { GroupViewKey } from "./utils";

interface GroupView {
  key: GroupViewKey;
  title: string;
  content: React.ReactNode;
  icon: IconName;
  count?: number;
}

const GroupDetailColumn = () => {
  const { groupId, groupView: selectedGroupViewKey } = useParams<
    Record<string, string>
  >();

  const [showSyncModal, setShowSyncModal] = useState(false);
  const [showRenameGroupModal, setShowRenameGroupModal] = useState(false);
  const [showDeleteGroupModal, setShowDeleteGroupModal] = useState(false);
  const [showEditGroupBindingModal, setShowEditGroupBindingModal] = useState(
    false
  );
  const [showLinkGroupModal, setShowLinkGroupModal] = useState(false);
  const [showUnlinkGroupModal, setShowUnlinkGroupModal] = useState(false);
  const [showRequestRedirectModal, setShowRequestRedirectModal] = useState(
    false
  );
  const [mode, setMode] = useState<FormMode>("view");
  const [config, setConfig] = useState<Partial<ResourceConfig>>({});
  const [initialConfig, setInitialConfig] = useState<Partial<ResourceConfig>>();
  const [errors, setErrors] = useState<string[]>([]);
  const hasGroupBindings = useFeatureFlag(FeatureFlag.GroupBindings);

  const transitionTo = useTransitionTo({
    preserveQueries: [
      ACCESS_OPTION_URL_KEY,
      ITEM_TYPE_URL_KEY,
      OKTA_APP_ID_URL_KEY,
      APP_ID_URL_KEY,
      ACCOUNT_ID_URL_KEY,
    ],
  });
  const { authState } = useContext(AuthContext);
  const { orgState } = useContext(OrgContext);
  const { selectedUnmanagedItems, bulkMode, isSelectMode } = useContext(
    AppsContext
  );
  const logEvent = useLogEvent();

  useReadUINotification(groupId);

  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();

  const [recordGroupView] = useRecordGroupViewMutation();
  useMountEffect(() => {
    if (!authState.impersonatingUser) {
      try {
        recordGroupView({
          variables: {
            input: {
              groupId: groupId,
            },
          },
        });
      } catch (err) {
        logError(err, "failed to record group view");
      }
    }
  });

  const {
    data: groupData,
    error: groupError,
    loading: groupLoading,
    refetch,
  } = useGroupQuery({
    variables: {
      input: {
        id: groupId,
      },
    },
  });

  let group: Maybe<GroupFragment> = null;
  let groupNotFound = true;
  if (groupData) {
    switch (groupData.group.__typename) {
      case "GroupResult":
        group = groupData.group.group;
        groupNotFound = false;
        break;
      case "GroupNotFoundError":
        break;
      default:
        logError(new Error(`failed to get group`));
    }
  } else if (groupError) {
    logError(groupError, `failed to get group`);
  }

  const {
    data: syncData,
    error: syncError,
    loading: syncLoading,
  } = useSyncStatusQuery({
    variables: {
      input: {
        syncType: SyncType.PullConnectionsSingleGroup,
        groupId,
      },
    },
    skip: groupNotFound,
  });

  let lastSuccessfulSyncTask: SyncTaskFragment | null = null;
  let syncErrors: SyncErrorFragment[] = [];
  if (syncData) {
    switch (syncData.syncStatus.__typename) {
      case "SyncStatusResult":
        lastSuccessfulSyncTask = syncData.syncStatus.lastSuccessfulSyncTask
          ? syncData.syncStatus.lastSuccessfulSyncTask
          : null;
        syncErrors = syncData.syncStatus.syncErrors;
        break;
      case "InvalidSyncTypeError":
      case "GroupNotFoundError":
        logError(syncData.syncStatus.message);
        break;
    }
  }

  const syncMenuItem = useSyncMenuItem({
    syncType: SyncType.PullConnectionsSingleGroup,
    group: group ?? undefined,
    queriesToRefetch: ["Group", "GroupAccessLevels"],
    loadingEntity: groupLoading || groupNotFound,
  });

  const showSyncStatusToast = useSyncStatusToast({
    loadingText: "Successfully updated, now syncing...",
    queriesToRefetch: ["Group"],
  });

  const [bulkUpdate, { loading: updateLoading }] = useBulkUpdateMutation({
    refetchQueries: ["Group"],
  });

  useEffect(() => {
    if (!group) return;
    const groupConfig = makeConfigForGroup(
      group,
      group.configTemplate ?? undefined
    );
    // Something in ResourcesConfigFormV2 is causing this parent component to refetch the group
    // which causes this effect to get rerun, resetting configV2 to the initial config.
    // TODO: figure out why this is happening and fix it, but for now this is a workaround.
    // It's possible it might not be an issue once we add a resolver for requestConfigs.
    if (
      Object.keys(config).length === 0 ||
      config.entityId !== group.id ||
      config.configurationTemplate !== group.configTemplate
    ) {
      setConfig(groupConfig);
    }
    setInitialConfig(groupConfig);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [group]);

  const isSourceGroup =
    group && group.groupBinding
      ? group.groupBinding?.sourceGroupId === group.id
      : true;

  useEffect(() => {
    if (selectedGroupViewKey === "request" && !isSourceGroup) {
      return setShowRequestRedirectModal(true);
    }
    setShowRequestRedirectModal(false);
  }, [selectedGroupViewKey, isSourceGroup]);

  if (bulkMode === "request") {
    return null;
  }

  if (groupLoading) {
    return (
      <AppsContentColumn>
        <ColumnContentSkeleton />
      </AppsContentColumn>
    );
  }

  if (groupNotFound) {
    return (
      <Column isContent>
        <NotFoundPage entity="Group" />
      </Column>
    );
  }

  if (groupError) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={groupError} />
      </Column>
    );
  }

  if (selectedUnmanagedItems.length === 1) {
    return <BulkImportColumnV2 />;
  }

  if (!group) {
    return null;
  }

  const adminTabsVisible =
    group.authorizedActions?.includes(AuthorizedActionManage) ||
    group.authorizedActions?.includes(AuthorizedActionExport);

  const isViewingAsNonAdmin =
    mode === "view" &&
    !group.authorizedActions?.includes(AuthorizedActionManage) &&
    !group.authorizedActions?.includes(AuthorizedActionExport);

  const canManage = group.authorizedActions?.includes(AuthorizedActionManage);

  const groupViews: GroupView[] = [
    {
      key: "overview",
      title: "Overview",
      content: (
        <>
          {group.configTemplate ? (
            <div className={sprinkles({ padding: "md" })}>
              <Banner
                type={mode === "edit" ? "warning" : "info"}
                message={
                  <>
                    This group is configured via the{" "}
                    <span className={sprinkles({ fontWeight: "semibold" })}>
                      {authState.user?.isAdmin ? (
                        <Link
                          url={getResourceUrlNew({
                            entityId: group.configTemplate.id,
                            entityType: EntityType.ConfigurationTemplate,
                          })}
                        >
                          {group.configTemplate.name}
                        </Link>
                      ) : (
                        group.configTemplate.name
                      )}
                    </span>{" "}
                    template.
                  </>
                }
              ></Banner>
            </div>
          ) : null}
          <ResourcesConfigForm
            mode={mode}
            config={config}
            onChange={setConfig}
            isViewingAsNonAdmin={isViewingAsNonAdmin}
          />
        </>
      ),
      icon: "list",
    },
    {
      key: "resources",
      title: "Resources",
      content: <GroupResourcesRow group={group} />,
      icon: "cube",
      count: group.groupResources.length,
    },
  ];

  if (adminTabsVisible) {
    groupViews.push({
      key: "users",
      title: "Users",
      content: <GroupUsersRow group={group} />,
      icon: "user",
      count: group.groupUsers.length,
    });
  }

  if (adminTabsVisible) {
    groupViews.push({
      key: "events",
      title: "Events",
      content: <GroupEventsRow group={group} />,
      icon: "events",
    });
  }

  const handleSave = async () => {
    if (!group || !initialConfig) return;

    const errors = validateResourceConfig(config, orgState);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    logEvent({
      name: "apps_item_edit_click",
      properties: {
        itemType: group.groupType,
      },
    });

    try {
      setErrors([]);
      const changedConfig = getResourceConfigChangedFields(
        initialConfig,
        config
      );
      const inputFields = makeUpdateInputForGroup(changedConfig);
      if (
        initialConfig.configurationTemplate?.id &&
        !config.configurationTemplate?.id
      ) {
        inputFields.configurationId = {};
      }

      const input = inputFields;

      const { data } = await bulkUpdate({
        variables: {
          input: {
            groupIds: [groupId],
            ...input,
          },
        },
      });

      switch (data?.bulkUpdateItems.__typename) {
        case "BulkUpdateItemsResult":
          setMode("view");
          setErrors([]);
          if (data.bulkUpdateItems.syncTask?.id) {
            showSyncStatusToast(data.bulkUpdateItems.syncTask.id);
          } else {
            displaySuccessToast(`Successfully updated group`);
          }
          break;
        case "TagNotFoundError":
          setErrors(["Failed to get tag data"]);
          break;
        case "InvalidUpdateGroupVisibilityGroupError":
          setErrors(["Invalid visibility group"]);
          break;
        case "InvalidReviewerSettingsError":
          setErrors(["Invalid reviewer stage configuration"]);
          break;
        case "GroupMaxDurationTooLargeError":
          setErrors([
            "Group max access duration cannot exceed org-wide max duration",
          ]);
          break;
        default:
          logError("Failed to update group");
          setErrors(["Failed to update group."]);
      }
    } catch (err) {
      logError(err, "Failed to update group");
      setErrors(["Failed to update group."]);
    }
  };

  const handleNavigate = (groupViewKey: GroupViewKey) => {
    if (groupViewKey === "request") {
      if (!isSourceGroup) {
        setShowRequestRedirectModal(true);
        return;
      }
    }
    transitionTo({
      pathname: `/groups/${groupId}/${groupViewKey}`,
    });
  };

  const handleRequestExport = () => {
    if (!group) return;
    logEvent({
      name: "apps_export_users",
      properties: {
        exportType: "group",
      },
    });
    displayLoadingToast("Generating export...");
    axios({
      url: "/export/groups/users?groupID=" + group.id,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        if (!group) return;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Opal_" + group.name.replace(" ", "_") + "_Users.csv"
        );
        link.click();
        displaySuccessToast(`Success: downloaded users in group`);
      })
      .catch(() => {
        displayErrorToast(`Error: failed to generate export`);
      });
  };

  const handleRequestExportDebugInfo = () => {
    if (!group) return;
    displayLoadingToast("Generating export...");
    axios({
      url: `/export/groups/${group.id}/debug`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        if (!group) return;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Opal_" + group.name.replace(" ", "_") + "_Debug.json"
        );
        link.click();
        displaySuccessToast(`Success: downloaded group debug info`);
      })
      .catch(() => {
        displayErrorToast(`Error: failed to generate export`);
      });
  };

  const getDisplayedGroupView = () => {
    if (!group) return null;

    if (selectedGroupViewKey === "request") {
      return <GroupRequestColumn group={group} onNavigate={handleNavigate} />;
    }

    const viewKey = selectedGroupViewKey ?? "overview";
    const groupViewToDisplay = groupViews.find(
      (groupView) => groupView.key === viewKey
    );

    if (!groupViewToDisplay) return null;

    const hasChanges = !_.isEqual(config, initialConfig);

    let headerButtons;
    if (viewKey === "overview") {
      headerButtons =
        mode === "view" ? (
          <Button
            label="Edit"
            leftIconName="edit"
            borderless
            onClick={() => setMode("edit")}
            size="md"
            disabled={isSelectMode}
            disabledTooltip="Exit bulk select mode to edit"
          />
        ) : (
          <div className={sprinkles({ display: "flex", gap: "sm" })}>
            <Button
              label="Cancel"
              onClick={() => {
                setErrors([]);
                setMode("view");
                if (initialConfig) {
                  setConfig(initialConfig);
                }
              }}
              disabled={updateLoading}
              borderless
              size="md"
            />
            <Button
              label={updateLoading ? "Saving..." : "Save"}
              leftIconName="check"
              type="primary"
              onClick={handleSave}
              disabled={updateLoading || !hasChanges}
              size="md"
            />
          </div>
        );
    }

    const titleAccessory = group.groupBinding ? (
      <GroupBindingDetailPopover
        groupId={group.id}
        groupBinding={group.groupBinding}
      />
    ) : undefined;

    return (
      <AppsContentColumn>
        <ColumnHeader
          title={group.name}
          titleAccessory={titleAccessory}
          icon={{ entityType: group.groupType, type: "entity" }}
          menuError={hasSyncErrors}
          menuOptions={menuOptions}
          onClickInsights={() => {
            if (!group) return;
            const hash = makeURLForEntityViz(group.id, EntityType.Group);
            openInNewTab("/insights" + hash);
          }}
          rightActions={
            <GroupActionButtons
              group={group}
              selectedGroupViewKey={viewKey}
              onNavigate={handleNavigate}
              mode={mode}
              editButtons={
                group.authorizedActions?.includes(AuthorizedActionManage)
                  ? headerButtons
                  : undefined
              }
            />
          }
        />
        <Divider margin="md" />

        {mode === "view" ? (
          <div
            className={sprinkles({ display: "flex", justifyContent: "center" })}
          >
            <Tabs
              tabInfos={groupViews
                .filter((view) => view.content)
                .map((view) => ({
                  title: view.title,
                  onClick: () => {
                    if (!group) return;
                    handleNavigate(view.key);
                    logEvent({
                      name: "apps_item_tab_click",
                      properties: {
                        itemType: group.groupType,
                        tab: view.key,
                      },
                    });
                  },
                  isSelected: viewKey === view.key,
                  badgeCount: view.count,
                  icon: view.icon,
                }))}
              round
            />
          </div>
        ) : null}
        {errors.map((error) => (
          <Banner message={error} type="error" />
        ))}

        <ColumnContent>{groupViewToDisplay.content}</ColumnContent>
      </AppsContentColumn>
    );
  };

  let sublabel: string;
  if (syncError) {
    sublabel = "Unable to get status";
  } else if (syncLoading) {
    sublabel = "Loading sync status";
  } else {
    sublabel = getSyncLabel(lastSuccessfulSyncTask, syncErrors);
  }

  const hasSyncErrors = authState.user?.isAdmin && syncErrors.length > 0;
  const menuOptions: PropsFor<typeof ColumnHeader>["menuOptions"] = [];
  if ((authState.user?.isAdmin || canManage) && syncMenuItem) {
    menuOptions.push({
      label: hasSyncErrors ? "View sync errors" : "View sync details",
      sublabel: sublabel,
      onClick: () => {
        logEvent({
          name: "apps_view_sync_details",
          properties: {
            syncType: SyncType.PullConnectionsSingleGroup,
          },
        });
        setShowSyncModal(true);
      },
      icon: hasSyncErrors
        ? { type: "name", icon: "alert-circle" }
        : { type: "name", icon: "info" },
      type: hasSyncErrors ? "warning" : undefined,
    });
    menuOptions.push(syncMenuItem);
  }

  if (
    menuOptions.length > 0 &&
    (group.authorizedActions?.includes(AuthorizedActionExport) ||
      group.authorizedActions?.includes(AuthorizedActionManage))
  ) {
    menuOptions.push({ type: "divider" });
  }

  if (group.authorizedActions?.includes(AuthorizedActionExport)) {
    menuOptions.push(
      {
        label: "Export group users",
        icon: { type: "name", icon: "users-right" },
        onClick: handleRequestExport,
      },
      {
        label: "Export debug info",
        icon: { type: "name", icon: "tool" },
        onClick: handleRequestExportDebugInfo,
      }
    );
    if (group.authorizedActions?.includes(AuthorizedActionManage)) {
      menuOptions.push({ type: "divider" });
    }
  }

  if (group.authorizedActions?.includes(AuthorizedActionManage)) {
    if (hasGroupBindings) {
      if (group.groupBinding) {
        menuOptions.push(
          {
            label: "Edit group link",
            onClick: () => setShowEditGroupBindingModal(true),
            icon: { type: "name", icon: "edit-3" },
          },
          {
            label: "Unlink",
            onClick: () => {
              setShowUnlinkGroupModal(true);
            },
            icon: { type: "name", icon: "link-broken" },
            type: "danger",
          }
        );
      } else {
        menuOptions.push({
          label: "Link to group",
          onClick: () => setShowLinkGroupModal(true),
          icon: { type: "name", icon: "link" },
        });
      }
      menuOptions.push({ type: "divider" });
    }
    menuOptions.push({
      label: "Rename",
      onClick: () => {
        setShowRenameGroupModal(true);
      },
      icon: { type: "name", icon: "edit-3" },
    });
    menuOptions.push({
      label: "Remove from Opal",
      onClick: () => {
        setShowDeleteGroupModal(true);
      },
      icon: { type: "name", icon: "trash" },
      type: "danger",
    });
  }

  return (
    <>
      {showSyncModal ? (
        <SyncStatusModal
          syncType={SyncType.PullConnectionsSingleGroup}
          entity={group}
          lastSuccessfulSyncTask={lastSuccessfulSyncTask}
          syncErrors={syncErrors}
          isModalOpen={showSyncModal}
          onClose={() => {
            setShowSyncModal(false);
          }}
        />
      ) : null}
      {getDisplayedGroupView()}

      {showRenameGroupModal && (
        <GroupRenameModal
          group={group}
          showModal={showRenameGroupModal}
          setShowModal={setShowRenameGroupModal}
        />
      )}
      {showDeleteGroupModal && (
        <GroupDeleteModal
          group={group}
          showModal={showDeleteGroupModal}
          setShowModal={setShowDeleteGroupModal}
        />
      )}
      {showLinkGroupModal && (
        <GroupBindingManualLinkModal
          isOpen={showLinkGroupModal}
          onModalClose={() => {
            setShowLinkGroupModal(false);
            refetch();
          }}
          groupId={group.id}
        />
      )}
      {showEditGroupBindingModal && (
        <GroupBindingEditModal
          isOpen={showEditGroupBindingModal}
          onModalClose={() => {
            setShowEditGroupBindingModal(false);
            refetch();
          }}
          groupBindingIds={[group.groupBinding?.id ?? ""]}
        />
      )}
      {showUnlinkGroupModal && (
        <GroupBindingDeleteModal
          isOpen={showUnlinkGroupModal}
          onModalClose={() => {
            setShowUnlinkGroupModal(false);
            refetch();
          }}
          groupBindingIds={[group.groupBinding?.id ?? ""]}
        />
      )}
      {showRequestRedirectModal && (
        <GroupBindingRequestRedirectModal
          isOpen={showRequestRedirectModal}
          onModalClose={() => {
            handleNavigate("overview");
            setShowRequestRedirectModal(false);
          }}
          groupId={group.id}
          groupBindingId={group.groupBinding?.id ?? ""}
        />
      )}
    </>
  );
};

export default GroupDetailColumn;
