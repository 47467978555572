import {
  AccessReviewConnectionFragment,
  AccessReviewTab,
  EntityType,
  useOngoingAccessReviewSubtabStatsQuery,
} from "api/generated/graphql";
import EntityViewer from "components/entity_viewer/EntityViewer";
import { EntityViewerTabType } from "components/entity_viewer/EntityViewerTabs";
import { useContext, useState } from "react";
import { logError } from "utils/logging";
import AccessReviewContext from "views/access_reviews/AccessReviewContext";
import AccessReviewConnectionActionButtons from "views/access_reviews/connections/AccessReviewConnectionActionButtons";
import AccessReviewConnectionEventsRow from "views/access_reviews/connections/AccessReviewConnectionEventsRow";
import { AccessReviewConnectionOverviewRow } from "views/access_reviews/connections/AccessReviewConnectionOverviewRow";
import AccessReviewConnectionTitleRow from "views/access_reviews/connections/AccessReviewConnectionTitleRow";
import AccessReviewConnectionUsersRow from "views/access_reviews/connections/AccessReviewConnectionUsersRow";

type AccessReviewConnectionViewerProps = {
  accessReviewConnection: AccessReviewConnectionFragment;
};

export const AccessReviewConnectionViewer = (
  props: AccessReviewConnectionViewerProps
) => {
  const { accessReviewState } = useContext(AccessReviewContext);
  const [selectedTab, setSelectedTab] = useState<EntityViewerTabType>(
    EntityViewerTabType.Users
  );

  // bulk assign
  const [showBulkUpdateColumn, setShowBulkUpdateColumn] = useState(false);
  const [selectedRows, setSelectedRows] = useState<Record<string, EntityType>>(
    {}
  );

  const { data, error } = useOngoingAccessReviewSubtabStatsQuery({
    variables: {
      input: {
        tab: AccessReviewTab.AppsTab,
        itemId: props.accessReviewConnection.id,
        accessReviewId: props.accessReviewConnection.accessReview?.id ?? "",
      },
    },
  });
  let tabStats = null;
  if (data) {
    switch (data.ongoingAccessReviewSubtabStats.__typename) {
      case "OngoingAccessReviewSubtabStatsResult":
        tabStats =
          data.ongoingAccessReviewSubtabStats.ongoingAccessReviewSubtabStats ||
          null;
        break;
      case "AccessReviewNotFoundError":
      case "AccessReviewAlreadyStoppedError":
        break;
      default:
        logError(new Error(`failed to retrieve access review stats`));
    }
  } else if (error) {
    logError(error, `failed to retrieve access review stats`);
  }

  return (
    <EntityViewer
      title={
        <AccessReviewConnectionTitleRow
          accessReviewConnection={props.accessReviewConnection}
        />
      }
      tabInfos={[
        {
          tabType: EntityViewerTabType.Overview,
          tabContent: (
            <AccessReviewConnectionOverviewRow
              accessReviewConnection={props.accessReviewConnection}
            />
          ),
        },
        {
          tabType: EntityViewerTabType.Users,
          tabContent: (
            <AccessReviewConnectionUsersRow
              accessReviewConnection={props.accessReviewConnection}
              selectedRows={showBulkUpdateColumn ? selectedRows : undefined}
              setSelectedRows={
                showBulkUpdateColumn ? setSelectedRows : undefined
              }
            />
          ),
          tabCount:
            tabStats && tabStats.numUsersToReview
              ? tabStats.numUsersToReview
              : undefined,
        },
        {
          tabType: EntityViewerTabType.Events,
          tabContent: (
            <AccessReviewConnectionEventsRow
              accessReviewConnection={props.accessReviewConnection}
            />
          ),
        },
      ]}
      selectedTab={selectedTab}
      setSelectedTab={setSelectedTab}
      button={
        accessReviewState.ongoingAccessReviewIdSet.has(
          props.accessReviewConnection.accessReviewId
        ) ? (
          <AccessReviewConnectionActionButtons
            accessReviewConnection={props.accessReviewConnection}
            selectedRows={selectedRows}
            setSelectedRows={setSelectedRows}
            showBulkUpdateColumn={showBulkUpdateColumn}
            setShowBulkUpdateColumn={setShowBulkUpdateColumn}
          />
        ) : (
          <></>
        )
      }
    />
  );
};

export default AccessReviewConnectionViewer;
