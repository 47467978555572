import {
  EntityType,
  ResourceTagFragment,
  TagPreviewLargeFragment,
} from "api/generated/graphql";
import ConnectionLabel from "components/label/item_labels/ConnectionLabel";
import { ResourceLabel } from "components/label/Label";
import ServiceTypeLabel from "components/label/ServiceTypeLabel";
import MaterialTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MaterialTable";
import moment from "moment";
import { useHistory } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { getResourceSublabel } from "utils/resources";

type TagResourceTableRow = {
  resource: string;
  connection: string;
  tagSource: string;
  createdAt: string;
};

type TagResourcesTableProps = {
  tag: TagPreviewLargeFragment;
  tagResources: ResourceTagFragment[];
};

const TagResourcesTable = (props: TagResourcesTableProps) => {
  const history = useHistory();

  const headers: Header<TagResourceTableRow>[] = [
    { id: "resource", label: "Resource" },
    { id: "connection", label: "App" },
    { id: "tagSource", label: "Tag source" },
    { id: "createdAt", label: "Created at" },
  ];

  const rows: CellRow<TagResourceTableRow>[] = props.tagResources.map(
    (tagResource) => {
      const connection = tagResource.resource?.connection;
      const connectionId = tagResource.resource?.connectionId;
      const createdAtTime = moment(new Date(tagResource.createdAt));
      const resource = tagResource.resource;

      return {
        id: tagResource.id || "",
        data: {
          resource: {
            value: resource?.id ?? "",
            element: (
              <ResourceLabel
                text={resource?.name}
                subText={resource ? getResourceSublabel(resource) : "--"}
                entityType={EntityTypeDeprecated.Resource}
                resourceType={resource?.resourceType}
                bold={true}
                icon={resource?.iconUrl}
                iconLarge={true}
                pointerCursor={true}
              />
            ),
            clickHandler: () => {
              history.push(
                getResourceUrlNew({
                  entityId: resource?.id ?? null,
                  entityType: EntityType.Resource,
                })
              );
            },
          },
          connection: {
            sortValue: connection?.name || "",
            value: connectionId || "",
            element: (
              <ConnectionLabel
                text={connection?.name}
                connectionId={connectionId}
                connectionType={connection?.connectionType}
              />
            ),
          },
          tagSource: {
            value: `tagSource_${tagResource.source}`,
            sortValue: tagResource.source,
            element: <ServiceTypeLabel serviceType={tagResource.source} />,
          },
          createdAt: {
            value: createdAtTime.unix(),
            element: <>{createdAtTime.fromNow()}</>,
          },
        },
      };
    }
  );

  return (
    <MaterialTable<TagResourceTableRow>
      headers={headers}
      rows={rows}
      denseFormatting={true}
    />
  );
};

export default TagResourcesTable;
