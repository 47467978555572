import { EntityType, Maybe } from "api/generated/graphql";
import { EntityForAccessLabel } from "components/label/AccessPointsLabel";
import { ResourceLabel } from "components/label/Label";
import { Modal } from "components/ui";
import {
  Section,
  SectionColumn,
  SectionEntry,
  SectionRow,
} from "components/viewer/Viewer";
import React, { ReactElement } from "react";

import * as styles from "./AccessPointModal.css";

export type AccessPointModalProps = {
  subject: Maybe<EntityForAccessLabel> | undefined;
  object: Maybe<EntityForAccessLabel> | undefined;
  roleName?: string;
  accessPathContent: ReactElement;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

export const AccessPointModal = (props: AccessPointModalProps) => {
  let subjectName = "--";
  let subjectContent = <></>;

  if (props.subject) {
    switch (props.subject.__typename) {
      case "User":
        subjectName = "User";
        subjectContent = (
          <ResourceLabel
            text={props.subject.fullName}
            entityTypeNew={EntityType.User}
            avatar={props.subject.avatarUrl}
            entityId={props.subject.id}
          />
        );
        break;
      case "Resource":
        subjectName = "Principal";
        subjectContent = (
          <ResourceLabel
            text={props.subject.name}
            resourceType={props.subject.resourceType}
            entityTypeNew={EntityType.Resource}
            entityId={props.subject.id}
          />
        );
        break;
      case "Group":
        subjectName = "Group";
        subjectContent = (
          <ResourceLabel
            text={props.subject.name}
            groupType={props.subject.groupType}
            entityTypeNew={EntityType.Group}
            entityId={props.subject.id}
          />
        );
        break;
    }
  }

  let objectName = "--";
  let objectContent = <></>;

  if (props.object) {
    switch (props.object.__typename) {
      case "User":
        objectName = "User";
        objectContent = (
          <ResourceLabel
            text={props.object.fullName}
            entityTypeNew={EntityType.User}
            avatar={props.object.avatarUrl}
            entityId={props.object.id}
          />
        );
        break;
      case "Resource":
        objectName = "Resource";
        objectContent = (
          <ResourceLabel
            text={props.object.name}
            resourceType={props.object.resourceType}
            entityTypeNew={EntityType.Resource}
            entityId={props.object.id}
          />
        );
        break;
      case "Group":
        if (props.subject?.__typename === "Group") {
          objectName = "Nested Group";
        } else {
          objectName = "Group";
        }
        objectContent = (
          <ResourceLabel
            text={props.object.name}
            groupType={props.object.groupType}
            entityTypeNew={EntityType.Group}
            entityId={props.object.id}
          />
        );
        break;
    }
  }

  return (
    <Modal
      isOpen={props.showModal}
      onClose={() => {
        props.setShowModal(false);
      }}
      title="Access Details"
      maxWidth={false}
    >
      <Modal.Body>
        <div className={styles.container}>
          <SectionColumn>
            <Section>
              <SectionRow>
                <SectionEntry title={subjectName}>
                  {subjectContent}
                </SectionEntry>
                <SectionEntry title={objectName}>{objectContent}</SectionEntry>
              </SectionRow>
              {props.roleName ? (
                <SectionRow>
                  <SectionEntry title={"Role"}>
                    <>{props.roleName}</>
                  </SectionEntry>
                </SectionRow>
              ) : null}
            </Section>
            <Section>
              <SectionRow>
                <SectionEntry title={"Access"}>
                  {props.accessPathContent}
                </SectionEntry>
              </SectionRow>
            </Section>
          </SectionColumn>
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Close"
        onPrimaryButtonClick={() => {
          props.setShowModal(false);
        }}
      />
    </Modal>
  );
};

export default AccessPointModal;
