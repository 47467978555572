import { ThirdPartyProvider } from "api/generated/graphql";
import opsgenieLogo from "assets/logos/opsgenie-logo.png";
import pagerDutyLogo from "assets/logos/pager-duty-logo.png";
import labelStyles from "components/label/Label.module.scss";
import { Label, Tooltip } from "components/ui";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import React from "react";
import { AlertCircle } from "react-feather";

type OnCallScheduleLabelProps = {
  scheduleName: string;
  thirdPartyProvider: ThirdPartyProvider | null;
  showMissingFromRemoteWarning: boolean;
};

export const getOncallScheduleIcon = (
  thirdPartyProvider: ThirdPartyProvider
) => {
  if (thirdPartyProvider === ThirdPartyProvider.PagerDuty) {
    return pagerDutyLogo;
  } else if (thirdPartyProvider === ThirdPartyProvider.Opsgenie) {
    return opsgenieLogo;
  }
};

export const OnCallScheduleLabel = (props: OnCallScheduleLabelProps) => {
  let icon: IconData;
  if (props.thirdPartyProvider) {
    const externalIconUrl = getOncallScheduleIcon(props.thirdPartyProvider);
    icon = {
      type: "src",
      icon: externalIconUrl,
    };
  } else {
    icon = {
      type: "src",
      icon: "clipboard",
    };
  }

  return (
    <span
      className={sprinkles({
        display: "flex",
        alignItems: "center",
        gap: "sm",
      })}
    >
      <Label label={props.scheduleName} icon={icon} />
      {props.showMissingFromRemoteWarning && (
        <Tooltip tooltipText="On-call schedule not found in remote system. This could be because the schedule was deleted, or due to API instability in the third-party system.">
          <div className={labelStyles.statusError}>
            <AlertCircle size={16} strokeWidth={3} />
          </div>
        </Tooltip>
      )}
    </span>
  );
};

export default OnCallScheduleLabel;
