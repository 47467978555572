import { ThirdPartyProvider } from "api/generated/graphql";
import { getLogoByThirdPartyProvider } from "components/label/Label";
import TicketProjectDropdown from "components/tickets/TicketProjectDropdown";
import TicketProviderDropdown from "components/tickets/TicketProviderDropdown";
import { FormGroup, FormRow, Icon, RadioGroup, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import { AppsContext } from "views/apps/AppsContext";

import { FormMode, ResourceTicketPropagation } from "../common";

enum PropagationOption {
  Disable,
  Both,
  OnGrant,
  OnRevocation,
}

type editAction =
  | { type: PropagationOption }
  | {
      type: "setTicketProvider";
      payload: ThirdPartyProvider;
    }
  | {
      type: "setTicketProject";
      payload: { id: string; name: string };
    };

const editValue = (
  value: ResourceTicketPropagation | undefined,
  action: editAction
): ResourceTicketPropagation | undefined => {
  switch (action.type) {
    case PropagationOption.Disable:
      return {
        onGrant: false,
        onRevocation: false,
      };
    case PropagationOption.Both:
      return {
        onGrant: true,
        onRevocation: true,
        ticketProvider: value?.ticketProvider,
      };
    case PropagationOption.OnGrant:
      return {
        onGrant: true,
        onRevocation: false,
        ticketProvider: value?.ticketProvider,
      };
    case PropagationOption.OnRevocation:
      return {
        onGrant: false,
        onRevocation: true,
        ticketProvider: value?.ticketProvider,
      };
    case "setTicketProvider":
      return {
        onGrant: value?.onGrant ?? true,
        onRevocation: value?.onRevocation ?? true,
        ticketProvider: action.payload
          ? {
              ticketProvider: action.payload,
              ticketProjectId: "",
              ticketProjectName: "",
            }
          : undefined,
      };
    case "setTicketProject":
      return {
        onGrant: value?.onGrant ?? true,
        onRevocation: value?.onRevocation ?? true,
        ticketProvider:
          action.payload && value?.ticketProvider
            ? {
                ticketProvider: value?.ticketProvider?.ticketProvider,
                ticketProjectId: action.payload.id,
                ticketProjectName: action.payload.name,
              }
            : undefined,
      };
    default:
      return value;
  }
};

type Props = {
  mode: FormMode;
  value?: ResourceTicketPropagation;
  onChange: (value?: ResourceTicketPropagation) => void;
  disabled?: boolean;
  isV3?: boolean;
};

const ResourceTicketPropagationRow = (props: Props) => {
  const { bulkMode } = useContext(AppsContext);
  const { value, onChange } = props;
  const { ticketProvider, ticketProjectId, ticketProjectName } =
    value?.ticketProvider ?? {};

  let viewContentLabel = "Disabled";
  if (value) {
    if (value.onGrant && value.onRevocation) {
      viewContentLabel = "Enabled for both access grant and revocation on:";
    } else if (value.onGrant) {
      viewContentLabel = "Enabled for access grants only on:";
    } else if (value.onRevocation) {
      viewContentLabel = "Enabled for access revocations only on:";
    }
  }

  const viewContent =
    value?.onGrant || value?.onRevocation ? (
      <>
        <div className={sprinkles({ display: "flex", gap: "sm" })}>
          {viewContentLabel}
          <Icon
            data={{
              icon: getLogoByThirdPartyProvider(ticketProvider) ?? undefined,
              type: "src",
            }}
            size="sm"
          />
          {ticketProjectName ?? "Unknown project"}
        </div>
      </>
    ) : (
      viewContentLabel
    );

  const propOptions = [
    {
      value: PropagationOption.Both,
      label: "On both access grant and revocation",
    },
    { value: PropagationOption.OnGrant, label: "Only on access grant" },
    {
      value: PropagationOption.OnRevocation,
      label: "Only on access revocation",
    },
  ];
  const propSetting = value?.onGrant
    ? value?.onRevocation
      ? PropagationOption.Both
      : PropagationOption.OnGrant
    : value?.onRevocation
    ? PropagationOption.OnRevocation
    : "none";

  const projectLabel =
    ticketProvider === ThirdPartyProvider.ServiceNow
      ? "Catalog Item"
      : "Project";
  const projectHelpText =
    ticketProvider === ThirdPartyProvider.ServiceNow
      ? "Please select the catalog item that will be requested when a ticket is created."
      : "Please select the project for Opal to create tickets in.";

  const editContent = (
    <div
      className={sprinkles({
        display: "flex",
        gap: "lg",
        flexDirection: "column",
      })}
    >
      {bulkMode === "edit" && (
        <div className={sprinkles({ marginBottom: "md" })}>
          <Switch
            label="Leave unchanged"
            checked={value === undefined}
            onChange={(v) => {
              if (v) {
                onChange(undefined);
              } else {
                onChange(editValue(value, { type: PropagationOption.Disable }));
              }
            }}
            rightAlign
          />
        </div>
      )}
      <Switch
        checked={
          Boolean(value) && Boolean(value?.onGrant || value?.onRevocation)
        }
        onChange={(val) =>
          onChange(
            editValue(value, {
              type: val ? PropagationOption.Both : PropagationOption.Disable,
            })
          )
        }
        label="Enable ticket propagation"
      />
      {propSetting !== "none" && (
        <>
          <RadioGroup
            options={propOptions}
            value={propOptions.find((o) => o.value === propSetting)}
            onSelectValue={(val) => {
              onChange(editValue(value, { type: val.value }));
            }}
            getOptionKey={(option) => option.value}
            getOptionLabel={(option) => option.label}
          />

          {/* This div is necessary to make the layout work */}
          <div>
            <FormGroup label="Ticket provider" required>
              <TicketProviderDropdown
                onSelectTicketProvider={(val) => {
                  if (val)
                    onChange(
                      editValue(value, {
                        type: "setTicketProvider",
                        payload: val,
                      })
                    );
                }}
                selectedTicketProvider={ticketProvider}
              />
            </FormGroup>
            {ticketProvider && (
              <FormGroup
                label={projectLabel}
                infoTooltip={projectHelpText}
                required
              >
                <TicketProjectDropdown
                  ticketProvider={ticketProvider}
                  selectedTicketProjectId={ticketProjectId}
                  selectedTicketProjectName={ticketProjectName}
                  onSelectTicketProject={(val) => {
                    if (val)
                      onChange(
                        editValue(value, {
                          type: "setTicketProject",
                          payload: val,
                        })
                      );
                  }}
                />
              </FormGroup>
            )}
          </div>
        </>
      )}
    </div>
  );

  const helpText = `If enabled, a ticket will be created for all access changes to this
   resource in Opal. The ticket assigns one of the resource's admins to manually
   make the access change on the end system.`;

  if (props.isV3) {
    return (
      <FormGroup
        label="Ticket provider for access propagation"
        infoTooltip={helpText}
      >
        {editContent}
      </FormGroup>
    );
  }

  return (
    <FormRow
      title="Ticket provider for access propagation"
      tooltipText={helpText}
    >
      {props.mode === "view" ? viewContent : editContent}
    </FormRow>
  );
};

export default ResourceTicketPropagationRow;
