import { readCachedQuery, refetchQueries } from "api/ApiContext";
import {
  NotificationsDocument,
  NotificationsQuery,
  NotificationsQueryVariables,
  useReadUiNotificationMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { useContext, useEffect } from "react";

import { logError } from "./logging";

// useReadUINotification is a hook for setting notifications
// with a tracking ID as read. See SetRead in the uinotifications
// go package for more detail.
export function useReadUINotification(trackingID: string) {
  const { authState } = useContext(AuthContext);

  const [readUINotification] = useReadUiNotificationMutation();

  useEffect(() => {
    (async () => {
      if (authState.impersonatingUser) {
        return;
      }
      if (trackingID === "") {
        return;
      }
      try {
        await readUINotification({
          variables: { input: { trackingId: trackingID } },
          ignoreResults: true,
        });

        // After we've marked this notification as read, try to refresh the
        // notifications list in case anything was read.

        // As a small optimization, we try to look at the currently fetched
        // notifications. We only want to do the refetch if we think something will
        // be affected.
        const cached = readCachedQuery<
          NotificationsQuery,
          NotificationsQueryVariables
        >({
          query: NotificationsDocument,
        });

        if (
          Array.isArray(cached?.uiNotifications) &&
          cached?.uiNotifications.some((n) => n.trackingId === trackingID)
        ) {
          await refetchQueries({ include: ["Notifications"] });
        }
      } catch (err) {
        logError(
          err,
          `failed to mark notifications with tracking id as read: ${trackingID}`
        );
      }
    })();
  }, [trackingID, readUINotification, authState.impersonatingUser]);
}
