// isOnPrem checks whether the current environment is on_prem
export function isOnPrem(env: string) {
  return isOnPremProd(env) || isOnPremLocal(env);
}

// isOnPremLocal checks whether the current environment is on_prem-local
export function isOnPremLocal(env: string) {
  return env === "on_prem-local";
}

// isOnPremProd checks whether the current environment is on_prem-local
export function isOnPremProd(env: string) {
  return env === "on_prem";
}

// isLocal checks whether the current environment is local
export function isDevLocal(env: string) {
  return env === "dev-local";
}

// isDevLocal checks whether the current environment is dev-local
export function isLocal(env: string) {
  return isDevLocal(env) || isOnPremLocal(env);
}

// isDev checks whether the current environment is dev
export function isDev(env: string) {
  return env === "dev";
}

// isStaging checks whether the current environment is staging
export function isStaging(env: string) {
  return env === "staging";
}

// isSandbox checks whether the current environment is sandbox
export function isSandbox(env: string) {
  return env === "sandbox";
}

// isProd checks whether the current environment is prod
export function isProd(env: string) {
  return env === "prod";
}

// isNonProd checks whether the current environment is non prod
export function isNonProd(env: string) {
  return !isProd(env) && !isOnPrem(env) && !isSandbox(env);
}

// isDemo checks whether the current environment is demo
export function isDemo(env: string) {
  return env === "demo";
}

// isCloud checks whether the current environment is in the cloud
export function isCloud(env: string) {
  return isDevLocal(env) || isDev(env) || isProd(env) || isDemo(env);
}
