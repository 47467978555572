import {
  EntityType,
  GroupBindingSuggestionFragment,
  GroupBindingSuggestionsSortByField,
  GroupPreviewTinyFragment,
  GroupType,
  SortDirection,
  useCreateGroupBindingsMutation,
  useDismissGroupBindingSuggestionsMutation,
  useGroupBindingSuggestionsTableQuery,
} from "api/generated/graphql";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { useToast } from "components/toast/Toast";
import { Button, Select } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import pluralize from "pluralize";
import { useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { logError } from "utils/logging";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { useGroupBindingsFilterState } from "views/group_bindings/GroupBindingsContext";
import GroupBindingCompareModal from "views/group_bindings/modals/GroupBindingCompareModal";
import GroupBindingSuggestionDisambiguateModal from "views/group_bindings/modals/GroupBindingSuggestionDisambiguateModal";

import GroupBindingGroupLabel from "./rows/GroupBindingGroupLabel";

const SUGGESTIONS_PER_PAGE = 30;
const PRIMARY_NAME_COL_ID = GroupBindingSuggestionsSortByField.PrimaryGroupName;
const SECONDARY_NAME_COL_ID =
  GroupBindingSuggestionsSortByField.SecondaryGroupName;

function isSortableField(
  str: string
): str is GroupBindingSuggestionsSortByField {
  return Object.values<string>(GroupBindingSuggestionsSortByField).includes(
    str
  );
}

type SortValue = {
  field: GroupBindingSuggestionsSortByField;
  direction: SortDirection;
};

type SuggestionRow = {
  id: string;
  [PRIMARY_NAME_COL_ID]: string;
  [SECONDARY_NAME_COL_ID]: string;
  sourceOfTruth?: string;
  data: GroupBindingSuggestionFragment;
  actions?: unknown;
};

const GroupBindingSuggestionsTable = () => {
  const {
    connectionIds,
    groupIds,
    groupTypes,
    searchQuery,
  } = useGroupBindingsFilterState();
  const [sourceGroupBySuggestion, setSourceGroupBySuggestion] = useState<
    Record<string, GroupPreviewTinyFragment>
  >({});
  const [showCompareModal, setShowCompareModal] = useState(false);
  const [showDisambiguateModal, setShowDisambiguateModal] = useState(false);
  const [
    suggestionIdsToDisambiguate,
    setSuggestionIdsToDisambiguate,
  ] = useState<string[]>([]);
  const [suggestionToCompare, setSuggestionToCompare] = useState<
    { suggestionId: string; sourceGroupId: string } | undefined
  >(undefined);
  const [selectedSuggestionIds, setSelectedSuggestionIds] = useState<string[]>(
    []
  );
  const { displaySuccessToast, displayErrorToast } = useToast();
  const history = useHistory();
  const logEvent = useLogEvent();
  const [sortBy, setSortBy] = useState<SortValue | undefined>({
    field: GroupBindingSuggestionsSortByField.PrimaryGroupName,
    direction: SortDirection.Asc,
  });

  const [dismiss] = useDismissGroupBindingSuggestionsMutation({
    refetchQueries: ["GroupBindingSuggestionsTable", "GroupBindingsCounts"],
  });
  const [createBindings] = useCreateGroupBindingsMutation({
    refetchQueries: [
      "GroupBindingsTable",
      "GroupBindingSuggestionsTable",
      "GroupBindingsCounts",
    ],
  });

  const hasFilters =
    connectionIds.length > 0 ||
    groupIds.length > 0 ||
    groupTypes.length > 0 ||
    searchQuery.length > 0;

  const {
    data,
    error,
    loading,
    fetchMore,
  } = useGroupBindingSuggestionsTableQuery({
    variables: {
      input: {
        filters: {
          connectionIds: connectionIds.length > 0 ? connectionIds : undefined,
          groupIds: groupIds.length > 0 ? groupIds : undefined,
          groupTypes:
            groupTypes.length > 0
              ? groupTypes.map((t) => t as GroupType)
              : undefined,
          searchQuery: searchQuery.length > 0 ? searchQuery : undefined,
        },
        pageSize: SUGGESTIONS_PER_PAGE,
        sortBy,
      },
    },
  });

  if (error) {
    logError(error, `failed to list group binding suggestions`);
    return <UnexpectedErrorPage error={error} />;
  }

  const { groupBindingSuggestions, totalNumItems, cursor } =
    data?.groupBindingSuggestions || {};

  const suggestionsById = _.keyBy(groupBindingSuggestions, "id");

  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            input: {
              cursor,
              pageSize: SUGGESTIONS_PER_PAGE,
              filters: {
                connectionIds:
                  connectionIds.length > 0 ? connectionIds : undefined,
                groupIds: groupIds.length > 0 ? groupIds : undefined,
                groupTypes:
                  groupTypes.length > 0
                    ? groupTypes.map((t) => t as GroupType)
                    : undefined,
                searchQuery: searchQuery.length > 0 ? searchQuery : undefined,
              },
              sortBy,
            },
          },
        });
      }
    : undefined;

  const onDismiss = async (ids: string[]) => {
    const results = await dismiss({
      variables: {
        input: {
          ids,
        },
      },
    });
    switch (results.data?.dismissGroupBindingSuggestions.__typename) {
      case "DismissGroupBindingSuggestionsResult":
        displaySuccessToast("Successfully dismissed suggestion");
        logEvent({ name: "group_binding_suggestions_dismiss" });
        setSelectedSuggestionIds([]);
        break;
      case "GroupBindingSuggestionNotFoundError":
        displayErrorToast("Failed to dismiss suggestion: not found");
        break;
    }
  };

  const onCreateBindings = async (ids: string[]) => {
    // Determine if a group is already being set into a binding when bulk accepting suggestions
    const groupIdToSuggestionId: Map<string, string> = new Map();
    const suggestionIdsWithConflict = new Set<string>();
    ids
      .flatMap((suggestionId) => {
        const suggestion = suggestionsById[suggestionId];
        return [
          { suggestionId, groupId: suggestion.primaryGroupId },
          { suggestionId, groupId: suggestion.secondaryGroupId },
        ];
      })
      .forEach(({ suggestionId, groupId }) => {
        if (groupIdToSuggestionId.has(groupId)) {
          const otherSuggestionId = groupIdToSuggestionId.get(groupId);
          if (!otherSuggestionId) {
            return;
          }
          suggestionIdsWithConflict.add(suggestionId);
          suggestionIdsWithConflict.add(otherSuggestionId);
        }
        groupIdToSuggestionId.set(groupId, suggestionId);
      });

    if (suggestionIdsWithConflict.size > 0) {
      setSuggestionIdsToDisambiguate(
        Array.from(suggestionIdsWithConflict.keys())
      );
      setShowDisambiguateModal(true);
      return;
    }

    if (ids.length === 0) {
      return;
    }

    const results = await createBindings({
      variables: {
        input: {
          groupBindings: ids.map((id) => ({
            groupIds: [
              suggestionsById[id]?.primaryGroup?.id ?? "",
              suggestionsById[id]?.secondaryGroup?.id ?? "",
            ],
            sourceGroupId:
              sourceGroupBySuggestion[id]?.id ??
              suggestionsById[id]?.primaryGroup?.id ??
              "",
            suggestionId: id,
          })),
        },
      },
    });
    switch (results.data?.createGroupBindings.__typename) {
      case "CreateGroupBindingsResult": {
        const count = results.data.createGroupBindings.groupBindings.length;
        displaySuccessToast(
          `Successfully created ${count} group ${pluralize("link", count)}`
        );
        logEvent({
          name: "group_bindings_create",
          properties: { fromSuggestion: true },
        });
        setSelectedSuggestionIds([]);
        if (!hasFilters) {
          history.push({ hash: "all" });
        }
        break;
      }
      case "GroupAlreadyBelongsToBindingError":
        displayErrorToast(
          "Failed to create group bindings: group already belongs to binding"
        );
        break;
      case "GroupBindingHasNoGroupsError":
        displayErrorToast(
          "Failed to create group bindings: no groups provided"
        );
        break;
    }
  };

  const rows =
    groupBindingSuggestions
      ?.filter(
        // Filter out if either primary or secondary group is missing
        (suggestion) => suggestion.primaryGroup && suggestion.secondaryGroup
      )
      .map((suggestion) => ({
        id: suggestion.id,
        [PRIMARY_NAME_COL_ID]: suggestion.primaryGroup?.name ?? "--",
        [SECONDARY_NAME_COL_ID]: suggestion.secondaryGroup?.name ?? "--",
        data: suggestion,
      })) ?? [];

  const columns: Header<SuggestionRow>[] = [
    {
      id: PRIMARY_NAME_COL_ID,
      label: "Group",
      sortable: true,
      width: 300,
      customCellRenderer: (row: SuggestionRow) => {
        const group = row.data.primaryGroup!;
        return <GroupBindingGroupLabel group={group} />;
      },
    },
    {
      id: SECONDARY_NAME_COL_ID,
      label: "Other group",
      sortable: true,
      width: 300,
      customCellRenderer: (row: SuggestionRow) => {
        const group = row.data.secondaryGroup!;
        return <GroupBindingGroupLabel group={group} />;
      },
    },
    {
      id: "sourceOfTruth",
      label: "Source of truth",
      width: 200,
      customCellRenderer: (row: SuggestionRow) => {
        const options: GroupPreviewTinyFragment[] = [
          row.data.primaryGroup!,
          row.data.secondaryGroup!,
        ];
        return (
          <Select
            options={options}
            value={sourceGroupBySuggestion[row.id] ?? options[0]}
            onChange={(group) => {
              if (!group) {
                return;
              }
              setSourceGroupBySuggestion((prev) => ({
                ...prev,
                [row.id]: group,
              }));
            }}
            selectOnly
            getOptionLabel={(group: GroupPreviewTinyFragment) =>
              group.name ?? "Hidden group"
            }
            getIcon={(group) => ({
              type: "src",
              icon: getGroupTypeInfo(group.groupType)?.icon,
            })}
            disableBuiltInFiltering
          />
        );
      },
    },
    {
      id: "actions",
      label: "",
      sortable: false,
      width: 300,
      customCellRenderer: (row: SuggestionRow) => {
        return (
          <div
            className={sprinkles({
              display: "flex",
              gap: "sm",
              justifyContent: "flex-end",
            })}
          >
            <Button
              label="Compare"
              leftIconName="compare"
              borderless
              onClick={() => {
                setShowCompareModal(true);
                setSuggestionToCompare({
                  suggestionId: row.id,
                  sourceGroupId:
                    sourceGroupBySuggestion[row.id]?.id ??
                    row.data.primaryGroup?.id,
                });
              }}
            />
            <Button
              label="Link groups"
              type="primary"
              leftIconName="link"
              onClick={() => onCreateBindings([row.id])}
            />
            <Button
              onClick={() => onDismiss([row.id])}
              leftIconName="x-close"
              borderless
            />
          </div>
        );
      },
    },
  ];

  return (
    <>
      <TableHeader
        entityType={EntityType.GroupBindingSuggestion}
        selectedNumRows={selectedSuggestionIds.length}
        totalNumRows={totalNumItems ?? 0}
        bulkRightActions={[
          {
            label: "Dismiss",
            type: "danger",
            onClick: () => onDismiss(selectedSuggestionIds),
            iconName: "x-close",
          },
          {
            label: "Link groups",
            type: "main",
            onClick: () => onCreateBindings(selectedSuggestionIds),
            iconName: "link",
          },
        ]}
      />
      <Table
        emptyState={{
          title: "No suggestions available",
          subtitle: "Check back later for more suggestions",
        }}
        loadingRows={loading}
        rows={rows}
        getRowId={(row) => row.id}
        columns={columns}
        totalNumRows={totalNumItems ?? 0}
        onLoadMoreRows={loadMoreRows}
        checkedRowIds={new Set(selectedSuggestionIds)}
        onCheckedRowsChange={(ids, checked) => {
          if (checked) {
            setSelectedSuggestionIds(
              _.uniq([...selectedSuggestionIds, ...ids])
            );
          } else {
            setSelectedSuggestionIds(
              selectedSuggestionIds.filter((id) => !ids.includes(id))
            );
          }
        }}
        selectAllChecked={rows.length === selectedSuggestionIds.length}
        onSelectAll={(checked) => {
          if (checked) {
            setSelectedSuggestionIds(rows.map((row) => row.id));
          } else {
            setSelectedSuggestionIds([]);
          }
        }}
        manualSortDirection={
          sortBy && {
            sortBy: sortBy.field,
            sortDirection: sortBy.direction,
          }
        }
        handleManualSort={(sortBy, sortDirection) => {
          if (!sortDirection) {
            setSortBy(undefined);
            return;
          }
          if (!isSortableField(sortBy)) {
            return;
          }
          const direction: SortDirection =
            sortDirection === "DESC" ? SortDirection.Desc : SortDirection.Asc;

          setSortBy({
            field: sortBy,
            direction,
          });
        }}
      />
      {showCompareModal && suggestionToCompare && (
        <GroupBindingCompareModal
          suggestionId={suggestionToCompare.suggestionId}
          sourceGroupId={suggestionToCompare.sourceGroupId}
          isOpen={showCompareModal}
          onModalClose={() => {
            setShowCompareModal(false);
          }}
        />
      )}
      {showDisambiguateModal && (
        <GroupBindingSuggestionDisambiguateModal
          suggestions={suggestionIdsToDisambiguate.map(
            (id) => suggestionsById[id]
          )}
          sourceGroupBySuggestionId={sourceGroupBySuggestion}
          isOpen={showDisambiguateModal}
          onModalClose={() => {
            setShowDisambiguateModal(false);
          }}
          onSubmit={(suggestionIds: string[]) => {
            const suggestionIdsToRemove = suggestionIdsToDisambiguate.filter(
              (id) => !suggestionIds.includes(id)
            );
            const updatedSelectedIds = selectedSuggestionIds.filter(
              (id) => !suggestionIdsToRemove.includes(id)
            );
            setSelectedSuggestionIds(updatedSelectedIds);
            setShowDisambiguateModal(false);
            onCreateBindings(updatedSelectedIds);
          }}
        />
      )}
    </>
  );
};

export default GroupBindingSuggestionsTable;
