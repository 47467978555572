import { Maybe } from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { Input, Modal } from "components/ui";
import React, { useState } from "react";

type RequestsCommentModalProps = {
  isModalOpen: boolean;
  onClose: () => void;
  onSubmit: (comment: string) => void;
  errorMessage?: Maybe<string>;
  loading?: boolean;
};

export const RequestsCommentModal = (props: RequestsCommentModalProps) => {
  const [comment, setComment] = useState("");

  return (
    <Modal
      isOpen={props.isModalOpen}
      onClose={props.onClose}
      title="Add a comment"
    >
      <Modal.Body>
        <Input
          type="textarea"
          placeholder="This access request is..."
          value={comment}
          onChange={setComment}
        />
        {props.errorMessage ? (
          <ModalErrorMessage errorMessage={props.errorMessage} />
        ) : null}
      </Modal.Body>
      <Modal.Footer
        onSecondaryButtonClick={props.onClose}
        secondaryButtonLabel="Cancel"
        onPrimaryButtonClick={() => {
          props.onSubmit(comment);
        }}
        primaryButtonLoading={props.loading}
        primaryButtonDisabled={props.loading || comment === ""}
        primaryButtonLabel="Add comment"
      />
    </Modal>
  );
};

export default RequestsCommentModal;
