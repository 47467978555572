import {
  AccessOption,
  ImportRemoteGroupInfo,
  ImportRemoteResourceInfo,
  useBulkImportMutation,
  Visibility,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import { ResourceConfig } from "components/forms/common";
import ResourcesConfigForm from "components/forms/ResourcesConfigForm";
import {
  makeDefaultRequestConfig,
  makeRequestConfigsInput,
  validateResourceConfig,
} from "components/forms/utils";
import { Banner, Button, DataElement, Divider, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { useLocation } from "react-router";
import useLogEvent from "utils/analytics";
import {
  getServiceTypeFromGroupType,
  getServiceTypeFromResourceType,
} from "utils/directory/services";
import { logError } from "utils/logging";
import useSyncStatusToast from "utils/sync/useSyncStatusToast";
import OrgContext from "views/settings/OrgContext";

import { APP_VIEWS } from "./AppDetailContent";
import { AppsContext } from "./AppsContext";
import * as styles from "./BulkEditColumn.css";
import { useAccessOptionKey } from "./utils";

const MAX_INITIAL_ITEMS = 10;

const BulkImportColumn = () => {
  const {
    selectedRemoteItems,
    toggleRemoteItem,
    clearSelectedItems,
  } = useContext(AppsContext);
  const { orgState } = useContext(OrgContext);
  const location = useLocation();
  const [, setAccessOptionKey] = useAccessOptionKey();
  const [showAll, setShowAll] = useState(false);

  const [config, setConfig] = useState<Partial<ResourceConfig>>({
    description: "",
    visibility: Visibility.Global,
    messageChannels: [],
    onCallSchedules: [],
    breakGlassUsers: [],
    requireMfaToApprove: false,
    requireMfaToConnect: false,
    tagIds: [],
    requestConfigs: [makeDefaultRequestConfig()],
    childrenDefaultConfigTemplate: undefined,
  });
  const [errors, setErrors] = useState<string[]>([]);
  const logEvent = useLogEvent();

  const showSyncStatusToast = useSyncStatusToast({
    loadingText: "Successfully imported, now syncing...",
    queriesToRefetch: ["ItemsListSection", "RemoteItemsList"],
  });
  const [bulkImport, { loading }] = useBulkImportMutation({
    refetchQueries: ["AppsListColumn"],
  });

  const handleSave = async () => {
    const errors = validateResourceConfig(config, orgState);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    const remoteGroups: ImportRemoteGroupInfo[] = [];
    const remoteResources: ImportRemoteResourceInfo[] = [];

    const itemTypes = new Set<string>();
    selectedRemoteItems.forEach((item) => {
      if (item.resourceType) {
        remoteResources.push({
          remoteId: item.id,
          resourceType: item.resourceType,
          name: item.remoteName,
          connectionId: item.connectionId,
          serviceType: getServiceTypeFromResourceType(item.resourceType),
          metadata: item.metadata,
        });
        itemTypes.add(item.resourceType);
      }
      if (item.groupType) {
        remoteGroups.push({
          remoteId: item.id,
          groupType: item.groupType,
          name: item.remoteName,
          connectionId: item.connectionId,
          serviceType: getServiceTypeFromGroupType(item.groupType),
          metadata: item.metadata,
        });
        itemTypes.add(item.groupType);
      }
    });

    logEvent({
      name: "apps_import_items_click",
      properties: {
        numItems: selectedRemoteItems.length,
        itemTypes: Array.from(itemTypes),
      },
    });

    try {
      const input = {
        remoteGroups,
        remoteResources,
        description: config.description,
        adminOwnerId: config.adminOwner?.id ?? undefined,
        configurationId: config.configurationTemplate?.id ?? undefined,
        visibility: config.visibility,
        visibilityGroupsIds: config.visibilityGroups,
        messageChannelIds: config.messageChannels?.map((channel) => channel.id),

        requireMfaToApprove: config.requireMfaToApprove,
        requireMfaToConnect: config.requireMfaToConnect,

        breakGlassUsersIds: config.breakGlassUsers?.map((user) => user.id),
        onCallSchedules: config.onCallSchedules?.map((schedule) => ({
          scheduleName: schedule.name,
          remoteId: schedule.remoteId,
          thirdPartyProvider: schedule.thirdPartyProvider,
        })),
        tagIds: config.tagIds,
        requestConfigs: makeRequestConfigsInput(config.requestConfigs ?? []),
        childrenDefaultConfigTemplateId: config.childrenDefaultConfigTemplate
          ?.id
          ? {
              configurationId: config.childrenDefaultConfigTemplate.id,
            }
          : undefined,
      };
      const { data } = await bulkImport({
        variables: {
          input,
        },
      });
      switch (data?.bulkImportRemoteItems.__typename) {
        case "BulkImportRemoteItemsResult":
          showSyncStatusToast(data.bulkImportRemoteItems.syncTask.id);
          setAccessOptionKey(AccessOption.All);
          clearSelectedItems();
          break;
        case "TagNotFoundError":
          setErrors(["Failed to get tag data"]);
          break;
        case "InvalidUpdateResourceVisibilityGroupError":
        case "InvalidUpdateGroupVisibilityGroupError":
          setErrors(["Invalid visibility group"]);
          break;
        case "InvalidReviewerSettingsError":
          setErrors(["Invalid reviewer stage configuration"]);
          break;
        case "GroupMaxDurationTooLargeError":
        case "ResourceMaxDurationTooLargeError":
          setErrors([
            "Max access duration cannot exceed org-wide max duration setting",
          ]);
          break;
        default:
          setErrors(["Failed to bulk import items"]);
          break;
      }
    } catch (err) {
      logError(err, "Failed to bulk import items");
    }
  };

  const currentAppView = APP_VIEWS.find((appView) =>
    location.pathname.endsWith(appView.key)
  );

  if (currentAppView) {
    return null;
  }

  const actionButtons = (
    <div className={sprinkles({ display: "flex", gap: "sm" })}>
      <Button label="Cancel" borderless onClick={() => clearSelectedItems()} />
      <Button
        label={loading ? "Adding..." : "Add to Opal"}
        leftIconName="plus"
        type="primary"
        onClick={handleSave}
        disabled={loading}
      />
    </div>
  );

  const items = showAll
    ? selectedRemoteItems
    : selectedRemoteItems.slice(0, MAX_INITIAL_ITEMS);

  return (
    <Column isContent maxWidth="lg">
      <ColumnHeader
        title={`Add ${selectedRemoteItems.length} ${pluralize(
          "resource",
          selectedRemoteItems.length
        )} to Opal`}
        icon={{ type: "name", icon: "plus" }}
        rightActions={actionButtons}
      />
      <Divider margin="md" />
      <ColumnContent>
        <div className={styles.entityListContainer}>
          {items.map((item) => {
            const entityType = item.resourceType || item.groupType;
            if (!entityType) return null;
            return (
              <Tooltip key={item.remoteId} tooltipText={item.remoteId}>
                <DataElement
                  key={item.remoteId}
                  label={item.remoteName}
                  color="blue"
                  onClick={() => toggleRemoteItem(item)}
                  rightIcon={{ name: "x" }}
                  leftIcon={{ data: { type: "entity", entityType } }}
                />
              </Tooltip>
            );
          })}
          {!showAll && selectedRemoteItems.length > MAX_INITIAL_ITEMS && (
            <DataElement
              key="show-all"
              label={`Show all ${selectedRemoteItems.length} items`}
              leftIcon={{ name: "plus" }}
              onClick={() => setShowAll(true)}
              color="gray"
            />
          )}
          {showAll && (
            <DataElement
              key="show-all"
              label={`Collapse items`}
              leftIcon={{ name: "minus" }}
              onClick={() => setShowAll(false)}
              color="gray"
            />
          )}
        </div>
        <Divider margin="md" />
        {errors.map((error) => (
          <Banner message={error} type="error" />
        ))}
        <ResourcesConfigForm config={config} onChange={setConfig} mode="edit" />
      </ColumnContent>
    </Column>
  );
};

export default BulkImportColumn;
