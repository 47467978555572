import {
  ConnectionType,
  EntityType,
  ResourceCustomAccessLevelFragment,
  ResourceType,
  ServiceType,
  useDeleteResourceCustomAccessLevelsMutation,
  useResourceCustomAccessLevelsQuery,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Input, Modal } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableFilters from "components/ui/table/TableFilters";
import TableHeader from "components/ui/table/TableHeader";
import moment from "moment";
import pluralize from "pluralize";
import { useMemo, useState } from "react";
import { useImmer } from "use-immer";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import { hasPolicyType } from "utils/directory/connections";
import { canImportCustomRoles } from "utils/directory/resources";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ResourceCustomRoleEditLabel from "views/resources/ResourceCustomRoleEditLabel";
import { CreateRoleWrapperModal } from "views/resources/viewer/rows/ResourceCustomRolesRow";
import { dropNothings } from "views/utils";

type ResourceCustomRolesRowProps = {
  resource: {
    id: string;
    name: string;
    serviceType: ServiceType;
    connection?: {
      connectionType: ConnectionType;
    } | null;
    resourceType: ResourceType;
    authorizedActions?: string[] | null;
  };
};

type ResourceCustomRolesTableRow = {
  roleName: string;
  remoteId: string;
  policy: string;
  priority: number;
  lastUpdated: string;
  actions?: string;
  role: ResourceCustomAccessLevelFragment;
};

const ResourceCustomRolesTableV3 = (props: ResourceCustomRolesRowProps) => {
  // V3 table
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 100);
  const [checkedRowIds, setCheckedRowIds] = useImmer<string[]>([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const transitionTo = useTransitionTo();

  const { data, error, loading } = useResourceCustomAccessLevelsQuery({
    variables: {
      input: {
        resourceId: props.resource.id,
      },
    },
    fetchPolicy: "cache-first",
  });

  const columns: Header<ResourceCustomRolesTableRow>[] = dropNothings([
    { id: "roleName", label: "Role" },
    { id: "remoteId", label: "Remote ID" },
    hasPolicyType(props.resource.resourceType)
      ? {
          id: "policy",
          label: "Policy",
        }
      : null,
    props.resource.serviceType === ServiceType.OktaDirectory
      ? {
          id: "priority",
          label: "Priority",
        }
      : null,
    {
      id: "lastUpdated",
      label: "Last Updated",
      customCellRenderer: (row) => {
        const updatedAtTime = moment(new Date(row.lastUpdated));
        return updatedAtTime.fromNow();
      },
    },
    hasPolicyType(props.resource.resourceType)
      ? {
          id: "actions",
          label: "Actions",
          customCellRenderer: (row) => {
            return (
              <ResourceCustomRoleEditLabel
                resourceCustomRole={row.role}
                serviceType={props.resource.serviceType}
                resourceType={props.resource.resourceType}
                resourceCustomRolesCount={
                  data?.resourceCustomAccessLevels.resourceCustomAccessLevels
                    .length ?? 0
                }
              />
            );
          },
        }
      : null,
  ]);

  const rows: ResourceCustomRolesTableRow[] = useMemo(() => {
    return (
      data?.resourceCustomAccessLevels.resourceCustomAccessLevels
        .filter((role) => {
          if (debouncedSearchQuery === "") return true;
          return (
            role.accessLevel.accessLevelName
              .toLowerCase()
              .includes(debouncedSearchQuery.toLowerCase()) ||
            role.accessLevel.accessLevelRemoteId
              .toLowerCase()
              .includes(debouncedSearchQuery.toLowerCase())
          );
        })
        .map((role) => {
          return {
            role: role,
            roleName: role.accessLevel.accessLevelName,
            remoteId: role.accessLevel.accessLevelRemoteId,
            policy: role.policy || "",
            priority: role.priority || 0,
            lastUpdated: role.updatedAt,
          };
        }) ?? []
    );
  }, [debouncedSearchQuery, data]);

  if (error) {
    logError(error, `unexpected error listing resource policies`);
    return <UnexpectedErrorPage error={error} />;
  }

  const rightActions: PropsFor<typeof TableHeader>["defaultRightActions"] = [];

  const canImportRoles = canImportCustomRoles(
    props.resource.serviceType,
    props.resource.connection?.connectionType
  );

  if (canImportRoles) {
    rightActions.push({
      label: "Import",
      iconName: "plus",
      type: "main",
      onClick: () => {
        transitionTo({
          pathname: `/resources/${props.resource.id}/import-roles`,
        });
      },
    });
  } else {
    rightActions.push({
      label: "Create",
      iconName: "plus",
      type: "main",
      onClick: () => {
        setShowCreateModal(true);
      },
    });
  }

  const resourceCustomRoles: ResourceCustomAccessLevelFragment[] =
    data?.resourceCustomAccessLevels.resourceCustomAccessLevels ?? [];
  const canManage = props.resource.authorizedActions?.includes(
    AuthorizedActionManage
  );

  return (
    <>
      <TableFilters>
        <TableFilters.Left>
          <div style={{ width: "200px" }}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Filter roles by name or ID"
            />
          </div>
        </TableFilters.Left>
      </TableFilters>
      <TableHeader
        entityType={EntityType.AccessLevel}
        totalNumRows={rows.length}
        loading={loading}
        defaultRightActions={canManage ? rightActions : []}
        selectedNumRows={checkedRowIds.length}
        bulkRightActions={
          canManage
            ? [
                {
                  label: "Remove",
                  type: "danger",
                  iconName: "trash",
                  onClick: () => {
                    setShowDeleteModal(true);
                  },
                },
              ]
            : []
        }
      />
      <Table
        totalNumRows={rows.length}
        loadingRows={loading}
        rows={rows}
        getRowId={(row) => row.role.id}
        columns={columns}
        defaultSortBy="roleName"
        checkedRowIds={new Set(checkedRowIds)}
        onCheckedRowsChange={(ids, checked) => {
          if (checked) {
            setCheckedRowIds((draft) => {
              draft.push(...ids);
            });
          } else {
            setCheckedRowIds((draft) => {
              return draft.filter((id) => !ids.includes(id));
            });
          }
        }}
      />

      {showCreateModal && (
        <CreateRoleWrapperModal
          // TODO: create a new modal better suited for v3
          resource={props.resource}
          showCreateModal={showCreateModal}
          setShowCreateModal={setShowCreateModal}
          resourceCustomRoles={resourceCustomRoles}
        />
      )}
      {showDeleteModal && (
        <DeleteRoleConfirmModal
          idsToDelete={checkedRowIds}
          onClose={() => {
            setShowDeleteModal(false);
            setCheckedRowIds([]);
            setSearchQuery("");
          }}
          showConfirmModal={showDeleteModal}
          resourceType={props.resource.resourceType}
        />
      )}
    </>
  );
};

const DeleteRoleConfirmModal = (props: {
  resourceType: ResourceType;
  idsToDelete: string[];
  showConfirmModal: boolean;
  onClose: () => void;
}) => {
  const { displaySuccessToast } = useToast();
  const logEvent = useLogEvent();

  const [errorMessage, setErrorMessage] = useState<string>();
  const [
    deleteResourceCustomAccessLevels,
    { loading },
  ] = useDeleteResourceCustomAccessLevelsMutation();

  return (
    <Modal
      isOpen={props.showConfirmModal}
      onClose={props.onClose}
      title={"Remove Roles"}
    >
      <Modal.Body>
        {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
        <p>
          Are you sure you want to remove{" "}
          {pluralize("role", props.idsToDelete.length, true)}?
        </p>
        <p>
          Deleting a role will remove access for all users and groups using that
          role. Access will be removed from both Opal and the end system.
        </p>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel={"Confirm"}
        primaryButtonLoading={loading}
        onPrimaryButtonClick={async () => {
          logEvent({
            name: "apps_delete_role",
            properties: {
              resourceType: props.resourceType,
            },
          });

          try {
            const { data } = await deleteResourceCustomAccessLevels({
              variables: {
                input: {
                  ids: props.idsToDelete,
                },
              },
              refetchQueries: ["ResourceCustomAccessLevels"],
            });
            switch (data?.deleteResourceCustomAccessLevels.__typename) {
              case "DeleteResourceCustomAccessLevelsResult":
                props.onClose();
                displaySuccessToast("Success: resource custom roles deleted");
                break;
              default:
                logError(new Error(`failed to delete resource custom roles`));
                setErrorMessage(
                  `Error: failed to delete resource custom roles`
                );
            }
          } catch (error) {
            logError(error, `failed to delete resource custom roles`);
            setErrorMessage(`Error: failed to delete resource custom roles`);
          }
        }}
      />
    </Modal>
  );
};

export default ResourceCustomRolesTableV3;
