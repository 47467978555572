import * as styles from "./Header.css";

interface Props {
  type: "column";
  text: string;
}

const Header = ({ type, text }: Props) => {
  switch (type) {
    case "column":
      return <h2 className={styles.columnHeader}>{text}</h2>;
  }
};

export default Header;
