import AuthContext from "components/auth/AuthContext";
import React, {
  createContext,
  Dispatch,
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useReducer,
} from "react";
import { useHistory } from "react-router";
import { hasBasicPermissions } from "utils/auth/auth";

export enum NavigationContextActionType {
  IsOnlyMineFilterChange,
  IsOnlyMyReviewsFilterChange,
  IsOnlyOngoingReviewsFilterChange,
  RefetchSyncItemsFuncInit,
  RefetchSyncStatusFuncInit,
  SetInitialHistorySize,
}

export type NavigationContextState = {
  isOnlyMineFilterOn: boolean;
  isOnlyMyReviewsFilterOn: boolean;
  isOnlyOngoingReviewsFilterOn: boolean;
  initialHistorySize: number | undefined | null;
};

export type NavigationContextEvent = {
  type: NavigationContextActionType;
  payload: {
    isOnlyMineFilterOn?: boolean | undefined | null;
    isOnlyMyReviewsFilterOn?: boolean | undefined | null;
    isOnlyOngoingReviewsFilterOn?: boolean | undefined | null;
    initialHistorySize?: number | undefined | null;
  };
};

const initialNavigationContext: NavigationContextState = {
  isOnlyMineFilterOn: false,
  isOnlyMyReviewsFilterOn: false,
  isOnlyOngoingReviewsFilterOn: false,
  initialHistorySize: null,
};

const NavigationContext = createContext<{
  navigationState: NavigationContextState;
  navigationDispatch: Dispatch<NavigationContextEvent>;
}>({
  navigationState: initialNavigationContext,
  navigationDispatch: () => null,
});

const reducer = (
  state: NavigationContextState,
  action: NavigationContextEvent
): NavigationContextState => {
  switch (action.type) {
    case NavigationContextActionType.IsOnlyMineFilterChange:
      return {
        ...state,
        isOnlyMineFilterOn: action.payload.isOnlyMineFilterOn!,
      };
    case NavigationContextActionType.IsOnlyMyReviewsFilterChange:
      return {
        ...state,
        isOnlyMyReviewsFilterOn: action.payload.isOnlyMyReviewsFilterOn!,
      };
    case NavigationContextActionType.IsOnlyOngoingReviewsFilterChange:
      return {
        ...state,
        isOnlyOngoingReviewsFilterOn: action.payload
          .isOnlyOngoingReviewsFilterOn!,
      };
    case NavigationContextActionType.SetInitialHistorySize:
      return {
        ...state,
        initialHistorySize: action.payload.initialHistorySize!,
      };
    default:
      return state;
  }
};

export const NavigationContextProvider: FunctionComponent = (
  props: PropsWithChildren<{}>
) => {
  const history = useHistory();
  const { authState } = useContext(AuthContext);

  const navigationContext = {
    ...initialNavigationContext,
    isOnlyMyReviewsFilterOn: hasBasicPermissions(authState.user),
  };
  const [navigationState, navigationDispatch] = useReducer(
    reducer,
    navigationContext
  );

  if (!navigationState.initialHistorySize) {
    navigationDispatch({
      type: NavigationContextActionType.SetInitialHistorySize,
      payload: {
        initialHistorySize: history.length,
      },
    });
  }

  return (
    <NavigationContext.Provider
      value={{
        navigationState: navigationState,
        navigationDispatch: navigationDispatch,
      }}
    >
      {props.children}
    </NavigationContext.Provider>
  );
};

export default NavigationContext;
