import {
  AccessReviewResourceUserFragment,
  EntityType,
  ReviewerUserFragment,
} from "api/generated/graphql";
import { ResourceLabel } from "components/label/Label";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import TableSimple, { Header } from "components/ui/table/TableSimple";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import { useParams } from "react-router";

import AccessReviewContext from "../AccessReviewContext";
import { DecisionLabel } from "../AccessReviewReviewerDetails";
import * as styles from "./ResourceUserSubRow.css";
import UserMoreInfo from "./UserMoreInfo";

interface Props {
  resourceUser: AccessReviewResourceUserFragment;
}

interface RowData {
  id: string;
  reviewer: string;
  decision: string;
  role: string;
  resourceUpdate: string;
  note: string;
  data: ReviewerUserFragment;
}

const ResourceUserSubRow = ({ resourceUser }: Props) => {
  const { accessReviewResourceId } = useParams<Record<string, string>>();
  const { accessReviewState } = useContext(AccessReviewContext);

  const performReviewState =
    accessReviewState.performReviewStateByUARResourceId[accessReviewResourceId];
  const reviewers = resourceUser.reviewerUsers ?? [];

  const hasRoleUpdate = reviewers.some((reviewer) =>
    Boolean(reviewer.updatedAccessLevel?.accessLevelName)
  );
  const hasResourceUpdate = reviewers.some((reviewer) =>
    Boolean(reviewer.updatedResourceId)
  );
  let resourceUpdateColumnName = "Updated Resource Access";

  const resourceType = reviewers
    .filter((reviewer) => !!reviewer.updatedResource)
    .map((reviewer) => reviewer.updatedResource?.resourceType)
    .shift();

  if (hasResourceUpdate && resourceType) {
    const resourceTypeInfo = resourceTypeInfoByType[resourceType];
    resourceUpdateColumnName = "Updated " + resourceTypeInfo.name;
  }

  const columns: Header<RowData>[] = [
    {
      id: "reviewer",
      label: "Reviewer",
      cellRenderer: ({ data }) => {
        const reviewer = data;
        return (
          <ResourceLabel
            text={reviewer.name}
            entityTypeNew={EntityType.User}
            avatar={reviewer.avatarUrl}
            entityId={reviewer.userId}
          />
        );
      },
    },
    {
      id: "decision",
      label: "Decision",
      cellRenderer: ({ data }) => {
        return <DecisionLabel status={data.status} />;
      },
    },
  ];
  if (hasRoleUpdate) {
    columns.push({
      id: "role",
      label: "Updated Role",
    });
  }
  if (hasResourceUpdate) {
    columns.push({
      id: "resourceUpdate",
      label: resourceUpdateColumnName,
      cellRenderer: ({ data }) => {
        const reviewer = data;
        return (
          <ResourceLabel
            entityId={reviewer.updatedResource?.id}
            serviceType={reviewer.updatedResource?.serviceType}
            resourceType={reviewer.updatedResource?.resourceType}
            text={reviewer.updatedResource?.name}
            entityTypeNew={EntityType.Resource}
          />
        );
      },
    });
  }
  columns.push({
    id: "note",
    label: "Review Note",
    cellRenderer: ({ data }) => {
      const updatedNote = performReviewState?.resourceUserActions.find(
        (resourceUserAction) =>
          resourceUserAction.accessReviewResourceUserId === resourceUser.id
      )?.note;
      return <span>{updatedNote ?? data.note ?? "--"}</span>;
    },
  });

  const rows: RowData[] = reviewers.map((reviewer) => {
    return {
      id: reviewer.userId,
      reviewer: reviewer.userId,
      decision: reviewer.status,
      role: reviewer.updatedAccessLevel?.accessLevelName || "--",
      resourceUpdate: reviewer.updatedResource?.id ?? "",
      note: reviewer.note || "--",
      data: reviewer,
    };
  });

  return (
    <div className={styles.container}>
      {resourceUser.user && <UserMoreInfo user={resourceUser.user} />}
      {rows.length > 0 ? (
        <TableSimple columns={columns} rows={rows} getRowId={(row) => row.id} />
      ) : (
        <div className={sprinkles({ color: "gray600", fontSize: "bodySm" })}>
          No reviewers assigned
        </div>
      )}
    </div>
  );
};

export default ResourceUserSubRow;
