import axios from "axios";
import moment from "moment";

export enum UARExportType {
  Report = "report",
  ResourceUsers = "resource-users",
  GroupUsers = "group-users",
}

/**
 * Send a request to download an access review export.
 */
export const handleRequestExport = (
  accessReview: {
    id: string;
    name: string;
    stoppedDate?: string | null;
  },
  exportType: UARExportType,
  displayLoadingToast?: (message?: string) => void,
  displaySuccessToast?: (
    message: string,
    linkTitle?: string,
    link?: string
  ) => void,
  displayErrorToast?: (message: string) => void,
  param?: string
) => {
  let url = "/export/audits/" + accessReview.id;
  let exportDesc, exportExt, exportName: string;

  if (exportType === UARExportType.Report) {
    if (accessReview.stoppedDate === null) {
      throw new Error(
        "An access review must be stopped before an audit report can be downloaded"
      );
    }
    url += "/report";
    exportExt = "pdf";
  } else if (exportType === UARExportType.ResourceUsers) {
    url += "/resources/users";
    exportDesc = "Resource_Users";
    exportExt = "csv";
  } else if (exportType === UARExportType.GroupUsers) {
    url += "/groups/users";
    exportDesc = "Group_Users";
    exportExt = "csv";
  }

  if (param) {
    url += param;
  }

  if (exportType === UARExportType.Report) {
    exportName = `Opal-${accessReview.name}-${moment().format(
      "YYYYMMDD"
    )}.${exportExt}`;
  } else {
    exportName = `Opal-${accessReview.name}-${exportDesc}-${moment().format(
      "YYYYMMDD"
    )}.${exportExt}`;
  }

  displayLoadingToast && displayLoadingToast("Generating export...");
  axios({
    url: url,
    method: "GET",
    responseType: "blob",
  })
    .then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", exportName);
      link.click();
      displaySuccessToast &&
        displaySuccessToast(`Success: downloaded ` + exportName);
    })
    .catch(async (error) => {
      let message;
      if (
        error.request.responseType === "blob" &&
        error.response.data instanceof Blob &&
        error.response.data.type &&
        error.response.data.type.toLowerCase().indexOf("json") !== -1
      ) {
        message = JSON.parse(await error.response.data.text());
      } else {
        message = `Error: failed to generate export (${error})`;
      }
      displayErrorToast && displayErrorToast(message);
    });
};
