import { VisualizationDataResultFragment } from "api/generated/graphql";
import { makeRolelNodeId } from "components/viz/utils";
import { produce } from "immer";
import { uniq } from "lodash";
import { createContext, Dispatch } from "react";

type GraphNeighborsMap = { [nodeId: string]: string[] };
export interface GraphState {
  selectedUserIds: string[];
  selectedGroupIds: string[];
  selectedResourceIds: string[];
  expandedIds: string[];
  data: VisualizationDataResultFragment | undefined;
  neighbors: GraphNeighborsMap;
  loading: boolean;
  error?: string;
}

export const graphInitialState: GraphState = {
  selectedUserIds: [],
  selectedGroupIds: [],
  selectedResourceIds: [],
  expandedIds: [],
  data: undefined,
  neighbors: {},
  loading: false,
};

export const GraphContext = createContext<{
  graphState: GraphState;
  graphDispatch: Dispatch<GraphAction>;
}>({
  graphState: graphInitialState,
  graphDispatch: () => null,
});

export type GraphAction =
  | {
      type: "EXPAND_RESOURCES";
      payload: {
        resourceIds: string[];
      };
    }
  | {
      type: "TOGGLE_EXPAND_NODE";
      payload: {
        nodeId: string;
      };
    }
  | {
      type: "TOGGLE_SELECT_USER";
      payload: {
        userId: string;
      };
    }
  | {
      type: "TOGGLE_SELECT_GROUP";
      payload: {
        groupId: string;
      };
    }
  | {
      type: "TOGGLE_SELECT_RESOURCE";
      payload: {
        resourceId: string;
      };
    }
  | {
      type: "CLEAR_SELECTED";
    }
  | {
      type: "SET_DATA";
      payload: {
        data: VisualizationDataResultFragment;
      };
    }
  | {
      type: "SET_LOADING";
      payload: {
        loading: boolean;
      };
    }
  | {
      type: "SET_ERROR";
      payload: {
        error?: string;
      };
    };

export function graphReducer(
  state: GraphState,
  action: GraphAction
): GraphState {
  switch (action.type) {
    case "EXPAND_RESOURCES": {
      const { resourceIds } = action.payload;
      return produce(state, (draft) => {
        draft.expandedIds = uniq([...draft.expandedIds, ...resourceIds]);
      });
    }
    case "TOGGLE_EXPAND_NODE": {
      const { nodeId } = action.payload;
      return produce(state, (draft) => {
        if (draft.expandedIds.includes(nodeId)) {
          draft.expandedIds = draft.expandedIds.filter((id) => id !== nodeId);
        } else {
          draft.expandedIds.push(nodeId);
        }
      });
    }
    case "TOGGLE_SELECT_USER": {
      const { userId } = action.payload;
      return produce(state, (draft) => {
        if (draft.selectedUserIds.includes(userId)) {
          draft.selectedUserIds = draft.selectedUserIds.filter(
            (id) => id !== userId
          );
        } else {
          draft.selectedUserIds.push(userId);
        }
      });
    }
    case "TOGGLE_SELECT_GROUP": {
      const { groupId } = action.payload;
      return produce(state, (draft) => {
        if (draft.selectedGroupIds.includes(groupId)) {
          draft.selectedGroupIds = draft.selectedGroupIds.filter(
            (id) => id !== groupId
          );
        } else {
          draft.selectedGroupIds.push(groupId);
        }
      });
    }
    case "TOGGLE_SELECT_RESOURCE": {
      const { resourceId } = action.payload;
      return produce(state, (draft) => {
        if (draft.selectedResourceIds.includes(resourceId)) {
          draft.selectedResourceIds = draft.selectedResourceIds.filter(
            (id) => id !== resourceId
          );
        } else {
          draft.selectedResourceIds.push(resourceId);
        }
      });
    }
    case "CLEAR_SELECTED": {
      return produce(state, (draft) => {
        draft.selectedGroupIds = [];
        draft.selectedResourceIds = [];
        draft.selectedUserIds = [];
      });
    }
    case "SET_DATA": {
      return produce(state, (draft) => {
        draft.data = action.payload.data;
        draft.neighbors = makeGraphNeighborsMap(action.payload.data);
      });
    }
    case "SET_LOADING": {
      return produce(state, (draft) => {
        draft.loading = action.payload.loading;
        if (action.payload.loading) {
          draft.error = undefined;
        }
      });
    }
    case "SET_ERROR": {
      return produce(state, (draft) => {
        draft.error = action.payload.error;
      });
    }
  }
}

const makeGraphNeighborsMap = (vizData?: VisualizationDataResultFragment) => {
  const neighbors: { [nodeId: string]: string[] } = {};
  vizData?.groupResourceEdges.forEach((gr) => {
    if (gr.groupId in neighbors) {
      neighbors[gr.groupId].push(gr.resourceId);
    } else {
      neighbors[gr.groupId] = [gr.resourceId];
    }
    if (gr.resourceId in neighbors) {
      neighbors[gr.resourceId].push(gr.groupId);
    } else {
      neighbors[gr.resourceId] = [gr.groupId];
    }
  });
  vizData?.userGroupEdges.forEach((ug) => {
    if (ug.groupId in neighbors) {
      neighbors[ug.groupId].push(ug.userId);
    } else {
      neighbors[ug.groupId] = [ug.userId];
    }
    if (ug.userId in neighbors) {
      neighbors[ug.userId].push(ug.groupId);
    } else {
      neighbors[ug.userId] = [ug.groupId];
    }
  });
  vizData?.userResourceEdges.forEach((ur) => {
    const resourceKey = makeRolelNodeId(ur.resourceId, ur.accessLevel);
    if (resourceKey in neighbors) {
      neighbors[resourceKey].push(ur.userId);
    } else {
      neighbors[resourceKey] = [ur.userId];
    }
    if (ur.userId in neighbors) {
      neighbors[ur.userId].push(resourceKey);
    } else {
      neighbors[ur.userId] = [resourceKey];
    }
  });
  return neighbors;
};
