import {
  EntityType,
  Maybe,
  RemoveResourceTagInput,
  ResourceTagFragment,
  TagFragment,
  useRemoveResourceTagsMutation,
} from "api/generated/graphql";
import { ConditionalEditor } from "components/entity_viewer/editor/ConditionalEditor";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import ResourceLabel from "components/label/item_labels/ResourceLabel";
import SelectItemsModal, {
  SelectType,
} from "components/modals/update/SelectItemsModal";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import { useToast } from "components/toast/Toast";
import pluralize from "pluralize";
import { useState } from "react";
import { logError } from "utils/logging";
import TagAddResourcesModal from "views/tags/TagAddResourcesModal";
import TagResourcesTable from "views/tags/TagResourcesTable";

type TagResourcesRowProps = {
  tag: TagFragment;
};

export const TagResourcesRow = (props: TagResourcesRowProps) => {
  const [showResourcesAddModal, setShowResourcesAddModal] = useState(false);
  const [showResourcesRemoveModal, setShowResourcesRemoveModal] = useState(
    false
  );

  const editor = (
    <ConditionalEditor
      menuOptions={[
        {
          label: "Add",
          handler: () => {
            setShowResourcesAddModal(true);
          },
        },
        {
          label: "Remove",
          handler: () => {
            setShowResourcesRemoveModal(true);
          },
        },
      ]}
    />
  );

  const addTagToResourcesModal = (
    <TagAddResourcesModal
      tag={props.tag}
      onClose={() => {
        setShowResourcesAddModal(false);
      }}
    />
  );

  const removeTagFromResourcesModal = (
    <RemoveTagFromResourcesModal
      key={`remove-tag-from-resources-modal`}
      tag={props.tag}
      resourceTags={props.tag.tagResources}
      showModal={showResourcesRemoveModal}
      setShowModal={setShowResourcesRemoveModal}
    />
  );

  return (
    <EntityViewerRow
      title={"Resources"}
      content={
        <EmptyStateContentWrapper
          content={
            <TagResourcesTable
              tag={props.tag}
              tagResources={props.tag.tagResources}
            />
          }
          entityType={EntityType.Resource}
          title={`No resources have this tag`}
          subtitle={`Add this tag to resources to populate the table`}
          buttonTitle={`Add resources`}
          isEmpty={props.tag.tagResources.length === 0}
          onClickHandler={() => {
            setShowResourcesAddModal(true);
          }}
          small={true}
        />
      }
      editor={editor}
      modals={
        <>
          {showResourcesAddModal && addTagToResourcesModal}
          {showResourcesRemoveModal && removeTagFromResourcesModal}
        </>
      }
      isTable={true}
    />
  );
};

type RemoveTagFromResourcesModalProps = {
  tag: TagFragment;
  resourceTags: ResourceTagFragment[];
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

const RemoveTagFromResourcesModal = (
  props: RemoveTagFromResourcesModalProps
) => {
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [searchQuery, setSearchQuery] = useState<string>("");

  const { displaySuccessToast } = useToast();

  const [removeTagResources, { loading }] = useRemoveResourceTagsMutation();
  const [idsToRemove, setIdsToRemove] = useState<string[]>([]);

  const modalReset = () => {
    props.setShowModal(false);
    setErrorMessage(null);
    setSearchQuery("");
  };

  const onSubmit = async () => {
    const resourceTagsToRemove: RemoveResourceTagInput[] = [];
    idsToRemove.forEach((resourceTagId) => {
      resourceTagsToRemove.push({
        resourceTagId: resourceTagId,
      });
    });

    try {
      const { data } = await removeTagResources({
        variables: {
          input: {
            resourceTags: resourceTagsToRemove,
          },
        },
        refetchQueries: ["ResourceTags", "Tag"],
      });
      switch (data?.removeResourceTags.__typename) {
        case "RemoveResourceTagsResult": {
          let resourceTags = [];
          data.removeResourceTags.entries.forEach((entry) => {
            switch (entry.__typename) {
              case "RemoveResourceTagsEntryResult":
                resourceTags.push(entry.resourceTag);
                break;
            }
          });
          if (resourceTags.length !== idsToRemove.length) {
            logError(new Error(`removed tag to some, but not all resources`));
            setErrorMessage(
              `Error: removed tag to some, but not all resources`
            );
          } else {
            modalReset();
            displaySuccessToast(
              `Success: removed tag from ${pluralize(
                "resource",
                idsToRemove.length,
                true
              )}`
            );
          }
          break;
        }
        case "ResourceTagNotFoundError": {
          logError(new Error(data.removeResourceTags.message));
          setErrorMessage(data.removeResourceTags.message);
          break;
        }
        default:
          logError(new Error(`failed to remove tag from selected resources`));
          setErrorMessage(
            "Error: failed to remove tag from selected resources"
          );
      }
    } catch (error) {
      logError(error, `failed to remove group tag from selected resources`);
      setErrorMessage("Error: failed to remove tag from selected resources");
    }
  };

  return (
    <SelectItemsModal
      key={"resources_remove"}
      title={"Remove tag from resources"}
      selectType={SelectType.Remove}
      entryInfos={props.tag.tagResources
        .filter((tagResource) =>
          tagResource.resource?.name
            .toLowerCase()
            .includes(searchQuery.toLocaleLowerCase())
        )
        .sort((a, b) => {
          if (a && b) {
            const aSortString = a.resource?.name || "";
            const bSortString = b.resource?.name || "";
            return aSortString.localeCompare(bSortString);
          }
          return 0;
        })
        .map((tagResource) => {
          return {
            entityId: {
              entityId: tagResource.id,
              entityType: EntityType.Resource,
            },
            label: (
              <ResourceLabel
                resourceId={tagResource.resourceId}
                text={tagResource.resource?.name}
              />
            ),
            isBold: false,
            isBreadcrumb: false,
          };
        })}
      itemName={"resource"}
      isModalOpen={props.showModal}
      errorMessage={errorMessage}
      submitDisabled={idsToRemove.length === 0}
      onClose={modalReset}
      onSubmit={onSubmit}
      loading={loading}
      idsToUpdate={idsToRemove}
      setIdsToUpdate={setIdsToRemove}
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
    />
  );
};

export default TagResourcesRow;
