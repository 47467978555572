import {
  useNotificationsQuery,
  useReadUiNotificationsMutation,
} from "api/generated/graphql";
import { SelfRefreshingTimestamp } from "components/time/time";
import { ButtonV3, Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { throttle } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";

import * as styles from "./notificationIcon.css";

const THROTTLE_TIME = 10 * 1000;
const INTERVAL_TIME = 60 * 1000;

interface Notification {
  id: string;
  createdAt: string;
  body: string;
  url: string;
  readAt?: string;
  trackingId?: string;
}

const NotificationIcon: React.FC = () => {
  const [showNotifications, setShowNotifications] = useState(false);

  const {
    data: notificationsData,
    loading: notificationLoading,
    refetch: baseNotificationsRefetch,
  } = useNotificationsQuery();

  const refetch = useRef(throttle(baseNotificationsRefetch, THROTTLE_TIME));
  const checkRefetch = useCallback(() => {
    if (document.visibilityState === "hidden") {
      return;
    }
    refetch.current();
  }, [refetch]);

  useEffect(() => {
    // Refetch the notifications every minute or when we regain visibility,
    // at most every 10 seconds.
    const refetcher = setInterval(() => {
      checkRefetch();
    }, INTERVAL_TIME);

    document.addEventListener("visibilitychange", checkRefetch);
    return () => {
      clearInterval(refetcher);
      document.removeEventListener("visibilitychange", checkRefetch);
    };
  }, [checkRefetch]);

  const notifications =
    !notificationLoading && notificationsData
      ? [...notificationsData.uiNotifications]
          .sort((a, b) => moment(b.createdAt).diff(moment(a.createdAt)))
          .map((notification) => ({
            id: notification.id,
            createdAt: notification.createdAt,
            body: notification.body,
            url: notification.url,
            readAt: notification.readAt ?? undefined,
            trackingId: notification.trackingId ?? undefined,
          }))
      : [];

  // Add a 250ms delay to the notifications popover so there is some buffer
  // for the user to hover on it.
  let hideNotificationsTimeout: NodeJS.Timeout;
  const handleHideNotifications = () => {
    hideNotificationsTimeout = setTimeout(() => {
      setShowNotifications(false);
    }, 250);
  };
  const handleShowNotifications = () => {
    clearTimeout(hideNotificationsTimeout);
    setShowNotifications(true);
  };

  return (
    <div
      className={styles.notificationButtonContainer}
      onMouseLeave={handleHideNotifications}
    >
      <ButtonV3
        leftIconName={
          notifications && notifications.filter((n) => !n.readAt).length > 0
            ? "bell-notification"
            : "bell"
        }
        size="lg"
        onClick={() => handleShowNotifications()}
      />
      <NotificationPopover
        showNotifications={showNotifications}
        handleShowNotifications={handleShowNotifications}
        handleHideNotifications={handleHideNotifications}
        notifications={notifications}
      />
    </div>
  );
};

interface NotificationPopoverProps {
  showNotifications: boolean;
  handleShowNotifications: () => void;
  handleHideNotifications: () => void;
  notifications: Notification[];
}

const NotificationPopover: React.FC<NotificationPopoverProps> = (props) => {
  const [readUINotifications] = useReadUiNotificationsMutation({
    refetchQueries: ["Notifications"],
  });
  const history = useHistory();

  if (!props.showNotifications) return null;
  if (props.notifications.length === 0) {
    return (
      <div
        className={styles.notificationsContainer}
        onMouseEnter={props.handleShowNotifications}
        onMouseLeave={props.handleHideNotifications}
      >
        <div className={styles.noNotificationsContainer}>
          <Icon name="bell" size="xs" />
          <div className={styles.noNotificationsTitle}>No Notifications</div>
          <div className={styles.noNotificationsSubtitle}>
            Good time to get a coffee.
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      className={styles.notificationsContainer}
      onMouseEnter={props.handleShowNotifications}
      onMouseLeave={props.handleHideNotifications}
    >
      <div
        className={sprinkles({
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginBottom: "sm",
        })}
      >
        <div
          className={sprinkles({
            display: "flex",
            alignItems: "center",
            fontWeight: "medium",
            gap: "sm",
          })}
        >
          <div className={sprinkles({ fontSize: "textLg" })}>Notifications</div>
          <div className={styles.notificationsCount}>
            {props.notifications.filter((n) => !n.readAt).length}
          </div>
        </div>
        <div
          className={sprinkles({
            color: "blue600V3",
            cursor: "pointer",
          })}
          onClick={() => {
            readUINotifications({
              variables: {
                input: { notificationIds: [], markAllAsRead: true },
              },
            });
          }}
        >
          Mark all as read
        </div>
      </div>
      {props.notifications.map((n) => (
        <button
          className={styles.notification}
          onClick={() => {
            readUINotifications({
              variables: {
                input: { notificationIds: [n.id], markAllAsRead: false },
              },
            });
            history.push(n.url);
          }}
        >
          <div>{n.body}</div>
          <div className={styles.timestamp}>
            {!n.readAt ? <div className={styles.unreadDot} /> : null}
            <SelfRefreshingTimestamp timestamp={moment(n.createdAt)} />
          </div>
        </button>
      ))}
    </div>
  );
};

export default NotificationIcon;
