import { getModifiedErrorMessage } from "api/ApiContext";
import {
  EntityType,
  PropagationStatusCode,
  RemoveRoleAssignmentInput,
  RoleAssignmentFragment,
  useRemoveRoleAssignmentsMutation,
} from "api/generated/graphql";
import { ResourceLabel, TimeLabel } from "components/label/Label";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import PropagationStatusLabelWithModal from "components/propagation/PropagationStatusLabelWithModal";
import { Input, Modal } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableFilters from "components/ui/table/TableFilters";
import TableHeader from "components/ui/table/TableHeader";
import { compareAccessPaths } from "components/ui/table/utils";
import sprinkles from "css/sprinkles.css";
import { Maybe } from "graphql/jsutils/Maybe";
import moment from "moment";
import pluralize from "pluralize";
import { useState } from "react";
import useLogEvent from "utils/analytics";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { PropagationType } from "utils/useRemediations";
import {
  AccessPathsInfo,
  getRoleAssignmentAccessPathsInfo,
  RoleAssignmentAccessPointsLabel,
} from "views/role_assignments/RoleAssignmentAccessPointsLabel";
import { dropNothings } from "views/utils";

import * as styles from "./RoleAssignmentsTable.css";
import { truncateResourceAncestorPath } from "./utils";

interface RoleAssignmentRow {
  id: string;
  name: string;
  role: string;
  accessPaths: AccessPathsInfo;
  expiry?: string | null;
  syncStatus?: PropagationStatusCode | null;
  roleAssignment: RoleAssignmentFragment;
}
type RoleAssignmentsTableV3Props = {
  resourceId: string;
  roleAssignments: RoleAssignmentFragment[];
  canManage?: boolean;
  mode: "principalView" | "entityView";
};

export const RoleAssignmentsTable = (props: RoleAssignmentsTableV3Props) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const transitionTo = useTransitionTo();
  const [
    removeAssignmentsErrorMessage,
    setRemoveAssignmentsErrorMessage,
  ] = useState<Maybe<string>>(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const startPushTaskPoll = usePushTaskLoader();
  const logEvent = useLogEvent();

  const viewingPrincipalsList = props.mode === "principalView";

  const ROLE_ASSIGNMENT_COLUMNS: Header<RoleAssignmentRow>[] = [
    {
      id: "name",
      label: viewingPrincipalsList ? "Principal" : "Resource",
      sortable: true,
      customCellRenderer: (row) => {
        if (viewingPrincipalsList) {
          const avatarUrl =
            row.roleAssignment.principal?.__typename === "User"
              ? row.roleAssignment.principal.avatarUrl
              : undefined;
          const resourceType =
            row.roleAssignment.principal?.__typename === "Resource"
              ? row.roleAssignment.principal.resourceType
              : undefined;
          const groupType =
            row.roleAssignment.principal?.__typename === "Group"
              ? row.roleAssignment.principal.groupType
              : undefined;
          const subText =
            row.roleAssignment.principal?.__typename === "Resource"
              ? truncateResourceAncestorPath(
                  row.roleAssignment.principal.ancestorPathToResource
                )
              : undefined;
          return (
            <div>
              <ResourceLabel
                text={row.name}
                subText={subText}
                pointerCursor={true}
                entityId={row.roleAssignment.principalID}
                entityTypeNew={row.roleAssignment.principalType}
                resourceType={resourceType}
                groupType={groupType}
                avatar={avatarUrl}
                maxChars={45}
                bold
              />
            </div>
          );
        } else {
          const resourceType =
            row.roleAssignment.entity?.__typename === "Resource"
              ? row.roleAssignment.entity.resourceType
              : undefined;
          const groupType =
            row.roleAssignment.entity?.__typename === "Group"
              ? row.roleAssignment.entity.groupType
              : undefined;
          const subText =
            row.roleAssignment.entity?.__typename === "Resource"
              ? truncateResourceAncestorPath(
                  row.roleAssignment.entity.ancestorPathToResource
                )
              : undefined;
          return (
            <div>
              <ResourceLabel
                text={row.name}
                subText={subText}
                pointerCursor={true}
                entityId={row.roleAssignment.entityID}
                entityTypeNew={row.roleAssignment.entityType}
                resourceType={resourceType}
                groupType={groupType}
                maxChars={45}
                bold
              />
            </div>
          );
        }
      },
      width: 140,
    },
    {
      id: "role",
      label: "Role",
      width: 100,
    },
    {
      id: "accessPaths",
      label: "Access Paths",
      customCellRenderer: (row) => {
        if (!row.roleAssignment.principal || !row.roleAssignment.entity) {
          return "--";
        }
        return (
          <RoleAssignmentAccessPointsLabel
            accessPathsInfo={row.accessPaths}
            subject={{
              ...row.roleAssignment.principal,
              id: row.roleAssignment.principalID,
            }}
            object={{
              ...row.roleAssignment.entity,
              id: row.roleAssignment.entityID,
            }}
            role={row.roleAssignment.accessLevel}
          />
        );
      },
      sortingFn: (rowA, rowB): number => {
        return compareAccessPaths(
          rowA.getValue("accessPaths"),
          rowB.getValue("accessPaths")
        );
      },
      width: 80,
    },
    {
      id: "expiry",
      label: "Expires",
      customCellRenderer: (row) => {
        const expirationTime = row.expiry ? moment(new Date(row.expiry)) : null;
        return (
          <TimeLabel
            time={expirationTime}
            supportTicket={
              row.roleAssignment.access?.latestExpiringAccessPoint.supportTicket
            }
            useExpiringLabel
          />
        );
      },
      width: 90,
    },
    {
      id: "syncStatus",
      label: "Status",
      customCellRenderer: (row) => {
        return (
          <PropagationStatusLabelWithModal
            key={
              row.roleAssignment.principalID +
              row.roleAssignment.entityID +
              row.roleAssignment.accessLevel?.accessLevelRemoteId
            }
            propagationType={PropagationType.RoleAssignment}
            propagationStatus={row.roleAssignment.propagationStatus}
            isAccessReview={false}
            entityInfo={{
              roleAssignmentKey:
                row.roleAssignment.entityID +
                row.roleAssignment.principalID +
                row.roleAssignment.accessLevel?.accessLevelRemoteId,
              principal: row.roleAssignment.principal,
              entity: row.roleAssignment.entity,
              role: row.roleAssignment.accessLevel,
              lastExpiringAccessPointExpiration:
                row.roleAssignment.access?.latestExpiringAccessPoint.expiration,
            }}
          />
        );
      },
      width: 65,
    },
  ];

  const [
    removeRoleAssignments,
    { loading: removeRoleAssignmentsLoading },
  ] = useRemoveRoleAssignmentsMutation({
    refetchQueries: ["ResourceDetailColumn"],
  });

  const submitRemoval = async (
    roleAssignmentsToRemove: RemoveRoleAssignmentInput[]
  ) => {
    logEvent({
      name: "apps_remove_role_assignments",
      properties: {
        entityID: props.resourceId,
        entityType: EntityType.Resource,
        numRoleAssignmentsRemoved: roleAssignmentsToRemove.length,
      },
    });

    try {
      const { data } = await removeRoleAssignments({
        variables: {
          input: {
            roleAssignments: roleAssignmentsToRemove,
          },
        },
      });
      switch (data?.removeRoleAssignments.__typename) {
        case "RemoveRoleAssignmentsResult":
          // TODO: handle list of taskIDs
          startPushTaskPoll(data.removeRoleAssignments.taskIds[0], {
            refetchOnComplete: [{ resourceId: props.resourceId }],
          });
          setRemoveAssignmentsErrorMessage(null);
          setSelectedItemIds([]);
          setShowRemoveModal(false);
          setSearchQuery("");
          break;
        default:
          logError(new Error(`failed to remove role assignment`));
          setRemoveAssignmentsErrorMessage(
            "Error: failed to remove role assignment"
          );
      }
    } catch (error) {
      logError(error, "failed to remove role assignment");
      setRemoveAssignmentsErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to remove role assignment",
          error
        )
      );
    }
  };

  let roleAssignments: RoleAssignmentFragment[] = props.roleAssignments;
  if (searchQuery) {
    roleAssignments = roleAssignments.filter((roleAssignment) => {
      const name = viewingPrincipalsList
        ? roleAssignment.principal?.name
        : roleAssignment.entity?.name;
      return name && name.toLowerCase().includes(searchQuery.toLowerCase());
    });
  }

  const rowsById: Record<string, RoleAssignmentRow> = {};
  const rows: RoleAssignmentRow[] = roleAssignments.flatMap(
    (roleAssignment) => {
      if (!roleAssignment.principal || !roleAssignment.entity) {
        return [];
      }
      const row = {
        id:
          roleAssignment.entityID +
          roleAssignment.principalID +
          roleAssignment.accessLevel?.accessLevelName,
        name: viewingPrincipalsList
          ? roleAssignment.principal.name
          : roleAssignment.entity.name,
        role: roleAssignment.accessLevel?.accessLevelName || "\u2014",
        expiry: roleAssignment.access?.latestExpiringAccessPoint.expiration,
        accessPaths: getRoleAssignmentAccessPathsInfo({
          subject: {
            ...roleAssignment.principal,
            id: roleAssignment.principalID,
          },
          object: {
            ...roleAssignment.entity,
            id: roleAssignment.entityID,
          },
          access: roleAssignment.access,
        }),
        roleAssignment: roleAssignment,
      };
      rowsById[row.id] = row;
      return [row];
    }
  );

  const bulkRightActions: PropsFor<
    typeof TableHeader
  >["bulkRightActions"] = dropNothings([
    props.canManage
      ? {
          label: "Remove",
          type: "danger",
          onClick: () => setShowRemoveModal(true),
          iconName: "trash",
        }
      : null,
  ]);

  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        height: "100%",
      })}
    >
      <TableFilters>
        <TableFilters.Left>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              placeholder="Filter by name"
              value={searchQuery}
              onChange={setSearchQuery}
            />
          </div>
        </TableFilters.Left>
      </TableFilters>
      <TableHeader
        entityName={
          selectedItemIds.length > 0
            ? "Assignment"
            : viewingPrincipalsList
            ? "Principal"
            : "Resource"
        }
        totalNumRows={
          new Set(
            rows.map((row) =>
              viewingPrincipalsList
                ? row.roleAssignment.principalID
                : row.roleAssignment.entityID
            )
          ).size
        }
        selectedNumRows={selectedItemIds.length}
        defaultRightActions={
          props.canManage && viewingPrincipalsList
            ? [
                {
                  label: "Add Principals",
                  type: "mainSecondary",
                  onClick: () => {
                    transitionTo({
                      pathname: `/resources/${props.resourceId}/add-principals`,
                    });
                  },
                  iconName: "plus",
                },
              ]
            : []
        }
        bulkRightActions={bulkRightActions}
      />
      <Table
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        columns={ROLE_ASSIGNMENT_COLUMNS}
        defaultSortBy="name"
        checkedRowIds={new Set(selectedItemIds)}
        onRowClickTransitionTo={(row) =>
          getResourceUrlNew({
            entityId: viewingPrincipalsList
              ? row.roleAssignment.principalID
              : row.roleAssignment.entityID,
            entityType: viewingPrincipalsList
              ? row.roleAssignment.principalType
              : row.roleAssignment.entityType,
          })
        }
        onCheckedRowsChange={
          props.canManage
            ? (checkedRowIds, checked) => {
                if (checked) {
                  setSelectedItemIds((prev) => [...prev, ...checkedRowIds]);
                  return;
                } else {
                  setSelectedItemIds((prev) =>
                    prev.filter((id) => !checkedRowIds.includes(id))
                  );
                }
              }
            : undefined
        }
        selectAllChecked={
          selectedItemIds.length > 0 &&
          selectedItemIds.length ===
            rows.filter((row) => row.roleAssignment.access?.directAccessPoint)
              .length
        }
        onSelectAll={(checked) =>
          checked
            ? setSelectedItemIds(
                rows
                  .filter((row) => row.roleAssignment.access?.directAccessPoint)
                  .map((row) => row.id)
              )
            : setSelectedItemIds([])
        }
        getCheckboxDisabledReason={(row) => {
          if (!row.roleAssignment.access?.directAccessPoint) {
            return "No direct access to remove.";
          }
          return undefined;
        }}
      />
      {showRemoveModal && (
        <Modal
          isOpen
          title="Remove Role Assignments"
          onClose={() => setShowRemoveModal(false)}
        >
          <Modal.Body>
            {removeAssignmentsErrorMessage && (
              <ModalErrorMessage errorMessage={removeAssignmentsErrorMessage} />
            )}
            Are you sure you want to remove{" "}
            {pluralize("direct role assignment", selectedItemIds.length, true)}?
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Remove"
            primaryButtonDisabled={selectedItemIds.length === 0}
            primaryButtonLoading={removeRoleAssignmentsLoading}
            onPrimaryButtonClick={() => {
              const roleAssignmentsToRemove: RemoveRoleAssignmentInput[] = [];
              for (const rowId of selectedItemIds) {
                const row = rowsById[rowId];
                roleAssignmentsToRemove.push({
                  entityID: row.roleAssignment.entityID,
                  entityType: row.roleAssignment.entityType,
                  principalID: row.roleAssignment.principalID,
                  principalType: row.roleAssignment.principalType,
                  accessLevel: {
                    accessLevelName:
                      row.roleAssignment.accessLevel?.accessLevelName ?? "",
                    accessLevelRemoteId:
                      row.roleAssignment.accessLevel?.accessLevelRemoteId ?? "",
                  },
                });
              }
              submitRemoval(roleAssignmentsToRemove);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default RoleAssignmentsTable;
