import { useRegenerateAccessReviewReportMutation } from "api/generated/graphql";
import { Button, ButtonV3 } from "components/ui";
import { MouseEvent } from "react";
import { logError } from "utils/logging";

import { handleRequestExport, UARExportType } from "./Export";

interface Props {
  isV3: boolean;
  label: string;
  accessReview: {
    id: string;
    name: string;
    stoppedDate?: string | null;
  };
  displayLoadingToast: (message?: string) => void;
  displaySuccessToast: (
    message: string,
    linkTitle?: string,
    link?: string
  ) => void;
  displayErrorToast: (message: string) => void;
}

const RegenerateAccessReviewReportButton = (props: Props) => {
  const [
    regenerateAccessReviewReport,
  ] = useRegenerateAccessReviewReportMutation();

  const onClick = async (e: MouseEvent) => {
    e.stopPropagation();
    try {
      const { data } = await regenerateAccessReviewReport({
        variables: {
          input: {
            accessReviewId: props.accessReview.id,
          },
        },
        refetchQueries: ["AccessReviews"],
      });
      switch (data?.regenerateAccessReviewReport?.__typename) {
        case "RegenerateAccessReviewReportResult":
          handleRequestExport(
            props.accessReview,
            UARExportType.Report,
            props.displayLoadingToast,
            props.displaySuccessToast,
            props.displayErrorToast
          );
          break;
        case "AccessReviewNotFoundError":
        case "AccessReviewNotStoppedError":
          props.displayErrorToast(data.regenerateAccessReviewReport.message);
          break;
      }
    } catch (error) {
      logError(error, "failed to regenerate access report");
      props.displayErrorToast("Error: Failed to regenerate access report");
      return;
    }
  };

  if (props.isV3) {
    return (
      <ButtonV3
        label={props.label}
        leftIconName="refresh"
        onClick={onClick}
        outline
      />
    );
  } else {
    return (
      <Button
        label={props.label}
        leftIconName="refresh"
        onClick={onClick}
        outline
      />
    );
  }
};

export default RegenerateAccessReviewReportButton;
