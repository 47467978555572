import {
  RiskyGroupPreviewTinyFragment,
  RiskyResourcePreviewTinyFragment,
  useEntitiesForRiskScoreRangeLazyQuery,
} from "api/generated/graphql";
import { EntityIcon, Skeleton } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useEffect, useState } from "react";
import { logError } from "utils/logging";
import { GRAPH_BUCKET_SIZE } from "views/recommendations/constants";
import * as styles from "views/recommendations/RiskScoreEntitiesPopover.css";

interface Props {
  riskScore: number;
  rgbColor: string;
  numEntities: number;
  small: boolean;
}

const applyOpacity = (color: string, opacity: number) => {
  return `${color.slice(0, color.length - 1)}, ${opacity})`;
};

const NUM_PREVIEW_ENTITIES = 8;
const NUM_PREVIEW_ENTITIES_SMALL = 2;

const RiskScoreEntitiesPopover = ({
  riskScore,
  numEntities,
  rgbColor: color,
  small,
}: Props) => {
  const [entities, setEntities] = useState<
    (RiskyResourcePreviewTinyFragment | RiskyGroupPreviewTinyFragment)[]
  >([]);
  const [error, setError] = useState("");
  const [
    getEntitiesForRiskScoreRange,
  ] = useEntitiesForRiskScoreRangeLazyQuery();

  const [minRiskScore, maxRiskScore] = ((riskScore: number) => {
    if (riskScore <= GRAPH_BUCKET_SIZE) {
      return [0, GRAPH_BUCKET_SIZE];
    }

    const min =
      Math.floor((riskScore - 1) / GRAPH_BUCKET_SIZE) * GRAPH_BUCKET_SIZE + 1;
    const max = Math.ceil(riskScore / GRAPH_BUCKET_SIZE) * GRAPH_BUCKET_SIZE;
    return [min, max];
  })(riskScore);

  useEffect(() => {
    const handler = setTimeout(async () => {
      try {
        const { data, error } = await getEntitiesForRiskScoreRange({
          variables: { minRiskScore: minRiskScore, maxRiskScore: maxRiskScore },
        });
        if (error) {
          setError(error.message);
          return;
        }
        setEntities(data?.entitiesForRiskScoreRange.entities || []);
      } catch (err) {
        logError(err);
        setError("An error occurred while fetching resources");
      }
    }, 100);

    return () => {
      clearTimeout(handler);
      setEntities([]);
    };
  }, [minRiskScore, maxRiskScore, getEntitiesForRiskScoreRange]);

  const numPreviewEntities = small
    ? NUM_PREVIEW_ENTITIES_SMALL
    : NUM_PREVIEW_ENTITIES;

  const loadingSkeleton = _.times(numPreviewEntities, (index) => (
    <Skeleton height="28px" key={index} />
  ));
  const loading = entities.length === 0 && !error;

  let previewEntities = entities;
  let numOverflow = 0;
  if (entities.length > numPreviewEntities) {
    previewEntities = entities.slice(0, numPreviewEntities);
    numOverflow = entities.length - numPreviewEntities;
  }

  return (
    <div className={styles.container}>
      <div className={sprinkles({ fontSize: "textMd" })}>
        {"Risk Scores: "}
        <span style={{ color }}>
          {minRiskScore}-{maxRiskScore}
        </span>
      </div>
      <div
        className={styles.countBadge}
        style={{
          color,
          backgroundColor: applyOpacity(color, 0.3),
        }}
      >
        {numEntities} resources
      </div>
      {error && <div className={styles.error}>{error}</div>}
      {loading
        ? loadingSkeleton
        : previewEntities.map((entity) => {
            const entityTypeIcon = (() => {
              switch (entity.__typename) {
                case "Resource":
                  return entity.resourceType;
                case "Group":
                  return entity.groupType;
                default:
                  return null;
              }
            })();
            return (
              entityTypeIcon && (
                <div className={styles.resourcePill} key={entity.name}>
                  <EntityIcon type={entityTypeIcon} size="sm" separate />
                  <div className={styles.resourceName}>{entity.name}</div>
                </div>
              )
            );
          })}
      {numOverflow > 0 && (
        <div
          className={sprinkles({
            fontSize: "textXs",
            paddingX: "sm",
            paddingTop: "xs",
          })}
        >
          + {numOverflow} more
        </div>
      )}
    </div>
  );
};

export default RiskScoreEntitiesPopover;
