import {
  RequestTemplateCustomFieldFragment,
  RequestTemplateCustomFieldType,
} from "api/generated/graphql";
import { IconName } from "components/ui/icon/Icon";

// Limits
// Keep in sync with limits in backend/models/requesttemplates/constants.go
export const REQUEST_TEMPLATE_NAME_MAX_LEN = 100;
export const CUSTOM_FIELDS_MAX_NUM = 20;

// Following slack API limits
export const CUSTOM_FIELD_NAME_MAX_LEN = 75;
export const CUSTOM_FIELD_DESCRIPTION_MAX_LEN = 150;
// https://api.slack.com/reference/block-kit/block-elements#multi_select
export const DROPDOWN_OPTIONS_MAX_NUM = 100;
export const DROPDOWN_OPTION_MAX_LEN = 75;

export const DEFAULT_CUSTOM_FIELD: RequestTemplateCustomFieldFragment = {
  name: "New Custom Field",
  type: RequestTemplateCustomFieldType.ShortText,
};

export const CustomFieldTypeLabels: Record<
  RequestTemplateCustomFieldType,
  string
> = {
  SHORT_TEXT: "Short answer",
  LONG_TEXT: "Paragraph",
  BOOLEAN: "Checkbox",
  MULTI_CHOICE: "Dropdown",
};

export const CUSTOM_FIELD_TYPE_OPTIONS = [
  RequestTemplateCustomFieldType.ShortText,
  RequestTemplateCustomFieldType.LongText,
  RequestTemplateCustomFieldType.Boolean,
  RequestTemplateCustomFieldType.MultiChoice,
];

export const CustomFieldTypeIcons: Record<
  RequestTemplateCustomFieldType,
  IconName
> = {
  SHORT_TEXT: "file-minus",
  LONG_TEXT: "file-text",
  BOOLEAN: "check-square",
  MULTI_CHOICE: "list",
};
