import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewAction,
  AccessReviewUserFragment,
  AccessReviewUserGroupPreviewFragment,
  AccessReviewUserPreviewFragment,
  EntityType,
  ReviewerUserStatus,
  ReviewGroupUserAction,
  useUpdateGroupUserReviewersMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { EntityViewerTabType } from "components/entity_viewer/EntityViewerTabs";
import AccessReviewNoteLabel from "components/label/AccessReviewNoteLabel";
import { GroupTableCellResourceLabel } from "components/label/GroupTableCellResourceLabel";
import ConnectionLabel from "components/label/item_labels/ConnectionLabel";
import NavigationContext from "components/layout/NavigationContextProvider";
import AccessReviewerModal from "components/modals/AccessReviewerModal";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import {
  CellRow,
  Header,
  ScrollableMuiVirtualTable,
} from "components/tables/material_table/MuiVirtualTable";
import { useToast } from "components/toast/Toast";
import { IconName } from "components/ui/icon/Icon";
import { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { SortDirection } from "react-virtualized";
import { logError } from "utils/logging";
import AccessReviewAcceptRevokeToggleButton, {
  calculateBatchAcceptRevokeState,
} from "views/access_reviews/AccessReviewAcceptRevokeToggleButton";
import AccessReviewContext, {
  AccessReviewContextActionType,
} from "views/access_reviews/AccessReviewContext";
import AccessReviewGroupRoleCell, {
  updateReviewStateForGroupUserRoleChange,
} from "views/access_reviews/AccessReviewGroupRoleCell";
import AccessReviewReviewerDetails, {
  accessReviewReviewerDetailsRowHeight,
} from "views/access_reviews/AccessReviewReviewerDetails";
import AccessReviewReviewersCell from "views/access_reviews/AccessReviewReviewersCell";
import {
  accessReviewItemStatusSortValue,
  AccessReviewOutcomeLabel,
  AccessReviewStatusLabel,
} from "views/access_reviews/AccessReviewStatus";
import {
  getReviewerModalEntries,
  getReviewersSortValue,
  isReviewer,
} from "views/access_reviews/common/Common";
import { getAccessReviewGroupUrl } from "views/access_reviews/common/Routes";
import {
  CONNECTION_HEADER,
  GROUP_NAME_HEADER,
  ITEM_ACTION_HEADER,
  ITEM_STATUS_HEADER,
  NOTE_HEADER,
  OUTCOME_HEADER,
  REVIEWERS_HEADER,
  ROLE_HEADER,
} from "views/access_reviews/common/TableHeaders";
import { getAccessReviewUserId } from "views/access_reviews/users/AccessReviewUsers";

interface AccessReviewUserGroupTableRow {
  name: string;
  role: string;
  connection: string;
  note?: string;
  reviewers?: string;
  status?: string;
  outcome?: string;
  acceptRevoke?: string;
}

type AccessReviewUserGroupsTableProps = {
  accessReviewUser: AccessReviewUserFragment;
  setSelectedTab: (tab: EntityViewerTabType) => void;
};

export const AccessReviewUserGroupsTable = (
  props: AccessReviewUserGroupsTableProps
) => {
  const { navigationState } = useContext(NavigationContext);
  const { authState } = useContext(AuthContext);
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );
  const history = useHistory();

  const [expandedByRowId, setExpandedByRowId] = useState<
    Record<string, boolean>
  >({});

  let groups = props.accessReviewUser.groupUsers || [];

  if (navigationState.isOnlyMyReviewsFilterOn) {
    groups = groups.filter((accessReviewGroupUser) => {
      return (
        authState.user?.user.id &&
        isReviewer(
          authState.user.user.id,
          accessReviewGroupUser.reviewerUsers ?? []
        )
      );
    });
  }

  const performReviewState =
    accessReviewState.performReviewStateByUARUserId[
      getAccessReviewUserId(props.accessReviewUser)
    ];
  const isUserBeingReviewed = !!performReviewState;

  let currentUserHasCompleteReviews = false;
  if (isUserBeingReviewed) {
    groups = groups.filter((user) => {
      return user.reviewerUsers?.find((reviewer) => {
        if (reviewer.userId === authState.user?.user.id) {
          currentUserHasCompleteReviews = true;
          return reviewer.status === ReviewerUserStatus.NotStarted;
        }
        return false;
      });
    });
  }

  /*
   * Figure out which columns to show depending on if the client is
   * performing reviews or viewing reviews.
   */

  // We always show these columns.
  const headers: Header<AccessReviewUserGroupTableRow>[] = [
    GROUP_NAME_HEADER,
    ROLE_HEADER,
    CONNECTION_HEADER,
    REVIEWERS_HEADER,
  ];

  // These are the conditional columns.
  if (isUserBeingReviewed && groups.length > 0) {
    const batchAcceptRevokeToggleButton = (
      <AccessReviewAcceptRevokeToggleButton
        state={calculateBatchAcceptRevokeState(
          performReviewState.groupUserActions,
          groups.length
        )}
        acceptText={"Accept All"}
        revokeText={"Revoke All"}
        onStateChange={(state) => {
          if (!isUserBeingReviewed) return;

          performReviewState.groupUserActions.forEach((groupUserAction) => {
            groupUserAction.action = state;
          });

          groups.forEach((groupUser) => {
            const existingInfo = performReviewState.groupUserActions.find(
              (groupUserAction) =>
                groupUserAction.accessReviewGroupUserId === groupUser.id
            );

            if (!existingInfo) {
              performReviewState.groupUserActions.push({
                accessReviewGroupUserId: groupUser.id,
                action: state,
              });
            }
          });

          accessReviewDispatch({
            type: AccessReviewContextActionType.AccessReviewItemUpdate,
            payload: {
              performReviewStateByUARUserId: {
                ...accessReviewState.performReviewStateByUARUserId,
                [getAccessReviewUserId(
                  props.accessReviewUser
                )]: performReviewState,
              },
            },
          });
        }}
      />
    );
    headers.push(
      {
        ...ITEM_ACTION_HEADER,
        customHeader: batchAcceptRevokeToggleButton,
      },
      NOTE_HEADER
    );
  } else {
    headers.push(ITEM_STATUS_HEADER, OUTCOME_HEADER);
  }

  /*
   * Table rows data
   */

  const groupUserActionByUarGroupUserId: Record<
    string,
    ReviewGroupUserAction
  > = {};
  performReviewState?.groupUserActions?.forEach((groupUserAction) => {
    const id = groupUserAction.accessReviewGroupUserId;
    groupUserActionByUarGroupUserId[id] = groupUserAction;
  });

  const rows: CellRow<AccessReviewUserGroupTableRow>[] = groups.map(
    (groupUser, idx) => {
      const rowId = groupUser.id;
      const groupUserAction = groupUserActionByUarGroupUserId[rowId];

      const group = groupUser.group?.name || groupUser.userId;
      const status = groupUser.statusAndOutcome.status;
      const outcome = groupUser.statusAndOutcome.outcome;

      const connection = groupUser?.group?.connection;

      return {
        id: rowId,
        expandableContent: {
          content: (
            <AccessReviewReviewerDetails reviewers={groupUser.reviewerUsers} />
          ),
          expandedRowHeight: accessReviewReviewerDetailsRowHeight(
            groupUser.reviewerUsers
          ),
          isExpanded: expandedByRowId[rowId],
          setIsExpanded: (expanded) => {
            setExpandedByRowId({
              ...expandedByRowId,
              [rowId]: expanded,
            });
          },
        },
        data: {
          name: {
            value: group,
            element: (
              <GroupTableCellResourceLabel
                groupName={group}
                groupType={groupUser.group?.groupType}
                isOnCall={!!groupUser.group?.onCallSchedules}
              />
            ),
            clickHandler: () => {
              history.push(
                getAccessReviewGroupUrl(groupUser.accessReviewGroupId)
              );
            },
          },
          connection: {
            sortValue: connection?.name || "",
            value: connection?.id || `${connection?.name}`,
            element: connection ? (
              <ConnectionLabel
                text={connection?.name}
                connectionType={connection?.connectionType}
              />
            ) : (
              <>{`--`}</>
            ),
          },
          role: {
            value: groupUser.accessLevel.accessLevelName || "",
            element: (
              <AccessReviewGroupRoleCell
                role={groupUser.accessLevel}
                updatedRole={groupUserAction?.updatedAccessLevel}
                showEditIcon={isUserBeingReviewed}
                groupId={groupUser.groupId}
                onRoleChange={(role) => {
                  if (!performReviewState) {
                    return;
                  }

                  const updatedPerformReviewState = updateReviewStateForGroupUserRoleChange(
                    performReviewState,
                    groupUser,
                    role
                  );

                  accessReviewDispatch({
                    type: AccessReviewContextActionType.AccessReviewItemUpdate,
                    payload: {
                      performReviewStateByUARGroupId: {
                        ...accessReviewState.performReviewStateByUARUserId,
                        [getAccessReviewUserId(
                          props.accessReviewUser
                        )]: updatedPerformReviewState,
                      },
                    },
                  });
                }}
              />
            ),
          },
          reviewers: {
            value: getReviewersSortValue(groupUser.reviewerUsers ?? []),
            element: (
              <AccessReviewerUserGroupsCell
                accessReviewUser={props.accessReviewUser}
                groupUser={groupUser}
              />
            ),
          },
          status: {
            value: status,
            sortValue: accessReviewItemStatusSortValue(status),
            element: (
              <AccessReviewStatusLabel
                entityType={EntityType.AccessReviewGroupUser}
                status={status}
                warnings={groupUser.warnings}
                group={groupUser.group}
                user={props.accessReviewUser.user}
                accessReviewId={props.accessReviewUser.accessReviewId}
              />
            ),
          },
          outcome: {
            value: outcome,
            element: (
              <AccessReviewOutcomeLabel
                entityType={EntityType.AccessReviewGroupUser}
                outcome={outcome}
              />
            ),
          },
          note: {
            options: {
              display: isUserBeingReviewed,
            },
            value: "",
            element: (
              <AccessReviewNoteLabel
                initNoteContent={groupUserAction?.note}
                onSubmit={(updatedNoteContent) => {
                  if (!performReviewState) {
                    return;
                  }

                  const existingInfo = performReviewState.groupUserActions.find(
                    (groupUserAction) =>
                      groupUserAction.accessReviewGroupUserId === groupUser.id
                  );

                  if (existingInfo) {
                    existingInfo.note = updatedNoteContent;
                  } else {
                    performReviewState.groupUserActions.push({
                      accessReviewGroupUserId: groupUser.id,
                      action: AccessReviewAction.NoAction,
                      note: updatedNoteContent,
                    });
                  }
                }}
              />
            ),
            sortValue: idx,
          },
          acceptRevoke: {
            value: groupUserAction?.action || AccessReviewAction.NoAction,
            element: (
              <AccessReviewAcceptRevokeToggleButton
                state={groupUserAction?.action || AccessReviewAction.NoAction}
                onStateChange={(state) => {
                  if (!performReviewState) {
                    return;
                  }

                  const existingInfo = performReviewState.groupUserActions.find(
                    (groupUserAction) =>
                      groupUserAction.accessReviewGroupUserId === rowId
                  );

                  if (existingInfo) {
                    existingInfo.action = state;
                  } else {
                    performReviewState.groupUserActions.push({
                      accessReviewGroupUserId: rowId,
                      action: state,
                    });
                  }
                  accessReviewDispatch({
                    type: AccessReviewContextActionType.AccessReviewItemUpdate,
                    payload: {
                      performReviewStateByUARUserId: {
                        ...accessReviewState.performReviewStateByUARUserId,
                        [getAccessReviewUserId(
                          props.accessReviewUser
                        )]: performReviewState,
                      },
                    },
                  });
                }}
              />
            ),
          },
        },
      };
    }
  );

  let emptyStateTitle: string;
  let emptyStateSubtitle: string;
  let emptyStateButtonTitle: string | undefined;
  let emptyStateButtonIcon: IconName | undefined;
  let emptyStateOnClickHandler: (() => void) | undefined;
  if (isUserBeingReviewed) {
    if (currentUserHasCompleteReviews) {
      emptyStateTitle = `No groups left to review`;
    } else {
      emptyStateTitle = `No groups to review`;
    }
    emptyStateSubtitle = `However, you have outstanding reviews for this user's resources.`;
    emptyStateButtonTitle = `Review resource access`;
    emptyStateButtonIcon = "check-circle";
    emptyStateOnClickHandler = () => {
      history.replace(`#${EntityViewerTabType.Resources}`);
      props.setSelectedTab(EntityViewerTabType.Resources);
    };
  } else {
    emptyStateTitle = `No groups to review`;
    emptyStateSubtitle = navigationState.isOnlyMyReviewsFilterOn
      ? `No groups have been assigned for you to review.`
      : `This user had no direct access to any groups at the time of the access review.`;
  }

  return (
    <EmptyStateContentWrapper
      content={
        <ScrollableMuiVirtualTable
          columns={headers}
          rows={rows}
          defaultSortBy={"name"}
          defaultSortDirection={SortDirection.ASC}
          allRowsLoaded
          expandable
        />
      }
      entityType={EntityType.User}
      title={emptyStateTitle}
      subtitle={emptyStateSubtitle}
      buttonTitle={emptyStateButtonTitle}
      buttonIcon={emptyStateButtonIcon}
      onClickHandler={emptyStateOnClickHandler}
      isEmpty={groups.length === 0}
    />
  );
};

type AccessReviewerUserGroupsCellProps = {
  accessReviewUser: AccessReviewUserPreviewFragment;
  groupUser: AccessReviewUserGroupPreviewFragment;
};

const AccessReviewerUserGroupsCell = (
  props: AccessReviewerUserGroupsCellProps
) => {
  const { accessReviewState } = useContext(AccessReviewContext);
  const [showReviewersModal, setShowReviewersModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { displaySuccessToast } = useToast();

  const [
    updateUserGroupReviewers,
    { loading },
  ] = useUpdateGroupUserReviewersMutation({});

  const canEditReviewers =
    accessReviewState.ongoingAccessReviewIdSet.has(
      props.accessReviewUser.accessReviewId
    ) && props.accessReviewUser.canEditReviewers;

  return (
    <>
      <AccessReviewReviewersCell
        itemType="group"
        reviewerUsers={props.groupUser.reviewerUsers}
        canEditReviewers={canEditReviewers}
        onClick={() => {
          setShowReviewersModal(true);
        }}
      />
      {showReviewersModal && (
        <AccessReviewerModal
          title={"Reviewers"}
          isModalOpen={showReviewersModal}
          onClose={() => {
            setShowReviewersModal(false);
            setErrorMessage("");
          }}
          loading={loading}
          onSubmit={async (reviewers) => {
            try {
              const { data } = await updateUserGroupReviewers({
                variables: {
                  input: {
                    accessReviewGroupUserId: props.groupUser.id,
                    reviewers: reviewers,
                  },
                },
                refetchQueries: ["AccessReviewUser"],
              });
              switch (data?.updateGroupUserReviewers.__typename) {
                case "UpdateGroupUserReviewersResult": {
                  displaySuccessToast("Success: reviewers updated");
                  setShowReviewersModal(false);
                  break;
                }
                case "AccessReviewAlreadyStoppedError": {
                  setErrorMessage(data?.updateGroupUserReviewers.message);
                  break;
                }
              }
            } catch (error) {
              logError(error, "failed to update access reviewers");
              setErrorMessage(
                getModifiedErrorMessage(
                  "Error: failed to update access reviewers",
                  error
                )
              );
            }
          }}
          errorMessage={errorMessage}
          entryInfos={getReviewerModalEntries(
            props.groupUser.reviewerUsers,
            null
          )}
          canEditReviewers={canEditReviewers}
        />
      )}
    </>
  );
};

export default AccessReviewUserGroupsTable;
