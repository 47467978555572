import { EntityType } from "api/generated/graphql";
import { EntityIcon, Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";

const FullscreenViewTitle = (props: {
  entityType:
    | PropsFor<typeof EntityIcon>["type"]
    | EntityType.User
    | EntityType.Owner
    | EntityType.Tag;

  entityName: string;
  targetEntityName: string;
  action: "import" | "add" | "edit";
}) => {
  let icon: React.ReactNode;
  switch (props.entityType) {
    case EntityType.User:
      icon = <Icon name="user" size="lg" />;
      break;
    case EntityType.Owner:
      icon = <Icon name="user-check" size="lg" />;
      break;
    case EntityType.Tag:
      icon = <Icon name="tag" size="lg" />;
      break;
    default:
      icon = <EntityIcon type={props.entityType} size="lg" />;
  }
  return (
    <>
      {_.capitalize(props.action)} {_.capitalize(props.targetEntityName)}:
      <div className={sprinkles({ display: "flex", alignItems: "center" })}>
        {icon}
      </div>
      {props.entityName}
    </>
  );
};

export default FullscreenViewTitle;
