import {
  AccessOption,
  AppDropdownPreviewFragment,
  ConnectionType,
  usePaginatedAppDropdownQuery,
} from "api/generated/graphql";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { Select } from "components/ui";
import { IconData } from "components/ui/utils";
import { useState } from "react";
import { Event } from "utils/analytics/constants";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";

const MAX_OPTIONS_TO_DISPLAY = 25;

type App = {
  __typename?: "App";
  name: string;
  id: string;
  app:
    | {
        __typename?: "ConnectionApp";
        connectionId: string;
        connectionType: ConnectionType;
      }
    | {
        __typename?: "OktaResourceApp";
        resourceId: string;
        iconUrl?: string | null;
      };
};

type PaginatedAppDropdownProps = {
  id?: string;
  value?: App;
  onChange: (app?: App) => void;
  disabled?: boolean;
  clearable?: boolean;
  /** Focus the input component on mount. */
  autoFocus?: boolean;
  placeholder?: string;
  placeHolderIcon?: IconData;
  selectOnly?: boolean;
  size?: PropsFor<typeof Select>["size"];
  focusEvent?: Event;
};

/**
 * A searchable dropdown containing all apps in the organization matching
 * the given filters. This dropdown statically limits the number of rendered
 * options to preserve performance -- the user is expected to leverage search
 * instead of scrolling to select the desired app.
 */
const PaginatedAppDropdown = ({
  value,
  onChange,
  disabled,
  autoFocus,
  placeholder,
  placeHolderIcon,
  selectOnly,
  ...props
}: PaginatedAppDropdownProps) => {
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 200);

  const {
    data: appsData,
    error: appsError,
    fetchMore,
  } = usePaginatedAppDropdownQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      access: AccessOption.All,
      searchQuery: debouncedSearchQuery,
      limit: MAX_OPTIONS_TO_DISPLAY,
    },
  });
  if (appsError) {
    logError(appsError, "failed to list apps");
  }

  const cursor = appsData?.apps.cursor;
  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            cursor,
            access: AccessOption.All,
            searchQuery: debouncedSearchQuery,
            limit: MAX_OPTIONS_TO_DISPLAY,
          },
        });
      }
    : undefined;

  let apps: AppDropdownPreviewFragment[] = [];
  switch (appsData?.apps.__typename) {
    case "AppsOutput":
      apps = appsData.apps.apps;
      break;
    default:
      break;
  }

  const handleSelectValue = (value?: AppDropdownPreviewFragment) => {
    onChange(value);
    if (selectOnly) setSearchQuery("");
  };

  return (
    <Select
      id={props.id}
      options={apps}
      value={value}
      onChange={handleSelectValue}
      onInputChange={setSearchQuery}
      disabled={disabled}
      getOptionLabel={(option) => option.name}
      getIcon={(app) => {
        switch (app.app.__typename) {
          case "OktaResourceApp":
            return {
              type: "src",
              icon: app.app.iconUrl ? app.app.iconUrl : undefined,
            };
          case "ConnectionApp":
            return {
              type: "src",
              icon: getConnectionTypeInfo(app.app.connectionType)?.icon,
            };
          default:
            return undefined;
        }
      }}
      getOptionSelected={(option, value) => option.id === value.id}
      disableBuiltInFiltering // Search is already handled server-side
      autoFocus={autoFocus}
      placeholder={placeholder}
      placeholderIcon={placeHolderIcon}
      selectOnly={selectOnly}
      onScrollToBottom={() => {
        if (loadMoreRows) loadMoreRows();
      }}
      size={props.size}
      focusEvent={props.focusEvent}
    />
  );
};

export default PaginatedAppDropdown;
