import {
  EntityType,
  ResourceAccessLevel,
  ResourcePreviewLargeFragment,
  useBundleItemsQuery,
  useBundleQuery,
} from "api/generated/graphql";
import { Column, ColumnContainer } from "components/column/Column";
import { Editor, EntityViewerRow } from "components/entity_viewer/EntityViewer";
import ConnectionLabel from "components/label/item_labels/ConnectionLabel";
import { ResourceLabel } from "components/label/Label";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import MuiVirtualTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MuiVirtualTable";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { SortDirection } from "react-virtualized";
import { AuthorizedActionManage } from "utils/auth/auth";
import { getResourceUrlNew } from "utils/common";
import { getResourceSublabel } from "utils/resources";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import BundleAddResourcesModal from "./BundleAddResourcesModal";
import BundleContentHeader from "./BundleContentHeader";
import BundleRemoveResourcesModal from "./BundleRemoveResourcesModal";

const BundleResourcesColumn = () => {
  const { bundleId } = useParams<Record<string, string>>();
  const [showAddModal, setShowAddModal] = useState(false);
  const [showRemoveModal, setShowRemoveModal] = useState(false);

  const {
    data: bundleData,
    error: bundleError,
    loading: bundleLoading,
  } = useBundleQuery({
    variables: {
      input: {
        id: bundleId,
      },
    },
  });

  const { data, error, loading } = useBundleItemsQuery({
    variables: {
      input: {
        bundleId,
      },
    },
    skip: !bundleId,
  });

  const allItems = data?.bundleItems.items ?? [];

  if (error || bundleError) {
    return null;
  }

  if (loading || bundleLoading) {
    return (
      <ColumnContainer>
        <Column isContent maxWidth="lg">
          <ColumnContentSkeleton />
        </Column>
      </ColumnContainer>
    );
  }

  const existingRolesByResourceId: Record<string, ResourceAccessLevel[]> = {};
  const resourcesWithRole: ResourceWithAccessLevel[] = [];
  allItems.forEach((item) => {
    if (!item.resource) {
      return;
    }
    if (item.resource.id in existingRolesByResourceId) {
      existingRolesByResourceId[item.resource.id].push({
        accessLevelRemoteId: item.accessLevelRemoteId,
        accessLevelName: item.accessLevelName,
      });
    } else {
      existingRolesByResourceId[item.resource.id] = [
        {
          accessLevelRemoteId: item.accessLevelRemoteId,
          accessLevelName: item.accessLevelName,
        },
      ];
    }
    resourcesWithRole.push({
      resource: item.resource,
      role: {
        accessLevelRemoteId: item.accessLevelRemoteId,
        accessLevelName: item.accessLevelName,
      },
    });
  });
  const numResources = Object.keys(existingRolesByResourceId).length;

  const editor = (
    <Editor
      menuOptions={[
        {
          label: "Add",
          handler: () => setShowAddModal(true),
        },
        {
          label: "Remove",
          handler: () => setShowRemoveModal(true),
          hide: numResources === 0,
        },
      ]}
    />
  );

  let canManage = false;
  if (bundleData?.bundle.__typename === "BundleResult") {
    canManage =
      bundleData.bundle.bundle.authorizedActions?.includes(
        AuthorizedActionManage
      ) ?? false;
  }

  if (!canManage) {
    return null;
  }

  return (
    <ColumnContainer>
      <Column isContent maxWidth="lg">
        <BundleContentHeader canManage={canManage} />
        <EntityViewerRow
          title="Bundle resources"
          isTable
          editor={editor}
          content={
            <EmptyStateContentWrapper
              title="No resources in bundle"
              isEmpty={numResources === 0}
              entityType={EntityType.Resource}
              content={<BundleResourcesTable resources={resourcesWithRole} />}
            />
          }
        />
      </Column>
      {showAddModal && (
        <BundleAddResourcesModal
          bundleId={bundleId}
          existingRolesByResourceId={existingRolesByResourceId}
          onClose={() => setShowAddModal(false)}
        />
      )}
      {showRemoveModal && (
        <BundleRemoveResourcesModal
          bundleId={bundleId}
          bundleItems={allItems}
          onClose={() => setShowRemoveModal(false)}
        />
      )}
    </ColumnContainer>
  );
};

interface BundleResourceRow {
  name: string;
  connection: string;
  role: string;
}

interface ResourceWithAccessLevel {
  resource: ResourcePreviewLargeFragment;
  role: ResourceAccessLevel;
}

interface TableProps {
  resources: ResourceWithAccessLevel[];
}

const BundleResourcesTable = (props: TableProps) => {
  const history = useHistory();

  const headers: Header<BundleResourceRow>[] = [
    {
      id: "name",
      label: "Name",
      width: 300,
      minWidth: 250,
    },
    {
      id: "role",
      label: "Role",
      width: 200,
      minWidth: 150,
    },
    {
      id: "connection",
      label: "App",
      width: 200,
      minWidth: 150,
    },
  ];

  const rows: CellRow<BundleResourceRow>[] = props.resources.map(
    ({ resource, role }) => ({
      id: resource.id,
      data: {
        name: {
          value: resource.name,
          element: (
            <ResourceLabel
              text={resource.name}
              subText={resource ? getResourceSublabel(resource) : "--"}
              entityTypeNew={EntityType.Resource}
              bold={true}
              icon={resource.iconUrl}
              resourceType={resource.resourceType}
              iconLarge={true}
              pointerCursor={true}
              maxChars="auto"
            />
          ),
          clickHandler: () => {
            history.push(
              getResourceUrlNew({
                entityId: resource.id,
                entityType: EntityType.Resource,
              })
            );
          },
        },
        role: {
          value: role.accessLevelName,
          element: <span>{role.accessLevelName || "--"}</span>,
        },
        connection: {
          value: resource.connection?.name ?? "",
          element: (
            <ConnectionLabel
              text={resource.connection?.name ?? ""}
              connectionId={resource.connectionId}
              connectionType={resource.connection?.connectionType}
            />
          ),
        },
      },
    })
  );
  return (
    <MuiVirtualTable
      columns={headers}
      rows={rows}
      defaultSortBy="name"
      defaultSortDirection={SortDirection.ASC}
      allRowsLoaded
    />
  );
};

export default BundleResourcesColumn;
