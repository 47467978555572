import { getModifiedErrorMessage } from "api/ApiContext";
import {
  ApiAccessLevel,
  FirstPartyTokenFragment,
  FirstPartyTokensDocument,
  FirstPartyTokensQuery,
  Maybe,
  useCreateFirstPartyTokenMutation,
  useDeleteFirstPartyTokensMutation,
  useFirstPartyTokensQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { formatDuration } from "components/label/Label";
import { EditDurationForm } from "components/modals/EditDurationModal";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { FieldLabel } from "components/modals/SessionDetailsModal";
import { useToast } from "components/toast/Toast";
import Banner from "components/ui/banner/Banner";
import FormGroup from "components/ui/form_group/FormGroup";
import Icon from "components/ui/icon/Icon";
import Input from "components/ui/input/Input";
import Modal from "components/ui/modal/Modal";
import Select from "components/ui/select/Select";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import pluralize from "pluralize";
import React, { useContext, useState } from "react";
import { apiRoleByRole } from "utils/auth/auth";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import {
  ExpirationValue,
  expirationValueToDurationInMinutes,
} from "views/requests/utils";
import { getUserAvatarIcon } from "views/users/utils";

import * as styles from "./ApiTokensTableV3.css";

interface ApiTokenRow {
  id: string;
  name: string;
  token: string;
  creatorUserId: string;
  creatorName?: string;
  creatorAvatar?: string;
  created: string;
  lastUsed: string;
  expiry: string;
  role: string;
}

type ApiTokenTableV3Props = {
  tokens: FirstPartyTokenFragment[];
  buttonTitle?: string;
  buttonOnClickHandler?: () => void;
};

export const ApiTokensTableV3 = (props: ApiTokenTableV3Props) => {
  const [selectedTokenIds, setSelectedTokenIds] = useState<string[]>([]);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showTokenModal, setShowTokenModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 200);

  const [token, setToken] = useState<Maybe<string>>(null);

  const { data, loading, error } = useFirstPartyTokensQuery();

  let tokens: FirstPartyTokenFragment[] = [];
  if (data) {
    switch (data.firstPartyTokens.__typename) {
      case "FirstPartyTokensResult":
        tokens = data.firstPartyTokens.tokens;
        break;
      default:
        logError(new Error(`failed to retrieve API tokens`));
    }
  } else if (error) {
    logError(error, `failed to retrieve API tokens`);
  }

  const API_TOKEN_COLUMNS: Header<ApiTokenRow>[] = [
    {
      id: "name",
      label: "Name",
      sortable: true,
    },
    {
      id: "token",
      label: "Token",
      sortable: true,
    },
    {
      id: "creatorUserId",
      label: "Created By",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <div className={sprinkles({ display: "flex", gap: "sm" })}>
            {row.creatorAvatar && (
              <div className={sprinkles({ flexShrink: 0 })}>
                <Icon
                  size="sm"
                  data={getUserAvatarIcon({ avatarUrl: row.creatorAvatar })}
                />
              </div>
            )}
            {row.creatorName ? (
              <div className={styles.nameField}>{row.creatorName}</div>
            ) : (
              <div className={styles.nameField}>{row.creatorUserId}</div>
            )}
          </div>
        );
      },
    },
    {
      id: "created",
      label: "Created",
      sortable: true,
      width: 125,
    },
    {
      id: "lastUsed",
      label: "Last Used",
      sortable: true,
      width: 125,
    },
    {
      id: "expiry",
      label: "Expiry",
      sortable: true,
      width: 100,
    },
    {
      id: "role",
      label: "Role",
      sortable: true,
      width: 100,
    },
  ];

  const filteredTokens = props.tokens.filter((token) => {
    if (debouncedSearchQuery === "") return true;
    return token.tokenLabel
      .toLowerCase()
      .includes(debouncedSearchQuery.toLowerCase());
  });

  const rows: ApiTokenRow[] = filteredTokens.map((token) => {
    const createdAtTime = moment(new Date(token.createdAt));
    const lastUsedAtTime = token.lastUsedAt
      ? moment(new Date(token.lastUsedAt))
      : null;
    const expiryTime = token.expiresAt
      ? moment(new Date(token.expiresAt))
      : null;

    return {
      id: token.id,
      token: token.tokenPreview,
      name: token.tokenLabel,
      creatorUserId: token.creatorUserId,
      creatorAvatar: token.creatorUser?.avatarUrl,
      creatorName: token.creatorUser?.fullName,
      created: createdAtTime.fromNow(),
      lastUsed: lastUsedAtTime ? lastUsedAtTime.fromNow() : "--",
      expiry: expiryTime ? expiryTime.fromNow() : "--",
      role: apiRoleByRole[token.accessLevel].name,
    };
  });

  const bulkRightActions: PropsFor<typeof TableHeader>["bulkRightActions"] = [
    {
      label: "Remove",
      type: "danger",
      onClick: () => setShowDeleteModal(true),
      iconName: "trash",
    },
  ];

  return (
    <>
      <div className={styles.searchInput}>
        <Input
          leftIconName="search"
          type="search"
          style="search"
          value={searchQuery}
          onChange={setSearchQuery}
          placeholder="Filter API access tokens by name"
        />
      </div>
      <TableHeader
        entityName={pluralize("API Access Token", tokens.length)}
        totalNumRows={tokens.length}
        selectedNumRows={selectedTokenIds.length}
        loading={loading}
        defaultRightActions={[
          {
            label: "API Access Token",
            type: "main",
            onClick: () => setShowCreateModal(true),
            iconName: "plus",
          },
        ]}
        bulkRightActions={bulkRightActions}
      />

      <Table
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        columns={API_TOKEN_COLUMNS}
        defaultSortBy="name"
        checkedRowIds={new Set(selectedTokenIds)}
        onCheckedRowsChange={(ids, checked) => {
          checked
            ? setSelectedTokenIds([...selectedTokenIds, ...ids])
            : setSelectedTokenIds(
                selectedTokenIds.filter((id) => !ids.includes(id))
              );
        }}
        selectAllChecked={
          selectedTokenIds.length === tokens.length &&
          tokens.every((token) => selectedTokenIds.includes(token.id))
        }
        onSelectAll={(checked) => {
          checked
            ? setSelectedTokenIds(tokens.map((token) => token.id))
            : setSelectedTokenIds([]);
        }}
      />

      {showCreateModal && (
        <CreateModal
          setShowModal={setShowCreateModal}
          onSuccess={(token: string) => {
            setToken(token);
            setShowTokenModal(true);
          }}
        />
      )}
      {showTokenModal && token && (
        <TokenModal setShowModal={setShowTokenModal} token={token} />
      )}
      {showDeleteModal && (
        <DeleteModal
          setShowModal={setShowDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          tokens={tokens || []}
          selectedTokenIds={selectedTokenIds}
          setSelectedTokenIds={setSelectedTokenIds}
        />
      )}
    </>
  );
};

type CreateModalProps = {
  setShowModal: (show: boolean) => void;
  onSuccess: (token: string) => void;
};

const CreateModal = (props: CreateModalProps) => {
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { authState } = useContext(AuthContext);

  const { displaySuccessToast } = useToast();

  const [tokenLabel, setTokenLabel] = useState<string>("");
  const [role, setRole] = useState<ApiAccessLevel>(ApiAccessLevel.ReadOnly);
  const [duration, setDuration] = useState<number | undefined>();

  const fieldUnset = tokenLabel === "";

  const adminExpirationTime = authState.user?.opalAdminExpirationTime
    ? moment(new Date(authState.user.opalAdminExpirationTime))
    : undefined;
  let maxExpirationDuration: moment.Duration | null = null;
  let maxExpirationDurationStr = "";
  let initExpirationDurationVal: number | undefined = undefined;
  if (adminExpirationTime) {
    maxExpirationDuration = moment.duration(
      adminExpirationTime.diff(moment(new Date()))
    );
    maxExpirationDurationStr = formatDuration(maxExpirationDuration);

    // find the largest expiration default value that is less than the max expiration duration
    // this way we can autoselect the best option in the EditDuration dropdown
    initExpirationDurationVal =
      Object.values(ExpirationValue)
        .map((time) => expirationValueToDurationInMinutes(time))
        .filter((duration) =>
          duration ? duration <= maxExpirationDuration! : false
        )
        .pop()
        ?.asMinutes() ?? undefined;
  }

  const [
    createFirstPartyToken,
    { loading },
  ] = useCreateFirstPartyTokenMutation();

  const handleSubmit = async () => {
    try {
      const { data } = await createFirstPartyToken({
        variables: {
          input: {
            tokenLabel: tokenLabel,
            accessLevel: role,
            expiresAt: duration
              ? moment(Date()).add(duration, "m").toDate().toISOString()
              : null,
          },
        },
        update: (cache, { data }) => {
          switch (data?.createFirstPartyToken.__typename) {
            case "CreateFirstPartyTokenResult": {
              let cachedQuery = cache.readQuery<FirstPartyTokensQuery>({
                query: FirstPartyTokensDocument,
                variables: {
                  input: {},
                },
              });
              if (cachedQuery) {
                cache.writeQuery<FirstPartyTokensQuery>({
                  query: FirstPartyTokensDocument,
                  variables: {
                    input: {},
                  },
                  data: {
                    ...cachedQuery,
                    firstPartyTokens: {
                      ...cachedQuery.firstPartyTokens,
                      tokens: [
                        ...cachedQuery.firstPartyTokens.tokens,
                        data.createFirstPartyToken.token,
                      ],
                    },
                  },
                });
              }
            }
          }
        },
      });
      switch (data?.createFirstPartyToken.__typename) {
        case "CreateFirstPartyTokenResult":
          props.setShowModal(false);
          displaySuccessToast("Success: API token created");
          props.onSuccess(data.createFirstPartyToken.signedToken);
          break;
        default:
          logError(new Error(`failed to create API token`));
          setErrorMessage("Error: failed to create API token");
      }
    } catch (error) {
      logError(error, `failed to create API token`);
      setErrorMessage("Error: failed to create API token");
    }
  };

  const handleClose = () => {
    props.setShowModal(false);
  };

  return (
    <Modal isOpen onClose={handleClose} title="Create API token">
      <Modal.Body>
        <FormGroup label="Label">
          <Input onChange={setTokenLabel} value={tokenLabel} />
        </FormGroup>
        <FormGroup label="Role">
          <Select
            onChange={(role) => {
              if (role) {
                setRole(role.value);
              }
            }}
            getOptionLabel={(option) => option.label}
            value={{
              label: apiRoleByRole[role].name,
              value: role,
            }}
            options={[
              {
                label: apiRoleByRole[ApiAccessLevel.ReadOnly].name,
                value: ApiAccessLevel.ReadOnly,
              },
              {
                label: apiRoleByRole[ApiAccessLevel.FullAccess].name,
                value: ApiAccessLevel.FullAccess,
              },
            ]}
          />
        </FormGroup>
        <EditDurationForm
          title="Expires In"
          onChange={setDuration}
          initValue={initExpirationDurationVal}
          includeIndefinite
          maxValue={
            maxExpirationDuration
              ? maxExpirationDuration.asMinutes()
              : undefined
          }
          maxValueReason={
            "Your access to the Opal admin role is expiring in " +
            maxExpirationDurationStr
          }
        />
        {adminExpirationTime && (
          <Banner
            message={
              <>
                Your access to the Opal admin role is expiring in{" "}
                <b>{maxExpirationDurationStr}</b>. If the creator of an API
                token loses admin access, their token will expire. For that
                reason, the max expiration you can set for this token is{" "}
                <b>{maxExpirationDurationStr}</b>.
              </>
            }
            type="warning"
          />
        )}
        {errorMessage ? (
          <ModalErrorMessage errorMessage={errorMessage} />
        ) : null}
      </Modal.Body>
      <Modal.Footer
        onSecondaryButtonClick={handleClose}
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLabel={"Create"}
        primaryButtonDisabled={loading || fieldUnset}
        primaryButtonLoading={loading}
      />
    </Modal>
  );
};

type TokenModalProps = {
  setShowModal: (show: boolean) => void;
  token: string;
};

const TokenModal = (props: TokenModalProps) => {
  const handleClose = () => {
    props.setShowModal(false);
  };
  return (
    <Modal isOpen onClose={handleClose} title="API Token">
      <Modal.Body>
        <p>
          This key will not be visible again. If you lose it, you should delete
          the API token and create a new one.
        </p>
        <FieldLabel isDataPrivate={true} value={props.token} />
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel={"Okay, understood"}
        onPrimaryButtonClick={handleClose}
      />
    </Modal>
  );
};

type DeleteModalProps = {
  setShowModal: (show: boolean) => void;
  tokens: FirstPartyTokenFragment[];
  selectedTokenIds: string[];
  setSelectedTokenIds: (ids: string[]) => void;
  onClose: () => void;
};

const DeleteModal = (props: DeleteModalProps) => {
  const { displaySuccessToast } = useToast();
  const [error, setError] = useState<string | undefined>(undefined);

  const [
    deleteFirstPartyTokens,
    { loading },
  ] = useDeleteFirstPartyTokensMutation();

  const modalReset = () => {
    props.setShowModal(false);
    props.setSelectedTokenIds([]);
    setError(undefined);
  };

  const handleSubmit = async () => {
    try {
      const { data } = await deleteFirstPartyTokens({
        variables: {
          input: {
            ids: props.selectedTokenIds,
          },
        },
        update: (cache, { data }) => {
          switch (data?.deleteFirstPartyTokens.__typename) {
            case "DeleteFirstPartyTokensResult":
              data?.deleteFirstPartyTokens.entries.forEach((entry) => {
                switch (entry.__typename) {
                  case "DeleteFirstPartyTokenEntryResult":
                    cache.evict({
                      id: cache.identify({
                        __typename: "FirstPartyToken",
                        id: entry.id,
                      }),
                    });
                }
              });
              break;
          }
        },
      });
      if (data) {
        switch (data.deleteFirstPartyTokens.__typename) {
          case "DeleteFirstPartyTokensResult": {
            modalReset();
            displaySuccessToast(
              `Success: ${pluralize(
                "token",
                props.selectedTokenIds.length,
                true
              )} deleted`
            );
            break;
          }
          default:
            logError(new Error(`failed to delete tokens`));
            setError("Error: failed to delete tokens");
        }
      }
    } catch (error) {
      logError(error, "failed to delete tokens");
      setError(
        getModifiedErrorMessage("Error: failed to delete tokens", error)
      );
    }
  };

  return (
    <Modal isOpen title="Delete API Tokens" onClose={props.onClose}>
      <Modal.Body>
        {error && <ModalErrorMessage errorMessage={error} />}
        Are you sure you want to delete{" "}
        {pluralize("API access token", props.selectedTokenIds.length, true)}?
        This cannot be undone.
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Remove"
        primaryButtonDisabled={props.selectedTokenIds.length === 0}
        primaryButtonLoading={loading}
        onPrimaryButtonClick={handleSubmit}
      />
    </Modal>
  );
};

export default ApiTokensTableV3;
