import { Select } from "components/ui";

import { GroupByOptions } from "./common";
import { useFilterDispatch, useFilterState } from "./contexts/FilterContext";

const groupByOptionLabels = {
  userTeam: "User team",
  userRole: "User role",
};

const GroupByDropdown = () => {
  const filterState = useFilterState();
  const filterDispatch = useFilterDispatch();

  return (
    <Select
      options={[...GroupByOptions]}
      value={filterState.groupBy}
      onChange={(val) => {
        filterDispatch({
          type: "UPDATE_GROUP_BY",
          payload: {
            value: val,
          },
        });
      }}
      getOptionLabel={(opt) => groupByOptionLabels[opt]}
      clearable
    />
  );
};

export default GroupByDropdown;
