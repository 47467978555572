import {
  BundleItemFragment,
  GroupForImportFragment,
  GroupForSelectFragment,
  RemoteAppItemFragment,
  ResourceForImportFragment,
  ResourceForSelectFragment,
} from "api/generated/graphql";
import React, { createContext, useState } from "react";
import { useLocalStorage, useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";

import { SortOption } from "./ItemsListSection";

export interface AppDetailState {
  searchQuery?: string;
  sortOption?: SortOption;
  accountId?: string;
}

type BulkMode = "edit" | "request";

export const ACCESS_OPTION_URL_KEY = "access";
export const APP_ID_URL_KEY = "appId";
export const ACCOUNT_ID_URL_KEY = "accountId";
export const CONNECTION_ID_URL_KEY = "connectionId";
export const OKTA_APP_ID_URL_KEY = "oktaAppId";
export const ITEM_TYPE_URL_KEY = "itemType";
export const SEARCH_QUERY_URL_KEY = "search";
export const LAYOUT_OPTION_LOCAL_STORAGE_KEY = "layout";

export const SELECTED_ITEMS_LOCAL_STORAGE_KEY = "opal-selected-items";
export const SELECTED_BUNDLE_ITEMS_LOCAL_STORAGE_KEY =
  "opal-selected-bundle-items";

export type SelectedItem = ResourceForSelectFragment | GroupForSelectFragment;
export type SelectedUnmanagedItem =
  | ResourceForImportFragment
  | GroupForImportFragment;
export type LayoutOptionKey = "grid" | "list";

interface AppsContextData {
  // Bulk select
  isSelectMode: boolean;
  setIsSelectMode: (isSelectMode: boolean) => void;
  selectedItems: SelectedItem[];
  toggleItem: (item: SelectedItem) => void;
  selectItem: (item: SelectedItem) => void;
  selectItems: (items: SelectedItem[]) => void;
  clearItem: (item: SelectedItem) => void;
  clearItems: (items: SelectedItem[]) => void;
  clearSelectedItems: () => void;
  bulkMode?: "edit" | "request";
  setBulkMode: (bulkMode?: BulkMode) => void;

  layoutOption: LayoutOptionKey;
  setLayoutOption: (layoutOption: LayoutOptionKey) => void;

  // Bundle items
  selectedBundleItems: BundleItemFragment[];
  selectBundleItems: (items: BundleItemFragment[]) => void;
  clearBundleItems: (items: BundleItemFragment[]) => void;

  // Bulk import
  selectedRemoteItems: RemoteAppItemFragment[];
  toggleRemoteItem: (item: RemoteAppItemFragment) => void;
  selectRemoteItems: (items: RemoteAppItemFragment[]) => void;
  clearRemoteItems: (items: RemoteAppItemFragment[]) => void;

  // Bulk import V2
  selectedUnmanagedItems: SelectedUnmanagedItem[];
  toggleUnmanagedItem: (item: SelectedUnmanagedItem) => void;
  selectUnmanagedItems: (items: SelectedUnmanagedItem[]) => void;
  selectUnmanagedItem: (item: SelectedUnmanagedItem) => void;
  clearUnmanagedItem: (item: SelectedUnmanagedItem) => void;
  clearUnmanagedItems: (item: SelectedUnmanagedItem[]) => void;

  // App detail state
  appDetailState?: AppDetailState;
  setAppDetailState: (appInfo: AppDetailState) => void;
}

export const AppsContext = createContext<AppsContextData>({
  isSelectMode: false,
  setIsSelectMode: () => {},
  selectedItems: [],
  toggleItem: () => {},
  selectItem: () => {},
  selectItems: () => {},
  clearItem: () => {},
  clearItems: () => {},
  clearSelectedItems: () => {},
  setBulkMode: () => {},
  layoutOption: "grid",
  setLayoutOption: () => {},
  selectedRemoteItems: [],
  toggleRemoteItem: () => {},
  selectRemoteItems: () => {},
  clearRemoteItems: () => {},
  setAppDetailState: () => {},
  selectedBundleItems: [],
  selectBundleItems: () => {},
  clearBundleItems: () => {},
  selectUnmanagedItems(): void {},
  selectUnmanagedItem(): void {},
  clearUnmanagedItem(): void {},
  clearUnmanagedItems(): void {},
  selectedUnmanagedItems: [],
  toggleUnmanagedItem(): void {},
});

export const AppsContextProvider: React.FC<{}> = ({ children }) => {
  // Bulk select
  const [selectedItems, setSelectedItems] = useState<SelectedItem[]>([]);
  const [selectedRemoteItems, setSelectedRemoteItems] = useState<
    RemoteAppItemFragment[]
  >([]);
  const [selectedUnmanagedItems, setSelectedUnmanagedItems] = useState<
    SelectedUnmanagedItem[]
  >([]);
  const [selectedBundleItems, setSelectedBundleItems] = useState<
    BundleItemFragment[]
  >([]);
  const [isSelectMode, setIsSelectMode] = useState(false);
  const [bulkMode, setBulkMode] = useState<BulkMode>();
  const [
    layoutOptionKeyFromUrl,
    setLayoutOptionKey,
  ] = useLocalStorage<LayoutOptionKey>(LAYOUT_OPTION_LOCAL_STORAGE_KEY, "grid");

  // App detail state
  const [appDetailState, setAppDetailState] = useState<AppDetailState>();
  useMountEffect(() => {
    const cachedSelectedItems = localStorage.getItem(
      SELECTED_ITEMS_LOCAL_STORAGE_KEY
    );
    if (cachedSelectedItems) {
      try {
        const parsed = JSON.parse(cachedSelectedItems) as SelectedItem[];
        if (parsed) {
          setBulkMode("request");
          setSelectedItems(parsed);
          setIsSelectMode(true);
          localStorage.removeItem(SELECTED_ITEMS_LOCAL_STORAGE_KEY);
        }
      } catch (err) {
        logError("Failed to parse selected request items from local storage");
      }
    }
    const cachedSelectedBundleItems = localStorage.getItem(
      SELECTED_BUNDLE_ITEMS_LOCAL_STORAGE_KEY
    );
    if (cachedSelectedBundleItems) {
      try {
        const parsed = JSON.parse(
          cachedSelectedBundleItems
        ) as BundleItemFragment[];
        if (parsed) {
          setBulkMode("request");
          setSelectedBundleItems(parsed);
          setIsSelectMode(true);
          localStorage.removeItem(SELECTED_BUNDLE_ITEMS_LOCAL_STORAGE_KEY);
        }
      } catch (err) {
        logError(
          "Failed to parse selected request bundle items from local storage"
        );
      }
    }
  });

  const handleToggleItem = (item: SelectedItem) => {
    if (selectedItems.some((i) => i.id === item.id)) {
      setSelectedItems((items) => items.filter((r) => r.id !== item.id));
    } else {
      setSelectedItems((items) => [...items, item]);
    }
  };

  const handleSelectItem = (item: SelectedItem) => {
    if (!selectedItems.some((i) => i.id === item.id)) {
      setSelectedItems((items) => [...items, item]);
    }
  };

  const handleSelectItems = (items: SelectedItem[]) => {
    const newItems = items.filter(
      (item) => !selectedItems.some((i) => i.id === item.id)
    );
    setSelectedItems((prev) => [...prev, ...newItems]);
  };

  const handleUnselectItem = (item: SelectedItem) => {
    if (selectedItems.some((i) => i.id === item.id)) {
      setSelectedItems((items) => items.filter((r) => r.id !== item.id));
    }
  };

  const handleUnselectItems = (items: SelectedItem[]) => {
    setSelectedItems((prev) =>
      prev.filter((i) => !items.some((item) => item.id === i.id))
    );
  };

  const handleToggleRemoteItem = (item: RemoteAppItemFragment) => {
    if (selectedRemoteItems.some((i) => i.id === item.id)) {
      setSelectedRemoteItems((items) => items.filter((r) => r.id !== item.id));
    } else {
      setSelectedRemoteItems((items) => [...items, item]);
    }
  };

  const handleSelectRemoteItems = (items: RemoteAppItemFragment[]) => {
    const newItems = items.filter(
      (item) => !selectedRemoteItems.some((i) => i.id === item.id)
    );
    setSelectedRemoteItems((prev) => [...prev, ...newItems]);
  };

  const handleUnselectRemoteItems = (items: RemoteAppItemFragment[]) => {
    setSelectedRemoteItems((prev) =>
      prev.filter((i) => !items.some((item) => item.id === i.id))
    );
  };

  const handleToggleUnmanagedItem = (item: SelectedUnmanagedItem) => {
    if (selectedUnmanagedItems.some((i) => i.id === item.id)) {
      setSelectedUnmanagedItems((items) =>
        items.filter((r) => r.id !== item.id)
      );
    } else {
      setSelectedUnmanagedItems((items) => [...items, item]);
    }
  };

  const handleSelectUnmanagedItem = (item: SelectedUnmanagedItem) => {
    if (!selectedUnmanagedItems.some((i) => i.id === item.id)) {
      setSelectedUnmanagedItems((items) => [...items, item]);
    }
  };

  const handleUnselectUnmanagedItem = (item: SelectedUnmanagedItem) => {
    if (selectedUnmanagedItems.some((i) => i.id === item.id)) {
      setSelectedUnmanagedItems((items) =>
        items.filter((r) => r.id !== item.id)
      );
    }
  };

  const handleUnselectUnmanagedItems = (items: SelectedUnmanagedItem[]) => {
    setSelectedUnmanagedItems((prev) =>
      prev.filter((i) => !items.some((item) => item.id === i.id))
    );
  };

  const handleSelectUnmanagedItems = (items: SelectedUnmanagedItem[]) => {
    const newItems = items.filter(
      (item) => !selectedUnmanagedItems.some((i) => i.id === item.id)
    );
    setSelectedUnmanagedItems((prev) => [...prev, ...newItems]);
  };

  const handleSelectBundleItems = (items: BundleItemFragment[]) => {
    const newItems = items.filter(
      (item) => !selectedBundleItems.some((i) => i.key === item.key)
    );
    setSelectedBundleItems((prev) => [...prev, ...newItems]);
  };

  const handleUnselectBundleItems = (items: BundleItemFragment[]) => {
    setSelectedBundleItems((prev) =>
      prev.filter((i) => !items.some((item) => item.key === i.key))
    );
  };

  const handleClearSelectedItems = () => {
    setSelectedItems([]);
    setIsSelectMode(false);
    setSelectedRemoteItems([]);
    setBulkMode(undefined);
    setSelectedBundleItems([]);
    setSelectedUnmanagedItems([]);
  };

  const handleSetIsSelectMode = (isSelectMode: boolean) => {
    // Because of the "Select All" functionality it is technically possible
    // that someone ends up in a state where they have isSelectMode = false
    // but selectedItems is not empty bc a request from "Select All" was returned after
    // the user exited select mode. To avoid possible confusion we clear the selectedItems
    // when entering select mode
    if (isSelectMode) {
      setSelectedItems([]);
      setSelectedRemoteItems([]);
    }
    setIsSelectMode(true);
  };

  return (
    <AppsContext.Provider
      value={{
        isSelectMode,
        setIsSelectMode: handleSetIsSelectMode,
        selectedItems,
        toggleItem: handleToggleItem,
        selectItem: handleSelectItem,
        selectItems: handleSelectItems,
        clearItem: handleUnselectItem,
        clearItems: handleUnselectItems,
        clearSelectedItems: handleClearSelectedItems,
        bulkMode: bulkMode,
        setBulkMode: setBulkMode,
        layoutOption: layoutOptionKeyFromUrl,
        setLayoutOption: setLayoutOptionKey,
        selectedRemoteItems,
        toggleRemoteItem: handleToggleRemoteItem,
        selectRemoteItems: handleSelectRemoteItems,
        clearRemoteItems: handleUnselectRemoteItems,
        selectedUnmanagedItems,
        toggleUnmanagedItem: handleToggleUnmanagedItem,
        selectUnmanagedItems: handleSelectUnmanagedItems,
        selectUnmanagedItem: handleSelectUnmanagedItem,
        clearUnmanagedItem: handleUnselectUnmanagedItem,
        clearUnmanagedItems: handleUnselectUnmanagedItems,
        appDetailState,
        setAppDetailState,
        selectedBundleItems,
        selectBundleItems: handleSelectBundleItems,
        clearBundleItems: handleUnselectBundleItems,
      }}
    >
      {children}
    </AppsContext.Provider>
  );
};
