import { HierarchyPointLink } from "@visx/hierarchy/lib/types";
import LinkHorizontalDiagonal from "@visx/shape/lib/shapes/link/diagonal/LinkHorizontal";
import { AccessType, EdgeMetadataFragment } from "api/generated/graphql";
import { BASE_NODE_HEIGHT, TreeNodeData } from "components/viz/common";
import { vars } from "css/vars.css";
import moment from "moment";
import * as React from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import { useFilterState } from "./contexts/FilterContext";
import { GraphContext } from "./contexts/GraphContext";
import {
  getLinkHighlightColor,
  getLinkHighlightColorV3,
  getNodeWidth,
  shouldShowLinkV3,
} from "./utils";

interface LinkProps {
  link: HierarchyPointLink<TreeNodeData>;
  metadata?: EdgeMetadataFragment;
}

const Link = React.memo(({ link, metadata }: LinkProps) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const { graphState } = React.useContext(GraphContext);
  const filterState = useFilterState();

  const linkId = `${link.source.data.nodeId}-${link.target.data.nodeId}`;

  const selectedIds = [
    ...graphState.selectedUserIds,
    ...graphState.selectedGroupIds,
    ...graphState.selectedResourceIds,
  ];
  // A link is backgrounded if neither node is selected
  const backgrounded =
    selectedIds.length > 0 &&
    !selectedIds.includes(link.source.data.nodeId) &&
    !selectedIds.includes(link.target.data.nodeId);
  // A link is actionable if both nodes are selected
  const actionable =
    selectedIds.includes(link.source.data.nodeId) &&
    selectedIds.includes(link.target.data.nodeId);

  // Calculate edge color
  let strokeColor = vars.color.gray600;
  if (backgrounded) {
    strokeColor = vars.color.gray200;
  }
  // Apply any colors from highlight filters
  const highlightColor = hasV3
    ? getLinkHighlightColorV3(backgrounded, metadata)
    : getLinkHighlightColor(filterState, graphState, backgrounded, metadata);
  if (highlightColor) {
    strokeColor = highlightColor;
  }
  // Override any colors for selected edges
  if (actionable) {
    strokeColor = vars.color.blue500V3;
  }

  const strokeWidth = actionable ? "2" : "1.5";

  const hasHighlightApplied =
    filterState.accessTypes.length > 0 || filterState.usage != null;
  if (hasV3 && !shouldShowLinkV3(filterState, metadata)) {
    return null;
  }
  if (
    hasHighlightApplied &&
    filterState.showHighlightedOnly &&
    !highlightColor
  ) {
    return null;
  }

  let title = "";
  if (metadata?.accessType?.type === AccessType.Expiring) {
    title = "Expires: " + moment(metadata.accessType.expiration).fromNow();
  }

  return (
    <LinkHorizontalDiagonal
      data={link}
      source={function (d) {
        // Make the link start at the right side of the box (rather than on the left/origin point)
        const nodeWidth = getNodeWidth(d.source.data);
        return {
          // Our custom x,y are opposite the default calculated x,y because we are displaying
          // the tree from left to right instead of top to down
          x:
            (d.source.data.y ?? d.source.x) +
            (d.source.data.height - BASE_NODE_HEIGHT) / 2,
          y: (d.source.data.x ?? d.source.y) + nodeWidth,
        };
      }}
      target={function (d) {
        return {
          x: d.target.data.y ?? d.target.x,
          y: d.target.data.x ?? d.target.y,
        };
      }}
    >
      {({ path: pathData }) => {
        return (
          <>
            <defs>
              <marker
                id={linkId}
                viewBox="0 0 8 8"
                refX="4"
                refY="4"
                markerWidth="4"
                markerHeight="4"
              >
                <circle cx="4" cy="4" r="4" fill={strokeColor} />
              </marker>
            </defs>
            <path
              d={pathData(link) ?? ""}
              stroke={strokeColor}
              strokeWidth={strokeWidth}
              fill="none"
              markerStart={`url(#${linkId})`}
              markerEnd={`url(#${linkId})`}
            >
              {title && <title>{title}</title>}
            </path>
          </>
        );
      }}
    </LinkHorizontalDiagonal>
  );
});

export default Link;
