// eslint-disable-next-line no-restricted-imports
import { Skeleton as MUISkeleton } from "@material-ui/lab";
import sprinkles from "css/sprinkles.css";
import { spaceVars } from "css/vars.css";
import React from "react";

type SkeletonProps = {
  height?: string;
  variant?: "text" | "rect" | "circle";
  width?: string;
  repeat?: number;

  // Margin helpers
  marginTop?: spaceVars;
  marginBottom?: spaceVars;
  marginLeft?: spaceVars;
  marginRight?: spaceVars;
  marginX?: spaceVars;
  marginY?: spaceVars;
  margin?: spaceVars;
};

const Skeleton: React.FC<SkeletonProps> = (props) => {
  let skeletons = [];
  const repeat = props.repeat || 1;
  for (let i = 0; i < repeat; i++) {
    skeletons.push(
      <MUISkeleton
        key={`skeleton-${i}`}
        animation="wave"
        height={props.height}
        variant={props.variant}
        width={props.width}
        className={sprinkles({
          marginTop: props.marginTop,
          marginBottom: props.marginBottom,
          marginLeft: props.marginLeft,
          marginRight: props.marginRight,
          marginX: props.marginX,
          marginY: props.marginY,
          margin: props.margin,
        })}
      />
    );
  }

  return <>{skeletons}</>;
};

export default Skeleton;
