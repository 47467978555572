import { FiltersMatchMode } from "api/generated/graphql";
import { Button, ButtonGroup } from "components/ui";
import sprinkles from "css/sprinkles.css";

interface Props {
  matchMode: FiltersMatchMode;
  onChange: (matchMode: FiltersMatchMode) => void;
}

const FilterMatchModeSwitch = ({ matchMode, onChange }: Props) => {
  return (
    <div
      className={sprinkles({
        marginBottom: "md",
        display: "flex",
        gap: "sm",
      })}
    >
      Include entities that match
      <ButtonGroup>
        <Button
          label="ANY"
          size="sm"
          type={matchMode === FiltersMatchMode.Any ? "primary" : undefined}
          outline={matchMode !== FiltersMatchMode.Any}
          onClick={() => onChange(FiltersMatchMode.Any)}
        />
        <Button
          label="ALL"
          size="sm"
          type={matchMode === FiltersMatchMode.All ? "primary" : undefined}
          outline={matchMode !== FiltersMatchMode.All}
          onClick={() => onChange(FiltersMatchMode.All)}
        />
      </ButtonGroup>
      of the following filters:
    </div>
  );
};

export default FilterMatchModeSwitch;
