import { ButtonV3, ContextMenu, EntityIcon, Icon, Link } from "components/ui";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import { UserExperience, useUserHasUserExperience } from "utils/auth/auth";

import * as styles from "./EndUserItemDetailsCard.css";

type EndUserItemDetailsCardProps = {
  icon?: IconData;
  title?: string;
  titleAccessory?: JSX.Element;
  subtitle?: string;
  description?: string;
  actions?: JSX.Element;
  rightContent?: JSX.Element;
  isModal?: boolean;
  detailsURL?: string;
  extraMenuOptions?: PropsFor<typeof ContextMenu>["options"];
};

export const EndUserItemDetailsCard = (props: EndUserItemDetailsCardProps) => {
  const showEndUserExp =
    useUserHasUserExperience() === UserExperience.EndUserUX;
  const renderIcon = () => {
    const iconData = props.icon;
    const iconSize = props.isModal ? "xxl" : props.description ? "xxl" : "xxxl";
    if (!iconData) return null;

    if (iconData.type === "src") {
      return <Icon data={iconData} size={iconSize} iconStyle="rounded" />;
    } else if (iconData.type === "name") {
      return <Icon name={iconData.icon} size={iconSize} iconStyle="rounded" />;
    } else {
      return (
        <EntityIcon
          type={iconData.entityType}
          size={iconSize}
          iconStyle="rounded"
          onlyBrandIcon={true}
        />
      );
    }
  };

  if (props.description || props.isModal) {
    return (
      <div className={styles.card({ isModal: true })}>
        <div className={styles.cardHeader}>
          <div
            className={sprinkles({
              display: "flex",
              flexDirection: "column",
              gap: "sm",
              justifyContent: "space-between",
            })}
          >
            <div
              className={sprinkles({
                display: "flex",
                gap: "mdlg",
                marginTop: "sm",
              })}
            >
              {renderIcon()}
              <div className={styles.titleContainer}>
                <div
                  className={sprinkles({
                    display: "flex",
                    flexDirection: "column",
                  })}
                >
                  <div
                    className={sprinkles({
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "sm",
                    })}
                  >
                    <div className={styles.title({ large: false })}>
                      {props.title}
                    </div>
                    {!showEndUserExp && (
                      <Link
                        url={props.detailsURL || ""}
                        target="_blank"
                        external={true}
                      />
                    )}
                    {props.titleAccessory}
                  </div>
                  <div className={styles.subtitle}>{props.subtitle}</div>
                </div>
              </div>
            </div>
            <div
              className={sprinkles({
                display: "flex",
                flexDirection: "column",
              })}
            >
              <div className={styles.description}>{props.description}</div>
              <div className={styles.actions}>{props.actions}</div>
            </div>
          </div>
          <div>{props.rightContent}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={styles.card({ isModal: false })}>
        <div className={styles.cardHeader}>
          <div className={sprinkles({ display: "flex" })}>
            <div className={sprinkles({ display: "flex", gap: "mdlg" })}>
              {renderIcon()}
              <div className={styles.titleContainer}>
                <div
                  className={sprinkles({
                    display: "flex",
                    flexDirection: "column",
                  })}
                >
                  <div className={styles.title({ large: true })}>
                    {props.title}
                  </div>
                  <div className={styles.subtitle}>{props.subtitle}</div>
                </div>
                <div className={styles.actions}>{props.actions}</div>
              </div>
            </div>
          </div>
          <div className={styles.rightContent}>
            {props.extraMenuOptions && props.extraMenuOptions.length > 0 && (
              <div>
                <ContextMenu
                  rightAligned
                  options={props.extraMenuOptions}
                  renderButton={(onClick) => (
                    <div
                      className={sprinkles({
                        marginRight: "sm",
                      })}
                      onClick={onClick}
                    >
                      <ButtonV3 leftIconName="dots-horizontal" size="sm" />
                    </div>
                  )}
                />
              </div>
            )}
            {props.rightContent}
          </div>
        </div>
      </div>
    );
  }
};
