import {
  GroupFragment,
  useBulkUpdateMutation,
  useGroupQuery,
} from "api/generated/graphql";
import { ResourceConfig } from "components/forms/common";
import {
  getResourceConfigChangedFields,
  makeConfigForGroup,
  makeUpdateInputForGroup,
  validateResourceConfig,
} from "components/forms/utils";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import { useUnsavedChangesModal } from "components/modals/update/UnsavedChangesModal";
import { useToast } from "components/toast/Toast";
import { EntityIcon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useContext, useState } from "react";
import { useParams } from "react-router";
import useLogEvent from "utils/analytics";
import { AuthorizedActionManage } from "utils/auth/auth";
import { logError } from "utils/logging";
import useSyncStatusToast from "utils/sync/useSyncStatusToast";
import {
  ForbiddenPage,
  NotFoundPage,
  UnexpectedErrorPage,
} from "views/error/ErrorCodePage";
import OrgContext from "views/settings/OrgContext";

import ResourceEditForm from "./ResourceEditForm";

const GroupEditView = () => {
  const { groupId } = useParams<Record<string, string>>();

  const { data, error, loading } = useGroupQuery({
    variables: {
      input: {
        id: groupId,
      },
    },
  });
  let group: GroupFragment | undefined;
  let notFound = false;
  if (data?.group.__typename === "GroupResult") {
    group = data.group.group;
  } else if (data?.group.__typename === "GroupNotFoundError") {
    notFound = true;
  }

  if (loading) {
    return <FullscreenSkeleton />;
  }
  if (!group?.authorizedActions?.includes(AuthorizedActionManage)) {
    return <ForbiddenPage />;
  }
  if (notFound) {
    return <NotFoundPage entity="Group" />;
  }
  if (!group || error) {
    return <UnexpectedErrorPage error={error} />;
  }

  return <GroupEdit group={group} />;
};

interface Props {
  group: GroupFragment;
}

const GroupEdit = ({ group }: Props) => {
  const logEvent = useLogEvent();
  const { orgState } = useContext(OrgContext);
  const { displaySuccessToast } = useToast();

  const [bulkUpdate, { loading: updateLoading }] = useBulkUpdateMutation({
    refetchQueries: ["Group"],
  });
  const showSyncStatusToast = useSyncStatusToast({
    loadingText: "Successfully updated, now syncing...",
    queriesToRefetch: ["Group"],
  });

  const initialConfig = makeConfigForGroup(
    group,
    group.configTemplate ?? undefined
  );
  const [config, setConfig] = useState<Partial<ResourceConfig>>(initialConfig);
  const [errors, setErrors] = useState<string[]>([]);

  const {
    handleClose,
    maybeRenderUnsavedChangesModal,
  } = useUnsavedChangesModal(`/groups/${group.id}`);

  const handleSave = async () => {
    if (!group || !initialConfig) return;

    const errors = validateResourceConfig(config, orgState);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    logEvent({
      name: "apps_item_edit_click",
      properties: {
        itemType: group.groupType,
      },
    });

    try {
      setErrors([]);
      const changedConfig = getResourceConfigChangedFields(
        initialConfig,
        config
      );
      const inputFields = makeUpdateInputForGroup(changedConfig);
      if (
        initialConfig.configurationTemplate?.id &&
        !config.configurationTemplate?.id
      ) {
        inputFields.configurationId = {};
      }

      const input = inputFields;

      const { data } = await bulkUpdate({
        variables: {
          input: {
            groupIds: [group.id],
            ...input,
          },
        },
      });

      switch (data?.bulkUpdateItems.__typename) {
        case "BulkUpdateItemsResult":
          setErrors([]);
          if (data.bulkUpdateItems.syncTask?.id) {
            showSyncStatusToast(data.bulkUpdateItems.syncTask.id);
          } else {
            displaySuccessToast(`Successfully updated group`);
          }
          handleClose(false);
          break;
        case "TagNotFoundError":
          setErrors(["Failed to get tag data"]);
          break;
        case "InvalidUpdateGroupVisibilityGroupError":
          setErrors(["Invalid visibility group"]);
          break;
        case "InvalidReviewerSettingsError":
          setErrors(["Invalid reviewer stage configuration"]);
          break;
        case "GroupMaxDurationTooLargeError":
          setErrors([
            "Group max access duration cannot exceed org-wide max duration",
          ]);
          break;
        case "TooManyGroupLeadersError":
          logError(new Error("Tried to create too many group leaders"));
          setErrors(["A group cannot have more than 10 leaders"]);
          break;
        default:
          setErrors(["Failed to update group."]);
      }
    } catch (err) {
      setErrors(["Failed to update group."]);
    }
  };

  const title = (
    <>
      Edit:
      <div className={sprinkles({ display: "flex", alignItems: "center" })}>
        <EntityIcon type={group.groupType} size="lg" />
      </div>
      {group.name}
    </>
  );

  const hasChanges = !_.isEqual(config, initialConfig);
  const hasRequiredFields =
    (config.name?.trim() && config.adminOwner?.id) ||
    config.commonMetadata?.matchRemoteName;

  return (
    <FullscreenView
      title={title}
      onCancel={() => handleClose(hasChanges)}
      onPrimaryButtonClick={handleSave}
      primaryButtonDisabled={updateLoading || !hasChanges || !hasRequiredFields}
    >
      <ResourceEditForm config={config} onChange={setConfig} errors={errors} />
      {maybeRenderUnsavedChangesModal()}
    </FullscreenView>
  );
};

export default GroupEditView;
