import {
  AccessReviewUserFragment,
  ReviewerUserStatus,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Button } from "components/ui";
import React, { useContext } from "react";
import { getOidcData, OidcPostAuthAction } from "utils/oidc/oidc";
import AccessReviewContext, {
  AccessReviewContextActionType,
  emptyPerformReviewState,
} from "views/access_reviews/AccessReviewContext";
import { SubmitAccessReviewButtons } from "views/access_reviews/common/SubmitAccessReviewButtons";
import styles from "views/access_reviews/common/SubmitReviewModalStyles.module.scss";
import { getAccessReviewUserId } from "views/access_reviews/users/AccessReviewUsers";

type AccessReviewUserActionButtonsProps = {
  accessReviewUser: AccessReviewUserFragment;
};

const AccessReviewUserActionButtons = (
  props: AccessReviewUserActionButtonsProps
) => {
  const { authState } = useContext(AuthContext);
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );

  const user = props.accessReviewUser.user;
  if (!user) {
    return <></>;
  }

  const selfReviewAllowed =
    props.accessReviewUser.accessReview?.selfReviewAllowed;

  // If this access review is not ongoing, don't allow any actions to be performed.
  if (
    !accessReviewState.ongoingAccessReviewIdSet.has(
      props.accessReviewUser.accessReviewId
    )
  ) {
    return <></>;
  }

  let performReviewState =
    accessReviewState.performReviewStateByUARUserId[
      getAccessReviewUserId(props.accessReviewUser)
    ];

  const queryParams = new URLSearchParams(location.search);
  if (queryParams.has("oidc_auth")) {
    // Restore state from before redirect
    const oidcAuthStatus = queryParams.get("oidc_auth");
    if (oidcAuthStatus === "success") {
      const oidcData = getOidcData();
      if (
        oidcData &&
        oidcData.params?.action === OidcPostAuthAction.PerformAccessReview
      ) {
        if (oidcData.params.performReviewState) {
          performReviewState = oidcData.params.performReviewState;
        }
      }
    }
  }
  const isUserBeingReviewed = !!performReviewState;
  if (isUserBeingReviewed) {
    return (
      <SubmitAccessReviewButtons
        entityName={user.fullName}
        accessReviewId={props.accessReviewUser.accessReviewId}
        performReviewState={performReviewState}
        accessReviewItem={props.accessReviewUser}
      />
    );
  }

  let isReviewer = false;
  for (const reviewer of props.accessReviewUser.reviewerUsers) {
    if (
      reviewer.userId === authState.user?.user.id &&
      reviewer.status === ReviewerUserStatus.NotStarted
    ) {
      isReviewer = true;
      break;
    }
  }

  const canReviewUser =
    isReviewer && (selfReviewAllowed || user.id !== authState.user?.user.id);

  return (
    <div className={styles.accessReviewButtons}>
      {canReviewUser && (
        <Button
          type="warning"
          label="Review Access"
          leftIconName="check-square"
          onClick={() => {
            accessReviewDispatch({
              type: AccessReviewContextActionType.AccessReviewItemUpdate,
              payload: {
                performReviewStateByUARUserId: {
                  ...accessReviewState.performReviewStateByUARUserId,
                  [getAccessReviewUserId(
                    props.accessReviewUser
                  )]: emptyPerformReviewState(),
                },
              },
            });
          }}
        />
      )}
    </div>
  );
};

export default AccessReviewUserActionButtons;
