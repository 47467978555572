import React from "react";
import { Redirect, Switch } from "react-router";
import { Route } from "react-router-dom";
import CreateGoogle from "views/idp/create/google_idp/CreateGoogle";

const CreateIdpConnection = () => {
  return (
    <Switch>
      <Route
        exact
        path={`/settings/idp/create/google`}
        component={CreateGoogle}
      />
      <Redirect to="/settings/idp/browse" />
    </Switch>
  );
};

export default CreateIdpConnection;
