import "components/toast/Toastify.css";

import whiteSpinner from "assets/spinners/white-spinner-transparent.gif";
import Icon, { IconName } from "components/ui/icon/Icon";
import sprinkles from "css/sprinkles.css";
import React, { ReactElement, ReactText, useCallback, useRef } from "react";
import { toast, ToastContainer } from "react-toastify";

import * as styles from "./Toast.css";

export enum ToastStyle {
  Default = "toast",
  Banner = "banner",
}

/**
 * This hook returns functions that can be used to display a toast.
 * @param style
 */
export const useToast = (
  style?: ToastStyle
): {
  displayLoadingToast: (
    message?: string | JSX.Element,
    autoClose?: boolean,
    closeButton?: ({ closeToast }: { closeToast: () => void }) => ReactElement
  ) => void;
  displaySuccessToast: (message: string | JSX.Element) => void;
  displayErrorToast: (message: string | JSX.Element) => void;
  displayCustomToast: (
    message: string | JSX.Element,
    iconName: IconName,
    autoClose?: boolean,
    detach?: boolean
  ) => void;
  clearToast: () => void;
} => {
  const toastIdRef = useRef<ReactText | null>(null);

  const toastifyPosition =
    style === "banner" ? toast.POSITION.TOP_CENTER : toast.POSITION.BOTTOM_LEFT;
  const defaultCloseButton = CustomCloseButton(style ?? ToastStyle.Default);

  const displayLoadingToast = useCallback(
    (
      message?: string | JSX.Element,
      autoClose?: boolean,
      closeButton?: ({ closeToast }: { closeToast: () => void }) => ReactElement
    ) => {
      const content = (
        <ToastContent
          externalIconUrl={whiteSpinner}
          message={message ? message : `Loading...`}
        />
      );

      if (toastIdRef.current && toast.isActive(toastIdRef.current)) {
        toast.update(toastIdRef.current, {
          render: content,
          className: styles.toast({ color: "gray", type: style }),
          autoClose: autoClose ? 5000 : false,
          position: toastifyPosition,
          closeButton: closeButton ?? defaultCloseButton,
        });
      } else {
        toastIdRef.current = toast(content, {
          className: styles.toast({ color: "gray", type: style }),
          autoClose: autoClose ? 5000 : false,
          position: toastifyPosition,
          closeButton: defaultCloseButton,
        });
      }
    },
    [toastifyPosition, style, defaultCloseButton]
  );

  const displaySuccessToast = useCallback(
    (message: string | JSX.Element) => {
      const content = <ToastContent iconName="check" message={message} />;

      if (toastIdRef.current && toast.isActive(toastIdRef.current)) {
        toast.update(toastIdRef.current, {
          render: content,
          className: styles.toast({ color: "green", type: style }),
          autoClose: 5000,
          position: toastifyPosition,
          closeButton: defaultCloseButton,
        });
      } else {
        toastIdRef.current = toast(content, {
          className: styles.toast({ color: "green", type: style }),
          autoClose: 5000,
          position: toastifyPosition,
          closeButton: defaultCloseButton,
        });
      }
    },
    [toastifyPosition, style, defaultCloseButton]
  );

  const displayErrorToast = useCallback(
    (message: string | JSX.Element) => {
      const content = (
        <ToastContent iconName="alert-triangle" message={message} />
      );

      if (toastIdRef.current && toast.isActive(toastIdRef.current)) {
        toast.update(toastIdRef.current, {
          render: content,
          className: styles.toast({ color: "red", type: style }),
          autoClose: 10000,
          position: toastifyPosition,
          closeButton: defaultCloseButton,
        });
      } else {
        toastIdRef.current = toast(content, {
          className: styles.toast({ color: "red", type: style }),
          autoClose: 10000,
          position: toastifyPosition,
          closeButton: defaultCloseButton,
        });
      }
    },
    [toastifyPosition, style, defaultCloseButton]
  );

  const displayCustomToast = useCallback(
    (
      message: string | JSX.Element,
      iconName: IconName,
      autoClose?: boolean,
      detach?: boolean
    ) => {
      const content = (
        <ToastContent
          iconName={iconName}
          message={message ? message : `Loading...`}
        />
      );

      const autoCloseDuration = autoClose ?? true ? 10000 : false;
      if (toastIdRef.current && toast.isActive(toastIdRef.current) && !detach) {
        toast.update(toastIdRef.current, {
          className: styles.toast({ color: "gray", type: style }),
          autoClose: autoCloseDuration,
          render: content,
          position: toastifyPosition,
          closeButton: defaultCloseButton,
        });
      } else {
        const newToast = toast(content, {
          className: styles.toast({ color: "gray", type: style }),
          autoClose: autoCloseDuration,
          position: toastifyPosition,
          closeButton: defaultCloseButton,
        });
        if (!detach) toastIdRef.current = newToast;
      }
    },
    [style, toastifyPosition, defaultCloseButton]
  );

  // Useful for clearing out a displayLoadingToast
  const clearToast = useCallback(() => {
    if (toastIdRef.current && toast.isActive(toastIdRef.current)) {
      toast.dismiss(toastIdRef.current);
      toastIdRef.current = null;
    }
  }, []);

  return {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
    displayCustomToast,
    clearToast,
  };
};

type ToastContentProps = {
  iconName?: IconName;
  externalIconUrl?: string;
  message: string | JSX.Element;
};

const ToastContent = (props: ToastContentProps) => {
  return (
    <div className={styles.content}>
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "center",
        })}
      >
        <span className={styles.icon}>
          <Icon
            name={props.iconName}
            externalIconUrl={props.externalIconUrl}
            size="sm"
          />
        </span>
        <span className={sprinkles({ marginLeft: "sm" })}>{props.message}</span>
      </div>
    </div>
  );
};

export const CustomCloseButton = (style: ToastStyle, onClose?: () => void) => {
  const CloseButton = ({ closeToast }: { closeToast: () => void }) => (
    <div
      className={styles.closeButton({ type: style })}
      onClick={() => {
        if (onClose) {
          onClose();
        }
        closeToast();
      }}
    >
      <Icon name="x" size="xs" />
    </div>
  );
  return CloseButton;
};

export const CustomToastContainer = () => {
  return (
    <ToastContainer
      position="bottom-left"
      hideProgressBar={true}
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss
      draggable={false}
      pauseOnHover
      closeButton={CustomCloseButton(ToastStyle.Default)}
    />
  );
};
