import { EntityType, Visibility } from "api/generated/graphql";
import { FormSection } from "components/ui";
import sprinkles from "css/sprinkles.css";

import { BundleConfig, FormMode } from "./common";
import AdminRow from "./rows/AdminRow";
import DescriptionRow from "./rows/DescriptionRow";
import VisibilityRow from "./rows/VisibilityRow";

interface Props {
  mode: FormMode;
  config: Partial<BundleConfig>;
  onChange?: (config: Partial<BundleConfig>) => void;
}

const BundlesConfigForm = (props: Props) => {
  const handleChange = (key: keyof BundleConfig) => (
    val: BundleConfig[keyof BundleConfig] | undefined
  ) => {
    if (props.onChange) {
      props.onChange({ ...props.config, [key]: val });
    }
  };

  return (
    <div
      className={sprinkles({
        padding: "md",
      })}
    >
      <FormSection title="General">
        <DescriptionRow
          mode={props.mode}
          value={props.config.description}
          onChange={handleChange("description")}
        />
        <AdminRow
          mode={props.mode}
          owner={props.config.adminOwner}
          onChange={handleChange("adminOwner")}
        />
        <VisibilityRow
          mode={props.mode}
          entityType={EntityType.Bundle}
          visibility={props.config.visibility}
          visibilityGroups={props.config.visibilityGroupsIds ?? []}
          onChangeVisibilityAndGroups={(val: {
            visibility?: Visibility;
            groupIds?: string[];
            setDoNotChange?: boolean;
          }) => {
            const { visibility, groupIds, setDoNotChange } = val;
            if (setDoNotChange) {
              if (props.onChange) {
                props.onChange({
                  ...props.config,
                  visibility: undefined,
                  visibilityGroupsIds: undefined,
                });
              }
              return;
            }
            const visDict = visibility ? { visibility } : {};
            const visGroupsDict = groupIds
              ? { visibilityGroupsIds: groupIds }
              : {};
            if (props.onChange) {
              props.onChange({
                ...props.config,
                ...visDict,
                ...visGroupsDict,
              });
            }
          }}
        />
      </FormSection>
    </div>
  );
};

export default BundlesConfigForm;
