import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AuthType,
  ConnectionType,
  Maybe,
  useCreateConnectionMutation,
  Visibility,
} from "api/generated/graphql";
import ldapLogo from "assets/logos/ldap-logo.png";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { Button, FormGroup, Input } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import { validateHostname } from "views/connections/create/common";
import {
  CreateConnectionComponents as CreateConnectionComponentsV2,
  CreateConnectionView as CreateConnectionViewV2,
} from "views/connections/create/CreateConnectionComponents";
import {
  CreateConnectionComponentsV3,
  CreateConnectionViewV3,
} from "views/connections/create/CreateConnectionComponentsV3";
import VisibilitySelector from "views/visibility/VisibilitySelector";

const CreateLDAP = () => {
  return <CreateLDAPForm />;
};

const CreateLDAPForm = () => {
  const history = useHistory();
  const hasAppLevelVisibility = useFeatureFlag(FeatureFlag.AppLevelVisibility);
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const logEvent = useLogEvent();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [visibility, setVisibility] = useState<Visibility>(Visibility.Global);
  const [visibilityGroupIds, setVisibilityGroupIds] = useState<
    string[] | undefined
  >([]);
  const [serverHostname, setServerHostname] = useState("");
  const [serverPort, setServerPort] = useState("");
  const [groupsDN, setGroupsDN] = useState("");
  const [usersDN, setUsersDN] = useState("");
  const [rootUserDN, setRootUserDN] = useState("");
  const [rootPW, setRootPW] = useState("");
  const [groupsUIDKey, setGroupsUIDKey] = useState("");

  const [adminOwnerId, setAdminOwnerId] = useState<string | undefined>(
    undefined
  );

  const [createConnectionMutation, { loading }] = useCreateConnectionMutation({
    refetchQueries: ["AppsListColumn", "Connections"],
  });

  const fieldUnset =
    name === "" ||
    description === "" ||
    !adminOwnerId ||
    serverHostname === "" ||
    serverPort === "" ||
    groupsDN === "" ||
    usersDN === "" ||
    rootUserDN === "" ||
    rootPW === "" ||
    groupsUIDKey === "";

  const onSubmit = async () => {
    const hostnameErrorMessage = validateHostname(
      serverHostname,
      "Server hostname"
    );
    if (hostnameErrorMessage) {
      setErrorMessage(`Error: ${hostnameErrorMessage}`);
      return;
    }
    try {
      const { data } = await createConnectionMutation({
        variables: {
          input: {
            name: name,
            description: description,
            connectionType: ConnectionType.Ldap,
            adminOwnerId: adminOwnerId ?? "",
            visibility: visibility,
            visibilityGroupIds: visibilityGroupIds ?? [],
            importVisibility: Visibility.Global,
            metadata: {
              connectionType: ConnectionType.Ldap,
              ldap: {
                serverHostname: serverHostname,
                serverPort: Number(serverPort),
                groupsDistinguishedName: groupsDN,
                usersDistinguishedName: usersDN,
                groupsUidKey: groupsUIDKey,
              },
            },
            credentials: {
              authType: AuthType.Ldap,
              ldap: {
                rootUserDistinguishedName: rootUserDN,
                rootPassword: rootPW,
              },
            },
          },
        },
      });
      switch (data?.createConnection.__typename) {
        case "CreateConnectionResult":
          displaySuccessToast(`Success: LDAP app created`);
          history.replace(
            getResourceUrl(
              EntityTypeDeprecated.Connection,
              data.createConnection.connection.id
            )
          );
          logEvent({
            name: "apps_create_click",
            properties: {
              connectionType: ConnectionType.Ldap,
            },
          });

          break;
        case "ConnectionExistsError":
        case "ConnectionBadMetadataError":
        case "UserFacingError":
          logWarning(new Error(data.createConnection.message));
          setErrorMessage(data.createConnection.message);
          break;
        default:
          logError(new Error(`failed to create LDAP connection`));
          setErrorMessage(`Error: failed to create LDAP connection`);
      }
    } catch (error) {
      logError(error, "failed to create LDAP connection");
      setErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to create LDAP connection",
          error
        )
      );
    }
  };

  const CreateConnectionComponents = hasV3
    ? CreateConnectionComponentsV3
    : CreateConnectionComponentsV2;
  const CreateConnectionView = hasV3
    ? CreateConnectionViewV3
    : CreateConnectionViewV2;

  return (
    <CreateConnectionView
      logo={ldapLogo}
      title={"Add your LDAP server"}
      onSubmit={onSubmit}
      submitDisabled={fieldUnset}
      submitLoading={loading}
    >
      <>
        <CreateConnectionComponents
          title={"Step 1"}
          subtitle={"Create an Opal group"}
        >
          <>
            <p>
              {`Opal requires a root LDAP user to manage access to your LDAP groups.
              Please also ensure that each user has the "mail" attribute set to the email
              address of the user in Opal.`}
            </p>
            <p>
              {`To read more about how we integrate with LDAP, `}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={"https://docs.opal.dev/docs/ldap"}
              >
                {`check out our official documentation`}
              </a>
              .
            </p>
          </>
        </CreateConnectionComponents>
        <CreateConnectionComponents
          title={"Step 2"}
          subtitle={"Fill in details about your LDAP server"}
          isLast
        >
          <div>
            <FormGroup label="App name:">
              <Input
                onChange={setName}
                placeholder="Identifiable name of the LDAP server."
                value={name}
              />
            </FormGroup>
            <FormGroup label="App admin:">
              <OwnerDropdown
                selectedOwnerId={adminOwnerId}
                onSelectOwner={(owner) => setAdminOwnerId(owner?.id)}
                placeholder="Select an owner to own this app."
              />
            </FormGroup>
            <FormGroup label="Description:">
              <Input
                onChange={setDescription}
                placeholder="A brief description of the account to further inform people requesting access to it."
                value={description}
              />
            </FormGroup>
            {hasAppLevelVisibility && (
              <FormGroup label="Visibility:">
                <VisibilitySelector
                  visibility={visibility}
                  onChangeVisibility={(vis) => {
                    if (vis == Visibility.Global) {
                      setVisibility(Visibility.Global);
                      setVisibilityGroupIds([]);
                    } else {
                      setVisibility(Visibility.Team);
                    }
                  }}
                  visibilityGroups={visibilityGroupIds ?? []}
                  onChangeVisibilityGroups={setVisibilityGroupIds}
                />
              </FormGroup>
            )}
            <FormGroup label="Server hostname:">
              <Input
                onChange={setServerHostname}
                placeholder={`The hostname of your LDAP server. (e.g. "ldap.acme.com") Please ensure it's reachable from Opal.`}
                value={serverHostname}
              />
            </FormGroup>
            <FormGroup label="Server port:">
              <Input
                onChange={setServerPort}
                placeholder="The port your LDAP server is exposed on (e.g. 389)."
                value={serverPort}
              />
            </FormGroup>
            <FormGroup label="Groups distinguished name:">
              <Input
                onChange={setGroupsDN}
                placeholder={`The DN from which Opal will search for groups (e.g. "OU=groups,DC=corp,DC=opal,DC=dev").`}
                value={groupsDN}
              />
            </FormGroup>
            <FormGroup label="Users distinguished name:">
              <Input
                onChange={setUsersDN}
                placeholder={`The DN from which Opal will search for users (e.g. "OU=users,DC=corp,DC=opal,DC=dev").`}
                value={usersDN}
              />
            </FormGroup>
            <FormGroup label="Root user distinguished name:">
              <Input
                onChange={setRootUserDN}
                placeholder={`The DN of the LDAP root admin user (e.g. "CN=admin,DC=corp,DC=opal,DC=dev").`}
                value={rootUserDN}
              />
            </FormGroup>
            <FormGroup label="Root password:">
              <Input
                onChange={setRootPW}
                placeholder="The password of the LDAP root admin user."
                value={rootPW}
                type="password"
              />
            </FormGroup>
            <FormGroup label="Group attribute unique identifier:">
              <Input
                onChange={setGroupsUIDKey}
                placeholder="The name of the attribute that your LDAP server uses to uniquely identify groups."
                value={groupsUIDKey}
              />
            </FormGroup>
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
            {!hasV3 && (
              <div
                className={sprinkles({
                  display: "flex",
                  justifyContent: "flex-end",
                })}
              >
                <Button
                  type="primary"
                  disabled={fieldUnset}
                  label={"Create"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </div>
            )}
          </div>
        </CreateConnectionComponents>
      </>
    </CreateConnectionView>
  );
};

export default CreateLDAP;
