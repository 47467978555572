import { UserProductRole } from "api/generated/graphql";
import { Button, ButtonV3, FileUpload, Icon, Tooltip } from "components/ui";
import _ from "lodash";
import React from "react";
import useLogEvent from "utils/analytics";
import { checkRequiredColumns, parseCSV } from "utils/csv";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

export type UploadCSVButtonProps = {
  onChangeUserInfos: (userInfos: UserInfo[]) => void;
  onChangeErrorMessage?: (errorMessage: string) => void;
  requiredColumns: CSVColumns[];
  optionalColumns?: CSVColumns[];
  trackName?: string;
};

export enum CSVColumns {
  Email = "email",
  Name = "name",
  OpalRole = "opal_role",
  RoleRemoteId = "role_remote_id",
  DurationInMinutes = "duration_in_minutes",
}

export type UserInfo = {
  name: string;
  email: string;
  productRole?: UserProductRole;
  roleRemoteId?: string | null;
  durationInMinutes?: number;
};

export const UploadCSVButton = (props: UploadCSVButtonProps) => {
  const logEvent = useLogEvent();
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  const handleUpload = (file: File) => {
    if (props.trackName) {
      logEvent({
        name: "csv_upload_start",
        properties: { entryPoint: props.trackName },
      });
    }

    let userInfos: UserInfo[] = [];
    if (file) {
      parseCSV(file)
        .then((rows) => {
          if (rows.length === 0) {
            if (props.onChangeErrorMessage) {
              props.onChangeErrorMessage(
                "No rows detected. Empty or missing header?"
              );
            }
            return;
          }
          rows.forEach((row) => {
            checkRequiredColumns(row, props.requiredColumns);
            userInfos.push({
              name: row[CSVColumns.Name],
              email: row[CSVColumns.Email],
              // "productRole" is used when inviting a teammate as Opal Admin
              // or when adding a user as Group Admin
              productRole: row[CSVColumns.OpalRole]
                ? parseProductRole(row[CSVColumns.OpalRole])
                : undefined,
              roleRemoteId: row[CSVColumns.RoleRemoteId],
              durationInMinutes: row[CSVColumns.DurationInMinutes]
                ? parseDurationInMinutes(row[CSVColumns.DurationInMinutes])
                : undefined,
            });
          });
          userInfos = _.uniqBy(userInfos, "email");
          props.onChangeUserInfos(userInfos);
        })
        .catch((error) => {
          if (props.onChangeErrorMessage) {
            props.onChangeErrorMessage(
              `failed to parse CSV file because ${error}`
            );
          }
        });
    }

    if (props.trackName) {
      logEvent({
        name: "csv_upload_complete",
        properties: { entryPoint: props.trackName },
      });
    }
  };

  let tooltipText = `The required columns for CSV upload are: ${props.requiredColumns
    .map((col) => `"${col}".`)
    .join(", ")}`;
  if (props.requiredColumns.length === 1) {
    tooltipText = `The required column for CSV upload is: "${props.requiredColumns[0]}".`;
  }
  let optionalColumnsText = "";
  if (props.optionalColumns) {
    if (props.optionalColumns.length === 1) {
      optionalColumnsText = `Optional column: "${props.optionalColumns[0]}"`;
    } else {
      optionalColumnsText = `Optional columns: ${props.optionalColumns
        .map((col) => `"${col}"`)
        .join(", ")}`;
    }
  }

  return (
    // No layout here, leave the layout handling to the parent
    <>
      <Tooltip
        tooltipTitleText="Upload CSV format"
        tooltipText={
          <>
            {tooltipText}
            {optionalColumnsText && (
              <>
                <br />
                {optionalColumnsText}
              </>
            )}
          </>
        }
      >
        <Icon name="help-circle" color="gray600" />
      </Tooltip>
      <FileUpload
        renderButton={(onClick) =>
          hasV3 ? (
            <ButtonV3 label="Upload CSV" onClick={onClick} size="sm" outline />
          ) : (
            <Button label="Upload CSV" borderless onClick={onClick} />
          )
        }
        handleUpload={handleUpload}
        accept={[".csv"]}
      />
    </>
  );
};

const parseProductRole = (productRole: string) => {
  if (typeof productRole === "string") {
    productRole = productRole.toUpperCase();
  }
  switch (productRole) {
    case UserProductRole.Member:
      return UserProductRole.Member;
    case UserProductRole.Admin:
      return UserProductRole.Admin;
    default:
      return UserProductRole.Member;
  }
};

const parseDurationInMinutes = (
  durationInMinutes: string
): number | undefined => {
  if (durationInMinutes) {
    const parsedDurationInMinutes = parseInt(durationInMinutes);
    if (parsedDurationInMinutes > 0) {
      return parsedDurationInMinutes;
    }
  }

  return undefined;
};

export default UploadCSVButton;
