import { getModifiedErrorMessage } from "api/ApiContext";
import {
  EntityType,
  PropagationStatusCode,
  RemoveResourceUserInput,
  ResourceType,
  useRemoveResourceUsersMutation,
  UserOverviewFragment,
  UserResourceFragment,
  useUserResourcesQuery,
} from "api/generated/graphql";
import { ResourceLabel, TimeLabel } from "components/label/Label";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import PropagationStatusLabelWithModal from "components/propagation/PropagationStatusLabelWithModal";
import { Checkbox, EntityIcon, Input, Modal } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableFilters from "components/ui/table/TableFilters";
import TableHeader from "components/ui/table/TableHeader";
import { compareAccessPaths } from "components/ui/table/utils";
import sprinkles from "css/sprinkles.css";
import { Maybe } from "graphql/jsutils/Maybe";
import moment from "moment";
import pluralize from "pluralize";
import { useState } from "react";
import { getResourceUrlNew } from "utils/common";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import { usePushTaskLoader } from "utils/sync/usePushTaskLoader";
import { PropagationType } from "utils/useRemediations";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ViewSkeleton from "views/loading/ViewSkeleton";
import {
  getResourceUserAccessPathsInfo,
  ResourceUserAccessPathsInfo,
  ResourceUserAccessPointsLabel,
} from "views/resources/ResourceUserAccessPointsLabel";
import { dropNothings } from "views/utils";

import * as styles from "./UserResourcesTableV3.css";

interface UserResourceRow {
  id: string;
  userResource: UserResourceFragment;
  resourceName?: string;
  resourceType: ResourceType;
  role: string;
  accessPaths: ResourceUserAccessPathsInfo;
  expiry?: string | null;
  syncStatus?: PropagationStatusCode | null;
  isUnmanaged?: boolean;
}

type UserResourcesTableV3Props = {
  user: UserOverviewFragment;
  canEdit: boolean;
};

export const UserResourcesTableV3 = (props: UserResourcesTableV3Props) => {
  const startPushTaskPoll = usePushTaskLoader();
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);
  const [
    removeResourcesErrorMessage,
    setRemoveResourcesErrorMessage,
  ] = useState<Maybe<string>>(null);
  const [showRemoveModal, setShowRemoveModal] = useState(false);
  const [showUnmanagedResources, setShowUnmanagedResources] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery, 200);

  const transitionTo = useTransitionTo();

  const [
    removeResourceUsers,
    { loading: removeUsersLoading },
  ] = useRemoveResourceUsersMutation();

  const { data, previousData, loading, error } = useUserResourcesQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      id: props.user.id,
    },
  });

  if (loading && !data && !previousData) {
    return <ViewSkeleton />;
  }

  if (error) {
    return <UnexpectedErrorPage error={error} />;
  }

  let userResources: UserResourceFragment[] = [];
  if (previousData?.user.__typename === "UserResult") {
    userResources = previousData.user.user.userResources;
  }
  if (data?.user.__typename === "UserResult") {
    userResources = data.user.user.userResources;
  }

  const submitRemoval = async (
    resourceUsersToRemove: RemoveResourceUserInput[]
  ) => {
    try {
      const { data } = await removeResourceUsers({
        variables: {
          input: {
            resourceUsers: resourceUsersToRemove,
          },
        },
      });
      switch (data?.removeResourceUsers.__typename) {
        case "RemoveResourceUsersResult":
          startPushTaskPoll(data.removeResourceUsers.taskId, {
            refetchOnComplete: [{ userId: props.user.id }],
          });
          setShowRemoveModal(false);
          setSelectedItemIds([]);
          break;
        case "OpalAdminRoleMustHaveAtLeastOneDirectUser":
          logError(new Error(data.removeResourceUsers.message));
          setRemoveResourcesErrorMessage(data.removeResourceUsers.message);
          break;
        default:
          logError(
            new Error(`failed to remove user access to the selected resources`)
          );
          setRemoveResourcesErrorMessage(
            "Error: failed to remove user access to the selected resources"
          );
      }
    } catch (error) {
      logError(error, "failed to remove user access to the selected resources");
      setRemoveResourcesErrorMessage(
        getModifiedErrorMessage(
          "Error: failed to remove user access to the selected resources",
          error
        )
      );
    }
  };

  const RESOURCE_USER_COLUMNS: Header<UserResourceRow>[] = [
    {
      id: "resourceName",
      label: "Name",
      sortable: true,
      customCellRenderer: (row) => {
        return row.isUnmanaged ? (
          <div>{row.resourceName}</div>
        ) : (
          <ResourceLabel
            text={row.resourceName}
            pointerCursor={true}
            resourceType={row.resourceType}
            entityId={row.userResource.resourceId}
            entityTypeNew={EntityType.Resource}
            hideIcon
          />
        );
      },
      width: 130,
    },
    {
      id: "resourceType",
      label: "Type",
      customCellRenderer: (row) => {
        return (
          <span
            className={sprinkles({
              display: "flex",
              gap: "sm",
              alignItems: "center",
            })}
          >
            <EntityIcon type={row.resourceType} />
            {resourceTypeInfoByType[row.resourceType].fullName}
          </span>
        );
      },
      width: 130,
    },
    {
      id: "role",
      label: "Role",
    },
    {
      id: "accessPaths",
      label: "Access Paths",
      customCellRenderer: (row) => {
        return (
          <ResourceUserAccessPointsLabel
            user={props.user}
            resource={row.userResource.resource}
            access={row.userResource.access}
          />
        );
      },
      sortingFn: (rowA, rowB): number => {
        return compareAccessPaths(
          rowA.getValue("accessPaths"),
          rowB.getValue("accessPaths")
        );
      },
      width: 110,
    },
    {
      id: "expiry",
      label: "Expires",
      customCellRenderer: (row) => {
        const expirationTime = row.userResource.access
          ?.latestExpiringAccessPoint?.expiration
          ? moment(
              new Date(
                row.userResource.access.latestExpiringAccessPoint.expiration
              )
            )
          : null;
        return (
          <TimeLabel
            time={expirationTime}
            supportTicket={
              row.userResource.access?.latestExpiringAccessPoint.supportTicket
            }
            useExpiringLabel
          />
        );
      },
      width: 125,
    },
    {
      id: "syncStatus",
      label: "Status",
      customCellRenderer: (row) => {
        return (
          <PropagationStatusLabelWithModal
            // key is required to force re-render when a resource user has multiple roles and multiple tickets
            key={
              row.userResource.userId +
              row.userResource.resourceId +
              row.userResource.accessLevel.accessLevelRemoteId
            }
            propagationType={PropagationType.ResourceUser}
            propagationStatus={row.userResource.propagationStatus}
            isAccessReview={false}
            entityInfo={{
              roleAssignmentKey:
                row.userResource.resourceId +
                props.user.id +
                row.userResource.accessLevel.accessLevelRemoteId,
              user: props.user,
              resource: row.userResource.resource,
              role: row.userResource.accessLevel,
              lastExpiringAccessPointExpiration:
                row.userResource.access?.latestExpiringAccessPoint.expiration,
            }}
          />
        );
      },
      width: 50,
    },
  ];

  if (!showUnmanagedResources) {
    userResources = userResources.filter(
      (userResource) => userResource.resource?.isManaged
    );
  }

  const filteredUserResources = userResources.filter((userResource) => {
    if (debouncedSearchQuery === "") return true;
    if (!userResource.resource) return false;
    return userResource.resource.name
      .toLowerCase()
      .includes(debouncedSearchQuery.toLowerCase());
  });
  const rowsById: Record<string, UserResourceRow> = {};
  const rows: UserResourceRow[] = filteredUserResources.flatMap(
    (userResource) => {
      if (!userResource.resource) return [];
      const row = {
        id: userResource.resourceId + userResource.accessLevel.accessLevelName,
        userResource: userResource,
        resourceName: userResource.resource.name,
        resourceType: userResource.resource.resourceType,
        role: userResource.accessLevel.accessLevelName || "--",
        accessPaths: getResourceUserAccessPathsInfo({
          user: props.user,
          resource: userResource.resource,
          access: userResource.access,
        }),
        expiry: userResource.access?.latestExpiringAccessPoint.expiration,
        syncStatus: userResource.propagationStatus?.statusCode,
      };
      rowsById[row.id] = row;
      return [row];
    }
  );

  const getCheckboxDisabledReason = (row: UserResourceRow) => {
    if (!row.userResource.resource?.isManaged) {
      return "Unmanaged resources cannot be removed";
    } else if (!row.userResource.access?.directAccessPoint) {
      return "The user has no direct access to remove";
    }
    return undefined;
  };

  const bulkRightActions: PropsFor<
    typeof TableHeader
  >["bulkRightActions"] = dropNothings([
    props.canEdit
      ? {
          label: "Remove",
          type: "danger",
          onClick: () => setShowRemoveModal(true),
          iconName: "trash",
        }
      : null,
  ]);

  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        height: "100%",
      })}
    >
      <TableFilters>
        <TableFilters.Left>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Filter resources by name"
            />
          </div>
          <Checkbox
            size="md"
            label="Show unmanaged resources"
            checked={showUnmanagedResources}
            onChange={setShowUnmanagedResources}
          />
        </TableFilters.Left>
      </TableFilters>
      <TableHeader
        entityName={"Resource Access Points"}
        selectedNumRows={selectedItemIds.length}
        totalNumRows={rows.length}
        defaultRightActions={
          props.canEdit
            ? [
                {
                  label: "Add Resources",
                  type: "mainSecondary",
                  onClick: () => {
                    transitionTo({
                      pathname: `/users/${props.user.id}/add-resources`,
                    });
                  },
                  iconName: "plus",
                },
              ]
            : []
        }
        bulkRightActions={bulkRightActions}
      />
      <Table
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        columns={RESOURCE_USER_COLUMNS}
        defaultSortBy="resourceName"
        checkedRowIds={new Set(selectedItemIds)}
        onRowClickTransitionTo={(row) =>
          getResourceUrlNew({
            entityId: row.userResource.resourceId,
            entityType: EntityType.Resource,
          })
        }
        onCheckedRowsChange={
          props.canEdit
            ? (checkedRowIds, checked) => {
                if (checked) {
                  setSelectedItemIds((prev) => [...prev, ...checkedRowIds]);
                  return;
                } else {
                  setSelectedItemIds((prev) =>
                    prev.filter((id) => !checkedRowIds.includes(id))
                  );
                }
              }
            : undefined
        }
        selectAllChecked={
          selectedItemIds.length > 0 &&
          selectedItemIds.length ===
            rows.filter((row) => !getCheckboxDisabledReason(row)).length
        }
        onSelectAll={(checked) =>
          checked
            ? setSelectedItemIds(
                rows
                  .filter((row) => !getCheckboxDisabledReason(row))
                  .map((row) => row.id)
              )
            : setSelectedItemIds([])
        }
        getCheckboxDisabledReason={getCheckboxDisabledReason}
      />
      {showRemoveModal && (
        <Modal
          isOpen
          title="Remove User Resources"
          onClose={() => setShowRemoveModal(false)}
        >
          <Modal.Body>
            {removeResourcesErrorMessage && (
              <ModalErrorMessage errorMessage={removeResourcesErrorMessage} />
            )}
            Are you sure you want to remove{" "}
            {pluralize("direct role assignments", selectedItemIds.length, true)}{" "}
            from this user?
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Remove"
            primaryButtonDisabled={selectedItemIds.length === 0}
            primaryButtonLoading={removeUsersLoading}
            onPrimaryButtonClick={() => {
              const userResourcesToRemove: RemoveResourceUserInput[] = [];
              for (const rowId of selectedItemIds) {
                const row = rowsById[rowId];
                userResourcesToRemove.push({
                  resourceId: row.userResource.resourceId,
                  userId: props.user.id,
                  accessLevel: {
                    accessLevelName:
                      row.userResource.accessLevel.accessLevelName,
                    accessLevelRemoteId:
                      row.userResource.accessLevel.accessLevelRemoteId,
                  },
                });
              }
              submitRemoval(userResourcesToRemove);
            }}
          />
        </Modal>
      )}
    </div>
  );
};

export default UserResourcesTableV3;
