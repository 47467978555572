import {
  AccessOption,
  ConnectionApp,
  ConnectionType,
  GroupType,
  OktaResourceApp,
  ResourceType,
} from "api/generated/graphql";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import { IconData } from "components/ui/utils";
import { Maybe } from "graphql/jsutils/Maybe";
import { useURLSearchParam } from "utils/router/hooks";

import { ACCESS_OPTION_URL_KEY } from "./AppsContext";

export const OKTA_APP_ITEM_TYPE = "OKTA_APP";

export type AppView =
  | {
      view: "item-type";
      displayText: string;
      itemType: string;
    }
  | {
      view: "all-items" | "overview" | "users" | "events" | "setup";
    };

export const getAppIcon = (
  app:
    | Pick<ConnectionApp, "__typename" | "connectionType" | "connectionIconUrl">
    | Pick<OktaResourceApp, "__typename" | "iconUrl">
): IconData => {
  if (app.__typename === "ConnectionApp") {
    if (app.connectionIconUrl) {
      return {
        type: "src",
        icon: app.connectionIconUrl,
      };
    }
    return {
      type: "entity",
      entityType: app.connectionType,
    };
  } else if (app.__typename === "OktaResourceApp" && app.iconUrl) {
    if (app.iconUrl) {
      return {
        type: "src",
        icon: app.iconUrl,
      };
    }
    return {
      type: "entity",
      entityType: ConnectionType.OktaDirectory,
    };
  }
  return {
    type: "entity",
    entityType: ConnectionType.Custom,
  };
};

export const getItemTypeIcon = (itemType: string): IconData => {
  if (itemType in resourceTypeInfoByType) {
    return {
      entityType: itemType as ResourceType,
      type: "entity",
    };
  }
  if (itemType in groupTypeInfoByType) {
    return {
      entityType: itemType as GroupType,
      type: "entity",
    };
  }
  return { icon: "cube", type: "name" };
};

export type ResourceViewKey =
  | "request"
  | "overview"
  | "child-resources"
  | "containing-groups"
  | "users"
  | "events"
  | "usage"
  | "roles"
  | "sessions";

export type GroupViewKey =
  | "request"
  | "overview"
  | "resources"
  | "users"
  | "events";

export const appsBreadcrumb = {
  name: "Apps",
  to: "/apps",
};

export function useAccessOptionKey(): [
  AccessOption,
  (newAccessOption: AccessOption) => void
] {
  const [accessOptionKeyFromUrl, setAccessOptionKey] = useURLSearchParam(
    ACCESS_OPTION_URL_KEY
  );

  if (!accessOptionKeyFromUrl) return [AccessOption.All, setAccessOptionKey];

  const accessOptionKey = Object.keys(AccessOption).find(
    (o) => o.toLowerCase() === accessOptionKeyFromUrl.toLowerCase()
  )
    ? (accessOptionKeyFromUrl as AccessOption)
    : AccessOption.All;

  return [accessOptionKey, setAccessOptionKey];
}

// truncateResourceAncestorPath truncates a resource ancestry path, keeping ancestor
// names in tact and replacing the ancestors at the start of the path with ellipses.
export function truncateResourceAncestorPath(path: Maybe<string>): string {
  if (!path || path.length === 0) return "";
  var ancestors = path.split(" / ");

  var result = "";
  for (let i = ancestors.length - 2; i >= 0; i--) {
    if (result.length > 30) {
      result = "..." + result;
      break;
    } else {
      result = (i > 0 ? "/" : "") + ancestors[i] + result;
    }
  }
  return result;
}
