import { gql, useFragment } from "@apollo/client";
import {
  EventFragment,
  PropagationTaskType,
  RequestFragment,
  RequestOverviewFragment,
  RequestStatus,
} from "api/generated/graphql";
import { PropagationStatusInfo } from "components/propagation/PropagationStatusModal";
import RequestedItems from "components/requests/RequestedItems";
import { CodeEditor } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React from "react";
import { RequestTicketPropagationBanner } from "views/requests/RequestTicketPropagationBanner";

import RequestEscalateManagerButton from "./actions/RequestEscalateManagerButton";
import RequestRemindReviewersButton from "./actions/RequestRemindReviewersButton";
import { RequestEventsTable } from "./RequestEventsTable";
import * as styles from "./RequestOverviewV3.css";
import { RequestReviewers } from "./RequestReviewers";
import RequestsCommentsButton from "./RequestsComments";

interface Props {
  requestId: string;
  // TODO: remove this fragment and user useFragment instead
  request: RequestFragment;
  events: EventFragment[];
  loading: boolean;
  propagationStatusInfos?: PropagationStatusInfo[];
  propagationTaskType?: PropagationTaskType;
}

const fragment = gql`
  fragment RequestOverview on Request {
    id
    status
    ...RequestedItems
  }
  ${RequestedItems.fragment}
`;

const RequestOverviewV3 = ({
  request,
  events,
  loading,
  propagationStatusInfos,
  propagationTaskType,
  ...props
}: Props) => {
  // TODO rewrite the rest of the view to use the data from the fragment
  useFragment<RequestOverviewFragment>({
    fragment,
    fragmentName: "RequestOverview",
    from: {
      __typename: "Request",
      id: props.requestId,
    },
  });

  const propagationTickets =
    propagationStatusInfos?.flatMap(
      (propagationStatusInfo) => propagationStatusInfo.propagationTickets ?? [],
    ) ?? [];

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        {propagationTickets.length > 0 && (
          <RequestTicketPropagationBanner
            request={request}
            propagationTickets={propagationTickets}
            propagationTaskType={propagationTaskType}
          />
        )}
        <RequestedItems requestId={props.requestId} />
        {request.status === RequestStatus.Pending && (
          <>
            <div
              className={sprinkles({
                display: "flex",
                alignItems: "center",
                marginTop: "xxl",
                marginBottom: "md",
              })}
            >
              <div className={styles.header}>Reviewers</div>
              <div className={sprinkles({ display: "flex", gap: "sm" })}>
                <RequestRemindReviewersButton request={request} />
                <RequestEscalateManagerButton request={request} />
              </div>
            </div>
            <RequestReviewers request={request} />
          </>
        )}
        {request.resourceProposal && (
          <div>
            <div className={styles.header}>Role policy permissions</div>
            <div className={styles.container}>
              <CodeEditor
                code={request.resourceProposal.metadata?.policyDocument}
              />
            </div>
          </div>
        )}
        {request.resourceProposal && (
          <div>
            <div className={styles.header}>Role trust policy</div>
            <div className={styles.container}>
              <CodeEditor
                code={
                  request.resourceProposal.metadata?.assumeRolePolicyDocument
                }
              />
            </div>
          </div>
        )}
      </div>
      <div className={styles.rightContainer}>
        <RequestActivity
          request={request}
          events={events}
          loading={loading}
          {...props}
        />
      </div>
    </div>
  );
};

RequestOverviewV3.fragments = {
  entry: fragment,
};

const RequestActivity = ({ request, events, loading }: Props) => {
  return (
    <div>
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "center",
          marginBottom: "md",
        })}
      >
        <div className={styles.header}>Activity</div>
        <RequestsCommentsButton request={request} />
      </div>
      <RequestEventsTable request={request} events={events} loading={loading} />
    </div>
  );
};

export default RequestOverviewV3;
