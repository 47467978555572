import { ResourcePreviewLargeFragment } from "api/generated/graphql";
import { FormGroup, Select } from "components/ui";
import React from "react";

type RequestModalResourceDropdownProps = {
  availableResources: ResourcePreviewLargeFragment[];
  selectedResource?: ResourcePreviewLargeFragment;
  setSelectedResource: (resource?: ResourcePreviewLargeFragment) => void;
  loading?: boolean;
  title?: string;
  noMargin?: boolean;
};

export const RequestModalResourceDropdown = (
  props: RequestModalResourceDropdownProps
) => {
  return (
    <FormGroup label="Updated resource">
      <Select
        value={props.selectedResource}
        loading={props.loading}
        onChange={(newValue?: ResourcePreviewLargeFragment) => {
          props.setSelectedResource(newValue);
        }}
        options={props.availableResources}
        getOptionLabel={(resource) => resource.name}
        placeholder="Select a new resource"
      />
    </FormGroup>
  );
};

export default RequestModalResourceDropdown;
