import { useStopImpersonationMutation } from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Banner } from "components/ui";
import { useContext } from "react";
import { logError } from "utils/logging";

const ImpersonationBanner = () => {
  const { authState } = useContext(AuthContext);

  const [stopImpersonation] = useStopImpersonationMutation();

  if (!authState.impersonatingUser) return null;
  const name = authState.user?.user.fullName;

  return (
    <Banner
      isGlobal
      message={
        <>
          You are viewing the application as <strong>{name}</strong> sees it.{" "}
          <Banner.Link
            label="Go back to your own account"
            onClick={async () => {
              try {
                await stopImpersonation();
                window.location.href = "/";
              } catch (e) {
                logError(e, "could not stop impersonation");
              }
            }}
          />
        </>
      }
    />
  );
};

export default ImpersonationBanner;
