import {
  AccessReviewResourceFragment,
  EntityType,
  ServiceType,
} from "api/generated/graphql";
import AdminRow from "components/forms/rows/AdminRow";
import AppRow from "components/forms/rows/AppRow";
import DescriptionRow from "components/forms/rows/DescriptionRow";
import SnapshotTimeRow from "components/forms/rows/SnapshotTimeRow";
import VisibilityRow from "components/forms/rows/VisibilityRow";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import { FormRow, Label } from "components/ui";
import sprinkles from "css/sprinkles.css";

interface Props {
  accessReviewResource: AccessReviewResourceFragment;
}

const AccessReviewResourceOverview = ({ accessReviewResource }: Props) => {
  const resource = accessReviewResource.resource;
  if (!resource) {
    return null;
  }

  return (
    <div className={sprinkles({ padding: "md" })}>
      <SnapshotTimeRow createdAt={accessReviewResource.createdAt} />
      {resource.serviceType !== ServiceType.Opal && (
        <>
          <FormRow title="Remote name">{resource.remoteName || "--"}</FormRow>
          <FormRow title="Remote ID">{resource.remoteId || "--"}</FormRow>
        </>
      )}
      <FormRow title="Resource type">
        <Label
          label={resourceTypeInfoByType[resource.resourceType].fullName}
          icon={{ type: "entity", entityType: resource.resourceType }}
        />
      </FormRow>
      <AppRow connectionId={resource.connectionId} mode="view" />
      {resource.adminOwner && (
        <AdminRow owner={resource.adminOwner} mode="view" onChange={() => {}} />
      )}
      <VisibilityRow
        entityType={EntityType.Resource}
        visibility={resource.visibility}
        visibilityGroups={resource.visibilityGroups.map(
          (group) => group.visibilityGroupId
        )}
        mode="view"
        onChangeVisibilityAndGroups={() => {}}
      />
      <DescriptionRow
        value={resource.description}
        mode="view"
        onChange={() => {}}
      />
    </div>
  );
};

export default AccessReviewResourceOverview;
