import { TimeZone } from "@vvo/tzdb";
import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewGroupResourceVisibilityPolicy,
  AccessReviewReviewerAssignmentPolicy,
  StartAccessReviewInput,
  useStartAccessReviewMutation,
} from "api/generated/graphql";
import AssignmentPolicy from "components/access_reviews/AssignmentPolicy";
import ReminderNotifications, {
  DEFAULT_REMINDER_SCHEDULE,
  DEFAULT_REMINDER_TIME_OF_DAY,
} from "components/access_reviews/ReminderNotifications";
import StartAccessReviewStats from "components/access_reviews/scope/AccessReviewStats";
import ConnectionsFilter from "components/access_reviews/scope/ConnectionsFilter";
import EntitiesIndividualFilter from "components/access_reviews/scope/EntitiesIndividualFilter";
import EntitiesNamedFilter from "components/access_reviews/scope/EntitiesNamedFilter";
import EntitiesWithAdminFilter from "components/access_reviews/scope/EntitiesWithAdminFilter";
import EntityTypesFilter from "components/access_reviews/scope/EntityTypesFilter";
import TagFilter from "components/access_reviews/scope/TagFilter";
import UsersFilter from "components/access_reviews/scope/UsersFilter";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import { useToast } from "components/toast/Toast";
import {
  Banner,
  Button,
  Card,
  Divider,
  FormGroup,
  Input,
  RadioGroup,
  Switch,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { getDefaultTimezone, getTimeZoneGuess } from "utils/time";
import AccessReviewContext, {
  AccessReviewContextActionType,
} from "views/access_reviews/AccessReviewContext";

import DeadlineForm from "../common/DeadlineForm";
import FilterMatchModeSwitch from "../common/FilterMatchModeSwitch";
import {
  AccessReviewFilters,
  buildAccessReviewFilter,
  defaultAccessReviewFilters,
} from "./utils";

export const AccessReviewGroupResourceVisibilityOptions = [
  {
    label: "Strict",
    value: AccessReviewGroupResourceVisibilityPolicy.Strict,
    description:
      "Users can only view resources within a group that they have been assigned to review regardless of their typical visibility.",
  },
  {
    label: "Moderate",
    value: AccessReviewGroupResourceVisibilityPolicy.ViewVisibleAndAssigned,
    description:
      "Users can see all resources within a group that they have visibility to in addition to resources they have been assigned to review, but they will only be able to approve or revoke those items they've been assigned.",
  },
  {
    label: "Full visibility",
    value: AccessReviewGroupResourceVisibilityPolicy.ViewAll,
    description:
      "Users can see all resources within a group regardless of their visibility or assignments, but they will only be able to approve or revoke those items they've been assigned.",
  },
];

const CreateAccessReviewColumn = () => {
  const history = useHistory();
  const { displaySuccessToast } = useToast();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );
  const hasGroupBindings = useFeatureFlag(FeatureFlag.GroupBindings);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [name, setName] = useState<string>("");
  const [timeZone, setTimeZone] = useState<TimeZone>(
    getTimeZoneGuess() || getDefaultTimezone()
  );
  const [deadline, setDeadline] = useState<string>("");

  const [reminderSchedule, setReminderSchedule] = useState<number[]>(
    DEFAULT_REMINDER_SCHEDULE
  );
  const [reminderTimeOfDay, setReminderTimeOfDay] = useState<Date>(
    DEFAULT_REMINDER_TIME_OF_DAY
  );
  const [reminderIncludeManager, setReminderIncludeManager] = useState(false);
  const [filters, setFilters] = useState<AccessReviewFilters>(
    defaultAccessReviewFilters()
  );
  const [assignmentPolicy, setAssignmentPolicy] = useState(
    AccessReviewReviewerAssignmentPolicy.Manually
  );
  const [
    groupResourceVisibilityPolicy,
    setGroupResourceVisibilityPolicy,
  ] = useState(AccessReviewGroupResourceVisibilityPolicy.Strict);
  const [
    sendReviewerAssignmentNotifications,
    setSendReviewerAssignmentNotifications,
  ] = useState(false);
  const [selfReviewAllowed, setSelfReviewAllowed] = useState(true);
  const [showMoreFilters, setShowMoreFilters] = useState(false);
  const [includeGroupBindings, setIncludeGroupBindings] = useState(
    hasGroupBindings
  );

  const [startAccessReview, { loading }] = useStartAccessReviewMutation();

  const handleDeadlineChanges = (newDeadline: string, tz: TimeZone) => {
    if (newDeadline !== deadline) {
      setDeadline(newDeadline);
    }

    if (tz.name != timeZone.name) {
      setTimeZone(tz);
    }
  };

  const handleStartAccessReview = async () => {
    if (!deadline || !timeZone || !name) return;

    try {
      const input: StartAccessReviewInput = {
        deadline: deadline,
        name: name,
        reminderSchedule: reminderSchedule,
        reminderTimeOfDay: reminderTimeOfDay.toISOString(), // the Z suffix is a shorthand for UTC.
        reminderIncludeManager: reminderIncludeManager,
        timeZone: timeZone.name,
        reviewerAssignmentPolicy: assignmentPolicy,
        sendReviewerAssignmentNotification: sendReviewerAssignmentNotifications,
        selfReviewAllowed: selfReviewAllowed,
        filters: buildAccessReviewFilter(filters),
        groupResourceVisibilityPolicy: groupResourceVisibilityPolicy,
        includeGroupBindings: includeGroupBindings,
      };

      const { data } = await startAccessReview({
        variables: { input },
        refetchQueries: ["AccessReviews"],
      });
      switch (data?.startAccessReview.__typename) {
        case "StartAccessReviewResult": {
          accessReviewDispatch({
            type: AccessReviewContextActionType.AccessReviewUpdate,
            payload: {
              ongoingAccessReviewIdSet: accessReviewState.ongoingAccessReviewIdSet.add(
                data.startAccessReview.accessReview.id
              ),
            },
          });
          displaySuccessToast(`Success: access review started`);
          const url = `/access-reviews/${data.startAccessReview.accessReview.id}?category=ongoing`;
          history.replace(url);
          break;
        }
        default:
          logError(new Error(`failed to start access review`));
          setErrorMessage(`Error: failed to start access review`);
          containerRef.current?.scroll({ top: 0, behavior: "smooth" });
      }
    } catch (error) {
      logError(error, "failed to start access review");
      setErrorMessage(
        getModifiedErrorMessage(`Error: failed to start access review`, error)
      );
      containerRef.current?.scroll({ top: 0, behavior: "smooth" });
    }
  };

  return (
    <Column isContent maxWidth="lg">
      <ColumnHeader
        title="Create an Access Review"
        icon={{ type: "name", icon: "plus" }}
        rightActions={
          <Button
            label="Start access review"
            leftIconName="plus"
            type="primary"
            onClick={handleStartAccessReview}
            disabled={!deadline || !timeZone || !name}
            loading={loading}
          />
        }
      />
      <Divider />
      <ColumnContent ref={containerRef}>
        <div className={sprinkles({ padding: "lg" })}>
          {errorMessage ? (
            <div className={sprinkles({ marginBottom: "md" })}>
              <Banner type="error" message={errorMessage} />
            </div>
          ) : null}
          <p>
            A user access review is crucial for maintaining security and
            compliance within an organization. To create and configure one,
            identify your timeframe, determine the review scope, and set up a
            notification cadence.
          </p>
          <Card title="Access Review Information">
            <FormGroup label="Name" required>
              <Input
                value={name}
                onChange={setName}
                placeholder="Be concise but descriptive"
              />
            </FormGroup>
            <DeadlineForm
              onChange={handleDeadlineChanges}
              label={"Set a deadline"}
              timeZone={timeZone}
            />
          </Card>
          <Card title="Scope">
            <FormGroup label="Entities">
              <StartAccessReviewStats
                filters={filters}
                includeGroupBindings={includeGroupBindings}
              />
            </FormGroup>
            {
              <FormGroup label="Group Resource Visibility">
                <RadioGroup
                  value={AccessReviewGroupResourceVisibilityOptions.find(
                    (opt) => opt.value === groupResourceVisibilityPolicy
                  )}
                  options={AccessReviewGroupResourceVisibilityOptions}
                  getOptionKey={(opt) => opt.value}
                  getOptionLabel={(opt) => opt.label}
                  getOptionDescription={(opt) => opt.description}
                  onSelectValue={(opt) =>
                    setGroupResourceVisibilityPolicy(opt.value)
                  }
                />
              </FormGroup>
            }
            {hasGroupBindings && (
              // Note: "include linked groups" switch won't affect the access review stats
              <FormGroup label="Include linked groups">
                <Switch
                  label="Include linked groups in the access review. Otherwise, only the groups strictly matching the filters will be included."
                  checked={includeGroupBindings}
                  onChange={(checked) => setIncludeGroupBindings(checked)}
                  rightAlign
                />
              </FormGroup>
            )}
            <div>
              <FormGroup label="Users">
                {filters.userIDs.length === 0 && "All users"}
                <UsersFilter
                  userIDs={filters.userIDs}
                  onChangeUserIDs={(newUserIDs) => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      userIDs: newUserIDs,
                    }));
                  }}
                />
              </FormGroup>
            </div>
            <Divider />

            <FilterMatchModeSwitch
              matchMode={filters.matchMode}
              onChange={(matchMode) => {
                setFilters((prev) => ({ ...prev, matchMode }));
              }}
            />
            <EntitiesIndividualFilter
              entityIds={filters.entityIDs}
              onChangeEntityIds={(newEntityIds) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  entityIDs: newEntityIds,
                }));
              }}
            />
            <ConnectionsFilter
              connectionIDs={filters.connectionIDs}
              onChangeConnectionIDs={(newConnectionIDs) => {
                setFilters((prevFilters) => ({
                  ...prevFilters,
                  connectionIDs: newConnectionIDs,
                }));
              }}
            />
            <EntitiesWithAdminFilter
              adminIDs={filters.adminIDs}
              onChangeAdminIDs={(newAdminIDs) => {
                setFilters({
                  ...filters,
                  adminIDs: newAdminIDs,
                });
              }}
            />
            {showMoreFilters && (
              <>
                <EntityTypesFilter
                  resourceTypes={filters.resourceTypes}
                  groupTypes={filters.groupTypes}
                  onChangeResourceTypes={(newTypes) => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      resourceTypes: newTypes,
                    }));
                  }}
                  onChangeGroupTypes={(newTypes) => {
                    setFilters((prevFilters) => ({
                      ...prevFilters,
                      groupTypes: newTypes,
                    }));
                  }}
                />
                <TagFilter
                  tags={filters.tags}
                  onChangeTags={(newTags) => {
                    setFilters({
                      ...filters,
                      tags: newTags,
                    });
                  }}
                />
                <EntitiesNamedFilter
                  names={filters.names}
                  onChangeNames={(newNames) => {
                    setFilters({
                      ...filters,
                      names: newNames,
                    });
                  }}
                />
              </>
            )}
            <Button
              label={
                showMoreFilters
                  ? "Hide additional filters"
                  : "Show additional filters"
              }
              borderless
              size="sm"
              onClick={() => setShowMoreFilters((prev) => !prev)}
            />
          </Card>
          <Card title="Notifications">
            <ReminderNotifications
              reminderSchedule={reminderSchedule}
              reminderTimeOfDay={reminderTimeOfDay}
              reminderIncludeManager={reminderIncludeManager}
              timezone={timeZone}
              onChange={(
                thisReminderSchedule,
                thisReminderTimeOfDay,
                thisReminderIncludeManager
              ) => {
                setReminderSchedule(thisReminderSchedule);
                setReminderTimeOfDay(thisReminderTimeOfDay);
                setReminderIncludeManager(thisReminderIncludeManager);
              }}
            />
            <FormGroup
              label={`New review notifications: ${
                sendReviewerAssignmentNotifications ? "On" : "Off"
              }`}
            >
              <Switch
                label="Send a notification to reviewers when they're assigned a new review."
                checked={sendReviewerAssignmentNotifications}
                onChange={(checked) =>
                  setSendReviewerAssignmentNotifications(checked)
                }
                rightAlign
              />
            </FormGroup>
          </Card>
          <Card title="Reviews">
            <AssignmentPolicy
              assignmentPolicy={assignmentPolicy}
              onChange={setAssignmentPolicy}
            />
            <FormGroup
              label={`Self-review: ${
                selfReviewAllowed ? "Allowed" : "Not Allowed"
              }`}
            >
              <Switch
                label="Allow self reviews. Self reviewed items will be noted in the review results report."
                checked={selfReviewAllowed}
                onChange={(checked) => setSelfReviewAllowed(checked)}
                rightAlign
              />
            </FormGroup>
          </Card>
        </div>
      </ColumnContent>
    </Column>
  );
};

export default CreateAccessReviewColumn;
