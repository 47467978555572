import { useState } from "react";
import { useTransitionBack } from "utils/router/hooks";

import ConfirmModal from "./ConfirmModal";

type UnsavedChangesModalProps = {
  isModalOpen: boolean;
  // Called when the user decides to continue editing
  onCancel: () => void;
  // Called when the users decides to discard the unsaved changes
  onDiscardChanges: () => void;
};

const UnsavedChangesModal = (props: UnsavedChangesModalProps) => {
  return (
    <ConfirmModal
      isModalOpen={props.isModalOpen}
      onClose={props.onCancel}
      onSubmit={props.onDiscardChanges}
      title="Unsaved Changes"
      message="Are you sure you want to discard these unsaved changes?"
      submitLabel="Discard Changes"
    />
  );
};

export const useUnsavedChangesModal = (urlToReturnTo: string) => {
  const [
    isUnsavedChangesModalShown,
    setIsUnsavedChangesModalShown,
  ] = useState<boolean>(false);

  const transitionBack = useTransitionBack();

  const returnToParentPage = () => {
    transitionBack(urlToReturnTo);
  };

  const handleClose = (hasUnsavedChanges: boolean) => {
    if (hasUnsavedChanges) {
      setIsUnsavedChangesModalShown(true);
    } else {
      returnToParentPage();
    }
  };

  const handleHideUnsavedChangesModal = () => {
    setIsUnsavedChangesModalShown(false);
  };

  const maybeRenderUnsavedChangesModal = () => {
    return (
      isUnsavedChangesModalShown && (
        <UnsavedChangesModal
          isModalOpen={isUnsavedChangesModalShown}
          onCancel={handleHideUnsavedChangesModal}
          onDiscardChanges={returnToParentPage}
        />
      )
    );
  };

  return {
    handleClose,
    maybeRenderUnsavedChangesModal,
  };
};
