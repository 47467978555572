import { useParams } from "react-router";
import ConnectionUsersRow from "views/connections/viewer/rows/ConnectionUsersRow";

import AppHeader from "./AppHeader";
import AppsContentColumn from "./AppsContentColumn";

const AppUsersColumn = () => {
  const { appId } = useParams<Record<string, string>>();

  return (
    <AppsContentColumn>
      <AppHeader />
      <ConnectionUsersRow connectionId={appId} />
    </AppsContentColumn>
  );
};

export default AppUsersColumn;
