import { EntityType, GroupFragment } from "api/generated/graphql";
import ColumnHeader from "components/column/ColumnHeader";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import RequestModal from "components/modals/RequestModal";
import { Divider } from "components/ui";
import sprinkles from "css/sprinkles.css";

import AppsContentColumn from "./AppsContentColumn";
import { GroupViewKey } from "./utils";

interface Props {
  group: GroupFragment;
  onNavigate: (groupViewKey: GroupViewKey) => void;
}

const GroupRequestColumn = (props: Props) => {
  return (
    <AppsContentColumn maxWidth="md">
      <ColumnHeader
        title={`Request access to ${props.group.name}`}
        subtitle={getGroupTypeInfo(props.group.groupType)?.name}
        icon={{ type: "name", icon: "lock" }}
        onClose={() => props.onNavigate("overview")}
      />
      <Divider margin="md" />
      <div className={sprinkles({ padding: "md" })}>
        <RequestModal
          entity={props.group}
          isImpersonationResource={false}
          isGlobalImpersonationResource={false}
          entityType={EntityType.Group}
          isModalOpen={true}
          onClose={() => {}}
          contentOnly
        />
      </div>
    </AppsContentColumn>
  );
};

export default GroupRequestColumn;
