import { useContext } from "react";

import { AppsContext } from "./AppsContext";
import BulkEditColumn from "./BulkEditColumn";
import BulkRequestColumn from "./BulkRequestColumn";

const BulkActionColumn = () => {
  const { bulkMode } = useContext(AppsContext);

  if (bulkMode === "edit") {
    return <BulkEditColumn />;
  }
  if (bulkMode === "request") {
    return <BulkRequestColumn />;
  }
  return null;
};

export default BulkActionColumn;
