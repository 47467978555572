import {
  AppCategory,
  ConnectionType,
  useConnectionsQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import { TabsV3 } from "components/ui";
import { ProgressStages } from "components/ui/progress_stages/ProgressStages";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import { useHistory } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useHasInventory } from "utils/hooks";
import { logError } from "utils/logging";
import {
  BrowseServicesListV3,
  categoryInfoByCategory,
  ConnectionInfo,
  CONNECTIONS_LIST,
} from "views/connections/create/BrowseServices";
import { ForbiddenPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";

import * as styles from "./AppAddIntegrationView.css";

const AppAddIntegrationView = () => {
  const history = useHistory();
  const { authState } = useContext(AuthContext);
  const hasVault = useFeatureFlag(FeatureFlag.Vault);
  const hasInventory = useHasInventory();

  const hasAD = useFeatureFlag(FeatureFlag.ActiveDirectory);
  const hasAWSLegacy = useFeatureFlag(FeatureFlag.AWSLegacy);
  const hasSnowflake = useFeatureFlag(
    FeatureFlag.EnableSnowflakeConnectionType
  );
  const hasWorkday = useFeatureFlag(FeatureFlag.EnableWorkdayConnectionType);

  const { data, error, loading } = useConnectionsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {},
    },
  });

  const connections = data?.connections.connections ?? [];

  if (!authState.user?.isAdmin) {
    return <ForbiddenPage />;
  }
  if (loading) {
    return <FullscreenSkeleton />;
  }
  if (error) {
    logError(error, `failed to list connections`);
    return <UnexpectedErrorPage error={error} />;
  }

  const selectedCategory = decodeURI(location.hash.slice(1)) || "All";
  const tabInfos: PropsFor<typeof TabsV3>["tabInfos"] = Object.values(
    categoryInfoByCategory
  )
    .filter((info) => info.category != AppCategory.OktaApp)
    .map((info) => ({
      title: info.name,
      onClick: () => history.push({ hash: info.name }),
      isSelected: selectedCategory === info.name,
    }));

  let filteredServices = CONNECTIONS_LIST;
  if (selectedCategory !== "All") {
    filteredServices = filteredServices.filter(
      (app) => app.category.name === selectedCategory
    );
  }
  filteredServices = filteredServices.sort((a, b) =>
    a.name.toLowerCase().localeCompare(b.name.toLowerCase())
  );
  filteredServices = filterServices(filteredServices, {
    excludeVault: !hasVault,
    excludeAD: !hasAD,
    excludeAWSLegacy: !hasAWSLegacy,
    excludeSnowflake: !hasSnowflake,
    excludeWorkday: !hasWorkday,
  });

  const topSection = (
    <div
      className={sprinkles({
        display: "flex",
        justifyContent: "center",
        height: "fit-content",
        paddingTop: "md",
        paddingBottom: "lg",
      })}
    >
      <ProgressStages
        stages={[
          {
            label: "Select App",
            state: "active",
          },
          {
            label: "Connect App",
            state: "uncompleted",
          },
        ]}
      />
    </div>
  );

  return (
    <FullscreenView
      title="Add App"
      onCancel={() => history.push(hasInventory ? "/inventory" : "/apps")}
      primaryButtonHidden
      topSection={topSection}
      topSectionBorder={false}
    >
      <FullscreenView.Content fullWidth>
        <div className={styles.tabsContainer}>
          <TabsV3 tabInfos={tabInfos} />
        </div>
        <BrowseServicesListV3
          services={filteredServices}
          connections={connections}
        />
      </FullscreenView.Content>
    </FullscreenView>
  );
};

interface FilterServicesOptions {
  excludeVault: boolean;
  excludeAD: boolean;
  excludeAWSLegacy: boolean;
  excludeSnowflake: boolean;
  excludeWorkday: boolean;
}

export const filterServices = (
  apps: ConnectionInfo[],
  options: FilterServicesOptions
): ConnectionInfo[] => {
  const servicesToExclude: ConnectionType[] = [];
  if (options.excludeVault) {
    servicesToExclude.push(
      ConnectionType.Mongo,
      ConnectionType.MongoAtlas,
      ConnectionType.Mysql,
      ConnectionType.Mariadb,
      ConnectionType.Postgres
    );
  }
  if (options.excludeAD) {
    servicesToExclude.push(ConnectionType.ActiveDirectory);
  }
  if (options.excludeAWSLegacy) {
    servicesToExclude.push(ConnectionType.Aws);
  }
  if (options.excludeSnowflake) {
    servicesToExclude.push(ConnectionType.Snowflake);
  }
  if (options.excludeWorkday) {
    servicesToExclude.push(ConnectionType.Workday);
  }
  return apps.filter((app) => !servicesToExclude.includes(app.connectionType));
};

export default AppAddIntegrationView;
