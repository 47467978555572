import { EntityType, OwnerFragment } from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Editor, EntityViewerRow } from "components/entity_viewer/EntityViewer";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import { useContext, useState } from "react";

import OwnerEditUsersModal from "./owner_viewer/OwnerEditUsersModal";
import OwnerUsersTable from "./owner_viewer/OwnerUsersTable";

interface Props {
  owner: OwnerFragment;
}

const OwnerUsersColumn = (props: Props) => {
  const { owner } = props;

  const { authState } = useContext(AuthContext);
  const [showEditUsersModal, setShowEditUsersModal] = useState(false);

  const ownerUsers = [...owner.ownerUsers];
  ownerUsers.sort((a, b) => a.reviewerPriority - b.reviewerPriority);
  const orderedOwnerUsers = ownerUsers.flatMap((ou) => {
    if (!ou.user) return [];
    return [
      {
        id: ou.user.id,
        fullName: ou.user.fullName,
        avatarUrl: ou.user.avatarUrl,
        position: ou.user.position,
        hrIdpStatus: ou.user.hrIdpStatus,
      },
    ];
  });
  // if we have a source group and we don't have an escalation path, then it's
  // pointless to edit the user list
  const ownerUsersEditable =
    authState.user?.isAdmin &&
    (!owner.sourceGroup || owner.accessRequestEscalationPeriodInMinutes);

  return (
    <EntityViewerRow
      title="Users"
      content={
        <EmptyStateContentWrapper
          content={
            <OwnerUsersTable
              users={orderedOwnerUsers}
              hasEscalation={Boolean(
                owner.accessRequestEscalationPeriodInMinutes
              )}
            />
          }
          entityType={EntityType.User}
          title="No users for owner"
          isEmpty={orderedOwnerUsers.length === 0}
        />
      }
      editor={
        ownerUsersEditable ? (
          <Editor
            menuOptions={[
              {
                label: "Edit",
                handler: () => {
                  setShowEditUsersModal(true);
                },
              },
            ]}
          />
        ) : null
      }
      isTable
      modals={
        <OwnerEditUsersModal
          hasEscalation={Boolean(owner.accessRequestEscalationPeriodInMinutes)}
          hasSourceGroup={Boolean(owner.sourceGroup)}
          ownerId={owner.id}
          users={orderedOwnerUsers}
          isOpen={showEditUsersModal}
          onClose={() => setShowEditUsersModal(false)}
        />
      }
    />
  );
};

export default OwnerUsersColumn;
