import { ConnectionType } from "api/generated/graphql";

import { brandIconSet } from "./brandIconSet";
import { brandSquareIconSet } from "./brandSquareIconSet";

export const getBrandIconNameFromConnectionType = (
  connectionType: ConnectionType
): keyof typeof brandIconSet => {
  switch (connectionType) {
    case ConnectionType.ActiveDirectory:
      return "brand-active-directory";
    case ConnectionType.Aws:
      return "brand-aws";
    case ConnectionType.AwsSso:
      return "brand-awsiam";
    case ConnectionType.AzureAd:
      return "brand-azure";
    case ConnectionType.Custom:
      return "brand-customapp";
    case ConnectionType.CustomConnector:
      return "brand-customconnection";
    case ConnectionType.Duo:
      return "brand-duo";
    case ConnectionType.Gcp:
      return "brand-gcp";
    case ConnectionType.GitHub:
      return "brand-github";
    case ConnectionType.GitLab:
      return "brand-gitlab";
    case ConnectionType.GoogleGroups:
      return "brand-googlegroups";
    case ConnectionType.GoogleWorkspace:
      return "brand-google";
    case ConnectionType.Ldap:
      return "brand-ldap";
    case ConnectionType.Mariadb:
      return "brand-mariadb";
    case ConnectionType.Mongo:
      return "brand-mongo";
    case ConnectionType.MongoAtlas:
      return "brand-mongo";
    case ConnectionType.Mysql:
      return "brand-mysql";
    case ConnectionType.OktaDirectory:
      return "brand-okta";
    case ConnectionType.Opal:
      return "brand-opal";
    case ConnectionType.Pagerduty:
      return "brand-pagerduty";
    case ConnectionType.Salesforce:
      return "brand-salesforce";
    case ConnectionType.Snowflake:
      return "brand-snowflake";
    case ConnectionType.Tailscale:
      return "brand-tailscale";
    case ConnectionType.Teleport:
      return "brand-teleport";
    case ConnectionType.Workday:
      return "brand-workday";
    default:
      return "brand-selfhosted";
  }
};

export const getBrandSquareIconNameFromConnectionType = (
  connectionType: ConnectionType
): keyof typeof brandSquareIconSet | undefined => {
  switch (connectionType) {
    case ConnectionType.ActiveDirectory:
      return "brand-square-active-directory";
    case ConnectionType.Aws:
      return "brand-square-aws";
    case ConnectionType.AwsSso:
      return "brand-square-awsiam";
    case ConnectionType.AzureAd:
      return "brand-square-azure";
    case ConnectionType.Custom:
      return "brand-square-customapp";
    case ConnectionType.CustomConnector:
      return "brand-square-customconnection";
    case ConnectionType.Duo:
      return "brand-square-duo";
    case ConnectionType.Gcp:
      return "brand-square-gcp";
    case ConnectionType.GitHub:
      return "brand-square-github";
    case ConnectionType.GitLab:
      return "brand-square-gitlab";
    case ConnectionType.GoogleGroups:
      return "brand-square-googlegroups";
    case ConnectionType.GoogleWorkspace:
      return "brand-square-google";
    case ConnectionType.Ldap:
      return "brand-square-ldap";
    case ConnectionType.Mariadb:
      return "brand-square-mariadb";
    case ConnectionType.Mongo:
      return "brand-square-mongo";
    case ConnectionType.MongoAtlas:
      return "brand-square-mongo";
    case ConnectionType.Mysql:
      return "brand-square-mysql";
    case ConnectionType.OktaDirectory:
      return "brand-square-okta";
    case ConnectionType.Opal:
      return "brand-square-opal";
    case ConnectionType.Pagerduty:
      return "brand-square-pagerduty";
    case ConnectionType.Salesforce:
      return "brand-square-salesforce";
    case ConnectionType.Snowflake:
      return "brand-square-snowflake";
    case ConnectionType.Tailscale:
      return "brand-square-tailscale";
    case ConnectionType.Teleport:
      return "brand-square-teleport";
    case ConnectionType.Workday:
      return "brand-square-workday";
    default:
      return undefined;
  }
};
