import * as React from "react";
import { useHistory, useLocation } from "react-router";

export function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export interface Location {
  pathname?: string;
  search?: string;
  hash?: string;
}
interface TransitionOptions {
  preserveQueries?: string[];
  preserveAllQueries?: boolean;
}

export function useTransitionTo(options?: TransitionOptions) {
  const history = useHistory();
  const location = useLocation();

  const handleTransitionEvent = (
    location: Location,
    event?: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (event?.ctrlKey || event?.metaKey) {
      const url =
        (location.pathname || "") +
        (location.search ? "?" + location.search : "") +
        (location.hash ? "#" + location.hash : "");
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    } else {
      history.push(location);
    }
  };

  const transitionTo = (
    newLocation: Location,
    event?: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    let newSearch = newLocation.search;
    const searchParams = new URLSearchParams(location.search);

    if (options?.preserveAllQueries) {
      handleTransitionEvent(
        {
          ...newLocation,
          search: searchParams.toString(),
        },
        event
      );
      return;
    }

    options?.preserveQueries?.forEach((queryKey) => {
      const queryValue = searchParams.get(queryKey);
      if (queryValue) {
        const newSearchParams = new URLSearchParams(newSearch);
        newSearchParams.set(queryKey, queryValue);
        newSearch = newSearchParams.toString();
      }
    });

    handleTransitionEvent(
      {
        ...newLocation,
        search: newSearch,
      },
      event
    );
  };

  return transitionTo;
}

export function useTransitionBack() {
  const history = useHistory();
  const location = useLocation();

  const transitionBack = (fallbackURL: string) => {
    // If location.key is defined, then we navigated to
    // the current page page from somewhere in the app
    if (location.key && location.key !== "default") {
      history.goBack();
    } else {
      history.push(fallbackURL);
    }
  };

  return transitionBack;
}

export function useReplaceTo(options?: TransitionOptions) {
  const history = useHistory();
  const location = useLocation();

  const replaceTo = (newLocation: Location) => {
    let newSearch = newLocation.search;
    if (options?.preserveAllQueries) {
      const currentQueryParams = new URLSearchParams(location.search);
      const newQueryParams = new URLSearchParams(newSearch);
      currentQueryParams.forEach((value, key) => {
        // make sure to not overwrite the new keys
        if (!newQueryParams.has(key)) newQueryParams.set(key, value);
      });
      newSearch = newQueryParams.toString();
    } else {
      options?.preserveQueries?.forEach((queryKey) => {
        const queryValue = new URLSearchParams(location.search).get(queryKey);
        if (queryValue) {
          const newSearchParams = new URLSearchParams(newSearch);
          newSearchParams.set(queryKey, queryValue);
          newSearch = newSearchParams.toString();
        }
      });
    }

    history.replace({
      ...newLocation,
      search: newSearch,
    });
  };

  return replaceTo;
}

export type SearchParamValue = string | null;
export function useURLSearchParam(
  searchParamKey: string,
  defaultValue?: string,
  pushInsteadOfReplace?: boolean
): [SearchParamValue, (newValue: SearchParamValue) => void] {
  const location = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(location.search);
  const searchParamValue = searchParams.get(searchParamKey);

  return [
    searchParamValue || defaultValue || null,
    (newValue: SearchParamValue) => {
      const newSearchParams = new URLSearchParams(location.search);
      if (newValue == null) {
        newSearchParams.delete(searchParamKey);
      } else {
        newSearchParams.set(searchParamKey, newValue);
      }
      const destination = {
        ...location,
        search: newSearchParams.toString(),
      };
      if (pushInsteadOfReplace) {
        history.push(destination);
      }
      history.replace(destination);
    },
  ];
}

export function useBooleanURLSearchParam(
  searchParamKey: string
): [boolean, (newValue: boolean) => void] {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);
  const searchParamValue = searchParams.get(searchParamKey);
  return [
    searchParamValue === "true",
    (newValue: boolean) => {
      const newSearchParams = new URLSearchParams(location.search);
      if (newValue) {
        newSearchParams.set(searchParamKey, newValue.toString());
      } else {
        newSearchParams.delete(searchParamKey);
      }
      history.replace({
        ...location,
        search: newSearchParams.toString(),
      });
    },
  ];
}

// Inspired from https://stackoverflow.com/questions/17380845/how-do-i-convert-a-string-to-enum-in-typescript/41548441#41548441
function enumFromStringValue<T>(
  enm: { [s: string]: T },
  value: string | null,
  defaultVal: T
): T {
  if (value === null) {
    return defaultVal;
  }
  // The ternary check is needed to check if the provided value is a valid value of the enum
  return ((Object.values(enm) as unknown) as string[]).includes(value)
    ? ((value as unknown) as T)
    : defaultVal;
}

// useURLSearchParamAsEnum hook limits the Search Params to enum
// When newValue is null, it removes the searchParamKey from the search
export function useURLSearchParamAsEnum<T>(
  searchParamKey: string,
  enm: { [s: string]: T },
  defaultValue: T
): [T, (newValue: T) => void] {
  const location = useLocation();
  const history = useHistory();

  const searchParams = new URLSearchParams(location.search);
  const searchParamValue = searchParams.get(searchParamKey);
  const enumValue = enumFromStringValue(enm, searchParamValue, defaultValue);
  return [
    enumValue,
    (newValue: T) => {
      const newSearchParams = new URLSearchParams(location.search);
      if (newValue != null) {
        newSearchParams.set(searchParamKey, newValue.toString());
      } else {
        newSearchParams.delete(searchParamKey);
      }
      history.replace({
        ...location,
        search: newSearchParams.toString(),
      });
    },
  ];
}
