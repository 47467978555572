import { MoreInfo } from "components/more_info/MoreInfo";
import { LockTooltip } from "components/tooltips/LockTooltip";
import sprinkles from "css/sprinkles.css";
import React from "react";

import * as styles from "./Switch.css";

type SwitchProps = {
  label?: string;
  checked: boolean;
  onChange?: (checked: boolean) => void;
  disabled?: boolean;
  infoTooltip?: string;
  rightAlign?: boolean;
};

const Switch: React.FC<SwitchProps> = (props) => {
  const { disabled = false, onChange, checked, rightAlign } = props;

  const handleClick =
    !disabled && onChange ? () => onChange(!props.checked) : undefined;

  const switchButton = (
    <button
      className={styles.switchTrack({ checked, disabled })}
      onClick={handleClick}
    >
      <div className={styles.switchHandle({ checked })} />
    </button>
  );

  const label = (
    <div
      className={
        props.label && props.label !== ""
          ? styles.label({ disabled, rightAlign })
          : undefined
      }
      onClick={handleClick}
    >
      {props.label}
    </div>
  );

  const tooltipIcon = props.infoTooltip ? (
    props.disabled ? (
      <div className={sprinkles({ marginRight: "sm" })}>
        <LockTooltip tooltipText={props.infoTooltip} />
      </div>
    ) : (
      <MoreInfo tooltipText={props.infoTooltip} />
    )
  ) : null;

  if (props.rightAlign) {
    return (
      <div className={sprinkles({ display: "flex" })}>
        {label}
        {tooltipIcon}
        {switchButton}
      </div>
    );
  }

  return (
    <div className={sprinkles({ display: "flex" })}>
      {switchButton}
      {label}
      {tooltipIcon}
    </div>
  );
};

export default Switch;
