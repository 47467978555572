import {
  AccessOption,
  useBulkUpdateMutation,
  Visibility,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import { ResourceConfig } from "components/forms/common";
import ResourcesConfigForm from "components/forms/ResourcesConfigForm";
import {
  makeDefaultRequestConfig,
  makeRequestConfigsInput,
  validateResourceConfig,
} from "components/forms/utils";
import { Banner, Button, DataElement, Divider } from "components/ui";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { useLocation } from "react-router";
import useLogEvent from "utils/analytics";
import { logError } from "utils/logging";
import useSyncStatusToast from "utils/sync/useSyncStatusToast";
import OrgContext from "views/settings/OrgContext";

import { APP_VIEWS } from "./AppDetailContent";
import { AppsContext, SelectedUnmanagedItem } from "./AppsContext";
import * as styles from "./BulkEditColumn.css";
import { useAccessOptionKey } from "./utils";

const MAX_INITIAL_ITEMS = 10;

const itemEntityType = (item: SelectedUnmanagedItem) => {
  if ("resourceType" in item) {
    return item.resourceType;
  } else {
    return item.groupType;
  }
};

const BulkImportColumnV2 = () => {
  const {
    selectedUnmanagedItems,
    toggleUnmanagedItem,
    clearSelectedItems,
  } = useContext(AppsContext);
  const { orgState } = useContext(OrgContext);
  const location = useLocation();
  const [, setAccessOptionKey] = useAccessOptionKey();
  const [showAll, setShowAll] = useState(false);

  const [config, setConfig] = useState<Partial<ResourceConfig>>({
    description: "",
    visibility: Visibility.Global,
    messageChannels: undefined,
    onCallSchedules: undefined,
    breakGlassUsers: undefined,
    requireMfaToApprove: false,
    requireMfaToConnect: false,
    tagIds: undefined,
    requestConfigs: [makeDefaultRequestConfig()],
    childrenDefaultConfigTemplate: undefined,
  });
  const [errors, setErrors] = useState<string[]>([]);
  const logEvent = useLogEvent();

  const showSyncStatusToast = useSyncStatusToast({
    loadingText: "Successfully imported, now syncing...",
    queriesToRefetch: [
      "ItemsListSection",
      "ItemsListSectionImport",
      "Group",
      "Resource",
    ],
  });
  const [bulkUpdate, { loading }] = useBulkUpdateMutation({
    refetchQueries: ["AppsListColumn"],
  });

  const handleSave = async () => {
    const errors = validateResourceConfig(config, orgState);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    const resourceIds: string[] = [];
    const groupIds: string[] = [];

    const itemTypes = new Set<string>();
    selectedUnmanagedItems.forEach((item) => {
      if ("resourceType" in item) {
        resourceIds.push(item.id);
        itemTypes.add(item.resourceType);
      } else {
        groupIds.push(item.id);
        itemTypes.add(item.groupType);
      }
    });

    logEvent({
      name: "apps_import_items_click",
      properties: {
        numItems: selectedUnmanagedItems.length,
        itemTypes: Array.from(itemTypes),
      },
    });

    try {
      const input = {
        resourceIds,
        groupIds,
        importUnmanagedItems: true,
        commonMetadata: config.commonMetadata,
        description: config.description,
        adminOwnerId: config.adminOwner?.id ?? undefined,
        configurationId: config.configurationTemplate
          ? {
              configurationId: config.configurationTemplate?.id,
            }
          : undefined,
        visibility: config.visibility,
        visibilityGroupsIds: config.visibilityGroups,
        messageChannelIds: config.messageChannels?.map((channel) => channel.id),

        requireMfaToApprove: config.requireMfaToApprove,
        requireMfaToConnect: config.requireMfaToConnect,

        breakGlassUsersIds: config.breakGlassUsers?.map((user) => user.id),
        onCallSchedules: config.onCallSchedules?.map((schedule) => ({
          scheduleName: schedule.name,
          remoteId: schedule.remoteId,
          thirdPartyProvider: schedule.thirdPartyProvider,
        })),
        tagIds: config.tagIds,
        requestConfigs: makeRequestConfigsInput(config.requestConfigs ?? []),
        childrenDefaultConfigTemplateId: config.childrenDefaultConfigTemplate
          ?.id
          ? {
              configurationId: config.childrenDefaultConfigTemplate.id,
            }
          : undefined,
      };
      const { data } = await bulkUpdate({
        variables: {
          input,
        },
      });
      switch (data?.bulkUpdateItems.__typename) {
        case "BulkUpdateItemsResult":
          if (data.bulkUpdateItems.syncTask) {
            showSyncStatusToast(data.bulkUpdateItems.syncTask.id);
          }
          setAccessOptionKey(AccessOption.All);
          clearSelectedItems();
          break;
        case "TagNotFoundError":
          setErrors(["Failed to get tag data"]);
          break;
        case "InvalidUpdateResourceVisibilityGroupError":
        case "InvalidUpdateGroupVisibilityGroupError":
          setErrors(["Invalid visibility group"]);
          break;
        case "InvalidReviewerSettingsError":
          setErrors(["Invalid reviewer stage configuration"]);
          break;
        case "GroupMaxDurationTooLargeError":
        case "ResourceMaxDurationTooLargeError":
          setErrors([
            "Max access duration cannot exceed org-wide max duration setting",
          ]);
          break;
        case "CannotUpdateConfigurationTemplateError":
          setErrors(["Cannot update configuration template"]);
          break;
        default:
          setErrors(["Failed to bulk import items"]);
          break;
      }
    } catch (err) {
      logError(err, "Failed to bulk import items");
    }
  };

  const currentAppView = APP_VIEWS.find((appView) =>
    location.pathname.endsWith(appView.key)
  );

  if (currentAppView) {
    return null;
  }

  const actionButtons = (
    <div className={sprinkles({ display: "flex", gap: "sm" })}>
      <Button label="Cancel" borderless onClick={() => clearSelectedItems()} />
      <Button
        label={loading ? "Adding..." : "Add to Opal"}
        leftIconName="plus"
        type="primary"
        onClick={handleSave}
        disabled={loading}
      />
    </div>
  );

  const items = showAll
    ? selectedUnmanagedItems
    : selectedUnmanagedItems.slice(0, MAX_INITIAL_ITEMS);

  return (
    <Column isContent maxWidth="lg">
      <ColumnHeader
        title={`Add ${selectedUnmanagedItems.length} ${pluralize(
          "resource",
          selectedUnmanagedItems.length
        )} to Opal`}
        icon={{ type: "name", icon: "plus" }}
        rightActions={actionButtons}
      />
      <Divider margin="md" />
      <ColumnContent>
        <div className={styles.entityListContainer}>
          {items.map((item) => {
            return (
              <DataElement
                key={item.id}
                label={item.name}
                color="blue"
                onClick={() => toggleUnmanagedItem(item)}
                rightIcon={{ name: "x" }}
                leftIcon={{
                  data: { type: "entity", entityType: itemEntityType(item) },
                }}
              />
            );
          })}
          {!showAll && selectedUnmanagedItems.length > MAX_INITIAL_ITEMS && (
            <DataElement
              key="show-all"
              label={`Show all ${selectedUnmanagedItems.length} items`}
              leftIcon={{ name: "plus" }}
              onClick={() => setShowAll(true)}
              color="gray"
            />
          )}
          {showAll && (
            <DataElement
              key="show-all"
              label={`Collapse items`}
              leftIcon={{ name: "minus" }}
              onClick={() => setShowAll(false)}
              color="gray"
            />
          )}
        </div>
        <Divider margin="md" />
        {errors.map((error) => (
          <Banner message={error} type="error" />
        ))}
        <ResourcesConfigForm config={config} onChange={setConfig} mode="edit" />
      </ColumnContent>
    </Column>
  );
};

export default BulkImportColumnV2;
