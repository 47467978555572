import { getModifiedErrorMessage } from "api/ApiContext";
import { openInNewTab } from "api/common/common";
import {
  ConnectionType,
  EntityType,
  GeneralSettingType,
  Maybe,
  OidcProviderType,
  ResourceAccessLevel,
  ResourceFragment,
  ResourceType,
  ServiceType,
  useAssumeImpersonationMutation,
  useCreateSessionMutation,
  useInitOidcAuthFlowMutation,
} from "api/generated/graphql";
import axios from "axios";
import AuthContext from "components/auth/AuthContext";
import { opalConfetti } from "components/confetti/Confetti";
import { TooltipPlacement } from "components/label/Label";
import { ResourceExpirationLabel } from "components/label/ResourceExpirationLabel";
import { getServiceTypeInfo } from "components/label/ServiceTypeLabel";
import { IdpMfaModal } from "components/modals/IdpMfaModal";
import { RoleModal } from "components/modals/RoleModal";
import SessionDetailsModal from "components/modals/SessionDetailsModal";
import { PendingRequestDisplay } from "components/requests/PendingRequestDisplay";
import { PendingRequestMenuOption } from "components/requests/PendingRequestMenuOption";
import { useToast } from "components/toast/Toast";
import { ButtonV3, Icon, Tooltip } from "components/ui";
import ContextMenu from "components/ui/context_menu/ContextMenu";
import { makeURLForEntityViz } from "components/viz/contexts/FilterContext";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import moment from "moment";
import React, {
  Dispatch,
  SetStateAction,
  useContext,
  useRef,
  useState,
} from "react";
import { useHistory, useLocation } from "react-router-dom";
import useLogEvent from "utils/analytics";
import { AuthorizedActionExport, generateState } from "utils/auth/auth";
import {
  DEFAULT_SESSION_LENGTH_MINUTES,
  IMPERSONATION_SESSION_LENGTH_MINUTES,
  OPAL_GLOBAL_IMPERSONATION_REMOTE_ID,
  OPAL_IMPERSONATION_REMOTE_ID,
  OPAL_PROD_GLOBAL_IMPERSONATION_REMOTE_ID,
} from "utils/constants";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";
import { clearMfaData, getMfaParams, MfaCustomParams } from "utils/mfa/mfa";
import {
  clearOidcData,
  getOidcData,
  OidcPostAuthAction,
  setOidcData,
} from "utils/oidc/oidc";
import { getResourcePendingRequestRoleRemoteIds } from "utils/resources";
import {
  CurrentUserResourceAccessStatus,
  currentUserResourceAccessStatusFromResource,
  getSessionWithExpiration,
  SessionWithExpiration,
  useConnectTransition,
} from "views/connect_sessions/utils";
import { ForfeitResourceButton } from "views/resources/ForfeitResourceButton";
import { ForfeitResourceMenuOption } from "views/resources/ForfeitResourceMenuOption";
import OrgContext from "views/settings/OrgContext";

import { AppsContext } from "./AppsContext";
import { ResourceViewKey } from "./utils";

type MenuOptions = PropsFor<typeof ContextMenu>["options"];

function getExpirationText(currentSessions: SessionWithExpiration[]) {
  if (currentSessions.length === 1) {
    const currentSession = currentSessions[0];
    if (currentSession.isExpired) {
      return "Expired";
    }
    const { expirationHours, expirationMinutes } = currentSession;
    if (expirationHours === 0) {
      if (expirationMinutes >= 1) {
        return `Expires: ${expirationMinutes}m`;
      } else {
        return `Expires: <1m`;
      }
    }
    return `Expires: ${expirationHours}h ${expirationMinutes}m`;
  }
  if (currentSessions.length > 1) {
    return "Multiple Sessions";
  }
  return;
}

type ResourceActionButtonsV3Props = {
  resource: {
    id: string;
    name: string;
    resourceType: ResourceType;
    serviceType: ServiceType;
    remoteId: string;
    currentUserAccess: ResourceFragment["currentUserAccess"];
    connection?: ResourceFragment["connection"] | null;
    isRequestable: boolean;
    requireMfaToConnect: boolean;
    authorizedActions?: Array<string> | null;
  };
  resourceId: string;
  onNavigate: (resourceViewKey: ResourceViewKey) => void;
  refetchResource?: () => void;
  canManage: boolean | undefined;
  setShowDeleteResourceModal?: Dispatch<SetStateAction<boolean>>;
};

export const ResourceActionButtonsV3 = (
  props: ResourceActionButtonsV3Props
) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const hasEUX = useFeatureFlag(FeatureFlag.EndUserExperience);
  const { authState } = useContext(AuthContext);
  const { orgState } = useContext(OrgContext);
  const { bulkMode } = useContext(AppsContext);

  const history = useHistory();
  const location = useLocation();
  const {
    displaySuccessToast,
    displayErrorToast,
    displayCustomToast,
    displayLoadingToast,
  } = useToast();
  const logEvent = useLogEvent();

  const [assumeImpersonation] = useAssumeImpersonationMutation();

  const [showSessionDetailsModal, setShowSessionDetailsModal] = useState(false);
  const [showRoleModal, setShowRoleModal] = useState<boolean>(false);
  const [isIdpMfaModalOpen, setIsIdpMfaModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const transitionToConnect = useConnectTransition();

  const isImpersonationResource =
    props.resource.remoteId === OPAL_IMPERSONATION_REMOTE_ID;
  const isGlobalImpersonationResource =
    props.resource.remoteId === OPAL_GLOBAL_IMPERSONATION_REMOTE_ID;
  const isProdGlobalImpersonationResource =
    props.resource.remoteId === OPAL_PROD_GLOBAL_IMPERSONATION_REMOTE_ID;
  const isOktaApp = props.resource.resourceType === ResourceType.OktaApp;
  const isAdmin = authState.user?.isAdmin || authState.user?.isReadOnlyAdmin;

  const [selectedRole, setSelectedRole] = useState<ResourceAccessLevel>({
    accessLevelName: "",
    accessLevelRemoteId: "",
  });

  const [
    createSessionMutation,
    { loading: createSessionLoading },
  ] = useCreateSessionMutation();

  const [initOidcAuthFlow] = useInitOidcAuthFlowMutation();

  const queryParams = new URLSearchParams(location.search);
  // Our CLI sets the 'refresh=true' query param when it wants to force-create a new session.
  const pageForceNewSession = queryParams.get("refresh") === "true";
  useMountEffect(() => {
    if (hasEUX) return;
    if (
      props.resource.currentUserAccess.resourceUsers?.length == 1 &&
      !isImpersonationResource &&
      !isGlobalImpersonationResource &&
      !isProdGlobalImpersonationResource
    ) {
      const onlyRole =
        props.resource.currentUserAccess.resourceUsers[0].accessLevel;
      setSelectedRole(onlyRole);
    }
    if (queryParams.has("showModal")) {
      setShowRoleModal(true);
    }
    const performMfaActions = (mfaParams: MfaCustomParams) => {
      if (
        mfaParams &&
        mfaParams.resourceId === props.resource.id &&
        mfaParams.accessLevel
      ) {
        setSelectedRole(mfaParams.accessLevel);
        connectSessionAndDisplayDetails(
          mfaParams.accessLevel,
          mfaParams.forceNewSession
        );
        clearMfaData();
      } else if (
        mfaParams &&
        mfaParams.requestReason &&
        bulkMode !== "request"
      ) {
        props.onNavigate("request");
      }
    };
    if (queryParams.has("mfa")) {
      const mfaStatus = queryParams.get("mfa");
      if (mfaStatus === "success") {
        displaySuccessToast("MFA challenge completed.");
        const mfaParams = getMfaParams();
        if (mfaParams) {
          performMfaActions(mfaParams);
        }
      } else if (mfaStatus === "access_denied") {
        displayErrorToast(
          "Error: Please contact your Opal Admin to reset your MFA."
        );
      } else {
        displayErrorToast(
          "MFA challenge failed. Please try again or contact support if the issue remains."
        );
      }

      queryParams.delete("mfa");
      location.search = queryParams.toString();
    }
    if (queryParams.has("oidc_auth")) {
      const oidcAuthStatus = queryParams.get("oidc_auth");
      if (oidcAuthStatus === "success") {
        const oidcData = getOidcData();
        if (
          oidcData &&
          oidcData.params?.action === OidcPostAuthAction.StartSession &&
          oidcData.params.resourceId === props.resource.id &&
          oidcData.params.accessLevel
        ) {
          setSelectedRole(oidcData.params.accessLevel);
          setShowRoleModal(true);
          connectSessionAndDisplayDetails(
            oidcData.params.accessLevel,
            oidcData.params.forceNewSession
          );
          clearOidcData();
          // Clear oidc_auth parameter
          queryParams.delete("oidc_auth");
          history.replace({
            search: queryParams.toString(),
          });
        } else if (oidcData?.mfaParams) {
          performMfaActions(oidcData.mfaParams);
          clearOidcData();
          queryParams.delete("oidc_auth");
          history.replace({
            search: queryParams.toString(),
          });
        }
      } else {
        displayErrorToast(
          "Authentication failed. Please try again or contact support if the issue remains."
        );
      }
    }
  });

  // Re-render component every 30s
  const [, setCurrentTime] = useState<moment.Moment>(moment());
  useMountEffect(() => {
    const interval = setInterval(() => setCurrentTime(moment()), 30_000);
    return () => clearInterval(interval);
  });

  // Set of ids of the sessions that have expired and we have already sent out a toast for
  const notifiedExpiredSessions = useRef(new Set());

  const allSessions = props.resource.currentUserAccess.activeSessions.map(
    getSessionWithExpiration
  );

  // Remove notified ids of any sessions that we no longer have information on
  for (const notifiedSessId of Array.from(notifiedExpiredSessions.current)) {
    if (!allSessions.some((sess) => sess.session.id === notifiedSessId))
      notifiedExpiredSessions.current.delete(notifiedSessId);
  }

  // Send notification for expired sessions
  for (const session of allSessions) {
    if (
      session.isExpired &&
      !notifiedExpiredSessions.current.has(session.session.id)
    ) {
      let message;
      const role = session.session.accessLevel;
      if (role?.trim()) {
        message = `Your session for "${role}" access has expired.`;
      } else {
        message = "Your session has expired.";
      }
      displayCustomToast(message, "alert-triangle", false, true);
      notifiedExpiredSessions.current.add(session.session.id);
    }
  }

  // Hide expired sessions
  const currentSessions = allSessions.filter((s) => !s.isExpired);

  // TODO: this really shouldn't be "most recent". We should change the UI to support all of them
  const mostRecentUserAccess = _.maxBy(
    props.resource.currentUserAccess.resourceUsers.map(
      (resourceUser) => resourceUser.access,
      "createdAt"
    )
  );

  const hasForfeitableRole = props.resource.currentUserAccess.resourceUsers.some(
    (resourceUser) => resourceUser.access?.directAccessPoint
  );

  const mostRecentUserAccessStatus = currentUserResourceAccessStatusFromResource(
    props.resource,
    currentSessions.map((s) => s.session)
  );

  const getCurrentSession = (remoteId: string) => {
    for (const session of currentSessions) {
      // The current session must not have expired
      if (
        session.session.accessLevelRemoteId === remoteId &&
        !session.isExpired
      ) {
        return session;
      }
    }
    return null;
  };

  const session = getCurrentSession(selectedRole?.accessLevelRemoteId);

  const connectSessionAndDisplayDetails = async (
    role: ResourceAccessLevel,
    forceNewSession?: boolean
  ) => {
    logEvent({
      name: "apps_initiate_session_click",
      properties: { itemType: props.resource.resourceType },
    });

    if (
      props.resource.resourceType === ResourceType.AwsSsoPermissionSet &&
      props.resource.connection?.metadata?.__typename ===
        "AWSSSOConnectionMetadata"
    ) {
      // AWS SSO resources don't use sessions (yet, perhaps it's possible).
      // Redirect them to the AWS Portal which can grant console or CLI access.
      window.open(props.resource.connection.metadata.accessPortalUrl);
      return false;
    }

    // If an unexpired session already exists, no need to try to create a new one
    if (!forceNewSession && session) {
      if (isImpersonationResource || isProdGlobalImpersonationResource) {
        if (session.session.accessLevelRemoteId) {
          try {
            await assumeImpersonation({
              variables: {
                input: {
                  userId: session.session.accessLevelRemoteId,
                },
              },
            });
          } catch (e) {
            logError(e, "could not assume impersonation");
          }
        } else {
          logError("no role remote id found for impersonation session");
        }
        window.location.href = "/";
      } else {
        setShowSessionDetailsModal(true);
        setShowRoleModal(false);
      }
      return false;
    }

    try {
      const { data } = await createSessionMutation({
        variables: {
          input: {
            resourceId: props.resource.id,
            durationInMinutes:
              isImpersonationResource ||
              isGlobalImpersonationResource ||
              isProdGlobalImpersonationResource
                ? IMPERSONATION_SESSION_LENGTH_MINUTES
                : DEFAULT_SESSION_LENGTH_MINUTES,
            accessLevel: {
              accessLevelName: role.accessLevelName,
              accessLevelRemoteId: role.accessLevelRemoteId,
            },
          },
        },
      });
      switch (data?.createSession.__typename) {
        case "CreateSessionResult":
          if (isImpersonationResource || isProdGlobalImpersonationResource) {
            if (data.createSession.session.accessLevelRemoteId) {
              try {
                await assumeImpersonation({
                  variables: {
                    input: {
                      userId: data.createSession.session.accessLevelRemoteId,
                    },
                  },
                });
              } catch (e) {
                logError(e, "could not assume impersonation");
              }
            } else {
              logError("no role remote id found for impersonation session");
            }
            window.location.href = "/";
          } else {
            props.refetchResource && props.refetchResource();
            setShowSessionDetailsModal(true);
            opalConfetti();
          }
          return true;
        case "MfaInvalidError": {
          const useOktaMfa = orgState.orgSettings?.generalSettings.some(
            (setting) =>
              setting === GeneralSettingType.UseOktaMfaForGatingOpalActions
          );
          const useOidcMfa = orgState.orgSettings?.generalSettings.some(
            (setting) =>
              setting === GeneralSettingType.UseOidcMfaForGatingOpalActions
          );
          if (useOidcMfa) {
            try {
              const state = generateState();
              const { data } = await initOidcAuthFlow({
                variables: {
                  input: {
                    state: state,
                    oidcProviderType: OidcProviderType.Mfa,
                  },
                },
              });
              switch (data?.initOidcAuthFlow.__typename) {
                case "InitOidcAuthFlowResult":
                  setOidcData({
                    state: state,
                    oidcProviderType: OidcProviderType.Mfa,
                    postAuthPath: window.location.pathname,
                    postAuthHash: window.location.hash,
                    mfaParams: {
                      resourceId: props.resource.id,
                      accessLevel: role,
                      forceNewSession: forceNewSession,
                    },
                  });
                  window.location.replace(data?.initOidcAuthFlow.authorizeUrl);
                  break;
                case "OidcProviderNotFoundError":
                  displayErrorToast(
                    "Error: Failed to trigger MFA. Please contact your admin."
                  );
                  break;
                default:
                  displayErrorToast(
                    "Error: Failed to trigger MFA. Please try again or contact support if the issue persists."
                  );
              }
            } catch (e) {
              logError(e, "Error: could not complete MFA");
            }
          } else if (useOktaMfa) {
            setShowRoleModal(false);
            setIsIdpMfaModalOpen(true);
          } else {
            authState.authClient?.mfaFlow(history.location.pathname, {
              resourceId: props.resource.id,
              accessLevel: role,
              forceNewSession: forceNewSession,
            });
          }
          break;
        }
        case "OidcIDTokenNotFoundError": {
          try {
            const state = generateState();
            const { data } = await initOidcAuthFlow({
              variables: {
                input: {
                  state: state,
                  oidcProviderType: OidcProviderType.AwsSession,
                },
              },
            });
            switch (data?.initOidcAuthFlow.__typename) {
              case "InitOidcAuthFlowResult":
                setOidcData({
                  state: state,
                  oidcProviderType: OidcProviderType.AwsSession,
                  postAuthPath: window.location.pathname,
                  postAuthHash: window.location.hash,
                  params: {
                    action: OidcPostAuthAction.StartSession,
                    resourceId: props.resource.id,
                    accessLevel: role,
                    forceNewSession: forceNewSession,
                  },
                });
                window.location.replace(data?.initOidcAuthFlow.authorizeUrl);
                break;
              case "OidcProviderNotFoundError":
                displayErrorToast(
                  "Error: Failed to trigger MFA. Please contact your admin."
                );
                break;
              default:
                displayErrorToast(
                  "Error: Failed to trigger MFA. Please try again or contact support if the issue persists."
                );
            }
          } catch (e) {
            logError(e, "session creation failed");
            setErrorMessage(
              getModifiedErrorMessage("Error: session creation failed", e)
            );
          }
          break;
        }
        case "ResourceNotFoundError":
        case "UserImpersonationDisabledError":
        case "ImpersonationSessionLengthError":
        case "EndSystemAuthorizationError":
        case "UserFacingError":
        case "VaultClientNotFoundError":
        case "VaultSessionError":
          setErrorMessage(data.createSession.message);
          break;
        default:
          logError(new Error(`session creation failed`));
          setErrorMessage("Error: session creation failed");
      }
    } catch (e) {
      logError(e, "session creation failed");
      setErrorMessage(
        getModifiedErrorMessage("Error: session creation failed", e)
      );
    }
    return false;
  };

  const expirationTime = mostRecentUserAccess?.latestExpiringAccessPoint
    ?.expiration
    ? moment(
        new Date(mostRecentUserAccess.latestExpiringAccessPoint.expiration)
      )
    : null;

  const connectOrSelectRole = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    if (hasEUX) {
      transitionToConnect({
        connectionId: props?.resource?.connection?.id ?? "",
        resourceId: props?.resource.id,
        event,
      });
    } else if (
      props.resource.currentUserAccess.resourceUsers?.length == 1 &&
      !isImpersonationResource &&
      !isGlobalImpersonationResource &&
      !isProdGlobalImpersonationResource
    ) {
      connectSessionAndDisplayDetails(selectedRole, pageForceNewSession);
    } else {
      setShowRoleModal(true);
    }
  };

  // Button/Menu Option Handlers
  const handleRequestExport = () => {
    if (!props.resource) return;
    logEvent({
      name: "apps_export_users",
      properties: {
        exportType: "resource",
      },
    });

    displayLoadingToast("Generating export...");
    axios({
      url: "/export/resources/users?resourceID=" + props.resource.id,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        if (!props.resource) return;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Opal_" + props.resource.name.replace(" ", "_") + "_Users.csv"
        );
        link.click();
        displaySuccessToast(`Success: downloaded users for resource`);
      })
      .catch(() => {
        displayErrorToast(`Error: failed to generate export`);
      });
  };
  const handleRequestExportDebugInfo = () => {
    if (!props.resource) return;
    displayLoadingToast("Generating export...");
    axios({
      url: `/export/resources/${props.resource.id}/debug`,
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        if (!props.resource) return;
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Opal_" + props.resource.name.replace(" ", "_") + "_Debug.json"
        );
        link.click();
        displaySuccessToast(`Success: downloaded resource debug info`);
      })
      .catch(() => {
        displayErrorToast(`Error: failed to generate export`);
      });
  };

  // Populate Button Array and Menu
  let menuOptions: MenuOptions = [];

  // Add Export options
  if (props.resource.authorizedActions?.includes(AuthorizedActionExport)) {
    menuOptions.push({
      label: "Export resource users",
      icon: { type: "name", icon: "users-right" },
      onClick: handleRequestExport,
    });
    menuOptions.push({
      label: "Export debug info",
      icon: { type: "name", icon: "tool" },
      onClick: handleRequestExportDebugInfo,
    });
  }
  // Add Remove from Opal
  if (
    props.canManage &&
    props.resource.connection?.connectionType != ConnectionType.Opal
  ) {
    menuOptions.push({
      label: "Remove from Opal",
      onClick: () => {
        if (props.setShowDeleteResourceModal) {
          props.setShowDeleteResourceModal(true);
        }
      },
      type: "danger",
      icon: { type: "name", icon: "trash" },
    });
  }

  // Define Buttons
  let initiateSessionButton = null;
  let connectedButton = null;
  let expirationToolTip = null;
  let forfeitButton = null; // TODO: delete with EUX flag deprecation
  let hasForfeitMenuOption = false;
  let expirationLabel = null; // TODO: delete with EUX flag deprecation
  let hasAccess = false;
  let expirationText = null;

  const contextMenu = (
    <ContextMenu
      key="menu"
      rightAligned
      options={menuOptions}
      renderButton={(onClick) => (
        <ButtonV3
          onClick={onClick}
          leftIconName="dots-horizontal"
          size="sm"
          dataTestId="Menu"
        />
      )}
    />
  );
  const exploreButton = (
    <ButtonV3
      type="mainSecondary"
      label="Explore"
      leftIconName="department"
      onClick={() => {
        const hash = makeURLForEntityViz(props.resourceId, EntityType.Resource);
        openInNewTab("/insights" + hash);
      }}
      size="sm"
      key="exploreButton"
    />
  );
  const editButton = (
    <ButtonV3
      type="mainSecondary"
      label="Edit"
      leftIconName="edit"
      onClick={() => history.push(`/resources/${props.resourceId}/edit`)}
      size="sm"
      key="editButton"
    />
  );

  let viewRequestButton = (
    <PendingRequestDisplay
      key="request-button"
      resourceId={props.resource.id}
      pendingRequests={props.resource.currentUserAccess.pendingRequests}
      renderButton={(onClick) => (
        <ButtonV3
          label="View request"
          leftIconName="send"
          type="defaultSecondary"
          onClick={onClick}
          size="sm"
        />
      )}
    />
  );

  switch (mostRecentUserAccessStatus) {
    case CurrentUserResourceAccessStatus.Authorized:
      if (hasForfeitableRole && !isProdGlobalImpersonationResource) {
        forfeitButton = (
          <ForfeitResourceButton
            resource={props.resource}
            refetchResource={props.refetchResource}
            hasV3={hasV3}
            key="forfeitButton"
            buttonSize="sm"
          />
        );
        if (!hasForfeitMenuOption) {
          hasForfeitMenuOption = true;
        }
      }
      hasAccess = true;

      break;
    case CurrentUserResourceAccessStatus.AuthorizedSessionStarted:
      expirationText = getExpirationText(currentSessions);

      connectedButton = (
        <ButtonV3
          label={"Connected"}
          leftIconName="zap"
          onClick={connectOrSelectRole}
          type="successSecondary"
          key="connectedButton"
          size="sm"
        />
      );

      expirationToolTip = (
        <Tooltip
          key="expirationToolTip"
          tooltipText={expirationText}
          placement={TooltipPlacement.Top}
        >
          <Icon name="info" size="xs" color="gray600" />
        </Tooltip>
      );

      if (hasForfeitableRole) {
        forfeitButton = (
          <ForfeitResourceButton
            resource={props.resource}
            refetchResource={props.refetchResource}
            hasV3={hasV3}
            key="forfeitButton"
            buttonSize="sm"
          />
        );
        if (!hasForfeitMenuOption) {
          hasForfeitMenuOption = true;
        }
      }
      hasAccess = true;

      break;
    case CurrentUserResourceAccessStatus.AuthorizedSessionNotStarted:
      initiateSessionButton = (
        <ButtonV3
          label="Connect"
          leftIconName="zap"
          onClick={connectOrSelectRole}
          type="success"
          key="initiateSessionButton"
          size="sm"
        />
      );

      if (hasForfeitableRole) {
        forfeitButton = (
          <ForfeitResourceButton
            resource={props.resource}
            refetchResource={props.refetchResource}
            hasV3={hasV3}
            key="forfeitButton"
            buttonSize="sm"
          />
        );
        if (!hasForfeitMenuOption) {
          hasForfeitMenuOption = true;
        }
      }
      hasAccess = true;

      break;
  }

  // Request Button
  // Show a disabled request button if the user has no access to the resource and is not allowed to request it.
  const userPendingRoleRemoteIds = getResourcePendingRequestRoleRemoteIds(
    props.resource.currentUserAccess
  );

  const notRequestableMessage =
    "This resource cannot be requested. Please see the resource admin.";
  const hasOtherPrimaryButton = hasEUX
    ? Boolean(connectedButton || initiateSessionButton)
    : Boolean(
        connectedButton ||
          initiateSessionButton ||
          forfeitButton ||
          expirationLabel ||
          userPendingRoleRemoteIds.size > 0
      );

  let requestButton = (
    <ButtonV3
      label="Request"
      leftIconName="raised-hand"
      type={hasOtherPrimaryButton ? "mainSecondary" : "main"}
      onClick={() => {
        props.onNavigate("request");
        logEvent({
          name: "apps_item_request_click",
          properties: {
            itemType: props.resource.resourceType,
          },
        });
      }}
      // TODO: implement backend check to see if okta app has at least one requestable resoruce
      disabled={!props.resource.isRequestable && !isOktaApp}
      disabledTooltip={notRequestableMessage}
      key="requestButton"
      size="sm"
    />
  );
  if (hasAccess) {
    expirationLabel = (
      <ResourceExpirationLabel
        user={authState.user?.user}
        resource={props.resource}
        entityType={EntityType.Resource}
        expiration={expirationTime}
        currentUserResourceUsers={
          props.resource.currentUserAccess.resourceUsers
        }
        iconOnly={true}
        hasV3={hasV3}
        key="expirationLabel"
        hasOtherPrimaryButton={hasOtherPrimaryButton}
        buttonSize="sm"
      />
    );
  }

  // Populate buttons array and menuOptions
  const buttons = [];
  // Explore Button
  if (isAdmin) {
    buttons.push(exploreButton);
  }
  // Edit Button
  if (props.canManage) {
    buttons.push(editButton);
  }

  // Request Button
  if (
    !isProdGlobalImpersonationResource &&
    (props.resource.isRequestable || !hasForfeitableRole)
  ) {
    if (hasAccess) {
      // if has Access, Request button goes to menu
      menuOptions.unshift({
        label: "Request",
        icon: { type: "name", icon: "raised-hand" },
        onClick: () => {
          props.onNavigate("request");
          logEvent({
            name: "apps_item_request_click",
            properties: {
              itemType: props.resource.resourceType,
            },
          });
        },
      });
    } else {
      // if no Access, Request button is displayed as button
      buttons.push(requestButton);
    }
  }
  // View Request Button
  if (!hasEUX && userPendingRoleRemoteIds.size > 0) {
    buttons.push(viewRequestButton);
  }
  // Access Button
  if (expirationLabel) {
    buttons.push(expirationLabel);
  }
  // Forfeit Button
  if (!hasEUX && forfeitButton) {
    buttons.push(forfeitButton);
  }
  // Connect Button
  if (initiateSessionButton) {
    buttons.push(initiateSessionButton);
  }
  // Connected Button
  if (connectedButton && expirationToolTip) {
    buttons.push(connectedButton);
    buttons.push(expirationToolTip);
  }
  // Context Menu
  if (menuOptions.length) {
    buttons.push(contextMenu);
  }

  return (
    <>
      {hasEUX && (
        <PendingRequestMenuOption
          resourceId={props.resourceId}
          pendingRequests={props.resource.currentUserAccess.pendingRequests}
          showMenuOption={(onClick) => {
            if (userPendingRoleRemoteIds.size > 0) {
              const option = menuOptions.find(
                (elem) => "label" in elem && elem.label === "View request"
              );
              if (!option) {
                menuOptions.unshift({
                  label: "View request",
                  icon: { type: "name", icon: "send" },
                  onClick: onClick,
                });
              }
            }
          }}
        />
      )}
      {hasEUX && hasForfeitMenuOption && (
        <ForfeitResourceMenuOption
          resource={props.resource}
          refetchResource={props.refetchResource}
          hasV3={hasV3}
          showMenuOption={(onClick) => {
            const option = menuOptions.find(
              (elem) => "label" in elem && elem.label === "Forfeit"
            );
            if (!option) {
              menuOptions.unshift({
                label: "Forfeit",
                icon: { type: "name", icon: "zap-off" },
                onClick: onClick,
                type: "danger",
                disabled: !!orgState.orgSettings?.isRemoteReadOnly,
              });
            }
          }}
        />
      )}
      {session && showSessionDetailsModal && (
        <SessionDetailsModal
          entityType={EntityTypeDeprecated.Resource}
          resource={props.resource}
          session={session.session}
          serviceName={getServiceTypeInfo(props.resource.serviceType)?.name}
          onClose={() => {
            setShowSessionDetailsModal(false);
          }}
          selectedRole={selectedRole}
        />
      )}
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "center",
          gap: "sm",
        })}
      >
        {buttons}
      </div>
      {showRoleModal && (
        <RoleModal
          errorMessage={errorMessage}
          isModalOpen={showRoleModal}
          onClose={() => {
            setErrorMessage(null);
            setShowRoleModal(false);
          }}
          resource={props.resource}
          selectedRole={selectedRole}
          setSelectedRole={setSelectedRole}
          isImpersonationResource={
            isImpersonationResource || isProdGlobalImpersonationResource
          }
          onSubmit={() => {
            return connectSessionAndDisplayDetails(
              selectedRole,
              pageForceNewSession
            );
          }}
          loading={createSessionLoading}
          getCurrentSession={getCurrentSession}
        />
      )}
      {isIdpMfaModalOpen && (
        <IdpMfaModal
          onClose={() => {
            setIsIdpMfaModalOpen(false);
            setShowRoleModal(true);
          }}
          onMfaSuccess={() => {
            setIsIdpMfaModalOpen(false);
            connectSessionAndDisplayDetails(selectedRole, pageForceNewSession);
          }}
        />
      )}
    </>
  );
};
