import {
  AuthSessionStatus,
  useAuthSessionStatusLazyQuery,
} from "api/generated/graphql";
import { ToastStyle } from "components/toast/Toast";
import { getToastUrl, MessageCode } from "components/toast/ToastUrlParser";
import _ from "lodash";
import { RequestState } from "utils/common";
import { useMountEffect } from "utils/hooks";
import { logError, logWarning } from "utils/logging";

const SessionValidListener = () => {
  const [authSessionStatus] = useAuthSessionStatusLazyQuery({
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      switch (data.authSessionStatus.__typename) {
        case "AuthSessionStatusResult":
          if (
            !window.location.pathname.includes("/sign-in") &&
            !window.location.pathname.includes("/sign-out") &&
            data.authSessionStatus.status === AuthSessionStatus.SessionExpired
          ) {
            window.location.href = getToastUrl(
              "/sign-out",
              RequestState.Warning,
              MessageCode.ErrorAuthSessionInvalid,
              ToastStyle.Banner,
              window.location.pathname
            );
            logWarning(new Error("auth session expired"));
          }
          break;
        default:
          logError(new Error("failed to list auth session status"));
      }
    },
  });

  // Executed when user has switched back to the tab, and at most once every
  // 60 seconds
  const onFocus = _.throttle(() => {
    authSessionStatus();
  }, 60 * 1000);

  useMountEffect(() => {
    window.addEventListener("focus", onFocus);
    return () => {
      window.removeEventListener("focus", onFocus);
    };
  });
  return null;
};

export default SessionValidListener;
