import { Skeleton } from "components/ui";
import pluralize from "pluralize";

const OpalAppCountCell = (props: {
  complete?: boolean;
  count?: number;
  subject: string;
}) => {
  return (props.complete === true || props.complete === undefined) &&
    props.count != null ? (
    <span>{`${pluralize(props.subject, props.count, true)}`}</span>
  ) : (
    <Skeleton variant="text" width="50px" />
  );
};

export default OpalAppCountCell;
