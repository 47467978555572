import {
  AccessReviewGroupResourceForDetailFragment,
  EntityType,
  ReviewerUserFragment,
} from "api/generated/graphql";
import { ResourceLabel } from "components/label/Label";
import TableSimple, { Header } from "components/ui/table/TableSimple";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import { useParams } from "react-router";

import AccessReviewContext from "../AccessReviewContext";
import { DecisionLabel } from "../AccessReviewReviewerDetails";
import ResourceMoreInfo from "./ResourceMoreInfo";
import * as styles from "./ResourceUserSubRow.css";

interface Props {
  groupResource: AccessReviewGroupResourceForDetailFragment;
}

interface RowData {
  id: string;
  reviewer: string;
  decision: string;
  note: string;
  data: ReviewerUserFragment;
}

const GroupResourceSubRow = ({ groupResource }: Props) => {
  const { accessReviewGroupId } = useParams<Record<string, string>>();
  const { accessReviewState } = useContext(AccessReviewContext);

  const performReviewState =
    accessReviewState.performReviewStateByUARGroupId[accessReviewGroupId];
  const reviewers = groupResource.reviewerUsers ?? [];

  const columns: Header<RowData>[] = [
    {
      id: "reviewer",
      label: "Reviewer",
      cellRenderer: ({ data }) => {
        const reviewer = data;
        return (
          <ResourceLabel
            text={reviewer.name}
            entityTypeNew={EntityType.User}
            avatar={reviewer.avatarUrl}
            entityId={reviewer.userId}
          />
        );
      },
    },
    {
      id: "decision",
      label: "Decision",
      cellRenderer: ({ data }) => {
        return <DecisionLabel status={data.status} />;
      },
    },
    {
      id: "note",
      label: "Review Note",
      cellRenderer: ({ data }) => {
        const updatedNote = performReviewState?.groupResourceActions.find(
          (groupResourceAction) =>
            groupResourceAction.accessReviewGroupResourceId === groupResource.id
        )?.note;
        return <span>{updatedNote ?? data.note ?? "--"}</span>;
      },
    },
  ];

  const rows: RowData[] = reviewers.map((reviewer) => {
    return {
      id: reviewer.userId,
      reviewer: reviewer.userId,
      decision: reviewer.status,
      note: reviewer.note || "--",
      data: reviewer,
    };
  });

  return (
    <div className={styles.container}>
      {groupResource.resource && (
        <ResourceMoreInfo resourceId={groupResource.resource.id} />
      )}
      {rows.length > 0 ? (
        <TableSimple columns={columns} rows={rows} getRowId={(row) => row.id} />
      ) : (
        <div className={sprinkles({ color: "gray600", fontSize: "bodySm" })}>
          No reviewers assigned
        </div>
      )}
    </div>
  );
};

export default GroupResourceSubRow;
