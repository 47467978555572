import {
  AccessReviewAction,
  AccessReviewResourceUserFragment,
  Maybe,
  ResourceAccessLevel,
  ResourceAccessLevelInput,
} from "api/generated/graphql";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import * as Icons from "react-feather";
import { PerformReviewState } from "views/access_reviews/AccessReviewContext";
import AccessReviewEditResourceRoleModal from "views/access_reviews/AccessReviewEditResourceRoleModal";

import * as styles from "./AccessReviewResourceRole.css";

type AccessReviewResourceRoleProps = {
  role: Maybe<ResourceAccessLevel>;
  updatedRole: Maybe<ResourceAccessLevelInput> | undefined;
  resourceId: string;
  showEditIcon?: boolean;
  onRoleChange?: (role: Maybe<ResourceAccessLevel>) => void;
};

const AccessReviewResourceRoleCell = (props: AccessReviewResourceRoleProps) => {
  const [showModal, setShowModal] = useState(false);
  let editIcon;

  if (props.role && props.role.accessLevelName && props.showEditIcon) {
    editIcon = (
      <span
        className={sprinkles({
          cursor: "pointer",
          paddingRight: "sm",
        })}
        onClick={() => setShowModal(true)}
      >
        <Icons.Edit2 strokeWidth={2} size={14} />
      </span>
    );
  }

  let role = props.updatedRole || props.role;
  let roleName = role?.accessLevelName || "--";

  return (
    <div className={sprinkles({ display: "flex", width: "100%" })}>
      <>
        <span
          className={styles.roleName({
            type: props.updatedRole ? "updated" : undefined,
          })}
        >
          {roleName}
        </span>
        <span>{editIcon}</span>
      </>
      {showModal && (
        <AccessReviewEditResourceRoleModal
          onSubmit={(role) => {
            if (props.onRoleChange) {
              props.onRoleChange(role);
            }
            setShowModal(false);
          }}
          onClose={() => setShowModal(false)}
          currentRole={role}
          resourceId={props.resourceId}
        />
      )}
    </div>
  );
};

export const updateReviewStateForResourceUserRoleChange = (
  performReviewState: PerformReviewState,
  accessReviewResourceUser: AccessReviewResourceUserFragment,
  role: Maybe<ResourceAccessLevel>
): PerformReviewState => {
  const existingInfo = performReviewState.resourceUserActions.find(
    (resourceUserAction) =>
      resourceUserAction.accessReviewResourceUserId ===
      accessReviewResourceUser.id
  );

  if (
    existingInfo &&
    accessReviewResourceUser.accessLevel.accessLevelName ===
      role?.accessLevelName
  ) {
    // This can happen if a user changes the role to a new one and
    // then changes it back to the existing one. Then we want to remove the
    // review action
    performReviewState.resourceUserActions = performReviewState.resourceUserActions.filter(
      (action) => {
        return (
          action.accessReviewResourceUserId !==
          existingInfo.accessReviewResourceUserId
        );
      }
    );
  } else if (existingInfo) {
    existingInfo.action = AccessReviewAction.Update;
    existingInfo.updatedAccessLevel = role;
  } else {
    performReviewState.resourceUserActions.push({
      accessReviewResourceUserId: accessReviewResourceUser.id,
      action: AccessReviewAction.Update,
      updatedAccessLevel: role,
    });
  }

  return performReviewState;
};

export default AccessReviewResourceRoleCell;
