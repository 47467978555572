import {
  AccessOption,
  ConnectionApp,
  ConnectionPreviewSmallFragment,
  ConnectionType,
  OktaResourceApp,
  SyncType,
  useAppDetailColumnHeaderQuery,
  useAppDetailColumnQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeader";
import ColumnListItem, {
  ColumnListItemsSkeleton,
} from "components/column/ColumnListItem";
import useSyncMenuItem from "components/sync/useSyncMenuItem";
import { Divider } from "components/ui";
import { useContext } from "react";
import { useHistory, useParams } from "react-router";
import useLogEvent from "utils/analytics";
import {
  connectionTypeAllowsCreateGroup,
  connectionTypeHasNestedResources,
  isConnectionTypeGroupsProvider,
  isConnectionTypeResourcesProvider,
} from "utils/directory/connections";
import { isNativeConnectionType } from "utils/directory/resources";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useTransitionTo } from "utils/router/hooks";
import BulkImportColumnV2 from "views/apps/BulkImportColumnV2";
import ItemsListSectionImport from "views/apps/ItemsListSectionImport";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { hasServiceTypes } from "views/resources/creation/utils";

import {
  ACCESS_OPTION_URL_KEY,
  AppsContext,
  ITEM_TYPE_URL_KEY,
} from "./AppsContext";
import ItemsListSection from "./ItemsListSection";
import ResourceTreeSection from "./ResourceTreeSection";
import { appsBreadcrumb, getAppIcon, useAccessOptionKey } from "./utils";

const AppDetailColumnHeader = ({
  app,
  name,
}: {
  app:
    | Pick<ConnectionApp, "__typename" | "connectionType" | "connectionId">
    | Pick<OktaResourceApp, "__typename">;
  name: string;
}) => {
  const history = useHistory();
  const [accessOptionKey] = useAccessOptionKey();

  const { isSelectMode, setIsSelectMode, clearSelectedItems } = useContext(
    AppsContext
  );
  const logEvent = useLogEvent();
  const { authState } = useContext(AuthContext);

  const connectionId =
    app.__typename === "ConnectionApp" ? app.connectionId : "";

  const { data, loading } = useAppDetailColumnHeaderQuery({
    variables: { connectionId },
    skip: !connectionId,
  });

  let connection: ConnectionPreviewSmallFragment | null = null;
  let connectionType: ConnectionType | null = null;
  let hasAwsRoleCreation = false;
  if (data?.connection.__typename === "ConnectionResult") {
    connection = data.connection.connection;
    connectionType = data.connection.connection.connectionType;
  }
  if (data?.organizationSettings.__typename === "OrganizationSettingsResult") {
    hasAwsRoleCreation = Boolean(
      data.organizationSettings.settings.roleCreationReviewerOwner
    );
  }

  const refreshMenuItem = useSyncMenuItem({
    syncType: SyncType.PullConnectionsSingleConnectionFast,
    connection: connection ?? undefined,
    label: "Refresh items",
    queriesToRefetch: ["ItemsListSectionImport"],
    loadingEntity: loading || !connection,
  });

  const menuOptions: PropsFor<typeof ColumnHeader>["menuOptions"] = [];

  if (accessOptionKey !== AccessOption.Unmanaged) {
    menuOptions.push({
      label: isSelectMode ? "Cancel bulk select" : "Bulk select",
      icon: { type: "name", icon: "check-square" },
      onClick: () => {
        if (isSelectMode) {
          clearSelectedItems();
        } else {
          logEvent({
            name: "apps_bulk_select_start",
            properties: {
              entryPoint: "app_detail",
            },
          });
          setIsSelectMode(true);
        }
      },
    });
  }

  if (connectionType) {
    const isGroupsProvider = isConnectionTypeGroupsProvider(connectionType);
    const isResourcesProvider = isConnectionTypeResourcesProvider(
      connectionType
    );
    const canImport = !isNativeConnectionType(connectionType);

    if (
      canImport &&
      authState.user?.isAdmin &&
      accessOptionKey !== AccessOption.Unmanaged
    ) {
      menuOptions.push({
        label: "Import items",
        icon: { type: "name", icon: "plus" },
        onClick: () => {
          history.push(
            `/apps/${connectionId}?${ACCESS_OPTION_URL_KEY}=${AccessOption.Unmanaged}`
          );
        },
      });
    }

    if (
      isGroupsProvider &&
      connectionTypeAllowsCreateGroup(connectionType) &&
      authState.user?.isAdmin
    ) {
      menuOptions.push({
        label: "Create group",
        icon: { type: "name", icon: "plus" },
        onClick: () => history.push(`/apps/${connectionId}/create-group`),
      });
    }

    if (
      isResourcesProvider &&
      connectionType !== ConnectionType.Opal &&
      !hasServiceTypes(connectionType) &&
      authState.user?.isAdmin
    ) {
      menuOptions.push({
        label: "Create resource",
        icon: { type: "name", icon: "plus" },
        onClick: () => history.push(`/apps/${connectionId}/create-resource`),
      });
    }

    if (connectionType === ConnectionType.Aws && hasAwsRoleCreation) {
      menuOptions.push({
        label: "Create new IAM role",
        icon: { type: "name", icon: "plus" },
        onClick: () =>
          history.push({
            pathname: `/apps/${connectionId}/create-role`,
          }),
      });
    }

    if (
      canImport &&
      authState.user?.isAdmin &&
      accessOptionKey === AccessOption.Unmanaged &&
      refreshMenuItem
    ) {
      menuOptions.push(refreshMenuItem);
    }
  }

  return (
    <ColumnHeader
      title={name}
      icon={getAppIcon(app)}
      breadcrumbs={[appsBreadcrumb]}
      menuOptions={menuOptions}
    />
  );
};
interface Props {
  appId?: string;
}

const AppDetailColumn = (props: Props) => {
  const [accessOptionKey] = useAccessOptionKey();

  const { selectedUnmanagedItems } = useContext(AppsContext);

  const transitionTo = useTransitionTo({
    preserveQueries: [ACCESS_OPTION_URL_KEY, ITEM_TYPE_URL_KEY],
  });

  const { appView } = useParams<Record<string, string>>();
  const { appId } = props;

  const { data, loading, error } = useAppDetailColumnQuery({
    variables: {
      id: appId || "",
    },
    skip: !appId,
  });

  const hasResourceHierarchyUI = useFeatureFlag(
    FeatureFlag.ResourceHierarchyUI
  );

  if (loading) {
    return (
      <Column>
        <ColumnHeaderSkeleton />
        <Divider margin="md" />
        <ColumnListItemsSkeleton />
      </Column>
    );
  }

  if (error) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  if (data?.app.__typename === "AppNotFoundError" || !appId) {
    return null;
  }

  const app = data?.app.__typename === "App" ? data.app : null;
  if (!app) return null;

  const getAppIsVisible = () => {
    if (app.app.__typename === "ConnectionApp") {
      return true;
    }
    if (app.app.__typename === "OktaResourceApp") {
      return app.app.isVisible;
    }
    return false;
  };

  if (
    accessOptionKey === AccessOption.Unmanaged &&
    app.app.__typename === "ConnectionApp"
  ) {
    return (
      <>
        <Column>
          <AppDetailColumnHeader app={app.app} name={app.name} />
          {getAppIsVisible() ? (
            <>
              <Divider margin="md" />
              <ColumnListItem
                label={app.name}
                icon={getAppIcon(app.app)}
                selected={Boolean(appView)}
                onClick={() => {
                  transitionTo({
                    pathname:
                      app.app.__typename === "OktaResourceApp"
                        ? `/apps/okta/${appId}/overview`
                        : `/apps/${appId}/overview`,
                  });
                }}
              />
            </>
          ) : null}
          <Divider margin="md" label="Resources to add" labelPosition="left" />
          <ItemsListSectionImport appId={appId} />
        </Column>
        {selectedUnmanagedItems.length > 0 && <BulkImportColumnV2 />}
      </>
    );
  }

  const hasNestedResources =
    app.app.__typename === "ConnectionApp" &&
    connectionTypeHasNestedResources(
      app.app.connectionType,
      app.app.connection?.metadata
    ) &&
    (app.app.connectionType === ConnectionType.CustomConnector ||
      app.app.connectionType === ConnectionType.Gcp ||
      app.app.connectionType === ConnectionType.AzureAd ||
      app.app.connectionType === ConnectionType.Snowflake);
  const showResourceHierarchy = hasResourceHierarchyUI && hasNestedResources;

  return (
    <Column maxWidth="mdlg">
      <AppDetailColumnHeader app={app.app} name={app.name} />
      {getAppIsVisible() ? (
        <>
          <Divider margin="md" />
          <ColumnListItem
            label={app.name}
            icon={getAppIcon(app.app)}
            selected={Boolean(appView)}
            onClick={() => {
              transitionTo({
                pathname:
                  app.app.__typename === "OktaResourceApp"
                    ? `/apps/okta/${appId}/overview`
                    : `/apps/${appId}/overview`,
              });
            }}
          />
        </>
      ) : null}

      <Divider margin="md" label="Resources" labelPosition="left" />
      {app.app.__typename === "ConnectionApp" && showResourceHierarchy ? (
        <ResourceTreeSection appId={appId} />
      ) : (
        <ItemsListSection appId={appId} />
      )}
    </Column>
  );
};

export default AppDetailColumn;
