import AuthCodeCallbackAuthed from "components/auth/AuthCodeCallbackAuthed";
import GitHubCallback from "components/auth/GitHubCallback";
import GitLabCallback from "components/auth/GitLabCallback";
import GitLabConnectionCallback from "components/auth/GitLabConnectionCallback";
import OidcCallback from "components/auth/OidcCallback";
import SignOut from "components/auth/SignOut";
import SlackCallback from "components/auth/SlackCallback";
import SlackMfa from "components/auth/SlackMfa";
import SlackMfaCallback from "components/auth/SlackMfaCallback";
import SlackMfaOidcPresigned from "components/auth/SlackMfaOidcPresigned";
import FullscreenLayout from "components/layout/FullscreenLayout";
import LayoutV2 from "components/layout/Layout";
import LayoutV3 from "components/layout/LayoutV3";
import useLeftSidebarSectionInfos from "components/layout/left_sidebar/useLeftSidebarRoutes";
import useLeftSidebarSectionInfosV3 from "components/layout/left_sidebar/useLeftSidebarRoutesV3";
import { NavigationContextProvider } from "components/layout/NavigationContextProvider";
import SpotlightSearchProvider from "components/spotlight_search/SpotlightSearchProvider";
import { VirtualTableContextProvider } from "components/tables/material_table/VirtualTableContextProvider";
import { ReactElement } from "react";
import { Redirect, Switch } from "react-router";
import { Route } from "react-router-dom";
import { CompatRoute } from "react-router-dom-v5-compat";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { useHasInventory } from "utils/hooks";
import AccessRequestView from "views/access_request/AccessRequestView";
import AccessReviewCreateV3 from "views/access_reviews/AccessReviewCreateV3";
import AccessReviewScheduleEditorV3 from "views/access_reviews/AccessReviewScheduleEditorV3";
import ResourceImportRolesView from "views/apps/ResourceImportRolesView";
import { NotFoundPage } from "views/error/ErrorCodePage";
import Homepage from "views/homepage/Homepage";
import { Search } from "views/search/Search";

import AccessReviewPerformView from "./access_reviews/AccessReviewPerform";
import AppAddIntegrationView from "./apps/AppAddIntegrationView";
import AppDetailViewV3 from "./apps/AppDetailViewV3";
import AppEditView from "./apps/AppEditView";
import AppImportItemsView from "./apps/AppImportItemsView";
import AppsBulkEditView from "./apps/AppsBulkEditView";
import { AppsContextProvider } from "./apps/AppsContext";
import BundleAddGroupsView from "./apps/BundleAddGroupsView";
import BundleAddResourcesView from "./apps/BundleAddResourcesView";
import GroupAddMemberGroupsView from "./apps/GroupAddMemberGroupsView";
import GroupAddResourcesView from "./apps/GroupAddResourcesView";
import GroupAddUsersView from "./apps/GroupAddUsersView";
import GroupEditView from "./apps/GroupEditView";
import OktaResourceAddResourcesView from "./apps/OktaResourceAddResourcesView";
import ResourceAddGroupsView from "./apps/ResourceAddGroupsView";
import ResourceAddPrincipalsView from "./apps/ResourceAddPrincipalsView";
import ResourceAddUsersView from "./apps/ResourceAddUsersView";
import ResourceEditView from "./apps/ResourceEditView";
import UserAddGroupsView from "./apps/UserAddGroupsView";
import UserAddResourcesView from "./apps/UserAddResourcesView";
import BundleCreateView from "./bundles/BundleCreateView";
import BundleDetailColumnV3 from "./bundles/BundleDetailColumnV3";
import BundleEditView from "./bundles/BundleEditView";
import CreateConnection from "./connections/CreateConnection";
import Inventory from "./inventory/Inventory";
import OwnerAddUsersView from "./owners/owner_viewer/OwnerAddUsersView";
import CustomRemediations from "./recommendations/resolutions/CustomRemediations";
import Setup from "./setup/Setup";
import { SetupContextProvider } from "./setup/SetupContext";
import TagAddGroupsView from "./tags/viewer/TagAddGroupsView";
import TagAddResourcesView from "./tags/viewer/TagAddResourcesView";
import TagAddUsersView from "./tags/viewer/TagAddUsersView";
import CreateConfigTemplateV3 from "./templates/CreateConfigTemplateV3";
import EditConfigTemplateView from "./templates/EditConfigTemplate";
import useInitApp from "./useInitApp";
import UserSettings from "./user_settings/UserSettings";

const getFullscreenRoutes = ({ isV3 = false }: { isV3: boolean }) => {
  if (!isV3) {
    return ["/setup"];
  }
  const EDIT_ROUTES = [
    "/apps/:connectionId/edit",
    "/apps/okta/:connectionId/edit",
    "/apps/bulk-edit",
    "/apps/:connectionId/account/:accountId/edit",
    "/resources/:resourceId/edit",
    "/groups/:groupId/edit",
    "/access-reviews/t/:scheduleId/edit",
    "/access-reviews/t/new",
    "/access-reviews/new",
    "/access-reviews/:accessReviewId/r/:accessReviewResourceId",
    "/access-reviews/:accessReviewId/g/:accessReviewGroupId",
    "/access-reviews/:accessReviewId/c/:accessReviewConnectionId",
    "/templates/configurations/:configurationTemplateId/edit",
    "/bundles/:bundleId/edit",
  ];
  const ADD_ROUTES = [
    "/apps/add-app",
    "/apps/create/:serviceID",
    "/apps/:connectionId/import",
    "/resources/:resourceId/add-principals",
    "/resources/:resourceId/add-users",
    "/resources/:resourceId/import-roles",
    "/groups/:groupId/add-users",
    "/owners/:ownerId/add-users",
    "/groups/:groupId/add-resources",
    "/groups/:groupId/add-groups",
    "/users/:userId/add-resources",
    "/users/:userId/add-groups",
    "/resources/:resourceId/add-groups",
    "/resources/:resourceId/add-resources",
    "/templates/configurations/create",
    "/bundles/create",
    "/bundles/:bundleId/add-resources",
    "/bundles/:bundleId/add-groups",
    "/request-access",
    "/tags/:tagId/add-users",
    "/tags/:tagId/add-resources",
    "/tags/:tagId/add-groups",
  ];
  return ["/setup", ...EDIT_ROUTES, ...ADD_ROUTES];
};

const AuthenticatedViews = () => {
  useInitApp();
  const leftSidebarSectionInfos = useLeftSidebarSectionInfos();
  const leftSidebarSectionInfosV3 = useLeftSidebarSectionInfosV3();
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);
  const hasNHIs = useFeatureFlag(FeatureFlag.NonHumanIdentities);
  const hasV3Search = useFeatureFlag(FeatureFlag.V3Search);
  const hasCustomRemediations = useFeatureFlag(FeatureFlag.CustomRemediations);
  const hasInventory = useHasInventory();
  const hasOktaAliasing = useFeatureFlag(FeatureFlag.OktaGroupAlias);

  const leftSidebarRoutes: ReactElement[] = [];
  if (hasV3Nav) {
    leftSidebarSectionInfosV3.forEach((sectionInfo) => {
      if ("options" in sectionInfo) {
        sectionInfo.options.forEach((option) => {
          if (option.canAccess) {
            leftSidebarRoutes.push(
              <CompatRoute
                key={`${option.route}-${option.title}`}
                path={option.route}
                component={option.component}
              />
            );
            if (option.extraRoutes) {
              option.extraRoutes.forEach((extraRoute) => {
                leftSidebarRoutes.push(
                  <CompatRoute
                    key={`${extraRoute}-${option.title}`}
                    path={extraRoute}
                    component={option.component}
                  />
                );
              });
            }
          }
        });
      } else {
        if (sectionInfo.canAccess) {
          leftSidebarRoutes.push(
            <CompatRoute
              key={`${sectionInfo.route}-${sectionInfo.title}`}
              path={sectionInfo.route}
              component={sectionInfo.component}
            />
          );
          if (sectionInfo.extraRoutes) {
            sectionInfo.extraRoutes.forEach((extraRoute) => {
              leftSidebarRoutes.push(
                <CompatRoute
                  key={`${extraRoute}-${sectionInfo.title}`}
                  path={extraRoute}
                  component={sectionInfo.component}
                />
              );
            });
          }
        }
      }
    });
  } else {
    leftSidebarSectionInfos.forEach((sectionInfo) => {
      sectionInfo.options.forEach((option) => {
        leftSidebarRoutes.push(
          <CompatRoute
            key={`${option.route}-${option.title}`}
            path={option.route}
            component={option.component}
          />
        );
        if (option.extraRoutes) {
          option.extraRoutes.forEach((extraRoute) => {
            leftSidebarRoutes.push(
              <CompatRoute
                key={`${extraRoute}-${option.title}`}
                path={extraRoute}
                component={option.component}
              />
            );
          });
        }
      });
    });
  }

  const Layout = hasV3Nav ? LayoutV3 : LayoutV2;

  let contents = (
    <Switch>
      <Route exact path="/sign-in" render={() => <Redirect to="/" />} />
      <Route exact path="/" component={Homepage} />,
      <Route exact path="/sign-out" component={SignOut} />
      <Route exact path="/callback" component={AuthCodeCallbackAuthed} />
      <Route exact path="/callback/slack" component={SlackCallback} />
      <Route exact path="/callback/github" component={GitHubCallback} />
      <Route exact path="/callback/gitlab" component={GitLabCallback} />
      <Route
        exact
        path="/callback/gitlab-connection"
        component={GitLabConnectionCallback}
      />
      <Route exact path="/callback/oidc" component={OidcCallback} />
      <Route exact path="/callback/slackmfa" component={SlackMfaCallback} />
      <Route exact path="/slackmfa" component={SlackMfa} />
      <Route exact path="/slackmfa/oidc" component={SlackMfaOidcPresigned} />
      <Route path="/browse" render={() => <Redirect to="/apps" />} />
      <Route path={getFullscreenRoutes({ isV3: hasV3Nav })}>
        <FullscreenLayout>
          <Switch>
            <Route path="/setup" component={Setup} />
            {hasV3Nav && (
              <>
                {hasNHIs ? (
                  <Route
                    path="/resources/:resourceId/add-principals"
                    component={ResourceAddPrincipalsView}
                  />
                ) : (
                  <Route
                    path="/resources/:resourceId/add-principals"
                    component={NotFoundPage}
                  />
                )}
                <Route
                  path="/apps/:connectionId/edit"
                  component={AppEditView}
                />
                <Route path="/apps/add-app" component={AppAddIntegrationView} />
                <Route
                  path="/apps/create/:serviceID"
                  component={CreateConnection}
                />
                <Route
                  path="/apps/:connectionId/import"
                  component={AppImportItemsView}
                />
                <Route path="/apps/bulk-edit" component={AppsBulkEditView} />
                <Route
                  path="/resources/:resourceId/edit"
                  component={ResourceEditView}
                />
                <Route
                  path="/resources/:resourceId/import-roles"
                  component={ResourceImportRolesView}
                />
                <Route path="/groups/:groupId/edit" component={GroupEditView} />
                <Route
                  path="/resources/:resourceId/add-users"
                  component={ResourceAddUsersView}
                />
                <Route
                  path="/groups/:groupId/add-users"
                  component={GroupAddUsersView}
                />
                <Route
                  path="/groups/:groupId/add-resources"
                  component={GroupAddResourcesView}
                />
                <Route
                  path="/groups/:groupId/add-groups"
                  component={GroupAddMemberGroupsView}
                />
                <Route
                  path="/users/:userId/add-resources"
                  component={UserAddResourcesView}
                />
                <Route
                  path="/users/:userId/add-groups"
                  component={UserAddGroupsView}
                />
                <Route
                  path="/resources/:resourceId/add-groups"
                  component={ResourceAddGroupsView}
                />
                <Route
                  path="/owners/:ownerId/add-users"
                  component={OwnerAddUsersView}
                />
                <Route
                  path="/tags/:tagId/add-users"
                  component={TagAddUsersView}
                />
                <Route
                  path="/tags/:tagId/add-resources"
                  component={TagAddResourcesView}
                />
                <Route
                  path="/tags/:tagId/add-groups"
                  component={TagAddGroupsView}
                />
                <Route
                  path="/access-reviews/t/:scheduleId/edit"
                  component={AccessReviewScheduleEditorV3}
                />
                <Route
                  path="/access-reviews/t/new"
                  component={AccessReviewScheduleEditorV3}
                />
                <Route
                  path="/access-reviews/new"
                  component={AccessReviewCreateV3}
                />
                <Route
                  path={[
                    "/access-reviews/:accessReviewId/r/:accessReviewResourceId",
                    "/access-reviews/:accessReviewId/g/:accessReviewGroupId",
                    "/access-reviews/:accessReviewId/c/:accessReviewConnectionId",
                  ]}
                  component={AccessReviewPerformView}
                />
                <Route
                  path="/templates/configurations/create"
                  component={CreateConfigTemplateV3}
                />
                <Route
                  path="/templates/configurations/:configurationTemplateId/edit"
                  component={EditConfigTemplateView}
                />
                <Route
                  path="/bundles/:bundleId/edit"
                  component={BundleEditView}
                />
                <Route path="/bundles/create" component={BundleCreateView} />
                <Route path="/request-access" component={AccessRequestView} />
                <Route
                  path="/bundles/:bundleId/add-resources"
                  component={BundleAddResourcesView}
                />
                <Route
                  path="/bundles/:bundleId/add-groups"
                  component={BundleAddGroupsView}
                />
                {hasOktaAliasing && (
                  <Route
                    path="/resources/:resourceId/add-resources"
                    component={OktaResourceAddResourcesView}
                  />
                )}
              </>
            )}
          </Switch>
        </FullscreenLayout>
      </Route>
      {hasInventory && (
        <CompatRoute
          key="add-app"
          path="/inventory/add-app"
          render={() => (
            <FullscreenLayout>
              <AppAddIntegrationView />
            </FullscreenLayout>
          )}
        />
      )}
      <Route path="/">
        <Layout>
          <Switch>
            <Route exact path="/user/settings" component={UserSettings} />
            {hasCustomRemediations && (
              <Route
                path="/home/custom-remediations"
                component={CustomRemediations}
              />
            )}
            {!hasV3Search && <Route path="/search" component={Search} />}
            {hasInventory && [
              <CompatRoute
                key="inventory-users"
                path="/inventory/users"
                render={() => <Inventory />}
              />,
              <CompatRoute
                key="inventory-nhis"
                path="/inventory/non-human-identities"
                render={() => <Inventory />}
              />,
              <CompatRoute
                key="inventory-owners"
                path="/inventory/owners"
                render={() => <Inventory />}
              />,
              <CompatRoute
                key="inventory-tags"
                path="/inventory/tags"
                render={() => <Inventory />}
              />,
              <CompatRoute
                key="inventory-bundles"
                path="/inventory/bundles/:bundleId"
                render={() => <BundleDetailColumnV3 />}
              />,
              <CompatRoute
                key="inventory-bundles"
                path="/inventory/bundles"
                render={() => <Inventory />}
              />,
              <CompatRoute
                key="appdetail"
                path="/inventory/:appId"
                render={() => {
                  return <AppDetailViewV3 />;
                }}
              />,
            ]}
            {leftSidebarRoutes}
            <Route path="/home" component={Homepage} />
            <Route path="/" component={NotFoundPage} />
          </Switch>
        </Layout>
      </Route>
    </Switch>
  );

  [
    VirtualTableContextProvider,
    NavigationContextProvider,
    SpotlightSearchProvider,
    AppsContextProvider,
    SetupContextProvider,
  ].forEach((Provider) => {
    contents = <Provider>{contents}</Provider>;
  });

  return contents;
};

export default AuthenticatedViews;
