import { Zoom } from "@visx/zoom";
import ZoomControls from "components/viz/ZoomControls";
import React from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import TopControlBar from "./TopControlBar";
import * as styles from "./ZoomingDragPane.css";

interface ZoomingDragPaneProps {
  children: React.ReactChild;
  width: number;
  height: number;
  onSort: () => void;
  sorted: boolean;
  onCollapse?: () => void;
}
const ZoomingDragPane = ({ children, width, height }: ZoomingDragPaneProps) => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  return (
    <Zoom<SVGSVGElement>
      width={width}
      height={height}
      scaleXMin={0.1}
      scaleXMax={2.0}
      scaleYMin={0.1}
      scaleYMax={2.0}
      pinchDelta={({ offset: [s], lastOffset: [lastS] }) => ({
        scaleX: s - lastS < 0 ? 0.96 : 1.04,
        scaleY: s - lastS < 0 ? 0.96 : 1.04,
      })}
      wheelDelta={(event) => {
        // Required for NodeMenu to update position correctly when panning graph.
        // The library we use for popovers only listens to 'scroll' and 'resize' events for auto updating position.
        // https://github.com/floating-ui/floating-ui/blob/11d058a17b923317648090515dfbc585bc13b7af/packages/dom/src/autoUpdate.ts#L65
        window.dispatchEvent(new Event("scroll"));

        if (event.metaKey) {
          // Default behavior from https://github.com/airbnb/visx/blob/master/packages/visx-zoom/src/Zoom.tsx#L33-L34
          return -event.deltaY > 0
            ? { scaleX: 1.04, scaleY: 1.04 }
            : { scaleX: 0.96, scaleY: 0.96 };
        } else {
          // Ignore default wheel gesture behavior (don't zoom, instead scroll in `onWheel` below)
          return { scaleX: 1, scaleY: 1 };
        }
      }}
    >
      {(zoom) => (
        <div
          className={styles.container}
          style={{
            backgroundPositionX: zoom.transformMatrix.translateX,
            backgroundPositionY: zoom.transformMatrix.translateY,
            // 384px should match the image size (./images/canvas.svg)
            backgroundSize: `${383 * zoom.transformMatrix.scaleX}px, ${
              381 * zoom.transformMatrix.scaleY
            }px`,
          }}
        >
          <svg
            className={styles.svg({ isDragging: zoom.isDragging })}
            ref={zoom.containerRef}
            onWheel={(e) => {
              e.stopPropagation();
              if (e.ctrlKey || e.metaKey) {
                // Pinch gesture/zoom gesture
                return;
              }
              // Two-finger scroll
              zoom.translate({
                translateX: -e.deltaX / zoom.transformMatrix.scaleX,
                // Dividing by scale keeps perceived "scroll velocity" the same across scales
                translateY: -e.deltaY / zoom.transformMatrix.scaleY,
              });
            }}
          >
            <rect className={styles.background} width={width} height={height} />
            <g
              // This seems considerably more performant than `zoom.toString()` which generates a matrix
              transform={`translate(${zoom.transformMatrix.translateX},${zoom.transformMatrix.translateY}) scale(${zoom.transformMatrix.scaleX})`}
            >
              {children}
            </g>
          </svg>
          {hasV3 ? (
            <TopControlBar
              zoom={zoom.transformMatrix.scaleX}
              onZoomIn={() =>
                zoom.setTransformMatrix({
                  ...zoom.transformMatrix,
                  scaleX: zoom.transformMatrix.scaleX + 0.2,
                  scaleY: zoom.transformMatrix.scaleY + 0.2,
                })
              }
              onZoomOut={() =>
                zoom.setTransformMatrix({
                  ...zoom.transformMatrix,
                  scaleX: zoom.transformMatrix.scaleX - 0.2,
                  scaleY: zoom.transformMatrix.scaleY - 0.2,
                })
              }
              onZoomReset={() => {
                zoom.reset();
              }}
            />
          ) : (
            <div className={styles.zoomControls}>
              <ZoomControls
                zoom={zoom.transformMatrix.scaleX}
                onZoomIn={() =>
                  zoom.setTransformMatrix({
                    ...zoom.transformMatrix,
                    scaleX: zoom.transformMatrix.scaleX + 0.2,
                    scaleY: zoom.transformMatrix.scaleY + 0.2,
                  })
                }
                onZoomOut={() =>
                  zoom.setTransformMatrix({
                    ...zoom.transformMatrix,
                    scaleX: zoom.transformMatrix.scaleX - 0.2,
                    scaleY: zoom.transformMatrix.scaleY - 0.2,
                  })
                }
                onZoomReset={() => {
                  zoom.reset();
                }}
              />
            </div>
          )}
        </div>
      )}
    </Zoom>
  );
};

export default ZoomingDragPane;
