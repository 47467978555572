import { EntityType, EventStreamFragment } from "api/generated/graphql";
import { EventStreamConnection } from "components/event_streaming/EventStreamConnection";
import { EventStreamMessagesTable } from "components/event_streaming/EventStreamMessagesTable";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import React from "react";

import * as styles from "./EventStreamConnections.css";

type EventStreamConnectionsProps = {
  eventStreams: EventStreamFragment[];
  setShowCreateModal: (show: boolean) => void;
};

export const EventStreamConnections = (props: EventStreamConnectionsProps) => {
  const eventStreams = props.eventStreams;
  const content =
    eventStreams.length > 0 ? (
      eventStreams.map((eventStream) => {
        return (
          <EventStreamConnection
            key={eventStream.id}
            eventStream={eventStream}
          />
        );
      })
    ) : (
      <div className={styles.emptyStateContainer}>
        <div className={styles.emptyStateTitle}>
          {"Event Streaming Connections"}
        </div>
        <div>Add connections to stream events to external systems</div>
      </div>
    );

  const handleCreateEventStreamConnection = () => {
    props.setShowCreateModal(true);
  };

  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
      })}
    >
      <div>
        <TableHeader
          entityType={EntityType.EventStreamConnection}
          totalNumRows={eventStreams.length}
          defaultRightActions={[
            {
              label: "Event Streaming Connection",
              type: "main",
              onClick: handleCreateEventStreamConnection,
              iconName: "plus",
              disabledTooltip:
                eventStreams.length >= 3
                  ? "You can only have 3 Event Streaming Connections"
                  : undefined,
            },
          ]}
        />
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
            gap: "xl",
          })}
        >
          {content}
        </div>
      </div>
      {eventStreams && eventStreams.length > 0 && (
        <EventStreamMessagesTable eventStreams={eventStreams} />
      )}
    </div>
  );
};
