import { SupportTicketPreviewFragment } from "api/generated/graphql";
import { SupportTicketLabel } from "components/label/Label";
import { TextHoverTooltip } from "components/more_info/MoreInfo";
import React from "react";
import styles from "views/access_reviews/revocations/AccessReviewRevocations.module.scss";

type AccessReviewRevocationsSupportTicketProps = {
  supportTicket?: SupportTicketPreviewFragment;
};

const AccessReviewRevocationsSupportTicket = (
  props: AccessReviewRevocationsSupportTicketProps
) => {
  if (!props.supportTicket) {
    return <></>;
  }
  return (
    <div className={styles.supportTicket}>
      <TextHoverTooltip
        tooltipText={`A ticket has been assigned to revoke this user's access on the end system. When the ticket is closed, the user will be marked as confirmed revoked.`}
      >
        Tracked by&nbsp;
        <SupportTicketLabel
          identifier={
            props.supportTicket.identifier || props.supportTicket.remoteId
          }
          url={props.supportTicket.url}
          thirdPartyProvider={props.supportTicket.thirdPartyProvider}
        />
      </TextHoverTooltip>
    </div>
  );
};

export default AccessReviewRevocationsSupportTicket;
