import { Maybe } from "api/generated/graphql";
import auth0 from "auth0-js";
import { Dispatch } from "react";
import { generateState } from "utils/auth/auth";
import { MfaCustomParams, setMfaData } from "utils/mfa/mfa";

export enum AuthResultStatus {
  Unauthorized = "unauthorized",
  LoginRequired = "login_required",
  ConsentRequired = "consent_required",
  AccessDenied = "access_denied",
  InvalidRequest = "invalid_request",
}

export enum Auth0Connection {
  Google = "google-oauth2",
  Microsoft = "azure-ad",
  Native = "Username-Password-Authentication",
}

export enum SignInErrorMessage {
  Google = "google-oauth2",
  Native = "Username-Password-Authentication",
}

export type Auth0Config = {
  domain: string;
  audience: string;
  clientId: string;
  redirectUri: string;
  logoutUri: string;
  nextLocalUrl?: Maybe<string>;
};

export class AuthClient {
  auth0: auth0.WebAuth;
  config: Auth0Config;

  constructor(auth0Config: Auth0Config) {
    this.config = auth0Config;

    let redirectUri = `${this.config.redirectUri}`;
    if (this.config.nextLocalUrl) {
      redirectUri = `${this.config.redirectUri}?next=${encodeURIComponent(
        this.config.nextLocalUrl
      )}`;
    }

    this.auth0 = new auth0.WebAuth({
      domain: this.config.domain,
      clientID: this.config.clientId,
      scope: "openid email profile",
      audience: this.config.audience,
      responseType: "code",
      redirectUri: redirectUri,
    });
  }

  mfaFlow = (nextUrl: string, params?: MfaCustomParams) => {
    const redirectUri = `${this.config.redirectUri}?next=${encodeURIComponent(
      nextUrl
    )}`;

    const state = generateState();
    setMfaData({
      state,
      params: params || {},
    });
    this.auth0.authorize({
      scope: "openid email profile mfa:required",
      state: state,
      redirectUri: redirectUri,
    });
  };

  socialSignIn = (
    state: string,
    forceExtraStep: boolean,
    nextUrl?: string,
    connection?: Auth0Connection
  ) => {
    const redirectUri = nextUrl
      ? `${this.config.redirectUri}?next=${encodeURIComponent(nextUrl)}`
      : this.config.redirectUri;
    const options: auth0.AuthorizeOptions = {
      connection: connection ?? Auth0Connection.Google,
      state: state,
      redirectUri: redirectUri,
    };

    // For local development we skip the select account prompt
    const isLocalOrDev =
      this.config.logoutUri.indexOf("localhost") >= 0 ||
      this.config.logoutUri.indexOf("dev.opal.dev") >= 0;
    if (!isLocalOrDev) {
      options.prompt = "select_account";
    }

    if (!forceExtraStep) {
      options.scope = "openid email profile mfa:skip";
    }

    this.auth0.authorize(options);
  };

  samlSignIn = (
    state: string,
    organizationId: string,
    forceExtraStep: boolean,
    nextUrl?: string
  ) => {
    const redirectUri = nextUrl
      ? `${this.config.redirectUri}?next=${encodeURIComponent(nextUrl)}`
      : this.config.redirectUri;
    let options = {
      connection: "saml-" + organizationId,
      state: state!,
      scope: "openid email profile",
      redirectUri: redirectUri,
    };

    if (!forceExtraStep) {
      options.scope = "openid email profile mfa:skip";
    }

    this.auth0.authorize(options);
  };

  socialSignUp = () => {
    this.auth0.authorize({
      connection: Auth0Connection.Google,
      mode: "signUp",
    });
  };

  emailSignIn = (
    state: string,
    email: Maybe<string>,
    password: Maybe<string>,
    setSubmissionErrorMessage: Dispatch<string>
  ) => {
    if (!email) {
      email = "";
    }

    if (!password) {
      password = "";
    }

    this.auth0.login(
      {
        email: email,
        password: password,
        realm: Auth0Connection.Native,
        state: state,
      },
      function (err: auth0.Auth0Error | null) {
        if (
          err?.error === AuthResultStatus.AccessDenied ||
          err?.error === AuthResultStatus.InvalidRequest
        ) {
          setSubmissionErrorMessage("Wrong email or password.");
        } else {
          setSubmissionErrorMessage("");
        }
      }
    );
  };

  signOut = (queryParams?: string) => {
    this.auth0.logout({
      returnTo: `${this.config.logoutUri}${queryParams}`,
      clientID: this.config.clientId,
    });
  };
}
export default AuthClient;
