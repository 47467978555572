import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from "@material-ui/core";
import {
  EntityType,
  Maybe,
  ReviewerUserFragment,
  ReviewerUserStatus,
} from "api/generated/graphql";
import { ResourceLabel } from "components/label/Label";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import { Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import React from "react";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import styles from "views/access_reviews/AccessReviewReviewerDetails.module.scss";

// This component is used as an expandable row in the virtual table, so it needs to
// tell it the render height of the expanded content
export const accessReviewReviewerDetailsRowHeight = (
  reviewers: Maybe<ReviewerUserFragment[]> | undefined
): number => {
  if (!reviewers) {
    return 96;
  }

  return 96 + reviewers.length * 32;
};

const StyledTableHeaderCell = withStyles({
  root: {
    borderBottom: "none",
    fontSize: "14px",
    fontWeight: "bold",
  },
})(TableCell);

const StyledTableCell = withStyles({
  root: {
    borderBottom: "none",
    fontSize: "14px",
  },
})(TableCell);

type AccessReviewReviewerDetailsProps = {
  reviewers: Maybe<ReviewerUserFragment[]> | undefined;
};

const AccessReviewReviewerDetails = (
  props: AccessReviewReviewerDetailsProps
) => {
  if (!props.reviewers) {
    return (
      <div className={styles.noReviewers}>No reviewers currently assigned</div>
    );
  }

  const hasRoleUpdate = _.some(
    props.reviewers,
    (reviewer) => !!reviewer.updatedAccessLevel?.accessLevelName
  );
  const hasResourceUpdate = _.some(
    props.reviewers,
    (reviewer) => !!reviewer.updatedResourceId
  );
  let resourceUpdateColumnName = "Updated Resource Access";

  const resourceType = props.reviewers
    .filter((reviewer) => !!reviewer.updatedResource)
    .map((reviewer) => reviewer.updatedResource?.resourceType)
    .shift();

  if (hasResourceUpdate && resourceType) {
    const resourceTypeInfo = resourceTypeInfoByType[resourceType];
    resourceUpdateColumnName = "Updated " + resourceTypeInfo.name;
  }

  return (
    <TableContainer>
      <Table aria-label="simple table" size="small">
        <TableHead>
          <TableRow>
            <StyledTableHeaderCell>Reviewer</StyledTableHeaderCell>
            <StyledTableHeaderCell>Decision</StyledTableHeaderCell>
            {hasRoleUpdate && (
              <StyledTableHeaderCell>Updated Role</StyledTableHeaderCell>
            )}
            {hasResourceUpdate && (
              <StyledTableHeaderCell>
                {resourceUpdateColumnName}
              </StyledTableHeaderCell>
            )}
            <StyledTableHeaderCell>Review Note</StyledTableHeaderCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.reviewers.map((reviewer) => (
            <TableRow>
              <StyledTableCell>
                <ResourceLabel
                  text={reviewer.name}
                  entityType={EntityTypeDeprecated.User}
                  avatar={reviewer.avatarUrl}
                  entityId={reviewer.userId}
                />
              </StyledTableCell>
              <StyledTableCell>
                <DecisionLabel status={reviewer.status} />
              </StyledTableCell>
              {hasRoleUpdate && (
                <StyledTableCell>
                  {reviewer.updatedAccessLevel?.accessLevelName}
                </StyledTableCell>
              )}
              {hasResourceUpdate && (
                <StyledTableCell>
                  <ResourceLabel
                    entityId={reviewer.updatedResource?.id}
                    serviceType={reviewer.updatedResource?.serviceType}
                    resourceType={reviewer.updatedResource?.resourceType}
                    text={reviewer.updatedResource?.name}
                    entityTypeNew={EntityType.Resource}
                  />
                </StyledTableCell>
              )}
              <StyledTableCell>{reviewer.note || "--"}</StyledTableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export const DecisionLabel: React.FC<{ status: ReviewerUserStatus }> = ({
  status,
}) => {
  switch (status) {
    case ReviewerUserStatus.NotStarted:
      return (
        <span
          className={sprinkles({
            display: "flex",
            gap: "xs",
            alignItems: "center",
          })}
        >
          <Icon name="minus-circle" color="gray600" size="xs" />
          No decision
        </span>
      );
    case ReviewerUserStatus.Accepted:
      return (
        <span
          className={sprinkles({
            display: "flex",
            gap: "xs",
            alignItems: "center",
            color: "green1000",
          })}
        >
          <Icon name="check-circle" color="green600" size="xs" />
          Approved
        </span>
      );
    case ReviewerUserStatus.NeedsEndSystemRevocation:
    case ReviewerUserStatus.Revoked:
      return (
        <span
          className={sprinkles({
            display: "flex",
            gap: "xs",
            alignItems: "center",
            color: "red1000",
          })}
        >
          <Icon name="x-circle" color="red600" size="xs" />
          Revoked
        </span>
      );
    case ReviewerUserStatus.NeedsUpdateRequestApproval:
    case ReviewerUserStatus.Updated:
      return (
        <span
          className={sprinkles({
            display: "flex",
            gap: "xs",
            alignItems: "center",
            color: "blue1000",
          })}
        >
          <Icon name="arrow-switch-3" color="blue600" size="xs" />
          Updated
        </span>
      );
    case ReviewerUserStatus.NotRequired:
      return (
        <span
          className={sprinkles({
            display: "flex",
            gap: "xs",
            alignItems: "center",
          })}
        >
          <Icon name="slash" color="gray600" size="xs" />
          No review required
        </span>
      );
  }
};

export default AccessReviewReviewerDetails;
