import { gql, useMutation, useQuery } from "@apollo/client";
import {
  HideFromEndUserModalQuery,
  IdpGroupMappingsUpdate,
  Maybe,
  UpdateIdpGroupMappingsMutation,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Modal } from "components/ui";
import { useState } from "react";
import { useParams } from "react-router";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  groupId?: string;
}

const QUERY = gql`
  query HideFromEndUserModal($resourceId: ResourceId!, $groupId: GroupId!) {
    resource(input: { id: $resourceId }) {
      ... on ResourceResult {
        resource {
          id
          name
        }
      }
      ... on ResourceNotFoundError {
        message
      }
    }
    group(input: { id: $groupId }) {
      ... on GroupResult {
        group {
          id
          name
        }
      }
      ... on GroupNotFoundError {
        message
      }
    }
    idpGroupMappings(appResourceId: $resourceId, groupIDs: [$groupId]) {
      id
      hiddenFromEndUser
    }
  }
`;

const MUTATION = gql`
  mutation UpdateIdpGroupMappings($input: IdpGroupMappingsUpdateInput!) {
    updateIdpGroupMappings(input: $input) {
      success
    }
  }
`;

export default ({ isOpen, onClose, groupId }: Props) => {
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { resourceId } = useParams<{ resourceId: string }>();
  const { displaySuccessToast } = useToast();
  const [updateIdpGroupMappings] = useMutation<UpdateIdpGroupMappingsMutation>(
    MUTATION
  );

  const { data, loading } = useQuery<HideFromEndUserModalQuery>(QUERY, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      resourceId: resourceId,
      groupId: groupId,
    },
    skip: !groupId,
  });

  const group =
    data?.group?.__typename == "GroupResult" ? data?.group.group : null;
  const app =
    data?.resource?.__typename == "ResourceResult"
      ? data?.resource.resource
      : null;
  const idpMapping = data?.idpGroupMappings[0];

  const toggleVisibility = async () => {
    const input: IdpGroupMappingsUpdate[] = [
      {
        idpGroupMappingId: idpMapping?.id ?? "",
        hiddenFromEndUser: !idpMapping?.hiddenFromEndUser,
      },
    ];

    const { data } = await updateIdpGroupMappings({
      variables: {
        input: {
          items: input,
        },
      },
      refetchQueries: ["AppResources"],
    });

    if (data?.updateIdpGroupMappings.success) {
      displaySuccessToast("Success: catalog visibility updated");
      onClose();
    } else {
      setErrorMessage("Error: failed updating catalog visiblity");
    }

    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`${idpMapping?.hiddenFromEndUser ? "Show" : "Hide"} Resource`}
    >
      <Modal.Body>
        {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
        <p>
          Are you sure you want to{" "}
          {idpMapping?.hiddenFromEndUser ? "show " : "hide "}
          <strong>{group?.name}</strong> from <strong>{app?.name}</strong> in
          the catalog?
        </p>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel={idpMapping?.hiddenFromEndUser ? "Show" : "Hide"}
        onPrimaryButtonClick={toggleVisibility}
        primaryButtonLoading={loading}
      />
    </Modal>
  );
};
