import { CircularProgress } from "@material-ui/core";
import opalLogoSpinner from "assets/spinners/opal-logo-small.gif";
import sprinkles from "css/sprinkles.css";
import * as React from "react";

import * as styles from "./Loader.css";

export interface Props {
  size?: "xs" | "sm" | "md" | "lg";
  color?: "black" | "white" | "inherit";
  label?: string;
  block?: boolean;
  opalLogo?: boolean;
}

const Loader: React.FC<Props> = (props) => {
  const { size = "sm", color = "black" } = props;
  const sizeMap = {
    xs: 12,
    sm: 16,
    md: 24,
    lg: 36,
  };

  if (props.opalLogo) {
    return (
      <div className={styles.loader}>
        <img
          src={opalLogoSpinner}
          alt="loading"
          height={sizeMap[size]}
          width={sizeMap[size]}
        />
        <span className={styles.loadingText}>{props.label}</span>
      </div>
    );
  }

  const loader = (
    <CircularProgress
      size={sizeMap[size]}
      className={sprinkles({
        color: color === "inherit" ? undefined : color,
      })}
      color="inherit"
    />
  );

  if (props.label || props.block) {
    return (
      <div
        className={sprinkles({
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          gap: "sm",
        })}
      >
        {loader}
        {props.label}
      </div>
    );
  }

  return loader;
};

export default Loader;
