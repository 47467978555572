import { DataElement } from "components/ui";

export type NotificationPeriodProps = {
  label: string;
  handleDelete?: () => void;
};

export const NotificationPeriod = ({
  label,
  handleDelete,
}: NotificationPeriodProps) => {
  return (
    <DataElement
      label={label}
      color="pink"
      leftIcon={{ name: "calendar" }}
      rightIcon={
        handleDelete && {
          name: "x",
          onClick: handleDelete,
        }
      }
    />
  );
};

export default NotificationPeriod;
