import { Badge, Checkbox, EntityIcon, Icon, Skeleton } from "components/ui";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import * as React from "react";

import * as styles from "./ColumnListItem.css";

export interface Props {
  label: string;
  sublabel?: string;
  icon?: IconData;
  brandIcon?: PropsFor<typeof Icon>["brandIcon"];
  largeIcon?: boolean;
  rightContent?: JSX.Element;
  type?:
    | "default"
    | "loader"
    | "success"
    | "info"
    | "danger"
    | "terminal"
    | "settings";
  active?: boolean;
  selected?: boolean;
  disabled?: boolean;
  inactive?: boolean;
  innerButton?: {
    label: string;
    iconName: PropsFor<typeof Icon>["name"];
    onClick: () => void;
  };
  onClick?: React.MouseEventHandler;
  checkbox?: {
    checked: boolean;
    onChange: (checked: boolean) => void;
    onChangeShift?: (checked: boolean) => void;
  };
  count?: number;
  badgeColor?: PropsFor<typeof Badge>["color"];
  sublabelColor?: "gray" | "red";
}

const ColumnListItem: React.FC<Props> = (props) => {
  const {
    type = "default",
    active = false,
    selected = false,
    disabled = false,
    inactive = false,
    badgeColor,
    sublabelColor = "gray",
  } = props;

  const renderIcon = () => {
    if (!props.icon) return null;

    if (props.icon.type === "name") {
      return (
        <div
          className={styles.icon({
            size: props.largeIcon ? "lg" : undefined,
            inactive,
          })}
        >
          <Icon
            name={props.icon.icon}
            brandIcon={props.brandIcon}
            size={props.largeIcon ? "sm" : "xs"}
            iconStyle={props.icon.style}
          />
        </div>
      );
    }

    if (props.icon.type === "src") {
      return (
        <div
          className={styles.icon({
            size: props.largeIcon ? "lg" : undefined,
            inactive,
          })}
        >
          <Icon
            data={props.icon}
            brandIcon={props.brandIcon}
            size={props.largeIcon ? "sm" : "xs"}
            iconStyle={props.icon.style}
          />
        </div>
      );
    }

    if (props.icon.type === "entity") {
      return (
        <div
          className={styles.icon({
            size: props.largeIcon ? "lg" : undefined,
            inactive,
          })}
        >
          <EntityIcon
            type={props.icon.entityType}
            size={props.largeIcon ? "md" : "sm"}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <button
      className={styles.container({ type, selected, active, disabled })}
      onClick={disabled ? undefined : props.onClick}
      disabled={disabled}
      data-test-id={props.label}
    >
      {props.checkbox ? (
        <div className={sprinkles({ marginRight: "xs" })}>
          <Checkbox
            checked={props.checkbox.checked}
            onChange={props.checkbox.onChange}
            onChangeShift={props.checkbox.onChangeShift}
            disabled={disabled}
          />
        </div>
      ) : null}
      {renderIcon()}
      <div className={styles.labelContainer}>
        <div className={styles.label({ inactive })} title={props.label}>
          {props.label}
        </div>
        <div
          className={styles.sublabel({ active, color: sublabelColor })}
          title={props.sublabel}
        >
          {props.sublabel}
        </div>
      </div>
      {props.innerButton && !disabled ? (
        <button
          className={styles.innerButton}
          onClick={props.innerButton.onClick}
        >
          <Icon name={props.innerButton.iconName} size="xxs" color="blue800" />
          <span className={styles.innerButtonLabel}>
            {props.innerButton.label}
          </span>
        </button>
      ) : null}
      {props.count != null ? (
        <Badge count={props.count} color={badgeColor} size="xs" />
      ) : null}
      {props.rightContent ? (
        <div
          className={sprinkles({
            flexShrink: 0,
            gap: "xs",
            display: "flex",
            alignItems: "center",
          })}
        >
          {props.rightContent}
        </div>
      ) : null}
      {type === "default" ? (
        <div className={styles.icon()}>
          <Icon
            name="chevron-right"
            size="xs"
            color={active ? "white" : "gray400"}
          />
        </div>
      ) : null}
    </button>
  );
};

export const ColumnListItemsSkeleton = (props: { numItems?: number }) => {
  const { numItems = 10 } = props;
  return (
    <>
      {_.times(numItems, (i) => (
        <div className={styles.container()} key={i}>
          <Skeleton width="100%" height="20px" variant="rect" />
        </div>
      ))}
    </>
  );
};

export default ColumnListItem;
