import {
  AccessReviewItemOutcome,
  AccessReviewItemStatus,
} from "api/generated/graphql";
import { Icon } from "components/ui";
import Popover from "components/ui/popover/PopoverV3";
import sprinkles from "css/sprinkles.css";

import * as styles from "./StatusCell.css";

interface Props {
  outcome: AccessReviewItemOutcome;
  status: AccessReviewItemStatus;
  notes?: {
    name: string;
    note: string;
  }[];
}

const renderPopover = (notes: { name: string; note: string }[]) => {
  return (
    <div className={styles.popoverContents}>
      {notes.map(({ name, note }) => (
        <div
          className={sprinkles({
            display: "flex",
            flexDirection: "column",
          })}
        >
          <span
            className={sprinkles({
              color: "gray700",
              fontWeight: "normal",
              fontSize: "textXs",
            })}
          >
            {name}
          </span>
          <span
            className={sprinkles({
              color: "gray800",
              fontWeight: "medium",
              fontSize: "textXs",
            })}
          >
            {note}
          </span>
        </div>
      ))}
    </div>
  );
};

const StatusCell = ({ outcome, status, notes }: Props) => {
  if (outcome === AccessReviewItemOutcome.Accepted) {
    return (
      <span
        className={sprinkles({
          display: "flex",
          gap: "xs",
          alignItems: "center",
          color: "green1000",
        })}
      >
        <Icon name="check-circle" color="green600" size="xs" />
        Approved
      </span>
    );
  }
  if (outcome === AccessReviewItemOutcome.Updated) {
    return (
      <span
        className={sprinkles({
          display: "flex",
          gap: "xs",
          alignItems: "center",
          color: "blue1000",
        })}
      >
        <Icon name="arrow-switch-3" color="blue600" size="xs" />
        Updated
      </span>
    );
  }
  if (outcome === AccessReviewItemOutcome.Revoked) {
    return (
      <div
        className={sprinkles({
          display: "flex",
          gap: "md",
        })}
      >
        <span
          className={sprinkles({
            display: "flex",
            gap: "xs",
            alignItems: "center",
            color: "red1000",
          })}
        >
          <Icon name="x-circle" color="red600" size="xs" />
          Revoked
        </span>
        {notes && notes.length > 0 && (
          <Popover content={renderPopover(notes)} position="top">
            <Icon name="message" size="md" color="blue700" />
          </Popover>
        )}
      </div>
    );
  }
  if (
    outcome === AccessReviewItemOutcome.None &&
    status === AccessReviewItemStatus.PartiallyCompleted
  ) {
    return (
      <span
        className={sprinkles({
          display: "flex",
          gap: "xs",
          alignItems: "center",
          color: "yellow1000",
        })}
      >
        <Icon name="incomplete" color="yellow700" size="xs" />
        Incomplete
      </span>
    );
  }
  return null;
};

export default StatusCell;
