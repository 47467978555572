import {
  EventFilterFields,
  EventFilterFragment,
  EventType,
} from "api/generated/graphql";
import _ from "lodash";
import moment from "moment";
import { useLocation } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

type FilterInfo = {
  value: string | EventType[];
  applied: boolean;
  displayName: string;
  urlParam: string;
};

type FilterInfos = {
  [key: string]: FilterInfo;
};

enum FilterType {
  StartDate = "START_DATE",
  EndDate = "END_DATE",
  Actor = "ACTOR",
  EventType = "EVENT_TYPE",
  Object = "OBJECT",
  Service = "SERVICE",
  Tag = "TAG",
  Manager = "MANAGER",
  SystemEvents = "SYSTEM_EVENTS",
  ApiToken = "API_TOKEN",
}

export const useFilterInfos = (): FilterInfos => {
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);

  const makeFilterInfo = (displayName: string, urlParam: string) => {
    return {
      value: urlParams.get(urlParam) || "",
      applied: !!urlParams.get(urlParam),
      displayName: displayName,
      urlParam: urlParam,
    };
  };

  const filterInfos: FilterInfos = {
    [FilterType.StartDate]: makeFilterInfo("Start Date", "startDate"),
    [FilterType.EndDate]: makeFilterInfo("End Date", "endDate"),
    [FilterType.Actor]: makeFilterInfo("Actor", "actor"),
    [FilterType.EventType]: makeFilterInfo("Event Type", "eventTypes"),
    [FilterType.Object]: makeFilterInfo("Object", "objects"),
    [FilterType.SystemEvents]: makeFilterInfo("System Events", "systemEvents"),
    [FilterType.ApiToken]: makeFilterInfo("API Token", "apiToken"),
  };

  return filterInfos;
};

export const useEventFilter = (): EventFilterFields => {
  const filterInfos = useFilterInfos();

  const eventFilter: EventFilterFields = {};
  _.each(filterInfos, (filterInfo, filterType) => {
    if (filterInfo.applied) {
      if (filterType === FilterType.StartDate) {
        eventFilter.startDate = {
          date: filterInfo.value as string,
        };
      } else if (filterType === FilterType.EndDate) {
        eventFilter.endDate = {
          date: filterInfo.value as string,
        };
      } else if (filterType === FilterType.Actor) {
        eventFilter.actors = {
          userId: filterInfo.value as string,
        };
      } else if (filterType === FilterType.EventType) {
        eventFilter.eventTypes = {
          eventTypes: (filterInfo.value as string).split(",") as EventType[],
        };
      } else if (filterType === FilterType.Object) {
        eventFilter.objects = {
          objectId: String(filterInfo.value),
        };
      } else if (filterType === FilterType.SystemEvents) {
        eventFilter.systemEvents = true;
      } else if (filterType === FilterType.ApiToken) {
        const [
          apiTokenLabel,
          apiTokenPreview,
        ] = (filterInfo.value as string).split(":");
        eventFilter.apiTokens = {
          apiTokenLabel: apiTokenLabel,
          apiTokenPreview: apiTokenPreview,
        };
      }
    }
  });

  return eventFilter;
};

// When passing end date filter to backend query, pad the date by
// 1 day to get a complete day's worth of events.
export const getEventFilterEndDateInput = (endDate?: string) => {
  if (!endDate) {
    return undefined;
  }
  return moment(endDate, "YYYY-MM-DD").add(1, "days").format("YYYY-MM-DD");
};

export const buildFilterURLParams = (eventFilter: EventFilterFields) => {
  const {
    startDate,
    endDate,
    actors,
    eventTypes,
    objects,
    systemEvents,
    apiTokens,
  } = eventFilter;

  const params = new URLSearchParams();
  if (startDate?.date) {
    params.append("startDate", startDate.date);
  }
  if (endDate?.date) {
    params.append("endDate", endDate.date);
  }
  if (actors?.userId) {
    params.append("actor", actors.userId);
  }
  if (eventTypes?.eventTypes?.length) {
    const eventTypesParam = eventTypes.eventTypes.join(",");
    params.append("eventTypes", eventTypesParam);
  }
  if (objects?.objectId) {
    params.append("objects", objects.objectId);
  }
  if (systemEvents) {
    params.append("systemEvents", "true");
  }
  if (apiTokens?.apiTokenLabel && apiTokens?.apiTokenPreview) {
    params.append(
      "apiToken",
      apiTokens.apiTokenLabel + ":" + apiTokens.apiTokenPreview
    );
  }

  return params;
};

export const createUrlParamsFromEventFilter = (
  eventFilter: EventFilterFragment
) => {
  const urlParams = new URLSearchParams();
  if (eventFilter.startDate) {
    urlParams.set("startDate", eventFilter.startDate);
  }
  if (eventFilter.endDate) {
    urlParams.set("endDate", eventFilter.endDate);
  }
  if (eventFilter.actor) {
    urlParams.set("actor", eventFilter.actor.id);
  }
  if (eventFilter.objectId) {
    urlParams.set("objects", eventFilter.objectId);
  }
  if (eventFilter.eventTypes) {
    urlParams.set("eventTypes", eventFilter.eventTypes.toString());
  }
  if (eventFilter.systemEvents) {
    urlParams.set("systemEvents", eventFilter.systemEvents.toString());
  }
  if (eventFilter.apiTokenLabel && eventFilter.apiTokenPreview) {
    urlParams.set(
      "apiToken",
      eventFilter.apiTokenLabel + ":" + eventFilter.apiTokenPreview
    );
  }
  return "?" + urlParams.toString();
};

export const useEventTypes = (): Array<EventType> => {
  const disallowedEventTypes = new Set<EventType>();
  if (!useFeatureFlag(FeatureFlag.EventStreaming)) {
    disallowedEventTypes.add(EventType.EventStreamConnectionsTested);
    disallowedEventTypes.add(EventType.EventStreamConnectionsCreated);
    disallowedEventTypes.add(EventType.EventStreamConnectionsUpdated);
    disallowedEventTypes.add(EventType.EventStreamConnectionsDeleted);
  }

  return Object.values(EventType).filter((et) => !disallowedEventTypes.has(et));
};
