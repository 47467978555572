import { ConnectionType, ResourceType } from "api/generated/graphql";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";

export const getResourceSublabel = (resource: {
  resourceType: ResourceType;
  parentResource?: { name: string | null } | null;
}) => {
  let subText = getResourceTypeInfo(resource.resourceType)?.fullName;
  const parentName = resource.parentResource?.name;
  if (parentName) {
    subText = `${subText} • ${parentName}`;
  }
  return subText;
};

type CurrentUserResourceAccess = {
  resourceUsers: {
    access?: {
      directAccessPoint?: {
        expiration?: string | null;
      } | null;
    } | null;
  }[];
  pendingRequests: {
    requestedResources: {
      accessLevel: {
        accessLevelRemoteId: string;
      };
    }[];
  }[];
};

export function getResourcePendingRequestRoleRemoteIds(
  currentUserAccess: CurrentUserResourceAccess | undefined
): Set<string> {
  return (
    currentUserAccess?.pendingRequests.reduce((acc, request) => {
      request.requestedResources.forEach((requestedResource) => {
        acc.add(requestedResource.accessLevel.accessLevelRemoteId);
      });

      return acc;
    }, new Set<string>()) ?? new Set<string>()
  );
}

export function resourceHasForfeitableRole(
  currentUserAccess: CurrentUserResourceAccess | undefined
): boolean {
  return (
    currentUserAccess?.resourceUsers.some(
      (resourceUser) => resourceUser.access?.directAccessPoint
    ) ?? false
  );
}

export const formatResourceBreadcrumb = (
  breadcrumb: string | undefined | null,
  length?: number | null,
  connectionName?: string
): string => {
  if (!breadcrumb || breadcrumb.length === 0) {
    if (connectionName) {
      return `${connectionName}/`;
    }
    return "";
  }
  const crumbs = breadcrumb.split(" / ");
  // Remove the last crumb which is the resource name
  crumbs.pop();

  if (length == null) {
    if (connectionName) {
      return `${[connectionName, ...crumbs].join("/")}/`;
    }
    return `${crumbs.join("/")}/`;
  }

  let totalLength = connectionName ? connectionName.length + 1 : 0;
  let left = 0,
    right = crumbs.length - 1;
  while (left < right) {
    totalLength += crumbs[right].length + 1;
    if (totalLength > length) {
      break;
    }
    right--;
    totalLength += crumbs[left].length + 1;
    if (totalLength > length) {
      break;
    }
    left++;
  }
  let crumbVal = crumbs
    .map((crumb, index) => {
      if (index < left) {
        return crumb;
      }
      if (left < right && index >= left && index <= right) {
        return "..";
      }
      return crumb;
    })
    .join("/");

  // if connectionName and crumbVal exist join them, otherwise handle forward slash based on existence
  if (connectionName && crumbVal) {
    return `${connectionName}/${crumbVal}/`;
  }

  if (connectionName) {
    return `${connectionName}/`;
  }

  if (crumbVal) {
    return `${crumbVal}/`;
  }

  return "";
};

/**
 * Whether this connection type uses a nested resource structure.
 */
export const connectionTypeCanHaveBreadcrumb = (
  connectionType: ConnectionType | null | undefined
) => {
  switch (connectionType) {
    case ConnectionType.AwsSso:
    case ConnectionType.Gcp:
    case ConnectionType.AzureAd:
    case ConnectionType.Snowflake:
      return true;
    default:
      return false;
  }
};
