import Checkbox from "@material-ui/core/Checkbox";
import React from "react";

export enum CheckboxState {
  Unselected,
  Checked,
  Indeterminate,
}

type ThreeStateCheckboxProps = {
  checkboxState: CheckboxState;
  setCheckboxState: (checkboxState: CheckboxState) => void;
};

const ThreeStateCheckbox = (props: ThreeStateCheckboxProps) => {
  return (
    <Checkbox
      checked={props.checkboxState === CheckboxState.Checked}
      indeterminate={props.checkboxState === CheckboxState.Indeterminate}
      onChange={() => {
        switch (props.checkboxState) {
          case CheckboxState.Unselected:
            props.setCheckboxState(CheckboxState.Checked);
            break;
          case CheckboxState.Checked:
            props.setCheckboxState(CheckboxState.Indeterminate);
            break;
          case CheckboxState.Indeterminate:
            props.setCheckboxState(CheckboxState.Unselected);
            break;
        }
      }}
    />
  );
};

export default ThreeStateCheckbox;
