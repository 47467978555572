import { Badge, Icon, Loader, Tooltip } from "components/ui";
import * as React from "react";

import * as styles from "./Button.css";

export interface Props {
  type?: "default" | "primary" | "warning" | "error" | "success";
  size?: "sm" | "md" | "lg";
  label?: string;
  tooltip?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  disabledTooltip?: string;
  loading?: boolean;
  leftIconName?: PropsFor<typeof Icon>["name"];
  rightIconName?: PropsFor<typeof Icon>["name"];
  badge?: PropsFor<typeof Badge>;
  flatEdge?: "left" | "right";
  borderless?: boolean;
  outline?: boolean;
  fullWidth?: boolean;
  submit?: boolean;

  /* In order for the button to be a round, icon-only button:
     leftIconName must be set, label is not set, and round = true */
  round?: boolean;
}

const Button: React.FC<Props> = (props) => {
  const { type = "default" } = props;

  const isRound = Boolean(props.leftIconName && !props.label && props.round);
  const button = (
    <button
      className={styles.button({
        type,
        outlineType: props.outline ? type : undefined,
        borderlessType: props.borderless ? type : undefined,
        size: props.size || "md",
        disabled: props.disabled,
        isRound,
        flatEdge: props.flatEdge,
        fullWidth: props.fullWidth,
      })}
      disabled={props.disabled}
      onClick={props.onClick}
      type={props.submit ? "submit" : undefined}
      data-testid={props.label}
    >
      {props.leftIconName && (
        <span
          className={styles.icon({
            withLabel: props.label !== undefined,
            side: "left",
            borderlessType: props.borderless ? type : undefined,
            loading: props.loading,
          })}
        >
          <Icon
            name={props.leftIconName}
            size={props.size === "lg" ? "sm" : "xs"}
          />
        </span>
      )}
      <span className={styles.text({ loading: props.loading })}>
        {props.label}
      </span>
      {props.rightIconName && (
        <span
          className={styles.icon({
            withLabel: props.label !== undefined,
            side: "right",
            borderlessType: props.borderless ? type : undefined,
          })}
        >
          <Icon
            name={props.rightIconName}
            size={props.size === "lg" ? "sm" : "xs"}
          />
        </span>
      )}
      {props.badge && (
        <span className={styles.badge}>
          <Badge {...props.badge} />
        </span>
      )}
      {props.loading && (
        <span className={styles.loadingSpinner}>
          <Loader size={props.size === "lg" ? "md" : "xs"} color="inherit" />
        </span>
      )}
    </button>
  );

  if (props.disabled && props.disabledTooltip) {
    return <Tooltip tooltipText={props.disabledTooltip}>{button}</Tooltip>;
  } else if (props.tooltip) {
    return <Tooltip tooltipText={props.tooltip}>{button}</Tooltip>;
  }

  return button;
};

export default Button;
