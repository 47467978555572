import { EntityIcon, Icon, Label } from "components/ui";

import * as styles from "./DataElement.css";

type IconData = Omit<PropsFor<typeof Icon>, "size">;
interface DataElementProps {
  label: string;
  color:
    | "pink"
    | "violet"
    | "navy"
    | "blue"
    | "turquoise"
    | "teal"
    | "green"
    | "yellow"
    | "orange"
    | "red"
    | "gray";
  leftIcon?: IconData;
  rightIcon?: IconData;
  size?: "sm" | "md";
  bold?: boolean;
  disableHover?: boolean;
  link?: string;
  allowedToWrap?: boolean;
  truncateLength?: PropsFor<typeof Label>["truncateLength"];
  onClick?: () => void;
  oneLine?: boolean;
}

const DataElement = (props: DataElementProps) => {
  const {
    label,
    bold = true,
    size = "md",
    disableHover,
    allowedToWrap,
    oneLine,
  } = props;

  let numIcons = 0;
  if (props.leftIcon) {
    numIcons += 1;
  }
  if (props.rightIcon) {
    numIcons += 1;
  }
  // Need to convert to strings to key style variants by.
  // Typescript doesn't seem to properly type unions of numbers.
  let numIconsString: "one" | "two" | undefined = undefined;
  if (numIcons === 1) {
    numIconsString = "one";
  } else if (numIcons === 2) {
    numIconsString = "two";
  }

  const renderIcon = (icon: IconData) => {
    if (!icon.data) {
      return <Icon {...icon} size="xs" />;
    }

    if (icon.data.type === "name") {
      return <Icon name={icon.data.icon} size="xs" />;
    }

    if (icon.data.type === "src") {
      return <Icon data={icon.data} size="xs" />;
    }

    if (icon.data.type === "entity") {
      return <EntityIcon type={icon.data.entityType} size="md" separate />;
    }

    return null;
  };

  return (
    <span
      className={styles.dataElement({
        color: props.color,
        bold,
        size,
        disableHover,
        clickable: Boolean(props.onClick),
        allowedToWrap: allowedToWrap ?? false,
        oneLine,
      })}
      onClick={props.onClick}
    >
      {props.leftIcon && (
        <span className={styles.leftIcon({ color: props.color })}>
          {renderIcon(props.leftIcon)}
        </span>
      )}
      <div className={styles.text({ oneLine, numIcons: numIconsString })}>
        <Label
          label={label}
          linkTo={props.link}
          truncateLength={props.truncateLength}
          oneLine={oneLine}
        />
      </div>
      {props.rightIcon && (
        <span className={styles.rightIcon({ color: props.color })}>
          {renderIcon(props.rightIcon)}
        </span>
      )}
    </span>
  );
};

export default DataElement;
