import {
  IntegrationType,
  ThirdPartyIntegrationFragment,
  useThirdPartyIntegrationsQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import ColumnListItem from "components/column/ColumnListItem";
import { Divider } from "components/ui";
import { useContext } from "react";
import { useHistory, useLocation } from "react-router";
import { usePageTitle } from "utils/hooks";
import { logError } from "utils/logging";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ViewSkeleton from "views/loading/ViewSkeleton";

import OrgContext from "../settings/OrgContext";
import AccountSettings from "./AccountSettings";
import NotificationPreferences from "./NotificationPreferences";
import UserIntegrations from "./UserIntegrations";
import { categoryInfos, UserSettingsCategory } from "./UserSettings";

const UserSettingsCategoryColumn = () => {
  const history = useHistory();
  const { hash } = useLocation();
  const { orgState } = useContext(OrgContext);
  usePageTitle("User Settings");

  let orgIntegrations: ThirdPartyIntegrationFragment[] =
    orgState.orgThirdPartyIntegrations || [];
  let userIntegrations: ThirdPartyIntegrationFragment[] = [];

  const { data, error, loading } = useThirdPartyIntegrationsQuery({
    variables: {
      input: {
        integrationType: IntegrationType.User,
      },
    },
  });
  if (data) {
    userIntegrations = data.thirdPartyIntegrations.thirdPartyIntegrations;
  } else if (error) {
    logError(error, `failed to get third party integrations`);
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  let content;
  if (loading) {
    content = <ViewSkeleton />;
  } else {
    switch (hash) {
      case "#notification-preferences":
        content = (
          <NotificationPreferences
            categoryInfo={
              categoryInfos[UserSettingsCategory.NotificationPreferences]
            }
            integrations={orgIntegrations}
          />
        );
        break;
      case "#identities":
        content = (
          <UserIntegrations
            categoryInfo={categoryInfos[UserSettingsCategory.IdentitySettings]}
            integrations={userIntegrations}
            disabled={Boolean(error)}
          />
        );
        break;
      default:
        content = (
          <AccountSettings
            categoryInfo={categoryInfos[UserSettingsCategory.AccountSettings]}
            orgIntegrations={orgIntegrations || []}
          />
        );
    }
  }

  return (
    <>
      <Column>
        <ColumnHeader
          title="User Settings"
          icon={{ type: "name", icon: "settings" }}
        />
        <Divider />
        <ColumnListItem
          key="account-settings"
          label="Account Settings"
          onClick={() => history.push("#account-settings")}
          selected={hash === "#account-settings" || !hash}
        />
        <ColumnListItem
          key="notification-preferences"
          label="Notification Preferences"
          onClick={() => history.push("#notification-preferences")}
          selected={hash === "#notification-preferences"}
        />
        <ColumnListItem
          key="identities"
          label="Identities"
          onClick={() => history.push("#identities")}
          selected={hash === "#identities"}
        />
      </Column>
      {content ? <Column isContent>{content}</Column> : null}
    </>
  );
};

export default UserSettingsCategoryColumn;
