import {
  AccessReviewTemplateFragment,
  Maybe,
  useAccessReviewTemplateQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import { useParams } from "react-router";
import { logError, logWarning } from "utils/logging";
import { NotFoundPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import CreateAccessReviewScheduleColumn from "./create/CreateAccessReviewScheduleColumn";

const AccessReviewScheduleViewer = () => {
  const { accessReviewTemplateId } = useParams<Record<string, string>>();

  const { data, error, loading } = useAccessReviewTemplateQuery({
    variables: {
      input: {
        accessReviewTemplateId: accessReviewTemplateId,
      },
    },
    skip: !accessReviewTemplateId || accessReviewTemplateId === "new",
  });

  let accessReviewTemplate: Maybe<AccessReviewTemplateFragment> = null;
  let isLoading: boolean = false;
  if (accessReviewTemplateId && accessReviewTemplateId !== "new") {
    if (data) {
      switch (data.accessReviewTemplate.__typename) {
        case "AccessReviewTemplateResult":
          accessReviewTemplate =
            data.accessReviewTemplate.accessReviewTemplate || null;
          break;
        case "AccessReviewTemplateNotFound":
          logWarning(new Error("error: access review schedule not found"));
          return <NotFoundPage />;
        default:
          logWarning(new Error("error: access review schedule not found"));
      }
    } else if (error) {
      logError(error, `failed to retrieve access review schedule`);
    }
    isLoading = loading;
  }

  if (isLoading) {
    return (
      <Column isContent maxWidth="lg">
        <ColumnContentSkeleton />
      </Column>
    );
  }

  return (
    <CreateAccessReviewScheduleColumn
      templateId={accessReviewTemplate?.id}
      name={accessReviewTemplate?.name}
      reviewerAssignmentPolicy={accessReviewTemplate?.reviewerAssignmentPolicy}
      timeZone={accessReviewTemplate?.timeZone}
      reminderSchedule={accessReviewTemplate?.reminderSchedule}
      reminderTimeOfDay={
        accessReviewTemplate
          ? new Date(accessReviewTemplate.reminderTimeOfDay)
          : undefined
      }
      reminderIncludeManager={accessReviewTemplate?.reminderIncludeManager}
      accessReviewDuration={accessReviewTemplate?.accessReviewDuration}
      startDayOfMonth={accessReviewTemplate?.startDayOfMonth}
      sendReviewerAssignmentNotifications={
        accessReviewTemplate?.sendReviewerAssignmentNotification
      }
      monthSchedule={accessReviewTemplate?.monthSchedule}
      selfReviewAllowed={accessReviewTemplate?.selfReviewAllowed}
      filters={accessReviewTemplate?.filters ?? undefined}
      includeGroupBindings={accessReviewTemplate?.includeGroupBindings ?? false}
      groupResourceVisibilityPolicy={
        accessReviewTemplate?.groupResourceVisibilityPolicy
      }
    />
  );
};

export default AccessReviewScheduleViewer;
