import {
  ConnectionType,
  CurrentUserGroupAccessFragment,
  EntityType,
  EventType,
  FactorType,
  GroupType,
  IdpConnectionType,
  Maybe,
  ResourceType,
  ServiceType,
  SupportTicketPreviewFragment,
  ThirdPartyProvider,
  useTicketQuery,
} from "api/generated/graphql";
import githubLogo from "assets/logos/github-logo.png";
import gitlabLogo from "assets/logos/gitlab-logo.svg";
import googleLogo from "assets/logos/google-logo.svg";
import jiraLogo from "assets/logos/jira-logo.png";
import linearLogo from "assets/logos/linear-logo.png";
import microsoftEntraIdLogo from "assets/logos/microsoft-entra-id-logo.svg";
import oktaLogo from "assets/logos/okta-logo.png";
import oktaRemoteGroupIcon from "assets/logos/okta-remote-group-icon.svg";
import oktaVerifyIcon from "assets/logos/okta-verify-icon.png";
import onCallGroupLogo from "assets/logos/on-call-group-icon.svg";
import opsgenieLogo from "assets/logos/opsgenie-logo.png";
import pagerDutyLogo from "assets/logos/pager-duty-logo.png";
import serviceNowLogo from "assets/logos/servicenow-icon.png";
import unknownLogo from "assets/logos/unknown-logo.png";
import workdayLogo from "assets/logos/workday-logo.png";
import clsx from "clsx";
import AuthContext from "components/auth/AuthContext";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import UserLabel from "components/label/item_labels/UserLabel";
import styles from "components/label/Label.module.scss";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { useToast } from "components/toast/Toast";
import { EntityIcon, Icon as IconNew, Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import moment from "moment";
import pluralize from "pluralize";
import React, { ReactElement, useContext } from "react";
import * as Icons from "react-feather";
import { Link } from "react-router-dom";
import { useRestrictUserViewingEntity } from "utils/auth/auth";
import { capitalize, getResourceUrl, getResourceUrlNew } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { SupportTicketTooltip } from "views/support_tickets/SupportTickets";

const strokeWidthDefault = 2;

export const timeValueIndefinite = Math.pow(10, 10) - 1;

export enum TimeValue {
  Indefinite = "Indefinite",
}

export enum ChipValue {
  Low,
  Medium,
  High,
}

export enum TooltipPlacement {
  TopStart = "top-start",
  Top = "top",
  TopEnd = "top-end",
  LeftStart = "left-start",
  Left = "left",
  LeftEnd = "left-end",
  RightStart = "right-start",
  Right = "right",
  RightEnd = "right-end",
  BottomStart = "bottom-start",
  Bottom = "bottom",
  BottomEnd = "bottom-end",
}

type ResourceIconProps = {
  icon?: Maybe<string>;
};

export const ResourceIcon = (props: ResourceIconProps) => {
  return (
    <div className={styles.resourceIcon}>
      <img src={props.icon || undefined} alt="" />
    </div>
  );
};

type ResourceLabelProps = {
  text?: string | null;
  subText?: string | null;
  entityType?: EntityTypeDeprecated | null;
  entityTypeNew?: EntityType | null;
  entityId?: string | null;
  avatar?: string;
  url?: string;
  pointerCursor?: boolean;
  email?: string;
  resourceType?: ResourceType;
  groupType?: GroupType;
  maxChars?: number | "auto";
  tooltipText?: string | null;
  tooltipPlacement?: TooltipPlacement;
  serviceType?: ServiceType;
  connectionType?: Maybe<ConnectionType>;
  eventType?: EventType;
  bold?: boolean;
  strikeThrough?: boolean;
  additionalStyleClass?: string;
  icon?: string | ReactElement | null;
  iconLarge?: boolean;
  hideIcon?: boolean;
  warningIcon?: ReactElement;
  warningTooltipText?: string;
  target?: string;
  inactive?: boolean;
};

export const ResourceLabel = (props: ResourceLabelProps) => {
  const { authState } = useContext(AuthContext);

  // Make links unclickable if the user can't view the entity
  const disallowLink = useRestrictUserViewingEntity(props.entityTypeNew);

  let url = props.url;
  if (props.inactive || disallowLink) {
    url = undefined;
  } else if (props.entityId && !url) {
    if (props.entityTypeNew && props.entityId) {
      url = getResourceUrlNew(
        {
          entityId: props.entityId,
          entityType: props.entityTypeNew,
        },
        undefined
      );
    } else {
      url = getResourceUrl(
        props.entityType || EntityTypeDeprecated.Resource,
        props.entityId
      );
    }
  }

  let text = props.text;
  if (!props.text || props.text === "") {
    text = props.entityId?.substr(0, 7) || "--";
  }

  let pointerCursor = disallowLink ? false : props.pointerCursor;
  let icon = undefined;
  let iconTooltipText = null;
  let nakedIcon = false;
  switch (props.entityType) {
    case EntityTypeDeprecated.Resource:
      if (props.resourceType) {
        icon = <EntityIcon type={props.resourceType} />;
        nakedIcon = true;
      } else {
        icon = <Icons.Box strokeWidth={strokeWidthDefault} />;
      }
      break;
    case EntityTypeDeprecated.Group:
      icon = <IconNew name="users" />;
      break;
    case EntityTypeDeprecated.Request:
      icon = <IconNew name="inbox" />;
      break;
    case EntityTypeDeprecated.Service:
    case EntityTypeDeprecated.ResourceService:
    case EntityTypeDeprecated.ConnectionService:
      icon = getConnectionTypeInfo(props.connectionType)?.icon;
      break;
    case EntityTypeDeprecated.Observation:
      icon = <Icons.Activity strokeWidth={strokeWidthDefault} />;
      break;
    case EntityTypeDeprecated.Connection:
      icon = <Icons.Layers strokeWidth={strokeWidthDefault} />;
      iconTooltipText =
        "An app is a credential set used to access a third-party system.";

      // Non-admins can't look at connections, we make them non-clickable here
      if (!authState.user?.isAdmin) {
        url = undefined;
        pointerCursor = false;
      }
      break;
    case EntityTypeDeprecated.Event:
      icon = <Icons.Activity strokeWidth={strokeWidthDefault} />;
      break;
    case EntityTypeDeprecated.User:
      return (
        <UserLabel
          name={text}
          subText={props.subText}
          avatar={props.avatar}
          bold={props.bold}
          large={props.iconLarge}
          url={url}
          email={props.email}
          warningIcon={props.warningIcon}
          warningTooltipText={props.warningTooltipText}
          pointerCursor={props.pointerCursor}
          inactive={props.inactive}
        />
      );
  }

  switch (props.entityTypeNew) {
    case EntityType.AccessReviewResource:
      icon = <Icons.Box strokeWidth={strokeWidthDefault} />;
      break;
    case EntityType.AccessReviewGroup:
      icon = <Icons.Users strokeWidth={strokeWidthDefault} />;
      break;
    case EntityType.AccessReviewConnection:
      icon = <Icons.Layers strokeWidth={strokeWidthDefault} />;
      break;
    case EntityType.Resource:
      if (props.resourceType) {
        icon = <EntityIcon type={props.resourceType} />;
        nakedIcon = true;
      } else if (props.icon && typeof props.icon === "string") {
        icon = <Icon icon={props.icon} iconWide={true} />;
        nakedIcon = true;
      } else {
        icon = <Icons.Box strokeWidth={strokeWidthDefault} />;
      }
      break;
    case EntityType.Group:
    case EntityType.OnCallGroup:
      if (props.groupType) {
        icon = <EntityIcon type={props.groupType} />;
        nakedIcon = true;
      } else {
        icon = <Icons.Users strokeWidth={strokeWidthDefault} />;
      }
      break;
    case EntityType.ResourceFolder:
    case EntityType.GroupFolder:
      icon = <Icons.Folder strokeWidth={strokeWidthDefault} />;
      break;
    case EntityType.Request:
      icon = <IconNew name="inbox" />;
      break;
    case EntityType.Tag:
      icon = <Icons.Tag strokeWidth={strokeWidthDefault} />;
      break;
    case EntityType.Connection:
      if (props.connectionType) {
        icon = <EntityIcon type={props.connectionType} />;
        nakedIcon = true;
      } else {
        icon = <IconNew name="layers" />;
      }
      break;
    case EntityType.Event:
      icon = <Icons.Activity strokeWidth={strokeWidthDefault} />;
      break;
    case EntityType.AccessReview:
    case EntityType.AccessReviewTemplate:
      icon = <Icons.CheckSquare strokeWidth={strokeWidthDefault} />;
      break;
    case EntityType.User:
      return (
        <UserLabel
          name={text}
          subText={props.subText}
          avatar={props.avatar}
          bold={props.bold}
          large={props.iconLarge}
          url={url}
          warningIcon={props.warningIcon}
          warningTooltipText={props.warningTooltipText}
          pointerCursor={pointerCursor}
          inactive={props.inactive}
          target={props.target}
          hideAvatar={props.hideIcon}
        />
      );
    case EntityType.Session:
      url = undefined; // disable the link since we do not currently support displaying sessions
      break;
    case EntityType.AuthToken:
      icon = <Icons.Disc strokeWidth={strokeWidthDefault} />;
      url = undefined; // disable the link since we do not currently support displaying sessions
      break;
    case EntityType.AccessLevel:
      icon = <Icons.Key strokeWidth={strokeWidthDefault} />;
      break;
    case EntityType.OnCallSchedule:
      icon = <Icons.Clipboard strokeWidth={strokeWidthDefault} />;
      break;
    case EntityType.EventFilter:
      icon = <Icons.Filter strokeWidth={strokeWidthDefault} />;
      break;
    case EntityType.Owner:
      icon = <IconNew name="user-square" />;
      break;
    case EntityType.Bundle:
      icon = <IconNew name="package" />;
      break;
  }

  let finalIcon: string | ReactElement | null | undefined = icon;
  if (props.icon && !props.connectionType) {
    finalIcon = props.icon;
    nakedIcon = false;
  }

  if (props.text?.includes("remote group")) {
    finalIcon = oktaRemoteGroupIcon;
  }
  if (props.hideIcon) {
    finalIcon = undefined;
  }

  return (
    <TruncatedLabel
      text={text}
      subText={props.subText}
      icon={finalIcon}
      iconLarge={props.iconLarge}
      nakedIcon={nakedIcon}
      additionalStyleClass={props.additionalStyleClass}
      url={url}
      pointerCursor={pointerCursor}
      maxChars={props.maxChars}
      tooltipText={props.tooltipText}
      tooltipPlacement={props.tooltipPlacement}
      bold={props.bold}
      strikeThrough={props.strikeThrough}
      iconTooltipText={iconTooltipText}
      rightIcon={props.warningIcon}
      rightIconTooltipText={props.warningTooltipText}
      target={props.target}
      inactive={props.inactive}
    />
  );
};

export const TruncatedLabel = (props: LabelProps) => {
  let { text, subText, tooltipText, maxChars = 50, ...labelProps } = props;

  if (!props.inactive) {
    if (maxChars !== "auto" && subText && subText.length > maxChars) {
      tooltipText = subText;
      subText = _.truncate(subText, {
        length: maxChars,
      });
    }

    // NOTE: We check the text length after the subtext
    //       so that if both text and subtext were truncated
    //       we give the tooltip preference to the text
    if (maxChars !== "auto" && text && text.length > maxChars) {
      tooltipText = text;
      text = _.truncate(text, {
        length: maxChars,
      });
    }
  }

  return (
    <Label
      text={text}
      subText={subText}
      tooltipText={tooltipText}
      maxChars={maxChars}
      {...labelProps}
    />
  );
};

type SupportTicketLabelViaHTTPProps = {
  supportTicketId: String;
};

export const SupportTicketLabelViaHTTP = (
  props: SupportTicketLabelViaHTTPProps
) => {
  const { data } = useTicketQuery({
    variables: {
      input: {
        id: props.supportTicketId.toString(),
      },
    },
    skip: !props.supportTicketId,
  });
  if (!props.supportTicketId) {
    return null;
  }
  if (data) {
    switch (data.supportTicket.__typename) {
      case "SupportTicketResult":
        return (
          <SupportTicketLabel
            identifier={
              data.supportTicket.ticket.identifier ||
              data.supportTicket.ticket.remoteId
            }
            url={data.supportTicket.ticket.url}
            thirdPartyProvider={data.supportTicket.ticket.thirdPartyProvider}
          />
        );
      default:
        break;
    }
  }
  return <>Failed to Load Ticket</>;
};

type SupportTicketLabelProps = {
  inline?: boolean;
  identifier: string;
  url: string;
  thirdPartyProvider: ThirdPartyProvider;
};

export const SupportTicketLabel = (props: SupportTicketLabelProps) => {
  const logo = getLogoByThirdPartyProvider(props.thirdPartyProvider);

  return (
    <span
      className={sprinkles({
        display: props.inline ? "inline-flex" : "flex",
        alignItems: "center",
        gap: "xs",
      })}
    >
      <IconNew
        data={{ type: "src", icon: logo }}
        size={props.inline ? "entitySm" : "md"}
      />
      <a href={props.url} target="_blank" rel="noreferrer noopener">
        {props.identifier}
      </a>
    </span>
  );
};

export const formatDuration = (durationInMinutes: Maybe<moment.Duration>) => {
  if (durationInMinutes != null && durationInMinutes.asMilliseconds() === 0) {
    return "0";
  }
  if (!durationInMinutes) {
    return "Indefinite";
  }

  // Math below was returning "2 months, 29 days" which seems wrong
  if (durationInMinutes.asMinutes() === 129600) {
    return "90 days";
  }

  // override moment's conversion system to ensure that 1 year = 365 days
  const numDays = durationInMinutes.asDays();
  let numYears = null;
  if (numDays >= 365) {
    numYears = Math.floor(numDays / 365);
  }

  let duration = durationInMinutes;

  // if the durationInMinutes is at least 365 days
  // then we need to create a new duration by subtracting the numYears * 365 days from the original duration
  if (numYears !== null) {
    const newDuration = moment.duration(durationInMinutes.asMinutes(), "m");
    newDuration.subtract(numYears * 365, "days");
    duration = newDuration;
  }

  const expirationValueFragments = [
    {
      value: numYears === null ? durationInMinutes.years() : numYears,
      valueType: "year",
    },
    { value: duration.months(), valueType: "month" },
    { value: duration.days(), valueType: "day" },
    { value: duration.hours(), valueType: "hour" },
    { value: duration.minutes(), valueType: "minute" },
  ];

  return expirationValueFragments
    .map(({ value, valueType }) => {
      return value > 0 ? `${value} ${pluralize(valueType, value)}` : null;
    })
    .filter((expirationValueFragmentString) => {
      return expirationValueFragmentString !== null;
    })
    .join(", ");
};

type ItemTypeLabelProps = {
  itemType: string;
  resourceType?: ResourceType;
  groupType?: GroupType;
};

export const ItemTypeLabel = (props: ItemTypeLabelProps) => {
  let icon = null;

  if (props.resourceType) {
    const info = getResourceTypeInfo(props.resourceType);
    icon = <Icon icon={info?.icon} />;
  } else if (props.groupType) {
    const info = getGroupTypeInfo(props.groupType);
    icon = <Icon icon={info?.icon} />;
  }

  return icon === null ? (
    <Label text={props.itemType} />
  ) : (
    <Label icon={icon} text={props.itemType} />
  );
};

type LabelProps = {
  text?: string | null;
  url?: string | null;
  externalUrl?: string | null;
  // undefined specifies a nonexistent icon (adds no indentation)
  // null specifies a transparent icon (adds indentation)
  icon?: string | ReactElement | null;
  iconLarge?: boolean;
  connectionType?: ConnectionType;
  nakedIcon?: boolean;
  iconTooltipText?: string | null;
  tooltipText?: string | null;
  tooltipPlacement?: TooltipPlacement;
  subText?: string | null;
  rightIcon?: ReactElement;
  rightIconTooltipText?: string;
  additionalStyleClass?: string;
  pointerCursor?: boolean;
  // Fixed number of characters to truncate text to.
  // Pass in "auto" to truncate based on label container's width.
  maxChars?: number | "auto";
  bold?: boolean;
  italic?: boolean;
  strikeThrough?: boolean;
  // clickToCopy when true lets user click on label to copy full text
  // useful when text is long and is truncated
  clickToCopy?: boolean;
  target?: string;
  inactive?: boolean;
};

export const Label = (props: LabelProps) => {
  const { displaySuccessToast } = useToast();

  let hasManagedClickBehavior = !!(
    props.clickToCopy ||
    props.url ||
    props.externalUrl
  );

  const handleClick = React.useCallback(() => {
    if (props.clickToCopy) {
      navigator.clipboard.writeText(props.text ?? "");
      displaySuccessToast("Copied to clipboard");
    }
  }, [props.text, props.clickToCopy, displaySuccessToast]);

  const defaultTooltipPlacement = TooltipPlacement.Bottom;
  const tooltipPlacement = props.tooltipPlacement || defaultTooltipPlacement;

  let text = props.text;
  if (props.maxChars !== "auto") {
    text = _.truncate(props.text || "", {
      length: props.maxChars || 300,
    });
  }

  let iconContent = props.icon;
  if (!props.nakedIcon) {
    iconContent = <Icon icon={props.icon} iconLarge={props.iconLarge} />;
  }
  if (props.iconTooltipText) {
    iconContent = (
      <Tooltip tooltipText={props.iconTooltipText} placement={tooltipPlacement}>
        <div>{iconContent}</div>
      </Tooltip>
    );
  }

  if (
    props.connectionType &&
    Object.values(ConnectionType).includes(props.connectionType)
  ) {
    iconContent = <EntityIcon type={props.connectionType} />;
  }

  let labelTextInnerContent: JSX.Element = <>{text}</>;
  if (props.url) {
    labelTextInnerContent = (
      <Link
        to={props.url}
        target={props.target}
        onClick={(e) => e.stopPropagation()}
      >
        {text}
      </Link>
    );
  } else if (props.externalUrl) {
    labelTextInnerContent = (
      <a
        href={props.externalUrl}
        target={"_blank"}
        onClick={(e) => e.stopPropagation()}
      >
        {text}
      </a>
    );
  }

  let labelTextContent = (
    <div
      className={clsx({
        [styles.labelText]: props.icon !== undefined,
        [styles.labelTextNoIcon]: props.icon === undefined,
        [styles.labelTextAutoTruncate]: props.maxChars === "auto",
        [styles.bold]: props.bold,
        [styles.strikeThrough]: props.strikeThrough,
        [styles.italic]: props.italic,
        [styles.inactive]: props.inactive,
      })}
      title={!props.tooltipText ? props.text ?? undefined : undefined}
    >
      {labelTextInnerContent}
    </div>
  );

  let rightIconContent = props.rightIcon;
  if (props.rightIconTooltipText) {
    rightIconContent = (
      <Tooltip
        tooltipText={props.rightIconTooltipText}
        placement={tooltipPlacement}
      >
        {rightIconContent}
      </Tooltip>
    );
  }

  const labelContent = props.subText ? (
    <>
      {iconContent}
      <div
        style={
          props.maxChars === "auto"
            ? {
                width: "100%",
                overflow: "hidden",
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }
            : {
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }
        }
      >
        {labelTextContent}
        <span
          style={{ fontSize: "12px", marginLeft: "6px", color: "#808080" }}
          title={props.subText ?? undefined}
        >
          {props.subText}
        </span>
      </div>
    </>
  ) : (
    <>
      {iconContent}
      {labelTextContent}
      {rightIconContent ? (
        <div className={sprinkles({ marginLeft: "xs" })}>
          {rightIconContent}
        </div>
      ) : null}
    </>
  );

  const label = (
    <div
      className={clsx({
        [styles.label]: true,
        [styles.labelAutoWidth]: props.maxChars === "auto",
        [props.additionalStyleClass || ""]: props.additionalStyleClass,
        [styles.defaultCursor]: !props.pointerCursor,
        [styles.pointerCursor]: props.pointerCursor || props.clickToCopy,
      })}
      onClick={handleClick}
    >
      {labelContent}
    </div>
  );

  let tooltipText: string = "";
  if (props.tooltipText) {
    tooltipText = props.tooltipText;
  } else if (props.clickToCopy) {
    tooltipText = "Click to copy";
  }

  // if there is tooltips text for the label text, wrap it in a tooltips
  return tooltipText ? (
    <Tooltip tooltipText={tooltipText} placement={tooltipPlacement}>
      <div
        onClick={(e) => {
          if (hasManagedClickBehavior) {
            e.stopPropagation();
          }
        }}
      >
        {label}
      </div>
    </Tooltip>
  ) : (
    <div
      onClick={(e) => {
        if (hasManagedClickBehavior) {
          e.stopPropagation();
        }
      }}
    >
      {label}
    </div>
  );
};

type IconProps = {
  // undefined is basically a nonexistent icon (doesn't preserve indentation)
  // null is basically a transparent icon (preserves indentation)
  icon?: string | ReactElement | null;
  iconLarge?: boolean;
  iconWide?: boolean;
};

/**
 * @deprecated Use Icon from components/ui
 */
export const Icon = (props: IconProps) => {
  let component: ReactElement = <></>;
  if (props.icon !== undefined) {
    if (typeof props.icon === "string") {
      // Image icon
      component = (
        <div
          className={clsx({
            [styles.imgIcon]: !props.iconLarge && !props.iconWide,
            [styles.imgIconLarge]: props.iconLarge && !props.iconWide,
            [styles.imgIconWide]: props.iconWide && !props.iconLarge,
          })}
        >
          <img src={props.icon || undefined} alt="" />
        </div>
      );
    } else {
      // SVG icon
      component = (
        <div
          className={clsx({
            [styles.svgIcon]: !props.iconLarge,
            [styles.svgIconLarge]: props.iconLarge,
          })}
        >
          {props.icon}
        </div>
      );
    }
  }
  return component;
};

export enum CurrentUserGroupAccessStatus {
  Unauthorized,
  Authorized,
  Requested,
}

export const currentUserGroupAccessStatusFromGroup = (group: {
  currentUserAccess: CurrentUserGroupAccessFragment;
}) => {
  const currentUserAccess = group.currentUserAccess;
  if (!currentUserAccess) {
    return CurrentUserGroupAccessStatus.Unauthorized;
  }

  if (currentUserAccess.groupUser) {
    return CurrentUserGroupAccessStatus.Authorized;
  } else if (currentUserAccess.pendingRequest) {
    return CurrentUserGroupAccessStatus.Requested;
  }
  return CurrentUserGroupAccessStatus.Unauthorized;
};

export const getHumanReadableTimeLabel = (
  time: string,
  icon?: string | ReactElement | null,
  pointerCursor?: boolean
) => {
  // Extract appropriate time values to account for Indefinite/None/JIRA
  // TODO: Handle JIRA case
  let timeValue = Math.pow(10, 10);
  let timeDisplay = "--";
  if (moment(new Date(time)).isValid()) {
    timeValue = moment(new Date(time)).unix();
    timeDisplay = capitalize(moment(new Date(time)).fromNow());
  } else if (time === TimeValue.Indefinite) {
    timeValue -= 1;
    timeDisplay = "Indefinite";
  }

  return {
    timeLabel: (
      <Label text={timeDisplay} icon={icon} pointerCursor={pointerCursor} />
    ),
    timeValue: timeValue,
  };
};

export type TimeLabelProps = {
  time: Maybe<moment.Moment>;
  supportTicket?: Maybe<SupportTicketPreviewFragment>;
  icon?: string | ReactElement | null;
  url?: string | null;
  useExpiringLabel?: boolean;
  isOncallAccess?: boolean;
};

export const TimeLabel = (props: TimeLabelProps) => {
  if (props.isOncallAccess && !props.time) {
    return (
      <div>
        <Tooltip
          tooltipText={`The user's access to this group is tied to an on-call rotation and will expire once the user is not on-call anymore.`}
        >
          <Icon icon={onCallGroupLogo} />
        </Tooltip>
      </div>
    );
  }

  let displayText = props.time
    ? _.upperFirst(props.time.fromNow())
    : "Permanent Access";
  const isExpired = moment().isAfter(props.time);
  if (props.time && props.useExpiringLabel && isExpired) {
    displayText = "Expiring...";
  }

  if (props.supportTicket) {
    displayText += " or ";
  }

  return (
    <div className={styles.label}>
      {props.useExpiringLabel && isExpired ? (
        <div className={styles.labelTextNoIcon}>
          <Tooltip
            tooltipText={`The user's access to this item has expired. Their access will be removed from the end system during the next sync run`}
          >
            {displayText}
          </Tooltip>
        </div>
      ) : (
        <div className={styles.labelTextNoIcon}>
          {props.url ? <Link to={props.url}>{displayText}</Link> : displayText}
        </div>
      )}
      {props.supportTicket && (
        <>
          &nbsp;
          <SupportTicketTooltip supportTicket={props.supportTicket} />
        </>
      )}
      <div className={styles.labelText}>
        <Icon icon={props.icon} />
      </div>
    </div>
  );
};

export const getFormattedTimestampLabel = (
  date: string,
  icon?: string | ReactElement | null
) => {
  let dateValue = 0;
  let dateDisplay = "--";
  if (moment(date).isValid()) {
    dateValue = moment(date).unix();
    dateDisplay = moment(date).toString();
  }

  return {
    dateLabel: <Label text={dateDisplay} icon={icon} />,
    dateValue: dateValue,
  };
};

const logoByThirdPartyProvider: Record<string, string | null> = {
  [ThirdPartyProvider.Auth0]: null,
  [ThirdPartyProvider.Jira]: jiraLogo,
  [ThirdPartyProvider.GitHub]: githubLogo,
  [ThirdPartyProvider.Slack]: null,
  [ThirdPartyProvider.Opsgenie]: opsgenieLogo,
  [ThirdPartyProvider.PagerDuty]: pagerDutyLogo,
  [ThirdPartyProvider.ServiceNow]: serviceNowLogo,
  [ThirdPartyProvider.Linear]: linearLogo,
  [ThirdPartyProvider.GitLab]: gitlabLogo,
};

export const getLogoByThirdPartyProvider = (
  thirdPartyProvider?: ThirdPartyProvider | null
) => {
  return thirdPartyProvider
    ? logoByThirdPartyProvider[thirdPartyProvider] || ""
    : "";
};

const logoByIdpConnectionType: Record<IdpConnectionType, string> = {
  [IdpConnectionType.Google]: googleLogo,
  [IdpConnectionType.Okta]: oktaLogo,
  [IdpConnectionType.AzureAd]: microsoftEntraIdLogo,
  [IdpConnectionType.Workday]: workdayLogo,
};

export const getLogoByIdpConnectionType = (
  idpConnectionType?: IdpConnectionType | null
) => {
  return idpConnectionType
    ? logoByIdpConnectionType[idpConnectionType] || ""
    : unknownLogo;
};

const logoByMfaType: Record<FactorType, string> = {
  [FactorType.OktaPush]: oktaVerifyIcon,
  [FactorType.OktaTotp]: oktaVerifyIcon,
};

export const getLogoByMfaType = (mfaType?: FactorType | null) => {
  return mfaType ? logoByMfaType[mfaType] || "" : "";
};

const nameByMfaType: Record<FactorType, string> = {
  [FactorType.OktaPush]: "Okta Verify Push",
  [FactorType.OktaTotp]: "Okta Verify TOTP",
};

export const getNameByMfaType = (mfaType: FactorType) => {
  return nameByMfaType[mfaType];
};

const nameByIdpConnectionType: Record<IdpConnectionType, string> = {
  [IdpConnectionType.Google]: "Google Identity Provider",
  [IdpConnectionType.Okta]: "Okta Identity Provider",
  [IdpConnectionType.AzureAd]: "Microsoft Entra ID Identity Provider",
  [IdpConnectionType.Workday]: "Workday HRIS",
};

export const getNameByIdpConnectionType = (
  idpConnectionType?: IdpConnectionType | null
) => {
  return idpConnectionType
    ? nameByIdpConnectionType[idpConnectionType] || ""
    : "";
};

type charRelativeWidthsMap = {
  [key: string]: number;
};

// Based on visual observations: font: Inter, font-size: 14
const charRelativeWidths: charRelativeWidthsMap = {
  A: 379,
  B: 364,
  C: 407,
  D: 403,
  E: 335,
  F: 329,
  G: 416,
  H: 414,
  I: 148,
  J: 304,
  K: 365,
  L: 315,
  M: 498,
  N: 422,
  O: 426,
  P: 356,
  Q: 426,
  R: 358,
  S: 357,
  T: 360,
  U: 415,
  V: 379,
  W: 531,
  X: 360,
  Y: 372,
  Z: 350,
  a: 316,
  b: 348,
  c: 313,
  d: 348,
  e: 326,
  f: 202,
  g: 341,
  h: 331,
  i: 133,
  j: 133,
  k: 305,
  l: 133,
  m: 487,
  n: 328,
  o: 334,
  p: 341,
  q: 341,
  r: 208,
  s: 293,
  t: 204,
  u: 325,
  v: 312,
  w: 455,
  x: 302,
  y: 312,
  z: 303,
  "1": 260,
  "2": 339,
  "3": 356,
  "4": 360,
  "5": 340,
  "6": 349,
  "7": 331,
  "8": 345,
  "9": 349,
  "0": 350,
  "-": 258,
  _: 253,
  "!": 156,
  "@": 524,
  "#": 353,
  $: 357,
  "%": 455,
  "^": 263,
  "&": 358,
  "*": 280,
  "(": 203,
  ")": 203,
  "+": 369,
  "=": 369,
  "`": 278,
  "~": 369,
  "{": 203,
  "}": 203,
  "[": 203,
  "]": 203,
  "\\": 172,
  "|": 183,
  ";": 157,
  ":": 154,
  '"': 226,
  "'": 124,
  "<": 369,
  ">": 369,
  ",": 157,
  ".": 154,
  "?": 284,
  "/": 200,
  " ": 157,
};

// Get the approximate text width before rendering a label
export const getApproximateTextWidth = (text: string) => {
  const sizeFactor = 40; // character widths are for 40 chars

  let width = 0;
  for (let i = 0; i < text.length; i++) {
    let charWidth = charRelativeWidths[text.charAt(i)] / sizeFactor;
    if (!charWidth) {
      charWidth = 350 / sizeFactor;
    }
    width += charWidth;
  }

  return width;
};

// Get the number of characters that the allowedWidth can fit, for better truncation
export const getNumberOfCharsForWidth = (
  text: string,
  allowedWidth: number
) => {
  const sizeFactor = 40; // character widths are for 40 chars

  let textWidth = 0;
  let numChars = 0;
  for (let i = 0; i < text.length; i++) {
    let charWidth = charRelativeWidths[text.charAt(i)] / sizeFactor;
    if (!charWidth) {
      charWidth = 350 / sizeFactor;
    }
    if (textWidth + charWidth > allowedWidth) {
      return numChars;
    }
    textWidth += charWidth;
    numChars++;
  }

  return numChars;
};

export default Label;
