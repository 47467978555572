import { Tooltip } from "components/ui";
import React from "react";
import { HTMLProps } from "react";

export default function OpalTableCheckbox({
  indeterminate,
  disabledTooltip,
  ...rest
}: {
  indeterminate?: boolean;
  disabledTooltip?: string;
} & HTMLProps<HTMLInputElement>) {
  const ref = React.useRef<HTMLInputElement>(null!);

  React.useEffect(() => {
    if (typeof indeterminate === "boolean") {
      ref.current.indeterminate = !rest.checked && indeterminate;
    }
  }, [ref, indeterminate, rest.checked]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Tooltip tooltipText={disabledTooltip}>
        <input
          type="checkbox"
          ref={ref}
          {...rest}
          style={{ display: "block" }}
        />
      </Tooltip>
    </div>
  );
}
