import { Banner, Select } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { isEqual } from "lodash";
import { useState } from "react";

import { UsageRange } from "../common";
import { useFilterDispatch, useFilterState } from "../contexts/FilterContext";
import FilterHeader from "./FilterHeader";

const usageOptions: Array<{
  label: string;
  value: UsageRange;
}> = [
  {
    label: "Over 90 days ago",
    value: {
      minDays: 90,
    },
  },
  {
    label: "Within the last 90 days",
    value: {
      maxDays: 90,
    },
  },
  {
    label: "Within the last 60 days",
    value: {
      maxDays: 60,
    },
  },
  {
    label: "Within the last 30 days",
    value: {
      maxDays: 30,
    },
  },
  {
    label: "No data",
    value: {
      noData: true,
    },
  },
];

const UsageFilter = () => {
  const [expanded, setExpanded] = useState(false);

  const { usage } = useFilterState();
  const filterDispatch = useFilterDispatch();

  const handleFilterChange = (usageRange?: UsageRange) => {
    filterDispatch({
      type: "UPDATE_USAGE_FILTER",
      payload: {
        value: usageRange,
      },
    });
  };

  return (
    <>
      <FilterHeader
        title="Usage"
        description="How often resources are used"
        expanded={expanded}
        onClick={() => setExpanded((prev) => !prev)}
        active={usage != null}
      />
      {expanded ? (
        <>
          <span
            className={sprinkles({ marginBottom: "sm", fontSize: "bodySm" })}
          >
            <Banner
              message="We currently only show usage data for Okta apps, initiated AWS sessions, and AWS IDC Roles and Groups. More data coming soon!"
              type="info"
              noIcon
            />
          </span>
          <Select
            options={usageOptions}
            value={usageOptions.find((opt) => isEqual(opt.value, usage))}
            onChange={(opt) => handleFilterChange(opt?.value ?? undefined)}
            getOptionLabel={(option) => option.label}
            clearable
          />
        </>
      ) : null}
    </>
  );
};

export default UsageFilter;
