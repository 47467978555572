import {
  ConnectionType,
  EntityType,
  GroupType,
  ResourceType,
} from "api/generated/graphql";
import ItemTypeLabel from "components/label/ItemTypeLabel";
import { ResourceLabel } from "components/label/Label";
import sprinkles from "css/sprinkles.css";

import * as styles from "./AccessReviewEntityCard.css";

const AccessReviewEntityCard = ({
  entityId,
  entityType,
  name,
  role,
  description,
  itemType,
  nameAccessory,
}: {
  entityId: string;
  entityType: EntityType.Resource | EntityType.Group | EntityType.Connection;
  name: string;
  role?: string;
  description: string;
  itemType?: ResourceType | GroupType | ConnectionType;
  nameAccessory?: JSX.Element;
}) => {
  const resourceType =
    entityType === EntityType.Resource ? (itemType as ResourceType) : undefined;
  const groupType =
    entityType === EntityType.Group ? (itemType as GroupType) : undefined;
  const connectionType =
    entityType === EntityType.Connection
      ? (itemType as ConnectionType)
      : undefined;
  return (
    <div className={styles.container}>
      <div className={styles.name}>
        <div className={sprinkles({ display: "flex", gap: "sm" })}>
          <ResourceLabel
            text={name}
            entityId={entityId}
            entityTypeNew={entityType}
            resourceType={resourceType}
            groupType={groupType}
            connectionType={connectionType}
            hideIcon={true}
            target="_blank"
          />
          {nameAccessory}
        </div>
      </div>
      {role && <div className={styles.role}>{role}</div>}
      {itemType && <ItemTypeLabel itemType={itemType} />}
      <div className={styles.description}>{description}</div>
    </div>
  );
};

export default AccessReviewEntityCard;
