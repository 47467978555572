import {
  OwnerFragment,
  useOwnerQuery,
  useUpdateOwnerMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import { Banner, Button, Divider, Tabs } from "components/ui";
import { IconName } from "components/ui/icon/Icon";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import EventsTable from "views/events/EventsTable";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import { FormMode, OwnerConfig } from "../../components/forms/common";
import { OwnersConfigForm } from "../../components/forms/OwnersConfigForm";
import {
  makeConfigForOwner,
  validateOwnerConfig,
} from "../../components/forms/utils";
import { useToast } from "../../components/toast/Toast";
import OwnerDeleteModal from "./owner_viewer/OwnerDeleteModal";
import OwnerGroupsTable from "./owner_viewer/OwnerGroupsTable";
import OwnerRenameModal from "./owner_viewer/OwnerRenameModal";
import OwnerResourcesTable from "./owner_viewer/OwnerResourcesTable";
import OwnerUsersColumn from "./OwnerUsersColumn";

interface OwnerView {
  key: string;
  title: string;
  iconName: IconName;
  content: JSX.Element;
  count?: number;
}

const OwnerDetailColumn = () => {
  const { authState } = useContext(AuthContext);
  const { ownerId } = useParams<Record<string, string>>();
  const history = useHistory();
  const location = useLocation();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [formMode, setFormMode] = useState<FormMode>("view");
  const [config, setConfig] = useState<Partial<OwnerConfig>>({});
  const [initialConfig, setInitialConfig] = useState<Partial<OwnerConfig>>({});
  const { displaySuccessToast } = useToast();
  const [errors, setErrors] = useState<string[]>([]);

  const selectedView = location.hash.slice(1) || "overview";

  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);

  const { data, error, loading } = useOwnerQuery({
    variables: { input: { id: ownerId } },
    skip: !ownerId,
  });

  const [update, { loading: updateLoading }] = useUpdateOwnerMutation();

  let owner: OwnerFragment | undefined = undefined;
  if (data) {
    switch (data.owner.__typename) {
      case "OwnerResult":
        owner = data.owner.owner;
        break;
      case "OwnerNotFoundError":
        break;
      default:
        logError(new Error(`failed to list owner`));
    }
  } else if (error) {
    logError(error, `failed to list owner`);
  }

  useEffect(() => {
    if (owner) {
      setConfig(makeConfigForOwner(owner));
      setInitialConfig(makeConfigForOwner(owner));
    }
  }, [ownerId, owner]);

  if (loading) {
    return (
      <>
        <Column isContent maxWidth="lg">
          <ColumnContentSkeleton />
        </Column>
      </>
    );
  }

  if (error) {
    logError(error, "failed to get owner");
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  if (!owner) {
    return (
      <Column isContent>
        <NotFoundPage />;
      </Column>
    );
  }

  const handleSave = async () => {
    const errors = validateOwnerConfig(config);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }

    try {
      setErrors([]);
      const { data } = await update({
        variables: {
          input: {
            ownerId: ownerId,
            accessRequestEscalationPeriodInMinutes: {
              int: config.escalationDurationMin,
            },
            description: config.description,
            reviewerMessageChannelId: {
              channelId: config.reviewerMessageChannel?.id,
            },
            sourceGroupId: {
              groupId: config.enabledSourceGroup
                ? config.sourceGroup?.id
                : null,
            },
          },
        },
      });

      switch (data?.updateOwner.__typename) {
        case "UpdateOwnerResult":
          setFormMode("view");
          displaySuccessToast(`Successfully updated owner`);
          break;
        case "GroupNotFoundError":
          setErrors(["Source group not found"]);
          break;
        case "MessageChannelNotFoundError":
          setErrors(["Reviewer message channel not found"]);
          break;
        case "OwnerAccessRequestFrequencyReminderOutOfBoundsError":
          setErrors(["Escalation duration must be between 1 and 1440 minutes"]);
          break;
        default:
          logError("Failed to update owner");
      }
    } catch (e) {
      logError(e, "failed to update owner");
    }
  };

  const ownerViews: OwnerView[] = [
    {
      key: "overview",
      title: "Overview",
      iconName: "list",
      content: (
        <OwnersConfigForm
          config={config}
          mode={formMode}
          onChange={setConfig}
        />
      ),
    },
    {
      key: "users",
      title: "Users",
      iconName: "user",
      count: owner.ownerUsers.length,
      content: <OwnerUsersColumn owner={owner} />,
    },
    {
      key: "resources",
      title: "Resources",
      iconName: "cube",
      count: owner.ownedResources.length,
      content: (
        <EntityViewerRow
          title="Owned Resources"
          content={
            <OwnerResourcesTable ownedResources={owner.ownedResources} />
          }
          isTable
        />
      ),
    },
    {
      key: "groups",
      title: "Groups",
      iconName: "users",
      count: owner.ownedGroups.length,
      content: (
        <EntityViewerRow
          title="Owned Groups"
          content={<OwnerGroupsTable ownedGroups={owner.ownedGroups} />}
          isTable
        />
      ),
    },
    {
      key: "events",
      title: "Events",
      iconName: "events",
      content: (
        <EntityViewerRow
          title="Events"
          content={
            <EventsTable
              eventFilter={{
                objects: {
                  objectId: owner.id,
                },
              }}
              emptyTitle="No events for this owner"
              emptySubtitle="Interact with this owner to add events"
            />
          }
          isTable
        />
      ),
    },
  ];

  const selectedViewInfo = ownerViews.find((view) => view.key === selectedView);
  const tabInfos = ownerViews.map((ownerView) => ({
    title: ownerView.title,
    isSelected: selectedViewInfo?.key === ownerView.key,
    onClick: () => history.push({ hash: ownerView.key }),
    icon: ownerView.iconName,
    badgeCount: ownerView.count,
  }));

  const overviewButtons = (
    <div className={sprinkles({ display: "flex", gap: "sm" })}>
      {formMode === "view" ? (
        <>
          <Button
            label="Edit"
            onClick={() => setFormMode("edit")}
            leftIconName="edit-2"
            size="md"
            borderless
          />
        </>
      ) : (
        <div className={sprinkles({ display: "flex", gap: "sm" })}>
          <Button
            label="Cancel"
            onClick={() => setFormMode("view")}
            size="md"
            borderless
          />
          <Button
            label={updateLoading ? "Saving..." : "Save"}
            leftIconName="check"
            type="primary"
            size="md"
            onClick={handleSave}
            disabled={updateLoading || _.isEqual(config, initialConfig)}
          />
        </div>
      )}
    </div>
  );

  const menuOptions: PropsFor<typeof ColumnHeader>["menuOptions"] = [
    {
      label: "Rename",
      onClick: () => setShowRenameModal(true),
      icon: { type: "name", icon: "edit-3" },
    },
    {
      label: "Delete",
      onClick: () => setShowDeleteModal(true),
      icon: { type: "name", icon: "trash" },
    },
  ];

  const isAdmin = authState.user?.isAdmin ?? false;

  const content = selectedViewInfo ? (
    <Column isContent maxWidth="lg">
      <ColumnHeader
        title={owner.name}
        subtitle={selectedViewInfo.title}
        breadcrumbs={
          hasV3Nav
            ? [
                {
                  name: "Owners",
                  to: "/owners",
                },
              ]
            : undefined
        }
        icon={{ type: "name", icon: selectedViewInfo.iconName }}
        rightActions={
          selectedViewInfo.key === "overview" && isAdmin
            ? overviewButtons
            : undefined
        }
        menuOptions={isAdmin ? menuOptions : undefined}
      />
      <Divider margin="md" />
      {formMode !== "edit" && (
        <div
          className={sprinkles({ display: "flex", justifyContent: "center" })}
        >
          <Tabs tabInfos={tabInfos} round />
        </div>
      )}
      {errors.map((error) => (
        <Banner message={error} type="error" />
      ))}
      <ColumnContent>{selectedViewInfo.content}</ColumnContent>
    </Column>
  ) : null;

  return (
    <>
      {content}
      {showDeleteModal && (
        <OwnerDeleteModal
          isOpen={showDeleteModal}
          onClose={() => setShowDeleteModal(false)}
          owner={owner}
        />
      )}
      {showRenameModal && (
        <OwnerRenameModal
          isOpen={showRenameModal}
          onClose={() => setShowRenameModal(false)}
          owner={owner}
        />
      )}
    </>
  );
};

export default OwnerDetailColumn;
