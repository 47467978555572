import { Icon } from "components/ui";

import * as styles from "./FilterHeader.css";

interface Props {
  title: string;
  description: string;
  active: boolean;
  expanded: boolean;
  onClick: () => void;
}

const FilterHeader = (props: Props) => {
  const { title, description, active, expanded, onClick } = props;

  return (
    <div className={styles.header} onClick={onClick}>
      {active ? <div className={styles.activeMarker} /> : null}
      <div>
        <div className={styles.title}>{title}</div>
        <div className={styles.subtitle}>{description}</div>
      </div>
      <Icon name={expanded ? "chevron-up" : "chevron-down"} size="xs" />
    </div>
  );
};

export default FilterHeader;
