import { RequestPreviewLargeFragment } from "api/generated/graphql";
import pluralize from "pluralize";
import { Redirect, Switch } from "react-router";
import { Route } from "react-router-dom";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import RequestsDetailViewV3 from "./RequestDetailViewV3";
import RequestsView from "./RequestsView";
import RequestsViewV3 from "./RequestsViewV3";

const Requests = () => {
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);

  if (hasV3Nav) {
    return (
      <Switch>
        <Route exact path={"/requests"}>
          <Redirect to="/requests/inbox" />
        </Route>
        <Route exact path={"/requests/inbox"}>
          <RequestsViewV3 selectedType="inbox" />
        </Route>
        <Route exact path={"/requests/sent"}>
          <RequestsViewV3 selectedType="sent" />
        </Route>
        <Route exact path={"/requests/admin"}>
          <RequestsViewV3 selectedType="admin" />
        </Route>

        {/* Support accessing individual requests from each sub-page (for browsing) or directly (for external URLs) */}
        <Route exact path={"/requests/inbox/:requestId"}>
          <RequestsDetailViewV3 selectedType="inbox" />
        </Route>
        <Route exact path={"/requests/sent/:requestId"}>
          <RequestsDetailViewV3 selectedType="sent" />
        </Route>
        <Route exact path={"/requests/admin/:requestId"}>
          <RequestsDetailViewV3 selectedType="admin" />
        </Route>
        <Route exact path={"/requests/:requestId"}>
          <RequestsDetailViewV3 selectedType="inbox" />
        </Route>
      </Switch>
    );
  }

  return (
    <Switch>
      <Route exact path={"/requests"} component={RequestsView} />
      <Route exact path={"/requests/inbox"}>
        <RequestsView selectedType="inbox" />
      </Route>
      <Route exact path={"/requests/sent"}>
        <RequestsView selectedType="sent" />
      </Route>
      <Route exact path={"/requests/admin"}>
        <RequestsView selectedType="admin" />
      </Route>

      {/* Support accessing individual requests from each sub-page (for browsing) or directly (for external URLs) */}
      <Route exact path={"/requests/inbox/:requestId"}>
        <RequestsView selectedType="inbox" />
      </Route>
      <Route exact path={"/requests/sent/:requestId"}>
        <RequestsView selectedType="sent" />
      </Route>
      <Route exact path={"/requests/admin/:requestId"}>
        <RequestsView selectedType="admin" />
      </Route>
      <Route exact path={[`/requests/:requestId`]} component={RequestsView} />
    </Switch>
  );
};

export const getRequestedItemsSummaryText = (
  request: RequestPreviewLargeFragment,
  short: boolean = false
): string => {
  const itemNames: string[] = [];
  request.requestedResources.forEach((requestedResource) => {
    if (requestedResource.resource?.name) {
      let name = requestedResource.resource.name;
      if (requestedResource.resource?.parentResource?.name) {
        name = `${requestedResource.resource.parentResource.name} / ${name}`;
      }
      itemNames.push(name);
    }
  });
  request.requestedGroups.forEach((requestedGroup) => {
    if (requestedGroup.group?.name) {
      itemNames.push(requestedGroup.group.name);
    }
  });

  const resourceProposal = request.resourceProposal;
  if (resourceProposal) {
    switch (resourceProposal.metadata?.__typename) {
      case "AWSRoleProposalMetadata": {
        return `creation of AWS IAM role "${resourceProposal.metadata?.roleName}"`;
      }
      default:
        return `creation of a resource`;
    }
  }

  if (itemNames.length === 1) {
    return itemNames[0];
  } else if (short) {
    return `${itemNames[0]} + ${itemNames.length - 1}`;
  } else {
    return `${itemNames[0]} and ${itemNames.length - 1} other ${pluralize(
      "item",
      itemNames.length - 1
    )}`;
  }
};

export default Requests;
