import AuthContext from "components/auth/AuthContext";
import { Editor } from "components/entity_viewer/EntityViewer";
import { LockTooltip } from "components/tooltips/LockTooltip";
import React, { useContext } from "react";
import OrgContext from "views/settings/OrgContext";

type MenuOption = {
  label: string;
  handler: () => void;
  warning?: boolean;
  disabled?: boolean;
  hide?: boolean;
};

type EditorCondition = {
  canEdit: () => boolean;
  tooltipText: string;
};

type ConditionalEditorProps = {
  menuOptions: MenuOption[];
  disabledInReadOnlyMode?: boolean;
  additionalConditions?: EditorCondition[];
  isAdmin?: boolean;
};

export const ConditionalEditor = (props: ConditionalEditorProps) => {
  const { authState } = useContext(AuthContext);

  const { orgState } = useContext(OrgContext);

  // TODO: remove hard-coded authState admin check
  const isAdmin = props.isAdmin ?? authState.user?.isAdmin;
  if (!isAdmin) {
    return <LockTooltip tooltipText={"You must be an admin to edit this."} />;
  }

  if (props.disabledInReadOnlyMode && orgState.orgSettings?.isRemoteReadOnly) {
    return (
      <LockTooltip
        tooltipText={"You cannot edit access while in read only mode."}
      />
    );
  }

  if (props.additionalConditions) {
    for (let condition of props.additionalConditions) {
      if (!condition.canEdit()) {
        return <LockTooltip tooltipText={condition.tooltipText} />;
      }
    }
  }

  return <Editor menuOptions={props.menuOptions} />;
};
