import { ResourceLabel } from "components/label/Label";
import { TextHoverTooltip } from "components/more_info/MoreInfo";
import React from "react";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import styles from "views/access_reviews/revocations/AccessReviewRevocations.module.scss";

type AccessReviewUpdateApprovalRequestProps = {
  requestId?: string;
};

const AccessReviewUpdateApprovalRequest = (
  props: AccessReviewUpdateApprovalRequestProps
) => {
  if (!props.requestId) {
    return <></>;
  }
  return (
    <div className={styles.supportTicket}>
      <TextHoverTooltip
        tooltipText={`A ticket has been assigned to revoke this user's access on the end system. When the ticket is closed, the user will be marked as confirmed revoked.`}
      >
        Tracked by request&nbsp;
        <ResourceLabel
          entityType={EntityTypeDeprecated.Request}
          entityId={props.requestId}
        />
      </TextHoverTooltip>
    </div>
  );
};

export default AccessReviewUpdateApprovalRequest;
