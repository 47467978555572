import {
  AccessReviewFragment,
  AccessReviewUserPreviewFragment,
  EntityType,
  ReviewerUserStatus,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { ResourceLabel } from "components/label/Label";
import {
  CellRow,
  Header,
  ScrollableMuiVirtualTable,
} from "components/tables/material_table/MuiVirtualTable";
import { Button } from "components/ui";
import { useContext } from "react";
import { useHistory } from "react-router";
import { SortDirection } from "react-virtualized";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import {
  AccessReviewStatusLabel,
  accessReviewSummaryStatusSortValue,
} from "views/access_reviews/AccessReviewStatus";
import { getAccessReviewEntityUrl } from "views/access_reviews/common/Routes";
import {
  SUMMARY_ACTION_HEADER,
  SUMMARY_STATUS_HEADER,
  USER_NAME_HEADER,
} from "views/access_reviews/common/TableHeaders";

import AccessReviewContext, {
  AccessReviewContextActionType,
  emptyPerformReviewState,
} from "../AccessReviewContext";
import { isReviewer } from "../common/Common";
import { getAccessReviewUserId } from "./AccessReviewUsers";

interface UsersAccessReviewTableRow {
  name: string;
  status: string;
  action: string;
}

type AccessReviewUsersTableProps = {
  accessReview: AccessReviewFragment;
  accessReviewUsers: AccessReviewUserPreviewFragment[];
};

const AccessReviewUsersTable = (props: AccessReviewUsersTableProps) => {
  const history = useHistory();
  const { authState } = useContext(AuthContext);
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );

  const headers: Header<UsersAccessReviewTableRow>[] = [
    USER_NAME_HEADER,
    SUMMARY_STATUS_HEADER,
  ];
  if (accessReviewState.ongoingAccessReviewIdSet.has(props.accessReview.id)) {
    headers.push(SUMMARY_ACTION_HEADER);
  }

  let rows: CellRow<UsersAccessReviewTableRow>[] = [];
  const currentUser = authState.user?.user.id;

  props.accessReviewUsers.forEach((accessReviewUser) => {
    if (accessReviewUser.user) {
      const userCanReviewUser =
        (currentUser &&
          isReviewer(currentUser, accessReviewUser.reviewerUsers) &&
          getUserCanReviewUser(currentUser, accessReviewUser) &&
          (props.accessReview.selfReviewAllowed ||
            accessReviewUser.userId !== currentUser)) ??
        false;
      const name = accessReviewUser.user.fullName;
      const status = accessReviewUser.status;

      const warnings = [
        ...accessReviewUser.resourceWarnings,
        ...accessReviewUser.groupWarnings,
      ];

      rows.push({
        id: accessReviewUser.userId,
        data: {
          name: {
            value: name,
            element: (
              <ResourceLabel
                text={name}
                subText={accessReviewUser.user?.teamAttr}
                entityType={EntityTypeDeprecated.User}
                avatar={accessReviewUser.user?.avatarUrl}
                bold={true}
                iconLarge={true}
                pointerCursor={true}
              />
            ),
            clickHandler: () => {
              history.push(getAccessReviewEntityUrl(accessReviewUser));
            },
          },
          status: {
            value: status,
            sortValue: accessReviewSummaryStatusSortValue(status),
            element: (
              <AccessReviewStatusLabel
                entityType={EntityType.User}
                status={status}
                warnings={warnings}
                user={accessReviewUser.user}
                accessReviewId={accessReviewUser.accessReviewId}
              />
            ),
          },
          action: {
            value: userCanReviewUser.toString(),
            element: userCanReviewUser ? (
              <Button
                type="warning"
                label="Review Access"
                leftIconName="check-square"
                onClick={() => {
                  accessReviewDispatch({
                    type: AccessReviewContextActionType.AccessReviewItemUpdate,
                    payload: {
                      performReviewStateByUARUserId: {
                        ...accessReviewState.performReviewStateByUARUserId,
                        [getAccessReviewUserId(
                          accessReviewUser
                        )]: emptyPerformReviewState(),
                      },
                    },
                  });
                  history.push(getAccessReviewEntityUrl(accessReviewUser));
                }}
              />
            ) : (
              <>{"--"}</>
            ),
          },
        },
      });
    }
  });

  return (
    <ScrollableMuiVirtualTable
      columns={headers}
      rows={rows}
      defaultSortBy={"status"}
      defaultSortDirection={SortDirection.ASC}
      allRowsLoaded
    />
  );
};

const getUserCanReviewUser = (
  userId: string,
  accessReviewUser: AccessReviewUserPreviewFragment
) => {
  return accessReviewUser.reviewerUsers
    .filter((reviewerUser) => reviewerUser.userId === userId)
    .some(
      (reviewerUser) => reviewerUser.status === ReviewerUserStatus.NotStarted
    );
};

export default AccessReviewUsersTable;
