import { getModifiedErrorMessage } from "api/ApiContext";
import { useCreateResourceMutation, Visibility } from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { Button, FormGroup, Input } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import React from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrl } from "utils/common";
import { getServiceTypeFromResourceType } from "utils/directory/services";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { logError } from "utils/logging";
import {
  hasRequiredRemoteId,
  remoteIdLabelByConnectionType,
  ResourceCreationInfo,
} from "views/resources/creation/utils";

type ResourceFromScratchBodyProps = {
  creationInfo: ResourceCreationInfo;
  setCreationInfo: (creationInfo: ResourceCreationInfo) => void;
};

const ResourceFromScratchBody = (props: ResourceFromScratchBodyProps) => {
  const history = useHistory();
  const { displaySuccessToast } = useToast();
  const logEvent = useLogEvent();

  const [errorMessage, setErrorMessage] = React.useState<string>("");

  const [createResource, { loading }] = useCreateResourceMutation();

  const handleSubmit = async () => {
    const {
      name,
      description,
      resourceType,
      adminOwner,
      connection,
      remoteId,
    } = props.creationInfo;
    if (!name || !resourceType || !adminOwner || !connection) {
      return;
    }
    let serviceType = getServiceTypeFromResourceType(
      resourceType,
      connection.connectionType
    );

    logEvent({
      name: "apps_create_resource_end",
      properties: {
        type: "manual",
        connectionType: connection.connectionType,
      },
    });

    try {
      const { data } = await createResource({
        variables: {
          input: {
            name,
            description: description || "",
            serviceType,
            adminOwnerId: adminOwner.id,
            connectionId: connection.id,
            remoteId,
            resourceType,
            visibility: Visibility.Global,
          },
        },
        refetchQueries: ["ItemsListSection", "ResourcesHomeQuery"],
      });
      switch (data?.createResource.__typename) {
        case "CreateResourceResult":
          history.push(
            getResourceUrl(
              EntityTypeDeprecated.Resource,
              data.createResource.resource.id
            )
          );
          displaySuccessToast(
            `Success: "${data.createResource.resource.name}" resource created`
          );
          break;
        default:
          logError(new Error(`failed to create resource`));
          setErrorMessage(`Error: failed to create resource`);
      }
    } catch (error) {
      logError(error, "failed to create resource");
      if (error instanceof Error) {
        setErrorMessage(
          getModifiedErrorMessage("Error: failed to create resource", error)
        );
      }
    }
  };

  const remoteIdRequired =
    (props.creationInfo.connection &&
      hasRequiredRemoteId(props.creationInfo.connection.connectionType)) ??
    false;

  const remoteIdLabel = remoteIdLabelByConnectionType(
    props.creationInfo.connection?.connectionType
  );

  const submitDisabled =
    !props.creationInfo.name ||
    !props.creationInfo.adminOwner ||
    (remoteIdRequired && !props.creationInfo.remoteId);

  return (
    <>
      {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
      <FormGroup label="Name:">
        <Input
          value={props.creationInfo.name}
          onChange={(name) => {
            props.setCreationInfo({
              ...props.creationInfo,
              name,
            });
          }}
          placeholder="Enter Resource Name"
        />
      </FormGroup>
      {remoteIdRequired && (
        <FormGroup label={`${remoteIdLabel}:`} required>
          <Input
            value={props.creationInfo.remoteId}
            onChange={(remoteId) => {
              props.setCreationInfo({
                ...props.creationInfo,
                remoteId,
              });
            }}
            placeholder={`Enter ${_.startCase(remoteIdLabel)}`}
          />
        </FormGroup>
      )}
      <FormGroup label="Admin:">
        <OwnerDropdown
          selectedOwnerId={props.creationInfo.adminOwner?.id}
          onSelectOwner={(owner) => {
            if (owner === undefined) {
              return;
            }
            props.setCreationInfo({
              ...props.creationInfo,
              adminOwner: owner,
            });
          }}
        />
      </FormGroup>
      <FormGroup label="Description:">
        <Input
          value={props.creationInfo.description}
          onChange={(description) => {
            props.setCreationInfo({
              ...props.creationInfo,
              description,
            });
          }}
          type="textarea"
          placeholder="Enter Resource Description"
        />
      </FormGroup>
      <div
        className={sprinkles({
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        })}
      >
        <Button
          label="Create"
          type="primary"
          disabled={submitDisabled}
          loading={loading}
          onClick={handleSubmit}
        />
      </div>
    </>
  );
};

export default ResourceFromScratchBody;
