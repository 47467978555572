import { Route } from "react-router-dom";

import NonHumanIdentitiesColumn from "./NonHumanIdentitiesColumn";

const NonHumanIdentities = () => {
  return (
    <Route
      exact
      path={["/non-human-identities"]}
      component={NonHumanIdentitiesComponent}
    />
  );
};

const NonHumanIdentitiesComponent = () => {
  return <NonHumanIdentitiesColumn />;
};

export default NonHumanIdentities;
