import { Button, ButtonGroup, FormGroup } from "components/ui";
import React from "react";

type AccessReviewMonthPickerProps = {
  selectedMonths: number[];
  onChange: (months: number[]) => void;
};

export const Months = [
  { id: 1, name: "Jan" },
  { id: 2, name: "Feb" },
  { id: 3, name: "Mar" },
  { id: 4, name: "Apr" },
  { id: 5, name: "May" },
  { id: 6, name: "Jun" },
  { id: 7, name: "Jul" },
  { id: 8, name: "Aug" },
  { id: 9, name: "Sep" },
  { id: 10, name: "Oct" },
  { id: 11, name: "Nov" },
  { id: 12, name: "Dec" },
];

const AccessReviewMonthPicker = (props: AccessReviewMonthPickerProps) => {
  return (
    <FormGroup label="Months to run access review" required>
      <ButtonGroup>
        {Months.map((month) => {
          const monthSelected = props.selectedMonths.includes(month.id);
          return (
            <Button
              label={month.name}
              type={monthSelected ? "primary" : undefined}
              outline={!monthSelected}
              onClick={() => {
                if (monthSelected) {
                  props.onChange(
                    props.selectedMonths.filter((m) => m !== month.id)
                  );
                } else {
                  props.onChange([...props.selectedMonths, month.id]);
                }
              }}
            />
          );
        })}
      </ButtonGroup>
    </FormGroup>
  );
};

export default AccessReviewMonthPicker;
