import { refetchQueries } from "api/ApiContext";
import {
  AccessReviewFragment,
  ConnectionPreviewSmallFragment,
  ConnectionType,
  GroupFragment,
  ResourcePreviewSmallFragment,
  ResourceType,
  SyncTaskStatus,
  SyncType,
  useStartSyncMutation,
  useSyncStatusQuery,
  useSyncTaskQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import {
  CustomCloseButton,
  ToastStyle,
  useToast,
} from "components/toast/Toast";
import { Button, Tooltip } from "components/ui";
import React, { useContext, useRef, useState } from "react";
import {
  isNativeGroupType,
  isNativeResourceType,
} from "utils/directory/resources";
import { useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";

export type StartSyncButtonProps = {
  syncType: SyncType;
  connection?: ConnectionPreviewSmallFragment;
  resource?: ResourcePreviewSmallFragment;
  group?: GroupFragment;
  accessReview?: AccessReviewFragment;
  queriesToRefetch?: Parameters<typeof refetchQueries>[0]["include"];
  canSync?: boolean;
};

export const StartSyncButton = (props: StartSyncButtonProps) => {
  const { authState } = useContext(AuthContext);
  const [syncInProgress, setSyncInProgress] = useState(false);

  const displayToast = useRef(false);
  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();

  const [startSyncMutation] = useStartSyncMutation();
  const { refetch } = useSyncTaskQuery({
    skip: true,
  });

  const { refetch: refetchOngoing } = useSyncStatusQuery({
    skip: true,
  });

  let syncedEntityName: string;
  let connectionId: string | undefined;
  let resourceId: string | undefined;
  let groupId: string | undefined;
  let accessReviewId: string | undefined;

  const closeToastButton = CustomCloseButton(ToastStyle.Default, () => {
    displayToast.current = false;
  });

  const checkSyncStatus = async (
    syncTaskId: string,
    syncedEntityName: string,
    waitInterval: number,
    totalWaited: number
  ) => {
    const syncTaskErrorText = `Error: could not get sync status`;
    try {
      const result = await refetch({
        input: {
          id: syncTaskId,
        },
      });
      const { data: syncTaskData } = result;
      let status: SyncTaskStatus;

      if (syncTaskData) {
        switch (syncTaskData?.syncTask.__typename) {
          case "SyncTaskResult":
            status = syncTaskData.syncTask.syncTask.status;
            if (status === SyncTaskStatus.Started) {
              // If in progress, keep polling to check for sync task completion
              if (displayToast.current) {
                displayLoadingToast(
                  `Syncing ${syncedEntityName}...`,
                  false,
                  closeToastButton
                );
              }
              setTimeout(() => {
                checkSyncStatus(
                  syncTaskId,
                  syncedEntityName,
                  Math.min(2000, waitInterval * 2),
                  totalWaited + waitInterval
                );
              }, waitInterval);
            } else {
              await handleSyncFinished(status);
            }

            break;
          case "SyncTaskNotFoundError":
          default:
            // Upon error, stop polling
            logError(new Error(syncTaskErrorText));
            displayErrorToast(syncTaskErrorText);
            setSyncInProgress(false);
        }
      }
    } catch (error) {
      logError(error, syncTaskErrorText);
      if (displayToast.current) {
        displayErrorToast(syncTaskErrorText);
      }
      setSyncInProgress(false);
    }
  };

  const handleSyncFinished = async (status: SyncTaskStatus) => {
    // If finished, refetch the relevant data
    if (props.queriesToRefetch) {
      try {
        await refetchQueries({
          include: props.queriesToRefetch,
        });
      } catch (error) {
        logError(error, `failed to refresh data after sync`);
      }
    }

    try {
      await refetchQueries({ include: ["SyncStatus"] });
    } catch (error) {
      logError(error, `failed to refresh data after sync`);
    }

    if (status === SyncTaskStatus.Completed && displayToast.current) {
      displaySuccessToast(`Success: finished syncing ${syncedEntityName}`);
    } else if (displayToast.current) {
      displayErrorToast(`Finished syncing ${syncedEntityName} with errors`);
    }
    setSyncInProgress(false);
  };

  const handleSync = async () => {
    displayToast.current = true;
    setSyncInProgress(true);
    const startSyncErrorText = `Error: failed to sync ${syncedEntityName}`;
    try {
      const { data } = await startSyncMutation({
        variables: {
          input: {
            syncType: props.syncType,
            connectionId: connectionId,
            resourceId: resourceId,
            groupId: groupId,
            accessReviewId: accessReviewId,
          },
        },
      });
      let syncTaskId: string;
      switch (data?.startSync.__typename) {
        case "StartSyncResult":
          // Poll to check for sync task completion
          syncTaskId = data.startSync.syncTask.id;
          setTimeout(() => {
            checkSyncStatus(syncTaskId, syncedEntityName, 500, 250);
          }, 250);
          break;
        case "InvalidSyncTypeError":
        case "ConnectionNotFoundError":
        case "ResourceNotFoundError":
        case "GroupNotFoundError":
        case "AccessReviewNotFoundError":
        default:
          logError(new Error(startSyncErrorText));
          displayErrorToast(startSyncErrorText);
          setSyncInProgress(false);
      }
    } catch (error) {
      logError(error, startSyncErrorText);
      displayErrorToast(startSyncErrorText);
      setSyncInProgress(false);
    }
  };

  useMountEffect(() => {
    const fetchOngoingSync = async (
      syncType: SyncType,
      syncedEntityName: string
    ) => {
      const ongoingResult = await refetchOngoing({
        input: {
          syncType: syncType,
          connectionId: props.connection?.id,
        },
      });
      const data = ongoingResult.data;
      if (data && data.syncStatus.__typename === "SyncStatusResult") {
        const taskID = data.syncStatus.ongoingSyncTask
          ? data.syncStatus.ongoingSyncTask.id
          : null;
        if (taskID != null) {
          setSyncInProgress(true);
          setTimeout(() => {
            checkSyncStatus(taskID, syncedEntityName, 500, 250);
          }, 250);
        }
      }
    };
    fetchOngoingSync(props.syncType, syncedEntityName);
  });

  // TODO: remove hard-coded authState admin check
  const canSync = props.canSync ?? authState.user?.isAdmin;
  if (!canSync) {
    return <></>;
  }

  switch (props.syncType) {
    case SyncType.PullConnectionsAll:
      syncedEntityName = "all resources and groups";
      break;
    case SyncType.PullConnectionsAllResources:
      syncedEntityName = "all resources";
      break;
    case SyncType.PullConnectionsAllGroups:
      syncedEntityName = "all groups";
      break;
    case SyncType.PullConnectionsSingleConnection:
      if (props.connection) {
        syncedEntityName = `app "${props.connection.name}"`;
        connectionId = props.connection.id;
      } else {
        logError(new Error(`StartSyncButton app must not be undefined`));
        return <></>;
      }
      break;
    case SyncType.PullConnectionsSingleResource:
      if (props.resource) {
        if (isNativeResourceType(props.resource.resourceType)) {
          return <></>;
        }
        syncedEntityName = `resource "${props.resource.name}"`;
        resourceId = props.resource.id;
      } else {
        logError(new Error(`StartSyncButton resource must not be undefined`));
        return <></>;
      }
      break;
    case SyncType.PullConnectionsSingleGroup:
      if (props.group) {
        if (
          isNativeGroupType(props.group.groupType) &&
          // Allow on-call team reviewers sync
          !props.group.isOnCallSynced
        ) {
          return <></>;
        }
        syncedEntityName = `group "${props.group.name}"`;
        groupId = props.group.id;
      } else {
        logError(new Error(`StartSyncButton group must not be undefined`));
        return <></>;
      }
      break;
    case SyncType.PullHrIdpData:
      syncedEntityName = "all HR and IDP data";
      break;
    case SyncType.PullUarRemoteTickets:
      accessReviewId = props.accessReview?.id;
      syncedEntityName = "all linked tickets";
      break;
    case SyncType.PullPropagationTickets:
      if (
        props.resource &&
        props.resource.resourceType === ResourceType.Custom
      ) {
        syncedEntityName = `resource "${props.resource.name}"`;
        resourceId = props.resource.id;
      } else if (
        props.connection &&
        props.connection.connectionType === ConnectionType.Custom
      ) {
        syncedEntityName = `app "${props.connection.name}"`;
        connectionId = props.connection.id;
      } else {
        logError(
          new Error(
            `StartSyncButton resource must not be undefined for custom resources`
          )
        );
        return <></>;
      }
      break;
    default:
      logError(
        new Error(`unsupported SyncType in StartSyncButton: ${props.syncType}`)
      );
      return <></>;
  }

  let tooltipText = `Sync ${syncedEntityName}`;

  return (
    <Tooltip tooltipText={tooltipText}>
      <Button
        onClick={handleSync}
        leftIconName="refresh-cw"
        type="primary"
        label="Sync"
        borderless
        disabled={syncInProgress}
        size="sm"
      />
    </Tooltip>
  );
};
