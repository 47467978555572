import slackLogo from "assets/logos/slack-logo.svg";
import CenteredDisplay from "components/centered_display/CenteredDisplay";
import { Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React from "react";
import { useMountEffect } from "utils/hooks";

// SlackMfaCallback is a dedicated component for handling the MFA validation redirect from Slack.
export const SlackMfaCallback = () => {
  useMountEffect(() => {
    window.location.replace("slack://open");
  });

  return (
    <CenteredDisplay>
      <div
        className={sprinkles({
          textAlign: "center",
        })}
      >
        <p
          className={sprinkles({
            fontWeight: "semibold",
          })}
        >
          You have successfully validated your MFA.
        </p>
        <p>
          You are being redirected back to Slack to complete your access
          request...
        </p>
        <Icon externalIconUrl={slackLogo} size="lg" />
      </div>
    </CenteredDisplay>
  );
};

export default SlackMfaCallback;
