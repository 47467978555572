import { FormGroup, FormRow, Input } from "../../ui";
import { FormMode } from "../common";

interface Props {
  mode: FormMode;
  value?: string;
  onChange: (value?: string) => void;
  disabled?: boolean;
  styleAsFormGroup?: boolean;
}

const PositionRow = (props: Props) => {
  const viewContent = props.value || "--";

  const editContent = (
    <Input
      value={props.value}
      onChange={props.onChange}
      type="text"
      placeholder="Enter position"
      disabled={props.disabled}
    />
  );
  return props.styleAsFormGroup ? (
    <FormGroup label="Title" disabled={props.disabled}>
      {props.mode === "view" ? viewContent : editContent}
    </FormGroup>
  ) : (
    <FormRow title="Title" disabled={props.disabled}>
      {props.mode === "view" ? viewContent : editContent}
    </FormRow>
  );
};

export default PositionRow;
