import { Column, ColumnContainer } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import { Button, Divider } from "components/ui";

import Dashboard from "./Dashboard";

const DashboardColumn = () => {
  return (
    <ColumnContainer>
      <Column isContent>
        <ColumnHeader
          title="All data"
          subtitle="Aggregated grant data across all apps"
          icon={{ type: "name", icon: "speedometer" }}
          demoBadge
          rightActions={
            <Button
              label="Export as PDF"
              leftIconName="trello"
              type="primary"
              borderless
              size="sm"
            />
          }
          tabs={[
            {
              title: "Security",
              isSelected: true,
              onClick: () => {},
              icon: "shield",
            },
            {
              title: "Productivity",
              isSelected: false,
              onClick: () => {},
              icon: "trending-up",
            },
            {
              title: "Compliance",
              isSelected: false,
              onClick: () => {},
              icon: "clipboard",
            },
          ]}
        />
        <Divider />
        <ColumnContent>
          <Dashboard />
        </ColumnContent>
      </Column>
    </ColumnContainer>
  );
};

export default DashboardColumn;
