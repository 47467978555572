import {
  EventForTableFragment,
  useEventPreviewQuery,
} from "api/generated/graphql";
import { PillV3 } from "components/pills/PillsV3";
import { Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import pluralize from "pluralize";
import { useParams } from "react-router";
import { eventTypeToString } from "utils/events";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ViewSkeleton from "views/loading/ViewSkeleton";

import * as styles from "./EventDetailColumn.css";
import { SubEventsTableV3 } from "./viewer/SubEventsTableV3";

const EventDetailColumnV3 = () => {
  const transitionTo = useTransitionTo({
    preserveAllQueries: true,
  });
  const { eventId } = useParams<Record<string, string>>();

  const { data, error, loading } = useEventPreviewQuery({
    variables: {
      id: eventId,
    },
  });

  let event: EventForTableFragment | null = null;
  if (data) {
    switch (data.event.__typename) {
      case "EventResult":
        event = data.event.event;
        break;
      case "EventNotFoundError":
        return <NotFoundPage />;
      default:
        logError(new Error(`failed to list events`));
    }
  } else if (error) {
    logError(error, `failed to list events`);
  }

  if (loading) {
    return <ViewSkeleton />;
  }

  if (!eventId || !event || error) {
    return <UnexpectedErrorPage error={error} />;
  }

  const createdAt = moment(new Date(event.createdAt));

  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        width: "100%",
      })}
    >
      <div
        className={sprinkles({
          padding: "lg",
          paddingBottom: "sm",
        })}
      >
        <div
          className={sprinkles({
            display: "flex",
            alignItems: "center",
            marginBottom: "xs",
          })}
        >
          <div className={styles.eventTitle}>
            {eventTypeToString(event.eventType, true)}
          </div>
          <Icon
            name="x"
            onClick={() => transitionTo({ pathname: "/events" })}
          />
        </div>
        <div className={styles.eventActor}>
          <Icon
            externalIconUrl={event.actorUser?.avatarUrl}
            iconStyle="rounded"
          />
          by {event.actorUser?.fullName}
        </div>
        <PillV3
          keyText={`${createdAt.toISOString()} (${createdAt.fromNow()})`}
          maxChars={100}
          pillColor="Blue"
        />
        {event.numSubEvents > 0 && (
          <div
            className={sprinkles({
              fontSize: "textLg",
              fontWeight: "semibold",
              marginTop: "xl",
            })}
          >
            {event.numSubEvents} {pluralize("Subevent", event.numSubEvents)}
          </div>
        )}
      </div>
      {event.numSubEvents > 0 && <SubEventsTableV3 id={event.id} />}
    </div>
  );
};

export default EventDetailColumnV3;
