import React from "react";
import { Search } from "react-feather";

import * as styles from "./ResultItem.css";
import { KBarItem } from "./utils";

interface ResultItemProps {
  active: boolean;
  item: KBarItem;
  searchQuery: string;
}

const ResultItem: React.FC<ResultItemProps> = (props: {
  item: KBarItem;
  active: boolean;
  searchQuery: string;
}) => {
  switch (props.item.type) {
    case "section":
      return <div className={styles.section}>{props.item.name}</div>;
    case "see-all":
      return (
        <div className={styles.seeAllItem({ active: props.active })}>
          <div className={styles.searchIcon}>
            <Search size={18} />
          </div>
          See all results for "
          <span className={styles.searchQuery}>{props.searchQuery}</span>"
        </div>
      );
    case "no-results":
      return <div className={styles.noResults}>No results</div>;
    case "result":
      return (
        <div className={styles.resultItem({ active: props.active })}>
          <div className={styles.resultItemLeft}>
            <div className={styles.entityIcon}>{props.item.icon}</div>
            <div className={styles.resultItemColumn}>
              <div className={styles.itemLabel}>{props.item.label}</div>
              <div className={styles.itemSubtext}>{props.item.subText}</div>
            </div>
          </div>
          <div className={styles.appName}>{props.item.rightText}</div>
        </div>
      );
    default:
      return null;
  }
};

export default ResultItem;
