import NotificationIcon from "components/notifications/notificationIcon";
import { useSpotlightSearch } from "components/spotlight_search/utils";
import { ButtonV3 } from "components/ui";
import Icon, { IconName } from "components/ui/icon/Icon";
import React from "react";
import { Link } from "react-router-dom-v5-compat";
import useLogEvent from "utils/analytics";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import * as styles from "./OpalHeader.css";

type Props = {
  icon?: IconName;
  title: string | React.ReactNode;
  breadcrumbs?: { name: string; to?: string }[];
  extraActions?: React.ReactNode; // avoid if possible
  leftActions?: React.ReactNode; // avoid if possible
};

const OpalHeader = ({
  icon,
  title,
  extraActions,
  leftActions,
  breadcrumbs,
}: Props) => {
  const { setShowSpotlightSearch } = useSpotlightSearch();
  const logEvent = useLogEvent();
  const hasV3Search = useFeatureFlag(FeatureFlag.V3Search);

  let search = null;
  if (!hasV3Search) {
    search = (
      <ButtonV3
        size="lg"
        leftIconName="search"
        onClick={() => {
          logEvent({
            name: "k_bar_search_open",
            properties: {
              entryPoint: "click",
            },
          });
          setShowSpotlightSearch(true);
        }}
      />
    );
  }

  return (
    <header className={styles.container}>
      {icon && (
        <div className={styles.icon}>
          <Icon name={icon} size="sm" />
        </div>
      )}
      <div className={styles.leftContainer}>
        <div className={styles.title}>
          {title}
          {breadcrumbs &&
            breadcrumbs.map((crumb, index) => (
              <span className={styles.breadcrumbs}>
                &nbsp;/{" "}
                {crumb.to != undefined &&
                crumb.to != "" &&
                index !== breadcrumbs.length - 1 ? (
                  <Link className={styles.breadcrumbLink} to={crumb.to}>
                    {crumb.name}
                  </Link>
                ) : (
                  crumb.name
                )}
              </span>
            ))}
        </div>
        {leftActions}
      </div>

      <div className={styles.actionsContainer}>
        {extraActions}
        {search}
        <NotificationIcon />
      </div>
    </header>
  );
};

export default OpalHeader;
