import {
  ConnectionType,
  EndUserResourceDetailsFragment,
  EntityType,
  ResourceRequestStatusCountsResult,
  useResourceDetailsModalQuery,
} from "api/generated/graphql";
import AccessInfoDisplay from "components/enduser_exp/AccessInfoDisplay";
import { AccessRequestCountDisplay } from "components/enduser_exp/AccessRequestCountDisplay";
import { PendingRequestDisplay } from "components/requests/PendingRequestDisplay";
import { ButtonV3, Col, Modal, Row, Skeleton } from "components/ui";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import { useEffect } from "react";
import { usePageTitle, useRecordViewFor } from "utils/hooks";
import {
  getResourcePendingRequestRoleRemoteIds,
  resourceHasForfeitableRole,
} from "utils/resources";
import { formatResourceBreadcrumb } from "utils/resources";
import { useAccessRequestTransition } from "views/access_request/AccessRequestContext";
import { NO_PERMISSION_TO_REQUEST } from "views/apps/enduser_exp/constants";
import {
  formatRequestDataForItems,
  getOwnerUserIconList,
  getResourceUserAccessInfo,
} from "views/apps/enduser_exp/utils";
import { EndUserItemDetailsCard } from "views/common/EndUserItemDetailsCard";
import { ItemUsageDetails } from "views/common/ItemUsageDetails";
import {
  CurrentUserResourceAccessStatus,
  currentUserResourceAccessStatusFromResource,
  useConnectTransition,
} from "views/connect_sessions/utils";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { ForfeitResourceButton } from "views/resources/ForfeitResourceButton";

type ResourceDetailsModalProps = {
  showModal: boolean;
  closeModal: () => void;
  resourceId: string;
  showButtons?: boolean;
};

export const ResourceDetailsModal = (props: ResourceDetailsModalProps) => {
  const { showButtons = true, closeModal } = props;
  const transitionToAccessRequest = useAccessRequestTransition();
  const transitionToConnect = useConnectTransition();
  const { data, loading, error, refetch } = useResourceDetailsModalQuery({
    variables: {
      id: props.resourceId,
    },
  });

  const resource: EndUserResourceDetailsFragment | null =
    data && data.resource.__typename === "ResourceResult"
      ? data.resource.resource
      : null;

  const resourceRequestCount: ResourceRequestStatusCountsResult | null =
    data &&
    data.resourceRequestStatusCounts.__typename ===
      "ResourceRequestStatusCountsResult"
      ? data?.resourceRequestStatusCounts
      : null;

  const userPendingRequestRoleRemoteIds = getResourcePendingRequestRoleRemoteIds(
    resource?.currentUserAccess
  );

  const hasForfeitableRole = resourceHasForfeitableRole(
    resource?.currentUserAccess
  );

  const mostRecentUserAccessStatus = currentUserResourceAccessStatusFromResource(
    resource
  );
  const isResourceConnectable =
    mostRecentUserAccessStatus ===
      CurrentUserResourceAccessStatus.AuthorizedSessionStarted ||
    mostRecentUserAccessStatus ===
      CurrentUserResourceAccessStatus.AuthorizedSessionNotStarted;

  const resourceOwners = getOwnerUserIconList(resource?.adminOwner?.ownerUsers);
  usePageTitle(resource?.name);

  const fullDetailsURL = `/resources/${resource?.id}`;

  useRecordViewFor("resource", resource?.id);

  // Auto-close modal if group is not visible or doesn't exist
  useEffect(() => {
    if (data?.resource.__typename === "ResourceNotFoundError") {
      closeModal();
    }
  }, [data, closeModal]);

  return (
    <Modal
      isOpen={props.showModal}
      title="More Info"
      onClose={(event) => {
        event.stopPropagation();
        closeModal();
      }}
      maxWidth="md"
      fullWidth={true}
    >
      <Modal.Body>
        {loading && <Skeleton />}
        {error && <UnexpectedErrorPage />}
        {resourceOwners && resource && (
          <div
            className={sprinkles({
              display: "flex",
              flexDirection: "column",
              gap: "xl",
              paddingBottom: "xl",
            })}
          >
            <EndUserItemDetailsCard
              icon={{
                type: "entity",
                entityType: resource?.resourceType || ConnectionType.Custom,
              }}
              title={resource?.name}
              subtitle={formatResourceBreadcrumb(
                resource.ancestorPathToResource,
                60,
                resource.connection?.name
              )}
              description={resource?.description}
              isModal={true}
              detailsURL={fullDetailsURL}
              actions={
                <>
                  {isResourceConnectable && showButtons && (
                    <ButtonV3
                      label="Connect"
                      type="success"
                      size="xs"
                      onClick={(event) => {
                        transitionToConnect({
                          connectionId: resource.connectionId,
                          resourceId: resource.id,
                          event,
                        });
                      }}
                    />
                  )}
                  {showButtons && (
                    <ButtonV3
                      label="Request"
                      type="main"
                      size="xs"
                      disabled={!resource.isRequestable}
                      disabledTooltip={NO_PERMISSION_TO_REQUEST}
                      onClick={(event) => {
                        transitionToAccessRequest(
                          {
                            ...formatRequestDataForItems({
                              entityId: resource.id,
                              entityType: EntityType.Resource,
                            }),
                            appId: resource.connectionId,
                          },
                          event
                        );
                      }}
                    />
                  )}
                  {hasForfeitableRole && showButtons && (
                    <ForfeitResourceButton
                      resource={resource}
                      refetchResource={refetch}
                      hasV3
                      noIcon
                      buttonSize="xs"
                    />
                  )}
                  {userPendingRequestRoleRemoteIds.size > 0 && showButtons && (
                    <PendingRequestDisplay
                      resourceId={resource.id}
                      pendingRequests={
                        resource.currentUserAccess.pendingRequests
                      }
                      renderButton={(onClick) => (
                        <ButtonV3
                          label="View request"
                          type="defaultSecondary"
                          onClick={onClick}
                          size="xs"
                        />
                      )}
                    />
                  )}
                </>
              }
              rightContent={
                <ItemUsageDetails
                  teamUsageCount={resource?.accessStats?.teamAccessCount}
                  titleUsageCount={resource?.accessStats?.titleAccessCount}
                  entityType={EntityType.Resource}
                  resourceType={resource?.resourceType}
                  ownerIcons={resourceOwners}
                />
              }
            />
            <Row>
              <Col>
                <AccessRequestCountDisplay
                  approvedCount={
                    resourceRequestCount?.requestStatusCounts.approved || 0
                  }
                  deniedCount={
                    resourceRequestCount?.requestStatusCounts.denied || 0
                  }
                  orgUsersCount={resource?.accessStats?.totalAccessCount || 0}
                  groupsCount={resource?.containingGroups?.length || 0}
                  groups={
                    resource?.containingGroups
                      .map((item) => {
                        return {
                          key: item.groupId,
                          label: item.group?.name || "",
                          icon: {
                            type: "entity",
                            entityType: item.group?.connection?.connectionType,
                            style: "rounded",
                          } as IconData,
                        };
                      })
                      .sort((a, b) =>
                        a.label
                          .toLowerCase()
                          .localeCompare(b.label.toLowerCase())
                      )
                      .filter((item) => item !== null) || []
                  }
                />
              </Col>
              <Col>
                <AccessInfoDisplay
                  {...getResourceUserAccessInfo(resource.currentUserAccess)}
                />
              </Col>
            </Row>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
