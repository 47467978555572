import { FieldLabel } from "components/modals/SessionDetailsModal";
import { Modal } from "components/ui";
import React from "react";

type SigningSecretModalProps = {
  isModalOpen: boolean;
  signingSecret: string;
  onClose: () => void;
  onSubmit: () => void;
  loading?: boolean;
};

export const SigningSecretModal = (props: SigningSecretModalProps) => {
  return (
    <Modal
      isOpen={props.isModalOpen}
      onClose={props.onClose}
      title="Signing Secret"
    >
      <Modal.Body>
        <p>
          {`This is the secret that Opal uses to sign the request payload. The remote system receiving the request from Opal should use this secret to
             validate the request's origin. This secret will not be visible again. If you lose it, you will need to regenerate the secret.`}
        </p>
        <FieldLabel isDataPrivate={true} value={props.signingSecret} />
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel={"Okay, understood"}
        onPrimaryButtonClick={props.onSubmit}
        primaryButtonLoading={props.loading}
        primaryButtonDisabled={props.loading}
      />
    </Modal>
  );
};
