import { useUsersQuery } from "api/generated/graphql";
import { PaginatedUserDropdown } from "components/dropdown/PaginatedUserDropdown";
import { DataElement, DataElementList, Modal, Skeleton } from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import React, { useState } from "react";
import { logError } from "utils/logging";
import { getUserAvatarIcon } from "views/users/utils";

interface Props {
  userIDs: string[];
  onChangeUserIDs?: (userIDs: string[]) => void;
}

const UsersFilter: React.FC<Props> = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [modalUserIDs, setModalUserIDs] = useState<string[]>([]);

  const userIds = _.uniq([...modalUserIDs, ...props.userIDs]);
  const { data, loading, error } = useUsersQuery({
    variables: {
      input: {
        userIds,
      },
    },
    skip: userIds.length === 0,
  });
  if (error) {
    logError(error, `failed to list users`);
  }

  const allUsers = data?.users.users ?? [];

  const handleOpenModal = () => {
    setShowModal(true);
    setModalUserIDs(props.userIDs);
  };

  const handleCancel = () => {
    setShowModal(false);
  };

  const handleSave = () => {
    if (!props.onChangeUserIDs) return;
    props.onChangeUserIDs([...modalUserIDs]);
    setShowModal(false);
  };

  if (!props.onChangeUserIDs) {
    if (props.userIDs.length === 0) {
      return null;
    }
    return (
      <DataElementList>
        {loading ? (
          <Skeleton />
        ) : (
          props.userIDs.map((userID) => {
            const user = allUsers.find((o) => o.id === userID);
            if (!user) {
              return null;
            }
            return (
              <DataElement
                label={user.fullName}
                color="pink"
                leftIcon={{ data: getUserAvatarIcon(user) }}
              />
            );
          })
        )}
      </DataElementList>
    );
  }

  return (
    <div className={sprinkles({ marginTop: "md" })}>
      <DataElementList>
        {loading ? (
          <Skeleton />
        ) : (
          props.userIDs.map((userID) => {
            const user = allUsers.find((o) => o.id === userID);
            if (!user) {
              return null;
            }
            return (
              <DataElement
                label={user.fullName}
                color="pink"
                leftIcon={{ data: getUserAvatarIcon(user) }}
                rightIcon={{
                  name: "x",
                  onClick: () => {
                    props.onChangeUserIDs &&
                      props.onChangeUserIDs(
                        props.userIDs.filter((id) => id !== userID)
                      );
                  },
                }}
              />
            );
          })
        )}
        <DataElement
          label="Add user"
          color="blue"
          leftIcon={{
            name: "plus",
          }}
          onClick={handleOpenModal}
        />
      </DataElementList>
      <Modal isOpen={showModal} onClose={handleCancel} title="Include users">
        <Modal.Body>
          <p>
            The access review will only include the following users. If any
            users are selected, any entity filters will be applied to only the
            entities that the selected users have access to.
          </p>
          <div className={sprinkles({ marginBottom: "md" })}>
            <PaginatedUserDropdown
              hiddenUserIds={modalUserIDs}
              selectOnly
              onChange={(user) => {
                if (user) {
                  setModalUserIDs((prev) => [...prev, user.id]);
                }
              }}
              popperForceDownward
            />
          </div>
          <DataElementList>
            {modalUserIDs.map((userID) => {
              const user = allUsers.find((o) => o.id === userID);
              if (!user) {
                return null;
              }
              return (
                <DataElement
                  label={user.fullName}
                  color="pink"
                  leftIcon={{ data: getUserAvatarIcon(user) }}
                  rightIcon={{
                    name: "x",
                    onClick: () => {
                      setModalUserIDs(
                        modalUserIDs.filter((id) => id !== userID)
                      );
                    },
                  }}
                />
              );
            })}
          </DataElementList>
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Save"
          onPrimaryButtonClick={handleSave}
          secondaryButtonLabel="Cancel"
          onSecondaryButtonClick={handleCancel}
        />
      </Modal>
    </div>
  );
};

export default UsersFilter;
