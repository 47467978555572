import { Maybe } from "api/generated/graphql";
import styles from "components/label/AccessReviewNoteLabel.module.scss";
import AccessReviewNoteModal from "components/modals/AccessReviewNoteModal";
import Overlay, { Alignment } from "components/overlay/Overlay";
import { Button } from "components/ui";
import React, { useState } from "react";

export type AccessReviewNoteLabelProps = {
  initNoteContent?: Maybe<string>;
  onSubmit: (updatedNoteContent: Maybe<string>) => void;
};

const AccessReviewNoteLabel = (props: AccessReviewNoteLabelProps) => {
  const [note, setNote] = useState<Maybe<string>>(
    props.initNoteContent || null
  );
  const [showPreview, setShowPreview] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <>
      <div className={styles.label}>
        {note ? (
          <Overlay
            showOverlay={showPreview}
            alignment={Alignment.Left}
            topOffset={8}
            onClose={() => setShowPreview(false)}
            content={
              <AccessReviewNoteLabelPreview
                noteContent={note}
                onEdit={() => setShowModal(true)}
                onDelete={() => setNote(null)}
                onClose={() => setShowPreview(false)}
              />
            }
            button={
              <Button
                leftIconName="file-text"
                onClick={() => setShowPreview(!showPreview)}
                label="View Note"
                outline
              />
            }
          ></Overlay>
        ) : (
          <Button
            onClick={() => setShowModal(true)}
            label="Add Note"
            leftIconName="plus"
            outline
          />
        )}
      </div>

      {showModal && (
        <AccessReviewNoteModal
          initNote={note}
          isOpen={showModal}
          errMsg=""
          onClose={() => {
            setShowModal(false);
          }}
          onSubmit={(newNote) => {
            setNote(newNote || null);
            props.onSubmit(newNote || null);
            setShowModal(false);
          }}
        />
      )}
    </>
  );
};

export type AccessReviewNoteLabelPreviewProps = {
  noteContent: Maybe<string>;
  onEdit: () => void;
  onDelete: () => void;
  onClose: () => void;
};

export const AccessReviewNoteLabelPreview = (
  props: AccessReviewNoteLabelPreviewProps
) => {
  const { onEdit, onDelete, onClose } = props;
  return (
    <div className={styles.preview}>
      <p>{props.noteContent}</p>

      <Button
        onClick={() => {
          onEdit();
          onClose();
        }}
        label="Edit Note"
        leftIconName="edit"
        outline
      />

      <Button
        onClick={() => {
          onDelete();
          onClose();
        }}
        label="Clear Note"
        leftIconName="x"
        outline
      />
    </div>
  );
};

export default AccessReviewNoteLabel;
