import sprinkles from "css/sprinkles.css";

import * as styles from "./RevocationButtonsCell.css";

export enum RevocationAction {
  MarkRevoked = "MARK_REVOKED",
  LinkTicket = "LINK_TICKET",
}

interface Props {
  state?: RevocationAction;
  onStateChange: (state?: RevocationAction) => void;
  isHeader?: boolean;
}

const RevocationButtonsCell = (props: Props) => {
  const { isHeader, state, onStateChange } = props;

  const revokeLabel = isHeader ? "Mark All" : "Mark Revoked";
  const ticketLabel = isHeader ? "Link All" : "Link Ticket";

  return (
    <div className={sprinkles({ display: "flex", gap: "sm" })}>
      <div
        className={styles.button({
          clear: Boolean(isHeader),
          color: "red",
          selected: state === RevocationAction.MarkRevoked,
        })}
        onClick={() => {
          onStateChange(
            state === RevocationAction.MarkRevoked
              ? undefined
              : RevocationAction.MarkRevoked
          );
        }}
      >
        {revokeLabel}
      </div>
      <div
        className={styles.button({
          clear: Boolean(isHeader),
          color: "blue",
          selected: state === RevocationAction.LinkTicket,
        })}
        onClick={() => {
          onStateChange(
            state === RevocationAction.LinkTicket
              ? undefined
              : RevocationAction.LinkTicket
          );
        }}
      >
        {ticketLabel}
      </div>
    </div>
  );
};

export default RevocationButtonsCell;
