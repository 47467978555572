import { useMetricsFiltersQuery } from "api/generated/graphql";
import GroupSearchDropdown from "components/dropdown/GroupSearchDropdown";
import ResourceSearchDropdown from "components/dropdown/ResourceSearchDropdown";
import { Button, DataElement, DataElementList, Select } from "components/ui";
import sprinkles from "css/sprinkles.css";
import useLogEvent from "utils/analytics";
import { logError } from "utils/logging";

import {
  useMetricsFilterDispatch,
  useMetricsFilterState,
} from "../MetricsDashboardContext";
import * as styles from "./MetricsFilters.css";

const MetricsFilter = () => {
  const {
    connectionIds,
    resourceIds,
    groupIds,
    tagIds,
  } = useMetricsFilterState();
  const filterDispatch = useMetricsFilterDispatch();
  const logEvent = useLogEvent();

  const { data, loading, error } = useMetricsFiltersQuery({
    variables: {
      resourceIds,
      groupIds,
    },
  });
  if (error) {
    logError(error, `failed to list connections`);
  }

  const allConnections = data?.connections.connections ?? [];
  const unselectedConnections = allConnections.filter(
    (connection) => !connectionIds.includes(connection.id)
  );
  const allTags = data?.tags.tags ?? [];
  const unselectedTags = allTags.filter((tag) => !tagIds.includes(tag.id));
  const resources = data?.resources.resources ?? [];
  const groups = data?.groups.groups ?? [];

  const hasFilters =
    connectionIds.length > 0 ||
    tagIds.length > 0 ||
    resourceIds.length > 0 ||
    groupIds.length > 0;

  return (
    <>
      <div className={styles.container}>
        <div className={styles.appsFilter}>
          <Select
            loading={loading}
            options={unselectedConnections}
            onChange={(connection) => {
              if (connection) {
                logEvent({
                  name: "metrics_dashboard_filter_select",
                  properties: {
                    filterType: "app",
                    filterValue: connection.id,
                  },
                });
                filterDispatch({
                  type: "SET_CONNECTION_IDS",
                  payload: {
                    ids: [...connectionIds, connection.id],
                  },
                });
              }
            }}
            getOptionLabel={(connection) => connection.name}
            getIcon={(connection) => ({
              type: "entity",
              entityType: connection.connectionType,
            })}
            placeholder="Filter by app"
            placeholderIcon={{ type: "name", icon: "dots-grid" }}
            selectOnly
          />
        </div>
        <div className={styles.entityFilter}>
          <ResourceSearchDropdown
            selectedResourceIds={resourceIds}
            onSelect={({ actionType, resources }) => {
              if (actionType === "remove-option") {
                filterDispatch({
                  type: "SET_RESOURCE_IDS",
                  payload: {
                    ids: resourceIds.filter(
                      (id) => !resources.map((r) => r.id).includes(id)
                    ),
                  },
                });
              } else {
                logEvent({
                  name: "metrics_dashboard_filter_select",
                  properties: {
                    filterType: "resource",
                    filterValue: resources.map((r) => r.id).join(", "),
                  },
                });
                filterDispatch({
                  type: "SET_RESOURCE_IDS",
                  payload: {
                    ids: [...resourceIds, ...resources.map((r) => r.id)],
                  },
                });
              }
            }}
            placeholder="Filter by resource"
            placeholderIcon={{ type: "name", icon: "cube" }}
            alwaysShowPlaceholder
          />
        </div>
        <div className={styles.entityFilter}>
          <GroupSearchDropdown
            selectedGroupIds={groupIds}
            onSelect={({ actionType, groups }) => {
              if (actionType === "remove-option") {
                filterDispatch({
                  type: "SET_GROUP_IDS",
                  payload: {
                    ids: groupIds.filter(
                      (id) => !groups.map((g) => g.id).includes(id)
                    ),
                  },
                });
              } else {
                logEvent({
                  name: "metrics_dashboard_filter_select",
                  properties: {
                    filterType: "group",
                    filterValue: groups.map((g) => g.id).join(", "),
                  },
                });
                filterDispatch({
                  type: "SET_GROUP_IDS",
                  payload: {
                    ids: [...groupIds, ...groups.map((g) => g.id)],
                  },
                });
              }
            }}
            placeholder="Filter by group"
            placeholderIcon={{ type: "name", icon: "users" }}
            alwaysShowPlaceholder
          />
        </div>
        <Select
          loading={loading}
          options={unselectedTags}
          onChange={(tag) => {
            if (tag) {
              logEvent({
                name: "metrics_dashboard_filter_select",
                properties: {
                  filterType: "tag",
                  filterValue: tag.id,
                },
              });
              filterDispatch({
                type: "SET_TAG_IDS",
                payload: {
                  ids: [...tagIds, tag.id],
                },
              });
            }
          }}
          getOptionLabel={(tag) =>
            tag.value ? `${tag.key}:${tag.value}` : tag.key
          }
          placeholder="Filter by tag"
          placeholderIcon={{ type: "name", icon: "tag" }}
          selectOnly
        />
        {hasFilters && (
          <div className={styles.clearButton}>
            <Button
              label="Clear filters"
              outline
              onClick={() => {
                filterDispatch({
                  type: "CLEAR_FILTERS",
                });
              }}
            />
          </div>
        )}
      </div>
      <div className={sprinkles({ display: "flex", justifyContent: "center" })}>
        <DataElementList>
          {connectionIds.map((connectionID) => {
            const connection = allConnections.find(
              (o) => o.id === connectionID
            );
            if (!connection) {
              return null;
            }
            return (
              <DataElement
                label={connection.name}
                color="blue"
                size="sm"
                leftIcon={{
                  data: {
                    type: "entity",
                    entityType: connection.connectionType,
                  },
                }}
                rightIcon={{
                  name: "x",
                  onClick: () => {
                    filterDispatch({
                      type: "SET_CONNECTION_IDS",
                      payload: {
                        ids: connectionIds.filter((id) => id !== connectionID),
                      },
                    });
                  },
                }}
              />
            );
          })}
          {resourceIds.map((resourceID) => {
            const resource = resources.find((o) => o.id === resourceID);
            if (!resource) {
              return null;
            }
            return (
              <DataElement
                label={resource.name}
                color="pink"
                size="sm"
                leftIcon={{
                  data: {
                    type: "entity",
                    entityType: resource.resourceType,
                  },
                }}
                rightIcon={{
                  name: "x",
                  onClick: () => {
                    filterDispatch({
                      type: "SET_RESOURCE_IDS",
                      payload: {
                        ids: resourceIds.filter((id) => id !== resourceID),
                      },
                    });
                  },
                }}
              />
            );
          })}
          {groupIds.map((groupID) => {
            const group = groups.find((o) => o.id === groupID);
            if (!group) {
              return null;
            }
            return (
              <DataElement
                label={group.name}
                color="pink"
                size="sm"
                leftIcon={{
                  data: {
                    type: "entity",
                    entityType: group.groupType,
                  },
                }}
                rightIcon={{
                  name: "x",
                  onClick: () => {
                    filterDispatch({
                      type: "SET_GROUP_IDS",
                      payload: {
                        ids: groupIds.filter((id) => id !== groupID),
                      },
                    });
                  },
                }}
              />
            );
          })}
          {tagIds.map((tagId) => {
            const tag = allTags.find((o) => o.id === tagId);
            if (!tag) {
              return null;
            }
            return (
              <DataElement
                label={tag.value ? `${tag.key}:${tag.value}` : tag.key}
                color="green"
                size="sm"
                leftIcon={{ name: "tag" }}
                rightIcon={{
                  name: "x",
                  onClick: () => {
                    filterDispatch({
                      type: "SET_TAG_IDS",
                      payload: {
                        ids: tagIds.filter((id) => id !== tagId),
                      },
                    });
                  },
                }}
              />
            );
          })}
        </DataElementList>
      </div>
    </>
  );
};

export default MetricsFilter;
