import AuthContext from "components/auth/AuthContext";
import { Icon } from "components/ui";
import { useContext } from "react";
import { useHistory } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import ViewSkeleton from "views/loading/ViewSkeleton";

import * as styles from "./Setup.css";
import { useResolvedState } from "./SetupContext";
import SetupFooter from "./SetupFooter";
import SetupProgress from "./SetupProgress";
import Credentials from "./steps/Credentials";
import SetupFinished from "./steps/Finished";
import IdpSelect from "./steps/IdpSelect";
import Import from "./steps/Import";
import Sync from "./steps/Sync";

const Setup = () => {
  const history = useHistory();

  const { authState } = useContext(AuthContext);
  const hasSetup = useFeatureFlag(FeatureFlag.SetupWizard);
  const [{ currentStep }, { loading }] = useResolvedState();

  if (!hasSetup || !authState.user?.isAdmin) {
    return null;
  }

  if (loading) {
    return <ViewSkeleton />;
  }

  const handleClose = () => {
    history.push("/");
  };

  let content;
  switch (currentStep) {
    case 0:
      content = <IdpSelect />;
      break;
    case 1:
      content = <Credentials />;
      break;
    case 2:
      content = <Sync />;
      break;
    case 3:
      content = <Import />;
      break;
    case 4:
      content = <SetupFinished />;
      break;
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.closeButton} onClick={handleClose}>
        <Icon name="x" />
      </div>
      <div className={styles.content}>
        <SetupProgress currentStep={currentStep} />
        {content}
      </div>
      <div className={styles.footer}>
        <SetupFooter />
      </div>
    </div>
  );
};

export default Setup;
