import {
  OidcProviderType,
  useValidateCodeAndSaveIdTokenMutation,
  useValidateCodeAndSaveIdTokenPresignedMutation,
} from "api/generated/graphql";
import { useToast } from "components/toast/Toast";
import { useLocation, useNavigate } from "react-router-dom-v5-compat";
import { useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";
import { clearOidcData, getOidcData } from "utils/oidc/oidc";
import { FullPageLoading } from "views/loading/FullPageLoading";

// OidcCallback is a dedicated component for handling OIDC auth redirects
// and parsing/removing the auth token fragment from the URL.
// This component handles two use cases:
// 1. (default user case) Users submitting requests from the web app.
// Users are already authenticated, and we don't have to do anything special.
// 2. Users submitting MFA requests from the slack app. They may not be
// authenticated, so we generate a presigned user token for them. It's stored
// in local storage when they start the OIDC callback flow, and we look it up
// to submit with the request when we call our mutation.
export const OidcCallback = () => {
  const location = useLocation();

  const { displayErrorToast } = useToast();

  const navigate = useNavigate();
  const [validateCodeAndSaveIdToken] = useValidateCodeAndSaveIdTokenMutation();
  const [
    validateCodeAndSaveIdTokenPresigned,
  ] = useValidateCodeAndSaveIdTokenPresignedMutation();

  let handleError = (postAuthPath: string) => {
    logError(new Error(`validating and saving OIDC ID token failed`));
    displayErrorToast("Error: Authorization failed");
    navigate(postAuthPath, { replace: true });
  };
  useMountEffect(() => {
    async function handleOidcCallback(
      code: string,
      state: string,
      oidcProviderType: OidcProviderType,
      postAuthPath: string,
      postAuthHash: string
    ) {
      if (code && state) {
        try {
          // We have received callback parameters from the OIDC provider,
          // so we need to forward them on to the Opal server.
          const { data } = await validateCodeAndSaveIdToken({
            variables: {
              input: {
                code: code,
                state: state,
                oidcProviderType: oidcProviderType,
              },
            },
          });
          switch (data?.validateCodeAndSaveIdToken) {
            case true:
              navigate(postAuthPath + "?oidc_auth=success" + postAuthHash, {
                replace: true,
              });
              break;
            default:
              handleError(postAuthPath);
          }
        } catch (error) {
          handleError(postAuthPath);
        }
      } else {
        handleError(postAuthPath);
      }
    }
    // handleOidcCallbackPresigned is a copy of handleOidcCallback, except
    // it calls the presigned-version of the mutation. This is necessary
    // because the user may not be authenticated (coming from the slack app).
    async function handleOidcCallbackPresigned(
      code: string,
      state: string,
      oidcProviderType: OidcProviderType,
      postAuthPath: string,
      postAuthHash: string
    ) {
      if (code && state) {
        try {
          // We have received callback parameters from the OIDC provider,
          // so we need to forward them on to the Opal server.
          const { data } = await validateCodeAndSaveIdTokenPresigned({
            variables: {
              input: {
                code: code,
                state: state,
                oidcProviderType: oidcProviderType,
              },
            },
          });
          switch (data?.validateCodeAndSaveIdTokenPresigned) {
            case true:
              navigate(postAuthPath + "?oidc_auth=success" + postAuthHash, {
                replace: true,
              });
              break;
            default:
              handleError(postAuthPath);
          }
        } catch (error) {
          handleError(postAuthPath);
        }
      } else {
        handleError(postAuthPath);
      }
    }

    const query = new URLSearchParams(location.search);
    const code = query.get("code");
    const state = query.get("state");
    if (code && state) {
      const oidcData = getOidcData();
      if (!oidcData || state != oidcData.state) {
        logError(
          new Error(
            `Invalid state (query state: ${state}, oidc state: ${oidcData?.state})})`
          )
        );
        displayErrorToast("Error: Authorization failed");
        navigate("/", { replace: true });
        return;
      }

      if (oidcData.mfaParams?.presignedUserToken) {
        // Use the presigned flow if we previously saved
        // a user token.
        const url = new URL(window.location.href);
        url.searchParams.append(
          "ps",
          oidcData.mfaParams?.presignedUserToken || ""
        );
        window.history.pushState(null, "", url.toString());

        clearOidcData();
        handleOidcCallbackPresigned(
          code,
          state,
          oidcData.oidcProviderType,
          oidcData.postAuthPath,
          oidcData.postAuthHash || ""
        );
      } else {
        handleOidcCallback(
          code,
          state,
          oidcData.oidcProviderType,
          oidcData.postAuthPath,
          oidcData.postAuthHash || ""
        );
      }
    } else {
      logError(new Error(`State or authorization code missing`));
      displayErrorToast("Error: Authorization failed");
      navigate("/", { replace: true });
    }
  });

  return <FullPageLoading />;
};

export default OidcCallback;
