import { ReviewStageOperator } from "api/generated/graphql";

import { GenericReviewer } from "./generic_reviewers";

export const getNumApproversInStage = (
  ownerIds: string[],
  genericReviewers: Record<GenericReviewer, boolean>
) => {
  let numApproversInStage = ownerIds.length;

  Object.values(genericReviewers).forEach((reviewerIncluded) => {
    if (reviewerIncluded) {
      numApproversInStage += 1;
    }
  });

  return numApproversInStage;
};

export const getOperatorDisplayName = (operator: ReviewStageOperator) => {
  switch (operator) {
    case ReviewStageOperator.And:
      return "ALL";
    case ReviewStageOperator.Or:
      return "ANY";
  }
};
