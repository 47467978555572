import { TimeZone } from "@vvo/tzdb";
import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewGroupResourceVisibilityPolicy,
  AccessReviewReviewerAssignmentPolicy,
  CreateAccessReviewTemplateInput,
  Maybe,
  UpdateAccessReviewTemplateInput,
  useCreateAccessReviewTemplateMutation,
  useDeleteAccessReviewTemplatesMutation,
  useUpdateAccessReviewTemplateMutation,
} from "api/generated/graphql";
import AccessReviewMonthPicker, {
  Months,
} from "components/access_reviews/AccessReviewMonthPicker";
import AssignmentPolicy from "components/access_reviews/AssignmentPolicy";
import ReminderNotifications, {
  DEFAULT_REMINDER_SCHEDULE,
  DEFAULT_REMINDER_TIME_OF_DAY,
} from "components/access_reviews/ReminderNotifications";
import StartAccessReviewStats from "components/access_reviews/scope/AccessReviewStats";
import ConnectionsFilter from "components/access_reviews/scope/ConnectionsFilter";
import EntitiesIndividualFilter from "components/access_reviews/scope/EntitiesIndividualFilter";
import EntitiesNamedFilter from "components/access_reviews/scope/EntitiesNamedFilter";
import EntitiesWithAdminFilter from "components/access_reviews/scope/EntitiesWithAdminFilter";
import EntityTypesFilter from "components/access_reviews/scope/EntityTypesFilter";
import TagFilter from "components/access_reviews/scope/TagFilter";
import UsersFilter from "components/access_reviews/scope/UsersFilter";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import {
  Banner,
  Button,
  Card,
  Divider,
  FormGroup,
  Input,
  Modal,
  Select,
  Switch,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { getNumberWithOrdinal } from "utils/common";
import { logError, logWarning } from "utils/logging";
import {
  getTimeZoneDisplayText,
  getTimeZoneFromString,
  getTimeZoneGuess,
  timeZones,
} from "utils/time";

import FilterMatchModeSwitch from "../common/FilterMatchModeSwitch";
import {
  AccessReviewFilters,
  buildAccessReviewFilter,
  defaultAccessReviewFilters,
} from "./utils";

type AccessReviewScheduleOverviewProps = {
  includeGroupBindings: boolean;
  templateId?: string;
  name?: string;
  timeZone?: string;
  reminderSchedule?: number[];
  reminderTimeOfDay?: Date;
  reminderIncludeManager?: boolean;
  filters?: AccessReviewFilters;
  reviewerAssignmentPolicy?: AccessReviewReviewerAssignmentPolicy;
  sendReviewerAssignmentNotifications?: boolean;
  monthSchedule?: number[];
  startDayOfMonth?: number;
  accessReviewDuration?: number;
  selfReviewAllowed?: boolean;
  groupResourceVisibilityPolicy?: AccessReviewGroupResourceVisibilityPolicy;
};

export const getTemplateReviewExample = (name: string, inputDate?: Date) => {
  let date = inputDate || new Date();
  return _.escape(_.trim(name))
    .replace(
      "{quarter}",
      "Q" + (Math.floor(date.getMonth() / 3) + 1).toString()
    )
    .replace("{month}", Months[date.getMonth()].name)
    .replace("{year}", date.getFullYear().toString());
};

const DURATION_UPPER_BOUND = 30;
const DURATION_LOWER_BOUND = 1;

interface AccessReviewScheduleConfig {
  name: string;
  timeZone?: TimeZone;
  reminderSchedule: number[];
  reminderTimeOfDay: Date;
  reminderIncludeManager: boolean;
  filters: AccessReviewFilters;
  assignmentPolicy: AccessReviewReviewerAssignmentPolicy;
  sendReviewerAssignmentNotifications: boolean;
  monthSchedule: number[];
  startDayOfMonth: number;
  accessReviewDuration: number | undefined;
  selfReviewAllowed: boolean;
  includeGroupBindings: boolean;
  groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
}

const CreateAccessReviewScheduleColumn = (
  props: AccessReviewScheduleOverviewProps
) => {
  const history = useHistory();
  const { displaySuccessToast } = useToast();
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const containerRef = useRef<HTMLDivElement | null>(null);

  const [
    createAccessReviewTemplate,
    { loading: createLoading },
  ] = useCreateAccessReviewTemplateMutation();
  const [
    updateAccessReviewTemplate,
    { loading: updateLoading },
  ] = useUpdateAccessReviewTemplateMutation();

  const getResetConfig = useCallback((): AccessReviewScheduleConfig => {
    const timezone =
      getTimeZoneFromString(props.timeZone) || getTimeZoneGuess();
    return {
      name: props.name || "",
      timeZone: timezone,
      reminderSchedule: props.reminderSchedule || DEFAULT_REMINDER_SCHEDULE,
      reminderTimeOfDay:
        props.reminderTimeOfDay || DEFAULT_REMINDER_TIME_OF_DAY,
      reminderIncludeManager: props.reminderIncludeManager || false,
      filters: props.filters ?? defaultAccessReviewFilters(),
      assignmentPolicy:
        props.reviewerAssignmentPolicy ||
        AccessReviewReviewerAssignmentPolicy.Manually,
      sendReviewerAssignmentNotifications:
        props.sendReviewerAssignmentNotifications || false,
      monthSchedule: props.monthSchedule || [],
      startDayOfMonth: props.startDayOfMonth || 1,
      accessReviewDuration: props.accessReviewDuration || 15,
      selfReviewAllowed: props.selfReviewAllowed ?? true,
      includeGroupBindings: props.includeGroupBindings ?? false,
      groupResourceVisibilityPolicy:
        props.groupResourceVisibilityPolicy ??
        AccessReviewGroupResourceVisibilityPolicy.Strict,
    };
  }, [
    props.accessReviewDuration,
    props.filters,
    props.monthSchedule,
    props.name,
    props.reminderIncludeManager,
    props.reminderSchedule,
    props.reminderTimeOfDay,
    props.reviewerAssignmentPolicy,
    props.selfReviewAllowed,
    props.sendReviewerAssignmentNotifications,
    props.startDayOfMonth,
    props.timeZone,
    props.includeGroupBindings,
    props.groupResourceVisibilityPolicy,
  ]);

  const [config, setConfig] = useState<AccessReviewScheduleConfig>(
    getResetConfig()
  );

  const [showDeleteTemplateModal, setShowDeleteTemplateModal] = useState(false);
  const [showMoreFilters, setShowMoreFilters] = useState(false);

  useEffect(() => {
    setConfig(getResetConfig());
  }, [props.templateId, getResetConfig]);

  const scrollToTop = () => {
    containerRef.current?.scroll({ top: 0, behavior: "smooth" });
  };

  const handleCreate = async () => {
    if (
      config.monthSchedule.length > 0 &&
      config.accessReviewDuration &&
      config.timeZone
    ) {
      try {
        const input: CreateAccessReviewTemplateInput = {
          name: config.name,
          accessReviewDuration: config.accessReviewDuration,
          monthSchedule: config.monthSchedule,
          startDayOfMonth: config.startDayOfMonth,
          reminderSchedule: config.reminderSchedule,
          reminderTimeOfDay: config.reminderTimeOfDay.toISOString(),
          reminderIncludeManager: config.reminderIncludeManager,
          timeZone: config.timeZone.name,
          reviewerAssignmentPolicy: config.assignmentPolicy,
          sendReviewerAssignmentNotification:
            config.sendReviewerAssignmentNotifications,
          selfReviewAllowed: config.selfReviewAllowed,
          filters: buildAccessReviewFilter(config.filters),
          includeGroupBindings: config.includeGroupBindings,
          groupResourceVisibilityPolicy: config.groupResourceVisibilityPolicy,
        };

        const { data } = await createAccessReviewTemplate({
          variables: { input },
          refetchQueries: ["AccessReviewTemplates"],
        });
        switch (data?.saveAccessReviewTemplate.__typename) {
          case "CreateAccessReviewTemplateResult":
            displaySuccessToast(`Success: access review schedule created`);
            history.replace("/access-reviews?category=schedules");
            break;
          default:
            logError(new Error(`failed to create access review schedule`));
            setErrorMessage(`Error: failed to create access review schedule`);
            scrollToTop();
        }
      } catch (error) {
        logError(error, "failed to create access review schedule");
        setErrorMessage(
          getModifiedErrorMessage(
            `Error: failed to create access review schedule`,
            error
          )
        );
        scrollToTop();
      }
    }
  };

  const handleUpdate = async () => {
    if (
      config.monthSchedule.length > 0 &&
      config.accessReviewDuration &&
      props.templateId &&
      config.timeZone
    ) {
      try {
        const input: UpdateAccessReviewTemplateInput = {
          id: props.templateId,
          name: config.name,
          accessReviewDuration: config.accessReviewDuration,
          monthSchedule: config.monthSchedule,
          startDayOfMonth: config.startDayOfMonth,
          reminderSchedule: config.reminderSchedule,
          reminderTimeOfDay: config.reminderTimeOfDay.toISOString(),
          reminderIncludeManager: config.reminderIncludeManager,
          timeZone: config.timeZone.name,
          reviewerAssignmentPolicy: config.assignmentPolicy,
          sendReviewerAssignmentNotification:
            config.sendReviewerAssignmentNotifications,
          selfReviewAllowed: config.selfReviewAllowed,
          filters: buildAccessReviewFilter(config.filters),
          includeGroupBindings: config.includeGroupBindings,
        };

        const { data } = await updateAccessReviewTemplate({
          variables: { input },
          refetchQueries: ["AccessReviewTemplates"],
        });
        switch (data?.updateAccessReviewTemplate.__typename) {
          case "UpdateAccessReviewTemplateResult":
            displaySuccessToast(`Success: access review schedule updated`);
            history.replace("/access-reviews?category=schedules");
            break;
          default:
            logError(new Error(`failed to update access review schedule`));
            setErrorMessage(`Error: failed to update access review schedule`);
            scrollToTop();
        }
      } catch (error) {
        logError(error, "failed to create access review schedule");
        setErrorMessage(
          getModifiedErrorMessage(
            `Error: failed to create access review schedule`,
            error
          )
        );
        scrollToTop();
      }
    }
  };

  return (
    <>
      <Column isContent maxWidth="lg">
        <ColumnHeader
          title={
            props.templateId
              ? "Edit Access Review Schedule"
              : "Create Access Review Schedule"
          }
          icon={{ type: "name", icon: props.templateId ? "edit" : "plus" }}
          rightActions={
            <div className={sprinkles({ display: "flex", gap: "sm" })}>
              {props.templateId ? (
                <Button
                  label="Delete"
                  leftIconName="trash"
                  type="error"
                  borderless
                  onClick={() => setShowDeleteTemplateModal(true)}
                />
              ) : null}
              <Button
                label="Save Schedule"
                leftIconName="plus"
                type="primary"
                onClick={props.templateId ? handleUpdate : handleCreate}
                disabled={
                  config.monthSchedule.length === 0 ||
                  !config.name ||
                  !config.accessReviewDuration
                }
                loading={createLoading || updateLoading}
              />
            </div>
          }
        />
        <Divider />
        <ColumnContent ref={containerRef}>
          <div className={sprinkles({ padding: "lg" })}>
            {errorMessage ? (
              <div className={sprinkles({ marginBottom: "md" })}>
                <Banner type="error" message={errorMessage} />
              </div>
            ) : null}
            <Card title="Access Review Information">
              <FormGroup label="Name" required>
                <div>
                  Specifies the name of the access review as shown in the UI. It
                  can contain the following placeholders:
                  <ul>
                    <li>{`{quarter} which will display as Q1, Q2, etc.`}</li>
                    <li>{`{month} which will display as Jan, Feb, etc.`}</li>
                    <li>{`{year} which will display as the year.`}</li>
                  </ul>
                </div>
                <Input
                  value={config.name}
                  onChange={(newName) =>
                    setConfig({ ...config, name: newName })
                  }
                  placeholder="e.g. {quarter} access review"
                />
                {_.trim(config.name).length > 0 ? (
                  <div className={sprinkles({ marginTop: "sm" })}>
                    Example: {getTemplateReviewExample(config.name)}
                  </div>
                ) : null}
              </FormGroup>
              <AccessReviewMonthPicker
                selectedMonths={config.monthSchedule}
                onChange={(newValue) =>
                  setConfig({ ...config, monthSchedule: newValue })
                }
              />
              <FormGroup
                label="Day of the month to start access review"
                required
              >
                <Select
                  options={[...Array(28)].map((_, i) => ({ startDay: i + 1 }))}
                  onChange={(value) => {
                    if (value) {
                      setConfig({ ...config, startDayOfMonth: value.startDay });
                    }
                  }}
                  value={{ startDay: config.startDayOfMonth }}
                  getOptionLabel={(value) =>
                    getNumberWithOrdinal(value.startDay)
                  }
                />
              </FormGroup>
              <FormGroup label="Duration of access review" required>
                <Input
                  type="number"
                  rightText="days"
                  value={config.accessReviewDuration}
                  min={DURATION_LOWER_BOUND}
                  max={DURATION_UPPER_BOUND}
                  onChange={(newValue) =>
                    setConfig({ ...config, accessReviewDuration: newValue })
                  }
                />
              </FormGroup>
              <FormGroup label="Time zone" required>
                <Select
                  value={config.timeZone}
                  options={timeZones}
                  getOptionLabel={getTimeZoneDisplayText}
                  onChange={(newTimeZone) =>
                    setConfig({ ...config, timeZone: newTimeZone })
                  }
                />
              </FormGroup>
            </Card>
            <Card title="Scope">
              <FormGroup label="Entities">
                <StartAccessReviewStats filters={config.filters} />
              </FormGroup>
              <div>
                <FormGroup label="Users">
                  {config.filters.userIDs.length === 0 && "All users"}
                  <UsersFilter
                    userIDs={config.filters.userIDs}
                    onChangeUserIDs={(newUserIDs) => {
                      setConfig((prevConfig) => ({
                        ...prevConfig,
                        filters: {
                          ...prevConfig.filters,
                          userIDs: newUserIDs,
                        },
                      }));
                    }}
                  />
                </FormGroup>
              </div>
              <Divider />
              <FilterMatchModeSwitch
                matchMode={config.filters.matchMode}
                onChange={(matchMode) => {
                  setConfig((prevConfig) => ({
                    ...prevConfig,
                    filters: {
                      ...prevConfig.filters,
                      matchMode,
                    },
                  }));
                }}
              />
              <EntitiesIndividualFilter
                entityIds={config.filters.entityIDs}
                onChangeEntityIds={(newEntityIds) => {
                  setConfig((prevConfig) => ({
                    ...prevConfig,
                    filters: {
                      ...prevConfig.filters,
                      entityIDs: newEntityIds,
                    },
                  }));
                }}
              />
              <ConnectionsFilter
                connectionIDs={config.filters.connectionIDs}
                onChangeConnectionIDs={(newConnectionIDs) => {
                  setConfig((prevConfig) => ({
                    ...prevConfig,
                    filters: {
                      ...prevConfig.filters,
                      connectionIDs: newConnectionIDs,
                    },
                  }));
                }}
              />
              <EntitiesWithAdminFilter
                adminIDs={config.filters.adminIDs}
                onChangeAdminIDs={(newAdminIDs) => {
                  setConfig({
                    ...config,
                    filters: {
                      ...config.filters,
                      adminIDs: newAdminIDs,
                    },
                  });
                }}
              />
              {showMoreFilters && (
                <>
                  <EntityTypesFilter
                    resourceTypes={config.filters.resourceTypes}
                    groupTypes={config.filters.groupTypes}
                    onChangeResourceTypes={(newTypes) => {
                      setConfig((prevConfig) => ({
                        ...prevConfig,
                        filters: {
                          ...prevConfig.filters,
                          resourceTypes: newTypes,
                        },
                      }));
                    }}
                    onChangeGroupTypes={(newTypes) => {
                      setConfig((prevConfig) => ({
                        ...prevConfig,
                        filters: {
                          ...prevConfig.filters,
                          groupTypes: newTypes,
                        },
                      }));
                    }}
                  />
                  <TagFilter
                    tags={config.filters.tags}
                    onChangeTags={(newTags) => {
                      setConfig({
                        ...config,
                        filters: {
                          ...config.filters,
                          tags: newTags,
                        },
                      });
                    }}
                  />
                  <EntitiesNamedFilter
                    names={config.filters.names}
                    onChangeNames={(newNames) => {
                      setConfig({
                        ...config,
                        filters: {
                          ...config.filters,
                          names: newNames,
                        },
                      });
                    }}
                  />
                </>
              )}
              <Button
                label={
                  showMoreFilters
                    ? "Hide additional filters"
                    : "Show additional filters"
                }
                borderless
                size="sm"
                onClick={() => setShowMoreFilters((prev) => !prev)}
              />
            </Card>
            <Card title="Notifications">
              <ReminderNotifications
                reminderSchedule={config.reminderSchedule}
                reminderTimeOfDay={config.reminderTimeOfDay}
                reminderIncludeManager={config.reminderIncludeManager}
                timezone={config.timeZone}
                onChange={(
                  thisReminderSchedule,
                  thisReminderTimeOfDay,
                  thisReminderIncludeManager
                ) => {
                  setConfig({
                    ...config,
                    reminderSchedule: thisReminderSchedule,
                    reminderTimeOfDay: thisReminderTimeOfDay,
                    reminderIncludeManager: thisReminderIncludeManager,
                  });
                }}
              />
              <FormGroup
                label={`New review notifications: ${
                  config.sendReviewerAssignmentNotifications ? "On" : "Off"
                }`}
              >
                <Switch
                  label="Send a notification to reviewers when they're assigned a new review."
                  checked={config.sendReviewerAssignmentNotifications}
                  onChange={(checked) =>
                    setConfig({
                      ...config,
                      sendReviewerAssignmentNotifications: checked,
                    })
                  }
                  rightAlign
                />
              </FormGroup>
            </Card>
            <Card title="Reviews">
              <AssignmentPolicy
                assignmentPolicy={config.assignmentPolicy}
                onChange={(newValue) =>
                  setConfig({ ...config, assignmentPolicy: newValue })
                }
              />
              <FormGroup
                label={`Self-review: ${
                  config.selfReviewAllowed ? "Allowed" : "Not Allowed"
                }`}
              >
                <Switch
                  label="Allow self-reviews. Self reviewed items will be noted in the review results report."
                  checked={config.selfReviewAllowed}
                  onChange={(checked) =>
                    setConfig({
                      ...config,
                      selfReviewAllowed: checked,
                    })
                  }
                  rightAlign
                />
              </FormGroup>
            </Card>
          </div>
        </ColumnContent>
      </Column>
      {showDeleteTemplateModal && props.templateId && (
        <TemplateDeleteModal
          templateId={props.templateId}
          showModal={showDeleteTemplateModal}
          onClose={() => setShowDeleteTemplateModal(false)}
        />
      )}
    </>
  );
};

type TemplateDeleteModalProps = {
  templateId: string;
  showModal: boolean;
  onClose: () => void;
};

const TemplateDeleteModal = (props: TemplateDeleteModalProps) => {
  const history = useHistory();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [
    deleteTemplates,
    { loading },
  ] = useDeleteAccessReviewTemplatesMutation();

  const deleteModalReset = () => {
    props.onClose();
    setErrorMessage(null);
  };

  const handleDelete = async () => {
    if (!props.templateId) {
      deleteModalReset();
      return;
    }
    try {
      const { data } = await deleteTemplates({
        variables: {
          input: {
            ids: [props.templateId],
          },
        },
        refetchQueries: ["AccessReviewTemplates"],
        update: (cache, { data }) => {
          switch (data?.deleteAccessReviewTemplates.__typename) {
            case "DeleteAccessReviewTemplatesResult":
              for (const template of data?.deleteAccessReviewTemplates
                .accessReviewTemplates) {
                cache.evict({
                  id: cache.identify(template),
                });
              }
              break;
          }
        },
      });
      switch (data?.deleteAccessReviewTemplates.__typename) {
        case "DeleteAccessReviewTemplatesResult":
          deleteModalReset();
          displaySuccessToast(`Success: access review schedule deleted`);
          history.replace("/access-reviews?category=schedules");
          break;
        case "AccessReviewTemplateNotFound":
          logWarning(new Error(data.deleteAccessReviewTemplates.message));
          setErrorMessage(data.deleteAccessReviewTemplates.message);
          break;
        default:
          logError(new Error(`failed to delete access review schedule`));
          setErrorMessage(`Error: failed to delete access review schedule`);
      }
    } catch (error) {
      logError(error, `failed to delete access review schedule`);
      setErrorMessage(
        getModifiedErrorMessage(
          `Error: failed to delete access review schedule`,
          error
        )
      );
    }
  };

  return (
    <Modal
      isOpen={props.showModal}
      onClose={props.onClose}
      title="Delete access review schedule"
    >
      <Modal.Body>
        <div>
          Are you sure you want to delete this access review schedule? This
          cannot be undone.
        </div>
        {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
      </Modal.Body>
      <Modal.Footer
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={props.onClose}
        primaryButtonLabel="Delete"
        onPrimaryButtonClick={handleDelete}
        primaryButtonLoading={loading}
        primaryButtonType="error"
      />
    </Modal>
  );
};
export { TemplateDeleteModal };
export default CreateAccessReviewScheduleColumn;
