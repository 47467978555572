import { Checkbox, FormRow, Input, Linkify, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import { AppsContext } from "views/apps/AppsContext";

import { FormMode } from "../common";

interface Props {
  mode: FormMode;
  value?: string;
  onChange: (value?: string) => void;
  matchRemoteDescription?: boolean;
  setMatchRemoteDescription?: (match: boolean) => void;
  disabled?: boolean;
}

const DescriptionRow = (props: Props) => {
  const { matchRemoteDescription, setMatchRemoteDescription } = props;

  const { bulkMode } = useContext(AppsContext);

  const viewContent = props.value ? (
    <Linkify>
      <div className={sprinkles({ whiteSpace: "pre-wrap" })}>{props.value}</div>
    </Linkify>
  ) : (
    <span
      className={sprinkles({
        color: "gray500",
        fontStyle: "italic",
      })}
    >
      No description
    </span>
  );

  const editContent = (
    <>
      {bulkMode === "edit" && (
        <div className={sprinkles({ marginBottom: "md" })}>
          <Switch
            label="Leave unchanged"
            checked={props.value === undefined}
            onChange={(val) => props.onChange(val ? undefined : "")}
            rightAlign
          />
        </div>
      )}
      <div className={sprinkles({ marginBottom: "sm" })}>
        <Input
          value={props.value}
          onChange={props.onChange}
          type="textarea"
          placeholder="Enter description"
          disabled={props.disabled}
        />
      </div>
      {matchRemoteDescription !== undefined && setMatchRemoteDescription ? (
        <Checkbox
          checked={matchRemoteDescription}
          label="Use description from end system"
          onChange={(checked) => {
            setMatchRemoteDescription(checked);
          }}
        />
      ) : null}
    </>
  );
  return (
    <FormRow title="Description" disabled={props.disabled}>
      {props.mode === "view" ? viewContent : editContent}
    </FormRow>
  );
};

export default DescriptionRow;
