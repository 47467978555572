import {
  ConnectionPreviewLargeFragment,
  Maybe,
  useUpdateConnectionMutation,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Input, Modal } from "components/ui";
import { useState } from "react";
import React from "react";
import useLogEvent from "utils/analytics";
import { logError } from "utils/logging";

export type ConnectionRenameModalProps = {
  connection: ConnectionPreviewLargeFragment;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

export const ConnectionRenameModal = (props: ConnectionRenameModalProps) => {
  const [name, setName] = useState(props.connection.name);

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();
  const logEvent = useLogEvent();

  const [updateConnectionMutation, { loading }] = useUpdateConnectionMutation();

  const editModalReset = () => {
    props.setShowModal(false);
    setErrorMessage(null);
  };

  const handleSubmit = async () => {
    logEvent({
      name: "apps_app_rename_click",
      properties: {
        connectionType: props.connection.connectionType,
      },
    });

    try {
      const { data } = await updateConnectionMutation({
        variables: {
          input: {
            id: props.connection.id,
            name: name,
          },
        },
        refetchQueries: ["ConnectionOverview", "AppDetailColumn"],
      });
      switch (data?.updateConnection.__typename) {
        case "UpdateConnectionResult":
          editModalReset();
          displaySuccessToast("Success: app name updated");
          break;
        case "ConnectionNotFoundError":
          logError(new Error(data.updateConnection.message));
          setErrorMessage(data.updateConnection.message);
          break;
        default:
          logError(new Error(`failed to update app name`));
          setErrorMessage("Error: failed to update app name");
      }
    } catch (error) {
      logError(new Error(`failed to update app name`));
      setErrorMessage("Error: failed to update app name");
    }
  };

  return (
    <Modal title="Rename" isOpen={props.showModal} onClose={editModalReset}>
      <Modal.Body>
        <Input value={name} onChange={setName} />
        {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Save"
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLoading={loading}
        primaryButtonDisabled={name === props.connection.name}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={editModalReset}
      />
    </Modal>
  );
};
