import { EventFilterFields, ResourceFragment } from "api/generated/graphql";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import React from "react";
import { EventsTable } from "views/events/EventsTable";

type ResourceEventsRowProps = {
  resource: ResourceFragment;
};

export const ResourceEventsRow = (props: ResourceEventsRowProps) => {
  const eventFilter: EventFilterFields = {
    objects: {
      objectId: props.resource.id,
    },
  };

  const eventsTable = (
    <EventsTable
      eventFilter={eventFilter}
      emptyTitle={`No events for this resource`}
      emptySubtitle={`Interact with this resource to add events`}
    />
  );

  return (
    <EntityViewerRow title={"Events"} content={eventsTable} isTable={true} />
  );
};

export default ResourceEventsRow;
