import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";

interface EntityCountOptionProps {
  name: string;
  count?: number;
  entityType?: string;
  parentResourceName?: string;
}

const EntityCountOption = (props: EntityCountOptionProps) => {
  return (
    <span>
      {props.name}
      {props.parentResourceName !== undefined && (
        <div className={sprinkles({ color: "gray500", fontSize: "labelMd" })}>
          {props.parentResourceName}
        </div>
      )}
      {props.count !== undefined && props.entityType !== undefined && (
        <div className={sprinkles({ color: "gray500", fontSize: "labelXs" })}>
          {props.count} {pluralize(props.entityType, props.count)}
        </div>
      )}
    </span>
  );
};

export default EntityCountOption;
