import { getModifiedErrorMessage } from "api/ApiContext";
import {
  IntegrationType,
  Maybe,
  ThirdPartyProvider,
  useCreateThirdPartyIntegrationMutation,
  useInitThirdPartyIntegrationLinkTokenMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { IntegrationInfo } from "components/integrations/OrgIntegrationApp";
import { useToast } from "components/toast/Toast";
import { useContext } from "react";
import { logError } from "utils/logging";
import { IntegrationButton } from "views/settings/third_party_authorizer/IntegrationButton";

export type ThirdPartyLinkFlowAuthorizerProps = {
  integrationType: IntegrationType;
  integrationInfo: IntegrationInfo;
  isConnected: boolean;
  openThirdPartyProvider: Maybe<ThirdPartyProvider>;
  setOpenThirdPartyProvider: (integration: Maybe<ThirdPartyProvider>) => void;
  connectionButtonDisabled?: boolean;
  simpleButton?: boolean;
};

// NOT IN USE RIGHT NOW WITH MERGE INTEGRATION GONE
export const ThirdPartyLinkFlowAuthorizer = (
  props: ThirdPartyLinkFlowAuthorizerProps
) => {
  const { authState } = useContext(AuthContext);
  const { displayErrorToast } = useToast();

  const [
    createLinkToken,
    { loading: createLinkTokenLoading },
  ] = useInitThirdPartyIntegrationLinkTokenMutation();

  const [
    ,
    { loading: callbackLoading },
  ] = useCreateThirdPartyIntegrationMutation();

  return (
    <IntegrationButton
      integrationType={props.integrationType}
      integrationInfo={props.integrationInfo}
      isConnected={props.isConnected}
      setOpenThirdPartyProvider={props.setOpenThirdPartyProvider}
      simpleButton={props.simpleButton}
      onConnectClick={async () => {
        try {
          const { data } = await createLinkToken();
          switch (data?.initThirdPartyIntegrationLinkAuthFlow.__typename) {
            case "InitThirdPartyIntegrationLinkAuthFlowResult":
              break;
            default:
              logError(new Error(`failed to create link token`));
              displayErrorToast(`failed to create link token`);
          }
        } catch (error) {
          logError(error, `failed to create link token: ${error}`);
          displayErrorToast(
            getModifiedErrorMessage(`failed to create link token`, error)
          );
        }
      }}
      buttonDisabled={
        createLinkTokenLoading ||
        callbackLoading ||
        props.connectionButtonDisabled ||
        !authState.user?.isAdmin
      }
    />
  );
};
