import {
  ConnectionMetadataOutputFragment,
  ConnectionType,
  Maybe,
  ResourceType,
  ServiceType,
} from "api/generated/graphql";
import {
  OPAL_GLOBAL_IMPERSONATION_REMOTE_ID,
  OPAL_IMPERSONATION_REMOTE_ID,
  OPAL_PROD_GLOBAL_IMPERSONATION_REMOTE_ID,
} from "utils/constants";

// These connections are group providers.
const groupConnectionTypes = new Set([
  ConnectionType.ActiveDirectory,
  ConnectionType.GitHub,
  ConnectionType.GitLab,
  ConnectionType.GoogleGroups,
  ConnectionType.Ldap,
  ConnectionType.OktaDirectory,
  ConnectionType.Opal,
  ConnectionType.Duo,
  ConnectionType.AwsSso,
  ConnectionType.AzureAd,
  ConnectionType.CustomConnector,
]);

// These connections are both group and resource providers.
const groupAndResourceConnectionTypes = [
  ConnectionType.GitHub,
  ConnectionType.GitLab,
  ConnectionType.OktaDirectory,
  ConnectionType.Opal,
  ConnectionType.AwsSso,
  ConnectionType.AzureAd,
  ConnectionType.CustomConnector,
];

// These are connections that support a users list.
const userConnectionTypes = new Set([
  ConnectionType.GoogleWorkspace,
  ConnectionType.Salesforce,
  ConnectionType.OktaDirectory,
  ConnectionType.Pagerduty,
  ConnectionType.Duo,
  ConnectionType.Workday,
  ConnectionType.CustomConnector,
  // ConnectionType.AwsSso, TODO: Add this back in when we support a UsersPuller
]);

export const isConnectionTypeResourcesProvider = (
  connectionType: ConnectionType
) => {
  return (
    !isConnectionTypeGroupsProvider(connectionType) ||
    groupAndResourceConnectionTypes.indexOf(connectionType) !== -1
  );
};

export const isConnectionTypeGroupsProvider = (
  connectionType: ConnectionType
) => {
  return groupConnectionTypes.has(connectionType);
};

export const isConnectionTypeUsersProvider = (
  connectionType: ConnectionType
) => {
  return userConnectionTypes.has(connectionType);
};

export const connectionTypeAllowsCreateGroup = (
  connectionType: ConnectionType
) => {
  return [
    ConnectionType.Opal,
    ConnectionType.OktaDirectory,
    ConnectionType.GoogleGroups,
  ].includes(connectionType);
};

export const connectionTypesByServiceType: Record<
  ServiceType,
  ConnectionType[]
> = {
  [ServiceType.ActiveDirectory]: [ConnectionType.ActiveDirectory],
  [ServiceType.AwsIam]: [ConnectionType.Aws],
  [ServiceType.AwsSso]: [ConnectionType.AwsSso],
  [ServiceType.Custom]: [ConnectionType.Custom],
  [ServiceType.CustomConnector]: [ConnectionType.CustomConnector],
  [ServiceType.Duo]: [ConnectionType.Duo],
  [ServiceType.GcpIam]: [ConnectionType.Gcp],
  [ServiceType.GcpStorage]: [ConnectionType.Gcp],
  [ServiceType.GcpBigQuery]: [ConnectionType.Gcp],
  [ServiceType.GitHub]: [ConnectionType.GitHub],
  [ServiceType.GitLab]: [ConnectionType.GitLab],
  [ServiceType.GoogleGroups]: [ConnectionType.GoogleGroups],
  [ServiceType.GoogleWorkspace]: [ConnectionType.GoogleWorkspace],
  [ServiceType.Kubernetes]: [ConnectionType.GoogleGroups],
  [ServiceType.Ldap]: [ConnectionType.Ldap],
  [ServiceType.Mongo]: [ConnectionType.Mongo],
  [ServiceType.MongoAtlas]: [ConnectionType.MongoAtlas],
  [ServiceType.Mysql]: [
    ConnectionType.Aws,
    ConnectionType.AwsSso,
    ConnectionType.Gcp,
    ConnectionType.Mysql,
  ],
  [ServiceType.Mariadb]: [ConnectionType.Mariadb],
  [ServiceType.OktaDirectory]: [ConnectionType.OktaDirectory],
  [ServiceType.Opal]: [ConnectionType.Opal],
  [ServiceType.Pagerduty]: [ConnectionType.Pagerduty],
  [ServiceType.Postgres]: [
    ConnectionType.Aws,
    ConnectionType.AwsSso,
    ConnectionType.Gcp,
    ConnectionType.Postgres,
  ],
  [ServiceType.Salesforce]: [ConnectionType.Salesforce],
  [ServiceType.Ssh]: [
    ConnectionType.Aws,
    ConnectionType.AwsSso,
    ConnectionType.Gcp,
  ],
  [ServiceType.Tailscale]: [ConnectionType.Tailscale],
  [ServiceType.Unknown]: [],
  [ServiceType.Workday]: [],
  [ServiceType.Teleport]: [ConnectionType.Teleport],
  [ServiceType.AzureAd]: [ConnectionType.AzureAd],
  [ServiceType.Snowflake]: [ConnectionType.Snowflake],
};

export const hasPolicyType = (resourceType: ResourceType) => {
  switch (resourceType) {
    case ResourceType.MongoInstance:
      return true;
    case ResourceType.MongoAtlasInstance:
      return true;
    case ResourceType.MysqlInstance:
    case ResourceType.MariadbInstance:
    case ResourceType.PostgresInstance:
      return true;
    case ResourceType.OktaApp:
      return true;
  }
  return false;
};

/**
 * Connection types that can support sessions tracked in Opal.
 */
export const isSessionableType = (
  connectionType: ConnectionType,
  remoteId?: string,
  resourceType?: ResourceType
) => {
  if (connectionType === ConnectionType.Opal) {
    const isImpersonationResource = remoteId === OPAL_IMPERSONATION_REMOTE_ID;

    const isGlobalImpersonationResource =
      remoteId === OPAL_GLOBAL_IMPERSONATION_REMOTE_ID;

    const isProdGlobalImpersonationResource =
      remoteId === OPAL_PROD_GLOBAL_IMPERSONATION_REMOTE_ID;

    return (
      isImpersonationResource ||
      isGlobalImpersonationResource ||
      isProdGlobalImpersonationResource
    );
  }

  switch (connectionType) {
    case ConnectionType.Aws:
    case ConnectionType.AwsSso:
    case ConnectionType.Mongo:
    case ConnectionType.MongoAtlas:
    case ConnectionType.Mysql:
    case ConnectionType.Mariadb:
    case ConnectionType.Postgres:
      return true;
    case ConnectionType.AzureAd:
      return (
        resourceType === ResourceType.AzureSqlDatabase ||
        resourceType === ResourceType.AzureSqlManagedDatabase
      );
    default:
      return false;
  }
};

/**
 * Whether this connection type supports group resources on the end system.
 */
export const hasRemoteGroupResources = (connectionType: ConnectionType) => {
  return (
    connectionType === ConnectionType.AzureAd ||
    connectionType === ConnectionType.OktaDirectory ||
    connectionType === ConnectionType.GitHub ||
    connectionType === ConnectionType.Snowflake
  );
};

/**
 * Whether this connection type uses a nested resource structure.
 */
export const connectionTypeHasNestedResources = (
  connectionType: ConnectionType,
  metadata?: Maybe<ConnectionMetadataOutputFragment>
) => {
  switch (connectionType) {
    case ConnectionType.Gcp:
    case ConnectionType.AzureAd:
    case ConnectionType.Snowflake:
      return true;
    case ConnectionType.CustomConnector:
      return (
        metadata?.__typename === "CustomConnectorConnectionMetadata" &&
        metadata.supportsNestedResources
      );
    default:
      return false;
  }
};

/**
 * Whether this connection and resource type supports resource principals.
 */
export const nhiConnectionTypes = new Set([
  ConnectionType.Gcp,
  ConnectionType.AzureAd,
]);

export const connectionAndResourceTypeCanHaveNHIs = (
  connectionType?: ConnectionType,
  resourceType?: ResourceType
) => {
  if (connectionType === undefined) return false;
  if (resourceType === ResourceType.AzureEntraIdRole) return false;
  return nhiConnectionTypes.has(connectionType);
};
