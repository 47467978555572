import {
  EntityType,
  HrIdpStatus,
  Maybe,
  UserPreviewLargeFragment,
} from "api/generated/graphql";
import { ResourceLabel } from "components/label/Label";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import MuiVirtualTable, {
  CellRow,
} from "components/tables/material_table/MuiVirtualTable";
import { UsersTableHeaders } from "components/tables/rows/UsersTableRow";
import { Icon } from "components/ui";
import _ from "lodash";
import * as Icons from "react-feather";
import { useHistory } from "react-router";
import { IndexRange, SortDirection } from "react-virtualized";
import useLogEvent from "utils/analytics";
import { getResourceUrlNew } from "utils/common";

type UsersTableRow = {
  name: string;
  position: string;
  manager: string;
};

type UsersTableProps = {
  users: Maybe<UserPreviewLargeFragment>[];
  loadMoreRows?: (params: IndexRange) => Promise<void>;
  allRowsLoaded: boolean;
  emptyTitle?: string;
  emptySubtitle?: string;
};

export const UsersTable = (props: UsersTableProps) => {
  const history = useHistory();
  const logEvent = useLogEvent();

  let users = props.users;
  let userRows: CellRow<UsersTableRow>[] = users.map((user) => {
    const name = user?.fullName || "";
    const position = user?.position || "";
    let managerCell;
    if (user?.manager) {
      managerCell = {
        value: user.manager.fullName,
        element: (
          <ResourceLabel
            text={user.manager.fullName}
            entityId={user.manager.id}
            entityTypeNew={EntityType.User}
            avatar={user.manager.avatarUrl}
          />
        ),
      };
    } else {
      managerCell = {
        value: "",
        element: <>--</>,
      };
    }

    return {
      id: user?.id || "",
      data: {
        name: {
          value: name,
          element: (
            <ResourceLabel
              text={name}
              subText={user?.teamAttr}
              iconLarge={true}
              bold={true}
              entityTypeNew={EntityType.User}
              avatar={user?.avatarUrl}
              warningIcon={
                user?.hrIdpStatus !== HrIdpStatus.Active ? (
                  <Icon name="user-x" size="xxs" color="red600" />
                ) : undefined
              }
              warningTooltipText={
                user?.hrIdpStatus !== HrIdpStatus.Active
                  ? `User IDP status is "${_.startCase(
                      _.camelCase(user?.hrIdpStatus?.toString() ?? "Unknown")
                    )}"`
                  : undefined
              }
              inactive={user?.hrIdpStatus !== HrIdpStatus.Active}
            />
          ),
          clickHandler: () => {
            logEvent({
              name: "table_row_click",
              properties: {
                entityID: user?.id ?? "",
                entityName: name,
                entityType: EntityType.User,
              },
            });
            history.push(
              getResourceUrlNew({
                entityId: user?.id ?? null,
                entityType: EntityType.User,
              })
            );
          },
        },
        position: {
          value: position,
          element: <>{position || "--"}</>,
        },
        manager: managerCell,
      },
    };
  });

  return (
    <EmptyStateContentWrapper
      content={
        <MuiVirtualTable
          columns={UsersTableHeaders}
          rows={userRows}
          defaultSortBy={"name"}
          defaultSortDirection={SortDirection.ASC}
          allRowsLoaded={props.allRowsLoaded}
          loadMoreRows={props.loadMoreRows}
        />
      }
      isEmpty={userRows.length === 0}
      icon={<Icons.User strokeWidth={2} />}
      title={props.emptyTitle || ""}
      subtitle={props.emptySubtitle || ""}
      small={false}
    />
  );
};

export default UsersTable;
