import {
  GroupUserFragment,
  PropagationStatusCode,
} from "api/generated/graphql";

export const TOOLTIP_ORG_ACCESS_MANAGEMENT_DISABLED = `Your organization has not enabled managing access from Opal.`;

export const getReadOnlyTooltipText = (
  action: string,
  readOnlyEntity: string
) => {
  return `You can't ${action} because the ${readOnlyEntity} is configured to have the source of truth for its
   synced state be the service it syncs from, rather than Opal.`;
};

/**
 * @param groupUser
 */
export const groupUserHasDirectAccess = (
  groupUser: GroupUserFragment
): boolean => {
  return !!groupUser.access?.directAccessPoint;
};

export const calculateMaxDurationInMinutes = (
  orgMaxDuration: number | null | undefined,
  itemMaxDuration: number | null | undefined
): number | null | undefined => {
  if (orgMaxDuration && itemMaxDuration) {
    return Math.min(orgMaxDuration, itemMaxDuration);
  }
  return orgMaxDuration || itemMaxDuration;
};

type ConditionalWrapperProps = {
  useWrapper: boolean;
  wrapper: (children: React.ReactNode) => React.ReactNode;
  children: React.ReactNode;
};
/**
 * ConditionalWrapper wraps the children with wrapper based on a given condition
 * Example usage:
 *      <ConditionalWrapper
          wrapper={(children) => (
            <Tooltip tooltipText="Tooltip">
              {children}
            </Tooltip>
          )}
          useWrapper={showTooltop}
        >
        <Button>Accept</Button>
      </ConditionalFeatureWrapper>
 */
export const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({
  useWrapper,
  wrapper,
  children,
}) => {
  return <>{useWrapper ? wrapper(children) : children}</>;
};

export const AVAILABLE_PROPAGATION_STATUSES = [
  {
    values: [PropagationStatusCode.Success],
    label: "Success",
  },
  {
    values: [PropagationStatusCode.Pending],
    label: "Pending",
  },
  {
    values: [
      PropagationStatusCode.PendingManualPropagation,
      PropagationStatusCode.PendingTicketCreation,
    ],
    label: "Pending Manual Propagation",
  },
  {
    values: [
      PropagationStatusCode.ErrTimeout,
      PropagationStatusCode.ErrUnknown,
      PropagationStatusCode.ErrOpalInternalError,
      PropagationStatusCode.ErrRemoteUserNotLinked,
      PropagationStatusCode.ErrRemoteProvisioningViaIdpFailed,
      PropagationStatusCode.ErrRemoteUserNotFound,
    ],
    label: "Failure",
  },
];
