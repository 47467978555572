import {
  AddGroupTagInput,
  ConnectionType,
  EntityType,
  Group,
  GroupDropdownPreviewFragment,
  GroupType,
  useAddGroupTagsMutation,
  useConnectionsSummaryQuery,
  usePaginatedGroupDropdownLazyQuery,
  useSearchGroupsQuery,
  useTagQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import FullscreenViewTitle from "components/fullscreen_modals/FullscreenViewTitle";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import {
  Banner,
  Divider,
  EntityIcon,
  Icon,
  Input,
  Label,
  Loader,
} from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import { IconData } from "components/ui/utils";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useParams } from "react-router";
import { useDebouncedValue } from "utils/hooks";
import { logError, logWarning } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import { ForbiddenPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";

import * as styles from "./TagAddGroupsView.css";

const PAGE_SIZE = 100;

interface TagGroupRow {
  id: string;
  icon?: IconData;
  name: string;
  sublabel?: string;
  connectionType?: ConnectionType;
  connectionId?: string;
  groupType?: GroupType;
  isEmpty?: boolean;
}

const TagAddGroupsView = () => {
  const transitionBack = useTransitionBack();
  const { tagId } = useParams<Record<string, string>>();
  const { displaySuccessToast } = useToast();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  const [groupById, setGroupById] = useState<{
    [groupId: string]: GroupDropdownPreviewFragment;
  }>({});
  const [groupsByConnectionId, setGroupsByConnectionId] = useState<{
    [connectionId: string]: GroupDropdownPreviewFragment[];
  }>({});
  const [groupsToAddByGroupId, setGroupsToAddByGroupId] = useState<
    Record<string, Group>
  >({});
  const [selectedRowIds, setSelectedRowIds] = useState<Set<string>>(new Set());
  const rowsById: Record<string, TagGroupRow> = {};
  const [itemsLoadingSubRows, setItemsLoadingSubRows] = useState<string[]>([]);
  const [addGroupsErrorMessage, setAddGroupsErrorMessage] = useState("");
  const numGroupsToAdd = Object.keys(groupsToAddByGroupId).length;

  const COLUMNS: Header<TagGroupRow>[] = [
    {
      id: "name",
      label: "Name",
      sortable: true,
      customCellRenderer: (row) => {
        return (
          <div className={styles.nameCell}>
            <Label label={row.name} icon={row.icon} />
            {itemsLoadingSubRows.includes(row.id) && <Loader size="xs" />}
          </div>
        );
      },
      width: 500,
    },
  ];

  const { authState } = useContext(AuthContext);

  // Get Tag Data
  const { data: tagData, loading: tagLoading, error: tagError } = useTagQuery({
    variables: { input: { id: tagId ?? "" } },
    fetchPolicy: "cache-and-network",
    skip: tagId == null,
  });

  const tag =
    tagData?.tag.__typename === "TagResult" ? tagData.tag.tag : undefined;

  const tagGroupIds = new Set(tag?.tagGroups.map((group) => group.groupId));

  const disabledGroupIds = new Set();

  Object.keys(groupById).forEach((groupId) => {
    if (tagGroupIds.has(groupId)) {
      disabledGroupIds.add(groupId);
    }
  });

  // Get Connection Data
  const {
    data: connectionsData,
    loading: connectionsLoading,
    error: connectionsError,
  } = useConnectionsSummaryQuery({
    variables: { input: {} },
    fetchPolicy: "cache-and-network",
    skip: tagId == null,
  });

  const allConnections = connectionsData?.connections.connections ?? [];
  const connections = allConnections.filter((connection) =>
    Boolean(connection.numGroups)
  );

  // Allow searching directly for groups
  const {
    data: searchGroupsData,
    loading: searchGroupsLoading,
    error: searchGroupsError,
  } = useSearchGroupsQuery({
    variables: {
      query: debouncedSearchQuery,
      maxNumEntries: PAGE_SIZE,
    },
    skip: debouncedSearchQuery === "",
  });

  useEffect(() => {
    setGroupById((groupById) => {
      return {
        ...groupById,
        ...searchGroupsData?.groups.groups.reduce((acc, group) => {
          acc[group.id] = group;
          return acc;
        }, {} as typeof groupById),
      };
    });
  }, [searchGroupsData]);

  const [getGroups] = usePaginatedGroupDropdownLazyQuery();
  const [addGroupTags, { loading: addLoading }] = useAddGroupTagsMutation();

  if (tagLoading || connectionsLoading) {
    return <FullscreenSkeleton />;
  }
  if (!tag || tagError || connectionsError) {
    return <UnexpectedErrorPage error={tagError || connectionsError} />;
  }

  if (!authState.user?.isAdmin) {
    return <ForbiddenPage />;
  }

  // Redirect to groups tab of tags after close
  const handleClose = () => {
    transitionBack(`/tags/${tagId}/#groups`);
  };

  // Fetch groups for a connection
  const handleFetchGroups = async (connectionId: string) => {
    try {
      setItemsLoadingSubRows((prev) => [...prev, connectionId]);
      const { data } = await getGroups({
        variables: {
          input: {
            connectionIds: [connectionId],
            maxNumEntries: PAGE_SIZE,
          },
        },
      });

      // Map connectionId to group
      setGroupsByConnectionId((groupsByConnectionId) => {
        return {
          ...groupsByConnectionId,
          [connectionId]: data?.groups.groups ?? [],
        };
      });

      // Map groupId to group
      setGroupById((groupById) => {
        return {
          ...groupById,
          ...data?.groups.groups.reduce((acc, group) => {
            acc[group.id] = group;
            if (tagGroupIds.has(group.id)) {
              disabledGroupIds.add(group.id);
            }
            return acc;
          }, {} as typeof groupById),
        };
      });
      setItemsLoadingSubRows((prev) =>
        prev.filter((id) => id !== connectionId)
      );
      return data?.groups.groups ?? [];
    } catch (err) {
      logError(err, "Failed to fetch groups for connection " + connectionId);
    }
  };

  const hasNestedRows = (row: TagGroupRow) => {
    return Boolean(row.connectionType);
  };

  const getNestedRows = (row: TagGroupRow) => {
    const groups = groupsByConnectionId[row.id];
    if (groups && groups.length === 0) {
      return [
        {
          id: `${row.id}-empty`,
          name: "No groups",
          isEmpty: true,
        },
      ];
    }
    return groups?.map((group) => {
      const iconData: IconData = {
        type: "entity",
        entityType: group.groupType,
      };
      const row: TagGroupRow = {
        id: group.id,
        icon: iconData,
        name: group.name,
        groupType: group.groupType,
        connectionId: group?.connection?.id,
      };
      rowsById[row.id] = row;
      return row;
    });
  };

  // Add groups to tag
  const handleSubmit = async () => {
    if (tag === null) {
      return;
    }
    const groupTagsToAdd: AddGroupTagInput[] = Object.keys(
      groupsToAddByGroupId
    ).map((id) => ({
      tagId: tag !== null ? tag.id : "",
      groupId: id,
    }));

    try {
      const { data } = await addGroupTags({
        variables: {
          input: {
            groupTags: groupTagsToAdd,
          },
        },
        refetchQueries: ["GroupTags", "Tag"],
      });
      switch (data?.addGroupTags.__typename) {
        case "AddGroupTagsResult": {
          const groupTags = data.addGroupTags.entries
            .filter((entry) => entry.__typename === "AddGroupTagsEntryResult")
            .map((entry) => entry.groupTag);

          if (groupTags.length !== groupTagsToAdd.length) {
            setAddGroupsErrorMessage(
              `Error: tag was not added to selected groups successfully`
            );
          } else {
            handleClose();
            displaySuccessToast(
              `Success: tag added to ${pluralize(
                "groups",
                groupTagsToAdd.length,
                true
              )}`
            );
          }
          break;
        }
        case "GroupNotFoundError":
        case "TagNotFoundError": {
          logWarning(new Error(data.addGroupTags.message));
          setAddGroupsErrorMessage(data.addGroupTags.message);
          break;
        }
        default:
          logError(new Error(`failed to add tag to groups`));
          setAddGroupsErrorMessage("Error: failed to add tag to groups");
      }
    } catch (error) {
      logError(error, `failed to add tag to groups`);
      setAddGroupsErrorMessage("Error: failed to add tag to groups");
    }
  };

  const getCheckboxDisabledReason = (row: TagGroupRow) => {
    if (row?.isEmpty) {
      return "No groups";
    }
    if (disabledGroupIds.has(row.id)) {
      return "Already in tag";
    }
  };

  const onCheckedRowsChange = (checkedRowIds: string[], checked: boolean) => {
    if (checked) {
      setSelectedRowIds((prev) => {
        checkedRowIds.forEach((checkedRowId) => prev.add(checkedRowId));
        return prev;
      });
    } else {
      setSelectedRowIds((selectedRowIds) => {
        const filteredRowIds: Set<string> = new Set();
        selectedRowIds.forEach((id) => {
          if (!checkedRowIds.includes(id)) {
            filteredRowIds.add(id);
          }
        });
        return filteredRowIds;
      });
    }
    checkedRowIds.forEach((id) => {
      const row = rowsById[id];
      onCheckRow(row, checked);
    });
  };

  const onCheckRow = async (row: TagGroupRow, checked: boolean) => {
    if (checked) {
      setSelectedRowIds((selectedRowIds) => {
        selectedRowIds.add(row.id);
        return selectedRowIds;
      });
      if (!hasNestedRows(row)) {
        setGroupsToAddByGroupId((prev) => {
          return {
            ...prev,
            [row.id]: row as Group,
          };
        });
      }
    } else {
      setSelectedRowIds((selectedRowIds) => {
        const filteredRowIds: Set<string> = new Set();
        selectedRowIds.forEach((id) => {
          if (id !== row.id) {
            filteredRowIds.add(id);
          }
        });
        return filteredRowIds;
      });
      if (!hasNestedRows(row)) {
        setGroupsToAddByGroupId((prev) => {
          const prevGroups = { ...prev };
          delete prevGroups[row.id];
          return prevGroups;
        });
      }
    }
    if (hasNestedRows(row)) {
      let children: GroupDropdownPreviewFragment[] | undefined;
      if (row.connectionType) {
        if (groupsByConnectionId[row.id]) {
          children = groupsByConnectionId[row.id];
        } else {
          children = await handleFetchGroups(row.id);
        }
      }
      ReactDOM.unstable_batchedUpdates(() => {
        children
          ?.filter((group) => !disabledGroupIds.has(group.id))
          .forEach((child) => {
            updateChildRows(child, checked);
          });
      });
    }
  };

  const updateChildRows = async (row: TagGroupRow, checked: boolean) => {
    if (checked) {
      try {
        setSelectedRowIds((selectedRowIds) => {
          selectedRowIds.add(row.id);
          return selectedRowIds;
        });
        if (hasNestedRows(row)) {
          let children: GroupDropdownPreviewFragment[] | undefined;
          if (row.connectionType) {
            if (groupsByConnectionId[row.id]) {
              children = groupsByConnectionId[row.id];
            } else {
              children = await handleFetchGroups(row.id);
            }
          }
          ReactDOM.unstable_batchedUpdates(() => {
            children
              ?.filter((group) => !disabledGroupIds.has(group.id))
              .forEach((child) => {
                updateChildRows(child, checked);
              });
          });
        } else {
          setGroupsToAddByGroupId((prev) => {
            return {
              ...prev,
              [row.id]: row as Group,
            };
          });
        }
      } catch (err) {
        logError(err, "Failed to fetch groups for connection");
      }
    } else {
      setSelectedRowIds((selectedRowIds) => {
        const filteredRowIds: Set<string> = new Set();
        selectedRowIds.forEach((id) => {
          if (id !== row.id && !disabledGroupIds.has(id)) {
            filteredRowIds.add(id);
          }
        });
        return filteredRowIds;
      });
      if (hasNestedRows(row)) {
        const children = getNestedRows(row);
        children?.forEach((child) => {
          updateChildRows(child, checked);
        });
      } else {
        setGroupsToAddByGroupId((prev) => {
          const prevGroups = { ...prev };
          delete prevGroups[row.id];
          return prevGroups;
        });
      }
    }
  };

  const onRowClick = async (row: TagGroupRow) => {
    if (row.isEmpty || disabledGroupIds.has(row.id)) {
      return;
    }
    if (hasNestedRows(row)) {
      // Retrieve all subitems on first click
      if (row.connectionType && !groupsByConnectionId[row.id]) {
        handleFetchGroups(row.id);
      }
    } else {
      // Select all subitems of row
      onCheckRow(row, !selectedRowIds.has(row.id));
    }
  };

  const renderConnectionsList = () => {
    const rows: TagGroupRow[] = connections.map((connection) => {
      const row: TagGroupRow = {
        id: connection.id,
        icon: {
          type: "src",
          icon: getConnectionTypeInfo(connection.connectionType)?.icon,
        },
        name: connection.name,
        connectionType: connection.connectionType,
      };
      rowsById[row.id] = row;
      return row;
    });

    return (
      <Table
        columns={COLUMNS}
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        getRowCanExpand={(row) => hasNestedRows(row.original)}
        loadingRows={tagLoading || connectionsLoading || searchGroupsLoading}
        defaultSortBy="name"
        checkedRowIds={selectedRowIds}
        onCheckedRowsChange={onCheckedRowsChange}
        getCheckboxDisabledReason={getCheckboxDisabledReason}
        onRowClick={onRowClick}
        onExpandRow={(row) => {
          if (row.connectionType && !groupsByConnectionId[row.id]) {
            handleFetchGroups(row.id);
          }
        }}
        getChildRows={getNestedRows}
        expandOnChecked={true}
        expandOnRowClick={true}
      />
    );
  };

  const renderSearchList = () => {
    if (searchGroupsError) {
      return <ModalErrorMessage errorMessage={searchGroupsError.message} />;
    }

    const filteredGroups = (searchGroupsData?.groups.groups ?? []).filter(
      (group) => !disabledGroupIds.has(group.id)
    );

    const rows: TagGroupRow[] = filteredGroups.map((group) => {
      const iconData: IconData = {
        type: "entity",
        entityType: group.groupType,
      };
      const row: TagGroupRow = {
        id: group.id,
        icon: iconData,
        name: group.name,
        groupType: group.groupType,
        connectionId: group?.connection?.id,
      };
      rowsById[row.id] = row;
      return row;
    });

    return (
      <Table
        columns={COLUMNS}
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        getRowCanExpand={(row) => hasNestedRows(row.original)}
        loadingRows={tagLoading || connectionsLoading || searchGroupsLoading}
        defaultSortBy="name"
        checkedRowIds={selectedRowIds}
        onCheckedRowsChange={onCheckedRowsChange}
        getCheckboxDisabledReason={getCheckboxDisabledReason}
        onRowClick={onRowClick}
        getChildRows={getNestedRows}
      />
    );
  };

  return (
    <FullscreenView
      title={
        <FullscreenViewTitle
          entityType={EntityType.Tag}
          entityName={tag.key}
          targetEntityName="Groups"
          action="add"
        />
      }
      onCancel={handleClose}
      onPrimaryButtonClick={handleSubmit}
      primaryButtonDisabled={numGroupsToAdd === 0}
      primaryButtonLabel={`Add ${
        numGroupsToAdd ? numGroupsToAdd : ""
      } ${pluralize("group", numGroupsToAdd)}`}
      primaryButtonLoading={addLoading}
    >
      <FullscreenView.Content fullWidth>
        <div className={styles.headerContainer}>
          <div className={styles.header}>Select groups to add to the tag:</div>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder="Search by name"
              autoFocus
            />
          </div>
          <div className={styles.resultsHint}>
            {debouncedSearchQuery === ""
              ? "Showing first 100 groups in each app. Use search to find more results."
              : "Showing first 100 search results. Refine your search to find more."}
          </div>
          <Divider />
          {debouncedSearchQuery === ""
            ? renderConnectionsList()
            : renderSearchList()}
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {addGroupsErrorMessage && (
          <Banner
            message={addGroupsErrorMessage}
            type="error"
            marginBottom="lg"
          />
        )}
        <div className={styles.addLabel}>
          Adding {numGroupsToAdd} {pluralize("Group", numGroupsToAdd)}
        </div>
        {Object.keys(groupsToAddByGroupId).map((groupId) => {
          const group = groupById[groupId];
          if (!group) {
            return null;
          }

          return (
            <GroupCard
              key={group.id}
              group={group}
              onRemove={() => {
                setGroupsToAddByGroupId((prev) => {
                  const prevGroups = { ...prev };
                  delete prevGroups[groupId];
                  return prevGroups;
                });
                setSelectedRowIds((selectedRowIds) => {
                  selectedRowIds.delete(group.id);
                  return selectedRowIds;
                });
              }}
            />
          );
        })}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};

interface Props {
  group: GroupDropdownPreviewFragment;
  onRemove: () => void;
}

export const GroupCard = (props: Props) => {
  const { group } = props;

  if (!group.connection) {
    return null;
  }

  return (
    <div key={group.id} className={styles.resourceCard}>
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "flex-start",
          gap: "sm",
        })}
      >
        <div
          className={sprinkles({
            flexShrink: 0,
          })}
        >
          <EntityIcon
            type={group.connection.connectionType}
            iconStyle="rounded"
          />
        </div>
        <div className={styles.resourceInfoSection}>
          <div className={styles.resourceCardHeader}>{group.name}</div>
          <div className={styles.resourceCardSubtitle}>
            {group.connection.name}
          </div>
          <div className={styles.resourceCardType}>
            <EntityIcon type={group.groupType} includeBrand={false} />
            {groupTypeInfoByType[group.groupType].name}
          </div>
        </div>
        <div className={sprinkles({ flexShrink: 0 })}>
          <Icon name="trash" color="red600V3" onClick={props.onRemove} />
        </div>
      </div>
    </div>
  );
};

export default TagAddGroupsView;
