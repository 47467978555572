import "reboot.css";
import "index.scss";

import App from "app/App";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import { CompatRouter } from "react-router-dom-v5-compat";
import { getLDProvider } from "utils/feature_flags";
import { logError } from "utils/logging";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

class ErrorBoundary extends React.Component {
  state = { error: null as Error | null };

  componentDidCatch(error: Error) {
    logError(error);
    this.setState({ error });
  }

  render() {
    if (this.state.error) {
      return <UnexpectedErrorPage error={this.state.error} />;
    }

    return this.props.children;
  }
}

window.onunhandledrejection = (evt) => {
  evt.preventDefault();
  let error = evt.reason;
  if (!(error instanceof Error)) {
    if (error) {
      error = new Error(error);
    } else {
      error = new Error("unknown unhandled promise rejection occurred");
    }
  }

  logError(error);
};

window.onerror = (msg, url, line, col, originalError) => {
  let error = originalError;
  if (!(error instanceof Error)) {
    if (error) {
      error = new Error(error);
    } else {
      if (typeof msg === "string") {
        error = new Error(msg);
      } else {
        error = new Error("unknown error occurred");
      }
    }
  }

  logError(error);
  return true;
};

(async () => {
  const LDProvider = await getLDProvider();

  ReactDOM.render(
    <React.StrictMode>
      <ErrorBoundary>
        <BrowserRouter>
          <CompatRouter>
            <LDProvider>
              <App />
            </LDProvider>
          </CompatRouter>
        </BrowserRouter>
      </ErrorBoundary>
    </React.StrictMode>,
    document.getElementById("root")
  );
})();
