import {
  Activity,
  Airplay,
  AlertCircle,
  AlertOctagon,
  AlertTriangle,
  AlignCenter,
  AlignJustify,
  AlignLeft,
  AlignRight,
  Anchor,
  Aperture,
  Archive,
  ArrowDown,
  ArrowDownCircle,
  ArrowDownLeft,
  ArrowDownRight,
  ArrowLeft,
  ArrowLeftCircle,
  ArrowRight,
  ArrowRightCircle,
  ArrowUp,
  ArrowUpCircle,
  ArrowUpLeft,
  ArrowUpRight,
  AtSign,
  Award,
  BarChart,
  BarChart2,
  Battery,
  BatteryCharging,
  Bell,
  BellOff,
  Bluetooth,
  Bold,
  Book,
  Bookmark,
  BookOpen,
  Box,
  Briefcase,
  Calendar,
  Camera,
  CameraOff,
  Cast,
  Check,
  CheckCircle,
  CheckSquare,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  ChevronsDown,
  ChevronsLeft,
  ChevronsRight,
  ChevronsUp,
  ChevronUp,
  Chrome,
  Circle,
  Clipboard,
  Clock,
  Cloud,
  CloudDrizzle,
  CloudLightning,
  CloudOff,
  CloudRain,
  CloudSnow,
  Code,
  Codepen,
  Codesandbox,
  Coffee,
  Columns,
  Command,
  Compass,
  Copy,
  CornerDownLeft,
  CornerDownRight,
  CornerLeftDown,
  CornerLeftUp,
  CornerRightDown,
  CornerRightUp,
  CornerUpLeft,
  CornerUpRight,
  Cpu,
  CreditCard,
  Crop,
  Crosshair,
  Database,
  Delete,
  Disc,
  Divide,
  DivideCircle,
  DivideSquare,
  DollarSign,
  Download,
  DownloadCloud,
  Dribbble,
  Droplet,
  Edit,
  Edit2,
  Edit3,
  ExternalLink,
  Eye,
  EyeOff,
  Facebook,
  FastForward,
  Feather,
  Figma,
  File,
  FileMinus,
  FilePlus,
  FileText,
  Film,
  Filter,
  Flag,
  Folder,
  FolderMinus,
  FolderPlus,
  Framer,
  Frown,
  Gift,
  GitBranch,
  GitCommit,
  GitHub,
  Gitlab,
  GitMerge,
  GitPullRequest,
  Globe,
  Grid,
  HardDrive,
  Hash,
  Headphones,
  Heart,
  HelpCircle,
  Hexagon,
  Home,
  Image,
  Inbox,
  Info,
  Instagram,
  Italic,
  Key,
  Layers,
  Layout,
  LifeBuoy,
  Link,
  Link2,
  Linkedin,
  List,
  Loader,
  Lock,
  LogIn,
  LogOut,
  Mail,
  Map,
  MapPin,
  Maximize,
  Maximize2,
  Meh,
  Menu,
  MessageCircle,
  MessageSquare,
  Mic,
  MicOff,
  Minimize,
  Minimize2,
  Minus,
  MinusCircle,
  MinusSquare,
  Monitor,
  Moon,
  MoreHorizontal,
  MoreVertical,
  MousePointer,
  Move,
  Music,
  Navigation,
  Navigation2,
  Octagon,
  Package,
  Paperclip,
  Pause,
  PauseCircle,
  PenTool,
  Percent,
  Phone,
  PhoneCall,
  PhoneForwarded,
  PhoneIncoming,
  PhoneMissed,
  PhoneOff,
  PhoneOutgoing,
  PieChart,
  Play,
  PlayCircle,
  Plus,
  PlusCircle,
  PlusSquare,
  Pocket,
  Power,
  Printer,
  Radio,
  RefreshCcw,
  RefreshCw,
  Repeat,
  Rewind,
  RotateCcw,
  RotateCw,
  Rss,
  Save,
  Scissors,
  Search,
  Send,
  Server,
  Settings,
  Share,
  Share2,
  Shield,
  ShieldOff,
  ShoppingBag,
  ShoppingCart,
  Shuffle,
  Sidebar,
  SkipBack,
  SkipForward,
  Slack,
  Slash,
  Sliders,
  Smartphone,
  Smile,
  Speaker,
  Square,
  Star,
  StopCircle,
  Sun,
  Sunrise,
  Sunset,
  Tablet,
  Tag,
  Target,
  Terminal,
  Thermometer,
  ThumbsDown,
  ThumbsUp,
  ToggleLeft,
  ToggleRight,
  Tool,
  Trash,
  Trash2,
  Trello,
  TrendingDown,
  TrendingUp,
  Triangle,
  Truck,
  Tv,
  Twitch,
  Twitter,
  Type,
  Umbrella,
  Underline,
  Unlock,
  Upload,
  UploadCloud,
  User,
  UserCheck,
  UserMinus,
  UserPlus,
  Users,
  UserX,
  Video,
  VideoOff,
  Voicemail,
  Volume,
  Volume1,
  Volume2,
  VolumeX,
  Watch,
  Wifi,
  WifiOff,
  Wind,
  X,
  XCircle,
  XOctagon,
  XSquare,
  Youtube,
  Zap,
  ZapOff,
  ZoomIn,
  ZoomOut,
} from "react-feather";

export const featherIconSet = {
  activity: Activity,
  airplay: Airplay,
  "alert-circle": AlertCircle,
  "alert-octagon": AlertOctagon,
  "alert-triangle": AlertTriangle,
  "align-center": AlignCenter,
  "align-justify": AlignJustify,
  "align-left": AlignLeft,
  "align-right": AlignRight,
  anchor: Anchor,
  aperture: Aperture,
  archive: Archive,
  "arrow-down-circle": ArrowDownCircle,
  "arrow-down-left": ArrowDownLeft,
  "arrow-down-right": ArrowDownRight,
  "arrow-down": ArrowDown,
  "arrow-left-circle": ArrowLeftCircle,
  "arrow-left": ArrowLeft,
  "arrow-right-circle": ArrowRightCircle,
  "arrow-right": ArrowRight,
  "arrow-up-circle": ArrowUpCircle,
  "arrow-up-left": ArrowUpLeft,
  "arrow-up-right": ArrowUpRight,
  "arrow-up": ArrowUp,
  "at-sign": AtSign,
  award: Award,
  "bar-chart-2": BarChart2,
  "bar-chart": BarChart,
  "battery-charging": BatteryCharging,
  battery: Battery,
  "bell-off": BellOff,
  bell: Bell,
  bluetooth: Bluetooth,
  bold: Bold,
  "book-open": BookOpen,
  book: Book,
  bookmark: Bookmark,
  box: Box,
  briefcase: Briefcase,
  calendar: Calendar,
  "camera-off": CameraOff,
  camera: Camera,
  cast: Cast,
  "check-circle": CheckCircle,
  "check-square": CheckSquare,
  check: Check,
  "chevron-down": ChevronDown,
  "chevron-left": ChevronLeft,
  "chevron-right": ChevronRight,
  "chevron-up": ChevronUp,
  "chevrons-down": ChevronsDown,
  "chevrons-left": ChevronsLeft,
  "chevrons-right": ChevronsRight,
  "chevrons-up": ChevronsUp,
  chrome: Chrome,
  circle: Circle,
  clipboard: Clipboard,
  clock: Clock,
  "cloud-drizzle": CloudDrizzle,
  "cloud-lightning": CloudLightning,
  "cloud-off": CloudOff,
  "cloud-rain": CloudRain,
  "cloud-snow": CloudSnow,
  cloud: Cloud,
  code: Code,
  codepen: Codepen,
  codesandbox: Codesandbox,
  coffee: Coffee,
  columns: Columns,
  command: Command,
  compass: Compass,
  copy: Copy,
  "corner-down-left": CornerDownLeft,
  "corner-down-right": CornerDownRight,
  "corner-left-down": CornerLeftDown,
  "corner-left-up": CornerLeftUp,
  "corner-right-down": CornerRightDown,
  "corner-right-up": CornerRightUp,
  "corner-up-left": CornerUpLeft,
  "corner-up-right": CornerUpRight,
  cpu: Cpu,
  "credit-card": CreditCard,
  crop: Crop,
  crosshair: Crosshair,
  database: Database,
  delete: Delete,
  disc: Disc,
  "divide-circle": DivideCircle,
  "divide-square": DivideSquare,
  divide: Divide,
  "dollar-sign": DollarSign,
  "download-cloud": DownloadCloud,
  download: Download,
  dribbble: Dribbble,
  droplet: Droplet,
  "edit-2": Edit2,
  "edit-3": Edit3,
  edit: Edit,
  "external-link": ExternalLink,
  "eye-off": EyeOff,
  eye: Eye,
  facebook: Facebook,
  "fast-forward": FastForward,
  feather: Feather,
  figma: Figma,
  "file-minus": FileMinus,
  "file-plus": FilePlus,
  "file-text": FileText,
  file: File,
  film: Film,
  filter: Filter,
  flag: Flag,
  "folder-minus": FolderMinus,
  "folder-plus": FolderPlus,
  folder: Folder,
  framer: Framer,
  frown: Frown,
  gift: Gift,
  "git-branch": GitBranch,
  "git-commit": GitCommit,
  "git-merge": GitMerge,
  "git-pull-request": GitPullRequest,
  github: GitHub,
  gitlab: Gitlab,
  globe: Globe,
  grid: Grid,
  "hard-drive": HardDrive,
  hash: Hash,
  headphones: Headphones,
  heart: Heart,
  "help-circle": HelpCircle,
  hexagon: Hexagon,
  home: Home,
  image: Image,
  inbox: Inbox,
  info: Info,
  instagram: Instagram,
  italic: Italic,
  key: Key,
  layers: Layers,
  layout: Layout,
  "life-buoy": LifeBuoy,
  "link-2": Link2,
  link: Link,
  linkedin: Linkedin,
  list: List,
  loader: Loader,
  lock: Lock,
  "log-in": LogIn,
  "log-out": LogOut,
  mail: Mail,
  "map-pin": MapPin,
  map: Map,
  "maximize-2": Maximize2,
  maximize: Maximize,
  meh: Meh,
  menu: Menu,
  "message-circle": MessageCircle,
  "message-square": MessageSquare,
  "mic-off": MicOff,
  mic: Mic,
  "minimize-2": Minimize2,
  minimize: Minimize,
  "minus-circle": MinusCircle,
  "minus-square": MinusSquare,
  minus: Minus,
  monitor: Monitor,
  moon: Moon,
  "more-horizontal": MoreHorizontal,
  "more-vertical": MoreVertical,
  "mouse-pointer": MousePointer,
  move: Move,
  music: Music,
  "navigation-2": Navigation2,
  navigation: Navigation,
  octagon: Octagon,
  package: Package,
  paperclip: Paperclip,
  "pause-circle": PauseCircle,
  pause: Pause,
  "pen-tool": PenTool,
  percent: Percent,
  "phone-call": PhoneCall,
  "phone-forwarded": PhoneForwarded,
  "phone-incoming": PhoneIncoming,
  "phone-missed": PhoneMissed,
  "phone-off": PhoneOff,
  "phone-outgoing": PhoneOutgoing,
  phone: Phone,
  "pie-chart": PieChart,
  "play-circle": PlayCircle,
  play: Play,
  "plus-circle": PlusCircle,
  "plus-square": PlusSquare,
  plus: Plus,
  pocket: Pocket,
  power: Power,
  printer: Printer,
  radio: Radio,
  "refresh-ccw": RefreshCcw,
  "refresh-cw": RefreshCw,
  repeat: Repeat,
  rewind: Rewind,
  "rotate-ccw": RotateCcw,
  "rotate-cw": RotateCw,
  rss: Rss,
  save: Save,
  scissors: Scissors,
  search: Search,
  send: Send,
  server: Server,
  settings: Settings,
  "share-2": Share2,
  share: Share,
  "shield-off": ShieldOff,
  shield: Shield,
  "shopping-bag": ShoppingBag,
  "shopping-cart": ShoppingCart,
  shuffle: Shuffle,
  sidebar: Sidebar,
  "skip-back": SkipBack,
  "skip-forward": SkipForward,
  slack: Slack,
  slash: Slash,
  sliders: Sliders,
  smartphone: Smartphone,
  smile: Smile,
  speaker: Speaker,
  square: Square,
  star: Star,
  "stop-circle": StopCircle,
  sun: Sun,
  sunrise: Sunrise,
  sunset: Sunset,
  tablet: Tablet,
  tag: Tag,
  target: Target,
  terminal: Terminal,
  thermometer: Thermometer,
  "thumbs-down": ThumbsDown,
  "thumbs-up": ThumbsUp,
  "toggle-left": ToggleLeft,
  "toggle-right": ToggleRight,
  tool: Tool,
  "trash-2": Trash2,
  trash: Trash,
  trello: Trello,
  "trending-down": TrendingDown,
  "trending-up": TrendingUp,
  triangle: Triangle,
  truck: Truck,
  tv: Tv,
  twitch: Twitch,
  twitter: Twitter,
  type: Type,
  umbrella: Umbrella,
  underline: Underline,
  unlock: Unlock,
  "upload-cloud": UploadCloud,
  upload: Upload,
  "user-check": UserCheck,
  "user-minus": UserMinus,
  "user-plus": UserPlus,
  "user-x": UserX,
  user: User,
  users: Users,
  "video-off": VideoOff,
  video: Video,
  voicemail: Voicemail,
  "volume-1": Volume1,
  "volume-2": Volume2,
  "volume-x": VolumeX,
  volume: Volume,
  watch: Watch,
  "wifi-off": WifiOff,
  wifi: Wifi,
  wind: Wind,
  "x-circle": XCircle,
  "x-octagon": XOctagon,
  "x-square": XSquare,
  x: X,
  youtube: Youtube,
  "zap-off": ZapOff,
  zap: Zap,
  "zoom-in": ZoomIn,
  "zoom-out": ZoomOut,
};
