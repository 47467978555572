import { TimeZone } from "@vvo/tzdb";
import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewFilters,
  AccessReviewGroupResourceVisibilityPolicy,
  AccessReviewReviewerAssignmentPolicy,
  Maybe,
  StartAccessReviewInput,
  useStartAccessReviewMutation,
} from "api/generated/graphql";
import AssignmentPolicy from "components/access_reviews/AssignmentPolicy";
import ReminderNotifications, {
  DEFAULT_REMINDER_SCHEDULE,
  DEFAULT_REMINDER_TIME_OF_DAY,
} from "components/access_reviews/ReminderNotifications";
import StartAccessReviewStats from "components/access_reviews/scope/AccessReviewStats";
import ConnectionsFilter from "components/access_reviews/scope/ConnectionsFilter";
import EntitiesIndividualFilter from "components/access_reviews/scope/EntitiesIndividualFilter";
import EntitiesNamedFilter from "components/access_reviews/scope/EntitiesNamedFilter";
import EntitiesWithAdminFilter from "components/access_reviews/scope/EntitiesWithAdminFilter";
import EntityTypesFilter from "components/access_reviews/scope/EntityTypesFilter";
import TagFilter from "components/access_reviews/scope/TagFilter";
import UsersFilter from "components/access_reviews/scope/UsersFilter";
import AuthContext from "components/auth/AuthContext";
import FullscreenView from "components/layout/FullscreenView";
import ConfirmModal from "components/modals/update/ConfirmModal";
import { useToast } from "components/toast/Toast";
import {
  Banner,
  Divider,
  FormGroup,
  Input,
  RadioGroup,
  Switch,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import { getDefaultTimezone, getTimeZoneGuess } from "utils/time";
import DeadlineForm from "views/access_reviews/common/DeadlineForm";
import FilterMatchModeSwitch from "views/access_reviews/common/FilterMatchModeSwitch";
import { AccessReviewGroupResourceVisibilityOptions } from "views/access_reviews/create/CreateAccessReviewColumn";
import {
  buildAccessReviewFilter,
  defaultAccessReviewFilters,
} from "views/access_reviews/create/utils";
import { ForbiddenPage } from "views/error/ErrorCodePage";

export const UAR_CREATE_CONFIG_KEY = "uarc";

interface AccessReviewConfig {
  name: string;
  timeZone?: TimeZone;
  deadline?: string;
  reminderSchedule: number[];
  reminderTimeOfDay: Date;
  reminderIncludeManager: boolean;
  filters: AccessReviewFilters;
  assignmentPolicy: AccessReviewReviewerAssignmentPolicy;
  sendReviewerAssignmentNotifications: boolean;
  selfReviewAllowed: boolean;
  groupResourceVisibilityPolicy: AccessReviewGroupResourceVisibilityPolicy;
  includeGroupBindings: boolean;
}

export const makeURLForCreateAccessReview = (
  config: Partial<AccessReviewConfig>
) => {
  const outputString = encodeURIComponent(JSON.stringify(config));
  const output = btoa(outputString);
  return `/access-reviews/new?${UAR_CREATE_CONFIG_KEY}=${output}`;
};

const isUARScoped = (config: Partial<AccessReviewConfig>) => {
  // Validating whether the UAR is scoped at all
  if (
    config.filters?.userIDs.length == 0 &&
    config.filters?.adminIDs.length == 0 &&
    config.filters?.entityIDs.length == 0 &&
    config.filters?.connectionIDs.length == 0 &&
    config.filters?.resourceTypes.length == 0
  ) {
    return false;
  }
  return true;
};

const AccessReviewCreateV3 = () => {
  const location = useLocation();
  const history = useHistory();
  const transitionBack = useTransitionBack();
  const hasGroupBindings = useFeatureFlag(FeatureFlag.GroupBindings);
  const [config, setConfig] = useState<AccessReviewConfig>({
    name: "",
    timeZone: getTimeZoneGuess() || getDefaultTimezone(),
    reminderSchedule: DEFAULT_REMINDER_SCHEDULE,
    reminderTimeOfDay: DEFAULT_REMINDER_TIME_OF_DAY,
    reminderIncludeManager: false,
    filters: defaultAccessReviewFilters(),
    assignmentPolicy: AccessReviewReviewerAssignmentPolicy.Manually,
    sendReviewerAssignmentNotifications: false,
    selfReviewAllowed: true,
    groupResourceVisibilityPolicy:
      AccessReviewGroupResourceVisibilityPolicy.Strict,
    includeGroupBindings: hasGroupBindings, // default to true if feature flag is enabled, when you'll remove the feature flag default to true.
  });
  const [
    createAccessReview,
    { loading: startReviewloading },
  ] = useStartAccessReviewMutation();
  const { displaySuccessToast } = useToast();
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { authState } = useContext(AuthContext);
  const canManage = authState.user?.isAdmin || authState.user?.isAuditor;

  useEffect(() => {
    const search = location.search;
    if (!search) {
      return;
    }

    const params = new URLSearchParams(search);
    const data = params.get(UAR_CREATE_CONFIG_KEY);
    if (!data) {
      return;
    }

    // Parse URL hash for create config
    const inputString = decodeURIComponent(atob(data));
    const parsed = JSON.parse(inputString) as Partial<AccessReviewConfig>;

    setConfig((prev) => ({
      ...prev,
      ...parsed,
    }));
  }, [location]);

  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const handlePrimaryButtonClick = () => {
    if (isUARScoped(config)) {
      // continue to UAR creation
      handleCreate();
    } else {
      // show warning that UAR is unscoped
      setShowConfirmModal(true);
    }
  };

  const confirmModal = (
    <ConfirmModal
      title={`No Scope Selected`}
      message="Are you sure you want to create this Access Review? You have not set any scope parameters which means everything in your organization will be included. This may result in very large access review."
      isModalOpen={showConfirmModal}
      onClose={() => setShowConfirmModal(false)}
      onSubmit={async () => {
        handleCreate();
      }}
      submitLabel={"Create"}
    />
  );

  const handleCreate = async () => {
    if (config.deadline && config.timeZone) {
      try {
        const input: StartAccessReviewInput = {
          name: config.name,
          deadline: config.deadline,
          reminderSchedule: config.reminderSchedule,
          reminderTimeOfDay: config.reminderTimeOfDay.toISOString(),
          reminderIncludeManager: config.reminderIncludeManager,
          timeZone: config.timeZone.name,
          reviewerAssignmentPolicy: config.assignmentPolicy,
          sendReviewerAssignmentNotification:
            config.sendReviewerAssignmentNotifications,
          selfReviewAllowed: config.selfReviewAllowed,
          filters: buildAccessReviewFilter(config.filters),
          groupResourceVisibilityPolicy: config.groupResourceVisibilityPolicy,
          includeGroupBindings: config.includeGroupBindings,
        };

        const { data } = await createAccessReview({
          variables: { input },
          refetchQueries: ["AccessReviews"],
        });
        switch (data?.startAccessReview.__typename) {
          case "StartAccessReviewResult":
            displaySuccessToast(`Success: access review created`);
            history.replace(
              "/access-reviews/" + data.startAccessReview.accessReview.id
            );
            break;
          default:
            logError(new Error(`failed to create access review`));
            setErrorMessage(`Error: failed to create access review`);
        }
      } catch (error) {
        logError(error, "failed to create access review");
        setErrorMessage(
          getModifiedErrorMessage(
            `Error: failed to create access review`,
            error
          )
        );
      }
    }
  };

  const handleDeadlineChanges = (newDeadline: string, tz: TimeZone) => {
    if (tz.name != config.timeZone?.name) {
      setConfig({ ...config, timeZone: tz, deadline: newDeadline });
    } else if (newDeadline !== config.deadline) {
      setConfig({ ...config, deadline: newDeadline });
    }
  };

  if (!canManage) {
    return <ForbiddenPage />;
  }

  return (
    <>
      <FullscreenView
        title="Create Access Review"
        onCancel={() => {
          !startReviewloading &&
            transitionBack("/access-reviews?category=ongoing");
        }}
        onPrimaryButtonClick={handlePrimaryButtonClick}
        primaryButtonLabel="Create"
        primaryButtonLoading={startReviewloading}
        primaryButtonDisabled={
          !config.name || !config.deadline || !config.timeZone
        }
        primaryAdjacentTip={
          <StartAccessReviewStats styleTextOnly filters={config.filters} />
        }
      >
        <FullscreenView.Sidebar>
          <FormGroup label="Name" required>
            <Input
              autoFocus
              value={config.name}
              onChange={(newName) => setConfig({ ...config, name: newName })}
            />
          </FormGroup>
          <AssignmentPolicy
            assignmentPolicy={config.assignmentPolicy}
            onChange={(newValue) =>
              setConfig({ ...config, assignmentPolicy: newValue })
            }
          />
          <FormGroup
            label={`Self-review: ${
              config.selfReviewAllowed ? "Allowed" : "Not Allowed"
            }`}
          >
            <Switch
              label="Allow self-reviews. Self reviewed items will be noted in the review results report."
              checked={config.selfReviewAllowed}
              onChange={(checked) =>
                setConfig({
                  ...config,
                  selfReviewAllowed: checked,
                })
              }
              rightAlign
            />
          </FormGroup>
          <DeadlineForm
            deadline={config.deadline}
            onChange={handleDeadlineChanges}
            label={"Set a deadline"}
            timeZone={config.timeZone}
          />
          <ReminderNotifications
            reminderSchedule={config.reminderSchedule}
            reminderTimeOfDay={config.reminderTimeOfDay}
            reminderIncludeManager={config.reminderIncludeManager}
            timezone={config.timeZone}
            onChange={(
              thisReminderSchedule,
              thisReminderTimeOfDay,
              thisReminderIncludeManager
            ) => {
              setConfig({
                ...config,
                reminderSchedule: thisReminderSchedule,
                reminderTimeOfDay: thisReminderTimeOfDay,
                reminderIncludeManager: thisReminderIncludeManager,
              });
            }}
          />
          <FormGroup
            label={`New review notifications: ${
              config.sendReviewerAssignmentNotifications ? "On" : "Off"
            }`}
          >
            <Switch
              label="Send a notification to reviewers when they're assigned a new review."
              checked={config.sendReviewerAssignmentNotifications}
              onChange={(checked) =>
                setConfig({
                  ...config,
                  sendReviewerAssignmentNotifications: checked,
                })
              }
              rightAlign
            />
          </FormGroup>
        </FullscreenView.Sidebar>
        <FullscreenView.Content>
          {errorMessage ? (
            <div className={sprinkles({ marginBottom: "md" })}>
              <Banner type="error" message={errorMessage} />
            </div>
          ) : null}
          <p>Define Scope & Visibility</p>
          <FormGroup label="Group Resource Visibility">
            <RadioGroup
              value={AccessReviewGroupResourceVisibilityOptions.find(
                (opt) => opt.value === config.groupResourceVisibilityPolicy
              )}
              options={AccessReviewGroupResourceVisibilityOptions}
              getOptionKey={(opt) => opt.value}
              getOptionLabel={(opt) => opt.label}
              getOptionDescription={(opt) => opt.description}
              onSelectValue={(opt) => {
                setConfig((prevConfig) => ({
                  ...prevConfig,
                  groupResourceVisibilityPolicy: opt.value,
                }));
              }}
            />
          </FormGroup>
          <div>
            <FormGroup label="Users">
              {config.filters.userIDs.length === 0 && "All users"}
              <UsersFilter
                userIDs={config.filters.userIDs}
                onChangeUserIDs={(newUserIDs) => {
                  setConfig((prevConfig) => ({
                    ...prevConfig,
                    filters: {
                      ...prevConfig.filters,
                      userIDs: newUserIDs,
                    },
                  }));
                }}
              />
            </FormGroup>
          </div>
          <Divider />
          <FilterMatchModeSwitch
            matchMode={config.filters.matchMode}
            onChange={(matchMode) => {
              setConfig((prevConfig) => ({
                ...prevConfig,
                filters: {
                  ...prevConfig.filters,
                  matchMode,
                },
              }));
            }}
          />
          <EntitiesIndividualFilter
            entityIds={config.filters.entityIDs}
            onChangeEntityIds={(newEntityIds) => {
              setConfig((prevConfig) => ({
                ...prevConfig,
                filters: {
                  ...prevConfig.filters,
                  entityIDs: newEntityIds,
                },
              }));
            }}
          />
          <ConnectionsFilter
            connectionIDs={config.filters.connectionIDs}
            onChangeConnectionIDs={(newConnectionIDs) => {
              setConfig((prevConfig) => ({
                ...prevConfig,
                filters: {
                  ...prevConfig.filters,
                  connectionIDs: newConnectionIDs,
                },
              }));
            }}
          />
          <EntitiesWithAdminFilter
            adminIDs={config.filters.adminIDs}
            onChangeAdminIDs={(newAdminIDs) => {
              setConfig({
                ...config,
                filters: {
                  ...config.filters,
                  adminIDs: newAdminIDs,
                },
              });
            }}
          />
          <EntityTypesFilter
            resourceTypes={config.filters.resourceTypes}
            groupTypes={config.filters.groupTypes}
            onChangeResourceTypes={(newTypes) => {
              setConfig((prevConfig) => ({
                ...prevConfig,
                filters: {
                  ...prevConfig.filters,
                  resourceTypes: newTypes,
                },
              }));
            }}
            onChangeGroupTypes={(newTypes) => {
              setConfig((prevConfig) => ({
                ...prevConfig,
                filters: {
                  ...prevConfig.filters,
                  groupTypes: newTypes,
                },
              }));
            }}
          />
          {hasGroupBindings && (
            // Note: "include linked groups" switch won't affect the access review stats
            <FormGroup
              label={`Include linked group source groups: ${
                config.includeGroupBindings ? "Yes" : "No"
              }`}
            >
              <Switch
                label="Include the source group in the access review. Otherwise, only the groups strictly matching the filters will be included."
                checked={config.includeGroupBindings}
                onChange={(checked) =>
                  setConfig({
                    ...config,
                    includeGroupBindings: checked,
                  })
                }
              />
            </FormGroup>
          )}
          <TagFilter
            tags={config.filters.tags}
            onChangeTags={(newTags) => {
              setConfig({
                ...config,
                filters: {
                  ...config.filters,
                  tags: newTags,
                },
              });
            }}
          />
          <EntitiesNamedFilter
            names={config.filters.names}
            onChangeNames={(newNames) => {
              setConfig({
                ...config,
                filters: {
                  ...config.filters,
                  names: newNames,
                },
              });
            }}
          />
        </FullscreenView.Content>
      </FullscreenView>
      {showConfirmModal && confirmModal}
    </>
  );
};

export default AccessReviewCreateV3;
