import {
  EntityType,
  ResourceCustomAccessLevelFragment,
  ResourceType,
  ServiceType,
} from "api/generated/graphql";
import Label from "components/label/Label";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import MaterialTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MaterialTable";
import moment from "moment";
import React from "react";
import { hasPolicyType } from "utils/directory/connections";
import ResourceCustomRoleEditLabel from "views/resources/ResourceCustomRoleEditLabel";

interface ResourceCustomRolesTableRow {
  role: string;
  remoteId: string;
  policy: string;
  priority: number;
  lastUpdated: string;
  actions: string;
}

type ResourceCustomRolesTableProps = {
  resourceCustomRoles: ResourceCustomAccessLevelFragment[];
  setShowCreateRoleModal: (show: boolean) => void;
  setShowImportRoleModal: (show: boolean) => void;
  isGCPService: boolean;
  serviceType: ServiceType;
  resourceType: ResourceType;
};

export const ResourceCustomRolesTable = (
  props: ResourceCustomRolesTableProps
) => {
  const headers: Header<ResourceCustomRolesTableRow>[] = [
    { id: "role", label: "Role" },
    { id: "remoteId", label: "Remote ID" },
    { id: "policy", label: "Policy", hide: !hasPolicyType(props.resourceType) },
    {
      id: "priority",
      label: "Priority",
      hide: props.serviceType !== ServiceType.OktaDirectory,
    },
    { id: "lastUpdated", label: "Last Updated" },
    {
      id: "actions",
      label: "Actions",
      hide: !hasPolicyType(props.resourceType),
    },
  ];

  let rows:
    | CellRow<ResourceCustomRolesTableRow>[]
    | undefined = props.resourceCustomRoles?.map((resourceCustomRole) => {
    const name = resourceCustomRole.accessLevel.accessLevelName;
    const remoteId = resourceCustomRole.accessLevel.accessLevelRemoteId;
    const policy = resourceCustomRole.policy || "";
    const priority = resourceCustomRole.priority;

    const updatedAtTime = moment(new Date(resourceCustomRole.updatedAt));

    return {
      id: resourceCustomRole.id,
      data: {
        role: {
          value: name,
          element: <>{name}</>,
        },
        remoteId: {
          value: remoteId,
          element: <Label text={remoteId} />,
        },
        policy: {
          value: policy.substring(0, 40),
          element: <Label text={policy || "--"} maxChars={80} />,
          hide: !hasPolicyType(props.resourceType),
        },
        priority: {
          value: priority || "",
          element: <Label text={String(priority)} maxChars={3} />,
          hide: props.serviceType !== ServiceType.OktaDirectory,
        },
        lastUpdated: {
          value: updatedAtTime.unix(),
          element: <>{updatedAtTime.fromNow()}</>,
        },
        actions: {
          value: "",
          element: (
            <ResourceCustomRoleEditLabel
              resourceCustomRole={resourceCustomRole}
              serviceType={props.serviceType}
              resourceType={props.resourceType}
              resourceCustomRolesCount={props.resourceCustomRoles.length}
            />
          ),
          hide: !hasPolicyType(props.resourceType),
        },
      },
    };
  });

  let roleVerb = "Create";
  let roleDescription = `${roleVerb} custom roles for this resource to populate the table`;

  if (props.isGCPService) {
    roleVerb = "Import";
    roleDescription = `Because no custom roles have been imported, users by default can request access to this resource with any GCP role. ${roleVerb} custom roles for this resource to restrict which roles can be used.`;
  }

  return (
    <EmptyStateContentWrapper
      content={
        <MaterialTable<ResourceCustomRolesTableRow>
          headers={headers}
          rows={rows || []}
          denseFormatting={true}
          defaultSortField={
            props.serviceType === ServiceType.OktaDirectory
              ? "priority"
              : "role"
          }
          defaultSortOrder={"asc"}
        />
      }
      entityType={EntityType.AccessLevel}
      title={`No custom roles for this resource`}
      subtitle={roleDescription}
      buttonTitle={`${roleVerb} role`}
      onClickHandler={() => {
        if (props.isGCPService) {
          props.setShowImportRoleModal(true);
        } else {
          props.setShowCreateRoleModal(true);
        }
      }}
      isEmpty={!rows || rows.length === 0}
    />
  );
};

export default ResourceCustomRolesTable;
