import * as styles from "./TableFilters.css";

type LeftFilterProps = {
  children?: React.ReactNode;
};

const LeftFilter: React.FC = ({ children }: LeftFilterProps) => {
  return <div className={styles.leftFilters}>{children}</div>;
};

type RightFilterProps = {
  children?: React.ReactNode;
};

const RightFilter: React.FC = ({ children }: RightFilterProps) => {
  return <div className={styles.rightFilters}>{children}</div>;
};

type TableFiltersProps = {
  children?: React.ReactNode;
};

const TableFilters: React.FC = (props: TableFiltersProps) => {
  return <div className={styles.tableFilters}>{props.children}</div>;
};

export default Object.assign(TableFilters, {
  Left: LeftFilter,
  Right: RightFilter,
});
