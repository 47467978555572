import { Maybe } from "api/generated/graphql";
import { TruncatedLabel } from "components/label/Label";
import { Icon } from "components/ui";
import React from "react";

type ResourceLabelProps = {
  text?: string | null;
  subText?: string;
  resourceId?: string;
  maxChars?: number;
  icon?: Maybe<string>;
  iconLarge?: boolean;
  bold?: boolean;
};

export const ResourceLabel = (props: ResourceLabelProps) => {
  let url = null;
  if (props.resourceId) {
    url = `/resources/${props.resourceId}`;
  }

  let text = props.text;
  if (!props.text || props.text === "") {
    text = props.resourceId?.substr(0, 7) || "--";
  }

  let icon = props.icon ? (
    <Icon externalIconUrl={props.icon} />
  ) : (
    <Icon name={"box"} />
  );

  return (
    <TruncatedLabel
      text={text}
      subText={props.subText}
      icon={icon}
      url={url}
      maxChars={props.maxChars}
      iconLarge={props.iconLarge}
      bold={props.bold}
    />
  );
};

export default ResourceLabel;
