import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewAction,
  usePerformReviewMutation,
} from "api/generated/graphql";
import clsx from "clsx";
import modalStyles from "components/modals/Modals.module.scss";
import ConfirmModal from "components/modals/update/ConfirmModal";
import { useToast } from "components/toast/Toast";
import { ButtonV3 } from "components/ui";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import styles from "views/access_reviews/common/SubmitReviewModalStyles.module.scss";

import AccessReviewContext, {
  AccessReviewContextActionType,
  PerformReviewState,
} from "../AccessReviewContext";
import { UARSelectContext } from "../UARSelectContext";
import { ReviewedItem } from "./SubmitAccessReviewButtons";

interface Props {
  accessReviewId: string;
  performReviewState: PerformReviewState;
  entityName: string;
  onCancel: () => void;
  onSubmit?: () => void;
}

const SubmitAccessReviewButtonV3 = (props: Props) => {
  const { onCancel } = props;

  const { accessReviewDispatch } = useContext(AccessReviewContext);
  const [showModal, setShowModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [performReview, { loading }] = usePerformReviewMutation();
  const { displaySuccessToast } = useToast();
  const { clearAllSelected } = useContext(UARSelectContext);

  const filterReviewState = (performReviewState: PerformReviewState) => {
    const resourceUserActions = performReviewState.resourceUserActions
      .filter((action) => action.action !== AccessReviewAction.NoAction)
      .map((action) => {
        if (action.updatedAccessLevel) {
          action.updatedAccessLevel = {
            accessLevelName: action.updatedAccessLevel.accessLevelName,
            accessLevelRemoteId: action.updatedAccessLevel.accessLevelRemoteId,
          };
        }
        return action;
      });
    const groupUserActions = performReviewState.groupUserActions
      .filter((action) => action.action !== AccessReviewAction.NoAction)
      .map((action) => {
        if (action.updatedAccessLevel) {
          action.updatedAccessLevel = {
            accessLevelName: action.updatedAccessLevel.accessLevelName,
            accessLevelRemoteId: action.updatedAccessLevel.accessLevelRemoteId,
          };
        }
        return action;
      });
    const groupResourceActions = performReviewState.groupResourceActions.filter(
      (action) => action.action !== AccessReviewAction.NoAction
    );
    const connectionUserActions = performReviewState.connectionUserActions.filter(
      (action) => action.action !== AccessReviewAction.NoAction
    );
    const resourceResourceActions = performReviewState.resourceResourceActions.filter(
      (action) => action.action !== AccessReviewAction.NoAction
    );
    return {
      resourceUserActions: resourceUserActions,
      groupUserActions: groupUserActions,
      groupResourceActions: groupResourceActions,
      connectionUserActions: connectionUserActions,
      resourceResourceActions: resourceResourceActions,
    };
  };
  const filteredReviewState = filterReviewState(props.performReviewState);

  const noItemsReviewed =
    filteredReviewState.resourceUserActions.length +
      filteredReviewState.groupUserActions.length +
      filteredReviewState.groupResourceActions.length +
      filteredReviewState.connectionUserActions.length +
      filteredReviewState.resourceResourceActions.length ===
    0;

  const modalReset = () => {
    setShowModal(false);
    setErrorMessage(null);
  };

  const resetReview = () => {
    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARResourceId: {},
        performReviewStateByUARGroupId: {},
        performReviewStateByUARUserId: {},
        performReviewStateByUARConnectionId: {},
      },
    });
    clearAllSelected();
  };
  const submitPerformReview = async (
    filteredReviewState: PerformReviewState
  ): Promise<boolean> => {
    const refetchQueries = [
      "AccessReview",
      "OngoingAccessReviewStats",
      "OngoingAccessReviewTabStats",
      "OngoingAccessReviewSubtabStats",
      "MyAccessReviewItemsV3",
      "AccessReviewUserAssignments",
      "AccessReviewGroupResourceAssignments",
      "AccessReviewResourcePrincipalAssignments",
      "AccessReviewChangesWithDetails",
    ];
    if (filteredReviewState.connectionUserActions.length > 0) {
      refetchQueries.push("AccessReviewConnection");
      refetchQueries.push("AccessReviewConnectionUsers");
    }
    if (
      filteredReviewState.resourceUserActions.length +
        filteredReviewState.resourceResourceActions.length +
        filteredReviewState.groupResourceActions.length >
      0
    ) {
      refetchQueries.push("AccessReviewResource");
      refetchQueries.push("AccessReviewResources");
    }
    if (
      filteredReviewState.groupUserActions.length +
        filteredReviewState.groupResourceActions.length >
      0
    ) {
      refetchQueries.push("AccessReviewGroup");
      refetchQueries.push("AccessReviewGroupDetails");
      refetchQueries.push("AccessReviewGroups");
    }
    if (
      filteredReviewState.resourceUserActions.length +
        filteredReviewState.groupUserActions.length >
      0
    ) {
      refetchQueries.push("AccessReviewUser");
      refetchQueries.push("AccessReviewUsers");
    }

    try {
      const { data } = await performReview({
        variables: {
          input: {
            accessReviewId: props.accessReviewId,
            resourceUserActions: filteredReviewState.resourceUserActions,
            groupUserActions: filteredReviewState.groupUserActions,
            groupResourceActions: filteredReviewState.groupResourceActions,
            connectionUserActions: filteredReviewState.connectionUserActions,
            resourceResourceActions:
              filteredReviewState.resourceResourceActions,
          },
        },
        refetchQueries: refetchQueries,
      });
      switch (data?.performReview.__typename) {
        case "PerformReviewResult":
          displaySuccessToast(`Success: access reviews submitted`);
          resetReview();
          modalReset();
          return true;
        case "AccessReviewNotFoundError":
        case "AccessReviewAlreadyStoppedError":
        case "ItemCannotBeRequestedError":
          setErrorMessage(data.performReview.message);
          break;
        default:
          setErrorMessage(`Error: failed to submit access reviews`);
      }
    } catch (error) {
      setErrorMessage(
        getModifiedErrorMessage("Error: failed to submit access reviews", error)
      );
    }

    return false;
  };

  const actionsOnNHIAccessToResources = filteredReviewState.resourceResourceActions.filter(
    (action) => action.isAccessReviewResourceActingAsPrincipal
  );
  const actionsOnNHIAccessToNHIs = filteredReviewState.resourceResourceActions.filter(
    (action) => !action.isAccessReviewResourceActingAsPrincipal
  );

  return (
    <>
      <div className={sprinkles({ display: "flex", gap: "sm" })}>
        <ButtonV3 label="Cancel" onClick={onCancel} outline />
        <ButtonV3
          type="main"
          label="Submit access review"
          onClick={() => setShowModal(true)}
          disabled={noItemsReviewed}
        />
      </div>
      {showModal && (
        <ConfirmModal
          title="Submit access review"
          subtitle={props.entityName}
          message={
            <div className={styles.modalFieldContainer}>
              <p>You are submitting access reviews for:</p>
              <div
                className={clsx(
                  modalStyles.boxedItemsContainer,
                  modalStyles.fieldContainer
                )}
              >
                {filteredReviewState.resourceUserActions.length > 0 && (
                  <ReviewedItem
                    entityType={`user`}
                    description={
                      <>
                        Access to <i>{props.entityName}</i> for{" "}
                        {pluralize(
                          `user`,
                          filteredReviewState.resourceUserActions.length,
                          true
                        )}
                      </>
                    }
                    reviewActions={filteredReviewState.resourceUserActions}
                  />
                )}
                {filteredReviewState.groupUserActions.length > 0 && (
                  <ReviewedItem
                    entityType={`user`}
                    description={
                      <>
                        Access to <i>{props.entityName}</i> for{" "}
                        {pluralize(
                          `user`,
                          filteredReviewState.groupUserActions.length,
                          true
                        )}
                      </>
                    }
                    reviewActions={filteredReviewState.groupUserActions}
                  />
                )}
                {filteredReviewState.groupResourceActions.length > 0 && (
                  <ReviewedItem
                    entityType={`resource`}
                    description={
                      <>
                        Access to
                        {` `}
                        {pluralize(
                          `resource`,
                          filteredReviewState.groupResourceActions.length,
                          true
                        )}
                        {` `}
                        for <i>{props.entityName}</i>
                      </>
                    }
                    reviewActions={filteredReviewState.groupResourceActions}
                  />
                )}
                {filteredReviewState.connectionUserActions.length > 0 && (
                  <ReviewedItem
                    entityType={`user`}
                    description={
                      <>
                        <p>
                          Access to <i>{props.entityName}</i> for{" "}
                          {pluralize(
                            `user`,
                            filteredReviewState.connectionUserActions.length,
                            true
                          )}
                        </p>
                        {filteredReviewState.connectionUserActions.some(
                          (action) =>
                            action.action === AccessReviewAction.Revoke
                        ) && (
                          <p
                            className={sprinkles({
                              fontWeight: "semibold",
                              color: "red600",
                            })}
                          >
                            Revoked users will be deprovisioned
                          </p>
                        )}
                      </>
                    }
                    reviewActions={filteredReviewState.connectionUserActions}
                  />
                )}
                {actionsOnNHIAccessToResources.length > 0 && (
                  <ReviewedItem
                    entityType={`resource`}
                    description={
                      <>
                        <i>{props.entityName}</i> access to{" "}
                        {pluralize(
                          `resource`,
                          actionsOnNHIAccessToResources.length,
                          true
                        )}
                      </>
                    }
                    reviewActions={actionsOnNHIAccessToResources}
                  />
                )}
                {actionsOnNHIAccessToNHIs.length > 0 && (
                  <ReviewedItem
                    entityType={`non-human identity`}
                    description={
                      <>
                        Access to <i>{props.entityName}</i> for{" "}
                        {pluralize(
                          `non-human identity`,
                          actionsOnNHIAccessToNHIs.length,
                          true
                        )}
                      </>
                    }
                    reviewActions={actionsOnNHIAccessToNHIs}
                  />
                )}
              </div>
              <p>
                Please confirm that you are ready to finalize these reviews. Are
                you sure you want to proceed?
              </p>
            </div>
          }
          isModalOpen={showModal}
          onClose={modalReset}
          onSubmit={async () => {
            let success = await submitPerformReview(filteredReviewState);
            if (success) {
              props.onSubmit && props.onSubmit();
            }
          }}
          loading={loading}
          errorMessage={errorMessage}
          submitLabel={"Submit"}
          submitDisabled={noItemsReviewed}
        />
      )}
    </>
  );
};

export default SubmitAccessReviewButtonV3;
