import { AccessReviewAction } from "api/generated/graphql";
import RowBar from "components/layout/RowBar";
import AccessReviewNoteModal from "components/modals/AccessReviewNoteModal";
import { Button, Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useContext, useState } from "react";

import AccessReviewContext, {
  AccessReviewContextActionType,
  emptyPerformReviewState,
} from "./AccessReviewContext";
import { UARSelectContext } from "./UARSelectContext";

const UARBulkSelectBar = () => {
  const {
    selectedResourceUserIds,
    selectedGroupUserIds,
    selectedGroupResourceIds,
    selectedConnectionUserIds,
    clearAllSelected,
  } = useContext(UARSelectContext);
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );

  const [showNoteModal, setShowNoteModal] = useState(false);

  let numTotalSelected = 0;
  Object.entries(selectedResourceUserIds).forEach(([, ids]) => {
    numTotalSelected += ids.length;
  });
  Object.entries(selectedGroupUserIds).forEach(([, ids]) => {
    numTotalSelected += ids.length;
  });
  Object.entries(selectedGroupResourceIds).forEach(([, ids]) => {
    numTotalSelected += ids.length;
  });
  Object.entries(selectedConnectionUserIds).forEach(([, ids]) => {
    numTotalSelected += ids.length;
  });

  if (numTotalSelected === 0) {
    return null;
  }

  const handleBulkDecision = (state: AccessReviewAction) => {
    Object.entries(selectedResourceUserIds).forEach(([resourceId, ruIds]) => {
      const performReviewState =
        accessReviewState.performReviewStateByUARResourceId[resourceId] ??
        emptyPerformReviewState();

      performReviewState.resourceUserActions.forEach((resourceUserAction) => {
        resourceUserAction.action = state;
      });

      ruIds.forEach((ruId) => {
        const existingInfo = performReviewState.resourceUserActions.find(
          (resourceUserAction) =>
            resourceUserAction.accessReviewResourceUserId === ruId
        );

        if (!existingInfo) {
          performReviewState.resourceUserActions.push({
            accessReviewResourceUserId: ruId,
            action: state,
          });
        } else {
          delete existingInfo.updatedAccessLevel;
        }
      });

      accessReviewDispatch({
        type: AccessReviewContextActionType.AccessReviewItemUpdate,
        payload: {
          performReviewStateByUARResourceId: {
            ...accessReviewState.performReviewStateByUARResourceId,
            [resourceId]: performReviewState,
          },
        },
      });
    });

    Object.entries(selectedGroupUserIds).forEach(([groupId, guIds]) => {
      const performReviewState =
        accessReviewState.performReviewStateByUARGroupId[groupId] ??
        emptyPerformReviewState();

      performReviewState.groupUserActions.forEach((groupUserAction) => {
        groupUserAction.action = state;
      });

      guIds.forEach((guId) => {
        const existingInfo = performReviewState.groupUserActions.find(
          (groupUserAction) => groupUserAction.accessReviewGroupUserId === guId
        );

        if (!existingInfo) {
          performReviewState.groupUserActions.push({
            accessReviewGroupUserId: guId,
            action: state,
          });
        }
      });

      accessReviewDispatch({
        type: AccessReviewContextActionType.AccessReviewItemUpdate,
        payload: {
          performReviewStateByUARGroupId: {
            ...accessReviewState.performReviewStateByUARGroupId,
            [groupId]: performReviewState,
          },
        },
      });
    });

    Object.entries(selectedGroupResourceIds).forEach(([groupId, grIds]) => {
      const performReviewState =
        accessReviewState.performReviewStateByUARGroupId[groupId] ??
        emptyPerformReviewState();

      performReviewState.groupResourceActions.forEach((groupResourceAction) => {
        groupResourceAction.action = state;
      });

      grIds.forEach((grId) => {
        const existingInfo = performReviewState.groupResourceActions.find(
          (groupResourceAction) =>
            groupResourceAction.accessReviewGroupResourceId === grId
        );

        if (!existingInfo) {
          performReviewState.groupResourceActions.push({
            accessReviewGroupResourceId: grId,
            action: state,
          });
        }
      });

      accessReviewDispatch({
        type: AccessReviewContextActionType.AccessReviewItemUpdate,
        payload: {
          performReviewStateByUARGroupId: {
            ...accessReviewState.performReviewStateByUARGroupId,
            [groupId]: performReviewState,
          },
        },
      });
    });

    Object.entries(selectedConnectionUserIds).forEach(
      ([connectionId, cuIds]) => {
        const performReviewState =
          accessReviewState.performReviewStateByUARConnectionId[connectionId] ??
          emptyPerformReviewState();

        performReviewState.connectionUserActions.forEach(
          (connectionUserAction) => {
            connectionUserAction.action = state;
          }
        );

        cuIds.forEach((cuId) => {
          const existingInfo = performReviewState.connectionUserActions.find(
            (connectionUserAction) =>
              connectionUserAction.accessReviewConnectionUserId === cuId
          );

          if (!existingInfo) {
            performReviewState.connectionUserActions.push({
              accessReviewConnectionUserId: cuId,
              action: state,
            });
          }
        });

        accessReviewDispatch({
          type: AccessReviewContextActionType.AccessReviewItemUpdate,
          payload: {
            performReviewStateByUARConnectionId: {
              ...accessReviewState.performReviewStateByUARConnectionId,
              [connectionId]: performReviewState,
            },
          },
        });
      }
    );
  };

  const handleNoteAdd = (newNote: string) => {
    Object.entries(selectedResourceUserIds).forEach(([resourceId, ruIds]) => {
      const performReviewState =
        accessReviewState.performReviewStateByUARResourceId[resourceId] ??
        emptyPerformReviewState();

      ruIds.forEach((resourceUserId) => {
        const existingInfo = performReviewState.resourceUserActions.find(
          (resourceUserAction) =>
            resourceUserAction.accessReviewResourceUserId === resourceUserId
        );

        if (existingInfo) {
          existingInfo.note = newNote;
        } else {
          performReviewState.resourceUserActions.push({
            accessReviewResourceUserId: resourceUserId,
            action: AccessReviewAction.NoAction,
            note: newNote,
          });
        }
      });
      accessReviewDispatch({
        type: AccessReviewContextActionType.AccessReviewItemUpdate,
        payload: {
          performReviewStateByUARResourceId: {
            ...accessReviewState.performReviewStateByUARResourceId,
            [resourceId]: performReviewState,
          },
        },
      });
    });

    Object.entries(selectedGroupUserIds).forEach(([groupId, guIds]) => {
      const performReviewState =
        accessReviewState.performReviewStateByUARGroupId[groupId] ??
        emptyPerformReviewState();

      guIds.forEach((groupUserId) => {
        const existingInfo = performReviewState.groupUserActions.find(
          (groupUserAction) =>
            groupUserAction.accessReviewGroupUserId === groupUserId
        );

        if (existingInfo) {
          existingInfo.note = newNote;
        } else {
          performReviewState.groupUserActions.push({
            accessReviewGroupUserId: groupUserId,
            action: AccessReviewAction.NoAction,
            note: newNote,
          });
        }
      });
      accessReviewDispatch({
        type: AccessReviewContextActionType.AccessReviewItemUpdate,
        payload: {
          performReviewStateByUARGroupId: {
            ...accessReviewState.performReviewStateByUARGroupId,
            [groupId]: performReviewState,
          },
        },
      });
    });

    Object.entries(selectedGroupResourceIds).forEach(([groupId, grIds]) => {
      const performReviewState =
        accessReviewState.performReviewStateByUARGroupId[groupId] ??
        emptyPerformReviewState();

      grIds.forEach((groupResourceId) => {
        const existingInfo = performReviewState.groupResourceActions.find(
          (groupResourceAction) =>
            groupResourceAction.accessReviewGroupResourceId === groupResourceId
        );

        if (existingInfo) {
          existingInfo.note = newNote;
        } else {
          performReviewState.groupResourceActions.push({
            accessReviewGroupResourceId: groupResourceId,
            action: AccessReviewAction.NoAction,
            note: newNote,
          });
        }
      });
      accessReviewDispatch({
        type: AccessReviewContextActionType.AccessReviewItemUpdate,
        payload: {
          performReviewStateByUARGroupId: {
            ...accessReviewState.performReviewStateByUARGroupId,
            [groupId]: performReviewState,
          },
        },
      });
    });

    Object.entries(selectedConnectionUserIds).forEach(
      ([connectionId, cuIds]) => {
        const performReviewState =
          accessReviewState.performReviewStateByUARConnectionId[connectionId] ??
          emptyPerformReviewState();

        cuIds.forEach((connectionUserId) => {
          const existingInfo = performReviewState.connectionUserActions.find(
            (connectionUserAction) =>
              connectionUserAction.accessReviewConnectionUserId ===
              connectionUserId
          );

          if (existingInfo) {
            existingInfo.note = newNote;
          } else {
            performReviewState.connectionUserActions.push({
              accessReviewConnectionUserId: connectionUserId,
              action: AccessReviewAction.NoAction,
              note: newNote,
            });
          }
        });
        accessReviewDispatch({
          type: AccessReviewContextActionType.AccessReviewItemUpdate,
          payload: {
            performReviewStateByUARConnectionId: {
              ...accessReviewState.performReviewStateByUARConnectionId,
              [connectionId]: performReviewState,
            },
          },
        });
      }
    );
  };

  return (
    <>
      {showNoteModal && (
        <AccessReviewNoteModal
          initNote=""
          isOpen={showNoteModal}
          errMsg=""
          onClose={() => {
            setShowNoteModal(false);
          }}
          onSubmit={(newNote) => {
            handleNoteAdd(newNote ?? "");
            setShowNoteModal(false);
          }}
        />
      )}
      <RowBar>
        <div
          className={sprinkles({
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          })}
        >
          <div
            className={sprinkles({
              display: "flex",
              alignItems: "center",
              gap: "sm",
            })}
          >
            <Icon name="check-square" size="xs" color="blue600" />
            You have selected {numTotalSelected}{" "}
            {pluralize("item", numTotalSelected)}.
            <Button
              label="Approve all"
              type="success"
              borderless
              size="sm"
              leftIconName="check-circle"
              onClick={() => handleBulkDecision(AccessReviewAction.Accept)}
            />
            <Button
              label="Revoke all"
              type="error"
              borderless
              size="sm"
              leftIconName="x-circle"
              onClick={() => handleBulkDecision(AccessReviewAction.Revoke)}
            />
            <Button
              label="Add note"
              borderless
              size="sm"
              leftIconName="edit-3"
              onClick={() => setShowNoteModal(true)}
            />
          </div>
          <Button
            size="sm"
            leftIconName="x"
            round
            type="primary"
            borderless
            onClick={clearAllSelected}
          />
        </div>
      </RowBar>
    </>
  );
};

export default UARBulkSelectBar;
