import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AuthType,
  ConnectionType,
  Maybe,
  useCreateConnectionMutation,
  Visibility,
} from "api/generated/graphql";
import mongoLogo from "assets/logos/mongodb-logo.png";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { Button, FormGroup, Input } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import { validateUrl } from "views/connections/create/common";
import {
  CreateConnectionComponents as CreateConnectionComponentsV2,
  CreateConnectionView as CreateConnectionViewV2,
} from "views/connections/create/CreateConnectionComponents";
import {
  CreateConnectionComponentsV3,
  CreateConnectionViewV3,
} from "views/connections/create/CreateConnectionComponentsV3";
import VisibilitySelector from "views/visibility/VisibilitySelector";

const CreateMongoAtlas = () => {
  return <CreateMongoAtlasForm />;
};

const CreateMongoAtlasForm = () => {
  const history = useHistory();
  const hasAppLevelVisibility = useFeatureFlag(FeatureFlag.AppLevelVisibility);
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const logEvent = useLogEvent();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [visibility, setVisibility] = useState<Visibility>(Visibility.Global);
  const [visibilityGroupIds, setVisibilityGroupIds] = useState<
    string[] | undefined
  >([]);
  const [hostname, setHostname] = useState("");
  const [projectID, setProjectID] = useState("");
  const [privateKey, setPrivateKey] = useState("");
  const [publicKey, setPublicKey] = useState("");

  const [adminOwnerId, setAdminOwnerId] = useState<string | undefined>(
    undefined
  );

  const [createConnectionMutation, { loading }] = useCreateConnectionMutation({
    refetchQueries: ["AppsListColumn", "Connections"],
  });

  const fieldUnset =
    name === "" ||
    description === "" ||
    hostname === "" ||
    !adminOwnerId ||
    projectID === "" ||
    publicKey === "" ||
    privateKey === "";

  const onSubmit = async () => {
    const urlErrorMessage = validateUrl(hostname, "URL");
    if (urlErrorMessage) {
      setErrorMessage(`Error: ${urlErrorMessage}`);
      return;
    }
    try {
      const { data } = await createConnectionMutation({
        variables: {
          input: {
            name: name,
            description: description,
            connectionType: ConnectionType.MongoAtlas,
            adminOwnerId: adminOwnerId ?? "",
            visibility: visibility,
            visibilityGroupIds: visibilityGroupIds ?? [],
            importVisibility: Visibility.Global,
            metadata: {
              connectionType: ConnectionType.MongoAtlas,
              mongoAtlas: {
                serverHostname: hostname,
              },
            },
            credentials: {
              authType: AuthType.MongoAtlas,
              mongoAtlas: {
                publicKey: publicKey,
                privateKey: privateKey,
                projectID: projectID,
              },
            },
          },
        },
      });
      switch (data?.createConnection.__typename) {
        case "CreateConnectionResult":
          history.replace(
            getResourceUrl(
              EntityTypeDeprecated.Connection,
              data.createConnection.connection.id
            )
          );
          displaySuccessToast(`Success: MongoDB Atlas app created`);
          logEvent({
            name: "apps_create_click",
            properties: {
              connectionType: ConnectionType.MongoAtlas,
            },
          });

          break;
        case "ConnectionExistsError":
        case "ConnectionBadMetadataError":
        case "UserFacingError":
          logWarning(new Error(data.createConnection.message));
          setErrorMessage(data.createConnection.message);
          break;
        default:
          logError(new Error(`app creation failed`));
          setErrorMessage(`Error: app creation failed`);
      }
    } catch (error) {
      logError(error, "app creation failed");
      setErrorMessage(
        getModifiedErrorMessage("Error: app creation failed", error)
      );
    }
  };

  const CreateConnectionComponents = hasV3
    ? CreateConnectionComponentsV3
    : CreateConnectionComponentsV2;
  const CreateConnectionView = hasV3
    ? CreateConnectionViewV3
    : CreateConnectionViewV2;

  return (
    <CreateConnectionView
      title={"Add your MongoDB Atlas database"}
      logo={mongoLogo}
      onSubmit={onSubmit}
      submitDisabled={fieldUnset}
      submitLoading={loading}
    >
      <>
        <CreateConnectionComponents
          title={"Step 1"}
          subtitle={"Create MongoDB Atlas database application"}
          isLast
        >
          <>
            <FormGroup label="App name:">
              <Input
                onChange={setName}
                placeholder="Identifiable name of the MongoDB Atlas database."
                value={name}
              />
            </FormGroup>
            <FormGroup label="App admin:">
              <OwnerDropdown
                selectedOwnerId={adminOwnerId}
                onSelectOwner={(owner) => setAdminOwnerId(owner?.id)}
                placeholder="Select an owner to own this app."
              />
            </FormGroup>
            <FormGroup label="Description:">
              <Input
                onChange={setDescription}
                placeholder="A brief description of the account to further inform people requesting access to it."
                value={description}
              />
            </FormGroup>
            {hasAppLevelVisibility && (
              <FormGroup label="Visibility:">
                <VisibilitySelector
                  visibility={visibility}
                  onChangeVisibility={(vis) => {
                    if (vis == Visibility.Global) {
                      setVisibility(Visibility.Global);
                      setVisibilityGroupIds([]);
                    } else {
                      setVisibility(Visibility.Team);
                    }
                  }}
                  visibilityGroups={visibilityGroupIds ?? []}
                  onChangeVisibilityGroups={setVisibilityGroupIds}
                />
              </FormGroup>
            )}
            <FormGroup label="URL:">
              <Input
                onChange={setHostname}
                placeholder="The URL associated with the database (e.g. mongodb+srv://cluster0.abc.mongodb.net)."
                value={hostname}
              />
            </FormGroup>
            <FormGroup label="Public key:">
              <Input
                onChange={setPublicKey}
                placeholder="The public key associated with the MongoDB Atlas project."
                value={publicKey}
                type="password"
              />
            </FormGroup>
            <FormGroup label="Private key:">
              <Input
                onChange={setPrivateKey}
                placeholder="The private key associated with the MongoDB Atlas project."
                value={privateKey}
                type="password"
              />
            </FormGroup>
            <FormGroup label="Project ID:">
              <Input
                onChange={setProjectID}
                placeholder="The project ID of the MongoDB Atlas project."
                value={projectID}
              />
            </FormGroup>
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
            {!hasV3 && (
              <div
                className={sprinkles({
                  display: "flex",
                  justifyContent: "flex-end",
                })}
              >
                <Button
                  type="primary"
                  disabled={fieldUnset}
                  label={"Create"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </div>
            )}
          </>
        </CreateConnectionComponents>
      </>
    </CreateConnectionView>
  );
};

export default CreateMongoAtlas;
