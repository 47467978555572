import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewConnectionFragment,
  AccessReviewConnectionUserFragment,
  EntityIdTuple,
  EntityType,
  ReviewerUserInput,
  useAccessReviewConnectionQuery,
  useUpdateConnectionReviewersMutation,
  useUpdateConnectionUserReviewersMutation,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import { connectionTypeInfoByType } from "components/label/ConnectionTypeLabel";
import AccessReviewerModal from "components/modals/AccessReviewerModal";
import { useToast } from "components/toast/Toast";
import { Banner, Button, Divider, Input, Tabs } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useState } from "react";
import { useHistory, useParams } from "react-router";
import { filterSearchResults } from "utils/search/filter";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import * as styles from "./AccessReviewResource.css";
import AccessReviewReviewersCell from "./AccessReviewReviewersCell";
import {
  AccessReviewOutcomeLabel,
  AccessReviewStatusLabel,
} from "./AccessReviewStatus";
import {
  getReviewerModalEntries,
  getReviewersSortValue,
} from "./common/Common";
import ConnectionUserSubRow from "./common/ConnectionUserSubRow";
import EventsTable from "./common/EventsTable";
import UserCell from "./common/UserCell";
import AccessReviewConnectionOverview from "./connections/AccessReviewConnectionOverview";

const AccessReviewConnectionDetails = () => {
  const history = useHistory();
  const { accessReviewId, accessReviewConnectionId } = useParams<
    Record<string, string>
  >();
  const { displaySuccessToast } = useToast();

  const [selectedConnectionUserIds, setSelectedConnectionUserIds] = useState<
    string[]
  >([]);
  const [showBulkReviewersModal, setShowBulkReviewersModal] = useState(false);
  const [reviewersError, setReviewersError] = useState("");

  const [
    updateConnectionReviewers,
    { loading: updateLoading },
  ] = useUpdateConnectionReviewersMutation();
  const { data, error, loading } = useAccessReviewConnectionQuery({
    variables: {
      input: {
        id: accessReviewConnectionId,
      },
    },
    skip: !accessReviewConnectionId,
  });

  if (loading) {
    return (
      <Column isContent>
        <ColumnContentSkeleton />
      </Column>
    );
  }

  let accessReviewConnection: AccessReviewConnectionFragment | undefined;
  if (
    data?.accessReviewConnection.__typename === "AccessReviewConnectionResult"
  ) {
    accessReviewConnection = data.accessReviewConnection.accessReviewConnection;
  }
  const connection = accessReviewConnection?.connection;

  if (error || !accessReviewConnection || !connection) {
    return (
      <Column isContent>
        <UnexpectedErrorPage />
      </Column>
    );
  }

  const currentAllConnectionUserIds = new Set(
    accessReviewConnection.connectionUsers?.map((u) => u.id)
  );
  const filteredConnectionUserIds = selectedConnectionUserIds.filter((id) =>
    currentAllConnectionUserIds.has(id)
  );

  const handleSubmitReviewers = async (reviewers: ReviewerUserInput[]) => {
    const entityIds: EntityIdTuple[] = filteredConnectionUserIds.map((id) => ({
      entityId: id,
      entityType: EntityType.AccessReviewConnectionUser,
    }));
    reviewers = reviewers.map((reviewer) => {
      reviewer.entityIds = entityIds;
      return reviewer;
    });

    // Only update reviewers for checked rows
    accessReviewConnection?.reviewerUsers.forEach((reviewerUser) => {
      if (!filteredConnectionUserIds.includes(reviewerUser.entityId)) {
        reviewers.push({
          userId: reviewerUser.userId,
          entityIds: [
            {
              entityId: reviewerUser.entityId,
              entityType: reviewerUser.entityType,
            },
          ],
        });
      }
    });

    try {
      const { data } = await updateConnectionReviewers({
        variables: {
          input: {
            accessReviewConnectionId,
            reviewers,
          },
        },
        refetchQueries: [
          "MyAccessReviewItems",
          "AccessReviewItems",
          "AccessReviewConnection",
        ],
      });
      switch (data?.updateConnectionReviewers.__typename) {
        case "UpdateConnectionReviewersResult": {
          displaySuccessToast("Success: reviewers updated");
          setSelectedConnectionUserIds([]);
          setShowBulkReviewersModal(false);
          break;
        }
        case "AccessReviewAlreadyStoppedError": {
          setReviewersError(data?.updateConnectionReviewers.message);
          break;
        }
      }
    } catch (error) {
      setReviewersError(
        getModifiedErrorMessage(
          "Error: failed to update access reviewers",
          error
        )
      );
    }
  };

  const isStoppedReview =
    accessReviewConnection.accessReview?.stoppedDate != null;

  return (
    <>
      <Column isContent>
        <ColumnHeader
          title={connection.name}
          icon={{ type: "entity", entityType: connection.connectionType }}
          subtitle={connectionTypeInfoByType[connection.connectionType].name}
          onClose={() =>
            history.push(`/access-reviews/${accessReviewId}/entities`)
          }
          rightActions={
            !isStoppedReview &&
            (!location.hash || location.hash === "#users") ? (
              <Button
                label="Bulk assign reviewers"
                type="primary"
                borderless
                leftIconName="edit"
                disabled={filteredConnectionUserIds.length === 0}
                onClick={() => setShowBulkReviewersModal(true)}
              />
            ) : undefined
          }
        />
        <Divider />
        <div
          className={sprinkles({
            display: "flex",
            justifyContent: "center",
            marginY: "md",
          })}
        >
          <div className={sprinkles({ width: "fit-content" })}>
            <Tabs
              tabInfos={[
                {
                  title: "Users",
                  isSelected: !location.hash || location.hash === "#users",
                  onClick: () => history.push({ hash: "#users" }),
                  icon: "users",
                  badgeCount: accessReviewConnection.numConnectionUsers,
                },
                {
                  title: "Overview",
                  isSelected: location.hash === "#overview",
                  onClick: () => history.push({ hash: "#overview" }),
                  icon: "list",
                },
                {
                  title: "Events",
                  isSelected: location.hash === "#events",
                  onClick: () => history.push({ hash: "#events" }),
                  icon: "events",
                },
              ]}
              round
            />
          </div>
        </div>
        <ColumnContent>
          {!location.hash || location.hash === "#users" ? (
            <Users
              accessReviewConnection={accessReviewConnection}
              selectedIds={filteredConnectionUserIds}
              onSelectRows={(ids) => {
                setSelectedConnectionUserIds((prev) =>
                  _.uniq([...prev, ...ids])
                );
              }}
              onRemoveRows={(ids) => {
                setSelectedConnectionUserIds((prev) =>
                  prev.filter((id) => !ids.includes(id))
                );
              }}
            />
          ) : null}
          {location.hash === "#overview" && (
            <AccessReviewConnectionOverview
              accessReviewConnection={accessReviewConnection}
            />
          )}
          {location.hash === "#events" && (
            <div className={styles.tableContainer}>
              <EventsTable
                eventFilter={{
                  objects: {
                    objectId: accessReviewConnection.id,
                  },
                }}
              />
            </div>
          )}
        </ColumnContent>
      </Column>
      {showBulkReviewersModal && (
        <AccessReviewerModal
          title="Reviewers"
          isModalOpen={showBulkReviewersModal}
          numUserReviews={filteredConnectionUserIds.length}
          onClose={() => {
            setShowBulkReviewersModal(false);
            setReviewersError("");
          }}
          loading={updateLoading}
          onSubmit={handleSubmitReviewers}
          errorMessage={reviewersError}
          entryInfos={getReviewerModalEntries(
            accessReviewConnection.reviewerUsers,
            new Set(filteredConnectionUserIds)
          )}
          canEditReviewers={!isStoppedReview}
        />
      )}
    </>
  );
};

interface ConnectionUserRow {
  id: string;
  name: string;
  reviewers: string;
  status: string;
  outcome: string;
  data: AccessReviewConnectionUserFragment;
}

const Users = ({
  accessReviewConnection,
  selectedIds,
  onSelectRows,
  onRemoveRows,
}: {
  accessReviewConnection: AccessReviewConnectionFragment;
  selectedIds: string[];
  onSelectRows: (ids: string[]) => void;
  onRemoveRows: (ids: string[]) => void;
}) => {
  const { displaySuccessToast } = useToast();

  const [selectedConnectionUser, setSelectedConnectionUser] = useState<
    AccessReviewConnectionUserFragment | undefined
  >();
  const [showReviewerModal, setShowReviewerModal] = useState(false);
  const [reviewerError, setReviewerError] = useState("");
  const [searchQuery, setSearchQuery] = useState("");

  const [
    updateConnectionUserReviewers,
    { loading },
  ] = useUpdateConnectionUserReviewersMutation();

  const connectionUsers = accessReviewConnection.connectionUsers ?? [];
  const isStoppedReview =
    accessReviewConnection.accessReview?.stoppedDate != null;

  const rows: ConnectionUserRow[] = filterSearchResults(
    connectionUsers.slice(),
    searchQuery,
    (connectionUser) => [
      connectionUser.user?.fullName,
      connectionUser.user?.email,
    ]
  ).map((connectionUser) => {
    return {
      id: connectionUser.id,
      name: connectionUser.user?.fullName ?? "",
      reviewers: getReviewersSortValue(connectionUser.reviewerUsers ?? []),
      status: connectionUser.statusAndOutcome.status,
      outcome: connectionUser.statusAndOutcome.outcome,
      data: connectionUser,
    };
  });

  const columns: Header<ConnectionUserRow>[] = [
    {
      id: "name",
      label: "Name",
      width: 400,
      customCellRenderer: (row) => {
        const user = row.data.user;
        if (!user) {
          return <></>;
        }
        return <UserCell user={user} />;
      },
    },
    {
      id: "reviewers",
      label: "Reviewers",
      width: 150,
      customCellRenderer: (row) => {
        return (
          <AccessReviewReviewersCell
            itemType="app"
            canEditReviewers={!isStoppedReview}
            reviewerUsers={row.data.reviewerUsers ?? []}
            onClick={() => {
              setSelectedConnectionUser(row.data);
              setShowReviewerModal(true);
            }}
          />
        );
      },
    },
    {
      id: "status",
      label: "Status",
      width: 150,
      customCellRenderer: (row) => {
        return (
          <AccessReviewStatusLabel
            entityType={EntityType.AccessReviewConnectionUser}
            status={row.data.statusAndOutcome.status}
            warnings={row.data.warnings}
            connection={accessReviewConnection.connection}
            user={row.data.user}
            accessReviewId={accessReviewConnection.accessReviewId}
          />
        );
      },
    },
    {
      id: "outcome",
      label: "Outcome",
      width: 120,
      customCellRenderer: (row) => {
        return (
          <AccessReviewOutcomeLabel
            entityType={EntityType.AccessReviewConnectionUser}
            outcome={row.data.statusAndOutcome.outcome}
          />
        );
      },
    },
  ];

  const renderSubRow = (row: ConnectionUserRow) => {
    return <ConnectionUserSubRow connectionUser={row.data} />;
  };

  const handleSubmitReviewers = async (reviewers: ReviewerUserInput[]) => {
    if (!selectedConnectionUser) {
      return;
    }
    try {
      const { data } = await updateConnectionUserReviewers({
        variables: {
          input: {
            accessReviewConnectionUserId: selectedConnectionUser.id,
            reviewers,
          },
        },
        refetchQueries: [
          "MyAccessReviewItems",
          "AccessReviewItems",
          "AccessReviewGroup",
        ],
      });
      switch (data?.updateConnectionUserReviewers.__typename) {
        case "UpdateConnectionUserReviewersResult": {
          displaySuccessToast("Success: reviewers updated");
          setShowReviewerModal(false);
          break;
        }
        case "AccessReviewAlreadyStoppedError": {
          setReviewerError(data?.updateConnectionUserReviewers.message);
          break;
        }
        case "UpdateAccessReviewReviewersValidationError": {
          setReviewerError(data?.updateConnectionUserReviewers.message);
          break;
        }
      }
    } catch (error) {
      setReviewerError(
        getModifiedErrorMessage(
          "Error: failed to update access reviewers",
          error
        )
      );
    }
  };

  const multiSelectProps = isStoppedReview
    ? {}
    : {
        checkedRowIds: new Set(selectedIds),
        onCheckedRowsChange: (ids: string[], checked: boolean) => {
          if (checked) {
            onSelectRows(ids);
          } else {
            onRemoveRows(ids);
          }
        },
        selectAllChecked: selectedIds.length === rows.length,
        onSelectAll: (checked: boolean) => {
          if (checked) {
            onSelectRows(rows.map((row) => row.id));
          } else {
            onRemoveRows(rows.map((row) => row.id));
          }
        },
      };

  return (
    <>
      <div className={styles.tableContainer}>
        {isStoppedReview && (
          <div className={sprinkles({ marginBottom: "md" })}>
            <Banner
              type="warning"
              message="This access review has been stopped. No further actions can be taken on reviewer assignments and reviews."
            />
          </div>
        )}
        <div className={styles.searchContainer}>
          <Input
            leftIconName="search"
            type="search"
            style="search"
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Search this table"
          />
        </div>
        <Table
          rows={rows}
          totalNumRows={rows.length}
          getRowId={(row) => row.id}
          columns={columns}
          renderSubRow={renderSubRow}
          emptyState={{
            title: "No users to review",
            subtitle:
              "No users had direct access to this resource at the time of the access review.",
          }}
          {...multiSelectProps}
        />
      </div>
      {selectedConnectionUser && showReviewerModal && (
        <AccessReviewerModal
          title="Reviewers"
          isModalOpen={showReviewerModal}
          onClose={() => {
            setShowReviewerModal(false);
            setReviewerError("");
          }}
          loading={loading}
          onSubmit={handleSubmitReviewers}
          errorMessage={reviewerError}
          entryInfos={getReviewerModalEntries(
            selectedConnectionUser.reviewerUsers,
            null
          )}
          canEditReviewers={!isStoppedReview}
        />
      )}
    </>
  );
};

export default AccessReviewConnectionDetails;
