import {
  EntityType,
  RequestTemplateSmallFragment,
  useRequestTemplatesQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnHeader from "components/column/ColumnHeader";
import ColumnListItem from "components/column/ColumnListItem";
import ColumnListScroller from "components/column/ColumnListScroller";
import { Divider } from "components/ui";
import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import { getResourceUrlNew } from "utils/common";

import CreateRequestTemplateModal from "./CreateRequestTemplateModal";

const RequestTemplatesColumn = () => {
  const { requestTemplateId } = useParams<{ requestTemplateId: string }>();
  const history = useHistory();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { data } = useRequestTemplatesQuery();
  const { authState } = useContext(AuthContext);

  let templates: RequestTemplateSmallFragment[] = [];
  if (data?.requestTemplates.requestTemplates) {
    templates = data?.requestTemplates.requestTemplates;
  }

  const addMenuOptions: PropsFor<typeof ColumnHeader>["addMenuOptions"] = [];
  if (authState.user?.isAdmin) {
    addMenuOptions.push({
      label: "Create template",
      onClick: () => setShowCreateModal(true),
      icon: { type: "name", icon: "plus" },
    });
  }

  return (
    <>
      <Column>
        <ColumnHeader
          title="Custom Access Requests"
          icon={{ type: "name", icon: "template" }}
          breadcrumbs={[{ name: "Templates", to: "/templates" }]}
          addMenuOptions={addMenuOptions}
        />
        <Divider />
        <ColumnListScroller
          emptyState={{
            title: "No templates",
            subtitle: authState.user?.isAdmin
              ? "Click the + button in the top right to add."
              : undefined,
          }}
          numRows={templates.length}
          renderRow={(i) => {
            const template = templates[i];
            if (!template) {
              return <></>;
            }
            return (
              <ColumnListItem
                key={template.id}
                label={template.name}
                selected={template.id === requestTemplateId}
                onClick={() => {
                  history.push(
                    getResourceUrlNew({
                      entityId: template.id,
                      entityType: EntityType.RequestTemplate,
                    })
                  );
                }}
              />
            );
          }}
        />
      </Column>
      {showCreateModal ? (
        <CreateRequestTemplateModal
          templateType="request"
          onClose={() => setShowCreateModal(false)}
        />
      ) : null}
    </>
  );
};

export default RequestTemplatesColumn;
