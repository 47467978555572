import { ResourceDetailViewFragment } from "api/generated/graphql";
import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import React from "react";
import UsageTableV3 from "views/usage/UsageTableV3";

type ResourceUsageRowProps = {
  resource: ResourceDetailViewFragment;
};

const ResourceUsageRowV3 = (props: ResourceUsageRowProps) => {
  const usageTable = <UsageTableV3 resource={props.resource} />;

  return (
    <EntityViewerRow title={"Usage"} content={usageTable} isTable={true} />
  );
};

export default ResourceUsageRowV3;
