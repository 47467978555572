import { Select, Skeleton } from "components/ui";
import React from "react";
import { useAllOwnersQuery } from "utils/hooks";
import { logError } from "utils/logging";

interface Owner {
  name: string;
  id: string;
}

interface OwnerDropdownProps {
  selectedOwnerId?: string;
  onSelectOwner: (owner?: Owner) => void;
  clearable?: boolean;
  placeholder?: string;
  size?: PropsFor<typeof Select>["size"];
}

const OwnerDropdown = (props: OwnerDropdownProps) => {
  const { data, error, loading } = useAllOwnersQuery({
    variables: {
      input: { includeAll: true },
    },
  });
  if (error) {
    logError(error, `failed to list owners`);
  }

  if (loading) {
    return <Skeleton />;
  }

  const allOwners = data?.owners.owners.map((o) => ({
    name: o.name,
    id: o.id,
  }));

  const selectedOwner = allOwners?.find((o) => o.id === props.selectedOwnerId);

  return (
    <Select
      options={allOwners || []}
      value={selectedOwner}
      getOptionLabel={(option) => option.name}
      onChange={props.onSelectOwner}
      loading={loading}
      clearable={props.clearable}
      placeholder={props.placeholder}
      size={props.size}
    />
  );
};

export default OwnerDropdown;
