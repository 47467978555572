// Compare function used to sort access path infos
interface AccessPathsInfo {
  hasDirectAccess: boolean;
  numInheritedAccessPoints: number;
  numGroupAccessPoints?: number;
}
export const compareAccessPaths = (
  pathsA: AccessPathsInfo,
  pathsB: AccessPathsInfo
) => {
  if (!pathsA && !pathsB) {
    return 0;
  } else if (!pathsB) {
    return -1;
  } else if (!pathsA) {
    return 1;
  }

  const indirectAccessPointsA =
    pathsA.numInheritedAccessPoints + (pathsA?.numGroupAccessPoints ?? 0);
  const indirectAccessPointsB =
    pathsB.numInheritedAccessPoints + (pathsB?.numGroupAccessPoints ?? 0);

  // Show direct access first, then sort by number of indirect access points
  if (pathsA.hasDirectAccess !== pathsB.hasDirectAccess) {
    return pathsA.hasDirectAccess ? -1 : 1;
  } else if (indirectAccessPointsA !== indirectAccessPointsB) {
    return indirectAccessPointsA >= indirectAccessPointsB ? -1 : 1;
  }
  return 0;
};
