import { FormMode } from "components/forms/common";
import { FormGroup, FormRow, RadioGroup } from "components/ui";
import React from "react";

const options = [
  {
    value: true,
    label: "On",
    description:
      "When a group is imported into Opal, any resources that it contains will also be imported.",
  },
  {
    value: false,
    label: "Off",
    description:
      "Resources contained by imported groups will not be imported into Opal unless they're separately imported themselves.",
  },
];

type ConnectionAutoImportGroupResourcesRowProps = {
  mode: FormMode;
  autoImportGroupResources: boolean;
  onChange: (autoImportGroupResources: boolean) => void;
  isV3?: boolean;
};

export const ConnectionAutoImportGroupResourcesRow = (
  props: ConnectionAutoImportGroupResourcesRowProps
) => {
  const viewContent = props.autoImportGroupResources ? "On" : "Off";

  const editContent = (
    <RadioGroup
      value={options.find(
        (option) => option.value === props.autoImportGroupResources
      )}
      options={options}
      getOptionKey={(option) => option.value}
      getOptionLabel={(option) => option.label}
      getOptionDescription={(option) => option.description}
      onSelectValue={(newSetting) => {
        props.onChange(newSetting.value);
      }}
    />
  );

  if (props.isV3) {
    return (
      <FormGroup
        label="Auto-import group resources"
        infoTooltip="If a group imported into Opal contains resources that aren't in Opal, this
      specifies whether those resources are auto-imported when the group is imported or synced."
      >
        {props.mode === "view" ? viewContent : editContent}
      </FormGroup>
    );
  }

  return (
    <FormRow
      title="Auto-import group resources"
      tooltipText={`If a group imported into Opal contains resources that aren't in Opal, this
      specifies whether those resources are auto-imported when the group is imported or synced.`}
    >
      {props.mode === "view" ? viewContent : editContent}
    </FormRow>
  );
};

export default ConnectionAutoImportGroupResourcesRow;
