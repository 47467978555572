import {
  AccessReviewAction,
  AccessReviewGroupForDetailFragment,
  AccessReviewGroupResourceForDetailFragment,
  AccessReviewGroupResourceVisibilityPolicy,
  AccessReviewGroupUserFragment,
  AccessReviewType,
  EntityType,
  GroupAccessLevel,
  GroupResourceForContextFragment,
  HrIdpStatus,
  Maybe,
  ReviewerUserFragment,
  ReviewerUserStatus,
  ReviewGroupResourceAction,
  ReviewGroupUserAction,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import { ResourceLabel, TooltipPlacement } from "components/label/Label";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { Icon, Input, Label, TabsV3, Tooltip } from "components/ui";
import ButtonGroup from "components/ui/buttongroup/ButtonGroupV3";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import pluralize from "pluralize";
import { useContext, useEffect, useState } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { filterSearchResults } from "utils/search/filter";
import { updateReviewStateForGroupUserRoleChange } from "views/access_reviews/AccessReviewGroupRoleCell";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import { formatHrIdpStatus } from "views/users/utils";

import { calculateBatchAcceptRevokeState } from "./AccessReviewAcceptRevokeToggleButton";
import {
  AccessReviewContextActionType,
  AccessReviewContextEvent,
  AccessReviewContextState,
  emptyPerformReviewState,
} from "./AccessReviewContext";
import * as styles from "./AccessReviewGroupV3.css";
import DecisionButtons from "./common/DecisionButtons";
import NoteCell from "./common/NoteCell";
import StatusCell from "./common/StatusCell";
import { ReviewerUsers } from "./ReviewerUsers";

interface GroupUserRow {
  id: string;
  user: string;
  role: string;
  title?: string;
  manager?: string;
  team?: string;
  hrIdpStatus?: HrIdpStatus;
  updatedRole?: string;
  expires?: string;
  outcome?: string;
  note?: string;
  reviewerUsers?: ReviewerUserFragment[];
  data: AccessReviewGroupUserFragment;
}

interface GroupResourceRow {
  id: string;
  resource: string;
  role: string;
  connection: string;
  outcome?: string;
  note?: string;
  reviewerUsers?: ReviewerUserFragment[];
  data: AccessReviewGroupResourceForDetailFragment;
  expiration?: string;
}

interface GroupResourceForContextRow {
  id: string;
  resource: string;
  role: string;
  data: GroupResourceForContextFragment;
}

interface Props {
  accessReviewGroup: AccessReviewGroupForDetailFragment;
  accessReviewState: AccessReviewContextState;
  accessReviewDispatch: React.Dispatch<AccessReviewContextEvent>;
}

const AccessReviewGroupV3 = ({
  accessReviewGroup,
  accessReviewState,
  accessReviewDispatch,
}: Props) => {
  const location = useLocation();
  const { accessReviewGroupId } = useParams<Record<string, string>>();
  const history = useHistory();
  const { authState } = useContext(AuthContext);

  const group = accessReviewGroup.group;
  const performReviewState =
    accessReviewState.performReviewStateByUARGroupId[accessReviewGroupId];

  // Initialize review state if not already initialized
  useEffect(() => {
    if (
      !performReviewState ||
      !performReviewState.groupUserActions ||
      !performReviewState.groupResourceActions
    ) {
      accessReviewDispatch({
        type: AccessReviewContextActionType.AccessReviewItemUpdate,
        payload: {
          performReviewStateByUARGroupId: {
            ...accessReviewState.performReviewStateByUARGroupId,
            [accessReviewGroupId]: emptyPerformReviewState(),
          },
        },
      });
    }
  }, [
    accessReviewState,
    accessReviewGroupId,
    performReviewState,
    accessReviewDispatch,
  ]);

  if (!performReviewState) {
    return null;
  }

  if (!group) {
    return (
      <Column isContent maxWidth="lg">
        <UnexpectedErrorPage />
      </Column>
    );
  }

  const groupResourcePolicy =
    accessReviewGroup?.accessReview?.groupResourceVisibilityPolicy;

  const subtitle =
    groupResourcePolicy === AccessReviewGroupResourceVisibilityPolicy.Strict
      ? "This access review was configured to not show other resources in this group"
      : groupResourcePolicy ===
        AccessReviewGroupResourceVisibilityPolicy.ViewVisibleAndAssigned
      ? "Below are resources in this group that you do not need to review, but may help with your assessment. There may be other resources in this group that you have not been granted visibility into."
      : "Below are resources in this group that you do not need to review, but may help with your assessment.";

  const selfReviewAllowed = accessReviewGroup?.accessReview?.selfReviewAllowed;
  const numIncompleteGroupUsers = (accessReviewGroup?.groupUsers ?? []).filter(
    (user) => {
      return user.reviewerUsers?.find((reviewer) => {
        return (
          reviewer.userId === authState.user?.user.id &&
          (selfReviewAllowed || authState.user?.user.id !== user.userId) &&
          reviewer.status === ReviewerUserStatus.NotStarted
        );
      });
    }
  )?.length;
  const numIncompleteGroupResources = (
    accessReviewGroup?.groupResources ?? []
  ).filter((resource) => {
    return resource.reviewerUsers?.find((reviewer) => {
      return (
        reviewer.userId === authState.user?.user.id &&
        reviewer.status === ReviewerUserStatus.NotStarted
      );
    });
  })?.length;

  return (
    <>
      <div
        className={
          location.hash === "#resources"
            ? styles.tabsContainerForScrollable
            : styles.tabsContainer
        }
      >
        <div className={styles.tabs}>
          <TabsV3
            tabInfos={[
              {
                title: "Users",
                onClick: () => history.replace({ hash: "#users" }),
                isSelected: !location.hash || location.hash === "#users",
                icon: { type: "name", icon: "users" },
                count: numIncompleteGroupUsers,
              },
              {
                title: "Resources",
                onClick: () => history.replace({ hash: "#resources" }),
                isSelected: location.hash === "#resources",
                icon: { type: "name", icon: "cube" },
                count: numIncompleteGroupResources,
              },
            ]}
          />
        </div>
      </div>
      {location.hash === "#resources" ? (
        <>
          <AccessReviewGroupResourcesSection
            accessReviewGroup={accessReviewGroup}
            accessReviewState={accessReviewState}
            accessReviewDispatch={accessReviewDispatch}
          />
          <div
            className={sprinkles({
              marginX: "sm",
              marginTop: "md",
              fontWeight: "semibold",
            })}
          >
            Other resources in this group:
          </div>
          <div
            className={sprinkles({
              marginX: "sm",
              marginBottom: "md",
            })}
          >
            {subtitle}
          </div>
          <AccessReviewOtherGroupResourcesTableSection
            accessReviewGroup={accessReviewGroup}
          />
        </>
      ) : (
        <AccessReviewGroupUsers
          accessReviewGroup={accessReviewGroup}
          accessReviewState={accessReviewState}
          accessReviewDispatch={accessReviewDispatch}
        />
      )}
    </>
  );
};

const AccessReviewGroupUsers = ({
  accessReviewGroup,
  accessReviewState,
  accessReviewDispatch,
}: Props) => {
  const accessReviewID = accessReviewGroup.accessReviewId;
  const { accessReviewGroupId } = useParams<Record<string, string>>();
  const { authState } = useContext(AuthContext);
  const [selectedGroupUserIds, setSelectedGroupUserIds] = useState<string[]>(
    []
  );
  const [
    selectedBulkAction,
    setSelectedBulkAction,
  ] = useState<AccessReviewAction>(AccessReviewAction.NoAction);
  const [bulkUpdatedNote, setBulkUpdatedNote] = useState<string>();

  const performReviewState =
    accessReviewState.performReviewStateByUARGroupId[accessReviewGroupId];

  const [searchQuery, setSearchQuery] = useState("");

  const selfReviewAllowed = accessReviewGroup?.accessReview?.selfReviewAllowed;

  // Separate out users that are completed and not completed
  const noteByGroupUserID: Record<string, string> = {};
  const completedGroupUsers = (accessReviewGroup?.groupUsers ?? []).filter(
    (user) => {
      return user.reviewerUsers?.find((reviewer) => {
        if (reviewer.userId === authState.user?.user.id) {
          noteByGroupUserID[user.id] = reviewer.note ?? "";
        }
        return (
          reviewer.userId === authState.user?.user.id &&
          (selfReviewAllowed || authState.user?.user.id !== user.userId) &&
          reviewer.status !== ReviewerUserStatus.NotStarted
        );
      });
    }
  );
  const notStartedGroupUsers = (accessReviewGroup?.groupUsers ?? []).filter(
    (user) => {
      return user.reviewerUsers?.find((reviewer) => {
        if (reviewer.userId === authState.user?.user.id) {
          noteByGroupUserID[user.id] = reviewer.note ?? "";
        }
        return (
          reviewer.userId === authState.user?.user.id &&
          (selfReviewAllowed || authState.user?.user.id !== user.userId) &&
          reviewer.status === ReviewerUserStatus.NotStarted
        );
      });
    }
  );
  const [showCompleted, setShowCompleted] = useState(
    notStartedGroupUsers.length === 0 && completedGroupUsers.length > 0
  );

  const group = accessReviewGroup.group;
  if (!group) {
    return null;
  }

  const filteredSearchGroupUsers = filterSearchResults(
    showCompleted ? completedGroupUsers : notStartedGroupUsers,
    searchQuery,
    (groupUser) => [groupUser.user?.fullName, groupUser.user?.email]
  );

  const groupUserActionByUarGroupUserId: Record<
    string,
    ReviewGroupUserAction
  > = {};
  performReviewState?.groupUserActions?.forEach((groupUserAction) => {
    const id = groupUserAction.accessReviewGroupUserId;
    groupUserActionByUarGroupUserId[id] = groupUserAction;
  });

  const groupHasRoles = notStartedGroupUsers.some((user) =>
    Boolean(user.accessLevel.accessLevelRemoteId)
  );

  const rows: GroupUserRow[] = filteredSearchGroupUsers.map((groupUser) => {
    const groupUserAction = groupUserActionByUarGroupUserId[groupUser.id];
    const otherReviewers = (groupUser.reviewerUsers ?? []).filter(
      (reviewer) => reviewer.userId !== authState.user?.user.id
    );

    const row: GroupUserRow = {
      id: groupUser.id,
      user: groupUser.user?.fullName || groupUser.userId,
      role: groupUser.accessLevel.accessLevelName ?? "",
      updatedRole: groupUserAction?.updatedAccessLevel?.accessLevelName,
      title: groupUser.user?.position,
      manager: groupUser.user?.manager?.fullName,
      team: groupUser.user?.teamAttr ?? "--",
      hrIdpStatus: groupUser.user?.hrIdpStatus ?? undefined,
      outcome: groupUser.statusAndOutcome.outcome,
      note:
        groupUserAction?.note ?? noteByGroupUserID[groupUser.id] ?? undefined,
      reviewerUsers: otherReviewers,
      data: groupUser,
    };
    return row;
  });

  const bulkState = calculateBatchAcceptRevokeState(
    performReviewState.groupUserActions,
    notStartedGroupUsers.length
  );

  const getGroupUserReviewState = (groupUserId: string) => {
    return performReviewState.groupUserActions.find(
      (gu) => gu.accessReviewGroupUserId === groupUserId
    )?.action;
  };

  const handleReviewAction = (
    state: AccessReviewAction,
    accessReviewGroupUserId: string
  ) => {
    const existingInfo = performReviewState.groupUserActions.find(
      (groupUserAction) =>
        groupUserAction.accessReviewGroupUserId === accessReviewGroupUserId
    );

    if (existingInfo) {
      existingInfo.action = state;
    } else {
      performReviewState.groupUserActions.push({
        accessReviewGroupUserId,
        action: state,
      });
    }
    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARGroupId: {
          ...accessReviewState.performReviewStateByUARGroupId,
          [accessReviewGroup.id]: performReviewState,
        },
      },
    });
  };

  const handleBulkReviewAction = (state: AccessReviewAction) => {
    performReviewState.groupUserActions.forEach((groupUserAction) => {
      groupUserAction.action = state;
    });

    notStartedGroupUsers.forEach((groupUser) => {
      const existingInfo = performReviewState.groupUserActions.find(
        (groupUserAction) =>
          groupUserAction.accessReviewGroupUserId === groupUser.id
      );

      if (!existingInfo) {
        performReviewState.groupUserActions.push({
          accessReviewGroupUserId: groupUser.id,
          action: state,
        });
      } else {
        delete existingInfo.updatedAccessLevel;
      }
    });

    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARGroupId: {
          ...accessReviewState.performReviewStateByUARGroupId,
          [accessReviewGroup.id]: performReviewState,
        },
      },
    });
  };
  const bulkDecisionHeader = (
    <DecisionButtons
      state={bulkState}
      onApproveClick={() =>
        handleBulkReviewAction(
          bulkState === AccessReviewAction.Accept
            ? AccessReviewAction.NoAction
            : AccessReviewAction.Accept
        )
      }
      onRevokeClick={() =>
        handleBulkReviewAction(
          bulkState === AccessReviewAction.Revoke
            ? AccessReviewAction.NoAction
            : AccessReviewAction.Revoke
        )
      }
      groupId={group?.id}
      onRoleSelect={(role) => {
        if (!performReviewState) {
          return;
        }

        let updatedPerformReviewState = {
          ...performReviewState,
        };
        notStartedGroupUsers.forEach((groupUser) => {
          updatedPerformReviewState = updateReviewStateForGroupUserRoleChange(
            updatedPerformReviewState,
            groupUser,
            role as Maybe<GroupAccessLevel>
          );
        });

        accessReviewDispatch({
          type: AccessReviewContextActionType.AccessReviewItemUpdate,
          payload: {
            performReviewStateByUARGroupId: {
              ...accessReviewState.performReviewStateByUARGroupId,
              [accessReviewGroupId]: updatedPerformReviewState,
            },
          },
        });
      }}
      showRole={groupHasRoles}
      clearBackground
      bulkAction
    />
  );

  const userColumn: Header<GroupUserRow> = {
    id: "user",
    label: "User",
    width: 130,
    customCellRenderer: (row) => {
      const user = row.data.user;
      if (!user) {
        return <></>;
      }
      return (
        <ResourceLabel
          text={user.fullName}
          avatar={user.avatarUrl}
          pointerCursor={true}
          entityId={user.id}
          entityTypeNew={EntityType.User}
          target="_blank"
        />
      );
    },
  };

  const expiryColumn: Header<GroupUserRow> = {
    id: "expires",
    label: "Expires",
    width: 90,
    customCellRenderer: (row) => {
      const expirationString =
        row.data.groupUser?.access?.latestExpiringAccessPoint?.expiration;
      if (!expirationString) {
        return <>Never</>;
      }
      const expires = moment(expirationString).fromNow();
      return <span>{expires}</span>;
    },
  };

  const roleColumn: Header<GroupUserRow> = {
    id: "role",
    label: "Role",
    width: 130,
    customCellRenderer: (row) => {
      return (
        <span
          className={sprinkles({
            color: row.updatedRole ? "blue600" : "black",
          })}
        >
          {row.updatedRole ?? row.role}
        </span>
      );
    },
  };

  const outcomeColumn: Header<GroupUserRow> = {
    id: "outcome",
    label: "",
    sortable: false,
    customHeader: rows.length > 0 ? bulkDecisionHeader : <></>,
    width: groupHasRoles ? 160 : 100,
    customCellRenderer: (row) => {
      const state = getGroupUserReviewState(row.id);
      return (
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
          })}
        >
          <DecisionButtons
            state={state}
            onApproveClick={() =>
              handleReviewAction(
                state === AccessReviewAction.Accept
                  ? AccessReviewAction.NoAction
                  : AccessReviewAction.Accept,
                row.id
              )
            }
            onRevokeClick={() =>
              handleReviewAction(
                state === AccessReviewAction.Revoke
                  ? AccessReviewAction.NoAction
                  : AccessReviewAction.Revoke,
                row.id
              )
            }
            groupId={group?.id}
            onRoleSelect={(role) => {
              if (!performReviewState) {
                return;
              }
              const groupUser = row.data;

              const updatedPerformReviewState = updateReviewStateForGroupUserRoleChange(
                performReviewState,
                groupUser,
                role as Maybe<GroupAccessLevel>
              );

              accessReviewDispatch({
                type: AccessReviewContextActionType.AccessReviewItemUpdate,
                payload: {
                  performReviewStateByUARGroupId: {
                    ...accessReviewState.performReviewStateByUARGroupId,
                    [accessReviewGroupId]: updatedPerformReviewState,
                  },
                },
              });
            }}
            showRole={groupHasRoles}
          />
          <NoteCell
            note={row.note}
            accessReviewID={accessReviewID}
            targetIDs={[row.id]}
            targetType={AccessReviewType.GroupUser}
            onNoteChange={(updatedNoteContent) => {
              const groupUser = row.data;
              if (!performReviewState) {
                return;
              }

              const existingInfo = performReviewState.groupUserActions.find(
                (groupUserAction) =>
                  groupUserAction.accessReviewGroupUserId === groupUser.id
              );

              if (existingInfo) {
                existingInfo.note = updatedNoteContent;
              } else {
                performReviewState.groupUserActions.push({
                  accessReviewGroupUserId: groupUser.id,
                  action: AccessReviewAction.NoAction,
                  note: updatedNoteContent,
                });
              }

              accessReviewDispatch({
                type: AccessReviewContextActionType.AccessReviewItemUpdate,
                payload: {
                  performReviewStateByUARGroupId: {
                    ...accessReviewState.performReviewStateByUARGroupId,
                    [accessReviewGroup.id]: performReviewState,
                  },
                },
              });
            }}
          />
        </div>
      );
    },
  };

  const outcomeCompletedColumn: Header<GroupUserRow> = {
    id: "outcome", // Only shown in the completed view
    label: "Status",
    sortable: false,
    width: 80,
    customCellRenderer: (row) => {
      const outcome = row.data.statusAndOutcome.outcome;
      const status = row.data.statusAndOutcome.status;
      if (!outcome || !status) {
        return <></>;
      }

      return <StatusCell outcome={outcome} status={status} />;
    },
  };

  const noteColumn: Header<GroupUserRow> = {
    id: "note",
    label: "",
    sortable: false,
    width: 0,
    customCellRenderer: (row) => {
      if (!row.note) return <></>;
      return (
        <Tooltip tooltipText={row.note} placement={TooltipPlacement.Top}>
          <Icon name="message" color="blue700V3" />
        </Tooltip>
      );
    },
  };

  const reviewerUsersColumn: Header<GroupUserRow> = {
    id: "reviewerUsers",
    label: "",
    width: 0,
    customCellRenderer: (row) => {
      if (!row.reviewerUsers || row.reviewerUsers.length <= 1) {
        return <></>;
      }

      return <ReviewerUsers reviewerUsers={row.reviewerUsers} />;
    },
  };

  const titleColumn: Header<GroupUserRow> = {
    id: "title",
    label: "Title",
    width: 120,
    customCellRenderer: (row) => {
      return <Label label={row.title || "--"} oneLine />;
    },
  };

  const managerColumn: Header<GroupUserRow> = {
    id: "manager",
    label: "Manager",
    width: 130,
    customCellRenderer: (row) => {
      const manager = row.data.user?.manager;
      if (!manager) {
        return "--";
      }
      return (
        <ResourceLabel
          text={manager.fullName}
          avatar={manager.avatarUrl}
          pointerCursor={true}
          entityId={manager.id}
          entityTypeNew={EntityType.User}
          target="_blank"
        />
      );
    },
  };

  const teamColumn: Header<GroupUserRow> = {
    id: "team",
    label: "Team",
    width: 100,
    customCellRenderer: (row) => {
      return <Label label={row.team || "--"} oneLine />;
    },
  };

  const hrIdpStatusColumn: Header<GroupUserRow> = {
    id: "hrIdpStatus",
    label: "HR IDP Status",
    width: 65,
    customCellRenderer: (row) => {
      return row.hrIdpStatus ? formatHrIdpStatus(row.hrIdpStatus) : "-—";
    },
  };

  let columns: Header<GroupUserRow>[] = [
    userColumn,
    ...(groupHasRoles ? [roleColumn] : []),
    titleColumn,
    managerColumn,
    teamColumn,
    hrIdpStatusColumn,
    expiryColumn,
    noteColumn,
    reviewerUsersColumn,
    outcomeColumn,
  ];
  if (showCompleted) {
    columns = [
      userColumn,
      ...(groupHasRoles ? [roleColumn] : []),
      titleColumn,
      managerColumn,
      teamColumn,
      hrIdpStatusColumn,
      noteColumn,
      reviewerUsersColumn,
      outcomeCompletedColumn,
    ];
  }

  return (
    <div className={styles.tableContainer}>
      <div className={styles.controls}>
        <div className={styles.searchContainer}>
          <Input
            leftIconName="search"
            type="search"
            style="search"
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Search this table"
          />
        </div>
        <ButtonGroup
          buttons={[
            {
              label: "Todo",
              onClick: () => setShowCompleted(false),
              selected: !showCompleted,
            },
            {
              label: "Completed",
              onClick: () => setShowCompleted(true),
              selected: showCompleted,
            },
          ]}
        />
        {selectedGroupUserIds.length > 0 && (
          <div
            className={sprinkles({
              display: "flex",
              alignItems: "center",
              gap: "sm",
            })}
          >
            <div>
              {`You have selected ${selectedGroupUserIds.length} ${pluralize(
                "item",
                selectedGroupUserIds.length
              )}:`}
            </div>
            <DecisionButtons
              state={selectedBulkAction}
              groupId={group.id}
              onApproveClick={() => {
                const actionToPerform =
                  selectedBulkAction === AccessReviewAction.Accept
                    ? AccessReviewAction.NoAction
                    : AccessReviewAction.Accept;
                selectedGroupUserIds.forEach((ruID) => {
                  handleReviewAction(actionToPerform, ruID);
                });
                setSelectedBulkAction(actionToPerform);
              }}
              onRevokeClick={() => {
                const actionToPerform =
                  selectedBulkAction === AccessReviewAction.Revoke
                    ? AccessReviewAction.NoAction
                    : AccessReviewAction.Revoke;
                selectedGroupUserIds.forEach((ruID) => {
                  handleReviewAction(actionToPerform, ruID);
                });
                setSelectedBulkAction(actionToPerform);
              }}
              bulkAction
            />
            <NoteCell
              note={bulkUpdatedNote}
              accessReviewID={accessReviewID}
              targetIDs={selectedGroupUserIds}
              targetType={AccessReviewType.GroupUser}
              onNoteChange={(newNote) => {
                selectedGroupUserIds.forEach((groupUserId) => {
                  const existingInfo = performReviewState.groupUserActions.find(
                    (groupUserAction) =>
                      groupUserAction.accessReviewGroupUserId === groupUserId
                  );

                  if (existingInfo) {
                    existingInfo.note = newNote;
                  } else {
                    performReviewState.groupUserActions.push({
                      accessReviewGroupUserId: groupUserId,
                      action: AccessReviewAction.NoAction,
                      note: newNote,
                    });
                  }
                  accessReviewDispatch({
                    type: AccessReviewContextActionType.AccessReviewItemUpdate,
                    payload: {
                      performReviewStateByUARResourceId: {
                        ...accessReviewState.performReviewStateByUARResourceId,
                        [accessReviewGroup.id]: performReviewState,
                      },
                    },
                  });
                  setBulkUpdatedNote(newNote);
                });
              }}
            />
          </div>
        )}
      </div>
      <Table
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        columns={columns}
        emptyState={{
          title: showCompleted ? "No completed users" : "No users to review",
        }}
        onCheckedRowsChange={
          !showCompleted
            ? (checkedRowIds, checked) => {
                if (checked) {
                  setSelectedGroupUserIds((prev) => [
                    ...prev,
                    ...checkedRowIds,
                  ]);
                } else {
                  setSelectedGroupUserIds((prev) =>
                    prev.filter((id) => !checkedRowIds.includes(id))
                  );
                }
              }
            : undefined
        }
        checkedRowIds={new Set(selectedGroupUserIds)}
        selectAllChecked={selectedGroupUserIds.length === rows.length}
        onSelectAll={(checked) => {
          if (checked) {
            setSelectedGroupUserIds(rows.map((row) => row.id));
          } else {
            setSelectedGroupUserIds([]);
          }
        }}
      />
    </div>
  );
};

const AccessReviewGroupResourcesSection = ({
  accessReviewGroup,
  accessReviewState,
  accessReviewDispatch,
}: Props) => {
  const accessReviewID = accessReviewGroup.accessReviewId;
  const { authState } = useContext(AuthContext);
  const [selectedGroupResourceIds, setSelectedGroupResourceIds] = useState<
    string[]
  >([]);
  const [
    selectedBulkAction,
    setSelectedBulkAction,
  ] = useState<AccessReviewAction>(AccessReviewAction.NoAction);
  const [bulkUpdatedNote, setBulkUpdatedNote] = useState<string>();

  const performReviewState =
    accessReviewState.performReviewStateByUARGroupId[accessReviewGroup.id];

  const [searchQuery, setSearchQuery] = useState("");

  // Separate out resources that are completed and not completed
  const noteByGroupResourceID: Record<string, string> = {};
  const notStartedGroupResources = (
    accessReviewGroup?.groupResources ?? []
  ).filter((resource) => {
    return resource.reviewerUsers?.find((reviewer) => {
      if (reviewer.userId === authState.user?.user.id) {
        noteByGroupResourceID[resource.id] = reviewer.note ?? "";
      }
      return (
        reviewer.userId === authState.user?.user.id &&
        reviewer.status === ReviewerUserStatus.NotStarted
      );
    });
  });
  const completedGroupResources = (
    accessReviewGroup?.groupResources ?? []
  ).filter((resource) => {
    return resource.reviewerUsers?.find((reviewer) => {
      if (reviewer.userId === authState.user?.user.id) {
        noteByGroupResourceID[resource.id] = reviewer.note ?? "";
      }
      return (
        reviewer.userId === authState.user?.user.id &&
        reviewer.status !== ReviewerUserStatus.NotStarted
      );
    });
  });
  const [showCompleted, setShowCompleted] = useState(
    notStartedGroupResources.length === 0 && completedGroupResources.length > 0
  );

  const group = accessReviewGroup.group;
  if (!group) {
    return null;
  }

  const filteredSearchGroupResources = filterSearchResults(
    showCompleted ? completedGroupResources : notStartedGroupResources,
    searchQuery,
    (groupResource) => [
      groupResource.resource?.name,
      groupResource.resource?.connection?.name,
    ]
  );

  const groupResourceActionByUarGroupResourceId: Record<
    string,
    ReviewGroupResourceAction
  > = {};
  performReviewState?.groupResourceActions?.forEach((groupResourceAction) => {
    const id = groupResourceAction.accessReviewGroupResourceId;
    groupResourceActionByUarGroupResourceId[id] = groupResourceAction;
  });

  const rows: GroupResourceRow[] = filteredSearchGroupResources.map(
    (groupResource) => {
      const groupResourceAction =
        groupResourceActionByUarGroupResourceId[groupResource.id];
      const otherReviewers = (groupResource.reviewerUsers ?? []).filter(
        (reviewer) => reviewer.userId !== authState.user?.user.id
      );
      const expirationString =
        groupResource.groupResourceAccess?.directAccessPoint?.expiration;

      return {
        id: groupResource.id,
        resource: groupResource.resource?.name || "--",
        role: groupResource.accessLevel.accessLevelName || "--",
        connection: groupResource.resource?.connection?.name || "--",
        outcome: groupResource.statusAndOutcome.outcome,
        note:
          groupResourceAction?.note ??
          noteByGroupResourceID[groupResource.id] ??
          undefined,
        reviewerUsers: otherReviewers,
        data: groupResource,
        expiration: expirationString
          ? moment(expirationString).fromNow()
          : "--",
      };
    }
  );

  const bulkState = calculateBatchAcceptRevokeState(
    performReviewState.groupResourceActions,
    notStartedGroupResources.length
  );

  const getGroupResourceReviewState = (groupResourceId: string) => {
    return performReviewState.groupResourceActions.find(
      (gu) => gu.accessReviewGroupResourceId === groupResourceId
    )?.action;
  };

  const handleReviewAction = (
    state: AccessReviewAction,
    accessReviewGroupResourceId: string
  ) => {
    const existingInfo = performReviewState.groupResourceActions.find(
      (groupResourceAction) =>
        groupResourceAction.accessReviewGroupResourceId ===
        accessReviewGroupResourceId
    );

    if (existingInfo) {
      existingInfo.action = state;
    } else {
      performReviewState.groupResourceActions.push({
        accessReviewGroupResourceId,
        action: state,
      });
    }
    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARGroupId: {
          ...accessReviewState.performReviewStateByUARGroupId,
          [accessReviewGroup.id]: performReviewState,
        },
      },
    });
  };

  const handleBulkReviewAction = (state: AccessReviewAction) => {
    performReviewState.groupResourceActions.forEach((groupResourceAction) => {
      groupResourceAction.action = state;
    });

    notStartedGroupResources.forEach((groupResource) => {
      const existingInfo = performReviewState.groupResourceActions.find(
        (groupResourceAction) =>
          groupResourceAction.accessReviewGroupResourceId === groupResource.id
      );

      if (!existingInfo) {
        performReviewState.groupResourceActions.push({
          accessReviewGroupResourceId: groupResource.id,
          action: state,
        });
      }
    });

    accessReviewDispatch({
      type: AccessReviewContextActionType.AccessReviewItemUpdate,
      payload: {
        performReviewStateByUARGroupId: {
          ...accessReviewState.performReviewStateByUARGroupId,
          [accessReviewGroup.id]: performReviewState,
        },
      },
    });
  };
  const bulkDecisionHeader = (
    <DecisionButtons
      state={bulkState}
      onApproveClick={() =>
        handleBulkReviewAction(
          bulkState === AccessReviewAction.Accept
            ? AccessReviewAction.NoAction
            : AccessReviewAction.Accept
        )
      }
      onRevokeClick={() =>
        handleBulkReviewAction(
          bulkState === AccessReviewAction.Revoke
            ? AccessReviewAction.NoAction
            : AccessReviewAction.Revoke
        )
      }
      clearBackground
      bulkAction
    />
  );

  const resourceColumn: Header<GroupResourceRow> = {
    id: "resource",
    label: "Resource",
    customCellRenderer: (row) => {
      const resource = row.data.resource;
      if (!resource) {
        return <>--</>;
      }
      return (
        <ResourceLabel
          text={resource.name}
          subText={getResourceTypeInfo(resource.resourceType)?.name || "--"}
          entityId={resource.id}
          entityTypeNew={EntityType.Resource}
          resourceType={resource.resourceType}
          iconLarge
          bold
          icon={resource.iconUrl}
          target="_blank"
        />
      );
    },
  };

  const roleColumn: Header<GroupResourceRow> = {
    id: "role",
    label: "Role",
  };

  const expirationColumn: Header<GroupResourceRow> = {
    id: "expiration",
    label: "Expiration",
  };

  const outcomeColumn: Header<GroupResourceRow> = {
    id: "outcome",
    label: "",
    sortable: false,
    customHeader: rows.length > 0 ? bulkDecisionHeader : <></>,
    width: 100,
    customCellRenderer: (row) => {
      const state = getGroupResourceReviewState(row.id);
      return (
        <div
          className={sprinkles({
            display: "flex",
            gap: "sm",
          })}
        >
          <DecisionButtons
            state={state}
            onApproveClick={() =>
              handleReviewAction(
                state === AccessReviewAction.Accept
                  ? AccessReviewAction.NoAction
                  : AccessReviewAction.Accept,
                row.id
              )
            }
            onRevokeClick={() =>
              handleReviewAction(
                state === AccessReviewAction.Revoke
                  ? AccessReviewAction.NoAction
                  : AccessReviewAction.Revoke,
                row.id
              )
            }
          />
          <NoteCell
            note={row.note}
            accessReviewID={accessReviewID}
            targetIDs={[row.id]}
            targetType={AccessReviewType.GroupResource}
            onNoteChange={(updatedNoteContent) => {
              const groupResource = row.data;
              if (!performReviewState) {
                return;
              }

              const existingInfo = performReviewState.groupResourceActions.find(
                (groupResourceAction) =>
                  groupResourceAction.accessReviewGroupResourceId ===
                  groupResource.id
              );

              if (existingInfo) {
                existingInfo.note = updatedNoteContent;
              } else {
                performReviewState.groupResourceActions.push({
                  accessReviewGroupResourceId: groupResource.id,
                  action: AccessReviewAction.NoAction,
                  note: updatedNoteContent,
                });
              }

              accessReviewDispatch({
                type: AccessReviewContextActionType.AccessReviewItemUpdate,
                payload: {
                  performReviewStateByUARGroupId: {
                    ...accessReviewState.performReviewStateByUARGroupId,
                    [accessReviewGroup.id]: performReviewState,
                  },
                },
              });
            }}
          />
        </div>
      );
    },
  };

  const outcomeCompletedColumn: Header<GroupResourceRow> = {
    id: "outcome",
    label: "Status",
    sortable: false,
    width: 100,
    customCellRenderer: (row) => {
      const outcome = row.data.statusAndOutcome.outcome;
      const status = row.data.statusAndOutcome.status;
      if (!outcome || !status) {
        return <></>;
      }

      return <StatusCell outcome={outcome} status={status} />;
    },
  };

  const noteColumn: Header<GroupResourceRow> = {
    id: "note",
    label: "",
    sortable: false,
    width: 24,
    customCellRenderer: (row) => {
      if (!row.note) return <></>;
      return (
        <Tooltip tooltipText={row.note} placement={TooltipPlacement.Top}>
          <Icon name="message" color="blue700V3" />
        </Tooltip>
      );
    },
  };

  const reviewerUsersColumn: Header<GroupResourceRow> = {
    id: "reviewerUsers",
    label: "",
    width: 24,
    customCellRenderer: (row) => {
      if (!row.reviewerUsers || row.reviewerUsers.length <= 1) {
        return <></>;
      }

      return <ReviewerUsers reviewerUsers={row.reviewerUsers} />;
    },
  };

  let columns: Header<GroupResourceRow>[] = [
    resourceColumn,
    roleColumn,
    expirationColumn,
    noteColumn,
    reviewerUsersColumn,
    outcomeColumn,
  ];
  if (showCompleted) {
    columns = [
      resourceColumn,
      roleColumn,
      expirationColumn,
      noteColumn,
      reviewerUsersColumn,
      outcomeCompletedColumn,
    ];
  }

  return (
    <div className={styles.tableContainer}>
      <div className={styles.controls}>
        <div className={styles.searchContainer}>
          <Input
            leftIconName="search"
            type="search"
            style="search"
            value={searchQuery}
            onChange={setSearchQuery}
            placeholder="Search this table"
          />
        </div>
        <ButtonGroup
          buttons={[
            {
              label: "Todo",
              onClick: () => setShowCompleted(false),
              selected: !showCompleted,
            },
            {
              label: "Completed",
              onClick: () => setShowCompleted(true),
              selected: showCompleted,
            },
          ]}
        />
        {selectedGroupResourceIds.length > 0 && (
          <div
            className={sprinkles({
              display: "flex",
              alignItems: "center",
              gap: "sm",
            })}
          >
            <div>
              {`You have selected ${
                selectedGroupResourceIds.length
              } ${pluralize("item", selectedGroupResourceIds.length)}:`}
            </div>
            <DecisionButtons
              state={selectedBulkAction}
              groupId={group.id}
              onApproveClick={() => {
                const actionToPerform =
                  selectedBulkAction === AccessReviewAction.Accept
                    ? AccessReviewAction.NoAction
                    : AccessReviewAction.Accept;
                selectedGroupResourceIds.forEach((ruID) => {
                  handleReviewAction(actionToPerform, ruID);
                });
                setSelectedBulkAction(actionToPerform);
              }}
              onRevokeClick={() => {
                const actionToPerform =
                  selectedBulkAction === AccessReviewAction.Revoke
                    ? AccessReviewAction.NoAction
                    : AccessReviewAction.Revoke;
                selectedGroupResourceIds.forEach((ruID) => {
                  handleReviewAction(actionToPerform, ruID);
                });
                setSelectedBulkAction(actionToPerform);
              }}
              bulkAction
            />
            <NoteCell
              note={bulkUpdatedNote}
              accessReviewID={accessReviewID}
              targetIDs={selectedGroupResourceIds}
              targetType={AccessReviewType.GroupResource}
              onNoteChange={(newNote) => {
                selectedGroupResourceIds.forEach((GroupResourceId) => {
                  const existingInfo = performReviewState.groupResourceActions.find(
                    (groupResourceAction) =>
                      groupResourceAction.accessReviewGroupResourceId ===
                      GroupResourceId
                  );

                  if (existingInfo) {
                    existingInfo.note = newNote;
                  } else {
                    performReviewState.groupResourceActions.push({
                      accessReviewGroupResourceId: GroupResourceId,
                      action: AccessReviewAction.NoAction,
                      note: newNote,
                    });
                  }
                  accessReviewDispatch({
                    type: AccessReviewContextActionType.AccessReviewItemUpdate,
                    payload: {
                      performReviewStateByUARResourceId: {
                        ...accessReviewState.performReviewStateByUARResourceId,
                        [accessReviewGroup.id]: performReviewState,
                      },
                    },
                  });
                  setBulkUpdatedNote(newNote);
                });
              }}
            />
          </div>
        )}
      </div>

      <Table
        rows={rows}
        totalNumRows={rows.length}
        getRowId={(row) => row.id}
        columns={columns}
        emptyState={{
          title: showCompleted
            ? "No completed resources"
            : "No resources to review",
        }}
        onCheckedRowsChange={
          !showCompleted
            ? (checkedRowIds, checked) => {
                if (checked) {
                  setSelectedGroupResourceIds((prev) => [
                    ...prev,
                    ...checkedRowIds,
                  ]);
                } else {
                  setSelectedGroupResourceIds((prev) =>
                    prev.filter((id) => !checkedRowIds.includes(id))
                  );
                }
              }
            : undefined
        }
        checkedRowIds={new Set(selectedGroupResourceIds)}
        selectAllChecked={selectedGroupResourceIds.length === rows.length}
        onSelectAll={(checked) => {
          if (checked) {
            setSelectedGroupResourceIds(rows.map((row) => row.id));
          } else {
            setSelectedGroupResourceIds([]);
          }
        }}
      />
    </div>
  );
};

const AccessReviewOtherGroupResourcesTableSection = ({
  accessReviewGroup,
}: {
  accessReviewGroup: AccessReviewGroupForDetailFragment;
}) => {
  // AllGroupResources helps UAR reviewers gain more context on a Group they are reviewing.
  // If the UAR uses the Moderate or Full Visibility group visibility resource policy,
  // this shows resources in the group that are not being reviewed by the reviewer.
  // This field, since it is a field to give context, shows the current state of the group,
  // while the UARGroupResources field has the state when the UAR was created.
  const [searchQuery, setSearchQuery] = useState("");
  const { authState } = useContext(AuthContext);

  const group = accessReviewGroup.group;
  if (!group) {
    return null;
  }
  const groupResourceIdsToReview = (
    accessReviewGroup?.groupResources ?? []
  ).reduce((acc, groupResource) => {
    const reviewer = groupResource.reviewerUsers?.find((reviewer) => {
      return (
        reviewer.userId === authState.user?.user.id &&
        reviewer.status === ReviewerUserStatus.NotStarted
      );
    });
    if (reviewer && groupResource?.resource?.id) {
      acc[groupResource?.resource?.id] = true;
    }
    return acc;
  }, {} as Record<string, boolean>);

  const groupResources =
    (accessReviewGroup?.allGroupResources ?? []).filter((resource) => {
      return !groupResourceIdsToReview[resource.resourceId];
    }) ?? [];

  const filteredSearchGroupResources = filterSearchResults(
    groupResources,
    searchQuery,
    (groupResource) => [
      groupResource.resource?.name,
      groupResource.resource?.connection?.name,
    ]
  );

  const rows: GroupResourceForContextRow[] = filteredSearchGroupResources.map(
    (groupResource) => {
      return {
        id: groupResource.groupId,
        resource: groupResource.resource?.name || "--",
        role: groupResource.accessLevel.accessLevelName || "--",
        data: groupResource,
      };
    }
  );

  const columns: Header<GroupResourceForContextRow>[] = [
    {
      id: "resource",
      label: "Resource",
      customCellRenderer: (row) => {
        const resource = row.data.resource;
        if (!resource) {
          return <>--</>;
        }
        return (
          <ResourceLabel
            text={resource.name}
            subText={getResourceTypeInfo(resource.resourceType)?.name || "--"}
            entityTypeNew={EntityType.Resource}
            resourceType={resource.resourceType}
            iconLarge
            bold
            icon={resource.iconUrl}
          />
        );
      },
    },
    {
      id: "role",
      label: "Role",
    },
    {
      id: "data",
      label: "Expiration",
      customCellRenderer: (row) => {
        const expirationString = row.data.access.directAccessPoint?.expiration;
        if (!expirationString) {
          return <>--</>;
        }
        return <div>{moment(expirationString).fromNow()}</div>;
      },
    },
  ];

  const isGroupResourcePolicyStrict =
    accessReviewGroup?.accessReview?.groupResourceVisibilityPolicy ===
    AccessReviewGroupResourceVisibilityPolicy.Strict;

  return (
    <>
      {!isGroupResourcePolicyStrict && (
        <div className={styles.tableContainer}>
          <div className={styles.searchContainer}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={setSearchQuery}
              placeholder="Search this table"
            />
          </div>
          <Table
            rows={rows}
            totalNumRows={rows.length}
            getRowId={(row) => row.id}
            columns={columns}
            emptyState={{
              title: "No other resources to show",
            }}
          />
        </div>
      )}
    </>
  );
};

export default AccessReviewGroupV3;
