import {
  MessageChannelFragment,
  MessageChannelType,
  useMessageChannelsQuery,
} from "api/generated/graphql";
import { MessageChannelLabel } from "components/label/MessageChannelLabel";
import { FormRow, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext, useState } from "react";
import { logError } from "utils/logging";
import { AppsContext } from "views/apps/AppsContext";

import { ReviewerMessageChannelEdit } from "../../message_channel/ReviewerMessageChannelModal";
import { FormMode } from "../common";

const HELP_TEXT =
  "Link a Slack channel that receives a message for every access request requiring this owner's review.";

interface Props {
  mode: FormMode;
  messageChannel?: MessageChannelFragment;
  onChange: (channel?: MessageChannelFragment) => void;
  adminOwnerName: string;
  isV3?: boolean;
}

const SlackChannelsRow = (props: Props) => {
  const { bulkMode } = useContext(AppsContext);

  const [channelNameToCreate, setChannelNameToCreate] = useState("");

  const { data, error, loading } = useMessageChannelsQuery({
    variables: {
      input: {
        generateChannelName: props.adminOwnerName
          ? {
              itemName: props.adminOwnerName,
              channelType: MessageChannelType.Reviewer,
            }
          : undefined,
      },
    },
  });

  const channelById: { [channelId: string]: MessageChannelFragment } = {};
  let messageChannels: MessageChannelFragment[] = [];
  let dropdownErrorMessage = null;
  if (data) {
    switch (data.messageChannels.__typename) {
      case "MessageChannelsResult":
        messageChannels = data.messageChannels.messageChannels;
        messageChannels.forEach(
          (channel) => (channelById[channel.id] = channel)
        );
        if (
          channelNameToCreate === "" &&
          data.messageChannels.generatedChannelName
        ) {
          setChannelNameToCreate(data.messageChannels.generatedChannelName);
        }
        break;
      case "MessageChannelMissingOAuthScopeError":
        dropdownErrorMessage = data.messageChannels.message;
        break;
      case "MessageChannelRateLimitedError":
        dropdownErrorMessage = data.messageChannels.message;
        break;
      default:
        dropdownErrorMessage = `Error: failed to fetch message channels`;
        logError(new Error(`failed to fetch message channels`));
    }
  } else if (error) {
    dropdownErrorMessage = `Error: failed to fetch message channels`;
    logError(error, `failed to fetch message channels`);
  }

  const viewContent = (
    <>
      <div className={sprinkles({ marginBottom: "sm" })}>{HELP_TEXT}</div>
      {props.messageChannel ? (
        <MessageChannelLabel
          messageChannelName={props.messageChannel.name}
          thirdPartyProvider={props.messageChannel.thirdPartyProvider}
        />
      ) : (
        <div className={sprinkles({ fontWeight: "medium" })}>
          No linked channel
        </div>
      )}
    </>
  );

  const editContent = (
    <>
      {bulkMode && (
        <div className={sprinkles({ marginBottom: "md" })}>
          <Switch
            label="Leave unchanged"
            checked={props.messageChannel == null}
            onChange={(val) => {
              if (val) {
                props.onChange(undefined);
              } else {
                props.onChange(props.messageChannel);
              }
            }}
            rightAlign
          />
        </div>
      )}
      <div className={sprinkles({ marginBottom: "sm" })}>{HELP_TEXT}</div>
      <ReviewerMessageChannelEdit
        messageChannelOptions={messageChannels}
        selectedChannel={props.messageChannel}
        setSelectedChannel={(channel) => {
          props.onChange(channel);
        }}
        loading={loading}
        channelNameToCreate={channelNameToCreate}
        adminTeamName={props.adminOwnerName}
        errorMessage=""
        dropdownErrorMessage={dropdownErrorMessage}
      />
    </>
  );

  if (props.isV3) {
    return editContent;
  }

  return (
    <FormRow title="Reviewer Slack channels">
      {props.mode === "view" ? viewContent : editContent}
    </FormRow>
  );
};

export default SlackChannelsRow;
