import {
  Maybe,
  ThirdPartyProvider,
  useConfirmEndSystemRevocationMutation,
} from "api/generated/graphql";
import ConfirmModal from "components/modals/update/ConfirmModal";
import TicketProviderDropdown from "components/tickets/TicketProviderDropdown";
import { useToast } from "components/toast/Toast";
import { Button } from "components/ui";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { ReactElement, useContext, useState } from "react";
import { logError } from "utils/logging";
import AccessReviewContext, {
  AccessReviewContextActionType,
} from "views/access_reviews/AccessReviewContext";
import styles from "views/access_reviews/revocations/AccessReviewRevocations.module.scss";
import { RevocationAction } from "views/access_reviews/revocations/AccessReviewRevocationsToggleButton";
import { SelectTicketAssigneeEditor } from "views/support_tickets/SupportTicketEditor";

import TicketProjectDropdown from "../../../components/tickets/TicketProjectDropdown";

type AccessReviewRevocationsActionButtonsProps = {
  accessReviewId: string;
};

const AccessReviewRevocationsActionButtons = (
  props: AccessReviewRevocationsActionButtonsProps
) => {
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );
  const [showModal, setShowModal] = useState(false);

  // If this access review is not ongoing, don't show any buttons.
  if (!accessReviewState.ongoingAccessReviewIdSet.has(props.accessReviewId)) {
    return <></>;
  }

  // If there's no actions clicked, don't show any action buttons.
  if (
    Object.keys(accessReviewState.revocationActionByUARResourceUserId)
      .length === 0
  ) {
    return <></>;
  }

  return (
    <div
      className={sprinkles({
        display: "flex",
        alignItems: "center",
        gap: "md",
      })}
    >
      <Button
        label="Cancel"
        onClick={() => {
          accessReviewDispatch({
            type: AccessReviewContextActionType.AccessReviewItemUpdate,
            payload: {
              revocationActionByUARResourceUserId: {},
            },
          });
        }}
        borderless
      />
      <Button
        label="Confirm Action"
        type="warning"
        onClick={() => {
          setShowModal(true);
        }}
        leftIconName="check-circle"
      />
      {showModal && (
        <ConfirmRevocationActionsModal
          accessReviewId={props.accessReviewId}
          showModal={showModal}
          setShowModal={setShowModal}
        />
      )}
    </div>
  );
};

export type ConfirmRevocationActionsModalProps = {
  accessReviewId: string;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

export const ConfirmRevocationActionsModal = (
  props: ConfirmRevocationActionsModalProps
) => {
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );
  const [ticketProvider, setTicketProvider] = useState<
    Maybe<ThirdPartyProvider>
  >(null);
  const [ticketProjectId, setTicketProjectId] = useState<Maybe<string>>(null);
  const [ticketAssigneeUserId, setTicketAssigneeUserId] = useState<
    Maybe<string>
  >(null);

  const { displaySuccessToast } = useToast();
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [
    confirmEndSystemRevocation,
    { loading },
  ] = useConfirmEndSystemRevocationMutation();

  const modalReset = () => {
    setTicketProvider(null);
    setTicketAssigneeUserId(null);
    setErrorMessage(null);
    props.setShowModal(false);
  };

  const revokedUARResourceIds: string[] = [];
  const linkedUARResourceIds: string[] = [];
  Object.entries(accessReviewState.revocationActionByUARResourceUserId).forEach(
    ([uarResourceUserId, action]) => {
      if (action === RevocationAction.MarkRevoked) {
        revokedUARResourceIds.push(uarResourceUserId);
      } else if (action === RevocationAction.LinkTicket) {
        linkedUARResourceIds.push(uarResourceUserId);
      }
    }
  );

  const messages: ReactElement[] = [];
  if (linkedUARResourceIds.length > 0) {
    messages.push(
      <span className={styles.linkTicket}>
        Assign tickets to revoke{" "}
        {pluralize("item", linkedUARResourceIds.length, true)} on the end system
      </span>
    );
  }
  if (revokedUARResourceIds.length > 0) {
    messages.push(
      <span className={styles.markRevoked}>
        Mark {pluralize("item", revokedUARResourceIds.length, true)} as already
        revoked on the end system
      </span>
    );
  }

  return (
    <ConfirmModal
      title={"Confirm revocation actions"}
      message={
        <>
          <p>Please confirm that you want to:</p>
          <ul>
            {messages.map((message, index) => (
              <li key={index}>{message}</li>
            ))}
          </ul>
          {revokedUARResourceIds.length > 0 &&
            linkedUARResourceIds.length === 0 && (
              <p>Are you sure you want to record this action?</p>
            )}
          {linkedUARResourceIds.length > 0 && (
            <>
              <p>
                For each item you link to a ticket, Opal will create a new
                ticket specific to that item and track its status. When the
                ticket is closed, the item will be recorded in Opal as revoked
                on the end system.
              </p>
              <p>Please select the ticket provider and assignee below:</p>
              <SupportTicketInput
                ticketProvider={ticketProvider}
                setTicketProvider={setTicketProvider}
                ticketProjectId={ticketProjectId}
                setTicketProjectId={setTicketProjectId}
                ticketAssigneeUserId={ticketAssigneeUserId}
                setTicketAssigneeUserId={setTicketAssigneeUserId}
              />
            </>
          )}
        </>
      }
      isModalOpen={props.showModal}
      loading={loading}
      onClose={modalReset}
      errorMessage={errorMessage}
      onSubmit={async () => {
        try {
          const { data } = await confirmEndSystemRevocation({
            variables: {
              input: {
                revokedUARResourceUserIds: revokedUARResourceIds,
                linkedUARResourceUserIds: linkedUARResourceIds,
                ticketAssigneeUserId: ticketAssigneeUserId,
                ticketProjectId: ticketProjectId,
                ticketProvider: ticketProvider,
              },
            },
          });
          switch (data?.confirmEndSystemRevocation.__typename) {
            case "ConfirmEndSystemRevocationResult":
              accessReviewDispatch({
                type: AccessReviewContextActionType.AccessReviewItemUpdate,
                payload: {
                  revocationActionByUARResourceUserId: {},
                },
              });
              displaySuccessToast(`Success: actions recorded`);
              modalReset();
              break;
            case "AccessReviewAlreadyStoppedError":
            case "AccessReviewUserSupportTicketAlreadyLinkedError":
            case "RemoteUserNotFoundError":
              setErrorMessage(data.confirmEndSystemRevocation.message);
              break;
            default:
              setErrorMessage(`Error: failed to record actions`);
              logError(new Error(`failed to record actions`));
          }
        } catch (error) {
          setErrorMessage(`Error: failed to record actions`);
          logError(new Error(`failed to record actions: ${error}`));
        }
      }}
      submitLabel={"Confirm"}
      submitDisabled={
        linkedUARResourceIds.length > 0 &&
        (!ticketAssigneeUserId || !ticketProjectId || !ticketProvider)
      }
    />
  );
};

type SupportTicketInputProps = {
  ticketProvider: Maybe<ThirdPartyProvider>;
  setTicketProvider: (ticketProvider: Maybe<ThirdPartyProvider>) => void;
  ticketProjectId: Maybe<string>;
  setTicketProjectId: (ticketProjectId: Maybe<string>) => void;
  ticketAssigneeUserId: Maybe<string>;
  setTicketAssigneeUserId: (ticketAssigneeUserId: Maybe<string>) => void;
};

const SupportTicketInput = (props: SupportTicketInputProps) => {
  const {
    ticketProvider,
    setTicketProvider,
    ticketProjectId,
    setTicketProjectId,
    ticketAssigneeUserId,
    setTicketAssigneeUserId,
  } = props;

  return (
    <div
      className={sprinkles({
        display: "flex",
        flexDirection: "column",
        gap: "md",
      })}
    >
      <TicketProviderDropdown
        selectedTicketProvider={ticketProvider || undefined}
        onSelectTicketProvider={(ticketProvider) => {
          setTicketProvider(ticketProvider || null);
          setTicketAssigneeUserId(null);
          setTicketProjectId(null);
        }}
      />
      {ticketProvider && (
        <>
          <span>
            Please select the project in which you would want Opal to create
            tickets in.
          </span>
          <TicketProjectDropdown
            ticketProvider={ticketProvider}
            selectedTicketProjectId={ticketProjectId ?? undefined}
            onSelectTicketProject={(ticketProject) => {
              setTicketProjectId(ticketProject?.id ?? null);
            }}
          />
        </>
      )}
      <SelectTicketAssigneeEditor
        ticketAssigneeUserId={ticketAssigneeUserId}
        setTicketAssigneeUserId={setTicketAssigneeUserId}
        resetBelowFields={() => {}}
      />
    </div>
  );
};

export default AccessReviewRevocationsActionButtons;
