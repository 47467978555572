import {
  EntityType,
  RequestTemplatesDocument,
  useCreateRequestTemplateMutation,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { FormGroup, Input, Modal } from "components/ui";
import * as React from "react";
import { useHistory } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";

import { REQUEST_TEMPLATE_NAME_MAX_LEN } from "./requests/utils";

type TemplateType = "request";

interface ModalProps {
  templateType?: TemplateType;
  onClose: () => void;
}

const CreateRequestTemplateModal: React.FC<ModalProps> = ({
  templateType,
  onClose,
}) => {
  const history = useHistory();
  const [error, setError] = React.useState<string>("");
  const [templateName, setTemplateName] = React.useState<string>("");
  const [
    createRequestTemplate,
    { loading },
  ] = useCreateRequestTemplateMutation();

  const validateForm = () => {
    if (templateName.trim() === "") {
      setError("Error: template name cannot be empty.");
      return false;
    }
    return true;
  };

  const handleCreate = async () => {
    if (templateType === "request") {
      if (!validateForm()) {
        return;
      }
      try {
        setError("");
        const { data } = await createRequestTemplate({
          variables: {
            input: {
              name: templateName,
            },
          },
          refetchQueries: [{ query: RequestTemplatesDocument }],
        });
        switch (data?.createRequestTemplate.__typename) {
          case "CreateRequestTemplateResult":
            if (data.createRequestTemplate.requestTemplate.id) {
              history.push(
                getResourceUrlNew({
                  entityId: data.createRequestTemplate.requestTemplate.id,
                  entityType: EntityType.RequestTemplate,
                })
              );
              onClose();
            }
            break;
          case "RequestTemplateNameExistsError":
            logError(new Error(data.createRequestTemplate.message));
            setError(data.createRequestTemplate.message);
            break;
          default:
            logError(new Error("failed to create request template"));
            setError("Error: failed to create request template");
        }
      } catch (error) {
        setError("Error: failed to create request template");
        logError(error, "failed to create request template");
      }
    }
  };

  let body = <></>;
  if (templateType === "request") {
    body = (
      <FormGroup label="Name">
        <Input
          value={templateName}
          onChange={setTemplateName}
          maxLength={REQUEST_TEMPLATE_NAME_MAX_LEN}
        />
      </FormGroup>
    );
  }

  return (
    <Modal
      title={`New ${templateType} template`}
      isOpen={templateType != null}
      onClose={onClose}
    >
      <Modal.Body>
        {error && <ModalErrorMessage errorMessage={error} />}
        {body}
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Create"
        onPrimaryButtonClick={handleCreate}
        primaryButtonLoading={loading}
      />
    </Modal>
  );
};

export default CreateRequestTemplateModal;
