import { Icon } from "components/ui";
import * as styles from "components/ui/progress_stages/ProgressStages.css";

export interface ProgressStage {
  label: string;
  state: "active" | "uncompleted" | "completed" | "cancelled" | "warning";
  onClick?: () => void;
}

export type ProgressStagesProps = {
  stages: ProgressStage[];
};

export const ProgressStages = (props: ProgressStagesProps) => {
  const currentStage = props.stages.findIndex((_, i) => {
    return (
      i === props.stages.length - 1 ||
      props.stages[i + 1].state === "uncompleted"
    );
  });
  return (
    <div className={styles.barWrapper}>
      {props.stages.map((stage, i) => {
        return (
          <>
            {i > 0 ? <BaseBridge state={stage.state} /> : null}
            <BasePill
              onclick={stage.onClick}
              isCurrent={i === currentStage}
              text={stage.label}
              state={stage.state}
            />
          </>
        );
      })}
    </div>
  );
};

type BaseBridgeProps = {
  state: "active" | "uncompleted" | "completed" | "cancelled" | "warning";
};

const BaseBridge = (props: BaseBridgeProps) => {
  return <span className={styles.bridge({ state: props.state })} />;
};

type BasePillProps = {
  text: string;
  state: "active" | "uncompleted" | "completed" | "cancelled" | "warning";
  onclick?: () => void;
  isCurrent: boolean;
};

const BasePill = (props: BasePillProps) => {
  let icon: JSX.Element | null;
  switch (props.state) {
    case "active":
      icon = <div className={styles.activeIcon}></div>;
      break;
    case "completed":
      icon = <Icon data={{ type: "name", icon: "check" }} size="xxs" />;
      break;
    case "uncompleted":
      icon = null;
      break;
    case "cancelled":
      icon = <Icon data={{ type: "name", icon: "x" }} size="xxs" />;
      break;
    case "warning":
      icon = (
        <Icon data={{ type: "name", icon: "alert-triangle" }} size="xxs" />
      );
      break;
  }
  return (
    <div
      className={styles.pillWrapper({
        state: props.state,
        hasOnClick: !!props.onclick,
      })}
      onClick={props.onclick}
    >
      {icon}
      <span className={styles.pillText({ isCurrent: props.isCurrent })}>
        {props.text}
      </span>
    </div>
  );
};
