import { useBundleQuery } from "api/generated/graphql";
import { Column, ColumnContainer } from "components/column/Column";
import { useParams } from "react-router";
import { AuthorizedActionManage } from "utils/auth/auth";
import EventsTable from "views/events/EventsTable";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import BundleContentHeader from "./BundleContentHeader";

const BundleEventsColumn = () => {
  const { bundleId } = useParams<Record<string, string>>();
  const { data, error, loading } = useBundleQuery({
    variables: {
      input: {
        id: bundleId,
      },
    },
  });

  if (loading) {
    return (
      <ColumnContainer>
        <Column isContent maxWidth="lg">
          <ColumnContentSkeleton />
        </Column>
      </ColumnContainer>
    );
  }

  let canManage = false;
  if (data?.bundle.__typename === "BundleResult") {
    canManage =
      data.bundle.bundle.authorizedActions?.includes(AuthorizedActionManage) ??
      false;
  }

  if (!canManage || error) {
    return null;
  }

  return (
    <ColumnContainer>
      <Column isContent maxWidth="lg">
        <BundleContentHeader canManage={canManage} />
        <EventsTable
          eventFilter={{
            objects: {
              objectId: bundleId,
            },
          }}
          emptyTitle="No events for this bundle"
          emptySubtitle="Interact with this bundle to add events"
        />
      </Column>
    </ColumnContainer>
  );
};

export default BundleEventsColumn;
