import { EntityType, UserDropdownPreviewFragment } from "api/generated/graphql";
import { FormGroup, FormRow } from "components/ui";
import { useContext } from "react";
import { AppsContext } from "views/apps/AppsContext";

import sprinkles from "../../../css/sprinkles.css";
import { PaginatedUserDropdown } from "../../dropdown/PaginatedUserDropdown";
import { ResourceLabel } from "../../label/Label";
import { FormMode } from "../common";

interface Props {
  mode: FormMode;
  title: string;
  user?: UserDropdownPreviewFragment;
  onChange: (user?: UserDropdownPreviewFragment) => void;
  disabled?: boolean;
  editDescription?: string;
  styleAsFormGroup?: boolean;
}

const UserRow = (props: Props) => {
  const { bulkMode } = useContext(AppsContext);

  const viewContent = props.user?.id ? (
    <ResourceLabel
      entityTypeNew={EntityType.User}
      text={props.user.fullName}
      entityId={props.user.id}
      avatar={props.user.avatarUrl}
    />
  ) : (
    "--"
  );

  const editContent = (
    <>
      <div className={sprinkles({ marginBottom: "sm" })}>
        {props.editDescription}
      </div>
      <PaginatedUserDropdown
        disabled={props.disabled}
        value={props.user}
        onChange={(user) => props.onChange(user)}
        clearable={bulkMode === "edit"}
      />
    </>
  );

  return props.styleAsFormGroup ? (
    <FormGroup label={props.title}>
      {props.mode === "view" ? viewContent : editContent}
    </FormGroup>
  ) : (
    <FormRow title={props.title}>
      {props.mode === "view" ? viewContent : editContent}
    </FormRow>
  );
};

export default UserRow;
