import {
  ConnectionPreviewLargeFragment,
  ConnectionUserFragment,
  EntityType,
  HrIdpStatus,
} from "api/generated/graphql";
import { ResourceLabel } from "components/label/Label";
import PropagationStatusLabelWithModal from "components/propagation/PropagationStatusLabelWithModal";
import MuiVirtualTable, {
  CellRow,
} from "components/tables/material_table/MuiVirtualTable";
import {
  UsersTableHeaders,
  UsersTableRow,
} from "components/tables/rows/UsersTableRow";
import { Icon } from "components/ui";
import _ from "lodash";
import { useHistory } from "react-router";
import { SortDirection } from "react-virtualized";
import { getResourceUrlNew } from "utils/common";
import { PropagationType } from "utils/useRemediations";

type ConnectionUserTableRow = UsersTableRow & {
  status: string;
};

type ConnectionUsersTableProps = {
  connection: ConnectionPreviewLargeFragment;
  users: ConnectionUserFragment[];
};

export const ConnectionUsersTable = (props: ConnectionUsersTableProps) => {
  const history = useHistory();

  const headers = [
    ...UsersTableHeaders,
    { id: "status", label: "Status", width: 80, minWidth: 80 },
  ];

  const rows: CellRow<ConnectionUserTableRow>[] = props.users.map(
    (connectionUser) => {
      const user = connectionUser.user;

      const name = user?.fullName || "";
      const position = user?.position || "";
      let managerCell;
      if (user?.manager) {
        managerCell = {
          value: user.manager.fullName,
          element: (
            <ResourceLabel
              text={user.manager.fullName}
              entityId={user.manager.id}
              entityTypeNew={EntityType.User}
              avatar={user.manager.avatarUrl}
            />
          ),
        };
      } else {
        managerCell = {
          value: "",
          element: <>--</>,
        };
      }

      return {
        id: user?.id || "",
        data: {
          name: {
            value: name,
            element: (
              <ResourceLabel
                text={name}
                subText={user?.teamAttr}
                iconLarge={true}
                bold={true}
                entityTypeNew={EntityType.User}
                avatar={user?.avatarUrl}
                warningIcon={
                  user?.hrIdpStatus !== HrIdpStatus.Active ? (
                    <Icon name="user-x" size="xxs" color="red600" />
                  ) : undefined
                }
                warningTooltipText={
                  user?.hrIdpStatus !== HrIdpStatus.Active
                    ? `User IDP status is "${_.startCase(
                        _.camelCase(user?.hrIdpStatus?.toString() ?? "Unknown")
                      )}"`
                    : undefined
                }
                inactive={user?.hrIdpStatus !== HrIdpStatus.Active}
              />
            ),
            clickHandler: () => {
              history.push(
                getResourceUrlNew({
                  entityId: user?.id ?? null,
                  entityType: EntityType.User,
                })
              );
            },
          },
          position: {
            value: position,
            element: <>{position || "--"}</>,
          },
          manager: managerCell,
          status: {
            value: connectionUser.userId,
            sortValue: connectionUser.propagationStatus?.statusCode,
            element: (
              <PropagationStatusLabelWithModal
                propagationType={PropagationType.ConnectionUser}
                propagationStatus={connectionUser.propagationStatus}
                isAccessReview={false}
                entityInfo={{
                  roleAssignmentKey:
                    connectionUser.userId + props.connection.id,
                  user: connectionUser.user,
                  connection: props.connection,
                }}
              />
            ),
          },
        },
      };
    }
  );

  return (
    <MuiVirtualTable
      columns={headers}
      rows={rows}
      defaultSortBy={"name"}
      defaultSortDirection={SortDirection.ASC}
      allRowsLoaded
    />
  );
};

export default ConnectionUsersTable;
