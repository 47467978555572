import { formatDuration } from "components/label/Label";
import { EditDurationForm } from "components/modals/EditDurationModal";
import { FormRow, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import { useContext } from "react";
import { AppsContext } from "views/apps/AppsContext";
import { NULLABLE_DURATION_INDEFINITE } from "views/requests/utils";

import { FormMode } from "../common";

interface Props {
  mode: FormMode;
  recommendedDurationMin?: number | null;
  onChange: (durationMin: number | undefined | null) => void;
}

const RecommendedDurationRow = (props: Props) => {
  const { bulkMode } = useContext(AppsContext);

  let durationString = "--";
  if (props.recommendedDurationMin === NULLABLE_DURATION_INDEFINITE) {
    durationString = "Indefinite";
  } else if (props.recommendedDurationMin != null) {
    durationString = formatDuration(
      moment.duration(props.recommendedDurationMin, "m")
    );
  }
  const viewContent = durationString;

  const editContent = (
    <>
      {bulkMode === "edit" && (
        <div className={sprinkles({ marginBottom: "md" })}>
          <Switch
            label="Leave unchanged"
            checked={props.recommendedDurationMin === undefined}
            onChange={(val) => props.onChange(val ? undefined : null)}
            rightAlign
          />
        </div>
      )}
      <div className={sprinkles({ marginBottom: "sm" })}>
        When set, this will be the default selected duration value in a request.
        The duration will also be marked as recommended to the requester.
      </div>
      <EditDurationForm
        title="Duration"
        initValue={props.recommendedDurationMin}
        // Pass null specifically to set indefinite duration
        // We reserve undefined in configs to denote unchanged.
        onChange={(val) => props.onChange(val ?? null)}
        nullable
      />
    </>
  );
  return (
    <FormRow title="Recommended duration">
      {props.mode === "view" ? viewContent : editContent}
    </FormRow>
  );
};

export default RecommendedDurationRow;
