import {
  EntityType,
  useCreateConfigurationTemplateMutation,
  Visibility,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { ResourceConfig } from "components/forms/common";
import {
  makeCreateInputForTemplate,
  makeDefaultRequestConfig,
  validateResourceConfig,
} from "components/forms/utils";
import FullscreenView from "components/layout/FullscreenView";
import { useToast } from "components/toast/Toast";
import { useContext, useState } from "react";
import { useHistory } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import { ForbiddenPage } from "views/error/ErrorCodePage";
import OrgContext from "views/settings/OrgContext";

import ConfigTemplateForm from "./ConfigTemplateForm";

const CreateConfigTemplateV3 = () => {
  const history = useHistory();
  const transitionBack = useTransitionBack();
  const { orgState } = useContext(OrgContext);
  const { authState } = useContext(AuthContext);
  const { displaySuccessToast } = useToast();

  const [errors, setErrors] = useState<string[]>([]);
  const [config, setConfig] = useState<Partial<ResourceConfig>>({
    requestConfigs: [makeDefaultRequestConfig()],
    visibility: Visibility.Global,
    requireMfaToApprove: false,
    requireMfaToConnect: false,
    customRequestNotification: undefined,
  });

  const [
    createConfigurationTemplate,
    { loading: saveLoading },
  ] = useCreateConfigurationTemplateMutation();

  if (!authState.user?.isAdmin) {
    return <ForbiddenPage />;
  }

  const handleClose = () => {
    transitionBack("/templates/configurations");
  };

  const handleSave = async () => {
    const errors = validateResourceConfig(config, orgState);
    if (errors.length > 0) {
      setErrors(errors);
      return;
    }
    if (!config.name) {
      setErrors(["A unique name is required."]);
      return;
    }
    if (!config.adminOwner) {
      setErrors(["Admin owner is required."]);
      return;
    }

    try {
      setErrors([]);
      const { data } = await createConfigurationTemplate({
        variables: {
          input: makeCreateInputForTemplate(config.name, config),
        },
        refetchQueries: [
          "GetConfigurationTemplate",
          "ListConfigurationsTemplates",
        ],
      });

      switch (data?.createConfigurationTemplate.__typename) {
        case "CreateConfigurationTemplateResult":
          setErrors([]);
          displaySuccessToast("Successfully created configuration template");
          history.push(
            getResourceUrlNew({
              entityId:
                data.createConfigurationTemplate.configurationTemplate.id,
              entityType: EntityType.ConfigurationTemplate,
            })
          );
          break;
        case "ConfigurationTemplateNameExistsError":
          setErrors([data.createConfigurationTemplate.message]);
          break;
        default:
          logError("Failed to create configuration template");
          setErrors(["Failed to create configuration template."]);
      }
    } catch (err) {
      logError(err, "Failed to create configuration template");
      setErrors(["Failed to create configuration template."]);
    }
  };

  const hasRequiredFields =
    config.name && config.name.trim().length > 0 && config.adminOwner;
  return (
    <FullscreenView
      title="Create Configuration Template"
      onCancel={handleClose}
      onPrimaryButtonClick={handleSave}
      primaryButtonDisabled={!hasRequiredFields}
      primaryButtonLoading={saveLoading}
    >
      <ConfigTemplateForm
        config={config}
        onChange={setConfig}
        errors={errors}
      />
    </FullscreenView>
  );
};

export default CreateConfigTemplateV3;
