import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AuthType,
  ConnectionType,
  Maybe,
  useCreateConnectionMutation,
  Visibility,
} from "api/generated/graphql";
import gitlabLogo from "assets/logos/gitlab-logo.svg";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { useToast } from "components/toast/Toast";
import { Button, Checkbox, FormGroup, Input, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import { useHistory } from "react-router";
import useLogEvent from "utils/analytics";
import { getResourceUrl } from "utils/common";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";
import { logError, logWarning } from "utils/logging";
import { validateUrl } from "views/connections/create/common";
import {
  CreateConnectionComponents as CreateConnectionComponentsV2,
  CreateConnectionView as CreateConnectionViewV2,
} from "views/connections/create/CreateConnectionComponents";
import {
  CreateConnectionComponentsV3,
  CreateConnectionViewV3,
} from "views/connections/create/CreateConnectionComponentsV3";
import { UploadCertButton } from "views/connections/create/UploadCertButton";
import VisibilitySelector from "views/visibility/VisibilitySelector";

const CreateGitLab = () => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  return hasV3 ? (
    <CreateGitLabForm />
  ) : (
    <div className={sprinkles({ display: "flex", width: "100%" })}>
      <CreateGitLabForm />
    </div>
  );
};

const CreateGitLabForm = () => {
  const history = useHistory();
  const hasAppLevelVisibility = useFeatureFlag(FeatureFlag.AppLevelVisibility);
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const logEvent = useLogEvent();

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const { displaySuccessToast } = useToast();

  const [name, setName] = useState("");
  const [adminOwnerId, setAdminOwnerId] = useState<string>("");
  const [description, setDescription] = useState("");
  const [visibility, setVisibility] = useState<Visibility>(Visibility.Global);
  const [visibilityGroupIds, setVisibilityGroupIds] = useState<
    string[] | undefined
  >([]);
  const [isSelfHosted, setIsSelfHosted] = useState<boolean>(false);
  const [hostName, setHostName] = useState<string | undefined>(undefined);
  const [appID, setAppID] = useState("");
  const [appSecret, setAppSecret] = useState("");
  const [tlsMode, setTlsMode] = useState(true);
  const [tlsCaCertContent, setTLSCaCertContent] = useState<Maybe<string>>(null);

  const [createConnectionMutation, { loading }] = useCreateConnectionMutation({
    refetchQueries: ["AppsListColumn", "Connections"],
  });

  const fieldUnset =
    name === "" ||
    appID === "" ||
    appSecret === "" ||
    description === "" ||
    adminOwnerId === "" ||
    (isSelfHosted && hostName === "");

  const onSubmit = async () => {
    if (isSelfHosted) {
      const urlErrorMessage = validateUrl(hostName || "", "URL");
      if (urlErrorMessage) {
        setErrorMessage(`Error: ${urlErrorMessage}`);
        return;
      }
    }
    try {
      const { data } = await createConnectionMutation({
        variables: {
          input: {
            name: name,
            description: description,
            connectionType: ConnectionType.GitLab,
            adminOwnerId: adminOwnerId,
            visibility: visibility,
            visibilityGroupIds: visibilityGroupIds ?? [],
            importVisibility: Visibility.Global,
            metadata: {
              connectionType: ConnectionType.GitLab,
              gitLab: {
                appId: appID,
                hostName: isSelfHosted ? hostName : null,
                tlsMode: tlsMode,
                tlsCaCertContent: tlsCaCertContent,
              },
            },
            credentials: {
              authType: AuthType.GitLab,
              gitLab: {
                appSecret: appSecret,
              },
            },
          },
        },
      });
      switch (data?.createConnection.__typename) {
        case "CreateConnectionResult":
          history.replace(
            getResourceUrl(
              EntityTypeDeprecated.Connection,
              data.createConnection.connection.id
            )
          );
          displaySuccessToast(`Success: GitLab app created`);
          logEvent({
            name: "apps_create_click",
            properties: {
              connectionType: ConnectionType.GitLab,
            },
          });

          break;
        case "ConnectionExistsError":
        case "UserFacingError":
          logWarning(new Error(data.createConnection.message));
          setErrorMessage(data.createConnection.message);
          break;
        default:
          logError(new Error(`app creation failed`));
          setErrorMessage(`Error: app creation failed`);
      }
    } catch (error) {
      logError(error, "app creation failed");
      setErrorMessage(
        getModifiedErrorMessage("Error: app creation failed", error)
      );
    }
  };

  const CreateConnectionComponents = hasV3
    ? CreateConnectionComponentsV3
    : CreateConnectionComponentsV2;
  const CreateConnectionView = hasV3
    ? CreateConnectionViewV3
    : CreateConnectionViewV2;

  return (
    <CreateConnectionView
      logo={gitlabLogo}
      title={"Add your GitLab OAuth App"}
      onSubmit={onSubmit}
      submitDisabled={fieldUnset}
      submitLoading={loading}
    >
      <>
        <CreateConnectionComponents
          title={"Step 1"}
          subtitle={"Create a GitLab Group or Admin OAuth App"}
        >
          <>
            <p>
              {`Opal requires an OAuth app in your GitLab instance for matching GitLab
                accounts with Opal user accounts.`}
            </p>
            <br />
            <p>
              {`To learn more about setting up an OAuth app,`}{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={
                  "https://docs.opal.dev/docs/gitlab#step-2---create-a-gitlab-oauth-app"
                }
              >
                check out our official documentation here
              </a>
              .
            </p>
            <br />
            <p>
              {`During the OAuth app creation process, for "Application Name", you can enter "Opal" or any other name you prefer.
                    For "Redirect URI", enter the domain name for your Opal instance followed by "/callback/gitlab" (e.g., https://app.opal.dev/callback/gitlab), and on a separate
                    line again your Opal instance followed by "/callback/gitlab-connection" (e.g.  https://app.opal.dev/callback/gitlab-connection).
                    For the remaining options, you should set the app as Trusted, Confidential and with the following scopes: "api", "profile", and "email".`}
              <br />
              <br />
              {`After your app is created, record the Application ID and Secret.
                These will be input into the "App ID" and "App Secret" fields in Step 2.`}
            </p>
            <p>
              {`Once your app has been created, you'll need to create the sync token through OAuth. Click on the Setup tab, then on "Connect OAuth Admin Token"`}
            </p>
          </>
        </CreateConnectionComponents>
        <CreateConnectionComponents
          title={"Step 2"}
          subtitle={"Fill in details about your GitLab organization"}
          isLast={isSelfHosted}
        >
          <div>
            <FormGroup label="App name:">
              <Input
                onChange={setName}
                placeholder="Identifiable name of the GitLab connection."
                value={name}
              />
            </FormGroup>
            <FormGroup label="App admin:">
              <OwnerDropdown
                selectedOwnerId={adminOwnerId}
                onSelectOwner={(owner) => setAdminOwnerId(owner?.id || "")}
                placeholder="Select an owner to own this app."
              />
            </FormGroup>
            <FormGroup label="Description:">
              <Input
                onChange={setDescription}
                placeholder="A brief description of the account to further inform people requesting access to it."
                value={description}
              />
            </FormGroup>
            {hasAppLevelVisibility && (
              <FormGroup label="Visibility:">
                <VisibilitySelector
                  visibility={visibility}
                  onChangeVisibility={(vis) => {
                    if (vis == Visibility.Global) {
                      setVisibility(Visibility.Global);
                      setVisibilityGroupIds([]);
                    } else {
                      setVisibility(Visibility.Team);
                    }
                  }}
                  visibilityGroups={visibilityGroupIds ?? []}
                  onChangeVisibilityGroups={setVisibilityGroupIds}
                />
              </FormGroup>
            )}
            <FormGroup label="Custom domain:">
              <Switch
                label="Custom domain for your GitLab instance (eg. https://gitlab.my-org.com)"
                checked={isSelfHosted}
                onChange={(isHosted) => {
                  setIsSelfHosted(isHosted);
                  setHostName(undefined);
                }}
              />
            </FormGroup>
            {isSelfHosted && (
              <>
                <FormGroup label="Hostname:">
                  <Input
                    value={hostName}
                    onChange={setHostName}
                    placeholder="Custom hostname for your GitLab instance."
                  />
                </FormGroup>
                <FormGroup
                  label="TLS Mode"
                  infoTooltip={`If enabled, Opal will use TLS to connect to your
                          GitLab instance. Optionally, if you use self-signed
                          certificates, you may manually upload your
                          CA certificate. When disabled, Opal will use the protocol specified
                          in the hostname (http or https), without verifying
                          the certificate.`}
                >
                  <div className={sprinkles({ display: "flex", gap: "md" })}>
                    <Checkbox
                      size="sm"
                      checked={tlsMode}
                      onChange={() => setTlsMode(!tlsMode)}
                      label="Enabled"
                    />
                    {tlsMode && (
                      <UploadCertButton
                        buttonTitle={"Upload TLS CA cert"}
                        uploadLabelId={"upload-tls-cert-ca-input"}
                        setCertContent={setTLSCaCertContent}
                        setErrorMessage={setErrorMessage}
                      />
                    )}
                  </div>
                </FormGroup>
              </>
            )}
            <FormGroup label="App ID:">
              <Input
                onChange={setAppID}
                placeholder="The app ID of the OAuth app created in Step 1."
                value={appID}
              />
            </FormGroup>
            <FormGroup label="App secret:">
              <Input
                onChange={setAppSecret}
                placeholder="The app secret of the OAuth app created in Step 1."
                value={appSecret}
                type="password"
              />
            </FormGroup>
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
            {!hasV3 && (
              <div
                className={sprinkles({
                  display: "flex",
                  justifyContent: "flex-end",
                })}
              >
                <Button
                  type="primary"
                  disabled={fieldUnset}
                  label={"Create"}
                  loading={loading}
                  onClick={onSubmit}
                />
              </div>
            )}
          </div>
        </CreateConnectionComponents>
        {!isSelfHosted && (
          <CreateConnectionComponents
            title={"Step 3"}
            subtitle={"Link GitLab identities to Opal accounts"}
            isLast
          >
            <>
              <p>
                {`Opal's GitLab integration requires an extra step of linking each GitLab user identity to an Opal account.
              To learn more,`}{" "}
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href={
                    "https://docs.opal.dev/docs/gitlab#step-4---link-gitlab-identities-to-opal-accounts"
                  }
                >
                  check out our official documentation here
                </a>
                .
              </p>
            </>
          </CreateConnectionComponents>
        )}
      </>
    </CreateConnectionView>
  );
};

export default CreateGitLab;
