import {
  ConnectionType,
  EntityType,
  GroupType,
  ResourceType,
} from "api/generated/graphql";
import { getEntityIcon } from "components/dropdown/EntityTypeDropdown";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { Label, Select } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useAllEntitiesQuery } from "utils/hooks";
import { logError } from "utils/logging";
import { formatResourceBreadcrumb } from "utils/resources";

interface EntityDropdownProps {
  entityFilter?: (Resource | Group)[];
  setEntityFilter: (entities?: (Resource | Group)[] | undefined) => void;
  entityTypes?: (ResourceType | GroupType)[];
}

export type Resource = {
  id: string;
  name: string;
  entityType: EntityType;
  resourceType: ResourceType;
  ancestorPathToResource?: string;
  connectionType?: string;
  connectionName?: string;
  iconUrl?: string;
};

export type Group = {
  id: String;
  name: String;
  entityType: EntityType;
  groupType: GroupType;
  connectionName?: string;
};

function isResource(entity: Resource | Group): entity is Resource {
  return (entity as Resource).resourceType !== undefined;
}

interface EntityDropdownItemProps {
  entity: Resource | Group;
}

const EntityDropdownItem: React.FC<EntityDropdownItemProps> = ({ entity }) => {
  return (
    <div>
      {isResource(entity) ? (
        <>
          {entity.name} <span style={{ color: "grey" }}>(Resource)</span>
          <div
            className={sprinkles({
              color: "gray400",
            })}
            style={{ marginTop: "4px" }}
          >
            {formatResourceBreadcrumb(
              entity.ancestorPathToResource,
              60,
              entity.connectionName
            ) + entity.name}
          </div>
        </>
      ) : (
        <>
          {entity.name} <span style={{ color: "grey" }}>(Group)</span>
          <div
            className={sprinkles({
              color: "gray400",
            })}
            style={{ marginTop: "4px" }}
          >
            {formatResourceBreadcrumb(undefined, 60, entity.connectionName) +
              entity.name}
          </div>
        </>
      )}
    </div>
  );
};

const EntityDropdown = (props: EntityDropdownProps) => {
  let resources: Resource[] = [];
  let groups: Group[] = [];

  const { data, error } = useAllEntitiesQuery({});
  if (error) {
    logError(error);
  }
  data?.resources.resources.map((resource) => {
    resources.push({
      id: resource.id,
      name: resource.name,
      resourceType: resource.resourceType,
      entityType: EntityType.Resource,
      ancestorPathToResource: resource.ancestorPathToResource ?? undefined,
      connectionType: resource.connection?.connectionType,
      connectionName: resource.connection?.name,
      iconUrl: resource.iconUrl ?? undefined,
    });
  });

  data?.groups.groups.map((group) => {
    groups.push({
      id: group.id,
      name: group.name,
      groupType: group.groupType,
      entityType: EntityType.Group,
      connectionName: group.connection?.name,
    });
  });
  const handleSetEntityFilter = (
    newEntityTypeFilter?: (Resource | Group)[] | undefined
  ): void => {
    if (newEntityTypeFilter?.length === 0) {
      props.setEntityFilter(undefined);
    } else {
      props.setEntityFilter(newEntityTypeFilter);
    }
  };

  const renderEntityInputValue = (value?: (Resource | Group)[]) => {
    if (!value || value?.length === 0) {
      return "Filter by Entity";
    } else if (value?.length === 1) {
      return value[0].name.toString();
    } else {
      return (
        value[0].name.toString() +
        (value?.length > 1 ? ` + ${String(value.length - 1)} more` : "")
      );
    }
  };

  const filteredResources = resources.filter((resource) => {
    const isTypeIncluded = props.entityTypes
      ? props.entityTypes.includes(resource.resourceType)
      : true;

    return (
      isTypeIncluded &&
      (!props.entityFilter ||
        !props.entityFilter.some(
          (selectedEntity) => selectedEntity.id === resource.id
        ))
    );
  });

  const filteredGroups = groups.filter((group) => {
    const isTypeIncluded = props.entityTypes
      ? props.entityTypes.includes(group.groupType)
      : true;

    return (
      isTypeIncluded &&
      (!props.entityFilter ||
        !props.entityFilter.some(
          (selectedEntity) => selectedEntity.id === group.id
        ))
    );
  });

  return (
    <Select<Resource | Group>
      size="sm"
      multiple
      value={props.entityFilter}
      options={[...filteredResources, ...filteredGroups]}
      getOptionLabel={(option) => option.name.toString()}
      renderInputValue={renderEntityInputValue}
      placeholder={renderEntityInputValue(props.entityFilter)}
      highlightWhenSelected={true}
      renderOptionLabel={(option) => <EntityDropdownItem entity={option} />}
      getOptionSelected={(option, value) => {
        return option.id == value.id;
      }}
      onChange={handleSetEntityFilter}
      placeholderIcon={{ type: "name", icon: "dots-grid" }}
      getIcon={(entity) => {
        if (isResource(entity)) {
          if (entity.resourceType == ResourceType.OktaApp) {
            return {
              type: "src",
              icon: entity.iconUrl,
            };
          } else if (entity.connectionType) {
            return {
              type: "src",
              icon: getConnectionTypeInfo(
                entity.connectionType as ConnectionType
              )?.icon,
            };
          } else {
            return {
              type: "src",
              icon: getEntityIcon(entity.resourceType),
            };
          }
        } else {
          return {
            type: "src",
            icon: getEntityIcon(entity.groupType),
          };
        }
      }}
      listboxFooter={{
        footer: (
          <div
            className={sprinkles({
              fontFamily: "body",
              padding: "md",
            })}
          >
            {props.entityFilter && props.entityFilter.length > 0 ? (
              <Label
                label={`Clear selection (${props.entityFilter.length})`}
                color="blue600V3"
                onClick={() => props.setEntityFilter([])}
              />
            ) : (
              <Label label="No items selected" color="gray700" />
            )}
          </div>
        ),
        sticky: true,
      }}
    />
  );
};

export default EntityDropdown;
