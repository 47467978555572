import * as React from "react";

import * as styles from "./Badge.css";

type BadgeColor = "default" | "warning" | "error" | "info" | "white";

interface Props {
  color?: BadgeColor;
  outline?: boolean;
  count: number;
  size?: "xs" | "sm" | "md";
  maxCount?: number;
}

const Badge: React.FC<Props> = ({
  color = "default",
  size = "md",
  count,
  outline,
  maxCount,
}: Props) => {
  let displayedCount: string = String(count);
  if (maxCount && count > maxCount) {
    displayedCount = `${maxCount}+`;
  }
  return (
    <span className={styles.badge({ color, outline, size })}>
      {displayedCount}
    </span>
  );
};

export default Badge;
