import {
  AddUserTagInput,
  EntityType,
  SortDirection,
  TagFragment,
  useAddUserTagsMutation,
  UserPreviewSmallFragment,
  UsersSortByField,
  useTagQuery,
  useUsersQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import FullscreenViewTitle from "components/fullscreen_modals/FullscreenViewTitle";
import { ResourceLabel } from "components/label/Label";
import FullscreenView, {
  FullscreenSkeleton,
} from "components/layout/FullscreenView";
import { useToast } from "components/toast/Toast";
import { Banner, ButtonV3, Divider, Icon, Input } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { useParams } from "react-router";
import { useDebouncedValue } from "utils/hooks";
import { logError, logWarning } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import {
  ForbiddenPage,
  NotFoundPage,
  UnexpectedErrorPage,
} from "views/error/ErrorCodePage";
import { getUserAvatarIcon } from "views/users/utils";

import * as styles from "./TagAddUsersView.css";

type SortValue = {
  field: UsersSortByField;
  direction: SortDirection;
};

function isSortableField(str: string): str is UsersSortByField {
  return Object.values<string>(UsersSortByField).includes(str);
}

interface UserItemRow {
  id: string;
  [UsersSortByField.FirstName]: string;
  [UsersSortByField.Email]: string;
  item: UserPreviewSmallFragment;
}
const columns: Header<UserItemRow>[] = [
  {
    id: UsersSortByField.FirstName,
    label: "Name",
    sortable: true,
    customCellRenderer: (row) => {
      let userFullname = row.item?.fullName;
      let icon = row.item ? getUserAvatarIcon(row.item) : null;
      if (!userFullname) {
        return <></>;
      }
      return (
        <div className={styles.nameCell}>
          {icon && <Icon data={icon} />}
          <ResourceLabel
            text={userFullname}
            bold={true}
            pointerCursor={true}
            maxChars="auto"
          />
        </div>
      );
    },
    width: 230,
  },
  {
    id: UsersSortByField.Email,
    label: "Email",
    sortable: true,
    customCellRenderer: (row) => {
      let userEmail = row.item.email;
      if (!userEmail) {
        return <></>;
      }
      return (
        <ResourceLabel
          text={userEmail}
          bold={false}
          pointerCursor={true}
          maxChars="auto"
        />
      );
    },
  },
];

const TagAddUsersView = () => {
  const transitionBack = useTransitionBack();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const debouncedSearchQuery = useDebouncedValue(searchQuery);
  const { tagId } = useParams<Record<string, string>>();
  const [sortBy, setSortBy] = useState<SortValue | undefined>({
    field: UsersSortByField.FirstName,
    direction: SortDirection.Asc,
  });
  const [usersToAddByUserId, setUsersToAddByUserId] = useState<
    Record<string, UserPreviewSmallFragment>
  >({});
  const { authState } = useContext(AuthContext);

  const [addUsersErrorMessage, setAddUsersErrorMessage] = useState("");
  const [addUserTags, { loading: addUsersLoading }] = useAddUserTagsMutation();
  const { displaySuccessToast } = useToast();

  // Get Tag data
  const { data: tagData, loading: tagLoading, error: tagError } = useTagQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        id: tagId ?? "",
      },
    },
    skip: tagId == null,
  });

  let tag: TagFragment | null = null;
  let tagNotFound = false;

  if (tagData) {
    switch (tagData.tag.__typename) {
      case "TagResult":
        tag = tagData.tag.tag;
        break;
      case "TagNotFoundError":
        tagNotFound = true;
        break;
      default:
        logError(new Error(`failed to list tag`));
    }
  } else if (tagError) {
    logError(tagError, `failed to list tags`);
  }

  const {
    data: usersData,
    previousData: previousUsersData,
    error: usersError,
    loading: usersLoading,
    fetchMore,
  } = useUsersQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        maxNumEntries: 100,
        searchQuery: debouncedSearchQuery,
        sortBy: sortBy,
      },
    },
  });
  if (usersError) {
    logError(usersError, `failed to list users`);
  }

  // Error Handling
  if (tagLoading) {
    return <FullscreenSkeleton />;
  }

  if (tagNotFound) {
    return <NotFoundPage />;
  }

  if (!tag || usersError || tagError) {
    return <UnexpectedErrorPage error={tagError} />;
  }

  if (!authState.user?.isAdmin) {
    return <ForbiddenPage />;
  }

  const cursor = usersData?.users?.cursor || undefined;
  const totalNumUsers = usersData?.users.totalNumUsers ?? 0;

  const listUsers =
    usersData?.users.users || previousUsersData?.users.users || [];

  const rows: UserItemRow[] = listUsers.map((user) => {
    return {
      id: user.id,
      [UsersSortByField.FirstName]: user.firstName || "--",
      [UsersSortByField.Email]: user.email || "--",
      item: user,
    };
  });

  const handleClose = () => {
    transitionBack(`/tags/${tagId}`);
  };

  const numUsersToAdd = Object.keys(usersToAddByUserId).length;

  const loadMoreRows = async () => {
    if (!cursor) {
      return;
    }
    await fetchMore({
      variables: {
        input: {
          searchQuery: debouncedSearchQuery,
          cursor: cursor,
          sortBy: sortBy,
          maxNumEntries: 100,
        },
      },
    });
  };

  const handleAddUsers = async () => {
    if (tag === null) {
      return;
    }
    const userTagsToAdd: AddUserTagInput[] = Object.keys(
      usersToAddByUserId
    ).map((id) => ({
      tagId: tag !== null ? tag.id : "",
      userId: id,
    }));

    try {
      const { data } = await addUserTags({
        variables: {
          input: {
            userTags: userTagsToAdd,
          },
        },
        refetchQueries: ["UserTags", "Tag"],
      });
      switch (data?.addUserTags.__typename) {
        case "AddUserTagsResult": {
          const userTags = data.addUserTags.entries
            .filter((entry) => entry.__typename === "AddUserTagsEntryResult")
            .map((entry) => entry.userTag);

          if (userTags.length !== userTagsToAdd.length) {
            setAddUsersErrorMessage(
              `Error: tag was not added to selected users successfully`
            );
          } else {
            handleClose();
            displaySuccessToast(
              `Success: tag added to ${pluralize(
                "users",
                userTagsToAdd.length,
                true
              )}`
            );
          }
          break;
        }
        case "UserNotFoundError":
        case "TagNotFoundError": {
          logWarning(new Error(data.addUserTags.message));
          setAddUsersErrorMessage(data.addUserTags.message);
          break;
        }
        default:
          logError(new Error(`failed to add tag to users`));
          setAddUsersErrorMessage("Error: failed to add tag to users");
      }
    } catch (error) {
      logError(error, `failed to add tag to users`);
      setAddUsersErrorMessage("Error: failed to add tag to users");
    }
  };

  return (
    <FullscreenView
      title={
        <FullscreenViewTitle
          entityType={EntityType.Tag}
          entityName={`${tag.key} - ${tag.value}`}
          targetEntityName="users"
          action="add"
        />
      }
      onCancel={handleClose}
      onPrimaryButtonClick={handleAddUsers}
      primaryButtonDisabled={numUsersToAdd === 0}
      primaryButtonLabel={`Add ${
        numUsersToAdd > 0 ? numUsersToAdd : ""
      } ${pluralize("user", numUsersToAdd)}`}
      primaryButtonLoading={addUsersLoading}
    >
      <FullscreenView.Content fullWidth>
        <div className={styles.contentContainer}>
          <div className={styles.headerText}>
            Select users to add to the tag:
          </div>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder="Filter by name or email"
            />
          </div>
          <Divider />
          {usersLoading ? (
            <ColumnListItemsSkeleton />
          ) : (
            <Table
              columns={columns}
              rows={rows}
              totalNumRows={totalNumUsers ?? 0}
              getRowId={(user) => user.id}
              onLoadMoreRows={loadMoreRows}
              loadingRows={usersLoading}
              checkedRowIds={new Set(Object.keys(usersToAddByUserId))}
              onCheckedRowsChange={(checkedRowIds, checked) => {
                if (checked) {
                  const newUsersToAddByUserId = {
                    ...usersToAddByUserId,
                  };
                  for (const userId of checkedRowIds) {
                    if (!(userId in usersToAddByUserId)) {
                      const user = listUsers.find((user) => user.id === userId);
                      if (user) {
                        newUsersToAddByUserId[userId] = user;
                      }
                    }
                  }
                  setUsersToAddByUserId(newUsersToAddByUserId);
                } else {
                  const newUsersToAddByUserId = {
                    ...usersToAddByUserId,
                  };
                  for (const userId of checkedRowIds) {
                    delete newUsersToAddByUserId[userId];
                  }
                  setUsersToAddByUserId(newUsersToAddByUserId);
                }
              }}
              onRowClick={(user) => {
                if (user.id in usersToAddByUserId) {
                  const newUsersToAddByUserId = {
                    ...usersToAddByUserId,
                  };
                  delete newUsersToAddByUserId[user.id];
                  setUsersToAddByUserId(newUsersToAddByUserId);
                } else {
                  const newUsersToAddByUserId = {
                    ...usersToAddByUserId,
                  };
                  newUsersToAddByUserId[user.id] = user.item;
                  setUsersToAddByUserId(newUsersToAddByUserId);
                }
              }}
              manualSortDirection={
                sortBy && {
                  sortBy: sortBy.field,
                  sortDirection: sortBy.direction,
                }
              }
              handleManualSort={(sortBy, sortDirection) => {
                if (!sortDirection) {
                  setSortBy(undefined);
                  return;
                }
                if (!isSortableField(sortBy)) {
                  return;
                }
                const direction: SortDirection =
                  sortDirection === "DESC"
                    ? SortDirection.Desc
                    : SortDirection.Asc;
                setSortBy({
                  field: sortBy,
                  direction,
                });
              }}
            />
          )}
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {addUsersErrorMessage && (
          <Banner
            message={addUsersErrorMessage}
            type="error"
            marginBottom="lg"
          />
        )}
        <div
          className={sprinkles({
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "lg",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textLg",
              fontWeight: "medium",
              marginBottom: "lg",
            })}
          >
            Adding {numUsersToAdd} {pluralize("User", numUsersToAdd)}
          </div>

          {numUsersToAdd > 0 && (
            <ButtonV3
              leftIconName="x"
              label="Clear all"
              size="xs"
              type="dangerBorderless"
              onClick={() => setUsersToAddByUserId({})}
            />
          )}
        </div>
        {Object.keys(usersToAddByUserId).map((userId) => {
          const user = usersToAddByUserId[userId];

          if (!user) {
            return null;
          }

          return (
            <div key={userId} className={styles.userCard}>
              <div
                className={sprinkles({
                  display: "flex",
                  alignItems: "flex-start",
                  gap: "sm",
                })}
              >
                <div className={sprinkles({ flexShrink: 0 })}>
                  <Icon data={getUserAvatarIcon(user)} />
                </div>
                <div className={styles.userInfoSection}>
                  <div className={styles.userCardHeader}>{user.fullName}</div>
                  <div className={styles.userCardSubtitle}>{user.email}</div>
                </div>
                <div className={sprinkles({ flexShrink: 0 })}>
                  <Icon
                    name="trash"
                    color="red600V3"
                    onClick={() => {
                      const newUsersToAddByUserId = {
                        ...usersToAddByUserId,
                      };
                      delete newUsersToAddByUserId[user.id];
                      setUsersToAddByUserId(newUsersToAddByUserId);
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};

export default TagAddUsersView;
