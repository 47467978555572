import {
  EntityType,
  useConfigurationTemplatesViewQuery,
  Visibility,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import { useContext } from "react";
import { useHistory } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { useTransitionTo } from "utils/router/hooks";

interface ConfigurationTemplateRow {
  id: string;
  name: string;
  adminOwner: string;
  visibility: string;
  requireMfaToApprove: boolean;
  requireMfaToConnect: boolean;
}

const CONFIGURATION_TEMPLATE_COLUMNS: Header<ConfigurationTemplateRow>[] = [
  {
    id: "name",
    label: "Name",
    sortable: true,
  },
  {
    id: "adminOwner",
    label: "Admin",
    sortable: false,
  },
  {
    id: "visibility",
    label: "Visibility",
    sortable: false,
    customCellRenderer: (row) => {
      return (
        <div>{row.visibility === Visibility.Global ? "Global" : "Limited"}</div>
      );
    },
  },
  {
    id: "requireMfaToApprove",
    label: "Require MFA to approve requests",
    sortable: false,
    customCellRenderer: (row) => {
      return <div>{row.requireMfaToApprove ? "Required" : "Not Required"}</div>;
    },
  },
  {
    id: "requireMfaToConnect",
    label: "Require MFA to connect",
    sortable: false,
    customCellRenderer: (row) => {
      return <div>{row.requireMfaToConnect ? "Required" : "Not Required"}</div>;
    },
  },
];

const ConfigurationTemplatesView = () => {
  const history = useHistory();
  const transitionTo = useTransitionTo();
  const { authState } = useContext(AuthContext);
  const isAdmin = authState.user?.isAdmin;

  const { data, loading } = useConfigurationTemplatesViewQuery({
    fetchPolicy: "cache-and-network",
  });

  const templates = data?.configurationTemplates.configurationTemplates ?? [];

  const rows: ConfigurationTemplateRow[] = templates.map((template) => {
    return {
      id: template.id,
      name: template.name,
      adminOwner: template.adminOwner.name,
      visibility: template.visibility,
      requireMfaToApprove: template.requireMfaToApprove,
      requireMfaToConnect: template.requireMfaToConnect,
    };
  });

  return (
    <>
      <TableHeader
        entityType={EntityType.ConfigurationTemplate}
        totalNumRows={
          data?.configurationTemplates.configurationTemplates.length ?? 0
        }
        loading={loading}
        defaultRightActions={
          isAdmin
            ? [
                {
                  label: "Configuration Template",
                  type: "main",
                  iconName: "plus",
                  onClick: () => {
                    history.push("/templates/configurations/create");
                  },
                },
              ]
            : undefined
        }
      />

      {loading && !data ? (
        <ColumnListItemsSkeleton />
      ) : (
        <Table
          rows={rows}
          totalNumRows={
            data?.configurationTemplates.configurationTemplates.length ?? 0
          }
          getRowId={(ru) => ru.id}
          columns={CONFIGURATION_TEMPLATE_COLUMNS}
          onRowClick={(row, event) => {
            transitionTo(
              {
                pathname: getResourceUrlNew({
                  entityId: row.id,
                  entityType: EntityType.ConfigurationTemplate,
                }),
              },
              event
            );
          }}
        />
      )}
    </>
  );
};

export default ConfigurationTemplatesView;
