import {
  Maybe,
  RequestFragment,
  RequestStatus,
  useRequestQuery,
} from "api/generated/graphql";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader from "components/column/ColumnHeader";
import { Divider } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useEffect } from "react";
import { logError, logWarning } from "utils/logging";
import { useReadUINotification } from "utils/notifications";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ColumnContentSkeleton from "views/loading/ColumnContentSkeleton";

import RequestApproveButton from "./actions/RequestApproveButton";
import RequestCancelButton from "./actions/RequestCancelButton";
import RequestDenyButton from "./actions/RequestDenyButton";
import RequestEscalateManagerButton from "./actions/RequestEscalateManagerButton";
import RequestRemindReviewersButton from "./actions/RequestRemindReviewersButton";
import * as styles from "./RequestDetailView.css";
import RequestOverview from "./RequestOverview";
import { RequestReviewers } from "./RequestReviewers";
import { getRequestedItemsSummaryText } from "./Requests";
import { getRequestedItemsIcon } from "./utils";

interface Props {
  requestId: string;
}

const RequestDetailView = (props: Props) => {
  const {
    data,
    error,
    loading,
    refetch: refetchRequest,
    stopPolling,
    startPolling,
  } = useRequestQuery({
    variables: {
      input: {
        id: props.requestId,
      },
    },
  });

  useReadUINotification(props.requestId);

  let request: Maybe<RequestFragment> = null;
  if (data) {
    switch (data.request.__typename) {
      case "RequestResult":
        request = data.request.request;
        break;
      case "RequestNotFoundError":
        logWarning(new Error("error: request not found"));
        break;
      default:
        logError(new Error("failed to get request"));
    }
  } else if (error) {
    logError(error, `failed to get request`);
  }

  const shouldPoll = request?.status === RequestStatus.Pending;
  useEffect(() => {
    if (shouldPoll) {
      startPolling(5000);
    } else {
      stopPolling();
    }
    return () => {
      stopPolling();
    };
  }, [startPolling, stopPolling, shouldPoll]);

  if (loading) {
    return (
      <Column isContent>
        <ColumnContentSkeleton />
      </Column>
    );
  }

  if (error || !request) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  if (!request) {
    return null;
  }

  const requestHeaderButtons = (
    <div className={sprinkles({ display: "flex", gap: "sm" })}>
      <RequestDenyButton request={request} />
      <RequestApproveButton request={request} />
      <RequestCancelButton request={request} />
    </div>
  );

  const reviewersHeader = (
    <div className={styles.reviewersHeader}>
      <div className={styles.title}>Reviewers</div>
      <div className={sprinkles({ display: "flex", gap: "sm" })}>
        <RequestRemindReviewersButton request={request} />
        <RequestEscalateManagerButton request={request} />
      </div>
    </div>
  );

  return (
    <Column isContent>
      <ColumnHeader
        icon={getRequestedItemsIcon(request)}
        title={`Request for ${getRequestedItemsSummaryText(request)}`}
        subtitle={
          request.requester ? `from ${request.requester?.fullName}` : undefined
        }
        rightActions={requestHeaderButtons}
      />
      <Divider />
      <ColumnContent>
        <RequestOverview request={request} refetchRequest={refetchRequest} />
        {request.status === RequestStatus.Pending ? (
          <>
            <Divider />
            {reviewersHeader}
            <RequestReviewers request={request} />
          </>
        ) : null}
      </ColumnContent>
    </Column>
  );
};

export default RequestDetailView;
