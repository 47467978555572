import {
  ConnectionType,
  EntityType,
  GroupType,
  ResourceType,
} from "api/generated/graphql";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import { getResourceTypeInfo } from "components/label/ResourceTypeLabel";
import { Label } from "components/ui";
import { IconGroup } from "components/ui/icon_group/IconGroup";
import pluralize from "pluralize";

import * as styles from "./ItemUsageDetails.css";

type ItemUseageDetails = {
  entityType:
    | EntityType.Connection
    | EntityType.Group
    | EntityType.Resource
    | EntityType.Bundle;
  teamUsageCount?: number | null;
  titleUsageCount?: number | null;
  ownerIcons: PropsFor<typeof IconGroup>["items"];
} & (
  | {
      entityType: EntityType.Connection;
      connectionType: ConnectionType;
    }
  | {
      entityType: EntityType.Group;
      groupType: GroupType;
    }
  | {
      entityType: EntityType.Resource;
      resourceType: ResourceType;
    }
  | {
      entityType: EntityType.Bundle;
    }
);

export const ItemUsageDetails = (props: ItemUseageDetails) => {
  // ToDo: Use resource type and name instead of connection type
  const renderResourceType = () => {
    switch (props.entityType) {
      case EntityType.Bundle: {
        return (
          <div className={styles.useageDetailRow}>
            <Label
              icon={{
                type: "name",
                icon: "bundle",
              }}
              label="Bundle"
              iconSize="xs"
              inline
            />
          </div>
        );
      }
      case EntityType.Connection: {
        return (
          <div className={styles.useageDetailRow}>
            <Label
              icon={{
                type: "name",
                icon: "apps",
              }}
              label="App"
              iconSize="xs"
              inline
            />
          </div>
        );
      }
      case EntityType.Group: {
        const groupInfo = getGroupTypeInfo(props.groupType);
        return (
          <div className={styles.useageDetailRow}>
            <Label
              icon={{
                type: "name",
                icon: groupInfo?.iconName ?? "users",
              }}
              label={groupInfo?.name ?? props.groupType}
              iconSize="xs"
              inline
            />
          </div>
        );
      }
      case EntityType.Resource: {
        const resourceInfo = getResourceTypeInfo(props.resourceType);
        return (
          <div className={styles.useageDetailRow}>
            <Label
              label={resourceInfo?.name ?? props.resourceType}
              icon={{
                type: "name",
                icon: resourceInfo?.iconName,
              }}
              iconSize="xs"
              inline
            />
          </div>
        );
      }
      default:
        return null;
    }
  };

  const renderPeopleCount = (count: number) => {
    return count == 1 ? "1 person" : `${count} people`;
  };

  return (
    <div>
      <div className={styles.useageContainer}>
        {props.entityType && renderResourceType()}
        {props.teamUsageCount != null && (
          <div className={styles.useageDetailRow}>
            <Label
              icon={{ type: "name", icon: "users" }}
              label={`${renderPeopleCount(
                props.teamUsageCount || 0
              )} on your team ${
                props.teamUsageCount === 1 ? "uses" : "use"
              } this`}
              iconSize="xs"
              inline
            />
          </div>
        )}
        {props.teamUsageCount != null && (
          <div className={styles.useageDetailRow}>
            <Label
              icon={{ type: "name", icon: "users" }}
              label={`${renderPeopleCount(
                props.titleUsageCount || 0
              )} with your title ${
                props.titleUsageCount === 1 ? "uses" : "use"
              } this`}
              iconSize="xs"
              inline
            />
          </div>
        )}
        <div className={styles.useageDetailRow}>
          <IconGroup items={props.ownerIcons} iconSize="md" />
          <div>{pluralize("Owner", props.ownerIcons.length)}</div>
        </div>
      </div>
    </div>
  );
};
