import { getModifiedErrorMessage } from "api/ApiContext";
import { OwnerFragment, useUpdateOwnerMutation } from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Input, Modal } from "components/ui";
import React, { useState } from "react";
import { logError } from "utils/logging";

type OwnerRenameModalProps = {
  owner: OwnerFragment;
  isOpen: boolean;
  onClose: () => void;
};

const OwnerRenameModal = (props: OwnerRenameModalProps) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [newName, setNewName] = useState(props.owner.name);
  const { displaySuccessToast } = useToast();
  const [updateOwnerMutation, { loading }] = useUpdateOwnerMutation();

  const handleReset = () => {
    props.onClose();
    setNewName("");
    setErrorMessage("");
  };

  const handleRename = async () => {
    try {
      const { data } = await updateOwnerMutation({
        variables: {
          input: {
            ownerId: props.owner.id,
            name: newName,
          },
        },
        refetchQueries: ["OwnersList", "Owner"],
      });
      switch (data?.updateOwner.__typename) {
        case "UpdateOwnerResult":
          displaySuccessToast("Success: owner updated");
          handleReset();
          break;
        default:
          logError(new Error(`failed to update owner`));
          setErrorMessage(`Error: failed to update owner`);
      }
    } catch (error) {
      logError(error, "failed to update owner");
      setErrorMessage(
        getModifiedErrorMessage("Error: failed to update owner", error)
      );
    }
  };

  return (
    <Modal
      title={`Rename ${props.owner.name}`}
      isOpen={props.isOpen}
      onClose={handleReset}
    >
      <Modal.Body>
        {errorMessage ? (
          <ModalErrorMessage errorMessage={errorMessage} />
        ) : null}
        <Input value={newName} onChange={setNewName} />
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Rename"
        onPrimaryButtonClick={handleRename}
        primaryButtonDisabled={!newName || newName === props.owner.name}
        primaryButtonLoading={loading}
      />
    </Modal>
  );
};

export default OwnerRenameModal;
