import { truncate as lodashTruncate } from "lodash";
import { ReactNode } from "react";

export const truncate = (text: string, size: number) => {
  return lodashTruncate(text, {
    length: size,
  });
};

/**
 * Formats a list of items in human readable format.
 * Examples:
 * - ['dogs', 'cats', 'snakes', 'snails'] => 'dogs, cats, snakes and snails'
 * - ['dogs', 'cats', 'snakes'] => 'dogs, cats and snakes'
 * - ['dogs', 'cats'] => 'dogs and cats'
 * - ['dogs'] => 'dogs'
 * - [] => ''
 */
export const humanizeList = (items: string[]): string => {
  if (items.length === 0) return "";
  if (items.length === 1) return items[0];
  return `${items.slice(0, -1).join(", ")} and ${items[items.length - 1]}`;
};

/**
 * @see humanizeList
 */
export const humanizeListElements = (
  items: ReactNode[],
  conjunction: string
): ReactNode[] => {
  if (items.length === 0) return [];
  if (items.length === 1) return items;
  const result = [];
  for (let i = 0; i < items.length; i++) {
    if (i > 0) {
      if (i === items.length - 1) {
        result.push((i === 1 ? " " : ", ") + conjunction + " ");
      } else {
        result.push(", ");
      }
    }
    result.push(items[i]);
  }
  return result;
};
