import { MouseEventHandler } from "react";
import { Link } from "react-router-dom-v5-compat";

type Props = PropsFor<typeof Link>;
const OpalLink = (props: Props) => {
  // We have a lot of links inside of tables, and a lot of tables
  // with rowClick behavior so stoppingPropagation at the component
  // level is probably okay
  const onClick: MouseEventHandler<HTMLSpanElement> = (e) => {
    e.stopPropagation();
  };

  return (
    <span onClick={onClick}>
      <Link {...props} />
    </span>
  );
};

export default OpalLink;
