import { TagFilter } from "api/generated/graphql";

export interface TagKeyValue {
  key: string;
  value?: string | null;
}

export const tagDisplayName = (tag: TagKeyValue): string => {
  let displayName = tag.key;
  if (tag.value) {
    displayName += `:${tag.value}`;
  }
  return displayName;
};

export const tagFilterFromString = (tagString: string): TagFilter => {
  const parts = tagString.split(":");
  const tagFilter: TagFilter = { key: parts[0] };
  if (parts.length === 2) {
    tagFilter.value = parts[1];
  }
  return tagFilter;
};

export const tagFilterFromTag = (tag: TagKeyValue): TagFilter => {
  return {
    key: tag.key,
    value: tag.value,
  };
};
