export type EntityWithAdminOwner = {
  adminOwner?: {
    ownerUsers: Array<{
      user?: {
        id: string;
      } | null;
    }>;
  } | null;
};

export function userIsAdminOfEntities(
  userId: string,
  entities: EntityWithAdminOwner[]
): boolean {
  if (!entities || entities.length === 0) {
    return false;
  }
  for (let entity of entities) {
    if (
      !entity.adminOwner?.ownerUsers
        .map((ownerUser) => ownerUser.user?.id)
        .includes(userId)
    ) {
      return false;
    }
  }
  return true;
}
