import * as React from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import * as styles from "./ColumnContent.css";

const ColumnContent = React.forwardRef<
  HTMLDivElement,
  React.PropsWithChildren<{}>
>((props, ref) => {
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);
  return (
    <div className={styles.container({ v3: hasV3Nav })} ref={ref}>
      {props.children}
    </div>
  );
});

export default ColumnContent;
