import { Maybe } from "api/generated/graphql";
import clsx from "clsx";
import AuthContext from "components/auth/AuthContext";
import { MoreInfo } from "components/more_info/MoreInfo";
import { LockTooltip } from "components/tooltips/LockTooltip";
import { Icon, Tooltip } from "components/ui";
import styles from "components/viewer/Viewer.module.scss";
import React, { ReactElement, useContext } from "react";

type ViewerOverviewProps = React.PropsWithChildren<{}>;

export const ViewerOverview = (props: ViewerOverviewProps) => {
  return <div className={styles.overviewContent}>{props.children}</div>;
};

type SectionColumnProps = React.PropsWithChildren<{
  small?: boolean;
  medium?: boolean;
  large?: boolean;
}>;

export const SectionColumn = (props: SectionColumnProps) => {
  return (
    <div
      className={clsx({
        [styles.sectionColumn]: true,
        [styles.sectionColumnSmall]: props.small,
        [styles.sectionColumnMedium]: props.medium,
        [styles.sectionColumnLarge]: props.large,
      })}
    >
      {props.children}
    </div>
  );
};

type SectionProps = React.PropsWithChildren<{
  title?: string;
  icon?: ReactElement;
  iconRight?: ReactElement;
  rightContent?: Maybe<ReactElement>;
  titleSmall?: boolean;
  style?: React.CSSProperties;
  subTitleContent?: ReactElement;
  helpTooltipText?: string;
}>;

export const Section = (props: SectionProps) => {
  return (
    <div className={styles.section} style={props.style}>
      {(props.title || props.icon || props.rightContent) && (
        <>
          <div className={styles.sectionHeader}>
            {(props.title || props.icon) && (
              <div
                className={clsx({
                  [styles.sectionTitle]: true,
                  [styles.sectionTitleNormal]: !props.titleSmall,
                  [styles.sectionTitleSmall]: props.titleSmall,
                })}
              >
                {props.icon}
                {props.title}
                {props.iconRight}
                {props.helpTooltipText ? (
                  <Tooltip tooltipText={props.helpTooltipText}>
                    <Icon name="help-circle" size="xs" />
                  </Tooltip>
                ) : null}
              </div>
            )}
            {props.rightContent}
          </div>
          {props.subTitleContent}
        </>
      )}
      {props.children}
    </div>
  );
};

type SectionRowProps = React.PropsWithChildren<{
  style?: React.CSSProperties;
}>;

export const SectionRow = (props: SectionRowProps) => {
  return (
    <div className={styles.sectionRow} style={props.style}>
      {props.children}
    </div>
  );
};

type SectionDividerProps = React.PropsWithChildren<{
  style?: React.CSSProperties;
}>;

export const SectionDivider = (props: SectionDividerProps) => {
  return <div className={styles.sectionDivider} style={props.style} />;
};

type SectionEntryProps = React.PropsWithChildren<{
  title: string;
  titleIcon?: Maybe<string>;
  tooltipText?: string | ReactElement | null;
  lockTooltipText?: string | ReactElement | null;
  adminEditor?: ReactElement | null;
  canEdit?: boolean;
}>;

export const SectionEntry = (props: SectionEntryProps) => {
  return (
    <div className={styles.sectionEntry}>
      <div className={styles.sectionEntryTitle}>
        {props.title}
        {props.titleIcon && (
          <div className={styles.sectionEntryTitleIcon}>
            <img alt={""} src={props.titleIcon} width={16} height={16} />
          </div>
        )}
        {props.tooltipText && <MoreInfo tooltipText={props.tooltipText} />}
        {props.lockTooltipText && (
          <LockTooltip tooltipText={props.lockTooltipText} />
        )}
        {props.adminEditor && (
          <AdminContent canEdit={props.canEdit}>
            {props.adminEditor}
          </AdminContent>
        )}
      </div>
      <div className={styles.sectionContent}>{props.children}</div>
    </div>
  );
};

type AdminContentProps = React.PropsWithChildren<{
  canEdit?: boolean;
}>;

export const AdminContent = (props: AdminContentProps) => {
  const { authState } = useContext(AuthContext);

  // TODO: remove hard-coded authState admin check
  if (!props.canEdit && !authState.user?.isAdmin) {
    return null;
  }

  return <div className={styles.adminContent}>{props.children}</div>;
};
