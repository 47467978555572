import {
  ResourceAccessLevelFragment,
  useResourceAccessLevelsQuery,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import ResourceIndividualRoleAutocomplete, {
  Role,
} from "components/modals/ResourceIndividualRoleModal";
import { SelectType } from "components/modals/update/SelectItemsModal";
import { Loader, Modal } from "components/ui";
import React from "react";
import { logError } from "utils/logging";

type ImportRolesModalProps = {
  title: string;
  isModalOpen: boolean;
  existingDirectRolesByEntityId: Record<string, Role[]>;
  updatedRolesByEntityId: Record<string, Role[]>;
  setUpdatedRolesByEntityId: (
    updatedRolesByEntityId: Record<string, Role[]>
  ) => void;
  onClose: () => void;
  onSubmit: () => void;
  loading?: boolean;
  errorMessage?: string;
  submitDisabled?: boolean;
  entityId: string;
};

export const ImportRolesModal = (props: ImportRolesModalProps) => {
  const { data, error, loading } = useResourceAccessLevelsQuery({
    variables: {
      input: {
        resourceId: props.entityId,
        skipCustomAccessLevels: true,
      },
    },
  });

  let roles: ResourceAccessLevelFragment[] = [];

  // fetch roles
  if (data) {
    switch (data.accessLevels.__typename) {
      case "ResourceAccessLevelsResult": {
        roles = data.accessLevels.accessLevels;
        break;
      }
      case "ResourceNotFoundError":
        logError(new Error(`Error: failed to list resource roles`));
        break;
    }
  } else if (error) {
    logError(error, `failed to list resource roles`);
  }

  return (
    <Modal
      isOpen={props.isModalOpen}
      onClose={props.onClose}
      title={props.title}
    >
      <Modal.Body>
        {loading ? (
          <Loader block />
        ) : roles.length === 0 ? (
          "No roles available to import."
        ) : (
          <ResourceIndividualRoleAutocomplete
            existingDirectRolesByEntityId={props.existingDirectRolesByEntityId}
            updatedRolesByEntityId={props.updatedRolesByEntityId}
            setUpdatedRolesByEntityId={props.setUpdatedRolesByEntityId}
            entityId={props.entityId}
            selectType={SelectType.Add}
            rolesForResource={roles}
            rolesLoading={loading}
          />
        )}
        {props.errorMessage && (
          <ModalErrorMessage errorMessage={props.errorMessage} />
        )}
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Import"
        onPrimaryButtonClick={props.onSubmit}
        primaryButtonDisabled={props.submitDisabled}
        primaryButtonLoading={props.loading}
      />
    </Modal>
  );
};

export default ImportRolesModal;
