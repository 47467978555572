import { datadogRum } from "@datadog/browser-rum";

export const initDatadogRUMTracer = (
  env: string,
  appVersion: string,
  user: {
    id: string;
    orgId: string;
    orgName: string;
    userRoles: string[];
  }
) => {
  datadogRum.init({
    applicationId: "33febc7b-782d-4c4f-a064-adebfb156b49",
    clientToken: "pub8779a6c74659b8dfb6251bb005ffdc19",
    site: "datadoghq.com",
    service: "opal-web-client",
    env,
    // Specify a version number to identify the deployed version of your application in Datadog
    version: appVersion,
    sampleRate: 100,
    sessionReplaySampleRate: 20,
    trackInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: "mask-user-input",
    excludedActivityUrls: [
      // match with regex
      /localhost/,
      "https://dev.opal.dev",
    ],
    tracingSampleRate: 100,
  });

  // Do not enable this for now because we have the equivalent in logrocket.
  // At some point we can decide to remove logrocket and use this instead.
  // datadogRum.startSessionReplayRecording();

  datadogRum.setUser({
    id: user.id,
    orgId: user.orgId,
    orgName: user.orgName,
    userRoles: user.userRoles,
  });
};
