import { Maybe } from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { Modal } from "components/ui";
import React, { useState } from "react";

type RoleCreationOwningTeamModalProps = {
  isModalOpen: boolean;
  onClose: () => void;
  loading?: boolean;
  errorMessage: Maybe<string>;
  onSubmit: (reviewerOwnerId?: string) => void;
  roleCreationReviewerOwnerId?: string;
};

export const RoleCreationOwningTeamModal = (
  props: RoleCreationOwningTeamModalProps
) => {
  const [
    roleCreationReviewerOwnerId,
    setRoleCreationReviewerOwnerId,
  ] = useState<string | undefined>(props.roleCreationReviewerOwnerId);

  return (
    <Modal
      title="Set role creation reviewer"
      isOpen={props.isModalOpen}
      onClose={props.onClose}
    >
      <Modal.Body>
        {props.errorMessage ? (
          <ModalErrorMessage errorMessage={props.errorMessage} />
        ) : null}
        <OwnerDropdown
          selectedOwnerId={roleCreationReviewerOwnerId}
          onSelectOwner={(owner) => {
            if (!owner) {
              setRoleCreationReviewerOwnerId(undefined);
              return;
            }
            setRoleCreationReviewerOwnerId(owner.id);
          }}
          clearable
        />
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Save"
        onPrimaryButtonClick={async () =>
          props.onSubmit(roleCreationReviewerOwnerId)
        }
        primaryButtonDisabled={
          props.roleCreationReviewerOwnerId === roleCreationReviewerOwnerId
        }
        primaryButtonLoading={props.loading}
      />
    </Modal>
  );
};

export default RoleCreationOwningTeamModal;
