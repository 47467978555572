import { UserDropdownPreviewFragment } from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { PaginatedUserDropdown } from "components/dropdown/PaginatedUserDropdown";
import { UserDropdown } from "components/dropdown/UserDropdown";
import * as React from "react";
import {
  EntityWithAdminOwner,
  userIsAdminOfEntities,
} from "utils/auth/entities";

interface Props {
  entities: EntityWithAdminOwner[];
  targetUser: UserDropdownPreviewFragment;
  onChange: (user: UserDropdownPreviewFragment) => void;
}
const RequestForUserDropdown: React.FC<Props> = (props) => {
  const { authState } = React.useContext(AuthContext);
  if (!authState.user?.user) {
    return null;
  }

  // If user is an Opal admin, global requester, or admin owner for all requested entities,
  // allow them to request on behalf of any user.
  if (
    authState.user?.isAdmin ||
    authState.user?.isGlobalRequester ||
    userIsAdminOfEntities(authState.user.user.id, props.entities)
  ) {
    return (
      <PaginatedUserDropdown
        value={props.targetUser}
        onChange={(newUser) => newUser && props.onChange(newUser)}
        clearable={false}
      />
    );
  }

  // Otherwise, they can only request on behalf of direct reports.
  const directReports = authState.user.user.directReports;
  return (
    <UserDropdown
      allUsers={[authState.user.user, ...directReports]}
      onChange={(newUser) => newUser && props.onChange(newUser)}
      value={props.targetUser}
      clearable={false}
    />
  );
};

export default RequestForUserDropdown;
