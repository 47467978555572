import {
  GroupBindingDetailGroupUserFragment,
  GroupBindingGroupWithUsersFragment,
  GroupBindingSuggestionDetailFragment,
  useCreateGroupBindingsMutation,
  useDismissGroupBindingSuggestionsMutation,
  useGroupBindingCompareModalQuery,
} from "api/generated/graphql";
import { getGroupTypeInfo } from "components/label/GroupTypeLabel";
import UserLabel from "components/label/item_labels/UserLabel";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Button, Modal, RadioGroup, Skeleton } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useMemo, useState } from "react";
import useLogEvent from "utils/analytics";
import { useMountEffect } from "utils/hooks";
import { logError } from "utils/logging";

import { formatGroupName } from "../common";
import * as styles from "./GroupBindingCompareModal.css";

type Props = {
  suggestionId: string;
  sourceGroupId: string;
  isOpen: boolean;
  onModalClose: () => void;
};

const GroupBindingCompareModal: React.FC<Props> = (props: Props) => {
  const {
    data,
    loading: bindingLoading,
    error,
  } = useGroupBindingCompareModalQuery({
    variables: {
      input: props.suggestionId,
    },
  });
  const [
    dismiss,
    { loading: dismissLoading },
  ] = useDismissGroupBindingSuggestionsMutation({
    refetchQueries: ["GroupBindingSuggestionsTable", "GroupBindingsCount"],
  });
  const [accept, { loading: acceptLoading }] = useCreateGroupBindingsMutation({
    refetchQueries: [
      "GroupBindingsTable",
      "GroupBindingSuggestionsTable",
      "GroupBindingsCounts",
    ],
  });
  const [sourceGroupId, setSourceGroupId] = useState(props.sourceGroupId);
  const { displaySuccessToast, displayErrorToast } = useToast();
  const [errorMessage, setErrorMessage] = useState("");
  const logEvent = useLogEvent();

  useMountEffect(() => {
    logEvent({ name: "group_binding_suggestions_compare" });
  });

  if (error) {
    logError(error, "failed to load suggestion");
    displayErrorToast("Failed to load suggestion");
    return null;
  }

  let suggestion: GroupBindingSuggestionDetailFragment | undefined = undefined;
  switch (data?.groupBindingSuggestion?.__typename) {
    case "GroupBindingSuggestion":
      suggestion = data.groupBindingSuggestion;
      break;
    case "GroupBindingSuggestionNotFoundError":
      displayErrorToast("Failed to load suggestion: not found");
      return null;
  }

  const loading = bindingLoading || dismissLoading || acceptLoading;
  let loadingContent = null;
  let groups: GroupBindingGroupWithUsersFragment[] = [];
  if (loading || !suggestion) {
    loadingContent = (
      <>
        <Skeleton width="500px" />
        <Skeleton width="500px" />
        <Skeleton width="500px" />
        <Skeleton width="200px" />
      </>
    );
  } else if (
    suggestion &&
    suggestion.primaryGroup &&
    suggestion.secondaryGroup
  ) {
    groups = [suggestion.primaryGroup!, suggestion.secondaryGroup!];
  } else {
    displayErrorToast("Failed to load suggestion: invalid data");
    return null;
  }

  const onDismiss = async () => {
    const results = await dismiss({
      variables: {
        input: {
          ids: [props.suggestionId],
        },
      },
    });
    switch (results.data?.dismissGroupBindingSuggestions.__typename) {
      case "DismissGroupBindingSuggestionsResult":
        displaySuccessToast("Successfully dismissed suggestion");
        logEvent({ name: "group_binding_suggestions_dismiss" });
        break;
      case "GroupBindingSuggestionNotFoundError":
        displayErrorToast("Failed to dismiss suggestion: not found");
        break;
    }
    props.onModalClose();
  };

  const onAccept = async () => {
    const result = await accept({
      variables: {
        input: {
          groupBindings: [
            {
              groupIds: [
                suggestion!.primaryGroup!.id,
                suggestion!.secondaryGroup!.id,
              ],
              sourceGroupId: sourceGroupId,
              suggestionId: props.suggestionId,
            },
          ],
        },
      },
    });
    const data = result.data?.createGroupBindings;
    switch (data?.__typename) {
      case "CreateGroupBindingsResult":
        displaySuccessToast("Successfully linked groups");
        logEvent({
          name: "group_bindings_create",
          properties: { fromSuggestion: true },
        });
        props.onModalClose();
        break;
      case "GroupAlreadyBelongsToBindingError":
        if (data.group?.name && data.groupBinding?.sourceGroup?.name) {
          setErrorMessage(
            `Group ${data.group.name} is already being linked to ${data.groupBinding.sourceGroup.name}`
          );
        } else {
          setErrorMessage(
            "Failed to update linked groups: one of the groups is already linked"
          );
        }
        break;
      case "GroupBindingHasNoGroupsError":
        setErrorMessage(
          "Failed to update linked groups: one of the bindings has no groups"
        );
        break;
      default:
        setErrorMessage("Failed to create linked groups");
        break;
    }
  };

  return (
    <Modal
      title="Compare groups"
      subtitle="View which users are unique to each group and which users are in both groups."
      isOpen={props.isOpen}
      onClose={() => {
        props.onModalClose();
      }}
      maxWidth={"lg"}
    >
      <Modal.Body>
        {loading || !suggestion ? (
          loadingContent
        ) : (
          <>
            <div
              className={sprinkles({
                fontWeight: "semibold",
                marginBottom: "md",
              })}
            >
              Source of truth
            </div>
            <RadioGroup
              value={groups.find((group) => group.id === sourceGroupId)}
              options={groups}
              getOptionKey={(option) => option.id}
              getOptionIcon={(option) => ({
                type: "src",
                icon: getGroupTypeInfo(option.groupType)?.icon,
              })}
              getOptionLabel={(option) => formatGroupName(option)}
              onSelectValue={(option) => setSourceGroupId(option.id)}
              inline
            />
            <div
              className={sprinkles({
                marginTop: "xl",
              })}
            >
              <CompareGroupUsersTable groups={groups} />
            </div>
            {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
          </>
        )}
      </Modal.Body>
      <Modal.Footer
        onPrimaryButtonClick={props.onModalClose}
        primaryButtonLabel="Close"
      >
        <div className={sprinkles({ display: "flex", gap: "sm" })}>
          {/* FIXME: change me to ButtonV3 once v3 is shipped */}
          <Button
            label="Dismiss suggestion"
            onClick={onDismiss}
            disabled={loading}
            borderless
          />
          <Button
            label="Link groups"
            onClick={onAccept}
            leftIconName="link"
            type="primary"
            disabled={loading}
          />
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export const CompareGroupUsersTable = ({
  groups,
}: {
  groups: GroupBindingGroupWithUsersFragment[];
}) => {
  const usersInCommon: GroupBindingDetailGroupUserFragment[] = useMemo(() => {
    if (groups.length === 0) return [];
    return groups.reduce(
      (usersInCommon, group) =>
        _.intersectionBy(
          usersInCommon,
          group.groupUsers,
          (user) => user.userId
        ),
      groups[0].groupUsers
    );
  }, [groups]);

  const uniqueUsersByGroupId = useMemo(() => {
    let usersByGroupId: Record<
      string,
      GroupBindingDetailGroupUserFragment[]
    > = {};
    if (groups.length === 0) return usersByGroupId;
    groups.forEach((group) => {
      usersByGroupId[group.id] = groups
        .filter((g) => g.id !== group.id)
        .reduce(
          (usersInGroup, otherGroup) =>
            _.differenceBy(
              usersInGroup,
              otherGroup.groupUsers,
              (user) => user.userId
            ),
          group.groupUsers
        );
    });
    return usersByGroupId;
  }, [groups]);

  const groupNamesById = _.keyBy(groups, (group) => group.id);

  return (
    <div className={styles.groupUserDiffContainer}>
      <div className={styles.groupUserListColumn}>
        <UserTable tableHeader="Users in common" users={usersInCommon} />
      </div>
      {Object.entries(uniqueUsersByGroupId).map(([groupId, users]) => {
        if (users.length === 0) return null;
        const groupName = groupNamesById[groupId].name;
        return (
          <div className={styles.groupUserListColumn}>
            <UserTable
              tableHeader={`Users in ${groupName} only`}
              users={users}
            />
          </div>
        );
      })}
    </div>
  );
};

const UserTable = (props: {
  tableHeader: string;
  users: GroupBindingDetailGroupUserFragment[];
}) => {
  const columns: Header<GroupBindingDetailGroupUserFragment>[] = [
    {
      id: "user",
      label: props.tableHeader,
      sortable: true,
      customCellRenderer(row) {
        if (!row.user) {
          return <></>;
        }
        return (
          <UserLabel name={row.user.fullName} avatar={row.user.avatarUrl} />
        );
      },
    },
  ];
  return (
    <Table
      columns={columns}
      getRowId={(row) => row.userId}
      rows={props.users}
      totalNumRows={props.users.length}
      emptyState={{
        title: "",
        subtitle: "No users in common",
      }}
    />
  );
};

export default GroupBindingCompareModal;
