import { NetworkStatus } from "@apollo/client";
import {
  EntityType,
  HrIdpStatus,
  SortDirection,
  SyncErrorFragment,
  SyncTaskFragment,
  SyncType,
  UsersSortByField,
  useSyncStatusQuery,
  useUsersTableQuery,
} from "api/generated/graphql";
import axios from "axios";
import AuthContext from "components/auth/AuthContext";
import ColumnHeaderV3 from "components/column/ColumnHeaderV3";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import SyncStatusModal from "components/label/SyncStatusModal";
import InviteTeammatesModal from "components/modals/InviteTeammatesModal";
import OpalHeaderExtraActions from "components/opal/layout/OpalHeaderExtraActions";
import OpalPage from "components/opal/layout/OpalPage";
import useSyncActionIcon, {
  getSyncLabel,
} from "components/sync/useSyncActionIcon";
import { useToast } from "components/toast/Toast";
import { Icon, Tooltip } from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import TableHeader from "components/ui/table/TableHeader";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import useLogEvent from "utils/analytics";
import { hasBasicPermissions } from "utils/auth/auth";
import { getResourceUrlNew } from "utils/common";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionTo } from "utils/router/hooks";
import UserFilterBarV3 from "views/users/UserFilterBarV3";

import * as styles from "./UsersColumnV3.css";
import { getUserAvatarIcon, useUsersFilter } from "./utils";

const CREATED_AT_COL_ID = UsersSortByField.CreatedAt;
const EMAIL_COL_ID = UsersSortByField.Email;
const NAME_COL_ID = UsersSortByField.FirstName;
const TEAM_COL_ID = UsersSortByField.Team;
const TITLE_COL_ID = UsersSortByField.Title;
const MANAGER_COL_ID = UsersSortByField.Manager;

function isSortableField(str: string): str is UsersSortByField {
  return Object.values<string>(UsersSortByField).includes(str);
}

type SortValue = {
  field: UsersSortByField;
  direction: SortDirection;
};

interface UserRow {
  id: string;
  [NAME_COL_ID]: string;
  userAvatarUrl: string;
  [EMAIL_COL_ID]: string;
  [MANAGER_COL_ID]:
    | {
        id: string;
        fullName: string;
        avatarUrl: string;
      }
    | undefined;
  [TEAM_COL_ID]: string;
  [TITLE_COL_ID]: string;
  resourceCount: number;
  [CREATED_AT_COL_ID]: string;
  idpStatus?: HrIdpStatus;
}

const USER_COLUMNS: Header<UserRow>[] = [
  {
    id: NAME_COL_ID,
    label: "Name",
    sortable: true,
    customCellRenderer: (row) => {
      return (
        <div className={sprinkles({ display: "flex", gap: "sm" })}>
          <div className={sprinkles({ flexShrink: 0 })}>
            <Icon
              size="sm"
              data={getUserAvatarIcon({ avatarUrl: row.userAvatarUrl })}
            />
          </div>
          <Link
            onClick={(e) => e.stopPropagation()}
            to={getResourceUrlNew({
              entityId: row.id,
              entityType: EntityType.User,
            })}
            className={styles.nameField}
          >
            {row[NAME_COL_ID]}
          </Link>
        </div>
      );
    },
  },
  {
    id: EMAIL_COL_ID,
    label: "Email",
    sortable: true,
    customCellRenderer: (row) => {
      return <div className={styles.emailCell}>{row[EMAIL_COL_ID]}</div>;
    },
  },
  {
    id: MANAGER_COL_ID,
    label: "Manager",
    sortable: true,
    customCellRenderer: (row) => {
      if (!row[MANAGER_COL_ID]) {
        return <></>;
      }
      const manager = row[MANAGER_COL_ID];
      return (
        <div className={sprinkles({ display: "flex", gap: "sm" })}>
          <div className={sprinkles({ flexShrink: 0 })}>
            <Icon
              size="sm"
              data={getUserAvatarIcon({
                avatarUrl: manager.avatarUrl,
              })}
            />
          </div>
          <Link
            onClick={(e) => e.stopPropagation()}
            to={getResourceUrlNew({
              entityId: manager.id,
              entityType: EntityType.User,
            })}
            className={styles.nameField}
          >
            {manager.fullName}
          </Link>
        </div>
      );
    },
  },
  {
    id: TEAM_COL_ID,
    label: "Team",
    sortable: true,
  },
  {
    id: TITLE_COL_ID,
    label: "Title",
    sortable: true,
  },
  {
    id: "resourceCount",
    label: "Resources",
    sortable: false,
    customCellRenderer: (row) => {
      return (
        <div
          className={sprinkles({
            color: row.resourceCount > 0 ? undefined : "gray600",
          })}
        >
          {`${row.resourceCount || "No"} ${pluralize(
            "Resource",
            row.resourceCount
          )}`}
        </div>
      );
    },
  },
  {
    id: CREATED_AT_COL_ID,
    label: "Created",
    sortable: true,
    width: 100,
    customCellRenderer: (row) => {
      return <div>{moment(row[CREATED_AT_COL_ID]).fromNow()}</div>;
    },
  },
  {
    id: "idpStatus",
    label: "Status",
    sortable: false,
    width: 110,
    customCellRenderer: (row) => {
      let iconName: PropsFor<typeof Icon>["name"] = "user";
      let color: PropsFor<typeof Icon>["color"] = "gray400";
      let tooltipContent: React.ReactNode;
      let labelContent: React.ReactNode;
      switch (row.idpStatus) {
        case HrIdpStatus.Active:
          iconName = "user-check";
          color = "green500";
          labelContent = "Active";
          tooltipContent = "This account is active";
          break;
        case HrIdpStatus.Deleted:
          iconName = "user-x";
          color = "red400";
          labelContent = "Deleted";
          tooltipContent = "This account has been deleted";
          break;
        case HrIdpStatus.Deprovisioned:
          iconName = "user-x";
          color = "red400";
          labelContent = "Deprovisioned";
          tooltipContent = "This account has been deprovisioned";
          break;
        case HrIdpStatus.NotFound:
          iconName = "user-x";
          color = "gray400";
          labelContent = "Not Found";
          tooltipContent = "Unable to locate this account";
          break;
        case HrIdpStatus.Suspended:
          iconName = "user-x";
          color = "red400";
          labelContent = "Suspended";
          tooltipContent = "This account has been suspended";
          break;
        default:
          iconName = "user-x";
          color = "gray400";
          labelContent = "Inactive";
          tooltipContent = "This account is inactive";
          break;
      }
      return (
        <div className={sprinkles({ whiteSpace: "nowrap" })}>
          <Tooltip tooltipText={tooltipContent} contentInline arrow>
            <Icon name={iconName} size="xs" color={color} /> {labelContent}
          </Tooltip>
        </div>
      );
    },
  },
];

const UsersColumnV3 = () => {
  const transitionTo = useTransitionTo();
  const [showCreateModal, setShowCreateModal] = useState(false);
  const { authState } = useContext(AuthContext);
  const [showSyncModal, setShowSyncModal] = useState(false);
  const [sortBy, setSortBy] = useState<SortValue | undefined>({
    field: UsersSortByField.FirstName,
    direction: SortDirection.Asc,
  });

  const logEvent = useLogEvent();
  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();
  const syncActionIcon = useSyncActionIcon({
    syncType: SyncType.PullHrIdpData,
    queriesToRefetch: ["UsersHome"],
    label: "Sync Users",
  });

  const usersFilter = useUsersFilter();
  const debouncedSearchQuery = useDebouncedValue(usersFilter.search, 300);
  // NOTE: if you add a new filter, make sure to update ApiContext with the new filter in keyArgs
  const {
    data: usersData,
    error: usersError,
    loading: usersLoading,
    fetchMore,
    networkStatus,
  } = useUsersTableQuery({
    variables: {
      input: {
        searchQuery: debouncedSearchQuery,
        sortBy: sortBy,
        managerFilter:
          usersFilter.manager !== undefined
            ? { managerId: usersFilter.manager }
            : undefined,
        titleFilter:
          usersFilter.title !== undefined
            ? { titles: [usersFilter.title] }
            : undefined,
        teamFilter:
          usersFilter.team !== undefined
            ? { teams: [usersFilter.team] }
            : undefined,
        idpStatusFilter:
          usersFilter.hrIdpStatus !== undefined
            ? {
                statuses: [usersFilter.hrIdpStatus as HrIdpStatus],
              }
            : undefined,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  if (usersError) {
    logError(usersError, `failed to list users home`);
  }

  const loadingInitialData =
    (usersLoading && networkStatus === NetworkStatus.loading) ||
    networkStatus === NetworkStatus.refetch; // Exclude refetches from table's loading state

  const {
    data: syncData,
    error: syncError,
    loading: syncLoading,
  } = useSyncStatusQuery({
    variables: {
      input: {
        syncType: SyncType.PullHrIdpData,
      },
    },
  });

  let lastSuccessfulSyncTask: SyncTaskFragment | null = null;
  let syncErrors: SyncErrorFragment[] = [];
  if (syncData) {
    switch (syncData.syncStatus.__typename) {
      case "SyncStatusResult":
        lastSuccessfulSyncTask = syncData.syncStatus.lastSuccessfulSyncTask
          ? syncData.syncStatus.lastSuccessfulSyncTask
          : null;
        syncErrors = syncData.syncStatus.syncErrors;
        break;
      case "InvalidSyncTypeError":
      case "ResourceNotFoundError":
        logError(syncData.syncStatus.message);
        break;
    }
  }

  const users = usersData?.users.users ?? [];
  const cursor = usersData?.users.cursor;
  const totalNumUsers = usersData?.users.totalNumUsers ?? 0;

  const rows: UserRow[] = users.map((user) => {
    return {
      id: user.id,
      [NAME_COL_ID]: user.fullName,
      userAvatarUrl: user.avatarUrl,
      [EMAIL_COL_ID]: user.email ?? "",
      [MANAGER_COL_ID]: user.manager
        ? {
            id: user.manager.id,
            fullName: user.manager.fullName,
            avatarUrl: user.manager.avatarUrl,
          }
        : undefined,
      [TEAM_COL_ID]: user.teamAttr ?? "",
      [TITLE_COL_ID]: user.position ?? "",
      resourceCount: user.numResources + user.numGroups,
      [CREATED_AT_COL_ID]: user.createdAt,
      idpStatus: user.hrIdpStatus ?? undefined,
    };
  });

  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            input: {
              cursor,
              searchQuery: usersFilter.search,
              sortBy: sortBy,
              managerFilter:
                usersFilter.manager !== undefined
                  ? { managerId: usersFilter.manager }
                  : undefined,
              titleFilter:
                usersFilter.title !== undefined
                  ? { titles: [usersFilter.title] }
                  : undefined,
              teamFilter:
                usersFilter.team !== undefined
                  ? { teams: [usersFilter.team] }
                  : undefined,
              idpStatusFilter:
                usersFilter.hrIdpStatus !== undefined
                  ? {
                      statuses: [usersFilter.hrIdpStatus],
                    }
                  : undefined,
            },
          },
        });
      }
    : undefined;

  const handleExportUsers = () => {
    displayLoadingToast("Generating export...");
    axios({
      url: "/export/users",
      method: "GET",
      responseType: "blob",
    })
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          "Opal_All_Users_" + moment().toISOString() + ".csv"
        );
        link.click();
        displaySuccessToast(`Success: downloaded all users`);
      })
      .catch(() => {
        displayErrorToast(`Error: failed to generate export`);
      });
  };

  let syncStatus: string;
  if (syncError) {
    syncStatus = "Unable to get status";
  } else if (syncLoading) {
    syncStatus = "Loading sync status";
  } else {
    syncStatus = getSyncLabel(lastSuccessfulSyncTask, syncErrors);
  }

  const actionIcons: PropsFor<typeof ColumnHeaderV3>["actionIcons"] = [];
  if (!hasBasicPermissions(authState.user)) {
    if (syncActionIcon) {
      actionIcons.push(syncActionIcon);
    }
    actionIcons.push({
      label: "View Sync Details",
      sublabel: syncStatus,
      onClick: () => {
        logEvent({
          name: "apps_view_sync_details",
          properties: {
            syncType: SyncType.PullHrIdpData,
          },
        });
        setShowSyncModal(true);
      },
      adminOnly: false,
    });
  }

  const rightSideActions: PropsFor<
    typeof TableHeader
  >["defaultRightActions"] = [
    {
      label: "Export Users",
      onClick: handleExportUsers,
      type: "mainSecondary",
      iconName: "users-right",
    },
  ];
  if (authState.user?.isAdmin) {
    rightSideActions.push({
      label: "User",
      onClick: () => {
        setShowCreateModal(true);
      },
      type: "main",
      iconName: "plus",
    });
  }

  return (
    <>
      {showSyncModal ? (
        <SyncStatusModal
          syncType={SyncType.PullHrIdpData}
          entity={null}
          lastSuccessfulSyncTask={lastSuccessfulSyncTask}
          syncErrors={syncErrors}
          isModalOpen={showSyncModal}
          onClose={() => {
            setShowSyncModal(false);
          }}
        />
      ) : null}
      <OpalPage
        title="Users"
        icon="user"
        extraActions={<OpalHeaderExtraActions actionIcons={actionIcons} />}
      >
        <UserFilterBarV3 />
        <TableHeader
          entityType={EntityType.User}
          totalNumRows={totalNumUsers}
          loading={usersLoading}
          defaultRightActions={rightSideActions}
        />
        <>
          {loadingInitialData ? (
            <ColumnListItemsSkeleton />
          ) : (
            <Table
              rows={rows}
              totalNumRows={totalNumUsers ?? Number.MAX_SAFE_INTEGER}
              getRowId={(ru) => ru.id}
              columns={USER_COLUMNS}
              loadingRows={usersLoading}
              onRowClick={(row, event) => {
                transitionTo(
                  {
                    pathname: getResourceUrlNew({
                      entityId: row.id,
                      entityType: EntityType.User,
                    }),
                  },
                  event
                );
              }}
              onLoadMoreRows={loadMoreRows}
              manualSortDirection={
                sortBy && {
                  sortBy: sortBy.field,
                  sortDirection: sortBy.direction,
                }
              }
              handleManualSort={(sortBy, sortDirection) => {
                if (!sortDirection) {
                  setSortBy(undefined);
                  return;
                }
                if (!isSortableField(sortBy)) {
                  return;
                }
                const direction: SortDirection =
                  sortDirection === "DESC"
                    ? SortDirection.Desc
                    : SortDirection.Asc;

                setSortBy({
                  field: sortBy,
                  direction,
                });
              }}
            />
          )}
        </>
      </OpalPage>
      {showCreateModal ? (
        <InviteTeammatesModal
          isModalOpen={showCreateModal}
          onClose={() => setShowCreateModal(false)}
        />
      ) : null}
    </>
  );
};

export default UsersColumnV3;
