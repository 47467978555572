import { EntityType } from "api/generated/graphql";
import { Skeleton } from "components/ui";
import pluralize from "pluralize";
import { entityTypeToString } from "utils/entity";

import * as styles from "./OpalTableHeader.css";

type Props = {
  entityType?: EntityType;
  /** Used as label name if entityType is not provided */
  entityName?: string;
  totalNumRows: number | null;
  selectedNumRows?: number;
  customLabel?: React.ReactNode;
  isLoading: boolean;
  actions?: React.ReactNode;
  filters?: React.ReactNode;
};

const TableHeader = (props: Props): JSX.Element => {
  const {
    totalNumRows,
    selectedNumRows,
    isLoading,
    actions,
    filters,
    customLabel,
  } = props;
  const bulkMode = (selectedNumRows ?? 0) > 0;

  const entityTypeLabel = pluralize(
    props.entityType
      ? entityTypeToString(props.entityType)
      : props.entityName ?? "",
    bulkMode ? selectedNumRows : totalNumRows ?? 0
  );

  let numRowsLabel = entityTypeLabel;

  if (totalNumRows != null || bulkMode) {
    const numRows = bulkMode ? selectedNumRows : totalNumRows;
    numRowsLabel =
      numRows === 0 ? `No ${entityTypeLabel}` : `${numRows} ${entityTypeLabel}`;
  }

  const renderHeaderLabel = () => {
    if (isLoading) {
      return <Skeleton width="100px" variant="text" />;
    }
    return bulkMode ? `${numRowsLabel} selected` : customLabel ?? numRowsLabel;
  };

  return (
    <div className={styles.container}>
      <span className={styles.label}>{renderHeaderLabel()}</span>
      <div className={styles.rightContainer}>
        {filters && <div className={styles.filters}>{filters}</div>}
        {actions && <div className={styles.actions}>{actions}</div>}
      </div>
    </div>
  );
};

export default TableHeader;
