import { OnCallSchedule } from "api/generated/graphql";
import OnCallScheduleLabel from "components/label/OnCallScheduleLabel";
import { FormRow, Switch } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext } from "react";
import { AppsContext } from "views/apps/AppsContext";
import { GroupOnCallSchedulesEdit } from "views/groups/oncall/GroupOnCallSchedulesModal";

import { FormMode } from "../common";

interface Props {
  mode: FormMode;
  onCallSchedules?: OnCallSchedule[];
  onChange: (schedules?: OnCallSchedule[]) => void;
  showTemplateWarning?: boolean;
}

const OnCallSchedulesRow = (props: Props) => {
  const { bulkMode } = useContext(AppsContext);

  const viewContent =
    props.onCallSchedules && props.onCallSchedules.length > 0
      ? props.onCallSchedules.map((schedule) => (
          <OnCallScheduleLabel
            key={schedule.remoteId}
            scheduleName={schedule.name}
            thirdPartyProvider={schedule.thirdPartyProvider}
            showMissingFromRemoteWarning={!schedule.existsInRemote}
          />
        ))
      : "None";

  const editContent = (
    <>
      {bulkMode === "edit" && (
        <div className={sprinkles({ marginBottom: "md" })}>
          <Switch
            label="Leave unchanged"
            checked={props.onCallSchedules === undefined}
            onChange={(val) => props.onChange(val ? undefined : [])}
            rightAlign
          />
        </div>
      )}
      <div className={sprinkles({ marginBottom: "sm" })}>
        Choose the on-call schedule(s) that define this group's members. This
        group's member list will be updated each time a shift changes.
      </div>
      <GroupOnCallSchedulesEdit
        onCallSchedules={props.onCallSchedules ?? []}
        onChange={props.onChange}
      />
    </>
  );

  return (
    <FormRow title="Members on-call schedules">
      {props.mode === "view" ? viewContent : editContent}
      {props.showTemplateWarning ? (
        <p className={sprinkles({ color: "gray500" })}>
          This only applies to groups
        </p>
      ) : null}
    </FormRow>
  );
};

export default OnCallSchedulesRow;
