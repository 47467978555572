import { TimeBucket } from "api/generated/graphql";
import { Button, ButtonGroup } from "components/ui";
import ButtonGroupV3 from "components/ui/buttongroup/ButtonGroupV3";
import sprinkles from "css/sprinkles.css";
import moment from "moment";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import {
  useMetricsFilterDispatch,
  useMetricsFilterState,
} from "../MetricsDashboardContext";

const TimeBucketPicker = () => {
  const { bucketSize, dateRange } = useMetricsFilterState();
  const dispatch = useMetricsFilterDispatch();
  const durationDays = moment(dateRange.to).diff(
    moment(dateRange.from),
    "days"
  );
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  const buttonGroup = hasV3 ? (
    <ButtonGroupV3
      buttons={[
        {
          label: "1 Day",
          selected: bucketSize === TimeBucket.Day,
          onClick: () =>
            dispatch({
              type: "SET_BUCKET_SIZE",
              payload: {
                bucketSize: TimeBucket.Day,
              },
            }),
        },
        {
          label: "7 Days",
          selected: bucketSize === TimeBucket.Week,
          onClick: () =>
            dispatch({
              type: "SET_BUCKET_SIZE",
              payload: {
                bucketSize: TimeBucket.Week,
              },
            }),
          disabled: durationDays < 7,
        },
        {
          label: "30 Days",
          selected: bucketSize === TimeBucket.Month,
          onClick: () =>
            dispatch({
              type: "SET_BUCKET_SIZE",
              payload: {
                bucketSize: TimeBucket.Month,
              },
            }),
          disabled: durationDays < 30,
        },
      ]}
    />
  ) : (
    <ButtonGroup>
      <Button
        label="1 Day"
        outline={bucketSize !== TimeBucket.Day}
        type={bucketSize === TimeBucket.Day ? "primary" : undefined}
        onClick={() =>
          dispatch({
            type: "SET_BUCKET_SIZE",
            payload: {
              bucketSize: TimeBucket.Day,
            },
          })
        }
      />
      <Button
        label="7 Days"
        outline={bucketSize !== TimeBucket.Week}
        type={bucketSize === TimeBucket.Week ? "primary" : undefined}
        onClick={() =>
          dispatch({
            type: "SET_BUCKET_SIZE",
            payload: {
              bucketSize: TimeBucket.Week,
            },
          })
        }
        disabled={durationDays < 7}
      />
      <Button
        label="30 Days"
        outline={bucketSize !== TimeBucket.Month}
        type={bucketSize === TimeBucket.Month ? "primary" : undefined}
        onClick={() =>
          dispatch({
            type: "SET_BUCKET_SIZE",
            payload: {
              bucketSize: TimeBucket.Month,
            },
          })
        }
        disabled={durationDays < 30}
      />
    </ButtonGroup>
  );

  return (
    <div
      className={sprinkles({
        display: "flex",
        gap: "sm",
        alignItems: "center",
        whiteSpace: "nowrap",
      })}
    >
      <div>Aggregate by:</div>
      {buttonGroup}
    </div>
  );
};

export default TimeBucketPicker;
