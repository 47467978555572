import { EntityType, OwnerPreviewSmallFragment } from "api/generated/graphql";
import OwnerDropdown from "components/owners/OwnerDropdown";
import { FormRow } from "components/ui";
import { useContext } from "react";
import { Link } from "react-router-dom";
import { useRestrictUserViewingEntity } from "utils/auth/auth";
import { getResourceUrlNew } from "utils/common";
import { AppsContext } from "views/apps/AppsContext";

import { FormMode } from "../common";

interface Props {
  mode: FormMode;
  owner?: OwnerPreviewSmallFragment;
  onChange: (owner?: { name: string; id: string }) => void;
  clearable?: boolean;
  placeholder?: string;
}

const AdminRow = (props: Props) => {
  const { bulkMode } = useContext(AppsContext);

  // Make links unclickable if the user can't view the entity
  const disallowLink = useRestrictUserViewingEntity(EntityType.Owner);

  const viewContent = props.owner?.id ? (
    disallowLink ? (
      props.owner.name
    ) : (
      <Link
        to={getResourceUrlNew(
          {
            entityId: props.owner.id,
            entityType: EntityType.Owner,
          },
          EntityType.Owner
        )}
      >
        {props.owner.name}
      </Link>
    )
  ) : (
    "--"
  );

  const editContent = (
    <OwnerDropdown
      selectedOwnerId={props.owner?.id}
      onSelectOwner={props.onChange}
      clearable={props.clearable || bulkMode === "edit"}
      placeholder={
        props.placeholder ??
        (bulkMode === "edit"
          ? "Leave unchanged"
          : "Leave empty to default to app admin")
      }
    />
  );

  return (
    <FormRow title="Admin">
      {props.mode === "view" ? viewContent : editContent}
    </FormRow>
  );
};

export default AdminRow;
