import { gql, useQuery } from "@apollo/client";
import {
  IdpConnectionUserAttributeUseAs,
  PriorityOrderQuery,
} from "api/generated/graphql";
import React from "react";
import { useHistory } from "react-router";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import TabsV3 from "../../../components/ui/tabs/TabsV3";
import { OrgSettingsSection } from "../OrgSettingsV3";
import { AttributeIdpOrder, IdpConnectionRow } from "./AttributeIdpOrder";
import * as styles from "./AttributeMappingsOrder.css";

export const AttributeMappingsOrder = () => {
  const history = useHistory();

  const { loading, error, data } = useQuery<PriorityOrderQuery>(
    gql`
      query PriorityOrder {
        priorityOrder {
          __typename
          ... on UserAttributePriorityOutput {
            idpUserAttributePriorities {
              useAs
              idpConnections {
                id
                connection {
                  name
                  connectionType
                }
              }
            }
          }
        }
      }
    `,
    {
      fetchPolicy: "cache-and-network",
    }
  );

  if (error) {
    return <UnexpectedErrorPage error={error} />;
  }

  let orderElements = [] as JSX.Element[];

  if (!loading && data?.priorityOrder) {
    const priorityOrderMap = new Map<
      IdpConnectionUserAttributeUseAs,
      IdpConnectionRow[]
    >();
    data.priorityOrder.idpUserAttributePriorities.forEach((priority) => {
      priorityOrderMap.set(
        priority.useAs,
        priority.idpConnections.map(
          (idpConnection) =>
            ({
              id: idpConnection.id,
              name: idpConnection.connection.name,
              connectonType: idpConnection.connection.connectionType,
            } as IdpConnectionRow)
        )
      );
    });

    orderElements = Array.from(priorityOrderMap)
      .sort()
      .map(([key, value]) => {
        return <AttributeIdpOrder attribute={key} idpConnections={value} />;
      });
  }

  return (
    <OrgSettingsSection title={"IDP & HR Integrations"}>
      <TabsV3
        tabInfos={[
          {
            title: "Settings",
            isSelected: false,
            onClick: () => {
              history.push("/settings#idp-and-hr");
            },
          },
          {
            title: "Attribute Mapping",
            isSelected: true,
            onClick: () => {
              history.push("/settings/idp/mappings");
            },
          },
        ]}
      />
      <div className={styles.heading}>
        For each user attribute, set the order of preferred values when a
        conflict exists.
      </div>
      <div className={styles.body}>
        When there are conflicting values, Opal will display the value from the
        highest ranked system. In the event that a user is deactivated in one
        system, Opal will display the value with the active user regardless of
        the ranking below.
      </div>
      {orderElements}
    </OrgSettingsSection>
  );
};
