import {
  ConnectionPreviewLargeFragment,
  useConnectionAppIconQuery,
  useUpdateConnectionMutation,
} from "api/generated/graphql";
import { useToast } from "components/toast/Toast";
import { Button, FileUpload, FormRow, Icon } from "components/ui";
import { IconData } from "components/ui/utils";
import { SectionEntry } from "components/viewer/Viewer";
import sprinkles from "css/sprinkles.css";
import { logError } from "utils/logging";

type Props = {
  connection: ConnectionPreviewLargeFragment;
};

export const ConnectionAppIconRow = (props: Props) => {
  const { displayErrorToast, displaySuccessToast } = useToast();

  const { data, error, loading } = useConnectionAppIconQuery({
    variables: {
      connectionId: props.connection.id,
    },
  });
  const [updateConnectionMutation] = useUpdateConnectionMutation();

  let connection;
  if (data) {
    switch (data.connection.__typename) {
      case "ConnectionResult":
        connection = data.connection.connection;
        break;
      case "ConnectionNotFoundError":
        // do nothing
        break;
      default:
        logError(new Error(`failed to get connection`));
    }
  } else if (error) {
    logError(error, `failed to get connection`);
  }

  if (loading) {
    return <SectionEntry title={"App icon"} />;
  }

  const updateConnectionAppIcon = async (upload: File) => {
    var filesize = upload.size / 1024 / 1024; // MB
    if (filesize > 2) {
      displayErrorToast(`Error: file size too large, must be less than 2MB`);
      return;
    }

    try {
      const { data } = await updateConnectionMutation({
        variables: {
          input: {
            id: props.connection.id,
            connectionImage: upload,
          },
        },
        refetchQueries: ["ConnectionAppIcon"],
      });
      switch (data?.updateConnection.__typename) {
        case "UpdateConnectionResult":
          displaySuccessToast(`Successfully updated app icon`);
          break;
        case "ConnectionBadMetadataError":
        case "ConnectionNotFoundError":
        case "UserFacingError":
          displayErrorToast(data.updateConnection.message);
          break;
        default:
          throw new Error(
            `failed to update app icon for connection ${props.connection.id}`
          );
      }
    } catch (error) {
      logError(error);
      displayErrorToast(`Error: failed to update app icon for connection`);
    }
  };

  const icon: IconData = {
    type: "src",
    icon: connection?.iconUrl ?? undefined,
  };
  return (
    <FormRow title={"App Icon"}>
      <div
        className={sprinkles({
          display: "flex",
          gap: "sm",
          alignItems: "center",
        })}
      >
        {connection?.iconUrl && <Icon data={icon} size="lg" />}
        <FileUpload
          renderButton={(onClick) => (
            <Button
              label="Update icon"
              onClick={onClick}
              size="md"
              borderless
            />
          )}
          handleUpload={updateConnectionAppIcon}
          accept={[".jpeg", ".jpg", ".png"]}
        />
      </div>
    </FormRow>
  );
};

export default ConnectionAppIconRow;
