import { ArcElement, Chart as ChartJS } from "chart.js";
import { Pie } from "react-chartjs-2";

import * as styles from "./BarGraph.css";

ChartJS.register(ArcElement);

interface SectionData {
  value: number;
  label: string;
  color: string;
}

interface Props {
  sections: SectionData[];
}

const PieChart = (props: Props) => {
  const options: PropsFor<typeof Pie>["options"] = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        displayColors: false,
      },
    },
    cutout: "25%",
  };

  const datasets: PropsFor<typeof Pie>["data"]["datasets"] = [
    {
      data: props.sections.map((section) => section.value),
      backgroundColor: props.sections.map((section) => section.color),
    },
  ];

  const labels = props.sections.map((section) => section.label);

  return (
    <Pie
      data={{
        datasets,
        labels,
      }}
      options={options}
    />
  );
};

export const PieChartLegend = (props: Props) => {
  return (
    <div>
      {props.sections.map((section) => {
        return (
          <div className={styles.legendLabel} key={section.label}>
            <div
              className={styles.legendColor}
              style={{ backgroundColor: section.color }}
            />
            {section.label}
          </div>
        );
      })}
    </div>
  );
};

export default PieChart;
