import { getModifiedErrorMessage } from "api/ApiContext";
import {
  AccessReviewAction,
  AccessReviewConnectionFragment,
  AccessReviewConnectionPreviewFragment,
  AccessReviewConnectionUserPreviewFragment,
  EntityType,
  ReviewConnectionUserAction,
  ReviewerUserStatus,
  useUpdateConnectionUserReviewersMutation,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import AccessReviewNoteLabel from "components/label/AccessReviewNoteLabel";
import { ResourceLabel } from "components/label/Label";
import NavigationContext from "components/layout/NavigationContextProvider";
import AccessReviewerModal from "components/modals/AccessReviewerModal";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import {
  CellRow,
  Header,
  ScrollableMuiVirtualTable,
} from "components/tables/material_table/MuiVirtualTable";
import { useToast } from "components/toast/Toast";
import { Popover } from "components/ui";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { SortDirection } from "react-virtualized";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { logError } from "utils/logging";
import AccessReviewAcceptRevokeToggleButton, {
  calculateBatchAcceptRevokeState,
} from "views/access_reviews/AccessReviewAcceptRevokeToggleButton";
import AccessReviewContext, {
  AccessReviewContextActionType,
} from "views/access_reviews/AccessReviewContext";
import AccessReviewReviewerDetails, {
  accessReviewReviewerDetailsRowHeight,
} from "views/access_reviews/AccessReviewReviewerDetails";
import AccessReviewReviewersCell from "views/access_reviews/AccessReviewReviewersCell";
import {
  accessReviewItemStatusSortValue,
  AccessReviewOutcomeLabel,
  AccessReviewStatusLabel,
} from "views/access_reviews/AccessReviewStatus";
import {
  getReviewerModalEntries,
  getReviewersSortValue,
  isReviewer,
} from "views/access_reviews/common/Common";
import { getAccessReviewUserUrl } from "views/access_reviews/common/Routes";
import {
  ITEM_ACTION_HEADER,
  ITEM_STATUS_HEADER,
  NOTE_HEADER,
  OUTCOME_HEADER,
  REVIEWERS_HEADER,
  USER_NAME_HEADER,
} from "views/access_reviews/common/TableHeaders";
import AccessReviewUserOverview from "views/access_reviews/users/AccessReviewUserOverview";

interface AccessReviewConnectionUserTableRow {
  name: string;
  note?: string;
  reviewers?: string;
  status?: string;
  outcome?: string;
  acceptRevoke?: string;
}

type AccessReviewConnectionUsersTableProps = {
  accessReviewConnection: AccessReviewConnectionFragment;
  selectedRows?: Record<string, EntityType>;
  setSelectedRows?: (value: Record<string, EntityType>) => void;
};

export const AccessReviewConnectionUsersTable = (
  props: AccessReviewConnectionUsersTableProps
) => {
  const { navigationState } = useContext(NavigationContext);
  const { authState } = useContext(AuthContext);
  const { accessReviewState, accessReviewDispatch } = useContext(
    AccessReviewContext
  );
  const history = useHistory();

  const selfReviewAllowed =
    props.accessReviewConnection.accessReview?.selfReviewAllowed;

  const [expandedByRowId, setExpandedByRowId] = useState<
    Record<string, boolean>
  >({});

  let users = props.accessReviewConnection.connectionUsers || [];

  if (navigationState.isOnlyMyReviewsFilterOn) {
    users = users.filter((accessReviewConnectionUser) => {
      return (
        authState.user?.user.id &&
        isReviewer(
          authState.user.user.id,
          accessReviewConnectionUser.reviewerUsers ?? []
        )
      );
    });
  }

  const performReviewState =
    accessReviewState.performReviewStateByUARConnectionId[
      props.accessReviewConnection.id
    ];
  const isConnectionBeingReviewed = !!performReviewState;

  if (isConnectionBeingReviewed) {
    users = users.filter((user) => {
      return user.reviewerUsers?.find((reviewer) => {
        if (reviewer.userId === authState.user?.user.id) {
          return (
            (selfReviewAllowed || user.userId !== authState.user?.user.id) &&
            reviewer.status === ReviewerUserStatus.NotStarted
          );
        }
        return false;
      });
    });
  }

  /*
   * Figure out which columns to show depending on if the client is
   * performing reviews or viewing reviews.
   */

  // We always show these columns.
  const headers: Header<AccessReviewConnectionUserTableRow>[] = [
    USER_NAME_HEADER,
    REVIEWERS_HEADER,
  ];

  // These are the conditional columns.
  if (isConnectionBeingReviewed && users.length > 0) {
    const batchAcceptRevokeToggleButton = (
      <AccessReviewAcceptRevokeToggleButton
        state={calculateBatchAcceptRevokeState(
          performReviewState.connectionUserActions,
          users.length
        )}
        acceptText={"Accept All"}
        revokeText={"Revoke All"}
        onStateChange={(state) => {
          if (!isConnectionBeingReviewed) return;

          performReviewState.connectionUserActions.forEach(
            (connectionUserAction) => {
              connectionUserAction.action = state;
            }
          );

          users.forEach((connectionUser) => {
            const existingInfo = performReviewState.connectionUserActions.find(
              (connectionUserAction) =>
                connectionUserAction.accessReviewConnectionUserId ===
                connectionUser.id
            );

            if (!existingInfo) {
              performReviewState.connectionUserActions.push({
                accessReviewConnectionUserId: connectionUser.id,
                action: state,
              });
            }
          });

          accessReviewDispatch({
            type: AccessReviewContextActionType.AccessReviewItemUpdate,
            payload: {
              performReviewStateByUARConnectionId: {
                ...accessReviewState.performReviewStateByUARConnectionId,
                [props.accessReviewConnection.id]: performReviewState,
              },
            },
          });
        }}
      />
    );
    headers.push(
      {
        ...ITEM_ACTION_HEADER,
        customHeader: batchAcceptRevokeToggleButton,
      },
      NOTE_HEADER
    );
  } else {
    headers.push(ITEM_STATUS_HEADER, OUTCOME_HEADER);
  }

  /*
   * Table rows data
   */

  const connectionUserActionByUarConnectionUserId: Record<
    string,
    ReviewConnectionUserAction
  > = {};
  performReviewState?.connectionUserActions?.forEach((connectionUserAction) => {
    const id = connectionUserAction.accessReviewConnectionUserId;
    connectionUserActionByUarConnectionUserId[id] = connectionUserAction;
  });

  const rows: CellRow<AccessReviewConnectionUserTableRow>[] = users.map(
    (connectionUser, idx) => {
      const rowId = connectionUser.id;
      const connectionUserAction =
        connectionUserActionByUarConnectionUserId[rowId];
      const user = connectionUser.user;
      const name = user?.fullName || connectionUser.userId;
      const status = connectionUser.statusAndOutcome.status;
      const outcome = connectionUser.statusAndOutcome.outcome;

      return {
        id: rowId,
        expandableContent: {
          content: (
            <AccessReviewReviewerDetails
              reviewers={connectionUser.reviewerUsers}
            />
          ),
          expandedRowHeight: accessReviewReviewerDetailsRowHeight(
            connectionUser.reviewerUsers
          ),
          isExpanded: expandedByRowId[rowId],
          setIsExpanded: (expanded) => {
            setExpandedByRowId({
              ...expandedByRowId,
              [rowId]: expanded,
            });
          },
        },
        data: {
          name: {
            value: name,
            element: (
              <Popover
                content={
                  <AccessReviewUserOverview userId={connectionUser.userId} />
                }
                inline
              >
                <ResourceLabel
                  text={name}
                  subText={connectionUser.user?.teamAttr}
                  iconLarge={true}
                  bold={true}
                  entityType={EntityTypeDeprecated.User}
                  avatar={connectionUser.user?.avatarUrl}
                  pointerCursor={true}
                />
              </Popover>
            ),
            clickHandler: () => {
              history.push(
                getAccessReviewUserUrl(
                  props.accessReviewConnection.accessReviewId,
                  connectionUser.userId
                )
              );
            },
          },
          reviewers: {
            value: getReviewersSortValue(connectionUser.reviewerUsers ?? []),
            element: (
              <ConnectionUserAccessReviewersCell
                accessReviewConnection={props.accessReviewConnection}
                connectionUser={connectionUser}
              />
            ),
          },
          status: {
            value: status,
            sortValue: accessReviewItemStatusSortValue(status),
            element: (
              <AccessReviewStatusLabel
                entityType={EntityType.Connection}
                status={status}
                warnings={connectionUser.warnings}
                connection={props.accessReviewConnection.connection}
                user={connectionUser.user}
                accessReviewId={props.accessReviewConnection.accessReviewId}
              />
            ),
          },
          note: {
            options: {
              display: isConnectionBeingReviewed,
            },
            value: "",
            element: (
              <AccessReviewNoteLabel
                initNoteContent={connectionUserAction?.note}
                onSubmit={(updatedNoteContent) => {
                  if (!performReviewState) {
                    return;
                  }

                  const existingInfo = performReviewState.connectionUserActions.find(
                    (connectionUserAction) =>
                      connectionUserAction.accessReviewConnectionUserId ===
                      connectionUser.id
                  );

                  if (existingInfo) {
                    existingInfo.note = updatedNoteContent;
                  } else {
                    performReviewState.connectionUserActions.push({
                      accessReviewConnectionUserId: connectionUser.id,
                      action: AccessReviewAction.NoAction,
                      note: updatedNoteContent,
                    });
                  }
                }}
              />
            ),
            sortValue: idx,
          },
          outcome: {
            value: outcome,
            element: (
              <AccessReviewOutcomeLabel
                entityType={EntityType.AccessReviewConnectionUser}
                outcome={outcome}
              />
            ),
          },
          acceptRevoke: {
            value: connectionUserAction?.action || AccessReviewAction.NoAction,
            element: (
              <AccessReviewAcceptRevokeToggleButton
                state={
                  connectionUserAction?.action || AccessReviewAction.NoAction
                }
                onStateChange={(state) => {
                  if (!performReviewState) {
                    return;
                  }

                  const existingInfo = performReviewState.connectionUserActions.find(
                    (connectionUserAction) =>
                      connectionUserAction.accessReviewConnectionUserId ===
                      rowId
                  );

                  if (existingInfo) {
                    existingInfo.action = state;
                  } else {
                    performReviewState.connectionUserActions.push({
                      accessReviewConnectionUserId: rowId,
                      action: state,
                    });
                  }
                  accessReviewDispatch({
                    type: AccessReviewContextActionType.AccessReviewItemUpdate,
                    payload: {
                      performReviewStateByUARConnectionId: {
                        ...accessReviewState.performReviewStateByUARConnectionId,
                        [props.accessReviewConnection.id]: performReviewState,
                      },
                    },
                  });
                }}
              />
            ),
          },
        },
      };
    }
  );

  const onCheckedRowsChange = (ids: string[], checkboxState: boolean) => {
    if (props.selectedRows && props.setSelectedRows) {
      if (checkboxState) {
        const newSelectedRows = props.selectedRows;
        ids.forEach(
          (id) => (newSelectedRows[id] = EntityType.AccessReviewConnectionUser)
        );
        props.setSelectedRows({ ...newSelectedRows });
      } else {
        const newSelectedRows = props.selectedRows;
        ids.forEach((id) => delete newSelectedRows[id]);
        props.setSelectedRows({ ...newSelectedRows });
      }
    }
  };

  // build checked row ids set
  const checkedRowIds = props.selectedRows
    ? new Set(Object.keys(props.selectedRows))
    : undefined;

  const selectAllChecked =
    Object.keys(props.selectedRows ?? {}).length ===
    props.accessReviewConnection.numConnectionUsers;

  const onSelectAllChange = (checkboxState: boolean) => {
    if (props.selectedRows && props.setSelectedRows) {
      if (checkboxState) {
        const newSelectedRows: Record<string, EntityType> = {};
        users.forEach((connectionUser) => {
          newSelectedRows[connectionUser.id] =
            EntityType.AccessReviewConnectionUser;
        });
        props.setSelectedRows({ ...newSelectedRows });
      } else {
        props.setSelectedRows({});
      }
    }
  };

  return (
    <EmptyStateContentWrapper
      content={
        <ScrollableMuiVirtualTable
          columns={headers}
          rows={rows}
          defaultSortBy={"name"}
          defaultSortDirection={SortDirection.ASC}
          allRowsLoaded
          expandable
          checkedRowIds={checkedRowIds}
          onCheckedRowsChange={onCheckedRowsChange}
          selectAllChecked={selectAllChecked}
          onSelectAllChange={onSelectAllChange}
        />
      }
      isEmpty={users.length === 0}
      entityType={EntityType.User}
      title={`No users to review`}
      subtitle={
        navigationState.isOnlyMyReviewsFilterOn
          ? `No users have been assigned for you to review.`
          : `No users had direct access to this app at the time of the access review.`
      }
    />
  );
};

type ConnectionUserAccessReviewersCellProps = {
  accessReviewConnection: AccessReviewConnectionPreviewFragment;
  connectionUser: AccessReviewConnectionUserPreviewFragment;
};

const ConnectionUserAccessReviewersCell = (
  props: ConnectionUserAccessReviewersCellProps
) => {
  const { accessReviewState } = useContext(AccessReviewContext);
  const [showReviewersModal, setShowReviewersModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { displaySuccessToast } = useToast();

  const [
    updateConnectionUserReviewers,
    { loading },
  ] = useUpdateConnectionUserReviewersMutation();

  const canEditReviewers =
    accessReviewState.ongoingAccessReviewIdSet.has(
      props.accessReviewConnection.accessReviewId
    ) && props.accessReviewConnection.canEditReviewers;

  return (
    <>
      <AccessReviewReviewersCell
        itemType="app"
        reviewerUsers={props.connectionUser.reviewerUsers}
        canEditReviewers={canEditReviewers}
        onClick={() => {
          setShowReviewersModal(true);
        }}
      />
      {showReviewersModal && (
        <AccessReviewerModal
          title={"Reviewers"}
          isModalOpen={showReviewersModal}
          onClose={() => {
            setShowReviewersModal(false);
            setErrorMessage("");
          }}
          loading={loading}
          onSubmit={async (reviewers) => {
            try {
              const { data } = await updateConnectionUserReviewers({
                variables: {
                  input: {
                    accessReviewConnectionUserId: props.connectionUser.id,
                    reviewers: reviewers,
                  },
                },
              });
              switch (data?.updateConnectionUserReviewers.__typename) {
                case "UpdateConnectionUserReviewersResult": {
                  displaySuccessToast("Success: reviewers updated");
                  setShowReviewersModal(false);
                  break;
                }
                case "AccessReviewAlreadyStoppedError": {
                  setErrorMessage(data?.updateConnectionUserReviewers.message);
                  break;
                }
              }
            } catch (error) {
              logError(error, "failed to update access reviewers");
              setErrorMessage(
                getModifiedErrorMessage(
                  "Error: failed to update access reviewers",
                  error
                )
              );
            }
          }}
          errorMessage={errorMessage}
          entryInfos={getReviewerModalEntries(
            props.connectionUser.reviewerUsers,
            null
          )}
          canEditReviewers={canEditReviewers}
        />
      )}
    </>
  );
};

export default AccessReviewConnectionUsersTable;
