import {
  GroupDocument,
  GroupFragment,
  Maybe,
  useUpdateGroupMutation,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Banner, Checkbox, Input, Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useState } from "react";
import useLogEvent from "utils/analytics";
import { logError } from "utils/logging";

import * as styles from "./GroupRenameModal.css";

export type GroupRenameModalProps = {
  group: GroupFragment;
  showModal: boolean;
  setShowModal: (show: boolean) => void;
};

export const GroupRenameModal = (props: GroupRenameModalProps) => {
  const [name, setName] = useState(props.group.name);
  const [placeholder, setPlaceholder] = useState("");
  const [matchRemoteName, setMatchRemoteName] = useState(
    props.group.commonMetadata.matchRemoteName
  );

  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);

  const { displaySuccessToast } = useToast();
  const logEvent = useLogEvent();

  const [updateGroup, { loading }] = useUpdateGroupMutation();

  const editModalReset = () => {
    props.setShowModal(false);
    setErrorMessage(null);
  };

  const isUpdated =
    name !== props.group.name ||
    matchRemoteName !== props.group.commonMetadata.matchRemoteName;

  const handleSubmit = async () => {
    logEvent({
      name: "apps_item_rename_click",
      properties: {
        itemType: props.group.groupType,
      },
    });

    try {
      const { data } = await updateGroup({
        variables: {
          input: {
            groupId: props.group.id,
            name: name,
            commonMetadata: {
              matchRemoteName: matchRemoteName,
            },
          },
        },
        refetchQueries: [
          {
            query: GroupDocument,
            variables: {
              input: {
                id: props.group.id,
              },
            },
          },
        ],
      });
      switch (data?.updateGroup.__typename) {
        case "UpdateGroupResult":
          editModalReset();
          displaySuccessToast("Success: group name updated");
          break;
        case "GroupNotFoundError":
        default:
          logError(new Error(`failed to update group name`));
          setErrorMessage(`Error: failed to update group name`);
      }
    } catch (error) {
      logError(error, `failed to update group name`);
      setErrorMessage(`Error: failed to update group name`);
    }
  };

  return (
    <Modal title="Rename" isOpen={props.showModal} onClose={editModalReset}>
      <Modal.Body>
        <div className={styles.body}>
          {!props.group.commonMetadata.matchRemoteName && matchRemoteName && (
            <div className={sprinkles({ marginBottom: "lg" })}>
              <Banner
                message={
                  "When selected, this item's name in Opal will match its name on the end system. To reflect newer changes, sync this item."
                }
                type="info"
              />
            </div>
          )}
          <div className={sprinkles({ marginBottom: "lg" })}>
            <Checkbox
              checked={matchRemoteName}
              label="Use name from end system"
              onChange={(checked) => {
                setMatchRemoteName(checked);
                if (checked) {
                  setName(props.group.remoteName);
                  setPlaceholder(
                    `${props.group.remoteName} (will be synced from end system)`
                  );
                } else {
                  setName(props.group.name);
                  setPlaceholder("");
                }
              }}
            />
          </div>
          <Input
            value={placeholder || name}
            onChange={setName}
            disabled={matchRemoteName}
          />
          {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Save"
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLoading={loading}
        primaryButtonDisabled={!isUpdated}
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={editModalReset}
      />
    </Modal>
  );
};
