import { EntityType, OwnedGroupFragment } from "api/generated/graphql";
import { GroupTableCellResourceLabel } from "components/label/GroupTableCellResourceLabel";
import ConnectionLabel from "components/label/item_labels/ConnectionLabel";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import MaterialTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MaterialTable";
import { Icon } from "components/ui";
import React from "react";
import { useHistory } from "react-router";
import { getResourceUrlNew } from "utils/common";

interface OwnerGroupsTableRow {
  name: string;
  connection: string;
  admin: string;
  reviewer: string;
}

type OwnerGroupsTableProps = {
  ownedGroups: OwnedGroupFragment[];
};

export const OwnerGroupsTable = (props: OwnerGroupsTableProps) => {
  const history = useHistory();

  const headers: Header<OwnerGroupsTableRow>[] = [
    { id: "name", label: "Name" },
    { id: "connection", label: "App" },
    { id: "admin", label: "Admin" },
    { id: "reviewer", label: "Reviewer" },
  ];

  const rows: CellRow<OwnerGroupsTableRow>[] = props.ownedGroups.map(
    ({ group, isAdmin, isReviewer }) => ({
      id: group.id,
      data: {
        name: {
          value: group.name,
          element: (
            <GroupTableCellResourceLabel
              groupName={group.name}
              groupType={group.groupType}
              isOnCall={group.isOnCallSynced}
            />
          ),
          clickHandler: () => {
            history.push(
              getResourceUrlNew({
                entityId: group?.id,
                entityType: EntityType.Group,
              })
            );
          },
        },
        connection: {
          sortValue: group.connection?.name || "",
          value: group.connection?.id || "",
          element: (
            <ConnectionLabel
              text={group.connection?.name}
              connectionId={group.connection?.id}
              connectionType={group.connection?.connectionType}
            />
          ),
        },
        admin: {
          value: String(isAdmin),
          element: isAdmin ? <Icon name="check" size="sm" /> : <></>,
        },
        reviewer: {
          value: String(isReviewer),
          element: isReviewer ? <Icon name="check" size="sm" /> : <></>,
        },
      },
    })
  );

  return (
    <EmptyStateContentWrapper
      content={
        <MaterialTable<OwnerGroupsTableRow> headers={headers} rows={rows} />
      }
      entityType={EntityType.Group}
      title="No groups owned"
      isEmpty={props.ownedGroups.length === 0}
    />
  );
};

export default OwnerGroupsTable;
