import {
  AccessReviewFragment,
  SyncType,
  useAccessReviewQuery,
  useOngoingAccessReviewTabStatsQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { StartSyncButton } from "components/buttons/StartSyncButton";
import { Column } from "components/column/Column";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeader";
import ColumnListItem, {
  ColumnListItemsSkeleton,
} from "components/column/ColumnListItem";
import { Divider } from "components/ui";
import { useContext } from "react";
import { useHistory, useLocation, useParams } from "react-router";
import { hasBasicPermissions } from "utils/auth/auth";
import { logError } from "utils/logging";
import { useReadUINotification } from "utils/notifications";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";

import * as styles from "./AccessReviewDetailColumn.css";
import AccessReviewEndedColumn from "./AccessReviewEndedColumn";
import AccessReviewOngoingColumn from "./AccessReviewOngoingColumn";
import EventsTable from "./common/EventsTable";
import AccessReviewConnections from "./connections/AccessReviewConnections";
import AccessReviewGroups from "./groups/AccessReviewGroups";
import AccessReviewResources from "./resources/AccessReviewResources";
import AccessReviewRevocations from "./revocations/AccessReviewRevocations";
import AccessReviewRevocationsActionButtons from "./revocations/AccessReviewRevocationsActionButtons";
import AccessReviewUsers from "./users/AccessReviewUsers";

const AccessReviewDetailColumn = () => {
  const history = useHistory();
  const location = useLocation();
  const { accessReviewId } = useParams<Record<string, string>>();
  const { authState } = useContext(AuthContext);

  const { data, error, loading } = useAccessReviewQuery({
    variables: {
      input: {
        accessReviewId: accessReviewId,
      },
    },
    skip: !accessReviewId,
  });
  let accessReviewNotFound = false;
  let accessReview: AccessReviewFragment | null = null;
  if (data) {
    switch (data.accessReview.__typename) {
      case "AccessReviewResult":
        accessReview = data.accessReview.accessReview;
        break;
      case "AccessReviewNotFoundError":
        accessReviewNotFound = true;
        break;
      default:
        logError(new Error(`failed to retrieve access reviews`));
    }
  } else if (error) {
    logError(error, `failed to retrieve access reviews`);
  }

  const {
    data: tabStatsData,
    error: tabStatsError,
  } = useOngoingAccessReviewTabStatsQuery({
    fetchPolicy: "cache-and-network",
    variables: {
      input: {
        accessReviewId: accessReview?.id ?? "",
      },
    },
    skip: !accessReview,
  });

  let tabStats = null;
  if (tabStatsData) {
    switch (tabStatsData.ongoingAccessReviewTabStats.__typename) {
      case "OngoingAccessReviewTabStatsResult":
        tabStats =
          tabStatsData.ongoingAccessReviewTabStats
            .ongoingAccessReviewTabStats || null;
        break;
      case "AccessReviewNotFoundError":
        accessReviewNotFound = true;
        break;
      case "AccessReviewAlreadyStoppedError":
        break;
      default:
        logError(new Error(`failed to retrieve access review stats`));
    }
  } else if (tabStatsError) {
    logError(tabStatsError, `failed to retrieve access review stats`);
  }

  const isPastAccessReview = accessReview?.stoppedDate !== null;
  useReadUINotification(accessReview?.id ?? "");

  if (loading) {
    return (
      <Column>
        <ColumnHeaderSkeleton />
        <Divider />
        <ColumnListItemsSkeleton />
      </Column>
    );
  }

  if (accessReviewNotFound) {
    return (
      <Column isContent>
        <NotFoundPage />
      </Column>
    );
  }

  if (!accessReviewId || !accessReview || error) {
    return (
      <Column isContent>
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  let content;
  switch (location.hash) {
    case "#resources":
      content = (
        <Column isContent>
          <AccessReviewResources accessReview={accessReview} />
        </Column>
      );
      break;
    case "#groups":
      content = (
        <Column isContent>
          <AccessReviewGroups accessReview={accessReview} />
        </Column>
      );
      break;
    case "#apps":
      content = (
        <Column isContent>
          <AccessReviewConnections accessReview={accessReview} />
        </Column>
      );
      break;
    case "#users":
      content = (
        <Column isContent>
          <AccessReviewUsers accessReview={accessReview} />
        </Column>
      );
      break;
    case "#access-changes":
      content = (
        <Column isContent>
          <div className={styles.header}>
            <div className={styles.title}>Access Changes</div>
            <StartSyncButton
              syncType={SyncType.PullUarRemoteTickets}
              accessReview={accessReview}
              queriesToRefetch={["AccessReviewRevocations"]}
            />
            {isPastAccessReview ? null : (
              <AccessReviewRevocationsActionButtons
                accessReviewId={accessReview.id}
              />
            )}
          </div>
          <AccessReviewRevocations accessReview={accessReview} />
        </Column>
      );
      break;
    case "#events":
      content = (
        <Column isContent>
          <ColumnHeader
            title="Events"
            icon={{ type: "name", icon: "events" }}
          />
          <Divider />
          <EventsTable
            eventFilter={{
              objects: {
                objectId: accessReview.id,
              },
            }}
          />
        </Column>
      );
      break;
    default:
      content = isPastAccessReview ? (
        <AccessReviewEndedColumn accessReview={accessReview} />
      ) : (
        <AccessReviewOngoingColumn
          ongoingAccessReview={accessReview}
          userReviewsStats={tabStats ?? undefined}
        />
      );
      break;
  }

  let category = "ongoing";
  if (isPastAccessReview) {
    category = "ended";
  }

  const shouldShowAdminContent = !hasBasicPermissions(authState.user);
  const shouldShowOverview = shouldShowAdminContent || !isPastAccessReview;
  return (
    <>
      <Column>
        <ColumnHeader
          title={accessReview.name}
          icon={{ type: "name", icon: "check-square" }}
          breadcrumbs={[
            { name: "Access Reviews", to: "/access-reviews" },
            {
              name: category === "ongoing" ? "Ongoing" : "Ended",
              to: "/access-reviews",
            },
          ]}
        />
        <Divider />
        {shouldShowOverview ? (
          <ColumnListItem
            key="overview"
            label="Overview"
            onClick={() =>
              history.push({ hash: "#overview", search: location.search })
            }
            selected={!location.hash || location.hash === "#overview"}
            icon={{ type: "name", icon: "list" }}
          />
        ) : null}
        {!isPastAccessReview && (
          <ColumnListItem
            key="my-reviews"
            label="My Reviews"
            onClick={() =>
              history.push(`/access-reviews/${accessReviewId}/my-reviews`)
            }
            icon={{ type: "name", icon: "hourglass" }}
            count={
              tabStats
                ? tabStats.numAppsToReview +
                  tabStats.numGroupsToReview +
                  tabStats.numResourcesToReview
                : undefined
            }
          />
        )}
        {shouldShowAdminContent && (
          <ColumnListItem
            key="review-entities"
            label="Review Entities"
            icon={{ type: "name", icon: "cube" }}
            onClick={() =>
              history.push(`/access-reviews/${accessReviewId}/entities`)
            }
          />
        )}
        {shouldShowAdminContent && (
          <ColumnListItem
            key="reviewers"
            label="Reviewers"
            icon={{ type: "name", icon: "user-check" }}
            onClick={() =>
              history.push(`/access-reviews/${accessReviewId}/reviewers`)
            }
          />
        )}

        {shouldShowAdminContent ? (
          <>
            <ColumnListItem
              key="access-changes"
              label="Access Changes"
              onClick={() => {
                history.push(
                  `/access-reviews/${accessReviewId}/access-changes`
                );
              }}
              selected={location.hash === "#access-changes"}
              icon={{ type: "name", icon: "refresh" }}
            />
            <ColumnListItem
              key="events"
              label="Events"
              onClick={() =>
                history.push({ hash: "#events", search: location.search })
              }
              selected={location.hash === "#events"}
              icon={{ type: "name", icon: "events" }}
            />
          </>
        ) : null}
      </Column>
      {content}
    </>
  );
};

export default AccessReviewDetailColumn;
