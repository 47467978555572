import AuthContext from "components/auth/AuthContext";
import { ButtonV3, Divider, Header } from "components/ui";
import EntityDataElements from "components/viz/EntityDataElements";
import EntitySelector from "components/viz/EntitySelector";
import UserDetailsFilter from "components/viz/filters/UserDetailsFilter";
import React, { useContext, useEffect, useState } from "react";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import ActionsSidebar from "./ActionSidebar";
import { GraphContext } from "./contexts/GraphContext";
import AccessTypeFilter from "./filters/AccessTypeFilter";
import UsageFilter from "./filters/UsageFilter";
import FiltersSidebarV3 from "./FiltersSidebarV3";
import * as styles from "./Sidebar.css";

const Sidebar = () => {
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);
  const { graphState, graphDispatch } = React.useContext(GraphContext);
  const { authState } = useContext(AuthContext);
  const selectedIds = [
    ...graphState.selectedUserIds,
    ...graphState.selectedResourceIds,
    ...graphState.selectedGroupIds,
  ];
  const actionsHidden = selectedIds.length === 0 || !authState.user?.isAdmin;
  const [isOpen, setIsOpen] = useState(actionsHidden);

  useEffect(() => {
    if (actionsHidden === false && isOpen === false) {
      setIsOpen(true);
    }
  }, [actionsHidden, isOpen]);

  return (
    <div className={styles.minimizeWrapper({ isOpen: isOpen })}>
      <div className={styles.wrapper}>
        {hasV3 ? <FiltersSidebarV3 /> : <FiltersSidebar />}
        <ActionsSidebar
          hidden={selectedIds.length === 0 || !authState.user?.isAdmin}
        />
      </div>
      <div className={styles.minimizeButton}>
        <ButtonV3
          outline
          leftIconName={isOpen ? "chevron-left" : "chevron-right"}
          onClick={() => {
            graphDispatch({
              type: "CLEAR_SELECTED",
            });
            setIsOpen(!isOpen);
          }}
        />
      </div>
    </div>
  );
};

const FiltersSidebar = () => {
  const hasUsageFilter = useFeatureFlag(FeatureFlag.VisualizationUsageFilter);

  return (
    <div className={styles.sidebar({ type: "filters" })}>
      <Header type="column" text="Insights" />
      <p className={styles.title}>Visualize Access</p>
      <p className={styles.subtitle}>
        Select any number of users, groups, or resources.
      </p>
      <EntitySelector />
      <EntityDataElements />
      <Divider margin="lg" />

      <p className={styles.title}>Highlight</p>
      <AccessTypeFilter />
      {hasUsageFilter && <UsageFilter />}

      <UserDetailsFilter />
    </div>
  );
};

export default Sidebar;
