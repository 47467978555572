import { IdpConnectionType, useSetupStateQuery } from "api/generated/graphql";
import { resourceTypeInfoByType } from "components/label/ResourceTypeLabel";
import { Checkbox, DataElement, Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useContext } from "react";
import { useMountEffect } from "utils/hooks";
import { CONNECTIONS_LIST } from "views/idp/create/BrowseIdpServices";

import { getIdpConnectionSubtitle, ImportStep } from "../common";
import { SetupContext, useResolvedState } from "../SetupContext";
import * as styles from "./IdpStep.css";
import ImportProgress from "./ImportProgress";
import ImportSelectData from "./ImportSelectData";

const Import = () => {
  const [{ importStep }] = useResolvedState();

  if (importStep === ImportStep.SELECT_MODE) {
    return <ImportSelectMode />;
  } else if (importStep === ImportStep.SELECT_DATA) {
    return <ImportSelectData />;
  } else {
    return <ImportProgress />;
  }
};

const ImportSelectMode = () => {
  // Loading and error states are handled by the parent component
  const { data: setupData, refetch } = useSetupStateQuery();
  const { importMode, setImportMode } = useContext(SetupContext);

  const idpConnectionInfo = CONNECTIONS_LIST.find(
    (info) =>
      info.idpConnectionType === setupData?.setupState.state.idpConnectionType
  );

  useMountEffect(() => {
    refetch();
  });

  if (!setupData?.setupState.state || !idpConnectionInfo) {
    return null;
  }

  const syncedEntityName =
    setupData.setupState.state.connection?.name ?? "IdP Connection";
  const subtitle = getIdpConnectionSubtitle(
    setupData.setupState.state.connection?.metadata
  );

  return (
    <div className={styles.container}>
      <div className={styles.header}>Import data</div>
      <div className={sprinkles({ overflowY: "auto", marginBottom: "lg" })}>
        <p>
          Opal has successfully synced your Identity provider. You can now
          import data.
        </p>
        <div className={styles.connectionCard}>
          <div className={styles.cardTop}>
            <div className={styles.cardLogoContainer}>
              <img alt={syncedEntityName} src={idpConnectionInfo.logo} />
            </div>
            <div>
              <div
                className={sprinkles({
                  fontWeight: "semibold",
                  fontSize: "headlineSm",
                })}
              >
                {syncedEntityName}
              </div>
              <div className={sprinkles({ color: "gray700" })}>{subtitle}</div>
            </div>
          </div>
          <div className={styles.cardBottom}>
            <div className={styles.cardBottomSection}>
              <div className={styles.cardBottomNumber}>
                {setupData.setupState.state.entityCounts?.numUsers ?? 0}
              </div>
              <div>Users</div>
            </div>
            {idpConnectionInfo.idpConnectionType !==
              IdpConnectionType.Google && (
              <div className={styles.cardBottomSection}>
                <div className={styles.cardBottomNumber}>
                  {setupData.setupState.state.entityCounts?.numGroups ?? 0}
                </div>
                <div>Groups</div>
              </div>
            )}
            {setupData.setupState.state.entityCounts?.resourceCounts.map(
              (resourceCountByType) => {
                const label =
                  resourceTypeInfoByType[resourceCountByType.resourceType]
                    ?.name ?? resourceCountByType.resourceType;

                return (
                  <div className={styles.cardBottomSection}>
                    <div className={styles.cardBottomNumber}>
                      {resourceCountByType.count}
                    </div>
                    <div>{pluralize(label, resourceCountByType.count)}</div>
                  </div>
                );
              }
            )}
          </div>
        </div>
        <p className={sprinkles({ fontWeight: "semibold", marginTop: "xl" })}>
          How would you like to import data into Opal?
        </p>
        <p>
          Select data to import into Opal. You can import all data, or import
          data selectively (you'll be able to select and import more data in the
          future).
        </p>
        <div
          className={sprinkles({ display: "flex", marginTop: "lg", gap: "lg" })}
        >
          <div
            className={styles.tile({ selected: importMode === "all" })}
            onClick={() => setImportMode("all")}
          >
            <div className={styles.checkbox}>
              <DataElement
                color="green"
                label="Recommended"
                size="sm"
                disableHover
              />
              <Checkbox
                checked={importMode === "all"}
                size="sm"
                onChange={() => setImportMode("all")}
              />
            </div>
            <div className={styles.logoContainer}>
              <Icon name="arrow-square-down" size="lg" />
            </div>
            <div
              className={sprinkles({
                fontSize: "bodyLg",
                fontWeight: "medium",
              })}
            >
              Import all
            </div>
            <div
              className={sprinkles({ color: "gray700", fontSize: "bodySm" })}
            >
              Import all data into Opal.
            </div>
          </div>
          <div
            className={styles.tile({ selected: importMode === "selected" })}
            onClick={() => setImportMode("selected")}
          >
            <div className={styles.checkbox}>
              <Checkbox
                checked={importMode === "selected"}
                size="sm"
                onChange={() => setImportMode("selected")}
              />
            </div>
            <div className={styles.logoContainer}>
              <Icon name="check-list" size="lg" />
            </div>
            <div
              className={sprinkles({
                fontSize: "bodyLg",
                fontWeight: "medium",
              })}
            >
              Import a selection
            </div>
            <div
              className={sprinkles({ color: "gray700", fontSize: "bodySm" })}
            >
              Hand-select and import specific data.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Import;
