import {
  BundleGroupInput,
  GroupAccessLevel,
  GroupPreviewSmallFragment,
  useCreateBundleGroupsMutation,
  useGroupPreviewLazyQuery,
  useMultipleGroupAccessLevelsQuery,
} from "api/generated/graphql";
import GroupSearchDropdown, {
  mergeGroupSelectData,
  removeGroupSelectData,
} from "components/dropdown/GroupSearchDropdown";
import * as styles from "components/modals/AddItemModal.css";
import GroupRoleRow from "components/modals/GroupRoleRow";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { Modal, Skeleton } from "components/ui";
import { useState } from "react";
import { groupTypeHasRoles } from "utils/directory/groups";
import { logError } from "utils/logging";

interface Props {
  bundleId: string;
  onClose: () => void;
  existingRolesByGroupId: Record<string, GroupAccessLevel[]>;
}

const BundleAddGroupsModal = (props: Props) => {
  const [roleByGroupIdToAdd, setRoleByGroupIdToAdd] = useState<
    Record<string, GroupAccessLevel[]>
  >({});
  const [error, setError] = useState("");
  const [rowErrors, setRowErrors] = useState<Record<string, string>>({}); // key is group id

  const { displaySuccessToast } = useToast();
  const [createBundleGroups, { loading }] = useCreateBundleGroupsMutation();

  const [getGroupInfo] = useGroupPreviewLazyQuery();
  const {
    data,
    error: rolesError,
    loading: loadingRoles,
  } = useMultipleGroupAccessLevelsQuery({
    variables: {
      input: {
        groupIds: Object.keys(props.existingRolesByGroupId),
      },
    },
  });

  if (loadingRoles) {
    return (
      <Modal title="Add Groups" isOpen onClose={props.onClose}>
        <Modal.Body>
          <Skeleton height="48px" />
        </Modal.Body>
      </Modal>
    );
  }

  if (rolesError) {
    return (
      <Modal title="Add Groups" isOpen onClose={props.onClose}>
        <Modal.Body>
          <ModalErrorMessage errorMessage={rolesError.message} />
        </Modal.Body>
      </Modal>
    );
  }

  let allRoles;
  switch (data?.multipleGroupAccessLevels.__typename) {
    case "MultipleGroupAccessLevelsResult":
      allRoles = data.multipleGroupAccessLevels.results;
  }

  const allRolesByGroupId: Record<string, GroupAccessLevel[]> = {};
  allRoles?.forEach((role) => {
    allRolesByGroupId[role.groupId] = role.accessLevels ?? [];
  });

  const handleSubmit = async () => {
    try {
      const groupInputs: BundleGroupInput[] = [];
      const newRowErrors: Record<string, string> = {};
      for (const [groupId, roles] of Object.entries(roleByGroupIdToAdd)) {
        const { data } = await getGroupInfo({
          variables: {
            input: {
              id: groupId,
            },
          },
        });
        let group: GroupPreviewSmallFragment | undefined;
        switch (data?.group.__typename) {
          case "GroupResult":
            group = data.group.group;
            break;
        }
        if (!group) {
          setError("Failed to add groups to bundle");
          return;
        }

        if (roles.length === 0) {
          // If group requires a role, but none are selected,
          // show an error.
          if (groupTypeHasRoles(group.groupType)) {
            newRowErrors[groupId] = "Please select at least one role";
          } else {
            // If group does not require roles,
            // add an empty role to add the group directly.
            groupInputs.push({
              bundleId: props.bundleId,
              groupId,
              accessLevelName: "",
              accessLevelRemoteId: "",
            });
          }
        }

        roles.forEach((role) => {
          groupInputs.push({
            bundleId: props.bundleId,
            groupId,
            accessLevelName: role.accessLevelName,
            accessLevelRemoteId: role.accessLevelRemoteId,
          });
        });
      }

      if (Object.keys(newRowErrors).length > 0) {
        setRowErrors(newRowErrors);
        return;
      }

      const { data } = await createBundleGroups({
        variables: {
          input: {
            inputs: groupInputs,
          },
        },
        refetchQueries: ["BundleItems", "Bundle"],
      });

      if (data?.createBundleGroups.bundleGroups) {
        displaySuccessToast("Success: groups added to bundle");
        props.onClose();
      }
    } catch (err) {
      logError(err, "Failed to add groups to bundle");
      setError("Failed to add groups to bundle");
    }
  };

  // A user can only be added to a group with 1 role, so disable selecting
  // a group if it's already been added to the bundle with any role.
  const disabledGroupIds: string[] = Object.keys(props.existingRolesByGroupId);

  return (
    <Modal isOpen title="Add Groups" onClose={props.onClose}>
      <Modal.Body>
        {error && <ModalErrorMessage errorMessage={error} />}
        <GroupSearchDropdown
          style="search"
          selectedGroupIds={Object.keys(roleByGroupIdToAdd)}
          onSelect={({ actionType, groups }) => {
            setRoleByGroupIdToAdd((prev) => {
              if (actionType === "select-option") {
                return mergeGroupSelectData(prev, groups);
              } else {
                return removeGroupSelectData(prev, groups);
              }
            });
          }}
          disabledGroupIds={disabledGroupIds}
        />
        <div className={styles.itemsContainer}>
          {Object.keys(roleByGroupIdToAdd).map((groupId) => {
            return (
              <GroupRoleRow
                key={groupId}
                groupId={groupId}
                existingRoles={props.existingRolesByGroupId[groupId] ?? []}
                roles={roleByGroupIdToAdd[groupId] ?? []}
                error={rowErrors[groupId]}
                onGroupRemove={() => {
                  setRoleByGroupIdToAdd((prev) => {
                    delete prev[groupId];
                    return { ...prev };
                  });
                }}
                onRoleSelect={(role) => {
                  setRoleByGroupIdToAdd((prev) => {
                    if (groupId in prev) {
                      return {
                        ...prev,
                        [groupId]: [...prev[groupId], role],
                      };
                    } else {
                      return {
                        ...prev,
                        [groupId]: [role],
                      };
                    }
                  });
                }}
                onRoleRemove={(role) => {
                  setRoleByGroupIdToAdd((prev) => {
                    return {
                      ...prev,
                      [groupId]: prev[groupId].filter(
                        (a) =>
                          a.accessLevelRemoteId !== role.accessLevelRemoteId
                      ),
                    };
                  });
                }}
              />
            );
          })}
        </div>
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Submit"
        primaryButtonDisabled={Object.keys(roleByGroupIdToAdd).length === 0}
        onPrimaryButtonClick={handleSubmit}
        primaryButtonLoading={loading}
      />
    </Modal>
  );
};

export default BundleAddGroupsModal;
