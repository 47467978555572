import { TooltipPlacement } from "components/label/Label";
import { Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { FC } from "react";

import Tooltip from "../tooltip/Tooltip";
import * as styles from "./FormRow.css";

interface Props {
  title: string;
  disabled?: boolean;
  tooltipText?: string;
  showToolTipIcon?: boolean;
}

const FormRow: FC<Props> = (props) => {
  const { disabled } = props;

  let titleContent = <>{props.title}</>;
  if (props.tooltipText && !props.showToolTipIcon) {
    titleContent = (
      <div className={styles.titleTooltipWrapper}>
        <Tooltip arrow tooltipText={props.tooltipText}>
          {props.title}
        </Tooltip>
      </div>
    );
  } else if (props.tooltipText && props.showToolTipIcon) {
    titleContent = (
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "center",
          gap: "xs",
        })}
      >
        {props.title}
        <Tooltip
          tooltipText={props.tooltipText}
          placement={TooltipPlacement.Right}
        >
          <Icon name="info" size="xs" color="gray900" />
        </Tooltip>
      </div>
    );
  }

  return (
    <div className={styles.container({ disabled })}>
      <div className={styles.title}>{titleContent}</div>
      <div className={styles.content}>{props.children}</div>
    </div>
  );
};

export default FormRow;
