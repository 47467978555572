import { useKBar, VisualState } from "kbar";

export function useSpotlightSearch() {
  const { searchQuery } = useKBar((state) => {
    return {
      searchQuery: state.searchQuery,
    };
  });
  const { query } = useKBar();
  const setShowSpotlightSearch = (showSpotlightSearch: boolean) => {
    if (showSpotlightSearch) {
      query.setVisualState(VisualState.animatingIn);
    } else {
      query.setVisualState(VisualState.animatingOut);
    }
  };
  return {
    setShowSpotlightSearch,
    searchQuery,
  };
}

interface BaseKBarItem {
  command: {
    perform: () => void;
  };
}

interface KBarSeeAllItem extends BaseKBarItem {
  type: "see-all";
}

export interface KBarResultItem extends BaseKBarItem {
  type: "result";
  icon: JSX.Element;
  label: string;
  subText: string;
  rightText: string;
}

interface KBarNoResults extends BaseKBarItem {
  type: "no-results";
}

interface KBarSection extends BaseKBarItem {
  type: "section";
  name: string;
}

export type KBarItem =
  | KBarResultItem
  | KBarSeeAllItem
  | KBarNoResults
  | KBarSection;
