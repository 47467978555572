import { Maybe } from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { Input, Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useContext, useState } from "react";

type DeleteOrgModalProps = {
  isModalOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  loading?: boolean;
  errorMessage?: Maybe<string>;
};

export const DeleteOrgModal = (props: DeleteOrgModalProps) => {
  const [inputConfirmationState, setInputConfirmationState] = useState("");
  const { authState } = useContext(AuthContext);

  const inputConfirmation = `Delete ${authState.user?.user.organization.name}`;

  return (
    <Modal
      isOpen={props.isModalOpen}
      onClose={props.onClose}
      title="Delete organization"
    >
      <Modal.Body>
        <p className={sprinkles({ marginBottom: "md" })}>
          To permanently delete this organization, type{" "}
          <span className={sprinkles({ fontWeight: "semibold" })}>
            Delete {authState.user?.user.organization.name}
          </span>{" "}
          below. This action is{" "}
          <span
            className={sprinkles({ fontWeight: "semibold", color: "red600" })}
          >
            irreversible
          </span>
          .
        </p>
        <Input
          placeholder={""}
          onChange={setInputConfirmationState}
          value={inputConfirmationState}
        />
        {props.errorMessage && (
          <ModalErrorMessage errorMessage={props.errorMessage} />
        )}
      </Modal.Body>
      <Modal.Footer
        secondaryButtonLabel="Cancel"
        onSecondaryButtonClick={props.onClose}
        primaryButtonType="error"
        primaryButtonLabel="Delete"
        onPrimaryButtonClick={props.onSubmit}
        primaryButtonLoading={props.loading}
        primaryButtonDisabled={inputConfirmation !== inputConfirmationState}
      />
    </Modal>
  );
};
