import { TextField } from "@material-ui/core";
import { Banner } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React, { useEffect, useState } from "react";

const normalizeNumberField = (
  value: number,
  setValue: (newValue: number) => void
) => {
  if (isNaN(value)) {
    setValue(0);
  }
};

type CustomDurationPickerProps = {
  durationInMinutes: number;
  setDurationInMinutes: (minutes: number) => void;
  maxValue?: number;
  maxValueReason?: string;
};

export const CustomDurationPicker = (props: CustomDurationPickerProps) => {
  let { durationInMinutes, setDurationInMinutes } = props;
  const [days, setDays] = useState(Math.floor(durationInMinutes / (24 * 60)));
  const [hours, setHours] = useState(
    Math.floor(durationInMinutes / 60) - days * 24
  );
  const [minutes, setMinutes] = useState(durationInMinutes % 60);

  useEffect(() => {
    setDays(Math.floor(durationInMinutes / (24 * 60)));
    setHours(
      Math.floor(durationInMinutes / 60) -
        Math.floor(durationInMinutes / (24 * 60)) * 24
    );
    setMinutes(durationInMinutes % 60);
  }, [durationInMinutes]);

  const handleCustomExpirationFieldChange = (
    event: React.ChangeEvent<{ value: string }>,
    setFunction: (newVal: number) => void
  ) => {
    const newVal = parseInt(event.target.value);
    setFunction(newVal);
  };
  const handleOnBlur = (value: number, setValue: (newVal: number) => void) => {
    // first return field to valid state if needed
    normalizeNumberField(value, setValue);

    // recalculate duration
    let totalMinutes = isNaN(minutes) ? 0 : minutes;
    totalMinutes += isNaN(hours) ? 0 : 60 * hours;
    totalMinutes += isNaN(days) ? 0 : 24 * 60 * days;
    setDurationInMinutes(totalMinutes);
  };

  const showExceedsMaxWarning =
    props.maxValue && durationInMinutes > props.maxValue;

  return (
    <div>
      <TextField
        label="Days"
        value={days}
        onChange={(event) => handleCustomExpirationFieldChange(event, setDays)}
        onBlur={() => handleOnBlur(days, setDays)}
        type="number"
        style={{ width: "32%", marginRight: "2%" }}
        inputProps={{ min: 0 }}
      />
      <TextField
        label="Hours"
        value={hours}
        onChange={(event) => handleCustomExpirationFieldChange(event, setHours)}
        onBlur={() => handleOnBlur(hours, setHours)}
        type="number"
        style={{ width: "32%", marginRight: "2%" }}
        inputProps={{ min: 0 }}
      />
      <TextField
        label="Minutes"
        value={minutes}
        onChange={(event) =>
          handleCustomExpirationFieldChange(event, setMinutes)
        }
        onBlur={() => handleOnBlur(minutes, setMinutes)}
        type="number"
        style={{ width: "32%" }}
        inputProps={{ min: 0 }}
      />
      {showExceedsMaxWarning && (
        <div className={sprinkles({ marginTop: "sm" })}>
          <Banner
            type="error"
            message={props.maxValueReason ?? "Exceeds maximum allowed"}
          />
        </div>
      )}
    </div>
  );
};
