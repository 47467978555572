import { useSpotlightSearch } from "components/spotlight_search/utils";
import { Icon } from "components/ui";
import React from "react";
import useLogEvent from "utils/analytics";

import RowBar from "./RowBar";
import * as styles from "./TopBar.css";

const TopBar = () => {
  const { setShowSpotlightSearch } = useSpotlightSearch();
  const logEvent = useLogEvent();

  return (
    <RowBar padding="xs">
      <button
        className={styles.inputContainer}
        onClick={() => {
          logEvent({
            name: "k_bar_search_open",
            properties: {
              entryPoint: "click",
            },
          });
          setShowSpotlightSearch(true);
        }}
      >
        <Icon name="search" size="xs" />
        Search for resources, groups, users...
      </button>
    </RowBar>
  );
};

export default TopBar;
