import { EntityViewerRow } from "components/entity_viewer/EntityViewer";
import { Icon } from "components/ui";
import sprinkles from "css/sprinkles.css";
import React from "react";

type EmptyEntityViewerRowProps = {
  rowTitle: string;
};

export const EmptyEntityViewerRow = (props: EmptyEntityViewerRowProps) => {
  return (
    <EntityViewerRow
      title={props.rowTitle}
      content={<span style={{ fontStyle: "italic" }}>Could not load</span>}
    />
  );
};

export const getDeletedIcon = () => {
  return (
    <div className={sprinkles({ color: "yellow600" })}>
      <Icon size="sm" name="alert-circle" />
    </div>
  );
};

export const getDeletedTooltip = (entityType: string) => {
  return `This ${entityType} existed when the access review started, but has since been deleted.`;
};
