import { Maybe } from "api/generated/graphql";
import { ToastStyle, useToast } from "components/toast/Toast";
import sprinkles from "css/sprinkles.css";
import _ from "lodash";
import { useEffect } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { RequestState } from "utils/common";

const excludedRoutes = new Set(["/sign-out"]);

/**
 * Adds URL params to `url` specifying a toast that should be displayed by when the page
 * is loaded. This toast is rendered by `ToastUrlParser`.
 */
export const getToastUrl = (
  url: string,
  status: RequestState,
  messageCode?: Maybe<MessageCode>,
  style?: ToastStyle,
  nextUrl?: string,
  additionalParams?: Record<string, string>
) => {
  let result = `${url}?status=${status}`;
  if (style) {
    result += `&style=${style}`;
  }
  if (messageCode) {
    result += `&messageCode=${messageCode}`;
  }
  if (nextUrl) {
    result += `&next=${nextUrl}`;
  }
  if (additionalParams) {
    Object.entries(additionalParams).forEach(
      ([key, value]) => (result += `&${key}=${value}`)
    );
  }
  return result;
};

/**
 * Displays a toast on the page if the URL params indicate that a toast should be displayed.
 */
export const ToastUrlParser = () => {
  const history = useHistory();
  const location = useLocation();

  const queryParams = new URLSearchParams(location.search);

  const statusParam = queryParams.get("status");
  const messageCodeParam = queryParams.get("messageCode");
  const styleParam = queryParams.get("style");
  const nextParam = queryParams.get("next");

  const status = statusParam
    ? (Number(statusParam) as RequestState)
    : undefined;
  const messageCode = statusParam
    ? (messageCodeParam as MessageCode)
    : undefined;
  const message = getMessageForToast(messageCode, status, queryParams);
  const style = styleParam ? (styleParam as ToastStyle) : undefined;

  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
    displayCustomToast,
  } = useToast(style);

  useEffect(() => {
    if (status !== undefined && !excludedRoutes.has(location.pathname)) {
      switch (status) {
        case RequestState.Loading:
          displayLoadingToast();
          break;
        case RequestState.Success:
          if (message) {
            displaySuccessToast(message);
          }
          break;
        case RequestState.Warning:
          if (message) {
            displayCustomToast(message, "log-out");
          }
          break;
        case RequestState.Failure:
          if (message) {
            displayErrorToast(message);
          }
          break;
      }
      history.replace(
        nextParam
          ? history.location.pathname + `?next=${encodeURIComponent(nextParam)}`
          : history.location.pathname
      );
    }
  }, [
    displayErrorToast,
    displayLoadingToast,
    displaySuccessToast,
    displayCustomToast,
    history,
    location.pathname,
    message,
    status,
    nextParam,
  ]);

  return null;
};

export enum MessageCode {
  ErrorNotInvitedSignIn = "ERR_NOT_INVITED",
  ErrorAuthSessionInvalid = "ERR_AUTH_SESSION_INVALID",
  ErrorAuthSessionNotGated = "ERR_AUTH_SESSION_NOT_GATED",
  ErrorLoginRequired = "ERR_LOGIN_REQUIRED",
  ErrorMfaResetRequired = "ERR_MFA_RESET_REQUIRED",
  ErrorAuthFlowFailure = "ERR_AUTH_FLOW",
  ErrorSamlNotConfiguredForOrg = "ERR_SAML_NOT_CONFIGURED_FOR_ORG",
  ErrorSamlLoginRequired = "ERR_SAML_LOGIN_REQUIRED",
  ErrorUserAlreadyExistsInAnotherOrg = "ERR_USER_ALREADY_EXISTS_IN_ANOTHER_ORG",
  SuccessOrganizationDeleted = "SUCCESS_ORG_DELETED",
}

const getMessageForToast = (
  code?: MessageCode,
  status?: RequestState,
  queryParams?: URLSearchParams
) => {
  let message: string | JSX.Element = "";
  switch (code) {
    case MessageCode.ErrorNotInvitedSignIn: {
      const email = queryParams?.get("email");
      if (email) {
        message = `account not found for user '${email}'. Please ask your admin for an invite.`;
      } else {
        message =
          "account not found for user. Please ask your admin for an invitation.";
      }
      break;
    }
    case MessageCode.ErrorAuthSessionInvalid:
      message =
        "auth session has expired. Please log back in to continue using Opal.";
      break;
    case MessageCode.ErrorAuthSessionNotGated:
      message = (
        <>
          {
            "Unfortunately, it looks like you are not gated to use Opal! Please contact your Opal admin or "
          }
          <a
            href="https://opal.dev/demo"
            target="_blank"
            className={sprinkles({
              textDecoration: "underline",
            })}
          >
            find time with an Opal expert.
          </a>
        </>
      );
      break;
    case MessageCode.ErrorLoginRequired:
      message = "user must sign in again to proceed";
      break;
    case MessageCode.ErrorMfaResetRequired:
      message = "please contact your Opal Admin to reset your MFA";
      break;
    case MessageCode.ErrorAuthFlowFailure:
      message = "auth flow failed unexpectedly";
      break;
    case MessageCode.ErrorSamlNotConfiguredForOrg:
      message =
        "SAML is not configured for the org associated with this email domain";
      break;
    case MessageCode.ErrorSamlLoginRequired:
      message = "your organization requires all logins to be done via SAML";
      break;
    case MessageCode.ErrorUserAlreadyExistsInAnotherOrg:
      message = "user already exists in another organization";
      break;
    case MessageCode.SuccessOrganizationDeleted:
      message = "organization deleted";
      break;
  }

  switch (status) {
    case RequestState.Failure:
      if (message) {
        message = typeof message === "string" ? `Error: ${message}` : message;
      } else {
        message = "Operation failed unexpectedly";
      }
      break;
    case RequestState.Warning:
      if (typeof message === "string") {
        message = `${_.upperFirst(message)}`;
      }
      break;
    case RequestState.Success:
      if (message) {
        message = `Success: ${message}`;
      } else {
        message = "Operation completed successfully";
      }
      break;
    default:
      message = "Complete";
  }

  return message;
};
