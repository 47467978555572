import { ConnectionApp, OktaResourceApp } from "api/generated/graphql";
import { EntityIcon, Icon } from "components/ui";

import { getAppIcon } from "./utils";

interface Props {
  app:
    | Pick<ConnectionApp, "__typename" | "connectionType" | "connectionIconUrl">
    | Pick<OktaResourceApp, "__typename" | "iconUrl">;
  iconSize?: PropsFor<typeof Icon>["size"];
  iconStyle?: PropsFor<typeof Icon>["iconStyle"];
}

const AppIcon = (props: Props) => {
  const iconData = getAppIcon(props.app);

  if (iconData.type === "entity") {
    return <EntityIcon type={iconData.entityType} size={props.iconSize} />;
  }
  return (
    <Icon data={iconData} size={props.iconSize} iconStyle={props.iconStyle} />
  );
};

export default AppIcon;
