import {
  KBarAnimator,
  KBarPortal,
  KBarPositioner,
  KBarProvider,
  KBarSearch,
} from "kbar";
import React from "react";
import useLogEvent from "utils/analytics";

import SearchResults from "./SearchResults";
import styles from "./SpotlightSearch.module.scss";

const SpotlightSearchProvider: React.FC<{}> = (props) => {
  const logEvent = useLogEvent();
  return (
    <KBarProvider
      options={{
        callbacks: {
          onOpen: () => {
            logEvent({
              name: "k_bar_search_open",
              properties: {
                entryPoint: "keyboard",
              },
            });
          },
        },
      }}
    >
      <KBarPortal>
        <KBarPositioner className={styles.positioner}>
          <KBarAnimator className={styles.animator}>
            <KBarSearch
              defaultPlaceholder="Search..."
              className={styles.search}
            />
            <SearchResults />
          </KBarAnimator>
        </KBarPositioner>
      </KBarPortal>
      {props.children}
    </KBarProvider>
  );
};

export default SpotlightSearchProvider;
