import { GroupAccessLevel, ResourceAccessLevel } from "api/generated/graphql";
import { TooltipPlacement } from "components/label/Label";
import { Tooltip } from "components/ui";
import sprinkles from "css/sprinkles.css";

type RoleLabelProps = {
  roles: GroupAccessLevel[] | ResourceAccessLevel[];
  includeLabel?: boolean;
};

export const RoleLabel = ({ roles, includeLabel = true }: RoleLabelProps) => {
  if (roles.length < 1) {
    return <></>;
  }

  const hasMultipleRoles = roles.length > 1;
  const roleNames = [] as string[];
  roles.forEach((r) => {
    if (r.accessLevelName.length > 0) {
      roleNames.push(r.accessLevelName);
    }
  });

  if (roleNames.length < 1) {
    return <></>;
  }

  const buildLabel = () => {
    let roleLabel = "";
    if (roleNames.length === 1) {
      roleLabel = `${roleNames[0]}`;
    } else {
      roleLabel = `${roleNames[0]}, +${roleNames.length - 1} more`;
    }

    return includeLabel ? `Role: ${roleLabel}` : roleLabel;
  };

  const buildToolTipText = () => {
    if (roleNames.length <= 1) {
      return;
    }
    if (roleNames.length === 2) {
      return `${roleNames[0]} and ${roleNames[1]}`;
    }

    let tooltip = [];
    for (let i = 0; i < roleNames.length - 1; i++) {
      tooltip.push(`${roleNames[i]}, `);
    }
    tooltip.push(`and ${roleNames[roleNames.length - 1]}`);
    return tooltip;
  };

  return (
    <div
      className={sprinkles({
        display: "flex",
        alignItems: "center",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      })}
    >
      {hasMultipleRoles ? (
        <Tooltip
          tooltipText={buildToolTipText()}
          placement={TooltipPlacement.Top}
          arrow
        >
          {buildLabel()}
        </Tooltip>
      ) : (
        <div>{buildLabel()}</div>
      )}
    </div>
  );
};
