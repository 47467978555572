import { EntityType, ReviewStageOperator } from "api/generated/graphql";
import {
  Button,
  ButtonGroup,
  DataElement,
  DataElementList,
  Icon,
  Select,
  Skeleton,
} from "components/ui";
import sprinkles from "css/sprinkles.css";
import React from "react";
import { getResourceUrlNew } from "utils/common";
import { useAllOwnersQuery } from "utils/hooks";
import { logError } from "utils/logging";

import * as styles from "./ApprovalsEditorStage.css";
import { GenericReviewer } from "./generic_reviewers";
import { getNumApproversInStage, getOperatorDisplayName } from "./utils";

interface ApprovalsEditorStageProps {
  stageNumber: number;
  requireManagerApproval: boolean;
  requireAdminApproval: boolean;
  setGenericReviewer: (
    reviewerRequired: GenericReviewer,
    value: boolean
  ) => void;
  showGenericReviewerButtons: Record<GenericReviewer, boolean>;
  ownerIds: string[];
  setOwnerIds: (ownerIds: string[]) => void;
  excludedOwnerIds: string[];
  operator: ReviewStageOperator;
  setOperator: (operator: ReviewStageOperator) => void;
  onDelete: () => void;
  editView: boolean;
}

const ApprovalsEditorStage = (props: ApprovalsEditorStageProps) => {
  const { data, error, loading } = useAllOwnersQuery({
    variables: {
      input: { includeAll: true },
    },
  });
  if (error) {
    logError(error, "failed to list owners");
  }

  if (loading) {
    return <Skeleton />;
  }

  const {
    ownerIds,
    requireManagerApproval,
    requireAdminApproval,
    excludedOwnerIds,
  } = props;

  const allOwners = data?.owners.owners || [];
  const filteredOwners = allOwners.filter(
    (allOwner) =>
      excludedOwnerIds.find(
        (excludedOwnerId) => excludedOwnerId === allOwner.id
      ) === undefined
  );

  const numApproversInStage = getNumApproversInStage(ownerIds, {
    [GenericReviewer.Manager]: requireManagerApproval,
    [GenericReviewer.Admin]: requireAdminApproval,
  });

  if (!props.editView) {
    return (
      <div className={styles.container}>
        <div className={styles.stageTitle}>Stage {props.stageNumber}</div>
        <div>
          {numApproversInStage >= 2 ? (
            <>
              Require{" "}
              <span className={sprinkles({ fontWeight: "semibold" })}>
                {getOperatorDisplayName(props.operator)}
              </span>{" "}
              of the following reviewers:
            </>
          ) : null}
        </div>
        <DataElementList>
          {ownerIds.map((ownerId) => (
            <DataElement
              key={ownerId}
              label={allOwners.find((o) => o.id === ownerId)?.name || ""}
              color="green"
              size="sm"
              link={getResourceUrlNew(
                {
                  entityId: ownerId,
                  entityType: EntityType.Owner,
                },
                EntityType.Owner
              )}
            />
          ))}
          {requireManagerApproval ? (
            <DataElement
              key="manager"
              label="Requester's manager"
              color="green"
              size="sm"
            />
          ) : null}
          {requireAdminApproval ? (
            <DataElement
              key="admin"
              label="Entity's admin"
              color="green"
              size="sm"
            />
          ) : null}
        </DataElementList>
      </div>
    );
  }

  return (
    <div className={styles.container}>
      <div className={styles.closeButton} onClick={props.onDelete}>
        <Icon name="x" size="xs" />
      </div>
      <div className={styles.stageTitle}>Stage {props.stageNumber}</div>
      {numApproversInStage >= 2 ? (
        <div className={styles.operatorContainer}>
          Require{" "}
          <ButtonGroup>
            <Button
              label={getOperatorDisplayName(ReviewStageOperator.And)}
              size="sm"
              type={props.operator === "AND" ? "primary" : undefined}
              outline={props.operator !== "AND"}
              onClick={() => props.setOperator(ReviewStageOperator.And)}
            />
            <Button
              label={getOperatorDisplayName(ReviewStageOperator.Or)}
              size="sm"
              onClick={() => props.setOperator(ReviewStageOperator.Or)}
              type={props.operator === "OR" ? "primary" : undefined}
              outline={props.operator !== "OR"}
            />
          </ButtonGroup>
          of the following reviewers:
        </div>
      ) : null}
      <div className={sprinkles({ marginBottom: "xs" })}>
        <Select
          options={filteredOwners}
          getOptionLabel={(option) => option.name}
          onChange={(owner) => {
            if (!owner) return;
            props.setOwnerIds([...ownerIds, owner.id]);
          }}
          placeholder="Select owners"
          loading={loading}
          selectOnly
        />
      </div>
      {numApproversInStage == 0 ? "No reviewers selected" : null}
      <DataElementList>
        {ownerIds.map((ownerId) => (
          <DataElement
            key={ownerId}
            label={allOwners.find((o) => o.id === ownerId)?.name || ""}
            rightIcon={{
              name: "x",
              onClick: () => {
                props.setOwnerIds(ownerIds.filter((o) => o !== ownerId));
              },
            }}
            size="sm"
            color="green"
            link={getResourceUrlNew(
              {
                entityId: ownerId,
                entityType: EntityType.Owner,
              },
              EntityType.Owner
            )}
          />
        ))}
        {/* The reason we need requireXApproval and showXButton is because this component is only single stage aware
          We use the requireXApproval field to determine if this stage has the reviewer already set
          For showGenericXButton, just because requierXApproval isn't set for this stage doesn't mean it isn't set on another
          so we need to tell this component how to render both!
        */}
        {requireManagerApproval ? (
          <DataElement
            key="manager"
            label="Requester's manager"
            rightIcon={{
              name: "x",
              onClick: () => {
                props.setGenericReviewer(GenericReviewer.Manager, false);
              },
            }}
            size="sm"
            color="green"
          />
        ) : null}
        {props.showGenericReviewerButtons[GenericReviewer.Manager] ? (
          <Button
            key="manager"
            leftIconName="plus"
            label="Add the Requester's manager"
            borderless
            onClick={() => {
              props.setGenericReviewer(GenericReviewer.Manager, true);
            }}
            size="sm"
          />
        ) : null}
        {requireAdminApproval ? (
          <DataElement
            key="admin"
            label="Entity's admin"
            rightIcon={{
              name: "x",
              onClick: () => {
                props.setGenericReviewer(GenericReviewer.Admin, false);
              },
            }}
            size="sm"
            color="green"
          />
        ) : null}
        {props.showGenericReviewerButtons[GenericReviewer.Admin] ? (
          <Button
            key="admin"
            leftIconName="plus"
            label="Add this entity's admin"
            borderless
            onClick={() => {
              props.setGenericReviewer(GenericReviewer.Admin, true);
            }}
            size="sm"
          />
        ) : null}
      </DataElementList>
    </div>
  );
};

export default ApprovalsEditorStage;
