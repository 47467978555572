import { gql, useMutation } from "@apollo/client";
import {
  IdpConnectionUserAttributeUseAs,
  ReorderIdpUserAttributePrioritiesMutation,
} from "api/generated/graphql";
import { ConnectionType } from "api/generated/graphql";
import { getConnectionTypeInfo } from "components/label/ConnectionTypeLabel";
import { Label } from "components/ui";
import React from "react";
import { getUserAttributeUseAsOptionLabel } from "utils/user_attribute";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import DragBubbles from "../../../components/ui/dragbubbles/DragBubbles";
import * as styles from "./AttributeIdpOrder.css";

export type IdpConnectionRow = {
  id: string;
  name: string;
  connectonType: ConnectionType;
};

export interface AttributeIDPOrderProps {
  attribute: IdpConnectionUserAttributeUseAs;
  idpConnections: IdpConnectionRow[];
}

export const AttributeIdpOrder = (props: AttributeIDPOrderProps) => {
  const [connectionOrder, setConnectionOrder] = React.useState<
    IdpConnectionRow[]
  >(props.idpConnections);

  const [
    updateOrder,
    { error: mutationError },
  ] = useMutation<ReorderIdpUserAttributePrioritiesMutation>(gql`
    mutation ReorderIdpUserAttributePriorities(
      $input: ReorderUserAttributePriorityInput!
    ) {
      reorderUserAttributePriority(input: $input) {
        __typename
        ... on ReorderUserAttributePriorityOutput {
          useAs
          idpConnections {
            id
            connection {
              name
              connectionType
            }
          }
        }
      }
    }
  `);

  const updateConnectionOrder = (newOrder: IdpConnectionRow[]) => {
    updateOrder({
      variables: {
        input: {
          useAs: props.attribute,
          idpConnectionIds: newOrder.map((row) => row.id),
        },
      },
    });
    setConnectionOrder(newOrder);
  };

  if (mutationError) {
    return <UnexpectedErrorPage />;
  }

  const items = connectionOrder.map((row, i) => {
    return {
      id: row.id,
      priorityLabel: i,
      titleContent: (
        <>
          <Label
            label={row.name}
            icon={{
              type: "src",
              icon: getConnectionTypeInfo(row.connectonType)?.icon,
            }}
          />
        </>
      ),
      isDragDisabled: false,
    };
  });

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        {getUserAttributeUseAsOptionLabel(props.attribute)}
      </div>
      <DragBubbles
        slimBubbles
        handleDragEnd={(result) => {
          if (
            result.destination?.index === undefined ||
            result.destination?.index === result.source.index
          ) {
            return;
          }

          const newOrder = [...connectionOrder];
          const [reorderedItem] = newOrder.splice(result.source.index, 1);
          newOrder.splice(result.destination.index, 0, reorderedItem);
          updateConnectionOrder(newOrder);
        }}
        isDragDisabled={false}
        items={items}
      />
    </div>
  );
};
