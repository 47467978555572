import {
  AccessOption,
  AppItemsSortByField,
  EntityType,
  GroupType,
  ResourceType,
  SortDirection,
  useItemsListSectionImportQuery,
} from "api/generated/graphql";
import ColumnListItem, {
  ColumnListItemsSkeleton,
} from "components/column/ColumnListItem";
import ColumnListScroller from "components/column/ColumnListScroller";
import { ColumnSearchAndSort } from "components/column/ColumnSearchAndSort";
import { useToast } from "components/toast/Toast";
import { Icon } from "components/ui";
import { IconData } from "components/ui/utils";
import { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router";
import { getResourceUrlNew } from "utils/common";
import { logError } from "utils/logging";
import { useTransitionTo, useURLSearchParam } from "utils/router/hooks";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";

import {
  ACCESS_OPTION_URL_KEY,
  ACCOUNT_ID_URL_KEY,
  APP_ID_URL_KEY,
  AppsContext,
  ITEM_TYPE_URL_KEY,
  OKTA_APP_ID_URL_KEY,
  SelectedUnmanagedItem,
} from "./AppsContext";
import { useAccessOptionKey } from "./utils";

interface Props {
  appId: string;
  accountId?: string;
}

export interface SortOption {
  label: string;
  value: { field: AppItemsSortByField; direction: SortDirection };
}

const SORT_OPTIONS: SortOption[] = [
  {
    label: "Name (A-Z)",
    value: {
      field: AppItemsSortByField.Name,
      direction: SortDirection.Asc,
    },
  },
  {
    label: "Name (Z-A)",
    value: {
      field: AppItemsSortByField.Name,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Newest first",
    value: {
      field: AppItemsSortByField.CreatedAt,
      direction: SortDirection.Desc,
    },
  },
  {
    label: "Oldest first",
    value: {
      field: AppItemsSortByField.CreatedAt,
      direction: SortDirection.Asc,
    },
  },
];

const ItemsListSectionImport = (props: Props) => {
  const {
    appDetailState,
    setAppDetailState,
    selectedUnmanagedItems,
    selectUnmanagedItem,
    toggleUnmanagedItem,
    selectUnmanagedItems,
    clearUnmanagedItem,
    clearUnmanagedItems,
  } = useContext(AppsContext);
  const [accessOptionKey] = useAccessOptionKey();

  const history = useHistory();

  const transitionTo = useTransitionTo({
    preserveQueries: [
      ACCESS_OPTION_URL_KEY,
      APP_ID_URL_KEY,
      ITEM_TYPE_URL_KEY,
      OKTA_APP_ID_URL_KEY,
      ACCOUNT_ID_URL_KEY,
    ],
  });
  const { resourceId, groupId, oktaAppId, accountId } = useParams<
    Record<string, string>
  >();
  const [searchQuery, setSearchQuery] = useState<string>(
    appDetailState?.searchQuery || ""
  );
  const [sortOption, setSortOption] = useState(
    appDetailState?.sortOption || SORT_OPTIONS[0]
  );
  const [selectedItemType, setSelectedItemType] = useURLSearchParam(
    ITEM_TYPE_URL_KEY
  );
  const { displayErrorToast } = useToast();
  const [lastClicked, setLastClicked] = useState<number | null>(null);

  const {
    data,
    loading,
    error,
    fetchMore,
    previousData,
  } = useItemsListSectionImportQuery({
    variables: {
      id: props.appId,
      itemType: selectedItemType,
      access: accessOptionKey,
      parentResourceId: props.accountId
        ? { resourceId: props.accountId }
        : null,
      searchQuery,
      sortBy: sortOption.value,
    },
    notifyOnNetworkStatusChange: true,
  });

  const app = data?.app.__typename === "App" ? data.app : null;
  const previousApp =
    previousData?.app.__typename === "App" ? previousData.app : null;

  // set lastClicked index to null when data changes
  useEffect(() => {
    setLastClicked(null);
  }, [app?.items?.items]);

  const cursor = app?.items.cursor;
  const loadMore = cursor
    ? async () => {
        await fetchMore({
          variables: {
            cursor,
          },
        });
      }
    : undefined;

  const selectAll = async () => {
    let thisCursor = cursor;
    app?.items.items?.forEach((item) => {
      item.resource && selectUnmanagedItem(item.resource);
      item.group && selectUnmanagedItem(item.group);
    });

    while (thisCursor) {
      const { data, error } = await fetchMore({
        variables: {
          cursor: thisCursor,
        },
      });
      if (error) {
        logError(error, "failed to select all items.");
        displayErrorToast("failed to select all items.");
        break;
      }
      const items =
        data?.app.__typename === "App" ? data.app.items.items : null;
      items?.forEach((item) => {
        item.resource && selectUnmanagedItem(item.resource);
        item.group && selectUnmanagedItem(item.group);
      });
      thisCursor = data.app.__typename === "App" ? data.app.items.cursor : null;
    }
  };

  const clearAll = async () => {
    app?.items.items?.forEach((item) => {
      item.resource && clearUnmanagedItem(item.resource);
      item.group && clearUnmanagedItem(item.group);
    });
  };

  if (loading && !data && !searchQuery) {
    return <ColumnListItemsSkeleton />;
  }

  if ((error || !data) && !searchQuery) {
    logError(error, "failed to get items list section query");
    return <UnexpectedErrorPage error={error} />;
  }

  const navigateToItem = (id: string, entityType: EntityType) => {
    setAppDetailState({
      accountId: props.accountId,
      searchQuery,
      sortOption,
    });

    const searchParams = new URLSearchParams();
    if (oktaAppId) {
      searchParams.set(OKTA_APP_ID_URL_KEY, oktaAppId);
    }
    if (accountId) {
      searchParams.set(ACCOUNT_ID_URL_KEY, accountId);
    }

    transitionTo({
      pathname: getResourceUrlNew({
        entityId: id,
        entityType: entityType,
      }),
      search: searchParams.toString(),
    });
  };

  const renderItems = () => {
    if (!app?.items.items) return <ColumnListItemsSkeleton />;

    const items = app?.items.items.flatMap((result, idx) => {
      if (result.resource) {
        const { id, name, resourceType, currentUserAccess } = result.resource;
        const hasAccess = currentUserAccess.resourceUsers.length > 0;
        const onChangeShift = (checked: boolean) => {
          if (lastClicked !== null) {
            const min = Math.min(lastClicked, idx);
            const max = Math.max(lastClicked, idx);
            const resources = app?.items.items
              ?.slice(min, max + 1)
              .map((item) => item?.resource)
              .filter((item) => item !== undefined) as SelectedUnmanagedItem[];
            checked
              ? selectUnmanagedItems(resources)
              : clearUnmanagedItems(resources);
          } else if (result.resource) {
            toggleUnmanagedItem(result.resource);
          }
          setLastClicked(idx);
        };

        return [
          <ColumnListItem
            key={id}
            label={name}
            sublabel={result.resource.remoteId ?? ""}
            onClick={() => {
              if (accessOptionKey === AccessOption.Unmanaged) {
                result.resource && toggleUnmanagedItem(result.resource);
              } else {
                navigateToItem(id, EntityType.Resource);
              }
            }}
            selected={resourceId === id}
            checkbox={{
              checked: selectedUnmanagedItems.some((i) => i.id === id),
              onChange: () => {
                result.resource && toggleUnmanagedItem(result.resource);
                setLastClicked(idx);
                history.push(
                  `/apps/${props.appId}?${ACCESS_OPTION_URL_KEY}=${AccessOption.Unmanaged}`
                );
              },
              onChangeShift,
            }}
            largeIcon
            icon={{
              type: "entity",
              entityType: resourceType,
            }}
            rightContent={
              hasAccess && accessOptionKey !== AccessOption.Unmanaged ? (
                <Icon name="check-circle" size="xs" color="green600" />
              ) : undefined
            }
          />,
        ];
      }
      if (result.group) {
        const { id, name, groupType, currentUserAccess } = result.group;
        const hasAccess = Boolean(currentUserAccess.groupUser);
        return [
          <ColumnListItem
            key={id}
            label={name}
            sublabel={result.group.remoteId ?? ""}
            onClick={() => {
              if (accessOptionKey === AccessOption.Unmanaged) {
                if (result.group) {
                  toggleUnmanagedItem(result.group);
                }
              } else {
                navigateToItem(id, EntityType.Group);
              }
            }}
            selected={groupId === id}
            checkbox={{
              checked: selectedUnmanagedItems.some((item) => item.id === id),
              onChange: () => {
                if (result.group) toggleUnmanagedItem(result.group);
              },
            }}
            largeIcon
            icon={{
              type: "entity",
              entityType: groupType,
            }}
            rightContent={
              hasAccess && accessOptionKey !== AccessOption.Unmanaged ? (
                <Icon name="check-circle" size="xs" color="green600" />
              ) : undefined
            }
          />,
        ];
      }
      return [];
    });

    return (
      <ColumnListScroller
        numRows={items.length}
        renderRow={(index) => items[index]}
        onLoadMore={loadMore}
        hasNextPage={cursor != null}
        loading={loading}
        emptyState={{ title: "No items to display." }}
      />
    );
  };

  // Prevent flash of item type selector when changing search/sort
  const itemTypes = app?.itemTypes ?? previousApp?.itemTypes ?? [];
  const itemTypesOptions = itemTypes.map((i) => ({
    label: i.displayText,
    value: i.itemType,
    icon: {
      type: "entity",
      entityType: i.itemType as ResourceType | GroupType,
    } as IconData,
  }));
  const allSelected = !!app?.items.items?.every((item) => {
    const id = item.group?.id || item.resource?.id;
    return selectedUnmanagedItems.some((i) => i.id === id);
  });

  return (
    <>
      <ColumnSearchAndSort
        placeholder="Filter items"
        sortOptions={SORT_OPTIONS}
        sortBy={sortOption}
        setSortBy={setSortOption}
        initialSearchQuery={searchQuery}
        setSearchQuery={setSearchQuery}
        filterByOptions={
          itemTypes.length && itemTypes.length > 1
            ? itemTypesOptions
            : undefined
        }
        filterBy={itemTypesOptions.find((i) => i.value === selectedItemType)}
        setFilterBy={(value) => setSelectedItemType(value?.value ?? null)}
        checkbox={{
          alwaysShow: app?.items.items ? app?.items.items?.length > 0 : false,
          checked: allSelected,
          onChange: allSelected ? clearAll : selectAll,
          loading: loading,
        }}
        trackName="items"
      />

      {renderItems()}
    </>
  );
};

export default ItemsListSectionImport;
