import { RiskLevel } from "api/generated/graphql";
import { Select } from "components/ui";
import { startCase } from "lodash";
import React from "react";

export type RiskSensitivityDropdownProps = {
  selectedSensitivity?: RiskLevel;
  onSelectSensitivity: (riskLevel?: RiskLevel) => void;
  clearable?: boolean;
  placeholder?: string;
  size?: PropsFor<typeof Select>["size"];
};

const RiskSensitivityDropdown = (props: RiskSensitivityDropdownProps) => {
  const allRiskLevels = [
    RiskLevel.Critical,
    RiskLevel.High,
    RiskLevel.Medium,
    RiskLevel.Low,
    RiskLevel.None,
  ];

  return (
    <Select
      options={allRiskLevels}
      value={props.selectedSensitivity}
      getOptionLabel={(option) => startCase(option.toLowerCase())}
      onChange={props.onSelectSensitivity}
      clearable={props.clearable}
      placeholder={props.placeholder}
      disableBuiltInFiltering
      size={props.size}
    />
  );
};

export default RiskSensitivityDropdown;
