import {
  ConfigurationTemplateFragment,
  useDeleteConfigurationTemplateMutation,
  useGetConfigurationsTemplateQuery,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { Column } from "components/column/Column";
import ColumnContent from "components/column/ColumnContent";
import ColumnHeader, {
  ColumnHeaderSkeleton,
} from "components/column/ColumnHeaderV3";
import ResourcesConfigForm from "components/forms/ResourcesConfigForm";
import { makeConfigForTemplate } from "components/forms/utils";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { ButtonV3, Modal } from "components/ui";
import sprinkles from "css/sprinkles.css";
import { useContext, useState } from "react";
import { useHistory, useParams } from "react-router";
import { logError } from "utils/logging";
import { ItemDetailsCard } from "views/common/ItemDetailsCard";
import { NotFoundPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";

const ConfigTemplateDetail = () => {
  const history = useHistory();
  const { configurationTemplateId } = useParams<{
    configurationTemplateId: string;
  }>();

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const { authState } = useContext(AuthContext);
  const isAdmin = authState.user?.isAdmin;

  const { data, error, loading } = useGetConfigurationsTemplateQuery({
    variables: {
      ID: configurationTemplateId,
    },
    skip: !configurationTemplateId,
  });
  let configurationTemplate: ConfigurationTemplateFragment | undefined;
  switch (data?.configurationTemplate.__typename) {
    case "ConfigurationTemplateNotFoundError":
      return (
        <Column isContent maxWidth="none">
          <NotFoundPage />
        </Column>
      );
    case "ConfigurationTemplateResult":
      configurationTemplate =
        data.configurationTemplate.configurationTemplate ?? undefined;
      break;
  }

  if (loading) {
    return (
      <Column isContent maxWidth="none">
        <ColumnHeaderSkeleton includeCard />
      </Column>
    );
  }
  if (error || !configurationTemplate) {
    return (
      <Column isContent maxWidth="none">
        <UnexpectedErrorPage error={error} />
      </Column>
    );
  }

  const handleEdit = () => {
    history.push(`/templates/configurations/${configurationTemplateId}/edit`);
  };

  const detailsCard = (
    <ItemDetailsCard
      title={configurationTemplate.name}
      rightActions={
        isAdmin ? (
          <div className={sprinkles({ display: "flex", gap: "sm" })}>
            <ButtonV3
              label="Delete Template"
              type="danger"
              size="sm"
              onClick={() => setShowDeleteModal(true)}
            />
            <ButtonV3
              label="Edit"
              leftIconName="edit"
              type="main"
              size="sm"
              onClick={handleEdit}
            />
          </div>
        ) : undefined
      }
    />
  );

  return (
    <>
      <Column isContent maxWidth="none">
        <ColumnHeader
          breadcrumbs={[
            { name: "Templates", to: "/templates/requests" },
            {
              name: "Configuration Templates",
              to: "/templates/configurations",
            },
            { name: configurationTemplate.name },
          ]}
          includeDefaultActions
        />
        <ColumnContent>
          {detailsCard}
          <div
            className={sprinkles({
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              fontSize: "textMd",
              fontWeight: "medium",
              margin: "md",
            })}
          >
            Configuration Template Details
            {isAdmin && (
              <ButtonV3
                label="Edit Details"
                leftIconName="edit"
                type="mainBorderless"
                size="xs"
                onClick={handleEdit}
              />
            )}
          </div>
          <ResourcesConfigForm
            config={makeConfigForTemplate(configurationTemplate)}
            mode="view"
            isTemplate
            onChange={() => {}}
          />
        </ColumnContent>
      </Column>
      {showDeleteModal && (
        <DeleteModal
          configurationTemplate={configurationTemplate}
          onClose={() => setShowDeleteModal(false)}
        />
      )}
    </>
  );
};

const DeleteModal = (props: {
  configurationTemplate: ConfigurationTemplateFragment;
  onClose: () => void;
}) => {
  const history = useHistory();
  const { displaySuccessToast } = useToast();
  const [
    deleteConfigurationTemplate,
    { loading: deleteLoading },
  ] = useDeleteConfigurationTemplateMutation();

  const [errors, setErrors] = useState<string[]>([]);

  const handleDelete = async () => {
    try {
      const { data } = await deleteConfigurationTemplate({
        variables: {
          id: props.configurationTemplate.id,
        },
        refetchQueries: ["ListConfigurationsTemplates"],
      });
      switch (data?.deleteConfigurationTemplate.__typename) {
        case "ConfigurationInUseError":
          setErrors([data.deleteConfigurationTemplate.message]);
          break;
        case "DeleteConfigurationTemplateResult":
          displaySuccessToast("Configuration template deleted.");
          history.push("/templates/configurations");
          props.onClose();
          break;
        default:
          setErrors(["Error: failed to delete configuration template."]);
          logError(new Error("failed to delete configuration template"));
      }
    } catch (error) {
      setErrors(["Error: failed to delete configuration template."]);
      logError(error, "failed to delete configuration template");
    }
  };

  return (
    <Modal
      title={`Delete ${props.configurationTemplate.name}`}
      isOpen
      onClose={props.onClose}
    >
      <Modal.Body>
        {errors.map((error) => (
          <ModalErrorMessage errorMessage={error} />
        ))}
        Are you sure you want to delete "{props.configurationTemplate.name}"?
        This cannot be undone.
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Delete"
        onPrimaryButtonClick={handleDelete}
        primaryButtonLoading={deleteLoading}
      />
    </Modal>
  );
};

export default ConfigTemplateDetail;
