import { ColumnContainer } from "components/column/Column";
import { Switch } from "react-router";
import { Route } from "react-router-dom";
import { FeatureFlag, useFeatureFlag } from "utils/feature_flags";

import UserDetailColumn from "./UserDetailColumn";
import UserDetailColumnV3 from "./UserDetailColumnV3";
import UsersColumn from "./UsersColumn";
import UsersColumnV3 from "./UsersColumnV3";

const Users = () => {
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);

  if (hasV3Nav) {
    return (
      <Switch>
        <Route
          exact
          path={["/users/:userId"]}
          component={UserDetailComponentV3}
        />
        <Route exact path={["/users"]} component={UsersComponentV3} />
      </Switch>
    );
  }

  return (
    <Route
      exact
      path={["/users", "/users/:userId"]}
      component={UsersComponent}
    />
  );
};

const UsersComponentV3 = () => {
  return (
    <ColumnContainer>
      <UsersColumnV3 />
    </ColumnContainer>
  );
};

const UserDetailComponentV3 = () => {
  const hasV3Nav = useFeatureFlag(FeatureFlag.V3Nav);
  return hasV3Nav ? (
    <ColumnContainer>
      <UserDetailColumnV3 />
    </ColumnContainer>
  ) : (
    <ColumnContainer>
      <UserDetailColumn />
    </ColumnContainer>
  );
};

const UsersComponent = () => {
  return (
    <ColumnContainer>
      <UsersColumn />
    </ColumnContainer>
  );
};

export default Users;
