import {
  Maybe,
  MessageChannelFragment,
  MessageChannelType,
} from "api/generated/graphql";
import CreateMessageChannel from "components/message_channel/CreateMessageChannel";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { Modal, Select } from "components/ui";
import sprinkles from "css/sprinkles.css";

import * as styles from "./ReviewerMessageChannelModal.css";

interface BaseProps {
  messageChannelOptions: MessageChannelFragment[];
  selectedChannel?: MessageChannelFragment;
  setSelectedChannel: (messageChannel?: MessageChannelFragment) => void;
  adminTeamName: string;

  // For authorization
  groupId?: string;
  errorMessage: Maybe<string>;
  dropdownErrorMessage: Maybe<string>;
  channelNameToCreate: string;
  loading: boolean;
}

type ReviewerMessageChannelModalProps = BaseProps & {
  title: string;
  helpText: string;
  onSubmit: () => void;
  onClose: () => void;
  initChannelId?: string;
  isModalOpen: boolean;
};

const ReviewerMessageChannelModal = (
  props: ReviewerMessageChannelModalProps
) => {
  return (
    <Modal
      isOpen={props.isModalOpen}
      onClose={props.onClose}
      title={props.title}
    >
      <Modal.Body>
        <div className={sprinkles({ marginBottom: "md" })}>
          {props.helpText}
        </div>
        <ReviewerMessageChannelEdit {...props} />
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Save"
        onPrimaryButtonClick={props.onSubmit}
        primaryButtonDisabled={
          props.initChannelId === props.selectedChannel?.id
        }
        primaryButtonLoading={props.loading}
      />
    </Modal>
  );
};

export const ReviewerMessageChannelEdit = (props: BaseProps) => {
  const hasCreatedChannels =
    props.selectedChannel || props.messageChannelOptions.length > 0;

  return (
    <>
      <div className={sprinkles({ marginBottom: "lg" })}>
        {!hasCreatedChannels ? (
          "Create or link your first channel below:"
        ) : (
          <div className={sprinkles({ marginBottom: "sm" })}>
            <Select
              value={props.selectedChannel}
              onChange={props.setSelectedChannel}
              options={props.messageChannelOptions}
              loading={props.loading}
              getOptionLabel={(messageChannel) => messageChannel.name}
              getIcon={(option) => ({
                type: "name",
                icon: option.isPrivate ? "lock" : "hash",
              })}
              placeholder="Search for channels"
              clearable
            />
          </div>
        )}
      </div>

      {props.dropdownErrorMessage && (
        <ModalErrorMessage errorMessage={props.dropdownErrorMessage} />
      )}
      {props.errorMessage && (
        <ModalErrorMessage errorMessage={props.errorMessage} />
      )}

      <div className={styles.createContainer}>
        <CreateMessageChannel
          channelNameToCreate={props.channelNameToCreate}
          adminTeamName={props.adminTeamName}
          channelType={MessageChannelType.Reviewer}
          onCreateChannel={props.setSelectedChannel}
          groupId={props.groupId}
        />
      </div>
    </>
  );
};

export default ReviewerMessageChannelModal;
