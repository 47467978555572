import { getModifiedErrorMessage } from "api/ApiContext";
import {
  GeneralSettingType,
  useUpdateOrganizationSettingsMutation,
} from "api/generated/graphql";
import clsx from "clsx";
import AuthContext from "components/auth/AuthContext";
import { MoreInfo } from "components/more_info/MoreInfo";
import { useToast } from "components/toast/Toast";
import { Switch } from "components/ui";
import React, { useContext } from "react";
import { logError } from "utils/logging";
import OrgContext, { OrgContextActionType } from "views/settings/OrgContext";
import { OrgSettingAttributes } from "views/settings/OrgSettings";
import styles from "views/settings/OrgSettings.module.scss";

type OrgSettingSwitchProps = {
  labelText: string;
  tooltipText: string;
  setting: OrgSettingAttributes;
  currentSettings: GeneralSettingType[];
};

export const OrgSettingSwitch = (props: OrgSettingSwitchProps) => {
  const { orgDispatch } = useContext(OrgContext);
  const { authState } = useContext(AuthContext);

  const [updateOrgSettings] = useUpdateOrganizationSettingsMutation();

  const {
    displayLoadingToast,
    displaySuccessToast,
    displayErrorToast,
  } = useToast();

  return (
    <div className={styles.switchesHeader}>
      <div className={styles.switches}>
        <div
          className={clsx({
            [styles.switch]: true,
            [styles.off]: !props.setting.initialStateOn,
          })}
        >
          <Switch
            disabled={!authState.user?.isAdmin}
            checked={props.setting.initialStateOn}
            onChange={async () => {
              try {
                displayLoadingToast();
                const { data } = await updateOrgSettings({
                  variables: {
                    input: {
                      settings: props.currentSettings.includes(
                        props.setting.settingType
                      )
                        ? props.currentSettings.filter(
                            (setting) => setting !== props.setting.settingType
                          )
                        : [...props.currentSettings, props.setting.settingType],
                    },
                  },
                });
                switch (data?.updateOrganizationSettings.__typename) {
                  case "UpdateOrganizationSettingsResult":
                    orgDispatch({
                      type: OrgContextActionType.OrgSettings,
                      payload: {
                        orgSettings: data.updateOrganizationSettings.settings,
                      },
                    });

                    displaySuccessToast("Success: settings updated");
                    break;
                  default:
                    logError(new Error(`failed to update org settings`));
                    displayErrorToast(`Error: failed to update org settings`);
                }
              } catch (error) {
                logError(error, "failed to update org settings");
                displayErrorToast(
                  getModifiedErrorMessage(
                    `Error: failed to update org settings`,
                    error
                  )
                );
              }
            }}
          />
        </div>
      </div>
      <div className={styles.label}>{props.labelText}</div>
      <MoreInfo tooltipText={props.tooltipText} />
    </div>
  );
};
