import clsx from "clsx";
import sprinkles from "css/sprinkles.css";
import { spaceVars } from "css/vars.css";
import React from "react";
import { AlertCircle, AlertTriangle, CheckCircle, Info } from "react-feather";
import { Link } from "react-router-dom";

import * as styles from "./Banner.css";

type BannerProps = {
  message: React.ReactNode;
  /** Defaults to info. */
  type?: "warning" | "error" | "success" | "info";
  /** Global banners are for global information, such as the impersonation banner. */
  isGlobal?: boolean;
  /** When set, default icon is not rendered. May use this for notification-inline */
  noIcon?: boolean;

  // Margin helpers
  marginTop?: spaceVars;
  marginBottom?: spaceVars;
  width?: "full" | "sm" | "md" | "lg";
};

const Banner = (props: BannerProps) => {
  const {
    type = "info",
    message,
    isGlobal = false,
    noIcon = false,
    width,
  } = props;

  const renderIcon = () => {
    switch (type) {
      case "info":
        return <Info />;
      case "warning":
        return <AlertCircle />;
      case "error":
        return <AlertTriangle />;
      case "success":
        return <CheckCircle />;
    }
  };

  if (!props.message) return null;

  return (
    <div
      className={clsx(
        styles.banner({ type, isGlobal, width }),
        sprinkles({
          marginTop: props.marginTop,
          marginBottom: props.marginBottom,
        })
      )}
    >
      {!noIcon && <div className={styles.icon({ type })}>{renderIcon()}</div>}
      <div className={styles.text}>{message}</div>
    </div>
  );
};

interface BannerLinkBaseProps {
  label: string;
}

interface BannerLinkToProps extends BannerLinkBaseProps {
  to: PropsFor<typeof Link>["to"];
}

interface BannerLinkClickProps extends BannerLinkBaseProps {
  onClick: () => void;
}

type BannerLinkProps = BannerLinkToProps | BannerLinkClickProps;

const BannerLink = (props: BannerLinkProps) => {
  if ("to" in props) {
    return (
      <Link className={styles.link} to={props.to}>
        {props.label}
      </Link>
    );
  }
  if ("onClick" in props) {
    return (
      <span className={styles.link} onClick={props.onClick}>
        {props.label}
      </span>
    );
  }
  return null;
};

export default Object.assign(Banner, { Link: BannerLink });
