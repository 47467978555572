import { MetricsInput, TimeBucket } from "api/generated/graphql";
import { produce } from "immer";
import moment from "moment-timezone";
import { useCallback } from "react";
import { DateRange } from "react-day-picker";
import { useHistory, useLocation } from "react-router";

const METRICS_FILTER_URL_KEY = "filters";

interface MetricsFilterState {
  dateRange: DateRange;
  bucketSize: TimeBucket;
  connectionIds: string[];
  resourceIds: string[];
  groupIds: string[];
  tagIds: string[];
}

// Default date range to the last 30 days
const now = new Date();
const defaultDate = new Date();
defaultDate.setDate(defaultDate.getDate() - 30);
const initialState: MetricsFilterState = {
  dateRange: {
    from: defaultDate,
  },
  bucketSize: TimeBucket.Day,
  connectionIds: [],
  resourceIds: [],
  groupIds: [],
  tagIds: [],
};

export const useMetricsFilterState = (): MetricsFilterState => {
  const { search } = useLocation();
  if (!search) {
    return initialState;
  }

  const params = new URLSearchParams(search);
  const data = params.get(METRICS_FILTER_URL_KEY);

  if (!data) {
    return initialState;
  }

  // Parse URL hash for filter state
  const inputString = decodeURIComponent(atob(data));

  const parsed = JSON.parse(inputString);

  // Convert date strings to Date objects
  const result = {
    ...parsed,
  };
  if (result.dateRange.from) {
    result.dateRange.from = new Date(result.dateRange.from);
  }
  if (result.dateRange.to) {
    result.dateRange.to = new Date(result.dateRange.to);
  }

  return result;
};

export const useMetricsQueryInput = (): MetricsInput => {
  const timezone = moment.tz.guess();
  const {
    dateRange,
    connectionIds,
    resourceIds,
    groupIds,
    tagIds,
    bucketSize,
  } = useMetricsFilterState();

  return {
    startDate: dateRange.from?.toISOString() ?? "",
    endDate: dateRange.to?.toISOString(),
    timezone,
    bucketSize,
    connectionIds,
    resourceIds,
    groupIds,
    tagIds,
  };
};

const makeFilterStateURLHash = (filterState: MetricsFilterState) => {
  const outputString = encodeURIComponent(JSON.stringify(filterState));
  const output = btoa(outputString);
  return `?${METRICS_FILTER_URL_KEY}=${output}`;
};

export type FilterAction =
  | {
      type: "SET_DATE_RANGE";
      payload: {
        dateRange: DateRange;
      };
    }
  | {
      type: "SET_CONNECTION_IDS";
      payload: {
        ids: string[];
      };
    }
  | {
      type: "SET_RESOURCE_IDS";
      payload: {
        ids: string[];
      };
    }
  | {
      type: "SET_GROUP_IDS";
      payload: {
        ids: string[];
      };
    }
  | {
      type: "SET_TAG_IDS";
      payload: {
        ids: string[];
      };
    }
  | {
      type: "SET_BUCKET_SIZE";
      payload: {
        bucketSize: TimeBucket;
      };
    }
  | {
      type: "CLEAR_FILTERS";
    };

export function filterReducer(
  state: MetricsFilterState,
  action: FilterAction
): MetricsFilterState {
  switch (action.type) {
    case "SET_DATE_RANGE": {
      return produce(state, (draft) => {
        draft.dateRange = action.payload.dateRange;
        // Update bucket size if date range is smaller than current bucket size
        const { from = now, to = now } = action.payload.dateRange;
        const durationDays = moment(to).diff(moment(from), "days");

        if (durationDays < 7) {
          draft.bucketSize = TimeBucket.Day;
        } else if (durationDays < 30 && draft.bucketSize === TimeBucket.Month) {
          draft.bucketSize = TimeBucket.Week;
        }
      });
    }
    case "SET_CONNECTION_IDS": {
      return produce(state, (draft) => {
        draft.connectionIds = action.payload.ids;
      });
    }
    case "SET_RESOURCE_IDS": {
      return produce(state, (draft) => {
        draft.resourceIds = action.payload.ids;
      });
    }
    case "SET_GROUP_IDS": {
      return produce(state, (draft) => {
        draft.groupIds = action.payload.ids;
      });
    }
    case "SET_TAG_IDS": {
      return produce(state, (draft) => {
        draft.tagIds = action.payload.ids;
      });
    }
    case "SET_BUCKET_SIZE": {
      return produce(state, (draft) => {
        draft.bucketSize = action.payload.bucketSize;
      });
    }
    case "CLEAR_FILTERS": {
      return produce(state, (draft) => {
        draft.connectionIds = [];
        draft.tagIds = [];
        draft.resourceIds = [];
        draft.groupIds = [];
      });
    }
  }
}

export const useMetricsFilterDispatch = () => {
  const currentState = useMetricsFilterState();
  const history = useHistory();

  const dispatch = useCallback(
    (action: FilterAction) => {
      const updatedState = filterReducer(currentState, action);

      // Encode state to base64 and update to URL
      const hash = makeFilterStateURLHash(updatedState);
      history.push(hash);
    },
    [currentState, history]
  );

  return dispatch;
};
