import {
  AddBigDataInput,
  AldwinRole,
  Maybe,
  useAddBigDataMutation,
  useAllowedToUseAldwinQuery,
  useRescoreRecommendationsMutation,
  useResetDataseedMutation,
  useResetRemediationsMutation,
  useSetRolesMutation,
} from "api/generated/graphql";
import aldwinIcon from "assets/icons/aldwin.png";
import AuthContext from "components/auth/AuthContext";
import { opalConfetti } from "components/confetti/Confetti";
import ConfirmModal from "components/modals/update/ConfirmModal";
import {
  Banner,
  Checkbox,
  ContextMenu,
  ExpandableSection,
  FormGroup,
  Input,
  Modal,
  RadioGroup,
  Switch,
} from "components/ui";
import { useContext, useEffect, useState } from "react";
import { isDemo } from "utils/environment";
import {
  addFFOverride,
  FeatureFlag,
  LOCAL_STORAGE_FF_OVERRIDES_KEY,
  useFeatureFlag,
} from "utils/feature_flags";
import { logError } from "utils/logging";
import AppContext from "views/app/AppContext";

import * as styles from "./Aldwin.css";

const ownersOptions = [
  { value: "nothing", label: "Don't change owners" },
  {
    value: "addToOwners",
    label: "Add me to some random group/resource admins (owners)",
  },
  { value: "removeFromOwners", label: "Remove me from all owners" },
];

// Aldwin is a wizard that brings a world of possibilities to test environments that
// shall be strictly hidden from any production environment
export const Aldwin = () => {
  const { authState } = useContext(AuthContext);
  const { appState } = useContext(AppContext);
  const { data: allowedToUseAldwinData } = useAllowedToUseAldwinQuery();
  const allowedToUseAldwin =
    allowedToUseAldwinData?.allowedToUseAldwin.allowed ?? false;

  // Hide
  const [hideAldwin, setHideAldwin] = useState<boolean>(false);

  // Reset dataseed
  const [
    resetDataseed,
    { loading: loadingResetDataseed },
  ] = useResetDataseedMutation();
  const [resetDataseedErrorMessage, setResetDataseedErrorMessage] = useState<
    Maybe<string>
  >(null);
  const [
    showConfirmResetDataseedModal,
    setShowConfirmResetDataseedModal,
  ] = useState<boolean>(false);

  // Set role
  const [hasRoleSuperAdmin, setHasRoleSuperAdmin] = useState(false);
  const [hasRoleAuditor, setHasRoleAuditor] = useState(false);
  const [hasRoleReadOnlyAdmin, setHasRoleReadOnlyAdmin] = useState(false);
  const [hasRoleRequester, setHasRoleRequester] = useState(false);

  useEffect(() => {
    setHasRoleSuperAdmin(authState.user?.isAdmin ?? false);
    setHasRoleAuditor(authState.user?.isAuditor ?? false);
    setHasRoleReadOnlyAdmin(authState.user?.isReadOnlyAdmin ?? false);
    setHasRoleRequester(authState.user?.isGlobalRequester ?? false);
  }, [authState]);

  const [setRoles] = useSetRolesMutation();
  const [showSetRolesModal, setShowSetRolesModal] = useState<boolean>(false);

  const [selectedOwnersOption, setSelectedOwnersOption] = useState(
    ownersOptions[0]
  );

  // Add Big Data
  const [addBigData, { loading: loadingAddBigData }] = useAddBigDataMutation();
  const [addBigDataError, setAddBigDataError] = useState<string | undefined>();
  const [
    showConfirmAddBigDataModal,
    setShowConfirmAddBigDataModal,
  ] = useState<boolean>(false);
  const [bigDataOptions, setBigDataOptions] = useState<
    Partial<AddBigDataInput>
  >({
    usersCount: 0,
    resourcesCount: 0,
    resourceUsersCount: 0,
    groupsCount: 0,
    groupUsersCount: 0,
    groupResourcesCount: 0,
    appsCount: 0,
    appUsersCount: 0,
    tagsCount: 0,
    requestsCount: 0,
    ownersCount: 0,
    ownerUsersCount: 0,
    sessionsCount: 0,
    bundlesCount: 0,
    bundleResourcesCount: 0,
    bundleGroupsCount: 0,
  });

  // Reset Remediations
  const [resetRemediations] = useResetRemediationsMutation();

  // Rescore recommendations
  const [rescoreRecommendations] = useRescoreRecommendationsMutation();

  // FF overrides
  const hasV3 = useFeatureFlag(FeatureFlag.V3Nav);

  if (hideAldwin || !allowedToUseAldwin) {
    return null;
  }

  const menuOptions: PropsFor<typeof ContextMenu>["options"] = [
    {
      label: "Reset dataseed",
      onClick: () => {
        setShowConfirmResetDataseedModal(true);
      },
      hide: isDemo(appState.environment),
    },
    {
      label: "Set roles",
      onClick: () => {
        setShowSetRolesModal(true);
      },
      hide: !authState.isAuthenticated,
    },
    {
      label: "Add big data",
      onClick: () => {
        setShowConfirmAddBigDataModal(true);
      },
      hide: !authState.isAuthenticated,
    },
    {
      label: "Activate invisibility",
      onClick: () => {
        setHideAldwin(true);
      },
    },
    {
      label: "Reset remediations",
      onClick: async () => {
        {
          try {
            const { data } = await resetRemediations({});
            switch (data?.resetRemediations.__typename) {
              case "ResetRemediationsResult":
                setTimeout(() => {
                  window.location.reload();
                }, 500);
                break;
              default:
                logError(new Error(`failed to reset remediations`));
            }
          } catch (error) {
            logError(new Error(`failed to reset remediations`));
          }
        }
      },
    },
    {
      label: "Rescore recommendations",
      onClick: async () => {
        {
          try {
            const { data } = await rescoreRecommendations({});
            switch (data?.rescoreRecommendations.__typename) {
              case "RescoreRecommendationsResult":
                setTimeout(() => {
                  window.location.reload();
                }, 500);
                break;
              default:
                logError(new Error(`failed to re-score recommendations`));
            }
          } catch (error) {
            logError(new Error(`failed to re-score recommendations`));
          }
        }
      },
    },
    {
      label: hasV3 ? "Deactivate V3" : "Activate V3",
      sublabel: hasV3
        ? "Override all V3 flags to false"
        : "Override all V3 flags to true",
      onClick: () => {
        addFFOverride(FeatureFlag.V3Nav, !hasV3);
        window.location.reload();
      },
    },
    {
      label: "Clear FF overrides",
      onClick: () => {
        localStorage.removeItem(LOCAL_STORAGE_FF_OVERRIDES_KEY);
        window.location.reload();
      },
    },
  ];

  return (
    <div className={styles.aldwin}>
      <ContextMenu
        options={menuOptions.filter((option) => !option.hide)}
        renderButton={(onClick) => (
          <button
            className={styles.button}
            onClick={onClick}
            data-testid="aldwin-button"
          >
            <img src={aldwinIcon} className={styles.aldwinIcon} alt="#" />
          </button>
        )}
      />
      {showConfirmResetDataseedModal && (
        <ConfirmModal
          title={"Reset dataseed"}
          message={
            "This operation will take a bit. The page will refresh when it's done."
          }
          isModalOpen={showConfirmResetDataseedModal}
          onClose={() => setShowConfirmResetDataseedModal(false)}
          loading={loadingResetDataseed}
          errorMessage={resetDataseedErrorMessage}
          onSubmit={async () => {
            try {
              const { data } = await resetDataseed({});
              switch (data?.resetDataseed.__typename) {
                case "ResetDataseedResult":
                  setShowConfirmResetDataseedModal(false);
                  opalConfetti();
                  setTimeout(() => {
                    window.location.reload();
                  }, 500);
                  break;
                default:
                  logError(new Error(`failed to reset dataseed`));
                  setResetDataseedErrorMessage(
                    `Error: failed to reset dataseed`
                  );
              }
            } catch (error) {
              logError(error, `failed to reset dataseed`);
              setResetDataseedErrorMessage(`Error: failed to reset dataseed`);
            }
          }}
          submitLabel={"Reset dataseed"}
        />
      )}
      {showSetRolesModal && (
        <Modal
          title={"Set roles"}
          isOpen={showSetRolesModal}
          onClose={() => setShowSetRolesModal(false)}
        >
          <Modal.Body>
            <Checkbox
              label="Super-admin"
              checked={hasRoleSuperAdmin ?? false}
              onChange={(checked) => {
                setHasRoleSuperAdmin(checked);
              }}
            />
            <br />
            <Checkbox
              label="Auditor"
              checked={hasRoleAuditor ?? false}
              onChange={(checked) => {
                setHasRoleAuditor(checked);
              }}
            />
            <br />
            <Checkbox
              label="Read-only admin"
              checked={hasRoleReadOnlyAdmin ?? false}
              onChange={(checked) => {
                setHasRoleReadOnlyAdmin(checked);
              }}
            />
            <br />
            <Checkbox
              label="Global requester"
              checked={hasRoleRequester ?? false}
              onChange={(checked) => {
                setHasRoleRequester(checked);
              }}
            />
            <br />
            {!hasRoleSuperAdmin &&
              !hasRoleAuditor &&
              !hasRoleReadOnlyAdmin &&
              !hasRoleRequester && (
                <>
                  <Checkbox label="Member" checked={true} disabled={true} />
                  <br />
                </>
              )}
            <br />
            <RadioGroup
              options={ownersOptions}
              onSelectValue={(option) => {
                setSelectedOwnersOption(option);
              }}
              value={selectedOwnersOption}
              getOptionKey={(option) => option.value}
              getOptionLabel={(option) => option.label}
            />
          </Modal.Body>
          <Modal.Footer
            primaryButtonLabel="Update"
            onPrimaryButtonClick={async () => {
              try {
                const roles = [];
                if (hasRoleSuperAdmin) {
                  roles.push(AldwinRole.Admin);
                }
                if (hasRoleAuditor) {
                  roles.push(AldwinRole.Auditor);
                }
                if (hasRoleReadOnlyAdmin) {
                  roles.push(AldwinRole.ReadOnlyAdmin);
                }
                if (hasRoleRequester) {
                  roles.push(AldwinRole.GlobalRequester);
                }
                const { data } = await setRoles({
                  variables: {
                    input: {
                      roles,
                      shouldAddToOwners:
                        selectedOwnersOption?.value === "addToOwners",
                      shouldRemoveFromOwners:
                        selectedOwnersOption?.value === "removeFromOwners",
                    },
                  },
                });
                switch (data?.setRoles.__typename) {
                  case "SetRolesResult":
                    setShowSetRolesModal(false);
                    opalConfetti();
                    setTimeout(() => {
                      window.location.reload();
                    }, 500);
                    break;
                  default:
                    logError(new Error(`failed to set roles`));
                }
              } catch (error) {
                logError(error, `failed to set roles`);
              }
            }}
          />
        </Modal>
      )}
      <Modal
        isOpen={showConfirmAddBigDataModal}
        onClose={() => setShowConfirmAddBigDataModal(false)}
        title="Add Big Data"
      >
        <Modal.Body>
          <div>
            <p>Get a sense of how Opal behaves with customer-sized data.</p>
            <p>
              Adding data may take a few minutes if you set the numbers high
              enough.
            </p>
            <ExpandableSection
              label={
                bigDataOptions.usersCount
                  ? `Users (${bigDataOptions.usersCount})`
                  : "Users"
              }
              initialShowSection={false}
              marginBottom={"md"}
            >
              <FormGroup label="Random users" compact marginSize={"sm"}>
                <Input
                  onChange={(value) => {
                    setBigDataOptions({
                      ...bigDataOptions,
                      usersCount: value,
                    });
                  }}
                  value={bigDataOptions.usersCount}
                  type="number"
                />
              </FormGroup>
            </ExpandableSection>
            <ExpandableSection
              label={
                bigDataOptions.resourcesCount
                  ? `Resources (${bigDataOptions.resourcesCount})`
                  : "Resources"
              }
              initialShowSection={false}
              marginBottom={"md"}
            >
              <FormGroup label="Random resources" compact marginSize={"sm"}>
                <Input
                  onChange={(value) => {
                    setBigDataOptions({
                      ...bigDataOptions,
                      resourcesCount: value,
                    });
                  }}
                  value={bigDataOptions.resourcesCount}
                  type="number"
                  min={0}
                />
              </FormGroup>
              <Switch
                label="Set limited resource visibility"
                checked={bigDataOptions.resourcesVisibilityGroupsCount != null}
                infoTooltip={
                  "By enabling this toggle, you'll set limited visibility on all the created resources. Set a number below for how many random visibility groups should be added."
                }
                disabled={!bigDataOptions.resourcesCount}
                onChange={(checked) => {
                  setBigDataOptions({
                    ...bigDataOptions,
                    resourcesVisibilityGroupsCount: checked ? 0 : undefined,
                  });
                }}
              />
              {bigDataOptions.resourcesVisibilityGroupsCount != null ? (
                <Input
                  onChange={(value) => {
                    setBigDataOptions({
                      ...bigDataOptions,
                      resourcesVisibilityGroupsCount: value ?? 0,
                    });
                  }}
                  value={bigDataOptions.resourcesVisibilityGroupsCount ?? 0}
                  type="number"
                  min={0}
                />
              ) : null}
              <FormGroup
                infoTooltip="For each resource created, how many users should be added"
                label="Random resource users"
                compact
                marginSize={"sm"}
              >
                <Input
                  onChange={(value) => {
                    setBigDataOptions({
                      ...bigDataOptions,
                      resourceUsersCount: value,
                    });
                  }}
                  value={bigDataOptions.resourceUsersCount}
                  type="number"
                  disabled={!bigDataOptions.resourcesCount}
                  min={0}
                />
              </FormGroup>
            </ExpandableSection>
            <ExpandableSection
              label={
                bigDataOptions.groupsCount
                  ? `Groups (${bigDataOptions.groupsCount})`
                  : "Groups"
              }
              initialShowSection={false}
              marginBottom={"md"}
            >
              <FormGroup label="Random groups" compact marginSize={"sm"}>
                <Input
                  onChange={(value) => {
                    setBigDataOptions({
                      ...bigDataOptions,
                      groupsCount: value,
                    });
                  }}
                  value={bigDataOptions.groupsCount}
                  type="number"
                  min={0}
                />
              </FormGroup>
              <Switch
                label="Set limited group visibility"
                checked={bigDataOptions.groupsVisibilityGroupsCount != null}
                infoTooltip={
                  "By enabling this toggle, you'll set limited visibility on all the created groups. Set a number below for how many random visibility groups should be added."
                }
                disabled={!bigDataOptions.groupsCount}
                onChange={(checked) => {
                  setBigDataOptions({
                    ...bigDataOptions,
                    groupsVisibilityGroupsCount: checked ? 0 : undefined,
                  });
                }}
              />
              {bigDataOptions.groupsVisibilityGroupsCount != null ? (
                <Input
                  onChange={(value) => {
                    setBigDataOptions({
                      ...bigDataOptions,
                      groupsVisibilityGroupsCount: value ?? 0,
                    });
                  }}
                  value={bigDataOptions.groupsVisibilityGroupsCount ?? 0}
                  type="number"
                  min={0}
                />
              ) : null}
              <FormGroup
                infoTooltip="For each group created, how many users should be added"
                label="Random group users"
                compact
                marginSize={"sm"}
              >
                <Input
                  onChange={(value) => {
                    setBigDataOptions({
                      ...bigDataOptions,
                      groupUsersCount: value,
                    });
                  }}
                  value={bigDataOptions.groupUsersCount}
                  type="number"
                  disabled={!bigDataOptions.groupsCount}
                  min={0}
                />
              </FormGroup>
              <FormGroup
                infoTooltip="For each group created, how many resources should be added"
                label="Random group resources"
                compact
                marginSize={"sm"}
              >
                <Input
                  onChange={(value) => {
                    setBigDataOptions({
                      ...bigDataOptions,
                      groupResourcesCount: value,
                    });
                  }}
                  value={bigDataOptions.groupResourcesCount}
                  type="number"
                  disabled={!bigDataOptions.groupsCount}
                  min={0}
                />
              </FormGroup>
            </ExpandableSection>
            <ExpandableSection
              label={
                bigDataOptions.appsCount
                  ? `Apps (${bigDataOptions.appsCount})`
                  : "Apps"
              }
              initialShowSection={false}
              marginBottom={"md"}
            >
              <FormGroup label="Random apps" compact marginSize={"sm"}>
                <Input
                  onChange={(value) => {
                    setBigDataOptions({
                      ...bigDataOptions,
                      appsCount: value,
                    });
                  }}
                  value={bigDataOptions.appsCount}
                  type="number"
                />
              </FormGroup>
              <FormGroup
                infoTooltip="For each app created, how many users should be added"
                label="Random app users"
                compact
                marginSize={"sm"}
              >
                <Input
                  onChange={(value) => {
                    setBigDataOptions({
                      ...bigDataOptions,
                      appUsersCount: value,
                    });
                  }}
                  value={bigDataOptions.appUsersCount}
                  type="number"
                  disabled={bigDataOptions.appsCount === 0}
                />
              </FormGroup>
            </ExpandableSection>
            <ExpandableSection
              label={
                bigDataOptions.ownersCount
                  ? `Owners (${bigDataOptions.ownersCount})`
                  : "Owners"
              }
              initialShowSection={false}
              marginBottom={"md"}
            >
              <FormGroup label="Random owners" compact marginSize={"sm"}>
                <Input
                  onChange={(value) => {
                    setBigDataOptions({
                      ...bigDataOptions,
                      ownersCount: value,
                    });
                  }}
                  value={bigDataOptions.ownersCount}
                  type="number"
                />
              </FormGroup>
              <FormGroup
                infoTooltip="For each owner created, how many users should be added"
                label="Random owner users"
                compact
                marginSize={"sm"}
              >
                <Input
                  onChange={(value) => {
                    setBigDataOptions({
                      ...bigDataOptions,
                      ownerUsersCount: value,
                    });
                  }}
                  value={bigDataOptions.ownerUsersCount}
                  type="number"
                  disabled={bigDataOptions.ownersCount === 0}
                />
              </FormGroup>
            </ExpandableSection>
            <ExpandableSection
              label={
                bigDataOptions.bundlesCount
                  ? `Bundles (${bigDataOptions.bundlesCount})`
                  : "Bundles"
              }
              initialShowSection={false}
              marginBottom={"md"}
            >
              <FormGroup label="Random bundles" compact marginSize={"sm"}>
                <Input
                  value={bigDataOptions.bundlesCount}
                  type="number"
                  onChange={(value) => {
                    setBigDataOptions({
                      ...bigDataOptions,
                      bundlesCount: value,
                    });
                  }}
                />
              </FormGroup>
              <FormGroup
                label="Random bundle resources"
                compact
                marginSize={"sm"}
              >
                <Input
                  value={bigDataOptions.bundleResourcesCount}
                  type="number"
                  onChange={(value) => {
                    setBigDataOptions({
                      ...bigDataOptions,
                      bundleResourcesCount: value,
                    });
                  }}
                  disabled={bigDataOptions.bundlesCount === 0}
                />
              </FormGroup>
              <FormGroup label="Random bundle groups" compact marginSize={"sm"}>
                <Input
                  value={bigDataOptions.bundleGroupsCount}
                  type="number"
                  onChange={(value) => {
                    setBigDataOptions({
                      ...bigDataOptions,
                      bundleGroupsCount: value,
                    });
                  }}
                  disabled={bigDataOptions.bundlesCount === 0}
                />
              </FormGroup>
            </ExpandableSection>
            <ExpandableSection
              label={
                bigDataOptions.tagsCount ||
                bigDataOptions.requestsCount ||
                bigDataOptions.sessionsCount
                  ? `Misc (tags: ${bigDataOptions.tagsCount}) (requests: ${bigDataOptions.requestsCount}) (sessions: ${bigDataOptions.sessionsCount})`
                  : "Misc"
              }
              initialShowSection={false}
              marginBottom={"md"}
            >
              <FormGroup label="Random tags" compact marginSize={"sm"}>
                <Input
                  onChange={(value) => {
                    setBigDataOptions({
                      ...bigDataOptions,
                      tagsCount: value,
                    });
                  }}
                  value={bigDataOptions.tagsCount}
                  type="number"
                />
              </FormGroup>
              <FormGroup
                label="Random requests (in your account)"
                compact
                marginSize={"sm"}
              >
                <Input
                  onChange={(value) => {
                    setBigDataOptions({
                      ...bigDataOptions,
                      requestsCount: value,
                    });
                  }}
                  value={bigDataOptions.requestsCount}
                  type="number"
                />
              </FormGroup>
              <FormGroup
                label="Random sessions (spread randomly across resources)"
                compact
                marginSize={"sm"}
              >
                <Input
                  onChange={(value) => {
                    setBigDataOptions({
                      ...bigDataOptions,
                      sessionsCount: value,
                    });
                  }}
                  value={bigDataOptions.sessionsCount}
                  type="number"
                />
              </FormGroup>
            </ExpandableSection>

            {addBigDataError && (
              <Banner type="error" message={addBigDataError} />
            )}
          </div>
        </Modal.Body>
        <Modal.Footer
          primaryButtonLabel="Add Big Data"
          primaryButtonLoading={loadingAddBigData}
          onPrimaryButtonClick={async () => {
            try {
              const parsedOptions: AddBigDataInput = {
                usersCount: bigDataOptions.usersCount ?? 0,
                resourcesCount: bigDataOptions.resourcesCount ?? 0,
                resourcesVisibilityGroupsCount:
                  bigDataOptions.resourcesVisibilityGroupsCount,
                resourceUsersCount: bigDataOptions.resourceUsersCount ?? 0,
                groupsCount: bigDataOptions.groupsCount ?? 0,
                groupsVisibilityGroupsCount:
                  bigDataOptions.groupsVisibilityGroupsCount,
                groupUsersCount: bigDataOptions.groupUsersCount ?? 0,
                groupResourcesCount: bigDataOptions.groupResourcesCount ?? 0,
                appsCount: bigDataOptions.appsCount ?? 0,
                appUsersCount: bigDataOptions.appUsersCount ?? 0,
                tagsCount: bigDataOptions.tagsCount ?? 0,
                requestsCount: bigDataOptions.requestsCount ?? 0,
                ownersCount: bigDataOptions.ownersCount ?? 0,
                ownerUsersCount: bigDataOptions.ownerUsersCount ?? 0,
                sessionsCount: bigDataOptions.sessionsCount ?? 0,
                bundlesCount: bigDataOptions.bundlesCount ?? 0,
                bundleResourcesCount: bigDataOptions.bundleResourcesCount ?? 0,
                bundleGroupsCount: bigDataOptions.bundleGroupsCount ?? 0,
              };
              const { data } = await addBigData({
                variables: { input: parsedOptions },
              });
              switch (data?.addBigData.__typename) {
                case "AddBigDataResult":
                  setShowConfirmAddBigDataModal(false);
                  opalConfetti();
                  setTimeout(() => {
                    window.location.reload();
                  }, 500);
                  break;
                default:
                  setAddBigDataError(`Error: failed to add big data`);
              }
            } catch (error) {
              setAddBigDataError(`Error: failed to add big data`);
            }
          }}
        />
      </Modal>
    </div>
  );
};
