import pluralize from "pluralize";
import { createRef, useLayoutEffect, useState } from "react";

import * as styles from "./DataElementList.css";

interface Props {
  maxHeight?: number;
  // noun is the name of the item that each DataElement child represents.
  // Used to describe any overflow/hidden items.
  noun?: string;
}

const DataElementList: React.FC<Props> = (props) => {
  const ref = createRef<HTMLDivElement>();
  const [numOverflow, setNumOverflow] = useState(0);

  useLayoutEffect(() => {
    if (!props.maxHeight) {
      return;
    }

    const maxY = props.maxHeight;
    const container = ref.current;
    if (container == null) {
      return;
    }
    const containerBottom = container.offsetTop + maxY;

    let numVisible = 0;
    container?.childNodes.forEach((childNode) => {
      const element = childNode as HTMLElement;
      const bottom = element.offsetTop + element.offsetHeight;
      if (bottom < containerBottom) {
        numVisible += 1;
      }
    });
    setNumOverflow(container.childElementCount - numVisible);
  }, [props.maxHeight, ref]);

  return (
    <div>
      <div
        ref={ref}
        className={styles.container}
        style={{ maxHeight: props.maxHeight }}
      >
        {props.children}
      </div>
      {numOverflow > 0 ? (
        <div className={styles.overflowLabel}>
          {`+ ${numOverflow} other ${pluralize(
            props.noun ?? "item",
            numOverflow
          )}`}
        </div>
      ) : null}
    </div>
  );
};

export default DataElementList;
