import { gql, useMutation } from "@apollo/client";
import {
  AppIdpGroupMappingRowFragment,
  IdpGroupMappingsUpdate,
  Maybe,
  UpdateIdpGroupMappingsMutation,
} from "api/generated/graphql";
import ModalErrorMessage from "components/modals/ModalErrorMessage";
import { useToast } from "components/toast/Toast";
import { FormGroup, Input, Modal } from "components/ui";
import { useState } from "react";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  idpGroupMappings: Record<
    string,
    { mapping: AppIdpGroupMappingRowFragment; name: string }
  >;
};

const MUTATION = gql`
  mutation UpdateIdpGroupMappings($input: IdpGroupMappingsUpdateInput!) {
    updateIdpGroupMappings(input: $input) {
      success
    }
  }
`;

const BulkEditAliasModal = ({ onClose, idpGroupMappings, isOpen }: Props) => {
  const [errorMessage, setErrorMessage] = useState<Maybe<string>>(null);
  const [idpGroupMappingByGroupId, setIdpGroupMappingByGroupId] = useState<
    Record<string, { mapping: AppIdpGroupMappingRowFragment; name: string }>
  >(idpGroupMappings);
  const { displaySuccessToast } = useToast();
  const [updateIdpGroupMappings] = useMutation<UpdateIdpGroupMappingsMutation>(
    MUTATION
  );

  // Have function to submit
  const handleUpdateAliases = async () => {
    const input: IdpGroupMappingsUpdate[] = Object.values(
      idpGroupMappingByGroupId
    )
      .filter((map) => map.mapping.alias !== null && map.mapping.id)
      .map((map) => {
        return {
          idpGroupMappingId: map.mapping.id,
          alias: {
            string: map.mapping.alias,
          },
        } as IdpGroupMappingsUpdate;
      });
    const { data } = await updateIdpGroupMappings({
      variables: {
        input: {
          items: input,
        },
      },
      refetchQueries: ["AppResources"],
    });

    if (data?.updateIdpGroupMappings.success) {
      displaySuccessToast("Success: catalog names updated");
      onClose();
    } else {
      setErrorMessage("Error: failed updating catalog names");
    }
  };

  const setAlias = (id: string) => {
    return (newAlias: string) => {
      setIdpGroupMappingByGroupId((prevCurr) => {
        const prevMapping = prevCurr[id];
        return {
          ...prevCurr,
          [id]: {
            ...prevMapping,
            mapping: {
              ...prevMapping.mapping,
              alias: newAlias,
            } as AppIdpGroupMappingRowFragment,
          },
        };
      });
    };
  };

  const resetModal = () => {
    setIdpGroupMappingByGroupId(idpGroupMappings);
    onClose();
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={resetModal}
      title={`Edit Catalog Name`}
      fullWidth
    >
      <Modal.Body>
        {errorMessage && <ModalErrorMessage errorMessage={errorMessage} />}
        {Object.entries(idpGroupMappingByGroupId).map(([key, value]) => {
          const {
            name,
            mapping: { alias },
          } = value;
          return (
            <FormGroup label={name}>
              <Input
                value={alias ?? ""}
                onChange={setAlias(key)}
                placeholder="Add Catalog Name"
              />
            </FormGroup>
          );
        })}
      </Modal.Body>
      <Modal.Footer
        primaryButtonLabel="Update"
        onPrimaryButtonClick={handleUpdateAliases}
        secondaryButtonLabel="Close"
        onSecondaryButtonClick={onClose}
      />
    </Modal>
  );
};

export default BulkEditAliasModal;
