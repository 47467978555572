import { gql, useMutation, useQuery } from "@apollo/client";
import {
  AddIdpGroupMappingsInput,
  AddIdpGroupMappingsMutation,
  ConnectionType,
  GroupType,
  IdpGroupMappingInput,
  NullableString,
  OktaResourceAddResourcesQuery,
  ResourceType,
} from "api/generated/graphql";
import AuthContext from "components/auth/AuthContext";
import { ColumnListItemsSkeleton } from "components/column/ColumnListItem";
import FullscreenViewTitle from "components/fullscreen_modals/FullscreenViewTitle";
import { groupTypeInfoByType } from "components/label/GroupTypeLabel";
import FullscreenView from "components/layout/FullscreenView";
import { useToast } from "components/toast/Toast";
import {
  Banner,
  ButtonV3,
  Divider,
  EntityIcon,
  FormGroup,
  Input,
  Label,
} from "components/ui";
import Table, { Header } from "components/ui/table/Table";
import sprinkles from "css/sprinkles.css";
import pluralize from "pluralize";
import { useContext, useState } from "react";
import { useParams } from "react-router";
import { AuthorizedActionManage } from "utils/auth/auth";
import { useDebouncedValue } from "utils/hooks";
import { logError } from "utils/logging";
import { useTransitionBack } from "utils/router/hooks";
import { ForbiddenPage, UnexpectedErrorPage } from "views/error/ErrorCodePage";

import * as styles from "./OktaResourceAddResourcesView.css";
interface ResourceItemRow {
  id: string;
  name: string;
  alias?: string;
  connection?: {
    connectionType: ConnectionType;
  } | null;
  groupType: GroupType;
}

const COLUMNS: Header<ResourceItemRow>[] = [
  {
    id: "name",
    label: "Name",
    sortable: true,
    width: 800,
    customCellRenderer: ({ name, groupType }) => {
      return (
        <Label
          label={name}
          icon={{
            type: "entity",
            entityType: groupType,
          }}
          key={name}
        />
      );
    },
  },
];

const QUERY = gql`
  query OktaResourceAddResources(
    $id: ResourceId!
    $cursor: String
    $connectionType: ConnectionType!
    $searchQuery: String
  ) {
    groups(
      input: {
        cursor: $cursor
        connectionType: $connectionType
        searchQuery: $searchQuery
      }
    ) {
      ... on GroupsResult {
        cursor
        totalNumGroups
        groups {
          id
          connection {
            connectionType
          }
          groupType
          name
          groupType
          authorizedActions
        }
      }
    }
    resource(input: { id: $id }) {
      ... on ResourceResult {
        __typename
        resource {
          id
          name
          serviceType
          authorizedActions
          connection {
            connectionType
          }
        }
      }
      ... on ResourceNotFoundError {
        message
      }
    }
    idpGroupMappings(appResourceId: $id) {
      groupId
    }
  }
`;

const MUTATION = gql`
  mutation AddIdpGroupMappings($input: AddIdpGroupMappingsInput!) {
    addIdpGroupMappings(input: $input) {
      success
    }
  }
`;

interface ResourceCardProps {
  name: string;
  id: string;
  onRemove: () => void;
  onChangeAlias: (value: string) => void;
  alias?: string;
  connection?: {
    connectionType: ConnectionType;
  } | null;
  groupType: GroupType;
}

const ResourceCard = ({
  name,
  id,
  onRemove,
  onChangeAlias,
  alias,
  connection,
  groupType,
}: ResourceCardProps) => {
  return (
    <div key={id} className={styles.resourceCard}>
      <div
        className={sprinkles({
          display: "flex",
          alignItems: "flex-start",
          gap: "sm",
        })}
      >
        {connection && (
          <div
            className={sprinkles({
              flexShrink: 0,
            })}
          >
            <EntityIcon type={connection?.connectionType} iconStyle="rounded" />
          </div>
        )}
        <div className={styles.resourceInfoSection}>
          <div className={styles.resourceCardHeader}>{name}</div>
          <div className={styles.resourceCardSubtitle}>Okta</div>
          <div className={styles.resourceCardType}>
            <EntityIcon type={groupType} includeBrand={false} />
            {groupTypeInfoByType[groupType].name}
          </div>
        </div>
        <ButtonV3
          leftIconName="trash"
          onClick={onRemove}
          type="dangerBorderless"
          round
        />
      </div>
      <Divider />
      <FormGroup label="Catalog Name">
        <Input
          placeholder="Add a Catalog Name"
          value={alias}
          onChange={onChangeAlias}
          size="smmd"
        />
      </FormGroup>
    </div>
  );
};

export default () => {
  const transitionBack = useTransitionBack();
  const { resourceId } = useParams<{ resourceId: string }>();
  const [searchQuery, setSearchQuery] = useState<string>("");
  const { displaySuccessToast } = useToast();
  const [addError, setAddError] = useState("");

  const { authState } = useContext(AuthContext);
  const [resourcesToAddByResourceId, setResourcesToAddByResourceId] = useState<
    Record<
      string,
      {
        id: string;
        name: string;
        alias?: string;
        connection?: {
          connectionType: ConnectionType;
        } | null;
        groupType: GroupType;
      }
    >
  >({});
  const numResourcesToAdd = Object.keys(resourcesToAddByResourceId).length;
  const [
    addIdpGroupMappings,
    { loading: addLoading },
  ] = useMutation<AddIdpGroupMappingsMutation>(MUTATION);

  const debouncedSearchQuery = useDebouncedValue(searchQuery, 200);

  const {
    data,
    loading,
    error,
    fetchMore,
    previousData,
  } = useQuery<OktaResourceAddResourcesQuery>(QUERY, {
    fetchPolicy: "cache-and-network",
    notifyOnNetworkStatusChange: true,
    variables: {
      id: resourceId,
      cursor: null,
      connectionType: ConnectionType.OktaDirectory,
      searchQuery: debouncedSearchQuery,
    },
    skip: resourceId == null,
  });

  const listGroups = data?.groups.groups || previousData?.groups.groups || [];
  const handleSubmit = async () => {
    const idpMappings: IdpGroupMappingInput[] = Object.values(
      resourcesToAddByResourceId
    ).map((resource) => {
      return {
        alias: {
          string: resource.alias,
        } as NullableString,
        groupId: resource.id,
      };
    });

    const input: AddIdpGroupMappingsInput = {
      appResourceId: resource?.id ?? "",
      mappings: idpMappings,
    };

    try {
      const { data } = await addIdpGroupMappings({
        variables: {
          input: input,
        },
      });
      if (data?.addIdpGroupMappings.success) {
        displaySuccessToast("Successfully added groups to resource");
        handleClose();
      }
    } catch (err) {
      logError(
        err,
        `Failed to add group mappings, try again or contact your admin`
      );
      setAddError(
        "Failed to add group mappings, try again or contact your admin"
      );
    }
  };

  const cursor = data?.groups.cursor ?? null;
  const loadMoreRows = cursor
    ? async () => {
        await fetchMore({
          variables: {
            cursor: cursor,
          },
        });
      }
    : undefined;

  const resource =
    data?.resource.__typename === "ResourceResult"
      ? data?.resource.resource
      : previousData?.resource.__typename === "ResourceResult"
      ? previousData?.resource.resource
      : undefined;
  const idpGroupMappings = new Set(
    data?.idpGroupMappings.map((mappings) => mappings.groupId)
  );

  const canEdit =
    resource?.authorizedActions?.includes(AuthorizedActionManage) ||
    authState.user?.isAdmin;

  if (!loading && !canEdit) {
    return <ForbiddenPage />;
  }

  if (error) {
    return <UnexpectedErrorPage error={error} />;
  }

  const rows: ResourceItemRow[] = listGroups
    .filter(({ id }) => !idpGroupMappings.has(id))
    .map((group) => {
      return {
        id: group.id,
        name: group.name,
        connection: group.connection,
        groupType: group.groupType,
      };
    });

  const handleClose = () => {
    transitionBack(`/resources/${resourceId}/#resources`);
  };

  const allSelected = rows.length === numResourcesToAdd;

  const onCheckedRowsChange = async (
    checkedRowIds: string[],
    checked: boolean
  ) => {
    const newResourcesToAddByResourceId = {
      ...resourcesToAddByResourceId,
    };
    if (checked) {
      for (const resourceId of checkedRowIds) {
        if (!(resourceId in resourcesToAddByResourceId)) {
          const resource = listGroups.find(
            (resource) => resource.id === resourceId
          );
          if (resource) {
            newResourcesToAddByResourceId[resourceId] = {
              ...resource,
              connection: resource.connection,
            };
          }
        }
      }
      setResourcesToAddByResourceId(newResourcesToAddByResourceId);
    } else {
      for (const resourceId of checkedRowIds) {
        delete newResourcesToAddByResourceId[resourceId];
      }
      setResourcesToAddByResourceId(newResourcesToAddByResourceId);
    }
  };

  const onRowClick = (resource: ResourceItemRow) => {
    const newResourcesToAddByResourceId = {
      ...resourcesToAddByResourceId,
    };
    if (resource.id in resourcesToAddByResourceId) {
      delete newResourcesToAddByResourceId[resource.id];
      setResourcesToAddByResourceId(newResourcesToAddByResourceId);
    } else {
      newResourcesToAddByResourceId[resource.id] = resource;
      setResourcesToAddByResourceId(newResourcesToAddByResourceId);
    }
  };

  const onSelectAll = (checked: boolean) => {
    if (checked) {
      const newResourcesToAddByResourceId: Record<
        string,
        {
          id: string;
          name: string;
          alias: string;
          connection?: {
            connectionType: ConnectionType;
          } | null;
          groupType: GroupType;
        }
      > = {};
      for (const resource of rows) {
        newResourcesToAddByResourceId[resource.id] = {
          ...resource,
          alias: resource.name,
          connection: resource.connection,
        };
      }
      setResourcesToAddByResourceId(newResourcesToAddByResourceId);
    } else {
      setResourcesToAddByResourceId({});
    }
  };

  return (
    <FullscreenView
      title={
        <FullscreenViewTitle
          entityType={ResourceType.OktaApp}
          entityName={resource?.name ?? ""}
          targetEntityName="Resources"
          action="add"
        />
      }
      onCancel={handleClose}
      onPrimaryButtonClick={handleSubmit}
      primaryButtonLabel={`Add ${pluralize(
        "Resource",
        numResourcesToAdd,
        true
      )}`}
      primaryButtonDisabled={numResourcesToAdd === 0}
      primaryButtonLoading={loading || addLoading}
    >
      <FullscreenView.Content fullWidth>
        <div className={styles.contentContainer}>
          <div className={styles.headerText}>
            Select resources to add to the App:
          </div>
          <div className={styles.searchInput}>
            <Input
              leftIconName="search"
              type="search"
              style="search"
              value={searchQuery}
              onChange={(value) => {
                setSearchQuery(value);
              }}
              placeholder="Filter by name or email"
            />
          </div>
          <Divider />
          {loading && !data && !previousData ? (
            <ColumnListItemsSkeleton />
          ) : (
            <Table
              columns={COLUMNS}
              rows={rows}
              emptyState={{ title: "No resources found" }}
              totalNumRows={data?.groups.totalNumGroups ?? 0}
              getRowId={(resource) => resource.id}
              onLoadMoreRows={loadMoreRows}
              loadingRows={loading}
              checkedRowIds={new Set(Object.keys(resourcesToAddByResourceId))}
              defaultSortBy="name"
              onCheckedRowsChange={onCheckedRowsChange}
              onRowClick={onRowClick}
              selectAllChecked={allSelected}
              onSelectAll={onSelectAll}
            />
          )}
        </div>
      </FullscreenView.Content>
      <FullscreenView.Sidebar>
        {addError && (
          <Banner message={addError} type="error" marginBottom="lg" />
        )}
        <div
          className={sprinkles({
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "lg",
          })}
        >
          <div
            className={sprinkles({
              fontSize: "textLg",
              fontWeight: "medium",
              marginBottom: "lg",
            })}
          >
            Adding {pluralize("Resource", numResourcesToAdd, true)}
          </div>
          {numResourcesToAdd > 0 && (
            <ButtonV3
              leftIconName="x"
              label="Clear all"
              size="xs"
              type="dangerBorderless"
              onClick={() => setResourcesToAddByResourceId({})}
            />
          )}
        </div>
        {Object.values(resourcesToAddByResourceId).map(({ id, ...rest }) => (
          <ResourceCard
            onRemove={() => {
              setResourcesToAddByResourceId((prev) => {
                const resourcesToAddCopy = { ...prev };
                delete resourcesToAddCopy[id];
                return resourcesToAddCopy;
              });
            }}
            onChangeAlias={(newAlias) => {
              setResourcesToAddByResourceId((prev) => {
                const resourcesToAddCopy = { ...prev };
                resourcesToAddCopy[id].alias = newAlias;
                return resourcesToAddCopy;
              });
            }}
            id={id}
            {...rest}
          />
        ))}
      </FullscreenView.Sidebar>
    </FullscreenView>
  );
};
