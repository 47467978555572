import {
  EntityType,
  ResourceFragment,
  ResourceUsageLogFragment,
  useResourceUsageQuery,
} from "api/generated/graphql";
import UserLabel from "components/label/item_labels/UserLabel";
import { ResourceLabel, timeValueIndefinite } from "components/label/Label";
import LinkLabel from "components/label/LinkLabel";
import { EmptyStateContentWrapper } from "components/tables/EmptyState";
import MaterialTable, {
  CellRow,
  Header,
} from "components/tables/material_table/MaterialTable";
import moment from "moment/moment";
import { ReactElement } from "react";
import * as Icons from "react-feather";
import { EntityTypeDeprecated } from "utils/entity_type_deprecated";
import { logError, logWarning } from "utils/logging";
import { UnexpectedErrorPage } from "views/error/ErrorCodePage";
import ViewSkeleton from "views/loading/ViewSkeleton";

interface UsageTableRow {
  user: string;
  startDate: string;
  endDate: string;
  details: string;
}

type UsageTableProps = {
  resource: ResourceFragment;
};

export const UsageTable = (props: UsageTableProps) => {
  const { data, error, loading } = useResourceUsageQuery({
    variables: {
      input: {
        id: props.resource.id,
      },
    },
  });

  let usageLogs: ResourceUsageLogFragment[] | undefined | null = [];
  let emptyStateTitle = `No usage for this resource`;
  let emptyStateSubtitle = `This resource is not in use by any Opal users.`;
  let emptyStateIcon: ReactElement | undefined = undefined;
  if (data) {
    switch (data.resourceUsage.__typename) {
      case "ResourceUsageResult":
        usageLogs = data.resourceUsage.usageLogs;
        break;
      case "ResourceNotFoundError":
        logWarning(
          new Error(
            `unexpected error listing resource usage logs: ${data.resourceUsage.message}`
          )
        );
        break;
      case "UserFacingError":
        emptyStateTitle = "Failed to get usage logs";
        emptyStateSubtitle = data.resourceUsage.message;
        emptyStateIcon = <Icons.AlertCircle strokeWidth={1} />;
        break;
      default:
        logError(new Error(`unexpected error listing resource usage logs`));
    }
  }

  if (loading) {
    return <ViewSkeleton />;
  }

  if (error) {
    logError(error, `unexpected error listing resource usage logs`);
    return <UnexpectedErrorPage error={error} />;
  }

  const headers: Header<UsageTableRow>[] = [
    { id: "user", label: "User" },
    { id: "startDate", label: "Start Date" },
    { id: "endDate", label: "End Date" },
    { id: "details", label: "Details" },
  ];

  let rows: CellRow<UsageTableRow>[] | undefined = usageLogs?.map(
    (usageLog) => {
      const isUser = usageLog.user !== undefined && usageLog.user !== null;
      const user = usageLog.user;
      const name = usageLog.name || "";
      const details = usageLog.details || "";
      const startDate = moment(new Date(usageLog.startDate));
      const endDate = usageLog.endDate
        ? moment(new Date(usageLog.endDate))
        : null;

      return {
        id: usageLog.id,
        data: {
          user: {
            value: isUser ? user!.id : name,
            element: (
              <>
                {isUser ? (
                  <ResourceLabel
                    text={user?.fullName}
                    entityType={EntityTypeDeprecated.User}
                    avatar={user?.avatarUrl}
                    entityId={user?.id}
                  />
                ) : (
                  <UserLabel name={name} />
                )}
              </>
            ),
          },
          startDate: {
            value: startDate?.unix() || timeValueIndefinite,
            element: (
              <>{`${startDate.toISOString()} (${startDate.fromNow()})`}</>
            ),
          },
          endDate: {
            value: endDate?.unix() || timeValueIndefinite,
            element: (
              <>
                {endDate
                  ? `${endDate.toISOString()} (${endDate.fromNow()})`
                  : "Ongoing"}
              </>
            ),
          },
          details: {
            value: details,
            element: (
              <LinkLabel linkTitle={"View Remote Logs"} link={details} />
            ),
          },
        },
      };
    }
  );

  return (
    <EmptyStateContentWrapper
      content={
        <MaterialTable<UsageTableRow>
          headers={headers}
          rows={rows || []}
          denseFormatting={true}
          defaultSortField={"startDate"}
          defaultSortOrder={"desc"}
        />
      }
      entityType={EntityType.Activity}
      title={emptyStateTitle}
      subtitle={emptyStateSubtitle}
      icon={emptyStateIcon}
      isEmpty={!rows || rows.length === 0}
    />
  );
};

export default UsageTable;
